.integration-email-add-account {
    padding: 32px 30px;
    text-align: left;

    .comp-back-button {
        margin: 0 0 16px 0;
    }

    .integration-email-add-account-box {
        background: #ffffff;
        border-radius: 8px;
        padding: 32px;
        border: solid 1px rgba(25, 25, 25, 0.1);

        .integration-email-add-account-box-top {
            padding-bottom: 15px;
            border-bottom: solid 1px #dcdcdc;
            position: relative;
            font-size: 20px;
            letter-spacing: -0.6px;

            button {
                position: absolute;
                top: -6px;
                right: 0;
                // padding: 7px 24px;
                font-size: 16px;
                letter-spacing: -0.6px;
                width: 190px;
                // width: 195px;
                // height: 40px;

                // .lds-ring {
                //     top: unset;
                //     right: unset;
                //     position: relative;
                //     top: 3px;

                //     div {
                //         margin: 0;
                //         border-color: #ffffff transparent transparent transparent;
                //     }
                // }
            }
        }

        .integration-email-add-account-box-form {
            width: 100%;

            .line-border {
                width: 100%;
                height: 1px;
                background: rgba(25, 25, 25, 0.1);
                margin: 28px 0 0;
            }

            .integration-email-add-account-box-form-inner {
                width: 350px;
            }

            .password-wrapper {
                position: relative;

                svg {
                    position: absolute;
                    top: 50%;
                    transform: translate(0, -50%);
                    right: 16px;
                    font-size: 20px;
                    cursor: pointer;
                }

                input {
                    padding: 12px 44px 12px 16px !important;
                }
            }

            .mail-server-config {
                font-size: 20px;
                letter-spacing: -0.6px;
                margin: 32px 0 0;
            }
            
            label {
                padding: 24px 0 0;
                font-size: 16px;
                letter-spacing: -0.5px;
                display: block;
                position: relative;

                span {
                    position: absolute;
                    right: 0;
                }
            }

            input[type="text"] {
                padding: 12px 16px;
                border-radius: 6px;
                border: solid 1px #dcdcdc;
                width: 100%;
                font-size: 16px;
                letter-spacing: -0.3px;
                display: block;
                // margin-bottom: 8px;

                &:focus {
                    border: solid 1px var(--main-dark-orange);
                }
            }

            input[type="password"] {
                padding: 12px 16px;
                border-radius: 6px;
                border: solid 1px #dcdcdc;
                width: 100%;
                font-size: 16px;
                letter-spacing: -0.3px;
                display: block;
                margin-bottom: 8px;

                &:focus {
                    border: solid 1px var(--main-dark-orange);
                }
            }

            .integration-info-form {
                font-size: 14px;
                color: rgba(25, 25, 25, 0.8);
                letter-spacing: -0.2px;
            }

            .custom-select-whatsapp-topic {
                .custom-select-option-value-wrapper {
                    padding: 12px 16px !important;
                    margin-bottom: 8px;
                }
            }
        }
    }
}