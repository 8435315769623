.add-user-contact-container {
  padding: 32px 30px;
  text-align: left;

  .integration-detail-loading-wrapper {
    .lds-ring {
      width: 50px;
      height: 50px;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      right: unset;

      div {
        border: 5px solid #fff;
        border-color: #ff7d00 transparent transparent transparent;
        width: 50px;
        height: 50px;
        margin: 0;
      }
    }
  }

  .comp-back-button {
    margin: 0 0 16px 0;
    font-family: PTRootBold;
  }

  .add-user-contact-box {
    background: #ffffff;
    border-radius: 8px;
    padding: 32px;
    border: solid 1px rgba(25, 25, 25, 0.1);

    .button-right {
      text-align: center;
      position: absolute;
      top: 108px;
      right: 32px;
      width: 188px;
      padding: 0 8px !important;
    }

    .add-user-contact-box-top {
      padding-bottom: 15px;
      border-bottom: solid 1px #1919191A;
      position: relative;
      font-size: 20px;
      letter-spacing: -0.6px;

      .title-wrapper {
        display: flex;
        align-items: center;
        margin-bottom: 8px;

        svg {
          margin-right: 4px;
        }
      }

      p {
        font-size: 16px;
        margin: 0;
        color: rgba(25, 25, 25, 0.8);
      }
    }

    .add-user-contact-box-form {
      width: 100%;

      label {
        padding: 24px 0 0;
        font-size: 14px;
        letter-spacing: -0.4px;
        display: block;

        b {
          font-size: 16px;
          line-height: 24px;
          letter-spacing: -0.5px;

          span {
            font-family: PTRootUI, 'sans-serif';
            color: rgba(25, 25, 25, 0.6);
          }
        }
      }

      .alert-email-phone {
        background-color: #FFE5E9;
        box-shadow: 0px 2px 20px rgba(25, 25, 25, 0.18);
        border: 1px solid #FF3F57;
        margin-top: 24px;
        color: #FF3F57;
      }

      .warning-error-red {
        display: flex;
        margin-top: 4px;
        color: #ff3f57;

        svg {
          margin-right: 4px;
          font-size: 12.7px;
          position: relative;
          top: 2.5px;
        }

        b {
          font-size: 12px;
        }
      }

      // .warning-error-red {
      //   color: #ff3f57;
      //   font-size: 12px;
      //   letter-spacing: -0.4px;
      //   margin: 4px 0 0;

      //   svg {
      //       font-size: 14px;
      //       position: relative;
      //       top: -2px;
      //       margin-right: 4px;
      //       color: #ff3f57 !important;
      //       cursor: unset !important;
      //   }
      // }

      .form-contact-fullname,
      .form-contact-alias,
      .form-contact-email,
      .form-contact-custom-field {
        width: 400px !important;
        
        input[type='text'] {
          padding: 12px 16px;
          border-radius: 6px;
          border: solid 1px #1919191A;
          width: 100%;
          font-size: 16px;
          letter-spacing: -0.3px;
          display: block;
          caret-color: #FF7E00;
        }

        input[type='text']:focus {
          border: solid 1px #ff7e00;
        }

        input[type='text']:disabled {
          background-color: rgba(25, 25, 25, 0.1) !important;
          color: rgba(25, 25, 25, 0.4) !important;
        }

        textarea {
          display: block;
          padding: 12px 16px;
          margin: 0;
          width: 100%;
          max-width: 400px;
          height: 96px;
          border-radius: 8px;
          font-size: 16px;
          letter-spacing: -0.3px;
          border: solid 1px #1919191A;
          resize: none;
          line-height: 24px;
          caret-color: #FF7E00;
        }

        textarea:focus {
          border: solid 1px #ff7e00;
        }

        textarea::-webkit-scrollbar {
          width: 12px;
        }
        
        textarea::-webkit-scrollbar-thumb {
          border: 4px solid #00000000;
          border-radius: 8px;
          background-color: #1919194D;
          background-clip: padding-box;
        }
      }

      .form-contact-email {
        input[type='text'] {
          &.error-email {
            &:focus {
              border: 1px solid #FF3F57;
            }
          }
        }
      }

      .form-contact-phonenumber {
        width: 400px !important;

        .input-phonenumber-container {
          padding: 0 16px;
          border-radius: 6px;
          border: solid 1px #1919191A;
          width: 100%;
          font-size: 16px;
          letter-spacing: -0.3px;
          display: flex;
          align-items: center;
          caret-color: #FF7E00;

          input {
            width: 100%;
          }

          input:disabled {
            background: transparent;
          }

          &.error-phone {
            border: 1px solid #FF3F57;
          }

          &.phone-focus {
            border: solid 1px #ff7e00;
          }

          &.phone-disabled {
            background-color: rgba(25, 25, 25, 0.1);
            color: rgba(25, 25, 25, 0.4);
          }

          label {
            padding: 0;
            margin: 0 4px 0 0;
            font-size: 16px;
            position: relative;
            top: 0px;
          }

          .form-phonenumber {
            padding: 0;
            height: 50px;
            border: none;
          }

          .form-phonenumber:disabled {
            color: rgba(25, 25, 25, 0.4) !important;
          }
        }

        .alert-wrapper {
          display: flex;
          margin-top: 4px;

          svg {
            margin-right: 4px;
            font-size: 20px;
            position: relative;
            top: -2px;
          }

          p {
            font-size: 12px;
          }
        }
      }

      .absolute-right-0 {
        bottom: 1px;
      }

      button {
        margin: 20px 0 0;
        
        &.add-contact-button {
          height: 40px;
          margin-top: 24px;
        }

        &.add-contact-button:disabled {
          background-color: rgba(25, 25, 25, 0.1) !important;
          color: rgba(25, 25, 25, 0.4) !important;
        }

        &.loading-button {
          width: 181px;
          height: 40px;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        &.loading-button:disabled {
          background-color: rgba(25, 25, 25, 0.1) !important;
          color: rgba(25, 25, 25, 0.4) !important;
        }

      }

      .contact-custom-field-separator {
        margin-top: 24px;
        width: 100%;
        height: 1px;
        background-color: #1919191A;
      }

      .dropdown {
        max-width: 400px;
  
        button {
          margin-top: 0px;
        }
  
        .dropdown-option-wrapper {
          max-height: none;
          border: none;
        }
      }

      .contact-date-value-wrapper {
        max-width: 400px;
        border-radius: 8px;
        height: 48px;
        width: 100%;
        position: relative;
        padding: 0 48px 0 16px;
        border: solid 1px #1919191A;
        cursor: pointer;
        line-height: 46px;

        &:hover {
          border-color: #ff7e00;
        }

        svg {
          position: absolute;
          right: 16px;
          top: 50%;
          transform: translate(0, -50%);
        }
      }

      .contact-date-value-wrapper.disabled {
        background: #1919191A;
        border: none;
        box-shadow: none;
        cursor: not-allowed;
      }
    }
  }
}
