#page-account-setting {
    position: relative;
}

#page-account-setting .input-wrapper {
    margin-top: 0;
}

#page-account-setting ul.nav-tabs {
    cursor: pointer;
    border-color: #dcdcdc;
}

#page-account-setting ul.nav-tabs li a {
    padding: 0 0 14px;
    margin-right: 32px;
    color: #9b9b9b;
    font-weight: bold;
    font-size: 16px;
    border: none;
}

#page-account-setting ul.nav-tabs li a.active-tabs-account-setting {
    color:#ff7e00;
    border-bottom: solid 4px #ff7e00;
}

#page-account-setting ul.nav-tabs li a:hover {
    color: #8a8a8a;
}