.modal-invitation-sent {
    .modal-content {
        width: 476px;
        border-radius: 8px;

        .modal-body {
            padding: 32px 24px;
            text-align: center;

            img {
                margin: 0 0 8px 0;
            }

            b {
                font-size: 20px;
            }

            p {
                margin: 8px 0 20px;
                font-size: 14px;
                letter-spacing: 21px;
                letter-spacing: -.2px;
            }
            
            button {
                height: 40px;
                width: 100%;
            }
        }
    }
}

.create-role-wrapper {
    .form-title-wrapper {
        position: relative;

        button {
            position: absolute;
            right: 0;
            top: -7px;
            width: 180px;
        }
    }
    
    label {
        width: 400px;
        font-size: 16px;
        letter-spacing: -0.5px;
    }

    input[type="text"], textarea {
        width: 400px;
    }
    
    .form-roles-create {
        button {
            width: inherit;
        }
    }

    .role-info {
        font-size: 14px;
        letter-spacing: -0.2px;
        color: rgba(25, 25, 25, 0.8);
        margin: 0 0 2px;

        &.role-info-24 {
            margin-bottom: 24px;
        }
    }

    .role-list-wrapper {
        margin-top: 0;
        width: 100%;

        label {
            margin: 0 0 16px;
            width: unset;
        }

        [type="checkbox"]:not(:checked) + label:before {
            top: 4px;
        }

        [type="checkbox"]:checked + label:before {
            top: 4px;
        }

        [type="checkbox"]:checked + label:after {
            top: -1px;
        }

        [type="checkbox"]:checked:disabled + label:after {
            background-size: 22px 22px !important;
            top: -1px !important;
            left: -3px !important;
        }
    }

    .main-role {
        padding: 0 0 0 8px;
        
        label {
            padding-left: 28px !important;
            display: inline-block !important;
        }
    }


    .custom-checkbox-child-wrapper {
        padding: 0 0 0 36px;

        .custom-checkbox-child {
            padding: 8px;
            border-radius: 6px;
            background: #fafafa;
            display: inline-block;
            min-width: 180px;
            margin: 0 8px 16px 0;
    
            label {
                margin: 0;
                width: unset;
                font-size: 14px;
                letter-spacing: -0.2px;
            }

            [type="checkbox"]:not(:checked) + label:before {
                top: 2px;
            }
    
            [type="checkbox"]:checked + label:after {
                top: -3px;
            }

            [type="checkbox"]:checked + label:before {
                top: 2px;
            }
        }
    }
}