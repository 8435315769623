.inbox-settings {
    .button-save {
        margin-top: 28px;
    }

    .status-wrapper {
        font-size: 16px;

        .status-value {
          color:rgba(25, 25, 25, 0.4);
          font-size: 16px;
          letter-spacing: -0.5px;

          &.status-enabled {
            color: #19C700;
          }
        }

        img {
          position: relative;
          top: -2px;
          margin-right: 4px;
        }
    }

    .needreply-settings-header {
        font-size: 20px;
        letter-spacing: -0.6px;
        color: #191919;
        padding-bottom: 24px;
        border-bottom: solid 1px #dcdcdc;
        position: relative;

        button {
            position: absolute;
            top: 0;
            right: 0;
        }
    }

    .needreply-settings-form {
        padding-top: 24px;
        position: relative;

        .custom-select-need-reply-config {
            position: absolute;
            top: 6px;
            right: 0;
            width: 143px;

            button {
                margin: 0;
            }

            .dropdown {
                .custom-select-option-value-wrapper {
                    padding: 8px 41px 8px 16px !important; 
                }

                p {
                    margin: 0;
                    padding: 0;
                }

                svg {
                    font-size: 20px;
                }

                .dropdown-option-wrapper {
                    min-width: unset;
                }
            }
        }

        .needreply-settings-form-content {
            display: block;
            width: 100%;
            font-size: 16px;
            letter-spacing: -0.5px;
            margin-bottom: 8px;
            position: relative;
            padding-right: 158px;

            &.content-need-reply {
                margin: 0;
            }

            .need-reply-val {
                letter-spacing: -0.5px;
                font-size: 16px;
                color: rgba(25, 25, 25, 0.6);
            }

            p {
                margin: 4px 0 10px;
                font-size: 14px;
                letter-spacing: -0.2px;
                color: rgba(25, 25, 25, 0.8);
            }

            textarea {
                width: 100%;
                border-radius: 8px;
                font-size: 16px;
                letter-spacing: -0.3px;
                border: solid 1px #dcdcdc;
                resize: none;
                padding: 12px 16px;
                line-height: 24px;
                margin: 0;
                max-width: 416px;
                width: 100%;
                display: block;

                &:focus {
                    border-color: #ff7d00;
                }

                &[disabled] {
                    color: rgba(25, 25, 25, 0.4);
                    background: rgba(25, 25, 25, 0.1);
                }
            }
        }
    }

    .need-reply-color-picker-wrapper {
        border: solid 1px rgba(25, 25, 25, 0.1);
        border-radius: 8px;
        padding: 7px 16px;
        width: 220px;
        position: relative;
        display: inline-block;
        margin: 0 0 8px 0;

        .input-color {
          width: calc(100% - 128px) !important;
          border: 0 !important;
          padding: 0 !important;
          font-family: PTRootUI !important;
          position: absolute !important;
          left: 26px;
          top: 50%;
          transform: translate(0, -50%);
          margin: 0;
        }

        .need-reply-badge {
            position: absolute;
            right: 16px;
            top: 50%;
            transform: translate(0, -50%);
            font-size: 12px;
            border-radius: 100px;
            padding: 3px 8px;
            background: rgba(255, 63, 87, 1);
            color: #fff;
        }

        .color-picker-container {
            background: #ffffff;
            position: absolute;
            bottom: 0;
            right: -265px;
            border-radius: 4px;
            box-shadow: 0 2px 20px 0 rgba(25, 25, 25, 0.18) !important;
            padding: 14px;
            z-index: 9999;

            .close-color-picker {
              position: absolute;
              top: 6px;
              font-size: 14px;
              right: 6px;
              color: rgba(25, 25, 25, 0.6);

              &:hover {
                color: rgba(25, 25, 25, 0.8);
              }
            }

            &::before {
              position: absolute;
              background: #ffffff;
              width: 16px;
              height: 16px;
              content: '';
              left: -8px;
              transform: rotate(45deg);
              bottom: 17px;
            }

            .sketch-picker {
              border-radius: 4px;
              box-shadow: none !important;

              .flexbox-fix:nth-child(2) {
                height: 28px;

                div:nth-child(1) {
                  div:nth-child(1) {
                    height: 23px !important  ;
                    div {
                      div.hue-horizontal {
                        div {
                          div {
                            display: block !important;
                            margin-top: 0 !important;
                          }
                        }
                      }
                    }
                  }

                  div:nth-child(2) {
                    div {
                      display: none;
                    }
                  }
                }
              }

              .flexbox-fix {
                &:nth-child(3) {
                  display: none !important;
                }

                &:nth-child(4) {
                  div {
                    div {
                      border-radius: 50% !important;
                    }
                  }
                }
              }
            }
        }
    }

    .flexbox-fix {
        &:nth-child(3) {
          display: none !important;
        }
    }

    .need-reply-color-config-wrapper {
        width: 100%;
        border-collapse: collapse;
        display: block;
        position: relative;

        .need-reply-color-config-label {
            margin-top: 4px;
        }

        .need-reply-color-picker-desc {
            line-height: 1;
            font-size: 14px;
            letter-spacing: -0.2px;
        }

        .change-color-wrapper {
            display: block;
            width: 100%;
            position: relative;
        }

        .title-after {
            font-size: 14px;
            display: inline-block;
            letter-spacing: -0.2px;
            margin: 0 6px;
        }

        input[type="text"] {
            border: solid 1px rgba(25, 25, 25, 0.1);
            border-radius: 8px;
            padding: 7px 16px;
            width: 220px;
            position: relative;
            display: inline-block;
            margin: 0 0 8px 0;
            font-family: PTRootBold;
            width: 62px;
        }

        .custom-select-time-config {
            width: 118px;
            display: inline-block;
            margin-left: 4px;

            .custom-select-option-value-wrapper {
                padding: 7px 16px 7px 8px !important;
                position: relative;
                top: -2px; 
                font-family: PTRootBold;
                color: #191919;
                font-size: 16px;
                letter-spacing: -0.5px;
            }

            svg {
                font-size: 12px;
                right: 8px;
            }

            .dropdown-option-wrapper {
                min-width: unset;
            }
        }
    }
}

.modal-confirmation-need-reply {
  .modal-footer {
    button {
      width: calc(50% - 2px) !important;

      &:nth-child(2) {
        margin: 0;
      }
    }
  }
}