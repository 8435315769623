.terms-and-condition-modal {
    .modal-content {
        width: 600px;
        border-radius: 8px;
    }

    .modal-body {
        padding: 32px;

        p {
            font-size: 20px;
            letter-spacing: -0.6px;

            svg {
                position: relative;
                top: -2px;
                margin-right: 4px;
            }
            
            &.title-waba {
                margin: 0;
            }
        }

        span {
            display: block;
            margin-bottom: 20px;
        }

        ol {
            margin: 0 0 12px 0;
            padding: 0 0 0 18px;

            li {
                font-size: 14px;
                line-height: 21px;
                letter-spacing: -0.2px;
                padding: 0 0 12px 7px;
                color: #000000;
            }
        }

        span {
            font-size: 14px;
            letter-spacing: -0.2px;
            line-height: 21px;
            color: rgba(25, 25, 25, .6);
        }

        button {
            width: calc(50% - 4px);
            margin: 0 8px 0 0;
            // height: 48px;

            &:nth-last-child(1) {
                margin: 0;
            }

            img {
                width: 22px;
            }
            // .lds-ring {
            //     top: 3px;
            //     right: 0;

            //     div {
            //         margin: 0;
            //         border-color: #ffffff transparent transparent transparent;
            //     }
            // }
        }
    }
}