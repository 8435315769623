.form-step {
    position: relative;
    white-space: nowrap;
    font-size: 16px;
    letter-spacing: -0.5px;
    color: rgba(25,25,25,0.4);
    overflow: hidden;
    width: 100%;

    .form-step-content {
        display: inline-block;
        
        &.active-form-step {
            color: #191919;
        }

        .value-number {
            border: 2px solid #E87402;
            background: #ff7e00;
            width: 24px;
            height: 24px;
            display: inline-block;
            border-radius: 50%;
            position: relative;
            margin-right: 4px;
            color: #ffffff;
            vertical-align: top;
            text-align: center;
            font-size: 13px;
            line-height: 19px;
            letter-spacing: 0;

            &::before {
                position: absolute;
                width: 26px;
                height: 26px;
                border: solid 2px #FFF2E5;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                content: "";
                border-radius: 50%;
            }

            &.not-active-value-wrapper {
                border: solid 2px #e8e8e8;
                background: #ffffff;
                color: rgba(25,25,25,0.4);

                &::before {
                    border: 0 !important;
                }
            }

            &.value-check {
                background: #e8e8e8;
                color: #959595;
                border: solid 2px #e8e8e8;

                &::before {
                    border: 0 !important;
                }
            }
        }
    }

    .line-separator {
        width: 30px;
        height: 2px;
        background: rgba(25,25,25,0.05);
        margin: 0 12px 0;
        display: inline-block;
        position: relative;
        top: -4px;
    }
}