.case-report-wrapper {
    // min-height: 500px;

    .case-report-title {
        font-size: 20px;
        letter-spacing: -0.6px;
        margin: 0 0 16px 0;
        border-bottom: solid 1px #dcdcdc;
        color: #191919;
        padding: 8px 0 12px;
    }

    .case-report-description {
        margin: 8px 0 16px;
        font-size: 16px;
        letter-spacing: -0.3px;
        width: calc(100% - 230px);
        color: #191919;
        line-height: 24px;
    }

    .add-case-report-button {
        // height: 40px;
        // line-height: 23px;
        // padding: 0;
        // width: 195px;
        text-align: center;
        position: absolute;
        top: 32px;
        right: 32px;

        // svg {
        //     vertical-align: top;
        //     font-size: 24px;
        //     position: relative;
        //     top: -1px;
        //     margin-right: 4px;
        // }
    }

    .case-report-data-outer-wrapper {
        position: relative;
        // padding: 12px 0 0;
    } 

    .case-report-data-wrapper {
        width: 100%;
        color: #191919;
        overflow-x: auto;
        min-height: 200px;

        .no-case-report {
            width: 100%;
            text-align: center;
            font-size: 20px;
            letter-spacing: -0.6px;
            color: rgba(25, 25, 25, 0.6);
        }

        table {
            width: 100%;
            min-width: 1480px;

            thead {
                font-size: 14px;
                border-bottom: solid 1px #dcdcdc;
                color: #191919;
                letter-spacing: -0.4px;

                td {
                    padding: 0 8px 8px;
                    white-space: nowrap;

                    &:last-child {
                        width: 84px;
                    }
                }

                .col-no-sort {
                    padding: 0 8px;
                }
            }

            tbody {
                font-size: 14px;
                letter-spacing: -0.2px;
                color: #191919;

                tr {
                    border-bottom: solid 1px #dcdcdc;
                }

                td {
                    padding: 16px 8px;

                    &.col_1 {
                        white-space: nowrap;

                        img {
                            margin-right: 4px;
                            width: 24px;
                            position: relative;
                            top: -1px;
                        }
                    }

                    &.col_2 {
                        min-width: 120px;
                    }

                    &.col_4, &.col_9 {
                        min-width: 220px;
                    }

                    &.col_5 {
                        min-width: 190px;
                    }

                    &.col_action {
                        font-size: 14px;
                        letter-spacing: -0.4px;

                        .edit-button {
                            color: #ff7d00;
                            cursor: pointer;
                            margin-right: 16px;
                        }

                        .remove-button {
                            color: #ff3f57;
                            cursor: pointer;
                        }

                        svg {
                            font-size: 16px;
                            position: relative;
                            top: -2px;
                            margin-right: 4px;
                        }
                    }

                    .case-report-label {
                        border-radius: 4px;
                        padding: 3px 12px;
                        white-space: nowrap;
                        color: #ffffff;
                        background: rgba(156, 136, 241, 1);
                        font-size: 12px;
                        letter-spacing: -0.4px;
                        display: inline-block;
                        margin: 0 4px 4px 0;
                        max-width: 200px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }

                    .case-report-status {
                        border-radius: 4px;
                        padding: 3px 12px;
                        white-space: nowrap;
                        max-width: 100px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        color: #ffffff;
                        background: rgba(156, 136, 241, 1);
                        font-size: 12px;
                        letter-spacing: -0.4px;
                        display: inline-block;

                        &.ongoing-case {
                            color: #ffffff;
                            background: #19C700;
                        }

                        &.open-case {
                            color: #ffffff;
                            background: #C3C8D0;
                        }

                        &.resolved-case {
                            color: rgba(25, 25, 25, 0.6);
                            background: rgba(25, 25, 25, 0.05);
                        }

                        svg {
                            position: relative;
                            margin-right: 4px;
                            top: -1px;
                        }
                    }
                }
            }
        }
    }
}