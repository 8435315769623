.topic-report-wrapper {
    // min-height: 500px;

    .topic-report-title {
        font-size: 20px;
        letter-spacing: -0.6px;
        margin: 0 0 16px 0;
        color: #191919;
        padding: 8px 0 12px;
        border-bottom: solid 1px #dcdcdc;
    }

    .topic-report-description {
        margin: 8px 0 16px;
        font-size: 16px;
        letter-spacing: -0.3px;
        width: calc(100% - 230px);
        color: #191919;
        line-height: 24px;
    }

    .topic-report-data-outer-wrapper {
        padding: 12px 0 0;
    } 

    .topic-report-data-wrapper {
        width: 100%;
        color: #191919;
        overflow-x: auto;

        .no-topic-report {
            width: 100%;
            text-align: center;
            font-size: 20px;
            letter-spacing: -0.6px;
            color: rgba(25, 25, 25, 0.6);
        }

        table {
            width: 100%;
            min-width: 1480px;

            thead {
                font-size: 14px;
                border-bottom: solid 1px #dcdcdc;
                color: #191919;
                letter-spacing: -0.4px;

                td {
                    padding: 0 8px 8px;
                    white-space: nowrap;
                }
            }

            tbody {
                font-size: 14px;
                letter-spacing: -0.2px;
                color: #191919;

                tr {
                    border-bottom: solid 1px #dcdcdc;
                }

                td {
                    padding: 16px 8px;
                    max-width: 130px;

                    &.col_action {
                        font-size: 14px;
                        letter-spacing: -0.4px;

                        .edit-button {
                            color: #ff7d00;
                            cursor: pointer;
                            margin-right: 16px;
                        }

                        .remove-button {
                            color: #ff3f57;
                            cursor: pointer;
                        }

                        svg {
                            font-size: 16px;
                            position: relative;
                            top: -2px;
                            margin-right: 4px;
                        }
                    }
                }
            }
            
            td {
                &.with-max-width {
                    white-space: break-spaces;
                    max-width: 130px;
                }
            }
        }
    }
}