.integration-whatsapp-business-api-add-account {
    padding: 32px 30px;
    text-align: left;

    .comp-back-button {
        margin: 0 0 24px 0;
    }

    .integration-whatsapp-business-api-add-account-box-top {
        padding-bottom: 12px;
        border-bottom: solid 1px #dcdcdc;
        position: relative;
        font-size: 20px;
        line-height: 24px;
        letter-spacing: -0.6px;
        color: #191919;
        margin-bottom: 24px;

        p {
            letter-spacing: -0.2px;
            margin: 0;
            font-size: 14px;
            color: rgba(25, 25, 25, 0.8);
        }
    }

    .integration-whatsapp-business-api-add-account-box-form {
        background: #ffffff;
        border-radius: 8px;
        padding: 32px;
        border: solid 1px rgba(25, 25, 25, 0.1);

        .integration-whatsapp-business-api-add-account-box-form-top {
            width: 100%;
            border-bottom: solid 1px #dcdcdc;
            position: relative;
            padding-bottom: 16px;

            .whatsapp-business-form-step {
                position: relative;
                white-space: nowrap;
                font-size: 16px;
                letter-spacing: -0.5px;
                color: rgba(25,25,25,0.4);
                overflow: hidden;
                max-width: calc(100% - 124px);

                .whatsapp-business-form-step-content {
                    display: inline-block;
                    
                    &.active-whatsapp-business-form-step {
                        color: #191919;
                    }

                    .value-number {
                        border: 2px solid #E87402;
                        background: #ff7e00;
                        width: 24px;
                        height: 24px;
                        display: inline-block;
                        border-radius: 50%;
                        position: relative;
                        margin-right: 4px;
                        color: #ffffff;
                        vertical-align: top;
                        text-align: center;
                        font-size: 13px;
                        line-height: 19px;
                        letter-spacing: 0;

                        &.not-active-value-wrapper {
                            border: solid 2px #e8e8e8;
                            background: #ffffff;
                            color: rgba(25,25,25,0.4);
                        }

                        &.value-check {
                            background: #e8e8e8;
                            color: #959595;
                            border: solid 2px #e8e8e8;
                        }
                    }
                }

                .line-separator {
                    width: 30px;
                    height: 2px;
                    background: rgba(25,25,25,0.05);
                    margin: 0 12px 0;
                    display: inline-block;
                    position: relative;
                    top: -4px;
                }
            }

            .integration-whatsapp-business-api-add-account-box-form-top-lang {
                position: absolute;
                right: 0;
                top: -3px;
                white-space: nowrap;

                .lang-content {
                    border: solid 1px #dcdcdc;
                    background: #ffffff;
                    color: pointer;
                    text-transform: uppercase;
                    font-size: 12px;
                    letter-spacing: -0.4px;
                    color: rgba(25,25,25,0.6);
                    border-radius: 4px;
                    margin-right: 4px;
                    display: inline-block;
                    padding: 1px 12px 1px 8px;
                    cursor: pointer;
                    transition: ease-in-out .1s;

                    img {
                        margin-right: 2px;
                        position: relative;
                        top: 1px;
                    }

                    &:hover {
                        background: #ff7e00;
                        border: solid 1px #ff7e00;
                        color: #ffffff;
                    }

                    &:nth-last-child(1) {
                        margin: 0;
                    }

                    &.active-lang-content {
                        background: #ff7e00;
                        border: solid 1px #ff7e00;
                        color: #ffffff;
                    }
                }
            }
        }

        .integration-whatsapp-business-api-add-account-box-form-tab {
            width: 100%;
            position: relative;

            .integration-whatsapp-business-tab-content {
                background: #ffffff;
                // display: none;

                &.active-tab {
                    display: block;
                }
            }
        }
            
        // label {
        //     // padding: 24px 0 0;
        //     margin-top: 24px;
        //     font-size: 16px;
        //     line-height: 24px;
        //     letter-spacing: -0.5px;
        //     display: block;
        // }

        .tooltip-toggle {
            position: relative;
            top: -1px;
            display: inline-block;
            margin-left: 4px;
        }

        .tooltip-link {
            color: #ff7e00;
            cursor: pointer;

            &:hover {
                text-decoration: underline !important;
            }
        }

        input[type="text"] {
            padding: 12px 16px;
            border-radius: 6px;
            border: solid 1px #dcdcdc;
            width: 100%;
            font-size: 16px;
            letter-spacing: -0.3px;
            display: block;
            margin-bottom: 8px;
        }

        .custom-radio {
            label {
                color: rgba(25, 25, 25, 0.8);
                font-size: 14px;
                letter-spacing: -0.4px;
                display: inline-block;
                margin-top: 0;
            }
        }
    }
}

.phone-input-wrapper {
    position: relative;
    // background: pink;
    width: 300px;
    display: inline-block;

    .area-code-wrapper {
        min-width: 135px;
        margin-right: 4px;
    }

    .country-search-wrapper {
        input {
            margin: 0;
            width: 100% !important;
        }
    }

    .country-list-select {
        max-height: 148px !important;

        &.no-option {
            border: none !important;
        }
    }

    button {
        margin: 0;
        height: 48px !important;
        vertical-align: top;
    }

    .phone-input {
        display: inline-block !important;
        vertical-align: top;
        width: calc(100% - 139px) !important;
    }
}