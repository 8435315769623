.integration-whatsapp-secure-authenticate {
    padding: 32px 30px;
    text-align: left;

    .integration-whatsapp-secure-authenticate-box-top {
        padding-bottom: 20px;
    }

    .comp-back-button {
        margin: 0 0 16px 0;
    }

    // .lds-ring {
    //     width: 50px;
    //     height: 50px;
    //     position: absolute;
    //     left: 50%;
    //     top: 50%;
    //     transform: translate(-50%, -50%);
    //     right: unset;
        
    //     div {
    //         border: 5px solid #fff;
    //         border-color: #ff7d00 transparent transparent transparent;
    //         width: 50px;
    //         height: 50px;
    //         margin: 0;
    //     }
    // }

    .integration-whatsapp-secure-authenticate-box {
        background: #ffffff;
        border-radius: 8px;
        padding: 32px;
        border: solid 1px rgba(25, 25, 25, 0.1);
        min-height: 400px;
        position: relative;

        .main-loading-wrapper {
            width: 100%;
            text-align: center;
            padding-top: 40px;

        }

        .alert-component {
            margin: 0 12px 0 0;
            // width: calc(100% - 300px);
            width: 100%;
            padding: 16px;

            svg {
                position: relative;
                top: -2px;
                transform: unset;
                left: unset;
                margin-right: 8px;
            }
        }

        .button-refresh {
            width: 185px;
            display: inline-block;
            height: 48px;
            letter-spacing: -0.5px;
            font-size: 16px;

            svg {
                font-size: 20px;
                margin-right: 4px;
                position: relative;
                top: -1px;
            }
        }

        .payment-step-list {
            position: relative;
            margin: 22px 0 0;
            color: #044b84;
            padding: 0 0 0 33px;
            letter-spacing: -0.3px;

            p {
                margin: 0;
            }

            .bank-account-wrapper {
                border-radius: 8px;
                font-size: 16px;
                letter-spacing: -0.5px;
                padding: 24px 24px 16px;
                color: #044b84;
                background: #ffffff;
                display: inline-block;
                margin-top: 14px;
            }
        }

        .payment-step-counter {
            border-radius: 50%;
            color: #ffffff;
            background: #044b84;
            width: 23px;
            height: 23px;
            text-align: center;
            line-height: 23px;
            display: inline-block;
            font-size: 14px;
            letter-spacing: -0.4px;
            position: absolute;
            top: 0;
            left: 0;
        }
    }

    .integration-whatsapp-secure-authenticate-box {
        width: 100%;
        position: relative;
        padding-top: 24px;

        .iframe-space {
            width: 100%;
            margin-top: 24px;
            background: #E0E0E0;
            min-height: 500px;
            
            iframe {
                width: 100%;
                border: none;
                min-height: 500px;
            }
        }
    }
}