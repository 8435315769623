.room-list-global-search-wrapper {
    width: calc(100% - 62px);
    height: 100%;
    position: absolute;
    transition: ease-in-out .2s;
    top: 0;
    left: -100%;
    z-index: 4;
    overflow: hidden;

    .case-room-filter-tag-badge-active {
        background: #ff7d00;
        color: #ffffff !important;
        border: solid 1px #ff7d00;

        svg {
            color: #fff !important;
        }

        &:hover {
            background: #e87200;
            border: solid 1px #e87200;
        }
    }

    .submit-search {
        background: #ffffff;
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
        border: solid 1px rgba(25, 25, 25, 0.1);
        position: absolute;
        top: 0px;
        right: 28px;
        height: 36px;
        width: 28px;
        text-align: center;
        -webkit-transition: none;
        transition: none;
        font-size: 14px;
        cursor: pointer;

        svg {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }

    &.room-list-global-search-wrapper-active {
        left: 62px;
    }

    .room-list-global-search-header {
        height: 90px;
        width: 100%;
        position: relative;
        background: #ffffff;
        padding: 8px 12px;
        border-bottom: solid 1px #dcdcdc;

        .case-room-search-wrapper {
            padding: 0;
            transition: ease-in-out .15s;
            // position: absolute;
            // top: 0;
            // left: 0;
            width: 100%;
            height: unset;

            .clear-search-wrapper {
                width: 16px;
                height: 16px;
                border-radius: 50%;
                position: absolute;
                top: 50%;
                right: 58px;
                transform: translate(0, -50%);
                background: #a3a3a3;
                cursor: pointer;

                &:hover {
                    background: #8c8c8c;
                }

                svg {
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    color: #ffffff;
                    font-size: 12px;
                    position: absolute;
                }
            }

            #input-search-form {
                text-align: left;

                input[type="text"] {
                    padding: 9px 11px 9px 11px;
                    width: calc(100% - 49px);
                    margin-bottom: 12px;

                    &::placeholder {
                        font-size: 12px;
                        color: rgba(25, 25, 25, 0.4);
                    }

                    &.active-search {
                        padding: 9px 31px 9px 11px;
                    }

                    &:focus {
                        padding: 9px 32px 9px 11px;

                        &~.submit-search {
                            background: #ff7d00;
                            color: #ffffff;
                        }

                        // & ~ .clear-search-x {
                        //     display: block;
                        // }
                    }
                }

                .clear-search-x {
                    cursor: pointer;
                    border-radius: 50%;
                    position: absolute;
                    top: 10px;
                    right: 60px;
                    width: 16px;
                    height: 16px;
                    background: #a3a3a3;
                    color: #ffffff;
                    z-index: -1;
                    // display: none;

                    &.active-clear-search-x {
                        z-index: unset;
                    }

                    svg {
                        font-size: 12px;
                        position: absolute;
                        left: 50%;
                        top: 50%;
                        transform: translate(-50%, -50%);
                    }

                    &:hover {
                        background: #999999;
                    }
                }

                .cancel-search {
                    font-size: 14px;
                    letter-spacing: -0.4px;
                    color: #ff7d00;
                    cursor: pointer;
                    margin-left: 8px;

                    &:hover {
                        color: var(--main-darker-orange);
                    }
                }
            }


            .submit-filter-button {
                right: 0;
            }
        }

        .room-list-global-search-header-bottom {
            .reset-button {
                background: #ff7d00;
                border-color: #ff7d00;
                color: #ffffff;

                &:hover {
                    border-color: #e87200;
                    background: #e87200;
                }
            }
        }
    }
}