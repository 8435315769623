.form-image-message-wrapper-new {
    position: relative;

    input[type="file"] {
        display: none;
    }

    label {
        display: inline-block !important;

        span {
            color: rgba(25, 25, 25, 0.6);
        }

        &.button-upload-image {
            margin: 0 8px 0 0;
            color: rgba(25, 25, 25, 0.6);
            border: solid 1px rgba(25, 25, 25, 0.1);
            letter-spacing: -0.4px;
            font-size: 12px;
            border-radius: 4px;
            cursor: pointer;
            display: inline-block !important;
            padding: 2px 8px;
            background: #ffffff;
            -webkit-transition: ease-in-out .1s;
            transition: ease-in-out .1s;

            &:hover {
                color: #ffffff;
                border: solid 1px #ff7d00;
                background: #ff7d00;
            }

            svg {
                position: relative;
                top: -1px;
                margin-right: 4px;
            }
        }

        &.button-add-var {
            margin: 0 8px 0 0;
            color: rgba(25, 25, 25, 0.6);
            border: solid 1px rgba(25, 25, 25, 0.1);
            letter-spacing: -0.4px;
            font-size: 12px;
            border-radius: 4px;
            cursor: pointer;
            display: inline-block !important;
            padding: 2px 8px;
            background: #ffffff;
            -webkit-transition: ease-in-out .1s;
            transition: ease-in-out .1s;

            &:hover {
                color: #ffffff;
                border: solid 1px #ff7d00;
                background: #ff7d00;
            }

            svg {
                position: relative;
                top: -1px;
                margin-right: 4px;
            }
        }
    }

    .image-and-caption-wrapper {
        position: relative;
        width: 494px;
        // width: 100%;
        max-width: 100%;
        font-size: 0;

        .broadcast-message-action-wrapper {
            position: absolute;
            height: 48px;
            border: solid 1px #dcdcdc;
            border-bottom-left-radius: 8px;
            border-bottom-right-radius: 8px;
            background: #ffffff;
            bottom: 0;
            left: 0;
            font-size: 12px;
            letter-spacing: -0.4px;
            display: block;
            width: 100%;
            padding: 12px 16px;
            display: inline-block;
            max-width: 100%;
            white-space: nowrap;
            overflow: hidden;

            &::before {
                content: "";
                height: 48px;
                left: 134px;
                width: 1px;
                border-left: solid 1px #dcdcdc;
                position: absolute;
                top: 0;
            }

            .broadcast-message-action-inner-wrapper {
                position: absolute;
                top: 12px;
                left: 134px;
                width: calc(100% - 134px);
                max-width: 100%;
                height: 52px;
                padding: 0 16px;
                overflow-x: auto;
                overflow-y: hidden;
            }

            .add-variable-title {
                font-size: 12px;
                letter-spacing: -0.4px;
    
                svg {
                    position: relative;
                    top: -1px;
                    margin-right: 13px;
                    outline: none;
                }
            }

            .add-variable-panel-wrapper {
                position: absolute;
                left: 128px;
                bottom: 37px;
                background: #ffffff;
                border-radius: 8px;
                color: #191919;
                box-shadow: 0px 2px 20px rgba(25, 25, 25, 0.18);
                padding: 16px 24px;
                width:350px;
                display: inline-block;
                text-align: left;
                z-index: 1;

                .button-close {
                    color:rgba(25, 25, 25, 0.6);
                    cursor: pointer;
                    position: absolute;
                    top: 16px;
                    font-size: 18px;
                    right: 24px;

                    &:hover {
                        color: rgba(25, 25, 25, 1);
                    }
                }

                .button-variable {
                    margin: 10px 8px 0 0;
                    color: rgba(25, 25, 25, 0.6);
                    border: solid 1px rgba(25, 25, 25, 0.1);
                    letter-spacing: -0.4px;
                    font-size: 12px;
                    border-radius: 4px;
                    cursor: pointer;
                    display: inline-block !important;
                    padding: 2px 8px;
                    background: #ffffff;
                    -webkit-transition: ease-in-out .1s;
                    transition: ease-in-out .1s;
                    display: inline-block;
                    width: unset;
                    vertical-align: top;

                    &:hover {
                        color: #ffffff;
                        border: solid 1px #ff7d00;
                        background: #ff7d00;
                    }
                }

                .add-variable-panel-title {
                    font-size: 14px;
                    letter-spacing: -0.4px;
                    background: #ffffff;
                }

                .variable-input {
                    position: relative;
                    margin-top: 9px;

                    input {
                        border-radius: 8px !important;
                        padding-right: 111px;

                        &:focus {
                            border: solid 1px #ff7e00;
                        }
                    }

                    textarea {
                        border-radius: 8px !important;
                    }

                    button {
                        position: absolute;
                        font-size: 12px;
                        letter-spacing: 2.5px;
                        height: 24px;
                        display: inline-block;
                        border-radius: 4px;
                        width: unset;
                        padding: 0 12px 0 10px;
                        right: 16px;
                        top: 12px;
                        margin: 0;

                        svg {
                            margin-right: 4px;
                            position: relative;
                            top: -1px;
                            font-size: 14px;
                        }
                    }
                }

                ul {
                    list-style: none;
                    border-radius: 8px;
                    background: rgba(25,25,25,0.05);
                    margin: 12px 0 0;
                    padding: 8px 0 0;

                    li {
                        font-size: 14px;
                        letter-spacing: -0.4px;
                        margin: 0;
                        padding: 0 16px 8px 16px;
                    }
                }
            }
        }
        
        .image-preview-wrapper {
            background: #ffffff;
            position: absolute;
            top: 1px;
            left: 16px;
            padding: 11px 0 8px 0;
            border-bottom: solid 1px rgba(25, 25, 25, 0.1);
            width: calc(100% - 32px);

            &.image-preview-wrapper-grey {
                background: #e8e8e8;
            }

            svg {
                font-size: 64px;
                position: relative;
                left: -5px;
                color: #9b9b9b;
            }

            .image-preview-frame {
                border: solid 1px rgba(25, 25, 25, 0.1);
                width: 64px;
                height: 64px;
                display: inline-block;
                position: relative;
                border-radius: 8px;
                overflow: hidden;

                img {
                    max-width: 100%;
                    max-height: 100%;
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                }

                .progress-wrapper {
                    position: absolute;
                    top: 0;
                    left: 0;
                    background: rgba(25, 25, 25, 0.4);
                    width: 100%;
                    height: 100%;

                    .CircularProgressbar {
                        width: calc(100% - 12px);
                        height: calc(100% - 12px);
                        position: absolute;
                        top: 6px;
                        left: 6px;

                        .CircularProgressbar-trail {
                            stroke: transparent;
                            stroke-width: 20;
                        }
                        
                        .CircularProgressbar-path {
                            stroke: #ffffff;
                        }
                    } 
                }

                .image-button-wrapper {
                    position: absolute;
                    top: 0;
                    left: 0;
                    background: rgba(25, 25, 25, 0.4);
                    width: 100%;
                    height: 100%;
                    display: none;

                    .close-button-wrapper {
                        width: 16px;
                        height: 16px;
                        border-radius: 50%;
                        background: #636462;
                        text-align: center;
                        font-size: 7px;
                        color: #ffffff;
                        cursor: pointer;
                        position: absolute;
                        top: 4px;
                        right: 4px;

                        &:hover {
                            background: #ff3f57;
                        }
                    }

                    svg {
                        &.preview-button {
                            font-size: 20px;
                            color: #ffffff;
                            cursor: pointer;
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                        }   

                        &.close-button {
                            color: #ffffff;
                            font-size: 12px;
                            position: absolute;
                            left: 50%;
                            top: 50%;
                            transform: translate(-50%, -50%);
                        }
                    }
                }
                
                &:hover {
                    .image-button-wrapper {
                        display: inline-block;
                    }
                }
            }
        }

        textarea {
            padding: 92px 16px 60px 16px;
            height: 220px !important;

            &[disabled] {
                background: #e8e8e8;
                cursor: not-allowed;

                padding: 12px 16px;
                height: 138px !important;
            }

            &.text-area-default {
                padding: 16px 16px 60px 16px;
                height: 150px !important;
            }
        }
    }
}

.modal-image-preview {
    width: 100% !important;

    .modal-content {
        width: 100%;
        height: 100%;
        background: unset;

        .modal-body {
            padding: 56px 86px;
            position: relative;

            img {
                max-width: calc(100% - 112px);
                max-height: calc(100% - 172px);
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                background: #ffffff;
            }

            svg {
                font-size: 24px;
                color: #ffffff;
                cursor: pointer;
                position: absolute;
                top: 44px;
                right: 44px;
            }
        }
    }
}