.modal-confirmation-delete-path {
  button {
    width: calc(50% - 4px) !important;
    margin-right: 16px;

    &:nth-last-child(1) {
      margin-right: 0;
    }
  }

  // .modal-footer {
  //   padding: 0;
  // }
}

.view-chatbot-detail {
  .grey-font-6 {
    margin: 0;
  }

  .flexing {
    display: flex;
    margin: 4px 0 0 !important;
  }
  .view-chatbot-detail-top {
    padding: 10px 0 16px 0;
    border-bottom: solid 1px #dcdcdc;
    margin-bottom: 24px;
    position: relative;

    .subtitle-chatbot {
      color:rgba(25, 25, 25, 0.8);
      font-size: 14px;
      letter-spacing: -0.2px;
      margin: 0 0 0;
      
      a {
        color: #ff7d00;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    button {
      position: absolute;
      right: 0;
      top: 0px;
      width: 180px;

      b {
        color: #fff !important;
        font-size: 16px !important;
        letter-spacing: -0.5px !important;
      }

      &.edit-button {
        b {
          color: #ff7d00 !important;
        }
      }

      &.edit-top {

        b {
          color: #ff7d00 !important;
          font-size: 16px !important;
          letter-spacing: -0.5px !important;
        }
  
        &.edit-button {
          b {
            color: var(--main-darker-orange) !important;
          }
        }
      }

      &.view-detail-top {
        b {
          color: var(--main-dark-orange) !important;
        }
      }

      &.left-button {
        right: 188px;
      }
    }

    b {
      font-size: 20px !important;
      letter-spacing: -0.6px;
      font-weight: 700;
    }

    .button-export {
      position: absolute;
      right: 0;
      bottom: 14px;

      b {
        color: #ff7d00 !important;
        font-size: 16px !important;
      }
    }

    .button-delete {
      position: absolute;
      right: 0;
      bottom: 14px;

      b {
        color: #fff !important;
        font-size: 16px !important;
      }
    }

    .button-edit {
      position: absolute;
      right: 134px;
      bottom: 14px;

      b {
        color: #ff7d00 !important;
        font-size: 16px !important;
      }
    }

    span {
      font-weight: bold;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: -0.5px;
      color: rgba(25, 25, 25, 0.4);
      position: relative;
      top: 2px;
    }
  }

  .colored-info-box {
    p {
      color: #ffffff;
      margin-top: 0 !important;
    }
  }

  div {
    p,
    b {
      font-size: 14px;
      letter-spacing: -0.4px  ;
      color: rgba(0, 0, 0, 0.8);
      font-family: PTRootUI !important;

      &.yes-text {
        color: #19C700;
      }

      &.no-text {
        color: #E02E2E;
      }
    }

    p {
      letter-spacing: -0.2px;

    }

    b {
      font-weight: 700 !important;
      vertical-align: top;
    }

    .disabled-form {
      div {
        background-color: rgba(25, 25, 25, 0.05);
        border: 1px solid rgba(25, 25, 25, 0.1);
        border-radius: 8px;
        padding: 12px 16px;
        width: 400px;
        margin: 8.5px 0 24px 0;
        word-break: break-word;

        p {
          font-size: 16px;
        }
      }
    }
  }

  .view-chatbot-detail-middle {
    padding: 0 0 16px;

    .detail-label {
      font-size: 16px;
      letter-spacing: -0.5px;
    }

    .detail-value {
      font-size: 16px;
      line-height: 24px;
      letter-spacing: -0.3px;
      color: #191919;
    }

    // .detail-value-token {
    //   font-size: 24px;
    //   letter-spacing: -0.6px;
    //   font-family: PTRootBold !important;
    // }

    .detail-value-no-data {
      color: #19191966;
    }

    .detail-status {
      display: block;
      margin-top: 4px;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: -0.5px;

      svg, img {
        width: 20px;
        height: 20px;
        top: 1.5px;
        vertical-align: top;
      }
    }
    
    .status-not-paid {
      color: #FF3F57;
    }

    .status-waiting-setup {
      color: #FF7E00;
    }

    .status-provisioning {
      color: #2085CC;

      .provisioning-wrapper {
        display: inline-block;

        b {
          color: #2085CC;
          font-size: 16px !important;
          line-height: 24px;
          letter-spacing: -0.5px !important;
        }

        p {
          color: #2085CC;
          margin: 0;
          font-size: 14px;
          line-height: 21px;
          letter-spacing: -0.2px;
        }
      }
    }

    .status-active {
      color: #19C700;
    }

    .status-others {
        color: #19191999;

        svg {
          margin: 0 4px 0 0;
          top: 2px;
          color: #19191999;
        }
    }

    .error-or-info-comp {
      svg {
        top: 1px;
      }
    }

    &.view-chatbot-detail-middle-top {
      max-width: 100%;
      // width: 500px;

      .chatbot-detail-topics {
        border-radius: 4px;
        background: rgba(25, 25, 25, 0.05);
        max-width: 150px;
        white-space: nowrap;
        margin: 0 4px 4px 0;
        text-overflow: ellipsis;
        overflow: hidden;
        vertical-align: top;
        text-align: center;
        padding: 6px 8px;
        line-height: 1;
        display: inline-block;
        
        b {
          color: rgba(25, 25, 25, 0.6);
          letter-spacing: -0.4px;
          font-size: 12px;
        }
      }

      p {
        b {
          letter-spacing: -0.6px !important;
          font-size: 20px !important;
        }
      }
    }

    .col_action {
      width: 70px;

      .orange-link-text {
        svg {
          top: 2px !important;
        }
      }
    }

    &.message-number-wrapper {
      border-bottom: solid 1px #dcdcdc;
      margin-bottom: 10px;

      &:nth-last-child(1) {
        margin-bottom: 0;
        border-bottom: 0;
      }
    }


    > div {
      svg {
        margin-right: 5px;
        vertical-align: top;
        position: relative;
        top: 4px;
      }
      img {
        margin-right: 5px;
        margin-bottom: 4px;

        &.label-icon {
          margin-bottom: 0;
          position: relative;
          vertical-align: top;
          top: 2px;
        }

        &.channel-icon {
          margin: 0 5px 0 0 !important;
          position: relative;
          top: 2px;
        }

        position: relative;
        top: -1px;
      }
    }
    p {
      margin: 8px 0 0;
      word-break: break-word;
    }
    .view-chatbot-detail-middle-category {
      p {
        margin-bottom: 0;
      }
    }
    .view-chatbot-detail-middle-channel {
      div > img {
        // margin-bottom: 9px;
      }
      > div {
        margin: 8px 0 0;
      }
    }
    .view-chatbot-detail-middle-status {
      .colored-info-box {
        margin-top: 10px;
        margin-bottom: 1rem;
        p {
          color: #ffffff;
          margin-top: 0 !important;
        }
      }
    }
    .view-chatbot-detail-middle-content-body {
      max-width: 568px;
      > img {
        margin-bottom: 5px !important;
      }
      .message-content {
        margin-top: 12px;
        img {
          width: 200px;
          height: 200px;
          object-fit: cover;
          border-radius: 8px;
          cursor: pointer;
          margin: 0 0 8px 0;
        }
        .content-caption {
          padding-top: 10px;
          // border-top: solid 1px #dcdcdc;
          margin-top: 0 !important;
        }
      }

      .file-url {
        cursor: pointer;
        img {
          width: 24px !important;
          height: 24px !important;
          margin-right: 7px;
        }
        div > p {
          margin-top: 2px;
          font-weight: bold;
          font-size: 14px;
          color: #ff7e00;
          line-height: 21px;
          letter-spacing: -0.4px;
          border-bottom: solid 1px #ff7e00;
        }
      }
    }

    .view-chatbot-detail-middle-total-status {
      font-weight: bold;
      > div {
        margin-right: 10%;
      }
      .flexing {
        font-weight: bold;
        font-size: 14px;
        line-height: 21px;
        letter-spacing: -0.4px;
      }
      .total {
        font-size: 24px;
        line-height: 30px;
        letter-spacing: -0.6px;
      }
    }

    svg {
      margin: 0 0 0 6px;
      font-size: 16px;
      color: #FF7E00;
      cursor: pointer;
    }

    &:nth-last-child(1) {
      padding: 0;
    }
  }

  .detail-value-wrapper {
    border: solid 1px rgba(25, 25, 25, 0.1);
    border-radius: 8px;
    width: 100%;
    position: relative;
    margin-top: 2px;

    input {
      border: none;
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      padding: 12px 48px 12px 16px;
      font-size: 16px;
      letter-spacing: -0.3px;
      background: transparent;
    }

    svg {
      cursor: pointer;
      position: absolute !important;
      right: 16px !important;
      top: 50% !important;
      transform: translate(0, -50%) !important;
      color: #191919;
      font-size: 20px;
    }
  }

  .view-chatbot-detail-bottom {
    padding: 24px 0 0;

    .view-message-box {
      position: relative;
      width: 100%;
    }

    .view-message-box-left {
      display: inline-block;
      vertical-align: top;
      width: 400px;
    }

    .view-message-box-right {
      width: calc(100% - 400px);
      vertical-align: top;
      display: inline-block;
      padding-left: 24px;
    }
  }

  div:last-child {
    .view-chatbot-detail-bottom-footer {
      p {
        margin-bottom: 0;
      }
    }
  }

  .scf-table-wrapper {
    width: 100%;
    position: relative;
    margin-top: 24px;
  }

  .table-scf thead tr {
    background: #ffffff;
    // border-top: solid 1px #dcdcdc;
    
    .col_name {
      width: unset;
    }

    td {
      // &:nth-child(1) {
      //     width: 100%;
      // }

      // &:nth-child(2), &:nth-child(3) {
      //     width: 100px;
      // }

      // &:nth-child(4), &:nth-child(5) {
      //     width: 120px;
      // }
      &.col_id {
        width: 70px;
      }
      
      &.col_type {
        width: 105px;
      }

      &.col_action {
        width: 105px;
      }
    }
  }

  .table-scf tbody 
  tr{
    // cursor: pointer;
    // &:hover {
    //   background-color: #FFF2E5;

    //   td {
    //     color: #FF7E00;
    //   }
    // }


    &.no-hover {
      cursor: default !important;
      &:hover {
        background-color: #ffffff !important;
  
        td {
          color: #191919 !important;
        }
      } 
    }
  }
  td {
    position: relative;

    
    &.col_type {
      width: 105px;
    }

    &.col_action {
      width: 105px;
    }
    
    &:nth-child(1) {
      div {
        display: flex;

        p {
          font-size: 12px !important;
          letter-spacing: -0.4px;
        }

        img {
          margin-right: 4px;
          margin-top: 2px;
        }
      }
    }

    // &:nth-child(2), &:nth-child(3) {
    //     width: 100px;
    // }

    // &:nth-child(4), &:nth-child(5) {
    //     width: 120px;
    // }

    &:last-child {  
      a {
        color: #ff7e00;
      }

      svg {
        position: relative;
        color: #ff7e00;
        font-size: 16px;
        margin-right: 4px;
        right: 0;
        top: -2px;
      }
    }

    .medium-logo {
      width: 16px;
      height: 16px;
    }
  }
  
  .custom-select-option-value {
    margin-top: 0px !important;
  }
}

.modal-image-broadcast.modal-dialog {
  width: 100vw !important;
}
.modal-image-broadcast .modal-content {
  width: 100%;
  height: 100%;
  background: none;
}
.modal-image-broadcast .modal-content .modal-body {
  width: 100%;
  height: 100%;
  background: rgba(25, 25, 25, 0.8);
}
.modal-image-broadcast .modal-content .modal-body img.image-preview-val {
  max-width: calc(100vw - 130px);
  max-height: calc(100vh - 130px);
  background: #ffffff;
}
.modal-image-broadcast .modal-content .modal-body .image-wrapper {
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;

  figure {
    margin: 0;

    &.zoom {
      background-size: 180%;
      background-position: -100% -100%;
      background-repeat: no-repeat;
      position: relative;
      width: 100%;
      overflow: hidden;
      cursor: zoom-in;

      img {
        transition: opacity 0.5s;
        display: inline-block;
        // width: 100%;

        &:hover {
          opacity: 0;
        }
      }
    }
  }
}
.modal-image-broadcast .modal-content .modal-body .close-modal-image-broadcast {
  position: absolute;
  top: 20px;
  right: 20px;
  color: #ffffff;
  font-size: 40px;
  cursor: pointer;
  transition: ease-in-out 0.1s;
}
.modal-image-broadcast
  .modal-content
  .modal-body
  .close-modal-image-broadcast:hover {
  color: #c9c9c9;
}

.edit-path-modal {
  .modal-content {
    width: 800px;
    border-radius: 8px;
    padding: 32px;
    position: relative;
    max-height: 90vh;
    overflow-y: auto;

    .custom-checkbox input {
      // display: none;
    }

    .create-path-title {
      font-size: 20px;
      letter-spacing: -0.6px;
      line-height: 1;
    }

    .close-modal-create-path {
      position: absolute;
      font-size: 22px;
      right: 0;
      top: 2px;
      cursor: pointer;
    }

    .form-content-wrapper {
      input, textarea, label {
        width: 100%;
        position: relative;

        .clear-all {
          color: var(--watermelon);
          cursor: pointer;
          position: absolute;
          right: 0;
          bottom: 0;

          &:hover {
            color: var(--watermelon-dark);
          }
        }
      }

      .input-counter {
        position: absolute;
        top: 0;
        right: 0;
      }
    }

    .button-create-path {
      width: 100%;
      margin-top: 20px;
    }
    
    .create-path-type-wrapper {
      position: relative;
      width: 100%;
      display: block;
      line-height: 1;

      .create-path-type-select {
        display: inline-block;
        border-radius: 8px;
        width: calc(50% - 5px);
        margin-right: 10px;
        border: solid 1px #dcdcdc;
        cursor: pointer;
        text-align: center;
        padding: 12px 5px;
        font-size: 16px;
        letter-spacing: -0.3px;

        &.create-path-type-active {
          border: solid 1px #ff7d00;
          color: #ff8d00;

          svg {
            color: #ff7d00;
          }
        }

        svg {
          margin-right: 4px;
          position: relative;
          top: -1px;
          color: rgba(25, 25, 25, 0.4);
        }

        &:nth-child(2) {
          margin: 0;
        }

        &:hover {
          border: solid 1px #ff7d00;
          color: #ff8d00;

          svg {
            color: #ff7d00;
          }
        }

        &.edit-path-type-select {
          border: none !important;
          cursor: default !important;
          padding: 0 !important;
          width: unset !important;
          line-height: 1;
        }
      }

      &.no-hover .create-path-type-select {
        color: inherit !important;

        svg {
          color: rgba(25, 25, 25, 0.4) !important;
        }
      }
    }

    .choose-related-department-wrapper {
      position: relative;
      width: 100%;
      display: block;

      .choose-related-department-select {
        display: inline-block;
        border-radius: 8px;
        width: calc((100% / 3) - 10px);
        margin-right: 15px;
        border: solid 1px #dcdcdc;
        cursor: pointer;
        text-align: left;
        padding: 12px 13px;
        font-size: 16px;
        letter-spacing: -0.3px;
        margin-bottom: 10px;
        
        label {
          white-space: nowrap;
          text-overflow: ellipsis;
          max-width: 100%;
          overflow: hidden;
        }

        &.choose-related-department-select-active {
          border: solid 1px #ff7d00;
          
          label {
            color: #ff7d00;
          }
        }

        svg {
          margin-right: 4px;
          position: relative;
          top: -1px;
          color: rgba(25, 25, 25, 0.4);
        }

        &:nth-child(3) {
          margin: 0;
        }

        &:hover {
          border: solid 1px #ff7d00;
          
          label {
            color: #ff7d00;
          }
        }

        label {
          margin: 0;
        }
      }
    }
  }
  
  .modal-body {
    padding: 0;
  }
}
