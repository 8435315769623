$color_1: #ff3f57;
$color_2: #ff7d00;
$color_3: var(--main-darker-orange);
$border-color_1: #ff7d00 transparent transparent transparent;

.setup-room-list-modal {
	background: #4e4e4e;
	.modal-content {
		width: unset;
		min-width: 268px;
		display: inline-block;
		border-radius: 8px;
		padding: 32px 16px;
		text-align: center;
		position: relative;
		.setup-main-content {
			.setup-image-content {
				height: 64px;
				width: 64px;
				display: inline-block;
				position: relative;
				border-radius: 50%;
				margin-bottom: 12px;
				.lds-ring {
					position: absolute;
					left: 0;
					top: 0;
					height: 64px;
					width: 64px;
					div {
						height: 64px;
						width: 64px;
						border: 8px solid;
						border-color: $border-color_1;
					}
				}
				img {
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
				}
			}
			.setup-image-content.setup-success {
				border: 12px solid #19C700;
			}
			.setup-fail {
				color: $color_1;
			}
			b {
				font-size: 20px;
				letter-spacing: -0.6px;
				color: $color_2;
			}
			p {
				font-size: 16px;
				letter-spacing: -0.3px;
				margin: 8px 0 0;
				svg {
					font-size: 20px;
					margin-right: 7px;
					font-size: 25px;
					position: relative;
					top: -1px;
				}
			}
			p.retry-setup {
				b {
					color: $color_2;
					transform: ease-in-out 0.1s;
					cursor: pointer;
					position: absolute;
					bottom: -12px;
					left: 50%;
					transform: translate(-50%, 0);
					font-size: 16px;
				}
				&:hover {
					color: $color_3;
				}
			}
		}
	}
	.lds-ring {
		div {
			margin: 0;
		}
	}
}
.modal-dialog.setup-room-list-modal {
	width: 100vw !important;
}

.setup-room-list-modal-tier {
  .modal-content {
    background: transparent !important;
    border: none !important;
    box-shadow: none !important;
  }
  
  .tier-blocking {
      .alert-component {
        width: 402px;
        padding: 16px 24px !important;

        svg {
          top: -2px !important;
          left: unset !important;
          margin: 0 8px 0 0 !important;
          position: relative !important;
        }
      }

      b {
        font-size: 16px;
        letter-spacing: -0.5px;
      }

      .alert-component-button-wrap {
        width: 100%;
        text-align: center;
        margin: 14px 0 0;

        button {
          position: relative !important;
          margin: 0 4px !important;
          width: 126px !important;
          top: unset !important;
          right: unset !important;

          b {
            font-size: 12px;
            letter-spacing: -0.4px;
          }

          svg {
            top: -1px !important;
          }
        }

        .no-fill-blue-button {
          color: rgba(4, 75, 132, 1) !important;
          border: solid 1px rgba(4, 75, 132, 1) !important;

          svg {
            color: rgba(4, 75, 132, 1) !important;
          }
        }
      }
  }
}
