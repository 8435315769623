.member-detail-outer-wrapper {
    margin: 16px 0 !important;
}

#member-detail-wrapper {
    position: relative;
    width: 100%;
}

.member-detail-title {
    font-size: 20px;
    letter-spacing: -0.6px;
    color: #191919;
    margin: 0 0 32px;
    border-bottom: solid 1px #dcdcdc;
    padding-bottom: 24px;
    position: relative;

    .member-detail-title-text {
        top: 3px;
        position: relative;
    }

    button {
        position: absolute;
        right: 0;
    }
}

.member-detail-avatar {
    width: 72px;
    vertical-align: top;
    display: inline-block;
    margin-right: 36px;
}

#member-value-wrapper {
    width: calc(100% - 108px);
    display: inline-block;
}

.member-value-content {
    // border-bottom: solid 1px rgba(25, 25, 25, 0.1);
    margin-bottom: 24px;
    padding-bottom: 16px;
}

.member-value-content img {
    width: 16px;
    margin-right: 15px;
    display: inline-block;
    vertical-align: top;
    position: relative;
    /* top: 12px; */
    top: 0;
}

.member-value-content .member-info-wrapper {
    width: calc(100% - 31px);
    display: inline-block;
    line-height: 1;
}

.member-value-content .member-info-wrapper label {
    margin-bottom: 8px;
    font-size: 14px;
    letter-spacing: -0.4px;
    color: rgba(25, 25, 25, 0.6);

    svg {
        position: relative;
        margin-left: 6px;
        font-size: 16px;
        color: var(--main-dark-orange);
        cursor: pointer;
        top: -2px;

        &:hover {
            color: var(--main-darker-orange);
        }
    }
}

.member-value-content .member-info-wrapper b.member-detail-value {
    display: block;
    /* margin-bottom: 16px; */
    font-size: 20px;
    letter-spacing: -0.6px;
    color: #191919;
}

.member-value-content .member-info-wrapper .access-to-inbox-label {
    letter-spacing: -0.2px;
    font-size: 14px;
    color: rgba(25, 25, 25, 0.6);
    margin: 4px 0 0;
}

.member-value-content:nth-last-child(1) {
    border: none;
    margin-bottom: 0;
}

.member-value-content:nth-last-child(1) b {
    margin-bottom: 0;
}

#member-value-wrapper {
    .member-value-content {
        position: relative;
        padding-left: 52px;
    }

    button {
        font-size: 12px;
        letter-spacing: -0.4px;
        border-radius: 4px;
        padding: 4px 10px;
        margin-top: 14px;

        img {
            width: 12px;
            height: 12px;
            margin-right: 4px;
        }
    }

    .icon-wrapper {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        display: inline-block;
        position: absolute;
        top: 0;
        left: 0;

        img {
            position: absolute;
            top: 50%;
            left: 50%;
            margin: 0;
            transform: translate(-50%, -50%);
        }

        &.icon-email {
            background: #E7E5FF;
        }   

        &.icon-name {
            background: #FFF9E5;
        }

        &.icon-alias {
            background: #E9F5FD;
        }

        &.icon-role {
            background: #E5FFEE;
        }
    }
}

.member-detail-edit-topic {
    font-size: 16px;
    letter-spacing: -0.5px;
    padding: 7px 30px;
    position: absolute;
    top: 0;
    right: 0;

    img {
        margin-right: 4px;
        width: 16px;
        position: relative;
        top: -2px;
    }
}

.topic-list-wrapper {
    position: relative;
    width: 100%;
    margin-top: 24px;

    .topic-list-content {
        color: rgba(25, 25, 25, 0.6);
        font-size: 12px;
        letter-spacing: -.4px;
        border-radius: 4px;
        background: rgba(25, 25, 25, 0.05);
        margin: 0 4px 8px 0;
        display: inline-block;
        padding: 2px 8px;
        vertical-align: top;
    }

    &.role-list-wrapper {
        margin-top: 8px;
    }
}

.topic-no-list {
    width: 100%;
    position: relative;
    height: 140px;
    text-align: center;
    color: rgba(25, 25, 25, 0.8);
    padding-top: 40px;

    .topic-no-list-icon-wrapper {
        width: 41px;
        height: 41px;
        display: inline-block;
        border-radius: 50%;
        position: relative;
        margin-bottom: 20px;
        background: #F3F3F3;

        img {
            width: 24px;
            position: relative;
            top: 6px;
            display: inline-block;
        }
    }
}

.modal-edit-aliass {
    .modal-content {
        width: 650px;
        border-radius: 8px;

        .modal-body {
            padding: 44px 24px;

            .modal-title {
                font-size: 20px;
                letter-spacing: -0.6px;
                width: 100%;
                position: relative;
            }

            input {
                margin-top: 8px;
                padding: 12px 16px;
                font-size: 16px;
                letter-spacing: -0.3px;
                width: 100%;
                border-radius: 6px;
                border: solid 1px #dcdcdc;

                &:focus {
                    border: solid 1px var(--main-dark-orange);
                }
            }

            .counter-alias {
                position: absolute;
                top: 2px;
                right: 0;
                font-size: 16px;
            }

            button {
                width: calc(50% - 2px);
                margin: 24px 4px 0 0;
                
                &:nth-last-child(1) {
                    margin: 24px 0 0;
                }
            }
        }
    }
}

.modal-member-change-role .modal-content {
    padding: 32px;
    border-radius: 8px;
    width: 500px;
}

.modal-member-change-role .modal-body {
    padding: 0;
}

.modal-member-change-role .modal-footer {
    border: 0;
    padding: 0;
}

.modal-member-change-role .modal-footer button {
    width: calc(50% - 4px);
    margin: 0 8px 0 0;
    padding: 10px 0 12px;
    position: relative;
}

.modal-member-change-role .modal-footer button:nth-child(2) {
    margin-right: 0;
}

.modal-member-change-role .modal-member-change-role-title {
    margin: 0 0 20px;
    font-size: 20px;
    letter-spacing: -0.6px;

    svg {
        margin-right: 6px;
        position: relative;
        top: -2px;
        font-size: 24px;
    }
}

.modal-member-change-role .modal-member-role-wrapper {
    margin-bottom: 36px;
}

.modal-member-change-role .role-radio-label {
    position: relative;
    top: -1px;
}

.modal-member-change-role .modal-member-role-wrapper b {
    font-size: 14px;
    letter-spacing: -0.2px;
    color: #191919;
}

.modal-member-change-role .modal-member-role-wrapper span {
    font-size: 14px;
    letter-spacing: -0.2px;
    color: rgba(25, 25, 25, 0.6);
    display: block;
}

.modal-member-change-role .change-role-gap {
    height: 16px;
    width: 100%;
}

.modal-member-change-role {
    .there-is-no-custom-role {
        margin: 4px 0 0 27px;
    }

    .edit-member-role-list-wrapper {
        width: calc(100% - 28px);
        height: 190px;
        overflow: auto;
        border-radius: 8px;
        border: solid 1px #dcdcdc;
        margin: 8px 0 0 28px;
        padding: 8px 12px 0 12px;

        label {
            font-size: 14px;
            letter-spacing: -0.2px;
            max-width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .custom-checkbox-child {
            padding: 0 0 16px 0;
        }
    }

    .error-edit-role-custom {
        margin-left: 28px;
    }
}

.form-title-wrapper {
    padding: 0 0 16px 0;

    .assign-topic-title-text {
        position: relative;
        top: 3px;
    }

    &.assigned-topic-member {
        display: flex;
        justify-content: space-between;

        button {
            font-size: 16px;
        }
    }
}
    

.modal-edit-topic {
    .modal-content {
        width: 840px;
        border-radius: 8px;

        .modal-body {
            padding: 32px 32px 0 32px;

            .modal-member-change-role-title {
                font-size: 20px;
                letter-spacing: -0.6px;

                svg {
                    font-size: 22px;
                    position: relative;
                    top: -2px;
                    margin-right: 4px;
                }
            }
        }

        .modal-footer {
            padding: 20px 32px 32px;
            border-top: 0;

            button {
                width: 181px;
                margin: 0 0 0 8px;
            }
        }
    }

    .invite-member-select-topic-wrapper {
        width: 100%;
        position: relative;

        .invite-member-select-topic-wrapper-50 {
            width: calc(50% - 8px);
            position: relative;
            vertical-align: top;
            display: inline-block;
            margin: 0 16px 0 0;

            label {
                font-size: 14px;
                letter-spacing: -0.4px;
            }

            &:nth-child(2) {
                margin: 0;
            }

            span {
                color: rgba(25, 25, 25, 0.6);
            }

            .selection-topic-wrapper {
                border-radius: 8px;
                font-size: 14px;
                letter-spacing: -0.2px;
                box-shadow: 0 0 0 1px #dcdcdc;

                .select-content {
                    width: 100%;
                    padding: 13px 17px;

                    label {
                        margin: 0;
                    }

                    &:hover {
                        background: #FFF2E5;
                    }

                    &:nth-child(1) {
                        border-top-left-radius: 8px;
                        border-top-right-radius: 8px;
                    }
                }

                .search-box-wrapper {
                    width: 100%;

                    input[type="text"] {
                        border: none !important;
                        border-bottom: solid 1px #dcdcdc !important;
                        border-radius: 0;
                        background: transparent;
                        width: 100%;
                        position: relative;
                        padding: 0 42px 0 16px;
                        height: 40px;
                    }

                    #search-icon {
                        width: 40px;
                        height: 40px;
                    }
                }

                .overflow-select-topic {
                    max-height: 160px;
                    overflow-y: auto;

                    .select-content {
                        &:nth-child(1) {
                            border-top-left-radius: 0px;
                            border-top-right-radius: 0px;
                        }
    
                        &:nth-last-child(1) {
                            border-bottom-left-radius: 8px;
                            border-bottom-right-radius: 8px;
                        }

                        &.checkbox-all {
                            border-bottom: solid 1px #dcdcdc;
                        }

                        &.not-found {
                            text-align: center;
                            color: var(--main-dark-grey);
                        }
                    }
                }
            }

            .select-topic-wrapper {
                width: 100%;
                height: 200px;
                
                .selected-topic-container {
                    width: 100%;
                    min-height: 100%;
                    overflow-y: auto;
                    max-height: 100%;
                    border: solid 1px #dcdcdc;
                    border-radius: 8px;
                    padding: 16px 16px 8px 16px;

                    .topic-list-content {
                        color: rgba(25, 25, 25, 0.6);
                        font-size: 12px;
                        letter-spacing: -.4px;
                        border-radius: 4px;
                        background: rgba(25, 25, 25, 0.05);
                        margin: 0 4px 8px 0;
                        display: inline-block;
                        padding: 2px 8px;
                        vertical-align: top;

                        .remove-topic {
                            display: inline-block;
                            color: #9c9c9c;
                            font-size: 18px;
                            margin-left: 4px;
                            position: relative;
                            top: 4px;
                            cursor: pointer;
                            font-size: 8px;
                            background: #9a9a9a;
                            width: 10px;
                            height: 10px;
                            color: #ffffff;
                            border-radius: 50%;
                            vertical-align: top;

                            svg {
                                position: absolute;
                                top: 50%;
                                left: 50%;
                                transform: translate(-50%, -50%);
                            }
                
                            &:hover {
                                background: #818181;
                            }
                        }
                    }
                }
            }
        }
    }
}

