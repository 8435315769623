.integration-livechat-customize {
  padding: 32px 30px;
  text-align: left;

  .integration-livechat-detail-account-box-1 {
    background: #ffffff;
    border-radius: 8px;
    padding: 22px 32px 22px;
    border: solid 1px rgba(25, 25, 25, 0.1);
    position: relative;

    &.box-channel-button {
      margin-top: 24px;
      margin-bottom: 24px;
    }

    .livechat-detail-channel-button {
      .channel-button-wrapper {
        margin-top: 24px;
        width: 300px;
        word-break: break-word;
      }
    }

    .status-wrapper {
      .status-value {
        color:rgba(25, 25, 25, 0.4);

        &.status-enabled {
          color: #19C700;
        }
      }

      img {
        position: relative;
        top: -1px;
        margin-right: 4px;
      }
    }

    .integration-livechat-detail-account-box-1-top {
      padding: 10px 0 18px;
      border-bottom: solid 1px #dcdcdc;
      position: relative;
      font-size: 20px;
      letter-spacing: -0.6px;
      padding-right: 310px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      .bot-name {
        margin-bottom: 0;
      }

      p {
        font-size: 14px;
        letter-spacing: -0.2px;
      }

      .integration-livechat-detail-account-box-1-top-button {
        position: absolute;
        top: 0px;
        right: 0;
        display: inline-block;

        button {
          padding: 7px 24px;
          font-size: 16px;
          letter-spacing: -0.6px;
          height: 40px;
          margin-left: 8px;
          
          &.button-channel-detail {
            position: relative;
            top: 2px;
          }

          svg {
            position: relative;
            top: -2px;
            margin-right: 4px;
          }

          .lds-ring {
            top: unset;
            right: unset;
            position: relative;
            top: 3px;

            div {
              margin: 0;
              border-color: #ffffff transparent transparent transparent;
            }
          }
        }
      }
    }
  }

  .select-faq-wrapper {
    label {
      position: relative;
    }
  
    .button-clear-scf-path {
      position: absolute;
      right: 0;
      bottom: 0;
      color: var(--watermelon);
      cursor: pointer;
  
      &:hover {
        color: var(--watermelon-dark);
      }
    }
    
    #tooltip-scf {
      position: relative;
      top: -2px;
      margin-left: 4px;
    }

    button {
      margin: 0;
    }

    .create-faq-button {
      color: #ff7d00;

      svg {
        position: relative;
        top: -2px;
        margin-right: 4px;
      }

      &:hover {
        color: var(--main-darker-orange);
      }
    }
  }

  .integration-list-loading-wrapper {
    // .lds-ring {
    //   width: 50px;
    //   height: 50px;
    //   position: absolute;
    //   left: 50%;
    //   top: 50%;
    //   transform: translate(-50%, -50%);
    //   right: unset;

    //   div {
    //     border: 5px solid #fff;
    //     border-color: #ff7d00 transparent transparent transparent;
    //     width: 50px;
    //     height: 50px;
    //     margin: 0;
    //   }
    // }
  }

  .comp-back-button {
    margin: 0 0 16px 0;
    font-family: PTRootBold;
  }

  .integration-livechat-customize-box {
    background: #ffffff;
    border-radius: 8px;
    padding: 32px;
    border: solid 1px rgba(25, 25, 25, 0.1);
    margin-bottom: 12px;

    .integration-livechat-customize-box-top {
      padding-bottom: 15px;
      border-bottom: solid 1px #dcdcdc;
      position: relative;
      font-size: 20px;
      letter-spacing: -0.6px;

      .title-wrapper {
        display: block;
        position: relative;

        img {
          width: 24px;
          height: 24px;
          top: 2px;
          left: 0;
          position: absolute;
        }

        .title-description {
          display: block;
          padding-left: 32px;
          position: relative;
          width: calc(100% - 280px);

          p {
            margin: 0;
          }

          .title-customize {
            font-size: 20px;
            // text-transform: capitalize;
          }

          .description-customize {
            font-size: 14px;
            overflow: hidden;
            text-overflow: ellipsis;

            a {
              color: #ff7e00;
            }
          }
        }
      }

      button {
        position: absolute;
        top: -6px;
        right: 0;
        // padding: 7px 24px;
        font-size: 16px;
        letter-spacing: -0.6px;
        // width: 121px;
        // height: 40px;
        // display: flex;
        // justify-content: center;
        // align-items: center;

        // &.copy-script-button {
        //   width: 155px;
        // }

        // &.save-button {
        //   width: 148px;
        // }

        &.red-button {
          right: 148px;
        }

        .button-text {
          margin-left: 4px;
        }

        // .lds-ring {
        //   top: unset;
        //   right: unset;
        //   position: relative;

        //   div {
        //     margin: 0;
        //     border-color: #ffffff transparent transparent transparent;
        //   }
        // }
      }
    }

    .integration-livechat-customize-box-form {
      width: 100%;

      .alert-component {
        border-radius: 8px;
        position: relative;
        padding: 12px 48px;
        letter-spacing: -0.5px;
        font-size: 16px;
        display: inline-block;
        vertical-align: top;
        // width: 100%;
        margin-top: 24px;

        .text-alert {
          font-size: 16px;
          color: #044b84 !important;
        }

        svg {
          position: absolute;
          font-size: 24px;
          left: 16px;
          top: 50%;
          transform: translate(0, -50%);
        }

        img {
          position: absolute;
          font-size: 24px;
          left: 16px;
          top: 50%;
          transform: translate(0, -50%);
        }

        &.alert-blue {
          color: #044b84;
          background: #c5dffe;
          border: solid 1px #044b84;
        }
      }

      .taptalk-snippet {
        width: 100%;
        background: rgb(39, 40, 34);
        text-align: left;
        border-radius: 4px;
        margin: 15px 0 0 0;
        display: block;
        padding: 10px;
        color: #ffffff;
        resize: none;

        pre {
          overflow-x: hidden !important;
          text-overflow: ellipsis;
        }
      }

      .form-customize {
        display: flex;
        margin: 24px 0;

        input[type='text'] {
          padding: 16px;
          border-radius: 6px;
          border: solid 1px #dcdcdc;
          width: 100%;
          font-size: 16px;
          letter-spacing: -0.3px;
          display: block;
        }

        .input-customize {
          width: 50%;
          // border: 1px solid #ff7d00;
          padding-right: 10px;

          &[disabled] {
            border: none !important;
          }

          .color-input-wrapper {
            position: relative;
            width: 166px;
            height: 48px;
            border-radius: 8px;
            border: solid 1px #dcdcdc;
            cursor: pointer;
            line-height: 47px;
            font-size: 16px;
            letter-spacing: -0.3px;
            color: #191919;
            padding: 0 0 0 16px;
            margin-top: 12px;

            .color-placeholder {
              color: rgba(25, 25, 25, 0.6);
            }

            img {
              position: absolute;
              position: absolute;
              right: 16px;
              top: 50%;
              transform: translate(0, -50%);
            }

            .color-tag {
              display: inline-block;
              margin-right: 1px;
            }

            .color-value {
              width: 16px;
              height: 16px;
              display: inline-block;
              background: #dcdcdc;
              border-radius: 50%;
              position: absolute;
              right: 16px;
              top: 50%;
              transform: translate(0, -50%);
              // margi: 0;
            }

            .color-input {
              border: none !important;
              padding: 0;
              background-color: transparent;
              height: 20px;
              margin-top: 0 !important;
              height: 40px !important;
              display: inline-block;
              width: 125px !important;
            }

            .color-picker-container {
              background: #ffffff;
              position: absolute;
              bottom: 0;
              right: -265px;
              border-radius: 4px;
              box-shadow: 0 2px 20px 0 rgba(25, 25, 25, 0.18) !important;
              padding: 14px;
              z-index: 9999;

              .close-color-picker {
                position: absolute;
                top: 6px;
                font-size: 14px;
                right: 6px;
                color: rgba(25, 25, 25, 0.6);

                &:hover {
                  color: rgba(25, 25, 25, 0.8);
                }
              }

              &::before {
                position: absolute;
                background: #ffffff;
                width: 16px;
                height: 16px;
                content: '';
                left: -8px;
                transform: rotate(45deg);
                bottom: 17px;
              }

              .sketch-picker {
                border-radius: 4px;
                box-shadow: none !important;

                .flexbox-fix:nth-child(2) {
                  height: 28px;

                  div:nth-child(1) {
                    div:nth-child(1) {
                      height: 23px !important  ;
                      div {
                        div.hue-horizontal {
                          div {
                            div {
                              display: block !important;
                              margin-top: 0 !important;
                            }
                          }
                        }
                      }
                    }

                    div:nth-child(2) {
                      div {
                        display: none;
                      }
                    }
                  }
                }

                .flexbox-fix {
                  &:nth-child(3) {
                    display: none !important;
                  }

                  &:nth-child(4) {
                    div {
                      div {
                        border-radius: 50% !important;
                      }
                    }
                  }
                }
              }
            }
          }

          .input-color {
            p {
              font-size: 14px;
              margin: 0;
            }

            input[type='text'] {
              margin-top: 12px;
              width: 166px;
              height: 48px;
            }
          }
        }

        .preview-customize {
          width: 50%;
          // border: 1px solid #ff7d00;
          padding-left: 10px;
          display: flex;
          flex-direction: column;
          align-items: flex-end;

          .open-case-switch-wrapper {
            width: 100%;
            max-width: 360px;
            height: 47px;
            background: #ffffff;
            position: relative;
            text-align: center;
            padding: 9px 16px;
            box-shadow: none;
            z-index: 1;
            margin-bottom: 16px;

            .open-case-switch-content {
              width: 100%;
              border-radius: 4px;
              height: 29px;
              border: none;
              position: relative;
              background: #f3f3f3;
              cursor: pointer;
              display: flex;

              .open-case-active-panel {
                width: 33%;
                content: '';
                background: #ffffff;
                border-radius: 4px;
                height: 27px;
                position: absolute;
                left: 0;
                top: 0;
                transition: ease-in-out 0.2s;

                &.active-mine-case-panel {
                  left: 0;
                }
              }

              .open-case-switch-panel {
                width: 33%;
                display: inline-block;
                text-align: center;
                font-size: 14px;
                letter-spacing: -0.4px;
                color: rgba(25, 25, 25, 0.6);
                height: 29px;
                border-radius: 4px;
                flex-wrap: nowrap;
                outline: none;
                border: none;
                padding-top: 1px;

                &:hover {
                  color: rgba(25, 25, 25, 1);
                }

                &.active-case-panel {
                  color: #191919;
                  background: #ffffff;
                  box-shadow: 0 2px 20px 0 rgba(25, 25, 25, 0.18);
                }
              }
            }
          }

          .taplive2-chat-widget-opener-button{
            background: #ff7d00;
            box-shadow: inset 1px -1px 10px 0px rgba(255, 255, 255, 0.12),
              0px 2px 20px 0px rgba(25, 25, 25, 0.3);
            height: 64px;
            width: 64px;
            border-radius: 50%;
            cursor: pointer;
            position: relative;
            top: 32px;
            right: 0;
            // bottom: 24px;
            // right: 24px;
            // z-index: 9990;
            box-sizing: border-box;

            img {
              position: absolute;
              left: 50%;
              top: 50%;
              transform: translate(-50%, -50%);
            }

            &:hover::before {
              content: '';
              height: 64px;
              width: 64px;
              position: absolute;
              border-radius: 50%;
              background: rgba(25, 25, 25, 0.1);
              top: 0;
              right: 0;
            }
          }
        }
      }

      .form-secret-key {
        width: 75%;

        label {
          margin-top: 24px;
        }

        form {
          border-radius: 6px;
          border: solid 1px #dcdcdc;
          display: flex;
          justify-content: space-between;
          padding: 12px 16px;

          img {
            cursor: pointer;
          }

          input[type='text'] {
            border: none;
            outline: none;
            width: 90%;
            font-size: 16px;
            letter-spacing: -0.3px;
            text-overflow: ellipsis;
          }

          button {
            border: none;
            background: transparent;
          }
        }
      }

      label {
        padding: 0;
        font-size: 14px;
        letter-spacing: -0.4px;
        display: block;
        position: relative;
      }

      .custom-select-livechat-topic {
        .custom-select-option-value-wrapper {
          padding: 12px 16px !important;
          margin-bottom: 8px;
        }
      }
    }
  }
}
