.form-company-detail {
    position: relative;
    width: 100%;

    .submit-button {
        width: unset;
        margin-top: 26px;
        margin-right: 8px;
    }

    label {
        position: relative;

        span {
            position: absolute;
            right: 0;
        }
    }

    input {
        width: 400px !important;
    }

    textarea {
        width: 400px !important;
    }

    .country-picker-wrapper {
        width: 400px;

        .country-picker-label {
            width: 300px;
        }
    }

    .main-custom-select-option-wrapper {
        width: 400px;

        button {
            margin: 0;
        }
    }
}

.phone-input-plus {
    position: absolute;
    left: 16px;
    top: 12px;

    &.phone-input-plus-disabled {
        color: rgba(25, 25, 25, 0.4);
    }
}

.phone-waba-input {
    padding-left: 28px !important;
}