.user-rating-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    .red-text {
        position: relative;
        font-size: 12px;
        margin: 0;

        svg {
            position: relative;
            top: -1px;
            margin-right: 4px;
        }
    }

    .button-submit-rating {
        background: #ff7d00;
        border-radius: 8px;
        border: none;
        color: #ffffff;
        position: relative;
        margin-top: 20px;
        
        .button-text-wrapper {
            position: relative;
            z-index: 2;
        }

        &:hover {
            &::before {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: rgba(25, 25, 25, 0.1);
                content: "";
                border-radius: 8px;
                z-index: 1;
            }
        } 
    }

    .result-wrapper {
        background: #ffffff;
        text-align: center;
        position: fixed;
        left: 50%;
        transform: translate(-50%, 0);
        top: 48px;
        width: 364px;
        padding: 16px;
        border-radius: 8px;
        max-height: 90%;

        &.loading-content {
            max-height: 100%;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            transform: unset;
            border-radius: 0;

            .inner-content {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }

        .lds-ring {
            width: 48px;
            height: 48px;

            div {
                margin: 0;
                width: 48px;
                height: 48px;
                border: solid 5px;
                border-color: #ff7d00 transparent transparent transparent;
            }
        }

        img {
            // width: 80px;
        }

        p {
            margin: 0;
            line-height: 1;

            &:nth-child(2) {
                color: rgba(25, 25, 25, 1);
                font-size: 24px;
                letter-spacing: -0.6px;
                margin: 12px 0 8px;
            }

            &:nth-child(3) {
                color: rgba(25, 25, 25, 0.6);
                font-size: 14px;
                letter-spacing: -0.2px;
                line-height: 21px;
            }
        }
    }

    .main-rating-wrapper {
        background: #ffffff;
        position: fixed;
        left: 50%;
        transform: translate(-50%, 0);
        top: 48px;
        width: 490px;
        padding: 32px;
        border-radius: 8px;
        max-width: 90%;
        max-height: calc(100% - 44px);
        overflow: auto;

        .main-rating-title {
            font-size: 20px;
            letter-spacing: -0.6px;
            display: block;
            margin: 0;
        }

        label {
            display: block;
            font-size: 14px;
            letter-spacing: -0.4px;
            margin-top: 8px;
            position: relative;

            .comment-length {
                color: rgba(25, 25, 25, 0.6);
                position: absolute;
                right: 0;
                top: 0;   
            }

            span {
                color: rgba(25, 25, 25, 0.6);
            }
        }

        textarea {
            border-radius: 8px;
            width: 100%;
            resize: none;
            border: 1px solid rgba(25, 25, 25, 0.1);
            padding: 12px 16px;
            font-size: 16px;
            letter-spacing: -0.3px;

            &::placeholder {
                color: rgba(25, 25, 25, 0.4);
            }
        }

        .value-wrapper {
            width: 100%;
            background: rgba(25, 25, 25, 0.05);
            border-radius: 8px;
            padding: 10px;
            line-height: 1;
            color:rgba(25, 25, 25, 0.4);

            svg {
                margin-right: 10px;
                position: relative;
                top: -2px;
            }
        }

        .rating-wrapper {
            margin-top: 18px;
            text-align: center;

            p {
                margin: 0;
            }

            .star-selection-wrapper {
                margin: 0 0 8px;
                height: 38px;
                width: 264px;
                position: relative;
                display: inline-block;
                max-width: 100%;
                
                img {
                    max-width: 100%;
                    position: relative;
                    // top: 0;
                    // left: 50%;
                    // transform: translate(-50%, 0);
                    z-index: 1;
                }

                .star-selection-content {
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    left: 0;
                    top: 0;
                }

                .star-selection {
                    width: 20%;
                    vertical-align: top;
                    position: relative;
                    z-index: 2;
                    height: 38px;
                    // background: red;
                    display: inline-block;
                    cursor: pointer;
                }
            }
        }
    } 
}