.colored-info-box {
    align-items: center;
    padding: 2px 8px;
    border-radius: 4px;
    color: white;
    width: fit-content;
}

.colored-info-box p {
    margin-bottom: 0;
    font-size: 12px;
    font-weight: bold;
    line-height: 18px;
    letter-spacing: -0.4px;
}