.form-questionnaire {
    position: relative;
    width: 100%;

    .submit-button {
        width: unset;
        margin-top: 26px;
    }

    .custom-radio {
        label {
            margin: 0;
            display: inline-block !important;
            line-height: 24px;
        }
    }
}

.modal-listed-here {
    .modal-content {
        // max-width: 90%;
        width: 900px;

        .modal-body {
            position: relative;
            padding: 32px;
            width: 100%;
            text-align: center;
            color: #191919;

            .modal-listed-here-title {
                font-size: 20px;
                letter-spacing: -0.6px;
                margin: 0;
            }

            svg {
                &.close-modal-listed-here {
                    cursor: pointer;
                    font-size: 18px;
                    position: absolute;
                    top: 41px;
                    right: 41px;

                    &:hover {
                        color: #ff7e00;
                    }
                }
            }

            .listed-here-card {
                width: calc(25% - 9px);
                margin-right: 12px;
                display: inline-block;
                position: relative;
                vertical-align: top;
                text-align: center;
                margin: 48px 12px 0 0;

                img {
                    margin-bottom: 8px;
                }
                
                .listed-here-card-title-wrapper {
                    margin-bottom: 12px;
                    height: 63px;
                    width: 100%;

                    .listed-here-card-title {
                        font-size: 14px;
                        letter-spacing: -0.4px;
                    }
                }

                &:nth-last-child(1) {
                    margin-right: 0;
                }

                .listed-here-card-list {
                    margin: 0;
                    // padding: 0;
                    position: relative;
                    width: 100%;
                    background: rgba(25, 25, 25, 0.05);
                    border-radius: 8px;
                    text-align: left;
                    padding: 24px 16px;

                    li {
                        margin: 0 0 0 16px;
                        font-size: 14px;
                        letter-spacing: -0.2px;
                        // padding: 0;
                    }

                    &::before {
                        width: 16px;
                        height: 16px;
                        border-top: solid 8px transparent;
                        border-bottom: solid 8px #ffffff;
                        border-right: solid 8px #ffffff;
                        border-left: solid 8px transparent;
                        position: absolute;
                        top: -2px;
                        left: 50%;
                        transform: rotate(45deg) translate(-50%, 0);
                        content: "";
                    }
                }
            }
        }
    }
}