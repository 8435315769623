.room-list-global-search-room-list {
    background: #f3f3f3;
    height: calc(100% - 90px);
    width: 100%;
    position: relative;
    // overflow: hidden;

    .list-search-case {
        overflow-x: hidden !important;
    }
    
    .room-list-global-search-room-wrapper {
        .search-not-found {
            text-align: center;
            padding: 0 16px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 100%;

            b {
                font-size: 18px;
                letter-spacing: -0.5px;
            }

            p {
                font-size: 16px;
                letter-spacing: -0.3px;
                color: rgba(25, 25, 25, 0.6);
                line-height: 24px;
            }
        }

        .search-for-result {
            color: #ff7d00;
            padding: 26px 82px;

            .lds-ring {
                top: 3px;
                right: unset;
                margin-right: 6px;

                div {
                    margin: 0;
                }
            }
        }
    }    
}