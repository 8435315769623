.integration-salestalk-login {
    label {
        display: block;
        font-size: 14px;
        letter-spacing: -0.4px;
        margin: 24px 0 4px 0;
    }

    .account-photo-wrapper {
        border-radius: 4px;
        background: #f3f3f3;
        width: 96px;
        height: 96px;
        position: relative;

        svg {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            font-size: 34px;
            color: #9c9c9c;
        }

        img {
            width: 100%;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }

    .account-name {
        font-size: 20px;
        letter-spacing: -0.6px;
    }

    .status-salestalk {
        color: #19C700;
        font-size: 16px;
        letter-spacing: -0.5px;

        img {
            position: relative;
            top: -1px;
        }
    }
}