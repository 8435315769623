.integration-google-business-api-detail-account {
    padding: 32px 30px;
    text-align: left;

    .colored-info-box {
        p {
            font-size: 16px !important;
            letter-spacing: -0.5px !important
        }

        color: #0C5F99;
        border: solid 1px #0c5f99;
        background-color: #EBF7FF !important;
        border-radius: 8px;
        white-space: nowrap;
        margin-bottom: 24px;
        padding: 11px 20px;
        box-shadow: 0px 2px 20px rgba(25, 25, 25, 0.18);

        svg {
            position: relative;
            margin-right: 4px;
            top: -1px;
            font-size: 18px;
        }
    }

    .comp-back-button {
        margin: 0 0 24px 0;
    }

    .nav-item {
        cursor: pointer;
        color: rgba(25, 25, 25, 0.4);
        font-size: 14px;
        letter-spacing: -0.5px;
        padding: 0 0 12px 0;
        margin-right: 18px;

        a {
            padding: 0;
            border: none;
        }

        :hover {
            color: #ff7d00;
        }

        &.active-setup-tab {
            color: #ff7d00;
            border-bottom: solid 4px #ff7d00;
        }
    }

    .tab-content {
        margin: 32px 0 0;
    }
}