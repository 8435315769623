.chat-room-message-file-out-wrapper {
  margin-top: 16px;
  padding: 0 24px;
  width: 100%;
  position: relative;
  text-align: right;

  .header-text {
    margin: 0;
    font-size: 16px;
    letter-spacing: -0.3px;
    word-break: break-word;
  }

  .body-text {
    margin: 0;
    font-size: 16px;
    letter-spacing: -0.3px;
    word-break: break-word;
  }

  .footer-text {
    margin: 0;
    color: #6F6A66;
    font-size: 16px;
    letter-spacing: -0.3px;
    word-break: break-word;
  }

  .button-list-wrapper {
    width: 100%;
    // padding: 0 10px;
  }

  .button-text {
    height: 32px;
    text-align: center;
    width: 100%;
    border-radius: 8px;
    line-height: 1;
    margin-top: 8px;
    color: var(--main-dark-orange);
    cursor: pointer;
    border: solid 1px var(--main-dark-orange);
    border-radius: 8px;
    font-size: 14px;
    letter-spacing: -0.2px;
    padding: 8px 12px 0;

    svg {
      position: relative;
      top: 0;
      margin-right: 4px;
      vertical-align: top;
    }
  }

  .buttons-2 {
    .button-text-0 {
      border-radius: 8px 8px 0 0 !important;
    }

    .button-text-1 {
      border-radius: 0 0 8px 8px !important;
      margin-top: 0px !important;
      border-top: 0 !important;
    }
  }

  .buttons-3 {
    .button-text-0 {
      border-radius: 8px 8px 0 0 !important;
    }

    .button-text-1 {
      border-radius: 0 !important;
      margin-top: 0px !important;
      border-top: 0 !important;
    }

    .button-text-2 {
      border-radius: 0 0 8px 8px !important;
      margin-top: 0px !important;
      border-top: 0 !important;
    }
  }
}

.chat-room-message-file-out-wrapper .message-out-bubble-file {
  border-radius: 16px 2px 16px 16px;
  background: #FFF2E5;
  border: 1px solid rgba(25, 25, 25, 0.1);
  display: inline-block;
  max-width: calc(100% - 25px);
  // max-width: calc(100% - 75px);
  // max-width: 100%;
  // padding: 10px 62px 10px 66px;
  // padding: 10px 10px 10px 66px;
  padding: 10px;
  color: #191919;
  font-size: 16px;
  letter-spacing: -0.3px;
  line-height: 24px;
  position: relative;
  // cursor: pointer;
  color: #191919;
  text-align: left;
  min-height: 69px;

  .click-area-file {
    cursor: pointer;
  }
}

.chat-room-message-file-out-wrapper .message-out-bubble-file .file-size-and-extension {
  padding: 0 3px 0 0;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  letter-spacing: -0.2px;
  line-height: 1;
  white-space: nowrap;
}

.chat-room-message-file-out-wrapper .message-out-bubble-file .file-size-and-extension-download-progress {
  min-width: 155px;
  max-width: 100%;
}

.chat-room-message-file-out-wrapper .message-out-bubble-file .reply-message {
  width: calc(100% - 20px);
  border-radius: 4px;
  border-left: solid 5px #FF8F22;
  background: #E88422;
  color: #ffffff;
  position: absolute;
  top: 10px;
  left: 10px;
  padding: 5px 7px 5px 7px;
  line-height: 1;
}

.chat-room-message-file-out-wrapper .message-out-bubble-file .reply-message p {
  margin: 0;
  font-size: 12px;
  letter-spacing: -0.4px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 20px;
}

.chat-room-message-file-out-wrapper .message-out-bubble-file .reply-message p:nth-child(2) {
  letter-spacing: -0.2px;
}

.chat-room-message-file-out-wrapper .message-out-bubble-file .reply-message.reply-file .reply-file-thumbnail {
  display: inline-block;
  width: 40px;
  height: 40px;
  margin-right: 10px;
  position: relative;
}

.chat-room-message-file-out-wrapper .message-out-bubble-file .reply-message.reply-file .reply-file-thumbnail img {
  width: 40px;
  height: 40px;
}

.chat-room-message-file-out-wrapper .message-out-bubble-file .reply-message.reply-file .reply-file-thumbnail svg {
  position: absolute;
  left: 50%;
  top: 50%;
  font-size: 35px;
  transform: translate(-50%, -50%);
}

.chat-room-message-file-out-wrapper .message-out-bubble-file .reply-message.reply-file .reply-text-wrapper {
  width: calc(100% - 50px);
  display: inline-block;
  vertical-align: top;
}

.chat-room-message-file-out-wrapper .message-out-bubble-file .replied-file-message {
  width: calc(100% - 20px);
  position: absolute;
  top: 10px;
  left: 10px;
  background: #E87303;
  border-radius: 16px 2px 16px 16px;
  color: #ffffff;
  padding: 10px;
}

.chat-room-message-file-out-wrapper .message-out-bubble-file .file-inner-wrapper {
  position: relative;
  padding-left: 56px;
  padding-top: 3px;
}

.chat-room-message-file-out-wrapper .message-out-bubble-file .caption-text {
  font-size: 16px;
  letter-spacing: -.3px;
  // padding: 10px 73px 10px 10px;
  padding: 4px 10px 0 0;
  margin: 0;
  word-break: break-word;
}

.chat-room-message-file-out-wrapper .message-out-bubble-file .file-icon-wrapper {
  height: 32px;
  width: 32px;
  display: inline-block;
  background: var(--main-dark-orange);
  border-radius: 50%;
  vertical-align: top;
  position: relative;
}

.chat-room-message-file-out-wrapper .message-out-bubble-file .file-icon-wrapper svg {
  font-size: 16px;
  color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.chat-room-message-file-out-wrapper .message-out-bubble-file .forwarded-message {
  color: #ffffff;
  font-size: 12px;
  letter-spacing: -0.4px;
  line-height: 18px;
  display: block;
  margin-bottom: 5px;
}

.chat-room-message-file-out-wrapper .message-out-bubble-file .message-info {
  // position: absolute;
  // bottom: 11px;
  // right: 27px;
  margin: 0;
  color: rgba(25, 25, 25, 0.4);
  font-size: 10px;
  letter-spacing: -0.4px;
  line-height: 18px;
  // width: 26px;
  white-space: nowrap;
  width: 100%;
  text-align: right;

  .centered-dot {
    width: 2px;
    height: 2px;
    display: inline-block;
    vertical-align: top;
    background: rgba(25, 25, 25, 0.4);
    border-radius: 50%;
    position: relative;
    top: 9px;
  }
}

.chat-room-message-file-out-wrapper .message-out-bubble-file .group-sender-name-wrapper {
  color: #ff7d00;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0 0 4px 0;
  font-size: 14px;
}

.chat-room-message-file-out-wrapper .message-out-bubble-file .group-sender-name-wrapper .agent-span {
  color: rgba(25, 25, 25, 0.4);
}

.chat-room-message-file-out-wrapper .message-out-bubble-file .message-info img {
  margin-left: 4px;
  width: 16px;
  position: relative;
  /* top: 4px; */
}

.chat-room-message-file-out-wrapper .message-out-bubble-file .message-bubble-file-wrapper {
  width: 100%;
  display: inline-block;
}

.chat-room-message-file-out-wrapper .message-out-bubble-file .file-icon-wrapper {
  height: 46px;
  width: 46px;
  display: inline-block;
  background: var(--main-dark-orange);
  border-radius: 50%;
  vertical-align: top;
  position: absolute;
  left: 0;
  top: 0;

}

.chat-room-message-file-out-wrapper .message-out-bubble-file .file-icon-wrapper svg {
  font-size: 25px;
  color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.chat-room-message-file-out-wrapper .message-out-bubble-file .file-icon-wrapper .onprocess-wrapper {
  height: 40px;
  width: 40px;
  display: inline-block;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.chat-room-message-file-out-wrapper .message-out-bubble-file .message-bubble-file-wrapper {
  p {
    max-width: 100%;
    line-height: 16px;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0;
    display: inline-block;
    white-space: nowrap;
    font-size: 14px;
    letter-spacing: -.2px;
  }
}

.chat-room-message-file-out-wrapper .failed-sending-file-warning {
  font-size: 12px;
  letter-spacing: -0.4px;
  color: rgba(25, 25, 25, 0.4);
}

.chat-room-message-file-out-wrapper:hover .message-action-wrapper {
  display: inline-block;
  left: -50px;
}

.chat-room-message-file-out-wrapper:hover .message-action-dropdown {
  display: inline-block;
}

.chat-room-message-file-out-wrapper .message-action-wrapper {
  &.with-forward {
    left: -88px !important;

    .forward-button {
      display: inline-block !important;
    }
  }
}

.chat-room-message-file-out-wrapper .CircularProgressbar .CircularProgressbar-trail {
  stroke: #ffffff;
}

.chat-room-message-file-out-wrapper .CircularProgressbar .CircularProgressbar-path {
  stroke: #ff7d00;
}

//reply button
.hide-reply-button .message-out-bubble-file .message-action-wrapper {
  left: -45px !important;

  &.with-forward {
    left: -48px !important;
  }
}

//reply button