.carousel-card-form-add-template {
  border-radius: 8px;
  margin: 4px 0 0;
  border: solid 1px rgba(25, 25, 25, 0.2);
  background: #fafafa;
  padding: 16px 16px 16px 44px;
  position: relative;

  .carousel-card-body {
    font-size: 16px;
    letter-spacing: -0.3px;
    margin: 12px 0 0;
    padding: 0 34px 0 0;
  }

  .carousel-replace-media {
    position: absolute;
    left: 154px;
    top: 52px;
    padding: 0 12px 0 8px !important
  }

  .fi-drag-drop {
    position: absolute;
    top: 16px;
    left: 16px;
    // cursor: pointer;
  }

  .carousel-card-button {
    position: absolute;
    font-size: 12px;
    letter-spacing: -0.4px;
    top: 16px;
    right: 16px;

    b {
      cursor: pointer;
      margin-left: 12px;

      &.carousel-edit {}

      &.carousel-delete {}
    }
  }

  .carousel-card-media {
    width: 100px;
    height: 100px;
    border-radius: 8px;
    background: rgba(25, 25, 25, 0.2);
    position: relative;
    overflow: hidden;

    img,
    video {
      position: absolute;
      top: 50%;
      left: 50%;
      max-width: 100%;
      max-height: 100%;
      transform: translate(-50%, -50%);
    }

    &::before {
      content: "";
      width: 100px;
      height: 100px;
      position: absolute;
      top: 0;
      left: 0;
      border-radius: 8px;
      z-index: 1;
      background: rgba(25, 25, 25, 0.2);
    }

    svg {
      color: #fff;
      z-index: 2;
      font-size: 24px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

.integration-waba-create-message-template-account {
  padding: 32px 30px;
  text-align: left;

  .custom-checkbox-auth {
    label {
      &::before {
        top: 4px;
      }

      &::after {
        top: -1px;
      }
    }
  }

  .carousel-card-outer-wrapper {
    // .carousel-card {
    //   border-radius: 8px;
    //   margin: 4px 0 0;
    //   border: solid 1px rgba(25, 25, 25, 0.2);
    //   background: #fafafa;
    //   padding: 16px 16px 16px 44px;
    //   position: relative;

    //   .carousel-card-body {
    //     font-size: 16px;
    //     letter-spacing: -0.3px;
    //     margin: 12px 0 0;
    //     padding: 0 34px 0 0;
    //   }

    //   .carousel-replace-media {
    //     position: absolute;
    //     left: 154px;
    //     top: 52px;
    //     padding: 0 12px 0 8px !important
    //   }

    //   .fi-drag-drop {
    //     position: absolute;
    //     top: 16px;
    //     left: 16px;
    //     // cursor: pointer;
    //   }

    //   .carousel-card-button {
    //     position: absolute;
    //     font-size: 12px;
    //     letter-spacing: -0.4px;
    //     top: 16px;
    //     right: 16px;

    //     b {
    //       cursor: pointer;
    //       margin-left: 12px;

    //       &.carousel-edit {}

    //       &.carousel-delete {}
    //     }
    //   }

    //   .carousel-card-media {
    //     width: 100px;
    //     height: 100px;
    //     border-radius: 8px;
    //     background: rgba(25, 25, 25, 0.2);
    //     position: relative;
    //     overflow: hidden;

    //     img,
    //     video {
    //       position: absolute;
    //       top: 50%;
    //       left: 50%;
    //       max-width: 100%;
    //       max-height: 100%;
    //       transform: translate(-50%, -50%);
    //     }

    //     &::before {
    //       content: "";
    //       width: 100px;
    //       height: 100px;
    //       position: absolute;
    //       top: 0;
    //       left: 0;
    //       border-radius: 8px;
    //       z-index: 1;
    //       background: rgba(25, 25, 25, 0.2);
    //     }

    //     svg {
    //       color: #fff;
    //       z-index: 2;
    //       font-size: 24px;
    //       position: absolute;
    //       top: 50%;
    //       left: 50%;
    //       transform: translate(-50%, -50%);
    //     }
    //   }
    // }
  }

  .category-card-wrapper {
    display: block;
    width: 100%;

    .category-card {
      vertical-align: top;
      border: solid 1px #dcdcdc;
      border-radius: 8px;
      width: calc((100% / 3) - 16px);
      margin-right: 24px;
      padding: 10px 16px;
      display: inline-block;
      cursor: pointer;

      img {
        margin-right: 10px;
      }

      &:nth-last-child(1) {
        margin-right: 0;
      }

      &:hover {
        border: solid 1px var(--main-dark-orange);
      }

      &.category-card-active {
        border: solid 1px var(--main-dark-orange);
      }
    }
  }

  .category-message-wrapper {
    margin: 8px 0 0;

    label {
      width: unset !important;
      display: inline-block !important;
      padding: 0 0 0 28px !important;
      font-size: 16px;
      letter-spacing: -0.3px;

      p {
        font-size: 12px;
        letter-spacing: -0.2px;
        color: rgba(25, 25, 25, 0.6);
        margin: 0 0 8px 0;

        &.no-margin-bottom {
          margin: 0;
        }
      }
    }

    &.with-border-bottom {
      border-bottom: solid 1px #dcdcdc;
      padding-bottom: 24px;
    }
  }

  #tooltip-sample-param {
    display: inline-block;
    position: relative;
    top: -1px;
  }

  .buttons-wrapper {
    padding: 12px;
    background: rgba(243, 243, 243, 1);
    border: solid 1px rgba(25, 25, 25, 0.1);
    border-radius: 8px;
    margin: 8px 0 12px;
    position: relative;

    .input-param {
      position: relative;
      width: 100%;

      &.param-right {
        input {
          width: calc(100% - 35px) !important;
        }

        span {
          color: rgba(25, 25, 25, 0.5);
          position: absolute;
          top: 50%;
          right: 0;
          transform: translate(0, -50%);
        }
      }

      &.param-left {
        input {
          width: calc(100% - 35px) !important;
          margin-left: 35px;
        }

        span {
          color: rgba(25, 25, 25, 0.5);
          position: absolute;
          top: 50%;
          left: 0;
          transform: translate(0, -50%);
        }
      }
    }

    .dropdown {
      button {
        margin: 0 !important;
      }
    }

    .button-delete {
      color: rgba(255, 63, 87, 1);
      font-size: 14px;
      position: absolute;
      top: 15px;
      right: 12px;
      cursor: pointer;
    }

    label {
      position: relative;

      .custom-tooltips {
        top: 4px;
      }

      &:nth-child(1) {
        padding: 0 !important;
      }

      .grey-font {
        display: inline-block;
      }

      span {
        color: rgba(25, 25, 25, 0.6);
        font-size: 14px;
        letter-spacing: -0.2px;
        position: absolute;
        right: 0;
        bottom: 0;

        &.right-18 {
          right: 18px !important;
        }
      }
    }

    .phone-number-wrapper {
      position: relative;

      svg {
        position: absolute;
        top: 14px;
        left: 12px;
        font-size: 22px;
      }

      input {
        padding-left: 36px !important;
      }
    }
  }

  .add-another-button {
    color: rgba(255, 126, 0, 1);
    font-size: 14px;
    padding-left: 6px;
    letter-spacing: -0.5px;
    cursor: pointer;
    line-height: 1;

    &:hover {
      color: var(--main-darker-orange);
    }

    svg {
      position: relative;
      top: -1px;
      margin-right: 4px;
    }
  }

  .add-another-card {
    padding-left: 0;
    padding-top: 12px;
    margin: 0;
  }

  .custom-checkbox {
    label {
      padding: 0 0 0 20px !important;
    }
  }

  .comp-back-button {
    margin: 0 0 16px 0;
    font-family: PTRootBold;
  }

  .integration-waba-create-message-template-account-box {
    background: #ffffff;
    border-radius: 8px;
    padding: 32px;
    border: solid 1px rgba(25, 25, 25, 0.1);

    .sample-param-input-wrapper {
      position: relative;
      padding-top: 26px;

      .max-char-variable {
        position: absolute;
        font-size: 14px;
        right: 0;
        top: 6px;
      }

      p {
        width: 150px;
        text-overflow: ellipsis;
        overflow: hidden;
        font-size: 14px;
        letter-spacing: -0.4px;
        display: inline-block;
        padding-right: 8px;
        padding-top: 11px;
        margin: 0;
      }

      input {
        width: calc(100% - 150px) !important;
        display: inline-block !important;
        border: solid 1px #dcdcdc;
        border-radius: 8px;
        vertical-align: top;
        height: 45px;
        color: rgba(25, 25, 25, 0.6);
      }
    }

    .form-content-wrapper {
      label {
        padding: 0 !important;
      }
    }

    .integration-waba-create-message-template-account-box-top {
      padding-bottom: 15px;
      position: relative;
      font-size: 20px;
      letter-spacing: -0.6px;

      button {
        position: absolute;
        top: -6px;
        right: 0;
        font-size: 16px;
        letter-spacing: -0.6px;
      }
    }

    .integration-waba-create-message-template-account-box-form {
      width: 100%;
      padding: 24px 0;
      border-top: solid 1px #dcdcdc;
      position: relative;

      .button-remove-template {
        position: absolute;
        top: 16px;
        right: 0;
      }

      &.box-top {
        border-bottom: solid 1px #dcdcdc;
      }

      .template-detail {
        font-size: 16px;
        letter-spacing: -0.5px;
        margin: 0;

        svg {
          position: relative;
          top: -2px;
          margin-right: 4px;
        }
      }

      label {
        padding: 24px 0 0;
        font-size: 16px;
        letter-spacing: -0.4px;
        display: block;
        position: relative;
        width: 100%;

        &.label-550 {
          width: 550px;

          span {
            position: absolute;
            right: 0;
          }
        }

        span {
          // position: absolute;
          // right: 0;
        }
      }

      input[type='text'] {
        padding: 12px 16px;
        border-radius: 6px;
        border: solid 1px #dcdcdc;
        width: 100%;
        font-size: 16px;
        letter-spacing: -0.3px;
        display: block;
      }

      .form-topic {
        // width: 400px;
        width: 100%;
        display: inline-block;

        .input-info-template-name {
          margin-bottom: 0;
        }

        .width-400 {
          width: 400px;
        }

        .width-550 {
          width: 550px;
        }
      }

      input[type='text']:disabled {
        background: rgba(25, 25, 25, 0.1);
        color: rgba(25, 25, 25, 0.2);
        border: none;
        cursor: not-allowed;
      }

      .integration-info-form {
        font-size: 14px;
        color: rgba(25, 25, 25, 0.8);
        letter-spacing: -0.2px;
      }

      .custom-select-waba-topic {
        .custom-select-option-value-wrapper {
          padding: 12px 16px !important;
          margin-bottom: 8px;
        }
      }

      &.box-bottom {
        position: relative;
        width: 100%;

        .box-bottom-left {
          display: inline-block;
          vertical-align: top;
          // width: 550px;
          width: 50%;

          .input-with-counter {
            position: relative;

            .input-counter {
              position: absolute;
              top: -28px;
              right: 0;
              font-size: 14px;
              letter-spacing: -0.2px;
            }
          }
        }

        .box-bottom-right {
          // width: calc(100% - 550px);
          width: 50%;
          vertical-align: top;
          display: inline-block;
          padding-left: 24px;
        }
      }

      .button-wrapper {
        position: relative;
        width: 100%;
      }
    }
  }

  .color-red {
    color: #FF3F57;
  }

  .color-blue {
    color: #3BAEFF;
  }

  .color-orange {
    color: #ff7d00;
  }
}

.template-type-selection-wrapper {
  width: 100%;

  .selection-template-type {
    border: solid 1px #dcdcdc;
    border-radius: 8px;
    padding: 25px 25px 25px 110px;
    display: inline-block;
    margin-right: 15px;
    width: calc((100% / 3) - 10px);
    position: relative;
    vertical-align: top;
    cursor: pointer;
    transition: ease-in-out .1s;

    &.active-template-type {
      border-color: #ff7d00;
    }

    &:hover {
      border-color: #ff7d00;
    }

    label {
      padding: 0 !important;
      position: absolute !important;
      left: 25px;
      top: 50%;
      transform: translate(0, -50%);
    }

    &:nth-last-child(1) {
      margin-right: 0;
    }

    .template-type-icon-wrapper {
      border-radius: 50%;
      width: 48px;
      height: 48px;
      position: absolute;
      left: 50px;
      top: 50%;
      transform: translate(0, -50%);
      background: rgba(242, 213, 251, 1);

      img {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }

      svg {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        font-size: 24px;
      }

      &.static-media {
        background: #ECFFE9;
        color: #19C700;
      }

      &.dynamic-media {
        background: #EBF7FF;
        color: #3BAEFF;
      }
    }

    p {
      margin: 0 !important;
      font-size: 16px;
      letter-spacing: -0.5px;

      &:nth-last-child(1) {
        font-size: 14px;
        letter-spacing: -0.2px;
        color: rgba(25, 25, 25, 0.8);
      }
    }
  }
}

.header-media-type-selection-wrapper {
  width: 100%;

  .header-media-selection {
    vertical-align: top;
    border-radius: 8px;
    border: solid 1px #dcdcdc;
    display: inline-block;
    position: relative;
    width: calc((100% / 3) - 6px);
    margin-right: 9px;
    font-size: 14px;
    letter-spacing: -0.2px;
    padding: 14px 10px 14px 45px;
    cursor: pointer;
    transition: ease-in-out .1s;

    &:nth-last-child(1) {
      margin-right: 0;
    }

    &.active-header-media-selection {
      border-color: #ff7d00;
    }

    &:hover {
      border-color: #ff7d00;
    }
  }

  .header-media-icon-wrapper {
    width: 28px;
    height: 28px;
    border-radius: 50%;
    background: #EBF7FF;
    color: #3BAEFF;
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translate(0, -50%);

    &.header-media-image {
      background: #ECFFE9;
      color: #19C700;
    }

    &.header-media-video {
      color: #FF3F57;
      background: #ffecce;
    }

    svg {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    &.header-media-document {}

    &.header-media-image {}

    &.header-media-video {}
  }

  p {
    font-size: 14px;
    letter-spacing: -.2px;
  }

  &:nth-last-child(1) {
    margin-right: 0;
  }
}

.modal-conformation-carousel-card {
  button {
    width: calc(50% - 4px) !important;
    margin: 0 8px 0 0;

    &:nth-last-child(1) {
      margin: 0;
    }
  }
}