.main-integration-google-create-channel {
    label {
        width: 400px;
    }
    
    .error-or-info-comp {
        max-width: 100%;
        width: 400px;
    }
    
    input[type="file"] {
        display: none;
    }

    input {
        width: 400px;
        display: block;
    }

    button {
        margin-right: 8px;
    }

    .create-channel-upload {
        width: 100%;
        position: relative;
        display: block;
        margin: 24px 0 0;

        .logo-wrapper {
            position: relative;
            width: 96px;
            height: 96px;
            display: block;
            background: #f3f3f3;
            border-radius: 4px;

            svg {
                color: #9c9c9c;
                font-size: 32px;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }

            img {
                width: 96px;
                height: 96px;
                display: block;
                border-radius: 4px;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }

            .loading-wrapper {
                width: 96px;
                height: 96px;
                display: block;
                border-radius: 4px;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                background: rgba(25, 25, 25, .4);

                .LoadingRoll_1234fed {
                    width: 96px;
                    height: 96px;
                    display: block;
                    border-radius: 4px;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }
                .lds-ring {
                    width: 76px;
                    height: 76px;

                    div {
                        border: solid 5px #fff;
                        border-color: #fff transparent transparent transparent;
                        width: 76px;
                        height: 76px;
                    }
                }
            }
        }

        button {
            position: absolute;
            top: 57px;
            right: 0;
            margin: 0;
        }
    }
}