#retrieve-data-loading-page {
    text-align: center;
    padding-top: 30px;

    &.no-padding-top {
        padding-top: 0 !important;
    }

    img {
        width: 400px;
        margin-bottom: 30px;
    }

    .loading-retrieve-data {
        font-weight: bold;
    }
    
    .retrieve-loading-text {
        b {
            color: var(--main-dark-orange) !important;
            font-size: 16px !important;
            letter-spacing: -0.5px !important;
        }
        
        font-size: unset !important;
        display: inline-block;
        position: relative;
        top: -1px;
        margin-left: 6px;
        margin-bottom: 0 !important;
    }

    .lds-ring {
        top: 3px;
        right: 0;
        width: 18px;
        height: 18px;
        margin: 0 !important;
    }

    .lds-ring div {
        margin: 0;
        width: 18px;
        height: 18px;
        border: solid 2px #ff7d00;
        border-color: #ff7d00 transparent transparent transparent;
    }

    .fail-retrive-data {
        text-align: center;
        font-size: 16px;
        font-weight: bold;
        letter-spacing: -0.5px;
        text-align: center;
        color: #9b9b9b;
        line-height: 1;
        color: rgba(25, 25, 25, 0.6);

        p {
            margin: 0;
        }

        svg {
            color: #C0C5CD;
            font-size: 61px;
            margin: 0 0 26px;
        }

        button {
            height: 40px;
            width: 138px;
            font-size: 16px;
            letter-spacing: -.2px;
            margin: 24px 0 0;
        }

        p {
            margin: 0 0 10px;

            &:nth-child(4) {
                font-size: 16px;
                font-weight: normal;
            }
        }

        &.tier-blocking {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 5;

            .tier-blur-layer {
                position: absolute;
                top: 0;
                left: 0;
                background: rgba(255, 255, 255, .8);
                // filter: blur(2px);
                backdrop-filter: blur(1px);
                width: 100%;
                height: 100%;
                border-radius: 8px;
            }

            .alert-component {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                color: #044B84;
                border: solid 1px rgba(12, 95, 153, 1);
                background: rgba(235, 247, 255, 1);
                font-size: 14px;
                letter-spacing: -.4px;
                padding: 17px 124px 17px 48px;
                display: inline-block;
                // max-width: 90%;
                white-space: nowrap;

                svg {
                    font-size: 20px;
                    color: #044B84;
                    left: 20px;
                }

                .contact-us-button {
                    position: absolute;
                    width: 99px;
                    border-radius: 4px;
                    height: unset;
                    font-size: 12px;
                    letter-spacing: -.4px;
                    right: 16px;
                    top: 11px;
                    margin: 0;
                    border: none;
                    box-shadow: none;
                    color: #ffffff;
                    padding: 7px 0 5px;
                    // line-height: 20px;

                    svg {
                        color: #ffffff;
                        position: relative;
                        margin: 0;
                        left: unset;
                        top: unset;
                        transform: unset;
                        margin-right: 4px;
                        font-size: 14px;
                        top: -1px;
                    }
                }
            }
        }
    }
}