#new-organization-header #new-organization-header-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    border-bottom: 1px solid #dcdcdc;
    text-align: left;
    width: 100%;
    background: #ffffff;
    z-index: 2;
}

#new-organization-header #new-organization-header-content {
    padding: 30px 15px 30px;
    position: relative;
}

#new-organization-header #new-organization-header-content #new-organization-title {
  font-size: 24px;
  font-weight: bold;
  letter-spacing: -0.4px;
  color: #191919;
  height: 40px;
  line-height: 40px;
  border-left: solid 1px #dcdcdc;
  padding-left: 15px;
  margin-left: 15px;
  display: inline-block;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
}

#new-organization-step-wrapper {
    height: 28px;
    line-height: 28px;
    position: relative;
    /* top: 50%; */
    /* transform: translate(0, -50%); */
    /* left: 380px; */
    display: inline-block;
}

#new-organization-step-wrapper .new-organization-number {
    background: #dcdcdc;
    border-radius: 50%;
    color: #ffffff;
    width: 18px;
    display: inline-block;
    height: 17px;
    line-height: 17px;
    text-align: center;
    font-size: 11px;
    margin-right: 8px;
    position: relative;
    top: -2px;
}

#new-organization-step-wrapper .new-organization-number.active-number {
    background: var(--main-dark-orange);
}

#new-organization-step-wrapper span {
    font-size: 16px;
    letter-spacing: -0.4px;
    color: #9b9b9b;
}

#new-organization-step-wrapper span.active-text {
    color: var(--main-dark-orange);
}

#new-organization-step-wrapper .new-organization-line {
    border-top: solid 2px #dcdcdc;
    width: 40px;
    display: inline-block;
    margin: 0 11px;
    position: relative;
    top: -4px;
}

#new-organization-step-wrapper .new-organization-line.active-line {
    border-color: var(--main-dark-orange);
}

.logout-organization {
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translate(0, -50%);
    color: #ff7e00;
    cursor: pointer;
}

.logout-organization:hover {
    color: #e87200;
}