#verify-email-wrapper {
    position: fixed;
    padding: 48px;
    margin: 60px;
    width: calc(100% - 120px);
    max-width: 540px;
    top: 50%;
    left: 50%;
    transform: translate(calc(-50% - 60px), calc(-50% - 60px));
    background: #ffffff;
    border-radius: 32px;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1), 0 20px 20px 0 rgba(0, 0, 0, 0.18);
    text-align: center;
    box-sizing: border-box;
}

#verify-email-wrapper img {
    width: 154px;
    display: inline-block;
}

#verify-email-wrapper p {
    margin: 10px 0 0;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.3px;
    text-align: center;
    color: #191919;
}

#verify-email-wrapper a {
    color: #FF7E00;
}

#verify-email-wrapper .verification-status-title {
    margin: 10px 0 0;
    font-size: 24px;
    font-weight: bold;
    line-height: 30px;
    letter-spacing: -0.6px;
    text-align: center;
    color: #191919;
}

#verify-email-wrapper .verification-status-failed {
    padding: 9.5px;
    margin: auto;
    width: 88px;
    height: 88px;
    background: #FF3F5733;
    border-radius: 44px;
}

#verify-email-wrapper .verification-status-failed svg {
    padding: 12.5px;
    width: 69px;
    height: 69px;
    background: #FF3F57;
    color: white;
    border-radius: 35px;
}

#verify-email-wrapper .verification-status-success {
    padding: 9.5px;
    margin: auto;
    width: 88px;
    height: 88px;
    background: #11830033;
    border-radius: 44px;
}

#verify-email-wrapper .verification-status-success svg {
    padding: 12.5px;
    width: 69px;
    height: 69px;
    background: #118300;
    color: #cfe6cc;
    border-radius: 35px;
}

#verify-email-wrapper .lds-ring {
    width: 88px;
    height: 88px;
}

#verify-email-wrapper .lds-ring div {
    width: 72px;
    height: 72px;
    border-width: 8px;
    margin: 8px;
}
