.header-type-quick-reply-outer-wrapper {
  button {
    margin-top: 12px !important;
  }
  
  .label-header-type-quick-reply {
    width: 570px;
  }

  input[type="text"] {
    width: 570px !important;
  }

  .header-type-quick-reply-wrapper {
      width: 100%;
      position: relative;
      display: block;
  
      .message-type-selection-selection {
          vertical-align: top;
          border-radius: 8px;
          border: solid 1px #dcdcdc;
          display: inline-block;
          position: relative;
          width: calc(25% - 6px);
          margin-right: 8px;
          font-size: 14px;
          letter-spacing: -0.2px;
          padding: 14px 14px 14px 49px;
          cursor: pointer;
          transition: ease-in-out .1s;
      
          &:nth-last-child(1) {
            margin-right: 0;
          }
      
          &.active-message-type-selection-selection {
            border-color: #ff7d00;
          }
      
          &:hover {
            border-color: #ff7d00;
          }
      }
      
      .message-type-selection-icon-wrapper {
          width: 28px;
          height: 28px;
          border-radius: 50%;
          position: absolute;
          top: 50%;
          left: 14px;
          transform: translate(0, -50%);
  
          img {
              width: 25px;
              height: 25px;
          }
      
          &.message-type-selection-text {
          //   background: #FFD4CC;
          }
          
          &.message-type-selection-image {
              // background: #EBF7FF;
          }
      
          img {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
      }
  }

  .header-content {
    font-size: 14px;
    letter-spacing: -0.4px;
    display: block;
    margin-top: 12px;
  }
  
  .button-upload-document {
    width: 220px;
    margin: 12px 0 0;
  }

  .main-button-22 {
    width: unset !important;
    padding: 0 8px 0 12px !important;
    margin: 12px 8px 0  0;
  }

  input[type="file"] {
    display: none;
  }

  .media-value-wrapper {
    border-radius: 8px;
    margin: 8px 0 0;
    width: 48px;
    height: 48px;
    background: #ebebeb;
    position: relative;
    overflow: hidden;

    img, video {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      max-width: 100%;
      max-height: 100px;
    }

    .media-value-loading-wrapper {
      position: absolute;
      border-radius: 8px;
      width: 48px;
      height: 48px;
      border-radius: 8px;
      top: 0;
      left: 0;
      background: rgba(25, 25, 25, 0.1);
      display: none;
      cursor: pointer;

      svg {
        font-size: 20px;
        color: #fff;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }

      .lds-ring {
        width: 38px;
        height: 38px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        div {
          margin: 0;
          width: 38px;
          height: 38px;
          border: solid 3px #fff;
          border-color: #fff transparent transparent transparent;
        }
      }
    }

    &:hover {
      .media-value-loading-wrapper {
        display: block;
      }
    }
  }
  
  .document-value-wrapper {
    width: 100%;
    margin-top: 20px;
    font-size: 14px;
    // padding-right: 30px;
    white-space: nowrap;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  
    .lds-ring {
      margin-left: 8px;
      position: relative;
      top: 4px;
      
      div {
        margin: 0;
      }
    }

    svg {
      position: relative;
      top: -1px;
      margin-right: 4px;
      font-size: 14px;   
      color: var(--main-dark-orange);
    }
  }
}