.labels-container {
	&:nth-child(1) {
		margin: 24px 0 0;
	}

	&:nth-child(2) {
		margin: 12px 0 24px;
	}

	
	.label-switch-action-title {
		font-size: 16px;
		letter-spacing: -0.5px;
		margin: 0;
	}

	.label-switch-action-description {
		font-size: 14px;
		letter-spacing: -0.2px;
		margin: 6px 0 0;
	}

	.labels-switch-wrapper {
		width: 100%;
		position: relative;
		padding: 0 50px 0 0;

		.custom-switch-wrapper {
			position: absolute;
			right: 4px;
			top: 10px;
			width: 38px;
			height: 22px;

			.custom-switch:before {
				width: 14px;
				height: 14px;
				left: 4px;
				top: 4px;
			}

			input:checked + .custom-switch:before {
				width: 14px;
				height: 14px;
				transform: translate(17px);
			}
		}
	}

	.title {
		font-size: 20px;
		letter-spacing: -0.6px;
		padding: 0 0 14px;
		border-bottom: solid 1px #dcdcdc;
		margin: 0 0 24px;
		width: 100%;
	}

	.header-labels {
		width: 100%;
		display: flex;
		justify-content: space-between;
		align-items: center;
		border-bottom: solid 1px #dcdcdc;
		margin-bottom: 12px;
		padding-bottom: 12px;
		color: #191919;

		.title-label-list {
			margin: 0;
			padding: 0;
			border: none;
		}
	}
	
	

	.labels-top-action-wrapper {
		// padding: 0 0 11px;
		// border-bottom: solid 1px #dcdcdc;
		position: relative;
		width: 100%;
		margin: 0 0 24px;
		display: flex;
		justify-content: space-between;
		align-items: flex-start;

		.create-label-button {
			font-size: 16px;
			letter-spacing: -0.5px;
			padding: 0 32px;
			height: 40px;
			position: absolute;
			right: 0;
			top: 50%;
			transform: translate(0, -50%);
			
			svg {
				font-size: 20px;
				position: relative;
				top: -2px;
				margin-right: 4px;
			}
		}
	}
}

.labels-outer-wrapper {
	width: 100%;
	position: relative;
}
.table-labels {
	.col_label {
		width: 200px;
		
		p {
			white-space: nowrap;
			max-width: 200px;
			overflow: hidden;
			text-overflow: ellipsis;
			display: inline-block;
		}
	}

	.col_description {
		width: calc(100% - 400px);
	}

	.col_action {
		width: 200px;
	}

	thead {
		tr {
			background: #ffffff;
			color: #191919;
			// border-top: solid 1px #dcdcdc;
		}

		td {
			font-size: 14px;
			letter-spacing: -0.4px;
		}
	}
	tbody {
		td {
			position: relative;
			font-size: 14px;
			letter-spacing: -0.2px;
			padding: 8px 10px;

            .col-action-button-wrapper {
				display: flex !important;
				justify-content: flex-end;
                position: relative;
                top: 0;
                right: unset;
				transform: unset;

                .view-agent {
                    color: #ff7d00;
					letter-spacing: -0.5px;
					position: relative;
					left: -6px;
                }

                .view-agent:hover {
                    color: var(--main-darker-orange);
                }

                svg {
                    margin-right: 3px;
                    position: relative;
                    top: -2px;
				}
								
				.single-remove-button {
					margin-left: 22px;

					svg {
						color: #FF3F57;
						margin-right: 5px;
					}
				}
            }

            &.col_action {
                width: 200px !important;
			}

			&.col_label,
			&.col_description,
			&.col_action {
				padding: 8px 10px;
			}
			
			.label-badge-wrapper {
				font-size: 12px;
				letter-spacing: -0.4px;
				color: rgba(25, 25, 25, 0.6);
				background: rgba(25, 25, 25, 0.05);
				border-radius: 4px;
				padding: 2px 8px;
				color: #ffffff;
				margin: 0;
			}
		}
    }
	button {
		svg {
			position: relative !important;
			right: unset !important;
			top: -2px !important;
			margin-right: 5px;
		}
	}
	.col-action-button-wrapper {
		position: absolute;
		right: 7px;
		top: 50%;
		transform: translate(0, -50%);
	}
	.single-remove-button {
		margin-left: 16px;
		color: #ff3f57;
		&:hover {
			color: #e83b51;
		}
	}
	.shortcut-badge {
		font-size: 12px;
		color: rgba(25, 25, 25, 0.6);
		letter-spacing: -0.4px;
		background: #f3f3f3;
		line-height: 1;
		border-radius: 4px;
		padding: 1px 8px 3px;
	}
	td {
		max-width: unset !important;
	}
}
