.modal-quick-reply-manage-row {
  position: fixed;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100%;
  background: rgba(25, 25, 25, 0.8);
  visibility: hidden;
  opacity: 0;
  right: 0;
  transition: visibility 0.3s, opacity 0.5s ease-in-out;
  z-index: 4;

  .manage-row-button-wrapper {
    width: 100%;
    height: 72px;
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 13px 24px;
    box-shadow: 0px 2px 20px 0px rgba(25, 25, 25, 0.18);

    button {
      width: calc(50% - 2px);
      margin-right: 4px;

      &:nth-child(2) {
        margin-right: 0 !important;
      }
    }
  }

  &.active-modal-quick-reply-manage-row {
    /* right: 0; */
    opacity: 1;
    visibility: visible;
    transition: visibility 0s, opacity 0.5s ease-in-out;
  }

  .modal-body {
    position: absolute;
    top: 8px;
    right: -100%;
    left: unset;
    transform: unset;
    height: calc(100% - 8px);
    width: 640px;
    display: inline-block;
    border-radius: 8px 0 0 0;
    background: #ffffff;
    padding: 0;
    max-height: calc(100% - 8px);
    // height: 100%;
    transition: ease-in-out 0.4s;

    &.action-modal-body-quick-reply-manage-row {
      right: 0;
    }

    .close-modal-interactive {
      border-radius: 50%;
      width: 48px;
      height: 48px;
      background: #ffffff;
      color: #757575;
      position: absolute;
      left: -65px;
      top: 0;
      text-align: center;
      cursor: pointer;

      &:hover {
        background: #f9f9f9;
      }

      svg {
        position: relative;
        font-size: 25px;
        top: 11px;
      }
    }
  }

  .new-interactiveList-message-container {
    padding: 0 32px 32px;
    max-height: calc(100% - 72px);
    overflow: auto;
  }

  .new-interactiveList-message-title {
    margin: 32px 0 0;

    svg {
      position: relative;
      top: 6px;
      vertical-align: top;
      margin-right: 4px;
      font-size: 19px;
    }
    
    b {
      font-size: 20px;
      letter-spacing: -0.2px;

    }

    p {
      margin: 4px 0 12px;
      font-size: 14px;
      letter-spacing: -0.6px;
      color: rgba(25, 25, 25, 0.6);
    }
  }

  .button-text-wrapper-form {
    border-radius: 8px;
    border: solid 1px rgba(25, 25, 25, 0.1);
    background: #f3f3f3;
    padding: 12px;
    margin: 8px 0 0;
    font-size: 16px;
    letter-spacing: -0.5px;
    width: calc(100% - 6px);

    input[type="text"] {
      background: white;
      border-radius: 8px;
      border: 1px solid rgba(25, 25, 25, 0.1);
      height: 48px;
      color: #191919;
      font-size: 16px;
      letter-spacing: -0.3px;
      width: 316px;
      max-width: 100%;
      padding: 0 16px;
      width: 100%;
    }

    label {
      width: 100%;
      position: relative;

      .grey-font {
        position: absolute;
        right: 0;
        top: 0;
      }

      &.row-description {
        height: 24px;
        margin: 8px 0 0;
      }
    }

    .manage-rows {
      margin: 18px 0 0;
      font-size: 14px;
      letter-spacing: -0.5px;
      color: var(--main-dark-orange);
      cursor: pointer;

      svg {
        position: relative;
        top: -1px;
        margin-right: 4px;
      }

      &:hover {
        color: var(--main-darker-orange);
      }
    }

    &.button-text-wrapper-form-1 {
      margin: 24px 0 0 !important;
    }

    label {
      margin-top: 0;
    }

    .remove-button-text {
      position: relative;
      top: -1px;
      margin-left: 4px;
      cursor: pointer;
      color: rgba(255, 63, 87, 1) !important;
    }
  }

  .add-another-button {
    margin: 16px 0 0;
    font-size: 14px;
    cursor: pointer;
    letter-spacing: -0.5px;
    color: var(--main-dark-orange);

    svg {
      position: relative;
      margin-right: 4px;
      top: -1px;
    }

    &:hover {
      color: var(--main-darker-orange);

      svg {
        color: var(--main-darker-orange);
      }
    }
  }
}