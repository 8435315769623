.modal-preview-contacts {
    .modal-content {
        width: 95vw;
        height: 90vh;
        overflow: auto;
        border-radius: 8px;
        
        .modal-body {
            padding: 0;
            position: relative;
            border-radius: 8px;
            height: 100%;

            .modal-preview-contacts-title {
                font-size: 24px;
                letter-spacing: -0.6px;
                padding: 34px;
                margin: 0;

                svg {
                    position: relative;
                    top: -2px;
                }
            }

            .modal-preview-contacts-close {
                color: #ff7d00;
                cursor: pointer;
                font-size: 24px;
                position: absolute;
                top: 34px;
                right: 34px;
                
                &:hover {
                    color: var(--main-darker-orange);
                }
            }

            .modal-preview-contacts-table-wrapper {
                width: 100%;
                overflow: auto;
                height: calc(100% - 195px);
            }

            table {
                width: 100%;

                thead {
                    tr {
                        td {
                            vertical-align: top;
                            padding: 12px 14px;
                            max-width: 200px;
                            word-break: break-word;

                            &:nth-child(1) {
                                padding: 12px 14px 12px 34px;
                            }
                        }
                    }
                }

                tbody {
                    tr {
                        border-top: solid 1px rgba(25, 25, 25, .1);

                        td {
                            vertical-align: top;
                            max-width: 200px;
                            padding: 14px;
                            word-break: break-word;

                            &:nth-child(1) {
                                padding: 12px 14px 12px 34px;
                            }
                        }
                    }
                }
            }

            #pagination-wrapper {
                height: 87px;
                line-height: 87px;
                padding: 0 34px;
            }
        }
    }
}