.create-organization-outer-wrapper {
    padding: 32px;
    border: solid 1px rgba(25, 25, 25, .1);
    background: #fff;
    border-radius: 8px;
    
    .create-organization-head-title {
        font-size: 24px;
        letter-spacing: -0.4px;
        padding: 8px 0 0 0;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 100%;
    }

    .create-organization-head {
        position: relative;
        width: 100%;
        height: 66px;
        border-bottom: solid 1px rgba(25, 25, 25, .1);

        &.create-organization-head-top {
            height: unset;
        }
    }

    .create-organization-head-button {
        display: inline-block;
        position: absolute;
        right: 0;
        top: 0;

        button {
            margin: 0 0 0 8px;
        }

        .no-fill-button {
            width: 186px;
        }
    }

    .create-organization-body {
        width: 100%;
        position: relative;

        button {
            position: absolute;
            right: 0;
            top: 3px;
        }

        .create-organization-body-1 {
            margin-top: 32px;
            
            .create-organization-body-1-inner {
                position: relative;
                padding: 0 210px 0 54px;
            }

            .create-organization-body-1-icon-wrapper {
                width: 48px;
                height: 48px;
                background: #fff;
                border-radius: 8px;
                box-shadow: 0px 2px 20px 0px rgba(25, 25, 25, 0.05);
                position: absolute;
                top: -1px;
                left: 0;
            }

            .create-organization-body-1-icon {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }

        .create-organization-body-2 {
            vertical-align: top;
            position: relative;
            top: 2px;

            .create-organization-body-2-title {
                color: rgba(25, 25, 25, .6);
                font-size: 10px;

                b {
                    letter-spacing: 1.5px !important;
                }

                margin: 0 0 4px 0;
            }

            .create-organization-body-2-desc {
                font-size: 16px;
                letter-spacing: -0.5px;
                color: #191919;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                max-width: 100%;
                margin: 0;
            }
        }
    }
}

#page-billing-organization {
    height: calc(100vh - 90px);
    width: 100%;
    background: #f8f8f8;
    padding: 40px 0 0 0;
    margin-top: 87px;
    box-sizing: border-box;
    position: relative;

    .comp-back-button {
        margin: 0 0 26px 0;
    }
}

#page-billing-organization b {
    // font-size: 20px;
    font-weight: bold;
    letter-spacing: -0.4px;
    // color: #191919;
}

#page-billing-organization label {
    font-size: 16px;
    font-weight: bold;
    letter-spacing: -0.4px;
    color: #191919;
    display: block;
    margin: 24px 0 10px;
    position: relative;

    .input-counter {
        color: rgba(25, 25, 25, .4);;
        position: absolute;
        top: 0;
        left: 405px;
        width: 65px;
    }
}

#page-billing-organization label .custom-tooltip {
    padding: 14px;
    color: #ffffff;
    background: #4a4a4a;
    letter-spacing: 0.4px;
    width: 219px;
    font-weight: normal;
    border-radius: 8px;
    position: absolute;
    top: -35px;
    right: -110px;
    font-size: 14px;
}

#page-billing-organization label .custom-tooltip::before {
    content: "";
    border-bottom: solid 12px #4a4a4a;
    border-top: solid 12px transparent;
    border-left: solid 12px transparent;
    border-right: solid 12px transparent;
    transform: rotate(-90deg);
    width: 20px;
    height: 20px;
    position: absolute;
    top: 35px;
    left: -24px;
}

#page-billing-organization .span-optional {
    font-size: 14px;
    letter-spacing: -0.2px;
    color: #9b9b9b;
    font-weight: normal;
    margin-left: 4px;
}

#page-billing-organization input[type="text"], 
#page-billing-organization input[type="password"],
#page-billing-organization textarea {
    padding: 10px 15px;
    border-radius: 8px;
    border: solid 1px #dcdcdc;
    display: block;
    width: 450px;

    &::placeholder {
        color: rgba(25, 25, 25, .4);
    }
}

#page-billing-organization .payment-div-50 {
    width: 220px;
    margin-right: 10px;
    display: inline-block;
}

#page-billing-organization .payment-div-50 svg {
    color: #9B9B9B;
    position: relative;
    top: -1px;
    margin-left: 5px;
    cursor: pointer;
}

#page-billing-organization .payment-div-50 input {
    width: 100%;
}

#page-billing-organization textarea {
    resize: none;
}

#page-billing-organization .country-dropdown-wrapper {
    display: inline-block;
    width: 450px;
    position: relative;
}

#page-billing-organization .country-dropdown-wrapper .dropdown-value-wrapper {
    padding: 10px 15px;
    border-radius: 8px;
    border: solid 1px #dcdcdc;
    display: block;
    width: 450px;
    background: #ffffff;
    cursor: pointer;
}

#page-billing-organization .country-dropdown-wrapper .arrow-down-wrapper {
    border-left: solid 1px #dcdcdc;
    text-align: center;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(0, -50%);
    line-height: 46px;
    height: 46px;
    width: 49px;
    font-size: 20px;
    color: #727272;
    z-index: 1;
}

#page-billing-organization .country-dropdown-wrapper .option-list-wrapper {
    background: #ffffff;
    border:solid 1px #dcdcdc;
    position: absolute;
    top: 46px;
    left: 0;
    border-radius: 8px;
    width: 250px;
    z-index: 1;
}

#page-billing-organization .country-dropdown-wrapper .option-list-wrapper ul {
    list-style: none;
    padding: 0;
    margin: 0;
    max-height: 204px;
    overflow-y: auto;
}

#page-billing-organization .country-dropdown-wrapper .option-list-wrapper ul li {
    padding: 5px 20px;
    cursor: pointer;
    transition: ease-in-out 0.3s;
}

#page-billing-organization .country-dropdown-wrapper .option-list-wrapper ul li:hover {
    color: var(--main-dark-orange);
}

#page-billing-organization .payment-setting-foot {
    position: relative;
    border-top: solid 1px #dcdcdc;
    margin-top: 30px;
}

#page-billing-organization .payment-setting-foot button {
    margin-top: 35px;
    // line-height: 1;
    // padding: 12px 0;
}

#page-billing-organization .payment-setting-foot button svg {
    font-size: 20px;
    position: relative;
    top: -2px;
}

#page-billing-organization #organization-billing-header {
    /* padding-bottom: 20px; */
    /* margin-bottom: 20px; */
    /* border-bottom: solid 1px #dcdcdc; */
}

// #page-billing-organization #organization-billing-header b {
//     font-size: 20px;
//     letter-spacing: -0.4px;
// }

#page-billing-organization .orange-button .lds-ring div {
    border-color: #ffffff transparent transparent transparent;
}

#page-billing-organization .orange-button {
    // width: 203px;
    // height: 48px;
    // position: relative;
}

#page-billing-organization .orange-button .lds-ring {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

#page-billing-organization .orange-button .lds-ring div {
    margin: 0;
}