.newButton {
  appearance: none;
  -webkit-appearance: none;
  position: relative;
  letter-spacing: -0.5px;
  font-size: 16px;
  vertical-align: baseline;
}

.newButton__icon {
  margin-right: 4px;
}

.newButton.outline-orange {
  background: #ffffff !important;
  color: var(--main-dark-orange) !important;
}

.newButton:disabled {
  background: rgba(0, 0, 0, 0.1) !important;
  color: rgba(0, 0, 0, 0.4) !important;
}

.newButton svg {
  position: relative;
  top: -1px;
}
