.chat-room-chat-history-tab-wrapper {
    overflow: hidden;
    height: 100%;
}

.chat-history-switch-content-outer {
    padding: 12px; 

    .chat-history-switch-content {
        width: 100%;
        border-radius: 12px;
        height: 29px;
        // border: solid 1px #dcdcdc;
        box-shadow: 0 0 1px #dcdcdc;
        position: relative;
        background: #f3f3f3;
        cursor: pointer;
        margin-top: 3px;

        .chat-history-active-panel {
            width: 50%;
            content: "";
            background: #ffffff;
            border-radius: 12px;
            height: 27px;
            position: absolute;
            left: 1px;
            top: 1px;
            transition: ease-in-out .2s;

            &.active-broadcast-panel {
                left: calc(50% - 1px);
            }
        }

        .chat-history-switch-panel {
            width: 50%;
            display: inline-block;
            text-align: center;
            font-size: 14px;
            letter-spacing: -0.4px;
            color: rgba(25, 25, 25, 0.6);
            padding-top: 4px;

            &:hover {
                color: rgba(25, 25, 25, 1); 
            }
            
            div {
                display: inline-block;
                position: relative;
            }

            .counter-case {
                font-size: 11px;
                color: #ffffff;
                text-align: center;
                background: #707070;
                border-radius: 12px;
                margin-left: 4px;
                height: 18px;
                display: inline-block;
                // line-height: 19px;
                padding: 1px 0 0;
                min-width: 18px;
                position: relative;
                top: -1px;
                // text-align: center;
                padding: 1px 5px 0 4px;
                // letter-spacing: 0;
            }

            &.active-chat-panel {
                color: #191919;

                .counter-case {
                    background: #474747;
                }
            }

            .centered-dot {
                width: 2px;
                height: 2px;
                display: inline-block;
                vertical-align: top;
                background: rgba(25, 25, 25, 0.6);
                border-radius: 50%;
                position: relative;
                top: 10px;
            }

            &.active-broadcast-panel-tab {
                color: #191919;
            }
        }
    }
}

.main-chat-history-content {
    height: calc(100% - 57px);
    position: relative;
}

.hide-panel-chat-history {
    display: none;
}
