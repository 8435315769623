.integration-all-channel {
  .sectionWrap {
    position: relative;

  }

  .your-channel-limit {
    width: 200px;
    position: absolute;
    bottom: 24px;
    left: 48px;

    p {
      color: #191919;
      font-size: 12px;
      letter-spacing: -0.4px;
      margin: 0 0 0;
    }

    .channel-limit {
      color: #ff7d00;
      font-size: 16px;
      letter-spacing: -0.5px;
    }
  }
}

.integration-livechat-list {
  text-align: left;

  .channels-list-loading-wrapper {
    .lds-ring {
      width: 50px;
      height: 50px;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      right: unset;

      div {
        border: 5px solid #fff;
        border-color: #ff7d00 transparent transparent transparent;
        width: 50px;
        height: 50px;
        margin: 0;
      }
    }
  }

  .channels-list-header {
    position: relative;
    padding-left: 42px;
    padding-bottom: 12px;
    border-bottom: solid 1px rgba(25, 25, 25, 0.1);

    .channels-list-header-icon {
      position: absolute;
      top: 0;
      left: 0;
      // text-align: left;
      width: 32px;
    }

    .channels-list-header-text-wrapper {
      text-align: left;

      b {
        font-size: 20px;
        letter-spacing: -0.6px;
      }

      p {
        margin: 0;
        font-size: 14px;
        letter-spacing: -0.2px;
        color: rgba(25, 25, 25, 0.8);
      }

      a {
        color: #ff7d00;
      }
    }

    .channels-list-header-button {
      position: absolute;
      right: 0;
      top: 0;
      font-size: 16px;
      letter-spacing: -0.5px;
      // padding: 7px 32px;

      // img {
      //   margin-right: 4px;
      //   position: relative;
      //   top: -2px;
      // }
    }
  }

  .channels-list-no-data {
    width: 100%;
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    b {
      font-size: 20px;
      letter-spacing: -0.6px;
      color: #191919;
    }

    p {
      color: rgba(25, 25, 25, 0.6);
      letter-spacing: -0.6px;
      font-size: 16px;
    }
  }

  .channels-list-outer-wrapper {
    position: relative;
    width: 100%;
    padding-top: 24px;
    text-align: left;

    .channels-list-inner-wrapper {
      width: calc((100% / 3) - 8px);
      border-radius: 8px;
      margin: 0 12px 12px 0;
      box-shadow: 0 2px 20px 0 rgba(25, 25, 25, 0.05);
      background: #ffffff;
      display: inline-block;
      vertical-align: top;
      padding: 12px 36px 12px 16px;
      cursor: pointer;
      position: relative;

      .medium-counter {
        letter-spacing: -0.4px;
        min-width: 20px;
        height: 20px;
        line-height: 20px;
        padding: 0 4px;
        background: #ff7d00;
        color: #ffffff;
        border-radius: 50%;
        position: absolute;
        right: 15px;
        top: 50%;
        transform: translate(0, -50%);
        font-size: 12px;
        text-align: center;
      }

      // p:nth-child(2) {
      //   font-size: 16px;
      //   letter-spacing: -0.5px;
      //   color: rgba(25, 25, 25, 0.6);
      //   margin: 0;
      //   white-space: nowrap;
      //   max-width: 100%;
      //   overflow: hidden;
      //   text-overflow: ellipsis;
      // }

      // span {
      //   display: block;
      //   font-size: 12px;
      //   letter-spacing: -0.2px;
      //   color: #191919;
      // }

      // p {
      //   margin: 10px 0 0;
      //   color: #ff7d00;
      //   font-size: 12px;
      //   letter-spacing: -0.2px;
      // }

      // svg {
      //   position: absolute;
      //   right: 12px;
      //   top: 12px;
      //   font-size: 22px;
      //   color: rgba(25, 25, 25, 0.6);
      // }

      // &:hover {
      //   box-shadow: 0 2px 20px 0 rgba(25, 25, 25, 0.18);

      //   p:nth-child(2) {
      //     color: #191919;
      //   }

      //   svg {
      //     color: #ff7d00;
      //   }
      // }

      .platform-wrapper {
        display: flex;
        align-items: center;

        .platform-name {
          // text-transform: capitalize;
          font-size: 16px;
          letter-spacing: -0.5px;
          color: rgba(25, 25, 25, 0.6);
          margin: 0;
          white-space: nowrap;
          padding-left: 2px;
          max-width: calc(100%);
          overflow: hidden;
          text-overflow: ellipsis;
        }

        img {
          margin-right: 5px;
          width: 24px;
        }
      }

      span {
        display: block;
        font-size: 12px;
        letter-spacing: -0.2px;
        color: #191919;
        white-space: nowrap;
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      svg {
        position: absolute;
        right: 12px;
        top: 12px;
        font-size: 22px;
        color: rgba(25, 25, 25, 0.6);
      }

      &:hover {
        box-shadow: 0 2px 20px 0 rgba(25, 25, 25, 0.18);

        .platform-name {
          color: #191919;
        }

        svg {
          color: #ff7d00;
        }
      }
    }
  }

  .link-livechat-account-list {
    &:nth-child(3n) {
      .channels-list-inner-wrapper {
        margin: 0 0 12px 0;
      }
    }
  }
}
