.add-card-wrapper {
    .add-card-title {
        font-size: 20px;
        letter-spacing: -0.6px;
        color: #191919;
        padding-bottom: 12px;
        border-bottom: solid 1px #dcdcdc;
    }

    .form-content-wrapper {
        width: 300px;

        .warning-error-red {
            color: #ff3f57;
            font-size: 12px;
            letter-spacing: -0.4px;
            margin: 4px 0 0;

            svg {
                font-size: 14px;
                position: relative;
                top: -2px;
                margin-right: 4px;
                color: #ff3f57 !important;
                cursor: unset !important;
            }
        }
        
        label {
            position: relative;

            .custom-tooltip {
                padding: 14px;
                color: #ffffff;
                background: #4a4a4a;
                letter-spacing: 0.4px;
                width: 219px;
                font-weight: normal;
                border-radius: 8px;
                position: absolute;
                top: -35px;
                right: -175px;
                font-size: 14px;
                z-index: 1;
    
                &::before {
                    content: "";
                    border-bottom: solid 12px #4a4a4a;
                    border-top: solid 12px transparent;
                    border-left: solid 12px transparent;
                    border-right: solid 12px transparent;
                    transform: rotate(-90deg);
                    width: 20px;
                    height: 20px;
                    position: absolute;
                    top: 35px;
                    left: -24px;
                }
            }
        }
        
        .span-optional {
            font-size: 14px;
            letter-spacing: -0.2px;
            color: #9b9b9b;
            font-weight: normal;
            margin-left: 4px;
        }
        
        input[type="text"],
        input[type="password"],
        textarea {
            padding: 10px 15px;
            border-radius: 8px;
            border: solid 1px #dcdcdc;
            display: block;
            width: 100%;
            position: relative;
        }

        .card-number {
            position: relative;

            input {
                padding: 10px 48px 10px 15px;
            }

            img {
                position: absolute;
                right: 16px;
                top: 13px;
                width: 22px;
            }
        }
        
        .payment-div-50 {
            width: 145px;
            margin-right: 10px;
            display: inline-block;
            vertical-align: top;

            &.no-margin {
                margin: 0;
            }
    
            svg {
                color: #9B9B9B;
                position: relative;
                top: -1px;
                margin-left: 5px;
                cursor: pointer;
            }
    
            input {
                width: 100%;
            }
        }
    
        textarea {
            resize: none;
        }
    
        button {
            &.submit-card {
                width: unset;
                padding: 0 24px;
                height: 40px;
                width: 134px;
                position: relative;

                .lds-ring {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }
            }
        }
    }

    .dropdown {
        button {
            padding: 11px 46px 11px 16px;
            // width: 245px;
            border: solid 1px #dcdcdc !important;
            border-radius: 8px;
            background: transparent !important;
            box-shadow: none !important;
            outline: none;
            color: #191919 !important;
            margin: 0;
            text-align: left;
            position: relative;

            p {
                margin: 0;
                line-height: 1;
            }

            svg {
                position: absolute;
                right: 13px;
                top: 50%;
                transform: translate(0, -50%);
                font-size: 16px;
                transition: ease-in-out .2s;

                &.active-dropdown-card {
                    transform: translate(0, -50%) rotate(180deg);
                }
            }
        }
        
        .dropdown-menu {
            padding: 0;
            border-radius: 8px;

            button {
                border: none !important;
                border-radius: 0;
                
                &:nth-child(1) {
                    border-top-right-radius: 8px;
                    border-top-left-radius: 8px;
                }

                &:nth-last-child(1) {
                    border-bottom-right-radius: 8px;
                    border-bottom-left-radius: 8px;
                }

                &:hover {
                    background: rgba(255, 126, 0, 0.05) !important;
                }
            }
        }
    }
} 