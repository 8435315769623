.overview-topic-report-wrapper {
    margin: 30px 0 0 0;

    .overview-box-no-data {
        width: 100%;
        text-align: center;
        padding: 0 0 40px 0;

        img {
            margin: 25px 0 0 0;
            width: 200px;
        }

        p {
            display: inline-block;
            margin: 22px 0 0 0;
            color: rgba(25, 25, 25, 0.4);
            font-size: 20px;
            letter-spacing: -0.6px;
        }
    }
}