.integration-google-edit-topic-account {
    padding: 32px 30px;
    text-align: left;
  
    .integration-detail-loading-wrapper {
      // .lds-ring {
      //   width: 50px;
      //   height: 50px;
      //   position: absolute;
      //   left: 50%;
      //   top: 50%;
      //   transform: translate(-50%, -50%);
      //   right: unset;
  
      //   div {
      //     border: 5px solid #fff;
      //     border-color: #ff7d00 transparent transparent transparent;
      //     width: 50px;
      //     height: 50px;
      //     margin: 0;
      //   }
      // }
    }
  
    .comp-back-button {
      margin: 0 0 16px 0;
      font-family: PTRootBold;
    }
  
    .integration-google-edit-topic-account-box {
      background: #ffffff;
      border-radius: 8px;
      padding: 32px;
      border: solid 1px rgba(25, 25, 25, 0.1);

      .form-content-wrapper {
          label {
              padding: 0 !important;
          }
      }
  
      .integration-google-edit-topic-account-box-top {
        padding-bottom: 15px;
        border-bottom: solid 1px #dcdcdc;
        position: relative;
        font-size: 20px;
        letter-spacing: -0.6px;
  
        button {
          position: absolute;
          top: -6px;
          right: 0;
          // padding: 7px 24px;
          font-size: 16px;
          letter-spacing: -0.6px;
          // width: 160px;
          // height: 40px;
  
          // .lds-ring {
          //   top: unset;
          //   right: unset;
          //   position: relative;
          //   top: 3px;
  
          //   div {
          //     margin: 0;
          //     border-color: #ffffff transparent transparent transparent;
          //   }
          // }
        }
      }
  
      .integration-google-edit-topic-account-box-form {
        width: 100%;
  
        label {
          padding: 24px 0 0;
          font-size: 14px;
          letter-spacing: -0.4px;
          display: block;
        }
  
        input[type='text'] {
          padding: 12px 16px;
          border-radius: 6px;
          border: solid 1px #dcdcdc;
          width: 100%;
          font-size: 16px;
          letter-spacing: -0.3px;
          display: block;
        }
  
        .form-topic {
          width: 575px;
        }
  
        input[type='text']:disabled {
          background: rgba(25, 25, 25, 0.1);
          color: rgba(25, 25, 25, 0.2);
          border: none;
          cursor: not-allowed;
        }
  
        .integration-info-form {
          font-size: 14px;
          color: rgba(25, 25, 25, 0.8);
          letter-spacing: -0.2px;
        }
  
        .custom-select-google-topic {
          .custom-select-option-value-wrapper {
            padding: 12px 16px !important;
            margin-bottom: 8px;
          }
        }
      }
    }
  }
  