.template-message-upload {
    .button-upload-media {
        display: inline-block !important;
    }


    input[type="file"] {
        display: none;
    }

    .media-content-title {
        margin: 24px 0 0;
        position: relative;
        font-size: 14px;
        letter-spacing: -0.2px;

        .fi-help {
            position: relative;
            top: -1px;
            font-size: 13px;
            margin-left: 4px;
        }
    }

    .replace-media {
        position: relative;
        top: -1px;
        margin-left: 4px;
        padding: 0 !important;
        display: inline-block !important;
        width: unset !important;

        label {
            margin: 0;
        }

        button {
            font-size: 12px;
            letter-spacing: -0.4px;
            border-radius: 4px;
            padding: 2px 12px 2px 8px;
        }
    }

    .template-message-upload-preview {
        padding: 12px 0;

        .preview-document {
            font-size: 14px;
            letter-spacing: -0.2px;
            color: #191919;

            .lds-ring {
                width: 14px;
                height: 14px;
                top: 2px;
                left: unset;

                div {
                    width: 14px;
                    height: 14px;
                    border: solid 2px var(--main-dark-orange);
                    border-color: var(--main-dark-orange) transparent transparent transparent;
                    margin: 0;
                }
            }
            
            svg {
                position: relative;
                margin-right: 4px;
                top: -2px;
                color: #ff7d00;
                font-size: 18px;
            }
        }

        .image-video-wrapper {
            width: 48px;
            height: 48px;
            position: relative;
            border-radius: 6px;
            overflow: hidden;

            .progress-wrapper {
                position: absolute;
                top: 0;
                left: 0;
                background: rgba(25, 25, 25, 0.4);
                width: 100%;
                height: 100%;

                .lds-ring {
                    width: 38px;
                    height: 38px;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);

                    div {
                        width: 38px;
                        height: 38px;
                        border: solid 5px #fff;
                        border-color: #fff transparent transparent transparent;
                        margin: 0;
                    }
                }

                .CircularProgressbar {
                    width: calc(100% - 12px);
                    height: calc(100% - 12px);
                    position: absolute;
                    top: 6px;
                    left: 6px;

                    .CircularProgressbar-trail {
                        stroke: transparent;
                        stroke-width: 20;
                    }
                    
                    .CircularProgressbar-path {
                        stroke: #ffffff;
                    }
                } 
            }

            img {
                width: 100%;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }

            video {
                width: 100%;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }
    }
}