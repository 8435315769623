.modal-sending {
    .modal-content {
        border-radius: 8px;
        width: 428px;
        display: inline-block;
    }

    .modal-body {
        padding: 32px;
        text-align: center;

        .lds-ring {
            height: 53px;
            width: 53px;
            top: unset;
            right: unset;

            div {
                height: 53px;
                width: 53px;
                margin: 0;
                border: 6px solid #fff;
                border-color: #ff7d00 transparent transparent transparent;
            }

            margin: 0 0 12px 0;
        }

        p {
            color: #000000;
            font-size: 14px;
            letter-spacing: -0.2px;
            margin: 0;
        }
    }
}