.modal-choose-channel {
  .modal-content {
    text-align: left;
    width: 534px;
    border-radius: 8px;

    .modal-body {
      padding: 32px;

      svg,
      img {
        margin-right: 5px;
        position: relative;
        top: -2px;
      }

      b {
        font-weight: 700 !important;
      }

      button {
          height: 40px;
          padding: 5px 20px;
      }

      #modal-choose-channel-title {
        font-size: 20px;
        position: relative;

        .cancel-new-message {
          cursor: pointer;
          top: 4px;
          margin: 0;
          right: 0;
          font-size: 22px;
          position: absolute;
        }
      }

      > :nth-child(2) {
        margin-top: 15px;
      }

      .modal-choose-channel-options {
        .modal-choose-select {
          border: solid 1px #f5f5f5;
          border-radius: 8px;
          width: fit-content;
          padding: 12px 24px;
          margin: 5px 4px 0 0;
          cursor: pointer;
          text-align: center;
          display: inline-block;
          width: calc(50% - 2px);
          vertical-align: top;
          height: 80px;

          &:nth-child(2) {
            margin: 5px 0 0 0;
          }

          b {
            color: rgba(25, 25, 25, 0.6);
            display: block;
          }

          img {
            width: 32px;
            height: 32px;
            vertical-align: -3px;
          }
        }

        div:hover {
          background-color: #fff2e6;

          b {
            color: #ff7e00;
          }
        }

        .modal-choose-channel-selected {
          border: solid 1px #fff2e6;
          background-color: #fff2e6;
          
          b {
            color: #ff7e00;
          }
        }
      }

      .modal-choose-channel-buttons {
          margin-top: 20px;

          .no-fill-button {
              width: 120px;
              margin-right: 5px;
          }

          .orange-button {
              width: 308px;
          }
      }
    }
  }
}
