.shared-media-main-tab-wrapper {
    display: block;
    width: 100%;

    .no-shared-media {
        width: 100%;
        text-align: center;
        padding: 24px 110px 0;

        p {
            &:nth-child(2) {
                font-size: 24px;
                letter-spacing: -0.6px;
                margin: 0 0 8px 0;
            }

            &:nth-child(3) {
                font-size: 16px;
                letter-spacing: -0.3px;
            }
        }
    }

    .share-media-list-wrapper {
        width: 100%;
        display: block;

        .share-media-date {
            display: block;
            width: 100%;
            background: rgba(25, 25, 25, 0.05);
            color: rgba(25, 25, 25, 0.6);
            letter-spacing: 3px;
            font-size: 14px;
            padding: 8px 16px;
            text-transform: uppercase;
        }

        .share-media-list-inner {
            width: 100%;
            display: block;
            padding: 0 16px;

            .triple-dot-wrap {
                width: 24px;
                height: 24px;
                border-radius: 50%;
                background: rgba(25, 25, 25, 0.05);
                cursor: pointer;
                position: absolute;
                top: 4px;
                right: 4px;

                img {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    width: 12px;
                }
            }

            .dropdown {
                position: absolute;
                top: 1px;
                right: 0;
                display: none;
            }

            .dropdown-view-in-chat {
                background: transparent !important;
                border: none !important;
                outline: none !important;
                box-shadow: none !important;
            }

            .dropdown-menu {
                padding: 0;
                border-radius: 8px;
                overflow: hidden;

                button {
                    border-radius: 0;
                    background: #fff !important;
                    font-size: 16px;
                    letter-spacing: -0.3px;
                    padding: 8px 20px;
                    color: #191919 !important;
                    
                    &:hover {
                        background: rgba(25, 25, 25, 0.1) !important;
                    }

                    img {
                        position: relative;
                        margin-right: 4px;
                    }

                    svg {
                        position: relative;
                        margin-right: 4px;
                        font-size: 24px;
                        color: var(--main-dark-orange);
                    }
                }
            }

            .share-media-card-doc {
                width: 100%;
                vertical-align: top;
                display: inline-block;
                cursor: pointer;
                position: relative;
                margin: 8px 0;
                padding: 0 0 0 56px;

                .share-media-doc-box {
                    width: 40px;
                    height: 40px;
                    border-radius: 8px;
                    background: var(--main-dark-orange);
                    position: absolute;
                    left: 0;
                    top: 1px;

                    svg {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        font-size: 24px;
                        color: #fff;
                    }

                    .icon-download {
                        display: none;
                    }

                    &:hover {
                        .icon-image-video {
                            display: none;
                        }

                        .icon-download {
                            display: block;
                        }
                    }
                }

                .share-media-doc-info {
                    .file-name {
                        font-size: 16px;
                        white-space: nowrap;
                        max-width: calc(100% - 40px);
                        text-overflow: ellipsis;
                        overflow: hidden;
                        letter-spacing: -0.5px;
                        color: #191919;
                        margin: 0 0 2px 0;
                    }

                    .file-info {
                        font-size: 10px;
                        white-space: nowrap;
                        max-width: 100%;
                        text-overflow: ellipsis;
                        overflow: hidden;
                        letter-spacing: -0.2px;
                        color: rgba(25, 25, 25, 0.8);
                        margin: 0 0 2px 0;

                        .centered-dot {
                            width: 2px;
                            height: 2px;
                            margin: 0 1px;
                            display: inline-block;
                            vertical-align: top;
                            background: rgba(25, 25, 25, 0.8);
                            border-radius: 50%;
                            position: relative;
                            top: 6px;
                        }
                    }
                }

                &:hover {
                    .dropdown {
                        display: inline-block;
                    }
                }
            }
        }
    }
}