.modal-confirmation-send-product {
    .modal-content {
        width: 560px !important;
    }

    button {
        width: calc(50% - 4px) !important;
        margin-right: 8px;

        &:nth-last-child(1) {
            margin-right: 0;
        }
    }
}

.modal-selected-product {
    width: 90% !important;
    
    .modal-content {
        height: 90%;
        width: 90%;

        .modal-body {
            padding: 24px;
            position: relative;

            .modal-header {
                position: relative;
                padding: 0;
                border: none;
                text-align: left;

                p {
                    font-size: 24px;
                    letter-spacing: -0.6px;
                    color: #191919;
                    margin: 0 0 20px 0;
                    display: inline-block;
                    line-height: 1;
                }

                span {
                    color: rgba(25, 25, 25, 0.6);
                    font-size: 14px;
                    letter-spacing: -0.2px;
                    position: relative;
                    margin-left: 4px;
                    top: -2px;
                }

                svg {
                    position: absolute;
                    font-size: 24px;
                    color: #ff7d00;
                    cursor: pointer;
                    right: 0; 

                    &:hover {
                        color: #e87200;
                    }
                }
            }

            .modal-foot {
                padding: 12px 0 0 0;
                text-align: right;

                button {
                    margin-left: 8px;
                }
            }

            .sales-talk-product-selected-outer {
                height: calc(100% - 104px);
                width: 100%;
                // padding: 4px 0;

                .sales-talk-product-selected-comp-box {
                    display: inline-block;
                    vertical-align: top;
                    width: calc(20% - 16px);
                    margin: 8px 20px 0 0;
                    // cursor: pointer;
                    padding: 12px;
                    position: relative;
                    // border-radius: 4px;
                    border: solid 1px #fff;
                    box-shadow: 0px 2px 20px rgba(25, 25, 25, 0.05);

                    &:nth-child(5n) {
                        margin: 8px 0 0 0;
                    }

                    .remove-product-icon {
                        position: absolute;
                        cursor: pointer;
                        color: red;
                    }
                
                    .custom-checkbox {
                        width: 24px;
                        height: 24px;
                        position: absolute;
                        top: 10px;
                        right: 10px;
                        border-radius: 50%;
                        z-index: 2;
                        background: #fff;
                        border: solid 1px #dcdcdc;

                        svg {
                            position: absolute;
                            left: 50%;
                            top: 50%;
                            transform: translate(-50%, -50%);
                        }
                
                        label {
                            position: absolute;
                            top: 0;
                            left: 0;
                            margin: 0;
                            padding: 0;
                
                            &::before {
                                width: 16px;
                                height: 16px;
                                border-radius: 50%;
                                top: 0;
                                left: 0;
                            }
                
                            &::after {
                                width: 16px;
                                height: 16px;
                                top: 0;
                                left: 0;
                                border-radius: 50%;
                            }
                        }
                    }
                
                    &:nth-child(2n) {
                        // margin-right: 0;
                    }
                
                    &:hover {
                        // &::before {
                        //     content: "";
                        //     border: solid 2px #ff7d00;
                        //     background-color: #ff7d00;
                        //     opacity: 0.1;
                        //     width: 100%;
                        //     height: 100%;
                        //     position: absolute;
                        //     left: 0;
                        //     top: 0;
                        // }
                
                        // &::after {
                        //     content: "";
                        //     border: solid 2px #ff7d00;
                        //     width: 100%;
                        //     height: 100%;
                        //     position: absolute;
                        //     left: 0;
                        //     top: 0;
                        // }
                        // box-shadow: 0px 0px 8px 0px #FF7E003D;
                        // border: solid 1px #ff7d00;
                    }
                
                    .sales-talk-product-selected-comp-image-wrapper {
                        width: 100%;
                        height: 200px;
                        // height: ;
                        border-radius: 12px;
                        margin: 0;
                        position: relative;
                        background: rgba(25, 25, 25, 0.2);
                        overflow: hidden;
                
                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                            z-index: 1;
                        }
                    }
                
                    .product-title {
                        font-size: 14px;
                        max-width: 100%;
                        // white-space: nowrap;
                        overflow: hidden;
                        display: -webkit-box;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        letter-spacing: -0.4px;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 2;
                        margin: 12px 0 0;
                        cursor: pointer;
                
                        &:hover {
                            text-decoration: underline;
                        }
                    }
                
                    .product-price {
                        font-size: 12px;
                        color: rgba(25, 25, 25, 0.8);
                        letter-spacing: -0.2px;
                        margin: 0 0 8px 0;
                    }
                
                    .product-stock {
                        font-size: 10px;
                        letter-spacing: 1.5px;
                        color: #ff7d00;
                        margin: 0;
                    }
                }
            }
        }
    }
}