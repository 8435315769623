.chat-room-user-info-wrapper {
	// width: 300px;
	width: 340px;
	display: inline-block;
	transition: ease-in-out .2s;
	position: absolute;
	top: 0;
	// right: -300px;
	right: -340px;
	background: #ffffff;
	vertical-align: top;
	height: 100%;
	top: -48px;
	// padding-top: 24px;
}

.utm-detail-wrapper-inbox {
	border-top: solid 1px #dcdcdc;
	padding-top: 16px;
	margin-top: 16px;

	.utm-detail-wrapper-inbox-title {
		font-size: 14px;
		letter-spacing: -0.4px;
		color: rgba(25, 25, 25, 0.8);
		margin: 0 0 16px;
	}
}

.chat-room-user-info-wrapper.active-chat-room-user-info-wrapper {
	right: 0;
}
.user-info-name-avatar-wrapper {
	width: 100%;
	position: relative;
	// padding: 0 16px 12px 16px;
	padding: 24px;
	background: #f3f3f3;

	img {
		&.user-avatar-name {
			width: 48px;
			height: 48px;
		}
	}
}
.user-info-avatar-wrapper {
	border-radius: 50%;
	height: 48px;
	display: inline-block;
	width: 48px;
	vertical-align: top;
	background: pink;
	position: relative;
	text-align: center;
	line-height: 48px;
	color: #ffffff;
	font-size: 16px;
}
.user-info-name-connectivity {
	width: calc(100% - 96px);
	display: inline-block;
	padding-left: 12px;
	line-height: 19px;
	position: absolute;
    top: 50%;
	transform: translate(0, -50%);
	left: 72px;
    
	.main-user-name-outer {
		position: relative;
		display: block;
		width: 100%;

		.main-user-name {
			display: inline-block;
			max-width: calc(100% - 104px);
			position: relative;
	
			p {
				white-space: nowrap;
				overflow: hidden;
				margin: 0;
				text-overflow: ellipsis;
				font-size: 16px;
				letter-spacing: -0.5px;
				// max-width: calc(100% - 50px);
				max-width: 100%;
				display: inline-block;
			}

			&.hide-edit-alias {
				max-width: 100%;
			}
		}
	}
    
    .edit-alias-button {
        color: #ff7d00;
        font-size: 12px;
        letter-spacing: -0.2px;
        position: absolute;
        top: 0;
        right: -110px;
		white-space: nowrap;
        cursor: pointer;

        svg {
            font-size: 16px;
            margin-right: 4px;
            position: relative;
            top: -1px;
        }

        &:hover {
            color: #e87200;
        }
    }
}
.user-info-online {
	font-size: 10px;
	letter-spacing: 1.5px;
	color: rgba(25, 25, 25, 0.6);
	font-weight: bold;
	svg {
		color: #19c700;
		position: relative;
		top: -1px;
		font-size: 13px;
		margin-right: 2px;
	}
}
.user-info-offline {
	cursor: pointer;
	border-radius: 4px;
	background: #ff7d00;
	color: #ffffff;
	font-size: 12px;
	line-height: 1.5;
	letter-spacing: -0.2px;
	padding: 1px 8px;
	display: inline-block;
	margin-top: 4px;
	transition: ease-in-out .2s;
	&:hover {
		background: #e87200;
	}
	svg {
		margin-right: 4px;
		position: relative;
		top: -1px;
	}
}
.user-info-offline-dot {
	font-size: 10px;
	letter-spacing: 1.5px;
	color: rgba(25, 25, 25, 0.6);
	font-weight: bold;
	svg {
		color: #ff3f57;
		position: relative;
		top: -1px;
		font-size: 13px;
		margin-right: 2px;
	}
}
.user-info-tab-wrapper {
	// border-bottom: solid 1px #dcdcdc;
	width: 100%;
	position: relative;
	// padding: 12px 0 0;
	background: #f3f3f3;
}
.user-info-tab-content {
	width: calc(100% / 3);
	display: inline-block;
	color: rgba(25, 25, 25, 0.6);
	text-align: center;
	font-size: 14px;
	letter-spacing: -0.4px;
	position: relative;
	padding: 8px 0;

	svg {
		font-size: 14px;
		margin-right: 4px;
		position: relative;
		top: -2px;
	}
	&:hover {
		color: #191919;
		cursor: pointer;
	}

	&:nth-child(1) {
		&.active-room-list-tab {
			border-radius: 0 12px 0 0;
		}
	}

	&:nth-last-child(1) {
		&.active-room-list-tab {
			border-radius: 12px 0 0 0;
		}
	}
}
.user-info-tab-content.active-room-list-tab {
	color: #ff7d00 !important;
	background: #ffffff;
    border-radius: 12px 12px 0 0;

	// &:before {
	// 	content: "";
	// 	position: absolute;
	// 	width: 100%;
	// 	height: 4px;
	// 	background: #ff7d00;
	// 	left: 0;
	// 	bottom: 0;
	// 	border-top-left-radius: 8px;
	// 	border-top-right-radius: 8px;
	// }
}

.main-user-info-wrapper {
	width: 100%;
	padding: 8px 12px;
}
.user-info-list-wrapper {
	margin-bottom: 16px;
	width: 100%;
    text-align: left;
	position: relative;
	
    .tooltip-icon {
        position: relative;
        top: -2px;
        margin-left: 4px;
        cursor: pointer;
    }

	label {
		font-size: 14px;
		letter-spacing: -0.4px;
		color: rgba(25, 25, 25, 0.8);
		display: block;
	}

	.user-info-list-button-action {
		font-size: 12px;
		letter-spacing: -0.2px;
		color: #ff7d00;
		cursor: pointer;
		position: absolute;
		right: 0;
		top: 9px;

		svg {
			font-size: 16px;
			margin-right: 4px;
			position: relative;
			top: -2px;
		}

		&:hover {
			color: var(--main-darker-orange);
		}
	}
}
.user-info-box-wrapper {
	background: rgba(25, 25, 25, 0.05);
	border-radius: 8px;
	height: 40px;
	width: 100%;
	line-height: 40px;
	padding: 0 9px;
	font-size: 14px;
	color: #191919;
	width: 100%;
	position: relative;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;

	&.user-info-list-wrapper-white {
		background: #fff;
        border: solid 1px rgba(25, 25, 25, 0.05);
    }
	
	input {
		border: none;
		background: none;
		height: 100%;
		width: calc(100% - 35px);
	}
	img {
		position: relative;
		top: -2px;
		margin-right: 7px;
	}

	&.user-info-box-wrapper-topics {
		padding: 0 34px 0 9px;

		.button-edit-topic {
			color: #ff7d00;
			position: absolute;
			right: 9px;
			top: 50%;
			transform: translate(0, -50%);
			cursor: pointer;
			margin: 0;
		}
	}

	&.user-info-box-wrapper-multi-line {
		white-space: unset;
		text-overflow: unset;
		overflow: unset;
		word-break: break-word;
		min-height: 40px;
		height: unset;
		line-height: 24px;
    	padding: 10px 9px;
	}
}

.user-info-label-wrapper {
	width: 100%;
	position: relative;

	.user-info-label-tag-content {
		font-size: 12px;
		letter-spacing: -0.4px;
		padding: 2px 8px;
		border-radius: 4px;
		margin-right: 4px;
		cursor: pointer;
		vertical-align: top;
		display: inline-block;
		height: unset;
		color: #ffffff;
		margin-bottom: 4px;

		svg {
			margin-right: 4px;
			font-size: 12px;
			vertical-align: top;
			position: relative;
			top: 3px;
		}

		&.user-info-label-tag-content-add {
			color: rgba(25, 25, 25, 0.6);
			transition: ease-in-out .1s;

			&:hover {
				color: #ff7d00;
				background: rgba(255, 126, 0, 0.1);
			}
		}
	}
}

.user-info-box-wrapper-multiple-line {
	background: rgba(25, 25, 25, 0.05);
	border-radius: 8px;
	width: 100%;
	line-height: 21px;
	padding: 10px 8px 10px 36px;
	font-size: 14px;
	letter-spacing: -0.2px;
	color: #191919;
	width: 100%;
	position: relative;
	min-height: 40px;
	word-break: break-word;

	input {
		border: none;
		background: none;
		height: 100%;
	}
	img {
		position: absolute;
		top: 12px;
		left: 8px;
		margin-right: 7px;
	}
}

.user-info-box-wrapper-multiple-line.no-icon {
	padding: 10px 8px 10px 8px;
	margin-bottom: 16px;
}

.user-info-box-wrapper.with-button-copy {
	padding: 0 65px 0 8px;
}
.user-detail-copy-wrapper {
	position: absolute;
	right: 12px;
	top: 50%;
	transform: translate(0, -50%);
	color: rgba(25, 25, 25, 0.6);
	font-size: 12px;
	line-height: -0.2px;
	cursor: pointer;
	&:hover {
		color: rgba(25, 25, 25, 0.8);
	}
	svg {
		font-size: 16px;
		margin-right: 4px;
	}
}
.user-info-tab {
	// max-height: calc(100vh - 180px);
	max-height: calc(100% - 132px);
	height: 100%;
	overflow-y: hidden;
}
.user-info-tags {
	font-size: 12px;
	letter-spacing: -0.4px;
	color: rgba(25, 25, 25, 0.6);
	line-height: 1.5;
	padding: 0 8px;
	border-radius: 4px;
	margin-right: 4px;
	display: inline-block;
	background: rgba(25, 25, 25, 0.06);
	vertical-align: top;
	margin-bottom: 5px;
}
.case-detail-loading-wrapper {
	font-size: 16px;
	letter-spacing: -0.5px;
	color: #ff7d00;
	text-align: center;
	padding: 24px;
	
	.lds-ring {
		top: 3px;
		right: unset;
		position: relative;
		margin-right: 5px;
		div {
			margin: 0;
		}
	}
}

.modal-edit-alias {
	.modal-content {
		width: 476px;
		border-radius: 8px;

		.modal-body {
			padding: 32px;
			
			p.title {
				font-size: 20px;
				letter-spacing: -0.6px;
				color: #191919;
			}
	
			input {
				width: 100%;
				font-size: 16px;
				letter-spacing: -0.3px;
				color: #191919;
				line-height: 1;
				padding: 16px;
				border-radius: 8px;
				border: solid 1px #dcdcdc;
				margin: 0 0 24px 0;

				&:focus {
					border: solid 1px #ff7d00;
				}
			}

			textarea {
				width: 100%;
				font-size: 16px;
				letter-spacing: -0.3px;
				color: #191919;
				line-height: 1;
				padding: 12px 16px;
				border-radius: 8px;
				border: solid 1px #dcdcdc;
				margin: 0 0 24px 0;
				resize: none;
				line-height: 21px;

				&:focus {
					border: solid 1px #ff7d00;
				}
			}
	
			button {
				width: calc(50% - 4px);
				display: inline-block;
				margin: 0 8px 0 0;
				height: 48px;
				text-align: center;
	
				&:nth-last-child(1) {
					margin: 0;
				}

				// .lds-ring {
				// 	margin: 0;
				// 	top: 3px;
				// 	right: unset;

				// 	div {
				// 		margin: 0;
				// 		border-color: #ffffff transparent transparent transparent;
				// 	}
				// }
			}
		}
	}

	// &.modal-add-label-case-detail {
	// 	.label-value-wrapper {
	// 		width: 100%;
	// 		position: relative;
	// 		padding: 16px 50px 12px 12px;
	// 		margin-bottom: 24px;
	// 		border-radius: 8px;
	// 		border: solid 1px #dcdcdc;
			
	// 		.search-add-label-icon {
	// 			color: #191919;
	// 			font-size: 24px;
	// 			position: absolute;
	// 			top: 16px;
	// 			right: 16px;
	// 		}

	// 		.label-value {
	// 			font-size: 12px;
	// 			letter-spacing: -0.4px;
	// 			display: inline-block;
	// 			margin: 0 4px 4px 0;
	// 			vertical-align: top;
	// 			border-radius: 4px;
	// 			color: #ffffff;
	// 			padding: 2px 8px;

	// 			svg {
	// 				position: relative;
	// 				vertical-align: top;
	// 				margin-right: 4px;
	// 				top: 3px;
	// 				color: #ffffff;
	// 			}
	// 		}

	// 		input {
	// 			border: none !important;
	// 			display: inline-block;
	// 			width: unset;
	// 			outline: none;
	// 			font-size: 16px;
	// 			letter-spacing: -0.3px;
	// 			color: rgba(25, 25, 25, 0.6);
	// 			margin: 0;
	// 			padding: 0;
	// 		}

	// 		.add-label-suggestion-wrapper {
	// 			max-height: 190px;
	// 			display: inline-block;
	// 			width: 100%;
	// 			border-radius: 8px;
	// 			background: #ffffff;
	// 			overflow: auto;
	// 			position: absolute;
	// 			left: 0;
	// 			top: 50px;
	// 			box-shadow: 0 2px 20px 0 rgba(25, 25, 25, 0.05);

	// 			ul {
	// 				list-style: none;
	// 				margin: 0;
	// 				padding: 0;
					
	// 				li {
	// 					width: 100%;
	// 					padding: 8px 16px;
	// 					cursor: pointer;

						
	// 					&:hover {
	// 						background: #FFF2E5;
	// 					}

	// 					&.no-option {
	// 						color: rgba(25, 25, 25, 0.6);
	// 						text-align: center;
	// 						font-size: 16px;
	// 						letter-spacing: -0.3px;

	// 						&:hover {
	// 							background: none;
	// 						}
	// 					}

	// 					.label-value {
	// 						margin: 0 4px 0 0;
	// 					}
	// 				}
	// 			}
	// 		}
	// 	}
	// }
}

// .chat-room-user-info-outer-container {
// 	position: relative;
// 	width: 100%;
// 	height: 100%;

// 	&::after {
// 		position: absolute;
// 		content: "";
// 		bottom: 0;
// 		left: 0;
// 		width: 100%;
// 		height: 96px;
// 		background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, #ffffff 100%);
// 	}
// }

