.add-card-main-wrapper {
  width: 100%;
  min-height: calc(100vh - 87px);
  margin-top: 87px;
  padding-top: 40px;
  background: #f3f3f3;

  .add-card-content {
    .add-card-header {
      padding: 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      
      .comp-back-button {
        margin: 0;
      }
      
      .button {
        padding: 8px 24px;
        // display: flex;
      }

      .cont-button {
        padding: 8px 24px;
        display: flex;
        justify-content: space-between;
        width: 145px;

        &:disabled {
          background: rgba(25, 25, 25, 0.1) !important;
        }

        .grey-lds-ring {
          div {
            border-color: rgba(25, 25, 25, 0.4) transparent transparent transparent;
            
          }
        }

        div {
          color: rgba(25, 25, 25, 0.4);
        }
      }
    }



    .add-card-container {
      // position: relative;
      // left: 50%;
      // transform: translateX(-50%);
      display: flex;
      flex-direction: column;
      align-items: center;

      h1 {
        margin: 32px 0;
      }

      .form-content-wrapper {
        width: 368px;
        background-color: #ffffff;
        padding: 32px;
        border: 1px solid rgba(25, 25, 25, 0.1);
        border-radius: 8px;

        .warning-error-red {
            color: #ff3f57;
            font-size: 12px;
            letter-spacing: -0.4px;
            margin: 4px 0 0;

            svg {
                font-size: 14px;
                position: relative;
                top: -1px;
                margin-right: 4px;
                color: #ff3f57 !important;
                cursor: unset !important;
            }
        }
        
        label {
            position: relative;

            .custom-tooltip {
                padding: 14px;
                color: #ffffff;
                background: #4a4a4a;
                letter-spacing: 0.4px;
                width: 219px;
                font-weight: normal;
                border-radius: 8px;
                position: absolute;
                top: -35px;
                right: -175px;
                font-size: 14px;
                z-index: 1;
    
                &::before {
                    content: "";
                    border-bottom: solid 12px #4a4a4a;
                    border-top: solid 12px transparent;
                    border-left: solid 12px transparent;
                    border-right: solid 12px transparent;
                    transform: rotate(-90deg);
                    width: 20px;
                    height: 20px;
                    position: absolute;
                    top: 35px;
                    left: -24px;
                }
            }
        }
        
        .span-optional {
            font-size: 14px;
            letter-spacing: -0.2px;
            color: #9b9b9b;
            font-weight: normal;
            margin-left: 4px;
        }
        
        input[type="text"],
        input[type="password"],
        textarea {
            padding: 10px 15px;
            border-radius: 8px;
            border: solid 1px #dcdcdc;
            display: block;
            width: 100%;
            position: relative;
        }

        .card-number {
            position: relative;

            input {
                padding: 10px 48px 10px 15px;
            }

            img {
                position: absolute;
                right: 16px;
                top: 13px;
                width: 22px;
            }
        }
        
        .payment-div-50 {
            width: 145px;
            margin-right: 10px;
            display: inline-block;
            vertical-align: top;

            &.no-margin {
                margin: 0;
            }
    
            svg {
                color: #9B9B9B;
                position: relative;
                top: -1px;
                margin-left: 5px;
                cursor: pointer;
            }
    
            input {
                width: 100%;
            }
        }
    
        textarea {
            resize: none;
        }
    
        button {
            &.submit-card {
                width: unset;
                padding: 0 24px;
                height: 40px;
                width: 100%;
                position: relative;

                .lds-ring {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }
            }
        }
    }
    }
  }
}

.add-card-modal {
  .modal-content {
      width: 476px;
  }

  .modal-body {
      padding: 32px;

      .add-card-modal-wrapper {
          position: relative;

          .error-title-wrapper {
            display: flex;
            align-items: center;

            .error-title {
                font-size: 20px;
                letter-spacing: -0.6px;
                color: #191919;
                line-height: 1;
                margin: 0;
            }

            svg {
              width: 18px;
              height: 18px;
              margin-right: 7px;
            }
          }

  
          .error-description {
              font-size: 14px;
              letter-spacing: -0.2px;
              color: rgba(25, 25, 25, 0.6);
              line-height: 21px;
              margin: 6px 0 20px;
          }

          button {
            &.dismiss {
              width: 100%;
              height: 48px;
            }
          }
      }
  }
}