.room-list-no-chat-container {
    padding: 130px 16px 30px 16px;
    text-align: center;
  }
  .room-list-no-chat-container p {
    font-size: 14px;
    letter-spacing: -0.3px;
    line-height: 24px;
    margin: 0;
  }
  .room-list-no-chat-container b {
    font-size: 18px;
    line-height: 24px;
  }
  .room-list-no-chat-container b:nth-child(3) {
    color: #ff7d00;
  }
  .room-list-no-chat-container .no-chat-button {
    color: #ff7d00;
    margin-top: 16px;
    font-size: 16px;
    letter-spacing: -0.5px;
    cursor: pointer;
  }
  .room-list-no-chat-container .no-chat-button:hover {
    color: #e87200;
  }
  .room-list-no-chat-container .no-chat-button svg {
    font-size: 18px;
    position: relative;
    top: -2px;
  }
  