.shimmer-product-component {
    width: 100%;
    display: block;
    padding: 12px;

    .main-shimmer-wrapper {
        box-shadow: 0px 2px 20px rgba(25, 25, 25, 0.05);
        width: calc(50% - 10px);
        margin-right: 20px;
        margin-bottom: 8px;
        background: #fff;
        display: inline-block;
        padding: 12px;

        &:nth-child(2n) {
            margin-right: 0;
        }

        .shimmer-image {
            height: 116px;
            width: 100%;
            display: block;
            border-radius: 8px;
        }

        .shimmer-title {
            height: 12px;
            width: 100%;
            display: block;
            border-radius: 8px;
            margin-top: 12px;
        }
    }
}