.integration-whatsapp-checkout {
  padding: 32px 30px;
  text-align: left;

  .integration-whatsapp-checkout-box-form {
    padding-top: 24px;
  }

  .comp-back-button {
      margin: 0 0 16px 0;
  }

  .integration-detail-loading-wrapper {
    .lds-ring {
      width: 50px;
      height: 50px;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      right: unset;

      div {
        border: 5px solid #fff;
        border-color: #ff7d00 transparent transparent transparent;
        width: 50px;
        height: 50px;
        margin: 0;
      }
    }
  }

  .integration-whatsapp-checkout-box {
      background: #ffffff;
      border-radius: 8px;
      padding: 32px;
      border: solid 1px rgba(25, 25, 25, 0.1);

      .integration-whatsapp-checkout-box-top {
          padding-bottom: 15px;
          border-bottom: solid 1px #dcdcdc;
          position: relative;
          font-size: 20px;
          letter-spacing: -0.6px;

          button {
              position: absolute;
              top: -6px;
              right: 0;
              padding: 7px 24px;
              font-size: 16px;
              letter-spacing: -0.6px;
              width: 160px;
              height: 40px;

              .lds-ring {
                  top: unset;
                  right: unset;
                  position: relative;
                  top: 3px;

                  div {
                      margin: 0;
                      border-color: #ffffff transparent transparent transparent;
                  }
              }
          }
      }

      .integration-whatsapp-checkout-box-form {
          width: 100%;

          .alert-checkout {
            margin: 0 0 24px;
            width: 100%;
            font-size: 16px;
            letter-spacing: -0.5px;

            svg {
              top: 25px;
              font-size: 20px;
            }

            .blue-button {
              background-color: #044B84;
              color: #ffffff;
              border-radius: 8px;
              margin-top: 8px;
              font-size: 14px;
            }
          }

          .amount-due {
            margin-bottom: 24px;
            padding: 24px 12px;
            text-align: center;
            background: rgba(25, 25, 25, 0.05);
            color: rgba(25, 25, 25, 0.6);

            .amount-box {
              width: 100%;
              // border-radius: 8px;
              // border: 1px solid rgba(25, 25, 25, 0.1);
              display: inline-block;
              align-items: center;
              margin-top: 4px;
              color: #191919;
              font-size: 32px;
              letter-spacing: -0.6px;
            }
          }

          .primary-card {
            margin-bottom: 24px;
            position: relative;
            width: 100%;
            border-bottom: solid 1px #dcdcdc;

            .card-box {
              display: inline-block;
              border-radius: 8px;
              padding: 10px 12px;
              position: relative;
              padding: 0 0 0 58px;

              .card-box-frame {
                border-radius: 50%;
                width: 48px;
                height: 48px;
                vertical-align: top;
                display: inline-block;
                background: #FFF5D9;
                display: inline-block;
                position: absolute;
                left: 0;

                &.card-box-frame-whatsapp {
                  background: #D0F5F1;
                }

                &.card-box-frame-manual {
                  color: #F4C22C;
                }

                &.card-box-frame-auto {
                  color: #9C89F1;
                }

                svg {
                  position: absolute;
                  top: 50%;
                  left: 50%;
                  transform: translate(-50%, -50%);
                  font-size: 20px;
                }

                img {
                  position: absolute;
                  top: 50%;
                  left: 50%;
                  transform: translate(-50%, -50%);
                }
              }

              img {
                margin-right: 12px;
                position: absolute;
                left: 0;

                &.cc-img {
                  width: 48px;
                }
              }

              .card-info {
                line-height: 1;
                p {
                  margin-top: 6px;
                  line-height: 1;
                }

                .card-top-info {
                  font-size: 16px;
                  font-size: 24px;
                  letter-spacing: -0.6px;

                  .masking-dot {
                    vertical-align: top;
                    width: 4px;
                    height: 4px;
                    position: relative;
                    top: 3px;
                    display: inline-block;
                    margin: 0 2px;
                    border-radius: 50%;
                    background: #191919;
                  }
                }

                .card-footer-info {
                  font-size: 16px;
                  letter-spacing: -0.3px;
                  line-height: 1;
                }
              }
            }

            button {
              position: absolute;
              right: 0;
              top: 0;
              height: 40px;
              padding: 0 24px;
            }

            &.no-border-bottom {
              border-bottom: 0;
            }
            
            .annualy-selection-wrapper {
              position: absolute;
              right: 0;
              top: 0;

              b {
                color: #ff7d00;
                margin-right: 12px;
                position: relative;
                top: 1px;
              }

              .custom-switch-wrapper {
                position: relative;
                width: 48px;
                height: 29px;
                
                .custom-switch.round {
                  &:before {
                    width: 16px;
                    height: 16px;
                    bottom: 7px;
                    left: 7px;
                  }
                }
  
                input:checked + .custom-switch:before {
                  transform: translateX(21px);
                }
  
                b {
                  font-size: 16px;
                  letter-spacing: -0.5px;
                }
              }
            }
          }
      }
  }

  .payment-button {
    width: 235px;
    height: 40px;
    position: relative;

    svg {
      margin-right: 5px;
      position: relative;
      top: -2px;
    }

    &.verify-payment {
      width: 180px;
    }
  }
}