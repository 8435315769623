.member-list-status-title {
    color: #191919;
    font-size: 20px;
    letter-spacing: -0.6px;
    line-height: 24px;
    padding-bottom: 16px;
    border-bottom: solid 1px rgba(25, 25, 25, 0.1);
    position: relative;
    padding-top: 10px;

    .button-invite-member {
        position: absolute;
        right: 0;
        top: 0;
        width: 181px;
    }

    .grey-font {
        position: absolute;
        right: 192px;
        font-size: 14px;
        letter-spacing: -0.2px;
    }
}

.member-list-status-tool-wrapper {
    width: 100%;
    position: relative;
    margin-top: 24px;
    margin-bottom: 24px;

    .dropdown-member-status {
        position: absolute;
        width: 322px;
        left: 356px;
        top: 0;
        box-shadow: none !important;
        outline: none !important;

        button {
            padding: 12px 34px 12px 14px;
            border-radius: 8px;
            border: 1px solid rgba(25, 25, 25, 0.1) !important;
            background: #fff !important;
            color: rgba(25, 25, 25, 1) !important;
            width: 322px;
            text-align: left;
            height: 47px;
            line-height: 18px;
            box-shadow: none !important;
            outline: none !important;
            white-space: nowrap;
            display: block;
            text-overflow: ellipsis;
            overflow: hidden;


            img {
                position: relative;
                top: -2px;
                margin-right: 4px;
                font-size: 18px;
            }

            svg {
                font-size: 18px;
                position: absolute;
                right: 14px;
                top: 15px;
            }
        }

        &.dropdown-isdisabled {
            button {
                background: rgba(25, 25, 25, 0.1) !important;
                color: rgba(25, 25, 25, 0.4) !important;
            }
        }

        .dropdown-menu {
            width: 322px;
            padding: 0;
            border-radius: 8px;
            overflow: hidden;

            .disabled-clock-filter {
                background: rgba(25, 25, 25, 0.1);
            }

            div {
                padding: 12px 8px;
                width: 100%;
                display: block;
                font-size: 16px;
                letter-spacing: -0.3px;

                label {
                    margin: 0;
                }

                [type="checkbox"]:not(:checked)+label:before {
                    top: 4px;
                }

                [type="checkbox"]:checked+label:before {
                    top: 4px;
                }

                [type="checkbox"]:checked+label:after {
                    top: -1px;
                }
            }
        }
    }
}

.member-list-status-tool-wrapper button.button-invite-member {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(0, -50%);
    /* padding: 11px 0; */
    /* width: 200px; */
}

/* .member-list-status-tool-wrapper button.button-invite-member svg {
    position: relative;
    top: -1px;
    margin-right: 5px;
} */

.member-list-status-table-wrapper {
    width: 100%;
    position: relative;

    .no-result-found {
        width: 100%;
        text-align: center;
        padding: 24px 0;
    }
}

.table-member-list-status {
    .member-alias-field {
        font-size: 14px;
        letter-spacing: -0.2px;
        color: rgba(25, 25, 25, 0.4);
        margin: 0;
    }
}

.table-member-list-status thead tr {
    background: #ffffff;
    // border-top: solid 1px #dcdcdc;

    .custom-checkbox-stripe [type="checkbox"]:checked+label:after {
        top: 2px;
        left: 0;
    }
}

.table-member-list-status tbody td {
    background: #ffffff;
    position: relative;
    word-break: break-word;
    
    &.col_state {
        position: relative;
        padding: 8px 8px 8px 25px;
    
        .status-badge {
            width: 12px;
            height: 12px;
            border-radius: 50%;
            position: absolute;
            top: 12px;
            left: 9px;
        }
    }
}

.table-member-list-status button svg {
    position: relative !important;
    right: unset !important;
    top: -2px !important;
    margin-right: 5px;
}

.table-member-list-status .col-action-button-wrapper {
    position: absolute;
    right: 7px;
    top: 50%;
    transform: translate(0, -50%);
    /* display: none; */
}

.table-member-list-status .single-remove-button {
    margin-left: 16px;
    color: #ff3f57;
}

.table-member-list-status .single-remove-button:hover {
    color: #e83b51;
}

.table-member-list-status .col-action-button-wrapper .view-agent {
    color: #ff7d00;
    letter-spacing: -0.5px;
}

.table-member-list-status .col-action-button-wrapper .view-agent:hover {
    color: var(--main-darker-orange);
}

.table-member-list-status .col-action-button-wrapper img.view-agent {
    margin-right: 3px;
    position: relative;
    top: -1px;
}

.table-member-list-status tbody tr:hover .col-action-button-wrapper {
    /* display: block; */
}

.table-member-list-status .no-data-tr {
    border-bottom: none;
}

.table-member-list-status .no-data-column {
    padding-top: 24px;
    letter-spacing: -0.5px;
    font-size: 16px;
}

.table-member-list-status tbody tr:hover {
    background: #f8f8f8 !important;
}

.table-member-list-status .resent-invitation {
    background: none;
    border: 0;
    color: #ff7d00;
    letter-spacing: -0.5px;
    padding: 0;
}

.table-member-list-status .resent-invitation img {
    position: relative;
    top: -2px;
}


.table-member-list-status .resent-invitation:hover {
    color: var(--main-darker-orange);
}


.table-member-list-status .resend-invitation-loading {
    width: 150px;
}

.table-member-list-status .resend-invitation-loading .lds-ring {
    top: 3px;
    right: unset;
}

.table-member-list-status .resend-invitation-loading .lds-ring div {
    margin: 0;
}

.member-tool-wrapper {
    width: 460px;
}

.col_full_name {
    width: 35%;
    position: relative !important;
    padding: 8px !important;

    .custom-checkbox {
        position: absolute !important;
        top: 1px !important;
        left: 12px !important;
    }

    .custom-checkbox-stripe {
        position: absolute !important;
        top: 1px !important;
        left: 12px !important;
    }

    .custom-checkbox-stripe [type="checkbox"]:checked+label:after {
        width: 16px;
        height: 16px;
    }
}

.col_name {
    width: 25%;
}

.col_role {
    width: 30%;

    .status-badge {
        width: 12px;
        height: 12px;
        border-radius: 50%;
        display: inline-block;
        position: relative;
        margin-right: 4px;
        top: 1px;
    }
}