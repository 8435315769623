.channel-icon-detail {
    width: 24px;
}

.chat-room-case-detail-tab-wrapper {
    font-size: 0;
    
    button {
        &.add-label {
            background-color: transparent;
            font-size: 12px;
            color: #191919;
            border: 1px solid #FF7E00;
            border-radius: 4px;
            height: 22px;
            color: #FF7E00;
            padding: 0 6px;

            svg {
                margin-right: 4px;
                color:#FF7E00;
                position: relative;
                top: -1px;
            }
        }
    }

    .box-labels {
        label {
            margin-bottom: 4px;
        }

        .labels-wrapper {
            display: flex;
            width: 100%;
            flex-wrap: wrap;

            .label-item {
                // display: flex;
                display: inline-block;
                align-items: center;
                margin-right: 4px;
                color: #ffffff;
                height: 22px;
                border-radius: 4px;
                width: fit-content;
                // line-height: 40px;
                padding: 2px 12px 4px 9px;
                font-size: 12px;
                position: relative;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                margin-bottom: 4px;
    
                svg {
                    color: #ffffff;
                    position: relative;
                    margin-right: 5px;
                    cursor: pointer;
                    font-size: 13px;
                    vertical-align: top;
                    top: 3px;
                }
            }
        }
    }
}

.modal-edit-topic {
    input {
        margin: 0 !important;
    }
}

.modal-confirmation-edit-topic {
    .modal-footer {
        button {
            width: calc(50% - 2px) !important;

            &:nth-child(2) {
                margin: 0;
            }
        }
    }
}

.modal-add-label {
	.modal-content {
		width: 476px;
		border-radius: 8px;

		.modal-body {
            padding: 32px;

            .add-label-wrapper {
                p.title {
                    font-size: 20px;
                    letter-spacing: -0.6px;
                    color: #191919;
                }
        
                input {
                    width: 100%;
                    font-size: 16px;
                    letter-spacing: -0.3px;
                    color: #191919;
                    line-height: 1;
                    padding: 16px;
                    border-radius: 8px;
                    border: solid 1px #dcdcdc;
                    margin: 0 0 24px 0;
    
                    &:focus {
                        border: solid 1px #ff7d00;
                    }
                }
        
                .button-container {
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                    margin-top: 24px;

                    button {
                        width: 50%;
          
                        &.orange-button {
                          margin-left: 8px;
                        }
                    }
                }

                .label-value {
                    font-size: 12px;
                    letter-spacing: -0.4px;
                    display: inline-block;
                    margin: 0 4px 4px 0;
                    vertical-align: top;
                    border-radius: 4px;
                    color: #ffffff;
                    padding: 2px 8px;

                    b {
                        font-size: 14px;
                        line-height: 1.5;
                        letter-spacing: -0.4px;
                    }
    
                    svg {
                        position: relative;
                        vertical-align: top;
                        margin-right: 4px;
                        top: 5px;
                        color: #ffffff;
                        cursor: pointer;
                    }

                    &.new-label {
                        background-color: rgba(255, 126, 0, 0.1);
                        color: #ff7d00;

                        svg {
                            top: 3px;
                            color: #ff7d00;
                        }
                    }
                }

                .label-value-wrapper {
                    width: 100%;
                    // max-width: 400px;
                    position: relative;
                    padding: 16px 50px 12px 12px;
                    // margin-bottom: 24px;
                    border-radius: 8px;
                    border: solid 1px #dcdcdc;
                    height: 56px;
                    overflow: auto;
                    display: flex;
                    flex-wrap: wrap;
                    // white-space: nowrap;
                    align-items: center;
                    // cursor: text;

                    .label-value {
                        display: flex;
                    }

                    &.focus {
                        border: 1px solid #FF7E00;
                    }
                
                    &.blur {
                        border: 1px solid rgba(25, 25, 25, 0.1);
                    }
        
                    .search-add-label-icon {
                        color: #191919;
                        font-size: 24px;
                        position: fixed;
                        right: 55px;
                        top: 94px;
                    }
        
                    input {
                        border: none !important;
                        display: inline-block;
                        width: unset;
                        // min-width: 160px;
                        // display: flex;
                        outline: none;
                        font-size: 16px;
                        letter-spacing: -0.3px;
                        color: rgba(25, 25, 25, 0.6);
                        margin: 0;
                        padding: 0;
                        flex-grow: 1;
                        position: relative;
                        top: -2px;
                        border-radius: 0 !important;
                    }
                }

                .add-label-suggestion-wrapper {
                    max-height: 190px;
                    width: 412px;
                    border-radius: 8px;
                    background: #ffffff;
                    overflow: auto;
                    position: absolute;
                    box-shadow: 0 2px 20px 0 rgba(25, 25, 25, 0.05);
                    display: none;
                    z-index: 3;

                    .loading-wrapper {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        height: 40px;
                    }
        
                    ul {
                        list-style: none;
                        margin: 0;
                        padding: 0;
                        
                        li {
                            width: 100%;
                            padding: 8px 16px;
                            cursor: pointer;
        
                            
                            &:hover {
                                background: #FFF2E5;
                            }
        
                            &.no-option {
                                color: rgba(25, 25, 25, 0.6);
                                text-align: center;
                                font-size: 16px;
                                letter-spacing: -0.3px;
        
                                &:hover {
                                    background: none;
                                }
                            }
        
                            .label-value {
                                margin: 0 4px 0 0;
                            }
                        }
                    }
                }
            }
			
		}
	}
}