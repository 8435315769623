#role-detail-wrapper {
    position: relative;
    width: 100%;
}

.role-detail-title {
    font-size: 20px;
    letter-spacing: -0.6px;
    color: #191919;
    margin: 0 0 32px;
    border-bottom: solid 1px #dcdcdc;
    padding-bottom: 24px;
    position: relative;

    .detail-title-text {
        position: relative;
        top: 6px;
    }

    button {
        position: absolute;
        right: 0;
        min-width: 181px;

        &.delete-role-button {
            right: 188px;
        }
    }
}

.role-detail-avatar {
    width: 72px;
    vertical-align: top;
    display: inline-block;
    margin-right: 36px;
}

#role-value-wrapper {
    width: calc(100% - 108px);
    display: inline-block;
}

.role-value-content {
    // border-bottom: solid 1px rgba(25, 25, 25, 0.1);
    margin-bottom: 24px;
}

.role-value-content img {
    width: 16px;
    margin-right: 15px;
    display: inline-block;
    vertical-align: top;
    position: relative;
    /* top: 12px; */
    top: 0;
}

.role-value-content .role-info-wrapper {
    width: calc(100% - 31px);
    display: inline-block;
    line-height: 1;

    .permission-wrapper {
        width: 418px;
        border-radius: 8px;
        background: #fafafa;
        padding: 16px;
    }

    p {
        color: rgba(25, 25, 25, 0.8);
        position: relative;

        svg {
            position: relative;
            margin-right: 6px;
            font-size: 18px;
            top: -1px;
        }

        &:nth-last-child(1) {
            margin: 0;
        }
    }
}

.role-value-content .role-info-wrapper label {
    margin-bottom: 8px;
    font-size: 16px;
    letter-spacing: -0.5px;
    color: rgba(25, 25, 25, 1);

    svg {
        position: relative;
        margin-left: 6px;
        font-size: 16px;
        color: var(--main-dark-orange);
        cursor: pointer;
        top: -2px;

        &:hover {
            color: var(--main-darker-orange);
        }
    }
}

.role-value-content .role-info-wrapper .role-detail-value {
    display: block;
    /* margin-bottom: 16px; */
    font-size: 16px;
    letter-spacing: -0.3px;
    color: #191919;
    margin: 0;
    width: 400px;
    line-height: 21px;
    word-break: break-word;
}

.role-value-content .role-info-wrapper .access-to-inbox-label {
    letter-spacing: -0.2px;
    font-size: 14px;
    color: rgba(25, 25, 25, 0.6);
    margin: 4px 0 0;
}

.role-value-content:nth-last-child(1) {
    border: none;
    margin-bottom: 0;
}

.role-value-content:nth-last-child(1) b {
    margin-bottom: 0;
}

#role-value-wrapper {
    .role-value-content {
        position: relative;
    }

    button {
        font-size: 12px;
        letter-spacing: -0.4px;
        border-radius: 4px;
        padding: 4px 10px;
        margin-top: 14px;

        img {
            width: 12px;
            height: 12px;
            margin-right: 4px;
        }
    }

    .icon-wrapper {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        display: inline-block;
        position: absolute;
        top: 0;
        left: 0;

        img {
            position: absolute;
            top: 50%;
            left: 50%;
            margin: 0;
            transform: translate(-50%, -50%);
        }

        &.icon-email {
            background: #E7E5FF;
        }

        &.icon-name {
            background: #FFF9E5;
        }

        &.icon-alias {
            background: #E9F5FD;
        }

        &.icon-role {
            background: #E5FFEE;
        }
    }
}

.role-detail-edit-topic {
    font-size: 16px;
    letter-spacing: -0.5px;
    padding: 7px 30px;
    position: absolute;
    top: 0;
    right: 0;

    img {
        margin-right: 4px;
        width: 16px;
        position: relative;
        top: -2px;
    }
}

.topic-list-wrapper {
    position: relative;
    width: 100%;
    margin-top: 24px;

    .topic-list-content {
        color: rgba(25, 25, 25, 0.6);
        font-size: 12px;
        letter-spacing: -.4px;
        border-radius: 4px;
        background: rgba(25, 25, 25, 0.05);
        margin: 0 4px 8px 0;
        display: inline-block;
        padding: 2px 8px;
        vertical-align: top;
    }
}

.topic-no-list {
    width: 100%;
    position: relative;
    height: 140px;
    text-align: center;
    color: rgba(25, 25, 25, 0.8);
    padding-top: 40px;

    .topic-no-list-icon-wrapper {
        width: 41px;
        height: 41px;
        display: inline-block;
        border-radius: 50%;
        position: relative;
        margin-bottom: 20px;
        background: #F3F3F3;

        img {
            width: 24px;
            position: relative;
            top: 6px;
            display: inline-block;
        }
    }
}

.modal-edit-aliass {
    .modal-content {
        width: 650px;
        border-radius: 8px;

        .modal-body {
            padding: 44px 24px;

            .modal-title {
                font-size: 20px;
                letter-spacing: -0.6px;
                width: 100%;
                position: relative;
            }

            input {
                margin-top: 8px;
                padding: 12px 16px;
                font-size: 16px;
                letter-spacing: -0.3px;
                width: 100%;
                border-radius: 6px;
                border: solid 1px #dcdcdc;

                &:focus {
                    border: solid 1px var(--main-dark-orange);
                }
            }

            .counter-alias {
                position: absolute;
                top: 2px;
                right: 0;
                font-size: 16px;
            }

            button {
                width: calc(50% - 2px);
                margin: 24px 4px 0 0;

                &:nth-last-child(1) {
                    margin: 24px 0 0;
                }
            }
        }
    }
}

.modal-role-change-role .modal-content {
    padding: 32px;
    border-radius: 8px;
    width: 500px;
}

.modal-role-change-role .modal-body {
    padding: 0;
}

.modal-role-change-role .modal-footer {
    border: 0;
    padding: 0;
}

.modal-role-change-role .modal-footer button {
    width: calc(50% - 4px);
    margin: 0 8px 0 0;
    padding: 10px 0 12px;
    position: relative;
    height: 48px;
}

.modal-role-change-role .modal-footer button:nth-child(2) {
    margin-right: 0;
}

.modal-role-change-role .modal-role-change-role-title {
    border-bottom: solid 1px #dcdcdc;
    margin: 0 0 18px;
    padding-bottom: 16px;
    font-size: 20px;
    letter-spacing: -0.6px;
}

.modal-role-change-role .modal-role-role-wrapper {
    margin-bottom: 36px;
}

.modal-role-change-role .modal-role-role-wrapper b {
    font-size: 16px;
    letter-spacing: -0.5px;
    color: #191919;
}

.modal-role-change-role .modal-role-role-wrapper span {
    font-size: 14px;
    letter-spacing: -0.2px;
    color: rgba(25, 25, 25, 0.6);
    display: block;
}

.modal-role-change-role .change-role-gap {
    height: 24px;
    width: 100%;
}

.form-title-wrapper {
    &.assigned-topic-member {
        display: flex;
        justify-content: space-between;

        button {
            font-size: 16px;
        }
    }
}

.role-member-bottom {
    position: relative;
    margin-bottom: 12px;

    .button-add-member {
        position: absolute;
        top: -2px;
        right: 0;
    }
}

.role-member-bottom-2 {
    width: 100%;

    table {
        margin: 24px 0 0;

        .col-action-button-wrapper {
            img {
                position: relative;
                top: -2px;
                margin-right: 4px;
            }
        }

        &.table-roles-list-member-detail {
            thead {
                td {
                    &.col_full_name {
                        width: unset !important;
                    } 

                    &.col_alias {
                        width: unset !important;
                    }

                    &.col_email {
                        width: unset !important;
                    }

                    &.col_remove {
                        width: 100px !important;
                    }
                }
            }

            tbody {
                .col_full_name_body {
                    padding: 8px 8px 8px 44px;

                    .custom-checkbox {
                        position: absolute !important;
                        top: 1px !important;
                        left: 8px !important;
                    }
                }

                .col_remove {
                    cursor: pointer;
                    
                    img {
                        width: 16px;
                        position: relative;
                        top: -1px;
                        margin-right: 4px;
                    }
                }
            }
        }
    }
}