.tier-home-wrapper {
    position: fixed;
    top: 0;
    left: 340px;
    width: calc(100% - 340px);
    height: 100%;
    transition: ease-in-out .1s;
    background: #f8f8f8;

    &.tier-home-active-billing-warning {
        height: calc(100% - 32px);
        top: 32px;
    }
    
    &.deactive-sub-menu {
        width: calc(100% - 62px);
        left: 62px;
    }

    .please-select-tier-wrapper {
        text-align: center;
        width: 550px;
        display: inline-block;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    
        p {
            margin: 0;
        }
        
        .please-select-tier-alert {
            font-size: 20px;
            letter-spacing: -0.6px;
            color: #191919;
    
            svg {
                margin-right: 8px;
                position: relative;
                top: -2px;
            }
        }
    
        .please-select-tier-description {
            font-size: 14px;
            letter-spacing: -0.2px;
            color: rgba(25, 25, 25, 0.6);
            margin: 0 0 16px;

            a {
                color: rgba(25, 25, 25, 0.6);

                :hover {
                    color: #191919;
                }
            }
        }
    
        button {
            height: 48px;
            width: 100%;
            font-size: 16px;
            letter-spacing: -0.5px; 
        }
    }
}