.office-hour-form-wrapper {
    .form-content-wrapper {
        width: 300px;

        button { 
            margin: 0;
        }
    }

    .office-hour-form-title {
        font-size: 20px;
        letter-spacing: -0.6px;
        display: block;
        margin: 0 0 24px;
        padding-bottom: 11px;
        border-bottom: solid 1px #dcdcdc;
    }
    
    .office-hour-time-form-wrapper {
        width: calc(50% - 4px);
        display: inline-block;
        margin-right: 8px;
        vertical-align: top;
        position: relative;

        input {
            width: 100%;
            padding-right: 50px;
        }
        
        &:nth-last-child(1) {
            margin: 0;
        }

        svg {
            font-size: 24px;
            color: #191919;
            position: absolute;
            right: 16px;
            top: 65px;
        }
    }

    .day-of-week-office-hour {
        color: rgba(25, 25, 25, 0.6);
        background: rgba(25, 25, 25, 0.1);
        width: 100%;
        border: solid 1px #dcdcdc;
        border-radius: 8px;
        font-size: 14px;
        letter-spacing: -0.4px;
        padding: 12px 16px;
    }
    
    .save-change-office-hour {
        margin-top: 24px;
    }
}