.blocking-screen-page-role {
    position: fixed;
    top: 0;
    left: 304px;
    width: calc(100% - 304px);
    height: 100%;
    transition: ease-in-out .1s;
    background: #f8f8f8;

    &.blocking-page-role-active-billing-warning {
        height: calc(100% - 32px);
        top: 32px;
    }
    
    &.deactive-sub-menu {
        width: calc(100% - 62px);
        left: 62px;
    }

    .blocking-screen-page-role-wrapper {
        text-align: center;
        width: 412px;
        display: inline-block;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    
        p {
            margin: 15px 0 0;
        }
        
        .blocking-screen-page-role-alert {
            font-size: 20px;
            letter-spacing: -0.6px;
            color: rgba(25, 25, 25, 0.6);
        }
    
        button {
            height: 40px;
            line-height: 33px;
            display: inline-block;
            font-size: 16px;
            letter-spacing: -0.5px; 
            padding: 0 24px;
            margin: 32px 0 0;
        }
    }
}