.chat-room-history-modal-wrapper {
    width: 100vw;
    height: 100vh;
    background: rgba(25, 25, 25, 0.8);
    position: fixed;
    top: 0;
    /* right: -100vw; */
    visibility: hidden;
    opacity: 0;
    right: 0;
    /* transition: visibility 0s, opacity 0.3s ease-in-out; */
    transition: visibility .3s, opacity 0.5s ease-in-out;
    z-index: 4;
}

.chat-room-history-modal-wrapper.active-chat-room-history-modal-wrapper {
    /* right: 0; */
    opacity: 1;
    visibility: visible;
    transition: visibility 0s, opacity 0.5s ease-in-out;
}

.chat-room-history-modal-content {
    position: absolute;
    width: 340px;
    height: calc(100vh - 8px);
    right: -100vw;
    top: 8px;
    display: inline-block;
    transition: ease-in-out .5s;
}

.chat-room-history-modal-content.active-chat-room-history-modal-content {
    right: 0;
    background: #ffffff;
    border-top-left-radius: 18px;
}

.chat-room-history-modal-wrapper .user-info-tab-content {
    width: 50%;
}