.pay-now-wrapper {
    .auto-payment-card-select-wrapper {
        position: relative;

        .auto-payment-add-card {
            font-size: 14px;
            color: #FF7E00;
            margin: 12px 0 0 0;
            cursor: pointer;

            svg {
                position: relative;
                top: -2px;
                font-size: 20px;
                margin-right: 4px;
            }

            &:hover {
                color: #e87200;
            }
        }

        &.selected-card {
            position: relative;
            
            label {
                margin: 12px 0 8px;
            }

            .dropdown {
                button {
                    padding: 16px 46px 16px 83px;
                    // width: 245px;
                    border: solid 1px #dcdcdc;
                    border-radius: 8px;
                    background: transparent;
                    box-shadow: none !important;
                    outline: none;
                    color: #191919;
                    margin: 0;
                    text-align: left;
                    position: relative;
                    max-width: 100%;
                    min-width: 268px;

                    p {
                        margin: 0;
                        line-height: 1;
                    }

                    svg {
                        position: absolute;
                        right: 13px;
                        top: 50%;
                        transform: translate(0, -50%);
                        font-size: 16px;
                        transition: ease-in-out .2s;

                        &.active-dropdown-card {
                            transform: translate(0, -50%) rotate(180deg);
                        }
                    }

                    &.please-select-card {
                        color: rgba(25, 25, 25, 0.3);
                        padding: 16px 46px 16px 16px;
                    }
                }
                
                .dropdown-menu {
                    padding: 0;
                    border-radius: 8px;
                    max-width: 100%;

                    button {
                        border: none;
                        border-radius: 0;
                        
                        &:nth-child(1) {
                            border-top-right-radius: 8px;
                            border-top-left-radius: 8px;
                        }

                        &:nth-last-child(1) {
                            border-bottom-right-radius: 8px;
                            border-bottom-left-radius: 8px;
                        }

                        &:hover {
                            background: rgba(255, 126, 0, 0.05);
                        }
                    }
                }
            }

            .centered-dot {
                width: 5px;
                height: 5px;
                display: inline-block;
                vertical-align: top;
                background: rgba(25, 25, 25, 0.4);
                border-radius: 50%;
                position: relative;
                top: 9px;
            }
        
            .card-icon {
                position: absolute;
                left: 13px;
                top: 50%;
                transform: translate(0, -50%);
            }
        
            .card-number {
                font-size: 16px;
                color: #191919;
                letter-spacing: -0.3px;
                position: relative;
                top: -2px;
                white-space: nowrap;
                max-width: 100%;
                overflow: hidden;
                text-overflow: ellipsis;
        
                .masking-dot {
                    vertical-align: top;
                    width: 4px;
                    height: 4px;
                    position: relative;
                    top: 3px;
                    display: inline-block;
                    margin: 0 2px;
                    border-radius: 50%;
                    background: #191919;
                }
            }
        
            .card-expiry-date {
                font-size: 12px;
                letter-spacing: -0.4px;
                color: rgba(25, 25, 25, 0.6);
                white-space: nowrap;
                max-width: 100%;
                overflow: hidden;
                text-overflow: ellipsis
            }
        }

        .list-card-selection-box {

        }
    }

    .option-box-wrapper {
        position: relative;
        border: solid 1px #dcdcdc;
        padding: 17px 15px;
        width: 291px;
        border-radius: 8px;
        margin-bottom: 10px;
    }

    .button-continue {
        margin-top: 22px;
        width: 110px;
    }

    .custom-radio {
        label {
            margin: 0;
            position: relative;
            line-height: 1;

            &::before {
                top: 50% !important;
                transform: translate(0, -50%);
            }

            &::after {
                top: 50% !important;
                transform: translate(0, -50%) scale(1) !important;
            }

            img {
                position: absolute;
                right: 0;
                top: 50%;
                transform: translate(0, -50%);
            }
        }
    }
}