.slide-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100% !important;
    height: 100%;
    background: rgba(25, 25, 25, 0.8);
    visibility: hidden;
    opacity: 0;
    right: 0;
    -webkit-transition: visibility .3s, opacity 0.5s ease-in-out;
    transition: visibility .3s, opacity 0.5s ease-in-out;
    z-index: 9999;

    &.active-slide-modal {
        /* right: 0; */
        opacity: 1;
        visibility: visible;
        transition: visibility 0s, opacity 0.5s ease-in-out;
    }

    .modal-body {
        position: absolute;
        top: 8px;
        right: -100%;
        left: unset;
        transform: unset;
        height: calc(100% - 8px);
        width: 340px;
        display: inline-block;
        border-radius: 8px 0 0 0;
        background: #ffffff;
        padding: 0;
        max-height: calc(100% - 8px);
        // height: 100%;
        transition: ease-in-out .4s;

        &.active-slide-modal-body {
            right: 0;
        }

        .close-slide-modal {
            border-radius: 50%;
            width: 48px;
            height: 48px;
            background: #ffffff;
            color: #757575;
            position: absolute;
            left: -65px;
            top: 0;
            text-align: center;
            cursor: pointer;
            
            &:hover {
                background: #f9f9f9;
            }
            
            svg {
                position: absolute;
                font-size: 25px;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }
    }

}

