.form-add-quick-reply-interactive-list-personal {
    .line-through {
        margin: 28px 0 0;
    }

    .message-content {
        width: 100%;
        position: relative;
        padding: 22px 0 12px;

        p {
            margin: 0;
            font-size: 16px;
            letter-spacing: -0.5px;
        }

        button {
            position: absolute;
            right: 0;
            top: -12px;
            width: unset;
        }
    }

    .input-outer-wrapper {
        width: 570px;
        max-width: 100%;

        label {
            position: relative;
            width: 100% !important;
        }

        textarea, input[type="text"] {
            position: relative;
            width: 100%;
        }

        .text-optional {
            position: relative;
            top: unset;
            right: unset;
        }

        textarea {
            height: 125px;
            padding: 12px;
        }

        .input-shortcut {
            width: calc(100% - 119px) !important;
        }
    }

    .button-text-wrapper-form {
        border-radius: 8px;
        border: solid 1px rgba(25, 25, 25, 0.1);
        background: #f3f3f3;
        padding: 12px;
        margin: 8px 0 0;
        width: 570px;
        max-width: 100%;

        .manage-rows {
          margin: 14px 0 0;
          font-size: 14px;
          letter-spacing: -0.5px;
          color: var(--main-dark-orange);
          cursor: pointer;

          svg {
            position: relative;
            top: -1px;
            margin-right: 4px;
            font-size: 16px;
          }

          &:hover {
            color: var(--main-darker-orange);
          }
        }

        input[type="text"] {
            width: 100%;
        }

        &.button-text-wrapper-form-1 {
          margin: 24px 0 0 !important;
        }

        label {
          margin-top: 0;
        }

        .remove-button-text {
          position: relative;
          top: -1px;
          cursor: pointer;
          margin-left: 4px;
          color: rgba(255, 63, 87, 1) !important;
        }
      }

      .add-another-button {
        margin: 16px 0 0;
        padding: 0 0 12px 0;
        font-size: 14px;
        cursor: pointer;
        letter-spacing: -0.5px;
        color: var(--main-dark-orange);

        svg {
          position: relative;
          margin-right: 4px;
          top: -1px;
        }

        &:hover {
          color: var(--main-darker-orange);

          svg {
            color: var(--main-darker-orange);
          }
        }
    }
}