.main-reuseable-button {
    white-space: nowrap;

    b {
        position: relative;
        // top: -1px;

        &.top-0 {
            top: 0;
        }
    }

    .button-text-wrapper {
        height: 100%;
        position: relative; 
        display: inline-block;

        span {
            position: relative;
            z-index: 2;
            cursor: pointer;
            font-weight: normal !important;
        }

        p {
            font-family: PTRootBold;
            margin: 0 !important;
            padding: 0 !important;
            // vertical-align: top;
            height: 100% !important;
            // position: relative !important;
            display: inline-block !important;
        }
    }
    

    .LoadingRoll_1234fed {
        display: inline-block;
        line-height: 1;
        vertical-align: top;
        padding: 0;
        height: 100%;
        width: 18px;

        .lds-ring {
            display: inline-block;
            position: relative;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            right: unset;
            z-index: 2;
            cursor: pointer;
        }
    }

    .loading-left {
        margin-right: 7px;
        display: inline-block;
        height: 100%;
        width: 18px;
        position: relative;
    }

    .loading-right {
        display: inline-block;
        margin-left: 7px;
        height: 100%;
        width: 18px;
        position: relative;
    }
}