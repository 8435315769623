.chat-room-wrapper {
    background: #F3F3F3;
    /* width: calc(100% - 635px); */
    /* width: calc(100% - 360px); */
    width: calc(100% - 382px);
    display: inline-block;
    height: 100%;
    max-height: 100vh;
    vertical-align: top;
    overflow: hidden;
    box-shadow: -1px 0 #dcdcdc;
    position: relative;
}

.group-sender-avatar-wrapper img {
    vertical-align: top;
}