.chatgpt-intro-wrapper {

    .top-title {
        width: 100%;
        display: block;
        position: relative;
        font-size: 20px;
        letter-spacing: -0.6px;
        margin: 0 0 12px;
        padding: 0 0 12px 0;
        border-bottom: solid 1px #dcdcdc;
    }

    .chatgpt-intro-card-wrapper {
        width: 100%;
        position: relative;
        display: block;

        .chatgpt-intro-card-outer {
            margin: 24px 0 0;

            .intro-title {
                width: 100%;
                position: relative;
                display: block;
                margin: 0 0 12px 0;
                white-space: nowrap;

                .intro-number {
                    width: 26px;
                    height: 26px;
                    border-radius: 50%;
                    background: rgba(25, 25, 25, 0.05);
                    position: relative;
                    text-align: center;
                    margin: 0 8px 0 0;
                    line-height: 25px;
                    display: inline-block;
                    font-size: 14px;
                }

                .intro-title-text {
                    font-size: 20px;
                    letter-spacing: -0.6px;
                    position: relative;
                    top: 2px;
                }
            }

            .intro-card {
                padding: 24px;
                background: rgba(25, 25, 25, 0.05);
                border-top: solid 1px #dcdcdc;

                .intro-card-image {
                    width: 240px;
                    padding: 0;
                    vertical-align: top;
                    display: inline-block;

                    img {
                        width: 240px;
                        // height: 123px;
                    }
                }

                .intro-html-wrapper {
                    width: calc(100% - 240px);
                    padding: 0 0 0 24px;
                    display: inline-block;
                    font-size: 14px;
                    letter-spacing: -0.4px;
                    line-height: 21px;
                    vertical-align: top;

                    ul {
                        padding-left: 23px;

                        // li::marker {
                        //     font-size: 10px;
                        // }
                    }
                }
            }
        }
    }

    .continue-button-wrapper {
        margin-top: 24px;
        width: 100%;
        text-align: right;

        .nav-link {
            padding: 0;
        }

        button {
            // position: absolute;
            // top: 0;
            // right: 0;
            width: 180px;
        }
    }
}