.form-image-message-wrapper {
    position: relative;

    input[type="file"] {
        display: none;
    }

    label {
        display: inline-block !important;

        span {
            color: rgba(25, 25, 25, 0.6);
        }

        &.button-upload-image {
            margin: 0 4px;
            color: #ff7d00;
            letter-spacing: -0.4px;
            font-size: 12px;
            border-radius: 4px;
            cursor: pointer;
            display: inline-block !important;
            padding: 2px 8px;
            background: rgba(255, 126, 0, 0.1);
            -webkit-transition: ease-in-out .1s;
            transition: ease-in-out .1s;

            &:hover {
                color: #ffffff;
                background: #ff7d00;
            }

            svg {
                position: relative;
                top: -1px;
                margin-right: 4px;
            }
        }
    }

    .image-and-caption-wrapper {
        position: relative;
        // width: 494px;
        width: 100%;
        max-width: 100%;
        
        .image-preview-wrapper {
            background: #ffffff;
            position: absolute;
            top: 1px;
            left: 16px;
            padding: 11px 0 8px 0;
            border-bottom: solid 1px rgba(25, 25, 25, 0.1);
            width: calc(100% - 32px);

            &.image-preview-wrapper-grey {
                background: #e8e8e8;
            }

            svg {
                font-size: 64px;
                position: relative;
                left: -5px;
                color: #9b9b9b;
            }

            .image-preview-frame {
                border: solid 1px rgba(25, 25, 25, 0.1);
                width: 64px;
                height: 64px;
                display: inline-block;
                position: relative;
                border-radius: 8px;
                overflow: hidden;

                img {
                    max-width: 100%;
                    max-height: 100%;
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                }

                video {
                    max-width: 100%;
                    max-height: 100%;
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                }

                .progress-wrapper {
                    position: absolute;
                    top: 0;
                    left: 0;
                    background: rgba(25, 25, 25, 0.4);
                    width: 100%;
                    height: 100%;

                    .CircularProgressbar {
                        width: calc(100% - 12px);
                        height: calc(100% - 12px);
                        position: absolute;
                        top: 6px;
                        left: 6px;

                        .CircularProgressbar-trail {
                            stroke: transparent;
                            stroke-width: 20;
                        }
                        
                        .CircularProgressbar-path {
                            stroke: #ffffff;
                        }
                    } 
                }

                .image-button-wrapper {
                    position: absolute;
                    top: 0;
                    left: 0;
                    background: rgba(25, 25, 25, 0.4);
                    width: 100%;
                    height: 100%;
                    display: none;

                    .close-button-wrapper {
                        width: 16px;
                        height: 16px;
                        border-radius: 50%;
                        background: #636462;
                        text-align: center;
                        font-size: 7px;
                        color: #ffffff;
                        cursor: pointer;
                        position: absolute;
                        top: 4px;
                        right: 4px;

                        &:hover {
                            background: #ff3f57;
                        }
                    }

                    svg {
                        &.preview-button {
                            font-size: 20px;
                            color: #ffffff;
                            cursor: pointer;
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                        }   

                        &.close-button {
                            color: #ffffff;
                            font-size: 12px;
                            position: absolute;
                            left: 50%;
                            top: 50%;
                            transform: translate(-50%, -50%);
                        }
                    }
                }
                
                &:hover {
                    .image-button-wrapper {
                        display: inline-block;
                    }
                }
            }
        }

        textarea {
            padding: 92px 16px 12px 16px;
            height: 178px !important;

            &[disabled] {
                background: #e8e8e8;
                cursor: not-allowed;

                // padding: 12px 16px;
                // height: 96px !important;
            }
        }
    }
}

.modal-image-preview {
    width: 100% !important;

    .modal-content {
        width: 100%;
        height: 100%;
        background: unset;

        .modal-body {
            padding: 56px 86px;
            position: relative;

            img {
                max-width: calc(100% - 112px);
                max-height: calc(100% - 172px);
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                background: #ffffff;
            }

            video {
                max-width: calc(100% - 112px);
                max-height: calc(100% - 172px);
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                background: #ffffff;
            }

            svg {
                font-size: 24px;
                color: #ffffff;
                cursor: pointer;
                position: absolute;
                top: 44px;
                right: 44px;
            }
        }
    }
}