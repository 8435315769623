.top-bar-blocking-wrapper {
    height: 32px;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    white-space: nowrap;
    text-align: center;
    background: #DCEBFF;
    color: #044B84;
    line-height: 32px;
    font-size: 16px;
    letter-spacing: -0.3px;
    
    svg {
        position: relative;
        top: -2px;
        margin-right: 4px;
    }

    a {
        color: #044B84;
        text-decoration: underline !important;
    }
}