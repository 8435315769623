.modal-has-open-case {
    .modal-content {
        width: 476px;
    }

    .modal-body {
        padding: 32px;

        .has-open-case-title {
            font-size: 20px;
            letter-spacing: -0.6px;
            margin: 0;
            line-height: 1;
            color: #191919;

            svg {
                position: relative;
                margin-right: 4px;
                top: -2px;
            }
        }

        .has-open-case-description {
            color: rgba(25, 25, 25, 0.6);
            font-size: 14px;
            margin: 8px 0 0;
        }

        .other-agent-wrapper {
            background: rgba(25, 25, 25, 0.05);
            border-radius: 8px;
            padding: 15px 12px 15px 56px;
            position: relative;
            width: 100%;
            color: #191919;
            font-size: 14px;
            letter-spacing: -0.4px;
            margin-top: 20px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            .agent-avatar {
                width: 34px;
                height: 34px;
                border-radius: 50%;
                color: #ffffff;
                text-align: center;
                line-height: 34px;
                font-size: 14px;
                letter-spacing: -0.4px;
                position: absolute;
                left: 12px;
                top: 50%;
                transform: translate(0, -50%);
            }
        }

        button {
            &.dismiss {
                height: 48px;
                font-size: 16px;
                letter-spacing: -0.5px;
                margin-top: 20px;
                width: 100%;
            }
        }
    }
}