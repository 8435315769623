.reply-message-out-bubble {
    // width: calc(100% - 20px);
    width: 100%;
    // display: inline-block;
    display: block;
    // position: absolute;
    // top: 10px;
    // left: 10px;
    position: relative;
    background: rgba(25, 25, 25, 0.05);
    margin-bottom: 4px;
    border-radius: 8px;
    overflow: hidden;
    cursor: pointer;

    &.with-media-or-file {
        padding: 8px 0;
    }

    .reply-message-out-bubble-name-text-wrapper {
        padding: 0 8px;

        &.with-border {
            padding: 0 8px 0 12px;

            &::before {
                position: absolute;
                left: 0;
                top: 0;
                width: 4px;
                border-top-left-radius: 4px;
                border-bottom-left-radius: 4px;
                background: var(--main-dark-orange);
                content: "";
                height: 100%;
            }
        }

        .reply-file-media-wrapper {
            border-radius: 4px;
            width: 48px;
            height: 48px;
            display: inline-block;
            margin: 0 8px 0 0;
            vertical-align: top;
            position: relative;
            top: 0;

            .reply-message-image {
                width: 100%;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                max-width: 100%;
                max-height: 100%;
                border-radius: 0 !important
            }

            .reply-message-video {
                width: 100%;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                max-width: 100%;
                max-height: 100%;
                border-radius: 0 !important
            }  

            .reply-message-file {
                border-radius: 50%;
                width: 100%;
                height: 100%;
                position: relative;
                background: #ff7e00;

                svg {
                    color: #ffffff;
                    font-size: 22px;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }
            }
        }

        .reply-message-out-bubble-reply-name-wrapper {
            position: relative;
            display: inline-block;
            padding: 6px 0 8px;
            width: 100%;
            color: var(--main-dark-orange);
            
            p {
                margin: 0;

                &.reply-message-out-bubble-reply-name {
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    max-width: 100%;
                    font-size: 12px;
                    line-height: 18px;
                    letter-spacing: -0.4px;
                }

                &.reply-message-out-bubble-reply-text {
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    max-width: 100%;
                    font-size: 12px;
                    line-height: 16px;
                    letter-spacing: -0.2px;
                    color: rgba(25, 25, 25, 0.4);
                }
            }
        }

        
        &.with-media-file {
            padding: 0 8px 0 12px;

            .reply-message-out-bubble-reply-name-wrapper {
                width: calc(100% - 66px);
            }
        }
    }
}