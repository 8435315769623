.login-image img {
    width: 85%;
}

.modal-resend-email .modal-content {
    padding: 48px 48px 34px;
    border-radius: 32px;
    max-width: 540px;
    width: calc(100vw - 120px);
}

.modal-resend-email .modal-body {
    padding: 0;
}

.modal-resend-email .modal-body p {
    font-size: 16px;
    font-weight: normal;
    color: #191919;
    letter-spacing: -0.3px;
    text-align: center;
}

.modal-resend-email .modal-body svg {
    font-size: 26px;
    position: relative;
    top: -2px;
}

.modal-resend-email .modal-body p:nth-child(1) {
    font-size: 24px;
    font-weight: bold;
    letter-spacing: -0.4px;
    color: #9b9b9b;
}

.modal-resend-email .modal-footer {
    padding: 0 0 10px;
    border: none;
}

.modal-resend-email .modal-footer button {
    width: 100%;
    position: relative;
}

.modal-email-sent .modal-body p:nth-child(1) {
    color: #7ec82a;
}

.modal-email-sent img {
    width: 30px;
    position: relative;
    top: -2px;
}

.modal-resend-email .lds-ring div {
    border-color: #ffffff transparent transparent transparent;
}

.input-wrapper .password-title-wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.input-wrapper .password-title-wrapper .forgot-password {
    color: #ff7d00;
    cursor: pointer;
}