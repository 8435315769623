.broadcast-main-wrapper {
    .btn {
        border: 0;
    }

    tr:hover {
        td {
            background: #ffffff !important;
        }
    }
}

.member-tab-title {
    color: #191919;
    font-size: 20px;
    letter-spacing: -0.6px;
    line-height: 24px;
    padding-bottom: 16px;
    border-bottom: solid 1px rgba(25, 25, 25, 0.1);
}

.member-tab-tool-wrapper {
    width: 100%;
    position: relative;
    margin-top: 24px;
    margin-bottom: 32px;
}

.member-tab-info {
    font-size: 16px;
    letter-spacing: -0.3px;
    color: rgba(25, 25, 25, 0.8);
    line-height: 1.5;
    padding: 0 208px 18px 0;
}

.member-tab-tool-wrapper button.button-invite-member {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(0, -50%);
    padding: 11px 0;
    width: 200px;
}

.member-tab-tool-wrapper button.button-invite-member svg {
    position: relative;
    top: -1px;
    margin-right: 5px;
}

.member-tab-table-wrapper {
    width: 100%;
    position: relative;
}

.table-member-tab thead tr {
    background: #ffffff;
    // border-top: solid 1px #dcdcdc;
}

.table-member-tab tbody td {
    background: #ffffff;
    position: relative;
}

.table-member-tab button svg {
    position: relative !important;
    right: unset !important;
    top: -2px !important;
    margin-right: 5px;
}

.table-member-tab .col-action-button-wrapper {
    position: absolute;
    right: 7px;
    top: 50%;
    transform: translate(0, -50%);
    /* display: none; */
}

.table-member-tab .single-remove-button {
    margin-left: 16px;
    color: #ff3f57;
}

.table-member-tab .single-remove-button:hover {
    color: #e83b51;
}

.table-member-tab .shortcut-badge {
    font-size: 12px;
    color: rgba(25, 25, 25, 0.6);
    letter-spacing: -0.4px;
    background: #f3f3f3;
    line-height: 1;
    border-radius: 4px;
    padding: 1px 8px 3px;
}

.table-member-tab td {
    max-width: unset !important;
}

.table-member-tab .col-action-button-wrapper {
    display: block !important;
    position: relative;
    top: 0;
    right: unset;
    transform: unset;
}

.table-member-tab .col-action-button-wrapper .view-agent {
    color: #ff7d00;
    letter-spacing: -0.5px;
}

.table-member-tab .col-action-button-wrapper .view-agent:hover {
    color: var(--main-darker-orange);
}

.table-member-tab .col-action-button-wrapper img.view-agent {
    margin-right: 3px;
    position: relative;
    top: -1px;
}

.member-tool-wrapper {
    width: 460px;
}

.table-member-tab {
    .col_action {
        width: 200px;
        text-align: right;
    }
}

.broadcast-page-wrapper {
    margin: 32px 0 0;

    .nav-item {
        cursor: pointer;
        color: rgba(25, 25, 25, 0.4);
        font-size: 16px;
        letter-spacing: -0.5px;
        padding: 0 0 12px 0;
        margin-right: 32px;

        a {
            padding: 0;
            border: none;
        }

        :hover {
            color: #ff7d00;
        }

        &.active-setup-tab {
            color: #ff7d00;
            border-bottom: solid 4px #ff7d00;
        }
    }

    .tab-content {
        margin: 32px 0 0;
    }
}