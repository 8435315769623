.modal-select-interactive-message {
    .modal-content {
        width: 635px;

        .modal-body {
            position: relative;
            border-radius: 8px;
            padding: 32px;

            .icon-close {
                position: absolute;
                top: 32px;
                right: 32px;
                color: var(--main-dark-orange);
                cursor: pointer;
                font-size: 20px;

                &:hover {
                    color: var(--main-darker-orange);
                }
            }

            .title-modal {
                font-size: 20px;
                letter-spacing: -0.6px;
                margin: 0 0 4px 0;
            }

            .modal-subtitle {
                font-size: 16px;
                letter-spacing: -0.3px;
                margin: 0 0 24px 0;
            }

            .select-interactive-50 {
                width: calc(50%);
                display: inline-block;
                vertical-align: top;
                text-align: center;
                padding: 24px;
                border-right: solid 1px #dcdcdc;

                &:nth-last-child(1) {
                    border: none !important;
                }

                img {

                }

                .select-title {
                    margin: 12px 0 6px 0;
                    font-size: 16px;
                    letter-spacing: -0.5px;
                }

                .select-subtitle {
                    margin: 0 0 12px 0;
                    font-size: 14px;
                    letter-spacing: -0.2px;
                }

                button {
                    line-height: 22px !important;
                    height: 24px;
                    border-radius: 4px !important;
                    font-size: 12px !important;
                    letter-spacing: -0.4px !important;
                }
            }
        }
    }
}