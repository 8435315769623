.main-integration-google-create-greeting {
    .border-top {
        border-top: solid 1px #dcdcdc;
        width: 100%;
        margin: 24px 0 0;
        padding: 24px 0;
        position: relative;

        svg {
            position: relative;
            top: -1px;
            margin-right: 4px;
        }

        p {
            margin: 0;
            font-size: 16px;
            letter-spacing: -0.5px;
        }

        button {
            position: absolute;
            right: 0;
            top: 0;
        }
    }

    .margin-top-24 {
        margin-top: 24px !important;
    }

    input {
        width: 400px;
        display: block;
        margin: 0;
    }

    textarea {
        width: 400px;
        display: block;
        margin: 0;
    }

    .dropdown {
        margin: 0;

        button {
            margin: 0;
        }
    }
    
    button {
        margin-right: 8px;
        margin-top: 24px;
    }

    .greeting-wrapper {
        width: 400px;
        display: block;
        position: relative;

        .check-locale {
            display: inline-block;
            padding: 0 0 0 20px;
            position: absolute;
            top: 42px;
            margin: 0;
            right: -30px;
            color: rgba(25, 25, 25, 0.4);

            .custom-checkbox {
                position: absolute;
                top: 2px;
                left: 0;
            }

            label {
                width: 147px;
            }
        }
    }

    label {
        position: relative;
        
        .grey-font {
            position: absolute;
            top: 0;
            right: 0;
        }
    }

    .add-lang {
        font-size: 16px;
        letter-spacing: -0.5px;
        color: #ff7d00;
        cursor: pointer;
        margin-top: 18px;

        svg {
            margin-right: 4px;
            position: relative;
            top: -1px;
        }

        &:hover {
            color: var(--main-darker-orange);
        }
    }
}