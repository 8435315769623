.case-list-action-wrapper {
    width: 100%;
    height: 26px;
    position: relative;
    display: block;
    border-bottom: solid 1px rgba(25, 25, 25, .1);
    z-index: 2 !important;
    background: #fff;
    
    .case-list-action-left {
        position: absolute;
        left: 12px;
        top: 50%;
        transform: translate(0, -50%);
        height: 26px;
        line-height: 22px;
        
        .dropdown {
            button {
                background: none !important;
                border: none !important;
                outline: none !important;
                padding: 0;
                font-size: 12px;
                letter-spacing: -0.4px;
                outline: none !important;
                box-shadow: none !important;
                color: #ff7d00 !important;

                .dropdown-case-action-active {
                    transform: rotate(180deg);
                }

                svg {
                    margin-left: 4px;
                    font-size: 16px;
                    transition: ease-in-out .2s;
                    position: relative;
                    top: -1px;
                }

                &:hover {
                    color: var(--main-darker-orange) !important;

                    svg {
                        color: var(--main-darker-orange) !important;
                    }
                }
            }

            &.dropdown-disabled {
                button {
                    color: rgba(25, 25, 25, .6) !important;

                    svg {
                        color: rgba(25, 25, 25, .6) !important;    
                    }
                }
            }
        }

        .dropdown-menu {
            background: #fff;
            padding: 0;
            border-radius: 8px;
            box-shadow: 0px 2px 20px rgba(25, 25, 25, 0.18);
            border: none !important;
            top: 6px;

            button {
                color: #191919 !important;
                font-size: 16px;
                letter-spacing: -0.3px;
                padding: 12px;

                svg {
                    position: relative;
                    margin-right: 8px;
                    margin-left: 0;
                    font-size: 18px;
                    color: #ff7d00 !important;
                    top: -1px;
                }
            }
        }
    }

    .case-list-action-right {
        position: absolute;
        right: 12px;
        top: 50%;
        transform: translate(0, -50%);
        height: 26px;
        line-height: 22px;
        padding-right: 20px;

        b {
            color: rgba(25, 25, 25, 0.6);
            letter-spacing: -0.4px;
            font-size: 12px;
        }

        .custom-checkbox-stripe {
            display: inline-block;
            position: relative;
            top: -6px;
            right: -4px;

            [type="checkbox"]:checked + label:after {
                width: 16px;
                height: 16px;
                top: 2px;
                left: 0;
            }

            label {
                padding: 0;
            }
        }

        .custom-checkbox {
            display: inline-block;
            position: relative;
            top: -6px;
            right: -4px;

            [type="checkbox"]:checked + label:after {
                width: 26px;
                height: 26px;
                top: -3px;
                left: -5px;
            }

            label {
                padding: 0;
            }
        }
    }
}