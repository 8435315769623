.register-image img {
    width: 100%;
}

.div50 {
    display: inline-block;
    width: 50%;
    position: relative;
    vertical-align: top;
}

.div50 div {
    letter-spacing: -0.2px;
    color: #191919;
    font-size: 14px;
    height: 28px;
    line-height: 28px;
    position: relative;
    padding-left: 22px;
}

.div50 div svg {
    position: absolute;
    top:50%;
    left:0;
    transform: translate(0, -50%);
}

.disabled-retype-password:disabled {
    color: rgba(25, 25, 25, 0.2) !important;
    border: none !important;
    background: rgba(25, 25, 25, 0.1) !important;
    cursor: not-allowed;
}

.register-outer-wrapper {

    .phone-number-form {
        margin: 8px 0 0;
        width: 100%;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: -0.3px;
        color: #191919;
        border-radius: 8px;
        border: 1px solid #1919191A;
        box-sizing: border-box;
        -webkit-box-shadow: 0 0 0 50px white inset;
        box-shadow: 0 0 0 50px white inset;

        .dropdown-menu {
            border: 1px solid rgba(0,0,0,.15);
            transform: translate3d(0px, 50px, 0px) !important;
        }
    
        input[type="text"] {
            width: 100%;
            padding: 12px 16px;
            // border: solid 1px rgba(25, 25, 25, 0.1);
            border-radius: 8px;
            box-shadow: none;
            outline: none;
    
            &.error-input-message {
                border-color: #ff3f57;
            }
    
            &:focus {
                border-color: #ff7d00;
            }
        }
    
        .area-code-wrapper {
            position: relative;
            display: inline-block;
            min-width: 0;
            margin-right: 4px;
    
            .dropdown {

                .value-country-code-wrapper {
                    width: 100%;
                    text-align: left;
                    outline: none !important;
                    border-radius: 8px;
                    background: none;
                    border: none;
                    color: #191919;
                    box-shadow: none !important;
                    position: relative;
                    padding: 11px 0 11px 12px;
                    margin: 0;
                    
                    .country-flag {
                        width: 32px;
                        height: 24px;
                        display: inline-block;
                        position: relative;
                        margin-right: 4px;
                        top: -1px;
                        border-radius: 2px;
                    }
    
                    svg {
                        display: inline-block;
                        transition: ease-in-out .1s;
                        margin-right: 8px;
    
                        &.chevron-up {
                            transform: rotate(180deg);
                        }
                    }

                    p {
                        display: inline-block;
                        margin: 0;
                        transform: translateY(1px);
                    }

                    &.loading {
                        color: #19191999;
                    }
                }
    
                .dropdown-menu {
                    padding: 0;
                    border-radius: 8px;
    
                    .country-search-wrapper {
                        position: relative;
                        width: 100%;
    
                        input {
                            margin: 0;
                            border: none !important;
                            height: 37px;
                            padding: 12px 12px 12px 36px !important;
                            font-size: 14px;
                            letter-spacing: -0.2px;
                            color: #191919;
                            border-bottom: solid 1px #dcdcdc !important;
                            border-radius: 8px 8px 0 0 !important;
                        }
    
                        svg {
                            position: relative;
                            color: #ff7d00;
                            position: absolute;
                            left: 12px;
                            top: 11px;
                        }
                    }
    
                    .country-list-select {
                        width: 320px;
                        // max-height: 170px;
                        min-height: 37px;
                        max-height: 222px;
                        overflow: auto;
    
                        .custom-phone-code-picker {
                            padding: 0 12px;
                            height: 37px;
                            line-height: 37px;
                            border-bottom: solid 1px #dcdcdc;
                            cursor: pointer;  
                            
                            &:nth-last-child(1) {
                                border-bottom: none;
                            }
                
                            img {
                                width: 21px;
                                height: 16px;
                                display: inline-block;
                                margin-right: 6px;
                                position: relative;
                                top: -2px;
                            }
    
                            svg {
                                margin-right: 6px;
                                position: relative;
                                color: #ff7d00;
                                font-size: 18px;
                                top: -1px;
                            }
                
                            div {
                                display: inline-block;
                                font-size: 14px;
                                letter-spacing: -0.2px;
                                line-height: 1;
                                position: relative;
                                vertical-align: top;
                                top: 11px;
                
                                .country-name {
                                    top: 0 !important;
                                }
                            }
    
                            &:hover {
                                color: #ff7d00;
                            }
    
                            &.active-code-number {
                                color: #ff7d00;
                            }
                        }
    
                        &.no-option {
                            text-align: center;
                            border-top: solid 1px #dcdcdc;
                            color: grey;
                            padding-top: 5px;
                        }
                    }
                }
            }
    
            input[type="text"] {
                padding: 12px 16px 12px 52px;
            }
    
            .country-flag-wrapper {
                padding: 8px;
                border: solid 1px rgba(25, 25, 25, 0.1);
                display: inline-block;
                height: 24px;
                width: 32px;
                position: absolute;
                left: 16px;
                top: 50%;
                transform: translate(0, -50%);
    
                svg {
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                    color: rgba(25, 25, 25, 0.4);
                }
            }

        }
    
        
        &.phone-number-disabled {
            input[type="text"] {
                background: rgba(25, 25, 25, 0.1);
                border: none;
                cursor: not-allowed;
            }
        }

        .phone-wrapper {
            width: calc(100% - 150px);
            display: inline-block;
            vertical-align: top;
            position: relative;

            input {
                border: none;
                padding: 12px 16px 12px 0;
            }
    
            p {
                color: #ff3f57;
                font-size: 12px;
                letter-spacing: -0.4px;
                margin: 4px 0 0;
                width: 100%;
                position: relative;
                padding: 0 0 0 20px;
                
                svg {
                    margin-right: 4px;
                    position: absolute;
                    left: 0;
                    top: 1px;
                    font-size: 16px;
                }
            }
    
            input[type="text"] {
                width: 100%;
                display: inline-block;
                margin-top: 0;
            }
    
            svg {
                &.remove-number {
                    position: absolute;
                    right: 0;
                    top: 14px;
                    font-size: 24px;
                    margin-left: 6px;
                    color: #ff3f57;
                    cursor: pointer;
                    
                    &:hover {
                        color: #e83b51;
                    }
                }
            }
        }
    }

    .loading  {
        color: #19191999;
        background: white;
        border-radius: 8px;
        border: none;
        box-sizing: border-box;
        box-shadow: 0 0 0 50px #E8E8E8 inset;
        -webkit-box-shadow: 0 0 0 50px #E8E8E8 inset;
    }
}

@media screen and (max-width: 415px) {
    .password-strength .div50 {
        width: 100%;
    }
}

.modal-creating-account {
    .modal-content {
        border-radius: 32px;
        width: 520px;

        .modal-body {
            padding: 48px;
            text-align: center;
            border-radius: 32px;

            .lds-ring {
                width: 88px;
                height: 88px;

                div {
                    width: 88px;
                    height: 88px;
                    margin: 0;
                    border: solid 9px;
                    border-color: #ff7d00 transparent transparent transparent;
                }
            }

            .modal-title-text {
                font-size: 24px;
                letter-spacing: -0.6px;
                margin: 10px 0;
            }

            .modal-desc-text {
                font-size: 16px;
                letter-spacing: -0.3px;
                margin: 0;
            }
        }
    }
}