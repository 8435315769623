.case-report-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #f3f3f3;
  position: relative;
  left: 50%;
  height: 100vh;
  transform: translateX(-50%);

  .case-logo {
    margin-bottom: 42px;
  }

  .case-report-title-wrapper {
    display: flex;
    margin-bottom: 32px;

    .icon-wrapper {
      width: 48px;
      height: 48px;
      border-radius: 50%;
      background-color: #e7e5ff;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 8px;
    }
  }

  .case-description {
    width: 500px;
    text-align: center;
    font-size: 16px;
    margin-bottom: 32px;
    line-height: 1.5;
    letter-spacing: -0.3px;
  }

  .case-report-input-container {
    width: 500px;
    background-color: #ffffff;
    border-radius: 8px;
    padding: 32px;

    .month-select {
      margin-bottom: 32px;
    }

    .year-select {
      margin-bottom: 32px;
    }
  }
}

.failed-modal {
  .modal-content {
      width: 476px;
  }

  .modal-body {
      padding: 32px;

      .confirm-modal-wrapper {
          position: relative;
          display: flex;
          flex-direction: column;
          align-items: center;

          svg {
            width: 64px;
            height: 64px;
            color: #ffb439;
          }

          .confirm-description {
              font-size: 14px;
              letter-spacing: -0.2px;
              color: #000000;
              line-height: 21px;
              margin: 8px 0;
              text-align: center;
          }
      }
  }
}