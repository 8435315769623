.modal-send-templated-message {
    .modal-content {
        border-radius: 8px;
        width: 476px;
        display: inline-block;
    }

    .modal-body {
        padding: 32px;

        .modal-send-title {
            font-size: 20px;
            font-weight: 700;
        }

        p {
            color: #000000;
            font-size: 14px;
            letter-spacing: -0.2px;
        }
    }

    .modal-send-templated-message-buttons {
        display: flex;

        .no-fill-button {
            width: 120px;
            margin-right: 5px;
        }

        .orange-button {
            width: 308px;
        }
    }
}