.modal-new-interactive-message {

  .form-label {
    label {
      width: 100%;
      position: relative;

      b {
        font-size: 16px;
        letter-spacing: -0.3px;
      }
      
      &.label-header-type {
        b {
          font-size: 14px;
        }
      }

      .grey-font {
        position: absolute;
        top: 1px;
        right: 0;
      }

      .text-optional {
        position: relative;
        top: unset;
        right: unset;
      }
    }

    .form-value {
      font-size: 16px;
      letter-spacing: -0.3px;
      margin-bottom: 0;
      color: rgba(25, 25, 25, 0.8);
    }
  }

  input:disabled {
    background-color: rgba(25, 25, 25, 0.1) !important;
  }

  input::placeholder {
    color: rgba(25, 25, 25, 0.4);
  }

  .disabled {
    label,
    b,
    input {
      color: rgba(25, 25, 25, 0.4);
    }
  }
}

.modal-new-interactive-message {
  position: fixed;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100%;
  background: rgba(25, 25, 25, 0.8);
  visibility: hidden;
  opacity: 0;
  right: 0;
  transition: visibility 0.3s, opacity 0.5s ease-in-out;
  z-index: 4;

  &.active-modal-new-interactive-message {
    /* right: 0; */
    opacity: 1;
    visibility: visible;
    transition: visibility 0s, opacity 0.5s ease-in-out;
  }

  .modal-body {
    position: absolute;
    top: 8px;
    right: -100%;
    left: unset;
    transform: unset;
    height: calc(100% - 8px);
    width: 640px;
    display: inline-block;
    border-radius: 8px 0 0 0;
    background: #ffffff;
    padding: 0;
    max-height: calc(100% - 8px);
    // height: 100%;
    transition: ease-in-out 0.4s;

    &.action-modal-body-new-interactive-message {
      right: 0;
    }

    .close-modal-interactive {
      border-radius: 50%;
      width: 48px;
      height: 48px;
      background: #ffffff;
      color: #757575;
      position: absolute;
      left: -65px;
      top: 0;
      text-align: center;
      cursor: pointer;

      &:hover {
        background: #f9f9f9;
      }

      svg {
        position: relative;
        font-size: 25px;
        top: 11px;
      }
    }

    .new-interactive-message-wrapper {
      height: calc(100% - 72px);
      max-height: calc(100% - 72px);
      overflow: hidden;

      & > div > div {
        overflow-x: hidden !important;
      }

      .comp-back-button {
        margin: 0 0 24px;
      }

      label {
        display: inline-block !important;
      }

      .new-interactive-message-container {
        padding: 32px;

        .new-interactive-message-title {
          color: #191919;

          img {
            width: 24px;
            position: relative;
            top: -7px;
            margin-right: 4px;
          }

          b {
            font-size: 20px;
            letter-spacing: -0.6px;
          }

          svg {
            font-size: 24px;
            margin: 0 4px 0 0;
            position: relative;
            top: -2px;
          }

          p {
            margin: 2px 0 0;
            color: rgba(25, 25, 25, 0.6);
            font-size: 14px;
            letter-spacing: -0.2px;
          }
        }

        label {
          span {
            letter-spacing: -0.2px;
            font-size: 14px;
            color: rgba(25, 25, 25, 0.6);
          }
        }

        textarea {
          width: 100%;
          height: 123px;
        }

        input[type="text"] {
          width: 100%;
        }

        input[type="file"] {
          display: none;
        }
      }

      .button-text-wrapper-form {
        border-radius: 8px;
        border: solid 1px rgba(25, 25, 25, 0.1);
        background: #f3f3f3;
        padding: 12px;
        margin: 8px 0 0;

        &.button-text-wrapper-form-1 {
          margin: 24px 0 0 !important;
        }

        label {
          margin-top: 0;
        }

        .remove-button-text {
          position: relative;
          top: -1px;
          cursor: pointer;
          margin-left: 4px;
          color: rgba(255, 63, 87, 1) !important;
        }
      }

      .add-another-button {
        margin: 16px 0 0;
        font-size: 14px;
        cursor: pointer;
        letter-spacing: -0.5px;
        color: var(--main-dark-orange);

        svg {
          position: relative;
          margin-right: 4px;
          top: -1px;
        }

        &:hover {
          color: var(--main-darker-orange);

          svg {
            color: var(--main-darker-orange);
          }
        }
      }
    }

    .send-message-button {
      width: 100%;
      padding: 12px 24px;
      background: #ffffff;
      font-size: 16px;
      letter-spacing: -0.5px;
      position: absolute;
      bottom: 0;
      left: 0;
      box-shadow: 0 2px 20px 0 rgba(25, 25, 25, 0.18);

      button {
        width: 100%;
        height: 48px;
      }
    }
  }
}
