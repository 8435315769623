.label-dropdown {
    width: 100%;
    // float: right;
    // margin: 6.5px 6px 0;

    &.disabled-label-dropdown {
        button {
            background: rgba(25, 25, 25, 0.1) !important;
            color: rgba(25, 25, 25, 0.4) !important;
        }
    }

    .button-text-wrapper {
        display: inline-block;
        text-align: left;
        width: calc(100% - 32px);
    }

    .label {
        display: inline-block;
        margin: 0;
        padding: 2px 8px;
        font-size: 12px;
        font-family: PTRootBold;
        line-height: 18px;
        letter-spacing: -0.4px;
        border-radius: 4px;
        color: white;
    }

    button {
        width: 100%;
        text-align: left;
        background: #ffffff !important;
        border: solid 1px #dcdcdc !important;
        outline: none !important;
        box-shadow: none !important;
        color: #191919 !important;
        padding: 12px 16px;
        border-radius: 8px;
        position: relative;

        .placeholder {
            display: inline-block;
            margin: 0;
            font-size: 16px;
            line-height: 24px;
            letter-spacing: -0.3px;
            color: #19191966;
        }

        .icon-caret {
            font-size: 24px;
            margin-left: 8px;
            transition: transform 0.2s;
        }

        .icon-caret-open {
            transform: rotate(180deg);
        }
    }

    .dropdown-menu {
        padding: 0;
        border-radius: 8px;
        box-shadow: 0px 2px 20px rgba(25, 25, 25, 0.18);
        border: none !important;

        button {
            border: none !important;
            border-bottom: solid 1px #1919191A !important;
            border-radius: 0;
            padding: 11px 16px;
            position: relative;
            
            &:nth-child(1) {
                border-top-left-radius: 8px;
                border-top-right-radius: 8px;
            }

            &:nth-last-child(1) {
                border-bottom-left-radius: 8px;
                border-bottom-right-radius: 8px;
                border-bottom: 0 !important;
            }

            &:hover {
                background: rgba(255, 242, 229, 1) !important;
            }

            svg {
                position: absolute;
                left: 16px;
                top: 50%;
                transform: translate(0, -50%);
                font-size: 16px;
                color: #ff7e00;
            }
        }
    }
}
