.dropdown-filter-status {
    position: absolute;
    width: 322px;
    left: 356px;
    top: 0;
    box-shadow: none !important;
    outline: none !important;

    button {
        padding: 12px 34px 12px 16px;
        border-radius: 8px;
        border: 1px solid rgba(25, 25, 25, 0.1) !important;
        background: #fff !important;
        color: rgba(25, 25, 25, 1) !important;
        width: 322px;
        text-align: left;
        height: 47px;
        line-height: 18px;
        box-shadow: none !important;
        outline: none !important;
        white-space: nowrap;
        display: block;
        text-overflow: ellipsis;
        overflow: hidden;

        .filter-black {
            width: 24px;
        }

        img {
            position: relative;
            top: -1px;
            margin-right: 8px;
            font-size: 18px;
        }

        svg {
            font-size: 18px;
            position: absolute;
            right: 14px;
            top: 15px;
        }
    }

    &.dropdown-isdisabled {
        button {
            background: rgba(25, 25, 25, 0.1) !important;
            color: rgba(25, 25, 25, 0.4) !important;
        }
    }

    .dropdown-menu {
        width: 322px;
        padding: 0;
        border-radius: 8px;
        overflow: hidden;
        
        .disabled-clock-filter {
            background: rgba(25, 25, 25, 0.1);
        }
        
        .dropdown-all-status {
            border-bottom: solid 1px #dcdcdc;
        }
        
        div {
            cursor: pointer;
            padding: 12px 8px 12px 36px;
            width: 100%;
            display: block;
            font-size: 16px;
            letter-spacing: -0.3px;
            position: relative;

            svg {
                position: absolute;
                font-size: 20px;
                color: var(--main-dark-orange);
                left: 8px;
                top: 14px;
            }

            label {
                margin: 0;
                cursor: pointer;
            }

            &:hover {
                background: rgba(255, 126, 0, 0.05);
            }
        }
    }
}