.detail-segment-main-wrapper {
  .detail-segment-header {
    padding-bottom: 22px;
    margin-bottom: 12px;
    border-bottom: 1px solid rgba(25, 25, 25, 0.1);
    width: 100%;
    position: relative;
    display: block;

    .btn {
      padding: 0;
    }

    .title {
      position: relative;
      width: 100%;
      display: block;

      .orange-button {
        width: 192px;
        position: absolute;
        right: 0;
        top: -6px;
      }

      .red-button {
        width: 192px;
        position: absolute;
        right: 200px;
        top: -6px;
      }
    }
  }

  .segment-name-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    button {
      margin: 24px 0 0;
    }

    b {
      margin-top: 12px;
      position: relative;
      font-size: 14px;
      width: 303px;
    }

    span {
      font-weight: normal;
    }
    
    .segment-name-input {
      width: 300px;
      border: 1px solid rgba(25, 25, 25, 0.1);
      border-radius: 8px;
      height: 48px;
      padding: 12px 16px;
      margin: 8px 0 0;

      &:focus {
        border: 1px solid #FF7E00;
      }
    }
  }
}

.contact-detail-segment-main-wrapper {
  .contact-detail-segment-header {
    padding-bottom: 12px;
    margin-bottom: 12px;
    border-bottom: 1px solid rgba(25, 25, 25, 0.1);
    position: relative;

    .button-download-contact-segment {
      position: absolute;
      right: 286px;
      top: -5px;
    }

    .button-add-into-segment {
      position: absolute;
      right: 0;
      top: -5px;
    }

    .title {
      font-size: 20px;
      letter-spacing: -0.5px;
    }

    .remove-segment-wrapper {
      position: absolute;
      right: 0;
      top: -5px;

      button {
        &.cancel-delete-segment-btn {
          margin-right: 8px;
        }
      }
    }
  }

  .search-box-wrapper {
    margin-bottom: 24px;
  }
}

.table-contact-detail-segment-list {
  // table-layout: fixed;

  .col_full_name, .col_alias {
    width: 220px;
  }

  .col_phone_number {
    width: 200px;
  }

  .col_email {
    width: 280px;
  }

  .col_action, .col_select_list {
    width: 90px;
  }

  thead {
    tr {
      background: transparent;

      .custom-checkbox-stripe [type="checkbox"]:checked + label:after {
        top: 2px;
        left: 0;
      }
  
      td {
        padding: 10px;
        // width: calc((100% - 100px) / 5);
        width: 20%;
        font-size: 14px;
        word-wrap: break-word;
        white-space: normal;

        .label-select-all {
          position: relative;
          top: 6px;
        }
  
        &:nth-child(1) {
          // width: 25%;
          padding-left: 34px !important;
        }
    
        &:nth-child(2) {
          // width: 25%;
        }
    
        &:nth-child(3) {
          // width: 25%;
        }

        &:nth-child(4) {
          // width: 30%;
        }
  
        &:last-child {
          // width: 20%;
          padding-right: 0;
          

          .main-custom-select-option-wrapper {
            display: flex;
            justify-content: flex-end;

            .dropdown {
              width: 80px;
            }
          }

          .custom-select-option-value-wrapper {
            width: 55px !important;
            height: 23px !important;
            padding: 2px 8px 2px 12px !important;
            border-radius: 4px !important;
    
            p {
              font-size: 12px;
              font-weight: bold;
            }
    
            .custom-select-option-value {
              padding: 0;
            }
    
            svg {
              right: 3px !important;
              height: 15px;
              top: 11px !important;
              position: absolute !important;
              margin-right: unset !important;
            }
          }
        }
  
      }
    }
  }

  tbody {
    tr {
      // cursor: pointer;

      &:hover {
        background-color: transparent !important;
      }

      td {
        background: transparent;
        position: relative;
        word-wrap: break-word;
        // vertical-align: top;

        &:nth-child(1) {
          padding-left: 34px !important;
        }

        &.col_full_name {
          p {
            margin-bottom: 0;
            display: inline-block;
            width: 75%;
            vertical-align: top;
          }

          label {
            
          }
        }

        &.col_action {
          padding-right: 0;
          // height: 77px;
          text-align: right;
          white-space: nowrap;

          button {
            border: none !important;

            &.delete-btn {
              width: 100px;
              color: #FF3F57 !important;

              &:hover {
                background: transparent;
                color: #e83b51;
              }

              svg {
                position: relative;
                top: -2px;
                right: 5px;
                color: #FF3F57;
              }
            }
          }
        }
      }
    }
  }
}