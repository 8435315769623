.chat-history-outer-wrapper {
    overflow-y: auto;
    max-height: 100%;
    min-height: 100%;
    position: relative;

    .lds-ring {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 28px;
        height: 28px;

        div {
            margin: 0;
            width: 28px;
            border: solid 3px #fff;
            height: 28px;
            border-color: var(--main-dark-orange) transparent transparent transparent;
        }
    }

    .empty-broadcast-list {
        text-align: center;
        padding: 0 16px;
        position: absolute;
        top: 50%;
        left: 0;
        transform: translate(0, -50%);
        width: 100%;
        
        p {
            &:nth-child(1) {
                color: rgba(25, 25, 25, 1);
                margin: 0 0 8px;
                line-height: 1;
            }
    
            &:nth-last-child(1) {
                color: rgba(25, 25, 25, .6);
                margin: 0;
            }
        }
    }
}

.chat-history-tab-list {
    padding: 8px;
    cursor: pointer;
    border-bottom: solid 1px #dcdcdc;
}

.chat-history-tab-list:hover {
    background: rgba(25, 25, 25, 0.1);
}

.chat-history-omnichannel-icon {
    position: relative;
    top: -2px;
    width: 24px;
}

.chat-history-topic-name {
    width: calc(100% - 24px);
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-left: 4px;
    font-size: 16px;
    letter-spacing: -0.5px;
    vertical-align: top;
    margin: 0;
}

.chat-history-last-message {
  margin: 4px 0 0;
  position: relative;
  top: -1px;
  font-size: 14px;
  letter-spacing: -0.2px;
  color: rgba(25, 25, 25, 0.6);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.chat-history-time-and-status-wrapper {
    width: 100%;
    position: relative;
}

.chat-history-time-wrapper {
    font-size: 12px;
    letter-spacing: -0.4px;
    color: rgba(25, 25, 25, 0.4);
    margin-top: 7px;
}

.chat-history-time-wrapper svg {
    position: relative;
    top: -1px;
    font-size: 12px;
    margin-right: 3px;
}

.chat-history-case-status {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(0, -50%);
    font-size: 12px;
    letter-spacing: -0.4px;
    border-radius: 4px;
    padding: 2px 12px 2px 8px;
    line-height: 18px;
    border-radius: 4px;
    background: #19c700;
    color: #ffffff;
    max-width: 125px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.chat-history-case-status svg {
    position: relative;
    top: -1px;
    margin-right: 4px;
}

.chat-history-case-status.chat-history-other-agent {
    background: #0052ff;
}

.chat-history-case-status.chat-history-solved {
    color: rgba(25, 25, 25, 0.6);
    background: rgba(25, 25, 25, 0.05);
}