.onboarding-wrapper {
    transition: ease-in-out .1s;

    .onboarding-collapse-opener {
        position: relative;
        width: 100%;
        padding: 0 22px 12px 0;
        cursor: pointer;

        .icon-arrow-collapse {
            position: absolute;
            right: 0;
            top: 50%;
            transform: translate(0, -50%);
            transition: ease-in-out .15s;
            font-size: 20px;
        }

        &:hover {
            .icon-arrow-collapse {
                color: #ff7d00;
            }
        }

        &.active-collapse {
            .icon-arrow-collapse {
                transform: translate(0, -50%) rotate(180deg);
            }
        }
    }

    .onboarding-collapse-opener-1 {
        padding: 0 12px 12px 80px;
        // margin-bottom: 24px;

        .onboarding-collapse-opener-1-info {
            font-size: 16px;
            letter-spacing: -0.3px;
            color: rgba(25, 25, 25, .8);
            line-height: 1;
            display: inline-block;
            vertical-align: top;

            b {
                color: #191919;
                font-size: 24px;
                letter-spacing: -0.6px;
            }

            p {
                line-height: 24px;
                margin: 12px 0 0;
            }
        }

        .onboarding-collapse-opener-1-image {
            margin-left: 24px;
        }

        .onboarding-progress-bar {
            width: 56px;
            height: 56px;
            display: inline-block;
            position: absolute;
            left: 0;
            top: 0%;
            vertical-align: top;

            p {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                color: rgba(25, 25, 25, .8);
                font-size: 16px;
                letter-spacing: -0.5px;
            }
        }

        &.active-collapse {
            border-bottom: solid 1px #dcdcdc;
        }
    }

    .CircularProgressbar .CircularProgressbar-trail {
        stroke: #f3f3f3;
    }
    
    .CircularProgressbar .CircularProgressbar-path {
        stroke: #19C700;
    }

    .onboarding-collapse-all {
        .onboarding-collapse-opener {
            padding: 13px 22px 13px 40px;
            border-bottom: solid 1px #dcdcdc;
            line-height: 24px;
            margin-top: 24px;

            &.onboarding-collapse-opener-child {
                font-size: 20px;
                letter-spacing: -0.6px;

                .collapse-number {
                    width: 26px;
                    height: 26px;
                    position: absolute;
                    left: 0;
                    top: 11px;
                    text-align: center;
                    border-radius: 50%;
                    background: #f3f3f3;
                    color: rgba(25, 25, 25, .8);
                    font-size: 14px;
                    display: inline-block;
                    
                    b {
                        position: relative;
                        top: 1px;
                    }

                    img {
                        vertical-align: top;
                    }

                    &.active-collapse-number {
                        background: #ff7d00;
                        color: #ffffff;
                    }
                }
            }
        }
    }

    .onboarding-child-wrapper {
        background: #f3f3f3;
        padding: 24px 32px;

        .alert-component {
            margin-bottom: 24px;
            font-size: 14px;
            letter-spacing: -0.4px;
            line-height: 21px;
            font-size: 14px;
            width: 100%;
            max-width: 500px;
            padding: 12px 24px 12px 48px;

            svg {
                transform: unset;
                top: 10px;
            }
            
            .text-alert {
                color: #044B84 !important;
            }
        }

        .onboarding-child-image {
            display: block;
        }

        .onboarding-description {
            margin: 24px 0;
            max-width: 400px;
            width: 100%;
        }

        .button-wrapper {
            button {
                margin-right: 8px;

                &.mark-as-undone {
                    border: none !important;
                    background: none !important;
                    padding: 0 16px !important;

                    &:hover {
                        color: var(--main-darker-orange) !important;
                    }
                }
            }
        }
    }
}

.onboarding-complete-modal {
    .modal-content {
        width: 477px;
    }
  
    .modal-body {
        padding: 32px;
  
        .onboarding-complete-wrapper {
            display: flex;
            flex-direction: column;
            align-items: center;

            img {
                margin-bottom: 8px;
            }

            .modal-title {
                font-size: 24px;
                line-height: 28px;
                text-align: center;
                letter-spacing: -0.6px;
                margin-bottom: 8px;
            }

            .modal-description {
                font-size: 16px;
                line-height: 24px;
                text-align: center;
                letter-spacing: -0.3px;
                margin-bottom: 16px;
            }

            .btn-complete {
                width: 100%;
            }
        }
    }
  }