.shared-media-main-tab-wrapper-1 {
    display: block;
    width: 100%;

    .no-shared-media {
        width: 100%;
        text-align: center;
        padding: 24px 110px 0;

        p {
            &:nth-child(2) {
                font-size: 24px;
                letter-spacing: -0.6px;
                margin: 0 0 8px 0;
            }

            &:nth-child(3) {
                font-size: 16px;
                letter-spacing: -0.3px;
            }
        }
    }

    .share-media-list-wrapper {
        width: 100%;
        display: block;

        .share-media-date {
            display: block;
            width: 100%;
            background: rgba(25, 25, 25, 0.05);
            color: rgba(25, 25, 25, 0.6);
            letter-spacing: 3px;
            font-size: 14px;
            padding: 8px 16px;
            text-transform: uppercase;
        }

        .share-media-list-inner {
            width: 100%;
            display: block;
            padding: 10px;

            .share-media-card {
                width: 97px;
                height: 100px;
                border-radius: 8px;
                vertical-align: top;
                display: inline-block;
                margin: 2px;
                cursor: pointer;
                position: relative;
                background: rgba(25, 25, 25, 0.1);

                .dropdown-view-in-chat {
                    background: transparent !important;
                    border: none !important;
                    outline: none !important;
                    box-shadow: none !important;
                }

                .dropdown-menu {
                    padding: 0;
                    border-radius: 8px;
                    overflow: hidden;

                    button {
                        border-radius: 0;
                        background: #fff !important;
                        font-size: 16px;
                        letter-spacing: -0.3px;
                        padding: 8px 20px;
                        color: #191919 !important;

                        &:hover {
                            background: rgba(25, 25, 25, 0.1) !important;
                        }

                        img {
                            position: relative;
                            margin-right: 4px;
                        }

                        svg {
                            position: relative;
                            margin-right: 4px;
                            font-size: 24px;
                            color: var(--main-dark-orange);
                        }
                    }
                }

                // &::after {
                //     position: absolute;
                //     content: "";
                //     top: 0;
                //     left: 0;
                //     width: 100%;
                //     height: 100%;
                //     background: rgba(25, 25, 25, 0.2);
                //     border-radius: 8px;
                //     z-index: 1;
                // }

                .triple-dot-wrap {
                    width: 24px;
                    height: 24px;
                    border-radius: 50%;
                    background: #fff;
                    cursor: pointer;
                    position: absolute;
                    top: 4px;
                    right: 4px;
                    z-index: 2;
                    display: none;

                    img {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        width: 12px;
                    }
                }

                .share-media-vid {
                    max-width: 100%;
                    max-height: 100%;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }

                .share-media-img {
                    max-width: 100%;
                    max-height: 100%;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }

                .media-camera-image {
                    position: absolute;
                    bottom: 4px;
                    left: 4px;
                    color: #fff;
                    font-size: 14px;
                    z-index: 2;
                }

                .media-size-text {
                    position: absolute;
                    font-size: 12px;
                    letter-spacing: -0.4px;
                    bottom: 4px;
                    right: 4px;
                    color: #fff;
                    z-index: 2;
                }

                &:hover {
                    .triple-dot-wrap {
                        display: inline-block;
                    }

                    &::before {
                        background: rgba(25, 25, 25, 0.05);
                        width: 100%;
                        height: 100%;
                        position: absolute;
                        top: 0;
                        left: 0;
                        content: "";
                    }
                }
            }
        }
    }
}