.billing-page-wrapper {
    margin: 32px 0 0;

    .nav-item {
        cursor: pointer;
        color: rgba(25, 25, 25, 0.4);
        font-size: 16px;
        letter-spacing: -0.5px;
        padding: 0 0 12px 0;
        margin-right: 32px;

        a {
            padding: 0;
            border: none;
        }

        :hover {
            color: #ff7d00;
        }

        &.active-setup-tab {
            color: #ff7d00;
            border-bottom: solid 4px #ff7d00;
        }
    }

    .tab-content {
        margin: 32px 0 0;
    }
}