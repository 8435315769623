.chat-room-sales-talk-product-top {
    .sales-talk-search-product-wrapper {
        padding: 8px 14px;

        .search-box-wrapper {
            input {
                padding: 5px 60px 5px 16px;
                background: rgba(25, 25, 25, 0.05);
            }

            .search-icon {
                width: 36px;
                height: 36px;

                svg {
                    font-size: 16px;
                }
            }

            .clear-search {
                right: 38px;
            }
        }

    }

    .sales-talk-category-wrapper-outer {
        height: 38px;
        background: rgba(243, 243, 243, 1);
        max-width: 100%;
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        position: relative;
        // border-bottom: solid 1px #f3f3f3;
        text-align: center;
        padding: 0 16px;
        
        &::before {
            position: absolute;
            content: "";
            bottom: 0;
            left: 0;
            width: 100%;
            height: 1px;
            background: #f3f3f3;
        }

        .scroll-left-wrapper {
            color: #ff7d00;
            font-size: 20px;
            width: 25px;
            text-align: center;
            display: inline-block;
            position: absolute;
            left: 0;
            top: 0;
            // background: pink;
            background: linear-gradient(90deg, #FFFFFF 55.97%, rgba(255, 255, 255, 0.8) 67.46%, rgba(255, 255, 255, 0) 95.95%);
            height: 100%;
            cursor: pointer;
            line-height: 32px;

            &:hover {
                color: #e87200;
            }
        }

        .scroll-right-wrapper {
            color: #ff7d00;
            font-size: 20px;
            cursor: pointer;
            text-align: center;
            width: 25px;
            display: inline-block;
            position: absolute;
            right: 0;
            top: 0;
            // background: pink;
            background: linear-gradient(270deg, #FFFFFF 55.97%, rgba(255, 255, 255, 0.8) 67.46%, rgba(255, 255, 255, 0) 95.95%);
            height: 100%;
            line-height: 32px;
            cursor: pointer;

            &:hover {
                color: #e87200;
            }
        }

        .sales-talk-category-wrapper {
            overflow: auto;
            border-bottom: solid 1px #f3f3f3;
            
            .sales-talk-category-selection {
                text-align: center;
                line-height: 38px;
                padding: 0 8px;
                min-width: 105px;
                font-size: 14px;
                letter-spacing: -0.4px;
                display: inline-block;
                cursor: pointer;
    
                &:hover {
                    color: #ff7d00;
                }

                &.sales-talk-category-selection-disabled {
                    color: rgba(25, 25, 25, 0.6) !important;
                }

                &.sales-talk-category-selection-active {
                    color: #ff7d00;
                    background: #ffffff;
                    border-radius: 12px 12px 0 0;

                    &:nth-child(1) {
                        border-radius: 0 12px 0 0;
                    }

                    &:nth-last-child(1) {
                        border-radius: 8px 0 0 0;
                    }
                }
            }
        }
    }

    .sales-talk-pagination-outer {
        padding: 0 16px;

        .sales-talk-pagination {
            position: relative;
            height: 32px;
            width: 100%;
            display: block;
            padding: 7px 0;
            border-bottom: solid 1px #dcdcdc;
    
            .total-products {
                color: rgba(25, 25, 25, 0.4);
                font-size: 12px;
                letter-spacing: -0.4px;

                span {
                    margin-left: 1px;
                }
            }
    
            .paging {
                color: rgba(25, 25, 25, 0.4);
                font-size: 14px;
                letter-spacing: -0.2px;
                position: absolute;
                padding: 0 28px;
                right: 0;
                top: 50%;
                transform: translate(0, -50%);
    
                svg {
                    font-size: 14px;
                    cursor: pointer;
                    position: absolute;
                    top: -1px;
    
                    &:hover {
                        color: #ff7d00;
                    }
    
                    &:nth-child(1) {
                        // margin-right: 20px;
                        top: 4px;
                        left: 0;
                    }
    
                    &:nth-last-child(1) {
                        // margin-left: 20px;
                        right: 0;
                        top: 4px;
                    }
                }
                
                span {
                    color: #ff7d00;
                }
            }   
        }
    }

    .sales-talk-sort-by-wrapper {
        padding: 8px;
        position: relative;

        .view-all-items {
            color: #ff7d00;
            cursor: pointer;
            font-size: 12px;
            letter-spacing: -0.4px;
            position: absolute;
            right: 8px;
            top: 50%;
            transform: translate(-0, -50%);

            &:hover {
                color: #e87200;
            }

            span {
                color: rgba(25, 25, 25, 0.6);
            }
        }
    }
}