.new-case-list-wrapper {
    width: 100%;
    display: block;
    position: relative;
    background: #fff;
    cursor: pointer;

    .click-area-case-list {
        position: absolute;
        height: 100%;
        width: calc(100% - 30px);
        display: inline-block;
        z-index: 1;
    }

    &:hover {
        // background: rgba(25, 25, 25, 0.1);
        background: #dddddd !important;  
    }

    .new-case-list-top {
        width: 100%;
        position: relative;

        .omnichannel-name {
            position: relative;
            padding: 5px 123px 5px 12px;
            font-size: 12px;
            letter-spacing: -0.2px;
            color: rgba(25, 25, 25, 1);
            margin: 0;
            // border-bottom : solid 1px rgba(25, 25, 25, .2);
            width: 100%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            .omnichannel-icon {
                width: 16px;
                height: 16px;
                position: relative;
                margin-right: 4px;
                top: -1px;
            }
        }

        .need-reply-or-junk {
            position: absolute;
            top: 50%;
            transform: translate(0, -50%);
            right: 12px;
            padding-right: 25px;

            .need-reply-or-junk-badge {
                padding: 0px 8px 0 8px;
                position: relative;
                background: rgba(255, 63, 87, 1);
                color: #fff;
                font-size: 10px;
                letter-spacing: 1.5px;
                border-radius: 100px;
                height: 16px;
                line-height: 16px;

                svg {
                    position: relative;
                    margin-right: 4px;
                    top: -1px;
                }
            }

            .custom-checkbox {
                display: inline-block;
                position: absolute;
                top: 50%;
                transform: translate(0, -50%);
                right: -6px;

                label {
                    top: -16px;
                    margin: 0;
                }
            }

            .custom-checkbox-white {
                display: inline-block;
                position: absolute;
                top: 50%;
                transform: translate(0, -50%);
                right: -6px;

                label {
                    top: -16px;
                    margin: 0;
                }
            }

            &.need-reply-or-junk-no-check {
                padding-right: 0;
            }
        }
    }

    .new-case-list-bottom {
        padding: 0 12px 5px 12px;
        border-bottom : solid 1px rgba(25, 25, 25, .2);

        .chat-roomname {
            white-space: nowrap;
            font-size: 16px;
            letter-spacing: -0.5px;
            position: relative;
            width: 100%;
            padding-right: 68px;
            overflow: hidden;
            text-overflow: ellipsis;
            margin: 0 0 7px 0;
            line-height: 1;
            height: 20px;

            .chat-timestamp {
                color: rgba(25, 25, 25, 0.4);
                font-size: 12px;
                position: absolute;
                right: 0;
                top: 50%;
                transform: translate(0, -50%);
                letter-spacing: -0.2px;
            }
        }

        .chat-case-id {
            color: rgba(25, 25, 25, 0.8);
            font-size: 12px;
            letter-spacing: -0.2px;
            line-height: 1;
            // margin: 0 0 8px 0;
            margin: 0;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 100%;
            white-space: nowrap;
            height: 13px;

            b {
                color: rgba(25, 25, 25, 0.6);
                letter-spacing: -0.2px;
                font-size: 12px;
                font-family: PTRootUI !important;
            }
        }

        .typing-room-list {
            font-size: 14px;
            letter-spacing: -0.2px;
            color: #ff7d00;
            display: none;
            margin: 8px 0 0 0;
            position: relative;
            line-height: 18px;

            .typing-name {
                color: rgba(25, 25, 25, 0.6);
            }

            .unread-count-wrapper {
                background: #ff7d00;
                color: #ffffff;
                font-size: 12px;
                border-radius: 20px;
                height: 18px;
                min-width: 18px;
                text-align: center;
                line-height: 18px;
                padding: 0 5px;
                font-weight: bold;
                display: inline-block;
                position: absolute;
                right: 0;
                top: 1px;
            }
        }

        .chat-last-message {
            // overflow: hidden;
            // text-overflow: ellipsis;
            // white-space: nowrap;
            width: 100%;
            font-size: 14px;
            letter-spacing: -0.2px;
            color: rgba(25, 25, 25, 1);
            padding: 0 32px 0 0;
            position: relative;
            margin: 8px 0 0 0;
            line-height: 18px;

            p {
                margin: 0;
                width: 100%;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
            }

            .chat-last-message-room {
                color: rgba(25, 25, 25, 0.6);
            }

            .message-status {
                position: absolute;
                right: 0;
                top: 50%;
                transform: translate(0, -50%);

                .unread-count-wrapper {
                    background: #ff7d00;
                    color: #ffffff;
                    font-size: 12px;
                    border-radius: 20px;
                    height: 18px;
                    min-width: 18px;
                    text-align: center;
                    line-height: 18px;
                    padding: 0 5px;
                    font-weight: bold;
                }

                img {
                    width: 20px;
                }
            }
        }

        .label-list-wrapper {
            width: 100%;
            display: block;
            height: 21px;
            margin-top: 6px;
        }

        .agent-list-wrapper {
            width: 100%;
            display: block;
            height: 21px;
            margin-top: 6px;
        }

        .case-status-wrapper {
            height: 21px;
            margin-top: 6px;
            position: relative;

            .agent-badge-wrapper {
                display: inline-block !important;
                max-width: calc(100% - 90px);

                .my-agent-badge {
                    max-width: 100% !important;
                }

                .other-agent-badge {
                    max-width: 100% !important;
                }
            }
            
            .agent-badge-new {
                font-size: 12px;
                line-height: 1.5;
                letter-spacing: -0.4px;
                color: #ffffff;
                border-radius: 4px;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                display: inline-block;
                padding: 1px 12px 2px;
                font-weight: bold;
                width: 100%;
                // max-width: calc(100% - 12px);
              }
              
              .agent-badge-new-wrapper {
                width: 86px;
                text-align: center;
                display: inline-block;
                height: 21px;
                margin-right: 4px;
              }

              .agent-badge {
                max-width: calc(100% - 90px);
                // white-space: nowrap;
              }
              
              .agent-badge-new img {
                position: relative;
                top: -1px;
                margin-right: 4px;
              }
              
              .agent-badge-new svg {
                position: relative;
                top: -1px;
                margin-right: 4px;
              }
              
              .other-agent-badge-new {
                background: #0052ff;
              }
              
              .my-agent-badge-new {
                background: #19c700;
              }
              
              .unassigned-agent-badge-new {
                // background: #ff3f57;
                background: #C3C8D0;
                color: #ffffff;
              }
              
              .resolved-agent-badge-new {
                color: rgba(25, 25, 25, 0.6);
                background: rgba(243, 243, 243, 1);
              }
        }
    }

    &.new-case-list-wrapper-reply {
        background: #FFF2E5;

        .omnichannel-name {
            // border-bottom: solid 1px rgba(255, 236, 238, 1);
        }
    }

    &.new-case-list-wrapper-active {
        background: #ff7d00 !important;

        .need-reply-or-junk-badge {
            background: #fff !important;
            color: #ff3f57 !important;
        }

        .omnichannel-name {
            color: #fff;
            // border-bottom: solid 1px #fff;
        }

        .new-case-list-bottom {
            border-bottom: solid 1px #ff7d00;
        }

        .more-label {
            color: #fff !important;
        }

        .chat-roomname {
            color: #fff;

            .chat-timestamp {
                color: #fff;
            }
        }

        .chat-case-id {
            color: #fff;

            b {
                color: #fff;
            }
        }

        .chat-last-message {
            color: #fff;

            span {
                color: #fff !important;
            }
        }

        .typing-room-list {
            color: #fff !important;

            .typing-name {
                color: #fff !important;
            }

            .typing-dot-one {
                color: #fff !important;
            }

            .typing-dot-two {
                color: #fff !important;
            }

            .typing-dot-three {
                color: #fff !important;
            }
        }

        .unread-count-wrapper {
            background: #ffffff !important;
            color: var(--main-dark-orange) !important;
        }
    }
    
    &.active-typing {
        .typing-room-list {
            display: block;
        }

        .chat-last-message {
            display: none;
        }
    }
}