.overview-daily-report-wrapper {
    min-height: 500px;

    .total-cases-per-hour {
        margin-top: 32px;

        .overview-daily-box-title {
            padding: 0 0 12px 0;
            border-bottom: solid 1px #1919191A;
        }
    }

    .real-time-status-title {
        margin: 0 0 24px 0;
    }

    .overview-daily-report-title {
        font-size: 20px;
        letter-spacing: -0.6px;
        margin: 0;
        color: #191919;
        padding: 8px 0 16px 0;
        position: relative;
        border-bottom: solid 1px #1919191A;

        .advanced-date-selection {
            position: absolute;
            right: 0;
            top: 0;
        }
    }

    .daily-overview-date-wrapper-top {
        position: relative;
        display: block;
        width: 100%;
        padding: 16px 0 0;

        .date-wrapper {
            height: 48px;
            border: solid 1px #1919191A;
            border-radius: 8px;
            color: #191919;
            font-size: 16px;
            letter-spacing: -0.3px;
            display: inline-block;
            padding: 12px 54px 12px 16px;
            position: relative;
            cursor: pointer;

            svg {
                position: absolute;
                right: 16px;
                top: 50%;
                transform: translate(0, -50%);
                font-size: 18px;
            }
        }

        .toggle-auto-refresh {
            display: inline-block;
            position: absolute;
            top: 28px;
            right: 0;

            .custom-switch-wrapper {
                display: inline-block !important;
                width: 26px;
                height: 14px;
                position: relative;
                top: 5px;
                margin: 0;
                margin-right: 4px;
                font-size: 16px;
                letter-spacing: -0.5px;

                .custom-switch {
                    padding: 0;

                    &.round {
                        &::before {
                            width: 8px;
                            height: 8px;
                            bottom: 3px;
                            left: 4px;
                        }
                    }
                }

                input:checked + .custom-switch:before {
                    transform: translateX(10px);
                }
            }

            svg {
                color: rgba(25, 25, 25, 0.8);
                outline: none !important;
                font-size: 12px;
                position: relative;
                top: -1px;
                margin-left: 2px;
            }
        }
    }

    .daily-overview-top-right {
        position: absolute;
        top: 3px;
        right: 0;

        span {
            font-size: 14px;
            letter-spacing: -0.2px;
            color: rgba(25, 25, 25, 0.4);
            margin-right: 8px;
            position: relative;
            top: 4px;
        }

        button {
            width: 181px;
        }
    }

    .real-time-status-wrapper {
        width: 100%;
        position: relative;
        display: block;
        // border-bottom: solid 1px #1919191A;
        // padding: 0 0 32px 0;
        // margin: 0 0 32px 0;

        &.real-time-status-wrapper-bottom {
            border: none !important;
            padding: 0;
            margin: 0 0 28px 0;
        }

        &.real-time-status-wrapper-not-today {
            border: none !important;
            margin: 16px 0 0 0;
            padding: 0;
        }

        .status-value-wrapper {
            padding: 0 0 0 24px;

            p {
                position: relative;
                width: 100%;
                border-bottom: solid 1px #1919191A;
                padding: 0 0 8px 24px;
                color: #191919;
                font-size: 14px;
                letter-spacing: -0.4px;
                font-family: "PTRootBold";

                .activity-circle {
                    width: 12px;
                    top: 4px;
                    height: 12px;
                    position: absolute;
                    border-radius: 50%;
                    left: 0;

                    &.offline {
                        background: #FF3F57;
                    }

                    &.online {
                        background: #19C700;
                    }

                    &.online-not {
                        background: #118300;
                    }

                    &.away {
                        background: #F4C12A;
                    }
                }

                .value-icon {
                    top: 1px;
                    position: absolute;
                    left: 0;
                }

                span {
                    position: absolute;
                    right: 0;
                }
            }
        }
    }

    .real-time-status-left-wrapper {
        width: calc(50% - 16px);
        position: relative;
        display: inline-block;
        vertical-align: top;
        margin: 0 16px 0 0;
    }

    .real-time-status-right-wrapper {
        width: calc(50% - 16px);
        position: relative;
        display: inline-block;
        vertical-align: top;
        margin: 0 0 0 16px;

        &.real-time-status-wrapper-60 {
            width: 65%;
        }
    }

    .value-title-icon {
        margin-right: 8px;
        position: relative;
        top: -2px;
    }

    .overview-daily-report-description {
        margin: 8px 0 16px;
        font-size: 16px;
        letter-spacing: -0.3px;
        width: calc(100% - 230px);
        color: #191919;
        line-height: 24px;
    }

    .add-overview-daily-report-button {
        // height: 40px;
        // line-height: 23px;
        // padding: 0;
        // width: 195px;
        text-align: center;
        position: absolute;
        top: 32px;
        right: 32px;

        // svg {
        //     vertical-align: top;
        //     font-size: 24px;
        //     position: relative;
        //     top: -1px;
        //     margin-right: 4px;
        // }
    }

    // .overview-daily-report-data-outer-wrapper {
    //     // border-top: solid 1px #1919191A;
    //     padding: 32px 0 0;
    // } 

    .overview-daily-report-data-wrapper {
        width: 100%;
        color: #191919;

        .no-overview-daily-report {
            width: 100%;
            text-align: center;
            font-size: 20px;
            letter-spacing: -0.6px;
            color: rgba(25, 25, 25, 0.6);
        }

        .overview-daily-box-wrapper {
            position: relative;
            margin-top: 32px;
            
            .overview-daily-box-title {
                font-size: 20px;
                letter-spacing: -.6px;
                color: #191919;
                padding: 0 0 12px;
                border-bottom: solid 1px #1919191A;
                margin: 0 0 24px;
            }

            .overview-daily-box-title-2 {
                margin: 40px 0 0 0;
                padding: 0 0 0 16px;
                border: none !important;
            }

            .case-report-box {
                border-radius: 8px;
                box-shadow: 0px 2px 20px rgba(25, 25, 25, 0.18);
                margin: 0 24px 24px 0;
                width: calc((100% / 3) - 16px);
                display: inline-block;
                padding: 18px 8px;
                vertical-align: top;

                &:nth-child(3n) {
                    margin: 0 0 24px 0;
                }

                img {

                }

                .case-report-value {
                    font-size: 24px;
                    letter-spacing: -0.6px;
                    margin: 8px 0;
                    line-height: 1;
                }

                .case-report-title {
                    letter-spacing: -0.4px;
                    font-size: 14px;
                    color: rgba(25, 25, 25, 0.6);
                    margin: 0;
                    line-height: 1;
                    position: relative;

                    svg {
                        position: relative;
                        margin-left: 4px;
                        top: -2px;
                        font-size: 12px;
                        outline: none !important;
                        cursor: pointer;
                    }
                }
            }

            .overview-daily-case-rating-left {
                vertical-align: top;
                width: 60%;
                height: 351px;
                display: inline-block;
                position: relative;
                padding: 0 60px 0 60px;

                .total-rating-is-text {
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                }

                .total-rating-is {
                    background: #ffffff;
                    border-radius: 50%;
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                    width: 170px;
                    height: 170px;
                    text-align: center;
                }
            }

            .overview-daily-case-rating-right {
                vertical-align: top;
                width: 40%;
                display: inline-block;

                .table-overview {
                    width: 100%;

                    thead {
                        tr {
                            td {
                                font-size: 16px;
                                letter-spacing: -0.5px;
                                color: #191919;
                                padding: 0 0 16px 0;

                                &:nth-child(2) {
                                    text-align: right;
                                }
                            }
                        }
                    }

                    tbody {
                        tr {
                            td {
                                font-size: 14px;
                                letter-spacing: -0.4px;
                                border-bottom: solid 1px #1919191A;
                                padding: 8px 0 8px 14px;
                                position: relative;

                                div {
                                    width: 6px;
                                    height: 6px;
                                    border-radius: 50%;
                                    position: absolute;
                                    left: 0;
                                    top: 50%;
                                    transform: translate(0, -50%);
                                }

                                &.stars-8 {
                                    div {
                                        background: #1919191A;
                                    }
                                }

                                &.stars-7 {
                                    div {
                                        background: #FF3F57;
                                    }
                                }

                                &.stars-6 {
                                    div {
                                        background: #007A8A;
                                    }
                                }

                                &.stars-5 {
                                    div {
                                        background: #51E683;
                                    }
                                }

                                &.stars-4 {
                                    div {
                                        background: #3BAEff;
                                    }
                                }

                                &.stars-3 {
                                    div {
                                        background: #9C2EEF;
                                    }
                                }
                                
                                &.stars-2 {
                                    div {
                                        background: #F4C12A;
                                    }
                                }

                                &.stars-1 {
                                    div {
                                        background: #f99080;
                                    }
                                }

                                &:nth-child(2) {
                                    text-align: right;
                                }
                            }
                        }
                    }
                }

                .overview-daily-case-rating-average {
                    padding: 16px 8px;
                    border-radius: 8px;
                    width: 100%;
                    position: relative;
                    display: block;
                    box-shadow: 0px 2px 20px rgba(25, 25, 25, 0.18);
                    margin-top: 24px;

                    .rating-value {
                        font-size: 24px;
                        letter-spacing: -0.6px;
                    }

                    svg {
                        color: #F2BF2A;
                        font-size: 22px;
                        margin-right: 4px;
                        top: -5px;
                        position: relative;
                    }

                    p {
                        margin: 8px 0 0;
                        color: rgba(25, 25, 25, 0.6);
                        font-size: 14px;
                        letter-spacing: -0.4px;
                    }
                }
            }

            .overview-daily-agent-report-wrapper {
                width: 100%;
                position: relative;
                display: block;

                .overview-daily-agent-report-box {
                    text-align: center;
                    width: 25%;
                    padding: 0 16px;
                    display: inline-block;
                    vertical-align: top;
                }

                .agent-report-icon-wrapper {
                    width: 48px;
                    height: 48px;
                    position: relative;
                    display: inline-block;
                    border-radius: 50%;
                    background: #F99080;
                    box-shadow: 0px 2px 20px rgba(25, 25, 25, 0.18);

                    svg {
                        color: #ffffff;
                        font-size: 20px;
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                    }
                }

                p {
                    &.agent-report-text-1 {
                        font-size: 24px;
                        letter-spacing: -0.6px;
                        margin: 8px 0;

                        .rating-text {
                            svg {
                                position: relative;
                                top: -3px;
                                font-size: 20px;
                                color: #F4C12A;
                                margin-right: 4px;
                            }
                        }
                    }

                    &.agent-report-text-2 {
                        font-size: 12px;
                        letter-spacing: -0.4px;
                        margin: 0 0 2px;
                    }   

                    &.agent-report-text-3 {
                        font-size: 12px;
                        letter-spacing: -0.2px;
                        color: rgba(25, 25, 25, 0.8);
                        margin: 0;
                    }
                }
            }

            .chart-legend-wrapper {
                position: absolute;
                top: 4px;
                right: 0px;

                .chart-legend-list {
                    display: inline-block;
                    
                    .chart-legend {
                        display: inline-block;
                        vertical-align: middle;
                        margin:0 8px 0 16px;
                        width: 8px;
                        height: 8px;
                        border-radius: 4px;
                    }

                    .chart-legend-tooltip-icon {
                        display: inline-block;
                        vertical-align: middle;
                        margin-left: 2px;
                        width: 12px;
                        height: 12px;
                        color: #19191966;
                        cursor: pointer;
                    }
    
                    .created {
                        background: #3BAEFF;
                    }
    
                    .unassigned {
                        background: #F99080;
                    }
    
                    .resolved {
                        background: #191919;
                    }
    
                    .channel-unassigned {
                        background: #F99080;
                    }
    
                    .assigned-to-agent {
                        background: #51E683;
                    }
    
                    .assigned-to-bot {
                        background: #9C88F1;
                    }
    
                    p {
                        display: inline-block;
                        vertical-align: middle;
                        margin: 0px;
                        font-size: 12px;
                        line-height: 16px;
                        letter-spacing: -0.2px;
                        color: #191919;
                    }
                }
            }

            .overview-topic-report-wrapper .overview-topic-report-inner-wrapper {

                padding-left: 28px;

                .channel-traffic-chart-wrapper {
                    width: 100%;
                    overflow-x: auto;
                }

                .chart-y-label {
                    position: absolute;
                    left: -16px;
                    top: 50%;
                    transform: translate(0, -50%);
                    transform: rotate(-90deg);
                    font-size: 12px;
                    line-height: 16px;
                    letter-spacing: -0.2px;
                    color: #19191966;
                }

                .chart-x-label {
                    width: 100%;
                    text-align: center;
                    font-size: 12px;
                    line-height: 16px;
                    letter-spacing: -0.2px;
                    color: #19191966;
                }
            }
        }

        .section-separator {
            border-bottom: solid 1px #1919191A;
            margin: 32px 0;
        }
    }
}

#channel-traffic-chart .apexcharts-tooltip {
    padding: 8px;
    font-family: "PTRootUI";
    font-size: 12px;
    letter-spacing: -0.4px;
    color: white;
    background: #6B6B6B !important;
    // border: none !important;

    img {
        margin: 0 4px 4px 0;
        width: 16px;
        height: 16px;
        border-radius: 8px;
    }

    b {
        margin-bottom: 4px;
        transform: translateY(-1px);
    }

    p {
        margin: 0;
    }
}

.tooltip-daily-report {
    .tooltip-inner {
        font-family: "PTRootUI" !important;
        font-weight: 400 !important;
        font-size: 12px !important;
        line-height: 16px !important;
        letter-spacing: -0.2px !important;
        color: #FFFFFF !important;
    }
}

.tooltip-chart-legend {
  
    .tooltip-inner {
        text-align: center !important;
        font-family: "PTRootUI" !important;
        font-weight: 400 !important;
        font-size: 12px !important;
        line-height: 16px !important;
        letter-spacing: -0.2px !important;
        color: #FFFFFF !important;
    }
}
