.integration-miitel-detail-account {
  padding: 32px 30px;
  text-align: left;

  .comp-back-button {
    margin: 0 0 16px 0;
    font-family: PTRootBold;
  }

  .integration-detail-loading-wrapper {
    // .lds-ring {
    //   width: 50px;
    //   height: 50px;
    //   position: absolute;
    //   left: 50%;
    //   top: 50%;
    //   transform: translate(-50%, -50%);
    //   right: unset;

    //   div {
    //     border: 5px solid #fff;
    //     border-color: #ff7d00 transparent transparent transparent;
    //     width: 50px;
    //     height: 50px;
    //     margin: 0;
    //   }
    // }
  }

  .integration-miitel-detail-account-box-1 {
    background: #ffffff;
    border-radius: 8px;
    padding: 22px 32px 32px;
    border: solid 1px rgba(25, 25, 25, 0.1);
    position: relative;

    .integration-miitel-detail-account-box-1-top {
      padding: 10px 0 15px;
      border-bottom: solid 1px #dcdcdc;
      position: relative;
      font-size: 20px;
      letter-spacing: -0.6px;
      padding-right: 230px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      input[type="text"] {
        width: 400px;
      }

      .integration-miitel-detail-account-box-1-top-button {
        position: absolute;
        top: 0px;
        right: 0;
        display: inline-block;

        button {
          padding: 7px 24px;
          font-size: 16px;
          letter-spacing: -0.6px;
          height: 40px;
          margin-left: 8px;
          width: 180px;

          &.button-channel-detail {
            position: relative;
            top: 15px;
          }

          img {
            position: relative;
            top: -2px;
            margin-right: 4px;
          }

          svg {
            position: relative;
            top: -2px;
            margin-right: 4px;
          }

          .lds-ring {
            top: unset;
            right: unset;
            position: relative;
            top: 3px;

            div {
              margin: 0;
              border-color: #ffffff transparent transparent transparent;
            }
          }
        }
      }
    }
  }
}
