.idle-case-container {
    .topContent {
        position: relative;
        padding: 0 192px 12px 0;
        display: block;
        border-bottom: solid 1px #dcdcdc;
        margin: 0 0 24px 0;

        .title {
            width: 100%;
            font-size: 20px;
            letter-spacing: -0.6px;
        }

        .sub-title {
            width: 100%;
            font-size: 14px;
            letter-spacing: -0.2px;
            color: rgba(25, 25, 25, 0.8);
            margin: 4px 0 0 0;
            line-height: 21px;
        }

        .button-edit-idle {
            position: absolute;
            right: 0;
            bottom: 18px;
            width: 180px;
        }
    }

    .idle-case-value-wrapper {
        .idle-case-value-label {
            font-size: 16px;
            letter-spacing: -0.5px;
        }

        .idle-case-value {
            font-size: 16px;
            letter-spacing: -0.3px;
            margin: 0;

            img {
                position: relative;
                top: -1px;
                margin-right: 2px;
            }
        }

        .idle-case-value-2 {
            font-size: 14px;
            letter-spacing: -0.2px;
            margin: 4px 0 0;
        }
    }
}