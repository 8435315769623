.new-interactiveList-row-message-wrapper {
  right: -700px;
  position: relative;
  transition: ease-in-out .5s;
  height: calc(100% - 72px);
  max-height: calc(100% - 72px);
  // padding: 32px;
  display: none;
  // padding: 24px 0 0;
  overflow-y: auto;

  & > div > div {
    // overflow-x: hidden !important;
  }

  .new-interactiveList-message-container {
    padding: 0 32px 32px;
  }

  &.new-interactiveList-row-message-wrapper-active {
    right: 0;
    display: block;
  }

  .comp-back-button {
    margin: 0;
    padding: 32px 32px 0 32px;
  }

  .new-interactiveList-message-title {
    margin: 32px 0 0;

    svg {
      position: relative;
      top: 6px;
      vertical-align: top;
      margin-right: 4px;
      font-size: 19px;
    }
    
    b {
      font-size: 20px;
      letter-spacing: -0.2px;

    }

    p {
      margin: 4px 0 12px;
      font-size: 14px;
      letter-spacing: -0.6px;
      color: rgba(25, 25, 25, 0.6);
    }
  }

  .button-text-wrapper-form {
    border-radius: 8px;
    border: solid 1px rgba(25, 25, 25, 0.1);
    background: #f3f3f3;
    padding: 12px;
    margin: 8px 0 0;
    font-size: 16px;
    letter-spacing: -0.5px;
    width: calc(100% - 6px);

    input[type="text"] {
      background: white;
      border-radius: 8px;
      border: 1px solid rgba(25, 25, 25, 0.1);
      height: 48px;
      color: #191919;
      font-size: 16px;
      letter-spacing: -0.3px;
      width: 316px;
      max-width: 100%;
      padding: 0 16px;
      width: 100%;
    }

    label {
      width: 100%;
      position: relative;

      .grey-font {
        position: absolute;
        right: 0;
        top: 0;
      }

      &.row-description {
        height: 24px;
        margin: 8px 0 0;
      }
    }

    .manage-rows {
      margin: 18px 0 0;
      font-size: 14px;
      letter-spacing: -0.5px;
      color: var(--main-dark-orange);
      cursor: pointer;

      svg {
        position: relative;
        top: -1px;
        margin-right: 4px;
      }

      &:hover {
        color: var(--main-darker-orange);
      }
    }

    &.button-text-wrapper-form-1 {
      margin: 24px 0 0 !important;
    }

    label {
      margin-top: 0;
    }

    .remove-button-text {
      position: relative;
      top: -1px;
      margin-left: 4px;
      cursor: pointer;
      color: rgba(255, 63, 87, 1) !important;
    }
  }

  .add-another-button {
    margin: 16px 0 0;
    font-size: 14px;
    cursor: pointer;
    letter-spacing: -0.5px;
    color: var(--main-dark-orange);

    svg {
      position: relative;
      margin-right: 4px;
      top: -1px;
    }

    &:hover {
      color: var(--main-darker-orange);

      svg {
        color: var(--main-darker-orange);
      }
    }
  }
}