.popup-password .modal-content {
    width: 552px;
    padding: 0 40px;
}

.popup-password .modal-body {
    padding: 40px 0;
}

.popup-password p {
    margin: 0;
}

.popup-password p svg {
    color: #ff7d00;
    position: relative;
    top: -2px;
    font-size: 16px;
}

.popup-password .modal-body label {
    font-size: 16px;
    font-weight: bold;
    letter-spacing: -0.4px;
    color: #191919;
}

.popup-password p:nth-child(1) {
    font-size: 24px;
    font-weight: bold;
    letter-spacing: -0.4px;
    color: #191919;
}

.popup-password p:nth-child(2) {
    font-size: 13px;
    letter-spacing: -0.2px;
    color: #9b9b9b;
    margin-bottom: 30px;
}

.popup-password .modal-body .popup-input-password-wrapper {
    position: relative;
    /* border: solid 1px #dcdcdc; */
    border-radius: 8px;
    width: 100%;
    margin-bottom: 30px;
}

.popup-password .modal-body .popup-input-password-wrapper input {
    padding: 12px 45px 14px 15px;
    /* width: calc(100% - 50px); */
    width: 100%;
    border: solid 1px #dcdcdc;
    display: inline-block;
    border-radius: 8px;
    letter-spacing: -0.2px;
    font-size: 16px;
    color: #191919;
}

.popup-password .modal-body .popup-input-password-wrapper input:focus {
   border: solid 1px #ff7d00;
}

.popup-password .modal-body .popup-input-password-wrapper .peek-password {
    position: absolute;
    width: 50px;
    color: #191919;
    text-align: center;
    top: 5px;
    right: 0;
    height: 43px;
    line-height: 38px;
    font-size: 20px;
    /* border-left: solid 1px #dcdcdc; */
    cursor: pointer;
}

.popup-password .modal-body .popup-input-password-wrapper input:focus + .peek-password {
    color: #ff7d00;
}

.popup-password .modal-footer {
    padding: 24px 0 34px;
    border-top: solid 1px #dcdcdc;
}

.popup-password .modal-body button, .popup-password .modal-body .button {
    width: calc(50% - 8px);
    margin-left: 16px;
    text-align: center;
    cursor: pointer;
    display: inline-block;
    vertical-align: top;
}

.popup-password .modal-body .no-fill-button {
    margin-left: 0;
}

.button-submit-change-password .lds-ring {
    top: 3px;
    right: unset;
}

.button-submit-change-password .lds-ring div {
    border-color: #ffffff transparent transparent transparent;
    margin: 0;
}