.disabled-enabled-wrapper {
    width: 100%;
    display: block;

    p {
        font-size: 16px;
        letter-spacing: -0.5px;
        margin: 0;

        &.disabled-text {
            color: rgba(255, 63, 87, 1);
        }

        &.enabled-text {
            color: #19c700;
        }

        img {
            margin-right: 4px;
            position: relative;
            top: -2px;
        }
    }
}