.modal-message-history-filter {
    width: 484px !important;

    .absolute-right-0 {
        font-weight: normal;
    }
    
    .modal-content {
        position: relative !important;
        padding: 32px;
        max-height: 85vh;
        border-radius: 8px;
        overflow-y: scroll;
        -ms-overflow-style: none;
        scrollbar-width: none;

        &::-webkit-scrollbar { 
            display: none;
        }

        .modal-body {
            padding: 0 !important;

            .modal-title {
                .icon-filter {
                    display: inline-block;
                    vertical-align: middle;
                    width: 24px;
                    height: 24px;
                    margin-right: 4px;
                }
        
                h3 {
                    display: inline-block;
                    vertical-align: middle;
                    margin: 0;
                    font-size: 20px;
                    line-height: 24px;
                    letter-spacing: -0.6px;
                    color: #191919;
                }
            }
        
            h5 {
                display: block;
                margin: 24px 0 8px;
                font-family: PTRootBold;
                font-size: 14px;
                line-height: 21px;
                letter-spacing: -0.4px;
            }
        
            .phone-input {
                width: 100%;
                padding: 12px 16px 12px 48px;
                background: white;
                border: 1px solid #1919191A;
                border-radius: 8px;
                font-family: PTRootUI;
                font-size: 16px;
                line-height: 24px;
                letter-spacing: -0.3px;
                color: #191919;

                input {
                    width: 100%;
                    border: none;
                }
                
                svg {
                    position: absolute;
                    left: 20px;
                    font-size: 16px;
                    transform: translateY(5px);
                }
            }
        
            .filter-item-wrapper {
                display: inline-block;
                vertical-align: middle;
                padding: 10px;
                margin-left: 12px;
                background: white;
                border: 1px solid #1919191A;
                border-radius: 8px;
                cursor: pointer;

                &:nth-child(1) {
                    margin-left: 0 !important;
                }
        
                .checkbox {
                    display: inline-block;
                    width: 24px;
                    height: 24px;
                    background: none;
                    border: 2px solid #DCDCDC;
                    border-radius: 4px;
                    object-position: -99999px 99999px;
                }
        
                .icon-message-type {
                    display: inline-block;
                    width: 28px;
                    height: 28px;
                    padding: 6px;
                    margin: 0 0 0 6px;
                    border-radius: 28px;
                }
        
                .text-message-type {
                    display: inline-block;
                    margin: 0 0 0 6px;
                    font-size: 14px;
                    line-height: 21px;
                    letter-spacing: -0.2px;
                    color: #191919;
                }
        
                .icon-channel-type {
                    display: inline-block;
                    width: 16px;
                    height: 16px;
                    margin: 0 0 0 6px;
                }
        
                .text-channel-type {
                    display: inline-block;
                    margin: 0 0 0 4px;
                    font-family: PTRootBold;
                    font-size: 16px;
                    font-weight: 700;
                    line-height: 20px;
                    letter-spacing: -0.5px;
                    color: #19191966;
                }
            }
        
            .filter-item-wrapper-active {
                display: inline-block;
                vertical-align: middle;
                padding: 10px;
                margin-left: 12px;
                background: #FFF2E5;
                border: 1px solid #FF7E00;
                border-radius: 8px;
                cursor: pointer;

                &:nth-child(1) {
                    margin-left: 0 !important;
                }
        
                .checkbox {
                    display: inline-block;
                    width: 24px;
                    height: 24px;
                    background: #FF7E00;
                    border: none;
                    border-radius: 4px;
                    object-position: 0px 0px;
                }
        
                .icon-message-type {
                    display: inline-block;
                    width: 28px;
                    height: 28px;
                    padding: 6px;
                    margin: 0 0 0 6px;
                    border-radius: 28px;
                }
        
                .text-message-type {
                    display: inline-block;
                    margin: 0 0 0 6px;
                    font-size: 14px;
                    line-height: 21px;
                    letter-spacing: -0.2px;
                    color: #191919;
                }
        
                .icon-channel-type {
                    display: inline-block;
                    width: 16px;
                    height: 16px;
                    margin: 0 0 0 6px;
                }
        
                .text-channel-type {
                    display: inline-block;
                    margin: 0 0 0 4px;
                    font-family: PTRootBold;
                    font-size: 16px;
                    font-weight: 700;
                    line-height: 20px;
                    letter-spacing: -0.5px;
                    color: #FF7E00;
                }
            }
        
            .message-status-dropdown {
                width: 100%;
                padding: 12px 16px;
                background: white;
                border: 1px solid #1919191A;
                border-radius: 8px;
                cursor: pointer;
        
                .message-status-label {
                    padding: 2px 8px;
                    border-radius: 8px;
                    font-size: 12px;
                    line-height: 18px;
                    letter-spacing: -0.4px;
                    color: white;
                }
            }
        }

        .modal-footer {
            border: none;
            padding: 0 !important;
            margin: 20px 0 0;

            button {
                display: inline-block;
                vertical-align: middle;
                padding: 8px 24px;
                margin: 0 4px 0 0;
                min-width: 134px;
                height: 40px;
                font-size: 16px;
                line-height: 20px;
                letter-spacing: -0.5px;
            }

            .button-clear {
                padding: 4px 16px;
                height: 26px;
                color: #ff7e00;
                border: none;
                background: none;
                font-size: 14px;
                letter-spacing: -0.5px;
                // position: absolute;
                // left: 0;
                // bottom: 0;
    
                svg {
                    font-size: 12px;
                    position: relative;
                    top: -1px;
                    margin-right: 4px;
                }
    
                &:hover {
                    color: #e87200;
                }
            }
        }
    }
}