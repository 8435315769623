.chat-room-message-in-email-wrapper {
    margin-top: 16px;
    padding: 0 24px;
    width: 100%;
    position: relative;
  }
  .chat-room-message-in-email-wrapper .message-in-bubble {
    border-radius: 2px 16px 16px;
    border: 1px solid rgba(25, 25, 25, 0.1);
    background: #ffffff;
    display: inline-block;
    width: 100%;
    // max-width: calc(100% - 148px);
    // padding: 10px 45px 10px 10px;
    padding: 10px;
    color: #191919;
    font-size: 16px;
    letter-spacing: -0.3px;
    line-height: 24px;
    position: relative;
    // cursor: pointer;
    min-height: 44px;

    .message-in-email-wrapper-top {
      position: relative;
      width: 100%;
      padding: 0 82px 10px 48px;
      border-bottom: solid 1px rgba(25, 25, 25, 0.1);

      .top-avatar-wrapper {
        position: relative;
        width: 40px;
        height: 40px;
        display: inline-block;
        border-radius: 50%;
        background: rgba(25, 25, 25, 0.2);
        position: absolute;
        left: 0;
        top: 0;

        b {
          color: #fff;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }

        .other-agent-icon {
          position: absolute;
          bottom: -1px;
          right: -1px;
          width: 16px;
          height: 16px;
          border-radius: 50%;
          border: solid 2px #fff;
          background: #0052ff;
          padding: 2px;

          img {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 8px;
          }
        }
      }

      .top-info-wrapper {
        height: 50px;
      }

      .top-name {
        font-size: 14px;
        letter-spacing: -0.4px;
        margin: 0 0 2px 0;
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        line-height: 16px;
        white-space: nowrap;
        color: rgba(25, 25, 25, 0.4);
      }

      .top-subject {
        font-size: 12px;
        letter-spacing: -0.2px;
        margin: 0 0 2px 0;
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        color: rgba(25, 25, 25, 0.6);
        line-height: 16px;
        white-space: nowrap;
      }

      .top-info {
        font-size: 12px;
        margin: 0;
        letter-spacing: -0.2px;
        margin: 0 0 2px 0;
        color: rgba(25, 25, 25, 0.6);
        line-height: 16px;
        position: relative;
        padding-right: 16px;
        display: inline-block;
        max-width: 100%;
        
        .top-info-name {
          white-space: nowrap;
          max-width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .dropdown {
          position: absolute;
          top: -1px;
          right: 0;
          margin-left: 2px;
          box-shadow: none !important;
          
          button {
            line-height: 1;
            padding: 0;
            background: transparent !important;
            border: none !important;
            box-shadow: none !important;
          }

          .dropdown-menu {
            padding: 16px 16px 12px 16px;
            font-size: 12px;
            letter-spacing: -0.2px;
            width: 320px;
            border-radius: 8px;
            margin: 0;
            overflow-y: auto;
            max-height: 270px;

            button {
              box-shadow: none !important;
            }
          }
        }
      }

      .top-info-arrow {
        font-size: 14px;
        color: rgba(25, 25, 25, 0.6);
        transition: ease-in-out .1s;
         
        &:hover {
          color: var(--main-dark-orange);
        }

        &.rotate-arrow {
          // transform: rotate(180deg);
        }
      }

      .message-info {
        position: absolute;
        top: 0;
        right: 0;
        display: inline-block;
      }
    }

    .message-in-email-wrapper-bottom {
      width: 100%;
      position: relative;
      padding: 12px 0 0;
    }
  }
  .chat-room-message-in-email-wrapper .message-in-bubble.deleted-bubble {
    color: #9b9b9b;
    padding: 10px 10px 10px 33px;
  }
  .chat-room-message-in-email-wrapper .message-in-bubble.deleted-bubble .deleted-icon {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translate(0, -50%);
  }
  .chat-room-message-in-email-wrapper .message-in-bubble.deleted-group-in {
    padding: 10px;
  }
  .chat-room-message-in-email-wrapper .message-in-bubble.deleted-group-in .deleted-icon {
    position: relative;
    left: 0;
    top: 5px;
    margin-right: 3px;
    transform: unset;
  }
  .chat-room-message-in-email-wrapper .message-in-bubble .forwarded-message {
    color: #191919;
    font-size: 12px;
    letter-spacing: -0.4px;
    line-height: 18px;
    display: block;
    margin-bottom: 5px;
  }
  .chat-room-message-in-email-wrapper .message-in-bubble .reply-message {
    width: calc(100% - 20px);
    border-radius: 4px;
    border-left: solid 5px #ff7d00;
    background: #f3f3f3;
    color: #191919;
    position: absolute;
    top: 10px;
    left: 10px;
    padding: 5px 7px 5px 7px;
    line-height: 1;
  }
  .chat-room-message-in-email-wrapper .message-in-bubble .reply-message p {
    margin: 0;
    font-size: 12px;
    letter-spacing: -0.4px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 20px;
  }
  .chat-room-message-in-email-wrapper .message-in-bubble .reply-message p:nth-child(2) {
    letter-spacing: -0.2px;
  }
  .chat-room-message-in-email-wrapper .message-in-bubble .reply-message.reply-file .reply-file-thumbnail {
    display: inline-block;
    width: 40px;
    height: 40px;
    margin-right: 10px;
    position: relative;
  }
  .chat-room-message-in-email-wrapper .message-in-bubble .reply-message.reply-file .reply-file-thumbnail img {
    width: 40px;
    height: 40px;
  }
  .chat-room-message-in-email-wrapper .message-in-bubble .reply-message.reply-file .reply-file-thumbnail svg {
    position: absolute;
    left: 50%;
    top: 50%;
    font-size: 35px;
    transform: translate(-50%, -50%);
  }
  .chat-room-message-in-email-wrapper .message-in-bubble .reply-message.reply-file .reply-text-wrapper {
    width: calc(100% - 50px);
    display: inline-block;
    vertical-align: top;
  }
  .chat-room-message-in-email-wrapper .message-in-bubble .message-info {
    /* position: absolute; */
    margin: 0;
    /* bottom: 11px; */
    /* right: 10px; */
    color: rgba(25, 25, 25, 0.4);
    font-size: 10px;
    letter-spacing: -0.4px;
    line-height: 18px;
    /* width: 24px; */
    width: 100%;
    text-align: right;
    /* width: 26px; */

    .centered-dot {
      width: 2px;
      height: 2px;
      display: inline-block;
      vertical-align: top;
      background: rgba(25, 25, 25, 0.4);
      border-radius: 50%;
      position: relative;
      top: 9px;
    }
  }
  .chat-room-message-in-email-wrapper .message-in-bubble .group-sender-name-wrapper {
    color: #ff7d00;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0;
    font-size: 14px;
  }
  .chat-room-message-in-email-wrapper .message-in-bubble .group-sender-name-wrapper .agent-span {
    color: #A3A3A3;
  }
  .chat-room-message-in-email-wrapper:hover .message-action-wrapper {
    display: inline-block;
  }
  .chat-room-message-in-email-wrapper .group-sender-avatar-wrapper {
    width: 32px;
    height: 32px;
    display: inline-block;
    border-radius: 50%;
    vertical-align: top;
    margin-right: 8px;
    font-size: 16px;
    line-height: 32px;
    text-align: center;
    color: #ffffff;
    position: relative;
  }
  .chat-room-message-in-email-wrapper .group-sender-avatar-wrapper .avatar-icon-badge {
    width: 20px;
    bottom: -2px;
    right: -1px;
    height: 20px;
    position: absolute;
  }
  .chat-room-message-in-email-wrapper .group-sender-avatar-wrapper img {
    width: 32px;
    height: 32px;
    display: inline-block;
    border-radius: 50%;
  }
  .chat-room-message-in-email-wrapper .message-action-wrapper {
    /* right: -120px !important; */
    right: -80px !important;

    &.with-forward {
      right: -118px !important;
      
      .forward-button {
       display: inline-block !important;
      } 
    }
  }

  //reply button
  .hide-reply-button .message-in-bubble .message-action-wrapper {
    right: -40px !important;

    &.with-forward {
      right: -78px !important;
    }
  }
  //reply button