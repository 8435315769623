.comp-email-wrapper-docs {
    .comp-email-wrapper-docs-inner {
        width: 100%;
        position: relative;
        display: block;
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        row-gap: 12px;
        column-gap: 7px;
        
        .comp-email-wrapper-docs-box {
            border-radius: 8px;
            border: solid 1px rgba(25, 25, 25, 0.1);
            width: calc((100% / 3) - 5px);

            .comp-email-wrapper-docs-box-top {
                background: #FFF2E5;
                width: 100%;
                height: 80px;
                position: relative;
                border-radius: 8px 8px 0 0;

                .comp-email-wrapper-docs-box-top-icon {
                    width: 36px;
                    height: 36px;
                    border-radius: 50%;
                    background: rgba(25, 25, 25, 0.4);
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);

                    svg {
                        font-size: 20px;
                        color: #fff;
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                    }
                }
            }

            .comp-email-wrapper-docs-box-bottom {
                padding: 8px 32px 8px 8px;
                width: 100%;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                position: relative;
                margin: 0;

                .comp-email-wrapper-docs-download {
                    color: var(--main-dark-orange);
                    margin-left: 6px;
                    position: absolute;
                    top: 50%;
                    transform: translate(0, -50%);
                    right: 8px;
                }
            }
        }
    }
}

.modal-image.modal-dialog {
    width: 100vw !important;
}

.modal-image .modal-content {
    width: 100%;
    height: 100%;
    background: none;
}

.modal-image .modal-content .modal-body {
    width: 100%;
    height: 100%;
    background: rgba(25, 25, 25, 0.8);
}

.modal-image .modal-content .modal-body img.image-preview-val {
    max-width: calc(100vw - 130px);
    max-height: calc(100vh - 130px);
    background: #ffffff;
}

.modal-image .modal-content .modal-body .image-wrapper {
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;

    figure {
        margin: 0;

        &.zoom {
            background-size: 180%;
            background-position: -100% -100%;
            background-repeat: no-repeat;
            position: relative;
            width: 100%;
            overflow: hidden;
            cursor: zoom-in;

            img {
                transition: opacity 0.5s;
                display: inline-block;
                // width: 100%;

                &:hover {
                    opacity: 0;
                }
            }
        }
    }
}

.modal-image .modal-content .modal-body .close-modal-image {
    position: absolute;
    top: 20px;
    right: 20px;
    color: #ffffff;
    font-size: 40px;
    cursor: pointer;
    transition: ease-in-out 0.1s;
}

.modal-image .modal-content .modal-body .close-modal-image:hover {
    color: #c9c9c9;
}

.modal-video.modal-dialog {
    width: 100vw !important;
}

.modal-video .modal-content {
    width: 100%;
    height: 100%;
    background: none;
}

.modal-video .modal-content .modal-body {
    width: 100%;
    height: 100%;
    background: rgba(25, 25, 25, 0.8);
}

.modal-video .modal-content .modal-body .video-wrapper {
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.modal-video .modal-content .modal-body .close-modal-video {
    position: absolute;
    top: 20px;
    right: 20px;
    color: #ffffff;
    font-size: 40px;
    cursor: pointer;
    transition: ease-in-out 0.1s;
}

.modal-video .modal-content .modal-body .close-modal-video:hover {
    color: #c9c9c9;
}

.modal-video .modal-content .modal-body video {
    max-height: calc(100vh - 130px);
    max-width: calc(100vw - 130px);
}