.integration-telegram-add-account {
  padding: 32px 30px;
  text-align: left;

  .comp-back-button {
    margin: 0 0 16px 0;
    font-family: PTRootBold;
  }

  .integration-telegram-add-account-box {
    background: #ffffff;
    border-radius: 8px;
    padding: 32px;
    border: solid 1px rgba(25, 25, 25, 0.1);

    .integration-telegram-add-account-box-top {
      padding-bottom: 15px;
      border-bottom: solid 1px #dcdcdc;
      position: relative;
      font-size: 20px;
      letter-spacing: -0.6px;

      button {
        position: absolute;
        top: -6px;
        right: 0;
        padding: 7px 24px;
        font-size: 16px;
        letter-spacing: -0.6px;
        width: 160px;
        height: 40px;

        // .lds-ring {
        //   top: unset;
        //   right: unset;
        //   position: relative;
        //   top: 3px;

        //   div {
        //     margin: 0;
        //     border-color: #ffffff transparent transparent transparent;
        //   }
        // }
      }
    }

    .integration-telegram-add-account-box-form {
      width: 100%;

      label {
        padding: 24px 0 0;
        font-size: 14px;
        letter-spacing: -0.4px;
        display: block;
      }

      input[type='text'] {
        padding: 12px 16px;
        border-radius: 6px;
        border: solid 1px #dcdcdc;
        width: 100%;
        font-size: 16px;
        letter-spacing: -0.3px;
        display: block;
      }

      .form-topic {
        width: 320px;
      }

      .form-bot-token {
        width: 100%;

        label {
          display: flex;
          align-items: center;

          .bot-token-title {
            margin-right: 5px;
            margin-top: 2px;
          }
        }

        .bot-token-wrapper {
          display: flex;
          align-items: center;
          margin-bottom: 8px;

          &.border-red {
            border: solid 1px #ff3f57 !important;
          }

          input[type='text'] {
            width: 721px;
            display: inline-block;
            border-radius: 6px 0 0 6px;
            border: none;
            margin-bottom: 0;
            transition: ease-in-out 0.3s, width 0s;
          }

          input[type='text']:disabled {
            border-right: none !important;
          }

          button {
            padding: 12px 16px;
            border-radius: 0 6px 6px 0;
            border: solid 1px #dcdcdc;
            font-size: 16px;
            letter-spacing: -0.3px;
            color: rgba(25, 25, 25, 0.2);
            background-color: transparent;
            padding: 12px 24px;
            font-family: PTRootBold;
            // transition: none;

            .valid {
              display: flex;
              align-items: center;

              .valid-green {
                width: 18px;
                height: 13px;
                background: url('../../../../assets/img/integration/icon-valid.svg');
                margin-right: 7px;
              }
              .text-green {
                color: #19c700;
              }
              .valid-grey {
                width: 18px;
                height: 13px;
                background: url('../../../../assets/img/integration/icon-valid-grey.svg');
                margin-right: 7px;
              }
              .text-grey {
                color: rgba(25, 25, 25, 0.2);
              }
            }

            .validating {
              display: flex;
              align-items: center;
              color: rgba(25, 25, 25, 0.4);
              // border: solid 1px #dcdcdc;
              .grey-lds-ring {
                top: -5px;
                right: 15px;
              }
              .grey-lds-ring div {
                border-color: #8996a4 transparent transparent transparent;
              }
            }
          }

          button[disabled] {
            border: solid 1px #dcdcdc !important;
            border-left: none !important;
          }
        }
      }

      input[type='text']:disabled {
        background: rgba(25, 25, 25, 0.1);
        color: rgba(25, 25, 25, 0.4);
        border: none;
        cursor: not-allowed;
      }

      .integration-info-form {
        font-size: 14px;
        color: rgba(25, 25, 25, 0.8);
        letter-spacing: -0.2px;
      }

      .custom-select-telegram-topic {
        .custom-select-option-value-wrapper {
          padding: 12px 16px !important;
          margin-bottom: 8px;
        }
      }
    }
  }
}

.modal-listed-here {
  .modal-content {

  }

  .modal-body {
    position: relative;
  }
}
