.form-broadcast-message {
    .button-go-to-preview {
        margin: 24px 0 0 0;
    }

    .add-more-message-wrapper {
        width: 100%;
        margin-top: 24px;
        border-top: solid 1px #dcdcdc;
        display: block;
        padding: 0 0 0 8px;
    }

    .recipient-type-multi {
        position: relative;
        font-size: 14px;
        letter-spacing: -0.2px;

        img {
            position: relative;
            top: -2px;
            margin-right: 4px;
        }
    }

    .add-new-channel-recipient {
        color: var(--main-dark-orange);
        font-size: 14px;
        letter-spacing: -0.5px;
        margin: 24px 0 24px;
        cursor: pointer;

        &:hover {
            color: var(--main-darker-orange);
        }

        svg {
            position: relative;
            top: -1px;
            margin: 0 4px 0 0;
        }
    }
    
    .custom-select-multi-whatsapp {
        width: 398px !important;
        max-width: unset !important;
    }

    .channel-account-multi {
        margin: 24px 0 8px;
    }

    .option-select-bulk-wrapper  {
        margin: 24px 0 0;
    }

    .for-select-segment {
        margin: 0 0 24px;
    }

    .pos-relative {
        width: 316px;
    }
    
    .message-part {
        border-bottom: solid 1px #dcdcdc;
        padding-bottom: 24px;
    }

    .react-select-broadcast__control {
        margin-bottom: 8px !important;
    }

    .broadcast-message-schedule {
        border-bottom: solid 1px #dcdcdc;
        padding: 0 0 24px 0;

        .broadcast-schedule-title {
            margin: 24px 0;

            svg {
                position: relative;
                top: -2px;
                margin-right: 4px;
            }
        }
    }
    
    .broadcast-schedule-time-wrapper {
        width: 100%;
        padding-left: 24px;

        label {
            padding-left: 0 !important;
            margin-top: 8px !important;
        }

        .time-select-info {
            font-size: 12px;
            letter-spacing: -0.4px;
            color: rgba(25, 25, 25, 0.8);
            margin: 8px 0 0;

            svg {
                position: relative;
                top: -1px;
                margin-right: 4px;
            }
        }

        .broadcast-date-select-wrapper {
            display: inline-block;
            margin-right: 24px;
            width: 173px;
            vertical-align: top;

            label {
                margin: 0 0 4px;
            }

            .broadcast-date-value-wrapper {
                border-radius: 8px;
                height: 48px;
                width: 100%;
                position: relative;
                padding: 0 48px 0 16px;
                border: solid 1px #dcdcdc;
                cursor: pointer;
                line-height: 46px;

                &:hover {
                    border-color: #ff7d00;
                }

                svg {
                    position: absolute;
                    right: 16px;
                    top: 50%;
                    transform: translate(0, -50%);
                }
            }
        }

        .broadcast-time-input-wrapper {
            display: inline-block;
            width: 146px;
            vertical-align: top;

            label {
                margin: 0 0 4px;
            }

            .broadcast-time-value-wrapper {
                border-radius: 8px;
                height: 48px;
                width: 100%;
                position: relative;
                padding: 0 48px 0 16px;
                // border: solid 1px #dcdcdc;

                svg {
                    position: absolute;
                    right: 16px;
                    top: 50%;
                    transform: translate(0, -50%);
                }

                input {
                    position: absolute;
                    border: solid 1px #dcdcdc;
                    width: 100%;
                    height: 48px;
                    border-radius: 8px;
                    left: 0;
                    top: 0;
                    background: transparent;
                    padding: 0 48px 0 16px;
                    
                    &:focus {
                        border-color: #ff7d00;
                    }
                }
            }
        }
    }

    .broadcast-info-separator {
        font-size: 20px;
        letter-spacing: -0.6px;
        color: #191919;
        margin: 24px 0;

        svg {
            position: relative;
            margin-right: 4px;
            top: -2px;
        }
    }

    .message-content-separator {
        font-size: 20px;
        letter-spacing: -0.6px;
        color: #191919;
        border-top: solid 1px #dcdcdc;
        margin: 24px 0;
        padding-top: 22px;

        img {
            position: relative;
            margin-right: 4px;
            top: -2px;
        }

        svg {
            position: relative;
            margin-right: 4px;
            top: -2px;
        }
    }

    .message-part {
        &.no-border-bottom {
            border-bottom: 0;
            padding-bottom: 0;
        }
    }

    .broadcast-time-separator {
        font-size: 20px;
        letter-spacing: -0.6px;
        color: #191919;
        border-top: solid 1px #dcdcdc;
        margin: 24px 0 16px;
        padding-top: 22px;
        position: relative;

        .delete-multi-recipient {
            position: absolute;
            right: 0;
            top: 16px;
            color: var(--watermelon);
            cursor: pointer;
        }

        img {
            position: relative;
            margin-right: 4px;
        }

        svg {
            position: relative;
            margin-right: 4px;
            top: -2px;
        }

        &.select-segment {
            margin: 24px 0 0;
        }
    }

    .bc-time-input-wrapper {
        
    }

    .broadcast-time-wrapper {
        .label-radio {
            margin: 8px 0 0;
            padding-left: 24px !important;
            font-size: 16px;
        }
    }

    .form-title-wrapper {
        padding-bottom: 12px;

        svg {
            font-size: 24px;
            margin-right: 6px;
            position: relative;
            top: -2px;
        }

        p {
            font-size: 16px;
            color: rgba(25, 25, 25, 0.8);
            letter-spacing: -0.3px;
            margin: 0;
        }
    }
    
    .form-content-wrapper {
        textarea {
            // width: 494px;
            width: 100%;
            max-width: 100%;
            height: 96px;
            // margin-bottom: 20px;
        }

        .message-channel-field {
            img {
                width: 24px;
                margin-right: 8px;
                position: relative;
                top: -2px;
            }

            font-size: 16px;
            letter-spacing: -0.3px;
        }

        .message-type-field {
            img {
                width: 24px;
                margin-right: 8px;
                position: relative;
                top: -2px;
            }

            font-size: 16px;
            letter-spacing: -0.3px;
        }

        .more-message-type-selection-wrapper {
            width: 100%;
            position: relative;
            display: block;
    
            .message-type-selection-selection {
                vertical-align: top;
                border-radius: 8px;
                border: solid 1px #dcdcdc;
                display: inline-block;
                position: relative;
                
                margin-right: 9px;
                font-size: 14px;
                letter-spacing: -0.2px;
                padding: 14px 14px 14px 49px;
                cursor: pointer;
                transition: ease-in-out .1s;
                
            
                &:nth-last-child(1) {
                  margin-right: 0;
                }
            
                &.active-message-type-selection-selection {
                  border-color: #ff7d00;
                }
            
                &:hover {
                  border-color: #ff7d00;
                }
            }
            
            .message-type-selection-icon-wrapper {
                width: 28px;
                height: 28px;
                border-radius: 50%;
                position: absolute;
                top: 50%;
                left: 14px;
                transform: translate(0, -50%);
    
                img {
                    width: 25px;
                    height: 25px;
                }
            
                &.message-type-selection-text {
                //   background: #FFD4CC;
                }
                
                &.message-type-selection-image {
                    // background: #EBF7FF;
                }
            
                img {
                  position: absolute;
                  top: 50%;
                  left: 50%;
                  transform: translate(-50%, -50%);
                }
            }
        }

        .add-more-message-button {
            font-size: 16px;
            letter-spacing: -0.5px;
            color: #ff7d00;
            width: unset;
            background: none;
            border: 0;
            cursor: pointer;
            height: unset;
            padding: 0;
            margin-top: 28px;
            
            svg {
                position: relative;
                margin-right: 0px;
                top: -2px;
            }

            &:hover {
                color: #e87200;
            }
        }

        .with-case-wrapper {
            margin-bottom: 24px;
            padding-right: 54px;
            width: 100%;
            position: relative;

            label {
                margin-bottom: 0;
                font-size: 16px;
                letter-spacing:  -0.5px;
            }

            p {
                margin: 0;
                font-size: 14px;
                color: rgba(25, 25, 25, 0.8);
                letter-spacing: -0.2px;
            }

            .custom-switch-wrapper {
                display: inline-block !important;
                width: 40px;
                height: 24px;
                position: absolute;
                top: 0;
                right: 0;
                margin: 0;

                .custom-switch.round {
                    &::before {
                        width: 14px;
                        height: 14px;
                        bottom: 5px;
                        left: 4px;
                    }
                }

                input:checked + .custom-switch:before {
                    transform: translateX(18px);
                }
            }
        }
    }

    .main-custom-select-option-wrapper {
        width: 316px;
        max-width: 100%;

        .custom-select-option-value-wrapper {
            margin-top: 0;
        }

        .dropdown-item {
            margin-top: 0;
        }
    }

    .submit-broadcast-message {
        padding: 7px 24px 8px;

        svg {
            position: relative;
            top: -2px;
            margin-right: 4px;
        }
    }

    .main-button-40 {
        svg {
            z-index: 0;
        }
    }

    .no-height {
        input[type="text"] {
            height: unset;
        }
    }
    .message-template-wrapper .css-9uig5a-control {
        margin-bottom: 8px;
    }
}