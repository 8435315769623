.reset-password-image img {
    width: 85%;
}

.div50 {
    display: inline-block;
    width: 50%;
    position: relative;
    vertical-align: top;
}

.div50 div {
    letter-spacing: -0.2px;
    color: #191919;
    font-size: 14px;
    height: 28px;
    line-height: 28px;
    position: relative;
    padding-left: 22px;
}

.div50 div svg {
    position: absolute;
    top:50%;
    left:0;
    transform: translate(0, -50%);
}

.disabled-retype-password:disabled {
    color: rgba(25, 25, 25, 0.2) !important;
    border: none !important;
    background: rgba(25, 25, 25, 0.1) !important;
    cursor: not-allowed;
}

.input-wrapper .popup-input-password-wrapper {
    position: relative;
    /* border: solid 1px #dcdcdc; */
    border-radius: 8px;
    // width: 310px;
}

.input-wrapper .popup-input-password-wrapper input {
    padding: 12px 50px 14px 15px;
    width: 100%;
    display: inline-block;
    border: none;
    border-radius: 8px;
    letter-spacing: -0.2px;
    font-size: 16px;
    color: #191919;
    border: solid 1px #dcdcdc;
}

.input-wrapper .popup-input-password-wrapper .peek-password {
    position: absolute;
    width: 50px;
    color: #191919;
    text-align: center;
    top: 12px;
    right: 1px;
    height: 41px;
    line-height: 40px;
    font-size: 20px;
    /* border-left: solid 1px #dcdcdc; */
    cursor: pointer;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    // background: #ffffff;
}

.input-wrapper .popup-input-password-wrapper .peek-password.active-eye {
  color: #ff7d00;
}

.input-wrapper .popup-input-password-wrapper .peek-password.disable-seek-password {
    cursor: not-allowed;
}

// .input-wrapper .popup-input-password-wrapper input:focus + .peek-password {
//     color: #ff7d00;
// }

.input-wrapper .popup-input-password-wrapper input:focus {
    border: solid 1px #ff7d00;
}


@media screen and (max-width: 415px) {
    .password-strength .div50 {
        width: 100%;
    }
}

.popup-modal-change-password {
    text-align: center;
}

.popup-modal-change-password .popup-modal-change-password-button {
  margin: 0;
}

.popup-modal-change-password .modal-content {
  width: 540px;
}

.popup-modal-change-password .modal-body {
    border-radius: 8px;
}

.popup-modal-change-password .modal-body {
    padding: 35px 48px 32px;
}

.popup-modal-change-password-title {
    font-size: 24px;
    letter-spacing: -0.6px;
    color: #52d540;
    margin: 0;
}

.popup-modal-change-password-title img {
    position: relative;
    top: -2px;
    margin-right: 8px;
}

.popup-modal-change-password-description {
    font-size: 16px;
    letter-spacing: -0.3px;
    margin: 8px 0 24px;
}

// verify reset password
// .outer-verify-reset-password {
//     width: 100%;
//     height: 100vh;
//     display: flex;
//     justify-content: center;
//     align-items: center;
  
    .loading-wrapper-password {
      position: relative;
      top: calc(50% - 152px);
      left: 50%;
      transform: translate(-50%, -50%);
      width: 540px;
      padding: 48px;
      border-radius: 32px;
      box-shadow: 0px 3px 22px 0px #1919194D;
      background-color: #ffffff;
      font-size: 24px;
      font-weight: bold;
      // font-family: "PTRootBold";
      line-height: 30px;
      letter-spacing: -0.6px;
      text-align: center;
      color: #191919;
  
      .auth-lds-ring {
        display: block;
        position: relative;
        width: 88px;
        height: 88px;
        left: 50%;
        transform: translateX(-50%);
        margin-bottom: 10px;
        
        div {
          width: 72px;
          height: 72px;
          border-width: 8px;
          top: 2px;
          right: 2px;
        }
      }
    }
  
    .failed-wrapper {
      position: relative;
      top: calc(50% - 152px);
      left: 50%;
      transform: translate(-50%, -50%);
      width: 424px;
      padding: 48px;
      border-radius: 32px;
      box-shadow: 0px 3px 22px 0px #1919194D;
      background-color: #ffffff;
      align-items: center;

      .reset-password-failed {
          padding: 9.5px;
          margin: auto;
          width: 88px;
          height: 88px;
          background: #FF3F5733;
          border-radius: 44px;
      }
      
      .reset-password-failed svg {
          padding: 12.5px;
          width: 69px;
          height: 69px;
          background: #FF3F57;
          color: white;
          border-radius: 35px;
      }

      .reset-password-success {
          padding: 9.5px;
          margin: auto;
          width: 88px;
          height: 88px;
          background: #11830033;
          border-radius: 44px;
      }
      
      .reset-password-success svg {
          padding: 12.5px;
          width: 69px;
          height: 69px;
          background: #118300;
          color: #cfe6cc;
          border-radius: 35px;
      }
  
      .message-wrapper {
        display: block;
        text-align: center;
        margin: 10px 0 0;
        width: 100%;
  
        b {
          font-size: 24px;
          line-height: 30px;
          letter-spacing: -0.6px;
          color: #191919;
          text-align: center;
        }
  
        p {
          margin: 10px 0 0;
          font-size: 16px;
          line-height: 24px;
          letter-spacing: -0.3px;
          color: #191919;
          text-align: center;
        }
      }
      
      .forgot-password {
        display: block;
        margin-top: 12px;
        width: 100%;
        text-align: center;
        font-size: 16px;
        font-weight: bold;
        line-height: 24px;
        letter-spacing: -0.5px;
        color: #ff7e00;

        svg {
          margin-left: 4px;
          transform: translateY(-2px);
        }
      }
    }
  // }
