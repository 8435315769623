.integration-miitel-add-account {
  padding: 32px 30px;
  text-align: left;

  .comp-back-button {
    margin: 0 0 16px 0;
    font-family: PTRootBold;
  }

  .integration-miitel-add-account-box {
    background: #ffffff;
    border-radius: 8px;
    padding: 32px;
    border: solid 1px rgba(25, 25, 25, 0.1);

    .integration-miitel-add-account-box-top {
      padding-bottom: 15px;
      border-bottom: solid 1px #dcdcdc;
      position: relative;
      font-size: 20px;
      letter-spacing: -0.6px;

      button {
        position: absolute;
        top: -6px;
        right: 0;
        font-size: 16px;
        letter-spacing: -0.6px;
        width: 180px;
      }
    }

    label {
      font-size: 16px;
      margin: 0 0 8px 0;
      position: relative;
      width: 343px;
      padding: 24px 0 0 0;

      span {
        position: absolute;
        right: 0;
        bottom: 0;
      }
    }

    input[type="text"] {
      width: 343px;
    }
  }
}

.modal-warning-limit-miitel {
  .modal-body {
    text-align: left;
  }
}
