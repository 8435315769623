.broadcast-message-form-additional-message {
    border-top: solid 1px #dcdcdc;
    margin-top: 24px;
    padding-top: 24px;

    .message-content-title {
        font-size: 16px;
        letter-spacing: -0.5px;
        width: 100%;
        display: block;
        position: relative;

        img {
            margin-right: 4px;
            position: relative;
            top: -1px;
        }

        .delete-message-button {
            position: absolute;
            width: unset;
            right: 0;
            top: 50%;
            transform: translate(0, -50%);
            margin: 0;
        }
    }

    .more-message-type-selection-wrapper {
        width: 100%;
        position: relative;
        display: block;

        .message-type-selection-selection {
            vertical-align: top;
            border-radius: 8px;
            border: solid 1px #dcdcdc;
            display: inline-block;
            position: relative;
            
            margin-right: 9px;
            font-size: 14px;
            letter-spacing: -0.2px;
            padding: 14px 14px 14px 49px;
            cursor: pointer;
            transition: ease-in-out .1s;
            
        
            &:nth-last-child(1) {
              margin-right: 0;
            }
        
            &.active-message-type-selection-selection {
              border-color: #ff7d00;
            }
        
            &:hover {
              border-color: #ff7d00;
            }
        }
        
        .message-type-selection-icon-wrapper {
            width: 28px;
            height: 28px;
            border-radius: 50%;
            position: absolute;
            top: 50%;
            left: 14px;
            transform: translate(0, -50%);

            img {
                width: 25px;
                height: 25px;
            }
        
            &.message-type-selection-text {
              // background: #FFD4CC;
            }
            
            &.message-type-selection-image {
                // background: #EBF7FF;
            }
        
            img {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
            }
        }
    }

    .message-content-value {
        
    }
}