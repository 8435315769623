.error-or-info-comp {
    font-size: 12px !important;
    letter-spacing: -0.4px !important;
    position: relative;
    padding: 0 0 0 20px;
    margin: 8px 0 0;
    color: rgba(25, 25, 25, 0.8);

    svg {
        position: absolute !important;
        // top: 3px !important;
        left: 0 !important;
        font-size: 16px !important;
    }

    b {
        font-size: 12px !important;
        letter-spacing: -0.4px !important;
        line-height: 18px !important;
    }
}