.form-add-label {
    position: relative;

    .form-title-wrapper {
        padding-bottom: 16px;
        border-bottom: solid 1px rgba(25, 25, 25, 0.1);
        color: #191919;
        font-size: 20px;
        letter-spacing: -0.6px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        button {
          font-size: 16px;
          width: 181px;
        }
    }

    .form-content-wrapper label {
        display: block;
        color: #191919;
        font-size: 16px;
        letter-spacing: -0.5px;
        margin-top: 22px;
        width: 300px;
        
        .input-label-optional {
          color: rgba(25, 25, 25, 0.4);
        }
    }

    .form-content-wrapper input[type="text"] {
        background: rgb(255, 255, 255);
        border-radius: 8px;
        border: 1px solid rgba(25, 25, 25, 0.1);
        height: 48px;
        width: 300px;
        color: #191919;
        font-size: 16px;
        letter-spacing: -0.3px;
        width: 300px;
        padding: 12px 16px;
        margin-bottom: 8px;
    }

    .form-content-wrapper textarea {
        background: rgb(255, 255, 255);
        border-radius: 8px;
        border: 1px solid rgba(25, 25, 25, 0.1);
        width: 300px;
        color: #191919;
        font-size: 16px;
        letter-spacing: -0.3px;
        padding: 16px;
        resize: none;
        margin-bottom: 8px;
    }

    .form-content-wrapper label {
        display: block !important;
    }

    .form-content-wrapper .role-radio-label {
        position: relative;
    }

    .form-content-wrapper .role-radio-label b {
        display: block;
        color: #191919;
        font-size: 14px;
        letter-spacing: -0.4px;
    } 

    .form-content-wrapper .role-radio-label label {
        color: rgba(25, 25, 25, 0.8);
        font-size: 14px;
        letter-spacing: -0.2px;
    } 

    .form-content-wrapper .custom-radio label {
        margin-top: 18px;
    }

    .form-content-wrapper button {
        width: 300px;
        padding: 10px 0 11px;
        margin-top: 32px;
    }

    .form-content-wrapper .orange-button .lds-ring {
        top: 3px;
        right: unset;
    }

    .form-content-wrapper .orange-button .lds-ring div {
        border-color: #ffffff transparent transparent transparent;
        margin: 0;
    }

    .submit-add-label {
        position: absolute;
        top: -7px;
        right: 32px;
        width: unset !important;
        padding: 7px 24px !important;
        margin-top: 32px !important;

        .lds-ring {
            top: 3px;
            right: unset;

            div {
                margin: 0;
                border-color: #ffffff transparent transparent transparent;
            }
        }
    }

    .input-info-wrapper {
        color: rgba(25, 25, 25, 0.8);
        font-size: 14px;
        letter-spacing: -0.2px;
        line-height: 21px;
        width: 326px;
    }

    .form-content-wrapper {
      .input-customize {
        width: 100%;
        padding-right: 10px;

        .color-select-wrapper {
          margin-top: 12px;
          padding: 0;
          white-space: nowrap;
          // border: 1px solid rgba(25, 25, 25, 0.1);
          border-radius: 8px;
          display: block;
          width: 100%;
          // display: flex;
          // justify-content: flex-start;
          // flex-wrap: wrap;

          .color-item {
            width: 32px;
            height: 32px;
            border-radius: 50%;
            cursor: pointer;
            margin: 0 16px 4px 0;
            display: inline-block;
            vertical-align: top;
            position: relative;

            svg {
              position: relative;
              left: 50%;
              top: 3px;
              transform: translateX(-50%);
              color: #ffffff;
              font-size: 20px;
              // display: none;
            }

            // &:nth-child(5n) {
            //   margin-right: 0;
            // }
          }

          &.grey-box {
            background: none !important;

            .color-item {
              &::before {
                content: "";
                background: rgba(255, 255, 255, 0.4);
                width: 32px;
                height: 32px;
                border-radius: 50%;
                position: absolute;
                top: 0;
                left: 0;
              }
            }
          }
        }
  
        .color-input-wrapper {
          position: relative;
          width: 166px;
          height: 48px;
          border-radius: 8px;
          border: solid 1px #dcdcdc;
          cursor: pointer;
          line-height: 47px;
          font-size: 16px;
          letter-spacing: -0.3px;
          color: #191919;
          padding: 0 0 0 16px;
          margin-top: 12px;
  
          .color-placeholder {
            color: rgba(25, 25, 25, 0.6);
          }
  
          img {
            position: absolute;
            position: absolute;
            right: 16px;
            top: 50%;
            transform: translate(0, -50%);
          }
  
          .color-tag {
            display: inline-block !important;
            margin-right: 1px;
            margin-top: 0;
          }
  
          .color-value {
            width: 16px;
            height: 16px;
            display: inline-block;
            background: #dcdcdc;
            border-radius: 50%;
            position: absolute;
            right: 16px;
            top: 50%;
            transform: translate(0, -50%);
          }
  
          .color-input {
            border: none;
            padding: 0;
            background-color: transparent;
            height: 20px;
            margin-top: 0 !important;
            height: 40px !important;
            display: inline-block;
            width: 125px !important;

            &:focus {
              border: none !important;
            }
          }
  
          .color-picker-container {
            background: #ffffff;
            position: absolute;
            bottom: 0;
            right: -265px;
            border-radius: 4px;
            box-shadow: 0 2px 20px 0 rgba(25, 25, 25, 0.18) !important;
            padding: 14px;
            z-index: 9999;
  
            .close-color-picker {
              position: absolute;
              top: 6px;
              font-size: 14px;
              right: 6px;
              color: rgba(25, 25, 25, 0.6);
  
              &:hover {
                color: rgba(25, 25, 25, 0.8);
              }
            }
  
            &::before {
              position: absolute;
              background: #ffffff;
              width: 16px;
              height: 16px;
              content: '';
              left: -8px;
              transform: rotate(45deg);
              bottom: 17px;
            }
  
            .sketch-picker {
              border-radius: 4px;
              box-shadow: none !important;
  
              .flexbox-fix:nth-child(2) {
                height: 28px;
  
                div:nth-child(1) {
                  div:nth-child(1) {
                    height: 23px !important  ;
                    div {
                      div.hue-horizontal {
                        div {
                          div {
                            display: block !important;
                            margin-top: 0 !important;
                          }
                        }
                      }
                    }
                  }
  
                  div:nth-child(2) {
                    div {
                      display: none;
                    }
                  }
                }
              }
  
              .flexbox-fix {
                &:nth-child(3) {
                  display: none !important;
                }
  
                &:nth-child(4) {
                  div {
                    div {
                      border-radius: 50% !important;
                    }
                  }
                }
              }
            }
          }
        }
  
        .input-color {
          p {
            font-size: 14px;
            margin: 0;
          }
  
          input[type='text'] {
            margin-top: 12px;
            width: 166px;
            height: 48px;
          }
        }
      }
    }

}