.modal-new-interactivePreview-message {
  .button-whatsapp-interactive-preview {
    width: 100%;
    height: 35px;
    text-align: center;
    color: #0096DE;
    padding: 9px 8px 0;
    border-radius: 8px;
    margin: 4px 0 0;
    font-size: 16px;
    background: #fff;
    line-height: 1;

    svg {
      position: relative;
      margin-right: 4px;
      top: 1px;
      font-size: 15px;
      vertical-align: top;
    }

    &.with-icon {
      padding: 8px 8px 0;
    }
  }


  .preview-message-body {
    margin-top: 16px;
  }

  .interactive-section-list {
    margin: 24px 0 0;

    .table-title {
      font-size: 16px;
      letter-spacing: -0.5px;
      margin: 12px 0 8px 0;
    }

    table {
      width: 100%;
      border-collapse: collapse;
    }

    thead {
      tr {
        border-bottom: solid 1px #dcdcdc;
      }

      td {
        font-size: 14px;
        letter-spacing: -0.4px;
        padding: 8px;

        &:nth-child(1) {
          width: 50px;
        }

        &:nth-child(2) {
          width: calc(50%);
        }

        &:nth-child(3) {
          width: calc(50%);
        }
      }
    }

    tbody {
      tr {
        &:nth-child(even) {
          background: #f3f3f3;
        }
      }
      td {
        font-size: 14px;
        letter-spacing: -0.2px;
        padding: 8px;
        word-break: break-word;
        vertical-align: top;
      }
    }
  }
  
  .form-label {
    label {
      width: 100%;
      position: relative;

      .grey-font {
        position: absolute;
        top: 1px;
        right: 0;
      }

      b {
        font-size: 16px;
        letter-spacing: -0.3px;
      }
    }

    .form-value {
      font-size: 16px;
      letter-spacing: -0.3px;
      margin-bottom: 0;
      color: rgba(25, 25, 25, 0.8);
    }
  }

  input:disabled {
    background-color: rgba(25, 25, 25, 0.1) !important;
  }

  input::placeholder {
    color: rgba(25, 25, 25, 0.4);
  }

  .disabled {
    label,
    b,
    input {
      color: rgba(25, 25, 25, 0.4);
    }
  }
}

.modal-new-interactivePreview-message {
  position: fixed;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100%;
  background: rgba(25, 25, 25, 0.8);
  visibility: hidden;
  opacity: 0;
  right: 0;
  transition: visibility 0.3s, opacity 0.5s ease-in-out;
  z-index: 1049;

  &.active-modal-new-interactivePreview-message {
    /* right: 0; */
    opacity: 1;
    visibility: visible;
    transition: visibility 0s, opacity 0.5s ease-in-out;
  }

  .modal-body {
    position: absolute;
    top: 8px;
    right: -100%;
    left: unset;
    transform: unset;
    height: calc(100% - 8px);
    width: 700px;
    display: inline-block;
    border-radius: 8px 0 0 0;
    background: #ffffff;
    padding: 0;
    max-height: calc(100% - 8px);
    // height: 100%;
    transition: ease-in-out 0.4s;

    &.action-modal-body-new-interactivePreview-message {
      right: 0;
    }

    .close-modal-interactivePreview {
      border-radius: 50%;
      width: 48px;
      height: 48px;
      background: #ffffff;
      color: #757575;
      position: absolute;
      left: -65px;
      top: 0;
      text-align: center;
      cursor: pointer;

      &:hover {
        background: #f9f9f9;
      }

      svg {
        position: relative;
        font-size: 25px;
        top: 11px;
      }
    }

    .new-interactivePreview-message-wrapper {
      height: calc(100% - 72px);
      max-height: calc(100% - 72px);
      overflow: auto;

      .comp-back-button {
        margin: 0 0 24px;
      }

      label {
        display: inline-block !important;
      }

      .new-interactivePreview-message-container {
        padding: 32px 32px 12px 32px;

        .new-interactivePreview-message-title {
          color: #191919;

          img {
            width: 24px;
            position: relative;
            top: -7px;
            margin-right: 4px;
          }

          b {
            font-size: 20px;
            letter-spacing: -0.6px;
          }

          svg {
            font-size: 24px;
            margin: 0 4px 0 0;
            position: relative;
            top: -7px;
          }

          p {
            margin: 2px 0 0;
            color: rgba(25, 25, 25, 0.6);
            font-size: 14px;
            letter-spacing: -0.2px;
          }
        }

        label {
          span {
            letter-spacing: -0.2px;
            font-size: 14px;
            color: rgba(25, 25, 25, 0.6);
          }
        }

        textarea {
          width: 100%;
          height: 152px;
        }

        input[type="text"] {
          width: 100%;
        }

        input[type="file"] {
          display: none;
        }
      }
    }

    .whatsapp-preview-interactive {
      width: 100%;
      position: relative;
      border-radius: 4px;
      background: #EBE6E1;
      padding: 32px 16px 24px 24px;
      text-align: center;
  
      .wrapper-quick-reply {
        div {
          margin: 0 !important;
        }
  
        &.wrapper-quick-reply-more {
          div {
            &:nth-child(1) {
              width: calc(50% - 2px);
              display: inline-block;
              margin-right: 4px !important;
            }
  
            &:nth-child(2) {
              width: calc(50% - 2px);
              display: inline-block;
              margin-right: 0 !important;
            }
          }
        }
      }
  
      .wrapper-call-to-action {
        .call-to-action-button-wrap {
          text-align: center;
          border-top: solid 1px rgba(25,25,25,0.1);
          color: #0096DE;
          height: 36px;
          margin: 0 !important;
          width: calc(100% + 20px);
          padding: 5px 20px 0;
          position: relative;
          left: -10px;
    
          svg {
            position: relative;
            margin-right: 6px;
            top: -1px;
          }
  
          &:nth-child(1) {
            margin-top: 8px !important;
          }
  
          &:nth-last-child(1) {
            height: 26px;
          }
        }
      }
  
      .whatsapp-link {
        color: #1391E9;
      }
  
      .whatsapp-info-wrapper {
        width: 74px;
        height: 24px;
        display: inline-block;
        background: #E4F2F9;
        border-radius: 4px;
        box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2);
        margin-bottom: 16px;
  
        .whatsapp-info-content {
          width: 50px;
          height: 14px;
          border-radius: 2px;
          display: inline-block;
          background: #D0DCE3;
          margin-top: 5px;
        }
      }
  
      .whatsapp-warning {
        width: 100%;
        font-size: 12px;
        letter-spacing: -0.4px;
        color: rgba(25, 25, 25, 0.6);
  
        svg {
          position: relative;
          top: -1px;
          margin-right: 4px;
        }
  
        .whatsapp-warning-content {
          padding: 2px 13px;
          border-radius: 4px;
          box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2);
          background: #ffffff;
          display: inline-block;
        }
      }
  
      .whatsapp-chat-bubble {
        word-break: break-word;
        background: #ffffff;
        width: 100%;
        padding: 10px;
        border-radius: 0 8px 8px 8px;
        box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2);
        position: relative;
        text-align: left;
        font-family: SFPro;
        overflow: hidden;
        
        .media-wrapper {
          text-align: center;
          width: 100%;
          // height: 50px;
          position: relative;
          border-radius: 8px;
          overflow: hidden;
          margin: 0 0 2px 0;
        }
        
        .image-media-preview {
          max-width: 100%;
          max-height: 100%;
          // height: 50px;
        }
        
        .video-media-preview {
          max-width: 100%;
          max-height: 100%;
          // height: 50px;
        }

        .black-layer {
          position: absolute;
          left: 0;
          top: 0;
          // background: rgba(0, 0, 0, 0.1);
          width: 100%;
          height: 100%;
        }

        svg {
          font-size: 24px;
          color: #fff;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
  
        &::before {
          position: absolute;
          background: transparent;
          width: 13px;
          height: 8px;
          content: "";
          border-top: solid 8px transparent;
          border-bottom: solid 8px transparent;
          border-left: solid 8px #ffffff;
          border-right: solid 8px transparent;
          transform: rotate(-45deg);
          top: -8px;
          left: -7px;
        }
  
        &.no-corner {
          text-align: center;
          border-radius: 8px !important;
          color: rgba(0, 150, 222, 1) !important;
          margin-top: 4px !important;
          vertical-align: top;
  
          svg {
            color: rgba(0, 150, 222, 1) !important;
            position: relative;
            top: -2px;
            font-size: 20px;
          }
  
          b {
            margin-left: 4px;
            font-size: 16px;
          }
  
          &::before {
            display: none;
          }
        }
  
        b {
          font-family: SFPro !important;
          font-weight: bold !important;
        }
  
        .whatsapp-chat-bubble-title {
          font-size: 16px;
          letter-spacing: -0.3px;
          line-height: 24px;
          margin: 0;
        }
  
        .whatsapp-chat-bubble-description {
          font-size: 16px;
          letter-spacing: -0.3px;
          line-height: 24px;
          margin: 0;
        }
  
        
        .whatsapp-chat-bubble-footer {
          font-size: 16px;
          letter-spacing: -0.3px;
          line-height: 24px;
          color: rgba(25,25,25,0.6);
          margin: 0;
        }
  
        .whatsapp-chat-bubble-status {
          text-align: right;
          width: 100%;
          font-size: 12px;
          color: rgba(0, 0, 0, 0.45);
          margin: 0;
  
          img {
            position: relative;
            margin-left: 3px;
          }
        }
      }
  
      .static-media {
        position: relative;
        font-size: 0;
  
        .static-media-document {
          border-radius: 8px;
          font-size: 16px;
          letter-spacing: -0.3px;
          padding: 12px 11px 12px 36px;
          position: relative;
          width: 100%;
          margin-bottom: 6px;
          background: #f3f3f3;
          color: rgba(25, 25, 25, 0.6);
          
          img {
            position: absolute;
            top: 50%;
            transform: translate(0, -50%);
            left: 11px;
          }
        } 
  
        img {
          &.static-media-image-asset {
            width: 100%;
            border-radius: 8px;
          }
        } 
  
        video {
          &.static-media-video-asset {
            width: 100%;
            border-radius: 8px;
          }
        }
  
        .static-media-image {
          border-radius: 12px;
          font-size: 14px;
          letter-spacing: -0.4px;
          padding: 82px 11px;
          position: relative;
          width: 100%;
          margin-bottom: 6px;
          background: #9b9b9b;
          color: #ffffff;
          text-align: center;
          
          svg {
           font-size: 26px;
           margin-bottom: 4px;
          }
        }
  
        .static-media-video {
          border-radius: 12px;
          font-size: 14px;
          letter-spacing: -0.4px;
          padding: 82px 11px;
          position: relative;
          width: 100%;
          margin-bottom: 6px;
          background: #9b9b9b;
          color: #ffffff;
          text-align: center;
  
          svg {
            font-size: 26px;
            margin-bottom: 4px;
          }
        }
  
        .static-media-video-file-wrapper {
          background: rgba(0, 0, 0, 0.35);
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 100%;
          height: 100%;
          border-radius: 8px;
          font-size: 0;
  
          svg {
            color: #ffffff;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-size: 32px;
          }
        }
      }
  
      .dynamic-media {
        .dynamic-media-document {
          border-radius: 8px;
          font-size: 16px;
          letter-spacing: -0.3px;
          padding: 12px 11px 12px 36px;
          position: relative;
          width: 100%;
          margin-bottom: 6px;
          background: #f3f3f3;
          
          img {
            position: absolute;
            top: 50%;
            transform: translate(0, -50%);
            left: 11px;
          }
        } 
  
        .dynamic-media-image {
          border-radius: 12px;
          font-size: 14px;
          letter-spacing: -0.4px;
          padding: 82px 11px;
          position: relative;
          width: 100%;
          margin-bottom: 6px;
          background: #9b9b9b;
          color: #ffffff;
          text-align: center;
          
          svg {
           font-size: 26px;
           margin-bottom: 4px;
          }
        }
  
        .dynamic-media-video {
          border-radius: 12px;
          font-size: 14px;
          letter-spacing: -0.4px;
          padding: 82px 11px;
          position: relative;
          width: 100%;
          margin-bottom: 6px;
          background: #9b9b9b;
          color: #ffffff;
          text-align: center;
  
          svg {
            font-size: 26px;
            margin-bottom: 4px;
          }
        }
      }
    }

    .send-message-button {
      width: 100%;
      padding: 12px 24px;
      background: #ffffff;
      font-size: 16px;
      letter-spacing: -0.5px;
      position: absolute;
      bottom: 0;
      left: 0;
      // box-shadow: 0 2px 20px 0 rgba(25, 25, 25, 0.18);

      button {
        width: 100%;
        height: 48px;
      }
    }
  }

  .add-to-quick-reply-wrapper {
    margin: 24px 0 0;
    
    .add-to-quick-reply-save {
      padding-bottom: 24px;

      .add-to-quick-reply-save-left {
        width: 50%;
        display: inline-block;
        vertical-align: top;
        font-size: 16px;
        letter-spacing: -0.3px;
        position: relative;
        top: 6px;

        .custom-checkbox [type="checkbox"]:not(:checked) + label:before {
          top: 4px;
        }

        .custom-checkbox [type="checkbox"]:checked + label:before {
          top: 4px;
        }

        .custom-checkbox [type="checkbox"]:checked + label:after {
          top: -1px;
          left: -5px;
        }
      }

      .add-to-quick-reply-save-right {
        width: 50%;
        display: inline-block;
        vertical-align: top;
        font-size: 14px;
        letter-spacing: -0.4px;
        text-align: right;

        .dropdown {
          width:  265px;
          display: inline-block;
        }

        .input-quick-reply-select {
          font-size: 14px;
          letter-spacing: -0.4px;

          .custom-select-option-value {
            font-family: PTRootBold;
            // text-align: center;
          }

          svg {
            color: var(--main-dark-orange) !important;
            right: 34px !important;
          }

          .dropdown {
            button {
              padding: 6px 56px 6px 36px !important;
            }
          }

          .dropdown-option-wrapper {
            padding: 0;
            
            button {
              padding: 6px 36px 6px 36px !important;
              font-family: PTRootBold;
              border-bottom: solid 1px #dcdcdc;

              &:nth-last-child(1) {
                border: none;
              }
            }

          }
        }
      }
    }

    .add-to-quick-reply-input {
      width: 100%;
      display: block;
      position: relative;

      label {
        font-size: 16px;
        letter-spacing: -0.5px;
        width: 100%;
        display: block;

        span {
          position: absolute;
          font-size: 16px !important;
          letter-spacing: -0.5px !important;
          right: 0;
          top: 1px;
        }
      }

      .input-slash {
        width: 40px !important;
        margin-right: 16px;
        display: inline-block;
      }

      .input-slash {
        width: 40px !important;
        margin-right: 8px;
        display: inline-block;
        vertical-align: top;
      }

      .input-me {
        width: 64px !important;
        margin-left: 8px;
        display: inline-block;
        vertical-align: top;
      }

      .grey-font {

      }

      input {
        border: solid 1px #dcdcdc;
        padding: 12px 16px;
        font-size: 16px;
        letter-spacing: -0.3px;
        border-radius: 8px  ;
      }

      &.input-general {
        .input-quick-reply {
          width: calc(100% - 48px) !important;
          display: inline-block;
          vertical-align: top;
        }
      }

      &.input-personal {
        .input-quick-reply {
          width: calc(100% - 120px) !important;
          display: inline-block;
          vertical-align: top;
        }

        .input-quick-reply-general {
          width: calc(100% - 48px) !important;
          display: inline-block;
          vertical-align: top;
        }
      }
    }
    
    .add-to-quick-reply-info {
      display: block;
      width: 60%;
      margin: 8px 0 0;
      font-size: 12px;
      letter-spacing: -0.2px;
      color: rgba(25, 25, 25, 0.6);
    }
  }
}

.modal-confirmation-send-interactive {
  button {
    width: calc(50% - 2px) !important;
    margin-right: 4px !important;

    &:nth-last-child(1) {
      margin-right: 0 !important;
    }
  }
}
