.search-box-wrapper {
    width: 307px;
    display: inline-block;
    position: relative;

    .search-box-input-inside {
      padding: 11px 72px 11px 16px !important;
    }

    .delete-search {
      position: absolute;
      cursor: pointer;
      right: 45px;
      top: 50%;
      transform: translate(0, -50%);
    }

    .search-icon {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translate(0, -50%);
      font-size: 20px;
      color: #191919;
      cursor: pointer;
      height: 46px;
      width: 48px;
      border-radius: 8px;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      background: transparent !important;
      // border: solid 1px rgba(25, 25, 25, .1) !important;
      // border-left: none;
      border: none !important;
      transition: none;

      svg {
        color: #191919;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }

      &:hover {
        background: hsla(30,100,45,1);
      }

      &[disabled] {
        // background: rgba(25, 25, 25, 0.1) !important;
        cursor: not-allowed;
        // border: solid 1px rgba(25, 25, 25, .1) !important;

        svg {
          color: rgba(25, 25, 25, 0.4) !important;
        }
      }
    }

    input {
      padding: 11px 72px 11px 16px;
      border:solid 1px rgba(25, 25, 25, .1);
      border-radius: 8px;
      width: 100%;
      font-size: 16px;
      letter-spacing: -0.3px;

      &:focus {
        border:solid 1px var(--main-dark-orange) !important; 

        ~ .search-icon {
          svg {
            color: var(--main-dark-orange) !important;
          }
        }
      }
    }

    .clear-search-inside {
      font-size: 16px;
      letter-spacing: -0.5px;
      color: #ff7d00;
      cursor: pointer;
      white-space: nowrap;
      position: absolute;
      top: 50%;
      transform: translate(0, -50%);
      margin: 0;
      right: 50px;

      svg {
        position: relative;
        top: -1px;
        margin-right: 4px;
      }

      &:hover {
        color: hsla(30,100,45,1);
      }
    }

    .clear-search {
      font-size: 16px;
      letter-spacing: -0.5px;
      color: #ff7d00;
      cursor: pointer;
      white-space: nowrap;
      position: absolute;
      top: 50%;
      transform: translate(0, -50%);
      margin: 0;
      right: -115px;
      display: none;

      svg {
        position: relative;
        top: -1px;
        margin-right: 4px;
      }

      &:hover {
        color: hsla(30,100,45,1);
      }
    }
}