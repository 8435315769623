.organization-details-container {
    .organization-details-top-content {
        border-bottom: solid 1px #dcdcdc;
        padding-bottom: 12px;

        button {
            width: 181px;
        }
    }

    .organization-details-bottom-content {
        color: #191919;
        position: relative;
        width: 100%;
        display: block;

        .organization-details-title {
            margin: 24px 0 8px;
            font-size: 14px;
            letter-spacing: -0.4px;
            position: relative;
            width: 316px;

            .input-counter {
                color: rgba(25, 25, 25, .4);;
                position: absolute;
                top: 0;
                right: 0;
            }
        }

        .organization-details-value {
            margin: 0;
            font-size: 20px;
            letter-spacing: -0.6px;
            max-width: 100%;
            width: 400px;
        }
    }
}