.source-text-wrapper {
    padding: 0.1px 16px 16px 16px;
    border-radius: 8px;
    background: #FAFAFA;

    .form-content-wrapper {

        label {
            margin: 16px 0 8px 0;
        }
    
        input {
            width: 100%;
        }

        textarea {
            display: block;
            width: 100%;
            overflow: hidden;
        }
    }

    .width-100 {
        max-width: 100% !important;
    }

    .main-button-40 {
        margin-top: 16px;
        background: white;

        &:hover {
            background-color: rgba(255, 126, 0, 0.05);
        }
    }
}
