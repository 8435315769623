.modal-confirmation {
    .modal-content {
      box-sizing: border-box;
      width: 476px;
      padding: 0 32px;
      border-radius: 8px;
    }
  
    .modal-body {
      font-size: 14px;
      letter-spacing: -0.2px;
      text-align: left;
      color: #9b9b9b;
  
      /* padding-top: 30px; */
      padding: 32px 0 12px;
  
      p {
        &.confirmation-description {
          margin: 4px 0 0;
          font-size: 14px;
          letter-spacing: -0.2px;
          color: rgba(25, 25, 25, 0.6);
          line-height: 21px;
        }
      }
  
      b {
        &.confirmation-title {
          font-size: 20px;
          letter-spacing: -0.6px;
          color: #191919;
          line-height: 24px;

          img {
            position: relative;
            top: -2px;
            margin-right: 4px;
          }

          svg {
            position: relative;
            top: -2px;
            margin-right: 4px;
          }
        }
      }
    }
  
    .modal-footer {
      padding: 0 0 32px;
      border: none;
      display: block;

      > * {
        margin: unset;
      }
  
      button {
        vertical-align: top;

        &.cancel-button {
          width: 128px;
          margin: 0 4px 0 0;
        }
  
        &.confirmation-button {
          margin: 0;
          width: calc(100% - 132px);
          display: inline-block;
        }
      }
    }
  }