.chatbot-setup-page-wrapper {

    // .sectionWrap {
    //     margin-top: 24px;
    
    .prompt-settings-title {
        margin-bottom: 4px; 
        font-size: 20px;
        line-height: 24px;
        letter-spacing: -0.6px;
        color: #191919;
    }

    .prompt-settings-description {
        margin: 8px 0 0 0;
        padding-bottom: 12px;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: -0.3px;
        color: #191919CC;
        border-bottom: solid 1px #1919191A;
    }

    .form-content-wrapper {
        width: 100%;
        max-width: 600px;

        label {
            font-size: 16px;
            line-height: 24px;
            letter-spacing: -0.5px;
            color: #191919;
        }

        .dropdown {
        
            button {
                margin-top: 0px;
            }
        }

        .greetings-list {
            position: relative;
            width: 100%;

            .input-greetings {
                display: block;
                margin: 4px 0;
                padding: 12px 48px 12px 16px;
                width: 100%;
                height: 48px;
                overflow: hidden;
            }

            .icon-remove-greeting {
                position: absolute;
                top: 0px;
                right: 0px;
                padding: 16px;
                width: 48px;
                height: 48px;
                color: #FF3F57;
                cursor: pointer;
            }
        }

        .button-add-greeting {
            margin: 4px 0;
            width: 216px;
            padding: 0 !important;
        }

        .optional {
            font-size: 14px;
            line-height: 21px;
            letter-spacing: -0.2px;
            color: #19191966;
        }

        .system-prompt-wrapper {

            .system-prompt-description {
                margin: -4px 0 16px 0;
                font-size: 14px;
                line-height: 21px;
                letter-spacing: -0.2px;
                color: #191919CC;
            }

            .input-system-prompt {
                display: block;
                margin: 4px 0;
                width: 100%;
                // height: 96px;
                // overflow: hidden;
                height: 338px;
                overflow: auto;
                resize: none;

                &::-webkit-scrollbar {
                    width: 12px;
                }
                
                &::-webkit-scrollbar-thumb {
                    border: 4px solid #00000000;
                    border-radius: 8px;
                    background-color: #1919194D;
                    background-clip: padding-box;
                }
            }
        }

        .dropdown-model .error-or-info-comp {

            a {
                color: var(--main-dark-orange);
            }
        
            a:hover {
                color: var(--main-dark-orange);
                text-decoration: underline !important;
            }
            
            a:active {
                color: var(--main-darker-orange);
                text-decoration: underline !important;
            }
        }

        .dropdown-language {

            .option-select-bulk-wrapper {
                padding-right: 88px;
            }
        }

        .add-margin-bottom {
            margin-bottom: 16px;
        }
    }
    // }

    .external-source-wrapper {
        max-width: unset !important;

        p {
            display: inline-block;
            vertical-align: top;
            margin: 0;
            font-size: 16px;
            letter-spacing: -0.3px;
            color: rgba(25, 25, 25, 0.8);
            transform: translateY(-2px);
        }

        .external-source-switch-wrapper {
            // position: absolute;
            // top: 0;
            // right: 0;
            display: inline-block;
            vertical-align: top;
            margin: 0 8px 0 0;

            .custom-switch-wrapper {
                margin: 0;
                height: 20px;
                width: 32px;

                .custom-switch:before {
                width: 12px;
                height: 12px;
                bottom: 4px;
                transform: translateX(-4px);
                }
            }

            input:checked+.custom-switch:before {
                transform: translateX(12px);
            }
        
            &[disabled] {
                input:checked + .custom-switch {
                background-color: #19191933;
                }
            }
        }
    }

    .tone-form-wrapper {
        margin-top: 32px;

        .tone-wrapper {
            margin-top: 4px;
            
            .tone-list-wrapper {
                display: inline-block;
                width: 196px;

                .tone-list-marker-wrapper {
                    display: block;
                    position: relative;
                    width: 100%;
                    height: 16px;

                    .tone-list-line {
                        width: 100%;
                        height: 4px;
                        transform: translate(6px, 6px);
                    }

                    .tone-list-dot {
                        position: absolute;
                        top: 0px;
                        left: 0px;
                        width: 16px;
                        height: 16px;
                        border-radius: 8px;
                        transition: 0.2s;
                        cursor: pointer;

                        &:hover {
                            background: #FF7E00 !important;
                            transition: 0.2s;
                        }
                    }

                    .bg-orange {
                        background: #FF7E00;
                        box-shadow: 0px 3px 5px 0px #00000033;
                    }

                    .bg-light-orange {
                        background: #FFCB99;
                    }

                    .bg-gray {
                        background: #F3F3F3;
                    }
                }

                .tone-list-label-wrapper {
                    display: block;
                    margin-top: 4px;
                    width: 100%;
                    font-size: 14px;
                    line-height: 21px;
                    letter-spacing: -0.2px;
                    color: #191919;

                    svg {
                        margin-left: 4px;
                        font-size: 16px;
                        transform: translateY(-2px);
                        cursor: pointer;
                    }
                }

                &:last-child {
                    .tone-list-marker-wrapper .tone-list-line {
                        display: none;
                    }
                }
            }
            
        }
    }

    .button-save {
        margin-top: 32px;
        width: 180px !important;
    }
    
    .language-bulk-wrapper {
        margin-top: 32px;
        max-width: 864px !important;
    }
}

.tooltip-tone {
    .bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
        border-bottom-color: #6B6B6B !important;
    }
    
    .tooltip.show {
        opacity: 1 !important;
        margin-top: 4px;
    }
    
    .tooltip-inner {
        padding: 8px 8px !important;
        min-width: 224px !important;
        font-family: "PTRootUI" !important;
        font-size: 12px !important;
        line-height: 16px !important;
        letter-spacing: -0.2px !important;
        border-radius: 8px !important;
        color: #FFFFFF !important;
        background: #6B6B6B !important;
        opacity: 1 !important;
    }
}
