.chatbot-main-wrapper {
    .btn {
        border: 0;
    }

    tBody {
        .col_key {
            color: rgba(25, 25, 25, 0.8);
            cursor: pointer;
    
            svg {
                color: rgba(25, 25, 25, 0.8);
                position: relative;
                top: -2px;
                right: unset;
                margin-right: 4px;
                font-size: 16px;
            }

            &:hover {
                color: #ff7e00;

                svg {
                    color: #ff7e00;
                }
            }
        }
        
        .orange-link-text {
            svg {
                right: unset !important;
            }
        }
    }

    .search-and-filter-wrapper {
        width: 100%;
        display: block;
        margin: 12px 0;
        position: relative;

        .search-box-wrapper {
            width: 370px;
        }

        .dropdown-filter-status {
            display: inline-block;
            position: absolute;
            left: 382px;
            top: 0;
        }
    }
}

.chatbot-list-title {
    color: #191919;
    font-size: 20px;
    letter-spacing: -0.6px;
    line-height: 24px;
    padding-bottom: 16px;
    border-bottom: solid 1px rgba(25, 25, 25, 0.1);
}

.chatbot-list-tool-wrapper {
    width: 100%;
    position: relative;
    margin-top: 24px;
    margin-bottom: 32px;
}

.chatbot-list-info {
    font-size: 16px;
    letter-spacing: -0.3px;
    color: rgba(25, 25, 25, 0.8);
    line-height: 1.5;
    padding: 0 208px 18px 0;
    border-bottom: solid 1px #dcdcdc;
    margin: 0 0 24px;
}

.chatbot-list-tool-wrapper button.button-invite-member {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(0, -50%);
    padding: 11px 0;
    width: 200px;
}

.chatbot-list-tool-wrapper button.button-invite-member svg {
    position: relative;
    top: -1px;
    margin-right: 5px;
}

.chatbot-list-table-wrapper {
    width: 100%;
    position: relative;
}

.table-chatbot-list thead tr {
    background: #ffffff;
    // border-top: solid 1px #dcdcdc;
}

.table-chatbot-list tbody td {
    background: #ffffff;
    position: relative;
}

.table-chatbot-list button svg {
    position: relative !important;
    right: unset !important;
    top: -2px !important;
    margin-right: 5px;
}

.table-chatbot-list .col-action-button-wrapper {
    position: absolute;
    right: 7px;
    top: 50%;
    transform: translate(0, -50%);
    /* display: none; */
}

.table-chatbot-list .single-remove-button {
    margin-left: 16px;
    color: #ff3f57;
}

.table-chatbot-list .single-remove-button:hover {
    color: #e83b51;
}

.table-chatbot-list .shortcut-badge {
    font-size: 12px;
    color: rgba(25, 25, 25, 0.6);
    letter-spacing: -0.4px;
    background: #f3f3f3;
    line-height: 1;
    border-radius: 4px;
    padding: 1px 8px 3px;
}

.table-chatbot-list td {
    max-width: unset !important;
}

.table-chatbot-list .col-action-button-wrapper {
    display: block !important;
    position: relative;
    top: 0;
    right: unset;
    transform: unset;
}

.table-chatbot-list .col-action-button-wrapper .view-agent {
    color: #ff7d00;
    letter-spacing: -0.5px;
}

.table-chatbot-list .col-action-button-wrapper .view-agent:hover {
    color: var(--main-darker-orange);
}

.table-chatbot-list .col-action-button-wrapper img.view-agent {
    margin-right: 3px;
    position: relative;
    top: -1px;
}

.member-tool-wrapper {
    width: 460px;
}

.table-chatbot-list {

    tr {
        cursor: pointer;
    }

    tr:hover {

        .orange-link-text { 
            color: var(--main-darker-orange) !important;

            svg {
                color: var(--main-darker-orange) !important;
            }
        }
    }

    .col_action {
        width: 200px;
        text-align: right;
    }

    .col_status {

        b {
            margin: 0;
            font-size: 16px;
            line-height: 24px;
            letter-spacing: -0.5px;
            color: #191919;

            svg, img {
                margin-right: 4px;
                width: 20px;
                height: 20px;
                transform: translateY(-1.5px);
                position: relative !important;
                top: unset !important;
                right: unset !important;
            }

            svg {
                position: unset;
            }
        }
    
        .status-not-paid {
            color: #FF3F57;
        }

        .status-waiting-setup {
            color: #FF7E00;
        }

        .status-provisioning {
            color: #2085CC;
        }

        .status-active {
            color: #19C700;
        }

        .status-others {
            color: #19191999;

            svg {
                color: #19191999;
            }
        }
    }
}

.modal-view-chatbot {
    .modal-content {
        width: 476px;
        border-radius: 8px;

        .modal-body {
            text-align: left;
            padding: 32px;

            .disabled-form {
                position: relative;

                p {
                    top: 0;
                    left: 0;
                    border: 1px solid rgba(25, 25, 25, 0.1) !important;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    padding: 0 46px 0 16px;
                    border-radius: 8px;
                    background: #f3f3f3;
                    margin: 0;
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    padding-top: 10px;
                }
            }

            .modal-title {
                font-size: 24px;
                line-height: 28px;
                letter-spacing: -0.6px;
                margin-bottom: 8px;
                margin: 0 0 24px 0;

                svg {
                    margin-right: 4px;
                    position: relative;
                    top: -1px;
                }
            }

            .disabled-form {
                width: 100%;
                
                input {
                    width: 100%;
                }
            }

            button {
                width: 100%;
                margin-top: 20px;
            }
        }
    }
}