.inbox-settings {
    .button-save {
        margin-top: 28px;
    }

    .status-wrapper {
        font-size: 16px;

        .status-value {
          color:rgba(25, 25, 25, 0.4);
          font-size: 16px;
          letter-spacing: -0.5px;

          &.status-enabled {
            color: #19C700;
          }
        }

        img {
          position: relative;
          top: -2px;
          margin-right: 4px;
        }
    }

    .inbox-settings-header {
        font-size: 20px;
        letter-spacing: -0.6px;
        color: #191919;
        padding-bottom: 11px;
        border-bottom: solid 1px #dcdcdc;
    }

    .inbox-settings-form {
        padding-top: 24px;
        position: relative;

        .closing-welcome-switch-wrapper {
            position: absolute;
            top: 38px;
            right: 0;
            vertical-align: top;
            display: inline-block;
            margin: 0 16px;
            
            .custom-switch-wrapper {
                margin: 0;
                height: 28px;
                width: 48px;

                .custom-switch:before {
                    width: 15px;
                    height: 15px;
                    bottom: 7px;
                }
            }
            
            input:checked + .custom-switch:before {
                transform: translateX(17px);
            }
        }

        .inbox-settings-form-content {
            display: block;
            width: 100%;
            font-size: 16px;
            letter-spacing: -0.5px;
            border-bottom: solid 1px #dcdcdc;
            margin-bottom: 20px;
            position: relative;
            padding-right: 124px;

            button {
                position: absolute;
                right: 0;
                top: 0;
                margin: 0;
                display: inline-block;
                width: unset;
            }

            &.content-need-reply {
                margin: 0;
            }

            &.content-last-item {
                margin: 0;
            }

            .need-reply-val {
                letter-spacing: -0.5px;
                font-size: 16px;
                color: rgba(25, 25, 25, 0.6);
            }

            p {
                margin: 4px 0 10px;
                font-size: 14px;
                letter-spacing: -0.2px;
                color: rgba(25, 25, 25, 0.8);
            }

            label {
            }

            textarea {
                width: 100%;
                border-radius: 8px;
                font-size: 16px;
                letter-spacing: -0.3px;
                border: solid 1px #dcdcdc;
                resize: none;
                padding: 12px 16px;
                line-height: 24px;
                margin: 0;
                max-width: 416px;
                width: 100%;
                display: block;

                &:focus {
                    border-color: #ff7d00;
                }

                &[disabled] {
                    color: rgba(25, 25, 25, 0.4);
                    background: rgba(25, 25, 25, 0.1);
                }
            }
        }
    }
}