.integration-livechat-list {
  text-align: left;

  .integration-list-loading-wrapper {
    .lds-ring {
      width: 50px;
      height: 50px;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      right: unset;

      div {
        border: 5px solid #fff;
        border-color: #ff7d00 transparent transparent transparent;
        width: 50px;
        height: 50px;
        margin: 0;
      }
    }
  }

  .integration-list-header {
    position: relative;
    padding-left: 42px;
    padding-bottom: 26px;
    border-bottom: solid 1px rgba(25, 25, 25, 0.1);

    .integration-list-header-icon {
      position: absolute;
      top: 0;
      left: 0;
      // text-align: left;
      width: 32px;
    }

    .integration-list-header-text-wrapper {
      text-align: left;

      b {
        font-size: 20px;
        letter-spacing: -0.6px;
      }

      p {
        margin: 0;
        font-size: 14px;
        letter-spacing: -0.2px;
      }

      a {
        color: #ff7d00;
      }
    }

    .integration-list-header-button {
      position: absolute;
      right: 0;
      top: 0;
      font-size: 16px;
      letter-spacing: -0.5px;
      // padding: 7px 32px;

      // img {
      //   margin-right: 4px;
      //   position: relative;
      //   top: -2px;
      // }
    }
  }

  .integration-list-no-data {
    width: 100%;
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    b {
      font-size: 20px;
      letter-spacing: -0.6px;
      color: #191919;
    }

    p {
      color: rgba(25, 25, 25, 0.6);
      letter-spacing: -0.6px;
      font-size: 16px;
    }
  }

  .integration-list-outer-wrapper {
    position: relative;
    width: 100%;
    padding-top: 24px;
    text-align: left;

    .integration-list-inner-wrapper {
      width: calc((100% / 3) - 8px);
      border-radius: 8px;
      margin: 0 12px 12px 0;
      box-shadow: 0 2px 20px 0 rgba(25, 25, 25, 0.05);
      background: #ffffff;
      display: inline-block;
      vertical-align: top;
      padding: 24px 30px 24px 24px;
      cursor: pointer;
      position: relative;

      // p:nth-child(2) {
      //   font-size: 16px;
      //   letter-spacing: -0.5px;
      //   color: rgba(25, 25, 25, 0.6);
      //   margin: 0;
      //   white-space: nowrap;
      //   max-width: 100%;
      //   overflow: hidden;
      //   text-overflow: ellipsis;
      // }

      // span {
      //   display: block;
      //   font-size: 12px;
      //   letter-spacing: -0.2px;
      //   color: #191919;
      // }

      // p {
      //   margin: 10px 0 0;
      //   color: #ff7d00;
      //   font-size: 12px;
      //   letter-spacing: -0.2px;
      // }

      // svg {
      //   position: absolute;
      //   right: 12px;
      //   top: 12px;
      //   font-size: 22px;
      //   color: rgba(25, 25, 25, 0.6);
      // }

      // &:hover {
      //   box-shadow: 0 2px 20px 0 rgba(25, 25, 25, 0.18);

      //   p:nth-child(2) {
      //     color: #191919;
      //   }

      //   svg {
      //     color: #ff7d00;
      //   }
      // }

      .platform-wrapper {
        display: flex;
        align-items: center;

        .platform-name {
          // text-transform: capitalize;
          font-size: 16px;
          letter-spacing: -0.5px;
          color: rgba(25, 25, 25, 0.6);
          margin: 0;
          white-space: nowrap;
          max-width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        img {
          margin-right: 5px;
        }
      }

      span {
        display: block;
        font-size: 12px;
        letter-spacing: -0.2px;
        color: #191919;
        white-space: nowrap;
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      svg {
        position: absolute;
        right: 12px;
        top: 12px;
        font-size: 22px;
        color: rgba(25, 25, 25, 0.6);
      }

      &:hover {
        box-shadow: 0 2px 20px 0 rgba(25, 25, 25, 0.18);

        .platform-name {
          color: #191919;
        }

        svg {
          color: #ff7d00;
        }
      }
    }
  }

  .link-livechat-account-list {
    &:nth-child(3n) {
      .integration-list-inner-wrapper {
        margin: 0 0 12px 0;
      }
    }
  }
}
