.custom-text-editor {
    width: 100%;
    border-radius: 8px;
    position: relative;
    font-size: 0;
    
    .input-counter {
        position: absolute;
        top: -28px;
        right: 0;
        font-size: 14px;
        letter-spacing: -0.2px;
    }

    textarea, input[type="text"] {
        width: 100% !important;
        border-radius: 8px 8px 0 0 !important;
    }

    .text-editor-button-action-wrapper {
        border: solid 1px #dcdcdc;
        border-top: 0;
        border-radius: 0 0 8px 8px;
        padding: 12px 16px 12px;
        
        .button-action {
            display: inline-block;
            height: 22px;
            border-radius: 4px;
            border: solid 1px #dcdcdc;
            text-align: center;
            padding: 0 6px;
            margin: 0;
            width: unset;
            margin-right: 4px;
            line-height: 11px;
            vertical-align: top;
            background: #ffffff;
            transition: ease-in-out .1s;

            svg {
                font-size: 12px;
                color: rgba(25,25,25,0.6);
            }

            &:hover {
                background: rgba(25,25,25,0.05);
            }

            &.add-variable {
                font-size: 12px;
                letter-spacing: -0.4px;
                color: rgba(25,25,25,0.6);
                padding: 0 12px 0 10px;
                position: relative;

                svg {
                    position: relative;
                    top: -1px;
                    margin-right: 2px;
                }

                &:hover {
                    color: #ffffff;
                    background: #ff7e00;
                    border: solid 1px #ff7e00;

                    svg {
                        color: #ffffff;
                    }
                }

                &.active-add-variable {
                    color: #ffffff;
                    background: #ff7e00;
                    border: solid 1px #ff7e00;

                    svg {
                        color: #ffffff;
                    }
                }
            }
        }

        .add-variable-wrapper {
            position: relative;
            display: inline-block;

            .button-close {
                position: absolute;
                top: 10px;
                right: 12px;
                font-size: 14px;
                cursor: pointer;
                color:rgba(25, 25, 25, 0.7);

                &:hover {
                    color:rgba(25, 25, 25, 1);    
                }
            }

            .add-variable-panel-wrapper {
                position: absolute;
                left: 0;
                bottom: 26px;
                background: #ffffff;
                border-radius: 8px;
                color: #191919;
                box-shadow: 0px 2px 20px rgba(25, 25, 25, 0.18);
                padding: 16px 24px;
                width:350px;
                display: inline-block;
                text-align: left;
                z-index: 1;

                .add-variable-panel-title {
                    font-size: 14px;
                    letter-spacing: -0.4px;
                    background: #ffffff;
                }

                .variable-input {
                    position: relative;
                    margin-top: 9px;

                    input {
                        border-radius: 8px !important;
                        padding-right: 111px;

                        &:focus {
                            border: solid 1px #ff7e00;
                        }
                    }

                    textarea {
                        border-radius: 8px !important;
                    }

                    button {
                        position: absolute;
                        font-size: 12px;
                        letter-spacing: 2.5px;
                        height: 24px;
                        display: inline-block;
                        border-radius: 4px;
                        width: unset;
                        padding: 0 12px 0 10px;
                        right: 16px;
                        top: 12px;
                        margin: 0;

                        svg {
                            margin-right: 4px;
                            position: relative;
                            top: -1px;
                            font-size: 14px;
                        }
                    }
                }

                ul {
                    list-style: none;
                    border-radius: 8px;
                    background: rgba(25,25,25,0.05);
                    margin: 12px 0 0;
                    padding: 8px 0 0;

                    li {
                        font-size: 14px;
                        letter-spacing: -0.4px;
                        margin: 0;
                        padding: 0 16px 8px 16px;
                    }
                }
            }
        }
    }
}