.sort-table-header {
    cursor: pointer;
    position: relative;
    padding: 8px 20px 8px 8px !important;

    .ascending-descending-table-wrapper {
        position: relative;
        // top: 50%;
        // right: 8px;
        // transform: translate(0, -50%);
        margin-left: 8px;
    }

    .sort-table-tooltip-wrapper {
        position: relative;
        display: inline-block;
        margin-left: 4px;
        height: 30px;
        width: 10px;
        vertical-align: top;
        top: -4px;
        right: -4px;

        svg {
            color: rgba(25, 25, 25, 1);
            outline: none !important;
            font-size: 12px;
        }
    }

    
}