.modal-interactiveList-message {
    .form-label {
      label {
        width: 100%;
        position: relative;
  
        b {
          font-size: 16px;
          letter-spacing: -0.3px;
        }
        
        &.label-header-type {
          b {
            font-size: 14px;
          }
        }
  
        .grey-font {
          position: absolute;
          top: 1px;
          right: 0;
        }
  
        .label-sub {
          display: block;
          margin: 4px 0 0;
          width: 100%;
          position: relative;
          top: unset !important;
          right: unset !important;
          color: rgba(25, 25, 25, 0.6) !important;
        }
  
        .text-optional {
          position: relative;
          top: unset;
          right: unset;
        }
      }
  
      .form-value {
        font-size: 16px;
        letter-spacing: -0.3px;
        margin-bottom: 0;
        color: rgba(25, 25, 25, 0.8);
      }
    }
  }
  
  .modal-interactiveList-message {
    position: fixed;
    top: 0;
    left: 0;
    width: 100% !important;
    height: 100%;
    background: rgba(25, 25, 25, 0.8);
    visibility: hidden;
    opacity: 0;
    right: 0;
    transition: visibility 0.3s, opacity 0.5s ease-in-out;
    z-index: 5;
  
    &.active-modal-interactiveList-message {
      /* right: 0; */
      opacity: 1;
      visibility: visible;
      transition: visibility 0s, opacity 0.5s ease-in-out;
    }
  
    .modal-body {
      position: absolute;
      top: 8px;
      right: -100%;
      left: unset;
      transform: unset;
      height: calc(100%);
      width: 640px;
      display: inline-block;
      border-radius: 8px 0 0 0;
      background: #ffffff;
      padding: 0;
      max-height: calc(100%);
      // height: 100%;
      transition: ease-in-out 0.4s;
  
      &.action-modal-body-interactiveList-message {
        right: 0;
      }
  
      .close-modal-interactiveList {
        border-radius: 50%;
        width: 48px;
        height: 48px;
        background: #ffffff;
        color: #757575;
        position: absolute;
        left: -65px;
        top: 0;
        text-align: center;
        cursor: pointer;
  
        &:hover {
          background: #f9f9f9;
        }
  
        svg {
          position: relative;
          font-size: 25px;
          top: 11px;
        }
      }

      .interactiveList-message-wrapper {
        height: calc(100%);
        max-height: calc(100%);
        overflow: hidden;
        right: 0;
        transition: ease-in-out .5s;
        position: relative;
        display: block;

        .interactive-section-list {
          margin: 24px 0 0;
      
          .table-title {
            font-size: 16px;
            letter-spacing: -0.5px;
            margin: 24px 0 8px 0;

            &:nth-child(1) {
              margin: 16px 0 8px 0;
            }
          }
      
          table {
            width: 100%;
            border-collapse: collapse;
          }
      
          thead {
            tr {
              border-bottom: solid 1px #dcdcdc;
            }
      
            td {
              font-size: 14px;
              letter-spacing: -0.4px;
              padding: 8px;

              &:nth-child(1) {
                width: 50px;
              }
      
              &:nth-child(2) {
                width: calc(50%);
              }
      
              &:nth-child(3) {
                width: calc(50%);
              }
            }
          }
      
          tbody {
            tr {
              &:nth-child(even) {
                background: #f3f3f3;
              }
            }
            td {
              font-size: 14px;
              letter-spacing: -0.2px;
              padding: 8px;
              word-break: break-word;
              vertical-align: top;
            }
          }
        }
  
        & > div > div {
          overflow-x: hidden !important;
        }
  
        &.interactiveList-message-wrapper-deactive {
          right: -700px;
          display: none;
        }
  
        .comp-back-button {
          margin: 0 0 24px;
        }
  
        label {
          display: inline-block !important;
        }
  
        .interactiveList-message-container {
          padding: 32px;
  
          .interactiveList-message-title {
            color: #191919;
  
            svg {
              width: 24px;
              position: relative;
              top: -7px !important;
              margin-right: 4px !important;
            }
  
            b {
              font-size: 20px;
              letter-spacing: -0.6px;
            }
  
            svg {
              font-size: 24px;
              margin: 0 4px 0 0;
              position: relative;
              top: -2px;
            }
  
            p {
              margin: 2px 0 0;
              color: rgba(25, 25, 25, 0.6);
              font-size: 14px;
              letter-spacing: -0.2px;
            }
          }
        }
      }
    }
  }
  