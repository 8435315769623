.modal-handover-case {
    .modal-content {
        width: 500px;
        max-height: 90%;
        // overflow: auto;
        border-radius: 8px;

        .modal-body {
            padding: 32px;
            border-radius: 8px;

            .modal-handover-description {
                color: rgba(25, 25, 25, 0.6);
                font-size: 14px;
                letter-spacing: -0.2px;
                margin-bottom: 0;
            }

            label {
                font-size: 14px;
                letter-spacing: -0.4px;
                margin-top: 24px;
                letter-spacing: -0.34px;
            }

            .modal-handover-case-title {
                font-size: 20px;
                letter-spacing: -0.6px;
                margin: 0;
            }

            .handover-case-button-wrapper {
                width: 100%;

                button {
                    margin-top: 20px;
                    width: calc(50% - 4px);
                    display: inline-block;
                    margin-right: 8px;

                    &:nth-child(2) {
                        margin-right: 0;
                    }
                }
            }
        }
    }
}