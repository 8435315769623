#page-account-my-profile {
    font-weight: bold;
    letter-spacing: -0.4px;
    color: #191919;
}

#page-account-my-profile .my-profile-input {
    position: relative;

    .form-separator {
        margin-top: 16px;
        border-top: solid 1px rgba(25, 25, 25, .1);
    }

    b {
        &:nth-child(2) {
            font-size: 20px;
            letter-spacing: -0.6px;
            line-height: 1;
        }
    }
}

#page-account-my-profile .my-profile-input input[type="file"] {
    display: none;
}

#page-account-my-profile .my-profile-input .profile-avatar-wrapper {
    width: 48px;
    height: 48px;
    box-sizing: border-box;
    background: #ffffff;
    border-radius: 8px;
}

#page-account-my-profile .my-profile-input .profile-avatar-wrapper .no-fill-button {
    padding: 0 32px 0;
    transition: east-in-out 0.1s;
    font-size: 16px;
    text-align: center;
    cursor: pointer;
    position: relative;
    top: -45px;
    margin-left: 60px;
    height: 40px;
    vertical-align: top;
    display: inline-block;
    width: 192px;
    line-height: 38px;

    img {
        width: 20px;
        margin: 0 4px 0 0;
        position: relative;
        top: -2px;
    }
}

#page-account-my-profile .my-profile-input .profile-avatar-wrapper img {
    width: 100%;
}

#page-account-my-profile .my-profile-input:last-child {
    padding-bottom: 0;
}

#page-account-my-profile .my-profile-input label {
    display: block;
    color: #191919;
}

#page-account-my-profile .my-profile-input span {
    font-size: 14px;
    letter-spacing: -0.2px;
    color: #191919;
    font-weight: 100;
    display: block;
    margin-bottom: 25px;
    margin-top: 3px;
}

#page-account-my-profile .my-profile-input input {
    margin-top: 1px;
    border: solid 1px #dcdcdc;
    font-size: 16px;
    letter-spacing: -0.2px;
    border-radius: 8px;
    width: 310px;
}

#page-account-my-profile .my-profile-input button {
    padding: 0 32px;
    height: 40px;
    line-height: 37px;
    transition: east-in-out 0.1s;
    font-size: 16px;
    text-align: center;
    letter-spacing: -0.5px;
    font-size: 16px;
}

#page-account-my-profile .my-profile-input button img {
    width: 20px;
    height: 20px;
    position: relative;
    margin-right: 4px;
    top: -2px;
}

#page-account-my-profile .account-setting-card {
    /* box-shadow: 0 1px 0 0 rgba(25, 25, 25, .1); */
    background-color: #ffffff;
}


#page-account-my-profile .account-setting-card .account-setting-card-title {
    padding-bottom: 18px;
    font-size: 20px;
    font-weight: bold;
    color: #191919;
    letter-spacing: -0.6px;
    margin-bottom: 24px;
    box-shadow: 0 1px 0 0 rgba(25, 25, 25, .1);
    position: relative;

    .account-setting-save-wrapper {
        padding-top: 0;
        position: absolute;
        right: 0;
        top: 0;
    }
}

#page-account-my-profile .account-setting-save-wrapper {
    padding-top: 24px;
}

.modal-edit-profile {
    .modal-content {
        border-radius: 8px;
        padding: 32px;
    }

    .modal-body {
        padding: 0;
        line-height: 1;

        .edit-profile-title {
            font-size: 20px;
            letter-spacing: -0.6px;
            margin: 0;
        }

        input {
            width: 100%;
        }
    }

    .modal-footer {
        padding: 24px 0 0;
        border: 0;

        button {
            width: calc(50% - 4px);
            margin: 0 8px 0 0;
            height: 48px;

            &:nth-last-child(1) {
                margin: 0;
            }
        }
    }
}