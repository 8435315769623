.broadcast-modal-contact {
    .modal-content {
        border-radius: 8px 0 0 0;
        bottom: 0;
        right: 0;
        position: fixed;
        width: 550px;
        max-width: calc(100% - 110px);
        height: calc(100% - 12px);
        left: unset;
        top: unset;
        transform: unset;
    }

    .modal-body {
        position: relative;
        overflow-x: visible;
        padding: 0;
        height: 100%;

        .broadcast-modal-content {
            padding: 32px;
            max-height: 100%;
            overflow-y: auto;
        }

        .broadcast-modal-close {
            width: 46px;
            height: 46px;
            border-radius: 50%;
            background: #ffffff;
            position: absolute;
            top: 0;
            left: -56px;
            cursor: pointer;

            svg {
                font-size: 20px;
                color: rgba(25, 25, 25, .6);
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }

            &:hover {
                svg {
                    color: rgba(25, 25, 25, .8);
                }
            }
        }

        .contact-fullname {
            font-size: 20px;
            letter-spacing: -0.6px;
            padding: 0 0 12px;
            margin: 0 0 20px;
            border-bottom: solid 1px #dcdcdc;

            svg {
                position: relative;
                top: -2px;
            }
        }

        .contact-label {
            font-size: 14px;
            letter-spacing: -0.4px;
            margin: 0 0 6px;

            svg {
                position: relative;
                top: -2px;
            }
        }

        .contact-value {
            font-size: 16px;
            margin: 0 0 22px;
            letter-spacing: -0.3px;

            &:nth-last-child(1) {
                margin: 0;
            }
        }
    }
}