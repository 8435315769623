.verify-card-wrapper {
    .verify-card-title {
        font-size: 20px;
        letter-spacing: -0.6px;
        color: #191919;
        padding-bottom: 12px;
        border-bottom: solid 1px #dcdcdc;
    }

    .iframe-card-wrapper {
        width: 100%;
        margin-top: 12px;
        // background: #dcdcdc;
        height: 500px;
        position: relative;

        .loading-add-card-wrapper {
            position: absolute;
            width: 100%;
            text-align: center;
            left: 0;
            top: 50%;
            transform: translate(0, -50%);
            color: #ff7d00;
            font-size: 20px;
            letter-spacing: -0.6px;

            .LoadingRoll_1234fed {
                height: unset;
                margin-bottom: 24px;
                
                .lds-ring {
                    width: 64px;
                    height: 64px;
                    position: relative;
                    top: unset;
                    left: unset;
                    transform: unset;
                    
                    div {
                        width: 64px;
                        height: 64px;
                    }
                }
            }
        }

        iframe {
            width: 100%;
            height: 100%;
        }
    }
} 