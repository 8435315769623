.solved-case-tab-wrapper {
  position: relative;
  /* top: 1px; */
  height: calc(100vh - 109px);
  background: #f3f3f3;
  width: calc(100% - 62px);
	display: inline-block;
  overflow: hidden;

  .case-room-search-wrapper {
    display: none;
  }

  .case-room-filter-tag-badge {
    margin-top: 6px;
    color: rgba(25, 25, 25, 0.6);

    img {
			position: relative;
			top: -1px;
			margin-right: 3px;
		}

    svg {
      color: rgba(25, 25, 25, 0.6);
    }
  }
}

.solved-case-room-wrapper {
  height: calc(100vh - 191px);
  position: relative;
  /* max-height: calc(100vh - 192px); */
  background: #f3f3f3;
}

.solved-case-tab-wrapper #input-search-form {
  input::placeholder {
    font-size: 13px;
    color: rgba(25, 25, 25, 0.4);
  }
}

.solved-case-room-wrapper .lds-ring {
  margin-right: 5px;
  top: 3px !important;
  right: 0;
  right: 5px;
}

.case-modal-calendar .modal-content {
  width: 690px;
}

.modal-date-filter-title {
  // border-bottom: solid 1px #dcdcdc;
  font-size: 20px;
  letter-spacing: -0.6px;
  padding-bottom: 6px;
  margin-bottom: 0;

  svg {
    position: relative;
    top: -2px;
    margin-right: 12px;
  }
}

.case-modal-calendar .modal-body {
  border: none;
  padding: 32px 32px 0;
}

.case-modal-calendar .modal-footer {
  border: none;
  padding: 0 32px 32px;
}

.case-modal-calendar .modal-footer button {
  // width: calc(50% - 4px);
  height: 48px;
  padding: 0 24px;
  margin: 0 8px 0 0;
}

.case-modal-calendar .modal-footer button:nth-last-child(1) {
  margin: 0;
}

.fetch-solved-case {
  width: 100%;
  position: relative;
  text-align: center;
  /* background: pink; */
  height: 45px;
  margin-bottom: 57px;
}

.fetch-solved-case .lds-ring {
  top: 7px !important;
  right: unset;
  margin-right: 5px;
  vertical-align: top;
}
.fetch-solved-case .lds-ring div {
  margin: 0;
}

.fetch-solved-case-content {
  background: transparent;
  width: calc(100% - 10px);
  border-radius: 8px;
  border: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* background: lightblue; */
  height: 35px;
  /* cursor: default !important; */
  font-size: 14px;
  color: #ff7d00;
  line-height: 32px;
}

.fetch-solved-case-content.retry-fetch {
  cursor: pointer;
  border: solid 1px #ff7d00;
}

.fetch-solved-case-content.retry-fetch svg {
  position: relative;
  top: -1px;
  margin-right: 5px;
  font-size: 18px;
}

.DayPicker:not(.DayPicker--interactionDisabled) .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
  background-color: rgba(25, 25, 25, 0.05) !important; 
  border-radius: 50% !important;
  color: #191919;
}

.Selectable .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
  background-color: #FFECD9 !important;
  color: #ff7d00;
}

.Selectable .DayPicker-Day {
  border-radius: 0 !important;
  outline: 0;
}

.Selectable .DayPicker-Day--start {
  border-top-left-radius: 50% !important;
  border-bottom-left-radius: 50% !important;
}

.Selectable .DayPicker-Day--end {
  border-top-right-radius: 50% !important;
  border-bottom-right-radius: 50% !important;
}