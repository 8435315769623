.source-document-wrapper {
    border: dashed 2px #1919191A;
    border-radius: 8px;


    .source-document-content-wrapper {
        padding: 32px 32px 54px 32px;
        text-align: center;
        font-size: 14px;
        line-height: 21px;
        letter-spacing: -0.2px;
        background: #fafafa;

        .icon-cloud {
            color: var(--main-dark-orange);
            font-size: 64px;
            display: inline-block;
            margin: 0 0 16px 0;
        }

        .chat-room-input-email-placeholder {
            display: block;
            width: 100%;
            text-align: center;
        }
        
        .main-button-40 {
            margin: 16px 0 0;
            background: white;

            &:hover {
                background-color: rgba(255, 126, 0, 0.05);
            }
        }
    }
}
