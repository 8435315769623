#page-error404 {
    position: fixed;
    width: 100vw;
    height: 100vh;
    background: #f9f9f9;
}

#page-error404.page-404-after-login {
    padding-top: 87px;
}

.page-404-content {
    position: absolute;
    display: inline-block;
    text-align: center;
    font-size: 25px;
    font-weight: bold;
    letter-spacing: -0.4px;
    color: #191919;
    line-height: 1;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.page-404-content p {
    font-size: 16px;
    font-weight: normal;
    margin: 10px 0;
}

/* .page-404-content.not-login {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.page-404-content.after-login {
    top: 110px;
    left: 50%;
    transform: translate(-50%, 0);
} */

.page-404-content button {
    padding: 10px 30px 11px;
    font-size: 16px;
    line-height: 1;
    position: relative;
}

.page-404-content button svg {
    position: absolute;
    font-size: 23px;
    left: 22px;
    top: 7px;
}

.page-404-content img {
    width: 400px;
    margin-bottom: 30px;
}
