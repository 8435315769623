.no-chat-container {
    width: 100%;
    text-align: center;
    padding-top: 250px;
    display: inline-block;
  }
  .no-chat-container .no-chat-content {
    display: inline-block;
    padding: 15px 16px;
    background: #dcebff;
    border-radius: 8px;
    color: #044b84;
    font-size: 16px;
    letter-spacing: -0.5px;
    /* line-height: 48px; */
    max-width: calc(100% - 100px);
  }
  .no-chat-container .no-chat-content svg {
    font-size: 18px;
    margin-right: 6px;
    position: relative;
    top: -2px;
  }