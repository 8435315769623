.tooltip-dropdown-button-wrapper {
  position: relative;
  display: inline-block;

  .tooltip-dropdown {
    background-color: #ffffff;
    position: absolute;
    width: 165px;
    right: 50%;
    transform: translateX(50.5px);
    border-radius: 8px;
    top: calc(100% + 21px);
    box-shadow: 0 5px 12px 0 rgba(0, 0, 0, 0.16),
      0 2px 5px 0 rgba(0, 0, 0, 0.16);
    ul {
      padding: 0;
      margin: 0;
    }
    li {
      padding: 8px;
      font-size: 14px;
      letter-spacing: -0.4px;
      color: rgba(25, 25, 25, 0.6);
      list-style: none;
      cursor: pointer;

      img {
        width: 18px;
        height: 18px;
        margin-right: 6px;
        margin-bottom: 3px;
      }

      svg {
        vertical-align: -2px;
        margin-right: 10px;
        position: relative;
        left: 2px;
      }
    }
    li:hover {
      color: #ff7e00;
    }
  }
  .tooltip-dropdown:after {
    bottom: 100%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }

  .tooltip-dropdown:after {
    border-color: rgba(255, 255, 255, 0);
    border-bottom-color: #ffffff;
    border-width: 9px;
    left: 80%;
    margin-left: -12px;
  }

  .tooltip-dropdown-button {
    display: inline-block;
    /* height: 100%; */
    height: 26px;
  }
  
  .tooltip-dropdown-button button {
    cursor: pointer;
    transition: ease-in-out;
    position: relative;
    /* right: 178px;
      top: 50%;
      transform: translate(0, -50%); */
    font-size: 14px;
    letter-spacing: -0.5px;
    transition: ease-in-out 0.3s;
    border-radius: 6px;
    /* padding: 6px 0 7px; */
    /* line-height: 24px; */
    /* width: 191px; */
    height: 100%;
    margin-right: 8px;
    padding: 0 12px 0 10px !important;
    vertical-align: top;
    line-height: 24px;
    background: #ff7d00;
  }
  
  .tooltip-dropdown-button button svg {
    position: relative;
    /* top: -2px; */
    font-size: 14px;
    /* margin-right: 4px; */
  }
  
  .tooltip-dropdown-button .lds-ring {
    top: 50%;
    transform: translate(-50%, -50%);
    left: 50%;
    right: unset;
    position: absolute;
  }
  
  .tooltip-dropdown-button .lds-ring div {
    margin: 0;
  }
}
