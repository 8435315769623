.account-settings {
    .button-save {
        margin-top: 28px;
    }

    .react-select-custom__input {
        height: 42px;
        position: relative;
        top: -3px;
    }

    .title-default {
        margin: 0 0 8px 0 !important;   

        img {
            position: relative;
            margin-right: 4px;
        }
    }

    .dropdown {
        button {
            width: 100% !important;
        }
    }

    .account-settings-header {
        font-size: 20px;
        letter-spacing: -0.6px;
        color: #191919;
        padding-bottom: 16px;
        border-bottom: solid 1px #dcdcdc;
        position: relative;
        width: 100%;

        button {
            width: 181px;
            position: absolute;
            right: 0;
            top: -4px;
            margin: 0;
        }
    }

    
}