.create-custom-field-main-wrapper {

  .label-left {
    padding-left: 0;
    position: relative;

    .tooltip-icon {
      width: 14px;
      height: 14px;
      margin-left: 4px;
      transform: translateY(-2px);
    }
  }

  .custom-switch-wrapper {
    display: inline-block !important;
    margin-top: 8px !important;
    width: 28px !important;
    height: 16px;
    position: relative !important;

    .custom-switch {
      padding: 0;
    }

    .custom-switch.round {
      transform: translateY(3px);

      &::before {
        width: 10px;
        height: 10px;
        top: 3px;
        left: 4px;
        box-shadow: none;
      }
    }

    input:checked + .custom-switch:before {
        transform: translateX(10px);
    }
  }

  .required-value {
    display: inline-block;
    margin: 0 0 0 4px;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: -0.2px;
    color: #19191999;
  }

  .create-custom-field-header {
    padding-bottom: 20px;
    margin-bottom: 12px;
    border-bottom: 1px solid rgba(25, 25, 25, 0.1);
    position: relative;
    display: block;

    p {
      transform: translateY(6px);
    }

    .grey-font {
      font-size: 16px;
      letter-spacing: -0.6px;
    }

    button {
      position: absolute;
      top: 0;
      right: 0;
      width: 188px;

      b {
        letter-spacing: -0.5px;
      }
    }

    button[disabled] {
      b {
        letter-spacing: -0.5px;
      }
    }

    .main-button-40.with-image-or-loading-left {
      padding: 0 !important;
    }

    .button-delete-custom-field {
      right: 192px;
    }
  }

  .create-custom-field-title {
    font-size: 20px;
    letter-spacing: -0.6px;
    margin: 0;
    color: #191919;
  }

  .create-custom-field-description {
    margin: 8px 0 16px;
    font-size: 16px;
    letter-spacing: -0.3px;
    width: calc(100% - 230px);
    color: #191919;
    line-height: 24px;
  }
}

.create-custom-field-add-wrapper {
  .create-custom-field-add-title {
    font-size: 20px;
    letter-spacing: -0.6px;
    line-height: 1;
  }

  .close-modal-create-custom-field {
    position: absolute;
    font-size: 22px;
    right: 0;
    top: 2px;
    cursor: pointer;
  }

  .form-content-wrapper {
    input, textarea, label {
      width: 100%;
      position: relative;
      max-width: 400px;

      .clear-all {
        color: var(--watermelon);
        cursor: pointer;
        position: absolute;
        right: 0;
        bottom: 0;

        &:hover {
          color: var(--watermelon-dark);
        }
      }
    }

    label {
      margin-top: 32px;
    }

    .field-value {
      margin: -4px 0 0 0;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: -0.3px;
      color: #191919;
    }

    .label-description {
      margin: -4px 0 0 0;
      font-size: 14px;
      line-height: 21px;
      letter-spacing: -0.2px;
      color: #19191999;
    }

    .dropdown {
      max-width: 400px;

      button {
        margin-top: 0px;
      }

      .dropdown-option-wrapper {
        max-height: none;
        border: none;
      }
    }

    .input-counter {
      position: absolute;
      top: 0;
      right: 0;
    }

    b {
      font-size: 16px;
      line-height: 24px;
      letter-spacing: -0.5px;
    }

    .input-char-limit {
      width: 88px;
      text-align: center;
    }

    .optional {
      font-size: 16px;
      line-height: 24px;
      letter-spacing: -0.5px;
      color: #19191966;
    }

    .view-only-input {
      display: block;
      padding: 12px 16px;
      max-width: 400px;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: -0.5px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      color: #191919CC;
      background-color: #1919191A;
      border-radius: 8px;
    }

    .view-options {
      display: block;
      padding: 12px 16px;
      margin: 8px 0 0 0;
      max-width: 400px;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: -0.3px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      color: #191919CC;
      background-color: #F3F3F3;
      border: solid 1px #1919191A;
      border-radius: 8px;
    }

    .required-view {
      margin-top: -4px;
      font-size: 14px;
      line-height: 21px;
      letter-spacing: -0.2px;
      color: #191919;

      img {
        margin-right: 4px;
        transform: translateY(-2px);
      }
    }

    .characters-label {
      margin: 0 0 0 8px;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: -0.3px;
      color: #191919;
    }

    .multiple-option-count {
      margin: 16px 0 4px 0;
      text-align: right;

      svg {
        margin-left: 8px;
        font-size: 16px;
        color: var(--watermelon);
        transform: translateY(-2px);
        cursor: pointer;
      }
    }

    .drag-indicator {
      width: 24px;
      height: 24px;
      position: absolute;
      left: 36px;
      transform: translateY(12px);
      cursor: grab;
    }

    .draggable {
      padding-left: 32px;
    }

    .add-option {
      margin: 16px 0 0;
      color: var(--main-dark-orange);
      cursor: pointer;

      b {
        font-size: 14px;
        line-height: 21px;
        letter-spacing: -0.4px;
      }

      svg {
        position: relative;
        font-size: 12px;
        top: -1.5px;
      }

      &:hover {
        color: var(--main-darker-orange);

        svg {
          color: var(--main-darker-orange);
        }
      }
    }
  }

  .button-create-custom-field {
    width: 100%;
    margin-top: 20px;
  }
}

.tooltip-required {
  .bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
    border-bottom-color: #333333 !important;
  }

  .tooltip.show {
    opacity: 1 !important;
    margin-top: 4px;
  }

  .tooltip-inner {
    padding: 8px !important;
    margin-left: 56px;
    min-width: 266px !important;
    font-family: "PTRootUI" !important;
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 21px !important;
    letter-spacing: -0.2px !important;
    border-radius: 4px !important;
    color: #FFFFFF !important;
    background: #333333 !important;
    opacity: 1 !important;
  }
}

.tooltip-char-limit {
  .bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
    border-right-color: #333333 !important;
  }

  .tooltip.show {
    opacity: 1 !important;
    margin-left: 4px;
  }

  .tooltip-inner {
    padding: 8px !important;
    min-width: 223px !important;
    font-family: "PTRootUI" !important;
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 21px !important;
    letter-spacing: -0.2px !important;
    border-radius: 4px !important;
    color: #FFFFFF !important;
    background: #333333 !important;
    opacity: 1 !important;
  }
}
