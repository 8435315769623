.edit-profile-outer-wrapper {
    .edit-account-profile-google {
        position: relative;
        width: 100%; 

        label {
            display: block;
        }
    
        .edit-account-profile-header {
            display: flex;
            justify-content: space-between;
            border-bottom: 1px solid rgba(25, 25, 25, 0.1);
            margin-bottom: 24px;
            padding-bottom: 12px;

            .title-wrapper {
                b {
                    font-size: 20px;
                }
            }
            
            button {
                margin-top: 0;
                white-space: nowrap;
            }
        }

        textarea, input {
            padding: 12px 16px;
            font-size: 16px;
            letter-spacing: -0.3px;
            border-radius: 8px;
            border: solid 1px #dcdcdc;
        }

        .custom-checkbox {
            top: 2px !important;
            
            label {
                font-size: 14px;
                width: 200px;
                color: #191919;
            }
        }
    }
}