.extra-header-wrapper {
    width: 100%;
    position: relative;
    border-bottom: solid 1px #dcdcdc;
    margin: 30px 0 24px 0;

    .extra-header-title {
        font-size: 20px;
        letter-spacing: -0.6px;
    }

    .extra-header-sub {
        font-size: 14px;
        letter-spacing: -0.2px;
        margin: 0 0 12px 0;
        color: rgba(25, 25, 25, 0.8);
    }
}