.integration-whatsapp-authenticate-now-account {
    padding: 32px 30px;
    text-align: left;

    .comp-back-button {
        margin: 0 0 16px 0;
    }

    .integration-whatsapp-authenticate-now-account-box {
        background: #ffffff;
        border-radius: 8px;
        padding: 32px;
        border: solid 1px rgba(25, 25, 25, 0.1);
        min-height: 400px;
        position: relative;

        .integration-whatsapp-authenticate-now-account-box-top {
            padding-bottom: 15px;
            border-bottom: solid 1px #dcdcdc;
            position: relative;
            font-size: 20px;
            letter-spacing: -0.6px;
        }
    }

    .authenticate-now-loading {
        text-align: center;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);

        .lds-ring {
            width: 60px;
            height: 60px;
            top: unset;
            right: unset;

            div {
                width: 60px;
                height: 60px;
                margin: 0;
                border: 6px solid #fff;
                border-color: #ff7d00 transparent transparent transparent;
            }
        }

        p {
            color: #ff7d00;
            font-size: 16px;
            margin: 12px 0 0;
            letter-spacing: -0.5px;
        }
    }

    .failed-authenticated-now-refresh {
        text-align: center;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);

        svg {
            color: #ff7d00;
            font-size: 61px;
            cursor: pointer;

            &:hover {
                color: #e87200; 
            }
        }

        p {
            color: #ff7d00;
            font-size: 16px;
            margin: 12px 0 0;
            letter-spacing: -0.5px;
        }
    }

    .main-authenticated-now-qr-scan {
        width: 100%;
        position: relative;
        padding-top: 24px;
        
        .qr-code-wrapper {
            width: 264px;
            display: inline-block;
            vertical-align: top;
            text-align: center;

            .qr-code-content {
                color: #ffffff;
                line-height: 264px;
                height: 264px;
                width: 100%;
                position: relative;

                img {
                    &.qr-image {
                        width: 100%;
                        height: 100%;
                        position: absolute;
                        top: 0;
                        left: 0;

                        &.qr-transparent {
                            opacity: 0.3;
                        }
                    }
                }

                .lds-ring {
                    width: 65px;
                    height: 65px;
                    top: 50%;
                    right: unset;
                    position: absolute;
                    left: 50%;
                    transform: translate(-50%, -50%);

                    div {
                        width: 65px;
                        height: 65px;
                        margin: 0;
                        border: solid 5px;
                        border-color: #ff7d00 transparent transparent transparent;
                    }
                }

                .qr-code-retry-wrapper {
                    height: 200px;
                    width: 200px;
                    text-align: center;
                    color: #ffffff;
                    cursor: pointer;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    background: #ff7d00;
                    border-radius: 50%;
                    transform: translate(-50%, -50%);
                    line-height: 1.5;
                    padding: 45px 40px 0;
                    font-size: 16px;
                    letter-spacing: -0.5px;

                    svg {
                        font-size: 40px;
                        margin: 0 0 16px;
                    }

                    &:hover {
                        background: #e87200;
                    }
                }
            }

            .point-phone-here {
                font-size: 12px;
                letter-spacing: -0.4px;
                color: rgba(25, 25, 25, 0.6);
                margin: 0;

                img {
                    position: relative;
                    top: -1px;
                    margin-right: 4px;
                }
            }
        }

        .qr-code-instruction-wrapper {
            width: calc(100% - 264px);
            display: inline-block;
            vertical-align: top;
            padding: 25px 0 0 24px;

            .make-sure-connection {
                font-size: 12px;
                letter-spacing: -0.4px;
                color: rgba(25, 25, 25, 0.6);
                margin: 0;

                svg {
                    color: rgba(25, 25, 25, 0.6);
                    position: relative;
                    top: -1px;
                    margin-right: 4px; 
                }
            }

            .instruction-title {
                letter-spacing: -0.6px;
                font-size: 20px;
                color: #191919;
                margin: 4px 0 22px;
            }

            .instruction-list {
                width: 100%;

                p {
                    font-size: 16px;
                    color: #191919;
                    letter-spacing: -0.3px;

                    span {
                        background: #ff7d00;
                        width: 24px;
                        height: 24px;
                        display: inline-block;
                        text-align: center;
                        color: #ffffff;
                        vertical-align: top;
                        line-height: 27px;
                        border-radius: 50%;
                        font-size: 14px;
                        letter-spacing: -0.4px;
                        margin-right: 8px;
                    }
                }
            }
        }
    }
}