.overview-topic-report-wrapper {
    margin: 30px 0 0 0;

    .overview-topic-report-inner-wrapper {
        padding: 8px;

        .overview-topic-report-title {
            font-size: 20px;
            letter-spacing: -0.6px;
            margin: 0 0 16px 0;
        }

        .table-overview {
            td {
                background: #fff !important;

                .onetalk-shine-left-1 {
                    width: 77px !important;
                    height: 22px !important; 
                    border-radius: 6px;
                    position: relative;
                    top: 3px;
                }

                .onetalk-shine-right {
                    width: 44px;
                    height: 21px;
                    border-radius: 6px;
                    position: relative;
                    top: 4px;
                }
            }
            
            .grey-box {
                font-size: 12px;
                letter-spacing: -0.4px;
                font-size: 12px;
                color: rgba(25, 25, 25, 0.6);
                background: rgba(25, 25, 25, 0.05);
                border-radius: 4px;
                padding: 2px 6px;
            }

            .others {
                div {
                    background: rgba(25, 25, 25, 0.18);
                }
            }
        }
    }
}