.modal-message-details {
    width: 484px !important;
    
    .modal-content {
        position: relative !important;
        padding: 32px;
        max-height: 85vh;
        border-radius: 8px;
        overflow-y: scroll;
        -ms-overflow-style: none;
        scrollbar-width: none;

        &::-webkit-scrollbar { 
            display: none;
        }

        .modal-body {
            padding: 0 !important;

            .modal-title {
                .icon-view {
                    display: inline-block;
                    vertical-align: middle;
                    width: 24px;
                    height: 24px;
                    margin-right: 4px;
                }
        
                h3 {
                    display: inline-block;
                    vertical-align: middle;
                    margin: 0;
                    font-size: 20px;
                    line-height: 24px;
                    letter-spacing: -0.6px;
                    color: #191919;
                }
            }
        
            h5 {
                display: block;
                margin: 24px 0 8px;
                font-family: PTRootBold;
                font-size: 14px;
                line-height: 21px;
                letter-spacing: -0.4px;
            }
        
            .text-wrapper {
                padding: 12px 16px;
                background: #1919190D;
                border: 1px solid #1919191A;
                border-radius: 8px;
                font-size: 16px;
                line-height: 24px;
                letter-spacing: -0.3px;
                color: #19191999;
                word-wrap: break-word;
            }

            .copy-button {
                padding: 12px 48px 12px 16px !important;

                svg {
                    position: absolute;
                    right: 16px;
                    font-size: 24px;
                    color: #FF7E00;
                    cursor: pointer;
                }
            }

            .body {
                min-height: 144px;
            }

            .thumbnail {
                display: inline-block;
                width: 56px;
                height: 56px;
                margin-top: 8px;
                border-radius: 8px;
            }

            .view-image {
                opacity: 0;
                display: inline-block;
                position: absolute;
                padding: 16px;
                left: 0;
                color: white;
                background: #19191966;
                cursor: pointer;

                &:hover {
                    opacity: 1;
                    transition: ease-in-out 0.2s;
                }

                svg {
                    width: 24px;
                    height: 24px;
                    transform: translateY(-2px);
                }
            }
        
            .document-wrapper {
                display: inline-block;
                max-width: 100%;
                padding: 12px 24px;
                border: 1px solid #19191933;
                border-radius: 8px;
                font-size: 16px;
                line-height: 24px;
                letter-spacing: -0.3px;
                color: #191919;
                word-wrap: break-word;
                cursor: pointer;

                &:hover {
                    border: 1px solid #FF7E00;
                    transition: ease-in-out 0.2s;
                }

                svg {
                    width: 20px;
                    height: 20px;
                    margin-right: 4px;
                    color: #FF7E00;
                    transform: translateY(-2px);
                }
            }
        }

        .modal-footer {
            border: none;
            padding: 0 !important;
            margin: 20px 0 0;

            button {
                display: block;
                width: 100%;
                height: 48px;
                padding: 12px 24px;
                margin: 0;
                font-size: 16px;
                line-height: 20px;
                letter-spacing: -0.5px;
            }
        }
    }
}