.form-add-quick-reply {
    position: relative;

    .form-title-wrapper {
        padding-bottom: 16px;
        border-bottom: solid 1px rgba(25, 25, 25, 0.1);
        color: #191919;
        font-size: 20px;
        letter-spacing: -0.6px;
        position: relative;

        button {
            position: absolute;
            top: 0;
            right: 0;
        }
    }

    #quick-reply-file {
        display: none;
    }

    .upload-media-button {
        width: unset;
        margin: 0 !important;
    }

    .media-action-wrapper {
        button {
            border-radius: 4px;
            border: none;
            margin-right: 8px;
            width: unset !important;
            font-size: 12px;
            letter-spacing: -0.4px;
            padding: 0 12px;
            margin-top: 0 !important;
            margin-bottom: 8px !important;
            height: 22px;
            line-height: 12px;

            svg {
                position: relative;
                margin-right: 4px;
                top: -1px;
            }
        }
    }

    .file-wrapper {
        white-space: nowrap;
        font-size: 14px;
        letter-spacing: -0.2px;
        position: relative;
        width: 100%;
        line-height: 1;

        p {
            max-width: calc(100% - 24px);
            overflow: hidden;
            text-overflow: ellipsis;
            display: inline-block;
            margin: 0;
            padding: 10px 4px 10px 18px;
        }

        svg {
            position: absolute;
            left: 0;
            top: 10px;
            color: var(--main-dark-orange);
        }

        .lds-ring {
            top: -9px;
            width: 16px;
            height: 16px;

            div {
                width: 16px;
                height: 16px;
                margin: 0;
            }
        }
    }

    .media-image-wrapper {
        width: 48px;
        height: 48px;
        display: block;
        position: relative;
        border-radius: 6px;
        overflow: hidden;
        border: solid 1px rgba(25, 25, 25, 0.1);

        img, video {
            max-width: 100%;
            max-height: 100%;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
        
        .lds-ring-wrapper {
            position: absolute;
            width: 100%;
            height: 100%;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            background: rgba(25, 25, 25, 0.2);

            .lds-ring {
                width: 38px;
                height: 38px;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                
                div {
                    border: solid 5px #fff;
                    width: 38px;
                    height: 38px;
                    border-color: #fff transparent transparent transparent;
                }
            }
        }
    }

    .form-content-wrapper label {
        display: block;
        color: #191919;
        font-size: 14px;
        margin-top: 22px;
        position: relative;

        span {
            color: rgba(25, 25, 25, 0.4);
            position: absolute;
            top: 0;
            right: 0;
        }

        &.pos-relative {
            width: 347px;
        }
    }

    .form-content-wrapper input[type="text"] {
        background: rgb(255, 255, 255);
        border-radius: 8px;
        border: 1px solid rgba(25, 25, 25, 0.1);
        height: 48px;
        width: 348px;
        color: #191919;
        font-size: 16px;
        letter-spacing: -0.3px;
        width: 300px;
        padding: 12px 16px;
        margin-bottom: 6px;
    }

    .form-content-wrapper textarea {
        background: rgb(255, 255, 255);
        border-radius: 8px;
        border: 1px solid rgba(25, 25, 25, 0.1);
        width: 348px;
        color: #191919;
        font-size: 16px;
        letter-spacing: -0.3px;
        width: 100%;
        padding: 16px;
        resize: none;
        margin-bottom: 0;
    }

    .form-content-wrapper label {
        display: block !important;
    }

    .form-content-wrapper .role-radio-label {
        position: relative;
    }

    .form-content-wrapper .role-radio-label b {
        display: block;
        color: #191919;
        font-size: 14px;
        letter-spacing: -0.4px;
    } 

    .form-content-wrapper .role-radio-label label {
        color: rgba(25, 25, 25, 0.8);
        font-size: 14px;
        letter-spacing: -0.2px;
    } 

    .form-content-wrapper .custom-radio label {
        margin-top: 18px;
    }

    .form-content-wrapper button {
        // width: 300px;
        margin-top: 22px;
    }

    .form-content-wrapper .orange-button .lds-ring div {
        border-color: #ffffff transparent transparent transparent;
        margin: 0;
    }

    .submit-add-quick-reply {
        position: absolute;
        top: -7px;
        right: 32px;
        width: 176px !important;
        padding: 7px 0 !important;
        margin-top: 32px !important;

        .lds-ring {
            top: 3px;
            right: unset;

            div {
                margin: 0;
                border-color: #ffffff transparent transparent transparent;
            }
        }
    }

    .input-info-wrapper {
        color: rgba(25, 25, 25, 0.6);
        font-size: 12px;
        letter-spacing: -0.2px;
        line-height: 16px;
        width: 450px;
        margin-top: 0;
    }

    .quick-reply-suffix {
        height: 48px;
        padding: 12px 16px;
        margin-left: 8px;
    }
    
    .quick-reply-prefix {
        height: 48px;
        padding: 12px 16px;
        margin-right: 8px;
    }
}