.scf-path-main-wrapper {
  .integration-list-loading-wrapper {
    height: 80vh;

    .lds-ring {
      width: 50px;
      height: 50px;
      position: relative;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      right: unset;

      div {
        border: 5px solid #fff;
        border-color: #ff7d00 transparent transparent transparent;
        width: 50px;
        height: 50px;
        margin: 0;
      }
    }
  }

  .scf-path-header {
    padding-bottom: 12px;
    margin-bottom: 12px;
    border-bottom: 1px solid rgba(25, 25, 25, 0.1);
    position: relative;
    display: block;

    .grey-font {
      font-size: 16px;
      letter-spacing: -0.6px;
    }

    button {
      position: absolute;
      top: 0;
      right: 0;
    }
  }

  .scf-path-title {
    font-size: 20px;
    letter-spacing: -0.6px;
    margin: 0;
    color: #191919;
}

  .scf-path-description {
    margin: 8px 0 16px;
    font-size: 16px;
    letter-spacing: -0.3px;
    width: calc(100% - 230px);
    color: #191919;
    line-height: 24px;
  } 

  .scf-path-search {
    width: 300px;
    border-radius: 8px;
    border: solid 1px rgba(25, 25, 25, 0.1);
    margin-bottom: 24px;

    &.focus-search {
      border: solid 1px #ff7e00;
      
      svg {
        color: #ff7e00;
      }
    }

    span {
      position: relative;
      top: -2px;
    }

    input {
      border: none;
      background: transparent;
      padding: 8px 4px 8px 16px;
      width: 90%;
    }
  }
}

.faq-list thead {
  tr {
    background: transparent;

    td {
      padding: 8px;
      // width: calc((100% - 100px) / 5);
      font-size: 14px;
      word-wrap: break-word;
      white-space: normal;

      
      &.col_type {
        width: 105px;
      }

      &.col_action {
        width: 105px;
      }

      &:last-child {
        width: 90px;

        .custom-select-option-value-wrapper {
          height: 30px;
          padding: 0 4px 0 12px !important;

          .custom-select-option-value {
            padding: 0;
          }

          svg {
            right: 10px;
          }
        }
      }

    }
  }
}

.faq-list {
  tbody {
    tr {
      // cursor: pointer;

      &:hover {
        // background-color: #FFF2E5;

        td {
          // color: #FF7E00;
        }
      }

      &.no-hover {
        cursor: default !important;
        &:hover {
          background-color: #ffffff !important;
    
          td {
            color: #191919 !important;
          }
        } 
      }

      td {
        background: transparent;
        position: relative;
        word-wrap: break-word;
        vertical-align: top;

        &.col_id {
          width: 70px;
        }
        
        &.col_type {
          width: 105px;
        }

        &.col_action {
          width: 105px;
        }

        .unavailable {
          color: rgba(25, 25, 25, 0.4);
        }

        .link-user {
          color: inherit;
          text-decoration: inherit;
        }

        &:nth-child(1) {
          p {
            margin-bottom: 4px;
          }

          .col_name_alias {
            font-size: 12px;
          }
        }

        &:nth-child(4) {
          &.col-origin {
            p {
              margin: 0;
              display: inline;
            }

            img {
              position: relative;
              top: -2px;
              margin-right: 8px; 
              width: 20px; 
            }
          }
        }
      }
    }
  }
}

// .faq-list button svg {
  // position: relative !important;
  // right: unset !important;
  // top: -2px !important;
  // margin-right: 5px;
// }

.faq-list .col-action-button-wrapper {
  position: absolute;
  right: 7px;
  top: 50%;
  transform: translate(0, -50%);
  /* display: none; */
}

.faq-list td {
  max-width: unset !important;

  svg {
    position: relative !important;
    // top: unset !important;
    right: unset !important;
  }
}

.add-path-modal {
  .modal-content {
    width: 800px;
    border-radius: 8px;
    padding: 32px;
    position: relative;
    max-height: 90vh;
    overflow-y: auto;

    .custom-checkbox input {
      // display: none;
    }

    .create-path-title {
      font-size: 20px;
      letter-spacing: -0.6px;
      line-height: 1;
    }

    .close-modal-create-path {
      position: absolute;
      font-size: 22px;
      right: 0;
      top: 2px;
      cursor: pointer;
    }

    .form-content-wrapper {
      input, textarea, label {
        width: 100%;
        position: relative;

        .clear-all {
          color: var(--watermelon);
          cursor: pointer;
          position: absolute;
          right: 0;
          bottom: 0;

          &:hover {
            color: var(--watermelon-dark);
          }
        }
      }

      .input-counter {
        position: absolute;
        top: 0;
        right: 0;
      }
    }

    .button-create-path {
      width: 100%;
      margin-top: 20px;
    }
    
    .create-path-type-wrapper {
      position: relative;
      width: 100%;
      display: block;

      .create-path-type-select {
        display: inline-block;
        border-radius: 8px;
        width: calc(50% - 5px);
        margin-right: 10px;
        border: solid 1px #dcdcdc;
        cursor: pointer;
        text-align: center;
        padding: 12px 5px;
        font-size: 16px;
        letter-spacing: -0.3px;

        &.create-path-type-active {
          border: solid 1px #ff7d00;
          color: #ff8d00;

          svg {
            color: #ff7d00;
          }
        }

        svg {
          margin-right: 4px;
          position: relative;
          top: -1px;
          color: rgba(25, 25, 25, 0.4);
        }

        &:nth-child(2) {
          margin: 0;
        }

        &:hover {
          border: solid 1px #ff7d00;
          color: #ff8d00;

          svg {
            color: #ff7d00;
          }
        }
      }
    }

    .choose-related-department-wrapper {
      position: relative;
      width: 100%;
      display: block;

      .choose-related-department-select {
        display: inline-block;
        border-radius: 8px;
        width: calc((100% / 3) - 10px);
        margin-right: 15px;
        border: solid 1px #dcdcdc;
        cursor: pointer;
        text-align: left;
        padding: 12px 13px;
        font-size: 16px;
        letter-spacing: -0.3px;
        margin-bottom: 10px;

        label {
          white-space: nowrap;
          text-overflow: ellipsis;
          max-width: 100%;
          overflow: hidden;
        }

        svg {
          margin-right: 4px;
          position: relative;
          top: -1px;
          color: rgba(25, 25, 25, 0.4);
        }

        &:nth-child(3) {
          margin: 0;
        }

        &:hover {
          border: solid 1px #ff7d00;
          
          label {
            color: #ff7d00;
          }
        }

        label {
          margin: 0;
        }

        &.choose-related-department-select-active {
          border: solid 1px #ff7d00;
          
          label {
            color: #ff7d00;
          }
        }
      }
    }
  }
  
  .modal-body {
    padding: 0;
  }
}