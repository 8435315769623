.chatgpt-setup-handover-page-wrapper {
  margin-top: 24px;

  .top-info {
    width: 460px;
    // margin-left: 8px;
    // margin-bottom: 16px;

    b {
      font-family: "PTRootUI";
      font-size: 14px;
      font-weight: 400;
      line-height: 21px;
      letter-spacing: -0.2px;
      color: #191919;
    }
  }

  .top-title {
    width: 100%;
    display: block;
    position: relative;
    font-size: 20px;
    letter-spacing: -0.6px;
    margin: 0 0 16px;
    // padding: 0 0 16px 0;
    // border-bottom: solid 1px #dcdcdc;

    button {
      position: absolute;
      top: 0;
      right: 0;
      width: 180px;
    }

    .top-subtitle {
      // margin: 4px 0 0;

      p {
        display: inline-block;
        vertical-align: top;
        margin: 0;
        font-size: 16px;
        letter-spacing: -0.3px;
        color: rgba(25, 25, 25, 0.8);
        transform: translateY(-2px);
      }

      .gpt-handover-switch-wrapper {
        // position: absolute;
        // top: 0;
        // right: 0;
        display: inline-block;
        vertical-align: top;
        margin: 0 8px 0 0;

        .custom-switch-wrapper {
          margin: 0;
          height: 20px;
          width: 32px;

          .custom-switch:before {
            width: 12px;
            height: 12px;
            bottom: 4px;
            transform: translateX(-4px);
          }
        }

        input:checked+.custom-switch:before {
          transform: translateX(12px);
        }
    
        &[disabled] {
          input:checked + .custom-switch {
            background-color: #19191933;
          }
        }
      }
    }
  }

  .criteria-list-wrapper {
    margin-top: 32px;
  }

  .form-content-wrapper {
    label {
      font-size: 16px;
      letter-spacing: -0.5px;
      margin-top: 8px;
    }

    input[type="text"] {
      width: 468px;
    }
  }

  .criteria-wrapper {
    padding: 8px 16px 16px 16px;
    background: rgba(243, 243, 243, 1);
    border: solid 1px rgba(25, 25, 25, 0.1);
    border-radius: 8px;
    margin: 8px 0 0;
    position: relative;
    width: 500px;

    .dropdown {
      button {
        margin: 0 !important;
      }
    }

    .button-delete {
      color: rgba(255, 63, 87, 1);
      font-size: 20px;
      position: absolute;
      top: 16px;
      right: 16px;
      padding: 1px;
      cursor: pointer;
    }

    label {
      position: relative;

      &:nth-child(1) {
        padding: 0 !important;
      }

      .grey-font {
        display: inline-block;
      }

      span {
        color: rgba(25, 25, 25, 0.6);
        font-size: 14px;
        letter-spacing: -0.2px;
        position: absolute;
        right: 0;
        bottom: 0;

        &.right-18 {
          right: 18px !important;
        }
      }
    }
  }

  .add-another-criteria {
    margin: 8px 0 0;
    font-size: 14px;
    letter-spacing: -0.5px;
    color: var(--main-dark-orange);
    cursor: pointer;
    padding: 0 12px 0 8px !important;
    border: none !important;
    
    svg {
      position: relative;
      top: -2px;
      margin: 0 4px 0 0;
    }

    &:hover {
      color: var(--main-darker-orange);
    }
  }
}
