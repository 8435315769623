.detail-contact-container {
  padding: 32px 30px;
  text-align: left;

  .comp-back-button {
    margin: 0 0 16px 0;
    font-family: PTRootBold;
  }

  .detail-contact-box {
    background: #ffffff;
    border-radius: 8px;
    padding: 32px;
    border: solid 1px rgba(25, 25, 25, 0.1);

    .lds-ring {
      margin-top: 15px;
      width: 38px;
      height: 38px;

      div {
        width: 38px;
        height: 38px;
        border: solid 3px #ff7e00;
        border-color: #ff7e00 transparent transparent transparent;
      }
    }

    .col-channel {
      // white-space: nowrap;
      position: relative;
      padding: 8px 8px 8px 28px;

      img {
        position: absolute;
        left: 0;
        margin-right: 4px;
        top: 6px;
        width: 24px;
        height: 24px;
      }
    }

    .status-badge {
      border-radius: 4px;
      padding: 3px 9px;
      font-size: 12px;
      letter-spacing: -0.4px;
      text-align: left;
      display: inline-block;

      svg {
        position: relative;
        margin-right: 4px;
        top: -1px;
        font-size: 13px;
        right: unset;
      }

      &.badge-open {
        color: #fff;
        background: #19c700;
        width: 63px;

        svg {
          color: #fff;
        }
      }

      &.badge-resolved {
        color: rgba(25, 25, 25, 0.6);
        background: rgba(25, 25, 25, 0.05);
        width: 81px;

        svg {
          color: rgba(25, 25, 25, 0.6);
        }
      }
    }

    .button-new-message {
      padding: 0 12px 0 12px !important;
    }

    &.detail-contact-box-bottom {
      margin-top: 12px;
    }
    

    &.detail-box-contact-segment {
      position: relative;
    }

    .table-detail-contact thead tr {
      background: #ffffff;
      // border-top: solid 1px #dcdcdc;
      
      // .col_name {
      //   width: unset;
      // }
  
      td {
        // &:nth-child(1) {
        //     width: 100%;
        // }
  
        // &:nth-child(2), &:nth-child(3) {
        //     width: 100px;
        // }
  
        // &:nth-child(4), &:nth-child(5) {
        //     width: 120px;
        // }

        &:nth-child(1) {
          width: 120px;
        }

        &:nth-child(2), &:nth-child(3) {
          width: 180px;
        }

        &:nth-child(4), &:nth-child(5) {
          width: 130px;
        }
  
        &:last-child {
          width: 90px;
  
          .custom-select-option-value-wrapper {
            width: 55px !important;
            height: 23px;
            padding: 2px 8px 2px 12px !important;
            border-radius: 4px !important;
  
            p {
              font-size: 12px;
              font-weight: bold;
            }
  
            .custom-select-option-value {
              padding: 0;
            }
  
            svg {
              right: 3px !important;
              height: 15px;
              top: 11px !important;
              position: absolute !important;
              margin-right: unset !important;
            }
          }
        }
      }
    }
  
    .table-detail-contact tbody td {
      position: relative;
  
      // &.col_name {
      //   width: unset;
      // }
  
      &.col_status {
        P {
          font-size: 12px !important;
        }
      }
  
      &.col_responded {
        b {
          font-size: 12px !important;
          position: relative;
          top: 2px;
        }
      }
      
      &:nth-child(1) {
        div {
          display: flex;
  
          p {
            font-size: 12px !important;
            letter-spacing: -0.4px;
          }
  
          img {
            margin-right: 4px;
            margin-top: 2px;
          }
        }
      }
  
      // &:nth-child(2), &:nth-child(3) {
      //     width: 100px;
      // }
  
      // &:nth-child(4), &:nth-child(5) {
      //     width: 120px;
      // }

      &:nth-child(1) {
        width: 120px;
      }

      &:nth-child(2), &:nth-child(3) {
        width: 180px;
      }

      &:nth-child(4), &:nth-child(5) {
        width: 130px;
      }
  
      &:last-child {
        color: #ff7e00;
        cursor: pointer;
        font-weight: 700;
        white-space: nowrap;
        
        a {
          color: #ff7e00;
        }
  
        svg {
          position: relative;
          color: #ff7e00;
          font-size: 16px;
          margin-right: 4px;
          right: 0;
          top: -1px;
        }
      }
  
      .medium-logo {
        width: 16px;
        height: 16px;
      }
    }

    .box-contact-segment-list {
      .no-contact-segment {
        padding: 24px 0 0;
        width: 100%;
        display: block;
        position: relative;
        text-align: center;
        font-size: 16px;
        letter-spacing: -0.3px;
        color: rgba(25, 25, 25, 0.4);
        margin: 0;
      }
    }
    
    .detail-contact-box-top {
      padding-bottom: 15px;
      border-bottom: solid 1px #dcdcdc;
      position: relative;
      font-size: 20px;
      letter-spacing: -0.6px;
      width: 100%;
      display: block;
      justify-content: space-between;

      .button-wrapper {
        button {
          font-size: 16px;
        }
      }

      .title-wrapper {
        display: inline-block;
        align-items: center;
        margin-bottom: 8px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        width: calc(100% - 368px);
        vertical-align: top;

        .edit-ctc-segment {
          width: 180px;
          position: absolute;
          top: 0;
          right: 0;
        }

        .title-text {
          position: relative;
          top: 2px;
        }

        .title-icon {
          margin-right: 4px;
          font-size: 24px;
        }

        img {
          position: relative;
          margin-right: 4px;
          top: 0;
        }
      }

      .button-wrapper {
        vertical-align: top;
        display: inline-block;
        width: 368px;
        text-align: right;

        button {
          margin-left: 4px;
          min-width: 180px;
        }
      }
    }

    .detail-contact-box-form {
      width: 100%;

      &:first-child {
        margin-top: 24px;
      }

      .contact-attribute-wrapper {
        width: 300px;
        margin-bottom: 24px;

        .ass-topic-wrapper {
          margin: 4px 0 0;
          
          .topic-list-content {
            margin: 8px 4px 0 0;
          }
        }

        &:first-child {
          margin-top: 24px;
        }

        &:nth-last-child(1) {
          margin-bottom: 0 !important;
        }

        .contact-title-wrapper {
          display: flex;
          align-items: center;
          margin-bottom: 4px;

          // b {
          //   font-size: 14px;
          // }

          img, svg {
            margin-right: 4px;
            // position: relative;
            // top: -1px;
          }
        }

        p {
          white-space: pre-wrap;
          word-break: break-word;
        }
      }

      .contact-origin {
        margin: 0;

        img {
          margin-right: 8px;
          position: relative;
          top: -2px;
          width: 20px;
        }
      }

      label {
        padding: 24px 0 0;
        font-size: 14px;
        letter-spacing: -0.4px;
        display: block;

        b {
          span {
            font-family: PTRootUI, 'sans-serif';
            color: rgba(25, 25, 25, 0.6);
          }
        }
      }

      .contact-custom-field-separator {
        margin-bottom: 24px;
        width: 100%;
        height: 1px;
        background-color: #1919191A;
      }

      .warning-error-red {
        color: #ff3f57;
        font-size: 12px;
        letter-spacing: -0.4px;
        margin: 4px 0 0;

        svg {
            font-size: 14px;
            position: relative;
            top: -2px;
            margin-right: 4px;
            color: #ff3f57 !important;
            cursor: unset !important;
        }
      }

      .form-contact-fullname,
      .form-contact-alias,
      .form-contact-email {
        width: 320px;
        
        input[type='text'] {
          padding: 12px 16px;
          border-radius: 6px;
          border: solid 1px #dcdcdc;
          width: 100%;
          font-size: 16px;
          letter-spacing: -0.3px;
          display: block;
        }

        input[type='text']:focus {
          border: solid 1px #ff7e00;
        }

        input[type='text']:disabled {
          background-color: rgba(25, 25, 25, 0.1) !important;
          color: rgba(25, 25, 25, 0.4) !important;
        }
      }

      .form-contact-phonenumber {
        width: 320px;

        .input-phonenumber-container {
          padding: 0 16px;
          border-radius: 6px;
          border: solid 1px #dcdcdc;
          width: 100%;
          font-size: 16px;
          letter-spacing: -0.3px;
          display: flex;
          align-items: center;

          &.phone-focus {
            border: solid 1px #ff7e00;
          }

          &.phone-disabled {
            background-color: rgba(25, 25, 25, 0.1);
            color: rgba(25, 25, 25, 0.4);
          }

          label {
            padding: 0;
            margin: 0 4px 0 0;
            font-size: 16px;
          }

          .form-phonenumber {
            padding: 0;
            height: 50px;
            border: none;
          }

          .form-phonenumber:disabled {
            color: rgba(25, 25, 25, 0.4) !important;
          }
        }

        .alert-wrapper {
          display: flex;
          margin-top: 4px;

          svg {
            margin-right: 4px;
            font-size: 20px;
          }

          p {
            font-size: 12px;
          }
        }
      }

      button {
        &.add-contact-button {
          width: 132px;
          height: 40px;
          margin-top: 8px;
        }

        &.add-contact-button:disabled {
          background-color: rgba(25, 25, 25, 0.1) !important;
          color: rgba(25, 25, 25, 0.4) !important;
        }

        &.loading-button {
          width: 181px;
          height: 40px;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        &.loading-button:disabled {
          background-color: rgba(25, 25, 25, 0.1) !important;
          color: rgba(25, 25, 25, 0.4) !important;
        }

      }
    }
  }
}

.modal-select-segment-contact-detail {
  .modal-content {
    width: 840px;
    border-radius: 8px;

    .modal-body {
      padding: 32px;

      .modal-select-segment-contact-detail-title {
        margin: 0 0 22px 0;
        font-size: 20px;
        letter-spacing: -0.6px;

        img {
          position: relative;
          margin-right: 4px;
          top: -2px;
        }
      }

      .modal-select-segment-contact-detail-button-wrap {
        width: 100%;
        display: block;
        text-align: right;
        padding: 20px 0 0;

        .button-cancel {
          
        }

        .button-save {
          margin-left: 8px;
        }
      }
    }
  }
}
