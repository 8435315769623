.user-segment-main-wrapper {
  .integration-list-loading-wrapper {
    height: 80vh;

    .lds-ring {
      width: 50px;
      height: 50px;
      position: relative;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      right: unset;

      div {
        border: 5px solid #fff;
        border-color: #ff7d00 transparent transparent transparent;
        width: 50px;
        height: 50px;
        margin: 0;
      }
    }
  }

  .user-segment-header {
    padding-bottom: 12px;
    margin-bottom: 12px;
    border-bottom: 1px solid rgba(25, 25, 25, 0.1);

    .remove-segment-wrapper {
      button {
        &.cancel-delete-segment-btn {
          margin-right: 8px;
        }
      }

      &:focus {
        border: none;
        outline: none;
      }
    }
  }

  .search-box-wrapper {
    margin-bottom: 24px;
  }

  // .user-segment-search {
  //   width: 300px;
  //   border-radius: 8px;
  //   border: solid 1px rgba(25, 25, 25, 0.1);
  //   margin-bottom: 24px;

  //   span {
  //     position: relative;
  //     top: -2px;
  //   }

  //   &.focus-search {
  //     border: solid 1px #ff7e00;

  //     svg {
  //       color: #ff7e00;
  //     }
  //   }

  //   input {
  //     border: none;
  //     background: transparent;
  //     padding: 8px 4px 8px 16px;
  //     width: 90%;
  //   }
  // }
}

.table-user-segment-list thead tr {

    .custom-checkbox-stripe [type="checkbox"]:checked + label:after {
        top: 2px;
        left: 0;
    }

    // td {
    //     &:nth-child(1) {
    //         padding-left: 34px !important;
    //     }
    // }
}

.table-user-segment-list {
  table-layout: unset;

  thead {
    // .tr-user-segment-list-head {
    //   border-bottom: solid 1px rgba(25, 25, 25, 0.05);
    // }

    tr {
      background: transparent;
  
      td {
        // width: calc((100% - 100px) / 5);
        // width: 20%;
        font-size: 14px;
        letter-spacing: -0.4px;
        font-family: "PTRootBold" !important;
        word-wrap: break-word;
        white-space: normal;

        // &.col_checkbox_all {
        //   width: 32px;
        //   cursor: pointer;

        //   img {
        //     padding: 2px;
        //     margin-left: 2px;
        //     width: 12px;
        //     height: 12px;
        //     border-radius: 3px;
        //     color: white;
        //   }
          
        //   &:hover {
        //     img {
        //       border: 1px solid var(--main-dark-orange);
        //     }
        //   }
        // }

        // &.checked {
        //   img {
        //     background-color: var(--main-dark-orange);
        //     border: 1px solid var(--main-dark-orange);
        //   }
        // }

        // &.unchecked {
        //   img {
        //     background-color: white;
        //     border: 1px solid rgba(25, 25, 25, 0.1);
        //   }
        // }

        &.col_segment_name {
          padding-left: 34px;
          width: calc(50% - 115px);

          .custom-checkbox {
              position: absolute !important;
              top: 1px !important;
              left: 12px !important;
          }
      
          .custom-checkbox-stripe {
              position: absolute !important;
              top: 1px !important;
              left: 12px !important;
          }
      
          .custom-checkbox-stripe [type="checkbox"]:checked + label:after {
              width: 16px;
              height: 16px;
          }
        }

        .col_number_contact {
          width: calc(50% - 115px);
        }

        &.col_select_list {
          width: 230px;
        }
  
        &:last-child {
          width: 161px;
          padding-right: 0;
          

          .main-custom-select-option-wrapper {
            display: flex;
            justify-content: flex-end;

            .dropdown {
              width: 55px;
            }
          }
  
          .custom-select-option-value-wrapper {
            height: 22px;
            padding: 0 4px 0 12px !important;
            border-radius: 4px !important;
  
            .custom-select-option-value {
              font-weight: bold;
              font-size: 12px;
              letter-spacing: -0.4px;
              padding: 0;
            }
  
            svg {
              font-size: 12px;
              right: 8px;
            }
          }

          .dropdown-option-wrapper {
            button {
              font-family: "PTRootUI";
              font-weight: normal;
            }
          }
        }
  
      }
    }
  }

  tbody {
    .tr-user-segment-list-body {
      // border-bottom: none;

      // &:hover {
      //   background-color: transparent !important;

      //   &:nth-child(even) {
      //       background: rgba(25, 25, 25, 0.05) !important;
      //   }
      // }

      // &:nth-child(even) {
      //     background: rgba(25, 25, 25, 0.05);
      // }

      td {
        // background: transparent;
        position: relative;
        word-wrap: break-word;
        max-width: unset;
        // vertical-align: top;
        // padding: 16px 8px;
        padding: 8px;
        line-height: 21px;

        // &.col_segment_checkbox {
        //   width: 32px;
        //   cursor: pointer;

        //   img {
        //     padding: 2px;
        //     margin-left: 2px;
        //     width: 12px;
        //     height: 12px;
        //     border-radius: 3px;
        //     color: white;
        //   }
          
        //   &:hover {
        //     img {
        //       border: 1px solid var(--main-dark-orange);
        //     }
        //   }
        // }

        // &.checked {
        //   img {
        //     background-color: var(--main-dark-orange);
        //     border: 1px solid var(--main-dark-orange);
        //   }
        // }

        // &.unchecked {
        //   img {
        //     background-color: white;
        //     border: 1px solid rgba(25, 25, 25, 0.1);
        //   }
        // }

        &.col_segment_name {
          padding-left: 34px;
          width: calc(50% - 115px);

          p {
            margin-bottom: 0;
            display: inline-block;
            width: 85%;
            vertical-align: top;
          }

          .custom-checkbox {
              position: absolute !important;
              top: 1px !important;
              left: 12px !important;
          }
      
          .custom-checkbox-stripe {
              position: absolute !important;
              top: 1px !important;
              left: 12px !important;
          }
      
          .custom-checkbox-stripe [type="checkbox"]:checked + label:after {
              width: 16px;
              height: 16px;
          }
        }

        &.col_segment_count {
          width: calc(50% - 115px);
        }

        // &:nth-child(3) {
        //   width: 230px;
        // }

        // &.col_segment_name {
        //   p {
        //     margin-bottom: 0;
        //     display: inline-block;
        //     width: 85%;
        //     vertical-align: top;
        //   }
        // }

        &.col-action {
          padding-right: 2px;
          width: 230px;
          // height: 77px;
          text-align: right;
          white-space: nowrap;

          button {
            border: none !important;

            &.detail-btn {
              margin-left: 10px;
              letter-spacing: -0.4px;

              &:hover {
                background: transparent;
                color: #e87200;
              }

              svg {
                position: relative;
                font-size: 16px;
                top: -2px;
                left: -4px;
              }
            }

            &.delete-btn {
              width: 100px;
              color: #FF3F57 !important;

              &:hover {
                background: transparent;
                color: #e83b51;
              }

              svg {
                position: relative;
                top: -3px;
                color: #FF3F57;
              }
            }
          }
        }
      }
    }
    
    .tr-user-segment-list-empty {
      border-bottom: none;

      td {
        padding-top: 24px;

        b {
          font-size: 16px;
          letter-spacing: -0.5px;
        }
      }
    }
  }
}

.custom-table-content {
  tbody {
    tr {
      &:hover {
        td {
          background: none;
        }
      }
    }
  }
}

.new-segment-modal {
  .modal-content {
      width: 520px;

      .modal-body {
        padding: 32px;
  
        .new-segment-wrapper {
          .segment-title-wrapper {
            .new-segment-title {
              font-size: 20px;
              line-height: 24px;
            }

            svg {
              margin-right: 10px;
              font-size: 21px;
              position: relative;
              top: -3px;
            }
          }

          label {
            width: 100%;
            margin-top: 20px;
          }
  
          .segment-name {
            width: 100%;
            height: 48px;
            border: 1px solid rgba(25, 25, 25, 0.1);
            border-radius: 8px;
            padding: 12px 16px;
            margin: 0;

            &:focus {
              border: 1px solid #FF7E00;
            }
          }
  
          .new-segment-button-container {
            width: 100%;
            display: flex;
            justify-content: space-between;
            margin: 20px 0 0;

            button {
              width: 50%;

              &.orange-button {
                margin-left: 8px;
              }
            }
            
          }
        }
    }
  }
}