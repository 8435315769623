.salestalk-main-wrapper {
    .salestalk-main-desc {
        margin: 18px 0 0;
        font-size: 16px;
        letter-spacing: -0.3px;
    }

    .button-connect {
        width: 277px;
        
        img {
            width: 24px;
        }
    }

    .topContent {
        // height: 60px;

        .title {
            width: 100%;
            color: #191919;
            font-size: 20px;
            letter-spacing: -0.6px;
            line-height: 24px;
            padding-bottom: 26px;
            border-bottom: solid 1px rgba(25, 25, 25, 0.1);
            margin: 0;
        }

        button {
            position: absolute;
            right: 32px;
            top: 32px;
            vertical-align: top;

            &.no-fill-button {
                right: 172px;
            }
            // float: right;
        }
    }

    .topContent-custom-provider {
        position: relative;
        border-bottom: solid 1px #dcdcdc;

        .title {
            b {
                font-size: 20px;
                letter-spacing: -0.6px;
            }
    
            p {
                font-size: 16px;
                letter-spacing: -0.3px;
    
                a {
                    color: #ff7d00;
    
                    &:hover {
                        color: var(--main-darker-orange);
                    }
                }
            }
        }

        button {
            position: absolute;
            right: 0;
            top: 0;
        }
    }

    img {
        margin-right: 4px;
        position: relative;
        top: -2px;
    }

    .sales-talk-main-view {
        font-size: 16px;
        letter-spacing: -0.3px;
        margin-top: 24px;

        .label-title {
            margin: 24px 0 8px 0;
            font-size: 16px;
            letter-spacing: -0.2px;
        }

        .dont-have-account {
            font-size: 14px;
            letter-spacing: -0.2px;
            color: rgba(25, 25, 25, 0.8);
            margin: 10px 0 0;

            a {
                color: #ff7d00;

                &:hover {
                    color: var(--main-darker-orange);
                }
            }
        }

        .for-more-details {
            font-size: 16px;
            letter-spacing: -0.3px;
            color: rgba(25, 25, 25, 0.8);
            margin: 10px 0 20px;

            a {
                color: #ff7d00;

                &:hover {
                    color: var(--main-darker-orange);
                }
            }
        }

        ul {
            padding: 0 0 0 18px;
            
            li {
                a {
                    color: #ff7d00;
    
                    &:hover {
                        text-decoration: underline !important;
                    }
                }
            }
        }
    }

    .integration-salestalk-error {
        width: 100%;
        text-align: center;
        margin: 38px 0 12px;

        p {
            margin: 14px 0 12px;
            font-size: 16px;
            letter-spacing: -0.3px;
            color: rgba(25, 25, 25, 0.6);
        }

        img {

        }
    }
}


.modal-salestalk {
    .modal-content {
        width: 476px;
        border-radius: 8px;

        .modal-body {
            
        }
    }
}

.modal-confirmation-disconnect-salestalk {
    button {
        width: calc(50% - 4px) !important;
        margin-right: 8px;

        &:nth-last-child(1) {
            margin-right: 0;
        }
    }
}