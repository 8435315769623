.ascending-descending-table-wrapper {
    position: relative;
    display: inline-block;
    margin-left: 4px;
    height: 20px;
    width: 10px;
    vertical-align: top;
    top: 0px;

    svg {
        cursor: pointer;
        color: #191919;
        font-size: 12px;
        border-radius: 2px;

        // &:hover {
        //     background: #e9e9e9;
        // }

        &.up-button {
            position: absolute;
            top: 0;
            left: 0;
        }

        &.down-button {
            position: absolute;
            top: 10px;
            left: 0;
        }

        &.active-sort {
            background: #FDF1F1;
            color: #ff7d00;
        }
    }
}