.assignment-settings {
    .button-save {
        margin-top: 28px;
    }

    .assignment-settings-header {
        font-size: 20px;
        letter-spacing: -0.6px;
        color: #191919;
        padding-bottom: 20px;
        position: relative;
        border-bottom: solid 1px #dcdcdc;

        button {
            position: absolute;
            top: -3px;
            right: 0;
            margin: 0;
        }
    }

    .assignment-settings-form {
        padding-top: 24px;
        position: relative;

        span {
            font-size: 14px;
            letter-spacing: -0.2px;
            color: rgba(25, 25, 25, 0.8);
            display: block;  
        }

        .change-rule-gap {
            margin-bottom: 24px;
        }
    }

    .ignore-inbox-connection {
        margin: 8px 0 0 27px;
        position: relative;
        padding: 0 0 0 32px;
        font-size: 12px;
        letter-spacing: -0.2px;

        p {
            color: rgba(25, 25, 25, 0.8);
        }

        b {
            font-size: 14px;
            letter-spacing: -0.4px;
        }

        .custom-switch-wrapper {
            display: inline-block !important;
            width: 24px;
            height: 14px;
            position: absolute;
            top: 4px;
            left: 0;
            margin: 0;

            .custom-switch {
                padding: unset;
            }

            .custom-switch.round {
                border-radius: 8px;

                &::before {
                    width: 8px;
                    height: 8px;
                    bottom: 3px;
                    left: 4px;
                }
            }

            input:checked + .custom-switch:before {
                transform: translateX(8px);
            }
        }
    }
}