.error-component-wrapper {
    width: 100%;
    display: block;
    text-align: center;
    padding: 10px;

    svg {
        color: rgba(255, 63, 87, 1);
        font-size: 58px;
    }

    p {
        margin: 16px 0 0;
        font-size: 16px;
        letter-spacing: -0.3px;
        color: rgba(255, 63, 87, 1);
    }
}