.modal-phone-code {
    .modal-body {
        height: 100%;
        width: 100%;
        position: fixed;
        background: transparent;
        padding: 0;
    }

    .modal-content {
        width: 480px;
        height: calc(100% - 240px);
        border-radius: 16px;
        overflow: hidden;

        .modal-phone-code-header {
            // height: 53px;
            width: 100%;
            text-align: center;
            padding: 16px 0 16px;
            position: relative;
            font-size: 20px;
            line-height: 24px;
            letter-spacing: -0.6px;


            svg {
                color: #ff7e00;
                cursor: pointer;
                font-size: 32px;
                padding: 4px;
                position: absolute;
                right: 12px;
                top: 11px;

                &:hover {
                    color: #E87200;
                }
            }
        }

        .modal-phone-search {
            padding: 8px 16px;
            
            input {
                display: block;
                padding: 8px 8px 8px 32px;
                width: 100%;
                font-size: 16px;
                line-height: 24px;
                letter-spacing: -0.3px;
                color: #191919;
                border-radius: 8px;
                border: 1px solid #1919191A;
                box-sizing: border-box;
                -webkit-box-shadow: 0 0 0 50px white inset;
                box-shadow: 0 0 0 50px white inset;
                
                &:focus {
                    border: solid 1px var(--main-dark-orange);
                }                
            }

            svg {
                position: absolute;
                width: 16px;
                height: 16px;
                left: 24px;
                top: 78px;
                bottom: 0;
                color: #191919;
            }

            input:focus + svg {
                color: var(--main-dark-orange) !important;
            }
        }

        .modal-phone-code-body {
            overflow: auto;
            width: 100%;
            max-height: calc(100% - 106px);

            .alphabet-wrapper {
                padding: 5px 16px;
                font-size: 12px;
                line-height: 18px;
                letter-spacing: 2.5px;
                font-weight: 500;
                color: #9B9B9B;
                background: #1919190D;
            }

            .country-wrapper {
                height: 44px;
                padding: 13.5px 16px 10px 60px;
                line-height: 1;
                position: relative;
                font-size: 16px;
                letter-spacing: -0.3px;
                cursor: pointer;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                max-width: 100%;
                color: #191919;

                img {
                    position: absolute;
                    top: 12px;
                    left: 16px;
                    width: 28px;
                    border-radius: 2px;
                }

                &:hover {
                    color: white;
                    background: #ff7e00;
                }
            }

        }

        @media screen and (min-width: 300px) and (max-width: 767px) {
            width: 100%;
            height: 100%;
        }
    }
}