.room-list-tab-wrapper {
    height: 53px;
    width: calc(100% - 62px);
    position: relative;
    padding: 0 8px;
    // line-height: 53px;
    background: #ffffff;
    // box-shadow: 0 9px 20px 0 rgba(25, 25, 25, 0.5);
    position: absolute;
    right: 0;
    bottom: 0;

    &.no-other {
        .room-list-tab-content {
            width: calc(100% / 3) !important;
        }
    }

    &.no-other-and-close {
        .room-list-tab-content {
            width: 50% !important;
        }
    }
}

.room-list-tab-content {
    width: calc(100% / 4);
    display: inline-block;
    color: rgba(25, 25, 25, 0.4);
    text-align: center;
    font-size: 13px;
    letter-spacing: -0.4px;
    position: relative;
    height: 53px;
    vertical-align: top;
    transition: ease-in-out .1s;
    padding-top: 9px;

    &.tab-content-with-image {
        .black-tab {
            display: none;
        }

        &:hover {
            .black-tab {
                display: inline-block !important;
            }

            .grey-tab {
                display: none;
            }
        }
    }
}

.room-list-tab-content.active-room-list-tab {
    color: #ff7d00 !important;
}

.room-list-tab-content:before {
    transition: ease-in-out .3s;
    content: "";
    position: absolute;
    width: 100%;
    height: 4px;
    left: 0;
    top: -1px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
}

.room-list-tab-content.active-room-list-tab:before {
    background: #ff7d00;
}

.room-list-tab-content svg {
    font-size: 17px;
    // margin-right: 4px;
    position: relative;
    top: -2px;
}


.room-list-tab-content img {
    // font-size: 19px;
    // margin-right: 4px;
    position: relative;
    top: -2px;
}

.room-list-tab-content:hover {
    color: #191919;
    cursor: pointer;
}

.last-case-no-margin-bottom > div > div:nth-last-child(1) {
    margin-bottom: 0 !important;
}

.last-case-no-margin-bottom > div > div:nth-last-child(1) .fetch-solved-case {
    margin-bottom: 0;
}

.case-list-separator {
    color: rgba(25, 25, 25, 0.6);
    font-size: 12px;
    letter-spacing: -0.4px;
    background: #f3f3f3;
    width: 100%;
    line-height: 1;
    padding: 10px 12px 6px;
}

.case-list-separator.with-border-top {
    border-top: solid 1px rgba(25, 25, 25, .1);
}

.main-case-wrapper {
    .case-tab-label-wrapper {
        position: relative;
        width: 100%;
        // padding-right: 26px;
        margin-top: 4px;
    }
}

.label-container {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    max-width: 100%;
    color: #ffffff;
    line-height: 1;
    height: 19px;
    margin-top: 8px;
    border-radius: 4px;
    // width: 250px;
    // width: 210px;

    .case-tab-label-content {
        // vertical-align: top;
        border-radius: 4px;
        font-size: 12px;
        letter-spacing: -0.4px;
        // color: #ffffff;
        margin-right: 2px;
        display: inline !important;
        padding: 2px 8px; 
        // white-space: nowrap;
        // text-overflow: ellipsis;
        // overflow: hidden;
        // width: fit-content;
        text-align: center; 
        // max-width: 107px;
        // max-width: 100%;
        // position: relative;
        // top: 3px;
    }
}


.more-label {
    font-size: 12px;
    letter-spacing: -0.4px;
    color: rgba(25, 25, 25, 0.4);
    position: absolute;
    right: 2px;
    top: 50%;
    transform: translate(0, -50%);
}
                            
                            
                
