.chatbot-setup-page-wrapper {
    
    .prompt-settings-title {
        margin-bottom: 4px; 
        font-size: 20px;
        line-height: 24px;
        letter-spacing: -0.6px;
        color: #191919;
    }

    .prompt-settings-description {
        margin: 8px 0 0 0;
        padding-bottom: 12px;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: -0.3px;
        color: #191919CC;
        border-bottom: solid 1px #1919191A;
    }

    .form-content-wrapper {
        position: relative;
        max-width: 600px;

        .input-counter {
            position: absolute;
            top: 0;
            right: 0;
        }

        input {
            width: 100%;
        }

        .dropdown {
            width: 100%;
        
            button {
                margin-top: 0px;
            }
        }

        .optional {
            font-size: 14px;
            line-height: 21px;
            letter-spacing: -0.2px;
            color: #19191966;
        }

        .brand-description-wrapper {
            width: 100%;

            .input-brand-description {
                display: block;
                margin: 4px 0;
                width: 100%;
                // height: 96px;
                // overflow: hidden;
                height: 338px;
                overflow: auto;
                resize: none;

                &::-webkit-scrollbar {
                    width: 12px;
                }
                
                &::-webkit-scrollbar-thumb {
                    border: 4px solid #00000000;
                    border-radius: 8px;
                    background-color: #1919194D;
                    background-clip: padding-box;
                }
            }
        }

        .dropdown-model .error-or-info-comp {

            a {
                color: var(--main-dark-orange);
            }
        
            a:hover {
                color: var(--main-dark-orange);
                text-decoration: underline !important;
            }
            
            a:active {
                color: var(--main-darker-orange);
                text-decoration: underline !important;
            }
        }

        .button-save {
            width: 180px !important;
        }
    }
}
