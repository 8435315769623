.bc-textarea-wrapper {
    max-width: 100%;
    height: 154px;
    position: relative;
    font-size: 0;

    textarea {
        width: 514px !important;
        display: inline-block;
        max-width: 100%;
        height: 106px !important;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        // z-index: 1;
        position: relative;
    }

    .broadcast-message-action-wrapper {
        position: absolute;
        height: 48px;
        border: solid 1px #dcdcdc;
        border-top: 0;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
        background: #ffffff;
        bottom: 0;
        left: 0;
        font-size: 12px;
        letter-spacing: -0.4px;
        width: 100%;
        padding: 12px 16px;
        // z-index: 0;
        width: 514px !important;
        display: inline-block;
        max-width: 100%;
        white-space: nowrap;
        overflow: hidden;

        .broadcast-message-action-inner-wrapper {
            position: absolute;
            top: 12px;
            left: 50%;
            transform: translate(-50%, 0);
            width: 100%;
            max-width: 100%;
            height: 52px;
            padding: 0 16px;
            overflow-x: auto;
            overflow-y: hidden;
        }

        .add-variable-title {
            font-size: 12px;
            letter-spacing: -0.4px;

            svg {
                position: relative;
                top: -1px;
                margin-right: 13px;
                outline: none;
            }
        }
        
        .button-add-var {
            margin: 0 8px 0 0;
            color: rgba(25, 25, 25, 0.6);
            border: solid 1px rgba(25, 25, 25, 0.1);
            letter-spacing: -0.4px;
            font-size: 12px;
            border-radius: 4px;
            cursor: pointer;
            display: inline-block !important;
            padding: 2px 8px;
            background: #ffffff;
            -webkit-transition: ease-in-out .1s;
            transition: ease-in-out .1s;

            &:hover {
                color: #ffffff;
                border: solid 1px #ff7d00;
                background: #ff7d00;
            }

            svg {
                position: relative;
                top: -1px;
                margin-right: 4px;
            }
        }

        .add-variable-panel-wrapper {
            position: absolute;
            left: 16px;
            bottom: 37px;
            background: #ffffff;
            border-radius: 8px;
            color: #191919;
            box-shadow: 0px 2px 20px rgba(25, 25, 25, 0.18);
            padding: 16px 24px;
            width:350px;
            display: inline-block;
            text-align: left;
            z-index: 1;

            .button-close {
                color:rgba(25, 25, 25, 0.6);
                cursor: pointer;
                position: absolute;
                top: 16px;
                font-size: 18px;
                right: 24px;

                &:hover {
                    color: rgba(25, 25, 25, 1);
                }
            }

            .button-variable {
                margin: 10px 8px 0 0;
                color: rgba(25, 25, 25, 0.6);
                border: solid 1px rgba(25, 25, 25, 0.1);
                letter-spacing: -0.4px;
                font-size: 12px;
                border-radius: 4px;
                cursor: pointer;
                display: inline-block !important;
                padding: 2px 8px;
                background: #ffffff;
                -webkit-transition: ease-in-out .1s;
                transition: ease-in-out .1s;
                display: inline-block;
                width: unset;
                vertical-align: top;

                &:hover {
                    color: #ffffff;
                    border: solid 1px #ff7d00;
                    background: #ff7d00;
                }
            }

            .add-variable-panel-title {
                font-size: 14px;
                letter-spacing: -0.4px;
                background: #ffffff;
            }

            .variable-input {
                position: relative;
                margin-top: 9px;

                input {
                    border-radius: 8px !important;
                    padding-right: 111px;

                    &:focus {
                        border: solid 1px #ff7e00;
                    }
                }

                textarea {
                    border-radius: 8px !important;
                }

                button {
                    position: absolute;
                    font-size: 12px;
                    letter-spacing: 2.5px;
                    height: 24px;
                    display: inline-block;
                    border-radius: 4px;
                    width: unset;
                    padding: 0 12px 0 10px;
                    right: 16px;
                    top: 12px;
                    margin: 0;

                    svg {
                        margin-right: 4px;
                        position: relative;
                        top: -1px;
                        font-size: 14px;
                    }
                }
            }

            ul {
                list-style: none;
                border-radius: 8px;
                background: rgba(25,25,25,0.05);
                margin: 12px 0 0;
                padding: 8px 0 0;

                li {
                    font-size: 14px;
                    letter-spacing: -0.4px;
                    margin: 0;
                    padding: 0 16px 8px 16px;
                }
            }
        }
    }
}