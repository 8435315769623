.modal-ongoing-case {
    .modal-content {
        border-radius: 8px;
        width: 498px;
        display: inline-block;
    }

    .modal-body {
        width: 100%;
        padding: 32px;

        .ongoing-title {
            color: #191919;
            letter-spacing: -0.6px;
            font-size: 20px;
            margin: 0;
            
            svg {
                font-size: 24px;
                margin-right: 4px;
                position: relative;
                top: -2px;
            }
        }
    
        .ongoing-description {
            color: rgba(25, 25, 25, 0.6);
            font-size: 14px;
            letter-spacing: -0.2px;
            line-height: 21px;
            margin: 4px 0 20px 0;
        }
    
        .ongoing-button-wrapper {
            button {
                height: 48px;
                font-size: 16px;
                letter-spacing: -0.5px;
                margin: 0;

                svg {
                    font-size: 20px;
                    position: relative;
                    margin-left: 4px;
                    top: -2px;
                }

                .lds-ring {
                    top: 3px;
                    right: unset;

                    div {
                        margin: 0;
                        border-color: #ffffff transparent transparent transparent;
                    }
                }
    
                &.button-50 {
                    width: calc(50% - 4px);
                    margin: 0 8px 0 0;
    
                    &:nth-last-child(1) {
                        margin: 0;
                    }
                }
            }
        }
    }
}