.modal-new-conversation {
  .select-channel-first {
    background-color: rgba(25, 25, 25, 0.1) !important;
    color: rgba(25, 25, 25, 0.4) !important;
    border-radius: 8px;
    padding: 12px 38px 12px 16px;
    position: relative;
    margin: 0 0 12px 0;

    svg {
      position: absolute;
      right: 14px;
      top: 12px;
      font-size: 24px;
    }
  }

  .select--is-disabled {
    .select__input {
      display: none;
    }
  }

  .channel-type {
    margin: 0;
    line-height: 1;
    font-size: 16px;
    letter-spacing: -0.3px;

    img {
      margin-right: 4px;
      position: relative;
      top: -2px;
    }
  }

  .case-id {
    p {
      margin-bottom: 0;
      color: rgba(25, 25, 25, 0.8);
    }
  }

  input:disabled {
    background-color: rgba(25, 25, 25, 0.1) !important;
  }

  input::placeholder {
    color: rgba(25, 25, 25, 0.4);
  }

  .disabled {

    label,
    b,
    input {
      color: rgba(25, 25, 25, 0.4);
    }
  }

  .new-conversation-field-info {
    font-size: 12px;
    letter-spacing: -0.4px;
    color: #191919;
    margin: 8px 0 0;

    svg {
      position: relative;
      margin-right: 4px;
      top: -1px;
      font-size: 16px;
    }
  }

  .search-contact-wrapper {
    .conversation-data-contact-wrapper {
      border-radius: 8px;
      border: solid 1px rgba(25, 25, 25, .1);
      padding: 11px 16px;
      font-size: 16px;
      color: #191919;

      p {
        white-space: nowrap;
        overflow: hidden;
        letter-spacing: -0.3px;
        text-overflow: ellipsis;
        margin: 0;
        max-width: 100%;
      }
    }

    .LoadingRoll_1234fed {
      position: absolute;
      top: 50%;
      right: 16px;
      transform: translate(0, -50%);
      font-size: 20px;
    }

    &.border-red {
      input {
        border: solid 1px #ff3f57 !important;
      }
    }
  }

  .search-message-template-wrapper,
  .search-language-template-wrapper {
    position: relative;

    div {
      div {
        margin-bottom: 0;
      }
    }

    .LoadingRoll_1234fed {
      position: absolute;
      top: 50%;
      right: 16px;
      transform: translate(0, -50%);
      font-size: 20px;
    }

    &.border-red {
      &>div>div {
        border-color: #ff3f57 !important;
      }
    }
  }

  .search-message-template-wrapper.disabled,
  .search-language-template-wrapper.disabled {
    input {
      visibility: hidden;
    }
  }

  .search-message-template-wrapper.disabledd,
  .search-language-template-wrapper.disabledd {
    input {
      visibility: hidden;
    }
  }

  .add-by-phone-number {
    &:hover {
      svg {
        color: #ffffff;
      }
    }
  }
}

.new-conversation-container {
  padding: 32px 32px 72px;

  .new-conversation-title {
    color: #191919;

    b {
      font-size: 20px;
      letter-spacing: -0.6px;
    }

    img {
      margin: 0 4px 0 0;
      position: relative;
      top: -3px;
    }

    svg {
      font-size: 24px;
      margin: 0 4px 0 0;
      position: relative;
      top: -2px;
    }

    p {
      margin: 2px 0 0;
      color: rgba(25, 25, 25, 0.6);
      font-size: 14px;
      letter-spacing: -0.2px;
    }
  }

  .channel-type {
    margin: 0;
    line-height: 1;
    font-size: 16px;
    letter-spacing: -0.3px;

    img {
      margin-right: 4px;
      position: relative;
      top: -2px;
    }
  }

  .form-label {
    width: 100%;
    position: relative;
    font-size: 14px;
    letter-spacing: -0.6px;
    color: #191919;

    &.form-label-phone {
      margin: 24px 0 0;
    }

    p {
      margin: 0 8px 0 0;
      width: 142px;
      display: inline-block;

      &.pos-relative {
        width: calc(100% - 150px);
        margin: 0 !important;
      }
    }
  }

  label {
    span {
      letter-spacing: -0.2px;
      font-size: 14px;
      color: rgba(25, 25, 25, 0.6);
    }

    &.button-upload-image {
      margin: 0 4px;
      color: #ff7d00;
      letter-spacing: -0.4px;
      font-size: 12px;
      border-radius: 4px;
      cursor: pointer;
      display: inline-block !important;
      padding: 2px 8px;
      background: rgba(255, 126, 0, 0.1);
      -webkit-transition: ease-in-out .1s;
      transition: ease-in-out .1s;

      &:hover {
        color: #ffffff;
        background: #ff7d00;
      }

      svg {
        position: relative;
        top: -1px;
        margin-right: 4px;
      }
    }

    &.button-upload-image-dis {
      margin: 0 4px;
      color: rgba(25, 25, 25, 0.4);
      letter-spacing: -0.4px;
      font-size: 12px;
      border-radius: 4px;
      cursor: default;
      display: inline-block !important;
      padding: 2px 8px;
      background: rgba(25, 25, 25, 0.1);
      -webkit-transition: ease-in-out .1s;
      transition: ease-in-out .1s;

      svg {
        position: relative;
        top: -1px;
        margin-right: 4px;
      }
    }
  }

  textarea {
    width: 100%;
    height: 96px;
  }

  .image-and-caption-wrapper {
    width: 100%;
  }

  input[type="file"] {
    display: none;
  }

  .phone-number-form {
    color: #191919;
    font-size: 16px;
    letter-spacing: -0.3px;
    margin-top: 8px;
    overflow-x: unset !important;

    .dropdown-menu {
      border: 1px solid rgba(0, 0, 0, .15);
      transform: translate3d(0px, 50px, 0px) !important;
    }

    input[type="text"] {
      width: 100%;
      padding: 12px 16px;
      border: solid 1px rgba(25, 25, 25, 0.1);
      border-radius: 8px;
      box-shadow: none;
      outline: none;

      &:focus {
        border-color: #ff7d00;
      }
    }

    .phone-wrapper {
      width: calc(100% - 150px);
      display: inline-block;
      vertical-align: top;

      input {
        height: 48px;
      }

      p {
        color: #ff3f57;
        font-size: 12px;
        letter-spacing: -0.4px;
        margin: 4px 0 0;
        width: 100%;
        position: relative;
        padding: 0 0 0 20px;

        svg {
          margin-right: 4px;
          position: absolute;
          left: 0;
          top: 1px;
          font-size: 16px;
        }
      }
    }

    &.phone-number-disabled {
      input[type="text"] {
        background: rgba(25, 25, 25, 0.1);
        border: none;
        cursor: not-allowed;
      }
    }
  }

  .custom-select-phonenumber {
    margin-bottom: 24px;
  }

  .new-templated-message-title {
    color: #191919;

    b {
      font-size: 20px;
      letter-spacing: -0.6px;
    }

    img {
      margin: 0 4px 0 0;
      position: relative;
      top: -3px;
    }

    svg {
      font-size: 24px;
      margin: 0 4px 0 0;
      position: relative;
      top: -2px;
    }

    p {
      margin: 2px 0 0;
      color: rgba(25, 25, 25, 0.6);
      font-size: 14px;
      letter-spacing: -0.2px;
    }
  }
}