.modal-comparison-whatsapp {
    .modal-content {
        width: 1110px;
        max-width: 90vw;
        border-radius: 8px;
    }

    .modal-body {
        padding: 36px;
        position: relative;
        text-align: center;

        div {
            margin: 0;
            line-height: 1;

            &.title {
                font-size: 20px;
                letter-spacing: -0.6px;

                b {
                    &.and-text {
                        margin: 0 4px;
                    }
                }

                img {
                    position: relative;
                    margin: 0 4px;
                    top: -2px;
                    width: 24px;
                    height: 24px;
                }
            }
        }

        svg {
            color: #ff7d00;
            position: absolute;
            top: 36px;
            right: 36px;
            cursor: pointer;

            &:hover {
                color: var(--main-darker-orange);
            }
        }

        table {
            margin: 28px 0 0;
            width: 100%;
            text-align: left;
            // border-collapse: collapse;

            thead {
                tr {
                    td {
                        padding: 8px 16px;
                        font-size: 16px;
                        letter-spacing: -0.5px;
                        color: #ffffff;
                        width: 160px;

                        &:nth-child(2) {
                            width: calc(50% - 80px);
                            background: #31AD5F;
                            border-radius: 8px 0 0 0;
                        }
                        
                        &:nth-child(3) {
                            width: calc(50% - 80px);
                            background: #00684F;
                            border-radius: 0 8px 0 0;
                        }
                    }
                }
            }

            tbody {
                tr {
                    background: none;
                    
                    td {
                        padding: 8px 16px;
                        font-size: 16px;
                        letter-spacing: -0.3px;
                        vertical-align: center;
                        // border: none;
                        // border-bottom: solid 1px #F3FCF2;

                        .upto-sync {
                            // font-size: 12px;
                            color: #667062;
                        }

                        .green-tick-span {
                            // font-size: 12px;
                            color: #667062;
                        }

                        img {
                            position: relative;
                            margin: 0 4px 0 0;
                            top: -1px;
                            width: 13px;
                            height: 13px;
                        }

                        &:nth-child(1) {
                            background: #ffffff !important;
                            text-align: left;
                            font-size: 14px;
                            letter-spacing: -0.4px;
                            width: 160px;
                        }
                    }

                    &:nth-child(odd) {
                        // background: #D1F4CC;
                        td {
                            &:nth-child(n + 1) {
                                background: #D1F4CC;
                                width: calc(50% - 80px);
                            }
                        }
                    }

                    &:nth-child(even) {
                        background: #F3FCF2;

                        td {
                            &:nth-child(n + 1) {
                                width: calc(50% - 80px);
                            }
                        }
                    }

                    &:nth-last-child(1) {
                        td {
                            border-bottom: none;
                        }
                    }
                }
            }
        }
    }
}