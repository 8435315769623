.pay-bank-transfer-wrapper {
    .please-transfer-to {
        font-size: 14px;
        letter-spacing: -0.4px;
        margin-top: 24px;
    }

    .orange-warning {
        color: #ff7d00;
        font-size: 12px;
        letter-spacing: -0.4px;

        svg {
            position: relative;
            top: -1px;
            margin-right: 4px;
        }
    }

    .bank-transfer-box {
        border-radius: 8px;
        border: solid 1px #dcdcdc;
        position: relative;
        width: 291px;
        font-size: 16px;
        letter-spacing: -0.5px;
        height: 48px;
        line-height: 45px;
        padding: 0 12px;
        cursor: pointer;
        
        &.bank-account {
            padding: 0 12px 12px 66px;
        }

        img {
            position: absolute;
            top: 50%;
            left: 12px;
            transform: translate(0, -50%);
        }

        svg {
            color: #a3a3a3;
            font-size: 18px;
            cursor: pointer;
            position: absolute;
            top: 50%;
            right: 12px;
            transform: translate(0, -50%);
        }
        
        
        &:hover {
            svg {
                color: #ff7d00;
            }
        }
    }

    .alert-component {
        font-size: 14px;
        letter-spacing: -0.4px;
        width: 323px;
        position: relative;
        padding: 12px 16px 12px 38px;

        svg {
            top: 22px;
            left: 16px;
            font-size: 16px;
        }
    }

    .display-none {
        display: none;
    }
}