.subscriptions-outer-wrapper {
  widows: 100%;
  position: relative;

  .subscription-list-loading-wrapper {
    height: 80vh;
    .lds-ring {
      width: 50px;
      height: 50px;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      right: unset;

      div {
        border: 5px solid #fff;
        border-color: #ff7d00 transparent transparent transparent;
        width: 50px;
        height: 50px;
        margin: 0;
      }
    }
  }

  .empty-subscription-wrapper {
    width: 100%;
    margin-top: 56px;
    text-align: center;
    font-size: 16px;
    letter-spacing: -0.5px;
    color: rgba(25, 25, 25, 0.6);

    button {
      margin: 24px 0 0;
      height: 40px;
      width: 121px;
    }
  }

  .alert-component {
    background: #fff4b1;
    border: 1px solid #ff7e00;
    box-shadow: 0px 2px 20px rgba(25, 25, 25, 0.18);
    width: 100%;
    color: #ff7e00;
    position: relative;

    .orange-button {
      position: absolute;
      right: 16px;
      top: 50%;
      transform: translate(0, -50%);
      padding: 0 24px;
      height: 32px;
    }
  }

  .subscriptions {
    .subscriptions-header {
      font-size: 20px;
      letter-spacing: -0.6px;
      color: #191919;
      padding-bottom: 11px;
      border-bottom: solid 1px #dcdcdc;
      position: relative;

      .subscriptions-header-button {
        position: absolute;
        right: 0;
        top: -10px;
        font-size: 16px;
        padding: 0 24px;
        letter-spacing: -.5px;
        line-height: 38px;
        height: 40px;

        svg {
          position: relative;
          margin-right: 4px;
          top: -1px;
        }
      }
    }

    .subscription-table {
      margin-top: 24px;
      width: 100%;

      table {
        width: 100%;

        thead {
          tr {
            border-bottom: solid 1px rgba(25, 25, 25, 0.1);

            td {
              padding: 8px;
              text-align: left;
              color: #191919;
              font-size: 14px;
              letter-spacing: -0.4px;

              &.col_desc {
                padding-left: 0;
              }

              &.col_price {
                width: 150px;
              }

              &.col_paid {
                width: 120px;
              }

              &.col_status {
                width: 160px;
              }
            }
          }
        }

        tbody {
          tr {
            td {
              padding: 16px 8px;
              text-align: left;
              color: #191919;
              font-size: 14px;
              letter-spacing: -0.2px;

              .subscription-label {
                padding: 0;
                &.active {
                  color: #19c700;
                }

                &.alert {
                  color: #ff7e00;
                }

                img {
                  position: relative;
                  margin-right: 4px;
                  top: -2px;
                }
              }

              &.unsubscribe {
                display: flex;
                align-items: center;
                color: #ff3f57;
                justify-content: flex-end;

                img {
                  margin-right: 4px;
                }
              }

              &.col_desc {
                padding-left: 0;
              }

              
              &.col_price {
                width: 150px;
              }
              
              &.col_paid {
                width: 120px;
              }
              
              &.col_status {
                width: 160px;
              }

              &.col_total {
                background: #dcebff;
                font-size: 16px;
                letter-spacing: -0.5px;
                text-align: right;
                width: 100%;
              }
            } 
          }
        }
      }
    }
  }
}
