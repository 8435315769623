.modal-login-salestalk {
    .modal-content {
        border-radius: 12px;
        width: 500px;

        .modal-body {
            text-align: center;
            padding: 80px;
            position: relative;

            .loading-login {
                width: 26px;
                height: 26px;
                margin-top: 10px;
                
                div {
                    width: 26px;
                    height: 26px;
                    border: solid 3px #ff7d00;
                    border-color: #ff7d00 transparent transparent transparent;
                    margin: 0;
                }
            }

            .modal-login-salestalk-close {
                color: #ff7d00;
                position: absolute;
                right: 12px;
                top: 16px;
                font-size: 28px;
                cursor: pointer;

                &:hover {
                    color: #ff7d00;
                }
            }
            
            .modal-login-salestalk-title {
                font-size: 28px;
                margin: 0 0 0;
                line-height: 1;
                // text-transform: capitalize;
                color: #1a1a1a;

                b {
                    font-family: NeueBold !important;
                }
            }
            
            .modal-login-salestalk-desc {
                font-size: 13px;
                margin: 5px 0 28px 0;
                color: #707070;
                // text-transform: capitalize;
                font-family: LatoMedium !important;
            }

            
            .modal-login-salestalk-phone-number {
                position: relative;
                text-align: left;
                margin: 0 0 10px 0;
                
                label {
                    font-size: 16px;

                    b {
                        font-family: LatoBold !important;
                    }
                }

                span {
                    position: absolute;
                    font-size: 16px;
                    color: #191919;
                    top: 50%;
                    transform: translate(0, -50%);
                    left: 12px;
                    font-family: LatoMedium !important;
                }

                input {
                    border: solid 1px #dcdcdc;
                    border-radius: 4px;
                    height: 42px;
                    line-height: 42px;
                    width: 100%;
                    padding: 0 12px 0 39px;
                    font-family: LatoMedium !important;
                }
            }

            button {
                width: 100%;
                border-radius: 6px;

                b {
                    font-family: NeueBold !important;
                }
            }
        }
    }
}

.modal-login-otp-salestalk {
    .modal-content {
        border-radius: 12px;
        width: 500px;

        .modal-body {
            text-align: center;
            padding: 80px;
            position: relative;

            .modal-login-otp-salestalk-close {
                color: #ff7d00;
                position: absolute;
                right: 12px;
                top: 24px;
                font-size: 28px;
                cursor: pointer;

                &:hover {
                    color: #ff7d00;
                }
            }
            
            .modal-login-otp-salestalk-title {
                font-size: 28px;
                margin: 0 0 24px 0;
                line-height: 1;
                color: #1a1a1a;

                b {
                    font-family: NeueBold !important;
                }
            }
            
            .modal-login-otp-salestalk-desc {
                font-size: 13px;
                margin: 5px 0 28px 0;
                color: #191919;
                font-family: LatoMedium !important;

                b {
                    font-family: NeueBold !important;
                }

                .send-otp-to-img {
                    width: 16px;
                    position: relative;
                }

                .lds-ring {
                    top: unset;
                    right: unset;
                    margin-right: 4px;
                    position: relative;
                    top: 3px;

                    div {
                        margin: 0;
                    }
                }

                .resent-otp-button {
                    margin: 5px 0 0;
                    color: #ff7d00;
                    cursor: pointer;

                    &:hover {
                        color: var(--main-darker-orange);
                    }

                    &.counter-timer {
                        color: #191919;
                    }
                }
            }

            
            .modal-login-otp-salestalk-phone-number {
                position: relative;
                text-align: center;
                margin: 0;

                .lds-ring {
                    top: unset;
                    right: unset;
                    width: 38px;
                    height: 38px;

                    div {
                        margin: 0;
                        width: 38px;
                        height: 38px;
                        border: 4px solid #fff;
                        border-color: var(--main-dark-orange) transparent transparent transparent;
                    }
                }

                .otp-invalid {
                    margin: 8px 0 0;
                    font-size: 13px;
                    line-height: 1;
                }

                input {
                    border: none;
                    border-bottom: solid 2px #a8a8a8;
                    border-radius: 0;
                    height: 34px;
                    width: 34px;
                    display: inline-block;
                    margin: 0 18px 10px 0;
                    padding: 0 4px;
                    text-align: center;
                    // border-bottom: solid 2px #ff7d00;
                    font-family: PTRootBold;
                    font-size: 24px;

                    &:nth-last-child(1) {
                        margin: 0;
                    }

                    &.filled {
                        border-bottom: solid 2px #ff7d00;
                    }

                    // &:focus {
                        // border-bottom: solid 2px #ff7d00;
                    // }
                }
            }

            button {
                width: 100%;
            }
        }
    }
}

.modal-login-success-salestalk {
    .modal-content {
        border-radius: 12px;
        width: 500px;

        .modal-body {
            text-align: center;
            padding: 24px;
            position: relative;

            .modal-login-success-salestalk-close {
                color: #ff7d00;
                position: absolute;
                right: 24px;
                top: 26px;
                font-size: 28px;
                cursor: pointer;

                &:hover {
                    color: var(--main-darker-orange);
                }
            }
            
            .modal-login-success-salestalk-title {
                font-size: 28px;
                margin: 0 0 24px 0;
                line-height: 1;

                b {
                    font-family: NeueBold !important;
                }
            }

            .modal-login-success-salestalk-desc {
                font-size: 16px;
                margin: 28px 0 24px 0;
                color: #1a1a1a;
                // text-transform: capitalize;
                font-family: LatoMedium !important;
            }

            button {
                width: 100%;

                b {
                    font-family: NeueBold !important;
                }
            }
        }
    }
}