.chat-room-input-email-attachment {
    position: fixed;
    top: 0;
    left: 0;
    width: 100% !important;
    height: 100%;
    background: rgba(25, 25, 25, 0.8);
    visibility: hidden;
    opacity: 0;
    right: 0;
    -webkit-transition: visibility .3s, opacity 0.5s ease-in-out;
    transition: visibility .3s, opacity 0.5s ease-in-out;
    z-index: 9998;

    &.active-chat-room-input-email-attachment {
        /* right: 0; */
        opacity: 1;
        visibility: visible;
        transition: visibility 0s, opacity 0.5s ease-in-out;
    }

    .close-modal-chat-room-input-email-attachment {
        border-radius: 50%;
        width: 48px;
        height: 48px;
        background: #ffffff;
        color: #757575;
        position: absolute;
        left: -65px;
        top: 0;
        text-align: center;
        cursor: pointer;
        
        &:hover {
            background: #f9f9f9;
        }
        
        svg {
            position: absolute;
            font-size: 25px;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }

    .modal-body {
        padding: 32px 32px 104px 32px;
        position: absolute;
        top: 8px;
        right: -100%;
        left: unset;
        -webkit-transform: unset;
        transform: unset;
        height: calc(100% - 8px);
        width: 600px;
        display: inline-block;
        border-radius: 8px 0 0 0;
        background: #ffffff;
        max-height: calc(100% - 8px);
        -webkit-transition: ease-in-out .4s;
        transition: ease-in-out .4s;

        &.active-modal-body-chat-room-input-email-attachment {
            right: 0;
        }

        .chat-room-input-email-attachment-head {
            border-radius: 8px 8px 0 0;
            font-size: 20px;
            letter-spacing: -0.6px;
            position: relative;

            svg {
                position: relative;
                top: -2px;
                margin-right: 4px;
            }

            p {
                margin: 0;
                font-size: 14px;
                letter-spacing: -0.2px;
                color: rgba(25, 25, 25, 0.6);
            }
        }

        // .chat-room-input-email-attachment-neck {
        //     padding: 0 16px;

        //     .chat-room-input-email-attachment-neck-part {
        //         padding: 8px 0;
        //         border-bottom: solid 1px #dcdcdc;

        //         p {
        //             margin: 0;
        //             color: rgba(25, 25, 25, 0.8);
        //         }
        //     }
        // }

        .chat-room-input-email-attachment-body {
            padding: 16px 32px;
            width: 100%;
            position: absolute;
            top: 100px;
            left: 50%;
            transform: translate(-50%, 0%);
            height: calc(100% - 173px);
            overflow-y: auto;

            .chat-room-input-email-attachment-body-placeholder-wrapper {
                text-align: center;
                position: absolute;
                top: 50%;
                left: 50%;
                width: 100%;
                transform: translate(-50%, -50%);
                padding: 16px;
                font-size: 14px;
                letter-spacing: -0.4px;

                .icon-cloud {
                    color: #d1d1d1;
                    font-size: 124px;
                    display: inline-block;
                    margin: 0 0 18px 0;
                }
                
                .main-button-40 {
                    margin: 24px 0 0;
                }
            }

            .chat-room-input-email-attachment-value-wrapper {
                position: relative;
                display: block;
                width: 100%;

                .chat-room-input-email-attachment-value-list {
                    font-size: 16px;
                    letter-spacing: -0.5px;
                    padding: 8px 40px 8px 16px;
                    border-radius: 4px;
                    color: rgba(25, 25, 25, 0.8);
                    background: rgba(243, 243, 243, 1);
                    margin: 0 0 8px 0;
                    position: relative;

                    .filename {
                        margin: 0;
                        display: inline-block;
                        white-space: nowrap;
                        max-width: calc(100% - 62px);
                        overflow: hidden;
                        text-overflow: ellipsis;
                        vertical-align: top;
                    }

                    .filesize {
                        margin: 0;
                        display: inline-block;
                        white-space: nowrap;
                        vertical-align: top;
                    }

                    svg {
                        color: rgba(255, 63, 87, 1);
                        position: absolute;
                        top: 12px;
                        right: 16px;
                    }

                    .lds-ring {
                        position: absolute;
                        top: 11px;
                        right: 16px;

                        div {
                            margin: 0;
                        }
                    }
                }

                .button-add-attachment {
                    font-size: 14px;
                    letter-spacing: -0.5px;
                    color: var(--main-dark-orange);
                    cursor: pointer;

                    svg {
                        position: relative;
                        top: -2px;
                        margin-right: 4px;
                    }

                    &:hover {
                        color: var(--main-darker-orange);
                    }
                }
            }
        }

        .chat-room-input-email-attachment-button-footer {
            width: 100%;
            padding: 12px 24px;
            position: absolute;
            bottom: 0;
            left: 0;
            box-shadow: 0px 2px 20px 0px rgba(25, 25, 25, 0.18);

            button {
                width: 100%;
            }
        }
    }
}