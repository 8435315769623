.chat-room-input-message-wrapper {
	box-shadow: 0 2px 20px 0 rgba(25, 25, 25, 0.18);
	background: #fff;
	position: absolute;
	width: 100%;
	bottom: 0;
	padding: 7px 0 4px;

	.quick-reply-document-wrapper {
		height: 68px;
		width: 100%;
		background: #fff;
		padding: 10px 14px 10px 98px;
		position: absolute;
		top: -69px;
		left: 0;
		border-bottom: solid 1px rgba(25, 25, 25, 0.1);

		.clear-quick-reply-document {
			font-size: 22px;
			position: absolute;
			left: 10px;
			top: 22px;
			color: rgba(25, 25, 25, 0.2);
			cursor: pointer;
		}

		.quick-reply-document-icon {
			background: var(--main-dark-orange);
			width: 48px;
			height: 48px;
			border-radius: 50%;
			position: absolute;
			left: 40px;
			top: 10px;

			svg {
				color: #fff;
				font-size: 22px;
				position: absolute;
				left: 50%;
				top: 50%;
				transform: translate(-50%, -50%);
			}
		}

		p {
			font-size: 12px;
			letter-spacing: -0.4px;
			position: relative;
			top: 14px;
			white-space: nowrap;
			max-width: 100%;
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}

	.icon-salestalk {
		position: absolute;
		top: 10px;
		right: 80px;
		z-index: 1;
		width: 18px;
		cursor: pointer;

		&.icon-salestalk-grey {}

		&.icon-salestalk-orange {
			display: none;

			&:hover {
				display: block !important;
			}
		}

		&:hover {
			+.icon-salestalk-orange {
				display: block;
			}
		}

		&.icon-salestalk-46 {
			right: 46px;
		}
	}

	.cancel-start-conversation {
		position: absolute;
		left: 25px;
		bottom: 18px;
		color: rgba(25, 25, 25, .7);
		font-size: 18px;
		z-index: 1;
		cursor: pointer;

		&:hover {
			color: rgba(25, 25, 25, 1);
		}
	}

	.start-conversation-image-video-priview-small-box {
		border-radius: 8px;
		width: 38px;
		height: 38px;
		background: #c9c9c9;
		position: absolute;
		left: 52px;
		z-index: 1;
		bottom: 8px;
		cursor: pointer;

		img {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			max-width: 100%;
			max-height: 100%;
		}

		video {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			max-width: 100%;
			max-height: 100%;

			.icon-play-mini {
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				max-width: 100%;
				max-height: 100%;
			}
		}

		.icon-file-mini {
			color: #fff;
			font-size: 20px;
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
		}
	}

	.button-send-message {
		font-size: 14px;
		letter-spacing: -0.4px;
		height: 32px;
		line-height: 29px;
		border-radius: 100px;
		position: absolute;
		z-index: 1;
		bottom: 11px;
		right: 24px;

		&.take-over-send {
			// width: 206px;
			padding: 0 11px 0 16px;
		}

		&.assign-me-send {
			// width: 176px;
			padding: 0 11px 0 16px;
		}

		&.just-send {
			// width: 76px;
			padding: 0 11px 0 16px;
		}

		img {
			position: relative;
			margin-left: 4px;
			top: -2px;
		}

		.lds-ring {
			margin: 0;
			top: 4px;
			right: unset;

			div {
				margin: 0;
				border-color: #ffffff transparent transparent transparent;
			}
		}

		.lds-ring {
			margin-left: 4px;
		}
	}

	.chat-room-textarea-wrapper {
		display: inline-block;
		position: relative;
		margin-right: 16px;
		width: 100%;

		.emoji-picker-outer-wrapper {
			position: relative;
			width: 20px;
			height: 20px;
			display: inline-block;
			position: absolute;
			top: 9px;
			right: 46px;

			.emoji-picker-main-wrapper {
				position: absolute;
				padding-bottom: 22px;
				bottom: 15px;
				right: -55px;
				transition: opacity .2s;
				opacity: 0;
				visibility: hidden;

				&:hover {
					opacity: 1;
					visibility: visible !important;
				}
			}

			.emoji-toggle-button {
				top: unset;
				right: unset;
				font-size: 20px;

				&:hover {
					&~.emoji-picker-main-wrapper {
						opacity: 1 !important;
						visibility: visible !important;
					}
				}
			}

			&.emoji-picker-outer-wrapper-16 {
				right: 16px;
			}
		}

		textarea.main-textarea-input {
			z-index: 1;
			max-height: 138px;
			width: 100%;
			font-size: 16px;
			letter-spacing: -0.3px;
			resize: none;
			position: relative;
			top: -2px;
			// overflow: hidden;
			scrollbar-width: none;
			// overflow: -moz-scrollbars-none;
			// -ms-overflow-style: none;
			border-radius: 20px;
			border: none;
			box-shadow: 0 0 0 1px rgba(25, 25, 25, .1);
			padding: 9px 105px 9px 16px;
			box-sizing: border-box;
			max-height: 138px;

			&::-webkit-scrollbar {
				display: none;
			}

			&:focus {
				box-shadow: 0 0 0 1px #ff7d00;
			}

			&:read-only {
				cursor: not-allowed;
			}
		}

		svg {
			color: rgba(25, 25, 25, 0.6);
			position: absolute;
			right: 16px;
			top: 11px;
			font-size: 18px;
			cursor: pointer;
			z-index: 1;

			&:hover {
				color: #ff7d00;
			}
		}

		svg.active-paperclip {
			color: #ff7d00;
		}
	}

	.chat-send-wrapper {
		width: 32px;
		height: 32px;
		text-align: center;
		border-radius: 50%;
		background: #ff7d00;
		position: absolute;
		right: 14px;
		bottom: 10px;
		display: inline-block;
		cursor: pointer;
		transition: ease-in-out 0.1s;
		border: none;

		&:hover {
			background: #ef7702;
		}

		img {
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
		}
	}

	form {
		width: calc(100% - 72px);
		margin-left: 12px;
		display: inline-block;
		transition: ease-in-out 0.2s;

		&.start-conversation-active {
			width: calc(100% - 24px);

			textarea.main-textarea-input {
				padding-top: 9px;
				padding-right: 0;
				padding-bottom: 9px;
				padding-left: 36px;
			}
		}
	}

	&.just-send {
		textarea.main-textarea-input {
			padding-right: 106px !important;
		}
	}

	&.assign-me-send {
		textarea.main-textarea-input {
			padding-right: 194px !important;
		}
	}

	&.take-over-send {
		textarea.main-textarea-input {
			padding-right: 194px !important;
		}
	}

	&.start-conversation-with-image-or-video {
		textarea.main-textarea-input {
			padding-left: 91px !important;
		}
	}
}

.input-file-wrapper-chat {
	width: 167px;
	display: inline-block;
	box-shadow: 0px 0px 9px 0px rgba(25, 25, 25, 0.1);
	line-height: 1;
	position: absolute;
	right: 0;
	bottom: 35px;
	border-radius: 8px;
	z-index: 1;

	input[type=file] {
		display: none;
	}

	.input-file-content {
		width: 100%;
		position: relative;
		padding: 12px 12px 12px 34px;
		font-size: 14px;
		letter-spacing: -0.4px;
		color: rgba(25, 25, 25, 0.6);
		// border-bottom: solid 1px #dcdcdc;
		background: #fff;
		cursor: pointer;

		img {
			position: absolute;
			top: 11px;
			left: 12px;
		}

		&:hover {
			background: #ededed;

			svg {
				color: #fff;
			}
		}
	}

	label {
		width: 100%;
		margin: 0;

		&:nth-child(1) {
			.input-file-content {
				border-top-left-radius: 8px;
				border-top-right-radius: 8px;
			}
		}

		&:nth-last-child(1) {
			.input-file-content {
				border: none;
				border-bottom-left-radius: 8px;
				border-bottom-right-radius: 8px;
			}
		}
	}

	svg {
		left: 12px;
		font-size: 20px;
	}
}

.modal-media.modal-dialog {
	width: 100vw !important;
	position: relative;
}

.modal-media {
	.modal-content {
		width: 100%;
		height: 100%;
		background: none;

		.modal-body {
			width: 100%;
			height: 100%;
			background: #191919;
			padding: 0;
			position: relative;

			.preview-arrow-navigator {
				font-size: 48px;
				color: #a3a3a3;
				position: absolute;
				top: 40%;
				transform: translate(0, -50%);
				cursor: pointer;
				transition: ease-in-out 0.1s;

				&:hover {
					color: #fff;
				}
			}

			.preview-arrow-navigator.left-preview-navigator {
				left: 30px;
			}

			.preview-arrow-navigator.right-preview-navigator {
				right: 30px;
			}

			.drop-file-here-wrapper {
				width: 100%;
				height: 100%;
				position: absolute;
				top: 0;
				left: 0;
				display: none;
				text-align: center;
				font-size: 24px;
				letter-spacing: -0.5px;
				background: #eaeaea;
				z-index: 3;
				opacity: 0.9;

				svg {
					font-size: 48px;
					margin-bottom: 16px;
				}

				p {
					margin: 0;
				}

				.drop-file-here-content {
					width: 80%;
					height: 80%;
					padding: 40px;
					border: dashed 4px #191919;
					display: inline-block;
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
					line-height: 80%;
					color: #191919;

					.drop-file-here-inner-content {
						width: 100%;
						position: absolute;
						top: 50%;
						left: 50%;
						transform: translate(-50%, -50%);
						padding: 24px;
					}
				}
			}

			.drop-file-here-wrapper.active-drop-file-here-wrapper {
				display: block;
			}

			.video-image-wrapper {
				display: inline-block;
				position: absolute;
				top: 43%;
				left: 50%;
				transform: translate(-50%, -50%);
				max-width: 90vw;
				min-width: 450px;

				.preview-container {
					text-align: center;

					.blocking-image-preview {
						position: absolute;
						width: 100%;
						height: 100%;
						top: 0;
						left: 0;
						background: transparent;
					}

					img {
						max-width: calc(100vw - 170px);
						max-height: calc(100vh - 320px);
						pointer-events: none;
					}

					video {
						max-width: calc(100vw - 170px);
						max-height: calc(100vh - 320px);
					}
				}

				.caption-container {
					position: relative;

					textarea {
						resize: none;
						background: rgba(25, 25, 25, 0.4);
						max-height: 71px;
						overflow: auto;
						width: 100%;
						// overflow: hidden;
						text-overflow: ellipsis;
						border: none;
						height: 71px;
						// background: none;
						color: #fff;
						box-shadow: none;
						padding: 8px 0 8px 0;
						border-radius: 0;
						font-size: 16px;
						letter-spacing: -0.3px;
						border-bottom: solid 1px #dcdcdc;

						&::placeholder {
							color: #fff;
						}

						&::-webkit-scrollbar {
							display: none;
						}
					}


					.airplane-icon {
						position: absolute;
						right: 0;
						top: 50%;
						transform: translate(0, -50%);
						cursor: pointer;
						transition: ease-in-out 0.3s;

						&:hover {
							transform: translate(0, -50%) scale(0.95);
						}
					}

					.caption-limit-text {
						position: absolute;
						color: #fff;
						right: 0;
						font-size: 11px;
						bottom: -18px;
					}
				}
			}

			.close-modal-video {
				position: absolute;
				top: 20px;
				left: 16px;
				color: #fff;
				font-size: 40px;
				cursor: pointer;
				transition: ease-in-out 0.1s;

				&:hover {
					color: #c9c9c9;
				}
			}
		}
	}

	.selected-file-wrapper {
		width: 100%;
		height: 120px;
		box-shadow: 0 -1px 0 0 rgba(255, 255, 255, 0.2);
		position: absolute;
		bottom: 0;
		left: 0;
		padding: 12px 312px 12px 106px;

		.file-counter {
			letter-spacing: -0.6px;
			font-size: 24px;
			color: #fff;
			left: 32px;
			top: 42px;
			position: absolute;
		}

		.selected-file-container {
			position: relative;
			height: 100%;
			text-align: right;
			max-width: 100%;
			white-space: nowrap;
			overflow-x: auto;

			label[for="media-input"] {
				height: 100%;
				width: 96px;
				margin: 0;
			}

			[type="file"] {
				display: none;
			}

			.add-more-file-button {
				font-size: 12px;
				letter-spacing: -0.2px;
				color: #fff;
				width: 96px;
				height: 100%;
				border-radius: 8px;
				border: solid 1px #fff;
				text-align: center;
				display: inline-block;
				vertical-align: top;
				padding-top: 24px;
				cursor: pointer;
				transition: ease-in-out 0.1s;

				&:hover {
					border: solid 1px #ff7d00;
				}

				svg {
					font-size: 30px;
				}

				img {
					margin-bottom: 8px;
				}
			}

			.selected-file-content {
				vertical-align: top;
				width: 96px;
				height: 100%;
				border-radius: 8px;
				text-align: center;
				margin-left: 12px;
				display: inline-block;
				cursor: pointer;
				transition: ease-in-out 0.1s;
				position: relative;
				border: solid 1px transparent;
				overflow: hidden;
				border: solid 1px #fff;

				&:nth-child(1) {
					margin-left: 0;
				}

				.media-thumbnail {
					max-width: calc(100% - 2px);
					max-height: calc(100% - 2px);
					position: absolute;
					left: 50%;
					top: 50%;
					transform: translate(-50%, -50%);
				}

				.remove-selected-file-button {
					width: 24px;
					height: 24px;
					border-radius: 50%;
					background: #e34d6f;
					color: #fff;
					border: solid 2px #fff;
					display: inline-block;
					position: absolute;
					left: 50%;
					top: 50%;
					transform: translate(-50%, -50%);
					text-align: center;
					display: none;
					z-index: 1;

					svg {
						position: relative;
						top: -4px;
					}
				}

				.dark-layer {
					position: absolute;
					top: 0;
					left: 0;
					border-radius: 8px;
					width: 100%;
					height: 100%;
					background: rgba(25, 25, 25, 0.4);
				}

				&:hover {
					.dark-layer {
						display: none;
					}
				}
			}

			.selected-file-content.active-selected-file {
				border: solid 1px var(--main-dark-orange);

				.remove-selected-file-button {
					display: block;
				}

				.dark-layer {
					display: none;
				}
			}
		}

		.send-media-button-wrapper {
			padding: 36px 44px;
			display: inline-block;
			width: 312px;
			position: absolute;
			right: 0;
			top: 0;
			height: 100%;

			button {
				font-size: 16px;
				letter-spacing: -0.5px;
				width: 100%;
				height: 100%;

				img {
					width: 20px;
					margin-right: 4px;
				}
			}
		}
	}
}

.taplive-main-chat-room-send-message-hamburger {
	border-radius: 50%;
	background: #ff7d00;
	width: 32px;
	height: 32px;
	text-align: center;
	position: relative;
	top: 4px;
	display: inline-block;
	vertical-align: top;
	cursor: pointer;
	margin-left: 14px;

	&:hover {
		background: #ef7702;
	}

	img {
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
	}
}

.onetalk-main-chat-room-solve-wrapper {
	height: 40px;
	position: relative;
	width: 100%;
	top: 4px;
	padding: 12px 33px 0 50px;
	color: #191919;
	font-size: 16px;
	letter-spacing: -0.5px;
	cursor: pointer;
	transition: ease-in-out 0.2s;
	line-height: 1;
	background: #efefef;

	&:hover {
		background: #ece9e9;
	}

	.taplive-lds-ring {
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);

		div {
			border-color: #ff7d00 transparent transparent transparent;
		}
	}

	img {
		vertical-align: top;
		position: absolute;
		top: 11px;
		left: 20px;
		margin-right: 4px;
	}

	.lds-ring {
		position: absolute;
		left: 50%;
		top: 50%;
		right: unset;
		transform: translate(-50%, -50%);

		div {
			margin: 0;
		}
	}
}

.onetalk-main-chat-room-solve-wrapper.onetalk-main-chat-room-solve-wrapper-hide {
	height: 0;
	overflow: hidden;
	padding: 0;
}

.quick-reply-outer-wrapper {
	z-index: 1;
	width: 100%;
	position: absolute;
	bottom: 54px;
	left: 0;
	box-shadow: 0px -4px 6px 0px rgba(25, 25, 25, 0.1);
	background: #fff;
	overflow: hidden;

	.quick-reply-inner-wrapper {
		max-height: 257px;
		overflow: auto;
	}

	.quick-reply-title-wrapper {
		font-size: 12px;
		color: rgba(25, 25, 25, 0.8);
		letter-spacing: -0.4px;
		padding: 12px 16px 10px;
		line-height: 1;
	}

	.quick-reply-list-option-wrapper {
		font-size: 14px;
		letter-spacing: -0.2px;
		color: #100;
		padding: 8px 16px 8px 16px;
		cursor: pointer;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		margin: 0;
		position: relative;

		span {
			color: rgba(25, 25, 25, 0.6);
		}

		&:hover {
			background: rgba(255, 126, 0, 0.1);
		}

		&.active-quick-reply-list-option-wrapper {
			background: rgba(255, 126, 0, 0.1);
		}

		&.quick-reply-list-option-wrapper-with-media {
			padding: 8px 50px 8px 16px
		}

		.quick-reply-media-wrapper {
			border-radius: 4px;
			width: 32px;
			height: 32px;
			border: solid 1px rgba(25, 25, 25, .1);
			position: absolute;
			right: 8px;
			top: 3px;
			overflow: hidden;

			img {
				max-width: 100%;
				max-height: 100%;
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
			}

			video {
				max-width: 100%;
				max-height: 100%;
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
			}

			svg {
				max-width: 100%;
				max-height: 100%;
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				color: var(--main-dark-orange);
			}

			.quick-reply-video-wrapper {
				background: rgba(25, 25, 25, 0.6);
				position: absolute;
				top: 50%;
				left: 50%;
				width: 100%;
				height: 100%;
				transform: translate(-50%, -50%);

				svg {
					color: #fff;
				}

				&.quick-reply-video-wrapper-orange {
					background: var(--main-dark-orange) !important;

					color: #fff !important;
				}
			}
		}
	}
}

.modal-confirmation-send-as-email {
	.modal-footer {
		button {
			width: calc(50% - 2px) !important;
			margin-right: 4px !important;

			&:nth-last-child(1) {
				margin-right: 0 !important;
			}
		}
	}
}