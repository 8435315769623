.chatbot-setup-page-wrapper {
    .text-info-top {
        margin: 16px 0 0;
        font-size: 14px;
        line-height: 21px;
        letter-spacing: -0.2px;
        color: #191919;

        svg {
            position: relative;
            top: -2px;
            margin-right: 4px;
        }

        p {
            margin: 0 0 0 32px;
        }
    }

    .knowledge-source {
        display: inline-block;
        vertical-align: top;
        margin-top: 32px;
        // width: 480px;
        width: 50%;
    
        .knowledge-source-title {
            margin-bottom: 4px;
            font-size: 20px;
            line-height: 24px;
            letter-spacing: -0.6px;
            color: #191919;
        }
    
        .knowledge-source-description {
            margin-bottom: 12px;
            font-size: 16px;
            line-height: 24px;
            letter-spacing: -0.3px;
            color: #191919CC;
        }

        .knowledge-source-input-wrapper {
            position: relative;

            .tooltip-icon {
                position: absolute;
                top: 4px;
                right: 4px;
                color: #191919;
                cursor: pointer;
            }

            .nav-item {
                cursor: pointer;
                color: rgba(25, 25, 25, 0.4);
                font-size: 16px;
                letter-spacing: -0.5px;
                padding: 0 8px 12px;
                margin-right: 24px;
                height: 36px;
        
                a {
                    padding: 0;
                    border: none;
                }
        
                :hover {
                    color: var(--main-dark-orange);
                }
        
                &.active-setup-tab {
                    color: var(--main-dark-orange);
                    border-bottom: solid 4px var(--main-dark-orange);
                }
            }
        
            .tab-content {
                margin: 16px 0 0;
            }
        }
    }

    .pending-changes-wrapper {
        margin-top: 3px;
        display: inline-block;
        vertical-align: top;
        margin: 60px 0 0 24px;
        // width: calc(100% - 496px);
        width: calc(50% - 24px);

        .pending-changes {
            margin-bottom: 16px;
            background: #FAFAFA;
            border: none;

            span {
                display: block;
                padding-bottom: 8px;
                width: 100%;
                font-size: 16px;
                border-bottom: solid 1px #1919191A;

                b {
                    line-height: 24px;
                    letter-spacing: -0.5px;
                    color: #191919;
                }

                svg {
                    margin-left: 4px;
                    color: #19191999;
                    transform: translateY(-1px);
                    cursor: pointer;
                }
            }

            .pending-changes-discard {
                position: absolute;
                top: 32px;
                right: 32px;
                padding: 0 12px 0 8px;
                width: 136px;
                height: 26px;
                border: none;
                cursor: pointer;
            }

            .trained-sources-discard {
                position: absolute;
                top: 32px;
                right: 32px;
                padding: 0 12px 0 8px;
                width: 94px;
                height: 26px;
                border: none;
                cursor: pointer;
            }

            .pending-changes-discard, .trained-sources-discard {
                svg {
                    margin: 0;
                    font-size: 12px;
                    color: #FF3F57;
                }

                b {
                    margin-left: 4px;
                    font-size: 14px;
                    letter-spacing: -0.5px;
                    color: #FF3F57;
                }

            }
        }

        .pending-changes-empty {
            padding: 24px 12px 12px 12px;
            width: 100%;
            text-align: center;
            font-size: 14px;
            line-height: 21px;
            letter-spacing: -0.2px;
            color: #19191999;
        }
        
        .pending-change-list {
            position: relative;
            width: 100%;
            height: 44px;
            border-bottom: solid 1px #1919191A;

            .pending-change-list-new {
                display: inline-block;
                margin: 12.5px 4px 0 0;
                padding: 2px 8px;
                width: 42px;
                height: 19px;
                background-color: #EBF7FF;
                border-radius: 4px;
                text-align: center;
                font-family: PTRootMedium;
                font-size: 10px;
                line-height: 15px;
                letter-spacing: 1.5px;
                color: #0C5F99;
            }

            .pending-change-list-remove {
                display: inline-block;
                margin: 12.5px 4px 0 0;
                padding: 2px 8px;
                width: 63px;
                height: 19px;
                background-color: #FFECEE;
                border-radius: 4px;
                text-align: center;
                font-family: PTRootMedium;
                font-size: 10px;
                line-height: 15px;
                letter-spacing: 1.5px;
                color: #FF3F57;
            }

            .trained-source-list-default {
                display: inline-block;
                margin: 8px 0 0 0;
                padding: 0px;
                width: 0px;
                height: 19px;
                background-color: white;
                text-align: center;
                font-family: PTRootMedium;
                font-size: 10px;
                line-height: 15px;
                letter-spacing: 1.5px;
                color: white;
            }

            .pending-change-list-text-container {
                display: inline-block;
                margin: 0 8px 0 0;

                .pending-change-list-name {
                    display: inline-block;
                    vertical-align: middle;
                    max-width: calc(100% - 40px);
                    font-size: 14px;
                    line-height: 21px;
                    letter-spacing: -0.2px;
                    color: #191919;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden;
                }
    
                .pending-change-list-extension {
                    display: inline-block;
                    vertical-align: middle;
                    font-size: 14px;
                    line-height: 21px;
                    letter-spacing: -0.2px;
                    color: #191919;
                }
            }

            .width-new {
                width: calc(100% - 114px);
            }


            .width-remove {
                width: calc(100% - 135px);
            }


            .width-default {
                width: calc(100% - 70px);
            }

            .pending-change-list-button-wrapper {
                position: absolute;
                top: 8px;
                right: 4px;

                svg, img {
                    display: inline-block;
                    margin-right: 4px;
                    width: 24px;
                    height: 24px;
                    padding: 4px;
                    border-radius: 4px;
                    cursor: pointer;
                }

                .color-orange {
                    color: #FF7E00;
                    transition: ease-in-out 0.2s;
                    
                    &:hover {
                        background-color: #FFF2E5;
                    }
                }

                .color-red {
                    color: #FF3F57;
                    transition: ease-in-out 0.2s;
                    
                    &:hover {
                        background-color: #FFECEE;
                    }
                }

                .color-black {
                    color: #191919;
                    transition: ease-in-out 0.2s;
                    
                    &:hover {
                        background-color: #EFEFEF;
                    }
                }
            }
        }

        .trained-source-list {
            margin-top: 0px;
        }
    }
}

.knowledge-button-wrapper {
    position: relative;
    top: 6px;
}

.tooltip-source-type {
    .bs-tooltip-auto[x-placement^=right] .arrow::before, .bs-tooltip-right .arrow::before {
        border-right-color: #6B6B6B !important;
    }

    .tooltip .arrow::before {
        border-color: transparent !important;
    }
    
    .tooltip-inner {
        padding: 6px 8px !important;
        min-width: 240px !important;
        font-family: "PTRootUI" !important;
        font-size: 12px !important;
        line-height: 16px !important;
        letter-spacing: -0.2px !important;
        border-radius: 8px !important;
        color: #FFFFFF !important;
        background: #6B6B6B !important;
        opacity: 1 !important;

        ul {
            margin: 0;
            padding-left: 24px;

            li {
                margin: 2px 0;
            }
        }
    }
}

.tooltip-pending-changes {
    .bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
        border-bottom-color: #6B6B6B;
    }

    .bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
        border-top-color: #6B6B6B;
    }
    
    .tooltip.show {
        opacity: 1 !important;
        margin-top: 4px;
    }
    
    .tooltip-inner {
        padding: 8px 16px !important;
        margin-left: -16px;
        min-width: 224px !important;
        font-family: "PTRootUI" !important;
        font-size: 12px !important;
        line-height: 16px !important;
        letter-spacing: -0.2px !important;
        border-radius: 8px !important;
        color: #FFFFFF !important;
        background: #6B6B6B !important;
        opacity: 1 !important;
    }
}

.modal-view-text-source {
    
    width: 35vw !important;
    
    .modal-content {
        position: relative !important;
        padding: 0;
        max-height: 85vh;
        border-radius: 8px;
        overflow-y: scroll;
        -ms-overflow-style: none;
        scrollbar-width: none;

        // &::-webkit-scrollbar {
        //     width: 12px;
        // }
        
        // &::-webkit-scrollbar-thumb {
        //     border: 4px solid #00000000;
        //     border-radius: 8px;
        //     background-color: #1919194D;
        //     background-clip: padding-box;
        // }

        .modal-body {

            position: relative;
            padding: 40px 32px 16px 32px;

            .close-button {
                position: absolute;
                top: 16px;
                right: 16px;
                width: 24px;
                height: 24px;
                color: #FF7E00;
                cursor: pointer;
            }

            .modal-view-text-source-field {
                
                margin-bottom: 16px;

                .modal-view-text-source-field-title {
                    font-size: 16px;
                    line-height: 24px;
                    letter-spacing: -0.5px;
                    color: #191919;
                }

                .modal-view-text-source-field-content {
                    margin: 8px 0 0 0;
                    padding: 12px 16px;
                    border-radius: 8px;
                    background: #1919191A;
                    font-size: 16px;
                    line-height: 24px;
                    letter-spacing: -0.3px;
                    color: #191919CC;
                    word-wrap: break-word;
                    white-space: pre-line;
                }
            }
        }
    }
}
