.error-product-component {
    padding: 0 24px;
    position: absolute;
    left: 50%;
    top: 60%;
    transform: translate(-50%, -50%);
    text-align: center;

    .error-title {
        font-size: 16px;
        letter-spacing: -0.5px;
        color: #191919;
        margin: 0 0 8px 0;

        svg {
            color: rgba(255, 63, 87, 1);
            position: relative;
            margin-right: 4px;
            top: -1px;
        }
    }

    .error-text {
        font-size: 16px;
        letter-spacing: -0.3px;
        color: rgba(25, 25, 25, 0.6);
    }
}