.chat-room-product-detail-modal-wrapper {
	width: 100vw;
	height: 100vh;
	background: rgba(25, 25, 25, 0.8);
	position: fixed;
	top: 0;
	visibility: hidden;
	opacity: 0;
	right: 0;
	transition: visibility .3s, opacity 0.5s ease-in-out;
	z-index: 4;
	.user-info-tab-content {
		width: 50%;
	}
}
.chat-room-product-detail-modal-wrapper.active-chat-room-product-detail-modal-wrapper {
	opacity: 1;
	visibility: visible;
	transition: visibility 0s, opacity 0.5s ease-in-out;
}
.chat-room-product-detail-modal-content {
	position: absolute;
	width: 300px;
	// height: calc(100vh - 8px);
    height: 100vh;
    right: -100vw;
	top: 0;
	display: inline-block;
	transition: ease-in-out .5s;
}
.chat-room-product-detail-modal-content.active-chat-room-product-detail-modal-content {
	right: 0;
	background: #ffffff;
	border-top-left-radius: 18px;
}

.close-modal-product-detail-wrapper {
    background: #ffffff;
    border-radius: 50%;
    cursor: pointer;
    position: absolute;
    width: 48px;
    height: 48px;
    display: inline-block;
    text-align: center;
    line-height: 45px;
    top: 8px;
    left: -64px;

    svg {
        font-size: 24px;
        color: #7D7C7D;
    }

    &:hover {
        svg {
            color: #5c5c5c;
        }
    }
}

.product-detail-modal-inner-wrapper {
    .product-title {
        // display: -webkit-box;
        // overflow: hidden;
        // text-overflow: ellipsis;
        // -webkit-box-orient: vertical;
        // -webkit-line-clamp: 2;
        // text-overflow: ellipsis;
        margin: 0;
        max-width: 100%;
        padding: 16px 24px;
        text-align: center;
        
        p {
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            line-height: 24px;
            color: #191919;
            margin: 0;
            font-size: 20px;
            letter-spacing: -0.6px;
            word-break: break-word;
        }
    }

    .product-detail {
        height: calc(100vh - 56px);
        box-shadow: 0px 2px 20px rgba(25, 25, 25, 0.18);

        .product-detail-inner {
            padding: 16px 16px 26px;
            // box-shadow: 0px 2px 20px rgba(25, 25, 25, 0.18);
            
            .main-product-detail-wrapper {
                padding: 0 8px;
            }

            .image-wrapper {
                width: 100%;
                height: 220px;
                background-color: rgba(25, 25, 25, 0.05);
                border-radius: 8px;
                position: relative;
                overflow: hidden;
                margin-bottom: 8px;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }
            }

            label {
                font-size: 14px;
                letter-spacing: -0.4px;
                color:rgba(25, 25, 25, 0.8);
                margin: 0 0 4px 0;
            }

            .value-wrapper {
                padding: 12px 8px;
                margin: 0 0 4px 0;
                border-radius: 8px;
                background-color: rgba(25, 25, 25, 0.05);
                font-size: 12px;
                letter-spacing: -0.2px;
                color: #191919;
                position: relative;
                word-break: break-word;

                &.read-more {
                    max-height: 100px;
                    overflow: hidden;
                    display: -webkit-box;
                    -webkit-line-clamp: 4;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    padding-bottom: 25px;
                }


                .read-more-wrapper {
                    display: block;
                    width: 100%;
                    background-color: #f3f3f3;
                    color: #ff7d00;
                    font-size: 10px;
                    letter-spacing: -0.4px;
                    position: absolute;
                    bottom: 0;
                    right: 0;
                    padding: 0 8px 6px;
                    text-align: right;

                    b {
                        cursor: pointer;

                        &:hover {
                            color: #e87200;
                        }
                    }
                }

                &.product-url {
                    padding: 12px 60px 12px 8px;
                    
                    .copy-url {
                        cursor: pointer;
                        color: rgba(25, 25, 25, 0.6);
                        position: absolute;
                        right: 12px;
                        top: 12px;
                        // transform: translate(0, -50%);

                        svg {
                            margin-right: 4px;
                        }

                        &:hover {
                            color: rgba(25, 25, 25, 1);
                        }
                    }
                }

                #copy-product-url {
                    border: 0;
                    width: 100%;
                    background: transparent;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                }
            }
        }
    }
}
