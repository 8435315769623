.form-add-api-key {
    .orange-button {
        margin-top: 32px;
        width: unset !important;
    }

    .description-wrapper {
        position: relative;
        display: inline-block;
        font-size: 14px;
        letter-spacing: -0.2px;
        color: rgba(25, 25, 25, 0.6);
        
        span {
            position: absolute;
            right: 0;
            top: -25px;
        }
    }
}