#page-account-security {
    margin-top: 24px;
    font-size: 24px;
    font-weight: bold;
    letter-spacing: -0.4px;
    color: #191919;
    /* border-bottom: solid 1px #dcdcdc; */
    /* padding-bottom: 30px; */
    position: relative;
}

#page-account-security #instruction-text {
    font-size: 14px;
    letter-spacing: -0.2px;
    color: #9b9b9b;
    font-weight: normal;
    margin-bottom: 0;
}

#page-account-security .security-input {
    position: relative;
    margin-top: 30px;
    /* padding-bottom: 30px; */
    /* border-bottom: solid 1px #dcdcdc; */
}

#page-account-security .security-input label {
    display: block;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: -0.4px;
    color: #191919;
}

#page-account-security .security-input span {
    font-size: 14px;
    letter-spacing: -0.2px;
    color: #191919;
    font-weight: 100;
    display: block;
    margin-bottom: 25px;
    margin-top: 3px;
}


#page-account-security .security-input input {
    margin-top: 1px;
    padding: 15px;
    border: solid 1px #dcdcdc;
    font-size: 16px;
    letter-spacing: -0.2px;
    border-radius: 8px;
    width: 310px;
}

#page-account-security .security-input input:focus {
    border: solid 1px #ff7d00;
}


#page-account-security #change-password-button {
    width: 216px;
    display: block;
    padding: 12px;
    font-size: 16px;
}

#page-account-security button {
    font-size: 16px;
}

#page-account-security .security-input .custom-switch-wrapper {
    display: inline-block;
    position: absolute;
    right: 0;
    bottom: 45px;
    margin: 0;
}

#page-account-security .security-input .custom-switch-wrapper input {
    width: 0;
}

#page-account-security .popup-input-password-wrapper {
    position: relative;
    /* border: solid 1px #dcdcdc; */
    border-radius: 8px;
    width: 310px;
}

#page-account-security .popup-input-password-wrapper input {
    padding: 12px 50px 14px 15px;
    width: 100%;
    display: inline-block;
    border: none;
    border-radius: 8px;
    letter-spacing: -0.2px;
    font-size: 16px;
    color: #191919;
    border: solid 1px #dcdcdc;
}

#page-account-security .popup-input-password-wrapper .peek-password {
    position: absolute;
    width: 50px;
    color: #191919;
    text-align: center;
    top: 6px;
    right: 1px;
    height: 41px;
    line-height: 40px;
    font-size: 20px;
    /* border-left: solid 1px #dcdcdc; */
    cursor: pointer;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    background: #ffffff;
    background: transparent;
}

#page-account-security .popup-input-password-wrapper input:focus + .peek-password {
    color: #ff7d00;
}

#page-account-security .popup-reinput-password-wrapper {
    margin-bottom: 26px;
}

#page-account-security .popup-reinput-password-wrapper .input-warning {
    font-weight: normal;
}

#page-account-security .password-strength {
    font-weight: normal;
    padding: 16px 0 24px;
}

#page-account-security .password-strength .div50 {
    width: 210px;
} 
