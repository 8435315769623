.integration-add-account-tokped {
    padding: 32px 30px;
    text-align: left;

    ul {
        padding: 0 0 24px 22px;
        margin: 24px 0 0 0;
        border-bottom: solid 1px rgba(25, 25, 25, 0.1);


        li {
            font-size: 14px;
            letter-spacing: -0.2px;
            color: rgba(25, 25, 25, 0.6);

            img {
                position: relative;
                top: -1px;
                margin: 0 2px;
            }
        }
    }

    .comp-back-button {
        margin: 0 0 16px 0;
    }

    .integration-add-account-box {
        background: #ffffff;
        border-radius: 8px;
        padding: 32px;
        border: solid 1px rgba(25, 25, 25, 0.1);

        .integration-add-account-box-top {
            padding-bottom: 15px;
            border-bottom: solid 1px #dcdcdc;
            position: relative;
            font-size: 20px;
            letter-spacing: -0.6px;

            button {
                position: absolute;
                top: -6px;
                right: 0;
                // padding: 7px 24px;
                font-size: 16px;
                letter-spacing: -0.6px;
                // width: 195px;
                // height: 40px;

                // .lds-ring {
                //     top: unset;
                //     right: unset;
                //     position: relative;
                //     top: 3px;

                //     div {
                //         margin: 0;
                //         border-color: #ffffff transparent transparent transparent;
                //     }
                // }
            }
        }

        .integration-add-account-box-form {
            // width: 320px;
            .width-455 {
                width: 455px;
                position: relative;

                span {
                    position: absolute;
                    font-size: 12px;
                    letter-spacing: -0.2px;
                    right: 0;
                    top: 24px;
                }

                p {
                    display: inline-block;
                    margin: 0;
                    position: relative;
                }

                input {
                    width: 250px !important;
                    display: inline-block !important;
                    margin-left: 4px;

                    &:focus {
                        border: solid 1px var(--main-dark-orange);
                    }
                }
            }

            .width-320 {
                width: 320px;
            }
            
            label {
                padding: 24px 0 0;
                font-size: 16px;
                letter-spacing: -0.5px;
                display: block;
            }

            input[type="text"] {
                padding: 12px 16px;
                border-radius: 6px;
                border: solid 1px #dcdcdc;
                width: 100%;
                font-size: 16px;
                letter-spacing: -0.3px;
                display: block;
                // margin-bottom: 8px;
            }

            .integration-info-form {
                font-size: 14px;
                color: rgba(25, 25, 25, 0.8);
                letter-spacing: -0.2px;
            }

            .custom-select-whatsapp-topic {
                .custom-select-option-value-wrapper {
                    padding: 12px 16px !important;
                    margin-bottom: 8px;
                }
            }
        }
    }
}