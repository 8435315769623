@import url('https://fonts.googleapis.com/css?family=Roboto&display=swap');

.preview-customize {
  .taplive2-centered-dot {
    width: 2px;
    height: 2px;
    display: inline-block;
    vertical-align: top;
    background: rgba(25, 25, 25, 0.4);
    border-radius: 50%;
    position: relative;
    top: 8px;
    margin: 0 2px;
  }

  .taplive2-centered-dot-white {
    background: #ffffff;
  }

  .taplive2-lds-ring {
    display: inline-block;
    position: relative;
    width: 18px;
    height: 18px;
  }

  .taplive2-lds-ring.centered-taplive2-lds-ring {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .taplive2-lds-ring.centered-taplive2-lds-ring div {
    margin: 0;
  }

  .taplive2-lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 18px;
    height: 18px;
    border: 2px solid #fff;
    border-radius: 50%;
    animation: taplive2-lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #ffffff transparent transparent transparent;
  }

  .taplive2-lds-ring.white-taplive2-lds-ring div {
    border-color: #ffffff transparent transparent transparent;
  }

  .taplive2-lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
  }

  .taplive2-lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
  }

  .taplive2-lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
  }

  @keyframes taplive2-lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  .taplive2-snack-bar-wrapper {
    width: calc(100% - 30px);
    display: inline-block;
    border-radius: 8px;
    position: absolute;
    z-index: 4;
    /* height: 48px; */
    bottom: 12px;
    left: 50%;
    transform: translate(-50%, 0);
    display: none;
    padding: 13px 30px 13px 50px;
  }

  .taplive2-snack-bar-wrapper.taplive2-error-snack-bar {
    border: solid 1px #ff3f57;
    color: #ff3f57;
    background: #ffc0c0;
  }

  .taplive2-snack-bar-wrapper.taplive2-warning-snack-bar {
    border: solid 1px #ff7d00;
    color: #ff7d00;
    background: #fff4b1;
  }

  .taplive2-snack-bar-wrapper .taplive2-snack-bar-message {
    font-size: 16px;
    letter-spacing: -0.5px;
  }

  .taplive2-snack-bar-wrapper .taplive2-snack-bar-icon {
    position: absolute;
    width: 24px;
    height: 24px;
    cursor: pointer;
    top: 50%;
    transform: translate(0, -50%);
    left: 16px;
    display: none;
  }

  .taplive2-snack-bar-wrapper .taplive2-close-snack-bar {
    position: absolute;
    width: 15px;
    height: 15px;
    cursor: pointer;
    top: 50%;
    transform: translate(0, -50%);
    right: 16px;
  }

  /* .taplive2-main-chat-wrapper button[disabled] {
      cursor: not-allowed;
      color: rgba(25, 25, 25, 0.2);
      background: rgba(25, 25, 25, 0.1);
  } */

  .taplive2-main-chat-wrapper * {
    /* font-family: ptRootNormal; */
    font-family: 'Roboto', sans-serif;
    box-sizing: border-box !important;
  }

  .taplive2-main-chat-wrapper b {
    font-family: PTRootBold !important;
  }

  .taplive2-main-brand-button {
    background: rgba(255, 126, 0, 1);
    cursor: pointer;
  }

  .taplive2-main-brand-nofill-button {
    background: #ffffff;
    color: rgba(255, 126, 0, 1);
    border: solid 1px rgba(255, 126, 0, 1);
    cursor: pointer;
  }

  .taplive2-main-brand-button:hover {
    /* background: rgba(255, 126, 0, 0.8) !important; */
  }

  .taplive2-main-brand-nofill-button:hover {
    /* background: rgba(255, 126, 0, 0.1) !important; */
  }

  .taplive2-chat-widget-opener-button {
    background: rgba(255, 126, 0, 1);
    box-shadow: inset 1px -1px 10px 0px rgba(255, 255, 255, 0.12),
      0px 2px 20px 0px rgba(25, 25, 25, 0.3);
    height: 64px;
    width: 64px;
    border-radius: 50%;
    cursor: pointer;
    position: fixed;
    bottom: 24px;
    right: 24px;
    z-index: 9990;
    box-sizing: border-box;
  }

  .taplive2-chat-widget-opener-button:hover::before {
    content: '';
    height: 64px;
    width: 64px;
    position: absolute;
    border-radius: 50%;
    background: rgba(25, 25, 25, 0.1);
    top: 0;
    left: 0;
  }

  .taplive2-chat-widget-opener-button img {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  .taplive2-close-widget-button {
    display: none;
  }

  .taplive2-outer-container {
    height: calc(100vh - 130px);
    /* width: 100%; */
    width: 375px;
    /* max-height: calc(100vh - 130px); */
    height: 445px;
    border-radius: 8px;
    box-shadow: 0px 2px 20px 0px rgba(25, 25, 25, 0.18);
    position: relative;
    /* right: 24px; */
    /* bottom: 110px; */
    /* right: 0; */
    font-family: Helvetica, Arial, sans-serif !important;
    z-index: 9990;
    background: #f3f3f3;
    display: none;
    overflow: hidden;
    text-align: left;
  }

  .active-taplive2-power .taplive2-main-chat-wrapper {
    height: calc(100% - 37px);
    width: 100%;
    position: relative;
    /* max-height: calc(100vh - 130px);
      border-radius: 6px;
      box-shadow: 0px 2px 20px 0px rgba(25, 25, 25, 0.18); */
  }

  .taplive2-power {
    height: 36px;
    padding: 4px 0;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    box-sizing: border-box;
    background: #ffffff;
    box-shadow: 0 1px 0 0 rgba(25, 25, 25, 0.1);
    display: none;
  }

  .taplive2-power div {
    padding: 0 14px;
    border-radius: 4px;
    display: inline-block;
    padding: 0 14px;
    border-radius: 4px;
    display: inline-block;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
    white-space: nowrap;
    height: 28px;
    line-height: 28px;
  }

  .taplive2-power div:hover {
    background: rgba(255, 126, 0, 0.1);
  }

  .taplive2-power div a {
    text-decoration: none;
    color: rgba(25, 25, 25, 0.8);
    font-size: 14px;
    letter-spacing: -0.2px;
  }

  .taplive2-power div a img {
    vertical-align: top;
    position: relative;
    top: 9px;
    margin-left: 4px;
    width: 71px;
  }

  .active-taplive2-power .taplive2-power {
    display: block;
  }

  .taplive2-start-new-case-with-omnichannel-box {
    padding: 18px;
    border-radius: 8px;
    background: #ffffff;
  }

  @media screen and (min-width: 300px) and (max-width: 767px) {
    .taplive2-outer-container {
      width: 100vw;
      /* height: calc(100vh - 170px); */
      height: 100vh;
      max-height: 100vh;
      bottom: 0;
      top: 0;
      right: 0;
      border-radius: 0 !important;
    }

    .taplive2-start-new-chat-wrapper .taplive2-start-new-chat-top-content {
      border-radius: 0 !important;
    }

    .taplive2-room-list-with-omnichannel .taplive2-start-new-chat-top-content {
      border-radius: 0 !important;
    }

    .taplive2-room-list-wrapper .taplive2-room-list-top-content {
      border-radius: 0 !important;
    }

    .taplive2-room-list-wrapper {
      border-radius: 0 !important;
    }

    .taplive2-close-widget-button {
      display: inline-block;
      width: 32px;
      height: 32px;
      border-radius: 8px;
      cursor: pointer;
      transition: ease-in-out 0.2s;
      background: none;
      position: relative;
    }

    .taplive2-close-widget-button:active {
      background: rgba(25, 25, 25, 0.1);
    }

    .taplive2-close-widget-button img {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 24px !important;
      height: 24px !important;
      display: inline-block;
    }

    .taplive2-room-list-top-content .taplive2-close-widget-button {
      position: absolute;
      right: 20px;
      top: 35px;
    }

    .taplive2-start-new-chat-top-content {
      padding-right: 62px !important;
    }

    .taplive2-start-new-chat-top-content .taplive2-close-widget-button {
      position: absolute;
      right: 24px;
      top: 24px;
    }

    .taplive2-main-chat-room-wrapper .taplive2-main-chat-room-header {
      border-radius: 0 !important;
    }
  }

  .taplive2-main-chat-wrapper * {
    outline: none;
  }

  .taplive2-main-chat-wrapper input[type='text']:focus,
  .taplive2-main-chat-wrapper textarea:focus {
    box-shadow: 0 0 0 1px rgba(255, 126, 0, 1);
  }
  .taplive2-start-new-case-wrapper {
    position: absolute !important;
    top: 0;
    right: -100%;
    transition: ease-in-out 0.3s;
  }
  .taplive2-start-new-case-wrapper.taplive2-active-start-new-case-wrapper {
    right: 0;
  }

  .taplive2-start-new-chat-wrapper {
    max-height: 100%;
    height: 100%;
    overflow-y: auto;
    width: 100%;
    position: relative;
  }
  .taplive2-start-new-case-wrapper .taplive2-start-new-chat-top-content {
    min-height: 150px;
  }

  .taplive2-start-new-chat-wrapper .taplive2-start-new-chat-top-content {
    background: rgba(255, 126, 0, 1);
    width: 100%;
    /* height: 220px; */
    color: #ffffff;
    border-radius: 8px 8px 0px 0px;
    /* padding: 30px 24px; */
    padding: 24px;
    box-sizing: border-box;
    position: relative;
  }
  .taplive2-start-new-chat-top-content-color-background {
    height: 220px;
  }

  .taplive2-start-new-chat-wrapper {
    background: #f1f1f1;
  }

  .taplive2-start-new-message-wrapper {
    max-height: unset;
    /* height: unset; */
    height: 100%;
    overflow: hidden;
    display: none;
    z-index: 1;
  }

  .taplive2-start-new-chat-wrapper
    .taplive2-start-new-chat-top-content
    .taplive2-start-new-chat-top-content-logo-wrapper {
    background: #ffffff;
    height: 48px;
    width: 48px;
    border-radius: 8px;
    position: relative;
    overflow: hidden;
  }

  .taplive2-start-new-chat-wrapper
    .taplive2-start-new-chat-top-content
    .taplive2-start-new-chat-top-content-logo-wrapper
    img {
    width: 100%;
  }

  /* .taplive2-start-new-chat-wrapper .taplive2-start-new-chat-top-content p:nth-child(2) { */
  .taplive2-start-new-chat-wrapper
    .taplive2-start-new-chat-top-content
    p:nth-child(1) {
    font-size: 20px;
    letter-spacing: -0.6px;
    /* margin: 25px 0 8px; */
    margin: 0 0 7px;
  }

  .taplive2-start-new-chat-wrapper
    .taplive2-start-new-chat-top-content
    p:nth-child(2) {
    margin: 0;
    line-height: 24px;
    font-size: 16px;
    letter-spacing: -0.3px;
  }

  .taplive2-start-new-chat-wrapper
    .taplive2-start-new-chat-top-content
    p:nth-child(3) {
    font-size: 16px;
    letter-spacing: -0.3px;
    margin: 0;
  }

  .taplive2-start-new-chat-wrapper .taplive2-start-new-chat-bottom-content {
    width: 100%;
    box-sizing: border-box;
    padding: 0 24px;
    position: relative;
    top: -30px;
    /* top: -15px; */
  }
  .taplive2-start-new-chat-bottom-content .taplive2-shimmer-room-list {
    width: 100%;
    height: 100%;
    position: relative;
  }

  .taplive2-shimmer-room-list-chat-content {
    width: 100%;
    position: relative;
    padding-top: 12px;
    font-size: 0;
  }

  .taplive2-avatar-shimmer-room-list {
    width: 52px;
    height: 52px;
    border-radius: 50%;
    margin: 0 12px 0 16px;
    display: inline-block;
    vertical-align: top;
  }

  .taplive2-shimmer-right-wrapper {
    width: calc(100% - 80px);
    display: inline-block;
  }

  .taplive2-shimmer-room-list-top {
    width: 100px;
    display: inline-block;
    height: 16px;
    margin-bottom: 8px;
  }

  .taplive2-shimmer-room-list-bottom {
    width: calc(100% - 16px);
    display: inline-block;
    height: 42px;
    margin-bottom: 12px;
  }

  .taplive2-shimmer-room-list-line {
    height: 2px;
    width: 100%;
  }

  .taplive2-shimmer-room-list .taplive2-shimmer-room-list-button-wrapper {
    padding: 12px 16px;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 1;
    background: #ffffff;
  }

  .taplive2-shimmer-room-list
    .taplive2-shimmer-room-list-button-wrapper
    .taplive2-shimmer-room-list-button-content {
    height: 48px;
    border-radius: 8px;
    width: 100%;
  }

  @keyframes taplivePlaceholderShimmer {
    0% {
      background-position: -468px 0;
    }

    100% {
      background-position: 468px 0;
    }
  }

  .taplive2-shine {
    background: #f6f7f8;
    background-image: linear-gradient(
      to right,
      #f6f7f8 0%,
      #edeef1 20%,
      #f6f7f8 40%,
      #f6f7f8 100%
    );
    background-repeat: no-repeat;
    background-size: 800px 104px;
    display: inline-block;
    position: relative;

    animation-duration: 1.5s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: taplivePlaceholderShimmer;
    animation-timing-function: linear;
  }

  .taplive2-start-new-chat-wrapper
    .taplive2-start-new-chat-bottom-content
    .taplive2-start-new-chat-form-wrapper {
    padding: 18px;
    background: #ffffff;
    border-radius: 8px 8px 0 0;
    /* border-top: solid 8px rgba(255, 126, 0, 0.1); */
    /* max-height: calc(100% - 70px); */
    overflow-y: auto;
  }

  .taplive2-start-new-chat-wrapper
    .taplive2-start-new-chat-bottom-content
    .taplive2-start-new-chat-form-wrapper
    label {
    display: block;
    font-size: 14px;
    letter-spacing: -0.4px;
  }

  .taplive2-start-new-chat-wrapper
    .taplive2-start-new-chat-bottom-content
    .taplive2-start-new-chat-form-wrapper
    input[type='text'] {
    display: block;
    width: 100%;
    box-sizing: border-box;
    border-radius: 8px;
    border: 1px solid rgba(25, 25, 25, 0.1);
    font-size: 16px;
    letter-spacing: -0.3px;
    padding: 16px;
    margin: 8px 0 12px;
  }

  .taplive2-start-new-chat-wrapper
    .taplive2-start-new-chat-bottom-content
    .taplive2-start-new-chat-form-wrapper
    select {
    display: block;
    width: 100%;
    box-sizing: border-box;
    border-radius: 8px;
    border: 1px solid rgba(25, 25, 25, 0.1);
    font-size: 16px;
    letter-spacing: -0.3px;
    padding: 16px 11px;
    margin: 8px 0 12px;
  }

  .taplive2-start-new-chat-wrapper
    .taplive2-start-new-chat-bottom-content
    .taplive2-start-new-chat-form-wrapper
    textarea {
    display: block;
    width: 100%;
    box-sizing: border-box;
    border-radius: 8px;
    border: 1px solid rgba(25, 25, 25, 0.1);
    font-size: 16px;
    letter-spacing: -0.3px;
    padding: 16px;
    margin: 8px 0 12px;
    resize: none;
    font-family: Helvetica, Arial, sans-serif !important;
  }

  .taplive2-start-new-chat-wrapper
    .taplive2-start-new-chat-bottom-content
    .taplive2-start-new-chat-form-wrapper
    .taplive2-submit-new-chat {
    color: #ffffff;
    background: rgba(255, 126, 0, 1);
    width: 100%;
    border-radius: 8px;
    border: none;
    font-size: 16px;
    letter-spacing: -0.5px;
    text-align: center;
    width: 100%;
    padding: 13px;
    margin-top: 10px;
    cursor: pointer;
  }

  .taplive2-submit-new-chat:hover {
    /* background: rgba(255, 126, 0, 0.8) !important; */
  }

  .taplive2-start-new-chat-wrapper
    .taplive2-start-new-chat-bottom-content-new-message {
    top: -150px;
  }

  .taplive2-start-new-chat-wrapper .taplive2-start-new-message-header {
    font-size: 20px;
    letter-spacing: -0.6px;
  }

  .taplive2-start-new-chat-wrapper .taplive2-start-new-message-header img {
    vertical-align: top;
    /* width: 25px; */
    margin-right: 5px;
    cursor: pointer;
    border-radius: 50%;
    position: relative;
    top: -1px;
    padding: 3px;
    transition: ease-in-out 0.1s;
  }

  .taplive2-start-new-chat-wrapper .taplive2-start-new-message-header img:hover {
    background: rgba(25, 25, 25, 0.3);
  }

  .taplive2-room-list-with-omnichannel {
    max-height: 100%;
    height: 100%;
    overflow-y: auto;
    width: 100%;
    position: relative;
  }
  .taplive2-roomlist-withomnichannel-omnichannel-list-wrapper {
    background: #ffffff;
    border-radius: 8px;
    padding: 18px;
  }

  .taplive2-start-new-case-wrapper .taplive2-start-new-chat-top-content {
    min-height: 150px;
  }

  .taplive2-room-list-with-omnichannel .taplive2-start-new-chat-top-content {
    background: rgba(255, 126, 0, 1);
    width: 100%;
    /* height: 220px; */
    color: #ffffff;
    border-radius: 8px 8px 0px 0px;
    /* padding: 30px 24px; */
    padding: 24px;
    box-sizing: border-box;
    position: relative;
  }

  .taplive2-room-list-with-omnichannel {
    background: #f1f1f1;
  }

  .taplive2-start-new-message-wrapper {
    max-height: unset;
    /* height: unset; */
    height: 100%;
    overflow: hidden;
    display: none;
    z-index: 1;
  }

  .taplive2-room-list-with-omnichannel
    .taplive2-start-new-chat-top-content
    .taplive2-start-new-chat-top-content-logo-wrapper {
    background: #ffffff;
    height: 48px;
    width: 48px;
    border-radius: 8px;
    position: relative;
    overflow: hidden;
  }

  .taplive2-room-list-with-omnichannel
    .taplive2-start-new-chat-top-content
    .taplive2-start-new-chat-top-content-logo-wrapper
    img {
    width: 100%;
  }

  /* .taplive2-room-list-with-omnichannel .taplive2-start-new-chat-top-content p:nth-child(2) { */
  .taplive2-room-list-with-omnichannel
    .taplive2-start-new-chat-top-content
    p:nth-child(1) {
    font-size: 20px;
    letter-spacing: -0.6px;
    /* margin: 25px 0 8px; */
    margin: 0 0 7px;
  }

  .taplive2-room-list-with-omnichannel
    .taplive2-start-new-chat-top-content
    p:nth-child(2) {
    margin: 0;
    line-height: 24px;
    font-size: 16px;
    letter-spacing: -0.3px;
  }

  .taplive2-room-list-with-omnichannel
    .taplive2-start-new-chat-top-content
    p:nth-child(3) {
    font-size: 16px;
    letter-spacing: -0.3px;
    margin: 0;
  }

  .taplive2-room-list-with-omnichannel .taplive2-start-new-chat-bottom-content {
    width: 100%;
    box-sizing: border-box;
    padding: 0 24px;
    position: relative;
    top: -30px;
    /* top: -15px; */
  }
  .taplive2-start-new-chat-bottom-content .taplive2-shimmer-room-list {
    width: 100%;
    height: 100%;
    position: relative;
  }

  .taplive2-shimmer-room-list-chat-content {
    width: 100%;
    position: relative;
    padding-top: 12px;
    font-size: 0;
  }

  .taplive2-avatar-shimmer-room-list {
    width: 52px;
    height: 52px;
    border-radius: 50%;
    margin: 0 12px 0 16px;
    display: inline-block;
    vertical-align: top;
  }

  .taplive2-shimmer-right-wrapper {
    width: calc(100% - 80px);
    display: inline-block;
  }

  .taplive2-shimmer-room-list-top {
    width: 100px;
    display: inline-block;
    height: 16px;
    margin-bottom: 8px;
  }

  .taplive2-shimmer-room-list-bottom {
    width: calc(100% - 16px);
    display: inline-block;
    height: 42px;
    margin-bottom: 12px;
  }

  .taplive2-shimmer-room-list-line {
    height: 2px;
    width: 100%;
  }

  .taplive2-shimmer-room-list .taplive2-shimmer-room-list-button-wrapper {
    padding: 12px 16px;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 1;
    background: #ffffff;
  }

  .taplive2-shimmer-room-list
    .taplive2-shimmer-room-list-button-wrapper
    .taplive2-shimmer-room-list-button-content {
    height: 48px;
    border-radius: 8px;
    width: 100%;
  }

  @keyframes taplivePlaceholderShimmer {
    0% {
      background-position: -468px 0;
    }

    100% {
      background-position: 468px 0;
    }
  }

  .taplive2-shine {
    background: #f6f7f8;
    background-image: linear-gradient(
      to right,
      #f6f7f8 0%,
      #edeef1 20%,
      #f6f7f8 40%,
      #f6f7f8 100%
    );
    background-repeat: no-repeat;
    background-size: 800px 104px;
    display: inline-block;
    position: relative;

    animation-duration: 1.5s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: taplivePlaceholderShimmer;
    animation-timing-function: linear;
  }

  .taplive2-room-list-with-omnichannel
    .taplive2-start-new-chat-bottom-content
    .taplive2-start-new-chat-form-wrapper {
    padding: 18px;
    background: #ffffff;
    border-radius: 8px 8px 0 0;
    /* border-top: solid 8px rgba(255, 126, 0, 0.1); */
    /* max-height: calc(100% - 70px); */
    overflow-y: auto;
  }

  .taplive2-room-list-with-omnichannel
    .taplive2-start-new-chat-bottom-content
    .taplive2-start-new-chat-form-wrapper
    label {
    display: block;
    font-size: 14px;
    letter-spacing: -0.4px;
  }

  .taplive2-room-list-with-omnichannel
    .taplive2-start-new-chat-bottom-content
    .taplive2-start-new-chat-form-wrapper
    input[type='text'] {
    display: block;
    width: 100%;
    box-sizing: border-box;
    border-radius: 8px;
    border: 1px solid rgba(25, 25, 25, 0.1);
    font-size: 16px;
    letter-spacing: -0.3px;
    padding: 16px;
    margin: 8px 0 12px;
  }

  .taplive2-room-list-with-omnichannel
    .taplive2-start-new-chat-bottom-content
    .taplive2-start-new-chat-form-wrapper
    select {
    display: block;
    width: 100%;
    box-sizing: border-box;
    border-radius: 8px;
    border: 1px solid rgba(25, 25, 25, 0.1);
    font-size: 16px;
    letter-spacing: -0.3px;
    padding: 16px 11px;
    margin: 8px 0 12px;
  }

  .taplive2-room-list-with-omnichannel
    .taplive2-start-new-chat-bottom-content
    .taplive2-start-new-chat-form-wrapper
    textarea {
    display: block;
    width: 100%;
    box-sizing: border-box;
    border-radius: 8px;
    border: 1px solid rgba(25, 25, 25, 0.1);
    font-size: 16px;
    letter-spacing: -0.3px;
    padding: 16px;
    margin: 8px 0 12px;
    resize: none;
    font-family: Helvetica, Arial, sans-serif !important;
  }

  .taplive2-room-list-with-omnichannel
    .taplive2-start-new-chat-bottom-content
    .taplive2-start-new-chat-form-wrapper
    .taplive2-submit-new-chat {
    color: #ffffff;
    background: rgba(255, 126, 0, 1);
    width: 100%;
    border-radius: 8px;
    border: none;
    font-size: 16px;
    letter-spacing: -0.5px;
    text-align: center;
    width: 100%;
    padding: 13px;
    margin-top: 10px;
    cursor: pointer;
  }

  .taplive2-submit-new-chat:hover {
    /* background: rgba(255, 126, 0, 0.8) !important; */
  }

  .taplive2-room-list-with-omnichannel
    .taplive2-start-new-chat-bottom-content-new-message {
    /* top: -150px; */
  }

  .taplive2-room-list-with-omnichannel .taplive2-start-new-message-header {
    font-size: 20px;
    letter-spacing: -0.6px;
  }

  .taplive2-room-list-with-omnichannel .taplive2-start-new-message-header img {
    vertical-align: top;
    width: 25px;
    margin-right: 10px;
    cursor: pointer;
  }

  .taplive2-room-list-wrapper {
    width: 100%;
    border-radius: 8px 8px 0 0;
    max-height: 100%;
    overflow: hidden;
    right: -100%;
    position: absolute;
    top: 0;
    transition: ease-in-out 0.3s;
    background: #f3f3f3;
  }
  .taplive2-room-list-wrapper.taplive2-active-room-list-wrapper {
    right: 0;
  }

  .taplive2-room-list-wrapper .taplive2-room-list-top-content {
    padding: 24px;
    color: #ffffff;
    width: 100%;
    background: rgba(255, 126, 0, 1);
    border-radius: 8px 8px 0 0;
    box-sizing: border-box;
    height: 175px;
    position: relative;
  }

  .taplive2-room-list-wrapper .taplive2-room-list-top-content img {
    width: 48px;
    height: 48px;
    display: inline-block;
    border-radius: 8px;
    vertical-align: top;
  }

  .taplive2-room-list-wrapper
    .taplive2-room-list-top-content
    img.taplive2-room-list-back-button {
    width: unset;
    height: unset;
    display: inline-block;
    vertical-align: top;
    position: absolute;
    top: 36px;
    border-radius: 50%;
    left: 22px;
    cursor: pointer;
    padding: 3px;
    transition: ease-in-out 0.1s;
  }

  .taplive2-room-list-wrapper
    .taplive2-room-list-top-content
    img.taplive2-room-list-back-button:hover {
    background: rgba(25, 25, 25, 0.3);
  }

  .taplive2-room-list-top-content.taplive2-room-list-without-omnichannel
    img.taplive2-room-list-back-button {
    display: none;
  }

  .taplive2-room-list-wrapper .taplive2-room-list-top-content b {
    font-size: 24px;
    letter-spacing: -0.6px;
    margin-left: 35px;
    position: relative;
    top: 11px;
  }

  .taplive2-room-list-top-content.taplive2-room-list-without-omnichannel b {
    margin-left: 0;
  }

  .taplive2-room-list-wrapper .taplive2-room-list-bottom-content {
    width: calc(100% - 50px);
    border-radius: 8px 8px 0 0;
    background: #ffffff;
    /* border-top: solid 8px rgba(255, 126, 0, 0.1); */
    position: relative;
    left: 24px;
    top: -90px;
    display: inline-block;
    height: calc(100vh - 216px);
    overflow-y: hidden;
  }

  .taplive2-room-list-wrapper
    .taplive2-room-list-bottom-content
    .taplive2-room-list-outer-container {
    max-height: calc(100% - 70px);
    overflow-y: auto;
  }

  @media screen and (min-width: 300px) and (max-width: 767px) {
    .taplive2-room-list-wrapper .taplive2-room-list-bottom-content {
      height: calc(100vh - 87px);
    }

    .active-taplive2-power
      .taplive2-room-list-wrapper
      .taplive2-room-list-bottom-content {
      /* height: calc(100vh - 301px); */
      height: calc(100vh - 132px);
    }
  }

  /* room list chat */
  .taplive2-chat-list-wrapper {
    background: #fff;
    width: 100%;
    padding: 12px 0 0;
    cursor: pointer;
    font-size: 0;
  }

  .taplive2-chat-list-wrapper:hover {
    background: rgba(255, 126, 0, 0.1);
  }

  .taplive2-chat-list-wrapper.active-chat-list {
    background: #efeeee;
  }

  .taplive2-chat-list-wrapper .taplive2-chat-avatar-wrapper {
    width: 70px;
    display: inline-block;
    vertical-align: top;
    text-align: center;
    position: relative;
  }

  .taplive2-chat-list-wrapper .taplive2-chat-avatar-wrapper img {
    width: 52px;
    height: 52px;
    display: inline-block;
    border-radius: 50%;
    object-fit: cover;
  }

  .taplive2-chat-list-wrapper .taplive2-chat-avatar-wrapper img.group-badge {
    width: 20px;
    height: 20px;
    position: absolute;
    bottom: 1px;
    right: 11px;
  }

  .taplive2-chat-list-wrapper
    .taplive2-chat-avatar-wrapper
    .taplive2-user-avatar-name {
    border-radius: 50%;
    width: 52px;
    height: 52px;
    display: inline-block;
    text-align: center;
    font-size: 20px;
    letter-spacing: -0.6px;
    color: white;
    line-height: 52px;
  }

  .taplive2-chat-list-wrapper .taplive2-dialog-message-wrapper {
    width: calc(100% - 70px);
    display: inline-block;
    position: relative;
    border-bottom: solid 1px #dcdcdc;
    padding: 5px 0 12px 0;
  }

  .taplive2-chat-list-wrapper .taplive2-dialog-message-wrapper .taplive2-dialog-top {
    position: relative;
    width: 100%;
    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;
    padding-right: 70px;
    box-sizing: border-box;
  }

  .taplive2-chat-list-wrapper
    .taplive2-dialog-message-wrapper
    .taplive2-dialog-top
    p {
    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;
    margin: 0;
  }

  .taplive2-chat-list-wrapper
    .taplive2-dialog-message-wrapper
    .taplive2-dialog-top
    b {
    font-size: 16px;
  }

  .taplive2-chat-list-wrapper
    .taplive2-dialog-message-wrapper
    .taplive2-dialog-top
    .taplive2-dialog-date {
    color: rgba(25, 25, 25, 0.6);
    font-size: 12px;
    letter-spacing: -0.4px;
    line-height: 18px;
    position: absolute;
    right: 16px;
    top: 0;
  }

  .taplive2-chat-list-wrapper
    .taplive2-dialog-message-wrapper
    .taplive2-dialog-bottom {
    color: rgba(25, 25, 25, 0.6);
    font-size: 14px;
    letter-spacing: -0.2px;
    line-height: 21px;
    padding: 3px 60px 0 0;
    position: relative;
    min-height: 32px;
  }

  .taplive2-chat-list-wrapper
    .taplive2-dialog-message-wrapper
    .taplive2-dialog-bottom
    span {
    color: #191919;
  }

  .taplive2-chat-list-wrapper
    .taplive2-dialog-message-wrapper
    .taplive2-dialog-bottom
    p {
    margin: 0;
    white-space: nowrap;
    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;
  }

  .taplive2-chat-list-wrapper
    .taplive2-dialog-message-wrapper
    .taplive2-dialog-bottom
    .taplive2-message-status {
    position: absolute;
    top: 10px;
    right: 16px;
  }

  .taplive2-chat-list-wrapper
    .taplive2-dialog-message-wrapper
    .taplive2-dialog-bottom
    .taplive2-message-status
    .taplive2-unread-count-wrapper {
    background: rgba(255, 126, 0, 1);
    color: #fff;
    font-size: 12px;
    border-radius: 12px;
    height: 24px;
    min-width: 24px;
    text-align: center;
    line-height: 24px;
    padding: 0 5px;
  }

  .taplive2-chat-list-wrapper:nth-last-child(1) .taplive2-dialog-message-wrapper {
    border-bottom: 0;
  }

  .taplive2-room-list-wrapper
    .taplive2-room-list-bottom-content
    .taplive2-room-list-bottom-new-message-wrapper {
    width: 100%;
    /* height: 43px; */
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 12px 16px;
    background: #ffffff;
    box-sizing: border-box;
  }

  .taplive2-room-list-wrapper
    .taplive2-room-list-bottom-content
    .taplive2-room-list-bottom-list-room-wrapper {
    max-height: calc(100% - 70px);
    overflow-y: auto;
    border-bottom: solid 1px #dcdcdc;
  }

  .taplive2-room-list-wrapper
    .taplive2-room-list-bottom-content
    .taplive2-room-list-bottom-new-message {
    border-radius: 8px;
    border: none;
    color: #ffffff;
    background: rgba(255, 126, 0, 1);
    width: 100%;
    padding: 13px;
    cursor: pointer;
    font-size: 16px;
    letter-spacing: -0.5px;
  }

  .taplive2-room-list-wrapper
    .taplive2-room-list-bottom-content
    .taplive2-room-list-bottom-new-message:hover {
    /* background: rgba(255, 126, 0, 0.8); */
  }

  .taplive2-room-list-wrapper
    .taplive2-room-list-bottom-content
    .taplive2-room-list-bottom-new-message
    img {
    position: relative;
    margin-right: 5px;
    vertical-align: top;
    top: 3px;
  }

  .taplive2-chat-list-wrapper:nth-last-child(1) .taplive2-dialog-message-wrapper {
    border-bottom: 0;
  }
  /* room list chat */

  /* review */
  .taplive2-review-wrapper {
    background: #00000073;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    border-radius: 8px;
    z-index: 3;
    transition: ease-in-out 0.5s;
    bottom: -100vh;
  }
  .taplive2-review-wrapper.active-taplive2-review-wrapper {
    bottom: 0;
  }
  .taplive2-review-wrapper .taplive2-review-content-wrapper {
    height: 340px;
    width: 100%;
    background: #ffffff;
    position: absolute;
    bottom: 0;
    left: 0;
    border-radius: 8px 8px 0 0;
    padding: 16px 12px;
  }

  .taplive2-review-wrapper
    .taplive2-review-content-wrapper
    .taplive2-review-content-head {
    width: 100%;
    position: relative;
    text-align: center;
  }

  .taplive2-review-wrapper
    .taplive2-review-content-wrapper
    .taplive2-review-content-head
    img {
    cursor: pointer;
    position: absolute;
    left: 0;
    top: 0;
  }

  .taplive2-review-wrapper
    .taplive2-review-content-wrapper
    .taplive2-review-content-body
    .taplive2-review-content-body-star-wrapper {
    text-align: center;
    padding-top: 38px;
    position: relative;
  }

  .taplive2-review-wrapper
    .taplive2-review-content-wrapper
    .taplive2-review-content-body
    .taplive2-review-content-body-star-wrapper
    .taplive2-star-image-wrapper {
    width: 264px;
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    font-size: 0;
  }

  .taplive2-star-image-wrapper .taplive2-star-image-click-wrapper {
    width: 264px;
    height: 38px;
    position: absolute;
    top: 0;
    left: 0;
  }

  .taplive2-star-image-wrapper .taplive2-star-image-click-content {
    width: 20%;
    display: inline-block;
    cursor: pointer;
    height: 38px;
  }

  .taplive2-review-wrapper
    .taplive2-review-content-wrapper
    .taplive2-review-content-body
    .taplive2-review-content-body-star-wrapper
    img {
    width: 264px;
  }

  .taplive2-review-wrapper
    .taplive2-review-content-wrapper
    .taplive2-review-content-body
    .taplive2-review-content-body-star-wrapper
    p {
    color: #191919;
    letter-spacing: -0.4px;
    font-size: 14px;
    margin-top: 50px;
  }

  .taplive2-review-wrapper
    .taplive2-review-content-wrapper
    .taplive2-review-content-body
    textarea {
    resize: none;
    border-radius: 8px;
    width: 100%;
    letter-spacing: -0.3px;
    font-size: 16px;
    padding: 12px 16px;
    border: solid 1px #dcdcdc;
    margin: 10px 0 24px;
  }

  .taplive2-review-wrapper
    .taplive2-review-content-wrapper
    .taplive2-review-content-foot {
    width: 100%;
  }

  .taplive2-review-wrapper
    .taplive2-review-content-wrapper
    .taplive2-review-content-foot {
    width: 100%;
  }

  .taplive2-review-wrapper
    .taplive2-review-content-wrapper
    .taplive2-review-content-foot
    button {
    border: 0;
    color: #ffffff;
    font-size: 16px;
    letter-spacing: -0.5px;
    width: 100%;
    border-radius: 8px;
    padding: 14px 0;
    line-height: 1;
    cursor: pointer;
  }

  .taplive2-submit-review-button:hover {
    /* background: rgba(255, 126, 0, 0.8); */
  }
  /* review */

  /* chat room */
  .taplive2-main-chat-room-wrapper {
    width: 100%;
    height: 100%;
    max-height: 100%;
    border-radius: 8px;
    position: absolute;
    left: 100%;
    top: 0;
    transition: ease-in-out 0.3s;
    background: #ffffff;
  }
  .taplive2-main-chat-room-wrapper .taplive2-loading-message-wrapper {
    padding-top: 16px;
    text-align: center;
    width: 100%;
  }

  .taplive2-main-chat-room-wrapper
    .taplive2-loading-message-wrapper
    .taplive2-lds-ring
    div {
    border-color: rgba(255, 126, 0, 1) transparent transparent transparent;
  }

  .taplive2-main-chat-room-wrapper.taplive2-active-main-chat-room-wrapper {
    left: 0;
  }

  .taplive2-main-chat-room-wrapper .taplive2-main-chat-room-header {
    border-radius: 8px 8px 0 0;
    background: rgba(255, 126, 0, 1);
    width: 100%;
    color: #ffffff;
    padding: 12px 20px 12px 55px;
    position: relative;
    /* height: 88px; */
    height: 70px;
  }

  .taplive2-main-chat-room-wrapper
    .taplive2-main-chat-room-header
    .taplive2-main-chat-room-back-button {
    position: absolute;
    left: 20px;
    top: 22px;
    cursor: pointer;
    transition: ease-in-out 0.1s;
    border-radius: 50%;
    padding: 3px;
    margin-right: 5px;
  }

  .taplive2-main-chat-room-wrapper
    .taplive2-main-chat-room-header
    .taplive2-main-chat-room-back-button:hover {
    /* left: 15px;
              transform: scale(0.90); */
    background: rgba(25, 25, 25, 0.3);
  }

  .taplive2-main-chat-room-wrapper
    .taplive2-main-chat-room-header
    .taplive2-main-chat-room-admin-name {
    font-size: 20px;
    letter-spacing: -0.6px;
    margin: 0;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 100%;
  }

  .taplive2-main-chat-room-wrapper
    .taplive2-main-chat-room-header
    .taplive2-main-chat-header-wrapper
    .taplive2-main-chat-room-case-topic {
    font-size: 20px;
    letter-spacing: -0.6px;
    margin: 0;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 100%;
  }

  .taplive2-main-chat-room-wrapper
    .taplive2-main-chat-room-header
    .taplive2-main-chat-header-wrapper
    .taplive2-main-chat-room-case-id {
    font-size: 12px;
    letter-spacing: -0.6px;
    margin: 0;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 100%;
  }

  .taplive2-main-chat-room-wrapper .taplive2-main-chat-room-container {
    width: 100%;
    /* max-height: calc(100% - 146px);
              min-height: calc(100% - 146px); */
    max-height: calc(100% - 125px);
    min-height: calc(100% - 125px);
    transition: ease-in-out 0.2s;
    padding: 0;
    overflow-y: auto;
    overflow-x: hidden;
    position: relative;
    background-color: #f3f3f3;
  }

  .taplive2-main-chat-room-wrapper
    .taplive2-main-chat-room-container.taplive2-chat-room-is-locked {
    max-height: calc(100% - 70px);
    min-height: calc(100% - 70px);
  }

  .taplive2-main-chat-room-container.taplive2-active-mark-as-solved {
    max-height: calc(100% - 166px);
    min-height: calc(100% - 166px);
    /* max-height: calc(100% - 186px);
                  min-height: calc(100% - 186px); */
  }

  .taplive2-main-chat-room-bubble-container {
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 0 16px 12px 16px;
    max-height: calc(100%);
    overflow-y: auto;
  }

  /* chat info */
  .taplive2-chat-room-info-wrapper {
    width: 100%;
    text-align: center;
    position: relative;
    padding-top: 16px;
  }

  .taplive2-chat-room-info-wrapper .taplive2-chat-room-info-content {
    padding: 4px 16px;
    border-radius: 8px;
    box-shadow: 0px 2px 20px 0px rgba(25, 25, 25, 0.05);
    display: inline-block;
    background: #fff;
    color: rgba(25, 25, 25, 0.4);
    font-size: 12px;
    letter-spacing: -0.4px;
    line-height: 18px;
    text-align: center;
  }
  /* chat-info */

  /* chat date */
  .taplive2-chat-room-date {
    width: 77px;
    height: 22px;
    border-radius: 8px;
    box-shadow: 0 2px 20px 0 rgba(25, 25, 25, 0.05);
    background-color: #ffffff;
    color: rgba(25, 25, 25, 0.4);
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    top: 12px;
    left: 50%;
    transform: translateX(-50%);
    margin-bottom: 12px;
  }

  .taplive2-chat-room-date p {
    font-size: 12px;
    margin: 0;
  }

  /* message out text */
  .taplive2-chat-room-message-out-wrapper {
    padding-top: 16px;
    width: 100%;
    position: relative;
    text-align: right;
  }

  .taplive2-chat-room-message-out-wrapper .taplive2-message-out-bubble {
    border-radius: 16px 2px 16px 16px;
    background: rgba(255, 126, 0, 1);
    display: inline-block;
    max-width: 250px;
    /* padding: 10px 62px 8px 10px; */
    padding: 10px 10px 8px 10px;
    color: #191919;
    font-size: 14px;
    letter-spacing: -0.3px;
    line-height: 24px;
    position: relative;
    cursor: pointer;
    color: #fff;
    text-align: left;
    /* min-height: 44px; */
  }

  .taplive2-chat-room-message-out-wrapper
    .taplive2-message-out-bubble.taplive2-deleted-bubble {
    color: #fff;
    padding: 10px 10px 10px 33px;
  }

  .taplive2-chat-room-message-out-wrapper
    .taplive2-message-out-bubble.taplive2-deleted-bubble
    .taplive2-deleted-icon {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translate(0, -50%);
  }

  .taplive2-chat-room-message-out-wrapper
    .taplive2-message-out-bubble
    .taplive2-message-info {
    /* position: absolute; */
    /* bottom: 11px;
                  right: 27px; */
    margin: 0;
    color: #ffffff;
    font-size: 12px;
    letter-spacing: -0.2px;
    line-height: 18px;
    /* width: 26px; */
    width: 100%;
    text-align: right;
    white-space: nowrap;
  }

  .centered-dot {
    width: 2px;
    height: 2px;
    display: inline-block;
    vertical-align: top;
    background: rgba(25, 25, 25, 0.4);
    border-radius: 50%;
    position: relative;
    top: 9px;
  }

  .taplive2-chat-room-message-out-wrapper
    .taplive2-message-out-bubble
    .taplive2-message-info
    img {
    /* margin-left: 4px; */
    width: 16px;
    position: relative;
    top: 1px;
    vertical-align: top;
  }
  /* message out text */

  /* message in text */
  .taplive2-chat-room-message-in-wrapper {
    padding-top: 16px;
    width: 100%;
    position: relative;
  }
  .taplive2-chat-room-message-in-wrapper .taplive2-message-review-bubble {
    padding: 10px !important;
    max-width: 250px !important;
  }

  .taplive2-chat-room-message-in-wrapper .taplive2-message-in-bubble {
    border-radius: 2px 16px 16px;
    border: 1px solid rgba(25, 25, 25, 0.1);
    background: #fff;
    display: inline-block;
    max-width: 231px;
    /* padding: 10px 45px 10px 10px; */
    padding: 10px;
    color: #191919;
    font-size: 14px;
    letter-spacing: -0.3px;
    line-height: 24px;
    position: relative;
    cursor: pointer;
    min-height: 44px;
  }

  .taplive2-chat-room-message-in-wrapper
    .taplive2-message-in-bubble
    .taplive2-review-button-bubble {
    color: #ffffff;
    border: none;
    letter-spacing: -0.5px;
    font-size: 14px;
    width: 100%;
    border-radius: 8px;
    margin-top: 4px;
    padding: 12px 0 13px;
  }

  .taplive2-review-button-bubble:hover {
    /* background: rgba(255, 126, 0, 0.8) !important; */
  }

  .taplive2-chat-room-message-in-wrapper
    .taplive2-message-in-bubble.deleted-bubble {
    color: #9b9b9b;
    padding: 10px;
  }

  .taplive2-chat-room-message-in-wrapper
    .taplive2-message-in-bubble.deleted-bubble
    .deleted-icon {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translate(0, -50%);
  }

  .taplive2-chat-room-message-in-wrapper
    .taplive2-message-in-bubble
    .taplive2-message-info {
    /* position: absolute; */
    /* bottom: 11px;
                  right: 27px; */
    margin: 0;
    color: rgba(25, 25, 25, 0.4);
    font-size: 12px;
    letter-spacing: -0.2px;
    line-height: 18px;
    /* width: 26px; */
    width: 100%;
    text-align: right;
    white-space: nowrap;
  }

  .taplive2-chat-room-message-in-wrapper
    .taplive2-message-in-bubble
    .taplive2-group-sender-name-wrapper {
    color: rgba(255, 126, 0, 1);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0;
    line-height: 1;
  }

  .taplive2-chat-room-message-in-wrapper .taplive2-group-sender-avatar-wrapper {
    width: 34px;
    height: 34px;
    display: inline-block;
    border-radius: 50%;
    vertical-align: top;
    margin-right: 5px;
    font-size: 16px;
    line-height: 34px;
    text-align: center;
    color: #fff;
  }

  .taplive2-chat-room-message-in-wrapper .taplive2-group-sender-avatar-wrapper img {
    width: 34px;
    height: 34px;
    display: inline-block;
    border-radius: 50%;
  }
  /* message in text */

  /* message in image */
  .taplive2-chat-room-message-image-in-wrapper {
    width: 100%;
    text-align: left;
    position: relative;
    padding-top: 16px;
    font-size: 0;
  }

  .taplive2-chat-room-message-image-in-wrapper
    .taplive2-group-sender-avatar-wrapper {
    width: 34px;
    height: 34px;
    display: inline-block;
    border-radius: 50%;
    vertical-align: top;
    margin-right: 5px;
    font-size: 14px;
    line-height: 34px;
    text-align: center;
    color: #fff;
  }

  .taplive2-chat-room-message-image-in-wrapper
    .taplive2-group-sender-avatar-wrapper
    img {
    width: 34px;
    height: 34px;
    display: inline-block;
    border-radius: 50%;
  }

  .taplive2-chat-room-message-image-in-wrapper .taplive2-message-in-bubble {
    border-radius: 2px 16px 16px;
    /* width: 200px; */
    max-width: 300px;
    display: inline-block;
    position: relative;
    font-size: 0;
    overflow: hidden;
    background: #fff;
    border: 1px solid rgba(25, 25, 25, 0.1);
  }

  .taplive2-chat-room-message-image-in-wrapper
    .taplive2-message-in-bubble
    .taplive2-bubble-image-wrapper {
    position: relative;
    min-width: 150px;
    min-height: 150px;
    padding: 4px;
  }

  .taplive2-chat-room-message-image-in-wrapper
    .taplive2-message-in-bubble
    .taplive2-bubble-image-wrapper
    img {
    vertical-align: top;
    width: 100%;
    max-width: 100%;
    cursor: pointer;
    border-radius: 0 16px 0 0;
  }

  .taplive2-chat-room-message-image-in-wrapper
    .taplive2-message-in-bubble
    .taplive2-bubble-image-wrapper.taplive2-bubble-wrapper-without-caption
    img {
    border-radius: 0 16px 16px 16px;
  }

  .taplive2-chat-room-message-image-in-wrapper
    .taplive2-message-in-bubble
    .taplive2-bubble-image-wrapper
    .taplive2-icon-status-wrapper {
    border-radius: 50%;
    position: absolute;
    width: 48px;
    height: 48px;
    display: inline-block;
    background: rgba(25, 25, 25, 0.4);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
  }

  .taplive2-chat-room-message-image-in-wrapper
    .taplive2-message-in-bubble
    .taplive2-bubble-image-wrapper
    .taplive2-icon-status-wrapper
    img {
    color: #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 20px;
  }

  .taplive2-chat-room-message-image-in-wrapper
    .taplive2-message-in-bubble
    .taplive2-bubble-image-wrapper
    .taplive2-icon-status-wrapper
    .taplive2-onprocess-wrapper {
    height: 40px;
    width: 40px;
    display: inline-block;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .taplive2-chat-room-message-image-in-wrapper
    .taplive2-message-in-bubble
    .taplive2-bubble-image-wrapper {
    vertical-align: top;
    width: 100%;
    min-width: 150px;
    min-height: 150px;
  }

  .taplive2-chat-room-message-image-in-wrapper
    .taplive2-message-in-bubble
    .taplive2-caption-text {
    font-size: 14px;
    /* padding: 10px 55px 10px 10px; */
    padding: 2px 10px 0 10px;
    margin: 0;
  }

  .taplive2-chat-room-message-image-in-wrapper
    .taplive2-message-in-bubble
    .taplive2-message-info {
    /* background: rgba(25, 25, 25, 0.6); */
    position: absolute;
    white-space: nowrap;
    border-radius: 10px;
    font-size: 12px;
    /* padding: 2px 5px; */
    color: #ffffff;
    bottom: 10px;
    right: 10px;
    z-index: 1;
    margin: 0;
    line-height: 18px;
    letter-spacing: -0.2px;
  }

  .taplive2-chat-room-message-image-in-wrapper
    .taplive2-message-in-bubble
    .taplive2-message-info.taplive2-message-info.taplive2-message-info-with-caption {
    position: relative;
    bottom: unset;
    right: unset;
    width: 100%;
    text-align: right;
    color: rgba(25, 25, 25, 0.4);
    padding: 2px 10px 3px;
  }

  .taplive2-chat-room-message-image-in-wrapper
    .taplive2-message-in-bubble.taplive2-not-sent-message-bubble {
    overflow: hidden;
  }

  .taplive2-chat-room-message-image-in-wrapper
    .taplive2-message-in-bubble.taplive2-not-sent-message-bubble
    .dark-cover {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: -5%;
    border-radius: 2px 16px 16px;
  }

  .taplive2-chat-room-message-image-in-wrapper:hover .message-action-wrapper {
    display: inline-block;
  }

  .taplive2-chat-room-message-image-in-wrapper .message-action-wrapper {
    right: unset !important;
    left: calc(30% + 40px) !important;
  }

  .taplive2-chat-room-message-image-in-wrapper
    .message-action-wrapper
    .message-action-button-wrapper {
    cursor: pointer;
  }

  .taplive2-chat-room-message-image-in-wrapper
    .CircularProgressbar
    .CircularProgressbar-trail {
    stroke: transparent;
  }

  .taplive2-chat-room-message-image-in-wrapper
    .CircularProgressbar
    .CircularProgressbar-path {
    stroke: #fff;
  }
  /* message in image */

  /* message out image */
  .taplive2-chat-room-message-image-out-wrapper {
    width: 100%;
    text-align: right;
    position: relative;
    padding-top: 16px;
    font-size: 0;
  }

  .taplive2-chat-room-message-image-out-wrapper .taplive2-message-out-bubble {
    border-radius: 16px 2px 16px 16px;
    /* width: 200px; */
    max-width: 300px;
    display: inline-block;
    position: relative;
    font-size: 0;
    border: 1px solid rgba(25, 25, 25, 0.1);
    overflow: hidden;
    background: rgba(255, 126, 0, 1);
    border: 1px solid rgba(25, 25, 25, 0.1);
    text-align: left;
    color: #fff;
  }

  .taplive2-chat-room-message-image-out-wrapper
    .taplive2-message-out-bubble
    .taplive2-bubble-image-wrapper {
    position: relative;
    min-width: 150px;
    min-height: 150px;
    padding: 4px;
  }

  .taplive2-chat-room-message-image-out-wrapper
    .taplive2-message-out-bubble
    .taplive2-bubble-image-wrapper.taplive2-bubble-image-wrapper.taplive2-bubble-wrapper-without-caption
    img {
    border-radius: 16px 0 16px 16px;
  }

  .taplive2-chat-room-message-image-out-wrapper
    .taplive2-message-out-bubble
    .taplive2-bubble-image-wrapper
    img {
    vertical-align: top;
    width: 100%;
    max-width: 100%;
    cursor: pointer;
    border-radius: 16px 0 0 0;
  }

  .taplive2-chat-room-message-image-out-wrapper
    .taplive2-message-out-bubble
    .taplive2-bubble-image-wrapper
    .taplive2-icon-status-wrapper {
    border-radius: 50%;
    position: absolute;
    width: 48px;
    height: 48px;
    display: inline-block;
    background: rgba(25, 25, 25, 0.4);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
  }

  .taplive2-chat-room-message-image-out-wrapper
    .taplive2-message-out-bubble
    .taplive2-bubble-image-wrapper
    .taplive2-icon-status-wrapper
    .taplive2-lds-ring {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    border-radius: 50%;
    padding: 4px;
  }

  .taplive2-chat-room-message-image-out-wrapper
    .taplive2-message-out-bubble
    .taplive2-bubble-image-wrapper
    .taplive2-icon-status-wrapper
    .taplive2-lds-ring
    div {
    border-color: rgba(255, 126, 0, 1) transparent transparent transparent;
    width: 40px;
    height: 40px;
  }

  .taplive2-chat-room-message-image-out-wrapper
    .taplive2-message-out-bubble
    .taplive2-bubble-image-wrapper
    .taplive2-icon-status-wrapper
    img {
    width: 24px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 20px;
  }

  .taplive2-chat-room-message-image-out-wrapper
    .taplive2-message-out-bubble
    .taplive2-bubble-image-wrapper
    .taplive2-icon-status-wrapper
    .taplive2-onprocess-wrapper {
    height: 40px;
    width: 40px;
    display: inline-block;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .taplive2-chat-room-message-image-out-wrapper
    .taplive2-message-out-bubble
    .taplive2-main-image-message {
    width: 100%;
  }

  .taplive2-chat-room-message-image-out-wrapper
    .taplive2-message-out-bubble
    .taplive2-caption-text {
    font-size: 14px;
    /* padding: 10px 73px 10px 10px; */
    padding: 2px 10px 0 10px;
    margin: 0;
  }

  .taplive2-chat-room-message-image-out-wrapper
    .taplive2-message-out-bubble
    .taplive2-message-info {
    /* background: rgba(25, 25, 25, 0.6); */
    margin: 0;
    position: absolute;
    white-space: nowrap;
    border-radius: 10px;
    font-size: 12px;
    /* padding: 2px 5px; */
    color: #ffffff;
    bottom: 10px;
    right: 10px;
    z-index: 1;
    line-height: 18px;
    letter-spacing: -0.2px;
  }

  .taplive2-chat-room-message-image-out-wrapper
    .taplive2-message-out-bubble
    .taplive2-message-info.taplive2-message-info.taplive2-message-info-with-caption {
    position: relative;
    bottom: unset;
    right: unset;
    width: 100%;
    text-align: right;
    color: #ffffff;
    padding: 2px 10px 3px;
  }

  .taplive2-chat-room-message-image-out-wrapper
    .taplive2-message-out-bubble
    .taplive2-message-info
    img {
    vertical-align: top;
    position: relative;
    top: 1px;
    /* margin-left: 3px; */
  }

  .taplive2-chat-room-message-image-out-wrapper
    .taplive2-message-out-bubble.taplive2-not-sent-message-bubble {
    overflow: hidden;
  }

  .taplive2-chat-room-message-image-out-wrapper
    .taplive2-failed-sending-file-warning {
    font-size: 12px;
    letter-spacing: -0.4px;
    color: rgba(25, 25, 25, 0.4);
  }

  .taplive2-chat-room-message-image-out-wrapper:hover .message-action-wrapper {
    display: inline-block;
  }

  .taplive2-chat-room-message-image-out-wrapper .message-action-wrapper {
    right: calc(30% + 10px) !important;
  }

  .taplive2-chat-room-message-image-out-wrapper
    .message-action-wrapper
    .message-action-button-wrapper {
    cursor: pointer;
  }

  .taplive2-chat-room-message-image-out-wrapper
    .CircularProgressbar
    .CircularProgressbar-trail {
    stroke: transparent;
  }

  .taplive2-chat-room-message-image-out-wrapper
    .CircularProgressbar
    .CircularProgressbar-path {
    stroke: #fff;
  }
  /* message out image */

  /* message in video */
  .taplive2-chat-room-message-video-in-wrapper {
    width: 100%;
    text-align: left;
    position: relative;
    padding-top: 16px;
    font-size: 0;
  }
  .taplive2-chat-room-message-video-in-wrapper
    .taplive2-group-sender-avatar-wrapper {
    width: 34px;
    height: 34px;
    display: inline-block;
    border-radius: 50%;
    vertical-align: top;
    margin-right: 5px;
    font-size: 14px;
    line-height: 34px;
    text-align: center;
    color: #ffffff;
  }

  .taplive2-chat-room-message-video-in-wrapper
    .taplive2-group-sender-avatar-wrapper
    img {
    width: 34px;
    height: 34px;
    display: inline-block;
    border-radius: 50%;
  }

  .taplive2-chat-room-message-video-in-wrapper .taplive2-message-in-bubble {
    border-radius: 2px 16px 16px;
    /* width: 200px; */
    max-width: 300px;
    display: inline-block;
    position: relative;
    font-size: 0;
    overflow: hidden;
    background: #ffffff;
    background: #ffffff;
    border: 1px solid rgba(25, 25, 25, 0.1);
  }

  .taplive2-chat-room-message-video-in-wrapper
    .taplive2-message-in-bubble
    .taplive2-bubble-image-wrapper {
    position: relative;
    min-width: 150px;
    min-height: 150px;
    padding: 4px;
  }

  .taplive2-chat-room-message-video-in-wrapper
    .taplive2-message-in-bubble
    .taplive2-bubble-image-wrapper.taplive2-bubble-wrapper-without-caption
    video {
    border-radius: 0 16px 16px 16px;
  }

  .taplive2-chat-room-message-video-in-wrapper
    .taplive2-message-in-bubble
    .taplive2-bubble-image-wrapper.taplive2-bubble-wrapper-without-caption
    img {
    border-radius: 0 16px 16px 16px;
  }

  .taplive2-chat-room-message-video-in-wrapper
    .taplive2-message-in-bubble
    .taplive2-bubble-image-wrapper
    .taplive2-icon-status-wrapper {
    border-radius: 50%;
    position: absolute;
    width: 48px;
    height: 48px;
    display: inline-block;
    background: rgba(25, 25, 25, 0.4);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
  }

  .taplive2-chat-room-message-video-in-wrapper
    .taplive2-message-in-bubble
    .taplive2-bubble-image-wrapper
    .taplive2-icon-status-wrapper
    img {
    color: #ffffff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 20px;
  }

  .taplive2-chat-room-message-video-in-wrapper
    .taplive2-message-in-bubble
    .taplive2-bubble-image-wrapper
    .taplive2-icon-status-wrapper
    .taplive2-onprocess-wrapper {
    height: 40px;
    width: 40px;
    display: inline-block;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .taplive2-chat-room-message-video-in-wrapper
    .taplive2-message-in-bubble
    .taplive2-bubble-image-wrapper
    .taplive2-video-thumbnail {
    width: 100%;
    border-radius: 0 16px 0 0;
  }

  .taplive2-chat-room-message-video-in-wrapper
    .taplive2-message-in-bubble
    .taplive2-bubble-image-wrapper
    .taplive2-video-image-thumbnail {
    width: 100%;
    border-radius: 0 16px 0 0;
  }

  .taplive2-chat-room-message-video-in-wrapper
    .taplive2-message-in-bubble
    .taplive2-main-image-message {
    width: 100%;
  }

  .taplive2-chat-room-message-video-in-wrapper
    .taplive2-message-in-bubble
    .taplive2-caption-text {
    font-size: 14px;
    /* padding: 10px 55px 10px 10px; */
    padding: 2px 10px 0 10px;
    margin: 0;
  }

  .taplive2-chat-room-message-video-in-wrapper
    .taplive2-message-in-bubble
    .taplive2-message-info {
    position: absolute;
    margin: 0;
    white-space: nowrap;
    border-radius: 10px;
    font-size: 12px;
    /* padding: 2px 5px; */
    letter-spacing: -0.2px;
    color: #ffffff;
    bottom: 10px;
    right: 10px;
    z-index: 1;
    line-height: 18px;
  }

  .taplive2-chat-room-message-video-in-wrapper
    .taplive2-message-in-bubble
    .taplive2-message-info.taplive2-message-info.taplive2-message-info-with-caption {
    position: relative;
    bottom: unset;
    right: unset;
    width: 100%;
    text-align: right;
    color: rgba(25, 25, 25, 0.4);
    padding: 2px 10px 3px;
  }

  .taplive2-chat-room-message-video-in-wrapper
    .taplive2-message-in-bubble
    .taplive2-message-info.taplive2-message-info-with-caption {
    position: relative;
    bottom: unset;
    right: unset;
    width: 100%;
    text-align: right;
    color: rgba(25, 25, 25, 0.4);
    padding: 2px 10px 3px;
  }

  .taplive2-chat-room-message-video-in-wrapper
    .taplive2-message-in-bubble
    .taplive2-message-info
    img {
    vertical-align: top;
    /* margin-left: 3px; */
  }

  .taplive2-chat-room-message-video-in-wrapper
    .taplive2-message-in-bubble.taplive2-not-sent-message-bubble {
    overflow: hidden;
  }

  .taplive2-chat-room-message-video-in-wrapper
    .taplive2-message-in-bubble.taplive2-not-sent-message-bubble
    .dark-cover {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    border-radius: 2px 16px 16px;
  }

  .taplive2-chat-room-message-video-in-wrapper
    .taplive2-message-in-bubble.taplive2-not-sent-message-bubble
    .dark-cover
    .taplive2-icon-status-wrapper {
    border-radius: 50%;
    position: absolute;
    width: 48px;
    height: 48px;
    display: inline-block;
    background: rgba(25, 25, 25, 0.4);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
  }

  .taplive2-chat-room-message-video-in-wrapper
    .taplive2-message-in-bubble.taplive2-not-sent-message-bubble
    .dark-cover
    .taplive2-icon-status-wrapper
    img {
    color: #ffffff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 20px;
  }

  .taplive2-chat-room-message-video-in-wrapper
    .taplive2-message-in-bubble.taplive2-not-sent-message-bubble
    .dark-cover
    .taplive2-icon-status-wrapper
    .taplive2-onprocess-wrapper {
    height: 40px;
    width: 40px;
    display: inline-block;
    border: solid 2px #ffffff;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .taplive2-chat-room-message-video-in-wrapper
    .taplive2-message-in-bubble
    .taplive2-timer-filesize-wrapper {
    background: rgba(25, 25, 25, 0.6);
    position: absolute;
    white-space: nowrap;
    border-radius: 10px;
    font-size: 12px;
    /* padding: 2px 5px; */
    color: rgba(255, 255, 255, 0.6);
    top: 10px;
    left: 10px;
    z-index: 2;
    padding: 2px 5px;
  }

  .taplive2-chat-room-message-video-in-wrapper .taplive2-message-in-bubble video {
    width: 100%;
    cursor: pointer;
  }

  .taplive2-chat-room-message-video-in-wrapper
    .taplive2-message-in-bubble
    .play-button-wraper {
    border-radius: 50%;
    position: absolute;
    width: 48px;
    height: 48px;
    display: inline-block;
    background: rgba(25, 25, 25, 0.4);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
  }

  .taplive2-chat-room-message-video-in-wrapper
    .taplive2-message-in-bubble
    .play-button-wraper
    img {
    color: #ffffff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 20px;
  }

  .taplive2-chat-room-message-video-in-wrapper:hover .message-action-wrapper {
    display: inline-block;
  }

  .taplive2-chat-room-message-video-in-wrapper .message-action-wrapper {
    right: unset !important;
    left: calc(30% + 34px) !important;
  }

  .taplive2-chat-room-message-video-in-wrapper
    .message-action-wrapper
    .message-action-button-wrapper {
    cursor: pointer;
  }

  .taplive2-chat-room-message-video-in-wrapper
    .CircularProgressbar
    .CircularProgressbar-trail {
    stroke: transparent;
  }

  .taplive2-chat-room-message-video-in-wrapper
    .CircularProgressbar
    .CircularProgressbar-path {
    stroke: #ffffff;
  }

  .modal-video .modal-content {
    width: 100%;
    height: 100%;
  }

  .modal-video .modal-content .modal-body {
    width: 100%;
    height: 100%;
    background: #191919;
  }

  .modal-video .modal-content .modal-body .video-wrapper {
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .modal-video .modal-content .modal-body .close-modal-video {
    position: absolute;
    top: 20px;
    right: 20px;
    color: #ffffff;
    font-size: 40px;
    cursor: pointer;
    transition: ease-in-out 0.1s;
  }

  .modal-video .modal-content .modal-body .close-modal-video:hover {
    color: #c9c9c9;
  }

  .modal-video .modal-content .modal-body video {
    max-height: 80vh;
  }
  /* message in video */

  /* message out video */
  .taplive2-chat-room-message-video-out-wrapper {
    width: 100%;
    text-align: right;
    position: relative;
    padding-top: 16px;
    font-size: 0;
  }
  .taplive2-chat-room-message-video-out-wrapper .taplive2-message-out-bubble {
    border-radius: 16px 2px 16px 16px;
    /* width: 200px; */
    max-width: 300px;
    display: inline-block;
    position: relative;
    font-size: 0;
    border: 1px solid rgba(25, 25, 25, 0.1);
    overflow: hidden;
    background: rgba(255, 126, 0, 1);
    border: 1px solid rgba(25, 25, 25, 0.1);
    text-align: left;
    color: #ffffff;
  }

  .taplive2-chat-room-message-video-out-wrapper
    .taplive2-message-out-bubble
    .taplive2-bubble-image-wrapper
    .taplive2-icon-status-wrapper {
    border-radius: 50%;
    position: absolute;
    width: 48px;
    height: 48px;
    display: inline-block;
    background: rgba(25, 25, 25, 0.4);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
  }

  .taplive2-chat-room-message-video-out-wrapper
    .taplive2-message-out-bubble
    .taplive2-bubble-image-wrapper
    .taplive2-icon-status-wrapper
    .taplive2-lds-ring {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    border-radius: 50%;
    padding: 4px;
  }

  .taplive2-chat-room-message-video-out-wrapper
    .taplive2-message-out-bubble
    .taplive2-bubble-image-wrapper
    .taplive2-icon-status-wrapper
    .taplive2-lds-ring
    div {
    border-color: rgba(255, 126, 0, 1) transparent transparent transparent;
    width: 40px;
    height: 40px;
  }

  .taplive2-chat-room-message-video-out-wrapper
    .taplive2-message-out-bubble
    .taplive2-bubble-image-wrapper
    .taplive2-icon-status-wrapper
    img {
    width: 24px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 20px;
  }

  .taplive2-chat-room-message-video-out-wrapper
    .taplive2-message-out-bubble
    .taplive2-bubble-image-wrapper {
    padding: 4px;
    position: relative;
    min-width: 150px;
    min-height: 150px;
  }

  .taplive2-chat-room-message-video-out-wrapper
    .taplive2-message-out-bubble
    .taplive2-bubble-image-wrapper
    video {
    width: 100%;
    cursor: pointer;
  }

  .taplive2-chat-room-message-video-out-wrapper
    .taplive2-message-out-bubble
    .taplive2-bubble-image-wrapper.taplive2-bubble-wrapper-without-caption
    video {
    border-radius: 16px 0 16px 16px;
  }

  .taplive2-chat-room-message-video-out-wrapper
    .taplive2-message-out-bubble
    .taplive2-bubble-image-wrapper.taplive2-bubble-wrapper-without-caption
    img {
    border-radius: 16px 0 16px 16px;
  }

  .taplive2-chat-room-message-video-out-wrapper
    .taplive2-message-out-bubble
    .taplive2-bubble-image-wrapper
    .taplive2-icon-status-wrapper {
    border-radius: 50%;
    position: absolute;
    width: 48px;
    height: 48px;
    display: inline-block;
    background: rgba(25, 25, 25, 0.4);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
  }

  .taplive2-chat-room-message-video-out-wrapper
    .taplive2-message-out-bubble
    .taplive2-bubble-image-wrapper
    .taplive2-icon-status-wrapper
    img {
    color: #ffffff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 20px;
  }

  .taplive2-chat-room-message-video-out-wrapper
    .taplive2-message-out-bubble
    .taplive2-bubble-image-wrapper
    .taplive2-icon-status-wrapper
    .taplive2-onprocess-wrapper {
    height: 40px;
    width: 40px;
    display: inline-block;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .taplive2-chat-room-message-video-out-wrapper
    .taplive2-message-out-bubble
    .taplive2-bubble-image-wrapper
    .taplive2-video-thumbnail {
    width: 100%;
    border-radius: 16px 0 0 0;
  }

  .taplive2-chat-room-message-video-out-wrapper
    .taplive2-message-out-bubble
    .taplive2-bubble-image-wrapper
    .taplive2-video-image-thumbnail {
    width: 100%;
    border-radius: 16px 0 0 0;
  }

  .taplive2-chat-room-message-video-out-wrapper
    .taplive2-message-out-bubble
    .taplive2-bubble-image-wrapper
    .taplive2-video-image-thumbnail {
    width: 100%;
  }

  .taplive2-chat-room-message-video-out-wrapper
    .taplive2-message-out-bubble
    .main-image-message {
    width: 100%;
  }

  .taplive2-chat-room-message-video-out-wrapper
    .taplive2-message-out-bubble
    .taplive2-caption-text {
    font-size: 14px;
    /* padding: 10px 73px 10px 10px; */
    padding: 2px 10px 0 10px;
    margin: 0;
  }

  .taplive2-chat-room-message-video-out-wrapper
    .taplive2-message-out-bubble
    .taplive2-message-info {
    position: absolute;
    white-space: nowrap;
    border-radius: 10px;
    font-size: 12px;
    /* padding: 2px 5px; */
    color: #ffffff;
    bottom: 10px;
    right: 10px;
    letter-spacing: -0.2px;
    z-index: 1;
    line-height: 18px;
    margin: 0;
  }

  .taplive2-chat-room-message-video-out-wrapper
    .taplive2-message-out-bubble
    .taplive2-message-info.taplive2-message-info-with-caption {
    position: relative;
    bottom: unset;
    right: unset;
    width: 100%;
    text-align: right;
    color: #ffffff;
    padding: 2px 10px 3px;
  }

  .taplive2-chat-room-message-video-out-wrapper
    .taplive2-message-out-bubble
    .taplive2-message-info
    img {
    vertical-align: top;
    position: relative;
    top: 1px;
    /* margin-left: 3px; */
  }
  .taplive2-chat-room-message-video-out-wrapper
    .taplive2-message-out-bubble.taplive2-not-sent-message-bubble {
    overflow: hidden;
  }

  .taplive2-chat-room-message-video-out-wrapper
    .taplive2-message-out-bubble
    .taplive2-timer-filesize-wrapper {
    background: rgba(25, 25, 25, 0.6);
    position: absolute;
    white-space: nowrap;
    border-radius: 10px;
    font-size: 12px;
    /* padding: 2px 5px; */
    color: rgba(255, 255, 255, 0.6);
    top: 10px;
    left: 10px;
    z-index: 2;
    padding: 2px 5px;
  }

  .taplive2-chat-room-message-video-out-wrapper
    .taplive2-message-out-bubble
    .play-button-wraper {
    border-radius: 50%;
    position: absolute;
    width: 48px;
    height: 48px;
    display: inline-block;
    background: rgba(25, 25, 25, 0.4);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
  }

  .taplive2-chat-room-message-video-out-wrapper
    .taplive2-message-out-bubble
    .play-button-wraper
    img {
    color: #ffffff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 20px;
  }

  .taplive2-chat-room-message-video-out-wrapper:hover .message-action-wrapper {
    display: inline-block;
  }

  .taplive2-chat-room-message-video-out-wrapper .message-action-wrapper {
    right: calc(30% + 10px) !important;
    left: unset !important;
  }

  .taplive2-chat-room-message-video-out-wrapper
    .message-action-wrapper
    .message-action-button-wrapper {
    cursor: pointer;
  }
  .taplive2-chat-room-message-video-out-wrapper
    .CircularProgressbar
    .CircularProgressbar-trail {
    stroke: transparent;
  }

  .taplive2-chat-room-message-video-out-wrapper
    .CircularProgressbar
    .CircularProgressbar-path {
    stroke: #ffffff;
  }

  .modal-video .modal-content {
    width: 100%;
    height: 100%;
  }

  .modal-video .modal-content .modal-body {
    width: 100%;
    height: 100%;
    background: #191919;
  }

  .modal-video .modal-content .modal-body .video-wrapper {
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .modal-video .modal-content .modal-body .close-modal-video {
    position: absolute;
    top: 20px;
    right: 20px;
    color: #ffffff;
    font-size: 40px;
    cursor: pointer;
    transition: ease-in-out 0.1s;
  }

  .modal-video .modal-content .modal-body .close-modal-video:hover {
    color: #c9c9c9;
  }

  .modal-video .modal-content .modal-body video {
    max-height: 80vh;
  }
  /* message out video */

  /* message in file */
  .taplive2-chat-room-message-file-in-wrapper {
    padding-top: 16px;
    width: 100%;
    position: relative;
    text-align: left;
  }

  .taplive2-chat-room-message-file-in-wrapper .message-action-right {
    right: -40px !important;
  }

  .taplive2-chat-room-message-file-in-wrapper
    .taplive2-group-sender-avatar-wrapper {
    width: 34px;
    height: 34px;
    display: inline-block;
    border-radius: 50%;
    vertical-align: top;
    margin-right: 5px;
    font-size: 14px;
    line-height: 34px;
    text-align: center;
    color: #ffffff;
  }

  .taplive2-chat-room-message-file-in-wrapper
    .taplive2-group-sender-avatar-wrapper
    img {
    width: 34px;
    height: 34px;
    display: inline-block;
    border-radius: 50%;
  }

  .taplive2-chat-room-message-file-in-wrapper .taplive2-message-in-bubble {
    border-radius: 2px 16px 16px;
    background: #ffffff;
    display: inline-block;
    max-width: calc(100% - 50px);
    /* padding: 10px 44px 10px 10px; */
    padding: 10px;
    color: #191919;
    font-size: 16px;
    letter-spacing: -0.3px;
    line-height: 24px;
    position: relative;
    cursor: pointer;
    color: #ffffff;
    text-align: left;
    border: 1px solid rgba(25, 25, 25, 0.1);
    /* width: 100%; */
  }

  .taplive2-chat-room-message-file-in-wrapper
    .taplive2-message-in-bubble
    .taplive2-message-info {
    /* position: absolute;
                  bottom: 11px;
                  right: 10px; */
    margin: 0;
    color: rgba(25, 25, 25, 0.4);
    font-size: 12px;
    letter-spacing: -0.2px;
    line-height: 18px;
    /* width: 26px; */
    width: 100%;
    text-align: right;
    white-space: nowrap;
  }

  .taplive2-chat-room-message-file-in-wrapper
    .taplive2-message-in-bubble
    .taplive2-message-info
    img {
    margin-left: 4px;
    width: 16px;
    position: relative;
    top: 4px;
  }

  .taplive2-chat-room-message-file-in-wrapper
    .taplive2-message-in-bubble
    .taplive2-message-bubble-file-wrapper {
    width: 100%;
    display: inline-block;
    color: #191919;
  }

  .taplive2-chat-room-message-file-in-wrapper
    .taplive2-message-in-bubble
    .taplive2-message-bubble-file-wrapper
    .taplive2-file-icon-wrapper {
    height: 46px;
    width: 46px;
    display: inline-block;
    background: rgba(255, 126, 0, 1);
    border-radius: 50%;
    vertical-align: top;
    position: relative;
  }

  .taplive2-chat-room-message-file-in-wrapper
    .taplive2-message-in-bubble
    .taplive2-message-bubble-file-wrapper
    .taplive2-file-icon-wrapper
    img {
    font-size: 25px;
    color: #ffffff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .taplive2-chat-room-message-file-in-wrapper
    .taplive2-message-in-bubble
    .taplive2-message-bubble-file-wrapper
    .taplive2-file-icon-wrapper
    .taplive2-onprocess-wrapper {
    height: 40px;
    width: 40px;
    display: inline-block;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .taplive2-chat-room-message-file-in-wrapper
    .taplive2-message-in-bubble
    .taplive2-message-bubble-file-wrapper
    .taplive2-file-detail-wrapper {
    width: calc(100% - 50px);
    display: inline-block;
    font-size: 14px;
    letter-spacing: -0.2px;
    padding-left: 10px;
    line-height: 19px;
    padding-top: 6px;
  }

  .taplive2-chat-room-message-file-in-wrapper
    .taplive2-message-in-bubble
    .taplive2-message-bubble-file-wrapper
    .taplive2-file-detail-wrapper
    .taplive2-filename-wrapper {
    width: 100%;
    margin: 0;
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .taplive2-chat-room-message-file-in-wrapper
    .taplive2-message-in-bubble
    .taplive2-message-bubble-file-wrapper
    .taplive2-file-detail-wrapper
    .taplive2-filename-wrapper
    b {
    letter-spacing: -0.4px;
    display: block;
  }

  .taplive2-chat-room-message-file-in-wrapper
    .taplive2-message-in-bubble
    .taplive2-message-bubble-file-wrapper
    .taplive2-file-detail-wrapper
    .taplive2-message-file-in-filesize {
    white-space: nowrap;
  }

  .taplive2-chat-room-message-file-in-wrapper:hover .message-action-wrapper {
    display: inline-block;
  }

  .taplive2-chat-room-message-file-in-wrapper
    .CircularProgressbar
    .CircularProgressbar-trail {
    stroke: rgba(255, 126, 0, 1);
  }

  .taplive2-chat-room-message-file-in-wrapper
    .CircularProgressbar
    .CircularProgressbar-path {
    stroke: #ffffff;
  }
  /* message in file */

  /* message out file */
  .taplive2-chat-room-message-file-out-wrapper {
    padding-top: 16px;
    width: 100%;
    position: relative;
    text-align: right;
  }

  .taplive2-chat-room-message-file-out-wrapper .taplive2-message-out-bubble {
    border-radius: 16px 2px 16px 16px;
    background: rgba(255, 126, 0, 1);
    display: inline-block;
    max-width: 100%;
    /* padding: 10px 62px 10px 10px; */
    padding: 10px;
    color: #191919;
    font-size: 14px;
    letter-spacing: -0.3px;
    line-height: 24px;
    position: relative;
    cursor: pointer;
    color: #ffffff;
    text-align: left;
    /* width: 90%; */
  }

  .taplive2-chat-room-message-file-out-wrapper
    .taplive2-message-out-bubble
    .taplive2-message-info {
    /* position: absolute;
                  bottom: 11px;
                  right: 10px; */
    margin: 0;
    color: #ffffff;
    font-size: 12px;
    letter-spacing: -0.2px;
    line-height: 18px;
    /* width: 26px; */
    width: 100%;
    text-align: right;
    white-space: nowrap;
  }

  .taplive2-chat-room-message-file-out-wrapper
    .taplive2-message-out-bubble
    .taplive2-message-info
    img {
    /* margin-left: 4px; */
    width: 16px;
    position: relative;
    top: 1px;
    vertical-align: top;
  }

  .taplive2-chat-room-message-file-out-wrapper
    .taplive2-message-out-bubble
    .taplive2-message-bubble-file-wrapper {
    width: 100%;
    display: inline-block;
  }

  .taplive2-chat-room-message-file-out-wrapper
    .taplive2-message-out-bubble
    .taplive2-message-bubble-file-wrapper
    .taplive2-file-icon-wrapper {
    height: 46px;
    width: 46px;
    display: inline-block;
    background: #ffffff;
    border-radius: 50%;
    vertical-align: top;
    position: relative;
  }

  .taplive2-chat-room-message-file-out-wrapper
    .taplive2-message-out-bubble
    .taplive2-message-bubble-file-wrapper
    .taplive2-file-icon-wrapper
    img {
    font-size: 25px;
    color: rgba(255, 126, 0, 1);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .taplive2-chat-room-message-file-out-wrapper
    .taplive2-message-out-bubble
    .taplive2-message-bubble-file-wrapper
    .taplive2-file-icon-wrapper
    .taplive2-onprocess-wrapper {
    height: 40px;
    width: 40px;
    display: inline-block;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .taplive2-chat-room-message-file-out-wrapper
    .taplive2-message-out-bubble
    .taplive2-message-bubble-file-wrapper
    .taplive2-file-detail-wrapper {
    width: calc(100% - 50px);
    display: inline-block;
    font-size: 14px;
    letter-spacing: -0.2px;
    padding-left: 10px;
    line-height: 19px;
    padding-top: 6px;
  }

  .taplive2-chat-room-message-file-out-wrapper
    .taplive2-message-out-bubble
    .taplive2-message-bubble-file-wrapper
    .taplive2-file-detail-wrapper
    .taplive2-filename-wrapper {
    width: 100%;
    margin: 0;
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .taplive2-chat-room-message-file-out-wrapper
    .taplive2-message-out-bubble
    .taplive2-message-bubble-file-wrapper
    .taplive2-file-detail-wrapper
    .taplive2-filename-wrapper
    b {
    letter-spacing: -0.4px;
    display: block;
  }

  .taplive2-chat-room-message-file-out-wrapper
    .taplive2-message-out-bubble
    .taplive2-message-bubble-file-wrapper
    .taplive2-file-detail-wrapper
    .taplive2-message-file-out-filesize {
    white-space: nowrap;
  }

  .taplive2-chat-room-message-file-out-wrapper
    .taplive2-failed-sending-file-warning {
    font-size: 12px;
    letter-spacing: -0.4px;
    color: rgba(25, 25, 25, 0.4);
  }

  .taplive2-chat-room-message-file-out-wrapper:hover .message-action-wrapper {
    display: inline-block;
    left: -90px;
  }

  .taplive2-chat-room-message-file-out-wrapper
    .CircularProgressbar
    .CircularProgressbar-trail {
    stroke: #ffffff;
  }

  .taplive2-chat-room-message-file-out-wrapper
    .CircularProgressbar
    .CircularProgressbar-path {
    stroke: rgba(255, 126, 0, 1);
  }
  /* message out file */

  /* send message */
  .taplive2-main-chat-room-wrapper
    .taplive2-main-chat-room-send-message-panel-wrapper {
    width: 100%;
    /* height: 56px; */
    box-shadow: 0px -1px 0px 0px rgba(25, 25, 25, 0.1);
    background: #ffffff;
    border-radius: 0 0 8px 8px;
    /* position: relative; */
    /* top: 2px; */
    bottom: 0;
    position: absolute;
    /* padding: 8px 12px; */
    /* padding: 7px 12px 4px; */
    padding: 7px 0 4px;
  }

  .taplive2-main-chat-room-wrapper
    .taplive2-main-chat-room-send-message-panel-wrapper
    .taplive2-main-chat-room-send-message-hamburger {
    border-radius: 50%;
    background: rgba(255, 126, 0, 1);
    width: 32px;
    height: 32px;
    text-align: center;
    position: relative;
    top: 4px;
    display: inline-block;
    vertical-align: top;
    cursor: pointer;
    margin-left: 14px;
  }

  .taplive2-main-chat-room-wrapper
    .taplive2-main-chat-room-send-message-panel-wrapper
    .taplive2-main-chat-room-send-message-hamburger:hover {
    /* background: rgba(255, 126, 0, 0.8); */
  }

  .taplive2-main-chat-room-wrapper
    .taplive2-main-chat-room-send-message-panel-wrapper
    .taplive2-main-chat-room-send-message-hamburger
    img {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  .taplive2-main-chat-room-wrapper
    .taplive2-main-chat-room-send-message-panel-wrapper
    .taplive2-main-chat-room-send-message-input {
    display: inline-block;
    position: relative;
    height: 100%;
    margin-left: 8px;
    transition: ease-in-out 0.3s;
    /* width: calc(100% - 44px); */
    width: calc(100% - 70px);
    z-index: 1;
  }

  .taplive2-main-chat-room-wrapper
    .taplive2-main-chat-room-send-message-panel-wrapper
    .taplive2-main-chat-room-send-message-input-typing {
    /* width: calc(100% - 87px); */
    width: calc(100% - 114px);
  }

  .taplive2-main-chat-room-wrapper
    .taplive2-main-chat-room-send-message-panel-wrapper
    .taplive2-main-chat-room-send-message-input
    input[type='file'] {
    display: none;
  }

  .taplive2-main-chat-room-wrapper
    .taplive2-main-chat-room-send-message-panel-wrapper
    .taplive2-main-chat-room-send-message-input
    textarea {
    border-radius: 20px;
    box-shadow: 0px 0px 0px 1px #dcdcdc;
    border: none;
    width: 100%;
    /* height: 100%; */
    color: rgba(25, 25, 25, 0.6);
    font-size: 14px;
    letter-spacing: -0.3px;
    padding: 11px 46px 11px 16px;
    resize: none;
    overflow: hidden;
    max-height: 124px;
  }

  .taplive2-main-chat-room-wrapper
    .taplive2-main-chat-room-send-message-panel-wrapper
    .taplive2-main-chat-room-send-message-input
    img {
    position: absolute;
    right: 17px;
    top: 10px;
    cursor: pointer;
    /* transform: translate(0, -50%); */
    transition: ease-in-out 0.3s;
  }

  .taplive2-main-chat-room-wrapper
    .taplive2-main-chat-room-send-message-panel-wrapper
    .taplive2-main-chat-room-send-message-input
    img:hover {
    transform: scale(1.1);
  }

  .taplive2-main-chat-room-wrapper
    .taplive2-main-chat-room-send-message-panel-wrapper
    .taplive2-main-chat-room-send-message-input
    .taplive2-input-file-media-wrapper {
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0px 2px 20px 0px rgba(25, 25, 25, 0.18);
    position: absolute;
    right: 0;
    z-index: 1;
    background: #ffffff;
    top: -70px;
    width: 220px;
    z-index: 2;
    padding: 10px;
    line-height: 23px;
    display: none;
    cursor: pointer;
  }

  .taplive2-main-chat-room-wrapper
    .taplive2-main-chat-room-send-message-panel-wrapper
    .submit-chat-icon-wrapper {
    background: rgba(255, 126, 0, 1);
  }

  .taplive2-main-chat-room-wrapper
    .taplive2-main-chat-room-send-message-panel-wrapper
    .input-message-wrapper {
    width: 32px;
    height: 32px;
    right: 12px;
    position: absolute;
    display: inline-block;
    border-radius: 50%;
    text-align: center;
    top: 11px;
    cursor: pointer;
    transition: ease-in-out 0.1s;
  }

  .taplive2-main-chat-room-wrapper
    .taplive2-main-chat-room-send-message-panel-wrapper
    .submit-chat-icon-wrapper {
  }

  .taplive2-main-chat-room-wrapper
    .taplive2-main-chat-room-send-message-panel-wrapper
    .input-message-wrapper
    img {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  .taplive2-main-chat-room-wrapper
    .taplive2-main-chat-room-send-message-panel-wrapper
    .submit-chat-icon-wrapper:hover {
    background: rgba(255, 126, 0, 0.8);
  }

  .taplive2-input-file-media-wrapper .taplive2-input-file-media-list {
    cursor: pointer;
    padding: 0 0 8px;
  }

  .taplive2-input-file-media-wrapper .taplive2-input-file-media-list:hover {
    color: rgba(255, 126, 0, 1);
  }

  .taplive2-input-file-media-wrapper
    label:nth-last-child(1)
    .taplive2-input-file-media-list {
    border-bottom: 0;
    padding: 0;
  }

  .taplive2-input-file-media-wrapper .taplive2-input-file-media-list img {
    position: relative !important;
    top: -2px !important;
    right: unset !important;
    transform: unset !important;
    vertical-align: top;
    margin-right: 5px;
  }

  .taplive2-main-chat-room-solve-wrapper {
    height: 40px;
    position: relative;
    width: 100%;
    /* border-radius: 0 0 8px 8px; */
    /* background: pink; */
    top: 4px;
    padding: 10px 8px 6px 20px;
    color: rgb(25, 25, 25);
    font-size: 16px;
    letter-spacing: -0.5px;
    cursor: pointer;
    transition: ease-in-out 0.2s;
    background: #efefef;
  }

  .taplive2-main-chat-room-solve-wrapper.taplive2-main-chat-room-solve-wrapper-hide {
    height: 0;
    overflow: hidden;
    padding: 0;
  }

  .taplive2-main-chat-room-solve-wrapper .taplive2-lds-ring {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  .taplive2-main-chat-room-solve-wrapper .taplive2-lds-ring div {
    border-color: rgba(255, 126, 0, 1) transparent transparent transparent;
  }

  .taplive2-main-chat-room-solve-wrapper:hover {
    background: #ece9e9;
  }

  .taplive2-main-chat-room-solve-wrapper img {
    vertical-align: top;
    position: relative;
    top: 2px;
    margin-right: 4px;
  }
  /* send messsage */
  /* chat room */

  /* circular progress */
  .taplive2-file-bubble-circular-progress-wrapper {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    border-radius: 50%;
    z-index: 1;
  }

  .taplive2-file-bubble-circular-progress-wrapper .taplive2-lds-ring-file-out {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background: #ffffff;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    padding: 3px;
  }

  .taplive2-file-bubble-circular-progress-wrapper .taplive2-lds-ring-file-out div {
    border-color: rgba(255, 126, 0, 1) transparent transparent transparent;
    width: 40px;
    height: 40px;
  }

  .taplive2-file-bubble-circular-progress-wrapper .taplive2-lds-ring-file-in {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background: rgba(255, 126, 0, 1);
    width: 100%;
    height: 100%;
    border-radius: 50%;
    padding: 3px;
  }

  .taplive2-file-bubble-circular-progress-wrapper .taplive2-lds-ring-file-in div {
    border-color: #ffffff transparent transparent transparent;
    width: 40px;
    height: 40px;
  }
  /* circular progress */

  .taplive2-message-file-out-filesize {
    white-space: nowrap;
  }

  .taplive2-message-filesize-progress {
    display: inline-block;
  }

  .typing-dot-one {
    opacity: 0;
    animation: typing-dot 1.3s infinite;
    animation-delay: 0s;
    letter-spacing: 1px;
  }

  .typing-dot-two {
    opacity: 0;
    animation: typing-dot 1.3s infinite;
    animation-delay: 0.2s;
    letter-spacing: 1px;
  }

  .typing-dot-three {
    opacity: 0;
    animation: typing-dot 1.3s infinite;
    animation-delay: 0.3s;
    letter-spacing: 1px;
  }

  @keyframes typing-dot {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  .taplive2-chat-list-wrapper.active-typing .last-message-room-list {
    display: none;
  }

  .taplive2-chat-list-wrapper .typing-room-list {
    display: none;
  }

  .taplive2-chat-list-wrapper.active-typing .typing-room-list {
    display: block;
  }

  .message-body {
    word-break: break-word;
  }

  .button-disabled {
    background: #9b9b9b !important;
  }

  .active-taplive2-power
    .taplive2-start-new-chat-wrapper
    .taplive2-start-new-chat-bottom-content {
    /* height: calc(100% - 160px);
      max-height: calc(100% - 160px); */
    height: calc(100% - 125px);
    max-height: calc(100% - 125px);
    position: absolute;
    /* top: 160px; */
    top: 125px;
  }

  .active-taplive2-power
    .taplive2-room-list-with-omnichannel
    .taplive2-start-new-chat-bottom-content {
    /* height: calc(100% - 160px);
      max-height: calc(100% - 160px); */
    height: calc(100% - 125px);
    max-height: calc(100% - 125px);
    position: absolute;
    /* top: 160px; */
    top: 125px;
  }

  .active-taplive2-power
    .taplive2-start-new-chat-wrapper
    .taplive2-start-new-chat-bottom-content
    .taplive2-start-new-chat-form-wrapper {
    max-height: 100%;
  }

  .active-taplive2-power
    .taplive2-room-list-with-omnichannel
    .taplive2-start-new-chat-bottom-content
    .taplive2-start-new-chat-form-wrapper {
    max-height: 100%;
  }

  .active-taplive2-power .taplive2-room-list-bottom-content {
    height: calc(100vh - 252px);
  }

  .active-taplive2-power
    .taplive2-main-chat-room-wrapper
    .taplive2-main-chat-room-send-message-panel-wrapper {
    border-radius: 0;
  }

  .active-taplive2-power .new-conversation-form-wrapper {
    top: 70px !important;
  }

  .active-taplive2-power
    .new-conversation-form-wrapper
    .taplive2-start-new-chat-form-wrapper {
    max-height: unset !important;
  }

  /* custom select option */
  .taplive2-custom-select-option {
    width: 100%;
    position: relative;
    cursor: pointer;
  }

  .taplive2-custom-select-option .taplive2-custom-select-option-value-box {
    display: block;
    width: 100%;
    box-sizing: border-box;
    border-radius: 8px;
    border: 1px solid rgba(25, 25, 25, 0.1);
    font-size: 16px;
    letter-spacing: -0.3px;
    padding: 16px;
    margin: 8px 0 12px;
  }

  .taplive2-custom-select-option-value-box::before {
    content: '';
    position: absolute;
    top: 23px;
    transform: rotate(135deg);
    right: 16px;
    border-top: solid 3px #191919;
    border-right: solid 3px #191919;
    border-bottom: solid 3px #ffffff;
    border-left: solid 3px #ffffff;
    transition: ease-in-out 0.3s;
  }

  .taplive2-custom-select-option-value-box.taplive2-active-select-box::before {
    top: 25px;
    transform: rotate(-45deg);
  }

  .taplive2-custom-select-option-wrapper {
    background: #ffffff;
    display: none;
    position: absolute;
    left: 0;
    top: 55px;
    width: 100%;
    border-radius: 8px;
    border: solid 1px rgba(25, 25, 25, 0.1);
    padding: 0;
    z-index: 1;
    max-height: 89px;
    overflow-y: auto;
  }

  .taplive2-custom-select-option-list {
    padding: 5px 16px;
  }

  .taplive2-custom-select-option-list:nth-child(1) {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }

  .taplive2-custom-select-option-list:nth-last-child(1) {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  .taplive2-custom-select-option-list:hover {
    background: rgba(255, 126, 0, 0.1);
  }

  .taplive2-custom-select-option-list.taplive2-selected-option {
    background: rgba(255, 126, 0, 0.1);
  }
  /* custom select option */

  /* omnichannel option */
  .omnichannel-option-wrapper {
  }

  .omnichannel-option-description {
    font-size: 14px;
    letter-spacing: -0.4px;
    color: rgba(25, 25, 25, 0.6);
    margin: 0;
    text-align: center;
  }

  .omnichannel-option-image-wrapper {
    width: 100%;
    text-align: center;
    margin-top: 12px;
  }

  .omnichannel-option-image-wrapper a {
    text-decoration: none;
  }

  .omnichannel-option-image-wrapper img {
    vertical-align: top;
    margin-right: 7px;
    display: inline-block;
    max-width: calc(20% - 9.6px);
    transition: ease-in-out 0.1s;
    opacity: 1;
    text-align: center;
  }

  /* .omnichannel-option-image-wrapper:hover img {
      opacity: .5;
  } */

  .omnichannel-option-image-wrapper img:hover {
    /* transform: scale(0.95); */
    /* opacity: 1; */
    /* opacity: .5; */
    /* display: none; */
    opacity: 1 !important;
  }

  .omnichannel-option-image-wrapper a:nth-last-child(1) img {
    margin-right: 0;
  }

  .omnichannel-option-separator {
    border-top: solid 1px #f3f3f3;
    position: relative;
    width: 100%;
    margin: 36px 0 12px;
  }

  .omnichannel-option-separator::before {
    content: 'OR';
    background: #ffffff;
    padding: 0 8px;
    font-weight: bold;
    color: rgba(25, 25, 25, 0.6);
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  /* omnichannel option */

  /*submit button*/
  .taplive2-submit-button {
    color: #ffffff;
    background: rgba(255, 126, 0, 1);
    width: 100%;
    border-radius: 8px;
    border: none;
    font-size: 16px;
    letter-spacing: -0.5px;
    text-align: center;
    width: 100%;
    padding: 13px;
    margin-top: 10px;
    cursor: pointer;
  }

  .taplive2-submit-button:hover {
    /* background: rgba(255, 126, 0, 0.8) !important; */
  }
  /*submit button*/

  /*full loading*/
  .taplive2-full-loading {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    background: white;
    border-radius: 8px;
    left: 0;
    z-index: 1;
  }

  .taplive2-full-loading-content {
    text-align: center;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    left: 50%;
    font-size: 14px;
    color: rgba(255, 126, 0, 0.8);
  }

  .taplive2-full-loading-content .taplive2-lds-ring {
    width: 28px;
    height: 28px;
  }

  .taplive2-full-loading-content .taplive2-lds-ring div {
    width: 28px;
    height: 28px;
    border-color: rgba(255, 126, 0, 0.8) transparent transparent transparent;
  }
  /*full loading*/

  /*room list with omnichannel*/
  .taplive2-room-list-with-omnichannel .taplive2-start-new-chat-form-wrapper {
    border-radius: 8px !important;
  }

  .taplive2-room-list-with-omnichannel-wrapper {
    margin-top: 16px;
    padding: 49px 16px 16px;
    background: #ffffff;
    border-radius: 8px;
    position: relative;
  }

  .taplive2-room-list-omnichannel-your-message {
    font-size: 14px;
    letter-spacing: -0.4px;
    color: #ff7d00;
    color: rgba(25, 25, 25, 0.6);
    position: absolute;
    left: 16px;
    top: 16px;
    margin: 0;
  }

  .taplive2-room-list-omnichannel-all-message {
    cursor: pointer;
    font-size: 14px;
    letter-spacing: -0.4px;
    color: #ff7d00;
    right: 16px;
    top: 16px;
    margin: 0;
    position: absolute;
    transition: ease-in-out 0.1s;
  }

  .taplive2-room-list-omnichannel-all-message:hover {
    opacity: 0.8;
  }

  .taplive2-room-list-with-omnichannel-button-wrapper button {
    border-radius: 8px;
    /* border: solid 1px rgba(255, 126, 0, 1); */
    /* background: #ffffff; */
    /* color: rgba(255, 126, 0, 1); */
    width: 100%;
    padding: 13px;
    cursor: pointer;
    font-size: 16px;
    letter-spacing: -0.5px;
    margin-top: 4px;
  }

  .taplive2-room-list-with-omnichannel-button-wrapper button:hover {
    /* background: rgba(255, 126, 0, 0.1) !important; */
  }

  .taplive2-room-list-with-omnichannel-button-wrapper button img {
    margin-right: 5px;
  }

  .taplive2-room-list-with-omnichannel-button-wrapper button b {
    position: relative;
    top: -1px;
  }

  .taplive2-room-list-with-omnichannel-chatlist-wrapper
    .taplive2-shimmer-room-list {
    height: unset;
  }
  /*room list with omnichanel*/

  .taplive2-start-new-case-without-omnichannel .first-start-new-case-back-button {
    display: none;
    padding: 3px;
    border-radius: 50%;
    transition: ease-in-out 0.1s;
  }

  .taplive2-start-new-case-without-omnichannel.taplive2-active-start-new-case-with-omnichannel
    .taplive2-start-new-chat-top-content {
    padding-left: 65px;
  }

  .taplive2-start-new-case-without-omnichannel.taplive2-active-start-new-case-with-omnichannel
    .first-start-new-case-back-button {
    display: inline-block;
    position: absolute;
    left: 24px;
    top: 22px;
    cursor: pointer;
  }

  .taplive2-start-new-case-without-omnichannel.taplive2-active-start-new-case-with-omnichannel
    .first-start-new-case-back-button:hover {
    background: rgba(25, 25, 25, 0.3);
  }

  .taplive2-first-message-us-directly {
    width: 100%;
    padding: 13px;
    cursor: pointer;
    font-size: 16px;
    letter-spacing: -0.5px;
    border-radius: 8px;
    margin-top: 10px;
  }

  .taplive2-message-info-dark {
    background: rgba(25, 25, 25, 0.6);
    padding: 2px 5px;
    color: #ffffff !important;
  }

  .bubble-action-icon-wrapper {
    border-radius: 50%;
    position: absolute;
    width: 48px;
    height: 48px;
    display: inline-block;
    background: rgba(25, 25, 25, 0.4);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
  }

  .bubble-action-icon-wrapper img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .taplive2-fullscreen-background-dark {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(25, 25, 25, 0.9);
    display: none;
    z-index: 2;
  }

  .taplive2-fullscreen-background-dark .taplive2-close-blackscreen {
    cursor: pointer;
    position: absolute;
    right: 16px;
    top: 16px;
  }

  .taplive2-fullscreen-background-dark .taplive2-close-blackscreen:hover {
    transform: scale(0.9);
  }

  .taplive2-image-preview-panel .taplive2-image-preview-value {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: calc(100vw - 130px);
    max-height: calc(100vh - 130px);
  }

  .taplive2-video-preview-panel .taplive2-video-preview-value {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: calc(100vw - 130px);
    max-height: calc(100vh - 130px);
  }

  .taplive2-preupload-file-wrapper {
    position: absolute;
    display: inline-block;
    left: 50%;
    transform: translate(-50%, -50%);
    top: 50%;
    text-align: center;
    min-width: 250px;
  }

  .taplive2-preupload-file-wrapper input {
    border: none;
    border-bottom: solid 1px #ffffff;
    color: #ffffff;
    font-size: 14px;
    letter-spacing: -0.5px;
    outline: none;
    text-align: left;
    background: none;
    padding: 8px 28px 8px 0;
    width: 100%;
  }

  .taplive2-preupload-file-wrapper input:focus {
    border: none !important;
    box-shadow: none !important;
    outline: none !important;
    border-bottom: solid 1px #ffffff !important;
  }

  .taplive2-preupload-file-wrapper input::placeholder {
    color: rgba(255, 255, 255, 0.3);
  }

  .taplive2-preupload-file-wrapper .taplive2-send-media-button-submit {
    position: absolute;
    right: 0;
    bottom: 41px;
    cursor: pointer;
  }

  .taplive2-preupload-file-wrapper .taplive2-caption-limit {
    width: 100%;
    text-align: right;
    padding: 8px 0;
    color: #ffffff;
    font-size: 14px;
    letter-spacing: -0.5px;
  }

  .taplive2-image-preupload-panel
    .taplive2-image-preupload-file-wrapper
    .taplive2-image-preupload-value {
    max-width: calc(100vw - 170px);
    max-height: calc(100vh - 170px);
  }

  .taplive2-video-preupload-panel
    .taplive2-video-preupload-file-wrapper
    .taplive2-video-preupload-value {
    max-width: calc(100vw - 170px);
    max-height: calc(100vh - 170px);
  }
}