.sectionContent {
  background: #f8f8f8;
  /* padding: 125px 50px 125px 255px; */
  /* padding: 5px 0 0 200px; */
  /* padding: 0 0 0 200px; */
  // padding: 0 30px 0 370px;
  
  // padding: 0 30px 0 334px;
  padding: 0 30px;  
  // left: 334px;
  left: 348px;
  min-height: 100%;
  position: fixed;
  top: 0;
  width: calc(100vw - 348px);
  max-height: 100%;
  overflow: auto;
  transition: ease-in-out .1s;

  &.section-width-100 {
    width: 100% !important;
    left: 0 !important;
  }

  table {
    tbody {
      tr {
        border-bottom: solid 1px #dcdcdc;

        td {
          background: none !important;
        }

        &:hover {
          // td {
            background: rgba(255, 242, 229, 1) !important;
          // }
        }
      }

      td {
        word-break: break-word;

        &.no-data-column {
          background: #fff !important;
          cursor: default !important;
        }
      }
    }

    thead {
      td {
        word-break: break-word;
      }
    }

    &.no-hover {
      tbody {
        tr {
          border-bottom:  none !important;
          
          &:hover {
            td {
              background: none !important;
            }
          }

          &:nth-child(odd) {
            td {
              background: #f8f8f8 !important;
            }
          }

          &:nth-child(even) {
            td {
              background: #ffffff !important;
            }
          }
        }
      }
    }
  }

  // .test-wr {
  //   position: absolute;
  //   top: 0;
  //   left: 0;
  //   width: 100%;
  //   height: 100%;
  //   background: rgba(25, 25, 25, 0.6);
  //   z-index: 2;
  // }

  &.deactive-sub-menu {
    // padding: 0 30px 0 92px;
    left: 62px;
    padding: 0 30px;
    width: calc(100vw - 62px);
  }

  &.section-wrap-active-billing-warning {
    top: 32px;
    min-height: calc(100% - 32px);
    max-height: calc(100% - 32px);
  }
}

.sectionWrap {
  position: relative;
  background: #fff;
  padding: 32px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  margin: 24px 0;

  .tcRow {
    &:hover {
      background: rgba(255, 242, 229, 1) !important;
    }
  }

  .bodyContent {
    margin: 20px 0 0 !important;
  }

  &.sectionWrapTop-12 {
    margin: 12px 0 0 !important;
  }
}

.sectionWrap .topContent {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .topContent-button-wrapper {
    button {
      margin-left: 4px;
    }
  }
}

.sectionWrap .topContent.bordered {
  padding-bottom: 16px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.sectionWrap .topContent .title {
  font-size: 20px;
  font-family: PTRootBold;
  line-height: 1.2;
  letter-spacing: -0.5px;
}

.sectionWrap .bodyContent {
  margin: 4px auto 24px;
  position: relative;
}

.sectionWrap .formBodyContent {
  margin: 24px auto;
}

.sectionWrap .formBodyContent label {
  margin: 0 0 8px;
}

.sectionWrap .bodyContent .reload {
  background: rgba(255, 255, 255, 0.7);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 35px;
}

.sectionWrap .flexContent {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.sectionWrap .flexContent.left {
  justify-content: flex-start;
}

.sectionWrap .flexContent .agentSearch {
  max-width: 370px;
  flex: 1;
  padding-right: 20px;
}

.sectionWrap .flexContent .agentAction {
  display: flex;
  align-items: center;
  position: relative;
  top: 0;
}

.sectionWrap .checkArea {
  display: flex;
  margin: 24px;
}

.sectionWrap .input-wrapper .input-icon {
  position: absolute;
  right: 15px;
  top: 14px;
  text-align: center;
  font-size: 16px;
  height: 24px;
  width: 24px;
  opacity: 0.5;
}

.sectionWrap .checkArea .input-wrapper {
  margin-right: 10px;
}

.sectionWrap .value {
  font-family: PTRootBold;
  text-transform: capitalize;
  margin: 4px 0;
  cursor: pointer;
}

.sectionWrap .formContent {
  position: relative;
  max-width: 350px;
}

.sectionWrap .tableContent {
  position: relative;
  margin: 4px auto 24px;
}

.sectionWrap .tcHeader,
.sectionWrap .tcRow {
  display: flex;
  width: 100%;
  font-size: 14px;
}

.sectionWrap .tcHeader {
  font-family: PTRootBold;
  font-weight: 600;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);

  .custom-checkbox-stripe [type="checkbox"]:checked + label:after {
    top: -3px;
    left: -5px;
    background-size: unset !important;
  }
}

.sectionWrap .tcRow {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.sectionWrap .tcRow.inside {
  border: none;
  padding: 0;
}

.sectionWrap .tcRow.plain {
  border: none;
}

.sectionWrap .tcCol {
  flex: 1;
  padding: 16px 10px;
  word-wrap: break-word;

  label {
    margin: 0;
  }
}

.sectionWrap .tcHeader .tcColCheck {
  padding: 0;
  text-align: center;
}

.sectionWrap .tcRow .tcColCheck {
  padding: 14px 23px;
  text-align: center;

  .custom-checkbox [type="checkbox"]:not(:checked) + label:before, .custom-checkbox [type="checkbox"]:checked + label:before {
    top: -10px !important;
  }

  .custom-checkbox [type="checkbox"]:checked + label:after {
    top: 10px !important;
  }
}

.sectionWrap .tcCol.capital {
  text-transform: capitalize;
}

.sectionWrap .tcHeader .tcCol {
  padding: 8px 10px;
}

.sectionWrap td.tcCol {
  flex: none;
}

.sectionWrap .tcCol.info {
  font-family: PTRootBold;
  text-align: center;
  color: rgba(0, 0, 0, 0.4);
  padding: 0;
  margin-top: 32px;
  margin-bottom: 0;
}

.sectionWrap .tcCol.info.center {
  text-align: center;
}

.sectionWrap .tcRow.inside .tcCol {
  padding: 0;
}

.sectionWrap .tcCol.flex {
  padding-left: 0;
  padding-right: 0;
  display: flex;
  align-items: center;
}

@media (max-width: 1200px) {
  .sectionWrap .tcCol.flex {
    display: block;
  }
}

.sectionWrap .textInfo {
  font: 16px PTRootBold;
  line-height: 1.5;
  letter-spacing: -0.2px;
}

.sectionWrap .textInfo.center {
  text-align: center;
}

.sectionWrap .textInfo.grey {
  color: rgba(0, 0, 0, 0.4) !important;
}

.sectionWrap .textInfo.red {
  color: var(--watermelon);
}

.sectionWrap .textInfo.emp {
  font-weight: 600;
  font-style: italic;
}

.ToastContainer {
  // padding: 8px 16px;
  width: auto !important;
  font-size: 14px;
}

.Toastify__toast {
  margin-bottom: 8px;
  padding: 17.5px 44px 17.5px 60px;
  min-height: 0;
  max-width: 500px;
  border-radius: 8px;
  font-size: 14px;
  color: #191919;
  word-break: break-word;
  box-shadow: none;
}

.ToastContainer .ToastContent-no-icon {
  padding: 3px 16px;
  background: #191919;
  color: #fff !important;
  border-radius: 8px !important;
  width: auto !important;
}

.Toastify__toast-body {
  margin: 0;
  padding: 0;
  // padding: 8px 0;
}

.ToastContainer .toast-icon-wrapper {
  position: absolute;
  top: 12px;
  left: 20px;
  padding: 6px;
  width: 32px;
  height: 32px;
  border-radius: 16px;

  svg {
    padding: 4px;
    width: 20px;
    height: 20px;
    border-radius: 10px;
    color: white;
    overflow: visible;
    transform: translate(0, -1px);
  }
}

.ToastContainer .close-icon {
  position: absolute;
  top: 20px;
  right: 16px;
  padding: 0;
  width: 16px;
  height: 16px;
  color: #191919;
}

.Toastify__close-button--default {
  display: none;
  width: 0;
}

// .ToastContainer .ToastContent {
//   /* background: #7EC82A !important; */
//   background: #191919;
//   color: #fff !important;
//   border-radius: 8px !important;
//   width: auto !important;
// }

.ToastContainer .ToastContent {
  background: #ECFFE9;
  border: solid 1px #118300;

  .toast-icon-wrapper {
    background: #11830033;

    svg {
      background: #118300;
    }
  }
}

// .ToastContainer .ToastContent-fail {
//   background: #ff3f57 !important;
//   color: #fff !important;
//   border-radius: 8px !important;
//   // padding: 3px 16px !important;
//   width: auto !important;
// }

.ToastContainer .ToastContent-fail, .ToastContent-error {
  background: #FFECEE;
  border: solid 1px #FF3F57;

  .toast-icon-wrapper {
    background: #FF3F5733;

    svg {
      background: #FF3F57;
    }
  }
}

.ToastContainer .ToastContent-info {
  background: #EBF7FF;
  border: solid 1px #0C5F99;

  .toast-icon-wrapper {
    background: #0C5F9933;

    svg {
      background: #0C5F99;
    }
  }
}
.ToastContainer .ToastContent-warning {
  background: #FFF4B1;
  border: solid 1px #FF7E00;

  .toast-icon-wrapper {
    background: #FF7E0033;

    svg {
      background: #FF7E00;
    }
  }
}

.ToastContainer .ToastContent button, .ToastContainer .ToastContent-fail button, .ToastContainer .ToastContent-no-icon button {
  display: none;
}

.ToastContainer .ToastContent .Toastify__progress-bar {
  background: #fff !important;
}

.Modal .modal-content {
  position: relative;
  padding: 32px;
}

.Modal .popup-header {
  font-size: 20px;
  font-family: PTRootBold;
  padding-bottom: 16px;
  margin-bottom: 32px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.Modal .popup-body {
  position: relative;
}

.Modal .popup-body .errorInfo {
  color: var(--watermelon);
  font-style: italic;
  font-size: 14px;
  margin: 8px;
}

.Modal .popup-desc {
  color: rgba(0, 0, 0, 0.6);
  letter-spacing: -0.2px;
  line-height: 1.5;
  font-size: 14px;
}

.Modal .button-flex {
  display: flex;
  justify-content: space-between;
  margin-top: 32px;
}

.Modal .button-flex button {
  display: inline-block;
  flex: 1;
}

.inputCopy {
  position: relative;
}

.inputCopy .iconCopy {
  appearance: none;
  -webkit-appearance: none;
  border: none;
  background: #fff;
  position: absolute;
  top: 5px;
  right: 6px;
  /* transform: translate(0, -50%); */
  width: 40px;
  height: 40px;
  color: rgba(0, 0, 0, 0.4);
  font-size: 20px;
  text-align: center;
  cursor: pointer;
}

.inputCopy input {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 13px 40px 15px 15px;
}

.fontBold {
  font-family: PTRootBold;
}