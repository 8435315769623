.billing-notification-add-modal {
    // position: fixed;
    // top: 0;
    // left: 0;
    // width: 100% !important;
    // height: 100%;
    // background: rgba(25, 25, 25, 0.8);
    // visibility: hidden;
    // opacity: 0;
    // right: 0;
    // transition: visibility .3s, opacity 0.5s ease-in-out;
    // z-index: 9999;

    // &.active-billing-notification-add-modal {
    //     opacity: 1;
    //     visibility: visible;
    //     transition: visibility 0s, opacity 0.5s ease-in-out; 
    // } 
    position: fixed !important;
    left: unset;
    right: 0;
    transform: unset;
    top: 0;
    border-radius: 8px 0 0 0;
    height: 100%;

    .modal-content {
        width: 450px;
        border-radius: 8px;
        // padding: 24px 32px;
        position: fixed;
        left: unset;
        right: 0;
        transform: unset;
        top: 0;
        border-radius: 8px 0 0 0;
        height: 100%;

        .modal-body {
            position: relative;
            padding: 0;
            
            .modal-title {
                font-size: 24px;
                letter-spacing: -0.6px;
                margin: 0 0 30px 0;
                padding: 24px 32px 0 32px;

                span {
                    font-size: 16px;
                    letter-spacing: -0.3px;
                    color: rgba(25, 25, 25, 0.6);
                }
            }

            .email-input-wrapper {
                position: relative;
                display: block;
                width: 100%;
                padding: 0 32px 0;
                max-height: calc(100% - 180px);
                overflow: auto;

                .add-more-button {
                    display: inline-block;
                    font-size: 12px;
                    letter-spacing: .2px;
                    cursor: pointer;
                    padding: 0 10px;
                    margin: 12px 0 0 4px;
                    border-radius: 4px;
                }

                .email-input-box {
                    position: relative;
                    width: 100%;
                    display: block;

                    &:nth-child(n + 2) {
                        margin-top: 12px;
                    }

                    .red-text {
                        margin: 0;

                        b {
                            font-size: 12px;
                            letter-spacing: -0.4px;
                            line-height: 1;
                            color: #ff3f57 !important;
                        }
            
                        svg {
                            position: relative;
                            top: -1px;
                        }
                    }

                    input {
                        border: solid 1px #dcdcdc;
                        border-radius: 8px;
                        padding: 11px 12px 11px 44px;
                        font-size: 16px;
                        letter-spacing: -0.3px;
                        width: calc(100% - 30px);
                        // margin-bottom: 12px;

                        &::placeholder {
                            color: rgba(25, 25, 25, 0.4);
                        }
                    }

                    .mail-icon {
                        color: rgba(123, 135, 148, 1);
                        position: absolute;
                        left: 12px;
                        top: 14px;
                        font-size: 20px;
                    }

                    .trash-icon {
                        color: rgba(255, 63, 87, 1);
                        position: absolute;
                        right: 0;
                        top: 14px;
                        font-size: 20px;
                        cursor: pointer;
                    }
                }
            }

            .add-recipient-button-wrapper {
                width: 100%;
                position: absolute;
                bottom: 0;
                left: 0;
                padding: 24px 32px;
                
                button {
                    width: calc(50% - 4px);
                    margin: 0 8px 0 0;
                    display: inline-block;

                    &:nth-last-child(1) {
                        margin: 0;
                    }
                }
            }
        }
    }
}

.modal-add-email-notification {
    button {
        width: calc(50% - 4px) !important;
        margin-right: 8px !important;

        &:nth-last-child(1) {
            margin: 0 !important;
        }
    }
}

.billing-notification-max-limit {
    .modal-content {
        width: 450px;
        border-radius: 8px;
        padding: 32px 32px;

        .modal-body {
            padding: 0;

            .modal-title {
                font-size: 20px;
                letter-spacing: -0.6px;
                margin: 0 0 4px 0;
            }

            .modal-desc {
                font-size: 14px;
                letter-spacing: -0.2px;
                line-height: 21px;
                margin: 0 0 20px 0;
                color: rgba(25, 25, 25, 0.6);
            }

            button {
                width: 100%;

            }
        }
    }
}