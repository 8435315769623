.quick-reply-main-wrapper {
    .btn {
        border: 0;
    }

    tbody {
        tr:hover {
            td {
                background: rgba(255, 242, 229, 1) !important;
            }
        }
    }
}

.quick-reply-list-title {
    color: #191919;
    font-size: 20px;
    letter-spacing: -0.6px;
    line-height: 24px;
    padding-bottom: 16px;
    border-bottom: solid 1px rgba(25, 25, 25, 0.1);
}

.quick-reply-list-tool-wrapper {
    width: 100%;
    position: relative;
    margin-top: 24px;
    margin-bottom: 32px;
}

.quick-reply-list-info {
    font-size: 16px;
    letter-spacing: -0.3px;
    color: rgba(25, 25, 25, 0.8);
    line-height: 1.5;
    padding: 0 208px 18px 0;
}

.quick-reply-list-tool-wrapper button.button-invite-member {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(0, -50%);
    padding: 11px 0;
    width: 200px;
}

.quick-reply-list-tool-wrapper button.button-invite-member svg {
    position: relative;
    top: -1px;
    margin-right: 5px;
}

.quick-reply-list-table-wrapper {
    width: 100%;
    position: relative;
}

.table-quick-reply-list thead tr {
    background: #ffffff;
    // border-top: solid 1px #dcdcdc;
}

.table-quick-reply-list tbody td {
    background: #ffffff;
    position: relative;

    &.no-hover {
        cursor: default !important;
        &:hover {
          background-color: #ffffff !important;
    
          td {
            color: #191919 !important;
          }
        } 
    }
}

.table-quick-reply-list button svg {
    position: relative !important;
    right: unset !important;
    top: -2px !important;
    margin-right: 5px;
}

.table-quick-reply-list .col-action-button-wrapper {
    position: absolute;
    right: 7px;
    top: 50%;
    transform: translate(0, -50%);
    /* display: none; */
}

.table-quick-reply-list .single-remove-button {
    margin-left: 16px;
    color: #ff3f57;
}

.table-quick-reply-list .single-remove-button:hover {
    color: #e83b51;
}

.table-quick-reply-list .shortcut-badge {
    font-size: 12px;
    color: rgba(25, 25, 25, 0.6);
    letter-spacing: -0.4px;
    background: #f3f3f3;
    line-height: 1;
    border-radius: 4px;
    padding: 1px 8px 3px;
}

.table-quick-reply-list .col_type {
    white-space: nowrap;
    
    img {
        position: relative;
        top: -1px;
        margin-right: 4px;
        right: unset !important;
        width: 16px;

        &.img-waba {
            width: 18px;
        }
    }

    svg {
        color: rgba(25, 25, 25, 0.4);
        position: relative;
        top: -1px;
        margin: 0 3px 0 0;
        font-size: 16px;
        right: unset !important;
    }
}

.table-quick-reply-list td {
    // max-width: unset !important;
    word-break: break-word;
}

.table-quick-reply-list .col-action-button-wrapper {
    display: block !important;
    position: relative;
    top: 0;
    right: unset;
    transform: unset;
}

.table-quick-reply-list .col-action-button-wrapper .view-agent {
    color: #ff7d00;
    letter-spacing: -0.5px;
}

.table-quick-reply-list .col-action-button-wrapper .view-agent:hover {
    color: var(--main-darker-orange);
}

.table-quick-reply-list .col-action-button-wrapper img.view-agent {
    margin-right: 3px;
    position: relative;
    top: -1px;
}

.member-tool-wrapper {
    width: 460px;
}

.table-quick-reply-list {
    .col_action {
        width: 200px;
        text-align: right;
        white-space: nowrap;
    }
}

.quick-reply-page-wrapper {
    margin: 32px 0 0;

    .nav-item {
        cursor: pointer;
        color: rgba(25, 25, 25, 0.4);
        font-size: 16px;
        letter-spacing: -0.5px;
        padding: 0 0 12px 0;
        margin-right: 32px;

        a {
            padding: 0;
            border: none;
        }

        :hover {
            color: #ff7d00;
        }

        &.active-setup-tab {
            color: #ff7d00;
            border-bottom: solid 4px #ff7d00;
        }
    }

    .tab-content {
        margin: 32px 0 0;
    }
}

.modal-add-quick-reply {
    .modal-content {
        width: 612px;
        
        .modal-body {
            position: relative;
            padding: 32px;

            .close-modal-quick-reply {
                position: absolute;
                right: 32px;
                top: 35px;
                font-size: 24px;
                color: #191919;
                cursor: pointer;
            }

            .add-quick-reply-title {
                font-size: 20px;
                letter-spacing: -0.6px;
                margin: 0 0 20px 0;
                display: block;
            }

            label {
                font-size: 16px;
                letter-spacing: -0.5px;
                margin: 0 0 8px 0;
                display: block;

                &.button-type {
                    margin: 12px 0 8px;
                }
            }

            img {
                width: 28px;
                height: 28px;
            }

            button {
                width: 100%;
                margin: 20px 0 0;
            }

            .add-quick-reply-selection {
                border: solid 1px #dcdcdc;
                cursor: pointer;
                font-size: 14px;
                letter-spacing: -0.2px;
                color: #191919;
                border-radius: 8px;
                width: calc(50% - 4px);
                margin-right: 8px;
                display: inline-block;
                text-align: left;
                padding: 10px;
                margin-bottom: 8px;
                text-align: center;

                &.no-margin-right {
                    margin-right: 0;
                }

                img {
                    position: relative;
                    top: 0px;
                    margin-right: 4px;
                }

                svg {
                    position: relative;
                    top: -1px;
                    margin-right: 4px;
                    color: rgba(25, 25, 25, 0.4);
                }

                &.active-add-quick-reply-selection {
                    color: #ff7d00;
                    border: solid 1px #ff7d00;

                    svg {
                        color: #ff7d00;
                    }
                }
            }

            .add-quick-reply-selection-button-type {
                border: solid 1px #dcdcdc;
                cursor: pointer;
                font-size: 14px;
                letter-spacing: -0.2px;
                color: #191919;
                border-radius: 8px;
                width: calc(50% - 4px);
                margin-right: 8px;
                display: inline-block;
                text-align: left;
                padding: 10px;
                margin-bottom: 8px;
                text-align: center;
                height: 49px;
                vertical-align: top;
                line-height: 27px;

                &.no-margin-right {
                    margin-right: 0;
                }

                img {
                    position: relative;
                    top: -1px;
                    margin-right: 4px;
                    width: 18px;
                }

                svg {
                    position: relative;
                    top: -1px;
                    margin-right: 4px;
                    color: rgba(25, 25, 25, 0.4);

                    &.orange-button-type {
                        color: var(--main-dark-orange);
                    }
                }

                &.active-add-quick-reply-selection-button-type {
                    color: #ff7d00;
                    border: solid 1px #ff7d00;

                    svg {
                        color: #ff7d00;
                    }
                }
            }
        }
    }
}