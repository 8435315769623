.modal-confirmation-activation {
  width: 520px !important;

  .modal-content {
    width: 520px !important;
  }

  button {
    width: calc(50% - 2px) !important;
    margin-right: 4px !important;

    &:nth-last-child(1) {
      margin-right: 0 !important;
    }
  }
}

.integration-detail-account {
  padding: 32px 30px;
  text-align: left;

  .alert-component {
    margin: 0 0 24px 0;
  }

  .copy-box-wrapper {
    width: 400px;
    margin-top: 4px;

    input {
      background: #f3f3f3;
      color: rgba(25, 25, 25, 0.6);
      width: 100%;
    }

    svg {
      color: var(--main-dark-orange);
    }
  }

  .topic-val {
    max-width: 420px;
    color: #191919 !important; 
    margin: 6px 0 24px 0 !important;
    font-size: 16px !important;
  }

  .comp-back-button {
    margin: 0 0 16px 0;
  }

  .detail-value-wrapper {
    color: #191919 !important;
    font-size: 16px !important;
    letter-spacing: -0.3px !important;
    margin: 0 0 24px 0 !important;
  }

  .detail-reason-wrapper {
    color: #191919 !important;
    font-size: 14px !important;
    letter-spacing: -0.2px !important;
    margin: 0 0 24px 0 !important;
    max-width: 100%;
    width: 400px;
  }

  .detail-status-wrapper {
    margin: 0 0 24px 0;
    font-size: 16px;
    letter-spacing: -0.5px;

    img {
      position: relative;
      top: -2px;
      margin: 0 4px 0 0;
    }
  }

  .account-photo-wrapper {
    background: rgba(25, 25, 25, 0.1);
    position: relative;
    border-radius: 4px;
    width: 96px;
    height: 96px;
    margin: 8px 0 24px 0;
    overflow: hidden;

    svg {
      font-size: 24px;
      color: rgba(25, 25, 25, 0.6);
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    img {
      max-width: 100%;
      max-height: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .integration-detail-loading-wrapper {
    // .lds-ring {
    //   width: 50px;
    //   height: 50px;
    //   position: absolute;
    //   left: 50%;
    //   top: 50%;
    //   transform: translate(-50%, -50%);
    //   right: unset;

    //   div {
    //     border: 5px solid #fff;
    //     border-color: #ff7d00 transparent transparent transparent;
    //     width: 50px;
    //     height: 50px;
    //     margin: 0;
    //   }
    // }
  }

  .integration-detail-account-box-1 {
    background: #ffffff;
    border-radius: 8px;
    padding: 22px 32px 32px;
    border: solid 1px rgba(25, 25, 25, 0.1);
    position: relative;

    &.box-channel-button {
      margin-top: 24px;
    }

    .whatsapp-detail-channel-button {
      .channel-button-wrapper {
        margin-top: 24px;
        width: 300px;
        word-break: break-word;
      }
    }

    .status-wrapper {
      .status-value {
        color:rgba(25, 25, 25, 0.4);

        &.status-enabled {
          color: #19C700;
        }
      }

      img {
        position: relative;
        top: -1px;
        margin-right: 4px;
      }
    }

    .integration-detail-account-box-1-top {
      padding: 10px 0 15px;
      border-bottom: solid 1px #dcdcdc;
      position: relative;
      font-size: 20px;
      letter-spacing: -0.6px;
      padding-right: 310px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      .bot-name {
        margin-bottom: 0;
      }

      p {
        font-size: 14px;
        letter-spacing: -0.2px;
      }

      .integration-detail-account-box-1-top-button {
        position: absolute;
        top: 0px;
        right: 0;
        display: inline-block;

        button {
          padding: 7px 24px;
          font-size: 16px;
          letter-spacing: -0.6px;
          height: 40px;
          margin-left: 8px;
          
          &.button-channel-detail {
            position: relative;
            top: 15px;
          }

          svg {
            position: relative;
            top: -2px;
            margin-right: 4px;
          }

          .lds-ring {
            top: unset;
            right: unset;
            position: relative;
            top: 3px;

            div {
              margin: 0;
              border-color: #ffffff transparent transparent transparent;
            }
          }
        }
      }
    }

    .integration-detail-account-box-1-bottom {
      padding-top: 24px;
      position: relative;
      font-size: 20px;
      letter-spacing: -0.6px;

      .disabled-form {
        margin: 0 0 24px 0;
      }

      label {
        font-size: 16px;
        letter-spacing: -0.5px;
        margin: 0;
      }

      p {
        color: #ff8d00;
        margin: 4px 0 0;
        font-size: 15px;
        letter-spacing: -0.5px;

        svg {
          margin-right: 4px;
          position: relative;
          top: -2px;
          font-size: 20px;
        }
      }

      .integration-detail-account-box-1-bottom-button {
        display: block;
        // margin-top: 16px;

        button {
          padding: 7px 24px 7px 20px;
          font-size: 16px;
          letter-spacing: -0.6px;
          height: 40px;
          margin-right: 8px;
          margin-top: 16px;

          svg {
            position: relative;
            top: -2px;
            margin-right: 4px;
          }

          img {
            position: relative;
            top: -2px;
            margin-right: 4px;
          }

          .lds-ring {
            top: unset;
            right: unset;
            position: relative;
            top: 3px;

            div {
              margin: 0;
              border-color: #ffffff transparent transparent transparent;
            }
          }
        }
      }

      .integration-detail-account-box-1-top-authenticated {
        color: #19c700;

        img {
          position: relative;
          top: -2px;
          margin-right: 4px;
        }
      }

      .iframe-space {
        width: 100%;
        margin-top: 24px;

        iframe {
          width: 100%;
          border: none;
          min-height: 500px;
        }
      }

      .iframe-space-maytapi {
        position: relative;
        width: 100%;
        border-radius: 8px;
        border: solid 1px #dcdcdc;
        min-height: 348px;
        overflow: hidden;
        text-align: right;
        margin-top: 24px;

        button {
          margin: 8px 8px 8px 0;

          .lds-ring {
            top: 50%;
          }

          svg {
            top: -1.5px;
          }
        }

        .iframe-space-maytapi-content {
          width: 100%;
          min-height: 278px;
          background: rgba(25, 25, 25, 0.1);
          font-size: 16px;
          letter-spacing: -0.5px;
          text-align: center;
          position: relative;

          img {
            width: 100%;
          }
          
          p {
            color: rgba(25, 25, 25, 0.4); 
            padding: 94px 0 0;
            margin: 0;
          }

          .loading-maytapi {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 100%;

            .lds-ring {
              width: 50px;
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              height: 50px;
  
              div {
                width: 50px;
                height: 50px;
                border: solid 4px;
                border-color: #ff7e00 transparent transparent transparent;
              }
            }
          }
        }
      }

      .not-paid {
        font-size: 16px;
        letter-spacing: -0.5px;
        color: #ff3f57;
        margin: 0;

        img {
          vertical-align: top;
          position: relative;
          margin-right: 4px;
          top: 3px;
        }
      }

      .waiting-setup {
        color: #ff7d00;
        font-size: 16px;
        letter-spacing: -0.5px;
        margin: 0;
      }

      .error-message {
        font-size: 14px;
        letter-spacing: -0.2px;
        margin: 4px 0 0;
        display: block;
      }
    }
  }

  .integration-detail-account-box-2 {
    background: #ffffff;
    border-radius: 8px;
    padding: 32px;
    border: solid 1px rgba(25, 25, 25, 0.1);
    margin-top: 12px;

    .integration-detail-account-box-2-title {
      font-size: 20px;
      letter-spacing: -0.6px;
      padding-bottom: 20px;
      border-bottom: solid 1px #dcdcdc;
      display: block;
      margin: 0 0 24px;
    }

    .integration-detail-account-box-2-payment-wrapper {
      width: 100%;
      position: relative;

      label {
        font-size: 14px;
        letter-spacing: -0.4px;
        margin: 0;
      }

      .payment-info-wrapper {
        position: relative;

        .payment-until {
          font-size: 15px;
          letter-spacing: -0.5px;
          color: #19c700;
          margin: 0;

          img {
            vertical-align: top;
            position: relative;
            top: 0;
            margin-right: 4px;
          }
        }

        .payment-expired {
          color: #ff3f57;
          font-size: 15px;
          letter-spacing: -0.5px;
          margin: 4px 0 0;

          svg {
            vertical-align: top;
            position: relative;
            font-size: 20px;
            margin-right: 4px;
          }
        }

        .payment-bar {
          width: 326px;
          height: 4px;
          background: rgba(25, 25, 25, 0.1);
          border-radius: 2px;
          margin: 8px 0;
          position: relative;

          div {
            position: absolute;
            left: 0;
            top: 0;
            background: #3baeff;
            height: 4px;
          }
        }

        .payment-left {
          font-size: 12px;
          letter-spacing: -0.2px;
          color: rgba(25, 25, 25, 0.4);
        }

        .not-paid {
          color: rgba(25, 25, 25, 0.4);
          font-size: 16px;
          letter-spacing: -0.5px;
          margin: 0 0 22px;
        }
      }

      .alet-verifying {
        border-radius: 8px;
        color: #044b84;
        border: solid 1px #044b84;
        background: #dcebff;
        // width: 326px;
        width: 100%;
        position: relative;
        padding: 12px 16px 12px 38px;
        font-size: 14px;
        letter-spacing: -0.4px;
        width: 323px;

        svg {
          font-size: 15px;
          transform: none;
          top: 15px
        }
      }

      .how-to-make-payment {
        border-radius: 8px;
        color: #044b84;
        border: solid 1px #044b84;
        background: #dcebff;
        // width: 326px;
        width: 100%;
        position: relative;
        padding: 32px;

        .how-to-make-payment-head {
          line-height: 1;
          margin-bottom: 24px;

          svg {
            // position: absolute;
            // top: 12px;
            // left: 12px;
            vertical-align: top;
            font-size: 24px;
            margin-right: 8px;
            position: relative;
            top: -2px;
          }

          b {
            letter-spacing: -0.6px;
            font-size: 20px;
          }
        }

        .add-on-price {
          font-size: 16px;
          letter-spacing: -0.5px;
          line-height: 1px;
          margin: 0 0 16px 0;
        }

        .price-tier {
          padding-right: 81px;

          .price-tier-card {
            background: #044b84;
            box-shadow: 0 2px 20px 0 rgba(4, 75, 132, 0.18);
            color: #ffffff;
            border-radius: 8px;
            margin: 10px 12px 24px 0;
            padding: 24px 16px 9px 16px;
            vertical-align: top;
            width: calc((100% / 3) - 8px);
            display: inline-block;

            &:nth-last-child(1) {
              margin: 10px 0 24px 0;
            }

            .price-tier-name {
              font-size: 14px;
              letter-spacing: -0.4px;
              display: inline-block;
              border-radius: 4px;
              line-height: 22px;
              height: 22px;
              padding: 0 8px;
              background: #ffffff;
              color: #044b84;
            }

            .price-tier-value {
              display: block;
              margin: 12px 0 8px;
              font-size: 20px;
              letter-spacing: -0.6px;
            }

            .price-tier-more-detail {
              color: #ffffff;
              font: 16px;
              letter-spacing: -0.5px;
            }

            svg {
              color: #ffffff;
              font-size: 24px;
              position: relative;
              top: -2px;
            }
          }
        }

        .payment-step-wrapper {
          width: 100%;

          .payment-step-list {
            position: relative;
            margin: 0 0 24px;
            color: #044b84;
            padding: 0 0 0 33px;

            &:nth-last-child(1) {
              margin: 0;
            }

            a {
              color: #044b84 !important;
            }

            p {
              margin: 0;
            }

            .bank-account-wrapper {
              border-radius: 8px;
              font-size: 16px;
              letter-spacing: -0.5px;
              padding: 24px 24px 16px;
              color: #044b84;
              background: #ffffff;
              display: inline-block;
              margin-top: 14px;
            }

            .payment-list-icon {
              background: #ffffff;
              font-size: 12px;
              letter-spacing: -0.4px;
              border-radius: 4px;
              padding: 2px 8px;
              color: #ff7d00;

              &.authenticated {
                color: #19c700;
              }

              svg {
                position: relative;
                top: -2px;
              }

              img {
                width: 12px;
                position: relative;
                top: -1px;
              }
            }
          }

          .payment-step-counter {
            border-radius: 50%;
            color: #ffffff;
            background: #044b84;
            width: 23px;
            height: 23px;
            text-align: center;
            line-height: 23px;
            display: inline-block;
            font-size: 14px;
            letter-spacing: -0.4px;
            position: absolute;
            top: 0;
            left: 0;
          }
        }
      }
    }
  }
}

.modal-secure-authentication {
  .modal-content {
    border-radius: 8px;
    width: 580px;
  }

  .modal-body {
    padding: 32px 32px 24px;

    .modal-secure-authentication-top {
      padding: 0 0 5px 32px;
      position: relative;

      b {
        font-size: 20px;
        letter-spacing: -0.6px;
        line-height: 20px;
        color: #191919;
      }

      span {
        font-size: 14px;
        letter-spacing: -0.2px;
        line-height: 21px;
        color: rgba(25, 25, 25, 0.6);
      }

      svg {
        position: absolute;
        left: 0;
        top: -1px;
        font-size: 24px;
      }
    }

    button {
      margin: 0 8px 0 0;
      width: calc(50% - 4px);
      
      &:nth-last-child(1) {
        margin: 0;
      }
    }
  }
}
