.account-profile-whatsapp {
    position: relative;
    width: 100%; 

    .account-profile-header {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid rgba(25, 25, 25, 0.1);
        margin-bottom: 24px;
        padding-bottom: 12px;

        .title-wrapper {
            b {
                font-size: 20px;
            }

            p {
                font-size: 16px;
                margin: 0;
            }
        }
    }

    .account-profile-content {
        .custom-select-phonenumber {
            width: 340px;
            max-width: 100%;
        }

        .account-profile-content-wrapper {
            border-top: solid 1px #dcdcdc;
            margin-top: 12px;
            padding-top: 24px;
            margin-bottom: 24px;
            position: relative;

            &:last-child {
                margin-bottom: 0;
            }

            .label-wrapper {
                display: flex;
                align-items: center;
                margin-bottom: 8px;

                svg {
                    margin-right: 4px;
                    position: relative;
                    top: -1px;
                }

                label {
                    margin: 0;
                    font-size: 14px;
                }
            }

            .photo-wrapper {
                margin-bottom: 8px;
                border: 1px solid rgba(25, 25, 25, 0.1);
                border-radius: 4px;
                width: 96px;
                height: 96px;

                img {
                    border-radius: 4px;
                    width: 96px;
                    height: 96px;
                }

                .profile-photo-empty {
                    height: 94px;
                    background: rgba(25, 25, 25, 0.05);
                    position: relative;
        
                    svg {
                        height: 32px;
                        width: 32px;
                        color: #9c9c9c;
                        position: absolute;
                        left: 50%;
                        top: 50%;
                        transform: translate(-50%, -50%);
                    }
                }
            }

            .info-wrapper {
                display: flex;
                // align-items: center;

                b {
                    font-size: 12px;
                }

                svg {
                    font-size: 16px;
                    margin-right: 4px;
                }
            }

            .text-wrapper {
                p {
                    margin: 0;
                }
            }
            .button-wrapper {
                position: absolute;
                right: 0;
                top: 50%;
                transform: translateY(-50%);
                > div {
                    display: flex;
                    label {
                        margin-right: 8px;
                    }
                }

                .LoadingRoll_1234fed {
                    vertical-align: 6px;
                    margin: 0 15px 0 auto !important;
                    height: unset !important;
                }
                label {
                    width: fit-content;
                    cursor: pointer;
                    padding: 0 24px 0 16px !important;
                    input {
                        width: 100%;
                    }

                    div {
                        display: inline-block;
                    }

                    &.disabled {
                        color: rgba(25, 25, 25, 0.4) !important;
                        border: solid 1px #E8E8E8 !important;
                        background: rgba(25, 25, 25, 0.1) !important;
                        cursor: not-allowed;
                        box-shadow: none !important;

                        input {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}

.modal-remove-photo {
    .modal-content {
        border-radius: 8px;
        padding: 32px;
        width: 480px;
    }

    .modal-body {
        padding: 0;
        line-height: 1;

        p {
            margin: 0;
        }

        .modal-remove-photo-title {
            font-size: 20px;
            letter-spacing: -0.6px;
        }

        input {
            width: 100%;
        }
    }

    .modal-footer {
        padding: 24px 0 0;
        border: 0;

        button {
            width: calc(50% - 4px);
            margin: 0 8px 0 0;
            height: 48px;

            &:nth-last-child(1) {
                margin: 0;
            }
        }
    }
}