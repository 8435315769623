.form-requester-info {
    position: relative;
    width: 100%;

    .submit-button {
        width: unset;
        margin-top: 26px;
        margin-right: 8px;
    }

    input {
        width: 300px !important;
    }

    textarea {
        width: 300px !important;
    }

    .area-code-wrapper {
        min-width: 72px;
        margin: 0;
        border-radius: 8px;
        background-color: unset;
        border-radius: 8px;

        .dropdown-country-code-picker {
            background-color: unset !important;

            button {
                border: none !important;
                border-radius: 8px;
                background-color: unset !important;
            }

            .dropdown-menu {
                width: 300px;
            }

            .custom-phone-code-picker {
                div {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    max-width: 200px;
                    white-space: nowrap;
                }
            }
        }
    }

    .phone-input {
        width: 215px !important;
        border: none !important;
        padding-left: 0 !important;
        margin: 0 !important;
        border-radius: 8px;
    }

    .phone-input-wrapper {
        border: solid 1px rgba(25, 25, 25, 0.1);
        border-radius: 8px;

        &.phone-input-disabled {
            cursor: not-allowed;
            background-color: rgba(25, 25, 25, 0.1);
        }
    }
}