.my-options {
    cursor: pointer;
    font-size: 16px;
    white-space: nowrap;
    max-width: 100%;
    text-overflow: ellipsis;
    overflow: auto;
    overflow-x: hidden;
    width: 100%;
    color: #191919;
    padding: 10px 16px 10px 35px !important;
    position: relative;
    z-index: 999;

    p {
        max-width: calc(100% - 100px);
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }

    &:hover {
        background: #ffe7d0;
    }

    .option-badge {
        width: 94px;
        display: inline-block;
        font-size: 12px;
        letter-spacing: -0.4px;
        position: absolute;
        right: 16px;
        background: red;
        color: #fff;
        border-radius: 4px;
        text-align: center;
        top: 50%;
        transform: translate(0, -50%);
        z-index: 999;
    }

    .option-authenticated {
        background: #19c700;
    }

    .option-got_qr_code {
        background: #0c5f99;
    }

    .option-active {
        background: #19c700;
    }

    .option-loading {
        background: #f4c12a;
    }

    .option-error {
        background: #e02e2e;
    }

    .option-waiting-setup {
        background: var(--main-dark-orange);
    }

    .option-not_paid {
        background: rgba(25, 25, 25, 0.4);
    }

    p {
        display: inline-block;
        margin: 0;
        position: relative;
        top: -1px;
    }
}

.my-options-normal {
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 10px 16px 10px 16px !important;
    cursor: pointer;

    &:hover {
        background: #ffe7d0;
    }
}

.custom-react-select__input {
    input {
        height: unset !important;
        margin-bottom: 0 !important;

        &[type="text"][disabled] {
            background: none !important;
            border: none !important;
        }
    }
}