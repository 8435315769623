.chat-room-sales-talk-product-modal {
    width: 340px;
    display: inline-block;
    transition: ease-in-out .2s;
    position: absolute;
    top: 0;
    right: -340px;
    background: #ffffff;
    vertical-align: top;
    height: 100%;
    // box-shadow: -1px 0 0 0 rgba(25, 25, 25, 0.1);
    // border-left: solid 1px #dcdcdc;
    user-select: none;
    z-index: 1;

    &.chat-room-sales-talk-product-modal-active {
        right: 0;
    }

    .chat-room-sales-talk-product-modal-title {
        font-size: 14px;
        letter-spacing: -0.4px;
        color: #191919;
        height: 48px;
        width: 100%;
        display: block;
        border-bottom: solid 1px #dcdcdc;
        position: relative;
        text-align: center;
        line-height: 48px;
        // border-left: solid 1px #dcdcdc;
        // margin-bottom: 151px;

        svg {
            position: absolute;
            color: rgba(25, 25, 25, 1);
            cursor: pointer;
            top: 15px;
            right: 18px;

            &:hover {
                color: #ff7d00;
            }
        }

        &.chat-room-sales-talk-product-modal-title-no-border {
            border: none !important;
        }
    }

    .salestalk-error-server-wrapper {
        overflow: auto;
        height: calc(100% - 48px);
        padding: 40px 16px;
        text-align: center;
        position: relative;

        .salestalk-error-server-content {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            width: 100%;
            padding: 24px;

            .desc-2 {
                margin: 14px 0 44px;
                font-size: 16px;
                letter-spacing: -0.3px;
                color:rgba(25, 25, 25, .6);
            }
        }
    }

    .salestalk-not-connect-wrapper {
        overflow: auto;
        max-height: calc(100% - 48px);
        padding: 40px 16px;
        text-align: center;
        border-top: solid 1px #dcdcdc;

        h3 {
            color: rgba(0, 60, 102, 1);
            font-size: 24px;
            letter-spacing: -0.6px;
            line-height: 30px;
            margin: 0 0 12px;
        }

        .desc-1 {
            font-size: 16px;
            letter-spacing: -0.3px;
            color: rgba(0, 60, 102, 1);
            margin: 0 0 24px;
        }

        .desc-2 {
            font-size: 16px;
            letter-spacing: -0.3px;
            color: rgba(0, 60, 102, 1);
            margin: 24px 0;
        }

        img {
            width: 100%;
        }
    }
}