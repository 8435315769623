.broadcast-message-form {
    .preview-outer-wrapper {
        width: 610px;
        max-width: 100%;

        .preview-inner-wrapper {
            padding: 16px 24px;
            position: relative;
            border-radius: 8px;
            border: solid 1px #dcdcdc;
            text-align: center;
            margin: 24px 0 0;

            .import-csv-icon {
                width: 48px;
                display: inline-block;
                margin: 8px 0 0;
            }

            .import-csv-title {
                margin: 8px 0 0;
                font-size: 16px;
                letter-spacing: -0.5px;
            }

            .import-csv-desc {
                margin: 4px 0 8px;
                font-size: 14px;
                letter-spacing: -0.2px;
            }

            .lds-ring {
                width: 33px;
                height: 33px;
                top: unset;
                right: unset;

                div {
                    border: solid 4px #fff;
                    border-color: #ff7d00 transparent transparent transparent;
                    width: 33px;
                    height: 33px;
                    margin: 12px 0 0;
                }
            }

            .importing-csv-title {
                margin: 6px 0 8px;
                font-size: 16px;
                letter-spacing: -0.5px;
            }
        }

        .preview-inner-wrapper-2 {
            padding: 16px 24px;
            position: relative;
            border-radius: 8px;
            border: solid 1px #dcdcdc;
            text-align: left;
            margin: 24px 0 0;

            .csv-fileicon {
                position: absolute;
                left: 24px;
                top: 50%;
                transform: translate(0, -50%);
            }

            .csv-filename {
                font-size: 16px;
                letter-spacing: -0.5px;
                margin: 0;
                padding: 0 110px 0 40px;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            .preview-data-button {
                font-size: 14px;
                letter-spacing: -0.4px;
                color: #ff7d00;
                cursor: pointer;
                position: absolute;
                right: 24px;
                top: 50%;
                transform: translate(0, -50%);

                svg {
                    margin-right: 4px;
                    position: relative;
                    top: -1px;
                    right: 0;
                }

                &:hover {
                    color: var(--main-darker-orange);
                }
            }
        }
    }
    
    .total-recipient {
        color: #191919;
        font-size: 14px;
        letter-spacing: -0.4px;
        margin: 8px 0 0;
    }

    .contacts-info {
        b {
            color: rgba(25, 25, 25, 0.8);
            font-size: 12px;
            letter-spacing: -0.4px;
            margin: 8px 0 0;
        }

        svg {
            margin-right: 4px;
        }
    }

    .outer-button-wrapper {
        label { 
            margin: 0;
            vertical-align: top;
        }
        
        button {
            margin-right: 8px;
            margin-top: 0;
        }
    }
}

.modal-replace-data {
    .modal-content {
        border-radius: 8px;
        width: 500px;

        .modal-body {
            padding: 32px;
            border-radius: 8px;

            .modal-title {
                font-size: 20px;
                letter-spacing: -0.6px;
            }

            .modal-desc {
                margin: 4px 0 20px;
                color: rgba(25, 25, 25, 0.6);
                font-size: 14px;
                letter-spacing: -0.2px;
            }

            button {
                margin-right: 4px;
                width: calc(50% - 2px);

                &:nth-last-child(1) {
                    margin-right: 0;
                }
            }
        }
    }
}