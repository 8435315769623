.search-box-wrapper {
    position: relative;
    width: 343px;
    position: relative;
    display: inline-block;
  
    input {
      padding: 11px 76px 11px 16px;
      font-size: 16px;
      border: solid 1px #dcdcdc;
      border-radius: 8px;
      width: 100% !important;
      letter-spacing: -0.3px;
    }

    .icon-remove-search {
      position: absolute !important;
      cursor: pointer;
      right: 40px !important;
      top: 50% !important;
      transform: translate(0, -50%) !important;
    }
  }
  
  #search-icon {
    width: 38px;
    height: 48px;
    line-height: 1;
    position: absolute;
    right: 0;
    top: 0;
    display: inline-block;
    text-align: center;
  
    /* background: rgba(25, 25, 25, 0.1); */
    border-radius: 0 8px 8px 0;
  
    /* border-left: solid 1px #dcdcdc; */
  
    svg {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      color: rgba(25, 25, 25, 0.6);
    }
  }
  
  .search-box-wrapper input {
    &:focus {
      border: solid 1px var(--main-dark-orange);
  
      + #search-icon {
        /* border-left: solid 1px var(--main-dark-orange); */
  
        svg {
          /* color: var(--main-dark-orange) !important; */
        }
      }
    }
  
    &::placeholder {
      color: #9c9c9c;
    }
  }
  