.edit-business-profile-outer-wrapper {
  .edit-business-profile-whatsapp {
    position: relative;
    width: 100%; 
  
    .edit-business-profile-header {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid rgba(25, 25, 25, 0.1);
      margin-bottom: 24px;
      padding-bottom: 12px;

      .title-wrapper {
          b {
              font-size: 20px;
          }
      }
    }

    .edit-business-profile-content {
      .business-profile-content-wrapper {
        width: 425px;
        margin-bottom: 24px;

        &.website-wrapper {
          width: 500px;
        }

        .label-wrapper {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 8px;
            width: 425px;

            label {
                margin: 0;
                font-size: 14px;
            }

            p {
              margin: 0;
              font-size: 14px;
              color: rgba(25, 25, 25, 0.6);
            }
        }

        .edit-input-wrapper {
            margin-bottom: 8px;

            &.edit-another-website {
              display: flex;
              position: relative;
              width: calc(100% + 30px);

              svg {
                height: 22px;
                width: 20px;
                position: absolute;
                right: 0;
                top: 12px;
                color: red;
                cursor: pointer;
              }

              input {
                width: calc(100% - 30px);
              }
            }

            textarea {
              width: 100%;
              border: 1px solid rgba(25, 25, 25, 0.1);
              border-radius: 8px;
              padding: 12px 16px;
              resize: none;

              &:focus {
                border: 1px solid #FF7E00;
              }
            }

            input {
              width: 100%;
              border: 1px solid rgba(25, 25, 25, 0.1);
              border-radius: 8px;
              padding: 12px 16px;

              &:focus {
                border: 1px solid #FF7E00;
              }
            }

            .custom-select-option-value-wrapper {
              border: 1px solid rgba(25, 25, 25, 0.1) !important;
            }

            &.website {
              display: flex;

              input {
                width: 425px;
              }

              .delete-wrapper {
                position: relative;
                left: 20px;
                top: 12px;
                cursor: pointer;

                svg {
                  font-size: 24px;
                  color: #FF3F57;
                }
              }
            }
        }
      }

      .add-another-website-wrapper {
        display: flex;
        align-items: center;
        cursor: pointer;
        color: #FF7E00;

        svg {
          margin-right: 4px;
          position: relative;
          font-size: 20px;
        }

        b {
          font-size: 16px;
        }
      } 
    }
  }
}