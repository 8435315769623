.bar-verify-email {
    width: 100%;
    margin: 32px 0;
    padding: 16px 224px 16px 36px;
    border: solid 1px #ff7d00;
    color: #ff7d00;
    font-size: 16px;
    letter-spacing: -0.5px;
    border-radius: 8px;
    background: #FDF2B0;
    position: relative;
    box-shadow: 0px 2px 20px 0px rgba(25, 25, 25, 0.18);

    .bar-verify-email-icon {
        position: absolute;
        top: 20px;
        left: 16px;
    }

    .main-button-22 {
        padding: 0 16px 0 12px !important;
        position: absolute;
        top: 16px;
        right: 24px;
    }
}

.modal-confirm-resend-comp {
    .modal-content {
        width: 444px;
        border-radius: 32px;

        .modal-body {
            padding: 48px;
            border-radius: 32px;
            text-align: center;

            .modal-confirm-resend-comp-title {
                margin: 0;
                font-size: 24px;
                letter-spacing: -0.6px;
            }

            .modal-confirm-resend-comp-desc {
                font-size: 16px;
                letter-spacing: -0.3px;
                margin: 10px 0 0 0;
            }

            .main-button-40 {
                margin: 32px 0 0 0;
                width: 100%;
            }
        }
    }
}