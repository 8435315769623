#verification-wrapper {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(calc(-50% - 60px), calc(-50% - 60px));
    padding: 48px;
    margin: 60px;
    width: calc(100% - 120px);
    max-width: 540px;
    background: #ffffff;
    border-radius: 32px;
    box-shadow: 0px 3px 22px 0px #1919194D;
    text-align: center;
}

#verification-wrapper img {
    width: 154px;
    display: inline-block;
}

#verification-wrapper .verification-status {
    padding: 9.5px;
    margin: auto;
    width: 88px;
    height: 88px;
    background: #11830033;
    border-radius: 44px;
}

#verification-wrapper .verification-status svg {
    padding: 12.5px;
    width: 69px;
    height: 69px;
    background: #118300;
    color: #cfe6cc;
    border-radius: 35px;
}

#verification-wrapper .verification-title {
    margin: 10px 0 0;
    font-size: 24px;
    line-height: 30px;
    letter-spacing: -0.6px;
    text-align: center;
    color: #191919;
}

#verification-wrapper .verification-description {
    margin: 10px 0 0;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.3px;
    text-align: center;
    color: #191919;
}

/* @media screen and (max-width: 600px) {
    #verification-wrapper {
        width: 100%;
        border-radius: 0;
        padding: 40px 28px;
    }

    #verification-wrapper .verification-status {
        font-size: 20px !important;
    }

    #verification-wrapper .verification-status svg {
        top: -2px !important;
        width: 21px;
    }

    #verification-wrapper .login-button {
        padding: 11px 0;
        font-size: 15px;
    }
} */

#verification-wrapper .resend-email-wrapper {
    margin: 32px 0;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.5px;
    text-align: center;
    color: #19191999;
    margin-top: 15px;
}

#verification-wrapper .resend-email-wrapper .resend-button {
    margin: 0 0 0 4px;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.5px;
    color: var(--main-dark-orange);
    font-weight: bold;
    transition: ease-in-out 0.1s;
    cursor: pointer;
}

#verification-wrapper .resend-email-wrapper .resend-button:hover {
    transform: scale(0.975);
}

#verification-wrapper .resend-email-wrapper .timer-resend-button {
    margin: 0 0 0 4px;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.5px;
    color: #19191999;
    font-weight: bold;
}

#verification-wrapper .resend-email-wrapper .lds-ring div {
    width: 12px;
    height: 12px;
    margin: 10px 0 0 4px;
}
