.clock-tutorial-container {
    width: 100%;
    // height: calc(100vh - 56px);
    max-height: 100%;
    height: 100%;
    display: inline-block;
    /* background: pink; */
    text-align: center;
    padding-top: 32px;
    overflow: auto;
    background: #f3f3f3;

    .clock-tutorial-agent-company {
        max-width: 90%;
        width: 486px;
        display: inline-block;
        border-bottom: solid 1px #dcdcdc;

        p {
            margin: 0;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        .clock-tutorial-agent {
            margin: 48px 0 12px;
            font-size: 20px;
            letter-spacing: -.6px;
        }

        .clock-tutorial-company {
            font-size: 12px;
            margin: 0 0 12px;
            letter-spacing: -0.4px;
            color:rgba(25, 25, 25, 0.4);
        }
    }
}

.clock-tutorial-container .main-logo {
    width: 120px;
}

.clock-tutorial-container ul {
    padding: 0;
    margin: 0;
    list-style: none;
    display: inline-block;
}

.clock-tutorial-container ul li {
    padding: 0 20px 0 36px;
    max-width: 100%;
    font-size: 14px;
    // white-space: nowrap;
    text-align: left;
    position: relative;
}

.button-clock-tutorial-wrapper {
    text-align: center;

    li {
        position: relative;
        font-size: 14px;
        letter-spacing: -0.2px;
        color:rgba(25, 25, 25, 0.6);
        margin-bottom: 16px;
    }

    .clock-status {
        width: 8px;
        height: 8px;
        display: inline-block;
        vertical-align: top;
        margin-right: 8px;
        top: 6px;
        border-radius: 50%;
        position: absolute;
        left: 20px;
        top: 6px;

        &.online-status {
            background: #19C700;
        }

        &.away-status {
            background: #FFB438;
        }
    }

    .clock-separator {
        margin: 32px 0;
        width: 360px;
        display: inline-block;
        max-width: 90%;
        position: relative;
        height: 2px;
        background: rgba(25, 25, 25, 0.2); 

        p {
            background: #f3f3f3;
            position: absolute;
            margin: 0;
            padding: 0 10px;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-size: 14px;
            letter-spacing: -.4px;
            color: rgba(25, 25, 25, 1);
        }

    }

    a {
        font-size: 16px;
        letter-spacing: -.5px;
        color: var(--main-dark-orange);

        svg {
            font-size: 18px;
            position: relative;
            top: -2px;
            margin-right: 4px;
        }

        &:hover {
            color: var(--main-darker-orange);
        }
    }

    .main-button-clock-wrapper {
        margin-bottom: 32px;

        button {
            font-size: 16px;
            letter-spacing: -.5px;
            margin: 0 6px;
            font-family: PTRootBold;

            &.button-start-away {
                background: #FFB438;
                border: solid 1px #ffb438;
                border-radius: 8px;
                color: #ffffff;
            }
            
            &.button-go-online {
                background: #19C700;
                border: solid 1px #19c700;
                border-radius: 8px;
                color: #ffffff;
            }
        }
    }
}

.button-clock-tutorial-wrapper .tutorial-button-wrapper {
    display: inline-block;
    font-size: 10px;
    background: rgba(25, 25, 25, 0.05);
    border-radius: 4px;
    padding: 2px 7px;
    margin-right: 5px;
    color: rgba(25, 25, 25, 0.6);
    letter-spacing: 1.5px;
    margin-top: 12px;
}

.button-clock-tutorial-wrapper .tutorial-start svg {
    color: #19c700;
}

.button-clock-tutorial-wrapper .tutorial-start-as-away svg {
    color: #ffb438;
}

.button-clock-tutorial-wrapper .tutorial-button-wrapper svg {
    position: relative;
    top: -1px;
    font-size: 9px;
    margin-right: 3px;
}

.clock-tutorial-step {
    font-size: 24px;
    letter-spacing: -0.6px;
    width: 100%;
    // margin-bottom: 64px;
    margin-top: 24px;

    .clock-tutorial-you-offline {
       margin: 0 0 16px;
       font-size: 40px;
       letter-spacing: -0.6px;
       position: relative;
       display: inline-block;
       padding-left: 22px;
       color:rgba(25, 25, 25, 1);

       &::before {
           content: "";
           position: absolute;
           left: 0;
           top: 50%;
           transform: translate(0, -50%);
           width: 14px;
           height: 14px;
           border-radius: 50%;
           background: #ff3f57;
       }
    }

    p {
        margin: 0;
        color:rgba(25, 25, 25, 0.6);
        font-size: 16px;
        letter-spacing: -0.3px;
    }
}

.clock-tutorial-step ul li {
    font-size: 16px;
    letter-spacing: -0.3px;
    margin-top: 10px;
}

.clock-tutorial-step-badge {
    display: inline-block;
    border-radius: 50%;
    background: #0052ff;
    color: #ffffff;
    width: 21px;
    height: 21px;
    text-align: center;
    font-size: 13px;
    letter-spacing: -0.4px;
    line-height: 16px;
    margin-right: 12px;
    position: relative;
    top: -1px;
    box-shadow: 0 2px 20px 0 rgba(25, 25, 25, 0.18);
    border: solid 2px #ffffff;
}