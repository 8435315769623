  .assist-ai {
    padding: 0;

  iframe {
    border: 0;
    box-shadow: none;
    outline: none;
    width: 100%;
    height: 100%;
  }
}

.sectionContent {
  .assist-ai {
    height: calc(100vh - 48px);
  }

  &.section-wrap-active-billing-warning {
    .assist-ai {
      height: calc(100vh - 80px);
    }
  }
}

