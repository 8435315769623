.chat-room-main-wrapper {
	// max-height: calc(100vh - 120px);
	max-height: calc(100vh - 48px);
	overflow: auto;
	position: relative;
	width: 100%;
	height: 100%;
	transition: ease-in-out .2s;
	display: inline-block;

	.hide-message-info-status {
		.message-info {
			img {
				display: none;
			}
		}
	}

	// .forward-button {
		// display: none !important;
	// }

	.chat-room-main-wrapper-loading-blocking {
		position: absolute;
		background: #f3f3f3;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 1;
		border-top: solid 1px #dcdcdc;
		border-right: solid 1px #dcdcdc;

		.lds-ring {
			width: 32px;
			height: 32px;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);

			div {
				border: solid 3px #ffffff;
				border-color: #ff7d00 transparent transparent transparent;
				width: 32px;
				height: 32px;
			}
		}
	}

	.room-is-unavailable {
		padding: 4px 16px;
		border-radius: 8px;
		box-shadow: 0px 2px 20px 0px rgba(25, 25, 25, 0.05);
		display: inline-block;
		background: #ffffff;
		color: rgba(25, 25, 25, 0.4);
		font-size: 12px;
		letter-spacing: -0.4px;
		line-height: 18px;
		text-align: center;
		position: absolute;
		top: 40%;
		left: 50%;
		transform: translate(-50%, 0);
	}

	.chat-room-main-content {
		// padding: 0 24px 16px;
		padding: 0 0 16px;
		max-height: 100%;
		// overflow-y: hidden;
		overflow-y: auto;
		width: 100%;
		position: absolute;
		left: 0;
		bottom: 0;
		
		.loading-message-wrapper {
			padding-top: 16px;
			text-align: center;
			width: 100%;
			.lds-ring {
				top: unset;
				right: unset;
				div {
					margin: 0;
					border-color: #ff7d00 transparent transparent transparent;
				}
			}
		}
	}

	.message-bubble-blue {
		background: #EBF7FF !important;

		.link-preview-container {
			.link-preview-side-decoration-out {
				background: var(--main-dark-orange);
			}

			.link-preview-title-out {
				color: #191919;
			}

			.link-preview-description-out {
				color: #191919;
			}
		}

		.message-info {
			img {
				display: none;
			}
		}
	}

	.message-bubble-organization {
		background: #fff !important;
		border: solid 1px var(--main-dark-orange) !important;

		.link-preview-container {
			.link-preview-side-decoration-out {
				background: var(--main-dark-orange);
			}

			.link-preview-title-out {
				color: #191919;
			}

			.link-preview-description-out {
				color: #191919;
			}
		}

		.message-info {
			img {
				display: none;
			}
		}
	}
}

.chat-room-main-wrapper.chat-room-respond-is-disabled {
	// max-height: calc(100vh - 178px) !important;
	max-height: calc(100vh - 122px) !important;
	
	.chat-room-main-content {
		// max-height: calc(100vh - 178px) !important;
		overflow: auto;
		max-height: calc(100vh - 122px) !important;
	}
}
.chat-room-main-wrapper.force-hide-input-message {
	// max-height: calc(100vh - 232px) !important;
	max-height: calc(100vh - 176px) !important;

	.chat-room-main-content {
		// max-height: calc(100vh - 232px) !important;
		overflow: auto;
		max-height: calc(100vh - 176px) !important;
	}
}
.chat-room-main-wrapper.mark-as-solved-is-active {
	// max-height: calc(100vh - 198px) !important;
	max-height: calc(100vh - 142px) !important;

	.chat-room-main-content {
		overflow: auto;
	}
}
.chat-room-main-wrapper.chat-room-panel-info-show {
	// max-height: calc(100vh - 232px);
	max-height: calc(100vh - 176px) !important;

	.chat-room-main-content {
		overflow: auto;
		// max-height: calc(100vh - 178px) !important;
		max-height: 100% !important;
	}
}
.chat-room-main-wrapper.chat-room-nopanel-input-or-panel-info {
	// max-height: calc(100vh - 104px);
	max-height: calc(100vh - 48px);

	.chat-room-main-content {
		overflow: auto;
	}
}
.chat-room-main-wrapper.chat-room-show-input-message {
	// max-height: calc(100vh - 158px);
	max-height: calc(100vh - 102px);

	.chat-room-main-content {
		overflow: auto;
	}
}
.no-longer-participant, .last-message-received {
	text-align: center;
	background: #fff4b1;
	width: 100%;
	color: #ff7d00;
	padding: 12px;
	transition: ease-in-out .2s;
	display: inline-block;
	position: relative;
	z-index: 1;
	
	.icon-title-send-template {
		margin-right: 4px;
		position: relative;
		top: -2px;
	}
	img {
		width: 16px;
		position: relative;
		top: -2px;
		margin-right: 5px;
	}
	svg {
		height: 16px;
		width: 16px;
	}
	p {
		font-size: 16px;
		letter-spacing: -0.5px;
		margin: 0;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;

		&:nth-child(2) {
			margin: 0 0 2px;
			font-size: 16px;
			letter-spacing: -0.2px;
		}
	}
	button {
		width: 100%;
		padding: 10px 0;
		font-size: 16px;
		color: #ffffff;
		letter-spacing: -0.5px;
		margin-top: 6px;
	}
	.lds-ring {
		top: 2px;
		right: unset;
		div {
			margin: 0;
			border-color: #ffffff transparent transparent transparent;
		}
	}
}

.wrap-all-chatroom {
	width: 100%;
	// height: calc(100vh - 104px);
	height: calc(100% - 48px);
	transition: ease-in-out .2s;
	display: inline-block;
	font-size: 0;
  	position: relative;
  
  .drop-file-here-wrapper {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    display: none;
    text-align: center;
    font-size: 24px;
    letter-spacing: -0.5px;
    background: #eaeaea;
    // transition:  all .3s ease 0s;
    z-index: 3;

    &.active-drop-file-here-wrapper {
      display: block;
    }

    svg {
      font-size: 48px;
      margin-bottom: 16px;
    }

    p {
      margin: 0;
    }

    .drop-file-here-content {
      width: 80%;
      height: 80%;
      padding: 40px;
      border: dashed 4px #191919;
      display: inline-block;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%); 
      line-height: 80%;
      color: #191919;
      
      .drop-file-here-inner-content {
        width: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        padding: 24px;
      }
    }
  }
}
.wrap-all-chatroom.active-chat-user-info {
	// width: calc(100% - 300px);
	width: calc(100% - 340px);
}

@keyframes highlight {
    0% {
        background: #FFF4B1; 
    }
    100% {
        background: none;
    }
}

.highlight-chat-bubble {
    animation: highlight 2s;
}

.with-reply {
	img, video {
		border-radius: 0 !important;
	}
}

.hide-reply-button {
	// .reply-button {
	// 	display: none !important;
	// }
	.reply-button {
		display: none !important;
	}
}

.forward-button {
	transform: scaleX(-1);
}

.message-body, .caption-text {
	cursor: text;
}

.hide-reply-button .with-forward {
	// width: 100%;
}

.message-action-button-wrapper {
	cursor: pointer;

	&:hover {
		background: rgba(25, 25, 25, 0.05);

		svg {
			color: rgba(25, 25, 25, 0.8) !important;
		}
	}
}

.tap-hyperlink {
	color: unset !important;
	text-decoration: underline !important;
}

.message-in-bubble {
	.tap-hyperlink {
		color: #039be5 !important;
		text-decoration: underline !important;
	}
}

.marker-map {
    position: relative;
    width: 50px;
    height: 5px;

    img {
        position: absolute;
        top: -37px;
        left: 50%;
        left: 50%;
        transform: translate(-50%, 0);
    }
}