.comp-email-wrapper-info {
    width: 340px;
    max-width: 100%;
    background: #fff;
    font-size: 12px;
    letter-spacing: -0.4px;
    position: relative;

    // .close-email-info {
    //     cursor: pointer;
    //     position: absolute;
    //     top: -27px;
    //     right: -24px;
    //     width: 22px;
    //     height: 22px;
    //     border: solid 1px #dcdcdc;
    //     border-radius: 50%;
    //     text-align: center;
    //     background: #fff;

    //     svg {
    //         position: absolute;
    //         top: 50%;
    //         left: 50%;
    //         transform: translate(-50%, -50%);        
    //     }
    // }

    .comp-email-line-wrapper {
        width: 100%;
        display: block;
        vertical-align: top;
        box-sizing: border-box;
        line-height: 16px;
        cursor: default;

        .comp-email-line-wrapper-left {
            width: 41px;
            display: inline-block;
            vertical-align: top;
            text-align: right;
            margin: 0 0 4px 0;
            color: rgba(25, 25, 25, 0.6);
        }

        .comp-email-line-wrapper-right {
            width: calc(100% - 41px);
            display: inline-block;
            vertical-align: top;
            margin: 0 0 4px 0;
            padding: 0 0 0 12px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        .comp-email-line-wrapper-right {
            &.with-overflow {
                overflow: visible !important;
                text-overflow: unset !important;
                white-space: normal !important;
                word-break: break-word;
            }
        }
    }
}