.header-add-contact-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 32px;

  .comp-back-button {
    margin: 0;
  }
}

.add-contact-main-container {
  width: 100%;
  display: flex;

  .add-contact-segment-main-wrapper {
    width: 60%;
    min-width: 600px;
    margin-right: 16px;

    .add-contact-segment-header {
      padding-bottom: 12px;
      margin-bottom: 24px;
      border-bottom: 1px solid rgba(25, 25, 25, 0.1);
    }

    .add-contact-segment-search {
      width: 343px;
      border-radius: 8px;
      border: solid 1px rgba(25, 25, 25, 0.1);
      margin-bottom: 24px;
      position: relative;

      .icon-remove-search {
        position: absolute;
        cursor: pointer;
        right: 50px;
        top: 50%;
        transform: translate(0, -50%);
      }
  
      &.focus-search {
        border: solid 1px #ff7e00;
  
        // svg {
        //   color: #ff7e00;
        // }
      }
  
      input {
        border: none;
        background: transparent;
        padding: 8px 40px 8px 16px;
        width: 90%;
        height: 47px;
      }
  
      svg {
        position: relative;
        top: -2px;
        font-size: 20px;
        color: rgba(25, 25, 25, 0.6) !important;
      }
    }

    .user-contact-segment-list-table-wrapper {
      .custom-table {
        min-height: 200px;
      }
    }
  }

  .selected-contact-segment-main-wrapper {
    width: 40%;
    min-width: 332px;
    height: fit-content;

    .selected-contact-segment-header {
      padding-bottom: 12px;
      margin-bottom: 12px;
      border-bottom: 1px solid rgba(25, 25, 25, 0.1);
      display: flex;
      justify-content: space-between;
      align-items: center;

      .clear-all {
        font-size: 16px;
        letter-spacing: -0.5px;
        color: #FF3F57;
        cursor: pointer;
        margin: 0;
  
        svg {
          position: relative;
          top: -1px;
          margin-right: 4px;
        }
  
        &:hover {
          color: rgba(255, 63, 87, 0.8);
        }
      }
    }

    .contact-selected-wrapper {
      width: 100%; 

      .contact-item-selected {
        padding: 2px 12px;
        background: rgba(25, 25, 25, 0.05);
        border-radius: 4px;
        margin-right: 4px;
        color: rgba(25, 25, 25, 0.6);
        display: inline-block;
        line-height: 18px;
        letter-spacing: -0.4px;
        margin-bottom: 4px;

        svg {
          position: relative;
          top: -1px;
          margin-right: 5px;
          cursor: pointer;
        }
      }

      .no-selected-contact-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 24px;
  
        img {
          margin-bottom: 20px;
        }
  
        p {
          margin: 0;
        }
      }
    }

    
  }
}

.table-user-contact-segment-list {
  table-layout: fixed;

  .custom-checkbox-stripe [type="checkbox"]:checked + label:after {
    top: 2px;
    left: 0;
  }

  thead {
    tr {
      background: transparent;
  
      td {
        padding: 10px;
        // width: calc((100% - 100px) / 5);
        width: 20%;
        font-size: 14px;
        word-wrap: break-word;
        white-space: normal;

        .custom-checkbox {
          top: 13px !important;
        }

        .custom-checkbox-stripe {
          top: 13px !important;
        }
  
        &:nth-child(1) {
          width: 200px;
          padding-left: 34px !important;
        }
    
        &:nth-child(2) {
          width: 200px;
        }
    
        &:nth-child(3) {
          width: 160px;
        }

        &:last-child {
          width: 90px;
          padding-right: 0;
          

          .main-custom-select-option-wrapper {
            display: flex;
            justify-content: flex-end;

            .dropdown {
              width: 80px;
            }
          }

          .custom-select-option-value-wrapper {
            width: 55px !important;
            height: 23px !important;
            padding: 2px 8px 2px 12px !important;
            border-radius: 4px !important;
    
            p {
              font-size: 12px;
              font-weight: bold;
            }
    
            .custom-select-option-value {
              padding: 0;
            }
    
            svg {
              right: 3px !important;
              height: 15px;
              top: 11px !important;
              position: absolute !important;
              margin-right: unset !important;
            }
          }
        }
      }
    }
  }

  tbody {
    tr {
      // cursor: pointer;

      td {
        background: transparent;
        position: relative;
        word-wrap: break-word;
        // vertical-align: top;

        &:nth-child(1) {
          padding-left: 34px !important;
        }

        &.col_full_name {
          p {
            margin-bottom: 0;
            display: inline-block;
            width: 75%;
            vertical-align: top;
          }
        }

        &.no-data-column {
          .no-contact-wrapper {
            // display: flex;
            flex-direction: column;
            margin-top: 6px;
            text-align: center;

            .no-contact-found-image {
              width: 40px;
            }

            img {
              margin-bottom: 20px;
            }

            p {
              margin: 0;
              color: rgba(25, 25, 25, 0.8);
            }
          }
        }

        &.col-action {
          padding-right: 0;
          // height: 77px;
          text-align: right;
          white-space: nowrap;
        }
      }

      &:nth-child(even) {
        // background: rgba(25, 25, 25, 0.05);
      }

      &:hover {
        background-color: transparent !important;

        // td {
        //   // color: #FF7E00;
        // }
      }

      
    }
  }
}