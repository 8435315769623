.other-case-tab-wrapper {
  background: #f3f3f3;
  width: calc(100% - 62px);
  display: inline-block;
  overflow: hidden;

  .case-room-search-wrapper {
    display: none;
  }

  .case-room-filter-tag-badge {
    margin-top: 6px;
  }
}

.other-case-room-wrapper {
  // height: calc(100vh - 109px);
  height: calc(100vh - 53px);
  position: relative;
  // top: 1px;
  /* max-height: calc(100vh - 105px); */

  .need-reply-unread-panel-wrapper {
	width: 100%;
	text-align: center;

	.need-reply-panel {
		border-radius: 12px;
	}
  }
}

.other-case-room-wrapper #input-search-form input::placeholder {
  font-size: 13px;
  color: rgba(25, 25, 25, 0.4);
}

.other-case-room-wrapper .lds-ring {
  margin-right: 5px;
  top: 3px !important;
  right: 0;
  right: 5px;
}

.other-case-sort-by-wrapper {
	box-shadow: 0 1px 0 0 rgba(25, 25, 25, 0.1);
	width: 100%;
	position: relative;
	z-index: 3;
	padding: 4px 12px 8px 12px;
	background: #ffffff;
	// overflow: hidden;
	white-space: nowrap;
	height: 37px;
	left: 1px;

	&::-webkit-scrollbar {
		display: none;
	}

	.other-case-sort-by-wrapper-inner {
    .sort-by-content-wrapper {
      top: 1px;
    }

		&::-webkit-scrollbar {
			display: none;
		}

		.sort-by-wrapper-inner-scroll {
			position: absolute;
			top: 4.5px;
			left: 160px;
			max-width: calc(100% - 171px);
			overflow-x: auto;
			margin: 0 5px;

			.case-room-filter-tag-badge {
				&:nth-child(1) {
					margin-left: 0;
				}
			}

			&::-webkit-scrollbar {
				display: none;
			}
		}
	}

  .case-room-filter-tag-badge {
    margin: 3px 0 0 4px;
    color: rgba(25, 25, 25, 0.6);

    img {
			position: relative;
			top: -1px;
			margin-right: 3px;
		}
  }
}