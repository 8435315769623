.modal-preview-image-or-video-reuseable {
    width: 100vw !important;
    
    .modal-content {
        width: 100%;
        height: 100%;
        background: none;

        .modal-body {
            width: 100%;
            height: 100%;
            background: rgba(25, 25, 25, 0.8);

            img {
                max-width: calc(100vw - 130px);
                max-height: calc(100vh - 130px);
            }

            .image-wrapper {
                display: inline-block;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }

            .video-wrapper {
                display: inline-block;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);

                video {
                    max-height: calc(100vh - 130px);
                    max-width: calc(100vw - 130px);
                }
            }

            .close-modal-image-video {
                position: absolute;
                top: 20px;
                right: 20px;
                color: #ffffff;
                font-size: 40px;
                cursor: pointer;
                transition: ease-in-out 0.1s;

                &:hover {
                    color: #c9c9c9;
                }
            }
        }
    }
}