.modal-confirmation-delete-path {
  button {
    width: calc(50% - 4px) !important;
    margin-right: 16px;

    &:nth-last-child(1) {
      margin-right: 0;
    }
  }

  // .modal-footer {
  //   padding: 0;
  // }
}

.view-chatgpt-usage {
  .grey-font-6 {
    margin: 0;
  }

  .flexing {
    display: flex;
    margin: 4px 0 0 !important;
  }

  .view-chatgpt-usage-top {
    padding: 10px 0 12px 0;
    border-bottom: solid 1px #dcdcdc;
    margin-bottom: 24px;
    position: relative;
    font-size: 20px;
    letter-spacing: -0.6px;

    svg {
      position: relative;
      top: -2px;
      margin-right: 8px;
      width: 24px;
      height: 24px;
    }

    .subtitle-chatbot {
      color: rgba(25, 25, 25, 0.8);
      font-size: 14px;
      letter-spacing: -0.2px;
      margin: 0 0 0;

      a {
        color: #ff7d00;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  .view-chatgpt-usage-bottom {
    .view-chatgpt-usage-filter-wrapper {
      width: 100%;
      display: block;
      margin: 0 0 24px 0;
      position: relative;
      text-align: right;
      height: 50px;

      .main-custom-select-option-wrapper {
        width: 276px;
        position: absolute;
        right: 0;
        top: 0;
        
        .custom-select-option-value-wrapper {
          padding: 12px 16px !important;
        }

        .custom-select-option-value {
          text-align: center;
          padding: 0;
        }

        .icon-arrow {
          right: 16px;
        }

        .icon-left {
          position: absolute;
          left: 16px;
          top: 50%;
          transform: translate(0, -50%);
          font-size: 20px;
        }
      }
    }

    .view-chatgpt-usage-top-usage-wrapper {
      width: 100%;
      display: block;
      margin: 0;
      position: relative;

      .package-information-left {
        position: relative;
        display: inline-block;
        vertical-align: top;
        min-width: 263px;
        max-width: 100%;
        // padding: 16px 24px 16px 24px;
        padding: 20px 24px;
        border-radius: 8px;
        border: solid 1px #1919191A;
        margin: 0 24px 24px 0;
        text-align: left;
        color: #191919;

        .package-information-top {
          font-size: 14px;
          letter-spacing: -0.4px;
          margin: 0 0 12px 0;

          svg {
            position: relative;
            top: -2.5px;
            margin: 0 4px 0 0;
          }

          b {
            font-size: 16px;
            line-height: 24px;
            letter-spacing: -0.5px;
          }
        }

        .package-information-list {
          font-size: 14px;
          letter-spacing: -0.2px;
          white-space: nowrap;
          line-height: 23px;
          max-width: 100%;
          text-overflow: ellipsis;
          overflow: hidden;
          margin: 0;
        }
      }

      .package-information-right {
        position: relative;
        display: inline-block;
        vertical-align: top;
        // padding: 16px 24px 16px 24px;
        padding: 16px 16px 0 16px;
        max-width: 100%;
        border-radius: 8px;
        border: solid 1px #1919191A;
        text-align: left;

        .package-information-right-list {
          // width: calc(100% / 3);
          display: inline-block;
          vertical-align: top;
          min-width: 166px;
          max-width: 100%;
          margin: 0 24px 16px 0;
          white-space: pre-line;
          word-wrap: break-word;

          img {
            width: 24px;
            height: 24px;
          }

          .package-information-right-text-1 {
            margin: 4px 0 0;
            font-size: 16px;
            line-height: 24px;
            letter-spacing: -0.5px;
            color: #191919;
          }

          .package-information-right-text-2 {
            margin: 4px 0 0;
            font-size: 24px;
            line-height: 30px;
            letter-spacing: -0.6px;
            color: #191919;
          }

          .package-information-right-text-3 {
            margin: 4px 0 0;
            font-size: 14px;
            line-height: 21px;
            letter-spacing: -0.4px;
            color: #19191966;
          }
        }
      }
    }

    .view-chatgpt-usage-bar-chart-wrapper {
      padding: 16px;

      .title-wrapper {
        font-size: 16px;
        line-height: 24px;
        letter-spacing: -0.5px;
        width: 100%;
        display: block;
        position: relative;
        padding: 0 0 16px 0;

        .bar-chart-legend-wrapper {
          display: block;
          position: absolute;
          right: 0;
          top: 2px;
          white-space: nowrap;
          font-size: 14px;
          line-height: 21px;
          letter-spacing: -0.2px;
          color: #191919;

          .bar-chart-legend-box {
            display: inline-block;
            vertical-align: top;
            margin: 0 12px 0 0;
            width: 48px;
            height: 20px;
            background: #FFCB99;
            background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='4' ry='4' stroke='%23FF7E00FF' stroke-width='1' stroke-dasharray='2%2c 2' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
            border-radius: 4px;
          }
        }
      }
    }
  }
}

.box-no-data {
  width: 100%;
  text-align: center;
  padding: 0 0 40px 0;

  img {
    height: 88px;
  }

  b {
    display: block;
    margin: 16px 0 0 0;
    color: rgba(25, 25, 25, 0.4);
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.5px;
  }
}
