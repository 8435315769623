.unpaid-invoices-detail-wrapper {
    padding: 40px 32px;

    .unpaid-invoices-detail-title {
        font-size: 20px;
        letter-spacing: -0.6px;
        color: #191919;
        margin: 0 0 16px;
        position: relative;

        .button-top-wrapper {
            position: absolute;
            right: 0;
            top: 50%;
            transform: translate(0, -50%);

            button {
                font-size: 16px;
                letter-spacing: -0.5px;
                margin-left: 4px;
                
                svg {
                    position: relative;
                    top: -1px;
                    margin-right: 4px;
                }
            }
        }

    }

    .alert-failed {
        color: #FF3F57;
        background: #FFE6E9;
        border: 1px solid #FF3F57;
        max-width: 100%;
        margin: 16px 0;

        .masking-dot {
            vertical-align: top;
            width: 4px;
            height: 4px;
            position: relative;
            top: 7px;
            display: inline-block;
            margin: 0 2px;
            border-radius: 50%;
            background: #FF3F57;
        }
        
        svg {
            top: 25px;
        }
    }

    .invoice-payment-issue-due {
        border-bottom: solid 1px #dcdcdc;

        div {
            width: 137px;
            display: inline-block;
            vertical-align: top;
            margin-right: 64px;
            padding-bottom: 12px;
            font-size: 14px;
            line-height: 21px;
            letter-spacing: -0.4px;
            color: #000000;

            b {
                font-size: 12px;
                line-height: 18px;
            }
        }
    }

    table {
        width: 100%;
        margin-top: 24px;

        thead {
            tr {
                border-bottom: solid 1px rgba(25, 25, 25, 0.1);
                
                td {
                    padding: 8px 8px 4px;
                    text-align: left;
                    color: #191919;
                    font-size: 14px;
                    letter-spacing: -0.4px;

                    &.col_amount {
                        text-align: right;
                    }

                    &:nth-child(2) {
                        text-align: center;
                    }
                }
            }
        }

        tbody {
            tr {
                td {
                    padding: 4px 8px 7px;
                    text-align: left;
                    color: #191919;
                    font-size: 14px;
                    letter-spacing: -0.2px;
                    vertical-align: top;

                    &.col_description {
                        font-size: 12px;
                        letter-spacing: -0.2px;
                        line-height: 16px;

                        b {
                            font-size: 14px;
                            line-height: 21px;
                        }
                    }

                    &.col_amount {
                        text-align: right;
                    }

                    &:nth-child(2) {
                        text-align: center;
                    }

                    .subscription-label-view {
                        color: #ff7e00;
                        cursor: pointer;
                        
                        svg {
                            position: relative;
                            margin-right: 4px;
                            top: -2px;
                        }

                        &:hover {
                            color: #e87200;
                        }
                    }

                    &.col_total {
                        background: #DCEBFF;
                        font-size: 16px;
                        letter-spacing: -0.5px;
                        text-align: right;
                        width: 100%;
                    }
                }

                &:nth-child(odd) {
                    background: rgba(25, 25, 25, 0.05);
                }
            }
        }
    }

    .invoice-detail-footer {
        position: relative;
        width: 100%;
        min-height: 160px;
        margin-top: 24px;

        .invoice-footer-right {
            position: absolute;
            top: 0;
            right: 0;
            width: 320px;
            max-width: calc(50%);
            display: inline-block;

            table {
                margin: 0;
                
                tr {
                    background: rgba(25, 25, 25, 0.05);
                    border-bottom: solid 1px #ffffff;
                    
                    td {
                        padding: 6px 8px 4px;
                        line-height: 21px;
                        color: rgba(25, 25, 25, 0.8);
                        
                        &:nth-child(even) {
                            text-align: right;
                        }

                        &.sub_total_amount {
                            color: #191919;
                        }
                    }

                    &:nth-child(even) {
                        background: rgba(25, 25, 25, 0.1);
                    }

                    &.col_footer_total {
                        background: #DCEBFF;

                        td {
                            .col_right {
                                font-size: 16px;
                                letter-spacing: -0.5px;
                                line-height: 24px;
                            }
                        }
                    }
                }
            }
        }

        .pay-pending-bottom-wrapper {
            max-width: calc(50%);
            width: 371px;
            display: inline-block;
            padding: 24px;
            letter-spacing: -0.4px;
            font-size: 14px;
            color: #191919;
            border-top: solid 1px #dcdcdc;
            border-right: solid 1px #dcdcdc;
    
            .pay-transfer-to {
    
            }
    
            .account-number-wrapper {
                width: 291px;
                max-width: 100%;
                position: relative;
                border-radius: 8px;
                border: solid 1px #dcdcdc;
                height: 48px;
                margin-top: 8px;
                font-size: 16px;
                letter-spacing: -0.5px;
                color: #191919;
                padding: 10px 56px;
                margin-bottom: 24px;
    
                img {
                    position: absolute;
                    left: 16px;
                    top: 50%;
                    transform: translate(0, -50%);
                }
    
                svg {
                    position: absolute;
                    right: 16px;
                    top: 50%;
                    transform: translate(0, -50%);
                    color: #a3a3a3;
                    cursor: pointer;
    
                    &:hover {
                        color: #6f6f6f;
                    }
                }
    
                input {
                    font-weight: bold;
                    border: none;
                }
            }
    
            .alert-component {
                border: solid 1px #044B84;
                background: #DCEBFF;
                color: #044B84;
                letter-spacing: -0.4px;
                font-size: 14px;
                width: 323px;
                max-width: 100%;
                padding: 12px 16px 12px 38px;
    
                svg {
                    font-size: 16px;
                    top: 22px;
                }
            }
        }
    }
} 

.modal-retry-payment {
    .modal-content {
        border-radius: 8px;
        width: 489px;
        display: inline-block;
    }

    .modal-body {
        padding: 32px;
        text-align: center;

        .lds-ring {
            height: 53px;
            width: 53px;
            top: unset;
            right: unset;

            div {
                height: 53px;
                width: 53px;
                margin: 0;
                border: 6px solid #fff;
                border-color: #ff7d00 transparent transparent transparent;
            }

            margin: 0 0 12px 0;
        }

        p {
            color: #000000;
            font-size: 14px;
            letter-spacing: -0.2px;
            margin: 0;
        }
    }
}

.modal-success-payment {
    .modal-content {
        width: 489px;
        border-radius: 8px;
        text-align: center;

        .modal-body {
            padding: 32px;

            p {
                font-size: 14px;
                letter-spacing: -0.2px;
                margin: 8px 0 16px;
            }

            button {
                width: 100%;
            }
        }
    }
}