.edit-account-profile-content {
    label {
        font-size: 14px;
        position: relative;

        span {
            position: absolute;
            top: 0;
            right: 0;
        }
    }
    
    .photo-wrapper {
        margin-bottom: 8px;
        border: 1px solid rgba(25, 25, 25, 0.1);
        border-radius: 4px;
        width: 96px;
        height: 96px;
    
        img {
            border-radius: 4px;
            height: 96px;
            width: 96px;
        }

        .profile-photo-empty {
            height: 94px;
            background: rgba(25, 25, 25, 0.05);
            position: relative;

            svg {
                height: 32px;
                width: 32px;
                color: #9c9c9c;
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
            }
        }
    }
    .button-wrapper {
        margin-bottom: 8px;
        > div {
            display: flex;
            label {
                margin-right: 8px;
            }
        }
        label {
            width: fit-content;
            cursor: pointer;
            margin-bottom: 0 !important;
            input {
                width: 100%;
            }
        }
    }
}
