.this-col-not-found.tcRow:hover {
  background: none ! important;
}

.this-col-not-found .no-data-found {
  margin: "0 ! important"; 
  text-align: "center"; 
  color: "#a6a6a6 ! important"; 
  background: "none ! important"; 
  padding: "24px 1px 0"; 
  border: "none ! important";
}

.topic {
  position: relative;
}

.form-topic-detail .formContent {
  position: unset;
}

.form-topic-detail label {
  margin: 0 0 8px;
}

.form-topic-detail .input-wrapper {
  padding: 0;
  margin: 0;
}

.assigned-agent-content {
  margin: 24px auto !important;
}

.action-button svg {
  position: relative;
  top: -1px;
}

.form-topic-detail .orange-button {
  margin-top: 12px;
}

.form-topic-detail .pos-relative {
  width: 350px;
}

.form-topic-detail span {
  font-weight: normal;
}

.assigned-agent-content td {
  word-break: break-word;
}

.assigned-agent-content .tableContent {
  margin: 24px 0 0 !important;
}

.topContent-topic-detail {
  padding-top: 0;
  padding-bottom: 18px;
}

.title-topic-details {
  width: 100%;
  position: relative;
  display: block;
  padding-top: 6px;
}

.title-topic-details .button-delete-topic {
  position: absolute;
  right: 184px;
  top: 0;
  width: 180px;
}

.button-save-topic {
  right: 32px;
  top: 20px;
  position: absolute;
  width: 180px;
}