.integration-google-detail-account {
    text-align: left;

    .topic-val {
      max-width: 320px;
      color: #191919 !important; 
      margin: 6px 0 16px 0 !important;
      font-size: 16px !important;
    }
  
    .comp-back-button {
      margin: 0 0 16px 0;
    }
  
    .integration-google-detail-account-box-1 {
      background: #ffffff;
      border-radius: 8px;
      padding: 22px 32px 32px;
      border: solid 1px rgba(25, 25, 25, 0.1);
      position: relative;
  
      .integration-google-detail-account-box-1-top {
        padding: 10px 0 15px;
        border-bottom: solid 1px #dcdcdc;
        position: relative;
        font-size: 20px;
        letter-spacing: -0.6px;
        padding-right: 230px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        .bot-name {
          margin-bottom: 0;
        }
  
        .integration-google-detail-account-box-1-top-button {
          position: absolute;
          top: 0px;
          right: 0;
          display: inline-block;
  
          button {
            padding: 7px 24px;
            font-size: 16px;
            letter-spacing: -0.6px;
            height: 40px;
            margin-left: 8px;

            &.button-channel-detail {
              position: relative;
              top: 15px;
            }
  
            svg {
              position: relative;
              top: -2px;
              margin-right: 4px;
            }
  
            .lds-ring {
              top: unset;
              right: unset;
              position: relative;
              top: 3px;
  
              div {
                margin: 0;
                border-color: #ffffff transparent transparent transparent;
              }
            }
          }
        }
      }
  
      .integration-google-detail-account-box-1-bottom {
        position: relative;
        font-size: 20px;
        letter-spacing: -0.6px;

        .custom-select-phonenumber {
          width: 316px;

          button {
            margin-top: 8px;
          }
        }
  
        label {
          font-size: 14px;
          letter-spacing: -0.4px;
          margin: 24px 0 0 0;
        }
  
        p {
          margin: 4px 0 0;
          font-size: 15px;
          letter-spacing: -0.5px;

          &.value-detail {
            font-size: 20px;
            letter-spacing: -0.6px;
            
            img {
                position: relative;
                top: -2px;
                margin-right: 4px;
            }
          }

          &.value-detail-status {
            font-size: 16px;
            letter-spacing: -0.5px;
          }
        
          &.value-active-green {
              color:rgba(25, 199, 0, 1);
          }

          &.value-active-orange {
            color: #ff7e00;
          }

          &.value-active-red {
            color:#ff3f57;
          }
  
          svg {
            margin-right: 4px;
            position: relative;
            top: -2px;
            font-size: 20px;
          }
        }
  
        .integration-google-detail-account-box-1-bottom-button {
          display: block;
          // margin-top: 16px;
  
          button {
            padding: 7px 24px 7px 20px;
            font-size: 16px;
            letter-spacing: -0.6px;
            height: 40px;
            margin-right: 8px;
            margin-top: 16px;
  
            svg {
              position: relative;
              top: -2px;
              margin-right: 4px;
            }
  
            img {
              position: relative;
              top: -2px;
              margin-right: 4px;
            }
  
            .lds-ring {
              top: unset;
              right: unset;
              position: relative;
              top: 3px;
  
              div {
                margin: 0;
                border-color: #ffffff transparent transparent transparent;
              }
            }
          }
        }        
      }
    }
  
    .integration-google-detail-account-box-2 {
      background: #ffffff;
      border-radius: 8px;
      padding: 32px;
      border: solid 1px rgba(25, 25, 25, 0.1);
      margin-top: 12px;
  
      .integration-google-detail-account-box-2-title {
        font-size: 20px;
        letter-spacing: -0.6px;
        padding-bottom: 20px;
        border-bottom: solid 1px #dcdcdc;
        display: block;
        margin: 0 0 24px;
      }
  
      .integration-google-detail-account-box-2-payment-wrapper {
        width: 100%;
        position: relative;
  
        label {
          font-size: 14px;
          letter-spacing: -0.4px;
          margin: 0;
        }
  
        .payment-info-wrapper {
          position: relative;
  
          .payment-until {
            font-size: 15px;
            letter-spacing: -0.5px;
            color: #19c700;
            margin: 0;
  
            img {
              vertical-align: top;
              position: relative;
              top: 0;
              margin-right: 4px;
            }
          }
  
          .payment-expired {
            color: #ff3f57;
            font-size: 15px;
            letter-spacing: -0.5px;
            margin: 4px 0 0;
  
            svg {
              vertical-align: top;
              position: relative;
              font-size: 20px;
              margin-right: 4px;
            }
          }
  
          .payment-bar {
            width: 326px;
            height: 4px;
            background: rgba(25, 25, 25, 0.1);
            border-radius: 2px;
            margin: 8px 0;
            position: relative;
  
            div {
              position: absolute;
              left: 0;
              top: 0;
              background: #3baeff;
              height: 4px;
            }
          }
  
          .payment-left {
            font-size: 12px;
            letter-spacing: -0.2px;
            color: rgba(25, 25, 25, 0.4);
          }
  
          .not-paid {
            color: rgba(25, 25, 25, 0.4);
            font-size: 16px;
            letter-spacing: -0.5px;
            margin: 0 0 22px;
          }
        }
  
        .alet-verifying {
          border-radius: 8px;
          color: #044b84;
          border: solid 1px #044b84;
          background: #dcebff;
          // width: 326px;
          width: 100%;
          position: relative;
          padding: 12px 16px 12px 38px;
          font-size: 14px;
          letter-spacing: -0.4px;
          width: 323px;
  
          svg {
            font-size: 15px;
            transform: none;
            top: 15px
          }
        }
      }
    }
  }

  .integration-google-detail-account-box-1 {
    background: #ffffff;
    border-radius: 8px;
    padding: 22px 32px 32px;
    border: solid 1px rgba(25, 25, 25, 0.1);

    p {
      font-size: 14px;
      letter-spacing: -0.2px;
    }

    &.box-channel-button {
      margin-top: 24px;
    }

    .whatsapp-detail-channel-button {
      .channel-button-wrapper {
        margin-top: 24px;
        width: 300px;
        word-break: break-word;
      }
    }

    .status-wrapper {
      .status-value {
        color:rgba(25, 25, 25, 0.4);

        &.status-enabled {
          color: #19C700;
        }
      }

      img {
        position: relative;
        top: -1px;
        margin-right: 4px;
      }
    }
  }
  