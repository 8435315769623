.custom-tooltips {
    position: relative;
    display: inline-block;
    margin-left: 4px;
    top: -2px;
    width: 16px;
    height: 16px;

    .inner-tooltip-wrapper {
        font-size: 14px;
        letter-spacing: -0.2px;
        text-align: left !important;
        line-height: 20px;
        padding: 6px 12px !important;
        color: #fff;
        position: absolute;
        background: #6B6B6B !important;
        border-radius: 4px;
        z-index: 1;
        top: 50%;
        transform: translate(0, -50%);
        left: 28px;
        display: none;
        width: 200px;

        p {
            margin: 0;
        }

        &::before {
            content: "";
            width: 10px;
            height: 10px;
            background: #6B6B6B;
            position: absolute;
            top: 50%;
            transform: rotate(-45deg) translate(0, -50%);
            left: -1px;
        }
    }

    svg {
        font-size: 16px;
        vertical-align: top;

        &:hover {
            +.inner-tooltip-wrapper {
                display: block;
            }
        }
    }
}