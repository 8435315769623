.edit-entry-point-google {
    .input-phone {
        position: relative;
    }
    
    .edit-entry-point-header {
        position: relative;
        
        .title-wrapper {
            width: 100%;
            border-bottom: solid 1px #dcdcdc;
            padding-bottom: 24px;
            margin-bottom: 24px;

            b {
                font-size: 20px;
                letter-spacing: -0.6px;
            }
        }

        button {
            right: 0;
            top: 0;
            position: absolute;
        }
    }

    .form-title {
        font-size: 16px;
        letter-spacing: -0.5px;
        color: #191919;
        margin: 0 0 4px 0;
        padding: 0 80px 0 0;
        position: relative;

        .custom-switch-wrapper {
            display: inline-block !important;
            width: 40px;
            height: 24px;
            position: absolute;
            top: 0;
            right: 0;
            margin: 0;
    
            
            .custom-switch.round {
                &::before {
                    width: 14px;
                    height: 14px;
                    bottom: 5px;
                    left: 4px;
                }
            }
    
            input:checked + .custom-switch:before {
                transform: translateX(18px);
            }
        }
    }

    .form-description {
        font-size: 16px;
        letter-spacing: -0.5px;
        color: rgba(25, 25, 25, 0.8);
        margin: 0;
        padding: 0 80px 0 0;
    }

    .non-local-form-wrapper {
        margin: 24px 0 0;

        .checkbox-wrapper {
            label {
                font-size: 14px;
                letter-spacing: -0.2px;
            }
        }

        input[type="text"] {
            border: solid 1px #dcdcdc;
        }
    }
}