.modal-manual-verification {
    .modal-content {
        width: 476px;
        border-radius: 8px;

        .modal-body {
            padding: 32px;
            position: relative;
            overflow-x: hidden;

            svg {
                &.close-modal {
                    position: absolute;
                    cursor: pointer;
                    color: rgba(25, 25, 25, 0.4);
                    transition: ease-in-out;
                    font-size: 20px;
                    top: 20px;
                    right: 21px;

                    &:hover {
                        color: rgba(25, 25, 25, 0.8);
                    }
                }
            }

            .manual-verification-progress-bar-wrapper {
                width: 100%;
                position: relative;

                .progress-value-wrapper {
                    width: 50%;
                    display: inline-block;
                    vertical-align: top;
                    text-align: center;

                    .progress-step-wrapper {
                        width: 32px;
                        height: 32px;
                        background: #ffffff;
                        border-radius: 50%;
                        position: relative;
                        display: inline-block;
                        text-align: center;
                        margin-bottom: 12px;

                        .progress-step-inner {
                            width: 23px;
                            height: 23px;
                            background: #f3f3f3;
                            border-radius: 50%;
                            position: relative;
                            text-align: center;
                            line-height: 24px;
                            font-size: 12px;
                            border: 1px solid rgba(25, 25, 25, 0.1);
                            display: inline-block;
                            position: absolute;
                            top: 50%;
                            left: 5px;
                            transform: translate(0, -50%);

                            b {
                                position: absolute;
                                top: 50%;
                                left: 50%;
                                transform: translate(-50%, -50%);
                                color: rgba(25, 25, 25, 0.4);
                            }
                        }
                    }

                    .progress-label {
                        font-size: 14px;
                        letter-spacing: -0.4px;
                        color: rgba(25, 25, 25, 0.4);
                    }

                    &.selected-value-wrapper {
                        .progress-step-wrapper { 
                            background: #FFF2E5;
                            
                            .progress-step-inner {
                                background: #ff7d00;

                                b {
                                    color: #ffffff;
                                }
                            }
                        }

                        .progress-label {
                            color: #191919;
                        }
                    }
                }

                .line-separator {
                    height: 6px;
                    width: 183px;
                    background: #f3f3f3;
                    border-top: solid 2px #e8e8e8;
                    border-bottom: solid 2px #e8e8e8;
                    position: absolute;
                    top: 13px;
                    left: 114px;
                    // z-index: 1;

                    &.orange-line {
                        border-top: solid 2px #ff7d00;
                        border-bottom: solid 2px #ff7d00;
                        background: #ff7d00;
                    }
                }
            }

            .manual-step-1 {
                transition: ease-in-out .2s;
                // left: -442px;
                position: relative;

                .manual-middle {
                    position: relative;
                    width: 100%;
                    transition: ease-in-out .2s;

                    .warning-top {
                        text-align: center;
                        width: 100%;
                        font-size: 14px;
                        letter-spacing: -0.2px;
                        margin: 0 0 24px;
                    }

                    border-top: solid 1px #dcdcdc;
                    border-bottom: solid 1px #dcdcdc;
                    margin: 12px 0 16px;
                    padding: 16px 0 0;

                    .value-wrapper {
                        font-size: 16px;
                        letter-spacing: -0.5px;
                        padding: 8px 16px;
                        width: 100%;
                        background: #F3F3F3;
                        border-radius: 8px;
                        position: relative;
                        margin: 0 0 24px;

                        svg {
                            cursor: pointer;
                            font-size: 18px;
                            color: rgba(25, 25, 25, 0.4);
                            position: absolute;
                            right: 16px;
                            top: 50%;
                            transform: translate(0, -50%);

                            &:hover {
                                color: rgba(25, 25, 25, 0.8);
                            }
                        }

                        &.value-wrapper-bank {
                            padding: 8px 16px 8px 54px;

                            img {
                                position: absolute;
                                left: 16px;
                                top: 50%;
                                transform: translate(0, -50%);
                            }
                        }
                    }
                }

                .warning-bottom {
                    text-align: center;
                    width: 100%;
                    font-size: 14px;
                    letter-spacing: -0.2px;
                    margin: 0 0 24px;
                }

                .button-submit {
                    width: 100%;

                    svg {

                    }
                }
            }

            .manual-step-2 {
                transition: ease-in-out .2s;
                // left: -442px;
                position: relative;
                border-top: solid 1px #dcdcdc;
                padding-top: 16px;
                margin-top: 12px;

                .warning-top {
                    text-align: center;
                    width: 100%;
                    font-size: 14px;
                    letter-spacing: -0.2px;
                }

                input {
                    width: 100%;
                }

                .button-select-image {
                    margin-top: 0;
                    width: unset;
                }

                .button-submit {
                    margin-top: 20px;
                }

                .button-verify {
                    float: right;
                }
            }
        }
    }
}