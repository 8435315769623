.modal-text-field {
    .modal-content {
      box-sizing: border-box;
      width: 500px;
      padding: 42px 24px;
      border-radius: 8px;
    }
  
    .modal-body {
      padding: 0 0 24px 0;
      font-size: 14px;
      letter-spacing: -0.2px;
      text-align: left;
      color: #9b9b9b;

      .text-field-wrapper {
        label {
          margin: 0;
        }

        .input-counter {
          position: absolute;
          top: 0;
          right: 0;
        }

        input[type="text"] {
          margin-top: 8px;
          width: 100%;
        }
      }
  
      p {
        &.modal-description {
          margin: 4px 0 0;
          font-size: 14px;
          letter-spacing: -0.2px;
          color: rgba(25, 25, 25, 0.6);
          line-height: 21px;
        }
      }
  
      b {
        &.modal-title {
          font-size: 20px;
          letter-spacing: -0.6px;
          color: #191919;
          line-height: 24px;

          img {
            position: relative;
            top: -2px;
            margin-right: 4px;
          }

          svg {
            position: relative;
            top: -2px;
            margin-right: 4px;
          }
        }
      }
    }
  
    .modal-footer {
      padding: 0;
      border: none;
      display: block;

      > * {
        margin: unset;
      }
  
      button {
        vertical-align: top;

        &.cancel-button {
          width: calc(50% - 4px);
          margin: 0 8px 0 0;
        }

        &.no-fill-button {
          &:disabled {
            background-color: white !important;
            border-color: #19191966 !important;
            cursor: default;
          }
        }
  
        &.modal-button {
          margin: 0;
          width: calc(50% - 4px);
          display: inline-block;
        }
      }
    }
  }
