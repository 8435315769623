.webhook2-main-setup-wrapper {
    .custom-checkbox {
        label {
            font-size: 16px;
            letter-spacing: -0.3px;

            p {
                font-size: 14px;
                letter-spacing: -0.2px;
                color: rgba(25, 25, 25, 0.6);
                margin: 0;
                word-break: break-word;
            }

            span {
                word-break: break-word;
            }
        }

        [type="checkbox"]:checked + label:before {
            top: 4px;
        }

        [type="checkbox"]:checked + label:after {
            top: -1px;
        }

        &:nth-last-child(1) {
            label {
                margin: 0;
            }
        }
    }

    .webhook2-main-setup-wrapper-title-wrapper {
        position: relative;
        width: 100%;
        margin: 0 0 10px 0;
        
        .title {
            font-size: 20px;
            letter-spacing: -0.6px;
            color: #191919;
        }

        .orange-button {
            position: absolute;
            right: 0;
            top: -5px;
        }

        .red-button {
            top: 0;
            right: 156px;
            position: absolute;
        }
    }

    .webhook2-list-info {
        padding: 0 158px 8px 0;
        border-bottom: solid 1px rgba(25, 25, 25, 0.1);
    }
    
    .label-title {
        font-size: 16px;
        letter-spacing: -0.5px;
        margin: 24px 0 8px 0;
        color: #191919;
        width: 100%;
        position: relative;
    }

    .label-desc {
        font-size: 14px;
        letter-spacing: -0.2px;
        color: rgba(25, 25, 25, 0.8);
        margin: 0;
    }

    .custom-switch-wrapper {
        display: inline-block !important;
        width: 40px;
        height: 24px;
        position: absolute;
        top: 0;
        right: 0;
        margin: 0;

        
        .custom-switch.round {
            &::before {
                width: 14px;
                height: 14px;
                bottom: 5px;
                left: 4px;
            }
        }

        input:checked + .custom-switch:before {
            transform: translateX(18px);
        }
    }

    .input-webhook {
        border: solid 1px rgba(25, 25, 25, 0.1);
        font-size: 16px;
        padding: 8px 16px;
        width: 100%;
        border-radius: 8px;
        margin: 0 0 8px 0;

        &:focus {
            border-color: #ff7d00;
        }
    }
}

.modal-remove-webhook {
    button {
        width: calc(50% - 2px) !important;
        display: inline-block;
        margin-right: 4px !important;
        
        &:nth-child(2) {
            margin-right: 0 !important;
        }
    }
}