.user-rating-setup {
    button {
        width: unset;
    }

    .closing-welcome-switch-wrapper {
        margin: 0 !important;
    }

    .message-wrapper {
        border-top: solid 1px #dcdcdc;
        margin-top: 24px;
        width: calc(100% + 90px);

        .message-wrapper-50 {
            width: calc(50% - 5px);
            display: inline-block;
            vertical-align: top;
            margin-right: 10px;

            .whatsapp-preview {
              margin-top: 24px !important;
            }

            label {
              position: relative;
              max-width: 416px;

              .grey-font {
                position: absolute;
                right: 0;
                top: 1px;
              }
            }

            &:nth-last-child(1) {
                margin-right: 0;
            }
        }
    }

    .user-rating-setup-header {
        font-size: 20px;
        letter-spacing: -0.6px;
        color: #191919;
        padding-bottom: 20px;
        position: relative;
        border-bottom: solid 1px #dcdcdc;

        button {
          position: absolute;
          top: -3px;
          right: 0;
          margin: 0;
        }
    }

    .user-rating-setup-form {
        padding-top: 24px;
        position: relative;

        .color-select-wrapper {
            width: 100%;
            position: relative;
            padding-top: 12px;

            .button-go-preview {
                position: absolute;
                top: 41px;
                right: -90px;
            }

            .color-input-wrapper {
                position: relative;
                width: 166px;
                height: 48px;
                border-radius: 8px;
                border: solid 1px #dcdcdc;
                cursor: pointer;
                line-height: 47px;
                font-size: 16px;
                letter-spacing: -0.3px;
                color: #191919;
                padding: 0 0 0 16px;
                margin-top: 12px;
    
                .color-placeholder {
                  color: rgba(25, 25, 25, 0.6);
                }
    
                img {
                  position: absolute;
                  position: absolute;
                  right: 16px;
                  top: 50%;
                  transform: translate(0, -50%);
                }
    
                .color-tag {
                  display: inline-block !important;
                  margin-right: 1px;
                  margin: 0;
                }
    
                .color-value {
                  width: 16px;
                  height: 16px;
                  display: inline-block;
                  background: #dcdcdc;
                  border-radius: 50%;
                  position: absolute;
                  right: 16px;
                  top: 50%;
                  transform: translate(0, -50%);
                  // margi: 0;
                }
    
                .color-input {
                  border: none;
                  padding: 0;
                  background-color: transparent;
                  height: 20px;
                  margin-top: 0 !important;
                  height: 40px !important;
                  display: inline-block;
                  width: 125px !important;
                }
    
                .color-picker-container {
                  background: #ffffff;
                  position: absolute;
                  bottom: 0;
                  right: -265px;
                  border-radius: 4px;
                  box-shadow: 0 2px 20px 0 rgba(25, 25, 25, 0.18) !important;
                  padding: 14px;
                  z-index: 9999;
    
                  .close-color-picker {
                    position: absolute;
                    top: 6px;
                    font-size: 14px;
                    right: 6px;
                    color: rgba(25, 25, 25, 0.6);
    
                    &:hover {
                      color: rgba(25, 25, 25, 0.8);
                    }
                  }
    
                  &::before {
                    position: absolute;
                    background: #ffffff;
                    width: 16px;
                    height: 16px;
                    content: '';
                    left: -8px;
                    transform: rotate(45deg);
                    bottom: 17px;
                  }
    
                  .sketch-picker {
                    border-radius: 4px;
                    box-shadow: none !important;
    
                    .flexbox-fix:nth-child(2) {
                      height: 28px;
    
                      div:nth-child(1) {
                        div:nth-child(1) {
                          height: 23px !important  ;
                          div {
                            div.hue-horizontal {
                              div {
                                div {
                                  display: block !important;
                                  margin-top: 0 !important;
                                }
                              }
                            }
                          }
                        }
    
                        div:nth-child(2) {
                          div {
                            display: none;
                          }
                        }
                      }
                    }
    
                    .flexbox-fix {
                      &:nth-child(3) {
                        display: none !important;
                      }
    
                      &:nth-child(4) {
                        div {
                          div {
                            border-radius: 50% !important;
                          }
                        }
                      }
                    }
                  }
                }
              }

            .color-picker-container {
                background: #ffffff;
                position: absolute;
                bottom: 0;
                right: -265px;
                border-radius: 4px;
                box-shadow: 0 2px 20px 0 rgba(25, 25, 25, 0.18) !important;
                padding: 14px;
                z-index: 9999;
  
                .close-color-picker {
                  position: absolute;
                  top: 6px;
                  font-size: 14px;
                  right: 6px;
                  color: rgba(25, 25, 25, 0.6);
  
                  &:hover {
                    color: rgba(25, 25, 25, 0.8);
                  }
                }
  
                &::before {
                  position: absolute;
                  background: #ffffff;
                  width: 16px;
                  height: 16px;
                  content: '';
                  left: -8px;
                  transform: rotate(45deg);
                  bottom: 17px;
                }
  
                .sketch-picker {
                  border-radius: 4px;
                  box-shadow: none !important;
  
                  .flexbox-fix:nth-child(2) {
                    height: 28px;
  
                    div:nth-child(1) {
                      div:nth-child(1) {
                        height: 23px !important  ;
                        div {
                          div.hue-horizontal {
                            div {
                              div {
                                display: block !important;
                                margin-top: 0 !important;
                              }
                            }
                          }
                        }
                      }
  
                      div:nth-child(2) {
                        div {
                          display: none;
                        }
                      }
                    }
                  }
  
                  .flexbox-fix {
                    &:nth-child(3) {
                      display: none !important;
                    }
  
                    &:nth-child(4) {
                      div {
                        div {
                          border-radius: 50% !important;
                        }
                      }
                    }
                  }
                }
              }
        }

        .closing-welcome-switch-wrapper {
            position: absolute;
            top: 38px;
            right: 0;
            vertical-align: top;
            display: inline-block;
            margin: 0 16px;
            
            .custom-switch-wrapper {
                margin: 0;
                height: 28px;
                width: 48px;

                .custom-switch:before {
                    width: 15px;
                    height: 15px;
                    bottom: 7px;
                }
            }
            
            input:checked + .custom-switch:before {
                transform: translateX(17px);
            }
        }

        .user-rating-setup-form-content {
            display: inline-block;
            font-size: 16px;
            letter-spacing: -0.5px;
            // max-width: calc(100% - 90px);
            padding-right: 90px;
            width: 100%;

            p {
                margin: 4px 0 10px;
                font-size: 14px;
                letter-spacing: -0.2px;
                color: rgba(25, 25, 25, 0.8);
            }

            label {
            }

            textarea {
                width: 100%;
                border-radius: 8px;
                font-size: 16px;
                letter-spacing: -0.3px;
                border: solid 1px #dcdcdc;
                resize: none;
                padding: 12px 16px;
                line-height: 24px;
                margin: 0;
                max-width: 416px;
                width: 100%;
                display: block;

                &:focus {
                    border-color: #ff7d00;
                }

                &[disabled] {
                    color: rgba(25, 25, 25, 0.4);
                    background: rgba(25, 25, 25, 0.1);
                }
            }
        }
    }
}