.option-select-segment-wrapper {
    width: 100%;
    position: relative;

    .select-all {
        border-bottom: solid 1px #dcdcdc;
        padding: 12px 17px 12px 17px !important;
    }

    .main-title {
        font-size: 20px;
        letter-spacing: -0.6px;
        margin: 0 0 22px 0;
        line-height: 1;

        img {
            position: relative;
            top: -1px;
            margin-right: 4px;
        }
    }

    .option-select-segment-wrapper-50 {
        width: calc(50% - 8px);
        position: relative;
        vertical-align: top;
        display: inline-block;
        margin: 0 16px 0 0;


        label {
            position: relative;
            width: 100%;
            font-size: 14px;
            letter-spacing: -0.4px;

            .clear-segment {
                position: absolute;
                bottom: 0;
                right: 0;
                cursor: pointer;
                color: var(--main-dark-orange);

                svg {
                    position: relative;
                    font-size: 16px;
                    top: -2px;
                    margin-right: 4px;
                }

                &:hover {
                    color: var(--main-darker-orange);
                }
            }
        }

        &:nth-child(5) {
            margin: 0;
        }

        span {
            color: rgba(25, 25, 25, 0.6);
            position: relative !important;
        }

         .selection-segment-wrapper {
             border-radius: 8px;
             font-size: 14px;
             letter-spacing: -0.2px;
             box-shadow: 0 0 0 1px #dcdcdc;
             overflow: hidden;
             position: relative;

             .select-content {
                 width: 100%;
                 padding: 12px 17px 6px 17px;
                 margin: 0;
                 white-space: nowrap;
                //  height: 43px;

                 label {
                     margin: 0;
                     max-width: 100%;
                     overflow: hidden;
                     text-overflow: ellipsis;
                     white-space: nowrap;
                     display: inline-block !important;
                     padding: 0 0 0 22px !important; 
                 }

                 input {
                     display: none;
                 }

                 &:hover {
                     background: #FFF2E5;
                 }

                 &:nth-child(1) {
                     border-top-left-radius: 8px;
                     border-top-right-radius: 8px;
                 }
             }

             .search-box-wrapper {
                width: 100%;

                input[type="text"] {
                    border: none !important;
                    border-bottom: solid 1px #dcdcdc !important;
                    border-radius: 0;
                    background: transparent;
                    width: 100%;
                    position: relative;
                    padding: 0 42px 0 16px;
                    height: 40px;
                    margin: 0 !important;
                }

                #search-icon {
                    width: 40px;
                    right: 0 !important;
                    height: 40px;
                    background: transparent !important;
                }
             }

             .overflow-select-segment {
                 max-height: calc(100vh - 374px);
                 min-height: calc(100vh - 374px);
                 overflow-y: auto;

                 .select-content {
                     img {
                         margin: 0 0 20px 0;
                     }

                     &:nth-child(1) {
                         border-top-left-radius: 0px;
                         border-top-right-radius: 0px;
                     }

                     &:nth-last-child(1) {
                        //  border-bottom-left-radius: 8px;
                        //  border-bottom-right-radius: 8px;
                     }

                     &.checkbox-all {
                         border-bottom: solid 1px #dcdcdc;
                     }

                     &.not-found {
                         text-align: center;
                         color: rgba(25, 25, 25, 0.4);
                         background: #fff !important;
                         position: absolute;
                         top: 50%;
                         left: 50%;
                         width: 100%;
                         transform: translate(-50%, -50%);

                         p { 
                            padding: 0 40px;
                         }
                     }

                     &.custom-checkbox-stripe [type="checkbox"]:checked + label:after {
                        top: 2px;
                        left: 0px;
                        width: 16px;
                        height: 16px;
                     }

                     &.custom-checkbox [type="checkbox"]:checked + label:after {
                        top: -3px;
                        left: -5px;
                        // width: 16px;
                        // height: 16px;
                     }
                 }
             }
         }

         .select-segment-wrapper {
             width: 100%;
             height: calc(100vh - 333px);
             position: relative;
            
             .selected-segment-container {
                 width: 100%;
                 min-height: 100%;
                 overflow-y: auto;
                 max-height: 100%;
                 border: solid 1px #dcdcdc;
                 border-radius: 8px;
                 padding: 16px 16px 8px 16px;

                 .no-selected-segment {
                     display: flex;
                     flex-direction: column;
                     align-items: center;
                    //  margin-top: 108px;
                     position: absolute;
                     top: 50%;
                     left: 50%;
                     transform: translate(-50%, -50%);
                     width: 100%;

                     img {
                         margin-bottom: 20px;
                     }

                     p {
                         color: rgba(25, 25, 25, 0.4);
                         font-size: 14px;
                         line-height: 21px;
                         letter-spacing: -0.2px;
                     }
                 }
             }
         }
    }

    .contact-segment-button-wrapper {
        text-align: right;
        width: 100%;
        margin-top: 20px;

        button {
            margin: 0 0 0 8px;
            width: 181px;
        }
    }
}

.modal-confirm-contact-segment {
    .modal-content {
        width: 476px;

        button {
            width: calc(50% - 2px) !important;
            margin-right: 4px !important;

            &:nth-last-child(1) {
                margin: 0 !important;
            }
        }
    }
}