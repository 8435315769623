.case-shared-media-wrapper {
    width: 100vw;
    height: 100vh;
    background: rgba(25, 25, 25, 0.8);
    position: fixed;
    top: 0;
    /* right: -100vw; */
    visibility: hidden;
    opacity: 0;
    right: 0;
    /* transition: visibility 0s, opacity 0.3s ease-in-out; */
    transition: visibility .3s, opacity 0.5s ease-in-out;
    z-index: 4;

    &.active-case-shared-media {
        opacity: 1;
        visibility: visible;
        -webkit-transition: visibility 0s, opacity 0.5s ease-in-out;
        transition: visibility 0s, opacity 0.5s ease-in-out;
    }

    .case-shared-media-main-wrapper {
        width: 540px;
        transition: ease-in-out 0.5s;
        background: #ffffff;
        height: calc(100vh - 8px);
        border-top-left-radius: 18px;
        bottom: 0;
        right: -405px;
        position: fixed;
        
        &.active-case-shared-media-main-wrapper {
            right: 0;
        }

        .case-shared-media-close {
            background: #ffffff;
            border-radius: 50%;
            cursor: pointer;
            position: absolute;
            width: 48px;
            height: 48px;
            display: inline-block;
            text-align: center;
            line-height: 45px;
            top: 0;
            left: -64px;

            svg {
                font-size: 24px;
                color: #7D7C7D;
            }
        }

        .title-case-shared-media {
            font-size: 24px;
            letter-spacing: -0.6px;
            padding: 24px;
            margin: 0;
        }

        .case-shared-media-list-wrapper {
            // padding: 20px;
            width: 100%;
            height: calc(100% - 85px);
            max-height: calc(100% - 85px);

            .loading-case-action {
                text-align: center;
                color: #ff7d00;
                padding-top: 12px;

                .lds-ring {
                    vertical-align: top;
                    top: 3px;
                    right: unset;
                    margin-right: 4px;

                    div {
                        margin: 0;
                    }
                }
            }

            .on-error {
                text-align: center;
                color: #ff7d00;
                
                svg {
                    position: relative;
                    margin-right: 4px;
                    top: -1px;
                }

                p {
                    cursor: pointer;
                    display: inline-block;

                    &:hover {
                        color: #e87200;
                    }
                }

            }

            .shared-media-tab-wrapper {
                padding: 12px 0 0;
                
                .shared-media-tab {
                    border-bottom: solid 1px #dcdcdc;
                    text-align: center;
                }

                .shared-media-tab-option {
                    letter-spacing: 3px;
                    width: calc(50% - 12px);
                    display: inline-block;
                    vertical-align: top;
                    text-align: center;
                    font-size: 16px;
                    color: rgba(25, 25, 25, 0.4);
                    cursor: pointer;
                    padding: 0 8px 8px;

                    &:hover {
                        color: var(--main-dark-orange)
                    }

                    &.active-shared-media-tab-option {
                        border-bottom: solid 2px var(--main-dark-orange);
                        color: var(--main-dark-orange);
                    }
                }
            }
        }
    }
}