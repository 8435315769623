.chatbot-setup-page-wrapper {
    // margin: 32px 0 0;
    position: relative;

    .chatbot-setup-header {
        width: 100%;
        position: relative;
        display: block;
        padding: 0 0 12px 0;
        border-bottom: solid 1px #dcdcdc;
        margin: 0 0 24px 0;

        button {
            position: absolute;
            top: -5px;
            right: 0px;
        }
    }

    .chatbot-setup-header-title {
        font-size: 20px;
        letter-spacing: -0.6px;
        color: #191919;
    }

    .chatbot-setup-header-last {
        margin: 0;
        font-size: 14px;
        letter-spacing: -0.2px;
        color: rgba(25, 25, 25, 0.4);
    }

    .chatbot-tab-wrapper {
        display: inline-block;
        height: 38px;
        border-radius: 8px;
        white-space: nowrap;
        background: rgba(25, 25, 25, 0.1);
        padding: 2px;
        overflow: hidden;

        .chatbot-tab {
            display: inline-block;
            position: relative;
            vertical-align: middle;
            margin: 0 12px 0 0;
            padding: 2px 16px;
            height: 100%;
            border-radius: 6px;
            font-size: 16px;
            line-height: 24px;
            letter-spacing: -0.5px;
            text-align: center;
            color: #19191966;
            border: solid 1px #19191900;
            cursor: pointer;
            transition: 0.2s;

            b {
                display: block;
                transform: translateY(2px);
            }

            &:hover {
                color: #191919CC;
                transition: 0.2s;
            }

            &:active {
                border: solid 1px #1919191A;
                color: #191919CC;
                transition: 0.2s;
            }

            &.chatbot-tab-active {
                background: white;
                color: #191919;
                border: solid 1px #19191900;
                box-shadow: 0px 8px 28px 0px rgba(25, 25, 25, 0.18);

                &:hover {
                    border: solid 1px #19191900;
                }
            }
        
            &:last-child {
                margin: 0;
            }
        }
    }
}

.modal-save-changes {
    button {
        padding: 0 8px !important;
        width: calc(50% - 4px);
        
        &:last-child {
            margin-left: 8px;
        }
    }
}

.padding-24 {
    padding: 24px;
}
