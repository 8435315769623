.main-integration-google-create {
    .integration-google-add-box {
        background: #ffffff;
        border-radius: 8px;
        padding: 32px;
        border: solid 1px rgba(25, 25, 25, 0.1);
        margin-top: 24px;

        .integration-goggle-add-title {
            font-size: 20px;
            letter-spacing: -0.6px;
            color: #191919;
            padding: 0 0 12px 0;
            border-bottom: solid 1px #dcdcdc;
            margin: 0 0 24px 0;
            display: block;
        }
        .integration-google-add-account-main {
            margin: 34px 0 0;
            width: 100%;
            display: block;

            label {
                font-size: 14px;
                letter-spacing: -0.4px;
                margin: 0 0 8px 0;
                display: block;

                p {
                    font-size: 14px;
                    letter-spacing: -0.2px;
                    color: rgba(25, 25, 25, 0.8);
                    margin: 4px 0 0;
                }
            }
        }
    }

    .comp-back-button {
        margin: 0 !important;
    }

    input {
        padding: 12px 16px;
        font-size: 16px;
        letter-spacing: -0.3px;
        border-radius: 8px;
        border: solid 1px #dcdcdc;
    }

    textarea {
        padding: 12px 16px;
        font-size: 16px;
        letter-spacing: -0.3px;
        border-radius: 8px;
        border: solid 1px #dcdcdc;
    }
}