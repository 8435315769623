.preview-broadcast-message-wrapper {
    .form-content-wrapper {
        .variable-title {
            font-size: 16px;
            padding-bottom: 6px;
            border-bottom: solid 1px #dcdcdc;
            margin-bottom: 16px;
        }


        button {
            width: unset;
        }
    }

    .media-upload-wrapper {
        .media-upload-wrapper-left {
            width: 200px;
            display: inline-block;
            vertical-align: top;
            position: relative;

            label {
                margin: 0;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                padding-right: 12px;
                width: 100%;
            }
        }

        input[type="file"] {
            display: none;
        }

        .media-upload-wrapper-right {
            width: calc(100% - 200px);
            display: inline-block;
            vertical-align: top;

            .input-param-wrapper {
                width: 100%;

                button {
                    width: 100%;
                }
            }

            button {
                margin: 0;
                text-transform: capitalize;
                width: unset;
            }

            .upload-info {
                font-size: 12px;
                letter-spacing: -0.4px;
                margin-top: 10px;

                .replace-media {
                    font-size: 12px;
                    letter-spacing: -0.4px;
                    border-radius: 4px;
                    padding: 2px 12px 2px 8px;
                    width: unset;
                    text-transform: capitalize;
                    // position: absolute;
                    // top: 0;
                    // left: 42px;
                    display: block;
                    margin: 0 0 8px 0;
    
                    svg {
                        position: relative;
                        top: -1px;
                        margin-right: 4px;
                    }
                }

                svg {
                    position: relative;
                    margin-right: 4px;
                    top: -1px;
                }
            }

            .image-video-wrapper {
                width: 48px;
                height: 48px;
                position: relative;
                border-radius: 6px;
                overflow: hidden;
    
                .progress-wrapper {
                    position: absolute;
                    top: 0;
                    left: 0;
                    background: rgba(25, 25, 25, 0.4);
                    width: 100%;
                    height: 100%;
    
                    .CircularProgressbar {
                        width: calc(100% - 12px);
                        height: calc(100% - 12px);
                        position: absolute;
                        top: 6px;
                        left: 6px;
    
                        .CircularProgressbar-trail {
                            stroke: transparent;
                            stroke-width: 20;
                        }
                        
                        .CircularProgressbar-path {
                            stroke: #ffffff;
                        }
                    } 
                }
    
                img {
                    width: 100%;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    max-width: 100%;
                    max-height: 100%;
                    object-fit: contain;
                }
    
                video {
                    width: 100%;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    max-width: 100%;
                    max-height: 100%;
                    object-fit: contain;
                }
            }

            .document-wrapper {
                font-size: 14px;
                letter-spacing: -0.2px;


                svg {
                    color: #ff7d00;
                    font-size: 16px;
                    margin-right: 4px;
                    position: relative;
                    top: -2px;    
                }
            }
        }
    }
    .preview-message-param {
        margin: 24px 0 40px;
        height: 48px;
        line-height: 48px;
        position: relative;

        label {
            vertical-align: -10px;
            width: 188px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .input-param-wrapper {
            position: absolute;
            right: 0;
            width: 336px;
            max-width: calc(100% - 204px);
            height: 48px;
            left: 200px;
            top: 0px;
            background: #ffffff;
            box-sizing: border-box;

            .custom-select-option-value-wrapper {
                margin-top: 0;
            }
        }

        .input-info-text {
            margin: 0;
            width: 100%;
            position: absolute;
            left: 0;
            top: 52px;
            line-height: 1;

            svg {
                top: 0px;
            }
        }

        input {
          padding: 12px 16px;
          width: 336px;
          width: 100%;
          height: 48px;
          background: #ffffff;
          border: 1px solid rgba(25, 25, 25, 0.1);
          box-sizing: border-box;
          border-radius: 8px;
        }

        input:focus {
          border: 1px solid #ff7e00;
        }
    }

    .form-title-wrapper {
        border: none;
        padding-bottom: 12px;
        position: relative;

        svg {
            position: relative;
            top: -1px;
            margin-right: 4px;
        }

        .send-message-button {
            font-size: 16px;
            letter-spacing: -0.5px;
            position: absolute;
            top: 0;
            right: 0;
        }
    }

    .agree-wrapper {
        width: calc(100% - 210px);
        display: inline-block;
        position: relative;
        padding-left: 24px;

        .custom-checkbox {
            position: absolute;
            top: 0;
            left: 0;

            label {
                margin: 0;
            }
        }

        p {
            font-size: 14px;
            letter-spacing: -0.2px;
            line-height: 21px;
            color: #000000;
            text-align: left;
            margin: 0;
        }

        b {
            color: #ff7d00;
            cursor: pointer;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    .broadcast-title-time {
        font-size: 16px;
        letter-spacing: -0.5px;
        margin: 24px 0 0;
    }

    .chatroom-preview-wrapper {
        border-radius: 8px;
        width: 100%;
        box-shadow: 0 2px 20px 0 rgba(25, 25, 25, 0.05);
        background: #F3F3F3;
        margin-top: 20px;
        margin-bottom: 20px;

        .chatroom-preview-container {
            border-top-left-radius: 8px;
            border-top-right-radius: 8px;
            padding: 16px;
            max-height: 400px;
            overflow: auto;

            .info-message-bubble-wrapper {
                width: 100%;
                text-align: center;

                .info-message-bubble {
                    width: 98px;
                    height: 24px;
                    position: relative;
                    box-shadow: 0 2px 20px 0 rgba(25, 25, 25, 0.05);
                    background: #ffffff;
                    border-radius: 4px;
                    display: inline-block;

                    div {
                        width: 50px;
                        height: 14px;
                        background: rgba(25, 25, 25, 0.1);
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        border-radius: 2px;
                    }
                }
            }

            .left-message-bubble-wrapper {
                width: 100%;
                text-align: left;
                position: relative;
                margin-top: 16px;

                .left-message-bubble {
                    .user-wrapper {
                        position: relative;
                        color: #ffffff;
                        border-radius: 50%;
                        width: 32px;
                        background: #9c89f1;
                        height: 32px;
                        margin-right: 8px;
                        display: inline-block;
                        vertical-align: top;

                        svg {
                            font-size: 20px;
                            position: absolute;
                            left: 50%;
                            top: 50%;
                            transform: translate(-50%, -50%);
                        }
                    }

                    .left-message-chat-bubble {
                        border-radius: 12px;
                        border-top-left-radius: 0;
                        border: solid 1px rgba(25, 25, 25, 0.1);
                        max-width: calc(100% - 40px);
                        display: inline-block;
                        background: #ffffff;
                        padding: 10px;
                        width: 405px;

                        div {
                            border-radius: 4px;
                            height: 14px;

                            &:nth-child(1) {
                                background: rgba(25, 25, 25, 0.2);
                                width: 80px;
                                margin-bottom: 4px;
                            }

                            &:nth-child(2) {
                                background-color: rgba(25, 25, 25, 0.1);
                                width: 100%;
                            }
                        }
                    }
                }
            }
        }

        .right-message-bubble-wrapper {
            width: 100%;
            text-align: right;
            position: relative;
            margin-top: 16px;
            color: #ffffff;

            .right-message-bubble {
                background: #ff7d00;
                border-radius: 12px;
                border-top-right-radius: 0;
                max-width: 80%;
                max-width: 100%;
                padding: 10px;
                position: relative;
                display: inline-block;
                text-align: left;

                &.file-bubble {
                    .file-wrapper {
                        width: 48px;
                        height: 48px;
                        background: #ffffff;
                        border-radius: 50%;
                        display: inline-block;
                        color: #ff7d00;
                        position: relative;

                        svg {
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                            font-size: 20px;
                        }
                    }

                    .file-info-wrapper {
                        width: calc(100% - 48px);
                        display: inline-block;
                        padding-left: 8px;
                        position: relative;
                        top: 3px;
                        vertical-align: top;
                    }

                    .filename-text {
                        font-size: 14px;
                        letter-spacing: -0.4px;
                        margin: 0 0 2px 0;
                        max-width: 100%;
                        white-space: nowrap;
                        overflow-x: hidden;
                        text-overflow: ellipsis;
                    }

                    .filezise-filetype {
                        font-size: 14px;
                        letter-spacing: -0.2px;
                        margin: 0;
                        text-transform: uppercase;
                        max-width: 100%;
                        white-space: nowrap;
                        overflow-x: hidden;
                        text-overflow: ellipsis; 
                    }
                }

                &.bubble-media {
                    max-width: 363px;
                }

                .preview-document-wrapper {
                    .download-wrapper {
                        background: #ffffff;
                        position: relative;
                        width: 48px;
                        height: 48px;
                        border-radius: 50%;
                        display: inline-block;
                        vertical-align: top;

                        svg {
                            color: #ff7d00;
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                            font-size: 26px;
                        }
                    }

                    .file-info-wrapper {
                        width: calc(100% - 48px);
                        display: inline-block;
                        padding-left: 8px;
                        padding-top: 4px;

                        &.preview-without-file {
                            padding-top: 15px;
                        }

                        .file-name {
                            margin: 0;
                            font-size: 14px;
                            letter-spacing: -0.4px;
                            white-space: nowrap;
                            max-width: 100%;
                            text-overflow: ellipsis;
                            overflow: hidden;
                        }

                        .file-size {
                            margin: 0;
                            font-size: 14px;
                            letter-spacing: -0.2px;
                            white-space: nowrap;
                            max-width: 100%;
                            text-overflow: ellipsis;
                            overflow: hidden;
                            text-transform: uppercase;
                        }
                    }
                }   

                .preview-image-wrapper {
                    width: 343px;
                    // height: 343px;
                    background: #8F4700;
                    position: relative;
                    border-radius: 12px;
                    display: inline-block;
                    margin-bottom: 2px;
                    overflow: hidden;
                    font-size: 0;

                    &.no-background {
                        background: transparent;
                    }

                    img {
                        width: 100%;
                    }
                }

                .preview-video-wrapper {
                    width: 343px;
                    // height: 343px;
                    background: #8F4700;
                    position: relative;
                    border-radius: 12px;
                    display: inline-block;
                    margin-bottom: 2px;
                    font-size: 0;
                    overflow: hidden;

                    &.no-background {
                        background: transparent;
                    }

                    video {
                        width: 100%;
                    }

                    .play-wrapper {
                        border-radius: 50%;
                        width: 48px;
                        height: 48px;
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        background: rgba(25, 25, 25, 0.4);

                        svg {
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                            color: #ffffff;
                            font-size: 26px;
                        }
                    }
                }

                .preview-placeholder {
                    // position: absolute;
                    // left: 50%;
                    // top: 50%;
                    // transform: translate(-50%, -50%);
                    width: 100%;
                    margin: 100px 0;
                    text-align: center;
                    font-size: 14px;
                    letter-spacing: -0.4px;

                    svg {
                        font-size: 26px;
                        margin-bottom: 4px;
                    }
                }

                .message-body {
                    font-size: 16px;
                    line-height: 24px;
                    letter-spacing: -0.3px;
                    margin: 0;
                }

                .message-info {
                    margin: 0;
                    font-size: 10px;
                    letter-spacing: -0.4px;
                    line-height: 18px;
                    width: 100%;
                    text-align: right;
                    
                    .centered-dot {
                        width: 2px;
                        height: 2px;
                        display: inline-block;
                        vertical-align: top;
                        background: #ffffff;
                        border-radius: 50%;
                        position: relative;
                        top: 9px;
                    }

                    img {
                        margin-left: 2px;
                    }
                }

                &.image-bubble {
                    max-width: 300px;
                    padding: 10px;

                    .bubble-image-wrapper {
                        position: relative;
                        min-width: 150px;
                        
                        .image-content {
                            vertical-align: top;
                            width: 100%;
                            max-width: 100%;
                            border-radius: 16px 0 16px 16px;
                            margin: 0 0 2px 0;
                        }
                    }

                    .message-body {
                        padding: 0 6px;
                    }

                    .message-info {
                        padding: 0 6px;
                    }
                }

                &.without-caption {
                    .image-content {
                        border-radius: 16px 0 16px 16px !important;
                    }

                    .message-info {
                        background: rgba(25, 25, 25, 0.6);
                        display: inline-block;
                        width: unset;
                        padding: 4px 8px;
                        border-radius: 12px;
                        position: absolute;
                        right: 10px;
                        bottom: 10px;
                    }
                }
            }
        }

        .input-text-wrapper {
            height: 56px;
            background: #ffffff;
            width: 100%;
            position: relative;
            padding: 8px 12px;
            border-bottom-left-radius: 8px;
            border-bottom-right-radius: 8px;
            box-shadow: 0 2px 20px 0 rgba(25, 25, 25, 0.05);

            .input-text-container {
                width: calc(100% - 44px);
                display: inline-block;
                border-radius:108px;
                border: solid 1px rgba(25, 25, 25, 0.1);
                height: 40px;
                position: relative;

                div {
                    width: 160px;
                    height: 16px;
                    border-radius: 4px;
                    background: rgba(25, 25, 25, 0.05);
                    position: absolute;
                    top: 50%;
                    left: 16px;
                    transform: translate(0, -50%);
                    max-width: calc(100% - 40px);
                }

                svg {
                    color: #a3a3a3;
                    font-size: 20px;
                    position: absolute;
                    right: 16px;
                    top: 50%;
                    transform: translate(0, -50%);
                }
            }

            .submit-button-airplane {
                position: absolute;
                width: 32px;
                height: 32px;
                background: #a3a3a3;
                top: 50%;
                right: 12px;
                transform: translate(0, -50%);
                border-radius: 50%;

                img {
                    width: 22px;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }
            }
        }
    }

    .custom-variable-wrapper {
        label {
            width: 200px;
            display: inline-block !important;
        }

        .input-param-wrapper {
            width: calc(100% - 200px);
            display: inline-block;
        }

        button {
            width: 100%;
            margin: 0;
            height: 44px;
            line-height: 1;
        }
    }

    .submit-send-message {
        position: absolute;
        right: 32px;
        height: 40px;
        line-height: 35px;
        padding: 0;
        top: 0;
        width: 175px;

        img {
            position: relative;
            width: 16px;
            margin-right: 4px;
            top: -1px;
        }
    }
}