.form-file-message-wrapper-new-conversation {
    position: relative;
    width: 100%;

    input[type="file"] {
        display: none;
    }

    label {
        display: inline-block !important;

        span {
            color: rgba(25, 25, 25, 0.6);
        }

        &.button-upload-file {
            margin: 0 8px 0 0;
            color: rgba(25, 25, 25, 0.6);
            border: solid 1px rgba(25, 25, 25, 0.1);
            letter-spacing: -0.4px;
            font-size: 12px;
            border-radius: 4px;
            cursor: pointer;
            display: inline-block !important;
            padding: 2px 8px;
            background: #ffffff;
            -webkit-transition: ease-in-out .1s;
            transition: ease-in-out .1s;

            &:hover {
                color: #ffffff;
                border: solid 1px #ff7d00;
                background: #ff7d00;
            }

            svg {
                position: relative;
                top: -1px;
                margin-right: 4px;
            }
        }

        &.button-add-var {
            margin: 0 8px 0 0;
            color: rgba(25, 25, 25, 0.6);
            border: solid 1px rgba(25, 25, 25, 0.1);
            letter-spacing: -0.4px;
            font-size: 12px;
            border-radius: 4px;
            cursor: pointer;
            display: inline-block !important;
            padding: 2px 8px;
            background: #ffffff;
            -webkit-transition: ease-in-out .1s;
            transition: ease-in-out .1s;

            &:hover {
                color: #ffffff;
                border: solid 1px #ff7d00;
                background: #ff7d00;
            }

            svg {
                position: relative;
                top: -1px;
                margin-right: 4px;
            }
        }
    }

    .file-and-caption-wrapper {
        position: relative;
        width: 100%;
        // width: 100%;
        max-width: 100%;
        font-size: 0;
        margin: 0 0 6px 0;

        textarea {
            padding: 72px 16px 12px 16px !important;
            height: 178px !important;
        }   

        .broadcast-message-action-wrapper {
            position: absolute;
            height: 48px;
            border: solid 1px #dcdcdc;
            border-bottom-left-radius: 8px;
            border-bottom-right-radius: 8px;
            background: #ffffff;
            bottom: 0;
            left: 0;
            font-size: 12px;
            letter-spacing: -0.4px;
            display: block;
            width: 100%;
            padding: 12px 16px;
        }
        
        .file-preview-wrapper {
            background: #ffffff;
            position: absolute;
            top: 1px;
            left: 16px;
            padding: 11px 0 0 0;
            // border-bottom: solid 1px rgba(25, 25, 25, 0.1);
            width: calc(100% - 32px);

            svg {
                font-size: 64px;
                position: relative;
                left: -5px;
                color: #9b9b9b;
            }

            .file-preview-frame {
                // border: solid 1px rgba(25, 25, 25, 0.1);
                // width: 64px;
                // height: 64px;
                display: inline-block;
                position: relative;
                border-radius: 8px;
                overflow: hidden;

                .file-progress-upload-wrapper {
                    font-size: 14px;
                    letter-spacing: -0.2px;
                    color: #191919;
                    margin: 0;
                    max-width: 100%;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: inline-block;
                    position: relative;
                    padding-right: 22px;

                    svg {
                        left: unset;
                        top: -2px;
                        font-size: 16px;
                        letter-spacing: -0.2px;
                        margin-right: 4px;

                        &.remove-file {
                            top: -1px;
                            color: #ff7d00;
                            margin-left: 4px;
                            cursor: pointer;
                            position: absolute;
                            right: 0;
                            top: 50%;
                            transform: translate(0, -50%);
                            margin: 0;

                            &:hover {
                                color: #e83b51;
                            }
                        }
                    }
                }

                img {
                    max-width: 100%;
                    max-height: 100%;
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                }

                // .progress-wrapper {
                //     position: relative;
                //     top: 3px;
                //     right: 0;
                //     margin-right: 4px;
                //     display: inline-block;
                //     // transform: translate(0, -50%);
                //     background: #ffffff;
                //     width: 16px;
                //     height: 16px;

                //     .CircularProgressbar {
                //         width: 100%;
                //         height: 100%;
                //         position: absolute;
                //         top: 0;
                //         left: 0;
                        
                //         .CircularProgressbar-trail {
                //             stroke: transparent;
                //             stroke-width: 20;
                //         }
                        
                //         .CircularProgressbar-path {
                //             stroke: #ff7d00;
                //         }
                //     } 
                // }

                .file-button-wrapper {
                    position: absolute;
                    top: 0;
                    left: 0;
                    background: rgba(25, 25, 25, 0.4);
                    width: 100%;
                    height: 100%;
                    display: none;

                    .close-button-wrapper {
                        width: 16px;
                        height: 16px;
                        border-radius: 50%;
                        background: #636462;
                        text-align: center;
                        font-size: 7px;
                        color: #ffffff;
                        cursor: pointer;
                        position: absolute;
                        top: 4px;
                        right: 4px;

                        &:hover {
                            background: #ff3f57;
                        }
                    }

                    svg {
                        &.preview-button {
                            font-size: 20px;
                            color: #ffffff;
                            cursor: pointer;
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                        }   

                        &.close-button {
                            color: #ffffff;
                            font-size: 12px;
                            position: absolute;
                            left: 50%;
                            top: 50%;
                            transform: translate(-50%, -50%);
                        }
                    }
                }
                
                &:hover {
                    .file-button-wrapper {
                        display: inline-block;
                    }
                }
            }
        }

        .placeholder-file {
            font-size: 14px;
            color: rgba(25, 25, 25, 0.6);
            height: 92px;
            border: solid 1px #dcdcdc;
            border-radius: 8px;
            padding: 12px 16px;
            // background: #e8e8e8;
        }

        textarea {
            padding: 48px 16px 60px 16px;
            height: 168px !important;

            &[disabled] {
                background: #e8e8e8;
                cursor: not-allowed;

                padding: 12px 16px;
                height: 138px !important;
            }
        }

        .label-upload-file {
            border: dotted 2px #ff7d00;
            cursor: pointer;
            margin: 0;
            width: 300px;
            border-radius: 8px;
            padding: 8px 0;
            text-align: center;
            font-size: 16px;
            letter-spacing: -0.5px;
            transition: ease-in-out .1s;
            color: #ff7d00;

            svg {
                position: relative;
                top: -2px;
                margin-right: 4px;
            }

            &:hover {
                border: dotted 2px #e87200;
                color: #e87200;
            }
        }

        .file-result-wrapper-outer {
            width: 100%;
            background: #fff;
            position: absolute;
            width: calc(100% - 32px);
            left: 16px;
            // top: 16px;
            top: 1px;
            padding-top: 16px;

            &.file-result-wrapper-outer-disabled {
                background: #e8e8e8;
            }
        }
        
        .file-result-wrapper {
            width: 100%;
            font-size: 14px;
            letter-spacing: -0.2px;
            background: #e3e3e3;
            border-radius: 8px;
            padding: 15px 98px 15px 46px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            position: relative;

            .progress-wrapper {
                position: absolute;
                top: 50%;
                transform: translate(0, -50%);
                left: 10px;
                margin-right: 4px;
                display: inline-block;
                // transform: translate(0, -50%);
                // background: #ffffff;
                width: 32px;
                height: 32px;

                .CircularProgressbar {
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    top: 0;
                    left: 0;
                    
                    .CircularProgressbar-trail {
                        stroke: transparent;
                        stroke-width: 20;
                    }
                    
                    .CircularProgressbar-path {
                        stroke: #ff7d00;
                    }
                } 
            }

            .icon-file {
                font-size: 20px;
                position: absolute;
                left: 17px;
                top: 50%;
                transform: translate(0, -50%);
                margin-right: 4px;
            }

            .remove-file {
                cursor: pointer;
                position: absolute;
                right: 17px;
                top: 50%;
                transform: translate(0, -50%);
                color: #FF3F57;

                svg {
                    font-size: 20px;
                    position: relative;
                    top: -1px;
                }

                &:hover {
                    color: #e83b51;
                }
            }
        }
    }
}

.modal-file-preview {
    width: 100% !important;

    .modal-content {
        width: 100%;
        height: 100%;
        background: unset;

        .modal-body {
            padding: 56px 86px;
            position: relative;

            img {
                max-width: calc(100% - 112px);
                max-height: calc(100% - 172px);
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                background: #ffffff;
            }

            svg {
                font-size: 24px;
                color: #ffffff;
                cursor: pointer;
                position: absolute;
                top: 44px;
                right: 44px;
            }
        }
    }
}