.chat-room-contact-info- {
    width: 340px;
    display: inline-block;
    transition: ease-in-out .2s;
    position: absolute;
    top: 0;
    right: -340px;
    background: #ffffff;
    vertical-align: top;
    height: 100%;
    // box-shadow: -1px 0 0 0 rgba(25, 25, 25, 0.1);
    // border-left: solid 1px #dcdcdc;
    user-select: none;
    z-index: 1;

    &.chat-room-contact-info-active {
        right: 0;
    }

    .chat-room-contact-info-title {
        font-size: 20px;
        letter-spacing: -0.6px;
        color: #191919;
        // height: 48px;
        padding: 28px 17px 12px 17px;
        width: 100%;
        display: block;
        border-bottom: solid 1px #dcdcdc;
        position: relative;
        text-align: left;
        line-height: 1;
        // border-left: solid 1px #dcdcdc;
        // margin-bottom: 151px;

        svg {
            position: relative;
            color: rgba(25, 25, 25, 1);
            cursor: pointer;
            top: -2px;
            margin-right: 4px;
        }

        &.chat-room-contact-info-title-no-border {
            border: none !important;
        }
    }

    .chat-room-contact-info-edit-wrapper {
        padding: 0 0 24px 0;
        width: 100%;
        position: relative;
        height: calc(100% - 63px);
        overflow: auto;

        .chat-room-contact-info-edit-inner {
            padding: 24px 16px 0;
            max-height: calc(100% - 64px);
            overflow: auto;

            .grey-font-6 {
                font-size: 12px;
                line-height: 16px;
                letter-spacing: -0.2px;
                position: relative;
                padding: 0 0 0 16px;
                margin: 4px 0 0;

                svg {
                    position: absolute;
                    top: 3px;
                    left: 0;
                }
            }

            .char-count {
                font-size: 12px;
                line-height: 16px;
                letter-spacing: -0.2px;
                transform: translateY(2px);
            }
            
            input[type="text"] {
                padding: 0 8px;
                width: 100%;
                border-radius: 8px;
                font-size: 14px;
                line-height: 21px;
                letter-spacing: -0.2px;
                color: rgba(25, 25, 25, 1);
                height: 40px;
                z-index: 1;
                background: transparent;
                border: solid 1px rgba(25, 25, 25, .1);
                caret-color: #FF7E00;
               
                &::placeholder {
                    color: rgba(25, 25, 25, 0.4);
                }

                &:disabled {
                    background: #E8E8E8;
                }

                &:focus {
                    border: solid 1px #ff7d00;
                }
            }

            .contact-custom-field-separator {
              margin-top: 24px;
              width: 100%;
              height: 1px;
              background-color: #1919191A;
            }
            
            textarea {
                padding: 8px;
                width: 100%;
                border-radius: 8px;
                font-size: 14px;
                line-height: 21px;
                letter-spacing: -0.2px;
                color: rgba(25, 25, 25, 1);
                height: 40px;
                min-height: 40px;
                // max-height: 80px;
                z-index: 1;
                background: transparent;
                border: solid 1px rgba(25, 25, 25, .1);
                caret-color: #FF7E00;
                resize: none;
               
                &::placeholder {
                    color: rgba(25, 25, 25, 0.4);
                }

                &:disabled {
                    background: #E8E8E8;
                }

                &:focus {
                    border: solid 1px #ff7d00;
                }

                &::-webkit-scrollbar {
                    width: 12px;
                }
                
                &::-webkit-scrollbar-thumb {
                    border: 4px solid #00000000;
                    border-radius: 8px;
                    background-color: #1919194D;
                    background-clip: padding-box;
                }
            }

            .contact-date-value-wrapper {
                max-width: 400px;
                border-radius: 8px;
                height: 40px;
                width: 100%;
                position: relative;
                padding: 0 40px 0 8px;
                border: solid 1px #1919191A;
                line-height: 38px;
                font-size: 14px;
                letter-spacing: -0.2px;
                cursor: pointer;
        
                &:hover {
                    border-color: #ff7e00;
                }
        
                svg {
                    position: absolute;
                    font-size: 16px;
                    right: 8px;
                    top: 50%;
                    transform: translate(0, -50%);
                    color: #FF7E00;
                }
            }

            .dropdown {
        
              button {
                padding: 0 40px 0 8px !important;
                margin-top: 0px;
                height: 40px;

                p {
                    line-height: 21px;
                    font-size: 14px;
                    letter-spacing: -0.2px;
                }
        
                svg {
                    font-size: 16px;
                    right: 8px;
                    color: #FF7E00 !important;
                }
              }
        
              .dropdown-option-wrapper {
                max-height: none;
                border: none;
        
                button {
                    padding: 0 8px 0 36px !important;
                    margin-top: 0px;
                    height: 48px;
                }
              }
            }
        }

        label {
            font-size: 14px;
            letter-spacing: -0.4px;
            color: rgba(25, 25, 25, .8);
            margin: 24px 0 2px 0;
            width: 100%;

            span {
                color: rgba(25, 25, 25, 0.4);
            }
        }

        .edit-contact-info-input-wrapper {
            position: relative;
            width: 100%;
            height: 40px;
            margin: 0;

            &.edit-contact-phone {
                margin: 0;
            }
            
            input[type="text"] {
                padding: 0 8px 0 32px;
                width: 100%;
                border-radius: 8px;
                font-size: 14px;
                letter-spacing: -0.4px;
                color: rgba(25, 25, 25, 1);
                height: 40px;
                position: absolute;
                left: 0;
                top: 0;
                z-index: 1;
                background: transparent;
                border: solid 1px rgba(25, 25, 25, .1);
                caret-color: #FF7E00;
               
                &::placeholder {
                    color: rgba(25, 25, 25, 0.4);
                }

                &:disabled {
                    background: #E8E8E8;
                }

                &:focus {
                    border: solid 1px #ff7d00;
                }
            }

            img, svg {
                position: absolute;
                left: 8px;
                top: 50%;
                transform: translate(0, -50%);
                z-index: 2;
                font-size: 16px;
            }
        }

        .edit-contact-button {
            width: 100%;
            padding: 24px 16px 24px 16px;
            position: absolute;
            bottom: 0;
            left: 0;
            
            button {
                width: calc(50% - 4px);
                margin-right: 8px;
                vertical-align: top;

                &:nth-last-child(1) {
                    margin: 0;
                }
            }
        }
    }
}

.modal-confirmation-edit-contact {
    button {
        width: calc(50% - 4px) !important;
        margin-right: 8px;

        &:nth-last-child(1) {
            margin: 0;
        }
    }
}