.broad-cast-form-csv-wrapper {
    position: relative;
    // width: 494px;
    width: 100%;
    max-width: 100%;

    .border-red {
        .react-select-broadcast__control {
            border: solid 1px var(--watermelon) !important;
        }
    }

    .input-info-template-name {
        margin: 8px 0 0;
    }

    .contact-selection-selection {
        vertical-align: top;
        border-radius: 8px;
        border: solid 1px #dcdcdc;
        display: inline-block;
        position: relative;
        min-width: 169px;
        margin-right: 9px;
        font-size: 14px;
        letter-spacing: -0.2px;
        padding: 14px 10px 14px 45px;
        cursor: pointer;
        transition: ease-in-out .1s;
    
        &:nth-last-child(1) {
          margin-right: 0;
        }
    
        &.active-contact-selection-selection {
          border-color: #ff7d00;
        }
    
        &:hover {
          border-color: #ff7d00;
        }
    }
    
    .contact-selection-icon-wrapper {
        width: 28px;
        height: 28px;
        border-radius: 50%;
        position: absolute;
        top: 50%;
        left: 10px;
        transform: translate(0, -50%);
    
        &.contact-selection-contact {
          background: #FFD4CC;
        }
        
        &.contact-selection-segment {
            background: #EBF7FF;
        }
    
        img {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
    }

    .react-select-broadcast__control {
        input {
            height: unset;
        }
    }

    input[type='file'] {
        display: none;
    }

    label {
        display: inline-block !important;
    }

    .button-import-csv {
        margin: 0 4px;
        color: #ff7d00;
        letter-spacing: -0.4px;
        font-size: 12px;
        border-radius: 4px;
        cursor: pointer;
        display: inline-block;
        padding: 2px 8px;
        background: rgba(255, 126, 0, 0.1);
        transition: ease-in-out .1s;
        
        svg {
            font-size: 12px;
            margin-right: 4px;
            position: relative;
            top: -1px;
        }

        &:hover {
            background: #ff7d00;
            color: #ffffff;
        }
    }

    // .button-download-csv {
    //     margin: 0;
    //     color: #ff7d00;
    //     letter-spacing: -0.4px;
    //     font-size: 12px;
    //     border-radius: 4px;
    //     cursor: pointer;
    //     display: inline-block;
    //     padding: 4px 8px;
    //     transition: ease-in-out .1s;

    //     svg {
    //         font-size: 12px;
    //         margin-right: 4px;
    //         position: relative;
    //         top: -1px;
    //     }

    //     &:hover {
    //         background: #ff7d00;
    //         color: #ffffff;
    //     }
    // }

    .phone-number-counter {
        letter-spacing: -0.2px;
        font-size: 14px;
        color: rgba(25, 25, 25, 0.6);
        margin-left: 4px;
        // position: absolute;
        // right: 0;
        // top: 28px;
    }

    .phone-number-container {
        position: relative;
        border: solid 1px #dcdcdc;
        border-radius: 8px;
        width: 100%;
        padding: 53px 16px 12px 16px;
        height: 96px;
        overflow: auto;
        margin-top: 2px;
        min-height: 229px;

        &.padding-top-12 {
            padding: 12px 16px !important;

            .empty-contact {
                top: 50%;
                transform: translate(-50%, -50%) !important;
            }
        }

        &.empty-contact-container {
            padding: 12px 16px 12px 16px;
            background: #e8e8e8;
        }

        .empty-contact {
            letter-spacing: -0.3px;
            font-size: 16px;
            color: rgba(25, 25, 25, 0.4);
            line-height: 24px;
            position: absolute;
            top: 126px;
            left: 50%;
            display: inline-block;
            transform: translate(-50%, 0);
            white-space: nowrap;
            margin: 0;
        }

        .selected-contact-list-wrapper {
            margin: 16px 0 0;

            .contact-item {
                display: inline-block;
                border-radius: 6px;
                margin: 0 4px 4px 0;
                font-size: 14px;
                letter-spacing: -0.4px;
                color: rgba(25, 25, 25, 0.8);
                background: rgba(25, 25, 25, 0.05);
                padding: 2px 8px;
                line-height: 1;
                cursor: pointer;
                transition: ease-in-out .1s;

                &:hover {
                    background: rgba(25, 25, 25, 0.1);
                }

                .csv-icon {
                    position: relative;
                    top: -2px;
                    margin-right: 4px;
                }

                &.active-contact-item {
                    background: #FFF2E5;
                    color: #ff7d00;

                    .csv-icon {
                        color: #ff7d00;
                    }
                }
            }
        }

        .contact-remove-button {
            display: inline-block;
            color: #9c9c9c;
            font-size: 18px;
            margin-left: 4px;
            position: relative;
            top: -1px;
            cursor: pointer;

            &:hover {
                color: #818181;
            }
        }

        .recipient-search-box {
            height: 37px;
            width: 292px;
            position: absolute;
            top: 16px;
            left: 16px;
            border-radius: 8px;

            input {
                width: 100%;
                padding: 8px 36px 8px 12px;
                font-size: 14px;
                letter-spacing: -0.2px;
                height: 37px;
                border: solid 1px #dcdcdc;
                line-height: 37px;

                &[disabled] {
                    border: solid 1px rgba(25, 25, 25, 0.1);
                    background: rgba(25, 25, 25, 0.1);
                }
            }

            svg {
                position: absolute;
                right: 12px;
                top: 50%;
                transform: translate(0, -50%);
                color: #959595;
                font-size: 16px;
            }
        }
    }

    .button-add-recipient {
        font-size: 16px;
        letter-spacing: -0.5px;
        width: unset;
        padding: 7px 24px 8px;
        margin-top: 8px;
        display: inline-block;
        cursor: pointer;

        svg {
            font-size: 20px;
            position: relative;
            top: -2px;
            margin-right: 4px;
        }
    }

    .button-clear-recipient {
        font-size: 16px;
        letter-spacing: -0.5px;
        width: unset;
        padding: 7px 24px 8px;
        margin-top: 8px;
        display: inline-block;
        cursor: pointer;
        margin-left: 8px;

        svg {
            font-size: 20px;
            position: relative;
            top: -2px;
            margin-right: 4px;
        }
    }
}

.modal-add-recipient {
    width: 100% !important;

    .modal-content {
        left: unset;
        right: 0;
        top: 8px;
        transform: unset;
        width: 600px;
        max-height: calc(100% - 8px);
        position: fixed;
        min-height: calc(100% - 8px);
        border-radius: 12px 0 0 0;
        
        .modal-header {
            padding: 32px 32px 0;
            border: none;
        }

        .modal-body {
            padding: 0 32px 32px;
            max-height: calc(100% - 40px);
            overflow: auto;
            // min-height: 210px;
            // max-height: calc(100% - 240px);
        }

        .modal-footer {
            padding: 12px 24px;
            border: none;
            box-shadow: 0px 2px 20px rgba(25, 25, 25, 0.18);

            button {
                margin: 0;
                vertical-align: top;
            }
        }
    }

    .react-select-country-picker__single-value {
        div {
            .country-name {
                display: none !important;
            }

            .parenthesis {
                display: none !important;
            }
        }
    }

    .add-recipient-title {
        color: #191919;
        font-size: 20px;
        letter-spacing: -0.6px;
        margin: 0 0 24px;

        span {
            color: rgba(25, 25, 25, 0.6);
            font-size: 14px;
            letter-spacing: -0.2px;
            position: relative;
            top: -2px;
        }
    }

    .add-recipient-form-wrapper {
        width: 100%;
        margin: 0 0 14px 0;

        .form-label {
            width: 100%;
            position: relative;
            font-size: 14px;
            letter-spacing: -0.6px;
            color: #191919;

            p {
                margin: 0 8px 0 0;
                width: 142px;
                display: inline-block;
            }
        }

        .phone-number-form {
            color: #191919;
            font-size: 16px;
            letter-spacing: -0.3px;
            margin-top: 8px;

            .dropdown-menu {
                border: 1px solid rgba(0,0,0,.15);
                transform: translate3d(0px, 50px, 0px) !important;
            }

            input[type="text"] {
                width: 100%;
                padding: 12px 16px;
                border: solid 1px rgba(25, 25, 25, 0.1);
                border-radius: 8px;
                box-shadow: none;
                outline: none;

                &.error-input-message {
                    border-color: #ff3f57;
                }

                &:focus {
                    border-color: #ff7d00;
                }
            }

            .area-code-wrapper {
                position: relative;
                width: 142px;
                display: inline-block;
                margin-right: 8px;

                .dropdown {
                    .value-country-code-wrapper {
                        width: 100%;
                        text-align: left;
                        outline: none !important;
                        border-radius: 8px;
                        border: solid 1px #dcdcdc;
                        background: #ffffff;
                        height: 49px;
                        color: #191919;
                        box-shadow: none !important;
                        position: relative;
                        padding: 6px 26px 6px 12px;

                        
                        .country-flag {
                            width: 32px;
                            height: 24px;
                            display: inline-block;
                            position: relative;
                            margin-right: 6px;
                            top: -1px;
                        }

                        svg {
                            transition: ease-in-out .1s;
                            position: absolute;
                            top: 17px;
                            right: 8px;

                            &.chevron-up {
                                transform: rotate(180deg);
                            }
                        }
                    }

                    .dropdown-menu {
                        padding: 0;
                        border-radius: 8px;

                        .country-search-wrapper {
                            position: relative;
                            width: 100%;

                            input {
                                border: none !important;
                                height: 37px;
                                padding: 12px 12px 12px 36px !important;
                                font-size: 14px;
                                letter-spacing: -0.2px;
                                color: #191919;
                                border-bottom: solid 1px #dcdcdc !important;
                                border-radius: 8px 8px 0 0 !important;
                            }

                            svg {
                                position: relative;
                                color: #ff7d00;
                                position: absolute;
                                left: 12px;
                                top: 11px;
                            }
                        }

                        .country-list-select {
                            width: 320px;
                            // max-height: 170px;
                            min-height: 37px;
                            max-height: 222px;
                            overflow: auto;

                            .custom-phone-code-picker {
                                padding: 0 12px;
                                height: 37px;
                                line-height: 37px;
                                border-bottom: solid 1px #dcdcdc;
                                cursor: pointer;  
                                
                                &:nth-last-child(1) {
                                    border-bottom: none;
                                }
                    
                                img {
                                    width: 21px;
                                    height: 16px;
                                    display: inline-block;
                                    margin-right: 6px;
                                    position: relative;
                                    top: -2px;
                                }

                                svg {
                                    margin-right: 6px;
                                    position: relative;
                                    color: #ff7d00;
                                    font-size: 18px;
                                    top: -1px;
                                }
                    
                                div {
                                    display: inline-block;
                                    font-size: 14px;
                                    letter-spacing: -0.2px;
                                    line-height: 1;
                                    position: relative;
                                    vertical-align: top;
                                    top: 11px;
                    
                                    .country-name {
                                        top: 0 !important;
                                    }
                                }

                                &:hover {
                                    color: #ff7d00;
                                }

                                &.active-code-number {
                                    color: #ff7d00;
                                }
                            }

                            &.no-option {
                                text-align: center;
                                border-top: solid 1px #dcdcdc;
                                color: grey;
                                padding-top: 5px;
                            }
                        }
                    }
                }

                input[type="text"] {
                    padding: 12px 16px 12px 52px;
                }

                .country-flag-wrapper {
                    padding: 8px;
                    border: solid 1px rgba(25, 25, 25, 0.1);
                    display: inline-block;
                    height: 24px;
                    width: 32px;
                    position: absolute;
                    left: 16px;
                    top: 50%;
                    transform: translate(0, -50%);

                    svg {
                        position: absolute;
                        left: 50%;
                        top: 50%;
                        transform: translate(-50%, -50%);
                        color: rgba(25, 25, 25, 0.4);
                    }
                }
            }

            .phone-wrapper {
                width: calc(100% - 150px);
                display: inline-block;
                vertical-align: top;
                position: relative;

                p {
                    color: #ff3f57;
                    font-size: 12px;
                    letter-spacing: -0.4px;
                    margin: 4px 0 0;
                    width: 100%;
                    position: relative;
                    padding: 0 0 0 20px;
                    
                    svg {
                        margin-right: 4px;
                        position: absolute;
                        left: 0;
                        top: 1px;
                        font-size: 16px;
                    }
                }

                input[type="text"] {
                    width: calc(100% - 31px);
                    display: inline-block;
                }

                svg {
                    &.remove-number {
                        position: absolute;
                        right: 0;
                        top: 14px;
                        font-size: 24px;
                        margin-left: 6px;
                        color: #ff3f57;
                        cursor: pointer;
                        
                        &:hover {
                            color: #e83b51;
                        }
                    }
                }
            }

            &.phone-number-disabled {
                input[type="text"] {
                    background: rgba(25, 25, 25, 0.1);
                    border: none;
                    cursor: not-allowed;
                }
            }
        }

        .add-recipient-button {
            color: #ff7d00;
            background: #FFF2E5;
            font-size: 12px;
            letter-spacing: -0.4px;
            width: 85px;
            height: 22px;
            border: none;
            outline: none;
            border-radius: 4px;
            transition: none;
            margin: 10px 0 0;

            svg {
                position: relative;
                top: -1px;
            }

            &:hover {
                background: #ff7d00;
                color: #ffffff;
            }
        }
    }

    .button-submit {
        width: calc(50% - 4px);
        margin: 0 8px 0 0 !important;
        // height: 48px;

        &:nth-last-child(1) {
            margin: 0 !important;
        }
    }
}