.button-invite-member-save {
    position: absolute;
    top: 32px;
    right: 30px;
    width: 181px;
}

.sectionWrap-add-role-members {
    margin-top: 90px;
    width: calc(100% - 328px);
    display: inline-block;
    margin-right: 16px;
}

.member-list-add-member-selected {
    margin-top: 90px;
    vertical-align: top;
    width: calc(312px);
    display: inline-block;
    background: #fff;
    border-radius: 8px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    padding: 32px;

    .member-list-add-member-selected-head {
        padding-bottom: 12px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        font-size: 20px;
        letter-spacing: -0.6px;
    }

    .member-list-add-member-selected-body {
        .member-list-add-member-selected-body-no-data {
            text-align: center;
            color: rgba(25, 25, 25, 0.4);
            padding: 24px 0 0;

            p {
                width: 100%;
                margin: 20px 0 0;
            }
        }
    }
}

.back-button-add-role-member {
    position: absolute;
    top: 0;
    left: 32px;
}

.member-list-add-member-title {
    color: #191919;
    font-size: 20px;
    letter-spacing: -0.6px;
    line-height: 24px;
    padding-bottom: 8px;
    border-bottom: solid 1px rgba(25, 25, 25, 0.1);
    position: relative;
    padding-top: 10px;

    .button-invite-member {
        position: absolute;
        right: 0;
        top: 0;
    }

    p {
        margin: 2px 0 0;
        font-size: 14px;
        letter-spacing: -0.2px;
    }
}

.member-list-add-member-tool-wrapper {
    width: 100%;
    position: relative;
    margin-top: 12px;
    margin-bottom: 24px;

    .dropdown {
        height: 30px;
        width: 74px;
        position: absolute;
        top: -38px;
        right: 0;

        button {
            padding: 0 4px 0 20px !important;
            border-radius: 4px !important;
            height: 30px;
            
            svg {
                font-size: 18px;
                right: 12px !important;
                position: absolute;
                top: 15px !important;
            }
        }
    }
}

.member-list-add-member-tool-wrapper button.button-invite-member {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(0, -50%);
    /* padding: 11px 0; */
    /* width: 200px; */
}

/* .member-list-add-member-tool-wrapper button.button-invite-member svg {
    position: relative;
    top: -1px;
    margin-right: 5px;
} */

.member-list-add-member-table-wrapper {
    width: 100%;
    position: relative;
}

.table-member-list-add-member thead {
    tr {
        background: #ffffff;
        // border-top: solid 1px #dcdcdc;

        .custom-checkbox-stripe [type="checkbox"]:checked+label:after {
            top: 2px;
            left: 0;
        }

        td {
            &:nth-child(1) {
                padding-left: 44px !important;
            }

            &.col_full_name {
                width: 35%;
                position: relative !important;
                padding: 8px 8px 8px 44px !important;
            
                .custom-checkbox {
                    position: absolute !important;
                    top: 1px !important;
                    left: 12px !important;
                }
            
                .custom-checkbox-stripe {
                    position: absolute !important;
                    top: 1px !important;
                    left: 12px !important;
                }
            
                .custom-checkbox-stripe [type="checkbox"]:checked + label:after {
                    width: 16px;
                    height: 16px;
                }
            }

            &.col_full_name {
                width: unset !important;
            }
            
            &.col_alias {
                width: 200px;
            }

            &.col_email {
                width: 200px;
            }

            &.col_role {
                width: 145px !important;
            }
        }
    }
}

.table-member-list-add-member tbody td {
    background: #ffffff;
    position: relative;
    word-break: break-word;

    &:nth-child(1) {
        padding-left: 44px !important;
    }

    &.col_full_name {
        width: unset !important;
    }

    &.col_alias {
        width: 200px;
    }

    &.col_email {
        width: 200px;
    }

    &.col_role {
        width: 145px !important;
    }
}

.table-member-list-add-member button svg {
    position: relative !important;
    right: unset !important;
    top: -2px !important;
    margin-right: 5px;
}

.table-member-list-add-member .col-action-button-wrapper {
    position: absolute;
    right: 7px;
    top: 50%;
    transform: translate(0, -50%);
    /* display: none; */
}

.table-member-list-add-member .single-remove-button {
    margin-left: 16px;
    color: #ff3f57;
}

.table-member-list-add-member .single-remove-button:hover {
    color: #e83b51;
}

.table-member-list-add-member .col-action-button-wrapper .view-agent {
    color: #ff7d00;
    letter-spacing: -0.5px;
}

.table-member-list-add-member .col-action-button-wrapper .view-agent:hover {
    color: var(--main-darker-orange);
}

.table-member-list-add-member .col-action-button-wrapper img.view-agent {
    margin-right: 3px;
    position: relative;
    top: -1px;
}

.table-member-list-add-member tbody tr:hover .col-action-button-wrapper {
    /* display: block; */
}

.table-member-list-add-member .no-data-tr {
    border-bottom: none;
}

.table-member-list-add-member .no-data-column {
    padding-top: 24px;
    letter-spacing: -0.5px;
    font-size: 16px;
}

.table-member-list-add-member tbody tr:hover {
    background: #f8f8f8 !important;
}

.table-member-list-add-member .resent-invitation {
    background: none;
    border: 0;
    color: #ff7d00;
    letter-spacing: -0.5px;
    padding: 0;
}

.table-member-list-add-member .resent-invitation img {
    position: relative;
    top: -2px;
}


.table-member-list-add-member .resent-invitation:hover {
    color: var(--main-darker-orange);
}


.table-member-list-add-member .resend-invitation-loading {
    width: 150px;
}

.table-member-list-add-member .resend-invitation-loading .lds-ring {
    top: 3px;
    right: unset;
}

.table-member-list-add-member .resend-invitation-loading .lds-ring div {
    margin: 0;
}

.member-tool-wrapper {
    width: 460px;
}

.modal-confirmation-change-role {
    .modal-content {
        button {
            width: calc(50% - 4px) !important;
            margin: 0 8px 0 0;

            &:nth-last-child(1) {
                margin: 0;
            }
        }
    }
}