.webhook2-main-wrapper {
    .event-wrap {
        padding: 0 0 0 20px;
        position: relative;
        
        span {
            font-size: 16px;
            letter-spacing: -0.3px;
            color: rgba(25, 25, 25, 1);
            word-break: break-word;
        }

        p {
            font-size: 14px;
            letter-spacing: -0.2px;
            color: rgba(25, 25, 25, 0.6);
            margin: 0 0 8px 0;
            word-break: break-word;

            &:nth-last-child(1) {
                margin: 0;
            }
        }

        svg {
            position: absolute;
            top: 4px;
            left: 0;
            font-size: 16px;
        }

        .icon-check {
            color: var(--main-dark-orange);
        }

        .icon-x {
            color: var(--main-dark-red);
        }
    }
    .webhook2-main-wrapper-title-wrapper {
        position: relative;
        width: 100%;
        
        .title {
            font-size: 20px;
            letter-spacing: -0.6px;
            color: #191919;
        }

        .no-fill-button {
            position: absolute;
            right: 0;
            top: 0;
            width: 181px;
        }

        .red-button {
            top: 0;
            right: 156px;
            position: absolute;
        }
    }

    .webhook2-list-info {
        padding: 0 200px 12px 0;
        border-bottom: solid 1px rgba(25, 25, 25, 0.1);
    }
    
    .label-title {
        font-size: 16px;
        letter-spacing: -0.5px;
        margin: 24px 0 8px 0;
        color: #191919;
    }
}

.modal-remove-webhook {
    button {
        width: calc(50% - 2px) !important;
        display: inline-block;
        margin-right: 4px !important;
        
        &:nth-child(2) {
            margin-right: 0 !important;
        }
    }
}