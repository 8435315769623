.integration-wrapper {
    width: 100%;
    position: relative;

    .integration-card {
        width: calc((100% / 3) - 6px);
        margin-right: 9px;
        border-radius: 8px;
        background: #ffffff;
        padding: 24px;
        text-align: center;
        box-shadow: 0px 2px 20px 0px #191919 5%;
        display: inline-block;
        vertical-align: top;

        &:nth-last-child(1) {
            margin-right: 0;
        }

        .intergration-card-label {
            border-radius: 6px;
            color: #ffffff;
            display: inline-block;
            font-size: 12px;
            letter-spacing: -0.4px;
            padding: 0 8px;
            line-height: 1;
            line-height: 18px;
        }

        .intergration-card-image {
            margin: 26px 0 24px;
        }

        .intergration-card-name {
            color: #191919;
            font-size: 16px;
            letter-spacing: -0.5px;
        }
    }
}

.modal-assistance {
    .modal-content {
        width: 601px;
        border-radius: 8px;

        .modal-body {
            padding: 32px;

            p {
                color: #191919;
                font-size: 20px;
                letter-spacing: -0.6px;
                margin: 0 0 20px 0;

                svg {
                    font-size: 20px;
                    position: relative;
                    top: -2px;
                    margin-right: 4px;
                }
            }

            ol {
                margin: 0;
                padding: 0 0 0 18px;

                li {
                    color: #000000;
                    font-size: 14px;
                    letter-spacing: -0.2px;
                    line-height: 21px;
                    margin: 0 0 12px 0;
                    padding-left: 12px;
                     
                    img {
                        position: relative;
                        vertical-align: top;
                        top: -1px;
                    }
                }
            }

            button {
                margin: 12px 0 0;
            }
        }
    }
}