.location-entry-point-google {
    .location-entry-point-header {
        position: relative;
        
        .title-wrapper {
            width: 100%;
            border-bottom: solid 1px #dcdcdc;
            padding-bottom: 12px;
            margin-bottom: 24px;

            b {
                font-size: 20px;
                letter-spacing: -0.6px;
            }

            p {
                font-size: 14px;
                letter-spacing: -0.2px;
                margin: 0;
                color: rgba(25, 25, 25, 0.8);
            }
        }

        button {
            right: 0;
            top: 0;
            position: absolute;
        }
    }

    .location-entry-point-body {
        width: 400px;

        input[type="text"] {
            width: 100%;
            border-radius: 8px;
            border: solid 1px #dcdcdc;
            font-size: 16px;
            letter-spacing: -0.3px;
            padding: 12px 16px;
        }

        button {
            margin-top: 24px;
        }

        .info-input {
            font-size: 12px;
            letter-spacing: -0.4px;
            color: #191919;
            margin-top: 8px;
            
            svg {
                position: relative;
                top: -1px;
                margin-right: 4px;
            }

            a {
                text-decoration: none;
                color: #ff7d00;

                &:hover {
                    color: var(--main-darker-orange);
                }
            }
        }
    }

    .form-title {
        font-size: 16px;
        letter-spacing: -0.5px;
        color: #191919;
        margin: 24px 0 4px 0;
        padding: 0 80px 0 0;
        position: relative;
        width: 100%;

        span {
            color: rgba(25, 25, 25, 0.6);
            font-size: 14px;
            letter-spacing: -0.2px;
            position: absolute;
            top: 0;
            right: 0;
        }

        .custom-switch-wrapper {
            display: inline-block !important;
            width: 40px;
            height: 24px;
            position: absolute;
            top: 0;
            right: 0;
            margin: 0;
    
            
            .custom-switch.round {
                &::before {
                    width: 14px;
                    height: 14px;
                    bottom: 5px;
                    left: 4px;
                }
            }
    
            input:checked + .custom-switch:before {
                transform: translateX(18px);
            }
        }
    }

    .form-description {
        font-size: 16px;
        letter-spacing: -0.5px;
        color: rgba(25, 25, 25, 0.8);
        margin: 0;
        padding: 0 80px 0 0;
    }
}