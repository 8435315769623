.roles-list-title {
    color: #191919;
    font-size: 20px;
    letter-spacing: -0.6px;
    line-height: 24px;
    padding-bottom: 16px;
    border-bottom: solid 1px rgba(25, 25, 25, 0.1);
    position: relative;
    padding-top: 10px;

    .button-create-role {
        position: absolute;
        right: 0;
        top: 0;
        width: 181px;
    }
}

.roles-list-tool-wrapper {
    width: 100%;
    position: relative;
    margin-top: 12px;
    margin-bottom: 24px;
}

.roles-list-tool-wrapper button.button-create-role {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(0, -50%);
    /* padding: 11px 0; */
    /* width: 200px; */
}

/* .roles-list-tool-wrapper button.button-create-role svg {
    position: relative;
    top: -1px;
    margin-right: 5px;
} */

.roles-list-table-wrapper {
    width: 100%;
    position: relative;
}

.table-roles-list {
    thead {
        .dropdown {
            width: 74px;
            height: 30px;

            button {
                position: relative;
                // border-radius: 4px !important;
                height: 30px;
                line-height: 1;
            }

            svg {
                position: absolute !important;
                top: 15px !important;
                right: 6px !important;
                font-size: 18px;
            }
        }

        .custom-select-option-value-wrapper {
            height: 30px;
            padding: 0 4px 0 20px !important;

            .custom-select-option-value {
                padding: 0;
            }

            svg {
                right: 10px;
            }
        }

        tr {
            background: #ffffff;
            // border-top: solid 1px #dcdcdc;

            .custom-checkbox-stripe [type="checkbox"]:checked+label:after {
                top: 2px;
                left: 0;
                height: 16px;
                width: 16px;
            }

            td {
                &.col_full_name {
                    width: 35%;
                    position: relative !important;
                    padding: 8px !important;

                    .custom-checkbox {
                        position: relative !important;
                        top: -7px !important;
                        left: 0 !important;
                    }

                    .custom-checkbox-stripe {
                        position: relative !important;
                        top: -7px !important;
                        left: 0 !important;
                    }

                    .custom-checkbox-stripe [type="checkbox"]:checked+label:after {
                        width: 16px;
                        height: 16px;
                    }
                }

                &.col_role {
                    width: 120px !important;
                }

                &.col_name {
                    width: 400px !important;
                    white-space: nowrap;
                    max-width: 400px !important;
                    text-overflow: ellipsis;
                    overflow: hidden;
                }

                &.col_desc {
                    width: 400px !important;
                    white-space: nowrap;
                    max-width: 400px !important;
                    text-overflow: ellipsis;
                    overflow: hidden;
                }

                &:nth-child(1) {
                    // padding-left: 34px !important;
                }

                &.col_alias {
                    width: 30%;
                }

                &.col_email {
                    width: 35%;
                }
            }
        }
    }
}

.table-roles-list tbody td {
    background: #ffffff;
    position: relative;
    word-break: break-word;

    &:nth-child(1) {
        // padding-left: 34px !important;
    }

    &.col_role {
        width: 120px !important;
        text-align: left;
    }

    &.col_name {
        width: 400px !important;
        white-space: nowrap;
        max-width: 400px !important;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    &.col_desc {
        width: 400px !important;
        white-space: nowrap;
        max-width: 400px !important;
        text-overflow: ellipsis;
        overflow: hidden;
    }


    .custom-checkbox {
        position: relative !important;
        top: -7px !important;
        left: 0 !important;
    }
}

.table-roles-list button svg {
    position: relative !important;
    right: unset !important;
    top: -2px !important;
    margin-right: 5px;
}

.table-roles-list .single-remove-button {
    margin-left: 16px;
    color: #ff3f57;
}

.table-roles-list .single-remove-button:hover {
    color: #e83b51;
}

.table-roles-list .col-action-button-wrapper .view-agent {
    color: #ff7d00;
    letter-spacing: -0.5px;
}

.table-roles-list .col-action-button-wrapper .view-agent:hover {
    color: var(--main-darker-orange);
}

.table-roles-list .col-action-button-wrapper img.view-agent {
    margin-right: 3px;
    position: relative;
    top: -2px;
}

.table-roles-list tbody tr:hover .col-action-button-wrapper {
    /* display: block; */
}

.table-roles-list .no-data-tr {
    border-bottom: none;
}

.table-roles-list .no-data-column {
    padding-top: 24px;
    letter-spacing: -0.5px;
    font-size: 16px;
}

.table-roles-list tbody tr:hover {
    background: #f8f8f8 !important;
}

.table-roles-list .resent-invitation {
    background: none;
    border: 0;
    color: #ff7d00;
    letter-spacing: -0.5px;
    padding: 0;
}

.table-roles-list .resent-invitation img {
    position: relative;
    top: -2px;
}


.table-roles-list .resent-invitation:hover {
    color: var(--main-darker-orange);
}


.table-roles-list .resend-invitation-loading {
    width: 150px;
}

.table-roles-list .resend-invitation-loading .lds-ring {
    top: 3px;
    right: unset;
}

.table-roles-list .resend-invitation-loading .lds-ring div {
    margin: 0;
}

.roles-tool-wrapper {
    width: 460px;
}