.sla-settings {
  .button-save {
    margin-top: 28px;
  }

  .status-wrapper {
    font-size: 16px;

    .status-value {
      color: rgba(25, 25, 25, 0.4);
      font-size: 16px;
      letter-spacing: -0.5px;

      &.status-enabled {
        color: #19C700;
      }
    }

    img {
      position: relative;
      top: -2px;
      margin-right: 4px;
    }
  }

  .sla-settings-header {
    font-size: 20px;
    letter-spacing: -0.6px;
    color: #191919;
    padding-bottom: 24px;
    border-bottom: solid 1px #dcdcdc;
    position: relative;
    margin: 0 0 24px 0;

    button {
      position: absolute;
      top: 0;
      right: 0;
    }
  }

  .sla-settings-form {
    // padding-top: 24px;
    position: relative;

    input[type="text"] {
      font-family: PTRootBold;
      width: 46px;
      height: 24px;
      margin: 0 4px;
      border-radius: 4px;
      text-align: center;
      padding: 0 6px;
      font-size: 12px;
      letter-spacing: -0.5px;
    }

    .input-time-select {
      font-size: 14px;
      letter-spacing: -0.4px;
      display: inline-block;
      vertical-align: top;
      margin-right: 4px;
      width: 108px;
      height: 24px;

      .custom-select-option-placeholder {
        padding: 0 !important;
        text-align: center;
      }

      .custom-select-option-value {
        font-family: PTRootBold;
        font-size: 12px;
        letter-spacing: -0.2px;
        padding: 0 !important;
        text-align: center;
      }

      svg {
        color: rgba(25, 25, 25, 0.8) !important;
        right: 12px !important;
        font-size: 14px;
      }

      .dropdown {
        button {
          padding: 1px 20px 1px 12px !important;
          width: 108px !important;
          height: 24px;
          border-radius: 4px !important;
          margin: 0 !important;
        }
      }

      .dropdown-option-wrapper {
        padding: 0;
        width: 108px;
        min-width: 0;
        border-radius: 4px !important;

        button {
          padding: 3px 12px 3px 26px !important;
          font-family: PTRootBold;
          border-bottom: solid 1px #dcdcdc;
          font-size: 12px;
          letter-spacing: -0.2px;
          border-radius: 0 !important;

          &::before {
            width: 12px;
            height: 12px;
            left: 10px;
            top: 6px;
            background-size: 12px;
            transform: none !important;
          }

          svg {
            font-size: 14px;
            left: 12px;
          }

          &:nth-last-child(1) {
            border: none;
          }
        }

      }
    }

    .closing-welcome-switch-wrapper {
      position: absolute;
      top: 0;
      right: 0;
      vertical-align: top;
      display: inline-block;
      margin: 0 0 0 16px;

      .custom-switch-wrapper {
        margin: 0;
        height: 28px;
        width: 48px;

        .custom-switch:before {
          width: 15px;
          height: 15px;
          bottom: 7px;
        }
      }

      input:checked+.custom-switch:before {
        transform: translateX(17px);
      }
    }

    .sla-settings-form-content {
      display: block;
      width: 100%;
      font-size: 16px;
      letter-spacing: -0.5px;
      margin-bottom: 20px;
      position: relative;
      padding-right: 64px;

      // button {
      //     position: absolute;
      //     right: 0;
      //     top: 0;
      //     margin: 0;
      //     display: inline-block;
      //     width: unset;
      // }

      p {
        margin: 4px 0 10px;
        font-size: 14px;
        letter-spacing: -0.2px;
        color: rgba(25, 25, 25, 0.8);
      }
    }
  }
}

.modal-confirmation-save-changes {
  .modal-content {
    width: 476px !important;
  }

  button {
    width: calc(50% - 4px) !important;
    margin-right: 8px;

    &:nth-last-child(1) {
      margin-right: 0;
    }
  }
}