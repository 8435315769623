.home-welcome-message {
  margin: 0;
  letter-spacing: -0.6px;
  font-size: 24px;
  color: #191919;
}

.onboarding-wrapper {
  margin: 32px 0 12px 0;
}

.section-home {
  // width: calc(100% - 362px);
  width: 100%;
  display: inline-block;
  position: relative;
  vertical-align: top;
  margin-right: 12px;
  margin: 0 0 12px 0;
}

.section-member-status {
  width: 350px;
  display: inline-block;
  position: relative;
  vertical-align: top;

  .section-member-status-list {
    padding-left: 24px;
    position: relative;
    margin: 0 0 8px 0;
    width: 100%;
    display: block;
    font-size: 16px;
    letter-spacing: -0.5px;

    .online-status-badge {
      width: 13px;
      height: 13px;
      border-radius: 50%;
      position: absolute;
      left: 0;
      top: 6px;
      background: #18BE00;
      filter: drop-shadow(0px 8px 28px rgba(25, 25, 25, 0.18));
    }

    .away-status-badge {
      width: 13px;
      height: 13px;
      border-radius: 50%;
      position: absolute;
      left: 0;
      top: 6px;
      background: #E6B628;
      filter: drop-shadow(0px 8px 28px rgba(25, 25, 25, 0.18));
    }

    p {
      margin: 0;
      color: rgba(25, 25, 25, 0.4);
      font-size: 10px;
      letter-spacing: 1.5px;
    }
  }

  .see-more-status {
    padding: 8px 0 0 0;
    width: 100%;
    display: block;
    color: var(--main-dark-orange);
    cursor: pointer;
    text-align: right;

    svg {
      position: relative;
      top: -2px;
    }

    &:hover {
      color: var(--main-darker-orange);
    }
  }
}

.home-info-wrapper {
  border-top: solid 1px rgba(25, 25, 25, 0.1);
  margin-top: 24px;
  padding-top: 28px;

  .home-info-alias {
    color: rgba(25, 25, 25, 0.8);
    font-size: 14px;
    margin: 0;
  }

  .home-info-content {
    // min-width: 300px;
    width: 25%;
    display: inline-block;
    // max-width: 50%;
    padding-right: 25px;
    vertical-align: top;
    max-height: 70px;

    .home-info-image {
      padding: 6px;
      border-radius: 8px;
      box-shadow: 0 2px 20px 0 rgba(25, 25, 25, 0.05);
      display: inline-block;
      vertical-align: top;
      position: relative;
      top: 2px;
    }

    .home-info-detail {
      width: calc(100% - 48px);
      display: inline-block;
      padding-left: 8px;
      position: relative;

      .home-info-detail-title {
        font-size: 10px;
        color: rgba(25, 25, 25, 0.6);
        margin: 0;
        letter-spacing: 1.5px;
        line-height: 15px;
        white-space: nowrap;
        max-width: 100%;
        text-overflow: ellipsis;
        overflow: hidden;
      }

      .home-info-detail-value {
        letter-spacing: -0.5px;
        font-size: 16px;
        margin: 0;
        line-height: 18px;
        white-space: nowrap;
        max-width: 100%;
        text-overflow: ellipsis;
        overflow: hidden;
        line-height: 24px;
        position: relative;
        top: -2px;
      }
    }

    button {
      height: 22px;
      font-size: 12px;
      letter-spacing: -0.4px;
      border-radius: 4px;
      padding: 0 10px 0 8px !important;
      line-height: 19px;
      margin-top: 4px;
    }
  }
}

.usage-info-wrapper {
  border-top: solid 1px rgba(25, 25, 25, 0.1);
  margin-top: 12px;
  padding-top: 24px;
  display: flex;

  .home-usage-wrapper {
    width: 25%;
    padding-right: 12px;

    .usage-icon {
      width: 32px;
      height: 32px;
      margin-bottom: 8px;
    }

    .usage-title {
      font-size: 14px;
      margin: 0;

      span {
        font-family: PTRootUI;
        color: rgba(25, 25, 25, 0.6);
      }

      img {
        width: 13px;
        position: relative;
        top: -1px;
      }
    }

    .data-not-available {
      color: rgba(25, 25, 25, 0.4);
      letter-spacing: 3px;
      margin: 0;

      &.data-not-available-1 {
        margin-top: 11px !important;
      }
    }

    .data-loading {
      background: rgba(25, 25, 25, 0.05);
      height: 40px;
      margin: 0 0 8px 0;
      border-radius: 4px;
      width: calc(60% - 20px);
      height: 24px;
      
      &.data-loading-1 {
        width: calc(100% - 20px);
        margin: 12px 0 8px 0;
        background: rgba(25, 25, 25, 0.1);
        height: 40px;
      }
    }

    .usage-value {
      font-size: 40px;
      margin: 0;
    }

    .usage-per {
      font-size: 16px;
      color: rgba(25, 25, 25, 0.4);
      margin: 0;
    }
  }
}

.usage-header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .usage-title {
    margin: 0;
    letter-spacing: -0.6px;
    font-size: 24px;
    color: #191919;
  }
}

.tooltip-mau {
  .tooltip-inner {
    white-space: nowrap;
    max-width: 500px !important;
  }
}