.integration-kataai-detail-account {
  padding: 32px 30px;
  text-align: left;

  label {
    font-size: 16px;
    letter-spacing: -0.5px;
  }

  .comp-back-button {
    margin: 0 0 16px 0;
    font-family: PTRootBold;
  }

  .integration-detail-loading-wrapper {
    // .lds-ring {
    //   width: 50px;
    //   height: 50px;
    //   position: absolute;
    //   left: 50%;
    //   top: 50%;
    //   transform: translate(-50%, -50%);
    //   right: unset;

    //   div {
    //     border: 5px solid #fff;
    //     border-color: #ff7d00 transparent transparent transparent;
    //     width: 50px;
    //     height: 50px;
    //     margin: 0;
    //   }
    // }
  }

  .integration-kataai-detail-account-box-1 {
    background: #ffffff;
    border-radius: 8px;
    padding: 22px 32px 32px;
    border: solid 1px rgba(25, 25, 25, 0.1);
    position: relative;

    &.channel-config-top {
      margin-top: 24px;

      .disabled-form {
        width: 100%;
        
        input {
          width: 100%;
        }
      }
    }

    .disabled-form {
      input {
        &:focus {
          border: solid 1px rgba(25, 25, 25, 0.1) !important;
        }
      }
    }

    svg {
      color: var(--main-dark-orange) !important;
    }

    .integration-kataai-detail-account-box-1-top {
      padding: 0 0 18px;
      border-bottom: solid 1px #dcdcdc;
      position: relative;
      font-size: 20px;
      letter-spacing: -0.6px;
      padding-right: 230px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      &.integration-kataai-detail-account-box-1-top-1-line {
        padding: 0 0 28px;

        .box-title {
          position: relative;
          top: 8px;
        }
      }

      &.box-channel-button {
        margin-top: 24px;

        .detail-topic {
          display: block;
        }

        .topic-wrapper {
          margin-top: 24px;
          width: 100%;
          display: block;
        }

        .status-wrapper {
          .status-value {
            color: rgba(25, 25, 25, 0.4);

            &.status-enabled {
              color: #19C700;
            }
          }

          img {
            position: relative;
            top: -1px;
            margin-right: 4px;
          }
        }
      }

      .bot-name {
        font-size: 12px;
        margin-bottom: 0;
        min-height: 8px;
      }

      .integration-kataai-detail-account-box-1-top-button {
        position: absolute;
        top: 4px;
        right: 0;
        display: inline-block;

        button {
          padding: 7px 24px;
          font-size: 16px;
          letter-spacing: -0.6px;
          height: 40px;
          margin-left: 8px;

          img {
            position: relative;
            top: -2px;
            margin-right: 4px;
          }

          svg {
            position: relative;
            top: -2px;
            margin-right: 4px;
          }

          .lds-ring {
            top: unset;
            right: unset;
            position: relative;
            top: 3px;

            div {
              margin: 0;
              border-color: #ffffff transparent transparent transparent;
            }
          }
        }
      }
    }

    .detail-topic {
      // display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 24px 0 0;
      // position: relative;

      button {
        position: absolute;
        right: 32px;
        top: 26px;
      }

      .topic-val {
        margin: 6px 0 0;
        max-width: calc(100% - 200px);
      }

      .topic-wrapper {
        display: flex;
        flex-direction: column;
        // color: #191919;

        .detail-title {
          font-size: 16px;
          letter-spacing: -0.5px;
        }

        b {
          &:nth-child(2) {
            font-size: 20px;
          }
        }
      }

      button {
        padding: 7px 24px;
        font-size: 16px;
        letter-spacing: -0.6px;
        height: 40px;
        margin-left: 8px;

        // img {
        //   position: relative;
        //   top: -2px;
        //   margin-right: 4px;
        // }

        svg {
          position: relative;
          top: -2px;
          margin-right: 4px;
        }
      }
    }

    .bot-token-status {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 24px 0 0;

      .bot-status-wrapper {
        .bot-status-title {
          font-size: 14px;
        }

        .status-wrapper {
          // display: flex;
          align-items: center;

          .facebook-button {
            width: unset;
            margin-top: 24px;
          }

          img {
            margin-right: 5px;
            position: relative;
            top: -1px;
          }

          .status-text {
            font-size: 16px;
          }

          .text-green {
            color: #19c700;
          }

          .text-red {
            color: #ff3f57;
          }
        }
      }

      .button-wrapper {
        button {
          padding: 7px 24px;
          font-size: 16px;
          letter-spacing: -0.6px;
          height: 40px;
          margin-left: 8px;
          font-family: PTRootBold;

          svg {
            position: relative;
            top: -2px;
            margin-right: 4px;
          }
        }
      }
    }

    &.box-channel-button {
      margin-top: 24px;

      .detail-topic {
        display: block;
      }

      .topic-wrapper {
        margin-top: 24px;
        width: 100%;
        display: block;
      }

      .status-wrapper {
        .status-value {
          color: rgba(25, 25, 25, 0.4);

          &.status-enabled {
            color: #19C700;
          }
        }

        img {
          position: relative;
          top: -1px;
          margin-right: 4px;
        }
      }
    }
  }

  .integration-detail-account-box-1 {
    background: #ffffff;
    border-radius: 8px;
    padding: 22px 32px 32px;
    border: solid 1px rgba(25, 25, 25, 0.1);

    &.box-channel-button {
      margin-top: 24px;

      .facebook-detail-topic {
        display: block;

        .channel-button-wrapper {
          margin-top: 24px;
        }
      }

      .topic-wrapper {
        margin-top: 24px;
        width: 100%;
        display: block;
      }

      .status-wrapper {
        .status-value {
          color: rgba(25, 25, 25, 0.4);

          &.status-enabled {
            color: #19C700;
          }
        }

        img {
          position: relative;
          top: -1px;
          margin-right: 4px;
        }
      }
    }

    .integration-detail-account-box-1-top {
      padding: 10px 0 15px;
      border-bottom: solid 1px #dcdcdc;
      position: relative;
      font-size: 20px;
      letter-spacing: -0.6px;
      padding-right: 230px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      .bot-name {
        font-size: 12px;
        margin-bottom: 0;
      }

      .integration-detail-account-box-1-top-button {
        position: absolute;
        top: 0px;
        right: 0;
        display: inline-block;

        button {
          padding: 7px 24px;
          font-size: 16px;
          letter-spacing: -0.6px;
          height: 40px;
          margin-left: 8px;

          &.button-channel-detail {
            position: relative;
            top: 15px;
          }

          img {
            position: relative;
            top: -2px;
            margin-right: 4px;
          }

          svg {
            position: relative;
            top: -2px;
            margin-right: 4px;
          }

          .lds-ring {
            top: unset;
            right: unset;
            position: relative;
            top: 3px;

            div {
              margin: 0;
              border-color: #ffffff transparent transparent transparent;
            }
          }
        }
      }
    }

    .facebook-detail-topic {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 24px 0 0;

      .channel-button-wrapper {
        margin-top: 24px;
        width: 300px;
        word-break: break-word;
      }

      .topic-wrapper {
        display: flex;
        flex-direction: column;
        // color: #191919;

        .detail-title {
          font-size: 14px;
        }
      }

      button {
        padding: 7px 24px;
        font-size: 16px;
        letter-spacing: -0.6px;
        height: 40px;
        margin-left: 8px;

        // img {
        //   position: relative;
        //   top: -2px;
        //   margin-right: 4px;
        // }

        svg {
          position: relative;
          top: -2px;
          margin-right: 4px;
        }
      }
    }

    .facebook-bot-token-status {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 24px 0 0;

      .bot-status-wrapper {
        .bot-status-title {
          font-size: 14px;
        }

        .status-wrapper {
          display: flex;
          align-items: center;

          img {
            margin-right: 5px;
            position: relative;
            top: -2px;
          }

          .status-text {
            font-size: 16px;
          }

          .text-green {
            color: #19c700;
          }

          .text-red {
            color: #ff3f57;
          }
        }
      }

      .button-wrapper {
        button {
          padding: 7px 24px;
          font-size: 16px;
          letter-spacing: -0.6px;
          height: 40px;
          margin-left: 8px;
          font-family: PTRootBold;

          svg {
            position: relative;
            top: -2px;
            margin-right: 4px;
          }
        }
      }
    }

    &.box-channel-button {
      margin-top: 24px;

      .facebook-detail-topic {
        display: block;
      }

      .topic-wrapper {
        margin-top: 24px;
        width: 100%;
        display: block;
      }

      .status-wrapper {
        .status-value {
          color: rgba(25, 25, 25, 0.4);

          &.status-enabled {
            color: #19C700;
          }
        }

        img {
          position: relative;
          top: -1px;
          margin-right: 4px;
        }
      }
    }
  }
}