.add-meta-card-wrapper {
  width: 100vw;
  height: 100vh;
  background: rgba(25, 25, 25, 0.8);
  position: fixed;
  top: 0;
  /* right: -100vw; */
  visibility: hidden;
  opacity: 0;
  right: 0;
  /* transition: visibility 0s, opacity 0.3s ease-in-out; */
  transition: visibility .3s, opacity 0.5s ease-in-out;
  z-index: 9991;

  input[type="file"] {
    display: none;
  }

  .add-sample-param-toggle {
    position: relative;
    display: inline-block;
    top: -2px;
    margin-left: 4px;
    cursor: pointer;
  }

  &.active-add-meta-card {
    opacity: 1;
    visibility: visible;
    -webkit-transition: visibility 0s, opacity 0.5s ease-in-out;
    transition: visibility 0s, opacity 0.5s ease-in-out;
  }

  .add-meta-card-main-wrapper {
    width: 580px;
    transition: ease-in-out 0.5s;
    background: #ffffff;
    height: calc(100vh - 8px);
    border-top-left-radius: 18px;
    bottom: 0;
    right: -405px;
    position: fixed;

    .buttons-wrapper-meta-card {
      padding: 12px 12px 12px;
      background: rgba(243, 243, 243, 1);
      border: solid 1px rgba(25, 25, 25, 0.1);
      border-radius: 8px;
      margin: 8px 0 12px;
      position: relative;

      .input-param {
        position: relative;
        width: 100%;

        &.param-right {
          input {
            width: calc(100% - 35px) !important;
          }

          span {
            color: rgba(25, 25, 25, 0.5);
            position: absolute;
            top: 50%;
            right: 0;
            transform: translate(0, -50%);
          }
        }

        &.param-left {
          input {
            width: calc(100% - 35px) !important;
            margin-left: 35px;
          }

          span {
            color: rgba(25, 25, 25, 0.5);
            position: absolute;
            top: 50%;
            left: 0;
            transform: translate(0, -50%);
          }
        }
      }

      .dropdown {
        button {
          margin: 0 !important;
        }
      }

      .button-delete {
        color: rgba(255, 63, 87, 1);
        font-size: 14px;
        position: absolute;
        top: 15px;
        right: 12px;
        cursor: pointer;
      }

      input[type="text"] {
        padding: 12px 16px;
        border-radius: 6px;
        border: solid 1px #dcdcdc;
        width: 100%;
        font-size: 16px;
        letter-spacing: -0.3px;
        display: block;
      }

      label {
        position: relative;
        padding: 24px 0 0 0 !important;
        margin: 0 0 8px 0 !important;

        .custom-tooltips {
          top: 4px;
        }

        &:nth-child(1) {
          padding: 0 !important;
        }

        .grey-font {
          display: inline-block;
        }

        span {
          color: rgba(25, 25, 25, 0.6);
          font-size: 14px;
          letter-spacing: -0.2px;
          position: absolute;
          right: 0;
          bottom: 0;

          &.right-18 {
            right: 18px !important;
          }
        }
      }

      .phone-number-wrapper {
        position: relative;

        svg {
          position: absolute;
          top: 14px;
          left: 12px;
          font-size: 22px;
        }

        input {
          padding-left: 36px !important;
        }
      }
    }

    &.active-add-meta-card-main-wrapper {
      right: 0;
    }

    .add-meta-card-close {
      background: #ffffff;
      border-radius: 50%;
      cursor: pointer;
      position: absolute;
      width: 48px;
      height: 48px;
      display: inline-block;
      text-align: center;
      line-height: 45px;
      top: 0;
      left: -64px;

      svg {
        font-size: 24px;
        color: #7D7C7D;
      }
    }

    .title-add-meta-card {
      font-size: 20px;
      letter-spacing: -0.6px;
      padding: 32px 32px 0 32px;
      margin: 0;

      svg {
        position: relative;
        margin-right: 8px;
        top: -2px;
      }


      p {
        font-size: 14px;
        letter-spacing: -0.2px;
        color: rgba(25, 25, 25, 0.6);
        margin: 8px 0 0;
      }
    }

    .body-add-meta-card {
      max-height: calc(100% - 164px);
      overflow-y: auto;
      width: 100%;
      padding: 0 32px 32px;

      .sample-param-input-wrapper {
        position: relative;
        padding-top: 26px;

        .max-char-variable {
          position: absolute;
          font-size: 14px;
          right: 0;
          top: 6px;
        }

        p {
          width: 150px;
          text-overflow: ellipsis;
          overflow: hidden;
          font-size: 14px;
          letter-spacing: -0.4px;
          display: inline-block;
          padding-right: 8px;
          padding-top: 11px;
          margin: 0;
        }

        input {
          width: calc(100% - 150px) !important;
          display: inline-block !important;
          border: solid 1px #dcdcdc;
          border-radius: 8px;
          vertical-align: top;
          height: 45px;
          color: rgba(25, 25, 25, 0.6);
          padding: 12px 16px;
        }
      }

      .image-or-video-wrapper {
        width: 100px;
        height: 100px;
        position: relative;
        background: #f9f9f9;
        border-radius: 8px;

        .lds-ring {
          top: 50%;
          left: 50%;
          width: 80px;
          height: 80px;
          transform: translate(-50%, -50%);
          z-index: 5;

          div {
            width: 80px;
            height: 80px;
            border: solid 8px #fff;
            border-color: #fff transparent transparent transparent;
            margin: 0;
          }
        }

        img,
        video {
          position: absolute;
          top: 50%;
          left: 50%;
          max-width: 100%;
          max-height: 100%;
          transform: translate(-50%, -50%);
        }

        &::before {
          content: "";
          width: 100px;
          height: 100px;
          position: absolute;
          top: 0;
          left: 0;
          border-radius: 8px;
          z-index: 1;
          background: rgba(25, 25, 25, 0.2);
        }

        svg {
          color: #fff;
          z-index: 2;
          font-size: 24px;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }

      label {
        display: block;
        margin: 16px 0 8px 0;
        position: relative;

        .grey-font-6 {
          position: absolute;
          right: 0;
          top: 0;
          font-size: 14px;
          letter-spacing: -0.2px;
        }
      }

      .carousel-replace-media {
        margin-left: 6px;
      }

      .button-upload-media {
        margin: 0 0 8px;
      }

      .add-card-button {
        font-size: 14px;
        letter-spacing: -0.5px;
        padding: 0 0 0 8px;

        svg {
          position: relative;
          margin-right: 4px;
          top: -1px;
        }
      }
    }

    .foot-add-meta-card {
      padding: 12px 24px;
      position: absolute;
      bottom: 0;
      left: 0;
      box-shadow: 0px 2px 20px 0px rgba(25, 25, 25, 0.18);
      width: 100%;

      button {
        width: calc(50% - 4px);
        margin: 0 8px 0 0;

        &:nth-last-child(1) {
          margin: 0;
        }
      }
    }
  }

  .add-meta-card-body-wrapper {
    width: 100%;
    display: block;
    position: relative;
    height: calc(100% - 80px);
  }
}