.open-case-tab-wrapper {
	height: calc(100vh - 109px);
	position: relative;
	// top: 1px;
	background: #f3f3f3;
	width: calc(100% - 62px);
	display: inline-block;
	overflow: hidden;
	// transition: ease-in-out .2s;

	.need-reply-panel-1 {
		width: 100%;
		text-align: center;
	}

	.border-radius-12 {
		border-radius: 12px !important; 
	}

	.need-reply-panel-only {

	}

	.room-list-loading-wrapper {
		padding: 20px 0;
		// height: calc((100vh - 104px - 90px - 8px) / 2);
		// height: calc(100vh - 152px);
		height: calc(100% - 152px);
		
		.lds-ring {
			margin-right: 5px;
			top: 3px !important;
			left: 0;
		}
	}
	.room-list-no-chat-container {
		padding: 10px 16px 30px 16px;
		color: #191919;
	}

	.room-list-chat-container {
		transition: ease-in-out .2s;
		position: absolute;
		right: -360px;
		width: 100%;
		// top: 136px;

		&.active-open-case-list-panel {
			right: 0;
		}
	}
}

.open-case-unassigned-panel {
	margin-bottom: 8px;
	width: 100%;
	svg {
		color: #ff3f57;
	}
}
.open-case-panel {
	background: #ffffff;
	transition: ease-in-out .1s;
	.room-list-main-chat-container {
		box-shadow: 0px 1px 0px #dcdcdc;
		border: 0;
		transition: ease-in-out .2s;
		overflow: hidden;
		position: relative;
	}
}
.open-case-bar {
	color: #191919;
	line-height: 1.5;
	letter-spacing: -0.5px;
	font-size: 16px;
	width: 100%;
	padding: 8px 16px;
	position: relative;
	cursor: pointer;
	border-bottom: solid 1px #dcdcdc;
	span {
		color: rgba(25, 25, 25, 0.4);
	}
	svg {
		margin-right: 2px;
		position: relative;
		top: -2px;
		left: -2px;
		font-size: 18px;
	}
	.expand-more-bar {
		position: absolute;
		right: 14px;
		top: 50%;
		transform: translate(0, -50%);
		color: #191919;
		font-size: 23px;
		transition: ease-in-out .3s;
		margin: 0;
		left: unset;
	}
	.expand-more-bar.active-expand {
		transform: rotate(180deg) translate(0, 50%);
	}
}
.open-case-my-case-panel {
	svg {
		color: #19c700;
	}
}

.open-case-switch-wrapper {
	width: 100%;
	// height: 47px;
	background: #ffffff;
	position: relative;
	text-align: center;
	padding: 9px 16px;
	box-shadow: 0 1px 0 0 rgba(25, 25, 25, 0.1);
	z-index: 1;
	padding: 0 12px 8px;

	.case-title-and-search-button-container {
		position: relative;
		width: 100%;
		height: 52px;
	
		.case-room-search-wrapper {
			padding: 8px 0;
			transition: ease-in-out .15s;
			position: absolute;
			top: 0;
			right: calc(-100% - 24px);
			width: 100%;

			&.active-case-room-search-wrapper {
				right: 0;
			}

			#input-search-form {
				text-align: left;

				input[type="text"] {
					padding: 9px 11px 9px 11px;
					width: calc(100% - 50px);

					&::placeholder {
						font-size: 12px;
						color: rgba(25, 25, 25, 0.4);
					}
				}

				.cancel-search {
					font-size: 14px;
					letter-spacing: -0.4px;
					color: #ff7d00;
					cursor: pointer;
					margin-left: 8px;

					&:hover {
						color: var(--main-darker-orange);
					}
				}
			}

	
			.submit-filter-button {
				right: 0;
			}
		}

		.case-title-and-search-button-wrapper {
			position: absolute;
			left: calc(-100% - 24px);
			display: inline-block;
			transition: ease-in-out .15s;
			text-align: left;
			top: 0;
			width: 100%;
			height: 52px;
			line-height: 52px;

			&.active-case-title-and-search-button-wrapper {
				left: 0;
			}

			.inbox-title {
				font-size: 24px;
				letter-spacing: -0.6px;
			}

			.new-message-button-wrapper {
				position: absolute;
				color: #191919;
				display: inline-block;
				background: #ffffff;
				white-space: nowrap;
				height: 52px;
				right: 0;
				cursor: pointer;

				img {
					font-size: 20px;
					// display: none;

					&.icon-edit {
						color: #ff7d00;
						margin: 0 4px 0 21px;
						position: relative;
						top: -2px;
					}

					&.icon-search {
						position: relative;
						top: -2px;
						color: #191919;
					}
				}

				p {
					font-size: 14px;
					letter-spacing: -0.4px;
					color: #ff7d00;
					display: inline-block;
					margin: 0;

					svg {
						font-size: 20px;
					}
				}
			}
		}
	}
	
	.open-case-switch-content {
		width: 100%;
		border-radius: 12px;
		height: 29px;
		// border: solid 1px #dcdcdc;
		box-shadow: 0 0 1px #dcdcdc;
		position: relative;
		background: #f3f3f3;
		cursor: pointer;
		margin-top: 3px;

		.open-case-active-panel {
			width: 50%;
			content: "";
			background: #ffffff;
			border-radius: 12px;
			height: 27px;
			position: absolute;
			left: 1px;
			top: 1px;
			transition: ease-in-out .2s;

			&.active-mine-case-panel {
				left: calc(50% - 1px);
			}
		}

		.open-case-switch-panel {
			width: 50%;
			display: inline-block;
			text-align: center;
			font-size: 14px;
			letter-spacing: -0.4px;
			color: rgba(25, 25, 25, 0.6);
			padding-top: 4px;

			&:hover {
				color: rgba(25, 25, 25, 1); 
			}
			
			div {
				display: inline-block;
				position: relative;
			}

			.counter-case {
				font-size: 11px;
				color: #ffffff;
				text-align: center;
				background: #707070;
				border-radius: 12px;
				margin-left: 4px;
				height: 18px;
				display: inline-block;
				// line-height: 19px;
				padding: 1px 0 0;
				min-width: 18px;
				position: relative;
				top: -1px;
				// text-align: center;
				padding: 1px 5px 0 4px;
				// letter-spacing: 0;
			}

			&.active-case-panel {
				color: #191919;

				.counter-case {
					background: #474747;
				}
			}
		}
	}
}

.open-case-sort-by-wrapper {
	box-shadow: 0 1px 0 0 rgba(25, 25, 25, 0.1);
	width: 100%;
	position: relative;
	z-index: 3;
	padding: 4px 12px 8px 12px;
	background: #ffffff;
	// overflow: hidden;
	white-space: nowrap;
	height: 37px;
	left: 1px;

	&::-webkit-scrollbar {
		display: none;
	}

	.open-case-sort-by-wrapper-inner {
		&::-webkit-scrollbar {
			display: none;
		}

		.sort-by-wrapper-inner-scroll {
			position: absolute;
			top: 4.5px;
			left: 160px;
			max-width: calc(100% - 171px);
			overflow-x: auto;
			margin: 0 5px;

			.case-room-filter-tag-badge {
				&:nth-child(1) {
					margin-left: 0;
				}
			}

			&::-webkit-scrollbar {
				display: none;
			}
		}
	}
	
	.case-room-filter-tag-badge {
		margin: 2px 0 0 4px;
		color: rgba(25, 25, 25, 0.6);
	
		img {
			position: relative;
			top: -1px;
			margin-right: 3px;
		}
	}
}

.modal-clock-on-new-message {
	.modal-body {
		padding: 32px 0 20px !important;
	}
}
