.message-action-dropdown {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    position: absolute !important;
    top: 50%;
    transform: translate(0, -50%);
    // z-index: 2;
    display: none;

    &.message-action-show {
        z-index: 2;
        display: block !important;
    }

    &.message-action-dropdown-left {
        right: -40px;
    }

    &.message-action-dropdown-right {
        left: -40px;
    }

    button {
        padding: 0;
        width: 32px;
        height: 32px;
        border-radius: 50%;
        outline: none !important;
        background: unset !important;
        border: none !important;
        box-shadow: none !important;

        &:hover {
            background: #dddddd !important;
        }
    }

    .dropdown-menu {
        width: 168px;
        border-radius: 8px;
        padding: 0;
        overflow: hidden;
        border: none !important;
        box-shadow: 0px 2px 20px 0px rgba(25, 25, 25, 0.18);

        button {
            width: 100%;
            height: unset !important;
            background: none !important;
            border-radius: 0 !important;
            padding: 12px 16px;
            font-size: 16px;
            letter-spacing: -0.3px;

            &:hover {
                background: #FFF2E5 !important;
            }

            img {
                margin: 0 8px 0 0;
                position: relative;
                top: -2px;
            }
        }
    }

    .dropdown-item {
        color: #191919 !important;
    }
}