.custom-toastr {
    position: absolute;
    width: 250px;
    padding: 10px;
    border-radius: 5px;
    color: #191919;
    top: 15px;
    right: 15px;
    transition: ease-in-out 0.4s;
    opacity: 0;
    z-index: 1;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1), 0 20px 20px 0 rgba(0, 0, 0, 0.18);
    background: #ffffff;
    font-weight: bold;
    padding: 10px 10px 10px 35px;
}

.custom-toastr.active-toast {
    opacity: 1;
}

.custom-toastr.toast-fail {
    border-left: solid 12px #FE3D58;
}

.custom-toastr.toast-success {
    border-left: solid 12px #7EC82A;
}

.custom-toastr.toast-process {
    border-left: solid 12px #9A9B9C;
}

.custom-toastr .icon-toast-wrapper {
    width: 25px;
    height: 5px;
    display: inline-block;
    vertical-align: top;
    position: absolute;
    top: 8px;
    left: 10px;
}

.custom-toastr .icon-toast-wrapper svg {
    font-size: 25px;
}

.custom-toastr .icon-toast-wrapper img {
    width: 25px;
    position: relative;
}

.custom-toastr .text-toast-wrapper {
    width: calc(100% - 30px);
    display: inline-block;
    vertical-align: top;
    padding-left: 10px;
    font-size: 16px;
}

.custom-toastr .icon-toast-wrapper .lds-ring {
    display: inline-block;
    position: relative;
    width: 100%;
    height: 25px;
    top: 0;
    right: 0;
}

.custom-toastr .icon-toast-wrapper .lds-ring div {
    border-color: #9A9B9C transparent transparent transparent;
    top: -2px;
    left: 0;
}
