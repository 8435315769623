.chat-room-start-chat-wrapper {
    text-align: center;
    padding: 96px 20px 0;
    height: calc(100vh - 120px);
  }
  .chat-room-start-chat-wrapper .new-chat-avatar {
    text-align: center;
    margin-bottom: 10px;
  }
  .chat-room-start-chat-wrapper .new-chat-avatar .new-chat-avatar-content {
    border-radius: 50%;
    width: 72px;
    height: 72px;
    display: inline-block;
    background: #ffffff;
    text-align: center;
    line-height: 72px;
    padding: 1px;
  }
  .chat-room-start-chat-wrapper .new-chat-avatar .new-chat-avatar-content:nth-child(1) {
    position: relative;
    right: -2px;
  }
  .chat-room-start-chat-wrapper .new-chat-avatar .new-chat-avatar-content:nth-child(2) {
    position: relative;
    left: -2px;
  }
  .chat-room-start-chat-wrapper .new-chat-avatar .new-chat-avatar-content img {
    width: 70px;
    height: 70px;
    display: inline-block;
    border-radius: 50%;
    vertical-align: top;
  }
  .chat-room-start-chat-wrapper .new-chat-avatar .new-chat-avatar-content .avatar-word-wrapper {
    width: 70px;
    height: 70px;
    display: inline-block;
    border-radius: 50%;
    background: pink;
    vertical-align: top;
    line-height: 70px;
  }
  .chat-room-start-chat-wrapper .new-chat-avatar .new-chat-avatar-content .avatar-word-wrapper b {
    color: #ffffff;
  }
  .chat-room-start-chat-wrapper b {
    font-size: 20px;
    letter-spacing: -0.6px;
    color: #191919;
  }
  .chat-room-start-chat-wrapper p {
    margin: 8px 0 0;
  }