.chat-room-message-out-meta-carousel-wrapper {
  margin-top: 16px;
  padding: 0 0 0 24px;
  width: 100%;
  position: relative;
  text-align: right;

  .link-preview-side-decoration-out {
    background-color: var(--main-dark-orange);
  }

  .link-preview-title-out {
    color: #191919;
  }

  .link-preview-description-out {
    color: #191919;
  }
}

.chat-room-message-out-meta-carousel-wrapper {
  .message-out-bubble-carousel-wrapper-out {
    overflow-x: auto;
    white-space: nowrap;
    
    .message-out-bubble {
      border-radius: 16px 2px 16px 16px;
      border: 1px solid rgba(25, 25, 25, 0.1);
      // background: rgba(255, 242, 229, 1);
      display: inline-block;
      /* max-width: calc(100% - 180px); */
      // max-width: 80%;
      // max-width: calc(100% - 50px);
      // max-width: calc(100% - 100px);
      /* padding: 10px 62px 10px 10px; */
      padding: 10px;
      color: #191919;
      font-size: 16px;
      letter-spacing: -0.3px;
      line-height: 24px;
      position: relative;
      // cursor: pointer;
      text-align: left;
      min-height: 44px;
    }

    .carousel-card {
      word-break: break-word;
      background: #ffffff;
      width: 278px;
      padding: 10px;
      border-radius: 8px 8px 8px 8px;
      box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2);
      position: relative;
      text-align: left;
      font-family: SFPro;
      overflow: hidden;
      margin: 4px 4px 0 0;
      vertical-align: top;
      display: inline-block;
      white-space: normal;
      background: rgba(255, 242, 229, 1);

      .button-carousel-wrapper {
        margin: 4px 0 0;
        border: solid 1px var(--main-dark-orange);
        border-radius: 8px;

        .button-carousel {
          padding: 8px;
          // border: solid 1px var(--main-dark-orange);
          text-align: center;
          color: var(--main-dark-orange);
          white-space: nowrap;
          max-width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          font-size: 14px;
          letter-spacing: -0.2px;

          svg {
            position: relative;
            top: -1px;
            margin-right: 4px;
          }

          img {
            position: relative;
            top: -1px;
            margin-right: 4px;
          }

          &:nth-child(2) {
            border-top: solid 1px var(--main-dark-orange);
          }

          &:nth-child(3) {
            border-top: solid 1px var(--main-dark-orange);
          }
        }
      }

      .carousel-card-image-wrapper {
        .image-wrapper {
          width: 100%;
          height: 160px;
          position: relative;
          background: #fafafa;
          border-radius: 8px;
          overflow: hidden;
          margin: 0 0 4px 0;

          img {
            max-width: 100%;
            max-height: 100%;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        }

        .icon-wrapper {
          width: 44px;
          height: 44px;
          border-radius: 50%;
          background: rgba(25, 25, 25, 0.40);
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);

          svg {
            font-size: 24px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            color: #fff;
          }
        }
      }

      p {
        margin: 0;
      }
    }
  }
}

.chat-room-message-out-meta-carousel-wrapper .message-out-bubble.deleted-bubble {
  color: #ffffff;
  padding: 10px 10px 10px 33px;
}

.chat-room-message-out-meta-carousel-wrapper .message-out-bubble.deleted-bubble .deleted-icon {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translate(0, -50%);
}

.chat-room-message-out-meta-carousel-wrapper .message-out-bubble .forwarded-message {
  color: #ffffff;
  font-size: 12px;
  letter-spacing: -0.4px;
  line-height: 18px;
  display: block;
  margin-bottom: 5px;
}

.chat-room-message-out-meta-carousel-wrapper .message-out-bubble .reply-message {
  width: calc(100% - 20px);
  border-radius: 4px;
  border-left: solid 5px #FF8F22;
  background: #E88422;
  color: #ffffff;
  position: absolute;
  top: 10px;
  left: 10px;
  padding: 5px 7px 5px 7px;
  line-height: 1;
}

.chat-room-message-out-meta-carousel-wrapper .message-out-bubble .reply-message p {
  margin: 0;
  font-size: 12px;
  letter-spacing: -0.4px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 20px;
}

.chat-room-message-out-meta-carousel-wrapper .message-out-bubble .reply-message p:nth-child(2) {
  letter-spacing: -0.2px;
}

.chat-room-message-out-meta-carousel-wrapper .message-out-bubble .reply-message.reply-file .reply-file-thumbnail {
  display: inline-block;
  width: 40px;
  height: 40px;
  margin-right: 10px;
  position: relative;
}

.chat-room-message-out-meta-carousel-wrapper .message-out-bubble .reply-message.reply-file .reply-file-thumbnail img {
  width: 40px;
  height: 40px;
}

.chat-room-message-out-meta-carousel-wrapper .message-out-bubble .reply-message.reply-file .reply-file-thumbnail svg {
  position: absolute;
  left: 50%;
  top: 50%;
  font-size: 35px;
  transform: translate(-50%, -50%);
}

.chat-room-message-out-meta-carousel-wrapper .message-out-bubble .reply-message.reply-file .reply-text-wrapper {
  width: calc(100% - 50px);
  display: inline-block;
  vertical-align: top;
}

.chat-room-message-out-meta-carousel-wrapper .message-out-bubble .message-info {
  /* position: absolute; */
  /* bottom: 11px; */
  /* right: 27px; */
  margin: 0;
  color: rgba(25, 25, 25, 0.4);
  letter-spacing: -0.4px;
  font-size: 10px;
  line-height: 18px;
  /* width: 24px; */
  width: 100%;
  white-space: nowrap;
  text-align: right;

  .centered-dot {
    width: 2px;
    height: 2px;
    display: inline-block;
    vertical-align: top;
    background: rgba(25, 25, 25, 0.4);
    border-radius: 50%;
    position: relative;
    top: 9px;
  }
}

.chat-room-message-out-meta-carousel-wrapper .message-out-bubble .group-sender-name-wrapper {
  color: #ff7d00;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
  font-size: 14px;
}

.chat-room-message-out-meta-carousel-wrapper .message-out-bubble .group-sender-name-wrapper .agent-span {
  color: rgba(25, 25, 25, 0.4);
}

.chat-room-message-out-meta-carousel-wrapper .message-out-bubble .message-info img {
  margin-left: 4px;
  width: 16px;
  position: relative;
  /* top: 4px; */
}

.message-action-wrapper {
  position: relative;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  /* left: -170px; */
  /* left: -90px; */
  left: -90px;
  display: none;
}

.message-action-wrapper.message-action-right {
  right: -170px;
  left: unset;
}

.message-action-wrapper .forward-message svg {
  transform: scaleX(-1);
}

.message-action-wrapper .message-action-button-wrapper {
  border-radius: 50%;
  width: 30px;
  height: 30px;
  text-align: center;
  position: relative;
  display: inline-block;
  background-attachment: fixed;
  margin-left: 10px;
}

.message-action-wrapper .message-action-button-wrapper svg {
  color: #9b9b9b;
  position: absolute;
  font-size: 18px;
  left: 6px;
  top: 6px;
}

.chat-room-message-out-meta-carousel-wrapper:hover .message-action-wrapper {
  display: inline-block;
}

.chat-room-message-out-meta-carousel-wrapper:hover .message-action-dropdown {
  display: inline-block;
}

.message-action-popup-wrapper {
  width: 172px;
  display: inline-block;
  box-shadow: 0px 0px 9px 0px rgba(25, 25, 25, 0.1);
  line-height: 1;
  position: absolute;
  right: 63px;
  top: 0;
  border-radius: 8px;
  z-index: 1;
}

.message-action-popup-wrapper .message-action-content {
  width: 100%;
  position: relative;
  padding: 12px 12px 12px 35px;
  font-size: 15px;
  letter-spacing: -0.3px;
  color: #191919;
  border-bottom: solid 1px #dcdcdc;
  background: #ffffff;
  cursor: pointer;
}

.message-action-popup-wrapper .message-action-content.delete-message {
  color: #ff3f57;
}

.message-action-popup-wrapper .message-action-content.forward-message svg {
  transform: rotate(180deg) translate(0, 50%);
}

.message-action-popup-wrapper .message-action-content:hover {
  background: #ff7d00;
  color: #ffffff;
}

.message-action-popup-wrapper .message-action-content:hover svg {
  color: #ffffff;
}

.message-action-popup-wrapper .message-action-content:nth-child(1) {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.message-action-popup-wrapper .message-action-content:nth-last-child(1) {
  border: none;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.message-action-popup-wrapper svg {
  left: 12px;
  font-size: 16px;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
}

.chat-room-message-out-meta-carousel-wrapper .message-action-wrapper {
  /* right: -120px !important; */
  left: -90px !important;

  &.with-forward {
    left: -128px !important;

    .forward-button {
      display: inline-block !important;
    }
  }
}

//reply button
.hide-reply-button .message-out-bubble .message-action-wrapper {
  left: -45px !important;

  &.with-forward {
    left: -88px !important;
  }
}

//reply button