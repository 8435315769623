@mixin submenuOrange {
	color: #ff7d00;
	background: rgba(255, 126, 0, 0.1);
}

@mixin submenuGrey {
	color: rgba(25, 25, 25, 0.4);
	background: rgba(25, 25, 25, 0.05);
}

#sidemenu-wrapper {
	width: 200px;
	display: inline-block;
	position: fixed;
	top: 0;
	background: var(--main-dark-orange);
	color: #ffffff;
	height: calc(100vh);
	overflow-x: hidden;
	box-shadow: 2px 2px 5px 0 #c3c3c3;
	z-index: 1;
	font-weight: 100;

	a.active-menu {
		div {
			color: #ffffff !important;
			opacity: 1 !important;
			font-weight: bold;
		}

		li {
			color: #ffffff !important;
			opacity: 1 !important;
			font-weight: bold;
		}
	}

	.menu-padding {
		padding: 0 16px;
	}

	.menu-height {
		line-height: 70px;
		height: 70px;
	}

	.list-menu-wrapper {
		position: relative;
		font-weight: bold;
		cursor: pointer;

		img {
			margin-right: 4px;
			position: relative;
			top: -2px;
			width: 18px;
		}
	}

	.menu-smaller {
		color: #ffffff;
		opacity: 0.8;
		font-size: 14px;
		font-weight: 100;
	}

	.menu-smaller.active-side-menu {
		background: #de6d00;
	}

	.parentMenu-with-child {
		font-size: 16px;

		.parentMenu-name {
			position: relative;
			border-bottom: solid 1px #E87200;
		}

		.childMenu-wrapper {
			position: relative;

			ul {
				margin: 0;
				padding: 0;
				list-style: none;
				position: relative;
				background: #E87200;

				li {
					border-bottom: solid 1px #E87200;
				}
			}
		}
	}

	.parentMenu-with-child.active {
		background: var(--main-dark-orange);

		.parentMenu-name {
			border-bottom: solid 1px #E87200;
		}

		.childMenu-wrapper {
			ul {
				li {
					border-bottom: solid 1px #fb7b00;
				}
			}
		}
	}

	.menu-hover {
		&:hover {
			background: #de6d00 ! important;
		}
	}
}

.menu-Home {
	border-top: 1px solid rgb(232, 114, 0);
}

.sidemenu-organization-wrapper {
	position: relative;
	margin-bottom: 24px;
	padding: 12px 16px 14px 16px;
	box-shadow: 0 1px 0 0 #e87200;
}

.side-organization-name {
	margin: 0;
	font-size: 14px;
	line-height: 1.5;
	letter-spacing: -0.4px;
	color: #ffffff;
	max-width: calc(100% - 30px);
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
}

.side-organization-email {
	margin: 0;
	font-size: 12px;
	line-height: 1.33;
	letter-spacing: -0.2px;
	color: #ffffff;
	font-weight: normal;
	max-width: 100%;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
}

.right-arrow-organization {
	position: absolute;
	right: 12px;
	top: 14px;
}

.organization-popup-wrapper {
	position: fixed;
	transition: ease-in-out .2s;
	background: #ffffff;
	width: 280px;
	display: inline-block;
	left: 80px;
	bottom: 16px;
	border-radius: 8px;
	box-shadow: 0 0 20px 2px #eaeaea;
	cursor: pointer;
	visibility: visible;
	opacity: 1;
	z-index: 1;

	&:before {
		content: "";
		position: absolute;
		width: 16px;
		height: 16px;
		transform: rotate(45deg);
		bottom: 18px;
		left: -8px;
		box-shadow: -4px 5px 5px 0px rgba(234, 232, 232, .2);
		background: #ffffff;
	}
}

.popup-organization-content-info {
	padding: 19px 20px;
	line-height: 1;
	border-bottom: solid 1px #dcdcdc;

	p {
		color: #191919;
		max-width: 100%;
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
		line-height: 15px;
	}

	&.popup-top-content {
		padding: 20px;
		position: relative;

		p:nth-child(1) {
			margin-bottom: 10px;
		}

		svg {
			color: #ff7d00;
			font-size: 20px;
			position: absolute;
			top: 19px;
			right: 20px;



			&:hover {
				color: #e87200;
			}
		}
	}
}

.popup-organization-content-info-name {
	font-size: 16px;
	margin: 0 0 4px 0;
}

.popup-organization-content-info-email {
	font-weight: normal;
	font-size: 14px;
	margin: 0;
}

.popup-oganization-list {
	// height: 183px;
	// height: 100%;
	max-height: 183px;
	overflow: hidden;

	&.organization-list-with-scrollbar {
		height: 183px;
	}

	ul {
		margin: 0;
		padding: 0;
		// max-height: 112px;
		max-height: 183px;
		overflow-y: auto;

		li {
			font-size: 14px;
			color: #191919;
			list-style: none;
			margin: 0;
			position: relative;
			font-weight: normal;

			&:hover {
				color: #ff7d00;
			}

			svg {
				color: #ff7d00;
				font-size: 34px;
				position: absolute;
				left: 15px;
				top: 12px;
				display: none;
			}
		}

		li.active-organization {
			color: #ff7d00;

			svg {
				display: block;
			}
		}
	}

	a {
		color: unset !important;
	}
}

.organization-popup-wrapper.deactive-organization-popup-wrapper {
	visibility: hidden;
	opacity: 0;
	transition: visibility 0s, opacity 0.5s linear;
}

.popup-organization-content-wrapper {
	padding: 0;
	line-height: 59px;
	height: 60px;
	width: 100%;

	b {
		position: relative;
	}

	svg {
		font-size: 25px;
		position: relative;
		top: -2px;
		// top: 50%;
		// transform: translate(0, -50%);
		// position: absolute;
	}
}

.new-organization-wrapper {
	font-size: 16px;
	color: #ff7d00;
	text-align: center;
	border-top: solid 1px #dcdcdc;
	position: relative;
}

.logout-wrapper {
	font-size: 16px;
	color: #ff3f57;
	text-align: center;
	border-top: solid 1px #dcdcdc;
}

.organization-link-wrapper {
	padding: 20px 30px 20px 49px;
}

.new-side-menu-wrapper {
	max-height: 100%;
	width: 62px;
	display: inline-block;
	position: relative;
	overflow-y: auto;
	background: #ff7d00;
	position: fixed;
	top: 0;
	left: 0;
	height: 100%;
	// z-index: 2;
	box-shadow: 0 2px 20px 0 rgba(25, 25, 25, 0.18);

	.scrolling-top-menu {
		// height: calc(100% - 202px);
		height: calc(100% - 162px);
		overflow: hidden;
	}

	&.side-menu-active-billing-warning {
		top: 32px;
		height: calc(100% - 32px);
		max-height: calc(100% - 32px);
	}

	.new-main-side-menu-wrapper {
		color: #ffffff;
		line-height: 12px;
		font-size: 10px;
		text-align: center;
		letter-spacing: -0.3px;
		padding: 0 4px 6px 3px;
		cursor: pointer;
		width: 100%;
		position: relative;

		img {
			margin: 12px 0 8px;
			width: 32px;
			height: 32px;
		}

		&:hover {
			background: #E87403;
		}

		&.active-side-menu {
			background: #E87403;

			.active-white-line {
				position: absolute;
				top: 12px;
				left: -1px;
				width: 3px;
				height: 32px;
				display: inline-block;
				background: #ffffff;
				box-shadow: 0 2px 20px 3px #ffffff;
				border-radius: 8px;
			}
		}
	}

	.side-menu-main-bottom-wrapper {
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		display: inline-block;

		.new-main-side-menu-wrapper {
			line-height: 12px;
			padding: 0 4px 10px 3px;

			img {
				margin: 12px 0 10px;
			}
		}

		.my-account-avatar {
			border-radius: 50%;
			width: 32px;
			height: 32px;
			margin: 12px 0 10px;

			&.avatar-name {
				background: #ffffff;
				color: #ff7d00;
				width: 32px;
				height: 32px;
				display: inline-block;
				line-height: 32px;
				font-size: 15px;
			}

			&.image {
				width: 100%;
			}
		}
	}
}

.new-sub-side-menu-wrapper {
	// width: 278px;
	width: 286px;
	display: inline-block;
	height: 100%;
	position: fixed;
	top: 0;
	left: 62px;
	background: #ffffff;
	// z-index: 1;
	padding: 12px;
	transition: ease-in-out .1s;
	overflow-y: auto;
	overflow-x: hidden;

	.sub-menu-scroll {
		height: calc(100% - 35px);
		position: relative;
		top: -12px;
		left: -12px;
		width: calc(100% + 24px);

		.sub-menu-scroll-inner {
			padding: 12px;
		}
	}

	.your-channel-limit {
		width: 100%;
		position: absolute;
		bottom: 12px;
		left: 16px;

		p {
			color: #191919;
			font-size: 12px;
			letter-spacing: -0.4px;
			margin: 0 0 0;
		}

		.channel-limit {
			color: #ff7d00;
			font-size: 16px;
			letter-spacing: -0.5px;
		}
	}

	&.new-sub-side-menu-active-billing-warning {
		top: 32px;
		height: calc(100% - 32px);
	}

	&.deactive-submenu-wrapper {
		width: 0;
		padding: 0;
	}

	.back-to-main-menu-sidemenu {
		color: #ff7d00;
		margin: 20px 0 14px;
		font-size: 14px;
		letter-spacing: -0.4px;
		padding: 0 0 0 12px;
		cursor: pointer;


		&:hover {
			color: var(--main-darker-orange);
		}

		svg {
			font-size: 16px;
			position: relative;
			top: -1px;
			margin-right: 4px;
		}
	}

	.channels-text-side-menu {
		color: rgba(25, 25, 25, 0.4);
		font-size: 12px;
		letter-spacing: 2.5px;
		margin: 0 0 8px;
		padding: 0 0 0 12px;
	}


	.sub-menu-content {
		color: rgba(25, 25, 25, 0.4);
		font-size: 16px;
		line-height: 24px;
		letter-spacing: -0.5px;
		padding: 12px 16px 12px 40px;
		margin-bottom: 10px;
		border-radius: 6px;
		cursor: pointer;
		position: relative;

		&.sub-menu-overflow {
			// padding: 10px 41px 10px 40px;
			padding: 12px 41px 12px 40px;
			white-space: nowrap;
			text-overflow: ellipsis;
			overflow: hidden;
		}

		.medium-counter {
			letter-spacing: -0.4px;
			min-width: 20px;
			height: 20px;
			line-height: 20px;
			padding: 0 4px;
			background: #ff7d00;
			color: #ffffff;
			border-radius: 50%;
			position: absolute;
			right: 15px;
			top: 50%;
			transform: translate(0, -50%);
			font-size: 12px;
			text-align: center;
		}

		.active-sub-side-menu {
			color: #ff7d00;
			background: rgba(255, 126, 0, 0.1);
		}

		.sub-menu-icon {
			position: absolute;
			width: 17px;
			height: 16px;
			content: "";
			left: 16px;
			top: 50%;
			transform: translate(0, -50%);
			background-repeat: no-repeat;
			background-size: contain;
			background-position: center;

			&.secretKeys {
				background-image: url('../../../assets/img/sidemenu-icon/new/sub/secret-keys-grey.svg');
			}

			&.pushNotification {
				background-image: url('../../../assets/img/sidemenu-icon/new/sub/push-notif-grey.svg');
			}

			&.profile {
				background-image: url('../../../assets/img/sidemenu-icon/new/sub/profile-grey.svg');
			}

			&.members {
				background-image: url('../../../assets/img/sidemenu-icon/new/sub/member-grey.svg');
			}

			&.setup {
				background-image: url('../../../assets/img/sidemenu-icon/new/sub/setup-grey.svg');
			}

			&.topics {
				background-image: url('../../../assets/img/sidemenu-icon/new/sub/topic-grey.svg');
			}

			&.roles {
				background-image: url('../../../assets/img/sidemenu-icon/new/sub/roles-grey.svg');
			}

			&.security {
				background-image: url('../../../assets/img/sidemenu-icon/new/sub/lock-grey.svg');
			}

			&.generalQuickReply {
				background-image: url('../../../assets/img/sidemenu-icon/new/sub/general-quick-reply-grey.svg');
			}

			&.personalQuickReply {
				background-image: url('../../../assets/img/sidemenu-icon/new/sub/personal-quick-reply-grey.svg');
			}

			&.billing {
				background-image: url('../../../assets/img/sidemenu-icon/new/sub/billing-grey.svg');
			}

			&.organizationDetails {
				background-image: url('../../../assets/img/sidemenu-icon/new/sub/organization-grey.svg');
			}

			&.labels {
				background-image: url('../../../assets/img/sidemenu-icon/new/sub/tag-grey.svg');
			}

			&.broadcastMessage {
				background-image: url('../../../assets/img/sidemenu-icon/new/sub/broadcast-message-grey.svg');
			}

			&.userContact {
				background-image: url('../../../assets/img/sidemenu-icon/new/sub/user-contact-grey.svg');
			}

			&.userSegment {
				background-image: url('../../../assets/img/sidemenu-icon/new/sub/user-segment-grey.svg');
			}

			&.topup {
				background-image: url('../../../assets/img/sidemenu-icon/new/sub/top-up-grey.svg');
			}

			&.officeHour {
				background-image: url('../../../assets/img/sidemenu-icon/new/sub/office-hour-grey.svg');
			}

			&.welcomeMessage {
				background-image: url('../../../assets/img/sidemenu-icon/new/sub/welcome-message-grey.svg');
			}

			&.awayMessage {
				background-image: url('../../../assets/img/sidemenu-icon/new/sub/away-message-grey.svg');
			}

			&.closingMessage {
				background-image: url('../../../assets/img/sidemenu-icon/new/sub/closing-message-grey.svg');
			}

			&.userRating {
				background-image: url('../../../assets/img/sidemenu-icon/new/sub/user-rating-grey.svg');
			}

			&.inboxSetting {
				background-image: url('../../../assets/img/sidemenu-icon/new/sub/inbox-setting-grey.svg');
			}

			&.quickReply {
				background-image: url('../../../assets/img/sidemenu-icon/new/sub/inbox-quick-reply-grey.svg');
			}

			&.dailyOverview {
				background-image: url('../../../assets/img/sidemenu-icon/new/sub/daily-overview-grey.svg');
			}

			&.overview {
				background-image: url('../../../assets/img/sidemenu-icon/new/sub/overview-grey.svg');
			}

			&.caseReport {
				background-image: url('../../../assets/img/sidemenu-icon/new/sub/case-report-grey.svg');
			}

			&.caseRatings {
				background-image: url('../../../assets/img/sidemenu-icon/new/sub/case-ratings-grey.svg');
			}

			&.agentReport {
				background-image: url('../../../assets/img/sidemenu-icon/new/sub/agent-report-grey.svg');
			}

			&.clockInReport {
				background-image: url('../../../assets/img/sidemenu-icon/new/sub/clock-in-report-grey.svg');
			}
			
			&.assignmentRules {
				background-image: url('../../../assets/img/sidemenu-icon/new/sub/assignment-rules-grey.svg');
			}

			&.topicReport {
				background-image: url('../../../assets/img/sidemenu-icon/new/sub/topic-report-grey.svg');
			}

			&.faq {
				background-image: url('../../../assets/img/sidemenu-icon/new/sub/faq-grey.svg');
			}

			&.messageApiHistory {
				background-image: url('../../../assets/img/sidemenu-icon/new/sub/message-history-grey.svg');
			}

			&.utm {
				background-image: url('../../../assets/img/sidemenu-icon/new/sub/utm-grey.svg');
			}

			&.messageUserSummary {
				background-image: url('../../../assets/img/sidemenu-icon/new/sub/message-user-summary-grey.svg');
			}

			&.settings {
				background-image: url('../../../assets/img/sidemenu-icon/new/sub/inbox-setting-grey.svg');
			}

			&.autoResolve {
				background-image: url('../../../assets/img/sidemenu-icon/new/sub/auto-resolve-grey.svg');
			}

			&.autoUnassign {
				background-image: url('../../../assets/img/sidemenu-icon/new/sub/auto-unassign-grey.svg');
			}

			&.contactInfoCustomField {
				background-image: url('../../../assets/img/sidemenu-icon/new/sub/contact-info-custom-field-grey.svg');
			}

			&.importContact {
				background-image: url('../../../assets/img/sidemenu-icon/new/sub/import-contact-grey.svg');
			}
		}

		&:hover {
			@include submenuGrey;

			.secretKeys {
				background-image: url('../../../assets/img/sidemenu-icon/new/sub/secret-keys-grey.svg');
			}

			.pushNotification {
				background-image: url('../../../assets/img/sidemenu-icon/new/sub/push-notif-grey.svg');
			}

			.profile {
				background-image: url('../../../assets/img/sidemenu-icon/new/sub/profile-grey.svg');
			}

			.members {
				background-image: url('../../../assets/img/sidemenu-icon/new/sub/member-grey.svg');
			}

			.setup {
				background-image: url('../../../assets/img/sidemenu-icon/new/sub/setup-grey.svg');
			}

			.topics {
				background-image: url('../../../assets/img/sidemenu-icon/new/sub/topic-grey.svg');
			}

			.roles {
				background-image: url('../../../assets/img/sidemenu-icon/new/sub/roles-grey.svg');
			}

			.security {
				background-image: url('../../../assets/img/sidemenu-icon/new/sub/lock-grey.svg');
			}

			.generalQuickReply {
				background-image: url('../../../assets/img/sidemenu-icon/new/sub/general-quick-reply-grey.svg');
			}

			.personalQuickReply {
				background-image: url('../../../assets/img/sidemenu-icon/new/sub/personal-quick-reply-grey.svg');
			}

			.billing {
				background-image: url('../../../assets/img/sidemenu-icon/new/sub/billing-grey.svg');
			}

			.organizationDetails {
				background-image: url('../../../assets/img/sidemenu-icon/new/sub/organization-grey.svg');
			}

			.labels {
				background-image: url('../../../assets/img/sidemenu-icon/new/sub/tag-grey.svg');
			}

			.broadcastMessage {
				background-image: url('../../../assets/img/sidemenu-icon/new/sub/broadcast-message-grey.svg');
			}

			.userContact {
				background-image: url('../../../assets/img/sidemenu-icon/new/sub/user-contact-grey.svg');
			}

			.userSegment {
				background-image: url('../../../assets/img/sidemenu-icon/new/sub/user-segment-grey.svg');
			}

			.topup {
				background-image: url('../../../assets/img/sidemenu-icon/new/sub/top-up-grey.svg');
			}

			.officeHour {
				background-image: url('../../../assets/img/sidemenu-icon/new/sub/office-hour-grey.svg');
			}

			.welcomeMessage {
				background-image: url('../../../assets/img/sidemenu-icon/new/sub/welcome-message-grey.svg');
			}

			.awayMessage {
				background-image: url('../../../assets/img/sidemenu-icon/new/sub/away-message-grey.svg');
			}

			.closingMessage {
				background-image: url('../../../assets/img/sidemenu-icon/new/sub/closing-message-grey.svg');
			}

			.userRating {
				background-image: url('../../../assets/img/sidemenu-icon/new/sub/user-rating-grey.svg');
			}

			.inboxSetting {
				background-image: url('../../../assets/img/sidemenu-icon/new/sub/inbox-setting-grey.svg');
			}

			.quickReply {
				background-image: url('../../../assets/img/sidemenu-icon/new/sub/inbox-quick-reply-grey.svg');
			}

			.dailyOverview {
				background-image: url('../../../assets/img/sidemenu-icon/new/sub/daily-overview-grey.svg');
			}

			.overview {
				background-image: url('../../../assets/img/sidemenu-icon/new/sub/overview-grey.svg');
			}

			.caseReport {
				background-image: url('../../../assets/img/sidemenu-icon/new/sub/case-report-grey.svg');
			}

			.caseRatings {
				background-image: url('../../../assets/img/sidemenu-icon/new/sub/case-ratings-grey.svg');
			}

			.agentReport {
				background-image: url('../../../assets/img/sidemenu-icon/new/sub/agent-report-grey.svg');
			}

			.clockInReport {
				background-image: url('../../../assets/img/sidemenu-icon/new/sub/clock-in-report-grey.svg');
			}

			.assignmentRules {
				background-image: url('../../../assets/img/sidemenu-icon/new/sub/assignment-rules-grey.svg');
			}

			.topicReport {
				background-image: url('../../../assets/img/sidemenu-icon/new/sub/topic-report-grey.svg');
			}

			.messageApiHistory {
				background-image: url('../../../assets/img/sidemenu-icon/new/sub/message-history-grey.svg');
			}

			.utm {
				background-image: url('../../../assets/img/sidemenu-icon/new/sub/utm-grey.svg');
			}

			.messageUserSummary {
				background-image: url('../../../assets/img/sidemenu-icon/new/sub/message-user-summary-grey.svg');
			}

			.faq {
				background-image: url('../../../assets/img/sidemenu-icon/new/sub/faq-grey.svg');
			}

			.contactInfoCustomField {
				background-image: url('../../../assets/img/sidemenu-icon/new/sub/contact-info-custom-field-grey.svg');
			}

			.settings {
				background-image: url('../../../assets/img/sidemenu-icon/new/sub/inbox-setting-grey.svg');
			}

			.importContact {
				background-image: url('../../../assets/img/sidemenu-icon/new/sub/import-contact-grey.svg');
			}
		}

		&.active-sub-menu {
			@include submenuOrange;

			.secretKeys {
				background-image: url('../../../assets/img/sidemenu-icon/new/sub/secret-keys-orange.svg');
			}

			.pushNotification {
				background-image: url('../../../assets/img/sidemenu-icon/new/sub/push-notif-orange.svg');
			}

			.profile {
				background-image: url('../../../assets/img/sidemenu-icon/new/sub/profile-orange.svg');
			}

			.members {
				background-image: url('../../../assets/img/sidemenu-icon/new/sub/member-orange.svg');
			}

			.setup {
				background-image: url('../../../assets/img/sidemenu-icon/new/sub/setup-orange.svg');
			}

			.topics {
				background-image: url('../../../assets/img/sidemenu-icon/new/sub/topic-orange.svg');
			}

			.roles {
				background-image: url('../../../assets/img/sidemenu-icon/new/sub/roles-orange.svg');
			}


			.security {
				background-image: url('../../../assets/img/sidemenu-icon/new/sub/lock-orange.svg');
			}

			.generalQuickReply {
				background-image: url('../../../assets/img/sidemenu-icon/new/sub/general-quick-reply-orange.svg');
			}

			.personalQuickReply {
				background-image: url('../../../assets/img/sidemenu-icon/new/sub/personal-quick-reply-orange.svg');
			}

			.billing {
				background-image: url('../../../assets/img/sidemenu-icon/new/sub/billing-orange.svg');
			}

			.organizationDetails {
				background-image: url('../../../assets/img/sidemenu-icon/new/sub/organization-orange.svg');
			}

			.labels {
				background-image: url('../../../assets/img/sidemenu-icon/new/sub/tag-orange.svg');
			}

			.broadcastMessage {
				background-image: url('../../../assets/img/sidemenu-icon/new/sub/broadcast-message-orange.svg');
			}

			.userContact {
				background-image: url('../../../assets/img/sidemenu-icon/new/sub/user-contact-orange.svg');
			}

			.userSegment {
				background-image: url('../../../assets/img/sidemenu-icon/new/sub/user-segment-orange.svg');
			}

			.topup {
				background-image: url('../../../assets/img/sidemenu-icon/new/sub/top-up-orange.svg');
			}

			.officeHour {
				background-image: url('../../../assets/img/sidemenu-icon/new/sub/office-hour-orange.svg');
			}

			.welcomeMessage {
				background-image: url('../../../assets/img/sidemenu-icon/new/sub/welcome-message-orange.svg');
			}

			.awayMessage {
				background-image: url('../../../assets/img/sidemenu-icon/new/sub/away-message-orange.svg');
			}

			.closingMessage {
				background-image: url('../../../assets/img/sidemenu-icon/new/sub/closing-message-orange.svg');
			}

			.userRating {
				background-image: url('../../../assets/img/sidemenu-icon/new/sub/user-rating-orange.svg');
			}

			.inboxSetting {
				background-image: url('../../../assets/img/sidemenu-icon/new/sub/inbox-settings-orange.svg');
			}

			.quickReply {
				background-image: url('../../../assets/img/sidemenu-icon/new/sub/inbox-quick-reply-orange.svg');
			}

			.dailyOverview {
				background-image: url('../../../assets/img/sidemenu-icon/new/sub/daily-overview-orange.svg');
			}

			.overview {
				background-image: url('../../../assets/img/sidemenu-icon/new/sub/overview-orange.svg');
			}

			.caseReport {
				background-image: url('../../../assets/img/sidemenu-icon/new/sub/case-report-orange.svg');
			}

			.caseRatings {
				background-image: url('../../../assets/img/sidemenu-icon/new/sub/case-ratings-orange.svg');
			}

			.agentReport {
				background-image: url('../../../assets/img/sidemenu-icon/new/sub/agent-report-orange.svg');
			}

			.clockInReport {
				background-image: url('../../../assets/img/sidemenu-icon/new/sub/clock-in-report-orange.svg');
			}

			.assignmentRules {
				background-image: url('../../../assets/img/sidemenu-icon/new/sub/assignment-rules-orange.svg');
			}

			.topicReport {
				background-image: url('../../../assets/img/sidemenu-icon/new/sub/topic-report-orange.svg');
			}

			.messageApiHistory {
				background-image: url('../../../assets/img/sidemenu-icon/new/sub/message-history-orange.svg');
			}

			.utm {
				background-image: url('../../../assets/img/sidemenu-icon/new/sub/utm-orange.svg');
			}

			.messageUserSummary {
				background-image: url('../../../assets/img/sidemenu-icon/new/sub/message-user-summary-orange.svg');
			}

			.faq {
				background-image: url('../../../assets/img/sidemenu-icon/new/sub/faq-orange.svg');
			}

			.autoResolve {
				background-image: url('../../../assets/img/sidemenu-icon/new/sub/auto-resolve-orange.svg');
			}

			.autoUnassign {
				background-image: url('../../../assets/img/sidemenu-icon/new/sub/auto-unassign-orange.svg');
			}

			.contactInfoCustomField {
				background-image: url('../../../assets/img/sidemenu-icon/new/sub/contact-info-custom-field-orange.svg');
			}

			.settings {
				background-image: url('../../../assets/img/sidemenu-icon/new/sub/inbox-settings-orange.svg');
			}

			.importContact {
				background-image: url('../../../assets/img/sidemenu-icon/new/sub/import-contact-orange.svg');
			}
		}

		&.integration-submenu {
			padding-left: 44px;
			margin-bottom: 4px;

			.sub-menu-icon {
				width: 24px;
				height: 24px;
				left: 12px;

				&.launcher {
					background-image: url('../../../assets/img/integration/logo-taptalk-side-menu.svg');
				}

				&.telegram {
					background-image: url('../../../assets/img/integration/logo-telegram-side-menu.svg');
				}

				&.whatsapp {
					background-image: url('../../../assets/img/integration/logo-whatsapp-side-menu.svg');
				}

				&.twitter {
					background-image: url('../../../assets/img/integration/logo-twitter-side-menu.svg');
				}

				&.line {
					background-image: url('../../../assets/img/integration/logo-line-side-menu.svg');
				}

				&.apiKeys {
					background-image: url('../../../assets/img/integration/logo-api-keys-side-menu.svg');
				}

				&.facebook {
					background-image: url('../../../assets/img/integration/logo-facebook-side-menu.svg');
				}

				&.whatsappba {
					background-image: url('../../../assets/img/integration/logo-whatsapp-business.svg');
				}

				&.instagram {
					background-image: url('../../../assets/img/integration/logo-instagram-side-menu.svg');
				}

				&.chatbots {
					background-image: url('../../../assets/img/integration/logo-chatbot-side-menu.svg');
				}

				&.webhook2 {
					background-image: url('../../../assets/img/integration/logo-status-webhook.svg');
				}

				&.salestalk {
					background-image: url('../../../assets/img/integration/logo-salestalk-side-menu.svg');
				}

				&.webhook {
					background-image: url('../../../assets/img/integration/logo-webhook-side-menu.svg');
				}

				&.google_business {
					background-image: url('../../../assets/img/integration/logo-google-side-menu.svg');
				}

				&.gb_profile {
					background-image: url('../../../assets/img/integration/logo-google-profile-side-menu.svg');
				}

				&.channels {
					background-image: url('../../../assets/img/integration/logo-channel-side-menu.svg');
				}

				&.tokopedia {
					background-image: url('../../../assets/img/integration/logo-tokopedia-side-menu.svg');
				}

				&.email {
					background-image: url('../../../assets/img/integration/logo-email-side-menu.svg');
				}

				&.kataai {
					background-image: url('../../../assets/img/integration/logo-kataai-side-menu.svg');
				}

				&.miitel {
					background-image: url('../../../assets/img/integration/logo-miitel-side-menu.svg');
				}
			}
		}
	}
}