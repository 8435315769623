.request-message-template-whatsapp {
  position: relative;
  width: 100%;
}

.request-message-template-whatsapp {
  .request-message-template-whatsapp-top {
    padding: 0px 0 28px;
    border-bottom: solid 1px #dcdcdc;
    font-size: 20px;
    letter-spacing: -0.6px;
    font-weight: 700;

    .request-message-template-whatsapp-top-button {
        position: absolute;
        right: 35px;
        top: 35px;
        font-size: 16px;
        letter-spacing: -0.5px;
        padding: 7px 32px;

        img {
            margin-right: 4px;
            position: relative;
            top: -2px;
        }
    }
  }
}

.modal-view-message {
  .modal-content {
    padding: 32px;
    width: 477px;
  }

  .modal-body {
    padding: 0;

    .header-modal {
      margin-bottom: 24px;

      b {
        font-size: 20px;
      }

      svg {
        margin-right: 4px;
        position: relative;
        top: -3px;
      }
    }

    .message-container {
      label {
        font-size: 14px;
      }

      input {
        display: flex;
        align-items: center;
        background: white;
        border-radius: 8px;
        border: 1px solid rgba(25, 25, 25, 0.1);
        height: 48px;
        width: 100%;
        color: #191919;
        font-size: 16px;
        letter-spacing: -0.3px;
        max-width: 100%;
        padding: 0 16px;
        margin-bottom: 24px;

        &:disabled {
          background: rgba(25, 25, 25, 0.05);
          cursor: not-allowed;
        }
      }

      textarea {
        background: white;
        border-radius: 8px;
        border: 1px solid rgba(25, 25, 25, 0.1);
        min-height: 48px;
        width: 100%;
        color: #191919;
        font-size: 16px;
        letter-spacing: -0.3px;
        max-width: 100%;
        padding: 12px 16px;
        line-height: 24px;
        resize: none;
        margin-bottom: 20px;

        &:disabled {
          background: rgba(25, 25, 25, 0.05);
          cursor: not-allowed;
        }
      }
    }
  }
}

.table-request-message-template-list {
  table-layout: fixed;

  thead {
    tr {
      background: transparent;

      td {
        padding: 8px;
        width: 20%;
        font-size: 14px;
        word-wrap: break-word;
        white-space: normal;
        position: relative;

        .question-circle-wrapper {
          margin-left: 5px;
          position: absolute;
          top: 25%;
        }

        &:first-child {
          width: 200px;
        }

        &:nth-child(2) {
          width: 300px;
        }

        &:nth-child(3) {
          width: 200px;
          img {
            margin-left: 5px;
            vertical-align: -1.5px;
          }
        }

        &:last-child {
          width: 100%;
          text-align: right;

          .custom-select-option-value-wrapper {
            width: 55px !important;
            height: 23px;
            padding: 2px 8px 2px 12px !important;
            border-radius: 4px !important;
            visibility: hidden;

            p {
              font-size: 12px;
              font-weight: bold;
            }

            .custom-select-option-value {
              padding: 0;
            }

            svg {
              right: 3px;
              height: 15px;
              top: 11px;
            }
          }
        }
      }
    }
  }

  tbody {
    tr {
      // cursor: pointer;
      border-bottom: none;

      &:hover {
        background-color: unset;

        td {
          color: unset;
        }
      }

      td:not(.no-data-column) {
        padding: 8px 10px;
        p {
          margin-bottom: 0;
        }
      }

      td {
        background: transparent;
        position: relative;
        word-wrap: break-word;
        vertical-align: top;

        .unavailable {
          color: rgba(25, 25, 25, 0.4);
        }

        .link-user {
          color: inherit;
          text-decoration: inherit;
        }

        &:nth-child(2) {
          p {
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
          }
        }

        &.col-view {
          color: #ff7e00;
          cursor: pointer;
          font-weight: 700;
          text-align: right;

          a {
            color: #ff7e00;
          }

          svg {
            position: relative;
            color: #ff7e00;
            font-size: 16px;
            margin-right: 4px;
            right: 0;
            top: -1px;
          }
        }
      }
    }
  }

  #pagination-wrapper {
    visibility: hidden;
    ul {
      li {
        font-size: 12px !important;
        position: relative !important;

        &:not(.pagination-button) {
          top: 6px !important;
        }

        span {
          position: absolute;
          top: -42px;
          display: inline-block;
          left: 50%;
          transform: translate(-50%, 0);
        }

        &.active {
          width: 30px !important;
          height: 30px !important;

          span {
            top: -40px !important;
          }
        }

        &.pagination-label {
          top: 5px !important;

          svg {
            height: 12px;
          }
        }
      }
    }
  }
}
