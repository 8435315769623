.modal-preview-message {
  position: fixed;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100%;
  background: rgba(25, 25, 25, 0.8);
  visibility: hidden;
  opacity: 0;
  right: 0;
  transition: visibility 0.3s, opacity 0.5s ease-in-out;
  z-index: 9999;

  .media-upload-wrapper {
    .media-upload-wrapper-left {
        width: 200px;
        display: inline-block;
        vertical-align: top;
        position: relative;
        margin-top: 24px;
        padding-right: 12px;

        label {
            margin: 0;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            padding-right: 12px;
        }
    }

    input[type="file"] {
        display: none;
    }

    .media-upload-wrapper-right {
        width: calc(100% - 200px);
        display: inline-block;
        vertical-align: top;
        margin-top: 24px;

        button {
            margin: 0;
            text-transform: capitalize;
            width: unset;
        }

        .upload-info {
          font-size: 12px;
          letter-spacing: -0.4px;
          margin-top: 10px;
          position: relative;

          .replace-media {
            font-size: 12px;
            letter-spacing: -0.4px;
            border-radius: 4px;
            padding: 2px 12px 2px 8px;
            width: unset;
            text-transform: capitalize;
            display: block;
            margin: 0 0 8px 0;
          }

          svg {
            position: relative;
            top: -1px;
            margin-right: 4px;
          }
      }

        .image-video-wrapper {
            width: 48px;
            height: 48px;
            position: relative;
            border-radius: 6px;
            overflow: hidden;

            .progress-wrapper {
                position: absolute;
                top: 0;
                left: 0;
                background: rgba(25, 25, 25, 0.4);
                width: 100%;
                height: 100%;

                .CircularProgressbar {
                    width: calc(100% - 12px);
                    height: calc(100% - 12px);
                    position: absolute;
                    top: 6px;
                    left: 6px;

                    .CircularProgressbar-trail {
                        stroke: transparent;
                        stroke-width: 20;
                    }
                    
                    .CircularProgressbar-path {
                        stroke: #ffffff;
                    }
                } 
            }

            img {
                width: 100%;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                max-width: 100%;
                max-height: 100%;
                object-fit: contain;
            }

            video {
                width: 100%;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                max-width: 100%;
                max-height: 100%;
                object-fit: contain;
            }
        }

        .document-wrapper {
            font-size: 14px;
            letter-spacing: -0.2px;


            svg {
                color: #ff7d00;
                font-size: 16px;
                margin-right: 4px;
                position: relative;
                top: -2px;    
            }
        }
    }
  }

  &.active-modal-preview-message {
    /* right: 0; */
    opacity: 1;
    visibility: visible;
    transition: visibility 0s, opacity 0.5s ease-in-out;
  }

  .modal-body {
    position: absolute;
    top: 8px;
    right: -100%;
    left: unset;
    transform: unset;
    height: calc(100% - 8px);
    width: 600px;
    display: inline-block;
    border-radius: 8px 0 0 0;
    background: #ffffff;
    padding: 0;
    max-height: calc(100% - 8px);
    // height: 100%;
    transition: ease-in-out 0.4s;

    &.action-modal-body-preview-message {
      right: 0;
    }

    .close-modal-start-conversation {
      border-radius: 50%;
      width: 48px;
      height: 48px;
      background: #ffffff;
      color: #757575;
      position: absolute;
      left: -65px;
      top: 0;
      text-align: center;
      cursor: pointer;

      &:hover {
        background: #f9f9f9;
      }

      svg {
        position: relative;
        font-size: 25px;
        top: 10px;
      }
    }

    .preview-message-wrapper {
      height: 100%;
      overflow: hidden;

      .comp-back-button {
        margin: 0 0 24px;
      }

      label {
        display: inline-block !important;
      }

      .preview-message-container {
        padding: 32px;

        .preview-message-title {
          color: #191919;

          b {
            font-size: 20px;
            letter-spacing: -0.6px;
          }

          svg {
            font-size: 24px;
            margin: 0 4px 0 0;
            position: relative;
            top: -2px;
          }

          p {
            margin: 2px 0 0;
            color: rgba(25, 25, 25, 0.6);
            font-size: 14px;
            letter-spacing: -0.2px;
          }
        }

        .preview-message-body {
          margin-top: 24px;
        }

        .preview-message-param-wrapper {
          margin-top: 24px;

          .preview-message-param-title {
            // position: relative;
            display: flex;
          }

          .line {
            content: "";
            // position: absolute;
            max-width: 350px;
            margin-left: 10px;
            align-self: center;
            // top: 50%;
            // left: 175px;
            background: linear-gradient(90deg, rgba(25, 25, 25, 0) 0%, rgba(25, 25, 25, 0.16) 100%);
            // border-top: 1px linear-gradient(90deg, rgba(25, 25, 25, 0) 0%, rgba(25, 25, 25, 0.16) 100%);
            // background: linear-gradient(90deg, rgba(25, 25, 25, 0) 0%, rgba(25, 25, 25, 0.16) 100%);
            width: 350px;
            height: 1px;
            transform: matrix(-1, 0, 0, 1, 0, 0);
          }

          span {
            margin-left: 5px;
          }

          .preview-message-param {
            margin: 24px 0 40px;
            position: relative;

            label {
              vertical-align: -10px;
              width: 188px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
            
            .input-param-wrapper {
              position: absolute;
              right: 0;
              width: 336px;
              max-width: calc(100% - 204px);
              height: 48px;
              left: 200px;
              top: 0px;
              background: #ffffff;
              box-sizing: border-box;
            }
    
            .input-info-text {
                margin: 0;
                width: 100%;
                position: absolute;
                left: 0;
                top: 52px;
                line-height: 1;
    
                svg {
                    top: 0px;
                }
            }

            input {
              padding: 12px 16px;
              width: 336px;
              height: 48px;
              background: #ffffff;
              border: 1px solid rgba(25, 25, 25, 0.1);
              box-sizing: border-box;
              border-radius: 8px;
            }

            .input-info-text {
              margin: 0;
              width: 100%;
              position: absolute;
              left: 0;
              top: 52px;
              line-height: 1;
  
              svg {
                  top: 0px;
              }
            }

            input:focus {
              border: 1px solid #ff7e00;
            }
          }
        }

        .preview-message-button {
          button {
            margin-top: 24px;
          }
        }
      }
    }
  }
}
