@charset "UTF-8";
.modal-image-video-preview {
  width: 100% !important; }
  .modal-image-video-preview .modal-content {
    width: 100%;
    height: 100%;
    background: unset; }
    .modal-image-video-preview .modal-content .modal-body {
      padding: 56px 86px;
      position: relative; }
      .modal-image-video-preview .modal-content .modal-body img {
        max-width: calc(100% - 112px);
        max-height: calc(100% - 172px);
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background: #ffffff; }
      .modal-image-video-preview .modal-content .modal-body svg {
        font-size: 24px;
        color: #ffffff;
        cursor: pointer;
        position: absolute;
        top: 44px;
        right: 44px; }
  .modal-image-video-preview img {
    max-width: 80vw;
    max-height: 80vh;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }
  .modal-image-video-preview video {
    max-width: 80vw;
    max-height: 80vh;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }

.modal-forward-message {
  position: fixed;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100%;
  background: rgba(25, 25, 25, 0.8);
  visibility: hidden;
  opacity: 0;
  right: 0;
  transition: visibility .3s, opacity 0.5s ease-in-out;
  z-index: 100; }
  .modal-forward-message.active-modal-forward-message {
    /* right: 0; */
    opacity: 1;
    visibility: visible;
    transition: visibility 0s, opacity 0.5s ease-in-out; }
  .modal-forward-message .modal-body {
    position: absolute;
    top: 8px;
    right: -100%;
    left: unset;
    transform: unset;
    height: calc(100% - 8px);
    width: 600px;
    display: inline-block;
    border-radius: 8px 0 0 0;
    background: #ffffff;
    padding: 0;
    max-height: calc(100% - 8px);
    transition: ease-in-out .4s; }
    .modal-forward-message .modal-body.action-modal-body-new-conversation {
      right: 0; }
    .modal-forward-message .modal-body .custom-select-topic button {
      margin-top: 0; }
    .modal-forward-message .modal-body .close-modal-start-conversation {
      border-radius: 50%;
      width: 48px;
      height: 48px;
      background: #ffffff;
      color: #757575;
      position: absolute;
      left: -65px;
      top: 0;
      text-align: center;
      cursor: pointer; }
      .modal-forward-message .modal-body .close-modal-start-conversation:hover {
        background: #f9f9f9; }
      .modal-forward-message .modal-body .close-modal-start-conversation svg {
        position: relative;
        font-size: 25px;
        top: 10px; }
    .modal-forward-message .modal-body .new-conversation-wrapper {
      height: calc(100% - 72px);
      max-height: calc(100% - 72px);
      overflow: hidden; }
      .modal-forward-message .modal-body .new-conversation-wrapper .comp-back-button {
        margin: 0 0 24px; }
      .modal-forward-message .modal-body .new-conversation-wrapper label {
        display: inline-block !important; }
      .modal-forward-message .modal-body .new-conversation-wrapper .new-conversation-container {
        padding: 32px; }
        .modal-forward-message .modal-body .new-conversation-wrapper .new-conversation-container .new-conversation-title {
          color: #191919; }
          .modal-forward-message .modal-body .new-conversation-wrapper .new-conversation-container .new-conversation-title b {
            font-size: 20px;
            letter-spacing: -0.6px; }
          .modal-forward-message .modal-body .new-conversation-wrapper .new-conversation-container .new-conversation-title img {
            margin: 0 4px 0 0;
            position: relative;
            top: -3px; }
          .modal-forward-message .modal-body .new-conversation-wrapper .new-conversation-container .new-conversation-title p {
            margin: 2px 0 0;
            color: rgba(25, 25, 25, 0.6);
            font-size: 14px;
            letter-spacing: -0.2px; }
        .modal-forward-message .modal-body .new-conversation-wrapper .new-conversation-container .form-label {
          width: 100%;
          position: relative;
          font-size: 14px;
          letter-spacing: -0.6px;
          color: #191919; }
          .modal-forward-message .modal-body .new-conversation-wrapper .new-conversation-container .form-label.form-label-phone {
            margin: 24px 0 0; }
          .modal-forward-message .modal-body .new-conversation-wrapper .new-conversation-container .form-label p {
            margin: 0 8px 0 0;
            width: 142px;
            display: inline-block; }
        .modal-forward-message .modal-body .new-conversation-wrapper .new-conversation-container label span {
          letter-spacing: -0.2px;
          font-size: 14px;
          color: rgba(25, 25, 25, 0.6); }
        .modal-forward-message .modal-body .new-conversation-wrapper .new-conversation-container label.button-upload-image {
          margin: 0 4px;
          color: #ff7d00;
          letter-spacing: -0.4px;
          font-size: 12px;
          border-radius: 4px;
          cursor: pointer;
          display: inline-block !important;
          padding: 2px 8px;
          background: rgba(255, 126, 0, 0.1);
          -webkit-transition: ease-in-out .1s;
          transition: ease-in-out .1s; }
          .modal-forward-message .modal-body .new-conversation-wrapper .new-conversation-container label.button-upload-image:hover {
            color: #ffffff;
            background: #ff7d00; }
          .modal-forward-message .modal-body .new-conversation-wrapper .new-conversation-container label.button-upload-image svg {
            position: relative;
            top: -1px;
            margin-right: 4px; }
        .modal-forward-message .modal-body .new-conversation-wrapper .new-conversation-container textarea {
          width: 100%;
          height: 96px; }
        .modal-forward-message .modal-body .new-conversation-wrapper .new-conversation-container .image-and-caption-wrapper {
          width: 100%; }
        .modal-forward-message .modal-body .new-conversation-wrapper .new-conversation-container input[type="file"] {
          display: none; }
        .modal-forward-message .modal-body .new-conversation-wrapper .new-conversation-container .phone-number-form {
          color: #191919;
          font-size: 16px;
          letter-spacing: -0.3px;
          margin-top: 8px; }
          .modal-forward-message .modal-body .new-conversation-wrapper .new-conversation-container .phone-number-form .dropdown-menu {
            border: 1px solid rgba(0, 0, 0, 0.15);
            transform: translate3d(0px, 50px, 0px) !important; }
          .modal-forward-message .modal-body .new-conversation-wrapper .new-conversation-container .phone-number-form input[type="text"] {
            width: 100%;
            padding: 12px 16px;
            border: solid 1px rgba(25, 25, 25, 0.1);
            border-radius: 8px;
            box-shadow: none;
            outline: none; }
            .modal-forward-message .modal-body .new-conversation-wrapper .new-conversation-container .phone-number-form input[type="text"]:focus {
              border-color: #ff7d00; }
          .modal-forward-message .modal-body .new-conversation-wrapper .new-conversation-container .phone-number-form .phone-wrapper {
            width: calc(100% - 150px);
            display: inline-block;
            vertical-align: top; }
            .modal-forward-message .modal-body .new-conversation-wrapper .new-conversation-container .phone-number-form .phone-wrapper input {
              height: 48px; }
            .modal-forward-message .modal-body .new-conversation-wrapper .new-conversation-container .phone-number-form .phone-wrapper p {
              color: #ff3f57;
              font-size: 12px;
              letter-spacing: -0.4px;
              margin: 4px 0 0;
              width: 100%;
              position: relative;
              padding: 0 0 0 20px; }
              .modal-forward-message .modal-body .new-conversation-wrapper .new-conversation-container .phone-number-form .phone-wrapper p svg {
                margin-right: 4px;
                position: absolute;
                left: 0;
                top: 1px;
                font-size: 16px; }
          .modal-forward-message .modal-body .new-conversation-wrapper .new-conversation-container .phone-number-form.phone-number-disabled input[type="text"] {
            background: rgba(25, 25, 25, 0.1);
            border: none;
            cursor: not-allowed; }
      .modal-forward-message .modal-body .new-conversation-wrapper .start-conversation-contact-wrapper label {
        margin: 0 0 8px; }
      .modal-forward-message .modal-body .new-conversation-wrapper .start-conversation-contact-wrapper .add-by-phone-number {
        display: inline-block;
        font-size: 12px;
        letter-spacing: .2px;
        cursor: pointer;
        padding: 0 10px;
        margin: 0 0 0 4px;
        border-radius: 4px; }
      .modal-forward-message .modal-body .new-conversation-wrapper .start-conversation-contact-wrapper .search-contact-wrapper {
        position: relative; }
        .modal-forward-message .modal-body .new-conversation-wrapper .start-conversation-contact-wrapper .search-contact-wrapper input {
          width: 100%;
          border: solid 1px #dcdcdc;
          padding: 12px 50px 12px 16px;
          border-radius: 8px;
          margin: 0;
          font-size: 16px;
          letter-spacing: -0.3px; }
          .modal-forward-message .modal-body .new-conversation-wrapper .start-conversation-contact-wrapper .search-contact-wrapper input:focus {
            border: solid 1px #ff7d00; }
        .modal-forward-message .modal-body .new-conversation-wrapper .start-conversation-contact-wrapper .search-contact-wrapper svg {
          position: absolute;
          top: 50%;
          right: 16px;
          transform: translate(0, -50%);
          font-size: 20px; }
      .modal-forward-message .modal-body .new-conversation-wrapper .start-conversation-contact-wrapper .selected-contact {
        position: relative;
        font-size: 14px;
        letter-spacing: -.4px;
        color: rgba(25, 25, 25, 0.6);
        border-radius: 4px;
        padding: 4px 24px 3px 8px;
        background: rgba(25, 25, 25, 0.05);
        display: inline-block;
        word-break: break-word; }
        .modal-forward-message .modal-body .new-conversation-wrapper .start-conversation-contact-wrapper .selected-contact svg {
          position: relative;
          top: -2px;
          margin-right: 4px;
          font-size: 16px; }
        .modal-forward-message .modal-body .new-conversation-wrapper .start-conversation-contact-wrapper .selected-contact .remove-contact {
          position: absolute;
          border-radius: 50%;
          background: #707070;
          right: 7px;
          top: 50%;
          transform: translate(0, -50%);
          width: 13px;
          height: 13px;
          cursor: pointer;
          text-align: center; }
          .modal-forward-message .modal-body .new-conversation-wrapper .start-conversation-contact-wrapper .selected-contact .remove-contact svg {
            font-size: 9px;
            position: absolute;
            top: 2px;
            left: 2px;
            color: #ffffff;
            margin: 0; }
          .modal-forward-message .modal-body .new-conversation-wrapper .start-conversation-contact-wrapper .selected-contact .remove-contact:hover {
            background: rgba(112, 112, 112, 0.8); }
      .modal-forward-message .modal-body .new-conversation-wrapper .start-conversation-contact-wrapper .add-phone-number-info {
        font-size: 12px;
        letter-spacing: -0.4px;
        color: #191919;
        margin: 8px 0 0; }
        .modal-forward-message .modal-body .new-conversation-wrapper .start-conversation-contact-wrapper .add-phone-number-info svg {
          position: relative;
          margin-right: 4px;
          top: -1px;
          font-size: 16px; }
      .modal-forward-message .modal-body .new-conversation-wrapper .start-conversation-contact-wrapper .dropdown-contact-list {
        position: absolute;
        left: 0;
        top: 52px;
        width: 100%;
        border-radius: 8px;
        max-height: 193px;
        overflow-y: auto;
        background: #ffffff;
        border: solid 1px #dcdcdc;
        z-index: 2;
        overflow-x: hidden !important; }
        .modal-forward-message .modal-body .new-conversation-wrapper .start-conversation-contact-wrapper .dropdown-contact-list .option-contact {
          font-size: 14px;
          letter-spacing: -.2px;
          border-bottom: solid 1px #dcdcdc;
          border-radius: 0;
          padding: 8px 20px;
          cursor: pointer;
          position: relative;
          word-break: break-word; }
          .modal-forward-message .modal-body .new-conversation-wrapper .start-conversation-contact-wrapper .dropdown-contact-list .option-contact.selected-number-active {
            padding: 8px 20px 8px 36px;
            background: #FFF2E5; }
            .modal-forward-message .modal-body .new-conversation-wrapper .start-conversation-contact-wrapper .dropdown-contact-list .option-contact.selected-number-active::before {
              color: #ff7e00;
              content: '✔';
              position: absolute;
              left: 15px;
              top: 50%;
              transform: translate(0, -50%); }
          .modal-forward-message .modal-body .new-conversation-wrapper .start-conversation-contact-wrapper .dropdown-contact-list .option-contact:nth-child(1) {
            border-top-left-radius: 8px;
            border-top-right-radius: 8px; }
          .modal-forward-message .modal-body .new-conversation-wrapper .start-conversation-contact-wrapper .dropdown-contact-list .option-contact span {
            font-size: 12px;
            display: block;
            color: rgba(25, 25, 25, 0.6); }
          .modal-forward-message .modal-body .new-conversation-wrapper .start-conversation-contact-wrapper .dropdown-contact-list .option-contact:hover {
            background: #FFF2E5; }
          .modal-forward-message .modal-body .new-conversation-wrapper .start-conversation-contact-wrapper .dropdown-contact-list .option-contact:nth-last-child(1) {
            border: none;
            border-bottom-left-radius: 8px;
            border-bottom-right-radius: 8px; }
          .modal-forward-message .modal-body .new-conversation-wrapper .start-conversation-contact-wrapper .dropdown-contact-list .option-contact.no-contact-found {
            text-align: center;
            color: rgba(25, 25, 25, 0.6);
            background: none !important; }
      .modal-forward-message .modal-body .new-conversation-wrapper .channel-type-icon-wrapper {
        display: block;
        font-size: 16px;
        letter-spacing: -0.3px;
        line-height: 1; }
        .modal-forward-message .modal-body .new-conversation-wrapper .channel-type-icon-wrapper img {
          position: relative;
          margin-right: 4px;
          top: -1px; }
      .modal-forward-message .modal-body .new-conversation-wrapper .forward-message-content-wrapper {
        position: relative; }
        .modal-forward-message .modal-body .new-conversation-wrapper .forward-message-content-wrapper .forward-message-content-box {
          padding: 12px 16px;
          width: 100%;
          border-radius: 8px;
          background: rgba(25, 25, 25, 0.1);
          font-size: 16px;
          letter-spacing: -0.3px;
          color: rgba(25, 25, 25, 0.4);
          word-break: break-word; }
          .modal-forward-message .modal-body .new-conversation-wrapper .forward-message-content-wrapper .forward-message-content-box .include-info-text {
            padding: 0 0 12px 0; }
            .modal-forward-message .modal-body .new-conversation-wrapper .forward-message-content-wrapper .forward-message-content-box .include-info-text p {
              margin: 0; }
            .modal-forward-message .modal-body .new-conversation-wrapper .forward-message-content-wrapper .forward-message-content-box .include-info-text.include-info-image-video-file {
              padding: 8px 0 0 0; }
          .modal-forward-message .modal-body .new-conversation-wrapper .forward-message-content-wrapper .forward-message-content-box .caption-text {
            margin: 0;
            padding: 8px 0 0 0; }
          .modal-forward-message .modal-body .new-conversation-wrapper .forward-message-content-wrapper .forward-message-content-box .forward-image-video-outer-wrapper {
            width: 100%;
            position: relative; }
            .modal-forward-message .modal-body .new-conversation-wrapper .forward-message-content-wrapper .forward-message-content-box .forward-image-video-outer-wrapper::before {
              width: 100%;
              height: 1px;
              background: #dcdcdc;
              position: absolute;
              left: 0;
              bottom: -8px;
              content: ""; }
          .modal-forward-message .modal-body .new-conversation-wrapper .forward-message-content-wrapper .forward-message-content-box .forward-image-video-wrapper {
            width: 56px;
            height: 56px;
            position: relative;
            border-radius: 8px;
            overflow: hidden;
            border: solid 1px rgba(25, 25, 25, 0.1);
            margin-bottom: 8px; }
            .modal-forward-message .modal-body .new-conversation-wrapper .forward-message-content-wrapper .forward-message-content-box .forward-image-video-wrapper .forward-hover-wrapper {
              background: rgba(25, 25, 25, 0.4);
              top: 0;
              left: 0;
              position: absolute;
              width: 100%;
              height: 100%;
              display: none;
              cursor: pointer; }
              .modal-forward-message .modal-body .new-conversation-wrapper .forward-message-content-wrapper .forward-message-content-box .forward-image-video-wrapper .forward-hover-wrapper svg {
                color: #fff;
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%); }
            .modal-forward-message .modal-body .new-conversation-wrapper .forward-message-content-wrapper .forward-message-content-box .forward-image-video-wrapper:hover .forward-hover-wrapper {
              display: block; }
            .modal-forward-message .modal-body .new-conversation-wrapper .forward-message-content-wrapper .forward-message-content-box .forward-image-video-wrapper img {
              max-width: 100%;
              max-height: 100%;
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%); }
            .modal-forward-message .modal-body .new-conversation-wrapper .forward-message-content-wrapper .forward-message-content-box .forward-image-video-wrapper video {
              max-width: 100%;
              max-height: 100%;
              width: 100%;
              position: absolute;
              top: 50%;
              left: 50%;
              width: 100%;
              transform: translate(-50%, -50%); }
          .modal-forward-message .modal-body .new-conversation-wrapper .forward-message-content-wrapper .forward-message-content-box .forward-file-wrapper {
            width: 100%;
            letter-spacing: -0.2px;
            background: rgba(25, 25, 25, 0.1);
            border-radius: 8px;
            padding: 12px 12px 12px 46px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            font-size: 14px;
            position: relative; }
            .modal-forward-message .modal-body .new-conversation-wrapper .forward-message-content-wrapper .forward-message-content-box .forward-file-wrapper p {
              margin: 0;
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden; }
            .modal-forward-message .modal-body .new-conversation-wrapper .forward-message-content-wrapper .forward-message-content-box .forward-file-wrapper svg {
              font-size: 20px;
              position: absolute;
              left: 17px;
              top: 50%;
              -webkit-transform: translate(0, -50%);
              transform: translate(0, -50%);
              margin-right: 4px; }
      .modal-forward-message .modal-body .new-conversation-wrapper .include-forward-info {
        position: absolute;
        right: 0;
        top: 25px;
        font-size: 12px;
        letter-spacing: -0.4px;
        color: rgba(25, 25, 25, 0.8);
        margin: 6px;
        padding: 0 0 0 26px;
        margin: 0; }
        .modal-forward-message .modal-body .new-conversation-wrapper .include-forward-info .custom-switch-wrapper {
          width: 22px;
          margin: 0;
          height: 12px;
          position: absolute;
          left: 0;
          top: 3px; }
          .modal-forward-message .modal-body .new-conversation-wrapper .include-forward-info .custom-switch-wrapper .custom-switch {
            padding: 0; }
            .modal-forward-message .modal-body .new-conversation-wrapper .include-forward-info .custom-switch-wrapper .custom-switch::before {
              width: 7px;
              height: 7px;
              left: 4px;
              bottom: 3px; }
          .modal-forward-message .modal-body .new-conversation-wrapper .include-forward-info .custom-switch-wrapper input:checked + .custom-switch:before {
            transform: translateX(8px); }
    .modal-forward-message .modal-body .send-message-button {
      width: 100%;
      padding: 12px 24px;
      background: #ffffff;
      font-size: 16px;
      letter-spacing: -0.5px;
      position: absolute;
      bottom: 0;
      left: 0;
      box-shadow: 0 2px 20px 0 rgba(25, 25, 25, 0.18); }
      .modal-forward-message .modal-body .send-message-button button {
        width: 100%;
        height: 48px; }
