.modal-warning {
    .modal-content {
        border-radius: 8px;
        width: 420px;
    }
    
    .modal-body {
        padding: 32px;
        color: #191919;
        font-size: 14px;
        letter-spacing: -0.2px;
        text-align: center;

        .title {
            font-size: 20px;
        }

        p {
            margin: 0 0 20px 0;
            color: rgba(25, 25, 25, 0.6);
        }

        svg {
            color: #FFB438;
            font-size: 64px;
        }

        button {
            width: 100%;
            margin: 0;
            height: 40px;
        }
    }
}