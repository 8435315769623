.chat-room-message-video-in-wrapper {
    width: 100%;
    padding: 0 24px;
    text-align: left;
    position: relative;
    margin-top: 16px;
    font-size: 0;
  }
  .chat-room-message-video-in-wrapper .group-sender-avatar-wrapper {
    width: 34px;
    height: 34px;
    display: inline-block;
    border-radius: 50%;
    vertical-align: top;
    margin-right: 5px;
    font-size: 16px;
    line-height: 34px;
    text-align: center;
    color: #ffffff;
    position: relative;
  }
  .chat-room-message-video-in-wrapper .group-sender-avatar-wrapper img {
    width: 34px;
    height: 34px;
    display: inline-block;
    border-radius: 50%;
  }
  .chat-room-message-video-in-wrapper .group-sender-avatar-wrapper .avatar-icon-badge {
    width: 20px;
    bottom: -2px;
    right: -1px;
    height: 20px;
    position: absolute;
  }
  .chat-room-message-video-in-wrapper .message-in-bubble-broadcast-video {
    border-radius: 2px 16px 16px;
    max-width: 300px;
    display: inline-block;
    position: relative;
    font-size: 0;
    // overflow: hidden;
    background: #ffffff;
    background: #ffffff;
    border: 1px solid rgba(25, 25, 25, 0.1);

    .message-title {
      padding: 10px 10px 0 10px;
      color: rgba(25, 25, 25, 0.4);
  
      b {
        font-size: 14px;
        letter-spacing: -.4px;
      }
      img {
        height: 17px !important;
        width: 16px !important;
        position: relative;
        top: -6px;
        margin-right: 5px !important;
      }
    }
  }
  .chat-room-message-video-in-wrapper .message-in-bubble-broadcast-video .bubble-image-wrapper {
    // position: relative;
    min-width: 150px;
    padding: 10px;

    &.bubble-wrapper-with-caption {
      padding: 10px 10px 0;
    }

    .video-inner-wrapper {
      position: relative;
    }

    img {
      border-radius: 0 16px 16px;
    }

    video {
      border-radius: 0 16px 16px;
    }

    // &.bubble-wrapper-without-caption {
    //   video {
    //     border-radius: 0 16px 16px;
    //   }
    // }

    // &.with-reply-caption {
    //   video {
    //     border-radius: 0;
    //   }

    //   img {
    //     border-radius: 0;
    //   }
    // }

    // &.with-reply-no-caption {
    //   video {
    //     border-radius: 0 16px 16px;
    //   }

    //   img {
    //     border-radius: 0 16px 16px;
    //   }
    // }
  }
  .chat-room-message-video-in-wrapper .message-in-bubble-broadcast-video .bubble-image-wrapper .icon-status-wrapper {
    border-radius: 50%;
    position: absolute;
    width: 48px;
    height: 48px;
    display: inline-block;
    background: rgba(25, 25, 25, 0.4);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
  }
  .chat-room-message-video-in-wrapper .message-in-bubble-broadcast-video .bubble-image-wrapper .icon-status-wrapper svg {
    color: #ffffff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 20px;
  }
  .chat-room-message-video-in-wrapper .message-in-bubble-broadcast-video .bubble-image-wrapper .icon-status-wrapper .onprocess-wrapper {
    height: 40px;
    width: 40px;
    display: inline-block;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .chat-room-message-video-in-wrapper .message-in-bubble-broadcast-video .bubble-image-wrapper .video-thumbnail {
    width: 100%;
  }
  .chat-room-message-video-in-wrapper .message-in-bubble-broadcast-video .main-image-message {
    width: 300px;
  }
  .chat-room-message-video-in-wrapper .message-in-bubble-broadcast-video .caption-text {
    font-size: 16px;
    letter-spacing: -.3px;
    // padding: 10px 55px 10px 10px;
    padding: 4px 10px 0 10px;
    margin: 0;
    word-break: break-word;
  }
  .chat-room-message-video-in-wrapper .message-in-bubble-broadcast-video .reply-message {
    width: calc(100% - 20px);
    border-radius: 4px;
    border-left: solid 5px #ff7d00;
    background: #f3f3f3;
    color: #191919;
    position: absolute;
    top: 10px;
    left: 10px;
    padding: 5px 7px 5px 7px;
    line-height: 1;
    z-index: 1;
  }
  .chat-room-message-video-in-wrapper .message-in-bubble-broadcast-video .reply-message p {
    margin: 0;
    font-size: 12px;
    letter-spacing: -0.4px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 20px;
  }
  .chat-room-message-video-in-wrapper .message-in-bubble-broadcast-video .reply-message p:nth-child(2) {
    letter-spacing: -0.2px;
  }
  .chat-room-message-video-in-wrapper .message-in-bubble-broadcast-video .reply-message.reply-file .reply-file-thumbnail {
    display: inline-block;
    width: 40px;
    height: 40px;
    margin-right: 10px;
    position: relative;
  }
  .chat-room-message-video-in-wrapper .message-in-bubble-broadcast-video .reply-message.reply-file .reply-file-thumbnail img {
    width: 40px;
    height: 40px;
  }
  .chat-room-message-video-in-wrapper .message-in-bubble-broadcast-video .reply-message.reply-file .reply-file-thumbnail svg {
    position: absolute;
    left: 50%;
    top: 50%;
    font-size: 35px;
    transform: translate(-50%, -50%);
  }
  .chat-room-message-video-in-wrapper .message-in-bubble-broadcast-video .reply-message.reply-file .reply-text-wrapper {
    width: calc(100% - 50px);
    display: inline-block;
    vertical-align: top;
  }
  .chat-room-message-video-in-wrapper .message-in-bubble-broadcast-video .message-info {
    // position: absolute;
    position: relative;
    margin: 0;
    white-space: nowrap;
    border-radius: 10px;
    font-size: 10px;
    letter-spacing: -0.4px;
    color: rgba(25, 25, 25, 0.4);
    // bottom: 10px;
    // right: 10px;
    // z-index: 1;
    width: 100%;
    text-align: right;
    padding: 2px 10px 10px;

    .centered-dot {
      width: 2px;
      height: 2px;
      display: inline-block;
      vertical-align: top;
      background: rgba(25, 25, 25, 0.4);
      border-radius: 50%;
      position: relative;
      top: 9px;
    }
  }
  .chat-room-message-video-in-wrapper .message-in-bubble-broadcast-video .group-sender-name-wrapper {
    color: #ff7d00;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0;
    font-size: 14px;
  }
  .chat-room-message-video-in-wrapper .message-in-bubble-broadcast-video .group-sender-name-wrapper .agent-span {
    color: #A3A3A3;
  }
  .chat-room-message-video-in-wrapper .message-in-bubble-broadcast-video .message-info-dark {
    background: rgba(25, 25, 25, 0.6);
    padding: 2px 5px;
    color: #ffffff;
    bottom: 16px;
    right: 16px;
    position: absolute;
    width: unset;

    .centered-dot {
      background: #ffffff;
    }
  }
  .chat-room-message-video-in-wrapper .message-in-bubble-broadcast-video .message-info img {
    vertical-align: top;
    margin-left: 3px;
  }
  .chat-room-message-video-in-wrapper .message-in-bubble-broadcast-video.not-sent-message-bubble {
    // overflow: hidden;
  }
  .chat-room-message-video-in-wrapper .message-in-bubble-broadcast-video.not-sent-message-bubble .dark-cover {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    border-radius: 2px 16px 16px;
  }
  .chat-room-message-video-in-wrapper .message-in-bubble-broadcast-video.not-sent-message-bubble .dark-cover .icon-status-wrapper {
    border-radius: 50%;
    position: absolute;
    width: 48px;
    height: 48px;
    display: inline-block;
    background: rgba(25, 25, 25, 0.4);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
  }
  .chat-room-message-video-in-wrapper .message-in-bubble-broadcast-video.not-sent-message-bubble .dark-cover .icon-status-wrapper svg {
    color: #ffffff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 20px;
  }
  .chat-room-message-video-in-wrapper .message-in-bubble-broadcast-video.not-sent-message-bubble .dark-cover .icon-status-wrapper .onprocess-wrapper {
    height: 40px;
    width: 40px;
    display: inline-block;
    border: solid 2px #ffffff;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .chat-room-message-video-in-wrapper .message-in-bubble-broadcast-video .timer-filesize-wrapper {
    background: rgba(25, 25, 25, 0.6);
    position: absolute;
    white-space: nowrap;
    border-radius: 10px;
    font-size: 12px;
    padding: 2px 5px;
    color: rgba(255, 255, 255, 0.6);
    top: 10px;
    left: 10px;
    z-index: 1;
  }
  .chat-room-message-video-in-wrapper .message-in-bubble-broadcast-video .play-button-wraper {
    border-radius: 50%;
    position: absolute;
    width: 48px;
    height: 48px;
    display: inline-block;
    background: rgba(25, 25, 25, 0.4);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
  }
  .chat-room-message-video-in-wrapper .message-in-bubble-broadcast-video .play-button-wraper svg {
    color: #ffffff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 20px;
  }
  .chat-room-message-video-in-wrapper:hover .message-action-wrapper {
    display: inline-block;
  }
  .chat-room-message-video-in-wrapper:hover .message-action-dropdown {
    display: inline-block;
  }
  .chat-room-message-video-in-wrapper .message-action-wrapper {
    right: -35px !important;
    left: unset !important;
  }
  .chat-room-message-video-in-wrapper .message-action-wrapper .message-action-button-wrapper {
    cursor: pointer;
  }
  .chat-room-message-video-in-wrapper .CircularProgressbar .CircularProgressbar-trail {
    stroke: transparent;
  }
  .chat-room-message-video-in-wrapper .CircularProgressbar .CircularProgressbar-path {
    stroke: #ffffff;
  }
  .modal-video.modal-dialog {
    width: 100vw !important;
  }  
  .modal-video .modal-content {
    width: 100%;
    height: 100%;
    background: none;
  }
  .modal-video .modal-content .modal-body {
    width: 100%;
    height: 100%;
    background: rgba(25, 25, 25, 0.8);
  }
  .modal-video .modal-content .modal-body .video-wrapper {
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .modal-video .modal-content .modal-body .close-modal-video {
    position: absolute;
    top: 20px;
    right: 20px;
    color: #ffffff;
    font-size: 40px;
    cursor: pointer;
    transition: ease-in-out 0.1s;
  }
  .modal-video .modal-content .modal-body .close-modal-video:hover {
    color: #c9c9c9;
  }
  .modal-video .modal-content .modal-body video {
    max-height: calc(100vh - 130px);
    max-width: calc(100vw - 130px);
  }