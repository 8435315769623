.main-custom-select-option-with-label-wrapper {
  position: relative;

  button {
    &:disabled {
      background-color: rgba(25, 25, 25, 0.1) !important;
      color: rgba(25, 25, 25, 0.4) !important;
    }
  }

  &.error-select {
    .dropdown {
      button {
        border-color: #ff3f57 !important;
      }
    }
  }
  .custom-select-option-value-wrapper {
    border-radius: 8px !important;
    width: 100% !important;
    padding: 12px 38px 12px 16px !important;
    position: relative;
    // border: solid 1px rgb(204, 204, 204) !important;
    border: solid 1px #1919191A !important;
    background-color: #ffffff !important;
    z-index: 1;
    text-align: left !important;
    outline: none !important;
    box-shadow: none !important;

    &.border-red {
      border: solid 1px #ff3f57 !important;
    }

    .custom-select-option-value {
      .option-badge {
        height: 22px;
        position: relative;
        left: 0;
        top: 1px;
        font-size: 12px;
        letter-spacing: -0.4px;
        color: #fff;
        padding: 2px 8px 0;
        display: inline-block;
        border-radius: 4px;
        margin-right: 4px;
        vertical-align: top;
      }
    }
  }

  .main-custom-select-option-with-label-wrapper.active-dropdown
    .custom-select-option-value-wrapper {
    border: solid 1px #ff7e00;
  }

  .custom-select-option-value-wrapper p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
    margin: 0 !important;
    letter-spacing: -0.2px;
    color: #191919;
    font-size: 16px;
    padding-right: 24px;
  }

  .custom-select-option-value-wrapper .icon-arrow {
    position: absolute;
    right: 13px;
    top: 50%;
    transform: translate(0, -50%);
    transition: ease-in-out 0.3s;
    font-size: 24px;
    color: #191919;
  }

  .main-custom-select-option-with-label-wrapper.active-dropdown
    .custom-select-option-value-wrapper
    .icon-arrow {
    transform: translate(0, -50%) rotate(180deg);
  }

  .custom-select-option-placeholder {
    color: rgba(25, 25, 25, 0.4) !important;
  }

  .dropdown-option-wrapper {
    /* position: absolute;
      top: 64px;
      left: 0; */
    border-radius: 8px !important;
    width: 100%;
    max-height: 150px;
    overflow-y: auto;
    padding: 5px 0;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.2);
    z-index: 2;
    background: #ffffff;
    display: none;
    outline: none;
  }

  .main-custom-select-option-with-label-wrapper.active-dropdown .dropdown-option-wrapper {
    display: block;
    z-index: 3;
  }

  .dropdown-option-wrapper {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  .dropdown-option-wrapper .dropdown-item {
    font-size: 16px;
    white-space: nowrap;
    max-width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
    color: #191919;
    padding: 10px 16px 10px 16px !important;
    position: relative;
    cursor: pointer;
    height: 54px;

    &.dropdown-item-active {
      padding: 10px 16px 10px 35px !important;
    }

    &:hover {
      background: #ffe7d0;
    }

    &:active {
      background: none;
      color: #191919;
    }

    .option-badge {
      height: 22px;
      position: relative;
      left: 0;
      top: 0;
      font-size: 12px;
      letter-spacing: -0.4px;
      color: #fff;
      padding: 2px 8px 0;
      display: inline-block;
      border-radius: 4px;
      margin-right: 4px;
      vertical-align: top;
    }
    
    p {
      display: inline-block;
      margin: 0;
      position: relative;
      top: -1px;
    }
  }

  .dropdown-option-wrapper .active-value {
    // color: #ff7e00;
    color: #191919;
    padding: 8px 16px 8px 35px !important
  }

  .dropdown-option-wrapper .active-value::before {
    position: absolute;
    left: 12px;
    top: 50%;
    transform: translate(0, -50%);
    color: #ff7e00;
    width: 16px;
    height: 16px;
    background-image: url("../../../assets//img/icon-check-orange.svg");
    background-size: 16px;
    content: "";
  }

  .dropdown-option-wrapper .active-multiple::before {
    position: absolute;
    left: 8px;
    top: 50%;
    transform: translate(0, -50%);
    width: 20px;
    height: 20px;
    background-image: url("../../../assets/img/icon-checkbox-active.svg");
    background-size: 20px;
    content: "";
  }

  .dropdown-option-wrapper .inactive-multiple::before {
    position: absolute;
    left: 8px;
    top: 50%;
    transform: translate(0, -50%);
    width: 20px;
    height: 20px;
    background-image: url("../../../assets/img/icon-checkbox-inactive.svg");
    background-size: 20px;
    content: "";
  }

  .dropdown-option-wrapper .no-data-option {
    text-align: center;
    color: rgb(128, 128, 128);
    padding: 10px 16px !important;
  }

  .loading-custom-select {
    display: inline-block;
    right: 5px;
    top: 50%;
    transform: translate(0, -50%);
    position: absolute;
  }

  .dropdown-item-page {
    .dropdown {
      width: 70px;
    }

    .dropdown-menu {
      width: 70px;
      min-width: unset;
      transform: unset !important;
      right: 0px;
      bottom: auto;
      left: 0 !important;
      top: 22px !important;
      border-radius: 4px !important;
      bottom: unset !important;

      .dropdown-item {
        padding: 0px 6px 0px 35px !important;
        font-size: 14px;
      }
    }
  }
}

