#outer-container {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  /* background: linear-gradient(to bottom, #ffab3a, #ff7d00); */
  background: url("../../assets/img/auth/bg-auth-v2.svg") no-repeat center center fixed;
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
}

.bg-img-paper-plane-left {
  position: absolute;
  top: 43%;
  left: min(calc(6% - 128px), 0px);
  width: 28.5%;
  z-index: -1;
}

.bg-img-paper-plane-right {
  position: absolute;
  top: 19%;
  right: 0;
  width: 18.6%;
  z-index: -1;
}

.bg-img-whatsapp-sme {
  position: absolute;
  top: 12%;
  left: calc(37.5% - 225px);
  min-width: 112px;
  width: 9.4%;
  z-index: -1;
}

.bg-img-telegram {
  position: absolute;
  top: 28%;
  left: calc(10% - 50px);
  min-width: 96px;
  width: 8%;
  z-index: -1;
}

.bg-img-gb-profile {
  position: absolute;
  top: 74%;
  left: calc(11% - 50px);
  min-width: 116px;
  width: 9.7%;
  z-index: -1;
}

.bg-img-google {
  position: absolute;
  top: 86%;
  left: calc(26% - 100px);
  min-width: 88px;
  width: 7.4%;
  z-index: -1;
}

.bg-img-instagram {
  position: absolute;
  top: 7%;
  right: calc(34% - 200px);
  min-width: 88px;
  width: 7.5%;
  z-index: -1;
}

.bg-img-fb-messenger {
  position: absolute;
  top: 33%;
  right: calc(10% - 50px);
  min-width: 111px;
  width: 9.3%;
  z-index: -1;
}

.bg-img-mail {
  position: absolute;
  top: 48%;
  right: calc(32.5% - 150px);
  min-width: 88px;
  width: 7.4%;
  z-index: -1;
}

.bg-img-tokopedia {
  position: absolute;
  top: 65%;
  right: calc(10% - 50px);
  min-width: 96px;
  width: 8%;
  z-index: -1;
}

.bg-img-whatsapp-ba {
  position: absolute;
  top: 80%;
  right: calc(22.5% - 100px);
  min-width: 120px;
  width: 10%;
  z-index: -1;
}

.wrapper-auth-v2 {
  padding: 0 64px;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.wrapper-auth-v2::-webkit-scrollbar {
  display: none;
}

.logo-image {
  display: block;
  margin: auto;
  margin-top: 64px;
  height: 64px;
  text-align: center;
}

.subtitle {
  margin: 0;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 4px;
  text-align: center;
  color: white;
  text-align: center;
  margin: auto;
  text-transform: uppercase;
}

.wrapper-form-center {
  /* display: flex;
  align-items: center;
  justify-content: center; */
  /* vertical-align: middle; */
  /* position: absolute; */
  margin: auto;
  margin-top: 60px;
  margin-bottom: 64px;
  width: 100%;
  max-width: 520px;
  border-radius: 32px;
  background: #ffffff;
  box-shadow: 0px 3px 22px 0px #1919194D;
  /* text-align: center; */
}

.wrapper-full-left {
  position: fixed;
  top: 0;
  left: 0;
  border-top-right-radius: 40px;
  width: 540px;
  height: 100%;
  background: #ffffff;
  text-align: left;
}

.wrapper-full-right-image {
  position: fixed;
  right: 0;
  top: 0;
  width: calc(100% - 540px);
  height: 100vh;
}

.header-centered-content {
  text-align: center;
}

.content-wrapper {
  padding: 48px;
  height: 100%;
}

.content-title {
  display: block;
  font-size: 40px;
  font-weight: 700;
  line-height: 48px;
  letter-spacing: -0.6px;
  text-align: center;
  color: #191919;
}

.content-subtitle {
  margin-top: 16px;
  display: block;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.3px;
  text-align: center;
  color: #191919CC;
}

.fail-panel {
  width: 100%;
  background: #ffc0c0;
  border-radius: 8px;
  color: #ff3f57;
  font-size: 16px;
  letter-spacing: -0.5px;
  padding: 12px 15px 10px;
  margin-bottom: 24px;
  text-align: left;
}

.fail-panel img {
  position: relative;
  top: -2px;
  margin-right: 8px;
}

.input-wrapper {
  position: relative;
  margin-top: 32px;
}

.input-wrapper label {
  margin: 0;
  font-size: 14px;
  font-weight: bold;
  line-height: 21px;
  letter-spacing: -0.4px;
  color: #191919;
}

.input-wrapper input {
  display: block;
  margin: 8px 0 0;
  padding: 12px 16px;
  width: 100%;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.3px;
  color: #191919;
  border-radius: 8px;
  border: 1px solid #1919191A;
  box-sizing: border-box;
  -webkit-box-shadow: 0 0 0 50px white inset;
  box-shadow: 0 0 0 50px white inset;
}

/* .input-wrapper input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 50px white inset;
}

.input-wrapper input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0 50px white inset;
} */

.input-wrapper .fail-state-input {
  border: solid 1px #ff3f57 !important;
}

.input-wrapper input::placeholder {
  color: #9b9b9b;
}

.input-wrapper input:focus {
  border: solid 1px var(--main-dark-orange);
  /* box-shadow: 0 0 8px 0 rgba(54, 42, 215, 0.24); */
}

.input-wrapper .input-error {
  margin: 6px 12px 0 0;
  font-size: 13px;
  /* font-style: italic; */
  letter-spacing: 0.5px;
  font-weight: 600;
  color: rgb(255, 0, 0, 0.7);
}

.input-warning {
  position: absolute;
  left: 0;
}

.input-wrapper .input-error-info {
  display: block;
  margin-top: 2px;
  width: 100%;
  color: #FF3F57;
}

.input-wrapper .input-error-info svg {
  margin-right: 4px;
  height: 16px;
}

.input-wrapper .input-error-info b {
  font-size: 12px;
  line-height: 18px;
  letter-spacing: -0.4px;
}

.input-wrapper-loading {
  position: relative;
  margin-top: 32px;
}

.input-wrapper-loading label {
  margin: 0;
  font-size: 14px;
  font-weight: bold;
  line-height: 21px;
  letter-spacing: -0.4px;
  color: #19191966;
}

.input-wrapper-loading input {
  display: block;
  margin: 8px 0 0;
  padding: 12px 16px;
  width: 100%;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.3px;
  color: #19191999;
  background: white;
  border-radius: 8px;
  border: none;
  box-sizing: border-box;
  box-shadow: 0 0 0 50px #E8E8E8 inset;
  -webkit-box-shadow: 0 0 0 50px #E8E8E8 inset;
}

.input-wrapper-loading .input-error-info {
  display: none !important;
}

/* .input-wrapper-loading input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 50px #E8E8E8 inset;
}

.input-wrapper-loading input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0 50px #E8E8E8 inset;
} */

.input-wrapper .peek-password {
  position: absolute;
  width: 48px;
  height: 48px;
  color: #191919;
  top: 30px;
  right: 4px;
  line-height: 48px;
  font-size: 24px;
  text-align: center;
  cursor: pointer;
}

.input-wrapper .peek-password svg {
  transform: translateY(-2px);
}

.input-wrapper-loading .peek-password {
  position: absolute;
  width: 48px;
  height: 48px;
  color: #191919;
  top: 30px;
  right: 4px;
  line-height: 48px;
  font-size: 24px;
  text-align: center;
  cursor: pointer;
  opacity: 0.6;
}

.input-wrapper .peek-password svg {
  transform: translateY(-2px);
}

input:focus + .peek-password {
  color: #ff7e00;
}

.content-wrapper form button {
  margin-top: 32px;
}

.login-button {
  width: 100%;
  margin-top: 10px;
  /* padding: 10px 0 12px; */
  border-radius: 8px;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  border: solid 1px #ff7e00;
  background: linear-gradient(to bottom, #ff9833, #ff7e00 100%);
  cursor: pointer;
  position: relative;
  /* height: 48px; */
}

.login-button .lds-ring {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.login-button .lds-ring div {
  border-color: #ffffff transparent transparent transparent;
  top: 0;
  left: 0;
  margin: 0;
}

.login-button:hover {
  background: linear-gradient(to bottom, #ff9833, #ef7600 100%);
}

.login-button:disabled {
  /* background: linear-gradient(to bottom, #cecece, #9b9b9b 100%); */
  cursor: not-allowed;
  border: solid 1px #cecece;
}

.forgot-password {
  display: block;
  margin-top: 32px;
  width: 100%;
  text-align: center;
}

.forgot-password-loading {
  display: block;
  margin-top: 32px;
  width: 100%;
  text-align: center;
}

.forgot-password a {
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.5px;
  color: #ff7e00;
}

.forgot-password-loading a {
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.5px;
  color: #19191999;
}

.forgot-password a:hover {
  color: #ff7e00;
}

#signup-here {
  margin-top: 8px;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.5px;
  text-align: center;
  color: #19191966;
}

#sign-in {
  margin-top: 32px;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.5px;
  text-align: center;
  color: #19191966;
}

#back-to-log-in {
  width: 100%;
  margin-top: 24px;
  font-size: 16px;
  font-weight: bold;
  line-height: 24px;
  letter-spacing: -0.5px;
  color: #FF7E00;
  text-align: center;
}

#back-to-log-in-loading {
  width: 100%;
  margin-top: 24px;
  font-size: 16px;
  font-weight: bold;
  line-height: 24px;
  letter-spacing: -0.5px;
  color: #19191966;
  text-align: center;
}

@media screen and (min-width: 300px) and (max-width: 550px) {
  .wrapper-full-left {
    width: 100%;
  }

  .wrapper-full-left .content-wrapper {
    padding: 32px 28px;
  }

  .wrapper-full-left .content-wrapper .content-title {
    font-size: 30px;
    margin: 21px 0;
  }

  .wrapper-full-left .content-wrapper .input-wrapper label,
  span {
    font-size: 15px;
  }

  .wrapper-full-left .content-wrapper .login-button {
    margin: 16px 0px !important;
    padding: 11px 0 12px;
    font-size: 15px;
  }

  .wrapper-full-left .content-wrapper .input-wrapper input[type="text"],
  input[type="password"] {
    padding: 9px 11px 11px;
    font-size: 14px;
  }

  .wrapper-full-left .content-wrapper #signup-here {
    font-size: 15px;
  }
}
