.overview-utm-report-wrapper {
    margin: 30px 0 0 0;

    .overview-utm-report-title {
        font-size: 20px;
        letter-spacing: -0.6px;
        margin: 0 0 16px 0;
    }

    .overview-utm-value-wrapper {
        padding: 0 12px 0;
        width: 100%;
        position: relative;
        display: block;

        .overview-utm-table-title {
            font-size: 16px;
            letter-spacing: -0.6px;
            width: 100%;
            position: relative;
            margin: 0;

            a {
                position: absolute;
                right: 0;
                color: var(--main-dark-orange);

                &:hover {
                    color: var(--main-darker-orange);
                }

                svg {
                    font-size: 20px;
                    position: relative;
                    margin-right: 4px;
                    top: -2px;
                }
            }

            .grey-font { 
                position: absolute;
                right: 0;
                top: 0;
                color: var(--main-dark-orange);

                &:hover {
                    color: var(--main-darker-orange);
                }

                svg {
                    font-size: 20px;
                    position: relative;
                    margin-right: 4px;
                    top: -2px;
                }
            }
        }

        .no-overview-data {
            color: rgba(25, 25, 25, 0.4);
            width: 100%;
            text-align: center;
            padding: 48px 20px 100px;
        }

        .overview-utm-table-wrapper {
            padding: 16px;
            border-radius: 8px;
            vertical-align: top;
            box-shadow: 0px 2px 20px 0px rgba(25, 25, 25, 0.05);
            background: #fff;
            width: calc(50% - 8px);
            display: inline-block;
            margin-right: 16px;
            margin-bottom: 40px;

            &:nth-child(even) {
                margin-right: 0;
            }

            &:nth-child(5) {
                margin-bottom: 0;
            }

            &:nth-child(6) {
                margin-bottom: 0;
            }

            .of-cases {
                font-size: 16px;
                letter-spacing: -0.5px;
                margin: 20px 0 16px;
                width: 100%;
                text-align: right;
            }

            .utm-val {
                border-bottom: solid 1px #dcdcdc;
                margin: 0;
                font-size: 14px;
                letter-spacing: -0.2px;
                position: relative;
                padding: 8px 76px 8px 0;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                height: 38px;

                &.onetalk-shine-1 {
                    .onetalk-shine-left { 
                        width: 120px;
                        height: 21px;
                        border-radius: 6px;
                    }
                }

                &.onetalk-shine-2 {
                    .onetalk-shine-left {
                        width: 200px;
                        height: 21px;
                        border-radius: 6px;
                    }
                }

                &.onetalk-shine-3 {
                    .onetalk-shine-left {
                        width: 160px;
                        height: 21px;
                        border-radius: 6px;
                    }
                }

                &.onetalk-shine-4 {
                    .onetalk-shine-left { 
                        width: 80px;
                        height: 21px;
                        border-radius: 6px;
                    }
                }

                &.onetalk-shine-5 {
                    .onetalk-shine-left { 
                        width: 120px;
                        height: 21px;
                        border-radius: 6px;
                    }
                }

                &.onetalk-shine-6 {
                    .onetalk-shine-left { 
                        width: 44px;
                        height: 21px;
                        border-radius: 6px;
                    }
                }

                .onetalk-shine-right {
                    width: 44px;
                    height: 21px;
                    border-radius: 6px;
                    position: absolute;
                    right: 0;
                }
                
                .utm-total {
                    position: absolute;
                    text-align: left;
                    width: 70px;
                    right: 0;
                }

                &:nth-last-child(1) {
                    padding: 8px 76px 0 0;
                    border: none;
                }
            }
        }
    }
}