.integration-auto-message-detail {
    background: #ffffff;
    border-radius: 8px;
    padding: 32px;
    border: solid 1px rgba(25, 25, 25, 0.1);
    margin-bottom: 12px;
    position: relative;

    #retrieve-data-loading-page {
        padding-top: 24px !important;
    }

    &.integration-auto-message-detail-margin-top {
        margin-top: 24px;
    }

    .loading-wrapper {
        width: 100%;
        text-align: center;
        padding-top: 24px;
        background: transparent !important;
        box-shadow: none !important;
        top: unset !important;
        transform: translate(-50%, 0) !important;

        .lds-ring {
            right: unset;
            top: unset;
            width: 38px;
            height: 38px;
    
            div {
                margin: 0;
                width: 38px;
                height: 38px;
                border: solid 4px #fff;
                border-color: #ff7e00 transparent transparent transparent;
            }
        }
    }

    .title-wrapper {
        display: block;
        width: 100%;
        position: relative;
        padding-right: 150px;
        border-bottom: solid 1px #dcdcdc;
        padding-bottom: 12px;

        img {
            width: 24px;
            height: 24px;
            margin-right: 8px;
            margin-top: 4px;
        }

        .title-description {
            display: block;

            p {
                margin: 0;
            }

            .title-customize {
                font-size: 20px;
                // text-transform: capitalize;
            }

            .description-customize {
                font-size: 14px;

                a {
                    color: #ff7e00;
                }
            }
        }

        button {
            position: absolute;
            right: 0;
            top: 8px;
        }
    }

    .detail-wrapper {
        width: 100%;
        display: block;

        .status-value {
            font-size: 16px;
            letter-spacing: -0.5px;

            img {
                margin-right: 4px;
                position: relative;
                top: -2px;
            }

            &.status-enabled {
                color: #19C700;
            }

            &.status-disabled {
                color: rgba(25, 25, 25, 0.4);
            }
        }

        label {
            font-size: 16px;
            letter-spacing: -0.5px;
            margin: 24px 0 0;
        }

        p {
            margin: 4px 0 0;
            font-size: 16px;
            letter-spacing: -0.2px;
            width: 500px;
            max-width: 100%;
            word-break: break-word;

            &.no-title {
                margin: 12px 0 0;
            }
        }

        .value-text {
            margin: 12px 0 0;
            font-size: 14px;
            letter-spacing: -0.2px;
        }
    }

    .theme-color-text {
        font-size: 18px;
        position: relative;
        padding-left: 24px;
        line-height: 1;
    }

    .theme-color {
        display: inline-block;
        position: absolute;
        vertical-align: top;
        border-radius: 50%;
        width: 18px;
        height: 18px;
        margin-right: 6px;
        left: 0;
        top: -2px;
        border: solid 1px #dcdcdc;
    }
}