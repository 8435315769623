.blocking-401-wrapper {
    text-align: center;
    margin-top: 65px;

    p {
        color: rgba(25, 25, 25, 0.6);
        font-size: 20px;
        letter-spacing: -0.6px;
        margin: 32px 0;
    }
}