.payment-setting-outer-wrapper {
    widows: 100%;
    position: relative;

    .centered-dot {
        width: 5px;
        height: 5px;
        display: inline-block;
        vertical-align: top;
        background: rgba(25, 25, 25, 0.4);
        border-radius: 50%;
        position: relative;
        top: 9px;
    }

    .card-icon {
        position: absolute;
        left: 13px;
        top: 50%;
        transform: translate(0, -50%);
    }

    .card-number {
        font-size: 16px;
        color: #191919;
        letter-spacing: -0.3px;
        position: relative;
        top: -2px;
        white-space: nowrap;
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;

        .masking-dot {
            vertical-align: top;
            width: 4px;
            height: 4px;
            position: relative;
            top: 3px;
            display: inline-block;
            margin: 0 2px;
            border-radius: 50%;
            background: #191919;
        }
    }

    .card-expiry-date {
        font-size: 12px;
        letter-spacing: -0.4px;
        color: rgba(25, 25, 25, 0.6);
        white-space: nowrap;
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis
    }

    .payment-setting {
        .payment-setting-header {
            font-size: 20px;
            letter-spacing: -0.6px;
            color: #191919;
            padding-bottom: 11px;
            border-bottom: solid 1px #dcdcdc;
            position: relative;

            button {
                position: absolute;
                right: 0;
                top: -10px;
                font-size: 16px;
                letter-spacing: -0.5px;
                padding: 0 24px;
                text-align: center;
                height: 40px;

                &.save-change-payment-setting {
                    width: 155px;
                }

                svg {
                    position: relative;
                    font-size: 20px;
                    margin-right: 4px;
                    top: -2px;
                }
            }

            .payment-setting-title-description {
                font-size: 14px;
                letter-spacing: -0.2px;
                margin: 0;
            }
        }

        .how-to-pay-wrapper {
            width: 100%;
            position: relative;

            p {
                &.how-to-pay-title {
                    letter-spacing: -0.5px;
                    font-size: 16px;
                    color: #191919;
                    margin: 24px 0;
                }
            }

            .custom-radio {
                label {
                    display: block;
                    margin-top: 24px;
                    font-size: 14px;
                    letter-spacing: -0.4px;
                }

                span {
                    display: block;
                    font-size: 14px;
                    letter-spacing: -0.2px;
                }
            }

            .auto-payment-card-select-wrapper {
                position: relative;

                .auto-payment-add-card {
                    font-size: 14px;
                    color: #FF7E00;
                    margin: 12px 0 0 27px;
                    cursor: pointer;

                    svg {
                        position: relative;
                        top: -2px;
                        font-size: 20px;
                        margin-right: 4px;
                    }

                    &:hover {
                        color: #e87200;
                    }
                }

                .selected-card {
                    position: relative;
                    margin-left: 27px;
                    
                    label {
                        margin: 12px 0 8px;
                    }

                    .dropdown {
                        button {
                            padding: 16px 46px 16px 83px;
                            // width: 245px;
                            border: solid 1px #dcdcdc;
                            border-radius: 8px;
                            background: transparent;
                            box-shadow: none;
                            outline: none;
                            color: #191919;
                            margin: 0;
                            text-align: left;
                            position: relative;
                            max-width: 100%;
                            min-width: 268px;

                            p {
                                margin: 0;
                                line-height: 1;
                            }

                            svg {
                                position: absolute;
                                right: 13px;
                                top: 50%;
                                transform: translate(0, -50%);
                                font-size: 16px;
                                transition: ease-in-out .2s;

                                &.active-dropdown-card {
                                    transform: translate(0, -50%) rotate(180deg);
                                }
                            }

                            &.please-select-card {
                                color: rgba(25, 25, 25, 0.3);
                                padding: 16px 46px 16px 16px;
                            }
                        }
                        
                        .dropdown-menu {
                            padding: 0;
                            border-radius: 8px;
                            max-width: 100%;

                            button {
                                border: none;
                                border-radius: 0;
                                
                                &:nth-child(1) {
                                    border-top-right-radius: 8px;
                                    border-top-left-radius: 8px;
                                }

                                &:nth-last-child(1) {
                                    border-bottom-right-radius: 8px;
                                    border-bottom-left-radius: 8px;
                                }

                                &:hover {
                                    background: rgba(255, 126, 0, 0.05);
                                }
                            }
                        }
                    }
                }

                .list-card-selection-box {

                }
            }
        }
    }

    .card-list-wrapper {
        width: 100%;
        padding-top: 28px;

        .card-list-no-data {
            width: 100%;

            p {
                color: rgba(25, 25, 25, 0.6);
                letter-spacing: -0.5px;
                font-size: 16px;
            }
        }

        .card-list-content {
            width: calc(50% - 8px);
            margin: 0 16px 16px 0;
            display: inline-block;
            position: relative;
            padding: 13px 43px 13px 83px;
            border-radius: 8px;
            border: solid 1px #dcdcdc;
            line-height: 1;

            p {
                margin: 0;
            }

            svg {
                color: #ff3f57;
                cursor: pointer;
                position: absolute;
                right: 15px;
                top: 50%;
                transform: translate(0, -50%);
                font-size: 20px;
            }

            &:nth-child(even) {
                margin: 0 0 16px 0;
            }
        }
    }
}