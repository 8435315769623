.billing-notif-main-wrapper {
    .btn {
        border: 0;
    }

    tr:hover {
        td {
            background: #ffffff !important;
        }
    }

    .billing-notif-top-title {
        font-size: 24px;
        letter-spacing: -0.6px;
        color: #191919;
        border-bottom: solid 1px #dcdcdc;
        padding-bottom: 12px;
        margin-bottom: 24px;
    }

    .topContent {
        align-items: unset !important;
        display: block;
        position: relative;
        width: 100%;
        padding: 0 225px 0 0;

        .title {
            margin: 0 0 4px 0;
        }

        .billing-notif-list-info {
            padding-bottom: 0;
            font-size: 12px;
        }

        .btn {
            position: absolute;
            right: 0;
            top: 50%;
            transform: translate(0, -50%);
        }
    }

    .billing-notif-list-table-wrapper {
        border-top: solid 1px #dcdcdc;
        margin-top: 16px;
    }
}

.billing-notif-list-title {
    color: #191919;
    font-size: 20px;
    letter-spacing: -0.6px;
    line-height: 24px;
    padding-bottom: 16px;
    border-bottom: solid 1px rgba(25, 25, 25, 0.1);
}

.billing-notif-list-tool-wrapper {
    width: 100%;
    position: relative;
    margin-top: 24px;
    margin-bottom: 32px;
}

.billing-notif-list-info {
    font-size: 14px;
    letter-spacing: -0.3px;
    color: rgba(25, 25, 25, 0.8);
    line-height: 1.5;
    padding: 0 48px 18px 0;
    position: relative;
    top: -4px;
}

.billing-notif-list-tool-wrapper button.button-invite-member {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(0, -50%);
    padding: 11px 0;
    width: 200px;
}

.billing-notif-list-tool-wrapper button.button-invite-member svg {
    position: relative;
    top: -1px;
    margin-right: 5px;
}

.billing-notif-list-table-wrapper {
    width: 100%;
    position: relative;
}

.table-billing-notif-list thead tr {
    background: #ffffff;
    // border-top: solid 1px #dcdcdc;
}

.table-billing-notif-list tbody tr {
    &:nth-last-child(1) {
        border-bottom: none !important;
    }
}

.table-billing-notif-list tbody td {
    background: #ffffff;
    position: relative;
}

.table-billing-notif-list button svg {
    position: relative !important;
    right: unset !important;
    top: -2px !important;
    margin-right: 5px;
}

.table-billing-notif-list .col-action-button-wrapper {
    position: absolute;
    right: 7px;
    top: 50%;
    transform: translate(0, -50%);
    /* display: none; */
}

.table-billing-notif-list .single-remove-button {
    margin-left: 16px;
    color: #ff3f57;
}

.table-billing-notif-list .single-remove-button:hover {
    color: #e83b51;
}

.table-billing-notif-list .shortcut-badge {
    font-size: 12px;
    color: rgba(25, 25, 25, 0.6);
    letter-spacing: -0.4px;
    background: #f3f3f3;
    line-height: 1;
    border-radius: 4px;
    padding: 1px 8px 3px;
}

.table-billing-notif-list td {
    max-width: unset !important;
}

.table-billing-notif-list .col-action-button-wrapper {
    display: block !important;
    position: relative;
    top: 0;
    right: unset;
    transform: unset;
}

.table-billing-notif-list .col-action-button-wrapper .view-agent {
    color: #ff7d00;
    letter-spacing: -0.5px;
}

.table-billing-notif-list .col-action-button-wrapper .view-agent:hover {
    color: var(--main-darker-orange);
}

.table-billing-notif-list .col-action-button-wrapper img.view-agent {
    margin-right: 3px;
    position: relative;
    top: -1px;
}

.member-tool-wrapper {
    width: 460px;
}

.table-billing-notif-list {
    .col_action {
        width: 200px !important;
        text-align: right;
    }

    .no-data-column {
        // color: rgba(25, 25, 25, 0.4) !important;
        text-align: left !important;
    }
}

.billing-notif-page-wrapper {
    margin: 32px 0 0;

    .nav-item {
        cursor: pointer;
        color: rgba(25, 25, 25, 0.4);
        font-size: 16px;
        letter-spacing: -0.5px;
        padding: 0 0 12px 0;
        margin-right: 32px;

        a {
            padding: 0;
            border: none;
        }

        :hover {
            color: #ff7d00;
        }

        &.active-setup-tab {
            color: #ff7d00;
            border-bottom: solid 4px #ff7d00;
        }
    }

    .tab-content {
        margin: 32px 0 0;
    }
}

.textarea-confirm-email {
    padding: 12px 16px;
    border-radius: 8px;
    border: solid 1px #dcdcdc;
    width: 100%;
    resize: none;
    height: 50px;
    margin-top: 20px;
    font-size: 16px;
    letter-spacing: -0.3px;

    &::placeholder {
        color: rgba(25, 25, 25, 0.4);
    }
}

.popup-remove-billing {
    .modal-body {
        border-bottom: none !important;

        .red-text {
            b {
                font-size: 12px;
                letter-spacing: -0.4px;
                line-height: 1;
                color: #ff3f57 !important;
            }

            svg {
                position: relative;
                top: -1px;
            }
        }
    }

    .modal-footer {
        border-top: none !important;
        padding-top: 0;
    }
}