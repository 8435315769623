.integration-whatsapp-verify {
  padding: 32px 30px;
  text-align: left;

  .comp-back-button {
      margin: 0 0 16px 0;
  }

  .integration-detail-loading-wrapper {
    .lds-ring {
      width: 50px;
      height: 50px;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      right: unset;

      div {
        border: 5px solid #fff;
        border-color: #ff7d00 transparent transparent transparent;
        width: 50px;
        height: 50px;
        margin: 0;
      }
    }
  }

  .integration-whatsapp-verify-box {
      background: #ffffff;
      border-radius: 8px;
      padding: 32px;
      border: solid 1px rgba(25, 25, 25, 0.1);

      .integration-whatsapp-verify-box-top {
          padding-bottom: 15px;
          border-bottom: solid 1px #dcdcdc;
          position: relative;
          font-size: 20px;
          letter-spacing: -0.6px;
          margin-bottom: 12px;

          h1 {
            font-size: 20px;
            margin: 0;
          }

      }

      .integration-whatsapp-checkout-box-form {
          width: 100%;

          .verify-card-iframe {
            width: 100%;
            height: 360px;
            // background-color: rgba(25, 25, 25, 0.1);
            display: flex;
            justify-content: center;
            align-items: center;
            border: solid 1px #dcdcdc;
            border-radius: 8px;
    
            .iframe-card-wrapper {
              width: 100%;
              height: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
              position: relative;

              .lds-ring {
                position: absolute;
                left: 50%;
                top: 50%;
                margin: 0;
                right: unset;
                width: 64px;
                height: 64px;
                transform: translate(-50%, -50%);
                
                div {
                  margin: 0;
                  width: 64px;
                  height: 64px;
                }
              }
    
              iframe {
                width: 100%;
                height: 100%;
              }
            }
            
            .loading-add-card-wrapper {
              position: absolute;
              width: 100%;
              text-align: center;
              left: 0;
              top: 50%;
              transform: translate(0, -50%);
              color: #ff7d00;
              font-size: 16px;
              letter-spacing: -0.6px;
    
              .LoadingRoll_1234fed {
                  height: unset;
                  margin-bottom: 24px;
                  
                  .lds-ring {
                      width: 64px;
                      height: 64px;
                      position: relative;
                      top: unset;
                      left: unset;
                      transform: unset;
                      
                      div {
                          border: solid 4px #ff7d00;
                          border-color: #ff7d00 transparent transparent transparent;
                          width: 64px;
                          height: 64px;
                      }
                  }
              }
    
              b {
                font-size: 18px;
                color: #ff7d00 !important;
              }
          }
    
          b {
              font-size: 40px;
              color: rgba(25, 25, 25, 0.4);
            }
          }
      }
  }
}