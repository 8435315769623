.modal-error {
    .modal-content {
      box-sizing: border-box;
      width: 450px;
      padding: 48px 48px 40px 48px;
      border-radius: 32px;
    }
  
    .modal-body {
      padding: 0;
      text-align: center;

      .error-icon {
        margin: auto;
        margin-bottom: 10px;
        padding: 9px;
        width: 88px;
        height: 88px;
        border-radius: 44px;
        background: #FF3F5733;

        svg {
          padding: 13px;
          width: 70px;
          height: 70px;
          border-radius: 35px;
          background: #FF3F57;
          color: white;
        }
      }
  
      b {
        &.error-title {
          margin: 0;
          font-size: 24px;
          line-height: 30px;
          letter-spacing: -0.6px;
          color: #191919;
        }
      }
  
      p {
        &.error-description {
          margin: 10px 0 4px;
          font-size: 16px;
          line-height: 24px;
          letter-spacing: -0.3px;
          color: #191919;
        }
      }

      .error-list-wrapper {
        margin-top: 28px;
        width: 100%;
        max-height: 132px;
        text-align: center;
        overflow: auto;

        .error-list {
          margin: auto;
          margin-bottom: 4px;
          padding: 4px 24px;
          // width: fit-content;
          max-width: calc(100% - 24px);
          height: 36px;
          border-radius: 8px;
          background: #1919190D;
          
          svg {
            display: inline;
            margin-right: 4px;
            font-size: 20px;
            color: #19191999;
          }

          .error-list-text-container {
            display: inline;
            margin: 0;
            max-width: calc(100% - 24px);

            .error-list-name {
                display: inline-block;
                vertical-align: middle;
                max-width: calc(100% - 50px);
                font-family: "PTRootBold";
                font-size: 16px;
                line-height: 20px;
                letter-spacing: -0.5px;
                color: #19191999;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;

                b {
                  display: inline-block;
                  width: 100%;
                  max-width: 100%;
                }
            }

            .error-list-extension {
                display: inline-block;
                vertical-align: middle;
                font-family: "PTRootBold";
                font-size: 16px;
                line-height: 20px;
                letter-spacing: -0.5px;
                color: #19191999;
            }
          }
        }

        &::-webkit-scrollbar {
          width: 12px;
        }
        
        &::-webkit-scrollbar-thumb {
          border: 4px solid #00000000;
          border-radius: 8px;
          background-color: #1919194D;
          background-clip: padding-box;
        }
      }
    }
  
    .modal-footer {
      margin: 28px 0 0;
      padding: 0;
      border: none;
  
      button {
        margin: 0 0 8px 0;
        width: 100%;
      }
    }
  }
