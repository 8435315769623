.sort-by-content-wrapper {
    position: relative;
    display: inline-block;

    .dropdown {
        display: inline-block;
        outline: none;
        transition: ease-in-out .2s !important;

        button {
            &.btn {
                border-radius: 4px;
                border: none;
                background: none !important;
                color: #191919 !important;
                border: solid 1px rgba(25, 25, 25, .1) !important;
                font-size: 12px;
                letter-spacing: -0.4px;
                box-shadow: none !important;
                padding: 4px 12px 2px 8px;
                line-height: 1;

                svg {
                    font-size: 14px;
                    margin-right: 4px;
                    position: relative;
                    top: -1px;
                    transition: ease-in-out .2s;

                    &.active-sort-by-menu {
                        transform: rotate(180deg);
                    }
                }

                &:hover {
                    color: #ff7d00 !important;
                    background: #ffe7d0 !important;
                    border: solid 1px #ffe7d0 !important;
                }
            }
        }

        .dropdown-menu {
            border-radius: 8px;
            // overflow: hidden;
            box-shadow: 0 2px 20px 0 rgba(25, 25, 25, 0.18);
            border: none;
            position: relative;
            padding: 0;
            margin-top: 12px;
            left: -8px !important;
            outline: none;

            &::before {
                position: absolute;
                width: 16px;
                height: 16px;
                background: #ffffff;
                content: "";
                top: -8px;
                transform: rotate(-45deg);
                left: 30px;
            }

            .dropdown-item {
                position: relative;
                font-size: 14px;
                letter-spacing: -0.4px;
                border-bottom: solid 1px rgba(25, 25, 25, .1);
                background: none;
                padding: 10px 16px 8px 16px;
                line-height: 1;
                color: #191919 !important;

                svg {
                    font-size: 16px;
                    margin-right: 8px;
                    position: absolute;
                    top: 10px;
                    left: 8px;
                }

                &:hover {
                    color: #ff7d00 !important;
                }

                &.active-sort-by-option {
                    padding: 10px 16px 8px 28px !important;
                    color: #ff7d00 !important;  
                }

                &:nth-last-child(1) {
                    border: none;
                }
            }  
        }
    }
}