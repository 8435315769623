.modal-select-how-to-add-recipient {
    .modal-content {
        width: 716px;
        border-radius: 8px;

        .modal-header {
            padding: 32px 34px 0;
            border: none;
            position: relative;

            .add-recipient-title {
                font-size: 20px;
                letter-spacing: -0.6px;
                margin: 0;
                width: 100%;

                span {
                    letter-spacing: -0.3px;
                    font-size: 16px;
                    margin: 0;
                }
            }

            .close-modal {
                position: absolute;
                top: 32px;
                right: 34px;
                color: #757575;
                font-size: 20px;
                cursor: pointer;

                &:hover {
                    color: #191919;
                }
            }
        }

        .modal-body {
            padding: 20px 34px 32px;
            text-align: center;

            .select-recipient-by {
                margin: 0 12px 8px 0;
                vertical-align: top;
                cursor: pointer;
                height: 183px;
                width: calc(50% - 6px);
                display: inline-block;
                background: #ffffff;
                border: solid 1px #dcdcdc;
                color: #191919;
                text-align: center;
                padding: 24px;
                border-radius: 8px;

                label {
                    cursor: pointer;
                }

                .button-download-csv {
                    margin: 4px 0 0;
                    color: #ff7d00;
                    border-radius: 4px;
                    cursor: pointer;
                    display: inline-block;
                    padding: 0 12px;
                    height: 22px;
                    transition: ease-in-out .1s;
                    line-height: 17px;

                    b {
                        letter-spacing: -0.4px;
                        font-size: 12px;
                    }
            
                    svg {
                        font-size: 12px;
                        margin-right: 4px;
                        position: relative;
                        top: 0;
                    }
            
                    // &:hover {
                    //     background: #ff7d00;
                    //     color: #ffffff;
                    // }
                }

                b {
                    font-size: 16px;
                    letter-spacing: -0.5px;
                }

                p {
                    margin: 6px 0 0;
                    font-size: 14px;
                    letter-spacing: -0.2px;
                }

                &:hover {
                    background: #FFF2E5;
                    color: #ff7d00;
                    border: solid 1px #FFF2E5;
                }

                &:nth-child(even) {
                    margin: 0 0 12px 0;
                }

                .select-recipient-by-icon {
                    border-radius: 50%;
                    width: 48px;
                    height: 48px;
                    display: inline-block;
                    position: relative;
                    margin: 0 0 6px 0;

                    svg {
                        font-size: 21px;
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                    }
                }

                &.from-contact { 
                    .select-recipient-by-icon {
                        background: #FFD4CC;
                        color: #ED5E45;
                    }
                }

                &.contact-segment {
                    .select-recipient-by-icon {
                        background: #D9E5FF;
                        color: #0051FC;
                    }
                }

                &.import-csv {
                    .select-recipient-by-icon {
                        background: #D9FFE5;
                        color: #50E682;
                    }
                }

                &.add-manually {
                    .select-recipient-by-icon {
                        background: #FFF2CC;
                        color: #F4C22C;
                    }
                }
            }
        }
    }
}

.modal-select-contact {
    width: 100% !important;

    .modal-content {
        left: unset;
        right: 0;
        top: 8px;
        transform: unset;
        // width: 870px;
        width: 75vw;
        max-height: calc(100% - 8px);
        position: fixed;
        min-height: calc(100% - 8px);
        border-radius: 12px 0 0 0;
        
        .modal-header {
            padding: 32px 32px 0;
            border: none;

            .user-icon {
                position: relative;
                top: -2px;
                margin-right: 4px;
                font-size: 24px;
            }

            .modal-select-search-wrapper {
                position: relative;
                width: 300px;
                display: inline-block;

                input {
                    padding: 11px 40px 11px 16px;
                    border:solid 1px #dcdcdc;
                    border-radius: 8px;
                    width: 100%;
                    font-size: 16px;
                    letter-spacing: -0.3px;
                }

                svg {
                    position: absolute;
                    right: 16px;
                    top: 50%;
                    transform: translate(0, -50%);
                    font-size: 20px;
                    color: #191919;
                    cursor: pointer;
                }
            }
        }

        .modal-body {
            padding: 0 32px 32px;
            max-height: calc(100% - 40px);
            overflow: auto;
            // min-height: 210px;
            // max-height: calc(100% - 240px);

            .table-contact-list {
                width: 100%;
                border-collapse: collapse;
                margin-top: 32px;

                thead {
                    tr {
                        border-bottom: solid 1px #dcdcdc;

                        td {
                            padding: 8px 4px;
                            position: relative;
                            font-size: 14px;
                            letter-spacing: -0.4px;
                            vertical-align: top;
                            
                            &.list-per-page {
                                width: 55px;
                            }
                        }
                    }
                }

                tbody {
                    tr {
                        border-bottom: solid 1px #dcdcdc;
                        
                        td {
                            padding: 16px 4px;
                            position: relative;
                            font-size: 14px;
                            letter-spacing: -0.2px;
                            vertical-align: top;
                            word-break: break-word;

                            span {
                                color: rgba(25, 25, 25, 0.8);
                            }

                            &.table-center {
                                text-align: center;
                                
                                span {
                                    color: rgba(25, 25, 25, 0.4);    
                                }

                                .lds-ring {
                                    top: 3px;
                                    right: unset;

                                    div {
                                        margin: 0;
                                    }
                                }
                            }

                            &:nth-child(1), &:nth-child(2), &:nth-child(3), &:nth-child(4) {
                                min-width: 160px;
                            }
                        }

                        // &:nth-child(even) {
                        //     background: rgba(25, 25, 25, 0.05)
                        // }

                        &:hover {
                            td {
                                background: rgba(255, 242, 229, 1) !important;

                                &.no-contact-found {
                                    background: #fff !important;
                                }
                            }
                        }
                    }

                    .no-contact-found {
                        background: #fff !important;
                    }
                }

                td {
                    .custom-checkbox {
                        display: inline-block;
                        vertical-align: top;
                        // top: -1px;
                        position: absolute;
                        left: 12px;

                        label {
                            margin: 0;
                            position: absolute;
                            top: 0;
                            left: 0;
                        }
                    }

                    .custom-checkbox-stripe {
                        display: inline-block;
                        vertical-align: top;
                        // top: -1px;
                        position: absolute;
                        left: 12px;

                        label {
                            margin: 0;
                            position: absolute;
                            top: 0;
                            left: 0;
                        }
                    }

                    .custom-checkbox-stripe [type="checkbox"]:checked + label:after {
                        top: -3px;
                        left: -5px;
                        background-size: unset !important;
                    }
                    
                    &.with-checkbox {
                        padding-left: 36px;
                    }
                }
            }
        }

        .modal-footer {
            padding: 12px 24px;
            border: none;
            box-shadow: 0px 2px 20px rgba(25, 25, 25, 0.18);
            text-align: right;
            position: relative;

            button {
                margin: 0;
                vertical-align: top;
                width: 185px;

                &.cancel-button {
                    width: 128px;
                    position: absolute;
                    left: 24px;
                    top: 50%;
                    transform: translate(0, -50%);
                }

                &.button-clear-selection {
                    color: #ff7d00;
                    background: none;
                    border: none;

                    &:hover {
                        color: #e87200;
                    }
                }
            }
        }
    }

    .select-contact-title {
        color: #191919;
        font-size: 20px;
        letter-spacing: -0.6px;
        margin: 0 0 24px;

        span {
            color: rgba(25, 25, 25, 0.6);
            font-size: 14px;
            letter-spacing: -0.2px;
        }
    }

    .button-submit {
        width: calc(50% - 4px);
        margin: 0 8px 0 0 !important;
        // height: 48px;

        &:nth-last-child(1) {
            margin: 0 !important;
        }
    }
}