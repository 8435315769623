.main-integration-google-create-entry {
    .colored-info-box {
        p {
            font-size: 16px !important;
            letter-spacing: -0.5px !important
        }

        color: #FF3F57;
        border: solid 1px #FF3F57;
        background-color: #FFECEE !important;
        border-radius: 8px;
        white-space: nowrap;
        margin-bottom: 24px;
        padding: 11px 20px;
        box-shadow: 0px 2px 20px rgba(25, 25, 25, 0.18);

        svg {
            position: relative;
            margin-right: 4px;
            top: -1px;
            font-size: 18px;
        }
    }

    label {
        width: 400px;
    }

    input {
        width: 400px;
        display: block;
    }

    .custom-switch-wrapper {
        display: inline-block !important;
        width: 40px;
        height: 24px;
        position: absolute;
        top: 0;
        right: 0;
        margin: 0;

        .custom-switch.round {
            &::before {
                width: 14px;
                height: 14px;
                bottom: 5px;
                left: 4px;
            }
        }

        input:checked + .custom-switch:before {
            transform: translateX(18px);
        }

        &.custom-switch-region {
            position: relative;
            top: unset !important;
            right: unset !important;
            margin-top: 28px !important;
            display: block !important;
            
            b {
                width: 200px;
                display: block;
                position: relative;
                left: 48px;
                top: -1px;
            }
            // width: 200px;

            &.region-create {
                b {
                    top: 2px !important;
                }
            }
        }
    }

    button {
        margin-right: 8px;
    }

    .local-wrapper {
        width: 100%;
        display: block;
        position: relative;
        padding-right: 60px;
        padding-bottom: 24px;
        margin-bottom: 24px;
        border-bottom: solid 1px #dcdcdc;

        label {
            margin: 0 !important;
        }
    }

    .non-local-wrapper {
        width: 100%;
        display: block;
        position: relative;
        padding-right: 60px;
        padding-bottom: 16px;

        label {
            margin: 0 !important;
        }
    }

    .non-local-form-wrapper {
        width: 100%;
        display: block;
        position: relative;

        input[type="text"] {
            padding: 11px 16px;
            border-radius: 8px;
            font-size: 16px;
            letter-spacing: -0.3px;
        }

        .label-domain {
            margin-top: 8px !important;
        }

        .domain-input-wrapper {
            position: relative;

            input {
                width: calc(100% - 45px);
            }

            .remove-domain-icon {
                top: 15px;
                position: absolute;
                color: #FF3F57;
                cursor: pointer;
                right: 0;
                font-size: 20px;
            }
        }

        .add-more-domain {
            margin: 8px 0 0;
            font-size: 16px;
            letter-spacing: -0.5px;
            color: #ff7d00;
            cursor: pointer;

            svg {
                position: relative;
                top: -2px;
                margin-right: 4px;
            }

            &:hover {
                color: var(--main-darker-orange);
            }
        }
    }

    .input-phone-wrapper {
        position: relative;
        width: 400px;

        input {
            padding: 11px 16px 11px 34px !important;
        }

        .icon-plus {
            position: absolute;
            left: 16px;
            top: 16px;
        }
    }

    .width-400 {
        width: 400px;
    }

    .checkbox-wrapper {
        margin-top: 13px;
        width: 400px;

        .custom-checkbox {
            width: calc(100% / 3);
            display: inline-block;
        }
    }

    .button-submit {
        margin-top: 26px;
    }

    .option-select-bulk-wrapper {
        margin-top: 20px;
    }
}