.office-hour-wrapper {
    .office-hour-title {
        font-size: 20px;
        letter-spacing: -0.6px;
        margin: 0;
        color: #191919;
    }

    .office-hour-description {
        margin: 8px 0 16px;
        font-size: 16px;
        letter-spacing: -0.3px;
        width: calc(100% - 230px);
        color: #191919;
        line-height: 24px;
    }

    .add-office-hour-button {
        // height: 40px;
        // line-height: 23px;
        // padding: 0;
        // width: 195px;
        text-align: center;
        position: absolute;
        top: 32px;
        right: 32px;

        // svg {
        //     vertical-align: top;
        //     font-size: 24px;
        //     position: relative;
        //     top: -1px;
        //     margin-right: 4px;
        // }
    }

    .office-hour-data-wrapper {
        padding: 32px 0 16px;
        border-top: solid 1px #dcdcdc;
        width: 100%;
        color: #191919;

        table {
            width: 100%;

            thead {
                font-size: 16px;
                border-bottom: solid 1px #dcdcdc;
                color: #191919;
                letter-spacing: -0.4px;

                td {
                    padding: 0 8px 8px;
                }
            }

            tbody {
                font-size: 14px;
                letter-spacing: -0.2px;
                color: #191919;

                td {
                    padding: 16px 8px;

                    &.no-hover {
                        cursor: default !important;
                        &:hover {
                          background-color: #ffffff !important;
                    
                          td {
                            color: #191919 !important;
                          }
                        } 
                    }

                    &.col_action {
                        font-size: 14px;
                        letter-spacing: -0.4px;

                        .edit-button {
                            color: #ff7d00;
                            cursor: pointer;
                            margin-right: 16px;
                        }

                        .remove-button {
                            color: #ff3f57;
                            cursor: pointer;
                        }

                        svg {
                            font-size: 16px;
                            position: relative;
                            top: -2px;
                            margin-right: 4px;
                        }
                    }
                }
            }

            .no-office-hour {
                width: 100%;
                text-align: center;
                font-size: 16px;
                letter-spacing: -0.5px;
                color: rgba(25, 25, 25, 0.6);
                padding: 24px 0 0;
            }
        }
    }
}