.sales-talk-product-comp {
    height: calc(100% - 215px);
    position: relative;
    width: 100%;
    display: block;
    padding: 0 4px;
    // overflow: auto;

    &.no-product-wrapper {
        height: calc(100% - 185px);
        border-top: none !important;
    }

    .sales-talk-product-comp-no-result {
        position: absolute;
        width: 100%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
        padding: 12px 24px;

        p {
            &:nth-child(1) {
                margin: 0;
                font-size: 16px;
                letter-spacing: -0.5px;
            }

            &:nth-last-child(1) {
                margin: 0;
                line-height: 24px;
                font-size: 16px;
                letter-spacing: -0.5px;
                color: rgba(25, 25, 25, 0.6);
            }
        }
    }
    
    .sales-talk-product-comp-inner {
        padding: 8px 8px 0;
    }

    .sales-talk-product-comp-box {
        display: inline-block;
        vertical-align: top;
        width: calc(50% - 10px);
        margin-right: 20px;
        cursor: pointer;
        padding: 12px;
        position: relative;
        // border-radius: 4px;
        border: solid 1px #fff;
        box-shadow: 0px 2px 20px rgba(25, 25, 25, 0.05);
        margin-bottom: 12px;

        .custom-checkbox {
            width: 16px;
            height: 16px;
            position: absolute;
            top: 20px;
            right: 20px;
            border-radius: 50%;
            z-index: 2;

            label {
                position: absolute;
                top: 0;
                left: 0;
                margin: 0;
                padding: 0;

                &::before {
                    width: 16px;
                    height: 16px;
                    border-radius: 50%;
                    top: 0;
                    left: 0;
                }

                &::after {
                    width: 16px;
                    height: 16px;
                    top: 0;
                    left: 0;
                    border-radius: 50%;
                }
            }
        }

        &:nth-child(2n) {
            margin-right: 0;
        }

        &:hover {
            // &::before {
            //     content: "";
            //     border: solid 2px #ff7d00;
            //     background-color: #ff7d00;
            //     opacity: 0.1;
            //     width: 100%;
            //     height: 100%;
            //     position: absolute;
            //     left: 0;
            //     top: 0;
            // }

            // &::after {
            //     content: "";
            //     border: solid 2px #ff7d00;
            //     width: 100%;
            //     height: 100%;
            //     position: absolute;
            //     left: 0;
            //     top: 0;
            // }
            box-shadow: 0px 0px 8px 0px #FF7E003D;
            border: solid 1px #ff7d00;
        }

        .sales-talk-product-comp-image-wrapper {
            width: 100%;
            height: 116px;
            border-radius: 12px;
            margin: 0;
            position: relative;
            background: rgba(25, 25, 25, 0.2);
            overflow: hidden;

            img {
                position: absolute;
                top: 50%;
                left: 50%;
                height: 100%;
                width: 100%;
                object-fit: cover;
                z-index: 1;
                transform: translate(-50%, -50%);
                // width: 116px;
                // height: 116px;
                // border-radius: 12px;
            }
        }

        .product-title {
            font-size: 14px;
            max-width: 100%;
            display: -webkit-box;
            overflow: hidden;
            text-overflow: ellipsis;
            letter-spacing: -0.4px;
            -webkit-box-orient: vertical;
            margin: 0;
            -webkit-line-clamp: 2;
            word-break: break-word;

            &:hover {
                text-decoration: underline;
            }
        }

        .product-price {
            font-size: 12px;
            color: rgba(25, 25, 25, 0.8);
            letter-spacing: -0.2px;
            margin: 4px 0;
        }

        .product-stock {
            font-size: 10px;
            letter-spacing: 1.5px;
            color: #ff7d00;
            margin: 0;
        }
    }
}