.contact-info-custom-field-wrapper {
    .contact-info-custom-field-title {
        font-size: 20px;
        letter-spacing: -0.6px;
        margin: 0;
        color: #191919;
    }

    .contact-info-custom-field-description {
        margin: 8px 0 16px;
        font-size: 16px;
        letter-spacing: -0.3px;
        width: calc(100% - 230px);
        color: #191919;
        line-height: 24px;
    }

    .add-contact-info-custom-field-button {
        text-align: center;
        position: absolute;
        top: 32px;
        right: 32px;
    }

    .contact-info-custom-field-data-wrapper {
        padding: 32px 0 16px;
        border-top: solid 1px #1919191A;
        width: 100%;
        color: #191919;

        table {
            width: 100%;

            thead {
                font-size: 16px;
                border-bottom: solid 1px #1919191A;
                color: #191919;
                letter-spacing: -0.4px;

                td {
                    padding: 0 8px 8px;
                }
            }

            .contact-info-custom-field-table-body {
                font-size: 14px;
                letter-spacing: -0.2px;
                color: #191919;

                .contact-info-custom-field-list {
                    border-bottom: solid 1px #1919191A;
                    cursor: pointer;

                    &:hover {
                      background-color: #FFF2E5;
                    }

                    td {
                        padding: 16px 8px;
                        border-bottom: solid 1px #1919191A;
    
                        &.col-required {
                            img {
                                width: 16px;
                                height: 16px;
                                margin-right: 4px;
                                transform: translateY(-2px);
                            }
                        }
                    }
                }
            }

            .no-contact-info-custom-field {
                .no-contact-info-custom-field-tr {
                    border-bottom: none;
                    cursor: default;

                    &:hover {
                      background-color: white !important;
                    }

                    td {
                        width: 100%;
                        text-align: center;
                        font-size: 16px;
                        letter-spacing: -0.5px;
                        color: rgba(25, 25, 25, 0.6);
                        padding: 24px 0 0;
                    }
                }
            }
        }
    }
}
