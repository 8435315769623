.whatsapp-preview {
  width: 100%;
  position: relative;
  border-radius: 4px;
  background: #EBE6E1;
  padding: 32px 16px 24px 24px;
  text-align: center;

  .carousel-wrapper {
    width: calc(100% + 16px);
    text-align: left;
    overflow-x: auto;
    white-space: nowrap;

    .carousel-card {
      word-break: break-word;
      background: #ffffff;
      width: 258px;
      padding: 10px 10px 0 10px;
      border-radius: 8px 8px 8px 8px;
      box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2);
      position: relative;
      text-align: left;
      font-family: SFPro;
      // overflow: hidden;
      margin: 4px 4px 0 0;
      vertical-align: top;
      display: inline-block;
      white-space: normal;

      .carousel-body-text {
        font-size: 16px;
        letter-spacing: -0.3px;
      }

      &:nth-child(1) {
        margin-left: 12px; 

        &::after {
          position: absolute;
          background: transparent;
          width: 13px;
          height: 17px;
          content: "";
          border-top: solid 8px transparent;
          border-bottom: solid 8px transparent;
          border-left: solid 8px transparent;
          border-right: solid 8px #fff;
          transform: rotate(-495deg);
          bottom: 3px;
          left: -7px;
        }
      }

      .button-carousel-wrapper {
        margin: 4px 0 0;

        .button-carousel {
          padding: 8px;
          border-top: solid 1px #dcdcdc;
          text-align: center;
          color: rgba(77, 139, 106, 1);
          white-space: nowrap;
          max-width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          margin: 0;
          font-size: 16px;
          letter-spacing: -0.3px;

          svg {
            position: relative;
            top: -1px;
            margin-right: 4px;
            font-size: 20px;
          }

          img {
            position: relative;
            top: -1px;
            margin-right: 4px;
          }
        }
      }

      .carousel-card-image-wrapper {
        margin: 0 !important;

        .image-video-wrapper {
          width: 100%;
          height: 160px;
          position: relative;
          background: #e5e5e5;
          border-radius: 8px;
          overflow: hidden;
          margin: 0 0 4px 0;

          img {
            max-width: 100%;
            max-height: 100%;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }

          video {
            max-width: 100%;
            max-height: 100%;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        }

        .icon-wrapper {
          width: 44px;
          height: 44px;
          border-radius: 50%;
          background: rgba(25, 25, 25, 0.40);
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);

          svg {
            font-size: 24px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            color: #fff;
          }
        }
      }

      p {
        margin: 0;
      }
    }
  }

  .wrapper-quick-reply {
    div {
      margin: 0 !important;
    }

    &.wrapper-quick-reply-more {
      div {
        &:nth-child(1) {
          width: calc(50% - 2px);
          display: inline-block;
          margin-right: 4px !important;
        }

        &:nth-child(2) {
          width: calc(50% - 2px);
          display: inline-block;
          margin-right: 0 !important;
        }
      }
    }
  }

  .wrapper-call-to-action {
    .call-to-action-button-wrap {
      text-align: center;
      border-top: solid 1px rgba(25, 25, 25, 0.1);
      color: #0096DE;
      height: 36px;
      margin: 0 !important;
      width: calc(100% + 20px);
      padding: 5px 20px 0;
      position: relative;
      left: -10px;

      svg {
        position: relative;
        margin-right: 6px;
        top: -1px;
      }

      &:nth-child(1) {
        margin-top: 8px !important;
      }

      &:nth-last-child(1) {
        height: 26px;
      }
    }
  }

  .whatsapp-link {
    color: #1391E9;
  }

  .whatsapp-info-wrapper {
    width: 74px;
    height: 24px;
    display: inline-block;
    background: #E4F2F9;
    border-radius: 4px;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2);
    margin-bottom: 16px;

    .whatsapp-info-content {
      width: 50px;
      height: 14px;
      border-radius: 2px;
      display: inline-block;
      background: #D0DCE3;
      margin-top: 5px;
    }
  }

  .whatsapp-warning {
    width: 100%;
    font-size: 12px;
    letter-spacing: -0.4px;
    color: rgba(25, 25, 25, 0.6);

    svg {
      position: relative;
      top: -1px;
      margin-right: 4px;
    }

    .whatsapp-warning-content {
      padding: 2px 13px;
      border-radius: 4px;
      box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2);
      background: #ffffff;
      display: inline-block;
    }
  }

  .whatsapp-chat-bubble {
    word-break: break-word;
    background: #ffffff;
    width: 100%;
    padding: 10px;
    border-radius: 0 8px 8px 8px;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2);
    position: relative;
    text-align: left;
    font-family: SFPro;
    overflow: hidden;

    &::before {
      position: absolute;
      background: transparent;
      width: 13px;
      height: 8px;
      content: "";
      border-top: solid 8px transparent;
      border-bottom: solid 8px transparent;
      border-left: solid 8px #ffffff;
      border-right: solid 8px transparent;
      transform: rotate(-45deg);
      top: -8px;
      left: -7px;
    }

    &.no-corner {
      text-align: center;
      border-radius: 8px !important;
      color: rgba(0, 150, 222, 1) !important;
      margin-top: 4px !important;
      vertical-align: top;

      svg {
        color: rgba(0, 150, 222, 1) !important;
        position: relative;
        top: -2px;
        font-size: 20px;
      }

      b {
        margin-left: 4px;
        font-size: 16px;
      }

      &::before {
        display: none;
      }
    }

    b {
      font-family: SFPro !important;
      font-weight: bold !important;
    }

    .whatsapp-chat-bubble-title {
      font-size: 16px;
      letter-spacing: -0.3px;
      line-height: 24px;
      margin: 0;
    }

    .whatsapp-chat-bubble-description {
      font-size: 16px;
      letter-spacing: -0.3px;
      line-height: 24px;
      margin: 0;
    }


    .whatsapp-chat-bubble-footer {
      font-size: 16px;
      letter-spacing: -0.3px;
      line-height: 24px;
      color: rgba(25, 25, 25, 0.6);
      margin: 0;
    }

    .whatsapp-chat-bubble-status {
      text-align: right;
      width: 100%;
      font-size: 12px;
      color: rgba(0, 0, 0, 0.45);
      margin: 0;

      img {
        position: relative;
        margin-left: 3px;
      }
    }
  }

  .static-media {
    position: relative;
    font-size: 0;

    .static-media-document {
      border-radius: 8px;
      font-size: 16px;
      letter-spacing: -0.3px;
      padding: 12px 11px 12px 36px;
      position: relative;
      width: 100%;
      margin-bottom: 6px;
      background: #f3f3f3;
      color: rgba(25, 25, 25, 0.6);

      img {
        position: absolute;
        top: 50%;
        transform: translate(0, -50%);
        left: 11px;
      }
    }

    img {
      &.static-media-image-asset {
        width: 100%;
        border-radius: 8px;
      }
    }

    video {
      &.static-media-video-asset {
        width: 100%;
        border-radius: 8px;
      }
    }

    .static-media-image {
      border-radius: 12px;
      font-size: 14px;
      letter-spacing: -0.4px;
      padding: 82px 11px;
      position: relative;
      width: 100%;
      margin-bottom: 6px;
      background: #9b9b9b;
      color: #ffffff;
      text-align: center;

      svg {
        font-size: 26px;
        margin-bottom: 4px;
      }
    }

    .static-media-video {
      border-radius: 12px;
      font-size: 14px;
      letter-spacing: -0.4px;
      padding: 82px 11px;
      position: relative;
      width: 100%;
      margin-bottom: 6px;
      background: #9b9b9b;
      color: #ffffff;
      text-align: center;

      svg {
        font-size: 26px;
        margin-bottom: 4px;
      }
    }

    .static-media-video-file-wrapper {
      background: rgba(0, 0, 0, 0.35);
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      height: 100%;
      border-radius: 8px;
      font-size: 0;

      svg {
        color: #ffffff;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 32px;
      }
    }
  }

  .dynamic-media {
    .dynamic-media-document {
      border-radius: 8px;
      font-size: 16px;
      letter-spacing: -0.3px;
      padding: 12px 11px 12px 36px;
      position: relative;
      width: 100%;
      margin-bottom: 6px;
      background: #f3f3f3;

      img {
        position: absolute;
        top: 50%;
        transform: translate(0, -50%);
        left: 11px;
      }
    }

    .dynamic-media-image {
      border-radius: 12px;
      font-size: 14px;
      letter-spacing: -0.4px;
      padding: 82px 11px;
      position: relative;
      width: 100%;
      margin-bottom: 6px;
      background: #9b9b9b;
      color: #ffffff;
      text-align: center;

      svg {
        font-size: 26px;
        margin-bottom: 4px;
      }
    }

    .dynamic-media-video {
      border-radius: 12px;
      font-size: 14px;
      letter-spacing: -0.4px;
      padding: 82px 11px;
      position: relative;
      width: 100%;
      margin-bottom: 6px;
      background: #9b9b9b;
      color: #ffffff;
      text-align: center;

      svg {
        font-size: 26px;
        margin-bottom: 4px;
      }
    }
  }
}