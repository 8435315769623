.integration-container {
	padding: 0;
	min-height: 520px;
	position: relative;
	margin: 0;
	border: none;
	height: 100vh;
	border-radius: 0;
}

.integration-side-menu-wrapper {
	padding: 0;
	// border-right: solid 1px #dcdcdc;
	width: 278px;
	height: 100vh;
	max-height: 100vh;
	overflow: hidden;
	position: absolute;
	left: 0;
	text-align: center;
	z-index: 1;
	box-shadow: 0 2px 20px 0 rgba(25, 25, 25, 0.05);
}
.integration-side-menu-option {
	margin-top: 12px;
	width: calc(100% - 24px);
	transition: ease-in-out .2s;
	border-radius: 8px;
	padding: 16px 12px 16px 44px;
	color: rgba(25, 25, 25, 0.4);
	font-size: 16px;
	letter-spacing: -0.5px;
	line-height: 1;
	position: relative;
	box-sizing: border-box;
	display: inline-block;
	text-align: left;
	&:hover {
		background: #ff7d00;
		color: #ffffff;
		cursor: pointer;
	}
	img {
		position: absolute;
		left: 12px;
		top: 50%;
		transform: translate(0, -50%);
	}
}
.integration-side-menu-option.active-integration-side-menu {
	background: rgba(255, 126, 0, 0.1);
	color: #ff7d00 !important;
}
.integration-main-content {
	width: calc(100% - 256px);
	height: 100%;
	position: absolute;
	top: 0;
	left: 256px;
}
.integration-main-container {
	background: #f8f8f8;
	text-align: center;
    // position: fixed;
    // top: 0;
    // left: 340px;
    // transform: none;
    // width: calc(100vw - 340px);
    color: rgb(25, 25, 25);
    font-size: 16px;
    letter-spacing: -0.5px;
    // height: 100vh;
	// max-height: 100vh;
	// overflow: auto;
	// padding: 32px 30px;	

	img.icon-integration-content {
		width: 60px;
		margin-bottom: 20px;
	}
	.button-email {
		margin-top: 24px;
    }
    img.icon-integration-content {
        width: 60px;
        margin-bottom: 20px;
	}
	.arrow-icon {
		margin-right: 5px;
		position: relative;
		top: -2px;
	}

	&.sectionWrap {
		border: none;
		padding: 16px 0;
		min-height: calc(100vh - 80px);
	}
}

.taptalk-snippet {
    width: 100%;
    background: #183055;
    text-align: left;
    /* border: solid 1px #dcdcdc; */
    border-radius: 4px;
    margin: 15px 0;
    display: block;
    padding: 10px;
    color: #ffffff;
}

.link-integration {
    color: #ff7d00;
}