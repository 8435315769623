.popup-message-info-wrapper {
    width: 100vw;
    height: 100vh;
    background: rgba(25, 25, 25, 0.8);
    position: fixed;
    top: 0;
    /* right: -100vw; */
    visibility: hidden;
    opacity: 0;
    right: 0;
    /* transition: visibility 0s, opacity 0.3s ease-in-out; */
    transition: visibility .3s, opacity 0.5s ease-in-out;
    z-index: 4;

    &.active-popup-message-info {
        opacity: 1;
        visibility: visible;
        -webkit-transition: visibility 0s, opacity 0.5s ease-in-out;
        transition: visibility 0s, opacity 0.5s ease-in-out;
    }

    .popup-message-info-main-wrapper {
        width: 440px;
        transition: ease-in-out 0.5s;
        background: #ffffff;
        height: calc(100vh - 8px);
        border-top-left-radius: 18px;
        bottom: 0;
        right: -405px;
        position: fixed;

        &.active-popup-message-info-main-wrapper {
            right: 0;
        }

        .popup-message-info-close {
            background: #ffffff;
            border-radius: 50%;
            cursor: pointer;
            position: absolute;
            width: 48px;
            height: 48px;
            display: inline-block;
            text-align: center;
            line-height: 45px;
            top: 0;
            left: -64px;

            svg {
                font-size: 24px;
                color: #7D7C7D;
            }
        }

        .title-popup-message-info {
            font-size: 20px;
            letter-spacing: -0.6px;
            padding: 24px;
            border-bottom: solid 1px #dcdcdc;
            margin: 0;

            svg {
                position: relative;
                margin-right: 8px;
                top: -2px;
            }
        }
    }

    .popup-message-info-body-wrapper {
        width: 100%;
        display: block;
        position: relative;
        height: calc(100% - 80px);

        .popup-message-info-body-top {
            width: 100%;
            display: block;
            position: relative;
            background: rgba(243, 243, 243, 1);
            height: 60%;

            .popup-message-info-body-inner {
                position: absolute;
                max-height: 100%;
                overflow-y: auto;
                width: 100%;
                left: 0;
                top: 0;
                padding: 0 0 16px 0;

                .message-action-dropdown {
                    display: none !important;
                }

                .message-out-bubble,
                .message-out-bubble-file,
                .message-out-bubble-image,
                .message-out-bubble-video,
                .message-out-bubble-rating,
                .message-out-bubble-location,
                .message-out-interactive-bubble {
                    max-width: 100% !important;
                }
            }
        }

        .popup-message-info-body-bottom {
            width: 100%;
            display: block;
            position: relative;
            height: 40%;
            padding: 32px 40px;

            .popup-message-info-name {
                padding: 0 0 8px 32px;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                position: relative;
                font-size: 16px;
                letter-spacing: -0.5px;
                border-bottom: solid 1px #dcdcdc;
                
                .popup-message-info-avatar {
                    text-align: center;
                    line-height: 24px;
                    font-size: 12px;
                    letter-spacing: -0.4px;
                    color: #ffffff;
                    width: 24px;
                    height: 24px;
                    background: lightblue;
                    border-radius: 50%;
                    position: absolute;
                    left: 0;
                    top: 0;
                }
            }

            .popup-message-status-wrapper {
                .lds-ring {
                    width: 40px;
                    height: 40px;
                    margin-top: 32px;

                    div {
                        width: 40px;
                        height: 40px;
                        border: 4px solid;
                        border-color: var(--main-dark-orange) transparent transparent transparent;
                    }
                }

                .popup-message-status-wrapper-list {
                    margin: 8px 0 0;
                    font-size: 12px;
                    letter-spacing: -0.2px;
                    position: relative;
                    color: rgba(25, 25, 25, 0.6);

                    img {
                        position: relative;
                        margin: 0 4px 0 0;
                        top: -1px;
                    }

                    .status-list-time {
                        color: rgba(25, 25, 25, 0.6);
                        position: absolute;
                        right: 0;
                        top: 0;

                        .dot-time {
                            width: 2px;
                            height: 2px;
                            border-radius: 50%;
                            background: rgba(25, 25, 25, 0.4);
                            position: relative;
                            display: inline-block;
                            top: -4px;
                        }
                    }

                    .status-list-time-empty {
                        position: absolute;
                        height: 2px;
                        right: 0;
                        top: 8px;
                        background: rgba(25, 25, 25, 0.4);
                        width: 24px;
                        border-radius: 8px;
                    }
                }
            }
        }
    }
}