.account-profile-google {
    position: relative;
    width: 100%; 

    .account-profile-header {
        border-bottom: 1px solid rgba(25, 25, 25, 0.1);
        margin-bottom: 24px;
        padding-bottom: 24px;
        position: relative;

        .title-wrapper {
            b {
                font-size: 20px;
            }

            p {
                font-size: 14px;
                letter-spacing: -0.2px;
                margin: 0;
                color: rgba(25, 25, 25, .8);
                padding-right:  150px;
            }
        }

        .header-button-wrapper {
            position: absolute;
            top: 0;
            right: 0;

            button {
                margin-left: 8px;
            }
        }
    }

    .account-profile-header-bottom {
        padding-bottom: 12px;
    }

    .account-profile-content {
        .copy-box-wrapper {
            margin-top: 8px;
        }
        
        &.account-profile-content-with-border {
            border-top: solid 1px #dcdcdc;
            margin-top: 24px;
            padding-top: 24px;
        }

        .colored-info-box {
            p {
                font-size: 16px !important;
                letter-spacing: -0.5px !important
            }

            color: #0C5F99;
            border: solid 1px #0c5f99;
            background-color: #EBF7FF !important;
            border-radius: 8px;
            white-space: nowrap;
            margin-bottom: 24px;
            padding: 11px 20px;
            box-shadow: 0px 2px 20px rgba(25, 25, 25, 0.18);

            svg {
                position: relative;
                margin-right: 4px;
                top: -1px;
                font-size: 18px;
            }
        }

        label {
            margin: 0;
            font-size: 14px;
            letter-spacing: -0.4px;
            display: block;
        }

        .verification-status {
            margin-top: 24px;

            .status-value {
                font-size: 16px;
                letter-spacing: -0.5px;

                svg, img {
                    position: relative;
                    margin-right: 4px;
                    top: -1px;
                }
            }
        }

        .no-fill-button {
            margin: 12px 8px 0 0;
        }

        .display-name {
            font-size: 20px;
            letter-spacing: -0.6px;
            width: 400px;
            max-width: 100%;
            word-wrap: break-word;
        }
        
        .custom-select-phonenumber {
            width: 340px;
            max-width: 100%;
        }

        .account-profile-content-wrapper {
            margin-top: 24px;
            margin-bottom: 24px;
            position: relative;

            &:last-child {
                margin-bottom: 0;
            }

            .label-wrapper {
                display: flex;
                align-items: center;
                margin-bottom: 8px;

                svg {
                    margin-right: 4px;
                    position: relative;
                    top: -1px;
                }
            }

            .photo-wrapper {
                margin-bottom: 24px;
                border: 1px solid rgba(25, 25, 25, 0.1);
                border-radius: 4px;
                width: 96px;
                height: 96px;
                position: relative;

                img {
                    border-radius: 4px;
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                    max-width: 100%;
                    object-fit: contain;
                }

                .profile-photo-empty {
                    height: 94px;
                    background: rgba(25, 25, 25, 0.05);
                    position: relative;
        
                    svg {
                        height: 32px;
                        width: 32px;
                        color: #9c9c9c;
                        position: absolute;
                        left: 50%;
                        top: 50%;
                        transform: translate(-50%, -50%);
                    }
                }
            }

            .info-wrapper {
                display: flex;
                // align-items: center;

                b {
                    font-size: 12px;
                }

                svg {
                    font-size: 16px;
                    margin-right: 4px;
                }
            }

            .text-wrapper {
                p {
                    margin: 0;
                }
            }
            .button-wrapper {
                position: absolute;
                right: 0;
                top: 50%;
                transform: translateY(-50%);
                > div {
                    display: flex;
                    label {
                        margin-right: 8px;
                    }
                }

                .LoadingRoll_1234fed {
                    vertical-align: 6px;
                    margin: 0 15px 0 auto !important;
                    height: unset !important;
                }
                label {
                    width: fit-content;
                    cursor: pointer;
                    padding: 0 24px 0 16px !important;
                    input {
                        width: 100%;
                    }

                    div {
                        display: inline-block;
                    }

                    &.disabled {
                        color: rgba(25, 25, 25, 0.4) !important;
                        border: solid 1px #E8E8E8 !important;
                        background: rgba(25, 25, 25, 0.1) !important;
                        cursor: not-allowed;
                        box-shadow: none !important;

                        input {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}

.modal-remove-photo {
    .modal-content {
        border-radius: 8px;
        padding: 32px;
        width: 480px;
    }

    .modal-body {
        padding: 0;
        line-height: 1;

        p {
            margin: 0;
        }

        .modal-remove-photo-title {
            font-size: 20px;
            letter-spacing: -0.6px;
        }

        input {
            width: 100%;
        }
    }

    .modal-footer {
        padding: 24px 0 0;
        border: 0;

        button {
            width: calc(50% - 4px);
            margin: 0 8px 0 0;
            height: 48px;

            &:nth-last-child(1) {
                margin: 0;
            }
        }
    }
}

.modal-test {
    .modal-content {
        width: 476px;

        .modal-body {
            padding: 24px;
        }
    }

    .modal-title {
        font-size: 20px;
        letter-spacing: -0.6px;
        margin: 0;
    }

    .modal-desc {
        font-size: 14px;
        letter-spacing: -0.2px;
        margin: 0 0 20px;
    }

    .qr-wrapper {
        margin: 0 0 8px 0;
        width: calc(100% - 24px);
        position: relative;
        left: 12px;
        height: 400px;

        img {
            width: 100%;
        }
    }

    .qr-button-wrapper {
        button {
            width: calc(50% - 2px);
            display: inline-block;
            margin-right: 4px;
            
            &:nth-last-child(1) {
                margin-right: 0;
            }
        }
    }
}

.modal-send-verification {
    .modal-content {
        max-height: 90vh;
        border-radius: 8px;

        .modal-title {
            padding: 24px 24px 0 24px;
        }

        .modal-body {
            padding: 0;
        }
    }

    .form-verification-wrapper {
        overflow: auto;
        max-height: calc(90vh - 170px);
        margin-top: 24px;

        .form-verification-wrapper-inner {
            padding: 0 24px 24px 24px;
        }

        label {
            display: block;
            margin: 24px 0 0;
        }

        input {
            margin-top: 8px;
            font-size: 16px;
            letter-spacing: -0.3px;
            padding: 12px 16px;
            border-radius: 8px;
            border: solid 1px #dcdcdc;
            width: 100%;
        }
    }

    .qr-button-wrapper {
        padding: 24px;
    }

    .colored-info-box {
        p {
            font-size: 16px !important;
            letter-spacing: -0.5px !important;
            padding-left: 26px;
            position: relative;
            line-height: 24px;
        }

        color: #0C5F99;
        border: solid 1px #0c5f99;
        background-color: #EBF7FF !important;
        border-radius: 8px;
        margin-bottom: 24px;
        padding: 11px 20px;
        box-shadow: 0px 2px 20px rgba(25, 25, 25, 0.18);

        svg {
            position: absolute;
            margin-right: 4px;
            left: 0;
            top: 2px;
            font-size: 18px;
        }
    }

}