.dropdown-list-per-page {
    width: 55px;

    button {
        width: 100%;
        box-shadow: none !important;
        outline: none !important;
        border: solid 1px #dcdcdc !important;
        background: #ffffff !important;
        color: #191919 !important;
        font-size: 12px;
        letter-spacing: -0.4px;
        transition: ease-in-out .1s;
        text-align: left;
        padding: 2px 9px;
        position: relative;

        svg {
            transition: ease-in-out .1s;
            position: absolute;
            right: 9px;
            top: 50%;
            transform: translate(0, -50%);

            &.show-dropdown {
                transform: translate(0, -50%) rotate(180deg);
            }
        }

        &:hover {
            color: #ff7d00 !important;
        }
    }

    .dropdown-menu {
        width: 55px;
        padding: 0;
        border-radius: 8px;
        min-width: unset;

        button {
            border: 0 !important;
            padding: 2px 9px 2px 24px;
            cursor: pointer;
            z-index: 1;

            &:nth-child(1) {
                border-top-left-radius: 8px;
                border-top-right-radius: 8px;
            }

            &:nth-last-child(1) {
                border-bottom-left-radius: 8px;
                border-bottom-right-radius: 8px;
            }

            svg {
                position: absolute;
                left: 9px;
                top: 50%;
                transform: translate(0, -50%);
                font-size: 10px;
            }

            &.selected-list-per-page {
                color: #ff7d00 !important;
            }

            &:hover {
                color: #ff7d00 !important;
            }
        }
    }
}