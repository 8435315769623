.chatbot-add-chatbot {
    padding: 32px 30px;
    text-align: left;

    .comp-back-button {
        margin: 0 0 16px 0;
    }

    .alert-component {
        margin-top: 36px;
    }

    .chatbot-add-chatbot-box {
        background: #ffffff;
        border-radius: 8px;
        padding: 32px;
        border: solid 1px rgba(25, 25, 25, 0.1);

        .chatbot-add-chatbot-box-top {
            padding-bottom: 15px;
            border-bottom: solid 1px #dcdcdc;
            position: relative;
            font-size: 20px;
            letter-spacing: -0.6px;

            .chat-bot-chatbot-button-wrapper {
                margin: 0;
            }

            button {
                position: absolute;
                top: -6px;
                right: 0;
                // padding: 7px 24px;
                font-size: 16px;
                margin: 0 !important;
                letter-spacing: -0.6px;
                // width: 195px;
                // height: 40px;

                // .lds-ring {
                //     top: unset;
                //     right: unset;
                //     position: relative;
                //     top: 3px;

                //     div {
                //         margin: 0;
                //         border-color: #ffffff transparent transparent transparent;
                //     }
                // }
            }
        }

        .chatbot-add-chatbot-box-form {
            width: 320px;
            
            label {
                padding: 24px 0 0;
                font-size: 14px;
                letter-spacing: -0.4px;
                display: block;
            }

            input[type="text"] {
                padding: 12px 16px;
                border-radius: 6px;
                border: solid 1px #dcdcdc;
                width: 100%;
                font-size: 16px;
                letter-spacing: -0.3px;
                display: block;
                margin-bottom: 8px;
            }

            .integration-info-form {
                font-size: 14px;
                color: rgba(25, 25, 25, 0.8);
                letter-spacing: -0.2px;
            }

            .custom-select-path {
                .custom-select-option-value-wrapper {
                    padding: 12px 16px !important;
                    margin-bottom: 8px;
                }
            }
        }
        
        .chat-bot-chatbot-button-wrapper {
            margin-top: 18px;

            button {
                margin: 0 4px 0 0;
            }
        }
    }
}