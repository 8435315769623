.chat-view-container {
    width: 100%;
    box-sizing: border-box;
    position: relative;
    max-height: 100vh;
    height: 100vh;
    font-size: 0;
    overflow: hidden;

    .tier-home-wrapper {
        left: 0;
        width: 100%;
    }
}

.main-chat-container {
    // height: calc(100vh - 56px);
    height: 100%;
    position: relative;
    /* top: -5px; */
    font-size: 16px;
}

.modal-refresh-on-reconnect {
    width: 100vw !important;
    background: rgba(25, 25, 25, 0.8);

    .modal-content {
        width: 476px;
        border: 8px;
        padding: 32px;
    }

    .modal-body {
        padding: 0;

        p {
            &:nth-child(1) {
                font-size: 20px;
                line-height: 24px;
                letter-spacing: -0.6px;
                margin: 0 0 4px;
            }

            &:nth-child(2) {
                margin: 0;
                font-size: 14px;
                line-height: 21px;
                letter-spacing: -0.2px;
                color: rgba(25, 25, 25, 0.6);
            }
        }
    }

    .modal-footer {
        padding: 24px 0 0;
        margin: 12px 0 0;

        button {
            width: 100%;
            padding: 12px 0;
            font-size: 16px;
            letter-spacing: -0.5px;
            line-height: 1;
        
            svg {
                font-size: 20px;
                margin-right: 4px;
                position: relative;
                top: -1px;
            }
        }
    }
}