.option-select-topic-wrapper {
    width: 100%;
    position: relative;

    .custom-checkbox-stripe [type="checkbox"]:checked + label:after {
        width: 16px;
        height: 16px;
        top: 2px;
        left: 0px;
    }

    &.option-select-topic-wrapper-disabled {
        .select-content {
            label {
                &::before {
                    border: solid 1px #dcdcdc !important;
                }
            }
        }
    }

    &.error-select-topic {
        .selection-topic-wrapper {
            border: solid 1px #ff3f57 !important;
        }

        .select-topic-wrapper {
            border: solid 1px #ff3f57 !important;
            border-radius: 8px;
        }
    }

    .option-select-topic-wrapper-50 {
        width: calc(50% - 8px);
        position: relative;
        vertical-align: top;
        display: inline-block;
        margin: 0 16px 0 0;

        label {
            position: relative;
            width: 100%;

            .clear-topic {
                position: absolute;
                bottom: 0;
                right: 0;
                cursor: pointer;
                color: var(--main-dark-orange);

                svg {
                    position: relative;
                    font-size: 16px;
                    top: -2px;
                    margin-right: 4px;
                }

                &:hover {
                    color: var(--main-darker-orange);
                }
            }
        }

        &:nth-child(2) {
            margin: 0;
        }

        span {
            color: rgba(25, 25, 25, 0.6);
            position: relative !important;
        }

        .selection-topic-wrapper {
            border-radius: 8px;
            box-shadow: 0 0 0 1px #dcdcdc;
            overflow: hidden;
            
            label {
                font-size: 14px !important;
                letter-spacing: -0.2px !important;
            }

            .select-content {
                width: 100%;
                padding: 13px 17px;
                white-space: nowrap;

                label {
                    margin: 0;
                    max-width: 100%;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    display: inline-block !important;
                    padding: 0 0 0 22px !important;
                }

                input {
                    display: none;
                }

                &:hover {
                    background: #FFF2E5;
                }

                &:nth-child(1) {
                    border-top-left-radius: 8px;
                    border-top-right-radius: 8px;
                }
            }

            .search-box-wrapper {
                width: 100%;

                input[type="text"] {
                    border: none !important;
                    border-bottom: solid 1px #dcdcdc !important;
                    border-radius: 0;
                    background: transparent;
                    width: 100% !important;
                    position: relative;
                    padding: 0 42px 0 16px;
                    height: 40px;
                    margin: 0 !important;
                }

                #search-icon {
                    width: 40px;
                    right: 0 !important;
                    left: unset !important;
                    height: 40px;
                    background: transparent !important;

                    svg {
                        color: rgba(25, 25, 25, 0.6);
                    }
                }
            }

            .overflow-select-topic {
                max-height: 160px;
                overflow-y: auto;

                .select-content {
                    // border-bottom: solid 1px #dcdcdc;
                    height: 47px;
                    
                    &:nth-child(1) {
                        border-top-left-radius: 0px;
                        border-top-right-radius: 0px;
                    }

                    &:nth-last-child(1) {
                        border-bottom-left-radius: 8px;
                        border-bottom-right-radius: 8px;
                    }

                    &.checkbox-all {
                        border-bottom: solid 1px #dcdcdc;
                    }

                    &.not-found {
                        text-align: center !important;
                        color: var(--main-dark-grey);
                        background: #fff !important;
                        padding: 34px 60px;
                        height: unset;
                        white-space: unset;
                        text-align: center;
                        overflow: hidden !important;

                        img {
                            margin: 0 0 20px 0;
                        }
                    }
                }
            }
        }

        .select-topic-wrapper {
            width: 100%;
            height: 200px;

            .selected-topic-container {
                width: 100%;
                min-height: 100%;
                overflow-y: auto;
                max-height: 100%;
                border: solid 1px #dcdcdc;
                border-radius: 8px;
                padding: 8px 16px 8px 16px;

                .no-selected-topic {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    margin-top: 48px;

                    img {
                        margin-bottom: 20px;
                    }

                    p {
                        color: rgba(25, 25, 25, 0.8);
                        font-size: 14px;
                        line-height: 21px;
                        letter-spacing: -0.2px;
                    }
                }
            }
        }
    }
}