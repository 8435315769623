.modal-loading {
    .modal-content {
      box-sizing: border-box;
      width: 450px;
      padding: 48px;
      border-radius: 32px;
    }
  
    .modal-body {
      padding: 0;
      text-align: center;

      .lds-ring {
        display: block;
        position: relative;
        margin: auto;
        margin-bottom: 10px;
        top: 1px;
        width: 88px;
        height: 88px;
  
          div {
              width: 72px;
              height: 72px;
              border-width: 7.2px;
          }
      }
  
      b {
        &.loading-title {
          margin: 0;
          font-size: 24px;
          line-height: 30px;
          letter-spacing: -0.6px;
          color: #191919;
        }
      }
  
      p {
        &.loading-description {
          margin: 10px 0 0;
          font-size: 16px;
          line-height: 24px;
          letter-spacing: -0.3px;
          color: #191919;
        }
      }
    }
  
    .modal-footer {
      margin: 32px 0 0;
      padding: 0;
      border: none;
  
      button {
        margin: 0;
        width: 100%;
      }
    }
  }
