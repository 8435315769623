.modal-confirmation-send-conversation {
    .modal-content {
        border-radius: 8px;
        padding: 32px;
        width: 476px;

        .modal-body {
            padding: 0;

            p {
                margin: 0;
                font-size: 20px;
                line-height: 24px;
                color: #191919;

                svg {
                    font-size: 22px;
                    top: -2px;
                    position: relative;
                    margin-right: 4px;
                }

                &:nth-child(2) {
                    font-size: 14px;
                    line-height: 21px;
                    letter-spacing: -0.2px;
                    color: rgba(25, 25, 25, 0.6);
                    margin: 4px 0 16px;
                }
            }
    
            button {
                width: calc(50% - 2px);
                margin-right: 4px;

                &:nth-last-child(1) {
                    margin-right: 0;
                }
            }
        }

    }
}

.modal-topup-balance {
    .modal-content {
        border-radius: 8px;
        padding: 32px;
        width: 476px;

        .modal-body {
            padding: 0;

            p {
                margin: 0;
                font-size: 20px;
                line-height: 24px;
                color: #191919;

                svg {
                    font-size: 22px;
                    top: -2px;
                    position: relative;
                    margin-right: 4px;
                }

                &:nth-child(2) {
                    font-size: 14px;
                    line-height: 21px;
                    letter-spacing: -0.2px;
                    color: rgba(25, 25, 25, 0.6);
                    margin: 4px 0 16px;
                }
            }
    
            button {
                width: calc(50% - 2px);
                margin-right: 4px;

                &:nth-last-child(1) {
                    margin-right: 0;
                }

                &.width-100 {
                    width: 100%;
                }
            }
        }

    }   
}