.form-custom-provider {
    position: relative;

    .form-title-wrapper {
        padding-bottom: 12px;
        border-bottom: solid 1px rgba(25, 25, 25, 0.1);
        color: #191919;
        font-size: 20px;
        letter-spacing: -0.6px;

        .form-title-desc {
            font-size: 16px;
            letter-spacing: -0.3px;
            margin: 8px 0 0;
        }
    }

    .form-content-wrapper {
        a {
            color: #ff7d00;

            &:hover {
                color: var(--main-darker-orange);
            }
        }
    }

    .form-content-wrapper label {
        display: block;
        color: #191919;
        font-size: 14px;
        margin-top: 22px;
    }

    .form-content-wrapper input[type="text"] {
        background: rgb(255, 255, 255);
        border-radius: 8px;
        border: 1px solid rgba(25, 25, 25, 0.1);
        height: 48px;
        width: 348px;
        color: #191919;
        font-size: 16px;
        letter-spacing: -0.3px;
        width: 100%;
        padding: 12px 16px;
        margin-bottom: 11px;
    }

    .form-content-wrapper label {
        display: block !important;
    }

    .form-content-wrapper .role-radio-label {
        position: relative;
    }

    .form-content-wrapper .role-radio-label b {
        display: block;
        color: #191919;
        font-size: 14px;
        letter-spacing: -0.4px;
    } 

    .form-content-wrapper .role-radio-label label {
        color: rgba(25, 25, 25, 0.8);
        font-size: 14px;
        letter-spacing: -0.2px;
    } 

    .form-content-wrapper .custom-radio label {
        margin-top: 18px;
    }

    .form-content-wrapper button {
        width: unset;
        margin-top: 22px;
    }

    .form-content-wrapper .orange-button .lds-ring div {
        border-color: #ffffff transparent transparent transparent;
        margin: 0;
    }

    .submit-add-custom-provider {
        position: absolute;
        top: -7px;
        right: 32px;
        width: 176px !important;
        padding: 7px 0 !important;
        margin-top: 32px !important;

        .lds-ring {
            top: 3px;
            right: unset;

            div {
                margin: 0;
                border-color: #ffffff transparent transparent transparent;
            }
        }
    }

    .input-info-wrapper {
        color: rgba(25, 25, 25, 0.8);
        font-size: 16px;
        letter-spacing: -0.3px;
        line-height: 21px;
        width: 100%;
        margin: 0;
        line-height: 1;
    }

    .custom-provider-suffix {
        height: 48px;
        padding: 12px 16px;
        margin-left: 8px;
    }
    
    .custom-provider-prefix {
        height: 48px;
        padding: 12px 16px;
        margin-right: 8px;
    }
}