.edit-profile-outer-wrapper {
  .edit-account-profile-whatsapp {
    position: relative;
    width: 100%; 
  
    .edit-account-profile-header {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid rgba(25, 25, 25, 0.1);
      margin-bottom: 24px;
      padding-bottom: 12px;

      .title-wrapper {
          b {
              font-size: 20px;
          }
      }
    }

    .edit-account-profile-content {
      .account-profile-content-wrapper {
        width: 425px;
        margin-bottom: 24px;

        &:last-child {
            margin-bottom: 0;
        }

        .label-wrapper {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 8px;

            label {
                margin: 0;
                font-size: 14px;
            }

            p {
              margin: 0;
              font-size: 14px;
              color: rgba(25, 25, 25, 0.6);
            }
        }

        .edit-input-wrapper {
            margin-bottom: 8px;

            textarea {
              width: 100%;
              border: 1px solid rgba(25, 25, 25, 0.1);
              border-radius: 8px;
              padding: 12px 16px;
              resize: none;
            }
        }

        .info-wrapper {
            display: flex;
            align-items: center;

            b {
                font-size: 12px;
            }

            svg {
                font-size: 16px;
                margin-right: 4px;
            }
        }
    }
    }
  }
}