@import url(https://fonts.googleapis.com/css?family=Roboto&display=swap);
@font-face {
  font-family: "PTRootUI";
  src: url(/static/media/PTRootUI.d9d6ef06.eot);
  src: url(/static/media/PTRootUI.d9d6ef06.eot) format("embedded-opentype"), url(/static/media/PTRootUI.a8a49c2b.woff2) format("woff2"), url(/static/media/PTRootUI.697fd944.woff) format("woff"), url(/static/media/PTRootUI.55cc421f.ttf) format("truetype"), url(/static/media/PTRootUI.db24963d.svg#PTRootUIWeb-Regular) format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: "PTRootBold";
  src: url("/static/media/PT Root UI_Bold.5944b4da.ttf"); }

@font-face {
  font-family: "PTRootMedium";
  src: url("/static/media/PT Root UI_Medium.b68f3b84.ttf"); }

@font-face {
  font-family: "SFPropBold";
  src: url(/static/media/SF-Pro-Text-Bold.88b6e0b2.otf); }

@font-face {
  font-family: "SFPro";
  src: url(/static/media/SF-Pro-Text-Regular.a07152f3.otf); }

@font-face {
  font-family: "NeueBold";
  src: url(/static/media/NeueEinstellung-Bold.b28f990e.ttf); }

@font-face {
  font-family: "NeueMedium";
  src: url(/static/media/NeueEinstellung-Normal.2a6bdcf5.ttf); }

@font-face {
  font-family: "LatoBold";
  src: url(/static/media/Lato-Bold.24b516c2.ttf); }

@font-face {
  font-family: "LatoMedium";
  src: url(/static/media/Lato-Light.2bcc211c.ttf); }

:root {
  --main-light-red: #ff7e8e;
  --main-dark-red: #ff3f57;
  --main-dark-orange: #ff7e00;
  --main-darker-red: #e83b51;
  --main-darker-orange: #e87200;
  --main-light-orange: #fdcb56;
  --main-light-grey: #cecece;
  --main-dark-grey: #9b9b9b;
  --main-darker-grey: #a39990;
  --watermelon: #ff3f57;
  --watermelon-dark: #e9001d;
  --main-box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.18),
    0px 0px 21px 0px rgba(0, 0, 0, 0.1);
  /* --button-vertical-padding-large: 13px 0 14px; */ }

body {
  margin: 0;
  padding: 0;
  font-family: PTRootUI !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

b,
h1,
h2,
h3 {
  font-family: PTRootBold !important;
  font-weight: 500 !important;
  margin: 0; }

.white-space-nowrap {
  white-space: nowrap !important; }

.font-root-medium {
  font-family: PTRootMedium !important; }

.grey-font {
  color: rgba(25, 25, 25, 0.4) !important; }

.grey-font-6 {
  color: rgba(25, 25, 25, 0.6) !important; }

.absolute-right-0 {
  position: absolute;
  right: 0; }

.reactstrap-tooltip .tooltip-inner {
  font-size: 14px !important;
  letter-spacing: -0.4px;
  border-radius: 4px;
  line-height: 18px;
  background: red; }

.cursor-default {
  cursor: default !important; }

input,
select,
textarea,
button {
  font-family: PTRootUI;
  outline: unset !important; }

.disabled-input {
  background: rgba(25, 25, 25, 0.1);
  color: rgba(25, 25, 25, 0.4);
  font-size: 16px;
  letter-spacing: -0.3px;
  border-radius: 8px;
  vertical-align: top;
  display: inline-block;
  height: 48px;
  padding: 12px 16px; }

button {
  -webkit-transition: ease-in-out 0.3s;
  transition: ease-in-out 0.3s; }

a {
  text-decoration: none !important; }

a.orange {
  color: #ff7e00;
  text-decoration: underline !important; }

code {
  font-family: Consolas, Menlo, Monaco, Lucida Console, Liberation Mono, DejaVu Sans Mono, Bitstream Vera Sans Mono, Courier New, Courier, monospace !important; }

mono {
  font-family: Consolas, Menlo, Monaco, Lucida Console, Liberation Mono, DejaVu Sans Mono, Bitstream Vera Sans Mono, Courier New, Courier, monospace !important; }

.input-warning {
  color: red;
  margin: 0;
  font-size: 13px;
  padding-bottom: 16px; }

.color-red {
  color: #ff3f57; }

.no-background-and-border-button {
  border: 0;
  background: 0;
  padding: 0; }

.no-fill-button {
  border: solid 1px #ff7e00 !important;
  border: solid 1px var(--main-dark-orange) !important;
  color: #ff7e00 !important;
  color: var(--main-dark-orange) !important;
  background: transparent;
  outline: none;
  box-shadow: none !important;
  border-radius: 8px;
  font-weight: bold; }

.no-fill-button:hover {
  background-color: rgba(255, 126, 0, 0.05); }

.no-fill-button-grey {
  border: solid 1px rgba(25, 25, 25, 0.4) !important;
  color: rgba(25, 25, 25, 0.4) !important;
  background: transparent;
  outline: none;
  box-shadow: none !important;
  border-radius: 8px;
  font-weight: bold; }

.no-fill-button-grey:hover {
  border: solid 1px rgba(25, 25, 25, 0.6) !important;
  color: rgba(25, 25, 25, 0.6) !important; }

.no-fill-button-grey-no-hover {
  border: solid 1px rgba(25, 25, 25, 0.6) !important;
  color: rgba(25, 25, 25, 0.6) !important;
  background: transparent;
  outline: none;
  box-shadow: none !important;
  border-radius: 8px;
  font-weight: bold; }

.no-fill-button-no-border {
  border: none !important;
  color: #ff7e00 !important;
  color: var(--main-dark-orange) !important;
  background: transparent;
  outline: none;
  box-shadow: none !important;
  border-radius: 8px;
  font-weight: bold; }

.no-fill-button-no-border:hover {
  color: #e87200 !important;
  color: var(--main-darker-orange) !important; }

.no-fill-button:active {
  background-color: rgba(255, 126, 0, 0.18); }

.no-fill-button-red {
  border: solid 1px #ff3f57 !important;
  color: #ff3f57 !important;
  background: transparent;
  outline: none;
  box-shadow: none !important;
  border-radius: 8px;
  font-weight: bold; }
  .no-fill-button-red:hover {
    background-color: rgba(255, 126, 0, 0.05); }
  .no-fill-button-red:active {
    background-color: rgba(255, 126, 0, 0.18); }
  .no-fill-button-red[disabled]:hover:hover {
    background: rgba(25, 25, 25, 0.1) !important;
    color: rgba(25, 25, 25, 0.4) !important; }
    .no-fill-button-red[disabled]:hover:hover svg {
      color: rgba(25, 25, 25, 0.4) !important; }

.orange-button {
  background: -webkit-gradient(linear, left top, left bottom, from(#ff9833), to(#ff7e00));
  background: linear-gradient(#ff9833, #ff7e00);
  background: -webkit-gradient(linear, left top, left bottom, from(#ff9833), to(var(--main-dark-orange)));
  background: linear-gradient(#ff9833, var(--main-dark-orange));
  color: white !important;
  border: solid 1px #ff7e00 !important;
  border: solid 1px var(--main-dark-orange) !important;
  outline: none;
  /* box-shadow: none !important; */
  border-radius: 8px;
  -webkit-transition: east-in-out 0.2s;
  transition: east-in-out 0.2s;
  font-weight: bold; }
  .orange-button svg {
    color: white !important; }

.orange-button:hover {
  background: #ff7e00;
  background: var(--main-dark-orange);
  box-shadow: 0 12px 12px 0 rgba(255, 126, 0, 0.2), 0 0 12px 0 rgba(255, 126, 0, 0.1); }

.orange-button:active {
  background: #e87200;
  background: var(--main-darker-orange);
  box-shadow: 0 6px 6px 0 rgba(255, 126, 0, 0.2), 0 0 6px 0 rgba(255, 126, 0, 0.1); }

.no-fill-black-button {
  background: transparent;
  color: #191919 !important;
  border: solid 1px rgba(25, 25, 25, 0.1) !important;
  outline: none;
  box-shadow: 0px 4px 10px 0px rgba(131, 119, 198, 0.1);
  border-radius: 8px;
  -webkit-transition: east-in-out 0.2s;
  transition: east-in-out 0.2s;
  font-weight: bold; }
  .no-fill-black-button svg {
    color: #191919 !important; }

.light-orange-button {
  background: #FFF2E5;
  color: #ff7d00 !important;
  border: solid 1px #FFF2E5 !important;
  outline: none;
  /* box-shadow: none !important; */
  border-radius: 8px;
  -webkit-transition: east-in-out 0.2s;
  transition: east-in-out 0.2s;
  font-weight: bold; }
  .light-orange-button svg {
    position: relative;
    top: -1px;
    margin-right: 4px;
    color: #ff7d00; }
  .light-orange-button:hover {
    background: #ff7d00;
    color: #ffffff !important;
    box-shadow: 0 12px 12px 0 rgba(255, 126, 0, 0.2), 0 0 12px 0 rgba(255, 126, 0, 0.1); }
    .light-orange-button:hover svg {
      color: #ffffff !important; }
  .light-orange-button[disabled]:hover {
    color: rgba(25, 25, 25, 0.4) !important; }
    .light-orange-button[disabled]:hover svg {
      color: rgba(25, 25, 25, 0.4) !important; }

.orange-button-disabled {
  background: -webkit-gradient(linear, left top, left bottom, from(#ff9833), to(#ff7e00));
  background: linear-gradient(#ff9833, #ff7e00);
  background: -webkit-gradient(linear, left top, left bottom, from(#ff9833), to(var(--main-dark-orange)));
  background: linear-gradient(#ff9833, var(--main-dark-orange));
  color: white !important;
  border: solid 1px #ff7e00 !important;
  border: solid 1px var(--main-dark-orange) !important;
  outline: none;
  /* box-shadow: none !important; */
  border-radius: 8px;
  -webkit-transition: east-in-out 0.2s;
  transition: east-in-out 0.2s;
  font-weight: bold; }

.blue-button {
  background-color: #044b84;
  color: #ffffff;
  border-radius: 8px;
  margin-top: 8px;
  font-size: 14px; }
  .blue-button:hover {
    background-color: rgba(4, 75, 132, 0.9); }
  .blue-button svg {
    color: #ffffff; }

.no-fill-blue-button {
  background-color: #fff;
  color: #044b84;
  border-radius: 8px;
  margin-top: 8px;
  font-size: 14px;
  border: solid 1px #044b84; }
  .no-fill-blue-button:hover {
    background-color: #fcfcfc; }
  .no-fill-blue-button svg {
    color: #ffffff; }

.red-button {
  background: #ff3f57;
  color: white !important;
  border: solid 1px #ff3f57 !important;
  border: solid 1px var(--main-dark-red) !important;
  outline: none;
  /* box-shadow: none !important; */
  border-radius: 8px;
  -webkit-transition: east-in-out 0.2s;
  transition: east-in-out 0.2s;
  font-weight: bold; }
  .red-button svg {
    color: white !important; }

.no-fill-button-red {
  border: solid 1px #ff3f57 !important;
  border: solid 1px var(--main-dark-red) !important;
  color: #ff3f57 !important;
  color: var(--main-dark-red) !important;
  background: transparent;
  outline: none;
  box-shadow: none !important;
  border-radius: 8px;
  font-weight: bold; }

.no-fill-button-red:hover {
  background-color: #ff3f57 !important;
  background-color: var(--main-dark-red) !important;
  color: #fff !important; }

.red-button-transparent {
  background: transparent;
  color: #ff3f57 !important;
  color: var(--main-dark-red) !important;
  border: solid 1px #ff3f57 !important;
  border: solid 1px var(--main-dark-red) !important;
  outline: none;
  /* box-shadow: none !important; */
  border-radius: 8px;
  -webkit-transition: east-in-out 0.2s;
  transition: east-in-out 0.2s;
  font-weight: bold; }
  .red-button-transparent svg {
    color: #ff3f57 !important;
    color: var(--main-dark-red) !important; }
  .red-button-transparent[disabled] {
    background: transparent !important;
    color: rgba(25, 25, 25, 0.4) !important;
    border: solid 1px rgba(25, 25, 25, 0.4) !important; }
    .red-button-transparent[disabled]:hover {
      background: transparent !important; }

.red-button.solid {
  background: #ff3f57;
  background: var(--main-dark-red); }

.red-button:hover {
  /* box-shadow: 0 12px 12px 0 rgba(255, 63, 87, 0.2),
    0 0 12px 0 rgba(255, 63, 87, 0.1); */
  background: #e83b51; }

.red-button-no-fill {
  color: #FF3F57;
  background: transparent;
  outline: none;
  /* box-shadow: none !important; */
  border-radius: 8px;
  -webkit-transition: east-in-out 0.2s;
  transition: east-in-out 0.2s;
  font-weight: bold; }
  .red-button-no-fill:hover {
    background: #FAEEEE; }
  .red-button-no-fill svg {
    color: #FF3F57; }

.green-button {
  background: #19c700;
  border: solid 1px #19c700 !important;
  color: #ffffff !important;
  outline: none;
  border-radius: 8px;
  -webkit-transition: east-in-out 0.2s;
  transition: east-in-out 0.2s;
  font-weight: bold; }
  .green-button svg {
    color: #ffffff !important; }

.green-button:hover {
  background: #19B503;
  color: #ffffff !important; }

.yellow-button {
  background: #ffb438;
  color: #ffffff !important; }

.yellow-button:hover {
  background: #f8ad30;
  color: #ffffff !important; }

.red-button:active {
  background: #e83b51; }

.gray-button-disabled {
  background: #1919191A !important;
  color: #19191966 !important;
  border: none !important;
  outline: none;
  border-radius: 8px;
  -webkit-transition: east-in-out 0.2s;
  transition: east-in-out 0.2s;
  font-weight: bold; }
  .gray-button-disabled svg {
    color: #19191966 !important; }

.action-button {
  display: flex;
  align-items: center;
  padding: 4px;
  margin: 0 6px;
  line-height: 1.5;
  letter-spacing: 0.5px;
  color: #ff7e00 !important;
  color: var(--main-dark-orange) !important;
  background: transparent;
  border: none;
  font-weight: 600; }

.action-button * {
  margin: 0 4px; }

.no-fill-button,
.orange-button,
.red-button {
  font-family: PTRootBold; }

.no-fill-button.wide-button,
.orange-button.wide-button,
.red-button.wide-button {
  min-width: 300px !important; }

.action-button.del {
  color: #ff3f57 !important;
  color: var(--watermelon) !important; }

button[disabled] {
  color: rgba(25, 25, 25, 0.4) !important;
  border: solid 1px #E8E8E8 !important;
  background: rgba(25, 25, 25, 0.1) !important;
  cursor: not-allowed;
  box-shadow: none !important; }
  button[disabled] svg {
    color: rgba(25, 25, 25, 0.4) !important; }

.orange-text {
  color: #ff7e00;
  cursor: pointer; }
  .orange-text:hover {
    color: #e87200 !important;
    color: var(--main-darker-orange) !important; }

@-webkit-keyframes fadeInOpacity {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fadeInOpacity {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

.no-result-found {
  color: rgba(25, 25, 25, 0.6) !important; }

.custom-switch-wrapper {
  position: relative;
  display: inline-block;
  width: 70px;
  height: 42px; }

.custom-switch-wrapper input {
  opacity: 0;
  width: 0;
  height: 0; }

.custom-switch-wrapper .custom-switch {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s; }

.custom-switch-wrapper .custom-switch:before {
  position: absolute;
  content: "";
  height: 25px;
  width: 25px;
  left: 8px;
  bottom: 8px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  box-shadow: 0px 2px 4px grey; }

input:checked + .custom-switch {
  background-color: #19C300; }

input:focus + .custom-switch {
  box-shadow: 0 0 1px #79c028; }

input:checked + .custom-switch:before {
  -webkit-transform: translateX(29px);
          transform: translateX(29px); }

/* Rounded custom-switchs */
.custom-switch.round {
  border-radius: 34px;
  margin: 0 !important; }

.custom-switch.round:before {
  border-radius: 50%; }

.custom-checkbox [type="checkbox"]:not(:checked),
.custom-checkbox [type="checkbox"]:checked {
  position: absolute;
  left: -9999px; }

.custom-checkbox [type="checkbox"]:not(:checked) + label,
.custom-checkbox [type="checkbox"]:checked + label {
  position: relative;
  padding-left: 22px;
  cursor: pointer; }

.custom-checkbox [type="checkbox"]:not(:checked):disabled + label,
.custom-checkbox [type="checkbox"]:checked:disabled + label {
  cursor: not-allowed; }

.custom-checkbox [type="checkbox"]:not(:checked) + label:before,
.custom-checkbox [type="checkbox"]:checked + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 2px;
  width: 16px;
  height: 16px;
  border: 1px solid #dcdcdc;
  background: #ffffff;
  border-radius: 4px; }

.custom-checkbox [type="checkbox"]:not(:checked) + label:after,
.custom-checkbox [type="checkbox"]:checked + label:after {
  content: "";
  background-image: url(/static/media/icon-for-checkbox.cec338ac.svg);
  position: absolute;
  /* content: "\f007"; */
  background-size: cover;
  background-repeat: no-repeat;
  top: -3px;
  left: -5px;
  width: 26px;
  height: 26px;
  font-size: 10px;
  line-height: 17px;
  text-align: center;
  color: white;
  border-radius: 4px;
  font-weight: normal; }

.custom-checkbox-circle label::after {
  background-image: url(/static/media/icon-for-checkbox-circle.dcbc3278.svg) !important; }

.custom-checkbox [type="checkbox"]:not(:checked):disabled + label:after,
.custom-checkbox [type="checkbox"]:checked:disabled + label:after {
  background-image: url(/static/media/icon-for-checkbox-disabled.02d127fc.svg);
  background-size: 21px 21px;
  /* width: 18px; */
  top: 0px;
  left: -2px; }

.custom-checkbox-stripe [type="checkbox"]:not(:checked):disabled + label:after,
.custom-checkbox-stripe [type="checkbox"]:checked:disabled + label:after {
  background-image: url(/static/media/icon-for-checkbox-stripe-disabled.16cf6578.svg) !important;
  background-size: 21px 21px;
  /* width: 18px; */
  top: 0px;
  left: -2px; }

.custom-checkbox [type="checkbox"]:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
          transform: scale(0); }

.custom-checkbox [type="checkbox"]:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(1);
          transform: scale(1); }

.custom-checkbox label:hover:before {
  border: 2px solid #ff7e00 !important;
  border: 2px solid var(--main-dark-orange) !important; }

.custom-checkbox input[disabled] + label:hover:before {
  border: 1px solid #dcdcdc !important; }

.custom-checkbox-stripe [type="checkbox"]:not(:checked),
.custom-checkbox-stripe [type="checkbox"]:checked {
  position: absolute;
  left: -9999px; }

.custom-checkbox-stripe [type="checkbox"]:not(:checked) + label,
.custom-checkbox-stripe [type="checkbox"]:checked + label {
  position: relative;
  padding-left: 22px;
  cursor: pointer; }

.custom-checkbox-stripe [type="checkbox"]:not(:checked):disabled + label,
.custom-checkbox-stripe [type="checkbox"]:checked:disabled + label {
  cursor: not-allowed; }

.custom-checkbox-stripe [type="checkbox"]:not(:checked) + label:before,
.custom-checkbox-stripe [type="checkbox"]:checked + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 2px;
  width: 16px;
  height: 16px;
  border: 1px solid #dcdcdc;
  background: #ffffff;
  border-radius: 4px; }

.custom-checkbox-stripe [type="checkbox"]:not(:checked) + label:after,
.custom-checkbox-stripe [type="checkbox"]:checked + label:after {
  content: "";
  background-image: url(/static/media/icon-for-checkbox-stripe.c052d710.svg);
  background-position: center;
  position: absolute;
  /* content: "\f007"; */
  background-size: cover;
  background-repeat: no-repeat;
  top: -3px;
  left: -5px;
  width: 26px;
  height: 26px;
  font-size: 10px;
  line-height: 17px;
  text-align: center;
  color: white;
  border-radius: 4px;
  font-weight: normal; }

.custom-checkbox-stripe-circle label::after {
  background-image: url(/static/media/icon-for-checkbox-circle.dcbc3278.svg) !important; }

.custom-checkbox-stripe [type="checkbox"]:not(:checked):disabled + label:after,
.custom-checkbox-stripe [type="checkbox"]:checked:disabled + label:after {
  background-image: url(/static/media/icon-for-checkbox-disabled.02d127fc.svg);
  background-size: 21px 21px;
  /* width: 18px; */
  top: 0px;
  left: -2px; }

.custom-checkbox-stripe [type="checkbox"]:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
          transform: scale(0); }

.custom-checkbox-stripe [type="checkbox"]:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(1);
          transform: scale(1); }

.custom-checkbox-stripe label:hover:before {
  border: 2px solid #ff7e00 !important;
  border: 2px solid var(--main-dark-orange) !important; }

.custom-checkbox-white [type="checkbox"]:not(:checked),
.custom-checkbox-white [type="checkbox"]:checked {
  position: absolute;
  left: -9999px; }

.custom-checkbox-white [type="checkbox"]:not(:checked) + label,
.custom-checkbox-white [type="checkbox"]:checked + label {
  position: relative;
  padding-left: 22px;
  cursor: pointer; }

.custom-checkbox-white [type="checkbox"]:not(:checked):disabled + label,
.custom-checkbox-white [type="checkbox"]:checked:disabled + label {
  cursor: not-allowed; }

.custom-checkbox-white [type="checkbox"]:not(:checked) + label:before,
.custom-checkbox-white [type="checkbox"]:checked + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 2px;
  width: 16px;
  height: 16px;
  border: 1px solid #dcdcdc;
  background: #ffffff;
  border-radius: 4px; }

.custom-checkbox-white [type="checkbox"]:not(:checked) + label:after,
.custom-checkbox-white [type="checkbox"]:checked + label:after {
  content: "";
  background-image: url(/static/media/icon-for-checkbox-white.17bf65ed.svg);
  position: absolute;
  /* content: "\f007"; */
  background-size: cover;
  background-repeat: no-repeat;
  top: -3px;
  left: -5px;
  width: 26px;
  height: 26px;
  font-size: 10px;
  line-height: 17px;
  text-align: center;
  color: white;
  border-radius: 4px;
  font-weight: normal; }

.custom-checkbox-white [type="checkbox"]:not(:checked):disabled + label:after,
.custom-checkbox-white [type="checkbox"]:checked:disabled + label:after {
  background-image: url(/static/media/icon-for-checkbox-disabled.02d127fc.svg);
  background-size: 21px 21px;
  /* width: 18px; */
  top: 0px;
  left: -2px; }

.custom-checkbox-white [type="checkbox"]:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
          transform: scale(0); }

.custom-checkbox-white [type="checkbox"]:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(1);
          transform: scale(1); }

.custom-checkbox-white label:hover:before {
  border: 2px solid #ff7e00 !important;
  border: 2px solid var(--main-dark-orange) !important; }

.custom-tapTalk-card {
  border-radius: 8px;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.1), 0 2px 2px 0 rgba(0, 0, 0, 0.18);
  background-color: #ffffff; }

.custom-radio [type="radio"]:checked,
[type="radio"]:not(:checked) {
  position: absolute;
  left: -9999px; }

.custom-radio label {
  margin-bottom: 0; }

.custom-radio [type="radio"]:checked + label,
[type="radio"]:not(:checked) + label {
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  display: inline-block;
  color: #191919; }

.custom-radio [type="radio"]:checked + label:before,
[type="radio"]:not(:checked) + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 4px;
  width: 16px;
  height: 16px;
  border: solid 1px #dcdcdc;
  border-radius: 100%;
  background: #fff; }

.custom-radio label:hover::before {
  border: solid 1px #ff7e00;
  border: solid 1px var(--main-dark-orange); }

.custom-radio-error [type="radio"]:not(:checked) + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 4px;
  width: 16px;
  height: 16px;
  border: solid 1px #ff3f57 !important;
  border-radius: 100%;
  background: #fff; }

.custom-radio [type="radio"]:checked + label:after,
[type="radio"]:not(:checked) + label:after {
  content: "";
  width: 16px;
  height: 16px;
  border: solid 5px #ff7e00;
  border: solid 5px var(--main-dark-orange);
  position: absolute;
  top: 4px;
  left: 0;
  border-radius: 100%;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease; }

.custom-radio.custom-radio-disabled [type="radio"]:checked + label:after,
[type="radio"]:not(:checked) + label:after {
  content: "";
  width: 16px;
  height: 16px;
  border: solid 5px #e8e8e8;
  position: absolute;
  top: 4px;
  left: 0;
  border-radius: 100%;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease; }

.custom-radio.custom-radio-inactive [type="radio"]:not(:checked) + label:after {
  content: "";
  width: 14px;
  height: 14px;
  border: none;
  position: absolute;
  top: 5px;
  left: 1px;
  border-radius: 50%;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  -webkit-transform: scale(1);
          transform: scale(1);
  opacity: 1; }

.custom-radio [type="radio"]:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0); }

.custom-radio [type="radio"]:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1); }

.lds-ring {
  display: inline-block;
  position: relative;
  width: 18px;
  height: 18px;
  top: -3px;
  right: -3px; }

.lds-ring.centered-lds-ring {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%); }

.lds-ring.centered-lds-ring div {
  margin: 0; }

.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 18px;
  height: 18px;
  margin: 6px;
  border: 2px solid #fff;
  border-radius: 50%;
  -webkit-animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
          animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #ff7e00 transparent transparent transparent;
  border-color: var(--main-dark-orange) transparent transparent transparent; }

.lds-ring.white-lds-ring div {
  border-color: #ffffff transparent transparent transparent; }

.lds-ring div:nth-child(1) {
  -webkit-animation-delay: -0.45s;
          animation-delay: -0.45s; }

.lds-ring div:nth-child(2) {
  -webkit-animation-delay: -0.3s;
          animation-delay: -0.3s; }

.lds-ring div:nth-child(3) {
  -webkit-animation-delay: -0.15s;
          animation-delay: -0.15s; }

@-webkit-keyframes lds-ring {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes lds-ring {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

.lds-ring-wrapper {
  width: 100%;
  text-align: center; }
  .lds-ring-wrapper .lds-ring {
    top: 3px;
    right: unset; }
    .lds-ring-wrapper .lds-ring div {
      margin: 0; }

.modal-backdrop.show {
  background: #191919 !important; }

.modal-dialog {
  /* width: 100vw !important; */
  margin: 0 auto !important;
  height: 100vh; }

.modal-content {
  position: fixed;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  box-sizing: border-box;
  box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.18),
    0px 0px 21px 0px rgba(0, 0, 0, 0.1);
  box-shadow: var(--main-box-shadow); }

.taptalk-form label {
  font-size: 16px;
  font-weight: bold;
  letter-spacing: -0.4px;
  color: #191919;
  display: block; }

.taptalk-form input[type="text"] {
  padding: 10px 15px 12px;
  border-radius: 8px;
  border: solid 1px #dcdcdc;
  margin: 10px 0 15px;
  font-size: 16px;
  letter-spacing: -0.2px;
  color: #191919;
  position: relative; }

/*table with checkbox*/
.custom-table {
  position: relative;
  max-width: 100%;
  overflow-x: auto; }
  .custom-table.custom-table-show-overflow {
    overflow: visible !important; }

.custom-table-content {
  width: 100%;
  border-collapse: collapse; }

.custom-table-content tr td {
  padding: 8px;
  position: relative;
  white-space: nowrap; }

.custom-table-content tr td > .custom-checkbox {
  display: inline-block;
  position: relative;
  top: -7px;
  margin-right: 15px; }

.custom-table-content tr td > .custom-checkbox-stripe {
  display: inline-block;
  position: relative;
  top: -7px;
  margin-right: 15px; }

.custom-table-content thead tr {
  background: #f8f8f8; }

.custom-table-content thead tr td {
  padding: 8px;
  font-size: 14px;
  letter-spacing: -0.4px; }

.custom-table-content tbody tr:hover td {
  background: rgba(255, 126, 0, 0.15); }

.custom-table-content tr {
  border-bottom: solid 1px #dcdcdc;
  background: #ffffff; }

.custom-table-content thead {
  font-weight: bold; }

.custom-table-content tbody tr td {
  /* cursor: pointer; */
  font-weight: 500;
  position: relative;
  max-width: 300px;
  white-space: normal;
  vertical-align: top;
  font-size: 14px;
  letter-spacing: -0.2px; }

.custom-table-content tbody tr td.no-data-column {
  text-align: center;
  color: #a6a6a6;
  background: none !important;
  padding: 24px 0;
  border: none !important; }

tr.no-data-column {
  border: none !important; }
  tr.no-data-column:nth-child(odd) td {
    background: none !important; }

.custom-table-content tbody tr td.action {
  width: 50px; }

.custom-table-content tbody tr td svg {
  font-size: 22px;
  color: #ff7e00;
  color: var(--main-dark-orange);
  position: absolute;
  right: 6px;
  top: 18px; }

.custom-table-content tbody tr td.email div {
  display: inline-block;
  position: relative;
  top: -7px;
  margin-right: 16px; }

.custom-table-content tbody tr td.col_action {
  width: 50px; }

.custom-table-content tbody tr td.col_action div {
  display: none; }

.custom-table-content tbody tr:hover td.col_action div {
  display: block; }

.custom-table-content .resend-invitation {
  padding: 3px 25px; }

/*table with checkbox*/
.custom-dropdown button.dropdown-toggle {
  border: solid 1px #dcdcdc !important;
  background: #ffffff !important;
  color: #191919 !important;
  border-radius: 8px;
  text-align: left;
  position: relative;
  width: 285px;
  box-shadow: none !important;
  padding: 9px 60px 10px 15px; }

.custom-dropdown button.dropdown-toggle::after {
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
  border-left: solid 1px #191919;
  border-bottom: solid 1px #191919;
  border-right: 0;
  border-top: 0;
  height: 7px;
  width: 7px;
  position: absolute;
  top: 17px;
  right: 18px; }

.custom-dropdown button.dropdown-toggle::before {
  content: "";
  width: 45px;
  position: absolute;
  height: 44px;
  top: 0;
  right: 0;
  background: transparent;
  border-left: solid 1px #dcdcdc; }

.custom-dropdown .dropdown-menu {
  width: 285px;
  border-radius: 8px;
  margin-top: 0;
  padding: 0; }

.custom-dropdown .dropdown-menu .dropdown-item {
  background: transparent !important;
  color: #191919 !important;
  position: relative; }

.custom-dropdown .dropdown-menu button {
  border: none !important;
  border-bottom: solid 1px #dcdcdc !important;
  padding: 10px 0 10px 46px; }

.custom-dropdown .dropdown-menu button:nth-last-child(1) {
  border: none !important; }

.custom-dropdown .dropdown-menu .dropdown-item.active-dropdown-list {
  color: #ff7e00 !important;
  color: var(--main-dark-orange) !important; }

.custom-dropdown .dropdown-menu .dropdown-item.active-dropdown-list::before {
  content: "";
  position: absolute;
  left: 17px;
  top: 50%;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%);
  width: 15px;
  height: 10px;
  background-position: center;
  background-size: 20px;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAAEgBckRAAAABGdBTUEAALGPC/xhBQAAAjtJREFUaAXtWEtOwzAQnX72cBs4AVwAiUq9CKs26oqDUKkgLgJrxC2QyJ7GzKSe1PGndlKHUmkiVXY8M++9mTiOXYCjL7UA5YB4Bx2vAQdQwHcDz2pag2z1DrJx+Ja0qQIeoky1o5kVRkztKHLSY+VoBSPbLvehChiVC7nsx4PO+CSf9m67XtCZzGj8oR8HhZxbT9IImCQ/ZSOIyaQ9qwrg5Cpswc4KYDuk3DeTroIS/QszpjXxTENKvwEGoOXo0hfjENTvUAVzdN5ikDfDFGAmcwjYoN+IiUnUBZhxggTsYBDRULAU7C+tVEAqcM4V8C3V2fIhcFqnjLWqwR43vZ6dWnkFyzp8DFc2THSxswPMext8VMC7aad+b4IUcCJwSqReYIK1vCdj6EoFp3iHAD7qDd4mRNIF3E8AMNPKHZIW+BSufTXXsU3jfQZa/UZ7zfDb/OyAL+CtQTnQ8RKQf4tkBK94nLmrcXAqpihnziCBQ0IDVJZE5UwQbSkT/Cm1cl+iaHCqg3qEi1Rf8ZMKSAWkAlIBqcB/rwDtK+qP5+7f0SKm9+DnPhac097aEO2BS9wU3hzav5w8gb7COceTJXCs8JMlkEt4cgJ0zoFPWENVn3W2GDinTTwDpLa5hTNvdArpw8EaA+hPO76SEwkKn8Jtjv1/NAFW3DWRoYWzruQEOCCWyF8JZz2dE+DAQCJs5rbEo2KWqcKAdts7AQYKJDK4cObP1lIiaglfg56fs6kVIKmAVEAqIBXQFfgFrMT8bpmHz1kAAAAASUVORK5CYII=); }

.custom-date-picker .DayPicker-Caption {
  position: relative; }

.custom-date-picker .DayPicker-wrapper {
  border: none !important;
  box-shadow: none !important;
  outline: 0 !important; }

.custom-date-picker .DayPicker-wrapper .DayPicker-Month {
  margin: 16px 12px 0; }

.custom-date-picker .DayPicker-Caption div {
  font-weight: bold !important;
  text-align: center; }

.custom-date-picker span.DayPicker-NavButton.DayPicker-NavButton--prev {
  position: absolute;
  display: inline-block;
  margin: 0;
  left: 27px;
  top: 20px;
  z-index: 1;
  width: 20px;
  height: 20px;
  background-image: none; }

.custom-date-picker span.DayPicker-NavButton.DayPicker-NavButton--prev::before {
  position: absolute;
  top: 4px;
  left: 0;
  content: "";
  border-left: solid 2px #ff7e00;
  border-left: solid 2px var(--main-dark-orange);
  border-bottom: solid 2px #ff7e00;
  border-bottom: solid 2px var(--main-dark-orange);
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  width: 12px;
  height: 12px; }

.custom-date-picker span.DayPicker-NavButton.DayPicker-NavButton--next {
  position: absolute;
  display: inline-block;
  margin: 0;
  right: 19px;
  top: 20px;
  z-index: 1;
  width: 20px;
  height: 20px;
  background-image: none; }

.custom-date-picker span.DayPicker-NavButton.DayPicker-NavButton--next::before {
  position: absolute;
  top: 4px;
  left: 0;
  content: "";
  border-right: solid 2px #ff7e00;
  border-right: solid 2px var(--main-dark-orange);
  border-bottom: solid 2px #ff7e00;
  border-bottom: solid 2px var(--main-dark-orange);
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
  width: 12px;
  height: 12px; }

.custom-date-picker .DayPicker-Day {
  font-weight: bold;
  width: 40px;
  height: 40px;
  text-align: center; }

.custom-date-picker .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
  background-color: #ff7e00;
  background-color: var(--main-dark-orange); }

.custom-date-picker .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover {
  background-color: #ff7e00;
  background-color: var(--main-dark-orange); }

/* single date picker */
/* .recharts-layer path {
	fill: red;
} */
/* custom chart */
.input-file-wrapper input {
  display: none; }

.input-file-wrapper label {
  padding: 12px 35px;
  display: inline-block !important;
  margin: 10px 0 24px !important; }

.input-file-wrapper label svg {
  position: relative;
  top: -1px;
  margin-right: 4px;
  cursor: pointer; }

.sort-by-dropdown .dropdown .dropdown-toggle {
  background: transparent !important;
  color: #ff7e00 !important;
  color: var(--main-dark-orange) !important;
  border: none !important;
  box-shadow: none !important;
  padding: 0 !important;
  font-weight: bold; }

.sort-by-dropdown .dropdown .dropdown-toggle:hover {
  color: #e87200 !important;
  color: var(--main-darker-orange) !important; }

.sort-by-dropdown .dropdown .dropdown-toggle::after {
  position: relative;
  top: 1px;
  margin-left: 8px; }

.go-back-wrapper a {
  color: #ff7e00 !important;
  color: var(--main-dark-orange) !important;
  display: block;
  padding-bottom: 15px; }

.go-back-wrapper a svg {
  position: relative;
  top: -1px; }

.box-extra-info {
  border-radius: 8px;
  padding: 11px 16px 11px 52px;
  font-size: 16px;
  color: #191919;
  display: block;
  position: relative; }

.box-extra-info.info-blue {
  background: #dcebff;
  color: #044b84; }

.box-extra-info.info-brown {
  background: #fdd984; }

.box-extra-info svg {
  font-size: 28px;
  position: absolute;
  left: 16px;
  top: 9px; }

.box-extra-info p {
  margin: 0; }

.modal.fade.show {
  width: 100vw;
  position: fixed;
  height: 100vh;
  top: 0;
  left: 0;
  background: rgba(25, 25, 25, 0.6); }

.modal-footer > * {
  margin: unset; }

.modal-backdrop.show {
  background: #191919 !important; }

.modal-dialog {
  /* width: 100vw !important; */
  margin: 0 auto !important;
  height: 100vh;
  position: relative !important;
  top: 0;
  left: 0;
  max-width: unset !important;
  width: 500px !important; }

.modal-content {
  position: fixed;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  box-sizing: border-box;
  box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.18),
    0px 0px 21px 0px rgba(0, 0, 0, 0.1);
  box-shadow: var(--main-box-shadow);
  background: #ffffff; }

.manage-project-tool-wrapper {
  position: absolute;
  right: 0;
  top: 50%;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%);
  margin-top: 0;
  padding: 0;
  border: none;
  height: 46px;
  margin-top: 0;
  padding: 0; }

.manage-project-tool-wrapper #button-box-wrapper {
  text-align: right;
  display: inline-block;
  position: absolute;
  right: 0;
  top: 0; }

.manage-project-tool-wrapper #button-box-wrapper button {
  margin-left: 10px;
  padding: 10px 30px; }

.typing-dot-one {
  opacity: 0;
  -webkit-animation: typing-dot 1.3s infinite;
          animation: typing-dot 1.3s infinite;
  -webkit-animation-delay: 0.0s;
          animation-delay: 0.0s;
  letter-spacing: 1px; }

.typing-dot-two {
  opacity: 0;
  -webkit-animation: typing-dot 1.3s infinite;
          animation: typing-dot 1.3s infinite;
  -webkit-animation-delay: 0.2s;
          animation-delay: 0.2s;
  letter-spacing: 1px; }

.typing-dot-three {
  opacity: 0;
  -webkit-animation: typing-dot 1.3s infinite;
          animation: typing-dot 1.3s infinite;
  -webkit-animation-delay: 0.3s;
          animation-delay: 0.3s;
  letter-spacing: 1px; }

@-webkit-keyframes typing-dot {
  0% {
    opacity: 0; }
  50% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes typing-dot {
  0% {
    opacity: 0; }
  50% {
    opacity: 0; }
  100% {
    opacity: 1; } }

.message-body {
  word-break: break-word; }

.button-padding-with-icon {
  padding: 8px 32px 8px 24px; }

.button-padding-without-icon {
  padding: 8px 24px; }

.user-avatar-name {
  text-transform: uppercase;
  border-radius: 50%; }

.button-flex {
  margin-top: 0 !important; }

.form-title-wrapper {
  padding-bottom: 16px;
  border-bottom: solid 1px rgba(25, 25, 25, 0.1);
  color: #191919;
  font-size: 20px;
  letter-spacing: -0.6px; }

.form-content-wrapper .input-info-wrapper {
  margin-top: 8px;
  font-size: 12px;
  letter-spacing: -0.4px;
  color: rgba(0, 0, 0, 0.6); }
  .form-content-wrapper .input-info-wrapper svg {
    margin-right: 4px;
    position: relative;
    top: -1px;
    font-size: 14px; }

.form-content-wrapper .disabled-form {
  position: relative;
  display: inline-block; }
  .form-content-wrapper .disabled-form input {
    background: #f3f3f3 !important;
    border: 1px solid rgba(25, 25, 25, 0.1) !important;
    color: rgba(25, 25, 25, 0.6) !important; }
  .form-content-wrapper .disabled-form svg {
    color: rgba(25, 25, 25, 0.4);
    font-size: 20px;
    position: absolute;
    top: 50%;
    right: 16px;
    -webkit-transform: translate(0, -50%);
            transform: translate(0, -50%);
    cursor: pointer; }
    .form-content-wrapper .disabled-form svg:hover {
      color: #ff7e00; }

.form-content-wrapper-label16 label {
  font-size: 16px !important;
  letter-spacing: -0.5px !important; }

.border-warning-red {
  border: 1px solid #FF3F57 !important; }

.warning-text {
  color: #ff3f57 !important; }

.form-content-wrapper label {
  display: block;
  color: #191919;
  font-size: 16px;
  margin-top: 24px;
  letter-spacing: -0.5px; }
  .form-content-wrapper label label {
    display: block;
    color: #191919;
    font-size: 14px;
    margin-top: 24px; }
    .form-content-wrapper label label.label-disabled {
      color: rgba(25, 25, 25, 0.2); }
    .form-content-wrapper label label .input-label-optional {
      color: rgba(25, 25, 25, 0.6); }

.form-content-wrapper input[type="text"] {
  background: white;
  border-radius: 8px;
  border: 1px solid rgba(25, 25, 25, 0.1);
  height: 48px;
  width: 348px;
  color: #191919;
  font-size: 16px;
  letter-spacing: -0.3px;
  width: 316px;
  max-width: 100%;
  padding: 0 16px;
  caret-color: #ff7e00;
  caret-color: var(--main-dark-orange); }

.form-content-wrapper textarea {
  background: white;
  border-radius: 8px;
  border: 1px solid rgba(25, 25, 25, 0.1);
  min-height: 48px;
  width: 348px;
  color: #191919;
  font-size: 16px;
  letter-spacing: -0.3px;
  width: 316px;
  max-width: 100%;
  padding: 12px 16px;
  line-height: 24px;
  resize: none;
  caret-color: #ff7e00;
  caret-color: var(--main-dark-orange); }

.form-content-wrapper label {
  display: block !important;
  letter-spacing: -0.4px; }

.form-content-wrapper .role-radio-label {
  position: relative; }

.form-content-wrapper .role-radio-label b {
  display: block;
  color: #191919;
  font-size: 14px;
  letter-spacing: -0.4px; }

.form-content-wrapper .role-radio-label label {
  color: rgba(25, 25, 25, 0.8);
  font-size: 14px;
  letter-spacing: -0.2px; }

.form-content-wrapper .custom-radio label {
  margin-top: 18px; }

.form-content-wrapper button {
  width: 300px;
  padding: 10px 0 11px;
  margin-top: 32px; }

.form-content-wrapper .orange-button .lds-ring {
  top: 3px;
  right: unset; }

.form-content-wrapper .orange-button .lds-ring div {
  border-color: #ffffff transparent transparent transparent;
  margin: 0; }

.form-content-wrapper.disabled-field label {
  color: rgba(25, 25, 25, 0.2) !important; }

.form-content-wrapper.disabled-field input[type="text"] {
  color: rgba(25, 25, 25, 0.6);
  background: #E8E8E8;
  cursor: not-allowed; }

.form-content-wrapper input[type="text"]:focus,
.form-content-wrapper textarea:focus {
  border: solid 1px #ff7e00; }

input[type="text"][disabled],
textarea[disabled],
textarea[disabled] {
  color: rgba(25, 25, 25, 0.8) !important;
  background: #ebebeb !important;
  border: solid 1px rgba(25, 25, 25, 0.1) !important; }

.caret-white {
  caret-color: #ffffff; }

.alert-component {
  border-radius: 8px;
  position: relative;
  padding: 12px 48px;
  letter-spacing: -0.5px;
  font-size: 16px;
  display: inline-block;
  vertical-align: top; }
  .alert-component svg {
    position: absolute;
    font-size: 24px;
    left: 16px;
    top: 12px; }
  .alert-component img {
    position: absolute;
    font-size: 24px;
    left: 16px;
    top: 50%;
    -webkit-transform: translate(0, -50%);
            transform: translate(0, -50%); }
  .alert-component.alert-blue {
    color: #0c5f99;
    background: #EBF7FF;
    border: solid 1px #0c5f99; }
  .alert-component.alert-orange {
    color: #ff7e00;
    color: var(--main-dark-orange);
    background: #fff2e5; }

.alert-component-whatsapp {
  border-radius: 8px;
  padding: 13px 144px 13px 10px;
  color: #044B84;
  background: #DCEBFF;
  border: solid 1px #044B84;
  margin: 24px 0 12px;
  line-height: 1;
  position: relative;
  display: inline-block; }
  .alert-component-whatsapp .alert-component-left-icon {
    position: relative;
    top: -1px;
    font-size: 18px;
    margin-right: 4px; }
  .alert-component-whatsapp img {
    position: relative;
    margin: 0 4px 0 6px;
    top: -2px;
    width: 15px;
    height: 15px; }
  .alert-component-whatsapp button {
    position: absolute;
    right: 16px;
    top: 50%;
    -webkit-transform: translate(0, -50%);
            transform: translate(0, -50%);
    height: 26px;
    font-size: 12px;
    letter-spacing: -0.4px;
    border: none;
    color: #ffffff;
    background: #044b84;
    border-radius: 4px;
    padding: 0 12px;
    line-height: 24px; }
    .alert-component-whatsapp button svg {
      font-size: 13px;
      margin-left: 4px;
      position: relative; }
    .alert-component-whatsapp button:hover {
      background: rgba(4, 75, 132, 0.8); }

.main-content-loading-screen .lds-ring {
  width: 50px;
  height: 50px;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  right: unset; }
  .main-content-loading-screen .lds-ring div {
    border: 5px solid #fff;
    border-color: #ff7d00 transparent transparent transparent;
    width: 50px;
    height: 50px;
    margin: 0; }

.step-container .step-content {
  display: inline-block; }
  .step-container .step-content .step-number {
    width: 18px;
    height: 18px;
    text-align: center;
    line-height: 18px;
    font-size: 12px;
    letter-spacing: -0.4px;
    color: #ffffff;
    background: rgba(25, 25, 25, 0.4);
    border-radius: 50%;
    display: inline-block; }
  .step-container .step-content .step-wording {
    font-size: 14px;
    color: #19191966;
    letter-spacing: -0.4px;
    display: inline-block;
    position: relative;
    top: 1px;
    margin-left: 6px; }
  .step-container .step-content.orange-step .step-number {
    background: #ff7d00; }
  .step-container .step-content.orange-step .step-wording {
    color: #ff7d00; }

.step-container .step-line {
  height: 2px;
  width: 20px;
  display: inline-block;
  margin: 0 12px;
  background: rgba(25, 25, 25, 0.4);
  position: relative;
  top: -2px; }
  .step-container .step-line.step-line-orange {
    background: #ff7d00; }

.arrow::before {
  border-bottom: solid 5px #6B6B6B !important; }

.tooltip-inner {
  font-size: 14px;
  letter-spacing: -0.2px;
  text-align: left !important;
  line-height: 20px;
  padding: 6px 12px !important;
  background: #6B6B6B !important; }

.no-margin {
  margin: 0 !important; }

.link-orange {
  color: #ff7d00; }
  .link-orange:hover {
    color: #e87200; }

.grey-text {
  color: rgba(25, 25, 25, 0.6) !important; }

.grey-text-04 {
  color: rgba(25, 25, 25, 0.4) !important; }

.red-text {
  color: #ff3f57 !important; }
  .red-text svg {
    color: #ff3f57 !important; }
  .red-text b {
    color: #ff3f57 !important; }

.red-text-hover {
  color: #ff3f57;
  color: var(--watermelon); }
  .red-text-hover:hover {
    color: #e9001d !important;
    color: var(--watermelon-dark) !important; }

.red-background {
  background: #ff3f57 !important; }

.green-text {
  color: #19c700 !important; }

.green-background {
  background: #19c700 !important; }

.blue-text {
  color: #3BAEFF !important; }

.orange-text {
  color: #ff7e00 !important;
  color: var(--main-dark-orange) !important; }

.yellow-text {
  color: #F4C12A !important; }

.yellow-background {
  background: #F4C12A !important; }

.facebook-button {
  width: 278px;
  border-radius: 8px;
  border: none;
  background: #3678EA;
  color: #ffffff;
  font-size: 16px;
  letter-spacing: -0.5px; }
  .facebook-button:hover {
    background: #166fe5; }

.channel-button-wrapper b {
  letter-spacing: -0.5px;
  font-size: 16px; }
  .channel-button-wrapper b.channel-button-title {
    font-size: 16px;
    letter-spacing: -0.5px; }
  .channel-button-wrapper b.status-value {
    font-size: 16px; }

.orange-link-text {
  color: #ff7e00 !important;
  color: var(--main-dark-orange) !important;
  cursor: pointer; }
  .orange-link-text svg {
    position: relative !important;
    top: -2px !important;
    margin-right: 4px;
    color: #ff7e00 !important;
    color: var(--main-dark-orange) !important; }
  .orange-link-text:hover {
    color: #e87200 !important;
    color: var(--main-darker-orange) !important; }
    .orange-link-text:hover svg {
      color: #e87200 !important;
      color: var(--main-darker-orange) !important; }

.text-center {
  text-align: center !important; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.input-info-text {
  font-size: 12px;
  letter-spacing: -0.4px;
  color: rgba(25, 25, 25, 0.8);
  margin: 8px 0 16px;
  width: 320px;
  position: relative;
  padding: 0 0 0 16px; }
  .input-info-text svg {
    position: absolute;
    left: 0;
    top: 3px;
    margin-right: 4px; }
  .input-info-text.no-margin-bottom {
    margin: 8px 0 0; }

.no-margin {
  margin: 0 !important; }

.no-margin-top {
  margin-top: 0 !important; }

.no-margin-bottom {
  margin-bottom: 0 !important; }

.width-100 {
  width: 100% !important; }

.topic-list-content {
  color: rgba(25, 25, 25, 0.6);
  font-size: 12px;
  letter-spacing: -.4px;
  border-radius: 4px;
  background: rgba(25, 25, 25, 0.05);
  margin: 8px 4px 0 0;
  display: inline-block;
  padding: 2px 8px;
  vertical-align: top;
  max-width: 100%;
  word-break: break-word; }
  .topic-list-content .remove-topic {
    display: inline-block;
    color: #9c9c9c;
    font-size: 18px;
    margin-left: 4px;
    position: relative;
    top: 4px;
    cursor: pointer;
    font-size: 8px;
    background: #9a9a9a;
    width: 10px;
    height: 10px;
    color: #ffffff;
    border-radius: 50%;
    vertical-align: top; }
    .topic-list-content .remove-topic svg {
      position: absolute;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%); }
    .topic-list-content .remove-topic:hover {
      background: #818181; }
  .topic-list-content.topic-list-with-remove {
    white-space: nowrap;
    position: relative;
    text-overflow: ellipsis;
    overflow: hidden;
    padding: 2px 28px 2px 8px; }
    .topic-list-content.topic-list-with-remove .remove-topic {
      position: absolute;
      right: 8px;
      top: 6px; }

.remove-bulk {
  display: inline-block;
  color: #9c9c9c;
  font-size: 18px;
  margin-left: 4px;
  position: relative;
  top: 4px;
  cursor: pointer;
  font-size: 8px;
  background: #9a9a9a;
  width: 10px;
  height: 10px;
  color: #ffffff;
  border-radius: 50%;
  vertical-align: top; }
  .remove-bulk svg {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  .remove-bulk:hover {
    background: #818181; }

.margin-0 {
  margin: 0 !important; }

.margin-top24 {
  margin-top: 24px; }

.padding-bottom24 {
  padding-bottom: 24px !important; }

.text-green {
  color: #19c700 !important; }

.text-left {
  text-align: left !important; }

@-webkit-keyframes taplivePlaceholderShimmer {
  0% {
    background-position: -468px 0; }
  100% {
    background-position: 468px 0; } }

@keyframes taplivePlaceholderShimmer {
  0% {
    background-position: -468px 0; }
  100% {
    background-position: 468px 0; } }

.taplive-shine {
  background: #f6f7f8;
  background-image: -webkit-gradient(linear, left top, right top, from(#f6f7f8), color-stop(20%, #edeef1), color-stop(40%, #f6f7f8), to(#f6f7f8));
  background-image: linear-gradient(to right, #f6f7f8 0%, #edeef1 20%, #f6f7f8 40%, #f6f7f8 100%);
  background-repeat: no-repeat;
  background-size: 800px 104px;
  display: inline-block;
  position: relative;
  -webkit-animation-duration: 1.5s;
          animation-duration: 1.5s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-name: taplivePlaceholderShimmer;
          animation-name: taplivePlaceholderShimmer;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear; }

.force-hide {
  display: none !important; }

.disabled-span {
  color: rgba(25, 25, 25, 0.4) !important; }

::-webkit-input-placeholder {
  color: rgba(25, 25, 25, 0.3); }

::-ms-input-placeholder {
  color: rgba(25, 25, 25, 0.3); }

::placeholder {
  color: rgba(25, 25, 25, 0.3); }

.text-capitalize {
  text-transform: capitalize; }

.font-orange {
  color: #ff7d00 !important; }

.font-red {
  color: #ff3f57 !important; }
  .font-red svg {
    color: #ff3f57 !important; }

.font-green {
  color: #19C700 !important; }

.font-yellow {
  color: #EDAB00 !important; }

.font-grey {
  color: rgba(25, 25, 25, 0.4); }

.border-red {
  border-color: #ff3f57 !important; }
  .border-red .react-select__control {
    border: solid 1px #ff3f57 !important; }
  .border-red .dropdown .custom-select-option-value-wrapper {
    border: solid 1px #ff3f57 !important; }

.margin-bottom-8 {
  margin-bottom: 8px !important; }

.margin-top-0 {
  margin-top: 0px !important; }

.margin-top-8 {
  margin-top: 8px !important; }

.margin-top-10 {
  margin-top: 10px !important; }

.margin-top-12 {
  margin-top: 12px !important; }

.margin-top-14 {
  margin-top: 14px !important; }

.margin-top-24 {
  margin-top: 24px !important; }

.margin-top-32 {
  margin-top: 32px !important; }

.margin-bottom-24 {
  margin-bottom: 24px !important; }

.display-block {
  display: block !important; }

.right-0 {
  right: 0 !important;
  left: unset !important; }

[placeholder] {
  text-overflow: ellipsis; }

.separator-line-grey {
  background: rgba(25, 25, 25, 0.1);
  width: 100%;
  display: block;
  height: 1px; }

.color-707070 {
  color: #707070 !important; }

.no-data-found {
  color: rgba(25, 25, 25, 0.6);
  letter-spacing: -0.6px;
  font-size: 16px; }

.grey-box {
  background: #ebebeb !important; }

.pos-relative {
  position: relative; }

.modal-add-topic .popup-header {
  margin-bottom: 16px !important; }

.modal-add-topic label {
  font-size: 14px;
  position: relative;
  width: 100%; }

.absolute-top-right-0 {
  position: absolute;
  top: 0;
  right: 0; }

.react-select-custom__single-value {
  color: #191919 !important; }

.react-select-custom__input {
  color: #191919 !important; }

.no-padding {
  padding: 0 !important; }

.no-padding-top {
  padding-top: 0 !important; }

.line-through {
  height: 1px;
  background: #dcdcdc;
  width: 100%; }

.no-hover {
  cursor: default !important; }

.colored-info-box {
  white-space: nowrap !important; }

.pos-relative {
  position: relative !important; }

.optional-text {
  color: rgba(0, 0, 0, 0.6) !important;
  font-family: 'PTRootUI' !important;
  position: relative !important;
  right: unset !important; }

.font16 {
  font-size: 16px !important;
  letter-spacing: -0.5px !important; }

.font14 {
  font-size: 14px !important;
  letter-spacing: -0.4px !important; }

.cursor-pointer {
  cursor: pointer; }

.text-uppercase {
  text-transform: uppercase; }

.form-content-wrapper input[type="text"].border-red {
  border-color: #ff3f57 !important; }

.form-content-wrapper textarea[type="text"].border-red {
  border-color: #ff3f57 !important; }

.dropdown-select-item-per-page .dropdown {
  width: 74px;
  height: 30px; }
  .dropdown-select-item-per-page .dropdown button {
    position: relative;
    border-radius: 4px !important;
    height: 30px; }
  .dropdown-select-item-per-page .dropdown svg {
    position: absolute !important;
    top: 15px !important;
    right: 6px !important;
    font-size: 18px; }

.custom-select2-wrapper {
  border: none !important;
  background: none !important; }
  .custom-select2-wrapper input[type="text"]:focus {
    border: none !important;
    background: none !important; }
  .custom-select2-wrapper input[type="text"] {
    border: none !important;
    background: none !important; }
    .custom-select2-wrapper input[type="text"]:disabled {
      border: none !important;
      background: none !important; }
    .custom-select2-wrapper input[type="text"]:focus {
      border: none !important;
      background: none !important; }

.green-background {
  background: #19C700 !important; }

.orange-background {
  background: #FF7E00 !important; }

.yellow-background {
  background: #F4C12A !important; }

.red-background {
  background: #E02E2E !important; }

.blue-background {
  background: #0c5f99 !important; }

.badge-text {
  font-size: 12px;
  letter-spacing: -0.4px;
  color: rgba(25, 25, 25, 0.6);
  display: inline-block;
  border-radius: 4px;
  padding: 2px 8px;
  white-space: nowrap;
  color: #ffffff; }

@keyframes taplivePlaceholderShimmer {
  0% {
    background-position: -468px 0; }
  100% {
    background-position: 468px 0; } }

.onetalk-shine {
  background: #f6f7f8;
  background-image: -webkit-gradient(linear, left top, right top, from(#f6f7f8), color-stop(20%, #edeef1), color-stop(40%, #f6f7f8), to(#f6f7f8)) !important;
  background-image: linear-gradient(to right, #f6f7f8 0%, #edeef1 20%, #f6f7f8 40%, #f6f7f8 100%) !important;
  background-repeat: no-repeat;
  background-size: 800px 104px;
  display: inline-block;
  position: relative;
  -webkit-animation-duration: 1.5s;
          animation-duration: 1.5s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-name: taplivePlaceholderShimmer;
          animation-name: taplivePlaceholderShimmer;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear; }

.border-red .react-select-custom__control {
  border-color: #ff3f57 !important; }

.no-overflow {
  overflow: hidden !important; }

.main-button-26 {
  padding: 0 24px !important;
  height: 26px;
  line-height: 24px;
  vertical-align: top;
  font-size: 14px !important;
  border-radius: 6px !important; }
  .main-button-26 svg {
    position: relative !important;
    top: -1.5px !important;
    margin-right: 6px !important;
    z-index: 2;
    cursor: pointer; }
    .main-button-26 svg.icon-right {
      margin-left: 6px;
      margin-right: 0; }
  .main-button-26 img {
    position: relative !important;
    top: -1.5px !important;
    width: 16px !important;
    margin-right: 6px !important;
    z-index: 2;
    cursor: pointer; }
    .main-button-26 img.icon-right {
      margin-left: 6px;
      margin-right: 0; }
  .main-button-26.width-100 {
    width: 100% !important; }
  .main-button-26.with-image-or-loading-left {
    padding: 0 32px 0 24px !important; }
  .main-button-26.with-image-or-loading-right {
    padding: 0 24px 0 32px !important; }

.main-button-40 {
  padding: 0 24px !important;
  height: 40px;
  line-height: 37px;
  vertical-align: top;
  font-size: 16px !important; }
  .main-button-40.minWidth180 {
    min-width: 180px !important; }
  .main-button-40 svg {
    position: relative !important;
    top: -1.5px !important;
    margin-right: 6px !important;
    z-index: 2;
    cursor: pointer; }
    .main-button-40 svg.icon-right {
      margin-left: 6px;
      margin-right: 0; }
  .main-button-40 img {
    position: relative !important;
    top: -1.5px !important;
    width: 16px !important;
    margin-right: 6px !important;
    z-index: 2;
    cursor: pointer; }
    .main-button-40 img.icon-right {
      margin-left: 6px;
      margin-right: 0; }
  .main-button-40.width-100 {
    width: 100% !important; }
  .main-button-40.with-image-or-loading-left {
    padding: 0 32px 0 24px !important; }
  .main-button-40.with-image-or-loading-right {
    padding: 0 24px 0 32px !important; }

.main-button-40-no-padding-horizontal {
  padding: 0 !important;
  height: 40px;
  line-height: 37px;
  vertical-align: top; }
  .main-button-40-no-padding-horizontal svg {
    position: relative !important;
    top: -1.5px !important;
    margin-right: 6px !important; }
    .main-button-40-no-padding-horizontal svg.icon-right {
      margin-left: 6px !important;
      margin-right: 0 !important; }
  .main-button-40-no-padding-horizontal img {
    position: relative !important;
    top: -1.5px !important;
    width: 16px !important;
    margin-right: 6px !important; }
    .main-button-40-no-padding-horizontal img.icon-right {
      margin-left: 6px !important;
      margin-right: 0 !important; }
  .main-button-40-no-padding-horizontal.width-100 {
    width: 100% !important; }
  .main-button-40-no-padding-horizontal.with-image-or-loading-left {
    padding: 0 !important; }
  .main-button-40-no-padding-horizontal.with-image-or-loading-right {
    padding: 0 !important; }

.main-button-48 {
  padding: 0 40px !important;
  height: 48px;
  line-height: 45px;
  vertical-align: top;
  font-size: 16px !important; }
  .main-button-48.minWidth180 {
    min-width: 180px !important; }
  .main-button-48 svg {
    position: relative !important;
    top: -1.5px !important;
    margin-right: 6px !important;
    font-size: 20px !important; }
    .main-button-48 svg.icon-right {
      margin-left: 6px !important;
      margin-right: 0 !important; }
  .main-button-48 img {
    position: relative;
    top: -1.5px !important;
    width: 16px !important;
    margin-right: 6px !important;
    min-width: 20px; }
    .main-button-48 img.icon-right {
      margin-left: 6px !important;
      margin-right: 0 !important; }
  .main-button-48.width-100 {
    width: 100% !important; }
  .main-button-48[disabled] {
    line-height: 46px; }
  .main-button-48.with-image-or-loading-left {
    padding: 0 40px 0 32px !important; }
  .main-button-48.with-image-or-loading-right {
    padding: 0 32px 0 40px !important; }

.main-button-48-no-padding-horizontal {
  padding: 0 !important;
  height: 48px;
  line-height: 45px;
  vertical-align: top; }
  .main-button-48-no-padding-horizontal svg {
    position: relative !important;
    top: -1.5px !important;
    margin-right: 6px !important;
    font-size: 20px !important; }
    .main-button-48-no-padding-horizontal svg.icon-right {
      margin-left: 6px !important;
      margin-right: 0 !important; }
  .main-button-48-no-padding-horizontal img {
    position: relative !important;
    top: -1.5px !important;
    width: 16px !important;
    margin-right: 6px !important; }
    .main-button-48-no-padding-horizontal img.icon-right {
      margin-left: 6px !important;
      margin-right: 0 !important; }
  .main-button-48-no-padding-horizontal.width-100 {
    width: 100% !important; }
  .main-button-48-no-padding-horizontal[disabled] {
    line-height: 46px; }
  .main-button-48-no-padding-horizontal.with-image-or-loading-left {
    padding: 0 !important; }
  .main-button-48-no-padding-horizontal.with-image-or-loading-right {
    padding: 0 !important; }

.hover-dark-layer {
  position: relative;
  overflow: hidden; }
  .hover-dark-layer:hover::after {
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0;
    content: "";
    background: red;
    width: 100%;
    height: 100%;
    background: rgba(25, 25, 25, 0.1);
    z-index: 1; }

.main-button-22 {
  padding: 0 24px !important;
  height: 22px;
  line-height: 22px;
  vertical-align: top;
  font-size: 12px !important;
  border-radius: 4px; }
  .main-button-22 svg {
    position: relative !important;
    top: -1.5px !important;
    margin-right: 6px !important;
    z-index: 2;
    cursor: pointer; }
    .main-button-22 svg.icon-right {
      margin-left: 6px;
      margin-right: 0; }
  .main-button-22 img {
    position: relative !important;
    top: -1.5px !important;
    width: 16px !important;
    margin-right: 6px !important;
    z-index: 2;
    cursor: pointer; }
    .main-button-22 img.icon-right {
      margin-left: 6px;
      margin-right: 0; }
  .main-button-22.width-100 {
    width: 100% !important; }
  .main-button-22.with-image-or-loading-left {
    padding: 0 32px 0 24px !important; }
  .main-button-22.with-image-or-loading-right {
    padding: 0 24px 0 32px !important; }
  .main-button-22 .lds-ring {
    width: 14px;
    height: 14px; }
    .main-button-22 .lds-ring div {
      width: 14px;
      height: 14px; }

.emoji-mart {
  font-family: PTRootUI !important; }
  .emoji-mart .emoji-mart-scroll {
    margin-top: 4px; }
  .emoji-mart .emoji-mart-search input {
    -webkit-transition: ease-in-out .1s;
    transition: ease-in-out .1s;
    font-size: 14px; }
    .emoji-mart .emoji-mart-search input:focus {
      border: solid 1px #ff7e00; }
  .emoji-mart .emoji-mart-search-icon {
    top: 4px;
    line-height: 1; }
    .emoji-mart .emoji-mart-search-icon svg {
      top: -2px !important;
      right: unset !important;
      position: relative !important; }
  .emoji-mart .emoji-mart-emoji span {
    cursor: pointer !important; }
  .emoji-mart button[disabled] {
    color: unset !important;
    border: unset !important;
    background: unset !important;
    cursor: default;
    box-shadow: unset !important; }
  .emoji-mart .emoji-mart-preview {
    display: none; }
  .emoji-mart .emoji-mart-bar .emoji-mart-anchor-icon svg {
    position: relative;
    top: unset;
    right: unset; }
  .emoji-mart .emoji-mart-bar .emoji-mart-anchor-bar {
    background-color: #ff7e00 !important; }
  .emoji-mart .emoji-mart-category-label {
    font-size: 14px; }
  .emoji-mart:hover {
    opacity: 1 !important;
    visibility: visible !important; }

#outer-container {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  /* background: linear-gradient(to bottom, #ffab3a, #ff7d00); */
  background: url(/static/media/bg-auth-v2.7a7d396d.svg) no-repeat center center fixed;
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
}

.bg-img-paper-plane-left {
  position: absolute;
  top: 43%;
  left: min(calc(6% - 128px), 0px);
  width: 28.5%;
  z-index: -1;
}

.bg-img-paper-plane-right {
  position: absolute;
  top: 19%;
  right: 0;
  width: 18.6%;
  z-index: -1;
}

.bg-img-whatsapp-sme {
  position: absolute;
  top: 12%;
  left: calc(37.5% - 225px);
  min-width: 112px;
  width: 9.4%;
  z-index: -1;
}

.bg-img-telegram {
  position: absolute;
  top: 28%;
  left: calc(10% - 50px);
  min-width: 96px;
  width: 8%;
  z-index: -1;
}

.bg-img-gb-profile {
  position: absolute;
  top: 74%;
  left: calc(11% - 50px);
  min-width: 116px;
  width: 9.7%;
  z-index: -1;
}

.bg-img-google {
  position: absolute;
  top: 86%;
  left: calc(26% - 100px);
  min-width: 88px;
  width: 7.4%;
  z-index: -1;
}

.bg-img-instagram {
  position: absolute;
  top: 7%;
  right: calc(34% - 200px);
  min-width: 88px;
  width: 7.5%;
  z-index: -1;
}

.bg-img-fb-messenger {
  position: absolute;
  top: 33%;
  right: calc(10% - 50px);
  min-width: 111px;
  width: 9.3%;
  z-index: -1;
}

.bg-img-mail {
  position: absolute;
  top: 48%;
  right: calc(32.5% - 150px);
  min-width: 88px;
  width: 7.4%;
  z-index: -1;
}

.bg-img-tokopedia {
  position: absolute;
  top: 65%;
  right: calc(10% - 50px);
  min-width: 96px;
  width: 8%;
  z-index: -1;
}

.bg-img-whatsapp-ba {
  position: absolute;
  top: 80%;
  right: calc(22.5% - 100px);
  min-width: 120px;
  width: 10%;
  z-index: -1;
}

.wrapper-auth-v2 {
  padding: 0 64px;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.wrapper-auth-v2::-webkit-scrollbar {
  display: none;
}

.logo-image {
  display: block;
  margin: auto;
  margin-top: 64px;
  height: 64px;
  text-align: center;
}

.subtitle {
  margin: 0;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 4px;
  text-align: center;
  color: white;
  text-align: center;
  margin: auto;
  text-transform: uppercase;
}

.wrapper-form-center {
  /* display: flex;
  align-items: center;
  justify-content: center; */
  /* vertical-align: middle; */
  /* position: absolute; */
  margin: auto;
  margin-top: 60px;
  margin-bottom: 64px;
  width: 100%;
  max-width: 520px;
  border-radius: 32px;
  background: #ffffff;
  box-shadow: 0px 3px 22px 0px #1919194D;
  /* text-align: center; */
}

.wrapper-full-left {
  position: fixed;
  top: 0;
  left: 0;
  border-top-right-radius: 40px;
  width: 540px;
  height: 100%;
  background: #ffffff;
  text-align: left;
}

.wrapper-full-right-image {
  position: fixed;
  right: 0;
  top: 0;
  width: calc(100% - 540px);
  height: 100vh;
}

.header-centered-content {
  text-align: center;
}

.content-wrapper {
  padding: 48px;
  height: 100%;
}

.content-title {
  display: block;
  font-size: 40px;
  font-weight: 700;
  line-height: 48px;
  letter-spacing: -0.6px;
  text-align: center;
  color: #191919;
}

.content-subtitle {
  margin-top: 16px;
  display: block;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.3px;
  text-align: center;
  color: #191919CC;
}

.fail-panel {
  width: 100%;
  background: #ffc0c0;
  border-radius: 8px;
  color: #ff3f57;
  font-size: 16px;
  letter-spacing: -0.5px;
  padding: 12px 15px 10px;
  margin-bottom: 24px;
  text-align: left;
}

.fail-panel img {
  position: relative;
  top: -2px;
  margin-right: 8px;
}

.input-wrapper {
  position: relative;
  margin-top: 32px;
}

.input-wrapper label {
  margin: 0;
  font-size: 14px;
  font-weight: bold;
  line-height: 21px;
  letter-spacing: -0.4px;
  color: #191919;
}

.input-wrapper input {
  display: block;
  margin: 8px 0 0;
  padding: 12px 16px;
  width: 100%;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.3px;
  color: #191919;
  border-radius: 8px;
  border: 1px solid #1919191A;
  box-sizing: border-box;
  box-shadow: 0 0 0 50px white inset;
}

/* .input-wrapper input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 50px white inset;
}

.input-wrapper input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0 50px white inset;
} */

.input-wrapper .fail-state-input {
  border: solid 1px #ff3f57 !important;
}

.input-wrapper input::-webkit-input-placeholder {
  color: #9b9b9b;
}

.input-wrapper input::-ms-input-placeholder {
  color: #9b9b9b;
}

.input-wrapper input::placeholder {
  color: #9b9b9b;
}

.input-wrapper input:focus {
  border: solid 1px var(--main-dark-orange);
  /* box-shadow: 0 0 8px 0 rgba(54, 42, 215, 0.24); */
}

.input-wrapper .input-error {
  margin: 6px 12px 0 0;
  font-size: 13px;
  /* font-style: italic; */
  letter-spacing: 0.5px;
  font-weight: 600;
  color: rgb(255, 0, 0, 0.7);
}

.input-warning {
  position: absolute;
  left: 0;
}

.input-wrapper .input-error-info {
  display: block;
  margin-top: 2px;
  width: 100%;
  color: #FF3F57;
}

.input-wrapper .input-error-info svg {
  margin-right: 4px;
  height: 16px;
}

.input-wrapper .input-error-info b {
  font-size: 12px;
  line-height: 18px;
  letter-spacing: -0.4px;
}

.input-wrapper-loading {
  position: relative;
  margin-top: 32px;
}

.input-wrapper-loading label {
  margin: 0;
  font-size: 14px;
  font-weight: bold;
  line-height: 21px;
  letter-spacing: -0.4px;
  color: #19191966;
}

.input-wrapper-loading input {
  display: block;
  margin: 8px 0 0;
  padding: 12px 16px;
  width: 100%;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.3px;
  color: #19191999;
  background: white;
  border-radius: 8px;
  border: none;
  box-sizing: border-box;
  box-shadow: 0 0 0 50px #E8E8E8 inset;
  -webkit-box-shadow: 0 0 0 50px #E8E8E8 inset;
}

.input-wrapper-loading .input-error-info {
  display: none !important;
}

/* .input-wrapper-loading input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 50px #E8E8E8 inset;
}

.input-wrapper-loading input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0 50px #E8E8E8 inset;
} */

.input-wrapper .peek-password {
  position: absolute;
  width: 48px;
  height: 48px;
  color: #191919;
  top: 30px;
  right: 4px;
  line-height: 48px;
  font-size: 24px;
  text-align: center;
  cursor: pointer;
}

.input-wrapper .peek-password svg {
  -webkit-transform: translateY(-2px);
          transform: translateY(-2px);
}

.input-wrapper-loading .peek-password {
  position: absolute;
  width: 48px;
  height: 48px;
  color: #191919;
  top: 30px;
  right: 4px;
  line-height: 48px;
  font-size: 24px;
  text-align: center;
  cursor: pointer;
  opacity: 0.6;
}

.input-wrapper .peek-password svg {
  -webkit-transform: translateY(-2px);
          transform: translateY(-2px);
}

input:focus + .peek-password {
  color: #ff7e00;
}

.content-wrapper form button {
  margin-top: 32px;
}

.login-button {
  width: 100%;
  margin-top: 10px;
  /* padding: 10px 0 12px; */
  border-radius: 8px;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  border: solid 1px #ff7e00;
  background: -webkit-gradient(linear, left top, left bottom, from(#ff9833), to(#ff7e00));
  background: linear-gradient(to bottom, #ff9833, #ff7e00 100%);
  cursor: pointer;
  position: relative;
  /* height: 48px; */
}

.login-button .lds-ring {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.login-button .lds-ring div {
  border-color: #ffffff transparent transparent transparent;
  top: 0;
  left: 0;
  margin: 0;
}

.login-button:hover {
  background: -webkit-gradient(linear, left top, left bottom, from(#ff9833), to(#ef7600));
  background: linear-gradient(to bottom, #ff9833, #ef7600 100%);
}

.login-button:disabled {
  /* background: linear-gradient(to bottom, #cecece, #9b9b9b 100%); */
  cursor: not-allowed;
  border: solid 1px #cecece;
}

.forgot-password {
  display: block;
  margin-top: 32px;
  width: 100%;
  text-align: center;
}

.forgot-password-loading {
  display: block;
  margin-top: 32px;
  width: 100%;
  text-align: center;
}

.forgot-password a {
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.5px;
  color: #ff7e00;
}

.forgot-password-loading a {
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.5px;
  color: #19191999;
}

.forgot-password a:hover {
  color: #ff7e00;
}

#signup-here {
  margin-top: 8px;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.5px;
  text-align: center;
  color: #19191966;
}

#sign-in {
  margin-top: 32px;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.5px;
  text-align: center;
  color: #19191966;
}

#back-to-log-in {
  width: 100%;
  margin-top: 24px;
  font-size: 16px;
  font-weight: bold;
  line-height: 24px;
  letter-spacing: -0.5px;
  color: #FF7E00;
  text-align: center;
}

#back-to-log-in-loading {
  width: 100%;
  margin-top: 24px;
  font-size: 16px;
  font-weight: bold;
  line-height: 24px;
  letter-spacing: -0.5px;
  color: #19191966;
  text-align: center;
}

@media screen and (min-width: 300px) and (max-width: 550px) {
  .wrapper-full-left {
    width: 100%;
  }

  .wrapper-full-left .content-wrapper {
    padding: 32px 28px;
  }

  .wrapper-full-left .content-wrapper .content-title {
    font-size: 30px;
    margin: 21px 0;
  }

  .wrapper-full-left .content-wrapper .input-wrapper label,
  span {
    font-size: 15px;
  }

  .wrapper-full-left .content-wrapper .login-button {
    margin: 16px 0px !important;
    padding: 11px 0 12px;
    font-size: 15px;
  }

  .wrapper-full-left .content-wrapper .input-wrapper input[type="text"],
  input[type="password"] {
    padding: 9px 11px 11px;
    font-size: 14px;
  }

  .wrapper-full-left .content-wrapper #signup-here {
    font-size: 15px;
  }
}

.login-image img {
    width: 85%;
}

.modal-resend-email .modal-content {
    padding: 48px 48px 34px;
    border-radius: 32px;
    max-width: 540px;
    width: calc(100vw - 120px);
}

.modal-resend-email .modal-body {
    padding: 0;
}

.modal-resend-email .modal-body p {
    font-size: 16px;
    font-weight: normal;
    color: #191919;
    letter-spacing: -0.3px;
    text-align: center;
}

.modal-resend-email .modal-body svg {
    font-size: 26px;
    position: relative;
    top: -2px;
}

.modal-resend-email .modal-body p:nth-child(1) {
    font-size: 24px;
    font-weight: bold;
    letter-spacing: -0.4px;
    color: #9b9b9b;
}

.modal-resend-email .modal-footer {
    padding: 0 0 10px;
    border: none;
}

.modal-resend-email .modal-footer button {
    width: 100%;
    position: relative;
}

.modal-email-sent .modal-body p:nth-child(1) {
    color: #7ec82a;
}

.modal-email-sent img {
    width: 30px;
    position: relative;
    top: -2px;
}

.modal-resend-email .lds-ring div {
    border-color: #ffffff transparent transparent transparent;
}

.input-wrapper .password-title-wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.input-wrapper .password-title-wrapper .forgot-password {
    color: #ff7d00;
    cursor: pointer;
}
.custom-toastr {
    position: absolute;
    width: 250px;
    padding: 10px;
    border-radius: 5px;
    color: #191919;
    top: 15px;
    right: 15px;
    -webkit-transition: ease-in-out 0.4s;
    transition: ease-in-out 0.4s;
    opacity: 0;
    z-index: 1;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1), 0 20px 20px 0 rgba(0, 0, 0, 0.18);
    background: #ffffff;
    font-weight: bold;
    padding: 10px 10px 10px 35px;
}

.custom-toastr.active-toast {
    opacity: 1;
}

.custom-toastr.toast-fail {
    border-left: solid 12px #FE3D58;
}

.custom-toastr.toast-success {
    border-left: solid 12px #7EC82A;
}

.custom-toastr.toast-process {
    border-left: solid 12px #9A9B9C;
}

.custom-toastr .icon-toast-wrapper {
    width: 25px;
    height: 5px;
    display: inline-block;
    vertical-align: top;
    position: absolute;
    top: 8px;
    left: 10px;
}

.custom-toastr .icon-toast-wrapper svg {
    font-size: 25px;
}

.custom-toastr .icon-toast-wrapper img {
    width: 25px;
    position: relative;
}

.custom-toastr .text-toast-wrapper {
    width: calc(100% - 30px);
    display: inline-block;
    vertical-align: top;
    padding-left: 10px;
    font-size: 16px;
}

.custom-toastr .icon-toast-wrapper .lds-ring {
    display: inline-block;
    position: relative;
    width: 100%;
    height: 25px;
    top: 0;
    right: 0;
}

.custom-toastr .icon-toast-wrapper .lds-ring div {
    border-color: #9A9B9C transparent transparent transparent;
    top: -2px;
    left: 0;
}

.main-reuseable-button {
  white-space: nowrap; }
  .main-reuseable-button b {
    position: relative; }
    .main-reuseable-button b.top-0 {
      top: 0; }
  .main-reuseable-button .button-text-wrapper {
    height: 100%;
    position: relative;
    display: inline-block; }
    .main-reuseable-button .button-text-wrapper span {
      position: relative;
      z-index: 2;
      cursor: pointer;
      font-weight: normal !important; }
    .main-reuseable-button .button-text-wrapper p {
      font-family: PTRootBold;
      margin: 0 !important;
      padding: 0 !important;
      height: 100% !important;
      display: inline-block !important; }
  .main-reuseable-button .LoadingRoll_1234fed {
    display: inline-block;
    line-height: 1;
    vertical-align: top;
    padding: 0;
    height: 100%;
    width: 18px; }
    .main-reuseable-button .LoadingRoll_1234fed .lds-ring {
      display: inline-block;
      position: relative;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%);
      right: unset;
      z-index: 2;
      cursor: pointer; }
  .main-reuseable-button .loading-left {
    margin-right: 7px;
    display: inline-block;
    height: 100%;
    width: 18px;
    position: relative; }
  .main-reuseable-button .loading-right {
    display: inline-block;
    margin-left: 7px;
    height: 100%;
    width: 18px;
    position: relative; }

.LoadingRoll_1234fed {
  position: relative;
  display: block;
  margin: 0 auto;
  text-align: center;
  font-size: 0;
  color: transparent;
  height: 40px;
  padding: 0 10px;
  max-width: 100%;
  max-height: 100%;
}

.LoadingRoll_1234fed img {
  max-width: 100%;
  max-height: 100%;
}

.white-lds-ring div {
  border-color: #ffffff transparent transparent transparent !important;
}

.gray-lds-ring div {
  border-color: #959595 transparent transparent transparent !important;
}


.register-image img {
  width: 100%; }

.div50 {
  display: inline-block;
  width: 50%;
  position: relative;
  vertical-align: top; }

.div50 div {
  letter-spacing: -0.2px;
  color: #191919;
  font-size: 14px;
  height: 28px;
  line-height: 28px;
  position: relative;
  padding-left: 22px; }

.div50 div svg {
  position: absolute;
  top: 50%;
  left: 0;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%); }

.disabled-retype-password:disabled {
  color: rgba(25, 25, 25, 0.2) !important;
  border: none !important;
  background: rgba(25, 25, 25, 0.1) !important;
  cursor: not-allowed; }

.register-outer-wrapper .phone-number-form {
  margin: 8px 0 0;
  width: 100%;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.3px;
  color: #191919;
  border-radius: 8px;
  border: 1px solid #1919191A;
  box-sizing: border-box;
  box-shadow: 0 0 0 50px white inset; }
  .register-outer-wrapper .phone-number-form .dropdown-menu {
    border: 1px solid rgba(0, 0, 0, 0.15);
    -webkit-transform: translate3d(0px, 50px, 0px) !important;
            transform: translate3d(0px, 50px, 0px) !important; }
  .register-outer-wrapper .phone-number-form input[type="text"] {
    width: 100%;
    padding: 12px 16px;
    border-radius: 8px;
    box-shadow: none;
    outline: none; }
    .register-outer-wrapper .phone-number-form input[type="text"].error-input-message {
      border-color: #ff3f57; }
    .register-outer-wrapper .phone-number-form input[type="text"]:focus {
      border-color: #ff7d00; }
  .register-outer-wrapper .phone-number-form .area-code-wrapper {
    position: relative;
    display: inline-block;
    min-width: 0;
    margin-right: 4px; }
    .register-outer-wrapper .phone-number-form .area-code-wrapper .dropdown .value-country-code-wrapper {
      width: 100%;
      text-align: left;
      outline: none !important;
      border-radius: 8px;
      background: none;
      border: none;
      color: #191919;
      box-shadow: none !important;
      position: relative;
      padding: 11px 0 11px 12px;
      margin: 0; }
      .register-outer-wrapper .phone-number-form .area-code-wrapper .dropdown .value-country-code-wrapper .country-flag {
        width: 32px;
        height: 24px;
        display: inline-block;
        position: relative;
        margin-right: 4px;
        top: -1px;
        border-radius: 2px; }
      .register-outer-wrapper .phone-number-form .area-code-wrapper .dropdown .value-country-code-wrapper svg {
        display: inline-block;
        -webkit-transition: ease-in-out .1s;
        transition: ease-in-out .1s;
        margin-right: 8px; }
        .register-outer-wrapper .phone-number-form .area-code-wrapper .dropdown .value-country-code-wrapper svg.chevron-up {
          -webkit-transform: rotate(180deg);
                  transform: rotate(180deg); }
      .register-outer-wrapper .phone-number-form .area-code-wrapper .dropdown .value-country-code-wrapper p {
        display: inline-block;
        margin: 0;
        -webkit-transform: translateY(1px);
                transform: translateY(1px); }
      .register-outer-wrapper .phone-number-form .area-code-wrapper .dropdown .value-country-code-wrapper.loading {
        color: #19191999; }
    .register-outer-wrapper .phone-number-form .area-code-wrapper .dropdown .dropdown-menu {
      padding: 0;
      border-radius: 8px; }
      .register-outer-wrapper .phone-number-form .area-code-wrapper .dropdown .dropdown-menu .country-search-wrapper {
        position: relative;
        width: 100%; }
        .register-outer-wrapper .phone-number-form .area-code-wrapper .dropdown .dropdown-menu .country-search-wrapper input {
          margin: 0;
          border: none !important;
          height: 37px;
          padding: 12px 12px 12px 36px !important;
          font-size: 14px;
          letter-spacing: -0.2px;
          color: #191919;
          border-bottom: solid 1px #dcdcdc !important;
          border-radius: 8px 8px 0 0 !important; }
        .register-outer-wrapper .phone-number-form .area-code-wrapper .dropdown .dropdown-menu .country-search-wrapper svg {
          position: relative;
          color: #ff7d00;
          position: absolute;
          left: 12px;
          top: 11px; }
      .register-outer-wrapper .phone-number-form .area-code-wrapper .dropdown .dropdown-menu .country-list-select {
        width: 320px;
        min-height: 37px;
        max-height: 222px;
        overflow: auto; }
        .register-outer-wrapper .phone-number-form .area-code-wrapper .dropdown .dropdown-menu .country-list-select .custom-phone-code-picker {
          padding: 0 12px;
          height: 37px;
          line-height: 37px;
          border-bottom: solid 1px #dcdcdc;
          cursor: pointer; }
          .register-outer-wrapper .phone-number-form .area-code-wrapper .dropdown .dropdown-menu .country-list-select .custom-phone-code-picker:nth-last-child(1) {
            border-bottom: none; }
          .register-outer-wrapper .phone-number-form .area-code-wrapper .dropdown .dropdown-menu .country-list-select .custom-phone-code-picker img {
            width: 21px;
            height: 16px;
            display: inline-block;
            margin-right: 6px;
            position: relative;
            top: -2px; }
          .register-outer-wrapper .phone-number-form .area-code-wrapper .dropdown .dropdown-menu .country-list-select .custom-phone-code-picker svg {
            margin-right: 6px;
            position: relative;
            color: #ff7d00;
            font-size: 18px;
            top: -1px; }
          .register-outer-wrapper .phone-number-form .area-code-wrapper .dropdown .dropdown-menu .country-list-select .custom-phone-code-picker div {
            display: inline-block;
            font-size: 14px;
            letter-spacing: -0.2px;
            line-height: 1;
            position: relative;
            vertical-align: top;
            top: 11px; }
            .register-outer-wrapper .phone-number-form .area-code-wrapper .dropdown .dropdown-menu .country-list-select .custom-phone-code-picker div .country-name {
              top: 0 !important; }
          .register-outer-wrapper .phone-number-form .area-code-wrapper .dropdown .dropdown-menu .country-list-select .custom-phone-code-picker:hover {
            color: #ff7d00; }
          .register-outer-wrapper .phone-number-form .area-code-wrapper .dropdown .dropdown-menu .country-list-select .custom-phone-code-picker.active-code-number {
            color: #ff7d00; }
        .register-outer-wrapper .phone-number-form .area-code-wrapper .dropdown .dropdown-menu .country-list-select.no-option {
          text-align: center;
          border-top: solid 1px #dcdcdc;
          color: grey;
          padding-top: 5px; }
    .register-outer-wrapper .phone-number-form .area-code-wrapper input[type="text"] {
      padding: 12px 16px 12px 52px; }
    .register-outer-wrapper .phone-number-form .area-code-wrapper .country-flag-wrapper {
      padding: 8px;
      border: solid 1px rgba(25, 25, 25, 0.1);
      display: inline-block;
      height: 24px;
      width: 32px;
      position: absolute;
      left: 16px;
      top: 50%;
      -webkit-transform: translate(0, -50%);
              transform: translate(0, -50%); }
      .register-outer-wrapper .phone-number-form .area-code-wrapper .country-flag-wrapper svg {
        position: absolute;
        left: 50%;
        top: 50%;
        -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
        color: rgba(25, 25, 25, 0.4); }
  .register-outer-wrapper .phone-number-form.phone-number-disabled input[type="text"] {
    background: rgba(25, 25, 25, 0.1);
    border: none;
    cursor: not-allowed; }
  .register-outer-wrapper .phone-number-form .phone-wrapper {
    width: calc(100% - 150px);
    display: inline-block;
    vertical-align: top;
    position: relative; }
    .register-outer-wrapper .phone-number-form .phone-wrapper input {
      border: none;
      padding: 12px 16px 12px 0; }
    .register-outer-wrapper .phone-number-form .phone-wrapper p {
      color: #ff3f57;
      font-size: 12px;
      letter-spacing: -0.4px;
      margin: 4px 0 0;
      width: 100%;
      position: relative;
      padding: 0 0 0 20px; }
      .register-outer-wrapper .phone-number-form .phone-wrapper p svg {
        margin-right: 4px;
        position: absolute;
        left: 0;
        top: 1px;
        font-size: 16px; }
    .register-outer-wrapper .phone-number-form .phone-wrapper input[type="text"] {
      width: 100%;
      display: inline-block;
      margin-top: 0; }
    .register-outer-wrapper .phone-number-form .phone-wrapper svg.remove-number {
      position: absolute;
      right: 0;
      top: 14px;
      font-size: 24px;
      margin-left: 6px;
      color: #ff3f57;
      cursor: pointer; }
      .register-outer-wrapper .phone-number-form .phone-wrapper svg.remove-number:hover {
        color: #e83b51; }

.register-outer-wrapper .loading {
  color: #19191999;
  background: white;
  border-radius: 8px;
  border: none;
  box-sizing: border-box;
  box-shadow: 0 0 0 50px #E8E8E8 inset;
  -webkit-box-shadow: 0 0 0 50px #E8E8E8 inset; }

@media screen and (max-width: 415px) {
  .password-strength .div50 {
    width: 100%; } }

.modal-creating-account .modal-content {
  border-radius: 32px;
  width: 520px; }
  .modal-creating-account .modal-content .modal-body {
    padding: 48px;
    text-align: center;
    border-radius: 32px; }
    .modal-creating-account .modal-content .modal-body .lds-ring {
      width: 88px;
      height: 88px; }
      .modal-creating-account .modal-content .modal-body .lds-ring div {
        width: 88px;
        height: 88px;
        margin: 0;
        border: solid 9px;
        border-color: #ff7d00 transparent transparent transparent; }
    .modal-creating-account .modal-content .modal-body .modal-title-text {
      font-size: 24px;
      letter-spacing: -0.6px;
      margin: 10px 0; }
    .modal-creating-account .modal-content .modal-body .modal-desc-text {
      font-size: 16px;
      letter-spacing: -0.3px;
      margin: 0; }

.modal-phone-code .modal-body {
  height: 100%;
  width: 100%;
  position: fixed;
  background: transparent;
  padding: 0; }

.modal-phone-code .modal-content {
  width: 480px;
  height: calc(100% - 240px);
  border-radius: 16px;
  overflow: hidden; }
  .modal-phone-code .modal-content .modal-phone-code-header {
    width: 100%;
    text-align: center;
    padding: 16px 0 16px;
    position: relative;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: -0.6px; }
    .modal-phone-code .modal-content .modal-phone-code-header svg {
      color: #ff7e00;
      cursor: pointer;
      font-size: 32px;
      padding: 4px;
      position: absolute;
      right: 12px;
      top: 11px; }
      .modal-phone-code .modal-content .modal-phone-code-header svg:hover {
        color: #E87200; }
  .modal-phone-code .modal-content .modal-phone-search {
    padding: 8px 16px; }
    .modal-phone-code .modal-content .modal-phone-search input {
      display: block;
      padding: 8px 8px 8px 32px;
      width: 100%;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: -0.3px;
      color: #191919;
      border-radius: 8px;
      border: 1px solid #1919191A;
      box-sizing: border-box;
      box-shadow: 0 0 0 50px white inset; }
      .modal-phone-code .modal-content .modal-phone-search input:focus {
        border: solid 1px var(--main-dark-orange); }
    .modal-phone-code .modal-content .modal-phone-search svg {
      position: absolute;
      width: 16px;
      height: 16px;
      left: 24px;
      top: 78px;
      bottom: 0;
      color: #191919; }
    .modal-phone-code .modal-content .modal-phone-search input:focus + svg {
      color: var(--main-dark-orange) !important; }
  .modal-phone-code .modal-content .modal-phone-code-body {
    overflow: auto;
    width: 100%;
    max-height: calc(100% - 106px); }
    .modal-phone-code .modal-content .modal-phone-code-body .alphabet-wrapper {
      padding: 5px 16px;
      font-size: 12px;
      line-height: 18px;
      letter-spacing: 2.5px;
      font-weight: 500;
      color: #9B9B9B;
      background: #1919190D; }
    .modal-phone-code .modal-content .modal-phone-code-body .country-wrapper {
      height: 44px;
      padding: 13.5px 16px 10px 60px;
      line-height: 1;
      position: relative;
      font-size: 16px;
      letter-spacing: -0.3px;
      cursor: pointer;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 100%;
      color: #191919; }
      .modal-phone-code .modal-content .modal-phone-code-body .country-wrapper img {
        position: absolute;
        top: 12px;
        left: 16px;
        width: 28px;
        border-radius: 2px; }
      .modal-phone-code .modal-content .modal-phone-code-body .country-wrapper:hover {
        color: white;
        background: #ff7e00; }
  @media screen and (min-width: 300px) and (max-width: 767px) {
    .modal-phone-code .modal-content {
      width: 100%;
      height: 100%; } }

#verification-wrapper {
    position: fixed;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(calc(-50% - 60px), calc(-50% - 60px));
            transform: translate(calc(-50% - 60px), calc(-50% - 60px));
    padding: 48px;
    margin: 60px;
    width: calc(100% - 120px);
    max-width: 540px;
    background: #ffffff;
    border-radius: 32px;
    box-shadow: 0px 3px 22px 0px #1919194D;
    text-align: center;
}

#verification-wrapper img {
    width: 154px;
    display: inline-block;
}

#verification-wrapper .verification-status {
    padding: 9.5px;
    margin: auto;
    width: 88px;
    height: 88px;
    background: #11830033;
    border-radius: 44px;
}

#verification-wrapper .verification-status svg {
    padding: 12.5px;
    width: 69px;
    height: 69px;
    background: #118300;
    color: #cfe6cc;
    border-radius: 35px;
}

#verification-wrapper .verification-title {
    margin: 10px 0 0;
    font-size: 24px;
    line-height: 30px;
    letter-spacing: -0.6px;
    text-align: center;
    color: #191919;
}

#verification-wrapper .verification-description {
    margin: 10px 0 0;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.3px;
    text-align: center;
    color: #191919;
}

/* @media screen and (max-width: 600px) {
    #verification-wrapper {
        width: 100%;
        border-radius: 0;
        padding: 40px 28px;
    }

    #verification-wrapper .verification-status {
        font-size: 20px !important;
    }

    #verification-wrapper .verification-status svg {
        top: -2px !important;
        width: 21px;
    }

    #verification-wrapper .login-button {
        padding: 11px 0;
        font-size: 15px;
    }
} */

#verification-wrapper .resend-email-wrapper {
    margin: 32px 0;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.5px;
    text-align: center;
    color: #19191999;
    margin-top: 15px;
}

#verification-wrapper .resend-email-wrapper .resend-button {
    margin: 0 0 0 4px;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.5px;
    color: var(--main-dark-orange);
    font-weight: bold;
    -webkit-transition: ease-in-out 0.1s;
    transition: ease-in-out 0.1s;
    cursor: pointer;
}

#verification-wrapper .resend-email-wrapper .resend-button:hover {
    -webkit-transform: scale(0.975);
            transform: scale(0.975);
}

#verification-wrapper .resend-email-wrapper .timer-resend-button {
    margin: 0 0 0 4px;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.5px;
    color: #19191999;
    font-weight: bold;
}

#verification-wrapper .resend-email-wrapper .lds-ring div {
    width: 12px;
    height: 12px;
    margin: 10px 0 0 4px;
}

#verify-email-wrapper {
    position: fixed;
    padding: 48px;
    margin: 60px;
    width: calc(100% - 120px);
    max-width: 540px;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(calc(-50% - 60px), calc(-50% - 60px));
            transform: translate(calc(-50% - 60px), calc(-50% - 60px));
    background: #ffffff;
    border-radius: 32px;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1), 0 20px 20px 0 rgba(0, 0, 0, 0.18);
    text-align: center;
    box-sizing: border-box;
}

#verify-email-wrapper img {
    width: 154px;
    display: inline-block;
}

#verify-email-wrapper p {
    margin: 10px 0 0;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.3px;
    text-align: center;
    color: #191919;
}

#verify-email-wrapper a {
    color: #FF7E00;
}

#verify-email-wrapper .verification-status-title {
    margin: 10px 0 0;
    font-size: 24px;
    font-weight: bold;
    line-height: 30px;
    letter-spacing: -0.6px;
    text-align: center;
    color: #191919;
}

#verify-email-wrapper .verification-status-failed {
    padding: 9.5px;
    margin: auto;
    width: 88px;
    height: 88px;
    background: #FF3F5733;
    border-radius: 44px;
}

#verify-email-wrapper .verification-status-failed svg {
    padding: 12.5px;
    width: 69px;
    height: 69px;
    background: #FF3F57;
    color: white;
    border-radius: 35px;
}

#verify-email-wrapper .verification-status-success {
    padding: 9.5px;
    margin: auto;
    width: 88px;
    height: 88px;
    background: #11830033;
    border-radius: 44px;
}

#verify-email-wrapper .verification-status-success svg {
    padding: 12.5px;
    width: 69px;
    height: 69px;
    background: #118300;
    color: #cfe6cc;
    border-radius: 35px;
}

#verify-email-wrapper .lds-ring {
    width: 88px;
    height: 88px;
}

#verify-email-wrapper .lds-ring div {
    width: 72px;
    height: 72px;
    border-width: 8px;
    margin: 8px;
}

.modal-reset-password-email-sent img {
  width: 30px;
  position: relative;
  top: -2px; }

.modal-reset-password-email-sent .modal-content {
  padding: 30px 40px;
  border-radius: 8px;
  width: 540px; }

.modal-reset-password-email-sent .modal-body {
  padding: 0; }
  .modal-reset-password-email-sent .modal-body p {
    font-size: 16px;
    font-weight: normal;
    color: #191919;
    letter-spacing: -0.3px;
    text-align: center; }
  .modal-reset-password-email-sent .modal-body svg {
    font-size: 26px;
    position: relative;
    top: -2px; }
  .modal-reset-password-email-sent .modal-body p:nth-child(1) {
    font-size: 24px;
    font-weight: bold;
    letter-spacing: -0.4px;
    color: #52d540; }
    .modal-reset-password-email-sent .modal-body p:nth-child(1) svg {
      margin-right: 8px; }

.modal-reset-password-email-sent .modal-footer {
  padding: 0 0 10px;
  border: none; }
  .modal-reset-password-email-sent .modal-footer button {
    width: 100%;
    position: relative;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center; }
    .modal-reset-password-email-sent .modal-footer button svg {
      margin-right: 4px;
      font-size: 20px; }
    .modal-reset-password-email-sent .modal-footer button b {
      font-size: 16px; }

.modal-reset-password-email-sent .lds-ring div {
  border-color: #ffffff transparent transparent transparent; }

.loading-sent-email-button {
  width: 100%;
  margin-top: 10px;
  padding: 10px 0 12px;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  border: solid 1px #ff7e00;
  background: -webkit-gradient(linear, left top, left bottom, from(#ff9833), to(#ff7e00));
  background: linear-gradient(to bottom, #ff9833, #ff7e00 100%);
  cursor: pointer;
  position: relative;
  height: 48px; }

.loading-sent-email-button .lds-ring {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%); }

.loading-sent-email-button .lds-ring div {
  border-color: #ffffff transparent transparent transparent;
  top: 0;
  left: 0;
  margin: 0; }

.loading-sent-email-button:hover {
  background: -webkit-gradient(linear, left top, left bottom, from(#ff9833), to(#ef7600));
  background: linear-gradient(to bottom, #ff9833, #ef7600 100%); }

.loading-sent-email-button:disabled {
  /* background: linear-gradient(to bottom, #cecece, #9b9b9b 100%); */
  cursor: not-allowed;
  border: solid 1px #cecece; }

.reset-password-image img {
  width: 85%; }

.div50 {
  display: inline-block;
  width: 50%;
  position: relative;
  vertical-align: top; }

.div50 div {
  letter-spacing: -0.2px;
  color: #191919;
  font-size: 14px;
  height: 28px;
  line-height: 28px;
  position: relative;
  padding-left: 22px; }

.div50 div svg {
  position: absolute;
  top: 50%;
  left: 0;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%); }

.disabled-retype-password:disabled {
  color: rgba(25, 25, 25, 0.2) !important;
  border: none !important;
  background: rgba(25, 25, 25, 0.1) !important;
  cursor: not-allowed; }

.input-wrapper .popup-input-password-wrapper {
  position: relative;
  /* border: solid 1px #dcdcdc; */
  border-radius: 8px; }

.input-wrapper .popup-input-password-wrapper input {
  padding: 12px 50px 14px 15px;
  width: 100%;
  display: inline-block;
  border: none;
  border-radius: 8px;
  letter-spacing: -0.2px;
  font-size: 16px;
  color: #191919;
  border: solid 1px #dcdcdc; }

.input-wrapper .popup-input-password-wrapper .peek-password {
  position: absolute;
  width: 50px;
  color: #191919;
  text-align: center;
  top: 12px;
  right: 1px;
  height: 41px;
  line-height: 40px;
  font-size: 20px;
  /* border-left: solid 1px #dcdcdc; */
  cursor: pointer;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px; }

.input-wrapper .popup-input-password-wrapper .peek-password.active-eye {
  color: #ff7d00; }

.input-wrapper .popup-input-password-wrapper .peek-password.disable-seek-password {
  cursor: not-allowed; }

.input-wrapper .popup-input-password-wrapper input:focus {
  border: solid 1px #ff7d00; }

@media screen and (max-width: 415px) {
  .password-strength .div50 {
    width: 100%; } }

.popup-modal-change-password {
  text-align: center; }

.popup-modal-change-password .popup-modal-change-password-button {
  margin: 0; }

.popup-modal-change-password .modal-content {
  width: 540px; }

.popup-modal-change-password .modal-body {
  border-radius: 8px; }

.popup-modal-change-password .modal-body {
  padding: 35px 48px 32px; }

.popup-modal-change-password-title {
  font-size: 24px;
  letter-spacing: -0.6px;
  color: #52d540;
  margin: 0; }

.popup-modal-change-password-title img {
  position: relative;
  top: -2px;
  margin-right: 8px; }

.popup-modal-change-password-description {
  font-size: 16px;
  letter-spacing: -0.3px;
  margin: 8px 0 24px; }

.loading-wrapper-password {
  position: relative;
  top: calc(50% - 152px);
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 540px;
  padding: 48px;
  border-radius: 32px;
  box-shadow: 0px 3px 22px 0px #1919194D;
  background-color: #ffffff;
  font-size: 24px;
  font-weight: bold;
  line-height: 30px;
  letter-spacing: -0.6px;
  text-align: center;
  color: #191919; }
  .loading-wrapper-password .auth-lds-ring {
    display: block;
    position: relative;
    width: 88px;
    height: 88px;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    margin-bottom: 10px; }
    .loading-wrapper-password .auth-lds-ring div {
      width: 72px;
      height: 72px;
      border-width: 8px;
      top: 2px;
      right: 2px; }

.failed-wrapper {
  position: relative;
  top: calc(50% - 152px);
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 424px;
  padding: 48px;
  border-radius: 32px;
  box-shadow: 0px 3px 22px 0px #1919194D;
  background-color: #ffffff;
  align-items: center; }
  .failed-wrapper .reset-password-failed {
    padding: 9.5px;
    margin: auto;
    width: 88px;
    height: 88px;
    background: #FF3F5733;
    border-radius: 44px; }
  .failed-wrapper .reset-password-failed svg {
    padding: 12.5px;
    width: 69px;
    height: 69px;
    background: #FF3F57;
    color: white;
    border-radius: 35px; }
  .failed-wrapper .reset-password-success {
    padding: 9.5px;
    margin: auto;
    width: 88px;
    height: 88px;
    background: #11830033;
    border-radius: 44px; }
  .failed-wrapper .reset-password-success svg {
    padding: 12.5px;
    width: 69px;
    height: 69px;
    background: #118300;
    color: #cfe6cc;
    border-radius: 35px; }
  .failed-wrapper .message-wrapper {
    display: block;
    text-align: center;
    margin: 10px 0 0;
    width: 100%; }
    .failed-wrapper .message-wrapper b {
      font-size: 24px;
      line-height: 30px;
      letter-spacing: -0.6px;
      color: #191919;
      text-align: center; }
    .failed-wrapper .message-wrapper p {
      margin: 10px 0 0;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: -0.3px;
      color: #191919;
      text-align: center; }
  .failed-wrapper .forgot-password {
    display: block;
    margin-top: 12px;
    width: 100%;
    text-align: center;
    font-size: 16px;
    font-weight: bold;
    line-height: 24px;
    letter-spacing: -0.5px;
    color: #ff7e00; }
    .failed-wrapper .forgot-password svg {
      margin-left: 4px;
      -webkit-transform: translateY(-2px);
              transform: translateY(-2px); }

#page-account-security {
    margin-top: 24px;
    font-size: 24px;
    font-weight: bold;
    letter-spacing: -0.4px;
    color: #191919;
    /* border-bottom: solid 1px #dcdcdc; */
    /* padding-bottom: 30px; */
    position: relative;
}

#page-account-security #instruction-text {
    font-size: 14px;
    letter-spacing: -0.2px;
    color: #9b9b9b;
    font-weight: normal;
    margin-bottom: 0;
}

#page-account-security .security-input {
    position: relative;
    margin-top: 30px;
    /* padding-bottom: 30px; */
    /* border-bottom: solid 1px #dcdcdc; */
}

#page-account-security .security-input label {
    display: block;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: -0.4px;
    color: #191919;
}

#page-account-security .security-input span {
    font-size: 14px;
    letter-spacing: -0.2px;
    color: #191919;
    font-weight: 100;
    display: block;
    margin-bottom: 25px;
    margin-top: 3px;
}


#page-account-security .security-input input {
    margin-top: 1px;
    padding: 15px;
    border: solid 1px #dcdcdc;
    font-size: 16px;
    letter-spacing: -0.2px;
    border-radius: 8px;
    width: 310px;
}

#page-account-security .security-input input:focus {
    border: solid 1px #ff7d00;
}


#page-account-security #change-password-button {
    width: 216px;
    display: block;
    padding: 12px;
    font-size: 16px;
}

#page-account-security button {
    font-size: 16px;
}

#page-account-security .security-input .custom-switch-wrapper {
    display: inline-block;
    position: absolute;
    right: 0;
    bottom: 45px;
    margin: 0;
}

#page-account-security .security-input .custom-switch-wrapper input {
    width: 0;
}

#page-account-security .popup-input-password-wrapper {
    position: relative;
    /* border: solid 1px #dcdcdc; */
    border-radius: 8px;
    width: 310px;
}

#page-account-security .popup-input-password-wrapper input {
    padding: 12px 50px 14px 15px;
    width: 100%;
    display: inline-block;
    border: none;
    border-radius: 8px;
    letter-spacing: -0.2px;
    font-size: 16px;
    color: #191919;
    border: solid 1px #dcdcdc;
}

#page-account-security .popup-input-password-wrapper .peek-password {
    position: absolute;
    width: 50px;
    color: #191919;
    text-align: center;
    top: 6px;
    right: 1px;
    height: 41px;
    line-height: 40px;
    font-size: 20px;
    /* border-left: solid 1px #dcdcdc; */
    cursor: pointer;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    background: #ffffff;
    background: transparent;
}

#page-account-security .popup-input-password-wrapper input:focus + .peek-password {
    color: #ff7d00;
}

#page-account-security .popup-reinput-password-wrapper {
    margin-bottom: 26px;
}

#page-account-security .popup-reinput-password-wrapper .input-warning {
    font-weight: normal;
}

#page-account-security .password-strength {
    font-weight: normal;
    padding: 16px 0 24px;
}

#page-account-security .password-strength .div50 {
    width: 210px;
} 

.popup-password .modal-content {
    width: 552px;
    padding: 0 40px;
}

.popup-password .modal-body {
    padding: 40px 0;
}

.popup-password p {
    margin: 0;
}

.popup-password p svg {
    color: #ff7d00;
    position: relative;
    top: -2px;
    font-size: 16px;
}

.popup-password .modal-body label {
    font-size: 16px;
    font-weight: bold;
    letter-spacing: -0.4px;
    color: #191919;
}

.popup-password p:nth-child(1) {
    font-size: 24px;
    font-weight: bold;
    letter-spacing: -0.4px;
    color: #191919;
}

.popup-password p:nth-child(2) {
    font-size: 13px;
    letter-spacing: -0.2px;
    color: #9b9b9b;
    margin-bottom: 30px;
}

.popup-password .modal-body .popup-input-password-wrapper {
    position: relative;
    /* border: solid 1px #dcdcdc; */
    border-radius: 8px;
    width: 100%;
    margin-bottom: 30px;
}

.popup-password .modal-body .popup-input-password-wrapper input {
    padding: 12px 45px 14px 15px;
    /* width: calc(100% - 50px); */
    width: 100%;
    border: solid 1px #dcdcdc;
    display: inline-block;
    border-radius: 8px;
    letter-spacing: -0.2px;
    font-size: 16px;
    color: #191919;
}

.popup-password .modal-body .popup-input-password-wrapper input:focus {
   border: solid 1px #ff7d00;
}

.popup-password .modal-body .popup-input-password-wrapper .peek-password {
    position: absolute;
    width: 50px;
    color: #191919;
    text-align: center;
    top: 5px;
    right: 0;
    height: 43px;
    line-height: 38px;
    font-size: 20px;
    /* border-left: solid 1px #dcdcdc; */
    cursor: pointer;
}

.popup-password .modal-body .popup-input-password-wrapper input:focus + .peek-password {
    color: #ff7d00;
}

.popup-password .modal-footer {
    padding: 24px 0 34px;
    border-top: solid 1px #dcdcdc;
}

.popup-password .modal-body button, .popup-password .modal-body .button {
    width: calc(50% - 8px);
    margin-left: 16px;
    text-align: center;
    cursor: pointer;
    display: inline-block;
    vertical-align: top;
}

.popup-password .modal-body .no-fill-button {
    margin-left: 0;
}

.button-submit-change-password .lds-ring {
    top: 3px;
    right: unset;
}

.button-submit-change-password .lds-ring div {
    border-color: #ffffff transparent transparent transparent;
    margin: 0;
}
#page-account-setting {
    position: relative;
}

#page-account-setting .input-wrapper {
    margin-top: 0;
}

#page-account-setting ul.nav-tabs {
    cursor: pointer;
    border-color: #dcdcdc;
}

#page-account-setting ul.nav-tabs li a {
    padding: 0 0 14px;
    margin-right: 32px;
    color: #9b9b9b;
    font-weight: bold;
    font-size: 16px;
    border: none;
}

#page-account-setting ul.nav-tabs li a.active-tabs-account-setting {
    color:#ff7e00;
    border-bottom: solid 4px #ff7e00;
}

#page-account-setting ul.nav-tabs li a:hover {
    color: #8a8a8a;
}
.sectionContent {
  background: #f8f8f8;
  /* padding: 125px 50px 125px 255px; */
  /* padding: 5px 0 0 200px; */
  /* padding: 0 0 0 200px; */
  padding: 0 30px;
  left: 348px;
  min-height: 100%;
  position: fixed;
  top: 0;
  width: calc(100vw - 348px);
  max-height: 100%;
  overflow: auto;
  -webkit-transition: ease-in-out .1s;
  transition: ease-in-out .1s; }
  .sectionContent.section-width-100 {
    width: 100% !important;
    left: 0 !important; }
  .sectionContent table tbody tr {
    border-bottom: solid 1px #dcdcdc; }
    .sectionContent table tbody tr td {
      background: none !important; }
    .sectionContent table tbody tr:hover {
      background: #fff2e5 !important; }
  .sectionContent table tbody td {
    word-break: break-word; }
    .sectionContent table tbody td.no-data-column {
      background: #fff !important;
      cursor: default !important; }
  .sectionContent table thead td {
    word-break: break-word; }
  .sectionContent table.no-hover tbody tr {
    border-bottom: none !important; }
    .sectionContent table.no-hover tbody tr:hover td {
      background: none !important; }
    .sectionContent table.no-hover tbody tr:nth-child(odd) td {
      background: #f8f8f8 !important; }
    .sectionContent table.no-hover tbody tr:nth-child(even) td {
      background: #ffffff !important; }
  .sectionContent.deactive-sub-menu {
    left: 62px;
    padding: 0 30px;
    width: calc(100vw - 62px); }
  .sectionContent.section-wrap-active-billing-warning {
    top: 32px;
    min-height: calc(100% - 32px);
    max-height: calc(100% - 32px); }

.sectionWrap {
  position: relative;
  background: #fff;
  padding: 32px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  margin: 24px 0; }
  .sectionWrap .tcRow:hover {
    background: #fff2e5 !important; }
  .sectionWrap .bodyContent {
    margin: 20px 0 0 !important; }
  .sectionWrap.sectionWrapTop-12 {
    margin: 12px 0 0 !important; }

.sectionWrap .topContent {
  display: flex;
  justify-content: space-between;
  align-items: center; }
  .sectionWrap .topContent .topContent-button-wrapper button {
    margin-left: 4px; }

.sectionWrap .topContent.bordered {
  padding-bottom: 16px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1); }

.sectionWrap .topContent .title {
  font-size: 20px;
  font-family: PTRootBold;
  line-height: 1.2;
  letter-spacing: -0.5px; }

.sectionWrap .bodyContent {
  margin: 4px auto 24px;
  position: relative; }

.sectionWrap .formBodyContent {
  margin: 24px auto; }

.sectionWrap .formBodyContent label {
  margin: 0 0 8px; }

.sectionWrap .bodyContent .reload {
  background: rgba(255, 255, 255, 0.7);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 35px; }

.sectionWrap .flexContent {
  display: flex;
  justify-content: space-between;
  align-items: center; }

.sectionWrap .flexContent.left {
  justify-content: flex-start; }

.sectionWrap .flexContent .agentSearch {
  max-width: 370px;
  flex: 1 1;
  padding-right: 20px; }

.sectionWrap .flexContent .agentAction {
  display: flex;
  align-items: center;
  position: relative;
  top: 0; }

.sectionWrap .checkArea {
  display: flex;
  margin: 24px; }

.sectionWrap .input-wrapper .input-icon {
  position: absolute;
  right: 15px;
  top: 14px;
  text-align: center;
  font-size: 16px;
  height: 24px;
  width: 24px;
  opacity: 0.5; }

.sectionWrap .checkArea .input-wrapper {
  margin-right: 10px; }

.sectionWrap .value {
  font-family: PTRootBold;
  text-transform: capitalize;
  margin: 4px 0;
  cursor: pointer; }

.sectionWrap .formContent {
  position: relative;
  max-width: 350px; }

.sectionWrap .tableContent {
  position: relative;
  margin: 4px auto 24px; }

.sectionWrap .tcHeader,
.sectionWrap .tcRow {
  display: flex;
  width: 100%;
  font-size: 14px; }

.sectionWrap .tcHeader {
  font-family: PTRootBold;
  font-weight: 600;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1); }
  .sectionWrap .tcHeader .custom-checkbox-stripe [type="checkbox"]:checked + label:after {
    top: -3px;
    left: -5px;
    background-size: unset !important; }

.sectionWrap .tcRow {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1); }

.sectionWrap .tcRow.inside {
  border: none;
  padding: 0; }

.sectionWrap .tcRow.plain {
  border: none; }

.sectionWrap .tcCol {
  flex: 1 1;
  padding: 16px 10px;
  word-wrap: break-word; }
  .sectionWrap .tcCol label {
    margin: 0; }

.sectionWrap .tcHeader .tcColCheck {
  padding: 0;
  text-align: center; }

.sectionWrap .tcRow .tcColCheck {
  padding: 14px 23px;
  text-align: center; }
  .sectionWrap .tcRow .tcColCheck .custom-checkbox [type="checkbox"]:not(:checked) + label:before, .sectionWrap .tcRow .tcColCheck .custom-checkbox [type="checkbox"]:checked + label:before {
    top: -10px !important; }
  .sectionWrap .tcRow .tcColCheck .custom-checkbox [type="checkbox"]:checked + label:after {
    top: 10px !important; }

.sectionWrap .tcCol.capital {
  text-transform: capitalize; }

.sectionWrap .tcHeader .tcCol {
  padding: 8px 10px; }

.sectionWrap td.tcCol {
  flex: none; }

.sectionWrap .tcCol.info {
  font-family: PTRootBold;
  text-align: center;
  color: rgba(0, 0, 0, 0.4);
  padding: 0;
  margin-top: 32px;
  margin-bottom: 0; }

.sectionWrap .tcCol.info.center {
  text-align: center; }

.sectionWrap .tcRow.inside .tcCol {
  padding: 0; }

.sectionWrap .tcCol.flex {
  padding-left: 0;
  padding-right: 0;
  display: flex;
  align-items: center; }

@media (max-width: 1200px) {
  .sectionWrap .tcCol.flex {
    display: block; } }

.sectionWrap .textInfo {
  font: 16px PTRootBold;
  line-height: 1.5;
  letter-spacing: -0.2px; }

.sectionWrap .textInfo.center {
  text-align: center; }

.sectionWrap .textInfo.grey {
  color: rgba(0, 0, 0, 0.4) !important; }

.sectionWrap .textInfo.red {
  color: var(--watermelon); }

.sectionWrap .textInfo.emp {
  font-weight: 600;
  font-style: italic; }

.ToastContainer {
  width: auto !important;
  font-size: 14px; }

.Toastify__toast {
  margin-bottom: 8px;
  padding: 17.5px 44px 17.5px 60px;
  min-height: 0;
  max-width: 500px;
  border-radius: 8px;
  font-size: 14px;
  color: #191919;
  word-break: break-word;
  box-shadow: none; }

.ToastContainer .ToastContent-no-icon {
  padding: 3px 16px;
  background: #191919;
  color: #fff !important;
  border-radius: 8px !important;
  width: auto !important; }

.Toastify__toast-body {
  margin: 0;
  padding: 0; }

.ToastContainer .toast-icon-wrapper {
  position: absolute;
  top: 12px;
  left: 20px;
  padding: 6px;
  width: 32px;
  height: 32px;
  border-radius: 16px; }
  .ToastContainer .toast-icon-wrapper svg {
    padding: 4px;
    width: 20px;
    height: 20px;
    border-radius: 10px;
    color: white;
    overflow: visible;
    -webkit-transform: translate(0, -1px);
            transform: translate(0, -1px); }

.ToastContainer .close-icon {
  position: absolute;
  top: 20px;
  right: 16px;
  padding: 0;
  width: 16px;
  height: 16px;
  color: #191919; }

.Toastify__close-button--default {
  display: none;
  width: 0; }

.ToastContainer .ToastContent {
  background: #ECFFE9;
  border: solid 1px #118300; }
  .ToastContainer .ToastContent .toast-icon-wrapper {
    background: #11830033; }
    .ToastContainer .ToastContent .toast-icon-wrapper svg {
      background: #118300; }

.ToastContainer .ToastContent-fail, .ToastContent-error {
  background: #FFECEE;
  border: solid 1px #FF3F57; }
  .ToastContainer .ToastContent-fail .toast-icon-wrapper, .ToastContent-error .toast-icon-wrapper {
    background: #FF3F5733; }
    .ToastContainer .ToastContent-fail .toast-icon-wrapper svg, .ToastContent-error .toast-icon-wrapper svg {
      background: #FF3F57; }

.ToastContainer .ToastContent-info {
  background: #EBF7FF;
  border: solid 1px #0C5F99; }
  .ToastContainer .ToastContent-info .toast-icon-wrapper {
    background: #0C5F9933; }
    .ToastContainer .ToastContent-info .toast-icon-wrapper svg {
      background: #0C5F99; }

.ToastContainer .ToastContent-warning {
  background: #FFF4B1;
  border: solid 1px #FF7E00; }
  .ToastContainer .ToastContent-warning .toast-icon-wrapper {
    background: #FF7E0033; }
    .ToastContainer .ToastContent-warning .toast-icon-wrapper svg {
      background: #FF7E00; }

.ToastContainer .ToastContent button, .ToastContainer .ToastContent-fail button, .ToastContainer .ToastContent-no-icon button {
  display: none; }

.ToastContainer .ToastContent .Toastify__progress-bar {
  background: #fff !important; }

.Modal .modal-content {
  position: relative;
  padding: 32px; }

.Modal .popup-header {
  font-size: 20px;
  font-family: PTRootBold;
  padding-bottom: 16px;
  margin-bottom: 32px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1); }

.Modal .popup-body {
  position: relative; }

.Modal .popup-body .errorInfo {
  color: var(--watermelon);
  font-style: italic;
  font-size: 14px;
  margin: 8px; }

.Modal .popup-desc {
  color: rgba(0, 0, 0, 0.6);
  letter-spacing: -0.2px;
  line-height: 1.5;
  font-size: 14px; }

.Modal .button-flex {
  display: flex;
  justify-content: space-between;
  margin-top: 32px; }

.Modal .button-flex button {
  display: inline-block;
  flex: 1 1; }

.inputCopy {
  position: relative; }

.inputCopy .iconCopy {
  -moz-appearance: none;
       appearance: none;
  -webkit-appearance: none;
  border: none;
  background: #fff;
  position: absolute;
  top: 5px;
  right: 6px;
  /* transform: translate(0, -50%); */
  width: 40px;
  height: 40px;
  color: rgba(0, 0, 0, 0.4);
  font-size: 20px;
  text-align: center;
  cursor: pointer; }

.inputCopy input {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 13px 40px 15px 15px; }

.fontBold {
  font-family: PTRootBold; }

.modal-confirmation-delete .modal-content {
    box-sizing: border-box;
    width: 516px;
    padding: 0 32px;
    border-radius: 8px;
}

.modal-confirmation-delete .modal-body {
    font-size: 14px;
    letter-spacing: -0.2px;
    text-align: left;
    color: #19191999;
    /* padding-top: 30px; */
    padding: 30px 0 16px;
}

.modal-confirmation-delete .modal-body p {
    margin: 0;
    word-break: break-word;
}

.modal-confirmation-delete .modal-body b {
    font-size: 20px;
    letter-spacing: -0.6px;
    color: #191919;
    word-break: break-word;
}

.modal-confirmation-delete .modal-footer {
    padding: 0 0 32px;
    border: 0;
}

.modal-confirmation-delete .modal-footer button {
    width: calc(50% - 4px);
    display: inline-block;
    margin: 0 8px 0 0;
    position: relative;
}

.modal-confirmation-delete .modal-footer button:nth-child(2) {
    margin-right: 0;
}

.modal-confirmation-delete .modal-footer-without-border {
    border-top: solid 1px #ffffff;
    padding: 4px 0 40px;
}
.bar-verify-email {
  width: 100%;
  margin: 32px 0;
  padding: 16px 224px 16px 36px;
  border: solid 1px #ff7d00;
  color: #ff7d00;
  font-size: 16px;
  letter-spacing: -0.5px;
  border-radius: 8px;
  background: #FDF2B0;
  position: relative;
  box-shadow: 0px 2px 20px 0px rgba(25, 25, 25, 0.18); }
  .bar-verify-email .bar-verify-email-icon {
    position: absolute;
    top: 20px;
    left: 16px; }
  .bar-verify-email .main-button-22 {
    padding: 0 16px 0 12px !important;
    position: absolute;
    top: 16px;
    right: 24px; }

.modal-confirm-resend-comp .modal-content {
  width: 444px;
  border-radius: 32px; }
  .modal-confirm-resend-comp .modal-content .modal-body {
    padding: 48px;
    border-radius: 32px;
    text-align: center; }
    .modal-confirm-resend-comp .modal-content .modal-body .modal-confirm-resend-comp-title {
      margin: 0;
      font-size: 24px;
      letter-spacing: -0.6px; }
    .modal-confirm-resend-comp .modal-content .modal-body .modal-confirm-resend-comp-desc {
      font-size: 16px;
      letter-spacing: -0.3px;
      margin: 10px 0 0 0; }
    .modal-confirm-resend-comp .modal-content .modal-body .main-button-40 {
      margin: 32px 0 0 0;
      width: 100%; }

#page-account-my-profile {
  font-weight: bold;
  letter-spacing: -0.4px;
  color: #191919; }

#page-account-my-profile .my-profile-input {
  position: relative; }
  #page-account-my-profile .my-profile-input .form-separator {
    margin-top: 16px;
    border-top: solid 1px rgba(25, 25, 25, 0.1); }
  #page-account-my-profile .my-profile-input b:nth-child(2) {
    font-size: 20px;
    letter-spacing: -0.6px;
    line-height: 1; }

#page-account-my-profile .my-profile-input input[type="file"] {
  display: none; }

#page-account-my-profile .my-profile-input .profile-avatar-wrapper {
  width: 48px;
  height: 48px;
  box-sizing: border-box;
  background: #ffffff;
  border-radius: 8px; }

#page-account-my-profile .my-profile-input .profile-avatar-wrapper .no-fill-button {
  padding: 0 32px 0;
  -webkit-transition: east-in-out 0.1s;
  transition: east-in-out 0.1s;
  font-size: 16px;
  text-align: center;
  cursor: pointer;
  position: relative;
  top: -45px;
  margin-left: 60px;
  height: 40px;
  vertical-align: top;
  display: inline-block;
  width: 192px;
  line-height: 38px; }
  #page-account-my-profile .my-profile-input .profile-avatar-wrapper .no-fill-button img {
    width: 20px;
    margin: 0 4px 0 0;
    position: relative;
    top: -2px; }

#page-account-my-profile .my-profile-input .profile-avatar-wrapper img {
  width: 100%; }

#page-account-my-profile .my-profile-input:last-child {
  padding-bottom: 0; }

#page-account-my-profile .my-profile-input label {
  display: block;
  color: #191919; }

#page-account-my-profile .my-profile-input span {
  font-size: 14px;
  letter-spacing: -0.2px;
  color: #191919;
  font-weight: 100;
  display: block;
  margin-bottom: 25px;
  margin-top: 3px; }

#page-account-my-profile .my-profile-input input {
  margin-top: 1px;
  border: solid 1px #dcdcdc;
  font-size: 16px;
  letter-spacing: -0.2px;
  border-radius: 8px;
  width: 310px; }

#page-account-my-profile .my-profile-input button {
  padding: 0 32px;
  height: 40px;
  line-height: 37px;
  -webkit-transition: east-in-out 0.1s;
  transition: east-in-out 0.1s;
  font-size: 16px;
  text-align: center;
  letter-spacing: -0.5px;
  font-size: 16px; }

#page-account-my-profile .my-profile-input button img {
  width: 20px;
  height: 20px;
  position: relative;
  margin-right: 4px;
  top: -2px; }

#page-account-my-profile .account-setting-card {
  /* box-shadow: 0 1px 0 0 rgba(25, 25, 25, .1); */
  background-color: #ffffff; }

#page-account-my-profile .account-setting-card .account-setting-card-title {
  padding-bottom: 18px;
  font-size: 20px;
  font-weight: bold;
  color: #191919;
  letter-spacing: -0.6px;
  margin-bottom: 24px;
  box-shadow: 0 1px 0 0 rgba(25, 25, 25, 0.1);
  position: relative; }
  #page-account-my-profile .account-setting-card .account-setting-card-title .account-setting-save-wrapper {
    padding-top: 0;
    position: absolute;
    right: 0;
    top: 0; }

#page-account-my-profile .account-setting-save-wrapper {
  padding-top: 24px; }

.modal-edit-profile .modal-content {
  border-radius: 8px;
  padding: 32px; }

.modal-edit-profile .modal-body {
  padding: 0;
  line-height: 1; }
  .modal-edit-profile .modal-body .edit-profile-title {
    font-size: 20px;
    letter-spacing: -0.6px;
    margin: 0; }
  .modal-edit-profile .modal-body input {
    width: 100%; }

.modal-edit-profile .modal-footer {
  padding: 24px 0 0;
  border: 0; }
  .modal-edit-profile .modal-footer button {
    width: calc(50% - 4px);
    margin: 0 8px 0 0;
    height: 48px; }
    .modal-edit-profile .modal-footer button:nth-last-child(1) {
      margin: 0; }

#retrieve-data-loading-page {
  text-align: center;
  padding-top: 30px; }
  #retrieve-data-loading-page.no-padding-top {
    padding-top: 0 !important; }
  #retrieve-data-loading-page img {
    width: 400px;
    margin-bottom: 30px; }
  #retrieve-data-loading-page .loading-retrieve-data {
    font-weight: bold; }
  #retrieve-data-loading-page .retrieve-loading-text {
    font-size: unset !important;
    display: inline-block;
    position: relative;
    top: -1px;
    margin-left: 6px;
    margin-bottom: 0 !important; }
    #retrieve-data-loading-page .retrieve-loading-text b {
      color: var(--main-dark-orange) !important;
      font-size: 16px !important;
      letter-spacing: -0.5px !important; }
  #retrieve-data-loading-page .lds-ring {
    top: 3px;
    right: 0;
    width: 18px;
    height: 18px;
    margin: 0 !important; }
  #retrieve-data-loading-page .lds-ring div {
    margin: 0;
    width: 18px;
    height: 18px;
    border: solid 2px #ff7d00;
    border-color: #ff7d00 transparent transparent transparent; }
  #retrieve-data-loading-page .fail-retrive-data {
    text-align: center;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: -0.5px;
    text-align: center;
    color: #9b9b9b;
    line-height: 1;
    color: rgba(25, 25, 25, 0.6); }
    #retrieve-data-loading-page .fail-retrive-data p {
      margin: 0; }
    #retrieve-data-loading-page .fail-retrive-data svg {
      color: #C0C5CD;
      font-size: 61px;
      margin: 0 0 26px; }
    #retrieve-data-loading-page .fail-retrive-data button {
      height: 40px;
      width: 138px;
      font-size: 16px;
      letter-spacing: -.2px;
      margin: 24px 0 0; }
    #retrieve-data-loading-page .fail-retrive-data p {
      margin: 0 0 10px; }
      #retrieve-data-loading-page .fail-retrive-data p:nth-child(4) {
        font-size: 16px;
        font-weight: normal; }
    #retrieve-data-loading-page .fail-retrive-data.tier-blocking {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 5; }
      #retrieve-data-loading-page .fail-retrive-data.tier-blocking .tier-blur-layer {
        position: absolute;
        top: 0;
        left: 0;
        background: rgba(255, 255, 255, 0.8);
        -webkit-backdrop-filter: blur(1px);
                backdrop-filter: blur(1px);
        width: 100%;
        height: 100%;
        border-radius: 8px; }
      #retrieve-data-loading-page .fail-retrive-data.tier-blocking .alert-component {
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
        color: #044B84;
        border: solid 1px #0c5f99;
        background: #ebf7ff;
        font-size: 14px;
        letter-spacing: -.4px;
        padding: 17px 124px 17px 48px;
        display: inline-block;
        white-space: nowrap; }
        #retrieve-data-loading-page .fail-retrive-data.tier-blocking .alert-component svg {
          font-size: 20px;
          color: #044B84;
          left: 20px; }
        #retrieve-data-loading-page .fail-retrive-data.tier-blocking .alert-component .contact-us-button {
          position: absolute;
          width: 99px;
          border-radius: 4px;
          height: unset;
          font-size: 12px;
          letter-spacing: -.4px;
          right: 16px;
          top: 11px;
          margin: 0;
          border: none;
          box-shadow: none;
          color: #ffffff;
          padding: 7px 0 5px; }
          #retrieve-data-loading-page .fail-retrive-data.tier-blocking .alert-component .contact-us-button svg {
            color: #ffffff;
            position: relative;
            margin: 0;
            left: unset;
            top: unset;
            -webkit-transform: unset;
                    transform: unset;
            margin-right: 4px;
            font-size: 14px;
            top: -1px; }

#page-account-my-profile-edit {
  font-weight: bold;
  letter-spacing: -0.4px;
  color: #191919; }

#page-account-my-profile-edit .my-profile-input {
  position: relative;
  width: 320px;
  max-width: 100%; }
  #page-account-my-profile-edit .my-profile-input .form-separator {
    margin-top: 16px;
    border-top: solid 1px rgba(25, 25, 25, 0.1); }
  #page-account-my-profile-edit .my-profile-input .input-counter {
    color: rgba(25, 25, 25, 0.4);
    position: absolute;
    top: 0;
    left: 262px;
    width: 65px; }

#page-account-my-profile-edit .my-profile-input input[type="file"] {
  display: none; }

#page-account-my-profile-edit .my-profile-input .profile-avatar-wrapper {
  width: 48px;
  height: 48px;
  box-sizing: border-box;
  background: #ffffff;
  border-radius: 8px; }

#page-account-my-profile-edit .my-profile-input .profile-avatar-wrapper .no-fill-button {
  padding: 0 32px 0;
  -webkit-transition: east-in-out 0.1s;
  transition: east-in-out 0.1s;
  font-size: 16px;
  text-align: center;
  cursor: pointer;
  position: relative;
  top: -45px;
  margin-left: 60px;
  height: 40px;
  vertical-align: top;
  display: inline-block;
  width: 192px;
  line-height: 38px; }
  #page-account-my-profile-edit .my-profile-input .profile-avatar-wrapper .no-fill-button img {
    width: 20px;
    margin: 0 4px 0 0;
    position: relative;
    top: -2px; }

#page-account-my-profile-edit .my-profile-input .profile-avatar-wrapper img {
  width: 100%; }

#page-account-my-profile-edit .my-profile-input:last-child {
  padding-bottom: 0; }

#page-account-my-profile-edit .my-profile-input label {
  display: block;
  color: #191919; }

#page-account-my-profile-edit .my-profile-input span {
  font-size: 14px;
  letter-spacing: -0.2px;
  color: #191919;
  font-weight: 100;
  display: block;
  margin-bottom: 25px;
  margin-top: 3px; }

#page-account-my-profile-edit .my-profile-input input {
  margin-top: 1px;
  border: solid 1px #dcdcdc;
  font-size: 16px;
  letter-spacing: -0.2px;
  border-radius: 8px;
  width: 310px; }

#page-account-my-profile-edit .my-profile-input button {
  padding: 0 32px;
  height: 40px;
  line-height: 37px;
  -webkit-transition: east-in-out 0.1s;
  transition: east-in-out 0.1s;
  font-size: 16px;
  text-align: center;
  letter-spacing: -0.5px;
  font-size: 16px; }

#page-account-my-profile-edit .my-profile-input button img {
  width: 20px;
  height: 20px;
  position: relative;
  margin-right: 4px;
  top: -2px; }

#page-account-my-profile-edit .account-setting-card {
  /* box-shadow: 0 1px 0 0 rgba(25, 25, 25, .1); */
  background-color: #ffffff; }

#page-account-my-profile-edit .account-setting-card .account-setting-card-title {
  padding-bottom: 18px;
  font-size: 20px;
  font-weight: bold;
  color: #191919;
  letter-spacing: -0.6px;
  margin-bottom: 24px;
  box-shadow: 0 1px 0 0 rgba(25, 25, 25, 0.1);
  position: relative; }
  #page-account-my-profile-edit .account-setting-card .account-setting-card-title .account-setting-save-wrapper {
    padding-top: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: unset; }

#page-account-my-profile-edit .account-setting-save-wrapper {
  padding-top: 24px; }

.modal-edit-profile .modal-content {
  border-radius: 8px;
  padding: 32px; }

.modal-edit-profile .modal-body {
  padding: 0;
  line-height: 1; }
  .modal-edit-profile .modal-body .edit-profile-title {
    font-size: 20px;
    letter-spacing: -0.6px;
    margin: 0; }
  .modal-edit-profile .modal-body input {
    width: 100%; }

.modal-edit-profile .modal-footer {
  padding: 24px 0 0;
  border: 0; }
  .modal-edit-profile .modal-footer button {
    width: calc(50% - 4px);
    margin: 0 8px 0 0;
    height: 48px; }
    .modal-edit-profile .modal-footer button:nth-last-child(1) {
      margin: 0; }

.comp-back-button {
  /* display: flex;
  justify-content: space-between; */
  border: none;
  background: none;
  color: var(--main-dark-orange) !important;
  font-weight: 600;
  letter-spacing: -0.5px;
  line-height: 1.5;
  font-size: 16px;
  margin: 32px 0 0px;
  padding: 0;
  display: block;
  max-width: 100%;
}

.comp-back-button p {
  margin: 0 !important;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.comp-back-button svg {
  position: relative;
  top: -1px;
}

.comp-back-button:hover svg {
  -webkit-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
  -webkit-transform: translateX(-25%);
          transform: translateX(-25%);
}

.error-or-info-comp {
  font-size: 12px !important;
  letter-spacing: -0.4px !important;
  position: relative;
  padding: 0 0 0 20px;
  margin: 8px 0 0;
  color: rgba(25, 25, 25, 0.8); }
  .error-or-info-comp svg {
    position: absolute !important;
    left: 0 !important;
    font-size: 16px !important; }
  .error-or-info-comp b {
    font-size: 12px !important;
    letter-spacing: -0.4px !important;
    line-height: 18px !important; }

.account-settings .button-save {
  margin-top: 28px; }

.account-settings .status-wrapper {
  font-size: 14px;
  letter-spacing: -0.2px;
  color: rgba(25, 25, 25, 0.8);
  margin: 4px; }
  .account-settings .status-wrapper .status-value {
    color: rgba(25, 25, 25, 0.4);
    font-size: 16px;
    letter-spacing: -0.5px; }
    .account-settings .status-wrapper .status-value.status-enabled {
      color: #19C700; }
  .account-settings .status-wrapper img {
    position: relative;
    top: -2px;
    margin-right: 4px; }

.account-settings .account-settings-header {
  font-size: 20px;
  letter-spacing: -0.6px;
  color: #191919;
  padding-bottom: 16px;
  border-bottom: solid 1px #dcdcdc;
  position: relative;
  width: 100%; }
  .account-settings .account-settings-header button {
    width: 181px;
    position: absolute;
    right: 0;
    top: -4px;
    margin: 0; }

.account-settings .account-settings-form {
  padding-top: 24px;
  position: relative; }
  .account-settings .account-settings-form .account-setting-icon {
    position: relative;
    top: -2px; }
  .account-settings .account-settings-form .closing-welcome-switch-wrapper {
    position: absolute;
    top: 38px;
    right: 0;
    vertical-align: top;
    display: inline-block;
    margin: 0 16px; }
    .account-settings .account-settings-form .closing-welcome-switch-wrapper .custom-switch-wrapper {
      margin: 0;
      height: 28px;
      width: 48px; }
      .account-settings .account-settings-form .closing-welcome-switch-wrapper .custom-switch-wrapper .custom-switch:before {
        width: 15px;
        height: 15px;
        bottom: 7px; }
    .account-settings .account-settings-form .closing-welcome-switch-wrapper input:checked + .custom-switch:before {
      -webkit-transform: translateX(17px);
              transform: translateX(17px); }
  .account-settings .account-settings-form .account-settings-form-content {
    display: block;
    width: 100%;
    font-size: 16px;
    letter-spacing: -0.5px;
    position: relative;
    padding-right: 124px; }
    .account-settings .account-settings-form .account-settings-form-content button {
      position: absolute;
      right: 0;
      top: 0;
      margin: 0;
      display: inline-block;
      width: unset; }
    .account-settings .account-settings-form .account-settings-form-content.content-need-reply {
      margin: 0; }
    .account-settings .account-settings-form .account-settings-form-content.content-last-item {
      margin: 0; }
    .account-settings .account-settings-form .account-settings-form-content .need-reply-val {
      letter-spacing: -0.5px;
      font-size: 16px;
      color: rgba(25, 25, 25, 0.6); }
    .account-settings .account-settings-form .account-settings-form-content p {
      margin: 4px 0 10px;
      font-size: 14px;
      letter-spacing: -0.2px;
      color: rgba(25, 25, 25, 0.8); }
    .account-settings .account-settings-form .account-settings-form-content textarea {
      width: 100%;
      border-radius: 8px;
      font-size: 16px;
      letter-spacing: -0.3px;
      border: solid 1px #dcdcdc;
      resize: none;
      padding: 12px 16px;
      line-height: 24px;
      margin: 0;
      max-width: 416px;
      width: 100%;
      display: block; }
      .account-settings .account-settings-form .account-settings-form-content textarea:focus {
        border-color: #ff7d00; }
      .account-settings .account-settings-form .account-settings-form-content textarea[disabled] {
        color: rgba(25, 25, 25, 0.4);
        background: rgba(25, 25, 25, 0.1); }

.extra-header-wrapper {
  width: 100%;
  position: relative;
  border-bottom: solid 1px #dcdcdc;
  margin: 30px 0 24px 0; }
  .extra-header-wrapper .extra-header-title {
    font-size: 20px;
    letter-spacing: -0.6px; }
  .extra-header-wrapper .extra-header-sub {
    font-size: 14px;
    letter-spacing: -0.2px;
    margin: 0 0 12px 0;
    color: rgba(25, 25, 25, 0.8); }

.account-settings .button-save {
  margin-top: 28px; }

.account-settings .react-select-custom__input {
  height: 42px;
  position: relative;
  top: -3px; }

.account-settings .title-default {
  margin: 0 0 8px 0 !important; }
  .account-settings .title-default img {
    position: relative;
    margin-right: 4px; }

.account-settings .dropdown button {
  width: 100% !important; }

.account-settings .account-settings-header {
  font-size: 20px;
  letter-spacing: -0.6px;
  color: #191919;
  padding-bottom: 16px;
  border-bottom: solid 1px #dcdcdc;
  position: relative;
  width: 100%; }
  .account-settings .account-settings-header button {
    width: 181px;
    position: absolute;
    right: 0;
    top: -4px;
    margin: 0; }

.create-organization-outer-wrapper {
  padding: 32px;
  border: solid 1px rgba(25, 25, 25, 0.1);
  background: #fff;
  border-radius: 8px; }
  .create-organization-outer-wrapper .create-organization-head-title {
    font-size: 24px;
    letter-spacing: -0.4px;
    padding: 8px 0 0 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%; }
  .create-organization-outer-wrapper .create-organization-head {
    position: relative;
    width: 100%;
    height: 66px;
    border-bottom: solid 1px rgba(25, 25, 25, 0.1); }
    .create-organization-outer-wrapper .create-organization-head.create-organization-head-top {
      height: unset; }
  .create-organization-outer-wrapper .create-organization-head-button {
    display: inline-block;
    position: absolute;
    right: 0;
    top: 0; }
    .create-organization-outer-wrapper .create-organization-head-button button {
      margin: 0 0 0 8px; }
    .create-organization-outer-wrapper .create-organization-head-button .no-fill-button {
      width: 186px; }
  .create-organization-outer-wrapper .create-organization-body {
    width: 100%;
    position: relative; }
    .create-organization-outer-wrapper .create-organization-body button {
      position: absolute;
      right: 0;
      top: 3px; }
    .create-organization-outer-wrapper .create-organization-body .create-organization-body-1 {
      margin-top: 32px; }
      .create-organization-outer-wrapper .create-organization-body .create-organization-body-1 .create-organization-body-1-inner {
        position: relative;
        padding: 0 210px 0 54px; }
      .create-organization-outer-wrapper .create-organization-body .create-organization-body-1 .create-organization-body-1-icon-wrapper {
        width: 48px;
        height: 48px;
        background: #fff;
        border-radius: 8px;
        box-shadow: 0px 2px 20px 0px rgba(25, 25, 25, 0.05);
        position: absolute;
        top: -1px;
        left: 0; }
      .create-organization-outer-wrapper .create-organization-body .create-organization-body-1 .create-organization-body-1-icon {
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%); }
    .create-organization-outer-wrapper .create-organization-body .create-organization-body-2 {
      vertical-align: top;
      position: relative;
      top: 2px; }
      .create-organization-outer-wrapper .create-organization-body .create-organization-body-2 .create-organization-body-2-title {
        color: rgba(25, 25, 25, 0.6);
        font-size: 10px;
        margin: 0 0 4px 0; }
        .create-organization-outer-wrapper .create-organization-body .create-organization-body-2 .create-organization-body-2-title b {
          letter-spacing: 1.5px !important; }
      .create-organization-outer-wrapper .create-organization-body .create-organization-body-2 .create-organization-body-2-desc {
        font-size: 16px;
        letter-spacing: -0.5px;
        color: #191919;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 100%;
        margin: 0; }

#page-billing-organization {
  height: calc(100vh - 90px);
  width: 100%;
  background: #f8f8f8;
  padding: 40px 0 0 0;
  margin-top: 87px;
  box-sizing: border-box;
  position: relative; }
  #page-billing-organization .comp-back-button {
    margin: 0 0 26px 0; }

#page-billing-organization b {
  font-weight: bold;
  letter-spacing: -0.4px; }

#page-billing-organization label {
  font-size: 16px;
  font-weight: bold;
  letter-spacing: -0.4px;
  color: #191919;
  display: block;
  margin: 24px 0 10px;
  position: relative; }
  #page-billing-organization label .input-counter {
    color: rgba(25, 25, 25, 0.4);
    position: absolute;
    top: 0;
    left: 405px;
    width: 65px; }

#page-billing-organization label .custom-tooltip {
  padding: 14px;
  color: #ffffff;
  background: #4a4a4a;
  letter-spacing: 0.4px;
  width: 219px;
  font-weight: normal;
  border-radius: 8px;
  position: absolute;
  top: -35px;
  right: -110px;
  font-size: 14px; }

#page-billing-organization label .custom-tooltip::before {
  content: "";
  border-bottom: solid 12px #4a4a4a;
  border-top: solid 12px transparent;
  border-left: solid 12px transparent;
  border-right: solid 12px transparent;
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
  width: 20px;
  height: 20px;
  position: absolute;
  top: 35px;
  left: -24px; }

#page-billing-organization .span-optional {
  font-size: 14px;
  letter-spacing: -0.2px;
  color: #9b9b9b;
  font-weight: normal;
  margin-left: 4px; }

#page-billing-organization input[type="text"],
#page-billing-organization input[type="password"],
#page-billing-organization textarea {
  padding: 10px 15px;
  border-radius: 8px;
  border: solid 1px #dcdcdc;
  display: block;
  width: 450px; }
  #page-billing-organization input[type="text"]::-webkit-input-placeholder, #page-billing-organization input[type="password"]::-webkit-input-placeholder, #page-billing-organization textarea::-webkit-input-placeholder {
    color: rgba(25, 25, 25, 0.4); }
  #page-billing-organization input[type="text"]::-ms-input-placeholder, #page-billing-organization input[type="password"]::-ms-input-placeholder, #page-billing-organization textarea::-ms-input-placeholder {
    color: rgba(25, 25, 25, 0.4); }
  #page-billing-organization input[type="text"]::placeholder,
  #page-billing-organization input[type="password"]::placeholder,
  #page-billing-organization textarea::placeholder {
    color: rgba(25, 25, 25, 0.4); }

#page-billing-organization .payment-div-50 {
  width: 220px;
  margin-right: 10px;
  display: inline-block; }

#page-billing-organization .payment-div-50 svg {
  color: #9B9B9B;
  position: relative;
  top: -1px;
  margin-left: 5px;
  cursor: pointer; }

#page-billing-organization .payment-div-50 input {
  width: 100%; }

#page-billing-organization textarea {
  resize: none; }

#page-billing-organization .country-dropdown-wrapper {
  display: inline-block;
  width: 450px;
  position: relative; }

#page-billing-organization .country-dropdown-wrapper .dropdown-value-wrapper {
  padding: 10px 15px;
  border-radius: 8px;
  border: solid 1px #dcdcdc;
  display: block;
  width: 450px;
  background: #ffffff;
  cursor: pointer; }

#page-billing-organization .country-dropdown-wrapper .arrow-down-wrapper {
  border-left: solid 1px #dcdcdc;
  text-align: center;
  position: absolute;
  right: 0;
  top: 50%;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%);
  line-height: 46px;
  height: 46px;
  width: 49px;
  font-size: 20px;
  color: #727272;
  z-index: 1; }

#page-billing-organization .country-dropdown-wrapper .option-list-wrapper {
  background: #ffffff;
  border: solid 1px #dcdcdc;
  position: absolute;
  top: 46px;
  left: 0;
  border-radius: 8px;
  width: 250px;
  z-index: 1; }

#page-billing-organization .country-dropdown-wrapper .option-list-wrapper ul {
  list-style: none;
  padding: 0;
  margin: 0;
  max-height: 204px;
  overflow-y: auto; }

#page-billing-organization .country-dropdown-wrapper .option-list-wrapper ul li {
  padding: 5px 20px;
  cursor: pointer;
  -webkit-transition: ease-in-out 0.3s;
  transition: ease-in-out 0.3s; }

#page-billing-organization .country-dropdown-wrapper .option-list-wrapper ul li:hover {
  color: var(--main-dark-orange); }

#page-billing-organization .payment-setting-foot {
  position: relative;
  border-top: solid 1px #dcdcdc;
  margin-top: 30px; }

#page-billing-organization .payment-setting-foot button {
  margin-top: 35px; }

#page-billing-organization .payment-setting-foot button svg {
  font-size: 20px;
  position: relative;
  top: -2px; }

#page-billing-organization #organization-billing-header {
  /* padding-bottom: 20px; */
  /* margin-bottom: 20px; */
  /* border-bottom: solid 1px #dcdcdc; */ }

#page-billing-organization .orange-button .lds-ring div {
  border-color: #ffffff transparent transparent transparent; }

#page-billing-organization .orange-button .lds-ring {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%); }

#page-billing-organization .orange-button .lds-ring div {
  margin: 0; }

#new-organization-header #new-organization-header-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    border-bottom: 1px solid #dcdcdc;
    text-align: left;
    width: 100%;
    background: #ffffff;
    z-index: 2;
}

#new-organization-header #new-organization-header-content {
    padding: 30px 15px 30px;
    position: relative;
}

#new-organization-header #new-organization-header-content #new-organization-title {
  font-size: 24px;
  font-weight: bold;
  letter-spacing: -0.4px;
  color: #191919;
  height: 40px;
  line-height: 40px;
  border-left: solid 1px #dcdcdc;
  padding-left: 15px;
  margin-left: 15px;
  display: inline-block;
  position: absolute;
  top: 50%;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%);
}

#new-organization-step-wrapper {
    height: 28px;
    line-height: 28px;
    position: relative;
    /* top: 50%; */
    /* transform: translate(0, -50%); */
    /* left: 380px; */
    display: inline-block;
}

#new-organization-step-wrapper .new-organization-number {
    background: #dcdcdc;
    border-radius: 50%;
    color: #ffffff;
    width: 18px;
    display: inline-block;
    height: 17px;
    line-height: 17px;
    text-align: center;
    font-size: 11px;
    margin-right: 8px;
    position: relative;
    top: -2px;
}

#new-organization-step-wrapper .new-organization-number.active-number {
    background: var(--main-dark-orange);
}

#new-organization-step-wrapper span {
    font-size: 16px;
    letter-spacing: -0.4px;
    color: #9b9b9b;
}

#new-organization-step-wrapper span.active-text {
    color: var(--main-dark-orange);
}

#new-organization-step-wrapper .new-organization-line {
    border-top: solid 2px #dcdcdc;
    width: 40px;
    display: inline-block;
    margin: 0 11px;
    position: relative;
    top: -4px;
}

#new-organization-step-wrapper .new-organization-line.active-line {
    border-color: var(--main-dark-orange);
}

.logout-organization {
    position: absolute;
    right: 15px;
    top: 50%;
    -webkit-transform: translate(0, -50%);
            transform: translate(0, -50%);
    color: #ff7e00;
    cursor: pointer;
}

.logout-organization:hover {
    color: #e87200;
}
#page-error404 {
    position: fixed;
    width: 100vw;
    height: 100vh;
    background: #f9f9f9;
}

#page-error404.page-404-after-login {
    padding-top: 87px;
}

.page-404-content {
    position: absolute;
    display: inline-block;
    text-align: center;
    font-size: 25px;
    font-weight: bold;
    letter-spacing: -0.4px;
    color: #191919;
    line-height: 1;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
}

.page-404-content p {
    font-size: 16px;
    font-weight: normal;
    margin: 10px 0;
}

/* .page-404-content.not-login {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.page-404-content.after-login {
    top: 110px;
    left: 50%;
    transform: translate(-50%, 0);
} */

.page-404-content button {
    padding: 10px 30px 11px;
    font-size: 16px;
    line-height: 1;
    position: relative;
}

.page-404-content button svg {
    position: absolute;
    font-size: 23px;
    left: 22px;
    top: 7px;
}

.page-404-content img {
    width: 400px;
    margin-bottom: 30px;
}

#sidemenu-wrapper {
  width: 200px;
  display: inline-block;
  position: fixed;
  top: 0;
  background: var(--main-dark-orange);
  color: #ffffff;
  height: calc(100vh);
  overflow-x: hidden;
  box-shadow: 2px 2px 5px 0 #c3c3c3;
  z-index: 1;
  font-weight: 100; }
  #sidemenu-wrapper a.active-menu div {
    color: #ffffff !important;
    opacity: 1 !important;
    font-weight: bold; }
  #sidemenu-wrapper a.active-menu li {
    color: #ffffff !important;
    opacity: 1 !important;
    font-weight: bold; }
  #sidemenu-wrapper .menu-padding {
    padding: 0 16px; }
  #sidemenu-wrapper .menu-height {
    line-height: 70px;
    height: 70px; }
  #sidemenu-wrapper .list-menu-wrapper {
    position: relative;
    font-weight: bold;
    cursor: pointer; }
    #sidemenu-wrapper .list-menu-wrapper img {
      margin-right: 4px;
      position: relative;
      top: -2px;
      width: 18px; }
  #sidemenu-wrapper .menu-smaller {
    color: #ffffff;
    opacity: 0.8;
    font-size: 14px;
    font-weight: 100; }
  #sidemenu-wrapper .menu-smaller.active-side-menu {
    background: #de6d00; }
  #sidemenu-wrapper .parentMenu-with-child {
    font-size: 16px; }
    #sidemenu-wrapper .parentMenu-with-child .parentMenu-name {
      position: relative;
      border-bottom: solid 1px #E87200; }
    #sidemenu-wrapper .parentMenu-with-child .childMenu-wrapper {
      position: relative; }
      #sidemenu-wrapper .parentMenu-with-child .childMenu-wrapper ul {
        margin: 0;
        padding: 0;
        list-style: none;
        position: relative;
        background: #E87200; }
        #sidemenu-wrapper .parentMenu-with-child .childMenu-wrapper ul li {
          border-bottom: solid 1px #E87200; }
  #sidemenu-wrapper .parentMenu-with-child.active {
    background: var(--main-dark-orange); }
    #sidemenu-wrapper .parentMenu-with-child.active .parentMenu-name {
      border-bottom: solid 1px #E87200; }
    #sidemenu-wrapper .parentMenu-with-child.active .childMenu-wrapper ul li {
      border-bottom: solid 1px #fb7b00; }
  #sidemenu-wrapper .menu-hover:hover {
    background: #de6d00 !important; }

.menu-Home {
  border-top: 1px solid #e87200; }

.sidemenu-organization-wrapper {
  position: relative;
  margin-bottom: 24px;
  padding: 12px 16px 14px 16px;
  box-shadow: 0 1px 0 0 #e87200; }

.side-organization-name {
  margin: 0;
  font-size: 14px;
  line-height: 1.5;
  letter-spacing: -0.4px;
  color: #ffffff;
  max-width: calc(100% - 30px);
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap; }

.side-organization-email {
  margin: 0;
  font-size: 12px;
  line-height: 1.33;
  letter-spacing: -0.2px;
  color: #ffffff;
  font-weight: normal;
  max-width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap; }

.right-arrow-organization {
  position: absolute;
  right: 12px;
  top: 14px; }

.organization-popup-wrapper {
  position: fixed;
  -webkit-transition: ease-in-out .2s;
  transition: ease-in-out .2s;
  background: #ffffff;
  width: 280px;
  display: inline-block;
  left: 80px;
  bottom: 16px;
  border-radius: 8px;
  box-shadow: 0 0 20px 2px #eaeaea;
  cursor: pointer;
  visibility: visible;
  opacity: 1;
  z-index: 1; }
  .organization-popup-wrapper:before {
    content: "";
    position: absolute;
    width: 16px;
    height: 16px;
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    bottom: 18px;
    left: -8px;
    box-shadow: -4px 5px 5px 0px rgba(234, 232, 232, 0.2);
    background: #ffffff; }

.popup-organization-content-info {
  padding: 19px 20px;
  line-height: 1;
  border-bottom: solid 1px #dcdcdc; }
  .popup-organization-content-info p {
    color: #191919;
    max-width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    line-height: 15px; }
  .popup-organization-content-info.popup-top-content {
    padding: 20px;
    position: relative; }
    .popup-organization-content-info.popup-top-content p:nth-child(1) {
      margin-bottom: 10px; }
    .popup-organization-content-info.popup-top-content svg {
      color: #ff7d00;
      font-size: 20px;
      position: absolute;
      top: 19px;
      right: 20px; }
      .popup-organization-content-info.popup-top-content svg:hover {
        color: #e87200; }

.popup-organization-content-info-name {
  font-size: 16px;
  margin: 0 0 4px 0; }

.popup-organization-content-info-email {
  font-weight: normal;
  font-size: 14px;
  margin: 0; }

.popup-oganization-list {
  max-height: 183px;
  overflow: hidden; }
  .popup-oganization-list.organization-list-with-scrollbar {
    height: 183px; }
  .popup-oganization-list ul {
    margin: 0;
    padding: 0;
    max-height: 183px;
    overflow-y: auto; }
    .popup-oganization-list ul li {
      font-size: 14px;
      color: #191919;
      list-style: none;
      margin: 0;
      position: relative;
      font-weight: normal; }
      .popup-oganization-list ul li:hover {
        color: #ff7d00; }
      .popup-oganization-list ul li svg {
        color: #ff7d00;
        font-size: 34px;
        position: absolute;
        left: 15px;
        top: 12px;
        display: none; }
    .popup-oganization-list ul li.active-organization {
      color: #ff7d00; }
      .popup-oganization-list ul li.active-organization svg {
        display: block; }
  .popup-oganization-list a {
    color: unset !important; }

.organization-popup-wrapper.deactive-organization-popup-wrapper {
  visibility: hidden;
  opacity: 0;
  -webkit-transition: visibility 0s, opacity 0.5s linear;
  transition: visibility 0s, opacity 0.5s linear; }

.popup-organization-content-wrapper {
  padding: 0;
  line-height: 59px;
  height: 60px;
  width: 100%; }
  .popup-organization-content-wrapper b {
    position: relative; }
  .popup-organization-content-wrapper svg {
    font-size: 25px;
    position: relative;
    top: -2px; }

.new-organization-wrapper {
  font-size: 16px;
  color: #ff7d00;
  text-align: center;
  border-top: solid 1px #dcdcdc;
  position: relative; }

.logout-wrapper {
  font-size: 16px;
  color: #ff3f57;
  text-align: center;
  border-top: solid 1px #dcdcdc; }

.organization-link-wrapper {
  padding: 20px 30px 20px 49px; }

.new-side-menu-wrapper {
  max-height: 100%;
  width: 62px;
  display: inline-block;
  position: relative;
  overflow-y: auto;
  background: #ff7d00;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  box-shadow: 0 2px 20px 0 rgba(25, 25, 25, 0.18); }
  .new-side-menu-wrapper .scrolling-top-menu {
    height: calc(100% - 162px);
    overflow: hidden; }
  .new-side-menu-wrapper.side-menu-active-billing-warning {
    top: 32px;
    height: calc(100% - 32px);
    max-height: calc(100% - 32px); }
  .new-side-menu-wrapper .new-main-side-menu-wrapper {
    color: #ffffff;
    line-height: 12px;
    font-size: 10px;
    text-align: center;
    letter-spacing: -0.3px;
    padding: 0 4px 6px 3px;
    cursor: pointer;
    width: 100%;
    position: relative; }
    .new-side-menu-wrapper .new-main-side-menu-wrapper img {
      margin: 12px 0 8px;
      width: 32px;
      height: 32px; }
    .new-side-menu-wrapper .new-main-side-menu-wrapper:hover {
      background: #E87403; }
    .new-side-menu-wrapper .new-main-side-menu-wrapper.active-side-menu {
      background: #E87403; }
      .new-side-menu-wrapper .new-main-side-menu-wrapper.active-side-menu .active-white-line {
        position: absolute;
        top: 12px;
        left: -1px;
        width: 3px;
        height: 32px;
        display: inline-block;
        background: #ffffff;
        box-shadow: 0 2px 20px 3px #ffffff;
        border-radius: 8px; }
  .new-side-menu-wrapper .side-menu-main-bottom-wrapper {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    display: inline-block; }
    .new-side-menu-wrapper .side-menu-main-bottom-wrapper .new-main-side-menu-wrapper {
      line-height: 12px;
      padding: 0 4px 10px 3px; }
      .new-side-menu-wrapper .side-menu-main-bottom-wrapper .new-main-side-menu-wrapper img {
        margin: 12px 0 10px; }
    .new-side-menu-wrapper .side-menu-main-bottom-wrapper .my-account-avatar {
      border-radius: 50%;
      width: 32px;
      height: 32px;
      margin: 12px 0 10px; }
      .new-side-menu-wrapper .side-menu-main-bottom-wrapper .my-account-avatar.avatar-name {
        background: #ffffff;
        color: #ff7d00;
        width: 32px;
        height: 32px;
        display: inline-block;
        line-height: 32px;
        font-size: 15px; }
      .new-side-menu-wrapper .side-menu-main-bottom-wrapper .my-account-avatar.image {
        width: 100%; }

.new-sub-side-menu-wrapper {
  width: 286px;
  display: inline-block;
  height: 100%;
  position: fixed;
  top: 0;
  left: 62px;
  background: #ffffff;
  padding: 12px;
  -webkit-transition: ease-in-out .1s;
  transition: ease-in-out .1s;
  overflow-y: auto;
  overflow-x: hidden; }
  .new-sub-side-menu-wrapper .sub-menu-scroll {
    height: calc(100% - 35px);
    position: relative;
    top: -12px;
    left: -12px;
    width: calc(100% + 24px); }
    .new-sub-side-menu-wrapper .sub-menu-scroll .sub-menu-scroll-inner {
      padding: 12px; }
  .new-sub-side-menu-wrapper .your-channel-limit {
    width: 100%;
    position: absolute;
    bottom: 12px;
    left: 16px; }
    .new-sub-side-menu-wrapper .your-channel-limit p {
      color: #191919;
      font-size: 12px;
      letter-spacing: -0.4px;
      margin: 0 0 0; }
    .new-sub-side-menu-wrapper .your-channel-limit .channel-limit {
      color: #ff7d00;
      font-size: 16px;
      letter-spacing: -0.5px; }
  .new-sub-side-menu-wrapper.new-sub-side-menu-active-billing-warning {
    top: 32px;
    height: calc(100% - 32px); }
  .new-sub-side-menu-wrapper.deactive-submenu-wrapper {
    width: 0;
    padding: 0; }
  .new-sub-side-menu-wrapper .back-to-main-menu-sidemenu {
    color: #ff7d00;
    margin: 20px 0 14px;
    font-size: 14px;
    letter-spacing: -0.4px;
    padding: 0 0 0 12px;
    cursor: pointer; }
    .new-sub-side-menu-wrapper .back-to-main-menu-sidemenu:hover {
      color: var(--main-darker-orange); }
    .new-sub-side-menu-wrapper .back-to-main-menu-sidemenu svg {
      font-size: 16px;
      position: relative;
      top: -1px;
      margin-right: 4px; }
  .new-sub-side-menu-wrapper .channels-text-side-menu {
    color: rgba(25, 25, 25, 0.4);
    font-size: 12px;
    letter-spacing: 2.5px;
    margin: 0 0 8px;
    padding: 0 0 0 12px; }
  .new-sub-side-menu-wrapper .sub-menu-content {
    color: rgba(25, 25, 25, 0.4);
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.5px;
    padding: 12px 16px 12px 40px;
    margin-bottom: 10px;
    border-radius: 6px;
    cursor: pointer;
    position: relative; }
    .new-sub-side-menu-wrapper .sub-menu-content.sub-menu-overflow {
      padding: 12px 41px 12px 40px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden; }
    .new-sub-side-menu-wrapper .sub-menu-content .medium-counter {
      letter-spacing: -0.4px;
      min-width: 20px;
      height: 20px;
      line-height: 20px;
      padding: 0 4px;
      background: #ff7d00;
      color: #ffffff;
      border-radius: 50%;
      position: absolute;
      right: 15px;
      top: 50%;
      -webkit-transform: translate(0, -50%);
              transform: translate(0, -50%);
      font-size: 12px;
      text-align: center; }
    .new-sub-side-menu-wrapper .sub-menu-content .active-sub-side-menu {
      color: #ff7d00;
      background: rgba(255, 126, 0, 0.1); }
    .new-sub-side-menu-wrapper .sub-menu-content .sub-menu-icon {
      position: absolute;
      width: 17px;
      height: 16px;
      content: "";
      left: 16px;
      top: 50%;
      -webkit-transform: translate(0, -50%);
              transform: translate(0, -50%);
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center; }
      .new-sub-side-menu-wrapper .sub-menu-content .sub-menu-icon.secretKeys {
        background-image: url(/static/media/secret-keys-grey.2cd72a9d.svg); }
      .new-sub-side-menu-wrapper .sub-menu-content .sub-menu-icon.pushNotification {
        background-image: url(/static/media/push-notif-grey.22aba3ef.svg); }
      .new-sub-side-menu-wrapper .sub-menu-content .sub-menu-icon.profile {
        background-image: url(/static/media/profile-grey.67d8e0b5.svg); }
      .new-sub-side-menu-wrapper .sub-menu-content .sub-menu-icon.members {
        background-image: url(/static/media/member-grey.e5a0bb05.svg); }
      .new-sub-side-menu-wrapper .sub-menu-content .sub-menu-icon.setup {
        background-image: url(/static/media/setup-grey.256dfe9b.svg); }
      .new-sub-side-menu-wrapper .sub-menu-content .sub-menu-icon.topics {
        background-image: url(/static/media/topic-grey.b872d951.svg); }
      .new-sub-side-menu-wrapper .sub-menu-content .sub-menu-icon.roles {
        background-image: url(/static/media/roles-grey.5ce72fef.svg); }
      .new-sub-side-menu-wrapper .sub-menu-content .sub-menu-icon.security {
        background-image: url(/static/media/lock-grey.e817a169.svg); }
      .new-sub-side-menu-wrapper .sub-menu-content .sub-menu-icon.generalQuickReply {
        background-image: url(/static/media/general-quick-reply-grey.fd879c82.svg); }
      .new-sub-side-menu-wrapper .sub-menu-content .sub-menu-icon.personalQuickReply {
        background-image: url(/static/media/personal-quick-reply-grey.b02a0292.svg); }
      .new-sub-side-menu-wrapper .sub-menu-content .sub-menu-icon.billing {
        background-image: url(/static/media/billing-grey.1d6c7d2d.svg); }
      .new-sub-side-menu-wrapper .sub-menu-content .sub-menu-icon.organizationDetails {
        background-image: url(/static/media/organization-grey.4606c627.svg); }
      .new-sub-side-menu-wrapper .sub-menu-content .sub-menu-icon.labels {
        background-image: url(/static/media/tag-grey.5ebd757f.svg); }
      .new-sub-side-menu-wrapper .sub-menu-content .sub-menu-icon.broadcastMessage {
        background-image: url(/static/media/broadcast-message-grey.9672d536.svg); }
      .new-sub-side-menu-wrapper .sub-menu-content .sub-menu-icon.userContact {
        background-image: url(/static/media/user-contact-grey.e75428f9.svg); }
      .new-sub-side-menu-wrapper .sub-menu-content .sub-menu-icon.userSegment {
        background-image: url(/static/media/user-segment-grey.360db598.svg); }
      .new-sub-side-menu-wrapper .sub-menu-content .sub-menu-icon.topup {
        background-image: url(/static/media/top-up-grey.a435a05b.svg); }
      .new-sub-side-menu-wrapper .sub-menu-content .sub-menu-icon.officeHour {
        background-image: url(/static/media/office-hour-grey.5a8cf1df.svg); }
      .new-sub-side-menu-wrapper .sub-menu-content .sub-menu-icon.welcomeMessage {
        background-image: url(/static/media/welcome-message-grey.c32a5b3b.svg); }
      .new-sub-side-menu-wrapper .sub-menu-content .sub-menu-icon.awayMessage {
        background-image: url(/static/media/away-message-grey.5008b709.svg); }
      .new-sub-side-menu-wrapper .sub-menu-content .sub-menu-icon.closingMessage {
        background-image: url(/static/media/closing-message-grey.c70a6b06.svg); }
      .new-sub-side-menu-wrapper .sub-menu-content .sub-menu-icon.userRating {
        background-image: url(/static/media/user-rating-grey.3cbfd70c.svg); }
      .new-sub-side-menu-wrapper .sub-menu-content .sub-menu-icon.inboxSetting {
        background-image: url(/static/media/inbox-setting-grey.140a5ce9.svg); }
      .new-sub-side-menu-wrapper .sub-menu-content .sub-menu-icon.quickReply {
        background-image: url(/static/media/inbox-quick-reply-grey.3437ac43.svg); }
      .new-sub-side-menu-wrapper .sub-menu-content .sub-menu-icon.dailyOverview {
        background-image: url(/static/media/daily-overview-grey.8b0cb409.svg); }
      .new-sub-side-menu-wrapper .sub-menu-content .sub-menu-icon.overview {
        background-image: url(/static/media/overview-grey.2a460b72.svg); }
      .new-sub-side-menu-wrapper .sub-menu-content .sub-menu-icon.caseReport {
        background-image: url(/static/media/case-report-grey.a841f896.svg); }
      .new-sub-side-menu-wrapper .sub-menu-content .sub-menu-icon.caseRatings {
        background-image: url(/static/media/case-ratings-grey.cd68b59c.svg); }
      .new-sub-side-menu-wrapper .sub-menu-content .sub-menu-icon.agentReport {
        background-image: url(/static/media/agent-report-grey.ca34f0de.svg); }
      .new-sub-side-menu-wrapper .sub-menu-content .sub-menu-icon.clockInReport {
        background-image: url(/static/media/clock-in-report-grey.1c918649.svg); }
      .new-sub-side-menu-wrapper .sub-menu-content .sub-menu-icon.assignmentRules {
        background-image: url(/static/media/assignment-rules-grey.448b74e4.svg); }
      .new-sub-side-menu-wrapper .sub-menu-content .sub-menu-icon.topicReport {
        background-image: url(/static/media/topic-report-grey.1920bf5b.svg); }
      .new-sub-side-menu-wrapper .sub-menu-content .sub-menu-icon.faq {
        background-image: url(/static/media/faq-grey.660054a9.svg); }
      .new-sub-side-menu-wrapper .sub-menu-content .sub-menu-icon.messageApiHistory {
        background-image: url(/static/media/message-history-grey.9796fbda.svg); }
      .new-sub-side-menu-wrapper .sub-menu-content .sub-menu-icon.utm {
        background-image: url(/static/media/utm-grey.c67dc495.svg); }
      .new-sub-side-menu-wrapper .sub-menu-content .sub-menu-icon.messageUserSummary {
        background-image: url(/static/media/message-user-summary-grey.068963d6.svg); }
      .new-sub-side-menu-wrapper .sub-menu-content .sub-menu-icon.settings {
        background-image: url(/static/media/inbox-setting-grey.140a5ce9.svg); }
      .new-sub-side-menu-wrapper .sub-menu-content .sub-menu-icon.autoResolve {
        background-image: url(/static/media/auto-resolve-grey.6aa6bddf.svg); }
      .new-sub-side-menu-wrapper .sub-menu-content .sub-menu-icon.autoUnassign {
        background-image: url(/static/media/auto-unassign-grey.f5e6a8bc.svg); }
      .new-sub-side-menu-wrapper .sub-menu-content .sub-menu-icon.contactInfoCustomField {
        background-image: url(/static/media/contact-info-custom-field-grey.9eb2da1a.svg); }
      .new-sub-side-menu-wrapper .sub-menu-content .sub-menu-icon.importContact {
        background-image: url(/static/media/import-contact-grey.1bfb274d.svg); }
    .new-sub-side-menu-wrapper .sub-menu-content:hover {
      color: rgba(25, 25, 25, 0.4);
      background: rgba(25, 25, 25, 0.05); }
      .new-sub-side-menu-wrapper .sub-menu-content:hover .secretKeys {
        background-image: url(/static/media/secret-keys-grey.2cd72a9d.svg); }
      .new-sub-side-menu-wrapper .sub-menu-content:hover .pushNotification {
        background-image: url(/static/media/push-notif-grey.22aba3ef.svg); }
      .new-sub-side-menu-wrapper .sub-menu-content:hover .profile {
        background-image: url(/static/media/profile-grey.67d8e0b5.svg); }
      .new-sub-side-menu-wrapper .sub-menu-content:hover .members {
        background-image: url(/static/media/member-grey.e5a0bb05.svg); }
      .new-sub-side-menu-wrapper .sub-menu-content:hover .setup {
        background-image: url(/static/media/setup-grey.256dfe9b.svg); }
      .new-sub-side-menu-wrapper .sub-menu-content:hover .topics {
        background-image: url(/static/media/topic-grey.b872d951.svg); }
      .new-sub-side-menu-wrapper .sub-menu-content:hover .roles {
        background-image: url(/static/media/roles-grey.5ce72fef.svg); }
      .new-sub-side-menu-wrapper .sub-menu-content:hover .security {
        background-image: url(/static/media/lock-grey.e817a169.svg); }
      .new-sub-side-menu-wrapper .sub-menu-content:hover .generalQuickReply {
        background-image: url(/static/media/general-quick-reply-grey.fd879c82.svg); }
      .new-sub-side-menu-wrapper .sub-menu-content:hover .personalQuickReply {
        background-image: url(/static/media/personal-quick-reply-grey.b02a0292.svg); }
      .new-sub-side-menu-wrapper .sub-menu-content:hover .billing {
        background-image: url(/static/media/billing-grey.1d6c7d2d.svg); }
      .new-sub-side-menu-wrapper .sub-menu-content:hover .organizationDetails {
        background-image: url(/static/media/organization-grey.4606c627.svg); }
      .new-sub-side-menu-wrapper .sub-menu-content:hover .labels {
        background-image: url(/static/media/tag-grey.5ebd757f.svg); }
      .new-sub-side-menu-wrapper .sub-menu-content:hover .broadcastMessage {
        background-image: url(/static/media/broadcast-message-grey.9672d536.svg); }
      .new-sub-side-menu-wrapper .sub-menu-content:hover .userContact {
        background-image: url(/static/media/user-contact-grey.e75428f9.svg); }
      .new-sub-side-menu-wrapper .sub-menu-content:hover .userSegment {
        background-image: url(/static/media/user-segment-grey.360db598.svg); }
      .new-sub-side-menu-wrapper .sub-menu-content:hover .topup {
        background-image: url(/static/media/top-up-grey.a435a05b.svg); }
      .new-sub-side-menu-wrapper .sub-menu-content:hover .officeHour {
        background-image: url(/static/media/office-hour-grey.5a8cf1df.svg); }
      .new-sub-side-menu-wrapper .sub-menu-content:hover .welcomeMessage {
        background-image: url(/static/media/welcome-message-grey.c32a5b3b.svg); }
      .new-sub-side-menu-wrapper .sub-menu-content:hover .awayMessage {
        background-image: url(/static/media/away-message-grey.5008b709.svg); }
      .new-sub-side-menu-wrapper .sub-menu-content:hover .closingMessage {
        background-image: url(/static/media/closing-message-grey.c70a6b06.svg); }
      .new-sub-side-menu-wrapper .sub-menu-content:hover .userRating {
        background-image: url(/static/media/user-rating-grey.3cbfd70c.svg); }
      .new-sub-side-menu-wrapper .sub-menu-content:hover .inboxSetting {
        background-image: url(/static/media/inbox-setting-grey.140a5ce9.svg); }
      .new-sub-side-menu-wrapper .sub-menu-content:hover .quickReply {
        background-image: url(/static/media/inbox-quick-reply-grey.3437ac43.svg); }
      .new-sub-side-menu-wrapper .sub-menu-content:hover .dailyOverview {
        background-image: url(/static/media/daily-overview-grey.8b0cb409.svg); }
      .new-sub-side-menu-wrapper .sub-menu-content:hover .overview {
        background-image: url(/static/media/overview-grey.2a460b72.svg); }
      .new-sub-side-menu-wrapper .sub-menu-content:hover .caseReport {
        background-image: url(/static/media/case-report-grey.a841f896.svg); }
      .new-sub-side-menu-wrapper .sub-menu-content:hover .caseRatings {
        background-image: url(/static/media/case-ratings-grey.cd68b59c.svg); }
      .new-sub-side-menu-wrapper .sub-menu-content:hover .agentReport {
        background-image: url(/static/media/agent-report-grey.ca34f0de.svg); }
      .new-sub-side-menu-wrapper .sub-menu-content:hover .clockInReport {
        background-image: url(/static/media/clock-in-report-grey.1c918649.svg); }
      .new-sub-side-menu-wrapper .sub-menu-content:hover .assignmentRules {
        background-image: url(/static/media/assignment-rules-grey.448b74e4.svg); }
      .new-sub-side-menu-wrapper .sub-menu-content:hover .topicReport {
        background-image: url(/static/media/topic-report-grey.1920bf5b.svg); }
      .new-sub-side-menu-wrapper .sub-menu-content:hover .messageApiHistory {
        background-image: url(/static/media/message-history-grey.9796fbda.svg); }
      .new-sub-side-menu-wrapper .sub-menu-content:hover .utm {
        background-image: url(/static/media/utm-grey.c67dc495.svg); }
      .new-sub-side-menu-wrapper .sub-menu-content:hover .messageUserSummary {
        background-image: url(/static/media/message-user-summary-grey.068963d6.svg); }
      .new-sub-side-menu-wrapper .sub-menu-content:hover .faq {
        background-image: url(/static/media/faq-grey.660054a9.svg); }
      .new-sub-side-menu-wrapper .sub-menu-content:hover .contactInfoCustomField {
        background-image: url(/static/media/contact-info-custom-field-grey.9eb2da1a.svg); }
      .new-sub-side-menu-wrapper .sub-menu-content:hover .settings {
        background-image: url(/static/media/inbox-setting-grey.140a5ce9.svg); }
      .new-sub-side-menu-wrapper .sub-menu-content:hover .importContact {
        background-image: url(/static/media/import-contact-grey.1bfb274d.svg); }
    .new-sub-side-menu-wrapper .sub-menu-content.active-sub-menu {
      color: #ff7d00;
      background: rgba(255, 126, 0, 0.1); }
      .new-sub-side-menu-wrapper .sub-menu-content.active-sub-menu .secretKeys {
        background-image: url(/static/media/secret-keys-orange.f252baf8.svg); }
      .new-sub-side-menu-wrapper .sub-menu-content.active-sub-menu .pushNotification {
        background-image: url(/static/media/push-notif-orange.3df258da.svg); }
      .new-sub-side-menu-wrapper .sub-menu-content.active-sub-menu .profile {
        background-image: url(/static/media/profile-orange.f2c50c52.svg); }
      .new-sub-side-menu-wrapper .sub-menu-content.active-sub-menu .members {
        background-image: url(/static/media/member-orange.e18151c4.svg); }
      .new-sub-side-menu-wrapper .sub-menu-content.active-sub-menu .setup {
        background-image: url(/static/media/setup-orange.178a64e7.svg); }
      .new-sub-side-menu-wrapper .sub-menu-content.active-sub-menu .topics {
        background-image: url(/static/media/topic-orange.bb4215cc.svg); }
      .new-sub-side-menu-wrapper .sub-menu-content.active-sub-menu .roles {
        background-image: url(/static/media/roles-orange.a6f62269.svg); }
      .new-sub-side-menu-wrapper .sub-menu-content.active-sub-menu .security {
        background-image: url(/static/media/lock-orange.0f197b8d.svg); }
      .new-sub-side-menu-wrapper .sub-menu-content.active-sub-menu .generalQuickReply {
        background-image: url(/static/media/general-quick-reply-orange.2539509a.svg); }
      .new-sub-side-menu-wrapper .sub-menu-content.active-sub-menu .personalQuickReply {
        background-image: url(/static/media/personal-quick-reply-orange.19f97bb1.svg); }
      .new-sub-side-menu-wrapper .sub-menu-content.active-sub-menu .billing {
        background-image: url(/static/media/billing-orange.a283bf85.svg); }
      .new-sub-side-menu-wrapper .sub-menu-content.active-sub-menu .organizationDetails {
        background-image: url(/static/media/organization-orange.683204d0.svg); }
      .new-sub-side-menu-wrapper .sub-menu-content.active-sub-menu .labels {
        background-image: url(/static/media/tag-orange.498df722.svg); }
      .new-sub-side-menu-wrapper .sub-menu-content.active-sub-menu .broadcastMessage {
        background-image: url(/static/media/broadcast-message-orange.6d053ec7.svg); }
      .new-sub-side-menu-wrapper .sub-menu-content.active-sub-menu .userContact {
        background-image: url(/static/media/user-contact-orange.c5ed9ca3.svg); }
      .new-sub-side-menu-wrapper .sub-menu-content.active-sub-menu .userSegment {
        background-image: url(/static/media/user-segment-orange.089c263a.svg); }
      .new-sub-side-menu-wrapper .sub-menu-content.active-sub-menu .topup {
        background-image: url(/static/media/top-up-orange.5de9582c.svg); }
      .new-sub-side-menu-wrapper .sub-menu-content.active-sub-menu .officeHour {
        background-image: url(/static/media/office-hour-orange.2bf67a0f.svg); }
      .new-sub-side-menu-wrapper .sub-menu-content.active-sub-menu .welcomeMessage {
        background-image: url(/static/media/welcome-message-orange.6a6c6ce6.svg); }
      .new-sub-side-menu-wrapper .sub-menu-content.active-sub-menu .awayMessage {
        background-image: url(/static/media/away-message-orange.e5323fef.svg); }
      .new-sub-side-menu-wrapper .sub-menu-content.active-sub-menu .closingMessage {
        background-image: url(/static/media/closing-message-orange.761b5e71.svg); }
      .new-sub-side-menu-wrapper .sub-menu-content.active-sub-menu .userRating {
        background-image: url(/static/media/user-rating-orange.1cf67045.svg); }
      .new-sub-side-menu-wrapper .sub-menu-content.active-sub-menu .inboxSetting {
        background-image: url(/static/media/inbox-settings-orange.b4e206d3.svg); }
      .new-sub-side-menu-wrapper .sub-menu-content.active-sub-menu .quickReply {
        background-image: url(/static/media/inbox-quick-reply-orange.1905a72a.svg); }
      .new-sub-side-menu-wrapper .sub-menu-content.active-sub-menu .dailyOverview {
        background-image: url(/static/media/daily-overview-orange.f01985b8.svg); }
      .new-sub-side-menu-wrapper .sub-menu-content.active-sub-menu .overview {
        background-image: url(/static/media/overview-orange.2e20a860.svg); }
      .new-sub-side-menu-wrapper .sub-menu-content.active-sub-menu .caseReport {
        background-image: url(/static/media/case-report-orange.75304281.svg); }
      .new-sub-side-menu-wrapper .sub-menu-content.active-sub-menu .caseRatings {
        background-image: url(/static/media/case-ratings-orange.1cf67045.svg); }
      .new-sub-side-menu-wrapper .sub-menu-content.active-sub-menu .agentReport {
        background-image: url(/static/media/agent-report-orange.7734bdce.svg); }
      .new-sub-side-menu-wrapper .sub-menu-content.active-sub-menu .clockInReport {
        background-image: url(/static/media/clock-in-report-orange.7b9d46dd.svg); }
      .new-sub-side-menu-wrapper .sub-menu-content.active-sub-menu .assignmentRules {
        background-image: url(/static/media/assignment-rules-orange.280bde96.svg); }
      .new-sub-side-menu-wrapper .sub-menu-content.active-sub-menu .topicReport {
        background-image: url(/static/media/topic-report-orange.f2a2ff56.svg); }
      .new-sub-side-menu-wrapper .sub-menu-content.active-sub-menu .messageApiHistory {
        background-image: url(/static/media/message-history-orange.f6e97629.svg); }
      .new-sub-side-menu-wrapper .sub-menu-content.active-sub-menu .utm {
        background-image: url(/static/media/utm-orange.1784e892.svg); }
      .new-sub-side-menu-wrapper .sub-menu-content.active-sub-menu .messageUserSummary {
        background-image: url(/static/media/message-user-summary-orange.4b9ba8e9.svg); }
      .new-sub-side-menu-wrapper .sub-menu-content.active-sub-menu .faq {
        background-image: url(/static/media/faq-orange.ab94993c.svg); }
      .new-sub-side-menu-wrapper .sub-menu-content.active-sub-menu .autoResolve {
        background-image: url(/static/media/auto-resolve-orange.56a13a65.svg); }
      .new-sub-side-menu-wrapper .sub-menu-content.active-sub-menu .autoUnassign {
        background-image: url(/static/media/auto-unassign-orange.b5ee08b0.svg); }
      .new-sub-side-menu-wrapper .sub-menu-content.active-sub-menu .contactInfoCustomField {
        background-image: url(/static/media/contact-info-custom-field-orange.a5ba85a0.svg); }
      .new-sub-side-menu-wrapper .sub-menu-content.active-sub-menu .settings {
        background-image: url(/static/media/inbox-settings-orange.b4e206d3.svg); }
      .new-sub-side-menu-wrapper .sub-menu-content.active-sub-menu .importContact {
        background-image: url(/static/media/import-contact-orange.d54adac9.svg); }
    .new-sub-side-menu-wrapper .sub-menu-content.integration-submenu {
      padding-left: 44px;
      margin-bottom: 4px; }
      .new-sub-side-menu-wrapper .sub-menu-content.integration-submenu .sub-menu-icon {
        width: 24px;
        height: 24px;
        left: 12px; }
        .new-sub-side-menu-wrapper .sub-menu-content.integration-submenu .sub-menu-icon.launcher {
          background-image: url(/static/media/logo-taptalk-side-menu.22f0587e.svg); }
        .new-sub-side-menu-wrapper .sub-menu-content.integration-submenu .sub-menu-icon.telegram {
          background-image: url(/static/media/logo-telegram-side-menu.2d411921.svg); }
        .new-sub-side-menu-wrapper .sub-menu-content.integration-submenu .sub-menu-icon.whatsapp {
          background-image: url(/static/media/logo-whatsapp-side-menu.ec40c90c.svg); }
        .new-sub-side-menu-wrapper .sub-menu-content.integration-submenu .sub-menu-icon.twitter {
          background-image: url(/static/media/logo-twitter-side-menu.d4717364.svg); }
        .new-sub-side-menu-wrapper .sub-menu-content.integration-submenu .sub-menu-icon.line {
          background-image: url(/static/media/logo-line-side-menu.eb902b33.svg); }
        .new-sub-side-menu-wrapper .sub-menu-content.integration-submenu .sub-menu-icon.apiKeys {
          background-image: url(/static/media/logo-api-keys-side-menu.e0ceb8c2.svg); }
        .new-sub-side-menu-wrapper .sub-menu-content.integration-submenu .sub-menu-icon.facebook {
          background-image: url(/static/media/logo-facebook-side-menu.39ae8a36.svg); }
        .new-sub-side-menu-wrapper .sub-menu-content.integration-submenu .sub-menu-icon.whatsappba {
          background-image: url(/static/media/logo-whatsapp-business.1e2ff0f8.svg); }
        .new-sub-side-menu-wrapper .sub-menu-content.integration-submenu .sub-menu-icon.instagram {
          background-image: url(/static/media/logo-instagram-side-menu.69a8af72.svg); }
        .new-sub-side-menu-wrapper .sub-menu-content.integration-submenu .sub-menu-icon.chatbots {
          background-image: url(/static/media/logo-chatbot-side-menu.f94c93d0.svg); }
        .new-sub-side-menu-wrapper .sub-menu-content.integration-submenu .sub-menu-icon.webhook2 {
          background-image: url(/static/media/logo-status-webhook.200e065e.svg); }
        .new-sub-side-menu-wrapper .sub-menu-content.integration-submenu .sub-menu-icon.salestalk {
          background-image: url(/static/media/logo-salestalk-side-menu.34f69f0a.svg); }
        .new-sub-side-menu-wrapper .sub-menu-content.integration-submenu .sub-menu-icon.webhook {
          background-image: url(/static/media/logo-webhook-side-menu.feb38beb.svg); }
        .new-sub-side-menu-wrapper .sub-menu-content.integration-submenu .sub-menu-icon.google_business {
          background-image: url(/static/media/logo-google-side-menu.693900d2.svg); }
        .new-sub-side-menu-wrapper .sub-menu-content.integration-submenu .sub-menu-icon.gb_profile {
          background-image: url(/static/media/logo-google-profile-side-menu.7911440d.svg); }
        .new-sub-side-menu-wrapper .sub-menu-content.integration-submenu .sub-menu-icon.channels {
          background-image: url(/static/media/logo-channel-side-menu.70f79026.svg); }
        .new-sub-side-menu-wrapper .sub-menu-content.integration-submenu .sub-menu-icon.tokopedia {
          background-image: url(/static/media/logo-tokopedia-side-menu.91491817.svg); }
        .new-sub-side-menu-wrapper .sub-menu-content.integration-submenu .sub-menu-icon.email {
          background-image: url(/static/media/logo-email-side-menu.ea319816.svg); }
        .new-sub-side-menu-wrapper .sub-menu-content.integration-submenu .sub-menu-icon.kataai {
          background-image: url(/static/media/logo-kataai-side-menu.c7b2ea0b.svg); }
        .new-sub-side-menu-wrapper .sub-menu-content.integration-submenu .sub-menu-icon.miitel {
          background-image: url(/static/media/logo-miitel-side-menu.febc870b.svg); }

.blocking-screen-page-role {
  position: fixed;
  top: 0;
  left: 304px;
  width: calc(100% - 304px);
  height: 100%;
  -webkit-transition: ease-in-out .1s;
  transition: ease-in-out .1s;
  background: #f8f8f8; }
  .blocking-screen-page-role.blocking-page-role-active-billing-warning {
    height: calc(100% - 32px);
    top: 32px; }
  .blocking-screen-page-role.deactive-sub-menu {
    width: calc(100% - 62px);
    left: 62px; }
  .blocking-screen-page-role .blocking-screen-page-role-wrapper {
    text-align: center;
    width: 412px;
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
    .blocking-screen-page-role .blocking-screen-page-role-wrapper p {
      margin: 15px 0 0; }
    .blocking-screen-page-role .blocking-screen-page-role-wrapper .blocking-screen-page-role-alert {
      font-size: 20px;
      letter-spacing: -0.6px;
      color: rgba(25, 25, 25, 0.6); }
    .blocking-screen-page-role .blocking-screen-page-role-wrapper button {
      height: 40px;
      line-height: 33px;
      display: inline-block;
      font-size: 16px;
      letter-spacing: -0.5px;
      padding: 0 24px;
      margin: 32px 0 0; }

.top-bar-blocking-wrapper {
  height: 32px;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  white-space: nowrap;
  text-align: center;
  background: #DCEBFF;
  color: #044B84;
  line-height: 32px;
  font-size: 16px;
  letter-spacing: -0.3px; }
  .top-bar-blocking-wrapper svg {
    position: relative;
    top: -2px;
    margin-right: 4px; }
  .top-bar-blocking-wrapper a {
    color: #044B84;
    text-decoration: underline !important; }

.tier-home-wrapper {
  position: fixed;
  top: 0;
  left: 340px;
  width: calc(100% - 340px);
  height: 100%;
  -webkit-transition: ease-in-out .1s;
  transition: ease-in-out .1s;
  background: #f8f8f8; }
  .tier-home-wrapper.tier-home-active-billing-warning {
    height: calc(100% - 32px);
    top: 32px; }
  .tier-home-wrapper.deactive-sub-menu {
    width: calc(100% - 62px);
    left: 62px; }
  .tier-home-wrapper .please-select-tier-wrapper {
    text-align: center;
    width: 550px;
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
    .tier-home-wrapper .please-select-tier-wrapper p {
      margin: 0; }
    .tier-home-wrapper .please-select-tier-wrapper .please-select-tier-alert {
      font-size: 20px;
      letter-spacing: -0.6px;
      color: #191919; }
      .tier-home-wrapper .please-select-tier-wrapper .please-select-tier-alert svg {
        margin-right: 8px;
        position: relative;
        top: -2px; }
    .tier-home-wrapper .please-select-tier-wrapper .please-select-tier-description {
      font-size: 14px;
      letter-spacing: -0.2px;
      color: rgba(25, 25, 25, 0.6);
      margin: 0 0 16px; }
      .tier-home-wrapper .please-select-tier-wrapper .please-select-tier-description a {
        color: rgba(25, 25, 25, 0.6); }
        .tier-home-wrapper .please-select-tier-wrapper .please-select-tier-description a :hover {
          color: #191919; }
    .tier-home-wrapper .please-select-tier-wrapper button {
      height: 48px;
      width: 100%;
      font-size: 16px;
      letter-spacing: -0.5px; }

.home-welcome-message {
  margin: 0;
  letter-spacing: -0.6px;
  font-size: 24px;
  color: #191919; }

.onboarding-wrapper {
  margin: 32px 0 12px 0; }

.section-home {
  width: 100%;
  display: inline-block;
  position: relative;
  vertical-align: top;
  margin-right: 12px;
  margin: 0 0 12px 0; }

.section-member-status {
  width: 350px;
  display: inline-block;
  position: relative;
  vertical-align: top; }
  .section-member-status .section-member-status-list {
    padding-left: 24px;
    position: relative;
    margin: 0 0 8px 0;
    width: 100%;
    display: block;
    font-size: 16px;
    letter-spacing: -0.5px; }
    .section-member-status .section-member-status-list .online-status-badge {
      width: 13px;
      height: 13px;
      border-radius: 50%;
      position: absolute;
      left: 0;
      top: 6px;
      background: #18BE00;
      -webkit-filter: drop-shadow(0px 8px 28px rgba(25, 25, 25, 0.18));
              filter: drop-shadow(0px 8px 28px rgba(25, 25, 25, 0.18)); }
    .section-member-status .section-member-status-list .away-status-badge {
      width: 13px;
      height: 13px;
      border-radius: 50%;
      position: absolute;
      left: 0;
      top: 6px;
      background: #E6B628;
      -webkit-filter: drop-shadow(0px 8px 28px rgba(25, 25, 25, 0.18));
              filter: drop-shadow(0px 8px 28px rgba(25, 25, 25, 0.18)); }
    .section-member-status .section-member-status-list p {
      margin: 0;
      color: rgba(25, 25, 25, 0.4);
      font-size: 10px;
      letter-spacing: 1.5px; }
  .section-member-status .see-more-status {
    padding: 8px 0 0 0;
    width: 100%;
    display: block;
    color: var(--main-dark-orange);
    cursor: pointer;
    text-align: right; }
    .section-member-status .see-more-status svg {
      position: relative;
      top: -2px; }
    .section-member-status .see-more-status:hover {
      color: var(--main-darker-orange); }

.home-info-wrapper {
  border-top: solid 1px rgba(25, 25, 25, 0.1);
  margin-top: 24px;
  padding-top: 28px; }
  .home-info-wrapper .home-info-alias {
    color: rgba(25, 25, 25, 0.8);
    font-size: 14px;
    margin: 0; }
  .home-info-wrapper .home-info-content {
    width: 25%;
    display: inline-block;
    padding-right: 25px;
    vertical-align: top;
    max-height: 70px; }
    .home-info-wrapper .home-info-content .home-info-image {
      padding: 6px;
      border-radius: 8px;
      box-shadow: 0 2px 20px 0 rgba(25, 25, 25, 0.05);
      display: inline-block;
      vertical-align: top;
      position: relative;
      top: 2px; }
    .home-info-wrapper .home-info-content .home-info-detail {
      width: calc(100% - 48px);
      display: inline-block;
      padding-left: 8px;
      position: relative; }
      .home-info-wrapper .home-info-content .home-info-detail .home-info-detail-title {
        font-size: 10px;
        color: rgba(25, 25, 25, 0.6);
        margin: 0;
        letter-spacing: 1.5px;
        line-height: 15px;
        white-space: nowrap;
        max-width: 100%;
        text-overflow: ellipsis;
        overflow: hidden; }
      .home-info-wrapper .home-info-content .home-info-detail .home-info-detail-value {
        letter-spacing: -0.5px;
        font-size: 16px;
        margin: 0;
        line-height: 18px;
        white-space: nowrap;
        max-width: 100%;
        text-overflow: ellipsis;
        overflow: hidden;
        line-height: 24px;
        position: relative;
        top: -2px; }
    .home-info-wrapper .home-info-content button {
      height: 22px;
      font-size: 12px;
      letter-spacing: -0.4px;
      border-radius: 4px;
      padding: 0 10px 0 8px !important;
      line-height: 19px;
      margin-top: 4px; }

.usage-info-wrapper {
  border-top: solid 1px rgba(25, 25, 25, 0.1);
  margin-top: 12px;
  padding-top: 24px;
  display: flex; }
  .usage-info-wrapper .home-usage-wrapper {
    width: 25%;
    padding-right: 12px; }
    .usage-info-wrapper .home-usage-wrapper .usage-icon {
      width: 32px;
      height: 32px;
      margin-bottom: 8px; }
    .usage-info-wrapper .home-usage-wrapper .usage-title {
      font-size: 14px;
      margin: 0; }
      .usage-info-wrapper .home-usage-wrapper .usage-title span {
        font-family: PTRootUI;
        color: rgba(25, 25, 25, 0.6); }
      .usage-info-wrapper .home-usage-wrapper .usage-title img {
        width: 13px;
        position: relative;
        top: -1px; }
    .usage-info-wrapper .home-usage-wrapper .data-not-available {
      color: rgba(25, 25, 25, 0.4);
      letter-spacing: 3px;
      margin: 0; }
      .usage-info-wrapper .home-usage-wrapper .data-not-available.data-not-available-1 {
        margin-top: 11px !important; }
    .usage-info-wrapper .home-usage-wrapper .data-loading {
      background: rgba(25, 25, 25, 0.05);
      height: 40px;
      margin: 0 0 8px 0;
      border-radius: 4px;
      width: calc(60% - 20px);
      height: 24px; }
      .usage-info-wrapper .home-usage-wrapper .data-loading.data-loading-1 {
        width: calc(100% - 20px);
        margin: 12px 0 8px 0;
        background: rgba(25, 25, 25, 0.1);
        height: 40px; }
    .usage-info-wrapper .home-usage-wrapper .usage-value {
      font-size: 40px;
      margin: 0; }
    .usage-info-wrapper .home-usage-wrapper .usage-per {
      font-size: 16px;
      color: rgba(25, 25, 25, 0.4);
      margin: 0; }

.usage-header {
  display: flex;
  justify-content: space-between;
  align-items: center; }
  .usage-header .usage-title {
    margin: 0;
    letter-spacing: -0.6px;
    font-size: 24px;
    color: #191919; }

.tooltip-mau .tooltip-inner {
  white-space: nowrap;
  max-width: 500px !important; }

.onboarding-wrapper {
  -webkit-transition: ease-in-out .1s;
  transition: ease-in-out .1s; }
  .onboarding-wrapper .onboarding-collapse-opener {
    position: relative;
    width: 100%;
    padding: 0 22px 12px 0;
    cursor: pointer; }
    .onboarding-wrapper .onboarding-collapse-opener .icon-arrow-collapse {
      position: absolute;
      right: 0;
      top: 50%;
      -webkit-transform: translate(0, -50%);
              transform: translate(0, -50%);
      -webkit-transition: ease-in-out .15s;
      transition: ease-in-out .15s;
      font-size: 20px; }
    .onboarding-wrapper .onboarding-collapse-opener:hover .icon-arrow-collapse {
      color: #ff7d00; }
    .onboarding-wrapper .onboarding-collapse-opener.active-collapse .icon-arrow-collapse {
      -webkit-transform: translate(0, -50%) rotate(180deg);
              transform: translate(0, -50%) rotate(180deg); }
  .onboarding-wrapper .onboarding-collapse-opener-1 {
    padding: 0 12px 12px 80px; }
    .onboarding-wrapper .onboarding-collapse-opener-1 .onboarding-collapse-opener-1-info {
      font-size: 16px;
      letter-spacing: -0.3px;
      color: rgba(25, 25, 25, 0.8);
      line-height: 1;
      display: inline-block;
      vertical-align: top; }
      .onboarding-wrapper .onboarding-collapse-opener-1 .onboarding-collapse-opener-1-info b {
        color: #191919;
        font-size: 24px;
        letter-spacing: -0.6px; }
      .onboarding-wrapper .onboarding-collapse-opener-1 .onboarding-collapse-opener-1-info p {
        line-height: 24px;
        margin: 12px 0 0; }
    .onboarding-wrapper .onboarding-collapse-opener-1 .onboarding-collapse-opener-1-image {
      margin-left: 24px; }
    .onboarding-wrapper .onboarding-collapse-opener-1 .onboarding-progress-bar {
      width: 56px;
      height: 56px;
      display: inline-block;
      position: absolute;
      left: 0;
      top: 0%;
      vertical-align: top; }
      .onboarding-wrapper .onboarding-collapse-opener-1 .onboarding-progress-bar p {
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
        color: rgba(25, 25, 25, 0.8);
        font-size: 16px;
        letter-spacing: -0.5px; }
    .onboarding-wrapper .onboarding-collapse-opener-1.active-collapse {
      border-bottom: solid 1px #dcdcdc; }
  .onboarding-wrapper .CircularProgressbar .CircularProgressbar-trail {
    stroke: #f3f3f3; }
  .onboarding-wrapper .CircularProgressbar .CircularProgressbar-path {
    stroke: #19C700; }
  .onboarding-wrapper .onboarding-collapse-all .onboarding-collapse-opener {
    padding: 13px 22px 13px 40px;
    border-bottom: solid 1px #dcdcdc;
    line-height: 24px;
    margin-top: 24px; }
    .onboarding-wrapper .onboarding-collapse-all .onboarding-collapse-opener.onboarding-collapse-opener-child {
      font-size: 20px;
      letter-spacing: -0.6px; }
      .onboarding-wrapper .onboarding-collapse-all .onboarding-collapse-opener.onboarding-collapse-opener-child .collapse-number {
        width: 26px;
        height: 26px;
        position: absolute;
        left: 0;
        top: 11px;
        text-align: center;
        border-radius: 50%;
        background: #f3f3f3;
        color: rgba(25, 25, 25, 0.8);
        font-size: 14px;
        display: inline-block; }
        .onboarding-wrapper .onboarding-collapse-all .onboarding-collapse-opener.onboarding-collapse-opener-child .collapse-number b {
          position: relative;
          top: 1px; }
        .onboarding-wrapper .onboarding-collapse-all .onboarding-collapse-opener.onboarding-collapse-opener-child .collapse-number img {
          vertical-align: top; }
        .onboarding-wrapper .onboarding-collapse-all .onboarding-collapse-opener.onboarding-collapse-opener-child .collapse-number.active-collapse-number {
          background: #ff7d00;
          color: #ffffff; }
  .onboarding-wrapper .onboarding-child-wrapper {
    background: #f3f3f3;
    padding: 24px 32px; }
    .onboarding-wrapper .onboarding-child-wrapper .alert-component {
      margin-bottom: 24px;
      font-size: 14px;
      letter-spacing: -0.4px;
      line-height: 21px;
      font-size: 14px;
      width: 100%;
      max-width: 500px;
      padding: 12px 24px 12px 48px; }
      .onboarding-wrapper .onboarding-child-wrapper .alert-component svg {
        -webkit-transform: unset;
                transform: unset;
        top: 10px; }
      .onboarding-wrapper .onboarding-child-wrapper .alert-component .text-alert {
        color: #044B84 !important; }
    .onboarding-wrapper .onboarding-child-wrapper .onboarding-child-image {
      display: block; }
    .onboarding-wrapper .onboarding-child-wrapper .onboarding-description {
      margin: 24px 0;
      max-width: 400px;
      width: 100%; }
    .onboarding-wrapper .onboarding-child-wrapper .button-wrapper button {
      margin-right: 8px; }
      .onboarding-wrapper .onboarding-child-wrapper .button-wrapper button.mark-as-undone {
        border: none !important;
        background: none !important;
        padding: 0 16px !important; }
        .onboarding-wrapper .onboarding-child-wrapper .button-wrapper button.mark-as-undone:hover {
          color: var(--main-darker-orange) !important; }

.onboarding-complete-modal .modal-content {
  width: 477px; }

.onboarding-complete-modal .modal-body {
  padding: 32px; }
  .onboarding-complete-modal .modal-body .onboarding-complete-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center; }
    .onboarding-complete-modal .modal-body .onboarding-complete-wrapper img {
      margin-bottom: 8px; }
    .onboarding-complete-modal .modal-body .onboarding-complete-wrapper .modal-title {
      font-size: 24px;
      line-height: 28px;
      text-align: center;
      letter-spacing: -0.6px;
      margin-bottom: 8px; }
    .onboarding-complete-modal .modal-body .onboarding-complete-wrapper .modal-description {
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      letter-spacing: -0.3px;
      margin-bottom: 16px; }
    .onboarding-complete-modal .modal-body .onboarding-complete-wrapper .btn-complete {
      width: 100%; }




.integration-wrapper {
  width: 100%;
  position: relative; }
  .integration-wrapper .integration-card {
    width: calc((100% / 3) - 6px);
    margin-right: 9px;
    border-radius: 8px;
    background: #ffffff;
    padding: 24px;
    text-align: center;
    box-shadow: 0px 2px 20px 0px #191919 5%;
    display: inline-block;
    vertical-align: top; }
    .integration-wrapper .integration-card:nth-last-child(1) {
      margin-right: 0; }
    .integration-wrapper .integration-card .intergration-card-label {
      border-radius: 6px;
      color: #ffffff;
      display: inline-block;
      font-size: 12px;
      letter-spacing: -0.4px;
      padding: 0 8px;
      line-height: 1;
      line-height: 18px; }
    .integration-wrapper .integration-card .intergration-card-image {
      margin: 26px 0 24px; }
    .integration-wrapper .integration-card .intergration-card-name {
      color: #191919;
      font-size: 16px;
      letter-spacing: -0.5px; }

.modal-assistance .modal-content {
  width: 601px;
  border-radius: 8px; }
  .modal-assistance .modal-content .modal-body {
    padding: 32px; }
    .modal-assistance .modal-content .modal-body p {
      color: #191919;
      font-size: 20px;
      letter-spacing: -0.6px;
      margin: 0 0 20px 0; }
      .modal-assistance .modal-content .modal-body p svg {
        font-size: 20px;
        position: relative;
        top: -2px;
        margin-right: 4px; }
    .modal-assistance .modal-content .modal-body ol {
      margin: 0;
      padding: 0 0 0 18px; }
      .modal-assistance .modal-content .modal-body ol li {
        color: #000000;
        font-size: 14px;
        letter-spacing: -0.2px;
        line-height: 21px;
        margin: 0 0 12px 0;
        padding-left: 12px; }
        .modal-assistance .modal-content .modal-body ol li img {
          position: relative;
          vertical-align: top;
          top: -1px; }
    .modal-assistance .modal-content .modal-body button {
      margin: 12px 0 0; }


.this-col-not-found.tcRow:hover {
  background: none ! important;
}

.this-col-not-found .no-data-found {
  margin: "0 ! important"; 
  text-align: "center"; 
  color: "#a6a6a6 ! important"; 
  background: "none ! important"; 
  padding: "24px 1px 0"; 
  border: "none ! important";
}

.topic {
  position: relative;
}

.form-topic-detail .formContent {
  position: unset;
}

.form-topic-detail label {
  margin: 0 0 8px;
}

.form-topic-detail .input-wrapper {
  padding: 0;
  margin: 0;
}

.assigned-agent-content {
  margin: 24px auto !important;
}

.action-button svg {
  position: relative;
  top: -1px;
}

.form-topic-detail .orange-button {
  margin-top: 12px;
}

.form-topic-detail .pos-relative {
  width: 350px;
}

.form-topic-detail span {
  font-weight: normal;
}

.assigned-agent-content td {
  word-break: break-word;
}

.assigned-agent-content .tableContent {
  margin: 24px 0 0 !important;
}

.topContent-topic-detail {
  padding-top: 0;
  padding-bottom: 18px;
}

.title-topic-details {
  width: 100%;
  position: relative;
  display: block;
  padding-top: 6px;
}

.title-topic-details .button-delete-topic {
  position: absolute;
  right: 184px;
  top: 0;
  width: 180px;
}

.button-save-topic {
  right: 32px;
  top: 20px;
  position: absolute;
  width: 180px;
}
.search-box-wrapper {
  position: relative;
  width: 343px;
  position: relative;
  display: inline-block; }
  .search-box-wrapper input {
    padding: 11px 76px 11px 16px;
    font-size: 16px;
    border: solid 1px #dcdcdc;
    border-radius: 8px;
    width: 100% !important;
    letter-spacing: -0.3px; }
  .search-box-wrapper .icon-remove-search {
    position: absolute !important;
    cursor: pointer;
    right: 40px !important;
    top: 50% !important;
    -webkit-transform: translate(0, -50%) !important;
            transform: translate(0, -50%) !important; }

#search-icon {
  width: 38px;
  height: 48px;
  line-height: 1;
  position: absolute;
  right: 0;
  top: 0;
  display: inline-block;
  text-align: center;
  /* background: rgba(25, 25, 25, 0.1); */
  border-radius: 0 8px 8px 0;
  /* border-left: solid 1px #dcdcdc; */ }
  #search-icon svg {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    color: rgba(25, 25, 25, 0.6); }

.search-box-wrapper input:focus {
  border: solid 1px var(--main-dark-orange); }
  .search-box-wrapper input:focus + #search-icon {
    /* border-left: solid 1px var(--main-dark-orange); */ }
    .search-box-wrapper input:focus + #search-icon svg {
      /* color: var(--main-dark-orange) !important; */ }

.search-box-wrapper input::-webkit-input-placeholder {
  color: #9c9c9c; }

.search-box-wrapper input::-ms-input-placeholder {
  color: #9c9c9c; }

.search-box-wrapper input::placeholder {
  color: #9c9c9c; }

.newButton {
  -moz-appearance: none;
       appearance: none;
  -webkit-appearance: none;
  position: relative;
  letter-spacing: -0.5px;
  font-size: 16px;
  vertical-align: baseline;
}

.newButton__icon {
  margin-right: 4px;
}

.newButton.outline-orange {
  background: #ffffff !important;
  color: var(--main-dark-orange) !important;
}

.newButton:disabled {
  background: rgba(0, 0, 0, 0.1) !important;
  color: rgba(0, 0, 0, 0.4) !important;
}

.newButton svg {
  position: relative;
  top: -1px;
}

.input-wrapperr {
  margin: 0 !important; }

.button-flexx {
  margin-top: 24px !important; }

.roles-list-title {
  color: #191919;
  font-size: 20px;
  letter-spacing: -0.6px;
  line-height: 24px;
  padding-bottom: 16px;
  border-bottom: solid 1px rgba(25, 25, 25, 0.1);
  position: relative;
  padding-top: 10px; }
  .roles-list-title .button-create-role {
    position: absolute;
    right: 0;
    top: 0;
    width: 181px; }

.roles-list-tool-wrapper {
  width: 100%;
  position: relative;
  margin-top: 12px;
  margin-bottom: 24px; }

.roles-list-tool-wrapper button.button-create-role {
  position: absolute;
  right: 0;
  top: 50%;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%);
  /* padding: 11px 0; */
  /* width: 200px; */ }

/* .roles-list-tool-wrapper button.button-create-role svg {
    position: relative;
    top: -1px;
    margin-right: 5px;
} */
.roles-list-table-wrapper {
  width: 100%;
  position: relative; }

.table-roles-list thead .dropdown {
  width: 74px;
  height: 30px; }
  .table-roles-list thead .dropdown button {
    position: relative;
    height: 30px;
    line-height: 1; }
  .table-roles-list thead .dropdown svg {
    position: absolute !important;
    top: 15px !important;
    right: 6px !important;
    font-size: 18px; }

.table-roles-list thead .custom-select-option-value-wrapper {
  height: 30px;
  padding: 0 4px 0 20px !important; }
  .table-roles-list thead .custom-select-option-value-wrapper .custom-select-option-value {
    padding: 0; }
  .table-roles-list thead .custom-select-option-value-wrapper svg {
    right: 10px; }

.table-roles-list thead tr {
  background: #ffffff; }
  .table-roles-list thead tr .custom-checkbox-stripe [type="checkbox"]:checked + label:after {
    top: 2px;
    left: 0;
    height: 16px;
    width: 16px; }
  .table-roles-list thead tr td.col_full_name {
    width: 35%;
    position: relative !important;
    padding: 8px !important; }
    .table-roles-list thead tr td.col_full_name .custom-checkbox {
      position: relative !important;
      top: -7px !important;
      left: 0 !important; }
    .table-roles-list thead tr td.col_full_name .custom-checkbox-stripe {
      position: relative !important;
      top: -7px !important;
      left: 0 !important; }
    .table-roles-list thead tr td.col_full_name .custom-checkbox-stripe [type="checkbox"]:checked + label:after {
      width: 16px;
      height: 16px; }
  .table-roles-list thead tr td.col_role {
    width: 120px !important; }
  .table-roles-list thead tr td.col_name {
    width: 400px !important;
    white-space: nowrap;
    max-width: 400px !important;
    text-overflow: ellipsis;
    overflow: hidden; }
  .table-roles-list thead tr td.col_desc {
    width: 400px !important;
    white-space: nowrap;
    max-width: 400px !important;
    text-overflow: ellipsis;
    overflow: hidden; }
  .table-roles-list thead tr td.col_alias {
    width: 30%; }
  .table-roles-list thead tr td.col_email {
    width: 35%; }

.table-roles-list tbody td {
  background: #ffffff;
  position: relative;
  word-break: break-word; }
  .table-roles-list tbody td.col_role {
    width: 120px !important;
    text-align: left; }
  .table-roles-list tbody td.col_name {
    width: 400px !important;
    white-space: nowrap;
    max-width: 400px !important;
    text-overflow: ellipsis;
    overflow: hidden; }
  .table-roles-list tbody td.col_desc {
    width: 400px !important;
    white-space: nowrap;
    max-width: 400px !important;
    text-overflow: ellipsis;
    overflow: hidden; }
  .table-roles-list tbody td .custom-checkbox {
    position: relative !important;
    top: -7px !important;
    left: 0 !important; }

.table-roles-list button svg {
  position: relative !important;
  right: unset !important;
  top: -2px !important;
  margin-right: 5px; }

.table-roles-list .single-remove-button {
  margin-left: 16px;
  color: #ff3f57; }

.table-roles-list .single-remove-button:hover {
  color: #e83b51; }

.table-roles-list .col-action-button-wrapper .view-agent {
  color: #ff7d00;
  letter-spacing: -0.5px; }

.table-roles-list .col-action-button-wrapper .view-agent:hover {
  color: var(--main-darker-orange); }

.table-roles-list .col-action-button-wrapper img.view-agent {
  margin-right: 3px;
  position: relative;
  top: -2px; }

.table-roles-list tbody tr:hover .col-action-button-wrapper {
  /* display: block; */ }

.table-roles-list .no-data-tr {
  border-bottom: none; }

.table-roles-list .no-data-column {
  padding-top: 24px;
  letter-spacing: -0.5px;
  font-size: 16px; }

.table-roles-list tbody tr:hover {
  background: #f8f8f8 !important; }

.table-roles-list .resent-invitation {
  background: none;
  border: 0;
  color: #ff7d00;
  letter-spacing: -0.5px;
  padding: 0; }

.table-roles-list .resent-invitation img {
  position: relative;
  top: -2px; }

.table-roles-list .resent-invitation:hover {
  color: var(--main-darker-orange); }

.table-roles-list .resend-invitation-loading {
  width: 150px; }

.table-roles-list .resend-invitation-loading .lds-ring {
  top: 3px;
  right: unset; }

.table-roles-list .resend-invitation-loading .lds-ring div {
  margin: 0; }

.roles-tool-wrapper {
  width: 460px; }

.modal-invitation-sent .modal-content {
  width: 476px;
  border-radius: 8px; }
  .modal-invitation-sent .modal-content .modal-body {
    padding: 32px 24px;
    text-align: center; }
    .modal-invitation-sent .modal-content .modal-body img {
      margin: 0 0 8px 0; }
    .modal-invitation-sent .modal-content .modal-body b {
      font-size: 20px; }
    .modal-invitation-sent .modal-content .modal-body p {
      margin: 8px 0 20px;
      font-size: 14px;
      letter-spacing: 21px;
      letter-spacing: -.2px; }
    .modal-invitation-sent .modal-content .modal-body button {
      height: 40px;
      width: 100%; }

.create-role-wrapper .form-title-wrapper {
  position: relative; }
  .create-role-wrapper .form-title-wrapper button {
    position: absolute;
    right: 0;
    top: -7px;
    width: 180px; }

.create-role-wrapper label {
  width: 400px;
  font-size: 16px;
  letter-spacing: -0.5px; }

.create-role-wrapper input[type="text"], .create-role-wrapper textarea {
  width: 400px; }

.create-role-wrapper .form-roles-create button {
  width: inherit; }

.create-role-wrapper .role-info {
  font-size: 14px;
  letter-spacing: -0.2px;
  color: rgba(25, 25, 25, 0.8);
  margin: 0 0 2px; }
  .create-role-wrapper .role-info.role-info-24 {
    margin-bottom: 24px; }

.create-role-wrapper .role-list-wrapper {
  margin-top: 0;
  width: 100%; }
  .create-role-wrapper .role-list-wrapper label {
    margin: 0 0 16px;
    width: unset; }
  .create-role-wrapper .role-list-wrapper [type="checkbox"]:not(:checked) + label:before {
    top: 4px; }
  .create-role-wrapper .role-list-wrapper [type="checkbox"]:checked + label:before {
    top: 4px; }
  .create-role-wrapper .role-list-wrapper [type="checkbox"]:checked + label:after {
    top: -1px; }
  .create-role-wrapper .role-list-wrapper [type="checkbox"]:checked:disabled + label:after {
    background-size: 22px 22px !important;
    top: -1px !important;
    left: -3px !important; }

.create-role-wrapper .main-role {
  padding: 0 0 0 8px; }
  .create-role-wrapper .main-role label {
    padding-left: 28px !important;
    display: inline-block !important; }

.create-role-wrapper .custom-checkbox-child-wrapper {
  padding: 0 0 0 36px; }
  .create-role-wrapper .custom-checkbox-child-wrapper .custom-checkbox-child {
    padding: 8px;
    border-radius: 6px;
    background: #fafafa;
    display: inline-block;
    min-width: 180px;
    margin: 0 8px 16px 0; }
    .create-role-wrapper .custom-checkbox-child-wrapper .custom-checkbox-child label {
      margin: 0;
      width: unset;
      font-size: 14px;
      letter-spacing: -0.2px; }
    .create-role-wrapper .custom-checkbox-child-wrapper .custom-checkbox-child [type="checkbox"]:not(:checked) + label:before {
      top: 2px; }
    .create-role-wrapper .custom-checkbox-child-wrapper .custom-checkbox-child [type="checkbox"]:checked + label:after {
      top: -3px; }
    .create-role-wrapper .custom-checkbox-child-wrapper .custom-checkbox-child [type="checkbox"]:checked + label:before {
      top: 2px; }

#role-detail-wrapper {
  position: relative;
  width: 100%; }

.role-detail-title {
  font-size: 20px;
  letter-spacing: -0.6px;
  color: #191919;
  margin: 0 0 32px;
  border-bottom: solid 1px #dcdcdc;
  padding-bottom: 24px;
  position: relative; }
  .role-detail-title .detail-title-text {
    position: relative;
    top: 6px; }
  .role-detail-title button {
    position: absolute;
    right: 0;
    min-width: 181px; }
    .role-detail-title button.delete-role-button {
      right: 188px; }

.role-detail-avatar {
  width: 72px;
  vertical-align: top;
  display: inline-block;
  margin-right: 36px; }

#role-value-wrapper {
  width: calc(100% - 108px);
  display: inline-block; }

.role-value-content {
  margin-bottom: 24px; }

.role-value-content img {
  width: 16px;
  margin-right: 15px;
  display: inline-block;
  vertical-align: top;
  position: relative;
  /* top: 12px; */
  top: 0; }

.role-value-content .role-info-wrapper {
  width: calc(100% - 31px);
  display: inline-block;
  line-height: 1; }
  .role-value-content .role-info-wrapper .permission-wrapper {
    width: 418px;
    border-radius: 8px;
    background: #fafafa;
    padding: 16px; }
  .role-value-content .role-info-wrapper p {
    color: rgba(25, 25, 25, 0.8);
    position: relative; }
    .role-value-content .role-info-wrapper p svg {
      position: relative;
      margin-right: 6px;
      font-size: 18px;
      top: -1px; }
    .role-value-content .role-info-wrapper p:nth-last-child(1) {
      margin: 0; }

.role-value-content .role-info-wrapper label {
  margin-bottom: 8px;
  font-size: 16px;
  letter-spacing: -0.5px;
  color: #191919; }
  .role-value-content .role-info-wrapper label svg {
    position: relative;
    margin-left: 6px;
    font-size: 16px;
    color: var(--main-dark-orange);
    cursor: pointer;
    top: -2px; }
    .role-value-content .role-info-wrapper label svg:hover {
      color: var(--main-darker-orange); }

.role-value-content .role-info-wrapper .role-detail-value {
  display: block;
  /* margin-bottom: 16px; */
  font-size: 16px;
  letter-spacing: -0.3px;
  color: #191919;
  margin: 0;
  width: 400px;
  line-height: 21px;
  word-break: break-word; }

.role-value-content .role-info-wrapper .access-to-inbox-label {
  letter-spacing: -0.2px;
  font-size: 14px;
  color: rgba(25, 25, 25, 0.6);
  margin: 4px 0 0; }

.role-value-content:nth-last-child(1) {
  border: none;
  margin-bottom: 0; }

.role-value-content:nth-last-child(1) b {
  margin-bottom: 0; }

#role-value-wrapper .role-value-content {
  position: relative; }

#role-value-wrapper button {
  font-size: 12px;
  letter-spacing: -0.4px;
  border-radius: 4px;
  padding: 4px 10px;
  margin-top: 14px; }
  #role-value-wrapper button img {
    width: 12px;
    height: 12px;
    margin-right: 4px; }

#role-value-wrapper .icon-wrapper {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0; }
  #role-value-wrapper .icon-wrapper img {
    position: absolute;
    top: 50%;
    left: 50%;
    margin: 0;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  #role-value-wrapper .icon-wrapper.icon-email {
    background: #E7E5FF; }
  #role-value-wrapper .icon-wrapper.icon-name {
    background: #FFF9E5; }
  #role-value-wrapper .icon-wrapper.icon-alias {
    background: #E9F5FD; }
  #role-value-wrapper .icon-wrapper.icon-role {
    background: #E5FFEE; }

.role-detail-edit-topic {
  font-size: 16px;
  letter-spacing: -0.5px;
  padding: 7px 30px;
  position: absolute;
  top: 0;
  right: 0; }
  .role-detail-edit-topic img {
    margin-right: 4px;
    width: 16px;
    position: relative;
    top: -2px; }

.topic-list-wrapper {
  position: relative;
  width: 100%;
  margin-top: 24px; }
  .topic-list-wrapper .topic-list-content {
    color: rgba(25, 25, 25, 0.6);
    font-size: 12px;
    letter-spacing: -.4px;
    border-radius: 4px;
    background: rgba(25, 25, 25, 0.05);
    margin: 0 4px 8px 0;
    display: inline-block;
    padding: 2px 8px;
    vertical-align: top; }

.topic-no-list {
  width: 100%;
  position: relative;
  height: 140px;
  text-align: center;
  color: rgba(25, 25, 25, 0.8);
  padding-top: 40px; }
  .topic-no-list .topic-no-list-icon-wrapper {
    width: 41px;
    height: 41px;
    display: inline-block;
    border-radius: 50%;
    position: relative;
    margin-bottom: 20px;
    background: #F3F3F3; }
    .topic-no-list .topic-no-list-icon-wrapper img {
      width: 24px;
      position: relative;
      top: 6px;
      display: inline-block; }

.modal-edit-aliass .modal-content {
  width: 650px;
  border-radius: 8px; }
  .modal-edit-aliass .modal-content .modal-body {
    padding: 44px 24px; }
    .modal-edit-aliass .modal-content .modal-body .modal-title {
      font-size: 20px;
      letter-spacing: -0.6px;
      width: 100%;
      position: relative; }
    .modal-edit-aliass .modal-content .modal-body input {
      margin-top: 8px;
      padding: 12px 16px;
      font-size: 16px;
      letter-spacing: -0.3px;
      width: 100%;
      border-radius: 6px;
      border: solid 1px #dcdcdc; }
      .modal-edit-aliass .modal-content .modal-body input:focus {
        border: solid 1px var(--main-dark-orange); }
    .modal-edit-aliass .modal-content .modal-body .counter-alias {
      position: absolute;
      top: 2px;
      right: 0;
      font-size: 16px; }
    .modal-edit-aliass .modal-content .modal-body button {
      width: calc(50% - 2px);
      margin: 24px 4px 0 0; }
      .modal-edit-aliass .modal-content .modal-body button:nth-last-child(1) {
        margin: 24px 0 0; }

.modal-role-change-role .modal-content {
  padding: 32px;
  border-radius: 8px;
  width: 500px; }

.modal-role-change-role .modal-body {
  padding: 0; }

.modal-role-change-role .modal-footer {
  border: 0;
  padding: 0; }

.modal-role-change-role .modal-footer button {
  width: calc(50% - 4px);
  margin: 0 8px 0 0;
  padding: 10px 0 12px;
  position: relative;
  height: 48px; }

.modal-role-change-role .modal-footer button:nth-child(2) {
  margin-right: 0; }

.modal-role-change-role .modal-role-change-role-title {
  border-bottom: solid 1px #dcdcdc;
  margin: 0 0 18px;
  padding-bottom: 16px;
  font-size: 20px;
  letter-spacing: -0.6px; }

.modal-role-change-role .modal-role-role-wrapper {
  margin-bottom: 36px; }

.modal-role-change-role .modal-role-role-wrapper b {
  font-size: 16px;
  letter-spacing: -0.5px;
  color: #191919; }

.modal-role-change-role .modal-role-role-wrapper span {
  font-size: 14px;
  letter-spacing: -0.2px;
  color: rgba(25, 25, 25, 0.6);
  display: block; }

.modal-role-change-role .change-role-gap {
  height: 24px;
  width: 100%; }

.form-title-wrapper.assigned-topic-member {
  display: flex;
  justify-content: space-between; }
  .form-title-wrapper.assigned-topic-member button {
    font-size: 16px; }

.role-member-bottom {
  position: relative;
  margin-bottom: 12px; }
  .role-member-bottom .button-add-member {
    position: absolute;
    top: -2px;
    right: 0; }

.role-member-bottom-2 {
  width: 100%; }
  .role-member-bottom-2 table {
    margin: 24px 0 0; }
    .role-member-bottom-2 table .col-action-button-wrapper img {
      position: relative;
      top: -2px;
      margin-right: 4px; }
    .role-member-bottom-2 table.table-roles-list-member-detail thead td.col_full_name {
      width: unset !important; }
    .role-member-bottom-2 table.table-roles-list-member-detail thead td.col_alias {
      width: unset !important; }
    .role-member-bottom-2 table.table-roles-list-member-detail thead td.col_email {
      width: unset !important; }
    .role-member-bottom-2 table.table-roles-list-member-detail thead td.col_remove {
      width: 100px !important; }
    .role-member-bottom-2 table.table-roles-list-member-detail tbody .col_full_name_body {
      padding: 8px 8px 8px 44px; }
      .role-member-bottom-2 table.table-roles-list-member-detail tbody .col_full_name_body .custom-checkbox {
        position: absolute !important;
        top: 1px !important;
        left: 8px !important; }
    .role-member-bottom-2 table.table-roles-list-member-detail tbody .col_remove {
      cursor: pointer; }
      .role-member-bottom-2 table.table-roles-list-member-detail tbody .col_remove img {
        width: 16px;
        position: relative;
        top: -1px;
        margin-right: 4px; }

.button-invite-member-save {
  position: absolute;
  top: 32px;
  right: 30px;
  width: 181px; }

.sectionWrap-add-role-members {
  margin-top: 90px;
  width: calc(100% - 328px);
  display: inline-block;
  margin-right: 16px; }

.member-list-add-member-selected {
  margin-top: 90px;
  vertical-align: top;
  width: calc(312px);
  display: inline-block;
  background: #fff;
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 32px; }
  .member-list-add-member-selected .member-list-add-member-selected-head {
    padding-bottom: 12px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    font-size: 20px;
    letter-spacing: -0.6px; }
  .member-list-add-member-selected .member-list-add-member-selected-body .member-list-add-member-selected-body-no-data {
    text-align: center;
    color: rgba(25, 25, 25, 0.4);
    padding: 24px 0 0; }
    .member-list-add-member-selected .member-list-add-member-selected-body .member-list-add-member-selected-body-no-data p {
      width: 100%;
      margin: 20px 0 0; }

.back-button-add-role-member {
  position: absolute;
  top: 0;
  left: 32px; }

.member-list-add-member-title {
  color: #191919;
  font-size: 20px;
  letter-spacing: -0.6px;
  line-height: 24px;
  padding-bottom: 8px;
  border-bottom: solid 1px rgba(25, 25, 25, 0.1);
  position: relative;
  padding-top: 10px; }
  .member-list-add-member-title .button-invite-member {
    position: absolute;
    right: 0;
    top: 0; }
  .member-list-add-member-title p {
    margin: 2px 0 0;
    font-size: 14px;
    letter-spacing: -0.2px; }

.member-list-add-member-tool-wrapper {
  width: 100%;
  position: relative;
  margin-top: 12px;
  margin-bottom: 24px; }
  .member-list-add-member-tool-wrapper .dropdown {
    height: 30px;
    width: 74px;
    position: absolute;
    top: -38px;
    right: 0; }
    .member-list-add-member-tool-wrapper .dropdown button {
      padding: 0 4px 0 20px !important;
      border-radius: 4px !important;
      height: 30px; }
      .member-list-add-member-tool-wrapper .dropdown button svg {
        font-size: 18px;
        right: 12px !important;
        position: absolute;
        top: 15px !important; }

.member-list-add-member-tool-wrapper button.button-invite-member {
  position: absolute;
  right: 0;
  top: 50%;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%);
  /* padding: 11px 0; */
  /* width: 200px; */ }

/* .member-list-add-member-tool-wrapper button.button-invite-member svg {
    position: relative;
    top: -1px;
    margin-right: 5px;
} */
.member-list-add-member-table-wrapper {
  width: 100%;
  position: relative; }

.table-member-list-add-member thead tr {
  background: #ffffff; }
  .table-member-list-add-member thead tr .custom-checkbox-stripe [type="checkbox"]:checked + label:after {
    top: 2px;
    left: 0; }
  .table-member-list-add-member thead tr td:nth-child(1) {
    padding-left: 44px !important; }
  .table-member-list-add-member thead tr td.col_full_name {
    width: 35%;
    position: relative !important;
    padding: 8px 8px 8px 44px !important; }
    .table-member-list-add-member thead tr td.col_full_name .custom-checkbox {
      position: absolute !important;
      top: 1px !important;
      left: 12px !important; }
    .table-member-list-add-member thead tr td.col_full_name .custom-checkbox-stripe {
      position: absolute !important;
      top: 1px !important;
      left: 12px !important; }
    .table-member-list-add-member thead tr td.col_full_name .custom-checkbox-stripe [type="checkbox"]:checked + label:after {
      width: 16px;
      height: 16px; }
  .table-member-list-add-member thead tr td.col_full_name {
    width: unset !important; }
  .table-member-list-add-member thead tr td.col_alias {
    width: 200px; }
  .table-member-list-add-member thead tr td.col_email {
    width: 200px; }
  .table-member-list-add-member thead tr td.col_role {
    width: 145px !important; }

.table-member-list-add-member tbody td {
  background: #ffffff;
  position: relative;
  word-break: break-word; }
  .table-member-list-add-member tbody td:nth-child(1) {
    padding-left: 44px !important; }
  .table-member-list-add-member tbody td.col_full_name {
    width: unset !important; }
  .table-member-list-add-member tbody td.col_alias {
    width: 200px; }
  .table-member-list-add-member tbody td.col_email {
    width: 200px; }
  .table-member-list-add-member tbody td.col_role {
    width: 145px !important; }

.table-member-list-add-member button svg {
  position: relative !important;
  right: unset !important;
  top: -2px !important;
  margin-right: 5px; }

.table-member-list-add-member .col-action-button-wrapper {
  position: absolute;
  right: 7px;
  top: 50%;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%);
  /* display: none; */ }

.table-member-list-add-member .single-remove-button {
  margin-left: 16px;
  color: #ff3f57; }

.table-member-list-add-member .single-remove-button:hover {
  color: #e83b51; }

.table-member-list-add-member .col-action-button-wrapper .view-agent {
  color: #ff7d00;
  letter-spacing: -0.5px; }

.table-member-list-add-member .col-action-button-wrapper .view-agent:hover {
  color: var(--main-darker-orange); }

.table-member-list-add-member .col-action-button-wrapper img.view-agent {
  margin-right: 3px;
  position: relative;
  top: -1px; }

.table-member-list-add-member tbody tr:hover .col-action-button-wrapper {
  /* display: block; */ }

.table-member-list-add-member .no-data-tr {
  border-bottom: none; }

.table-member-list-add-member .no-data-column {
  padding-top: 24px;
  letter-spacing: -0.5px;
  font-size: 16px; }

.table-member-list-add-member tbody tr:hover {
  background: #f8f8f8 !important; }

.table-member-list-add-member .resent-invitation {
  background: none;
  border: 0;
  color: #ff7d00;
  letter-spacing: -0.5px;
  padding: 0; }

.table-member-list-add-member .resent-invitation img {
  position: relative;
  top: -2px; }

.table-member-list-add-member .resent-invitation:hover {
  color: var(--main-darker-orange); }

.table-member-list-add-member .resend-invitation-loading {
  width: 150px; }

.table-member-list-add-member .resend-invitation-loading .lds-ring {
  top: 3px;
  right: unset; }

.table-member-list-add-member .resend-invitation-loading .lds-ring div {
  margin: 0; }

.member-tool-wrapper {
  width: 460px; }

.modal-confirmation-change-role .modal-content button {
  width: calc(50% - 4px) !important;
  margin: 0 8px 0 0; }
  .modal-confirmation-change-role .modal-content button:nth-last-child(1) {
    margin: 0; }

.modal-confirmation .modal-content {
  box-sizing: border-box;
  width: 476px;
  padding: 0 32px;
  border-radius: 8px; }

.modal-confirmation .modal-body {
  font-size: 14px;
  letter-spacing: -0.2px;
  text-align: left;
  color: #9b9b9b;
  /* padding-top: 30px; */
  padding: 32px 0 12px; }
  .modal-confirmation .modal-body p.confirmation-description {
    margin: 4px 0 0;
    font-size: 14px;
    letter-spacing: -0.2px;
    color: rgba(25, 25, 25, 0.6);
    line-height: 21px; }
  .modal-confirmation .modal-body b.confirmation-title {
    font-size: 20px;
    letter-spacing: -0.6px;
    color: #191919;
    line-height: 24px; }
    .modal-confirmation .modal-body b.confirmation-title img {
      position: relative;
      top: -2px;
      margin-right: 4px; }
    .modal-confirmation .modal-body b.confirmation-title svg {
      position: relative;
      top: -2px;
      margin-right: 4px; }

.modal-confirmation .modal-footer {
  padding: 0 0 32px;
  border: none;
  display: block; }
  .modal-confirmation .modal-footer > * {
    margin: unset; }
  .modal-confirmation .modal-footer button {
    vertical-align: top; }
    .modal-confirmation .modal-footer button.cancel-button {
      width: 128px;
      margin: 0 4px 0 0; }
    .modal-confirmation .modal-footer button.confirmation-button {
      margin: 0;
      width: calc(100% - 132px);
      display: inline-block; }


.member-list-title {
  color: #191919;
  font-size: 20px;
  letter-spacing: -0.6px;
  line-height: 24px;
  padding-bottom: 16px;
  border-bottom: solid 1px rgba(25, 25, 25, 0.1);
  position: relative;
  padding-top: 10px; }
  .member-list-title .button-invite-member {
    position: absolute;
    right: 0;
    top: 0; }

.member-list-tool-wrapper {
  width: 100%;
  position: relative;
  margin-top: 24px;
  margin-bottom: 24px; }

.member-list-tool-wrapper button.button-invite-member {
  position: absolute;
  right: 0;
  top: 50%;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%);
  /* padding: 11px 0; */
  /* width: 200px; */ }

/* .member-list-tool-wrapper button.button-invite-member svg {
    position: relative;
    top: -1px;
    margin-right: 5px;
} */
.member-list-table-wrapper {
  width: 100%;
  position: relative; }

.table-member-list thead tr {
  background: #ffffff; }
  .table-member-list thead tr .custom-checkbox-stripe [type="checkbox"]:checked + label:after {
    top: 2px;
    left: 0; }
  .table-member-list thead tr td:nth-child(1) {
    padding-left: 34px !important; }

.table-member-list tbody td {
  background: #ffffff;
  position: relative;
  word-break: break-word; }
  .table-member-list tbody td:nth-child(1) {
    padding-left: 34px !important; }

.table-member-list button svg {
  position: relative !important;
  right: unset !important;
  top: -2px !important;
  margin-right: 5px; }

.table-member-list .col-action-button-wrapper {
  position: absolute;
  right: 7px;
  top: 50%;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%);
  /* display: none; */ }

.table-member-list .single-remove-button {
  margin-left: 16px;
  color: #ff3f57; }

.table-member-list .single-remove-button:hover {
  color: #e83b51; }

.table-member-list .col-action-button-wrapper .view-agent {
  color: #ff7d00;
  letter-spacing: -0.5px; }

.table-member-list .col-action-button-wrapper .view-agent:hover {
  color: var(--main-darker-orange); }

.table-member-list .col-action-button-wrapper img.view-agent {
  margin-right: 3px;
  position: relative;
  top: -1px; }

.table-member-list tbody tr:hover .col-action-button-wrapper {
  /* display: block; */ }

.table-member-list .no-data-tr {
  border-bottom: none; }

.table-member-list .no-data-column {
  padding-top: 24px;
  letter-spacing: -0.5px;
  font-size: 16px; }

.table-member-list tbody tr:hover {
  background: #f8f8f8 !important; }

.table-member-list .resent-invitation {
  background: none;
  border: 0;
  color: #ff7d00;
  letter-spacing: -0.5px;
  padding: 0; }

.table-member-list .resent-invitation img {
  position: relative;
  top: -2px; }

.table-member-list .resent-invitation:hover {
  color: var(--main-darker-orange); }

.table-member-list .resend-invitation-loading {
  width: 150px; }

.table-member-list .resend-invitation-loading .lds-ring {
  top: 3px;
  right: unset; }

.table-member-list .resend-invitation-loading .lds-ring div {
  margin: 0; }

.member-tool-wrapper {
  width: 460px; }

.col_full_name {
  width: 35%;
  position: relative !important;
  padding: 8px !important; }
  .col_full_name .custom-checkbox {
    position: absolute !important;
    top: 1px !important;
    left: 12px !important; }
  .col_full_name .custom-checkbox-stripe {
    position: absolute !important;
    top: 1px !important;
    left: 12px !important; }
  .col_full_name .custom-checkbox-stripe [type="checkbox"]:checked + label:after {
    width: 16px;
    height: 16px; }

.col_name {
  width: 25%; }

.col_role {
  width: 30%; }

.broadcast-main-wrapper .btn {
  border: 0; }

.broadcast-main-wrapper tr:hover td {
  background: #ffffff !important; }

.member-tab-title {
  color: #191919;
  font-size: 20px;
  letter-spacing: -0.6px;
  line-height: 24px;
  padding-bottom: 16px;
  border-bottom: solid 1px rgba(25, 25, 25, 0.1); }

.member-tab-tool-wrapper {
  width: 100%;
  position: relative;
  margin-top: 24px;
  margin-bottom: 32px; }

.member-tab-info {
  font-size: 16px;
  letter-spacing: -0.3px;
  color: rgba(25, 25, 25, 0.8);
  line-height: 1.5;
  padding: 0 208px 18px 0; }

.member-tab-tool-wrapper button.button-invite-member {
  position: absolute;
  right: 0;
  top: 50%;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%);
  padding: 11px 0;
  width: 200px; }

.member-tab-tool-wrapper button.button-invite-member svg {
  position: relative;
  top: -1px;
  margin-right: 5px; }

.member-tab-table-wrapper {
  width: 100%;
  position: relative; }

.table-member-tab thead tr {
  background: #ffffff; }

.table-member-tab tbody td {
  background: #ffffff;
  position: relative; }

.table-member-tab button svg {
  position: relative !important;
  right: unset !important;
  top: -2px !important;
  margin-right: 5px; }

.table-member-tab .col-action-button-wrapper {
  position: absolute;
  right: 7px;
  top: 50%;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%);
  /* display: none; */ }

.table-member-tab .single-remove-button {
  margin-left: 16px;
  color: #ff3f57; }

.table-member-tab .single-remove-button:hover {
  color: #e83b51; }

.table-member-tab .shortcut-badge {
  font-size: 12px;
  color: rgba(25, 25, 25, 0.6);
  letter-spacing: -0.4px;
  background: #f3f3f3;
  line-height: 1;
  border-radius: 4px;
  padding: 1px 8px 3px; }

.table-member-tab td {
  max-width: unset !important; }

.table-member-tab .col-action-button-wrapper {
  display: block !important;
  position: relative;
  top: 0;
  right: unset;
  -webkit-transform: unset;
          transform: unset; }

.table-member-tab .col-action-button-wrapper .view-agent {
  color: #ff7d00;
  letter-spacing: -0.5px; }

.table-member-tab .col-action-button-wrapper .view-agent:hover {
  color: var(--main-darker-orange); }

.table-member-tab .col-action-button-wrapper img.view-agent {
  margin-right: 3px;
  position: relative;
  top: -1px; }

.member-tool-wrapper {
  width: 460px; }

.table-member-tab .col_action {
  width: 200px;
  text-align: right; }

.broadcast-page-wrapper {
  margin: 32px 0 0; }
  .broadcast-page-wrapper .nav-item {
    cursor: pointer;
    color: rgba(25, 25, 25, 0.4);
    font-size: 16px;
    letter-spacing: -0.5px;
    padding: 0 0 12px 0;
    margin-right: 32px; }
    .broadcast-page-wrapper .nav-item a {
      padding: 0;
      border: none; }
    .broadcast-page-wrapper .nav-item :hover {
      color: #ff7d00; }
    .broadcast-page-wrapper .nav-item.active-setup-tab {
      color: #ff7d00;
      border-bottom: solid 4px #ff7d00; }
  .broadcast-page-wrapper .tab-content {
    margin: 32px 0 0; }

.member-list-status-title {
  color: #191919;
  font-size: 20px;
  letter-spacing: -0.6px;
  line-height: 24px;
  padding-bottom: 16px;
  border-bottom: solid 1px rgba(25, 25, 25, 0.1);
  position: relative;
  padding-top: 10px; }
  .member-list-status-title .button-invite-member {
    position: absolute;
    right: 0;
    top: 0;
    width: 181px; }
  .member-list-status-title .grey-font {
    position: absolute;
    right: 192px;
    font-size: 14px;
    letter-spacing: -0.2px; }

.member-list-status-tool-wrapper {
  width: 100%;
  position: relative;
  margin-top: 24px;
  margin-bottom: 24px; }
  .member-list-status-tool-wrapper .dropdown-member-status {
    position: absolute;
    width: 322px;
    left: 356px;
    top: 0;
    box-shadow: none !important;
    outline: none !important; }
    .member-list-status-tool-wrapper .dropdown-member-status button {
      padding: 12px 34px 12px 14px;
      border-radius: 8px;
      border: 1px solid rgba(25, 25, 25, 0.1) !important;
      background: #fff !important;
      color: #191919 !important;
      width: 322px;
      text-align: left;
      height: 47px;
      line-height: 18px;
      box-shadow: none !important;
      outline: none !important;
      white-space: nowrap;
      display: block;
      text-overflow: ellipsis;
      overflow: hidden; }
      .member-list-status-tool-wrapper .dropdown-member-status button img {
        position: relative;
        top: -2px;
        margin-right: 4px;
        font-size: 18px; }
      .member-list-status-tool-wrapper .dropdown-member-status button svg {
        font-size: 18px;
        position: absolute;
        right: 14px;
        top: 15px; }
    .member-list-status-tool-wrapper .dropdown-member-status.dropdown-isdisabled button {
      background: rgba(25, 25, 25, 0.1) !important;
      color: rgba(25, 25, 25, 0.4) !important; }
    .member-list-status-tool-wrapper .dropdown-member-status .dropdown-menu {
      width: 322px;
      padding: 0;
      border-radius: 8px;
      overflow: hidden; }
      .member-list-status-tool-wrapper .dropdown-member-status .dropdown-menu .disabled-clock-filter {
        background: rgba(25, 25, 25, 0.1); }
      .member-list-status-tool-wrapper .dropdown-member-status .dropdown-menu div {
        padding: 12px 8px;
        width: 100%;
        display: block;
        font-size: 16px;
        letter-spacing: -0.3px; }
        .member-list-status-tool-wrapper .dropdown-member-status .dropdown-menu div label {
          margin: 0; }
        .member-list-status-tool-wrapper .dropdown-member-status .dropdown-menu div [type="checkbox"]:not(:checked) + label:before {
          top: 4px; }
        .member-list-status-tool-wrapper .dropdown-member-status .dropdown-menu div [type="checkbox"]:checked + label:before {
          top: 4px; }
        .member-list-status-tool-wrapper .dropdown-member-status .dropdown-menu div [type="checkbox"]:checked + label:after {
          top: -1px; }

.member-list-status-tool-wrapper button.button-invite-member {
  position: absolute;
  right: 0;
  top: 50%;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%);
  /* padding: 11px 0; */
  /* width: 200px; */ }

/* .member-list-status-tool-wrapper button.button-invite-member svg {
    position: relative;
    top: -1px;
    margin-right: 5px;
} */
.member-list-status-table-wrapper {
  width: 100%;
  position: relative; }
  .member-list-status-table-wrapper .no-result-found {
    width: 100%;
    text-align: center;
    padding: 24px 0; }

.table-member-list-status .member-alias-field {
  font-size: 14px;
  letter-spacing: -0.2px;
  color: rgba(25, 25, 25, 0.4);
  margin: 0; }

.table-member-list-status thead tr {
  background: #ffffff; }
  .table-member-list-status thead tr .custom-checkbox-stripe [type="checkbox"]:checked + label:after {
    top: 2px;
    left: 0; }

.table-member-list-status tbody td {
  background: #ffffff;
  position: relative;
  word-break: break-word; }
  .table-member-list-status tbody td.col_state {
    position: relative;
    padding: 8px 8px 8px 25px; }
    .table-member-list-status tbody td.col_state .status-badge {
      width: 12px;
      height: 12px;
      border-radius: 50%;
      position: absolute;
      top: 12px;
      left: 9px; }

.table-member-list-status button svg {
  position: relative !important;
  right: unset !important;
  top: -2px !important;
  margin-right: 5px; }

.table-member-list-status .col-action-button-wrapper {
  position: absolute;
  right: 7px;
  top: 50%;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%);
  /* display: none; */ }

.table-member-list-status .single-remove-button {
  margin-left: 16px;
  color: #ff3f57; }

.table-member-list-status .single-remove-button:hover {
  color: #e83b51; }

.table-member-list-status .col-action-button-wrapper .view-agent {
  color: #ff7d00;
  letter-spacing: -0.5px; }

.table-member-list-status .col-action-button-wrapper .view-agent:hover {
  color: var(--main-darker-orange); }

.table-member-list-status .col-action-button-wrapper img.view-agent {
  margin-right: 3px;
  position: relative;
  top: -1px; }

.table-member-list-status tbody tr:hover .col-action-button-wrapper {
  /* display: block; */ }

.table-member-list-status .no-data-tr {
  border-bottom: none; }

.table-member-list-status .no-data-column {
  padding-top: 24px;
  letter-spacing: -0.5px;
  font-size: 16px; }

.table-member-list-status tbody tr:hover {
  background: #f8f8f8 !important; }

.table-member-list-status .resent-invitation {
  background: none;
  border: 0;
  color: #ff7d00;
  letter-spacing: -0.5px;
  padding: 0; }

.table-member-list-status .resent-invitation img {
  position: relative;
  top: -2px; }

.table-member-list-status .resent-invitation:hover {
  color: var(--main-darker-orange); }

.table-member-list-status .resend-invitation-loading {
  width: 150px; }

.table-member-list-status .resend-invitation-loading .lds-ring {
  top: 3px;
  right: unset; }

.table-member-list-status .resend-invitation-loading .lds-ring div {
  margin: 0; }

.member-tool-wrapper {
  width: 460px; }

.col_full_name {
  width: 35%;
  position: relative !important;
  padding: 8px !important; }
  .col_full_name .custom-checkbox {
    position: absolute !important;
    top: 1px !important;
    left: 12px !important; }
  .col_full_name .custom-checkbox-stripe {
    position: absolute !important;
    top: 1px !important;
    left: 12px !important; }
  .col_full_name .custom-checkbox-stripe [type="checkbox"]:checked + label:after {
    width: 16px;
    height: 16px; }

.col_name {
  width: 25%; }

.col_role {
  width: 30%; }
  .col_role .status-badge {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    display: inline-block;
    position: relative;
    margin-right: 4px;
    top: 1px; }

.member-detail-outer-wrapper {
  margin: 16px 0 !important; }

#member-detail-wrapper {
  position: relative;
  width: 100%; }

.member-detail-title {
  font-size: 20px;
  letter-spacing: -0.6px;
  color: #191919;
  margin: 0 0 32px;
  border-bottom: solid 1px #dcdcdc;
  padding-bottom: 24px;
  position: relative; }
  .member-detail-title .member-detail-title-text {
    top: 3px;
    position: relative; }
  .member-detail-title button {
    position: absolute;
    right: 0; }

.member-detail-avatar {
  width: 72px;
  vertical-align: top;
  display: inline-block;
  margin-right: 36px; }

#member-value-wrapper {
  width: calc(100% - 108px);
  display: inline-block; }

.member-value-content {
  margin-bottom: 24px;
  padding-bottom: 16px; }

.member-value-content img {
  width: 16px;
  margin-right: 15px;
  display: inline-block;
  vertical-align: top;
  position: relative;
  /* top: 12px; */
  top: 0; }

.member-value-content .member-info-wrapper {
  width: calc(100% - 31px);
  display: inline-block;
  line-height: 1; }

.member-value-content .member-info-wrapper label {
  margin-bottom: 8px;
  font-size: 14px;
  letter-spacing: -0.4px;
  color: rgba(25, 25, 25, 0.6); }
  .member-value-content .member-info-wrapper label svg {
    position: relative;
    margin-left: 6px;
    font-size: 16px;
    color: var(--main-dark-orange);
    cursor: pointer;
    top: -2px; }
    .member-value-content .member-info-wrapper label svg:hover {
      color: var(--main-darker-orange); }

.member-value-content .member-info-wrapper b.member-detail-value {
  display: block;
  /* margin-bottom: 16px; */
  font-size: 20px;
  letter-spacing: -0.6px;
  color: #191919; }

.member-value-content .member-info-wrapper .access-to-inbox-label {
  letter-spacing: -0.2px;
  font-size: 14px;
  color: rgba(25, 25, 25, 0.6);
  margin: 4px 0 0; }

.member-value-content:nth-last-child(1) {
  border: none;
  margin-bottom: 0; }

.member-value-content:nth-last-child(1) b {
  margin-bottom: 0; }

#member-value-wrapper .member-value-content {
  position: relative;
  padding-left: 52px; }

#member-value-wrapper button {
  font-size: 12px;
  letter-spacing: -0.4px;
  border-radius: 4px;
  padding: 4px 10px;
  margin-top: 14px; }
  #member-value-wrapper button img {
    width: 12px;
    height: 12px;
    margin-right: 4px; }

#member-value-wrapper .icon-wrapper {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0; }
  #member-value-wrapper .icon-wrapper img {
    position: absolute;
    top: 50%;
    left: 50%;
    margin: 0;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  #member-value-wrapper .icon-wrapper.icon-email {
    background: #E7E5FF; }
  #member-value-wrapper .icon-wrapper.icon-name {
    background: #FFF9E5; }
  #member-value-wrapper .icon-wrapper.icon-alias {
    background: #E9F5FD; }
  #member-value-wrapper .icon-wrapper.icon-role {
    background: #E5FFEE; }

.member-detail-edit-topic {
  font-size: 16px;
  letter-spacing: -0.5px;
  padding: 7px 30px;
  position: absolute;
  top: 0;
  right: 0; }
  .member-detail-edit-topic img {
    margin-right: 4px;
    width: 16px;
    position: relative;
    top: -2px; }

.topic-list-wrapper {
  position: relative;
  width: 100%;
  margin-top: 24px; }
  .topic-list-wrapper .topic-list-content {
    color: rgba(25, 25, 25, 0.6);
    font-size: 12px;
    letter-spacing: -.4px;
    border-radius: 4px;
    background: rgba(25, 25, 25, 0.05);
    margin: 0 4px 8px 0;
    display: inline-block;
    padding: 2px 8px;
    vertical-align: top; }
  .topic-list-wrapper.role-list-wrapper {
    margin-top: 8px; }

.topic-no-list {
  width: 100%;
  position: relative;
  height: 140px;
  text-align: center;
  color: rgba(25, 25, 25, 0.8);
  padding-top: 40px; }
  .topic-no-list .topic-no-list-icon-wrapper {
    width: 41px;
    height: 41px;
    display: inline-block;
    border-radius: 50%;
    position: relative;
    margin-bottom: 20px;
    background: #F3F3F3; }
    .topic-no-list .topic-no-list-icon-wrapper img {
      width: 24px;
      position: relative;
      top: 6px;
      display: inline-block; }

.modal-edit-aliass .modal-content {
  width: 650px;
  border-radius: 8px; }
  .modal-edit-aliass .modal-content .modal-body {
    padding: 44px 24px; }
    .modal-edit-aliass .modal-content .modal-body .modal-title {
      font-size: 20px;
      letter-spacing: -0.6px;
      width: 100%;
      position: relative; }
    .modal-edit-aliass .modal-content .modal-body input {
      margin-top: 8px;
      padding: 12px 16px;
      font-size: 16px;
      letter-spacing: -0.3px;
      width: 100%;
      border-radius: 6px;
      border: solid 1px #dcdcdc; }
      .modal-edit-aliass .modal-content .modal-body input:focus {
        border: solid 1px var(--main-dark-orange); }
    .modal-edit-aliass .modal-content .modal-body .counter-alias {
      position: absolute;
      top: 2px;
      right: 0;
      font-size: 16px; }
    .modal-edit-aliass .modal-content .modal-body button {
      width: calc(50% - 2px);
      margin: 24px 4px 0 0; }
      .modal-edit-aliass .modal-content .modal-body button:nth-last-child(1) {
        margin: 24px 0 0; }

.modal-member-change-role .modal-content {
  padding: 32px;
  border-radius: 8px;
  width: 500px; }

.modal-member-change-role .modal-body {
  padding: 0; }

.modal-member-change-role .modal-footer {
  border: 0;
  padding: 0; }

.modal-member-change-role .modal-footer button {
  width: calc(50% - 4px);
  margin: 0 8px 0 0;
  padding: 10px 0 12px;
  position: relative; }

.modal-member-change-role .modal-footer button:nth-child(2) {
  margin-right: 0; }

.modal-member-change-role .modal-member-change-role-title {
  margin: 0 0 20px;
  font-size: 20px;
  letter-spacing: -0.6px; }
  .modal-member-change-role .modal-member-change-role-title svg {
    margin-right: 6px;
    position: relative;
    top: -2px;
    font-size: 24px; }

.modal-member-change-role .modal-member-role-wrapper {
  margin-bottom: 36px; }

.modal-member-change-role .role-radio-label {
  position: relative;
  top: -1px; }

.modal-member-change-role .modal-member-role-wrapper b {
  font-size: 14px;
  letter-spacing: -0.2px;
  color: #191919; }

.modal-member-change-role .modal-member-role-wrapper span {
  font-size: 14px;
  letter-spacing: -0.2px;
  color: rgba(25, 25, 25, 0.6);
  display: block; }

.modal-member-change-role .change-role-gap {
  height: 16px;
  width: 100%; }

.modal-member-change-role .there-is-no-custom-role {
  margin: 4px 0 0 27px; }

.modal-member-change-role .edit-member-role-list-wrapper {
  width: calc(100% - 28px);
  height: 190px;
  overflow: auto;
  border-radius: 8px;
  border: solid 1px #dcdcdc;
  margin: 8px 0 0 28px;
  padding: 8px 12px 0 12px; }
  .modal-member-change-role .edit-member-role-list-wrapper label {
    font-size: 14px;
    letter-spacing: -0.2px;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis; }
  .modal-member-change-role .edit-member-role-list-wrapper .custom-checkbox-child {
    padding: 0 0 16px 0; }

.modal-member-change-role .error-edit-role-custom {
  margin-left: 28px; }

.form-title-wrapper {
  padding: 0 0 16px 0; }
  .form-title-wrapper .assign-topic-title-text {
    position: relative;
    top: 3px; }
  .form-title-wrapper.assigned-topic-member {
    display: flex;
    justify-content: space-between; }
    .form-title-wrapper.assigned-topic-member button {
      font-size: 16px; }

.modal-edit-topic .modal-content {
  width: 840px;
  border-radius: 8px; }
  .modal-edit-topic .modal-content .modal-body {
    padding: 32px 32px 0 32px; }
    .modal-edit-topic .modal-content .modal-body .modal-member-change-role-title {
      font-size: 20px;
      letter-spacing: -0.6px; }
      .modal-edit-topic .modal-content .modal-body .modal-member-change-role-title svg {
        font-size: 22px;
        position: relative;
        top: -2px;
        margin-right: 4px; }
  .modal-edit-topic .modal-content .modal-footer {
    padding: 20px 32px 32px;
    border-top: 0; }
    .modal-edit-topic .modal-content .modal-footer button {
      width: 181px;
      margin: 0 0 0 8px; }

.modal-edit-topic .invite-member-select-topic-wrapper {
  width: 100%;
  position: relative; }
  .modal-edit-topic .invite-member-select-topic-wrapper .invite-member-select-topic-wrapper-50 {
    width: calc(50% - 8px);
    position: relative;
    vertical-align: top;
    display: inline-block;
    margin: 0 16px 0 0; }
    .modal-edit-topic .invite-member-select-topic-wrapper .invite-member-select-topic-wrapper-50 label {
      font-size: 14px;
      letter-spacing: -0.4px; }
    .modal-edit-topic .invite-member-select-topic-wrapper .invite-member-select-topic-wrapper-50:nth-child(2) {
      margin: 0; }
    .modal-edit-topic .invite-member-select-topic-wrapper .invite-member-select-topic-wrapper-50 span {
      color: rgba(25, 25, 25, 0.6); }
    .modal-edit-topic .invite-member-select-topic-wrapper .invite-member-select-topic-wrapper-50 .selection-topic-wrapper {
      border-radius: 8px;
      font-size: 14px;
      letter-spacing: -0.2px;
      box-shadow: 0 0 0 1px #dcdcdc; }
      .modal-edit-topic .invite-member-select-topic-wrapper .invite-member-select-topic-wrapper-50 .selection-topic-wrapper .select-content {
        width: 100%;
        padding: 13px 17px; }
        .modal-edit-topic .invite-member-select-topic-wrapper .invite-member-select-topic-wrapper-50 .selection-topic-wrapper .select-content label {
          margin: 0; }
        .modal-edit-topic .invite-member-select-topic-wrapper .invite-member-select-topic-wrapper-50 .selection-topic-wrapper .select-content:hover {
          background: #FFF2E5; }
        .modal-edit-topic .invite-member-select-topic-wrapper .invite-member-select-topic-wrapper-50 .selection-topic-wrapper .select-content:nth-child(1) {
          border-top-left-radius: 8px;
          border-top-right-radius: 8px; }
      .modal-edit-topic .invite-member-select-topic-wrapper .invite-member-select-topic-wrapper-50 .selection-topic-wrapper .search-box-wrapper {
        width: 100%; }
        .modal-edit-topic .invite-member-select-topic-wrapper .invite-member-select-topic-wrapper-50 .selection-topic-wrapper .search-box-wrapper input[type="text"] {
          border: none !important;
          border-bottom: solid 1px #dcdcdc !important;
          border-radius: 0;
          background: transparent;
          width: 100%;
          position: relative;
          padding: 0 42px 0 16px;
          height: 40px; }
        .modal-edit-topic .invite-member-select-topic-wrapper .invite-member-select-topic-wrapper-50 .selection-topic-wrapper .search-box-wrapper #search-icon {
          width: 40px;
          height: 40px; }
      .modal-edit-topic .invite-member-select-topic-wrapper .invite-member-select-topic-wrapper-50 .selection-topic-wrapper .overflow-select-topic {
        max-height: 160px;
        overflow-y: auto; }
        .modal-edit-topic .invite-member-select-topic-wrapper .invite-member-select-topic-wrapper-50 .selection-topic-wrapper .overflow-select-topic .select-content:nth-child(1) {
          border-top-left-radius: 0px;
          border-top-right-radius: 0px; }
        .modal-edit-topic .invite-member-select-topic-wrapper .invite-member-select-topic-wrapper-50 .selection-topic-wrapper .overflow-select-topic .select-content:nth-last-child(1) {
          border-bottom-left-radius: 8px;
          border-bottom-right-radius: 8px; }
        .modal-edit-topic .invite-member-select-topic-wrapper .invite-member-select-topic-wrapper-50 .selection-topic-wrapper .overflow-select-topic .select-content.checkbox-all {
          border-bottom: solid 1px #dcdcdc; }
        .modal-edit-topic .invite-member-select-topic-wrapper .invite-member-select-topic-wrapper-50 .selection-topic-wrapper .overflow-select-topic .select-content.not-found {
          text-align: center;
          color: var(--main-dark-grey); }
    .modal-edit-topic .invite-member-select-topic-wrapper .invite-member-select-topic-wrapper-50 .select-topic-wrapper {
      width: 100%;
      height: 200px; }
      .modal-edit-topic .invite-member-select-topic-wrapper .invite-member-select-topic-wrapper-50 .select-topic-wrapper .selected-topic-container {
        width: 100%;
        min-height: 100%;
        overflow-y: auto;
        max-height: 100%;
        border: solid 1px #dcdcdc;
        border-radius: 8px;
        padding: 16px 16px 8px 16px; }
        .modal-edit-topic .invite-member-select-topic-wrapper .invite-member-select-topic-wrapper-50 .select-topic-wrapper .selected-topic-container .topic-list-content {
          color: rgba(25, 25, 25, 0.6);
          font-size: 12px;
          letter-spacing: -.4px;
          border-radius: 4px;
          background: rgba(25, 25, 25, 0.05);
          margin: 0 4px 8px 0;
          display: inline-block;
          padding: 2px 8px;
          vertical-align: top; }
          .modal-edit-topic .invite-member-select-topic-wrapper .invite-member-select-topic-wrapper-50 .select-topic-wrapper .selected-topic-container .topic-list-content .remove-topic {
            display: inline-block;
            color: #9c9c9c;
            font-size: 18px;
            margin-left: 4px;
            position: relative;
            top: 4px;
            cursor: pointer;
            font-size: 8px;
            background: #9a9a9a;
            width: 10px;
            height: 10px;
            color: #ffffff;
            border-radius: 50%;
            vertical-align: top; }
            .modal-edit-topic .invite-member-select-topic-wrapper .invite-member-select-topic-wrapper-50 .select-topic-wrapper .selected-topic-container .topic-list-content .remove-topic svg {
              position: absolute;
              top: 50%;
              left: 50%;
              -webkit-transform: translate(-50%, -50%);
                      transform: translate(-50%, -50%); }
            .modal-edit-topic .invite-member-select-topic-wrapper .invite-member-select-topic-wrapper-50 .select-topic-wrapper .selected-topic-container .topic-list-content .remove-topic:hover {
              background: #818181; }

.option-select-topic-wrapper {
  width: 100%;
  position: relative; }
  .option-select-topic-wrapper .custom-checkbox-stripe [type="checkbox"]:checked + label:after {
    width: 16px;
    height: 16px;
    top: 2px;
    left: 0px; }
  .option-select-topic-wrapper.option-select-topic-wrapper-disabled .select-content label::before {
    border: solid 1px #dcdcdc !important; }
  .option-select-topic-wrapper.error-select-topic .selection-topic-wrapper {
    border: solid 1px #ff3f57 !important; }
  .option-select-topic-wrapper.error-select-topic .select-topic-wrapper {
    border: solid 1px #ff3f57 !important;
    border-radius: 8px; }
  .option-select-topic-wrapper .option-select-topic-wrapper-50 {
    width: calc(50% - 8px);
    position: relative;
    vertical-align: top;
    display: inline-block;
    margin: 0 16px 0 0; }
    .option-select-topic-wrapper .option-select-topic-wrapper-50 label {
      position: relative;
      width: 100%; }
      .option-select-topic-wrapper .option-select-topic-wrapper-50 label .clear-topic {
        position: absolute;
        bottom: 0;
        right: 0;
        cursor: pointer;
        color: var(--main-dark-orange); }
        .option-select-topic-wrapper .option-select-topic-wrapper-50 label .clear-topic svg {
          position: relative;
          font-size: 16px;
          top: -2px;
          margin-right: 4px; }
        .option-select-topic-wrapper .option-select-topic-wrapper-50 label .clear-topic:hover {
          color: var(--main-darker-orange); }
    .option-select-topic-wrapper .option-select-topic-wrapper-50:nth-child(2) {
      margin: 0; }
    .option-select-topic-wrapper .option-select-topic-wrapper-50 span {
      color: rgba(25, 25, 25, 0.6);
      position: relative !important; }
    .option-select-topic-wrapper .option-select-topic-wrapper-50 .selection-topic-wrapper {
      border-radius: 8px;
      box-shadow: 0 0 0 1px #dcdcdc;
      overflow: hidden; }
      .option-select-topic-wrapper .option-select-topic-wrapper-50 .selection-topic-wrapper label {
        font-size: 14px !important;
        letter-spacing: -0.2px !important; }
      .option-select-topic-wrapper .option-select-topic-wrapper-50 .selection-topic-wrapper .select-content {
        width: 100%;
        padding: 13px 17px;
        white-space: nowrap; }
        .option-select-topic-wrapper .option-select-topic-wrapper-50 .selection-topic-wrapper .select-content label {
          margin: 0;
          max-width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          display: inline-block !important;
          padding: 0 0 0 22px !important; }
        .option-select-topic-wrapper .option-select-topic-wrapper-50 .selection-topic-wrapper .select-content input {
          display: none; }
        .option-select-topic-wrapper .option-select-topic-wrapper-50 .selection-topic-wrapper .select-content:hover {
          background: #FFF2E5; }
        .option-select-topic-wrapper .option-select-topic-wrapper-50 .selection-topic-wrapper .select-content:nth-child(1) {
          border-top-left-radius: 8px;
          border-top-right-radius: 8px; }
      .option-select-topic-wrapper .option-select-topic-wrapper-50 .selection-topic-wrapper .search-box-wrapper {
        width: 100%; }
        .option-select-topic-wrapper .option-select-topic-wrapper-50 .selection-topic-wrapper .search-box-wrapper input[type="text"] {
          border: none !important;
          border-bottom: solid 1px #dcdcdc !important;
          border-radius: 0;
          background: transparent;
          width: 100% !important;
          position: relative;
          padding: 0 42px 0 16px;
          height: 40px;
          margin: 0 !important; }
        .option-select-topic-wrapper .option-select-topic-wrapper-50 .selection-topic-wrapper .search-box-wrapper #search-icon {
          width: 40px;
          right: 0 !important;
          left: unset !important;
          height: 40px;
          background: transparent !important; }
          .option-select-topic-wrapper .option-select-topic-wrapper-50 .selection-topic-wrapper .search-box-wrapper #search-icon svg {
            color: rgba(25, 25, 25, 0.6); }
      .option-select-topic-wrapper .option-select-topic-wrapper-50 .selection-topic-wrapper .overflow-select-topic {
        max-height: 160px;
        overflow-y: auto; }
        .option-select-topic-wrapper .option-select-topic-wrapper-50 .selection-topic-wrapper .overflow-select-topic .select-content {
          height: 47px; }
          .option-select-topic-wrapper .option-select-topic-wrapper-50 .selection-topic-wrapper .overflow-select-topic .select-content:nth-child(1) {
            border-top-left-radius: 0px;
            border-top-right-radius: 0px; }
          .option-select-topic-wrapper .option-select-topic-wrapper-50 .selection-topic-wrapper .overflow-select-topic .select-content:nth-last-child(1) {
            border-bottom-left-radius: 8px;
            border-bottom-right-radius: 8px; }
          .option-select-topic-wrapper .option-select-topic-wrapper-50 .selection-topic-wrapper .overflow-select-topic .select-content.checkbox-all {
            border-bottom: solid 1px #dcdcdc; }
          .option-select-topic-wrapper .option-select-topic-wrapper-50 .selection-topic-wrapper .overflow-select-topic .select-content.not-found {
            text-align: center !important;
            color: var(--main-dark-grey);
            background: #fff !important;
            padding: 34px 60px;
            height: unset;
            white-space: unset;
            text-align: center;
            overflow: hidden !important; }
            .option-select-topic-wrapper .option-select-topic-wrapper-50 .selection-topic-wrapper .overflow-select-topic .select-content.not-found img {
              margin: 0 0 20px 0; }
    .option-select-topic-wrapper .option-select-topic-wrapper-50 .select-topic-wrapper {
      width: 100%;
      height: 200px; }
      .option-select-topic-wrapper .option-select-topic-wrapper-50 .select-topic-wrapper .selected-topic-container {
        width: 100%;
        min-height: 100%;
        overflow-y: auto;
        max-height: 100%;
        border: solid 1px #dcdcdc;
        border-radius: 8px;
        padding: 8px 16px 8px 16px; }
        .option-select-topic-wrapper .option-select-topic-wrapper-50 .select-topic-wrapper .selected-topic-container .no-selected-topic {
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-top: 48px; }
          .option-select-topic-wrapper .option-select-topic-wrapper-50 .select-topic-wrapper .selected-topic-container .no-selected-topic img {
            margin-bottom: 20px; }
          .option-select-topic-wrapper .option-select-topic-wrapper-50 .select-topic-wrapper .selected-topic-container .no-selected-topic p {
            color: rgba(25, 25, 25, 0.8);
            font-size: 14px;
            line-height: 21px;
            letter-spacing: -0.2px; }

.modal-invitation-sent .modal-content {
  width: 476px;
  border-radius: 8px; }
  .modal-invitation-sent .modal-content .modal-body {
    padding: 32px 24px;
    text-align: center; }
    .modal-invitation-sent .modal-content .modal-body img {
      margin: 0 0 8px 0; }
    .modal-invitation-sent .modal-content .modal-body b {
      font-size: 20px; }
    .modal-invitation-sent .modal-content .modal-body p {
      margin: 8px 0 20px;
      font-size: 14px;
      letter-spacing: 21px;
      letter-spacing: -.2px; }
    .modal-invitation-sent .modal-content .modal-body button {
      height: 40px;
      width: 100%; }

.invite-member-wrapper {
  margin: 16px 0 !important; }
  .invite-member-wrapper label {
    width: 100%; }
    .invite-member-wrapper label.width-300 {
      width: 300px !important; }
  .invite-member-wrapper input {
    width: 300px !important; }
  .invite-member-wrapper .role-radio-label {
    font-size: 14px;
    letter-spacing: -0.2px; }
  .invite-member-wrapper .there-is-no-custom-role {
    margin: 8px 0 0 28px; }
  .invite-member-wrapper .form-title-wrapper {
    position: relative; }
    .invite-member-wrapper .form-title-wrapper button {
      position: absolute;
      right: 0;
      top: 0; }
  .invite-member-wrapper .form-member-invite button {
    width: inherit; }
  .invite-member-wrapper .custom-checkbox-child-wrapper {
    padding: 0 0 0 26px;
    margin-top: 8px; }
    .invite-member-wrapper .custom-checkbox-child-wrapper .custom-checkbox-child {
      padding: 8px;
      border-radius: 6px;
      background: #fafafa;
      display: inline-block;
      min-width: 180px;
      margin: 0 8px 16px 0;
      border: solid 1px transparent; }
      .invite-member-wrapper .custom-checkbox-child-wrapper .custom-checkbox-child label {
        margin: 0;
        width: unset;
        font-size: 14px !important;
        letter-spacing: -0.2px !important; }
      .invite-member-wrapper .custom-checkbox-child-wrapper .custom-checkbox-child [type="checkbox"]:not(:checked) + label:before {
        top: 2px; }
      .invite-member-wrapper .custom-checkbox-child-wrapper .custom-checkbox-child [type="checkbox"]:checked + label:after {
        top: -3px; }

.sla-settings .button-save {
  margin-top: 28px; }

.sla-settings .status-wrapper {
  font-size: 16px; }
  .sla-settings .status-wrapper .status-value {
    color: rgba(25, 25, 25, 0.4);
    font-size: 16px;
    letter-spacing: -0.5px; }
    .sla-settings .status-wrapper .status-value.status-enabled {
      color: #19C700; }
  .sla-settings .status-wrapper img {
    position: relative;
    top: -2px;
    margin-right: 4px; }

.sla-settings .sla-settings-header {
  font-size: 20px;
  letter-spacing: -0.6px;
  color: #191919;
  padding-bottom: 24px;
  border-bottom: solid 1px #dcdcdc;
  position: relative;
  margin: 0 0 24px 0; }
  .sla-settings .sla-settings-header button {
    position: absolute;
    top: 0;
    right: 0; }

.sla-settings .sla-settings-form {
  position: relative; }
  .sla-settings .sla-settings-form input[type="text"] {
    font-family: PTRootBold;
    width: 46px;
    height: 24px;
    margin: 0 4px;
    border-radius: 4px;
    text-align: center;
    padding: 0 6px;
    font-size: 12px;
    letter-spacing: -0.5px; }
  .sla-settings .sla-settings-form .input-time-select {
    font-size: 14px;
    letter-spacing: -0.4px;
    display: inline-block;
    vertical-align: top;
    margin-right: 4px;
    width: 108px;
    height: 24px; }
    .sla-settings .sla-settings-form .input-time-select .custom-select-option-placeholder {
      padding: 0 !important;
      text-align: center; }
    .sla-settings .sla-settings-form .input-time-select .custom-select-option-value {
      font-family: PTRootBold;
      font-size: 12px;
      letter-spacing: -0.2px;
      padding: 0 !important;
      text-align: center; }
    .sla-settings .sla-settings-form .input-time-select svg {
      color: rgba(25, 25, 25, 0.8) !important;
      right: 12px !important;
      font-size: 14px; }
    .sla-settings .sla-settings-form .input-time-select .dropdown button {
      padding: 1px 20px 1px 12px !important;
      width: 108px !important;
      height: 24px;
      border-radius: 4px !important;
      margin: 0 !important; }
    .sla-settings .sla-settings-form .input-time-select .dropdown-option-wrapper {
      padding: 0;
      width: 108px;
      min-width: 0;
      border-radius: 4px !important; }
      .sla-settings .sla-settings-form .input-time-select .dropdown-option-wrapper button {
        padding: 3px 12px 3px 26px !important;
        font-family: PTRootBold;
        border-bottom: solid 1px #dcdcdc;
        font-size: 12px;
        letter-spacing: -0.2px;
        border-radius: 0 !important; }
        .sla-settings .sla-settings-form .input-time-select .dropdown-option-wrapper button::before {
          width: 12px;
          height: 12px;
          left: 10px;
          top: 6px;
          background-size: 12px;
          -webkit-transform: none !important;
                  transform: none !important; }
        .sla-settings .sla-settings-form .input-time-select .dropdown-option-wrapper button svg {
          font-size: 14px;
          left: 12px; }
        .sla-settings .sla-settings-form .input-time-select .dropdown-option-wrapper button:nth-last-child(1) {
          border: none; }
  .sla-settings .sla-settings-form .closing-welcome-switch-wrapper {
    position: absolute;
    top: 0;
    right: 0;
    vertical-align: top;
    display: inline-block;
    margin: 0 0 0 16px; }
    .sla-settings .sla-settings-form .closing-welcome-switch-wrapper .custom-switch-wrapper {
      margin: 0;
      height: 28px;
      width: 48px; }
      .sla-settings .sla-settings-form .closing-welcome-switch-wrapper .custom-switch-wrapper .custom-switch:before {
        width: 15px;
        height: 15px;
        bottom: 7px; }
    .sla-settings .sla-settings-form .closing-welcome-switch-wrapper input:checked + .custom-switch:before {
      -webkit-transform: translateX(17px);
              transform: translateX(17px); }
  .sla-settings .sla-settings-form .sla-settings-form-content {
    display: block;
    width: 100%;
    font-size: 16px;
    letter-spacing: -0.5px;
    margin-bottom: 20px;
    position: relative;
    padding-right: 64px; }
    .sla-settings .sla-settings-form .sla-settings-form-content p {
      margin: 4px 0 10px;
      font-size: 14px;
      letter-spacing: -0.2px;
      color: rgba(25, 25, 25, 0.8); }

.modal-confirmation-save-changes .modal-content {
  width: 476px !important; }

.modal-confirmation-save-changes button {
  width: calc(50% - 4px) !important;
  margin-right: 8px; }
  .modal-confirmation-save-changes button:nth-last-child(1) {
    margin-right: 0; }

.main-custom-select-option-wrapper {
  position: relative; }
  .main-custom-select-option-wrapper .custom-select-option-value-wrapper:disabled {
    background-color: rgba(25, 25, 25, 0.1) !important;
    color: rgba(25, 25, 25, 0.4) !important; }
  .main-custom-select-option-wrapper.error-select .dropdown button {
    border-color: #ff3f57 !important; }
  .main-custom-select-option-wrapper .active-dropdown-toggle .custom-select-option-value-wrapper {
    border: solid 1px #ff7e00 !important; }

.custom-select-option-value-wrapper {
  border-radius: 8px !important;
  width: 100% !important;
  padding: 12px 38px 12px 16px !important;
  position: relative;
  border: solid 1px #1919191A !important;
  background-color: #ffffff !important;
  z-index: 1;
  text-align: left !important;
  outline: none !important;
  box-shadow: none !important; }
  .custom-select-option-value-wrapper .dropdown-logo-val {
    position: relative;
    margin-right: 8px;
    top: -1px;
    width: 24px; }
  .custom-select-option-value-wrapper.border-red {
    border: solid 1px #ff3f57 !important; }

.main-custom-select-option-wrapper.active-dropdown .custom-select-option-value-wrapper {
  border: solid 1px #ff7e00; }

.custom-select-option-value-wrapper p {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  margin: 0 !important;
  letter-spacing: -0.2px;
  color: #191919;
  font-size: 16px;
  padding-right: 24px; }

.custom-select-option-value-wrapper .icon-arrow {
  position: absolute;
  right: 16px;
  top: 50%;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%);
  -webkit-transition: ease-in-out 0.3s;
  transition: ease-in-out 0.3s;
  font-size: 24px;
  color: #191919; }

.main-custom-select-option-wrapper.active-dropdown .custom-select-option-value-wrapper .icon-arrow {
  -webkit-transform: translate(0, -50%) rotate(180deg);
          transform: translate(0, -50%) rotate(180deg); }

.custom-select-option-placeholder {
  color: rgba(25, 25, 25, 0.4) !important; }

.dropdown-option-wrapper {
  /* position: absolute;
    top: 64px;
    left: 0; */
  border-radius: 8px !important;
  width: 100%;
  max-height: 209px;
  overflow-y: auto;
  padding: 0 !important;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  z-index: 2;
  background: #ffffff;
  display: none;
  outline: none; }
  .dropdown-option-wrapper .dropdown-logo-option {
    position: relative;
    top: -2px;
    margin-right: 8px; }

.main-custom-select-option-wrapper.active-dropdown .dropdown-option-wrapper {
  display: block;
  z-index: 3; }

.dropdown-option-wrapper {
  margin: 0;
  padding: 0;
  list-style: none; }

.dropdown-option-wrapper .dropdown-item {
  font-size: 16px;
  white-space: nowrap;
  max-width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
  color: #191919;
  padding: 10px 16px 10px 35px !important;
  position: relative;
  cursor: pointer; }
  .dropdown-option-wrapper .dropdown-item:hover {
    background: #ffe7d0; }
  .dropdown-option-wrapper .dropdown-item:active {
    background: none;
    color: #191919; }
  .dropdown-option-wrapper .dropdown-item .dropdown-description-label {
    word-wrap: break-word;
    white-space: pre-wrap;
    word-break: break-word; }
    .dropdown-option-wrapper .dropdown-item .dropdown-description-label p {
      margin: 0;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: -0.2px;
      color: #19191999; }

.dropdown-option-wrapper .active-value {
  color: #191919;
  padding: 8px 16px 8px 35px !important; }

.dropdown-option-wrapper .active-value::before {
  position: absolute;
  left: 12px;
  top: 50%;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%);
  color: #ff7e00;
  width: 16px;
  height: 16px;
  background-image: url(/static/media/icon-check-orange.bc571b04.svg);
  background-size: 16px;
  content: ""; }

.dropdown-option-wrapper .active-multiple::before {
  position: absolute;
  left: 8px;
  top: 50%;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%);
  width: 20px;
  height: 20px;
  background-image: url(/static/media/icon-checkbox-active.9e36e90b.svg);
  background-size: 20px;
  content: ""; }

.dropdown-option-wrapper .inactive-multiple::before {
  position: absolute;
  left: 8px;
  top: 50%;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%);
  width: 20px;
  height: 20px;
  background-image: url(/static/media/icon-checkbox-inactive.483e3068.svg);
  background-size: 20px;
  content: ""; }

.dropdown-option-wrapper .no-data-option {
  text-align: center;
  color: gray;
  padding: 10px 16px !important; }

.loading-custom-select {
  display: inline-block;
  right: 16px;
  top: 50%;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%);
  position: absolute; }

.dropdown-item-page .dropdown {
  width: 70px; }

.dropdown-item-page .dropdown-menu {
  width: 70px;
  min-width: unset;
  -webkit-transform: unset !important;
          transform: unset !important;
  right: 0px;
  bottom: auto;
  left: 0 !important;
  top: 22px !important;
  border-radius: 4px !important;
  bottom: unset !important; }
  .dropdown-item-page .dropdown-menu .dropdown-item {
    padding: 0px 6px 0px 35px !important;
    font-size: 14px; }

.channel-option-with-badge:disabled {
  background: transparent !important;
  border: none !important; }

.channel-option-with-badge p {
  width: calc(100% - 130px);
  margin: 0;
  text-overflow: ellipsis;
  overflow: hidden;
  display: inline-block;
  font-size: 16px;
  letter-spacing: -0.3px;
  position: relative;
  top: 3px;
  white-space: nowrap; }
  .channel-option-with-badge p.grey-text {
    color: rgba(25, 25, 25, 0.6) !important; }

.channel-option-with-badge .option-badge {
  width: 94px;
  display: inline-block;
  font-size: 12px;
  letter-spacing: -0.4px;
  font-size: 12px;
  letter-spacing: -0.4px;
  position: absolute;
  right: 16px;
  background: red;
  color: #fff;
  border-radius: 4px;
  text-align: center;
  top: 50%;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%);
  background: rgba(25, 25, 25, 0.4); }

.channel-option-with-badge .option-authenticated {
  background: #19c700; }

.channel-option-with-badge .option-got_qr_code {
  background: #0c5f99; }

.channel-option-with-badge .option-active {
  background: #19c700; }

.channel-option-with-badge .option-loading {
  background: #f4c12a; }

.channel-option-with-badge .option-error {
  background: #e02e2e; }

.channel-option-with-badge .option-waiting-setup {
  background: var(--main-dark-orange); }

.channel-option-with-badge .option-not_paid {
  background: rgba(25, 25, 25, 0.4); }

.channel-option-with-badge:hover p {
  color: #191919; }

.dropdown-menu.show {
  display: block !important; }

.scf-path-main-wrapper .integration-list-loading-wrapper {
  height: 80vh; }
  .scf-path-main-wrapper .integration-list-loading-wrapper .lds-ring {
    width: 50px;
    height: 50px;
    position: relative;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    right: unset; }
    .scf-path-main-wrapper .integration-list-loading-wrapper .lds-ring div {
      border: 5px solid #fff;
      border-color: #ff7d00 transparent transparent transparent;
      width: 50px;
      height: 50px;
      margin: 0; }

.scf-path-main-wrapper .scf-path-header {
  padding-bottom: 12px;
  margin-bottom: 12px;
  border-bottom: 1px solid rgba(25, 25, 25, 0.1);
  position: relative;
  display: block; }
  .scf-path-main-wrapper .scf-path-header .grey-font {
    font-size: 16px;
    letter-spacing: -0.6px; }
  .scf-path-main-wrapper .scf-path-header button {
    position: absolute;
    top: 0;
    right: 0; }

.scf-path-main-wrapper .scf-path-title {
  font-size: 20px;
  letter-spacing: -0.6px;
  margin: 0;
  color: #191919; }

.scf-path-main-wrapper .scf-path-description {
  margin: 8px 0 16px;
  font-size: 16px;
  letter-spacing: -0.3px;
  width: calc(100% - 230px);
  color: #191919;
  line-height: 24px; }

.scf-path-main-wrapper .scf-path-search {
  width: 300px;
  border-radius: 8px;
  border: solid 1px rgba(25, 25, 25, 0.1);
  margin-bottom: 24px; }
  .scf-path-main-wrapper .scf-path-search.focus-search {
    border: solid 1px #ff7e00; }
    .scf-path-main-wrapper .scf-path-search.focus-search svg {
      color: #ff7e00; }
  .scf-path-main-wrapper .scf-path-search span {
    position: relative;
    top: -2px; }
  .scf-path-main-wrapper .scf-path-search input {
    border: none;
    background: transparent;
    padding: 8px 4px 8px 16px;
    width: 90%; }

.faq-list thead tr {
  background: transparent; }
  .faq-list thead tr td {
    padding: 8px;
    font-size: 14px;
    word-wrap: break-word;
    white-space: normal; }
    .faq-list thead tr td.col_type {
      width: 105px; }
    .faq-list thead tr td.col_action {
      width: 105px; }
    .faq-list thead tr td:last-child {
      width: 90px; }
      .faq-list thead tr td:last-child .custom-select-option-value-wrapper {
        height: 30px;
        padding: 0 4px 0 12px !important; }
        .faq-list thead tr td:last-child .custom-select-option-value-wrapper .custom-select-option-value {
          padding: 0; }
        .faq-list thead tr td:last-child .custom-select-option-value-wrapper svg {
          right: 10px; }

.faq-list tbody tr.no-hover {
  cursor: default !important; }
  .faq-list tbody tr.no-hover:hover {
    background-color: #ffffff !important; }
    .faq-list tbody tr.no-hover:hover td {
      color: #191919 !important; }

.faq-list tbody tr td {
  background: transparent;
  position: relative;
  word-wrap: break-word;
  vertical-align: top; }
  .faq-list tbody tr td.col_id {
    width: 70px; }
  .faq-list tbody tr td.col_type {
    width: 105px; }
  .faq-list tbody tr td.col_action {
    width: 105px; }
  .faq-list tbody tr td .unavailable {
    color: rgba(25, 25, 25, 0.4); }
  .faq-list tbody tr td .link-user {
    color: inherit;
    text-decoration: inherit; }
  .faq-list tbody tr td:nth-child(1) p {
    margin-bottom: 4px; }
  .faq-list tbody tr td:nth-child(1) .col_name_alias {
    font-size: 12px; }
  .faq-list tbody tr td:nth-child(4).col-origin p {
    margin: 0;
    display: inline; }
  .faq-list tbody tr td:nth-child(4).col-origin img {
    position: relative;
    top: -2px;
    margin-right: 8px;
    width: 20px; }

.faq-list .col-action-button-wrapper {
  position: absolute;
  right: 7px;
  top: 50%;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%);
  /* display: none; */ }

.faq-list td {
  max-width: unset !important; }
  .faq-list td svg {
    position: relative !important;
    right: unset !important; }

.add-path-modal .modal-content {
  width: 800px;
  border-radius: 8px;
  padding: 32px;
  position: relative;
  max-height: 90vh;
  overflow-y: auto; }
  .add-path-modal .modal-content .create-path-title {
    font-size: 20px;
    letter-spacing: -0.6px;
    line-height: 1; }
  .add-path-modal .modal-content .close-modal-create-path {
    position: absolute;
    font-size: 22px;
    right: 0;
    top: 2px;
    cursor: pointer; }
  .add-path-modal .modal-content .form-content-wrapper input, .add-path-modal .modal-content .form-content-wrapper textarea, .add-path-modal .modal-content .form-content-wrapper label {
    width: 100%;
    position: relative; }
    .add-path-modal .modal-content .form-content-wrapper input .clear-all, .add-path-modal .modal-content .form-content-wrapper textarea .clear-all, .add-path-modal .modal-content .form-content-wrapper label .clear-all {
      color: var(--watermelon);
      cursor: pointer;
      position: absolute;
      right: 0;
      bottom: 0; }
      .add-path-modal .modal-content .form-content-wrapper input .clear-all:hover, .add-path-modal .modal-content .form-content-wrapper textarea .clear-all:hover, .add-path-modal .modal-content .form-content-wrapper label .clear-all:hover {
        color: var(--watermelon-dark); }
  .add-path-modal .modal-content .form-content-wrapper .input-counter {
    position: absolute;
    top: 0;
    right: 0; }
  .add-path-modal .modal-content .button-create-path {
    width: 100%;
    margin-top: 20px; }
  .add-path-modal .modal-content .create-path-type-wrapper {
    position: relative;
    width: 100%;
    display: block; }
    .add-path-modal .modal-content .create-path-type-wrapper .create-path-type-select {
      display: inline-block;
      border-radius: 8px;
      width: calc(50% - 5px);
      margin-right: 10px;
      border: solid 1px #dcdcdc;
      cursor: pointer;
      text-align: center;
      padding: 12px 5px;
      font-size: 16px;
      letter-spacing: -0.3px; }
      .add-path-modal .modal-content .create-path-type-wrapper .create-path-type-select.create-path-type-active {
        border: solid 1px #ff7d00;
        color: #ff8d00; }
        .add-path-modal .modal-content .create-path-type-wrapper .create-path-type-select.create-path-type-active svg {
          color: #ff7d00; }
      .add-path-modal .modal-content .create-path-type-wrapper .create-path-type-select svg {
        margin-right: 4px;
        position: relative;
        top: -1px;
        color: rgba(25, 25, 25, 0.4); }
      .add-path-modal .modal-content .create-path-type-wrapper .create-path-type-select:nth-child(2) {
        margin: 0; }
      .add-path-modal .modal-content .create-path-type-wrapper .create-path-type-select:hover {
        border: solid 1px #ff7d00;
        color: #ff8d00; }
        .add-path-modal .modal-content .create-path-type-wrapper .create-path-type-select:hover svg {
          color: #ff7d00; }
  .add-path-modal .modal-content .choose-related-department-wrapper {
    position: relative;
    width: 100%;
    display: block; }
    .add-path-modal .modal-content .choose-related-department-wrapper .choose-related-department-select {
      display: inline-block;
      border-radius: 8px;
      width: calc((100% / 3) - 10px);
      margin-right: 15px;
      border: solid 1px #dcdcdc;
      cursor: pointer;
      text-align: left;
      padding: 12px 13px;
      font-size: 16px;
      letter-spacing: -0.3px;
      margin-bottom: 10px; }
      .add-path-modal .modal-content .choose-related-department-wrapper .choose-related-department-select label {
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: 100%;
        overflow: hidden; }
      .add-path-modal .modal-content .choose-related-department-wrapper .choose-related-department-select svg {
        margin-right: 4px;
        position: relative;
        top: -1px;
        color: rgba(25, 25, 25, 0.4); }
      .add-path-modal .modal-content .choose-related-department-wrapper .choose-related-department-select:nth-child(3) {
        margin: 0; }
      .add-path-modal .modal-content .choose-related-department-wrapper .choose-related-department-select:hover {
        border: solid 1px #ff7d00; }
        .add-path-modal .modal-content .choose-related-department-wrapper .choose-related-department-select:hover label {
          color: #ff7d00; }
      .add-path-modal .modal-content .choose-related-department-wrapper .choose-related-department-select label {
        margin: 0; }
      .add-path-modal .modal-content .choose-related-department-wrapper .choose-related-department-select.choose-related-department-select-active {
        border: solid 1px #ff7d00; }
        .add-path-modal .modal-content .choose-related-department-wrapper .choose-related-department-select.choose-related-department-select-active label {
          color: #ff7d00; }

.add-path-modal .modal-body {
  padding: 0; }

.scf-path-create-main-wrapper a {
  color: var(--main-dark-orange); }
  .scf-path-create-main-wrapper a:hover {
    color: var(--main-darker-orange); }

.scf-path-create-main-wrapper .testurl-wrap {
  position: relative;
  width: 100%; }
  .scf-path-create-main-wrapper .testurl-wrap input {
    padding: 0 168px 0 16px; }
  .scf-path-create-main-wrapper .testurl-wrap .testurl {
    color: #fff;
    font-size: 16px;
    letter-spacing: -0.5px;
    width: 152px;
    text-align: center;
    position: absolute;
    top: 0;
    right: 0;
    background: var(--main-dark-orange);
    cursor: pointer;
    border-radius: 0 8px 8px 0;
    padding: 12px 0; }
    .scf-path-create-main-wrapper .testurl-wrap .testurl .lds-ring {
      margin: 0 4px 0 0;
      top: 3px !important;
      right: 0 !important; }
      .scf-path-create-main-wrapper .testurl-wrap .testurl .lds-ring div {
        margin: 0;
        border-color: rgba(25, 25, 25, 0.4) transparent transparent transparent; }
    .scf-path-create-main-wrapper .testurl-wrap .testurl:hover {
      background: var(--main-darker-orange); }
    .scf-path-create-main-wrapper .testurl-wrap .testurl.testurl-disable {
      background: rgba(25, 25, 25, 0.1);
      color: rgba(25, 25, 25, 0.4); }

.scf-path-create-main-wrapper .response-api-bubble {
  border-radius: 16px 0 16px 16px;
  padding: 10px;
  background: #FFF2E5;
  border: solid 1px rgba(25, 25, 25, 0.1);
  max-width: 70%;
  display: inline-block;
  color: rgba(25, 25, 25, 0.6);
  font-size: 16px;
  letter-spacing: -0.3px;
  text-align: left; }
  .scf-path-create-main-wrapper .response-api-bubble .faq-caption {
    margin: 0;
    max-width: 280px;
    word-break: break-word; }
  .scf-path-create-main-wrapper .response-api-bubble .message-info {
    margin: 4px 0 0;
    font-size: 10px;
    text-align: right; }
    .scf-path-create-main-wrapper .response-api-bubble .message-info img {
      top: 1px !important; }
  .scf-path-create-main-wrapper .response-api-bubble .faq-image-video {
    position: relative;
    border-radius: 16px 0 16px 16px; }
    .scf-path-create-main-wrapper .response-api-bubble .faq-image-video .message-info {
      background: rgba(25, 25, 25, 0.4);
      position: absolute;
      right: 8px;
      bottom: 8px;
      font-size: 12px;
      border-radius: 16px;
      color: #fff !important;
      padding: 2px 8px; }
      .scf-path-create-main-wrapper .response-api-bubble .faq-image-video .message-info img {
        display: inline-block;
        width: unset !important; }
    .scf-path-create-main-wrapper .response-api-bubble .faq-image-video .black-layer {
      position: absolute;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%);
      width: 48px;
      height: 48px;
      background: rgba(25, 25, 25, 0.4);
      border-radius: 50%; }
      .scf-path-create-main-wrapper .response-api-bubble .faq-image-video .black-layer svg {
        font-size: 30px;
        color: #fff;
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%); }
    .scf-path-create-main-wrapper .response-api-bubble .faq-image-video img {
      max-width: 100%;
      max-height: 100%;
      width: 100%;
      display: block;
      border-radius: 16px 0 16px 16px; }
    .scf-path-create-main-wrapper .response-api-bubble .faq-image-video video {
      max-width: 100%;
      max-height: 100%;
      display: block;
      width: 100%;
      border-radius: 16px 0 16px 16px; }
    .scf-path-create-main-wrapper .response-api-bubble .faq-image-video p {
      margin: 8px 0 0; }
  .scf-path-create-main-wrapper .response-api-bubble .message-info-caption {
    margin: 4px 0 0 !important;
    font-size: 10px !important;
    text-align: right;
    position: relative !important;
    right: unset !important;
    bottom: unset !important;
    color: rgba(25, 25, 25, 0.6) !important; }
    .scf-path-create-main-wrapper .response-api-bubble .message-info-caption img {
      top: 1px !important; }
  .scf-path-create-main-wrapper .response-api-bubble .faq-document .faq-document-outer {
    padding: 0 0 0 54px;
    position: relative; }
  .scf-path-create-main-wrapper .response-api-bubble .faq-document .faq-document-inner {
    background: var(--main-dark-orange);
    width: 48px;
    height: 48px;
    border-radius: 50%;
    position: absolute;
    left: 0;
    top: 0; }
    .scf-path-create-main-wrapper .response-api-bubble .faq-document .faq-document-inner svg {
      color: #fff;
      font-size: 20px;
      position: absolute;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%); }
  .scf-path-create-main-wrapper .response-api-bubble .faq-document .faq-filename {
    color: #191919;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0;
    height: 48px;
    font-size: 14px;
    letter-spacing: -0.4px;
    padding: 14px 0 0; }

.scf-path-create-main-wrapper .label-left {
  padding-left: 36px;
  position: relative; }

.scf-path-create-main-wrapper .custom-switch-wrapper {
  display: inline-block !important;
  width: 30px !important;
  height: 16px;
  position: absolute !important;
  top: 2px;
  left: 0;
  margin: 0; }
  .scf-path-create-main-wrapper .custom-switch-wrapper .custom-switch {
    padding: 0; }
  .scf-path-create-main-wrapper .custom-switch-wrapper .custom-switch.round::before {
    width: 10px;
    height: 10px;
    bottom: 3px;
    left: 4px; }
  .scf-path-create-main-wrapper .custom-switch-wrapper input:checked + .custom-switch:before {
    -webkit-transform: translateX(11px);
            transform: translateX(11px); }

.scf-path-create-main-wrapper .integration-list-loading-wrapper {
  height: 80vh; }
  .scf-path-create-main-wrapper .integration-list-loading-wrapper .lds-ring {
    width: 50px;
    height: 50px;
    position: relative;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    right: unset; }
    .scf-path-create-main-wrapper .integration-list-loading-wrapper .lds-ring div {
      border: 5px solid #fff;
      border-color: #ff7d00 transparent transparent transparent;
      width: 50px;
      height: 50px;
      margin: 0; }

.scf-path-create-main-wrapper .scf-path-create-header {
  padding-bottom: 24px;
  margin-bottom: 12px;
  border-bottom: 1px solid rgba(25, 25, 25, 0.1);
  position: relative;
  display: block; }
  .scf-path-create-main-wrapper .scf-path-create-header .grey-font {
    font-size: 16px;
    letter-spacing: -0.6px; }
  .scf-path-create-main-wrapper .scf-path-create-header button {
    position: absolute;
    top: 0;
    right: 0;
    width: 180px; }

.scf-path-create-main-wrapper .scf-path-create-title {
  font-size: 20px;
  letter-spacing: -0.6px;
  margin: 0;
  color: #191919; }

.scf-path-create-main-wrapper .scf-path-create-description {
  margin: 8px 0 16px;
  font-size: 16px;
  letter-spacing: -0.3px;
  width: calc(100% - 230px);
  color: #191919;
  line-height: 24px; }

.scf-path-create-main-wrapper .scf-path-create-search {
  width: 300px;
  border-radius: 8px;
  border: solid 1px rgba(25, 25, 25, 0.1);
  margin-bottom: 24px; }
  .scf-path-create-main-wrapper .scf-path-create-search.focus-search {
    border: solid 1px #ff7e00; }
    .scf-path-create-main-wrapper .scf-path-create-search.focus-search svg {
      color: #ff7e00; }
  .scf-path-create-main-wrapper .scf-path-create-search span {
    position: relative;
    top: -2px; }
  .scf-path-create-main-wrapper .scf-path-create-search input {
    border: none;
    background: transparent;
    padding: 8px 4px 8px 16px;
    width: 90%; }

.faq-list thead tr {
  background: transparent; }
  .faq-list thead tr td {
    padding: 8px;
    font-size: 14px;
    word-wrap: break-word;
    white-space: normal; }
    .faq-list thead tr td.col_type {
      width: 105px; }
    .faq-list thead tr td.col_action {
      width: 105px; }
    .faq-list thead tr td:last-child {
      width: 90px; }
      .faq-list thead tr td:last-child .custom-select-option-value-wrapper {
        height: 30px;
        padding: 0 4px 0 12px !important; }
        .faq-list thead tr td:last-child .custom-select-option-value-wrapper .custom-select-option-value {
          padding: 0; }
        .faq-list thead tr td:last-child .custom-select-option-value-wrapper svg {
          right: 10px; }

.faq-list tbody tr.no-hover {
  cursor: default !important; }
  .faq-list tbody tr.no-hover:hover {
    background-color: #ffffff !important; }
    .faq-list tbody tr.no-hover:hover td {
      color: #191919 !important; }

.faq-list tbody tr td {
  background: transparent;
  position: relative;
  word-wrap: break-word;
  vertical-align: top; }
  .faq-list tbody tr td.col_id {
    width: 70px; }
  .faq-list tbody tr td.col_type {
    width: 105px; }
  .faq-list tbody tr td.col_action {
    width: 105px; }
  .faq-list tbody tr td .unavailable {
    color: rgba(25, 25, 25, 0.4); }
  .faq-list tbody tr td .link-user {
    color: inherit;
    text-decoration: inherit; }
  .faq-list tbody tr td:nth-child(1) p {
    margin-bottom: 4px; }
  .faq-list tbody tr td:nth-child(1) .col_name_alias {
    font-size: 12px; }
  .faq-list tbody tr td:nth-child(4).col-origin p {
    margin: 0;
    display: inline; }
  .faq-list tbody tr td:nth-child(4).col-origin img {
    position: relative;
    top: -2px;
    margin-right: 8px;
    width: 20px; }

.faq-list .col-action-button-wrapper {
  position: absolute;
  right: 7px;
  top: 50%;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%);
  /* display: none; */ }

.faq-list td {
  max-width: unset !important; }
  .faq-list td svg {
    position: relative !important;
    right: unset !important; }

.create-path-add-wrapper .create-path-add-title {
  font-size: 20px;
  letter-spacing: -0.6px;
  line-height: 1; }

.create-path-add-wrapper .close-modal-create-path {
  position: absolute;
  font-size: 22px;
  right: 0;
  top: 2px;
  cursor: pointer; }

.create-path-add-wrapper .form-content-wrapper input, .create-path-add-wrapper .form-content-wrapper textarea, .create-path-add-wrapper .form-content-wrapper label {
  width: 100%;
  position: relative; }
  .create-path-add-wrapper .form-content-wrapper input .clear-all, .create-path-add-wrapper .form-content-wrapper textarea .clear-all, .create-path-add-wrapper .form-content-wrapper label .clear-all {
    color: var(--watermelon);
    cursor: pointer;
    position: absolute;
    right: 0;
    bottom: 0; }
    .create-path-add-wrapper .form-content-wrapper input .clear-all:hover, .create-path-add-wrapper .form-content-wrapper textarea .clear-all:hover, .create-path-add-wrapper .form-content-wrapper label .clear-all:hover {
      color: var(--watermelon-dark); }

.create-path-add-wrapper .form-content-wrapper .input-counter {
  position: absolute;
  top: 0;
  right: 0; }

.create-path-add-wrapper .button-create-path {
  width: 100%;
  margin-top: 20px; }

.create-path-add-wrapper .create-path-add-type-wrapper {
  position: relative;
  width: 100%;
  display: block; }
  .create-path-add-wrapper .create-path-add-type-wrapper .create-path-add-type-select {
    display: inline-block;
    border-radius: 8px;
    width: calc((100% / 3) - 4px);
    margin-right: 6px;
    border: solid 1px #dcdcdc;
    cursor: pointer;
    text-align: center;
    padding: 12px 5px;
    font-size: 16px;
    letter-spacing: -0.3px;
    max-width: 204px; }
    .create-path-add-wrapper .create-path-add-type-wrapper .create-path-add-type-select.disabled-type-select {
      color: rgba(25, 25, 25, 0.4) !important;
      border: solid 1px rgba(25, 25, 25, 0.4) !important; }
      .create-path-add-wrapper .create-path-add-type-wrapper .create-path-add-type-select.disabled-type-select svg {
        color: rgba(25, 25, 25, 0.4) !important; }
    .create-path-add-wrapper .create-path-add-type-wrapper .create-path-add-type-select.create-path-add-type-active {
      border: solid 1px #ff7d00;
      color: #ff8d00; }
      .create-path-add-wrapper .create-path-add-type-wrapper .create-path-add-type-select.create-path-add-type-active svg {
        color: #ff7d00; }
    .create-path-add-wrapper .create-path-add-type-wrapper .create-path-add-type-select svg {
      margin-right: 4px;
      position: relative;
      top: -1px;
      color: rgba(25, 25, 25, 0.4); }
    .create-path-add-wrapper .create-path-add-type-wrapper .create-path-add-type-select:nth-child(3) {
      margin: 0; }
    .create-path-add-wrapper .create-path-add-type-wrapper .create-path-add-type-select:hover {
      border: solid 1px #ff7d00;
      color: #ff8d00; }
      .create-path-add-wrapper .create-path-add-type-wrapper .create-path-add-type-select:hover svg {
        color: #ff7d00; }

.create-path-add-wrapper .choose-related-department-wrapper {
  position: relative;
  width: 100%;
  display: block; }
  .create-path-add-wrapper .choose-related-department-wrapper .choose-related-department-select {
    display: inline-block;
    border-radius: 8px;
    width: calc((100% / 3) - 10px);
    margin-right: 15px;
    border: solid 1px #dcdcdc;
    cursor: pointer;
    text-align: left;
    padding: 12px 13px;
    font-size: 16px;
    letter-spacing: -0.3px;
    margin-bottom: 10px; }
    .create-path-add-wrapper .choose-related-department-wrapper .choose-related-department-select label {
      white-space: nowrap;
      text-overflow: ellipsis;
      max-width: 100%;
      overflow: hidden; }
    .create-path-add-wrapper .choose-related-department-wrapper .choose-related-department-select svg {
      margin-right: 4px;
      position: relative;
      top: -1px;
      color: rgba(25, 25, 25, 0.4); }
    .create-path-add-wrapper .choose-related-department-wrapper .choose-related-department-select:nth-child(3) {
      margin: 0; }
    .create-path-add-wrapper .choose-related-department-wrapper .choose-related-department-select:hover {
      border: solid 1px #ff7d00; }
      .create-path-add-wrapper .choose-related-department-wrapper .choose-related-department-select:hover label {
        color: #ff7d00; }
    .create-path-add-wrapper .choose-related-department-wrapper .choose-related-department-select label {
      margin: 0; }
    .create-path-add-wrapper .choose-related-department-wrapper .choose-related-department-select.choose-related-department-select-active {
      border: solid 1px #ff7d00; }
      .create-path-add-wrapper .choose-related-department-wrapper .choose-related-department-select.choose-related-department-select-active label {
        color: #ff7d00; }

.scf-path-edit-main-wrapper .edit-faq-type {
  font-size: 14px;
  letter-spacing: -0.4px; }
  .scf-path-edit-main-wrapper .edit-faq-type svg {
    margin-right: 4px;
    position: relative;
    top: -2px; }

.scf-path-edit-main-wrapper a {
  color: var(--main-dark-orange); }
  .scf-path-edit-main-wrapper a:hover {
    color: var(--main-darker-orange); }

.scf-path-edit-main-wrapper .testurl-wrap {
  position: relative;
  width: 100%; }
  .scf-path-edit-main-wrapper .testurl-wrap input {
    padding: 0 168px 0 16px; }
  .scf-path-edit-main-wrapper .testurl-wrap .testurl {
    color: #fff;
    font-size: 16px;
    letter-spacing: -0.5px;
    width: 152px;
    text-align: center;
    position: absolute;
    top: 0;
    right: 0;
    background: var(--main-dark-orange);
    cursor: pointer;
    border-radius: 0 8px 8px 0;
    padding: 12px 0; }
    .scf-path-edit-main-wrapper .testurl-wrap .testurl .lds-ring {
      margin: 0 4px 0 0;
      top: 3px !important;
      right: 0 !important; }
      .scf-path-edit-main-wrapper .testurl-wrap .testurl .lds-ring div {
        margin: 0;
        border-color: rgba(25, 25, 25, 0.4) transparent transparent transparent; }
    .scf-path-edit-main-wrapper .testurl-wrap .testurl:hover {
      background: var(--main-darker-orange); }
    .scf-path-edit-main-wrapper .testurl-wrap .testurl.testurl-disable {
      background: rgba(25, 25, 25, 0.1);
      color: rgba(25, 25, 25, 0.4); }

.scf-path-edit-main-wrapper .response-api-bubble {
  border-radius: 16px 0 16px 16px;
  padding: 10px;
  background: #FFF2E5;
  border: solid 1px rgba(25, 25, 25, 0.1);
  max-width: 70%;
  display: inline-block;
  color: rgba(25, 25, 25, 0.6);
  font-size: 16px;
  letter-spacing: -0.3px;
  text-align: left; }
  .scf-path-edit-main-wrapper .response-api-bubble .faq-caption {
    margin: 0;
    max-width: 280px;
    word-break: break-word; }
  .scf-path-edit-main-wrapper .response-api-bubble .message-info {
    margin: 4px 0 0;
    font-size: 10px;
    text-align: right; }
    .scf-path-edit-main-wrapper .response-api-bubble .message-info img {
      top: 1px !important; }
  .scf-path-edit-main-wrapper .response-api-bubble .faq-image-video {
    position: relative;
    border-radius: 16px 0 16px 16px; }
    .scf-path-edit-main-wrapper .response-api-bubble .faq-image-video .message-info {
      background: rgba(25, 25, 25, 0.4);
      position: absolute;
      right: 8px;
      bottom: 8px;
      font-size: 12px;
      border-radius: 16px;
      color: #fff !important;
      padding: 2px 8px; }
      .scf-path-edit-main-wrapper .response-api-bubble .faq-image-video .message-info img {
        display: inline-block;
        width: unset !important; }
    .scf-path-edit-main-wrapper .response-api-bubble .faq-image-video .black-layer {
      position: absolute;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%);
      width: 48px;
      height: 48px;
      background: rgba(25, 25, 25, 0.4);
      border-radius: 50%; }
      .scf-path-edit-main-wrapper .response-api-bubble .faq-image-video .black-layer svg {
        font-size: 30px;
        color: #fff;
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%); }
    .scf-path-edit-main-wrapper .response-api-bubble .faq-image-video img {
      max-width: 100%;
      max-height: 100%;
      width: 100%;
      display: block;
      border-radius: 16px 0 16px 16px; }
    .scf-path-edit-main-wrapper .response-api-bubble .faq-image-video video {
      max-width: 100%;
      max-height: 100%;
      display: block;
      width: 100%;
      border-radius: 16px 0 16px 16px; }
    .scf-path-edit-main-wrapper .response-api-bubble .faq-image-video p {
      margin: 8px 0 0; }
  .scf-path-edit-main-wrapper .response-api-bubble .message-info-caption {
    margin: 4px 0 0 !important;
    font-size: 10px !important;
    text-align: right;
    position: relative !important;
    right: unset !important;
    bottom: unset !important;
    color: rgba(25, 25, 25, 0.6) !important; }
    .scf-path-edit-main-wrapper .response-api-bubble .message-info-caption img {
      top: 1px !important; }
  .scf-path-edit-main-wrapper .response-api-bubble .faq-document .faq-document-outer {
    padding: 0 0 0 54px;
    position: relative; }
  .scf-path-edit-main-wrapper .response-api-bubble .faq-document .faq-document-inner {
    background: var(--main-dark-orange);
    width: 48px;
    height: 48px;
    border-radius: 50%;
    position: absolute;
    left: 0;
    top: 0; }
    .scf-path-edit-main-wrapper .response-api-bubble .faq-document .faq-document-inner svg {
      color: #fff;
      font-size: 20px;
      position: absolute;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%); }
  .scf-path-edit-main-wrapper .response-api-bubble .faq-document .faq-filename {
    color: #191919;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0;
    height: 48px;
    font-size: 14px;
    letter-spacing: -0.4px;
    padding: 14px 0 0; }

.scf-path-edit-main-wrapper .label-left {
  padding-left: 36px;
  position: relative; }

.scf-path-edit-main-wrapper .custom-switch-wrapper {
  display: inline-block !important;
  width: 30px !important;
  height: 16px;
  position: absolute !important;
  top: 2px;
  left: 0;
  margin: 0; }
  .scf-path-edit-main-wrapper .custom-switch-wrapper .custom-switch {
    padding: 0; }
  .scf-path-edit-main-wrapper .custom-switch-wrapper .custom-switch.round::before {
    width: 10px;
    height: 10px;
    bottom: 3px;
    left: 4px; }
  .scf-path-edit-main-wrapper .custom-switch-wrapper input:checked + .custom-switch:before {
    -webkit-transform: translateX(11px);
            transform: translateX(11px); }

.scf-path-edit-main-wrapper .integration-list-loading-wrapper {
  height: 80vh; }
  .scf-path-edit-main-wrapper .integration-list-loading-wrapper .lds-ring {
    width: 50px;
    height: 50px;
    position: relative;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    right: unset; }
    .scf-path-edit-main-wrapper .integration-list-loading-wrapper .lds-ring div {
      border: 5px solid #fff;
      border-color: #ff7d00 transparent transparent transparent;
      width: 50px;
      height: 50px;
      margin: 0; }

.scf-path-edit-main-wrapper .scf-path-edit-header {
  padding-bottom: 24px;
  margin-bottom: 12px;
  border-bottom: 1px solid rgba(25, 25, 25, 0.1);
  position: relative;
  display: block; }
  .scf-path-edit-main-wrapper .scf-path-edit-header .grey-font {
    font-size: 16px;
    letter-spacing: -0.6px; }
  .scf-path-edit-main-wrapper .scf-path-edit-header button {
    position: absolute;
    top: 0;
    right: 0;
    width: 180px; }

.scf-path-edit-main-wrapper .scf-path-edit-title {
  font-size: 20px;
  letter-spacing: -0.6px;
  margin: 0;
  color: #191919; }

.scf-path-edit-main-wrapper .scf-path-edit-description {
  margin: 8px 0 16px;
  font-size: 16px;
  letter-spacing: -0.3px;
  width: calc(100% - 230px);
  color: #191919;
  line-height: 24px; }

.scf-path-edit-main-wrapper .scf-path-edit-search {
  width: 300px;
  border-radius: 8px;
  border: solid 1px rgba(25, 25, 25, 0.1);
  margin-bottom: 24px; }
  .scf-path-edit-main-wrapper .scf-path-edit-search.focus-search {
    border: solid 1px #ff7e00; }
    .scf-path-edit-main-wrapper .scf-path-edit-search.focus-search svg {
      color: #ff7e00; }
  .scf-path-edit-main-wrapper .scf-path-edit-search span {
    position: relative;
    top: -2px; }
  .scf-path-edit-main-wrapper .scf-path-edit-search input {
    border: none;
    background: transparent;
    padding: 8px 4px 8px 16px;
    width: 90%; }

.faq-list thead tr {
  background: transparent; }
  .faq-list thead tr td {
    padding: 8px;
    font-size: 14px;
    word-wrap: break-word;
    white-space: normal; }
    .faq-list thead tr td.col_type {
      width: 105px; }
    .faq-list thead tr td.col_action {
      width: 105px; }
    .faq-list thead tr td:last-child {
      width: 90px; }
      .faq-list thead tr td:last-child .custom-select-option-value-wrapper {
        height: 30px;
        padding: 0 4px 0 12px !important; }
        .faq-list thead tr td:last-child .custom-select-option-value-wrapper .custom-select-option-value {
          padding: 0; }
        .faq-list thead tr td:last-child .custom-select-option-value-wrapper svg {
          right: 10px; }

.faq-list tbody tr.no-hover {
  cursor: default !important; }
  .faq-list tbody tr.no-hover:hover {
    background-color: #ffffff !important; }
    .faq-list tbody tr.no-hover:hover td {
      color: #191919 !important; }

.faq-list tbody tr td {
  background: transparent;
  position: relative;
  word-wrap: break-word;
  vertical-align: top; }
  .faq-list tbody tr td.col_id {
    width: 70px; }
  .faq-list tbody tr td.col_type {
    width: 105px; }
  .faq-list tbody tr td.col_action {
    width: 105px; }
  .faq-list tbody tr td .unavailable {
    color: rgba(25, 25, 25, 0.4); }
  .faq-list tbody tr td .link-user {
    color: inherit;
    text-decoration: inherit; }
  .faq-list tbody tr td:nth-child(1) p {
    margin-bottom: 4px; }
  .faq-list tbody tr td:nth-child(1) .col_name_alias {
    font-size: 12px; }
  .faq-list tbody tr td:nth-child(4).col-origin p {
    margin: 0;
    display: inline; }
  .faq-list tbody tr td:nth-child(4).col-origin img {
    position: relative;
    top: -2px;
    margin-right: 8px;
    width: 20px; }

.faq-list .col-action-button-wrapper {
  position: absolute;
  right: 7px;
  top: 50%;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%);
  /* display: none; */ }

.faq-list td {
  max-width: unset !important; }
  .faq-list td svg {
    position: relative !important;
    right: unset !important; }

.create-path-add-wrapper .create-path-add-title {
  font-size: 20px;
  letter-spacing: -0.6px;
  line-height: 1; }

.create-path-add-wrapper .close-modal-create-path {
  position: absolute;
  font-size: 22px;
  right: 0;
  top: 2px;
  cursor: pointer; }

.create-path-add-wrapper .form-content-wrapper input, .create-path-add-wrapper .form-content-wrapper textarea, .create-path-add-wrapper .form-content-wrapper label {
  width: 100%;
  position: relative; }
  .create-path-add-wrapper .form-content-wrapper input .clear-all, .create-path-add-wrapper .form-content-wrapper textarea .clear-all, .create-path-add-wrapper .form-content-wrapper label .clear-all {
    color: var(--watermelon);
    cursor: pointer;
    position: absolute;
    right: 0;
    bottom: 0; }
    .create-path-add-wrapper .form-content-wrapper input .clear-all:hover, .create-path-add-wrapper .form-content-wrapper textarea .clear-all:hover, .create-path-add-wrapper .form-content-wrapper label .clear-all:hover {
      color: var(--watermelon-dark); }

.create-path-add-wrapper .form-content-wrapper .input-counter {
  position: absolute;
  top: 0;
  right: 0; }

.create-path-add-wrapper .button-create-path {
  width: 100%;
  margin-top: 20px; }

.create-path-add-wrapper .create-path-add-type-wrapper {
  position: relative;
  width: 100%;
  display: block; }
  .create-path-add-wrapper .create-path-add-type-wrapper .create-path-add-type-select {
    display: inline-block;
    border-radius: 8px;
    width: calc((100% / 3) - 4px);
    margin-right: 6px;
    border: solid 1px #dcdcdc;
    cursor: pointer;
    text-align: center;
    padding: 12px 5px;
    font-size: 16px;
    letter-spacing: -0.3px;
    max-width: 204px; }
    .create-path-add-wrapper .create-path-add-type-wrapper .create-path-add-type-select.disabled-type-select {
      color: rgba(25, 25, 25, 0.4) !important;
      border: solid 1px rgba(25, 25, 25, 0.4) !important; }
      .create-path-add-wrapper .create-path-add-type-wrapper .create-path-add-type-select.disabled-type-select svg {
        color: rgba(25, 25, 25, 0.4) !important; }
    .create-path-add-wrapper .create-path-add-type-wrapper .create-path-add-type-select.create-path-add-type-active {
      border: solid 1px #ff7d00;
      color: #ff8d00; }
      .create-path-add-wrapper .create-path-add-type-wrapper .create-path-add-type-select.create-path-add-type-active svg {
        color: #ff7d00; }
    .create-path-add-wrapper .create-path-add-type-wrapper .create-path-add-type-select svg {
      margin-right: 4px;
      position: relative;
      top: -1px;
      color: rgba(25, 25, 25, 0.4); }
    .create-path-add-wrapper .create-path-add-type-wrapper .create-path-add-type-select:nth-child(3) {
      margin: 0; }
    .create-path-add-wrapper .create-path-add-type-wrapper .create-path-add-type-select:hover {
      border: solid 1px #ff7d00;
      color: #ff8d00; }
      .create-path-add-wrapper .create-path-add-type-wrapper .create-path-add-type-select:hover svg {
        color: #ff7d00; }

.create-path-add-wrapper .choose-related-department-wrapper {
  position: relative;
  width: 100%;
  display: block; }
  .create-path-add-wrapper .choose-related-department-wrapper .choose-related-department-select {
    display: inline-block;
    border-radius: 8px;
    width: calc((100% / 3) - 10px);
    margin-right: 15px;
    border: solid 1px #dcdcdc;
    cursor: pointer;
    text-align: left;
    padding: 12px 13px;
    font-size: 16px;
    letter-spacing: -0.3px;
    margin-bottom: 10px; }
    .create-path-add-wrapper .choose-related-department-wrapper .choose-related-department-select label {
      white-space: nowrap;
      text-overflow: ellipsis;
      max-width: 100%;
      overflow: hidden; }
    .create-path-add-wrapper .choose-related-department-wrapper .choose-related-department-select svg {
      margin-right: 4px;
      position: relative;
      top: -1px;
      color: rgba(25, 25, 25, 0.4); }
    .create-path-add-wrapper .choose-related-department-wrapper .choose-related-department-select:nth-child(3) {
      margin: 0; }
    .create-path-add-wrapper .choose-related-department-wrapper .choose-related-department-select:hover {
      border: solid 1px #ff7d00; }
      .create-path-add-wrapper .choose-related-department-wrapper .choose-related-department-select:hover label {
        color: #ff7d00; }
    .create-path-add-wrapper .choose-related-department-wrapper .choose-related-department-select label {
      margin: 0; }
    .create-path-add-wrapper .choose-related-department-wrapper .choose-related-department-select.choose-related-department-select-active {
      border: solid 1px #ff7d00; }
      .create-path-add-wrapper .choose-related-department-wrapper .choose-related-department-select.choose-related-department-select-active label {
        color: #ff7d00; }

.modal-confirmation-delete-path button {
  width: calc(50% - 4px) !important;
  margin-right: 16px; }
  .modal-confirmation-delete-path button:nth-last-child(1) {
    margin-right: 0; }

.view-faq-detail .response-api-bubble {
  border-radius: 16px 0 16px 16px;
  padding: 10px;
  background: #FFF2E5;
  border: solid 1px rgba(25, 25, 25, 0.1);
  max-width: 70%;
  display: inline-block;
  color: rgba(25, 25, 25, 0.6);
  font-size: 16px;
  letter-spacing: -0.3px;
  text-align: left; }
  .view-faq-detail .response-api-bubble .faq-caption {
    margin: 0;
    max-width: 280px;
    word-break: break-word; }
  .view-faq-detail .response-api-bubble .message-info {
    margin: 4px 0 0;
    font-size: 10px;
    text-align: right; }
    .view-faq-detail .response-api-bubble .message-info img {
      top: 1px !important; }
  .view-faq-detail .response-api-bubble .faq-image-video {
    position: relative;
    border-radius: 16px 0 16px 16px; }
    .view-faq-detail .response-api-bubble .faq-image-video .message-info {
      background: rgba(25, 25, 25, 0.4);
      position: absolute;
      right: 8px;
      bottom: 8px;
      font-size: 12px;
      border-radius: 16px;
      color: #fff !important;
      padding: 2px 8px; }
      .view-faq-detail .response-api-bubble .faq-image-video .message-info img {
        display: inline-block;
        width: unset !important;
        margin: 0 !important;
        width: 200px; }
    .view-faq-detail .response-api-bubble .faq-image-video .black-layer {
      position: absolute;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%);
      width: 48px;
      height: 48px;
      background: rgba(25, 25, 25, 0.4);
      border-radius: 50%; }
      .view-faq-detail .response-api-bubble .faq-image-video .black-layer svg {
        font-size: 30px;
        color: #fff;
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%); }
    .view-faq-detail .response-api-bubble .faq-image-video img {
      max-width: 100%;
      max-height: 100%;
      width: 100%;
      display: block;
      border-radius: 16px 0 16px 16px; }
    .view-faq-detail .response-api-bubble .faq-image-video video {
      max-width: 100%;
      max-height: 100%;
      display: block;
      width: 100%;
      border-radius: 16px 0 16px 16px; }
    .view-faq-detail .response-api-bubble .faq-image-video p {
      margin: 8px 0 0; }
  .view-faq-detail .response-api-bubble .message-info-caption {
    margin: 4px 0 0 !important;
    font-size: 10px !important;
    text-align: right;
    position: relative !important;
    right: unset !important;
    bottom: unset !important;
    color: rgba(25, 25, 25, 0.6) !important; }
    .view-faq-detail .response-api-bubble .message-info-caption img {
      top: 1px !important; }
  .view-faq-detail .response-api-bubble .faq-document .faq-document-outer {
    padding: 0 0 0 54px;
    position: relative; }
  .view-faq-detail .response-api-bubble .faq-document .faq-document-inner {
    background: var(--main-dark-orange);
    width: 48px;
    height: 48px;
    border-radius: 50%;
    position: absolute;
    left: 0;
    top: 0; }
    .view-faq-detail .response-api-bubble .faq-document .faq-document-inner svg {
      color: #fff;
      font-size: 20px;
      position: absolute;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%); }
  .view-faq-detail .response-api-bubble .faq-document .faq-filename {
    color: #191919;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0;
    height: 48px;
    font-size: 14px;
    letter-spacing: -0.4px;
    padding: 14px 0 0; }

.view-faq-detail .grey-font-6 {
  margin: 0; }

.view-faq-detail .flexing {
  display: flex;
  margin: 4px 0 0 !important; }

.view-faq-detail .view-faq-detail-top {
  padding: 10px 0 12px 0;
  border-bottom: solid 1px #dcdcdc;
  margin-bottom: 24px;
  position: relative; }
  .view-faq-detail .view-faq-detail-top b {
    font-size: 20px !important;
    letter-spacing: -0.6px;
    font-weight: 700; }
  .view-faq-detail .view-faq-detail-top .button-export {
    position: absolute;
    right: 0;
    bottom: 14px; }
    .view-faq-detail .view-faq-detail-top .button-export b {
      color: #ff7d00 !important;
      font-size: 16px !important; }
  .view-faq-detail .view-faq-detail-top .button-delete {
    position: absolute;
    right: 0;
    bottom: 14px; }
    .view-faq-detail .view-faq-detail-top .button-delete b {
      color: #fff !important;
      font-size: 16px !important; }
  .view-faq-detail .view-faq-detail-top .button-edit {
    position: absolute;
    right: 134px;
    bottom: 14px; }
    .view-faq-detail .view-faq-detail-top .button-edit b {
      color: #ff7d00 !important;
      font-size: 16px !important; }
  .view-faq-detail .view-faq-detail-top span {
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.5px;
    color: rgba(25, 25, 25, 0.4);
    position: relative;
    top: 2px; }

.view-faq-detail .colored-info-box p {
  color: #ffffff;
  margin-top: 0 !important; }

.view-faq-detail div p,
.view-faq-detail div b {
  font-size: 14px;
  letter-spacing: -0.4px;
  color: rgba(0, 0, 0, 0.8);
  font-family: PTRootUI !important; }
  .view-faq-detail div p.yes-text,
  .view-faq-detail div b.yes-text {
    color: #19C700; }
  .view-faq-detail div p.no-text,
  .view-faq-detail div b.no-text {
    color: #E02E2E; }

.view-faq-detail div p {
  letter-spacing: -0.2px; }

.view-faq-detail div b {
  font-weight: 700 !important;
  vertical-align: top; }

.view-faq-detail div .disabled-form div {
  background-color: rgba(25, 25, 25, 0.05);
  border: 1px solid rgba(25, 25, 25, 0.1);
  border-radius: 8px;
  padding: 12px 16px;
  width: 400px;
  margin: 8.5px 0 24px 0;
  word-break: break-word; }
  .view-faq-detail div .disabled-form div p {
    font-size: 16px; }

.view-faq-detail .view-faq-detail-middle {
  padding: 0 0 16px; }
  .view-faq-detail .view-faq-detail-middle.view-faq-detail-middle-top {
    max-width: 100%;
    width: 90%; }
    .view-faq-detail .view-faq-detail-middle.view-faq-detail-middle-top .faq-detail-topics {
      border-radius: 4px;
      background: rgba(25, 25, 25, 0.05);
      max-width: 150px;
      white-space: nowrap;
      margin: 0 4px 4px 0;
      text-overflow: ellipsis;
      overflow: hidden;
      vertical-align: top;
      text-align: center;
      padding: 6px 8px;
      line-height: 1;
      display: inline-block; }
      .view-faq-detail .view-faq-detail-middle.view-faq-detail-middle-top .faq-detail-topics b {
        color: rgba(25, 25, 25, 0.6);
        letter-spacing: -0.4px;
        font-size: 12px; }
  .view-faq-detail .view-faq-detail-middle .col_action {
    width: 70px; }
    .view-faq-detail .view-faq-detail-middle .col_action .orange-link-text svg {
      top: 2px !important; }
  .view-faq-detail .view-faq-detail-middle.message-number-wrapper {
    border-bottom: solid 1px #dcdcdc;
    margin-bottom: 10px; }
    .view-faq-detail .view-faq-detail-middle.message-number-wrapper:nth-last-child(1) {
      margin-bottom: 0;
      border-bottom: 0; }
  .view-faq-detail .view-faq-detail-middle > div svg {
    margin-right: 4px;
    vertical-align: top;
    position: relative;
    top: 3px; }
  .view-faq-detail .view-faq-detail-middle > div img {
    margin-right: 5px;
    margin-bottom: 4px;
    position: relative;
    top: -1px; }
    .view-faq-detail .view-faq-detail-middle > div img.label-icon {
      margin-bottom: 0;
      position: relative;
      vertical-align: top;
      top: 2px; }
    .view-faq-detail .view-faq-detail-middle > div img.channel-icon {
      margin: 0 5px 0 0 !important;
      position: relative;
      top: 2px; }
  .view-faq-detail .view-faq-detail-middle p {
    margin: 8px 0 0;
    word-break: break-word; }
  .view-faq-detail .view-faq-detail-middle .view-faq-detail-middle-category p {
    margin-bottom: 0; }
  .view-faq-detail .view-faq-detail-middle .view-faq-detail-middle-channel > div {
    margin: 8px 0 0; }
  .view-faq-detail .view-faq-detail-middle .view-faq-detail-middle-status .colored-info-box {
    margin-top: 10px;
    margin-bottom: 1rem; }
    .view-faq-detail .view-faq-detail-middle .view-faq-detail-middle-status .colored-info-box p {
      color: #ffffff;
      margin-top: 0 !important; }
  .view-faq-detail .view-faq-detail-middle .view-faq-detail-middle-content-body {
    max-width: 568px; }
    .view-faq-detail .view-faq-detail-middle .view-faq-detail-middle-content-body > img {
      margin-bottom: 5px !important; }
    .view-faq-detail .view-faq-detail-middle .view-faq-detail-middle-content-body .message-content {
      margin-top: 12px; }
      .view-faq-detail .view-faq-detail-middle .view-faq-detail-middle-content-body .message-content img {
        width: 200px;
        height: 200px;
        object-fit: cover;
        border-radius: 8px;
        cursor: pointer;
        margin: 0 0 8px 0; }
      .view-faq-detail .view-faq-detail-middle .view-faq-detail-middle-content-body .message-content .content-caption {
        padding-top: 10px;
        margin-top: 0 !important; }
    .view-faq-detail .view-faq-detail-middle .view-faq-detail-middle-content-body .file-url {
      cursor: pointer; }
      .view-faq-detail .view-faq-detail-middle .view-faq-detail-middle-content-body .file-url img {
        width: 24px !important;
        height: 24px !important;
        margin-right: 7px; }
      .view-faq-detail .view-faq-detail-middle .view-faq-detail-middle-content-body .file-url div > p {
        margin-top: 2px;
        font-weight: bold;
        font-size: 14px;
        color: #ff7e00;
        line-height: 21px;
        letter-spacing: -0.4px;
        border-bottom: solid 1px #ff7e00; }
  .view-faq-detail .view-faq-detail-middle .view-faq-detail-middle-total-status {
    font-weight: bold; }
    .view-faq-detail .view-faq-detail-middle .view-faq-detail-middle-total-status > div {
      margin-right: 10%; }
    .view-faq-detail .view-faq-detail-middle .view-faq-detail-middle-total-status .flexing {
      font-weight: bold;
      font-size: 14px;
      line-height: 21px;
      letter-spacing: -0.4px; }
    .view-faq-detail .view-faq-detail-middle .view-faq-detail-middle-total-status .total {
      font-size: 24px;
      line-height: 30px;
      letter-spacing: -0.6px; }
  .view-faq-detail .view-faq-detail-middle:nth-last-child(1) {
    padding: 0; }

.view-faq-detail .view-faq-detail-bottom {
  padding: 24px 0 0; }
  .view-faq-detail .view-faq-detail-bottom .view-message-box {
    position: relative;
    width: 100%; }
  .view-faq-detail .view-faq-detail-bottom .view-message-box-left {
    display: inline-block;
    vertical-align: top;
    width: 400px; }
  .view-faq-detail .view-faq-detail-bottom .view-message-box-right {
    width: calc(100% - 400px);
    vertical-align: top;
    display: inline-block;
    padding-left: 24px; }

.view-faq-detail div:last-child .view-faq-detail-bottom-footer p {
  margin-bottom: 0; }

.view-faq-detail .scf-table-wrapper {
  width: 100%;
  position: relative;
  margin-top: 24px; }

.view-faq-detail .table-scf thead tr {
  background: #ffffff; }
  .view-faq-detail .table-scf thead tr .col_name {
    width: unset; }
  .view-faq-detail .table-scf thead tr td.col_id {
    width: 70px; }
  .view-faq-detail .table-scf thead tr td.col_type {
    width: 105px; }
  .view-faq-detail .table-scf thead tr td.col_action {
    width: 105px; }

.view-faq-detail .table-scf tbody
tr.no-hover {
  cursor: default !important; }
  .view-faq-detail .table-scf tbody
tr.no-hover:hover {
    background-color: #ffffff !important; }
    .view-faq-detail .table-scf tbody
tr.no-hover:hover td {
      color: #191919 !important; }

.view-faq-detail td {
  position: relative; }
  .view-faq-detail td.col_type {
    width: 105px; }
  .view-faq-detail td.col_action {
    width: 105px; }
  .view-faq-detail td:nth-child(1) div {
    display: flex; }
    .view-faq-detail td:nth-child(1) div p {
      font-size: 12px !important;
      letter-spacing: -0.4px; }
    .view-faq-detail td:nth-child(1) div img {
      margin-right: 4px;
      margin-top: 2px; }
  .view-faq-detail td:last-child a {
    color: #ff7e00; }
  .view-faq-detail td:last-child svg {
    position: relative;
    color: #ff7e00;
    font-size: 16px;
    margin-right: 4px;
    right: 0;
    top: -2px; }
  .view-faq-detail td .medium-logo {
    width: 16px;
    height: 16px; }

.view-faq-detail .custom-select-option-value {
  margin-top: 0px !important; }

.modal-image-broadcast.modal-dialog {
  width: 100vw !important; }

.modal-image-broadcast .modal-content {
  width: 100%;
  height: 100%;
  background: none; }

.modal-image-broadcast .modal-content .modal-body {
  width: 100%;
  height: 100%;
  background: rgba(25, 25, 25, 0.8); }

.modal-image-broadcast .modal-content .modal-body img.image-preview-val {
  max-width: calc(100vw - 130px);
  max-height: calc(100vh - 130px);
  background: #ffffff; }

.modal-image-broadcast .modal-content .modal-body .image-wrapper {
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  text-align: center; }
  .modal-image-broadcast .modal-content .modal-body .image-wrapper figure {
    margin: 0; }
    .modal-image-broadcast .modal-content .modal-body .image-wrapper figure.zoom {
      background-size: 180%;
      background-position: -100% -100%;
      background-repeat: no-repeat;
      position: relative;
      width: 100%;
      overflow: hidden;
      cursor: -webkit-zoom-in;
      cursor: zoom-in; }
      .modal-image-broadcast .modal-content .modal-body .image-wrapper figure.zoom img {
        -webkit-transition: opacity 0.5s;
        transition: opacity 0.5s;
        display: inline-block; }
        .modal-image-broadcast .modal-content .modal-body .image-wrapper figure.zoom img:hover {
          opacity: 0; }

.modal-image-broadcast .modal-content .modal-body .close-modal-image-broadcast {
  position: absolute;
  top: 20px;
  right: 20px;
  color: #ffffff;
  font-size: 40px;
  cursor: pointer;
  -webkit-transition: ease-in-out 0.1s;
  transition: ease-in-out 0.1s; }

.modal-image-broadcast
.modal-content
.modal-body
.close-modal-image-broadcast:hover {
  color: #c9c9c9; }

.edit-path-modal .modal-content {
  width: 800px;
  border-radius: 8px;
  padding: 32px;
  position: relative;
  max-height: 90vh;
  overflow-y: auto; }
  .edit-path-modal .modal-content .create-path-title {
    font-size: 20px;
    letter-spacing: -0.6px;
    line-height: 1; }
  .edit-path-modal .modal-content .close-modal-create-path {
    position: absolute;
    font-size: 22px;
    right: 0;
    top: 2px;
    cursor: pointer; }
  .edit-path-modal .modal-content .form-content-wrapper input, .edit-path-modal .modal-content .form-content-wrapper textarea, .edit-path-modal .modal-content .form-content-wrapper label {
    width: 100%;
    position: relative; }
    .edit-path-modal .modal-content .form-content-wrapper input .clear-all, .edit-path-modal .modal-content .form-content-wrapper textarea .clear-all, .edit-path-modal .modal-content .form-content-wrapper label .clear-all {
      color: var(--watermelon);
      cursor: pointer;
      position: absolute;
      right: 0;
      bottom: 0; }
      .edit-path-modal .modal-content .form-content-wrapper input .clear-all:hover, .edit-path-modal .modal-content .form-content-wrapper textarea .clear-all:hover, .edit-path-modal .modal-content .form-content-wrapper label .clear-all:hover {
        color: var(--watermelon-dark); }
  .edit-path-modal .modal-content .form-content-wrapper .input-counter {
    position: absolute;
    top: 0;
    right: 0; }
  .edit-path-modal .modal-content .button-create-path {
    width: 100%;
    margin-top: 20px; }
  .edit-path-modal .modal-content .create-path-type-wrapper {
    position: relative;
    width: 100%;
    display: block;
    line-height: 1; }
    .edit-path-modal .modal-content .create-path-type-wrapper .create-path-type-select {
      display: inline-block;
      border-radius: 8px;
      width: calc(50% - 5px);
      margin-right: 10px;
      border: solid 1px #dcdcdc;
      cursor: pointer;
      text-align: center;
      padding: 12px 5px;
      font-size: 16px;
      letter-spacing: -0.3px; }
      .edit-path-modal .modal-content .create-path-type-wrapper .create-path-type-select.create-path-type-active {
        border: solid 1px #ff7d00;
        color: #ff8d00; }
        .edit-path-modal .modal-content .create-path-type-wrapper .create-path-type-select.create-path-type-active svg {
          color: #ff7d00; }
      .edit-path-modal .modal-content .create-path-type-wrapper .create-path-type-select svg {
        margin-right: 4px;
        position: relative;
        top: -1px;
        color: rgba(25, 25, 25, 0.4); }
      .edit-path-modal .modal-content .create-path-type-wrapper .create-path-type-select:nth-child(2) {
        margin: 0; }
      .edit-path-modal .modal-content .create-path-type-wrapper .create-path-type-select:hover {
        border: solid 1px #ff7d00;
        color: #ff8d00; }
        .edit-path-modal .modal-content .create-path-type-wrapper .create-path-type-select:hover svg {
          color: #ff7d00; }
      .edit-path-modal .modal-content .create-path-type-wrapper .create-path-type-select.edit-path-type-select {
        border: none !important;
        cursor: default !important;
        padding: 0 !important;
        width: unset !important;
        line-height: 1; }
    .edit-path-modal .modal-content .create-path-type-wrapper.no-hover .create-path-type-select {
      color: inherit !important; }
      .edit-path-modal .modal-content .create-path-type-wrapper.no-hover .create-path-type-select svg {
        color: rgba(25, 25, 25, 0.4) !important; }
  .edit-path-modal .modal-content .choose-related-department-wrapper {
    position: relative;
    width: 100%;
    display: block; }
    .edit-path-modal .modal-content .choose-related-department-wrapper .choose-related-department-select {
      display: inline-block;
      border-radius: 8px;
      width: calc((100% / 3) - 10px);
      margin-right: 15px;
      border: solid 1px #dcdcdc;
      cursor: pointer;
      text-align: left;
      padding: 12px 13px;
      font-size: 16px;
      letter-spacing: -0.3px;
      margin-bottom: 10px; }
      .edit-path-modal .modal-content .choose-related-department-wrapper .choose-related-department-select label {
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: 100%;
        overflow: hidden; }
      .edit-path-modal .modal-content .choose-related-department-wrapper .choose-related-department-select.choose-related-department-select-active {
        border: solid 1px #ff7d00; }
        .edit-path-modal .modal-content .choose-related-department-wrapper .choose-related-department-select.choose-related-department-select-active label {
          color: #ff7d00; }
      .edit-path-modal .modal-content .choose-related-department-wrapper .choose-related-department-select svg {
        margin-right: 4px;
        position: relative;
        top: -1px;
        color: rgba(25, 25, 25, 0.4); }
      .edit-path-modal .modal-content .choose-related-department-wrapper .choose-related-department-select:nth-child(3) {
        margin: 0; }
      .edit-path-modal .modal-content .choose-related-department-wrapper .choose-related-department-select:hover {
        border: solid 1px #ff7d00; }
        .edit-path-modal .modal-content .choose-related-department-wrapper .choose-related-department-select:hover label {
          color: #ff7d00; }
      .edit-path-modal .modal-content .choose-related-department-wrapper .choose-related-department-select label {
        margin: 0; }

.edit-path-modal .modal-body {
  padding: 0; }

.inbox-settings .button-save {
  margin-top: 28px; }

.inbox-settings .status-wrapper {
  font-size: 16px; }
  .inbox-settings .status-wrapper .status-value {
    color: rgba(25, 25, 25, 0.4);
    font-size: 16px;
    letter-spacing: -0.5px; }
    .inbox-settings .status-wrapper .status-value.status-enabled {
      color: #19C700; }
  .inbox-settings .status-wrapper img {
    position: relative;
    top: -2px;
    margin-right: 4px; }

.inbox-settings .inbox-settings-header {
  font-size: 20px;
  letter-spacing: -0.6px;
  color: #191919;
  padding-bottom: 11px;
  border-bottom: solid 1px #dcdcdc; }

.inbox-settings .inbox-settings-form {
  padding-top: 24px;
  position: relative; }
  .inbox-settings .inbox-settings-form .closing-welcome-switch-wrapper {
    position: absolute;
    top: 38px;
    right: 0;
    vertical-align: top;
    display: inline-block;
    margin: 0 16px; }
    .inbox-settings .inbox-settings-form .closing-welcome-switch-wrapper .custom-switch-wrapper {
      margin: 0;
      height: 28px;
      width: 48px; }
      .inbox-settings .inbox-settings-form .closing-welcome-switch-wrapper .custom-switch-wrapper .custom-switch:before {
        width: 15px;
        height: 15px;
        bottom: 7px; }
    .inbox-settings .inbox-settings-form .closing-welcome-switch-wrapper input:checked + .custom-switch:before {
      -webkit-transform: translateX(17px);
              transform: translateX(17px); }
  .inbox-settings .inbox-settings-form .inbox-settings-form-content {
    display: block;
    width: 100%;
    font-size: 16px;
    letter-spacing: -0.5px;
    border-bottom: solid 1px #dcdcdc;
    margin-bottom: 20px;
    position: relative;
    padding-right: 124px; }
    .inbox-settings .inbox-settings-form .inbox-settings-form-content button {
      position: absolute;
      right: 0;
      top: 0;
      margin: 0;
      display: inline-block;
      width: unset; }
    .inbox-settings .inbox-settings-form .inbox-settings-form-content.content-need-reply {
      margin: 0; }
    .inbox-settings .inbox-settings-form .inbox-settings-form-content.content-last-item {
      margin: 0; }
    .inbox-settings .inbox-settings-form .inbox-settings-form-content .need-reply-val {
      letter-spacing: -0.5px;
      font-size: 16px;
      color: rgba(25, 25, 25, 0.6); }
    .inbox-settings .inbox-settings-form .inbox-settings-form-content p {
      margin: 4px 0 10px;
      font-size: 14px;
      letter-spacing: -0.2px;
      color: rgba(25, 25, 25, 0.8); }
    .inbox-settings .inbox-settings-form .inbox-settings-form-content textarea {
      width: 100%;
      border-radius: 8px;
      font-size: 16px;
      letter-spacing: -0.3px;
      border: solid 1px #dcdcdc;
      resize: none;
      padding: 12px 16px;
      line-height: 24px;
      margin: 0;
      max-width: 416px;
      width: 100%;
      display: block; }
      .inbox-settings .inbox-settings-form .inbox-settings-form-content textarea:focus {
        border-color: #ff7d00; }
      .inbox-settings .inbox-settings-form .inbox-settings-form-content textarea[disabled] {
        color: rgba(25, 25, 25, 0.4);
        background: rgba(25, 25, 25, 0.1); }

.inbox-settings .button-save {
  margin-top: 28px; }

.inbox-settings .status-wrapper {
  font-size: 16px; }
  .inbox-settings .status-wrapper .status-value {
    color: rgba(25, 25, 25, 0.4);
    font-size: 16px;
    letter-spacing: -0.5px; }
    .inbox-settings .status-wrapper .status-value.status-enabled {
      color: #19C700; }
  .inbox-settings .status-wrapper img {
    position: relative;
    top: -2px;
    margin-right: 4px; }

.inbox-settings .othertab-settings-header {
  font-size: 20px;
  letter-spacing: -0.6px;
  color: #191919;
  padding-bottom: 24px;
  border-bottom: solid 1px #dcdcdc;
  position: relative; }
  .inbox-settings .othertab-settings-header button {
    position: absolute;
    top: 0;
    right: 0; }

.inbox-settings .othertab-settings-form {
  padding-top: 24px;
  position: relative; }
  .inbox-settings .othertab-settings-form .closing-welcome-switch-wrapper {
    position: absolute;
    top: 0;
    right: 0;
    vertical-align: top;
    display: inline-block;
    margin: 0 0 0 16px; }
    .inbox-settings .othertab-settings-form .closing-welcome-switch-wrapper .custom-switch-wrapper {
      margin: 0;
      height: 28px;
      width: 48px; }
      .inbox-settings .othertab-settings-form .closing-welcome-switch-wrapper .custom-switch-wrapper .custom-switch:before {
        width: 15px;
        height: 15px;
        bottom: 7px; }
    .inbox-settings .othertab-settings-form .closing-welcome-switch-wrapper input:checked + .custom-switch:before {
      -webkit-transform: translateX(17px);
              transform: translateX(17px); }
  .inbox-settings .othertab-settings-form .othertab-settings-form-content {
    display: block;
    width: 100%;
    font-size: 16px;
    letter-spacing: -0.5px;
    margin-bottom: 20px;
    position: relative;
    padding-right: 64px; }
    .inbox-settings .othertab-settings-form .othertab-settings-form-content button {
      position: absolute;
      right: 0;
      top: 0;
      margin: 0;
      display: inline-block;
      width: unset; }
    .inbox-settings .othertab-settings-form .othertab-settings-form-content.content-need-reply {
      margin: 0; }
    .inbox-settings .othertab-settings-form .othertab-settings-form-content .need-reply-val {
      letter-spacing: -0.5px;
      font-size: 16px;
      color: rgba(25, 25, 25, 0.6); }
    .inbox-settings .othertab-settings-form .othertab-settings-form-content p {
      margin: 4px 0 10px;
      font-size: 14px;
      letter-spacing: -0.2px;
      color: rgba(25, 25, 25, 0.8); }
    .inbox-settings .othertab-settings-form .othertab-settings-form-content textarea {
      width: 100%;
      border-radius: 8px;
      font-size: 16px;
      letter-spacing: -0.3px;
      border: solid 1px #dcdcdc;
      resize: none;
      padding: 12px 16px;
      line-height: 24px;
      margin: 0;
      max-width: 416px;
      width: 100%;
      display: block; }
      .inbox-settings .othertab-settings-form .othertab-settings-form-content textarea:focus {
        border-color: #ff7d00; }
      .inbox-settings .othertab-settings-form .othertab-settings-form-content textarea[disabled] {
        color: rgba(25, 25, 25, 0.4);
        background: rgba(25, 25, 25, 0.1); }

.inbox-settings .table-other-tab-config {
  width: 100%;
  border-collapse: collapse; }
  .inbox-settings .table-other-tab-config thead tr td {
    border-bottom: solid 1px #dcdcdc;
    padding: 8px;
    font-size: 14px;
    letter-spacing: -0.4px; }
  .inbox-settings .table-other-tab-config tbody tr {
    border-bottom: solid 1px #dcdcdc; }
    .inbox-settings .table-other-tab-config tbody tr td {
      padding: 8px;
      font-size: 14px;
      letter-spacing: -0.2px; }
      .inbox-settings .table-other-tab-config tbody tr td.option-td {
        width: 190px; }
    .inbox-settings .table-other-tab-config tbody tr .custom-select-other-tab-config button {
      padding: 8px 16px 8px 16px; }
      .inbox-settings .table-other-tab-config tbody tr .custom-select-other-tab-config button p {
        font-size: 14px;
        letter-spacing: -0.2px; }
      .inbox-settings .table-other-tab-config tbody tr .custom-select-other-tab-config button svg {
        font-size: 20px; }

.inbox-settings .button-save {
  margin-top: 28px; }

.inbox-settings .status-wrapper {
  font-size: 16px; }
  .inbox-settings .status-wrapper .status-value {
    color: rgba(25, 25, 25, 0.4);
    font-size: 16px;
    letter-spacing: -0.5px; }
    .inbox-settings .status-wrapper .status-value.status-enabled {
      color: #19C700; }
  .inbox-settings .status-wrapper img {
    position: relative;
    top: -2px;
    margin-right: 4px; }

.inbox-settings .ResolvedTab-settings-header {
  font-size: 20px;
  letter-spacing: -0.6px;
  color: #191919;
  padding-bottom: 24px;
  border-bottom: solid 1px #dcdcdc;
  position: relative; }
  .inbox-settings .ResolvedTab-settings-header button {
    position: absolute;
    top: 0;
    right: 0; }

.inbox-settings .ResolvedTab-settings-form {
  padding-top: 24px;
  position: relative; }
  .inbox-settings .ResolvedTab-settings-form .closing-welcome-switch-wrapper {
    position: absolute;
    top: 0;
    right: 0;
    vertical-align: top;
    display: inline-block;
    margin: 0 0 0 16px; }
    .inbox-settings .ResolvedTab-settings-form .closing-welcome-switch-wrapper .custom-switch-wrapper {
      margin: 0;
      height: 28px;
      width: 48px; }
      .inbox-settings .ResolvedTab-settings-form .closing-welcome-switch-wrapper .custom-switch-wrapper .custom-switch:before {
        width: 15px;
        height: 15px;
        bottom: 7px; }
    .inbox-settings .ResolvedTab-settings-form .closing-welcome-switch-wrapper input:checked + .custom-switch:before {
      -webkit-transform: translateX(17px);
              transform: translateX(17px); }
  .inbox-settings .ResolvedTab-settings-form .ResolvedTab-settings-form-content {
    display: block;
    width: 100%;
    font-size: 16px;
    letter-spacing: -0.5px;
    margin-bottom: 20px;
    position: relative;
    padding-right: 64px; }
    .inbox-settings .ResolvedTab-settings-form .ResolvedTab-settings-form-content button {
      position: absolute;
      right: 0;
      top: 0;
      margin: 0;
      display: inline-block;
      width: unset; }
    .inbox-settings .ResolvedTab-settings-form .ResolvedTab-settings-form-content.content-need-reply {
      margin: 0; }
    .inbox-settings .ResolvedTab-settings-form .ResolvedTab-settings-form-content .need-reply-val {
      letter-spacing: -0.5px;
      font-size: 16px;
      color: rgba(25, 25, 25, 0.6); }
    .inbox-settings .ResolvedTab-settings-form .ResolvedTab-settings-form-content p {
      margin: 4px 0 10px;
      font-size: 14px;
      letter-spacing: -0.2px;
      color: rgba(25, 25, 25, 0.8); }
    .inbox-settings .ResolvedTab-settings-form .ResolvedTab-settings-form-content textarea {
      width: 100%;
      border-radius: 8px;
      font-size: 16px;
      letter-spacing: -0.3px;
      border: solid 1px #dcdcdc;
      resize: none;
      padding: 12px 16px;
      line-height: 24px;
      margin: 0;
      max-width: 416px;
      width: 100%;
      display: block; }
      .inbox-settings .ResolvedTab-settings-form .ResolvedTab-settings-form-content textarea:focus {
        border-color: #ff7d00; }
      .inbox-settings .ResolvedTab-settings-form .ResolvedTab-settings-form-content textarea[disabled] {
        color: rgba(25, 25, 25, 0.4);
        background: rgba(25, 25, 25, 0.1); }

.inbox-settings .table-other-tab-config {
  width: 100%;
  border-collapse: collapse; }
  .inbox-settings .table-other-tab-config thead tr td {
    border-bottom: solid 1px #dcdcdc;
    padding: 8px;
    font-size: 14px;
    letter-spacing: -0.4px; }
  .inbox-settings .table-other-tab-config tbody tr {
    border-bottom: solid 1px #dcdcdc; }
    .inbox-settings .table-other-tab-config tbody tr td {
      padding: 8px;
      font-size: 14px;
      letter-spacing: -0.2px; }
      .inbox-settings .table-other-tab-config tbody tr td.option-td {
        width: 190px; }
    .inbox-settings .table-other-tab-config tbody tr .custom-select-other-tab-config button {
      padding: 8px 16px 8px 16px; }
      .inbox-settings .table-other-tab-config tbody tr .custom-select-other-tab-config button p {
        font-size: 14px;
        letter-spacing: -0.2px; }
      .inbox-settings .table-other-tab-config tbody tr .custom-select-other-tab-config button svg {
        font-size: 20px; }

.inbox-settings .button-save {
  margin-top: 28px; }

.inbox-settings .status-wrapper {
  font-size: 16px; }
  .inbox-settings .status-wrapper .status-value {
    color: rgba(25, 25, 25, 0.4);
    font-size: 16px;
    letter-spacing: -0.5px; }
    .inbox-settings .status-wrapper .status-value.status-enabled {
      color: #19C700; }
  .inbox-settings .status-wrapper img {
    position: relative;
    top: -2px;
    margin-right: 4px; }

.inbox-settings .PhoneNumber-settings-header {
  font-size: 20px;
  letter-spacing: -0.6px;
  color: #191919;
  padding-bottom: 24px;
  border-bottom: solid 1px #dcdcdc;
  position: relative; }
  .inbox-settings .PhoneNumber-settings-header button {
    position: absolute;
    top: 0;
    right: 0; }

.inbox-settings .PhoneNumber-settings-form {
  padding-top: 24px;
  position: relative; }
  .inbox-settings .PhoneNumber-settings-form .closing-welcome-switch-wrapper {
    position: absolute;
    top: 0;
    right: 0;
    vertical-align: top;
    display: inline-block;
    margin: 0 0 0 16px; }
    .inbox-settings .PhoneNumber-settings-form .closing-welcome-switch-wrapper .custom-switch-wrapper {
      margin: 0;
      height: 28px;
      width: 48px; }
      .inbox-settings .PhoneNumber-settings-form .closing-welcome-switch-wrapper .custom-switch-wrapper .custom-switch:before {
        width: 15px;
        height: 15px;
        bottom: 7px; }
    .inbox-settings .PhoneNumber-settings-form .closing-welcome-switch-wrapper input:checked + .custom-switch:before {
      -webkit-transform: translateX(17px);
              transform: translateX(17px); }
  .inbox-settings .PhoneNumber-settings-form .PhoneNumber-settings-form-content {
    display: block;
    width: 100%;
    font-size: 16px;
    letter-spacing: -0.5px;
    margin-bottom: 20px;
    position: relative;
    padding-right: 64px; }
    .inbox-settings .PhoneNumber-settings-form .PhoneNumber-settings-form-content button {
      position: absolute;
      right: 0;
      top: 0;
      margin: 0;
      display: inline-block;
      width: unset; }
    .inbox-settings .PhoneNumber-settings-form .PhoneNumber-settings-form-content.content-need-reply {
      margin: 0; }
    .inbox-settings .PhoneNumber-settings-form .PhoneNumber-settings-form-content .need-reply-val {
      letter-spacing: -0.5px;
      font-size: 16px;
      color: rgba(25, 25, 25, 0.6); }
    .inbox-settings .PhoneNumber-settings-form .PhoneNumber-settings-form-content p {
      margin: 4px 0 10px;
      font-size: 14px;
      letter-spacing: -0.2px;
      color: rgba(25, 25, 25, 0.8); }
    .inbox-settings .PhoneNumber-settings-form .PhoneNumber-settings-form-content textarea {
      width: 100%;
      border-radius: 8px;
      font-size: 16px;
      letter-spacing: -0.3px;
      border: solid 1px #dcdcdc;
      resize: none;
      padding: 12px 16px;
      line-height: 24px;
      margin: 0;
      max-width: 416px;
      width: 100%;
      display: block; }
      .inbox-settings .PhoneNumber-settings-form .PhoneNumber-settings-form-content textarea:focus {
        border-color: #ff7d00; }
      .inbox-settings .PhoneNumber-settings-form .PhoneNumber-settings-form-content textarea[disabled] {
        color: rgba(25, 25, 25, 0.4);
        background: rgba(25, 25, 25, 0.1); }

.inbox-settings .table-other-tab-config {
  width: 100%;
  border-collapse: collapse; }
  .inbox-settings .table-other-tab-config thead tr td {
    border-bottom: solid 1px #dcdcdc;
    padding: 8px;
    font-size: 14px;
    letter-spacing: -0.4px;
    white-space: nowrap; }
  .inbox-settings .table-other-tab-config tbody tr {
    border-bottom: solid 1px #dcdcdc; }
    .inbox-settings .table-other-tab-config tbody tr td {
      padding: 8px;
      font-size: 14px;
      letter-spacing: -0.2px; }
      .inbox-settings .table-other-tab-config tbody tr td.option-td {
        width: 190px; }
    .inbox-settings .table-other-tab-config tbody tr .custom-select-other-tab-config button {
      padding: 8px 16px 8px 16px; }
      .inbox-settings .table-other-tab-config tbody tr .custom-select-other-tab-config button p {
        font-size: 14px;
        letter-spacing: -0.2px; }
      .inbox-settings .table-other-tab-config tbody tr .custom-select-other-tab-config button svg {
        font-size: 20px; }

.inbox-settings .button-save {
  margin-top: 28px; }

.inbox-settings .status-wrapper {
  font-size: 16px; }
  .inbox-settings .status-wrapper .status-value {
    color: rgba(25, 25, 25, 0.4);
    font-size: 16px;
    letter-spacing: -0.5px; }
    .inbox-settings .status-wrapper .status-value.status-enabled {
      color: #19C700; }
  .inbox-settings .status-wrapper img {
    position: relative;
    top: -2px;
    margin-right: 4px; }

.inbox-settings .needreply-settings-header {
  font-size: 20px;
  letter-spacing: -0.6px;
  color: #191919;
  padding-bottom: 24px;
  border-bottom: solid 1px #dcdcdc;
  position: relative; }
  .inbox-settings .needreply-settings-header button {
    position: absolute;
    top: 0;
    right: 0; }

.inbox-settings .needreply-settings-form {
  padding-top: 24px;
  position: relative; }
  .inbox-settings .needreply-settings-form .custom-select-need-reply-config {
    position: absolute;
    top: 6px;
    right: 0;
    width: 143px; }
    .inbox-settings .needreply-settings-form .custom-select-need-reply-config button {
      margin: 0; }
    .inbox-settings .needreply-settings-form .custom-select-need-reply-config .dropdown .custom-select-option-value-wrapper {
      padding: 8px 41px 8px 16px !important; }
    .inbox-settings .needreply-settings-form .custom-select-need-reply-config .dropdown p {
      margin: 0;
      padding: 0; }
    .inbox-settings .needreply-settings-form .custom-select-need-reply-config .dropdown svg {
      font-size: 20px; }
    .inbox-settings .needreply-settings-form .custom-select-need-reply-config .dropdown .dropdown-option-wrapper {
      min-width: unset; }
  .inbox-settings .needreply-settings-form .needreply-settings-form-content {
    display: block;
    width: 100%;
    font-size: 16px;
    letter-spacing: -0.5px;
    margin-bottom: 8px;
    position: relative;
    padding-right: 158px; }
    .inbox-settings .needreply-settings-form .needreply-settings-form-content.content-need-reply {
      margin: 0; }
    .inbox-settings .needreply-settings-form .needreply-settings-form-content .need-reply-val {
      letter-spacing: -0.5px;
      font-size: 16px;
      color: rgba(25, 25, 25, 0.6); }
    .inbox-settings .needreply-settings-form .needreply-settings-form-content p {
      margin: 4px 0 10px;
      font-size: 14px;
      letter-spacing: -0.2px;
      color: rgba(25, 25, 25, 0.8); }
    .inbox-settings .needreply-settings-form .needreply-settings-form-content textarea {
      width: 100%;
      border-radius: 8px;
      font-size: 16px;
      letter-spacing: -0.3px;
      border: solid 1px #dcdcdc;
      resize: none;
      padding: 12px 16px;
      line-height: 24px;
      margin: 0;
      max-width: 416px;
      width: 100%;
      display: block; }
      .inbox-settings .needreply-settings-form .needreply-settings-form-content textarea:focus {
        border-color: #ff7d00; }
      .inbox-settings .needreply-settings-form .needreply-settings-form-content textarea[disabled] {
        color: rgba(25, 25, 25, 0.4);
        background: rgba(25, 25, 25, 0.1); }

.inbox-settings .need-reply-color-picker-wrapper {
  border: solid 1px rgba(25, 25, 25, 0.1);
  border-radius: 8px;
  padding: 7px 16px;
  width: 220px;
  position: relative;
  display: inline-block;
  margin: 0 0 8px 0; }
  .inbox-settings .need-reply-color-picker-wrapper .input-color {
    width: calc(100% - 128px) !important;
    border: 0 !important;
    padding: 0 !important;
    font-family: PTRootUI !important;
    position: absolute !important;
    left: 26px;
    top: 50%;
    -webkit-transform: translate(0, -50%);
            transform: translate(0, -50%);
    margin: 0; }
  .inbox-settings .need-reply-color-picker-wrapper .need-reply-badge {
    position: absolute;
    right: 16px;
    top: 50%;
    -webkit-transform: translate(0, -50%);
            transform: translate(0, -50%);
    font-size: 12px;
    border-radius: 100px;
    padding: 3px 8px;
    background: #ff3f57;
    color: #fff; }
  .inbox-settings .need-reply-color-picker-wrapper .color-picker-container {
    background: #ffffff;
    position: absolute;
    bottom: 0;
    right: -265px;
    border-radius: 4px;
    box-shadow: 0 2px 20px 0 rgba(25, 25, 25, 0.18) !important;
    padding: 14px;
    z-index: 9999; }
    .inbox-settings .need-reply-color-picker-wrapper .color-picker-container .close-color-picker {
      position: absolute;
      top: 6px;
      font-size: 14px;
      right: 6px;
      color: rgba(25, 25, 25, 0.6); }
      .inbox-settings .need-reply-color-picker-wrapper .color-picker-container .close-color-picker:hover {
        color: rgba(25, 25, 25, 0.8); }
    .inbox-settings .need-reply-color-picker-wrapper .color-picker-container::before {
      position: absolute;
      background: #ffffff;
      width: 16px;
      height: 16px;
      content: '';
      left: -8px;
      -webkit-transform: rotate(45deg);
              transform: rotate(45deg);
      bottom: 17px; }
    .inbox-settings .need-reply-color-picker-wrapper .color-picker-container .sketch-picker {
      border-radius: 4px;
      box-shadow: none !important; }
      .inbox-settings .need-reply-color-picker-wrapper .color-picker-container .sketch-picker .flexbox-fix:nth-child(2) {
        height: 28px; }
        .inbox-settings .need-reply-color-picker-wrapper .color-picker-container .sketch-picker .flexbox-fix:nth-child(2) div:nth-child(1) div:nth-child(1) {
          height: 23px !important; }
          .inbox-settings .need-reply-color-picker-wrapper .color-picker-container .sketch-picker .flexbox-fix:nth-child(2) div:nth-child(1) div:nth-child(1) div div.hue-horizontal div div {
            display: block !important;
            margin-top: 0 !important; }
        .inbox-settings .need-reply-color-picker-wrapper .color-picker-container .sketch-picker .flexbox-fix:nth-child(2) div:nth-child(1) div:nth-child(2) div {
          display: none; }
      .inbox-settings .need-reply-color-picker-wrapper .color-picker-container .sketch-picker .flexbox-fix:nth-child(3) {
        display: none !important; }
      .inbox-settings .need-reply-color-picker-wrapper .color-picker-container .sketch-picker .flexbox-fix:nth-child(4) div div {
        border-radius: 50% !important; }

.inbox-settings .flexbox-fix:nth-child(3) {
  display: none !important; }

.inbox-settings .need-reply-color-config-wrapper {
  width: 100%;
  border-collapse: collapse;
  display: block;
  position: relative; }
  .inbox-settings .need-reply-color-config-wrapper .need-reply-color-config-label {
    margin-top: 4px; }
  .inbox-settings .need-reply-color-config-wrapper .need-reply-color-picker-desc {
    line-height: 1;
    font-size: 14px;
    letter-spacing: -0.2px; }
  .inbox-settings .need-reply-color-config-wrapper .change-color-wrapper {
    display: block;
    width: 100%;
    position: relative; }
  .inbox-settings .need-reply-color-config-wrapper .title-after {
    font-size: 14px;
    display: inline-block;
    letter-spacing: -0.2px;
    margin: 0 6px; }
  .inbox-settings .need-reply-color-config-wrapper input[type="text"] {
    border: solid 1px rgba(25, 25, 25, 0.1);
    border-radius: 8px;
    padding: 7px 16px;
    width: 220px;
    position: relative;
    display: inline-block;
    margin: 0 0 8px 0;
    font-family: PTRootBold;
    width: 62px; }
  .inbox-settings .need-reply-color-config-wrapper .custom-select-time-config {
    width: 118px;
    display: inline-block;
    margin-left: 4px; }
    .inbox-settings .need-reply-color-config-wrapper .custom-select-time-config .custom-select-option-value-wrapper {
      padding: 7px 16px 7px 8px !important;
      position: relative;
      top: -2px;
      font-family: PTRootBold;
      color: #191919;
      font-size: 16px;
      letter-spacing: -0.5px; }
    .inbox-settings .need-reply-color-config-wrapper .custom-select-time-config svg {
      font-size: 12px;
      right: 8px; }
    .inbox-settings .need-reply-color-config-wrapper .custom-select-time-config .dropdown-option-wrapper {
      min-width: unset; }

.modal-confirmation-need-reply .modal-footer button {
  width: calc(50% - 2px) !important; }
  .modal-confirmation-need-reply .modal-footer button:nth-child(2) {
    margin: 0; }

.inbox-settings .button-save {
  margin-top: 28px; }

.inbox-settings .status-wrapper {
  font-size: 16px; }
  .inbox-settings .status-wrapper .status-value {
    color: rgba(25, 25, 25, 0.4);
    font-size: 16px;
    letter-spacing: -0.5px; }
    .inbox-settings .status-wrapper .status-value.status-enabled {
      color: #19C700; }
  .inbox-settings .status-wrapper img {
    position: relative;
    top: -2px;
    margin-right: 4px; }

.inbox-settings .allowreply-settings-header {
  font-size: 20px;
  letter-spacing: -0.6px;
  color: #191919;
  padding-bottom: 24px;
  border-bottom: solid 1px #dcdcdc;
  position: relative; }
  .inbox-settings .allowreply-settings-header button {
    position: absolute;
    top: 0;
    right: 0; }

.inbox-settings .allowreply-settings-form {
  padding-top: 24px;
  position: relative; }
  .inbox-settings .allowreply-settings-form .closing-welcome-switch-wrapper {
    position: absolute;
    top: 0;
    right: 0;
    vertical-align: top;
    display: inline-block;
    margin: 0 0 0 16px; }
    .inbox-settings .allowreply-settings-form .closing-welcome-switch-wrapper .custom-switch-wrapper {
      margin: 0;
      height: 28px;
      width: 48px; }
      .inbox-settings .allowreply-settings-form .closing-welcome-switch-wrapper .custom-switch-wrapper .custom-switch:before {
        width: 15px;
        height: 15px;
        bottom: 7px; }
    .inbox-settings .allowreply-settings-form .closing-welcome-switch-wrapper input:checked + .custom-switch:before {
      -webkit-transform: translateX(17px);
              transform: translateX(17px); }
  .inbox-settings .allowreply-settings-form .allowreply-settings-form-content {
    display: block;
    width: 100%;
    font-size: 16px;
    letter-spacing: -0.5px;
    margin-bottom: 20px;
    position: relative;
    padding-right: 64px; }
    .inbox-settings .allowreply-settings-form .allowreply-settings-form-content button {
      position: absolute;
      right: 0;
      top: 0;
      margin: 0;
      display: inline-block;
      width: unset; }
    .inbox-settings .allowreply-settings-form .allowreply-settings-form-content.content-need-reply {
      margin: 0; }
    .inbox-settings .allowreply-settings-form .allowreply-settings-form-content .need-reply-val {
      letter-spacing: -0.5px;
      font-size: 16px;
      color: rgba(25, 25, 25, 0.6); }
    .inbox-settings .allowreply-settings-form .allowreply-settings-form-content p {
      margin: 4px 0 10px;
      font-size: 14px;
      letter-spacing: -0.2px;
      color: rgba(25, 25, 25, 0.8); }
    .inbox-settings .allowreply-settings-form .allowreply-settings-form-content textarea {
      width: 100%;
      border-radius: 8px;
      font-size: 16px;
      letter-spacing: -0.3px;
      border: solid 1px #dcdcdc;
      resize: none;
      padding: 12px 16px;
      line-height: 24px;
      margin: 0;
      max-width: 416px;
      width: 100%;
      display: block; }
      .inbox-settings .allowreply-settings-form .allowreply-settings-form-content textarea:focus {
        border-color: #ff7d00; }
      .inbox-settings .allowreply-settings-form .allowreply-settings-form-content textarea[disabled] {
        color: rgba(25, 25, 25, 0.4);
        background: rgba(25, 25, 25, 0.1); }

.inbox-settings .table-other-tab-config {
  width: 100%;
  border-collapse: collapse; }
  .inbox-settings .table-other-tab-config thead tr td {
    border-bottom: solid 1px #dcdcdc;
    padding: 8px;
    font-size: 14px;
    letter-spacing: -0.4px; }
  .inbox-settings .table-other-tab-config tbody tr {
    border-bottom: solid 1px #dcdcdc; }
    .inbox-settings .table-other-tab-config tbody tr td {
      padding: 8px;
      font-size: 14px;
      letter-spacing: -0.2px; }
      .inbox-settings .table-other-tab-config tbody tr td.option-td {
        width: 190px; }
    .inbox-settings .table-other-tab-config tbody tr .custom-select-other-tab-config button {
      padding: 8px 16px 8px 16px; }
      .inbox-settings .table-other-tab-config tbody tr .custom-select-other-tab-config button p {
        font-size: 14px;
        letter-spacing: -0.2px; }
      .inbox-settings .table-other-tab-config tbody tr .custom-select-other-tab-config button svg {
        font-size: 20px; }

.modal-confirmation-save-changes .modal-content {
  width: 476px !important; }

.modal-confirmation-save-changes button {
  width: calc(50% - 4px) !important;
  margin-right: 8px; }
  .modal-confirmation-save-changes button:nth-last-child(1) {
    margin-right: 0; }

.inbox-settings .button-save {
  margin-top: 28px; }

.inbox-settings .status-wrapper {
  font-size: 16px; }
  .inbox-settings .status-wrapper .status-value {
    color: rgba(25, 25, 25, 0.4);
    font-size: 16px;
    letter-spacing: -0.5px; }
    .inbox-settings .status-wrapper .status-value.status-enabled {
      color: #19C700; }
  .inbox-settings .status-wrapper img {
    position: relative;
    top: -2px;
    margin-right: 4px; }

.inbox-settings .allowreply-settings-header {
  font-size: 20px;
  letter-spacing: -0.6px;
  color: #191919;
  padding-bottom: 24px;
  border-bottom: solid 1px #dcdcdc;
  position: relative; }
  .inbox-settings .allowreply-settings-header button {
    position: absolute;
    top: 0;
    right: 0; }

.inbox-settings .allowreply-settings-form {
  padding-top: 24px;
  position: relative; }
  .inbox-settings .allowreply-settings-form .closing-welcome-switch-wrapper {
    position: absolute;
    top: 0;
    right: 0;
    vertical-align: top;
    display: inline-block;
    margin: 0 0 0 16px; }
    .inbox-settings .allowreply-settings-form .closing-welcome-switch-wrapper .custom-switch-wrapper {
      margin: 0;
      height: 28px;
      width: 48px; }
      .inbox-settings .allowreply-settings-form .closing-welcome-switch-wrapper .custom-switch-wrapper .custom-switch:before {
        width: 15px;
        height: 15px;
        bottom: 7px; }
    .inbox-settings .allowreply-settings-form .closing-welcome-switch-wrapper input:checked + .custom-switch:before {
      -webkit-transform: translateX(17px);
              transform: translateX(17px); }
  .inbox-settings .allowreply-settings-form .allowreply-settings-form-content {
    display: block;
    width: 100%;
    font-size: 16px;
    letter-spacing: -0.5px;
    margin-bottom: 20px;
    position: relative;
    padding-right: 64px; }
    .inbox-settings .allowreply-settings-form .allowreply-settings-form-content button {
      position: absolute;
      right: 0;
      top: 0;
      margin: 0;
      display: inline-block;
      width: unset; }
    .inbox-settings .allowreply-settings-form .allowreply-settings-form-content.content-need-reply {
      margin: 0; }
    .inbox-settings .allowreply-settings-form .allowreply-settings-form-content .need-reply-val {
      letter-spacing: -0.5px;
      font-size: 16px;
      color: rgba(25, 25, 25, 0.6); }
    .inbox-settings .allowreply-settings-form .allowreply-settings-form-content p {
      margin: 4px 0 10px;
      font-size: 14px;
      letter-spacing: -0.2px;
      color: rgba(25, 25, 25, 0.8); }
    .inbox-settings .allowreply-settings-form .allowreply-settings-form-content textarea {
      width: 100%;
      border-radius: 8px;
      font-size: 16px;
      letter-spacing: -0.3px;
      border: solid 1px #dcdcdc;
      resize: none;
      padding: 12px 16px;
      line-height: 24px;
      margin: 0;
      max-width: 416px;
      width: 100%;
      display: block; }
      .inbox-settings .allowreply-settings-form .allowreply-settings-form-content textarea:focus {
        border-color: #ff7d00; }
      .inbox-settings .allowreply-settings-form .allowreply-settings-form-content textarea[disabled] {
        color: rgba(25, 25, 25, 0.4);
        background: rgba(25, 25, 25, 0.1); }

.inbox-settings .table-other-tab-config {
  width: 100%;
  border-collapse: collapse; }
  .inbox-settings .table-other-tab-config thead tr td {
    border-bottom: solid 1px #dcdcdc;
    padding: 8px;
    font-size: 14px;
    letter-spacing: -0.4px; }
  .inbox-settings .table-other-tab-config tbody tr {
    border-bottom: solid 1px #dcdcdc; }
    .inbox-settings .table-other-tab-config tbody tr td {
      padding: 8px;
      font-size: 14px;
      letter-spacing: -0.2px; }
      .inbox-settings .table-other-tab-config tbody tr td.option-td {
        width: 190px; }
    .inbox-settings .table-other-tab-config tbody tr .custom-select-other-tab-config button {
      padding: 8px 16px 8px 16px; }
      .inbox-settings .table-other-tab-config tbody tr .custom-select-other-tab-config button p {
        font-size: 14px;
        letter-spacing: -0.2px; }
      .inbox-settings .table-other-tab-config tbody tr .custom-select-other-tab-config button svg {
        font-size: 20px; }

.modal-confirmation-save-changes .modal-content {
  width: 476px !important; }

.modal-confirmation-save-changes button {
  width: calc(50% - 4px) !important;
  margin-right: 8px; }
  .modal-confirmation-save-changes button:nth-last-child(1) {
    margin-right: 0; }

.closing-welcome-message .button-save {
  margin-top: 28px; }

.closing-welcome-message .closing-welcome-message-header {
  font-size: 20px;
  letter-spacing: -0.6px;
  color: #191919;
  padding-bottom: 20px;
  border-bottom: solid 1px #dcdcdc;
  position: relative; }
  .closing-welcome-message .closing-welcome-message-header button {
    position: absolute;
    top: -3px;
    right: 0;
    margin: 0; }

.closing-welcome-message .closing-welcome-message-form {
  padding-top: 24px;
  position: relative; }
  .closing-welcome-message .closing-welcome-message-form .closing-welcome-switch-wrapper {
    position: absolute;
    top: 38px;
    right: 0;
    vertical-align: top;
    display: inline-block;
    margin: 0 16px; }
    .closing-welcome-message .closing-welcome-message-form .closing-welcome-switch-wrapper .custom-switch-wrapper {
      margin: 0;
      height: 28px;
      width: 48px; }
      .closing-welcome-message .closing-welcome-message-form .closing-welcome-switch-wrapper .custom-switch-wrapper .custom-switch:before {
        width: 15px;
        height: 15px;
        bottom: 8px; }
    .closing-welcome-message .closing-welcome-message-form .closing-welcome-switch-wrapper input:checked + .custom-switch:before {
      -webkit-transform: translateX(17px);
              transform: translateX(17px); }
  .closing-welcome-message .closing-welcome-message-form .closing-welcome-message-form-content {
    display: inline-block;
    font-size: 16px;
    letter-spacing: -0.5px;
    max-width: calc(100% - 90px); }
    .closing-welcome-message .closing-welcome-message-form .closing-welcome-message-form-content p {
      margin: 4px 0 10px;
      font-size: 14px;
      letter-spacing: -0.2px;
      color: rgba(25, 25, 25, 0.8); }
    .closing-welcome-message .closing-welcome-message-form .closing-welcome-message-form-content label {
      width: 416px; }
    .closing-welcome-message .closing-welcome-message-form .closing-welcome-message-form-content textarea {
      width: 100%;
      border-radius: 8px;
      font-size: 16px;
      letter-spacing: -0.3px;
      border: solid 1px #dcdcdc;
      resize: none;
      padding: 12px 16px;
      line-height: 24px;
      margin: 0;
      max-width: 416px;
      width: 100%;
      display: block; }
      .closing-welcome-message .closing-welcome-message-form .closing-welcome-message-form-content textarea:focus {
        border-color: #ff7d00; }
      .closing-welcome-message .closing-welcome-message-form .closing-welcome-message-form-content textarea[disabled] {
        color: rgba(25, 25, 25, 0.4);
        background: rgba(25, 25, 25, 0.1); }

.away-welcome-message .button-save {
  margin-top: 28px; }

.away-welcome-message .away-welcome-message-header {
  font-size: 20px;
  letter-spacing: -0.6px;
  color: #191919;
  padding-bottom: 20px;
  border-bottom: solid 1px #dcdcdc;
  position: relative; }
  .away-welcome-message .away-welcome-message-header button {
    position: absolute;
    top: -3px;
    right: 0;
    margin: 0; }

.away-welcome-message .away-welcome-message-form {
  padding-top: 24px;
  position: relative; }
  .away-welcome-message .away-welcome-message-form .away-welcome-switch-wrapper {
    position: absolute;
    top: 38px;
    right: 0;
    vertical-align: top;
    display: inline-block;
    margin: 0 16px; }
    .away-welcome-message .away-welcome-message-form .away-welcome-switch-wrapper .custom-switch-wrapper {
      margin: 0;
      height: 28px;
      width: 48px; }
      .away-welcome-message .away-welcome-message-form .away-welcome-switch-wrapper .custom-switch-wrapper .custom-switch:before {
        width: 15px;
        height: 15px;
        bottom: 7px; }
    .away-welcome-message .away-welcome-message-form .away-welcome-switch-wrapper input:checked + .custom-switch:before {
      -webkit-transform: translateX(17px);
              transform: translateX(17px); }
  .away-welcome-message .away-welcome-message-form .away-welcome-message-form-content {
    display: inline-block;
    font-size: 16px;
    letter-spacing: -0.5px;
    max-width: calc(100% - 90px); }
    .away-welcome-message .away-welcome-message-form .away-welcome-message-form-content p {
      margin: 4px 0 10px;
      font-size: 14px;
      letter-spacing: -0.2px;
      color: rgba(25, 25, 25, 0.8); }
    .away-welcome-message .away-welcome-message-form .away-welcome-message-form-content label {
      width: 416px; }
    .away-welcome-message .away-welcome-message-form .away-welcome-message-form-content textarea {
      width: 100%;
      border-radius: 8px;
      font-size: 16px;
      letter-spacing: -0.3px;
      border: solid 1px #dcdcdc;
      resize: none;
      padding: 12px 16px;
      line-height: 24px;
      margin: 0;
      max-width: 416px;
      width: 100%;
      display: block; }
      .away-welcome-message .away-welcome-message-form .away-welcome-message-form-content textarea:focus {
        border-color: #ff7d00; }
      .away-welcome-message .away-welcome-message-form .away-welcome-message-form-content textarea[disabled] {
        color: rgba(25, 25, 25, 0.4);
        background: rgba(25, 25, 25, 0.1); }

.away-welcome-message .button-save {
  margin-top: 28px; }

.away-welcome-message .away-welcome-message-header {
  font-size: 20px;
  letter-spacing: -0.6px;
  color: #191919;
  padding-bottom: 20px;
  border-bottom: solid 1px #dcdcdc;
  position: relative; }
  .away-welcome-message .away-welcome-message-header button {
    position: absolute;
    top: -3px;
    right: 0;
    margin: 0; }

.away-welcome-message .away-welcome-message-form {
  padding-top: 24px;
  position: relative; }
  .away-welcome-message .away-welcome-message-form .away-welcome-switch-wrapper {
    position: absolute;
    top: 38px;
    right: 0;
    vertical-align: top;
    display: inline-block;
    margin: 0 16px; }
    .away-welcome-message .away-welcome-message-form .away-welcome-switch-wrapper .custom-switch-wrapper {
      margin: 0;
      height: 28px;
      width: 48px; }
      .away-welcome-message .away-welcome-message-form .away-welcome-switch-wrapper .custom-switch-wrapper .custom-switch:before {
        width: 15px;
        height: 15px;
        bottom: 7px; }
    .away-welcome-message .away-welcome-message-form .away-welcome-switch-wrapper input:checked + .custom-switch:before {
      -webkit-transform: translateX(17px);
              transform: translateX(17px); }
  .away-welcome-message .away-welcome-message-form .away-welcome-message-form-content {
    display: inline-block;
    font-size: 16px;
    letter-spacing: -0.5px;
    max-width: calc(100% - 90px); }
    .away-welcome-message .away-welcome-message-form .away-welcome-message-form-content p {
      margin: 4px 0 10px;
      font-size: 14px;
      letter-spacing: -0.2px;
      color: rgba(25, 25, 25, 0.8); }
    .away-welcome-message .away-welcome-message-form .away-welcome-message-form-content label {
      width: 416px; }
    .away-welcome-message .away-welcome-message-form .away-welcome-message-form-content textarea {
      width: 100%;
      border-radius: 8px;
      font-size: 16px;
      letter-spacing: -0.3px;
      border: solid 1px #dcdcdc;
      resize: none;
      padding: 12px 16px;
      line-height: 24px;
      margin: 0;
      max-width: 416px;
      width: 100%;
      display: block; }
      .away-welcome-message .away-welcome-message-form .away-welcome-message-form-content textarea:focus {
        border-color: #ff7d00; }
      .away-welcome-message .away-welcome-message-form .away-welcome-message-form-content textarea[disabled] {
        color: rgba(25, 25, 25, 0.4);
        background: rgba(25, 25, 25, 0.1); }

.office-hour-wrapper .office-hour-title {
  font-size: 20px;
  letter-spacing: -0.6px;
  margin: 0;
  color: #191919; }

.office-hour-wrapper .office-hour-description {
  margin: 8px 0 16px;
  font-size: 16px;
  letter-spacing: -0.3px;
  width: calc(100% - 230px);
  color: #191919;
  line-height: 24px; }

.office-hour-wrapper .add-office-hour-button {
  text-align: center;
  position: absolute;
  top: 32px;
  right: 32px; }

.office-hour-wrapper .office-hour-data-wrapper {
  padding: 32px 0 16px;
  border-top: solid 1px #dcdcdc;
  width: 100%;
  color: #191919; }
  .office-hour-wrapper .office-hour-data-wrapper table {
    width: 100%; }
    .office-hour-wrapper .office-hour-data-wrapper table thead {
      font-size: 16px;
      border-bottom: solid 1px #dcdcdc;
      color: #191919;
      letter-spacing: -0.4px; }
      .office-hour-wrapper .office-hour-data-wrapper table thead td {
        padding: 0 8px 8px; }
    .office-hour-wrapper .office-hour-data-wrapper table tbody {
      font-size: 14px;
      letter-spacing: -0.2px;
      color: #191919; }
      .office-hour-wrapper .office-hour-data-wrapper table tbody td {
        padding: 16px 8px; }
        .office-hour-wrapper .office-hour-data-wrapper table tbody td.no-hover {
          cursor: default !important; }
          .office-hour-wrapper .office-hour-data-wrapper table tbody td.no-hover:hover {
            background-color: #ffffff !important; }
            .office-hour-wrapper .office-hour-data-wrapper table tbody td.no-hover:hover td {
              color: #191919 !important; }
        .office-hour-wrapper .office-hour-data-wrapper table tbody td.col_action {
          font-size: 14px;
          letter-spacing: -0.4px; }
          .office-hour-wrapper .office-hour-data-wrapper table tbody td.col_action .edit-button {
            color: #ff7d00;
            cursor: pointer;
            margin-right: 16px; }
          .office-hour-wrapper .office-hour-data-wrapper table tbody td.col_action .remove-button {
            color: #ff3f57;
            cursor: pointer; }
          .office-hour-wrapper .office-hour-data-wrapper table tbody td.col_action svg {
            font-size: 16px;
            position: relative;
            top: -2px;
            margin-right: 4px; }
    .office-hour-wrapper .office-hour-data-wrapper table .no-office-hour {
      width: 100%;
      text-align: center;
      font-size: 16px;
      letter-spacing: -0.5px;
      color: rgba(25, 25, 25, 0.6);
      padding: 24px 0 0; }

.office-hour-form-wrapper .form-content-wrapper {
  width: 300px; }
  .office-hour-form-wrapper .form-content-wrapper button {
    margin: 0; }

.office-hour-form-wrapper .office-hour-form-title {
  font-size: 20px;
  letter-spacing: -0.6px;
  display: block;
  margin: 0 0 24px;
  padding-bottom: 11px;
  border-bottom: solid 1px #dcdcdc; }

.office-hour-form-wrapper .office-hour-time-form-wrapper {
  width: calc(50% - 4px);
  display: inline-block;
  margin-right: 8px;
  vertical-align: top;
  position: relative; }
  .office-hour-form-wrapper .office-hour-time-form-wrapper input {
    width: 100%;
    padding-right: 50px; }
  .office-hour-form-wrapper .office-hour-time-form-wrapper:nth-last-child(1) {
    margin: 0; }
  .office-hour-form-wrapper .office-hour-time-form-wrapper svg {
    font-size: 24px;
    color: #191919;
    position: absolute;
    right: 16px;
    top: 65px; }

.office-hour-form-wrapper .save-change-office-hour {
  margin-top: 24px; }

.office-hour-form-wrapper .form-content-wrapper {
  width: 300px; }
  .office-hour-form-wrapper .form-content-wrapper button {
    margin: 0; }

.office-hour-form-wrapper .office-hour-form-title {
  font-size: 20px;
  letter-spacing: -0.6px;
  display: block;
  margin: 0 0 24px;
  padding-bottom: 11px;
  border-bottom: solid 1px #dcdcdc; }

.office-hour-form-wrapper .office-hour-time-form-wrapper {
  width: calc(50% - 4px);
  display: inline-block;
  margin-right: 8px;
  vertical-align: top;
  position: relative; }
  .office-hour-form-wrapper .office-hour-time-form-wrapper input {
    width: 100%;
    padding-right: 50px; }
  .office-hour-form-wrapper .office-hour-time-form-wrapper:nth-last-child(1) {
    margin: 0; }
  .office-hour-form-wrapper .office-hour-time-form-wrapper svg {
    font-size: 24px;
    color: #191919;
    position: absolute;
    right: 16px;
    top: 65px; }

.office-hour-form-wrapper .day-of-week-office-hour {
  color: rgba(25, 25, 25, 0.6);
  background: rgba(25, 25, 25, 0.1);
  width: 100%;
  border: solid 1px #dcdcdc;
  border-radius: 8px;
  font-size: 14px;
  letter-spacing: -0.4px;
  padding: 12px 16px; }

.office-hour-form-wrapper .save-change-office-hour {
  margin-top: 24px; }

.user-rating-setup button {
  width: unset; }

.user-rating-setup .closing-welcome-switch-wrapper {
  margin: 0 !important; }

.user-rating-setup .message-wrapper {
  border-top: solid 1px #dcdcdc;
  margin-top: 24px;
  width: calc(100% + 90px); }
  .user-rating-setup .message-wrapper .message-wrapper-50 {
    width: calc(50% - 5px);
    display: inline-block;
    vertical-align: top;
    margin-right: 10px; }
    .user-rating-setup .message-wrapper .message-wrapper-50 .whatsapp-preview {
      margin-top: 24px !important; }
    .user-rating-setup .message-wrapper .message-wrapper-50 label {
      position: relative;
      max-width: 416px; }
      .user-rating-setup .message-wrapper .message-wrapper-50 label .grey-font {
        position: absolute;
        right: 0;
        top: 1px; }
    .user-rating-setup .message-wrapper .message-wrapper-50:nth-last-child(1) {
      margin-right: 0; }

.user-rating-setup .user-rating-setup-header {
  font-size: 20px;
  letter-spacing: -0.6px;
  color: #191919;
  padding-bottom: 20px;
  position: relative;
  border-bottom: solid 1px #dcdcdc; }
  .user-rating-setup .user-rating-setup-header button {
    position: absolute;
    top: -3px;
    right: 0;
    margin: 0; }

.user-rating-setup .user-rating-setup-form {
  padding-top: 24px;
  position: relative; }
  .user-rating-setup .user-rating-setup-form .color-select-wrapper {
    width: 100%;
    position: relative;
    padding-top: 12px; }
    .user-rating-setup .user-rating-setup-form .color-select-wrapper .button-go-preview {
      position: absolute;
      top: 41px;
      right: -90px; }
    .user-rating-setup .user-rating-setup-form .color-select-wrapper .color-input-wrapper {
      position: relative;
      width: 166px;
      height: 48px;
      border-radius: 8px;
      border: solid 1px #dcdcdc;
      cursor: pointer;
      line-height: 47px;
      font-size: 16px;
      letter-spacing: -0.3px;
      color: #191919;
      padding: 0 0 0 16px;
      margin-top: 12px; }
      .user-rating-setup .user-rating-setup-form .color-select-wrapper .color-input-wrapper .color-placeholder {
        color: rgba(25, 25, 25, 0.6); }
      .user-rating-setup .user-rating-setup-form .color-select-wrapper .color-input-wrapper img {
        position: absolute;
        position: absolute;
        right: 16px;
        top: 50%;
        -webkit-transform: translate(0, -50%);
                transform: translate(0, -50%); }
      .user-rating-setup .user-rating-setup-form .color-select-wrapper .color-input-wrapper .color-tag {
        display: inline-block !important;
        margin-right: 1px;
        margin: 0; }
      .user-rating-setup .user-rating-setup-form .color-select-wrapper .color-input-wrapper .color-value {
        width: 16px;
        height: 16px;
        display: inline-block;
        background: #dcdcdc;
        border-radius: 50%;
        position: absolute;
        right: 16px;
        top: 50%;
        -webkit-transform: translate(0, -50%);
                transform: translate(0, -50%); }
      .user-rating-setup .user-rating-setup-form .color-select-wrapper .color-input-wrapper .color-input {
        border: none;
        padding: 0;
        background-color: transparent;
        height: 20px;
        margin-top: 0 !important;
        height: 40px !important;
        display: inline-block;
        width: 125px !important; }
      .user-rating-setup .user-rating-setup-form .color-select-wrapper .color-input-wrapper .color-picker-container {
        background: #ffffff;
        position: absolute;
        bottom: 0;
        right: -265px;
        border-radius: 4px;
        box-shadow: 0 2px 20px 0 rgba(25, 25, 25, 0.18) !important;
        padding: 14px;
        z-index: 9999; }
        .user-rating-setup .user-rating-setup-form .color-select-wrapper .color-input-wrapper .color-picker-container .close-color-picker {
          position: absolute;
          top: 6px;
          font-size: 14px;
          right: 6px;
          color: rgba(25, 25, 25, 0.6); }
          .user-rating-setup .user-rating-setup-form .color-select-wrapper .color-input-wrapper .color-picker-container .close-color-picker:hover {
            color: rgba(25, 25, 25, 0.8); }
        .user-rating-setup .user-rating-setup-form .color-select-wrapper .color-input-wrapper .color-picker-container::before {
          position: absolute;
          background: #ffffff;
          width: 16px;
          height: 16px;
          content: '';
          left: -8px;
          -webkit-transform: rotate(45deg);
                  transform: rotate(45deg);
          bottom: 17px; }
        .user-rating-setup .user-rating-setup-form .color-select-wrapper .color-input-wrapper .color-picker-container .sketch-picker {
          border-radius: 4px;
          box-shadow: none !important; }
          .user-rating-setup .user-rating-setup-form .color-select-wrapper .color-input-wrapper .color-picker-container .sketch-picker .flexbox-fix:nth-child(2) {
            height: 28px; }
            .user-rating-setup .user-rating-setup-form .color-select-wrapper .color-input-wrapper .color-picker-container .sketch-picker .flexbox-fix:nth-child(2) div:nth-child(1) div:nth-child(1) {
              height: 23px !important; }
              .user-rating-setup .user-rating-setup-form .color-select-wrapper .color-input-wrapper .color-picker-container .sketch-picker .flexbox-fix:nth-child(2) div:nth-child(1) div:nth-child(1) div div.hue-horizontal div div {
                display: block !important;
                margin-top: 0 !important; }
            .user-rating-setup .user-rating-setup-form .color-select-wrapper .color-input-wrapper .color-picker-container .sketch-picker .flexbox-fix:nth-child(2) div:nth-child(1) div:nth-child(2) div {
              display: none; }
          .user-rating-setup .user-rating-setup-form .color-select-wrapper .color-input-wrapper .color-picker-container .sketch-picker .flexbox-fix:nth-child(3) {
            display: none !important; }
          .user-rating-setup .user-rating-setup-form .color-select-wrapper .color-input-wrapper .color-picker-container .sketch-picker .flexbox-fix:nth-child(4) div div {
            border-radius: 50% !important; }
    .user-rating-setup .user-rating-setup-form .color-select-wrapper .color-picker-container {
      background: #ffffff;
      position: absolute;
      bottom: 0;
      right: -265px;
      border-radius: 4px;
      box-shadow: 0 2px 20px 0 rgba(25, 25, 25, 0.18) !important;
      padding: 14px;
      z-index: 9999; }
      .user-rating-setup .user-rating-setup-form .color-select-wrapper .color-picker-container .close-color-picker {
        position: absolute;
        top: 6px;
        font-size: 14px;
        right: 6px;
        color: rgba(25, 25, 25, 0.6); }
        .user-rating-setup .user-rating-setup-form .color-select-wrapper .color-picker-container .close-color-picker:hover {
          color: rgba(25, 25, 25, 0.8); }
      .user-rating-setup .user-rating-setup-form .color-select-wrapper .color-picker-container::before {
        position: absolute;
        background: #ffffff;
        width: 16px;
        height: 16px;
        content: '';
        left: -8px;
        -webkit-transform: rotate(45deg);
                transform: rotate(45deg);
        bottom: 17px; }
      .user-rating-setup .user-rating-setup-form .color-select-wrapper .color-picker-container .sketch-picker {
        border-radius: 4px;
        box-shadow: none !important; }
        .user-rating-setup .user-rating-setup-form .color-select-wrapper .color-picker-container .sketch-picker .flexbox-fix:nth-child(2) {
          height: 28px; }
          .user-rating-setup .user-rating-setup-form .color-select-wrapper .color-picker-container .sketch-picker .flexbox-fix:nth-child(2) div:nth-child(1) div:nth-child(1) {
            height: 23px !important; }
            .user-rating-setup .user-rating-setup-form .color-select-wrapper .color-picker-container .sketch-picker .flexbox-fix:nth-child(2) div:nth-child(1) div:nth-child(1) div div.hue-horizontal div div {
              display: block !important;
              margin-top: 0 !important; }
          .user-rating-setup .user-rating-setup-form .color-select-wrapper .color-picker-container .sketch-picker .flexbox-fix:nth-child(2) div:nth-child(1) div:nth-child(2) div {
            display: none; }
        .user-rating-setup .user-rating-setup-form .color-select-wrapper .color-picker-container .sketch-picker .flexbox-fix:nth-child(3) {
          display: none !important; }
        .user-rating-setup .user-rating-setup-form .color-select-wrapper .color-picker-container .sketch-picker .flexbox-fix:nth-child(4) div div {
          border-radius: 50% !important; }
  .user-rating-setup .user-rating-setup-form .closing-welcome-switch-wrapper {
    position: absolute;
    top: 38px;
    right: 0;
    vertical-align: top;
    display: inline-block;
    margin: 0 16px; }
    .user-rating-setup .user-rating-setup-form .closing-welcome-switch-wrapper .custom-switch-wrapper {
      margin: 0;
      height: 28px;
      width: 48px; }
      .user-rating-setup .user-rating-setup-form .closing-welcome-switch-wrapper .custom-switch-wrapper .custom-switch:before {
        width: 15px;
        height: 15px;
        bottom: 7px; }
    .user-rating-setup .user-rating-setup-form .closing-welcome-switch-wrapper input:checked + .custom-switch:before {
      -webkit-transform: translateX(17px);
              transform: translateX(17px); }
  .user-rating-setup .user-rating-setup-form .user-rating-setup-form-content {
    display: inline-block;
    font-size: 16px;
    letter-spacing: -0.5px;
    padding-right: 90px;
    width: 100%; }
    .user-rating-setup .user-rating-setup-form .user-rating-setup-form-content p {
      margin: 4px 0 10px;
      font-size: 14px;
      letter-spacing: -0.2px;
      color: rgba(25, 25, 25, 0.8); }
    .user-rating-setup .user-rating-setup-form .user-rating-setup-form-content textarea {
      width: 100%;
      border-radius: 8px;
      font-size: 16px;
      letter-spacing: -0.3px;
      border: solid 1px #dcdcdc;
      resize: none;
      padding: 12px 16px;
      line-height: 24px;
      margin: 0;
      max-width: 416px;
      width: 100%;
      display: block; }
      .user-rating-setup .user-rating-setup-form .user-rating-setup-form-content textarea:focus {
        border-color: #ff7d00; }
      .user-rating-setup .user-rating-setup-form .user-rating-setup-form-content textarea[disabled] {
        color: rgba(25, 25, 25, 0.4);
        background: rgba(25, 25, 25, 0.1); }

.whatsapp-preview {
  width: 100%;
  position: relative;
  border-radius: 4px;
  background: #EBE6E1;
  padding: 32px 16px 24px 24px;
  text-align: center; }
  .whatsapp-preview .carousel-wrapper {
    width: calc(100% + 16px);
    text-align: left;
    overflow-x: auto;
    white-space: nowrap; }
    .whatsapp-preview .carousel-wrapper .carousel-card {
      word-break: break-word;
      background: #ffffff;
      width: 258px;
      padding: 10px 10px 0 10px;
      border-radius: 8px 8px 8px 8px;
      box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2);
      position: relative;
      text-align: left;
      font-family: SFPro;
      margin: 4px 4px 0 0;
      vertical-align: top;
      display: inline-block;
      white-space: normal; }
      .whatsapp-preview .carousel-wrapper .carousel-card .carousel-body-text {
        font-size: 16px;
        letter-spacing: -0.3px; }
      .whatsapp-preview .carousel-wrapper .carousel-card:nth-child(1) {
        margin-left: 12px; }
        .whatsapp-preview .carousel-wrapper .carousel-card:nth-child(1)::after {
          position: absolute;
          background: transparent;
          width: 13px;
          height: 17px;
          content: "";
          border-top: solid 8px transparent;
          border-bottom: solid 8px transparent;
          border-left: solid 8px transparent;
          border-right: solid 8px #fff;
          -webkit-transform: rotate(-495deg);
                  transform: rotate(-495deg);
          bottom: 3px;
          left: -7px; }
      .whatsapp-preview .carousel-wrapper .carousel-card .button-carousel-wrapper {
        margin: 4px 0 0; }
        .whatsapp-preview .carousel-wrapper .carousel-card .button-carousel-wrapper .button-carousel {
          padding: 8px;
          border-top: solid 1px #dcdcdc;
          text-align: center;
          color: #4d8b6a;
          white-space: nowrap;
          max-width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          margin: 0;
          font-size: 16px;
          letter-spacing: -0.3px; }
          .whatsapp-preview .carousel-wrapper .carousel-card .button-carousel-wrapper .button-carousel svg {
            position: relative;
            top: -1px;
            margin-right: 4px;
            font-size: 20px; }
          .whatsapp-preview .carousel-wrapper .carousel-card .button-carousel-wrapper .button-carousel img {
            position: relative;
            top: -1px;
            margin-right: 4px; }
      .whatsapp-preview .carousel-wrapper .carousel-card .carousel-card-image-wrapper {
        margin: 0 !important; }
        .whatsapp-preview .carousel-wrapper .carousel-card .carousel-card-image-wrapper .image-video-wrapper {
          width: 100%;
          height: 160px;
          position: relative;
          background: #e5e5e5;
          border-radius: 8px;
          overflow: hidden;
          margin: 0 0 4px 0; }
          .whatsapp-preview .carousel-wrapper .carousel-card .carousel-card-image-wrapper .image-video-wrapper img {
            max-width: 100%;
            max-height: 100%;
            position: absolute;
            top: 50%;
            left: 50%;
            -webkit-transform: translate(-50%, -50%);
                    transform: translate(-50%, -50%); }
          .whatsapp-preview .carousel-wrapper .carousel-card .carousel-card-image-wrapper .image-video-wrapper video {
            max-width: 100%;
            max-height: 100%;
            position: absolute;
            top: 50%;
            left: 50%;
            -webkit-transform: translate(-50%, -50%);
                    transform: translate(-50%, -50%); }
        .whatsapp-preview .carousel-wrapper .carousel-card .carousel-card-image-wrapper .icon-wrapper {
          width: 44px;
          height: 44px;
          border-radius: 50%;
          background: rgba(25, 25, 25, 0.4);
          position: absolute;
          top: 50%;
          left: 50%;
          -webkit-transform: translate(-50%, -50%);
                  transform: translate(-50%, -50%); }
          .whatsapp-preview .carousel-wrapper .carousel-card .carousel-card-image-wrapper .icon-wrapper svg {
            font-size: 24px;
            position: absolute;
            top: 50%;
            left: 50%;
            -webkit-transform: translate(-50%, -50%);
                    transform: translate(-50%, -50%);
            color: #fff; }
      .whatsapp-preview .carousel-wrapper .carousel-card p {
        margin: 0; }
  .whatsapp-preview .wrapper-quick-reply div {
    margin: 0 !important; }
  .whatsapp-preview .wrapper-quick-reply.wrapper-quick-reply-more div:nth-child(1) {
    width: calc(50% - 2px);
    display: inline-block;
    margin-right: 4px !important; }
  .whatsapp-preview .wrapper-quick-reply.wrapper-quick-reply-more div:nth-child(2) {
    width: calc(50% - 2px);
    display: inline-block;
    margin-right: 0 !important; }
  .whatsapp-preview .wrapper-call-to-action .call-to-action-button-wrap {
    text-align: center;
    border-top: solid 1px rgba(25, 25, 25, 0.1);
    color: #0096DE;
    height: 36px;
    margin: 0 !important;
    width: calc(100% + 20px);
    padding: 5px 20px 0;
    position: relative;
    left: -10px; }
    .whatsapp-preview .wrapper-call-to-action .call-to-action-button-wrap svg {
      position: relative;
      margin-right: 6px;
      top: -1px; }
    .whatsapp-preview .wrapper-call-to-action .call-to-action-button-wrap:nth-child(1) {
      margin-top: 8px !important; }
    .whatsapp-preview .wrapper-call-to-action .call-to-action-button-wrap:nth-last-child(1) {
      height: 26px; }
  .whatsapp-preview .whatsapp-link {
    color: #1391E9; }
  .whatsapp-preview .whatsapp-info-wrapper {
    width: 74px;
    height: 24px;
    display: inline-block;
    background: #E4F2F9;
    border-radius: 4px;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2);
    margin-bottom: 16px; }
    .whatsapp-preview .whatsapp-info-wrapper .whatsapp-info-content {
      width: 50px;
      height: 14px;
      border-radius: 2px;
      display: inline-block;
      background: #D0DCE3;
      margin-top: 5px; }
  .whatsapp-preview .whatsapp-warning {
    width: 100%;
    font-size: 12px;
    letter-spacing: -0.4px;
    color: rgba(25, 25, 25, 0.6); }
    .whatsapp-preview .whatsapp-warning svg {
      position: relative;
      top: -1px;
      margin-right: 4px; }
    .whatsapp-preview .whatsapp-warning .whatsapp-warning-content {
      padding: 2px 13px;
      border-radius: 4px;
      box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2);
      background: #ffffff;
      display: inline-block; }
  .whatsapp-preview .whatsapp-chat-bubble {
    word-break: break-word;
    background: #ffffff;
    width: 100%;
    padding: 10px;
    border-radius: 0 8px 8px 8px;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2);
    position: relative;
    text-align: left;
    font-family: SFPro;
    overflow: hidden; }
    .whatsapp-preview .whatsapp-chat-bubble::before {
      position: absolute;
      background: transparent;
      width: 13px;
      height: 8px;
      content: "";
      border-top: solid 8px transparent;
      border-bottom: solid 8px transparent;
      border-left: solid 8px #ffffff;
      border-right: solid 8px transparent;
      -webkit-transform: rotate(-45deg);
              transform: rotate(-45deg);
      top: -8px;
      left: -7px; }
    .whatsapp-preview .whatsapp-chat-bubble.no-corner {
      text-align: center;
      border-radius: 8px !important;
      color: #0096de !important;
      margin-top: 4px !important;
      vertical-align: top; }
      .whatsapp-preview .whatsapp-chat-bubble.no-corner svg {
        color: #0096de !important;
        position: relative;
        top: -2px;
        font-size: 20px; }
      .whatsapp-preview .whatsapp-chat-bubble.no-corner b {
        margin-left: 4px;
        font-size: 16px; }
      .whatsapp-preview .whatsapp-chat-bubble.no-corner::before {
        display: none; }
    .whatsapp-preview .whatsapp-chat-bubble b {
      font-family: SFPro !important;
      font-weight: bold !important; }
    .whatsapp-preview .whatsapp-chat-bubble .whatsapp-chat-bubble-title {
      font-size: 16px;
      letter-spacing: -0.3px;
      line-height: 24px;
      margin: 0; }
    .whatsapp-preview .whatsapp-chat-bubble .whatsapp-chat-bubble-description {
      font-size: 16px;
      letter-spacing: -0.3px;
      line-height: 24px;
      margin: 0; }
    .whatsapp-preview .whatsapp-chat-bubble .whatsapp-chat-bubble-footer {
      font-size: 16px;
      letter-spacing: -0.3px;
      line-height: 24px;
      color: rgba(25, 25, 25, 0.6);
      margin: 0; }
    .whatsapp-preview .whatsapp-chat-bubble .whatsapp-chat-bubble-status {
      text-align: right;
      width: 100%;
      font-size: 12px;
      color: rgba(0, 0, 0, 0.45);
      margin: 0; }
      .whatsapp-preview .whatsapp-chat-bubble .whatsapp-chat-bubble-status img {
        position: relative;
        margin-left: 3px; }
  .whatsapp-preview .static-media {
    position: relative;
    font-size: 0; }
    .whatsapp-preview .static-media .static-media-document {
      border-radius: 8px;
      font-size: 16px;
      letter-spacing: -0.3px;
      padding: 12px 11px 12px 36px;
      position: relative;
      width: 100%;
      margin-bottom: 6px;
      background: #f3f3f3;
      color: rgba(25, 25, 25, 0.6); }
      .whatsapp-preview .static-media .static-media-document img {
        position: absolute;
        top: 50%;
        -webkit-transform: translate(0, -50%);
                transform: translate(0, -50%);
        left: 11px; }
    .whatsapp-preview .static-media img.static-media-image-asset {
      width: 100%;
      border-radius: 8px; }
    .whatsapp-preview .static-media video.static-media-video-asset {
      width: 100%;
      border-radius: 8px; }
    .whatsapp-preview .static-media .static-media-image {
      border-radius: 12px;
      font-size: 14px;
      letter-spacing: -0.4px;
      padding: 82px 11px;
      position: relative;
      width: 100%;
      margin-bottom: 6px;
      background: #9b9b9b;
      color: #ffffff;
      text-align: center; }
      .whatsapp-preview .static-media .static-media-image svg {
        font-size: 26px;
        margin-bottom: 4px; }
    .whatsapp-preview .static-media .static-media-video {
      border-radius: 12px;
      font-size: 14px;
      letter-spacing: -0.4px;
      padding: 82px 11px;
      position: relative;
      width: 100%;
      margin-bottom: 6px;
      background: #9b9b9b;
      color: #ffffff;
      text-align: center; }
      .whatsapp-preview .static-media .static-media-video svg {
        font-size: 26px;
        margin-bottom: 4px; }
    .whatsapp-preview .static-media .static-media-video-file-wrapper {
      background: rgba(0, 0, 0, 0.35);
      position: absolute;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%);
      width: 100%;
      height: 100%;
      border-radius: 8px;
      font-size: 0; }
      .whatsapp-preview .static-media .static-media-video-file-wrapper svg {
        color: #ffffff;
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
        font-size: 32px; }
  .whatsapp-preview .dynamic-media .dynamic-media-document {
    border-radius: 8px;
    font-size: 16px;
    letter-spacing: -0.3px;
    padding: 12px 11px 12px 36px;
    position: relative;
    width: 100%;
    margin-bottom: 6px;
    background: #f3f3f3; }
    .whatsapp-preview .dynamic-media .dynamic-media-document img {
      position: absolute;
      top: 50%;
      -webkit-transform: translate(0, -50%);
              transform: translate(0, -50%);
      left: 11px; }
  .whatsapp-preview .dynamic-media .dynamic-media-image {
    border-radius: 12px;
    font-size: 14px;
    letter-spacing: -0.4px;
    padding: 82px 11px;
    position: relative;
    width: 100%;
    margin-bottom: 6px;
    background: #9b9b9b;
    color: #ffffff;
    text-align: center; }
    .whatsapp-preview .dynamic-media .dynamic-media-image svg {
      font-size: 26px;
      margin-bottom: 4px; }
  .whatsapp-preview .dynamic-media .dynamic-media-video {
    border-radius: 12px;
    font-size: 14px;
    letter-spacing: -0.4px;
    padding: 82px 11px;
    position: relative;
    width: 100%;
    margin-bottom: 6px;
    background: #9b9b9b;
    color: #ffffff;
    text-align: center; }
    .whatsapp-preview .dynamic-media .dynamic-media-video svg {
      font-size: 26px;
      margin-bottom: 4px; }

.assign-rule-page-wrapper {
  margin: 32px 0 0; }
  .assign-rule-page-wrapper .nav-item {
    cursor: pointer;
    color: rgba(25, 25, 25, 0.4);
    font-size: 16px;
    letter-spacing: -0.5px;
    padding: 0 0 12px 0;
    margin-right: 32px; }
    .assign-rule-page-wrapper .nav-item a {
      padding: 0;
      border: none; }
    .assign-rule-page-wrapper .nav-item :hover {
      color: #ff7d00; }
    .assign-rule-page-wrapper .nav-item.active-setup-tab {
      color: #ff7d00;
      border-bottom: solid 4px #ff7d00; }
  .assign-rule-page-wrapper .tab-content {
    margin: 24px 0 0; }

.assignment-settings .button-save {
  margin-top: 28px; }

.assignment-settings .assignment-settings-header {
  font-size: 20px;
  letter-spacing: -0.6px;
  color: #191919;
  padding-bottom: 20px;
  position: relative;
  border-bottom: solid 1px #dcdcdc; }
  .assignment-settings .assignment-settings-header button {
    position: absolute;
    top: -3px;
    right: 0;
    margin: 0; }

.assignment-settings .assignment-settings-form {
  padding-top: 24px;
  position: relative; }
  .assignment-settings .assignment-settings-form span {
    font-size: 14px;
    letter-spacing: -0.2px;
    color: rgba(25, 25, 25, 0.8);
    display: block; }
  .assignment-settings .assignment-settings-form .change-rule-gap {
    margin-bottom: 24px; }

.assignment-settings .ignore-inbox-connection {
  margin: 8px 0 0 27px;
  position: relative;
  padding: 0 0 0 32px;
  font-size: 12px;
  letter-spacing: -0.2px; }
  .assignment-settings .ignore-inbox-connection p {
    color: rgba(25, 25, 25, 0.8); }
  .assignment-settings .ignore-inbox-connection b {
    font-size: 14px;
    letter-spacing: -0.4px; }
  .assignment-settings .ignore-inbox-connection .custom-switch-wrapper {
    display: inline-block !important;
    width: 24px;
    height: 14px;
    position: absolute;
    top: 4px;
    left: 0;
    margin: 0; }
    .assignment-settings .ignore-inbox-connection .custom-switch-wrapper .custom-switch {
      padding: unset; }
    .assignment-settings .ignore-inbox-connection .custom-switch-wrapper .custom-switch.round {
      border-radius: 8px; }
      .assignment-settings .ignore-inbox-connection .custom-switch-wrapper .custom-switch.round::before {
        width: 8px;
        height: 8px;
        bottom: 3px;
        left: 4px; }
    .assignment-settings .ignore-inbox-connection .custom-switch-wrapper input:checked + .custom-switch:before {
      -webkit-transform: translateX(8px);
              transform: translateX(8px); }

.idle-case-container .topContent {
  position: relative;
  padding: 0 192px 12px 0;
  display: block;
  border-bottom: solid 1px #dcdcdc;
  margin: 0 0 24px 0; }
  .idle-case-container .topContent .title {
    width: 100%;
    font-size: 20px;
    letter-spacing: -0.6px; }
  .idle-case-container .topContent .sub-title {
    width: 100%;
    font-size: 14px;
    letter-spacing: -0.2px;
    color: rgba(25, 25, 25, 0.8);
    margin: 4px 0 0 0;
    line-height: 21px; }
  .idle-case-container .topContent .button-edit-idle {
    position: absolute;
    right: 0;
    bottom: 18px;
    width: 180px; }

.idle-case-container .idle-case-value-wrapper .idle-case-value-label {
  font-size: 16px;
  letter-spacing: -0.5px; }

.idle-case-container .idle-case-value-wrapper .idle-case-value {
  font-size: 16px;
  letter-spacing: -0.3px;
  margin: 0; }
  .idle-case-container .idle-case-value-wrapper .idle-case-value img {
    position: relative;
    top: -1px;
    margin-right: 2px; }

.idle-case-container .idle-case-value-wrapper .idle-case-value-2 {
  font-size: 14px;
  letter-spacing: -0.2px;
  margin: 4px 0 0; }

.auto-resolve-settings .button-save {
  margin-top: 28px; }

.auto-resolve-settings .status-wrapper {
  font-size: 16px; }
  .auto-resolve-settings .status-wrapper .status-value {
    color: rgba(25, 25, 25, 0.4);
    font-size: 16px;
    letter-spacing: -0.5px; }
    .auto-resolve-settings .status-wrapper .status-value.status-enabled {
      color: #19C700; }
  .auto-resolve-settings .status-wrapper img {
    position: relative;
    top: -2px;
    margin-right: 4px; }

.auto-resolve-settings .autoUnassign-settings-header {
  font-size: 20px;
  letter-spacing: -0.6px;
  color: #191919;
  padding-bottom: 24px;
  border-bottom: solid 1px #dcdcdc;
  position: relative;
  margin: 0 0 24px 0; }
  .auto-resolve-settings .autoUnassign-settings-header button {
    position: absolute;
    top: 0;
    right: 0; }

.auto-resolve-settings .autoUnassign-settings-form {
  position: relative; }
  .auto-resolve-settings .autoUnassign-settings-form input[type="text"] {
    font-family: PTRootBold;
    width: 46px;
    height: 24px;
    margin: 0 4px;
    border-radius: 4px;
    text-align: center;
    padding: 0 6px;
    font-size: 12px;
    letter-spacing: -0.5px; }
  .auto-resolve-settings .autoUnassign-settings-form .input-time-select {
    font-size: 14px;
    letter-spacing: -0.4px;
    display: inline-block;
    vertical-align: top;
    margin-right: 4px;
    width: 108px;
    height: 24px; }
    .auto-resolve-settings .autoUnassign-settings-form .input-time-select .custom-select-option-placeholder {
      padding: 0 !important;
      text-align: center; }
    .auto-resolve-settings .autoUnassign-settings-form .input-time-select .custom-select-option-value {
      font-family: PTRootBold;
      font-size: 12px;
      letter-spacing: -0.2px;
      padding: 0 !important;
      text-align: center; }
    .auto-resolve-settings .autoUnassign-settings-form .input-time-select svg {
      color: rgba(25, 25, 25, 0.8) !important;
      right: 12px !important;
      font-size: 14px; }
    .auto-resolve-settings .autoUnassign-settings-form .input-time-select .dropdown button {
      padding: 1px 20px 1px 12px !important;
      width: 108px !important;
      height: 24px;
      border-radius: 4px !important;
      margin: 0 !important; }
    .auto-resolve-settings .autoUnassign-settings-form .input-time-select .dropdown-option-wrapper {
      padding: 0;
      width: 108px;
      min-width: 0;
      border-radius: 4px !important; }
      .auto-resolve-settings .autoUnassign-settings-form .input-time-select .dropdown-option-wrapper button {
        padding: 3px 12px 3px 26px !important;
        font-family: PTRootBold;
        border-bottom: solid 1px #dcdcdc;
        font-size: 12px;
        letter-spacing: -0.2px;
        border-radius: 0 !important; }
        .auto-resolve-settings .autoUnassign-settings-form .input-time-select .dropdown-option-wrapper button::before {
          width: 12px;
          height: 12px;
          left: 10px;
          top: 6px;
          background-size: 12px;
          -webkit-transform: none !important;
                  transform: none !important; }
        .auto-resolve-settings .autoUnassign-settings-form .input-time-select .dropdown-option-wrapper button svg {
          font-size: 14px;
          left: 12px; }
        .auto-resolve-settings .autoUnassign-settings-form .input-time-select .dropdown-option-wrapper button:nth-last-child(1) {
          border: none; }
  .auto-resolve-settings .autoUnassign-settings-form .closing-welcome-switch-wrapper {
    position: absolute;
    top: 0;
    right: 0;
    vertical-align: top;
    display: inline-block;
    margin: 0 0 0 16px; }
    .auto-resolve-settings .autoUnassign-settings-form .closing-welcome-switch-wrapper .custom-switch-wrapper {
      margin: 0;
      height: 28px;
      width: 48px; }
      .auto-resolve-settings .autoUnassign-settings-form .closing-welcome-switch-wrapper .custom-switch-wrapper .custom-switch:before {
        width: 15px;
        height: 15px;
        bottom: 7px; }
    .auto-resolve-settings .autoUnassign-settings-form .closing-welcome-switch-wrapper input:checked + .custom-switch:before {
      -webkit-transform: translateX(17px);
              transform: translateX(17px); }
  .auto-resolve-settings .autoUnassign-settings-form .autoUnassign-settings-form-content {
    display: block;
    width: 100%;
    font-size: 16px;
    letter-spacing: -0.5px;
    margin-bottom: 20px;
    position: relative;
    padding-right: 64px; }
    .auto-resolve-settings .autoUnassign-settings-form .autoUnassign-settings-form-content p {
      margin: 4px 0 10px;
      font-size: 14px;
      letter-spacing: -0.2px;
      color: rgba(25, 25, 25, 0.8); }

.modal-confirmation-save-changes .modal-content {
  width: 476px !important; }

.modal-confirmation-save-changes button {
  width: calc(50% - 4px) !important;
  margin-right: 8px; }
  .modal-confirmation-save-changes button:nth-last-child(1) {
    margin-right: 0; }

.auto-unassign-settings .button-save {
  margin-top: 28px; }

.auto-unassign-settings .status-wrapper {
  font-size: 16px; }
  .auto-unassign-settings .status-wrapper .status-value {
    color: rgba(25, 25, 25, 0.4);
    font-size: 16px;
    letter-spacing: -0.5px; }
    .auto-unassign-settings .status-wrapper .status-value.status-enabled {
      color: #19C700; }
  .auto-unassign-settings .status-wrapper img {
    position: relative;
    top: -2px;
    margin-right: 4px; }

.auto-unassign-settings .autoUnassign-settings-header {
  font-size: 20px;
  letter-spacing: -0.6px;
  color: #191919;
  padding-bottom: 24px;
  border-bottom: solid 1px #dcdcdc;
  position: relative;
  margin: 0 0 24px 0; }
  .auto-unassign-settings .autoUnassign-settings-header button {
    position: absolute;
    top: 0;
    right: 0; }

.auto-unassign-settings .autoUnassign-settings-form {
  padding-top: 24px;
  position: relative; }
  .auto-unassign-settings .autoUnassign-settings-form input[type="text"] {
    font-family: PTRootBold;
    width: 46px;
    height: 24px;
    margin: 0 4px;
    border-radius: 4px;
    text-align: center;
    padding: 0 6px;
    font-size: 12px;
    letter-spacing: -0.5px; }
  .auto-unassign-settings .autoUnassign-settings-form .input-time-select {
    font-size: 14px;
    letter-spacing: -0.4px;
    display: inline-block;
    vertical-align: top;
    margin-right: 4px;
    width: 108px;
    height: 24px; }
    .auto-unassign-settings .autoUnassign-settings-form .input-time-select .custom-select-option-placeholder {
      padding: 0 !important;
      text-align: center; }
    .auto-unassign-settings .autoUnassign-settings-form .input-time-select .custom-select-option-value {
      font-family: PTRootBold;
      font-size: 12px;
      letter-spacing: -0.2px;
      padding: 0 !important;
      text-align: center; }
    .auto-unassign-settings .autoUnassign-settings-form .input-time-select svg {
      color: rgba(25, 25, 25, 0.8) !important;
      right: 12px !important;
      font-size: 14px; }
    .auto-unassign-settings .autoUnassign-settings-form .input-time-select .dropdown button {
      padding: 1px 20px 1px 12px !important;
      width: 108px !important;
      height: 24px;
      border-radius: 4px !important;
      margin: 0 !important; }
    .auto-unassign-settings .autoUnassign-settings-form .input-time-select .dropdown-option-wrapper {
      padding: 0;
      width: 90px;
      min-width: 0;
      border-radius: 4px !important; }
      .auto-unassign-settings .autoUnassign-settings-form .input-time-select .dropdown-option-wrapper button {
        padding: 3px 12px 3px 26px !important;
        font-family: PTRootBold;
        border-bottom: solid 1px #dcdcdc;
        font-size: 12px;
        letter-spacing: -0.2px;
        border-radius: 0 !important; }
        .auto-unassign-settings .autoUnassign-settings-form .input-time-select .dropdown-option-wrapper button::before {
          width: 12px;
          height: 12px;
          left: 10px;
          top: 6px;
          background-size: 12px;
          -webkit-transform: none !important;
                  transform: none !important; }
        .auto-unassign-settings .autoUnassign-settings-form .input-time-select .dropdown-option-wrapper button svg {
          font-size: 14px;
          left: 12px; }
        .auto-unassign-settings .autoUnassign-settings-form .input-time-select .dropdown-option-wrapper button:nth-last-child(1) {
          border: none; }
  .auto-unassign-settings .autoUnassign-settings-form .closing-welcome-switch-wrapper {
    position: absolute;
    top: 0;
    right: 0;
    vertical-align: top;
    display: inline-block;
    margin: 0 0 0 16px; }
    .auto-unassign-settings .autoUnassign-settings-form .closing-welcome-switch-wrapper .custom-switch-wrapper {
      margin: 0;
      height: 28px;
      width: 48px; }
      .auto-unassign-settings .autoUnassign-settings-form .closing-welcome-switch-wrapper .custom-switch-wrapper .custom-switch:before {
        width: 15px;
        height: 15px;
        bottom: 7px; }
    .auto-unassign-settings .autoUnassign-settings-form .closing-welcome-switch-wrapper input:checked + .custom-switch:before {
      -webkit-transform: translateX(17px);
              transform: translateX(17px); }
  .auto-unassign-settings .autoUnassign-settings-form .autoUnassign-settings-form-content {
    display: block;
    width: 100%;
    font-size: 16px;
    letter-spacing: -0.5px;
    margin-bottom: 20px;
    position: relative;
    padding-right: 64px; }
    .auto-unassign-settings .autoUnassign-settings-form .autoUnassign-settings-form-content p {
      margin: 4px 0 10px;
      font-size: 14px;
      letter-spacing: -0.2px;
      color: rgba(25, 25, 25, 0.8); }

.modal-confirmation-save-changes .modal-content {
  width: 476px !important; }

.modal-confirmation-save-changes button {
  width: calc(50% - 4px) !important;
  margin-right: 8px; }
  .modal-confirmation-save-changes button:nth-last-child(1) {
    margin-right: 0; }

.auto-chatbot-settings .button-save {
  margin-top: 28px; }

.auto-chatbot-settings .status-wrapper {
  font-size: 16px; }
  .auto-chatbot-settings .status-wrapper .status-value {
    color: rgba(25, 25, 25, 0.4);
    font-size: 16px;
    letter-spacing: -0.5px; }
    .auto-chatbot-settings .status-wrapper .status-value.status-enabled {
      color: #19C700; }
  .auto-chatbot-settings .status-wrapper img {
    position: relative;
    top: -2px;
    margin-right: 4px; }

.auto-chatbot-settings .autoChatbot-settings-header {
  font-size: 20px;
  letter-spacing: -0.6px;
  color: #191919;
  padding-bottom: 24px;
  border-bottom: solid 1px #dcdcdc;
  position: relative;
  margin: 0 0 24px 0; }
  .auto-chatbot-settings .autoChatbot-settings-header button {
    position: absolute;
    top: 0;
    right: 0; }

.auto-chatbot-settings .autoChatbot-settings-form {
  padding-top: 24px;
  position: relative; }
  .auto-chatbot-settings .autoChatbot-settings-form input[type="text"] {
    font-family: PTRootBold;
    width: 46px;
    height: 24px;
    margin: 0 4px;
    border-radius: 4px;
    text-align: center;
    padding: 0 6px;
    font-size: 12px;
    letter-spacing: -0.5px; }
  .auto-chatbot-settings .autoChatbot-settings-form .input-time-select {
    font-size: 14px;
    letter-spacing: -0.4px;
    display: inline-block;
    vertical-align: top;
    margin-right: 4px;
    width: 108px;
    height: 24px; }
    .auto-chatbot-settings .autoChatbot-settings-form .input-time-select .custom-select-option-placeholder {
      padding: 0 !important;
      text-align: center; }
    .auto-chatbot-settings .autoChatbot-settings-form .input-time-select .custom-select-option-value {
      font-family: PTRootBold;
      font-size: 12px;
      letter-spacing: -0.2px;
      padding: 0 !important;
      text-align: center; }
    .auto-chatbot-settings .autoChatbot-settings-form .input-time-select svg {
      color: rgba(25, 25, 25, 0.8) !important;
      right: 12px !important;
      font-size: 14px; }
    .auto-chatbot-settings .autoChatbot-settings-form .input-time-select .dropdown button {
      padding: 1px 20px 1px 12px !important;
      width: 108px !important;
      height: 24px;
      border-radius: 4px !important;
      margin: 0 !important; }
    .auto-chatbot-settings .autoChatbot-settings-form .input-time-select .dropdown-option-wrapper {
      padding: 0;
      width: 90px;
      min-width: 0;
      border-radius: 4px !important; }
      .auto-chatbot-settings .autoChatbot-settings-form .input-time-select .dropdown-option-wrapper button {
        padding: 3px 12px 3px 26px !important;
        font-family: PTRootBold;
        border-bottom: solid 1px #dcdcdc;
        font-size: 12px;
        letter-spacing: -0.2px;
        border-radius: 0 !important; }
        .auto-chatbot-settings .autoChatbot-settings-form .input-time-select .dropdown-option-wrapper button::before {
          width: 12px;
          height: 12px;
          left: 10px;
          top: 6px;
          background-size: 12px;
          -webkit-transform: none !important;
                  transform: none !important; }
        .auto-chatbot-settings .autoChatbot-settings-form .input-time-select .dropdown-option-wrapper button svg {
          font-size: 14px;
          left: 12px; }
        .auto-chatbot-settings .autoChatbot-settings-form .input-time-select .dropdown-option-wrapper button:nth-last-child(1) {
          border: none; }
    .auto-chatbot-settings .autoChatbot-settings-form .input-time-select.input-time-first {
      margin: 0 8px;
      width: 155px; }
      .auto-chatbot-settings .autoChatbot-settings-form .input-time-select.input-time-first .custom-select-option-value {
        text-align: left; }
      .auto-chatbot-settings .autoChatbot-settings-form .input-time-select.input-time-first .dropdown-option-wrapper {
        width: 155px; }
      .auto-chatbot-settings .autoChatbot-settings-form .input-time-select.input-time-first .dropdown button {
        width: 155px !important; }
  .auto-chatbot-settings .autoChatbot-settings-form .closing-welcome-switch-wrapper {
    position: absolute;
    top: 0;
    right: 0;
    vertical-align: top;
    display: inline-block;
    margin: 0 0 0 16px; }
    .auto-chatbot-settings .autoChatbot-settings-form .closing-welcome-switch-wrapper .custom-switch-wrapper {
      margin: 0;
      height: 28px;
      width: 48px; }
      .auto-chatbot-settings .autoChatbot-settings-form .closing-welcome-switch-wrapper .custom-switch-wrapper .custom-switch:before {
        width: 15px;
        height: 15px;
        bottom: 7px; }
    .auto-chatbot-settings .autoChatbot-settings-form .closing-welcome-switch-wrapper input:checked + .custom-switch:before {
      -webkit-transform: translateX(17px);
              transform: translateX(17px); }
  .auto-chatbot-settings .autoChatbot-settings-form .autoChatbot-settings-form-content {
    display: block;
    width: 100%;
    font-size: 16px;
    letter-spacing: -0.5px;
    margin-bottom: 20px;
    position: relative;
    padding-right: 64px; }
    .auto-chatbot-settings .autoChatbot-settings-form .autoChatbot-settings-form-content textarea {
      width: 400px;
      display: block;
      margin: 8px 0 6px; }
    .auto-chatbot-settings .autoChatbot-settings-form .autoChatbot-settings-form-content span {
      font-size: 14px;
      letter-spacing: -0.2px; }
    .auto-chatbot-settings .autoChatbot-settings-form .autoChatbot-settings-form-content p {
      margin: 4px 0 10px;
      font-size: 14px;
      letter-spacing: -0.2px;
      color: rgba(25, 25, 25, 0.8); }

.auto-chatbot-settings .default-topic-here {
  margin: 6px 0 0;
  width: 311px; }
  .auto-chatbot-settings .default-topic-here .dropdown button {
    margin: 0; }

.modal-confirmation-save-changes .modal-content {
  width: 476px !important; }

.modal-confirmation-save-changes button {
  width: calc(50% - 4px) !important;
  margin-right: 8px; }
  .modal-confirmation-save-changes button:nth-last-child(1) {
    margin-right: 0; }

.contact-info-custom-field-wrapper .contact-info-custom-field-title {
  font-size: 20px;
  letter-spacing: -0.6px;
  margin: 0;
  color: #191919; }

.contact-info-custom-field-wrapper .contact-info-custom-field-description {
  margin: 8px 0 16px;
  font-size: 16px;
  letter-spacing: -0.3px;
  width: calc(100% - 230px);
  color: #191919;
  line-height: 24px; }

.contact-info-custom-field-wrapper .add-contact-info-custom-field-button {
  text-align: center;
  position: absolute;
  top: 32px;
  right: 32px; }

.contact-info-custom-field-wrapper .contact-info-custom-field-data-wrapper {
  padding: 32px 0 16px;
  border-top: solid 1px #1919191A;
  width: 100%;
  color: #191919; }
  .contact-info-custom-field-wrapper .contact-info-custom-field-data-wrapper table {
    width: 100%; }
    .contact-info-custom-field-wrapper .contact-info-custom-field-data-wrapper table thead {
      font-size: 16px;
      border-bottom: solid 1px #1919191A;
      color: #191919;
      letter-spacing: -0.4px; }
      .contact-info-custom-field-wrapper .contact-info-custom-field-data-wrapper table thead td {
        padding: 0 8px 8px; }
    .contact-info-custom-field-wrapper .contact-info-custom-field-data-wrapper table .contact-info-custom-field-table-body {
      font-size: 14px;
      letter-spacing: -0.2px;
      color: #191919; }
      .contact-info-custom-field-wrapper .contact-info-custom-field-data-wrapper table .contact-info-custom-field-table-body .contact-info-custom-field-list {
        border-bottom: solid 1px #1919191A;
        cursor: pointer; }
        .contact-info-custom-field-wrapper .contact-info-custom-field-data-wrapper table .contact-info-custom-field-table-body .contact-info-custom-field-list:hover {
          background-color: #FFF2E5; }
        .contact-info-custom-field-wrapper .contact-info-custom-field-data-wrapper table .contact-info-custom-field-table-body .contact-info-custom-field-list td {
          padding: 16px 8px;
          border-bottom: solid 1px #1919191A; }
          .contact-info-custom-field-wrapper .contact-info-custom-field-data-wrapper table .contact-info-custom-field-table-body .contact-info-custom-field-list td.col-required img {
            width: 16px;
            height: 16px;
            margin-right: 4px;
            -webkit-transform: translateY(-2px);
                    transform: translateY(-2px); }
    .contact-info-custom-field-wrapper .contact-info-custom-field-data-wrapper table .no-contact-info-custom-field .no-contact-info-custom-field-tr {
      border-bottom: none;
      cursor: default; }
      .contact-info-custom-field-wrapper .contact-info-custom-field-data-wrapper table .no-contact-info-custom-field .no-contact-info-custom-field-tr:hover {
        background-color: white !important; }
      .contact-info-custom-field-wrapper .contact-info-custom-field-data-wrapper table .no-contact-info-custom-field .no-contact-info-custom-field-tr td {
        width: 100%;
        text-align: center;
        font-size: 16px;
        letter-spacing: -0.5px;
        color: rgba(25, 25, 25, 0.6);
        padding: 24px 0 0; }

.create-custom-field-main-wrapper .label-left {
  padding-left: 0;
  position: relative; }
  .create-custom-field-main-wrapper .label-left .tooltip-icon {
    width: 14px;
    height: 14px;
    margin-left: 4px;
    -webkit-transform: translateY(-2px);
            transform: translateY(-2px); }

.create-custom-field-main-wrapper .custom-switch-wrapper {
  display: inline-block !important;
  margin-top: 8px !important;
  width: 28px !important;
  height: 16px;
  position: relative !important; }
  .create-custom-field-main-wrapper .custom-switch-wrapper .custom-switch {
    padding: 0; }
  .create-custom-field-main-wrapper .custom-switch-wrapper .custom-switch.round {
    -webkit-transform: translateY(3px);
            transform: translateY(3px); }
    .create-custom-field-main-wrapper .custom-switch-wrapper .custom-switch.round::before {
      width: 10px;
      height: 10px;
      top: 3px;
      left: 4px;
      box-shadow: none; }
  .create-custom-field-main-wrapper .custom-switch-wrapper input:checked + .custom-switch:before {
    -webkit-transform: translateX(10px);
            transform: translateX(10px); }

.create-custom-field-main-wrapper .required-value {
  display: inline-block;
  margin: 0 0 0 4px;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: -0.2px;
  color: #19191999; }

.create-custom-field-main-wrapper .create-custom-field-header {
  padding-bottom: 20px;
  margin-bottom: 12px;
  border-bottom: 1px solid rgba(25, 25, 25, 0.1);
  position: relative;
  display: block; }
  .create-custom-field-main-wrapper .create-custom-field-header p {
    -webkit-transform: translateY(6px);
            transform: translateY(6px); }
  .create-custom-field-main-wrapper .create-custom-field-header .grey-font {
    font-size: 16px;
    letter-spacing: -0.6px; }
  .create-custom-field-main-wrapper .create-custom-field-header button {
    position: absolute;
    top: 0;
    right: 0;
    width: 188px; }
    .create-custom-field-main-wrapper .create-custom-field-header button b {
      letter-spacing: -0.5px; }
  .create-custom-field-main-wrapper .create-custom-field-header button[disabled] b {
    letter-spacing: -0.5px; }
  .create-custom-field-main-wrapper .create-custom-field-header .main-button-40.with-image-or-loading-left {
    padding: 0 !important; }
  .create-custom-field-main-wrapper .create-custom-field-header .button-delete-custom-field {
    right: 192px; }

.create-custom-field-main-wrapper .create-custom-field-title {
  font-size: 20px;
  letter-spacing: -0.6px;
  margin: 0;
  color: #191919; }

.create-custom-field-main-wrapper .create-custom-field-description {
  margin: 8px 0 16px;
  font-size: 16px;
  letter-spacing: -0.3px;
  width: calc(100% - 230px);
  color: #191919;
  line-height: 24px; }

.create-custom-field-add-wrapper .create-custom-field-add-title {
  font-size: 20px;
  letter-spacing: -0.6px;
  line-height: 1; }

.create-custom-field-add-wrapper .close-modal-create-custom-field {
  position: absolute;
  font-size: 22px;
  right: 0;
  top: 2px;
  cursor: pointer; }

.create-custom-field-add-wrapper .form-content-wrapper input, .create-custom-field-add-wrapper .form-content-wrapper textarea, .create-custom-field-add-wrapper .form-content-wrapper label {
  width: 100%;
  position: relative;
  max-width: 400px; }
  .create-custom-field-add-wrapper .form-content-wrapper input .clear-all, .create-custom-field-add-wrapper .form-content-wrapper textarea .clear-all, .create-custom-field-add-wrapper .form-content-wrapper label .clear-all {
    color: var(--watermelon);
    cursor: pointer;
    position: absolute;
    right: 0;
    bottom: 0; }
    .create-custom-field-add-wrapper .form-content-wrapper input .clear-all:hover, .create-custom-field-add-wrapper .form-content-wrapper textarea .clear-all:hover, .create-custom-field-add-wrapper .form-content-wrapper label .clear-all:hover {
      color: var(--watermelon-dark); }

.create-custom-field-add-wrapper .form-content-wrapper label {
  margin-top: 32px; }

.create-custom-field-add-wrapper .form-content-wrapper .field-value {
  margin: -4px 0 0 0;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.3px;
  color: #191919; }

.create-custom-field-add-wrapper .form-content-wrapper .label-description {
  margin: -4px 0 0 0;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: -0.2px;
  color: #19191999; }

.create-custom-field-add-wrapper .form-content-wrapper .dropdown {
  max-width: 400px; }
  .create-custom-field-add-wrapper .form-content-wrapper .dropdown button {
    margin-top: 0px; }
  .create-custom-field-add-wrapper .form-content-wrapper .dropdown .dropdown-option-wrapper {
    max-height: none;
    border: none; }

.create-custom-field-add-wrapper .form-content-wrapper .input-counter {
  position: absolute;
  top: 0;
  right: 0; }

.create-custom-field-add-wrapper .form-content-wrapper b {
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.5px; }

.create-custom-field-add-wrapper .form-content-wrapper .input-char-limit {
  width: 88px;
  text-align: center; }

.create-custom-field-add-wrapper .form-content-wrapper .optional {
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.5px;
  color: #19191966; }

.create-custom-field-add-wrapper .form-content-wrapper .view-only-input {
  display: block;
  padding: 12px 16px;
  max-width: 400px;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #191919CC;
  background-color: #1919191A;
  border-radius: 8px; }

.create-custom-field-add-wrapper .form-content-wrapper .view-options {
  display: block;
  padding: 12px 16px;
  margin: 8px 0 0 0;
  max-width: 400px;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.3px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #191919CC;
  background-color: #F3F3F3;
  border: solid 1px #1919191A;
  border-radius: 8px; }

.create-custom-field-add-wrapper .form-content-wrapper .required-view {
  margin-top: -4px;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: -0.2px;
  color: #191919; }
  .create-custom-field-add-wrapper .form-content-wrapper .required-view img {
    margin-right: 4px;
    -webkit-transform: translateY(-2px);
            transform: translateY(-2px); }

.create-custom-field-add-wrapper .form-content-wrapper .characters-label {
  margin: 0 0 0 8px;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.3px;
  color: #191919; }

.create-custom-field-add-wrapper .form-content-wrapper .multiple-option-count {
  margin: 16px 0 4px 0;
  text-align: right; }
  .create-custom-field-add-wrapper .form-content-wrapper .multiple-option-count svg {
    margin-left: 8px;
    font-size: 16px;
    color: var(--watermelon);
    -webkit-transform: translateY(-2px);
            transform: translateY(-2px);
    cursor: pointer; }

.create-custom-field-add-wrapper .form-content-wrapper .drag-indicator {
  width: 24px;
  height: 24px;
  position: absolute;
  left: 36px;
  -webkit-transform: translateY(12px);
          transform: translateY(12px);
  cursor: -webkit-grab;
  cursor: grab; }

.create-custom-field-add-wrapper .form-content-wrapper .draggable {
  padding-left: 32px; }

.create-custom-field-add-wrapper .form-content-wrapper .add-option {
  margin: 16px 0 0;
  color: var(--main-dark-orange);
  cursor: pointer; }
  .create-custom-field-add-wrapper .form-content-wrapper .add-option b {
    font-size: 14px;
    line-height: 21px;
    letter-spacing: -0.4px; }
  .create-custom-field-add-wrapper .form-content-wrapper .add-option svg {
    position: relative;
    font-size: 12px;
    top: -1.5px; }
  .create-custom-field-add-wrapper .form-content-wrapper .add-option:hover {
    color: var(--main-darker-orange); }
    .create-custom-field-add-wrapper .form-content-wrapper .add-option:hover svg {
      color: var(--main-darker-orange); }

.create-custom-field-add-wrapper .button-create-custom-field {
  width: 100%;
  margin-top: 20px; }

.tooltip-required .bs-tooltip-bottom .arrow::before, .tooltip-required .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
  border-bottom-color: #333333 !important; }

.tooltip-required .tooltip.show {
  opacity: 1 !important;
  margin-top: 4px; }

.tooltip-required .tooltip-inner {
  padding: 8px !important;
  margin-left: 56px;
  min-width: 266px !important;
  font-family: "PTRootUI" !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 21px !important;
  letter-spacing: -0.2px !important;
  border-radius: 4px !important;
  color: #FFFFFF !important;
  background: #333333 !important;
  opacity: 1 !important; }

.tooltip-char-limit .bs-tooltip-right .arrow::before, .tooltip-char-limit .bs-tooltip-auto[x-placement^="right"] .arrow::before {
  border-right-color: #333333 !important; }

.tooltip-char-limit .tooltip.show {
  opacity: 1 !important;
  margin-left: 4px; }

.tooltip-char-limit .tooltip-inner {
  padding: 8px !important;
  min-width: 223px !important;
  font-family: "PTRootUI" !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 21px !important;
  letter-spacing: -0.2px !important;
  border-radius: 4px !important;
  color: #FFFFFF !important;
  background: #333333 !important;
  opacity: 1 !important; }

.user-rating-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }
  .user-rating-wrapper .red-text {
    position: relative;
    font-size: 12px;
    margin: 0; }
    .user-rating-wrapper .red-text svg {
      position: relative;
      top: -1px;
      margin-right: 4px; }
  .user-rating-wrapper .button-submit-rating {
    background: #ff7d00;
    border-radius: 8px;
    border: none;
    color: #ffffff;
    position: relative;
    margin-top: 20px; }
    .user-rating-wrapper .button-submit-rating .button-text-wrapper {
      position: relative;
      z-index: 2; }
    .user-rating-wrapper .button-submit-rating:hover::before {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(25, 25, 25, 0.1);
      content: "";
      border-radius: 8px;
      z-index: 1; }
  .user-rating-wrapper .result-wrapper {
    background: #ffffff;
    text-align: center;
    position: fixed;
    left: 50%;
    -webkit-transform: translate(-50%, 0);
            transform: translate(-50%, 0);
    top: 48px;
    width: 364px;
    padding: 16px;
    border-radius: 8px;
    max-height: 90%; }
    .user-rating-wrapper .result-wrapper.loading-content {
      max-height: 100%;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      -webkit-transform: unset;
              transform: unset;
      border-radius: 0; }
      .user-rating-wrapper .result-wrapper.loading-content .inner-content {
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%); }
    .user-rating-wrapper .result-wrapper .lds-ring {
      width: 48px;
      height: 48px; }
      .user-rating-wrapper .result-wrapper .lds-ring div {
        margin: 0;
        width: 48px;
        height: 48px;
        border: solid 5px;
        border-color: #ff7d00 transparent transparent transparent; }
    .user-rating-wrapper .result-wrapper p {
      margin: 0;
      line-height: 1; }
      .user-rating-wrapper .result-wrapper p:nth-child(2) {
        color: #191919;
        font-size: 24px;
        letter-spacing: -0.6px;
        margin: 12px 0 8px; }
      .user-rating-wrapper .result-wrapper p:nth-child(3) {
        color: rgba(25, 25, 25, 0.6);
        font-size: 14px;
        letter-spacing: -0.2px;
        line-height: 21px; }
  .user-rating-wrapper .main-rating-wrapper {
    background: #ffffff;
    position: fixed;
    left: 50%;
    -webkit-transform: translate(-50%, 0);
            transform: translate(-50%, 0);
    top: 48px;
    width: 490px;
    padding: 32px;
    border-radius: 8px;
    max-width: 90%;
    max-height: calc(100% - 44px);
    overflow: auto; }
    .user-rating-wrapper .main-rating-wrapper .main-rating-title {
      font-size: 20px;
      letter-spacing: -0.6px;
      display: block;
      margin: 0; }
    .user-rating-wrapper .main-rating-wrapper label {
      display: block;
      font-size: 14px;
      letter-spacing: -0.4px;
      margin-top: 8px;
      position: relative; }
      .user-rating-wrapper .main-rating-wrapper label .comment-length {
        color: rgba(25, 25, 25, 0.6);
        position: absolute;
        right: 0;
        top: 0; }
      .user-rating-wrapper .main-rating-wrapper label span {
        color: rgba(25, 25, 25, 0.6); }
    .user-rating-wrapper .main-rating-wrapper textarea {
      border-radius: 8px;
      width: 100%;
      resize: none;
      border: 1px solid rgba(25, 25, 25, 0.1);
      padding: 12px 16px;
      font-size: 16px;
      letter-spacing: -0.3px; }
      .user-rating-wrapper .main-rating-wrapper textarea::-webkit-input-placeholder {
        color: rgba(25, 25, 25, 0.4); }
      .user-rating-wrapper .main-rating-wrapper textarea::-ms-input-placeholder {
        color: rgba(25, 25, 25, 0.4); }
      .user-rating-wrapper .main-rating-wrapper textarea::placeholder {
        color: rgba(25, 25, 25, 0.4); }
    .user-rating-wrapper .main-rating-wrapper .value-wrapper {
      width: 100%;
      background: rgba(25, 25, 25, 0.05);
      border-radius: 8px;
      padding: 10px;
      line-height: 1;
      color: rgba(25, 25, 25, 0.4); }
      .user-rating-wrapper .main-rating-wrapper .value-wrapper svg {
        margin-right: 10px;
        position: relative;
        top: -2px; }
    .user-rating-wrapper .main-rating-wrapper .rating-wrapper {
      margin-top: 18px;
      text-align: center; }
      .user-rating-wrapper .main-rating-wrapper .rating-wrapper p {
        margin: 0; }
      .user-rating-wrapper .main-rating-wrapper .rating-wrapper .star-selection-wrapper {
        margin: 0 0 8px;
        height: 38px;
        width: 264px;
        position: relative;
        display: inline-block;
        max-width: 100%; }
        .user-rating-wrapper .main-rating-wrapper .rating-wrapper .star-selection-wrapper img {
          max-width: 100%;
          position: relative;
          z-index: 1; }
        .user-rating-wrapper .main-rating-wrapper .rating-wrapper .star-selection-wrapper .star-selection-content {
          width: 100%;
          height: 100%;
          position: absolute;
          left: 0;
          top: 0; }
        .user-rating-wrapper .main-rating-wrapper .rating-wrapper .star-selection-wrapper .star-selection {
          width: 20%;
          vertical-align: top;
          position: relative;
          z-index: 2;
          height: 38px;
          display: inline-block;
          cursor: pointer; }

.billing-page-wrapper {
  margin: 32px 0 0; }
  .billing-page-wrapper .nav-item {
    cursor: pointer;
    color: rgba(25, 25, 25, 0.4);
    font-size: 16px;
    letter-spacing: -0.5px;
    padding: 0 0 12px 0;
    margin-right: 32px; }
    .billing-page-wrapper .nav-item a {
      padding: 0;
      border: none; }
    .billing-page-wrapper .nav-item :hover {
      color: #ff7d00; }
    .billing-page-wrapper .nav-item.active-setup-tab {
      color: #ff7d00;
      border-bottom: solid 4px #ff7d00; }
  .billing-page-wrapper .tab-content {
    margin: 32px 0 0; }

.blocking-401-wrapper {
  text-align: center;
  margin-top: 65px; }
  .blocking-401-wrapper p {
    color: rgba(25, 25, 25, 0.6);
    font-size: 20px;
    letter-spacing: -0.6px;
    margin: 32px 0; }

.subscriptions-outer-wrapper {
  widows: 100%;
  position: relative; }
  .subscriptions-outer-wrapper .subscription-list-loading-wrapper {
    height: 80vh; }
    .subscriptions-outer-wrapper .subscription-list-loading-wrapper .lds-ring {
      width: 50px;
      height: 50px;
      position: absolute;
      left: 50%;
      top: 50%;
      -webkit-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%);
      right: unset; }
      .subscriptions-outer-wrapper .subscription-list-loading-wrapper .lds-ring div {
        border: 5px solid #fff;
        border-color: #ff7d00 transparent transparent transparent;
        width: 50px;
        height: 50px;
        margin: 0; }
  .subscriptions-outer-wrapper .empty-subscription-wrapper {
    width: 100%;
    margin-top: 56px;
    text-align: center;
    font-size: 16px;
    letter-spacing: -0.5px;
    color: rgba(25, 25, 25, 0.6); }
    .subscriptions-outer-wrapper .empty-subscription-wrapper button {
      margin: 24px 0 0;
      height: 40px;
      width: 121px; }
  .subscriptions-outer-wrapper .alert-component {
    background: #fff4b1;
    border: 1px solid #ff7e00;
    box-shadow: 0px 2px 20px rgba(25, 25, 25, 0.18);
    width: 100%;
    color: #ff7e00;
    position: relative; }
    .subscriptions-outer-wrapper .alert-component .orange-button {
      position: absolute;
      right: 16px;
      top: 50%;
      -webkit-transform: translate(0, -50%);
              transform: translate(0, -50%);
      padding: 0 24px;
      height: 32px; }
  .subscriptions-outer-wrapper .subscriptions .subscriptions-header {
    font-size: 20px;
    letter-spacing: -0.6px;
    color: #191919;
    padding-bottom: 11px;
    border-bottom: solid 1px #dcdcdc;
    position: relative; }
    .subscriptions-outer-wrapper .subscriptions .subscriptions-header .subscriptions-header-button {
      position: absolute;
      right: 0;
      top: -10px;
      font-size: 16px;
      padding: 0 24px;
      letter-spacing: -.5px;
      line-height: 38px;
      height: 40px; }
      .subscriptions-outer-wrapper .subscriptions .subscriptions-header .subscriptions-header-button svg {
        position: relative;
        margin-right: 4px;
        top: -1px; }
  .subscriptions-outer-wrapper .subscriptions .subscription-table {
    margin-top: 24px;
    width: 100%; }
    .subscriptions-outer-wrapper .subscriptions .subscription-table table {
      width: 100%; }
      .subscriptions-outer-wrapper .subscriptions .subscription-table table thead tr {
        border-bottom: solid 1px rgba(25, 25, 25, 0.1); }
        .subscriptions-outer-wrapper .subscriptions .subscription-table table thead tr td {
          padding: 8px;
          text-align: left;
          color: #191919;
          font-size: 14px;
          letter-spacing: -0.4px; }
          .subscriptions-outer-wrapper .subscriptions .subscription-table table thead tr td.col_desc {
            padding-left: 0; }
          .subscriptions-outer-wrapper .subscriptions .subscription-table table thead tr td.col_price {
            width: 150px; }
          .subscriptions-outer-wrapper .subscriptions .subscription-table table thead tr td.col_paid {
            width: 120px; }
          .subscriptions-outer-wrapper .subscriptions .subscription-table table thead tr td.col_status {
            width: 160px; }
      .subscriptions-outer-wrapper .subscriptions .subscription-table table tbody tr td {
        padding: 16px 8px;
        text-align: left;
        color: #191919;
        font-size: 14px;
        letter-spacing: -0.2px; }
        .subscriptions-outer-wrapper .subscriptions .subscription-table table tbody tr td .subscription-label {
          padding: 0; }
          .subscriptions-outer-wrapper .subscriptions .subscription-table table tbody tr td .subscription-label.active {
            color: #19c700; }
          .subscriptions-outer-wrapper .subscriptions .subscription-table table tbody tr td .subscription-label.alert {
            color: #ff7e00; }
          .subscriptions-outer-wrapper .subscriptions .subscription-table table tbody tr td .subscription-label img {
            position: relative;
            margin-right: 4px;
            top: -2px; }
        .subscriptions-outer-wrapper .subscriptions .subscription-table table tbody tr td.unsubscribe {
          display: flex;
          align-items: center;
          color: #ff3f57;
          justify-content: flex-end; }
          .subscriptions-outer-wrapper .subscriptions .subscription-table table tbody tr td.unsubscribe img {
            margin-right: 4px; }
        .subscriptions-outer-wrapper .subscriptions .subscription-table table tbody tr td.col_desc {
          padding-left: 0; }
        .subscriptions-outer-wrapper .subscriptions .subscription-table table tbody tr td.col_price {
          width: 150px; }
        .subscriptions-outer-wrapper .subscriptions .subscription-table table tbody tr td.col_paid {
          width: 120px; }
        .subscriptions-outer-wrapper .subscriptions .subscription-table table tbody tr td.col_status {
          width: 160px; }
        .subscriptions-outer-wrapper .subscriptions .subscription-table table tbody tr td.col_total {
          background: #dcebff;
          font-size: 16px;
          letter-spacing: -0.5px;
          text-align: right;
          width: 100%; }

.payment-setting-outer-wrapper {
  widows: 100%;
  position: relative; }
  .payment-setting-outer-wrapper .centered-dot {
    width: 5px;
    height: 5px;
    display: inline-block;
    vertical-align: top;
    background: rgba(25, 25, 25, 0.4);
    border-radius: 50%;
    position: relative;
    top: 9px; }
  .payment-setting-outer-wrapper .card-icon {
    position: absolute;
    left: 13px;
    top: 50%;
    -webkit-transform: translate(0, -50%);
            transform: translate(0, -50%); }
  .payment-setting-outer-wrapper .card-number {
    font-size: 16px;
    color: #191919;
    letter-spacing: -0.3px;
    position: relative;
    top: -2px;
    white-space: nowrap;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis; }
    .payment-setting-outer-wrapper .card-number .masking-dot {
      vertical-align: top;
      width: 4px;
      height: 4px;
      position: relative;
      top: 3px;
      display: inline-block;
      margin: 0 2px;
      border-radius: 50%;
      background: #191919; }
  .payment-setting-outer-wrapper .card-expiry-date {
    font-size: 12px;
    letter-spacing: -0.4px;
    color: rgba(25, 25, 25, 0.6);
    white-space: nowrap;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis; }
  .payment-setting-outer-wrapper .payment-setting .payment-setting-header {
    font-size: 20px;
    letter-spacing: -0.6px;
    color: #191919;
    padding-bottom: 11px;
    border-bottom: solid 1px #dcdcdc;
    position: relative; }
    .payment-setting-outer-wrapper .payment-setting .payment-setting-header button {
      position: absolute;
      right: 0;
      top: -10px;
      font-size: 16px;
      letter-spacing: -0.5px;
      padding: 0 24px;
      text-align: center;
      height: 40px; }
      .payment-setting-outer-wrapper .payment-setting .payment-setting-header button.save-change-payment-setting {
        width: 155px; }
      .payment-setting-outer-wrapper .payment-setting .payment-setting-header button svg {
        position: relative;
        font-size: 20px;
        margin-right: 4px;
        top: -2px; }
    .payment-setting-outer-wrapper .payment-setting .payment-setting-header .payment-setting-title-description {
      font-size: 14px;
      letter-spacing: -0.2px;
      margin: 0; }
  .payment-setting-outer-wrapper .payment-setting .how-to-pay-wrapper {
    width: 100%;
    position: relative; }
    .payment-setting-outer-wrapper .payment-setting .how-to-pay-wrapper p.how-to-pay-title {
      letter-spacing: -0.5px;
      font-size: 16px;
      color: #191919;
      margin: 24px 0; }
    .payment-setting-outer-wrapper .payment-setting .how-to-pay-wrapper .custom-radio label {
      display: block;
      margin-top: 24px;
      font-size: 14px;
      letter-spacing: -0.4px; }
    .payment-setting-outer-wrapper .payment-setting .how-to-pay-wrapper .custom-radio span {
      display: block;
      font-size: 14px;
      letter-spacing: -0.2px; }
    .payment-setting-outer-wrapper .payment-setting .how-to-pay-wrapper .auto-payment-card-select-wrapper {
      position: relative; }
      .payment-setting-outer-wrapper .payment-setting .how-to-pay-wrapper .auto-payment-card-select-wrapper .auto-payment-add-card {
        font-size: 14px;
        color: #FF7E00;
        margin: 12px 0 0 27px;
        cursor: pointer; }
        .payment-setting-outer-wrapper .payment-setting .how-to-pay-wrapper .auto-payment-card-select-wrapper .auto-payment-add-card svg {
          position: relative;
          top: -2px;
          font-size: 20px;
          margin-right: 4px; }
        .payment-setting-outer-wrapper .payment-setting .how-to-pay-wrapper .auto-payment-card-select-wrapper .auto-payment-add-card:hover {
          color: #e87200; }
      .payment-setting-outer-wrapper .payment-setting .how-to-pay-wrapper .auto-payment-card-select-wrapper .selected-card {
        position: relative;
        margin-left: 27px; }
        .payment-setting-outer-wrapper .payment-setting .how-to-pay-wrapper .auto-payment-card-select-wrapper .selected-card label {
          margin: 12px 0 8px; }
        .payment-setting-outer-wrapper .payment-setting .how-to-pay-wrapper .auto-payment-card-select-wrapper .selected-card .dropdown button {
          padding: 16px 46px 16px 83px;
          border: solid 1px #dcdcdc;
          border-radius: 8px;
          background: transparent;
          box-shadow: none;
          outline: none;
          color: #191919;
          margin: 0;
          text-align: left;
          position: relative;
          max-width: 100%;
          min-width: 268px; }
          .payment-setting-outer-wrapper .payment-setting .how-to-pay-wrapper .auto-payment-card-select-wrapper .selected-card .dropdown button p {
            margin: 0;
            line-height: 1; }
          .payment-setting-outer-wrapper .payment-setting .how-to-pay-wrapper .auto-payment-card-select-wrapper .selected-card .dropdown button svg {
            position: absolute;
            right: 13px;
            top: 50%;
            -webkit-transform: translate(0, -50%);
                    transform: translate(0, -50%);
            font-size: 16px;
            -webkit-transition: ease-in-out .2s;
            transition: ease-in-out .2s; }
            .payment-setting-outer-wrapper .payment-setting .how-to-pay-wrapper .auto-payment-card-select-wrapper .selected-card .dropdown button svg.active-dropdown-card {
              -webkit-transform: translate(0, -50%) rotate(180deg);
                      transform: translate(0, -50%) rotate(180deg); }
          .payment-setting-outer-wrapper .payment-setting .how-to-pay-wrapper .auto-payment-card-select-wrapper .selected-card .dropdown button.please-select-card {
            color: rgba(25, 25, 25, 0.3);
            padding: 16px 46px 16px 16px; }
        .payment-setting-outer-wrapper .payment-setting .how-to-pay-wrapper .auto-payment-card-select-wrapper .selected-card .dropdown .dropdown-menu {
          padding: 0;
          border-radius: 8px;
          max-width: 100%; }
          .payment-setting-outer-wrapper .payment-setting .how-to-pay-wrapper .auto-payment-card-select-wrapper .selected-card .dropdown .dropdown-menu button {
            border: none;
            border-radius: 0; }
            .payment-setting-outer-wrapper .payment-setting .how-to-pay-wrapper .auto-payment-card-select-wrapper .selected-card .dropdown .dropdown-menu button:nth-child(1) {
              border-top-right-radius: 8px;
              border-top-left-radius: 8px; }
            .payment-setting-outer-wrapper .payment-setting .how-to-pay-wrapper .auto-payment-card-select-wrapper .selected-card .dropdown .dropdown-menu button:nth-last-child(1) {
              border-bottom-right-radius: 8px;
              border-bottom-left-radius: 8px; }
            .payment-setting-outer-wrapper .payment-setting .how-to-pay-wrapper .auto-payment-card-select-wrapper .selected-card .dropdown .dropdown-menu button:hover {
              background: rgba(255, 126, 0, 0.05); }
  .payment-setting-outer-wrapper .card-list-wrapper {
    width: 100%;
    padding-top: 28px; }
    .payment-setting-outer-wrapper .card-list-wrapper .card-list-no-data {
      width: 100%; }
      .payment-setting-outer-wrapper .card-list-wrapper .card-list-no-data p {
        color: rgba(25, 25, 25, 0.6);
        letter-spacing: -0.5px;
        font-size: 16px; }
    .payment-setting-outer-wrapper .card-list-wrapper .card-list-content {
      width: calc(50% - 8px);
      margin: 0 16px 16px 0;
      display: inline-block;
      position: relative;
      padding: 13px 43px 13px 83px;
      border-radius: 8px;
      border: solid 1px #dcdcdc;
      line-height: 1; }
      .payment-setting-outer-wrapper .card-list-wrapper .card-list-content p {
        margin: 0; }
      .payment-setting-outer-wrapper .card-list-wrapper .card-list-content svg {
        color: #ff3f57;
        cursor: pointer;
        position: absolute;
        right: 15px;
        top: 50%;
        -webkit-transform: translate(0, -50%);
                transform: translate(0, -50%);
        font-size: 20px; }
      .payment-setting-outer-wrapper .card-list-wrapper .card-list-content:nth-child(even) {
        margin: 0 0 16px 0; }

.billing-notif-main-wrapper .btn {
  border: 0; }

.billing-notif-main-wrapper tr:hover td {
  background: #ffffff !important; }

.billing-notif-main-wrapper .billing-notif-top-title {
  font-size: 24px;
  letter-spacing: -0.6px;
  color: #191919;
  border-bottom: solid 1px #dcdcdc;
  padding-bottom: 12px;
  margin-bottom: 24px; }

.billing-notif-main-wrapper .topContent {
  align-items: unset !important;
  display: block;
  position: relative;
  width: 100%;
  padding: 0 225px 0 0; }
  .billing-notif-main-wrapper .topContent .title {
    margin: 0 0 4px 0; }
  .billing-notif-main-wrapper .topContent .billing-notif-list-info {
    padding-bottom: 0;
    font-size: 12px; }
  .billing-notif-main-wrapper .topContent .btn {
    position: absolute;
    right: 0;
    top: 50%;
    -webkit-transform: translate(0, -50%);
            transform: translate(0, -50%); }

.billing-notif-main-wrapper .billing-notif-list-table-wrapper {
  border-top: solid 1px #dcdcdc;
  margin-top: 16px; }

.billing-notif-list-title {
  color: #191919;
  font-size: 20px;
  letter-spacing: -0.6px;
  line-height: 24px;
  padding-bottom: 16px;
  border-bottom: solid 1px rgba(25, 25, 25, 0.1); }

.billing-notif-list-tool-wrapper {
  width: 100%;
  position: relative;
  margin-top: 24px;
  margin-bottom: 32px; }

.billing-notif-list-info {
  font-size: 14px;
  letter-spacing: -0.3px;
  color: rgba(25, 25, 25, 0.8);
  line-height: 1.5;
  padding: 0 48px 18px 0;
  position: relative;
  top: -4px; }

.billing-notif-list-tool-wrapper button.button-invite-member {
  position: absolute;
  right: 0;
  top: 50%;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%);
  padding: 11px 0;
  width: 200px; }

.billing-notif-list-tool-wrapper button.button-invite-member svg {
  position: relative;
  top: -1px;
  margin-right: 5px; }

.billing-notif-list-table-wrapper {
  width: 100%;
  position: relative; }

.table-billing-notif-list thead tr {
  background: #ffffff; }

.table-billing-notif-list tbody tr:nth-last-child(1) {
  border-bottom: none !important; }

.table-billing-notif-list tbody td {
  background: #ffffff;
  position: relative; }

.table-billing-notif-list button svg {
  position: relative !important;
  right: unset !important;
  top: -2px !important;
  margin-right: 5px; }

.table-billing-notif-list .col-action-button-wrapper {
  position: absolute;
  right: 7px;
  top: 50%;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%);
  /* display: none; */ }

.table-billing-notif-list .single-remove-button {
  margin-left: 16px;
  color: #ff3f57; }

.table-billing-notif-list .single-remove-button:hover {
  color: #e83b51; }

.table-billing-notif-list .shortcut-badge {
  font-size: 12px;
  color: rgba(25, 25, 25, 0.6);
  letter-spacing: -0.4px;
  background: #f3f3f3;
  line-height: 1;
  border-radius: 4px;
  padding: 1px 8px 3px; }

.table-billing-notif-list td {
  max-width: unset !important; }

.table-billing-notif-list .col-action-button-wrapper {
  display: block !important;
  position: relative;
  top: 0;
  right: unset;
  -webkit-transform: unset;
          transform: unset; }

.table-billing-notif-list .col-action-button-wrapper .view-agent {
  color: #ff7d00;
  letter-spacing: -0.5px; }

.table-billing-notif-list .col-action-button-wrapper .view-agent:hover {
  color: var(--main-darker-orange); }

.table-billing-notif-list .col-action-button-wrapper img.view-agent {
  margin-right: 3px;
  position: relative;
  top: -1px; }

.member-tool-wrapper {
  width: 460px; }

.table-billing-notif-list .col_action {
  width: 200px !important;
  text-align: right; }

.table-billing-notif-list .no-data-column {
  text-align: left !important; }

.billing-notif-page-wrapper {
  margin: 32px 0 0; }
  .billing-notif-page-wrapper .nav-item {
    cursor: pointer;
    color: rgba(25, 25, 25, 0.4);
    font-size: 16px;
    letter-spacing: -0.5px;
    padding: 0 0 12px 0;
    margin-right: 32px; }
    .billing-notif-page-wrapper .nav-item a {
      padding: 0;
      border: none; }
    .billing-notif-page-wrapper .nav-item :hover {
      color: #ff7d00; }
    .billing-notif-page-wrapper .nav-item.active-setup-tab {
      color: #ff7d00;
      border-bottom: solid 4px #ff7d00; }
  .billing-notif-page-wrapper .tab-content {
    margin: 32px 0 0; }

.textarea-confirm-email {
  padding: 12px 16px;
  border-radius: 8px;
  border: solid 1px #dcdcdc;
  width: 100%;
  resize: none;
  height: 50px;
  margin-top: 20px;
  font-size: 16px;
  letter-spacing: -0.3px; }
  .textarea-confirm-email::-webkit-input-placeholder {
    color: rgba(25, 25, 25, 0.4); }
  .textarea-confirm-email::-ms-input-placeholder {
    color: rgba(25, 25, 25, 0.4); }
  .textarea-confirm-email::placeholder {
    color: rgba(25, 25, 25, 0.4); }

.popup-remove-billing .modal-body {
  border-bottom: none !important; }
  .popup-remove-billing .modal-body .red-text b {
    font-size: 12px;
    letter-spacing: -0.4px;
    line-height: 1;
    color: #ff3f57 !important; }
  .popup-remove-billing .modal-body .red-text svg {
    position: relative;
    top: -1px; }

.popup-remove-billing .modal-footer {
  border-top: none !important;
  padding-top: 0; }

.billing-notification-add-modal {
  position: fixed !important;
  left: unset;
  right: 0;
  -webkit-transform: unset;
          transform: unset;
  top: 0;
  border-radius: 8px 0 0 0;
  height: 100%; }
  .billing-notification-add-modal .modal-content {
    width: 450px;
    border-radius: 8px;
    position: fixed;
    left: unset;
    right: 0;
    -webkit-transform: unset;
            transform: unset;
    top: 0;
    border-radius: 8px 0 0 0;
    height: 100%; }
    .billing-notification-add-modal .modal-content .modal-body {
      position: relative;
      padding: 0; }
      .billing-notification-add-modal .modal-content .modal-body .modal-title {
        font-size: 24px;
        letter-spacing: -0.6px;
        margin: 0 0 30px 0;
        padding: 24px 32px 0 32px; }
        .billing-notification-add-modal .modal-content .modal-body .modal-title span {
          font-size: 16px;
          letter-spacing: -0.3px;
          color: rgba(25, 25, 25, 0.6); }
      .billing-notification-add-modal .modal-content .modal-body .email-input-wrapper {
        position: relative;
        display: block;
        width: 100%;
        padding: 0 32px 0;
        max-height: calc(100% - 180px);
        overflow: auto; }
        .billing-notification-add-modal .modal-content .modal-body .email-input-wrapper .add-more-button {
          display: inline-block;
          font-size: 12px;
          letter-spacing: .2px;
          cursor: pointer;
          padding: 0 10px;
          margin: 12px 0 0 4px;
          border-radius: 4px; }
        .billing-notification-add-modal .modal-content .modal-body .email-input-wrapper .email-input-box {
          position: relative;
          width: 100%;
          display: block; }
          .billing-notification-add-modal .modal-content .modal-body .email-input-wrapper .email-input-box:nth-child(n + 2) {
            margin-top: 12px; }
          .billing-notification-add-modal .modal-content .modal-body .email-input-wrapper .email-input-box .red-text {
            margin: 0; }
            .billing-notification-add-modal .modal-content .modal-body .email-input-wrapper .email-input-box .red-text b {
              font-size: 12px;
              letter-spacing: -0.4px;
              line-height: 1;
              color: #ff3f57 !important; }
            .billing-notification-add-modal .modal-content .modal-body .email-input-wrapper .email-input-box .red-text svg {
              position: relative;
              top: -1px; }
          .billing-notification-add-modal .modal-content .modal-body .email-input-wrapper .email-input-box input {
            border: solid 1px #dcdcdc;
            border-radius: 8px;
            padding: 11px 12px 11px 44px;
            font-size: 16px;
            letter-spacing: -0.3px;
            width: calc(100% - 30px); }
            .billing-notification-add-modal .modal-content .modal-body .email-input-wrapper .email-input-box input::-webkit-input-placeholder {
              color: rgba(25, 25, 25, 0.4); }
            .billing-notification-add-modal .modal-content .modal-body .email-input-wrapper .email-input-box input::-ms-input-placeholder {
              color: rgba(25, 25, 25, 0.4); }
            .billing-notification-add-modal .modal-content .modal-body .email-input-wrapper .email-input-box input::placeholder {
              color: rgba(25, 25, 25, 0.4); }
          .billing-notification-add-modal .modal-content .modal-body .email-input-wrapper .email-input-box .mail-icon {
            color: #7b8794;
            position: absolute;
            left: 12px;
            top: 14px;
            font-size: 20px; }
          .billing-notification-add-modal .modal-content .modal-body .email-input-wrapper .email-input-box .trash-icon {
            color: #ff3f57;
            position: absolute;
            right: 0;
            top: 14px;
            font-size: 20px;
            cursor: pointer; }
      .billing-notification-add-modal .modal-content .modal-body .add-recipient-button-wrapper {
        width: 100%;
        position: absolute;
        bottom: 0;
        left: 0;
        padding: 24px 32px; }
        .billing-notification-add-modal .modal-content .modal-body .add-recipient-button-wrapper button {
          width: calc(50% - 4px);
          margin: 0 8px 0 0;
          display: inline-block; }
          .billing-notification-add-modal .modal-content .modal-body .add-recipient-button-wrapper button:nth-last-child(1) {
            margin: 0; }

.modal-add-email-notification button {
  width: calc(50% - 4px) !important;
  margin-right: 8px !important; }
  .modal-add-email-notification button:nth-last-child(1) {
    margin: 0 !important; }

.billing-notification-max-limit .modal-content {
  width: 450px;
  border-radius: 8px;
  padding: 32px 32px; }
  .billing-notification-max-limit .modal-content .modal-body {
    padding: 0; }
    .billing-notification-max-limit .modal-content .modal-body .modal-title {
      font-size: 20px;
      letter-spacing: -0.6px;
      margin: 0 0 4px 0; }
    .billing-notification-max-limit .modal-content .modal-body .modal-desc {
      font-size: 14px;
      letter-spacing: -0.2px;
      line-height: 21px;
      margin: 0 0 20px 0;
      color: rgba(25, 25, 25, 0.6); }
    .billing-notification-max-limit .modal-content .modal-body button {
      width: 100%; }

.view-pending-payment-wrapper #retrieve-data-loading-page {
  padding-top: 0; }

.view-pending-payment-wrapper .view-pending-payment-title {
  font-size: 20px;
  letter-spacing: -0.6px;
  color: #191919;
  padding-bottom: 12px;
  border-bottom: solid 1px #dcdcdc; }

.view-pending-payment-wrapper table {
  width: 100%;
  margin-top: 24px; }
  .view-pending-payment-wrapper table thead tr {
    border-bottom: solid 1px rgba(25, 25, 25, 0.1); }
    .view-pending-payment-wrapper table thead tr td {
      padding: 8px;
      text-align: left;
      color: #191919;
      font-size: 14px;
      letter-spacing: -0.4px; }
  .view-pending-payment-wrapper table tbody tr td {
    padding: 16px 8px;
    text-align: left;
    color: #191919;
    font-size: 14px;
    letter-spacing: -0.2px; }
    .view-pending-payment-wrapper table tbody tr td .subscription-label-view {
      color: #ff7e00;
      cursor: pointer; }
      .view-pending-payment-wrapper table tbody tr td .subscription-label-view svg {
        position: relative;
        margin-right: 4px;
        top: -2px; }
      .view-pending-payment-wrapper table tbody tr td .subscription-label-view:hover {
        color: #e87200; }
    .view-pending-payment-wrapper table tbody tr td.col_total {
      background: #DCEBFF;
      font-size: 16px;
      letter-spacing: -0.5px;
      text-align: right;
      width: 100%; }

.unpaid-invoices-detail-wrapper {
  padding: 40px 32px; }
  .unpaid-invoices-detail-wrapper .unpaid-invoices-detail-title {
    font-size: 20px;
    letter-spacing: -0.6px;
    color: #191919;
    margin: 0 0 16px;
    position: relative; }
    .unpaid-invoices-detail-wrapper .unpaid-invoices-detail-title .button-top-wrapper {
      position: absolute;
      right: 0;
      top: 50%;
      -webkit-transform: translate(0, -50%);
              transform: translate(0, -50%); }
      .unpaid-invoices-detail-wrapper .unpaid-invoices-detail-title .button-top-wrapper button {
        font-size: 16px;
        letter-spacing: -0.5px;
        margin-left: 4px; }
        .unpaid-invoices-detail-wrapper .unpaid-invoices-detail-title .button-top-wrapper button svg {
          position: relative;
          top: -1px;
          margin-right: 4px; }
  .unpaid-invoices-detail-wrapper .alert-failed {
    color: #FF3F57;
    background: #FFE6E9;
    border: 1px solid #FF3F57;
    max-width: 100%;
    margin: 16px 0; }
    .unpaid-invoices-detail-wrapper .alert-failed .masking-dot {
      vertical-align: top;
      width: 4px;
      height: 4px;
      position: relative;
      top: 7px;
      display: inline-block;
      margin: 0 2px;
      border-radius: 50%;
      background: #FF3F57; }
    .unpaid-invoices-detail-wrapper .alert-failed svg {
      top: 25px; }
  .unpaid-invoices-detail-wrapper .invoice-payment-issue-due {
    border-bottom: solid 1px #dcdcdc; }
    .unpaid-invoices-detail-wrapper .invoice-payment-issue-due div {
      width: 137px;
      display: inline-block;
      vertical-align: top;
      margin-right: 64px;
      padding-bottom: 12px;
      font-size: 14px;
      line-height: 21px;
      letter-spacing: -0.4px;
      color: #000000; }
      .unpaid-invoices-detail-wrapper .invoice-payment-issue-due div b {
        font-size: 12px;
        line-height: 18px; }
  .unpaid-invoices-detail-wrapper table {
    width: 100%;
    margin-top: 24px; }
    .unpaid-invoices-detail-wrapper table thead tr {
      border-bottom: solid 1px rgba(25, 25, 25, 0.1); }
      .unpaid-invoices-detail-wrapper table thead tr td {
        padding: 8px 8px 4px;
        text-align: left;
        color: #191919;
        font-size: 14px;
        letter-spacing: -0.4px; }
        .unpaid-invoices-detail-wrapper table thead tr td.col_amount {
          text-align: right; }
        .unpaid-invoices-detail-wrapper table thead tr td:nth-child(2) {
          text-align: center; }
    .unpaid-invoices-detail-wrapper table tbody tr td {
      padding: 4px 8px 7px;
      text-align: left;
      color: #191919;
      font-size: 14px;
      letter-spacing: -0.2px;
      vertical-align: top; }
      .unpaid-invoices-detail-wrapper table tbody tr td.col_description {
        font-size: 12px;
        letter-spacing: -0.2px;
        line-height: 16px; }
        .unpaid-invoices-detail-wrapper table tbody tr td.col_description b {
          font-size: 14px;
          line-height: 21px; }
      .unpaid-invoices-detail-wrapper table tbody tr td.col_amount {
        text-align: right; }
      .unpaid-invoices-detail-wrapper table tbody tr td:nth-child(2) {
        text-align: center; }
      .unpaid-invoices-detail-wrapper table tbody tr td .subscription-label-view {
        color: #ff7e00;
        cursor: pointer; }
        .unpaid-invoices-detail-wrapper table tbody tr td .subscription-label-view svg {
          position: relative;
          margin-right: 4px;
          top: -2px; }
        .unpaid-invoices-detail-wrapper table tbody tr td .subscription-label-view:hover {
          color: #e87200; }
      .unpaid-invoices-detail-wrapper table tbody tr td.col_total {
        background: #DCEBFF;
        font-size: 16px;
        letter-spacing: -0.5px;
        text-align: right;
        width: 100%; }
    .unpaid-invoices-detail-wrapper table tbody tr:nth-child(odd) {
      background: rgba(25, 25, 25, 0.05); }
  .unpaid-invoices-detail-wrapper .invoice-detail-footer {
    position: relative;
    width: 100%;
    min-height: 160px;
    margin-top: 24px; }
    .unpaid-invoices-detail-wrapper .invoice-detail-footer .invoice-footer-right {
      position: absolute;
      top: 0;
      right: 0;
      width: 320px;
      max-width: calc(50%);
      display: inline-block; }
      .unpaid-invoices-detail-wrapper .invoice-detail-footer .invoice-footer-right table {
        margin: 0; }
        .unpaid-invoices-detail-wrapper .invoice-detail-footer .invoice-footer-right table tr {
          background: rgba(25, 25, 25, 0.05);
          border-bottom: solid 1px #ffffff; }
          .unpaid-invoices-detail-wrapper .invoice-detail-footer .invoice-footer-right table tr td {
            padding: 6px 8px 4px;
            line-height: 21px;
            color: rgba(25, 25, 25, 0.8); }
            .unpaid-invoices-detail-wrapper .invoice-detail-footer .invoice-footer-right table tr td:nth-child(even) {
              text-align: right; }
            .unpaid-invoices-detail-wrapper .invoice-detail-footer .invoice-footer-right table tr td.sub_total_amount {
              color: #191919; }
          .unpaid-invoices-detail-wrapper .invoice-detail-footer .invoice-footer-right table tr:nth-child(even) {
            background: rgba(25, 25, 25, 0.1); }
          .unpaid-invoices-detail-wrapper .invoice-detail-footer .invoice-footer-right table tr.col_footer_total {
            background: #DCEBFF; }
            .unpaid-invoices-detail-wrapper .invoice-detail-footer .invoice-footer-right table tr.col_footer_total td .col_right {
              font-size: 16px;
              letter-spacing: -0.5px;
              line-height: 24px; }
    .unpaid-invoices-detail-wrapper .invoice-detail-footer .pay-pending-bottom-wrapper {
      max-width: calc(50%);
      width: 371px;
      display: inline-block;
      padding: 24px;
      letter-spacing: -0.4px;
      font-size: 14px;
      color: #191919;
      border-top: solid 1px #dcdcdc;
      border-right: solid 1px #dcdcdc; }
      .unpaid-invoices-detail-wrapper .invoice-detail-footer .pay-pending-bottom-wrapper .account-number-wrapper {
        width: 291px;
        max-width: 100%;
        position: relative;
        border-radius: 8px;
        border: solid 1px #dcdcdc;
        height: 48px;
        margin-top: 8px;
        font-size: 16px;
        letter-spacing: -0.5px;
        color: #191919;
        padding: 10px 56px;
        margin-bottom: 24px; }
        .unpaid-invoices-detail-wrapper .invoice-detail-footer .pay-pending-bottom-wrapper .account-number-wrapper img {
          position: absolute;
          left: 16px;
          top: 50%;
          -webkit-transform: translate(0, -50%);
                  transform: translate(0, -50%); }
        .unpaid-invoices-detail-wrapper .invoice-detail-footer .pay-pending-bottom-wrapper .account-number-wrapper svg {
          position: absolute;
          right: 16px;
          top: 50%;
          -webkit-transform: translate(0, -50%);
                  transform: translate(0, -50%);
          color: #a3a3a3;
          cursor: pointer; }
          .unpaid-invoices-detail-wrapper .invoice-detail-footer .pay-pending-bottom-wrapper .account-number-wrapper svg:hover {
            color: #6f6f6f; }
        .unpaid-invoices-detail-wrapper .invoice-detail-footer .pay-pending-bottom-wrapper .account-number-wrapper input {
          font-weight: bold;
          border: none; }
      .unpaid-invoices-detail-wrapper .invoice-detail-footer .pay-pending-bottom-wrapper .alert-component {
        border: solid 1px #044B84;
        background: #DCEBFF;
        color: #044B84;
        letter-spacing: -0.4px;
        font-size: 14px;
        width: 323px;
        max-width: 100%;
        padding: 12px 16px 12px 38px; }
        .unpaid-invoices-detail-wrapper .invoice-detail-footer .pay-pending-bottom-wrapper .alert-component svg {
          font-size: 16px;
          top: 22px; }

.modal-retry-payment .modal-content {
  border-radius: 8px;
  width: 489px;
  display: inline-block; }

.modal-retry-payment .modal-body {
  padding: 32px;
  text-align: center; }
  .modal-retry-payment .modal-body .lds-ring {
    height: 53px;
    width: 53px;
    top: unset;
    right: unset;
    margin: 0 0 12px 0; }
    .modal-retry-payment .modal-body .lds-ring div {
      height: 53px;
      width: 53px;
      margin: 0;
      border: 6px solid #fff;
      border-color: #ff7d00 transparent transparent transparent; }
  .modal-retry-payment .modal-body p {
    color: #000000;
    font-size: 14px;
    letter-spacing: -0.2px;
    margin: 0; }

.modal-success-payment .modal-content {
  width: 489px;
  border-radius: 8px;
  text-align: center; }
  .modal-success-payment .modal-content .modal-body {
    padding: 32px; }
    .modal-success-payment .modal-content .modal-body p {
      font-size: 14px;
      letter-spacing: -0.2px;
      margin: 8px 0 16px; }
    .modal-success-payment .modal-content .modal-body button {
      width: 100%; }

.modal-manual-verification .modal-content {
  width: 476px;
  border-radius: 8px; }
  .modal-manual-verification .modal-content .modal-body {
    padding: 32px;
    position: relative;
    overflow-x: hidden; }
    .modal-manual-verification .modal-content .modal-body svg.close-modal {
      position: absolute;
      cursor: pointer;
      color: rgba(25, 25, 25, 0.4);
      -webkit-transition: ease-in-out;
      transition: ease-in-out;
      font-size: 20px;
      top: 20px;
      right: 21px; }
      .modal-manual-verification .modal-content .modal-body svg.close-modal:hover {
        color: rgba(25, 25, 25, 0.8); }
    .modal-manual-verification .modal-content .modal-body .manual-verification-progress-bar-wrapper {
      width: 100%;
      position: relative; }
      .modal-manual-verification .modal-content .modal-body .manual-verification-progress-bar-wrapper .progress-value-wrapper {
        width: 50%;
        display: inline-block;
        vertical-align: top;
        text-align: center; }
        .modal-manual-verification .modal-content .modal-body .manual-verification-progress-bar-wrapper .progress-value-wrapper .progress-step-wrapper {
          width: 32px;
          height: 32px;
          background: #ffffff;
          border-radius: 50%;
          position: relative;
          display: inline-block;
          text-align: center;
          margin-bottom: 12px; }
          .modal-manual-verification .modal-content .modal-body .manual-verification-progress-bar-wrapper .progress-value-wrapper .progress-step-wrapper .progress-step-inner {
            width: 23px;
            height: 23px;
            background: #f3f3f3;
            border-radius: 50%;
            position: relative;
            text-align: center;
            line-height: 24px;
            font-size: 12px;
            border: 1px solid rgba(25, 25, 25, 0.1);
            display: inline-block;
            position: absolute;
            top: 50%;
            left: 5px;
            -webkit-transform: translate(0, -50%);
                    transform: translate(0, -50%); }
            .modal-manual-verification .modal-content .modal-body .manual-verification-progress-bar-wrapper .progress-value-wrapper .progress-step-wrapper .progress-step-inner b {
              position: absolute;
              top: 50%;
              left: 50%;
              -webkit-transform: translate(-50%, -50%);
                      transform: translate(-50%, -50%);
              color: rgba(25, 25, 25, 0.4); }
        .modal-manual-verification .modal-content .modal-body .manual-verification-progress-bar-wrapper .progress-value-wrapper .progress-label {
          font-size: 14px;
          letter-spacing: -0.4px;
          color: rgba(25, 25, 25, 0.4); }
        .modal-manual-verification .modal-content .modal-body .manual-verification-progress-bar-wrapper .progress-value-wrapper.selected-value-wrapper .progress-step-wrapper {
          background: #FFF2E5; }
          .modal-manual-verification .modal-content .modal-body .manual-verification-progress-bar-wrapper .progress-value-wrapper.selected-value-wrapper .progress-step-wrapper .progress-step-inner {
            background: #ff7d00; }
            .modal-manual-verification .modal-content .modal-body .manual-verification-progress-bar-wrapper .progress-value-wrapper.selected-value-wrapper .progress-step-wrapper .progress-step-inner b {
              color: #ffffff; }
        .modal-manual-verification .modal-content .modal-body .manual-verification-progress-bar-wrapper .progress-value-wrapper.selected-value-wrapper .progress-label {
          color: #191919; }
      .modal-manual-verification .modal-content .modal-body .manual-verification-progress-bar-wrapper .line-separator {
        height: 6px;
        width: 183px;
        background: #f3f3f3;
        border-top: solid 2px #e8e8e8;
        border-bottom: solid 2px #e8e8e8;
        position: absolute;
        top: 13px;
        left: 114px; }
        .modal-manual-verification .modal-content .modal-body .manual-verification-progress-bar-wrapper .line-separator.orange-line {
          border-top: solid 2px #ff7d00;
          border-bottom: solid 2px #ff7d00;
          background: #ff7d00; }
    .modal-manual-verification .modal-content .modal-body .manual-step-1 {
      -webkit-transition: ease-in-out .2s;
      transition: ease-in-out .2s;
      position: relative; }
      .modal-manual-verification .modal-content .modal-body .manual-step-1 .manual-middle {
        position: relative;
        width: 100%;
        -webkit-transition: ease-in-out .2s;
        transition: ease-in-out .2s;
        border-top: solid 1px #dcdcdc;
        border-bottom: solid 1px #dcdcdc;
        margin: 12px 0 16px;
        padding: 16px 0 0; }
        .modal-manual-verification .modal-content .modal-body .manual-step-1 .manual-middle .warning-top {
          text-align: center;
          width: 100%;
          font-size: 14px;
          letter-spacing: -0.2px;
          margin: 0 0 24px; }
        .modal-manual-verification .modal-content .modal-body .manual-step-1 .manual-middle .value-wrapper {
          font-size: 16px;
          letter-spacing: -0.5px;
          padding: 8px 16px;
          width: 100%;
          background: #F3F3F3;
          border-radius: 8px;
          position: relative;
          margin: 0 0 24px; }
          .modal-manual-verification .modal-content .modal-body .manual-step-1 .manual-middle .value-wrapper svg {
            cursor: pointer;
            font-size: 18px;
            color: rgba(25, 25, 25, 0.4);
            position: absolute;
            right: 16px;
            top: 50%;
            -webkit-transform: translate(0, -50%);
                    transform: translate(0, -50%); }
            .modal-manual-verification .modal-content .modal-body .manual-step-1 .manual-middle .value-wrapper svg:hover {
              color: rgba(25, 25, 25, 0.8); }
          .modal-manual-verification .modal-content .modal-body .manual-step-1 .manual-middle .value-wrapper.value-wrapper-bank {
            padding: 8px 16px 8px 54px; }
            .modal-manual-verification .modal-content .modal-body .manual-step-1 .manual-middle .value-wrapper.value-wrapper-bank img {
              position: absolute;
              left: 16px;
              top: 50%;
              -webkit-transform: translate(0, -50%);
                      transform: translate(0, -50%); }
      .modal-manual-verification .modal-content .modal-body .manual-step-1 .warning-bottom {
        text-align: center;
        width: 100%;
        font-size: 14px;
        letter-spacing: -0.2px;
        margin: 0 0 24px; }
      .modal-manual-verification .modal-content .modal-body .manual-step-1 .button-submit {
        width: 100%; }
    .modal-manual-verification .modal-content .modal-body .manual-step-2 {
      -webkit-transition: ease-in-out .2s;
      transition: ease-in-out .2s;
      position: relative;
      border-top: solid 1px #dcdcdc;
      padding-top: 16px;
      margin-top: 12px; }
      .modal-manual-verification .modal-content .modal-body .manual-step-2 .warning-top {
        text-align: center;
        width: 100%;
        font-size: 14px;
        letter-spacing: -0.2px; }
      .modal-manual-verification .modal-content .modal-body .manual-step-2 input {
        width: 100%; }
      .modal-manual-verification .modal-content .modal-body .manual-step-2 .button-select-image {
        margin-top: 0;
        width: unset; }
      .modal-manual-verification .modal-content .modal-body .manual-step-2 .button-submit {
        margin-top: 20px; }
      .modal-manual-verification .modal-content .modal-body .manual-step-2 .button-verify {
        float: right; }

.pay-now-wrapper .auto-payment-card-select-wrapper {
  position: relative; }
  .pay-now-wrapper .auto-payment-card-select-wrapper .auto-payment-add-card {
    font-size: 14px;
    color: #FF7E00;
    margin: 12px 0 0 0;
    cursor: pointer; }
    .pay-now-wrapper .auto-payment-card-select-wrapper .auto-payment-add-card svg {
      position: relative;
      top: -2px;
      font-size: 20px;
      margin-right: 4px; }
    .pay-now-wrapper .auto-payment-card-select-wrapper .auto-payment-add-card:hover {
      color: #e87200; }
  .pay-now-wrapper .auto-payment-card-select-wrapper.selected-card {
    position: relative; }
    .pay-now-wrapper .auto-payment-card-select-wrapper.selected-card label {
      margin: 12px 0 8px; }
    .pay-now-wrapper .auto-payment-card-select-wrapper.selected-card .dropdown button {
      padding: 16px 46px 16px 83px;
      border: solid 1px #dcdcdc;
      border-radius: 8px;
      background: transparent;
      box-shadow: none !important;
      outline: none;
      color: #191919;
      margin: 0;
      text-align: left;
      position: relative;
      max-width: 100%;
      min-width: 268px; }
      .pay-now-wrapper .auto-payment-card-select-wrapper.selected-card .dropdown button p {
        margin: 0;
        line-height: 1; }
      .pay-now-wrapper .auto-payment-card-select-wrapper.selected-card .dropdown button svg {
        position: absolute;
        right: 13px;
        top: 50%;
        -webkit-transform: translate(0, -50%);
                transform: translate(0, -50%);
        font-size: 16px;
        -webkit-transition: ease-in-out .2s;
        transition: ease-in-out .2s; }
        .pay-now-wrapper .auto-payment-card-select-wrapper.selected-card .dropdown button svg.active-dropdown-card {
          -webkit-transform: translate(0, -50%) rotate(180deg);
                  transform: translate(0, -50%) rotate(180deg); }
      .pay-now-wrapper .auto-payment-card-select-wrapper.selected-card .dropdown button.please-select-card {
        color: rgba(25, 25, 25, 0.3);
        padding: 16px 46px 16px 16px; }
    .pay-now-wrapper .auto-payment-card-select-wrapper.selected-card .dropdown .dropdown-menu {
      padding: 0;
      border-radius: 8px;
      max-width: 100%; }
      .pay-now-wrapper .auto-payment-card-select-wrapper.selected-card .dropdown .dropdown-menu button {
        border: none;
        border-radius: 0; }
        .pay-now-wrapper .auto-payment-card-select-wrapper.selected-card .dropdown .dropdown-menu button:nth-child(1) {
          border-top-right-radius: 8px;
          border-top-left-radius: 8px; }
        .pay-now-wrapper .auto-payment-card-select-wrapper.selected-card .dropdown .dropdown-menu button:nth-last-child(1) {
          border-bottom-right-radius: 8px;
          border-bottom-left-radius: 8px; }
        .pay-now-wrapper .auto-payment-card-select-wrapper.selected-card .dropdown .dropdown-menu button:hover {
          background: rgba(255, 126, 0, 0.05); }
    .pay-now-wrapper .auto-payment-card-select-wrapper.selected-card .centered-dot {
      width: 5px;
      height: 5px;
      display: inline-block;
      vertical-align: top;
      background: rgba(25, 25, 25, 0.4);
      border-radius: 50%;
      position: relative;
      top: 9px; }
    .pay-now-wrapper .auto-payment-card-select-wrapper.selected-card .card-icon {
      position: absolute;
      left: 13px;
      top: 50%;
      -webkit-transform: translate(0, -50%);
              transform: translate(0, -50%); }
    .pay-now-wrapper .auto-payment-card-select-wrapper.selected-card .card-number {
      font-size: 16px;
      color: #191919;
      letter-spacing: -0.3px;
      position: relative;
      top: -2px;
      white-space: nowrap;
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis; }
      .pay-now-wrapper .auto-payment-card-select-wrapper.selected-card .card-number .masking-dot {
        vertical-align: top;
        width: 4px;
        height: 4px;
        position: relative;
        top: 3px;
        display: inline-block;
        margin: 0 2px;
        border-radius: 50%;
        background: #191919; }
    .pay-now-wrapper .auto-payment-card-select-wrapper.selected-card .card-expiry-date {
      font-size: 12px;
      letter-spacing: -0.4px;
      color: rgba(25, 25, 25, 0.6);
      white-space: nowrap;
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis; }

.pay-now-wrapper .option-box-wrapper {
  position: relative;
  border: solid 1px #dcdcdc;
  padding: 17px 15px;
  width: 291px;
  border-radius: 8px;
  margin-bottom: 10px; }

.pay-now-wrapper .button-continue {
  margin-top: 22px;
  width: 110px; }

.pay-now-wrapper .custom-radio label {
  margin: 0;
  position: relative;
  line-height: 1; }
  .pay-now-wrapper .custom-radio label::before {
    top: 50% !important;
    -webkit-transform: translate(0, -50%);
            transform: translate(0, -50%); }
  .pay-now-wrapper .custom-radio label::after {
    top: 50% !important;
    -webkit-transform: translate(0, -50%) scale(1) !important;
            transform: translate(0, -50%) scale(1) !important; }
  .pay-now-wrapper .custom-radio label img {
    position: absolute;
    right: 0;
    top: 50%;
    -webkit-transform: translate(0, -50%);
            transform: translate(0, -50%); }

.pay-bank-transfer-wrapper .please-transfer-to {
  font-size: 14px;
  letter-spacing: -0.4px;
  margin-top: 24px; }

.pay-bank-transfer-wrapper .orange-warning {
  color: #ff7d00;
  font-size: 12px;
  letter-spacing: -0.4px; }
  .pay-bank-transfer-wrapper .orange-warning svg {
    position: relative;
    top: -1px;
    margin-right: 4px; }

.pay-bank-transfer-wrapper .bank-transfer-box {
  border-radius: 8px;
  border: solid 1px #dcdcdc;
  position: relative;
  width: 291px;
  font-size: 16px;
  letter-spacing: -0.5px;
  height: 48px;
  line-height: 45px;
  padding: 0 12px;
  cursor: pointer; }
  .pay-bank-transfer-wrapper .bank-transfer-box.bank-account {
    padding: 0 12px 12px 66px; }
  .pay-bank-transfer-wrapper .bank-transfer-box img {
    position: absolute;
    top: 50%;
    left: 12px;
    -webkit-transform: translate(0, -50%);
            transform: translate(0, -50%); }
  .pay-bank-transfer-wrapper .bank-transfer-box svg {
    color: #a3a3a3;
    font-size: 18px;
    cursor: pointer;
    position: absolute;
    top: 50%;
    right: 12px;
    -webkit-transform: translate(0, -50%);
            transform: translate(0, -50%); }
  .pay-bank-transfer-wrapper .bank-transfer-box:hover svg {
    color: #ff7d00; }

.pay-bank-transfer-wrapper .alert-component {
  font-size: 14px;
  letter-spacing: -0.4px;
  width: 323px;
  position: relative;
  padding: 12px 16px 12px 38px; }
  .pay-bank-transfer-wrapper .alert-component svg {
    top: 22px;
    left: 16px;
    font-size: 16px; }

.pay-bank-transfer-wrapper .display-none {
  display: none; }

.add-card-wrapper .add-card-title {
  font-size: 20px;
  letter-spacing: -0.6px;
  color: #191919;
  padding-bottom: 12px;
  border-bottom: solid 1px #dcdcdc; }

.add-card-wrapper .form-content-wrapper {
  width: 300px; }
  .add-card-wrapper .form-content-wrapper .warning-error-red {
    color: #ff3f57;
    font-size: 12px;
    letter-spacing: -0.4px;
    margin: 4px 0 0; }
    .add-card-wrapper .form-content-wrapper .warning-error-red svg {
      font-size: 14px;
      position: relative;
      top: -2px;
      margin-right: 4px;
      color: #ff3f57 !important;
      cursor: unset !important; }
  .add-card-wrapper .form-content-wrapper label {
    position: relative; }
    .add-card-wrapper .form-content-wrapper label .custom-tooltip {
      padding: 14px;
      color: #ffffff;
      background: #4a4a4a;
      letter-spacing: 0.4px;
      width: 219px;
      font-weight: normal;
      border-radius: 8px;
      position: absolute;
      top: -35px;
      right: -175px;
      font-size: 14px;
      z-index: 1; }
      .add-card-wrapper .form-content-wrapper label .custom-tooltip::before {
        content: "";
        border-bottom: solid 12px #4a4a4a;
        border-top: solid 12px transparent;
        border-left: solid 12px transparent;
        border-right: solid 12px transparent;
        -webkit-transform: rotate(-90deg);
                transform: rotate(-90deg);
        width: 20px;
        height: 20px;
        position: absolute;
        top: 35px;
        left: -24px; }
  .add-card-wrapper .form-content-wrapper .span-optional {
    font-size: 14px;
    letter-spacing: -0.2px;
    color: #9b9b9b;
    font-weight: normal;
    margin-left: 4px; }
  .add-card-wrapper .form-content-wrapper input[type="text"],
  .add-card-wrapper .form-content-wrapper input[type="password"],
  .add-card-wrapper .form-content-wrapper textarea {
    padding: 10px 15px;
    border-radius: 8px;
    border: solid 1px #dcdcdc;
    display: block;
    width: 100%;
    position: relative; }
  .add-card-wrapper .form-content-wrapper .card-number {
    position: relative; }
    .add-card-wrapper .form-content-wrapper .card-number input {
      padding: 10px 48px 10px 15px; }
    .add-card-wrapper .form-content-wrapper .card-number img {
      position: absolute;
      right: 16px;
      top: 13px;
      width: 22px; }
  .add-card-wrapper .form-content-wrapper .payment-div-50 {
    width: 145px;
    margin-right: 10px;
    display: inline-block;
    vertical-align: top; }
    .add-card-wrapper .form-content-wrapper .payment-div-50.no-margin {
      margin: 0; }
    .add-card-wrapper .form-content-wrapper .payment-div-50 svg {
      color: #9B9B9B;
      position: relative;
      top: -1px;
      margin-left: 5px;
      cursor: pointer; }
    .add-card-wrapper .form-content-wrapper .payment-div-50 input {
      width: 100%; }
  .add-card-wrapper .form-content-wrapper textarea {
    resize: none; }
  .add-card-wrapper .form-content-wrapper button.submit-card {
    width: unset;
    padding: 0 24px;
    height: 40px;
    width: 134px;
    position: relative; }
    .add-card-wrapper .form-content-wrapper button.submit-card .lds-ring {
      position: absolute;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%); }

.add-card-wrapper .dropdown button {
  padding: 11px 46px 11px 16px;
  border: solid 1px #dcdcdc !important;
  border-radius: 8px;
  background: transparent !important;
  box-shadow: none !important;
  outline: none;
  color: #191919 !important;
  margin: 0;
  text-align: left;
  position: relative; }
  .add-card-wrapper .dropdown button p {
    margin: 0;
    line-height: 1; }
  .add-card-wrapper .dropdown button svg {
    position: absolute;
    right: 13px;
    top: 50%;
    -webkit-transform: translate(0, -50%);
            transform: translate(0, -50%);
    font-size: 16px;
    -webkit-transition: ease-in-out .2s;
    transition: ease-in-out .2s; }
    .add-card-wrapper .dropdown button svg.active-dropdown-card {
      -webkit-transform: translate(0, -50%) rotate(180deg);
              transform: translate(0, -50%) rotate(180deg); }

.add-card-wrapper .dropdown .dropdown-menu {
  padding: 0;
  border-radius: 8px; }
  .add-card-wrapper .dropdown .dropdown-menu button {
    border: none !important;
    border-radius: 0; }
    .add-card-wrapper .dropdown .dropdown-menu button:nth-child(1) {
      border-top-right-radius: 8px;
      border-top-left-radius: 8px; }
    .add-card-wrapper .dropdown .dropdown-menu button:nth-last-child(1) {
      border-bottom-right-radius: 8px;
      border-bottom-left-radius: 8px; }
    .add-card-wrapper .dropdown .dropdown-menu button:hover {
      background: rgba(255, 126, 0, 0.05) !important; }

.verify-card-wrapper .verify-card-title {
  font-size: 20px;
  letter-spacing: -0.6px;
  color: #191919;
  padding-bottom: 12px;
  border-bottom: solid 1px #dcdcdc; }

.verify-card-wrapper .iframe-card-wrapper {
  width: 100%;
  margin-top: 12px;
  height: 500px;
  position: relative; }
  .verify-card-wrapper .iframe-card-wrapper .loading-add-card-wrapper {
    position: absolute;
    width: 100%;
    text-align: center;
    left: 0;
    top: 50%;
    -webkit-transform: translate(0, -50%);
            transform: translate(0, -50%);
    color: #ff7d00;
    font-size: 20px;
    letter-spacing: -0.6px; }
    .verify-card-wrapper .iframe-card-wrapper .loading-add-card-wrapper .LoadingRoll_1234fed {
      height: unset;
      margin-bottom: 24px; }
      .verify-card-wrapper .iframe-card-wrapper .loading-add-card-wrapper .LoadingRoll_1234fed .lds-ring {
        width: 64px;
        height: 64px;
        position: relative;
        top: unset;
        left: unset;
        -webkit-transform: unset;
                transform: unset; }
        .verify-card-wrapper .iframe-card-wrapper .loading-add-card-wrapper .LoadingRoll_1234fed .lds-ring div {
          width: 64px;
          height: 64px; }
  .verify-card-wrapper .iframe-card-wrapper iframe {
    width: 100%;
    height: 100%; }

.organization-details-container .organization-details-top-content {
  border-bottom: solid 1px #dcdcdc;
  padding-bottom: 12px; }

.organization-details-container .organization-details-bottom-content {
  color: #191919;
  position: relative;
  width: 100%;
  display: block; }
  .organization-details-container .organization-details-bottom-content .organization-details-title {
    margin: 24px 0 8px;
    font-size: 16px !important;
    letter-spacing: -0.5px !important; }
  .organization-details-container .organization-details-bottom-content .organization-details-value {
    margin: 0;
    font-size: 16px !important;
    letter-spacing: -0.3px !important;
    max-width: 100%;
    width: 400px; }
  .organization-details-container .organization-details-bottom-content .text_address {
    margin: 0;
    font-size: 20px;
    letter-spacing: -0.6px;
    max-width: 100%;
    width: 400px;
    border: 0;
    font-family: PTRootBold !important; }

.organization-details-container .organization-details-top-content {
  border-bottom: solid 1px #dcdcdc;
  padding-bottom: 12px; }
  .organization-details-container .organization-details-top-content button {
    width: 181px; }

.organization-details-container .organization-details-bottom-content {
  color: #191919;
  position: relative;
  width: 100%;
  display: block; }
  .organization-details-container .organization-details-bottom-content .organization-details-title {
    margin: 24px 0 8px;
    font-size: 14px;
    letter-spacing: -0.4px;
    position: relative;
    width: 316px; }
    .organization-details-container .organization-details-bottom-content .organization-details-title .input-counter {
      color: rgba(25, 25, 25, 0.4);
      position: absolute;
      top: 0;
      right: 0; }
  .organization-details-container .organization-details-bottom-content .organization-details-value {
    margin: 0;
    font-size: 20px;
    letter-spacing: -0.6px;
    max-width: 100%;
    width: 400px; }

.country-picker-wrapper {
  width: 300px;
  display: flex;
  border: 1px solid rgba(25, 25, 25, 0.1);
  border-radius: 8px; }
  .country-picker-wrapper.country-picker-wrapper-disabled {
    background: rgba(25, 25, 25, 0.1) !important; }
  .country-picker-wrapper .country-picker-label {
    padding: 12px 16px 12px 0;
    font-size: 16px;
    width: 200px; }
    .country-picker-wrapper .country-picker-label p {
      margin: 0; }
    .country-picker-wrapper .country-picker-label .placeholder {
      color: rgba(25, 25, 25, 0.4); }
  .country-picker-wrapper .react-select-country-picker__placeholder {
    width: 35px;
    height: 24px;
    border: solid 1px rgba(25, 25, 25, 0.1);
    border-radius: 2px;
    text-align: center; }
    .country-picker-wrapper .react-select-country-picker__placeholder svg {
      width: 18px;
      height: 18px;
      vertical-align: -2.5px; }
  .country-picker-wrapper .react-select-country-picker__input input {
    margin: 0;
    height: unset;
    padding: 0; }
  .country-picker-wrapper .icon-image-select {
    width: 32px;
    height: 24px;
    margin-right: 4px;
    vertical-align: top;
    position: relative;
    right: -2px; }
  .country-picker-wrapper input {
    position: relative;
    top: 4px; }

.labels-container:nth-child(1) {
  margin: 24px 0 0; }

.labels-container:nth-child(2) {
  margin: 12px 0 24px; }

.labels-container .label-switch-action-title {
  font-size: 16px;
  letter-spacing: -0.5px;
  margin: 0; }

.labels-container .label-switch-action-description {
  font-size: 14px;
  letter-spacing: -0.2px;
  margin: 6px 0 0; }

.labels-container .labels-switch-wrapper {
  width: 100%;
  position: relative;
  padding: 0 50px 0 0; }
  .labels-container .labels-switch-wrapper .custom-switch-wrapper {
    position: absolute;
    right: 4px;
    top: 10px;
    width: 38px;
    height: 22px; }
    .labels-container .labels-switch-wrapper .custom-switch-wrapper .custom-switch:before {
      width: 14px;
      height: 14px;
      left: 4px;
      top: 4px; }
    .labels-container .labels-switch-wrapper .custom-switch-wrapper input:checked + .custom-switch:before {
      width: 14px;
      height: 14px;
      -webkit-transform: translate(17px);
              transform: translate(17px); }

.labels-container .title {
  font-size: 20px;
  letter-spacing: -0.6px;
  padding: 0 0 14px;
  border-bottom: solid 1px #dcdcdc;
  margin: 0 0 24px;
  width: 100%; }

.labels-container .header-labels {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: solid 1px #dcdcdc;
  margin-bottom: 12px;
  padding-bottom: 12px;
  color: #191919; }
  .labels-container .header-labels .title-label-list {
    margin: 0;
    padding: 0;
    border: none; }

.labels-container .labels-top-action-wrapper {
  position: relative;
  width: 100%;
  margin: 0 0 24px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start; }
  .labels-container .labels-top-action-wrapper .create-label-button {
    font-size: 16px;
    letter-spacing: -0.5px;
    padding: 0 32px;
    height: 40px;
    position: absolute;
    right: 0;
    top: 50%;
    -webkit-transform: translate(0, -50%);
            transform: translate(0, -50%); }
    .labels-container .labels-top-action-wrapper .create-label-button svg {
      font-size: 20px;
      position: relative;
      top: -2px;
      margin-right: 4px; }

.labels-outer-wrapper {
  width: 100%;
  position: relative; }

.table-labels .col_label {
  width: 200px; }
  .table-labels .col_label p {
    white-space: nowrap;
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block; }

.table-labels .col_description {
  width: calc(100% - 400px); }

.table-labels .col_action {
  width: 200px; }

.table-labels thead tr {
  background: #ffffff;
  color: #191919; }

.table-labels thead td {
  font-size: 14px;
  letter-spacing: -0.4px; }

.table-labels tbody td {
  position: relative;
  font-size: 14px;
  letter-spacing: -0.2px;
  padding: 8px 10px; }
  .table-labels tbody td .col-action-button-wrapper {
    display: flex !important;
    justify-content: flex-end;
    position: relative;
    top: 0;
    right: unset;
    -webkit-transform: unset;
            transform: unset; }
    .table-labels tbody td .col-action-button-wrapper .view-agent {
      color: #ff7d00;
      letter-spacing: -0.5px;
      position: relative;
      left: -6px; }
    .table-labels tbody td .col-action-button-wrapper .view-agent:hover {
      color: var(--main-darker-orange); }
    .table-labels tbody td .col-action-button-wrapper svg {
      margin-right: 3px;
      position: relative;
      top: -2px; }
    .table-labels tbody td .col-action-button-wrapper .single-remove-button {
      margin-left: 22px; }
      .table-labels tbody td .col-action-button-wrapper .single-remove-button svg {
        color: #FF3F57;
        margin-right: 5px; }
  .table-labels tbody td.col_action {
    width: 200px !important; }
  .table-labels tbody td.col_label, .table-labels tbody td.col_description, .table-labels tbody td.col_action {
    padding: 8px 10px; }
  .table-labels tbody td .label-badge-wrapper {
    font-size: 12px;
    letter-spacing: -0.4px;
    color: rgba(25, 25, 25, 0.6);
    background: rgba(25, 25, 25, 0.05);
    border-radius: 4px;
    padding: 2px 8px;
    color: #ffffff;
    margin: 0; }

.table-labels button svg {
  position: relative !important;
  right: unset !important;
  top: -2px !important;
  margin-right: 5px; }

.table-labels .col-action-button-wrapper {
  position: absolute;
  right: 7px;
  top: 50%;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%); }

.table-labels .single-remove-button {
  margin-left: 16px;
  color: #ff3f57; }
  .table-labels .single-remove-button:hover {
    color: #e83b51; }

.table-labels .shortcut-badge {
  font-size: 12px;
  color: rgba(25, 25, 25, 0.6);
  letter-spacing: -0.4px;
  background: #f3f3f3;
  line-height: 1;
  border-radius: 4px;
  padding: 1px 8px 3px; }

.table-labels td {
  max-width: unset !important; }

.search-box-wrapper {
  width: 307px;
  display: inline-block;
  position: relative; }
  .search-box-wrapper .search-box-input-inside {
    padding: 11px 72px 11px 16px !important; }
  .search-box-wrapper .delete-search {
    position: absolute;
    cursor: pointer;
    right: 45px;
    top: 50%;
    -webkit-transform: translate(0, -50%);
            transform: translate(0, -50%); }
  .search-box-wrapper .search-icon {
    position: absolute;
    right: 0;
    top: 50%;
    -webkit-transform: translate(0, -50%);
            transform: translate(0, -50%);
    font-size: 20px;
    color: #191919;
    cursor: pointer;
    height: 46px;
    width: 48px;
    border-radius: 8px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    background: transparent !important;
    border: none !important;
    -webkit-transition: none;
    transition: none; }
    .search-box-wrapper .search-icon svg {
      color: #191919;
      position: absolute;
      left: 50%;
      top: 50%;
      -webkit-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%); }
    .search-box-wrapper .search-icon:hover {
      background: #e67300; }
    .search-box-wrapper .search-icon[disabled] {
      cursor: not-allowed; }
      .search-box-wrapper .search-icon[disabled] svg {
        color: rgba(25, 25, 25, 0.4) !important; }
  .search-box-wrapper input {
    padding: 11px 72px 11px 16px;
    border: solid 1px rgba(25, 25, 25, 0.1);
    border-radius: 8px;
    width: 100%;
    font-size: 16px;
    letter-spacing: -0.3px; }
    .search-box-wrapper input:focus {
      border: solid 1px var(--main-dark-orange) !important; }
      .search-box-wrapper input:focus ~ .search-icon svg {
        color: var(--main-dark-orange) !important; }
  .search-box-wrapper .clear-search-inside {
    font-size: 16px;
    letter-spacing: -0.5px;
    color: #ff7d00;
    cursor: pointer;
    white-space: nowrap;
    position: absolute;
    top: 50%;
    -webkit-transform: translate(0, -50%);
            transform: translate(0, -50%);
    margin: 0;
    right: 50px; }
    .search-box-wrapper .clear-search-inside svg {
      position: relative;
      top: -1px;
      margin-right: 4px; }
    .search-box-wrapper .clear-search-inside:hover {
      color: #e67300; }
  .search-box-wrapper .clear-search {
    font-size: 16px;
    letter-spacing: -0.5px;
    color: #ff7d00;
    cursor: pointer;
    white-space: nowrap;
    position: absolute;
    top: 50%;
    -webkit-transform: translate(0, -50%);
            transform: translate(0, -50%);
    margin: 0;
    right: -115px;
    display: none; }
    .search-box-wrapper .clear-search svg {
      position: relative;
      top: -1px;
      margin-right: 4px; }
    .search-box-wrapper .clear-search:hover {
      color: #e67300; }

.form-add-label {
  position: relative; }
  .form-add-label .form-title-wrapper {
    padding-bottom: 16px;
    border-bottom: solid 1px rgba(25, 25, 25, 0.1);
    color: #191919;
    font-size: 20px;
    letter-spacing: -0.6px;
    display: flex;
    justify-content: space-between;
    align-items: center; }
    .form-add-label .form-title-wrapper button {
      font-size: 16px;
      width: 181px; }
  .form-add-label .form-content-wrapper label {
    display: block;
    color: #191919;
    font-size: 16px;
    letter-spacing: -0.5px;
    margin-top: 22px;
    width: 300px; }
    .form-add-label .form-content-wrapper label .input-label-optional {
      color: rgba(25, 25, 25, 0.4); }
  .form-add-label .form-content-wrapper input[type="text"] {
    background: white;
    border-radius: 8px;
    border: 1px solid rgba(25, 25, 25, 0.1);
    height: 48px;
    width: 300px;
    color: #191919;
    font-size: 16px;
    letter-spacing: -0.3px;
    width: 300px;
    padding: 12px 16px;
    margin-bottom: 8px; }
  .form-add-label .form-content-wrapper textarea {
    background: white;
    border-radius: 8px;
    border: 1px solid rgba(25, 25, 25, 0.1);
    width: 300px;
    color: #191919;
    font-size: 16px;
    letter-spacing: -0.3px;
    padding: 16px;
    resize: none;
    margin-bottom: 8px; }
  .form-add-label .form-content-wrapper label {
    display: block !important; }
  .form-add-label .form-content-wrapper .role-radio-label {
    position: relative; }
  .form-add-label .form-content-wrapper .role-radio-label b {
    display: block;
    color: #191919;
    font-size: 14px;
    letter-spacing: -0.4px; }
  .form-add-label .form-content-wrapper .role-radio-label label {
    color: rgba(25, 25, 25, 0.8);
    font-size: 14px;
    letter-spacing: -0.2px; }
  .form-add-label .form-content-wrapper .custom-radio label {
    margin-top: 18px; }
  .form-add-label .form-content-wrapper button {
    width: 300px;
    padding: 10px 0 11px;
    margin-top: 32px; }
  .form-add-label .form-content-wrapper .orange-button .lds-ring {
    top: 3px;
    right: unset; }
  .form-add-label .form-content-wrapper .orange-button .lds-ring div {
    border-color: #ffffff transparent transparent transparent;
    margin: 0; }
  .form-add-label .submit-add-label {
    position: absolute;
    top: -7px;
    right: 32px;
    width: unset !important;
    padding: 7px 24px !important;
    margin-top: 32px !important; }
    .form-add-label .submit-add-label .lds-ring {
      top: 3px;
      right: unset; }
      .form-add-label .submit-add-label .lds-ring div {
        margin: 0;
        border-color: #ffffff transparent transparent transparent; }
  .form-add-label .input-info-wrapper {
    color: rgba(25, 25, 25, 0.8);
    font-size: 14px;
    letter-spacing: -0.2px;
    line-height: 21px;
    width: 326px; }
  .form-add-label .form-content-wrapper .input-customize {
    width: 100%;
    padding-right: 10px; }
    .form-add-label .form-content-wrapper .input-customize .color-select-wrapper {
      margin-top: 12px;
      padding: 0;
      white-space: nowrap;
      border-radius: 8px;
      display: block;
      width: 100%; }
      .form-add-label .form-content-wrapper .input-customize .color-select-wrapper .color-item {
        width: 32px;
        height: 32px;
        border-radius: 50%;
        cursor: pointer;
        margin: 0 16px 4px 0;
        display: inline-block;
        vertical-align: top;
        position: relative; }
        .form-add-label .form-content-wrapper .input-customize .color-select-wrapper .color-item svg {
          position: relative;
          left: 50%;
          top: 3px;
          -webkit-transform: translateX(-50%);
                  transform: translateX(-50%);
          color: #ffffff;
          font-size: 20px; }
      .form-add-label .form-content-wrapper .input-customize .color-select-wrapper.grey-box {
        background: none !important; }
        .form-add-label .form-content-wrapper .input-customize .color-select-wrapper.grey-box .color-item::before {
          content: "";
          background: rgba(255, 255, 255, 0.4);
          width: 32px;
          height: 32px;
          border-radius: 50%;
          position: absolute;
          top: 0;
          left: 0; }
    .form-add-label .form-content-wrapper .input-customize .color-input-wrapper {
      position: relative;
      width: 166px;
      height: 48px;
      border-radius: 8px;
      border: solid 1px #dcdcdc;
      cursor: pointer;
      line-height: 47px;
      font-size: 16px;
      letter-spacing: -0.3px;
      color: #191919;
      padding: 0 0 0 16px;
      margin-top: 12px; }
      .form-add-label .form-content-wrapper .input-customize .color-input-wrapper .color-placeholder {
        color: rgba(25, 25, 25, 0.6); }
      .form-add-label .form-content-wrapper .input-customize .color-input-wrapper img {
        position: absolute;
        position: absolute;
        right: 16px;
        top: 50%;
        -webkit-transform: translate(0, -50%);
                transform: translate(0, -50%); }
      .form-add-label .form-content-wrapper .input-customize .color-input-wrapper .color-tag {
        display: inline-block !important;
        margin-right: 1px;
        margin-top: 0; }
      .form-add-label .form-content-wrapper .input-customize .color-input-wrapper .color-value {
        width: 16px;
        height: 16px;
        display: inline-block;
        background: #dcdcdc;
        border-radius: 50%;
        position: absolute;
        right: 16px;
        top: 50%;
        -webkit-transform: translate(0, -50%);
                transform: translate(0, -50%); }
      .form-add-label .form-content-wrapper .input-customize .color-input-wrapper .color-input {
        border: none;
        padding: 0;
        background-color: transparent;
        height: 20px;
        margin-top: 0 !important;
        height: 40px !important;
        display: inline-block;
        width: 125px !important; }
        .form-add-label .form-content-wrapper .input-customize .color-input-wrapper .color-input:focus {
          border: none !important; }
      .form-add-label .form-content-wrapper .input-customize .color-input-wrapper .color-picker-container {
        background: #ffffff;
        position: absolute;
        bottom: 0;
        right: -265px;
        border-radius: 4px;
        box-shadow: 0 2px 20px 0 rgba(25, 25, 25, 0.18) !important;
        padding: 14px;
        z-index: 9999; }
        .form-add-label .form-content-wrapper .input-customize .color-input-wrapper .color-picker-container .close-color-picker {
          position: absolute;
          top: 6px;
          font-size: 14px;
          right: 6px;
          color: rgba(25, 25, 25, 0.6); }
          .form-add-label .form-content-wrapper .input-customize .color-input-wrapper .color-picker-container .close-color-picker:hover {
            color: rgba(25, 25, 25, 0.8); }
        .form-add-label .form-content-wrapper .input-customize .color-input-wrapper .color-picker-container::before {
          position: absolute;
          background: #ffffff;
          width: 16px;
          height: 16px;
          content: '';
          left: -8px;
          -webkit-transform: rotate(45deg);
                  transform: rotate(45deg);
          bottom: 17px; }
        .form-add-label .form-content-wrapper .input-customize .color-input-wrapper .color-picker-container .sketch-picker {
          border-radius: 4px;
          box-shadow: none !important; }
          .form-add-label .form-content-wrapper .input-customize .color-input-wrapper .color-picker-container .sketch-picker .flexbox-fix:nth-child(2) {
            height: 28px; }
            .form-add-label .form-content-wrapper .input-customize .color-input-wrapper .color-picker-container .sketch-picker .flexbox-fix:nth-child(2) div:nth-child(1) div:nth-child(1) {
              height: 23px !important; }
              .form-add-label .form-content-wrapper .input-customize .color-input-wrapper .color-picker-container .sketch-picker .flexbox-fix:nth-child(2) div:nth-child(1) div:nth-child(1) div div.hue-horizontal div div {
                display: block !important;
                margin-top: 0 !important; }
            .form-add-label .form-content-wrapper .input-customize .color-input-wrapper .color-picker-container .sketch-picker .flexbox-fix:nth-child(2) div:nth-child(1) div:nth-child(2) div {
              display: none; }
          .form-add-label .form-content-wrapper .input-customize .color-input-wrapper .color-picker-container .sketch-picker .flexbox-fix:nth-child(3) {
            display: none !important; }
          .form-add-label .form-content-wrapper .input-customize .color-input-wrapper .color-picker-container .sketch-picker .flexbox-fix:nth-child(4) div div {
            border-radius: 50% !important; }
    .form-add-label .form-content-wrapper .input-customize .input-color p {
      font-size: 14px;
      margin: 0; }
    .form-add-label .form-content-wrapper .input-customize .input-color input[type='text'] {
      margin-top: 12px;
      width: 166px;
      height: 48px; }

.form-edit-label {
  position: relative; }
  .form-edit-label .form-title-wrapper {
    padding-bottom: 16px;
    border-bottom: solid 1px rgba(25, 25, 25, 0.1);
    color: #191919;
    font-size: 20px;
    letter-spacing: -0.6px;
    display: flex;
    justify-content: space-between;
    align-items: center; }
    .form-edit-label .form-title-wrapper button {
      font-size: 16px;
      width: 180px; }
      .form-edit-label .form-title-wrapper button.red-button {
        position: absolute;
        right: 216px; }
  .form-edit-label .form-content-wrapper label {
    display: block;
    color: #191919;
    font-size: 16px;
    letter-spacing: -0.5px;
    margin-top: 22px;
    width: 300px; }
  .form-edit-label .form-content-wrapper input[type="text"] {
    background: white;
    border-radius: 8px;
    border: 1px solid rgba(25, 25, 25, 0.1);
    height: 48px;
    width: 300px;
    color: #191919;
    font-size: 16px;
    letter-spacing: -0.3px;
    padding: 12px 16px;
    margin-bottom: 8px; }
  .form-edit-label .form-content-wrapper textarea {
    background: white;
    border-radius: 8px;
    border: 1px solid rgba(25, 25, 25, 0.1);
    width: 300px;
    color: #191919;
    font-size: 16px;
    letter-spacing: -0.3px;
    padding: 16px;
    resize: none;
    margin-bottom: 8px; }
  .form-edit-label .form-content-wrapper label {
    display: block !important; }
  .form-edit-label .form-content-wrapper .role-radio-label {
    position: relative; }
  .form-edit-label .form-content-wrapper .role-radio-label b {
    display: block;
    color: #191919;
    font-size: 14px;
    letter-spacing: -0.4px; }
  .form-edit-label .form-content-wrapper .role-radio-label label {
    color: rgba(25, 25, 25, 0.8);
    font-size: 14px;
    letter-spacing: -0.2px; }
  .form-edit-label .form-content-wrapper .custom-radio label {
    margin-top: 18px; }
  .form-edit-label .form-content-wrapper button {
    width: 300px;
    padding: 10px 0 11px;
    margin-top: 32px; }
  .form-edit-label .form-content-wrapper .orange-button .lds-ring {
    top: 3px;
    right: unset; }
  .form-edit-label .form-content-wrapper .orange-button .lds-ring div {
    border-color: #ffffff transparent transparent transparent;
    margin: 0; }
  .form-edit-label .submit-add-label {
    position: absolute;
    top: -7px;
    right: 32px;
    width: unset !important;
    padding: 7px 24px !important;
    margin-top: 32px !important; }
    .form-edit-label .submit-add-label .lds-ring {
      top: 3px;
      right: unset; }
      .form-edit-label .submit-add-label .lds-ring div {
        margin: 0;
        border-color: #ffffff transparent transparent transparent; }
  .form-edit-label .input-info-wrapper {
    color: rgba(25, 25, 25, 0.8);
    font-size: 14px;
    letter-spacing: -0.2px;
    line-height: 21px;
    width: 326px; }
  .form-edit-label .form-content-wrapper .input-customize {
    width: 100%;
    padding-right: 10px; }
    .form-edit-label .form-content-wrapper .input-customize .color-select-wrapper {
      margin-top: 12px;
      border-radius: 8px;
      display: inline-block;
      vertical-align: top; }
      .form-edit-label .form-content-wrapper .input-customize .color-select-wrapper .color-item {
        width: 32px;
        height: 32px;
        border-radius: 50%;
        cursor: pointer;
        margin: 0 16px 4px 0;
        display: inline-block;
        vertical-align: top;
        position: relative; }
        .form-edit-label .form-content-wrapper .input-customize .color-select-wrapper .color-item svg {
          position: relative;
          left: 50%;
          top: 3px;
          -webkit-transform: translateX(-50%);
                  transform: translateX(-50%);
          color: #ffffff;
          font-size: 20px; }
      .form-edit-label .form-content-wrapper .input-customize .color-select-wrapper.grey-box {
        background: none !important; }
        .form-edit-label .form-content-wrapper .input-customize .color-select-wrapper.grey-box .color-item::before {
          content: "";
          background: rgba(255, 255, 255, 0.4);
          width: 32px;
          height: 32px;
          border-radius: 50%;
          position: absolute;
          top: 0;
          left: 0; }
    .form-edit-label .form-content-wrapper .input-customize .color-input-wrapper {
      position: relative;
      width: 166px;
      height: 48px;
      border-radius: 8px;
      border: solid 1px #dcdcdc;
      cursor: pointer;
      line-height: 47px;
      font-size: 16px;
      letter-spacing: -0.3px;
      color: #191919;
      padding: 0 0 0 16px;
      margin-top: 12px; }
      .form-edit-label .form-content-wrapper .input-customize .color-input-wrapper .color-placeholder {
        color: rgba(25, 25, 25, 0.6); }
      .form-edit-label .form-content-wrapper .input-customize .color-input-wrapper img {
        position: absolute;
        position: absolute;
        right: 16px;
        top: 50%;
        -webkit-transform: translate(0, -50%);
                transform: translate(0, -50%); }
      .form-edit-label .form-content-wrapper .input-customize .color-input-wrapper .color-tag {
        display: inline-block !important;
        margin-right: 1px;
        margin-top: 0; }
      .form-edit-label .form-content-wrapper .input-customize .color-input-wrapper .color-value {
        width: 16px;
        height: 16px;
        display: inline-block;
        background: #dcdcdc;
        border-radius: 50%;
        position: absolute;
        right: 16px;
        top: 50%;
        -webkit-transform: translate(0, -50%);
                transform: translate(0, -50%); }
      .form-edit-label .form-content-wrapper .input-customize .color-input-wrapper .color-input {
        border: none;
        padding: 0;
        background-color: transparent;
        height: 20px;
        margin-top: 0 !important;
        height: 40px !important;
        display: inline-block;
        width: 125px !important; }
        .form-edit-label .form-content-wrapper .input-customize .color-input-wrapper .color-input:focus {
          border: none !important; }
      .form-edit-label .form-content-wrapper .input-customize .color-input-wrapper .color-picker-container {
        background: #ffffff;
        position: absolute;
        bottom: 0;
        right: -265px;
        border-radius: 4px;
        box-shadow: 0 2px 20px 0 rgba(25, 25, 25, 0.18) !important;
        padding: 14px;
        z-index: 9999; }
        .form-edit-label .form-content-wrapper .input-customize .color-input-wrapper .color-picker-container .close-color-picker {
          position: absolute;
          top: 6px;
          font-size: 14px;
          right: 6px;
          color: rgba(25, 25, 25, 0.6); }
          .form-edit-label .form-content-wrapper .input-customize .color-input-wrapper .color-picker-container .close-color-picker:hover {
            color: rgba(25, 25, 25, 0.8); }
        .form-edit-label .form-content-wrapper .input-customize .color-input-wrapper .color-picker-container::before {
          position: absolute;
          background: #ffffff;
          width: 16px;
          height: 16px;
          content: '';
          left: -8px;
          -webkit-transform: rotate(45deg);
                  transform: rotate(45deg);
          bottom: 17px; }
        .form-edit-label .form-content-wrapper .input-customize .color-input-wrapper .color-picker-container .sketch-picker {
          border-radius: 4px;
          box-shadow: none !important; }
          .form-edit-label .form-content-wrapper .input-customize .color-input-wrapper .color-picker-container .sketch-picker .flexbox-fix:nth-child(2) {
            height: 28px; }
            .form-edit-label .form-content-wrapper .input-customize .color-input-wrapper .color-picker-container .sketch-picker .flexbox-fix:nth-child(2) div:nth-child(1) div:nth-child(1) {
              height: 23px !important; }
              .form-edit-label .form-content-wrapper .input-customize .color-input-wrapper .color-picker-container .sketch-picker .flexbox-fix:nth-child(2) div:nth-child(1) div:nth-child(1) div div.hue-horizontal div div {
                display: block !important;
                margin-top: 0 !important; }
            .form-edit-label .form-content-wrapper .input-customize .color-input-wrapper .color-picker-container .sketch-picker .flexbox-fix:nth-child(2) div:nth-child(1) div:nth-child(2) div {
              display: none; }
          .form-edit-label .form-content-wrapper .input-customize .color-input-wrapper .color-picker-container .sketch-picker .flexbox-fix:nth-child(3) {
            display: none !important; }
          .form-edit-label .form-content-wrapper .input-customize .color-input-wrapper .color-picker-container .sketch-picker .flexbox-fix:nth-child(4) div div {
            border-radius: 50% !important; }
    .form-edit-label .form-content-wrapper .input-customize .input-color p {
      font-size: 14px;
      margin: 0; }
    .form-edit-label .form-content-wrapper .input-customize .input-color input[type='text'] {
      margin-top: 12px;
      width: 166px;
      height: 48px; }

.integration-all-channel .sectionWrap {
  position: relative; }

.integration-all-channel .your-channel-limit {
  width: 200px;
  position: absolute;
  bottom: 24px;
  left: 48px; }
  .integration-all-channel .your-channel-limit p {
    color: #191919;
    font-size: 12px;
    letter-spacing: -0.4px;
    margin: 0 0 0; }
  .integration-all-channel .your-channel-limit .channel-limit {
    color: #ff7d00;
    font-size: 16px;
    letter-spacing: -0.5px; }

.integration-livechat-list {
  text-align: left; }
  .integration-livechat-list .channels-list-loading-wrapper .lds-ring {
    width: 50px;
    height: 50px;
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    right: unset; }
    .integration-livechat-list .channels-list-loading-wrapper .lds-ring div {
      border: 5px solid #fff;
      border-color: #ff7d00 transparent transparent transparent;
      width: 50px;
      height: 50px;
      margin: 0; }
  .integration-livechat-list .channels-list-header {
    position: relative;
    padding-left: 42px;
    padding-bottom: 12px;
    border-bottom: solid 1px rgba(25, 25, 25, 0.1); }
    .integration-livechat-list .channels-list-header .channels-list-header-icon {
      position: absolute;
      top: 0;
      left: 0;
      width: 32px; }
    .integration-livechat-list .channels-list-header .channels-list-header-text-wrapper {
      text-align: left; }
      .integration-livechat-list .channels-list-header .channels-list-header-text-wrapper b {
        font-size: 20px;
        letter-spacing: -0.6px; }
      .integration-livechat-list .channels-list-header .channels-list-header-text-wrapper p {
        margin: 0;
        font-size: 14px;
        letter-spacing: -0.2px;
        color: rgba(25, 25, 25, 0.8); }
      .integration-livechat-list .channels-list-header .channels-list-header-text-wrapper a {
        color: #ff7d00; }
    .integration-livechat-list .channels-list-header .channels-list-header-button {
      position: absolute;
      right: 0;
      top: 0;
      font-size: 16px;
      letter-spacing: -0.5px; }
  .integration-livechat-list .channels-list-no-data {
    width: 100%;
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
    .integration-livechat-list .channels-list-no-data b {
      font-size: 20px;
      letter-spacing: -0.6px;
      color: #191919; }
    .integration-livechat-list .channels-list-no-data p {
      color: rgba(25, 25, 25, 0.6);
      letter-spacing: -0.6px;
      font-size: 16px; }
  .integration-livechat-list .channels-list-outer-wrapper {
    position: relative;
    width: 100%;
    padding-top: 24px;
    text-align: left; }
    .integration-livechat-list .channels-list-outer-wrapper .channels-list-inner-wrapper {
      width: calc((100% / 3) - 8px);
      border-radius: 8px;
      margin: 0 12px 12px 0;
      box-shadow: 0 2px 20px 0 rgba(25, 25, 25, 0.05);
      background: #ffffff;
      display: inline-block;
      vertical-align: top;
      padding: 12px 36px 12px 16px;
      cursor: pointer;
      position: relative; }
      .integration-livechat-list .channels-list-outer-wrapper .channels-list-inner-wrapper .medium-counter {
        letter-spacing: -0.4px;
        min-width: 20px;
        height: 20px;
        line-height: 20px;
        padding: 0 4px;
        background: #ff7d00;
        color: #ffffff;
        border-radius: 50%;
        position: absolute;
        right: 15px;
        top: 50%;
        -webkit-transform: translate(0, -50%);
                transform: translate(0, -50%);
        font-size: 12px;
        text-align: center; }
      .integration-livechat-list .channels-list-outer-wrapper .channels-list-inner-wrapper .platform-wrapper {
        display: flex;
        align-items: center; }
        .integration-livechat-list .channels-list-outer-wrapper .channels-list-inner-wrapper .platform-wrapper .platform-name {
          font-size: 16px;
          letter-spacing: -0.5px;
          color: rgba(25, 25, 25, 0.6);
          margin: 0;
          white-space: nowrap;
          padding-left: 2px;
          max-width: calc(100%);
          overflow: hidden;
          text-overflow: ellipsis; }
        .integration-livechat-list .channels-list-outer-wrapper .channels-list-inner-wrapper .platform-wrapper img {
          margin-right: 5px;
          width: 24px; }
      .integration-livechat-list .channels-list-outer-wrapper .channels-list-inner-wrapper span {
        display: block;
        font-size: 12px;
        letter-spacing: -0.2px;
        color: #191919;
        white-space: nowrap;
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis; }
      .integration-livechat-list .channels-list-outer-wrapper .channels-list-inner-wrapper svg {
        position: absolute;
        right: 12px;
        top: 12px;
        font-size: 22px;
        color: rgba(25, 25, 25, 0.6); }
      .integration-livechat-list .channels-list-outer-wrapper .channels-list-inner-wrapper:hover {
        box-shadow: 0 2px 20px 0 rgba(25, 25, 25, 0.18); }
        .integration-livechat-list .channels-list-outer-wrapper .channels-list-inner-wrapper:hover .platform-name {
          color: #191919; }
        .integration-livechat-list .channels-list-outer-wrapper .channels-list-inner-wrapper:hover svg {
          color: #ff7d00; }
  .integration-livechat-list .link-livechat-account-list:nth-child(3n) .channels-list-inner-wrapper {
    margin: 0 0 12px 0; }

.modal-warning .modal-content {
  border-radius: 8px;
  width: 420px; }

.modal-warning .modal-body {
  padding: 32px;
  color: #191919;
  font-size: 14px;
  letter-spacing: -0.2px;
  text-align: center; }
  .modal-warning .modal-body .title {
    font-size: 20px; }
  .modal-warning .modal-body p {
    margin: 0 0 20px 0;
    color: rgba(25, 25, 25, 0.6); }
  .modal-warning .modal-body svg {
    color: #FFB438;
    font-size: 64px; }
  .modal-warning .modal-body button {
    width: 100%;
    margin: 0;
    height: 40px; }


.integration-instagram-list {
  text-align: left; }
  .integration-instagram-list .integration-list-loading-wrapper .lds-ring {
    width: 50px;
    height: 50px;
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    right: unset; }
    .integration-instagram-list .integration-list-loading-wrapper .lds-ring div {
      border: 5px solid #fff;
      border-color: #ff7d00 transparent transparent transparent;
      width: 50px;
      height: 50px;
      margin: 0; }
  .integration-instagram-list .integration-list-no-data {
    width: 100%;
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
    .integration-instagram-list .integration-list-no-data b {
      font-size: 20px;
      letter-spacing: -0.6px;
      color: #191919; }
    .integration-instagram-list .integration-list-no-data p {
      color: rgba(25, 25, 25, 0.6);
      letter-spacing: -0.6px;
      font-size: 16px; }
  .integration-instagram-list .integration-list-header {
    position: relative;
    padding-left: 42px;
    padding-bottom: 10px;
    border-bottom: solid 1px rgba(25, 25, 25, 0.1); }
    .integration-instagram-list .integration-list-header .integration-list-header-icon {
      position: absolute;
      top: 0;
      left: 0;
      width: 32px; }
    .integration-instagram-list .integration-list-header .integration-list-header-text-wrapper b {
      font-size: 20px;
      letter-spacing: -0.6px; }
    .integration-instagram-list .integration-list-header .integration-list-header-text-wrapper p {
      margin: 0;
      font-size: 14px;
      letter-spacing: -0.2px; }
    .integration-instagram-list .integration-list-header .integration-list-header-text-wrapper a {
      color: #ff7d00; }
    .integration-instagram-list .integration-list-header .integration-list-header-button {
      position: absolute;
      right: 0;
      top: 0;
      font-size: 16px;
      letter-spacing: -0.5px;
      padding: 7px 32px; }
      .integration-instagram-list .integration-list-header .integration-list-header-button img {
        margin-right: 4px;
        position: relative;
        top: -2px; }
  .integration-instagram-list .integration-list-outer-wrapper {
    position: relative;
    width: 100%;
    padding-top: 24px; }
    .integration-instagram-list .integration-list-outer-wrapper .integration-list-inner-wrapper {
      width: calc((100% / 3) - 8px);
      border-radius: 8px;
      margin: 0 12px 12px 0;
      box-shadow: 0 2px 20px 0 rgba(25, 25, 25, 0.05);
      background: #ffffff;
      display: inline-block;
      vertical-align: top;
      padding: 24px 30px 24px 24px;
      cursor: pointer;
      position: relative; }
      .integration-instagram-list .integration-list-outer-wrapper .integration-list-inner-wrapper p:nth-child(2) {
        font-size: 16px;
        letter-spacing: -0.5px;
        color: rgba(25, 25, 25, 0.6);
        margin: 0;
        white-space: nowrap;
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis; }
      .integration-instagram-list .integration-list-outer-wrapper .integration-list-inner-wrapper span {
        display: block;
        font-size: 12px;
        letter-spacing: -0.2px;
        color: rgba(25, 25, 25, 0.6); }
      .integration-instagram-list .integration-list-outer-wrapper .integration-list-inner-wrapper p {
        margin: 10px 0 0;
        color: #ff7d00;
        font-size: 12px;
        letter-spacing: -0.2px; }
        .integration-instagram-list .integration-list-outer-wrapper .integration-list-inner-wrapper p.font-green {
          color: #19c700; }
        .integration-instagram-list .integration-list-outer-wrapper .integration-list-inner-wrapper p.font-grey {
          color: rgba(25, 25, 25, 0.4); }
      .integration-instagram-list .integration-list-outer-wrapper .integration-list-inner-wrapper svg {
        position: absolute;
        right: 12px;
        top: 12px;
        font-size: 22px;
        color: rgba(25, 25, 25, 0.6); }
      .integration-instagram-list .integration-list-outer-wrapper .integration-list-inner-wrapper:hover {
        box-shadow: 0 2px 20px 0 rgba(25, 25, 25, 0.18); }
        .integration-instagram-list .integration-list-outer-wrapper .integration-list-inner-wrapper:hover p:nth-child(2) {
          color: #191919; }
        .integration-instagram-list .integration-list-outer-wrapper .integration-list-inner-wrapper:hover svg {
          color: #ff7d00; }
  .integration-instagram-list .link-instagram-account-list:nth-child(3n) .integration-list-inner-wrapper {
    margin: 0 0 12px 0; }

.integration-instagram-add-account {
  padding: 32px 30px;
  text-align: left; }
  .integration-instagram-add-account .comp-back-button {
    margin: 0 0 16px 0; }
  .integration-instagram-add-account .integration-instagram-add-account-box {
    background: #ffffff;
    border-radius: 8px;
    padding: 32px;
    border: solid 1px rgba(25, 25, 25, 0.1); }
    .integration-instagram-add-account .integration-instagram-add-account-box .top-component {
      padding-top: 24px; }
      .integration-instagram-add-account .integration-instagram-add-account-box .top-component .alert-component {
        border: 1px solid #044b84;
        background: #DCEBFF;
        color: #044b84; }
        .integration-instagram-add-account .integration-instagram-add-account-box .top-component .alert-component a {
          color: #044b84; }
          .integration-instagram-add-account .integration-instagram-add-account-box .top-component .alert-component a:hover {
            text-decoration: underline !important; }
    .integration-instagram-add-account .integration-instagram-add-account-box .facebook-component {
      padding-top: 24px; }
      .integration-instagram-add-account .integration-instagram-add-account-box .facebook-component p {
        margin: 0; }
      .integration-instagram-add-account .integration-instagram-add-account-box .facebook-component ul {
        padding-left: 18px; }
      .integration-instagram-add-account .integration-instagram-add-account-box .facebook-component a {
        color: #ff7d00; }
        .integration-instagram-add-account .integration-instagram-add-account-box .facebook-component a:hover {
          text-decoration: underline !important; }
    .integration-instagram-add-account .integration-instagram-add-account-box .integration-instagram-add-account-box-top {
      padding-bottom: 15px;
      border-bottom: solid 1px #dcdcdc;
      position: relative;
      font-size: 20px;
      letter-spacing: -0.6px; }
      .integration-instagram-add-account .integration-instagram-add-account-box .integration-instagram-add-account-box-top button {
        position: absolute;
        top: -6px;
        right: 0;
        font-size: 16px;
        letter-spacing: -0.6px; }
    .integration-instagram-add-account .integration-instagram-add-account-box .integration-instagram-add-account-box-form {
      width: 320px; }
      .integration-instagram-add-account .integration-instagram-add-account-box .integration-instagram-add-account-box-form label {
        padding: 24px 0 0;
        font-size: 14px;
        letter-spacing: -0.4px;
        display: block; }
      .integration-instagram-add-account .integration-instagram-add-account-box .integration-instagram-add-account-box-form input[type="text"] {
        padding: 12px 16px;
        border-radius: 6px;
        border: solid 1px #dcdcdc;
        width: 100%;
        font-size: 16px;
        letter-spacing: -0.3px;
        display: block;
        margin-bottom: 8px; }
      .integration-instagram-add-account .integration-instagram-add-account-box .integration-instagram-add-account-box-form .integration-info-form {
        font-size: 14px;
        color: rgba(25, 25, 25, 0.8);
        letter-spacing: -0.2px; }
      .integration-instagram-add-account .integration-instagram-add-account-box .integration-instagram-add-account-box-form .custom-select-instagram-topic .custom-select-option-value-wrapper {
        padding: 12px 16px !important;
        margin-bottom: 8px; }

.integration-instagram-edit-topic-account {
  padding: 32px 30px;
  text-align: left; }
  .integration-instagram-edit-topic-account .comp-back-button {
    margin: 0 0 16px 0;
    font-family: PTRootBold; }
  .integration-instagram-edit-topic-account .integration-instagram-edit-topic-account-box {
    background: #ffffff;
    border-radius: 8px;
    padding: 32px;
    border: solid 1px rgba(25, 25, 25, 0.1); }
    .integration-instagram-edit-topic-account .integration-instagram-edit-topic-account-box .integration-instagram-edit-topic-account-box-top {
      padding-bottom: 15px;
      border-bottom: solid 1px #dcdcdc;
      position: relative;
      font-size: 20px;
      letter-spacing: -0.6px; }
      .integration-instagram-edit-topic-account .integration-instagram-edit-topic-account-box .integration-instagram-edit-topic-account-box-top button {
        position: absolute;
        top: -6px;
        right: 0;
        font-size: 16px;
        letter-spacing: -0.6px; }
    .integration-instagram-edit-topic-account .integration-instagram-edit-topic-account-box .integration-instagram-edit-topic-account-box-form {
      width: 100%; }
      .integration-instagram-edit-topic-account .integration-instagram-edit-topic-account-box .integration-instagram-edit-topic-account-box-form label {
        padding: 24px 0 0;
        font-size: 14px;
        letter-spacing: -0.4px;
        display: block; }
      .integration-instagram-edit-topic-account .integration-instagram-edit-topic-account-box .integration-instagram-edit-topic-account-box-form input[type='text'] {
        padding: 12px 16px;
        border-radius: 6px;
        border: solid 1px #dcdcdc;
        width: 100%;
        font-size: 16px;
        letter-spacing: -0.3px;
        display: block; }
      .integration-instagram-edit-topic-account .integration-instagram-edit-topic-account-box .integration-instagram-edit-topic-account-box-form .form-topic {
        width: 620px !important; }
      .integration-instagram-edit-topic-account .integration-instagram-edit-topic-account-box .integration-instagram-edit-topic-account-box-form input[type='text']:disabled {
        background: rgba(25, 25, 25, 0.1);
        color: rgba(25, 25, 25, 0.2);
        border: none;
        cursor: not-allowed; }
      .integration-instagram-edit-topic-account .integration-instagram-edit-topic-account-box .integration-instagram-edit-topic-account-box-form .integration-info-form {
        font-size: 14px;
        color: rgba(25, 25, 25, 0.8);
        letter-spacing: -0.2px; }
      .integration-instagram-edit-topic-account .integration-instagram-edit-topic-account-box .integration-instagram-edit-topic-account-box-form .custom-select-instagram-topic .custom-select-option-value-wrapper {
        padding: 12px 16px !important;
        margin-bottom: 8px; }

.integration-instagram-detail-account {
  padding: 32px 30px;
  text-align: left; }
  .integration-instagram-detail-account .comp-back-button {
    margin: 0 0 16px 0;
    font-family: PTRootBold; }
  .integration-instagram-detail-account .integration-instagram-detail-account-box-1 {
    background: #ffffff;
    border-radius: 8px;
    padding: 22px 32px 32px;
    border: solid 1px rgba(25, 25, 25, 0.1);
    position: relative; }
    .integration-instagram-detail-account .integration-instagram-detail-account-box-1.box-channel-button {
      margin-top: 24px; }
      .integration-instagram-detail-account .integration-instagram-detail-account-box-1.box-channel-button .instagram-detail-topic {
        display: block; }
        .integration-instagram-detail-account .integration-instagram-detail-account-box-1.box-channel-button .instagram-detail-topic .channel-button-wrapper {
          margin-top: 24px; }
      .integration-instagram-detail-account .integration-instagram-detail-account-box-1.box-channel-button .topic-wrapper {
        margin-top: 24px;
        width: 100%;
        display: block; }
      .integration-instagram-detail-account .integration-instagram-detail-account-box-1.box-channel-button .status-wrapper .status-value {
        color: rgba(25, 25, 25, 0.4); }
        .integration-instagram-detail-account .integration-instagram-detail-account-box-1.box-channel-button .status-wrapper .status-value.status-enabled {
          color: #19C700; }
      .integration-instagram-detail-account .integration-instagram-detail-account-box-1.box-channel-button .status-wrapper img {
        position: relative;
        top: -1px;
        margin-right: 4px; }
    .integration-instagram-detail-account .integration-instagram-detail-account-box-1 .integration-instagram-detail-account-box-1-top {
      padding: 0 0 15px;
      border-bottom: solid 1px #dcdcdc;
      position: relative;
      font-size: 20px;
      letter-spacing: -0.6px;
      padding-right: 230px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis; }
      .integration-instagram-detail-account .integration-instagram-detail-account-box-1 .integration-instagram-detail-account-box-1-top .bot-name {
        font-size: 12px;
        margin-bottom: 0; }
      .integration-instagram-detail-account .integration-instagram-detail-account-box-1 .integration-instagram-detail-account-box-1-top .integration-instagram-detail-account-box-1-top-button {
        position: absolute;
        top: 0px;
        right: 0;
        display: inline-block; }
        .integration-instagram-detail-account .integration-instagram-detail-account-box-1 .integration-instagram-detail-account-box-1-top .integration-instagram-detail-account-box-1-top-button button {
          padding: 7px 24px;
          font-size: 16px;
          letter-spacing: -0.6px;
          height: 40px;
          margin-left: 8px; }
          .integration-instagram-detail-account .integration-instagram-detail-account-box-1 .integration-instagram-detail-account-box-1-top .integration-instagram-detail-account-box-1-top-button button img {
            position: relative;
            top: -2px;
            margin-right: 4px; }
          .integration-instagram-detail-account .integration-instagram-detail-account-box-1 .integration-instagram-detail-account-box-1-top .integration-instagram-detail-account-box-1-top-button button svg {
            position: relative;
            top: -2px;
            margin-right: 4px; }
          .integration-instagram-detail-account .integration-instagram-detail-account-box-1 .integration-instagram-detail-account-box-1-top .integration-instagram-detail-account-box-1-top-button button .lds-ring {
            top: unset;
            right: unset;
            position: relative;
            top: 3px; }
            .integration-instagram-detail-account .integration-instagram-detail-account-box-1 .integration-instagram-detail-account-box-1-top .integration-instagram-detail-account-box-1-top-button button .lds-ring div {
              margin: 0;
              border-color: #ffffff transparent transparent transparent; }
    .integration-instagram-detail-account .integration-instagram-detail-account-box-1 .instagram-detail-topic {
      justify-content: space-between;
      align-items: center;
      margin: 24px 0 0; }
      .integration-instagram-detail-account .integration-instagram-detail-account-box-1 .instagram-detail-topic button {
        position: absolute;
        right: 32px;
        top: 26px; }
      .integration-instagram-detail-account .integration-instagram-detail-account-box-1 .instagram-detail-topic .topic-val {
        margin-top: 6px;
        max-width: calc(100% - 200px); }
      .integration-instagram-detail-account .integration-instagram-detail-account-box-1 .instagram-detail-topic .topic-wrapper {
        display: flex;
        flex-direction: column; }
        .integration-instagram-detail-account .integration-instagram-detail-account-box-1 .instagram-detail-topic .topic-wrapper .topic-title {
          font-size: 14px; }
        .integration-instagram-detail-account .integration-instagram-detail-account-box-1 .instagram-detail-topic .topic-wrapper b:nth-child(2) {
          font-size: 20px; }
      .integration-instagram-detail-account .integration-instagram-detail-account-box-1 .instagram-detail-topic button {
        padding: 7px 24px;
        font-size: 16px;
        letter-spacing: -0.6px;
        height: 40px;
        margin-left: 8px; }
        .integration-instagram-detail-account .integration-instagram-detail-account-box-1 .instagram-detail-topic button svg {
          position: relative;
          top: -2px;
          margin-right: 4px; }
    .integration-instagram-detail-account .integration-instagram-detail-account-box-1 .instagram-bot-token-status {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 24px 0 0; }
      .integration-instagram-detail-account .integration-instagram-detail-account-box-1 .instagram-bot-token-status .bot-status-wrapper .bot-status-title {
        font-size: 14px; }
      .integration-instagram-detail-account .integration-instagram-detail-account-box-1 .instagram-bot-token-status .bot-status-wrapper .status-wrapper {
        align-items: center; }
        .integration-instagram-detail-account .integration-instagram-detail-account-box-1 .instagram-bot-token-status .bot-status-wrapper .status-wrapper .facebook-button {
          width: unset;
          margin-top: 24px; }
        .integration-instagram-detail-account .integration-instagram-detail-account-box-1 .instagram-bot-token-status .bot-status-wrapper .status-wrapper img {
          margin-right: 5px;
          position: relative;
          top: -1px; }
        .integration-instagram-detail-account .integration-instagram-detail-account-box-1 .instagram-bot-token-status .bot-status-wrapper .status-wrapper .status-text {
          font-size: 16px; }
        .integration-instagram-detail-account .integration-instagram-detail-account-box-1 .instagram-bot-token-status .bot-status-wrapper .status-wrapper .text-green {
          color: #19c700; }
        .integration-instagram-detail-account .integration-instagram-detail-account-box-1 .instagram-bot-token-status .bot-status-wrapper .status-wrapper .text-red {
          color: #ff3f57; }
      .integration-instagram-detail-account .integration-instagram-detail-account-box-1 .instagram-bot-token-status .button-wrapper button {
        padding: 7px 24px;
        font-size: 16px;
        letter-spacing: -0.6px;
        height: 40px;
        margin-left: 8px;
        font-family: PTRootBold; }
        .integration-instagram-detail-account .integration-instagram-detail-account-box-1 .instagram-bot-token-status .button-wrapper button svg {
          position: relative;
          top: -2px;
          margin-right: 4px; }
    .integration-instagram-detail-account .integration-instagram-detail-account-box-1.box-channel-button {
      margin-top: 24px; }
      .integration-instagram-detail-account .integration-instagram-detail-account-box-1.box-channel-button .instagram-detail-topic {
        display: block; }
      .integration-instagram-detail-account .integration-instagram-detail-account-box-1.box-channel-button .topic-wrapper {
        margin-top: 24px;
        width: 100%;
        display: block; }
      .integration-instagram-detail-account .integration-instagram-detail-account-box-1.box-channel-button .status-wrapper .status-value {
        color: rgba(25, 25, 25, 0.4); }
        .integration-instagram-detail-account .integration-instagram-detail-account-box-1.box-channel-button .status-wrapper .status-value.status-enabled {
          color: #19C700; }
      .integration-instagram-detail-account .integration-instagram-detail-account-box-1.box-channel-button .status-wrapper img {
        position: relative;
        top: -1px;
        margin-right: 4px; }

.integration-auto-message-detail {
  background: #ffffff;
  border-radius: 8px;
  padding: 32px;
  border: solid 1px rgba(25, 25, 25, 0.1);
  margin-bottom: 12px;
  position: relative; }
  .integration-auto-message-detail #retrieve-data-loading-page {
    padding-top: 24px !important; }
  .integration-auto-message-detail.integration-auto-message-detail-margin-top {
    margin-top: 24px; }
  .integration-auto-message-detail .loading-wrapper {
    width: 100%;
    text-align: center;
    padding-top: 24px;
    background: transparent !important;
    box-shadow: none !important;
    top: unset !important;
    -webkit-transform: translate(-50%, 0) !important;
            transform: translate(-50%, 0) !important; }
    .integration-auto-message-detail .loading-wrapper .lds-ring {
      right: unset;
      top: unset;
      width: 38px;
      height: 38px; }
      .integration-auto-message-detail .loading-wrapper .lds-ring div {
        margin: 0;
        width: 38px;
        height: 38px;
        border: solid 4px #fff;
        border-color: #ff7e00 transparent transparent transparent; }
  .integration-auto-message-detail .title-wrapper {
    display: block;
    width: 100%;
    position: relative;
    padding-right: 150px;
    border-bottom: solid 1px #dcdcdc;
    padding-bottom: 12px; }
    .integration-auto-message-detail .title-wrapper img {
      width: 24px;
      height: 24px;
      margin-right: 8px;
      margin-top: 4px; }
    .integration-auto-message-detail .title-wrapper .title-description {
      display: block; }
      .integration-auto-message-detail .title-wrapper .title-description p {
        margin: 0; }
      .integration-auto-message-detail .title-wrapper .title-description .title-customize {
        font-size: 20px; }
      .integration-auto-message-detail .title-wrapper .title-description .description-customize {
        font-size: 14px; }
        .integration-auto-message-detail .title-wrapper .title-description .description-customize a {
          color: #ff7e00; }
    .integration-auto-message-detail .title-wrapper button {
      position: absolute;
      right: 0;
      top: 8px; }
  .integration-auto-message-detail .detail-wrapper {
    width: 100%;
    display: block; }
    .integration-auto-message-detail .detail-wrapper .status-value {
      font-size: 16px;
      letter-spacing: -0.5px; }
      .integration-auto-message-detail .detail-wrapper .status-value img {
        margin-right: 4px;
        position: relative;
        top: -2px; }
      .integration-auto-message-detail .detail-wrapper .status-value.status-enabled {
        color: #19C700; }
      .integration-auto-message-detail .detail-wrapper .status-value.status-disabled {
        color: rgba(25, 25, 25, 0.4); }
    .integration-auto-message-detail .detail-wrapper label {
      font-size: 16px;
      letter-spacing: -0.5px;
      margin: 24px 0 0; }
    .integration-auto-message-detail .detail-wrapper p {
      margin: 4px 0 0;
      font-size: 16px;
      letter-spacing: -0.2px;
      width: 500px;
      max-width: 100%;
      word-break: break-word; }
      .integration-auto-message-detail .detail-wrapper p.no-title {
        margin: 12px 0 0; }
    .integration-auto-message-detail .detail-wrapper .value-text {
      margin: 12px 0 0;
      font-size: 14px;
      letter-spacing: -0.2px; }
  .integration-auto-message-detail .theme-color-text {
    font-size: 18px;
    position: relative;
    padding-left: 24px;
    line-height: 1; }
  .integration-auto-message-detail .theme-color {
    display: inline-block;
    position: absolute;
    vertical-align: top;
    border-radius: 50%;
    width: 18px;
    height: 18px;
    margin-right: 6px;
    left: 0;
    top: -2px;
    border: solid 1px #dcdcdc; }

.chatbot-add-chatbot {
  padding: 32px 30px;
  text-align: left; }
  .chatbot-add-chatbot .comp-back-button {
    margin: 0 0 16px 0; }
  .chatbot-add-chatbot .chatbot-add-chatbot-box {
    background: #ffffff;
    border-radius: 8px;
    padding: 32px;
    border: solid 1px rgba(25, 25, 25, 0.1); }
    .chatbot-add-chatbot .chatbot-add-chatbot-box .chatbot-add-chatbot-box-top {
      padding-bottom: 15px;
      border-bottom: solid 1px #dcdcdc;
      position: relative;
      font-size: 20px;
      letter-spacing: -0.6px; }
      .chatbot-add-chatbot .chatbot-add-chatbot-box .chatbot-add-chatbot-box-top .chat-bot-chatbot-button-wrapper {
        margin: 0; }
      .chatbot-add-chatbot .chatbot-add-chatbot-box .chatbot-add-chatbot-box-top button {
        position: absolute;
        top: -6px;
        right: 0;
        font-size: 16px;
        margin: 0 !important;
        letter-spacing: -0.6px; }
    .chatbot-add-chatbot .chatbot-add-chatbot-box .chatbot-add-chatbot-box-form {
      width: 320px; }
      .chatbot-add-chatbot .chatbot-add-chatbot-box .chatbot-add-chatbot-box-form label {
        padding: 24px 0 0;
        font-size: 14px;
        letter-spacing: -0.4px;
        display: block; }
      .chatbot-add-chatbot .chatbot-add-chatbot-box .chatbot-add-chatbot-box-form input[type="text"] {
        padding: 12px 16px;
        border-radius: 6px;
        border: solid 1px #dcdcdc;
        width: 100%;
        font-size: 16px;
        letter-spacing: -0.3px;
        display: block;
        margin-bottom: 8px; }
      .chatbot-add-chatbot .chatbot-add-chatbot-box .chatbot-add-chatbot-box-form .integration-info-form {
        font-size: 14px;
        color: rgba(25, 25, 25, 0.8);
        letter-spacing: -0.2px; }
      .chatbot-add-chatbot .chatbot-add-chatbot-box .chatbot-add-chatbot-box-form .custom-select-path .custom-select-option-value-wrapper {
        padding: 12px 16px !important;
        margin-bottom: 8px; }
    .chatbot-add-chatbot .chatbot-add-chatbot-box .chat-bot-chatbot-button-wrapper {
      margin-top: 18px; }
      .chatbot-add-chatbot .chatbot-add-chatbot-box .chat-bot-chatbot-button-wrapper button {
        margin: 0 4px 0 0; }

.my-options {
  cursor: pointer;
  font-size: 16px;
  white-space: nowrap;
  max-width: 100%;
  text-overflow: ellipsis;
  overflow: auto;
  overflow-x: hidden;
  width: 100%;
  color: #191919;
  padding: 10px 16px 10px 35px !important;
  position: relative;
  z-index: 999; }
  .my-options p {
    max-width: calc(100% - 100px);
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap; }
  .my-options:hover {
    background: #ffe7d0; }
  .my-options .option-badge {
    width: 94px;
    display: inline-block;
    font-size: 12px;
    letter-spacing: -0.4px;
    position: absolute;
    right: 16px;
    background: red;
    color: #fff;
    border-radius: 4px;
    text-align: center;
    top: 50%;
    -webkit-transform: translate(0, -50%);
            transform: translate(0, -50%);
    z-index: 999; }
  .my-options .option-authenticated {
    background: #19c700; }
  .my-options .option-got_qr_code {
    background: #0c5f99; }
  .my-options .option-active {
    background: #19c700; }
  .my-options .option-loading {
    background: #f4c12a; }
  .my-options .option-error {
    background: #e02e2e; }
  .my-options .option-waiting-setup {
    background: var(--main-dark-orange); }
  .my-options .option-not_paid {
    background: rgba(25, 25, 25, 0.4); }
  .my-options p {
    display: inline-block;
    margin: 0;
    position: relative;
    top: -1px; }

.my-options-normal {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 10px 16px 10px 16px !important;
  cursor: pointer; }
  .my-options-normal:hover {
    background: #ffe7d0; }

.custom-react-select__input input {
  height: unset !important;
  margin-bottom: 0 !important; }
  .custom-react-select__input input[type="text"][disabled] {
    background: none !important;
    border: none !important; }


.integration-auto-message-setup {
  background: #ffffff;
  border-radius: 8px;
  padding: 32px;
  border: solid 1px rgba(25, 25, 25, 0.1);
  margin-bottom: 12px;
  margin-top: 24px; }
  .integration-auto-message-setup .label-auto-message {
    position: relative;
    width: 400px; }
    .integration-auto-message-setup .label-auto-message span {
      position: absolute;
      right: 0; }
  .integration-auto-message-setup .title-wrapper {
    display: block;
    width: 100%;
    position: relative;
    padding-right: 150px;
    border-bottom: solid 1px #dcdcdc;
    padding-bottom: 24px;
    margin-bottom: 24px; }
    .integration-auto-message-setup .title-wrapper .title-description {
      display: block; }
      .integration-auto-message-setup .title-wrapper .title-description p {
        margin: 0; }
      .integration-auto-message-setup .title-wrapper .title-description .title-customize {
        font-size: 20px; }
      .integration-auto-message-setup .title-wrapper .title-description .description-customize {
        font-size: 14px; }
    .integration-auto-message-setup .title-wrapper button {
      position: absolute;
      right: 0;
      top: 0; }
  .integration-auto-message-setup .top-setup-wrapper {
    display: block;
    width: 100%;
    position: relative;
    padding-right: 150px;
    margin-bottom: 24px; }
    .integration-auto-message-setup .top-setup-wrapper .top-setup-description {
      display: block; }
      .integration-auto-message-setup .top-setup-wrapper .top-setup-description p {
        margin: 0; }
      .integration-auto-message-setup .top-setup-wrapper .top-setup-description .title-customize {
        font-size: 16px;
        letter-spacing: -0.5px; }
      .integration-auto-message-setup .top-setup-wrapper .top-setup-description .description-customize {
        font-size: 14px;
        letter-spacing: -0.2px;
        color: rgba(25, 25, 25, 0.8); }
    .integration-auto-message-setup .top-setup-wrapper button {
      position: absolute;
      right: 0;
      top: 0; }
    .integration-auto-message-setup .top-setup-wrapper .custom-switch-wrapper {
      position: absolute;
      right: 0;
      top: 0;
      width: 48px;
      height: 28px; }
      .integration-auto-message-setup .top-setup-wrapper .custom-switch-wrapper .custom-switch::before {
        width: 18px;
        height: 18px;
        bottom: 6px; }
      .integration-auto-message-setup .top-setup-wrapper .custom-switch-wrapper input:checked + .custom-switch:before {
        -webkit-transform: translateX(16px);
                transform: translateX(16px); }
  .integration-auto-message-setup .auto-message-setup-textarea-wrapper {
    position: relative;
    display: inline-block; }
    .integration-auto-message-setup .auto-message-setup-textarea-wrapper .auto-message-setup-textarea {
      padding: 12px 16px;
      border-radius: 8px;
      border: solid 1px #dcdcdc;
      font-size: 16px;
      letter-spacing: -0.3px;
      height: 200px;
      width: 400px; }
    .integration-auto-message-setup .auto-message-setup-textarea-wrapper span {
      position: absolute;
      bottom: -10px;
      font-size: 14px;
      letter-spacing: -0.2px;
      right: 0; }
  .integration-auto-message-setup .user-rating-wrapper {
    position: relative;
    width: 100%; }
    .integration-auto-message-setup .user-rating-wrapper .color-select-wrapper {
      width: 100%;
      position: relative;
      padding-top: 24px; }
      .integration-auto-message-setup .user-rating-wrapper .color-select-wrapper .color-select-title {
        font-size: 14px;
        letter-spacing: -0.4px; }
      .integration-auto-message-setup .user-rating-wrapper .color-select-wrapper .color-select-desc {
        font-size: 14px;
        letter-spacing: -0.2px; }
      .integration-auto-message-setup .user-rating-wrapper .color-select-wrapper .button-go-preview {
        position: absolute;
        top: 24px;
        right: 0; }
      .integration-auto-message-setup .user-rating-wrapper .color-select-wrapper .color-input-wrapper {
        position: relative;
        width: 166px;
        height: 48px;
        border-radius: 8px;
        border: solid 1px #dcdcdc;
        cursor: pointer;
        line-height: 47px;
        font-size: 16px;
        letter-spacing: -0.3px;
        color: #191919;
        padding: 0 0 0 16px;
        margin-top: 12px; }
        .integration-auto-message-setup .user-rating-wrapper .color-select-wrapper .color-input-wrapper .color-placeholder {
          color: rgba(25, 25, 25, 0.6); }
        .integration-auto-message-setup .user-rating-wrapper .color-select-wrapper .color-input-wrapper img {
          position: absolute;
          position: absolute;
          right: 16px;
          top: 50%;
          -webkit-transform: translate(0, -50%);
                  transform: translate(0, -50%); }
        .integration-auto-message-setup .user-rating-wrapper .color-select-wrapper .color-input-wrapper .color-tag {
          display: inline-block !important;
          margin-right: 1px;
          margin: 0; }
        .integration-auto-message-setup .user-rating-wrapper .color-select-wrapper .color-input-wrapper .color-value {
          width: 16px;
          height: 16px;
          display: inline-block;
          background: #dcdcdc;
          border-radius: 50%;
          position: absolute;
          right: 16px;
          top: 50%;
          -webkit-transform: translate(0, -50%);
                  transform: translate(0, -50%); }
        .integration-auto-message-setup .user-rating-wrapper .color-select-wrapper .color-input-wrapper .color-input {
          border: none !important;
          padding: 0;
          background-color: transparent;
          height: 20px;
          margin-top: 0 !important;
          height: 40px !important;
          display: inline-block;
          width: 125px !important; }
        .integration-auto-message-setup .user-rating-wrapper .color-select-wrapper .color-input-wrapper .color-picker-container {
          background: #ffffff;
          position: absolute;
          bottom: 0;
          right: -265px;
          border-radius: 4px;
          box-shadow: 0 2px 20px 0 rgba(25, 25, 25, 0.18) !important;
          padding: 14px;
          z-index: 9999; }
          .integration-auto-message-setup .user-rating-wrapper .color-select-wrapper .color-input-wrapper .color-picker-container .close-color-picker {
            position: absolute;
            top: 6px;
            font-size: 14px;
            right: 6px;
            color: rgba(25, 25, 25, 0.6); }
            .integration-auto-message-setup .user-rating-wrapper .color-select-wrapper .color-input-wrapper .color-picker-container .close-color-picker:hover {
              color: rgba(25, 25, 25, 0.8); }
          .integration-auto-message-setup .user-rating-wrapper .color-select-wrapper .color-input-wrapper .color-picker-container::before {
            position: absolute;
            background: #ffffff;
            width: 16px;
            height: 16px;
            content: '';
            left: -8px;
            -webkit-transform: rotate(45deg);
                    transform: rotate(45deg);
            bottom: 17px; }
          .integration-auto-message-setup .user-rating-wrapper .color-select-wrapper .color-input-wrapper .color-picker-container .sketch-picker {
            border-radius: 4px;
            box-shadow: none !important; }
            .integration-auto-message-setup .user-rating-wrapper .color-select-wrapper .color-input-wrapper .color-picker-container .sketch-picker .flexbox-fix:nth-child(2) {
              height: 28px; }
              .integration-auto-message-setup .user-rating-wrapper .color-select-wrapper .color-input-wrapper .color-picker-container .sketch-picker .flexbox-fix:nth-child(2) div:nth-child(1) div:nth-child(1) {
                height: 23px !important; }
                .integration-auto-message-setup .user-rating-wrapper .color-select-wrapper .color-input-wrapper .color-picker-container .sketch-picker .flexbox-fix:nth-child(2) div:nth-child(1) div:nth-child(1) div div.hue-horizontal div div {
                  display: block !important;
                  margin-top: 0 !important; }
              .integration-auto-message-setup .user-rating-wrapper .color-select-wrapper .color-input-wrapper .color-picker-container .sketch-picker .flexbox-fix:nth-child(2) div:nth-child(1) div:nth-child(2) div {
                display: none; }
            .integration-auto-message-setup .user-rating-wrapper .color-select-wrapper .color-input-wrapper .color-picker-container .sketch-picker .flexbox-fix:nth-child(3) {
              display: none !important; }
            .integration-auto-message-setup .user-rating-wrapper .color-select-wrapper .color-input-wrapper .color-picker-container .sketch-picker .flexbox-fix:nth-child(4) div div {
              border-radius: 50% !important; }
      .integration-auto-message-setup .user-rating-wrapper .color-select-wrapper .color-picker-container {
        background: #ffffff;
        position: absolute;
        bottom: 0;
        right: -265px;
        border-radius: 4px;
        box-shadow: 0 2px 20px 0 rgba(25, 25, 25, 0.18) !important;
        padding: 14px;
        z-index: 9999; }
        .integration-auto-message-setup .user-rating-wrapper .color-select-wrapper .color-picker-container .close-color-picker {
          position: absolute;
          top: 6px;
          font-size: 14px;
          right: 6px;
          color: rgba(25, 25, 25, 0.6); }
          .integration-auto-message-setup .user-rating-wrapper .color-select-wrapper .color-picker-container .close-color-picker:hover {
            color: rgba(25, 25, 25, 0.8); }
        .integration-auto-message-setup .user-rating-wrapper .color-select-wrapper .color-picker-container::before {
          position: absolute;
          background: #ffffff;
          width: 16px;
          height: 16px;
          content: '';
          left: -8px;
          -webkit-transform: rotate(45deg);
                  transform: rotate(45deg);
          bottom: 17px; }
        .integration-auto-message-setup .user-rating-wrapper .color-select-wrapper .color-picker-container .sketch-picker {
          border-radius: 4px;
          box-shadow: none !important; }
          .integration-auto-message-setup .user-rating-wrapper .color-select-wrapper .color-picker-container .sketch-picker .flexbox-fix:nth-child(2) {
            height: 28px; }
            .integration-auto-message-setup .user-rating-wrapper .color-select-wrapper .color-picker-container .sketch-picker .flexbox-fix:nth-child(2) div:nth-child(1) div:nth-child(1) {
              height: 23px !important; }
              .integration-auto-message-setup .user-rating-wrapper .color-select-wrapper .color-picker-container .sketch-picker .flexbox-fix:nth-child(2) div:nth-child(1) div:nth-child(1) div div.hue-horizontal div div {
                display: block !important;
                margin-top: 0 !important; }
            .integration-auto-message-setup .user-rating-wrapper .color-select-wrapper .color-picker-container .sketch-picker .flexbox-fix:nth-child(2) div:nth-child(1) div:nth-child(2) div {
              display: none; }
          .integration-auto-message-setup .user-rating-wrapper .color-select-wrapper .color-picker-container .sketch-picker .flexbox-fix:nth-child(3) {
            display: none !important; }
          .integration-auto-message-setup .user-rating-wrapper .color-select-wrapper .color-picker-container .sketch-picker .flexbox-fix:nth-child(4) div div {
            border-radius: 50% !important; }

.integration-whatsapp-list {
  text-align: left; }
  .integration-whatsapp-list .integration-list-loading-wrapper .lds-ring {
    width: 50px;
    height: 50px;
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    right: unset; }
    .integration-whatsapp-list .integration-list-loading-wrapper .lds-ring div {
      border: 5px solid #fff;
      border-color: #ff7d00 transparent transparent transparent;
      width: 50px;
      height: 50px;
      margin: 0; }
  .integration-whatsapp-list .integration-list-no-data {
    width: 100%;
    text-align: center;
    position: relative;
    margin: 32px 0 0; }
    .integration-whatsapp-list .integration-list-no-data b {
      font-size: 20px;
      letter-spacing: -0.6px;
      color: #191919; }
      .integration-whatsapp-list .integration-list-no-data b.grey-font {
        font-size: 16px !important;
        letter-spacing: -0.6px !important; }
    .integration-whatsapp-list .integration-list-no-data p {
      color: rgba(25, 25, 25, 0.6);
      letter-spacing: -0.6px;
      font-size: 16px; }
  .integration-whatsapp-list .integration-list-header {
    position: relative;
    padding-left: 42px;
    padding-bottom: 26px;
    border-bottom: solid 1px rgba(25, 25, 25, 0.1); }
    .integration-whatsapp-list .integration-list-header .integration-list-header-icon {
      position: absolute;
      top: 0;
      left: 0; }
    .integration-whatsapp-list .integration-list-header .integration-list-header-text-wrapper b {
      font-size: 20px;
      letter-spacing: -0.6px; }
    .integration-whatsapp-list .integration-list-header .integration-list-header-text-wrapper p {
      margin: 0;
      font-size: 14px;
      letter-spacing: -0.2px; }
    .integration-whatsapp-list .integration-list-header .integration-list-header-text-wrapper a {
      color: #ff7d00; }
    .integration-whatsapp-list .integration-list-header .integration-list-header-button {
      position: absolute;
      right: 0;
      top: 0;
      font-size: 16px;
      letter-spacing: -0.5px;
      padding: 7px 32px; }
      .integration-whatsapp-list .integration-list-header .integration-list-header-button img {
        margin-right: 4px;
        position: relative;
        top: -2px; }
  .integration-whatsapp-list .filter-result-found {
    margin: 24px 0 0;
    color: rgba(25, 25, 25, 0.6);
    font-size: 16px;
    letter-spacing: -0.5px; }
  .integration-whatsapp-list .search-and-filter-wrapper {
    width: 100%;
    display: block;
    margin-top: 12px;
    position: relative; }
    .integration-whatsapp-list .search-and-filter-wrapper .search-box-wrapper {
      width: 370px; }
    .integration-whatsapp-list .search-and-filter-wrapper .dropdown-filter-status {
      display: inline-block;
      position: absolute;
      left: 382px;
      top: 0; }
  .integration-whatsapp-list .integration-list-outer-wrapper {
    position: relative;
    width: 100%;
    padding-top: 24px; }
    .integration-whatsapp-list .integration-list-outer-wrapper .integration-list-inner-wrapper {
      width: calc((100% / 3) - 8px);
      border-radius: 8px;
      margin: 0 12px 12px 0;
      box-shadow: 0 2px 20px 0 rgba(25, 25, 25, 0.05);
      background: #ffffff;
      display: inline-block;
      vertical-align: top;
      padding: 24px 30px 24px 24px;
      cursor: pointer;
      position: relative; }
      .integration-whatsapp-list .integration-list-outer-wrapper .integration-list-inner-wrapper p:nth-child(2) {
        font-size: 16px;
        letter-spacing: -0.5px;
        color: rgba(25, 25, 25, 0.6);
        margin: 0;
        white-space: nowrap;
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis; }
      .integration-whatsapp-list .integration-list-outer-wrapper .integration-list-inner-wrapper span {
        display: block;
        font-size: 12px;
        letter-spacing: -0.2px;
        color: #191919; }
      .integration-whatsapp-list .integration-list-outer-wrapper .integration-list-inner-wrapper p {
        margin: 10px 0 0;
        color: #ff7d00;
        font-size: 12px;
        letter-spacing: -0.2px; }
        .integration-whatsapp-list .integration-list-outer-wrapper .integration-list-inner-wrapper p.font-green {
          color: #19c700; }
        .integration-whatsapp-list .integration-list-outer-wrapper .integration-list-inner-wrapper p.font-grey {
          color: rgba(25, 25, 25, 0.4); }
      .integration-whatsapp-list .integration-list-outer-wrapper .integration-list-inner-wrapper svg {
        position: absolute;
        right: 12px;
        top: 12px;
        font-size: 22px;
        color: rgba(25, 25, 25, 0.6); }
      .integration-whatsapp-list .integration-list-outer-wrapper .integration-list-inner-wrapper:hover {
        box-shadow: 0 2px 20px 0 rgba(25, 25, 25, 0.18); }
        .integration-whatsapp-list .integration-list-outer-wrapper .integration-list-inner-wrapper:hover p:nth-child(2) {
          color: #191919; }
        .integration-whatsapp-list .integration-list-outer-wrapper .integration-list-inner-wrapper:hover svg {
          color: #ff7d00; }
  .integration-whatsapp-list .link-whatsapp-account-list:nth-child(3n) .integration-list-inner-wrapper {
    margin: 0 0 12px 0; }

.dropdown-filter-status {
  position: absolute;
  width: 322px;
  left: 356px;
  top: 0;
  box-shadow: none !important;
  outline: none !important; }
  .dropdown-filter-status button {
    padding: 12px 34px 12px 16px;
    border-radius: 8px;
    border: 1px solid rgba(25, 25, 25, 0.1) !important;
    background: #fff !important;
    color: #191919 !important;
    width: 322px;
    text-align: left;
    height: 47px;
    line-height: 18px;
    box-shadow: none !important;
    outline: none !important;
    white-space: nowrap;
    display: block;
    text-overflow: ellipsis;
    overflow: hidden; }
    .dropdown-filter-status button .filter-black {
      width: 24px; }
    .dropdown-filter-status button img {
      position: relative;
      top: -1px;
      margin-right: 8px;
      font-size: 18px; }
    .dropdown-filter-status button svg {
      font-size: 18px;
      position: absolute;
      right: 14px;
      top: 15px; }
  .dropdown-filter-status.dropdown-isdisabled button {
    background: rgba(25, 25, 25, 0.1) !important;
    color: rgba(25, 25, 25, 0.4) !important; }
  .dropdown-filter-status .dropdown-menu {
    width: 322px;
    padding: 0;
    border-radius: 8px;
    overflow: hidden; }
    .dropdown-filter-status .dropdown-menu .disabled-clock-filter {
      background: rgba(25, 25, 25, 0.1); }
    .dropdown-filter-status .dropdown-menu .dropdown-all-status {
      border-bottom: solid 1px #dcdcdc; }
    .dropdown-filter-status .dropdown-menu div {
      cursor: pointer;
      padding: 12px 8px 12px 36px;
      width: 100%;
      display: block;
      font-size: 16px;
      letter-spacing: -0.3px;
      position: relative; }
      .dropdown-filter-status .dropdown-menu div svg {
        position: absolute;
        font-size: 20px;
        color: var(--main-dark-orange);
        left: 8px;
        top: 14px; }
      .dropdown-filter-status .dropdown-menu div label {
        margin: 0;
        cursor: pointer; }
      .dropdown-filter-status .dropdown-menu div:hover {
        background: rgba(255, 126, 0, 0.05); }

.modal-comparison-whatsapp .modal-content {
  width: 1110px;
  max-width: 90vw;
  border-radius: 8px; }

.modal-comparison-whatsapp .modal-body {
  padding: 36px;
  position: relative;
  text-align: center; }
  .modal-comparison-whatsapp .modal-body div {
    margin: 0;
    line-height: 1; }
    .modal-comparison-whatsapp .modal-body div.title {
      font-size: 20px;
      letter-spacing: -0.6px; }
      .modal-comparison-whatsapp .modal-body div.title b.and-text {
        margin: 0 4px; }
      .modal-comparison-whatsapp .modal-body div.title img {
        position: relative;
        margin: 0 4px;
        top: -2px;
        width: 24px;
        height: 24px; }
  .modal-comparison-whatsapp .modal-body svg {
    color: #ff7d00;
    position: absolute;
    top: 36px;
    right: 36px;
    cursor: pointer; }
    .modal-comparison-whatsapp .modal-body svg:hover {
      color: var(--main-darker-orange); }
  .modal-comparison-whatsapp .modal-body table {
    margin: 28px 0 0;
    width: 100%;
    text-align: left; }
    .modal-comparison-whatsapp .modal-body table thead tr td {
      padding: 8px 16px;
      font-size: 16px;
      letter-spacing: -0.5px;
      color: #ffffff;
      width: 160px; }
      .modal-comparison-whatsapp .modal-body table thead tr td:nth-child(2) {
        width: calc(50% - 80px);
        background: #31AD5F;
        border-radius: 8px 0 0 0; }
      .modal-comparison-whatsapp .modal-body table thead tr td:nth-child(3) {
        width: calc(50% - 80px);
        background: #00684F;
        border-radius: 0 8px 0 0; }
    .modal-comparison-whatsapp .modal-body table tbody tr {
      background: none; }
      .modal-comparison-whatsapp .modal-body table tbody tr td {
        padding: 8px 16px;
        font-size: 16px;
        letter-spacing: -0.3px;
        vertical-align: center; }
        .modal-comparison-whatsapp .modal-body table tbody tr td .upto-sync {
          color: #667062; }
        .modal-comparison-whatsapp .modal-body table tbody tr td .green-tick-span {
          color: #667062; }
        .modal-comparison-whatsapp .modal-body table tbody tr td img {
          position: relative;
          margin: 0 4px 0 0;
          top: -1px;
          width: 13px;
          height: 13px; }
        .modal-comparison-whatsapp .modal-body table tbody tr td:nth-child(1) {
          background: #ffffff !important;
          text-align: left;
          font-size: 14px;
          letter-spacing: -0.4px;
          width: 160px; }
      .modal-comparison-whatsapp .modal-body table tbody tr:nth-child(odd) td:nth-child(n + 1) {
        background: #D1F4CC;
        width: calc(50% - 80px); }
      .modal-comparison-whatsapp .modal-body table tbody tr:nth-child(even) {
        background: #F3FCF2; }
        .modal-comparison-whatsapp .modal-body table tbody tr:nth-child(even) td:nth-child(n + 1) {
          width: calc(50% - 80px); }
      .modal-comparison-whatsapp .modal-body table tbody tr:nth-last-child(1) td {
        border-bottom: none; }

.integration-whatsapp-add-account {
  padding: 32px 30px;
  text-align: left; }
  .integration-whatsapp-add-account .comp-back-button {
    margin: 0 0 16px 0; }
  .integration-whatsapp-add-account .integration-whatsapp-add-account-box {
    background: #ffffff;
    border-radius: 8px;
    padding: 32px;
    border: solid 1px rgba(25, 25, 25, 0.1); }
    .integration-whatsapp-add-account .integration-whatsapp-add-account-box .integration-whatsapp-add-account-box-top {
      padding-bottom: 15px;
      border-bottom: solid 1px #dcdcdc;
      position: relative;
      font-size: 20px;
      letter-spacing: -0.6px; }
      .integration-whatsapp-add-account .integration-whatsapp-add-account-box .integration-whatsapp-add-account-box-top button {
        position: absolute;
        top: -6px;
        right: 0;
        font-size: 16px;
        letter-spacing: -0.6px; }
    .integration-whatsapp-add-account .integration-whatsapp-add-account-box .integration-whatsapp-add-account-box-form {
      width: 320px; }
      .integration-whatsapp-add-account .integration-whatsapp-add-account-box .integration-whatsapp-add-account-box-form label {
        padding: 24px 0 0;
        font-size: 14px;
        letter-spacing: -0.4px;
        display: block;
        position: relative; }
        .integration-whatsapp-add-account .integration-whatsapp-add-account-box .integration-whatsapp-add-account-box-form label span {
          position: absolute;
          right: 0; }
      .integration-whatsapp-add-account .integration-whatsapp-add-account-box .integration-whatsapp-add-account-box-form input[type="text"] {
        padding: 12px 16px;
        border-radius: 6px;
        border: solid 1px #dcdcdc;
        width: 100%;
        font-size: 16px;
        letter-spacing: -0.3px;
        display: block; }
      .integration-whatsapp-add-account .integration-whatsapp-add-account-box .integration-whatsapp-add-account-box-form .integration-info-form {
        font-size: 14px;
        color: rgba(25, 25, 25, 0.8);
        letter-spacing: -0.2px; }
      .integration-whatsapp-add-account .integration-whatsapp-add-account-box .integration-whatsapp-add-account-box-form .custom-select-whatsapp-topic .custom-select-option-value-wrapper {
        padding: 12px 16px !important;
        margin-bottom: 8px; }

.terms-and-condition-modal .modal-content {
  width: 600px;
  border-radius: 8px; }

.terms-and-condition-modal .modal-body {
  padding: 32px; }
  .terms-and-condition-modal .modal-body p {
    font-size: 20px;
    letter-spacing: -0.6px; }
    .terms-and-condition-modal .modal-body p svg {
      position: relative;
      top: -2px;
      margin-right: 4px; }
    .terms-and-condition-modal .modal-body p.title-waba {
      margin: 0; }
  .terms-and-condition-modal .modal-body span {
    display: block;
    margin-bottom: 20px; }
  .terms-and-condition-modal .modal-body ol {
    margin: 0 0 12px 0;
    padding: 0 0 0 18px; }
    .terms-and-condition-modal .modal-body ol li {
      font-size: 14px;
      line-height: 21px;
      letter-spacing: -0.2px;
      padding: 0 0 12px 7px;
      color: #000000; }
  .terms-and-condition-modal .modal-body span {
    font-size: 14px;
    letter-spacing: -0.2px;
    line-height: 21px;
    color: rgba(25, 25, 25, 0.6); }
  .terms-and-condition-modal .modal-body button {
    width: calc(50% - 4px);
    margin: 0 8px 0 0; }
    .terms-and-condition-modal .modal-body button:nth-last-child(1) {
      margin: 0; }
    .terms-and-condition-modal .modal-body button img {
      width: 22px; }

.integration-whatsapp-checkout {
  padding: 32px 30px;
  text-align: left; }
  .integration-whatsapp-checkout .integration-whatsapp-checkout-box-form {
    padding-top: 24px; }
  .integration-whatsapp-checkout .comp-back-button {
    margin: 0 0 16px 0; }
  .integration-whatsapp-checkout .integration-detail-loading-wrapper .lds-ring {
    width: 50px;
    height: 50px;
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    right: unset; }
    .integration-whatsapp-checkout .integration-detail-loading-wrapper .lds-ring div {
      border: 5px solid #fff;
      border-color: #ff7d00 transparent transparent transparent;
      width: 50px;
      height: 50px;
      margin: 0; }
  .integration-whatsapp-checkout .integration-whatsapp-checkout-box {
    background: #ffffff;
    border-radius: 8px;
    padding: 32px;
    border: solid 1px rgba(25, 25, 25, 0.1); }
    .integration-whatsapp-checkout .integration-whatsapp-checkout-box .integration-whatsapp-checkout-box-top {
      padding-bottom: 15px;
      border-bottom: solid 1px #dcdcdc;
      position: relative;
      font-size: 20px;
      letter-spacing: -0.6px; }
      .integration-whatsapp-checkout .integration-whatsapp-checkout-box .integration-whatsapp-checkout-box-top button {
        position: absolute;
        top: -6px;
        right: 0;
        padding: 7px 24px;
        font-size: 16px;
        letter-spacing: -0.6px;
        width: 160px;
        height: 40px; }
        .integration-whatsapp-checkout .integration-whatsapp-checkout-box .integration-whatsapp-checkout-box-top button .lds-ring {
          top: unset;
          right: unset;
          position: relative;
          top: 3px; }
          .integration-whatsapp-checkout .integration-whatsapp-checkout-box .integration-whatsapp-checkout-box-top button .lds-ring div {
            margin: 0;
            border-color: #ffffff transparent transparent transparent; }
    .integration-whatsapp-checkout .integration-whatsapp-checkout-box .integration-whatsapp-checkout-box-form {
      width: 100%; }
      .integration-whatsapp-checkout .integration-whatsapp-checkout-box .integration-whatsapp-checkout-box-form .alert-checkout {
        margin: 0 0 24px;
        width: 100%;
        font-size: 16px;
        letter-spacing: -0.5px; }
        .integration-whatsapp-checkout .integration-whatsapp-checkout-box .integration-whatsapp-checkout-box-form .alert-checkout svg {
          top: 25px;
          font-size: 20px; }
        .integration-whatsapp-checkout .integration-whatsapp-checkout-box .integration-whatsapp-checkout-box-form .alert-checkout .blue-button {
          background-color: #044B84;
          color: #ffffff;
          border-radius: 8px;
          margin-top: 8px;
          font-size: 14px; }
      .integration-whatsapp-checkout .integration-whatsapp-checkout-box .integration-whatsapp-checkout-box-form .amount-due {
        margin-bottom: 24px;
        padding: 24px 12px;
        text-align: center;
        background: rgba(25, 25, 25, 0.05);
        color: rgba(25, 25, 25, 0.6); }
        .integration-whatsapp-checkout .integration-whatsapp-checkout-box .integration-whatsapp-checkout-box-form .amount-due .amount-box {
          width: 100%;
          display: inline-block;
          align-items: center;
          margin-top: 4px;
          color: #191919;
          font-size: 32px;
          letter-spacing: -0.6px; }
      .integration-whatsapp-checkout .integration-whatsapp-checkout-box .integration-whatsapp-checkout-box-form .primary-card {
        margin-bottom: 24px;
        position: relative;
        width: 100%;
        border-bottom: solid 1px #dcdcdc; }
        .integration-whatsapp-checkout .integration-whatsapp-checkout-box .integration-whatsapp-checkout-box-form .primary-card .card-box {
          display: inline-block;
          border-radius: 8px;
          padding: 10px 12px;
          position: relative;
          padding: 0 0 0 58px; }
          .integration-whatsapp-checkout .integration-whatsapp-checkout-box .integration-whatsapp-checkout-box-form .primary-card .card-box .card-box-frame {
            border-radius: 50%;
            width: 48px;
            height: 48px;
            vertical-align: top;
            display: inline-block;
            background: #FFF5D9;
            display: inline-block;
            position: absolute;
            left: 0; }
            .integration-whatsapp-checkout .integration-whatsapp-checkout-box .integration-whatsapp-checkout-box-form .primary-card .card-box .card-box-frame.card-box-frame-whatsapp {
              background: #D0F5F1; }
            .integration-whatsapp-checkout .integration-whatsapp-checkout-box .integration-whatsapp-checkout-box-form .primary-card .card-box .card-box-frame.card-box-frame-manual {
              color: #F4C22C; }
            .integration-whatsapp-checkout .integration-whatsapp-checkout-box .integration-whatsapp-checkout-box-form .primary-card .card-box .card-box-frame.card-box-frame-auto {
              color: #9C89F1; }
            .integration-whatsapp-checkout .integration-whatsapp-checkout-box .integration-whatsapp-checkout-box-form .primary-card .card-box .card-box-frame svg {
              position: absolute;
              top: 50%;
              left: 50%;
              -webkit-transform: translate(-50%, -50%);
                      transform: translate(-50%, -50%);
              font-size: 20px; }
            .integration-whatsapp-checkout .integration-whatsapp-checkout-box .integration-whatsapp-checkout-box-form .primary-card .card-box .card-box-frame img {
              position: absolute;
              top: 50%;
              left: 50%;
              -webkit-transform: translate(-50%, -50%);
                      transform: translate(-50%, -50%); }
          .integration-whatsapp-checkout .integration-whatsapp-checkout-box .integration-whatsapp-checkout-box-form .primary-card .card-box img {
            margin-right: 12px;
            position: absolute;
            left: 0; }
            .integration-whatsapp-checkout .integration-whatsapp-checkout-box .integration-whatsapp-checkout-box-form .primary-card .card-box img.cc-img {
              width: 48px; }
          .integration-whatsapp-checkout .integration-whatsapp-checkout-box .integration-whatsapp-checkout-box-form .primary-card .card-box .card-info {
            line-height: 1; }
            .integration-whatsapp-checkout .integration-whatsapp-checkout-box .integration-whatsapp-checkout-box-form .primary-card .card-box .card-info p {
              margin-top: 6px;
              line-height: 1; }
            .integration-whatsapp-checkout .integration-whatsapp-checkout-box .integration-whatsapp-checkout-box-form .primary-card .card-box .card-info .card-top-info {
              font-size: 16px;
              font-size: 24px;
              letter-spacing: -0.6px; }
              .integration-whatsapp-checkout .integration-whatsapp-checkout-box .integration-whatsapp-checkout-box-form .primary-card .card-box .card-info .card-top-info .masking-dot {
                vertical-align: top;
                width: 4px;
                height: 4px;
                position: relative;
                top: 3px;
                display: inline-block;
                margin: 0 2px;
                border-radius: 50%;
                background: #191919; }
            .integration-whatsapp-checkout .integration-whatsapp-checkout-box .integration-whatsapp-checkout-box-form .primary-card .card-box .card-info .card-footer-info {
              font-size: 16px;
              letter-spacing: -0.3px;
              line-height: 1; }
        .integration-whatsapp-checkout .integration-whatsapp-checkout-box .integration-whatsapp-checkout-box-form .primary-card button {
          position: absolute;
          right: 0;
          top: 0;
          height: 40px;
          padding: 0 24px; }
        .integration-whatsapp-checkout .integration-whatsapp-checkout-box .integration-whatsapp-checkout-box-form .primary-card.no-border-bottom {
          border-bottom: 0; }
        .integration-whatsapp-checkout .integration-whatsapp-checkout-box .integration-whatsapp-checkout-box-form .primary-card .annualy-selection-wrapper {
          position: absolute;
          right: 0;
          top: 0; }
          .integration-whatsapp-checkout .integration-whatsapp-checkout-box .integration-whatsapp-checkout-box-form .primary-card .annualy-selection-wrapper b {
            color: #ff7d00;
            margin-right: 12px;
            position: relative;
            top: 1px; }
          .integration-whatsapp-checkout .integration-whatsapp-checkout-box .integration-whatsapp-checkout-box-form .primary-card .annualy-selection-wrapper .custom-switch-wrapper {
            position: relative;
            width: 48px;
            height: 29px; }
            .integration-whatsapp-checkout .integration-whatsapp-checkout-box .integration-whatsapp-checkout-box-form .primary-card .annualy-selection-wrapper .custom-switch-wrapper .custom-switch.round:before {
              width: 16px;
              height: 16px;
              bottom: 7px;
              left: 7px; }
            .integration-whatsapp-checkout .integration-whatsapp-checkout-box .integration-whatsapp-checkout-box-form .primary-card .annualy-selection-wrapper .custom-switch-wrapper input:checked + .custom-switch:before {
              -webkit-transform: translateX(21px);
                      transform: translateX(21px); }
            .integration-whatsapp-checkout .integration-whatsapp-checkout-box .integration-whatsapp-checkout-box-form .primary-card .annualy-selection-wrapper .custom-switch-wrapper b {
              font-size: 16px;
              letter-spacing: -0.5px; }
  .integration-whatsapp-checkout .payment-button {
    width: 235px;
    height: 40px;
    position: relative; }
    .integration-whatsapp-checkout .payment-button svg {
      margin-right: 5px;
      position: relative;
      top: -2px; }
    .integration-whatsapp-checkout .payment-button.verify-payment {
      width: 180px; }

.integration-whatsapp-verify {
  padding: 32px 30px;
  text-align: left; }
  .integration-whatsapp-verify .comp-back-button {
    margin: 0 0 16px 0; }
  .integration-whatsapp-verify .integration-detail-loading-wrapper .lds-ring {
    width: 50px;
    height: 50px;
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    right: unset; }
    .integration-whatsapp-verify .integration-detail-loading-wrapper .lds-ring div {
      border: 5px solid #fff;
      border-color: #ff7d00 transparent transparent transparent;
      width: 50px;
      height: 50px;
      margin: 0; }
  .integration-whatsapp-verify .integration-whatsapp-verify-box {
    background: #ffffff;
    border-radius: 8px;
    padding: 32px;
    border: solid 1px rgba(25, 25, 25, 0.1); }
    .integration-whatsapp-verify .integration-whatsapp-verify-box .integration-whatsapp-verify-box-top {
      padding-bottom: 15px;
      border-bottom: solid 1px #dcdcdc;
      position: relative;
      font-size: 20px;
      letter-spacing: -0.6px;
      margin-bottom: 12px; }
      .integration-whatsapp-verify .integration-whatsapp-verify-box .integration-whatsapp-verify-box-top h1 {
        font-size: 20px;
        margin: 0; }
    .integration-whatsapp-verify .integration-whatsapp-verify-box .integration-whatsapp-checkout-box-form {
      width: 100%; }
      .integration-whatsapp-verify .integration-whatsapp-verify-box .integration-whatsapp-checkout-box-form .verify-card-iframe {
        width: 100%;
        height: 360px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: solid 1px #dcdcdc;
        border-radius: 8px; }
        .integration-whatsapp-verify .integration-whatsapp-verify-box .integration-whatsapp-checkout-box-form .verify-card-iframe .iframe-card-wrapper {
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          position: relative; }
          .integration-whatsapp-verify .integration-whatsapp-verify-box .integration-whatsapp-checkout-box-form .verify-card-iframe .iframe-card-wrapper .lds-ring {
            position: absolute;
            left: 50%;
            top: 50%;
            margin: 0;
            right: unset;
            width: 64px;
            height: 64px;
            -webkit-transform: translate(-50%, -50%);
                    transform: translate(-50%, -50%); }
            .integration-whatsapp-verify .integration-whatsapp-verify-box .integration-whatsapp-checkout-box-form .verify-card-iframe .iframe-card-wrapper .lds-ring div {
              margin: 0;
              width: 64px;
              height: 64px; }
          .integration-whatsapp-verify .integration-whatsapp-verify-box .integration-whatsapp-checkout-box-form .verify-card-iframe .iframe-card-wrapper iframe {
            width: 100%;
            height: 100%; }
        .integration-whatsapp-verify .integration-whatsapp-verify-box .integration-whatsapp-checkout-box-form .verify-card-iframe .loading-add-card-wrapper {
          position: absolute;
          width: 100%;
          text-align: center;
          left: 0;
          top: 50%;
          -webkit-transform: translate(0, -50%);
                  transform: translate(0, -50%);
          color: #ff7d00;
          font-size: 16px;
          letter-spacing: -0.6px; }
          .integration-whatsapp-verify .integration-whatsapp-verify-box .integration-whatsapp-checkout-box-form .verify-card-iframe .loading-add-card-wrapper .LoadingRoll_1234fed {
            height: unset;
            margin-bottom: 24px; }
            .integration-whatsapp-verify .integration-whatsapp-verify-box .integration-whatsapp-checkout-box-form .verify-card-iframe .loading-add-card-wrapper .LoadingRoll_1234fed .lds-ring {
              width: 64px;
              height: 64px;
              position: relative;
              top: unset;
              left: unset;
              -webkit-transform: unset;
                      transform: unset; }
              .integration-whatsapp-verify .integration-whatsapp-verify-box .integration-whatsapp-checkout-box-form .verify-card-iframe .loading-add-card-wrapper .LoadingRoll_1234fed .lds-ring div {
                border: solid 4px #ff7d00;
                border-color: #ff7d00 transparent transparent transparent;
                width: 64px;
                height: 64px; }
          .integration-whatsapp-verify .integration-whatsapp-verify-box .integration-whatsapp-checkout-box-form .verify-card-iframe .loading-add-card-wrapper b {
            font-size: 18px;
            color: #ff7d00 !important; }
        .integration-whatsapp-verify .integration-whatsapp-verify-box .integration-whatsapp-checkout-box-form .verify-card-iframe b {
          font-size: 40px;
          color: rgba(25, 25, 25, 0.4); }

.integration-whatsapp-edit-account {
  padding: 32px 30px;
  text-align: left; }
  .integration-whatsapp-edit-account .comp-back-button {
    margin: 0 0 16px 0; }
  .integration-whatsapp-edit-account .integration-whatsapp-edit-account-box {
    background: #ffffff;
    border-radius: 8px;
    padding: 32px;
    border: solid 1px rgba(25, 25, 25, 0.1); }
    .integration-whatsapp-edit-account .integration-whatsapp-edit-account-box .integration-whatsapp-edit-account-box-top {
      padding-bottom: 15px;
      border-bottom: solid 1px #dcdcdc;
      position: relative;
      font-size: 20px;
      letter-spacing: -0.6px; }
      .integration-whatsapp-edit-account .integration-whatsapp-edit-account-box .integration-whatsapp-edit-account-box-top button {
        position: absolute;
        right: 0;
        font-size: 16px; }
    .integration-whatsapp-edit-account .integration-whatsapp-edit-account-box .integration-whatsapp-edit-account-box-form {
      width: 575px; }
      .integration-whatsapp-edit-account .integration-whatsapp-edit-account-box .integration-whatsapp-edit-account-box-form label {
        padding: 24px 0 0;
        font-size: 14px;
        letter-spacing: -0.4px;
        display: block;
        position: relative; }
        .integration-whatsapp-edit-account .integration-whatsapp-edit-account-box .integration-whatsapp-edit-account-box-form label span {
          position: absolute;
          right: 0; }
      .integration-whatsapp-edit-account .integration-whatsapp-edit-account-box .integration-whatsapp-edit-account-box-form input[type="text"] {
        padding: 12px 16px;
        border-radius: 6px;
        border: solid 1px #dcdcdc;
        width: 100%;
        font-size: 16px;
        letter-spacing: -0.3px;
        display: block;
        margin-bottom: 8px; }
      .integration-whatsapp-edit-account .integration-whatsapp-edit-account-box .integration-whatsapp-edit-account-box-form .integration-info-form {
        font-size: 14px;
        color: rgba(25, 25, 25, 0.8);
        letter-spacing: -0.2px; }
      .integration-whatsapp-edit-account .integration-whatsapp-edit-account-box .integration-whatsapp-edit-account-box-form .custom-select-whatsapp-topic .custom-select-option-value-wrapper {
        padding: 12px 16px !important;
        margin-bottom: 8px; }

.integration-whatsapp-edit-topic-account {
  padding: 32px 30px;
  text-align: left; }
  .integration-whatsapp-edit-topic-account .comp-back-button {
    margin: 0 0 16px 0;
    font-family: PTRootBold; }
  .integration-whatsapp-edit-topic-account .integration-whatsapp-edit-topic-account-box {
    background: #ffffff;
    border-radius: 8px;
    padding: 32px;
    border: solid 1px rgba(25, 25, 25, 0.1); }
    .integration-whatsapp-edit-topic-account .integration-whatsapp-edit-topic-account-box .custom-checkbox label {
      width: unset !important;
      padding: 0 0 0 24px !important; }
    .integration-whatsapp-edit-topic-account .integration-whatsapp-edit-topic-account-box .integration-whatsapp-edit-topic-account-box-top {
      padding-bottom: 15px;
      border-bottom: solid 1px #dcdcdc;
      position: relative;
      font-size: 20px;
      letter-spacing: -0.6px; }
      .integration-whatsapp-edit-topic-account .integration-whatsapp-edit-topic-account-box .integration-whatsapp-edit-topic-account-box-top button {
        position: absolute;
        top: -6px;
        right: 0;
        font-size: 16px;
        letter-spacing: -0.6px; }
    .integration-whatsapp-edit-topic-account .integration-whatsapp-edit-topic-account-box .integration-whatsapp-edit-topic-account-box-form {
      width: 100%; }
      .integration-whatsapp-edit-topic-account .integration-whatsapp-edit-topic-account-box .integration-whatsapp-edit-topic-account-box-form label {
        padding: 32px 0 0;
        margin: 0 0 8px 0;
        font-size: 14px;
        letter-spacing: -0.4px;
        display: block;
        position: relative;
        width: 300px; }
        .integration-whatsapp-edit-topic-account .integration-whatsapp-edit-topic-account-box .integration-whatsapp-edit-topic-account-box-form label span {
          font-size: 14px;
          letter-spacing: -0.2px;
          position: absolute;
          bottom: 0;
          right: 0; }
      .integration-whatsapp-edit-topic-account .integration-whatsapp-edit-topic-account-box .integration-whatsapp-edit-topic-account-box-form .phone-input {
        position: relative; }
        .integration-whatsapp-edit-topic-account .integration-whatsapp-edit-topic-account-box .integration-whatsapp-edit-topic-account-box-form .phone-input span {
          position: absolute;
          top: 12px;
          left: 16px; }
        .integration-whatsapp-edit-topic-account .integration-whatsapp-edit-topic-account-box .integration-whatsapp-edit-topic-account-box-form .phone-input input {
          padding: 12px 16px 12px 26px !important; }
        .integration-whatsapp-edit-topic-account .integration-whatsapp-edit-topic-account-box .integration-whatsapp-edit-topic-account-box-form .phone-input .alert-wrapper {
          display: flex;
          margin-top: 4px;
          width: 300px; }
          .integration-whatsapp-edit-topic-account .integration-whatsapp-edit-topic-account-box .integration-whatsapp-edit-topic-account-box-form .phone-input .alert-wrapper svg {
            margin-right: 4px;
            font-size: 20px;
            position: relative;
            top: -2px; }
          .integration-whatsapp-edit-topic-account .integration-whatsapp-edit-topic-account-box .integration-whatsapp-edit-topic-account-box-form .phone-input .alert-wrapper p {
            font-size: 12px;
            margin: 0; }
      .integration-whatsapp-edit-topic-account .integration-whatsapp-edit-topic-account-box .integration-whatsapp-edit-topic-account-box-form textarea {
        width: 300px; }
      .integration-whatsapp-edit-topic-account .integration-whatsapp-edit-topic-account-box .integration-whatsapp-edit-topic-account-box-form input[type='text'] {
        padding: 12px 16px;
        border-radius: 6px;
        border: solid 1px #dcdcdc;
        width: 100%;
        font-size: 16px;
        letter-spacing: -0.3px;
        display: block; }
      .integration-whatsapp-edit-topic-account .integration-whatsapp-edit-topic-account-box .integration-whatsapp-edit-topic-account-box-form .form-topic {
        width: 100%; }
        .integration-whatsapp-edit-topic-account .integration-whatsapp-edit-topic-account-box .integration-whatsapp-edit-topic-account-box-form .form-topic input[type="text"] {
          width: 300px; }
        .integration-whatsapp-edit-topic-account .integration-whatsapp-edit-topic-account-box .integration-whatsapp-edit-topic-account-box-form .form-topic .switch-channel-button {
          position: relative; }
          .integration-whatsapp-edit-topic-account .integration-whatsapp-edit-topic-account-box .integration-whatsapp-edit-topic-account-box-form .form-topic .switch-channel-button label {
            margin: 0; }
          .integration-whatsapp-edit-topic-account .integration-whatsapp-edit-topic-account-box .integration-whatsapp-edit-topic-account-box-form .form-topic .switch-channel-button .custom-switch-wrapper {
            position: absolute;
            width: 48px;
            height: 29px;
            right: 0;
            bottom: 20px; }
            .integration-whatsapp-edit-topic-account .integration-whatsapp-edit-topic-account-box .integration-whatsapp-edit-topic-account-box-form .form-topic .switch-channel-button .custom-switch-wrapper .custom-switch.round:before {
              width: 16px;
              height: 16px;
              bottom: 8px;
              left: 7px; }
            .integration-whatsapp-edit-topic-account .integration-whatsapp-edit-topic-account-box .integration-whatsapp-edit-topic-account-box-form .form-topic .switch-channel-button .custom-switch-wrapper input:checked + .custom-switch:before {
              -webkit-transform: translateX(19px);
                      transform: translateX(19px); }
            .integration-whatsapp-edit-topic-account .integration-whatsapp-edit-topic-account-box .integration-whatsapp-edit-topic-account-box-form .form-topic .switch-channel-button .custom-switch-wrapper b {
              font-size: 16px;
              letter-spacing: -0.5px; }
      .integration-whatsapp-edit-topic-account .integration-whatsapp-edit-topic-account-box .integration-whatsapp-edit-topic-account-box-form input[type='text']:disabled {
        background: rgba(25, 25, 25, 0.1);
        color: rgba(25, 25, 25, 0.2);
        border: none;
        cursor: not-allowed; }
      .integration-whatsapp-edit-topic-account .integration-whatsapp-edit-topic-account-box .integration-whatsapp-edit-topic-account-box-form .integration-info-form {
        font-size: 14px;
        color: rgba(25, 25, 25, 0.8);
        letter-spacing: -0.2px; }
      .integration-whatsapp-edit-topic-account .integration-whatsapp-edit-topic-account-box .integration-whatsapp-edit-topic-account-box-form .custom-select-whatsapp-topic .custom-select-option-value-wrapper {
        padding: 12px 16px !important;
        margin-bottom: 8px; }

.integration-whatsapp-detail-account {
  padding: 32px 30px;
  text-align: left; }
  .integration-whatsapp-detail-account .topic-val {
    max-width: 320px;
    color: #191919 !important;
    margin: 6px 0 16px 0 !important;
    font-size: 16px !important; }
  .integration-whatsapp-detail-account .comp-back-button {
    margin: 0 0 16px 0; }
  .integration-whatsapp-detail-account .integration-whatsapp-detail-account-box-1 {
    background: #ffffff;
    border-radius: 8px;
    padding: 22px 32px 32px;
    border: solid 1px rgba(25, 25, 25, 0.1);
    position: relative; }
    .integration-whatsapp-detail-account .integration-whatsapp-detail-account-box-1.box-channel-button {
      margin-top: 24px; }
    .integration-whatsapp-detail-account .integration-whatsapp-detail-account-box-1 .whatsapp-detail-channel-button .channel-button-wrapper {
      margin-top: 24px;
      width: 300px;
      word-break: break-word; }
    .integration-whatsapp-detail-account .integration-whatsapp-detail-account-box-1 .status-wrapper .status-value {
      color: rgba(25, 25, 25, 0.4); }
      .integration-whatsapp-detail-account .integration-whatsapp-detail-account-box-1 .status-wrapper .status-value.status-enabled {
        color: #19C700; }
    .integration-whatsapp-detail-account .integration-whatsapp-detail-account-box-1 .status-wrapper img {
      position: relative;
      top: -1px;
      margin-right: 4px; }
    .integration-whatsapp-detail-account .integration-whatsapp-detail-account-box-1 .integration-whatsapp-detail-account-box-1-top {
      padding: 10px 0 15px;
      border-bottom: solid 1px #dcdcdc;
      position: relative;
      font-size: 20px;
      letter-spacing: -0.6px;
      padding-right: 310px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis; }
      .integration-whatsapp-detail-account .integration-whatsapp-detail-account-box-1 .integration-whatsapp-detail-account-box-1-top .bot-name {
        margin-bottom: 0; }
      .integration-whatsapp-detail-account .integration-whatsapp-detail-account-box-1 .integration-whatsapp-detail-account-box-1-top p {
        font-size: 14px;
        letter-spacing: -0.2px; }
      .integration-whatsapp-detail-account .integration-whatsapp-detail-account-box-1 .integration-whatsapp-detail-account-box-1-top .integration-whatsapp-detail-account-box-1-top-button {
        position: absolute;
        top: 0px;
        right: 0;
        display: inline-block; }
        .integration-whatsapp-detail-account .integration-whatsapp-detail-account-box-1 .integration-whatsapp-detail-account-box-1-top .integration-whatsapp-detail-account-box-1-top-button button {
          padding: 7px 24px;
          font-size: 16px;
          letter-spacing: -0.6px;
          height: 40px;
          margin-left: 8px; }
          .integration-whatsapp-detail-account .integration-whatsapp-detail-account-box-1 .integration-whatsapp-detail-account-box-1-top .integration-whatsapp-detail-account-box-1-top-button button.button-channel-detail {
            position: relative;
            top: 15px; }
          .integration-whatsapp-detail-account .integration-whatsapp-detail-account-box-1 .integration-whatsapp-detail-account-box-1-top .integration-whatsapp-detail-account-box-1-top-button button svg {
            position: relative;
            top: -2px;
            margin-right: 4px; }
          .integration-whatsapp-detail-account .integration-whatsapp-detail-account-box-1 .integration-whatsapp-detail-account-box-1-top .integration-whatsapp-detail-account-box-1-top-button button .lds-ring {
            top: unset;
            right: unset;
            position: relative;
            top: 3px; }
            .integration-whatsapp-detail-account .integration-whatsapp-detail-account-box-1 .integration-whatsapp-detail-account-box-1-top .integration-whatsapp-detail-account-box-1-top-button button .lds-ring div {
              margin: 0;
              border-color: #ffffff transparent transparent transparent; }
    .integration-whatsapp-detail-account .integration-whatsapp-detail-account-box-1 .integration-whatsapp-detail-account-box-1-bottom {
      padding-top: 24px;
      position: relative;
      font-size: 20px;
      letter-spacing: -0.6px; }
      .integration-whatsapp-detail-account .integration-whatsapp-detail-account-box-1 .integration-whatsapp-detail-account-box-1-bottom label {
        font-size: 14px;
        letter-spacing: -0.4px;
        margin: 0; }
      .integration-whatsapp-detail-account .integration-whatsapp-detail-account-box-1 .integration-whatsapp-detail-account-box-1-bottom p {
        color: #ff8d00;
        margin: 4px 0 0;
        font-size: 15px;
        letter-spacing: -0.5px; }
        .integration-whatsapp-detail-account .integration-whatsapp-detail-account-box-1 .integration-whatsapp-detail-account-box-1-bottom p svg {
          margin-right: 4px;
          position: relative;
          top: -2px;
          font-size: 20px; }
      .integration-whatsapp-detail-account .integration-whatsapp-detail-account-box-1 .integration-whatsapp-detail-account-box-1-bottom .integration-whatsapp-detail-account-box-1-bottom-button {
        display: block; }
        .integration-whatsapp-detail-account .integration-whatsapp-detail-account-box-1 .integration-whatsapp-detail-account-box-1-bottom .integration-whatsapp-detail-account-box-1-bottom-button button {
          padding: 7px 24px 7px 20px;
          font-size: 16px;
          letter-spacing: -0.6px;
          height: 40px;
          margin-right: 8px;
          margin-top: 16px; }
          .integration-whatsapp-detail-account .integration-whatsapp-detail-account-box-1 .integration-whatsapp-detail-account-box-1-bottom .integration-whatsapp-detail-account-box-1-bottom-button button svg {
            position: relative;
            top: -2px;
            margin-right: 4px; }
          .integration-whatsapp-detail-account .integration-whatsapp-detail-account-box-1 .integration-whatsapp-detail-account-box-1-bottom .integration-whatsapp-detail-account-box-1-bottom-button button img {
            position: relative;
            top: -2px;
            margin-right: 4px; }
          .integration-whatsapp-detail-account .integration-whatsapp-detail-account-box-1 .integration-whatsapp-detail-account-box-1-bottom .integration-whatsapp-detail-account-box-1-bottom-button button .lds-ring {
            top: unset;
            right: unset;
            position: relative;
            top: 3px; }
            .integration-whatsapp-detail-account .integration-whatsapp-detail-account-box-1 .integration-whatsapp-detail-account-box-1-bottom .integration-whatsapp-detail-account-box-1-bottom-button button .lds-ring div {
              margin: 0;
              border-color: #ffffff transparent transparent transparent; }
      .integration-whatsapp-detail-account .integration-whatsapp-detail-account-box-1 .integration-whatsapp-detail-account-box-1-bottom .integration-whatsapp-detail-account-box-1-top-authenticated {
        color: #19c700; }
        .integration-whatsapp-detail-account .integration-whatsapp-detail-account-box-1 .integration-whatsapp-detail-account-box-1-bottom .integration-whatsapp-detail-account-box-1-top-authenticated img {
          position: relative;
          top: -2px;
          margin-right: 4px; }
      .integration-whatsapp-detail-account .integration-whatsapp-detail-account-box-1 .integration-whatsapp-detail-account-box-1-bottom .iframe-space {
        width: 100%;
        margin-top: 24px; }
        .integration-whatsapp-detail-account .integration-whatsapp-detail-account-box-1 .integration-whatsapp-detail-account-box-1-bottom .iframe-space iframe {
          width: 100%;
          border: none;
          min-height: 500px; }
      .integration-whatsapp-detail-account .integration-whatsapp-detail-account-box-1 .integration-whatsapp-detail-account-box-1-bottom .iframe-space-maytapi {
        position: relative;
        width: 100%;
        border-radius: 8px;
        border: solid 1px #dcdcdc;
        min-height: 348px;
        overflow: hidden;
        text-align: right;
        margin-top: 24px; }
        .integration-whatsapp-detail-account .integration-whatsapp-detail-account-box-1 .integration-whatsapp-detail-account-box-1-bottom .iframe-space-maytapi button {
          margin: 8px 8px 8px 0; }
          .integration-whatsapp-detail-account .integration-whatsapp-detail-account-box-1 .integration-whatsapp-detail-account-box-1-bottom .iframe-space-maytapi button .lds-ring {
            top: 50%; }
          .integration-whatsapp-detail-account .integration-whatsapp-detail-account-box-1 .integration-whatsapp-detail-account-box-1-bottom .iframe-space-maytapi button svg {
            top: -1.5px; }
        .integration-whatsapp-detail-account .integration-whatsapp-detail-account-box-1 .integration-whatsapp-detail-account-box-1-bottom .iframe-space-maytapi .iframe-space-maytapi-content {
          width: 100%;
          min-height: 278px;
          background: rgba(25, 25, 25, 0.1);
          font-size: 16px;
          letter-spacing: -0.5px;
          text-align: center;
          position: relative; }
          .integration-whatsapp-detail-account .integration-whatsapp-detail-account-box-1 .integration-whatsapp-detail-account-box-1-bottom .iframe-space-maytapi .iframe-space-maytapi-content img {
            width: 100%; }
          .integration-whatsapp-detail-account .integration-whatsapp-detail-account-box-1 .integration-whatsapp-detail-account-box-1-bottom .iframe-space-maytapi .iframe-space-maytapi-content p {
            color: rgba(25, 25, 25, 0.4);
            padding: 94px 0 0;
            margin: 0; }
          .integration-whatsapp-detail-account .integration-whatsapp-detail-account-box-1 .integration-whatsapp-detail-account-box-1-bottom .iframe-space-maytapi .iframe-space-maytapi-content .loading-maytapi {
            position: absolute;
            top: 50%;
            left: 50%;
            -webkit-transform: translate(-50%, -50%);
                    transform: translate(-50%, -50%);
            width: 100%; }
            .integration-whatsapp-detail-account .integration-whatsapp-detail-account-box-1 .integration-whatsapp-detail-account-box-1-bottom .iframe-space-maytapi .iframe-space-maytapi-content .loading-maytapi .lds-ring {
              width: 50px;
              position: absolute;
              top: 50%;
              left: 50%;
              -webkit-transform: translate(-50%, -50%);
                      transform: translate(-50%, -50%);
              height: 50px; }
              .integration-whatsapp-detail-account .integration-whatsapp-detail-account-box-1 .integration-whatsapp-detail-account-box-1-bottom .iframe-space-maytapi .iframe-space-maytapi-content .loading-maytapi .lds-ring div {
                width: 50px;
                height: 50px;
                border: solid 4px;
                border-color: #ff7e00 transparent transparent transparent; }
      .integration-whatsapp-detail-account .integration-whatsapp-detail-account-box-1 .integration-whatsapp-detail-account-box-1-bottom .not-paid {
        font-size: 16px;
        letter-spacing: -0.5px;
        color: #ff3f57;
        margin: 0; }
        .integration-whatsapp-detail-account .integration-whatsapp-detail-account-box-1 .integration-whatsapp-detail-account-box-1-bottom .not-paid img {
          vertical-align: top;
          position: relative;
          margin-right: 4px;
          top: 3px; }
      .integration-whatsapp-detail-account .integration-whatsapp-detail-account-box-1 .integration-whatsapp-detail-account-box-1-bottom .waiting-setup {
        color: #ff7d00;
        font-size: 16px;
        letter-spacing: -0.5px;
        margin: 0; }
      .integration-whatsapp-detail-account .integration-whatsapp-detail-account-box-1 .integration-whatsapp-detail-account-box-1-bottom .error-message {
        font-size: 14px;
        letter-spacing: -0.2px;
        margin: 4px 0 0;
        display: block; }
  .integration-whatsapp-detail-account .integration-whatsapp-detail-account-box-2 {
    background: #ffffff;
    border-radius: 8px;
    padding: 32px;
    border: solid 1px rgba(25, 25, 25, 0.1);
    margin-top: 12px; }
    .integration-whatsapp-detail-account .integration-whatsapp-detail-account-box-2 .integration-whatsapp-detail-account-box-2-title {
      font-size: 20px;
      letter-spacing: -0.6px;
      padding-bottom: 20px;
      border-bottom: solid 1px #dcdcdc;
      display: block;
      margin: 0 0 24px; }
    .integration-whatsapp-detail-account .integration-whatsapp-detail-account-box-2 .integration-whatsapp-detail-account-box-2-payment-wrapper {
      width: 100%;
      position: relative; }
      .integration-whatsapp-detail-account .integration-whatsapp-detail-account-box-2 .integration-whatsapp-detail-account-box-2-payment-wrapper label {
        font-size: 14px;
        letter-spacing: -0.4px;
        margin: 0; }
      .integration-whatsapp-detail-account .integration-whatsapp-detail-account-box-2 .integration-whatsapp-detail-account-box-2-payment-wrapper .payment-info-wrapper {
        position: relative; }
        .integration-whatsapp-detail-account .integration-whatsapp-detail-account-box-2 .integration-whatsapp-detail-account-box-2-payment-wrapper .payment-info-wrapper .payment-until {
          font-size: 15px;
          letter-spacing: -0.5px;
          color: #19c700;
          margin: 0; }
          .integration-whatsapp-detail-account .integration-whatsapp-detail-account-box-2 .integration-whatsapp-detail-account-box-2-payment-wrapper .payment-info-wrapper .payment-until img {
            vertical-align: top;
            position: relative;
            top: 0;
            margin-right: 4px; }
        .integration-whatsapp-detail-account .integration-whatsapp-detail-account-box-2 .integration-whatsapp-detail-account-box-2-payment-wrapper .payment-info-wrapper .payment-expired {
          color: #ff3f57;
          font-size: 15px;
          letter-spacing: -0.5px;
          margin: 4px 0 0; }
          .integration-whatsapp-detail-account .integration-whatsapp-detail-account-box-2 .integration-whatsapp-detail-account-box-2-payment-wrapper .payment-info-wrapper .payment-expired svg {
            vertical-align: top;
            position: relative;
            font-size: 20px;
            margin-right: 4px; }
        .integration-whatsapp-detail-account .integration-whatsapp-detail-account-box-2 .integration-whatsapp-detail-account-box-2-payment-wrapper .payment-info-wrapper .payment-bar {
          width: 326px;
          height: 4px;
          background: rgba(25, 25, 25, 0.1);
          border-radius: 2px;
          margin: 8px 0;
          position: relative; }
          .integration-whatsapp-detail-account .integration-whatsapp-detail-account-box-2 .integration-whatsapp-detail-account-box-2-payment-wrapper .payment-info-wrapper .payment-bar div {
            position: absolute;
            left: 0;
            top: 0;
            background: #3baeff;
            height: 4px; }
        .integration-whatsapp-detail-account .integration-whatsapp-detail-account-box-2 .integration-whatsapp-detail-account-box-2-payment-wrapper .payment-info-wrapper .payment-left {
          font-size: 12px;
          letter-spacing: -0.2px;
          color: rgba(25, 25, 25, 0.4); }
        .integration-whatsapp-detail-account .integration-whatsapp-detail-account-box-2 .integration-whatsapp-detail-account-box-2-payment-wrapper .payment-info-wrapper .not-paid {
          color: rgba(25, 25, 25, 0.4);
          font-size: 16px;
          letter-spacing: -0.5px;
          margin: 0 0 22px; }
      .integration-whatsapp-detail-account .integration-whatsapp-detail-account-box-2 .integration-whatsapp-detail-account-box-2-payment-wrapper .alet-verifying {
        border-radius: 8px;
        color: #044b84;
        border: solid 1px #044b84;
        background: #dcebff;
        width: 100%;
        position: relative;
        padding: 12px 16px 12px 38px;
        font-size: 14px;
        letter-spacing: -0.4px;
        width: 323px; }
        .integration-whatsapp-detail-account .integration-whatsapp-detail-account-box-2 .integration-whatsapp-detail-account-box-2-payment-wrapper .alet-verifying svg {
          font-size: 15px;
          -webkit-transform: none;
                  transform: none;
          top: 15px; }
      .integration-whatsapp-detail-account .integration-whatsapp-detail-account-box-2 .integration-whatsapp-detail-account-box-2-payment-wrapper .how-to-make-payment {
        border-radius: 8px;
        color: #044b84;
        border: solid 1px #044b84;
        background: #dcebff;
        width: 100%;
        position: relative;
        padding: 32px; }
        .integration-whatsapp-detail-account .integration-whatsapp-detail-account-box-2 .integration-whatsapp-detail-account-box-2-payment-wrapper .how-to-make-payment .how-to-make-payment-head {
          line-height: 1;
          margin-bottom: 24px; }
          .integration-whatsapp-detail-account .integration-whatsapp-detail-account-box-2 .integration-whatsapp-detail-account-box-2-payment-wrapper .how-to-make-payment .how-to-make-payment-head svg {
            vertical-align: top;
            font-size: 24px;
            margin-right: 8px;
            position: relative;
            top: -2px; }
          .integration-whatsapp-detail-account .integration-whatsapp-detail-account-box-2 .integration-whatsapp-detail-account-box-2-payment-wrapper .how-to-make-payment .how-to-make-payment-head b {
            letter-spacing: -0.6px;
            font-size: 20px; }
        .integration-whatsapp-detail-account .integration-whatsapp-detail-account-box-2 .integration-whatsapp-detail-account-box-2-payment-wrapper .how-to-make-payment .add-on-price {
          font-size: 16px;
          letter-spacing: -0.5px;
          line-height: 1px;
          margin: 0 0 16px 0; }
        .integration-whatsapp-detail-account .integration-whatsapp-detail-account-box-2 .integration-whatsapp-detail-account-box-2-payment-wrapper .how-to-make-payment .price-tier {
          padding-right: 81px; }
          .integration-whatsapp-detail-account .integration-whatsapp-detail-account-box-2 .integration-whatsapp-detail-account-box-2-payment-wrapper .how-to-make-payment .price-tier .price-tier-card {
            background: #044b84;
            box-shadow: 0 2px 20px 0 rgba(4, 75, 132, 0.18);
            color: #ffffff;
            border-radius: 8px;
            margin: 10px 12px 24px 0;
            padding: 24px 16px 9px 16px;
            vertical-align: top;
            width: calc((100% / 3) - 8px);
            display: inline-block; }
            .integration-whatsapp-detail-account .integration-whatsapp-detail-account-box-2 .integration-whatsapp-detail-account-box-2-payment-wrapper .how-to-make-payment .price-tier .price-tier-card:nth-last-child(1) {
              margin: 10px 0 24px 0; }
            .integration-whatsapp-detail-account .integration-whatsapp-detail-account-box-2 .integration-whatsapp-detail-account-box-2-payment-wrapper .how-to-make-payment .price-tier .price-tier-card .price-tier-name {
              font-size: 14px;
              letter-spacing: -0.4px;
              display: inline-block;
              border-radius: 4px;
              line-height: 22px;
              height: 22px;
              padding: 0 8px;
              background: #ffffff;
              color: #044b84; }
            .integration-whatsapp-detail-account .integration-whatsapp-detail-account-box-2 .integration-whatsapp-detail-account-box-2-payment-wrapper .how-to-make-payment .price-tier .price-tier-card .price-tier-value {
              display: block;
              margin: 12px 0 8px;
              font-size: 20px;
              letter-spacing: -0.6px; }
            .integration-whatsapp-detail-account .integration-whatsapp-detail-account-box-2 .integration-whatsapp-detail-account-box-2-payment-wrapper .how-to-make-payment .price-tier .price-tier-card .price-tier-more-detail {
              color: #ffffff;
              font: 16px;
              letter-spacing: -0.5px; }
            .integration-whatsapp-detail-account .integration-whatsapp-detail-account-box-2 .integration-whatsapp-detail-account-box-2-payment-wrapper .how-to-make-payment .price-tier .price-tier-card svg {
              color: #ffffff;
              font-size: 24px;
              position: relative;
              top: -2px; }
        .integration-whatsapp-detail-account .integration-whatsapp-detail-account-box-2 .integration-whatsapp-detail-account-box-2-payment-wrapper .how-to-make-payment .payment-step-wrapper {
          width: 100%; }
          .integration-whatsapp-detail-account .integration-whatsapp-detail-account-box-2 .integration-whatsapp-detail-account-box-2-payment-wrapper .how-to-make-payment .payment-step-wrapper .payment-step-list {
            position: relative;
            margin: 0 0 24px;
            color: #044b84;
            padding: 0 0 0 33px; }
            .integration-whatsapp-detail-account .integration-whatsapp-detail-account-box-2 .integration-whatsapp-detail-account-box-2-payment-wrapper .how-to-make-payment .payment-step-wrapper .payment-step-list:nth-last-child(1) {
              margin: 0; }
            .integration-whatsapp-detail-account .integration-whatsapp-detail-account-box-2 .integration-whatsapp-detail-account-box-2-payment-wrapper .how-to-make-payment .payment-step-wrapper .payment-step-list a {
              color: #044b84 !important; }
            .integration-whatsapp-detail-account .integration-whatsapp-detail-account-box-2 .integration-whatsapp-detail-account-box-2-payment-wrapper .how-to-make-payment .payment-step-wrapper .payment-step-list p {
              margin: 0; }
            .integration-whatsapp-detail-account .integration-whatsapp-detail-account-box-2 .integration-whatsapp-detail-account-box-2-payment-wrapper .how-to-make-payment .payment-step-wrapper .payment-step-list .bank-account-wrapper {
              border-radius: 8px;
              font-size: 16px;
              letter-spacing: -0.5px;
              padding: 24px 24px 16px;
              color: #044b84;
              background: #ffffff;
              display: inline-block;
              margin-top: 14px; }
            .integration-whatsapp-detail-account .integration-whatsapp-detail-account-box-2 .integration-whatsapp-detail-account-box-2-payment-wrapper .how-to-make-payment .payment-step-wrapper .payment-step-list .payment-list-icon {
              background: #ffffff;
              font-size: 12px;
              letter-spacing: -0.4px;
              border-radius: 4px;
              padding: 2px 8px;
              color: #ff7d00; }
              .integration-whatsapp-detail-account .integration-whatsapp-detail-account-box-2 .integration-whatsapp-detail-account-box-2-payment-wrapper .how-to-make-payment .payment-step-wrapper .payment-step-list .payment-list-icon.authenticated {
                color: #19c700; }
              .integration-whatsapp-detail-account .integration-whatsapp-detail-account-box-2 .integration-whatsapp-detail-account-box-2-payment-wrapper .how-to-make-payment .payment-step-wrapper .payment-step-list .payment-list-icon svg {
                position: relative;
                top: -2px; }
              .integration-whatsapp-detail-account .integration-whatsapp-detail-account-box-2 .integration-whatsapp-detail-account-box-2-payment-wrapper .how-to-make-payment .payment-step-wrapper .payment-step-list .payment-list-icon img {
                width: 12px;
                position: relative;
                top: -1px; }
          .integration-whatsapp-detail-account .integration-whatsapp-detail-account-box-2 .integration-whatsapp-detail-account-box-2-payment-wrapper .how-to-make-payment .payment-step-wrapper .payment-step-counter {
            border-radius: 50%;
            color: #ffffff;
            background: #044b84;
            width: 23px;
            height: 23px;
            text-align: center;
            line-height: 23px;
            display: inline-block;
            font-size: 14px;
            letter-spacing: -0.4px;
            position: absolute;
            top: 0;
            left: 0; }

.modal-secure-authentication .modal-content {
  border-radius: 8px;
  width: 580px; }

.modal-secure-authentication .modal-body {
  padding: 32px 32px 24px; }
  .modal-secure-authentication .modal-body .modal-secure-authentication-top {
    padding: 0 0 5px 32px;
    position: relative; }
    .modal-secure-authentication .modal-body .modal-secure-authentication-top b {
      font-size: 20px;
      letter-spacing: -0.6px;
      line-height: 20px;
      color: #191919; }
    .modal-secure-authentication .modal-body .modal-secure-authentication-top span {
      font-size: 14px;
      letter-spacing: -0.2px;
      line-height: 21px;
      color: rgba(25, 25, 25, 0.6); }
    .modal-secure-authentication .modal-body .modal-secure-authentication-top svg {
      position: absolute;
      left: 0;
      top: -1px;
      font-size: 24px; }
  .modal-secure-authentication .modal-body button {
    margin: 0 8px 0 0;
    width: calc(50% - 4px); }
    .modal-secure-authentication .modal-body button:nth-last-child(1) {
      margin: 0; }


.integration-whatsapp-authenticate-now-account {
  padding: 32px 30px;
  text-align: left; }
  .integration-whatsapp-authenticate-now-account .comp-back-button {
    margin: 0 0 16px 0; }
  .integration-whatsapp-authenticate-now-account .integration-whatsapp-authenticate-now-account-box {
    background: #ffffff;
    border-radius: 8px;
    padding: 32px;
    border: solid 1px rgba(25, 25, 25, 0.1);
    min-height: 400px;
    position: relative; }
    .integration-whatsapp-authenticate-now-account .integration-whatsapp-authenticate-now-account-box .integration-whatsapp-authenticate-now-account-box-top {
      padding-bottom: 15px;
      border-bottom: solid 1px #dcdcdc;
      position: relative;
      font-size: 20px;
      letter-spacing: -0.6px; }
  .integration-whatsapp-authenticate-now-account .authenticate-now-loading {
    text-align: center;
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
    .integration-whatsapp-authenticate-now-account .authenticate-now-loading .lds-ring {
      width: 60px;
      height: 60px;
      top: unset;
      right: unset; }
      .integration-whatsapp-authenticate-now-account .authenticate-now-loading .lds-ring div {
        width: 60px;
        height: 60px;
        margin: 0;
        border: 6px solid #fff;
        border-color: #ff7d00 transparent transparent transparent; }
    .integration-whatsapp-authenticate-now-account .authenticate-now-loading p {
      color: #ff7d00;
      font-size: 16px;
      margin: 12px 0 0;
      letter-spacing: -0.5px; }
  .integration-whatsapp-authenticate-now-account .failed-authenticated-now-refresh {
    text-align: center;
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
    .integration-whatsapp-authenticate-now-account .failed-authenticated-now-refresh svg {
      color: #ff7d00;
      font-size: 61px;
      cursor: pointer; }
      .integration-whatsapp-authenticate-now-account .failed-authenticated-now-refresh svg:hover {
        color: #e87200; }
    .integration-whatsapp-authenticate-now-account .failed-authenticated-now-refresh p {
      color: #ff7d00;
      font-size: 16px;
      margin: 12px 0 0;
      letter-spacing: -0.5px; }
  .integration-whatsapp-authenticate-now-account .main-authenticated-now-qr-scan {
    width: 100%;
    position: relative;
    padding-top: 24px; }
    .integration-whatsapp-authenticate-now-account .main-authenticated-now-qr-scan .qr-code-wrapper {
      width: 264px;
      display: inline-block;
      vertical-align: top;
      text-align: center; }
      .integration-whatsapp-authenticate-now-account .main-authenticated-now-qr-scan .qr-code-wrapper .qr-code-content {
        color: #ffffff;
        line-height: 264px;
        height: 264px;
        width: 100%;
        position: relative; }
        .integration-whatsapp-authenticate-now-account .main-authenticated-now-qr-scan .qr-code-wrapper .qr-code-content img.qr-image {
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0; }
          .integration-whatsapp-authenticate-now-account .main-authenticated-now-qr-scan .qr-code-wrapper .qr-code-content img.qr-image.qr-transparent {
            opacity: 0.3; }
        .integration-whatsapp-authenticate-now-account .main-authenticated-now-qr-scan .qr-code-wrapper .qr-code-content .lds-ring {
          width: 65px;
          height: 65px;
          top: 50%;
          right: unset;
          position: absolute;
          left: 50%;
          -webkit-transform: translate(-50%, -50%);
                  transform: translate(-50%, -50%); }
          .integration-whatsapp-authenticate-now-account .main-authenticated-now-qr-scan .qr-code-wrapper .qr-code-content .lds-ring div {
            width: 65px;
            height: 65px;
            margin: 0;
            border: solid 5px;
            border-color: #ff7d00 transparent transparent transparent; }
        .integration-whatsapp-authenticate-now-account .main-authenticated-now-qr-scan .qr-code-wrapper .qr-code-content .qr-code-retry-wrapper {
          height: 200px;
          width: 200px;
          text-align: center;
          color: #ffffff;
          cursor: pointer;
          position: absolute;
          top: 50%;
          left: 50%;
          background: #ff7d00;
          border-radius: 50%;
          -webkit-transform: translate(-50%, -50%);
                  transform: translate(-50%, -50%);
          line-height: 1.5;
          padding: 45px 40px 0;
          font-size: 16px;
          letter-spacing: -0.5px; }
          .integration-whatsapp-authenticate-now-account .main-authenticated-now-qr-scan .qr-code-wrapper .qr-code-content .qr-code-retry-wrapper svg {
            font-size: 40px;
            margin: 0 0 16px; }
          .integration-whatsapp-authenticate-now-account .main-authenticated-now-qr-scan .qr-code-wrapper .qr-code-content .qr-code-retry-wrapper:hover {
            background: #e87200; }
      .integration-whatsapp-authenticate-now-account .main-authenticated-now-qr-scan .qr-code-wrapper .point-phone-here {
        font-size: 12px;
        letter-spacing: -0.4px;
        color: rgba(25, 25, 25, 0.6);
        margin: 0; }
        .integration-whatsapp-authenticate-now-account .main-authenticated-now-qr-scan .qr-code-wrapper .point-phone-here img {
          position: relative;
          top: -1px;
          margin-right: 4px; }
    .integration-whatsapp-authenticate-now-account .main-authenticated-now-qr-scan .qr-code-instruction-wrapper {
      width: calc(100% - 264px);
      display: inline-block;
      vertical-align: top;
      padding: 25px 0 0 24px; }
      .integration-whatsapp-authenticate-now-account .main-authenticated-now-qr-scan .qr-code-instruction-wrapper .make-sure-connection {
        font-size: 12px;
        letter-spacing: -0.4px;
        color: rgba(25, 25, 25, 0.6);
        margin: 0; }
        .integration-whatsapp-authenticate-now-account .main-authenticated-now-qr-scan .qr-code-instruction-wrapper .make-sure-connection svg {
          color: rgba(25, 25, 25, 0.6);
          position: relative;
          top: -1px;
          margin-right: 4px; }
      .integration-whatsapp-authenticate-now-account .main-authenticated-now-qr-scan .qr-code-instruction-wrapper .instruction-title {
        letter-spacing: -0.6px;
        font-size: 20px;
        color: #191919;
        margin: 4px 0 22px; }
      .integration-whatsapp-authenticate-now-account .main-authenticated-now-qr-scan .qr-code-instruction-wrapper .instruction-list {
        width: 100%; }
        .integration-whatsapp-authenticate-now-account .main-authenticated-now-qr-scan .qr-code-instruction-wrapper .instruction-list p {
          font-size: 16px;
          color: #191919;
          letter-spacing: -0.3px; }
          .integration-whatsapp-authenticate-now-account .main-authenticated-now-qr-scan .qr-code-instruction-wrapper .instruction-list p span {
            background: #ff7d00;
            width: 24px;
            height: 24px;
            display: inline-block;
            text-align: center;
            color: #ffffff;
            vertical-align: top;
            line-height: 27px;
            border-radius: 50%;
            font-size: 14px;
            letter-spacing: -0.4px;
            margin-right: 8px; }

.integration-whatsapp-secure-authenticate {
  padding: 32px 30px;
  text-align: left; }
  .integration-whatsapp-secure-authenticate .integration-whatsapp-secure-authenticate-box-top {
    padding-bottom: 20px; }
  .integration-whatsapp-secure-authenticate .comp-back-button {
    margin: 0 0 16px 0; }
  .integration-whatsapp-secure-authenticate .integration-whatsapp-secure-authenticate-box {
    background: #ffffff;
    border-radius: 8px;
    padding: 32px;
    border: solid 1px rgba(25, 25, 25, 0.1);
    min-height: 400px;
    position: relative; }
    .integration-whatsapp-secure-authenticate .integration-whatsapp-secure-authenticate-box .main-loading-wrapper {
      width: 100%;
      text-align: center;
      padding-top: 40px; }
    .integration-whatsapp-secure-authenticate .integration-whatsapp-secure-authenticate-box .alert-component {
      margin: 0 12px 0 0;
      width: 100%;
      padding: 16px; }
      .integration-whatsapp-secure-authenticate .integration-whatsapp-secure-authenticate-box .alert-component svg {
        position: relative;
        top: -2px;
        -webkit-transform: unset;
                transform: unset;
        left: unset;
        margin-right: 8px; }
    .integration-whatsapp-secure-authenticate .integration-whatsapp-secure-authenticate-box .button-refresh {
      width: 185px;
      display: inline-block;
      height: 48px;
      letter-spacing: -0.5px;
      font-size: 16px; }
      .integration-whatsapp-secure-authenticate .integration-whatsapp-secure-authenticate-box .button-refresh svg {
        font-size: 20px;
        margin-right: 4px;
        position: relative;
        top: -1px; }
    .integration-whatsapp-secure-authenticate .integration-whatsapp-secure-authenticate-box .payment-step-list {
      position: relative;
      margin: 22px 0 0;
      color: #044b84;
      padding: 0 0 0 33px;
      letter-spacing: -0.3px; }
      .integration-whatsapp-secure-authenticate .integration-whatsapp-secure-authenticate-box .payment-step-list p {
        margin: 0; }
      .integration-whatsapp-secure-authenticate .integration-whatsapp-secure-authenticate-box .payment-step-list .bank-account-wrapper {
        border-radius: 8px;
        font-size: 16px;
        letter-spacing: -0.5px;
        padding: 24px 24px 16px;
        color: #044b84;
        background: #ffffff;
        display: inline-block;
        margin-top: 14px; }
    .integration-whatsapp-secure-authenticate .integration-whatsapp-secure-authenticate-box .payment-step-counter {
      border-radius: 50%;
      color: #ffffff;
      background: #044b84;
      width: 23px;
      height: 23px;
      text-align: center;
      line-height: 23px;
      display: inline-block;
      font-size: 14px;
      letter-spacing: -0.4px;
      position: absolute;
      top: 0;
      left: 0; }
  .integration-whatsapp-secure-authenticate .integration-whatsapp-secure-authenticate-box {
    width: 100%;
    position: relative;
    padding-top: 24px; }
    .integration-whatsapp-secure-authenticate .integration-whatsapp-secure-authenticate-box .iframe-space {
      width: 100%;
      margin-top: 24px;
      background: #E0E0E0;
      min-height: 500px; }
      .integration-whatsapp-secure-authenticate .integration-whatsapp-secure-authenticate-box .iframe-space iframe {
        width: 100%;
        border: none;
        min-height: 500px; }

.chatbot-add-chatbot {
  padding: 32px 30px;
  text-align: left; }
  .chatbot-add-chatbot .comp-back-button {
    margin: 0 0 16px 0; }
  .chatbot-add-chatbot .chatbot-add-chatbot-box {
    background: #ffffff;
    border-radius: 8px;
    padding: 32px;
    border: solid 1px rgba(25, 25, 25, 0.1); }
    .chatbot-add-chatbot .chatbot-add-chatbot-box .chatbot-add-chatbot-box-top {
      padding-bottom: 15px;
      border-bottom: solid 1px #dcdcdc;
      position: relative;
      font-size: 20px;
      letter-spacing: -0.6px; }
      .chatbot-add-chatbot .chatbot-add-chatbot-box .chatbot-add-chatbot-box-top .chat-bot-chatbot-button-wrapper {
        margin: 0; }
      .chatbot-add-chatbot .chatbot-add-chatbot-box .chatbot-add-chatbot-box-top button {
        position: absolute;
        top: -6px;
        right: 0;
        margin: 0 !important;
        font-size: 16px;
        letter-spacing: -0.6px; }
    .chatbot-add-chatbot .chatbot-add-chatbot-box .chatbot-add-chatbot-box-form {
      width: 320px; }
      .chatbot-add-chatbot .chatbot-add-chatbot-box .chatbot-add-chatbot-box-form label {
        padding: 24px 0 0;
        font-size: 14px;
        letter-spacing: -0.4px;
        display: block; }
      .chatbot-add-chatbot .chatbot-add-chatbot-box .chatbot-add-chatbot-box-form input[type="text"] {
        padding: 12px 16px;
        border-radius: 6px;
        border: solid 1px #dcdcdc;
        width: 100%;
        font-size: 16px;
        letter-spacing: -0.3px;
        display: block;
        margin-bottom: 8px; }
      .chatbot-add-chatbot .chatbot-add-chatbot-box .chatbot-add-chatbot-box-form .integration-info-form {
        font-size: 14px;
        color: rgba(25, 25, 25, 0.8);
        letter-spacing: -0.2px; }
      .chatbot-add-chatbot .chatbot-add-chatbot-box .chatbot-add-chatbot-box-form .custom-select-path .custom-select-option-value-wrapper {
        padding: 12px 16px !important;
        margin-bottom: 8px; }
    .chatbot-add-chatbot .chatbot-add-chatbot-box .chat-bot-chatbot-button-wrapper {
      margin-top: 18px; }
      .chatbot-add-chatbot .chatbot-add-chatbot-box .chat-bot-chatbot-button-wrapper button {
        margin: 0 4px 0 0; }

.integration-telegram-list {
  text-align: left; }
  .integration-telegram-list .integration-list-loading-wrapper .lds-ring {
    width: 50px;
    height: 50px;
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    right: unset; }
    .integration-telegram-list .integration-list-loading-wrapper .lds-ring div {
      border: 5px solid #fff;
      border-color: #ff7d00 transparent transparent transparent;
      width: 50px;
      height: 50px;
      margin: 0; }
  .integration-telegram-list .integration-list-header {
    position: relative;
    padding-left: 42px;
    padding-bottom: 26px;
    border-bottom: solid 1px rgba(25, 25, 25, 0.1); }
    .integration-telegram-list .integration-list-header .integration-list-header-icon {
      position: absolute;
      top: 0;
      left: 0;
      width: 32px; }
    .integration-telegram-list .integration-list-header .integration-list-header-text-wrapper {
      text-align: left; }
      .integration-telegram-list .integration-list-header .integration-list-header-text-wrapper b {
        font-size: 20px;
        letter-spacing: -0.6px; }
      .integration-telegram-list .integration-list-header .integration-list-header-text-wrapper p {
        margin: 0;
        font-size: 14px;
        letter-spacing: -0.2px; }
      .integration-telegram-list .integration-list-header .integration-list-header-text-wrapper a {
        color: #ff7d00; }
    .integration-telegram-list .integration-list-header .integration-list-header-button {
      position: absolute;
      right: 0;
      top: 0;
      font-size: 16px;
      letter-spacing: -0.5px;
      padding: 7px 32px; }
      .integration-telegram-list .integration-list-header .integration-list-header-button img {
        margin-right: 4px;
        position: relative;
        top: -2px; }
  .integration-telegram-list .integration-list-no-data {
    width: 100%;
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
    .integration-telegram-list .integration-list-no-data b {
      font-size: 20px;
      letter-spacing: -0.6px;
      color: #191919; }
    .integration-telegram-list .integration-list-no-data p {
      color: rgba(25, 25, 25, 0.6);
      letter-spacing: -0.6px;
      font-size: 16px; }
  .integration-telegram-list .integration-list-outer-wrapper {
    position: relative;
    width: 100%;
    padding-top: 24px;
    text-align: left; }
    .integration-telegram-list .integration-list-outer-wrapper .integration-list-inner-wrapper {
      width: calc((100% / 3) - 8px);
      border-radius: 8px;
      margin: 0 12px 12px 0;
      box-shadow: 0 2px 20px 0 rgba(25, 25, 25, 0.05);
      background: #ffffff;
      display: inline-block;
      vertical-align: top;
      padding: 24px 30px 24px 24px;
      cursor: pointer;
      position: relative; }
      .integration-telegram-list .integration-list-outer-wrapper .integration-list-inner-wrapper p:nth-child(2) {
        font-size: 16px;
        letter-spacing: -0.5px;
        color: rgba(25, 25, 25, 0.6);
        margin: 0;
        white-space: nowrap;
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis; }
      .integration-telegram-list .integration-list-outer-wrapper .integration-list-inner-wrapper span {
        display: block;
        font-size: 12px;
        letter-spacing: -0.2px;
        color: #191919; }
      .integration-telegram-list .integration-list-outer-wrapper .integration-list-inner-wrapper p {
        margin: 10px 0 0;
        color: #ff7d00;
        font-size: 12px;
        letter-spacing: -0.2px; }
        .integration-telegram-list .integration-list-outer-wrapper .integration-list-inner-wrapper p.font-green {
          color: #19c700; }
        .integration-telegram-list .integration-list-outer-wrapper .integration-list-inner-wrapper p.font-grey {
          color: rgba(25, 25, 25, 0.4); }
      .integration-telegram-list .integration-list-outer-wrapper .integration-list-inner-wrapper svg {
        position: absolute;
        right: 12px;
        top: 12px;
        font-size: 22px;
        color: rgba(25, 25, 25, 0.6); }
      .integration-telegram-list .integration-list-outer-wrapper .integration-list-inner-wrapper:hover {
        box-shadow: 0 2px 20px 0 rgba(25, 25, 25, 0.18); }
        .integration-telegram-list .integration-list-outer-wrapper .integration-list-inner-wrapper:hover p:nth-child(2) {
          color: #191919; }
        .integration-telegram-list .integration-list-outer-wrapper .integration-list-inner-wrapper:hover svg {
          color: #ff7d00; }
  .integration-telegram-list .link-telegram-account-list:nth-child(3n) .integration-list-inner-wrapper {
    margin: 0 0 12px 0; }

.integration-telegram-add-account {
  padding: 32px 30px;
  text-align: left; }
  .integration-telegram-add-account .comp-back-button {
    margin: 0 0 16px 0;
    font-family: PTRootBold; }
  .integration-telegram-add-account .integration-telegram-add-account-box {
    background: #ffffff;
    border-radius: 8px;
    padding: 32px;
    border: solid 1px rgba(25, 25, 25, 0.1); }
    .integration-telegram-add-account .integration-telegram-add-account-box .integration-telegram-add-account-box-top {
      padding-bottom: 15px;
      border-bottom: solid 1px #dcdcdc;
      position: relative;
      font-size: 20px;
      letter-spacing: -0.6px; }
      .integration-telegram-add-account .integration-telegram-add-account-box .integration-telegram-add-account-box-top button {
        position: absolute;
        top: -6px;
        right: 0;
        padding: 7px 24px;
        font-size: 16px;
        letter-spacing: -0.6px;
        width: 160px;
        height: 40px; }
    .integration-telegram-add-account .integration-telegram-add-account-box .integration-telegram-add-account-box-form {
      width: 100%; }
      .integration-telegram-add-account .integration-telegram-add-account-box .integration-telegram-add-account-box-form label {
        padding: 24px 0 0;
        font-size: 14px;
        letter-spacing: -0.4px;
        display: block; }
      .integration-telegram-add-account .integration-telegram-add-account-box .integration-telegram-add-account-box-form input[type='text'] {
        padding: 12px 16px;
        border-radius: 6px;
        border: solid 1px #dcdcdc;
        width: 100%;
        font-size: 16px;
        letter-spacing: -0.3px;
        display: block; }
      .integration-telegram-add-account .integration-telegram-add-account-box .integration-telegram-add-account-box-form .form-topic {
        width: 320px; }
      .integration-telegram-add-account .integration-telegram-add-account-box .integration-telegram-add-account-box-form .form-bot-token {
        width: 100%; }
        .integration-telegram-add-account .integration-telegram-add-account-box .integration-telegram-add-account-box-form .form-bot-token label {
          display: flex;
          align-items: center; }
          .integration-telegram-add-account .integration-telegram-add-account-box .integration-telegram-add-account-box-form .form-bot-token label .bot-token-title {
            margin-right: 5px;
            margin-top: 2px; }
        .integration-telegram-add-account .integration-telegram-add-account-box .integration-telegram-add-account-box-form .form-bot-token .bot-token-wrapper {
          display: flex;
          align-items: center;
          margin-bottom: 8px; }
          .integration-telegram-add-account .integration-telegram-add-account-box .integration-telegram-add-account-box-form .form-bot-token .bot-token-wrapper.border-red {
            border: solid 1px #ff3f57 !important; }
          .integration-telegram-add-account .integration-telegram-add-account-box .integration-telegram-add-account-box-form .form-bot-token .bot-token-wrapper input[type='text'] {
            width: 721px;
            display: inline-block;
            border-radius: 6px 0 0 6px;
            border: none;
            margin-bottom: 0;
            -webkit-transition: ease-in-out 0.3s, width 0s;
            transition: ease-in-out 0.3s, width 0s; }
          .integration-telegram-add-account .integration-telegram-add-account-box .integration-telegram-add-account-box-form .form-bot-token .bot-token-wrapper input[type='text']:disabled {
            border-right: none !important; }
          .integration-telegram-add-account .integration-telegram-add-account-box .integration-telegram-add-account-box-form .form-bot-token .bot-token-wrapper button {
            padding: 12px 16px;
            border-radius: 0 6px 6px 0;
            border: solid 1px #dcdcdc;
            font-size: 16px;
            letter-spacing: -0.3px;
            color: rgba(25, 25, 25, 0.2);
            background-color: transparent;
            padding: 12px 24px;
            font-family: PTRootBold; }
            .integration-telegram-add-account .integration-telegram-add-account-box .integration-telegram-add-account-box-form .form-bot-token .bot-token-wrapper button .valid {
              display: flex;
              align-items: center; }
              .integration-telegram-add-account .integration-telegram-add-account-box .integration-telegram-add-account-box-form .form-bot-token .bot-token-wrapper button .valid .valid-green {
                width: 18px;
                height: 13px;
                background: url(/static/media/icon-valid.9c255a92.svg);
                margin-right: 7px; }
              .integration-telegram-add-account .integration-telegram-add-account-box .integration-telegram-add-account-box-form .form-bot-token .bot-token-wrapper button .valid .text-green {
                color: #19c700; }
              .integration-telegram-add-account .integration-telegram-add-account-box .integration-telegram-add-account-box-form .form-bot-token .bot-token-wrapper button .valid .valid-grey {
                width: 18px;
                height: 13px;
                background: url(/static/media/icon-valid-grey.acab4116.svg);
                margin-right: 7px; }
              .integration-telegram-add-account .integration-telegram-add-account-box .integration-telegram-add-account-box-form .form-bot-token .bot-token-wrapper button .valid .text-grey {
                color: rgba(25, 25, 25, 0.2); }
            .integration-telegram-add-account .integration-telegram-add-account-box .integration-telegram-add-account-box-form .form-bot-token .bot-token-wrapper button .validating {
              display: flex;
              align-items: center;
              color: rgba(25, 25, 25, 0.4); }
              .integration-telegram-add-account .integration-telegram-add-account-box .integration-telegram-add-account-box-form .form-bot-token .bot-token-wrapper button .validating .grey-lds-ring {
                top: -5px;
                right: 15px; }
              .integration-telegram-add-account .integration-telegram-add-account-box .integration-telegram-add-account-box-form .form-bot-token .bot-token-wrapper button .validating .grey-lds-ring div {
                border-color: #8996a4 transparent transparent transparent; }
          .integration-telegram-add-account .integration-telegram-add-account-box .integration-telegram-add-account-box-form .form-bot-token .bot-token-wrapper button[disabled] {
            border: solid 1px #dcdcdc !important;
            border-left: none !important; }
      .integration-telegram-add-account .integration-telegram-add-account-box .integration-telegram-add-account-box-form input[type='text']:disabled {
        background: rgba(25, 25, 25, 0.1);
        color: rgba(25, 25, 25, 0.4);
        border: none;
        cursor: not-allowed; }
      .integration-telegram-add-account .integration-telegram-add-account-box .integration-telegram-add-account-box-form .integration-info-form {
        font-size: 14px;
        color: rgba(25, 25, 25, 0.8);
        letter-spacing: -0.2px; }
      .integration-telegram-add-account .integration-telegram-add-account-box .integration-telegram-add-account-box-form .custom-select-telegram-topic .custom-select-option-value-wrapper {
        padding: 12px 16px !important;
        margin-bottom: 8px; }

.modal-listed-here .modal-body {
  position: relative; }

.integration-telegram-detail-account {
  padding: 32px 30px;
  text-align: left; }
  .integration-telegram-detail-account .comp-back-button {
    margin: 0 0 16px 0;
    font-family: PTRootBold; }
  .integration-telegram-detail-account .integration-telegram-detail-account-box-1 {
    background: #ffffff;
    border-radius: 8px;
    padding: 22px 32px 32px;
    border: solid 1px rgba(25, 25, 25, 0.1);
    position: relative; }
    .integration-telegram-detail-account .integration-telegram-detail-account-box-1 .integration-telegram-detail-account-box-1-top {
      padding: 10px 0 15px;
      border-bottom: solid 1px #dcdcdc;
      position: relative;
      font-size: 20px;
      letter-spacing: -0.6px;
      padding-right: 230px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis; }
      .integration-telegram-detail-account .integration-telegram-detail-account-box-1 .integration-telegram-detail-account-box-1-top .bot-name {
        font-size: 12px;
        margin-bottom: 0; }
      .integration-telegram-detail-account .integration-telegram-detail-account-box-1 .integration-telegram-detail-account-box-1-top .integration-telegram-detail-account-box-1-top-button {
        position: absolute;
        top: 0px;
        right: 0;
        display: inline-block; }
        .integration-telegram-detail-account .integration-telegram-detail-account-box-1 .integration-telegram-detail-account-box-1-top .integration-telegram-detail-account-box-1-top-button button {
          padding: 7px 24px;
          font-size: 16px;
          letter-spacing: -0.6px;
          height: 40px;
          margin-left: 8px; }
          .integration-telegram-detail-account .integration-telegram-detail-account-box-1 .integration-telegram-detail-account-box-1-top .integration-telegram-detail-account-box-1-top-button button.button-channel-detail {
            position: relative;
            top: 15px; }
          .integration-telegram-detail-account .integration-telegram-detail-account-box-1 .integration-telegram-detail-account-box-1-top .integration-telegram-detail-account-box-1-top-button button img {
            position: relative;
            top: -2px;
            margin-right: 4px; }
          .integration-telegram-detail-account .integration-telegram-detail-account-box-1 .integration-telegram-detail-account-box-1-top .integration-telegram-detail-account-box-1-top-button button svg {
            position: relative;
            top: -2px;
            margin-right: 4px; }
          .integration-telegram-detail-account .integration-telegram-detail-account-box-1 .integration-telegram-detail-account-box-1-top .integration-telegram-detail-account-box-1-top-button button .lds-ring {
            top: unset;
            right: unset;
            position: relative;
            top: 3px; }
            .integration-telegram-detail-account .integration-telegram-detail-account-box-1 .integration-telegram-detail-account-box-1-top .integration-telegram-detail-account-box-1-top-button button .lds-ring div {
              margin: 0;
              border-color: #ffffff transparent transparent transparent; }
    .integration-telegram-detail-account .integration-telegram-detail-account-box-1 .telegram-detail-topic {
      justify-content: space-between;
      align-items: center;
      margin: 24px 0 0; }
      .integration-telegram-detail-account .integration-telegram-detail-account-box-1 .telegram-detail-topic button {
        position: absolute;
        right: 0;
        top: 22px;
        right: 168px; }
      .integration-telegram-detail-account .integration-telegram-detail-account-box-1 .telegram-detail-topic .topic-val {
        margin-top: 6px;
        max-width: calc(100% - 200px); }
      .integration-telegram-detail-account .integration-telegram-detail-account-box-1 .telegram-detail-topic .channel-button-wrapper {
        margin-top: 24px;
        width: 300px;
        word-break: break-word; }
      .integration-telegram-detail-account .integration-telegram-detail-account-box-1 .telegram-detail-topic .topic-wrapper {
        display: flex;
        flex-direction: column; }
        .integration-telegram-detail-account .integration-telegram-detail-account-box-1 .telegram-detail-topic .topic-wrapper .topic-title {
          font-size: 14px; }
      .integration-telegram-detail-account .integration-telegram-detail-account-box-1 .telegram-detail-topic button {
        padding: 7px 24px;
        font-size: 16px;
        letter-spacing: -0.6px;
        height: 40px;
        margin-left: 8px; }
        .integration-telegram-detail-account .integration-telegram-detail-account-box-1 .telegram-detail-topic button svg {
          position: relative;
          top: -2px;
          margin-right: 4px; }
    .integration-telegram-detail-account .integration-telegram-detail-account-box-1 .telegram-bot-token-status {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 24px 0 0; }
      .integration-telegram-detail-account .integration-telegram-detail-account-box-1 .telegram-bot-token-status .bot-status-wrapper .bot-status-title {
        font-size: 14px; }
      .integration-telegram-detail-account .integration-telegram-detail-account-box-1 .telegram-bot-token-status .bot-status-wrapper .status-wrapper {
        display: flex;
        align-items: center; }
        .integration-telegram-detail-account .integration-telegram-detail-account-box-1 .telegram-bot-token-status .bot-status-wrapper .status-wrapper img {
          margin-right: 5px; }
        .integration-telegram-detail-account .integration-telegram-detail-account-box-1 .telegram-bot-token-status .bot-status-wrapper .status-wrapper .status-text {
          font-size: 16px; }
        .integration-telegram-detail-account .integration-telegram-detail-account-box-1 .telegram-bot-token-status .bot-status-wrapper .status-wrapper .text-green {
          color: #19c700; }
        .integration-telegram-detail-account .integration-telegram-detail-account-box-1 .telegram-bot-token-status .bot-status-wrapper .status-wrapper .text-red {
          color: #ff3f57; }
      .integration-telegram-detail-account .integration-telegram-detail-account-box-1 .telegram-bot-token-status .button-wrapper button {
        padding: 7px 24px;
        font-size: 16px;
        letter-spacing: -0.6px;
        height: 40px;
        margin-left: 8px;
        font-family: PTRootBold; }
        .integration-telegram-detail-account .integration-telegram-detail-account-box-1 .telegram-bot-token-status .button-wrapper button svg {
          position: relative;
          top: -2px;
          margin-right: 4px; }
    .integration-telegram-detail-account .integration-telegram-detail-account-box-1.box-channel-button {
      margin-top: 24px; }
      .integration-telegram-detail-account .integration-telegram-detail-account-box-1.box-channel-button .telegram-detail-topic {
        display: block; }
      .integration-telegram-detail-account .integration-telegram-detail-account-box-1.box-channel-button .topic-wrapper {
        margin-top: 24px;
        width: 100%;
        display: block; }
      .integration-telegram-detail-account .integration-telegram-detail-account-box-1.box-channel-button .status-wrapper .status-value {
        color: rgba(25, 25, 25, 0.4); }
        .integration-telegram-detail-account .integration-telegram-detail-account-box-1.box-channel-button .status-wrapper .status-value.status-enabled {
          color: #19C700; }
      .integration-telegram-detail-account .integration-telegram-detail-account-box-1.box-channel-button .status-wrapper img {
        position: relative;
        top: -1px;
        margin-right: 4px; }


.integration-telegram-edit-topic-account {
  padding: 32px 30px;
  text-align: left; }
  .integration-telegram-edit-topic-account .comp-back-button {
    margin: 0 0 16px 0;
    font-family: PTRootBold; }
  .integration-telegram-edit-topic-account .integration-telegram-edit-topic-account-box {
    background: #ffffff;
    border-radius: 8px;
    padding: 32px;
    border: solid 1px rgba(25, 25, 25, 0.1); }
    .integration-telegram-edit-topic-account .integration-telegram-edit-topic-account-box .integration-telegram-edit-topic-account-box-top {
      padding-bottom: 15px;
      border-bottom: solid 1px #dcdcdc;
      position: relative;
      font-size: 20px;
      letter-spacing: -0.6px; }
      .integration-telegram-edit-topic-account .integration-telegram-edit-topic-account-box .integration-telegram-edit-topic-account-box-top button {
        position: absolute;
        top: -6px;
        right: 0;
        font-size: 16px;
        letter-spacing: -0.6px; }
    .integration-telegram-edit-topic-account .integration-telegram-edit-topic-account-box .integration-telegram-edit-topic-account-box-form {
      width: 100%; }
      .integration-telegram-edit-topic-account .integration-telegram-edit-topic-account-box .integration-telegram-edit-topic-account-box-form label {
        padding: 24px 0 0;
        font-size: 14px;
        letter-spacing: -0.4px;
        display: block; }
      .integration-telegram-edit-topic-account .integration-telegram-edit-topic-account-box .integration-telegram-edit-topic-account-box-form input[type='text'] {
        padding: 12px 16px;
        border-radius: 6px;
        border: solid 1px #dcdcdc;
        width: 100%;
        font-size: 16px;
        letter-spacing: -0.3px;
        display: block; }
      .integration-telegram-edit-topic-account .integration-telegram-edit-topic-account-box .integration-telegram-edit-topic-account-box-form .form-topic {
        width: 620px !important; }
      .integration-telegram-edit-topic-account .integration-telegram-edit-topic-account-box .integration-telegram-edit-topic-account-box-form input[type='text']:disabled {
        background: rgba(25, 25, 25, 0.1);
        color: rgba(25, 25, 25, 0.2);
        border: none;
        cursor: not-allowed; }
      .integration-telegram-edit-topic-account .integration-telegram-edit-topic-account-box .integration-telegram-edit-topic-account-box-form .integration-info-form {
        font-size: 14px;
        color: rgba(25, 25, 25, 0.8);
        letter-spacing: -0.2px; }
      .integration-telegram-edit-topic-account .integration-telegram-edit-topic-account-box .integration-telegram-edit-topic-account-box-form .custom-select-telegram-topic .custom-select-option-value-wrapper {
        padding: 12px 16px !important;
        margin-bottom: 8px; }

.integration-telegram-edit-topic-account {
  padding: 32px 30px;
  text-align: left; }
  .integration-telegram-edit-topic-account .comp-back-button {
    margin: 0 0 16px 0;
    font-family: PTRootBold; }
  .integration-telegram-edit-topic-account .integration-telegram-edit-topic-account-box {
    background: #ffffff;
    border-radius: 8px;
    padding: 32px;
    border: solid 1px rgba(25, 25, 25, 0.1); }
    .integration-telegram-edit-topic-account .integration-telegram-edit-topic-account-box .integration-telegram-edit-topic-account-box-top {
      padding-bottom: 15px;
      border-bottom: solid 1px #dcdcdc;
      position: relative;
      font-size: 20px;
      letter-spacing: -0.6px; }
      .integration-telegram-edit-topic-account .integration-telegram-edit-topic-account-box .integration-telegram-edit-topic-account-box-top button {
        position: absolute;
        top: -6px;
        right: 0;
        font-size: 16px;
        letter-spacing: -0.6px; }
    .integration-telegram-edit-topic-account .integration-telegram-edit-topic-account-box .integration-telegram-edit-topic-account-box-form {
      width: 100%; }
      .integration-telegram-edit-topic-account .integration-telegram-edit-topic-account-box .integration-telegram-edit-topic-account-box-form label {
        padding: 32px 0 0;
        margin: 0 0 8px 0;
        font-size: 14px;
        letter-spacing: -0.4px;
        display: block;
        position: relative;
        width: 300px; }
        .integration-telegram-edit-topic-account .integration-telegram-edit-topic-account-box .integration-telegram-edit-topic-account-box-form label span {
          font-size: 14px;
          letter-spacing: -0.2px;
          position: absolute;
          bottom: 0;
          right: 0; }
      .integration-telegram-edit-topic-account .integration-telegram-edit-topic-account-box .integration-telegram-edit-topic-account-box-form input[type='text'] {
        padding: 12px 16px;
        border-radius: 6px;
        border: solid 1px #dcdcdc;
        width: 100%;
        font-size: 16px;
        letter-spacing: -0.3px;
        display: block; }
      .integration-telegram-edit-topic-account .integration-telegram-edit-topic-account-box .integration-telegram-edit-topic-account-box-form .form-topic {
        width: 100%; }
        .integration-telegram-edit-topic-account .integration-telegram-edit-topic-account-box .integration-telegram-edit-topic-account-box-form .form-topic input[type="text"] {
          width: 300px; }
        .integration-telegram-edit-topic-account .integration-telegram-edit-topic-account-box .integration-telegram-edit-topic-account-box-form .form-topic .switch-channel-button {
          position: relative; }
          .integration-telegram-edit-topic-account .integration-telegram-edit-topic-account-box .integration-telegram-edit-topic-account-box-form .form-topic .switch-channel-button label {
            margin: 0; }
          .integration-telegram-edit-topic-account .integration-telegram-edit-topic-account-box .integration-telegram-edit-topic-account-box-form .form-topic .switch-channel-button .custom-switch-wrapper {
            position: absolute;
            width: 48px;
            height: 29px;
            right: 0;
            bottom: 20px; }
            .integration-telegram-edit-topic-account .integration-telegram-edit-topic-account-box .integration-telegram-edit-topic-account-box-form .form-topic .switch-channel-button .custom-switch-wrapper .custom-switch.round:before {
              width: 16px;
              height: 16px;
              bottom: 8px;
              left: 7px; }
            .integration-telegram-edit-topic-account .integration-telegram-edit-topic-account-box .integration-telegram-edit-topic-account-box-form .form-topic .switch-channel-button .custom-switch-wrapper input:checked + .custom-switch:before {
              -webkit-transform: translateX(19px);
                      transform: translateX(19px); }
            .integration-telegram-edit-topic-account .integration-telegram-edit-topic-account-box .integration-telegram-edit-topic-account-box-form .form-topic .switch-channel-button .custom-switch-wrapper b {
              font-size: 16px;
              letter-spacing: -0.5px; }
      .integration-telegram-edit-topic-account .integration-telegram-edit-topic-account-box .integration-telegram-edit-topic-account-box-form input[type='text']:disabled {
        background: rgba(25, 25, 25, 0.1);
        color: rgba(25, 25, 25, 0.4);
        border: none;
        cursor: not-allowed; }
      .integration-telegram-edit-topic-account .integration-telegram-edit-topic-account-box .integration-telegram-edit-topic-account-box-form .integration-info-form {
        font-size: 14px;
        color: rgba(25, 25, 25, 0.8);
        letter-spacing: -0.2px; }
      .integration-telegram-edit-topic-account .integration-telegram-edit-topic-account-box .integration-telegram-edit-topic-account-box-form .custom-select-telegram-topic .custom-select-option-value-wrapper {
        padding: 12px 16px !important;
        margin-bottom: 8px; }

.integration-telegram-edit-bot-token-account {
  padding: 32px 30px;
  text-align: left; }
  .integration-telegram-edit-bot-token-account .comp-back-button {
    margin: 0 0 16px 0;
    font-family: PTRootBold; }
  .integration-telegram-edit-bot-token-account .integration-telegram-edit-bot-token-account-box {
    background: #ffffff;
    border-radius: 8px;
    padding: 32px;
    border: solid 1px rgba(25, 25, 25, 0.1); }
    .integration-telegram-edit-bot-token-account .integration-telegram-edit-bot-token-account-box .integration-telegram-edit-bot-token-account-box-top {
      padding-bottom: 15px;
      border-bottom: solid 1px #dcdcdc;
      position: relative;
      font-size: 20px;
      letter-spacing: -0.6px; }
      .integration-telegram-edit-bot-token-account .integration-telegram-edit-bot-token-account-box .integration-telegram-edit-bot-token-account-box-top button {
        position: absolute;
        top: -6px;
        right: 0;
        font-size: 16px;
        letter-spacing: -0.6px; }
    .integration-telegram-edit-bot-token-account .integration-telegram-edit-bot-token-account-box .integration-telegram-edit-bot-token-account-box-form {
      width: 100%; }
      .integration-telegram-edit-bot-token-account .integration-telegram-edit-bot-token-account-box .integration-telegram-edit-bot-token-account-box-form .alert-edit-token {
        margin-top: 24px;
        box-shadow: 0 2px 20px 0 rgba(25, 25, 25, 0.18); }
      .integration-telegram-edit-bot-token-account .integration-telegram-edit-bot-token-account-box .integration-telegram-edit-bot-token-account-box-form label {
        padding: 24px 0 0;
        font-size: 14px;
        letter-spacing: -0.4px;
        display: block; }
      .integration-telegram-edit-bot-token-account .integration-telegram-edit-bot-token-account-box .integration-telegram-edit-bot-token-account-box-form input[type='text'] {
        padding: 12px 16px;
        border-radius: 6px;
        border: solid 1px #dcdcdc;
        width: 100%;
        font-size: 16px;
        letter-spacing: -0.3px;
        display: block; }
      .integration-telegram-edit-bot-token-account .integration-telegram-edit-bot-token-account-box .integration-telegram-edit-bot-token-account-box-form .form-topic {
        width: 320px; }
      .integration-telegram-edit-bot-token-account .integration-telegram-edit-bot-token-account-box .integration-telegram-edit-bot-token-account-box-form .form-bot-token {
        width: 100%; }
        .integration-telegram-edit-bot-token-account .integration-telegram-edit-bot-token-account-box .integration-telegram-edit-bot-token-account-box-form .form-bot-token label {
          display: flex;
          align-items: center; }
          .integration-telegram-edit-bot-token-account .integration-telegram-edit-bot-token-account-box .integration-telegram-edit-bot-token-account-box-form .form-bot-token label .bot-token-title {
            margin-right: 5px;
            margin-top: 2px; }
        .integration-telegram-edit-bot-token-account .integration-telegram-edit-bot-token-account-box .integration-telegram-edit-bot-token-account-box-form .form-bot-token .bot-token-wrapper {
          display: flex;
          align-items: center;
          margin-bottom: 8px; }
          .integration-telegram-edit-bot-token-account .integration-telegram-edit-bot-token-account-box .integration-telegram-edit-bot-token-account-box-form .form-bot-token .bot-token-wrapper.border-red {
            border: solid 1px #ff3f57 !important; }
          .integration-telegram-edit-bot-token-account .integration-telegram-edit-bot-token-account-box .integration-telegram-edit-bot-token-account-box-form .form-bot-token .bot-token-wrapper input[type='text'] {
            width: 721px;
            display: inline-block;
            border-radius: 6px 0 0 6px;
            border: none;
            margin-bottom: 0;
            -webkit-transition: ease-in-out 0.3s, width 0s;
            transition: ease-in-out 0.3s, width 0s; }
          .integration-telegram-edit-bot-token-account .integration-telegram-edit-bot-token-account-box .integration-telegram-edit-bot-token-account-box-form .form-bot-token .bot-token-wrapper input[type='text']:disabled {
            border-right: none !important; }
          .integration-telegram-edit-bot-token-account .integration-telegram-edit-bot-token-account-box .integration-telegram-edit-bot-token-account-box-form .form-bot-token .bot-token-wrapper button {
            padding: 12px 16px;
            border-radius: 0 6px 6px 0;
            border: solid 1px #dcdcdc;
            font-size: 16px;
            letter-spacing: -0.3px;
            color: rgba(25, 25, 25, 0.2);
            background-color: transparent;
            padding: 12px 24px;
            font-family: PTRootBold; }
            .integration-telegram-edit-bot-token-account .integration-telegram-edit-bot-token-account-box .integration-telegram-edit-bot-token-account-box-form .form-bot-token .bot-token-wrapper button .valid {
              display: flex;
              align-items: center; }
              .integration-telegram-edit-bot-token-account .integration-telegram-edit-bot-token-account-box .integration-telegram-edit-bot-token-account-box-form .form-bot-token .bot-token-wrapper button .valid .valid-green {
                width: 18px;
                height: 13px;
                background: url(/static/media/icon-valid.9c255a92.svg);
                margin-right: 7px; }
              .integration-telegram-edit-bot-token-account .integration-telegram-edit-bot-token-account-box .integration-telegram-edit-bot-token-account-box-form .form-bot-token .bot-token-wrapper button .valid .text-green {
                color: #19c700; }
              .integration-telegram-edit-bot-token-account .integration-telegram-edit-bot-token-account-box .integration-telegram-edit-bot-token-account-box-form .form-bot-token .bot-token-wrapper button .valid .valid-grey {
                width: 18px;
                height: 13px;
                background: url(/static/media/icon-valid-grey.acab4116.svg);
                margin-right: 7px; }
              .integration-telegram-edit-bot-token-account .integration-telegram-edit-bot-token-account-box .integration-telegram-edit-bot-token-account-box-form .form-bot-token .bot-token-wrapper button .valid .text-grey {
                color: rgba(25, 25, 25, 0.2); }
            .integration-telegram-edit-bot-token-account .integration-telegram-edit-bot-token-account-box .integration-telegram-edit-bot-token-account-box-form .form-bot-token .bot-token-wrapper button .validating {
              display: flex;
              align-items: center;
              color: rgba(25, 25, 25, 0.4); }
              .integration-telegram-edit-bot-token-account .integration-telegram-edit-bot-token-account-box .integration-telegram-edit-bot-token-account-box-form .form-bot-token .bot-token-wrapper button .validating .grey-lds-ring {
                top: -5px;
                right: 15px; }
              .integration-telegram-edit-bot-token-account .integration-telegram-edit-bot-token-account-box .integration-telegram-edit-bot-token-account-box-form .form-bot-token .bot-token-wrapper button .validating .grey-lds-ring div {
                border-color: #8996a4 transparent transparent transparent; }
          .integration-telegram-edit-bot-token-account .integration-telegram-edit-bot-token-account-box .integration-telegram-edit-bot-token-account-box-form .form-bot-token .bot-token-wrapper button[disabled] {
            border: solid 1px #dcdcdc !important;
            border-left: none !important; }
      .integration-telegram-edit-bot-token-account .integration-telegram-edit-bot-token-account-box .integration-telegram-edit-bot-token-account-box-form input[type='text']:disabled {
        background: rgba(25, 25, 25, 0.1);
        color: rgba(25, 25, 25, 0.4);
        border: none;
        cursor: not-allowed; }
      .integration-telegram-edit-bot-token-account .integration-telegram-edit-bot-token-account-box .integration-telegram-edit-bot-token-account-box-form .integration-info-form {
        font-size: 14px;
        color: rgba(25, 25, 25, 0.8);
        letter-spacing: -0.2px; }
      .integration-telegram-edit-bot-token-account .integration-telegram-edit-bot-token-account-box .integration-telegram-edit-bot-token-account-box-form .custom-select-telegram-topic .custom-select-option-value-wrapper {
        padding: 12px 16px !important;
        margin-bottom: 8px; }

.chatbot-add-chatbot {
  padding: 32px 30px;
  text-align: left; }
  .chatbot-add-chatbot .comp-back-button {
    margin: 0 0 16px 0; }
  .chatbot-add-chatbot .chatbot-add-chatbot-box {
    background: #ffffff;
    border-radius: 8px;
    padding: 32px;
    border: solid 1px rgba(25, 25, 25, 0.1); }
    .chatbot-add-chatbot .chatbot-add-chatbot-box .chatbot-add-chatbot-box-top {
      padding-bottom: 15px;
      border-bottom: solid 1px #dcdcdc;
      position: relative;
      font-size: 20px;
      letter-spacing: -0.6px; }
      .chatbot-add-chatbot .chatbot-add-chatbot-box .chatbot-add-chatbot-box-top .chat-bot-chatbot-button-wrapper {
        margin: 0; }
      .chatbot-add-chatbot .chatbot-add-chatbot-box .chatbot-add-chatbot-box-top button {
        position: absolute;
        top: -6px;
        right: 0;
        font-size: 16px;
        margin: 0 !important;
        letter-spacing: -0.6px; }
    .chatbot-add-chatbot .chatbot-add-chatbot-box .chatbot-add-chatbot-box-form {
      width: 320px; }
      .chatbot-add-chatbot .chatbot-add-chatbot-box .chatbot-add-chatbot-box-form label {
        padding: 24px 0 0;
        font-size: 14px;
        letter-spacing: -0.4px;
        display: block; }
      .chatbot-add-chatbot .chatbot-add-chatbot-box .chatbot-add-chatbot-box-form input[type="text"] {
        padding: 12px 16px;
        border-radius: 6px;
        border: solid 1px #dcdcdc;
        width: 100%;
        font-size: 16px;
        letter-spacing: -0.3px;
        display: block;
        margin-bottom: 8px; }
      .chatbot-add-chatbot .chatbot-add-chatbot-box .chatbot-add-chatbot-box-form .integration-info-form {
        font-size: 14px;
        color: rgba(25, 25, 25, 0.8);
        letter-spacing: -0.2px; }
      .chatbot-add-chatbot .chatbot-add-chatbot-box .chatbot-add-chatbot-box-form .custom-select-path .custom-select-option-value-wrapper {
        padding: 12px 16px !important;
        margin-bottom: 8px; }
    .chatbot-add-chatbot .chatbot-add-chatbot-box .chat-bot-chatbot-button-wrapper {
      margin-top: 18px; }
      .chatbot-add-chatbot .chatbot-add-chatbot-box .chat-bot-chatbot-button-wrapper button {
        margin: 0 4px 0 0; }


.integration-fb-list {
  text-align: left; }
  .integration-fb-list .integration-list-loading-wrapper .lds-ring {
    width: 50px;
    height: 50px;
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    right: unset; }
    .integration-fb-list .integration-list-loading-wrapper .lds-ring div {
      border: 5px solid #fff;
      border-color: #ff7d00 transparent transparent transparent;
      width: 50px;
      height: 50px;
      margin: 0; }
  .integration-fb-list .integration-list-no-data {
    width: 100%;
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
    .integration-fb-list .integration-list-no-data b {
      font-size: 20px;
      letter-spacing: -0.6px;
      color: #191919; }
    .integration-fb-list .integration-list-no-data p {
      color: rgba(25, 25, 25, 0.6);
      letter-spacing: -0.6px;
      font-size: 16px; }
  .integration-fb-list .integration-list-header {
    position: relative;
    padding-left: 42px;
    padding-bottom: 10px;
    border-bottom: solid 1px rgba(25, 25, 25, 0.1); }
    .integration-fb-list .integration-list-header .integration-list-header-icon {
      position: absolute;
      top: 0;
      left: 0;
      width: 32px; }
    .integration-fb-list .integration-list-header .integration-list-header-text-wrapper b {
      font-size: 20px;
      letter-spacing: -0.6px; }
    .integration-fb-list .integration-list-header .integration-list-header-text-wrapper p {
      margin: 0;
      font-size: 14px;
      letter-spacing: -0.2px; }
    .integration-fb-list .integration-list-header .integration-list-header-text-wrapper a {
      color: #ff7d00; }
    .integration-fb-list .integration-list-header .integration-list-header-button {
      position: absolute;
      right: 0;
      top: 0;
      font-size: 16px;
      letter-spacing: -0.5px;
      padding: 7px 32px; }
      .integration-fb-list .integration-list-header .integration-list-header-button img {
        margin-right: 4px;
        position: relative;
        top: -2px; }
  .integration-fb-list .integration-list-outer-wrapper {
    position: relative;
    width: 100%;
    padding-top: 24px; }
    .integration-fb-list .integration-list-outer-wrapper .integration-list-inner-wrapper {
      width: calc((100% / 3) - 8px);
      border-radius: 8px;
      margin: 0 12px 12px 0;
      box-shadow: 0 2px 20px 0 rgba(25, 25, 25, 0.05);
      background: #ffffff;
      display: inline-block;
      vertical-align: top;
      padding: 24px 30px 24px 24px;
      cursor: pointer;
      position: relative; }
      .integration-fb-list .integration-list-outer-wrapper .integration-list-inner-wrapper p:nth-child(2) {
        font-size: 16px;
        letter-spacing: -0.5px;
        color: rgba(25, 25, 25, 0.6);
        margin: 0;
        white-space: nowrap;
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis; }
      .integration-fb-list .integration-list-outer-wrapper .integration-list-inner-wrapper span {
        display: block;
        font-size: 12px;
        letter-spacing: -0.2px;
        color: rgba(25, 25, 25, 0.6); }
      .integration-fb-list .integration-list-outer-wrapper .integration-list-inner-wrapper p {
        margin: 10px 0 0;
        color: #ff7d00;
        font-size: 12px;
        letter-spacing: -0.2px; }
        .integration-fb-list .integration-list-outer-wrapper .integration-list-inner-wrapper p.font-green {
          color: #19c700; }
        .integration-fb-list .integration-list-outer-wrapper .integration-list-inner-wrapper p.font-grey {
          color: rgba(25, 25, 25, 0.4); }
      .integration-fb-list .integration-list-outer-wrapper .integration-list-inner-wrapper svg {
        position: absolute;
        right: 12px;
        top: 12px;
        font-size: 22px;
        color: rgba(25, 25, 25, 0.6); }
      .integration-fb-list .integration-list-outer-wrapper .integration-list-inner-wrapper:hover {
        box-shadow: 0 2px 20px 0 rgba(25, 25, 25, 0.18); }
        .integration-fb-list .integration-list-outer-wrapper .integration-list-inner-wrapper:hover p:nth-child(2) {
          color: #191919; }
        .integration-fb-list .integration-list-outer-wrapper .integration-list-inner-wrapper:hover svg {
          color: #ff7d00; }
  .integration-fb-list .link-fb-account-list:nth-child(3n) .integration-list-inner-wrapper {
    margin: 0 0 12px 0; }

.integration-facebook-edit-topic-account {
  padding: 32px 30px;
  text-align: left; }
  .integration-facebook-edit-topic-account .comp-back-button {
    margin: 0 0 16px 0;
    font-family: PTRootBold; }
  .integration-facebook-edit-topic-account .integration-facebook-edit-topic-account-box {
    background: #ffffff;
    border-radius: 8px;
    padding: 32px;
    border: solid 1px rgba(25, 25, 25, 0.1); }
    .integration-facebook-edit-topic-account .integration-facebook-edit-topic-account-box .integration-facebook-edit-topic-account-box-top {
      padding-bottom: 15px;
      border-bottom: solid 1px #dcdcdc;
      position: relative;
      font-size: 20px;
      letter-spacing: -0.6px; }
      .integration-facebook-edit-topic-account .integration-facebook-edit-topic-account-box .integration-facebook-edit-topic-account-box-top button {
        position: absolute;
        top: -6px;
        right: 0;
        font-size: 16px;
        letter-spacing: -0.6px; }
    .integration-facebook-edit-topic-account .integration-facebook-edit-topic-account-box .integration-facebook-edit-topic-account-box-form {
      width: 100%; }
      .integration-facebook-edit-topic-account .integration-facebook-edit-topic-account-box .integration-facebook-edit-topic-account-box-form label {
        padding: 32px 0 0;
        margin: 0 0 8px 0;
        font-size: 14px;
        letter-spacing: -0.4px;
        display: block;
        position: relative;
        width: 300px; }
        .integration-facebook-edit-topic-account .integration-facebook-edit-topic-account-box .integration-facebook-edit-topic-account-box-form label span {
          font-size: 14px;
          letter-spacing: -0.2px;
          position: absolute;
          bottom: 0;
          right: 0; }
      .integration-facebook-edit-topic-account .integration-facebook-edit-topic-account-box .integration-facebook-edit-topic-account-box-form input[type='text'] {
        padding: 12px 16px;
        border-radius: 6px;
        border: solid 1px #dcdcdc;
        width: 100%;
        font-size: 16px;
        letter-spacing: -0.3px;
        display: block; }
      .integration-facebook-edit-topic-account .integration-facebook-edit-topic-account-box .integration-facebook-edit-topic-account-box-form .form-topic {
        width: 100%; }
        .integration-facebook-edit-topic-account .integration-facebook-edit-topic-account-box .integration-facebook-edit-topic-account-box-form .form-topic input[type="text"] {
          width: 300px; }
        .integration-facebook-edit-topic-account .integration-facebook-edit-topic-account-box .integration-facebook-edit-topic-account-box-form .form-topic .switch-channel-button {
          position: relative; }
          .integration-facebook-edit-topic-account .integration-facebook-edit-topic-account-box .integration-facebook-edit-topic-account-box-form .form-topic .switch-channel-button label {
            margin: 0; }
          .integration-facebook-edit-topic-account .integration-facebook-edit-topic-account-box .integration-facebook-edit-topic-account-box-form .form-topic .switch-channel-button .custom-switch-wrapper {
            position: absolute;
            width: 48px;
            height: 29px;
            right: 0;
            bottom: 20px; }
            .integration-facebook-edit-topic-account .integration-facebook-edit-topic-account-box .integration-facebook-edit-topic-account-box-form .form-topic .switch-channel-button .custom-switch-wrapper .custom-switch.round:before {
              width: 16px;
              height: 16px;
              bottom: 8px;
              left: 7px; }
            .integration-facebook-edit-topic-account .integration-facebook-edit-topic-account-box .integration-facebook-edit-topic-account-box-form .form-topic .switch-channel-button .custom-switch-wrapper input:checked + .custom-switch:before {
              -webkit-transform: translateX(19px);
                      transform: translateX(19px); }
            .integration-facebook-edit-topic-account .integration-facebook-edit-topic-account-box .integration-facebook-edit-topic-account-box-form .form-topic .switch-channel-button .custom-switch-wrapper b {
              font-size: 16px;
              letter-spacing: -0.5px; }
      .integration-facebook-edit-topic-account .integration-facebook-edit-topic-account-box .integration-facebook-edit-topic-account-box-form input[type='text']:disabled {
        background: rgba(25, 25, 25, 0.1);
        color: rgba(25, 25, 25, 0.4);
        border: none;
        cursor: not-allowed; }
      .integration-facebook-edit-topic-account .integration-facebook-edit-topic-account-box .integration-facebook-edit-topic-account-box-form .integration-info-form {
        font-size: 14px;
        color: rgba(25, 25, 25, 0.8);
        letter-spacing: -0.2px; }
      .integration-facebook-edit-topic-account .integration-facebook-edit-topic-account-box .integration-facebook-edit-topic-account-box-form .custom-select-facebook-topic .custom-select-option-value-wrapper {
        padding: 12px 16px !important;
        margin-bottom: 8px; }

.integration-messenger-detail-account {
  padding: 32px 30px;
  text-align: left; }
  .integration-messenger-detail-account .comp-back-button {
    margin: 0 0 16px 0;
    font-family: PTRootBold; }
  .integration-messenger-detail-account .integration-messenger-detail-account-box-1 {
    background: #ffffff;
    border-radius: 8px;
    padding: 22px 32px 32px;
    border: solid 1px rgba(25, 25, 25, 0.1);
    position: relative; }
    .integration-messenger-detail-account .integration-messenger-detail-account-box-1 .integration-messenger-detail-account-box-1-top {
      padding: 10px 0 15px;
      border-bottom: solid 1px #dcdcdc;
      position: relative;
      font-size: 20px;
      letter-spacing: -0.6px;
      padding-right: 230px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis; }
      .integration-messenger-detail-account .integration-messenger-detail-account-box-1 .integration-messenger-detail-account-box-1-top.box-channel-button {
        margin-top: 24px; }
        .integration-messenger-detail-account .integration-messenger-detail-account-box-1 .integration-messenger-detail-account-box-1-top.box-channel-button .messenger-detail-topic {
          display: block; }
        .integration-messenger-detail-account .integration-messenger-detail-account-box-1 .integration-messenger-detail-account-box-1-top.box-channel-button .topic-wrapper {
          margin-top: 24px;
          width: 100%;
          display: block; }
        .integration-messenger-detail-account .integration-messenger-detail-account-box-1 .integration-messenger-detail-account-box-1-top.box-channel-button .status-wrapper .status-value {
          color: rgba(25, 25, 25, 0.4); }
          .integration-messenger-detail-account .integration-messenger-detail-account-box-1 .integration-messenger-detail-account-box-1-top.box-channel-button .status-wrapper .status-value.status-enabled {
            color: #19C700; }
        .integration-messenger-detail-account .integration-messenger-detail-account-box-1 .integration-messenger-detail-account-box-1-top.box-channel-button .status-wrapper img {
          position: relative;
          top: -1px;
          margin-right: 4px; }
      .integration-messenger-detail-account .integration-messenger-detail-account-box-1 .integration-messenger-detail-account-box-1-top .bot-name {
        font-size: 12px;
        margin-bottom: 0; }
      .integration-messenger-detail-account .integration-messenger-detail-account-box-1 .integration-messenger-detail-account-box-1-top .integration-messenger-detail-account-box-1-top-button {
        position: absolute;
        top: 0px;
        right: 0;
        display: inline-block; }
        .integration-messenger-detail-account .integration-messenger-detail-account-box-1 .integration-messenger-detail-account-box-1-top .integration-messenger-detail-account-box-1-top-button button {
          padding: 7px 24px;
          font-size: 16px;
          letter-spacing: -0.6px;
          height: 40px;
          margin-left: 8px; }
          .integration-messenger-detail-account .integration-messenger-detail-account-box-1 .integration-messenger-detail-account-box-1-top .integration-messenger-detail-account-box-1-top-button button img {
            position: relative;
            top: -2px;
            margin-right: 4px; }
          .integration-messenger-detail-account .integration-messenger-detail-account-box-1 .integration-messenger-detail-account-box-1-top .integration-messenger-detail-account-box-1-top-button button svg {
            position: relative;
            top: -2px;
            margin-right: 4px; }
          .integration-messenger-detail-account .integration-messenger-detail-account-box-1 .integration-messenger-detail-account-box-1-top .integration-messenger-detail-account-box-1-top-button button .lds-ring {
            top: unset;
            right: unset;
            position: relative;
            top: 3px; }
            .integration-messenger-detail-account .integration-messenger-detail-account-box-1 .integration-messenger-detail-account-box-1-top .integration-messenger-detail-account-box-1-top-button button .lds-ring div {
              margin: 0;
              border-color: #ffffff transparent transparent transparent; }
    .integration-messenger-detail-account .integration-messenger-detail-account-box-1 .messenger-detail-topic {
      justify-content: space-between;
      align-items: center;
      margin: 24px 0 0; }
      .integration-messenger-detail-account .integration-messenger-detail-account-box-1 .messenger-detail-topic button {
        position: absolute;
        right: 32px;
        top: 26px; }
      .integration-messenger-detail-account .integration-messenger-detail-account-box-1 .messenger-detail-topic .topic-val {
        margin: 6px 0 0;
        max-width: calc(100% - 200px); }
      .integration-messenger-detail-account .integration-messenger-detail-account-box-1 .messenger-detail-topic .topic-wrapper {
        display: flex;
        flex-direction: column; }
        .integration-messenger-detail-account .integration-messenger-detail-account-box-1 .messenger-detail-topic .topic-wrapper .topic-title {
          font-size: 14px; }
        .integration-messenger-detail-account .integration-messenger-detail-account-box-1 .messenger-detail-topic .topic-wrapper b:nth-child(2) {
          font-size: 20px; }
      .integration-messenger-detail-account .integration-messenger-detail-account-box-1 .messenger-detail-topic button {
        padding: 7px 24px;
        font-size: 16px;
        letter-spacing: -0.6px;
        height: 40px;
        margin-left: 8px; }
        .integration-messenger-detail-account .integration-messenger-detail-account-box-1 .messenger-detail-topic button svg {
          position: relative;
          top: -2px;
          margin-right: 4px; }
    .integration-messenger-detail-account .integration-messenger-detail-account-box-1 .messenger-bot-token-status {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 24px 0 0; }
      .integration-messenger-detail-account .integration-messenger-detail-account-box-1 .messenger-bot-token-status .bot-status-wrapper .bot-status-title {
        font-size: 14px; }
      .integration-messenger-detail-account .integration-messenger-detail-account-box-1 .messenger-bot-token-status .bot-status-wrapper .status-wrapper {
        align-items: center; }
        .integration-messenger-detail-account .integration-messenger-detail-account-box-1 .messenger-bot-token-status .bot-status-wrapper .status-wrapper .facebook-button {
          width: unset;
          margin-top: 24px; }
        .integration-messenger-detail-account .integration-messenger-detail-account-box-1 .messenger-bot-token-status .bot-status-wrapper .status-wrapper img {
          margin-right: 5px;
          position: relative;
          top: -1px; }
        .integration-messenger-detail-account .integration-messenger-detail-account-box-1 .messenger-bot-token-status .bot-status-wrapper .status-wrapper .status-text {
          font-size: 16px; }
        .integration-messenger-detail-account .integration-messenger-detail-account-box-1 .messenger-bot-token-status .bot-status-wrapper .status-wrapper .text-green {
          color: #19c700; }
        .integration-messenger-detail-account .integration-messenger-detail-account-box-1 .messenger-bot-token-status .bot-status-wrapper .status-wrapper .text-red {
          color: #ff3f57; }
      .integration-messenger-detail-account .integration-messenger-detail-account-box-1 .messenger-bot-token-status .button-wrapper button {
        padding: 7px 24px;
        font-size: 16px;
        letter-spacing: -0.6px;
        height: 40px;
        margin-left: 8px;
        font-family: PTRootBold; }
        .integration-messenger-detail-account .integration-messenger-detail-account-box-1 .messenger-bot-token-status .button-wrapper button svg {
          position: relative;
          top: -2px;
          margin-right: 4px; }
    .integration-messenger-detail-account .integration-messenger-detail-account-box-1.box-channel-button {
      margin-top: 24px; }
      .integration-messenger-detail-account .integration-messenger-detail-account-box-1.box-channel-button .messenger-detail-topic {
        display: block; }
      .integration-messenger-detail-account .integration-messenger-detail-account-box-1.box-channel-button .topic-wrapper {
        margin-top: 24px;
        width: 100%;
        display: block; }
      .integration-messenger-detail-account .integration-messenger-detail-account-box-1.box-channel-button .status-wrapper .status-value {
        color: rgba(25, 25, 25, 0.4); }
        .integration-messenger-detail-account .integration-messenger-detail-account-box-1.box-channel-button .status-wrapper .status-value.status-enabled {
          color: #19C700; }
      .integration-messenger-detail-account .integration-messenger-detail-account-box-1.box-channel-button .status-wrapper img {
        position: relative;
        top: -1px;
        margin-right: 4px; }
  .integration-messenger-detail-account .integration-facebook-detail-account-box-1 {
    background: #ffffff;
    border-radius: 8px;
    padding: 22px 32px 32px;
    border: solid 1px rgba(25, 25, 25, 0.1); }
    .integration-messenger-detail-account .integration-facebook-detail-account-box-1.box-channel-button {
      margin-top: 24px; }
      .integration-messenger-detail-account .integration-facebook-detail-account-box-1.box-channel-button .facebook-detail-topic {
        display: block; }
        .integration-messenger-detail-account .integration-facebook-detail-account-box-1.box-channel-button .facebook-detail-topic .channel-button-wrapper {
          margin-top: 24px; }
      .integration-messenger-detail-account .integration-facebook-detail-account-box-1.box-channel-button .topic-wrapper {
        margin-top: 24px;
        width: 100%;
        display: block; }
      .integration-messenger-detail-account .integration-facebook-detail-account-box-1.box-channel-button .status-wrapper .status-value {
        color: rgba(25, 25, 25, 0.4); }
        .integration-messenger-detail-account .integration-facebook-detail-account-box-1.box-channel-button .status-wrapper .status-value.status-enabled {
          color: #19C700; }
      .integration-messenger-detail-account .integration-facebook-detail-account-box-1.box-channel-button .status-wrapper img {
        position: relative;
        top: -1px;
        margin-right: 4px; }
    .integration-messenger-detail-account .integration-facebook-detail-account-box-1 .integration-facebook-detail-account-box-1-top {
      padding: 10px 0 15px;
      border-bottom: solid 1px #dcdcdc;
      position: relative;
      font-size: 20px;
      letter-spacing: -0.6px;
      padding-right: 230px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis; }
      .integration-messenger-detail-account .integration-facebook-detail-account-box-1 .integration-facebook-detail-account-box-1-top .bot-name {
        font-size: 12px;
        margin-bottom: 0; }
      .integration-messenger-detail-account .integration-facebook-detail-account-box-1 .integration-facebook-detail-account-box-1-top .integration-facebook-detail-account-box-1-top-button {
        position: absolute;
        top: 0px;
        right: 0;
        display: inline-block; }
        .integration-messenger-detail-account .integration-facebook-detail-account-box-1 .integration-facebook-detail-account-box-1-top .integration-facebook-detail-account-box-1-top-button button {
          padding: 7px 24px;
          font-size: 16px;
          letter-spacing: -0.6px;
          height: 40px;
          margin-left: 8px; }
          .integration-messenger-detail-account .integration-facebook-detail-account-box-1 .integration-facebook-detail-account-box-1-top .integration-facebook-detail-account-box-1-top-button button.button-channel-detail {
            position: relative;
            top: 15px; }
          .integration-messenger-detail-account .integration-facebook-detail-account-box-1 .integration-facebook-detail-account-box-1-top .integration-facebook-detail-account-box-1-top-button button img {
            position: relative;
            top: -2px;
            margin-right: 4px; }
          .integration-messenger-detail-account .integration-facebook-detail-account-box-1 .integration-facebook-detail-account-box-1-top .integration-facebook-detail-account-box-1-top-button button svg {
            position: relative;
            top: -2px;
            margin-right: 4px; }
          .integration-messenger-detail-account .integration-facebook-detail-account-box-1 .integration-facebook-detail-account-box-1-top .integration-facebook-detail-account-box-1-top-button button .lds-ring {
            top: unset;
            right: unset;
            position: relative;
            top: 3px; }
            .integration-messenger-detail-account .integration-facebook-detail-account-box-1 .integration-facebook-detail-account-box-1-top .integration-facebook-detail-account-box-1-top-button button .lds-ring div {
              margin: 0;
              border-color: #ffffff transparent transparent transparent; }
    .integration-messenger-detail-account .integration-facebook-detail-account-box-1 .facebook-detail-topic {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 24px 0 0; }
      .integration-messenger-detail-account .integration-facebook-detail-account-box-1 .facebook-detail-topic .channel-button-wrapper {
        margin-top: 24px;
        width: 300px;
        word-break: break-word; }
      .integration-messenger-detail-account .integration-facebook-detail-account-box-1 .facebook-detail-topic .topic-wrapper {
        display: flex;
        flex-direction: column; }
        .integration-messenger-detail-account .integration-facebook-detail-account-box-1 .facebook-detail-topic .topic-wrapper .topic-title {
          font-size: 14px; }
      .integration-messenger-detail-account .integration-facebook-detail-account-box-1 .facebook-detail-topic button {
        padding: 7px 24px;
        font-size: 16px;
        letter-spacing: -0.6px;
        height: 40px;
        margin-left: 8px; }
        .integration-messenger-detail-account .integration-facebook-detail-account-box-1 .facebook-detail-topic button svg {
          position: relative;
          top: -2px;
          margin-right: 4px; }
    .integration-messenger-detail-account .integration-facebook-detail-account-box-1 .facebook-bot-token-status {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 24px 0 0; }
      .integration-messenger-detail-account .integration-facebook-detail-account-box-1 .facebook-bot-token-status .bot-status-wrapper .bot-status-title {
        font-size: 14px; }
      .integration-messenger-detail-account .integration-facebook-detail-account-box-1 .facebook-bot-token-status .bot-status-wrapper .status-wrapper {
        display: flex;
        align-items: center; }
        .integration-messenger-detail-account .integration-facebook-detail-account-box-1 .facebook-bot-token-status .bot-status-wrapper .status-wrapper img {
          margin-right: 5px;
          position: relative;
          top: -2px; }
        .integration-messenger-detail-account .integration-facebook-detail-account-box-1 .facebook-bot-token-status .bot-status-wrapper .status-wrapper .status-text {
          font-size: 16px; }
        .integration-messenger-detail-account .integration-facebook-detail-account-box-1 .facebook-bot-token-status .bot-status-wrapper .status-wrapper .text-green {
          color: #19c700; }
        .integration-messenger-detail-account .integration-facebook-detail-account-box-1 .facebook-bot-token-status .bot-status-wrapper .status-wrapper .text-red {
          color: #ff3f57; }
      .integration-messenger-detail-account .integration-facebook-detail-account-box-1 .facebook-bot-token-status .button-wrapper button {
        padding: 7px 24px;
        font-size: 16px;
        letter-spacing: -0.6px;
        height: 40px;
        margin-left: 8px;
        font-family: PTRootBold; }
        .integration-messenger-detail-account .integration-facebook-detail-account-box-1 .facebook-bot-token-status .button-wrapper button svg {
          position: relative;
          top: -2px;
          margin-right: 4px; }
    .integration-messenger-detail-account .integration-facebook-detail-account-box-1.box-channel-button {
      margin-top: 24px; }
      .integration-messenger-detail-account .integration-facebook-detail-account-box-1.box-channel-button .facebook-detail-topic {
        display: block; }
      .integration-messenger-detail-account .integration-facebook-detail-account-box-1.box-channel-button .topic-wrapper {
        margin-top: 24px;
        width: 100%;
        display: block; }
      .integration-messenger-detail-account .integration-facebook-detail-account-box-1.box-channel-button .status-wrapper .status-value {
        color: rgba(25, 25, 25, 0.4); }
        .integration-messenger-detail-account .integration-facebook-detail-account-box-1.box-channel-button .status-wrapper .status-value.status-enabled {
          color: #19C700; }
      .integration-messenger-detail-account .integration-facebook-detail-account-box-1.box-channel-button .status-wrapper img {
        position: relative;
        top: -1px;
        margin-right: 4px; }


.integration-messenger-edit-topic-account {
  padding: 32px 30px;
  text-align: left; }
  .integration-messenger-edit-topic-account .comp-back-button {
    margin: 0 0 16px 0;
    font-family: PTRootBold; }
  .integration-messenger-edit-topic-account .integration-messenger-edit-topic-account-box {
    background: #ffffff;
    border-radius: 8px;
    padding: 32px;
    border: solid 1px rgba(25, 25, 25, 0.1); }
    .integration-messenger-edit-topic-account .integration-messenger-edit-topic-account-box .integration-messenger-edit-topic-account-box-top {
      padding-bottom: 15px;
      border-bottom: solid 1px #dcdcdc;
      position: relative;
      font-size: 20px;
      letter-spacing: -0.6px; }
      .integration-messenger-edit-topic-account .integration-messenger-edit-topic-account-box .integration-messenger-edit-topic-account-box-top button {
        position: absolute;
        top: -6px;
        right: 0;
        font-size: 16px;
        letter-spacing: -0.6px; }
    .integration-messenger-edit-topic-account .integration-messenger-edit-topic-account-box .integration-messenger-edit-topic-account-box-form {
      width: 100%; }
      .integration-messenger-edit-topic-account .integration-messenger-edit-topic-account-box .integration-messenger-edit-topic-account-box-form label {
        padding: 24px 0 0;
        font-size: 14px;
        letter-spacing: -0.4px;
        display: block; }
      .integration-messenger-edit-topic-account .integration-messenger-edit-topic-account-box .integration-messenger-edit-topic-account-box-form input[type='text'] {
        padding: 12px 16px;
        border-radius: 6px;
        border: solid 1px #dcdcdc;
        width: 100%;
        font-size: 16px;
        letter-spacing: -0.3px;
        display: block; }
      .integration-messenger-edit-topic-account .integration-messenger-edit-topic-account-box .integration-messenger-edit-topic-account-box-form .form-topic {
        width: 620px !important; }
      .integration-messenger-edit-topic-account .integration-messenger-edit-topic-account-box .integration-messenger-edit-topic-account-box-form input[type='text']:disabled {
        background: rgba(25, 25, 25, 0.1);
        color: rgba(25, 25, 25, 0.2);
        border: none;
        cursor: not-allowed; }
      .integration-messenger-edit-topic-account .integration-messenger-edit-topic-account-box .integration-messenger-edit-topic-account-box-form .integration-info-form {
        font-size: 14px;
        color: rgba(25, 25, 25, 0.8);
        letter-spacing: -0.2px; }
      .integration-messenger-edit-topic-account .integration-messenger-edit-topic-account-box .integration-messenger-edit-topic-account-box-form .custom-select-messenger-topic .custom-select-option-value-wrapper {
        padding: 12px 16px !important;
        margin-bottom: 8px; }

.integration-Facebook-add-account {
  padding: 32px 30px;
  text-align: left; }
  .integration-Facebook-add-account .comp-back-button {
    margin: 0 0 16px 0; }
  .integration-Facebook-add-account .integration-Facebook-add-account-box {
    background: #ffffff;
    border-radius: 8px;
    padding: 32px;
    border: solid 1px rgba(25, 25, 25, 0.1); }
    .integration-Facebook-add-account .integration-Facebook-add-account-box .top-component {
      padding-top: 24px; }
      .integration-Facebook-add-account .integration-Facebook-add-account-box .top-component .alert-component {
        border: 1px solid #044b84;
        background: #DCEBFF;
        color: #044b84; }
        .integration-Facebook-add-account .integration-Facebook-add-account-box .top-component .alert-component a {
          color: #044b84; }
          .integration-Facebook-add-account .integration-Facebook-add-account-box .top-component .alert-component a:hover {
            text-decoration: underline !important; }
    .integration-Facebook-add-account .integration-Facebook-add-account-box .facebook-component {
      padding-top: 24px; }
      .integration-Facebook-add-account .integration-Facebook-add-account-box .facebook-component p {
        margin: 0; }
      .integration-Facebook-add-account .integration-Facebook-add-account-box .facebook-component ul {
        padding-left: 18px; }
      .integration-Facebook-add-account .integration-Facebook-add-account-box .facebook-component a {
        color: #ff7d00; }
        .integration-Facebook-add-account .integration-Facebook-add-account-box .facebook-component a:hover {
          text-decoration: underline !important; }
    .integration-Facebook-add-account .integration-Facebook-add-account-box .integration-Facebook-add-account-box-top {
      padding-bottom: 15px;
      border-bottom: solid 1px #dcdcdc;
      position: relative;
      font-size: 20px;
      letter-spacing: -0.6px; }
      .integration-Facebook-add-account .integration-Facebook-add-account-box .integration-Facebook-add-account-box-top button {
        position: absolute;
        top: -6px;
        right: 0;
        font-size: 16px;
        letter-spacing: -0.6px; }
    .integration-Facebook-add-account .integration-Facebook-add-account-box .integration-Facebook-add-account-box-form {
      width: 320px; }
      .integration-Facebook-add-account .integration-Facebook-add-account-box .integration-Facebook-add-account-box-form label {
        padding: 24px 0 0;
        font-size: 14px;
        letter-spacing: -0.4px;
        display: block; }
      .integration-Facebook-add-account .integration-Facebook-add-account-box .integration-Facebook-add-account-box-form input[type="text"] {
        padding: 12px 16px;
        border-radius: 6px;
        border: solid 1px #dcdcdc;
        width: 100%;
        font-size: 16px;
        letter-spacing: -0.3px;
        display: block;
        margin-bottom: 8px; }
      .integration-Facebook-add-account .integration-Facebook-add-account-box .integration-Facebook-add-account-box-form .integration-info-form {
        font-size: 14px;
        color: rgba(25, 25, 25, 0.8);
        letter-spacing: -0.2px; }
      .integration-Facebook-add-account .integration-Facebook-add-account-box .integration-Facebook-add-account-box-form .custom-select-Facebook-topic .custom-select-option-value-wrapper {
        padding: 12px 16px !important;
        margin-bottom: 8px; }

.chatbot-add-chatbot {
  padding: 32px 30px;
  text-align: left; }
  .chatbot-add-chatbot .comp-back-button {
    margin: 0 0 16px 0; }
  .chatbot-add-chatbot .chatbot-add-chatbot-box {
    background: #ffffff;
    border-radius: 8px;
    padding: 32px;
    border: solid 1px rgba(25, 25, 25, 0.1); }
    .chatbot-add-chatbot .chatbot-add-chatbot-box .chatbot-add-chatbot-box-top {
      padding-bottom: 15px;
      border-bottom: solid 1px #dcdcdc;
      position: relative;
      font-size: 20px;
      letter-spacing: -0.6px; }
      .chatbot-add-chatbot .chatbot-add-chatbot-box .chatbot-add-chatbot-box-top .chat-bot-chatbot-button-wrapper {
        margin: 0; }
      .chatbot-add-chatbot .chatbot-add-chatbot-box .chatbot-add-chatbot-box-top button {
        position: absolute;
        top: -6px;
        right: 0;
        margin: 0 !important;
        font-size: 16px;
        letter-spacing: -0.6px; }
    .chatbot-add-chatbot .chatbot-add-chatbot-box .chatbot-add-chatbot-box-form {
      width: 320px; }
      .chatbot-add-chatbot .chatbot-add-chatbot-box .chatbot-add-chatbot-box-form label {
        padding: 24px 0 0;
        font-size: 14px;
        letter-spacing: -0.4px;
        display: block; }
      .chatbot-add-chatbot .chatbot-add-chatbot-box .chatbot-add-chatbot-box-form input[type="text"] {
        padding: 12px 16px;
        border-radius: 6px;
        border: solid 1px #dcdcdc;
        width: 100%;
        font-size: 16px;
        letter-spacing: -0.3px;
        display: block;
        margin-bottom: 8px; }
      .chatbot-add-chatbot .chatbot-add-chatbot-box .chatbot-add-chatbot-box-form .integration-info-form {
        font-size: 14px;
        color: rgba(25, 25, 25, 0.8);
        letter-spacing: -0.2px; }
      .chatbot-add-chatbot .chatbot-add-chatbot-box .chatbot-add-chatbot-box-form .custom-select-path .custom-select-option-value-wrapper {
        padding: 12px 16px !important;
        margin-bottom: 8px; }
    .chatbot-add-chatbot .chatbot-add-chatbot-box .chat-bot-chatbot-button-wrapper {
      margin-top: 18px; }
      .chatbot-add-chatbot .chatbot-add-chatbot-box .chat-bot-chatbot-button-wrapper button {
        margin: 0 4px 0 0; }

.integration-livechat-customize {
  padding: 32px 30px;
  text-align: left; }
  .integration-livechat-customize .integration-livechat-detail-account-box-1 {
    background: #ffffff;
    border-radius: 8px;
    padding: 22px 32px 22px;
    border: solid 1px rgba(25, 25, 25, 0.1);
    position: relative; }
    .integration-livechat-customize .integration-livechat-detail-account-box-1.box-channel-button {
      margin-top: 24px;
      margin-bottom: 24px; }
    .integration-livechat-customize .integration-livechat-detail-account-box-1 .livechat-detail-channel-button .channel-button-wrapper {
      margin-top: 24px;
      width: 300px;
      word-break: break-word; }
    .integration-livechat-customize .integration-livechat-detail-account-box-1 .status-wrapper .status-value {
      color: rgba(25, 25, 25, 0.4); }
      .integration-livechat-customize .integration-livechat-detail-account-box-1 .status-wrapper .status-value.status-enabled {
        color: #19C700; }
    .integration-livechat-customize .integration-livechat-detail-account-box-1 .status-wrapper img {
      position: relative;
      top: -1px;
      margin-right: 4px; }
    .integration-livechat-customize .integration-livechat-detail-account-box-1 .integration-livechat-detail-account-box-1-top {
      padding: 10px 0 18px;
      border-bottom: solid 1px #dcdcdc;
      position: relative;
      font-size: 20px;
      letter-spacing: -0.6px;
      padding-right: 310px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis; }
      .integration-livechat-customize .integration-livechat-detail-account-box-1 .integration-livechat-detail-account-box-1-top .bot-name {
        margin-bottom: 0; }
      .integration-livechat-customize .integration-livechat-detail-account-box-1 .integration-livechat-detail-account-box-1-top p {
        font-size: 14px;
        letter-spacing: -0.2px; }
      .integration-livechat-customize .integration-livechat-detail-account-box-1 .integration-livechat-detail-account-box-1-top .integration-livechat-detail-account-box-1-top-button {
        position: absolute;
        top: 0px;
        right: 0;
        display: inline-block; }
        .integration-livechat-customize .integration-livechat-detail-account-box-1 .integration-livechat-detail-account-box-1-top .integration-livechat-detail-account-box-1-top-button button {
          padding: 7px 24px;
          font-size: 16px;
          letter-spacing: -0.6px;
          height: 40px;
          margin-left: 8px; }
          .integration-livechat-customize .integration-livechat-detail-account-box-1 .integration-livechat-detail-account-box-1-top .integration-livechat-detail-account-box-1-top-button button.button-channel-detail {
            position: relative;
            top: 2px; }
          .integration-livechat-customize .integration-livechat-detail-account-box-1 .integration-livechat-detail-account-box-1-top .integration-livechat-detail-account-box-1-top-button button svg {
            position: relative;
            top: -2px;
            margin-right: 4px; }
          .integration-livechat-customize .integration-livechat-detail-account-box-1 .integration-livechat-detail-account-box-1-top .integration-livechat-detail-account-box-1-top-button button .lds-ring {
            top: unset;
            right: unset;
            position: relative;
            top: 3px; }
            .integration-livechat-customize .integration-livechat-detail-account-box-1 .integration-livechat-detail-account-box-1-top .integration-livechat-detail-account-box-1-top-button button .lds-ring div {
              margin: 0;
              border-color: #ffffff transparent transparent transparent; }
  .integration-livechat-customize .select-faq-wrapper label {
    position: relative; }
  .integration-livechat-customize .select-faq-wrapper .button-clear-scf-path {
    position: absolute;
    right: 0;
    bottom: 0;
    color: var(--watermelon);
    cursor: pointer; }
    .integration-livechat-customize .select-faq-wrapper .button-clear-scf-path:hover {
      color: var(--watermelon-dark); }
  .integration-livechat-customize .select-faq-wrapper #tooltip-scf {
    position: relative;
    top: -2px;
    margin-left: 4px; }
  .integration-livechat-customize .select-faq-wrapper button {
    margin: 0; }
  .integration-livechat-customize .select-faq-wrapper .create-faq-button {
    color: #ff7d00; }
    .integration-livechat-customize .select-faq-wrapper .create-faq-button svg {
      position: relative;
      top: -2px;
      margin-right: 4px; }
    .integration-livechat-customize .select-faq-wrapper .create-faq-button:hover {
      color: var(--main-darker-orange); }
  .integration-livechat-customize .comp-back-button {
    margin: 0 0 16px 0;
    font-family: PTRootBold; }
  .integration-livechat-customize .integration-livechat-customize-box {
    background: #ffffff;
    border-radius: 8px;
    padding: 32px;
    border: solid 1px rgba(25, 25, 25, 0.1);
    margin-bottom: 12px; }
    .integration-livechat-customize .integration-livechat-customize-box .integration-livechat-customize-box-top {
      padding-bottom: 15px;
      border-bottom: solid 1px #dcdcdc;
      position: relative;
      font-size: 20px;
      letter-spacing: -0.6px; }
      .integration-livechat-customize .integration-livechat-customize-box .integration-livechat-customize-box-top .title-wrapper {
        display: block;
        position: relative; }
        .integration-livechat-customize .integration-livechat-customize-box .integration-livechat-customize-box-top .title-wrapper img {
          width: 24px;
          height: 24px;
          top: 2px;
          left: 0;
          position: absolute; }
        .integration-livechat-customize .integration-livechat-customize-box .integration-livechat-customize-box-top .title-wrapper .title-description {
          display: block;
          padding-left: 32px;
          position: relative;
          width: calc(100% - 280px); }
          .integration-livechat-customize .integration-livechat-customize-box .integration-livechat-customize-box-top .title-wrapper .title-description p {
            margin: 0; }
          .integration-livechat-customize .integration-livechat-customize-box .integration-livechat-customize-box-top .title-wrapper .title-description .title-customize {
            font-size: 20px; }
          .integration-livechat-customize .integration-livechat-customize-box .integration-livechat-customize-box-top .title-wrapper .title-description .description-customize {
            font-size: 14px;
            overflow: hidden;
            text-overflow: ellipsis; }
            .integration-livechat-customize .integration-livechat-customize-box .integration-livechat-customize-box-top .title-wrapper .title-description .description-customize a {
              color: #ff7e00; }
      .integration-livechat-customize .integration-livechat-customize-box .integration-livechat-customize-box-top button {
        position: absolute;
        top: -6px;
        right: 0;
        font-size: 16px;
        letter-spacing: -0.6px; }
        .integration-livechat-customize .integration-livechat-customize-box .integration-livechat-customize-box-top button.red-button {
          right: 148px; }
        .integration-livechat-customize .integration-livechat-customize-box .integration-livechat-customize-box-top button .button-text {
          margin-left: 4px; }
    .integration-livechat-customize .integration-livechat-customize-box .integration-livechat-customize-box-form {
      width: 100%; }
      .integration-livechat-customize .integration-livechat-customize-box .integration-livechat-customize-box-form .alert-component {
        border-radius: 8px;
        position: relative;
        padding: 12px 48px;
        letter-spacing: -0.5px;
        font-size: 16px;
        display: inline-block;
        vertical-align: top;
        margin-top: 24px; }
        .integration-livechat-customize .integration-livechat-customize-box .integration-livechat-customize-box-form .alert-component .text-alert {
          font-size: 16px;
          color: #044b84 !important; }
        .integration-livechat-customize .integration-livechat-customize-box .integration-livechat-customize-box-form .alert-component svg {
          position: absolute;
          font-size: 24px;
          left: 16px;
          top: 50%;
          -webkit-transform: translate(0, -50%);
                  transform: translate(0, -50%); }
        .integration-livechat-customize .integration-livechat-customize-box .integration-livechat-customize-box-form .alert-component img {
          position: absolute;
          font-size: 24px;
          left: 16px;
          top: 50%;
          -webkit-transform: translate(0, -50%);
                  transform: translate(0, -50%); }
        .integration-livechat-customize .integration-livechat-customize-box .integration-livechat-customize-box-form .alert-component.alert-blue {
          color: #044b84;
          background: #c5dffe;
          border: solid 1px #044b84; }
      .integration-livechat-customize .integration-livechat-customize-box .integration-livechat-customize-box-form .taptalk-snippet {
        width: 100%;
        background: #272822;
        text-align: left;
        border-radius: 4px;
        margin: 15px 0 0 0;
        display: block;
        padding: 10px;
        color: #ffffff;
        resize: none; }
        .integration-livechat-customize .integration-livechat-customize-box .integration-livechat-customize-box-form .taptalk-snippet pre {
          overflow-x: hidden !important;
          text-overflow: ellipsis; }
      .integration-livechat-customize .integration-livechat-customize-box .integration-livechat-customize-box-form .form-customize {
        display: flex;
        margin: 24px 0; }
        .integration-livechat-customize .integration-livechat-customize-box .integration-livechat-customize-box-form .form-customize input[type='text'] {
          padding: 16px;
          border-radius: 6px;
          border: solid 1px #dcdcdc;
          width: 100%;
          font-size: 16px;
          letter-spacing: -0.3px;
          display: block; }
        .integration-livechat-customize .integration-livechat-customize-box .integration-livechat-customize-box-form .form-customize .input-customize {
          width: 50%;
          padding-right: 10px; }
          .integration-livechat-customize .integration-livechat-customize-box .integration-livechat-customize-box-form .form-customize .input-customize[disabled] {
            border: none !important; }
          .integration-livechat-customize .integration-livechat-customize-box .integration-livechat-customize-box-form .form-customize .input-customize .color-input-wrapper {
            position: relative;
            width: 166px;
            height: 48px;
            border-radius: 8px;
            border: solid 1px #dcdcdc;
            cursor: pointer;
            line-height: 47px;
            font-size: 16px;
            letter-spacing: -0.3px;
            color: #191919;
            padding: 0 0 0 16px;
            margin-top: 12px; }
            .integration-livechat-customize .integration-livechat-customize-box .integration-livechat-customize-box-form .form-customize .input-customize .color-input-wrapper .color-placeholder {
              color: rgba(25, 25, 25, 0.6); }
            .integration-livechat-customize .integration-livechat-customize-box .integration-livechat-customize-box-form .form-customize .input-customize .color-input-wrapper img {
              position: absolute;
              position: absolute;
              right: 16px;
              top: 50%;
              -webkit-transform: translate(0, -50%);
                      transform: translate(0, -50%); }
            .integration-livechat-customize .integration-livechat-customize-box .integration-livechat-customize-box-form .form-customize .input-customize .color-input-wrapper .color-tag {
              display: inline-block;
              margin-right: 1px; }
            .integration-livechat-customize .integration-livechat-customize-box .integration-livechat-customize-box-form .form-customize .input-customize .color-input-wrapper .color-value {
              width: 16px;
              height: 16px;
              display: inline-block;
              background: #dcdcdc;
              border-radius: 50%;
              position: absolute;
              right: 16px;
              top: 50%;
              -webkit-transform: translate(0, -50%);
                      transform: translate(0, -50%); }
            .integration-livechat-customize .integration-livechat-customize-box .integration-livechat-customize-box-form .form-customize .input-customize .color-input-wrapper .color-input {
              border: none !important;
              padding: 0;
              background-color: transparent;
              height: 20px;
              margin-top: 0 !important;
              height: 40px !important;
              display: inline-block;
              width: 125px !important; }
            .integration-livechat-customize .integration-livechat-customize-box .integration-livechat-customize-box-form .form-customize .input-customize .color-input-wrapper .color-picker-container {
              background: #ffffff;
              position: absolute;
              bottom: 0;
              right: -265px;
              border-radius: 4px;
              box-shadow: 0 2px 20px 0 rgba(25, 25, 25, 0.18) !important;
              padding: 14px;
              z-index: 9999; }
              .integration-livechat-customize .integration-livechat-customize-box .integration-livechat-customize-box-form .form-customize .input-customize .color-input-wrapper .color-picker-container .close-color-picker {
                position: absolute;
                top: 6px;
                font-size: 14px;
                right: 6px;
                color: rgba(25, 25, 25, 0.6); }
                .integration-livechat-customize .integration-livechat-customize-box .integration-livechat-customize-box-form .form-customize .input-customize .color-input-wrapper .color-picker-container .close-color-picker:hover {
                  color: rgba(25, 25, 25, 0.8); }
              .integration-livechat-customize .integration-livechat-customize-box .integration-livechat-customize-box-form .form-customize .input-customize .color-input-wrapper .color-picker-container::before {
                position: absolute;
                background: #ffffff;
                width: 16px;
                height: 16px;
                content: '';
                left: -8px;
                -webkit-transform: rotate(45deg);
                        transform: rotate(45deg);
                bottom: 17px; }
              .integration-livechat-customize .integration-livechat-customize-box .integration-livechat-customize-box-form .form-customize .input-customize .color-input-wrapper .color-picker-container .sketch-picker {
                border-radius: 4px;
                box-shadow: none !important; }
                .integration-livechat-customize .integration-livechat-customize-box .integration-livechat-customize-box-form .form-customize .input-customize .color-input-wrapper .color-picker-container .sketch-picker .flexbox-fix:nth-child(2) {
                  height: 28px; }
                  .integration-livechat-customize .integration-livechat-customize-box .integration-livechat-customize-box-form .form-customize .input-customize .color-input-wrapper .color-picker-container .sketch-picker .flexbox-fix:nth-child(2) div:nth-child(1) div:nth-child(1) {
                    height: 23px !important; }
                    .integration-livechat-customize .integration-livechat-customize-box .integration-livechat-customize-box-form .form-customize .input-customize .color-input-wrapper .color-picker-container .sketch-picker .flexbox-fix:nth-child(2) div:nth-child(1) div:nth-child(1) div div.hue-horizontal div div {
                      display: block !important;
                      margin-top: 0 !important; }
                  .integration-livechat-customize .integration-livechat-customize-box .integration-livechat-customize-box-form .form-customize .input-customize .color-input-wrapper .color-picker-container .sketch-picker .flexbox-fix:nth-child(2) div:nth-child(1) div:nth-child(2) div {
                    display: none; }
                .integration-livechat-customize .integration-livechat-customize-box .integration-livechat-customize-box-form .form-customize .input-customize .color-input-wrapper .color-picker-container .sketch-picker .flexbox-fix:nth-child(3) {
                  display: none !important; }
                .integration-livechat-customize .integration-livechat-customize-box .integration-livechat-customize-box-form .form-customize .input-customize .color-input-wrapper .color-picker-container .sketch-picker .flexbox-fix:nth-child(4) div div {
                  border-radius: 50% !important; }
          .integration-livechat-customize .integration-livechat-customize-box .integration-livechat-customize-box-form .form-customize .input-customize .input-color p {
            font-size: 14px;
            margin: 0; }
          .integration-livechat-customize .integration-livechat-customize-box .integration-livechat-customize-box-form .form-customize .input-customize .input-color input[type='text'] {
            margin-top: 12px;
            width: 166px;
            height: 48px; }
        .integration-livechat-customize .integration-livechat-customize-box .integration-livechat-customize-box-form .form-customize .preview-customize {
          width: 50%;
          padding-left: 10px;
          display: flex;
          flex-direction: column;
          align-items: flex-end; }
          .integration-livechat-customize .integration-livechat-customize-box .integration-livechat-customize-box-form .form-customize .preview-customize .open-case-switch-wrapper {
            width: 100%;
            max-width: 360px;
            height: 47px;
            background: #ffffff;
            position: relative;
            text-align: center;
            padding: 9px 16px;
            box-shadow: none;
            z-index: 1;
            margin-bottom: 16px; }
            .integration-livechat-customize .integration-livechat-customize-box .integration-livechat-customize-box-form .form-customize .preview-customize .open-case-switch-wrapper .open-case-switch-content {
              width: 100%;
              border-radius: 4px;
              height: 29px;
              border: none;
              position: relative;
              background: #f3f3f3;
              cursor: pointer;
              display: flex; }
              .integration-livechat-customize .integration-livechat-customize-box .integration-livechat-customize-box-form .form-customize .preview-customize .open-case-switch-wrapper .open-case-switch-content .open-case-active-panel {
                width: 33%;
                content: '';
                background: #ffffff;
                border-radius: 4px;
                height: 27px;
                position: absolute;
                left: 0;
                top: 0;
                -webkit-transition: ease-in-out 0.2s;
                transition: ease-in-out 0.2s; }
                .integration-livechat-customize .integration-livechat-customize-box .integration-livechat-customize-box-form .form-customize .preview-customize .open-case-switch-wrapper .open-case-switch-content .open-case-active-panel.active-mine-case-panel {
                  left: 0; }
              .integration-livechat-customize .integration-livechat-customize-box .integration-livechat-customize-box-form .form-customize .preview-customize .open-case-switch-wrapper .open-case-switch-content .open-case-switch-panel {
                width: 33%;
                display: inline-block;
                text-align: center;
                font-size: 14px;
                letter-spacing: -0.4px;
                color: rgba(25, 25, 25, 0.6);
                height: 29px;
                border-radius: 4px;
                flex-wrap: nowrap;
                outline: none;
                border: none;
                padding-top: 1px; }
                .integration-livechat-customize .integration-livechat-customize-box .integration-livechat-customize-box-form .form-customize .preview-customize .open-case-switch-wrapper .open-case-switch-content .open-case-switch-panel:hover {
                  color: #191919; }
                .integration-livechat-customize .integration-livechat-customize-box .integration-livechat-customize-box-form .form-customize .preview-customize .open-case-switch-wrapper .open-case-switch-content .open-case-switch-panel.active-case-panel {
                  color: #191919;
                  background: #ffffff;
                  box-shadow: 0 2px 20px 0 rgba(25, 25, 25, 0.18); }
          .integration-livechat-customize .integration-livechat-customize-box .integration-livechat-customize-box-form .form-customize .preview-customize .taplive2-chat-widget-opener-button {
            background: #ff7d00;
            box-shadow: inset 1px -1px 10px 0px rgba(255, 255, 255, 0.12), 0px 2px 20px 0px rgba(25, 25, 25, 0.3);
            height: 64px;
            width: 64px;
            border-radius: 50%;
            cursor: pointer;
            position: relative;
            top: 32px;
            right: 0;
            box-sizing: border-box; }
            .integration-livechat-customize .integration-livechat-customize-box .integration-livechat-customize-box-form .form-customize .preview-customize .taplive2-chat-widget-opener-button img {
              position: absolute;
              left: 50%;
              top: 50%;
              -webkit-transform: translate(-50%, -50%);
                      transform: translate(-50%, -50%); }
            .integration-livechat-customize .integration-livechat-customize-box .integration-livechat-customize-box-form .form-customize .preview-customize .taplive2-chat-widget-opener-button:hover::before {
              content: '';
              height: 64px;
              width: 64px;
              position: absolute;
              border-radius: 50%;
              background: rgba(25, 25, 25, 0.1);
              top: 0;
              right: 0; }
      .integration-livechat-customize .integration-livechat-customize-box .integration-livechat-customize-box-form .form-secret-key {
        width: 75%; }
        .integration-livechat-customize .integration-livechat-customize-box .integration-livechat-customize-box-form .form-secret-key label {
          margin-top: 24px; }
        .integration-livechat-customize .integration-livechat-customize-box .integration-livechat-customize-box-form .form-secret-key form {
          border-radius: 6px;
          border: solid 1px #dcdcdc;
          display: flex;
          justify-content: space-between;
          padding: 12px 16px; }
          .integration-livechat-customize .integration-livechat-customize-box .integration-livechat-customize-box-form .form-secret-key form img {
            cursor: pointer; }
          .integration-livechat-customize .integration-livechat-customize-box .integration-livechat-customize-box-form .form-secret-key form input[type='text'] {
            border: none;
            outline: none;
            width: 90%;
            font-size: 16px;
            letter-spacing: -0.3px;
            text-overflow: ellipsis; }
          .integration-livechat-customize .integration-livechat-customize-box .integration-livechat-customize-box-form .form-secret-key form button {
            border: none;
            background: transparent; }
      .integration-livechat-customize .integration-livechat-customize-box .integration-livechat-customize-box-form label {
        padding: 0;
        font-size: 14px;
        letter-spacing: -0.4px;
        display: block;
        position: relative; }
      .integration-livechat-customize .integration-livechat-customize-box .integration-livechat-customize-box-form .custom-select-livechat-topic .custom-select-option-value-wrapper {
        padding: 12px 16px !important;
        margin-bottom: 8px; }

.preview-customize {
  /* .taplive2-main-chat-wrapper button[disabled] {
      cursor: not-allowed;
      color: rgba(25, 25, 25, 0.2);
      background: rgba(25, 25, 25, 0.1);
  } */
  /* .taplive2-start-new-chat-wrapper .taplive2-start-new-chat-top-content p:nth-child(2) { */
  /* .taplive2-room-list-with-omnichannel .taplive2-start-new-chat-top-content p:nth-child(2) { */
  /* room list chat */
  /* room list chat */
  /* review */
  /* review */
  /* chat room */
  /* chat info */
  /* chat-info */
  /* chat date */
  /* message out text */
  /* message out text */
  /* message in text */
  /* message in text */
  /* message in image */
  /* message in image */
  /* message out image */
  /* message out image */
  /* message in video */
  /* message in video */
  /* message out video */
  /* message out video */
  /* message in file */
  /* message in file */
  /* message out file */
  /* message out file */
  /* send message */
  /* send messsage */
  /* chat room */
  /* circular progress */
  /* circular progress */
  /* custom select option */
  /* custom select option */
  /* omnichannel option */
  /* .omnichannel-option-image-wrapper:hover img {
      opacity: .5;
  } */
  /* omnichannel option */
  /*submit button*/
  /*submit button*/
  /*full loading*/
  /*full loading*/
  /*room list with omnichannel*/
  /*room list with omnichanel*/ }
  .preview-customize .taplive2-centered-dot {
    width: 2px;
    height: 2px;
    display: inline-block;
    vertical-align: top;
    background: rgba(25, 25, 25, 0.4);
    border-radius: 50%;
    position: relative;
    top: 8px;
    margin: 0 2px; }
  .preview-customize .taplive2-centered-dot-white {
    background: #ffffff; }
  .preview-customize .taplive2-lds-ring {
    display: inline-block;
    position: relative;
    width: 18px;
    height: 18px; }
  .preview-customize .taplive2-lds-ring.centered-taplive2-lds-ring {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  .preview-customize .taplive2-lds-ring.centered-taplive2-lds-ring div {
    margin: 0; }
  .preview-customize .taplive2-lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 18px;
    height: 18px;
    border: 2px solid #fff;
    border-radius: 50%;
    -webkit-animation: taplive2-lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
            animation: taplive2-lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #ffffff transparent transparent transparent; }
  .preview-customize .taplive2-lds-ring.white-taplive2-lds-ring div {
    border-color: #ffffff transparent transparent transparent; }
  .preview-customize .taplive2-lds-ring div:nth-child(1) {
    -webkit-animation-delay: -0.45s;
            animation-delay: -0.45s; }
  .preview-customize .taplive2-lds-ring div:nth-child(2) {
    -webkit-animation-delay: -0.3s;
            animation-delay: -0.3s; }
  .preview-customize .taplive2-lds-ring div:nth-child(3) {
    -webkit-animation-delay: -0.15s;
            animation-delay: -0.15s; }

@-webkit-keyframes taplive2-lds-ring {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes taplive2-lds-ring {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }
  .preview-customize .taplive2-snack-bar-wrapper {
    width: calc(100% - 30px);
    display: inline-block;
    border-radius: 8px;
    position: absolute;
    z-index: 4;
    /* height: 48px; */
    bottom: 12px;
    left: 50%;
    -webkit-transform: translate(-50%, 0);
            transform: translate(-50%, 0);
    display: none;
    padding: 13px 30px 13px 50px; }
  .preview-customize .taplive2-snack-bar-wrapper.taplive2-error-snack-bar {
    border: solid 1px #ff3f57;
    color: #ff3f57;
    background: #ffc0c0; }
  .preview-customize .taplive2-snack-bar-wrapper.taplive2-warning-snack-bar {
    border: solid 1px #ff7d00;
    color: #ff7d00;
    background: #fff4b1; }
  .preview-customize .taplive2-snack-bar-wrapper .taplive2-snack-bar-message {
    font-size: 16px;
    letter-spacing: -0.5px; }
  .preview-customize .taplive2-snack-bar-wrapper .taplive2-snack-bar-icon {
    position: absolute;
    width: 24px;
    height: 24px;
    cursor: pointer;
    top: 50%;
    -webkit-transform: translate(0, -50%);
            transform: translate(0, -50%);
    left: 16px;
    display: none; }
  .preview-customize .taplive2-snack-bar-wrapper .taplive2-close-snack-bar {
    position: absolute;
    width: 15px;
    height: 15px;
    cursor: pointer;
    top: 50%;
    -webkit-transform: translate(0, -50%);
            transform: translate(0, -50%);
    right: 16px; }
  .preview-customize .taplive2-main-chat-wrapper * {
    /* font-family: ptRootNormal; */
    font-family: 'Roboto', sans-serif;
    box-sizing: border-box !important; }
  .preview-customize .taplive2-main-chat-wrapper b {
    font-family: PTRootBold !important; }
  .preview-customize .taplive2-main-brand-button {
    background: #ff7e00;
    cursor: pointer; }
  .preview-customize .taplive2-main-brand-nofill-button {
    background: #ffffff;
    color: #ff7e00;
    border: solid 1px #ff7e00;
    cursor: pointer; }
  .preview-customize .taplive2-main-brand-button:hover {
    /* background: rgba(255, 126, 0, 0.8) !important; */ }
  .preview-customize .taplive2-main-brand-nofill-button:hover {
    /* background: rgba(255, 126, 0, 0.1) !important; */ }
  .preview-customize .taplive2-chat-widget-opener-button {
    background: #ff7e00;
    box-shadow: inset 1px -1px 10px 0px rgba(255, 255, 255, 0.12), 0px 2px 20px 0px rgba(25, 25, 25, 0.3);
    height: 64px;
    width: 64px;
    border-radius: 50%;
    cursor: pointer;
    position: fixed;
    bottom: 24px;
    right: 24px;
    z-index: 9990;
    box-sizing: border-box; }
  .preview-customize .taplive2-chat-widget-opener-button:hover::before {
    content: '';
    height: 64px;
    width: 64px;
    position: absolute;
    border-radius: 50%;
    background: rgba(25, 25, 25, 0.1);
    top: 0;
    left: 0; }
  .preview-customize .taplive2-chat-widget-opener-button img {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  .preview-customize .taplive2-close-widget-button {
    display: none; }
  .preview-customize .taplive2-outer-container {
    height: calc(100vh - 130px);
    /* width: 100%; */
    width: 375px;
    /* max-height: calc(100vh - 130px); */
    height: 445px;
    border-radius: 8px;
    box-shadow: 0px 2px 20px 0px rgba(25, 25, 25, 0.18);
    position: relative;
    /* right: 24px; */
    /* bottom: 110px; */
    /* right: 0; */
    font-family: Helvetica, Arial, sans-serif !important;
    z-index: 9990;
    background: #f3f3f3;
    display: none;
    overflow: hidden;
    text-align: left; }
  .preview-customize .active-taplive2-power .taplive2-main-chat-wrapper {
    height: calc(100% - 37px);
    width: 100%;
    position: relative;
    /* max-height: calc(100vh - 130px);
      border-radius: 6px;
      box-shadow: 0px 2px 20px 0px rgba(25, 25, 25, 0.18); */ }
  .preview-customize .taplive2-power {
    height: 36px;
    padding: 4px 0;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    box-sizing: border-box;
    background: #ffffff;
    box-shadow: 0 1px 0 0 rgba(25, 25, 25, 0.1);
    display: none; }
  .preview-customize .taplive2-power div {
    padding: 0 14px;
    border-radius: 4px;
    display: inline-block;
    padding: 0 14px;
    border-radius: 4px;
    display: inline-block;
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    cursor: pointer;
    white-space: nowrap;
    height: 28px;
    line-height: 28px; }
  .preview-customize .taplive2-power div:hover {
    background: rgba(255, 126, 0, 0.1); }
  .preview-customize .taplive2-power div a {
    text-decoration: none;
    color: rgba(25, 25, 25, 0.8);
    font-size: 14px;
    letter-spacing: -0.2px; }
  .preview-customize .taplive2-power div a img {
    vertical-align: top;
    position: relative;
    top: 9px;
    margin-left: 4px;
    width: 71px; }
  .preview-customize .active-taplive2-power .taplive2-power {
    display: block; }
  .preview-customize .taplive2-start-new-case-with-omnichannel-box {
    padding: 18px;
    border-radius: 8px;
    background: #ffffff; }
  @media screen and (min-width: 300px) and (max-width: 767px) {
    .preview-customize .taplive2-outer-container {
      width: 100vw;
      /* height: calc(100vh - 170px); */
      height: 100vh;
      max-height: 100vh;
      bottom: 0;
      top: 0;
      right: 0;
      border-radius: 0 !important; }
    .preview-customize .taplive2-start-new-chat-wrapper .taplive2-start-new-chat-top-content {
      border-radius: 0 !important; }
    .preview-customize .taplive2-room-list-with-omnichannel .taplive2-start-new-chat-top-content {
      border-radius: 0 !important; }
    .preview-customize .taplive2-room-list-wrapper .taplive2-room-list-top-content {
      border-radius: 0 !important; }
    .preview-customize .taplive2-room-list-wrapper {
      border-radius: 0 !important; }
    .preview-customize .taplive2-close-widget-button {
      display: inline-block;
      width: 32px;
      height: 32px;
      border-radius: 8px;
      cursor: pointer;
      -webkit-transition: ease-in-out 0.2s;
      transition: ease-in-out 0.2s;
      background: none;
      position: relative; }
    .preview-customize .taplive2-close-widget-button:active {
      background: rgba(25, 25, 25, 0.1); }
    .preview-customize .taplive2-close-widget-button img {
      position: absolute;
      left: 50%;
      top: 50%;
      -webkit-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%);
      width: 24px !important;
      height: 24px !important;
      display: inline-block; }
    .preview-customize .taplive2-room-list-top-content .taplive2-close-widget-button {
      position: absolute;
      right: 20px;
      top: 35px; }
    .preview-customize .taplive2-start-new-chat-top-content {
      padding-right: 62px !important; }
    .preview-customize .taplive2-start-new-chat-top-content .taplive2-close-widget-button {
      position: absolute;
      right: 24px;
      top: 24px; }
    .preview-customize .taplive2-main-chat-room-wrapper .taplive2-main-chat-room-header {
      border-radius: 0 !important; } }
  .preview-customize .taplive2-main-chat-wrapper * {
    outline: none; }
  .preview-customize .taplive2-main-chat-wrapper input[type='text']:focus,
  .preview-customize .taplive2-main-chat-wrapper textarea:focus {
    box-shadow: 0 0 0 1px #ff7e00; }
  .preview-customize .taplive2-start-new-case-wrapper {
    position: absolute !important;
    top: 0;
    right: -100%;
    -webkit-transition: ease-in-out 0.3s;
    transition: ease-in-out 0.3s; }
  .preview-customize .taplive2-start-new-case-wrapper.taplive2-active-start-new-case-wrapper {
    right: 0; }
  .preview-customize .taplive2-start-new-chat-wrapper {
    max-height: 100%;
    height: 100%;
    overflow-y: auto;
    width: 100%;
    position: relative; }
  .preview-customize .taplive2-start-new-case-wrapper .taplive2-start-new-chat-top-content {
    min-height: 150px; }
  .preview-customize .taplive2-start-new-chat-wrapper .taplive2-start-new-chat-top-content {
    background: #ff7e00;
    width: 100%;
    /* height: 220px; */
    color: #ffffff;
    border-radius: 8px 8px 0px 0px;
    /* padding: 30px 24px; */
    padding: 24px;
    box-sizing: border-box;
    position: relative; }
  .preview-customize .taplive2-start-new-chat-top-content-color-background {
    height: 220px; }
  .preview-customize .taplive2-start-new-chat-wrapper {
    background: #f1f1f1; }
  .preview-customize .taplive2-start-new-message-wrapper {
    max-height: unset;
    /* height: unset; */
    height: 100%;
    overflow: hidden;
    display: none;
    z-index: 1; }
  .preview-customize .taplive2-start-new-chat-wrapper
.taplive2-start-new-chat-top-content
.taplive2-start-new-chat-top-content-logo-wrapper {
    background: #ffffff;
    height: 48px;
    width: 48px;
    border-radius: 8px;
    position: relative;
    overflow: hidden; }
  .preview-customize .taplive2-start-new-chat-wrapper
.taplive2-start-new-chat-top-content
.taplive2-start-new-chat-top-content-logo-wrapper
img {
    width: 100%; }
  .preview-customize .taplive2-start-new-chat-wrapper
.taplive2-start-new-chat-top-content
p:nth-child(1) {
    font-size: 20px;
    letter-spacing: -0.6px;
    /* margin: 25px 0 8px; */
    margin: 0 0 7px; }
  .preview-customize .taplive2-start-new-chat-wrapper
.taplive2-start-new-chat-top-content
p:nth-child(2) {
    margin: 0;
    line-height: 24px;
    font-size: 16px;
    letter-spacing: -0.3px; }
  .preview-customize .taplive2-start-new-chat-wrapper
.taplive2-start-new-chat-top-content
p:nth-child(3) {
    font-size: 16px;
    letter-spacing: -0.3px;
    margin: 0; }
  .preview-customize .taplive2-start-new-chat-wrapper .taplive2-start-new-chat-bottom-content {
    width: 100%;
    box-sizing: border-box;
    padding: 0 24px;
    position: relative;
    top: -30px;
    /* top: -15px; */ }
  .preview-customize .taplive2-start-new-chat-bottom-content .taplive2-shimmer-room-list {
    width: 100%;
    height: 100%;
    position: relative; }
  .preview-customize .taplive2-shimmer-room-list-chat-content {
    width: 100%;
    position: relative;
    padding-top: 12px;
    font-size: 0; }
  .preview-customize .taplive2-avatar-shimmer-room-list {
    width: 52px;
    height: 52px;
    border-radius: 50%;
    margin: 0 12px 0 16px;
    display: inline-block;
    vertical-align: top; }
  .preview-customize .taplive2-shimmer-right-wrapper {
    width: calc(100% - 80px);
    display: inline-block; }
  .preview-customize .taplive2-shimmer-room-list-top {
    width: 100px;
    display: inline-block;
    height: 16px;
    margin-bottom: 8px; }
  .preview-customize .taplive2-shimmer-room-list-bottom {
    width: calc(100% - 16px);
    display: inline-block;
    height: 42px;
    margin-bottom: 12px; }
  .preview-customize .taplive2-shimmer-room-list-line {
    height: 2px;
    width: 100%; }
  .preview-customize .taplive2-shimmer-room-list .taplive2-shimmer-room-list-button-wrapper {
    padding: 12px 16px;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 1;
    background: #ffffff; }
  .preview-customize .taplive2-shimmer-room-list
.taplive2-shimmer-room-list-button-wrapper
.taplive2-shimmer-room-list-button-content {
    height: 48px;
    border-radius: 8px;
    width: 100%; }

@-webkit-keyframes taplivePlaceholderShimmer {
  0% {
    background-position: -468px 0; }
  100% {
    background-position: 468px 0; } }

@keyframes taplivePlaceholderShimmer {
  0% {
    background-position: -468px 0; }
  100% {
    background-position: 468px 0; } }
  .preview-customize .taplive2-shine {
    background: #f6f7f8;
    background-image: -webkit-gradient(linear, left top, right top, from(#f6f7f8), color-stop(20%, #edeef1), color-stop(40%, #f6f7f8), to(#f6f7f8));
    background-image: linear-gradient(to right, #f6f7f8 0%, #edeef1 20%, #f6f7f8 40%, #f6f7f8 100%);
    background-repeat: no-repeat;
    background-size: 800px 104px;
    display: inline-block;
    position: relative;
    -webkit-animation-duration: 1.5s;
            animation-duration: 1.5s;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
    -webkit-animation-name: taplivePlaceholderShimmer;
            animation-name: taplivePlaceholderShimmer;
    -webkit-animation-timing-function: linear;
            animation-timing-function: linear; }
  .preview-customize .taplive2-start-new-chat-wrapper
.taplive2-start-new-chat-bottom-content
.taplive2-start-new-chat-form-wrapper {
    padding: 18px;
    background: #ffffff;
    border-radius: 8px 8px 0 0;
    /* border-top: solid 8px rgba(255, 126, 0, 0.1); */
    /* max-height: calc(100% - 70px); */
    overflow-y: auto; }
  .preview-customize .taplive2-start-new-chat-wrapper
.taplive2-start-new-chat-bottom-content
.taplive2-start-new-chat-form-wrapper
label {
    display: block;
    font-size: 14px;
    letter-spacing: -0.4px; }
  .preview-customize .taplive2-start-new-chat-wrapper
.taplive2-start-new-chat-bottom-content
.taplive2-start-new-chat-form-wrapper
input[type='text'] {
    display: block;
    width: 100%;
    box-sizing: border-box;
    border-radius: 8px;
    border: 1px solid rgba(25, 25, 25, 0.1);
    font-size: 16px;
    letter-spacing: -0.3px;
    padding: 16px;
    margin: 8px 0 12px; }
  .preview-customize .taplive2-start-new-chat-wrapper
.taplive2-start-new-chat-bottom-content
.taplive2-start-new-chat-form-wrapper
select {
    display: block;
    width: 100%;
    box-sizing: border-box;
    border-radius: 8px;
    border: 1px solid rgba(25, 25, 25, 0.1);
    font-size: 16px;
    letter-spacing: -0.3px;
    padding: 16px 11px;
    margin: 8px 0 12px; }
  .preview-customize .taplive2-start-new-chat-wrapper
.taplive2-start-new-chat-bottom-content
.taplive2-start-new-chat-form-wrapper
textarea {
    display: block;
    width: 100%;
    box-sizing: border-box;
    border-radius: 8px;
    border: 1px solid rgba(25, 25, 25, 0.1);
    font-size: 16px;
    letter-spacing: -0.3px;
    padding: 16px;
    margin: 8px 0 12px;
    resize: none;
    font-family: Helvetica, Arial, sans-serif !important; }
  .preview-customize .taplive2-start-new-chat-wrapper
.taplive2-start-new-chat-bottom-content
.taplive2-start-new-chat-form-wrapper
.taplive2-submit-new-chat {
    color: #ffffff;
    background: #ff7e00;
    width: 100%;
    border-radius: 8px;
    border: none;
    font-size: 16px;
    letter-spacing: -0.5px;
    text-align: center;
    width: 100%;
    padding: 13px;
    margin-top: 10px;
    cursor: pointer; }
  .preview-customize .taplive2-submit-new-chat:hover {
    /* background: rgba(255, 126, 0, 0.8) !important; */ }
  .preview-customize .taplive2-start-new-chat-wrapper
.taplive2-start-new-chat-bottom-content-new-message {
    top: -150px; }
  .preview-customize .taplive2-start-new-chat-wrapper .taplive2-start-new-message-header {
    font-size: 20px;
    letter-spacing: -0.6px; }
  .preview-customize .taplive2-start-new-chat-wrapper .taplive2-start-new-message-header img {
    vertical-align: top;
    /* width: 25px; */
    margin-right: 5px;
    cursor: pointer;
    border-radius: 50%;
    position: relative;
    top: -1px;
    padding: 3px;
    -webkit-transition: ease-in-out 0.1s;
    transition: ease-in-out 0.1s; }
  .preview-customize .taplive2-start-new-chat-wrapper .taplive2-start-new-message-header img:hover {
    background: rgba(25, 25, 25, 0.3); }
  .preview-customize .taplive2-room-list-with-omnichannel {
    max-height: 100%;
    height: 100%;
    overflow-y: auto;
    width: 100%;
    position: relative; }
  .preview-customize .taplive2-roomlist-withomnichannel-omnichannel-list-wrapper {
    background: #ffffff;
    border-radius: 8px;
    padding: 18px; }
  .preview-customize .taplive2-start-new-case-wrapper .taplive2-start-new-chat-top-content {
    min-height: 150px; }
  .preview-customize .taplive2-room-list-with-omnichannel .taplive2-start-new-chat-top-content {
    background: #ff7e00;
    width: 100%;
    /* height: 220px; */
    color: #ffffff;
    border-radius: 8px 8px 0px 0px;
    /* padding: 30px 24px; */
    padding: 24px;
    box-sizing: border-box;
    position: relative; }
  .preview-customize .taplive2-room-list-with-omnichannel {
    background: #f1f1f1; }
  .preview-customize .taplive2-start-new-message-wrapper {
    max-height: unset;
    /* height: unset; */
    height: 100%;
    overflow: hidden;
    display: none;
    z-index: 1; }
  .preview-customize .taplive2-room-list-with-omnichannel
.taplive2-start-new-chat-top-content
.taplive2-start-new-chat-top-content-logo-wrapper {
    background: #ffffff;
    height: 48px;
    width: 48px;
    border-radius: 8px;
    position: relative;
    overflow: hidden; }
  .preview-customize .taplive2-room-list-with-omnichannel
.taplive2-start-new-chat-top-content
.taplive2-start-new-chat-top-content-logo-wrapper
img {
    width: 100%; }
  .preview-customize .taplive2-room-list-with-omnichannel
.taplive2-start-new-chat-top-content
p:nth-child(1) {
    font-size: 20px;
    letter-spacing: -0.6px;
    /* margin: 25px 0 8px; */
    margin: 0 0 7px; }
  .preview-customize .taplive2-room-list-with-omnichannel
.taplive2-start-new-chat-top-content
p:nth-child(2) {
    margin: 0;
    line-height: 24px;
    font-size: 16px;
    letter-spacing: -0.3px; }
  .preview-customize .taplive2-room-list-with-omnichannel
.taplive2-start-new-chat-top-content
p:nth-child(3) {
    font-size: 16px;
    letter-spacing: -0.3px;
    margin: 0; }
  .preview-customize .taplive2-room-list-with-omnichannel .taplive2-start-new-chat-bottom-content {
    width: 100%;
    box-sizing: border-box;
    padding: 0 24px;
    position: relative;
    top: -30px;
    /* top: -15px; */ }
  .preview-customize .taplive2-start-new-chat-bottom-content .taplive2-shimmer-room-list {
    width: 100%;
    height: 100%;
    position: relative; }
  .preview-customize .taplive2-shimmer-room-list-chat-content {
    width: 100%;
    position: relative;
    padding-top: 12px;
    font-size: 0; }
  .preview-customize .taplive2-avatar-shimmer-room-list {
    width: 52px;
    height: 52px;
    border-radius: 50%;
    margin: 0 12px 0 16px;
    display: inline-block;
    vertical-align: top; }
  .preview-customize .taplive2-shimmer-right-wrapper {
    width: calc(100% - 80px);
    display: inline-block; }
  .preview-customize .taplive2-shimmer-room-list-top {
    width: 100px;
    display: inline-block;
    height: 16px;
    margin-bottom: 8px; }
  .preview-customize .taplive2-shimmer-room-list-bottom {
    width: calc(100% - 16px);
    display: inline-block;
    height: 42px;
    margin-bottom: 12px; }
  .preview-customize .taplive2-shimmer-room-list-line {
    height: 2px;
    width: 100%; }
  .preview-customize .taplive2-shimmer-room-list .taplive2-shimmer-room-list-button-wrapper {
    padding: 12px 16px;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 1;
    background: #ffffff; }
  .preview-customize .taplive2-shimmer-room-list
.taplive2-shimmer-room-list-button-wrapper
.taplive2-shimmer-room-list-button-content {
    height: 48px;
    border-radius: 8px;
    width: 100%; }

@keyframes taplivePlaceholderShimmer {
  0% {
    background-position: -468px 0; }
  100% {
    background-position: 468px 0; } }
  .preview-customize .taplive2-shine {
    background: #f6f7f8;
    background-image: -webkit-gradient(linear, left top, right top, from(#f6f7f8), color-stop(20%, #edeef1), color-stop(40%, #f6f7f8), to(#f6f7f8));
    background-image: linear-gradient(to right, #f6f7f8 0%, #edeef1 20%, #f6f7f8 40%, #f6f7f8 100%);
    background-repeat: no-repeat;
    background-size: 800px 104px;
    display: inline-block;
    position: relative;
    -webkit-animation-duration: 1.5s;
            animation-duration: 1.5s;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
    -webkit-animation-name: taplivePlaceholderShimmer;
            animation-name: taplivePlaceholderShimmer;
    -webkit-animation-timing-function: linear;
            animation-timing-function: linear; }
  .preview-customize .taplive2-room-list-with-omnichannel
.taplive2-start-new-chat-bottom-content
.taplive2-start-new-chat-form-wrapper {
    padding: 18px;
    background: #ffffff;
    border-radius: 8px 8px 0 0;
    /* border-top: solid 8px rgba(255, 126, 0, 0.1); */
    /* max-height: calc(100% - 70px); */
    overflow-y: auto; }
  .preview-customize .taplive2-room-list-with-omnichannel
.taplive2-start-new-chat-bottom-content
.taplive2-start-new-chat-form-wrapper
label {
    display: block;
    font-size: 14px;
    letter-spacing: -0.4px; }
  .preview-customize .taplive2-room-list-with-omnichannel
.taplive2-start-new-chat-bottom-content
.taplive2-start-new-chat-form-wrapper
input[type='text'] {
    display: block;
    width: 100%;
    box-sizing: border-box;
    border-radius: 8px;
    border: 1px solid rgba(25, 25, 25, 0.1);
    font-size: 16px;
    letter-spacing: -0.3px;
    padding: 16px;
    margin: 8px 0 12px; }
  .preview-customize .taplive2-room-list-with-omnichannel
.taplive2-start-new-chat-bottom-content
.taplive2-start-new-chat-form-wrapper
select {
    display: block;
    width: 100%;
    box-sizing: border-box;
    border-radius: 8px;
    border: 1px solid rgba(25, 25, 25, 0.1);
    font-size: 16px;
    letter-spacing: -0.3px;
    padding: 16px 11px;
    margin: 8px 0 12px; }
  .preview-customize .taplive2-room-list-with-omnichannel
.taplive2-start-new-chat-bottom-content
.taplive2-start-new-chat-form-wrapper
textarea {
    display: block;
    width: 100%;
    box-sizing: border-box;
    border-radius: 8px;
    border: 1px solid rgba(25, 25, 25, 0.1);
    font-size: 16px;
    letter-spacing: -0.3px;
    padding: 16px;
    margin: 8px 0 12px;
    resize: none;
    font-family: Helvetica, Arial, sans-serif !important; }
  .preview-customize .taplive2-room-list-with-omnichannel
.taplive2-start-new-chat-bottom-content
.taplive2-start-new-chat-form-wrapper
.taplive2-submit-new-chat {
    color: #ffffff;
    background: #ff7e00;
    width: 100%;
    border-radius: 8px;
    border: none;
    font-size: 16px;
    letter-spacing: -0.5px;
    text-align: center;
    width: 100%;
    padding: 13px;
    margin-top: 10px;
    cursor: pointer; }
  .preview-customize .taplive2-submit-new-chat:hover {
    /* background: rgba(255, 126, 0, 0.8) !important; */ }
  .preview-customize .taplive2-room-list-with-omnichannel
.taplive2-start-new-chat-bottom-content-new-message {
    /* top: -150px; */ }
  .preview-customize .taplive2-room-list-with-omnichannel .taplive2-start-new-message-header {
    font-size: 20px;
    letter-spacing: -0.6px; }
  .preview-customize .taplive2-room-list-with-omnichannel .taplive2-start-new-message-header img {
    vertical-align: top;
    width: 25px;
    margin-right: 10px;
    cursor: pointer; }
  .preview-customize .taplive2-room-list-wrapper {
    width: 100%;
    border-radius: 8px 8px 0 0;
    max-height: 100%;
    overflow: hidden;
    right: -100%;
    position: absolute;
    top: 0;
    -webkit-transition: ease-in-out 0.3s;
    transition: ease-in-out 0.3s;
    background: #f3f3f3; }
  .preview-customize .taplive2-room-list-wrapper.taplive2-active-room-list-wrapper {
    right: 0; }
  .preview-customize .taplive2-room-list-wrapper .taplive2-room-list-top-content {
    padding: 24px;
    color: #ffffff;
    width: 100%;
    background: #ff7e00;
    border-radius: 8px 8px 0 0;
    box-sizing: border-box;
    height: 175px;
    position: relative; }
  .preview-customize .taplive2-room-list-wrapper .taplive2-room-list-top-content img {
    width: 48px;
    height: 48px;
    display: inline-block;
    border-radius: 8px;
    vertical-align: top; }
  .preview-customize .taplive2-room-list-wrapper
.taplive2-room-list-top-content
img.taplive2-room-list-back-button {
    width: unset;
    height: unset;
    display: inline-block;
    vertical-align: top;
    position: absolute;
    top: 36px;
    border-radius: 50%;
    left: 22px;
    cursor: pointer;
    padding: 3px;
    -webkit-transition: ease-in-out 0.1s;
    transition: ease-in-out 0.1s; }
  .preview-customize .taplive2-room-list-wrapper
.taplive2-room-list-top-content
img.taplive2-room-list-back-button:hover {
    background: rgba(25, 25, 25, 0.3); }
  .preview-customize .taplive2-room-list-top-content.taplive2-room-list-without-omnichannel
img.taplive2-room-list-back-button {
    display: none; }
  .preview-customize .taplive2-room-list-wrapper .taplive2-room-list-top-content b {
    font-size: 24px;
    letter-spacing: -0.6px;
    margin-left: 35px;
    position: relative;
    top: 11px; }
  .preview-customize .taplive2-room-list-top-content.taplive2-room-list-without-omnichannel b {
    margin-left: 0; }
  .preview-customize .taplive2-room-list-wrapper .taplive2-room-list-bottom-content {
    width: calc(100% - 50px);
    border-radius: 8px 8px 0 0;
    background: #ffffff;
    /* border-top: solid 8px rgba(255, 126, 0, 0.1); */
    position: relative;
    left: 24px;
    top: -90px;
    display: inline-block;
    height: calc(100vh - 216px);
    overflow-y: hidden; }
  .preview-customize .taplive2-room-list-wrapper
.taplive2-room-list-bottom-content
.taplive2-room-list-outer-container {
    max-height: calc(100% - 70px);
    overflow-y: auto; }
  @media screen and (min-width: 300px) and (max-width: 767px) {
    .preview-customize .taplive2-room-list-wrapper .taplive2-room-list-bottom-content {
      height: calc(100vh - 87px); }
    .preview-customize .active-taplive2-power
.taplive2-room-list-wrapper
.taplive2-room-list-bottom-content {
      /* height: calc(100vh - 301px); */
      height: calc(100vh - 132px); } }
  .preview-customize .taplive2-chat-list-wrapper {
    background: #fff;
    width: 100%;
    padding: 12px 0 0;
    cursor: pointer;
    font-size: 0; }
  .preview-customize .taplive2-chat-list-wrapper:hover {
    background: rgba(255, 126, 0, 0.1); }
  .preview-customize .taplive2-chat-list-wrapper.active-chat-list {
    background: #efeeee; }
  .preview-customize .taplive2-chat-list-wrapper .taplive2-chat-avatar-wrapper {
    width: 70px;
    display: inline-block;
    vertical-align: top;
    text-align: center;
    position: relative; }
  .preview-customize .taplive2-chat-list-wrapper .taplive2-chat-avatar-wrapper img {
    width: 52px;
    height: 52px;
    display: inline-block;
    border-radius: 50%;
    object-fit: cover; }
  .preview-customize .taplive2-chat-list-wrapper .taplive2-chat-avatar-wrapper img.group-badge {
    width: 20px;
    height: 20px;
    position: absolute;
    bottom: 1px;
    right: 11px; }
  .preview-customize .taplive2-chat-list-wrapper
.taplive2-chat-avatar-wrapper
.taplive2-user-avatar-name {
    border-radius: 50%;
    width: 52px;
    height: 52px;
    display: inline-block;
    text-align: center;
    font-size: 20px;
    letter-spacing: -0.6px;
    color: white;
    line-height: 52px; }
  .preview-customize .taplive2-chat-list-wrapper .taplive2-dialog-message-wrapper {
    width: calc(100% - 70px);
    display: inline-block;
    position: relative;
    border-bottom: solid 1px #dcdcdc;
    padding: 5px 0 12px 0; }
  .preview-customize .taplive2-chat-list-wrapper .taplive2-dialog-message-wrapper .taplive2-dialog-top {
    position: relative;
    width: 100%;
    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;
    padding-right: 70px;
    box-sizing: border-box; }
  .preview-customize .taplive2-chat-list-wrapper
.taplive2-dialog-message-wrapper
.taplive2-dialog-top
p {
    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;
    margin: 0; }
  .preview-customize .taplive2-chat-list-wrapper
.taplive2-dialog-message-wrapper
.taplive2-dialog-top
b {
    font-size: 16px; }
  .preview-customize .taplive2-chat-list-wrapper
.taplive2-dialog-message-wrapper
.taplive2-dialog-top
.taplive2-dialog-date {
    color: rgba(25, 25, 25, 0.6);
    font-size: 12px;
    letter-spacing: -0.4px;
    line-height: 18px;
    position: absolute;
    right: 16px;
    top: 0; }
  .preview-customize .taplive2-chat-list-wrapper
.taplive2-dialog-message-wrapper
.taplive2-dialog-bottom {
    color: rgba(25, 25, 25, 0.6);
    font-size: 14px;
    letter-spacing: -0.2px;
    line-height: 21px;
    padding: 3px 60px 0 0;
    position: relative;
    min-height: 32px; }
  .preview-customize .taplive2-chat-list-wrapper
.taplive2-dialog-message-wrapper
.taplive2-dialog-bottom
span {
    color: #191919; }
  .preview-customize .taplive2-chat-list-wrapper
.taplive2-dialog-message-wrapper
.taplive2-dialog-bottom
p {
    margin: 0;
    white-space: nowrap;
    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis; }
  .preview-customize .taplive2-chat-list-wrapper
.taplive2-dialog-message-wrapper
.taplive2-dialog-bottom
.taplive2-message-status {
    position: absolute;
    top: 10px;
    right: 16px; }
  .preview-customize .taplive2-chat-list-wrapper
.taplive2-dialog-message-wrapper
.taplive2-dialog-bottom
.taplive2-message-status
.taplive2-unread-count-wrapper {
    background: #ff7e00;
    color: #fff;
    font-size: 12px;
    border-radius: 12px;
    height: 24px;
    min-width: 24px;
    text-align: center;
    line-height: 24px;
    padding: 0 5px; }
  .preview-customize .taplive2-chat-list-wrapper:nth-last-child(1) .taplive2-dialog-message-wrapper {
    border-bottom: 0; }
  .preview-customize .taplive2-room-list-wrapper
.taplive2-room-list-bottom-content
.taplive2-room-list-bottom-new-message-wrapper {
    width: 100%;
    /* height: 43px; */
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 12px 16px;
    background: #ffffff;
    box-sizing: border-box; }
  .preview-customize .taplive2-room-list-wrapper
.taplive2-room-list-bottom-content
.taplive2-room-list-bottom-list-room-wrapper {
    max-height: calc(100% - 70px);
    overflow-y: auto;
    border-bottom: solid 1px #dcdcdc; }
  .preview-customize .taplive2-room-list-wrapper
.taplive2-room-list-bottom-content
.taplive2-room-list-bottom-new-message {
    border-radius: 8px;
    border: none;
    color: #ffffff;
    background: #ff7e00;
    width: 100%;
    padding: 13px;
    cursor: pointer;
    font-size: 16px;
    letter-spacing: -0.5px; }
  .preview-customize .taplive2-room-list-wrapper
.taplive2-room-list-bottom-content
.taplive2-room-list-bottom-new-message:hover {
    /* background: rgba(255, 126, 0, 0.8); */ }
  .preview-customize .taplive2-room-list-wrapper
.taplive2-room-list-bottom-content
.taplive2-room-list-bottom-new-message
img {
    position: relative;
    margin-right: 5px;
    vertical-align: top;
    top: 3px; }
  .preview-customize .taplive2-chat-list-wrapper:nth-last-child(1) .taplive2-dialog-message-wrapper {
    border-bottom: 0; }
  .preview-customize .taplive2-review-wrapper {
    background: #00000073;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    border-radius: 8px;
    z-index: 3;
    -webkit-transition: ease-in-out 0.5s;
    transition: ease-in-out 0.5s;
    bottom: -100vh; }
  .preview-customize .taplive2-review-wrapper.active-taplive2-review-wrapper {
    bottom: 0; }
  .preview-customize .taplive2-review-wrapper .taplive2-review-content-wrapper {
    height: 340px;
    width: 100%;
    background: #ffffff;
    position: absolute;
    bottom: 0;
    left: 0;
    border-radius: 8px 8px 0 0;
    padding: 16px 12px; }
  .preview-customize .taplive2-review-wrapper
.taplive2-review-content-wrapper
.taplive2-review-content-head {
    width: 100%;
    position: relative;
    text-align: center; }
  .preview-customize .taplive2-review-wrapper
.taplive2-review-content-wrapper
.taplive2-review-content-head
img {
    cursor: pointer;
    position: absolute;
    left: 0;
    top: 0; }
  .preview-customize .taplive2-review-wrapper
.taplive2-review-content-wrapper
.taplive2-review-content-body
.taplive2-review-content-body-star-wrapper {
    text-align: center;
    padding-top: 38px;
    position: relative; }
  .preview-customize .taplive2-review-wrapper
.taplive2-review-content-wrapper
.taplive2-review-content-body
.taplive2-review-content-body-star-wrapper
.taplive2-star-image-wrapper {
    width: 264px;
    position: absolute;
    left: 50%;
    -webkit-transform: translate(-50%, 0);
            transform: translate(-50%, 0);
    font-size: 0; }
  .preview-customize .taplive2-star-image-wrapper .taplive2-star-image-click-wrapper {
    width: 264px;
    height: 38px;
    position: absolute;
    top: 0;
    left: 0; }
  .preview-customize .taplive2-star-image-wrapper .taplive2-star-image-click-content {
    width: 20%;
    display: inline-block;
    cursor: pointer;
    height: 38px; }
  .preview-customize .taplive2-review-wrapper
.taplive2-review-content-wrapper
.taplive2-review-content-body
.taplive2-review-content-body-star-wrapper
img {
    width: 264px; }
  .preview-customize .taplive2-review-wrapper
.taplive2-review-content-wrapper
.taplive2-review-content-body
.taplive2-review-content-body-star-wrapper
p {
    color: #191919;
    letter-spacing: -0.4px;
    font-size: 14px;
    margin-top: 50px; }
  .preview-customize .taplive2-review-wrapper
.taplive2-review-content-wrapper
.taplive2-review-content-body
textarea {
    resize: none;
    border-radius: 8px;
    width: 100%;
    letter-spacing: -0.3px;
    font-size: 16px;
    padding: 12px 16px;
    border: solid 1px #dcdcdc;
    margin: 10px 0 24px; }
  .preview-customize .taplive2-review-wrapper
.taplive2-review-content-wrapper
.taplive2-review-content-foot {
    width: 100%; }
  .preview-customize .taplive2-review-wrapper
.taplive2-review-content-wrapper
.taplive2-review-content-foot {
    width: 100%; }
  .preview-customize .taplive2-review-wrapper
.taplive2-review-content-wrapper
.taplive2-review-content-foot
button {
    border: 0;
    color: #ffffff;
    font-size: 16px;
    letter-spacing: -0.5px;
    width: 100%;
    border-radius: 8px;
    padding: 14px 0;
    line-height: 1;
    cursor: pointer; }
  .preview-customize .taplive2-submit-review-button:hover {
    /* background: rgba(255, 126, 0, 0.8); */ }
  .preview-customize .taplive2-main-chat-room-wrapper {
    width: 100%;
    height: 100%;
    max-height: 100%;
    border-radius: 8px;
    position: absolute;
    left: 100%;
    top: 0;
    -webkit-transition: ease-in-out 0.3s;
    transition: ease-in-out 0.3s;
    background: #ffffff; }
  .preview-customize .taplive2-main-chat-room-wrapper .taplive2-loading-message-wrapper {
    padding-top: 16px;
    text-align: center;
    width: 100%; }
  .preview-customize .taplive2-main-chat-room-wrapper
.taplive2-loading-message-wrapper
.taplive2-lds-ring
div {
    border-color: #ff7e00 transparent transparent transparent; }
  .preview-customize .taplive2-main-chat-room-wrapper.taplive2-active-main-chat-room-wrapper {
    left: 0; }
  .preview-customize .taplive2-main-chat-room-wrapper .taplive2-main-chat-room-header {
    border-radius: 8px 8px 0 0;
    background: #ff7e00;
    width: 100%;
    color: #ffffff;
    padding: 12px 20px 12px 55px;
    position: relative;
    /* height: 88px; */
    height: 70px; }
  .preview-customize .taplive2-main-chat-room-wrapper
.taplive2-main-chat-room-header
.taplive2-main-chat-room-back-button {
    position: absolute;
    left: 20px;
    top: 22px;
    cursor: pointer;
    -webkit-transition: ease-in-out 0.1s;
    transition: ease-in-out 0.1s;
    border-radius: 50%;
    padding: 3px;
    margin-right: 5px; }
  .preview-customize .taplive2-main-chat-room-wrapper
.taplive2-main-chat-room-header
.taplive2-main-chat-room-back-button:hover {
    /* left: 15px;
              transform: scale(0.90); */
    background: rgba(25, 25, 25, 0.3); }
  .preview-customize .taplive2-main-chat-room-wrapper
.taplive2-main-chat-room-header
.taplive2-main-chat-room-admin-name {
    font-size: 20px;
    letter-spacing: -0.6px;
    margin: 0;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 100%; }
  .preview-customize .taplive2-main-chat-room-wrapper
.taplive2-main-chat-room-header
.taplive2-main-chat-header-wrapper
.taplive2-main-chat-room-case-topic {
    font-size: 20px;
    letter-spacing: -0.6px;
    margin: 0;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 100%; }
  .preview-customize .taplive2-main-chat-room-wrapper
.taplive2-main-chat-room-header
.taplive2-main-chat-header-wrapper
.taplive2-main-chat-room-case-id {
    font-size: 12px;
    letter-spacing: -0.6px;
    margin: 0;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 100%; }
  .preview-customize .taplive2-main-chat-room-wrapper .taplive2-main-chat-room-container {
    width: 100%;
    /* max-height: calc(100% - 146px);
              min-height: calc(100% - 146px); */
    max-height: calc(100% - 125px);
    min-height: calc(100% - 125px);
    -webkit-transition: ease-in-out 0.2s;
    transition: ease-in-out 0.2s;
    padding: 0;
    overflow-y: auto;
    overflow-x: hidden;
    position: relative;
    background-color: #f3f3f3; }
  .preview-customize .taplive2-main-chat-room-wrapper
.taplive2-main-chat-room-container.taplive2-chat-room-is-locked {
    max-height: calc(100% - 70px);
    min-height: calc(100% - 70px); }
  .preview-customize .taplive2-main-chat-room-container.taplive2-active-mark-as-solved {
    max-height: calc(100% - 166px);
    min-height: calc(100% - 166px);
    /* max-height: calc(100% - 186px);
                  min-height: calc(100% - 186px); */ }
  .preview-customize .taplive2-main-chat-room-bubble-container {
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 0 16px 12px 16px;
    max-height: calc(100%);
    overflow-y: auto; }
  .preview-customize .taplive2-chat-room-info-wrapper {
    width: 100%;
    text-align: center;
    position: relative;
    padding-top: 16px; }
  .preview-customize .taplive2-chat-room-info-wrapper .taplive2-chat-room-info-content {
    padding: 4px 16px;
    border-radius: 8px;
    box-shadow: 0px 2px 20px 0px rgba(25, 25, 25, 0.05);
    display: inline-block;
    background: #fff;
    color: rgba(25, 25, 25, 0.4);
    font-size: 12px;
    letter-spacing: -0.4px;
    line-height: 18px;
    text-align: center; }
  .preview-customize .taplive2-chat-room-date {
    width: 77px;
    height: 22px;
    border-radius: 8px;
    box-shadow: 0 2px 20px 0 rgba(25, 25, 25, 0.05);
    background-color: #ffffff;
    color: rgba(25, 25, 25, 0.4);
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    top: 12px;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    margin-bottom: 12px; }
  .preview-customize .taplive2-chat-room-date p {
    font-size: 12px;
    margin: 0; }
  .preview-customize .taplive2-chat-room-message-out-wrapper {
    padding-top: 16px;
    width: 100%;
    position: relative;
    text-align: right; }
  .preview-customize .taplive2-chat-room-message-out-wrapper .taplive2-message-out-bubble {
    border-radius: 16px 2px 16px 16px;
    background: #ff7e00;
    display: inline-block;
    max-width: 250px;
    /* padding: 10px 62px 8px 10px; */
    padding: 10px 10px 8px 10px;
    color: #191919;
    font-size: 14px;
    letter-spacing: -0.3px;
    line-height: 24px;
    position: relative;
    cursor: pointer;
    color: #fff;
    text-align: left;
    /* min-height: 44px; */ }
  .preview-customize .taplive2-chat-room-message-out-wrapper
.taplive2-message-out-bubble.taplive2-deleted-bubble {
    color: #fff;
    padding: 10px 10px 10px 33px; }
  .preview-customize .taplive2-chat-room-message-out-wrapper
.taplive2-message-out-bubble.taplive2-deleted-bubble
.taplive2-deleted-icon {
    position: absolute;
    left: 10px;
    top: 50%;
    -webkit-transform: translate(0, -50%);
            transform: translate(0, -50%); }
  .preview-customize .taplive2-chat-room-message-out-wrapper
.taplive2-message-out-bubble
.taplive2-message-info {
    /* position: absolute; */
    /* bottom: 11px;
                  right: 27px; */
    margin: 0;
    color: #ffffff;
    font-size: 12px;
    letter-spacing: -0.2px;
    line-height: 18px;
    /* width: 26px; */
    width: 100%;
    text-align: right;
    white-space: nowrap; }
  .preview-customize .centered-dot {
    width: 2px;
    height: 2px;
    display: inline-block;
    vertical-align: top;
    background: rgba(25, 25, 25, 0.4);
    border-radius: 50%;
    position: relative;
    top: 9px; }
  .preview-customize .taplive2-chat-room-message-out-wrapper
.taplive2-message-out-bubble
.taplive2-message-info
img {
    /* margin-left: 4px; */
    width: 16px;
    position: relative;
    top: 1px;
    vertical-align: top; }
  .preview-customize .taplive2-chat-room-message-in-wrapper {
    padding-top: 16px;
    width: 100%;
    position: relative; }
  .preview-customize .taplive2-chat-room-message-in-wrapper .taplive2-message-review-bubble {
    padding: 10px !important;
    max-width: 250px !important; }
  .preview-customize .taplive2-chat-room-message-in-wrapper .taplive2-message-in-bubble {
    border-radius: 2px 16px 16px;
    border: 1px solid rgba(25, 25, 25, 0.1);
    background: #fff;
    display: inline-block;
    max-width: 231px;
    /* padding: 10px 45px 10px 10px; */
    padding: 10px;
    color: #191919;
    font-size: 14px;
    letter-spacing: -0.3px;
    line-height: 24px;
    position: relative;
    cursor: pointer;
    min-height: 44px; }
  .preview-customize .taplive2-chat-room-message-in-wrapper
.taplive2-message-in-bubble
.taplive2-review-button-bubble {
    color: #ffffff;
    border: none;
    letter-spacing: -0.5px;
    font-size: 14px;
    width: 100%;
    border-radius: 8px;
    margin-top: 4px;
    padding: 12px 0 13px; }
  .preview-customize .taplive2-review-button-bubble:hover {
    /* background: rgba(255, 126, 0, 0.8) !important; */ }
  .preview-customize .taplive2-chat-room-message-in-wrapper
.taplive2-message-in-bubble.deleted-bubble {
    color: #9b9b9b;
    padding: 10px; }
  .preview-customize .taplive2-chat-room-message-in-wrapper
.taplive2-message-in-bubble.deleted-bubble
.deleted-icon {
    position: absolute;
    left: 10px;
    top: 50%;
    -webkit-transform: translate(0, -50%);
            transform: translate(0, -50%); }
  .preview-customize .taplive2-chat-room-message-in-wrapper
.taplive2-message-in-bubble
.taplive2-message-info {
    /* position: absolute; */
    /* bottom: 11px;
                  right: 27px; */
    margin: 0;
    color: rgba(25, 25, 25, 0.4);
    font-size: 12px;
    letter-spacing: -0.2px;
    line-height: 18px;
    /* width: 26px; */
    width: 100%;
    text-align: right;
    white-space: nowrap; }
  .preview-customize .taplive2-chat-room-message-in-wrapper
.taplive2-message-in-bubble
.taplive2-group-sender-name-wrapper {
    color: #ff7e00;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0;
    line-height: 1; }
  .preview-customize .taplive2-chat-room-message-in-wrapper .taplive2-group-sender-avatar-wrapper {
    width: 34px;
    height: 34px;
    display: inline-block;
    border-radius: 50%;
    vertical-align: top;
    margin-right: 5px;
    font-size: 16px;
    line-height: 34px;
    text-align: center;
    color: #fff; }
  .preview-customize .taplive2-chat-room-message-in-wrapper .taplive2-group-sender-avatar-wrapper img {
    width: 34px;
    height: 34px;
    display: inline-block;
    border-radius: 50%; }
  .preview-customize .taplive2-chat-room-message-image-in-wrapper {
    width: 100%;
    text-align: left;
    position: relative;
    padding-top: 16px;
    font-size: 0; }
  .preview-customize .taplive2-chat-room-message-image-in-wrapper
.taplive2-group-sender-avatar-wrapper {
    width: 34px;
    height: 34px;
    display: inline-block;
    border-radius: 50%;
    vertical-align: top;
    margin-right: 5px;
    font-size: 14px;
    line-height: 34px;
    text-align: center;
    color: #fff; }
  .preview-customize .taplive2-chat-room-message-image-in-wrapper
.taplive2-group-sender-avatar-wrapper
img {
    width: 34px;
    height: 34px;
    display: inline-block;
    border-radius: 50%; }
  .preview-customize .taplive2-chat-room-message-image-in-wrapper .taplive2-message-in-bubble {
    border-radius: 2px 16px 16px;
    /* width: 200px; */
    max-width: 300px;
    display: inline-block;
    position: relative;
    font-size: 0;
    overflow: hidden;
    background: #fff;
    border: 1px solid rgba(25, 25, 25, 0.1); }
  .preview-customize .taplive2-chat-room-message-image-in-wrapper
.taplive2-message-in-bubble
.taplive2-bubble-image-wrapper {
    position: relative;
    min-width: 150px;
    min-height: 150px;
    padding: 4px; }
  .preview-customize .taplive2-chat-room-message-image-in-wrapper
.taplive2-message-in-bubble
.taplive2-bubble-image-wrapper
img {
    vertical-align: top;
    width: 100%;
    max-width: 100%;
    cursor: pointer;
    border-radius: 0 16px 0 0; }
  .preview-customize .taplive2-chat-room-message-image-in-wrapper
.taplive2-message-in-bubble
.taplive2-bubble-image-wrapper.taplive2-bubble-wrapper-without-caption
img {
    border-radius: 0 16px 16px 16px; }
  .preview-customize .taplive2-chat-room-message-image-in-wrapper
.taplive2-message-in-bubble
.taplive2-bubble-image-wrapper
.taplive2-icon-status-wrapper {
    border-radius: 50%;
    position: absolute;
    width: 48px;
    height: 48px;
    display: inline-block;
    background: rgba(25, 25, 25, 0.4);
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    cursor: pointer; }
  .preview-customize .taplive2-chat-room-message-image-in-wrapper
.taplive2-message-in-bubble
.taplive2-bubble-image-wrapper
.taplive2-icon-status-wrapper
img {
    color: #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    font-size: 20px; }
  .preview-customize .taplive2-chat-room-message-image-in-wrapper
.taplive2-message-in-bubble
.taplive2-bubble-image-wrapper
.taplive2-icon-status-wrapper
.taplive2-onprocess-wrapper {
    height: 40px;
    width: 40px;
    display: inline-block;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  .preview-customize .taplive2-chat-room-message-image-in-wrapper
.taplive2-message-in-bubble
.taplive2-bubble-image-wrapper {
    vertical-align: top;
    width: 100%;
    min-width: 150px;
    min-height: 150px; }
  .preview-customize .taplive2-chat-room-message-image-in-wrapper
.taplive2-message-in-bubble
.taplive2-caption-text {
    font-size: 14px;
    /* padding: 10px 55px 10px 10px; */
    padding: 2px 10px 0 10px;
    margin: 0; }
  .preview-customize .taplive2-chat-room-message-image-in-wrapper
.taplive2-message-in-bubble
.taplive2-message-info {
    /* background: rgba(25, 25, 25, 0.6); */
    position: absolute;
    white-space: nowrap;
    border-radius: 10px;
    font-size: 12px;
    /* padding: 2px 5px; */
    color: #ffffff;
    bottom: 10px;
    right: 10px;
    z-index: 1;
    margin: 0;
    line-height: 18px;
    letter-spacing: -0.2px; }
  .preview-customize .taplive2-chat-room-message-image-in-wrapper
.taplive2-message-in-bubble
.taplive2-message-info.taplive2-message-info.taplive2-message-info-with-caption {
    position: relative;
    bottom: unset;
    right: unset;
    width: 100%;
    text-align: right;
    color: rgba(25, 25, 25, 0.4);
    padding: 2px 10px 3px; }
  .preview-customize .taplive2-chat-room-message-image-in-wrapper
.taplive2-message-in-bubble.taplive2-not-sent-message-bubble {
    overflow: hidden; }
  .preview-customize .taplive2-chat-room-message-image-in-wrapper
.taplive2-message-in-bubble.taplive2-not-sent-message-bubble
.dark-cover {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: -5%;
    border-radius: 2px 16px 16px; }
  .preview-customize .taplive2-chat-room-message-image-in-wrapper:hover .message-action-wrapper {
    display: inline-block; }
  .preview-customize .taplive2-chat-room-message-image-in-wrapper .message-action-wrapper {
    right: unset !important;
    left: calc(30% + 40px) !important; }
  .preview-customize .taplive2-chat-room-message-image-in-wrapper
.message-action-wrapper
.message-action-button-wrapper {
    cursor: pointer; }
  .preview-customize .taplive2-chat-room-message-image-in-wrapper
.CircularProgressbar
.CircularProgressbar-trail {
    stroke: transparent; }
  .preview-customize .taplive2-chat-room-message-image-in-wrapper
.CircularProgressbar
.CircularProgressbar-path {
    stroke: #fff; }
  .preview-customize .taplive2-chat-room-message-image-out-wrapper {
    width: 100%;
    text-align: right;
    position: relative;
    padding-top: 16px;
    font-size: 0; }
  .preview-customize .taplive2-chat-room-message-image-out-wrapper .taplive2-message-out-bubble {
    border-radius: 16px 2px 16px 16px;
    /* width: 200px; */
    max-width: 300px;
    display: inline-block;
    position: relative;
    font-size: 0;
    border: 1px solid rgba(25, 25, 25, 0.1);
    overflow: hidden;
    background: #ff7e00;
    border: 1px solid rgba(25, 25, 25, 0.1);
    text-align: left;
    color: #fff; }
  .preview-customize .taplive2-chat-room-message-image-out-wrapper
.taplive2-message-out-bubble
.taplive2-bubble-image-wrapper {
    position: relative;
    min-width: 150px;
    min-height: 150px;
    padding: 4px; }
  .preview-customize .taplive2-chat-room-message-image-out-wrapper
.taplive2-message-out-bubble
.taplive2-bubble-image-wrapper.taplive2-bubble-image-wrapper.taplive2-bubble-wrapper-without-caption
img {
    border-radius: 16px 0 16px 16px; }
  .preview-customize .taplive2-chat-room-message-image-out-wrapper
.taplive2-message-out-bubble
.taplive2-bubble-image-wrapper
img {
    vertical-align: top;
    width: 100%;
    max-width: 100%;
    cursor: pointer;
    border-radius: 16px 0 0 0; }
  .preview-customize .taplive2-chat-room-message-image-out-wrapper
.taplive2-message-out-bubble
.taplive2-bubble-image-wrapper
.taplive2-icon-status-wrapper {
    border-radius: 50%;
    position: absolute;
    width: 48px;
    height: 48px;
    display: inline-block;
    background: rgba(25, 25, 25, 0.4);
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    cursor: pointer; }
  .preview-customize .taplive2-chat-room-message-image-out-wrapper
.taplive2-message-out-bubble
.taplive2-bubble-image-wrapper
.taplive2-icon-status-wrapper
.taplive2-lds-ring {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    border-radius: 50%;
    padding: 4px; }
  .preview-customize .taplive2-chat-room-message-image-out-wrapper
.taplive2-message-out-bubble
.taplive2-bubble-image-wrapper
.taplive2-icon-status-wrapper
.taplive2-lds-ring
div {
    border-color: #ff7e00 transparent transparent transparent;
    width: 40px;
    height: 40px; }
  .preview-customize .taplive2-chat-room-message-image-out-wrapper
.taplive2-message-out-bubble
.taplive2-bubble-image-wrapper
.taplive2-icon-status-wrapper
img {
    width: 24px;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    font-size: 20px; }
  .preview-customize .taplive2-chat-room-message-image-out-wrapper
.taplive2-message-out-bubble
.taplive2-bubble-image-wrapper
.taplive2-icon-status-wrapper
.taplive2-onprocess-wrapper {
    height: 40px;
    width: 40px;
    display: inline-block;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  .preview-customize .taplive2-chat-room-message-image-out-wrapper
.taplive2-message-out-bubble
.taplive2-main-image-message {
    width: 100%; }
  .preview-customize .taplive2-chat-room-message-image-out-wrapper
.taplive2-message-out-bubble
.taplive2-caption-text {
    font-size: 14px;
    /* padding: 10px 73px 10px 10px; */
    padding: 2px 10px 0 10px;
    margin: 0; }
  .preview-customize .taplive2-chat-room-message-image-out-wrapper
.taplive2-message-out-bubble
.taplive2-message-info {
    /* background: rgba(25, 25, 25, 0.6); */
    margin: 0;
    position: absolute;
    white-space: nowrap;
    border-radius: 10px;
    font-size: 12px;
    /* padding: 2px 5px; */
    color: #ffffff;
    bottom: 10px;
    right: 10px;
    z-index: 1;
    line-height: 18px;
    letter-spacing: -0.2px; }
  .preview-customize .taplive2-chat-room-message-image-out-wrapper
.taplive2-message-out-bubble
.taplive2-message-info.taplive2-message-info.taplive2-message-info-with-caption {
    position: relative;
    bottom: unset;
    right: unset;
    width: 100%;
    text-align: right;
    color: #ffffff;
    padding: 2px 10px 3px; }
  .preview-customize .taplive2-chat-room-message-image-out-wrapper
.taplive2-message-out-bubble
.taplive2-message-info
img {
    vertical-align: top;
    position: relative;
    top: 1px;
    /* margin-left: 3px; */ }
  .preview-customize .taplive2-chat-room-message-image-out-wrapper
.taplive2-message-out-bubble.taplive2-not-sent-message-bubble {
    overflow: hidden; }
  .preview-customize .taplive2-chat-room-message-image-out-wrapper
.taplive2-failed-sending-file-warning {
    font-size: 12px;
    letter-spacing: -0.4px;
    color: rgba(25, 25, 25, 0.4); }
  .preview-customize .taplive2-chat-room-message-image-out-wrapper:hover .message-action-wrapper {
    display: inline-block; }
  .preview-customize .taplive2-chat-room-message-image-out-wrapper .message-action-wrapper {
    right: calc(30% + 10px) !important; }
  .preview-customize .taplive2-chat-room-message-image-out-wrapper
.message-action-wrapper
.message-action-button-wrapper {
    cursor: pointer; }
  .preview-customize .taplive2-chat-room-message-image-out-wrapper
.CircularProgressbar
.CircularProgressbar-trail {
    stroke: transparent; }
  .preview-customize .taplive2-chat-room-message-image-out-wrapper
.CircularProgressbar
.CircularProgressbar-path {
    stroke: #fff; }
  .preview-customize .taplive2-chat-room-message-video-in-wrapper {
    width: 100%;
    text-align: left;
    position: relative;
    padding-top: 16px;
    font-size: 0; }
  .preview-customize .taplive2-chat-room-message-video-in-wrapper
.taplive2-group-sender-avatar-wrapper {
    width: 34px;
    height: 34px;
    display: inline-block;
    border-radius: 50%;
    vertical-align: top;
    margin-right: 5px;
    font-size: 14px;
    line-height: 34px;
    text-align: center;
    color: #ffffff; }
  .preview-customize .taplive2-chat-room-message-video-in-wrapper
.taplive2-group-sender-avatar-wrapper
img {
    width: 34px;
    height: 34px;
    display: inline-block;
    border-radius: 50%; }
  .preview-customize .taplive2-chat-room-message-video-in-wrapper .taplive2-message-in-bubble {
    border-radius: 2px 16px 16px;
    /* width: 200px; */
    max-width: 300px;
    display: inline-block;
    position: relative;
    font-size: 0;
    overflow: hidden;
    background: #ffffff;
    background: #ffffff;
    border: 1px solid rgba(25, 25, 25, 0.1); }
  .preview-customize .taplive2-chat-room-message-video-in-wrapper
.taplive2-message-in-bubble
.taplive2-bubble-image-wrapper {
    position: relative;
    min-width: 150px;
    min-height: 150px;
    padding: 4px; }
  .preview-customize .taplive2-chat-room-message-video-in-wrapper
.taplive2-message-in-bubble
.taplive2-bubble-image-wrapper.taplive2-bubble-wrapper-without-caption
video {
    border-radius: 0 16px 16px 16px; }
  .preview-customize .taplive2-chat-room-message-video-in-wrapper
.taplive2-message-in-bubble
.taplive2-bubble-image-wrapper.taplive2-bubble-wrapper-without-caption
img {
    border-radius: 0 16px 16px 16px; }
  .preview-customize .taplive2-chat-room-message-video-in-wrapper
.taplive2-message-in-bubble
.taplive2-bubble-image-wrapper
.taplive2-icon-status-wrapper {
    border-radius: 50%;
    position: absolute;
    width: 48px;
    height: 48px;
    display: inline-block;
    background: rgba(25, 25, 25, 0.4);
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    cursor: pointer; }
  .preview-customize .taplive2-chat-room-message-video-in-wrapper
.taplive2-message-in-bubble
.taplive2-bubble-image-wrapper
.taplive2-icon-status-wrapper
img {
    color: #ffffff;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    font-size: 20px; }
  .preview-customize .taplive2-chat-room-message-video-in-wrapper
.taplive2-message-in-bubble
.taplive2-bubble-image-wrapper
.taplive2-icon-status-wrapper
.taplive2-onprocess-wrapper {
    height: 40px;
    width: 40px;
    display: inline-block;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  .preview-customize .taplive2-chat-room-message-video-in-wrapper
.taplive2-message-in-bubble
.taplive2-bubble-image-wrapper
.taplive2-video-thumbnail {
    width: 100%;
    border-radius: 0 16px 0 0; }
  .preview-customize .taplive2-chat-room-message-video-in-wrapper
.taplive2-message-in-bubble
.taplive2-bubble-image-wrapper
.taplive2-video-image-thumbnail {
    width: 100%;
    border-radius: 0 16px 0 0; }
  .preview-customize .taplive2-chat-room-message-video-in-wrapper
.taplive2-message-in-bubble
.taplive2-main-image-message {
    width: 100%; }
  .preview-customize .taplive2-chat-room-message-video-in-wrapper
.taplive2-message-in-bubble
.taplive2-caption-text {
    font-size: 14px;
    /* padding: 10px 55px 10px 10px; */
    padding: 2px 10px 0 10px;
    margin: 0; }
  .preview-customize .taplive2-chat-room-message-video-in-wrapper
.taplive2-message-in-bubble
.taplive2-message-info {
    position: absolute;
    margin: 0;
    white-space: nowrap;
    border-radius: 10px;
    font-size: 12px;
    /* padding: 2px 5px; */
    letter-spacing: -0.2px;
    color: #ffffff;
    bottom: 10px;
    right: 10px;
    z-index: 1;
    line-height: 18px; }
  .preview-customize .taplive2-chat-room-message-video-in-wrapper
.taplive2-message-in-bubble
.taplive2-message-info.taplive2-message-info.taplive2-message-info-with-caption {
    position: relative;
    bottom: unset;
    right: unset;
    width: 100%;
    text-align: right;
    color: rgba(25, 25, 25, 0.4);
    padding: 2px 10px 3px; }
  .preview-customize .taplive2-chat-room-message-video-in-wrapper
.taplive2-message-in-bubble
.taplive2-message-info.taplive2-message-info-with-caption {
    position: relative;
    bottom: unset;
    right: unset;
    width: 100%;
    text-align: right;
    color: rgba(25, 25, 25, 0.4);
    padding: 2px 10px 3px; }
  .preview-customize .taplive2-chat-room-message-video-in-wrapper
.taplive2-message-in-bubble
.taplive2-message-info
img {
    vertical-align: top;
    /* margin-left: 3px; */ }
  .preview-customize .taplive2-chat-room-message-video-in-wrapper
.taplive2-message-in-bubble.taplive2-not-sent-message-bubble {
    overflow: hidden; }
  .preview-customize .taplive2-chat-room-message-video-in-wrapper
.taplive2-message-in-bubble.taplive2-not-sent-message-bubble
.dark-cover {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    border-radius: 2px 16px 16px; }
  .preview-customize .taplive2-chat-room-message-video-in-wrapper
.taplive2-message-in-bubble.taplive2-not-sent-message-bubble
.dark-cover
.taplive2-icon-status-wrapper {
    border-radius: 50%;
    position: absolute;
    width: 48px;
    height: 48px;
    display: inline-block;
    background: rgba(25, 25, 25, 0.4);
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    cursor: pointer; }
  .preview-customize .taplive2-chat-room-message-video-in-wrapper
.taplive2-message-in-bubble.taplive2-not-sent-message-bubble
.dark-cover
.taplive2-icon-status-wrapper
img {
    color: #ffffff;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    font-size: 20px; }
  .preview-customize .taplive2-chat-room-message-video-in-wrapper
.taplive2-message-in-bubble.taplive2-not-sent-message-bubble
.dark-cover
.taplive2-icon-status-wrapper
.taplive2-onprocess-wrapper {
    height: 40px;
    width: 40px;
    display: inline-block;
    border: solid 2px #ffffff;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  .preview-customize .taplive2-chat-room-message-video-in-wrapper
.taplive2-message-in-bubble
.taplive2-timer-filesize-wrapper {
    background: rgba(25, 25, 25, 0.6);
    position: absolute;
    white-space: nowrap;
    border-radius: 10px;
    font-size: 12px;
    /* padding: 2px 5px; */
    color: rgba(255, 255, 255, 0.6);
    top: 10px;
    left: 10px;
    z-index: 2;
    padding: 2px 5px; }
  .preview-customize .taplive2-chat-room-message-video-in-wrapper .taplive2-message-in-bubble video {
    width: 100%;
    cursor: pointer; }
  .preview-customize .taplive2-chat-room-message-video-in-wrapper
.taplive2-message-in-bubble
.play-button-wraper {
    border-radius: 50%;
    position: absolute;
    width: 48px;
    height: 48px;
    display: inline-block;
    background: rgba(25, 25, 25, 0.4);
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    cursor: pointer; }
  .preview-customize .taplive2-chat-room-message-video-in-wrapper
.taplive2-message-in-bubble
.play-button-wraper
img {
    color: #ffffff;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    font-size: 20px; }
  .preview-customize .taplive2-chat-room-message-video-in-wrapper:hover .message-action-wrapper {
    display: inline-block; }
  .preview-customize .taplive2-chat-room-message-video-in-wrapper .message-action-wrapper {
    right: unset !important;
    left: calc(30% + 34px) !important; }
  .preview-customize .taplive2-chat-room-message-video-in-wrapper
.message-action-wrapper
.message-action-button-wrapper {
    cursor: pointer; }
  .preview-customize .taplive2-chat-room-message-video-in-wrapper
.CircularProgressbar
.CircularProgressbar-trail {
    stroke: transparent; }
  .preview-customize .taplive2-chat-room-message-video-in-wrapper
.CircularProgressbar
.CircularProgressbar-path {
    stroke: #ffffff; }
  .preview-customize .modal-video .modal-content {
    width: 100%;
    height: 100%; }
  .preview-customize .modal-video .modal-content .modal-body {
    width: 100%;
    height: 100%;
    background: #191919; }
  .preview-customize .modal-video .modal-content .modal-body .video-wrapper {
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  .preview-customize .modal-video .modal-content .modal-body .close-modal-video {
    position: absolute;
    top: 20px;
    right: 20px;
    color: #ffffff;
    font-size: 40px;
    cursor: pointer;
    -webkit-transition: ease-in-out 0.1s;
    transition: ease-in-out 0.1s; }
  .preview-customize .modal-video .modal-content .modal-body .close-modal-video:hover {
    color: #c9c9c9; }
  .preview-customize .modal-video .modal-content .modal-body video {
    max-height: 80vh; }
  .preview-customize .taplive2-chat-room-message-video-out-wrapper {
    width: 100%;
    text-align: right;
    position: relative;
    padding-top: 16px;
    font-size: 0; }
  .preview-customize .taplive2-chat-room-message-video-out-wrapper .taplive2-message-out-bubble {
    border-radius: 16px 2px 16px 16px;
    /* width: 200px; */
    max-width: 300px;
    display: inline-block;
    position: relative;
    font-size: 0;
    border: 1px solid rgba(25, 25, 25, 0.1);
    overflow: hidden;
    background: #ff7e00;
    border: 1px solid rgba(25, 25, 25, 0.1);
    text-align: left;
    color: #ffffff; }
  .preview-customize .taplive2-chat-room-message-video-out-wrapper
.taplive2-message-out-bubble
.taplive2-bubble-image-wrapper
.taplive2-icon-status-wrapper {
    border-radius: 50%;
    position: absolute;
    width: 48px;
    height: 48px;
    display: inline-block;
    background: rgba(25, 25, 25, 0.4);
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    cursor: pointer; }
  .preview-customize .taplive2-chat-room-message-video-out-wrapper
.taplive2-message-out-bubble
.taplive2-bubble-image-wrapper
.taplive2-icon-status-wrapper
.taplive2-lds-ring {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    border-radius: 50%;
    padding: 4px; }
  .preview-customize .taplive2-chat-room-message-video-out-wrapper
.taplive2-message-out-bubble
.taplive2-bubble-image-wrapper
.taplive2-icon-status-wrapper
.taplive2-lds-ring
div {
    border-color: #ff7e00 transparent transparent transparent;
    width: 40px;
    height: 40px; }
  .preview-customize .taplive2-chat-room-message-video-out-wrapper
.taplive2-message-out-bubble
.taplive2-bubble-image-wrapper
.taplive2-icon-status-wrapper
img {
    width: 24px;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    font-size: 20px; }
  .preview-customize .taplive2-chat-room-message-video-out-wrapper
.taplive2-message-out-bubble
.taplive2-bubble-image-wrapper {
    padding: 4px;
    position: relative;
    min-width: 150px;
    min-height: 150px; }
  .preview-customize .taplive2-chat-room-message-video-out-wrapper
.taplive2-message-out-bubble
.taplive2-bubble-image-wrapper
video {
    width: 100%;
    cursor: pointer; }
  .preview-customize .taplive2-chat-room-message-video-out-wrapper
.taplive2-message-out-bubble
.taplive2-bubble-image-wrapper.taplive2-bubble-wrapper-without-caption
video {
    border-radius: 16px 0 16px 16px; }
  .preview-customize .taplive2-chat-room-message-video-out-wrapper
.taplive2-message-out-bubble
.taplive2-bubble-image-wrapper.taplive2-bubble-wrapper-without-caption
img {
    border-radius: 16px 0 16px 16px; }
  .preview-customize .taplive2-chat-room-message-video-out-wrapper
.taplive2-message-out-bubble
.taplive2-bubble-image-wrapper
.taplive2-icon-status-wrapper {
    border-radius: 50%;
    position: absolute;
    width: 48px;
    height: 48px;
    display: inline-block;
    background: rgba(25, 25, 25, 0.4);
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    cursor: pointer; }
  .preview-customize .taplive2-chat-room-message-video-out-wrapper
.taplive2-message-out-bubble
.taplive2-bubble-image-wrapper
.taplive2-icon-status-wrapper
img {
    color: #ffffff;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    font-size: 20px; }
  .preview-customize .taplive2-chat-room-message-video-out-wrapper
.taplive2-message-out-bubble
.taplive2-bubble-image-wrapper
.taplive2-icon-status-wrapper
.taplive2-onprocess-wrapper {
    height: 40px;
    width: 40px;
    display: inline-block;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  .preview-customize .taplive2-chat-room-message-video-out-wrapper
.taplive2-message-out-bubble
.taplive2-bubble-image-wrapper
.taplive2-video-thumbnail {
    width: 100%;
    border-radius: 16px 0 0 0; }
  .preview-customize .taplive2-chat-room-message-video-out-wrapper
.taplive2-message-out-bubble
.taplive2-bubble-image-wrapper
.taplive2-video-image-thumbnail {
    width: 100%;
    border-radius: 16px 0 0 0; }
  .preview-customize .taplive2-chat-room-message-video-out-wrapper
.taplive2-message-out-bubble
.taplive2-bubble-image-wrapper
.taplive2-video-image-thumbnail {
    width: 100%; }
  .preview-customize .taplive2-chat-room-message-video-out-wrapper
.taplive2-message-out-bubble
.main-image-message {
    width: 100%; }
  .preview-customize .taplive2-chat-room-message-video-out-wrapper
.taplive2-message-out-bubble
.taplive2-caption-text {
    font-size: 14px;
    /* padding: 10px 73px 10px 10px; */
    padding: 2px 10px 0 10px;
    margin: 0; }
  .preview-customize .taplive2-chat-room-message-video-out-wrapper
.taplive2-message-out-bubble
.taplive2-message-info {
    position: absolute;
    white-space: nowrap;
    border-radius: 10px;
    font-size: 12px;
    /* padding: 2px 5px; */
    color: #ffffff;
    bottom: 10px;
    right: 10px;
    letter-spacing: -0.2px;
    z-index: 1;
    line-height: 18px;
    margin: 0; }
  .preview-customize .taplive2-chat-room-message-video-out-wrapper
.taplive2-message-out-bubble
.taplive2-message-info.taplive2-message-info-with-caption {
    position: relative;
    bottom: unset;
    right: unset;
    width: 100%;
    text-align: right;
    color: #ffffff;
    padding: 2px 10px 3px; }
  .preview-customize .taplive2-chat-room-message-video-out-wrapper
.taplive2-message-out-bubble
.taplive2-message-info
img {
    vertical-align: top;
    position: relative;
    top: 1px;
    /* margin-left: 3px; */ }
  .preview-customize .taplive2-chat-room-message-video-out-wrapper
.taplive2-message-out-bubble.taplive2-not-sent-message-bubble {
    overflow: hidden; }
  .preview-customize .taplive2-chat-room-message-video-out-wrapper
.taplive2-message-out-bubble
.taplive2-timer-filesize-wrapper {
    background: rgba(25, 25, 25, 0.6);
    position: absolute;
    white-space: nowrap;
    border-radius: 10px;
    font-size: 12px;
    /* padding: 2px 5px; */
    color: rgba(255, 255, 255, 0.6);
    top: 10px;
    left: 10px;
    z-index: 2;
    padding: 2px 5px; }
  .preview-customize .taplive2-chat-room-message-video-out-wrapper
.taplive2-message-out-bubble
.play-button-wraper {
    border-radius: 50%;
    position: absolute;
    width: 48px;
    height: 48px;
    display: inline-block;
    background: rgba(25, 25, 25, 0.4);
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    cursor: pointer; }
  .preview-customize .taplive2-chat-room-message-video-out-wrapper
.taplive2-message-out-bubble
.play-button-wraper
img {
    color: #ffffff;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    font-size: 20px; }
  .preview-customize .taplive2-chat-room-message-video-out-wrapper:hover .message-action-wrapper {
    display: inline-block; }
  .preview-customize .taplive2-chat-room-message-video-out-wrapper .message-action-wrapper {
    right: calc(30% + 10px) !important;
    left: unset !important; }
  .preview-customize .taplive2-chat-room-message-video-out-wrapper
.message-action-wrapper
.message-action-button-wrapper {
    cursor: pointer; }
  .preview-customize .taplive2-chat-room-message-video-out-wrapper
.CircularProgressbar
.CircularProgressbar-trail {
    stroke: transparent; }
  .preview-customize .taplive2-chat-room-message-video-out-wrapper
.CircularProgressbar
.CircularProgressbar-path {
    stroke: #ffffff; }
  .preview-customize .modal-video .modal-content {
    width: 100%;
    height: 100%; }
  .preview-customize .modal-video .modal-content .modal-body {
    width: 100%;
    height: 100%;
    background: #191919; }
  .preview-customize .modal-video .modal-content .modal-body .video-wrapper {
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  .preview-customize .modal-video .modal-content .modal-body .close-modal-video {
    position: absolute;
    top: 20px;
    right: 20px;
    color: #ffffff;
    font-size: 40px;
    cursor: pointer;
    -webkit-transition: ease-in-out 0.1s;
    transition: ease-in-out 0.1s; }
  .preview-customize .modal-video .modal-content .modal-body .close-modal-video:hover {
    color: #c9c9c9; }
  .preview-customize .modal-video .modal-content .modal-body video {
    max-height: 80vh; }
  .preview-customize .taplive2-chat-room-message-file-in-wrapper {
    padding-top: 16px;
    width: 100%;
    position: relative;
    text-align: left; }
  .preview-customize .taplive2-chat-room-message-file-in-wrapper .message-action-right {
    right: -40px !important; }
  .preview-customize .taplive2-chat-room-message-file-in-wrapper
.taplive2-group-sender-avatar-wrapper {
    width: 34px;
    height: 34px;
    display: inline-block;
    border-radius: 50%;
    vertical-align: top;
    margin-right: 5px;
    font-size: 14px;
    line-height: 34px;
    text-align: center;
    color: #ffffff; }
  .preview-customize .taplive2-chat-room-message-file-in-wrapper
.taplive2-group-sender-avatar-wrapper
img {
    width: 34px;
    height: 34px;
    display: inline-block;
    border-radius: 50%; }
  .preview-customize .taplive2-chat-room-message-file-in-wrapper .taplive2-message-in-bubble {
    border-radius: 2px 16px 16px;
    background: #ffffff;
    display: inline-block;
    max-width: calc(100% - 50px);
    /* padding: 10px 44px 10px 10px; */
    padding: 10px;
    color: #191919;
    font-size: 16px;
    letter-spacing: -0.3px;
    line-height: 24px;
    position: relative;
    cursor: pointer;
    color: #ffffff;
    text-align: left;
    border: 1px solid rgba(25, 25, 25, 0.1);
    /* width: 100%; */ }
  .preview-customize .taplive2-chat-room-message-file-in-wrapper
.taplive2-message-in-bubble
.taplive2-message-info {
    /* position: absolute;
                  bottom: 11px;
                  right: 10px; */
    margin: 0;
    color: rgba(25, 25, 25, 0.4);
    font-size: 12px;
    letter-spacing: -0.2px;
    line-height: 18px;
    /* width: 26px; */
    width: 100%;
    text-align: right;
    white-space: nowrap; }
  .preview-customize .taplive2-chat-room-message-file-in-wrapper
.taplive2-message-in-bubble
.taplive2-message-info
img {
    margin-left: 4px;
    width: 16px;
    position: relative;
    top: 4px; }
  .preview-customize .taplive2-chat-room-message-file-in-wrapper
.taplive2-message-in-bubble
.taplive2-message-bubble-file-wrapper {
    width: 100%;
    display: inline-block;
    color: #191919; }
  .preview-customize .taplive2-chat-room-message-file-in-wrapper
.taplive2-message-in-bubble
.taplive2-message-bubble-file-wrapper
.taplive2-file-icon-wrapper {
    height: 46px;
    width: 46px;
    display: inline-block;
    background: #ff7e00;
    border-radius: 50%;
    vertical-align: top;
    position: relative; }
  .preview-customize .taplive2-chat-room-message-file-in-wrapper
.taplive2-message-in-bubble
.taplive2-message-bubble-file-wrapper
.taplive2-file-icon-wrapper
img {
    font-size: 25px;
    color: #ffffff;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  .preview-customize .taplive2-chat-room-message-file-in-wrapper
.taplive2-message-in-bubble
.taplive2-message-bubble-file-wrapper
.taplive2-file-icon-wrapper
.taplive2-onprocess-wrapper {
    height: 40px;
    width: 40px;
    display: inline-block;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  .preview-customize .taplive2-chat-room-message-file-in-wrapper
.taplive2-message-in-bubble
.taplive2-message-bubble-file-wrapper
.taplive2-file-detail-wrapper {
    width: calc(100% - 50px);
    display: inline-block;
    font-size: 14px;
    letter-spacing: -0.2px;
    padding-left: 10px;
    line-height: 19px;
    padding-top: 6px; }
  .preview-customize .taplive2-chat-room-message-file-in-wrapper
.taplive2-message-in-bubble
.taplive2-message-bubble-file-wrapper
.taplive2-file-detail-wrapper
.taplive2-filename-wrapper {
    width: 100%;
    margin: 0;
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; }
  .preview-customize .taplive2-chat-room-message-file-in-wrapper
.taplive2-message-in-bubble
.taplive2-message-bubble-file-wrapper
.taplive2-file-detail-wrapper
.taplive2-filename-wrapper
b {
    letter-spacing: -0.4px;
    display: block; }
  .preview-customize .taplive2-chat-room-message-file-in-wrapper
.taplive2-message-in-bubble
.taplive2-message-bubble-file-wrapper
.taplive2-file-detail-wrapper
.taplive2-message-file-in-filesize {
    white-space: nowrap; }
  .preview-customize .taplive2-chat-room-message-file-in-wrapper:hover .message-action-wrapper {
    display: inline-block; }
  .preview-customize .taplive2-chat-room-message-file-in-wrapper
.CircularProgressbar
.CircularProgressbar-trail {
    stroke: #ff7e00; }
  .preview-customize .taplive2-chat-room-message-file-in-wrapper
.CircularProgressbar
.CircularProgressbar-path {
    stroke: #ffffff; }
  .preview-customize .taplive2-chat-room-message-file-out-wrapper {
    padding-top: 16px;
    width: 100%;
    position: relative;
    text-align: right; }
  .preview-customize .taplive2-chat-room-message-file-out-wrapper .taplive2-message-out-bubble {
    border-radius: 16px 2px 16px 16px;
    background: #ff7e00;
    display: inline-block;
    max-width: 100%;
    /* padding: 10px 62px 10px 10px; */
    padding: 10px;
    color: #191919;
    font-size: 14px;
    letter-spacing: -0.3px;
    line-height: 24px;
    position: relative;
    cursor: pointer;
    color: #ffffff;
    text-align: left;
    /* width: 90%; */ }
  .preview-customize .taplive2-chat-room-message-file-out-wrapper
.taplive2-message-out-bubble
.taplive2-message-info {
    /* position: absolute;
                  bottom: 11px;
                  right: 10px; */
    margin: 0;
    color: #ffffff;
    font-size: 12px;
    letter-spacing: -0.2px;
    line-height: 18px;
    /* width: 26px; */
    width: 100%;
    text-align: right;
    white-space: nowrap; }
  .preview-customize .taplive2-chat-room-message-file-out-wrapper
.taplive2-message-out-bubble
.taplive2-message-info
img {
    /* margin-left: 4px; */
    width: 16px;
    position: relative;
    top: 1px;
    vertical-align: top; }
  .preview-customize .taplive2-chat-room-message-file-out-wrapper
.taplive2-message-out-bubble
.taplive2-message-bubble-file-wrapper {
    width: 100%;
    display: inline-block; }
  .preview-customize .taplive2-chat-room-message-file-out-wrapper
.taplive2-message-out-bubble
.taplive2-message-bubble-file-wrapper
.taplive2-file-icon-wrapper {
    height: 46px;
    width: 46px;
    display: inline-block;
    background: #ffffff;
    border-radius: 50%;
    vertical-align: top;
    position: relative; }
  .preview-customize .taplive2-chat-room-message-file-out-wrapper
.taplive2-message-out-bubble
.taplive2-message-bubble-file-wrapper
.taplive2-file-icon-wrapper
img {
    font-size: 25px;
    color: #ff7e00;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  .preview-customize .taplive2-chat-room-message-file-out-wrapper
.taplive2-message-out-bubble
.taplive2-message-bubble-file-wrapper
.taplive2-file-icon-wrapper
.taplive2-onprocess-wrapper {
    height: 40px;
    width: 40px;
    display: inline-block;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  .preview-customize .taplive2-chat-room-message-file-out-wrapper
.taplive2-message-out-bubble
.taplive2-message-bubble-file-wrapper
.taplive2-file-detail-wrapper {
    width: calc(100% - 50px);
    display: inline-block;
    font-size: 14px;
    letter-spacing: -0.2px;
    padding-left: 10px;
    line-height: 19px;
    padding-top: 6px; }
  .preview-customize .taplive2-chat-room-message-file-out-wrapper
.taplive2-message-out-bubble
.taplive2-message-bubble-file-wrapper
.taplive2-file-detail-wrapper
.taplive2-filename-wrapper {
    width: 100%;
    margin: 0;
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; }
  .preview-customize .taplive2-chat-room-message-file-out-wrapper
.taplive2-message-out-bubble
.taplive2-message-bubble-file-wrapper
.taplive2-file-detail-wrapper
.taplive2-filename-wrapper
b {
    letter-spacing: -0.4px;
    display: block; }
  .preview-customize .taplive2-chat-room-message-file-out-wrapper
.taplive2-message-out-bubble
.taplive2-message-bubble-file-wrapper
.taplive2-file-detail-wrapper
.taplive2-message-file-out-filesize {
    white-space: nowrap; }
  .preview-customize .taplive2-chat-room-message-file-out-wrapper
.taplive2-failed-sending-file-warning {
    font-size: 12px;
    letter-spacing: -0.4px;
    color: rgba(25, 25, 25, 0.4); }
  .preview-customize .taplive2-chat-room-message-file-out-wrapper:hover .message-action-wrapper {
    display: inline-block;
    left: -90px; }
  .preview-customize .taplive2-chat-room-message-file-out-wrapper
.CircularProgressbar
.CircularProgressbar-trail {
    stroke: #ffffff; }
  .preview-customize .taplive2-chat-room-message-file-out-wrapper
.CircularProgressbar
.CircularProgressbar-path {
    stroke: #ff7e00; }
  .preview-customize .taplive2-main-chat-room-wrapper
.taplive2-main-chat-room-send-message-panel-wrapper {
    width: 100%;
    /* height: 56px; */
    box-shadow: 0px -1px 0px 0px rgba(25, 25, 25, 0.1);
    background: #ffffff;
    border-radius: 0 0 8px 8px;
    /* position: relative; */
    /* top: 2px; */
    bottom: 0;
    position: absolute;
    /* padding: 8px 12px; */
    /* padding: 7px 12px 4px; */
    padding: 7px 0 4px; }
  .preview-customize .taplive2-main-chat-room-wrapper
.taplive2-main-chat-room-send-message-panel-wrapper
.taplive2-main-chat-room-send-message-hamburger {
    border-radius: 50%;
    background: #ff7e00;
    width: 32px;
    height: 32px;
    text-align: center;
    position: relative;
    top: 4px;
    display: inline-block;
    vertical-align: top;
    cursor: pointer;
    margin-left: 14px; }
  .preview-customize .taplive2-main-chat-room-wrapper
.taplive2-main-chat-room-send-message-panel-wrapper
.taplive2-main-chat-room-send-message-hamburger:hover {
    /* background: rgba(255, 126, 0, 0.8); */ }
  .preview-customize .taplive2-main-chat-room-wrapper
.taplive2-main-chat-room-send-message-panel-wrapper
.taplive2-main-chat-room-send-message-hamburger
img {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  .preview-customize .taplive2-main-chat-room-wrapper
.taplive2-main-chat-room-send-message-panel-wrapper
.taplive2-main-chat-room-send-message-input {
    display: inline-block;
    position: relative;
    height: 100%;
    margin-left: 8px;
    -webkit-transition: ease-in-out 0.3s;
    transition: ease-in-out 0.3s;
    /* width: calc(100% - 44px); */
    width: calc(100% - 70px);
    z-index: 1; }
  .preview-customize .taplive2-main-chat-room-wrapper
.taplive2-main-chat-room-send-message-panel-wrapper
.taplive2-main-chat-room-send-message-input-typing {
    /* width: calc(100% - 87px); */
    width: calc(100% - 114px); }
  .preview-customize .taplive2-main-chat-room-wrapper
.taplive2-main-chat-room-send-message-panel-wrapper
.taplive2-main-chat-room-send-message-input
input[type='file'] {
    display: none; }
  .preview-customize .taplive2-main-chat-room-wrapper
.taplive2-main-chat-room-send-message-panel-wrapper
.taplive2-main-chat-room-send-message-input
textarea {
    border-radius: 20px;
    box-shadow: 0px 0px 0px 1px #dcdcdc;
    border: none;
    width: 100%;
    /* height: 100%; */
    color: rgba(25, 25, 25, 0.6);
    font-size: 14px;
    letter-spacing: -0.3px;
    padding: 11px 46px 11px 16px;
    resize: none;
    overflow: hidden;
    max-height: 124px; }
  .preview-customize .taplive2-main-chat-room-wrapper
.taplive2-main-chat-room-send-message-panel-wrapper
.taplive2-main-chat-room-send-message-input
img {
    position: absolute;
    right: 17px;
    top: 10px;
    cursor: pointer;
    /* transform: translate(0, -50%); */
    -webkit-transition: ease-in-out 0.3s;
    transition: ease-in-out 0.3s; }
  .preview-customize .taplive2-main-chat-room-wrapper
.taplive2-main-chat-room-send-message-panel-wrapper
.taplive2-main-chat-room-send-message-input
img:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1); }
  .preview-customize .taplive2-main-chat-room-wrapper
.taplive2-main-chat-room-send-message-panel-wrapper
.taplive2-main-chat-room-send-message-input
.taplive2-input-file-media-wrapper {
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0px 2px 20px 0px rgba(25, 25, 25, 0.18);
    position: absolute;
    right: 0;
    z-index: 1;
    background: #ffffff;
    top: -70px;
    width: 220px;
    z-index: 2;
    padding: 10px;
    line-height: 23px;
    display: none;
    cursor: pointer; }
  .preview-customize .taplive2-main-chat-room-wrapper
.taplive2-main-chat-room-send-message-panel-wrapper
.submit-chat-icon-wrapper {
    background: #ff7e00; }
  .preview-customize .taplive2-main-chat-room-wrapper
.taplive2-main-chat-room-send-message-panel-wrapper
.input-message-wrapper {
    width: 32px;
    height: 32px;
    right: 12px;
    position: absolute;
    display: inline-block;
    border-radius: 50%;
    text-align: center;
    top: 11px;
    cursor: pointer;
    -webkit-transition: ease-in-out 0.1s;
    transition: ease-in-out 0.1s; }
  .preview-customize .taplive2-main-chat-room-wrapper
.taplive2-main-chat-room-send-message-panel-wrapper
.input-message-wrapper
img {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  .preview-customize .taplive2-main-chat-room-wrapper
.taplive2-main-chat-room-send-message-panel-wrapper
.submit-chat-icon-wrapper:hover {
    background: rgba(255, 126, 0, 0.8); }
  .preview-customize .taplive2-input-file-media-wrapper .taplive2-input-file-media-list {
    cursor: pointer;
    padding: 0 0 8px; }
  .preview-customize .taplive2-input-file-media-wrapper .taplive2-input-file-media-list:hover {
    color: #ff7e00; }
  .preview-customize .taplive2-input-file-media-wrapper
label:nth-last-child(1)
.taplive2-input-file-media-list {
    border-bottom: 0;
    padding: 0; }
  .preview-customize .taplive2-input-file-media-wrapper .taplive2-input-file-media-list img {
    position: relative !important;
    top: -2px !important;
    right: unset !important;
    -webkit-transform: unset !important;
            transform: unset !important;
    vertical-align: top;
    margin-right: 5px; }
  .preview-customize .taplive2-main-chat-room-solve-wrapper {
    height: 40px;
    position: relative;
    width: 100%;
    /* border-radius: 0 0 8px 8px; */
    /* background: pink; */
    top: 4px;
    padding: 10px 8px 6px 20px;
    color: #191919;
    font-size: 16px;
    letter-spacing: -0.5px;
    cursor: pointer;
    -webkit-transition: ease-in-out 0.2s;
    transition: ease-in-out 0.2s;
    background: #efefef; }
  .preview-customize .taplive2-main-chat-room-solve-wrapper.taplive2-main-chat-room-solve-wrapper-hide {
    height: 0;
    overflow: hidden;
    padding: 0; }
  .preview-customize .taplive2-main-chat-room-solve-wrapper .taplive2-lds-ring {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  .preview-customize .taplive2-main-chat-room-solve-wrapper .taplive2-lds-ring div {
    border-color: #ff7e00 transparent transparent transparent; }
  .preview-customize .taplive2-main-chat-room-solve-wrapper:hover {
    background: #ece9e9; }
  .preview-customize .taplive2-main-chat-room-solve-wrapper img {
    vertical-align: top;
    position: relative;
    top: 2px;
    margin-right: 4px; }
  .preview-customize .taplive2-file-bubble-circular-progress-wrapper {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    border-radius: 50%;
    z-index: 1; }
  .preview-customize .taplive2-file-bubble-circular-progress-wrapper .taplive2-lds-ring-file-out {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    background: #ffffff;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    padding: 3px; }
  .preview-customize .taplive2-file-bubble-circular-progress-wrapper .taplive2-lds-ring-file-out div {
    border-color: #ff7e00 transparent transparent transparent;
    width: 40px;
    height: 40px; }
  .preview-customize .taplive2-file-bubble-circular-progress-wrapper .taplive2-lds-ring-file-in {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    background: #ff7e00;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    padding: 3px; }
  .preview-customize .taplive2-file-bubble-circular-progress-wrapper .taplive2-lds-ring-file-in div {
    border-color: #ffffff transparent transparent transparent;
    width: 40px;
    height: 40px; }
  .preview-customize .taplive2-message-file-out-filesize {
    white-space: nowrap; }
  .preview-customize .taplive2-message-filesize-progress {
    display: inline-block; }
  .preview-customize .typing-dot-one {
    opacity: 0;
    -webkit-animation: typing-dot 1.3s infinite;
            animation: typing-dot 1.3s infinite;
    -webkit-animation-delay: 0s;
            animation-delay: 0s;
    letter-spacing: 1px; }
  .preview-customize .typing-dot-two {
    opacity: 0;
    -webkit-animation: typing-dot 1.3s infinite;
            animation: typing-dot 1.3s infinite;
    -webkit-animation-delay: 0.2s;
            animation-delay: 0.2s;
    letter-spacing: 1px; }
  .preview-customize .typing-dot-three {
    opacity: 0;
    -webkit-animation: typing-dot 1.3s infinite;
            animation: typing-dot 1.3s infinite;
    -webkit-animation-delay: 0.3s;
            animation-delay: 0.3s;
    letter-spacing: 1px; }

@-webkit-keyframes typing-dot {
  0% {
    opacity: 0; }
  50% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes typing-dot {
  0% {
    opacity: 0; }
  50% {
    opacity: 0; }
  100% {
    opacity: 1; } }
  .preview-customize .taplive2-chat-list-wrapper.active-typing .last-message-room-list {
    display: none; }
  .preview-customize .taplive2-chat-list-wrapper .typing-room-list {
    display: none; }
  .preview-customize .taplive2-chat-list-wrapper.active-typing .typing-room-list {
    display: block; }
  .preview-customize .message-body {
    word-break: break-word; }
  .preview-customize .button-disabled {
    background: #9b9b9b !important; }
  .preview-customize .active-taplive2-power
.taplive2-start-new-chat-wrapper
.taplive2-start-new-chat-bottom-content {
    /* height: calc(100% - 160px);
      max-height: calc(100% - 160px); */
    height: calc(100% - 125px);
    max-height: calc(100% - 125px);
    position: absolute;
    /* top: 160px; */
    top: 125px; }
  .preview-customize .active-taplive2-power
.taplive2-room-list-with-omnichannel
.taplive2-start-new-chat-bottom-content {
    /* height: calc(100% - 160px);
      max-height: calc(100% - 160px); */
    height: calc(100% - 125px);
    max-height: calc(100% - 125px);
    position: absolute;
    /* top: 160px; */
    top: 125px; }
  .preview-customize .active-taplive2-power
.taplive2-start-new-chat-wrapper
.taplive2-start-new-chat-bottom-content
.taplive2-start-new-chat-form-wrapper {
    max-height: 100%; }
  .preview-customize .active-taplive2-power
.taplive2-room-list-with-omnichannel
.taplive2-start-new-chat-bottom-content
.taplive2-start-new-chat-form-wrapper {
    max-height: 100%; }
  .preview-customize .active-taplive2-power .taplive2-room-list-bottom-content {
    height: calc(100vh - 252px); }
  .preview-customize .active-taplive2-power
.taplive2-main-chat-room-wrapper
.taplive2-main-chat-room-send-message-panel-wrapper {
    border-radius: 0; }
  .preview-customize .active-taplive2-power .new-conversation-form-wrapper {
    top: 70px !important; }
  .preview-customize .active-taplive2-power
.new-conversation-form-wrapper
.taplive2-start-new-chat-form-wrapper {
    max-height: unset !important; }
  .preview-customize .taplive2-custom-select-option {
    width: 100%;
    position: relative;
    cursor: pointer; }
  .preview-customize .taplive2-custom-select-option .taplive2-custom-select-option-value-box {
    display: block;
    width: 100%;
    box-sizing: border-box;
    border-radius: 8px;
    border: 1px solid rgba(25, 25, 25, 0.1);
    font-size: 16px;
    letter-spacing: -0.3px;
    padding: 16px;
    margin: 8px 0 12px; }
  .preview-customize .taplive2-custom-select-option-value-box::before {
    content: '';
    position: absolute;
    top: 23px;
    -webkit-transform: rotate(135deg);
            transform: rotate(135deg);
    right: 16px;
    border-top: solid 3px #191919;
    border-right: solid 3px #191919;
    border-bottom: solid 3px #ffffff;
    border-left: solid 3px #ffffff;
    -webkit-transition: ease-in-out 0.3s;
    transition: ease-in-out 0.3s; }
  .preview-customize .taplive2-custom-select-option-value-box.taplive2-active-select-box::before {
    top: 25px;
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg); }
  .preview-customize .taplive2-custom-select-option-wrapper {
    background: #ffffff;
    display: none;
    position: absolute;
    left: 0;
    top: 55px;
    width: 100%;
    border-radius: 8px;
    border: solid 1px rgba(25, 25, 25, 0.1);
    padding: 0;
    z-index: 1;
    max-height: 89px;
    overflow-y: auto; }
  .preview-customize .taplive2-custom-select-option-list {
    padding: 5px 16px; }
  .preview-customize .taplive2-custom-select-option-list:nth-child(1) {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px; }
  .preview-customize .taplive2-custom-select-option-list:nth-last-child(1) {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px; }
  .preview-customize .taplive2-custom-select-option-list:hover {
    background: rgba(255, 126, 0, 0.1); }
  .preview-customize .taplive2-custom-select-option-list.taplive2-selected-option {
    background: rgba(255, 126, 0, 0.1); }
  .preview-customize .omnichannel-option-description {
    font-size: 14px;
    letter-spacing: -0.4px;
    color: rgba(25, 25, 25, 0.6);
    margin: 0;
    text-align: center; }
  .preview-customize .omnichannel-option-image-wrapper {
    width: 100%;
    text-align: center;
    margin-top: 12px; }
  .preview-customize .omnichannel-option-image-wrapper a {
    text-decoration: none; }
  .preview-customize .omnichannel-option-image-wrapper img {
    vertical-align: top;
    margin-right: 7px;
    display: inline-block;
    max-width: calc(20% - 9.6px);
    -webkit-transition: ease-in-out 0.1s;
    transition: ease-in-out 0.1s;
    opacity: 1;
    text-align: center; }
  .preview-customize .omnichannel-option-image-wrapper img:hover {
    /* transform: scale(0.95); */
    /* opacity: 1; */
    /* opacity: .5; */
    /* display: none; */
    opacity: 1 !important; }
  .preview-customize .omnichannel-option-image-wrapper a:nth-last-child(1) img {
    margin-right: 0; }
  .preview-customize .omnichannel-option-separator {
    border-top: solid 1px #f3f3f3;
    position: relative;
    width: 100%;
    margin: 36px 0 12px; }
  .preview-customize .omnichannel-option-separator::before {
    content: 'OR';
    background: #ffffff;
    padding: 0 8px;
    font-weight: bold;
    color: rgba(25, 25, 25, 0.6);
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  .preview-customize .taplive2-submit-button {
    color: #ffffff;
    background: #ff7e00;
    width: 100%;
    border-radius: 8px;
    border: none;
    font-size: 16px;
    letter-spacing: -0.5px;
    text-align: center;
    width: 100%;
    padding: 13px;
    margin-top: 10px;
    cursor: pointer; }
  .preview-customize .taplive2-submit-button:hover {
    /* background: rgba(255, 126, 0, 0.8) !important; */ }
  .preview-customize .taplive2-full-loading {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    background: white;
    border-radius: 8px;
    left: 0;
    z-index: 1; }
  .preview-customize .taplive2-full-loading-content {
    text-align: center;
    position: absolute;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    left: 50%;
    font-size: 14px;
    color: rgba(255, 126, 0, 0.8); }
  .preview-customize .taplive2-full-loading-content .taplive2-lds-ring {
    width: 28px;
    height: 28px; }
  .preview-customize .taplive2-full-loading-content .taplive2-lds-ring div {
    width: 28px;
    height: 28px;
    border-color: rgba(255, 126, 0, 0.8) transparent transparent transparent; }
  .preview-customize .taplive2-room-list-with-omnichannel .taplive2-start-new-chat-form-wrapper {
    border-radius: 8px !important; }
  .preview-customize .taplive2-room-list-with-omnichannel-wrapper {
    margin-top: 16px;
    padding: 49px 16px 16px;
    background: #ffffff;
    border-radius: 8px;
    position: relative; }
  .preview-customize .taplive2-room-list-omnichannel-your-message {
    font-size: 14px;
    letter-spacing: -0.4px;
    color: #ff7d00;
    color: rgba(25, 25, 25, 0.6);
    position: absolute;
    left: 16px;
    top: 16px;
    margin: 0; }
  .preview-customize .taplive2-room-list-omnichannel-all-message {
    cursor: pointer;
    font-size: 14px;
    letter-spacing: -0.4px;
    color: #ff7d00;
    right: 16px;
    top: 16px;
    margin: 0;
    position: absolute;
    -webkit-transition: ease-in-out 0.1s;
    transition: ease-in-out 0.1s; }
  .preview-customize .taplive2-room-list-omnichannel-all-message:hover {
    opacity: 0.8; }
  .preview-customize .taplive2-room-list-with-omnichannel-button-wrapper button {
    border-radius: 8px;
    /* border: solid 1px rgba(255, 126, 0, 1); */
    /* background: #ffffff; */
    /* color: rgba(255, 126, 0, 1); */
    width: 100%;
    padding: 13px;
    cursor: pointer;
    font-size: 16px;
    letter-spacing: -0.5px;
    margin-top: 4px; }
  .preview-customize .taplive2-room-list-with-omnichannel-button-wrapper button:hover {
    /* background: rgba(255, 126, 0, 0.1) !important; */ }
  .preview-customize .taplive2-room-list-with-omnichannel-button-wrapper button img {
    margin-right: 5px; }
  .preview-customize .taplive2-room-list-with-omnichannel-button-wrapper button b {
    position: relative;
    top: -1px; }
  .preview-customize .taplive2-room-list-with-omnichannel-chatlist-wrapper
.taplive2-shimmer-room-list {
    height: unset; }
  .preview-customize .taplive2-start-new-case-without-omnichannel .first-start-new-case-back-button {
    display: none;
    padding: 3px;
    border-radius: 50%;
    -webkit-transition: ease-in-out 0.1s;
    transition: ease-in-out 0.1s; }
  .preview-customize .taplive2-start-new-case-without-omnichannel.taplive2-active-start-new-case-with-omnichannel
.taplive2-start-new-chat-top-content {
    padding-left: 65px; }
  .preview-customize .taplive2-start-new-case-without-omnichannel.taplive2-active-start-new-case-with-omnichannel
.first-start-new-case-back-button {
    display: inline-block;
    position: absolute;
    left: 24px;
    top: 22px;
    cursor: pointer; }
  .preview-customize .taplive2-start-new-case-without-omnichannel.taplive2-active-start-new-case-with-omnichannel
.first-start-new-case-back-button:hover {
    background: rgba(25, 25, 25, 0.3); }
  .preview-customize .taplive2-first-message-us-directly {
    width: 100%;
    padding: 13px;
    cursor: pointer;
    font-size: 16px;
    letter-spacing: -0.5px;
    border-radius: 8px;
    margin-top: 10px; }
  .preview-customize .taplive2-message-info-dark {
    background: rgba(25, 25, 25, 0.6);
    padding: 2px 5px;
    color: #ffffff !important; }
  .preview-customize .bubble-action-icon-wrapper {
    border-radius: 50%;
    position: absolute;
    width: 48px;
    height: 48px;
    display: inline-block;
    background: rgba(25, 25, 25, 0.4);
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    cursor: pointer; }
  .preview-customize .bubble-action-icon-wrapper img {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  .preview-customize .taplive2-fullscreen-background-dark {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(25, 25, 25, 0.9);
    display: none;
    z-index: 2; }
  .preview-customize .taplive2-fullscreen-background-dark .taplive2-close-blackscreen {
    cursor: pointer;
    position: absolute;
    right: 16px;
    top: 16px; }
  .preview-customize .taplive2-fullscreen-background-dark .taplive2-close-blackscreen:hover {
    -webkit-transform: scale(0.9);
            transform: scale(0.9); }
  .preview-customize .taplive2-image-preview-panel .taplive2-image-preview-value {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    max-width: calc(100vw - 130px);
    max-height: calc(100vh - 130px); }
  .preview-customize .taplive2-video-preview-panel .taplive2-video-preview-value {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    max-width: calc(100vw - 130px);
    max-height: calc(100vh - 130px); }
  .preview-customize .taplive2-preupload-file-wrapper {
    position: absolute;
    display: inline-block;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    top: 50%;
    text-align: center;
    min-width: 250px; }
  .preview-customize .taplive2-preupload-file-wrapper input {
    border: none;
    border-bottom: solid 1px #ffffff;
    color: #ffffff;
    font-size: 14px;
    letter-spacing: -0.5px;
    outline: none;
    text-align: left;
    background: none;
    padding: 8px 28px 8px 0;
    width: 100%; }
  .preview-customize .taplive2-preupload-file-wrapper input:focus {
    border: none !important;
    box-shadow: none !important;
    outline: none !important;
    border-bottom: solid 1px #ffffff !important; }
  .preview-customize .taplive2-preupload-file-wrapper input::-webkit-input-placeholder {
    color: rgba(255, 255, 255, 0.3); }
  .preview-customize .taplive2-preupload-file-wrapper input::-ms-input-placeholder {
    color: rgba(255, 255, 255, 0.3); }
  .preview-customize .taplive2-preupload-file-wrapper input::placeholder {
    color: rgba(255, 255, 255, 0.3); }
  .preview-customize .taplive2-preupload-file-wrapper .taplive2-send-media-button-submit {
    position: absolute;
    right: 0;
    bottom: 41px;
    cursor: pointer; }
  .preview-customize .taplive2-preupload-file-wrapper .taplive2-caption-limit {
    width: 100%;
    text-align: right;
    padding: 8px 0;
    color: #ffffff;
    font-size: 14px;
    letter-spacing: -0.5px; }
  .preview-customize .taplive2-image-preupload-panel
.taplive2-image-preupload-file-wrapper
.taplive2-image-preupload-value {
    max-width: calc(100vw - 170px);
    max-height: calc(100vh - 170px); }
  .preview-customize .taplive2-video-preupload-panel
.taplive2-video-preupload-file-wrapper
.taplive2-video-preupload-value {
    max-width: calc(100vw - 170px);
    max-height: calc(100vh - 170px); }

.integration-taptalk-edit-account {
  padding: 32px 30px;
  text-align: left; }
  .integration-taptalk-edit-account .comp-back-button {
    margin: 0 0 16px 0; }
  .integration-taptalk-edit-account .integration-taptalk-edit-account-box {
    background: #ffffff;
    border-radius: 8px;
    padding: 32px;
    border: solid 1px rgba(25, 25, 25, 0.1); }
    .integration-taptalk-edit-account .integration-taptalk-edit-account-box .integration-taptalk-edit-account-box-top {
      padding-bottom: 20px;
      border-bottom: solid 1px #dcdcdc;
      position: relative;
      font-size: 20px;
      letter-spacing: -0.6px; }
      .integration-taptalk-edit-account .integration-taptalk-edit-account-box .integration-taptalk-edit-account-box-top button {
        position: absolute;
        right: 0;
        font-size: 16px; }
    .integration-taptalk-edit-account .integration-taptalk-edit-account-box .integration-taptalk-edit-account-box-form {
      width: 575px; }
      .integration-taptalk-edit-account .integration-taptalk-edit-account-box .integration-taptalk-edit-account-box-form label {
        padding: 24px 0 0;
        font-size: 14px;
        letter-spacing: -0.4px;
        display: block;
        position: relative; }
        .integration-taptalk-edit-account .integration-taptalk-edit-account-box .integration-taptalk-edit-account-box-form label span {
          position: absolute;
          right: 0; }
      .integration-taptalk-edit-account .integration-taptalk-edit-account-box .integration-taptalk-edit-account-box-form input[type="text"] {
        padding: 12px 16px;
        border-radius: 6px;
        border: solid 1px #dcdcdc;
        width: 100%;
        font-size: 16px;
        letter-spacing: -0.3px;
        display: block;
        margin-bottom: 8px; }
      .integration-taptalk-edit-account .integration-taptalk-edit-account-box .integration-taptalk-edit-account-box-form .integration-info-form {
        font-size: 14px;
        color: rgba(25, 25, 25, 0.8);
        letter-spacing: -0.2px; }
      .integration-taptalk-edit-account .integration-taptalk-edit-account-box .integration-taptalk-edit-account-box-form .custom-select-taptalk-topic .custom-select-option-value-wrapper {
        padding: 12px 16px !important;
        margin-bottom: 8px; }


.integration-livechat-add-account {
  padding: 32px 30px;
  text-align: left; }
  .integration-livechat-add-account .comp-back-button {
    margin: 0 0 16px 0;
    font-family: PTRootBold; }
  .integration-livechat-add-account .integration-livechat-add-account-box {
    background: #ffffff;
    border-radius: 8px;
    padding: 32px;
    border: solid 1px rgba(25, 25, 25, 0.1); }
    .integration-livechat-add-account .integration-livechat-add-account-box .integration-livechat-add-account-box-top {
      padding-bottom: 15px;
      border-bottom: solid 1px #dcdcdc;
      position: relative;
      font-size: 20px;
      letter-spacing: -0.6px; }
      .integration-livechat-add-account .integration-livechat-add-account-box .integration-livechat-add-account-box-top button {
        position: absolute;
        top: -6px;
        right: 0;
        font-size: 16px;
        letter-spacing: -0.6px; }
    .integration-livechat-add-account .integration-livechat-add-account-box .integration-livechat-add-account-box-form {
      width: 100%; }
      .integration-livechat-add-account .integration-livechat-add-account-box .integration-livechat-add-account-box-form label {
        padding: 24px 0 0;
        font-size: 14px;
        letter-spacing: -0.4px;
        display: block;
        position: relative; }
        .integration-livechat-add-account .integration-livechat-add-account-box .integration-livechat-add-account-box-form label span {
          position: absolute;
          right: 0; }
      .integration-livechat-add-account .integration-livechat-add-account-box .integration-livechat-add-account-box-form input[type='text'] {
        padding: 12px 16px;
        border-radius: 6px;
        border: solid 1px #dcdcdc;
        width: 100%;
        font-size: 16px;
        letter-spacing: -0.3px;
        display: block; }
      .integration-livechat-add-account .integration-livechat-add-account-box .integration-livechat-add-account-box-form .form-topic {
        width: 320px; }
      .integration-livechat-add-account .integration-livechat-add-account-box .integration-livechat-add-account-box-form .form-origin {
        width: 320px; }
        .integration-livechat-add-account .integration-livechat-add-account-box .integration-livechat-add-account-box-form .form-origin label {
          display: flex; }
          .integration-livechat-add-account .integration-livechat-add-account-box .integration-livechat-add-account-box-form .form-origin label .origin-title {
            margin-right: 5px; }
            .integration-livechat-add-account .integration-livechat-add-account-box .integration-livechat-add-account-box-form .form-origin label .origin-title span {
              font-family: PTRootUI; }
        .integration-livechat-add-account .integration-livechat-add-account-box .integration-livechat-add-account-box-form .form-origin input[type='text']:focus {
          border: 1px solid #ff7d00; }
      .integration-livechat-add-account .integration-livechat-add-account-box .integration-livechat-add-account-box-form input[type='text']:disabled {
        background: rgba(25, 25, 25, 0.1);
        color: rgba(25, 25, 25, 0.4);
        border: none;
        cursor: not-allowed; }
      .integration-livechat-add-account .integration-livechat-add-account-box .integration-livechat-add-account-box-form .integration-info-form {
        font-size: 14px;
        color: rgba(25, 25, 25, 0.8);
        letter-spacing: -0.2px; }
      .integration-livechat-add-account .integration-livechat-add-account-box .integration-livechat-add-account-box-form .custom-select-livechat-topic .custom-select-option-value-wrapper {
        padding: 12px 16px !important;
        margin-bottom: 8px; }

.integration-livechat-list {
  text-align: left; }
  .integration-livechat-list .integration-list-loading-wrapper .lds-ring {
    width: 50px;
    height: 50px;
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    right: unset; }
    .integration-livechat-list .integration-list-loading-wrapper .lds-ring div {
      border: 5px solid #fff;
      border-color: #ff7d00 transparent transparent transparent;
      width: 50px;
      height: 50px;
      margin: 0; }
  .integration-livechat-list .integration-list-header {
    position: relative;
    padding-left: 42px;
    padding-bottom: 26px;
    border-bottom: solid 1px rgba(25, 25, 25, 0.1); }
    .integration-livechat-list .integration-list-header .integration-list-header-icon {
      position: absolute;
      top: 0;
      left: 0;
      width: 32px; }
    .integration-livechat-list .integration-list-header .integration-list-header-text-wrapper {
      text-align: left; }
      .integration-livechat-list .integration-list-header .integration-list-header-text-wrapper b {
        font-size: 20px;
        letter-spacing: -0.6px; }
      .integration-livechat-list .integration-list-header .integration-list-header-text-wrapper p {
        margin: 0;
        font-size: 14px;
        letter-spacing: -0.2px; }
      .integration-livechat-list .integration-list-header .integration-list-header-text-wrapper a {
        color: #ff7d00; }
    .integration-livechat-list .integration-list-header .integration-list-header-button {
      position: absolute;
      right: 0;
      top: 0;
      font-size: 16px;
      letter-spacing: -0.5px; }
  .integration-livechat-list .integration-list-no-data {
    width: 100%;
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
    .integration-livechat-list .integration-list-no-data b {
      font-size: 20px;
      letter-spacing: -0.6px;
      color: #191919; }
    .integration-livechat-list .integration-list-no-data p {
      color: rgba(25, 25, 25, 0.6);
      letter-spacing: -0.6px;
      font-size: 16px; }
  .integration-livechat-list .integration-list-outer-wrapper {
    position: relative;
    width: 100%;
    padding-top: 24px;
    text-align: left; }
    .integration-livechat-list .integration-list-outer-wrapper .integration-list-inner-wrapper {
      width: calc((100% / 3) - 8px);
      border-radius: 8px;
      margin: 0 12px 12px 0;
      box-shadow: 0 2px 20px 0 rgba(25, 25, 25, 0.05);
      background: #ffffff;
      display: inline-block;
      vertical-align: top;
      padding: 24px 30px 24px 24px;
      cursor: pointer;
      position: relative; }
      .integration-livechat-list .integration-list-outer-wrapper .integration-list-inner-wrapper .platform-wrapper {
        display: flex;
        align-items: center; }
        .integration-livechat-list .integration-list-outer-wrapper .integration-list-inner-wrapper .platform-wrapper .platform-name {
          font-size: 16px;
          letter-spacing: -0.5px;
          color: rgba(25, 25, 25, 0.6);
          margin: 0;
          white-space: nowrap;
          max-width: 100%;
          overflow: hidden;
          text-overflow: ellipsis; }
        .integration-livechat-list .integration-list-outer-wrapper .integration-list-inner-wrapper .platform-wrapper img {
          margin-right: 5px; }
      .integration-livechat-list .integration-list-outer-wrapper .integration-list-inner-wrapper span {
        display: block;
        font-size: 12px;
        letter-spacing: -0.2px;
        color: #191919;
        white-space: nowrap;
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis; }
      .integration-livechat-list .integration-list-outer-wrapper .integration-list-inner-wrapper svg {
        position: absolute;
        right: 12px;
        top: 12px;
        font-size: 22px;
        color: rgba(25, 25, 25, 0.6); }
      .integration-livechat-list .integration-list-outer-wrapper .integration-list-inner-wrapper:hover {
        box-shadow: 0 2px 20px 0 rgba(25, 25, 25, 0.18); }
        .integration-livechat-list .integration-list-outer-wrapper .integration-list-inner-wrapper:hover .platform-name {
          color: #191919; }
        .integration-livechat-list .integration-list-outer-wrapper .integration-list-inner-wrapper:hover svg {
          color: #ff7d00; }
  .integration-livechat-list .link-livechat-account-list:nth-child(3n) .integration-list-inner-wrapper {
    margin: 0 0 12px 0; }

.alert-sdk {
  display: block;
  margin: 12px 0 24px !important;
  width: 100%; }

.chatbot-add-chatbot {
  padding: 32px 30px;
  text-align: left; }
  .chatbot-add-chatbot .comp-back-button {
    margin: 0 0 16px 0; }
  .chatbot-add-chatbot .chatbot-add-chatbot-box {
    background: #ffffff;
    border-radius: 8px;
    padding: 32px;
    border: solid 1px rgba(25, 25, 25, 0.1); }
    .chatbot-add-chatbot .chatbot-add-chatbot-box .chatbot-add-chatbot-box-top {
      padding-bottom: 15px;
      border-bottom: solid 1px #dcdcdc;
      position: relative;
      font-size: 20px;
      letter-spacing: -0.6px; }
      .chatbot-add-chatbot .chatbot-add-chatbot-box .chatbot-add-chatbot-box-top .chat-bot-chatbot-button-wrapper {
        margin: 0; }
      .chatbot-add-chatbot .chatbot-add-chatbot-box .chatbot-add-chatbot-box-top button {
        position: absolute;
        top: -6px;
        right: 0;
        margin: 0 !important;
        font-size: 16px;
        letter-spacing: -0.6px; }
    .chatbot-add-chatbot .chatbot-add-chatbot-box .chatbot-add-chatbot-box-form {
      width: 320px; }
      .chatbot-add-chatbot .chatbot-add-chatbot-box .chatbot-add-chatbot-box-form label {
        padding: 24px 0 0;
        font-size: 14px;
        letter-spacing: -0.4px;
        display: block; }
      .chatbot-add-chatbot .chatbot-add-chatbot-box .chatbot-add-chatbot-box-form input[type="text"] {
        padding: 12px 16px;
        border-radius: 6px;
        border: solid 1px #dcdcdc;
        width: 100%;
        font-size: 16px;
        letter-spacing: -0.3px;
        display: block;
        margin-bottom: 8px; }
      .chatbot-add-chatbot .chatbot-add-chatbot-box .chatbot-add-chatbot-box-form .integration-info-form {
        font-size: 14px;
        color: rgba(25, 25, 25, 0.8);
        letter-spacing: -0.2px; }
      .chatbot-add-chatbot .chatbot-add-chatbot-box .chatbot-add-chatbot-box-form .custom-select-path .custom-select-option-value-wrapper {
        padding: 12px 16px !important;
        margin-bottom: 8px; }
    .chatbot-add-chatbot .chatbot-add-chatbot-box .chat-bot-chatbot-button-wrapper {
      margin-top: 18px; }
      .chatbot-add-chatbot .chatbot-add-chatbot-box .chat-bot-chatbot-button-wrapper button {
        margin: 0 4px 0 0; }


.integration-container {
  padding: 0;
  min-height: 520px;
  position: relative;
  margin: 0;
  border: none;
  height: 100vh;
  border-radius: 0; }

.integration-side-menu-wrapper {
  padding: 0;
  width: 278px;
  height: 100vh;
  max-height: 100vh;
  overflow: hidden;
  position: absolute;
  left: 0;
  text-align: center;
  z-index: 1;
  box-shadow: 0 2px 20px 0 rgba(25, 25, 25, 0.05); }

.integration-side-menu-option {
  margin-top: 12px;
  width: calc(100% - 24px);
  -webkit-transition: ease-in-out .2s;
  transition: ease-in-out .2s;
  border-radius: 8px;
  padding: 16px 12px 16px 44px;
  color: rgba(25, 25, 25, 0.4);
  font-size: 16px;
  letter-spacing: -0.5px;
  line-height: 1;
  position: relative;
  box-sizing: border-box;
  display: inline-block;
  text-align: left; }
  .integration-side-menu-option:hover {
    background: #ff7d00;
    color: #ffffff;
    cursor: pointer; }
  .integration-side-menu-option img {
    position: absolute;
    left: 12px;
    top: 50%;
    -webkit-transform: translate(0, -50%);
            transform: translate(0, -50%); }

.integration-side-menu-option.active-integration-side-menu {
  background: rgba(255, 126, 0, 0.1);
  color: #ff7d00 !important; }

.integration-main-content {
  width: calc(100% - 256px);
  height: 100%;
  position: absolute;
  top: 0;
  left: 256px; }

.integration-main-container {
  background: #f8f8f8;
  text-align: center;
  color: #191919;
  font-size: 16px;
  letter-spacing: -0.5px; }
  .integration-main-container img.icon-integration-content {
    width: 60px;
    margin-bottom: 20px; }
  .integration-main-container .button-email {
    margin-top: 24px; }
  .integration-main-container img.icon-integration-content {
    width: 60px;
    margin-bottom: 20px; }
  .integration-main-container .arrow-icon {
    margin-right: 5px;
    position: relative;
    top: -2px; }
  .integration-main-container.sectionWrap {
    border: none;
    padding: 16px 0;
    min-height: calc(100vh - 80px); }

.taptalk-snippet {
  width: 100%;
  background: #183055;
  text-align: left;
  /* border: solid 1px #dcdcdc; */
  border-radius: 4px;
  margin: 15px 0;
  display: block;
  padding: 10px;
  color: #ffffff; }

.link-integration {
  color: #ff7d00; }


.integration-whatsapp-business-list {
  text-align: left; }
  .integration-whatsapp-business-list .integration-list-loading-wrapper .lds-ring {
    width: 50px;
    height: 50px;
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    right: unset; }
    .integration-whatsapp-business-list .integration-list-loading-wrapper .lds-ring div {
      border: 5px solid #fff;
      border-color: #ff7d00 transparent transparent transparent;
      width: 50px;
      height: 50px;
      margin: 0; }
  .integration-whatsapp-business-list .integration-list-no-data {
    width: 100%;
    text-align: center;
    position: relative;
    margin: 32px 0 0; }
    .integration-whatsapp-business-list .integration-list-no-data b {
      font-size: 20px;
      letter-spacing: -0.6px;
      color: #191919; }
      .integration-whatsapp-business-list .integration-list-no-data b.grey-font {
        font-size: 16px !important;
        letter-spacing: -0.6px !important; }
    .integration-whatsapp-business-list .integration-list-no-data p {
      color: rgba(25, 25, 25, 0.6);
      letter-spacing: -0.6px;
      font-size: 16px; }
  .integration-whatsapp-business-list .integration-list-header {
    position: relative;
    padding-left: 42px;
    padding-bottom: 26px;
    border-bottom: solid 1px rgba(25, 25, 25, 0.1); }
    .integration-whatsapp-business-list .integration-list-header .integration-list-header-icon {
      position: absolute;
      top: 0;
      left: 0; }
    .integration-whatsapp-business-list .integration-list-header .integration-list-header-text-wrapper b {
      font-size: 20px;
      letter-spacing: -0.6px; }
    .integration-whatsapp-business-list .integration-list-header .integration-list-header-text-wrapper p {
      margin: 0;
      font-size: 14px;
      letter-spacing: -0.2px; }
    .integration-whatsapp-business-list .integration-list-header .integration-list-header-text-wrapper a {
      color: #ff7d00; }
    .integration-whatsapp-business-list .integration-list-header .integration-list-header-button {
      position: absolute;
      right: 0;
      top: 0;
      font-size: 16px;
      letter-spacing: -0.5px;
      padding: 7px 32px; }
      .integration-whatsapp-business-list .integration-list-header .integration-list-header-button img {
        margin-right: 4px;
        position: relative;
        top: -2px; }
  .integration-whatsapp-business-list .alert-component-whatsapp {
    border-radius: 8px;
    padding: 13px 122px 13px 10px;
    color: #044B84;
    background: #DCEBFF;
    border: solid 1px #044B84;
    margin: 24px 0 12px;
    line-height: 1;
    position: relative;
    display: inline-block; }
    .integration-whatsapp-business-list .alert-component-whatsapp svg {
      position: relative;
      margin: 0 4px 0 6px;
      top: -1px;
      font-size: 18px; }
    .integration-whatsapp-business-list .alert-component-whatsapp img {
      position: relative;
      margin: 0 4px 0 6px;
      top: -2px;
      width: 15px;
      height: 15px; }
    .integration-whatsapp-business-list .alert-component-whatsapp button {
      position: absolute;
      right: 16px;
      top: 50%;
      -webkit-transform: translate(0, -50%);
              transform: translate(0, -50%);
      height: 26px;
      font-size: 12px;
      letter-spacing: -0.4px;
      border: none;
      color: #ffffff;
      background: #044b84;
      border-radius: 4px;
      padding: 0 12px; }
      .integration-whatsapp-business-list .alert-component-whatsapp button:hover {
        background: rgba(4, 75, 132, 0.8); }
  .integration-whatsapp-business-list .filter-result-found {
    margin: 24px 0 0;
    color: rgba(25, 25, 25, 0.6);
    font-size: 16px;
    letter-spacing: -0.5px; }
  .integration-whatsapp-business-list .search-and-filter-wrapper {
    width: 100%;
    display: block;
    margin-top: 24px;
    position: relative; }
    .integration-whatsapp-business-list .search-and-filter-wrapper .search-box-wrapper {
      width: 370px; }
    .integration-whatsapp-business-list .search-and-filter-wrapper .dropdown-filter-status {
      display: inline-block;
      position: absolute;
      left: 382px;
      top: 0; }
  .integration-whatsapp-business-list .integration-list-outer-wrapper {
    position: relative;
    width: 100%;
    padding-top: 24px; }
    .integration-whatsapp-business-list .integration-list-outer-wrapper .integration-list-inner-wrapper {
      width: calc((100% / 3) - 8px);
      border-radius: 8px;
      margin: 0 12px 12px 0;
      box-shadow: 0 2px 20px 0 rgba(25, 25, 25, 0.05);
      background: #ffffff;
      display: inline-block;
      vertical-align: top;
      padding: 24px 30px 24px 24px;
      cursor: pointer;
      position: relative; }
      .integration-whatsapp-business-list .integration-list-outer-wrapper .integration-list-inner-wrapper p:nth-child(2) {
        font-size: 16px;
        letter-spacing: -0.5px;
        color: rgba(25, 25, 25, 0.6);
        margin: 0;
        white-space: nowrap;
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis; }
      .integration-whatsapp-business-list .integration-list-outer-wrapper .integration-list-inner-wrapper span {
        display: block;
        font-size: 12px;
        letter-spacing: -0.2px;
        color: #191919; }
      .integration-whatsapp-business-list .integration-list-outer-wrapper .integration-list-inner-wrapper p {
        margin: 10px 0 0;
        color: #ff7d00;
        font-size: 12px;
        letter-spacing: -0.2px; }
        .integration-whatsapp-business-list .integration-list-outer-wrapper .integration-list-inner-wrapper p.font-green {
          color: #19c700; }
        .integration-whatsapp-business-list .integration-list-outer-wrapper .integration-list-inner-wrapper p.font-grey {
          color: rgba(25, 25, 25, 0.4); }
      .integration-whatsapp-business-list .integration-list-outer-wrapper .integration-list-inner-wrapper svg {
        position: absolute;
        right: 12px;
        top: 12px;
        font-size: 22px;
        color: rgba(25, 25, 25, 0.6); }
      .integration-whatsapp-business-list .integration-list-outer-wrapper .integration-list-inner-wrapper:hover {
        box-shadow: 0 2px 20px 0 rgba(25, 25, 25, 0.18); }
        .integration-whatsapp-business-list .integration-list-outer-wrapper .integration-list-inner-wrapper:hover p:nth-child(2) {
          color: #191919; }
        .integration-whatsapp-business-list .integration-list-outer-wrapper .integration-list-inner-wrapper:hover svg {
          color: #ff7d00; }
  .integration-whatsapp-business-list .link-whatsapp-account-list:nth-child(3n) .integration-list-inner-wrapper {
    margin: 0 0 12px 0; }

.integration.lds-ring {
  height: 64px;
  width: 64px;
  top: 50vh;
  left: 50vw;
  margin: 0 0 12px 0; }
  .integration.lds-ring div {
    height: 64px;
    width: 64px;
    margin: 0;
    border: 5px solid #fff;
    border-color: #ff7d00 transparent transparent transparent; }

.integration-has-request-and-no-channels {
  text-align: center;
  margin-top: 40vh; }
  .integration-has-request-and-no-channels img {
    margin-bottom: 8px; }
  .integration-has-request-and-no-channels b {
    font-size: 24px; }
  .integration-has-request-and-no-channels p {
    font-size: 16px;
    width: 413px;
    margin: 5px auto auto; }

.modal-info .modal-content {
  box-sizing: border-box;
  width: 476px;
  padding: 0 32px;
  border-radius: 8px; }

.modal-info .modal-body {
  font-size: 14px;
  letter-spacing: -0.2px;
  text-align: left;
  color: #9b9b9b;
  text-align: center;
  padding: 32px 0 12px; }
  .modal-info .modal-body .modal-info-title-image {
    margin-bottom: 8px; }
  .modal-info .modal-body p.info-description {
    margin: 4px 0 0;
    font-size: 14px;
    letter-spacing: -0.2px;
    color: rgba(25, 25, 25, 0.8);
    line-height: 21px; }
  .modal-info .modal-body b.info-title {
    font-size: 20px;
    letter-spacing: -0.6px;
    color: #191919;
    line-height: 24px; }
    .modal-info .modal-body b.info-title img {
      position: relative;
      top: -2px;
      margin-right: 4px; }
    .modal-info .modal-body b.info-title svg {
      position: relative;
      top: -2px;
      margin-right: 4px; }

.modal-info .modal-footer {
  padding: 0 0 32px;
  border: none; }
  .modal-info .modal-footer button {
    width: 100%; }

.integration-whatsapp-business-api-add-account {
  padding: 32px 30px;
  text-align: left; }
  .integration-whatsapp-business-api-add-account .comp-back-button {
    margin: 0 0 24px 0; }
  .integration-whatsapp-business-api-add-account .integration-whatsapp-business-api-add-account-box-top {
    padding-bottom: 12px;
    border-bottom: solid 1px #dcdcdc;
    position: relative;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: -0.6px;
    color: #191919;
    margin-bottom: 24px; }
    .integration-whatsapp-business-api-add-account .integration-whatsapp-business-api-add-account-box-top p {
      letter-spacing: -0.2px;
      margin: 0;
      font-size: 14px;
      color: rgba(25, 25, 25, 0.8); }
  .integration-whatsapp-business-api-add-account .integration-whatsapp-business-api-add-account-box-form {
    background: #ffffff;
    border-radius: 8px;
    padding: 32px;
    border: solid 1px rgba(25, 25, 25, 0.1); }
    .integration-whatsapp-business-api-add-account .integration-whatsapp-business-api-add-account-box-form .integration-whatsapp-business-api-add-account-box-form-top {
      width: 100%;
      border-bottom: solid 1px #dcdcdc;
      position: relative;
      padding-bottom: 16px; }
      .integration-whatsapp-business-api-add-account .integration-whatsapp-business-api-add-account-box-form .integration-whatsapp-business-api-add-account-box-form-top .whatsapp-business-form-step {
        position: relative;
        white-space: nowrap;
        font-size: 16px;
        letter-spacing: -0.5px;
        color: rgba(25, 25, 25, 0.4);
        overflow: hidden;
        max-width: calc(100% - 124px); }
        .integration-whatsapp-business-api-add-account .integration-whatsapp-business-api-add-account-box-form .integration-whatsapp-business-api-add-account-box-form-top .whatsapp-business-form-step .whatsapp-business-form-step-content {
          display: inline-block; }
          .integration-whatsapp-business-api-add-account .integration-whatsapp-business-api-add-account-box-form .integration-whatsapp-business-api-add-account-box-form-top .whatsapp-business-form-step .whatsapp-business-form-step-content.active-whatsapp-business-form-step {
            color: #191919; }
          .integration-whatsapp-business-api-add-account .integration-whatsapp-business-api-add-account-box-form .integration-whatsapp-business-api-add-account-box-form-top .whatsapp-business-form-step .whatsapp-business-form-step-content .value-number {
            border: 2px solid #E87402;
            background: #ff7e00;
            width: 24px;
            height: 24px;
            display: inline-block;
            border-radius: 50%;
            position: relative;
            margin-right: 4px;
            color: #ffffff;
            vertical-align: top;
            text-align: center;
            font-size: 13px;
            line-height: 19px;
            letter-spacing: 0; }
            .integration-whatsapp-business-api-add-account .integration-whatsapp-business-api-add-account-box-form .integration-whatsapp-business-api-add-account-box-form-top .whatsapp-business-form-step .whatsapp-business-form-step-content .value-number.not-active-value-wrapper {
              border: solid 2px #e8e8e8;
              background: #ffffff;
              color: rgba(25, 25, 25, 0.4); }
            .integration-whatsapp-business-api-add-account .integration-whatsapp-business-api-add-account-box-form .integration-whatsapp-business-api-add-account-box-form-top .whatsapp-business-form-step .whatsapp-business-form-step-content .value-number.value-check {
              background: #e8e8e8;
              color: #959595;
              border: solid 2px #e8e8e8; }
        .integration-whatsapp-business-api-add-account .integration-whatsapp-business-api-add-account-box-form .integration-whatsapp-business-api-add-account-box-form-top .whatsapp-business-form-step .line-separator {
          width: 30px;
          height: 2px;
          background: rgba(25, 25, 25, 0.05);
          margin: 0 12px 0;
          display: inline-block;
          position: relative;
          top: -4px; }
      .integration-whatsapp-business-api-add-account .integration-whatsapp-business-api-add-account-box-form .integration-whatsapp-business-api-add-account-box-form-top .integration-whatsapp-business-api-add-account-box-form-top-lang {
        position: absolute;
        right: 0;
        top: -3px;
        white-space: nowrap; }
        .integration-whatsapp-business-api-add-account .integration-whatsapp-business-api-add-account-box-form .integration-whatsapp-business-api-add-account-box-form-top .integration-whatsapp-business-api-add-account-box-form-top-lang .lang-content {
          border: solid 1px #dcdcdc;
          background: #ffffff;
          color: pointer;
          text-transform: uppercase;
          font-size: 12px;
          letter-spacing: -0.4px;
          color: rgba(25, 25, 25, 0.6);
          border-radius: 4px;
          margin-right: 4px;
          display: inline-block;
          padding: 1px 12px 1px 8px;
          cursor: pointer;
          -webkit-transition: ease-in-out .1s;
          transition: ease-in-out .1s; }
          .integration-whatsapp-business-api-add-account .integration-whatsapp-business-api-add-account-box-form .integration-whatsapp-business-api-add-account-box-form-top .integration-whatsapp-business-api-add-account-box-form-top-lang .lang-content img {
            margin-right: 2px;
            position: relative;
            top: 1px; }
          .integration-whatsapp-business-api-add-account .integration-whatsapp-business-api-add-account-box-form .integration-whatsapp-business-api-add-account-box-form-top .integration-whatsapp-business-api-add-account-box-form-top-lang .lang-content:hover {
            background: #ff7e00;
            border: solid 1px #ff7e00;
            color: #ffffff; }
          .integration-whatsapp-business-api-add-account .integration-whatsapp-business-api-add-account-box-form .integration-whatsapp-business-api-add-account-box-form-top .integration-whatsapp-business-api-add-account-box-form-top-lang .lang-content:nth-last-child(1) {
            margin: 0; }
          .integration-whatsapp-business-api-add-account .integration-whatsapp-business-api-add-account-box-form .integration-whatsapp-business-api-add-account-box-form-top .integration-whatsapp-business-api-add-account-box-form-top-lang .lang-content.active-lang-content {
            background: #ff7e00;
            border: solid 1px #ff7e00;
            color: #ffffff; }
    .integration-whatsapp-business-api-add-account .integration-whatsapp-business-api-add-account-box-form .integration-whatsapp-business-api-add-account-box-form-tab {
      width: 100%;
      position: relative; }
      .integration-whatsapp-business-api-add-account .integration-whatsapp-business-api-add-account-box-form .integration-whatsapp-business-api-add-account-box-form-tab .integration-whatsapp-business-tab-content {
        background: #ffffff; }
        .integration-whatsapp-business-api-add-account .integration-whatsapp-business-api-add-account-box-form .integration-whatsapp-business-api-add-account-box-form-tab .integration-whatsapp-business-tab-content.active-tab {
          display: block; }
    .integration-whatsapp-business-api-add-account .integration-whatsapp-business-api-add-account-box-form .tooltip-toggle {
      position: relative;
      top: -1px;
      display: inline-block;
      margin-left: 4px; }
    .integration-whatsapp-business-api-add-account .integration-whatsapp-business-api-add-account-box-form .tooltip-link {
      color: #ff7e00;
      cursor: pointer; }
      .integration-whatsapp-business-api-add-account .integration-whatsapp-business-api-add-account-box-form .tooltip-link:hover {
        text-decoration: underline !important; }
    .integration-whatsapp-business-api-add-account .integration-whatsapp-business-api-add-account-box-form input[type="text"] {
      padding: 12px 16px;
      border-radius: 6px;
      border: solid 1px #dcdcdc;
      width: 100%;
      font-size: 16px;
      letter-spacing: -0.3px;
      display: block;
      margin-bottom: 8px; }
    .integration-whatsapp-business-api-add-account .integration-whatsapp-business-api-add-account-box-form .custom-radio label {
      color: rgba(25, 25, 25, 0.8);
      font-size: 14px;
      letter-spacing: -0.4px;
      display: inline-block;
      margin-top: 0; }

.phone-input-wrapper {
  position: relative;
  width: 300px;
  display: inline-block; }
  .phone-input-wrapper .area-code-wrapper {
    min-width: 135px;
    margin-right: 4px; }
  .phone-input-wrapper .country-search-wrapper input {
    margin: 0;
    width: 100% !important; }
  .phone-input-wrapper .country-list-select {
    max-height: 148px !important; }
    .phone-input-wrapper .country-list-select.no-option {
      border: none !important; }
  .phone-input-wrapper button {
    margin: 0;
    height: 48px !important;
    vertical-align: top; }
  .phone-input-wrapper .phone-input {
    display: inline-block !important;
    vertical-align: top;
    width: calc(100% - 139px) !important; }

.form-questionnaire {
  position: relative;
  width: 100%; }
  .form-questionnaire .submit-button {
    width: unset;
    margin-top: 26px; }
  .form-questionnaire .custom-radio label {
    margin: 0;
    display: inline-block !important;
    line-height: 24px; }

.modal-listed-here .modal-content {
  width: 900px; }
  .modal-listed-here .modal-content .modal-body {
    position: relative;
    padding: 32px;
    width: 100%;
    text-align: center;
    color: #191919; }
    .modal-listed-here .modal-content .modal-body .modal-listed-here-title {
      font-size: 20px;
      letter-spacing: -0.6px;
      margin: 0; }
    .modal-listed-here .modal-content .modal-body svg.close-modal-listed-here {
      cursor: pointer;
      font-size: 18px;
      position: absolute;
      top: 41px;
      right: 41px; }
      .modal-listed-here .modal-content .modal-body svg.close-modal-listed-here:hover {
        color: #ff7e00; }
    .modal-listed-here .modal-content .modal-body .listed-here-card {
      width: calc(25% - 9px);
      margin-right: 12px;
      display: inline-block;
      position: relative;
      vertical-align: top;
      text-align: center;
      margin: 48px 12px 0 0; }
      .modal-listed-here .modal-content .modal-body .listed-here-card img {
        margin-bottom: 8px; }
      .modal-listed-here .modal-content .modal-body .listed-here-card .listed-here-card-title-wrapper {
        margin-bottom: 12px;
        height: 63px;
        width: 100%; }
        .modal-listed-here .modal-content .modal-body .listed-here-card .listed-here-card-title-wrapper .listed-here-card-title {
          font-size: 14px;
          letter-spacing: -0.4px; }
      .modal-listed-here .modal-content .modal-body .listed-here-card:nth-last-child(1) {
        margin-right: 0; }
      .modal-listed-here .modal-content .modal-body .listed-here-card .listed-here-card-list {
        margin: 0;
        position: relative;
        width: 100%;
        background: rgba(25, 25, 25, 0.05);
        border-radius: 8px;
        text-align: left;
        padding: 24px 16px; }
        .modal-listed-here .modal-content .modal-body .listed-here-card .listed-here-card-list li {
          margin: 0 0 0 16px;
          font-size: 14px;
          letter-spacing: -0.2px; }
        .modal-listed-here .modal-content .modal-body .listed-here-card .listed-here-card-list::before {
          width: 16px;
          height: 16px;
          border-top: solid 8px transparent;
          border-bottom: solid 8px #ffffff;
          border-right: solid 8px #ffffff;
          border-left: solid 8px transparent;
          position: absolute;
          top: -2px;
          left: 50%;
          -webkit-transform: rotate(45deg) translate(-50%, 0);
                  transform: rotate(45deg) translate(-50%, 0);
          content: ""; }

.form-company-detail {
  position: relative;
  width: 100%; }
  .form-company-detail .submit-button {
    width: unset;
    margin-top: 26px;
    margin-right: 8px; }
  .form-company-detail label {
    position: relative; }
    .form-company-detail label span {
      position: absolute;
      right: 0; }
  .form-company-detail input {
    width: 400px !important; }
  .form-company-detail textarea {
    width: 400px !important; }
  .form-company-detail .country-picker-wrapper {
    width: 400px; }
    .form-company-detail .country-picker-wrapper .country-picker-label {
      width: 300px; }
  .form-company-detail .main-custom-select-option-wrapper {
    width: 400px; }
    .form-company-detail .main-custom-select-option-wrapper button {
      margin: 0; }

.phone-input-plus {
  position: absolute;
  left: 16px;
  top: 12px; }
  .phone-input-plus.phone-input-plus-disabled {
    color: rgba(25, 25, 25, 0.4); }

.phone-waba-input {
  padding-left: 28px !important; }

.form-requester-info {
  position: relative;
  width: 100%; }
  .form-requester-info .submit-button {
    width: unset;
    margin-top: 26px;
    margin-right: 8px; }
  .form-requester-info input {
    width: 300px !important; }
  .form-requester-info textarea {
    width: 300px !important; }
  .form-requester-info .area-code-wrapper {
    min-width: 72px;
    margin: 0;
    border-radius: 8px;
    background-color: unset;
    border-radius: 8px; }
    .form-requester-info .area-code-wrapper .dropdown-country-code-picker {
      background-color: unset !important; }
      .form-requester-info .area-code-wrapper .dropdown-country-code-picker button {
        border: none !important;
        border-radius: 8px;
        background-color: unset !important; }
      .form-requester-info .area-code-wrapper .dropdown-country-code-picker .dropdown-menu {
        width: 300px; }
      .form-requester-info .area-code-wrapper .dropdown-country-code-picker .custom-phone-code-picker div {
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 200px;
        white-space: nowrap; }
  .form-requester-info .phone-input {
    width: 215px !important;
    border: none !important;
    padding-left: 0 !important;
    margin: 0 !important;
    border-radius: 8px; }
  .form-requester-info .phone-input-wrapper {
    border: solid 1px rgba(25, 25, 25, 0.1);
    border-radius: 8px; }
    .form-requester-info .phone-input-wrapper.phone-input-disabled {
      cursor: not-allowed;
      background-color: rgba(25, 25, 25, 0.1); }

.area-code-wrapper {
  position: relative;
  min-width: 142px;
  display: inline-block;
  margin-right: 8px;
  height: 48px; }
  .area-code-wrapper .dropdown-country-code-picker {
    position: relative;
    background: #ffffff !important; }
    .area-code-wrapper .dropdown-country-code-picker .value-country-code-wrapper {
      width: 100%;
      text-align: left;
      outline: none !important;
      border-radius: 8px;
      border: solid 1px #dcdcdc !important;
      background: #ffffff !important;
      height: 49px;
      color: #191919 !important;
      box-shadow: none !important;
      position: relative;
      padding: 6px 26px 6px 12px; }
      .area-code-wrapper .dropdown-country-code-picker .value-country-code-wrapper .area-code-placeholder {
        width: 35px;
        height: 24px;
        border: solid 1px rgba(25, 25, 25, 0.1);
        border-radius: 2px;
        text-align: center; }
        .area-code-wrapper .dropdown-country-code-picker .value-country-code-wrapper .area-code-placeholder svg {
          width: 18px;
          height: 18px;
          top: 15px;
          right: 35px; }
      .area-code-wrapper .dropdown-country-code-picker .value-country-code-wrapper .country-flag {
        width: 32px;
        height: 24px;
        display: inline-block;
        position: relative;
        margin-right: 6px;
        top: -1px; }
      .area-code-wrapper .dropdown-country-code-picker .value-country-code-wrapper svg {
        -webkit-transition: ease-in-out .1s;
        transition: ease-in-out .1s;
        position: absolute;
        top: 17px;
        right: 8px; }
        .area-code-wrapper .dropdown-country-code-picker .value-country-code-wrapper svg.chevron-up {
          -webkit-transform: rotate(180deg);
                  transform: rotate(180deg); }
    .area-code-wrapper .dropdown-country-code-picker .dropdown-menu {
      padding: 0;
      border-radius: 8px;
      box-shadow: 0px 8px 28px 0px rgba(25, 25, 25, 0.18) !important; }
      .area-code-wrapper .dropdown-country-code-picker .dropdown-menu .country-search-wrapper {
        position: relative;
        width: 100%; }
        .area-code-wrapper .dropdown-country-code-picker .dropdown-menu .country-search-wrapper input {
          border: none !important;
          height: 37px;
          padding: 12px 12px 12px 36px !important;
          font-size: 14px;
          letter-spacing: -0.2px;
          color: #191919;
          border-bottom: solid 1px #dcdcdc !important;
          border-radius: 8px 8px 0 0 !important; }
        .area-code-wrapper .dropdown-country-code-picker .dropdown-menu .country-search-wrapper svg {
          position: relative;
          color: #ff7d00;
          position: absolute;
          left: 12px;
          top: 11px; }
      .area-code-wrapper .dropdown-country-code-picker .dropdown-menu .country-list-select {
        width: 320px;
        min-height: 37px;
        max-height: 222px;
        overflow: auto; }
        .area-code-wrapper .dropdown-country-code-picker .dropdown-menu .country-list-select .custom-phone-code-picker {
          padding: 0 12px 0 40px;
          position: relative;
          height: 37px;
          line-height: 37px;
          cursor: pointer;
          color: #191919;
          font-size: 14px; }
          .area-code-wrapper .dropdown-country-code-picker .dropdown-menu .country-list-select .custom-phone-code-picker:nth-last-child(1) {
            border-bottom: none; }
          .area-code-wrapper .dropdown-country-code-picker .dropdown-menu .country-list-select .custom-phone-code-picker .country-name {
            margin: 0;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            max-width: calc(100% - 40px);
            height: 20px; }
          .area-code-wrapper .dropdown-country-code-picker .dropdown-menu .country-list-select .custom-phone-code-picker .country-code {
            color: rgba(25, 25, 25, 0.6);
            position: absolute;
            right: 0;
            top: 13px;
            font-size: 12px;
            letter-spacing: -0.2px; }
          .area-code-wrapper .dropdown-country-code-picker .dropdown-menu .country-list-select .custom-phone-code-picker img {
            width: 21px;
            height: 16px;
            display: inline-block;
            margin-right: 6px;
            position: absolute;
            left: 38px;
            top: 10px; }
          .area-code-wrapper .dropdown-country-code-picker .dropdown-menu .country-list-select .custom-phone-code-picker svg {
            margin-right: 6px;
            position: absolute;
            color: #ff7d00;
            font-size: 18px;
            top: 9px;
            left: 10px; }
          .area-code-wrapper .dropdown-country-code-picker .dropdown-menu .country-list-select .custom-phone-code-picker div {
            display: inline-block;
            font-size: 14px;
            letter-spacing: -0.2px;
            line-height: 1;
            position: relative;
            vertical-align: top;
            width: 100%;
            padding: 11px 0 0 28px; }
            .area-code-wrapper .dropdown-country-code-picker .dropdown-menu .country-list-select .custom-phone-code-picker div .country-name {
              top: 0 !important; }
          .area-code-wrapper .dropdown-country-code-picker .dropdown-menu .country-list-select .custom-phone-code-picker:hover {
            color: #ff7d00; }
          .area-code-wrapper .dropdown-country-code-picker .dropdown-menu .country-list-select .custom-phone-code-picker.active-code-number {
            color: #ff7d00; }
        .area-code-wrapper .dropdown-country-code-picker .dropdown-menu .country-list-select.no-option {
          text-align: center;
          border-top: solid 1px #dcdcdc;
          color: grey;
          padding-top: 5px; }

.integration-whatsapp-business-api-detail-account {
  padding: 32px 30px;
  text-align: left; }
  .integration-whatsapp-business-api-detail-account .comp-back-button {
    margin: 0 0 24px 0; }
  .integration-whatsapp-business-api-detail-account .nav-item {
    cursor: pointer;
    color: rgba(25, 25, 25, 0.4);
    font-size: 14px;
    letter-spacing: -0.5px;
    padding: 0 0 12px 0;
    margin-right: 18px; }
    .integration-whatsapp-business-api-detail-account .nav-item a {
      padding: 0;
      border: none; }
    .integration-whatsapp-business-api-detail-account .nav-item :hover {
      color: #ff7d00; }
    .integration-whatsapp-business-api-detail-account .nav-item.active-setup-tab {
      color: #ff7d00;
      border-bottom: solid 4px #ff7d00; }
  .integration-whatsapp-business-api-detail-account .tab-content {
    margin: 32px 0 0; }

.integration-whatsapp-ba-detail-account {
  text-align: left; }
  .integration-whatsapp-ba-detail-account .topic-val {
    max-width: 320px;
    color: #191919 !important;
    margin: 6px 0 16px 0 !important;
    font-size: 16px !important; }
  .integration-whatsapp-ba-detail-account .comp-back-button {
    margin: 0 0 16px 0; }
  .integration-whatsapp-ba-detail-account .integration-whatsapp-ba-detail-account-box-1 {
    background: #ffffff;
    border-radius: 8px;
    padding: 22px 32px 32px;
    border: solid 1px rgba(25, 25, 25, 0.1);
    position: relative; }
    .integration-whatsapp-ba-detail-account .integration-whatsapp-ba-detail-account-box-1 .integration-whatsapp-ba-detail-account-box-1-top {
      padding: 10px 0 15px;
      border-bottom: solid 1px #dcdcdc;
      position: relative;
      font-size: 20px;
      letter-spacing: -0.6px;
      padding-right: 230px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis; }
      .integration-whatsapp-ba-detail-account .integration-whatsapp-ba-detail-account-box-1 .integration-whatsapp-ba-detail-account-box-1-top .bot-name {
        margin-bottom: 0; }
      .integration-whatsapp-ba-detail-account .integration-whatsapp-ba-detail-account-box-1 .integration-whatsapp-ba-detail-account-box-1-top .integration-whatsapp-ba-detail-account-box-1-top-button {
        position: absolute;
        top: 0px;
        right: 0;
        display: inline-block; }
        .integration-whatsapp-ba-detail-account .integration-whatsapp-ba-detail-account-box-1 .integration-whatsapp-ba-detail-account-box-1-top .integration-whatsapp-ba-detail-account-box-1-top-button button {
          padding: 7px 24px;
          font-size: 16px;
          letter-spacing: -0.6px;
          height: 40px;
          margin-left: 8px; }
          .integration-whatsapp-ba-detail-account .integration-whatsapp-ba-detail-account-box-1 .integration-whatsapp-ba-detail-account-box-1-top .integration-whatsapp-ba-detail-account-box-1-top-button button.button-channel-detail {
            position: relative;
            top: 15px; }
          .integration-whatsapp-ba-detail-account .integration-whatsapp-ba-detail-account-box-1 .integration-whatsapp-ba-detail-account-box-1-top .integration-whatsapp-ba-detail-account-box-1-top-button button svg {
            position: relative;
            top: -2px;
            margin-right: 4px; }
          .integration-whatsapp-ba-detail-account .integration-whatsapp-ba-detail-account-box-1 .integration-whatsapp-ba-detail-account-box-1-top .integration-whatsapp-ba-detail-account-box-1-top-button button .lds-ring {
            top: unset;
            right: unset;
            position: relative;
            top: 3px; }
            .integration-whatsapp-ba-detail-account .integration-whatsapp-ba-detail-account-box-1 .integration-whatsapp-ba-detail-account-box-1-top .integration-whatsapp-ba-detail-account-box-1-top-button button .lds-ring div {
              margin: 0;
              border-color: #ffffff transparent transparent transparent; }
    .integration-whatsapp-ba-detail-account .integration-whatsapp-ba-detail-account-box-1 .integration-whatsapp-ba-detail-account-box-1-bottom {
      position: relative;
      font-size: 20px;
      letter-spacing: -0.6px; }
      .integration-whatsapp-ba-detail-account .integration-whatsapp-ba-detail-account-box-1 .integration-whatsapp-ba-detail-account-box-1-bottom .custom-select-phonenumber {
        width: 316px; }
        .integration-whatsapp-ba-detail-account .integration-whatsapp-ba-detail-account-box-1 .integration-whatsapp-ba-detail-account-box-1-bottom .custom-select-phonenumber button {
          margin-top: 8px; }
      .integration-whatsapp-ba-detail-account .integration-whatsapp-ba-detail-account-box-1 .integration-whatsapp-ba-detail-account-box-1-bottom label {
        font-size: 14px;
        letter-spacing: -0.4px;
        margin: 24px 0 0 0; }
      .integration-whatsapp-ba-detail-account .integration-whatsapp-ba-detail-account-box-1 .integration-whatsapp-ba-detail-account-box-1-bottom p {
        margin: 4px 0 0;
        font-size: 15px;
        letter-spacing: -0.5px; }
        .integration-whatsapp-ba-detail-account .integration-whatsapp-ba-detail-account-box-1 .integration-whatsapp-ba-detail-account-box-1-bottom p.value-detail {
          font-size: 20px;
          letter-spacing: -0.6px; }
          .integration-whatsapp-ba-detail-account .integration-whatsapp-ba-detail-account-box-1 .integration-whatsapp-ba-detail-account-box-1-bottom p.value-detail img {
            position: relative;
            top: -2px;
            margin-right: 4px; }
        .integration-whatsapp-ba-detail-account .integration-whatsapp-ba-detail-account-box-1 .integration-whatsapp-ba-detail-account-box-1-bottom p.value-detail-status {
          font-size: 16px;
          letter-spacing: -0.5px; }
        .integration-whatsapp-ba-detail-account .integration-whatsapp-ba-detail-account-box-1 .integration-whatsapp-ba-detail-account-box-1-bottom p.value-active-green {
          color: #19c700; }
        .integration-whatsapp-ba-detail-account .integration-whatsapp-ba-detail-account-box-1 .integration-whatsapp-ba-detail-account-box-1-bottom p.value-active-orange {
          color: #ff7e00; }
        .integration-whatsapp-ba-detail-account .integration-whatsapp-ba-detail-account-box-1 .integration-whatsapp-ba-detail-account-box-1-bottom p.value-active-red {
          color: #ff3f57; }
        .integration-whatsapp-ba-detail-account .integration-whatsapp-ba-detail-account-box-1 .integration-whatsapp-ba-detail-account-box-1-bottom p svg {
          margin-right: 4px;
          position: relative;
          top: -2px;
          font-size: 20px; }
      .integration-whatsapp-ba-detail-account .integration-whatsapp-ba-detail-account-box-1 .integration-whatsapp-ba-detail-account-box-1-bottom .integration-whatsapp-ba-detail-account-box-1-bottom-button {
        display: block; }
        .integration-whatsapp-ba-detail-account .integration-whatsapp-ba-detail-account-box-1 .integration-whatsapp-ba-detail-account-box-1-bottom .integration-whatsapp-ba-detail-account-box-1-bottom-button button {
          padding: 7px 24px 7px 20px;
          font-size: 16px;
          letter-spacing: -0.6px;
          height: 40px;
          margin-right: 8px;
          margin-top: 16px; }
          .integration-whatsapp-ba-detail-account .integration-whatsapp-ba-detail-account-box-1 .integration-whatsapp-ba-detail-account-box-1-bottom .integration-whatsapp-ba-detail-account-box-1-bottom-button button svg {
            position: relative;
            top: -2px;
            margin-right: 4px; }
          .integration-whatsapp-ba-detail-account .integration-whatsapp-ba-detail-account-box-1 .integration-whatsapp-ba-detail-account-box-1-bottom .integration-whatsapp-ba-detail-account-box-1-bottom-button button img {
            position: relative;
            top: -2px;
            margin-right: 4px; }
          .integration-whatsapp-ba-detail-account .integration-whatsapp-ba-detail-account-box-1 .integration-whatsapp-ba-detail-account-box-1-bottom .integration-whatsapp-ba-detail-account-box-1-bottom-button button .lds-ring {
            top: unset;
            right: unset;
            position: relative;
            top: 3px; }
            .integration-whatsapp-ba-detail-account .integration-whatsapp-ba-detail-account-box-1 .integration-whatsapp-ba-detail-account-box-1-bottom .integration-whatsapp-ba-detail-account-box-1-bottom-button button .lds-ring div {
              margin: 0;
              border-color: #ffffff transparent transparent transparent; }
  .integration-whatsapp-ba-detail-account .integration-whatsapp-ba-detail-account-box-2 {
    background: #ffffff;
    border-radius: 8px;
    padding: 32px;
    border: solid 1px rgba(25, 25, 25, 0.1);
    margin-top: 12px; }
    .integration-whatsapp-ba-detail-account .integration-whatsapp-ba-detail-account-box-2 .integration-whatsapp-ba-detail-account-box-2-title {
      font-size: 20px;
      letter-spacing: -0.6px;
      padding-bottom: 20px;
      border-bottom: solid 1px #dcdcdc;
      display: block;
      margin: 0 0 24px; }
    .integration-whatsapp-ba-detail-account .integration-whatsapp-ba-detail-account-box-2 .integration-whatsapp-ba-detail-account-box-2-payment-wrapper {
      width: 100%;
      position: relative; }
      .integration-whatsapp-ba-detail-account .integration-whatsapp-ba-detail-account-box-2 .integration-whatsapp-ba-detail-account-box-2-payment-wrapper label {
        font-size: 14px;
        letter-spacing: -0.4px;
        margin: 0; }
      .integration-whatsapp-ba-detail-account .integration-whatsapp-ba-detail-account-box-2 .integration-whatsapp-ba-detail-account-box-2-payment-wrapper .payment-info-wrapper {
        position: relative; }
        .integration-whatsapp-ba-detail-account .integration-whatsapp-ba-detail-account-box-2 .integration-whatsapp-ba-detail-account-box-2-payment-wrapper .payment-info-wrapper .payment-until {
          font-size: 15px;
          letter-spacing: -0.5px;
          color: #19c700;
          margin: 0; }
          .integration-whatsapp-ba-detail-account .integration-whatsapp-ba-detail-account-box-2 .integration-whatsapp-ba-detail-account-box-2-payment-wrapper .payment-info-wrapper .payment-until img {
            vertical-align: top;
            position: relative;
            top: 0;
            margin-right: 4px; }
        .integration-whatsapp-ba-detail-account .integration-whatsapp-ba-detail-account-box-2 .integration-whatsapp-ba-detail-account-box-2-payment-wrapper .payment-info-wrapper .payment-expired {
          color: #ff3f57;
          font-size: 15px;
          letter-spacing: -0.5px;
          margin: 4px 0 0; }
          .integration-whatsapp-ba-detail-account .integration-whatsapp-ba-detail-account-box-2 .integration-whatsapp-ba-detail-account-box-2-payment-wrapper .payment-info-wrapper .payment-expired svg {
            vertical-align: top;
            position: relative;
            font-size: 20px;
            margin-right: 4px; }
        .integration-whatsapp-ba-detail-account .integration-whatsapp-ba-detail-account-box-2 .integration-whatsapp-ba-detail-account-box-2-payment-wrapper .payment-info-wrapper .payment-bar {
          width: 326px;
          height: 4px;
          background: rgba(25, 25, 25, 0.1);
          border-radius: 2px;
          margin: 8px 0;
          position: relative; }
          .integration-whatsapp-ba-detail-account .integration-whatsapp-ba-detail-account-box-2 .integration-whatsapp-ba-detail-account-box-2-payment-wrapper .payment-info-wrapper .payment-bar div {
            position: absolute;
            left: 0;
            top: 0;
            background: #3baeff;
            height: 4px; }
        .integration-whatsapp-ba-detail-account .integration-whatsapp-ba-detail-account-box-2 .integration-whatsapp-ba-detail-account-box-2-payment-wrapper .payment-info-wrapper .payment-left {
          font-size: 12px;
          letter-spacing: -0.2px;
          color: rgba(25, 25, 25, 0.4); }
        .integration-whatsapp-ba-detail-account .integration-whatsapp-ba-detail-account-box-2 .integration-whatsapp-ba-detail-account-box-2-payment-wrapper .payment-info-wrapper .not-paid {
          color: rgba(25, 25, 25, 0.4);
          font-size: 16px;
          letter-spacing: -0.5px;
          margin: 0 0 22px; }
      .integration-whatsapp-ba-detail-account .integration-whatsapp-ba-detail-account-box-2 .integration-whatsapp-ba-detail-account-box-2-payment-wrapper .alet-verifying {
        border-radius: 8px;
        color: #044b84;
        border: solid 1px #044b84;
        background: #dcebff;
        width: 100%;
        position: relative;
        padding: 12px 16px 12px 38px;
        font-size: 14px;
        letter-spacing: -0.4px;
        width: 323px; }
        .integration-whatsapp-ba-detail-account .integration-whatsapp-ba-detail-account-box-2 .integration-whatsapp-ba-detail-account-box-2-payment-wrapper .alet-verifying svg {
          font-size: 15px;
          -webkit-transform: none;
                  transform: none;
          top: 15px; }

.integration-whatsapp-ba-detail-account-box-1 {
  background: #ffffff;
  border-radius: 8px;
  padding: 22px 32px 32px;
  border: solid 1px rgba(25, 25, 25, 0.1); }
  .integration-whatsapp-ba-detail-account-box-1 p {
    font-size: 14px;
    letter-spacing: -0.2px; }
  .integration-whatsapp-ba-detail-account-box-1.box-channel-button {
    margin-top: 24px; }
  .integration-whatsapp-ba-detail-account-box-1 .whatsapp-detail-channel-button .channel-button-wrapper {
    margin-top: 24px;
    width: 300px;
    word-break: break-word; }
  .integration-whatsapp-ba-detail-account-box-1 .status-wrapper .status-value {
    color: rgba(25, 25, 25, 0.4); }
    .integration-whatsapp-ba-detail-account-box-1 .status-wrapper .status-value.status-enabled {
      color: #19C700; }
  .integration-whatsapp-ba-detail-account-box-1 .status-wrapper img {
    position: relative;
    top: -1px;
    margin-right: 4px; }

.account-profile-whatsapp {
  position: relative;
  width: 100%; }
  .account-profile-whatsapp .account-profile-header {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid rgba(25, 25, 25, 0.1);
    margin-bottom: 24px;
    padding-bottom: 12px; }
    .account-profile-whatsapp .account-profile-header .title-wrapper b {
      font-size: 20px; }
    .account-profile-whatsapp .account-profile-header .title-wrapper p {
      font-size: 16px;
      margin: 0; }
  .account-profile-whatsapp .account-profile-content .custom-select-phonenumber {
    width: 340px;
    max-width: 100%; }
  .account-profile-whatsapp .account-profile-content .account-profile-content-wrapper {
    border-top: solid 1px #dcdcdc;
    margin-top: 12px;
    padding-top: 24px;
    margin-bottom: 24px;
    position: relative; }
    .account-profile-whatsapp .account-profile-content .account-profile-content-wrapper:last-child {
      margin-bottom: 0; }
    .account-profile-whatsapp .account-profile-content .account-profile-content-wrapper .label-wrapper {
      display: flex;
      align-items: center;
      margin-bottom: 8px; }
      .account-profile-whatsapp .account-profile-content .account-profile-content-wrapper .label-wrapper svg {
        margin-right: 4px;
        position: relative;
        top: -1px; }
      .account-profile-whatsapp .account-profile-content .account-profile-content-wrapper .label-wrapper label {
        margin: 0;
        font-size: 14px; }
    .account-profile-whatsapp .account-profile-content .account-profile-content-wrapper .photo-wrapper {
      margin-bottom: 8px;
      border: 1px solid rgba(25, 25, 25, 0.1);
      border-radius: 4px;
      width: 96px;
      height: 96px; }
      .account-profile-whatsapp .account-profile-content .account-profile-content-wrapper .photo-wrapper img {
        border-radius: 4px;
        width: 96px;
        height: 96px; }
      .account-profile-whatsapp .account-profile-content .account-profile-content-wrapper .photo-wrapper .profile-photo-empty {
        height: 94px;
        background: rgba(25, 25, 25, 0.05);
        position: relative; }
        .account-profile-whatsapp .account-profile-content .account-profile-content-wrapper .photo-wrapper .profile-photo-empty svg {
          height: 32px;
          width: 32px;
          color: #9c9c9c;
          position: absolute;
          left: 50%;
          top: 50%;
          -webkit-transform: translate(-50%, -50%);
                  transform: translate(-50%, -50%); }
    .account-profile-whatsapp .account-profile-content .account-profile-content-wrapper .info-wrapper {
      display: flex; }
      .account-profile-whatsapp .account-profile-content .account-profile-content-wrapper .info-wrapper b {
        font-size: 12px; }
      .account-profile-whatsapp .account-profile-content .account-profile-content-wrapper .info-wrapper svg {
        font-size: 16px;
        margin-right: 4px; }
    .account-profile-whatsapp .account-profile-content .account-profile-content-wrapper .text-wrapper p {
      margin: 0; }
    .account-profile-whatsapp .account-profile-content .account-profile-content-wrapper .button-wrapper {
      position: absolute;
      right: 0;
      top: 50%;
      -webkit-transform: translateY(-50%);
              transform: translateY(-50%); }
      .account-profile-whatsapp .account-profile-content .account-profile-content-wrapper .button-wrapper > div {
        display: flex; }
        .account-profile-whatsapp .account-profile-content .account-profile-content-wrapper .button-wrapper > div label {
          margin-right: 8px; }
      .account-profile-whatsapp .account-profile-content .account-profile-content-wrapper .button-wrapper .LoadingRoll_1234fed {
        vertical-align: 6px;
        margin: 0 15px 0 auto !important;
        height: unset !important; }
      .account-profile-whatsapp .account-profile-content .account-profile-content-wrapper .button-wrapper label {
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
        cursor: pointer;
        padding: 0 24px 0 16px !important; }
        .account-profile-whatsapp .account-profile-content .account-profile-content-wrapper .button-wrapper label input {
          width: 100%; }
        .account-profile-whatsapp .account-profile-content .account-profile-content-wrapper .button-wrapper label div {
          display: inline-block; }
        .account-profile-whatsapp .account-profile-content .account-profile-content-wrapper .button-wrapper label.disabled {
          color: rgba(25, 25, 25, 0.4) !important;
          border: solid 1px #E8E8E8 !important;
          background: rgba(25, 25, 25, 0.1) !important;
          cursor: not-allowed;
          box-shadow: none !important; }
          .account-profile-whatsapp .account-profile-content .account-profile-content-wrapper .button-wrapper label.disabled input {
            display: none; }

.modal-remove-photo .modal-content {
  border-radius: 8px;
  padding: 32px;
  width: 480px; }

.modal-remove-photo .modal-body {
  padding: 0;
  line-height: 1; }
  .modal-remove-photo .modal-body p {
    margin: 0; }
  .modal-remove-photo .modal-body .modal-remove-photo-title {
    font-size: 20px;
    letter-spacing: -0.6px; }
  .modal-remove-photo .modal-body input {
    width: 100%; }

.modal-remove-photo .modal-footer {
  padding: 24px 0 0;
  border: 0; }
  .modal-remove-photo .modal-footer button {
    width: calc(50% - 4px);
    margin: 0 8px 0 0;
    height: 48px; }
    .modal-remove-photo .modal-footer button:nth-last-child(1) {
      margin: 0; }

.business-profile-whatsapp {
  position: relative;
  width: 100%; }
  .business-profile-whatsapp .business-profile-header {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid rgba(25, 25, 25, 0.1);
    margin-bottom: 24px;
    padding-bottom: 12px; }
    .business-profile-whatsapp .business-profile-header .title-wrapper b {
      font-size: 20px; }
    .business-profile-whatsapp .business-profile-header .title-wrapper p {
      font-size: 16px;
      margin: 0; }
  .business-profile-whatsapp .business-profile-content .phone-number-wrapper {
    border-bottom: solid 1px #dcdcdc;
    margin-bottom: 24px;
    padding-bottom: 12px; }
    .business-profile-whatsapp .business-profile-content .phone-number-wrapper .custom-select-phonenumber {
      max-width: 100%;
      width: 340px; }
  .business-profile-whatsapp .business-profile-content .business-profile-content-wrapper {
    margin-bottom: 24px;
    max-width: 400px; }
    .business-profile-whatsapp .business-profile-content .business-profile-content-wrapper:last-child {
      margin-bottom: 0; }
    .business-profile-whatsapp .business-profile-content .business-profile-content-wrapper .label-wrapper {
      display: flex;
      align-items: center;
      margin-bottom: 8px; }
      .business-profile-whatsapp .business-profile-content .business-profile-content-wrapper .label-wrapper svg {
        margin-right: 4px;
        position: relative;
        top: 0px; }
      .business-profile-whatsapp .business-profile-content .business-profile-content-wrapper .label-wrapper img {
        margin-right: 4px; }
      .business-profile-whatsapp .business-profile-content .business-profile-content-wrapper .label-wrapper label {
        margin: 0;
        font-size: 14px; }
    .business-profile-whatsapp .business-profile-content .business-profile-content-wrapper .photo-wrapper {
      margin-bottom: 8px; }
    .business-profile-whatsapp .business-profile-content .business-profile-content-wrapper .info-wrapper {
      display: flex;
      align-items: center; }
      .business-profile-whatsapp .business-profile-content .business-profile-content-wrapper .info-wrapper b {
        font-size: 12px; }
      .business-profile-whatsapp .business-profile-content .business-profile-content-wrapper .info-wrapper svg {
        font-size: 16px;
        margin-right: 4px; }
    .business-profile-whatsapp .business-profile-content .business-profile-content-wrapper .text-wrapper p {
      margin: 0;
      word-break: break-word; }
    .business-profile-whatsapp .business-profile-content .business-profile-content-wrapper .text-wrapper.web-link {
      margin-bottom: 8px; }

.message-template--whatsapp {
  position: relative;
  width: 100%; }

.message-template-whatsapp .message-template-whatsapp-top {
  padding: 0px 0 28px;
  border-bottom: solid 1px #dcdcdc;
  font-size: 20px;
  letter-spacing: -0.6px;
  font-weight: 700; }

.modal-view-message .modal-content {
  padding: 32px;
  width: 477px; }

.modal-view-message .modal-body {
  padding: 0; }
  .modal-view-message .modal-body .header-modal {
    margin-bottom: 24px; }
    .modal-view-message .modal-body .header-modal b {
      font-size: 20px; }
    .modal-view-message .modal-body .header-modal svg {
      margin-right: 4px;
      position: relative;
      top: -3px; }
  .modal-view-message .modal-body .message-container label {
    font-size: 14px; }
  .modal-view-message .modal-body .message-container input {
    display: flex;
    align-items: center;
    background: white;
    border-radius: 8px;
    border: 1px solid rgba(25, 25, 25, 0.1);
    height: 48px;
    width: 100%;
    color: #191919;
    font-size: 16px;
    letter-spacing: -0.3px;
    max-width: 100%;
    padding: 0 16px;
    margin-bottom: 24px; }
    .modal-view-message .modal-body .message-container input:disabled {
      background: rgba(25, 25, 25, 0.05);
      cursor: not-allowed; }
  .modal-view-message .modal-body .message-container textarea {
    background: white;
    border-radius: 8px;
    border: 1px solid rgba(25, 25, 25, 0.1);
    min-height: 48px;
    width: 100%;
    color: #191919;
    font-size: 16px;
    letter-spacing: -0.3px;
    max-width: 100%;
    padding: 12px 16px;
    line-height: 24px;
    resize: none;
    margin-bottom: 20px; }
    .modal-view-message .modal-body .message-container textarea:disabled {
      background: rgba(25, 25, 25, 0.05);
      cursor: not-allowed; }

.table-message-template-list {
  table-layout: fixed; }
  .table-message-template-list thead tr {
    background: transparent; }
    .table-message-template-list thead tr td {
      padding: 8px;
      width: 20%;
      font-size: 14px;
      word-wrap: break-word;
      white-space: normal;
      position: relative; }
      .table-message-template-list thead tr td .question-circle-wrapper {
        margin-left: 5px;
        position: absolute;
        top: 25%; }
      .table-message-template-list thead tr td:first-child {
        width: 200px; }
      .table-message-template-list thead tr td:nth-child(2) {
        width: 115px; }
      .table-message-template-list thead tr td:nth-child(3) {
        width: 115px; }
        .table-message-template-list thead tr td:nth-child(3) img {
          margin-left: 5px;
          vertical-align: -1.5px; }
      .table-message-template-list thead tr td:last-child {
        text-align: right; }
        .table-message-template-list thead tr td:last-child .custom-select-option-value-wrapper {
          width: 55px !important;
          height: 23px;
          padding: 2px 8px 2px 12px !important;
          border-radius: 4px !important;
          visibility: hidden; }
          .table-message-template-list thead tr td:last-child .custom-select-option-value-wrapper p {
            font-size: 12px;
            font-weight: bold; }
          .table-message-template-list thead tr td:last-child .custom-select-option-value-wrapper .custom-select-option-value {
            padding: 0; }
          .table-message-template-list thead tr td:last-child .custom-select-option-value-wrapper svg {
            right: 3px;
            height: 15px;
            top: 11px; }
  .table-message-template-list tbody tr {
    border-bottom: none; }
    .table-message-template-list tbody tr:hover {
      background-color: unset; }
      .table-message-template-list tbody tr:hover td {
        color: unset; }
    .table-message-template-list tbody tr td:not(.no-data-column) {
      padding: 8px 10px; }
      .table-message-template-list tbody tr td:not(.no-data-column) p {
        margin-bottom: 0; }
    .table-message-template-list tbody tr td {
      background: transparent;
      position: relative;
      word-wrap: break-word;
      vertical-align: top; }
      .table-message-template-list tbody tr td .unavailable {
        color: rgba(25, 25, 25, 0.4); }
      .table-message-template-list tbody tr td .link-user {
        color: inherit;
        text-decoration: inherit; }
      .table-message-template-list tbody tr td:nth-child(2) p {
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical; }
      .table-message-template-list tbody tr td:nth-child(3) .custom-switch-wrapper {
        width: 40px;
        top: 2px; }
        .table-message-template-list tbody tr td:nth-child(3) .custom-switch-wrapper input:checked + .custom-switch:before {
          -webkit-transform: translateX(19px);
          transform: translateX(19px); }
        .table-message-template-list tbody tr td:nth-child(3) .custom-switch-wrapper .custom-switch {
          width: 16px;
          height: 16px; }
        .table-message-template-list tbody tr td:nth-child(3) .custom-switch-wrapper .custom-switch:before {
          left: 4px;
          bottom: 3.8px; }
        .table-message-template-list tbody tr td:nth-child(3) .custom-switch-wrapper .custom-switch.round:before {
          width: 10px;
          height: 10px; }
      .table-message-template-list tbody tr td.col-view {
        color: #ff7e00;
        cursor: pointer;
        font-weight: 700;
        text-align: right; }
        .table-message-template-list tbody tr td.col-view a {
          color: #ff7e00; }
        .table-message-template-list tbody tr td.col-view svg {
          position: relative;
          color: #ff7e00;
          font-size: 16px;
          margin-right: 4px;
          right: 0;
          top: -2px; }
  .table-message-template-list #pagination-wrapper {
    visibility: hidden; }
    .table-message-template-list #pagination-wrapper ul li {
      font-size: 12px !important;
      position: relative !important; }
      .table-message-template-list #pagination-wrapper ul li:not(.pagination-button) {
        top: 6px !important; }
      .table-message-template-list #pagination-wrapper ul li span {
        position: absolute;
        top: -42px;
        display: inline-block;
        left: 50%;
        -webkit-transform: translate(-50%, 0);
                transform: translate(-50%, 0); }
      .table-message-template-list #pagination-wrapper ul li.active {
        width: 30px !important;
        height: 30px !important; }
        .table-message-template-list #pagination-wrapper ul li.active span {
          top: -40px !important; }
      .table-message-template-list #pagination-wrapper ul li.pagination-label {
        top: 5px !important; }
        .table-message-template-list #pagination-wrapper ul li.pagination-label svg {
          height: 12px; }

.request-message-template-whatsapp {
  position: relative;
  width: 100%; }

.request-message-template-whatsapp .request-message-template-whatsapp-top {
  padding: 0px 0 28px;
  border-bottom: solid 1px #dcdcdc;
  font-size: 20px;
  letter-spacing: -0.6px;
  font-weight: 700; }
  .request-message-template-whatsapp .request-message-template-whatsapp-top .request-message-template-whatsapp-top-button {
    position: absolute;
    right: 35px;
    top: 35px;
    font-size: 16px;
    letter-spacing: -0.5px;
    padding: 7px 32px; }
    .request-message-template-whatsapp .request-message-template-whatsapp-top .request-message-template-whatsapp-top-button img {
      margin-right: 4px;
      position: relative;
      top: -2px; }

.modal-view-message .modal-content {
  padding: 32px;
  width: 477px; }

.modal-view-message .modal-body {
  padding: 0; }
  .modal-view-message .modal-body .header-modal {
    margin-bottom: 24px; }
    .modal-view-message .modal-body .header-modal b {
      font-size: 20px; }
    .modal-view-message .modal-body .header-modal svg {
      margin-right: 4px;
      position: relative;
      top: -3px; }
  .modal-view-message .modal-body .message-container label {
    font-size: 14px; }
  .modal-view-message .modal-body .message-container input {
    display: flex;
    align-items: center;
    background: white;
    border-radius: 8px;
    border: 1px solid rgba(25, 25, 25, 0.1);
    height: 48px;
    width: 100%;
    color: #191919;
    font-size: 16px;
    letter-spacing: -0.3px;
    max-width: 100%;
    padding: 0 16px;
    margin-bottom: 24px; }
    .modal-view-message .modal-body .message-container input:disabled {
      background: rgba(25, 25, 25, 0.05);
      cursor: not-allowed; }
  .modal-view-message .modal-body .message-container textarea {
    background: white;
    border-radius: 8px;
    border: 1px solid rgba(25, 25, 25, 0.1);
    min-height: 48px;
    width: 100%;
    color: #191919;
    font-size: 16px;
    letter-spacing: -0.3px;
    max-width: 100%;
    padding: 12px 16px;
    line-height: 24px;
    resize: none;
    margin-bottom: 20px; }
    .modal-view-message .modal-body .message-container textarea:disabled {
      background: rgba(25, 25, 25, 0.05);
      cursor: not-allowed; }

.table-request-message-template-list {
  table-layout: fixed; }
  .table-request-message-template-list thead tr {
    background: transparent; }
    .table-request-message-template-list thead tr td {
      padding: 8px;
      width: 20%;
      font-size: 14px;
      word-wrap: break-word;
      white-space: normal;
      position: relative; }
      .table-request-message-template-list thead tr td .question-circle-wrapper {
        margin-left: 5px;
        position: absolute;
        top: 25%; }
      .table-request-message-template-list thead tr td:first-child {
        width: 200px; }
      .table-request-message-template-list thead tr td:nth-child(2) {
        width: 300px; }
      .table-request-message-template-list thead tr td:nth-child(3) {
        width: 200px; }
        .table-request-message-template-list thead tr td:nth-child(3) img {
          margin-left: 5px;
          vertical-align: -1.5px; }
      .table-request-message-template-list thead tr td:last-child {
        width: 100%;
        text-align: right; }
        .table-request-message-template-list thead tr td:last-child .custom-select-option-value-wrapper {
          width: 55px !important;
          height: 23px;
          padding: 2px 8px 2px 12px !important;
          border-radius: 4px !important;
          visibility: hidden; }
          .table-request-message-template-list thead tr td:last-child .custom-select-option-value-wrapper p {
            font-size: 12px;
            font-weight: bold; }
          .table-request-message-template-list thead tr td:last-child .custom-select-option-value-wrapper .custom-select-option-value {
            padding: 0; }
          .table-request-message-template-list thead tr td:last-child .custom-select-option-value-wrapper svg {
            right: 3px;
            height: 15px;
            top: 11px; }
  .table-request-message-template-list tbody tr {
    border-bottom: none; }
    .table-request-message-template-list tbody tr:hover {
      background-color: unset; }
      .table-request-message-template-list tbody tr:hover td {
        color: unset; }
    .table-request-message-template-list tbody tr td:not(.no-data-column) {
      padding: 8px 10px; }
      .table-request-message-template-list tbody tr td:not(.no-data-column) p {
        margin-bottom: 0; }
    .table-request-message-template-list tbody tr td {
      background: transparent;
      position: relative;
      word-wrap: break-word;
      vertical-align: top; }
      .table-request-message-template-list tbody tr td .unavailable {
        color: rgba(25, 25, 25, 0.4); }
      .table-request-message-template-list tbody tr td .link-user {
        color: inherit;
        text-decoration: inherit; }
      .table-request-message-template-list tbody tr td:nth-child(2) p {
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical; }
      .table-request-message-template-list tbody tr td.col-view {
        color: #ff7e00;
        cursor: pointer;
        font-weight: 700;
        text-align: right; }
        .table-request-message-template-list tbody tr td.col-view a {
          color: #ff7e00; }
        .table-request-message-template-list tbody tr td.col-view svg {
          position: relative;
          color: #ff7e00;
          font-size: 16px;
          margin-right: 4px;
          right: 0;
          top: -1px; }
  .table-request-message-template-list #pagination-wrapper {
    visibility: hidden; }
    .table-request-message-template-list #pagination-wrapper ul li {
      font-size: 12px !important;
      position: relative !important; }
      .table-request-message-template-list #pagination-wrapper ul li:not(.pagination-button) {
        top: 6px !important; }
      .table-request-message-template-list #pagination-wrapper ul li span {
        position: absolute;
        top: -42px;
        display: inline-block;
        left: 50%;
        -webkit-transform: translate(-50%, 0);
                transform: translate(-50%, 0); }
      .table-request-message-template-list #pagination-wrapper ul li.active {
        width: 30px !important;
        height: 30px !important; }
        .table-request-message-template-list #pagination-wrapper ul li.active span {
          top: -40px !important; }
      .table-request-message-template-list #pagination-wrapper ul li.pagination-label {
        top: 5px !important; }
        .table-request-message-template-list #pagination-wrapper ul li.pagination-label svg {
          height: 12px; }

.phone-number--whatsapp {
  position: relative;
  width: 100%; }

.phone-number-whatsapp .phone-number-whatsapp-top {
  padding: 0px 0 28px;
  border-bottom: solid 1px #dcdcdc;
  font-size: 20px;
  letter-spacing: -0.6px;
  font-weight: 700; }

.modal-view-message .modal-content {
  padding: 32px;
  width: 477px; }

.modal-view-message .modal-body {
  padding: 0; }
  .modal-view-message .modal-body .header-modal {
    margin-bottom: 24px; }
    .modal-view-message .modal-body .header-modal b {
      font-size: 20px; }
    .modal-view-message .modal-body .header-modal svg {
      margin-right: 4px;
      position: relative;
      top: -3px; }
  .modal-view-message .modal-body .message-container label {
    font-size: 14px; }
  .modal-view-message .modal-body .message-container input {
    display: flex;
    align-items: center;
    background: white;
    border-radius: 8px;
    border: 1px solid rgba(25, 25, 25, 0.1);
    height: 48px;
    width: 100%;
    color: #191919;
    font-size: 16px;
    letter-spacing: -0.3px;
    max-width: 100%;
    padding: 0 16px;
    margin-bottom: 24px; }
    .modal-view-message .modal-body .message-container input:disabled {
      background: rgba(25, 25, 25, 0.05);
      cursor: not-allowed; }
  .modal-view-message .modal-body .message-container textarea {
    background: white;
    border-radius: 8px;
    border: 1px solid rgba(25, 25, 25, 0.1);
    min-height: 48px;
    width: 100%;
    color: #191919;
    font-size: 16px;
    letter-spacing: -0.3px;
    max-width: 100%;
    padding: 12px 16px;
    line-height: 24px;
    resize: none;
    margin-bottom: 20px; }
    .modal-view-message .modal-body .message-container textarea:disabled {
      background: rgba(25, 25, 25, 0.05);
      cursor: not-allowed; }

.table-phone-number-list {
  table-layout: fixed; }
  .table-phone-number-list thead tr {
    background: transparent; }
    .table-phone-number-list thead tr td {
      padding: 8px;
      width: calc(100% / 3);
      font-size: 14px;
      word-wrap: break-word;
      white-space: normal;
      position: relative; }
      .table-phone-number-list thead tr td .question-circle-wrapper {
        margin-left: 5px;
        position: absolute;
        top: 25%; }
  .table-phone-number-list tbody tr {
    border-bottom: none; }
    .table-phone-number-list tbody tr:hover {
      background-color: unset; }
      .table-phone-number-list tbody tr:hover td {
        color: unset; }
    .table-phone-number-list tbody tr td:not(.no-data-column) {
      padding: 8px 10px; }
      .table-phone-number-list tbody tr td:not(.no-data-column) p {
        margin-bottom: 0; }
    .table-phone-number-list tbody tr td {
      background: transparent;
      position: relative;
      word-wrap: break-word;
      vertical-align: top; }
      .table-phone-number-list tbody tr td .unavailable {
        color: rgba(25, 25, 25, 0.4); }
      .table-phone-number-list tbody tr td .link-user {
        color: inherit;
        text-decoration: inherit; }
      .table-phone-number-list tbody tr td:nth-child(2) p {
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical; }
      .table-phone-number-list tbody tr td:nth-child(3) .custom-switch-wrapper {
        width: 40px;
        top: 2px; }
        .table-phone-number-list tbody tr td:nth-child(3) .custom-switch-wrapper input:checked + .custom-switch:before {
          -webkit-transform: translateX(19px);
          transform: translateX(19px); }
        .table-phone-number-list tbody tr td:nth-child(3) .custom-switch-wrapper .custom-switch {
          width: 16px;
          height: 16px; }
        .table-phone-number-list tbody tr td:nth-child(3) .custom-switch-wrapper .custom-switch:before {
          left: 4px;
          bottom: 3.8px; }
        .table-phone-number-list tbody tr td:nth-child(3) .custom-switch-wrapper .custom-switch.round:before {
          width: 10px;
          height: 10px; }
      .table-phone-number-list tbody tr td.col-view {
        color: #ff7e00;
        cursor: pointer;
        font-weight: 700;
        text-align: right; }
        .table-phone-number-list tbody tr td.col-view a {
          color: #ff7e00; }
        .table-phone-number-list tbody tr td.col-view svg {
          position: relative;
          color: #ff7e00;
          font-size: 16px;
          margin-right: 4px;
          right: 0;
          top: -2px; }
  .table-phone-number-list #pagination-wrapper {
    visibility: hidden; }
    .table-phone-number-list #pagination-wrapper ul li {
      font-size: 12px !important;
      position: relative !important; }
      .table-phone-number-list #pagination-wrapper ul li:not(.pagination-button) {
        top: 6px !important; }
      .table-phone-number-list #pagination-wrapper ul li span {
        position: absolute;
        top: -42px;
        display: inline-block;
        left: 50%;
        -webkit-transform: translate(-50%, 0);
                transform: translate(-50%, 0); }
      .table-phone-number-list #pagination-wrapper ul li.active {
        width: 30px !important;
        height: 30px !important; }
        .table-phone-number-list #pagination-wrapper ul li.active span {
          top: -40px !important; }
      .table-phone-number-list #pagination-wrapper ul li.pagination-label {
        top: 5px !important; }
        .table-phone-number-list #pagination-wrapper ul li.pagination-label svg {
          height: 12px; }


.integration-whatsapp-ba-edit-topic-account {
  padding: 32px 30px;
  text-align: left; }
  .integration-whatsapp-ba-edit-topic-account .comp-back-button {
    margin: 0 0 16px 0;
    font-family: PTRootBold; }
  .integration-whatsapp-ba-edit-topic-account .integration-whatsapp-edit-topic-account-box {
    background: #ffffff;
    border-radius: 8px;
    padding: 32px;
    border: solid 1px rgba(25, 25, 25, 0.1); }
    .integration-whatsapp-ba-edit-topic-account .integration-whatsapp-edit-topic-account-box .custom-checkbox label {
      width: unset !important;
      padding: 0 0 0 24px !important; }
    .integration-whatsapp-ba-edit-topic-account .integration-whatsapp-edit-topic-account-box .integration-whatsapp-edit-topic-account-box-top {
      padding-bottom: 15px;
      border-bottom: solid 1px #dcdcdc;
      position: relative;
      font-size: 20px;
      letter-spacing: -0.6px; }
      .integration-whatsapp-ba-edit-topic-account .integration-whatsapp-edit-topic-account-box .integration-whatsapp-edit-topic-account-box-top button {
        position: absolute;
        top: -6px;
        right: 0;
        font-size: 16px;
        letter-spacing: -0.6px; }
    .integration-whatsapp-ba-edit-topic-account .integration-whatsapp-edit-topic-account-box .integration-whatsapp-edit-topic-account-box-form {
      width: 100%; }
      .integration-whatsapp-ba-edit-topic-account .integration-whatsapp-edit-topic-account-box .integration-whatsapp-edit-topic-account-box-form .phone-number-wrapper .custom-select-phonenumber {
        width: 340px;
        max-width: 100%; }
      .integration-whatsapp-ba-edit-topic-account .integration-whatsapp-edit-topic-account-box .integration-whatsapp-edit-topic-account-box-form label {
        padding: 32px 0 0;
        margin: 0 0 8px 0;
        font-size: 14px;
        letter-spacing: -0.4px;
        display: block;
        position: relative;
        width: 300px; }
        .integration-whatsapp-ba-edit-topic-account .integration-whatsapp-edit-topic-account-box .integration-whatsapp-edit-topic-account-box-form label span {
          font-size: 14px;
          letter-spacing: -0.2px;
          position: absolute;
          bottom: 0;
          right: 0; }
      .integration-whatsapp-ba-edit-topic-account .integration-whatsapp-edit-topic-account-box .integration-whatsapp-edit-topic-account-box-form .phone-input {
        position: relative; }
        .integration-whatsapp-ba-edit-topic-account .integration-whatsapp-edit-topic-account-box .integration-whatsapp-edit-topic-account-box-form .phone-input span {
          position: absolute;
          top: 12px;
          left: 16px;
          color: rgba(25, 25, 25, 0.4); }
        .integration-whatsapp-ba-edit-topic-account .integration-whatsapp-edit-topic-account-box .integration-whatsapp-edit-topic-account-box-form .phone-input input {
          padding: 12px 16px 12px 26px !important; }
        .integration-whatsapp-ba-edit-topic-account .integration-whatsapp-edit-topic-account-box .integration-whatsapp-edit-topic-account-box-form .phone-input .alert-wrapper {
          display: flex;
          margin-top: 4px;
          width: 300px; }
          .integration-whatsapp-ba-edit-topic-account .integration-whatsapp-edit-topic-account-box .integration-whatsapp-edit-topic-account-box-form .phone-input .alert-wrapper svg {
            margin-right: 4px;
            font-size: 20px;
            position: relative;
            top: -2px; }
          .integration-whatsapp-ba-edit-topic-account .integration-whatsapp-edit-topic-account-box .integration-whatsapp-edit-topic-account-box-form .phone-input .alert-wrapper p {
            font-size: 12px;
            margin: 0; }
      .integration-whatsapp-ba-edit-topic-account .integration-whatsapp-edit-topic-account-box .integration-whatsapp-edit-topic-account-box-form textarea {
        width: 300px; }
      .integration-whatsapp-ba-edit-topic-account .integration-whatsapp-edit-topic-account-box .integration-whatsapp-edit-topic-account-box-form input[type='text'] {
        padding: 12px 16px;
        border-radius: 6px;
        border: solid 1px #dcdcdc;
        width: 100%;
        font-size: 16px;
        letter-spacing: -0.3px;
        display: block; }
      .integration-whatsapp-ba-edit-topic-account .integration-whatsapp-edit-topic-account-box .integration-whatsapp-edit-topic-account-box-form .form-topic {
        width: 100%; }
        .integration-whatsapp-ba-edit-topic-account .integration-whatsapp-edit-topic-account-box .integration-whatsapp-edit-topic-account-box-form .form-topic input[type="text"] {
          width: 300px; }
        .integration-whatsapp-ba-edit-topic-account .integration-whatsapp-edit-topic-account-box .integration-whatsapp-edit-topic-account-box-form .form-topic .switch-channel-button {
          position: relative; }
          .integration-whatsapp-ba-edit-topic-account .integration-whatsapp-edit-topic-account-box .integration-whatsapp-edit-topic-account-box-form .form-topic .switch-channel-button label {
            margin: 0; }
          .integration-whatsapp-ba-edit-topic-account .integration-whatsapp-edit-topic-account-box .integration-whatsapp-edit-topic-account-box-form .form-topic .switch-channel-button .custom-switch-wrapper {
            position: absolute;
            width: 48px;
            height: 29px;
            right: 0;
            bottom: 20px; }
            .integration-whatsapp-ba-edit-topic-account .integration-whatsapp-edit-topic-account-box .integration-whatsapp-edit-topic-account-box-form .form-topic .switch-channel-button .custom-switch-wrapper .custom-switch.round:before {
              width: 16px;
              height: 16px;
              bottom: 7px;
              left: 7px; }
            .integration-whatsapp-ba-edit-topic-account .integration-whatsapp-edit-topic-account-box .integration-whatsapp-edit-topic-account-box-form .form-topic .switch-channel-button .custom-switch-wrapper input:checked + .custom-switch:before {
              -webkit-transform: translateX(19px);
                      transform: translateX(19px); }
            .integration-whatsapp-ba-edit-topic-account .integration-whatsapp-edit-topic-account-box .integration-whatsapp-edit-topic-account-box-form .form-topic .switch-channel-button .custom-switch-wrapper b {
              font-size: 16px;
              letter-spacing: -0.5px; }
      .integration-whatsapp-ba-edit-topic-account .integration-whatsapp-edit-topic-account-box .integration-whatsapp-edit-topic-account-box-form input[type='text']:disabled {
        background: rgba(25, 25, 25, 0.1);
        color: rgba(25, 25, 25, 0.4);
        border: none;
        cursor: not-allowed; }
      .integration-whatsapp-ba-edit-topic-account .integration-whatsapp-edit-topic-account-box .integration-whatsapp-edit-topic-account-box-form .integration-info-form {
        font-size: 14px;
        color: rgba(25, 25, 25, 0.8);
        letter-spacing: -0.2px; }
      .integration-whatsapp-ba-edit-topic-account .integration-whatsapp-edit-topic-account-box .integration-whatsapp-edit-topic-account-box-form .custom-select-whatsapp-topic .custom-select-option-value-wrapper {
        padding: 12px 16px !important;
        margin-bottom: 8px; }

.integration-waba-edit-topic-account {
  padding: 32px 30px;
  text-align: left; }
  .integration-waba-edit-topic-account .comp-back-button {
    margin: 0 0 16px 0;
    font-family: PTRootBold; }
  .integration-waba-edit-topic-account .integration-waba-edit-topic-account-box {
    background: #ffffff;
    border-radius: 8px;
    padding: 32px;
    border: solid 1px rgba(25, 25, 25, 0.1); }
    .integration-waba-edit-topic-account .integration-waba-edit-topic-account-box .form-content-wrapper label {
      padding: 0 !important; }
    .integration-waba-edit-topic-account .integration-waba-edit-topic-account-box .integration-waba-edit-topic-account-box-top {
      padding-bottom: 15px;
      border-bottom: solid 1px #dcdcdc;
      position: relative;
      font-size: 20px;
      letter-spacing: -0.6px; }
      .integration-waba-edit-topic-account .integration-waba-edit-topic-account-box .integration-waba-edit-topic-account-box-top button {
        position: absolute;
        top: -6px;
        right: 0;
        font-size: 16px;
        letter-spacing: -0.6px; }
    .integration-waba-edit-topic-account .integration-waba-edit-topic-account-box .integration-waba-edit-topic-account-box-form {
      width: 100%; }
      .integration-waba-edit-topic-account .integration-waba-edit-topic-account-box .integration-waba-edit-topic-account-box-form label {
        padding: 24px 0 0;
        font-size: 14px;
        letter-spacing: -0.4px;
        display: block;
        position: relative; }
        .integration-waba-edit-topic-account .integration-waba-edit-topic-account-box .integration-waba-edit-topic-account-box-form label span {
          position: absolute;
          right: 0; }
      .integration-waba-edit-topic-account .integration-waba-edit-topic-account-box .integration-waba-edit-topic-account-box-form input[type='text'] {
        padding: 12px 16px;
        border-radius: 6px;
        border: solid 1px #dcdcdc;
        width: 100%;
        font-size: 16px;
        letter-spacing: -0.3px;
        display: block; }
      .integration-waba-edit-topic-account .integration-waba-edit-topic-account-box .integration-waba-edit-topic-account-box-form .form-topic {
        width: 575px; }
      .integration-waba-edit-topic-account .integration-waba-edit-topic-account-box .integration-waba-edit-topic-account-box-form input[type='text']:disabled {
        background: rgba(25, 25, 25, 0.1);
        color: rgba(25, 25, 25, 0.2);
        border: none;
        cursor: not-allowed; }
      .integration-waba-edit-topic-account .integration-waba-edit-topic-account-box .integration-waba-edit-topic-account-box-form .integration-info-form {
        font-size: 14px;
        color: rgba(25, 25, 25, 0.8);
        letter-spacing: -0.2px; }
      .integration-waba-edit-topic-account .integration-waba-edit-topic-account-box .integration-waba-edit-topic-account-box-form .custom-select-waba-topic .custom-select-option-value-wrapper {
        padding: 12px 16px !important;
        margin-bottom: 8px; }

.carousel-card-form-add-template {
  border-radius: 8px;
  margin: 4px 0 0;
  border: solid 1px rgba(25, 25, 25, 0.2);
  background: #fafafa;
  padding: 16px 16px 16px 44px;
  position: relative; }
  .carousel-card-form-add-template .carousel-card-body {
    font-size: 16px;
    letter-spacing: -0.3px;
    margin: 12px 0 0;
    padding: 0 34px 0 0; }
  .carousel-card-form-add-template .carousel-replace-media {
    position: absolute;
    left: 154px;
    top: 52px;
    padding: 0 12px 0 8px !important; }
  .carousel-card-form-add-template .fi-drag-drop {
    position: absolute;
    top: 16px;
    left: 16px; }
  .carousel-card-form-add-template .carousel-card-button {
    position: absolute;
    font-size: 12px;
    letter-spacing: -0.4px;
    top: 16px;
    right: 16px; }
    .carousel-card-form-add-template .carousel-card-button b {
      cursor: pointer;
      margin-left: 12px; }
  .carousel-card-form-add-template .carousel-card-media {
    width: 100px;
    height: 100px;
    border-radius: 8px;
    background: rgba(25, 25, 25, 0.2);
    position: relative;
    overflow: hidden; }
    .carousel-card-form-add-template .carousel-card-media img,
    .carousel-card-form-add-template .carousel-card-media video {
      position: absolute;
      top: 50%;
      left: 50%;
      max-width: 100%;
      max-height: 100%;
      -webkit-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%); }
    .carousel-card-form-add-template .carousel-card-media::before {
      content: "";
      width: 100px;
      height: 100px;
      position: absolute;
      top: 0;
      left: 0;
      border-radius: 8px;
      z-index: 1;
      background: rgba(25, 25, 25, 0.2); }
    .carousel-card-form-add-template .carousel-card-media svg {
      color: #fff;
      z-index: 2;
      font-size: 24px;
      position: absolute;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%); }

.integration-waba-create-message-template-account {
  padding: 32px 30px;
  text-align: left; }
  .integration-waba-create-message-template-account .custom-checkbox-auth label::before {
    top: 4px; }
  .integration-waba-create-message-template-account .custom-checkbox-auth label::after {
    top: -1px; }
  .integration-waba-create-message-template-account .category-card-wrapper {
    display: block;
    width: 100%; }
    .integration-waba-create-message-template-account .category-card-wrapper .category-card {
      vertical-align: top;
      border: solid 1px #dcdcdc;
      border-radius: 8px;
      width: calc((100% / 3) - 16px);
      margin-right: 24px;
      padding: 10px 16px;
      display: inline-block;
      cursor: pointer; }
      .integration-waba-create-message-template-account .category-card-wrapper .category-card img {
        margin-right: 10px; }
      .integration-waba-create-message-template-account .category-card-wrapper .category-card:nth-last-child(1) {
        margin-right: 0; }
      .integration-waba-create-message-template-account .category-card-wrapper .category-card:hover {
        border: solid 1px var(--main-dark-orange); }
      .integration-waba-create-message-template-account .category-card-wrapper .category-card.category-card-active {
        border: solid 1px var(--main-dark-orange); }
  .integration-waba-create-message-template-account .category-message-wrapper {
    margin: 8px 0 0; }
    .integration-waba-create-message-template-account .category-message-wrapper label {
      width: unset !important;
      display: inline-block !important;
      padding: 0 0 0 28px !important;
      font-size: 16px;
      letter-spacing: -0.3px; }
      .integration-waba-create-message-template-account .category-message-wrapper label p {
        font-size: 12px;
        letter-spacing: -0.2px;
        color: rgba(25, 25, 25, 0.6);
        margin: 0 0 8px 0; }
        .integration-waba-create-message-template-account .category-message-wrapper label p.no-margin-bottom {
          margin: 0; }
    .integration-waba-create-message-template-account .category-message-wrapper.with-border-bottom {
      border-bottom: solid 1px #dcdcdc;
      padding-bottom: 24px; }
  .integration-waba-create-message-template-account #tooltip-sample-param {
    display: inline-block;
    position: relative;
    top: -1px; }
  .integration-waba-create-message-template-account .buttons-wrapper {
    padding: 12px;
    background: #f3f3f3;
    border: solid 1px rgba(25, 25, 25, 0.1);
    border-radius: 8px;
    margin: 8px 0 12px;
    position: relative; }
    .integration-waba-create-message-template-account .buttons-wrapper .input-param {
      position: relative;
      width: 100%; }
      .integration-waba-create-message-template-account .buttons-wrapper .input-param.param-right input {
        width: calc(100% - 35px) !important; }
      .integration-waba-create-message-template-account .buttons-wrapper .input-param.param-right span {
        color: rgba(25, 25, 25, 0.5);
        position: absolute;
        top: 50%;
        right: 0;
        -webkit-transform: translate(0, -50%);
                transform: translate(0, -50%); }
      .integration-waba-create-message-template-account .buttons-wrapper .input-param.param-left input {
        width: calc(100% - 35px) !important;
        margin-left: 35px; }
      .integration-waba-create-message-template-account .buttons-wrapper .input-param.param-left span {
        color: rgba(25, 25, 25, 0.5);
        position: absolute;
        top: 50%;
        left: 0;
        -webkit-transform: translate(0, -50%);
                transform: translate(0, -50%); }
    .integration-waba-create-message-template-account .buttons-wrapper .dropdown button {
      margin: 0 !important; }
    .integration-waba-create-message-template-account .buttons-wrapper .button-delete {
      color: #ff3f57;
      font-size: 14px;
      position: absolute;
      top: 15px;
      right: 12px;
      cursor: pointer; }
    .integration-waba-create-message-template-account .buttons-wrapper label {
      position: relative; }
      .integration-waba-create-message-template-account .buttons-wrapper label .custom-tooltips {
        top: 4px; }
      .integration-waba-create-message-template-account .buttons-wrapper label:nth-child(1) {
        padding: 0 !important; }
      .integration-waba-create-message-template-account .buttons-wrapper label .grey-font {
        display: inline-block; }
      .integration-waba-create-message-template-account .buttons-wrapper label span {
        color: rgba(25, 25, 25, 0.6);
        font-size: 14px;
        letter-spacing: -0.2px;
        position: absolute;
        right: 0;
        bottom: 0; }
        .integration-waba-create-message-template-account .buttons-wrapper label span.right-18 {
          right: 18px !important; }
    .integration-waba-create-message-template-account .buttons-wrapper .phone-number-wrapper {
      position: relative; }
      .integration-waba-create-message-template-account .buttons-wrapper .phone-number-wrapper svg {
        position: absolute;
        top: 14px;
        left: 12px;
        font-size: 22px; }
      .integration-waba-create-message-template-account .buttons-wrapper .phone-number-wrapper input {
        padding-left: 36px !important; }
  .integration-waba-create-message-template-account .add-another-button {
    color: #ff7e00;
    font-size: 14px;
    padding-left: 6px;
    letter-spacing: -0.5px;
    cursor: pointer;
    line-height: 1; }
    .integration-waba-create-message-template-account .add-another-button:hover {
      color: var(--main-darker-orange); }
    .integration-waba-create-message-template-account .add-another-button svg {
      position: relative;
      top: -1px;
      margin-right: 4px; }
  .integration-waba-create-message-template-account .add-another-card {
    padding-left: 0;
    padding-top: 12px;
    margin: 0; }
  .integration-waba-create-message-template-account .custom-checkbox label {
    padding: 0 0 0 20px !important; }
  .integration-waba-create-message-template-account .comp-back-button {
    margin: 0 0 16px 0;
    font-family: PTRootBold; }
  .integration-waba-create-message-template-account .integration-waba-create-message-template-account-box {
    background: #ffffff;
    border-radius: 8px;
    padding: 32px;
    border: solid 1px rgba(25, 25, 25, 0.1); }
    .integration-waba-create-message-template-account .integration-waba-create-message-template-account-box .sample-param-input-wrapper {
      position: relative;
      padding-top: 26px; }
      .integration-waba-create-message-template-account .integration-waba-create-message-template-account-box .sample-param-input-wrapper .max-char-variable {
        position: absolute;
        font-size: 14px;
        right: 0;
        top: 6px; }
      .integration-waba-create-message-template-account .integration-waba-create-message-template-account-box .sample-param-input-wrapper p {
        width: 150px;
        text-overflow: ellipsis;
        overflow: hidden;
        font-size: 14px;
        letter-spacing: -0.4px;
        display: inline-block;
        padding-right: 8px;
        padding-top: 11px;
        margin: 0; }
      .integration-waba-create-message-template-account .integration-waba-create-message-template-account-box .sample-param-input-wrapper input {
        width: calc(100% - 150px) !important;
        display: inline-block !important;
        border: solid 1px #dcdcdc;
        border-radius: 8px;
        vertical-align: top;
        height: 45px;
        color: rgba(25, 25, 25, 0.6); }
    .integration-waba-create-message-template-account .integration-waba-create-message-template-account-box .form-content-wrapper label {
      padding: 0 !important; }
    .integration-waba-create-message-template-account .integration-waba-create-message-template-account-box .integration-waba-create-message-template-account-box-top {
      padding-bottom: 15px;
      position: relative;
      font-size: 20px;
      letter-spacing: -0.6px; }
      .integration-waba-create-message-template-account .integration-waba-create-message-template-account-box .integration-waba-create-message-template-account-box-top button {
        position: absolute;
        top: -6px;
        right: 0;
        font-size: 16px;
        letter-spacing: -0.6px; }
    .integration-waba-create-message-template-account .integration-waba-create-message-template-account-box .integration-waba-create-message-template-account-box-form {
      width: 100%;
      padding: 24px 0;
      border-top: solid 1px #dcdcdc;
      position: relative; }
      .integration-waba-create-message-template-account .integration-waba-create-message-template-account-box .integration-waba-create-message-template-account-box-form .button-remove-template {
        position: absolute;
        top: 16px;
        right: 0; }
      .integration-waba-create-message-template-account .integration-waba-create-message-template-account-box .integration-waba-create-message-template-account-box-form.box-top {
        border-bottom: solid 1px #dcdcdc; }
      .integration-waba-create-message-template-account .integration-waba-create-message-template-account-box .integration-waba-create-message-template-account-box-form .template-detail {
        font-size: 16px;
        letter-spacing: -0.5px;
        margin: 0; }
        .integration-waba-create-message-template-account .integration-waba-create-message-template-account-box .integration-waba-create-message-template-account-box-form .template-detail svg {
          position: relative;
          top: -2px;
          margin-right: 4px; }
      .integration-waba-create-message-template-account .integration-waba-create-message-template-account-box .integration-waba-create-message-template-account-box-form label {
        padding: 24px 0 0;
        font-size: 16px;
        letter-spacing: -0.4px;
        display: block;
        position: relative;
        width: 100%; }
        .integration-waba-create-message-template-account .integration-waba-create-message-template-account-box .integration-waba-create-message-template-account-box-form label.label-550 {
          width: 550px; }
          .integration-waba-create-message-template-account .integration-waba-create-message-template-account-box .integration-waba-create-message-template-account-box-form label.label-550 span {
            position: absolute;
            right: 0; }
      .integration-waba-create-message-template-account .integration-waba-create-message-template-account-box .integration-waba-create-message-template-account-box-form input[type='text'] {
        padding: 12px 16px;
        border-radius: 6px;
        border: solid 1px #dcdcdc;
        width: 100%;
        font-size: 16px;
        letter-spacing: -0.3px;
        display: block; }
      .integration-waba-create-message-template-account .integration-waba-create-message-template-account-box .integration-waba-create-message-template-account-box-form .form-topic {
        width: 100%;
        display: inline-block; }
        .integration-waba-create-message-template-account .integration-waba-create-message-template-account-box .integration-waba-create-message-template-account-box-form .form-topic .input-info-template-name {
          margin-bottom: 0; }
        .integration-waba-create-message-template-account .integration-waba-create-message-template-account-box .integration-waba-create-message-template-account-box-form .form-topic .width-400 {
          width: 400px; }
        .integration-waba-create-message-template-account .integration-waba-create-message-template-account-box .integration-waba-create-message-template-account-box-form .form-topic .width-550 {
          width: 550px; }
      .integration-waba-create-message-template-account .integration-waba-create-message-template-account-box .integration-waba-create-message-template-account-box-form input[type='text']:disabled {
        background: rgba(25, 25, 25, 0.1);
        color: rgba(25, 25, 25, 0.2);
        border: none;
        cursor: not-allowed; }
      .integration-waba-create-message-template-account .integration-waba-create-message-template-account-box .integration-waba-create-message-template-account-box-form .integration-info-form {
        font-size: 14px;
        color: rgba(25, 25, 25, 0.8);
        letter-spacing: -0.2px; }
      .integration-waba-create-message-template-account .integration-waba-create-message-template-account-box .integration-waba-create-message-template-account-box-form .custom-select-waba-topic .custom-select-option-value-wrapper {
        padding: 12px 16px !important;
        margin-bottom: 8px; }
      .integration-waba-create-message-template-account .integration-waba-create-message-template-account-box .integration-waba-create-message-template-account-box-form.box-bottom {
        position: relative;
        width: 100%; }
        .integration-waba-create-message-template-account .integration-waba-create-message-template-account-box .integration-waba-create-message-template-account-box-form.box-bottom .box-bottom-left {
          display: inline-block;
          vertical-align: top;
          width: 50%; }
          .integration-waba-create-message-template-account .integration-waba-create-message-template-account-box .integration-waba-create-message-template-account-box-form.box-bottom .box-bottom-left .input-with-counter {
            position: relative; }
            .integration-waba-create-message-template-account .integration-waba-create-message-template-account-box .integration-waba-create-message-template-account-box-form.box-bottom .box-bottom-left .input-with-counter .input-counter {
              position: absolute;
              top: -28px;
              right: 0;
              font-size: 14px;
              letter-spacing: -0.2px; }
        .integration-waba-create-message-template-account .integration-waba-create-message-template-account-box .integration-waba-create-message-template-account-box-form.box-bottom .box-bottom-right {
          width: 50%;
          vertical-align: top;
          display: inline-block;
          padding-left: 24px; }
      .integration-waba-create-message-template-account .integration-waba-create-message-template-account-box .integration-waba-create-message-template-account-box-form .button-wrapper {
        position: relative;
        width: 100%; }
  .integration-waba-create-message-template-account .color-red {
    color: #FF3F57; }
  .integration-waba-create-message-template-account .color-blue {
    color: #3BAEFF; }
  .integration-waba-create-message-template-account .color-orange {
    color: #ff7d00; }

.template-type-selection-wrapper {
  width: 100%; }
  .template-type-selection-wrapper .selection-template-type {
    border: solid 1px #dcdcdc;
    border-radius: 8px;
    padding: 25px 25px 25px 110px;
    display: inline-block;
    margin-right: 15px;
    width: calc((100% / 3) - 10px);
    position: relative;
    vertical-align: top;
    cursor: pointer;
    -webkit-transition: ease-in-out .1s;
    transition: ease-in-out .1s; }
    .template-type-selection-wrapper .selection-template-type.active-template-type {
      border-color: #ff7d00; }
    .template-type-selection-wrapper .selection-template-type:hover {
      border-color: #ff7d00; }
    .template-type-selection-wrapper .selection-template-type label {
      padding: 0 !important;
      position: absolute !important;
      left: 25px;
      top: 50%;
      -webkit-transform: translate(0, -50%);
              transform: translate(0, -50%); }
    .template-type-selection-wrapper .selection-template-type:nth-last-child(1) {
      margin-right: 0; }
    .template-type-selection-wrapper .selection-template-type .template-type-icon-wrapper {
      border-radius: 50%;
      width: 48px;
      height: 48px;
      position: absolute;
      left: 50px;
      top: 50%;
      -webkit-transform: translate(0, -50%);
              transform: translate(0, -50%);
      background: #f2d5fb; }
      .template-type-selection-wrapper .selection-template-type .template-type-icon-wrapper img {
        position: absolute;
        left: 50%;
        top: 50%;
        -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%); }
      .template-type-selection-wrapper .selection-template-type .template-type-icon-wrapper svg {
        position: absolute;
        left: 50%;
        top: 50%;
        -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
        font-size: 24px; }
      .template-type-selection-wrapper .selection-template-type .template-type-icon-wrapper.static-media {
        background: #ECFFE9;
        color: #19C700; }
      .template-type-selection-wrapper .selection-template-type .template-type-icon-wrapper.dynamic-media {
        background: #EBF7FF;
        color: #3BAEFF; }
    .template-type-selection-wrapper .selection-template-type p {
      margin: 0 !important;
      font-size: 16px;
      letter-spacing: -0.5px; }
      .template-type-selection-wrapper .selection-template-type p:nth-last-child(1) {
        font-size: 14px;
        letter-spacing: -0.2px;
        color: rgba(25, 25, 25, 0.8); }

.header-media-type-selection-wrapper {
  width: 100%; }
  .header-media-type-selection-wrapper .header-media-selection {
    vertical-align: top;
    border-radius: 8px;
    border: solid 1px #dcdcdc;
    display: inline-block;
    position: relative;
    width: calc((100% / 3) - 6px);
    margin-right: 9px;
    font-size: 14px;
    letter-spacing: -0.2px;
    padding: 14px 10px 14px 45px;
    cursor: pointer;
    -webkit-transition: ease-in-out .1s;
    transition: ease-in-out .1s; }
    .header-media-type-selection-wrapper .header-media-selection:nth-last-child(1) {
      margin-right: 0; }
    .header-media-type-selection-wrapper .header-media-selection.active-header-media-selection {
      border-color: #ff7d00; }
    .header-media-type-selection-wrapper .header-media-selection:hover {
      border-color: #ff7d00; }
  .header-media-type-selection-wrapper .header-media-icon-wrapper {
    width: 28px;
    height: 28px;
    border-radius: 50%;
    background: #EBF7FF;
    color: #3BAEFF;
    position: absolute;
    top: 50%;
    left: 10px;
    -webkit-transform: translate(0, -50%);
            transform: translate(0, -50%); }
    .header-media-type-selection-wrapper .header-media-icon-wrapper.header-media-image {
      background: #ECFFE9;
      color: #19C700; }
    .header-media-type-selection-wrapper .header-media-icon-wrapper.header-media-video {
      color: #FF3F57;
      background: #ffecce; }
    .header-media-type-selection-wrapper .header-media-icon-wrapper svg {
      position: absolute;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%); }
  .header-media-type-selection-wrapper p {
    font-size: 14px;
    letter-spacing: -.2px; }
  .header-media-type-selection-wrapper:nth-last-child(1) {
    margin-right: 0; }

.modal-conformation-carousel-card button {
  width: calc(50% - 4px) !important;
  margin: 0 8px 0 0; }
  .modal-conformation-carousel-card button:nth-last-child(1) {
    margin: 0; }

.add-meta-card-wrapper {
  width: 100vw;
  height: 100vh;
  background: rgba(25, 25, 25, 0.8);
  position: fixed;
  top: 0;
  /* right: -100vw; */
  visibility: hidden;
  opacity: 0;
  right: 0;
  /* transition: visibility 0s, opacity 0.3s ease-in-out; */
  -webkit-transition: visibility .3s, opacity 0.5s ease-in-out;
  transition: visibility .3s, opacity 0.5s ease-in-out;
  z-index: 9991; }
  .add-meta-card-wrapper input[type="file"] {
    display: none; }
  .add-meta-card-wrapper .add-sample-param-toggle {
    position: relative;
    display: inline-block;
    top: -2px;
    margin-left: 4px;
    cursor: pointer; }
  .add-meta-card-wrapper.active-add-meta-card {
    opacity: 1;
    visibility: visible;
    -webkit-transition: visibility 0s, opacity 0.5s ease-in-out;
    transition: visibility 0s, opacity 0.5s ease-in-out; }
  .add-meta-card-wrapper .add-meta-card-main-wrapper {
    width: 580px;
    -webkit-transition: ease-in-out 0.5s;
    transition: ease-in-out 0.5s;
    background: #ffffff;
    height: calc(100vh - 8px);
    border-top-left-radius: 18px;
    bottom: 0;
    right: -405px;
    position: fixed; }
    .add-meta-card-wrapper .add-meta-card-main-wrapper .buttons-wrapper-meta-card {
      padding: 12px 12px 12px;
      background: #f3f3f3;
      border: solid 1px rgba(25, 25, 25, 0.1);
      border-radius: 8px;
      margin: 8px 0 12px;
      position: relative; }
      .add-meta-card-wrapper .add-meta-card-main-wrapper .buttons-wrapper-meta-card .input-param {
        position: relative;
        width: 100%; }
        .add-meta-card-wrapper .add-meta-card-main-wrapper .buttons-wrapper-meta-card .input-param.param-right input {
          width: calc(100% - 35px) !important; }
        .add-meta-card-wrapper .add-meta-card-main-wrapper .buttons-wrapper-meta-card .input-param.param-right span {
          color: rgba(25, 25, 25, 0.5);
          position: absolute;
          top: 50%;
          right: 0;
          -webkit-transform: translate(0, -50%);
                  transform: translate(0, -50%); }
        .add-meta-card-wrapper .add-meta-card-main-wrapper .buttons-wrapper-meta-card .input-param.param-left input {
          width: calc(100% - 35px) !important;
          margin-left: 35px; }
        .add-meta-card-wrapper .add-meta-card-main-wrapper .buttons-wrapper-meta-card .input-param.param-left span {
          color: rgba(25, 25, 25, 0.5);
          position: absolute;
          top: 50%;
          left: 0;
          -webkit-transform: translate(0, -50%);
                  transform: translate(0, -50%); }
      .add-meta-card-wrapper .add-meta-card-main-wrapper .buttons-wrapper-meta-card .dropdown button {
        margin: 0 !important; }
      .add-meta-card-wrapper .add-meta-card-main-wrapper .buttons-wrapper-meta-card .button-delete {
        color: #ff3f57;
        font-size: 14px;
        position: absolute;
        top: 15px;
        right: 12px;
        cursor: pointer; }
      .add-meta-card-wrapper .add-meta-card-main-wrapper .buttons-wrapper-meta-card input[type="text"] {
        padding: 12px 16px;
        border-radius: 6px;
        border: solid 1px #dcdcdc;
        width: 100%;
        font-size: 16px;
        letter-spacing: -0.3px;
        display: block; }
      .add-meta-card-wrapper .add-meta-card-main-wrapper .buttons-wrapper-meta-card label {
        position: relative;
        padding: 24px 0 0 0 !important;
        margin: 0 0 8px 0 !important; }
        .add-meta-card-wrapper .add-meta-card-main-wrapper .buttons-wrapper-meta-card label .custom-tooltips {
          top: 4px; }
        .add-meta-card-wrapper .add-meta-card-main-wrapper .buttons-wrapper-meta-card label:nth-child(1) {
          padding: 0 !important; }
        .add-meta-card-wrapper .add-meta-card-main-wrapper .buttons-wrapper-meta-card label .grey-font {
          display: inline-block; }
        .add-meta-card-wrapper .add-meta-card-main-wrapper .buttons-wrapper-meta-card label span {
          color: rgba(25, 25, 25, 0.6);
          font-size: 14px;
          letter-spacing: -0.2px;
          position: absolute;
          right: 0;
          bottom: 0; }
          .add-meta-card-wrapper .add-meta-card-main-wrapper .buttons-wrapper-meta-card label span.right-18 {
            right: 18px !important; }
      .add-meta-card-wrapper .add-meta-card-main-wrapper .buttons-wrapper-meta-card .phone-number-wrapper {
        position: relative; }
        .add-meta-card-wrapper .add-meta-card-main-wrapper .buttons-wrapper-meta-card .phone-number-wrapper svg {
          position: absolute;
          top: 14px;
          left: 12px;
          font-size: 22px; }
        .add-meta-card-wrapper .add-meta-card-main-wrapper .buttons-wrapper-meta-card .phone-number-wrapper input {
          padding-left: 36px !important; }
    .add-meta-card-wrapper .add-meta-card-main-wrapper.active-add-meta-card-main-wrapper {
      right: 0; }
    .add-meta-card-wrapper .add-meta-card-main-wrapper .add-meta-card-close {
      background: #ffffff;
      border-radius: 50%;
      cursor: pointer;
      position: absolute;
      width: 48px;
      height: 48px;
      display: inline-block;
      text-align: center;
      line-height: 45px;
      top: 0;
      left: -64px; }
      .add-meta-card-wrapper .add-meta-card-main-wrapper .add-meta-card-close svg {
        font-size: 24px;
        color: #7D7C7D; }
    .add-meta-card-wrapper .add-meta-card-main-wrapper .title-add-meta-card {
      font-size: 20px;
      letter-spacing: -0.6px;
      padding: 32px 32px 0 32px;
      margin: 0; }
      .add-meta-card-wrapper .add-meta-card-main-wrapper .title-add-meta-card svg {
        position: relative;
        margin-right: 8px;
        top: -2px; }
      .add-meta-card-wrapper .add-meta-card-main-wrapper .title-add-meta-card p {
        font-size: 14px;
        letter-spacing: -0.2px;
        color: rgba(25, 25, 25, 0.6);
        margin: 8px 0 0; }
    .add-meta-card-wrapper .add-meta-card-main-wrapper .body-add-meta-card {
      max-height: calc(100% - 164px);
      overflow-y: auto;
      width: 100%;
      padding: 0 32px 32px; }
      .add-meta-card-wrapper .add-meta-card-main-wrapper .body-add-meta-card .sample-param-input-wrapper {
        position: relative;
        padding-top: 26px; }
        .add-meta-card-wrapper .add-meta-card-main-wrapper .body-add-meta-card .sample-param-input-wrapper .max-char-variable {
          position: absolute;
          font-size: 14px;
          right: 0;
          top: 6px; }
        .add-meta-card-wrapper .add-meta-card-main-wrapper .body-add-meta-card .sample-param-input-wrapper p {
          width: 150px;
          text-overflow: ellipsis;
          overflow: hidden;
          font-size: 14px;
          letter-spacing: -0.4px;
          display: inline-block;
          padding-right: 8px;
          padding-top: 11px;
          margin: 0; }
        .add-meta-card-wrapper .add-meta-card-main-wrapper .body-add-meta-card .sample-param-input-wrapper input {
          width: calc(100% - 150px) !important;
          display: inline-block !important;
          border: solid 1px #dcdcdc;
          border-radius: 8px;
          vertical-align: top;
          height: 45px;
          color: rgba(25, 25, 25, 0.6);
          padding: 12px 16px; }
      .add-meta-card-wrapper .add-meta-card-main-wrapper .body-add-meta-card .image-or-video-wrapper {
        width: 100px;
        height: 100px;
        position: relative;
        background: #f9f9f9;
        border-radius: 8px; }
        .add-meta-card-wrapper .add-meta-card-main-wrapper .body-add-meta-card .image-or-video-wrapper .lds-ring {
          top: 50%;
          left: 50%;
          width: 80px;
          height: 80px;
          -webkit-transform: translate(-50%, -50%);
                  transform: translate(-50%, -50%);
          z-index: 5; }
          .add-meta-card-wrapper .add-meta-card-main-wrapper .body-add-meta-card .image-or-video-wrapper .lds-ring div {
            width: 80px;
            height: 80px;
            border: solid 8px #fff;
            border-color: #fff transparent transparent transparent;
            margin: 0; }
        .add-meta-card-wrapper .add-meta-card-main-wrapper .body-add-meta-card .image-or-video-wrapper img,
        .add-meta-card-wrapper .add-meta-card-main-wrapper .body-add-meta-card .image-or-video-wrapper video {
          position: absolute;
          top: 50%;
          left: 50%;
          max-width: 100%;
          max-height: 100%;
          -webkit-transform: translate(-50%, -50%);
                  transform: translate(-50%, -50%); }
        .add-meta-card-wrapper .add-meta-card-main-wrapper .body-add-meta-card .image-or-video-wrapper::before {
          content: "";
          width: 100px;
          height: 100px;
          position: absolute;
          top: 0;
          left: 0;
          border-radius: 8px;
          z-index: 1;
          background: rgba(25, 25, 25, 0.2); }
        .add-meta-card-wrapper .add-meta-card-main-wrapper .body-add-meta-card .image-or-video-wrapper svg {
          color: #fff;
          z-index: 2;
          font-size: 24px;
          position: absolute;
          top: 50%;
          left: 50%;
          -webkit-transform: translate(-50%, -50%);
                  transform: translate(-50%, -50%); }
      .add-meta-card-wrapper .add-meta-card-main-wrapper .body-add-meta-card label {
        display: block;
        margin: 16px 0 8px 0;
        position: relative; }
        .add-meta-card-wrapper .add-meta-card-main-wrapper .body-add-meta-card label .grey-font-6 {
          position: absolute;
          right: 0;
          top: 0;
          font-size: 14px;
          letter-spacing: -0.2px; }
      .add-meta-card-wrapper .add-meta-card-main-wrapper .body-add-meta-card .carousel-replace-media {
        margin-left: 6px; }
      .add-meta-card-wrapper .add-meta-card-main-wrapper .body-add-meta-card .button-upload-media {
        margin: 0 0 8px; }
      .add-meta-card-wrapper .add-meta-card-main-wrapper .body-add-meta-card .add-card-button {
        font-size: 14px;
        letter-spacing: -0.5px;
        padding: 0 0 0 8px; }
        .add-meta-card-wrapper .add-meta-card-main-wrapper .body-add-meta-card .add-card-button svg {
          position: relative;
          margin-right: 4px;
          top: -1px; }
    .add-meta-card-wrapper .add-meta-card-main-wrapper .foot-add-meta-card {
      padding: 12px 24px;
      position: absolute;
      bottom: 0;
      left: 0;
      box-shadow: 0px 2px 20px 0px rgba(25, 25, 25, 0.18);
      width: 100%; }
      .add-meta-card-wrapper .add-meta-card-main-wrapper .foot-add-meta-card button {
        width: calc(50% - 4px);
        margin: 0 8px 0 0; }
        .add-meta-card-wrapper .add-meta-card-main-wrapper .foot-add-meta-card button:nth-last-child(1) {
          margin: 0; }
  .add-meta-card-wrapper .add-meta-card-body-wrapper {
    width: 100%;
    display: block;
    position: relative;
    height: calc(100% - 80px); }

.custom-tooltips {
  position: relative;
  display: inline-block;
  margin-left: 4px;
  top: -2px;
  width: 16px;
  height: 16px; }
  .custom-tooltips .inner-tooltip-wrapper {
    font-size: 14px;
    letter-spacing: -0.2px;
    text-align: left !important;
    line-height: 20px;
    padding: 6px 12px !important;
    color: #fff;
    position: absolute;
    background: #6B6B6B !important;
    border-radius: 4px;
    z-index: 1;
    top: 50%;
    -webkit-transform: translate(0, -50%);
            transform: translate(0, -50%);
    left: 28px;
    display: none;
    width: 200px; }
    .custom-tooltips .inner-tooltip-wrapper p {
      margin: 0; }
    .custom-tooltips .inner-tooltip-wrapper::before {
      content: "";
      width: 10px;
      height: 10px;
      background: #6B6B6B;
      position: absolute;
      top: 50%;
      -webkit-transform: rotate(-45deg) translate(0, -50%);
              transform: rotate(-45deg) translate(0, -50%);
      left: -1px; }
  .custom-tooltips svg {
    font-size: 16px;
    vertical-align: top; }
    .custom-tooltips svg:hover + .inner-tooltip-wrapper {
      display: block; }

.custom-text-editor {
  width: 100%;
  border-radius: 8px;
  position: relative;
  font-size: 0; }
  .custom-text-editor .input-counter {
    position: absolute;
    top: -28px;
    right: 0;
    font-size: 14px;
    letter-spacing: -0.2px; }
  .custom-text-editor textarea, .custom-text-editor input[type="text"] {
    width: 100% !important;
    border-radius: 8px 8px 0 0 !important; }
  .custom-text-editor .text-editor-button-action-wrapper {
    border: solid 1px #dcdcdc;
    border-top: 0;
    border-radius: 0 0 8px 8px;
    padding: 12px 16px 12px; }
    .custom-text-editor .text-editor-button-action-wrapper .button-action {
      display: inline-block;
      height: 22px;
      border-radius: 4px;
      border: solid 1px #dcdcdc;
      text-align: center;
      padding: 0 6px;
      margin: 0;
      width: unset;
      margin-right: 4px;
      line-height: 11px;
      vertical-align: top;
      background: #ffffff;
      -webkit-transition: ease-in-out .1s;
      transition: ease-in-out .1s; }
      .custom-text-editor .text-editor-button-action-wrapper .button-action svg {
        font-size: 12px;
        color: rgba(25, 25, 25, 0.6); }
      .custom-text-editor .text-editor-button-action-wrapper .button-action:hover {
        background: rgba(25, 25, 25, 0.05); }
      .custom-text-editor .text-editor-button-action-wrapper .button-action.add-variable {
        font-size: 12px;
        letter-spacing: -0.4px;
        color: rgba(25, 25, 25, 0.6);
        padding: 0 12px 0 10px;
        position: relative; }
        .custom-text-editor .text-editor-button-action-wrapper .button-action.add-variable svg {
          position: relative;
          top: -1px;
          margin-right: 2px; }
        .custom-text-editor .text-editor-button-action-wrapper .button-action.add-variable:hover {
          color: #ffffff;
          background: #ff7e00;
          border: solid 1px #ff7e00; }
          .custom-text-editor .text-editor-button-action-wrapper .button-action.add-variable:hover svg {
            color: #ffffff; }
        .custom-text-editor .text-editor-button-action-wrapper .button-action.add-variable.active-add-variable {
          color: #ffffff;
          background: #ff7e00;
          border: solid 1px #ff7e00; }
          .custom-text-editor .text-editor-button-action-wrapper .button-action.add-variable.active-add-variable svg {
            color: #ffffff; }
    .custom-text-editor .text-editor-button-action-wrapper .add-variable-wrapper {
      position: relative;
      display: inline-block; }
      .custom-text-editor .text-editor-button-action-wrapper .add-variable-wrapper .button-close {
        position: absolute;
        top: 10px;
        right: 12px;
        font-size: 14px;
        cursor: pointer;
        color: rgba(25, 25, 25, 0.7); }
        .custom-text-editor .text-editor-button-action-wrapper .add-variable-wrapper .button-close:hover {
          color: #191919; }
      .custom-text-editor .text-editor-button-action-wrapper .add-variable-wrapper .add-variable-panel-wrapper {
        position: absolute;
        left: 0;
        bottom: 26px;
        background: #ffffff;
        border-radius: 8px;
        color: #191919;
        box-shadow: 0px 2px 20px rgba(25, 25, 25, 0.18);
        padding: 16px 24px;
        width: 350px;
        display: inline-block;
        text-align: left;
        z-index: 1; }
        .custom-text-editor .text-editor-button-action-wrapper .add-variable-wrapper .add-variable-panel-wrapper .add-variable-panel-title {
          font-size: 14px;
          letter-spacing: -0.4px;
          background: #ffffff; }
        .custom-text-editor .text-editor-button-action-wrapper .add-variable-wrapper .add-variable-panel-wrapper .variable-input {
          position: relative;
          margin-top: 9px; }
          .custom-text-editor .text-editor-button-action-wrapper .add-variable-wrapper .add-variable-panel-wrapper .variable-input input {
            border-radius: 8px !important;
            padding-right: 111px; }
            .custom-text-editor .text-editor-button-action-wrapper .add-variable-wrapper .add-variable-panel-wrapper .variable-input input:focus {
              border: solid 1px #ff7e00; }
          .custom-text-editor .text-editor-button-action-wrapper .add-variable-wrapper .add-variable-panel-wrapper .variable-input textarea {
            border-radius: 8px !important; }
          .custom-text-editor .text-editor-button-action-wrapper .add-variable-wrapper .add-variable-panel-wrapper .variable-input button {
            position: absolute;
            font-size: 12px;
            letter-spacing: 2.5px;
            height: 24px;
            display: inline-block;
            border-radius: 4px;
            width: unset;
            padding: 0 12px 0 10px;
            right: 16px;
            top: 12px;
            margin: 0; }
            .custom-text-editor .text-editor-button-action-wrapper .add-variable-wrapper .add-variable-panel-wrapper .variable-input button svg {
              margin-right: 4px;
              position: relative;
              top: -1px;
              font-size: 14px; }
        .custom-text-editor .text-editor-button-action-wrapper .add-variable-wrapper .add-variable-panel-wrapper ul {
          list-style: none;
          border-radius: 8px;
          background: rgba(25, 25, 25, 0.05);
          margin: 12px 0 0;
          padding: 8px 0 0; }
          .custom-text-editor .text-editor-button-action-wrapper .add-variable-wrapper .add-variable-panel-wrapper ul li {
            font-size: 14px;
            letter-spacing: -0.4px;
            margin: 0;
            padding: 0 16px 8px 16px; }

.template-message-upload .button-upload-media {
  display: inline-block !important; }

.template-message-upload input[type="file"] {
  display: none; }

.template-message-upload .media-content-title {
  margin: 24px 0 0;
  position: relative;
  font-size: 14px;
  letter-spacing: -0.2px; }
  .template-message-upload .media-content-title .fi-help {
    position: relative;
    top: -1px;
    font-size: 13px;
    margin-left: 4px; }

.template-message-upload .replace-media {
  position: relative;
  top: -1px;
  margin-left: 4px;
  padding: 0 !important;
  display: inline-block !important;
  width: unset !important; }
  .template-message-upload .replace-media label {
    margin: 0; }
  .template-message-upload .replace-media button {
    font-size: 12px;
    letter-spacing: -0.4px;
    border-radius: 4px;
    padding: 2px 12px 2px 8px; }

.template-message-upload .template-message-upload-preview {
  padding: 12px 0; }
  .template-message-upload .template-message-upload-preview .preview-document {
    font-size: 14px;
    letter-spacing: -0.2px;
    color: #191919; }
    .template-message-upload .template-message-upload-preview .preview-document .lds-ring {
      width: 14px;
      height: 14px;
      top: 2px;
      left: unset; }
      .template-message-upload .template-message-upload-preview .preview-document .lds-ring div {
        width: 14px;
        height: 14px;
        border: solid 2px var(--main-dark-orange);
        border-color: var(--main-dark-orange) transparent transparent transparent;
        margin: 0; }
    .template-message-upload .template-message-upload-preview .preview-document svg {
      position: relative;
      margin-right: 4px;
      top: -2px;
      color: #ff7d00;
      font-size: 18px; }
  .template-message-upload .template-message-upload-preview .image-video-wrapper {
    width: 48px;
    height: 48px;
    position: relative;
    border-radius: 6px;
    overflow: hidden; }
    .template-message-upload .template-message-upload-preview .image-video-wrapper .progress-wrapper {
      position: absolute;
      top: 0;
      left: 0;
      background: rgba(25, 25, 25, 0.4);
      width: 100%;
      height: 100%; }
      .template-message-upload .template-message-upload-preview .image-video-wrapper .progress-wrapper .lds-ring {
        width: 38px;
        height: 38px;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%); }
        .template-message-upload .template-message-upload-preview .image-video-wrapper .progress-wrapper .lds-ring div {
          width: 38px;
          height: 38px;
          border: solid 5px #fff;
          border-color: #fff transparent transparent transparent;
          margin: 0; }
      .template-message-upload .template-message-upload-preview .image-video-wrapper .progress-wrapper .CircularProgressbar {
        width: calc(100% - 12px);
        height: calc(100% - 12px);
        position: absolute;
        top: 6px;
        left: 6px; }
        .template-message-upload .template-message-upload-preview .image-video-wrapper .progress-wrapper .CircularProgressbar .CircularProgressbar-trail {
          stroke: transparent;
          stroke-width: 20; }
        .template-message-upload .template-message-upload-preview .image-video-wrapper .progress-wrapper .CircularProgressbar .CircularProgressbar-path {
          stroke: #ffffff; }
    .template-message-upload .template-message-upload-preview .image-video-wrapper img {
      width: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%); }
    .template-message-upload .template-message-upload-preview .image-video-wrapper video {
      width: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%); }

.view-message-template-whatsapp .carousel-card-wrapper {
  padding: 12px;
  border-radius: 8px;
  background: rgba(25, 25, 25, 0.05);
  border: solid 1px rgba(25, 25, 25, 0.1);
  margin-top: 8px; }
  .view-message-template-whatsapp .carousel-card-wrapper .card-title {
    font-size: 16px;
    letter-spacing: -0.5px;
    color: rgba(25, 25, 25, 0.8); }
  .view-message-template-whatsapp .carousel-card-wrapper .card-label {
    font-size: 16px;
    letter-spacing: -0.5px;
    color: rgba(25, 25, 25, 0.8);
    margin: 16px 0 8px 0; }
  .view-message-template-whatsapp .carousel-card-wrapper .disabled-form div {
    width: 100%; }
  .view-message-template-whatsapp .carousel-card-wrapper .wrapper-button-action {
    background: rgba(25, 25, 25, 0.05); }
  .view-message-template-whatsapp .carousel-card-wrapper .box-value {
    border: solid 1px rgba(25, 25, 25, 0.2) !important; }

.view-message-template-whatsapp .sample_url_wrapper {
  width: 100%;
  position: relative;
  padding-left: 40px; }
  .view-message-template-whatsapp .sample_url_wrapper b {
    color: rgba(25, 25, 25, 0.6);
    font-size: 14px !important;
    letter-spacing: -0.4px !important;
    position: absolute;
    left: 0;
    top: 11px; }
  .view-message-template-whatsapp .sample_url_wrapper .box-value {
    height: 45px;
    padding: 12px 16px;
    border-radius: 8px;
    font-size: 14px;
    letter-spacing: -0.2px;
    border: solid 1px rgba(25, 25, 25, 0.05);
    color: rgba(25, 25, 25, 0.6);
    width: calc(100% - 40px); }

.view-message-template-whatsapp .wrapper-button-action {
  background: #f3f3f3;
  border-radius: 8px;
  padding: 12px;
  color: rgba(25, 25, 25, 0.6);
  font-size: 16px;
  letter-spacing: -0.3px;
  margin-top: 8px;
  border: solid 1px rgba(25, 25, 25, 0.05); }
  .view-message-template-whatsapp .wrapper-button-action label {
    color: rgba(25, 25, 25, 0.8) !important;
    font-size: 14px !important;
    letter-spacing: -0.3px !important;
    margin-bottom: 4px; }
  .view-message-template-whatsapp .wrapper-button-action p {
    margin-bottom: 16px !important; }
    .view-message-template-whatsapp .wrapper-button-action p svg {
      font-size: 16px;
      position: relative;
      margin-right: 2px !important; }
    .view-message-template-whatsapp .wrapper-button-action p:nth-last-child(1) {
      margin-bottom: 0 !important; }

.view-message-template-whatsapp .marginTop24 {
  margin-top: 24px !important; }

.view-message-template-whatsapp .sample-param-input-wrapper {
  padding-top: 8px; }
  .view-message-template-whatsapp .sample-param-input-wrapper p {
    width: 150px;
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: 14px;
    letter-spacing: -0.4px;
    display: inline-block;
    padding-right: 8px;
    padding-top: 11px;
    margin: 0; }
  .view-message-template-whatsapp .sample-param-input-wrapper input {
    width: calc(100% - 150px) !important;
    display: inline-block !important;
    border: solid 1px #dcdcdc;
    border-radius: 8px;
    vertical-align: top;
    height: 45px;
    padding: 0 12px; }
  .view-message-template-whatsapp .sample-param-input-wrapper.paddingTop0 {
    padding-top: 0 !important; }
  .view-message-template-whatsapp .sample-param-input-wrapper.paddingBottom24 {
    padding-bottom: 24 !important; }

.view-message-template-whatsapp .view-message-template-whatsapp-top {
  padding: 10px 0 15px;
  border-bottom: solid 1px #dcdcdc; }
  .view-message-template-whatsapp .view-message-template-whatsapp-top b {
    font-size: 20px !important;
    letter-spacing: -0.6px;
    font-weight: 700; }

.view-message-template-whatsapp div p,
.view-message-template-whatsapp div b {
  font-size: 14px;
  word-break: break-word; }

.view-message-template-whatsapp div .disabled-form div {
  background-color: rgba(25, 25, 25, 0.05);
  border: 1px solid rgba(25, 25, 25, 0.1);
  border-radius: 8px;
  padding: 12px 16px;
  width: 400px;
  margin: 8.5px 0 0 0;
  word-break: break-word; }
  .view-message-template-whatsapp div .disabled-form div p {
    font-size: 16px;
    margin: 0;
    color: rgba(25, 25, 25, 0.6); }

.view-message-template-whatsapp div:first-child {
  margin-bottom: 24px; }
  .view-message-template-whatsapp div:first-child b {
    font-size: 16px; }
  .view-message-template-whatsapp div:first-child svg {
    margin-right: 5px;
    vertical-align: -2.4px; }
  .view-message-template-whatsapp div:first-child img {
    margin-right: 5px; }

.view-message-template-whatsapp .view-message-template-whatsapp-middle {
  padding: 0 0 24px;
  border-bottom: solid 1px #dcdcdc; }
  .view-message-template-whatsapp .view-message-template-whatsapp-middle .view-message-template-whatsapp-middle-category p {
    margin-bottom: 0; }

.view-message-template-whatsapp .view-message-template-whatsapp-bottom {
  padding: 24px 0 0; }
  .view-message-template-whatsapp .view-message-template-whatsapp-bottom .view-message-box {
    position: relative;
    width: 100%; }
  .view-message-template-whatsapp .view-message-template-whatsapp-bottom .view-message-box-left {
    display: inline-block;
    vertical-align: top;
    width: 400px; }
  .view-message-template-whatsapp .view-message-template-whatsapp-bottom .view-message-box-right {
    width: calc(100% - 400px);
    vertical-align: top;
    display: inline-block;
    padding-left: 24px; }

.view-message-template-whatsapp div:last-child .view-message-template-whatsapp-bottom-footer p {
  margin-bottom: 0; }

.view-message-template-whatsapp .icon-wrapper {
  display: inline-block;
  margin-right: 6px;
  border-radius: 50%;
  width: 28px;
  height: 28px;
  position: relative;
  vertical-align: top;
  margin-bottom: 0 !important; }
  .view-message-template-whatsapp .icon-wrapper svg {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }

.view-message-template-whatsapp .header-image {
  line-height: 26px;
  font-size: 14px;
  letter-spacing: -0.2px; }
  .view-message-template-whatsapp .header-image .icon-wrapper {
    background: #ECFFE9;
    color: #19C700;
    display: inline-block; }

.view-message-template-whatsapp .header-video {
  line-height: 26px;
  font-size: 14px;
  letter-spacing: -0.2px; }
  .view-message-template-whatsapp .header-video .icon-wrapper {
    color: #FF3F57;
    background: #ffecce;
    display: inline-block; }

.view-message-template-whatsapp .header-document {
  line-height: 26px;
  font-size: 14px;
  letter-spacing: -0.2px; }
  .view-message-template-whatsapp .header-document .icon-wrapper {
    background: #EBF7FF;
    color: #3BAEFF;
    display: inline-block; }

.view-request-message-template-whatsapp .carousel-card-wrapper {
  padding: 12px;
  border-radius: 8px;
  background: rgba(25, 25, 25, 0.05);
  border: solid 1px rgba(25, 25, 25, 0.1);
  margin-top: 8px; }
  .view-request-message-template-whatsapp .carousel-card-wrapper .card-title {
    font-size: 16px;
    letter-spacing: -0.5px;
    color: rgba(25, 25, 25, 0.8); }
  .view-request-message-template-whatsapp .carousel-card-wrapper .card-label {
    font-size: 16px;
    letter-spacing: -0.5px;
    color: rgba(25, 25, 25, 0.8);
    margin: 16px 0 8px 0; }
  .view-request-message-template-whatsapp .carousel-card-wrapper .disabled-form div {
    width: 100%; }
  .view-request-message-template-whatsapp .carousel-card-wrapper .wrapper-button-action {
    background: rgba(25, 25, 25, 0.05); }
  .view-request-message-template-whatsapp .carousel-card-wrapper .box-value {
    border: solid 1px rgba(25, 25, 25, 0.2) !important; }

.view-request-message-template-whatsapp .sample_url_wrapper {
  width: 100%;
  position: relative;
  padding-left: 40px; }
  .view-request-message-template-whatsapp .sample_url_wrapper b {
    color: rgba(25, 25, 25, 0.6);
    font-size: 14px !important;
    letter-spacing: -0.4px !important;
    position: absolute;
    left: 0;
    top: 11px; }
  .view-request-message-template-whatsapp .sample_url_wrapper .box-value {
    height: 45px;
    padding: 12px 16px;
    border-radius: 8px;
    font-size: 14px;
    letter-spacing: -0.2px;
    border: solid 1px rgba(25, 25, 25, 0.05);
    color: rgba(25, 25, 25, 0.6);
    width: calc(100%);
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden; }

.view-request-message-template-whatsapp .marginTop24 {
  margin-top: 24px !important; }

.view-request-message-template-whatsapp .wrapper-button-action {
  background: #f3f3f3;
  border-radius: 8px;
  padding: 12px;
  color: rgba(25, 25, 25, 0.6);
  font-size: 16px;
  letter-spacing: -0.3px;
  margin-top: 8px;
  border: solid 1px rgba(25, 25, 25, 0.05); }
  .view-request-message-template-whatsapp .wrapper-button-action label {
    color: rgba(25, 25, 25, 0.8) !important;
    font-size: 14px !important;
    letter-spacing: -0.3px !important;
    margin-bottom: 4px; }
  .view-request-message-template-whatsapp .wrapper-button-action p {
    margin-bottom: 16px !important; }
    .view-request-message-template-whatsapp .wrapper-button-action p svg {
      font-size: 16px;
      position: relative;
      margin-right: 2px !important; }
    .view-request-message-template-whatsapp .wrapper-button-action p:nth-last-child(1) {
      margin-bottom: 0 !important; }

.view-request-message-template-whatsapp .sample-param-input-wrapper {
  padding-top: 8px; }
  .view-request-message-template-whatsapp .sample-param-input-wrapper p {
    width: 150px;
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: 14px;
    letter-spacing: -0.4px;
    display: inline-block;
    padding-right: 8px;
    padding-top: 11px;
    margin: 0;
    color: rgba(25, 25, 25, 0.6); }
  .view-request-message-template-whatsapp .sample-param-input-wrapper input {
    width: calc(100% - 150px) !important;
    display: inline-block !important;
    border: solid 1px #dcdcdc;
    border-radius: 8px;
    vertical-align: top;
    height: 45px;
    padding: 0 12px; }
  .view-request-message-template-whatsapp .sample-param-input-wrapper.paddingTop0 {
    padding-top: 0 !important; }
  .view-request-message-template-whatsapp .sample-param-input-wrapper.paddingBottom24 {
    padding-bottom: 24 !important; }

.view-request-message-template-whatsapp .view-request-message-template-whatsapp-top {
  padding: 10px 0 15px;
  border-bottom: solid 1px #dcdcdc; }
  .view-request-message-template-whatsapp .view-request-message-template-whatsapp-top b {
    font-size: 20px !important;
    letter-spacing: -0.6px;
    font-weight: 700; }

.view-request-message-template-whatsapp div p,
.view-request-message-template-whatsapp div b {
  font-size: 14px;
  word-break: break-word; }

.view-request-message-template-whatsapp div .disabled-form div {
  background-color: rgba(25, 25, 25, 0.05);
  border: 1px solid rgba(25, 25, 25, 0.1);
  border-radius: 8px;
  padding: 12px 16px;
  width: 400px;
  margin: 8.5px 0 0 0;
  word-break: break-word;
  color: rgba(25, 25, 25, 0.6); }
  .view-request-message-template-whatsapp div .disabled-form div p {
    font-size: 16px;
    margin: 0; }

.view-request-message-template-whatsapp div:first-child {
  margin-bottom: 24px; }
  .view-request-message-template-whatsapp div:first-child b {
    font-size: 16px; }
  .view-request-message-template-whatsapp div:first-child svg {
    margin-right: 5px;
    vertical-align: -2.4px; }
  .view-request-message-template-whatsapp div:first-child img {
    margin-right: 5px; }

.view-request-message-template-whatsapp .view-request-message-template-whatsapp-middle {
  padding: 0 0 24px;
  border-bottom: solid 1px #dcdcdc; }

.view-request-message-template-whatsapp .view-request-message-template-whatsapp-bottom {
  padding: 24px 0 0; }
  .view-request-message-template-whatsapp .view-request-message-template-whatsapp-bottom .view-message-box {
    position: relative;
    width: 100%; }
  .view-request-message-template-whatsapp .view-request-message-template-whatsapp-bottom .view-message-box-left {
    display: inline-block;
    vertical-align: top;
    width: 400px; }
  .view-request-message-template-whatsapp .view-request-message-template-whatsapp-bottom .view-message-box-right {
    width: calc(100% - 400px);
    vertical-align: top;
    display: inline-block;
    padding-left: 24px; }

.view-request-message-template-whatsapp div:last-child .view-request-message-template-whatsapp-bottom-footer p {
  margin-bottom: 0; }

.view-request-message-template-whatsapp .icon-wrapper {
  display: inline-block;
  margin-right: 6px;
  border-radius: 50%;
  width: 28px;
  height: 28px;
  position: relative;
  vertical-align: top;
  margin-bottom: 0 !important; }
  .view-request-message-template-whatsapp .icon-wrapper svg {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }

.view-request-message-template-whatsapp .media-type {
  font-size: 14px;
  letter-spacing: -0.2px;
  margin: 0; }

.view-request-message-template-whatsapp .header-wrap {
  border: solid 1px #dcdcdc;
  display: inline-block;
  border-radius: 8px;
  padding: 10px;
  min-width: 120px;
  margin: 8px 0 0; }

.view-request-message-template-whatsapp .header-image {
  line-height: 26px;
  font-size: 14px;
  letter-spacing: -0.2px; }
  .view-request-message-template-whatsapp .header-image .icon-wrapper {
    background: #ECFFE9;
    color: #19C700; }

.view-request-message-template-whatsapp .header-video {
  line-height: 26px;
  font-size: 14px;
  letter-spacing: -0.2px; }
  .view-request-message-template-whatsapp .header-video .icon-wrapper {
    color: #FF3F57;
    background: #ffecce; }

.view-request-message-template-whatsapp .header-document {
  line-height: 26px;
  font-size: 14px;
  letter-spacing: -0.2px; }
  .view-request-message-template-whatsapp .header-document .icon-wrapper {
    background: #EBF7FF;
    color: #3BAEFF; }

.chatbot-add-chatbot {
  padding: 32px 30px;
  text-align: left; }
  .chatbot-add-chatbot .comp-back-button {
    margin: 0 0 16px 0; }
  .chatbot-add-chatbot .alert-component {
    margin-top: 36px; }
  .chatbot-add-chatbot .chatbot-add-chatbot-box {
    background: #ffffff;
    border-radius: 8px;
    padding: 32px;
    border: solid 1px rgba(25, 25, 25, 0.1); }
    .chatbot-add-chatbot .chatbot-add-chatbot-box .chatbot-add-chatbot-box-top {
      padding-bottom: 15px;
      border-bottom: solid 1px #dcdcdc;
      position: relative;
      font-size: 20px;
      letter-spacing: -0.6px; }
      .chatbot-add-chatbot .chatbot-add-chatbot-box .chatbot-add-chatbot-box-top .chat-bot-chatbot-button-wrapper {
        margin: 0; }
      .chatbot-add-chatbot .chatbot-add-chatbot-box .chatbot-add-chatbot-box-top button {
        position: absolute;
        top: -6px;
        right: 0;
        margin: 0 !important;
        font-size: 16px;
        letter-spacing: -0.6px; }
    .chatbot-add-chatbot .chatbot-add-chatbot-box .chatbot-add-chatbot-box-form {
      width: 320px; }
      .chatbot-add-chatbot .chatbot-add-chatbot-box .chatbot-add-chatbot-box-form label {
        padding: 24px 0 0;
        font-size: 14px;
        letter-spacing: -0.4px;
        display: block; }
      .chatbot-add-chatbot .chatbot-add-chatbot-box .chatbot-add-chatbot-box-form input[type="text"] {
        padding: 12px 16px;
        border-radius: 6px;
        border: solid 1px #dcdcdc;
        width: 100%;
        font-size: 16px;
        letter-spacing: -0.3px;
        display: block;
        margin-bottom: 8px; }
      .chatbot-add-chatbot .chatbot-add-chatbot-box .chatbot-add-chatbot-box-form .integration-info-form {
        font-size: 14px;
        color: rgba(25, 25, 25, 0.8);
        letter-spacing: -0.2px; }
      .chatbot-add-chatbot .chatbot-add-chatbot-box .chatbot-add-chatbot-box-form .custom-select-path .custom-select-option-value-wrapper {
        padding: 12px 16px !important;
        margin-bottom: 8px; }
    .chatbot-add-chatbot .chatbot-add-chatbot-box .chat-bot-chatbot-button-wrapper {
      margin-top: 18px; }
      .chatbot-add-chatbot .chatbot-add-chatbot-box .chat-bot-chatbot-button-wrapper button {
        margin: 0 4px 0 0; }


.api-key-main-wrapper .btn {
  border: 0; }

.api-key-main-wrapper tBody .col_key {
  color: #ff7e00;
  cursor: pointer; }
  .api-key-main-wrapper tBody .col_key svg {
    color: #ff7e00;
    position: relative;
    top: -2px;
    right: unset;
    margin-right: 4px;
    font-size: 16px; }
  .api-key-main-wrapper tBody .col_key:hover {
    color: var(--main-darker-orange); }
    .api-key-main-wrapper tBody .col_key:hover svg {
      color: var(--main-darker-orange); }

.api-key-list-title {
  color: #191919;
  font-size: 20px;
  letter-spacing: -0.6px;
  line-height: 24px;
  padding-bottom: 16px;
  border-bottom: solid 1px rgba(25, 25, 25, 0.1); }

.api-key-list-tool-wrapper {
  width: 100%;
  position: relative;
  margin-top: 24px;
  margin-bottom: 32px; }

.api-key-list-info {
  font-size: 16px;
  letter-spacing: -0.3px;
  color: rgba(25, 25, 25, 0.8);
  line-height: 1.5;
  padding: 0 208px 18px 0;
  margin: 0 0 24px 0;
  border-bottom: solid 1px #dcdcdc; }

.api-key-list-tool-wrapper button.button-invite-member {
  position: absolute;
  right: 0;
  top: 50%;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%);
  padding: 11px 0;
  width: 200px; }

.api-key-list-tool-wrapper button.button-invite-member svg {
  position: relative;
  top: -1px;
  margin-right: 5px; }

.api-key-list-table-wrapper {
  width: 100%;
  position: relative; }

.table-api-key-list thead tr {
  background: #ffffff; }

.table-api-key-list tbody td {
  background: #ffffff;
  position: relative; }

.table-api-key-list button svg {
  position: relative !important;
  right: unset !important;
  top: -2px !important;
  margin-right: 5px; }

.table-api-key-list .col-action-button-wrapper {
  position: absolute;
  right: 7px;
  top: 50%;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%);
  /* display: none; */ }

.table-api-key-list .single-remove-button {
  margin-left: 16px;
  color: #ff3f57; }

.table-api-key-list .single-remove-button:hover {
  color: #e83b51; }

.table-api-key-list .shortcut-badge {
  font-size: 12px;
  color: rgba(25, 25, 25, 0.6);
  letter-spacing: -0.4px;
  background: #f3f3f3;
  line-height: 1;
  border-radius: 4px;
  padding: 1px 8px 3px; }

.table-api-key-list td {
  max-width: unset !important; }

.table-api-key-list .col-action-button-wrapper {
  display: block !important;
  position: relative;
  top: 0;
  right: unset;
  -webkit-transform: unset;
          transform: unset; }

.table-api-key-list .col-action-button-wrapper .view-agent {
  color: #ff7d00;
  letter-spacing: -0.5px; }

.table-api-key-list .col-action-button-wrapper .view-agent:hover {
  color: var(--main-darker-orange); }

.table-api-key-list .col-action-button-wrapper img.view-agent {
  margin-right: 3px;
  position: relative;
  top: -1px; }

.member-tool-wrapper {
  width: 460px; }

.table-api-key-list .col_action {
  width: 200px;
  text-align: right; }

.modal-view-key .modal-content {
  width: 476px;
  border-radius: 8px; }
  .modal-view-key .modal-content .modal-body {
    text-align: left;
    padding: 32px; }
    .modal-view-key .modal-content .modal-body .disabled-form {
      position: relative; }
      .modal-view-key .modal-content .modal-body .disabled-form p {
        top: 0;
        left: 0;
        border: 1px solid rgba(25, 25, 25, 0.1) !important;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding: 0 46px 0 16px;
        border-radius: 8px;
        background: #f3f3f3;
        margin: 0;
        position: absolute;
        width: 100%;
        height: 100%;
        padding-top: 10px; }
    .modal-view-key .modal-content .modal-body .modal-title {
      font-size: 24px;
      line-height: 28px;
      letter-spacing: -0.6px;
      margin-bottom: 8px;
      margin: 0 0 24px 0; }
      .modal-view-key .modal-content .modal-body .modal-title svg {
        margin-right: 4px;
        position: relative;
        top: -1px; }
    .modal-view-key .modal-content .modal-body .disabled-form {
      width: 100%; }
      .modal-view-key .modal-content .modal-body .disabled-form input {
        width: 100%; }
    .modal-view-key .modal-content .modal-body button {
      width: 100%;
      margin-top: 20px; }

.form-add-api-key .orange-button {
  margin-top: 32px;
  width: unset !important; }

.form-add-api-key .description-wrapper {
  position: relative;
  display: inline-block;
  font-size: 14px;
  letter-spacing: -0.2px;
  color: rgba(25, 25, 25, 0.6); }
  .form-add-api-key .description-wrapper span {
    position: absolute;
    right: 0;
    top: -25px; }

.chatbot-main-wrapper .btn {
  border: 0; }

.chatbot-main-wrapper tBody .col_key {
  color: rgba(25, 25, 25, 0.8);
  cursor: pointer; }
  .chatbot-main-wrapper tBody .col_key svg {
    color: rgba(25, 25, 25, 0.8);
    position: relative;
    top: -2px;
    right: unset;
    margin-right: 4px;
    font-size: 16px; }
  .chatbot-main-wrapper tBody .col_key:hover {
    color: #ff7e00; }
    .chatbot-main-wrapper tBody .col_key:hover svg {
      color: #ff7e00; }

.chatbot-main-wrapper tBody .orange-link-text svg {
  right: unset !important; }

.chatbot-main-wrapper .search-and-filter-wrapper {
  width: 100%;
  display: block;
  margin: 12px 0;
  position: relative; }
  .chatbot-main-wrapper .search-and-filter-wrapper .search-box-wrapper {
    width: 370px; }
  .chatbot-main-wrapper .search-and-filter-wrapper .dropdown-filter-status {
    display: inline-block;
    position: absolute;
    left: 382px;
    top: 0; }

.chatbot-list-title {
  color: #191919;
  font-size: 20px;
  letter-spacing: -0.6px;
  line-height: 24px;
  padding-bottom: 16px;
  border-bottom: solid 1px rgba(25, 25, 25, 0.1); }

.chatbot-list-tool-wrapper {
  width: 100%;
  position: relative;
  margin-top: 24px;
  margin-bottom: 32px; }

.chatbot-list-info {
  font-size: 16px;
  letter-spacing: -0.3px;
  color: rgba(25, 25, 25, 0.8);
  line-height: 1.5;
  padding: 0 208px 18px 0;
  border-bottom: solid 1px #dcdcdc;
  margin: 0 0 24px; }

.chatbot-list-tool-wrapper button.button-invite-member {
  position: absolute;
  right: 0;
  top: 50%;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%);
  padding: 11px 0;
  width: 200px; }

.chatbot-list-tool-wrapper button.button-invite-member svg {
  position: relative;
  top: -1px;
  margin-right: 5px; }

.chatbot-list-table-wrapper {
  width: 100%;
  position: relative; }

.table-chatbot-list thead tr {
  background: #ffffff; }

.table-chatbot-list tbody td {
  background: #ffffff;
  position: relative; }

.table-chatbot-list button svg {
  position: relative !important;
  right: unset !important;
  top: -2px !important;
  margin-right: 5px; }

.table-chatbot-list .col-action-button-wrapper {
  position: absolute;
  right: 7px;
  top: 50%;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%);
  /* display: none; */ }

.table-chatbot-list .single-remove-button {
  margin-left: 16px;
  color: #ff3f57; }

.table-chatbot-list .single-remove-button:hover {
  color: #e83b51; }

.table-chatbot-list .shortcut-badge {
  font-size: 12px;
  color: rgba(25, 25, 25, 0.6);
  letter-spacing: -0.4px;
  background: #f3f3f3;
  line-height: 1;
  border-radius: 4px;
  padding: 1px 8px 3px; }

.table-chatbot-list td {
  max-width: unset !important; }

.table-chatbot-list .col-action-button-wrapper {
  display: block !important;
  position: relative;
  top: 0;
  right: unset;
  -webkit-transform: unset;
          transform: unset; }

.table-chatbot-list .col-action-button-wrapper .view-agent {
  color: #ff7d00;
  letter-spacing: -0.5px; }

.table-chatbot-list .col-action-button-wrapper .view-agent:hover {
  color: var(--main-darker-orange); }

.table-chatbot-list .col-action-button-wrapper img.view-agent {
  margin-right: 3px;
  position: relative;
  top: -1px; }

.member-tool-wrapper {
  width: 460px; }

.table-chatbot-list tr {
  cursor: pointer; }

.table-chatbot-list tr:hover .orange-link-text {
  color: var(--main-darker-orange) !important; }
  .table-chatbot-list tr:hover .orange-link-text svg {
    color: var(--main-darker-orange) !important; }

.table-chatbot-list .col_action {
  width: 200px;
  text-align: right; }

.table-chatbot-list .col_status b {
  margin: 0;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.5px;
  color: #191919; }
  .table-chatbot-list .col_status b svg, .table-chatbot-list .col_status b img {
    margin-right: 4px;
    width: 20px;
    height: 20px;
    -webkit-transform: translateY(-1.5px);
            transform: translateY(-1.5px);
    position: relative !important;
    top: unset !important;
    right: unset !important; }
  .table-chatbot-list .col_status b svg {
    position: unset; }

.table-chatbot-list .col_status .status-not-paid {
  color: #FF3F57; }

.table-chatbot-list .col_status .status-waiting-setup {
  color: #FF7E00; }

.table-chatbot-list .col_status .status-provisioning {
  color: #2085CC; }

.table-chatbot-list .col_status .status-active {
  color: #19C700; }

.table-chatbot-list .col_status .status-others {
  color: #19191999; }
  .table-chatbot-list .col_status .status-others svg {
    color: #19191999; }

.modal-view-chatbot .modal-content {
  width: 476px;
  border-radius: 8px; }
  .modal-view-chatbot .modal-content .modal-body {
    text-align: left;
    padding: 32px; }
    .modal-view-chatbot .modal-content .modal-body .disabled-form {
      position: relative; }
      .modal-view-chatbot .modal-content .modal-body .disabled-form p {
        top: 0;
        left: 0;
        border: 1px solid rgba(25, 25, 25, 0.1) !important;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding: 0 46px 0 16px;
        border-radius: 8px;
        background: #f3f3f3;
        margin: 0;
        position: absolute;
        width: 100%;
        height: 100%;
        padding-top: 10px; }
    .modal-view-chatbot .modal-content .modal-body .modal-title {
      font-size: 24px;
      line-height: 28px;
      letter-spacing: -0.6px;
      margin-bottom: 8px;
      margin: 0 0 24px 0; }
      .modal-view-chatbot .modal-content .modal-body .modal-title svg {
        margin-right: 4px;
        position: relative;
        top: -1px; }
    .modal-view-chatbot .modal-content .modal-body .disabled-form {
      width: 100%; }
      .modal-view-chatbot .modal-content .modal-body .disabled-form input {
        width: 100%; }
    .modal-view-chatbot .modal-content .modal-body button {
      width: 100%;
      margin-top: 20px; }

.chatbot-add-chatbot {
  padding: 32px 30px;
  text-align: left; }
  .chatbot-add-chatbot .comp-back-button {
    margin: 0 0 16px 0; }
  .chatbot-add-chatbot .chatbot-add-chatbot-box {
    background: #ffffff;
    border-radius: 8px;
    padding: 32px;
    border: solid 1px rgba(25, 25, 25, 0.1); }
    .chatbot-add-chatbot .chatbot-add-chatbot-box .chatbot-add-chatbot-box-top {
      padding-bottom: 15px;
      border-bottom: solid 1px #dcdcdc;
      position: relative;
      font-size: 20px;
      letter-spacing: -0.6px; }
      .chatbot-add-chatbot .chatbot-add-chatbot-box .chatbot-add-chatbot-box-top p {
        font-size: 16px;
        letter-spacing: -0.3px;
        color: rgba(25, 25, 25, 0.8);
        line-height: 1.5;
        margin: 0; }
      .chatbot-add-chatbot .chatbot-add-chatbot-box .chatbot-add-chatbot-box-top button {
        position: absolute;
        top: -6px;
        right: 0;
        font-size: 16px;
        letter-spacing: -0.6px;
        width: 180px; }
    .chatbot-add-chatbot .chatbot-add-chatbot-box .chatbot-add-chatbot-box-form {
      width: 320px; }
      .chatbot-add-chatbot .chatbot-add-chatbot-box .chatbot-add-chatbot-box-form label {
        padding: 24px 0 0;
        font-size: 14px;
        letter-spacing: -0.4px;
        display: block;
        position: relative; }
        .chatbot-add-chatbot .chatbot-add-chatbot-box .chatbot-add-chatbot-box-form label span {
          position: absolute;
          right: 0; }
      .chatbot-add-chatbot .chatbot-add-chatbot-box .chatbot-add-chatbot-box-form input[type="text"] {
        padding: 12px 16px;
        border-radius: 6px;
        border: solid 1px #dcdcdc;
        width: 100%;
        font-size: 16px;
        letter-spacing: -0.3px;
        display: block;
        margin-bottom: 0; }
      .chatbot-add-chatbot .chatbot-add-chatbot-box .chatbot-add-chatbot-box-form .integration-info-form {
        font-size: 14px;
        color: rgba(25, 25, 25, 0.8);
        letter-spacing: -0.2px; }
      .chatbot-add-chatbot .chatbot-add-chatbot-box .chatbot-add-chatbot-box-form .custom-select-path .custom-select-option-value-wrapper {
        padding: 12px 16px !important;
        margin-bottom: 8px; }
    .chatbot-add-chatbot .chatbot-add-chatbot-box .chat-bot-chatbot-button-wrapper {
      margin-top: 18px; }
      .chatbot-add-chatbot .chatbot-add-chatbot-box .chat-bot-chatbot-button-wrapper button {
        margin: 0 4px 0 0; }

.chatbot-edit-chatbot {
  padding: 32px 30px;
  text-align: left; }
  .chatbot-edit-chatbot .comp-back-button {
    margin: 0 0 16px 0; }
  .chatbot-edit-chatbot .chatbot-edit-chatbot-box {
    background: #ffffff;
    border-radius: 8px;
    padding: 32px;
    margin-bottom: 24px;
    border: solid 1px rgba(25, 25, 25, 0.1); }
    .chatbot-edit-chatbot .chatbot-edit-chatbot-box .chatbot-edit-chatbot-box-top {
      padding-bottom: 15px;
      padding-right: 180px;
      border-bottom: solid 1px #dcdcdc;
      position: relative;
      font-size: 20px;
      letter-spacing: -0.6px; }
      .chatbot-edit-chatbot .chatbot-edit-chatbot-box .chatbot-edit-chatbot-box-top p {
        font-size: 16px;
        letter-spacing: -0.3px;
        color: rgba(25, 25, 25, 0.8);
        line-height: 1.5;
        margin: 0; }
      .chatbot-edit-chatbot .chatbot-edit-chatbot-box .chatbot-edit-chatbot-box-top button {
        position: absolute;
        top: 0;
        right: 0;
        font-size: 16px;
        letter-spacing: -0.6px; }
    .chatbot-edit-chatbot .chatbot-edit-chatbot-box .chatbot-edit-chatbot-box-form {
      width: 320px; }
      .chatbot-edit-chatbot .chatbot-edit-chatbot-box .chatbot-edit-chatbot-box-form label {
        padding: 24px 0 0;
        font-size: 14px;
        letter-spacing: -0.4px;
        display: block;
        position: relative; }
      .chatbot-edit-chatbot .chatbot-edit-chatbot-box .chatbot-edit-chatbot-box-form .chatbot-val {
        font-size: 20px;
        letter-spacing: -0.6px; }
      .chatbot-edit-chatbot .chatbot-edit-chatbot-box .chatbot-edit-chatbot-box-form input[type="text"] {
        padding: 12px 16px;
        border-radius: 6px;
        border: solid 1px #dcdcdc;
        width: 100%;
        font-size: 16px;
        letter-spacing: -0.3px;
        display: block;
        margin-bottom: 0; }
      .chatbot-edit-chatbot .chatbot-edit-chatbot-box .chatbot-edit-chatbot-box-form .integration-info-form {
        font-size: 14px;
        color: rgba(25, 25, 25, 0.8);
        letter-spacing: -0.2px; }
      .chatbot-edit-chatbot .chatbot-edit-chatbot-box .chatbot-edit-chatbot-box-form .custom-select-path .custom-select-option-value-wrapper {
        padding: 12px 16px !important;
        margin-bottom: 8px; }
    .chatbot-edit-chatbot .chatbot-edit-chatbot-box .chat-bot-chatbot-button-wrapper {
      margin-top: 18px; }
      .chatbot-edit-chatbot .chatbot-edit-chatbot-box .chat-bot-chatbot-button-wrapper button {
        margin: 0 4px 0 0; }

.modal-confirmation-delete-path button {
  width: calc(50% - 4px) !important;
  margin-right: 16px; }
  .modal-confirmation-delete-path button:nth-last-child(1) {
    margin-right: 0; }

.view-chatbot-detail .grey-font-6 {
  margin: 0; }

.view-chatbot-detail .flexing {
  display: flex;
  margin: 4px 0 0 !important; }

.view-chatbot-detail .view-chatbot-detail-top {
  padding: 10px 0 16px 0;
  border-bottom: solid 1px #dcdcdc;
  margin-bottom: 24px;
  position: relative; }
  .view-chatbot-detail .view-chatbot-detail-top .subtitle-chatbot {
    color: rgba(25, 25, 25, 0.8);
    font-size: 14px;
    letter-spacing: -0.2px;
    margin: 0 0 0; }
    .view-chatbot-detail .view-chatbot-detail-top .subtitle-chatbot a {
      color: #ff7d00; }
      .view-chatbot-detail .view-chatbot-detail-top .subtitle-chatbot a:hover {
        text-decoration: underline; }
  .view-chatbot-detail .view-chatbot-detail-top button {
    position: absolute;
    right: 0;
    top: 0px;
    width: 180px; }
    .view-chatbot-detail .view-chatbot-detail-top button b {
      color: #fff !important;
      font-size: 16px !important;
      letter-spacing: -0.5px !important; }
    .view-chatbot-detail .view-chatbot-detail-top button.edit-button b {
      color: #ff7d00 !important; }
    .view-chatbot-detail .view-chatbot-detail-top button.edit-top b {
      color: #ff7d00 !important;
      font-size: 16px !important;
      letter-spacing: -0.5px !important; }
    .view-chatbot-detail .view-chatbot-detail-top button.edit-top.edit-button b {
      color: var(--main-darker-orange) !important; }
    .view-chatbot-detail .view-chatbot-detail-top button.view-detail-top b {
      color: var(--main-dark-orange) !important; }
    .view-chatbot-detail .view-chatbot-detail-top button.left-button {
      right: 188px; }
  .view-chatbot-detail .view-chatbot-detail-top b {
    font-size: 20px !important;
    letter-spacing: -0.6px;
    font-weight: 700; }
  .view-chatbot-detail .view-chatbot-detail-top .button-export {
    position: absolute;
    right: 0;
    bottom: 14px; }
    .view-chatbot-detail .view-chatbot-detail-top .button-export b {
      color: #ff7d00 !important;
      font-size: 16px !important; }
  .view-chatbot-detail .view-chatbot-detail-top .button-delete {
    position: absolute;
    right: 0;
    bottom: 14px; }
    .view-chatbot-detail .view-chatbot-detail-top .button-delete b {
      color: #fff !important;
      font-size: 16px !important; }
  .view-chatbot-detail .view-chatbot-detail-top .button-edit {
    position: absolute;
    right: 134px;
    bottom: 14px; }
    .view-chatbot-detail .view-chatbot-detail-top .button-edit b {
      color: #ff7d00 !important;
      font-size: 16px !important; }
  .view-chatbot-detail .view-chatbot-detail-top span {
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.5px;
    color: rgba(25, 25, 25, 0.4);
    position: relative;
    top: 2px; }

.view-chatbot-detail .colored-info-box p {
  color: #ffffff;
  margin-top: 0 !important; }

.view-chatbot-detail div p,
.view-chatbot-detail div b {
  font-size: 14px;
  letter-spacing: -0.4px;
  color: rgba(0, 0, 0, 0.8);
  font-family: PTRootUI !important; }
  .view-chatbot-detail div p.yes-text,
  .view-chatbot-detail div b.yes-text {
    color: #19C700; }
  .view-chatbot-detail div p.no-text,
  .view-chatbot-detail div b.no-text {
    color: #E02E2E; }

.view-chatbot-detail div p {
  letter-spacing: -0.2px; }

.view-chatbot-detail div b {
  font-weight: 700 !important;
  vertical-align: top; }

.view-chatbot-detail div .disabled-form div {
  background-color: rgba(25, 25, 25, 0.05);
  border: 1px solid rgba(25, 25, 25, 0.1);
  border-radius: 8px;
  padding: 12px 16px;
  width: 400px;
  margin: 8.5px 0 24px 0;
  word-break: break-word; }
  .view-chatbot-detail div .disabled-form div p {
    font-size: 16px; }

.view-chatbot-detail .view-chatbot-detail-middle {
  padding: 0 0 16px; }
  .view-chatbot-detail .view-chatbot-detail-middle .detail-label {
    font-size: 16px;
    letter-spacing: -0.5px; }
  .view-chatbot-detail .view-chatbot-detail-middle .detail-value {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.3px;
    color: #191919; }
  .view-chatbot-detail .view-chatbot-detail-middle .detail-value-no-data {
    color: #19191966; }
  .view-chatbot-detail .view-chatbot-detail-middle .detail-status {
    display: block;
    margin-top: 4px;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.5px; }
    .view-chatbot-detail .view-chatbot-detail-middle .detail-status svg, .view-chatbot-detail .view-chatbot-detail-middle .detail-status img {
      width: 20px;
      height: 20px;
      top: 1.5px;
      vertical-align: top; }
  .view-chatbot-detail .view-chatbot-detail-middle .status-not-paid {
    color: #FF3F57; }
  .view-chatbot-detail .view-chatbot-detail-middle .status-waiting-setup {
    color: #FF7E00; }
  .view-chatbot-detail .view-chatbot-detail-middle .status-provisioning {
    color: #2085CC; }
    .view-chatbot-detail .view-chatbot-detail-middle .status-provisioning .provisioning-wrapper {
      display: inline-block; }
      .view-chatbot-detail .view-chatbot-detail-middle .status-provisioning .provisioning-wrapper b {
        color: #2085CC;
        font-size: 16px !important;
        line-height: 24px;
        letter-spacing: -0.5px !important; }
      .view-chatbot-detail .view-chatbot-detail-middle .status-provisioning .provisioning-wrapper p {
        color: #2085CC;
        margin: 0;
        font-size: 14px;
        line-height: 21px;
        letter-spacing: -0.2px; }
  .view-chatbot-detail .view-chatbot-detail-middle .status-active {
    color: #19C700; }
  .view-chatbot-detail .view-chatbot-detail-middle .status-others {
    color: #19191999; }
    .view-chatbot-detail .view-chatbot-detail-middle .status-others svg {
      margin: 0 4px 0 0;
      top: 2px;
      color: #19191999; }
  .view-chatbot-detail .view-chatbot-detail-middle .error-or-info-comp svg {
    top: 1px; }
  .view-chatbot-detail .view-chatbot-detail-middle.view-chatbot-detail-middle-top {
    max-width: 100%; }
    .view-chatbot-detail .view-chatbot-detail-middle.view-chatbot-detail-middle-top .chatbot-detail-topics {
      border-radius: 4px;
      background: rgba(25, 25, 25, 0.05);
      max-width: 150px;
      white-space: nowrap;
      margin: 0 4px 4px 0;
      text-overflow: ellipsis;
      overflow: hidden;
      vertical-align: top;
      text-align: center;
      padding: 6px 8px;
      line-height: 1;
      display: inline-block; }
      .view-chatbot-detail .view-chatbot-detail-middle.view-chatbot-detail-middle-top .chatbot-detail-topics b {
        color: rgba(25, 25, 25, 0.6);
        letter-spacing: -0.4px;
        font-size: 12px; }
    .view-chatbot-detail .view-chatbot-detail-middle.view-chatbot-detail-middle-top p b {
      letter-spacing: -0.6px !important;
      font-size: 20px !important; }
  .view-chatbot-detail .view-chatbot-detail-middle .col_action {
    width: 70px; }
    .view-chatbot-detail .view-chatbot-detail-middle .col_action .orange-link-text svg {
      top: 2px !important; }
  .view-chatbot-detail .view-chatbot-detail-middle.message-number-wrapper {
    border-bottom: solid 1px #dcdcdc;
    margin-bottom: 10px; }
    .view-chatbot-detail .view-chatbot-detail-middle.message-number-wrapper:nth-last-child(1) {
      margin-bottom: 0;
      border-bottom: 0; }
  .view-chatbot-detail .view-chatbot-detail-middle > div svg {
    margin-right: 5px;
    vertical-align: top;
    position: relative;
    top: 4px; }
  .view-chatbot-detail .view-chatbot-detail-middle > div img {
    margin-right: 5px;
    margin-bottom: 4px;
    position: relative;
    top: -1px; }
    .view-chatbot-detail .view-chatbot-detail-middle > div img.label-icon {
      margin-bottom: 0;
      position: relative;
      vertical-align: top;
      top: 2px; }
    .view-chatbot-detail .view-chatbot-detail-middle > div img.channel-icon {
      margin: 0 5px 0 0 !important;
      position: relative;
      top: 2px; }
  .view-chatbot-detail .view-chatbot-detail-middle p {
    margin: 8px 0 0;
    word-break: break-word; }
  .view-chatbot-detail .view-chatbot-detail-middle .view-chatbot-detail-middle-category p {
    margin-bottom: 0; }
  .view-chatbot-detail .view-chatbot-detail-middle .view-chatbot-detail-middle-channel > div {
    margin: 8px 0 0; }
  .view-chatbot-detail .view-chatbot-detail-middle .view-chatbot-detail-middle-status .colored-info-box {
    margin-top: 10px;
    margin-bottom: 1rem; }
    .view-chatbot-detail .view-chatbot-detail-middle .view-chatbot-detail-middle-status .colored-info-box p {
      color: #ffffff;
      margin-top: 0 !important; }
  .view-chatbot-detail .view-chatbot-detail-middle .view-chatbot-detail-middle-content-body {
    max-width: 568px; }
    .view-chatbot-detail .view-chatbot-detail-middle .view-chatbot-detail-middle-content-body > img {
      margin-bottom: 5px !important; }
    .view-chatbot-detail .view-chatbot-detail-middle .view-chatbot-detail-middle-content-body .message-content {
      margin-top: 12px; }
      .view-chatbot-detail .view-chatbot-detail-middle .view-chatbot-detail-middle-content-body .message-content img {
        width: 200px;
        height: 200px;
        object-fit: cover;
        border-radius: 8px;
        cursor: pointer;
        margin: 0 0 8px 0; }
      .view-chatbot-detail .view-chatbot-detail-middle .view-chatbot-detail-middle-content-body .message-content .content-caption {
        padding-top: 10px;
        margin-top: 0 !important; }
    .view-chatbot-detail .view-chatbot-detail-middle .view-chatbot-detail-middle-content-body .file-url {
      cursor: pointer; }
      .view-chatbot-detail .view-chatbot-detail-middle .view-chatbot-detail-middle-content-body .file-url img {
        width: 24px !important;
        height: 24px !important;
        margin-right: 7px; }
      .view-chatbot-detail .view-chatbot-detail-middle .view-chatbot-detail-middle-content-body .file-url div > p {
        margin-top: 2px;
        font-weight: bold;
        font-size: 14px;
        color: #ff7e00;
        line-height: 21px;
        letter-spacing: -0.4px;
        border-bottom: solid 1px #ff7e00; }
  .view-chatbot-detail .view-chatbot-detail-middle .view-chatbot-detail-middle-total-status {
    font-weight: bold; }
    .view-chatbot-detail .view-chatbot-detail-middle .view-chatbot-detail-middle-total-status > div {
      margin-right: 10%; }
    .view-chatbot-detail .view-chatbot-detail-middle .view-chatbot-detail-middle-total-status .flexing {
      font-weight: bold;
      font-size: 14px;
      line-height: 21px;
      letter-spacing: -0.4px; }
    .view-chatbot-detail .view-chatbot-detail-middle .view-chatbot-detail-middle-total-status .total {
      font-size: 24px;
      line-height: 30px;
      letter-spacing: -0.6px; }
  .view-chatbot-detail .view-chatbot-detail-middle svg {
    margin: 0 0 0 6px;
    font-size: 16px;
    color: #FF7E00;
    cursor: pointer; }
  .view-chatbot-detail .view-chatbot-detail-middle:nth-last-child(1) {
    padding: 0; }

.view-chatbot-detail .detail-value-wrapper {
  border: solid 1px rgba(25, 25, 25, 0.1);
  border-radius: 8px;
  width: 100%;
  position: relative;
  margin-top: 2px; }
  .view-chatbot-detail .detail-value-wrapper input {
    border: none;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 12px 48px 12px 16px;
    font-size: 16px;
    letter-spacing: -0.3px;
    background: transparent; }
  .view-chatbot-detail .detail-value-wrapper svg {
    cursor: pointer;
    position: absolute !important;
    right: 16px !important;
    top: 50% !important;
    -webkit-transform: translate(0, -50%) !important;
            transform: translate(0, -50%) !important;
    color: #191919;
    font-size: 20px; }

.view-chatbot-detail .view-chatbot-detail-bottom {
  padding: 24px 0 0; }
  .view-chatbot-detail .view-chatbot-detail-bottom .view-message-box {
    position: relative;
    width: 100%; }
  .view-chatbot-detail .view-chatbot-detail-bottom .view-message-box-left {
    display: inline-block;
    vertical-align: top;
    width: 400px; }
  .view-chatbot-detail .view-chatbot-detail-bottom .view-message-box-right {
    width: calc(100% - 400px);
    vertical-align: top;
    display: inline-block;
    padding-left: 24px; }

.view-chatbot-detail div:last-child .view-chatbot-detail-bottom-footer p {
  margin-bottom: 0; }

.view-chatbot-detail .scf-table-wrapper {
  width: 100%;
  position: relative;
  margin-top: 24px; }

.view-chatbot-detail .table-scf thead tr {
  background: #ffffff; }
  .view-chatbot-detail .table-scf thead tr .col_name {
    width: unset; }
  .view-chatbot-detail .table-scf thead tr td.col_id {
    width: 70px; }
  .view-chatbot-detail .table-scf thead tr td.col_type {
    width: 105px; }
  .view-chatbot-detail .table-scf thead tr td.col_action {
    width: 105px; }

.view-chatbot-detail .table-scf tbody
tr.no-hover {
  cursor: default !important; }
  .view-chatbot-detail .table-scf tbody
tr.no-hover:hover {
    background-color: #ffffff !important; }
    .view-chatbot-detail .table-scf tbody
tr.no-hover:hover td {
      color: #191919 !important; }

.view-chatbot-detail td {
  position: relative; }
  .view-chatbot-detail td.col_type {
    width: 105px; }
  .view-chatbot-detail td.col_action {
    width: 105px; }
  .view-chatbot-detail td:nth-child(1) div {
    display: flex; }
    .view-chatbot-detail td:nth-child(1) div p {
      font-size: 12px !important;
      letter-spacing: -0.4px; }
    .view-chatbot-detail td:nth-child(1) div img {
      margin-right: 4px;
      margin-top: 2px; }
  .view-chatbot-detail td:last-child a {
    color: #ff7e00; }
  .view-chatbot-detail td:last-child svg {
    position: relative;
    color: #ff7e00;
    font-size: 16px;
    margin-right: 4px;
    right: 0;
    top: -2px; }
  .view-chatbot-detail td .medium-logo {
    width: 16px;
    height: 16px; }

.view-chatbot-detail .custom-select-option-value {
  margin-top: 0px !important; }

.modal-image-broadcast.modal-dialog {
  width: 100vw !important; }

.modal-image-broadcast .modal-content {
  width: 100%;
  height: 100%;
  background: none; }

.modal-image-broadcast .modal-content .modal-body {
  width: 100%;
  height: 100%;
  background: rgba(25, 25, 25, 0.8); }

.modal-image-broadcast .modal-content .modal-body img.image-preview-val {
  max-width: calc(100vw - 130px);
  max-height: calc(100vh - 130px);
  background: #ffffff; }

.modal-image-broadcast .modal-content .modal-body .image-wrapper {
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  text-align: center; }
  .modal-image-broadcast .modal-content .modal-body .image-wrapper figure {
    margin: 0; }
    .modal-image-broadcast .modal-content .modal-body .image-wrapper figure.zoom {
      background-size: 180%;
      background-position: -100% -100%;
      background-repeat: no-repeat;
      position: relative;
      width: 100%;
      overflow: hidden;
      cursor: -webkit-zoom-in;
      cursor: zoom-in; }
      .modal-image-broadcast .modal-content .modal-body .image-wrapper figure.zoom img {
        -webkit-transition: opacity 0.5s;
        transition: opacity 0.5s;
        display: inline-block; }
        .modal-image-broadcast .modal-content .modal-body .image-wrapper figure.zoom img:hover {
          opacity: 0; }

.modal-image-broadcast .modal-content .modal-body .close-modal-image-broadcast {
  position: absolute;
  top: 20px;
  right: 20px;
  color: #ffffff;
  font-size: 40px;
  cursor: pointer;
  -webkit-transition: ease-in-out 0.1s;
  transition: ease-in-out 0.1s; }

.modal-image-broadcast
.modal-content
.modal-body
.close-modal-image-broadcast:hover {
  color: #c9c9c9; }

.edit-path-modal .modal-content {
  width: 800px;
  border-radius: 8px;
  padding: 32px;
  position: relative;
  max-height: 90vh;
  overflow-y: auto; }
  .edit-path-modal .modal-content .create-path-title {
    font-size: 20px;
    letter-spacing: -0.6px;
    line-height: 1; }
  .edit-path-modal .modal-content .close-modal-create-path {
    position: absolute;
    font-size: 22px;
    right: 0;
    top: 2px;
    cursor: pointer; }
  .edit-path-modal .modal-content .form-content-wrapper input, .edit-path-modal .modal-content .form-content-wrapper textarea, .edit-path-modal .modal-content .form-content-wrapper label {
    width: 100%;
    position: relative; }
    .edit-path-modal .modal-content .form-content-wrapper input .clear-all, .edit-path-modal .modal-content .form-content-wrapper textarea .clear-all, .edit-path-modal .modal-content .form-content-wrapper label .clear-all {
      color: var(--watermelon);
      cursor: pointer;
      position: absolute;
      right: 0;
      bottom: 0; }
      .edit-path-modal .modal-content .form-content-wrapper input .clear-all:hover, .edit-path-modal .modal-content .form-content-wrapper textarea .clear-all:hover, .edit-path-modal .modal-content .form-content-wrapper label .clear-all:hover {
        color: var(--watermelon-dark); }
  .edit-path-modal .modal-content .form-content-wrapper .input-counter {
    position: absolute;
    top: 0;
    right: 0; }
  .edit-path-modal .modal-content .button-create-path {
    width: 100%;
    margin-top: 20px; }
  .edit-path-modal .modal-content .create-path-type-wrapper {
    position: relative;
    width: 100%;
    display: block;
    line-height: 1; }
    .edit-path-modal .modal-content .create-path-type-wrapper .create-path-type-select {
      display: inline-block;
      border-radius: 8px;
      width: calc(50% - 5px);
      margin-right: 10px;
      border: solid 1px #dcdcdc;
      cursor: pointer;
      text-align: center;
      padding: 12px 5px;
      font-size: 16px;
      letter-spacing: -0.3px; }
      .edit-path-modal .modal-content .create-path-type-wrapper .create-path-type-select.create-path-type-active {
        border: solid 1px #ff7d00;
        color: #ff8d00; }
        .edit-path-modal .modal-content .create-path-type-wrapper .create-path-type-select.create-path-type-active svg {
          color: #ff7d00; }
      .edit-path-modal .modal-content .create-path-type-wrapper .create-path-type-select svg {
        margin-right: 4px;
        position: relative;
        top: -1px;
        color: rgba(25, 25, 25, 0.4); }
      .edit-path-modal .modal-content .create-path-type-wrapper .create-path-type-select:nth-child(2) {
        margin: 0; }
      .edit-path-modal .modal-content .create-path-type-wrapper .create-path-type-select:hover {
        border: solid 1px #ff7d00;
        color: #ff8d00; }
        .edit-path-modal .modal-content .create-path-type-wrapper .create-path-type-select:hover svg {
          color: #ff7d00; }
      .edit-path-modal .modal-content .create-path-type-wrapper .create-path-type-select.edit-path-type-select {
        border: none !important;
        cursor: default !important;
        padding: 0 !important;
        width: unset !important;
        line-height: 1; }
    .edit-path-modal .modal-content .create-path-type-wrapper.no-hover .create-path-type-select {
      color: inherit !important; }
      .edit-path-modal .modal-content .create-path-type-wrapper.no-hover .create-path-type-select svg {
        color: rgba(25, 25, 25, 0.4) !important; }
  .edit-path-modal .modal-content .choose-related-department-wrapper {
    position: relative;
    width: 100%;
    display: block; }
    .edit-path-modal .modal-content .choose-related-department-wrapper .choose-related-department-select {
      display: inline-block;
      border-radius: 8px;
      width: calc((100% / 3) - 10px);
      margin-right: 15px;
      border: solid 1px #dcdcdc;
      cursor: pointer;
      text-align: left;
      padding: 12px 13px;
      font-size: 16px;
      letter-spacing: -0.3px;
      margin-bottom: 10px; }
      .edit-path-modal .modal-content .choose-related-department-wrapper .choose-related-department-select label {
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: 100%;
        overflow: hidden; }
      .edit-path-modal .modal-content .choose-related-department-wrapper .choose-related-department-select.choose-related-department-select-active {
        border: solid 1px #ff7d00; }
        .edit-path-modal .modal-content .choose-related-department-wrapper .choose-related-department-select.choose-related-department-select-active label {
          color: #ff7d00; }
      .edit-path-modal .modal-content .choose-related-department-wrapper .choose-related-department-select svg {
        margin-right: 4px;
        position: relative;
        top: -1px;
        color: rgba(25, 25, 25, 0.4); }
      .edit-path-modal .modal-content .choose-related-department-wrapper .choose-related-department-select:nth-child(3) {
        margin: 0; }
      .edit-path-modal .modal-content .choose-related-department-wrapper .choose-related-department-select:hover {
        border: solid 1px #ff7d00; }
        .edit-path-modal .modal-content .choose-related-department-wrapper .choose-related-department-select:hover label {
          color: #ff7d00; }
      .edit-path-modal .modal-content .choose-related-department-wrapper .choose-related-department-select label {
        margin: 0; }

.edit-path-modal .modal-body {
  padding: 0; }

.modal-text-field .modal-content {
  box-sizing: border-box;
  width: 500px;
  padding: 42px 24px;
  border-radius: 8px; }

.modal-text-field .modal-body {
  padding: 0 0 24px 0;
  font-size: 14px;
  letter-spacing: -0.2px;
  text-align: left;
  color: #9b9b9b; }
  .modal-text-field .modal-body .text-field-wrapper label {
    margin: 0; }
  .modal-text-field .modal-body .text-field-wrapper .input-counter {
    position: absolute;
    top: 0;
    right: 0; }
  .modal-text-field .modal-body .text-field-wrapper input[type="text"] {
    margin-top: 8px;
    width: 100%; }
  .modal-text-field .modal-body p.modal-description {
    margin: 4px 0 0;
    font-size: 14px;
    letter-spacing: -0.2px;
    color: rgba(25, 25, 25, 0.6);
    line-height: 21px; }
  .modal-text-field .modal-body b.modal-title {
    font-size: 20px;
    letter-spacing: -0.6px;
    color: #191919;
    line-height: 24px; }
    .modal-text-field .modal-body b.modal-title img {
      position: relative;
      top: -2px;
      margin-right: 4px; }
    .modal-text-field .modal-body b.modal-title svg {
      position: relative;
      top: -2px;
      margin-right: 4px; }

.modal-text-field .modal-footer {
  padding: 0;
  border: none;
  display: block; }
  .modal-text-field .modal-footer > * {
    margin: unset; }
  .modal-text-field .modal-footer button {
    vertical-align: top; }
    .modal-text-field .modal-footer button.cancel-button {
      width: calc(50% - 4px);
      margin: 0 8px 0 0; }
    .modal-text-field .modal-footer button.no-fill-button:disabled {
      background-color: white !important;
      border-color: #19191966 !important;
      cursor: default; }
    .modal-text-field .modal-footer button.modal-button {
      margin: 0;
      width: calc(50% - 4px);
      display: inline-block; }

.modal-confirmation-delete-path button {
  width: calc(50% - 4px) !important;
  margin-right: 16px; }
  .modal-confirmation-delete-path button:nth-last-child(1) {
    margin-right: 0; }

.view-chatgpt-usage .grey-font-6 {
  margin: 0; }

.view-chatgpt-usage .flexing {
  display: flex;
  margin: 4px 0 0 !important; }

.view-chatgpt-usage .view-chatgpt-usage-top {
  padding: 10px 0 12px 0;
  border-bottom: solid 1px #dcdcdc;
  margin-bottom: 24px;
  position: relative;
  font-size: 20px;
  letter-spacing: -0.6px; }
  .view-chatgpt-usage .view-chatgpt-usage-top svg {
    position: relative;
    top: -2px;
    margin-right: 8px;
    width: 24px;
    height: 24px; }
  .view-chatgpt-usage .view-chatgpt-usage-top .subtitle-chatbot {
    color: rgba(25, 25, 25, 0.8);
    font-size: 14px;
    letter-spacing: -0.2px;
    margin: 0 0 0; }
    .view-chatgpt-usage .view-chatgpt-usage-top .subtitle-chatbot a {
      color: #ff7d00; }
      .view-chatgpt-usage .view-chatgpt-usage-top .subtitle-chatbot a:hover {
        text-decoration: underline; }

.view-chatgpt-usage .view-chatgpt-usage-bottom .view-chatgpt-usage-filter-wrapper {
  width: 100%;
  display: block;
  margin: 0 0 24px 0;
  position: relative;
  text-align: right;
  height: 50px; }
  .view-chatgpt-usage .view-chatgpt-usage-bottom .view-chatgpt-usage-filter-wrapper .main-custom-select-option-wrapper {
    width: 276px;
    position: absolute;
    right: 0;
    top: 0; }
    .view-chatgpt-usage .view-chatgpt-usage-bottom .view-chatgpt-usage-filter-wrapper .main-custom-select-option-wrapper .custom-select-option-value-wrapper {
      padding: 12px 16px !important; }
    .view-chatgpt-usage .view-chatgpt-usage-bottom .view-chatgpt-usage-filter-wrapper .main-custom-select-option-wrapper .custom-select-option-value {
      text-align: center;
      padding: 0; }
    .view-chatgpt-usage .view-chatgpt-usage-bottom .view-chatgpt-usage-filter-wrapper .main-custom-select-option-wrapper .icon-arrow {
      right: 16px; }
    .view-chatgpt-usage .view-chatgpt-usage-bottom .view-chatgpt-usage-filter-wrapper .main-custom-select-option-wrapper .icon-left {
      position: absolute;
      left: 16px;
      top: 50%;
      -webkit-transform: translate(0, -50%);
              transform: translate(0, -50%);
      font-size: 20px; }

.view-chatgpt-usage .view-chatgpt-usage-bottom .view-chatgpt-usage-top-usage-wrapper {
  width: 100%;
  display: block;
  margin: 0;
  position: relative; }
  .view-chatgpt-usage .view-chatgpt-usage-bottom .view-chatgpt-usage-top-usage-wrapper .package-information-left {
    position: relative;
    display: inline-block;
    vertical-align: top;
    min-width: 263px;
    max-width: 100%;
    padding: 20px 24px;
    border-radius: 8px;
    border: solid 1px #1919191A;
    margin: 0 24px 24px 0;
    text-align: left;
    color: #191919; }
    .view-chatgpt-usage .view-chatgpt-usage-bottom .view-chatgpt-usage-top-usage-wrapper .package-information-left .package-information-top {
      font-size: 14px;
      letter-spacing: -0.4px;
      margin: 0 0 12px 0; }
      .view-chatgpt-usage .view-chatgpt-usage-bottom .view-chatgpt-usage-top-usage-wrapper .package-information-left .package-information-top svg {
        position: relative;
        top: -2.5px;
        margin: 0 4px 0 0; }
      .view-chatgpt-usage .view-chatgpt-usage-bottom .view-chatgpt-usage-top-usage-wrapper .package-information-left .package-information-top b {
        font-size: 16px;
        line-height: 24px;
        letter-spacing: -0.5px; }
    .view-chatgpt-usage .view-chatgpt-usage-bottom .view-chatgpt-usage-top-usage-wrapper .package-information-left .package-information-list {
      font-size: 14px;
      letter-spacing: -0.2px;
      white-space: nowrap;
      line-height: 23px;
      max-width: 100%;
      text-overflow: ellipsis;
      overflow: hidden;
      margin: 0; }
  .view-chatgpt-usage .view-chatgpt-usage-bottom .view-chatgpt-usage-top-usage-wrapper .package-information-right {
    position: relative;
    display: inline-block;
    vertical-align: top;
    padding: 16px 16px 0 16px;
    max-width: 100%;
    border-radius: 8px;
    border: solid 1px #1919191A;
    text-align: left; }
    .view-chatgpt-usage .view-chatgpt-usage-bottom .view-chatgpt-usage-top-usage-wrapper .package-information-right .package-information-right-list {
      display: inline-block;
      vertical-align: top;
      min-width: 166px;
      max-width: 100%;
      margin: 0 24px 16px 0;
      white-space: pre-line;
      word-wrap: break-word; }
      .view-chatgpt-usage .view-chatgpt-usage-bottom .view-chatgpt-usage-top-usage-wrapper .package-information-right .package-information-right-list img {
        width: 24px;
        height: 24px; }
      .view-chatgpt-usage .view-chatgpt-usage-bottom .view-chatgpt-usage-top-usage-wrapper .package-information-right .package-information-right-list .package-information-right-text-1 {
        margin: 4px 0 0;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: -0.5px;
        color: #191919; }
      .view-chatgpt-usage .view-chatgpt-usage-bottom .view-chatgpt-usage-top-usage-wrapper .package-information-right .package-information-right-list .package-information-right-text-2 {
        margin: 4px 0 0;
        font-size: 24px;
        line-height: 30px;
        letter-spacing: -0.6px;
        color: #191919; }
      .view-chatgpt-usage .view-chatgpt-usage-bottom .view-chatgpt-usage-top-usage-wrapper .package-information-right .package-information-right-list .package-information-right-text-3 {
        margin: 4px 0 0;
        font-size: 14px;
        line-height: 21px;
        letter-spacing: -0.4px;
        color: #19191966; }

.view-chatgpt-usage .view-chatgpt-usage-bottom .view-chatgpt-usage-bar-chart-wrapper {
  padding: 16px; }
  .view-chatgpt-usage .view-chatgpt-usage-bottom .view-chatgpt-usage-bar-chart-wrapper .title-wrapper {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.5px;
    width: 100%;
    display: block;
    position: relative;
    padding: 0 0 16px 0; }
    .view-chatgpt-usage .view-chatgpt-usage-bottom .view-chatgpt-usage-bar-chart-wrapper .title-wrapper .bar-chart-legend-wrapper {
      display: block;
      position: absolute;
      right: 0;
      top: 2px;
      white-space: nowrap;
      font-size: 14px;
      line-height: 21px;
      letter-spacing: -0.2px;
      color: #191919; }
      .view-chatgpt-usage .view-chatgpt-usage-bottom .view-chatgpt-usage-bar-chart-wrapper .title-wrapper .bar-chart-legend-wrapper .bar-chart-legend-box {
        display: inline-block;
        vertical-align: top;
        margin: 0 12px 0 0;
        width: 48px;
        height: 20px;
        background: #FFCB99;
        background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='4' ry='4' stroke='%23FF7E00FF' stroke-width='1' stroke-dasharray='2%2c 2' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
        border-radius: 4px; }

.box-no-data {
  width: 100%;
  text-align: center;
  padding: 0 0 40px 0; }
  .box-no-data img {
    height: 88px; }
  .box-no-data b {
    display: block;
    margin: 16px 0 0 0;
    color: rgba(25, 25, 25, 0.4);
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.5px; }

.chatbot-setup-page-wrapper {
  position: relative; }
  .chatbot-setup-page-wrapper .chatbot-setup-header {
    width: 100%;
    position: relative;
    display: block;
    padding: 0 0 12px 0;
    border-bottom: solid 1px #dcdcdc;
    margin: 0 0 24px 0; }
    .chatbot-setup-page-wrapper .chatbot-setup-header button {
      position: absolute;
      top: -5px;
      right: 0px; }
  .chatbot-setup-page-wrapper .chatbot-setup-header-title {
    font-size: 20px;
    letter-spacing: -0.6px;
    color: #191919; }
  .chatbot-setup-page-wrapper .chatbot-setup-header-last {
    margin: 0;
    font-size: 14px;
    letter-spacing: -0.2px;
    color: rgba(25, 25, 25, 0.4); }
  .chatbot-setup-page-wrapper .chatbot-tab-wrapper {
    display: inline-block;
    height: 38px;
    border-radius: 8px;
    white-space: nowrap;
    background: rgba(25, 25, 25, 0.1);
    padding: 2px;
    overflow: hidden; }
    .chatbot-setup-page-wrapper .chatbot-tab-wrapper .chatbot-tab {
      display: inline-block;
      position: relative;
      vertical-align: middle;
      margin: 0 12px 0 0;
      padding: 2px 16px;
      height: 100%;
      border-radius: 6px;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: -0.5px;
      text-align: center;
      color: #19191966;
      border: solid 1px #19191900;
      cursor: pointer;
      -webkit-transition: 0.2s;
      transition: 0.2s; }
      .chatbot-setup-page-wrapper .chatbot-tab-wrapper .chatbot-tab b {
        display: block;
        -webkit-transform: translateY(2px);
                transform: translateY(2px); }
      .chatbot-setup-page-wrapper .chatbot-tab-wrapper .chatbot-tab:hover {
        color: #191919CC;
        -webkit-transition: 0.2s;
        transition: 0.2s; }
      .chatbot-setup-page-wrapper .chatbot-tab-wrapper .chatbot-tab:active {
        border: solid 1px #1919191A;
        color: #191919CC;
        -webkit-transition: 0.2s;
        transition: 0.2s; }
      .chatbot-setup-page-wrapper .chatbot-tab-wrapper .chatbot-tab.chatbot-tab-active {
        background: white;
        color: #191919;
        border: solid 1px #19191900;
        box-shadow: 0px 8px 28px 0px rgba(25, 25, 25, 0.18); }
        .chatbot-setup-page-wrapper .chatbot-tab-wrapper .chatbot-tab.chatbot-tab-active:hover {
          border: solid 1px #19191900; }
      .chatbot-setup-page-wrapper .chatbot-tab-wrapper .chatbot-tab:last-child {
        margin: 0; }

.modal-save-changes button {
  padding: 0 8px !important;
  width: calc(50% - 4px); }
  .modal-save-changes button:last-child {
    margin-left: 8px; }

.padding-24 {
  padding: 24px; }

.chatbot-setup-page-wrapper .prompt-settings-title {
  margin-bottom: 4px;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: -0.6px;
  color: #191919; }

.chatbot-setup-page-wrapper .prompt-settings-description {
  margin: 8px 0 0 0;
  padding-bottom: 12px;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.3px;
  color: #191919CC;
  border-bottom: solid 1px #1919191A; }

.chatbot-setup-page-wrapper .form-content-wrapper {
  width: 100%;
  max-width: 600px; }
  .chatbot-setup-page-wrapper .form-content-wrapper label {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.5px;
    color: #191919; }
  .chatbot-setup-page-wrapper .form-content-wrapper .dropdown button {
    margin-top: 0px; }
  .chatbot-setup-page-wrapper .form-content-wrapper .greetings-list {
    position: relative;
    width: 100%; }
    .chatbot-setup-page-wrapper .form-content-wrapper .greetings-list .input-greetings {
      display: block;
      margin: 4px 0;
      padding: 12px 48px 12px 16px;
      width: 100%;
      height: 48px;
      overflow: hidden; }
    .chatbot-setup-page-wrapper .form-content-wrapper .greetings-list .icon-remove-greeting {
      position: absolute;
      top: 0px;
      right: 0px;
      padding: 16px;
      width: 48px;
      height: 48px;
      color: #FF3F57;
      cursor: pointer; }
  .chatbot-setup-page-wrapper .form-content-wrapper .button-add-greeting {
    margin: 4px 0;
    width: 216px;
    padding: 0 !important; }
  .chatbot-setup-page-wrapper .form-content-wrapper .optional {
    font-size: 14px;
    line-height: 21px;
    letter-spacing: -0.2px;
    color: #19191966; }
  .chatbot-setup-page-wrapper .form-content-wrapper .system-prompt-wrapper .system-prompt-description {
    margin: -4px 0 16px 0;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: -0.2px;
    color: #191919CC; }
  .chatbot-setup-page-wrapper .form-content-wrapper .system-prompt-wrapper .input-system-prompt {
    display: block;
    margin: 4px 0;
    width: 100%;
    height: 338px;
    overflow: auto;
    resize: none; }
    .chatbot-setup-page-wrapper .form-content-wrapper .system-prompt-wrapper .input-system-prompt::-webkit-scrollbar {
      width: 12px; }
    .chatbot-setup-page-wrapper .form-content-wrapper .system-prompt-wrapper .input-system-prompt::-webkit-scrollbar-thumb {
      border: 4px solid #00000000;
      border-radius: 8px;
      background-color: #1919194D;
      background-clip: padding-box; }
  .chatbot-setup-page-wrapper .form-content-wrapper .dropdown-model .error-or-info-comp a {
    color: var(--main-dark-orange); }
  .chatbot-setup-page-wrapper .form-content-wrapper .dropdown-model .error-or-info-comp a:hover {
    color: var(--main-dark-orange);
    text-decoration: underline !important; }
  .chatbot-setup-page-wrapper .form-content-wrapper .dropdown-model .error-or-info-comp a:active {
    color: var(--main-darker-orange);
    text-decoration: underline !important; }
  .chatbot-setup-page-wrapper .form-content-wrapper .dropdown-language .option-select-bulk-wrapper {
    padding-right: 88px; }
  .chatbot-setup-page-wrapper .form-content-wrapper .add-margin-bottom {
    margin-bottom: 16px; }

.chatbot-setup-page-wrapper .external-source-wrapper {
  max-width: unset !important; }
  .chatbot-setup-page-wrapper .external-source-wrapper p {
    display: inline-block;
    vertical-align: top;
    margin: 0;
    font-size: 16px;
    letter-spacing: -0.3px;
    color: rgba(25, 25, 25, 0.8);
    -webkit-transform: translateY(-2px);
            transform: translateY(-2px); }
  .chatbot-setup-page-wrapper .external-source-wrapper .external-source-switch-wrapper {
    display: inline-block;
    vertical-align: top;
    margin: 0 8px 0 0; }
    .chatbot-setup-page-wrapper .external-source-wrapper .external-source-switch-wrapper .custom-switch-wrapper {
      margin: 0;
      height: 20px;
      width: 32px; }
      .chatbot-setup-page-wrapper .external-source-wrapper .external-source-switch-wrapper .custom-switch-wrapper .custom-switch:before {
        width: 12px;
        height: 12px;
        bottom: 4px;
        -webkit-transform: translateX(-4px);
                transform: translateX(-4px); }
    .chatbot-setup-page-wrapper .external-source-wrapper .external-source-switch-wrapper input:checked + .custom-switch:before {
      -webkit-transform: translateX(12px);
              transform: translateX(12px); }
    .chatbot-setup-page-wrapper .external-source-wrapper .external-source-switch-wrapper[disabled] input:checked + .custom-switch {
      background-color: #19191933; }

.chatbot-setup-page-wrapper .tone-form-wrapper {
  margin-top: 32px; }
  .chatbot-setup-page-wrapper .tone-form-wrapper .tone-wrapper {
    margin-top: 4px; }
    .chatbot-setup-page-wrapper .tone-form-wrapper .tone-wrapper .tone-list-wrapper {
      display: inline-block;
      width: 196px; }
      .chatbot-setup-page-wrapper .tone-form-wrapper .tone-wrapper .tone-list-wrapper .tone-list-marker-wrapper {
        display: block;
        position: relative;
        width: 100%;
        height: 16px; }
        .chatbot-setup-page-wrapper .tone-form-wrapper .tone-wrapper .tone-list-wrapper .tone-list-marker-wrapper .tone-list-line {
          width: 100%;
          height: 4px;
          -webkit-transform: translate(6px, 6px);
                  transform: translate(6px, 6px); }
        .chatbot-setup-page-wrapper .tone-form-wrapper .tone-wrapper .tone-list-wrapper .tone-list-marker-wrapper .tone-list-dot {
          position: absolute;
          top: 0px;
          left: 0px;
          width: 16px;
          height: 16px;
          border-radius: 8px;
          -webkit-transition: 0.2s;
          transition: 0.2s;
          cursor: pointer; }
          .chatbot-setup-page-wrapper .tone-form-wrapper .tone-wrapper .tone-list-wrapper .tone-list-marker-wrapper .tone-list-dot:hover {
            background: #FF7E00 !important;
            -webkit-transition: 0.2s;
            transition: 0.2s; }
        .chatbot-setup-page-wrapper .tone-form-wrapper .tone-wrapper .tone-list-wrapper .tone-list-marker-wrapper .bg-orange {
          background: #FF7E00;
          box-shadow: 0px 3px 5px 0px #00000033; }
        .chatbot-setup-page-wrapper .tone-form-wrapper .tone-wrapper .tone-list-wrapper .tone-list-marker-wrapper .bg-light-orange {
          background: #FFCB99; }
        .chatbot-setup-page-wrapper .tone-form-wrapper .tone-wrapper .tone-list-wrapper .tone-list-marker-wrapper .bg-gray {
          background: #F3F3F3; }
      .chatbot-setup-page-wrapper .tone-form-wrapper .tone-wrapper .tone-list-wrapper .tone-list-label-wrapper {
        display: block;
        margin-top: 4px;
        width: 100%;
        font-size: 14px;
        line-height: 21px;
        letter-spacing: -0.2px;
        color: #191919; }
        .chatbot-setup-page-wrapper .tone-form-wrapper .tone-wrapper .tone-list-wrapper .tone-list-label-wrapper svg {
          margin-left: 4px;
          font-size: 16px;
          -webkit-transform: translateY(-2px);
                  transform: translateY(-2px);
          cursor: pointer; }
      .chatbot-setup-page-wrapper .tone-form-wrapper .tone-wrapper .tone-list-wrapper:last-child .tone-list-marker-wrapper .tone-list-line {
        display: none; }

.chatbot-setup-page-wrapper .button-save {
  margin-top: 32px;
  width: 180px !important; }

.chatbot-setup-page-wrapper .language-bulk-wrapper {
  margin-top: 32px;
  max-width: 864px !important; }

.tooltip-tone .bs-tooltip-bottom .arrow::before, .tooltip-tone .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
  border-bottom-color: #6B6B6B !important; }

.tooltip-tone .tooltip.show {
  opacity: 1 !important;
  margin-top: 4px; }

.tooltip-tone .tooltip-inner {
  padding: 8px 8px !important;
  min-width: 224px !important;
  font-family: "PTRootUI" !important;
  font-size: 12px !important;
  line-height: 16px !important;
  letter-spacing: -0.2px !important;
  border-radius: 8px !important;
  color: #FFFFFF !important;
  background: #6B6B6B !important;
  opacity: 1 !important; }

.option-select-bulk-wrapper {
  width: 100%;
  position: relative; }
  .option-select-bulk-wrapper .custom-checkbox-stripe [type="checkbox"]:checked + label:after {
    width: 16px;
    height: 16px;
    top: 2px;
    left: 0px; }
  .option-select-bulk-wrapper.option-select-bulk-wrapper-disabled .select-content label::before {
    border: solid 1px #dcdcdc !important; }
  .option-select-bulk-wrapper .option-select-bulk-wrapper-50 {
    width: calc(50% - 8px);
    position: relative;
    vertical-align: top;
    display: inline-block;
    margin: 0 16px 0 0; }
    .option-select-bulk-wrapper .option-select-bulk-wrapper-50 label {
      position: relative;
      width: 100%; }
      .option-select-bulk-wrapper .option-select-bulk-wrapper-50 label .clear-bulk {
        position: absolute;
        bottom: 0;
        right: 0;
        cursor: pointer;
        color: var(--main-dark-orange); }
        .option-select-bulk-wrapper .option-select-bulk-wrapper-50 label .clear-bulk svg {
          position: relative;
          font-size: 16px;
          top: -2px;
          margin-right: 4px; }
        .option-select-bulk-wrapper .option-select-bulk-wrapper-50 label .clear-bulk:hover {
          color: var(--main-darker-orange); }
    .option-select-bulk-wrapper .option-select-bulk-wrapper-50:nth-child(2) {
      margin: 0; }
    .option-select-bulk-wrapper .option-select-bulk-wrapper-50 span {
      color: rgba(25, 25, 25, 0.6);
      position: relative !important; }
    .option-select-bulk-wrapper .option-select-bulk-wrapper-50 .selection-bulk-wrapper {
      border-radius: 8px;
      font-size: 14px;
      letter-spacing: -0.2px;
      border: solid 1px #dcdcdc;
      overflow: hidden; }
      .option-select-bulk-wrapper .option-select-bulk-wrapper-50 .selection-bulk-wrapper .select-content {
        width: 100%;
        padding: 13px 17px;
        white-space: nowrap;
        height: 47px; }
        .option-select-bulk-wrapper .option-select-bulk-wrapper-50 .selection-bulk-wrapper .select-content label {
          margin: 0;
          max-width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          display: inline-block !important;
          padding: 0 0 0 22px !important; }
        .option-select-bulk-wrapper .option-select-bulk-wrapper-50 .selection-bulk-wrapper .select-content input {
          display: none; }
        .option-select-bulk-wrapper .option-select-bulk-wrapper-50 .selection-bulk-wrapper .select-content:hover {
          background: #FFF2E5; }
        .option-select-bulk-wrapper .option-select-bulk-wrapper-50 .selection-bulk-wrapper .select-content:nth-child(1) {
          border-top-left-radius: 8px;
          border-top-right-radius: 8px; }
      .option-select-bulk-wrapper .option-select-bulk-wrapper-50 .selection-bulk-wrapper .search-box-wrapper {
        width: 100%; }
        .option-select-bulk-wrapper .option-select-bulk-wrapper-50 .selection-bulk-wrapper .search-box-wrapper input[type="text"] {
          border: none !important;
          border-bottom: solid 1px #dcdcdc !important;
          border-radius: 0;
          background: transparent;
          width: 100%;
          position: relative;
          padding: 0 39px 0 16px;
          height: 40px;
          margin: 0 !important; }
        .option-select-bulk-wrapper .option-select-bulk-wrapper-50 .selection-bulk-wrapper .search-box-wrapper #search-icon {
          width: 40px;
          height: 40px;
          right: 0 !important;
          left: unset !important;
          background: none !important; }
      .option-select-bulk-wrapper .option-select-bulk-wrapper-50 .selection-bulk-wrapper .overflow-select-bulk {
        max-height: 160px;
        overflow-y: auto; }
        .option-select-bulk-wrapper .option-select-bulk-wrapper-50 .selection-bulk-wrapper .overflow-select-bulk .select-content:nth-child(1) {
          border-top-left-radius: 0px;
          border-top-right-radius: 0px; }
        .option-select-bulk-wrapper .option-select-bulk-wrapper-50 .selection-bulk-wrapper .overflow-select-bulk .select-content:nth-last-child(1) {
          border-bottom-left-radius: 8px;
          border-bottom-right-radius: 8px; }
        .option-select-bulk-wrapper .option-select-bulk-wrapper-50 .selection-bulk-wrapper .overflow-select-bulk .select-content.checkbox-all {
          border-bottom: solid 1px #dcdcdc; }
        .option-select-bulk-wrapper .option-select-bulk-wrapper-50 .selection-bulk-wrapper .overflow-select-bulk .select-content.not-found {
          text-align: center !important;
          color: var(--main-dark-grey);
          background: #fff !important;
          padding: 34px 60px;
          height: unset;
          white-space: unset;
          text-align: center;
          overflow: hidden !important; }
          .option-select-bulk-wrapper .option-select-bulk-wrapper-50 .selection-bulk-wrapper .overflow-select-bulk .select-content.not-found img {
            margin: 0 0 20px 0; }
    .option-select-bulk-wrapper .option-select-bulk-wrapper-50 .select-bulk-wrapper {
      width: 100%;
      height: 200px; }
      .option-select-bulk-wrapper .option-select-bulk-wrapper-50 .select-bulk-wrapper .selected-bulk-container {
        width: 100%;
        min-height: 100%;
        overflow-y: auto;
        max-height: 100%;
        border: solid 1px #dcdcdc;
        border-radius: 8px;
        padding: 8px 16px 8px 16px; }
        .option-select-bulk-wrapper .option-select-bulk-wrapper-50 .select-bulk-wrapper .selected-bulk-container .no-selected-bulk {
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-top: 48px; }
          .option-select-bulk-wrapper .option-select-bulk-wrapper-50 .select-bulk-wrapper .selected-bulk-container .no-selected-bulk img {
            margin-bottom: 20px; }
          .option-select-bulk-wrapper .option-select-bulk-wrapper-50 .select-bulk-wrapper .selected-bulk-container .no-selected-bulk p {
            color: rgba(25, 25, 25, 0.4);
            font-size: 14px;
            line-height: 21px;
            letter-spacing: -0.2px;
            text-align: center; }

.chatbot-setup-page-wrapper .text-info-top {
  margin: 16px 0 0;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: -0.2px;
  color: #191919; }
  .chatbot-setup-page-wrapper .text-info-top svg {
    position: relative;
    top: -2px;
    margin-right: 4px; }
  .chatbot-setup-page-wrapper .text-info-top p {
    margin: 0 0 0 32px; }

.chatbot-setup-page-wrapper .knowledge-source {
  display: inline-block;
  vertical-align: top;
  margin-top: 32px;
  width: 50%; }
  .chatbot-setup-page-wrapper .knowledge-source .knowledge-source-title {
    margin-bottom: 4px;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: -0.6px;
    color: #191919; }
  .chatbot-setup-page-wrapper .knowledge-source .knowledge-source-description {
    margin-bottom: 12px;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.3px;
    color: #191919CC; }
  .chatbot-setup-page-wrapper .knowledge-source .knowledge-source-input-wrapper {
    position: relative; }
    .chatbot-setup-page-wrapper .knowledge-source .knowledge-source-input-wrapper .tooltip-icon {
      position: absolute;
      top: 4px;
      right: 4px;
      color: #191919;
      cursor: pointer; }
    .chatbot-setup-page-wrapper .knowledge-source .knowledge-source-input-wrapper .nav-item {
      cursor: pointer;
      color: rgba(25, 25, 25, 0.4);
      font-size: 16px;
      letter-spacing: -0.5px;
      padding: 0 8px 12px;
      margin-right: 24px;
      height: 36px; }
      .chatbot-setup-page-wrapper .knowledge-source .knowledge-source-input-wrapper .nav-item a {
        padding: 0;
        border: none; }
      .chatbot-setup-page-wrapper .knowledge-source .knowledge-source-input-wrapper .nav-item :hover {
        color: var(--main-dark-orange); }
      .chatbot-setup-page-wrapper .knowledge-source .knowledge-source-input-wrapper .nav-item.active-setup-tab {
        color: var(--main-dark-orange);
        border-bottom: solid 4px var(--main-dark-orange); }
    .chatbot-setup-page-wrapper .knowledge-source .knowledge-source-input-wrapper .tab-content {
      margin: 16px 0 0; }

.chatbot-setup-page-wrapper .pending-changes-wrapper {
  margin-top: 3px;
  display: inline-block;
  vertical-align: top;
  margin: 60px 0 0 24px;
  width: calc(50% - 24px); }
  .chatbot-setup-page-wrapper .pending-changes-wrapper .pending-changes {
    margin-bottom: 16px;
    background: #FAFAFA;
    border: none; }
    .chatbot-setup-page-wrapper .pending-changes-wrapper .pending-changes span {
      display: block;
      padding-bottom: 8px;
      width: 100%;
      font-size: 16px;
      border-bottom: solid 1px #1919191A; }
      .chatbot-setup-page-wrapper .pending-changes-wrapper .pending-changes span b {
        line-height: 24px;
        letter-spacing: -0.5px;
        color: #191919; }
      .chatbot-setup-page-wrapper .pending-changes-wrapper .pending-changes span svg {
        margin-left: 4px;
        color: #19191999;
        -webkit-transform: translateY(-1px);
                transform: translateY(-1px);
        cursor: pointer; }
    .chatbot-setup-page-wrapper .pending-changes-wrapper .pending-changes .pending-changes-discard {
      position: absolute;
      top: 32px;
      right: 32px;
      padding: 0 12px 0 8px;
      width: 136px;
      height: 26px;
      border: none;
      cursor: pointer; }
    .chatbot-setup-page-wrapper .pending-changes-wrapper .pending-changes .trained-sources-discard {
      position: absolute;
      top: 32px;
      right: 32px;
      padding: 0 12px 0 8px;
      width: 94px;
      height: 26px;
      border: none;
      cursor: pointer; }
    .chatbot-setup-page-wrapper .pending-changes-wrapper .pending-changes .pending-changes-discard svg, .chatbot-setup-page-wrapper .pending-changes-wrapper .pending-changes .trained-sources-discard svg {
      margin: 0;
      font-size: 12px;
      color: #FF3F57; }
    .chatbot-setup-page-wrapper .pending-changes-wrapper .pending-changes .pending-changes-discard b, .chatbot-setup-page-wrapper .pending-changes-wrapper .pending-changes .trained-sources-discard b {
      margin-left: 4px;
      font-size: 14px;
      letter-spacing: -0.5px;
      color: #FF3F57; }
  .chatbot-setup-page-wrapper .pending-changes-wrapper .pending-changes-empty {
    padding: 24px 12px 12px 12px;
    width: 100%;
    text-align: center;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: -0.2px;
    color: #19191999; }
  .chatbot-setup-page-wrapper .pending-changes-wrapper .pending-change-list {
    position: relative;
    width: 100%;
    height: 44px;
    border-bottom: solid 1px #1919191A; }
    .chatbot-setup-page-wrapper .pending-changes-wrapper .pending-change-list .pending-change-list-new {
      display: inline-block;
      margin: 12.5px 4px 0 0;
      padding: 2px 8px;
      width: 42px;
      height: 19px;
      background-color: #EBF7FF;
      border-radius: 4px;
      text-align: center;
      font-family: PTRootMedium;
      font-size: 10px;
      line-height: 15px;
      letter-spacing: 1.5px;
      color: #0C5F99; }
    .chatbot-setup-page-wrapper .pending-changes-wrapper .pending-change-list .pending-change-list-remove {
      display: inline-block;
      margin: 12.5px 4px 0 0;
      padding: 2px 8px;
      width: 63px;
      height: 19px;
      background-color: #FFECEE;
      border-radius: 4px;
      text-align: center;
      font-family: PTRootMedium;
      font-size: 10px;
      line-height: 15px;
      letter-spacing: 1.5px;
      color: #FF3F57; }
    .chatbot-setup-page-wrapper .pending-changes-wrapper .pending-change-list .trained-source-list-default {
      display: inline-block;
      margin: 8px 0 0 0;
      padding: 0px;
      width: 0px;
      height: 19px;
      background-color: white;
      text-align: center;
      font-family: PTRootMedium;
      font-size: 10px;
      line-height: 15px;
      letter-spacing: 1.5px;
      color: white; }
    .chatbot-setup-page-wrapper .pending-changes-wrapper .pending-change-list .pending-change-list-text-container {
      display: inline-block;
      margin: 0 8px 0 0; }
      .chatbot-setup-page-wrapper .pending-changes-wrapper .pending-change-list .pending-change-list-text-container .pending-change-list-name {
        display: inline-block;
        vertical-align: middle;
        max-width: calc(100% - 40px);
        font-size: 14px;
        line-height: 21px;
        letter-spacing: -0.2px;
        color: #191919;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden; }
      .chatbot-setup-page-wrapper .pending-changes-wrapper .pending-change-list .pending-change-list-text-container .pending-change-list-extension {
        display: inline-block;
        vertical-align: middle;
        font-size: 14px;
        line-height: 21px;
        letter-spacing: -0.2px;
        color: #191919; }
    .chatbot-setup-page-wrapper .pending-changes-wrapper .pending-change-list .width-new {
      width: calc(100% - 114px); }
    .chatbot-setup-page-wrapper .pending-changes-wrapper .pending-change-list .width-remove {
      width: calc(100% - 135px); }
    .chatbot-setup-page-wrapper .pending-changes-wrapper .pending-change-list .width-default {
      width: calc(100% - 70px); }
    .chatbot-setup-page-wrapper .pending-changes-wrapper .pending-change-list .pending-change-list-button-wrapper {
      position: absolute;
      top: 8px;
      right: 4px; }
      .chatbot-setup-page-wrapper .pending-changes-wrapper .pending-change-list .pending-change-list-button-wrapper svg, .chatbot-setup-page-wrapper .pending-changes-wrapper .pending-change-list .pending-change-list-button-wrapper img {
        display: inline-block;
        margin-right: 4px;
        width: 24px;
        height: 24px;
        padding: 4px;
        border-radius: 4px;
        cursor: pointer; }
      .chatbot-setup-page-wrapper .pending-changes-wrapper .pending-change-list .pending-change-list-button-wrapper .color-orange {
        color: #FF7E00;
        -webkit-transition: ease-in-out 0.2s;
        transition: ease-in-out 0.2s; }
        .chatbot-setup-page-wrapper .pending-changes-wrapper .pending-change-list .pending-change-list-button-wrapper .color-orange:hover {
          background-color: #FFF2E5; }
      .chatbot-setup-page-wrapper .pending-changes-wrapper .pending-change-list .pending-change-list-button-wrapper .color-red {
        color: #FF3F57;
        -webkit-transition: ease-in-out 0.2s;
        transition: ease-in-out 0.2s; }
        .chatbot-setup-page-wrapper .pending-changes-wrapper .pending-change-list .pending-change-list-button-wrapper .color-red:hover {
          background-color: #FFECEE; }
      .chatbot-setup-page-wrapper .pending-changes-wrapper .pending-change-list .pending-change-list-button-wrapper .color-black {
        color: #191919;
        -webkit-transition: ease-in-out 0.2s;
        transition: ease-in-out 0.2s; }
        .chatbot-setup-page-wrapper .pending-changes-wrapper .pending-change-list .pending-change-list-button-wrapper .color-black:hover {
          background-color: #EFEFEF; }
  .chatbot-setup-page-wrapper .pending-changes-wrapper .trained-source-list {
    margin-top: 0px; }

.knowledge-button-wrapper {
  position: relative;
  top: 6px; }

.tooltip-source-type .bs-tooltip-auto[x-placement^=right] .arrow::before, .tooltip-source-type .bs-tooltip-right .arrow::before {
  border-right-color: #6B6B6B !important; }

.tooltip-source-type .tooltip .arrow::before {
  border-color: transparent !important; }

.tooltip-source-type .tooltip-inner {
  padding: 6px 8px !important;
  min-width: 240px !important;
  font-family: "PTRootUI" !important;
  font-size: 12px !important;
  line-height: 16px !important;
  letter-spacing: -0.2px !important;
  border-radius: 8px !important;
  color: #FFFFFF !important;
  background: #6B6B6B !important;
  opacity: 1 !important; }
  .tooltip-source-type .tooltip-inner ul {
    margin: 0;
    padding-left: 24px; }
    .tooltip-source-type .tooltip-inner ul li {
      margin: 2px 0; }

.tooltip-pending-changes .bs-tooltip-bottom .arrow::before, .tooltip-pending-changes .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
  border-bottom-color: #6B6B6B; }

.tooltip-pending-changes .bs-tooltip-top .arrow::before, .tooltip-pending-changes .bs-tooltip-auto[x-placement^="top"] .arrow::before {
  border-top-color: #6B6B6B; }

.tooltip-pending-changes .tooltip.show {
  opacity: 1 !important;
  margin-top: 4px; }

.tooltip-pending-changes .tooltip-inner {
  padding: 8px 16px !important;
  margin-left: -16px;
  min-width: 224px !important;
  font-family: "PTRootUI" !important;
  font-size: 12px !important;
  line-height: 16px !important;
  letter-spacing: -0.2px !important;
  border-radius: 8px !important;
  color: #FFFFFF !important;
  background: #6B6B6B !important;
  opacity: 1 !important; }

.modal-view-text-source {
  width: 35vw !important; }
  .modal-view-text-source .modal-content {
    position: relative !important;
    padding: 0;
    max-height: 85vh;
    border-radius: 8px;
    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none; }
    .modal-view-text-source .modal-content .modal-body {
      position: relative;
      padding: 40px 32px 16px 32px; }
      .modal-view-text-source .modal-content .modal-body .close-button {
        position: absolute;
        top: 16px;
        right: 16px;
        width: 24px;
        height: 24px;
        color: #FF7E00;
        cursor: pointer; }
      .modal-view-text-source .modal-content .modal-body .modal-view-text-source-field {
        margin-bottom: 16px; }
        .modal-view-text-source .modal-content .modal-body .modal-view-text-source-field .modal-view-text-source-field-title {
          font-size: 16px;
          line-height: 24px;
          letter-spacing: -0.5px;
          color: #191919; }
        .modal-view-text-source .modal-content .modal-body .modal-view-text-source-field .modal-view-text-source-field-content {
          margin: 8px 0 0 0;
          padding: 12px 16px;
          border-radius: 8px;
          background: #1919191A;
          font-size: 16px;
          line-height: 24px;
          letter-spacing: -0.3px;
          color: #191919CC;
          word-wrap: break-word;
          white-space: pre-line; }

.source-document-wrapper {
  border: dashed 2px #1919191A;
  border-radius: 8px; }
  .source-document-wrapper .source-document-content-wrapper {
    padding: 32px 32px 54px 32px;
    text-align: center;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: -0.2px;
    background: #fafafa; }
    .source-document-wrapper .source-document-content-wrapper .icon-cloud {
      color: var(--main-dark-orange);
      font-size: 64px;
      display: inline-block;
      margin: 0 0 16px 0; }
    .source-document-wrapper .source-document-content-wrapper .chat-room-input-email-placeholder {
      display: block;
      width: 100%;
      text-align: center; }
    .source-document-wrapper .source-document-content-wrapper .main-button-40 {
      margin: 16px 0 0;
      background: white; }
      .source-document-wrapper .source-document-content-wrapper .main-button-40:hover {
        background-color: rgba(255, 126, 0, 0.05); }

.source-text-wrapper {
  padding: 0.1px 16px 16px 16px;
  border-radius: 8px;
  background: #FAFAFA; }
  .source-text-wrapper .form-content-wrapper label {
    margin: 16px 0 8px 0; }
  .source-text-wrapper .form-content-wrapper input {
    width: 100%; }
  .source-text-wrapper .form-content-wrapper textarea {
    display: block;
    width: 100%;
    overflow: hidden; }
  .source-text-wrapper .width-100 {
    max-width: 100% !important; }
  .source-text-wrapper .main-button-40 {
    margin-top: 16px;
    background: white; }
    .source-text-wrapper .main-button-40:hover {
      background-color: rgba(255, 126, 0, 0.05); }

.modal-loading .modal-content {
  box-sizing: border-box;
  width: 450px;
  padding: 48px;
  border-radius: 32px; }

.modal-loading .modal-body {
  padding: 0;
  text-align: center; }
  .modal-loading .modal-body .lds-ring {
    display: block;
    position: relative;
    margin: auto;
    margin-bottom: 10px;
    top: 1px;
    width: 88px;
    height: 88px; }
    .modal-loading .modal-body .lds-ring div {
      width: 72px;
      height: 72px;
      border-width: 7.2px; }
  .modal-loading .modal-body b.loading-title {
    margin: 0;
    font-size: 24px;
    line-height: 30px;
    letter-spacing: -0.6px;
    color: #191919; }
  .modal-loading .modal-body p.loading-description {
    margin: 10px 0 0;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.3px;
    color: #191919; }

.modal-loading .modal-footer {
  margin: 32px 0 0;
  padding: 0;
  border: none; }
  .modal-loading .modal-footer button {
    margin: 0;
    width: 100%; }

.modal-error .modal-content {
  box-sizing: border-box;
  width: 450px;
  padding: 48px 48px 40px 48px;
  border-radius: 32px; }

.modal-error .modal-body {
  padding: 0;
  text-align: center; }
  .modal-error .modal-body .error-icon {
    margin: auto;
    margin-bottom: 10px;
    padding: 9px;
    width: 88px;
    height: 88px;
    border-radius: 44px;
    background: #FF3F5733; }
    .modal-error .modal-body .error-icon svg {
      padding: 13px;
      width: 70px;
      height: 70px;
      border-radius: 35px;
      background: #FF3F57;
      color: white; }
  .modal-error .modal-body b.error-title {
    margin: 0;
    font-size: 24px;
    line-height: 30px;
    letter-spacing: -0.6px;
    color: #191919; }
  .modal-error .modal-body p.error-description {
    margin: 10px 0 4px;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.3px;
    color: #191919; }
  .modal-error .modal-body .error-list-wrapper {
    margin-top: 28px;
    width: 100%;
    max-height: 132px;
    text-align: center;
    overflow: auto; }
    .modal-error .modal-body .error-list-wrapper .error-list {
      margin: auto;
      margin-bottom: 4px;
      padding: 4px 24px;
      max-width: calc(100% - 24px);
      height: 36px;
      border-radius: 8px;
      background: #1919190D; }
      .modal-error .modal-body .error-list-wrapper .error-list svg {
        display: inline;
        margin-right: 4px;
        font-size: 20px;
        color: #19191999; }
      .modal-error .modal-body .error-list-wrapper .error-list .error-list-text-container {
        display: inline;
        margin: 0;
        max-width: calc(100% - 24px); }
        .modal-error .modal-body .error-list-wrapper .error-list .error-list-text-container .error-list-name {
          display: inline-block;
          vertical-align: middle;
          max-width: calc(100% - 50px);
          font-family: "PTRootBold";
          font-size: 16px;
          line-height: 20px;
          letter-spacing: -0.5px;
          color: #19191999;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden; }
          .modal-error .modal-body .error-list-wrapper .error-list .error-list-text-container .error-list-name b {
            display: inline-block;
            width: 100%;
            max-width: 100%; }
        .modal-error .modal-body .error-list-wrapper .error-list .error-list-text-container .error-list-extension {
          display: inline-block;
          vertical-align: middle;
          font-family: "PTRootBold";
          font-size: 16px;
          line-height: 20px;
          letter-spacing: -0.5px;
          color: #19191999; }
    .modal-error .modal-body .error-list-wrapper::-webkit-scrollbar {
      width: 12px; }
    .modal-error .modal-body .error-list-wrapper::-webkit-scrollbar-thumb {
      border: 4px solid #00000000;
      border-radius: 8px;
      background-color: #1919194D;
      background-clip: padding-box; }

.modal-error .modal-footer {
  margin: 28px 0 0;
  padding: 0;
  border: none; }
  .modal-error .modal-footer button {
    margin: 0 0 8px 0;
    width: 100%; }

.chatgpt-setup-handover-page-wrapper {
  margin-top: 24px; }
  .chatgpt-setup-handover-page-wrapper .top-info {
    width: 460px; }
    .chatgpt-setup-handover-page-wrapper .top-info b {
      font-family: "PTRootUI";
      font-size: 14px;
      font-weight: 400;
      line-height: 21px;
      letter-spacing: -0.2px;
      color: #191919; }
  .chatgpt-setup-handover-page-wrapper .top-title {
    width: 100%;
    display: block;
    position: relative;
    font-size: 20px;
    letter-spacing: -0.6px;
    margin: 0 0 16px; }
    .chatgpt-setup-handover-page-wrapper .top-title button {
      position: absolute;
      top: 0;
      right: 0;
      width: 180px; }
    .chatgpt-setup-handover-page-wrapper .top-title .top-subtitle p {
      display: inline-block;
      vertical-align: top;
      margin: 0;
      font-size: 16px;
      letter-spacing: -0.3px;
      color: rgba(25, 25, 25, 0.8);
      -webkit-transform: translateY(-2px);
              transform: translateY(-2px); }
    .chatgpt-setup-handover-page-wrapper .top-title .top-subtitle .gpt-handover-switch-wrapper {
      display: inline-block;
      vertical-align: top;
      margin: 0 8px 0 0; }
      .chatgpt-setup-handover-page-wrapper .top-title .top-subtitle .gpt-handover-switch-wrapper .custom-switch-wrapper {
        margin: 0;
        height: 20px;
        width: 32px; }
        .chatgpt-setup-handover-page-wrapper .top-title .top-subtitle .gpt-handover-switch-wrapper .custom-switch-wrapper .custom-switch:before {
          width: 12px;
          height: 12px;
          bottom: 4px;
          -webkit-transform: translateX(-4px);
                  transform: translateX(-4px); }
      .chatgpt-setup-handover-page-wrapper .top-title .top-subtitle .gpt-handover-switch-wrapper input:checked + .custom-switch:before {
        -webkit-transform: translateX(12px);
                transform: translateX(12px); }
      .chatgpt-setup-handover-page-wrapper .top-title .top-subtitle .gpt-handover-switch-wrapper[disabled] input:checked + .custom-switch {
        background-color: #19191933; }
  .chatgpt-setup-handover-page-wrapper .criteria-list-wrapper {
    margin-top: 32px; }
  .chatgpt-setup-handover-page-wrapper .form-content-wrapper label {
    font-size: 16px;
    letter-spacing: -0.5px;
    margin-top: 8px; }
  .chatgpt-setup-handover-page-wrapper .form-content-wrapper input[type="text"] {
    width: 468px; }
  .chatgpt-setup-handover-page-wrapper .criteria-wrapper {
    padding: 8px 16px 16px 16px;
    background: #f3f3f3;
    border: solid 1px rgba(25, 25, 25, 0.1);
    border-radius: 8px;
    margin: 8px 0 0;
    position: relative;
    width: 500px; }
    .chatgpt-setup-handover-page-wrapper .criteria-wrapper .dropdown button {
      margin: 0 !important; }
    .chatgpt-setup-handover-page-wrapper .criteria-wrapper .button-delete {
      color: #ff3f57;
      font-size: 20px;
      position: absolute;
      top: 16px;
      right: 16px;
      padding: 1px;
      cursor: pointer; }
    .chatgpt-setup-handover-page-wrapper .criteria-wrapper label {
      position: relative; }
      .chatgpt-setup-handover-page-wrapper .criteria-wrapper label:nth-child(1) {
        padding: 0 !important; }
      .chatgpt-setup-handover-page-wrapper .criteria-wrapper label .grey-font {
        display: inline-block; }
      .chatgpt-setup-handover-page-wrapper .criteria-wrapper label span {
        color: rgba(25, 25, 25, 0.6);
        font-size: 14px;
        letter-spacing: -0.2px;
        position: absolute;
        right: 0;
        bottom: 0; }
        .chatgpt-setup-handover-page-wrapper .criteria-wrapper label span.right-18 {
          right: 18px !important; }
  .chatgpt-setup-handover-page-wrapper .add-another-criteria {
    margin: 8px 0 0;
    font-size: 14px;
    letter-spacing: -0.5px;
    color: var(--main-dark-orange);
    cursor: pointer;
    padding: 0 12px 0 8px !important;
    border: none !important; }
    .chatgpt-setup-handover-page-wrapper .add-another-criteria svg {
      position: relative;
      top: -2px;
      margin: 0 4px 0 0; }
    .chatgpt-setup-handover-page-wrapper .add-another-criteria:hover {
      color: var(--main-darker-orange); }

.chatbot-setup-page-wrapper .prompt-settings-title {
  margin-bottom: 4px;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: -0.6px;
  color: #191919; }

.chatbot-setup-page-wrapper .prompt-settings-description {
  margin: 8px 0 0 0;
  padding-bottom: 12px;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.3px;
  color: #191919CC;
  border-bottom: solid 1px #1919191A; }

.chatbot-setup-page-wrapper .form-content-wrapper {
  position: relative;
  max-width: 600px; }
  .chatbot-setup-page-wrapper .form-content-wrapper .input-counter {
    position: absolute;
    top: 0;
    right: 0; }
  .chatbot-setup-page-wrapper .form-content-wrapper input {
    width: 100%; }
  .chatbot-setup-page-wrapper .form-content-wrapper .dropdown {
    width: 100%; }
    .chatbot-setup-page-wrapper .form-content-wrapper .dropdown button {
      margin-top: 0px; }
  .chatbot-setup-page-wrapper .form-content-wrapper .optional {
    font-size: 14px;
    line-height: 21px;
    letter-spacing: -0.2px;
    color: #19191966; }
  .chatbot-setup-page-wrapper .form-content-wrapper .brand-description-wrapper {
    width: 100%; }
    .chatbot-setup-page-wrapper .form-content-wrapper .brand-description-wrapper .input-brand-description {
      display: block;
      margin: 4px 0;
      width: 100%;
      height: 338px;
      overflow: auto;
      resize: none; }
      .chatbot-setup-page-wrapper .form-content-wrapper .brand-description-wrapper .input-brand-description::-webkit-scrollbar {
        width: 12px; }
      .chatbot-setup-page-wrapper .form-content-wrapper .brand-description-wrapper .input-brand-description::-webkit-scrollbar-thumb {
        border: 4px solid #00000000;
        border-radius: 8px;
        background-color: #1919194D;
        background-clip: padding-box; }
  .chatbot-setup-page-wrapper .form-content-wrapper .dropdown-model .error-or-info-comp a {
    color: var(--main-dark-orange); }
  .chatbot-setup-page-wrapper .form-content-wrapper .dropdown-model .error-or-info-comp a:hover {
    color: var(--main-dark-orange);
    text-decoration: underline !important; }
  .chatbot-setup-page-wrapper .form-content-wrapper .dropdown-model .error-or-info-comp a:active {
    color: var(--main-darker-orange);
    text-decoration: underline !important; }
  .chatbot-setup-page-wrapper .form-content-wrapper .button-save {
    width: 180px !important; }

.chatgpt-intro-wrapper .top-title {
  width: 100%;
  display: block;
  position: relative;
  font-size: 20px;
  letter-spacing: -0.6px;
  margin: 0 0 12px;
  padding: 0 0 12px 0;
  border-bottom: solid 1px #dcdcdc; }

.chatgpt-intro-wrapper .chatgpt-intro-card-wrapper {
  width: 100%;
  position: relative;
  display: block; }
  .chatgpt-intro-wrapper .chatgpt-intro-card-wrapper .chatgpt-intro-card-outer {
    margin: 24px 0 0; }
    .chatgpt-intro-wrapper .chatgpt-intro-card-wrapper .chatgpt-intro-card-outer .intro-title {
      width: 100%;
      position: relative;
      display: block;
      margin: 0 0 12px 0;
      white-space: nowrap; }
      .chatgpt-intro-wrapper .chatgpt-intro-card-wrapper .chatgpt-intro-card-outer .intro-title .intro-number {
        width: 26px;
        height: 26px;
        border-radius: 50%;
        background: rgba(25, 25, 25, 0.05);
        position: relative;
        text-align: center;
        margin: 0 8px 0 0;
        line-height: 25px;
        display: inline-block;
        font-size: 14px; }
      .chatgpt-intro-wrapper .chatgpt-intro-card-wrapper .chatgpt-intro-card-outer .intro-title .intro-title-text {
        font-size: 20px;
        letter-spacing: -0.6px;
        position: relative;
        top: 2px; }
    .chatgpt-intro-wrapper .chatgpt-intro-card-wrapper .chatgpt-intro-card-outer .intro-card {
      padding: 24px;
      background: rgba(25, 25, 25, 0.05);
      border-top: solid 1px #dcdcdc; }
      .chatgpt-intro-wrapper .chatgpt-intro-card-wrapper .chatgpt-intro-card-outer .intro-card .intro-card-image {
        width: 240px;
        padding: 0;
        vertical-align: top;
        display: inline-block; }
        .chatgpt-intro-wrapper .chatgpt-intro-card-wrapper .chatgpt-intro-card-outer .intro-card .intro-card-image img {
          width: 240px; }
      .chatgpt-intro-wrapper .chatgpt-intro-card-wrapper .chatgpt-intro-card-outer .intro-card .intro-html-wrapper {
        width: calc(100% - 240px);
        padding: 0 0 0 24px;
        display: inline-block;
        font-size: 14px;
        letter-spacing: -0.4px;
        line-height: 21px;
        vertical-align: top; }
        .chatgpt-intro-wrapper .chatgpt-intro-card-wrapper .chatgpt-intro-card-outer .intro-card .intro-html-wrapper ul {
          padding-left: 23px; }

.chatgpt-intro-wrapper .continue-button-wrapper {
  margin-top: 24px;
  width: 100%;
  text-align: right; }
  .chatgpt-intro-wrapper .continue-button-wrapper .nav-link {
    padding: 0; }
  .chatgpt-intro-wrapper .continue-button-wrapper button {
    width: 180px; }

.webhook2-main-wrapper .event-wrap {
  padding: 0 0 0 20px;
  position: relative; }
  .webhook2-main-wrapper .event-wrap span {
    font-size: 16px;
    letter-spacing: -0.3px;
    color: #191919;
    word-break: break-word; }
  .webhook2-main-wrapper .event-wrap p {
    font-size: 14px;
    letter-spacing: -0.2px;
    color: rgba(25, 25, 25, 0.6);
    margin: 0 0 8px 0;
    word-break: break-word; }
    .webhook2-main-wrapper .event-wrap p:nth-last-child(1) {
      margin: 0; }
  .webhook2-main-wrapper .event-wrap svg {
    position: absolute;
    top: 4px;
    left: 0;
    font-size: 16px; }
  .webhook2-main-wrapper .event-wrap .icon-check {
    color: var(--main-dark-orange); }
  .webhook2-main-wrapper .event-wrap .icon-x {
    color: var(--main-dark-red); }

.webhook2-main-wrapper .webhook2-main-wrapper-title-wrapper {
  position: relative;
  width: 100%; }
  .webhook2-main-wrapper .webhook2-main-wrapper-title-wrapper .title {
    font-size: 20px;
    letter-spacing: -0.6px;
    color: #191919; }
  .webhook2-main-wrapper .webhook2-main-wrapper-title-wrapper .no-fill-button {
    position: absolute;
    right: 0;
    top: 0;
    width: 181px; }
  .webhook2-main-wrapper .webhook2-main-wrapper-title-wrapper .red-button {
    top: 0;
    right: 156px;
    position: absolute; }

.webhook2-main-wrapper .webhook2-list-info {
  padding: 0 200px 12px 0;
  border-bottom: solid 1px rgba(25, 25, 25, 0.1); }

.webhook2-main-wrapper .label-title {
  font-size: 16px;
  letter-spacing: -0.5px;
  margin: 24px 0 8px 0;
  color: #191919; }

.modal-remove-webhook button {
  width: calc(50% - 2px) !important;
  display: inline-block;
  margin-right: 4px !important; }
  .modal-remove-webhook button:nth-child(2) {
    margin-right: 0 !important; }

.copy-box-wrapper {
  border: 1px solid rgba(25, 25, 25, 0.1) !important;
  display: block;
  position: relative;
  background: #fff;
  border-radius: 8px; }
  .copy-box-wrapper input {
    border: 0 !important;
    white-space: nowrap;
    background: #fff;
    padding: 8px 48px 8px 16px !important;
    color: #191919;
    border-radius: 8px;
    font-size: 16px;
    letter-spacing: -0.3px;
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden; }
    .copy-box-wrapper input.grey-box {
      color: rgba(25, 25, 25, 0.4); }
  .copy-box-wrapper svg {
    position: absolute;
    top: 50%;
    -webkit-transform: translate(0, -50%);
            transform: translate(0, -50%);
    right: 16px;
    color: var(--main-dark-orange) !important;
    cursor: pointer;
    font-size: 20px; }
    .copy-box-wrapper svg:hover {
      color: var(--main-darker-orange) !important; }

.disabled-enabled-wrapper {
  width: 100%;
  display: block; }
  .disabled-enabled-wrapper p {
    font-size: 16px;
    letter-spacing: -0.5px;
    margin: 0; }
    .disabled-enabled-wrapper p.disabled-text {
      color: #ff3f57; }
    .disabled-enabled-wrapper p.enabled-text {
      color: #19c700; }
    .disabled-enabled-wrapper p img {
      margin-right: 4px;
      position: relative;
      top: -2px; }

.webhook2-main-setup-wrapper .custom-checkbox label {
  font-size: 16px;
  letter-spacing: -0.3px; }
  .webhook2-main-setup-wrapper .custom-checkbox label p {
    font-size: 14px;
    letter-spacing: -0.2px;
    color: rgba(25, 25, 25, 0.6);
    margin: 0;
    word-break: break-word; }
  .webhook2-main-setup-wrapper .custom-checkbox label span {
    word-break: break-word; }

.webhook2-main-setup-wrapper .custom-checkbox [type="checkbox"]:checked + label:before {
  top: 4px; }

.webhook2-main-setup-wrapper .custom-checkbox [type="checkbox"]:checked + label:after {
  top: -1px; }

.webhook2-main-setup-wrapper .custom-checkbox:nth-last-child(1) label {
  margin: 0; }

.webhook2-main-setup-wrapper .webhook2-main-setup-wrapper-title-wrapper {
  position: relative;
  width: 100%;
  margin: 0 0 10px 0; }
  .webhook2-main-setup-wrapper .webhook2-main-setup-wrapper-title-wrapper .title {
    font-size: 20px;
    letter-spacing: -0.6px;
    color: #191919; }
  .webhook2-main-setup-wrapper .webhook2-main-setup-wrapper-title-wrapper .orange-button {
    position: absolute;
    right: 0;
    top: -5px; }
  .webhook2-main-setup-wrapper .webhook2-main-setup-wrapper-title-wrapper .red-button {
    top: 0;
    right: 156px;
    position: absolute; }

.webhook2-main-setup-wrapper .webhook2-list-info {
  padding: 0 158px 8px 0;
  border-bottom: solid 1px rgba(25, 25, 25, 0.1); }

.webhook2-main-setup-wrapper .label-title {
  font-size: 16px;
  letter-spacing: -0.5px;
  margin: 24px 0 8px 0;
  color: #191919;
  width: 100%;
  position: relative; }

.webhook2-main-setup-wrapper .label-desc {
  font-size: 14px;
  letter-spacing: -0.2px;
  color: rgba(25, 25, 25, 0.8);
  margin: 0; }

.webhook2-main-setup-wrapper .custom-switch-wrapper {
  display: inline-block !important;
  width: 40px;
  height: 24px;
  position: absolute;
  top: 0;
  right: 0;
  margin: 0; }
  .webhook2-main-setup-wrapper .custom-switch-wrapper .custom-switch.round::before {
    width: 14px;
    height: 14px;
    bottom: 5px;
    left: 4px; }
  .webhook2-main-setup-wrapper .custom-switch-wrapper input:checked + .custom-switch:before {
    -webkit-transform: translateX(18px);
            transform: translateX(18px); }

.webhook2-main-setup-wrapper .input-webhook {
  border: solid 1px rgba(25, 25, 25, 0.1);
  font-size: 16px;
  padding: 8px 16px;
  width: 100%;
  border-radius: 8px;
  margin: 0 0 8px 0; }
  .webhook2-main-setup-wrapper .input-webhook:focus {
    border-color: #ff7d00; }

.modal-remove-webhook button {
  width: calc(50% - 2px) !important;
  display: inline-block;
  margin-right: 4px !important; }
  .modal-remove-webhook button:nth-child(2) {
    margin-right: 0 !important; }

.salestalk-main-wrapper .salestalk-main-desc {
  margin: 18px 0 0;
  font-size: 16px;
  letter-spacing: -0.3px; }

.salestalk-main-wrapper .button-connect {
  width: 277px; }
  .salestalk-main-wrapper .button-connect img {
    width: 24px; }

.salestalk-main-wrapper .topContent .title {
  width: 100%;
  color: #191919;
  font-size: 20px;
  letter-spacing: -0.6px;
  line-height: 24px;
  padding-bottom: 26px;
  border-bottom: solid 1px rgba(25, 25, 25, 0.1);
  margin: 0; }

.salestalk-main-wrapper .topContent button {
  position: absolute;
  right: 32px;
  top: 32px;
  vertical-align: top; }
  .salestalk-main-wrapper .topContent button.no-fill-button {
    right: 172px; }

.salestalk-main-wrapper .topContent-custom-provider {
  position: relative;
  border-bottom: solid 1px #dcdcdc; }
  .salestalk-main-wrapper .topContent-custom-provider .title b {
    font-size: 20px;
    letter-spacing: -0.6px; }
  .salestalk-main-wrapper .topContent-custom-provider .title p {
    font-size: 16px;
    letter-spacing: -0.3px; }
    .salestalk-main-wrapper .topContent-custom-provider .title p a {
      color: #ff7d00; }
      .salestalk-main-wrapper .topContent-custom-provider .title p a:hover {
        color: var(--main-darker-orange); }
  .salestalk-main-wrapper .topContent-custom-provider button {
    position: absolute;
    right: 0;
    top: 0; }

.salestalk-main-wrapper img {
  margin-right: 4px;
  position: relative;
  top: -2px; }

.salestalk-main-wrapper .sales-talk-main-view {
  font-size: 16px;
  letter-spacing: -0.3px;
  margin-top: 24px; }
  .salestalk-main-wrapper .sales-talk-main-view .label-title {
    margin: 24px 0 8px 0;
    font-size: 16px;
    letter-spacing: -0.2px; }
  .salestalk-main-wrapper .sales-talk-main-view .dont-have-account {
    font-size: 14px;
    letter-spacing: -0.2px;
    color: rgba(25, 25, 25, 0.8);
    margin: 10px 0 0; }
    .salestalk-main-wrapper .sales-talk-main-view .dont-have-account a {
      color: #ff7d00; }
      .salestalk-main-wrapper .sales-talk-main-view .dont-have-account a:hover {
        color: var(--main-darker-orange); }
  .salestalk-main-wrapper .sales-talk-main-view .for-more-details {
    font-size: 16px;
    letter-spacing: -0.3px;
    color: rgba(25, 25, 25, 0.8);
    margin: 10px 0 20px; }
    .salestalk-main-wrapper .sales-talk-main-view .for-more-details a {
      color: #ff7d00; }
      .salestalk-main-wrapper .sales-talk-main-view .for-more-details a:hover {
        color: var(--main-darker-orange); }
  .salestalk-main-wrapper .sales-talk-main-view ul {
    padding: 0 0 0 18px; }
    .salestalk-main-wrapper .sales-talk-main-view ul li a {
      color: #ff7d00; }
      .salestalk-main-wrapper .sales-talk-main-view ul li a:hover {
        text-decoration: underline !important; }

.salestalk-main-wrapper .integration-salestalk-error {
  width: 100%;
  text-align: center;
  margin: 38px 0 12px; }
  .salestalk-main-wrapper .integration-salestalk-error p {
    margin: 14px 0 12px;
    font-size: 16px;
    letter-spacing: -0.3px;
    color: rgba(25, 25, 25, 0.6); }

.modal-salestalk .modal-content {
  width: 476px;
  border-radius: 8px; }

.modal-confirmation-disconnect-salestalk button {
  width: calc(50% - 4px) !important;
  margin-right: 8px; }
  .modal-confirmation-disconnect-salestalk button:nth-last-child(1) {
    margin-right: 0; }

.integration-salestalk-login label {
  display: block;
  font-size: 14px;
  letter-spacing: -0.4px;
  margin: 24px 0 4px 0; }

.integration-salestalk-login .account-photo-wrapper {
  border-radius: 4px;
  background: #f3f3f3;
  width: 96px;
  height: 96px;
  position: relative; }
  .integration-salestalk-login .account-photo-wrapper svg {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    font-size: 34px;
    color: #9c9c9c; }
  .integration-salestalk-login .account-photo-wrapper img {
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }

.integration-salestalk-login .account-name {
  font-size: 20px;
  letter-spacing: -0.6px; }

.integration-salestalk-login .status-salestalk {
  color: #19C700;
  font-size: 16px;
  letter-spacing: -0.5px; }
  .integration-salestalk-login .status-salestalk img {
    position: relative;
    top: -1px; }

.modal-login-salestalk .modal-content {
  border-radius: 12px;
  width: 500px; }
  .modal-login-salestalk .modal-content .modal-body {
    text-align: center;
    padding: 80px;
    position: relative; }
    .modal-login-salestalk .modal-content .modal-body .loading-login {
      width: 26px;
      height: 26px;
      margin-top: 10px; }
      .modal-login-salestalk .modal-content .modal-body .loading-login div {
        width: 26px;
        height: 26px;
        border: solid 3px #ff7d00;
        border-color: #ff7d00 transparent transparent transparent;
        margin: 0; }
    .modal-login-salestalk .modal-content .modal-body .modal-login-salestalk-close {
      color: #ff7d00;
      position: absolute;
      right: 12px;
      top: 16px;
      font-size: 28px;
      cursor: pointer; }
      .modal-login-salestalk .modal-content .modal-body .modal-login-salestalk-close:hover {
        color: #ff7d00; }
    .modal-login-salestalk .modal-content .modal-body .modal-login-salestalk-title {
      font-size: 28px;
      margin: 0 0 0;
      line-height: 1;
      color: #1a1a1a; }
      .modal-login-salestalk .modal-content .modal-body .modal-login-salestalk-title b {
        font-family: NeueBold !important; }
    .modal-login-salestalk .modal-content .modal-body .modal-login-salestalk-desc {
      font-size: 13px;
      margin: 5px 0 28px 0;
      color: #707070;
      font-family: LatoMedium !important; }
    .modal-login-salestalk .modal-content .modal-body .modal-login-salestalk-phone-number {
      position: relative;
      text-align: left;
      margin: 0 0 10px 0; }
      .modal-login-salestalk .modal-content .modal-body .modal-login-salestalk-phone-number label {
        font-size: 16px; }
        .modal-login-salestalk .modal-content .modal-body .modal-login-salestalk-phone-number label b {
          font-family: LatoBold !important; }
      .modal-login-salestalk .modal-content .modal-body .modal-login-salestalk-phone-number span {
        position: absolute;
        font-size: 16px;
        color: #191919;
        top: 50%;
        -webkit-transform: translate(0, -50%);
                transform: translate(0, -50%);
        left: 12px;
        font-family: LatoMedium !important; }
      .modal-login-salestalk .modal-content .modal-body .modal-login-salestalk-phone-number input {
        border: solid 1px #dcdcdc;
        border-radius: 4px;
        height: 42px;
        line-height: 42px;
        width: 100%;
        padding: 0 12px 0 39px;
        font-family: LatoMedium !important; }
    .modal-login-salestalk .modal-content .modal-body button {
      width: 100%;
      border-radius: 6px; }
      .modal-login-salestalk .modal-content .modal-body button b {
        font-family: NeueBold !important; }

.modal-login-otp-salestalk .modal-content {
  border-radius: 12px;
  width: 500px; }
  .modal-login-otp-salestalk .modal-content .modal-body {
    text-align: center;
    padding: 80px;
    position: relative; }
    .modal-login-otp-salestalk .modal-content .modal-body .modal-login-otp-salestalk-close {
      color: #ff7d00;
      position: absolute;
      right: 12px;
      top: 24px;
      font-size: 28px;
      cursor: pointer; }
      .modal-login-otp-salestalk .modal-content .modal-body .modal-login-otp-salestalk-close:hover {
        color: #ff7d00; }
    .modal-login-otp-salestalk .modal-content .modal-body .modal-login-otp-salestalk-title {
      font-size: 28px;
      margin: 0 0 24px 0;
      line-height: 1;
      color: #1a1a1a; }
      .modal-login-otp-salestalk .modal-content .modal-body .modal-login-otp-salestalk-title b {
        font-family: NeueBold !important; }
    .modal-login-otp-salestalk .modal-content .modal-body .modal-login-otp-salestalk-desc {
      font-size: 13px;
      margin: 5px 0 28px 0;
      color: #191919;
      font-family: LatoMedium !important; }
      .modal-login-otp-salestalk .modal-content .modal-body .modal-login-otp-salestalk-desc b {
        font-family: NeueBold !important; }
      .modal-login-otp-salestalk .modal-content .modal-body .modal-login-otp-salestalk-desc .send-otp-to-img {
        width: 16px;
        position: relative; }
      .modal-login-otp-salestalk .modal-content .modal-body .modal-login-otp-salestalk-desc .lds-ring {
        top: unset;
        right: unset;
        margin-right: 4px;
        position: relative;
        top: 3px; }
        .modal-login-otp-salestalk .modal-content .modal-body .modal-login-otp-salestalk-desc .lds-ring div {
          margin: 0; }
      .modal-login-otp-salestalk .modal-content .modal-body .modal-login-otp-salestalk-desc .resent-otp-button {
        margin: 5px 0 0;
        color: #ff7d00;
        cursor: pointer; }
        .modal-login-otp-salestalk .modal-content .modal-body .modal-login-otp-salestalk-desc .resent-otp-button:hover {
          color: var(--main-darker-orange); }
        .modal-login-otp-salestalk .modal-content .modal-body .modal-login-otp-salestalk-desc .resent-otp-button.counter-timer {
          color: #191919; }
    .modal-login-otp-salestalk .modal-content .modal-body .modal-login-otp-salestalk-phone-number {
      position: relative;
      text-align: center;
      margin: 0; }
      .modal-login-otp-salestalk .modal-content .modal-body .modal-login-otp-salestalk-phone-number .lds-ring {
        top: unset;
        right: unset;
        width: 38px;
        height: 38px; }
        .modal-login-otp-salestalk .modal-content .modal-body .modal-login-otp-salestalk-phone-number .lds-ring div {
          margin: 0;
          width: 38px;
          height: 38px;
          border: 4px solid #fff;
          border-color: var(--main-dark-orange) transparent transparent transparent; }
      .modal-login-otp-salestalk .modal-content .modal-body .modal-login-otp-salestalk-phone-number .otp-invalid {
        margin: 8px 0 0;
        font-size: 13px;
        line-height: 1; }
      .modal-login-otp-salestalk .modal-content .modal-body .modal-login-otp-salestalk-phone-number input {
        border: none;
        border-bottom: solid 2px #a8a8a8;
        border-radius: 0;
        height: 34px;
        width: 34px;
        display: inline-block;
        margin: 0 18px 10px 0;
        padding: 0 4px;
        text-align: center;
        font-family: PTRootBold;
        font-size: 24px; }
        .modal-login-otp-salestalk .modal-content .modal-body .modal-login-otp-salestalk-phone-number input:nth-last-child(1) {
          margin: 0; }
        .modal-login-otp-salestalk .modal-content .modal-body .modal-login-otp-salestalk-phone-number input.filled {
          border-bottom: solid 2px #ff7d00; }
    .modal-login-otp-salestalk .modal-content .modal-body button {
      width: 100%; }

.modal-login-success-salestalk .modal-content {
  border-radius: 12px;
  width: 500px; }
  .modal-login-success-salestalk .modal-content .modal-body {
    text-align: center;
    padding: 24px;
    position: relative; }
    .modal-login-success-salestalk .modal-content .modal-body .modal-login-success-salestalk-close {
      color: #ff7d00;
      position: absolute;
      right: 24px;
      top: 26px;
      font-size: 28px;
      cursor: pointer; }
      .modal-login-success-salestalk .modal-content .modal-body .modal-login-success-salestalk-close:hover {
        color: var(--main-darker-orange); }
    .modal-login-success-salestalk .modal-content .modal-body .modal-login-success-salestalk-title {
      font-size: 28px;
      margin: 0 0 24px 0;
      line-height: 1; }
      .modal-login-success-salestalk .modal-content .modal-body .modal-login-success-salestalk-title b {
        font-family: NeueBold !important; }
    .modal-login-success-salestalk .modal-content .modal-body .modal-login-success-salestalk-desc {
      font-size: 16px;
      margin: 28px 0 24px 0;
      color: #1a1a1a;
      font-family: LatoMedium !important; }
    .modal-login-success-salestalk .modal-content .modal-body button {
      width: 100%; }
      .modal-login-success-salestalk .modal-content .modal-body button b {
        font-family: NeueBold !important; }

.form-custom-provider {
  position: relative; }
  .form-custom-provider .form-title-wrapper {
    padding-bottom: 12px;
    border-bottom: solid 1px rgba(25, 25, 25, 0.1);
    color: #191919;
    font-size: 20px;
    letter-spacing: -0.6px; }
    .form-custom-provider .form-title-wrapper .form-title-desc {
      font-size: 16px;
      letter-spacing: -0.3px;
      margin: 8px 0 0; }
  .form-custom-provider .form-content-wrapper a {
    color: #ff7d00; }
    .form-custom-provider .form-content-wrapper a:hover {
      color: var(--main-darker-orange); }
  .form-custom-provider .form-content-wrapper label {
    display: block;
    color: #191919;
    font-size: 14px;
    margin-top: 22px; }
  .form-custom-provider .form-content-wrapper input[type="text"] {
    background: white;
    border-radius: 8px;
    border: 1px solid rgba(25, 25, 25, 0.1);
    height: 48px;
    width: 348px;
    color: #191919;
    font-size: 16px;
    letter-spacing: -0.3px;
    width: 100%;
    padding: 12px 16px;
    margin-bottom: 11px; }
  .form-custom-provider .form-content-wrapper label {
    display: block !important; }
  .form-custom-provider .form-content-wrapper .role-radio-label {
    position: relative; }
  .form-custom-provider .form-content-wrapper .role-radio-label b {
    display: block;
    color: #191919;
    font-size: 14px;
    letter-spacing: -0.4px; }
  .form-custom-provider .form-content-wrapper .role-radio-label label {
    color: rgba(25, 25, 25, 0.8);
    font-size: 14px;
    letter-spacing: -0.2px; }
  .form-custom-provider .form-content-wrapper .custom-radio label {
    margin-top: 18px; }
  .form-custom-provider .form-content-wrapper button {
    width: unset;
    margin-top: 22px; }
  .form-custom-provider .form-content-wrapper .orange-button .lds-ring div {
    border-color: #ffffff transparent transparent transparent;
    margin: 0; }
  .form-custom-provider .submit-add-custom-provider {
    position: absolute;
    top: -7px;
    right: 32px;
    width: 176px !important;
    padding: 7px 0 !important;
    margin-top: 32px !important; }
    .form-custom-provider .submit-add-custom-provider .lds-ring {
      top: 3px;
      right: unset; }
      .form-custom-provider .submit-add-custom-provider .lds-ring div {
        margin: 0;
        border-color: #ffffff transparent transparent transparent; }
  .form-custom-provider .input-info-wrapper {
    color: rgba(25, 25, 25, 0.8);
    font-size: 16px;
    letter-spacing: -0.3px;
    line-height: 21px;
    width: 100%;
    margin: 0;
    line-height: 1; }
  .form-custom-provider .custom-provider-suffix {
    height: 48px;
    padding: 12px 16px;
    margin-left: 8px; }
  .form-custom-provider .custom-provider-prefix {
    height: 48px;
    padding: 12px 16px;
    margin-right: 8px; }

.form-custom-provider-edit {
  position: relative; }
  .form-custom-provider-edit .form-title-wrapper {
    padding-bottom: 24px;
    border-bottom: solid 1px rgba(25, 25, 25, 0.1);
    color: #191919;
    font-size: 20px;
    letter-spacing: -0.6px;
    position: relative; }
    .form-custom-provider-edit .form-title-wrapper .form-title-desc {
      font-size: 16px;
      letter-spacing: -0.3px;
      margin: 8px 0 0; }
    .form-custom-provider-edit .form-title-wrapper button {
      position: absolute;
      top: 0;
      right: 0; }
  .form-custom-provider-edit .form-content-wrapper a {
    color: #ff7d00; }
    .form-custom-provider-edit .form-content-wrapper a:hover {
      color: var(--main-darker-orange); }
  .form-custom-provider-edit .form-content-wrapper label {
    display: block;
    color: #191919;
    font-size: 14px;
    margin-top: 22px; }
  .form-custom-provider-edit .form-content-wrapper input[type="text"] {
    background: white;
    border-radius: 8px;
    border: 1px solid rgba(25, 25, 25, 0.1);
    height: 48px;
    width: 348px;
    color: #191919;
    font-size: 16px;
    letter-spacing: -0.3px;
    width: 100%;
    padding: 12px 16px;
    margin-bottom: 11px; }
  .form-custom-provider-edit .form-content-wrapper label {
    display: block !important; }
  .form-custom-provider-edit .form-content-wrapper .role-radio-label {
    position: relative; }
  .form-custom-provider-edit .form-content-wrapper .role-radio-label b {
    display: block;
    color: #191919;
    font-size: 14px;
    letter-spacing: -0.4px; }
  .form-custom-provider-edit .form-content-wrapper .role-radio-label label {
    color: rgba(25, 25, 25, 0.8);
    font-size: 14px;
    letter-spacing: -0.2px; }
  .form-custom-provider-edit .form-content-wrapper .custom-radio label {
    margin-top: 18px; }
  .form-custom-provider-edit .form-content-wrapper button {
    width: unset;
    margin-top: 22px; }
  .form-custom-provider-edit .form-content-wrapper .orange-button .lds-ring div {
    border-color: #ffffff transparent transparent transparent;
    margin: 0; }
  .form-custom-provider-edit .submit-add-custom-provider {
    position: absolute;
    top: -7px;
    right: 32px;
    width: 176px !important;
    padding: 7px 0 !important;
    margin-top: 32px !important; }
    .form-custom-provider-edit .submit-add-custom-provider .lds-ring {
      top: 3px;
      right: unset; }
      .form-custom-provider-edit .submit-add-custom-provider .lds-ring div {
        margin: 0;
        border-color: #ffffff transparent transparent transparent; }
  .form-custom-provider-edit .input-info-wrapper {
    color: rgba(25, 25, 25, 0.8);
    font-size: 16px;
    letter-spacing: -0.3px;
    line-height: 21px;
    width: 100%;
    margin: 0;
    line-height: 1; }
  .form-custom-provider-edit .custom-provider-suffix {
    height: 48px;
    padding: 12px 16px;
    margin-left: 8px; }
  .form-custom-provider-edit .custom-provider-prefix {
    height: 48px;
    padding: 12px 16px;
    margin-right: 8px; }

.integration-google-list {
  text-align: left; }
  .integration-google-list .integration-list-loading-wrapper .lds-ring {
    width: 50px;
    height: 50px;
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    right: unset; }
    .integration-google-list .integration-list-loading-wrapper .lds-ring div {
      border: 5px solid #fff;
      border-color: #ff7d00 transparent transparent transparent;
      width: 50px;
      height: 50px;
      margin: 0; }
  .integration-google-list .status-suspended {
    color: #FF3F57 !important; }
  .integration-google-list .status-in_review {
    color: #EDAB00 !important; }
  .integration-google-list .status-verified {
    color: #19C700 !important; }
  .integration-google-list .status-unverified {
    color: rgba(25, 25, 25, 0.4) !important; }
  .integration-google-list .integration-list-header {
    position: relative;
    padding-left: 42px;
    padding-bottom: 26px;
    border-bottom: solid 1px rgba(25, 25, 25, 0.1); }
    .integration-google-list .integration-list-header .integration-list-header-icon {
      position: absolute;
      top: 0;
      left: 0;
      width: 32px; }
    .integration-google-list .integration-list-header .integration-list-header-text-wrapper {
      text-align: left; }
      .integration-google-list .integration-list-header .integration-list-header-text-wrapper b {
        font-size: 20px;
        letter-spacing: -0.6px; }
      .integration-google-list .integration-list-header .integration-list-header-text-wrapper p {
        margin: 0;
        font-size: 14px;
        letter-spacing: -0.2px; }
      .integration-google-list .integration-list-header .integration-list-header-text-wrapper a {
        color: #ff7d00; }
    .integration-google-list .integration-list-header .integration-list-header-button {
      position: absolute;
      right: 0;
      top: 0;
      font-size: 16px;
      letter-spacing: -0.5px;
      padding: 7px 32px; }
      .integration-google-list .integration-list-header .integration-list-header-button img {
        margin-right: 4px;
        position: relative;
        top: -2px; }
  .integration-google-list .integration-list-no-data {
    width: 100%;
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
    .integration-google-list .integration-list-no-data b {
      font-size: 20px;
      letter-spacing: -0.6px;
      color: #191919; }
    .integration-google-list .integration-list-no-data p {
      color: rgba(25, 25, 25, 0.6);
      letter-spacing: -0.6px;
      font-size: 16px; }
  .integration-google-list .integration-list-outer-wrapper {
    position: relative;
    width: 100%;
    padding-top: 24px;
    text-align: left; }
    .integration-google-list .integration-list-outer-wrapper .integration-list-inner-wrapper {
      width: calc((100% / 3) - 8px);
      border-radius: 8px;
      margin: 0 12px 12px 0;
      box-shadow: 0 2px 20px 0 rgba(25, 25, 25, 0.05);
      background: #ffffff;
      display: inline-block;
      vertical-align: top;
      padding: 24px 30px 24px 24px;
      cursor: pointer;
      position: relative; }
      .integration-google-list .integration-list-outer-wrapper .integration-list-inner-wrapper p:nth-child(2) {
        font-size: 16px;
        letter-spacing: -0.5px;
        color: rgba(25, 25, 25, 0.6);
        margin: 0;
        white-space: nowrap;
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis; }
      .integration-google-list .integration-list-outer-wrapper .integration-list-inner-wrapper span {
        display: block;
        font-size: 12px;
        letter-spacing: -0.2px;
        color: #191919; }
      .integration-google-list .integration-list-outer-wrapper .integration-list-inner-wrapper p {
        margin: 30px 0 0;
        color: #ff7d00;
        font-size: 12px;
        letter-spacing: -0.2px; }
        .integration-google-list .integration-list-outer-wrapper .integration-list-inner-wrapper p.font-green {
          color: #19c700; }
        .integration-google-list .integration-list-outer-wrapper .integration-list-inner-wrapper p.font-grey {
          color: rgba(25, 25, 25, 0.4); }
      .integration-google-list .integration-list-outer-wrapper .integration-list-inner-wrapper svg {
        position: absolute;
        right: 12px;
        top: 12px;
        font-size: 22px;
        color: #191919; }
      .integration-google-list .integration-list-outer-wrapper .integration-list-inner-wrapper:hover {
        box-shadow: 0 2px 20px 0 rgba(25, 25, 25, 0.18); }
        .integration-google-list .integration-list-outer-wrapper .integration-list-inner-wrapper:hover p:nth-child(2) {
          color: #191919; }
        .integration-google-list .integration-list-outer-wrapper .integration-list-inner-wrapper:hover svg {
          color: #ff7d00; }
  .integration-google-list .link-google-account-list:nth-child(3n) .integration-list-inner-wrapper {
    margin: 0 0 12px 0; }

.main-integration-google-create .integration-google-add-box {
  background: #ffffff;
  border-radius: 8px;
  padding: 32px;
  border: solid 1px rgba(25, 25, 25, 0.1);
  margin-top: 24px; }
  .main-integration-google-create .integration-google-add-box .integration-goggle-add-title {
    font-size: 20px;
    letter-spacing: -0.6px;
    color: #191919;
    padding: 0 0 12px 0;
    border-bottom: solid 1px #dcdcdc;
    margin: 0 0 24px 0;
    display: block; }
  .main-integration-google-create .integration-google-add-box .integration-google-add-account-main {
    margin: 34px 0 0;
    width: 100%;
    display: block; }
    .main-integration-google-create .integration-google-add-box .integration-google-add-account-main label {
      font-size: 14px;
      letter-spacing: -0.4px;
      margin: 0 0 8px 0;
      display: block; }
      .main-integration-google-create .integration-google-add-box .integration-google-add-account-main label p {
        font-size: 14px;
        letter-spacing: -0.2px;
        color: rgba(25, 25, 25, 0.8);
        margin: 4px 0 0; }

.main-integration-google-create .comp-back-button {
  margin: 0 !important; }

.main-integration-google-create input {
  padding: 12px 16px;
  font-size: 16px;
  letter-spacing: -0.3px;
  border-radius: 8px;
  border: solid 1px #dcdcdc; }

.main-integration-google-create textarea {
  padding: 12px 16px;
  font-size: 16px;
  letter-spacing: -0.3px;
  border-radius: 8px;
  border: solid 1px #dcdcdc; }

.form-step {
  position: relative;
  white-space: nowrap;
  font-size: 16px;
  letter-spacing: -0.5px;
  color: rgba(25, 25, 25, 0.4);
  overflow: hidden;
  width: 100%; }
  .form-step .form-step-content {
    display: inline-block; }
    .form-step .form-step-content.active-form-step {
      color: #191919; }
    .form-step .form-step-content .value-number {
      border: 2px solid #E87402;
      background: #ff7e00;
      width: 24px;
      height: 24px;
      display: inline-block;
      border-radius: 50%;
      position: relative;
      margin-right: 4px;
      color: #ffffff;
      vertical-align: top;
      text-align: center;
      font-size: 13px;
      line-height: 19px;
      letter-spacing: 0; }
      .form-step .form-step-content .value-number::before {
        position: absolute;
        width: 26px;
        height: 26px;
        border: solid 2px #FFF2E5;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
        content: "";
        border-radius: 50%; }
      .form-step .form-step-content .value-number.not-active-value-wrapper {
        border: solid 2px #e8e8e8;
        background: #ffffff;
        color: rgba(25, 25, 25, 0.4); }
        .form-step .form-step-content .value-number.not-active-value-wrapper::before {
          border: 0 !important; }
      .form-step .form-step-content .value-number.value-check {
        background: #e8e8e8;
        color: #959595;
        border: solid 2px #e8e8e8; }
        .form-step .form-step-content .value-number.value-check::before {
          border: 0 !important; }
  .form-step .line-separator {
    width: 30px;
    height: 2px;
    background: rgba(25, 25, 25, 0.05);
    margin: 0 12px 0;
    display: inline-block;
    position: relative;
    top: -4px; }

.main-integration-google-create button {
  margin: 48px 0 0; }

.main-integration-google-create-channel label {
  width: 400px; }

.main-integration-google-create-channel .error-or-info-comp {
  max-width: 100%;
  width: 400px; }

.main-integration-google-create-channel input[type="file"] {
  display: none; }

.main-integration-google-create-channel input {
  width: 400px;
  display: block; }

.main-integration-google-create-channel button {
  margin-right: 8px; }

.main-integration-google-create-channel .create-channel-upload {
  width: 100%;
  position: relative;
  display: block;
  margin: 24px 0 0; }
  .main-integration-google-create-channel .create-channel-upload .logo-wrapper {
    position: relative;
    width: 96px;
    height: 96px;
    display: block;
    background: #f3f3f3;
    border-radius: 4px; }
    .main-integration-google-create-channel .create-channel-upload .logo-wrapper svg {
      color: #9c9c9c;
      font-size: 32px;
      position: absolute;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%); }
    .main-integration-google-create-channel .create-channel-upload .logo-wrapper img {
      width: 96px;
      height: 96px;
      display: block;
      border-radius: 4px;
      position: absolute;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%); }
    .main-integration-google-create-channel .create-channel-upload .logo-wrapper .loading-wrapper {
      width: 96px;
      height: 96px;
      display: block;
      border-radius: 4px;
      position: absolute;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%);
      background: rgba(25, 25, 25, 0.4); }
      .main-integration-google-create-channel .create-channel-upload .logo-wrapper .loading-wrapper .LoadingRoll_1234fed {
        width: 96px;
        height: 96px;
        display: block;
        border-radius: 4px;
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%); }
      .main-integration-google-create-channel .create-channel-upload .logo-wrapper .loading-wrapper .lds-ring {
        width: 76px;
        height: 76px; }
        .main-integration-google-create-channel .create-channel-upload .logo-wrapper .loading-wrapper .lds-ring div {
          border: solid 5px #fff;
          border-color: #fff transparent transparent transparent;
          width: 76px;
          height: 76px; }
  .main-integration-google-create-channel .create-channel-upload button {
    position: absolute;
    top: 57px;
    right: 0;
    margin: 0; }

.main-integration-google-create-greeting .border-top {
  border-top: solid 1px #dcdcdc;
  width: 100%;
  margin: 24px 0 0;
  padding: 24px 0;
  position: relative; }
  .main-integration-google-create-greeting .border-top svg {
    position: relative;
    top: -1px;
    margin-right: 4px; }
  .main-integration-google-create-greeting .border-top p {
    margin: 0;
    font-size: 16px;
    letter-spacing: -0.5px; }
  .main-integration-google-create-greeting .border-top button {
    position: absolute;
    right: 0;
    top: 0; }

.main-integration-google-create-greeting .margin-top-24 {
  margin-top: 24px !important; }

.main-integration-google-create-greeting input {
  width: 400px;
  display: block;
  margin: 0; }

.main-integration-google-create-greeting textarea {
  width: 400px;
  display: block;
  margin: 0; }

.main-integration-google-create-greeting .dropdown {
  margin: 0; }
  .main-integration-google-create-greeting .dropdown button {
    margin: 0; }

.main-integration-google-create-greeting button {
  margin-right: 8px;
  margin-top: 24px; }

.main-integration-google-create-greeting .greeting-wrapper {
  width: 400px;
  display: block;
  position: relative; }
  .main-integration-google-create-greeting .greeting-wrapper .check-locale {
    display: inline-block;
    padding: 0 0 0 20px;
    position: absolute;
    top: 42px;
    margin: 0;
    right: -30px;
    color: rgba(25, 25, 25, 0.4); }
    .main-integration-google-create-greeting .greeting-wrapper .check-locale .custom-checkbox {
      position: absolute;
      top: 2px;
      left: 0; }
    .main-integration-google-create-greeting .greeting-wrapper .check-locale label {
      width: 147px; }

.main-integration-google-create-greeting label {
  position: relative; }
  .main-integration-google-create-greeting label .grey-font {
    position: absolute;
    top: 0;
    right: 0; }

.main-integration-google-create-greeting .add-lang {
  font-size: 16px;
  letter-spacing: -0.5px;
  color: #ff7d00;
  cursor: pointer;
  margin-top: 18px; }
  .main-integration-google-create-greeting .add-lang svg {
    margin-right: 4px;
    position: relative;
    top: -1px; }
  .main-integration-google-create-greeting .add-lang:hover {
    color: var(--main-darker-orange); }

.main-integration-google-create-entry .colored-info-box {
  color: #FF3F57;
  border: solid 1px #FF3F57;
  background-color: #FFECEE !important;
  border-radius: 8px;
  white-space: nowrap;
  margin-bottom: 24px;
  padding: 11px 20px;
  box-shadow: 0px 2px 20px rgba(25, 25, 25, 0.18); }
  .main-integration-google-create-entry .colored-info-box p {
    font-size: 16px !important;
    letter-spacing: -0.5px !important; }
  .main-integration-google-create-entry .colored-info-box svg {
    position: relative;
    margin-right: 4px;
    top: -1px;
    font-size: 18px; }

.main-integration-google-create-entry label {
  width: 400px; }

.main-integration-google-create-entry input {
  width: 400px;
  display: block; }

.main-integration-google-create-entry .custom-switch-wrapper {
  display: inline-block !important;
  width: 40px;
  height: 24px;
  position: absolute;
  top: 0;
  right: 0;
  margin: 0; }
  .main-integration-google-create-entry .custom-switch-wrapper .custom-switch.round::before {
    width: 14px;
    height: 14px;
    bottom: 5px;
    left: 4px; }
  .main-integration-google-create-entry .custom-switch-wrapper input:checked + .custom-switch:before {
    -webkit-transform: translateX(18px);
            transform: translateX(18px); }
  .main-integration-google-create-entry .custom-switch-wrapper.custom-switch-region {
    position: relative;
    top: unset !important;
    right: unset !important;
    margin-top: 28px !important;
    display: block !important; }
    .main-integration-google-create-entry .custom-switch-wrapper.custom-switch-region b {
      width: 200px;
      display: block;
      position: relative;
      left: 48px;
      top: -1px; }
    .main-integration-google-create-entry .custom-switch-wrapper.custom-switch-region.region-create b {
      top: 2px !important; }

.main-integration-google-create-entry button {
  margin-right: 8px; }

.main-integration-google-create-entry .local-wrapper {
  width: 100%;
  display: block;
  position: relative;
  padding-right: 60px;
  padding-bottom: 24px;
  margin-bottom: 24px;
  border-bottom: solid 1px #dcdcdc; }
  .main-integration-google-create-entry .local-wrapper label {
    margin: 0 !important; }

.main-integration-google-create-entry .non-local-wrapper {
  width: 100%;
  display: block;
  position: relative;
  padding-right: 60px;
  padding-bottom: 16px; }
  .main-integration-google-create-entry .non-local-wrapper label {
    margin: 0 !important; }

.main-integration-google-create-entry .non-local-form-wrapper {
  width: 100%;
  display: block;
  position: relative; }
  .main-integration-google-create-entry .non-local-form-wrapper input[type="text"] {
    padding: 11px 16px;
    border-radius: 8px;
    font-size: 16px;
    letter-spacing: -0.3px; }
  .main-integration-google-create-entry .non-local-form-wrapper .label-domain {
    margin-top: 8px !important; }
  .main-integration-google-create-entry .non-local-form-wrapper .domain-input-wrapper {
    position: relative; }
    .main-integration-google-create-entry .non-local-form-wrapper .domain-input-wrapper input {
      width: calc(100% - 45px); }
    .main-integration-google-create-entry .non-local-form-wrapper .domain-input-wrapper .remove-domain-icon {
      top: 15px;
      position: absolute;
      color: #FF3F57;
      cursor: pointer;
      right: 0;
      font-size: 20px; }
  .main-integration-google-create-entry .non-local-form-wrapper .add-more-domain {
    margin: 8px 0 0;
    font-size: 16px;
    letter-spacing: -0.5px;
    color: #ff7d00;
    cursor: pointer; }
    .main-integration-google-create-entry .non-local-form-wrapper .add-more-domain svg {
      position: relative;
      top: -2px;
      margin-right: 4px; }
    .main-integration-google-create-entry .non-local-form-wrapper .add-more-domain:hover {
      color: var(--main-darker-orange); }

.main-integration-google-create-entry .input-phone-wrapper {
  position: relative;
  width: 400px; }
  .main-integration-google-create-entry .input-phone-wrapper input {
    padding: 11px 16px 11px 34px !important; }
  .main-integration-google-create-entry .input-phone-wrapper .icon-plus {
    position: absolute;
    left: 16px;
    top: 16px; }

.main-integration-google-create-entry .width-400 {
  width: 400px; }

.main-integration-google-create-entry .checkbox-wrapper {
  margin-top: 13px;
  width: 400px; }
  .main-integration-google-create-entry .checkbox-wrapper .custom-checkbox {
    width: calc(100% / 3);
    display: inline-block; }

.main-integration-google-create-entry .button-submit {
  margin-top: 26px; }

.main-integration-google-create-entry .option-select-bulk-wrapper {
  margin-top: 20px; }

.colored-info-box {
    align-items: center;
    padding: 2px 8px;
    border-radius: 4px;
    color: white;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.colored-info-box p {
    margin-bottom: 0;
    font-size: 12px;
    font-weight: bold;
    line-height: 18px;
    letter-spacing: -0.4px;
}
.integration-google-business-api-detail-account {
  padding: 32px 30px;
  text-align: left; }
  .integration-google-business-api-detail-account .colored-info-box {
    color: #0C5F99;
    border: solid 1px #0c5f99;
    background-color: #EBF7FF !important;
    border-radius: 8px;
    white-space: nowrap;
    margin-bottom: 24px;
    padding: 11px 20px;
    box-shadow: 0px 2px 20px rgba(25, 25, 25, 0.18); }
    .integration-google-business-api-detail-account .colored-info-box p {
      font-size: 16px !important;
      letter-spacing: -0.5px !important; }
    .integration-google-business-api-detail-account .colored-info-box svg {
      position: relative;
      margin-right: 4px;
      top: -1px;
      font-size: 18px; }
  .integration-google-business-api-detail-account .comp-back-button {
    margin: 0 0 24px 0; }
  .integration-google-business-api-detail-account .nav-item {
    cursor: pointer;
    color: rgba(25, 25, 25, 0.4);
    font-size: 14px;
    letter-spacing: -0.5px;
    padding: 0 0 12px 0;
    margin-right: 18px; }
    .integration-google-business-api-detail-account .nav-item a {
      padding: 0;
      border: none; }
    .integration-google-business-api-detail-account .nav-item :hover {
      color: #ff7d00; }
    .integration-google-business-api-detail-account .nav-item.active-setup-tab {
      color: #ff7d00;
      border-bottom: solid 4px #ff7d00; }
  .integration-google-business-api-detail-account .tab-content {
    margin: 32px 0 0; }

.integration-google-detail-account {
  text-align: left; }
  .integration-google-detail-account .topic-val {
    max-width: 320px;
    color: #191919 !important;
    margin: 6px 0 16px 0 !important;
    font-size: 16px !important; }
  .integration-google-detail-account .comp-back-button {
    margin: 0 0 16px 0; }
  .integration-google-detail-account .integration-google-detail-account-box-1 {
    background: #ffffff;
    border-radius: 8px;
    padding: 22px 32px 32px;
    border: solid 1px rgba(25, 25, 25, 0.1);
    position: relative; }
    .integration-google-detail-account .integration-google-detail-account-box-1 .integration-google-detail-account-box-1-top {
      padding: 10px 0 15px;
      border-bottom: solid 1px #dcdcdc;
      position: relative;
      font-size: 20px;
      letter-spacing: -0.6px;
      padding-right: 230px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis; }
      .integration-google-detail-account .integration-google-detail-account-box-1 .integration-google-detail-account-box-1-top .bot-name {
        margin-bottom: 0; }
      .integration-google-detail-account .integration-google-detail-account-box-1 .integration-google-detail-account-box-1-top .integration-google-detail-account-box-1-top-button {
        position: absolute;
        top: 0px;
        right: 0;
        display: inline-block; }
        .integration-google-detail-account .integration-google-detail-account-box-1 .integration-google-detail-account-box-1-top .integration-google-detail-account-box-1-top-button button {
          padding: 7px 24px;
          font-size: 16px;
          letter-spacing: -0.6px;
          height: 40px;
          margin-left: 8px; }
          .integration-google-detail-account .integration-google-detail-account-box-1 .integration-google-detail-account-box-1-top .integration-google-detail-account-box-1-top-button button.button-channel-detail {
            position: relative;
            top: 15px; }
          .integration-google-detail-account .integration-google-detail-account-box-1 .integration-google-detail-account-box-1-top .integration-google-detail-account-box-1-top-button button svg {
            position: relative;
            top: -2px;
            margin-right: 4px; }
          .integration-google-detail-account .integration-google-detail-account-box-1 .integration-google-detail-account-box-1-top .integration-google-detail-account-box-1-top-button button .lds-ring {
            top: unset;
            right: unset;
            position: relative;
            top: 3px; }
            .integration-google-detail-account .integration-google-detail-account-box-1 .integration-google-detail-account-box-1-top .integration-google-detail-account-box-1-top-button button .lds-ring div {
              margin: 0;
              border-color: #ffffff transparent transparent transparent; }
    .integration-google-detail-account .integration-google-detail-account-box-1 .integration-google-detail-account-box-1-bottom {
      position: relative;
      font-size: 20px;
      letter-spacing: -0.6px; }
      .integration-google-detail-account .integration-google-detail-account-box-1 .integration-google-detail-account-box-1-bottom .custom-select-phonenumber {
        width: 316px; }
        .integration-google-detail-account .integration-google-detail-account-box-1 .integration-google-detail-account-box-1-bottom .custom-select-phonenumber button {
          margin-top: 8px; }
      .integration-google-detail-account .integration-google-detail-account-box-1 .integration-google-detail-account-box-1-bottom label {
        font-size: 14px;
        letter-spacing: -0.4px;
        margin: 24px 0 0 0; }
      .integration-google-detail-account .integration-google-detail-account-box-1 .integration-google-detail-account-box-1-bottom p {
        margin: 4px 0 0;
        font-size: 15px;
        letter-spacing: -0.5px; }
        .integration-google-detail-account .integration-google-detail-account-box-1 .integration-google-detail-account-box-1-bottom p.value-detail {
          font-size: 20px;
          letter-spacing: -0.6px; }
          .integration-google-detail-account .integration-google-detail-account-box-1 .integration-google-detail-account-box-1-bottom p.value-detail img {
            position: relative;
            top: -2px;
            margin-right: 4px; }
        .integration-google-detail-account .integration-google-detail-account-box-1 .integration-google-detail-account-box-1-bottom p.value-detail-status {
          font-size: 16px;
          letter-spacing: -0.5px; }
        .integration-google-detail-account .integration-google-detail-account-box-1 .integration-google-detail-account-box-1-bottom p.value-active-green {
          color: #19c700; }
        .integration-google-detail-account .integration-google-detail-account-box-1 .integration-google-detail-account-box-1-bottom p.value-active-orange {
          color: #ff7e00; }
        .integration-google-detail-account .integration-google-detail-account-box-1 .integration-google-detail-account-box-1-bottom p.value-active-red {
          color: #ff3f57; }
        .integration-google-detail-account .integration-google-detail-account-box-1 .integration-google-detail-account-box-1-bottom p svg {
          margin-right: 4px;
          position: relative;
          top: -2px;
          font-size: 20px; }
      .integration-google-detail-account .integration-google-detail-account-box-1 .integration-google-detail-account-box-1-bottom .integration-google-detail-account-box-1-bottom-button {
        display: block; }
        .integration-google-detail-account .integration-google-detail-account-box-1 .integration-google-detail-account-box-1-bottom .integration-google-detail-account-box-1-bottom-button button {
          padding: 7px 24px 7px 20px;
          font-size: 16px;
          letter-spacing: -0.6px;
          height: 40px;
          margin-right: 8px;
          margin-top: 16px; }
          .integration-google-detail-account .integration-google-detail-account-box-1 .integration-google-detail-account-box-1-bottom .integration-google-detail-account-box-1-bottom-button button svg {
            position: relative;
            top: -2px;
            margin-right: 4px; }
          .integration-google-detail-account .integration-google-detail-account-box-1 .integration-google-detail-account-box-1-bottom .integration-google-detail-account-box-1-bottom-button button img {
            position: relative;
            top: -2px;
            margin-right: 4px; }
          .integration-google-detail-account .integration-google-detail-account-box-1 .integration-google-detail-account-box-1-bottom .integration-google-detail-account-box-1-bottom-button button .lds-ring {
            top: unset;
            right: unset;
            position: relative;
            top: 3px; }
            .integration-google-detail-account .integration-google-detail-account-box-1 .integration-google-detail-account-box-1-bottom .integration-google-detail-account-box-1-bottom-button button .lds-ring div {
              margin: 0;
              border-color: #ffffff transparent transparent transparent; }
  .integration-google-detail-account .integration-google-detail-account-box-2 {
    background: #ffffff;
    border-radius: 8px;
    padding: 32px;
    border: solid 1px rgba(25, 25, 25, 0.1);
    margin-top: 12px; }
    .integration-google-detail-account .integration-google-detail-account-box-2 .integration-google-detail-account-box-2-title {
      font-size: 20px;
      letter-spacing: -0.6px;
      padding-bottom: 20px;
      border-bottom: solid 1px #dcdcdc;
      display: block;
      margin: 0 0 24px; }
    .integration-google-detail-account .integration-google-detail-account-box-2 .integration-google-detail-account-box-2-payment-wrapper {
      width: 100%;
      position: relative; }
      .integration-google-detail-account .integration-google-detail-account-box-2 .integration-google-detail-account-box-2-payment-wrapper label {
        font-size: 14px;
        letter-spacing: -0.4px;
        margin: 0; }
      .integration-google-detail-account .integration-google-detail-account-box-2 .integration-google-detail-account-box-2-payment-wrapper .payment-info-wrapper {
        position: relative; }
        .integration-google-detail-account .integration-google-detail-account-box-2 .integration-google-detail-account-box-2-payment-wrapper .payment-info-wrapper .payment-until {
          font-size: 15px;
          letter-spacing: -0.5px;
          color: #19c700;
          margin: 0; }
          .integration-google-detail-account .integration-google-detail-account-box-2 .integration-google-detail-account-box-2-payment-wrapper .payment-info-wrapper .payment-until img {
            vertical-align: top;
            position: relative;
            top: 0;
            margin-right: 4px; }
        .integration-google-detail-account .integration-google-detail-account-box-2 .integration-google-detail-account-box-2-payment-wrapper .payment-info-wrapper .payment-expired {
          color: #ff3f57;
          font-size: 15px;
          letter-spacing: -0.5px;
          margin: 4px 0 0; }
          .integration-google-detail-account .integration-google-detail-account-box-2 .integration-google-detail-account-box-2-payment-wrapper .payment-info-wrapper .payment-expired svg {
            vertical-align: top;
            position: relative;
            font-size: 20px;
            margin-right: 4px; }
        .integration-google-detail-account .integration-google-detail-account-box-2 .integration-google-detail-account-box-2-payment-wrapper .payment-info-wrapper .payment-bar {
          width: 326px;
          height: 4px;
          background: rgba(25, 25, 25, 0.1);
          border-radius: 2px;
          margin: 8px 0;
          position: relative; }
          .integration-google-detail-account .integration-google-detail-account-box-2 .integration-google-detail-account-box-2-payment-wrapper .payment-info-wrapper .payment-bar div {
            position: absolute;
            left: 0;
            top: 0;
            background: #3baeff;
            height: 4px; }
        .integration-google-detail-account .integration-google-detail-account-box-2 .integration-google-detail-account-box-2-payment-wrapper .payment-info-wrapper .payment-left {
          font-size: 12px;
          letter-spacing: -0.2px;
          color: rgba(25, 25, 25, 0.4); }
        .integration-google-detail-account .integration-google-detail-account-box-2 .integration-google-detail-account-box-2-payment-wrapper .payment-info-wrapper .not-paid {
          color: rgba(25, 25, 25, 0.4);
          font-size: 16px;
          letter-spacing: -0.5px;
          margin: 0 0 22px; }
      .integration-google-detail-account .integration-google-detail-account-box-2 .integration-google-detail-account-box-2-payment-wrapper .alet-verifying {
        border-radius: 8px;
        color: #044b84;
        border: solid 1px #044b84;
        background: #dcebff;
        width: 100%;
        position: relative;
        padding: 12px 16px 12px 38px;
        font-size: 14px;
        letter-spacing: -0.4px;
        width: 323px; }
        .integration-google-detail-account .integration-google-detail-account-box-2 .integration-google-detail-account-box-2-payment-wrapper .alet-verifying svg {
          font-size: 15px;
          -webkit-transform: none;
                  transform: none;
          top: 15px; }

.integration-google-detail-account-box-1 {
  background: #ffffff;
  border-radius: 8px;
  padding: 22px 32px 32px;
  border: solid 1px rgba(25, 25, 25, 0.1); }
  .integration-google-detail-account-box-1 p {
    font-size: 14px;
    letter-spacing: -0.2px; }
  .integration-google-detail-account-box-1.box-channel-button {
    margin-top: 24px; }
  .integration-google-detail-account-box-1 .whatsapp-detail-channel-button .channel-button-wrapper {
    margin-top: 24px;
    width: 300px;
    word-break: break-word; }
  .integration-google-detail-account-box-1 .status-wrapper .status-value {
    color: rgba(25, 25, 25, 0.4); }
    .integration-google-detail-account-box-1 .status-wrapper .status-value.status-enabled {
      color: #19C700; }
  .integration-google-detail-account-box-1 .status-wrapper img {
    position: relative;
    top: -1px;
    margin-right: 4px; }

.account-profile-google {
  position: relative;
  width: 100%; }
  .account-profile-google .account-profile-header {
    border-bottom: 1px solid rgba(25, 25, 25, 0.1);
    margin-bottom: 24px;
    padding-bottom: 24px;
    position: relative; }
    .account-profile-google .account-profile-header .title-wrapper b {
      font-size: 20px; }
    .account-profile-google .account-profile-header .title-wrapper p {
      font-size: 14px;
      letter-spacing: -0.2px;
      margin: 0;
      color: rgba(25, 25, 25, 0.8);
      padding-right: 150px; }
    .account-profile-google .account-profile-header .header-button-wrapper {
      position: absolute;
      top: 0;
      right: 0; }
      .account-profile-google .account-profile-header .header-button-wrapper button {
        margin-left: 8px; }
  .account-profile-google .account-profile-header-bottom {
    padding-bottom: 12px; }
  .account-profile-google .account-profile-content .copy-box-wrapper {
    margin-top: 8px; }
  .account-profile-google .account-profile-content.account-profile-content-with-border {
    border-top: solid 1px #dcdcdc;
    margin-top: 24px;
    padding-top: 24px; }
  .account-profile-google .account-profile-content .colored-info-box {
    color: #0C5F99;
    border: solid 1px #0c5f99;
    background-color: #EBF7FF !important;
    border-radius: 8px;
    white-space: nowrap;
    margin-bottom: 24px;
    padding: 11px 20px;
    box-shadow: 0px 2px 20px rgba(25, 25, 25, 0.18); }
    .account-profile-google .account-profile-content .colored-info-box p {
      font-size: 16px !important;
      letter-spacing: -0.5px !important; }
    .account-profile-google .account-profile-content .colored-info-box svg {
      position: relative;
      margin-right: 4px;
      top: -1px;
      font-size: 18px; }
  .account-profile-google .account-profile-content label {
    margin: 0;
    font-size: 14px;
    letter-spacing: -0.4px;
    display: block; }
  .account-profile-google .account-profile-content .verification-status {
    margin-top: 24px; }
    .account-profile-google .account-profile-content .verification-status .status-value {
      font-size: 16px;
      letter-spacing: -0.5px; }
      .account-profile-google .account-profile-content .verification-status .status-value svg, .account-profile-google .account-profile-content .verification-status .status-value img {
        position: relative;
        margin-right: 4px;
        top: -1px; }
  .account-profile-google .account-profile-content .no-fill-button {
    margin: 12px 8px 0 0; }
  .account-profile-google .account-profile-content .display-name {
    font-size: 20px;
    letter-spacing: -0.6px;
    width: 400px;
    max-width: 100%;
    word-wrap: break-word; }
  .account-profile-google .account-profile-content .custom-select-phonenumber {
    width: 340px;
    max-width: 100%; }
  .account-profile-google .account-profile-content .account-profile-content-wrapper {
    margin-top: 24px;
    margin-bottom: 24px;
    position: relative; }
    .account-profile-google .account-profile-content .account-profile-content-wrapper:last-child {
      margin-bottom: 0; }
    .account-profile-google .account-profile-content .account-profile-content-wrapper .label-wrapper {
      display: flex;
      align-items: center;
      margin-bottom: 8px; }
      .account-profile-google .account-profile-content .account-profile-content-wrapper .label-wrapper svg {
        margin-right: 4px;
        position: relative;
        top: -1px; }
    .account-profile-google .account-profile-content .account-profile-content-wrapper .photo-wrapper {
      margin-bottom: 24px;
      border: 1px solid rgba(25, 25, 25, 0.1);
      border-radius: 4px;
      width: 96px;
      height: 96px;
      position: relative; }
      .account-profile-google .account-profile-content .account-profile-content-wrapper .photo-wrapper img {
        border-radius: 4px;
        width: 100%;
        height: 100%;
        position: absolute;
        left: 50%;
        top: 50%;
        -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
        max-width: 100%;
        object-fit: contain; }
      .account-profile-google .account-profile-content .account-profile-content-wrapper .photo-wrapper .profile-photo-empty {
        height: 94px;
        background: rgba(25, 25, 25, 0.05);
        position: relative; }
        .account-profile-google .account-profile-content .account-profile-content-wrapper .photo-wrapper .profile-photo-empty svg {
          height: 32px;
          width: 32px;
          color: #9c9c9c;
          position: absolute;
          left: 50%;
          top: 50%;
          -webkit-transform: translate(-50%, -50%);
                  transform: translate(-50%, -50%); }
    .account-profile-google .account-profile-content .account-profile-content-wrapper .info-wrapper {
      display: flex; }
      .account-profile-google .account-profile-content .account-profile-content-wrapper .info-wrapper b {
        font-size: 12px; }
      .account-profile-google .account-profile-content .account-profile-content-wrapper .info-wrapper svg {
        font-size: 16px;
        margin-right: 4px; }
    .account-profile-google .account-profile-content .account-profile-content-wrapper .text-wrapper p {
      margin: 0; }
    .account-profile-google .account-profile-content .account-profile-content-wrapper .button-wrapper {
      position: absolute;
      right: 0;
      top: 50%;
      -webkit-transform: translateY(-50%);
              transform: translateY(-50%); }
      .account-profile-google .account-profile-content .account-profile-content-wrapper .button-wrapper > div {
        display: flex; }
        .account-profile-google .account-profile-content .account-profile-content-wrapper .button-wrapper > div label {
          margin-right: 8px; }
      .account-profile-google .account-profile-content .account-profile-content-wrapper .button-wrapper .LoadingRoll_1234fed {
        vertical-align: 6px;
        margin: 0 15px 0 auto !important;
        height: unset !important; }
      .account-profile-google .account-profile-content .account-profile-content-wrapper .button-wrapper label {
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
        cursor: pointer;
        padding: 0 24px 0 16px !important; }
        .account-profile-google .account-profile-content .account-profile-content-wrapper .button-wrapper label input {
          width: 100%; }
        .account-profile-google .account-profile-content .account-profile-content-wrapper .button-wrapper label div {
          display: inline-block; }
        .account-profile-google .account-profile-content .account-profile-content-wrapper .button-wrapper label.disabled {
          color: rgba(25, 25, 25, 0.4) !important;
          border: solid 1px #E8E8E8 !important;
          background: rgba(25, 25, 25, 0.1) !important;
          cursor: not-allowed;
          box-shadow: none !important; }
          .account-profile-google .account-profile-content .account-profile-content-wrapper .button-wrapper label.disabled input {
            display: none; }

.modal-remove-photo .modal-content {
  border-radius: 8px;
  padding: 32px;
  width: 480px; }

.modal-remove-photo .modal-body {
  padding: 0;
  line-height: 1; }
  .modal-remove-photo .modal-body p {
    margin: 0; }
  .modal-remove-photo .modal-body .modal-remove-photo-title {
    font-size: 20px;
    letter-spacing: -0.6px; }
  .modal-remove-photo .modal-body input {
    width: 100%; }

.modal-remove-photo .modal-footer {
  padding: 24px 0 0;
  border: 0; }
  .modal-remove-photo .modal-footer button {
    width: calc(50% - 4px);
    margin: 0 8px 0 0;
    height: 48px; }
    .modal-remove-photo .modal-footer button:nth-last-child(1) {
      margin: 0; }

.modal-test .modal-content {
  width: 476px; }
  .modal-test .modal-content .modal-body {
    padding: 24px; }

.modal-test .modal-title {
  font-size: 20px;
  letter-spacing: -0.6px;
  margin: 0; }

.modal-test .modal-desc {
  font-size: 14px;
  letter-spacing: -0.2px;
  margin: 0 0 20px; }

.modal-test .qr-wrapper {
  margin: 0 0 8px 0;
  width: calc(100% - 24px);
  position: relative;
  left: 12px;
  height: 400px; }
  .modal-test .qr-wrapper img {
    width: 100%; }

.modal-test .qr-button-wrapper button {
  width: calc(50% - 2px);
  display: inline-block;
  margin-right: 4px; }
  .modal-test .qr-button-wrapper button:nth-last-child(1) {
    margin-right: 0; }

.modal-send-verification .modal-content {
  max-height: 90vh;
  border-radius: 8px; }
  .modal-send-verification .modal-content .modal-title {
    padding: 24px 24px 0 24px; }
  .modal-send-verification .modal-content .modal-body {
    padding: 0; }

.modal-send-verification .form-verification-wrapper {
  overflow: auto;
  max-height: calc(90vh - 170px);
  margin-top: 24px; }
  .modal-send-verification .form-verification-wrapper .form-verification-wrapper-inner {
    padding: 0 24px 24px 24px; }
  .modal-send-verification .form-verification-wrapper label {
    display: block;
    margin: 24px 0 0; }
  .modal-send-verification .form-verification-wrapper input {
    margin-top: 8px;
    font-size: 16px;
    letter-spacing: -0.3px;
    padding: 12px 16px;
    border-radius: 8px;
    border: solid 1px #dcdcdc;
    width: 100%; }

.modal-send-verification .qr-button-wrapper {
  padding: 24px; }

.modal-send-verification .colored-info-box {
  color: #0C5F99;
  border: solid 1px #0c5f99;
  background-color: #EBF7FF !important;
  border-radius: 8px;
  margin-bottom: 24px;
  padding: 11px 20px;
  box-shadow: 0px 2px 20px rgba(25, 25, 25, 0.18); }
  .modal-send-verification .colored-info-box p {
    font-size: 16px !important;
    letter-spacing: -0.5px !important;
    padding-left: 26px;
    position: relative;
    line-height: 24px; }
  .modal-send-verification .colored-info-box svg {
    position: absolute;
    margin-right: 4px;
    left: 0;
    top: 2px;
    font-size: 18px; }

.launch-google {
  position: relative;
  width: 100%; }

.no-launch-data {
  background: #fff;
  text-align: center;
  padding: 48px;
  width: 100%;
  text-align: center; }
  .no-launch-data b {
    font-size: 16px;
    letter-spacing: -0.5px; }
  .no-launch-data p {
    font-size: 14px;
    letter-spacing: -0.2px;
    margin: 16px 0; }

.launch-google .launch-google-top {
  padding: 0px 0 28px;
  border-bottom: solid 1px #dcdcdc;
  font-size: 20px;
  letter-spacing: -0.6px;
  font-weight: 700;
  margin: 0 0 24px 0; }
  .launch-google .launch-google-top .launch-google-top-button {
    position: absolute;
    right: 35px;
    top: 35px;
    font-size: 16px;
    letter-spacing: -0.5px;
    padding: 7px 32px; }
    .launch-google .launch-google-top .launch-google-top-button img {
      margin-right: 4px;
      position: relative;
      top: -2px; }

.launch-google .launch-google-middle {
  width: 100%;
  display: block;
  position: relative;
  margin-bottom: 24px;
  height: 48px; }
  .launch-google .launch-google-middle .unlaunch-button {
    right: 138px !important; }
  .launch-google .launch-google-middle .launch-google-top-button {
    position: absolute;
    top: 0;
    right: 0; }

.modal-view-message .modal-content {
  padding: 32px;
  width: 477px; }

.modal-view-message .modal-body {
  padding: 0; }
  .modal-view-message .modal-body .header-modal {
    margin-bottom: 24px; }
    .modal-view-message .modal-body .header-modal b {
      font-size: 20px; }
    .modal-view-message .modal-body .header-modal svg {
      margin-right: 4px;
      position: relative;
      top: -3px; }
  .modal-view-message .modal-body .message-container label {
    font-size: 14px; }
  .modal-view-message .modal-body .message-container input {
    display: flex;
    align-items: center;
    background: white;
    border-radius: 8px;
    border: 1px solid rgba(25, 25, 25, 0.1);
    height: 48px;
    width: 100%;
    color: #191919;
    font-size: 16px;
    letter-spacing: -0.3px;
    max-width: 100%;
    padding: 0 16px;
    margin-bottom: 24px; }
    .modal-view-message .modal-body .message-container input:disabled {
      background: rgba(25, 25, 25, 0.05);
      cursor: not-allowed; }
  .modal-view-message .modal-body .message-container textarea {
    background: white;
    border-radius: 8px;
    border: 1px solid rgba(25, 25, 25, 0.1);
    min-height: 48px;
    width: 100%;
    color: #191919;
    font-size: 16px;
    letter-spacing: -0.3px;
    max-width: 100%;
    padding: 12px 16px;
    line-height: 24px;
    resize: none;
    margin-bottom: 20px; }
    .modal-view-message .modal-body .message-container textarea:disabled {
      background: rgba(25, 25, 25, 0.05);
      cursor: not-allowed; }

.table-launch-list {
  table-layout: fixed; }
  .table-launch-list .custom-checkbox {
    display: inline-block; }
  .table-launch-list .custom-checkbox-stripe [type="checkbox"]:checked + label:after {
    width: 16px;
    height: 16px;
    top: 2px;
    left: 0; }
  .table-launch-list thead tr {
    background: transparent; }
    .table-launch-list thead tr td {
      padding: 8px;
      font-size: 14px;
      word-wrap: break-word;
      position: relative; }
      .table-launch-list thead tr td .question-circle-wrapper {
        margin-left: 5px;
        position: absolute;
        top: 25%; }
      .table-launch-list thead tr td:first-child {
        width: 30px; }
      .table-launch-list thead tr td:nth-child(4) {
        width: 150px; }
        .table-launch-list thead tr td:nth-child(4) img {
          margin-left: 5px;
          vertical-align: -1.5px; }
      .table-launch-list thead tr td:last-child .custom-select-option-value-wrapper {
        width: 55px !important;
        height: 23px;
        padding: 2px 8px 2px 12px !important;
        border-radius: 4px !important; }
        .table-launch-list thead tr td:last-child .custom-select-option-value-wrapper p {
          font-size: 12px;
          font-weight: bold; }
        .table-launch-list thead tr td:last-child .custom-select-option-value-wrapper .custom-select-option-value {
          padding: 0; }
        .table-launch-list thead tr td:last-child .custom-select-option-value-wrapper svg {
          right: 3px;
          height: 15px;
          top: 11px; }
  .table-launch-list tbody tr {
    border-bottom: none; }
    .table-launch-list tbody tr:hover {
      background-color: unset; }
      .table-launch-list tbody tr:hover td {
        color: unset; }
    .table-launch-list tbody tr td:not(.no-data-column) {
      padding: 8px; }
      .table-launch-list tbody tr td:not(.no-data-column) p {
        margin-bottom: 0; }
    .table-launch-list tbody tr td {
      background: transparent;
      position: relative;
      word-wrap: break-word;
      vertical-align: top; }
      .table-launch-list tbody tr td .unavailable {
        color: rgba(25, 25, 25, 0.4); }
      .table-launch-list tbody tr td .link-user {
        color: inherit;
        text-decoration: inherit; }
      .table-launch-list tbody tr td:nth-child(2) p {
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical; }
      .table-launch-list tbody tr td.col-view {
        color: #ff7e00;
        cursor: pointer;
        font-weight: 700;
        text-align: right; }
        .table-launch-list tbody tr td.col-view a {
          color: #ff7e00; }
        .table-launch-list tbody tr td.col-view svg {
          position: relative;
          color: #ff7e00;
          font-size: 16px;
          margin-right: 4px;
          right: 0;
          top: -2px; }
  .table-launch-list tbody .no-data-row .no-result-found {
    color: rgba(25, 25, 25, 0.6);
    margin-top: 18px; }
  .table-launch-list tbody .no-data-row button svg {
    right: unset !important; }
  .table-launch-list #pagination-wrapper {
    visibility: hidden; }
    .table-launch-list #pagination-wrapper ul li {
      font-size: 12px !important;
      position: relative !important; }
      .table-launch-list #pagination-wrapper ul li:not(.pagination-button) {
        top: 6px !important; }
      .table-launch-list #pagination-wrapper ul li span {
        position: absolute;
        top: -42px;
        display: inline-block;
        left: 50%;
        -webkit-transform: translate(-50%, 0);
                transform: translate(-50%, 0); }
      .table-launch-list #pagination-wrapper ul li.active {
        width: 30px !important;
        height: 30px !important; }
        .table-launch-list #pagination-wrapper ul li.active span {
          top: -40px !important; }
      .table-launch-list #pagination-wrapper ul li.pagination-label {
        top: 5px !important; }
        .table-launch-list #pagination-wrapper ul li.pagination-label svg {
          height: 12px; }

.modal-launch .modal-content {
  width: 478px; }
  .modal-launch .modal-content button {
    width: calc(50% - 2px) !important;
    margin-right: 4px; }
    .modal-launch .modal-content button:nth-last-child(1) {
      margin-right: 0 !important; }

.modal-launch-error .modal-content button {
  width: 100% !important;
  margin-right: 0 !important; }
  .modal-launch-error .modal-content button.no-fill-button {
    display: none !important; }

.entry-google {
  position: relative;
  width: 100%; }

.non-local-block {
  margin: 24px 0 0; }
  .non-local-block label {
    position: relative; }
    .non-local-block label svg {
      position: relative;
      top: -2px;
      margin-right: 4px; }
  .non-local-block .value-non-local {
    font-size: 20px;
    letter-spacing: -0.6px;
    line-height: 1;
    margin: 0 0 24px; }
  .non-local-block .region-block .custom-regions {
    font-size: 14px;
    letter-spacing: -0.4px;
    color: rgba(25, 25, 25, 0.6);
    display: block;
    margin-bottom: 10px; }
  .non-local-block .region-block.contact-block {
    margin-top: 14px; }
  .non-local-block .region-block .region-badge {
    font-size: 12px;
    letter-spacing: -0.4px;
    color: rgba(25, 25, 25, 0.6);
    margin: 0 8px 8px 0;
    background: rgba(25, 25, 25, 0.05);
    border-radius: 4px;
    padding: 2px 8px;
    display: inline-block; }

.entry-google .entry-google-top {
  padding: 0 292px 0px 0;
  border-bottom: solid 1px #dcdcdc;
  font-size: 20px;
  letter-spacing: -0.6px;
  font-weight: 700;
  margin: 0 0 24px 0;
  position: relative; }
  .entry-google .entry-google-top.local-disabled {
    padding: 0 130px 0 0; }
  .entry-google .entry-google-top.entry-google-top-non-local {
    padding: 0 130px 0 0; }
  .entry-google .entry-google-top.entry-google-bottom {
    padding: 0 120px 0px 0; }
    .entry-google .entry-google-top.entry-google-bottom .no-fill-button {
      right: 0 !important; }
  .entry-google .entry-google-top p {
    font-size: 14px;
    letter-spacing: -0.2px;
    color: rgba(25, 25, 25, 0.6); }
  .entry-google .entry-google-top .entry-google-top-button {
    position: absolute;
    right: 0;
    top: 0;
    font-size: 16px;
    letter-spacing: -0.5px;
    padding: 7px 32px;
    margin-left: 8px; }
    .entry-google .entry-google-top .entry-google-top-button img {
      margin-right: 4px;
      position: relative;
      top: -2px; }
  .entry-google .entry-google-top .no-fill-button {
    right: 174px; }
  .entry-google .entry-google-top .edit-non-local {
    right: 0; }

.entry-google .entry-google-middle {
  width: 100%;
  display: block;
  position: relative;
  margin-bottom: 24px; }
  .entry-google .entry-google-middle .contact-us-source {
    margin-top: 12px; }
    .entry-google .entry-google-middle .contact-us-source .contact-us-source-list {
      margin-right: 8px;
      border-radius: 4px;
      color: rgba(25, 25, 25, 0.6);
      background: rgba(25, 25, 25, 0.05);
      font-size: 12px;
      letter-spacing: -0.4px;
      display: inline-block;
      padding: 3px 8px; }
  .entry-google .entry-google-middle .text-value {
    font-size: 20px;
    letter-spacing: -0.6px;
    line-height: 1; }
    .entry-google .entry-google-middle .text-value .custom-region {
      margin: 0 0 8px 0;
      font-size: 14px;
      letter-spacing: -0.4px;
      color: rgba(25, 25, 25, 0.6); }
  .entry-google .entry-google-middle .search-delete-wrapper {
    position: relative;
    width: 100%;
    min-height: 48px; }
    .entry-google .entry-google-middle .search-delete-wrapper .delete-location {
      position: absolute;
      right: 0; }
  .entry-google .entry-google-middle label {
    display: block;
    font-size: 14px;
    letter-spacing: -0.4px;
    margin-bottom: 6px; }
    .entry-google .entry-google-middle label.location-list-label {
      margin-top: 24px; }
    .entry-google .entry-google-middle label svg {
      position: relative;
      top: -2px;
      margin-right: 4px; }
  .entry-google .entry-google-middle .entry-google-top-button {
    position: absolute;
    top: 0;
    right: 0; }

.modal-view-message .modal-content {
  padding: 32px;
  width: 477px; }

.modal-view-message .modal-body {
  padding: 0; }
  .modal-view-message .modal-body .header-modal {
    margin-bottom: 24px; }
    .modal-view-message .modal-body .header-modal b {
      font-size: 20px; }
    .modal-view-message .modal-body .header-modal svg {
      margin-right: 4px;
      position: relative;
      top: -3px; }
  .modal-view-message .modal-body .message-container label {
    font-size: 14px; }
  .modal-view-message .modal-body .message-container input {
    display: flex;
    align-items: center;
    background: white;
    border-radius: 8px;
    border: 1px solid rgba(25, 25, 25, 0.1);
    height: 48px;
    width: 100%;
    color: #191919;
    font-size: 16px;
    letter-spacing: -0.3px;
    max-width: 100%;
    padding: 0 16px;
    margin-bottom: 24px; }
    .modal-view-message .modal-body .message-container input:disabled {
      background: rgba(25, 25, 25, 0.05);
      cursor: not-allowed; }
  .modal-view-message .modal-body .message-container textarea {
    background: white;
    border-radius: 8px;
    border: 1px solid rgba(25, 25, 25, 0.1);
    min-height: 48px;
    width: 100%;
    color: #191919;
    font-size: 16px;
    letter-spacing: -0.3px;
    max-width: 100%;
    padding: 12px 16px;
    line-height: 24px;
    resize: none;
    margin-bottom: 20px; }
    .modal-view-message .modal-body .message-container textarea:disabled {
      background: rgba(25, 25, 25, 0.05);
      cursor: not-allowed; }

.table-entry-list {
  table-layout: fixed;
  min-height: 115px; }
  .table-entry-list .td-item-per-page {
    width: 70px; }
  .table-entry-list .custom-checkbox {
    display: inline-block; }
  .table-entry-list .custom-checkbox-stripe {
    display: inline-block; }
  .table-entry-list thead tr {
    background: transparent; }
    .table-entry-list thead tr .custom-checkbox-stripe {
      position: absolute !important;
      top: 1px !important;
      left: 12px !important; }
    .table-entry-list thead tr .custom-checkbox-stripe [type="checkbox"]:not(:checked) + label:before {
      left: -3px; }
    .table-entry-list thead tr .custom-checkbox-stripe [type="checkbox"]:checked + label:before {
      left: -3px; }
    .table-entry-list thead tr .custom-checkbox-stripe [type="checkbox"]:checked + label:after {
      width: 16px;
      height: 16px;
      top: 2px;
      left: -3px; }
    .table-entry-list thead tr td {
      padding: 8px;
      width: 20%;
      font-size: 14px;
      word-wrap: break-word;
      white-space: normal;
      position: relative; }
      .table-entry-list thead tr td .question-circle-wrapper {
        margin-left: 5px;
        position: absolute;
        top: 25%; }
      .table-entry-list thead tr td:first-child {
        width: 30px; }
      .table-entry-list thead tr td:last-child .custom-select-option-value-wrapper {
        width: 55px !important;
        height: 23px;
        padding: 2px 8px 2px 12px !important;
        border-radius: 4px !important; }
        .table-entry-list thead tr td:last-child .custom-select-option-value-wrapper p {
          font-size: 12px;
          font-weight: bold; }
        .table-entry-list thead tr td:last-child .custom-select-option-value-wrapper .custom-select-option-value {
          padding: 0; }
        .table-entry-list thead tr td:last-child .custom-select-option-value-wrapper svg {
          right: 3px;
          height: 15px;
          top: 11px; }
  .table-entry-list tbody tr {
    border-bottom: none; }
    .table-entry-list tbody tr:hover {
      background-color: unset; }
      .table-entry-list tbody tr:hover td {
        color: unset; }
    .table-entry-list tbody tr td:not(.no-data-column) {
      padding: 8px; }
      .table-entry-list tbody tr td:not(.no-data-column) p {
        margin-bottom: 0; }
    .table-entry-list tbody tr td {
      background: transparent;
      position: relative;
      word-wrap: break-word;
      vertical-align: top;
      white-space: nowrap; }
      .table-entry-list tbody tr td.alias-wrap {
        position: relative;
        padding: 8px 30px 8px 8px;
        white-space: unset !important; }
        .table-entry-list tbody tr td.alias-wrap svg {
          position: absolute;
          top: 10px;
          right: 8px;
          font-size: 16px;
          cursor: pointer; }
          .table-entry-list tbody tr td.alias-wrap svg:hover {
            color: var(--main-darker-orange); }
      .table-entry-list tbody tr td.status-wrap .badge-status {
        font-size: 14px;
        letter-spacing: -0.4px; }
        .table-entry-list tbody tr td.status-wrap .badge-status svg, .table-entry-list tbody tr td.status-wrap .badge-status img {
          position: relative;
          top: -2px;
          margin-right: 4px; }
      .table-entry-list tbody tr td.status-wrap button {
        font-size: 14px;
        letter-spacing: -0.5px;
        padding: 4px 17px;
        border-radius: 6px;
        color: #fff;
        background: #ff7d00;
        cursor: pointer;
        border: 0;
        box-shadow: 0; }
        .table-entry-list tbody tr td.status-wrap button:hover {
          background: var(--main-darker-orange); }
      .table-entry-list tbody tr td.test-url-wrap {
        color: #ff7d00; }
        .table-entry-list tbody tr td.test-url-wrap b {
          margin-right: 8px;
          cursor: pointer; }
          .table-entry-list tbody tr td.test-url-wrap b img {
            position: relative;
            top: -2px;
            margin-right: 4px; }
          .table-entry-list tbody tr td.test-url-wrap b:hover {
            text-decoration: underline; }
      .table-entry-list tbody tr td .unavailable {
        color: rgba(25, 25, 25, 0.4); }
      .table-entry-list tbody tr td .link-user {
        color: inherit;
        text-decoration: inherit; }
      .table-entry-list tbody tr td:nth-child(2) p {
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical; }
      .table-entry-list tbody tr td.col-view {
        color: #ff7e00;
        cursor: pointer;
        font-weight: 700;
        text-align: right; }
        .table-entry-list tbody tr td.col-view a {
          color: #ff7e00; }
        .table-entry-list tbody tr td.col-view svg {
          position: relative;
          color: #ff7e00;
          font-size: 16px;
          margin-right: 4px;
          right: 0;
          top: -2px; }
  .table-entry-list tbody .no-data-row .no-result-found {
    color: rgba(25, 25, 25, 0.6);
    margin-top: 18px; }
  .table-entry-list tbody .no-data-row button svg {
    right: unset !important; }
  .table-entry-list .td_place_id {
    white-space: unset !important;
    width: 255px !important; }
  .table-entry-list .td_place_alias {
    width: 200px; }
  .table-entry-list .td_verification_status {
    width: 168px; }
  .table-entry-list .td_test_url {
    width: 152px; }
  .table-entry-list #pagination-wrapper {
    visibility: hidden; }
    .table-entry-list #pagination-wrapper ul li {
      font-size: 12px !important;
      position: relative !important; }
      .table-entry-list #pagination-wrapper ul li:not(.pagination-button) {
        top: 6px !important; }
      .table-entry-list #pagination-wrapper ul li span {
        position: absolute;
        top: -42px;
        display: inline-block;
        left: 50%;
        -webkit-transform: translate(-50%, 0);
                transform: translate(-50%, 0); }
      .table-entry-list #pagination-wrapper ul li.active {
        width: 30px !important;
        height: 30px !important; }
        .table-entry-list #pagination-wrapper ul li.active span {
          top: -40px !important; }
      .table-entry-list #pagination-wrapper ul li.pagination-label {
        top: 5px !important; }
        .table-entry-list #pagination-wrapper ul li.pagination-label svg {
          height: 12px; }

.modal-edit-alias-entry .modal-content {
  width: 476px; }
  .modal-edit-alias-entry .modal-content .modal-body {
    padding: 32px; }
    .modal-edit-alias-entry .modal-content .modal-body .edit-alias-title {
      font-size: 20px;
      letter-spacing: -0.6px;
      color: #191919;
      margin: 0 0 20px 0; }
    .modal-edit-alias-entry .modal-content .modal-body p {
      font-size: 14px;
      letter-spacing: -0.2px;
      position: relative;
      margin: 20px 0 8px 0; }
      .modal-edit-alias-entry .modal-content .modal-body p span {
        color: rgba(25, 25, 25, 0.6);
        position: absolute;
        top: 0;
        right: 0; }
    .modal-edit-alias-entry .modal-content .modal-body input {
      width: 100%;
      padding: 11px 18px;
      border-radius: 8px;
      border: solid 1px #dcdcdc;
      font-size: 16px;
      letter-spacing: -0.3px;
      margin: 0; }
    .modal-edit-alias-entry .modal-content .modal-body button {
      margin-right: 4px;
      width: calc(50% - 2px);
      display: inline-block;
      vertical-align: top;
      margin-top: 20px; }
      .modal-edit-alias-entry .modal-content .modal-body button:nth-last-child(1) {
        margin-right: 0; }

.modal-add-location .modal-content {
  width: 600px; }

.modal-add-location .modal-body {
  border-radius: 8px;
  padding: 32px;
  position: relative; }
  .modal-add-location .modal-body input[type="file"] {
    display: none; }
  .modal-add-location .modal-body .modal-add-location-title {
    font-size: 20px;
    letter-spacing: -0.6px;
    color: #191919;
    line-height: 1; }
  .modal-add-location .modal-body .modal-add-location-desc {
    font-size: 16px;
    letter-spacing: -0.3px;
    color: rgba(25, 25, 25, 0.8);
    margin: 4px 0 28px;
    line-height: 1; }
  .modal-add-location .modal-body .close-icon {
    font-size: 24px;
    position: absolute;
    top: 32px;
    right: 32px;
    color: #ff7d00;
    cursor: pointer; }
    .modal-add-location .modal-body .close-icon:hover {
      color: var(--main-darker-orange); }
  .modal-add-location .modal-body .selection-box {
    width: 50%;
    display: inline-block;
    vertical-align: top;
    text-align: center;
    border-right: solid 1px rgba(25, 25, 25, 0.1); }
    .modal-add-location .modal-body .selection-box:nth-last-child(1) {
      border: none !important; }
    .modal-add-location .modal-body .selection-box img {
      margin: 0 0 12px 0; }
    .modal-add-location .modal-body .selection-box b {
      font-size: 16px;
      letter-spacing: -0.5px; }
    .modal-add-location .modal-body .selection-box p {
      font-size: 16px;
      letter-spacing: -0.5px;
      margin: 4px 0 12px;
      color: rgba(25, 25, 25, 0.6); }
    .modal-add-location .modal-body .selection-box button {
      height: 22px;
      padding: 4px 10px;
      border-radius: 4px; }
      .modal-add-location .modal-body .selection-box button .lds-ring {
        width: 13px;
        height: 13px;
        position: relative;
        margin-right: 8px;
        top: -6px; }
        .modal-add-location .modal-body .selection-box button .lds-ring div {
          border: solid 1px #fff;
          border-color: #fff transparent transparent transparent;
          margin: 0;
          width: 13px;
          height: 13px; }
      .modal-add-location .modal-body .selection-box button.disabled {
        background: rgba(25, 25, 25, 0.6) !important;
        border: solid 1px transparent !important; }
      .modal-add-location .modal-body .selection-box button b {
        font-size: 12px;
        letter-spacing: -0.4px;
        position: relative;
        top: -9px; }
      .modal-add-location .modal-body .selection-box button svg {
        margin-right: 6px;
        position: relative;
        top: -9px;
        font-size: 12px; }
      .modal-add-location .modal-body .selection-box button.download-template {
        background: #fff !important;
        color: #ff7d00 !important;
        box-shadow: none !important;
        border: 0 !important; }
        .modal-add-location .modal-body .selection-box button.download-template svg {
          color: #ff7d00 !important; }
        .modal-add-location .modal-body .selection-box button.download-template:hover {
          color: var(--main-darker-orange) !important; }
          .modal-add-location .modal-body .selection-box button.download-template:hover svg {
            color: var(--main-darker-orange) !important; }

.modal-verification-entry .modal-content {
  width: 538px; }
  .modal-verification-entry .modal-content button {
    width: calc(50% - 2px) !important;
    margin-right: 4px; }
    .modal-verification-entry .modal-content button:nth-last-child(1) {
      margin-right: 0; }

#pagination-wrapper {
  width: 100%;
  text-align: right;
  height: 110px;
  line-height: 110px; }
  #pagination-wrapper ul {
    padding: 0;
    margin: 0;
    list-style: none; }
    #pagination-wrapper ul li {
      display: inline;
      padding: 8px;
      color: rgba(25, 25, 25, 0.6);
      font-weight: 600;
      font-size: 12px;
      letter-spacing: -0.4px;
      margin-right: 12px; }
      #pagination-wrapper ul li.active {
        cursor: pointer;
        position: relative;
        color: #fff;
        /* padding: 12px 20px; */ }
        #pagination-wrapper ul li.active::before {
          content: "";
          width: 30px;
          height: 30px;
          background: var(--main-dark-orange);
          position: absolute;
          border-radius: 4px;
          top: 50%;
          left: 50%;
          -webkit-transform: translate(-50%, -50%);
                  transform: translate(-50%, -50%);
          z-index: 1; }
        #pagination-wrapper ul li.active span {
          z-index: 2;
          position: relative; }
      #pagination-wrapper ul li.enabled {
        cursor: pointer; }
        #pagination-wrapper ul li.enabled:hover {
          position: relative;
          color: #FF7E00; }
          #pagination-wrapper ul li.enabled:hover::before {
            content: "";
            width: 30px;
            height: 30px;
            background: #FFF2E5;
            position: absolute;
            border-radius: 4px;
            top: 50%;
            left: 50%;
            -webkit-transform: translate(-50%, -50%);
                    transform: translate(-50%, -50%);
            z-index: 1; }
          #pagination-wrapper ul li.enabled:hover span {
            z-index: 2;
            position: relative; }
          #pagination-wrapper ul li.enabled:hover img {
            z-index: 2; }
      #pagination-wrapper ul li .pagination-button-icon {
        vertical-align: middle !important;
        position: relative !important;
        top: -1px !important; }
      #pagination-wrapper ul li.pagination-input {
        position: relative;
        top: -1px;
        margin-right: 0;
        padding-left: 8px;
        border-left: solid 1px #1919191A;
        cursor: default; }
      #pagination-wrapper ul li .page-input-form {
        display: inline-block; }
        #pagination-wrapper ul li .page-input-form .page-input {
          margin: 0 0 0 4px;
          padding: 8px;
          border: solid 1px rgba(25, 25, 25, 0.1);
          border-radius: 8px;
          width: 45px;
          height: 34px;
          font-family: "PTRootBold";
          font-size: 12px;
          line-height: 18px;
          letter-spacing: -0.4px;
          text-align: center; }
          #pagination-wrapper ul li .page-input-form .page-input:focus {
            border: solid 1px var(--main-dark-orange) !important; }
      #pagination-wrapper ul li .pagination-label {
        vertical-align: middle;
        position: relative;
        top: -1px;
        margin: 0 0 0 4px;
        font-family: "PTRootBold" !important;
        font-weight: 700 !important;
        font-size: 12px;
        line-height: 18px;
        letter-spacing: -0.4px;
        color: #19191999; }

.integration-google-edit-topic-account {
  padding: 32px 30px;
  text-align: left; }
  .integration-google-edit-topic-account .comp-back-button {
    margin: 0 0 16px 0;
    font-family: PTRootBold; }
  .integration-google-edit-topic-account .integration-google-edit-topic-account-box {
    background: #ffffff;
    border-radius: 8px;
    padding: 32px;
    border: solid 1px rgba(25, 25, 25, 0.1); }
    .integration-google-edit-topic-account .integration-google-edit-topic-account-box .form-content-wrapper label {
      padding: 0 !important; }
    .integration-google-edit-topic-account .integration-google-edit-topic-account-box .integration-google-edit-topic-account-box-top {
      padding-bottom: 15px;
      border-bottom: solid 1px #dcdcdc;
      position: relative;
      font-size: 20px;
      letter-spacing: -0.6px; }
      .integration-google-edit-topic-account .integration-google-edit-topic-account-box .integration-google-edit-topic-account-box-top button {
        position: absolute;
        top: -6px;
        right: 0;
        font-size: 16px;
        letter-spacing: -0.6px; }
    .integration-google-edit-topic-account .integration-google-edit-topic-account-box .integration-google-edit-topic-account-box-form {
      width: 100%; }
      .integration-google-edit-topic-account .integration-google-edit-topic-account-box .integration-google-edit-topic-account-box-form label {
        padding: 24px 0 0;
        font-size: 14px;
        letter-spacing: -0.4px;
        display: block; }
      .integration-google-edit-topic-account .integration-google-edit-topic-account-box .integration-google-edit-topic-account-box-form input[type='text'] {
        padding: 12px 16px;
        border-radius: 6px;
        border: solid 1px #dcdcdc;
        width: 100%;
        font-size: 16px;
        letter-spacing: -0.3px;
        display: block; }
      .integration-google-edit-topic-account .integration-google-edit-topic-account-box .integration-google-edit-topic-account-box-form .form-topic {
        width: 575px; }
      .integration-google-edit-topic-account .integration-google-edit-topic-account-box .integration-google-edit-topic-account-box-form input[type='text']:disabled {
        background: rgba(25, 25, 25, 0.1);
        color: rgba(25, 25, 25, 0.2);
        border: none;
        cursor: not-allowed; }
      .integration-google-edit-topic-account .integration-google-edit-topic-account-box .integration-google-edit-topic-account-box-form .integration-info-form {
        font-size: 14px;
        color: rgba(25, 25, 25, 0.8);
        letter-spacing: -0.2px; }
      .integration-google-edit-topic-account .integration-google-edit-topic-account-box .integration-google-edit-topic-account-box-form .custom-select-google-topic .custom-select-option-value-wrapper {
        padding: 12px 16px !important;
        margin-bottom: 8px; }

.edit-profile-outer-wrapper .edit-account-profile-google {
  position: relative;
  width: 100%; }
  .edit-profile-outer-wrapper .edit-account-profile-google label {
    display: block; }
  .edit-profile-outer-wrapper .edit-account-profile-google .edit-account-profile-header {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid rgba(25, 25, 25, 0.1);
    margin-bottom: 24px;
    padding-bottom: 12px; }
    .edit-profile-outer-wrapper .edit-account-profile-google .edit-account-profile-header .title-wrapper b {
      font-size: 20px; }
    .edit-profile-outer-wrapper .edit-account-profile-google .edit-account-profile-header button {
      margin-top: 0;
      white-space: nowrap; }
  .edit-profile-outer-wrapper .edit-account-profile-google textarea, .edit-profile-outer-wrapper .edit-account-profile-google input {
    padding: 12px 16px;
    font-size: 16px;
    letter-spacing: -0.3px;
    border-radius: 8px;
    border: solid 1px #dcdcdc; }
  .edit-profile-outer-wrapper .edit-account-profile-google .custom-checkbox {
    top: 2px !important; }
    .edit-profile-outer-wrapper .edit-account-profile-google .custom-checkbox label {
      font-size: 14px;
      width: 200px;
      color: #191919; }

.edit-account-profile-content label {
  font-size: 14px;
  position: relative; }
  .edit-account-profile-content label span {
    position: absolute;
    top: 0;
    right: 0; }

.edit-account-profile-content .photo-wrapper {
  margin-bottom: 8px;
  border: 1px solid rgba(25, 25, 25, 0.1);
  border-radius: 4px;
  width: 96px;
  height: 96px; }
  .edit-account-profile-content .photo-wrapper img {
    border-radius: 4px;
    height: 96px;
    width: 96px; }
  .edit-account-profile-content .photo-wrapper .profile-photo-empty {
    height: 94px;
    background: rgba(25, 25, 25, 0.05);
    position: relative; }
    .edit-account-profile-content .photo-wrapper .profile-photo-empty svg {
      height: 32px;
      width: 32px;
      color: #9c9c9c;
      position: absolute;
      left: 50%;
      top: 50%;
      -webkit-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%); }

.edit-account-profile-content .button-wrapper {
  margin-bottom: 8px; }
  .edit-account-profile-content .button-wrapper > div {
    display: flex; }
    .edit-account-profile-content .button-wrapper > div label {
      margin-right: 8px; }
  .edit-account-profile-content .button-wrapper label {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    cursor: pointer;
    margin-bottom: 0 !important; }
    .edit-account-profile-content .button-wrapper label input {
      width: 100%; }

.edit-entry-point-google .edit-entry-point-header {
  position: relative; }
  .edit-entry-point-google .edit-entry-point-header .title-wrapper {
    width: 100%;
    border-bottom: solid 1px #dcdcdc;
    padding-bottom: 30px;
    margin-bottom: 24px; }
  .edit-entry-point-google .edit-entry-point-header button {
    right: 0;
    top: 0;
    position: absolute; }

.edit-entry-point-google .form-title {
  font-size: 16px;
  letter-spacing: -0.5px;
  color: #191919;
  margin: 0 0 4px 0;
  padding: 0 80px 0 0;
  position: relative; }
  .edit-entry-point-google .form-title .custom-switch-wrapper {
    display: inline-block !important;
    width: 40px;
    height: 24px;
    position: absolute;
    top: 0;
    right: 0;
    margin: 0; }
    .edit-entry-point-google .form-title .custom-switch-wrapper .custom-switch.round::before {
      width: 14px;
      height: 14px;
      bottom: 5px;
      left: 4px; }
    .edit-entry-point-google .form-title .custom-switch-wrapper input:checked + .custom-switch:before {
      -webkit-transform: translateX(18px);
              transform: translateX(18px); }

.edit-entry-point-google .form-description {
  font-size: 16px;
  letter-spacing: -0.5px;
  color: rgba(25, 25, 25, 0.8);
  margin: 0;
  padding: 0 80px 0 0; }

.edit-entry-point-google .input-phone {
  position: relative; }

.edit-entry-point-google .edit-entry-point-header {
  position: relative; }
  .edit-entry-point-google .edit-entry-point-header .title-wrapper {
    width: 100%;
    border-bottom: solid 1px #dcdcdc;
    padding-bottom: 24px;
    margin-bottom: 24px; }
    .edit-entry-point-google .edit-entry-point-header .title-wrapper b {
      font-size: 20px;
      letter-spacing: -0.6px; }
  .edit-entry-point-google .edit-entry-point-header button {
    right: 0;
    top: 0;
    position: absolute; }

.edit-entry-point-google .form-title {
  font-size: 16px;
  letter-spacing: -0.5px;
  color: #191919;
  margin: 0 0 4px 0;
  padding: 0 80px 0 0;
  position: relative; }
  .edit-entry-point-google .form-title .custom-switch-wrapper {
    display: inline-block !important;
    width: 40px;
    height: 24px;
    position: absolute;
    top: 0;
    right: 0;
    margin: 0; }
    .edit-entry-point-google .form-title .custom-switch-wrapper .custom-switch.round::before {
      width: 14px;
      height: 14px;
      bottom: 5px;
      left: 4px; }
    .edit-entry-point-google .form-title .custom-switch-wrapper input:checked + .custom-switch:before {
      -webkit-transform: translateX(18px);
              transform: translateX(18px); }

.edit-entry-point-google .form-description {
  font-size: 16px;
  letter-spacing: -0.5px;
  color: rgba(25, 25, 25, 0.8);
  margin: 0;
  padding: 0 80px 0 0; }

.edit-entry-point-google .non-local-form-wrapper {
  margin: 24px 0 0; }
  .edit-entry-point-google .non-local-form-wrapper .checkbox-wrapper label {
    font-size: 14px;
    letter-spacing: -0.2px; }
  .edit-entry-point-google .non-local-form-wrapper input[type="text"] {
    border: solid 1px #dcdcdc; }

.location-entry-point-google .location-entry-point-header {
  position: relative; }
  .location-entry-point-google .location-entry-point-header .title-wrapper {
    width: 100%;
    border-bottom: solid 1px #dcdcdc;
    padding-bottom: 12px;
    margin-bottom: 24px; }
    .location-entry-point-google .location-entry-point-header .title-wrapper b {
      font-size: 20px;
      letter-spacing: -0.6px; }
    .location-entry-point-google .location-entry-point-header .title-wrapper p {
      font-size: 14px;
      letter-spacing: -0.2px;
      margin: 0;
      color: rgba(25, 25, 25, 0.8); }
  .location-entry-point-google .location-entry-point-header button {
    right: 0;
    top: 0;
    position: absolute; }

.location-entry-point-google .location-entry-point-body {
  width: 400px; }
  .location-entry-point-google .location-entry-point-body input[type="text"] {
    width: 100%;
    border-radius: 8px;
    border: solid 1px #dcdcdc;
    font-size: 16px;
    letter-spacing: -0.3px;
    padding: 12px 16px; }
  .location-entry-point-google .location-entry-point-body button {
    margin-top: 24px; }
  .location-entry-point-google .location-entry-point-body .info-input {
    font-size: 12px;
    letter-spacing: -0.4px;
    color: #191919;
    margin-top: 8px; }
    .location-entry-point-google .location-entry-point-body .info-input svg {
      position: relative;
      top: -1px;
      margin-right: 4px; }
    .location-entry-point-google .location-entry-point-body .info-input a {
      text-decoration: none;
      color: #ff7d00; }
      .location-entry-point-google .location-entry-point-body .info-input a:hover {
        color: var(--main-darker-orange); }

.location-entry-point-google .form-title {
  font-size: 16px;
  letter-spacing: -0.5px;
  color: #191919;
  margin: 24px 0 4px 0;
  padding: 0 80px 0 0;
  position: relative;
  width: 100%; }
  .location-entry-point-google .form-title span {
    color: rgba(25, 25, 25, 0.6);
    font-size: 14px;
    letter-spacing: -0.2px;
    position: absolute;
    top: 0;
    right: 0; }
  .location-entry-point-google .form-title .custom-switch-wrapper {
    display: inline-block !important;
    width: 40px;
    height: 24px;
    position: absolute;
    top: 0;
    right: 0;
    margin: 0; }
    .location-entry-point-google .form-title .custom-switch-wrapper .custom-switch.round::before {
      width: 14px;
      height: 14px;
      bottom: 5px;
      left: 4px; }
    .location-entry-point-google .form-title .custom-switch-wrapper input:checked + .custom-switch:before {
      -webkit-transform: translateX(18px);
              transform: translateX(18px); }

.location-entry-point-google .form-description {
  font-size: 16px;
  letter-spacing: -0.5px;
  color: rgba(25, 25, 25, 0.8);
  margin: 0;
  padding: 0 80px 0 0; }

.chatbot-add-chatbot {
  padding: 32px 30px;
  text-align: left; }
  .chatbot-add-chatbot .comp-back-button {
    margin: 0 0 16px 0; }
  .chatbot-add-chatbot .alert-component {
    margin-top: 36px; }
  .chatbot-add-chatbot .chatbot-add-chatbot-box {
    background: #ffffff;
    border-radius: 8px;
    padding: 32px;
    border: solid 1px rgba(25, 25, 25, 0.1); }
    .chatbot-add-chatbot .chatbot-add-chatbot-box .chatbot-add-chatbot-box-top {
      padding-bottom: 15px;
      border-bottom: solid 1px #dcdcdc;
      position: relative;
      font-size: 20px;
      letter-spacing: -0.6px; }
      .chatbot-add-chatbot .chatbot-add-chatbot-box .chatbot-add-chatbot-box-top .chat-bot-chatbot-button-wrapper {
        margin: 0; }
      .chatbot-add-chatbot .chatbot-add-chatbot-box .chatbot-add-chatbot-box-top button {
        position: absolute;
        top: -6px;
        right: 0;
        font-size: 16px;
        margin: 0 !important;
        letter-spacing: -0.6px; }
    .chatbot-add-chatbot .chatbot-add-chatbot-box .chatbot-add-chatbot-box-form {
      width: 320px; }
      .chatbot-add-chatbot .chatbot-add-chatbot-box .chatbot-add-chatbot-box-form label {
        padding: 24px 0 0;
        font-size: 14px;
        letter-spacing: -0.4px;
        display: block; }
      .chatbot-add-chatbot .chatbot-add-chatbot-box .chatbot-add-chatbot-box-form input[type="text"] {
        padding: 12px 16px;
        border-radius: 6px;
        border: solid 1px #dcdcdc;
        width: 100%;
        font-size: 16px;
        letter-spacing: -0.3px;
        display: block;
        margin-bottom: 8px; }
      .chatbot-add-chatbot .chatbot-add-chatbot-box .chatbot-add-chatbot-box-form .integration-info-form {
        font-size: 14px;
        color: rgba(25, 25, 25, 0.8);
        letter-spacing: -0.2px; }
      .chatbot-add-chatbot .chatbot-add-chatbot-box .chatbot-add-chatbot-box-form .custom-select-path .custom-select-option-value-wrapper {
        padding: 12px 16px !important;
        margin-bottom: 8px; }
    .chatbot-add-chatbot .chatbot-add-chatbot-box .chat-bot-chatbot-button-wrapper {
      margin-top: 18px; }
      .chatbot-add-chatbot .chatbot-add-chatbot-box .chat-bot-chatbot-button-wrapper button {
        margin: 0 4px 0 0; }


.integration-google-profile-list {
  text-align: left; }
  .integration-google-profile-list .integration-list-loading-wrapper .lds-ring {
    width: 50px;
    height: 50px;
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    right: unset; }
    .integration-google-profile-list .integration-list-loading-wrapper .lds-ring div {
      border: 5px solid #fff;
      border-color: #ff7d00 transparent transparent transparent;
      width: 50px;
      height: 50px;
      margin: 0; }
  .integration-google-profile-list .integration-list-no-data {
    width: 100%;
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
    .integration-google-profile-list .integration-list-no-data b {
      font-size: 20px;
      letter-spacing: -0.6px;
      color: #191919; }
    .integration-google-profile-list .integration-list-no-data p {
      color: rgba(25, 25, 25, 0.6);
      letter-spacing: -0.6px;
      font-size: 16px; }
  .integration-google-profile-list .integration-list-header {
    position: relative;
    padding-left: 42px;
    padding-bottom: 22px;
    border-bottom: solid 1px rgba(25, 25, 25, 0.1); }
    .integration-google-profile-list .integration-list-header .integration-list-header-icon {
      position: absolute;
      top: 0;
      left: 0;
      width: 32px; }
    .integration-google-profile-list .integration-list-header .integration-list-header-text-wrapper b {
      font-size: 20px;
      letter-spacing: -0.6px; }
    .integration-google-profile-list .integration-list-header .integration-list-header-text-wrapper p {
      margin: 0;
      font-size: 14px;
      letter-spacing: -0.2px; }
    .integration-google-profile-list .integration-list-header .integration-list-header-text-wrapper a {
      color: #ff7d00; }
    .integration-google-profile-list .integration-list-header .integration-list-header-button {
      position: absolute;
      right: 0;
      top: 0;
      font-size: 16px;
      letter-spacing: -0.5px;
      padding: 7px 32px; }
      .integration-google-profile-list .integration-list-header .integration-list-header-button img {
        margin-right: 4px;
        position: relative;
        top: -2px; }
  .integration-google-profile-list .integration-list-outer-wrapper {
    position: relative;
    width: 100%;
    padding-top: 24px; }
    .integration-google-profile-list .integration-list-outer-wrapper .integration-list-inner-wrapper {
      width: calc((100% / 3) - 8px);
      border-radius: 8px;
      margin: 0 12px 12px 0;
      box-shadow: 0 2px 20px 0 rgba(25, 25, 25, 0.05);
      background: #ffffff;
      display: inline-block;
      vertical-align: top;
      padding: 24px 36px 24px 24px;
      cursor: pointer;
      position: relative; }
      .integration-google-profile-list .integration-list-outer-wrapper .integration-list-inner-wrapper p:nth-child(2) {
        font-size: 16px;
        letter-spacing: -0.5px;
        color: rgba(25, 25, 25, 0.6);
        margin: 0;
        white-space: nowrap;
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis; }
      .integration-google-profile-list .integration-list-outer-wrapper .integration-list-inner-wrapper span {
        display: block;
        font-size: 12px;
        letter-spacing: -0.2px;
        color: rgba(25, 25, 25, 0.6); }
      .integration-google-profile-list .integration-list-outer-wrapper .integration-list-inner-wrapper p {
        margin: 28px 0 0;
        color: #ff7d00;
        font-size: 12px;
        letter-spacing: -0.2px; }
        .integration-google-profile-list .integration-list-outer-wrapper .integration-list-inner-wrapper p.font-green {
          color: #19c700; }
        .integration-google-profile-list .integration-list-outer-wrapper .integration-list-inner-wrapper p.font-grey {
          color: rgba(25, 25, 25, 0.4); }
      .integration-google-profile-list .integration-list-outer-wrapper .integration-list-inner-wrapper svg {
        position: absolute;
        right: 12px;
        top: 12px;
        font-size: 22px;
        color: rgba(25, 25, 25, 0.6); }
      .integration-google-profile-list .integration-list-outer-wrapper .integration-list-inner-wrapper:hover {
        box-shadow: 0 2px 20px 0 rgba(25, 25, 25, 0.18); }
        .integration-google-profile-list .integration-list-outer-wrapper .integration-list-inner-wrapper:hover p:nth-child(2) {
          color: #191919; }
        .integration-google-profile-list .integration-list-outer-wrapper .integration-list-inner-wrapper:hover svg {
          color: #ff7d00; }
  .integration-google-profile-list .link-google-account-list:nth-child(3n) .integration-list-inner-wrapper {
    margin: 0 0 12px 0; }

.integration-google-profile-detail-account {
  padding: 32px 30px;
  text-align: left; }
  .integration-google-profile-detail-account .no-fill-black-button {
    width: 224px;
    margin-top: 22px; }
    .integration-google-profile-detail-account .no-fill-black-button img {
      width: 20px !important; }
  .integration-google-profile-detail-account .comp-back-button {
    margin: 0 0 16px 0;
    font-family: PTRootBold; }
  .integration-google-profile-detail-account .view-in-google-maps {
    color: #ff7d00;
    margin: 16px 0 0; }
    .integration-google-profile-detail-account .view-in-google-maps svg {
      margin-right: 4px;
      position: relative;
      top: -1px; }
    .integration-google-profile-detail-account .view-in-google-maps b {
      font-size: 14px !important;
      letter-spacing: -0.4px !important; }
    .integration-google-profile-detail-account .view-in-google-maps:hover {
      text-decoration: underline !important; }
  .integration-google-profile-detail-account .integration-google-profile-detail-account-box-2 {
    background: #ffffff;
    border-radius: 8px;
    padding: 0;
    border: solid 1px rgba(25, 25, 25, 0.1);
    position: relative;
    margin-top: 24px; }
    .integration-google-profile-detail-account .integration-google-profile-detail-account-box-2 .integration-auto-message-detail-margin-top {
      margin: 0; }
  .integration-google-profile-detail-account .integration-google-profile-detail-account-box-1 {
    background: #ffffff;
    border-radius: 8px;
    padding: 22px 32px 32px;
    border: solid 1px rgba(25, 25, 25, 0.1);
    position: relative; }
    .integration-google-profile-detail-account .integration-google-profile-detail-account-box-1 .integration-google-profile-detail-account-box-1-top {
      padding: 10px 0 15px;
      border-bottom: solid 1px #dcdcdc;
      position: relative;
      font-size: 20px;
      letter-spacing: -0.6px;
      padding-right: 230px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis; }
      .integration-google-profile-detail-account .integration-google-profile-detail-account-box-1 .integration-google-profile-detail-account-box-1-top.box-channel-button {
        margin-top: 24px; }
        .integration-google-profile-detail-account .integration-google-profile-detail-account-box-1 .integration-google-profile-detail-account-box-1-top.box-channel-button .messenger-detail-topic {
          display: block; }
        .integration-google-profile-detail-account .integration-google-profile-detail-account-box-1 .integration-google-profile-detail-account-box-1-top.box-channel-button .topic-wrapper {
          margin-top: 24px;
          width: 100%;
          display: block; }
        .integration-google-profile-detail-account .integration-google-profile-detail-account-box-1 .integration-google-profile-detail-account-box-1-top.box-channel-button .status-wrapper .status-value {
          color: rgba(25, 25, 25, 0.4); }
          .integration-google-profile-detail-account .integration-google-profile-detail-account-box-1 .integration-google-profile-detail-account-box-1-top.box-channel-button .status-wrapper .status-value.status-enabled {
            color: #19C700; }
        .integration-google-profile-detail-account .integration-google-profile-detail-account-box-1 .integration-google-profile-detail-account-box-1-top.box-channel-button .status-wrapper img {
          position: relative;
          top: -1px;
          margin-right: 4px; }
      .integration-google-profile-detail-account .integration-google-profile-detail-account-box-1 .integration-google-profile-detail-account-box-1-top .bot-name {
        font-size: 12px;
        margin-bottom: 0; }
      .integration-google-profile-detail-account .integration-google-profile-detail-account-box-1 .integration-google-profile-detail-account-box-1-top .integration-google-profile-detail-account-box-1-top-button {
        position: absolute;
        top: 0px;
        right: 0;
        display: inline-block; }
        .integration-google-profile-detail-account .integration-google-profile-detail-account-box-1 .integration-google-profile-detail-account-box-1-top .integration-google-profile-detail-account-box-1-top-button button {
          padding: 7px 24px;
          font-size: 16px;
          letter-spacing: -0.6px;
          height: 40px;
          margin-left: 8px; }
          .integration-google-profile-detail-account .integration-google-profile-detail-account-box-1 .integration-google-profile-detail-account-box-1-top .integration-google-profile-detail-account-box-1-top-button button img {
            position: relative;
            top: -2px;
            margin-right: 4px; }
          .integration-google-profile-detail-account .integration-google-profile-detail-account-box-1 .integration-google-profile-detail-account-box-1-top .integration-google-profile-detail-account-box-1-top-button button svg {
            position: relative;
            top: -2px;
            margin-right: 4px; }
          .integration-google-profile-detail-account .integration-google-profile-detail-account-box-1 .integration-google-profile-detail-account-box-1-top .integration-google-profile-detail-account-box-1-top-button button .lds-ring {
            top: unset;
            right: unset;
            position: relative;
            top: 3px; }
            .integration-google-profile-detail-account .integration-google-profile-detail-account-box-1 .integration-google-profile-detail-account-box-1-top .integration-google-profile-detail-account-box-1-top-button button .lds-ring div {
              margin: 0;
              border-color: #ffffff transparent transparent transparent; }
    .integration-google-profile-detail-account .integration-google-profile-detail-account-box-1 .messenger-detail-topic {
      justify-content: space-between;
      align-items: center;
      margin: 24px 0 0; }
      .integration-google-profile-detail-account .integration-google-profile-detail-account-box-1 .messenger-detail-topic button {
        position: absolute;
        right: 32px;
        top: 26px; }
      .integration-google-profile-detail-account .integration-google-profile-detail-account-box-1 .messenger-detail-topic .topic-val {
        margin: 6px 0 0;
        max-width: calc(100% - 100px); }
      .integration-google-profile-detail-account .integration-google-profile-detail-account-box-1 .messenger-detail-topic .topic-wrapper {
        display: flex;
        flex-direction: column; }
        .integration-google-profile-detail-account .integration-google-profile-detail-account-box-1 .messenger-detail-topic .topic-wrapper .topic-title {
          font-size: 16px; }
        .integration-google-profile-detail-account .integration-google-profile-detail-account-box-1 .messenger-detail-topic .topic-wrapper b:nth-child(2) {
          font-size: 20px; }
      .integration-google-profile-detail-account .integration-google-profile-detail-account-box-1 .messenger-detail-topic button {
        padding: 7px 24px;
        font-size: 16px;
        letter-spacing: -0.6px;
        height: 40px;
        margin-left: 8px; }
        .integration-google-profile-detail-account .integration-google-profile-detail-account-box-1 .messenger-detail-topic button svg {
          position: relative;
          top: -2px;
          margin-right: 4px; }
    .integration-google-profile-detail-account .integration-google-profile-detail-account-box-1 .messenger-bot-token-status {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 24px 0 0; }
      .integration-google-profile-detail-account .integration-google-profile-detail-account-box-1 .messenger-bot-token-status .bot-status-wrapper .bot-status-title {
        font-size: 14px; }
      .integration-google-profile-detail-account .integration-google-profile-detail-account-box-1 .messenger-bot-token-status .bot-status-wrapper .status-wrapper {
        align-items: center; }
        .integration-google-profile-detail-account .integration-google-profile-detail-account-box-1 .messenger-bot-token-status .bot-status-wrapper .status-wrapper .facebook-button {
          width: unset;
          margin-top: 24px; }
        .integration-google-profile-detail-account .integration-google-profile-detail-account-box-1 .messenger-bot-token-status .bot-status-wrapper .status-wrapper img {
          margin-right: 5px;
          position: relative;
          top: -1px; }
        .integration-google-profile-detail-account .integration-google-profile-detail-account-box-1 .messenger-bot-token-status .bot-status-wrapper .status-wrapper .status-text {
          font-size: 16px; }
        .integration-google-profile-detail-account .integration-google-profile-detail-account-box-1 .messenger-bot-token-status .bot-status-wrapper .status-wrapper .text-green {
          color: #19c700; }
        .integration-google-profile-detail-account .integration-google-profile-detail-account-box-1 .messenger-bot-token-status .bot-status-wrapper .status-wrapper .text-red {
          color: #ff3f57; }
      .integration-google-profile-detail-account .integration-google-profile-detail-account-box-1 .messenger-bot-token-status .button-wrapper button {
        padding: 7px 24px;
        font-size: 16px;
        letter-spacing: -0.6px;
        height: 40px;
        margin-left: 8px;
        font-family: PTRootBold; }
        .integration-google-profile-detail-account .integration-google-profile-detail-account-box-1 .messenger-bot-token-status .button-wrapper button svg {
          position: relative;
          top: -2px;
          margin-right: 4px; }
    .integration-google-profile-detail-account .integration-google-profile-detail-account-box-1.box-channel-button {
      margin-top: 24px; }
      .integration-google-profile-detail-account .integration-google-profile-detail-account-box-1.box-channel-button .messenger-detail-topic {
        display: block; }
      .integration-google-profile-detail-account .integration-google-profile-detail-account-box-1.box-channel-button .topic-wrapper {
        margin-top: 24px;
        width: 100%;
        display: block; }
      .integration-google-profile-detail-account .integration-google-profile-detail-account-box-1.box-channel-button .status-wrapper .status-value {
        color: rgba(25, 25, 25, 0.4); }
        .integration-google-profile-detail-account .integration-google-profile-detail-account-box-1.box-channel-button .status-wrapper .status-value.status-enabled {
          color: #19C700; }
      .integration-google-profile-detail-account .integration-google-profile-detail-account-box-1.box-channel-button .status-wrapper img {
        position: relative;
        top: -1px;
        margin-right: 4px; }
  .integration-google-profile-detail-account .integration-facebook-detail-account-box-1 {
    background: #ffffff;
    border-radius: 8px;
    padding: 22px 32px 32px;
    border: solid 1px rgba(25, 25, 25, 0.1); }
    .integration-google-profile-detail-account .integration-facebook-detail-account-box-1.box-channel-button {
      margin-top: 24px; }
      .integration-google-profile-detail-account .integration-facebook-detail-account-box-1.box-channel-button .facebook-detail-topic {
        display: block; }
        .integration-google-profile-detail-account .integration-facebook-detail-account-box-1.box-channel-button .facebook-detail-topic .channel-button-wrapper {
          margin-top: 24px; }
      .integration-google-profile-detail-account .integration-facebook-detail-account-box-1.box-channel-button .topic-wrapper {
        margin-top: 24px;
        width: 100%;
        display: block; }
      .integration-google-profile-detail-account .integration-facebook-detail-account-box-1.box-channel-button .status-wrapper .status-value {
        color: rgba(25, 25, 25, 0.4); }
        .integration-google-profile-detail-account .integration-facebook-detail-account-box-1.box-channel-button .status-wrapper .status-value.status-enabled {
          color: #19C700; }
      .integration-google-profile-detail-account .integration-facebook-detail-account-box-1.box-channel-button .status-wrapper img {
        position: relative;
        top: -1px;
        margin-right: 4px; }
    .integration-google-profile-detail-account .integration-facebook-detail-account-box-1 .integration-facebook-detail-account-box-1-top {
      padding: 10px 0 15px;
      border-bottom: solid 1px #dcdcdc;
      position: relative;
      font-size: 20px;
      letter-spacing: -0.6px;
      padding-right: 230px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis; }
      .integration-google-profile-detail-account .integration-facebook-detail-account-box-1 .integration-facebook-detail-account-box-1-top .bot-name {
        font-size: 12px;
        margin-bottom: 0; }
      .integration-google-profile-detail-account .integration-facebook-detail-account-box-1 .integration-facebook-detail-account-box-1-top .integration-facebook-detail-account-box-1-top-button {
        position: absolute;
        top: 0px;
        right: 0;
        display: inline-block; }
        .integration-google-profile-detail-account .integration-facebook-detail-account-box-1 .integration-facebook-detail-account-box-1-top .integration-facebook-detail-account-box-1-top-button button {
          padding: 7px 24px;
          font-size: 16px;
          letter-spacing: -0.6px;
          height: 40px;
          margin-left: 8px; }
          .integration-google-profile-detail-account .integration-facebook-detail-account-box-1 .integration-facebook-detail-account-box-1-top .integration-facebook-detail-account-box-1-top-button button.button-channel-detail {
            position: relative;
            top: 15px; }
          .integration-google-profile-detail-account .integration-facebook-detail-account-box-1 .integration-facebook-detail-account-box-1-top .integration-facebook-detail-account-box-1-top-button button img {
            position: relative;
            top: -2px;
            margin-right: 4px; }
          .integration-google-profile-detail-account .integration-facebook-detail-account-box-1 .integration-facebook-detail-account-box-1-top .integration-facebook-detail-account-box-1-top-button button svg {
            position: relative;
            top: -2px;
            margin-right: 4px; }
          .integration-google-profile-detail-account .integration-facebook-detail-account-box-1 .integration-facebook-detail-account-box-1-top .integration-facebook-detail-account-box-1-top-button button .lds-ring {
            top: unset;
            right: unset;
            position: relative;
            top: 3px; }
            .integration-google-profile-detail-account .integration-facebook-detail-account-box-1 .integration-facebook-detail-account-box-1-top .integration-facebook-detail-account-box-1-top-button button .lds-ring div {
              margin: 0;
              border-color: #ffffff transparent transparent transparent; }
    .integration-google-profile-detail-account .integration-facebook-detail-account-box-1 .facebook-detail-topic {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 24px 0 0; }
      .integration-google-profile-detail-account .integration-facebook-detail-account-box-1 .facebook-detail-topic .channel-button-wrapper {
        margin-top: 24px;
        width: 300px;
        word-break: break-word; }
      .integration-google-profile-detail-account .integration-facebook-detail-account-box-1 .facebook-detail-topic .topic-wrapper {
        display: flex;
        flex-direction: column; }
        .integration-google-profile-detail-account .integration-facebook-detail-account-box-1 .facebook-detail-topic .topic-wrapper .topic-title {
          font-size: 14px; }
      .integration-google-profile-detail-account .integration-facebook-detail-account-box-1 .facebook-detail-topic button {
        padding: 7px 24px;
        font-size: 16px;
        letter-spacing: -0.6px;
        height: 40px;
        margin-left: 8px; }
        .integration-google-profile-detail-account .integration-facebook-detail-account-box-1 .facebook-detail-topic button svg {
          position: relative;
          top: -2px;
          margin-right: 4px; }
    .integration-google-profile-detail-account .integration-facebook-detail-account-box-1 .facebook-bot-token-status {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 24px 0 0; }
      .integration-google-profile-detail-account .integration-facebook-detail-account-box-1 .facebook-bot-token-status .bot-status-wrapper .bot-status-title {
        font-size: 14px; }
      .integration-google-profile-detail-account .integration-facebook-detail-account-box-1 .facebook-bot-token-status .bot-status-wrapper .status-wrapper {
        display: flex;
        align-items: center; }
        .integration-google-profile-detail-account .integration-facebook-detail-account-box-1 .facebook-bot-token-status .bot-status-wrapper .status-wrapper img {
          margin-right: 5px;
          position: relative;
          top: -2px; }
        .integration-google-profile-detail-account .integration-facebook-detail-account-box-1 .facebook-bot-token-status .bot-status-wrapper .status-wrapper .status-text {
          font-size: 16px; }
        .integration-google-profile-detail-account .integration-facebook-detail-account-box-1 .facebook-bot-token-status .bot-status-wrapper .status-wrapper .text-green {
          color: #19c700; }
        .integration-google-profile-detail-account .integration-facebook-detail-account-box-1 .facebook-bot-token-status .bot-status-wrapper .status-wrapper .text-red {
          color: #ff3f57; }
      .integration-google-profile-detail-account .integration-facebook-detail-account-box-1 .facebook-bot-token-status .button-wrapper button {
        padding: 7px 24px;
        font-size: 16px;
        letter-spacing: -0.6px;
        height: 40px;
        margin-left: 8px;
        font-family: PTRootBold; }
        .integration-google-profile-detail-account .integration-facebook-detail-account-box-1 .facebook-bot-token-status .button-wrapper button svg {
          position: relative;
          top: -2px;
          margin-right: 4px; }
    .integration-google-profile-detail-account .integration-facebook-detail-account-box-1.box-channel-button {
      margin-top: 24px; }
      .integration-google-profile-detail-account .integration-facebook-detail-account-box-1.box-channel-button .facebook-detail-topic {
        display: block; }
      .integration-google-profile-detail-account .integration-facebook-detail-account-box-1.box-channel-button .topic-wrapper {
        margin-top: 24px;
        width: 100%;
        display: block; }
      .integration-google-profile-detail-account .integration-facebook-detail-account-box-1.box-channel-button .status-wrapper .status-value {
        color: rgba(25, 25, 25, 0.4); }
        .integration-google-profile-detail-account .integration-facebook-detail-account-box-1.box-channel-button .status-wrapper .status-value.status-enabled {
          color: #19C700; }
      .integration-google-profile-detail-account .integration-facebook-detail-account-box-1.box-channel-button .status-wrapper img {
        position: relative;
        top: -1px;
        margin-right: 4px; }

.integration-fb-edit-name-account {
  padding: 32px 30px;
  text-align: left; }
  .integration-fb-edit-name-account .comp-back-button {
    margin: 0 0 16px 0;
    font-family: PTRootBold; }
  .integration-fb-edit-name-account .integration-fb-edit-name-account-box {
    background: #ffffff;
    border-radius: 8px;
    padding: 32px;
    border: solid 1px rgba(25, 25, 25, 0.1); }
    .integration-fb-edit-name-account .integration-fb-edit-name-account-box .integration-fb-edit-name-account-box-top {
      padding-bottom: 15px;
      border-bottom: solid 1px #dcdcdc;
      position: relative;
      font-size: 20px;
      letter-spacing: -0.6px; }
      .integration-fb-edit-name-account .integration-fb-edit-name-account-box .integration-fb-edit-name-account-box-top button {
        position: absolute;
        top: -6px;
        right: 0;
        font-size: 16px;
        letter-spacing: -0.6px; }
    .integration-fb-edit-name-account .integration-fb-edit-name-account-box .integration-fb-edit-name-account-box-form {
      width: 100%; }
      .integration-fb-edit-name-account .integration-fb-edit-name-account-box .integration-fb-edit-name-account-box-form label {
        padding: 24px 0 0;
        font-size: 14px;
        letter-spacing: -0.4px;
        display: block; }
      .integration-fb-edit-name-account .integration-fb-edit-name-account-box .integration-fb-edit-name-account-box-form .input-wrap {
        position: relative;
        width: 360px; }
        .integration-fb-edit-name-account .integration-fb-edit-name-account-box .integration-fb-edit-name-account-box-form .input-wrap span {
          position: absolute;
          top: -29px;
          right: 0;
          font-size: 14px;
          letter-spacing: -0.4px; }
      .integration-fb-edit-name-account .integration-fb-edit-name-account-box .integration-fb-edit-name-account-box-form input[type='text'] {
        padding: 12px 16px;
        border-radius: 6px;
        border: solid 1px #dcdcdc;
        width: 100%;
        font-size: 16px;
        letter-spacing: -0.3px;
        display: block; }
      .integration-fb-edit-name-account .integration-fb-edit-name-account-box .integration-fb-edit-name-account-box-form .form-name {
        width: 360px !important; }
        .integration-fb-edit-name-account .integration-fb-edit-name-account-box .integration-fb-edit-name-account-box-form .form-name input {
          margin-bottom: 8px; }
      .integration-fb-edit-name-account .integration-fb-edit-name-account-box .integration-fb-edit-name-account-box-form input[type='text']:disabled {
        background: rgba(25, 25, 25, 0.1);
        color: rgba(25, 25, 25, 0.2);
        border: none;
        cursor: not-allowed; }
      .integration-fb-edit-name-account .integration-fb-edit-name-account-box .integration-fb-edit-name-account-box-form .integration-info-form {
        font-size: 12px;
        color: rgba(25, 25, 25, 0.8);
        letter-spacing: -0.2px;
        position: relative;
        margin: 0;
        padding-left: 20px; }
        .integration-fb-edit-name-account .integration-fb-edit-name-account-box .integration-fb-edit-name-account-box-form .integration-info-form b {
          display: block; }
        .integration-fb-edit-name-account .integration-fb-edit-name-account-box .integration-fb-edit-name-account-box-form .integration-info-form svg {
          position: absolute;
          left: 0;
          vertical-align: top;
          font-size: 16px;
          top: 1px; }
      .integration-fb-edit-name-account .integration-fb-edit-name-account-box .integration-fb-edit-name-account-box-form .custom-select-fb-name .custom-select-option-value-wrapper {
        padding: 12px 16px !important;
        margin-bottom: 8px; }

.modal-select-account-google-profile .modal-content {
  width: 650px;
  border-radius: 8px; }
  .modal-select-account-google-profile .modal-content .modal-body {
    padding: 0; }
    .modal-select-account-google-profile .modal-content .modal-body .table-modal-select-location-wrapper {
      width: 100%;
      display: block;
      overflow: auto;
      max-height: calc(78vh - 120px); }
    .modal-select-account-google-profile .modal-content .modal-body .modal-header {
      padding: 18px 24px;
      position: relative;
      letter-spacing: -0.6px;
      color: #191919;
      font-size: 20px;
      line-height: 1;
      margin: 0;
      border-bottom: 0; }
      .modal-select-account-google-profile .modal-content .modal-body .modal-header svg {
        color: var(--main-dark-orange);
        position: absolute;
        top: 18px;
        right: 24px;
        cursor: pointer;
        font-size: 22px; }
        .modal-select-account-google-profile .modal-content .modal-body .modal-header svg:hover {
          color: var(--main-darker-orange); }
    .modal-select-account-google-profile .modal-content .modal-body table {
      width: 100%;
      border-collapse: collapse; }
      .modal-select-account-google-profile .modal-content .modal-body table thead tr {
        border-top: solid 1px rgba(25, 25, 25, 0.1);
        border-bottom: solid 1px rgba(25, 25, 25, 0.1); }
        .modal-select-account-google-profile .modal-content .modal-body table thead tr td {
          padding: 8px 24px;
          font-size: 14px;
          letter-spacing: -0.2px; }
      .modal-select-account-google-profile .modal-content .modal-body table tbody tr:nth-child(even) {
        background: rgba(25, 25, 25, 0.05); }
      .modal-select-account-google-profile .modal-content .modal-body table tbody tr td {
        padding: 16px 24px;
        font-size: 14px;
        letter-spacing: -0.2px; }
        .modal-select-account-google-profile .modal-content .modal-body table tbody tr td.more-accounts {
          text-align: center;
          color: #ff7d00; }
          .modal-select-account-google-profile .modal-content .modal-body table tbody tr td.more-accounts .lds-ring {
            top: 4px;
            right: 0;
            margin-right: 6px; }
            .modal-select-account-google-profile .modal-content .modal-body table tbody tr td.more-accounts .lds-ring div {
              margin: 0; }
        .modal-select-account-google-profile .modal-content .modal-body table tbody tr td button {
          width: 128px !important;
          padding: 0 !important; }
        .modal-select-account-google-profile .modal-content .modal-body table tbody tr td:nth-child(3) {
          width: 160px; }

.modal-select-location-google-profile .modal-content {
  width: 90vw;
  max-height: 80vh;
  border-radius: 8px; }
  .modal-select-location-google-profile .modal-content .modal-body {
    padding: 0;
    width: 100%; }
    .modal-select-location-google-profile .modal-content .modal-body .table-modal-select-location-wrapper {
      width: 100%;
      display: block;
      overflow: auto;
      max-height: calc(78vh - 120px); }
    .modal-select-location-google-profile .modal-content .modal-body .modal-header {
      padding: 18px 24px;
      position: relative;
      letter-spacing: -0.6px;
      color: #191919;
      border-bottom: 0;
      margin: 0;
      height: 121px;
      padding-top: 56px; }
      .modal-select-location-google-profile .modal-content .modal-body .modal-header .back-to-select-account {
        color: var(--main-dark-orange);
        font-size: 16px;
        letter-spacing: -0.5px;
        position: absolute;
        top: 18px;
        left: 22px;
        cursor: pointer; }
        .modal-select-location-google-profile .modal-content .modal-body .modal-header .back-to-select-account:hover {
          color: var(--main-darker-orange); }
        .modal-select-location-google-profile .modal-content .modal-body .modal-header .back-to-select-account svg {
          position: relative;
          top: -1px;
          margin-right: 4px;
          font-size: 18px; }
      .modal-select-location-google-profile .modal-content .modal-body .modal-header .modal-header-title {
        font-size: 24px;
        line-height: 1;
        margin: 0;
        letter-spacing: -0.6px;
        border-bottom: 0;
        position: relative;
        top: 12px; }
      .modal-select-location-google-profile .modal-content .modal-body .modal-header .close-modal-select-location {
        color: var(--main-dark-orange);
        position: absolute;
        top: 18px;
        right: 24px;
        cursor: pointer;
        font-size: 22px; }
        .modal-select-location-google-profile .modal-content .modal-body .modal-header .close-modal-select-location:hover {
          color: var(--main-darker-orange); }
    .modal-select-location-google-profile .modal-content .modal-body table {
      width: 100%;
      border-collapse: collapse; }
      .modal-select-location-google-profile .modal-content .modal-body table thead tr {
        border-top: solid 1px rgba(25, 25, 25, 0.1);
        border-bottom: solid 1px rgba(25, 25, 25, 0.1); }
        .modal-select-location-google-profile .modal-content .modal-body table thead tr td {
          padding: 8px 24px;
          font-size: 14px;
          letter-spacing: -0.2px; }
      .modal-select-location-google-profile .modal-content .modal-body table tbody tr:nth-child(even) {
        background: rgba(25, 25, 25, 0.05); }
      .modal-select-location-google-profile .modal-content .modal-body table tbody tr td {
        padding: 16px 24px;
        font-size: 14px;
        letter-spacing: -0.2px;
        vertical-align: top; }
        .modal-select-location-google-profile .modal-content .modal-body table tbody tr td.more-locations {
          text-align: center;
          color: #ff7d00; }
          .modal-select-location-google-profile .modal-content .modal-body table tbody tr td.more-locations .lds-ring {
            top: 4px;
            right: 0;
            margin-right: 6px; }
            .modal-select-location-google-profile .modal-content .modal-body table tbody tr td.more-locations .lds-ring div {
              margin: 0; }
        .modal-select-location-google-profile .modal-content .modal-body table tbody tr td button {
          width: 156px !important;
          padding: 0 !important; }
        .modal-select-location-google-profile .modal-content .modal-body table tbody tr td:nth-child(4) {
          width: 160px; }
        .modal-select-location-google-profile .modal-content .modal-body table tbody tr td a {
          color: #191919;
          cursor: pointer; }
          .modal-select-location-google-profile .modal-content .modal-body table tbody tr td a:hover {
            text-decoration: underline !important; }

.integration-google-profile-add-account {
  padding: 32px 30px;
  text-align: left; }
  .integration-google-profile-add-account .google-button img {
    width: 20px !important; }
  .integration-google-profile-add-account .no-fill-black-button {
    min-width: 224px; }
  .integration-google-profile-add-account .comp-back-button {
    margin: 0 0 16px 0; }
  .integration-google-profile-add-account .integration-google-profile-add-account-box {
    background: #ffffff;
    border-radius: 8px;
    padding: 32px;
    border: solid 1px rgba(25, 25, 25, 0.1); }
    .integration-google-profile-add-account .integration-google-profile-add-account-box .top-component {
      padding-top: 24px; }
      .integration-google-profile-add-account .integration-google-profile-add-account-box .top-component .top-desc {
        font-size: 14px;
        letter-spacing: -0.2px;
        color: rgba(25, 25, 25, 0.6);
        width: 600px;
        max-width: 100%; }
        .integration-google-profile-add-account .integration-google-profile-add-account-box .top-component .top-desc a {
          color: var(--main-dark-orange); }
          .integration-google-profile-add-account .integration-google-profile-add-account-box .top-component .top-desc a:hover {
            text-decoration: underline !important; }
      .integration-google-profile-add-account .integration-google-profile-add-account-box .top-component .alert-component {
        border: 1px solid #044b84;
        background: #DCEBFF;
        color: #044b84; }
        .integration-google-profile-add-account .integration-google-profile-add-account-box .top-component .alert-component a {
          color: #044b84; }
          .integration-google-profile-add-account .integration-google-profile-add-account-box .top-component .alert-component a:hover {
            text-decoration: underline !important; }
    .integration-google-profile-add-account .integration-google-profile-add-account-box .facebook-component {
      padding-top: 6px; }
      .integration-google-profile-add-account .integration-google-profile-add-account-box .facebook-component p {
        margin: 0; }
      .integration-google-profile-add-account .integration-google-profile-add-account-box .facebook-component ul {
        padding-left: 18px; }
      .integration-google-profile-add-account .integration-google-profile-add-account-box .facebook-component a {
        color: #ff7d00; }
        .integration-google-profile-add-account .integration-google-profile-add-account-box .facebook-component a:hover {
          text-decoration: underline !important; }
    .integration-google-profile-add-account .integration-google-profile-add-account-box .integration-google-profile-add-account-box-top {
      padding-bottom: 15px;
      border-bottom: solid 1px #dcdcdc;
      position: relative;
      font-size: 20px;
      letter-spacing: -0.6px; }
      .integration-google-profile-add-account .integration-google-profile-add-account-box .integration-google-profile-add-account-box-top button {
        position: absolute;
        top: -6px;
        right: 0;
        font-size: 16px;
        letter-spacing: -0.6px; }
    .integration-google-profile-add-account .integration-google-profile-add-account-box .integration-google-profile-add-account-box-form {
      max-width: 100%;
      border-top: solid 1px #dcdcdc;
      margin-top: 24px; }
      .integration-google-profile-add-account .integration-google-profile-add-account-box .integration-google-profile-add-account-box-form .selected-label {
        font-size: 16px;
        letter-spacing: -0.5px;
        margin: 12px 0 0;
        cursor: pointer;
        display: inline-block; }
        .integration-google-profile-add-account .integration-google-profile-add-account-box .integration-google-profile-add-account-box-form .selected-label svg {
          position: relative;
          margin-right: 4px;
          top: -1px;
          color: #474747 !important; }
        .integration-google-profile-add-account .integration-google-profile-add-account-box .integration-google-profile-add-account-box-form .selected-label .selected-label-edit {
          color: var(--main-dark-orange) !important;
          cursor: pointer;
          position: relative;
          top: -1px;
          margin-left: 4px;
          font-size: 14px;
          text-decoration: none !important; }
        .integration-google-profile-add-account .integration-google-profile-add-account-box .integration-google-profile-add-account-box-form .selected-label:hover {
          color: var(--main-dark-orange); }
          .integration-google-profile-add-account .integration-google-profile-add-account-box .integration-google-profile-add-account-box-form .selected-label:hover b {
            text-decoration: underline; }
      .integration-google-profile-add-account .integration-google-profile-add-account-box .integration-google-profile-add-account-box-form .selected-title {
        font-size: 16px;
        letter-spacing: -0.3px;
        padding: 0 0 0 20px;
        margin: 0;
        max-width: 620px; }
      .integration-google-profile-add-account .integration-google-profile-add-account-box .integration-google-profile-add-account-box-form .selected-address {
        font-size: 14px;
        letter-spacing: -0.2px;
        color: rgba(25, 25, 25, 0.6);
        padding: 0 0 0 20px;
        margin: 4px 0 0 0; }
      .integration-google-profile-add-account .integration-google-profile-add-account-box .integration-google-profile-add-account-box-form .select-business-profile {
        color: var(--main-dark-orange);
        cursor: pointer;
        font-size: 14px;
        margin: 0;
        letter-spacing: -0.4px; }
        .integration-google-profile-add-account .integration-google-profile-add-account-box .integration-google-profile-add-account-box-form .select-business-profile:hover {
          text-decoration: underline; }
        .integration-google-profile-add-account .integration-google-profile-add-account-box .integration-google-profile-add-account-box-form .select-business-profile svg {
          position: relative;
          top: -2px;
          margin-right: 2px; }
      .integration-google-profile-add-account .integration-google-profile-add-account-box .integration-google-profile-add-account-box-form .input-wrap {
        position: relative;
        width: 340px; }
        .integration-google-profile-add-account .integration-google-profile-add-account-box .integration-google-profile-add-account-box-form .input-wrap span {
          position: absolute;
          top: -29px;
          right: 0;
          font-size: 14px;
          letter-spacing: -0.4px; }
      .integration-google-profile-add-account .integration-google-profile-add-account-box .integration-google-profile-add-account-box-form .input-channel-name {
        width: 340px !important; }
      .integration-google-profile-add-account .integration-google-profile-add-account-box .integration-google-profile-add-account-box-form .custom-select-option-value-wrapper {
        width: 340px !important; }
      .integration-google-profile-add-account .integration-google-profile-add-account-box .integration-google-profile-add-account-box-form label {
        padding: 24px 0 0;
        font-size: 14px;
        letter-spacing: -0.4px;
        display: block; }
      .integration-google-profile-add-account .integration-google-profile-add-account-box .integration-google-profile-add-account-box-form input[type="text"] {
        padding: 12px 16px;
        border-radius: 6px;
        border: solid 1px #dcdcdc;
        width: 100%;
        font-size: 16px;
        letter-spacing: -0.3px;
        display: block;
        margin-bottom: 8px; }
      .integration-google-profile-add-account .integration-google-profile-add-account-box .integration-google-profile-add-account-box-form .integration-info-form {
        font-size: 14px;
        color: rgba(25, 25, 25, 0.8);
        letter-spacing: -0.2px; }
      .integration-google-profile-add-account .integration-google-profile-add-account-box .integration-google-profile-add-account-box-form .custom-select-google-profile-topic .custom-select-option-value-wrapper {
        padding: 12px 16px !important;
        margin-bottom: 8px; }
      .integration-google-profile-add-account .integration-google-profile-add-account-box .integration-google-profile-add-account-box-form .custom-select-google-profile-topic .dropdown button {
        margin: 0 !important; }
      .integration-google-profile-add-account .integration-google-profile-add-account-box .integration-google-profile-add-account-box-form .custom-select-google-profile-topic .dropdown-menu {
        width: 340px; }

.modal-continue-with-google .modal-content {
  width: 550px; }

.modal-continue-with-google button {
  width: calc(50% - 2px) !important;
  margin-right: 4px !important; }
  .modal-continue-with-google button:nth-last-child(1) {
    margin-right: 0 !important; }

.integration-list {
  text-align: left; }
  .integration-list .integration-list-loading-wrapper .lds-ring {
    width: 50px;
    height: 50px;
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    right: unset; }
    .integration-list .integration-list-loading-wrapper .lds-ring div {
      border: 5px solid #fff;
      border-color: #ff7d00 transparent transparent transparent;
      width: 50px;
      height: 50px;
      margin: 0; }
  .integration-list .status-canceled {
    color: #FF3F57 !important; }
  .integration-list .status-suspended {
    color: #f4c12a !important; }
  .integration-list .status-pending {
    color: #ff7e00 !important; }
  .integration-list .status-active {
    color: #19C700 !important; }
  .integration-list .status-not-connected {
    color: rgba(25, 25, 25, 0.6) !important; }
  .integration-list .status-not_connected {
    color: rgba(25, 25, 25, 0.6) !important; }
  .integration-list .integration-list-header {
    position: relative;
    padding-left: 42px;
    padding-bottom: 26px;
    border-bottom: solid 1px rgba(25, 25, 25, 0.1); }
    .integration-list .integration-list-header .integration-list-header-icon {
      position: absolute;
      top: 0;
      left: 0;
      width: 32px; }
    .integration-list .integration-list-header .integration-list-header-text-wrapper {
      text-align: left; }
      .integration-list .integration-list-header .integration-list-header-text-wrapper b {
        font-size: 20px;
        letter-spacing: -0.6px; }
      .integration-list .integration-list-header .integration-list-header-text-wrapper p {
        margin: 0;
        font-size: 14px;
        letter-spacing: -0.2px; }
      .integration-list .integration-list-header .integration-list-header-text-wrapper a {
        color: #ff7d00; }
    .integration-list .integration-list-header .integration-list-header-button {
      position: absolute;
      right: 0;
      top: 0;
      font-size: 16px;
      letter-spacing: -0.5px;
      padding: 7px 32px; }
      .integration-list .integration-list-header .integration-list-header-button img {
        margin-right: 4px;
        position: relative;
        top: -2px; }
  .integration-list .integration-list-no-data {
    width: 100%;
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
    .integration-list .integration-list-no-data b {
      font-size: 20px;
      letter-spacing: -0.6px;
      color: #191919; }
    .integration-list .integration-list-no-data p {
      color: rgba(25, 25, 25, 0.6);
      letter-spacing: -0.6px;
      font-size: 16px; }
  .integration-list .integration-list-outer-wrapper {
    position: relative;
    width: 100%;
    padding-top: 24px;
    text-align: left; }
    .integration-list .integration-list-outer-wrapper .integration-list-inner-wrapper {
      width: calc((100% / 3) - 8px);
      border-radius: 8px;
      margin: 0 12px 12px 0;
      box-shadow: 0 2px 20px 0 rgba(25, 25, 25, 0.05);
      background: #ffffff;
      display: inline-block;
      vertical-align: top;
      padding: 24px 30px 24px 24px;
      cursor: pointer;
      position: relative; }
      .integration-list .integration-list-outer-wrapper .integration-list-inner-wrapper p:nth-child(2) {
        font-size: 16px;
        letter-spacing: -0.5px;
        color: rgba(25, 25, 25, 0.6);
        margin: 0;
        white-space: nowrap;
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis; }
      .integration-list .integration-list-outer-wrapper .integration-list-inner-wrapper span {
        display: block;
        font-size: 12px;
        letter-spacing: -0.2px;
        color: #191919; }
      .integration-list .integration-list-outer-wrapper .integration-list-inner-wrapper p {
        margin: 30px 0 0;
        color: #ff7d00;
        font-size: 12px;
        letter-spacing: -0.2px; }
        .integration-list .integration-list-outer-wrapper .integration-list-inner-wrapper p.font-green {
          color: #19c700; }
        .integration-list .integration-list-outer-wrapper .integration-list-inner-wrapper p.font-grey {
          color: rgba(25, 25, 25, 0.4); }
      .integration-list .integration-list-outer-wrapper .integration-list-inner-wrapper svg {
        position: absolute;
        right: 12px;
        top: 12px;
        font-size: 22px;
        color: #191919; }
      .integration-list .integration-list-outer-wrapper .integration-list-inner-wrapper:hover {
        box-shadow: 0 2px 20px 0 rgba(25, 25, 25, 0.18); }
        .integration-list .integration-list-outer-wrapper .integration-list-inner-wrapper:hover p:nth-child(2) {
          color: #191919; }
        .integration-list .integration-list-outer-wrapper .integration-list-inner-wrapper:hover svg {
          color: #ff7d00; }
  .integration-list .link-google-account-list:nth-child(3n) .integration-list-inner-wrapper {
    margin: 0 0 12px 0; }

.integration-add-account-tokped {
  padding: 32px 30px;
  text-align: left; }
  .integration-add-account-tokped ul {
    padding: 0 0 24px 22px;
    margin: 24px 0 0 0;
    border-bottom: solid 1px rgba(25, 25, 25, 0.1); }
    .integration-add-account-tokped ul li {
      font-size: 14px;
      letter-spacing: -0.2px;
      color: rgba(25, 25, 25, 0.6); }
      .integration-add-account-tokped ul li img {
        position: relative;
        top: -1px;
        margin: 0 2px; }
  .integration-add-account-tokped .comp-back-button {
    margin: 0 0 16px 0; }
  .integration-add-account-tokped .integration-add-account-box {
    background: #ffffff;
    border-radius: 8px;
    padding: 32px;
    border: solid 1px rgba(25, 25, 25, 0.1); }
    .integration-add-account-tokped .integration-add-account-box .integration-add-account-box-top {
      padding-bottom: 15px;
      border-bottom: solid 1px #dcdcdc;
      position: relative;
      font-size: 20px;
      letter-spacing: -0.6px; }
      .integration-add-account-tokped .integration-add-account-box .integration-add-account-box-top button {
        position: absolute;
        top: -6px;
        right: 0;
        font-size: 16px;
        letter-spacing: -0.6px; }
    .integration-add-account-tokped .integration-add-account-box .integration-add-account-box-form .width-455 {
      width: 455px;
      position: relative; }
      .integration-add-account-tokped .integration-add-account-box .integration-add-account-box-form .width-455 span {
        position: absolute;
        font-size: 12px;
        letter-spacing: -0.2px;
        right: 0;
        top: 24px; }
      .integration-add-account-tokped .integration-add-account-box .integration-add-account-box-form .width-455 p {
        display: inline-block;
        margin: 0;
        position: relative; }
      .integration-add-account-tokped .integration-add-account-box .integration-add-account-box-form .width-455 input {
        width: 250px !important;
        display: inline-block !important;
        margin-left: 4px; }
        .integration-add-account-tokped .integration-add-account-box .integration-add-account-box-form .width-455 input:focus {
          border: solid 1px var(--main-dark-orange); }
    .integration-add-account-tokped .integration-add-account-box .integration-add-account-box-form .width-320 {
      width: 320px; }
    .integration-add-account-tokped .integration-add-account-box .integration-add-account-box-form label {
      padding: 24px 0 0;
      font-size: 16px;
      letter-spacing: -0.5px;
      display: block; }
    .integration-add-account-tokped .integration-add-account-box .integration-add-account-box-form input[type="text"] {
      padding: 12px 16px;
      border-radius: 6px;
      border: solid 1px #dcdcdc;
      width: 100%;
      font-size: 16px;
      letter-spacing: -0.3px;
      display: block; }
    .integration-add-account-tokped .integration-add-account-box .integration-add-account-box-form .integration-info-form {
      font-size: 14px;
      color: rgba(25, 25, 25, 0.8);
      letter-spacing: -0.2px; }
    .integration-add-account-tokped .integration-add-account-box .integration-add-account-box-form .custom-select-whatsapp-topic .custom-select-option-value-wrapper {
      padding: 12px 16px !important;
      margin-bottom: 8px; }

.modal-confirmation-activation {
  width: 520px !important; }
  .modal-confirmation-activation .modal-content {
    width: 520px !important; }
  .modal-confirmation-activation button {
    width: calc(50% - 2px) !important;
    margin-right: 4px !important; }
    .modal-confirmation-activation button:nth-last-child(1) {
      margin-right: 0 !important; }

.integration-detail-account {
  padding: 32px 30px;
  text-align: left; }
  .integration-detail-account .alert-component {
    margin: 0 0 24px 0; }
  .integration-detail-account .copy-box-wrapper {
    width: 400px;
    margin-top: 4px; }
    .integration-detail-account .copy-box-wrapper input {
      background: #f3f3f3;
      color: rgba(25, 25, 25, 0.6);
      width: 100%; }
    .integration-detail-account .copy-box-wrapper svg {
      color: var(--main-dark-orange); }
  .integration-detail-account .topic-val {
    max-width: 420px;
    color: #191919 !important;
    margin: 6px 0 24px 0 !important;
    font-size: 16px !important; }
  .integration-detail-account .comp-back-button {
    margin: 0 0 16px 0; }
  .integration-detail-account .detail-value-wrapper {
    color: #191919 !important;
    font-size: 16px !important;
    letter-spacing: -0.3px !important;
    margin: 0 0 24px 0 !important; }
  .integration-detail-account .detail-reason-wrapper {
    color: #191919 !important;
    font-size: 14px !important;
    letter-spacing: -0.2px !important;
    margin: 0 0 24px 0 !important;
    max-width: 100%;
    width: 400px; }
  .integration-detail-account .detail-status-wrapper {
    margin: 0 0 24px 0;
    font-size: 16px;
    letter-spacing: -0.5px; }
    .integration-detail-account .detail-status-wrapper img {
      position: relative;
      top: -2px;
      margin: 0 4px 0 0; }
  .integration-detail-account .account-photo-wrapper {
    background: rgba(25, 25, 25, 0.1);
    position: relative;
    border-radius: 4px;
    width: 96px;
    height: 96px;
    margin: 8px 0 24px 0;
    overflow: hidden; }
    .integration-detail-account .account-photo-wrapper svg {
      font-size: 24px;
      color: rgba(25, 25, 25, 0.6);
      position: absolute;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%); }
    .integration-detail-account .account-photo-wrapper img {
      max-width: 100%;
      max-height: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%); }
  .integration-detail-account .integration-detail-account-box-1 {
    background: #ffffff;
    border-radius: 8px;
    padding: 22px 32px 32px;
    border: solid 1px rgba(25, 25, 25, 0.1);
    position: relative; }
    .integration-detail-account .integration-detail-account-box-1.box-channel-button {
      margin-top: 24px; }
    .integration-detail-account .integration-detail-account-box-1 .whatsapp-detail-channel-button .channel-button-wrapper {
      margin-top: 24px;
      width: 300px;
      word-break: break-word; }
    .integration-detail-account .integration-detail-account-box-1 .status-wrapper .status-value {
      color: rgba(25, 25, 25, 0.4); }
      .integration-detail-account .integration-detail-account-box-1 .status-wrapper .status-value.status-enabled {
        color: #19C700; }
    .integration-detail-account .integration-detail-account-box-1 .status-wrapper img {
      position: relative;
      top: -1px;
      margin-right: 4px; }
    .integration-detail-account .integration-detail-account-box-1 .integration-detail-account-box-1-top {
      padding: 10px 0 15px;
      border-bottom: solid 1px #dcdcdc;
      position: relative;
      font-size: 20px;
      letter-spacing: -0.6px;
      padding-right: 310px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis; }
      .integration-detail-account .integration-detail-account-box-1 .integration-detail-account-box-1-top .bot-name {
        margin-bottom: 0; }
      .integration-detail-account .integration-detail-account-box-1 .integration-detail-account-box-1-top p {
        font-size: 14px;
        letter-spacing: -0.2px; }
      .integration-detail-account .integration-detail-account-box-1 .integration-detail-account-box-1-top .integration-detail-account-box-1-top-button {
        position: absolute;
        top: 0px;
        right: 0;
        display: inline-block; }
        .integration-detail-account .integration-detail-account-box-1 .integration-detail-account-box-1-top .integration-detail-account-box-1-top-button button {
          padding: 7px 24px;
          font-size: 16px;
          letter-spacing: -0.6px;
          height: 40px;
          margin-left: 8px; }
          .integration-detail-account .integration-detail-account-box-1 .integration-detail-account-box-1-top .integration-detail-account-box-1-top-button button.button-channel-detail {
            position: relative;
            top: 15px; }
          .integration-detail-account .integration-detail-account-box-1 .integration-detail-account-box-1-top .integration-detail-account-box-1-top-button button svg {
            position: relative;
            top: -2px;
            margin-right: 4px; }
          .integration-detail-account .integration-detail-account-box-1 .integration-detail-account-box-1-top .integration-detail-account-box-1-top-button button .lds-ring {
            top: unset;
            right: unset;
            position: relative;
            top: 3px; }
            .integration-detail-account .integration-detail-account-box-1 .integration-detail-account-box-1-top .integration-detail-account-box-1-top-button button .lds-ring div {
              margin: 0;
              border-color: #ffffff transparent transparent transparent; }
    .integration-detail-account .integration-detail-account-box-1 .integration-detail-account-box-1-bottom {
      padding-top: 24px;
      position: relative;
      font-size: 20px;
      letter-spacing: -0.6px; }
      .integration-detail-account .integration-detail-account-box-1 .integration-detail-account-box-1-bottom .disabled-form {
        margin: 0 0 24px 0; }
      .integration-detail-account .integration-detail-account-box-1 .integration-detail-account-box-1-bottom label {
        font-size: 16px;
        letter-spacing: -0.5px;
        margin: 0; }
      .integration-detail-account .integration-detail-account-box-1 .integration-detail-account-box-1-bottom p {
        color: #ff8d00;
        margin: 4px 0 0;
        font-size: 15px;
        letter-spacing: -0.5px; }
        .integration-detail-account .integration-detail-account-box-1 .integration-detail-account-box-1-bottom p svg {
          margin-right: 4px;
          position: relative;
          top: -2px;
          font-size: 20px; }
      .integration-detail-account .integration-detail-account-box-1 .integration-detail-account-box-1-bottom .integration-detail-account-box-1-bottom-button {
        display: block; }
        .integration-detail-account .integration-detail-account-box-1 .integration-detail-account-box-1-bottom .integration-detail-account-box-1-bottom-button button {
          padding: 7px 24px 7px 20px;
          font-size: 16px;
          letter-spacing: -0.6px;
          height: 40px;
          margin-right: 8px;
          margin-top: 16px; }
          .integration-detail-account .integration-detail-account-box-1 .integration-detail-account-box-1-bottom .integration-detail-account-box-1-bottom-button button svg {
            position: relative;
            top: -2px;
            margin-right: 4px; }
          .integration-detail-account .integration-detail-account-box-1 .integration-detail-account-box-1-bottom .integration-detail-account-box-1-bottom-button button img {
            position: relative;
            top: -2px;
            margin-right: 4px; }
          .integration-detail-account .integration-detail-account-box-1 .integration-detail-account-box-1-bottom .integration-detail-account-box-1-bottom-button button .lds-ring {
            top: unset;
            right: unset;
            position: relative;
            top: 3px; }
            .integration-detail-account .integration-detail-account-box-1 .integration-detail-account-box-1-bottom .integration-detail-account-box-1-bottom-button button .lds-ring div {
              margin: 0;
              border-color: #ffffff transparent transparent transparent; }
      .integration-detail-account .integration-detail-account-box-1 .integration-detail-account-box-1-bottom .integration-detail-account-box-1-top-authenticated {
        color: #19c700; }
        .integration-detail-account .integration-detail-account-box-1 .integration-detail-account-box-1-bottom .integration-detail-account-box-1-top-authenticated img {
          position: relative;
          top: -2px;
          margin-right: 4px; }
      .integration-detail-account .integration-detail-account-box-1 .integration-detail-account-box-1-bottom .iframe-space {
        width: 100%;
        margin-top: 24px; }
        .integration-detail-account .integration-detail-account-box-1 .integration-detail-account-box-1-bottom .iframe-space iframe {
          width: 100%;
          border: none;
          min-height: 500px; }
      .integration-detail-account .integration-detail-account-box-1 .integration-detail-account-box-1-bottom .iframe-space-maytapi {
        position: relative;
        width: 100%;
        border-radius: 8px;
        border: solid 1px #dcdcdc;
        min-height: 348px;
        overflow: hidden;
        text-align: right;
        margin-top: 24px; }
        .integration-detail-account .integration-detail-account-box-1 .integration-detail-account-box-1-bottom .iframe-space-maytapi button {
          margin: 8px 8px 8px 0; }
          .integration-detail-account .integration-detail-account-box-1 .integration-detail-account-box-1-bottom .iframe-space-maytapi button .lds-ring {
            top: 50%; }
          .integration-detail-account .integration-detail-account-box-1 .integration-detail-account-box-1-bottom .iframe-space-maytapi button svg {
            top: -1.5px; }
        .integration-detail-account .integration-detail-account-box-1 .integration-detail-account-box-1-bottom .iframe-space-maytapi .iframe-space-maytapi-content {
          width: 100%;
          min-height: 278px;
          background: rgba(25, 25, 25, 0.1);
          font-size: 16px;
          letter-spacing: -0.5px;
          text-align: center;
          position: relative; }
          .integration-detail-account .integration-detail-account-box-1 .integration-detail-account-box-1-bottom .iframe-space-maytapi .iframe-space-maytapi-content img {
            width: 100%; }
          .integration-detail-account .integration-detail-account-box-1 .integration-detail-account-box-1-bottom .iframe-space-maytapi .iframe-space-maytapi-content p {
            color: rgba(25, 25, 25, 0.4);
            padding: 94px 0 0;
            margin: 0; }
          .integration-detail-account .integration-detail-account-box-1 .integration-detail-account-box-1-bottom .iframe-space-maytapi .iframe-space-maytapi-content .loading-maytapi {
            position: absolute;
            top: 50%;
            left: 50%;
            -webkit-transform: translate(-50%, -50%);
                    transform: translate(-50%, -50%);
            width: 100%; }
            .integration-detail-account .integration-detail-account-box-1 .integration-detail-account-box-1-bottom .iframe-space-maytapi .iframe-space-maytapi-content .loading-maytapi .lds-ring {
              width: 50px;
              position: absolute;
              top: 50%;
              left: 50%;
              -webkit-transform: translate(-50%, -50%);
                      transform: translate(-50%, -50%);
              height: 50px; }
              .integration-detail-account .integration-detail-account-box-1 .integration-detail-account-box-1-bottom .iframe-space-maytapi .iframe-space-maytapi-content .loading-maytapi .lds-ring div {
                width: 50px;
                height: 50px;
                border: solid 4px;
                border-color: #ff7e00 transparent transparent transparent; }
      .integration-detail-account .integration-detail-account-box-1 .integration-detail-account-box-1-bottom .not-paid {
        font-size: 16px;
        letter-spacing: -0.5px;
        color: #ff3f57;
        margin: 0; }
        .integration-detail-account .integration-detail-account-box-1 .integration-detail-account-box-1-bottom .not-paid img {
          vertical-align: top;
          position: relative;
          margin-right: 4px;
          top: 3px; }
      .integration-detail-account .integration-detail-account-box-1 .integration-detail-account-box-1-bottom .waiting-setup {
        color: #ff7d00;
        font-size: 16px;
        letter-spacing: -0.5px;
        margin: 0; }
      .integration-detail-account .integration-detail-account-box-1 .integration-detail-account-box-1-bottom .error-message {
        font-size: 14px;
        letter-spacing: -0.2px;
        margin: 4px 0 0;
        display: block; }
  .integration-detail-account .integration-detail-account-box-2 {
    background: #ffffff;
    border-radius: 8px;
    padding: 32px;
    border: solid 1px rgba(25, 25, 25, 0.1);
    margin-top: 12px; }
    .integration-detail-account .integration-detail-account-box-2 .integration-detail-account-box-2-title {
      font-size: 20px;
      letter-spacing: -0.6px;
      padding-bottom: 20px;
      border-bottom: solid 1px #dcdcdc;
      display: block;
      margin: 0 0 24px; }
    .integration-detail-account .integration-detail-account-box-2 .integration-detail-account-box-2-payment-wrapper {
      width: 100%;
      position: relative; }
      .integration-detail-account .integration-detail-account-box-2 .integration-detail-account-box-2-payment-wrapper label {
        font-size: 14px;
        letter-spacing: -0.4px;
        margin: 0; }
      .integration-detail-account .integration-detail-account-box-2 .integration-detail-account-box-2-payment-wrapper .payment-info-wrapper {
        position: relative; }
        .integration-detail-account .integration-detail-account-box-2 .integration-detail-account-box-2-payment-wrapper .payment-info-wrapper .payment-until {
          font-size: 15px;
          letter-spacing: -0.5px;
          color: #19c700;
          margin: 0; }
          .integration-detail-account .integration-detail-account-box-2 .integration-detail-account-box-2-payment-wrapper .payment-info-wrapper .payment-until img {
            vertical-align: top;
            position: relative;
            top: 0;
            margin-right: 4px; }
        .integration-detail-account .integration-detail-account-box-2 .integration-detail-account-box-2-payment-wrapper .payment-info-wrapper .payment-expired {
          color: #ff3f57;
          font-size: 15px;
          letter-spacing: -0.5px;
          margin: 4px 0 0; }
          .integration-detail-account .integration-detail-account-box-2 .integration-detail-account-box-2-payment-wrapper .payment-info-wrapper .payment-expired svg {
            vertical-align: top;
            position: relative;
            font-size: 20px;
            margin-right: 4px; }
        .integration-detail-account .integration-detail-account-box-2 .integration-detail-account-box-2-payment-wrapper .payment-info-wrapper .payment-bar {
          width: 326px;
          height: 4px;
          background: rgba(25, 25, 25, 0.1);
          border-radius: 2px;
          margin: 8px 0;
          position: relative; }
          .integration-detail-account .integration-detail-account-box-2 .integration-detail-account-box-2-payment-wrapper .payment-info-wrapper .payment-bar div {
            position: absolute;
            left: 0;
            top: 0;
            background: #3baeff;
            height: 4px; }
        .integration-detail-account .integration-detail-account-box-2 .integration-detail-account-box-2-payment-wrapper .payment-info-wrapper .payment-left {
          font-size: 12px;
          letter-spacing: -0.2px;
          color: rgba(25, 25, 25, 0.4); }
        .integration-detail-account .integration-detail-account-box-2 .integration-detail-account-box-2-payment-wrapper .payment-info-wrapper .not-paid {
          color: rgba(25, 25, 25, 0.4);
          font-size: 16px;
          letter-spacing: -0.5px;
          margin: 0 0 22px; }
      .integration-detail-account .integration-detail-account-box-2 .integration-detail-account-box-2-payment-wrapper .alet-verifying {
        border-radius: 8px;
        color: #044b84;
        border: solid 1px #044b84;
        background: #dcebff;
        width: 100%;
        position: relative;
        padding: 12px 16px 12px 38px;
        font-size: 14px;
        letter-spacing: -0.4px;
        width: 323px; }
        .integration-detail-account .integration-detail-account-box-2 .integration-detail-account-box-2-payment-wrapper .alet-verifying svg {
          font-size: 15px;
          -webkit-transform: none;
                  transform: none;
          top: 15px; }
      .integration-detail-account .integration-detail-account-box-2 .integration-detail-account-box-2-payment-wrapper .how-to-make-payment {
        border-radius: 8px;
        color: #044b84;
        border: solid 1px #044b84;
        background: #dcebff;
        width: 100%;
        position: relative;
        padding: 32px; }
        .integration-detail-account .integration-detail-account-box-2 .integration-detail-account-box-2-payment-wrapper .how-to-make-payment .how-to-make-payment-head {
          line-height: 1;
          margin-bottom: 24px; }
          .integration-detail-account .integration-detail-account-box-2 .integration-detail-account-box-2-payment-wrapper .how-to-make-payment .how-to-make-payment-head svg {
            vertical-align: top;
            font-size: 24px;
            margin-right: 8px;
            position: relative;
            top: -2px; }
          .integration-detail-account .integration-detail-account-box-2 .integration-detail-account-box-2-payment-wrapper .how-to-make-payment .how-to-make-payment-head b {
            letter-spacing: -0.6px;
            font-size: 20px; }
        .integration-detail-account .integration-detail-account-box-2 .integration-detail-account-box-2-payment-wrapper .how-to-make-payment .add-on-price {
          font-size: 16px;
          letter-spacing: -0.5px;
          line-height: 1px;
          margin: 0 0 16px 0; }
        .integration-detail-account .integration-detail-account-box-2 .integration-detail-account-box-2-payment-wrapper .how-to-make-payment .price-tier {
          padding-right: 81px; }
          .integration-detail-account .integration-detail-account-box-2 .integration-detail-account-box-2-payment-wrapper .how-to-make-payment .price-tier .price-tier-card {
            background: #044b84;
            box-shadow: 0 2px 20px 0 rgba(4, 75, 132, 0.18);
            color: #ffffff;
            border-radius: 8px;
            margin: 10px 12px 24px 0;
            padding: 24px 16px 9px 16px;
            vertical-align: top;
            width: calc((100% / 3) - 8px);
            display: inline-block; }
            .integration-detail-account .integration-detail-account-box-2 .integration-detail-account-box-2-payment-wrapper .how-to-make-payment .price-tier .price-tier-card:nth-last-child(1) {
              margin: 10px 0 24px 0; }
            .integration-detail-account .integration-detail-account-box-2 .integration-detail-account-box-2-payment-wrapper .how-to-make-payment .price-tier .price-tier-card .price-tier-name {
              font-size: 14px;
              letter-spacing: -0.4px;
              display: inline-block;
              border-radius: 4px;
              line-height: 22px;
              height: 22px;
              padding: 0 8px;
              background: #ffffff;
              color: #044b84; }
            .integration-detail-account .integration-detail-account-box-2 .integration-detail-account-box-2-payment-wrapper .how-to-make-payment .price-tier .price-tier-card .price-tier-value {
              display: block;
              margin: 12px 0 8px;
              font-size: 20px;
              letter-spacing: -0.6px; }
            .integration-detail-account .integration-detail-account-box-2 .integration-detail-account-box-2-payment-wrapper .how-to-make-payment .price-tier .price-tier-card .price-tier-more-detail {
              color: #ffffff;
              font: 16px;
              letter-spacing: -0.5px; }
            .integration-detail-account .integration-detail-account-box-2 .integration-detail-account-box-2-payment-wrapper .how-to-make-payment .price-tier .price-tier-card svg {
              color: #ffffff;
              font-size: 24px;
              position: relative;
              top: -2px; }
        .integration-detail-account .integration-detail-account-box-2 .integration-detail-account-box-2-payment-wrapper .how-to-make-payment .payment-step-wrapper {
          width: 100%; }
          .integration-detail-account .integration-detail-account-box-2 .integration-detail-account-box-2-payment-wrapper .how-to-make-payment .payment-step-wrapper .payment-step-list {
            position: relative;
            margin: 0 0 24px;
            color: #044b84;
            padding: 0 0 0 33px; }
            .integration-detail-account .integration-detail-account-box-2 .integration-detail-account-box-2-payment-wrapper .how-to-make-payment .payment-step-wrapper .payment-step-list:nth-last-child(1) {
              margin: 0; }
            .integration-detail-account .integration-detail-account-box-2 .integration-detail-account-box-2-payment-wrapper .how-to-make-payment .payment-step-wrapper .payment-step-list a {
              color: #044b84 !important; }
            .integration-detail-account .integration-detail-account-box-2 .integration-detail-account-box-2-payment-wrapper .how-to-make-payment .payment-step-wrapper .payment-step-list p {
              margin: 0; }
            .integration-detail-account .integration-detail-account-box-2 .integration-detail-account-box-2-payment-wrapper .how-to-make-payment .payment-step-wrapper .payment-step-list .bank-account-wrapper {
              border-radius: 8px;
              font-size: 16px;
              letter-spacing: -0.5px;
              padding: 24px 24px 16px;
              color: #044b84;
              background: #ffffff;
              display: inline-block;
              margin-top: 14px; }
            .integration-detail-account .integration-detail-account-box-2 .integration-detail-account-box-2-payment-wrapper .how-to-make-payment .payment-step-wrapper .payment-step-list .payment-list-icon {
              background: #ffffff;
              font-size: 12px;
              letter-spacing: -0.4px;
              border-radius: 4px;
              padding: 2px 8px;
              color: #ff7d00; }
              .integration-detail-account .integration-detail-account-box-2 .integration-detail-account-box-2-payment-wrapper .how-to-make-payment .payment-step-wrapper .payment-step-list .payment-list-icon.authenticated {
                color: #19c700; }
              .integration-detail-account .integration-detail-account-box-2 .integration-detail-account-box-2-payment-wrapper .how-to-make-payment .payment-step-wrapper .payment-step-list .payment-list-icon svg {
                position: relative;
                top: -2px; }
              .integration-detail-account .integration-detail-account-box-2 .integration-detail-account-box-2-payment-wrapper .how-to-make-payment .payment-step-wrapper .payment-step-list .payment-list-icon img {
                width: 12px;
                position: relative;
                top: -1px; }
          .integration-detail-account .integration-detail-account-box-2 .integration-detail-account-box-2-payment-wrapper .how-to-make-payment .payment-step-wrapper .payment-step-counter {
            border-radius: 50%;
            color: #ffffff;
            background: #044b84;
            width: 23px;
            height: 23px;
            text-align: center;
            line-height: 23px;
            display: inline-block;
            font-size: 14px;
            letter-spacing: -0.4px;
            position: absolute;
            top: 0;
            left: 0; }

.modal-secure-authentication .modal-content {
  border-radius: 8px;
  width: 580px; }

.modal-secure-authentication .modal-body {
  padding: 32px 32px 24px; }
  .modal-secure-authentication .modal-body .modal-secure-authentication-top {
    padding: 0 0 5px 32px;
    position: relative; }
    .modal-secure-authentication .modal-body .modal-secure-authentication-top b {
      font-size: 20px;
      letter-spacing: -0.6px;
      line-height: 20px;
      color: #191919; }
    .modal-secure-authentication .modal-body .modal-secure-authentication-top span {
      font-size: 14px;
      letter-spacing: -0.2px;
      line-height: 21px;
      color: rgba(25, 25, 25, 0.6); }
    .modal-secure-authentication .modal-body .modal-secure-authentication-top svg {
      position: absolute;
      left: 0;
      top: -1px;
      font-size: 24px; }
  .modal-secure-authentication .modal-body button {
    margin: 0 8px 0 0;
    width: calc(50% - 4px); }
    .modal-secure-authentication .modal-body button:nth-last-child(1) {
      margin: 0; }

.integration-add-account {
  padding: 32px 30px;
  text-align: left; }
  .integration-add-account ul {
    padding: 0 0 24px 22px;
    margin: 24px 0 0 0;
    border-bottom: solid 1px rgba(25, 25, 25, 0.1); }
    .integration-add-account ul li {
      font-size: 14px;
      letter-spacing: -0.2px;
      color: rgba(25, 25, 25, 0.6); }
      .integration-add-account ul li img {
        position: relative;
        top: -1px;
        margin: 0 2px; }
  .integration-add-account .comp-back-button {
    margin: 0 0 16px 0; }
  .integration-add-account .integration-add-account-box {
    background: #ffffff;
    border-radius: 8px;
    padding: 32px;
    border: solid 1px rgba(25, 25, 25, 0.1); }
    .integration-add-account .integration-add-account-box .integration-add-account-box-top {
      padding-bottom: 15px;
      border-bottom: solid 1px #dcdcdc;
      position: relative;
      font-size: 20px;
      letter-spacing: -0.6px; }
      .integration-add-account .integration-add-account-box .integration-add-account-box-top button {
        position: absolute;
        top: -6px;
        right: 0;
        font-size: 16px;
        letter-spacing: -0.6px; }
    .integration-add-account .integration-add-account-box .integration-add-account-box-form .width-455 {
      width: 455px;
      position: relative; }
      .integration-add-account .integration-add-account-box .integration-add-account-box-form .width-455 span {
        position: absolute;
        font-size: 12px;
        letter-spacing: -0.2px;
        right: 0;
        top: 24px; }
      .integration-add-account .integration-add-account-box .integration-add-account-box-form .width-455 p {
        display: inline-block;
        margin: 0;
        position: relative; }
      .integration-add-account .integration-add-account-box .integration-add-account-box-form .width-455 input {
        width: 250px !important;
        display: inline-block !important;
        margin-left: 4px; }
        .integration-add-account .integration-add-account-box .integration-add-account-box-form .width-455 input:focus {
          border: solid 1px var(--main-dark-orange); }
    .integration-add-account .integration-add-account-box .integration-add-account-box-form .width-320 {
      width: 320px; }
    .integration-add-account .integration-add-account-box .integration-add-account-box-form label {
      padding: 24px 0 0;
      font-size: 14px;
      letter-spacing: -0.5px;
      display: block; }
    .integration-add-account .integration-add-account-box .integration-add-account-box-form input[type="text"] {
      padding: 12px 16px;
      border-radius: 6px;
      border: solid 1px #dcdcdc;
      width: 100%;
      font-size: 16px;
      letter-spacing: -0.3px;
      display: block;
      margin-bottom: 8px; }
    .integration-add-account .integration-add-account-box .integration-add-account-box-form .integration-info-form {
      font-size: 14px;
      color: rgba(25, 25, 25, 0.8);
      letter-spacing: -0.2px; }
    .integration-add-account .integration-add-account-box .integration-add-account-box-form .custom-select-whatsapp-topic .custom-select-option-value-wrapper {
      padding: 12px 16px !important;
      margin-bottom: 8px; }

.chatbot-add-chatbot {
  padding: 32px 30px;
  text-align: left; }
  .chatbot-add-chatbot .comp-back-button {
    margin: 0 0 16px 0; }
  .chatbot-add-chatbot .chatbot-add-chatbot-box {
    background: #ffffff;
    border-radius: 8px;
    padding: 32px;
    border: solid 1px rgba(25, 25, 25, 0.1); }
    .chatbot-add-chatbot .chatbot-add-chatbot-box .chatbot-add-chatbot-box-top {
      padding-bottom: 15px;
      border-bottom: solid 1px #dcdcdc;
      position: relative;
      font-size: 20px;
      letter-spacing: -0.6px; }
      .chatbot-add-chatbot .chatbot-add-chatbot-box .chatbot-add-chatbot-box-top .chat-bot-chatbot-button-wrapper {
        margin: 0; }
      .chatbot-add-chatbot .chatbot-add-chatbot-box .chatbot-add-chatbot-box-top button {
        position: absolute;
        top: -6px;
        right: 0;
        margin: 0 !important;
        font-size: 16px;
        letter-spacing: -0.6px; }
    .chatbot-add-chatbot .chatbot-add-chatbot-box .chatbot-add-chatbot-box-form {
      width: 320px; }
      .chatbot-add-chatbot .chatbot-add-chatbot-box .chatbot-add-chatbot-box-form label {
        padding: 24px 0 0;
        font-size: 14px;
        letter-spacing: -0.4px;
        display: block; }
      .chatbot-add-chatbot .chatbot-add-chatbot-box .chatbot-add-chatbot-box-form input[type="text"] {
        padding: 12px 16px;
        border-radius: 6px;
        border: solid 1px #dcdcdc;
        width: 100%;
        font-size: 16px;
        letter-spacing: -0.3px;
        display: block;
        margin-bottom: 8px; }
      .chatbot-add-chatbot .chatbot-add-chatbot-box .chatbot-add-chatbot-box-form .integration-info-form {
        font-size: 14px;
        color: rgba(25, 25, 25, 0.8);
        letter-spacing: -0.2px; }
      .chatbot-add-chatbot .chatbot-add-chatbot-box .chatbot-add-chatbot-box-form .custom-select-path .custom-select-option-value-wrapper {
        padding: 12px 16px !important;
        margin-bottom: 8px; }
    .chatbot-add-chatbot .chatbot-add-chatbot-box .chat-bot-chatbot-button-wrapper {
      margin-top: 18px; }
      .chatbot-add-chatbot .chatbot-add-chatbot-box .chat-bot-chatbot-button-wrapper button {
        margin: 0 4px 0 0; }


.integration-email-list {
  text-align: left; }
  .integration-email-list .status-text, .integration-email-list .email-button-text {
    margin: 8px 0 0 !important; }
  .integration-email-list .integration-list-loading-wrapper .lds-ring {
    width: 50px;
    height: 50px;
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    right: unset; }
    .integration-email-list .integration-list-loading-wrapper .lds-ring div {
      border: 5px solid #fff;
      border-color: #ff7d00 transparent transparent transparent;
      width: 50px;
      height: 50px;
      margin: 0; }
  .integration-email-list .integration-list-no-data {
    width: 100%;
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
    .integration-email-list .integration-list-no-data b {
      font-size: 20px;
      letter-spacing: -0.6px;
      color: #191919; }
    .integration-email-list .integration-list-no-data p {
      color: rgba(25, 25, 25, 0.6);
      letter-spacing: -0.6px;
      font-size: 16px; }
  .integration-email-list .integration-list-header {
    position: relative;
    padding-left: 42px;
    padding-bottom: 10px;
    border-bottom: solid 1px rgba(25, 25, 25, 0.1); }
    .integration-email-list .integration-list-header .integration-list-header-icon {
      position: absolute;
      top: 0;
      left: 0;
      width: 32px; }
    .integration-email-list .integration-list-header .integration-list-header-text-wrapper b {
      font-size: 20px;
      letter-spacing: -0.6px; }
    .integration-email-list .integration-list-header .integration-list-header-text-wrapper p {
      margin: 0;
      font-size: 14px;
      letter-spacing: -0.2px; }
    .integration-email-list .integration-list-header .integration-list-header-text-wrapper a {
      color: #ff7d00; }
    .integration-email-list .integration-list-header .integration-list-header-button {
      position: absolute;
      right: 0;
      top: 0;
      font-size: 16px;
      letter-spacing: -0.5px;
      padding: 7px 32px; }
      .integration-email-list .integration-list-header .integration-list-header-button img {
        margin-right: 4px;
        position: relative;
        top: -2px; }
  .integration-email-list .integration-list-outer-wrapper {
    position: relative;
    width: 100%;
    padding-top: 24px; }
    .integration-email-list .integration-list-outer-wrapper .integration-list-inner-wrapper {
      width: calc((100% / 3) - 8px);
      border-radius: 8px;
      margin: 0 12px 12px 0;
      box-shadow: 0 2px 20px 0 rgba(25, 25, 25, 0.05);
      background: #ffffff;
      display: inline-block;
      vertical-align: top;
      padding: 24px 30px 24px 24px;
      cursor: pointer;
      position: relative; }
      .integration-email-list .integration-list-outer-wrapper .integration-list-inner-wrapper p:nth-child(2) {
        font-size: 16px;
        letter-spacing: -0.5px;
        color: rgba(25, 25, 25, 0.6);
        margin: 0;
        white-space: nowrap;
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis; }
      .integration-email-list .integration-list-outer-wrapper .integration-list-inner-wrapper span {
        display: block;
        font-size: 12px;
        letter-spacing: -0.2px;
        color: rgba(25, 25, 25, 0.6); }
      .integration-email-list .integration-list-outer-wrapper .integration-list-inner-wrapper p {
        margin: 30px 0 0;
        color: #ff7d00;
        font-size: 12px;
        letter-spacing: -0.2px; }
        .integration-email-list .integration-list-outer-wrapper .integration-list-inner-wrapper p.font-green {
          color: #19c700; }
        .integration-email-list .integration-list-outer-wrapper .integration-list-inner-wrapper p.font-grey {
          color: rgba(25, 25, 25, 0.4); }
      .integration-email-list .integration-list-outer-wrapper .integration-list-inner-wrapper svg {
        position: absolute;
        right: 12px;
        top: 12px;
        font-size: 22px;
        color: rgba(25, 25, 25, 0.6); }
      .integration-email-list .integration-list-outer-wrapper .integration-list-inner-wrapper:hover {
        box-shadow: 0 2px 20px 0 rgba(25, 25, 25, 0.18); }
        .integration-email-list .integration-list-outer-wrapper .integration-list-inner-wrapper:hover p:nth-child(2) {
          color: #191919; }
        .integration-email-list .integration-list-outer-wrapper .integration-list-inner-wrapper:hover svg {
          color: #ff7d00; }
  .integration-email-list .link-instagram-account-list:nth-child(3n) .integration-list-inner-wrapper {
    margin: 0 0 12px 0; }

.integration-email-add-account {
  padding: 32px 30px;
  text-align: left; }
  .integration-email-add-account .comp-back-button {
    margin: 0 0 16px 0; }
  .integration-email-add-account .integration-email-add-account-box {
    background: #ffffff;
    border-radius: 8px;
    padding: 32px;
    border: solid 1px rgba(25, 25, 25, 0.1); }
    .integration-email-add-account .integration-email-add-account-box .integration-email-add-account-box-top {
      padding-bottom: 15px;
      border-bottom: solid 1px #dcdcdc;
      position: relative;
      font-size: 20px;
      letter-spacing: -0.6px; }
      .integration-email-add-account .integration-email-add-account-box .integration-email-add-account-box-top button {
        position: absolute;
        top: -6px;
        right: 0;
        font-size: 16px;
        letter-spacing: -0.6px;
        width: 190px; }
    .integration-email-add-account .integration-email-add-account-box .integration-email-add-account-box-form {
      width: 100%; }
      .integration-email-add-account .integration-email-add-account-box .integration-email-add-account-box-form .line-border {
        width: 100%;
        height: 1px;
        background: rgba(25, 25, 25, 0.1);
        margin: 28px 0 0; }
      .integration-email-add-account .integration-email-add-account-box .integration-email-add-account-box-form .integration-email-add-account-box-form-inner {
        width: 350px; }
      .integration-email-add-account .integration-email-add-account-box .integration-email-add-account-box-form .password-wrapper {
        position: relative; }
        .integration-email-add-account .integration-email-add-account-box .integration-email-add-account-box-form .password-wrapper svg {
          position: absolute;
          top: 50%;
          -webkit-transform: translate(0, -50%);
                  transform: translate(0, -50%);
          right: 16px;
          font-size: 20px;
          cursor: pointer; }
        .integration-email-add-account .integration-email-add-account-box .integration-email-add-account-box-form .password-wrapper input {
          padding: 12px 44px 12px 16px !important; }
      .integration-email-add-account .integration-email-add-account-box .integration-email-add-account-box-form .mail-server-config {
        font-size: 20px;
        letter-spacing: -0.6px;
        margin: 32px 0 0; }
      .integration-email-add-account .integration-email-add-account-box .integration-email-add-account-box-form label {
        padding: 24px 0 0;
        font-size: 16px;
        letter-spacing: -0.5px;
        display: block;
        position: relative; }
        .integration-email-add-account .integration-email-add-account-box .integration-email-add-account-box-form label span {
          position: absolute;
          right: 0; }
      .integration-email-add-account .integration-email-add-account-box .integration-email-add-account-box-form input[type="text"] {
        padding: 12px 16px;
        border-radius: 6px;
        border: solid 1px #dcdcdc;
        width: 100%;
        font-size: 16px;
        letter-spacing: -0.3px;
        display: block; }
        .integration-email-add-account .integration-email-add-account-box .integration-email-add-account-box-form input[type="text"]:focus {
          border: solid 1px var(--main-dark-orange); }
      .integration-email-add-account .integration-email-add-account-box .integration-email-add-account-box-form input[type="password"] {
        padding: 12px 16px;
        border-radius: 6px;
        border: solid 1px #dcdcdc;
        width: 100%;
        font-size: 16px;
        letter-spacing: -0.3px;
        display: block;
        margin-bottom: 8px; }
        .integration-email-add-account .integration-email-add-account-box .integration-email-add-account-box-form input[type="password"]:focus {
          border: solid 1px var(--main-dark-orange); }
      .integration-email-add-account .integration-email-add-account-box .integration-email-add-account-box-form .integration-info-form {
        font-size: 14px;
        color: rgba(25, 25, 25, 0.8);
        letter-spacing: -0.2px; }
      .integration-email-add-account .integration-email-add-account-box .integration-email-add-account-box-form .custom-select-whatsapp-topic .custom-select-option-value-wrapper {
        padding: 12px 16px !important;
        margin-bottom: 8px; }

.integration-email-detail-account {
  padding: 32px 30px;
  text-align: left; }
  .integration-email-detail-account .email-detail-channel-button {
    margin: 24px 0 0; }
  .integration-email-detail-account .big-title {
    border-top: solid 1px #dcdcdc;
    padding-top: 20px;
    font-size: 16px;
    letter-spacing: -0.5px;
    margin: 24px 0 0; }
    .integration-email-detail-account .big-title.no-border-top {
      border: none !important;
      padding: 0 !important; }
  .integration-email-detail-account .comp-back-button {
    margin: 0 0 16px 0;
    font-family: PTRootBold; }
  .integration-email-detail-account .red-button {
    right: 220px !important; }
  .integration-email-detail-account .red-button, .integration-email-detail-account .no-fill-button {
    width: 180px; }
  .integration-email-detail-account .form-content-wrapper label {
    font-size: 16px;
    letter-spacing: -0.5px; }
  .integration-email-detail-account .form-content-wrapper svg {
    color: #ff7d00; }
  .integration-email-detail-account .integration-email-detail-account-box-1 {
    background: #ffffff;
    border-radius: 8px;
    padding: 22px 32px 32px;
    border: solid 1px rgba(25, 25, 25, 0.1);
    position: relative; }
    .integration-email-detail-account .integration-email-detail-account-box-1.box-channel-button {
      margin-top: 24px; }
      .integration-email-detail-account .integration-email-detail-account-box-1.box-channel-button .email-detail-topic {
        display: block; }
      .integration-email-detail-account .integration-email-detail-account-box-1.box-channel-button .channel-button-wrapper {
        margin-top: 24px; }
      .integration-email-detail-account .integration-email-detail-account-box-1.box-channel-button .topic-wrapper {
        margin-top: 24px;
        width: 100%;
        display: block; }
      .integration-email-detail-account .integration-email-detail-account-box-1.box-channel-button .status-wrapper .status-value {
        color: rgba(25, 25, 25, 0.4); }
        .integration-email-detail-account .integration-email-detail-account-box-1.box-channel-button .status-wrapper .status-value.status-enabled {
          color: #19C700; }
      .integration-email-detail-account .integration-email-detail-account-box-1.box-channel-button .status-wrapper img {
        position: relative;
        top: -1px;
        margin-right: 4px; }
    .integration-email-detail-account .integration-email-detail-account-box-1 .integration-email-detail-account-box-1-top {
      padding: 0 0 12px;
      border-bottom: solid 1px #dcdcdc;
      position: relative;
      font-size: 20px;
      letter-spacing: -0.6px;
      padding-right: 382px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis; }
      .integration-email-detail-account .integration-email-detail-account-box-1 .integration-email-detail-account-box-1-top .subtitle-chatbot {
        color: rgba(25, 25, 25, 0.8);
        font-size: 14px;
        letter-spacing: -0.2px;
        margin: 0 0 0; }
        .integration-email-detail-account .integration-email-detail-account-box-1 .integration-email-detail-account-box-1-top .subtitle-chatbot a {
          color: #ff7d00; }
          .integration-email-detail-account .integration-email-detail-account-box-1 .integration-email-detail-account-box-1-top .subtitle-chatbot a:hover {
            text-decoration: underline; }
      .integration-email-detail-account .integration-email-detail-account-box-1 .integration-email-detail-account-box-1-top.integration-email-detail-account-box-2-top {
        padding-right: 190px; }
      .integration-email-detail-account .integration-email-detail-account-box-1 .integration-email-detail-account-box-1-top .bot-name {
        font-size: 14px;
        margin-bottom: 0;
        letter-spacing: -0.2px; }
      .integration-email-detail-account .integration-email-detail-account-box-1 .integration-email-detail-account-box-1-top .integration-email-detail-account-box-1-top-button {
        position: absolute;
        top: 0px;
        right: 0;
        display: inline-block; }
        .integration-email-detail-account .integration-email-detail-account-box-1 .integration-email-detail-account-box-1-top .integration-email-detail-account-box-1-top-button button {
          padding: 7px 24px;
          font-size: 16px;
          letter-spacing: -0.6px;
          height: 40px;
          margin-left: 8px; }
          .integration-email-detail-account .integration-email-detail-account-box-1 .integration-email-detail-account-box-1-top .integration-email-detail-account-box-1-top-button button img {
            position: relative;
            top: -2px;
            margin-right: 4px; }
          .integration-email-detail-account .integration-email-detail-account-box-1 .integration-email-detail-account-box-1-top .integration-email-detail-account-box-1-top-button button svg {
            position: relative;
            top: -2px;
            margin-right: 4px; }
          .integration-email-detail-account .integration-email-detail-account-box-1 .integration-email-detail-account-box-1-top .integration-email-detail-account-box-1-top-button button .lds-ring {
            top: unset;
            right: unset;
            position: relative;
            top: 3px; }
            .integration-email-detail-account .integration-email-detail-account-box-1 .integration-email-detail-account-box-1-top .integration-email-detail-account-box-1-top-button button .lds-ring div {
              margin: 0;
              border-color: #ffffff transparent transparent transparent; }
    .integration-email-detail-account .integration-email-detail-account-box-1 .email-detail-topic {
      justify-content: space-between;
      align-items: center;
      margin: 24px 0 0; }
      .integration-email-detail-account .integration-email-detail-account-box-1 .email-detail-topic button {
        position: absolute;
        right: 32px;
        top: 26px; }
      .integration-email-detail-account .integration-email-detail-account-box-1 .email-detail-topic .topic-val {
        margin-top: 6px;
        max-width: calc(100% - 200px);
        margin-bottom: 0; }
      .integration-email-detail-account .integration-email-detail-account-box-1 .email-detail-topic .email-title {
        margin-top: 24px; }
      .integration-email-detail-account .integration-email-detail-account-box-1 .email-detail-topic .email-val {
        font-size: 16px;
        letter-spacing: -0.3px;
        color: rgba(25, 25, 25, 0.8); }
      .integration-email-detail-account .integration-email-detail-account-box-1 .email-detail-topic .topic-wrapper {
        display: flex;
        flex-direction: column; }
        .integration-email-detail-account .integration-email-detail-account-box-1 .email-detail-topic .topic-wrapper .topic-title {
          font-size: 16px;
          letter-spacing: -0.5px; }
        .integration-email-detail-account .integration-email-detail-account-box-1 .email-detail-topic .topic-wrapper b:nth-child(2) {
          font-size: 20px; }
      .integration-email-detail-account .integration-email-detail-account-box-1 .email-detail-topic button {
        padding: 7px 24px;
        font-size: 16px;
        letter-spacing: -0.6px;
        height: 40px;
        margin-left: 8px; }
        .integration-email-detail-account .integration-email-detail-account-box-1 .email-detail-topic button svg {
          position: relative;
          top: -2px;
          margin-right: 4px; }
    .integration-email-detail-account .integration-email-detail-account-box-1 .email-bot-token-status {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 16px 0 0; }
      .integration-email-detail-account .integration-email-detail-account-box-1 .email-bot-token-status .bot-status-wrapper .bot-status-title {
        font-size: 16px;
        letter-spacing: -0.5px; }
      .integration-email-detail-account .integration-email-detail-account-box-1 .email-bot-token-status .bot-status-wrapper .status-wrapper {
        align-items: center; }
        .integration-email-detail-account .integration-email-detail-account-box-1 .email-bot-token-status .bot-status-wrapper .status-wrapper .facebook-button {
          width: unset;
          margin-top: 24px; }
        .integration-email-detail-account .integration-email-detail-account-box-1 .email-bot-token-status .bot-status-wrapper .status-wrapper img {
          margin-right: 5px;
          position: relative;
          top: -1px; }
        .integration-email-detail-account .integration-email-detail-account-box-1 .email-bot-token-status .bot-status-wrapper .status-wrapper .status-text {
          font-size: 16px; }
        .integration-email-detail-account .integration-email-detail-account-box-1 .email-bot-token-status .bot-status-wrapper .status-wrapper .text-green {
          color: #19c700; }
        .integration-email-detail-account .integration-email-detail-account-box-1 .email-bot-token-status .bot-status-wrapper .status-wrapper .text-red {
          color: #ff3f57; }
      .integration-email-detail-account .integration-email-detail-account-box-1 .email-bot-token-status .button-wrapper button {
        padding: 7px 24px;
        font-size: 16px;
        letter-spacing: -0.6px;
        height: 40px;
        margin-left: 8px;
        font-family: PTRootBold; }
        .integration-email-detail-account .integration-email-detail-account-box-1 .email-bot-token-status .button-wrapper button svg {
          position: relative;
          top: -2px;
          margin-right: 4px; }
    .integration-email-detail-account .integration-email-detail-account-box-1.box-channel-button {
      margin-top: 24px; }
      .integration-email-detail-account .integration-email-detail-account-box-1.box-channel-button .email-detail-topic {
        display: block; }
      .integration-email-detail-account .integration-email-detail-account-box-1.box-channel-button .topic-wrapper {
        margin-top: 24px;
        width: 100%;
        display: block; }
      .integration-email-detail-account .integration-email-detail-account-box-1.box-channel-button .status-wrapper .status-value {
        color: rgba(25, 25, 25, 0.4); }
        .integration-email-detail-account .integration-email-detail-account-box-1.box-channel-button .status-wrapper .status-value.status-enabled {
          color: #19C700; }
      .integration-email-detail-account .integration-email-detail-account-box-1.box-channel-button .status-wrapper img {
        position: relative;
        top: -1px;
        margin-right: 4px; }

.integration-email-edit-topic-account {
  padding: 32px 30px;
  text-align: left; }
  .integration-email-edit-topic-account .comp-back-button {
    margin: 0 0 16px 0;
    font-family: PTRootBold; }
  .integration-email-edit-topic-account .integration-email-edit-topic-account-box {
    background: #ffffff;
    border-radius: 8px;
    padding: 32px;
    border: solid 1px rgba(25, 25, 25, 0.1); }
    .integration-email-edit-topic-account .integration-email-edit-topic-account-box .form-content-wrapper {
      width: 343px; }
      .integration-email-edit-topic-account .integration-email-edit-topic-account-box .form-content-wrapper label {
        padding: 0 !important;
        font-size: 14px !important;
        letter-spacing: -0.4px !important; }
      .integration-email-edit-topic-account .integration-email-edit-topic-account-box .form-content-wrapper .dropdown button {
        margin: 0 !important; }
    .integration-email-edit-topic-account .integration-email-edit-topic-account-box .integration-email-edit-topic-account-box-top {
      padding-bottom: 15px;
      border-bottom: solid 1px #dcdcdc;
      position: relative;
      font-size: 20px;
      letter-spacing: -0.6px; }
      .integration-email-edit-topic-account .integration-email-edit-topic-account-box .integration-email-edit-topic-account-box-top button {
        position: absolute;
        top: -6px;
        right: 0;
        font-size: 16px;
        letter-spacing: -0.6px; }
    .integration-email-edit-topic-account .integration-email-edit-topic-account-box .integration-email-edit-topic-account-box-form {
      width: 100%; }
      .integration-email-edit-topic-account .integration-email-edit-topic-account-box .integration-email-edit-topic-account-box-form label {
        padding: 24px 0 0;
        font-size: 14px;
        letter-spacing: -0.4px;
        display: block;
        position: relative; }
        .integration-email-edit-topic-account .integration-email-edit-topic-account-box .integration-email-edit-topic-account-box-form label span {
          position: absolute;
          right: 0; }
      .integration-email-edit-topic-account .integration-email-edit-topic-account-box .integration-email-edit-topic-account-box-form input[type='text'] {
        padding: 12px 16px;
        border-radius: 6px;
        border: solid 1px #dcdcdc;
        width: 100%;
        font-size: 16px;
        letter-spacing: -0.3px;
        display: block; }
      .integration-email-edit-topic-account .integration-email-edit-topic-account-box .integration-email-edit-topic-account-box-form .form-topic {
        width: 575px; }
      .integration-email-edit-topic-account .integration-email-edit-topic-account-box .integration-email-edit-topic-account-box-form input[type='text']:disabled {
        background: rgba(25, 25, 25, 0.1);
        color: rgba(25, 25, 25, 0.2);
        border: none;
        cursor: not-allowed; }
      .integration-email-edit-topic-account .integration-email-edit-topic-account-box .integration-email-edit-topic-account-box-form .integration-info-form {
        font-size: 14px;
        color: rgba(25, 25, 25, 0.8);
        letter-spacing: -0.2px; }
      .integration-email-edit-topic-account .integration-email-edit-topic-account-box .integration-email-edit-topic-account-box-form .custom-select-waba-topic .custom-select-option-value-wrapper {
        padding: 12px 16px !important;
        margin-bottom: 8px; }

.integration-email-edit-account {
  padding: 32px 30px;
  text-align: left; }
  .integration-email-edit-account .input-protocol-out {
    width: 400px; }
  .integration-email-edit-account .comp-back-button {
    margin: 0 0 16px 0; }
  .integration-email-edit-account .integration-email-edit-account-box {
    background: #ffffff;
    border-radius: 8px;
    padding: 32px;
    border: solid 1px rgba(25, 25, 25, 0.1); }
    .integration-email-edit-account .integration-email-edit-account-box .integration-email-edit-account-box-top {
      padding-bottom: 15px;
      border-bottom: solid 1px #dcdcdc;
      position: relative;
      font-size: 20px;
      letter-spacing: -0.6px; }
      .integration-email-edit-account .integration-email-edit-account-box .integration-email-edit-account-box-top button {
        width: 190px;
        position: absolute;
        top: -6px;
        right: 0;
        font-size: 16px;
        letter-spacing: -0.6px; }
    .integration-email-edit-account .integration-email-edit-account-box .integration-email-edit-account-box-form {
      width: 100%; }
      .integration-email-edit-account .integration-email-edit-account-box .integration-email-edit-account-box-form input[type="text"] {
        width: 400px !important; }
      .integration-email-edit-account .integration-email-edit-account-box .integration-email-edit-account-box-form input[type="password"] {
        width: 400px !important; }
      .integration-email-edit-account .integration-email-edit-account-box .integration-email-edit-account-box-form .grey-text {
        right: unset;
        position: unset; }
      .integration-email-edit-account .integration-email-edit-account-box .integration-email-edit-account-box-form .line-border {
        width: 100%;
        height: 1px;
        background: rgba(25, 25, 25, 0.1);
        margin: 28px 0 0; }
      .integration-email-edit-account .integration-email-edit-account-box .integration-email-edit-account-box-form .integration-email-edit-account-box-form-inner {
        width: 100%; }
      .integration-email-edit-account .integration-email-edit-account-box .integration-email-edit-account-box-form .password-wrapper {
        position: relative;
        width: 400px; }
        .integration-email-edit-account .integration-email-edit-account-box .integration-email-edit-account-box-form .password-wrapper svg {
          position: absolute;
          top: 50%;
          -webkit-transform: translate(0, -50%);
                  transform: translate(0, -50%);
          right: 16px;
          font-size: 20px;
          cursor: pointer; }
        .integration-email-edit-account .integration-email-edit-account-box .integration-email-edit-account-box-form .password-wrapper input {
          padding: 12px 44px 12px 16px !important; }
      .integration-email-edit-account .integration-email-edit-account-box .integration-email-edit-account-box-form .mail-server-config {
        font-size: 20px;
        letter-spacing: -0.6px;
        margin: 32px 0 0; }
      .integration-email-edit-account .integration-email-edit-account-box .integration-email-edit-account-box-form label {
        padding: 24px 0 0;
        font-size: 14px;
        letter-spacing: -0.4px;
        display: block;
        position: relative; }
        .integration-email-edit-account .integration-email-edit-account-box .integration-email-edit-account-box-form label span {
          position: absolute;
          right: 0; }
      .integration-email-edit-account .integration-email-edit-account-box .integration-email-edit-account-box-form input[type="text"] {
        padding: 12px 16px;
        border-radius: 6px;
        border: solid 1px #dcdcdc;
        width: 100%;
        font-size: 16px;
        letter-spacing: -0.3px;
        display: block;
        margin-bottom: 8px; }
        .integration-email-edit-account .integration-email-edit-account-box .integration-email-edit-account-box-form input[type="text"]:focus {
          border: solid 1px var(--main-dark-orange); }
      .integration-email-edit-account .integration-email-edit-account-box .integration-email-edit-account-box-form input[type="password"] {
        padding: 12px 16px;
        border-radius: 6px;
        border: solid 1px #dcdcdc;
        width: 100%;
        font-size: 16px;
        letter-spacing: -0.3px;
        display: block;
        margin-bottom: 8px; }
        .integration-email-edit-account .integration-email-edit-account-box .integration-email-edit-account-box-form input[type="password"]:focus {
          border: solid 1px var(--main-dark-orange); }
      .integration-email-edit-account .integration-email-edit-account-box .integration-email-edit-account-box-form .integration-info-form {
        font-size: 14px;
        color: rgba(25, 25, 25, 0.8);
        letter-spacing: -0.2px; }
      .integration-email-edit-account .integration-email-edit-account-box .integration-email-edit-account-box-form .custom-select-whatsapp-topic .custom-select-option-value-wrapper {
        padding: 12px 16px !important;
        margin-bottom: 8px; }


.integration-email-edit-channel-button {
  padding: 32px 30px;
  text-align: left; }
  .integration-email-edit-channel-button .form-content-wrapper {
    width: 100%; }
  .integration-email-edit-channel-button .comp-back-button {
    margin: 0 0 16px 0;
    font-family: PTRootBold; }
  .integration-email-edit-channel-button .integration-email-edit-channel-button-box {
    background: #ffffff;
    border-radius: 8px;
    padding: 32px;
    border: solid 1px rgba(25, 25, 25, 0.1); }
    .integration-email-edit-channel-button .integration-email-edit-channel-button-box .custom-checkbox label {
      width: unset !important;
      padding: 0 0 0 24px !important; }
    .integration-email-edit-channel-button .integration-email-edit-channel-button-box .integration-email-edit-channel-button-box-top {
      padding-bottom: 15px;
      border-bottom: solid 1px #dcdcdc;
      position: relative;
      font-size: 20px;
      letter-spacing: -0.6px; }
      .integration-email-edit-channel-button .integration-email-edit-channel-button-box .integration-email-edit-channel-button-box-top button {
        width: 190px;
        position: absolute;
        top: -6px;
        right: 0;
        font-size: 16px;
        letter-spacing: -0.6px; }
    .integration-email-edit-channel-button .integration-email-edit-channel-button-box .integration-email-edit-channel-button-box-form {
      width: 100%; }
      .integration-email-edit-channel-button .integration-email-edit-channel-button-box .integration-email-edit-channel-button-box-form label {
        padding: 0;
        margin: 24px 0 8px 0;
        font-size: 16px;
        letter-spacing: -0.5px;
        display: block;
        position: relative;
        width: 343px; }
        .integration-email-edit-channel-button .integration-email-edit-channel-button-box .integration-email-edit-channel-button-box-form label span {
          font-size: 14px;
          letter-spacing: -0.2px;
          position: absolute;
          bottom: 0;
          right: 0; }
      .integration-email-edit-channel-button .integration-email-edit-channel-button-box .integration-email-edit-channel-button-box-form .phone-input {
        position: relative; }
        .integration-email-edit-channel-button .integration-email-edit-channel-button-box .integration-email-edit-channel-button-box-form .phone-input span {
          position: absolute;
          top: 12px;
          left: 16px; }
        .integration-email-edit-channel-button .integration-email-edit-channel-button-box .integration-email-edit-channel-button-box-form .phone-input input {
          padding: 12px 16px 12px 26px !important; }
        .integration-email-edit-channel-button .integration-email-edit-channel-button-box .integration-email-edit-channel-button-box-form .phone-input .alert-wrapper {
          display: flex;
          margin-top: 4px;
          width: 343px; }
          .integration-email-edit-channel-button .integration-email-edit-channel-button-box .integration-email-edit-channel-button-box-form .phone-input .alert-wrapper svg {
            margin-right: 4px;
            font-size: 20px;
            position: relative;
            top: -2px; }
          .integration-email-edit-channel-button .integration-email-edit-channel-button-box .integration-email-edit-channel-button-box-form .phone-input .alert-wrapper p {
            font-size: 12px;
            margin: 0; }
      .integration-email-edit-channel-button .integration-email-edit-channel-button-box .integration-email-edit-channel-button-box-form textarea {
        width: 343px; }
      .integration-email-edit-channel-button .integration-email-edit-channel-button-box .integration-email-edit-channel-button-box-form input[type='text'] {
        padding: 12px 16px;
        border-radius: 6px;
        border: solid 1px #dcdcdc;
        width: 100%;
        font-size: 16px;
        letter-spacing: -0.3px;
        display: block; }
      .integration-email-edit-channel-button .integration-email-edit-channel-button-box .integration-email-edit-channel-button-box-form .form-channel-button {
        width: 100%;
        margin: 24px 0 0; }
        .integration-email-edit-channel-button .integration-email-edit-channel-button-box .integration-email-edit-channel-button-box-form .form-channel-button .copy-box-wrapper {
          width: 343px; }
        .integration-email-edit-channel-button .integration-email-edit-channel-button-box .integration-email-edit-channel-button-box-form .form-channel-button input[type="text"] {
          width: 343px; }
        .integration-email-edit-channel-button .integration-email-edit-channel-button-box .integration-email-edit-channel-button-box-form .form-channel-button .switch-channel-button {
          position: relative; }
          .integration-email-edit-channel-button .integration-email-edit-channel-button-box .integration-email-edit-channel-button-box-form .form-channel-button .switch-channel-button label {
            margin: 0; }
          .integration-email-edit-channel-button .integration-email-edit-channel-button-box .integration-email-edit-channel-button-box-form .form-channel-button .switch-channel-button .custom-switch-wrapper {
            position: absolute;
            width: 48px;
            height: 29px;
            right: 0;
            bottom: 20px; }
            .integration-email-edit-channel-button .integration-email-edit-channel-button-box .integration-email-edit-channel-button-box-form .form-channel-button .switch-channel-button .custom-switch-wrapper .custom-switch.round:before {
              width: 16px;
              height: 16px;
              bottom: 8px;
              left: 7px; }
            .integration-email-edit-channel-button .integration-email-edit-channel-button-box .integration-email-edit-channel-button-box-form .form-channel-button .switch-channel-button .custom-switch-wrapper input:checked + .custom-switch:before {
              -webkit-transform: translateX(19px);
                      transform: translateX(19px); }
            .integration-email-edit-channel-button .integration-email-edit-channel-button-box .integration-email-edit-channel-button-box-form .form-channel-button .switch-channel-button .custom-switch-wrapper b {
              font-size: 16px;
              letter-spacing: -0.5px; }
      .integration-email-edit-channel-button .integration-email-edit-channel-button-box .integration-email-edit-channel-button-box-form input[type='text']:disabled {
        background: rgba(25, 25, 25, 0.1);
        color: rgba(25, 25, 25, 0.2);
        border: none;
        cursor: not-allowed; }
      .integration-email-edit-channel-button .integration-email-edit-channel-button-box .integration-email-edit-channel-button-box-form .integration-info-form {
        font-size: 14px;
        color: rgba(25, 25, 25, 0.8);
        letter-spacing: -0.2px; }
      .integration-email-edit-channel-button .integration-email-edit-channel-button-box .integration-email-edit-channel-button-box-form .custom-select-whatsapp-topic .custom-select-option-value-wrapper {
        padding: 12px 16px !important;
        margin-bottom: 8px; }

.integration-kataai-list {
  text-align: left; }
  .integration-kataai-list .integration-list-loading-wrapper .lds-ring {
    width: 50px;
    height: 50px;
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    right: unset; }
    .integration-kataai-list .integration-list-loading-wrapper .lds-ring div {
      border: 5px solid #fff;
      border-color: #ff7d00 transparent transparent transparent;
      width: 50px;
      height: 50px;
      margin: 0; }
  .integration-kataai-list .integration-list-header {
    position: relative;
    padding-left: 42px;
    padding-bottom: 20px;
    border-bottom: solid 1px rgba(25, 25, 25, 0.1); }
    .integration-kataai-list .integration-list-header .integration-list-header-icon {
      position: absolute;
      top: 0;
      left: 0;
      width: 32px; }
    .integration-kataai-list .integration-list-header .integration-list-header-text-wrapper {
      text-align: left; }
      .integration-kataai-list .integration-list-header .integration-list-header-text-wrapper b {
        font-size: 20px;
        letter-spacing: -0.6px; }
      .integration-kataai-list .integration-list-header .integration-list-header-text-wrapper p {
        margin: 0;
        font-size: 14px;
        letter-spacing: -0.2px; }
      .integration-kataai-list .integration-list-header .integration-list-header-text-wrapper a {
        color: #ff7d00; }
    .integration-kataai-list .integration-list-header .integration-list-header-button {
      position: absolute;
      right: 0;
      top: 0;
      font-size: 16px;
      letter-spacing: -0.5px; }
  .integration-kataai-list .integration-list-no-data {
    width: 100%;
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
    .integration-kataai-list .integration-list-no-data b {
      font-size: 20px;
      letter-spacing: -0.6px;
      color: #191919; }
    .integration-kataai-list .integration-list-no-data p {
      color: rgba(25, 25, 25, 0.6);
      letter-spacing: -0.6px;
      font-size: 16px; }
  .integration-kataai-list .integration-list-outer-wrapper {
    position: relative;
    width: 100%;
    padding-top: 24px;
    text-align: left; }
    .integration-kataai-list .integration-list-outer-wrapper .integration-list-inner-wrapper {
      width: calc((100% / 3) - 8px);
      border-radius: 8px;
      margin: 0 12px 12px 0;
      box-shadow: 0 2px 20px 0 rgba(25, 25, 25, 0.05);
      background: #ffffff;
      display: inline-block;
      vertical-align: top;
      padding: 24px 30px 24px 24px;
      cursor: pointer;
      position: relative; }
      .integration-kataai-list .integration-list-outer-wrapper .integration-list-inner-wrapper .platform-wrapper {
        display: flex;
        align-items: center; }
        .integration-kataai-list .integration-list-outer-wrapper .integration-list-inner-wrapper .platform-wrapper .platform-name {
          font-size: 16px;
          letter-spacing: -0.5px;
          color: rgba(25, 25, 25, 0.6);
          margin: 0;
          white-space: nowrap;
          max-width: 100%;
          overflow: hidden;
          text-overflow: ellipsis; }
        .integration-kataai-list .integration-list-outer-wrapper .integration-list-inner-wrapper .platform-wrapper img {
          margin-right: 5px; }
      .integration-kataai-list .integration-list-outer-wrapper .integration-list-inner-wrapper span {
        display: block;
        font-size: 12px;
        letter-spacing: -0.2px;
        color: #191919;
        white-space: nowrap;
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis; }
      .integration-kataai-list .integration-list-outer-wrapper .integration-list-inner-wrapper svg {
        position: absolute;
        right: 12px;
        top: 12px;
        font-size: 22px;
        color: rgba(25, 25, 25, 0.6); }
      .integration-kataai-list .integration-list-outer-wrapper .integration-list-inner-wrapper:hover {
        box-shadow: 0 2px 20px 0 rgba(25, 25, 25, 0.18); }
        .integration-kataai-list .integration-list-outer-wrapper .integration-list-inner-wrapper:hover .platform-name {
          color: #191919; }
        .integration-kataai-list .integration-list-outer-wrapper .integration-list-inner-wrapper:hover svg {
          color: #ff7d00; }
  .integration-kataai-list .link-kataai-account-list:nth-child(3n) .integration-list-inner-wrapper {
    margin: 0 0 12px 0; }

.integration-kataai-add-account {
  padding: 32px 30px;
  text-align: left; }
  .integration-kataai-add-account .comp-back-button {
    margin: 0 0 16px 0; }
  .integration-kataai-add-account .integration-kataai-add-account-box {
    background: #ffffff;
    border-radius: 8px;
    padding: 32px;
    border: solid 1px rgba(25, 25, 25, 0.1); }
    .integration-kataai-add-account .integration-kataai-add-account-box .integration-kataai-add-account-box-top {
      padding-bottom: 15px;
      border-bottom: solid 1px #dcdcdc;
      position: relative;
      font-size: 20px;
      letter-spacing: -0.6px; }
      .integration-kataai-add-account .integration-kataai-add-account-box .integration-kataai-add-account-box-top button {
        position: absolute;
        top: -6px;
        right: 0;
        font-size: 16px;
        letter-spacing: -0.6px; }
    .integration-kataai-add-account .integration-kataai-add-account-box .integration-kataai-add-account-box-form {
      width: 100%; }
      .integration-kataai-add-account .integration-kataai-add-account-box .integration-kataai-add-account-box-form .input-name, .integration-kataai-add-account .integration-kataai-add-account-box .integration-kataai-add-account-box-form .input-topic {
        width: 343px !important; }
      .integration-kataai-add-account .integration-kataai-add-account-box .integration-kataai-add-account-box-form label {
        padding: 24px 0 0;
        font-size: 16px;
        letter-spacing: -0.5px;
        display: block;
        position: relative;
        width: 343px; }
        .integration-kataai-add-account .integration-kataai-add-account-box .integration-kataai-add-account-box-form label span {
          position: absolute;
          right: 0; }
      .integration-kataai-add-account .integration-kataai-add-account-box .integration-kataai-add-account-box-form input[type="text"] {
        padding: 12px 16px;
        border-radius: 6px;
        border: solid 1px #dcdcdc;
        width: 100%;
        font-size: 16px;
        letter-spacing: -0.3px;
        display: block; }
        .integration-kataai-add-account .integration-kataai-add-account-box .integration-kataai-add-account-box-form input[type="text"]:focus {
          border: solid 1px var(--main-dark-orange); }
      .integration-kataai-add-account .integration-kataai-add-account-box .integration-kataai-add-account-box-form .integration-info-form {
        font-size: 14px;
        color: rgba(25, 25, 25, 0.8);
        letter-spacing: -0.2px; }
      .integration-kataai-add-account .integration-kataai-add-account-box .integration-kataai-add-account-box-form .custom-select-whatsapp-topic .custom-select-option-value-wrapper {
        padding: 12px 16px !important;
        margin-bottom: 8px; }

.integration-kataai-detail-account {
  padding: 32px 30px;
  text-align: left; }
  .integration-kataai-detail-account label {
    font-size: 16px;
    letter-spacing: -0.5px; }
  .integration-kataai-detail-account .comp-back-button {
    margin: 0 0 16px 0;
    font-family: PTRootBold; }
  .integration-kataai-detail-account .integration-kataai-detail-account-box-1 {
    background: #ffffff;
    border-radius: 8px;
    padding: 22px 32px 32px;
    border: solid 1px rgba(25, 25, 25, 0.1);
    position: relative; }
    .integration-kataai-detail-account .integration-kataai-detail-account-box-1.channel-config-top {
      margin-top: 24px; }
      .integration-kataai-detail-account .integration-kataai-detail-account-box-1.channel-config-top .disabled-form {
        width: 100%; }
        .integration-kataai-detail-account .integration-kataai-detail-account-box-1.channel-config-top .disabled-form input {
          width: 100%; }
    .integration-kataai-detail-account .integration-kataai-detail-account-box-1 .disabled-form input:focus {
      border: solid 1px rgba(25, 25, 25, 0.1) !important; }
    .integration-kataai-detail-account .integration-kataai-detail-account-box-1 svg {
      color: var(--main-dark-orange) !important; }
    .integration-kataai-detail-account .integration-kataai-detail-account-box-1 .integration-kataai-detail-account-box-1-top {
      padding: 0 0 18px;
      border-bottom: solid 1px #dcdcdc;
      position: relative;
      font-size: 20px;
      letter-spacing: -0.6px;
      padding-right: 230px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis; }
      .integration-kataai-detail-account .integration-kataai-detail-account-box-1 .integration-kataai-detail-account-box-1-top.integration-kataai-detail-account-box-1-top-1-line {
        padding: 0 0 28px; }
        .integration-kataai-detail-account .integration-kataai-detail-account-box-1 .integration-kataai-detail-account-box-1-top.integration-kataai-detail-account-box-1-top-1-line .box-title {
          position: relative;
          top: 8px; }
      .integration-kataai-detail-account .integration-kataai-detail-account-box-1 .integration-kataai-detail-account-box-1-top.box-channel-button {
        margin-top: 24px; }
        .integration-kataai-detail-account .integration-kataai-detail-account-box-1 .integration-kataai-detail-account-box-1-top.box-channel-button .detail-topic {
          display: block; }
        .integration-kataai-detail-account .integration-kataai-detail-account-box-1 .integration-kataai-detail-account-box-1-top.box-channel-button .topic-wrapper {
          margin-top: 24px;
          width: 100%;
          display: block; }
        .integration-kataai-detail-account .integration-kataai-detail-account-box-1 .integration-kataai-detail-account-box-1-top.box-channel-button .status-wrapper .status-value {
          color: rgba(25, 25, 25, 0.4); }
          .integration-kataai-detail-account .integration-kataai-detail-account-box-1 .integration-kataai-detail-account-box-1-top.box-channel-button .status-wrapper .status-value.status-enabled {
            color: #19C700; }
        .integration-kataai-detail-account .integration-kataai-detail-account-box-1 .integration-kataai-detail-account-box-1-top.box-channel-button .status-wrapper img {
          position: relative;
          top: -1px;
          margin-right: 4px; }
      .integration-kataai-detail-account .integration-kataai-detail-account-box-1 .integration-kataai-detail-account-box-1-top .bot-name {
        font-size: 12px;
        margin-bottom: 0;
        min-height: 8px; }
      .integration-kataai-detail-account .integration-kataai-detail-account-box-1 .integration-kataai-detail-account-box-1-top .integration-kataai-detail-account-box-1-top-button {
        position: absolute;
        top: 4px;
        right: 0;
        display: inline-block; }
        .integration-kataai-detail-account .integration-kataai-detail-account-box-1 .integration-kataai-detail-account-box-1-top .integration-kataai-detail-account-box-1-top-button button {
          padding: 7px 24px;
          font-size: 16px;
          letter-spacing: -0.6px;
          height: 40px;
          margin-left: 8px; }
          .integration-kataai-detail-account .integration-kataai-detail-account-box-1 .integration-kataai-detail-account-box-1-top .integration-kataai-detail-account-box-1-top-button button img {
            position: relative;
            top: -2px;
            margin-right: 4px; }
          .integration-kataai-detail-account .integration-kataai-detail-account-box-1 .integration-kataai-detail-account-box-1-top .integration-kataai-detail-account-box-1-top-button button svg {
            position: relative;
            top: -2px;
            margin-right: 4px; }
          .integration-kataai-detail-account .integration-kataai-detail-account-box-1 .integration-kataai-detail-account-box-1-top .integration-kataai-detail-account-box-1-top-button button .lds-ring {
            top: unset;
            right: unset;
            position: relative;
            top: 3px; }
            .integration-kataai-detail-account .integration-kataai-detail-account-box-1 .integration-kataai-detail-account-box-1-top .integration-kataai-detail-account-box-1-top-button button .lds-ring div {
              margin: 0;
              border-color: #ffffff transparent transparent transparent; }
    .integration-kataai-detail-account .integration-kataai-detail-account-box-1 .detail-topic {
      justify-content: space-between;
      align-items: center;
      margin: 24px 0 0; }
      .integration-kataai-detail-account .integration-kataai-detail-account-box-1 .detail-topic button {
        position: absolute;
        right: 32px;
        top: 26px; }
      .integration-kataai-detail-account .integration-kataai-detail-account-box-1 .detail-topic .topic-val {
        margin: 6px 0 0;
        max-width: calc(100% - 200px); }
      .integration-kataai-detail-account .integration-kataai-detail-account-box-1 .detail-topic .topic-wrapper {
        display: flex;
        flex-direction: column; }
        .integration-kataai-detail-account .integration-kataai-detail-account-box-1 .detail-topic .topic-wrapper .detail-title {
          font-size: 16px;
          letter-spacing: -0.5px; }
        .integration-kataai-detail-account .integration-kataai-detail-account-box-1 .detail-topic .topic-wrapper b:nth-child(2) {
          font-size: 20px; }
      .integration-kataai-detail-account .integration-kataai-detail-account-box-1 .detail-topic button {
        padding: 7px 24px;
        font-size: 16px;
        letter-spacing: -0.6px;
        height: 40px;
        margin-left: 8px; }
        .integration-kataai-detail-account .integration-kataai-detail-account-box-1 .detail-topic button svg {
          position: relative;
          top: -2px;
          margin-right: 4px; }
    .integration-kataai-detail-account .integration-kataai-detail-account-box-1 .bot-token-status {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 24px 0 0; }
      .integration-kataai-detail-account .integration-kataai-detail-account-box-1 .bot-token-status .bot-status-wrapper .bot-status-title {
        font-size: 14px; }
      .integration-kataai-detail-account .integration-kataai-detail-account-box-1 .bot-token-status .bot-status-wrapper .status-wrapper {
        align-items: center; }
        .integration-kataai-detail-account .integration-kataai-detail-account-box-1 .bot-token-status .bot-status-wrapper .status-wrapper .facebook-button {
          width: unset;
          margin-top: 24px; }
        .integration-kataai-detail-account .integration-kataai-detail-account-box-1 .bot-token-status .bot-status-wrapper .status-wrapper img {
          margin-right: 5px;
          position: relative;
          top: -1px; }
        .integration-kataai-detail-account .integration-kataai-detail-account-box-1 .bot-token-status .bot-status-wrapper .status-wrapper .status-text {
          font-size: 16px; }
        .integration-kataai-detail-account .integration-kataai-detail-account-box-1 .bot-token-status .bot-status-wrapper .status-wrapper .text-green {
          color: #19c700; }
        .integration-kataai-detail-account .integration-kataai-detail-account-box-1 .bot-token-status .bot-status-wrapper .status-wrapper .text-red {
          color: #ff3f57; }
      .integration-kataai-detail-account .integration-kataai-detail-account-box-1 .bot-token-status .button-wrapper button {
        padding: 7px 24px;
        font-size: 16px;
        letter-spacing: -0.6px;
        height: 40px;
        margin-left: 8px;
        font-family: PTRootBold; }
        .integration-kataai-detail-account .integration-kataai-detail-account-box-1 .bot-token-status .button-wrapper button svg {
          position: relative;
          top: -2px;
          margin-right: 4px; }
    .integration-kataai-detail-account .integration-kataai-detail-account-box-1.box-channel-button {
      margin-top: 24px; }
      .integration-kataai-detail-account .integration-kataai-detail-account-box-1.box-channel-button .detail-topic {
        display: block; }
      .integration-kataai-detail-account .integration-kataai-detail-account-box-1.box-channel-button .topic-wrapper {
        margin-top: 24px;
        width: 100%;
        display: block; }
      .integration-kataai-detail-account .integration-kataai-detail-account-box-1.box-channel-button .status-wrapper .status-value {
        color: rgba(25, 25, 25, 0.4); }
        .integration-kataai-detail-account .integration-kataai-detail-account-box-1.box-channel-button .status-wrapper .status-value.status-enabled {
          color: #19C700; }
      .integration-kataai-detail-account .integration-kataai-detail-account-box-1.box-channel-button .status-wrapper img {
        position: relative;
        top: -1px;
        margin-right: 4px; }
  .integration-kataai-detail-account .integration-detail-account-box-1 {
    background: #ffffff;
    border-radius: 8px;
    padding: 22px 32px 32px;
    border: solid 1px rgba(25, 25, 25, 0.1); }
    .integration-kataai-detail-account .integration-detail-account-box-1.box-channel-button {
      margin-top: 24px; }
      .integration-kataai-detail-account .integration-detail-account-box-1.box-channel-button .facebook-detail-topic {
        display: block; }
        .integration-kataai-detail-account .integration-detail-account-box-1.box-channel-button .facebook-detail-topic .channel-button-wrapper {
          margin-top: 24px; }
      .integration-kataai-detail-account .integration-detail-account-box-1.box-channel-button .topic-wrapper {
        margin-top: 24px;
        width: 100%;
        display: block; }
      .integration-kataai-detail-account .integration-detail-account-box-1.box-channel-button .status-wrapper .status-value {
        color: rgba(25, 25, 25, 0.4); }
        .integration-kataai-detail-account .integration-detail-account-box-1.box-channel-button .status-wrapper .status-value.status-enabled {
          color: #19C700; }
      .integration-kataai-detail-account .integration-detail-account-box-1.box-channel-button .status-wrapper img {
        position: relative;
        top: -1px;
        margin-right: 4px; }
    .integration-kataai-detail-account .integration-detail-account-box-1 .integration-detail-account-box-1-top {
      padding: 10px 0 15px;
      border-bottom: solid 1px #dcdcdc;
      position: relative;
      font-size: 20px;
      letter-spacing: -0.6px;
      padding-right: 230px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis; }
      .integration-kataai-detail-account .integration-detail-account-box-1 .integration-detail-account-box-1-top .bot-name {
        font-size: 12px;
        margin-bottom: 0; }
      .integration-kataai-detail-account .integration-detail-account-box-1 .integration-detail-account-box-1-top .integration-detail-account-box-1-top-button {
        position: absolute;
        top: 0px;
        right: 0;
        display: inline-block; }
        .integration-kataai-detail-account .integration-detail-account-box-1 .integration-detail-account-box-1-top .integration-detail-account-box-1-top-button button {
          padding: 7px 24px;
          font-size: 16px;
          letter-spacing: -0.6px;
          height: 40px;
          margin-left: 8px; }
          .integration-kataai-detail-account .integration-detail-account-box-1 .integration-detail-account-box-1-top .integration-detail-account-box-1-top-button button.button-channel-detail {
            position: relative;
            top: 15px; }
          .integration-kataai-detail-account .integration-detail-account-box-1 .integration-detail-account-box-1-top .integration-detail-account-box-1-top-button button img {
            position: relative;
            top: -2px;
            margin-right: 4px; }
          .integration-kataai-detail-account .integration-detail-account-box-1 .integration-detail-account-box-1-top .integration-detail-account-box-1-top-button button svg {
            position: relative;
            top: -2px;
            margin-right: 4px; }
          .integration-kataai-detail-account .integration-detail-account-box-1 .integration-detail-account-box-1-top .integration-detail-account-box-1-top-button button .lds-ring {
            top: unset;
            right: unset;
            position: relative;
            top: 3px; }
            .integration-kataai-detail-account .integration-detail-account-box-1 .integration-detail-account-box-1-top .integration-detail-account-box-1-top-button button .lds-ring div {
              margin: 0;
              border-color: #ffffff transparent transparent transparent; }
    .integration-kataai-detail-account .integration-detail-account-box-1 .facebook-detail-topic {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 24px 0 0; }
      .integration-kataai-detail-account .integration-detail-account-box-1 .facebook-detail-topic .channel-button-wrapper {
        margin-top: 24px;
        width: 300px;
        word-break: break-word; }
      .integration-kataai-detail-account .integration-detail-account-box-1 .facebook-detail-topic .topic-wrapper {
        display: flex;
        flex-direction: column; }
        .integration-kataai-detail-account .integration-detail-account-box-1 .facebook-detail-topic .topic-wrapper .detail-title {
          font-size: 14px; }
      .integration-kataai-detail-account .integration-detail-account-box-1 .facebook-detail-topic button {
        padding: 7px 24px;
        font-size: 16px;
        letter-spacing: -0.6px;
        height: 40px;
        margin-left: 8px; }
        .integration-kataai-detail-account .integration-detail-account-box-1 .facebook-detail-topic button svg {
          position: relative;
          top: -2px;
          margin-right: 4px; }
    .integration-kataai-detail-account .integration-detail-account-box-1 .facebook-bot-token-status {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 24px 0 0; }
      .integration-kataai-detail-account .integration-detail-account-box-1 .facebook-bot-token-status .bot-status-wrapper .bot-status-title {
        font-size: 14px; }
      .integration-kataai-detail-account .integration-detail-account-box-1 .facebook-bot-token-status .bot-status-wrapper .status-wrapper {
        display: flex;
        align-items: center; }
        .integration-kataai-detail-account .integration-detail-account-box-1 .facebook-bot-token-status .bot-status-wrapper .status-wrapper img {
          margin-right: 5px;
          position: relative;
          top: -2px; }
        .integration-kataai-detail-account .integration-detail-account-box-1 .facebook-bot-token-status .bot-status-wrapper .status-wrapper .status-text {
          font-size: 16px; }
        .integration-kataai-detail-account .integration-detail-account-box-1 .facebook-bot-token-status .bot-status-wrapper .status-wrapper .text-green {
          color: #19c700; }
        .integration-kataai-detail-account .integration-detail-account-box-1 .facebook-bot-token-status .bot-status-wrapper .status-wrapper .text-red {
          color: #ff3f57; }
      .integration-kataai-detail-account .integration-detail-account-box-1 .facebook-bot-token-status .button-wrapper button {
        padding: 7px 24px;
        font-size: 16px;
        letter-spacing: -0.6px;
        height: 40px;
        margin-left: 8px;
        font-family: PTRootBold; }
        .integration-kataai-detail-account .integration-detail-account-box-1 .facebook-bot-token-status .button-wrapper button svg {
          position: relative;
          top: -2px;
          margin-right: 4px; }
    .integration-kataai-detail-account .integration-detail-account-box-1.box-channel-button {
      margin-top: 24px; }
      .integration-kataai-detail-account .integration-detail-account-box-1.box-channel-button .facebook-detail-topic {
        display: block; }
      .integration-kataai-detail-account .integration-detail-account-box-1.box-channel-button .topic-wrapper {
        margin-top: 24px;
        width: 100%;
        display: block; }
      .integration-kataai-detail-account .integration-detail-account-box-1.box-channel-button .status-wrapper .status-value {
        color: rgba(25, 25, 25, 0.4); }
        .integration-kataai-detail-account .integration-detail-account-box-1.box-channel-button .status-wrapper .status-value.status-enabled {
          color: #19C700; }
      .integration-kataai-detail-account .integration-detail-account-box-1.box-channel-button .status-wrapper img {
        position: relative;
        top: -1px;
        margin-right: 4px; }

.integration-kataai-edit-detail-account {
  padding: 32px 30px;
  text-align: left; }
  .integration-kataai-edit-detail-account .input-topic label {
    width: 100% !important; }
  .integration-kataai-edit-detail-account .comp-back-button {
    margin: 0 0 16px 0;
    font-family: PTRootBold; }
  .integration-kataai-edit-detail-account .integration-kataai-edit-detail-account-box {
    background: #ffffff;
    border-radius: 8px;
    padding: 32px;
    border: solid 1px rgba(25, 25, 25, 0.1); }
    .integration-kataai-edit-detail-account .integration-kataai-edit-detail-account-box .integration-kataai-edit-detail-account-box-top {
      padding-bottom: 15px;
      border-bottom: solid 1px #dcdcdc;
      position: relative;
      font-size: 20px;
      letter-spacing: -0.6px; }
      .integration-kataai-edit-detail-account .integration-kataai-edit-detail-account-box .integration-kataai-edit-detail-account-box-top button {
        position: absolute;
        top: -6px;
        right: 0;
        font-size: 16px;
        letter-spacing: -0.6px; }
    .integration-kataai-edit-detail-account .integration-kataai-edit-detail-account-box .integration-kataai-edit-detail-account-box-form {
      width: 100%; }
      .integration-kataai-edit-detail-account .integration-kataai-edit-detail-account-box .integration-kataai-edit-detail-account-box-form label {
        padding: 24px 0 0;
        font-size: 16px;
        letter-spacing: -0.5px;
        display: block;
        width: 343px;
        position: relative; }
        .integration-kataai-edit-detail-account .integration-kataai-edit-detail-account-box .integration-kataai-edit-detail-account-box-form label span {
          position: absolute;
          right: 0; }
      .integration-kataai-edit-detail-account .integration-kataai-edit-detail-account-box .integration-kataai-edit-detail-account-box-form input[type='text'] {
        padding: 12px 16px;
        border-radius: 6px;
        border: solid 1px #dcdcdc;
        width: 100%;
        font-size: 16px;
        letter-spacing: -0.3px;
        display: block; }
        .integration-kataai-edit-detail-account .integration-kataai-edit-detail-account-box .integration-kataai-edit-detail-account-box-form input[type='text']:focus {
          border: solid 1px var(--main-dark-orange); }
      .integration-kataai-edit-detail-account .integration-kataai-edit-detail-account-box .integration-kataai-edit-detail-account-box-form .form-detail input {
        width: 343px; }
      .integration-kataai-edit-detail-account .integration-kataai-edit-detail-account-box .integration-kataai-edit-detail-account-box-form input[type='text']:disabled {
        background: rgba(25, 25, 25, 0.1);
        color: rgba(25, 25, 25, 0.2);
        border: none;
        cursor: not-allowed; }
      .integration-kataai-edit-detail-account .integration-kataai-edit-detail-account-box .integration-kataai-edit-detail-account-box-form .integration-info-form {
        font-size: 14px;
        color: rgba(25, 25, 25, 0.8);
        letter-spacing: -0.2px; }
      .integration-kataai-edit-detail-account .integration-kataai-edit-detail-account-box .integration-kataai-edit-detail-account-box-form .custom-select-kataai-detail .custom-select-option-value-wrapper {
        padding: 12px 16px !important;
        margin-bottom: 8px; }

.integration-kataai-edit-config-account {
  padding: 32px 30px;
  text-align: left; }
  .integration-kataai-edit-config-account .comp-back-button {
    margin: 0 0 16px 0;
    font-family: PTRootBold; }
  .integration-kataai-edit-config-account .integration-kataai-edit-config-account-box {
    background: #ffffff;
    border-radius: 8px;
    padding: 32px;
    border: solid 1px rgba(25, 25, 25, 0.1); }
    .integration-kataai-edit-config-account .integration-kataai-edit-config-account-box .integration-kataai-edit-config-account-box-top {
      padding-bottom: 15px;
      border-bottom: solid 1px #dcdcdc;
      position: relative;
      font-size: 20px;
      letter-spacing: -0.6px; }
      .integration-kataai-edit-config-account .integration-kataai-edit-config-account-box .integration-kataai-edit-config-account-box-top button {
        position: absolute;
        top: -6px;
        right: 0;
        font-size: 16px;
        letter-spacing: -0.6px; }
    .integration-kataai-edit-config-account .integration-kataai-edit-config-account-box .integration-kataai-edit-config-account-box-form {
      width: 100%; }
      .integration-kataai-edit-config-account .integration-kataai-edit-config-account-box .integration-kataai-edit-config-account-box-form label {
        padding: 24px 0 0;
        font-size: 16px;
        letter-spacing: -0.5px;
        display: block; }
      .integration-kataai-edit-config-account .integration-kataai-edit-config-account-box .integration-kataai-edit-config-account-box-form input[type='text'] {
        padding: 12px 16px;
        border-radius: 6px;
        border: solid 1px #dcdcdc;
        width: 100%;
        font-size: 16px;
        letter-spacing: -0.3px;
        display: block; }
        .integration-kataai-edit-config-account .integration-kataai-edit-config-account-box .integration-kataai-edit-config-account-box-form input[type='text']:focus {
          border: solid 1px var(--main-dark-orange); }
      .integration-kataai-edit-config-account .integration-kataai-edit-config-account-box .integration-kataai-edit-config-account-box-form input[type='text']:disabled {
        background: rgba(25, 25, 25, 0.1);
        color: rgba(25, 25, 25, 0.2);
        border: none;
        cursor: not-allowed; }
      .integration-kataai-edit-config-account .integration-kataai-edit-config-account-box .integration-kataai-edit-config-account-box-form .integration-info-form {
        font-size: 14px;
        color: rgba(25, 25, 25, 0.8);
        letter-spacing: -0.2px; }
      .integration-kataai-edit-config-account .integration-kataai-edit-config-account-box .integration-kataai-edit-config-account-box-form .custom-select-kataai-detail .custom-select-option-value-wrapper {
        padding: 12px 16px !important;
        margin-bottom: 8px; }

.chatbot-add-chatbot {
  padding: 32px 30px;
  text-align: left; }
  .chatbot-add-chatbot .comp-back-button {
    margin: 0 0 16px 0; }
  .chatbot-add-chatbot .chatbot-add-chatbot-box {
    background: #ffffff;
    border-radius: 8px;
    padding: 32px;
    border: solid 1px rgba(25, 25, 25, 0.1); }
    .chatbot-add-chatbot .chatbot-add-chatbot-box .chatbot-add-chatbot-box-top {
      padding-bottom: 15px;
      border-bottom: solid 1px #dcdcdc;
      position: relative;
      font-size: 20px;
      letter-spacing: -0.6px; }
      .chatbot-add-chatbot .chatbot-add-chatbot-box .chatbot-add-chatbot-box-top .chat-bot-chatbot-button-wrapper {
        margin: 0; }
      .chatbot-add-chatbot .chatbot-add-chatbot-box .chatbot-add-chatbot-box-top button {
        position: absolute;
        top: -6px;
        right: 0;
        margin: 0 !important;
        font-size: 16px;
        letter-spacing: -0.6px; }
    .chatbot-add-chatbot .chatbot-add-chatbot-box .chatbot-add-chatbot-box-form {
      width: 320px; }
      .chatbot-add-chatbot .chatbot-add-chatbot-box .chatbot-add-chatbot-box-form label {
        padding: 24px 0 0;
        font-size: 14px;
        letter-spacing: -0.4px;
        display: block; }
      .chatbot-add-chatbot .chatbot-add-chatbot-box .chatbot-add-chatbot-box-form input[type="text"] {
        padding: 12px 16px;
        border-radius: 6px;
        border: solid 1px #dcdcdc;
        width: 100%;
        font-size: 16px;
        letter-spacing: -0.3px;
        display: block;
        margin-bottom: 8px; }
      .chatbot-add-chatbot .chatbot-add-chatbot-box .chatbot-add-chatbot-box-form .integration-info-form {
        font-size: 14px;
        color: rgba(25, 25, 25, 0.8);
        letter-spacing: -0.2px; }
      .chatbot-add-chatbot .chatbot-add-chatbot-box .chatbot-add-chatbot-box-form .custom-select-path .custom-select-option-value-wrapper {
        padding: 12px 16px !important;
        margin-bottom: 8px; }
    .chatbot-add-chatbot .chatbot-add-chatbot-box .chat-bot-chatbot-button-wrapper {
      margin-top: 18px; }
      .chatbot-add-chatbot .chatbot-add-chatbot-box .chat-bot-chatbot-button-wrapper button {
        margin: 0 4px 0 0; }


.integration-miitel-list {
  text-align: left; }
  .integration-miitel-list .integration-list-loading-wrapper .lds-ring {
    width: 50px;
    height: 50px;
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    right: unset; }
    .integration-miitel-list .integration-list-loading-wrapper .lds-ring div {
      border: 5px solid #fff;
      border-color: #ff7d00 transparent transparent transparent;
      width: 50px;
      height: 50px;
      margin: 0; }
  .integration-miitel-list .status-suspended {
    color: #FF3F57 !important; }
  .integration-miitel-list .status-in_review {
    color: #EDAB00 !important; }
  .integration-miitel-list .status-verified {
    color: #19C700 !important; }
  .integration-miitel-list .status-unverified {
    color: rgba(25, 25, 25, 0.4) !important; }
  .integration-miitel-list .integration-list-header {
    position: relative;
    padding-left: 42px;
    padding-bottom: 26px;
    border-bottom: solid 1px rgba(25, 25, 25, 0.1); }
    .integration-miitel-list .integration-list-header .integration-list-header-icon {
      position: absolute;
      top: 0;
      left: 0;
      width: 32px; }
    .integration-miitel-list .integration-list-header .integration-list-header-text-wrapper {
      text-align: left; }
      .integration-miitel-list .integration-list-header .integration-list-header-text-wrapper b {
        font-size: 20px;
        letter-spacing: -0.6px; }
      .integration-miitel-list .integration-list-header .integration-list-header-text-wrapper p {
        margin: 0;
        font-size: 14px;
        letter-spacing: -0.2px; }
      .integration-miitel-list .integration-list-header .integration-list-header-text-wrapper a {
        color: #ff7d00; }
    .integration-miitel-list .integration-list-header .integration-list-header-button {
      position: absolute;
      right: 0;
      top: 0;
      font-size: 16px;
      letter-spacing: -0.5px;
      padding: 7px 32px; }
      .integration-miitel-list .integration-list-header .integration-list-header-button img {
        margin-right: 4px;
        position: relative;
        top: -2px; }
  .integration-miitel-list .integration-list-no-data {
    width: 100%;
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
    .integration-miitel-list .integration-list-no-data b {
      font-size: 20px;
      letter-spacing: -0.6px;
      color: #191919; }
    .integration-miitel-list .integration-list-no-data p {
      color: rgba(25, 25, 25, 0.6);
      letter-spacing: -0.6px;
      font-size: 16px; }
  .integration-miitel-list .integration-list-outer-wrapper {
    position: relative;
    width: 100%;
    padding-top: 24px;
    text-align: left; }
    .integration-miitel-list .integration-list-outer-wrapper .integration-list-inner-wrapper {
      width: calc((100% / 3) - 8px);
      border-radius: 8px;
      margin: 0 12px 12px 0;
      box-shadow: 0 2px 20px 0 rgba(25, 25, 25, 0.05);
      background: #ffffff;
      display: inline-block;
      vertical-align: top;
      padding: 24px 30px 24px 24px;
      cursor: pointer;
      position: relative; }
      .integration-miitel-list .integration-list-outer-wrapper .integration-list-inner-wrapper p:nth-child(2) {
        font-size: 16px;
        letter-spacing: -0.5px;
        color: rgba(25, 25, 25, 0.6);
        margin: 0;
        white-space: nowrap;
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis; }
      .integration-miitel-list .integration-list-outer-wrapper .integration-list-inner-wrapper span {
        display: block;
        font-size: 12px;
        letter-spacing: -0.2px;
        color: #191919; }
      .integration-miitel-list .integration-list-outer-wrapper .integration-list-inner-wrapper p {
        margin: 30px 0 0;
        color: #ff7d00;
        font-size: 12px;
        letter-spacing: -0.2px; }
        .integration-miitel-list .integration-list-outer-wrapper .integration-list-inner-wrapper p.font-green {
          color: #19c700; }
        .integration-miitel-list .integration-list-outer-wrapper .integration-list-inner-wrapper p.font-grey {
          color: rgba(25, 25, 25, 0.4); }
      .integration-miitel-list .integration-list-outer-wrapper .integration-list-inner-wrapper svg {
        position: absolute;
        right: 12px;
        top: 12px;
        font-size: 22px;
        color: #191919; }
      .integration-miitel-list .integration-list-outer-wrapper .integration-list-inner-wrapper:hover {
        box-shadow: 0 2px 20px 0 rgba(25, 25, 25, 0.18); }
        .integration-miitel-list .integration-list-outer-wrapper .integration-list-inner-wrapper:hover p:nth-child(2) {
          color: #191919; }
        .integration-miitel-list .integration-list-outer-wrapper .integration-list-inner-wrapper:hover svg {
          color: #ff7d00; }
  .integration-miitel-list .link-google-account-list:nth-child(3n) .integration-list-inner-wrapper {
    margin: 0 0 12px 0; }

.integration-miitel-add-account {
  padding: 32px 30px;
  text-align: left; }
  .integration-miitel-add-account .comp-back-button {
    margin: 0 0 16px 0;
    font-family: PTRootBold; }
  .integration-miitel-add-account .integration-miitel-add-account-box {
    background: #ffffff;
    border-radius: 8px;
    padding: 32px;
    border: solid 1px rgba(25, 25, 25, 0.1); }
    .integration-miitel-add-account .integration-miitel-add-account-box .integration-miitel-add-account-box-top {
      padding-bottom: 15px;
      border-bottom: solid 1px #dcdcdc;
      position: relative;
      font-size: 20px;
      letter-spacing: -0.6px; }
      .integration-miitel-add-account .integration-miitel-add-account-box .integration-miitel-add-account-box-top button {
        position: absolute;
        top: -6px;
        right: 0;
        font-size: 16px;
        letter-spacing: -0.6px;
        width: 180px; }
    .integration-miitel-add-account .integration-miitel-add-account-box label {
      font-size: 16px;
      margin: 0 0 8px 0;
      position: relative;
      width: 343px;
      padding: 24px 0 0 0; }
      .integration-miitel-add-account .integration-miitel-add-account-box label span {
        position: absolute;
        right: 0;
        bottom: 0; }
    .integration-miitel-add-account .integration-miitel-add-account-box input[type="text"] {
      width: 343px; }

.modal-warning-limit-miitel .modal-body {
  text-align: left; }

.integration-miitel-add-account {
  padding: 32px 30px;
  text-align: left; }
  .integration-miitel-add-account .comp-back-button {
    margin: 0 0 16px 0;
    font-family: PTRootBold; }
  .integration-miitel-add-account .integration-miitel-add-account-box {
    background: #ffffff;
    border-radius: 8px;
    padding: 32px;
    border: solid 1px rgba(25, 25, 25, 0.1); }
    .integration-miitel-add-account .integration-miitel-add-account-box .integration-miitel-add-account-box-top {
      padding-bottom: 15px;
      border-bottom: solid 1px #dcdcdc;
      position: relative;
      font-size: 20px;
      letter-spacing: -0.6px; }
      .integration-miitel-add-account .integration-miitel-add-account-box .integration-miitel-add-account-box-top button {
        position: absolute;
        top: -6px;
        right: 0;
        font-size: 16px;
        letter-spacing: -0.6px;
        width: 180px; }
    .integration-miitel-add-account .integration-miitel-add-account-box label {
      font-size: 16px;
      margin: 0 0 8px 0;
      position: relative;
      width: 343px;
      padding: 24px 0 0 0; }
      .integration-miitel-add-account .integration-miitel-add-account-box label span {
        position: absolute;
        right: 0;
        bottom: 0; }
    .integration-miitel-add-account .integration-miitel-add-account-box input[type="text"] {
      width: 343px; }

.modal-warning-limit-miitel .modal-body {
  text-align: left; }

.integration-miitel-detail-account {
  padding: 32px 30px;
  text-align: left; }
  .integration-miitel-detail-account .comp-back-button {
    margin: 0 0 16px 0;
    font-family: PTRootBold; }
  .integration-miitel-detail-account .integration-miitel-detail-account-box-1 {
    background: #ffffff;
    border-radius: 8px;
    padding: 22px 32px 32px;
    border: solid 1px rgba(25, 25, 25, 0.1);
    position: relative; }
    .integration-miitel-detail-account .integration-miitel-detail-account-box-1 .integration-miitel-detail-account-box-1-top {
      padding: 10px 0 15px;
      border-bottom: solid 1px #dcdcdc;
      position: relative;
      font-size: 20px;
      letter-spacing: -0.6px;
      padding-right: 230px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis; }
      .integration-miitel-detail-account .integration-miitel-detail-account-box-1 .integration-miitel-detail-account-box-1-top input[type="text"] {
        width: 400px; }
      .integration-miitel-detail-account .integration-miitel-detail-account-box-1 .integration-miitel-detail-account-box-1-top .integration-miitel-detail-account-box-1-top-button {
        position: absolute;
        top: 0px;
        right: 0;
        display: inline-block; }
        .integration-miitel-detail-account .integration-miitel-detail-account-box-1 .integration-miitel-detail-account-box-1-top .integration-miitel-detail-account-box-1-top-button button {
          padding: 7px 24px;
          font-size: 16px;
          letter-spacing: -0.6px;
          height: 40px;
          margin-left: 8px;
          width: 180px; }
          .integration-miitel-detail-account .integration-miitel-detail-account-box-1 .integration-miitel-detail-account-box-1-top .integration-miitel-detail-account-box-1-top-button button.button-channel-detail {
            position: relative;
            top: 15px; }
          .integration-miitel-detail-account .integration-miitel-detail-account-box-1 .integration-miitel-detail-account-box-1-top .integration-miitel-detail-account-box-1-top-button button img {
            position: relative;
            top: -2px;
            margin-right: 4px; }
          .integration-miitel-detail-account .integration-miitel-detail-account-box-1 .integration-miitel-detail-account-box-1-top .integration-miitel-detail-account-box-1-top-button button svg {
            position: relative;
            top: -2px;
            margin-right: 4px; }
          .integration-miitel-detail-account .integration-miitel-detail-account-box-1 .integration-miitel-detail-account-box-1-top .integration-miitel-detail-account-box-1-top-button button .lds-ring {
            top: unset;
            right: unset;
            position: relative;
            top: 3px; }
            .integration-miitel-detail-account .integration-miitel-detail-account-box-1 .integration-miitel-detail-account-box-1-top .integration-miitel-detail-account-box-1-top-button button .lds-ring div {
              margin: 0;
              border-color: #ffffff transparent transparent transparent; }

.quick-reply-main-wrapper .btn {
  border: 0; }

.quick-reply-main-wrapper tbody tr:hover td {
  background: #fff2e5 !important; }

.quick-reply-list-title {
  color: #191919;
  font-size: 20px;
  letter-spacing: -0.6px;
  line-height: 24px;
  padding-bottom: 16px;
  border-bottom: solid 1px rgba(25, 25, 25, 0.1); }

.quick-reply-list-tool-wrapper {
  width: 100%;
  position: relative;
  margin-top: 24px;
  margin-bottom: 32px; }

.quick-reply-list-info {
  font-size: 16px;
  letter-spacing: -0.3px;
  color: rgba(25, 25, 25, 0.8);
  line-height: 1.5;
  padding: 0 208px 18px 0; }

.quick-reply-list-tool-wrapper button.button-invite-member {
  position: absolute;
  right: 0;
  top: 50%;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%);
  padding: 11px 0;
  width: 200px; }

.quick-reply-list-tool-wrapper button.button-invite-member svg {
  position: relative;
  top: -1px;
  margin-right: 5px; }

.quick-reply-list-table-wrapper {
  width: 100%;
  position: relative; }

.table-quick-reply-list thead tr {
  background: #ffffff; }

.table-quick-reply-list tbody td {
  background: #ffffff;
  position: relative; }
  .table-quick-reply-list tbody td.no-hover {
    cursor: default !important; }
    .table-quick-reply-list tbody td.no-hover:hover {
      background-color: #ffffff !important; }
      .table-quick-reply-list tbody td.no-hover:hover td {
        color: #191919 !important; }

.table-quick-reply-list button svg {
  position: relative !important;
  right: unset !important;
  top: -2px !important;
  margin-right: 5px; }

.table-quick-reply-list .col-action-button-wrapper {
  position: absolute;
  right: 7px;
  top: 50%;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%);
  /* display: none; */ }

.table-quick-reply-list .single-remove-button {
  margin-left: 16px;
  color: #ff3f57; }

.table-quick-reply-list .single-remove-button:hover {
  color: #e83b51; }

.table-quick-reply-list .shortcut-badge {
  font-size: 12px;
  color: rgba(25, 25, 25, 0.6);
  letter-spacing: -0.4px;
  background: #f3f3f3;
  line-height: 1;
  border-radius: 4px;
  padding: 1px 8px 3px; }

.table-quick-reply-list .col_type {
  white-space: nowrap; }
  .table-quick-reply-list .col_type img {
    position: relative;
    top: -1px;
    margin-right: 4px;
    right: unset !important;
    width: 16px; }
    .table-quick-reply-list .col_type img.img-waba {
      width: 18px; }
  .table-quick-reply-list .col_type svg {
    color: rgba(25, 25, 25, 0.4);
    position: relative;
    top: -1px;
    margin: 0 3px 0 0;
    font-size: 16px;
    right: unset !important; }

.table-quick-reply-list td {
  word-break: break-word; }

.table-quick-reply-list .col-action-button-wrapper {
  display: block !important;
  position: relative;
  top: 0;
  right: unset;
  -webkit-transform: unset;
          transform: unset; }

.table-quick-reply-list .col-action-button-wrapper .view-agent {
  color: #ff7d00;
  letter-spacing: -0.5px; }

.table-quick-reply-list .col-action-button-wrapper .view-agent:hover {
  color: var(--main-darker-orange); }

.table-quick-reply-list .col-action-button-wrapper img.view-agent {
  margin-right: 3px;
  position: relative;
  top: -1px; }

.member-tool-wrapper {
  width: 460px; }

.table-quick-reply-list .col_action {
  width: 200px;
  text-align: right;
  white-space: nowrap; }

.quick-reply-page-wrapper {
  margin: 32px 0 0; }
  .quick-reply-page-wrapper .nav-item {
    cursor: pointer;
    color: rgba(25, 25, 25, 0.4);
    font-size: 16px;
    letter-spacing: -0.5px;
    padding: 0 0 12px 0;
    margin-right: 32px; }
    .quick-reply-page-wrapper .nav-item a {
      padding: 0;
      border: none; }
    .quick-reply-page-wrapper .nav-item :hover {
      color: #ff7d00; }
    .quick-reply-page-wrapper .nav-item.active-setup-tab {
      color: #ff7d00;
      border-bottom: solid 4px #ff7d00; }
  .quick-reply-page-wrapper .tab-content {
    margin: 32px 0 0; }

.modal-add-quick-reply .modal-content {
  width: 612px; }
  .modal-add-quick-reply .modal-content .modal-body {
    position: relative;
    padding: 32px; }
    .modal-add-quick-reply .modal-content .modal-body .close-modal-quick-reply {
      position: absolute;
      right: 32px;
      top: 35px;
      font-size: 24px;
      color: #191919;
      cursor: pointer; }
    .modal-add-quick-reply .modal-content .modal-body .add-quick-reply-title {
      font-size: 20px;
      letter-spacing: -0.6px;
      margin: 0 0 20px 0;
      display: block; }
    .modal-add-quick-reply .modal-content .modal-body label {
      font-size: 16px;
      letter-spacing: -0.5px;
      margin: 0 0 8px 0;
      display: block; }
      .modal-add-quick-reply .modal-content .modal-body label.button-type {
        margin: 12px 0 8px; }
    .modal-add-quick-reply .modal-content .modal-body img {
      width: 28px;
      height: 28px; }
    .modal-add-quick-reply .modal-content .modal-body button {
      width: 100%;
      margin: 20px 0 0; }
    .modal-add-quick-reply .modal-content .modal-body .add-quick-reply-selection {
      border: solid 1px #dcdcdc;
      cursor: pointer;
      font-size: 14px;
      letter-spacing: -0.2px;
      color: #191919;
      border-radius: 8px;
      width: calc(50% - 4px);
      margin-right: 8px;
      display: inline-block;
      text-align: left;
      padding: 10px;
      margin-bottom: 8px;
      text-align: center; }
      .modal-add-quick-reply .modal-content .modal-body .add-quick-reply-selection.no-margin-right {
        margin-right: 0; }
      .modal-add-quick-reply .modal-content .modal-body .add-quick-reply-selection img {
        position: relative;
        top: 0px;
        margin-right: 4px; }
      .modal-add-quick-reply .modal-content .modal-body .add-quick-reply-selection svg {
        position: relative;
        top: -1px;
        margin-right: 4px;
        color: rgba(25, 25, 25, 0.4); }
      .modal-add-quick-reply .modal-content .modal-body .add-quick-reply-selection.active-add-quick-reply-selection {
        color: #ff7d00;
        border: solid 1px #ff7d00; }
        .modal-add-quick-reply .modal-content .modal-body .add-quick-reply-selection.active-add-quick-reply-selection svg {
          color: #ff7d00; }
    .modal-add-quick-reply .modal-content .modal-body .add-quick-reply-selection-button-type {
      border: solid 1px #dcdcdc;
      cursor: pointer;
      font-size: 14px;
      letter-spacing: -0.2px;
      color: #191919;
      border-radius: 8px;
      width: calc(50% - 4px);
      margin-right: 8px;
      display: inline-block;
      text-align: left;
      padding: 10px;
      margin-bottom: 8px;
      text-align: center;
      height: 49px;
      vertical-align: top;
      line-height: 27px; }
      .modal-add-quick-reply .modal-content .modal-body .add-quick-reply-selection-button-type.no-margin-right {
        margin-right: 0; }
      .modal-add-quick-reply .modal-content .modal-body .add-quick-reply-selection-button-type img {
        position: relative;
        top: -1px;
        margin-right: 4px;
        width: 18px; }
      .modal-add-quick-reply .modal-content .modal-body .add-quick-reply-selection-button-type svg {
        position: relative;
        top: -1px;
        margin-right: 4px;
        color: rgba(25, 25, 25, 0.4); }
        .modal-add-quick-reply .modal-content .modal-body .add-quick-reply-selection-button-type svg.orange-button-type {
          color: var(--main-dark-orange); }
      .modal-add-quick-reply .modal-content .modal-body .add-quick-reply-selection-button-type.active-add-quick-reply-selection-button-type {
        color: #ff7d00;
        border: solid 1px #ff7d00; }
        .modal-add-quick-reply .modal-content .modal-body .add-quick-reply-selection-button-type.active-add-quick-reply-selection-button-type svg {
          color: #ff7d00; }

.form-add-quick-reply {
  position: relative; }
  .form-add-quick-reply .form-title-wrapper {
    padding-bottom: 16px;
    border-bottom: solid 1px rgba(25, 25, 25, 0.1);
    color: #191919;
    font-size: 20px;
    letter-spacing: -0.6px;
    position: relative; }
    .form-add-quick-reply .form-title-wrapper button {
      position: absolute;
      top: 0;
      right: 0; }
  .form-add-quick-reply #quick-reply-file {
    display: none; }
  .form-add-quick-reply .upload-media-button {
    width: unset;
    margin: 0 !important; }
  .form-add-quick-reply .media-action-wrapper button {
    border-radius: 4px;
    border: none;
    margin-right: 8px;
    width: unset !important;
    font-size: 12px;
    letter-spacing: -0.4px;
    padding: 0 12px;
    margin-top: 0 !important;
    margin-bottom: 8px !important;
    height: 22px;
    line-height: 12px; }
    .form-add-quick-reply .media-action-wrapper button svg {
      position: relative;
      margin-right: 4px;
      top: -1px; }
  .form-add-quick-reply .file-wrapper {
    white-space: nowrap;
    font-size: 14px;
    letter-spacing: -0.2px;
    position: relative;
    width: 100%;
    line-height: 1; }
    .form-add-quick-reply .file-wrapper p {
      max-width: calc(100% - 24px);
      overflow: hidden;
      text-overflow: ellipsis;
      display: inline-block;
      margin: 0;
      padding: 10px 4px 10px 18px; }
    .form-add-quick-reply .file-wrapper svg {
      position: absolute;
      left: 0;
      top: 10px;
      color: var(--main-dark-orange); }
    .form-add-quick-reply .file-wrapper .lds-ring {
      top: -9px;
      width: 16px;
      height: 16px; }
      .form-add-quick-reply .file-wrapper .lds-ring div {
        width: 16px;
        height: 16px;
        margin: 0; }
  .form-add-quick-reply .media-image-wrapper {
    width: 48px;
    height: 48px;
    display: block;
    position: relative;
    border-radius: 6px;
    overflow: hidden;
    border: solid 1px rgba(25, 25, 25, 0.1); }
    .form-add-quick-reply .media-image-wrapper img, .form-add-quick-reply .media-image-wrapper video {
      max-width: 100%;
      max-height: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%); }
    .form-add-quick-reply .media-image-wrapper .lds-ring-wrapper {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 50%;
      top: 50%;
      -webkit-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%);
      background: rgba(25, 25, 25, 0.2); }
      .form-add-quick-reply .media-image-wrapper .lds-ring-wrapper .lds-ring {
        width: 38px;
        height: 38px;
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%); }
        .form-add-quick-reply .media-image-wrapper .lds-ring-wrapper .lds-ring div {
          border: solid 5px #fff;
          width: 38px;
          height: 38px;
          border-color: #fff transparent transparent transparent; }
  .form-add-quick-reply .form-content-wrapper label {
    display: block;
    color: #191919;
    font-size: 14px;
    margin-top: 22px;
    position: relative; }
    .form-add-quick-reply .form-content-wrapper label span {
      color: rgba(25, 25, 25, 0.4);
      position: absolute;
      top: 0;
      right: 0; }
    .form-add-quick-reply .form-content-wrapper label.pos-relative {
      width: 347px; }
  .form-add-quick-reply .form-content-wrapper input[type="text"] {
    background: white;
    border-radius: 8px;
    border: 1px solid rgba(25, 25, 25, 0.1);
    height: 48px;
    width: 348px;
    color: #191919;
    font-size: 16px;
    letter-spacing: -0.3px;
    width: 300px;
    padding: 12px 16px;
    margin-bottom: 6px; }
  .form-add-quick-reply .form-content-wrapper textarea {
    background: white;
    border-radius: 8px;
    border: 1px solid rgba(25, 25, 25, 0.1);
    width: 348px;
    color: #191919;
    font-size: 16px;
    letter-spacing: -0.3px;
    width: 100%;
    padding: 16px;
    resize: none;
    margin-bottom: 0; }
  .form-add-quick-reply .form-content-wrapper label {
    display: block !important; }
  .form-add-quick-reply .form-content-wrapper .role-radio-label {
    position: relative; }
  .form-add-quick-reply .form-content-wrapper .role-radio-label b {
    display: block;
    color: #191919;
    font-size: 14px;
    letter-spacing: -0.4px; }
  .form-add-quick-reply .form-content-wrapper .role-radio-label label {
    color: rgba(25, 25, 25, 0.8);
    font-size: 14px;
    letter-spacing: -0.2px; }
  .form-add-quick-reply .form-content-wrapper .custom-radio label {
    margin-top: 18px; }
  .form-add-quick-reply .form-content-wrapper button {
    margin-top: 22px; }
  .form-add-quick-reply .form-content-wrapper .orange-button .lds-ring div {
    border-color: #ffffff transparent transparent transparent;
    margin: 0; }
  .form-add-quick-reply .submit-add-quick-reply {
    position: absolute;
    top: -7px;
    right: 32px;
    width: 176px !important;
    padding: 7px 0 !important;
    margin-top: 32px !important; }
    .form-add-quick-reply .submit-add-quick-reply .lds-ring {
      top: 3px;
      right: unset; }
      .form-add-quick-reply .submit-add-quick-reply .lds-ring div {
        margin: 0;
        border-color: #ffffff transparent transparent transparent; }
  .form-add-quick-reply .input-info-wrapper {
    color: rgba(25, 25, 25, 0.6);
    font-size: 12px;
    letter-spacing: -0.2px;
    line-height: 16px;
    width: 450px;
    margin-top: 0; }
  .form-add-quick-reply .quick-reply-suffix {
    height: 48px;
    padding: 12px 16px;
    margin-left: 8px; }
  .form-add-quick-reply .quick-reply-prefix {
    height: 48px;
    padding: 12px 16px;
    margin-right: 8px; }

.form-add-quick-reply-interactive-button-personal .line-through {
  margin: 28px 0 0; }

.form-add-quick-reply-interactive-button-personal .message-content {
  width: 100%;
  position: relative;
  padding: 22px 0 12px; }
  .form-add-quick-reply-interactive-button-personal .message-content p {
    margin: 0;
    font-size: 16px;
    letter-spacing: -0.5px; }
  .form-add-quick-reply-interactive-button-personal .message-content button {
    position: absolute;
    right: 0;
    top: -12px;
    width: unset; }

.form-add-quick-reply-interactive-button-personal .input-outer-wrapper {
  width: 570px;
  max-width: 100%; }
  .form-add-quick-reply-interactive-button-personal .input-outer-wrapper label {
    position: relative;
    width: 100% !important; }
  .form-add-quick-reply-interactive-button-personal .input-outer-wrapper textarea, .form-add-quick-reply-interactive-button-personal .input-outer-wrapper input[type="text"] {
    position: relative;
    width: 100%; }
  .form-add-quick-reply-interactive-button-personal .input-outer-wrapper .text-optional {
    position: relative;
    top: unset;
    right: unset; }
  .form-add-quick-reply-interactive-button-personal .input-outer-wrapper textarea {
    height: 125px;
    padding: 12px; }
  .form-add-quick-reply-interactive-button-personal .input-outer-wrapper .input-shortcut {
    width: calc(100% - 119px) !important; }

.form-add-quick-reply-interactive-button-personal .button-text-wrapper-form {
  border-radius: 8px;
  border: solid 1px rgba(25, 25, 25, 0.1);
  background: #f3f3f3;
  padding: 12px;
  margin: 8px 0 0;
  width: 570px; }
  .form-add-quick-reply-interactive-button-personal .button-text-wrapper-form input[type="text"] {
    width: 100%; }
  .form-add-quick-reply-interactive-button-personal .button-text-wrapper-form.button-text-wrapper-form-1 {
    margin: 24px 0 0 !important; }
  .form-add-quick-reply-interactive-button-personal .button-text-wrapper-form label {
    margin-top: 0; }
  .form-add-quick-reply-interactive-button-personal .button-text-wrapper-form .remove-button-text {
    position: relative;
    top: -1px;
    cursor: pointer;
    margin-left: 4px;
    color: #ff3f57 !important; }

.form-add-quick-reply-interactive-button-personal .add-another-button {
  margin: 16px 0 0;
  padding: 0 0 12px 0;
  font-size: 14px;
  cursor: pointer;
  letter-spacing: -0.5px;
  color: var(--main-dark-orange); }
  .form-add-quick-reply-interactive-button-personal .add-another-button svg {
    position: relative;
    margin-right: 4px;
    top: -1px; }
  .form-add-quick-reply-interactive-button-personal .add-another-button:hover {
    color: var(--main-darker-orange); }
    .form-add-quick-reply-interactive-button-personal .add-another-button:hover svg {
      color: var(--main-darker-orange); }

.header-type-quick-reply-outer-wrapper button {
  margin-top: 12px !important; }

.header-type-quick-reply-outer-wrapper .label-header-type-quick-reply {
  width: 570px; }

.header-type-quick-reply-outer-wrapper input[type="text"] {
  width: 570px !important; }

.header-type-quick-reply-outer-wrapper .header-type-quick-reply-wrapper {
  width: 100%;
  position: relative;
  display: block; }
  .header-type-quick-reply-outer-wrapper .header-type-quick-reply-wrapper .message-type-selection-selection {
    vertical-align: top;
    border-radius: 8px;
    border: solid 1px #dcdcdc;
    display: inline-block;
    position: relative;
    width: calc(25% - 6px);
    margin-right: 8px;
    font-size: 14px;
    letter-spacing: -0.2px;
    padding: 14px 14px 14px 49px;
    cursor: pointer;
    -webkit-transition: ease-in-out .1s;
    transition: ease-in-out .1s; }
    .header-type-quick-reply-outer-wrapper .header-type-quick-reply-wrapper .message-type-selection-selection:nth-last-child(1) {
      margin-right: 0; }
    .header-type-quick-reply-outer-wrapper .header-type-quick-reply-wrapper .message-type-selection-selection.active-message-type-selection-selection {
      border-color: #ff7d00; }
    .header-type-quick-reply-outer-wrapper .header-type-quick-reply-wrapper .message-type-selection-selection:hover {
      border-color: #ff7d00; }
  .header-type-quick-reply-outer-wrapper .header-type-quick-reply-wrapper .message-type-selection-icon-wrapper {
    width: 28px;
    height: 28px;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 14px;
    -webkit-transform: translate(0, -50%);
            transform: translate(0, -50%); }
    .header-type-quick-reply-outer-wrapper .header-type-quick-reply-wrapper .message-type-selection-icon-wrapper img {
      width: 25px;
      height: 25px; }
    .header-type-quick-reply-outer-wrapper .header-type-quick-reply-wrapper .message-type-selection-icon-wrapper img {
      position: absolute;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%); }

.header-type-quick-reply-outer-wrapper .header-content {
  font-size: 14px;
  letter-spacing: -0.4px;
  display: block;
  margin-top: 12px; }

.header-type-quick-reply-outer-wrapper .button-upload-document {
  width: 220px;
  margin: 12px 0 0; }

.header-type-quick-reply-outer-wrapper .main-button-22 {
  width: unset !important;
  padding: 0 8px 0 12px !important;
  margin: 12px 8px 0  0; }

.header-type-quick-reply-outer-wrapper input[type="file"] {
  display: none; }

.header-type-quick-reply-outer-wrapper .media-value-wrapper {
  border-radius: 8px;
  margin: 8px 0 0;
  width: 48px;
  height: 48px;
  background: #ebebeb;
  position: relative;
  overflow: hidden; }
  .header-type-quick-reply-outer-wrapper .media-value-wrapper img, .header-type-quick-reply-outer-wrapper .media-value-wrapper video {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    max-width: 100%;
    max-height: 100px; }
  .header-type-quick-reply-outer-wrapper .media-value-wrapper .media-value-loading-wrapper {
    position: absolute;
    border-radius: 8px;
    width: 48px;
    height: 48px;
    border-radius: 8px;
    top: 0;
    left: 0;
    background: rgba(25, 25, 25, 0.1);
    display: none;
    cursor: pointer; }
    .header-type-quick-reply-outer-wrapper .media-value-wrapper .media-value-loading-wrapper svg {
      font-size: 20px;
      color: #fff;
      position: absolute;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%); }
    .header-type-quick-reply-outer-wrapper .media-value-wrapper .media-value-loading-wrapper .lds-ring {
      width: 38px;
      height: 38px;
      position: absolute;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%); }
      .header-type-quick-reply-outer-wrapper .media-value-wrapper .media-value-loading-wrapper .lds-ring div {
        margin: 0;
        width: 38px;
        height: 38px;
        border: solid 3px #fff;
        border-color: #fff transparent transparent transparent; }
  .header-type-quick-reply-outer-wrapper .media-value-wrapper:hover .media-value-loading-wrapper {
    display: block; }

.header-type-quick-reply-outer-wrapper .document-value-wrapper {
  width: 100%;
  margin-top: 20px;
  font-size: 14px;
  white-space: nowrap;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis; }
  .header-type-quick-reply-outer-wrapper .document-value-wrapper .lds-ring {
    margin-left: 8px;
    position: relative;
    top: 4px; }
    .header-type-quick-reply-outer-wrapper .document-value-wrapper .lds-ring div {
      margin: 0; }
  .header-type-quick-reply-outer-wrapper .document-value-wrapper svg {
    position: relative;
    top: -1px;
    margin-right: 4px;
    font-size: 14px;
    color: var(--main-dark-orange); }

.modal-new-interactivePreview-message .button-whatsapp-interactive-preview {
  width: 100%;
  height: 35px;
  text-align: center;
  color: #0096DE;
  padding: 9px 8px 0;
  border-radius: 8px;
  margin: 4px 0 0;
  font-size: 16px;
  background: #fff;
  line-height: 1; }
  .modal-new-interactivePreview-message .button-whatsapp-interactive-preview svg {
    position: relative;
    margin-right: 4px;
    top: 1px;
    font-size: 15px;
    vertical-align: top; }
  .modal-new-interactivePreview-message .button-whatsapp-interactive-preview.with-icon {
    padding: 8px 8px 0; }

.modal-new-interactivePreview-message .preview-message-body {
  margin-top: 16px; }

.modal-new-interactivePreview-message .interactive-section-list {
  margin: 24px 0 0; }
  .modal-new-interactivePreview-message .interactive-section-list .table-title {
    font-size: 16px;
    letter-spacing: -0.5px;
    margin: 12px 0 8px 0; }
  .modal-new-interactivePreview-message .interactive-section-list table {
    width: 100%;
    border-collapse: collapse; }
  .modal-new-interactivePreview-message .interactive-section-list thead tr {
    border-bottom: solid 1px #dcdcdc; }
  .modal-new-interactivePreview-message .interactive-section-list thead td {
    font-size: 14px;
    letter-spacing: -0.4px;
    padding: 8px; }
    .modal-new-interactivePreview-message .interactive-section-list thead td:nth-child(1) {
      width: 50px; }
    .modal-new-interactivePreview-message .interactive-section-list thead td:nth-child(2) {
      width: calc(50%); }
    .modal-new-interactivePreview-message .interactive-section-list thead td:nth-child(3) {
      width: calc(50%); }
  .modal-new-interactivePreview-message .interactive-section-list tbody tr:nth-child(even) {
    background: #f3f3f3; }
  .modal-new-interactivePreview-message .interactive-section-list tbody td {
    font-size: 14px;
    letter-spacing: -0.2px;
    padding: 8px;
    word-break: break-word;
    vertical-align: top; }

.modal-new-interactivePreview-message .form-label label {
  width: 100%;
  position: relative; }
  .modal-new-interactivePreview-message .form-label label .grey-font {
    position: absolute;
    top: 1px;
    right: 0; }
  .modal-new-interactivePreview-message .form-label label b {
    font-size: 16px;
    letter-spacing: -0.3px; }

.modal-new-interactivePreview-message .form-label .form-value {
  font-size: 16px;
  letter-spacing: -0.3px;
  margin-bottom: 0;
  color: rgba(25, 25, 25, 0.8); }

.modal-new-interactivePreview-message input:disabled {
  background-color: rgba(25, 25, 25, 0.1) !important; }

.modal-new-interactivePreview-message input::-webkit-input-placeholder {
  color: rgba(25, 25, 25, 0.4); }

.modal-new-interactivePreview-message input::-ms-input-placeholder {
  color: rgba(25, 25, 25, 0.4); }

.modal-new-interactivePreview-message input::placeholder {
  color: rgba(25, 25, 25, 0.4); }

.modal-new-interactivePreview-message .disabled label,
.modal-new-interactivePreview-message .disabled b,
.modal-new-interactivePreview-message .disabled input {
  color: rgba(25, 25, 25, 0.4); }

.modal-new-interactivePreview-message {
  position: fixed;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100%;
  background: rgba(25, 25, 25, 0.8);
  visibility: hidden;
  opacity: 0;
  right: 0;
  -webkit-transition: visibility 0.3s, opacity 0.5s ease-in-out;
  transition: visibility 0.3s, opacity 0.5s ease-in-out;
  z-index: 1049; }
  .modal-new-interactivePreview-message.active-modal-new-interactivePreview-message {
    /* right: 0; */
    opacity: 1;
    visibility: visible;
    -webkit-transition: visibility 0s, opacity 0.5s ease-in-out;
    transition: visibility 0s, opacity 0.5s ease-in-out; }
  .modal-new-interactivePreview-message .modal-body {
    position: absolute;
    top: 8px;
    right: -100%;
    left: unset;
    -webkit-transform: unset;
            transform: unset;
    height: calc(100% - 8px);
    width: 700px;
    display: inline-block;
    border-radius: 8px 0 0 0;
    background: #ffffff;
    padding: 0;
    max-height: calc(100% - 8px);
    -webkit-transition: ease-in-out 0.4s;
    transition: ease-in-out 0.4s; }
    .modal-new-interactivePreview-message .modal-body.action-modal-body-new-interactivePreview-message {
      right: 0; }
    .modal-new-interactivePreview-message .modal-body .close-modal-interactivePreview {
      border-radius: 50%;
      width: 48px;
      height: 48px;
      background: #ffffff;
      color: #757575;
      position: absolute;
      left: -65px;
      top: 0;
      text-align: center;
      cursor: pointer; }
      .modal-new-interactivePreview-message .modal-body .close-modal-interactivePreview:hover {
        background: #f9f9f9; }
      .modal-new-interactivePreview-message .modal-body .close-modal-interactivePreview svg {
        position: relative;
        font-size: 25px;
        top: 11px; }
    .modal-new-interactivePreview-message .modal-body .new-interactivePreview-message-wrapper {
      overflow: hidden; }
      .modal-new-interactivePreview-message .modal-body .new-interactivePreview-message-wrapper .comp-back-button {
        margin: 0 0 24px; }
      .modal-new-interactivePreview-message .modal-body .new-interactivePreview-message-wrapper label {
        display: inline-block !important; }
      .modal-new-interactivePreview-message .modal-body .new-interactivePreview-message-wrapper .new-interactivePreview-message-container {
        padding: 32px 32px 12px 32px; }
        .modal-new-interactivePreview-message .modal-body .new-interactivePreview-message-wrapper .new-interactivePreview-message-container .new-interactivePreview-message-title {
          color: #191919;
          position: relative; }
          .modal-new-interactivePreview-message .modal-body .new-interactivePreview-message-wrapper .new-interactivePreview-message-container .new-interactivePreview-message-title.title-preivew-quick-reply b {
            margin-left: 30px; }
          .modal-new-interactivePreview-message .modal-body .new-interactivePreview-message-wrapper .new-interactivePreview-message-container .new-interactivePreview-message-title img {
            width: 24px;
            position: relative;
            top: -7px;
            margin-right: 4px; }
          .modal-new-interactivePreview-message .modal-body .new-interactivePreview-message-wrapper .new-interactivePreview-message-container .new-interactivePreview-message-title .eye-modal-preview-quick-reply {
            position: absolute;
            left: 0;
            top: 3px; }
          .modal-new-interactivePreview-message .modal-body .new-interactivePreview-message-wrapper .new-interactivePreview-message-container .new-interactivePreview-message-title b {
            font-size: 20px;
            letter-spacing: -0.6px; }
          .modal-new-interactivePreview-message .modal-body .new-interactivePreview-message-wrapper .new-interactivePreview-message-container .new-interactivePreview-message-title svg {
            font-size: 24px;
            margin: 0 4px 0 0;
            position: relative;
            top: -7px; }
          .modal-new-interactivePreview-message .modal-body .new-interactivePreview-message-wrapper .new-interactivePreview-message-container .new-interactivePreview-message-title p {
            margin: 2px 0 0;
            color: rgba(25, 25, 25, 0.6);
            font-size: 14px;
            letter-spacing: -0.2px; }
        .modal-new-interactivePreview-message .modal-body .new-interactivePreview-message-wrapper .new-interactivePreview-message-container label span {
          letter-spacing: -0.2px;
          font-size: 14px;
          color: rgba(25, 25, 25, 0.6); }
        .modal-new-interactivePreview-message .modal-body .new-interactivePreview-message-wrapper .new-interactivePreview-message-container textarea {
          width: 100%;
          height: 152px; }
        .modal-new-interactivePreview-message .modal-body .new-interactivePreview-message-wrapper .new-interactivePreview-message-container input[type="text"] {
          width: 100%; }
        .modal-new-interactivePreview-message .modal-body .new-interactivePreview-message-wrapper .new-interactivePreview-message-container input[type="file"] {
          display: none; }
    .modal-new-interactivePreview-message .modal-body .whatsapp-preview-interactive {
      width: 100%;
      position: relative;
      border-radius: 4px;
      background: #EBE6E1;
      padding: 32px 16px 24px 24px;
      text-align: center; }
      .modal-new-interactivePreview-message .modal-body .whatsapp-preview-interactive .wrapper-quick-reply div {
        margin: 0 !important; }
      .modal-new-interactivePreview-message .modal-body .whatsapp-preview-interactive .wrapper-quick-reply.wrapper-quick-reply-more div:nth-child(1) {
        width: calc(50% - 2px);
        display: inline-block;
        margin-right: 4px !important; }
      .modal-new-interactivePreview-message .modal-body .whatsapp-preview-interactive .wrapper-quick-reply.wrapper-quick-reply-more div:nth-child(2) {
        width: calc(50% - 2px);
        display: inline-block;
        margin-right: 0 !important; }
      .modal-new-interactivePreview-message .modal-body .whatsapp-preview-interactive .wrapper-call-to-action .call-to-action-button-wrap {
        text-align: center;
        border-top: solid 1px rgba(25, 25, 25, 0.1);
        color: #0096DE;
        height: 36px;
        margin: 0 !important;
        width: calc(100% + 20px);
        padding: 5px 20px 0;
        position: relative;
        left: -10px; }
        .modal-new-interactivePreview-message .modal-body .whatsapp-preview-interactive .wrapper-call-to-action .call-to-action-button-wrap svg {
          position: relative;
          margin-right: 6px;
          top: -1px; }
        .modal-new-interactivePreview-message .modal-body .whatsapp-preview-interactive .wrapper-call-to-action .call-to-action-button-wrap:nth-child(1) {
          margin-top: 8px !important; }
        .modal-new-interactivePreview-message .modal-body .whatsapp-preview-interactive .wrapper-call-to-action .call-to-action-button-wrap:nth-last-child(1) {
          height: 26px; }
      .modal-new-interactivePreview-message .modal-body .whatsapp-preview-interactive .whatsapp-link {
        color: #1391E9; }
      .modal-new-interactivePreview-message .modal-body .whatsapp-preview-interactive .whatsapp-info-wrapper {
        width: 74px;
        height: 24px;
        display: inline-block;
        background: #E4F2F9;
        border-radius: 4px;
        box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2);
        margin-bottom: 16px; }
        .modal-new-interactivePreview-message .modal-body .whatsapp-preview-interactive .whatsapp-info-wrapper .whatsapp-info-content {
          width: 50px;
          height: 14px;
          border-radius: 2px;
          display: inline-block;
          background: #D0DCE3;
          margin-top: 5px; }
      .modal-new-interactivePreview-message .modal-body .whatsapp-preview-interactive .whatsapp-warning {
        width: 100%;
        font-size: 12px;
        letter-spacing: -0.4px;
        color: rgba(25, 25, 25, 0.6); }
        .modal-new-interactivePreview-message .modal-body .whatsapp-preview-interactive .whatsapp-warning svg {
          position: relative;
          top: -1px;
          margin-right: 4px; }
        .modal-new-interactivePreview-message .modal-body .whatsapp-preview-interactive .whatsapp-warning .whatsapp-warning-content {
          padding: 2px 13px;
          border-radius: 4px;
          box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2);
          background: #ffffff;
          display: inline-block; }
      .modal-new-interactivePreview-message .modal-body .whatsapp-preview-interactive .whatsapp-chat-bubble {
        word-break: break-word;
        background: #ffffff;
        width: 100%;
        padding: 10px;
        border-radius: 0 8px 8px 8px;
        box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2);
        position: relative;
        text-align: left;
        font-family: SFPro;
        overflow: hidden; }
        .modal-new-interactivePreview-message .modal-body .whatsapp-preview-interactive .whatsapp-chat-bubble .media-wrapper {
          text-align: center;
          width: 100%;
          position: relative;
          border-radius: 8px;
          overflow: hidden;
          margin: 0 0 2px 0; }
        .modal-new-interactivePreview-message .modal-body .whatsapp-preview-interactive .whatsapp-chat-bubble .image-media-preview {
          max-width: 100%;
          max-height: 100%; }
        .modal-new-interactivePreview-message .modal-body .whatsapp-preview-interactive .whatsapp-chat-bubble .video-media-preview {
          max-width: 100%;
          max-height: 100%; }
        .modal-new-interactivePreview-message .modal-body .whatsapp-preview-interactive .whatsapp-chat-bubble .black-layer {
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%; }
        .modal-new-interactivePreview-message .modal-body .whatsapp-preview-interactive .whatsapp-chat-bubble svg {
          font-size: 24px;
          color: #fff;
          position: absolute;
          top: 50%;
          left: 50%;
          -webkit-transform: translate(-50%, -50%);
                  transform: translate(-50%, -50%); }
        .modal-new-interactivePreview-message .modal-body .whatsapp-preview-interactive .whatsapp-chat-bubble::before {
          position: absolute;
          background: transparent;
          width: 13px;
          height: 8px;
          content: "";
          border-top: solid 8px transparent;
          border-bottom: solid 8px transparent;
          border-left: solid 8px #ffffff;
          border-right: solid 8px transparent;
          -webkit-transform: rotate(-45deg);
                  transform: rotate(-45deg);
          top: -8px;
          left: -7px; }
        .modal-new-interactivePreview-message .modal-body .whatsapp-preview-interactive .whatsapp-chat-bubble.no-corner {
          text-align: center;
          border-radius: 8px !important;
          color: #0096de !important;
          margin-top: 4px !important;
          vertical-align: top; }
          .modal-new-interactivePreview-message .modal-body .whatsapp-preview-interactive .whatsapp-chat-bubble.no-corner svg {
            color: #0096de !important;
            position: relative;
            top: -2px;
            font-size: 20px; }
          .modal-new-interactivePreview-message .modal-body .whatsapp-preview-interactive .whatsapp-chat-bubble.no-corner b {
            margin-left: 4px;
            font-size: 16px; }
          .modal-new-interactivePreview-message .modal-body .whatsapp-preview-interactive .whatsapp-chat-bubble.no-corner::before {
            display: none; }
        .modal-new-interactivePreview-message .modal-body .whatsapp-preview-interactive .whatsapp-chat-bubble b {
          font-family: SFPro !important;
          font-weight: bold !important; }
        .modal-new-interactivePreview-message .modal-body .whatsapp-preview-interactive .whatsapp-chat-bubble .whatsapp-chat-bubble-title {
          font-size: 16px;
          letter-spacing: -0.3px;
          line-height: 24px;
          margin: 0; }
        .modal-new-interactivePreview-message .modal-body .whatsapp-preview-interactive .whatsapp-chat-bubble .whatsapp-chat-bubble-description {
          font-size: 16px;
          letter-spacing: -0.3px;
          line-height: 24px;
          margin: 0; }
        .modal-new-interactivePreview-message .modal-body .whatsapp-preview-interactive .whatsapp-chat-bubble .whatsapp-chat-bubble-footer {
          font-size: 16px;
          letter-spacing: -0.3px;
          line-height: 24px;
          color: rgba(25, 25, 25, 0.6);
          margin: 0; }
        .modal-new-interactivePreview-message .modal-body .whatsapp-preview-interactive .whatsapp-chat-bubble .whatsapp-chat-bubble-status {
          text-align: right;
          width: 100%;
          font-size: 12px;
          color: rgba(0, 0, 0, 0.45);
          margin: 0; }
          .modal-new-interactivePreview-message .modal-body .whatsapp-preview-interactive .whatsapp-chat-bubble .whatsapp-chat-bubble-status img {
            position: relative;
            margin-left: 3px; }
      .modal-new-interactivePreview-message .modal-body .whatsapp-preview-interactive .static-media {
        position: relative;
        font-size: 0; }
        .modal-new-interactivePreview-message .modal-body .whatsapp-preview-interactive .static-media .static-media-document {
          border-radius: 8px;
          font-size: 16px;
          letter-spacing: -0.3px;
          padding: 12px 11px 12px 36px;
          position: relative;
          width: 100%;
          margin-bottom: 6px;
          background: #f3f3f3;
          color: rgba(25, 25, 25, 0.6); }
          .modal-new-interactivePreview-message .modal-body .whatsapp-preview-interactive .static-media .static-media-document img {
            position: absolute;
            top: 50%;
            -webkit-transform: translate(0, -50%);
                    transform: translate(0, -50%);
            left: 11px; }
        .modal-new-interactivePreview-message .modal-body .whatsapp-preview-interactive .static-media img.static-media-image-asset {
          width: 100%;
          border-radius: 8px; }
        .modal-new-interactivePreview-message .modal-body .whatsapp-preview-interactive .static-media video.static-media-video-asset {
          width: 100%;
          border-radius: 8px; }
        .modal-new-interactivePreview-message .modal-body .whatsapp-preview-interactive .static-media .static-media-image {
          border-radius: 12px;
          font-size: 14px;
          letter-spacing: -0.4px;
          padding: 82px 11px;
          position: relative;
          width: 100%;
          margin-bottom: 6px;
          background: #9b9b9b;
          color: #ffffff;
          text-align: center; }
          .modal-new-interactivePreview-message .modal-body .whatsapp-preview-interactive .static-media .static-media-image svg {
            font-size: 26px;
            margin-bottom: 4px; }
        .modal-new-interactivePreview-message .modal-body .whatsapp-preview-interactive .static-media .static-media-video {
          border-radius: 12px;
          font-size: 14px;
          letter-spacing: -0.4px;
          padding: 82px 11px;
          position: relative;
          width: 100%;
          margin-bottom: 6px;
          background: #9b9b9b;
          color: #ffffff;
          text-align: center; }
          .modal-new-interactivePreview-message .modal-body .whatsapp-preview-interactive .static-media .static-media-video svg {
            font-size: 26px;
            margin-bottom: 4px; }
        .modal-new-interactivePreview-message .modal-body .whatsapp-preview-interactive .static-media .static-media-video-file-wrapper {
          background: rgba(0, 0, 0, 0.35);
          position: absolute;
          top: 50%;
          left: 50%;
          -webkit-transform: translate(-50%, -50%);
                  transform: translate(-50%, -50%);
          width: 100%;
          height: 100%;
          border-radius: 8px;
          font-size: 0; }
          .modal-new-interactivePreview-message .modal-body .whatsapp-preview-interactive .static-media .static-media-video-file-wrapper svg {
            color: #ffffff;
            position: absolute;
            top: 50%;
            left: 50%;
            -webkit-transform: translate(-50%, -50%);
                    transform: translate(-50%, -50%);
            font-size: 32px; }
      .modal-new-interactivePreview-message .modal-body .whatsapp-preview-interactive .dynamic-media .dynamic-media-document {
        border-radius: 8px;
        font-size: 16px;
        letter-spacing: -0.3px;
        padding: 12px 11px 12px 36px;
        position: relative;
        width: 100%;
        margin-bottom: 6px;
        background: #f3f3f3; }
        .modal-new-interactivePreview-message .modal-body .whatsapp-preview-interactive .dynamic-media .dynamic-media-document img {
          position: absolute;
          top: 50%;
          -webkit-transform: translate(0, -50%);
                  transform: translate(0, -50%);
          left: 11px; }
      .modal-new-interactivePreview-message .modal-body .whatsapp-preview-interactive .dynamic-media .dynamic-media-image {
        border-radius: 12px;
        font-size: 14px;
        letter-spacing: -0.4px;
        padding: 82px 11px;
        position: relative;
        width: 100%;
        margin-bottom: 6px;
        background: #9b9b9b;
        color: #ffffff;
        text-align: center; }
        .modal-new-interactivePreview-message .modal-body .whatsapp-preview-interactive .dynamic-media .dynamic-media-image svg {
          font-size: 26px;
          margin-bottom: 4px; }
      .modal-new-interactivePreview-message .modal-body .whatsapp-preview-interactive .dynamic-media .dynamic-media-video {
        border-radius: 12px;
        font-size: 14px;
        letter-spacing: -0.4px;
        padding: 82px 11px;
        position: relative;
        width: 100%;
        margin-bottom: 6px;
        background: #9b9b9b;
        color: #ffffff;
        text-align: center; }
        .modal-new-interactivePreview-message .modal-body .whatsapp-preview-interactive .dynamic-media .dynamic-media-video svg {
          font-size: 26px;
          margin-bottom: 4px; }
    .modal-new-interactivePreview-message .modal-body .send-message-button {
      width: 100%;
      padding: 12px 24px;
      background: #ffffff;
      font-size: 16px;
      letter-spacing: -0.5px;
      position: absolute;
      bottom: 0;
      left: 0; }
      .modal-new-interactivePreview-message .modal-body .send-message-button button {
        width: 100%;
        height: 48px; }

.form-add-quick-reply-interactive-list-personal .line-through {
  margin: 28px 0 0; }

.form-add-quick-reply-interactive-list-personal .message-content {
  width: 100%;
  position: relative;
  padding: 22px 0 12px; }
  .form-add-quick-reply-interactive-list-personal .message-content p {
    margin: 0;
    font-size: 16px;
    letter-spacing: -0.5px; }
  .form-add-quick-reply-interactive-list-personal .message-content button {
    position: absolute;
    right: 0;
    top: -12px;
    width: unset; }

.form-add-quick-reply-interactive-list-personal .input-outer-wrapper {
  width: 570px;
  max-width: 100%; }
  .form-add-quick-reply-interactive-list-personal .input-outer-wrapper label {
    position: relative;
    width: 100% !important; }
  .form-add-quick-reply-interactive-list-personal .input-outer-wrapper textarea, .form-add-quick-reply-interactive-list-personal .input-outer-wrapper input[type="text"] {
    position: relative;
    width: 100%; }
  .form-add-quick-reply-interactive-list-personal .input-outer-wrapper .text-optional {
    position: relative;
    top: unset;
    right: unset; }
  .form-add-quick-reply-interactive-list-personal .input-outer-wrapper textarea {
    height: 125px;
    padding: 12px; }
  .form-add-quick-reply-interactive-list-personal .input-outer-wrapper .input-shortcut {
    width: calc(100% - 119px) !important; }

.form-add-quick-reply-interactive-list-personal .button-text-wrapper-form {
  border-radius: 8px;
  border: solid 1px rgba(25, 25, 25, 0.1);
  background: #f3f3f3;
  padding: 12px;
  margin: 8px 0 0;
  width: 570px;
  max-width: 100%; }
  .form-add-quick-reply-interactive-list-personal .button-text-wrapper-form .manage-rows {
    margin: 14px 0 0;
    font-size: 14px;
    letter-spacing: -0.5px;
    color: var(--main-dark-orange);
    cursor: pointer; }
    .form-add-quick-reply-interactive-list-personal .button-text-wrapper-form .manage-rows svg {
      position: relative;
      top: -1px;
      margin-right: 4px;
      font-size: 16px; }
    .form-add-quick-reply-interactive-list-personal .button-text-wrapper-form .manage-rows:hover {
      color: var(--main-darker-orange); }
  .form-add-quick-reply-interactive-list-personal .button-text-wrapper-form input[type="text"] {
    width: 100%; }
  .form-add-quick-reply-interactive-list-personal .button-text-wrapper-form.button-text-wrapper-form-1 {
    margin: 24px 0 0 !important; }
  .form-add-quick-reply-interactive-list-personal .button-text-wrapper-form label {
    margin-top: 0; }
  .form-add-quick-reply-interactive-list-personal .button-text-wrapper-form .remove-button-text {
    position: relative;
    top: -1px;
    cursor: pointer;
    margin-left: 4px;
    color: #ff3f57 !important; }

.form-add-quick-reply-interactive-list-personal .add-another-button {
  margin: 16px 0 0;
  padding: 0 0 12px 0;
  font-size: 14px;
  cursor: pointer;
  letter-spacing: -0.5px;
  color: var(--main-dark-orange); }
  .form-add-quick-reply-interactive-list-personal .add-another-button svg {
    position: relative;
    margin-right: 4px;
    top: -1px; }
  .form-add-quick-reply-interactive-list-personal .add-another-button:hover {
    color: var(--main-darker-orange); }
    .form-add-quick-reply-interactive-list-personal .add-another-button:hover svg {
      color: var(--main-darker-orange); }

.modal-quick-reply-manage-row {
  position: fixed;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100%;
  background: rgba(25, 25, 25, 0.8);
  visibility: hidden;
  opacity: 0;
  right: 0;
  -webkit-transition: visibility 0.3s, opacity 0.5s ease-in-out;
  transition: visibility 0.3s, opacity 0.5s ease-in-out;
  z-index: 4; }
  .modal-quick-reply-manage-row .manage-row-button-wrapper {
    width: 100%;
    height: 72px;
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 13px 24px;
    box-shadow: 0px 2px 20px 0px rgba(25, 25, 25, 0.18); }
    .modal-quick-reply-manage-row .manage-row-button-wrapper button {
      width: calc(50% - 2px);
      margin-right: 4px; }
      .modal-quick-reply-manage-row .manage-row-button-wrapper button:nth-child(2) {
        margin-right: 0 !important; }
  .modal-quick-reply-manage-row.active-modal-quick-reply-manage-row {
    /* right: 0; */
    opacity: 1;
    visibility: visible;
    -webkit-transition: visibility 0s, opacity 0.5s ease-in-out;
    transition: visibility 0s, opacity 0.5s ease-in-out; }
  .modal-quick-reply-manage-row .modal-body {
    position: absolute;
    top: 8px;
    right: -100%;
    left: unset;
    -webkit-transform: unset;
            transform: unset;
    height: calc(100% - 8px);
    width: 640px;
    display: inline-block;
    border-radius: 8px 0 0 0;
    background: #ffffff;
    padding: 0;
    max-height: calc(100% - 8px);
    -webkit-transition: ease-in-out 0.4s;
    transition: ease-in-out 0.4s; }
    .modal-quick-reply-manage-row .modal-body.action-modal-body-quick-reply-manage-row {
      right: 0; }
    .modal-quick-reply-manage-row .modal-body .close-modal-interactive {
      border-radius: 50%;
      width: 48px;
      height: 48px;
      background: #ffffff;
      color: #757575;
      position: absolute;
      left: -65px;
      top: 0;
      text-align: center;
      cursor: pointer; }
      .modal-quick-reply-manage-row .modal-body .close-modal-interactive:hover {
        background: #f9f9f9; }
      .modal-quick-reply-manage-row .modal-body .close-modal-interactive svg {
        position: relative;
        font-size: 25px;
        top: 11px; }
  .modal-quick-reply-manage-row .new-interactiveList-message-container {
    padding: 0 32px 32px;
    max-height: calc(100% - 72px);
    overflow: auto; }
  .modal-quick-reply-manage-row .new-interactiveList-message-title {
    margin: 32px 0 0; }
    .modal-quick-reply-manage-row .new-interactiveList-message-title svg {
      position: relative;
      top: 6px;
      vertical-align: top;
      margin-right: 4px;
      font-size: 19px; }
    .modal-quick-reply-manage-row .new-interactiveList-message-title b {
      font-size: 20px;
      letter-spacing: -0.2px; }
    .modal-quick-reply-manage-row .new-interactiveList-message-title p {
      margin: 4px 0 12px;
      font-size: 14px;
      letter-spacing: -0.6px;
      color: rgba(25, 25, 25, 0.6); }
  .modal-quick-reply-manage-row .button-text-wrapper-form {
    border-radius: 8px;
    border: solid 1px rgba(25, 25, 25, 0.1);
    background: #f3f3f3;
    padding: 12px;
    margin: 8px 0 0;
    font-size: 16px;
    letter-spacing: -0.5px;
    width: calc(100% - 6px); }
    .modal-quick-reply-manage-row .button-text-wrapper-form input[type="text"] {
      background: white;
      border-radius: 8px;
      border: 1px solid rgba(25, 25, 25, 0.1);
      height: 48px;
      color: #191919;
      font-size: 16px;
      letter-spacing: -0.3px;
      width: 316px;
      max-width: 100%;
      padding: 0 16px;
      width: 100%; }
    .modal-quick-reply-manage-row .button-text-wrapper-form label {
      width: 100%;
      position: relative; }
      .modal-quick-reply-manage-row .button-text-wrapper-form label .grey-font {
        position: absolute;
        right: 0;
        top: 0; }
      .modal-quick-reply-manage-row .button-text-wrapper-form label.row-description {
        height: 24px;
        margin: 8px 0 0; }
    .modal-quick-reply-manage-row .button-text-wrapper-form .manage-rows {
      margin: 18px 0 0;
      font-size: 14px;
      letter-spacing: -0.5px;
      color: var(--main-dark-orange);
      cursor: pointer; }
      .modal-quick-reply-manage-row .button-text-wrapper-form .manage-rows svg {
        position: relative;
        top: -1px;
        margin-right: 4px; }
      .modal-quick-reply-manage-row .button-text-wrapper-form .manage-rows:hover {
        color: var(--main-darker-orange); }
    .modal-quick-reply-manage-row .button-text-wrapper-form.button-text-wrapper-form-1 {
      margin: 24px 0 0 !important; }
    .modal-quick-reply-manage-row .button-text-wrapper-form label {
      margin-top: 0; }
    .modal-quick-reply-manage-row .button-text-wrapper-form .remove-button-text {
      position: relative;
      top: -1px;
      margin-left: 4px;
      cursor: pointer;
      color: #ff3f57 !important; }
  .modal-quick-reply-manage-row .add-another-button {
    margin: 16px 0 0;
    font-size: 14px;
    cursor: pointer;
    letter-spacing: -0.5px;
    color: var(--main-dark-orange); }
    .modal-quick-reply-manage-row .add-another-button svg {
      position: relative;
      margin-right: 4px;
      top: -1px; }
    .modal-quick-reply-manage-row .add-another-button:hover {
      color: var(--main-darker-orange); }
      .modal-quick-reply-manage-row .add-another-button:hover svg {
        color: var(--main-darker-orange); }

.form-add-quick-reply-interactive-button .line-through {
  margin: 28px 0 0; }

.form-add-quick-reply-interactive-button .message-content {
  width: 100%;
  position: relative;
  padding: 22px 0 12px; }
  .form-add-quick-reply-interactive-button .message-content p {
    margin: 0;
    font-size: 16px;
    letter-spacing: -0.5px; }
  .form-add-quick-reply-interactive-button .message-content button {
    position: absolute;
    right: 0;
    top: -12px;
    width: unset; }

.form-add-quick-reply-interactive-button .input-outer-wrapper {
  width: 570px;
  max-width: 100%; }
  .form-add-quick-reply-interactive-button .input-outer-wrapper label {
    position: relative;
    width: 100% !important; }
  .form-add-quick-reply-interactive-button .input-outer-wrapper textarea, .form-add-quick-reply-interactive-button .input-outer-wrapper input[type="text"] {
    position: relative;
    width: 100%; }
  .form-add-quick-reply-interactive-button .input-outer-wrapper .text-optional {
    position: relative;
    top: unset;
    right: unset; }
  .form-add-quick-reply-interactive-button .input-outer-wrapper textarea {
    height: 125px;
    padding: 12px; }
  .form-add-quick-reply-interactive-button .input-outer-wrapper .input-shortcut {
    width: calc(100% - 47px) !important; }

.form-add-quick-reply-interactive-button .button-text-wrapper-form {
  border-radius: 8px;
  border: solid 1px rgba(25, 25, 25, 0.1);
  background: #f3f3f3;
  padding: 12px;
  margin: 8px 0 0;
  width: 570px; }
  .form-add-quick-reply-interactive-button .button-text-wrapper-form input[type="text"] {
    width: 100%; }
  .form-add-quick-reply-interactive-button .button-text-wrapper-form.button-text-wrapper-form-1 {
    margin: 24px 0 0 !important; }
  .form-add-quick-reply-interactive-button .button-text-wrapper-form label {
    margin-top: 0; }
  .form-add-quick-reply-interactive-button .button-text-wrapper-form .remove-button-text {
    position: relative;
    top: -1px;
    cursor: pointer;
    margin-left: 4px;
    color: #ff3f57 !important; }

.form-add-quick-reply-interactive-button .add-another-button {
  margin: 16px 0 0;
  padding: 0 0 12px 0;
  font-size: 14px;
  cursor: pointer;
  letter-spacing: -0.5px;
  color: var(--main-dark-orange); }
  .form-add-quick-reply-interactive-button .add-another-button svg {
    position: relative;
    margin-right: 4px;
    top: -1px; }
  .form-add-quick-reply-interactive-button .add-another-button:hover {
    color: var(--main-darker-orange); }
    .form-add-quick-reply-interactive-button .add-another-button:hover svg {
      color: var(--main-darker-orange); }

.header-type-quick-reply-outer-wrapper button {
  margin-top: 12px !important; }

.header-type-quick-reply-outer-wrapper .label-header-type-quick-reply {
  width: 570px; }

.header-type-quick-reply-outer-wrapper input[type="text"] {
  width: 570px !important; }

.header-type-quick-reply-outer-wrapper .header-type-quick-reply-wrapper {
  width: 100%;
  position: relative;
  display: block; }
  .header-type-quick-reply-outer-wrapper .header-type-quick-reply-wrapper .message-type-selection-selection {
    vertical-align: top;
    border-radius: 8px;
    border: solid 1px #dcdcdc;
    display: inline-block;
    position: relative;
    width: calc(25% - 6px);
    margin-right: 8px;
    font-size: 14px;
    letter-spacing: -0.2px;
    padding: 14px 14px 14px 49px;
    cursor: pointer;
    -webkit-transition: ease-in-out .1s;
    transition: ease-in-out .1s; }
    .header-type-quick-reply-outer-wrapper .header-type-quick-reply-wrapper .message-type-selection-selection:nth-last-child(1) {
      margin-right: 0; }
    .header-type-quick-reply-outer-wrapper .header-type-quick-reply-wrapper .message-type-selection-selection.active-message-type-selection-selection {
      border-color: #ff7d00; }
    .header-type-quick-reply-outer-wrapper .header-type-quick-reply-wrapper .message-type-selection-selection:hover {
      border-color: #ff7d00; }
  .header-type-quick-reply-outer-wrapper .header-type-quick-reply-wrapper .message-type-selection-icon-wrapper {
    width: 28px;
    height: 28px;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 14px;
    -webkit-transform: translate(0, -50%);
            transform: translate(0, -50%); }
    .header-type-quick-reply-outer-wrapper .header-type-quick-reply-wrapper .message-type-selection-icon-wrapper img {
      width: 25px;
      height: 25px; }
    .header-type-quick-reply-outer-wrapper .header-type-quick-reply-wrapper .message-type-selection-icon-wrapper img {
      position: absolute;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%); }

.header-type-quick-reply-outer-wrapper .header-content {
  font-size: 14px;
  letter-spacing: -0.4px;
  display: block;
  margin-top: 12px; }

.header-type-quick-reply-outer-wrapper .button-upload-document {
  width: 220px;
  margin: 12px 0 0; }

.header-type-quick-reply-outer-wrapper .main-button-22 {
  width: unset !important;
  padding: 0 8px 0 12px !important;
  margin: 12px 8px 0  0; }

.header-type-quick-reply-outer-wrapper input[type="file"] {
  display: none; }

.header-type-quick-reply-outer-wrapper .media-value-wrapper {
  border-radius: 8px;
  margin: 8px 0 0;
  width: 48px;
  height: 48px;
  background: #ebebeb;
  position: relative;
  overflow: hidden; }
  .header-type-quick-reply-outer-wrapper .media-value-wrapper img, .header-type-quick-reply-outer-wrapper .media-value-wrapper video {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    max-width: 100%;
    max-height: 100px; }
  .header-type-quick-reply-outer-wrapper .media-value-wrapper .media-value-loading-wrapper {
    position: absolute;
    border-radius: 8px;
    width: 48px;
    height: 48px;
    border-radius: 8px;
    top: 0;
    left: 0;
    background: rgba(25, 25, 25, 0.1);
    display: none;
    cursor: pointer; }
    .header-type-quick-reply-outer-wrapper .media-value-wrapper .media-value-loading-wrapper svg {
      font-size: 20px;
      color: #fff;
      position: absolute;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%); }
    .header-type-quick-reply-outer-wrapper .media-value-wrapper .media-value-loading-wrapper .lds-ring {
      width: 38px;
      height: 38px;
      position: absolute;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%); }
      .header-type-quick-reply-outer-wrapper .media-value-wrapper .media-value-loading-wrapper .lds-ring div {
        margin: 0;
        width: 38px;
        height: 38px;
        border: solid 3px #fff;
        border-color: #fff transparent transparent transparent; }
  .header-type-quick-reply-outer-wrapper .media-value-wrapper:hover .media-value-loading-wrapper {
    display: block; }

.header-type-quick-reply-outer-wrapper .document-value-wrapper {
  width: 100%;
  margin-top: 20px;
  font-size: 14px;
  white-space: nowrap;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis; }
  .header-type-quick-reply-outer-wrapper .document-value-wrapper .lds-ring {
    margin-left: 8px;
    position: relative;
    top: 4px; }
    .header-type-quick-reply-outer-wrapper .document-value-wrapper .lds-ring div {
      margin: 0; }
  .header-type-quick-reply-outer-wrapper .document-value-wrapper svg {
    position: relative;
    top: -1px;
    margin-right: 4px;
    font-size: 14px;
    color: var(--main-dark-orange); }

.form-add-quick-reply-interactive-list .line-through {
  margin: 28px 0 0; }

.form-add-quick-reply-interactive-list .message-content {
  width: 100%;
  position: relative;
  padding: 22px 0 12px; }
  .form-add-quick-reply-interactive-list .message-content p {
    margin: 0;
    font-size: 16px;
    letter-spacing: -0.5px; }
  .form-add-quick-reply-interactive-list .message-content button {
    position: absolute;
    right: 0;
    top: -12px;
    width: unset; }

.form-add-quick-reply-interactive-list .input-outer-wrapper {
  width: 570px;
  max-width: 100%; }
  .form-add-quick-reply-interactive-list .input-outer-wrapper label {
    position: relative;
    width: 100% !important; }
  .form-add-quick-reply-interactive-list .input-outer-wrapper textarea, .form-add-quick-reply-interactive-list .input-outer-wrapper input[type="text"] {
    position: relative;
    width: 100%; }
  .form-add-quick-reply-interactive-list .input-outer-wrapper .text-optional {
    position: relative;
    top: unset;
    right: unset; }
  .form-add-quick-reply-interactive-list .input-outer-wrapper textarea {
    height: 125px;
    padding: 12px; }
  .form-add-quick-reply-interactive-list .input-outer-wrapper .input-shortcut {
    width: calc(100% - 47px) !important; }

.form-add-quick-reply-interactive-list .button-text-wrapper-form {
  border-radius: 8px;
  border: solid 1px rgba(25, 25, 25, 0.1);
  background: #f3f3f3;
  padding: 12px;
  margin: 8px 0 0;
  width: 570px;
  max-width: 100%; }
  .form-add-quick-reply-interactive-list .button-text-wrapper-form .manage-rows {
    margin: 14px 0 0;
    font-size: 14px;
    letter-spacing: -0.5px;
    color: var(--main-dark-orange);
    cursor: pointer; }
    .form-add-quick-reply-interactive-list .button-text-wrapper-form .manage-rows svg {
      position: relative;
      top: -1px;
      margin-right: 4px;
      font-size: 16px; }
    .form-add-quick-reply-interactive-list .button-text-wrapper-form .manage-rows:hover {
      color: var(--main-darker-orange); }
  .form-add-quick-reply-interactive-list .button-text-wrapper-form input[type="text"] {
    width: 100%; }
  .form-add-quick-reply-interactive-list .button-text-wrapper-form.button-text-wrapper-form-1 {
    margin: 24px 0 0 !important; }
  .form-add-quick-reply-interactive-list .button-text-wrapper-form label {
    margin-top: 0; }
  .form-add-quick-reply-interactive-list .button-text-wrapper-form .remove-button-text {
    position: relative;
    top: -1px;
    cursor: pointer;
    margin-left: 4px;
    color: #ff3f57 !important; }

.form-add-quick-reply-interactive-list .add-another-button {
  margin: 16px 0 0;
  padding: 0 0 12px 0;
  font-size: 14px;
  cursor: pointer;
  letter-spacing: -0.5px;
  color: var(--main-dark-orange); }
  .form-add-quick-reply-interactive-list .add-another-button svg {
    position: relative;
    margin-right: 4px;
    top: -1px; }
  .form-add-quick-reply-interactive-list .add-another-button:hover {
    color: var(--main-darker-orange); }
    .form-add-quick-reply-interactive-list .add-another-button:hover svg {
      color: var(--main-darker-orange); }

.select-tier-main-wrapper {
  width: 100%;
  min-height: calc(100vh - 87px);
  margin-top: 87px;
  background: #f3f3f3;
  padding-top: 40px; }
  .select-tier-main-wrapper #sectionContent {
    min-height: calc(100vh - 127px); }

#new-organization-header-content {
  color: red; }
  #new-organization-header-content .step-container {
    position: absolute;
    top: 50%;
    -webkit-transform: translate(0, -50%);
            transform: translate(0, -50%);
    right: 64px; }

.select-tier-form-1 {
  position: relative; }
  .select-tier-form-1 .comp-back-button {
    margin-left: 72px; }
  .select-tier-form-1 .continue-tier {
    top: 25px;
    right: 72px;
    height: 40px;
    width: 110px;
    position: absolute; }
  .select-tier-form-1 .select-tier-top {
    width: 100%;
    font-size: 20px;
    letter-spacing: -0.6px;
    color: #191919;
    padding: 0;
    white-space: nowrap;
    text-align: left; }
    .select-tier-form-1 .select-tier-top .select-your-tier-title {
      font-size: 40px;
      letter-spacing: -0.6px;
      line-height: 1;
      margin: 0 0 16px; }
    .select-tier-form-1 .select-tier-top .toggle-select-tier {
      position: relative;
      top: 10px;
      display: inline-block; }
      .select-tier-form-1 .select-tier-top .toggle-select-tier .pay-switch-wrapper {
        vertical-align: top;
        display: inline-block;
        margin: 0 16px; }
        .select-tier-form-1 .select-tier-top .toggle-select-tier .pay-switch-wrapper .custom-switch-wrapper {
          margin: 0;
          height: 28px;
          width: 48px; }
          .select-tier-form-1 .select-tier-top .toggle-select-tier .pay-switch-wrapper .custom-switch-wrapper .custom-switch:before {
            width: 15px;
            height: 15px;
            bottom: 7px; }
        .select-tier-form-1 .select-tier-top .toggle-select-tier .pay-switch-wrapper input:checked + .custom-switch:before {
          -webkit-transform: translateX(17px);
                  transform: translateX(17px); }
      .select-tier-form-1 .select-tier-top .toggle-select-tier b {
        color: rgba(25, 25, 25, 0.8); }
    .select-tier-form-1 .select-tier-top .alert-component {
      font-size: 12px;
      letter-spacing: -0.5px;
      padding: 2px 8px 2px 8px;
      margin-left: 16px;
      border-radius: 4px;
      position: relative;
      top: 14px; }
  .select-tier-form-1 .pricing-outer-container {
    padding: 40px 0 24px; }
    .select-tier-form-1 .pricing-outer-container .alert-component {
      background: #fff4b1;
      border: 1px solid #ff7e00;
      box-shadow: 0px 2px 20px rgba(25, 25, 25, 0.18);
      width: 100%;
      color: #ff7e00;
      position: relative;
      margin-bottom: 28px; }
      .select-tier-form-1 .pricing-outer-container .alert-component .orange-button {
        position: absolute;
        right: 16px;
        top: 50%;
        -webkit-transform: translate(0, -50%);
                transform: translate(0, -50%);
        padding: 0 24px;
        height: 32px;
        font-size: 14px; }
    .select-tier-form-1 .pricing-outer-container .main-pricing-wrapper .main-pricing-title {
      font-size: 40px;
      line-height: 1.2;
      letter-spacing: -0.6px;
      text-align: left;
      color: #191919;
      margin: 0 0 48px; }
    .select-tier-form-1 .pricing-outer-container .main-pricing-wrapper .pricing-div-wrapper {
      margin-bottom: 12px;
      margin-right: 12px;
      width: calc(25% - 9px);
      display: inline-block;
      vertical-align: top;
      background: #ffffff;
      padding: 24px 32px;
      border-radius: 8px;
      text-align: left;
      box-shadow: 0 2px 20px 0 rgba(25, 25, 25, 0.05); }
      .select-tier-form-1 .pricing-outer-container .main-pricing-wrapper .pricing-div-wrapper.active-selected-tier {
        border: solid 1px #dcdcdc;
        box-shadow: 0px 0px 8px rgba(255, 126, 0, 0.24); }
      .select-tier-form-1 .pricing-outer-container .main-pricing-wrapper .pricing-div-wrapper P.note-text {
        text-align: left;
        margin: 0;
        font-size: 16px;
        letter-spacing: -0.5px; }
      .select-tier-form-1 .pricing-outer-container .main-pricing-wrapper .pricing-div-wrapper:nth-child(4n) {
        margin-right: 0 !important; }
      .select-tier-form-1 .pricing-outer-container .main-pricing-wrapper .pricing-div-wrapper .pricing-badge {
        color: #191919;
        font-size: 24px;
        letter-spacing: -0.6px; }
      .select-tier-form-1 .pricing-outer-container .main-pricing-wrapper .pricing-div-wrapper .pricing-title {
        color: rgba(25, 25, 25, 0.6);
        font-size: 14px;
        letter-spacing: -0.4px;
        margin: 2px 0 0; }
      .select-tier-form-1 .pricing-outer-container .main-pricing-wrapper .pricing-div-wrapper .pricing-price1 {
        margin: 0;
        font-size: 16px;
        letter-spacing: -0.5px;
        color: #191919; }
      .select-tier-form-1 .pricing-outer-container .main-pricing-wrapper .pricing-div-wrapper .save-pricing {
        color: #ff7e00;
        font-size: 14px;
        letter-spacing: -0.4px;
        margin: 4px 0 0; }
      .select-tier-form-1 .pricing-outer-container .main-pricing-wrapper .pricing-div-wrapper .submit-price {
        height: 40px;
        font-size: 16px;
        letter-spacing: -0.5px;
        width: 100%;
        text-align: center;
        margin: 16px 0 0; }
        .select-tier-form-1 .pricing-outer-container .main-pricing-wrapper .pricing-div-wrapper .submit-price p {
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          margin: 0; }
        .select-tier-form-1 .pricing-outer-container .main-pricing-wrapper .pricing-div-wrapper .submit-price .lds-ring {
          position: relative;
          top: 3px;
          right: unset; }
          .select-tier-form-1 .pricing-outer-container .main-pricing-wrapper .pricing-div-wrapper .submit-price .lds-ring div {
            margin: 0;
            border-color: #ffffff transparent transparent transparent; }
        .select-tier-form-1 .pricing-outer-container .main-pricing-wrapper .pricing-div-wrapper .submit-price.active-tier-button {
          background: #ff7e00 !important;
          color: #ffffff !important; }
      .select-tier-form-1 .pricing-outer-container .main-pricing-wrapper .pricing-div-wrapper .pricing-price2 {
        margin: 0;
        color: rgba(25, 25, 25, 0.6);
        font-size: 14px;
        letter-spacing: -0.2px; }
      .select-tier-form-1 .pricing-outer-container .main-pricing-wrapper .pricing-div-wrapper ul {
        list-style: none;
        padding: 16px 0 0;
        margin: 0; }
        .select-tier-form-1 .pricing-outer-container .main-pricing-wrapper .pricing-div-wrapper ul.no-margin-padding-top {
          padding: 0 0 0;
          margin: 0 0 0; }
        .select-tier-form-1 .pricing-outer-container .main-pricing-wrapper .pricing-div-wrapper ul li {
          text-align: left;
          padding: 0 0 8px 24px;
          position: relative;
          color: #191919;
          letter-spacing: -0.2px;
          font-size: 14px;
          color: rgba(25, 25, 25, 0.8); }
          .select-tier-form-1 .pricing-outer-container .main-pricing-wrapper .pricing-div-wrapper ul li .fi-check-wrapper {
            width: 16px;
            height: 16px;
            border-radius: 50%;
            background: var(--main-dark-orange);
            position: relative; }
            .select-tier-form-1 .pricing-outer-container .main-pricing-wrapper .pricing-div-wrapper ul li .fi-check-wrapper svg {
              position: absolute;
              top: 50%;
              left: 50%;
              -webkit-transform: translate(-50%, -50%);
                      transform: translate(-50%, -50%);
              color: #fff;
              font-size: 12px; }
          .select-tier-form-1 .pricing-outer-container .main-pricing-wrapper .pricing-div-wrapper ul li img {
            position: absolute;
            left: 0;
            width: 18px;
            top: 1px; }
          .select-tier-form-1 .pricing-outer-container .main-pricing-wrapper .pricing-div-wrapper ul li.pricing-whatsapp {
            padding: 0 0 12px 24px; }
            .select-tier-form-1 .pricing-outer-container .main-pricing-wrapper .pricing-div-wrapper ul li.pricing-whatsapp p {
              font-size: 14px;
              letter-spacing: -0.2px;
              margin: 2px 0 0; }
            .select-tier-form-1 .pricing-outer-container .main-pricing-wrapper .pricing-div-wrapper ul li.pricing-whatsapp b {
              color: #191919;
              font-size: 16px;
              letter-spacing: -0.5px; }
      .select-tier-form-1 .pricing-outer-container .main-pricing-wrapper .pricing-div-wrapper .accordion-toggle-wrapper {
        text-align: center;
        margin: 8px 0 0; }
        .select-tier-form-1 .pricing-outer-container .main-pricing-wrapper .pricing-div-wrapper .accordion-toggle-wrapper .accordion-toggle-content {
          color: rgba(25, 25, 25, 0.6);
          font-size: 14px;
          letter-spacing: -0.4px;
          border-bottom: 1px solid #dcdcdc;
          width: 100%;
          display: inline-block;
          padding-bottom: 12px;
          margin-bottom: 12px;
          cursor: pointer; }
          .select-tier-form-1 .pricing-outer-container .main-pricing-wrapper .pricing-div-wrapper .accordion-toggle-wrapper .accordion-toggle-content:hover {
            color: #191919; }
          .select-tier-form-1 .pricing-outer-container .main-pricing-wrapper .pricing-div-wrapper .accordion-toggle-wrapper .accordion-toggle-content svg {
            -webkit-transition: ease-in-out .2s;
            transition: ease-in-out .2s;
            position: relative;
            margin-right: 4px;
            top: -2px; }
            .select-tier-form-1 .pricing-outer-container .main-pricing-wrapper .pricing-div-wrapper .accordion-toggle-wrapper .accordion-toggle-content svg.active-accordion-feature {
              -webkit-transform: rotate(180deg);
                      transform: rotate(180deg); }
      .select-tier-form-1 .pricing-outer-container .main-pricing-wrapper .pricing-div-wrapper.pricing-professional {
        background: #ffffff;
        color: #191919;
        border: solid 1px rgba(25, 25, 25, 0.1); }
        .select-tier-form-1 .pricing-outer-container .main-pricing-wrapper .pricing-div-wrapper.pricing-professional .pricing-badge {
          color: #191919;
          font-size: 24px;
          letter-spacing: -0.6px; }
        .select-tier-form-1 .pricing-outer-container .main-pricing-wrapper .pricing-div-wrapper.pricing-professional .pricing-title {
          color: rgba(25, 25, 25, 0.6); }
        .select-tier-form-1 .pricing-outer-container .main-pricing-wrapper .pricing-div-wrapper.pricing-professional .pricing-price1 {
          color: #191919; }
        .select-tier-form-1 .pricing-outer-container .main-pricing-wrapper .pricing-div-wrapper.pricing-professional .pricing-price2 {
          color: #191919; }
        .select-tier-form-1 .pricing-outer-container .main-pricing-wrapper .pricing-div-wrapper.pricing-professional ul {
          border-color: rgba(25, 25, 25, 0.1); }
          .select-tier-form-1 .pricing-outer-container .main-pricing-wrapper .pricing-div-wrapper.pricing-professional ul li {
            color: #191919; }
            .select-tier-form-1 .pricing-outer-container .main-pricing-wrapper .pricing-div-wrapper.pricing-professional ul li svg {
              color: #5FD84E; }
    .select-tier-form-1 .pricing-outer-container .additional-pricing {
      margin-top: 32px;
      border: solid 1px #044b84;
      background: #dcebff;
      padding: 15px 20px 12px;
      text-align: center;
      color: #044b84;
      border-radius: 8px;
      margin-bottom: 120px; }
      .select-tier-form-1 .pricing-outer-container .additional-pricing p {
        margin: 0; }
        .select-tier-form-1 .pricing-outer-container .additional-pricing p:nth-child(1) {
          position: relative;
          display: inline-block;
          font-size: 14px;
          letter-spacing: -0.3px;
          line-height: 24px; }
          .select-tier-form-1 .pricing-outer-container .additional-pricing p:nth-child(1) img {
            top: 50%;
            -webkit-transform: translate(0, -50%);
                    transform: translate(0, -50%);
            position: absolute;
            visibility: top;
            left: 0; }
        .select-tier-form-1 .pricing-outer-container .additional-pricing p:nth-child(2) {
          margin-top: 4px;
          font-size: 20px;
          line-height: 24px;
          letter-spacing: -0.3px; }

.modal-has-pending-subscription .modal-content {
  border-radius: 8px; }

.modal-has-pending-subscription .modal-body {
  padding: 32px; }
  .modal-has-pending-subscription .modal-body p.modal-has-pending-subscription-title {
    font-size: 20px;
    letter-spacing: -0.6px;
    margin: 0 0 4px 0; }
    .modal-has-pending-subscription .modal-body p.modal-has-pending-subscription-title svg {
      font-size: 24px;
      margin-right: 4px;
      position: relative;
      top: -2px; }
  .modal-has-pending-subscription .modal-body p.modal-has-pending-subscription-description {
    color: rgba(25, 25, 25, 0.6);
    font-size: 14px;
    letter-spacing: -0.2px;
    line-height: 1.5; }
  .modal-has-pending-subscription .modal-body button {
    height: 48px;
    width: calc(50% - 4px);
    margin: 0 8px 0 0; }
    .modal-has-pending-subscription .modal-body button:nth-last-child(1) {
      margin: 0;
      vertical-align: top; }

.review-transaction-main-wrapper {
  width: 100%;
  min-height: calc(100vh - 87px);
  margin-top: 87px;
  padding-top: 40px;
  background: #f3f3f3; }
  .review-transaction-main-wrapper .review-transaction-content .review-transaction-header {
    padding: 0;
    display: flex;
    justify-content: space-between;
    align-items: center; }
    .review-transaction-main-wrapper .review-transaction-content .review-transaction-header .comp-back-button {
      margin: 0; }
    .review-transaction-main-wrapper .review-transaction-content .review-transaction-header .button {
      padding: 8px 24px; }
  .review-transaction-main-wrapper .review-transaction-content .review-transaction-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 96px; }
    .review-transaction-main-wrapper .review-transaction-content .review-transaction-container h1 {
      margin: 32px 0; }
    .review-transaction-main-wrapper .review-transaction-content .review-transaction-container .review-container {
      width: 600px;
      background-color: #ffffff;
      border-radius: 8px;
      border: 1px solid rgba(25, 25, 25, 0.1);
      padding: 32px;
      display: flex;
      flex-direction: column;
      align-items: center; }
      .review-transaction-main-wrapper .review-transaction-content .review-transaction-container .review-container .box-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%; }
        .review-transaction-main-wrapper .review-transaction-content .review-transaction-container .review-container .box-container .box-item-container {
          display: flex;
          justify-content: space-between;
          width: 100%;
          box-shadow: 0px 1px 0px rgba(25, 25, 25, 0.1);
          margin-bottom: 32px; }
          .review-transaction-main-wrapper .review-transaction-content .review-transaction-container .review-container .box-container .box-item-container:nth-child(3) {
            margin-bottom: 24px;
            box-shadow: none; }
          .review-transaction-main-wrapper .review-transaction-content .review-transaction-container .review-container .box-container .box-item-container .img-title-wrapper {
            display: flex; }
            .review-transaction-main-wrapper .review-transaction-content .review-transaction-container .review-container .box-container .box-item-container .img-title-wrapper .image-credit-card {
              width: 46px;
              height: 36px; }
            .review-transaction-main-wrapper .review-transaction-content .review-transaction-container .review-container .box-container .box-item-container .img-title-wrapper b {
              font-size: 24px; }
            .review-transaction-main-wrapper .review-transaction-content .review-transaction-container .review-container .box-container .box-item-container .img-title-wrapper .method-wrapper {
              max-width: 297px; }
          .review-transaction-main-wrapper .review-transaction-content .review-transaction-container .review-container .box-container .box-item-container img {
            width: 48px;
            height: 48px;
            margin-right: 12px; }
          .review-transaction-main-wrapper .review-transaction-content .review-transaction-container .review-container .box-container .box-item-container button.tier-button {
            width: 131px;
            height: 40px; }
          .review-transaction-main-wrapper .review-transaction-content .review-transaction-container .review-container .box-container .box-item-container button.method-button {
            width: 156px;
            height: 40px; }
            .review-transaction-main-wrapper .review-transaction-content .review-transaction-container .review-container .box-container .box-item-container button.method-button svg {
              position: relative;
              top: -2px;
              margin-right: 0;
              font-size: 20px; }
          .review-transaction-main-wrapper .review-transaction-content .review-transaction-container .review-container .box-container .box-item-container button.card-button {
            width: 136px;
            height: 40px; }
      .review-transaction-main-wrapper .review-transaction-content .review-transaction-container .review-container .trial-container {
        width: 100%;
        background: rgba(25, 25, 25, 0.05);
        border-radius: 8px;
        margin: 0 0 16px;
        padding: 24px 0;
        display: flex;
        justify-content: center;
        align-items: center; }
        .review-transaction-main-wrapper .review-transaction-content .review-transaction-container .review-container .trial-container b {
          font-size: 24px; }
      .review-transaction-main-wrapper .review-transaction-content .review-transaction-container .review-container .amount-container {
        width: 100%;
        background: rgba(25, 25, 25, 0.05);
        border-radius: 8px;
        padding: 24px 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center; }
        .review-transaction-main-wrapper .review-transaction-content .review-transaction-container .review-container .amount-container p {
          color: rgba(25, 25, 25, 0.6);
          font-size: 16px;
          margin-bottom: 10px; }
        .review-transaction-main-wrapper .review-transaction-content .review-transaction-container .review-container .amount-container b {
          font-size: 32px; }
      .review-transaction-main-wrapper .review-transaction-content .review-transaction-container .review-container .alert-container {
        margin: 24px 0; }
      .review-transaction-main-wrapper .review-transaction-content .review-transaction-container .review-container button.confirm-button {
        width: 100%;
        height: 40px; }
        .review-transaction-main-wrapper .review-transaction-content .review-transaction-container .review-container button.confirm-button .lds-ring {
          top: 3px;
          right: unset; }
          .review-transaction-main-wrapper .review-transaction-content .review-transaction-container .review-container button.confirm-button .lds-ring div {
            border-color: #ffffff transparent transparent transparent;
            margin: 0; }

.confirm-modal .modal-content {
  width: 476px; }

.confirm-modal .modal-body {
  padding: 32px; }
  .confirm-modal .modal-body .confirm-modal-wrapper {
    position: relative; }
    .confirm-modal .modal-body .confirm-modal-wrapper .confirm-title-wrapper {
      display: flex;
      align-items: center; }
      .confirm-modal .modal-body .confirm-modal-wrapper .confirm-title-wrapper .confirm-title {
        font-size: 20px;
        letter-spacing: -0.6px;
        color: #191919;
        line-height: 1;
        margin: 0; }
      .confirm-modal .modal-body .confirm-modal-wrapper .confirm-title-wrapper svg {
        width: 18px;
        height: 18px;
        margin-right: 7px; }
    .confirm-modal .modal-body .confirm-modal-wrapper .confirm-description {
      font-size: 14px;
      letter-spacing: -0.2px;
      color: rgba(25, 25, 25, 0.6);
      line-height: 21px;
      margin: 6px 0 20px; }
    .confirm-modal .modal-body .confirm-modal-wrapper .button-container {
      width: 100%;
      display: flex;
      justify-content: space-between; }
      .confirm-modal .modal-body .confirm-modal-wrapper .button-container button.cancel {
        width: 23%;
        height: 48px; }
      .confirm-modal .modal-body .confirm-modal-wrapper .button-container button.discard {
        width: 75%;
        height: 48px; }
        .confirm-modal .modal-body .confirm-modal-wrapper .button-container button.discard svg {
          margin-right: 9px; }

.verify-card-main-wrapper {
  width: 100%;
  min-height: calc(100vh - 87px);
  margin-top: 87px;
  padding-top: 40px;
  background: #f3f3f3; }
  .verify-card-main-wrapper .verify-card-content .verify-card-header {
    padding: 0;
    display: flex;
    justify-content: space-between;
    align-items: center; }
    .verify-card-main-wrapper .verify-card-content .verify-card-header .comp-back-button {
      margin: 0; }
    .verify-card-main-wrapper .verify-card-content .verify-card-header .button {
      padding: 8px 24px; }
  .verify-card-main-wrapper .verify-card-content .verify-card-container {
    position: relative;
    display: block;
    flex-direction: column;
    align-items: center;
    padding: 0 32px 32px;
    top: 32px;
    background-color: #ffffff;
    border: 1px solid rgba(25, 25, 25, 0.1);
    border-radius: 8px;
    width: 90%;
    margin: 0 auto;
    text-align: center; }
    .verify-card-main-wrapper .verify-card-content .verify-card-container h1 {
      margin: 32px 0;
      font-size: 40px; }
    .verify-card-main-wrapper .verify-card-content .verify-card-container .verify-card-iframe {
      width: 100%;
      height: 360px;
      display: flex;
      justify-content: center;
      align-items: center;
      border: solid 1px #dcdcdc;
      border-radius: 8px; }
      .verify-card-main-wrapper .verify-card-content .verify-card-container .verify-card-iframe .iframe-card-wrapper {
        width: 100%;
        height: 100%; }
        .verify-card-main-wrapper .verify-card-content .verify-card-container .verify-card-iframe .iframe-card-wrapper iframe {
          width: 100%;
          height: 100%; }
      .verify-card-main-wrapper .verify-card-content .verify-card-container .verify-card-iframe .loading-add-card-wrapper {
        position: absolute;
        width: 100%;
        text-align: center;
        left: 0;
        top: 50%;
        -webkit-transform: translate(0, -50%);
                transform: translate(0, -50%);
        color: #ff7d00;
        font-size: 16px;
        letter-spacing: -0.6px; }
        .verify-card-main-wrapper .verify-card-content .verify-card-container .verify-card-iframe .loading-add-card-wrapper .LoadingRoll_1234fed {
          height: unset;
          margin-bottom: 24px; }
          .verify-card-main-wrapper .verify-card-content .verify-card-container .verify-card-iframe .loading-add-card-wrapper .LoadingRoll_1234fed .lds-ring {
            width: 64px;
            height: 64px;
            position: relative;
            top: unset;
            left: unset;
            -webkit-transform: unset;
                    transform: unset; }
            .verify-card-main-wrapper .verify-card-content .verify-card-container .verify-card-iframe .loading-add-card-wrapper .LoadingRoll_1234fed .lds-ring div {
              border: solid 4px #ff7d00;
              border-color: #ff7d00 transparent transparent transparent;
              width: 64px;
              height: 64px; }
        .verify-card-main-wrapper .verify-card-content .verify-card-container .verify-card-iframe .loading-add-card-wrapper b {
          font-size: 18px;
          color: #ff7d00 !important; }
      .verify-card-main-wrapper .verify-card-content .verify-card-container .verify-card-iframe b {
        font-size: 40px;
        color: rgba(25, 25, 25, 0.4); }

.add-card-main-wrapper {
  width: 100%;
  min-height: calc(100vh - 87px);
  margin-top: 87px;
  padding-top: 40px;
  background: #f3f3f3; }
  .add-card-main-wrapper .add-card-content .add-card-header {
    padding: 0;
    display: flex;
    justify-content: space-between;
    align-items: center; }
    .add-card-main-wrapper .add-card-content .add-card-header .comp-back-button {
      margin: 0; }
    .add-card-main-wrapper .add-card-content .add-card-header .button {
      padding: 8px 24px; }
    .add-card-main-wrapper .add-card-content .add-card-header .cont-button {
      padding: 8px 24px;
      display: flex;
      justify-content: space-between;
      width: 145px; }
      .add-card-main-wrapper .add-card-content .add-card-header .cont-button:disabled {
        background: rgba(25, 25, 25, 0.1) !important; }
      .add-card-main-wrapper .add-card-content .add-card-header .cont-button .grey-lds-ring div {
        border-color: rgba(25, 25, 25, 0.4) transparent transparent transparent; }
      .add-card-main-wrapper .add-card-content .add-card-header .cont-button div {
        color: rgba(25, 25, 25, 0.4); }
  .add-card-main-wrapper .add-card-content .add-card-container {
    display: flex;
    flex-direction: column;
    align-items: center; }
    .add-card-main-wrapper .add-card-content .add-card-container h1 {
      margin: 32px 0; }
    .add-card-main-wrapper .add-card-content .add-card-container .form-content-wrapper {
      width: 368px;
      background-color: #ffffff;
      padding: 32px;
      border: 1px solid rgba(25, 25, 25, 0.1);
      border-radius: 8px; }
      .add-card-main-wrapper .add-card-content .add-card-container .form-content-wrapper .warning-error-red {
        color: #ff3f57;
        font-size: 12px;
        letter-spacing: -0.4px;
        margin: 4px 0 0; }
        .add-card-main-wrapper .add-card-content .add-card-container .form-content-wrapper .warning-error-red svg {
          font-size: 14px;
          position: relative;
          top: -1px;
          margin-right: 4px;
          color: #ff3f57 !important;
          cursor: unset !important; }
      .add-card-main-wrapper .add-card-content .add-card-container .form-content-wrapper label {
        position: relative; }
        .add-card-main-wrapper .add-card-content .add-card-container .form-content-wrapper label .custom-tooltip {
          padding: 14px;
          color: #ffffff;
          background: #4a4a4a;
          letter-spacing: 0.4px;
          width: 219px;
          font-weight: normal;
          border-radius: 8px;
          position: absolute;
          top: -35px;
          right: -175px;
          font-size: 14px;
          z-index: 1; }
          .add-card-main-wrapper .add-card-content .add-card-container .form-content-wrapper label .custom-tooltip::before {
            content: "";
            border-bottom: solid 12px #4a4a4a;
            border-top: solid 12px transparent;
            border-left: solid 12px transparent;
            border-right: solid 12px transparent;
            -webkit-transform: rotate(-90deg);
                    transform: rotate(-90deg);
            width: 20px;
            height: 20px;
            position: absolute;
            top: 35px;
            left: -24px; }
      .add-card-main-wrapper .add-card-content .add-card-container .form-content-wrapper .span-optional {
        font-size: 14px;
        letter-spacing: -0.2px;
        color: #9b9b9b;
        font-weight: normal;
        margin-left: 4px; }
      .add-card-main-wrapper .add-card-content .add-card-container .form-content-wrapper input[type="text"],
      .add-card-main-wrapper .add-card-content .add-card-container .form-content-wrapper input[type="password"],
      .add-card-main-wrapper .add-card-content .add-card-container .form-content-wrapper textarea {
        padding: 10px 15px;
        border-radius: 8px;
        border: solid 1px #dcdcdc;
        display: block;
        width: 100%;
        position: relative; }
      .add-card-main-wrapper .add-card-content .add-card-container .form-content-wrapper .card-number {
        position: relative; }
        .add-card-main-wrapper .add-card-content .add-card-container .form-content-wrapper .card-number input {
          padding: 10px 48px 10px 15px; }
        .add-card-main-wrapper .add-card-content .add-card-container .form-content-wrapper .card-number img {
          position: absolute;
          right: 16px;
          top: 13px;
          width: 22px; }
      .add-card-main-wrapper .add-card-content .add-card-container .form-content-wrapper .payment-div-50 {
        width: 145px;
        margin-right: 10px;
        display: inline-block;
        vertical-align: top; }
        .add-card-main-wrapper .add-card-content .add-card-container .form-content-wrapper .payment-div-50.no-margin {
          margin: 0; }
        .add-card-main-wrapper .add-card-content .add-card-container .form-content-wrapper .payment-div-50 svg {
          color: #9B9B9B;
          position: relative;
          top: -1px;
          margin-left: 5px;
          cursor: pointer; }
        .add-card-main-wrapper .add-card-content .add-card-container .form-content-wrapper .payment-div-50 input {
          width: 100%; }
      .add-card-main-wrapper .add-card-content .add-card-container .form-content-wrapper textarea {
        resize: none; }
      .add-card-main-wrapper .add-card-content .add-card-container .form-content-wrapper button.submit-card {
        width: unset;
        padding: 0 24px;
        height: 40px;
        width: 100%;
        position: relative; }
        .add-card-main-wrapper .add-card-content .add-card-container .form-content-wrapper button.submit-card .lds-ring {
          position: absolute;
          top: 50%;
          left: 50%;
          -webkit-transform: translate(-50%, -50%);
                  transform: translate(-50%, -50%); }

.add-card-modal .modal-content {
  width: 476px; }

.add-card-modal .modal-body {
  padding: 32px; }
  .add-card-modal .modal-body .add-card-modal-wrapper {
    position: relative; }
    .add-card-modal .modal-body .add-card-modal-wrapper .error-title-wrapper {
      display: flex;
      align-items: center; }
      .add-card-modal .modal-body .add-card-modal-wrapper .error-title-wrapper .error-title {
        font-size: 20px;
        letter-spacing: -0.6px;
        color: #191919;
        line-height: 1;
        margin: 0; }
      .add-card-modal .modal-body .add-card-modal-wrapper .error-title-wrapper svg {
        width: 18px;
        height: 18px;
        margin-right: 7px; }
    .add-card-modal .modal-body .add-card-modal-wrapper .error-description {
      font-size: 14px;
      letter-spacing: -0.2px;
      color: rgba(25, 25, 25, 0.6);
      line-height: 21px;
      margin: 6px 0 20px; }
    .add-card-modal .modal-body .add-card-modal-wrapper button.dismiss {
      width: 100%;
      height: 48px; }

.pay-methods-main-wrapper {
  width: 100%;
  min-height: calc(100vh - 87px);
  margin-top: 87px;
  padding-top: 40px;
  background: #f3f3f3; }
  .pay-methods-main-wrapper .pay-methods-content .pay-methods-header {
    padding: 0;
    display: flex;
    justify-content: space-between;
    align-items: center; }
    .pay-methods-main-wrapper .pay-methods-content .pay-methods-header .comp-back-button {
      margin: 0; }
    .pay-methods-main-wrapper .pay-methods-content .pay-methods-header .button {
      height: 40px;
      width: 110px; }
    .pay-methods-main-wrapper .pay-methods-content .pay-methods-header .cont-button {
      padding: 8px 24px;
      display: flex;
      justify-content: space-between;
      width: 145px; }
      .pay-methods-main-wrapper .pay-methods-content .pay-methods-header .cont-button:disabled {
        background: rgba(25, 25, 25, 0.1) !important; }
      .pay-methods-main-wrapper .pay-methods-content .pay-methods-header .cont-button .grey-lds-ring div {
        border-color: rgba(25, 25, 25, 0.4) transparent transparent transparent; }
      .pay-methods-main-wrapper .pay-methods-content .pay-methods-header .cont-button div {
        color: rgba(25, 25, 25, 0.4); }
  .pay-methods-main-wrapper .pay-methods-content .pay-methods-container {
    display: flex;
    flex-direction: column;
    align-items: center; }
    .pay-methods-main-wrapper .pay-methods-content .pay-methods-container .alert-component {
      width: 100%;
      color: #044B84;
      font-size: 14px;
      letter-spacing: -0.4px;
      border: solid 1px #044B84;
      background: #DCEBFF;
      line-height: 18px;
      padding: 8px 24px 8px 40px;
      margin: 0 0 10px 0; }
      .pay-methods-main-wrapper .pay-methods-content .pay-methods-container .alert-component svg {
        font-size: 16px; }
    .pay-methods-main-wrapper .pay-methods-content .pay-methods-container .pay-title {
      margin: 40px 0 32px;
      font-size: 40px;
      letter-spacing: -0.6px;
      line-height: 48px; }
    .pay-methods-main-wrapper .pay-methods-content .pay-methods-container .pay-methods-wrapper {
      display: flex;
      justify-content: space-between;
      width: 652px; }
      .pay-methods-main-wrapper .pay-methods-content .pay-methods-container .pay-methods-wrapper button.select-button {
        width: 100%;
        height: 40px; }
      .pay-methods-main-wrapper .pay-methods-content .pay-methods-container .pay-methods-wrapper button:focus {
        box-shadow: none; }
      .pay-methods-main-wrapper .pay-methods-content .pay-methods-container .pay-methods-wrapper button svg {
        margin-right: 6px;
        position: relative;
        top: -1px; }
      .pay-methods-main-wrapper .pay-methods-content .pay-methods-container .pay-methods-wrapper .pay-methods-auto {
        background-color: #ffffff;
        width: 320px;
        display: flex;
        flex-direction: column;
        align-items: center;
        border: 1px solid rgba(25, 25, 25, 0.1);
        border-radius: 8px;
        padding: 24px; }
        .pay-methods-main-wrapper .pay-methods-content .pay-methods-container .pay-methods-wrapper .pay-methods-auto img {
          margin-bottom: 12px; }
        .pay-methods-main-wrapper .pay-methods-content .pay-methods-container .pay-methods-wrapper .pay-methods-auto p {
          text-align: center; }
      .pay-methods-main-wrapper .pay-methods-content .pay-methods-container .pay-methods-wrapper .pay-methods-manual {
        background-color: #ffffff;
        width: 320px;
        display: flex;
        flex-direction: column;
        align-items: center;
        border: 1px solid rgba(25, 25, 25, 0.1);
        border-radius: 8px;
        padding: 24px; }
        .pay-methods-main-wrapper .pay-methods-content .pay-methods-container .pay-methods-wrapper .pay-methods-manual img {
          margin-bottom: 12px; }
        .pay-methods-main-wrapper .pay-methods-content .pay-methods-container .pay-methods-wrapper .pay-methods-manual p {
          text-align: center; }

.tooltip-bc-type .tooltip.show {
  opacity: 1; }

.tooltip-bc-type .tooltip-inner {
  display: block;
  opacity: 2 !important;
  width: 264px !important;
  max-width: unset !important; }

.tooltip-single-bc {
  font-size: 12px;
  letter-spacing: -0.2px;
  margin: 0 0 12px 0; }

.tooltip-multi-bc {
  font-size: 12px;
  letter-spacing: -0.2px;
  margin: 0; }

.broadcast-main-wrapper .btn {
  border: 0; }

.broadcast-main-wrapper tr:hover td {
  background: #ffffff !important; }

.broadcast-list-title {
  color: #191919;
  font-size: 20px;
  letter-spacing: -0.6px;
  line-height: 24px;
  padding-bottom: 16px;
  border-bottom: solid 1px rgba(25, 25, 25, 0.1); }

.broadcast-list-tool-wrapper {
  width: 100%;
  position: relative;
  margin-top: 24px;
  margin-bottom: 32px; }

.broadcast-list-info {
  font-size: 16px;
  letter-spacing: -0.3px;
  color: rgba(25, 25, 25, 0.8);
  line-height: 1.5;
  padding: 0 208px 18px 0; }

.broadcast-list-tool-wrapper button.button-invite-member {
  position: absolute;
  right: 0;
  top: 50%;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%);
  padding: 11px 0;
  width: 200px; }

.broadcast-list-tool-wrapper button.button-invite-member svg {
  position: relative;
  top: -1px;
  margin-right: 5px; }

.broadcast-list-table-wrapper {
  width: 100%;
  position: relative; }

.table-broadcast-list thead tr {
  background: #ffffff; }

.table-broadcast-list tbody td {
  background: #ffffff;
  position: relative; }

.table-broadcast-list button svg {
  position: relative !important;
  right: unset !important;
  top: -2px !important;
  margin-right: 5px; }

.table-broadcast-list .col-action-button-wrapper {
  position: absolute;
  right: 7px;
  top: 50%;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%);
  /* display: none; */ }

.table-broadcast-list .single-remove-button {
  margin-left: 16px;
  color: #ff3f57; }

.table-broadcast-list .single-remove-button:hover {
  color: #e83b51; }

.table-broadcast-list .shortcut-badge {
  font-size: 12px;
  color: rgba(25, 25, 25, 0.6);
  letter-spacing: -0.4px;
  background: #f3f3f3;
  line-height: 1;
  border-radius: 4px;
  padding: 1px 8px 3px; }

.table-broadcast-list td {
  max-width: unset !important; }

.table-broadcast-list .col-action-button-wrapper {
  display: block !important;
  position: relative;
  top: 0;
  right: unset;
  -webkit-transform: unset;
          transform: unset; }

.table-broadcast-list .col-action-button-wrapper .view-agent {
  color: #ff7d00;
  letter-spacing: -0.5px; }

.table-broadcast-list .col-action-button-wrapper .view-agent:hover {
  color: var(--main-darker-orange); }

.table-broadcast-list .col-action-button-wrapper img.view-agent {
  margin-right: 3px;
  position: relative;
  top: -1px; }

.member-tool-wrapper {
  width: 460px; }

.table-broadcast-list .col_action {
  width: 200px;
  text-align: right; }

.broadcast-page-wrapper {
  margin: 32px 0 0;
  position: relative; }
  .broadcast-page-wrapper .button-create-broadcast {
    position: absolute;
    top: 0;
    right: 0; }
  .broadcast-page-wrapper .nav {
    width: calc(100% - 240px); }
  .broadcast-page-wrapper .nav-item {
    cursor: pointer;
    color: rgba(25, 25, 25, 0.4);
    font-size: 16px;
    letter-spacing: -0.5px;
    padding: 0 0 12px 0;
    margin-right: 32px; }
    .broadcast-page-wrapper .nav-item a {
      padding: 0;
      border: none; }
    .broadcast-page-wrapper .nav-item :hover {
      color: #ff7d00; }
    .broadcast-page-wrapper .nav-item.active-setup-tab {
      color: #ff7d00;
      border-bottom: solid 4px #ff7d00; }
  .broadcast-page-wrapper .tab-content {
    margin: 32px 0 0; }

.broadcast-message-main-wrapper .btn {
  border: 0; }

.broadcast-message-main-wrapper .view-broadcast-message-detail-middle {
  padding: 16px;
  display: flex;
  border: solid 1px #dcdcdc;
  margin-bottom: 24px;
  max-width: 100%;
  overflow: auto; }
  .broadcast-message-main-wrapper .view-broadcast-message-detail-middle > div svg {
    margin-right: 5px;
    vertical-align: -2.4px; }
  .broadcast-message-main-wrapper .view-broadcast-message-detail-middle > div img {
    margin-right: 5px;
    margin-bottom: 4px;
    position: relative;
    top: 1px; }
  .broadcast-message-main-wrapper .view-broadcast-message-detail-middle p {
    margin-top: 8px; }
  .broadcast-message-main-wrapper .view-broadcast-message-detail-middle .view-broadcast-message-detail-middle-category p {
    margin-bottom: 0; }
  .broadcast-message-main-wrapper .view-broadcast-message-detail-middle .view-broadcast-message-detail-middle-channel div > img {
    margin-bottom: 9px; }
  .broadcast-message-main-wrapper .view-broadcast-message-detail-middle .view-broadcast-message-detail-middle-channel > div {
    margin-top: 8px; }
  .broadcast-message-main-wrapper .view-broadcast-message-detail-middle .view-broadcast-message-detail-middle-status .colored-info-box {
    margin-top: 10px;
    margin-bottom: 1rem; }
    .broadcast-message-main-wrapper .view-broadcast-message-detail-middle .view-broadcast-message-detail-middle-status .colored-info-box p {
      color: #ffffff;
      margin-top: 0 !important; }
  .broadcast-message-main-wrapper .view-broadcast-message-detail-middle .view-broadcast-message-detail-middle-content-body {
    max-width: 568px; }
    .broadcast-message-main-wrapper .view-broadcast-message-detail-middle .view-broadcast-message-detail-middle-content-body > img {
      margin-bottom: 5px !important; }
    .broadcast-message-main-wrapper .view-broadcast-message-detail-middle .view-broadcast-message-detail-middle-content-body .message-content {
      margin-top: 20px; }
      .broadcast-message-main-wrapper .view-broadcast-message-detail-middle .view-broadcast-message-detail-middle-content-body .message-content img {
        width: 200px;
        height: 200px;
        object-fit: cover;
        border-radius: 8px;
        cursor: pointer;
        margin: 0 0 8px 0; }
      .broadcast-message-main-wrapper .view-broadcast-message-detail-middle .view-broadcast-message-detail-middle-content-body .message-content .content-caption {
        padding-top: 10px;
        border-top: solid 1px #dcdcdc;
        margin-top: 0 !important; }
    .broadcast-message-main-wrapper .view-broadcast-message-detail-middle .view-broadcast-message-detail-middle-content-body .file-url {
      cursor: pointer; }
      .broadcast-message-main-wrapper .view-broadcast-message-detail-middle .view-broadcast-message-detail-middle-content-body .file-url img {
        width: 24px !important;
        height: 24px !important;
        margin-right: 7px; }
      .broadcast-message-main-wrapper .view-broadcast-message-detail-middle .view-broadcast-message-detail-middle-content-body .file-url div > p {
        margin-top: 2px;
        font-weight: bold;
        font-size: 14px;
        color: #ff7e00;
        line-height: 21px;
        letter-spacing: -0.4px;
        border-bottom: solid 1px #ff7e00; }
  .broadcast-message-main-wrapper .view-broadcast-message-detail-middle.view-broadcast-message-detail-middle-total-status {
    font-weight: bold; }
    .broadcast-message-main-wrapper .view-broadcast-message-detail-middle.view-broadcast-message-detail-middle-total-status > div {
      margin-right: 9%; }
      .broadcast-message-main-wrapper .view-broadcast-message-detail-middle.view-broadcast-message-detail-middle-total-status > div:nth-last-child(1) {
        margin-right: 0; }
    .broadcast-message-main-wrapper .view-broadcast-message-detail-middle.view-broadcast-message-detail-middle-total-status .flexing {
      font-weight: bold;
      font-size: 14px;
      line-height: 21px;
      letter-spacing: -0.4px; }
    .broadcast-message-main-wrapper .view-broadcast-message-detail-middle.view-broadcast-message-detail-middle-total-status .total {
      font-size: 24px;
      line-height: 30px;
      letter-spacing: -0.6px; }

.broadcast-message-list-title {
  color: #191919;
  font-size: 20px;
  letter-spacing: -0.6px;
  line-height: 24px;
  padding-bottom: 16px;
  border-bottom: solid 1px rgba(25, 25, 25, 0.1); }

.broadcast-message-list-tool-wrapper {
  width: 100%;
  position: relative;
  margin-top: 24px;
  margin-bottom: 32px; }

.broadcast-message-list-info {
  font-size: 16px;
  letter-spacing: -0.3px;
  color: rgba(25, 25, 25, 0.8);
  line-height: 1.5;
  padding: 0 208px 12px 0;
  margin-bottom: 24px;
  border-bottom: solid 1px #dcdcdc; }

.broadcast-message-list-tool-wrapper button.button-new-message {
  position: absolute;
  right: 0;
  top: 50%;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%);
  padding: 11px 0;
  width: 200px; }

.broadcast-message-list-tool-wrapper button.button-new-message svg {
  position: relative;
  top: -1px;
  margin-right: 5px; }

.broadcast-message-list-table-wrapper {
  width: 100%;
  position: relative; }
  .broadcast-message-list-table-wrapper .custom-table {
    min-height: 200px; }

.table-broadcast-message-list thead tr {
  background: #ffffff; }
  .table-broadcast-message-list thead tr td:nth-child(1), .table-broadcast-message-list thead tr td:nth-child(2) {
    min-width: 160px; }
  .table-broadcast-message-list thead tr td:nth-child(4) {
    min-width: 120px; }
  .table-broadcast-message-list thead tr td:nth-child(6), .table-broadcast-message-list thead tr td:nth-child(7) {
    min-width: 110px; }
  .table-broadcast-message-list thead tr td:last-child {
    width: 90px; }
    .table-broadcast-message-list thead tr td:last-child .custom-select-option-value-wrapper {
      width: 55px !important;
      height: 23px;
      padding: 2px 8px 2px 12px !important;
      border-radius: 4px !important; }
      .table-broadcast-message-list thead tr td:last-child .custom-select-option-value-wrapper p {
        font-size: 12px;
        font-weight: bold; }
      .table-broadcast-message-list thead tr td:last-child .custom-select-option-value-wrapper .custom-select-option-value {
        padding: 0; }
      .table-broadcast-message-list thead tr td:last-child .custom-select-option-value-wrapper svg {
        right: 3px !important;
        height: 15px;
        top: 11px !important;
        position: absolute !important;
        margin-right: unset !important; }

.table-broadcast-message-list tbody td {
  position: relative; }
  .table-broadcast-message-list tbody td.col_view svg {
    position: relative;
    top: -1px !important; }
  .table-broadcast-message-list tbody td:nth-child(1) div {
    display: flex; }
    .table-broadcast-message-list tbody td:nth-child(1) div img {
      margin-right: 4px;
      margin-top: 2px; }
  .table-broadcast-message-list tbody td:last-child {
    color: #ff7e00;
    font-weight: 700; }
    .table-broadcast-message-list tbody td:last-child a {
      color: #ff7e00; }
    .table-broadcast-message-list tbody td:last-child svg {
      position: relative;
      color: #ff7e00;
      font-size: 16px;
      margin-right: 4px;
      right: 0;
      top: -2px; }
  .table-broadcast-message-list tbody td .medium-logo {
    width: 16px;
    height: 16px; }

.table-broadcast-message-list button svg {
  position: relative !important;
  right: unset !important;
  top: -2px !important;
  margin-right: 5px; }

.table-broadcast-message-list .col-action-button-wrapper {
  position: absolute;
  right: 7px;
  top: 50%;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%);
  /* display: none; */ }

.table-broadcast-message-list td {
  max-width: unset !important; }

.new-message-modal .modal-content {
  width: 712px; }

.new-message-modal.new-message-select-channel .modal-content {
  width: 512px; }

.new-message-modal .modal-body {
  padding: 32px; }
  .new-message-modal .modal-body .new-message-wrapper {
    position: relative; }
    .new-message-modal .modal-body .new-message-wrapper .comp-back-button {
      margin: 0 0 20px; }
    .new-message-modal .modal-body .new-message-wrapper .new-message-title {
      font-size: 20px;
      letter-spacing: -0.6px;
      color: #191919;
      line-height: 1;
      margin: 6px 0 0; }
    .new-message-modal .modal-body .new-message-wrapper .new-message-description {
      font-size: 16px;
      letter-spacing: -0.3px;
      color: rgba(25, 25, 25, 0.8);
      line-height: 1;
      margin: 6px 0 0; }
    .new-message-modal .modal-body .new-message-wrapper .close-modal-new-message {
      position: absolute;
      right: 0;
      top: 0;
      font-size: 24px;
      cursor: pointer; }
      .new-message-modal .modal-body .new-message-wrapper .close-modal-new-message:hover {
        color: #ff7d00; }
  .new-message-modal .modal-body .new-message-channel-option-container label {
    font-size: 14px;
    letter-spacing: -0.4px;
    margin: 20px 0 4px; }
    .new-message-modal .modal-body .new-message-channel-option-container label svg {
      margin-left: 6px;
      border: none !important;
      outline: 0 !important; }
  .new-message-modal .modal-body .new-message-channel-option-container .new-message-channel-option-wrapper {
    width: 100%;
    position: relative; }
    .new-message-modal .modal-body .new-message-channel-option-container .new-message-channel-option-wrapper .new-message-channel-option {
      cursor: pointer;
      border-radius: 8px;
      border: solid 1px #dcdcdc;
      color: rgba(25, 25, 25, 0.6);
      font-size: 16px;
      letter-spacing: -0.4px;
      padding: 0 24px;
      height: 40px;
      line-height: 36px;
      vertical-align: top;
      margin: 0 4px 0 0;
      display: inline-block;
      width: calc(50% - 2px); }
      .new-message-modal .modal-body .new-message-channel-option-container .new-message-channel-option-wrapper .new-message-channel-option.active-channel {
        background: #FFF2E5;
        color: #ff7d00;
        border: solid 1px #FFF2E5; }
      .new-message-modal .modal-body .new-message-channel-option-container .new-message-channel-option-wrapper .new-message-channel-option:hover {
        background: #FFF2E5;
        color: #ff7d00;
        border: solid 1px #FFF2E5; }
      .new-message-modal .modal-body .new-message-channel-option-container .new-message-channel-option-wrapper .new-message-channel-option svg {
        font-size: 16px;
        letter-spacing: -0.5px;
        width: 16px;
        height: 16px;
        margin-right: 4px;
        position: relative;
        top: -1px; }
      .new-message-modal .modal-body .new-message-channel-option-container .new-message-channel-option-wrapper .new-message-channel-option img {
        font-size: 16px;
        letter-spacing: -0.5px;
        width: 16px;
        height: 16px;
        margin-right: 4px;
        position: relative;
        top: -1px; }
      .new-message-modal .modal-body .new-message-channel-option-container .new-message-channel-option-wrapper .new-message-channel-option:nth-last-child(1) {
        margin: 0 0 0; }
  .new-message-modal .modal-body .new-message-option-container {
    width: 100%;
    margin-top: 20px;
    text-align: center; }
    .new-message-modal .modal-body .new-message-option-container .new-message-option-list {
      cursor: pointer;
      text-align: center;
      width: calc((100% / 3) - 8px);
      margin-right: 12px;
      display: inline-block;
      vertical-align: top;
      border-radius: 8px;
      border: solid 1px rgba(25, 25, 25, 0.1);
      padding: 24px;
      height: 178px; }
      .new-message-modal .modal-body .new-message-option-container .new-message-option-list:nth-last-child(1) {
        margin-right: 0; }
      .new-message-modal .modal-body .new-message-option-container .new-message-option-list img {
        display: inline-block;
        margin: 0 0 14px; }
      .new-message-modal .modal-body .new-message-option-container .new-message-option-list b {
        line-height: 1;
        font-size: 16px;
        letter-spacing: -0.5px;
        color: rgba(25, 25, 25, 0.6); }
      .new-message-modal .modal-body .new-message-option-container .new-message-option-list p {
        letter-spacing: -0.2px;
        color: rgba(25, 25, 25, 0.8);
        font-size: 14px;
        line-height: 21px; }
      .new-message-modal .modal-body .new-message-option-container .new-message-option-list:hover {
        border: solid 1px rgba(25, 25, 25, 0.05);
        background: rgba(25, 25, 25, 0.05); }
        .new-message-modal .modal-body .new-message-option-container .new-message-option-list:hover b {
          color: #191919; }
        .new-message-modal .modal-body .new-message-option-container .new-message-option-list:hover p {
          color: #191919; }
      .new-message-modal .modal-body .new-message-option-container .new-message-option-list.active-message-type {
        border: solid 1px rgba(25, 25, 25, 0.05);
        background: rgba(25, 25, 25, 0.05); }
        .new-message-modal .modal-body .new-message-option-container .new-message-option-list.active-message-type b {
          color: #191919; }
        .new-message-modal .modal-body .new-message-option-container .new-message-option-list.active-message-type p {
          color: #191919; }

.new-message-modal .cancel-broadcast {
  margin: 20px 8px 0 0;
  width: calc(50% - 4px);
  height: 40px; }

.new-message-modal .continue-broadcast {
  margin: 20px 0 0 0;
  width: calc(50% - 4px);
  height: 40px; }

.bc-tootlip-toggle {
  margin-left: 4px; }

.advanced-date-selection {
  margin-bottom: 24px; }
  .advanced-date-selection.disabled-advanced-date-selection button {
    background: rgba(25, 25, 25, 0.1) !important;
    color: rgba(25, 25, 25, 0.4) !important; }
  .advanced-date-selection button {
    background: #ffffff !important;
    border: solid 1px #dcdcdc !important;
    outline: none !important;
    box-shadow: none !important;
    color: #191919 !important;
    padding: 12px 40px 12px 16px;
    border-radius: 8px;
    position: relative;
    font-size: 14px;
    letter-spacing: -0.2px;
    text-align: left; }
    .advanced-date-selection button .icon-calendar {
      font-size: 20px;
      position: relative;
      top: -2px;
      margin-right: 4px; }
    .advanced-date-selection button .icon-caret {
      font-size: 20px;
      margin-left: 8px;
      position: absolute;
      right: 16px; }
  .advanced-date-selection .dropdown-menu {
    padding: 0;
    border-radius: 8px;
    box-shadow: 0px 2px 20px rgba(25, 25, 25, 0.18);
    border: none !important; }
    .advanced-date-selection .dropdown-menu button {
      border: none !important;
      border-radius: 0;
      padding: 7px 18px 7px 36px;
      position: relative; }
      .advanced-date-selection .dropdown-menu button:nth-child(1) {
        border-top-left-radius: 8px;
        border-top-right-radius: 8px; }
      .advanced-date-selection .dropdown-menu button:nth-last-child(1) {
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
        border-bottom: 0 !important; }
      .advanced-date-selection .dropdown-menu button:hover {
        background: #fff2e5 !important; }
      .advanced-date-selection .dropdown-menu button svg {
        position: absolute;
        left: 16px;
        top: 50%;
        -webkit-transform: translate(0, -50%);
                transform: translate(0, -50%);
        font-size: 16px;
        color: #ff7d00; }

.modal-max-range-filter .modal-content {
  width: 350px; }

.modal-max-range-filter .modal-body p {
  text-align: center; }

.modal-max-range-filter .modal-body button {
  width: 100%; }

.case-modal-calendar .modal-footer {
  position: relative; }
  .case-modal-calendar .modal-footer .button-clear {
    color: #ff7d00;
    border: none;
    background: none;
    font-size: 16px;
    letter-spacing: -0.5px;
    position: absolute;
    left: 0;
    top: 0; }
    .case-modal-calendar .modal-footer .button-clear svg {
      font-size: 19px;
      position: relative;
      top: -1px;
      margin-right: 4px; }
    .case-modal-calendar .modal-footer .button-clear:hover {
      color: #e87200; }
  .case-modal-calendar .modal-footer button {
    width: 181px; }

.DayPicker-Day--disabled {
  cursor: not-allowed !important; }

.broadcast-message-main-wrapper .btn {
  border: 0; }

.broadcast-message-main-wrapper .view-broadcast-message-detail-middle {
  padding: 16px;
  display: flex;
  border: solid 1px #dcdcdc;
  margin-bottom: 24px;
  max-width: 100%;
  overflow: auto; }
  .broadcast-message-main-wrapper .view-broadcast-message-detail-middle > div svg {
    margin-right: 5px;
    vertical-align: -2.4px; }
  .broadcast-message-main-wrapper .view-broadcast-message-detail-middle > div img {
    margin-right: 5px;
    margin-bottom: 4px;
    position: relative;
    top: 1px; }
  .broadcast-message-main-wrapper .view-broadcast-message-detail-middle p {
    margin-top: 8px; }
  .broadcast-message-main-wrapper .view-broadcast-message-detail-middle .view-broadcast-message-detail-middle-category p {
    margin-bottom: 0; }
  .broadcast-message-main-wrapper .view-broadcast-message-detail-middle .view-broadcast-message-detail-middle-channel div > img {
    margin-bottom: 9px; }
  .broadcast-message-main-wrapper .view-broadcast-message-detail-middle .view-broadcast-message-detail-middle-channel > div {
    margin-top: 8px; }
  .broadcast-message-main-wrapper .view-broadcast-message-detail-middle .view-broadcast-message-detail-middle-status .colored-info-box {
    margin-top: 10px;
    margin-bottom: 1rem; }
    .broadcast-message-main-wrapper .view-broadcast-message-detail-middle .view-broadcast-message-detail-middle-status .colored-info-box p {
      color: #ffffff;
      margin-top: 0 !important; }
  .broadcast-message-main-wrapper .view-broadcast-message-detail-middle .view-broadcast-message-detail-middle-content-body {
    max-width: 568px; }
    .broadcast-message-main-wrapper .view-broadcast-message-detail-middle .view-broadcast-message-detail-middle-content-body > img {
      margin-bottom: 5px !important; }
    .broadcast-message-main-wrapper .view-broadcast-message-detail-middle .view-broadcast-message-detail-middle-content-body .message-content {
      margin-top: 20px; }
      .broadcast-message-main-wrapper .view-broadcast-message-detail-middle .view-broadcast-message-detail-middle-content-body .message-content img {
        width: 200px;
        height: 200px;
        object-fit: cover;
        border-radius: 8px;
        cursor: pointer;
        margin: 0 0 8px 0; }
      .broadcast-message-main-wrapper .view-broadcast-message-detail-middle .view-broadcast-message-detail-middle-content-body .message-content .content-caption {
        padding-top: 10px;
        border-top: solid 1px #dcdcdc;
        margin-top: 0 !important; }
    .broadcast-message-main-wrapper .view-broadcast-message-detail-middle .view-broadcast-message-detail-middle-content-body .file-url {
      cursor: pointer; }
      .broadcast-message-main-wrapper .view-broadcast-message-detail-middle .view-broadcast-message-detail-middle-content-body .file-url img {
        width: 24px !important;
        height: 24px !important;
        margin-right: 7px; }
      .broadcast-message-main-wrapper .view-broadcast-message-detail-middle .view-broadcast-message-detail-middle-content-body .file-url div > p {
        margin-top: 2px;
        font-weight: bold;
        font-size: 14px;
        color: #ff7e00;
        line-height: 21px;
        letter-spacing: -0.4px;
        border-bottom: solid 1px #ff7e00; }
  .broadcast-message-main-wrapper .view-broadcast-message-detail-middle.view-broadcast-message-detail-middle-total-status {
    font-weight: bold; }
    .broadcast-message-main-wrapper .view-broadcast-message-detail-middle.view-broadcast-message-detail-middle-total-status > div {
      margin-right: 9%; }
      .broadcast-message-main-wrapper .view-broadcast-message-detail-middle.view-broadcast-message-detail-middle-total-status > div:nth-last-child(1) {
        margin-right: 0; }
    .broadcast-message-main-wrapper .view-broadcast-message-detail-middle.view-broadcast-message-detail-middle-total-status .flexing {
      font-weight: bold;
      font-size: 14px;
      line-height: 21px;
      letter-spacing: -0.4px; }
    .broadcast-message-main-wrapper .view-broadcast-message-detail-middle.view-broadcast-message-detail-middle-total-status .total {
      font-size: 24px;
      line-height: 30px;
      letter-spacing: -0.6px; }

.broadcast-message-list-title {
  color: #191919;
  font-size: 20px;
  letter-spacing: -0.6px;
  line-height: 24px;
  padding-bottom: 16px;
  border-bottom: solid 1px rgba(25, 25, 25, 0.1); }

.broadcast-message-list-tool-wrapper {
  width: 100%;
  position: relative;
  margin-top: 24px;
  margin-bottom: 32px; }

.broadcast-message-list-info {
  font-size: 16px;
  letter-spacing: -0.3px;
  color: rgba(25, 25, 25, 0.8);
  line-height: 1.5;
  padding: 0 208px 12px 0;
  margin-bottom: 24px;
  border-bottom: solid 1px #dcdcdc; }

.broadcast-message-list-tool-wrapper button.button-new-message {
  position: absolute;
  right: 0;
  top: 50%;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%);
  padding: 11px 0;
  width: 200px; }

.broadcast-message-list-tool-wrapper button.button-new-message svg {
  position: relative;
  top: -1px;
  margin-right: 5px; }

.broadcast-message-list-table-wrapper {
  width: 100%;
  position: relative; }

.table-broadcast-message-list thead tr {
  background: #ffffff; }
  .table-broadcast-message-list thead tr td:last-child {
    width: 90px; }
    .table-broadcast-message-list thead tr td:last-child .custom-select-option-value-wrapper {
      width: 55px !important;
      height: 23px;
      padding: 2px 8px 2px 12px !important;
      border-radius: 4px !important; }
      .table-broadcast-message-list thead tr td:last-child .custom-select-option-value-wrapper p {
        font-size: 12px;
        font-weight: bold; }
      .table-broadcast-message-list thead tr td:last-child .custom-select-option-value-wrapper .custom-select-option-value {
        padding: 0; }
      .table-broadcast-message-list thead tr td:last-child .custom-select-option-value-wrapper svg {
        right: 3px !important;
        height: 15px;
        top: 11px !important;
        position: absolute !important;
        margin-right: unset !important; }

.table-broadcast-message-list tbody td {
  position: relative; }
  .table-broadcast-message-list tbody td.col_view svg {
    position: relative;
    top: -1px !important; }
  .table-broadcast-message-list tbody td:nth-child(1) div {
    display: flex; }
    .table-broadcast-message-list tbody td:nth-child(1) div img {
      margin-right: 4px;
      margin-top: 2px; }
  .table-broadcast-message-list tbody td:last-child {
    color: #ff7e00;
    font-weight: 700; }
    .table-broadcast-message-list tbody td:last-child a {
      color: #ff7e00; }
    .table-broadcast-message-list tbody td:last-child svg {
      position: relative;
      color: #ff7e00;
      font-size: 16px;
      margin-right: 4px;
      right: 0;
      top: -2px; }
  .table-broadcast-message-list tbody td .medium-logo {
    width: 16px;
    height: 16px; }

.table-broadcast-message-list button svg {
  position: relative !important;
  right: unset !important;
  top: -2px !important;
  margin-right: 5px; }

.table-broadcast-message-list .col-action-button-wrapper {
  position: absolute;
  right: 7px;
  top: 50%;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%);
  /* display: none; */ }

.table-broadcast-message-list td {
  max-width: unset !important; }

.new-message-modal .modal-content {
  width: 712px; }

.new-message-modal.new-message-select-channel .modal-content {
  width: 512px; }

.new-message-modal .modal-body {
  padding: 32px; }
  .new-message-modal .modal-body .new-message-wrapper {
    position: relative; }
    .new-message-modal .modal-body .new-message-wrapper .comp-back-button {
      margin: 0 0 20px; }
    .new-message-modal .modal-body .new-message-wrapper .new-message-title {
      font-size: 20px;
      letter-spacing: -0.6px;
      color: #191919;
      line-height: 1;
      margin: 6px 0 0; }
    .new-message-modal .modal-body .new-message-wrapper .new-message-description {
      font-size: 16px;
      letter-spacing: -0.3px;
      color: rgba(25, 25, 25, 0.8);
      line-height: 1;
      margin: 6px 0 0; }
    .new-message-modal .modal-body .new-message-wrapper .close-modal-new-message {
      position: absolute;
      right: 0;
      top: 0;
      font-size: 24px;
      cursor: pointer; }
      .new-message-modal .modal-body .new-message-wrapper .close-modal-new-message:hover {
        color: #ff7d00; }
  .new-message-modal .modal-body .new-message-channel-option-container label {
    font-size: 14px;
    letter-spacing: -0.4px;
    margin: 20px 0 4px; }
  .new-message-modal .modal-body .new-message-channel-option-container .new-message-channel-option-wrapper {
    width: 100%;
    position: relative; }
    .new-message-modal .modal-body .new-message-channel-option-container .new-message-channel-option-wrapper .new-message-channel-option {
      cursor: pointer;
      border-radius: 8px;
      border: solid 1px #dcdcdc;
      color: #191919 !important;
      font-size: 16px;
      letter-spacing: -0.4px;
      padding: 0 24px;
      height: 40px;
      line-height: 38px;
      vertical-align: top;
      margin: 0 4px 0 0;
      display: inline-block; }
      .new-message-modal .modal-body .new-message-channel-option-container .new-message-channel-option-wrapper .new-message-channel-option:hover {
        background: #FFF2E5;
        color: #ff7d00;
        border: solid 1px #ff7d00; }
        .new-message-modal .modal-body .new-message-channel-option-container .new-message-channel-option-wrapper .new-message-channel-option:hover svg {
          color: #ff7d00; }
      .new-message-modal .modal-body .new-message-channel-option-container .new-message-channel-option-wrapper .new-message-channel-option.active-channel {
        background: #FFF2E5;
        border: solid 1px rgba(25, 25, 25, 0.05); }
        .new-message-modal .modal-body .new-message-channel-option-container .new-message-channel-option-wrapper .new-message-channel-option.active-channel svg {
          color: #ff7d00; }
      .new-message-modal .modal-body .new-message-channel-option-container .new-message-channel-option-wrapper .new-message-channel-option img {
        font-size: 16px;
        letter-spacing: -0.5px;
        width: 16px;
        height: 16px;
        margin-right: 4px;
        position: relative;
        top: -1px; }
  .new-message-modal .modal-body .new-message-option-container {
    width: 100%;
    margin-top: 20px;
    text-align: center; }
    .new-message-modal .modal-body .new-message-option-container .new-message-option-list {
      cursor: pointer;
      text-align: center;
      width: calc((100% / 3) - 8px);
      margin-right: 12px;
      display: inline-block;
      vertical-align: top;
      border-radius: 8px;
      border: solid 1px rgba(25, 25, 25, 0.1);
      padding: 24px;
      height: 178px; }
      .new-message-modal .modal-body .new-message-option-container .new-message-option-list:nth-last-child(1) {
        margin-right: 0; }
      .new-message-modal .modal-body .new-message-option-container .new-message-option-list img {
        display: inline-block;
        margin: 0 0 14px; }
      .new-message-modal .modal-body .new-message-option-container .new-message-option-list b {
        line-height: 1;
        font-size: 16px;
        letter-spacing: -0.5px;
        color: rgba(25, 25, 25, 0.6); }
      .new-message-modal .modal-body .new-message-option-container .new-message-option-list p {
        letter-spacing: -0.2px;
        color: rgba(25, 25, 25, 0.8);
        font-size: 14px;
        line-height: 21px; }
      .new-message-modal .modal-body .new-message-option-container .new-message-option-list:hover {
        border: solid 1px rgba(25, 25, 25, 0.05);
        background: rgba(25, 25, 25, 0.05); }
        .new-message-modal .modal-body .new-message-option-container .new-message-option-list:hover b {
          color: #191919; }
        .new-message-modal .modal-body .new-message-option-container .new-message-option-list:hover p {
          color: #191919; }
      .new-message-modal .modal-body .new-message-option-container .new-message-option-list.active-message-type {
        border: solid 1px rgba(25, 25, 25, 0.05);
        background: rgba(25, 25, 25, 0.05); }
        .new-message-modal .modal-body .new-message-option-container .new-message-option-list.active-message-type b {
          color: #191919; }
        .new-message-modal .modal-body .new-message-option-container .new-message-option-list.active-message-type p {
          color: #191919; }

.new-message-modal .cancel-broadcast {
  margin: 20px 8px 0 0;
  width: calc(50% - 4px);
  height: 40px; }

.new-message-modal .continue-broadcast {
  margin: 20px 0 0 0;
  width: calc(50% - 4px);
  height: 40px; }

.bc-tootlip-toggle {
  margin-left: 4px; }

.view-broadcast-message-detail .flexing {
  display: flex;
  margin: 4px 0 0 !important; }

.view-broadcast-message-detail .view-broadcast-message-detail-top {
  padding: 10px 0 20px 0;
  border-bottom: solid 1px #dcdcdc;
  margin-bottom: 24px;
  position: relative; }
  .view-broadcast-message-detail .view-broadcast-message-detail-top b {
    font-size: 20px !important;
    letter-spacing: -0.6px;
    font-weight: 700; }
  .view-broadcast-message-detail .view-broadcast-message-detail-top .button-export {
    position: absolute;
    right: 0;
    bottom: 10px; }
    .view-broadcast-message-detail .view-broadcast-message-detail-top .button-export b {
      color: unset !important;
      font-size: 16px !important; }
  .view-broadcast-message-detail .view-broadcast-message-detail-top span {
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.5px;
    color: rgba(25, 25, 25, 0.4);
    position: relative;
    top: 2px; }
  .view-broadcast-message-detail .view-broadcast-message-detail-top button b {
    font-size: 16px !important;
    color: #ffffff !important;
    letter-spacing: -.5px !important; }
  .view-broadcast-message-detail .view-broadcast-message-detail-top button.button-edit {
    position: absolute;
    right: 134px;
    top: 6px; }
  .view-broadcast-message-detail .view-broadcast-message-detail-top button.button-delete {
    position: absolute;
    right: 0;
    top: 6px; }

.view-broadcast-message-detail .colored-info-box p {
  color: #ffffff;
  margin-top: 0 !important; }

.view-broadcast-message-detail div p,
.view-broadcast-message-detail div b {
  font-size: 14px;
  letter-spacing: -0.4px;
  color: rgba(0, 0, 0, 0.8);
  font-family: PTRootUI !important; }
  .view-broadcast-message-detail div p.yes-text,
  .view-broadcast-message-detail div b.yes-text {
    color: #19C700; }
  .view-broadcast-message-detail div p.no-text,
  .view-broadcast-message-detail div b.no-text {
    color: #E02E2E; }

.view-broadcast-message-detail div p {
  letter-spacing: -0.2px; }

.view-broadcast-message-detail div b {
  font-weight: 700 !important;
  vertical-align: top; }

.view-broadcast-message-detail div .disabled-form div {
  background-color: rgba(25, 25, 25, 0.05);
  border: 1px solid rgba(25, 25, 25, 0.1);
  border-radius: 8px;
  padding: 12px 16px;
  width: 400px;
  margin: 8.5px 0 24px 0;
  word-break: break-word; }
  .view-broadcast-message-detail div .disabled-form div p {
    font-size: 16px; }

.view-broadcast-message-detail .bc-list-status-tool-wrapper {
  position: relative; }

.view-broadcast-message-detail .view-broadcast-message-detail-middle {
  padding: 0 0 12px; }
  .view-broadcast-message-detail .view-broadcast-message-detail-middle .dropdownBcFilter {
    width: 200px;
    left: unset;
    margin-left: 8px;
    display: inline-block; }
    .view-broadcast-message-detail .view-broadcast-message-detail-middle .dropdownBcFilter button {
      width: 200px; }
    .view-broadcast-message-detail .view-broadcast-message-detail-middle .dropdownBcFilter .dropdown-menu {
      width: 200px; }
  .view-broadcast-message-detail .view-broadcast-message-detail-middle .yes-color b {
    color: #19c700; }
  .view-broadcast-message-detail .view-broadcast-message-detail-middle .no-color b {
    color: #ff3f57; }
  .view-broadcast-message-detail .view-broadcast-message-detail-middle.message-number-wrapper {
    border-bottom: solid 1px #dcdcdc;
    margin-bottom: 10px; }
    .view-broadcast-message-detail .view-broadcast-message-detail-middle.message-number-wrapper:nth-last-child(1) {
      margin-bottom: 0;
      border-bottom: 0; }
  .view-broadcast-message-detail .view-broadcast-message-detail-middle > div .icon-sender {
    margin-right: 5px;
    vertical-align: top;
    position: relative;
    top: 1px; }
  .view-broadcast-message-detail .view-broadcast-message-detail-middle > div img {
    margin-right: 5px;
    position: relative;
    top: -1px; }
    .view-broadcast-message-detail .view-broadcast-message-detail-middle > div img.label-icon {
      margin-bottom: 0;
      position: relative;
      vertical-align: top;
      top: 1px; }
    .view-broadcast-message-detail .view-broadcast-message-detail-middle > div img.channel-icon {
      margin: 0 5px 0 0 !important;
      position: relative;
      top: -8px; }
  .view-broadcast-message-detail .view-broadcast-message-detail-middle .view-broadcast-message-detail-middle-category p {
    margin-bottom: 0; }
  .view-broadcast-message-detail .view-broadcast-message-detail-middle .view-broadcast-message-detail-middle-channel > div {
    margin: 8px 0 0; }
  .view-broadcast-message-detail .view-broadcast-message-detail-middle .view-broadcast-message-detail-middle-status .colored-info-box {
    margin-top: 6px;
    margin-bottom: 1rem; }
    .view-broadcast-message-detail .view-broadcast-message-detail-middle .view-broadcast-message-detail-middle-status .colored-info-box p {
      color: #ffffff;
      margin-top: 0 !important; }
  .view-broadcast-message-detail .view-broadcast-message-detail-middle .view-broadcast-message-detail-middle-content-body {
    max-width: 568px; }
    .view-broadcast-message-detail .view-broadcast-message-detail-middle .view-broadcast-message-detail-middle-content-body > img {
      margin-bottom: 5px !important; }
    .view-broadcast-message-detail .view-broadcast-message-detail-middle .view-broadcast-message-detail-middle-content-body .message-content {
      margin: 4px 0 0 0; }
      .view-broadcast-message-detail .view-broadcast-message-detail-middle .view-broadcast-message-detail-middle-content-body .message-content img {
        width: 200px;
        height: 200px;
        object-fit: cover;
        border-radius: 8px;
        cursor: pointer;
        margin: 0 0 20px 0; }
      .view-broadcast-message-detail .view-broadcast-message-detail-middle .view-broadcast-message-detail-middle-content-body .message-content .content-caption {
        margin-top: 0 !important; }
    .view-broadcast-message-detail .view-broadcast-message-detail-middle .view-broadcast-message-detail-middle-content-body .file-url {
      cursor: pointer; }
      .view-broadcast-message-detail .view-broadcast-message-detail-middle .view-broadcast-message-detail-middle-content-body .file-url img {
        width: 24px !important;
        height: 24px !important;
        margin-right: 7px; }
      .view-broadcast-message-detail .view-broadcast-message-detail-middle .view-broadcast-message-detail-middle-content-body .file-url div > p {
        margin-top: 2px;
        font-weight: bold;
        font-size: 14px;
        color: #ff7e00;
        line-height: 21px;
        letter-spacing: -0.4px;
        border-bottom: solid 1px #ff7e00; }
  .view-broadcast-message-detail .view-broadcast-message-detail-middle .view-broadcast-message-detail-middle-total-status {
    font-weight: bold;
    max-width: 100%;
    overflow: auto;
    border: solid 1px #dcdcdc;
    border-radius: 8px;
    padding: 16px 32px;
    margin: 4px 0 24px 0 !important; }
    .view-broadcast-message-detail .view-broadcast-message-detail-middle .view-broadcast-message-detail-middle-total-status .total-status-wrapper {
      width: calc(100% / 6);
      display: inline-block;
      padding: 0 12px 0 0; }
    .view-broadcast-message-detail .view-broadcast-message-detail-middle .view-broadcast-message-detail-middle-total-status .flexing {
      font-weight: bold;
      font-size: 14px;
      line-height: 21px;
      letter-spacing: -0.4px; }
    .view-broadcast-message-detail .view-broadcast-message-detail-middle .view-broadcast-message-detail-middle-total-status .total {
      padding: 0 0 0 21px;
      font-size: 16px;
      line-height: 30px;
      letter-spacing: -0.3px; }
  .view-broadcast-message-detail .view-broadcast-message-detail-middle:nth-last-child(1) {
    padding: 0; }

.view-broadcast-message-detail .view-broadcast-message-detail-bottom {
  padding: 24px 0 0; }
  .view-broadcast-message-detail .view-broadcast-message-detail-bottom .view-message-box {
    position: relative;
    width: 100%; }
  .view-broadcast-message-detail .view-broadcast-message-detail-bottom .view-message-box-left {
    display: inline-block;
    vertical-align: top;
    width: 400px; }
  .view-broadcast-message-detail .view-broadcast-message-detail-bottom .view-message-box-right {
    width: calc(100% - 400px);
    vertical-align: top;
    display: inline-block;
    padding-left: 24px; }

.view-broadcast-message-detail div:last-child .view-broadcast-message-detail-bottom-footer p {
  margin-bottom: 0; }

.view-broadcast-message-detail .broadcast-recipients-table-wrapper {
  width: 100%;
  position: relative;
  margin-top: 24px; }

.view-broadcast-message-detail .table-broadcast-recipients #retrieve-data-loading-page {
  display: block; }
  .view-broadcast-message-detail .table-broadcast-recipients #retrieve-data-loading-page .fail-retrive-data {
    display: block; }
    .view-broadcast-message-detail .table-broadcast-recipients #retrieve-data-loading-page .fail-retrive-data p {
      font-size: 14px !important; }

.view-broadcast-message-detail .table-broadcast-recipients .col_name, .view-broadcast-message-detail .table-broadcast-recipients .col_alias, .view-broadcast-message-detail .table-broadcast-recipients .col_phone {
  width: 150px; }

.view-broadcast-message-detail .table-broadcast-recipients .col_status, .view-broadcast-message-detail .table-broadcast-recipients .col_responded, .view-broadcast-message-detail .table-broadcast-recipients .col_last_update, .view-broadcast-message-detail .table-broadcast-recipients .col_reason, .view-broadcast-message-detail .table-broadcast-recipients .col_history {
  width: 90px; }

.view-broadcast-message-detail .table-broadcast-recipients thead tr {
  background: #ffffff; }
  .view-broadcast-message-detail .table-broadcast-recipients thead tr td:last-child {
    width: 90px; }
    .view-broadcast-message-detail .table-broadcast-recipients thead tr td:last-child .custom-select-option-value-wrapper {
      width: 55px !important;
      height: 23px;
      padding: 2px 8px 2px 12px !important;
      border-radius: 4px !important; }
      .view-broadcast-message-detail .table-broadcast-recipients thead tr td:last-child .custom-select-option-value-wrapper p {
        font-size: 12px;
        font-weight: bold; }
      .view-broadcast-message-detail .table-broadcast-recipients thead tr td:last-child .custom-select-option-value-wrapper .custom-select-option-value {
        padding: 0; }
      .view-broadcast-message-detail .table-broadcast-recipients thead tr td:last-child .custom-select-option-value-wrapper svg {
        right: 3px !important;
        height: 15px;
        top: 11px !important;
        position: absolute !important;
        margin-right: unset !important; }

.view-broadcast-message-detail .table-broadcast-recipients tbody td {
  position: relative; }
  .view-broadcast-message-detail .table-broadcast-recipients tbody td #retrieve-data-loading-page {
    padding: 0;
    text-align: center;
    display: block; }
    .view-broadcast-message-detail .table-broadcast-recipients tbody td #retrieve-data-loading-page .loading-retrieve-data {
      display: block; }
      .view-broadcast-message-detail .table-broadcast-recipients tbody td #retrieve-data-loading-page .loading-retrieve-data div {
        display: inline-block; }
      .view-broadcast-message-detail .table-broadcast-recipients tbody td #retrieve-data-loading-page .loading-retrieve-data .lds-ring {
        left: -8px;
        top: 5px; }
  .view-broadcast-message-detail .table-broadcast-recipients tbody td.col_status P {
    font-size: 12px !important; }
  .view-broadcast-message-detail .table-broadcast-recipients tbody td.col_history .main-button-26 {
    padding: 0 8px !important; }
    .view-broadcast-message-detail .table-broadcast-recipients tbody td.col_history .main-button-26 b {
      color: var(--main-dark-orange) !important; }
  .view-broadcast-message-detail .table-broadcast-recipients tbody td.col_responded b {
    font-size: 12px !important;
    position: relative;
    top: 2px; }
  .view-broadcast-message-detail .table-broadcast-recipients tbody td:nth-child(1) div {
    display: flex; }
    .view-broadcast-message-detail .table-broadcast-recipients tbody td:nth-child(1) div p {
      font-size: 12px !important;
      letter-spacing: -0.4px; }
    .view-broadcast-message-detail .table-broadcast-recipients tbody td:nth-child(1) div img {
      margin-right: 4px;
      margin-top: 2px; }
  .view-broadcast-message-detail .table-broadcast-recipients tbody td:last-child {
    color: #ff7e00;
    font-weight: 700; }
    .view-broadcast-message-detail .table-broadcast-recipients tbody td:last-child a {
      color: #ff7e00; }
    .view-broadcast-message-detail .table-broadcast-recipients tbody td:last-child svg {
      position: relative;
      color: #ff7e00;
      font-size: 16px;
      margin-right: 4px;
      right: 0;
      top: -2px; }
  .view-broadcast-message-detail .table-broadcast-recipients tbody td .medium-logo {
    width: 16px;
    height: 16px; }

.view-broadcast-message-detail .custom-select-option-value {
  margin-top: 0px !important; }

.modal-image-broadcast.modal-dialog {
  width: 100vw !important; }

.modal-image-broadcast .modal-content {
  width: 100%;
  height: 100%;
  background: none; }

.modal-image-broadcast .modal-content .modal-body {
  width: 100%;
  height: 100%;
  background: rgba(25, 25, 25, 0.8); }

.modal-image-broadcast .modal-content .modal-body img.image-preview-val {
  max-width: calc(100vw - 130px);
  max-height: calc(100vh - 130px);
  background: #ffffff; }

.modal-image-broadcast .modal-content .modal-body .image-wrapper {
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  text-align: center; }
  .modal-image-broadcast .modal-content .modal-body .image-wrapper figure {
    margin: 0; }
    .modal-image-broadcast .modal-content .modal-body .image-wrapper figure.zoom {
      background-size: 180%;
      background-position: -100% -100%;
      background-repeat: no-repeat;
      position: relative;
      width: 100%;
      overflow: hidden;
      cursor: -webkit-zoom-in;
      cursor: zoom-in; }
      .modal-image-broadcast .modal-content .modal-body .image-wrapper figure.zoom img {
        -webkit-transition: opacity 0.5s;
        transition: opacity 0.5s;
        display: inline-block; }
        .modal-image-broadcast .modal-content .modal-body .image-wrapper figure.zoom img:hover {
          opacity: 0; }

.modal-image-broadcast .modal-content .modal-body .close-modal-image-broadcast {
  position: absolute;
  top: 20px;
  right: 20px;
  color: #ffffff;
  font-size: 40px;
  cursor: pointer;
  -webkit-transition: ease-in-out 0.1s;
  transition: ease-in-out 0.1s; }

.modal-image-broadcast .modal-content .modal-body .close-modal-image-broadcast:hover {
  color: #c9c9c9; }

.modal-confirmation-delete-broadcast-scheduled button {
  width: calc(50% - 6px) !important;
  display: inline-block;
  margin-right: 12px; }
  .modal-confirmation-delete-broadcast-scheduled button:nth-last-child(1) {
    margin-right: 0 !important; }

.bc-history-wrapper {
  width: 100vw;
  height: 100vh;
  background: rgba(25, 25, 25, 0.8);
  position: fixed;
  top: 0;
  /* right: -100vw; */
  visibility: hidden;
  opacity: 0;
  right: 0;
  /* transition: visibility 0s, opacity 0.3s ease-in-out; */
  -webkit-transition: visibility .3s, opacity 0.5s ease-in-out;
  transition: visibility .3s, opacity 0.5s ease-in-out;
  z-index: 4; }
  .bc-history-wrapper.active-bc-history {
    opacity: 1;
    visibility: visible;
    -webkit-transition: visibility 0s, opacity 0.5s ease-in-out;
    transition: visibility 0s, opacity 0.5s ease-in-out; }
  .bc-history-wrapper .bc-history-main-wrapper {
    width: 340px;
    -webkit-transition: ease-in-out 0.5s;
    transition: ease-in-out 0.5s;
    background: #ffffff;
    height: calc(100vh - 8px);
    border-top-left-radius: 18px;
    bottom: 0;
    right: -405px;
    position: fixed; }
    .bc-history-wrapper .bc-history-main-wrapper.active-bc-history-main-wrapper {
      right: 0; }
    .bc-history-wrapper .bc-history-main-wrapper .bc-history-close {
      background: #ffffff;
      border-radius: 50%;
      cursor: pointer;
      position: absolute;
      width: 48px;
      height: 48px;
      display: inline-block;
      text-align: center;
      line-height: 45px;
      top: 0;
      left: -64px; }
      .bc-history-wrapper .bc-history-main-wrapper .bc-history-close svg {
        font-size: 24px;
        color: #7D7C7D; }
    .bc-history-wrapper .bc-history-main-wrapper .title-bc-history {
      font-size: 24px;
      letter-spacing: -0.6px;
      padding: 24px;
      border-bottom: solid 1px #dcdcdc;
      margin: 0; }
    .bc-history-wrapper .bc-history-main-wrapper .bc-history-recipient-name {
      border-top: solid 1px #dcdcdc;
      width: 100%;
      padding: 8px 24px 24px 40px; }
      .bc-history-wrapper .bc-history-main-wrapper .bc-history-recipient-name b {
        font-size: 14px;
        letter-spacing: -0.2px; }
      .bc-history-wrapper .bc-history-main-wrapper .bc-history-recipient-name P {
        margin: 0;
        font-size: 14px;
        letter-spacing: -0.2px;
        color: rgba(25, 25, 25, 0.8);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis; }
    .bc-history-wrapper .bc-history-main-wrapper .bc-history-list-wrapper {
      width: 100%;
      height: 100%;
      max-height: 100%; }
      .bc-history-wrapper .bc-history-main-wrapper .bc-history-list-wrapper .loading-bc-history {
        text-align: center;
        color: #ff7d00;
        padding-top: 12px; }
        .bc-history-wrapper .bc-history-main-wrapper .bc-history-list-wrapper .loading-bc-history .lds-ring {
          vertical-align: top;
          top: 3px;
          right: unset;
          margin-right: 4px; }
          .bc-history-wrapper .bc-history-main-wrapper .bc-history-list-wrapper .loading-bc-history .lds-ring div {
            margin: 0; }
      .bc-history-wrapper .bc-history-main-wrapper .bc-history-list-wrapper .on-error {
        text-align: center;
        color: #ff7d00; }
        .bc-history-wrapper .bc-history-main-wrapper .bc-history-list-wrapper .on-error svg {
          position: relative;
          margin-right: 4px;
          top: -1px; }
        .bc-history-wrapper .bc-history-main-wrapper .bc-history-list-wrapper .on-error p {
          cursor: pointer;
          display: inline-block; }
          .bc-history-wrapper .bc-history-main-wrapper .bc-history-list-wrapper .on-error p:hover {
            color: #e87200; }
      .bc-history-wrapper .bc-history-main-wrapper .bc-history-list-wrapper .bc-history-card {
        padding: 8px 20px 8px 52px;
        position: relative;
        margin-bottom: 12px; }
        .bc-history-wrapper .bc-history-main-wrapper .bc-history-list-wrapper .bc-history-card::before {
          position: absolute;
          border-radius: 50%;
          width: 16px;
          height: 16px;
          left: 20px;
          top: 10px;
          content: "";
          border: solid 2px rgba(25, 25, 25, 0.2);
          background: #ffffff;
          z-index: 2; }
        .bc-history-wrapper .bc-history-main-wrapper .bc-history-list-wrapper .bc-history-card::after {
          position: absolute;
          width: 1px;
          height: 100%;
          left: 27px;
          top: 24px;
          content: "";
          border-left: dashed 2px rgba(25, 25, 25, 0.2);
          z-index: 1; }
        .bc-history-wrapper .bc-history-main-wrapper .bc-history-list-wrapper .bc-history-card:nth-child(1)::before {
          border: solid 2px #ff7d00; }
        .bc-history-wrapper .bc-history-main-wrapper .bc-history-list-wrapper .bc-history-card p {
          max-width: 100%; }
          .bc-history-wrapper .bc-history-main-wrapper .bc-history-list-wrapper .bc-history-card p.action-time {
            font-size: 12px;
            letter-spacing: -0.2px;
            color: rgba(25, 25, 25, 0.4);
            margin: 0; }
            .bc-history-wrapper .bc-history-main-wrapper .bc-history-list-wrapper .bc-history-card p.action-time svg {
              position: relative;
              margin-right: 4px;
              top: -1px; }
          .bc-history-wrapper .bc-history-main-wrapper .bc-history-list-wrapper .bc-history-card p.action-text {
            margin: 0;
            font-size: 14px;
            letter-spacing: -0.4px;
            color: rgba(25, 25, 25, 0.6);
            word-break: break-word; }
        .bc-history-wrapper .bc-history-main-wrapper .bc-history-list-wrapper .bc-history-card:nth-last-child(1)::after {
          display: none; }
        .bc-history-wrapper .bc-history-main-wrapper .bc-history-list-wrapper .bc-history-card:nth-child(1) {
          margin-top: 12px; }

.img-overlay {
  color: white;
  position: relative;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  cursor: pointer; }
  .img-overlay img {
    display: block; }
  .img-overlay img,
  .img-overlay svg {
    border-radius: 8px;
    width: 56px;
    height: 56px;
    object-fit: cover;
    margin: 8px 0; }
  .img-overlay .eye-icon {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 8px;
    background: rgba(25, 25, 25, 0.2);
    z-index: 1; }
    .img-overlay .eye-icon svg {
      width: 24px;
      height: 24px;
      position: absolute !important;
      top: 50% !important;
      left: 50%;
      margin-left: -12px;
      margin-top: -12px; }
  .img-overlay:hover .eye-icon {
    display: block; }
  .img-overlay .x-button {
    background-color: #ff3f57;
    color: #fff;
    display: block;
    width: 24px;
    height: 24px;
    position: absolute;
    top: -8px;
    right: -7px;
    border-radius: 50%; }
    .img-overlay .x-button svg {
      width: 20px;
      height: 20px;
      position: absolute;
      top: -6px;
      right: 2px; }
  .img-overlay .LoadingRoll_1234fed {
    position: absolute;
    height: 28px;
    left: 50%;
    margin-left: -24px; }
    .img-overlay .LoadingRoll_1234fed .lds-ring div {
      border: 5px solid #fff;
      width: 46px;
      height: 46px;
      border-color: #ffffff transparent transparent transparent; }

.img-overlay::before,
.loading-img-overlay::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #000;
  border-radius: 8px;
  -webkit-transition: 0.5s ease;
  transition: 0.5s ease; }

.img-overlay::before {
  opacity: 0; }

.loading-img-overlay::before,
.img-overlay:hover::before {
  opacity: 0.2; }

.modal-preview-image-broadcast-detail .modal-content {
  text-align: center;
  background: transparent;
  position: fixed;
  height: 100%;
  -webkit-transform: unset;
          transform: unset;
  width: 100%;
  box-shadow: none;
  border: 0;
  top: 0;
  left: 0; }
  .modal-preview-image-broadcast-detail .modal-content img {
    max-width: 80vw;
    max-height: 80vh;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  .modal-preview-image-broadcast-detail .modal-content svg {
    color: #ffffff;
    cursor: pointer;
    position: fixed;
    top: 24px;
    right: 24px;
    font-size: 32px; }
    .modal-preview-image-broadcast-detail .modal-content svg:hover {
      color: #e9e9e9; }

.view-broadcast-message-detail .flexing {
  display: flex;
  margin: 4px 0 0 !important; }

.view-broadcast-message-detail .view-broadcast-message-detail-top-edit {
  padding: 10px 0 10px 0;
  border-bottom: solid 1px #dcdcdc;
  margin-bottom: 24px;
  position: relative; }
  .view-broadcast-message-detail .view-broadcast-message-detail-top-edit svg {
    margin-right: 4px;
    position: relative;
    top: -3px; }
  .view-broadcast-message-detail .view-broadcast-message-detail-top-edit p {
    font-size: 16px;
    color: rgba(25, 25, 25, 0.8);
    letter-spacing: -0.3px;
    margin: 0; }
  .view-broadcast-message-detail .view-broadcast-message-detail-top-edit b {
    font-size: 20px !important;
    letter-spacing: -0.6px;
    font-weight: 700; }
  .view-broadcast-message-detail .view-broadcast-message-detail-top-edit .button-export {
    position: absolute;
    right: 0;
    bottom: 10px; }
    .view-broadcast-message-detail .view-broadcast-message-detail-top-edit .button-export b {
      color: #ffffff !important;
      font-size: 16px !important; }
  .view-broadcast-message-detail .view-broadcast-message-detail-top-edit span {
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.5px;
    color: rgba(25, 25, 25, 0.4);
    position: relative;
    top: 2px; }
  .view-broadcast-message-detail .view-broadcast-message-detail-top-edit button b {
    font-size: 16px !important;
    color: #ffffff !important;
    letter-spacing: -.5px !important; }
  .view-broadcast-message-detail .view-broadcast-message-detail-top-edit button.button-edit {
    position: absolute;
    right: 134px;
    top: 6px; }
  .view-broadcast-message-detail .view-broadcast-message-detail-top-edit button.button-delete {
    position: absolute;
    right: 0;
    top: 6px; }

.view-broadcast-message-detail .colored-info-box p {
  color: #ffffff;
  margin-top: 0 !important; }

.view-broadcast-message-detail div p,
.view-broadcast-message-detail div b {
  font-size: 14px;
  letter-spacing: -0.4px;
  color: rgba(0, 0, 0, 0.8);
  font-family: PTRootUI !important; }
  .view-broadcast-message-detail div p.yes-text,
  .view-broadcast-message-detail div b.yes-text {
    color: #19C700; }
  .view-broadcast-message-detail div p.no-text,
  .view-broadcast-message-detail div b.no-text {
    color: #E02E2E; }

.view-broadcast-message-detail div p {
  letter-spacing: -0.2px; }

.view-broadcast-message-detail div b {
  font-weight: 700 !important;
  vertical-align: top; }

.view-broadcast-message-detail div .disabled-form div {
  background-color: rgba(25, 25, 25, 0.05);
  border: 1px solid rgba(25, 25, 25, 0.1);
  border-radius: 8px;
  padding: 12px 16px;
  width: 400px;
  margin: 8.5px 0 24px 0;
  word-break: break-word; }
  .view-broadcast-message-detail div .disabled-form div p {
    font-size: 16px; }

.view-broadcast-message-detail .view-broadcast-message-detail-middle-edit {
  padding: 0 0 24px; }
  .view-broadcast-message-detail .view-broadcast-message-detail-middle-edit .broadcast-message-schedule {
    border-bottom: solid 1px #dcdcdc;
    padding: 0 0 22px 0;
    margin: 0 0 24px 0; }
    .view-broadcast-message-detail .view-broadcast-message-detail-middle-edit .broadcast-message-schedule .broadcast-schedule-title {
      margin: 24px 0; }
      .view-broadcast-message-detail .view-broadcast-message-detail-middle-edit .broadcast-message-schedule .broadcast-schedule-title svg {
        position: relative;
        top: 3px;
        margin-right: 4px; }
    .view-broadcast-message-detail .view-broadcast-message-detail-middle-edit .broadcast-message-schedule .broadcast-schedule-time-wrapper {
      width: 343px; }
      .view-broadcast-message-detail .view-broadcast-message-detail-middle-edit .broadcast-message-schedule .broadcast-schedule-time-wrapper .time-select-info b {
        font-size: 12px;
        letter-spacing: -0.4px;
        color: rgba(25, 25, 25, 0.8);
        margin: 8px 0 0; }
      .view-broadcast-message-detail .view-broadcast-message-detail-middle-edit .broadcast-message-schedule .broadcast-schedule-time-wrapper .time-select-info svg {
        position: relative;
        top: 2px;
        margin-right: 4px; }
      .view-broadcast-message-detail .view-broadcast-message-detail-middle-edit .broadcast-message-schedule .broadcast-schedule-time-wrapper .broadcast-date-select-wrapper {
        display: inline-block;
        margin-right: 24px;
        width: 173px;
        vertical-align: top; }
        .view-broadcast-message-detail .view-broadcast-message-detail-middle-edit .broadcast-message-schedule .broadcast-schedule-time-wrapper .broadcast-date-select-wrapper .broadcast-date-value-wrapper {
          border-radius: 8px;
          height: 48px;
          width: 100%;
          position: relative;
          padding: 0 48px 0 16px;
          border: solid 1px #dcdcdc;
          cursor: pointer;
          line-height: 46px; }
          .view-broadcast-message-detail .view-broadcast-message-detail-middle-edit .broadcast-message-schedule .broadcast-schedule-time-wrapper .broadcast-date-select-wrapper .broadcast-date-value-wrapper:hover {
            border-color: #ff7d00; }
          .view-broadcast-message-detail .view-broadcast-message-detail-middle-edit .broadcast-message-schedule .broadcast-schedule-time-wrapper .broadcast-date-select-wrapper .broadcast-date-value-wrapper svg {
            position: absolute;
            right: 16px;
            top: 50%;
            -webkit-transform: translate(0, -50%);
                    transform: translate(0, -50%); }
      .view-broadcast-message-detail .view-broadcast-message-detail-middle-edit .broadcast-message-schedule .broadcast-schedule-time-wrapper .broadcast-time-input-wrapper {
        display: inline-block;
        width: 146px;
        vertical-align: top; }
        .view-broadcast-message-detail .view-broadcast-message-detail-middle-edit .broadcast-message-schedule .broadcast-schedule-time-wrapper .broadcast-time-input-wrapper .broadcast-time-value-wrapper {
          border-radius: 8px;
          height: 48px;
          width: 100%;
          position: relative;
          padding: 0 48px 0 16px; }
          .view-broadcast-message-detail .view-broadcast-message-detail-middle-edit .broadcast-message-schedule .broadcast-schedule-time-wrapper .broadcast-time-input-wrapper .broadcast-time-value-wrapper svg {
            position: absolute;
            right: 16px;
            top: 50%;
            -webkit-transform: translate(0, -50%);
                    transform: translate(0, -50%); }
          .view-broadcast-message-detail .view-broadcast-message-detail-middle-edit .broadcast-message-schedule .broadcast-schedule-time-wrapper .broadcast-time-input-wrapper .broadcast-time-value-wrapper input {
            position: absolute;
            border: solid 1px #dcdcdc;
            width: 100%;
            height: 48px;
            border-radius: 8px;
            left: 0;
            top: 0;
            background: transparent;
            padding: 0 48px 0 16px; }
            .view-broadcast-message-detail .view-broadcast-message-detail-middle-edit .broadcast-message-schedule .broadcast-schedule-time-wrapper .broadcast-time-input-wrapper .broadcast-time-value-wrapper input:focus {
              border-color: #ff7d00; }
  .view-broadcast-message-detail .view-broadcast-message-detail-middle-edit .button-save-changes {
    font-size: 16px !important;
    letter-spacing: -0.5px !important; }
    .view-broadcast-message-detail .view-broadcast-message-detail-middle-edit .button-save-changes b {
      color: #fff !important; }
  .view-broadcast-message-detail .view-broadcast-message-detail-middle-edit.message-number-wrapper {
    border-top: solid 1px #dcdcdc;
    border-bottom: none !important;
    padding-top: 24px;
    margin-bottom: 10px; }
    .view-broadcast-message-detail .view-broadcast-message-detail-middle-edit.message-number-wrapper:nth-last-child(1) {
      margin-bottom: 0;
      border-bottom: 0; }
  .view-broadcast-message-detail .view-broadcast-message-detail-middle-edit > div svg {
    margin-right: 5px;
    vertical-align: top;
    position: relative;
    top: 1px; }
  .view-broadcast-message-detail .view-broadcast-message-detail-middle-edit > div img {
    margin-right: 5px;
    margin-bottom: 4px;
    position: relative;
    top: -1px; }
    .view-broadcast-message-detail .view-broadcast-message-detail-middle-edit > div img.label-icon {
      margin-bottom: 0;
      position: relative;
      vertical-align: top;
      top: 1px; }
    .view-broadcast-message-detail .view-broadcast-message-detail-middle-edit > div img.channel-icon {
      margin: 0 5px 0 0 !important;
      position: relative;
      top: 2px; }
  .view-broadcast-message-detail .view-broadcast-message-detail-middle-edit p {
    margin: 6px 0 0; }
  .view-broadcast-message-detail .view-broadcast-message-detail-middle-edit .view-broadcast-message-detail-middle-edit-category p {
    margin-bottom: 0; }
  .view-broadcast-message-detail .view-broadcast-message-detail-middle-edit .view-broadcast-message-detail-middle-edit-channel > div {
    margin: 8px 0 0; }
  .view-broadcast-message-detail .view-broadcast-message-detail-middle-edit .view-broadcast-message-detail-middle-edit-status .colored-info-box {
    margin-top: 10px;
    margin-bottom: 1rem; }
    .view-broadcast-message-detail .view-broadcast-message-detail-middle-edit .view-broadcast-message-detail-middle-edit-status .colored-info-box p {
      color: #ffffff;
      margin-top: 0 !important; }
  .view-broadcast-message-detail .view-broadcast-message-detail-middle-edit .view-broadcast-message-detail-middle-edit-content-body {
    max-width: 568px; }
    .view-broadcast-message-detail .view-broadcast-message-detail-middle-edit .view-broadcast-message-detail-middle-edit-content-body > img {
      margin-bottom: 5px !important; }
    .view-broadcast-message-detail .view-broadcast-message-detail-middle-edit .view-broadcast-message-detail-middle-edit-content-body .message-content {
      margin-top: 12px; }
      .view-broadcast-message-detail .view-broadcast-message-detail-middle-edit .view-broadcast-message-detail-middle-edit-content-body .message-content img {
        width: 200px;
        height: 200px;
        object-fit: cover;
        border-radius: 8px;
        cursor: pointer;
        margin: 0 0 8px 0; }
      .view-broadcast-message-detail .view-broadcast-message-detail-middle-edit .view-broadcast-message-detail-middle-edit-content-body .message-content .content-caption {
        padding-top: 10px;
        margin-top: 0 !important; }
    .view-broadcast-message-detail .view-broadcast-message-detail-middle-edit .view-broadcast-message-detail-middle-edit-content-body .file-url {
      cursor: pointer; }
      .view-broadcast-message-detail .view-broadcast-message-detail-middle-edit .view-broadcast-message-detail-middle-edit-content-body .file-url img {
        width: 24px !important;
        height: 24px !important;
        margin-right: 7px; }
      .view-broadcast-message-detail .view-broadcast-message-detail-middle-edit .view-broadcast-message-detail-middle-edit-content-body .file-url div > p {
        margin-top: 2px;
        font-weight: bold;
        font-size: 14px;
        color: #ff7e00;
        line-height: 21px;
        letter-spacing: -0.4px;
        border-bottom: solid 1px #ff7e00; }
  .view-broadcast-message-detail .view-broadcast-message-detail-middle-edit .view-broadcast-message-detail-middle-edit-total-status {
    font-weight: bold;
    max-width: 100%;
    overflow: auto; }
    .view-broadcast-message-detail .view-broadcast-message-detail-middle-edit .view-broadcast-message-detail-middle-edit-total-status > div {
      margin-right: 9%; }
    .view-broadcast-message-detail .view-broadcast-message-detail-middle-edit .view-broadcast-message-detail-middle-edit-total-status .flexing {
      font-weight: bold;
      font-size: 14px;
      line-height: 21px;
      letter-spacing: -0.4px; }
    .view-broadcast-message-detail .view-broadcast-message-detail-middle-edit .view-broadcast-message-detail-middle-edit-total-status .total {
      font-size: 24px;
      line-height: 30px;
      letter-spacing: -0.6px; }
  .view-broadcast-message-detail .view-broadcast-message-detail-middle-edit:nth-last-child(1) {
    padding: 0; }

.view-broadcast-message-detail .view-broadcast-message-detail-bottom {
  padding: 24px 0 0; }
  .view-broadcast-message-detail .view-broadcast-message-detail-bottom .view-message-box {
    position: relative;
    width: 100%; }
  .view-broadcast-message-detail .view-broadcast-message-detail-bottom .view-message-box-left {
    display: inline-block;
    vertical-align: top;
    width: 400px; }
  .view-broadcast-message-detail .view-broadcast-message-detail-bottom .view-message-box-right {
    width: calc(100% - 400px);
    vertical-align: top;
    display: inline-block;
    padding-left: 24px; }

.view-broadcast-message-detail div:last-child .view-broadcast-message-detail-bottom-footer p {
  margin-bottom: 0; }

.view-broadcast-message-detail .broadcast-recipients-table-wrapper {
  width: 100%;
  position: relative;
  margin-top: 24px; }

.view-broadcast-message-detail .table-broadcast-recipients thead tr {
  background: #ffffff; }
  .view-broadcast-message-detail .table-broadcast-recipients thead tr td:last-child {
    width: 90px; }
    .view-broadcast-message-detail .table-broadcast-recipients thead tr td:last-child .custom-select-option-value-wrapper {
      width: 55px !important;
      height: 23px;
      padding: 2px 8px 2px 12px !important;
      border-radius: 4px !important; }
      .view-broadcast-message-detail .table-broadcast-recipients thead tr td:last-child .custom-select-option-value-wrapper p {
        font-size: 12px;
        font-weight: bold; }
      .view-broadcast-message-detail .table-broadcast-recipients thead tr td:last-child .custom-select-option-value-wrapper .custom-select-option-value {
        padding: 0; }
      .view-broadcast-message-detail .table-broadcast-recipients thead tr td:last-child .custom-select-option-value-wrapper svg {
        right: 3px !important;
        height: 15px;
        top: 11px !important;
        position: absolute !important;
        margin-right: unset !important; }

.view-broadcast-message-detail .table-broadcast-recipients tbody td {
  position: relative; }
  .view-broadcast-message-detail .table-broadcast-recipients tbody td.col_status P {
    font-size: 12px !important; }
  .view-broadcast-message-detail .table-broadcast-recipients tbody td.col_responded b {
    font-size: 12px !important;
    position: relative;
    top: 2px; }
  .view-broadcast-message-detail .table-broadcast-recipients tbody td:nth-child(1) div {
    display: flex; }
    .view-broadcast-message-detail .table-broadcast-recipients tbody td:nth-child(1) div p {
      font-size: 12px !important;
      letter-spacing: -0.4px; }
    .view-broadcast-message-detail .table-broadcast-recipients tbody td:nth-child(1) div img {
      margin-right: 4px;
      margin-top: 2px; }
  .view-broadcast-message-detail .table-broadcast-recipients tbody td:last-child {
    color: #ff7e00;
    font-weight: 700; }
    .view-broadcast-message-detail .table-broadcast-recipients tbody td:last-child a {
      color: #ff7e00; }
    .view-broadcast-message-detail .table-broadcast-recipients tbody td:last-child svg {
      position: relative;
      color: #ff7e00;
      font-size: 16px;
      margin-right: 4px;
      right: 0;
      top: -2px; }
  .view-broadcast-message-detail .table-broadcast-recipients tbody td .medium-logo {
    width: 16px;
    height: 16px; }

.view-broadcast-message-detail .custom-select-option-value {
  margin-top: 0px !important; }

.modal-image-broadcast.modal-dialog {
  width: 100vw !important; }

.modal-image-broadcast .modal-content {
  width: 100%;
  height: 100%;
  background: none; }

.modal-image-broadcast .modal-content .modal-body {
  width: 100%;
  height: 100%;
  background: rgba(25, 25, 25, 0.8); }

.modal-image-broadcast .modal-content .modal-body img.image-preview-val {
  max-width: calc(100vw - 130px);
  max-height: calc(100vh - 130px);
  background: #ffffff; }

.modal-image-broadcast .modal-content .modal-body .image-wrapper {
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  text-align: center; }
  .modal-image-broadcast .modal-content .modal-body .image-wrapper figure {
    margin: 0; }
    .modal-image-broadcast .modal-content .modal-body .image-wrapper figure.zoom {
      background-size: 180%;
      background-position: -100% -100%;
      background-repeat: no-repeat;
      position: relative;
      width: 100%;
      overflow: hidden;
      cursor: -webkit-zoom-in;
      cursor: zoom-in; }
      .modal-image-broadcast .modal-content .modal-body .image-wrapper figure.zoom img {
        -webkit-transition: opacity 0.5s;
        transition: opacity 0.5s;
        display: inline-block; }
        .modal-image-broadcast .modal-content .modal-body .image-wrapper figure.zoom img:hover {
          opacity: 0; }

.modal-image-broadcast .modal-content .modal-body .close-modal-image-broadcast {
  position: absolute;
  top: 20px;
  right: 20px;
  color: #ffffff;
  font-size: 40px;
  cursor: pointer;
  -webkit-transition: ease-in-out 0.1s;
  transition: ease-in-out 0.1s; }

.modal-image-broadcast
.modal-content
.modal-body
.close-modal-image-broadcast:hover {
  color: #c9c9c9; }

.modal-confirmation-delete-broadcast-scheduled button {
  width: calc(50% - 6px) !important;
  display: inline-block;
  margin-right: 12px; }
  .modal-confirmation-delete-broadcast-scheduled button:nth-last-child(1) {
    margin-right: 0 !important; }

.single-date-modal-calendar .modal-content {
  width: 500px;
  position: relative; }
  .single-date-modal-calendar .modal-content .cancel-single-picker {
    position: absolute;
    border: none !important;
    left: 0;
    background: transparent !important; }
    .single-date-modal-calendar .modal-content .cancel-single-picker:hover {
      color: var(--main-darker-orange); }

.single-date-modal-calendar .modal-body {
  padding: 32px 32px 0 32px;
  position: relative;
  text-align: center; }
  .single-date-modal-calendar .modal-body .close-picker {
    position: absolute;
    cursor: pointer;
    font-size: 24px;
    top: 24px;
    right: 24px; }
    .single-date-modal-calendar .modal-body .close-picker:hover {
      color: #ff7d00; }

.single-date-modal-calendar .modal-date-filter-single-title {
  margin: 0 0 8px 0;
  font-size: 24px;
  letter-spacing: -0.6px;
  text-align: left; }
  .single-date-modal-calendar .modal-date-filter-single-title svg {
    position: relative;
    margin-right: 4px;
    top: -3px; }

.single-date-modal-calendar .modal-footer {
  position: relative;
  border-top: 0;
  padding: 0 32px 32px 32px; }
  .single-date-modal-calendar .modal-footer button {
    margin: 0 0 0 4px;
    width: 150px; }
  .single-date-modal-calendar .modal-footer .button-clear {
    color: #ff7d00;
    border: none;
    background: none;
    font-size: 16px;
    letter-spacing: -0.5px;
    position: absolute;
    left: 0;
    top: 0; }
    .single-date-modal-calendar .modal-footer .button-clear svg {
      font-size: 19px;
      position: relative;
      top: -1px;
      margin-right: 4px; }
    .single-date-modal-calendar .modal-footer .button-clear:hover {
      color: #e87200; }

.DayPicker-Day--today {
  color: #FF7E00; }

.form-broadcast-message .button-go-to-preview {
  margin: 24px 0 0 0; }

.form-broadcast-message .add-more-message-wrapper {
  width: 100%;
  margin-top: 24px;
  border-top: solid 1px #dcdcdc;
  display: block;
  padding: 0 0 0 8px; }

.form-broadcast-message .recipient-type-multi {
  position: relative;
  font-size: 14px;
  letter-spacing: -0.2px; }
  .form-broadcast-message .recipient-type-multi img {
    position: relative;
    top: -2px;
    margin-right: 4px; }

.form-broadcast-message .add-new-channel-recipient {
  color: var(--main-dark-orange);
  font-size: 14px;
  letter-spacing: -0.5px;
  margin: 24px 0 24px;
  cursor: pointer; }
  .form-broadcast-message .add-new-channel-recipient:hover {
    color: var(--main-darker-orange); }
  .form-broadcast-message .add-new-channel-recipient svg {
    position: relative;
    top: -1px;
    margin: 0 4px 0 0; }

.form-broadcast-message .custom-select-multi-whatsapp {
  width: 398px !important;
  max-width: unset !important; }

.form-broadcast-message .channel-account-multi {
  margin: 24px 0 8px; }

.form-broadcast-message .option-select-bulk-wrapper {
  margin: 24px 0 0; }

.form-broadcast-message .for-select-segment {
  margin: 0 0 24px; }

.form-broadcast-message .pos-relative {
  width: 316px; }

.form-broadcast-message .message-part {
  border-bottom: solid 1px #dcdcdc;
  padding-bottom: 24px; }

.form-broadcast-message .react-select-broadcast__control {
  margin-bottom: 8px !important; }

.form-broadcast-message .broadcast-message-schedule {
  border-bottom: solid 1px #dcdcdc;
  padding: 0 0 24px 0; }
  .form-broadcast-message .broadcast-message-schedule .broadcast-schedule-title {
    margin: 24px 0; }
    .form-broadcast-message .broadcast-message-schedule .broadcast-schedule-title svg {
      position: relative;
      top: -2px;
      margin-right: 4px; }

.form-broadcast-message .broadcast-schedule-time-wrapper {
  width: 100%;
  padding-left: 24px; }
  .form-broadcast-message .broadcast-schedule-time-wrapper label {
    padding-left: 0 !important;
    margin-top: 8px !important; }
  .form-broadcast-message .broadcast-schedule-time-wrapper .time-select-info {
    font-size: 12px;
    letter-spacing: -0.4px;
    color: rgba(25, 25, 25, 0.8);
    margin: 8px 0 0; }
    .form-broadcast-message .broadcast-schedule-time-wrapper .time-select-info svg {
      position: relative;
      top: -1px;
      margin-right: 4px; }
  .form-broadcast-message .broadcast-schedule-time-wrapper .broadcast-date-select-wrapper {
    display: inline-block;
    margin-right: 24px;
    width: 173px;
    vertical-align: top; }
    .form-broadcast-message .broadcast-schedule-time-wrapper .broadcast-date-select-wrapper label {
      margin: 0 0 4px; }
    .form-broadcast-message .broadcast-schedule-time-wrapper .broadcast-date-select-wrapper .broadcast-date-value-wrapper {
      border-radius: 8px;
      height: 48px;
      width: 100%;
      position: relative;
      padding: 0 48px 0 16px;
      border: solid 1px #dcdcdc;
      cursor: pointer;
      line-height: 46px; }
      .form-broadcast-message .broadcast-schedule-time-wrapper .broadcast-date-select-wrapper .broadcast-date-value-wrapper:hover {
        border-color: #ff7d00; }
      .form-broadcast-message .broadcast-schedule-time-wrapper .broadcast-date-select-wrapper .broadcast-date-value-wrapper svg {
        position: absolute;
        right: 16px;
        top: 50%;
        -webkit-transform: translate(0, -50%);
                transform: translate(0, -50%); }
  .form-broadcast-message .broadcast-schedule-time-wrapper .broadcast-time-input-wrapper {
    display: inline-block;
    width: 146px;
    vertical-align: top; }
    .form-broadcast-message .broadcast-schedule-time-wrapper .broadcast-time-input-wrapper label {
      margin: 0 0 4px; }
    .form-broadcast-message .broadcast-schedule-time-wrapper .broadcast-time-input-wrapper .broadcast-time-value-wrapper {
      border-radius: 8px;
      height: 48px;
      width: 100%;
      position: relative;
      padding: 0 48px 0 16px; }
      .form-broadcast-message .broadcast-schedule-time-wrapper .broadcast-time-input-wrapper .broadcast-time-value-wrapper svg {
        position: absolute;
        right: 16px;
        top: 50%;
        -webkit-transform: translate(0, -50%);
                transform: translate(0, -50%); }
      .form-broadcast-message .broadcast-schedule-time-wrapper .broadcast-time-input-wrapper .broadcast-time-value-wrapper input {
        position: absolute;
        border: solid 1px #dcdcdc;
        width: 100%;
        height: 48px;
        border-radius: 8px;
        left: 0;
        top: 0;
        background: transparent;
        padding: 0 48px 0 16px; }
        .form-broadcast-message .broadcast-schedule-time-wrapper .broadcast-time-input-wrapper .broadcast-time-value-wrapper input:focus {
          border-color: #ff7d00; }

.form-broadcast-message .broadcast-info-separator {
  font-size: 20px;
  letter-spacing: -0.6px;
  color: #191919;
  margin: 24px 0; }
  .form-broadcast-message .broadcast-info-separator svg {
    position: relative;
    margin-right: 4px;
    top: -2px; }

.form-broadcast-message .message-content-separator {
  font-size: 20px;
  letter-spacing: -0.6px;
  color: #191919;
  border-top: solid 1px #dcdcdc;
  margin: 24px 0;
  padding-top: 22px; }
  .form-broadcast-message .message-content-separator img {
    position: relative;
    margin-right: 4px;
    top: -2px; }
  .form-broadcast-message .message-content-separator svg {
    position: relative;
    margin-right: 4px;
    top: -2px; }

.form-broadcast-message .message-part.no-border-bottom {
  border-bottom: 0;
  padding-bottom: 0; }

.form-broadcast-message .broadcast-time-separator {
  font-size: 20px;
  letter-spacing: -0.6px;
  color: #191919;
  border-top: solid 1px #dcdcdc;
  margin: 24px 0 16px;
  padding-top: 22px;
  position: relative; }
  .form-broadcast-message .broadcast-time-separator .delete-multi-recipient {
    position: absolute;
    right: 0;
    top: 16px;
    color: var(--watermelon);
    cursor: pointer; }
  .form-broadcast-message .broadcast-time-separator img {
    position: relative;
    margin-right: 4px; }
  .form-broadcast-message .broadcast-time-separator svg {
    position: relative;
    margin-right: 4px;
    top: -2px; }
  .form-broadcast-message .broadcast-time-separator.select-segment {
    margin: 24px 0 0; }

.form-broadcast-message .broadcast-time-wrapper .label-radio {
  margin: 8px 0 0;
  padding-left: 24px !important;
  font-size: 16px; }

.form-broadcast-message .form-title-wrapper {
  padding-bottom: 12px; }
  .form-broadcast-message .form-title-wrapper svg {
    font-size: 24px;
    margin-right: 6px;
    position: relative;
    top: -2px; }
  .form-broadcast-message .form-title-wrapper p {
    font-size: 16px;
    color: rgba(25, 25, 25, 0.8);
    letter-spacing: -0.3px;
    margin: 0; }

.form-broadcast-message .form-content-wrapper textarea {
  width: 100%;
  max-width: 100%;
  height: 96px; }

.form-broadcast-message .form-content-wrapper .message-channel-field {
  font-size: 16px;
  letter-spacing: -0.3px; }
  .form-broadcast-message .form-content-wrapper .message-channel-field img {
    width: 24px;
    margin-right: 8px;
    position: relative;
    top: -2px; }

.form-broadcast-message .form-content-wrapper .message-type-field {
  font-size: 16px;
  letter-spacing: -0.3px; }
  .form-broadcast-message .form-content-wrapper .message-type-field img {
    width: 24px;
    margin-right: 8px;
    position: relative;
    top: -2px; }

.form-broadcast-message .form-content-wrapper .more-message-type-selection-wrapper {
  width: 100%;
  position: relative;
  display: block; }
  .form-broadcast-message .form-content-wrapper .more-message-type-selection-wrapper .message-type-selection-selection {
    vertical-align: top;
    border-radius: 8px;
    border: solid 1px #dcdcdc;
    display: inline-block;
    position: relative;
    margin-right: 9px;
    font-size: 14px;
    letter-spacing: -0.2px;
    padding: 14px 14px 14px 49px;
    cursor: pointer;
    -webkit-transition: ease-in-out .1s;
    transition: ease-in-out .1s; }
    .form-broadcast-message .form-content-wrapper .more-message-type-selection-wrapper .message-type-selection-selection:nth-last-child(1) {
      margin-right: 0; }
    .form-broadcast-message .form-content-wrapper .more-message-type-selection-wrapper .message-type-selection-selection.active-message-type-selection-selection {
      border-color: #ff7d00; }
    .form-broadcast-message .form-content-wrapper .more-message-type-selection-wrapper .message-type-selection-selection:hover {
      border-color: #ff7d00; }
  .form-broadcast-message .form-content-wrapper .more-message-type-selection-wrapper .message-type-selection-icon-wrapper {
    width: 28px;
    height: 28px;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 14px;
    -webkit-transform: translate(0, -50%);
            transform: translate(0, -50%); }
    .form-broadcast-message .form-content-wrapper .more-message-type-selection-wrapper .message-type-selection-icon-wrapper img {
      width: 25px;
      height: 25px; }
    .form-broadcast-message .form-content-wrapper .more-message-type-selection-wrapper .message-type-selection-icon-wrapper img {
      position: absolute;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%); }

.form-broadcast-message .form-content-wrapper .add-more-message-button {
  font-size: 16px;
  letter-spacing: -0.5px;
  color: #ff7d00;
  width: unset;
  background: none;
  border: 0;
  cursor: pointer;
  height: unset;
  padding: 0;
  margin-top: 28px; }
  .form-broadcast-message .form-content-wrapper .add-more-message-button svg {
    position: relative;
    margin-right: 0px;
    top: -2px; }
  .form-broadcast-message .form-content-wrapper .add-more-message-button:hover {
    color: #e87200; }

.form-broadcast-message .form-content-wrapper .with-case-wrapper {
  margin-bottom: 24px;
  padding-right: 54px;
  width: 100%;
  position: relative; }
  .form-broadcast-message .form-content-wrapper .with-case-wrapper label {
    margin-bottom: 0;
    font-size: 16px;
    letter-spacing: -0.5px; }
  .form-broadcast-message .form-content-wrapper .with-case-wrapper p {
    margin: 0;
    font-size: 14px;
    color: rgba(25, 25, 25, 0.8);
    letter-spacing: -0.2px; }
  .form-broadcast-message .form-content-wrapper .with-case-wrapper .custom-switch-wrapper {
    display: inline-block !important;
    width: 40px;
    height: 24px;
    position: absolute;
    top: 0;
    right: 0;
    margin: 0; }
    .form-broadcast-message .form-content-wrapper .with-case-wrapper .custom-switch-wrapper .custom-switch.round::before {
      width: 14px;
      height: 14px;
      bottom: 5px;
      left: 4px; }
    .form-broadcast-message .form-content-wrapper .with-case-wrapper .custom-switch-wrapper input:checked + .custom-switch:before {
      -webkit-transform: translateX(18px);
              transform: translateX(18px); }

.form-broadcast-message .main-custom-select-option-wrapper {
  width: 316px;
  max-width: 100%; }
  .form-broadcast-message .main-custom-select-option-wrapper .custom-select-option-value-wrapper {
    margin-top: 0; }
  .form-broadcast-message .main-custom-select-option-wrapper .dropdown-item {
    margin-top: 0; }

.form-broadcast-message .submit-broadcast-message {
  padding: 7px 24px 8px; }
  .form-broadcast-message .submit-broadcast-message svg {
    position: relative;
    top: -2px;
    margin-right: 4px; }

.form-broadcast-message .main-button-40 svg {
  z-index: 0; }

.form-broadcast-message .no-height input[type="text"] {
  height: unset; }

.form-broadcast-message .message-template-wrapper .css-9uig5a-control {
  margin-bottom: 8px; }

.broad-cast-form-csv-wrapper {
  position: relative;
  width: 100%;
  max-width: 100%; }
  .broad-cast-form-csv-wrapper .border-red .react-select-broadcast__control {
    border: solid 1px var(--watermelon) !important; }
  .broad-cast-form-csv-wrapper .input-info-template-name {
    margin: 8px 0 0; }
  .broad-cast-form-csv-wrapper .contact-selection-selection {
    vertical-align: top;
    border-radius: 8px;
    border: solid 1px #dcdcdc;
    display: inline-block;
    position: relative;
    min-width: 169px;
    margin-right: 9px;
    font-size: 14px;
    letter-spacing: -0.2px;
    padding: 14px 10px 14px 45px;
    cursor: pointer;
    -webkit-transition: ease-in-out .1s;
    transition: ease-in-out .1s; }
    .broad-cast-form-csv-wrapper .contact-selection-selection:nth-last-child(1) {
      margin-right: 0; }
    .broad-cast-form-csv-wrapper .contact-selection-selection.active-contact-selection-selection {
      border-color: #ff7d00; }
    .broad-cast-form-csv-wrapper .contact-selection-selection:hover {
      border-color: #ff7d00; }
  .broad-cast-form-csv-wrapper .contact-selection-icon-wrapper {
    width: 28px;
    height: 28px;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 10px;
    -webkit-transform: translate(0, -50%);
            transform: translate(0, -50%); }
    .broad-cast-form-csv-wrapper .contact-selection-icon-wrapper.contact-selection-contact {
      background: #FFD4CC; }
    .broad-cast-form-csv-wrapper .contact-selection-icon-wrapper.contact-selection-segment {
      background: #EBF7FF; }
    .broad-cast-form-csv-wrapper .contact-selection-icon-wrapper img {
      position: absolute;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%); }
  .broad-cast-form-csv-wrapper .react-select-broadcast__control input {
    height: unset; }
  .broad-cast-form-csv-wrapper input[type='file'] {
    display: none; }
  .broad-cast-form-csv-wrapper label {
    display: inline-block !important; }
  .broad-cast-form-csv-wrapper .button-import-csv {
    margin: 0 4px;
    color: #ff7d00;
    letter-spacing: -0.4px;
    font-size: 12px;
    border-radius: 4px;
    cursor: pointer;
    display: inline-block;
    padding: 2px 8px;
    background: rgba(255, 126, 0, 0.1);
    -webkit-transition: ease-in-out .1s;
    transition: ease-in-out .1s; }
    .broad-cast-form-csv-wrapper .button-import-csv svg {
      font-size: 12px;
      margin-right: 4px;
      position: relative;
      top: -1px; }
    .broad-cast-form-csv-wrapper .button-import-csv:hover {
      background: #ff7d00;
      color: #ffffff; }
  .broad-cast-form-csv-wrapper .phone-number-counter {
    letter-spacing: -0.2px;
    font-size: 14px;
    color: rgba(25, 25, 25, 0.6);
    margin-left: 4px; }
  .broad-cast-form-csv-wrapper .phone-number-container {
    position: relative;
    border: solid 1px #dcdcdc;
    border-radius: 8px;
    width: 100%;
    padding: 53px 16px 12px 16px;
    height: 96px;
    overflow: auto;
    margin-top: 2px;
    min-height: 229px; }
    .broad-cast-form-csv-wrapper .phone-number-container.padding-top-12 {
      padding: 12px 16px !important; }
      .broad-cast-form-csv-wrapper .phone-number-container.padding-top-12 .empty-contact {
        top: 50%;
        -webkit-transform: translate(-50%, -50%) !important;
                transform: translate(-50%, -50%) !important; }
    .broad-cast-form-csv-wrapper .phone-number-container.empty-contact-container {
      padding: 12px 16px 12px 16px;
      background: #e8e8e8; }
    .broad-cast-form-csv-wrapper .phone-number-container .empty-contact {
      letter-spacing: -0.3px;
      font-size: 16px;
      color: rgba(25, 25, 25, 0.4);
      line-height: 24px;
      position: absolute;
      top: 126px;
      left: 50%;
      display: inline-block;
      -webkit-transform: translate(-50%, 0);
              transform: translate(-50%, 0);
      white-space: nowrap;
      margin: 0; }
    .broad-cast-form-csv-wrapper .phone-number-container .selected-contact-list-wrapper {
      margin: 16px 0 0; }
      .broad-cast-form-csv-wrapper .phone-number-container .selected-contact-list-wrapper .contact-item {
        display: inline-block;
        border-radius: 6px;
        margin: 0 4px 4px 0;
        font-size: 14px;
        letter-spacing: -0.4px;
        color: rgba(25, 25, 25, 0.8);
        background: rgba(25, 25, 25, 0.05);
        padding: 2px 8px;
        line-height: 1;
        cursor: pointer;
        -webkit-transition: ease-in-out .1s;
        transition: ease-in-out .1s; }
        .broad-cast-form-csv-wrapper .phone-number-container .selected-contact-list-wrapper .contact-item:hover {
          background: rgba(25, 25, 25, 0.1); }
        .broad-cast-form-csv-wrapper .phone-number-container .selected-contact-list-wrapper .contact-item .csv-icon {
          position: relative;
          top: -2px;
          margin-right: 4px; }
        .broad-cast-form-csv-wrapper .phone-number-container .selected-contact-list-wrapper .contact-item.active-contact-item {
          background: #FFF2E5;
          color: #ff7d00; }
          .broad-cast-form-csv-wrapper .phone-number-container .selected-contact-list-wrapper .contact-item.active-contact-item .csv-icon {
            color: #ff7d00; }
    .broad-cast-form-csv-wrapper .phone-number-container .contact-remove-button {
      display: inline-block;
      color: #9c9c9c;
      font-size: 18px;
      margin-left: 4px;
      position: relative;
      top: -1px;
      cursor: pointer; }
      .broad-cast-form-csv-wrapper .phone-number-container .contact-remove-button:hover {
        color: #818181; }
    .broad-cast-form-csv-wrapper .phone-number-container .recipient-search-box {
      height: 37px;
      width: 292px;
      position: absolute;
      top: 16px;
      left: 16px;
      border-radius: 8px; }
      .broad-cast-form-csv-wrapper .phone-number-container .recipient-search-box input {
        width: 100%;
        padding: 8px 36px 8px 12px;
        font-size: 14px;
        letter-spacing: -0.2px;
        height: 37px;
        border: solid 1px #dcdcdc;
        line-height: 37px; }
        .broad-cast-form-csv-wrapper .phone-number-container .recipient-search-box input[disabled] {
          border: solid 1px rgba(25, 25, 25, 0.1);
          background: rgba(25, 25, 25, 0.1); }
      .broad-cast-form-csv-wrapper .phone-number-container .recipient-search-box svg {
        position: absolute;
        right: 12px;
        top: 50%;
        -webkit-transform: translate(0, -50%);
                transform: translate(0, -50%);
        color: #959595;
        font-size: 16px; }
  .broad-cast-form-csv-wrapper .button-add-recipient {
    font-size: 16px;
    letter-spacing: -0.5px;
    width: unset;
    padding: 7px 24px 8px;
    margin-top: 8px;
    display: inline-block;
    cursor: pointer; }
    .broad-cast-form-csv-wrapper .button-add-recipient svg {
      font-size: 20px;
      position: relative;
      top: -2px;
      margin-right: 4px; }
  .broad-cast-form-csv-wrapper .button-clear-recipient {
    font-size: 16px;
    letter-spacing: -0.5px;
    width: unset;
    padding: 7px 24px 8px;
    margin-top: 8px;
    display: inline-block;
    cursor: pointer;
    margin-left: 8px; }
    .broad-cast-form-csv-wrapper .button-clear-recipient svg {
      font-size: 20px;
      position: relative;
      top: -2px;
      margin-right: 4px; }

.modal-add-recipient {
  width: 100% !important; }
  .modal-add-recipient .modal-content {
    left: unset;
    right: 0;
    top: 8px;
    -webkit-transform: unset;
            transform: unset;
    width: 600px;
    max-height: calc(100% - 8px);
    position: fixed;
    min-height: calc(100% - 8px);
    border-radius: 12px 0 0 0; }
    .modal-add-recipient .modal-content .modal-header {
      padding: 32px 32px 0;
      border: none; }
    .modal-add-recipient .modal-content .modal-body {
      padding: 0 32px 32px;
      max-height: calc(100% - 40px);
      overflow: auto; }
    .modal-add-recipient .modal-content .modal-footer {
      padding: 12px 24px;
      border: none;
      box-shadow: 0px 2px 20px rgba(25, 25, 25, 0.18); }
      .modal-add-recipient .modal-content .modal-footer button {
        margin: 0;
        vertical-align: top; }
  .modal-add-recipient .react-select-country-picker__single-value div .country-name {
    display: none !important; }
  .modal-add-recipient .react-select-country-picker__single-value div .parenthesis {
    display: none !important; }
  .modal-add-recipient .add-recipient-title {
    color: #191919;
    font-size: 20px;
    letter-spacing: -0.6px;
    margin: 0 0 24px; }
    .modal-add-recipient .add-recipient-title span {
      color: rgba(25, 25, 25, 0.6);
      font-size: 14px;
      letter-spacing: -0.2px;
      position: relative;
      top: -2px; }
  .modal-add-recipient .add-recipient-form-wrapper {
    width: 100%;
    margin: 0 0 14px 0; }
    .modal-add-recipient .add-recipient-form-wrapper .form-label {
      width: 100%;
      position: relative;
      font-size: 14px;
      letter-spacing: -0.6px;
      color: #191919; }
      .modal-add-recipient .add-recipient-form-wrapper .form-label p {
        margin: 0 8px 0 0;
        width: 142px;
        display: inline-block; }
    .modal-add-recipient .add-recipient-form-wrapper .phone-number-form {
      color: #191919;
      font-size: 16px;
      letter-spacing: -0.3px;
      margin-top: 8px; }
      .modal-add-recipient .add-recipient-form-wrapper .phone-number-form .dropdown-menu {
        border: 1px solid rgba(0, 0, 0, 0.15);
        -webkit-transform: translate3d(0px, 50px, 0px) !important;
                transform: translate3d(0px, 50px, 0px) !important; }
      .modal-add-recipient .add-recipient-form-wrapper .phone-number-form input[type="text"] {
        width: 100%;
        padding: 12px 16px;
        border: solid 1px rgba(25, 25, 25, 0.1);
        border-radius: 8px;
        box-shadow: none;
        outline: none; }
        .modal-add-recipient .add-recipient-form-wrapper .phone-number-form input[type="text"].error-input-message {
          border-color: #ff3f57; }
        .modal-add-recipient .add-recipient-form-wrapper .phone-number-form input[type="text"]:focus {
          border-color: #ff7d00; }
      .modal-add-recipient .add-recipient-form-wrapper .phone-number-form .area-code-wrapper {
        position: relative;
        width: 142px;
        display: inline-block;
        margin-right: 8px; }
        .modal-add-recipient .add-recipient-form-wrapper .phone-number-form .area-code-wrapper .dropdown .value-country-code-wrapper {
          width: 100%;
          text-align: left;
          outline: none !important;
          border-radius: 8px;
          border: solid 1px #dcdcdc;
          background: #ffffff;
          height: 49px;
          color: #191919;
          box-shadow: none !important;
          position: relative;
          padding: 6px 26px 6px 12px; }
          .modal-add-recipient .add-recipient-form-wrapper .phone-number-form .area-code-wrapper .dropdown .value-country-code-wrapper .country-flag {
            width: 32px;
            height: 24px;
            display: inline-block;
            position: relative;
            margin-right: 6px;
            top: -1px; }
          .modal-add-recipient .add-recipient-form-wrapper .phone-number-form .area-code-wrapper .dropdown .value-country-code-wrapper svg {
            -webkit-transition: ease-in-out .1s;
            transition: ease-in-out .1s;
            position: absolute;
            top: 17px;
            right: 8px; }
            .modal-add-recipient .add-recipient-form-wrapper .phone-number-form .area-code-wrapper .dropdown .value-country-code-wrapper svg.chevron-up {
              -webkit-transform: rotate(180deg);
                      transform: rotate(180deg); }
        .modal-add-recipient .add-recipient-form-wrapper .phone-number-form .area-code-wrapper .dropdown .dropdown-menu {
          padding: 0;
          border-radius: 8px; }
          .modal-add-recipient .add-recipient-form-wrapper .phone-number-form .area-code-wrapper .dropdown .dropdown-menu .country-search-wrapper {
            position: relative;
            width: 100%; }
            .modal-add-recipient .add-recipient-form-wrapper .phone-number-form .area-code-wrapper .dropdown .dropdown-menu .country-search-wrapper input {
              border: none !important;
              height: 37px;
              padding: 12px 12px 12px 36px !important;
              font-size: 14px;
              letter-spacing: -0.2px;
              color: #191919;
              border-bottom: solid 1px #dcdcdc !important;
              border-radius: 8px 8px 0 0 !important; }
            .modal-add-recipient .add-recipient-form-wrapper .phone-number-form .area-code-wrapper .dropdown .dropdown-menu .country-search-wrapper svg {
              position: relative;
              color: #ff7d00;
              position: absolute;
              left: 12px;
              top: 11px; }
          .modal-add-recipient .add-recipient-form-wrapper .phone-number-form .area-code-wrapper .dropdown .dropdown-menu .country-list-select {
            width: 320px;
            min-height: 37px;
            max-height: 222px;
            overflow: auto; }
            .modal-add-recipient .add-recipient-form-wrapper .phone-number-form .area-code-wrapper .dropdown .dropdown-menu .country-list-select .custom-phone-code-picker {
              padding: 0 12px;
              height: 37px;
              line-height: 37px;
              border-bottom: solid 1px #dcdcdc;
              cursor: pointer; }
              .modal-add-recipient .add-recipient-form-wrapper .phone-number-form .area-code-wrapper .dropdown .dropdown-menu .country-list-select .custom-phone-code-picker:nth-last-child(1) {
                border-bottom: none; }
              .modal-add-recipient .add-recipient-form-wrapper .phone-number-form .area-code-wrapper .dropdown .dropdown-menu .country-list-select .custom-phone-code-picker img {
                width: 21px;
                height: 16px;
                display: inline-block;
                margin-right: 6px;
                position: relative;
                top: -2px; }
              .modal-add-recipient .add-recipient-form-wrapper .phone-number-form .area-code-wrapper .dropdown .dropdown-menu .country-list-select .custom-phone-code-picker svg {
                margin-right: 6px;
                position: relative;
                color: #ff7d00;
                font-size: 18px;
                top: -1px; }
              .modal-add-recipient .add-recipient-form-wrapper .phone-number-form .area-code-wrapper .dropdown .dropdown-menu .country-list-select .custom-phone-code-picker div {
                display: inline-block;
                font-size: 14px;
                letter-spacing: -0.2px;
                line-height: 1;
                position: relative;
                vertical-align: top;
                top: 11px; }
                .modal-add-recipient .add-recipient-form-wrapper .phone-number-form .area-code-wrapper .dropdown .dropdown-menu .country-list-select .custom-phone-code-picker div .country-name {
                  top: 0 !important; }
              .modal-add-recipient .add-recipient-form-wrapper .phone-number-form .area-code-wrapper .dropdown .dropdown-menu .country-list-select .custom-phone-code-picker:hover {
                color: #ff7d00; }
              .modal-add-recipient .add-recipient-form-wrapper .phone-number-form .area-code-wrapper .dropdown .dropdown-menu .country-list-select .custom-phone-code-picker.active-code-number {
                color: #ff7d00; }
            .modal-add-recipient .add-recipient-form-wrapper .phone-number-form .area-code-wrapper .dropdown .dropdown-menu .country-list-select.no-option {
              text-align: center;
              border-top: solid 1px #dcdcdc;
              color: grey;
              padding-top: 5px; }
        .modal-add-recipient .add-recipient-form-wrapper .phone-number-form .area-code-wrapper input[type="text"] {
          padding: 12px 16px 12px 52px; }
        .modal-add-recipient .add-recipient-form-wrapper .phone-number-form .area-code-wrapper .country-flag-wrapper {
          padding: 8px;
          border: solid 1px rgba(25, 25, 25, 0.1);
          display: inline-block;
          height: 24px;
          width: 32px;
          position: absolute;
          left: 16px;
          top: 50%;
          -webkit-transform: translate(0, -50%);
                  transform: translate(0, -50%); }
          .modal-add-recipient .add-recipient-form-wrapper .phone-number-form .area-code-wrapper .country-flag-wrapper svg {
            position: absolute;
            left: 50%;
            top: 50%;
            -webkit-transform: translate(-50%, -50%);
                    transform: translate(-50%, -50%);
            color: rgba(25, 25, 25, 0.4); }
      .modal-add-recipient .add-recipient-form-wrapper .phone-number-form .phone-wrapper {
        width: calc(100% - 150px);
        display: inline-block;
        vertical-align: top;
        position: relative; }
        .modal-add-recipient .add-recipient-form-wrapper .phone-number-form .phone-wrapper p {
          color: #ff3f57;
          font-size: 12px;
          letter-spacing: -0.4px;
          margin: 4px 0 0;
          width: 100%;
          position: relative;
          padding: 0 0 0 20px; }
          .modal-add-recipient .add-recipient-form-wrapper .phone-number-form .phone-wrapper p svg {
            margin-right: 4px;
            position: absolute;
            left: 0;
            top: 1px;
            font-size: 16px; }
        .modal-add-recipient .add-recipient-form-wrapper .phone-number-form .phone-wrapper input[type="text"] {
          width: calc(100% - 31px);
          display: inline-block; }
        .modal-add-recipient .add-recipient-form-wrapper .phone-number-form .phone-wrapper svg.remove-number {
          position: absolute;
          right: 0;
          top: 14px;
          font-size: 24px;
          margin-left: 6px;
          color: #ff3f57;
          cursor: pointer; }
          .modal-add-recipient .add-recipient-form-wrapper .phone-number-form .phone-wrapper svg.remove-number:hover {
            color: #e83b51; }
      .modal-add-recipient .add-recipient-form-wrapper .phone-number-form.phone-number-disabled input[type="text"] {
        background: rgba(25, 25, 25, 0.1);
        border: none;
        cursor: not-allowed; }
    .modal-add-recipient .add-recipient-form-wrapper .add-recipient-button {
      color: #ff7d00;
      background: #FFF2E5;
      font-size: 12px;
      letter-spacing: -0.4px;
      width: 85px;
      height: 22px;
      border: none;
      outline: none;
      border-radius: 4px;
      -webkit-transition: none;
      transition: none;
      margin: 10px 0 0; }
      .modal-add-recipient .add-recipient-form-wrapper .add-recipient-button svg {
        position: relative;
        top: -1px; }
      .modal-add-recipient .add-recipient-form-wrapper .add-recipient-button:hover {
        background: #ff7d00;
        color: #ffffff; }
  .modal-add-recipient .button-submit {
    width: calc(50% - 4px);
    margin: 0 8px 0 0 !important; }
    .modal-add-recipient .button-submit:nth-last-child(1) {
      margin: 0 !important; }

.broadcast-modal-contact .modal-content {
  border-radius: 8px 0 0 0;
  bottom: 0;
  right: 0;
  position: fixed;
  width: 550px;
  max-width: calc(100% - 110px);
  height: calc(100% - 12px);
  left: unset;
  top: unset;
  -webkit-transform: unset;
          transform: unset; }

.broadcast-modal-contact .modal-body {
  position: relative;
  overflow-x: visible;
  padding: 0;
  height: 100%; }
  .broadcast-modal-contact .modal-body .broadcast-modal-content {
    padding: 32px;
    max-height: 100%;
    overflow-y: auto; }
  .broadcast-modal-contact .modal-body .broadcast-modal-close {
    width: 46px;
    height: 46px;
    border-radius: 50%;
    background: #ffffff;
    position: absolute;
    top: 0;
    left: -56px;
    cursor: pointer; }
    .broadcast-modal-contact .modal-body .broadcast-modal-close svg {
      font-size: 20px;
      color: rgba(25, 25, 25, 0.6);
      position: absolute;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%); }
    .broadcast-modal-contact .modal-body .broadcast-modal-close:hover svg {
      color: rgba(25, 25, 25, 0.8); }
  .broadcast-modal-contact .modal-body .contact-fullname {
    font-size: 20px;
    letter-spacing: -0.6px;
    padding: 0 0 12px;
    margin: 0 0 20px;
    border-bottom: solid 1px #dcdcdc; }
    .broadcast-modal-contact .modal-body .contact-fullname svg {
      position: relative;
      top: -2px; }
  .broadcast-modal-contact .modal-body .contact-label {
    font-size: 14px;
    letter-spacing: -0.4px;
    margin: 0 0 6px; }
    .broadcast-modal-contact .modal-body .contact-label svg {
      position: relative;
      top: -2px; }
  .broadcast-modal-contact .modal-body .contact-value {
    font-size: 16px;
    margin: 0 0 22px;
    letter-spacing: -0.3px; }
    .broadcast-modal-contact .modal-body .contact-value:nth-last-child(1) {
      margin: 0; }

.modal-select-how-to-add-recipient .modal-content {
  width: 716px;
  border-radius: 8px; }
  .modal-select-how-to-add-recipient .modal-content .modal-header {
    padding: 32px 34px 0;
    border: none;
    position: relative; }
    .modal-select-how-to-add-recipient .modal-content .modal-header .add-recipient-title {
      font-size: 20px;
      letter-spacing: -0.6px;
      margin: 0;
      width: 100%; }
      .modal-select-how-to-add-recipient .modal-content .modal-header .add-recipient-title span {
        letter-spacing: -0.3px;
        font-size: 16px;
        margin: 0; }
    .modal-select-how-to-add-recipient .modal-content .modal-header .close-modal {
      position: absolute;
      top: 32px;
      right: 34px;
      color: #757575;
      font-size: 20px;
      cursor: pointer; }
      .modal-select-how-to-add-recipient .modal-content .modal-header .close-modal:hover {
        color: #191919; }
  .modal-select-how-to-add-recipient .modal-content .modal-body {
    padding: 20px 34px 32px;
    text-align: center; }
    .modal-select-how-to-add-recipient .modal-content .modal-body .select-recipient-by {
      margin: 0 12px 8px 0;
      vertical-align: top;
      cursor: pointer;
      height: 183px;
      width: calc(50% - 6px);
      display: inline-block;
      background: #ffffff;
      border: solid 1px #dcdcdc;
      color: #191919;
      text-align: center;
      padding: 24px;
      border-radius: 8px; }
      .modal-select-how-to-add-recipient .modal-content .modal-body .select-recipient-by label {
        cursor: pointer; }
      .modal-select-how-to-add-recipient .modal-content .modal-body .select-recipient-by .button-download-csv {
        margin: 4px 0 0;
        color: #ff7d00;
        border-radius: 4px;
        cursor: pointer;
        display: inline-block;
        padding: 0 12px;
        height: 22px;
        -webkit-transition: ease-in-out .1s;
        transition: ease-in-out .1s;
        line-height: 17px; }
        .modal-select-how-to-add-recipient .modal-content .modal-body .select-recipient-by .button-download-csv b {
          letter-spacing: -0.4px;
          font-size: 12px; }
        .modal-select-how-to-add-recipient .modal-content .modal-body .select-recipient-by .button-download-csv svg {
          font-size: 12px;
          margin-right: 4px;
          position: relative;
          top: 0; }
      .modal-select-how-to-add-recipient .modal-content .modal-body .select-recipient-by b {
        font-size: 16px;
        letter-spacing: -0.5px; }
      .modal-select-how-to-add-recipient .modal-content .modal-body .select-recipient-by p {
        margin: 6px 0 0;
        font-size: 14px;
        letter-spacing: -0.2px; }
      .modal-select-how-to-add-recipient .modal-content .modal-body .select-recipient-by:hover {
        background: #FFF2E5;
        color: #ff7d00;
        border: solid 1px #FFF2E5; }
      .modal-select-how-to-add-recipient .modal-content .modal-body .select-recipient-by:nth-child(even) {
        margin: 0 0 12px 0; }
      .modal-select-how-to-add-recipient .modal-content .modal-body .select-recipient-by .select-recipient-by-icon {
        border-radius: 50%;
        width: 48px;
        height: 48px;
        display: inline-block;
        position: relative;
        margin: 0 0 6px 0; }
        .modal-select-how-to-add-recipient .modal-content .modal-body .select-recipient-by .select-recipient-by-icon svg {
          font-size: 21px;
          position: absolute;
          top: 50%;
          left: 50%;
          -webkit-transform: translate(-50%, -50%);
                  transform: translate(-50%, -50%); }
      .modal-select-how-to-add-recipient .modal-content .modal-body .select-recipient-by.from-contact .select-recipient-by-icon {
        background: #FFD4CC;
        color: #ED5E45; }
      .modal-select-how-to-add-recipient .modal-content .modal-body .select-recipient-by.contact-segment .select-recipient-by-icon {
        background: #D9E5FF;
        color: #0051FC; }
      .modal-select-how-to-add-recipient .modal-content .modal-body .select-recipient-by.import-csv .select-recipient-by-icon {
        background: #D9FFE5;
        color: #50E682; }
      .modal-select-how-to-add-recipient .modal-content .modal-body .select-recipient-by.add-manually .select-recipient-by-icon {
        background: #FFF2CC;
        color: #F4C22C; }

.modal-select-contact {
  width: 100% !important; }
  .modal-select-contact .modal-content {
    left: unset;
    right: 0;
    top: 8px;
    -webkit-transform: unset;
            transform: unset;
    width: 75vw;
    max-height: calc(100% - 8px);
    position: fixed;
    min-height: calc(100% - 8px);
    border-radius: 12px 0 0 0; }
    .modal-select-contact .modal-content .modal-header {
      padding: 32px 32px 0;
      border: none; }
      .modal-select-contact .modal-content .modal-header .user-icon {
        position: relative;
        top: -2px;
        margin-right: 4px;
        font-size: 24px; }
      .modal-select-contact .modal-content .modal-header .modal-select-search-wrapper {
        position: relative;
        width: 300px;
        display: inline-block; }
        .modal-select-contact .modal-content .modal-header .modal-select-search-wrapper input {
          padding: 11px 40px 11px 16px;
          border: solid 1px #dcdcdc;
          border-radius: 8px;
          width: 100%;
          font-size: 16px;
          letter-spacing: -0.3px; }
        .modal-select-contact .modal-content .modal-header .modal-select-search-wrapper svg {
          position: absolute;
          right: 16px;
          top: 50%;
          -webkit-transform: translate(0, -50%);
                  transform: translate(0, -50%);
          font-size: 20px;
          color: #191919;
          cursor: pointer; }
    .modal-select-contact .modal-content .modal-body {
      padding: 0 32px 32px;
      max-height: calc(100% - 40px);
      overflow: auto; }
      .modal-select-contact .modal-content .modal-body .table-contact-list {
        width: 100%;
        border-collapse: collapse;
        margin-top: 32px; }
        .modal-select-contact .modal-content .modal-body .table-contact-list thead tr {
          border-bottom: solid 1px #dcdcdc; }
          .modal-select-contact .modal-content .modal-body .table-contact-list thead tr td {
            padding: 8px 4px;
            position: relative;
            font-size: 14px;
            letter-spacing: -0.4px;
            vertical-align: top; }
            .modal-select-contact .modal-content .modal-body .table-contact-list thead tr td.list-per-page {
              width: 55px; }
        .modal-select-contact .modal-content .modal-body .table-contact-list tbody tr {
          border-bottom: solid 1px #dcdcdc; }
          .modal-select-contact .modal-content .modal-body .table-contact-list tbody tr td {
            padding: 16px 4px;
            position: relative;
            font-size: 14px;
            letter-spacing: -0.2px;
            vertical-align: top;
            word-break: break-word; }
            .modal-select-contact .modal-content .modal-body .table-contact-list tbody tr td span {
              color: rgba(25, 25, 25, 0.8); }
            .modal-select-contact .modal-content .modal-body .table-contact-list tbody tr td.table-center {
              text-align: center; }
              .modal-select-contact .modal-content .modal-body .table-contact-list tbody tr td.table-center span {
                color: rgba(25, 25, 25, 0.4); }
              .modal-select-contact .modal-content .modal-body .table-contact-list tbody tr td.table-center .lds-ring {
                top: 3px;
                right: unset; }
                .modal-select-contact .modal-content .modal-body .table-contact-list tbody tr td.table-center .lds-ring div {
                  margin: 0; }
            .modal-select-contact .modal-content .modal-body .table-contact-list tbody tr td:nth-child(1), .modal-select-contact .modal-content .modal-body .table-contact-list tbody tr td:nth-child(2), .modal-select-contact .modal-content .modal-body .table-contact-list tbody tr td:nth-child(3), .modal-select-contact .modal-content .modal-body .table-contact-list tbody tr td:nth-child(4) {
              min-width: 160px; }
          .modal-select-contact .modal-content .modal-body .table-contact-list tbody tr:hover td {
            background: #fff2e5 !important; }
            .modal-select-contact .modal-content .modal-body .table-contact-list tbody tr:hover td.no-contact-found {
              background: #fff !important; }
        .modal-select-contact .modal-content .modal-body .table-contact-list tbody .no-contact-found {
          background: #fff !important; }
        .modal-select-contact .modal-content .modal-body .table-contact-list td .custom-checkbox {
          display: inline-block;
          vertical-align: top;
          position: absolute;
          left: 12px; }
          .modal-select-contact .modal-content .modal-body .table-contact-list td .custom-checkbox label {
            margin: 0;
            position: absolute;
            top: 0;
            left: 0; }
        .modal-select-contact .modal-content .modal-body .table-contact-list td .custom-checkbox-stripe {
          display: inline-block;
          vertical-align: top;
          position: absolute;
          left: 12px; }
          .modal-select-contact .modal-content .modal-body .table-contact-list td .custom-checkbox-stripe label {
            margin: 0;
            position: absolute;
            top: 0;
            left: 0; }
        .modal-select-contact .modal-content .modal-body .table-contact-list td .custom-checkbox-stripe [type="checkbox"]:checked + label:after {
          top: -3px;
          left: -5px;
          background-size: unset !important; }
        .modal-select-contact .modal-content .modal-body .table-contact-list td.with-checkbox {
          padding-left: 36px; }
    .modal-select-contact .modal-content .modal-footer {
      padding: 12px 24px;
      border: none;
      box-shadow: 0px 2px 20px rgba(25, 25, 25, 0.18);
      text-align: right;
      position: relative; }
      .modal-select-contact .modal-content .modal-footer button {
        margin: 0;
        vertical-align: top;
        width: 185px; }
        .modal-select-contact .modal-content .modal-footer button.cancel-button {
          width: 128px;
          position: absolute;
          left: 24px;
          top: 50%;
          -webkit-transform: translate(0, -50%);
                  transform: translate(0, -50%); }
        .modal-select-contact .modal-content .modal-footer button.button-clear-selection {
          color: #ff7d00;
          background: none;
          border: none; }
          .modal-select-contact .modal-content .modal-footer button.button-clear-selection:hover {
            color: #e87200; }
  .modal-select-contact .select-contact-title {
    color: #191919;
    font-size: 20px;
    letter-spacing: -0.6px;
    margin: 0 0 24px; }
    .modal-select-contact .select-contact-title span {
      color: rgba(25, 25, 25, 0.6);
      font-size: 14px;
      letter-spacing: -0.2px; }
  .modal-select-contact .button-submit {
    width: calc(50% - 4px);
    margin: 0 8px 0 0 !important; }
    .modal-select-contact .button-submit:nth-last-child(1) {
      margin: 0 !important; }

.dropdown-list-per-page {
  width: 55px; }
  .dropdown-list-per-page button {
    width: 100%;
    box-shadow: none !important;
    outline: none !important;
    border: solid 1px #dcdcdc !important;
    background: #ffffff !important;
    color: #191919 !important;
    font-size: 12px;
    letter-spacing: -0.4px;
    -webkit-transition: ease-in-out .1s;
    transition: ease-in-out .1s;
    text-align: left;
    padding: 2px 9px;
    position: relative; }
    .dropdown-list-per-page button svg {
      -webkit-transition: ease-in-out .1s;
      transition: ease-in-out .1s;
      position: absolute;
      right: 9px;
      top: 50%;
      -webkit-transform: translate(0, -50%);
              transform: translate(0, -50%); }
      .dropdown-list-per-page button svg.show-dropdown {
        -webkit-transform: translate(0, -50%) rotate(180deg);
                transform: translate(0, -50%) rotate(180deg); }
    .dropdown-list-per-page button:hover {
      color: #ff7d00 !important; }
  .dropdown-list-per-page .dropdown-menu {
    width: 55px;
    padding: 0;
    border-radius: 8px;
    min-width: unset; }
    .dropdown-list-per-page .dropdown-menu button {
      border: 0 !important;
      padding: 2px 9px 2px 24px;
      cursor: pointer;
      z-index: 1; }
      .dropdown-list-per-page .dropdown-menu button:nth-child(1) {
        border-top-left-radius: 8px;
        border-top-right-radius: 8px; }
      .dropdown-list-per-page .dropdown-menu button:nth-last-child(1) {
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px; }
      .dropdown-list-per-page .dropdown-menu button svg {
        position: absolute;
        left: 9px;
        top: 50%;
        -webkit-transform: translate(0, -50%);
                transform: translate(0, -50%);
        font-size: 10px; }
      .dropdown-list-per-page .dropdown-menu button.selected-list-per-page {
        color: #ff7d00 !important; }
      .dropdown-list-per-page .dropdown-menu button:hover {
        color: #ff7d00 !important; }

.broadcast-message-form .preview-outer-wrapper {
  width: 610px;
  max-width: 100%; }
  .broadcast-message-form .preview-outer-wrapper .preview-inner-wrapper {
    padding: 16px 24px;
    position: relative;
    border-radius: 8px;
    border: solid 1px #dcdcdc;
    text-align: center;
    margin: 24px 0 0; }
    .broadcast-message-form .preview-outer-wrapper .preview-inner-wrapper .import-csv-icon {
      width: 48px;
      display: inline-block;
      margin: 8px 0 0; }
    .broadcast-message-form .preview-outer-wrapper .preview-inner-wrapper .import-csv-title {
      margin: 8px 0 0;
      font-size: 16px;
      letter-spacing: -0.5px; }
    .broadcast-message-form .preview-outer-wrapper .preview-inner-wrapper .import-csv-desc {
      margin: 4px 0 8px;
      font-size: 14px;
      letter-spacing: -0.2px; }
    .broadcast-message-form .preview-outer-wrapper .preview-inner-wrapper .lds-ring {
      width: 33px;
      height: 33px;
      top: unset;
      right: unset; }
      .broadcast-message-form .preview-outer-wrapper .preview-inner-wrapper .lds-ring div {
        border: solid 4px #fff;
        border-color: #ff7d00 transparent transparent transparent;
        width: 33px;
        height: 33px;
        margin: 12px 0 0; }
    .broadcast-message-form .preview-outer-wrapper .preview-inner-wrapper .importing-csv-title {
      margin: 6px 0 8px;
      font-size: 16px;
      letter-spacing: -0.5px; }
  .broadcast-message-form .preview-outer-wrapper .preview-inner-wrapper-2 {
    padding: 16px 24px;
    position: relative;
    border-radius: 8px;
    border: solid 1px #dcdcdc;
    text-align: left;
    margin: 24px 0 0; }
    .broadcast-message-form .preview-outer-wrapper .preview-inner-wrapper-2 .csv-fileicon {
      position: absolute;
      left: 24px;
      top: 50%;
      -webkit-transform: translate(0, -50%);
              transform: translate(0, -50%); }
    .broadcast-message-form .preview-outer-wrapper .preview-inner-wrapper-2 .csv-filename {
      font-size: 16px;
      letter-spacing: -0.5px;
      margin: 0;
      padding: 0 110px 0 40px;
      overflow: hidden;
      text-overflow: ellipsis; }
    .broadcast-message-form .preview-outer-wrapper .preview-inner-wrapper-2 .preview-data-button {
      font-size: 14px;
      letter-spacing: -0.4px;
      color: #ff7d00;
      cursor: pointer;
      position: absolute;
      right: 24px;
      top: 50%;
      -webkit-transform: translate(0, -50%);
              transform: translate(0, -50%); }
      .broadcast-message-form .preview-outer-wrapper .preview-inner-wrapper-2 .preview-data-button svg {
        margin-right: 4px;
        position: relative;
        top: -1px;
        right: 0; }
      .broadcast-message-form .preview-outer-wrapper .preview-inner-wrapper-2 .preview-data-button:hover {
        color: var(--main-darker-orange); }

.broadcast-message-form .total-recipient {
  color: #191919;
  font-size: 14px;
  letter-spacing: -0.4px;
  margin: 8px 0 0; }

.broadcast-message-form .contacts-info b {
  color: rgba(25, 25, 25, 0.8);
  font-size: 12px;
  letter-spacing: -0.4px;
  margin: 8px 0 0; }

.broadcast-message-form .contacts-info svg {
  margin-right: 4px; }

.broadcast-message-form .outer-button-wrapper label {
  margin: 0;
  vertical-align: top; }

.broadcast-message-form .outer-button-wrapper button {
  margin-right: 8px;
  margin-top: 0; }

.modal-replace-data .modal-content {
  border-radius: 8px;
  width: 500px; }
  .modal-replace-data .modal-content .modal-body {
    padding: 32px;
    border-radius: 8px; }
    .modal-replace-data .modal-content .modal-body .modal-title {
      font-size: 20px;
      letter-spacing: -0.6px; }
    .modal-replace-data .modal-content .modal-body .modal-desc {
      margin: 4px 0 20px;
      color: rgba(25, 25, 25, 0.6);
      font-size: 14px;
      letter-spacing: -0.2px; }
    .modal-replace-data .modal-content .modal-body button {
      margin-right: 4px;
      width: calc(50% - 2px); }
      .modal-replace-data .modal-content .modal-body button:nth-last-child(1) {
        margin-right: 0; }

.modal-preview-contacts .modal-content {
  width: 95vw;
  height: 90vh;
  overflow: auto;
  border-radius: 8px; }
  .modal-preview-contacts .modal-content .modal-body {
    padding: 0;
    position: relative;
    border-radius: 8px;
    height: 100%; }
    .modal-preview-contacts .modal-content .modal-body .modal-preview-contacts-title {
      font-size: 24px;
      letter-spacing: -0.6px;
      padding: 34px;
      margin: 0; }
      .modal-preview-contacts .modal-content .modal-body .modal-preview-contacts-title svg {
        position: relative;
        top: -2px; }
    .modal-preview-contacts .modal-content .modal-body .modal-preview-contacts-close {
      color: #ff7d00;
      cursor: pointer;
      font-size: 24px;
      position: absolute;
      top: 34px;
      right: 34px; }
      .modal-preview-contacts .modal-content .modal-body .modal-preview-contacts-close:hover {
        color: var(--main-darker-orange); }
    .modal-preview-contacts .modal-content .modal-body .modal-preview-contacts-table-wrapper {
      width: 100%;
      overflow: auto;
      height: calc(100% - 195px); }
    .modal-preview-contacts .modal-content .modal-body table {
      width: 100%; }
      .modal-preview-contacts .modal-content .modal-body table thead tr td {
        vertical-align: top;
        padding: 12px 14px;
        max-width: 200px;
        word-break: break-word; }
        .modal-preview-contacts .modal-content .modal-body table thead tr td:nth-child(1) {
          padding: 12px 14px 12px 34px; }
      .modal-preview-contacts .modal-content .modal-body table tbody tr {
        border-top: solid 1px rgba(25, 25, 25, 0.1); }
        .modal-preview-contacts .modal-content .modal-body table tbody tr td {
          vertical-align: top;
          max-width: 200px;
          padding: 14px;
          word-break: break-word; }
          .modal-preview-contacts .modal-content .modal-body table tbody tr td:nth-child(1) {
            padding: 12px 14px 12px 34px; }
    .modal-preview-contacts .modal-content .modal-body #pagination-wrapper {
      height: 87px;
      line-height: 87px;
      padding: 0 34px; }

.bc-textarea-wrapper {
  max-width: 100%;
  height: 154px;
  position: relative;
  font-size: 0; }
  .bc-textarea-wrapper textarea {
    width: 514px !important;
    display: inline-block;
    max-width: 100%;
    height: 106px !important;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    position: relative; }
  .bc-textarea-wrapper .broadcast-message-action-wrapper {
    position: absolute;
    height: 48px;
    border: solid 1px #dcdcdc;
    border-top: 0;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    background: #ffffff;
    bottom: 0;
    left: 0;
    font-size: 12px;
    letter-spacing: -0.4px;
    width: 100%;
    padding: 12px 16px;
    width: 514px !important;
    display: inline-block;
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden; }
    .bc-textarea-wrapper .broadcast-message-action-wrapper .broadcast-message-action-inner-wrapper {
      position: absolute;
      top: 12px;
      left: 50%;
      -webkit-transform: translate(-50%, 0);
              transform: translate(-50%, 0);
      width: 100%;
      max-width: 100%;
      height: 52px;
      padding: 0 16px;
      overflow-x: auto;
      overflow-y: hidden; }
    .bc-textarea-wrapper .broadcast-message-action-wrapper .add-variable-title {
      font-size: 12px;
      letter-spacing: -0.4px; }
      .bc-textarea-wrapper .broadcast-message-action-wrapper .add-variable-title svg {
        position: relative;
        top: -1px;
        margin-right: 13px;
        outline: none; }
    .bc-textarea-wrapper .broadcast-message-action-wrapper .button-add-var {
      margin: 0 8px 0 0;
      color: rgba(25, 25, 25, 0.6);
      border: solid 1px rgba(25, 25, 25, 0.1);
      letter-spacing: -0.4px;
      font-size: 12px;
      border-radius: 4px;
      cursor: pointer;
      display: inline-block !important;
      padding: 2px 8px;
      background: #ffffff;
      -webkit-transition: ease-in-out .1s;
      transition: ease-in-out .1s; }
      .bc-textarea-wrapper .broadcast-message-action-wrapper .button-add-var:hover {
        color: #ffffff;
        border: solid 1px #ff7d00;
        background: #ff7d00; }
      .bc-textarea-wrapper .broadcast-message-action-wrapper .button-add-var svg {
        position: relative;
        top: -1px;
        margin-right: 4px; }
    .bc-textarea-wrapper .broadcast-message-action-wrapper .add-variable-panel-wrapper {
      position: absolute;
      left: 16px;
      bottom: 37px;
      background: #ffffff;
      border-radius: 8px;
      color: #191919;
      box-shadow: 0px 2px 20px rgba(25, 25, 25, 0.18);
      padding: 16px 24px;
      width: 350px;
      display: inline-block;
      text-align: left;
      z-index: 1; }
      .bc-textarea-wrapper .broadcast-message-action-wrapper .add-variable-panel-wrapper .button-close {
        color: rgba(25, 25, 25, 0.6);
        cursor: pointer;
        position: absolute;
        top: 16px;
        font-size: 18px;
        right: 24px; }
        .bc-textarea-wrapper .broadcast-message-action-wrapper .add-variable-panel-wrapper .button-close:hover {
          color: #191919; }
      .bc-textarea-wrapper .broadcast-message-action-wrapper .add-variable-panel-wrapper .button-variable {
        margin: 10px 8px 0 0;
        color: rgba(25, 25, 25, 0.6);
        border: solid 1px rgba(25, 25, 25, 0.1);
        letter-spacing: -0.4px;
        font-size: 12px;
        border-radius: 4px;
        cursor: pointer;
        display: inline-block !important;
        padding: 2px 8px;
        background: #ffffff;
        -webkit-transition: ease-in-out .1s;
        transition: ease-in-out .1s;
        display: inline-block;
        width: unset;
        vertical-align: top; }
        .bc-textarea-wrapper .broadcast-message-action-wrapper .add-variable-panel-wrapper .button-variable:hover {
          color: #ffffff;
          border: solid 1px #ff7d00;
          background: #ff7d00; }
      .bc-textarea-wrapper .broadcast-message-action-wrapper .add-variable-panel-wrapper .add-variable-panel-title {
        font-size: 14px;
        letter-spacing: -0.4px;
        background: #ffffff; }
      .bc-textarea-wrapper .broadcast-message-action-wrapper .add-variable-panel-wrapper .variable-input {
        position: relative;
        margin-top: 9px; }
        .bc-textarea-wrapper .broadcast-message-action-wrapper .add-variable-panel-wrapper .variable-input input {
          border-radius: 8px !important;
          padding-right: 111px; }
          .bc-textarea-wrapper .broadcast-message-action-wrapper .add-variable-panel-wrapper .variable-input input:focus {
            border: solid 1px #ff7e00; }
        .bc-textarea-wrapper .broadcast-message-action-wrapper .add-variable-panel-wrapper .variable-input textarea {
          border-radius: 8px !important; }
        .bc-textarea-wrapper .broadcast-message-action-wrapper .add-variable-panel-wrapper .variable-input button {
          position: absolute;
          font-size: 12px;
          letter-spacing: 2.5px;
          height: 24px;
          display: inline-block;
          border-radius: 4px;
          width: unset;
          padding: 0 12px 0 10px;
          right: 16px;
          top: 12px;
          margin: 0; }
          .bc-textarea-wrapper .broadcast-message-action-wrapper .add-variable-panel-wrapper .variable-input button svg {
            margin-right: 4px;
            position: relative;
            top: -1px;
            font-size: 14px; }
      .bc-textarea-wrapper .broadcast-message-action-wrapper .add-variable-panel-wrapper ul {
        list-style: none;
        border-radius: 8px;
        background: rgba(25, 25, 25, 0.05);
        margin: 12px 0 0;
        padding: 8px 0 0; }
        .bc-textarea-wrapper .broadcast-message-action-wrapper .add-variable-panel-wrapper ul li {
          font-size: 14px;
          letter-spacing: -0.4px;
          margin: 0;
          padding: 0 16px 8px 16px; }

.form-image-message-wrapper-new {
  position: relative; }
  .form-image-message-wrapper-new input[type="file"] {
    display: none; }
  .form-image-message-wrapper-new label {
    display: inline-block !important; }
    .form-image-message-wrapper-new label span {
      color: rgba(25, 25, 25, 0.6); }
    .form-image-message-wrapper-new label.button-upload-image {
      margin: 0 8px 0 0;
      color: rgba(25, 25, 25, 0.6);
      border: solid 1px rgba(25, 25, 25, 0.1);
      letter-spacing: -0.4px;
      font-size: 12px;
      border-radius: 4px;
      cursor: pointer;
      display: inline-block !important;
      padding: 2px 8px;
      background: #ffffff;
      -webkit-transition: ease-in-out .1s;
      transition: ease-in-out .1s; }
      .form-image-message-wrapper-new label.button-upload-image:hover {
        color: #ffffff;
        border: solid 1px #ff7d00;
        background: #ff7d00; }
      .form-image-message-wrapper-new label.button-upload-image svg {
        position: relative;
        top: -1px;
        margin-right: 4px; }
    .form-image-message-wrapper-new label.button-add-var {
      margin: 0 8px 0 0;
      color: rgba(25, 25, 25, 0.6);
      border: solid 1px rgba(25, 25, 25, 0.1);
      letter-spacing: -0.4px;
      font-size: 12px;
      border-radius: 4px;
      cursor: pointer;
      display: inline-block !important;
      padding: 2px 8px;
      background: #ffffff;
      -webkit-transition: ease-in-out .1s;
      transition: ease-in-out .1s; }
      .form-image-message-wrapper-new label.button-add-var:hover {
        color: #ffffff;
        border: solid 1px #ff7d00;
        background: #ff7d00; }
      .form-image-message-wrapper-new label.button-add-var svg {
        position: relative;
        top: -1px;
        margin-right: 4px; }
  .form-image-message-wrapper-new .image-and-caption-wrapper {
    position: relative;
    width: 494px;
    max-width: 100%;
    font-size: 0; }
    .form-image-message-wrapper-new .image-and-caption-wrapper .broadcast-message-action-wrapper {
      position: absolute;
      height: 48px;
      border: solid 1px #dcdcdc;
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
      background: #ffffff;
      bottom: 0;
      left: 0;
      font-size: 12px;
      letter-spacing: -0.4px;
      display: block;
      width: 100%;
      padding: 12px 16px;
      display: inline-block;
      max-width: 100%;
      white-space: nowrap;
      overflow: hidden; }
      .form-image-message-wrapper-new .image-and-caption-wrapper .broadcast-message-action-wrapper::before {
        content: "";
        height: 48px;
        left: 134px;
        width: 1px;
        border-left: solid 1px #dcdcdc;
        position: absolute;
        top: 0; }
      .form-image-message-wrapper-new .image-and-caption-wrapper .broadcast-message-action-wrapper .broadcast-message-action-inner-wrapper {
        position: absolute;
        top: 12px;
        left: 134px;
        width: calc(100% - 134px);
        max-width: 100%;
        height: 52px;
        padding: 0 16px;
        overflow-x: auto;
        overflow-y: hidden; }
      .form-image-message-wrapper-new .image-and-caption-wrapper .broadcast-message-action-wrapper .add-variable-title {
        font-size: 12px;
        letter-spacing: -0.4px; }
        .form-image-message-wrapper-new .image-and-caption-wrapper .broadcast-message-action-wrapper .add-variable-title svg {
          position: relative;
          top: -1px;
          margin-right: 13px;
          outline: none; }
      .form-image-message-wrapper-new .image-and-caption-wrapper .broadcast-message-action-wrapper .add-variable-panel-wrapper {
        position: absolute;
        left: 128px;
        bottom: 37px;
        background: #ffffff;
        border-radius: 8px;
        color: #191919;
        box-shadow: 0px 2px 20px rgba(25, 25, 25, 0.18);
        padding: 16px 24px;
        width: 350px;
        display: inline-block;
        text-align: left;
        z-index: 1; }
        .form-image-message-wrapper-new .image-and-caption-wrapper .broadcast-message-action-wrapper .add-variable-panel-wrapper .button-close {
          color: rgba(25, 25, 25, 0.6);
          cursor: pointer;
          position: absolute;
          top: 16px;
          font-size: 18px;
          right: 24px; }
          .form-image-message-wrapper-new .image-and-caption-wrapper .broadcast-message-action-wrapper .add-variable-panel-wrapper .button-close:hover {
            color: #191919; }
        .form-image-message-wrapper-new .image-and-caption-wrapper .broadcast-message-action-wrapper .add-variable-panel-wrapper .button-variable {
          margin: 10px 8px 0 0;
          color: rgba(25, 25, 25, 0.6);
          border: solid 1px rgba(25, 25, 25, 0.1);
          letter-spacing: -0.4px;
          font-size: 12px;
          border-radius: 4px;
          cursor: pointer;
          display: inline-block !important;
          padding: 2px 8px;
          background: #ffffff;
          -webkit-transition: ease-in-out .1s;
          transition: ease-in-out .1s;
          display: inline-block;
          width: unset;
          vertical-align: top; }
          .form-image-message-wrapper-new .image-and-caption-wrapper .broadcast-message-action-wrapper .add-variable-panel-wrapper .button-variable:hover {
            color: #ffffff;
            border: solid 1px #ff7d00;
            background: #ff7d00; }
        .form-image-message-wrapper-new .image-and-caption-wrapper .broadcast-message-action-wrapper .add-variable-panel-wrapper .add-variable-panel-title {
          font-size: 14px;
          letter-spacing: -0.4px;
          background: #ffffff; }
        .form-image-message-wrapper-new .image-and-caption-wrapper .broadcast-message-action-wrapper .add-variable-panel-wrapper .variable-input {
          position: relative;
          margin-top: 9px; }
          .form-image-message-wrapper-new .image-and-caption-wrapper .broadcast-message-action-wrapper .add-variable-panel-wrapper .variable-input input {
            border-radius: 8px !important;
            padding-right: 111px; }
            .form-image-message-wrapper-new .image-and-caption-wrapper .broadcast-message-action-wrapper .add-variable-panel-wrapper .variable-input input:focus {
              border: solid 1px #ff7e00; }
          .form-image-message-wrapper-new .image-and-caption-wrapper .broadcast-message-action-wrapper .add-variable-panel-wrapper .variable-input textarea {
            border-radius: 8px !important; }
          .form-image-message-wrapper-new .image-and-caption-wrapper .broadcast-message-action-wrapper .add-variable-panel-wrapper .variable-input button {
            position: absolute;
            font-size: 12px;
            letter-spacing: 2.5px;
            height: 24px;
            display: inline-block;
            border-radius: 4px;
            width: unset;
            padding: 0 12px 0 10px;
            right: 16px;
            top: 12px;
            margin: 0; }
            .form-image-message-wrapper-new .image-and-caption-wrapper .broadcast-message-action-wrapper .add-variable-panel-wrapper .variable-input button svg {
              margin-right: 4px;
              position: relative;
              top: -1px;
              font-size: 14px; }
        .form-image-message-wrapper-new .image-and-caption-wrapper .broadcast-message-action-wrapper .add-variable-panel-wrapper ul {
          list-style: none;
          border-radius: 8px;
          background: rgba(25, 25, 25, 0.05);
          margin: 12px 0 0;
          padding: 8px 0 0; }
          .form-image-message-wrapper-new .image-and-caption-wrapper .broadcast-message-action-wrapper .add-variable-panel-wrapper ul li {
            font-size: 14px;
            letter-spacing: -0.4px;
            margin: 0;
            padding: 0 16px 8px 16px; }
    .form-image-message-wrapper-new .image-and-caption-wrapper .image-preview-wrapper {
      background: #ffffff;
      position: absolute;
      top: 1px;
      left: 16px;
      padding: 11px 0 8px 0;
      border-bottom: solid 1px rgba(25, 25, 25, 0.1);
      width: calc(100% - 32px); }
      .form-image-message-wrapper-new .image-and-caption-wrapper .image-preview-wrapper.image-preview-wrapper-grey {
        background: #e8e8e8; }
      .form-image-message-wrapper-new .image-and-caption-wrapper .image-preview-wrapper svg {
        font-size: 64px;
        position: relative;
        left: -5px;
        color: #9b9b9b; }
      .form-image-message-wrapper-new .image-and-caption-wrapper .image-preview-wrapper .image-preview-frame {
        border: solid 1px rgba(25, 25, 25, 0.1);
        width: 64px;
        height: 64px;
        display: inline-block;
        position: relative;
        border-radius: 8px;
        overflow: hidden; }
        .form-image-message-wrapper-new .image-and-caption-wrapper .image-preview-wrapper .image-preview-frame img {
          max-width: 100%;
          max-height: 100%;
          position: absolute;
          left: 50%;
          top: 50%;
          -webkit-transform: translate(-50%, -50%);
                  transform: translate(-50%, -50%); }
        .form-image-message-wrapper-new .image-and-caption-wrapper .image-preview-wrapper .image-preview-frame .progress-wrapper {
          position: absolute;
          top: 0;
          left: 0;
          background: rgba(25, 25, 25, 0.4);
          width: 100%;
          height: 100%; }
          .form-image-message-wrapper-new .image-and-caption-wrapper .image-preview-wrapper .image-preview-frame .progress-wrapper .CircularProgressbar {
            width: calc(100% - 12px);
            height: calc(100% - 12px);
            position: absolute;
            top: 6px;
            left: 6px; }
            .form-image-message-wrapper-new .image-and-caption-wrapper .image-preview-wrapper .image-preview-frame .progress-wrapper .CircularProgressbar .CircularProgressbar-trail {
              stroke: transparent;
              stroke-width: 20; }
            .form-image-message-wrapper-new .image-and-caption-wrapper .image-preview-wrapper .image-preview-frame .progress-wrapper .CircularProgressbar .CircularProgressbar-path {
              stroke: #ffffff; }
        .form-image-message-wrapper-new .image-and-caption-wrapper .image-preview-wrapper .image-preview-frame .image-button-wrapper {
          position: absolute;
          top: 0;
          left: 0;
          background: rgba(25, 25, 25, 0.4);
          width: 100%;
          height: 100%;
          display: none; }
          .form-image-message-wrapper-new .image-and-caption-wrapper .image-preview-wrapper .image-preview-frame .image-button-wrapper .close-button-wrapper {
            width: 16px;
            height: 16px;
            border-radius: 50%;
            background: #636462;
            text-align: center;
            font-size: 7px;
            color: #ffffff;
            cursor: pointer;
            position: absolute;
            top: 4px;
            right: 4px; }
            .form-image-message-wrapper-new .image-and-caption-wrapper .image-preview-wrapper .image-preview-frame .image-button-wrapper .close-button-wrapper:hover {
              background: #ff3f57; }
          .form-image-message-wrapper-new .image-and-caption-wrapper .image-preview-wrapper .image-preview-frame .image-button-wrapper svg.preview-button {
            font-size: 20px;
            color: #ffffff;
            cursor: pointer;
            position: absolute;
            top: 50%;
            left: 50%;
            -webkit-transform: translate(-50%, -50%);
                    transform: translate(-50%, -50%); }
          .form-image-message-wrapper-new .image-and-caption-wrapper .image-preview-wrapper .image-preview-frame .image-button-wrapper svg.close-button {
            color: #ffffff;
            font-size: 12px;
            position: absolute;
            left: 50%;
            top: 50%;
            -webkit-transform: translate(-50%, -50%);
                    transform: translate(-50%, -50%); }
        .form-image-message-wrapper-new .image-and-caption-wrapper .image-preview-wrapper .image-preview-frame:hover .image-button-wrapper {
          display: inline-block; }
    .form-image-message-wrapper-new .image-and-caption-wrapper textarea {
      padding: 92px 16px 60px 16px;
      height: 220px !important; }
      .form-image-message-wrapper-new .image-and-caption-wrapper textarea[disabled] {
        background: #e8e8e8;
        cursor: not-allowed;
        padding: 12px 16px;
        height: 138px !important; }
      .form-image-message-wrapper-new .image-and-caption-wrapper textarea.text-area-default {
        padding: 16px 16px 60px 16px;
        height: 150px !important; }

.modal-image-preview {
  width: 100% !important; }
  .modal-image-preview .modal-content {
    width: 100%;
    height: 100%;
    background: unset; }
    .modal-image-preview .modal-content .modal-body {
      padding: 56px 86px;
      position: relative; }
      .modal-image-preview .modal-content .modal-body img {
        max-width: calc(100% - 112px);
        max-height: calc(100% - 172px);
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
        background: #ffffff; }
      .modal-image-preview .modal-content .modal-body svg {
        font-size: 24px;
        color: #ffffff;
        cursor: pointer;
        position: absolute;
        top: 44px;
        right: 44px; }

.form-file-message-wrapper-new {
  position: relative; }
  .form-file-message-wrapper-new input[type="file"] {
    display: none; }
  .form-file-message-wrapper-new label {
    display: inline-block !important; }
    .form-file-message-wrapper-new label span {
      color: rgba(25, 25, 25, 0.6); }
    .form-file-message-wrapper-new label.button-upload-file {
      margin: 0 8px 0 0;
      color: rgba(25, 25, 25, 0.6);
      border: solid 1px rgba(25, 25, 25, 0.1);
      letter-spacing: -0.4px;
      font-size: 12px;
      border-radius: 4px;
      cursor: pointer;
      display: inline-block !important;
      padding: 2px 8px;
      background: #ffffff;
      -webkit-transition: ease-in-out .1s;
      transition: ease-in-out .1s; }
      .form-file-message-wrapper-new label.button-upload-file:hover {
        color: #ffffff;
        border: solid 1px #ff7d00;
        background: #ff7d00; }
      .form-file-message-wrapper-new label.button-upload-file svg {
        position: relative;
        top: -1px;
        margin-right: 4px; }
    .form-file-message-wrapper-new label.button-add-var {
      margin: 0 8px 0 0;
      color: rgba(25, 25, 25, 0.6);
      border: solid 1px rgba(25, 25, 25, 0.1);
      letter-spacing: -0.4px;
      font-size: 12px;
      border-radius: 4px;
      cursor: pointer;
      display: inline-block !important;
      padding: 2px 8px;
      background: #ffffff;
      -webkit-transition: ease-in-out .1s;
      transition: ease-in-out .1s; }
      .form-file-message-wrapper-new label.button-add-var:hover {
        color: #ffffff;
        border: solid 1px #ff7d00;
        background: #ff7d00; }
      .form-file-message-wrapper-new label.button-add-var svg {
        position: relative;
        top: -1px;
        margin-right: 4px; }
  .form-file-message-wrapper-new .file-and-caption-wrapper {
    position: relative;
    width: 494px;
    max-width: 100%;
    font-size: 0; }
    .form-file-message-wrapper-new .file-and-caption-wrapper .broadcast-message-action-wrapper {
      position: absolute;
      height: 48px;
      border: solid 1px #dcdcdc;
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
      background: #ffffff;
      bottom: 0;
      left: 0;
      font-size: 12px;
      letter-spacing: -0.4px;
      display: block;
      width: 100%;
      padding: 12px 16px; }
    .form-file-message-wrapper-new .file-and-caption-wrapper .file-preview-wrapper {
      background: #ffffff;
      position: absolute;
      top: 1px;
      left: 16px;
      padding: 11px 0 0 0;
      width: calc(100% - 32px); }
      .form-file-message-wrapper-new .file-and-caption-wrapper .file-preview-wrapper svg {
        font-size: 64px;
        position: relative;
        left: -5px;
        color: #9b9b9b; }
      .form-file-message-wrapper-new .file-and-caption-wrapper .file-preview-wrapper .file-preview-frame {
        display: inline-block;
        position: relative;
        border-radius: 8px;
        overflow: hidden; }
        .form-file-message-wrapper-new .file-and-caption-wrapper .file-preview-wrapper .file-preview-frame .file-progress-upload-wrapper {
          font-size: 14px;
          letter-spacing: -0.2px;
          color: #191919;
          margin: 0;
          max-width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          display: inline-block;
          position: relative;
          padding-right: 22px; }
          .form-file-message-wrapper-new .file-and-caption-wrapper .file-preview-wrapper .file-preview-frame .file-progress-upload-wrapper svg {
            left: unset;
            top: -2px;
            font-size: 16px;
            letter-spacing: -0.2px;
            margin-right: 4px; }
            .form-file-message-wrapper-new .file-and-caption-wrapper .file-preview-wrapper .file-preview-frame .file-progress-upload-wrapper svg.remove-file {
              top: -1px;
              color: #ff7d00;
              margin-left: 4px;
              cursor: pointer;
              position: absolute;
              right: 0;
              top: 50%;
              -webkit-transform: translate(0, -50%);
                      transform: translate(0, -50%);
              margin: 0; }
              .form-file-message-wrapper-new .file-and-caption-wrapper .file-preview-wrapper .file-preview-frame .file-progress-upload-wrapper svg.remove-file:hover {
                color: #e83b51; }
        .form-file-message-wrapper-new .file-and-caption-wrapper .file-preview-wrapper .file-preview-frame img {
          max-width: 100%;
          max-height: 100%;
          position: absolute;
          left: 50%;
          top: 50%;
          -webkit-transform: translate(-50%, -50%);
                  transform: translate(-50%, -50%); }
        .form-file-message-wrapper-new .file-and-caption-wrapper .file-preview-wrapper .file-preview-frame .file-button-wrapper {
          position: absolute;
          top: 0;
          left: 0;
          background: rgba(25, 25, 25, 0.4);
          width: 100%;
          height: 100%;
          display: none; }
          .form-file-message-wrapper-new .file-and-caption-wrapper .file-preview-wrapper .file-preview-frame .file-button-wrapper .close-button-wrapper {
            width: 16px;
            height: 16px;
            border-radius: 50%;
            background: #636462;
            text-align: center;
            font-size: 7px;
            color: #ffffff;
            cursor: pointer;
            position: absolute;
            top: 4px;
            right: 4px; }
            .form-file-message-wrapper-new .file-and-caption-wrapper .file-preview-wrapper .file-preview-frame .file-button-wrapper .close-button-wrapper:hover {
              background: #ff3f57; }
          .form-file-message-wrapper-new .file-and-caption-wrapper .file-preview-wrapper .file-preview-frame .file-button-wrapper svg.preview-button {
            font-size: 20px;
            color: #ffffff;
            cursor: pointer;
            position: absolute;
            top: 50%;
            left: 50%;
            -webkit-transform: translate(-50%, -50%);
                    transform: translate(-50%, -50%); }
          .form-file-message-wrapper-new .file-and-caption-wrapper .file-preview-wrapper .file-preview-frame .file-button-wrapper svg.close-button {
            color: #ffffff;
            font-size: 12px;
            position: absolute;
            left: 50%;
            top: 50%;
            -webkit-transform: translate(-50%, -50%);
                    transform: translate(-50%, -50%); }
        .form-file-message-wrapper-new .file-and-caption-wrapper .file-preview-wrapper .file-preview-frame:hover .file-button-wrapper {
          display: inline-block; }
    .form-file-message-wrapper-new .file-and-caption-wrapper .placeholder-file {
      font-size: 14px;
      color: rgba(25, 25, 25, 0.6);
      height: 92px;
      border: solid 1px #dcdcdc;
      border-radius: 8px;
      padding: 12px 16px; }
    .form-file-message-wrapper-new .file-and-caption-wrapper textarea {
      padding: 48px 16px 60px 16px;
      height: 168px !important; }
      .form-file-message-wrapper-new .file-and-caption-wrapper textarea[disabled] {
        background: #e8e8e8;
        cursor: not-allowed;
        padding: 12px 16px;
        height: 138px !important; }
    .form-file-message-wrapper-new .file-and-caption-wrapper .label-upload-file {
      border: dotted 2px #ff7d00;
      cursor: pointer;
      margin: 0;
      width: 300px;
      border-radius: 8px;
      padding: 8px 0;
      text-align: center;
      font-size: 16px;
      letter-spacing: -0.5px;
      -webkit-transition: ease-in-out .1s;
      transition: ease-in-out .1s;
      color: #ff7d00; }
      .form-file-message-wrapper-new .file-and-caption-wrapper .label-upload-file svg {
        position: relative;
        top: -2px;
        margin-right: 4px; }
      .form-file-message-wrapper-new .file-and-caption-wrapper .label-upload-file:hover {
        border: dotted 2px #e87200;
        color: #e87200; }
    .form-file-message-wrapper-new .file-and-caption-wrapper .file-result-wrapper {
      font-size: 14px;
      letter-spacing: -0.2px;
      background: #e3e3e3;
      border-radius: 8px;
      padding: 15px 98px 15px 42px;
      position: relative;
      width: 494px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden; }
      .form-file-message-wrapper-new .file-and-caption-wrapper .file-result-wrapper .progress-wrapper {
        position: absolute;
        top: 50%;
        -webkit-transform: translate(0, -50%);
                transform: translate(0, -50%);
        left: 17px;
        margin-right: 4px;
        display: inline-block;
        width: 16px;
        height: 16px; }
        .form-file-message-wrapper-new .file-and-caption-wrapper .file-result-wrapper .progress-wrapper .CircularProgressbar {
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0; }
          .form-file-message-wrapper-new .file-and-caption-wrapper .file-result-wrapper .progress-wrapper .CircularProgressbar .CircularProgressbar-trail {
            stroke: transparent;
            stroke-width: 20; }
          .form-file-message-wrapper-new .file-and-caption-wrapper .file-result-wrapper .progress-wrapper .CircularProgressbar .CircularProgressbar-path {
            stroke: #ff7d00; }
      .form-file-message-wrapper-new .file-and-caption-wrapper .file-result-wrapper .icon-file {
        font-size: 20px;
        position: absolute;
        left: 17px;
        top: 50%;
        -webkit-transform: translate(0, -50%);
                transform: translate(0, -50%);
        margin-right: 4px; }
      .form-file-message-wrapper-new .file-and-caption-wrapper .file-result-wrapper .remove-file {
        cursor: pointer;
        position: absolute;
        right: 17px;
        top: 50%;
        -webkit-transform: translate(0, -50%);
                transform: translate(0, -50%);
        color: #FF3F57; }
        .form-file-message-wrapper-new .file-and-caption-wrapper .file-result-wrapper .remove-file svg {
          font-size: 20px;
          position: relative;
          top: -1px; }
        .form-file-message-wrapper-new .file-and-caption-wrapper .file-result-wrapper .remove-file:hover {
          color: #e83b51; }

.modal-file-preview {
  width: 100% !important; }
  .modal-file-preview .modal-content {
    width: 100%;
    height: 100%;
    background: unset; }
    .modal-file-preview .modal-content .modal-body {
      padding: 56px 86px;
      position: relative; }
      .modal-file-preview .modal-content .modal-body img {
        max-width: calc(100% - 112px);
        max-height: calc(100% - 172px);
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
        background: #ffffff; }
      .modal-file-preview .modal-content .modal-body svg {
        font-size: 24px;
        color: #ffffff;
        cursor: pointer;
        position: absolute;
        top: 44px;
        right: 44px; }

.broadcast-message-form-additional-message {
  border-top: solid 1px #dcdcdc;
  margin-top: 24px;
  padding-top: 24px; }
  .broadcast-message-form-additional-message .message-content-title {
    font-size: 16px;
    letter-spacing: -0.5px;
    width: 100%;
    display: block;
    position: relative; }
    .broadcast-message-form-additional-message .message-content-title img {
      margin-right: 4px;
      position: relative;
      top: -1px; }
    .broadcast-message-form-additional-message .message-content-title .delete-message-button {
      position: absolute;
      width: unset;
      right: 0;
      top: 50%;
      -webkit-transform: translate(0, -50%);
              transform: translate(0, -50%);
      margin: 0; }
  .broadcast-message-form-additional-message .more-message-type-selection-wrapper {
    width: 100%;
    position: relative;
    display: block; }
    .broadcast-message-form-additional-message .more-message-type-selection-wrapper .message-type-selection-selection {
      vertical-align: top;
      border-radius: 8px;
      border: solid 1px #dcdcdc;
      display: inline-block;
      position: relative;
      margin-right: 9px;
      font-size: 14px;
      letter-spacing: -0.2px;
      padding: 14px 14px 14px 49px;
      cursor: pointer;
      -webkit-transition: ease-in-out .1s;
      transition: ease-in-out .1s; }
      .broadcast-message-form-additional-message .more-message-type-selection-wrapper .message-type-selection-selection:nth-last-child(1) {
        margin-right: 0; }
      .broadcast-message-form-additional-message .more-message-type-selection-wrapper .message-type-selection-selection.active-message-type-selection-selection {
        border-color: #ff7d00; }
      .broadcast-message-form-additional-message .more-message-type-selection-wrapper .message-type-selection-selection:hover {
        border-color: #ff7d00; }
    .broadcast-message-form-additional-message .more-message-type-selection-wrapper .message-type-selection-icon-wrapper {
      width: 28px;
      height: 28px;
      border-radius: 50%;
      position: absolute;
      top: 50%;
      left: 14px;
      -webkit-transform: translate(0, -50%);
              transform: translate(0, -50%); }
      .broadcast-message-form-additional-message .more-message-type-selection-wrapper .message-type-selection-icon-wrapper img {
        width: 25px;
        height: 25px; }
      .broadcast-message-form-additional-message .more-message-type-selection-wrapper .message-type-selection-icon-wrapper img {
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%); }

.preview-broadcast-message-wrapper .form-content-wrapper .variable-title {
  font-size: 16px;
  padding-bottom: 6px;
  border-bottom: solid 1px #dcdcdc;
  margin-bottom: 16px; }

.preview-broadcast-message-wrapper .form-content-wrapper button {
  width: unset; }

.preview-broadcast-message-wrapper .media-upload-wrapper .media-upload-wrapper-left {
  width: 200px;
  display: inline-block;
  vertical-align: top;
  position: relative; }
  .preview-broadcast-message-wrapper .media-upload-wrapper .media-upload-wrapper-left label {
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 12px;
    width: 100%; }

.preview-broadcast-message-wrapper .media-upload-wrapper input[type="file"] {
  display: none; }

.preview-broadcast-message-wrapper .media-upload-wrapper .media-upload-wrapper-right {
  width: calc(100% - 200px);
  display: inline-block;
  vertical-align: top; }
  .preview-broadcast-message-wrapper .media-upload-wrapper .media-upload-wrapper-right .input-param-wrapper {
    width: 100%; }
    .preview-broadcast-message-wrapper .media-upload-wrapper .media-upload-wrapper-right .input-param-wrapper button {
      width: 100%; }
  .preview-broadcast-message-wrapper .media-upload-wrapper .media-upload-wrapper-right button {
    margin: 0;
    text-transform: capitalize;
    width: unset; }
  .preview-broadcast-message-wrapper .media-upload-wrapper .media-upload-wrapper-right .upload-info {
    font-size: 12px;
    letter-spacing: -0.4px;
    margin-top: 10px; }
    .preview-broadcast-message-wrapper .media-upload-wrapper .media-upload-wrapper-right .upload-info .replace-media {
      font-size: 12px;
      letter-spacing: -0.4px;
      border-radius: 4px;
      padding: 2px 12px 2px 8px;
      width: unset;
      text-transform: capitalize;
      display: block;
      margin: 0 0 8px 0; }
      .preview-broadcast-message-wrapper .media-upload-wrapper .media-upload-wrapper-right .upload-info .replace-media svg {
        position: relative;
        top: -1px;
        margin-right: 4px; }
    .preview-broadcast-message-wrapper .media-upload-wrapper .media-upload-wrapper-right .upload-info svg {
      position: relative;
      margin-right: 4px;
      top: -1px; }
  .preview-broadcast-message-wrapper .media-upload-wrapper .media-upload-wrapper-right .image-video-wrapper {
    width: 48px;
    height: 48px;
    position: relative;
    border-radius: 6px;
    overflow: hidden; }
    .preview-broadcast-message-wrapper .media-upload-wrapper .media-upload-wrapper-right .image-video-wrapper .progress-wrapper {
      position: absolute;
      top: 0;
      left: 0;
      background: rgba(25, 25, 25, 0.4);
      width: 100%;
      height: 100%; }
      .preview-broadcast-message-wrapper .media-upload-wrapper .media-upload-wrapper-right .image-video-wrapper .progress-wrapper .CircularProgressbar {
        width: calc(100% - 12px);
        height: calc(100% - 12px);
        position: absolute;
        top: 6px;
        left: 6px; }
        .preview-broadcast-message-wrapper .media-upload-wrapper .media-upload-wrapper-right .image-video-wrapper .progress-wrapper .CircularProgressbar .CircularProgressbar-trail {
          stroke: transparent;
          stroke-width: 20; }
        .preview-broadcast-message-wrapper .media-upload-wrapper .media-upload-wrapper-right .image-video-wrapper .progress-wrapper .CircularProgressbar .CircularProgressbar-path {
          stroke: #ffffff; }
    .preview-broadcast-message-wrapper .media-upload-wrapper .media-upload-wrapper-right .image-video-wrapper img {
      width: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%);
      max-width: 100%;
      max-height: 100%;
      object-fit: contain; }
    .preview-broadcast-message-wrapper .media-upload-wrapper .media-upload-wrapper-right .image-video-wrapper video {
      width: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%);
      max-width: 100%;
      max-height: 100%;
      object-fit: contain; }
  .preview-broadcast-message-wrapper .media-upload-wrapper .media-upload-wrapper-right .document-wrapper {
    font-size: 14px;
    letter-spacing: -0.2px; }
    .preview-broadcast-message-wrapper .media-upload-wrapper .media-upload-wrapper-right .document-wrapper svg {
      color: #ff7d00;
      font-size: 16px;
      margin-right: 4px;
      position: relative;
      top: -2px; }

.preview-broadcast-message-wrapper .preview-message-param {
  margin: 24px 0 40px;
  height: 48px;
  line-height: 48px;
  position: relative; }
  .preview-broadcast-message-wrapper .preview-message-param label {
    vertical-align: -10px;
    width: 188px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; }
  .preview-broadcast-message-wrapper .preview-message-param .input-param-wrapper {
    position: absolute;
    right: 0;
    width: 336px;
    max-width: calc(100% - 204px);
    height: 48px;
    left: 200px;
    top: 0px;
    background: #ffffff;
    box-sizing: border-box; }
    .preview-broadcast-message-wrapper .preview-message-param .input-param-wrapper .custom-select-option-value-wrapper {
      margin-top: 0; }
  .preview-broadcast-message-wrapper .preview-message-param .input-info-text {
    margin: 0;
    width: 100%;
    position: absolute;
    left: 0;
    top: 52px;
    line-height: 1; }
    .preview-broadcast-message-wrapper .preview-message-param .input-info-text svg {
      top: 0px; }
  .preview-broadcast-message-wrapper .preview-message-param input {
    padding: 12px 16px;
    width: 336px;
    width: 100%;
    height: 48px;
    background: #ffffff;
    border: 1px solid rgba(25, 25, 25, 0.1);
    box-sizing: border-box;
    border-radius: 8px; }
  .preview-broadcast-message-wrapper .preview-message-param input:focus {
    border: 1px solid #ff7e00; }

.preview-broadcast-message-wrapper .form-title-wrapper {
  border: none;
  padding-bottom: 12px;
  position: relative; }
  .preview-broadcast-message-wrapper .form-title-wrapper svg {
    position: relative;
    top: -1px;
    margin-right: 4px; }
  .preview-broadcast-message-wrapper .form-title-wrapper .send-message-button {
    font-size: 16px;
    letter-spacing: -0.5px;
    position: absolute;
    top: 0;
    right: 0; }

.preview-broadcast-message-wrapper .agree-wrapper {
  width: calc(100% - 210px);
  display: inline-block;
  position: relative;
  padding-left: 24px; }
  .preview-broadcast-message-wrapper .agree-wrapper .custom-checkbox {
    position: absolute;
    top: 0;
    left: 0; }
    .preview-broadcast-message-wrapper .agree-wrapper .custom-checkbox label {
      margin: 0; }
  .preview-broadcast-message-wrapper .agree-wrapper p {
    font-size: 14px;
    letter-spacing: -0.2px;
    line-height: 21px;
    color: #000000;
    text-align: left;
    margin: 0; }
  .preview-broadcast-message-wrapper .agree-wrapper b {
    color: #ff7d00;
    cursor: pointer; }
    .preview-broadcast-message-wrapper .agree-wrapper b:hover {
      text-decoration: underline; }

.preview-broadcast-message-wrapper .broadcast-title-time {
  font-size: 16px;
  letter-spacing: -0.5px;
  margin: 24px 0 0; }

.preview-broadcast-message-wrapper .chatroom-preview-wrapper {
  border-radius: 8px;
  width: 100%;
  box-shadow: 0 2px 20px 0 rgba(25, 25, 25, 0.05);
  background: #F3F3F3;
  margin-top: 20px;
  margin-bottom: 20px; }
  .preview-broadcast-message-wrapper .chatroom-preview-wrapper .chatroom-preview-container {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    padding: 16px;
    max-height: 400px;
    overflow: auto; }
    .preview-broadcast-message-wrapper .chatroom-preview-wrapper .chatroom-preview-container .info-message-bubble-wrapper {
      width: 100%;
      text-align: center; }
      .preview-broadcast-message-wrapper .chatroom-preview-wrapper .chatroom-preview-container .info-message-bubble-wrapper .info-message-bubble {
        width: 98px;
        height: 24px;
        position: relative;
        box-shadow: 0 2px 20px 0 rgba(25, 25, 25, 0.05);
        background: #ffffff;
        border-radius: 4px;
        display: inline-block; }
        .preview-broadcast-message-wrapper .chatroom-preview-wrapper .chatroom-preview-container .info-message-bubble-wrapper .info-message-bubble div {
          width: 50px;
          height: 14px;
          background: rgba(25, 25, 25, 0.1);
          position: absolute;
          top: 50%;
          left: 50%;
          -webkit-transform: translate(-50%, -50%);
                  transform: translate(-50%, -50%);
          border-radius: 2px; }
    .preview-broadcast-message-wrapper .chatroom-preview-wrapper .chatroom-preview-container .left-message-bubble-wrapper {
      width: 100%;
      text-align: left;
      position: relative;
      margin-top: 16px; }
      .preview-broadcast-message-wrapper .chatroom-preview-wrapper .chatroom-preview-container .left-message-bubble-wrapper .left-message-bubble .user-wrapper {
        position: relative;
        color: #ffffff;
        border-radius: 50%;
        width: 32px;
        background: #9c89f1;
        height: 32px;
        margin-right: 8px;
        display: inline-block;
        vertical-align: top; }
        .preview-broadcast-message-wrapper .chatroom-preview-wrapper .chatroom-preview-container .left-message-bubble-wrapper .left-message-bubble .user-wrapper svg {
          font-size: 20px;
          position: absolute;
          left: 50%;
          top: 50%;
          -webkit-transform: translate(-50%, -50%);
                  transform: translate(-50%, -50%); }
      .preview-broadcast-message-wrapper .chatroom-preview-wrapper .chatroom-preview-container .left-message-bubble-wrapper .left-message-bubble .left-message-chat-bubble {
        border-radius: 12px;
        border-top-left-radius: 0;
        border: solid 1px rgba(25, 25, 25, 0.1);
        max-width: calc(100% - 40px);
        display: inline-block;
        background: #ffffff;
        padding: 10px;
        width: 405px; }
        .preview-broadcast-message-wrapper .chatroom-preview-wrapper .chatroom-preview-container .left-message-bubble-wrapper .left-message-bubble .left-message-chat-bubble div {
          border-radius: 4px;
          height: 14px; }
          .preview-broadcast-message-wrapper .chatroom-preview-wrapper .chatroom-preview-container .left-message-bubble-wrapper .left-message-bubble .left-message-chat-bubble div:nth-child(1) {
            background: rgba(25, 25, 25, 0.2);
            width: 80px;
            margin-bottom: 4px; }
          .preview-broadcast-message-wrapper .chatroom-preview-wrapper .chatroom-preview-container .left-message-bubble-wrapper .left-message-bubble .left-message-chat-bubble div:nth-child(2) {
            background-color: rgba(25, 25, 25, 0.1);
            width: 100%; }
  .preview-broadcast-message-wrapper .chatroom-preview-wrapper .right-message-bubble-wrapper {
    width: 100%;
    text-align: right;
    position: relative;
    margin-top: 16px;
    color: #ffffff; }
    .preview-broadcast-message-wrapper .chatroom-preview-wrapper .right-message-bubble-wrapper .right-message-bubble {
      background: #ff7d00;
      border-radius: 12px;
      border-top-right-radius: 0;
      max-width: 80%;
      max-width: 100%;
      padding: 10px;
      position: relative;
      display: inline-block;
      text-align: left; }
      .preview-broadcast-message-wrapper .chatroom-preview-wrapper .right-message-bubble-wrapper .right-message-bubble.file-bubble .file-wrapper {
        width: 48px;
        height: 48px;
        background: #ffffff;
        border-radius: 50%;
        display: inline-block;
        color: #ff7d00;
        position: relative; }
        .preview-broadcast-message-wrapper .chatroom-preview-wrapper .right-message-bubble-wrapper .right-message-bubble.file-bubble .file-wrapper svg {
          position: absolute;
          top: 50%;
          left: 50%;
          -webkit-transform: translate(-50%, -50%);
                  transform: translate(-50%, -50%);
          font-size: 20px; }
      .preview-broadcast-message-wrapper .chatroom-preview-wrapper .right-message-bubble-wrapper .right-message-bubble.file-bubble .file-info-wrapper {
        width: calc(100% - 48px);
        display: inline-block;
        padding-left: 8px;
        position: relative;
        top: 3px;
        vertical-align: top; }
      .preview-broadcast-message-wrapper .chatroom-preview-wrapper .right-message-bubble-wrapper .right-message-bubble.file-bubble .filename-text {
        font-size: 14px;
        letter-spacing: -0.4px;
        margin: 0 0 2px 0;
        max-width: 100%;
        white-space: nowrap;
        overflow-x: hidden;
        text-overflow: ellipsis; }
      .preview-broadcast-message-wrapper .chatroom-preview-wrapper .right-message-bubble-wrapper .right-message-bubble.file-bubble .filezise-filetype {
        font-size: 14px;
        letter-spacing: -0.2px;
        margin: 0;
        text-transform: uppercase;
        max-width: 100%;
        white-space: nowrap;
        overflow-x: hidden;
        text-overflow: ellipsis; }
      .preview-broadcast-message-wrapper .chatroom-preview-wrapper .right-message-bubble-wrapper .right-message-bubble.bubble-media {
        max-width: 363px; }
      .preview-broadcast-message-wrapper .chatroom-preview-wrapper .right-message-bubble-wrapper .right-message-bubble .preview-document-wrapper .download-wrapper {
        background: #ffffff;
        position: relative;
        width: 48px;
        height: 48px;
        border-radius: 50%;
        display: inline-block;
        vertical-align: top; }
        .preview-broadcast-message-wrapper .chatroom-preview-wrapper .right-message-bubble-wrapper .right-message-bubble .preview-document-wrapper .download-wrapper svg {
          color: #ff7d00;
          position: absolute;
          top: 50%;
          left: 50%;
          -webkit-transform: translate(-50%, -50%);
                  transform: translate(-50%, -50%);
          font-size: 26px; }
      .preview-broadcast-message-wrapper .chatroom-preview-wrapper .right-message-bubble-wrapper .right-message-bubble .preview-document-wrapper .file-info-wrapper {
        width: calc(100% - 48px);
        display: inline-block;
        padding-left: 8px;
        padding-top: 4px; }
        .preview-broadcast-message-wrapper .chatroom-preview-wrapper .right-message-bubble-wrapper .right-message-bubble .preview-document-wrapper .file-info-wrapper.preview-without-file {
          padding-top: 15px; }
        .preview-broadcast-message-wrapper .chatroom-preview-wrapper .right-message-bubble-wrapper .right-message-bubble .preview-document-wrapper .file-info-wrapper .file-name {
          margin: 0;
          font-size: 14px;
          letter-spacing: -0.4px;
          white-space: nowrap;
          max-width: 100%;
          text-overflow: ellipsis;
          overflow: hidden; }
        .preview-broadcast-message-wrapper .chatroom-preview-wrapper .right-message-bubble-wrapper .right-message-bubble .preview-document-wrapper .file-info-wrapper .file-size {
          margin: 0;
          font-size: 14px;
          letter-spacing: -0.2px;
          white-space: nowrap;
          max-width: 100%;
          text-overflow: ellipsis;
          overflow: hidden;
          text-transform: uppercase; }
      .preview-broadcast-message-wrapper .chatroom-preview-wrapper .right-message-bubble-wrapper .right-message-bubble .preview-image-wrapper {
        width: 343px;
        background: #8F4700;
        position: relative;
        border-radius: 12px;
        display: inline-block;
        margin-bottom: 2px;
        overflow: hidden;
        font-size: 0; }
        .preview-broadcast-message-wrapper .chatroom-preview-wrapper .right-message-bubble-wrapper .right-message-bubble .preview-image-wrapper.no-background {
          background: transparent; }
        .preview-broadcast-message-wrapper .chatroom-preview-wrapper .right-message-bubble-wrapper .right-message-bubble .preview-image-wrapper img {
          width: 100%; }
      .preview-broadcast-message-wrapper .chatroom-preview-wrapper .right-message-bubble-wrapper .right-message-bubble .preview-video-wrapper {
        width: 343px;
        background: #8F4700;
        position: relative;
        border-radius: 12px;
        display: inline-block;
        margin-bottom: 2px;
        font-size: 0;
        overflow: hidden; }
        .preview-broadcast-message-wrapper .chatroom-preview-wrapper .right-message-bubble-wrapper .right-message-bubble .preview-video-wrapper.no-background {
          background: transparent; }
        .preview-broadcast-message-wrapper .chatroom-preview-wrapper .right-message-bubble-wrapper .right-message-bubble .preview-video-wrapper video {
          width: 100%; }
        .preview-broadcast-message-wrapper .chatroom-preview-wrapper .right-message-bubble-wrapper .right-message-bubble .preview-video-wrapper .play-wrapper {
          border-radius: 50%;
          width: 48px;
          height: 48px;
          position: absolute;
          top: 50%;
          left: 50%;
          -webkit-transform: translate(-50%, -50%);
                  transform: translate(-50%, -50%);
          background: rgba(25, 25, 25, 0.4); }
          .preview-broadcast-message-wrapper .chatroom-preview-wrapper .right-message-bubble-wrapper .right-message-bubble .preview-video-wrapper .play-wrapper svg {
            position: absolute;
            top: 50%;
            left: 50%;
            -webkit-transform: translate(-50%, -50%);
                    transform: translate(-50%, -50%);
            color: #ffffff;
            font-size: 26px; }
      .preview-broadcast-message-wrapper .chatroom-preview-wrapper .right-message-bubble-wrapper .right-message-bubble .preview-placeholder {
        width: 100%;
        margin: 100px 0;
        text-align: center;
        font-size: 14px;
        letter-spacing: -0.4px; }
        .preview-broadcast-message-wrapper .chatroom-preview-wrapper .right-message-bubble-wrapper .right-message-bubble .preview-placeholder svg {
          font-size: 26px;
          margin-bottom: 4px; }
      .preview-broadcast-message-wrapper .chatroom-preview-wrapper .right-message-bubble-wrapper .right-message-bubble .message-body {
        font-size: 16px;
        line-height: 24px;
        letter-spacing: -0.3px;
        margin: 0; }
      .preview-broadcast-message-wrapper .chatroom-preview-wrapper .right-message-bubble-wrapper .right-message-bubble .message-info {
        margin: 0;
        font-size: 10px;
        letter-spacing: -0.4px;
        line-height: 18px;
        width: 100%;
        text-align: right; }
        .preview-broadcast-message-wrapper .chatroom-preview-wrapper .right-message-bubble-wrapper .right-message-bubble .message-info .centered-dot {
          width: 2px;
          height: 2px;
          display: inline-block;
          vertical-align: top;
          background: #ffffff;
          border-radius: 50%;
          position: relative;
          top: 9px; }
        .preview-broadcast-message-wrapper .chatroom-preview-wrapper .right-message-bubble-wrapper .right-message-bubble .message-info img {
          margin-left: 2px; }
      .preview-broadcast-message-wrapper .chatroom-preview-wrapper .right-message-bubble-wrapper .right-message-bubble.image-bubble {
        max-width: 300px;
        padding: 10px; }
        .preview-broadcast-message-wrapper .chatroom-preview-wrapper .right-message-bubble-wrapper .right-message-bubble.image-bubble .bubble-image-wrapper {
          position: relative;
          min-width: 150px; }
          .preview-broadcast-message-wrapper .chatroom-preview-wrapper .right-message-bubble-wrapper .right-message-bubble.image-bubble .bubble-image-wrapper .image-content {
            vertical-align: top;
            width: 100%;
            max-width: 100%;
            border-radius: 16px 0 16px 16px;
            margin: 0 0 2px 0; }
        .preview-broadcast-message-wrapper .chatroom-preview-wrapper .right-message-bubble-wrapper .right-message-bubble.image-bubble .message-body {
          padding: 0 6px; }
        .preview-broadcast-message-wrapper .chatroom-preview-wrapper .right-message-bubble-wrapper .right-message-bubble.image-bubble .message-info {
          padding: 0 6px; }
      .preview-broadcast-message-wrapper .chatroom-preview-wrapper .right-message-bubble-wrapper .right-message-bubble.without-caption .image-content {
        border-radius: 16px 0 16px 16px !important; }
      .preview-broadcast-message-wrapper .chatroom-preview-wrapper .right-message-bubble-wrapper .right-message-bubble.without-caption .message-info {
        background: rgba(25, 25, 25, 0.6);
        display: inline-block;
        width: unset;
        padding: 4px 8px;
        border-radius: 12px;
        position: absolute;
        right: 10px;
        bottom: 10px; }
  .preview-broadcast-message-wrapper .chatroom-preview-wrapper .input-text-wrapper {
    height: 56px;
    background: #ffffff;
    width: 100%;
    position: relative;
    padding: 8px 12px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    box-shadow: 0 2px 20px 0 rgba(25, 25, 25, 0.05); }
    .preview-broadcast-message-wrapper .chatroom-preview-wrapper .input-text-wrapper .input-text-container {
      width: calc(100% - 44px);
      display: inline-block;
      border-radius: 108px;
      border: solid 1px rgba(25, 25, 25, 0.1);
      height: 40px;
      position: relative; }
      .preview-broadcast-message-wrapper .chatroom-preview-wrapper .input-text-wrapper .input-text-container div {
        width: 160px;
        height: 16px;
        border-radius: 4px;
        background: rgba(25, 25, 25, 0.05);
        position: absolute;
        top: 50%;
        left: 16px;
        -webkit-transform: translate(0, -50%);
                transform: translate(0, -50%);
        max-width: calc(100% - 40px); }
      .preview-broadcast-message-wrapper .chatroom-preview-wrapper .input-text-wrapper .input-text-container svg {
        color: #a3a3a3;
        font-size: 20px;
        position: absolute;
        right: 16px;
        top: 50%;
        -webkit-transform: translate(0, -50%);
                transform: translate(0, -50%); }
    .preview-broadcast-message-wrapper .chatroom-preview-wrapper .input-text-wrapper .submit-button-airplane {
      position: absolute;
      width: 32px;
      height: 32px;
      background: #a3a3a3;
      top: 50%;
      right: 12px;
      -webkit-transform: translate(0, -50%);
              transform: translate(0, -50%);
      border-radius: 50%; }
      .preview-broadcast-message-wrapper .chatroom-preview-wrapper .input-text-wrapper .submit-button-airplane img {
        width: 22px;
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%); }

.preview-broadcast-message-wrapper .custom-variable-wrapper label {
  width: 200px;
  display: inline-block !important; }

.preview-broadcast-message-wrapper .custom-variable-wrapper .input-param-wrapper {
  width: calc(100% - 200px);
  display: inline-block; }

.preview-broadcast-message-wrapper .custom-variable-wrapper button {
  width: 100%;
  margin: 0;
  height: 44px;
  line-height: 1; }

.preview-broadcast-message-wrapper .submit-send-message {
  position: absolute;
  right: 32px;
  height: 40px;
  line-height: 35px;
  padding: 0;
  top: 0;
  width: 175px; }
  .preview-broadcast-message-wrapper .submit-send-message img {
    position: relative;
    width: 16px;
    margin-right: 4px;
    top: -1px; }

.add-contact-main-wrapper .integration-list-loading-wrapper {
  height: 80vh; }
  .add-contact-main-wrapper .integration-list-loading-wrapper .lds-ring {
    width: 50px;
    height: 50px;
    position: relative;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    right: unset; }
    .add-contact-main-wrapper .integration-list-loading-wrapper .lds-ring div {
      border: 5px solid #fff;
      border-color: #ff7d00 transparent transparent transparent;
      width: 50px;
      height: 50px;
      margin: 0; }

.add-contact-main-wrapper .add-contact-header {
  padding-bottom: 12px;
  margin-bottom: 12px;
  border-bottom: 1px solid rgba(25, 25, 25, 0.1); }
  .add-contact-main-wrapper .add-contact-header .grey-font {
    font-size: 16px;
    letter-spacing: -0.6px; }

.add-contact-main-wrapper .add-contact-search {
  width: 300px;
  border-radius: 8px;
  border: solid 1px rgba(25, 25, 25, 0.1);
  margin-bottom: 24px; }
  .add-contact-main-wrapper .add-contact-search.focus-search {
    border: solid 1px #ff7e00; }
    .add-contact-main-wrapper .add-contact-search.focus-search svg {
      color: #ff7e00; }
  .add-contact-main-wrapper .add-contact-search span {
    position: relative;
    top: -2px; }
  .add-contact-main-wrapper .add-contact-search input {
    border: none;
    background: transparent;
    padding: 8px 4px 8px 16px;
    width: 90%; }

.add-contact-list-table-wrapper .custom-table {
  min-height: 200px; }

.table-user-contact-list {
  table-layout: fixed;
  margin: 24px 0 0; }

.table-user-contact-list thead tr {
  background: transparent; }
  .table-user-contact-list thead tr td {
    padding: 8px;
    width: 180px;
    font-size: 14px;
    word-wrap: break-word;
    white-space: normal; }
    .table-user-contact-list thead tr td:last-child {
      width: 90px; }
      .table-user-contact-list thead tr td:last-child .custom-select-option-value-wrapper {
        width: 55px !important;
        height: 23px !important;
        padding: 2px 8px 2px 12px !important;
        border-radius: 4px !important; }
        .table-user-contact-list thead tr td:last-child .custom-select-option-value-wrapper p {
          font-size: 12px;
          font-weight: bold; }
        .table-user-contact-list thead tr td:last-child .custom-select-option-value-wrapper .custom-select-option-value {
          padding: 0; }
        .table-user-contact-list thead tr td:last-child .custom-select-option-value-wrapper svg {
          right: 3px !important;
          height: 15px;
          top: 11px !important;
          position: absolute !important;
          margin-right: unset !important; }

.table-user-contact-list tbody tr {
  cursor: pointer; }
  .table-user-contact-list tbody tr:hover {
    background-color: #FFF2E5; }
  .table-user-contact-list tbody tr td {
    width: 180px;
    background: transparent;
    position: relative;
    word-wrap: break-word;
    vertical-align: top; }
    .table-user-contact-list tbody tr td.col_topic {
      width: 180px; }
    .table-user-contact-list tbody tr td.col_view {
      color: var(--main-dark-orange); }
    .table-user-contact-list tbody tr td .unavailable {
      color: rgba(25, 25, 25, 0.4); }
    .table-user-contact-list tbody tr td .link-user {
      color: inherit;
      text-decoration: inherit; }
    .table-user-contact-list tbody tr td.col-origin img {
      width: 20px;
      position: relative;
      margin-right: 4px;
      top: -2px; }
    .table-user-contact-list tbody tr td:nth-child(1) p {
      margin-bottom: 4px; }
    .table-user-contact-list tbody tr td:nth-child(1) .col_name_alias {
      font-size: 12px; }
    .table-user-contact-list tbody tr td:nth-child(4).col-origin p {
      margin: 0;
      display: inline-block;
      position: relative;
      padding: 0 0 0 24px; }
    .table-user-contact-list tbody tr td:nth-child(4).col-origin img {
      position: absolute;
      left: 0;
      top: 0;
      margin-right: 8px;
      width: 20px; }
    .table-user-contact-list tbody tr td svg {
      position: relative;
      font-size: 16px;
      top: -2px;
      left: -4px; }

.table-user-contact-list .col-action-button-wrapper {
  position: absolute;
  right: 7px;
  top: 50%;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%);
  /* display: none; */ }

.table-user-contact-list td {
  max-width: unset !important; }

.add-contact-modal .modal-content {
  width: 740px; }

.add-contact-modal .modal-body {
  padding: 32px; }
  .add-contact-modal .modal-body .add-contact-wrapper {
    position: relative; }
    .add-contact-modal .modal-body .add-contact-wrapper .add-contact-title {
      font-size: 20px;
      letter-spacing: -0.6px;
      color: #191919;
      line-height: 1;
      margin: 6px 0 0; }
    .add-contact-modal .modal-body .add-contact-wrapper .add-contact-description {
      font-size: 16px;
      letter-spacing: -0.3px;
      color: rgba(25, 25, 25, 0.8);
      line-height: 1;
      margin: 6px 0 0; }
    .add-contact-modal .modal-body .add-contact-wrapper svg {
      position: absolute;
      right: 0;
      top: 0;
      font-size: 24px;
      cursor: pointer; }
      .add-contact-modal .modal-body .add-contact-wrapper svg:hover {
        color: #ff7d00; }
  .add-contact-modal .modal-body .add-contact-option-container {
    width: 100%;
    margin-top: 20px;
    text-align: center;
    display: flex; }
    .add-contact-modal .modal-body .add-contact-option-container .add-contact-new-user {
      width: 50%;
      border-right: solid 1px rgba(25, 25, 25, 0.1);
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 24px 24px 32px; }
      .add-contact-modal .modal-body .add-contact-option-container .add-contact-new-user img {
        margin-bottom: 12px; }
      .add-contact-modal .modal-body .add-contact-option-container .add-contact-new-user p {
        margin-bottom: 4px; }
      .add-contact-modal .modal-body .add-contact-option-container .add-contact-new-user .add-description {
        margin-bottom: 12px;
        width: 80%;
        text-align: center;
        font-size: 14px; }
      .add-contact-modal .modal-body .add-contact-option-container .add-contact-new-user button {
        font-family: 'PTRootUI';
        font-size: 12px; }
        .add-contact-modal .modal-body .add-contact-option-container .add-contact-new-user button img {
          margin: 0;
          margin-right: 4px;
          position: relative;
          top: -1px; }
        .add-contact-modal .modal-body .add-contact-option-container .add-contact-new-user button.add-new-contact-btn {
          width: 125px;
          height: 22px;
          border-radius: 4px;
          line-height: 13px; }
    .add-contact-modal .modal-body .add-contact-option-container .add-contact-import-contact {
      width: 50%;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 24px 12px 32px; }
      .add-contact-modal .modal-body .add-contact-option-container .add-contact-import-contact img {
        margin-bottom: 12px; }
      .add-contact-modal .modal-body .add-contact-option-container .add-contact-import-contact p {
        margin-bottom: 4px; }
      .add-contact-modal .modal-body .add-contact-option-container .add-contact-import-contact .add-description {
        margin-bottom: 12px;
        text-align: center;
        font-size: 14px;
        width: 80%; }
      .add-contact-modal .modal-body .add-contact-option-container .add-contact-import-contact .button-import-container {
        display: block;
        width: 125px; }
        .add-contact-modal .modal-body .add-contact-option-container .add-contact-import-contact .button-import-container button {
          width: 100% !important; }
      .add-contact-modal .modal-body .add-contact-option-container .add-contact-import-contact button {
        font-family: 'PTRootUI';
        font-size: 12px; }
        .add-contact-modal .modal-body .add-contact-option-container .add-contact-import-contact button img {
          margin: 0;
          margin-right: 4px; }
        .add-contact-modal .modal-body .add-contact-option-container .add-contact-import-contact button.upload-csv-btn {
          width: 97px;
          height: 22px;
          border-radius: 4px;
          margin-right: 5px;
          line-height: 12px;
          margin-top: 21px; }
          .add-contact-modal .modal-body .add-contact-option-container .add-contact-import-contact button.upload-csv-btn img {
            position: relative;
            top: -1px; }
        .add-contact-modal .modal-body .add-contact-option-container .add-contact-import-contact button.no-border {
          border: none !important;
          margin-right: 12px;
          line-height: 12px;
          position: relative;
          top: -3px; }
          .add-contact-modal .modal-body .add-contact-option-container .add-contact-import-contact button.no-border:hover {
            color: #e87200 !important;
            background: transparent; }
            .add-contact-modal .modal-body .add-contact-option-container .add-contact-import-contact button.no-border:hover svg {
              color: #e87200; }
          .add-contact-modal .modal-body .add-contact-option-container .add-contact-import-contact button.no-border svg {
            position: relative;
            font-size: 12px;
            top: -1px;
            margin-right: 4px;
            -webkit-transition: ease-in-out 0.3s;
            transition: ease-in-out 0.3s; }

.importing-contact-modal .modal-content {
  width: 450px; }

.importing-contact-modal .modal-body {
  padding: 32px; }
  .importing-contact-modal .modal-body .importing-contact-wrapper {
    position: relative; }
    .importing-contact-modal .modal-body .importing-contact-wrapper button {
      width: calc(50% - 6px);
      margin-right: 12px; }
      .importing-contact-modal .modal-body .importing-contact-wrapper button:nth-last-child(1) {
        margin: 0; }
    .importing-contact-modal .modal-body .importing-contact-wrapper .integration-list-loading-wrapper {
      position: relative;
      width: 100%;
      height: 50px; }
      .importing-contact-modal .modal-body .importing-contact-wrapper .integration-list-loading-wrapper .lds-ring {
        width: 50px;
        height: 50px;
        position: relative;
        left: 50%;
        top: 20px;
        -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%); }
        .importing-contact-modal .modal-body .importing-contact-wrapper .integration-list-loading-wrapper .lds-ring div {
          border: 5px solid #fff;
          border-color: #ff7d00 transparent transparent transparent;
          width: 50px;
          height: 50px;
          margin: 0; }
    .importing-contact-modal .modal-body .importing-contact-wrapper .importing-text {
      margin: 12px 0;
      font-size: 14px;
      text-align: center; }
    .importing-contact-modal .modal-body .importing-contact-wrapper .retry-button {
      width: 100%;
      height: 40px; }
      .importing-contact-modal .modal-body .importing-contact-wrapper .retry-button svg {
        margin-right: 4px; }
    .importing-contact-modal .modal-body .importing-contact-wrapper .warning-svg {
      display: flex;
      justify-content: center; }
      .importing-contact-modal .modal-body .importing-contact-wrapper .warning-svg svg {
        color: #FF7E00;
        font-size: 50px; }

.add-user-contact-container {
  padding: 32px 30px;
  text-align: left; }
  .add-user-contact-container .integration-detail-loading-wrapper .lds-ring {
    width: 50px;
    height: 50px;
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    right: unset; }
    .add-user-contact-container .integration-detail-loading-wrapper .lds-ring div {
      border: 5px solid #fff;
      border-color: #ff7d00 transparent transparent transparent;
      width: 50px;
      height: 50px;
      margin: 0; }
  .add-user-contact-container .comp-back-button {
    margin: 0 0 16px 0;
    font-family: PTRootBold; }
  .add-user-contact-container .add-user-contact-box {
    background: #ffffff;
    border-radius: 8px;
    padding: 32px;
    border: solid 1px rgba(25, 25, 25, 0.1); }
    .add-user-contact-container .add-user-contact-box .button-right {
      text-align: center;
      position: absolute;
      top: 108px;
      right: 32px;
      width: 188px;
      padding: 0 8px !important; }
    .add-user-contact-container .add-user-contact-box .add-user-contact-box-top {
      padding-bottom: 15px;
      border-bottom: solid 1px #1919191A;
      position: relative;
      font-size: 20px;
      letter-spacing: -0.6px; }
      .add-user-contact-container .add-user-contact-box .add-user-contact-box-top .title-wrapper {
        display: flex;
        align-items: center;
        margin-bottom: 8px; }
        .add-user-contact-container .add-user-contact-box .add-user-contact-box-top .title-wrapper svg {
          margin-right: 4px; }
      .add-user-contact-container .add-user-contact-box .add-user-contact-box-top p {
        font-size: 16px;
        margin: 0;
        color: rgba(25, 25, 25, 0.8); }
    .add-user-contact-container .add-user-contact-box .add-user-contact-box-form {
      width: 100%; }
      .add-user-contact-container .add-user-contact-box .add-user-contact-box-form label {
        padding: 24px 0 0;
        font-size: 14px;
        letter-spacing: -0.4px;
        display: block; }
        .add-user-contact-container .add-user-contact-box .add-user-contact-box-form label b {
          font-size: 16px;
          line-height: 24px;
          letter-spacing: -0.5px; }
          .add-user-contact-container .add-user-contact-box .add-user-contact-box-form label b span {
            font-family: PTRootUI, 'sans-serif';
            color: rgba(25, 25, 25, 0.6); }
      .add-user-contact-container .add-user-contact-box .add-user-contact-box-form .alert-email-phone {
        background-color: #FFE5E9;
        box-shadow: 0px 2px 20px rgba(25, 25, 25, 0.18);
        border: 1px solid #FF3F57;
        margin-top: 24px;
        color: #FF3F57; }
      .add-user-contact-container .add-user-contact-box .add-user-contact-box-form .warning-error-red {
        display: flex;
        margin-top: 4px;
        color: #ff3f57; }
        .add-user-contact-container .add-user-contact-box .add-user-contact-box-form .warning-error-red svg {
          margin-right: 4px;
          font-size: 12.7px;
          position: relative;
          top: 2.5px; }
        .add-user-contact-container .add-user-contact-box .add-user-contact-box-form .warning-error-red b {
          font-size: 12px; }
      .add-user-contact-container .add-user-contact-box .add-user-contact-box-form .form-contact-fullname,
      .add-user-contact-container .add-user-contact-box .add-user-contact-box-form .form-contact-alias,
      .add-user-contact-container .add-user-contact-box .add-user-contact-box-form .form-contact-email,
      .add-user-contact-container .add-user-contact-box .add-user-contact-box-form .form-contact-custom-field {
        width: 400px !important; }
        .add-user-contact-container .add-user-contact-box .add-user-contact-box-form .form-contact-fullname input[type='text'],
        .add-user-contact-container .add-user-contact-box .add-user-contact-box-form .form-contact-alias input[type='text'],
        .add-user-contact-container .add-user-contact-box .add-user-contact-box-form .form-contact-email input[type='text'],
        .add-user-contact-container .add-user-contact-box .add-user-contact-box-form .form-contact-custom-field input[type='text'] {
          padding: 12px 16px;
          border-radius: 6px;
          border: solid 1px #1919191A;
          width: 100%;
          font-size: 16px;
          letter-spacing: -0.3px;
          display: block;
          caret-color: #FF7E00; }
        .add-user-contact-container .add-user-contact-box .add-user-contact-box-form .form-contact-fullname input[type='text']:focus,
        .add-user-contact-container .add-user-contact-box .add-user-contact-box-form .form-contact-alias input[type='text']:focus,
        .add-user-contact-container .add-user-contact-box .add-user-contact-box-form .form-contact-email input[type='text']:focus,
        .add-user-contact-container .add-user-contact-box .add-user-contact-box-form .form-contact-custom-field input[type='text']:focus {
          border: solid 1px #ff7e00; }
        .add-user-contact-container .add-user-contact-box .add-user-contact-box-form .form-contact-fullname input[type='text']:disabled,
        .add-user-contact-container .add-user-contact-box .add-user-contact-box-form .form-contact-alias input[type='text']:disabled,
        .add-user-contact-container .add-user-contact-box .add-user-contact-box-form .form-contact-email input[type='text']:disabled,
        .add-user-contact-container .add-user-contact-box .add-user-contact-box-form .form-contact-custom-field input[type='text']:disabled {
          background-color: rgba(25, 25, 25, 0.1) !important;
          color: rgba(25, 25, 25, 0.4) !important; }
        .add-user-contact-container .add-user-contact-box .add-user-contact-box-form .form-contact-fullname textarea,
        .add-user-contact-container .add-user-contact-box .add-user-contact-box-form .form-contact-alias textarea,
        .add-user-contact-container .add-user-contact-box .add-user-contact-box-form .form-contact-email textarea,
        .add-user-contact-container .add-user-contact-box .add-user-contact-box-form .form-contact-custom-field textarea {
          display: block;
          padding: 12px 16px;
          margin: 0;
          width: 100%;
          max-width: 400px;
          height: 96px;
          border-radius: 8px;
          font-size: 16px;
          letter-spacing: -0.3px;
          border: solid 1px #1919191A;
          resize: none;
          line-height: 24px;
          caret-color: #FF7E00; }
        .add-user-contact-container .add-user-contact-box .add-user-contact-box-form .form-contact-fullname textarea:focus,
        .add-user-contact-container .add-user-contact-box .add-user-contact-box-form .form-contact-alias textarea:focus,
        .add-user-contact-container .add-user-contact-box .add-user-contact-box-form .form-contact-email textarea:focus,
        .add-user-contact-container .add-user-contact-box .add-user-contact-box-form .form-contact-custom-field textarea:focus {
          border: solid 1px #ff7e00; }
        .add-user-contact-container .add-user-contact-box .add-user-contact-box-form .form-contact-fullname textarea::-webkit-scrollbar,
        .add-user-contact-container .add-user-contact-box .add-user-contact-box-form .form-contact-alias textarea::-webkit-scrollbar,
        .add-user-contact-container .add-user-contact-box .add-user-contact-box-form .form-contact-email textarea::-webkit-scrollbar,
        .add-user-contact-container .add-user-contact-box .add-user-contact-box-form .form-contact-custom-field textarea::-webkit-scrollbar {
          width: 12px; }
        .add-user-contact-container .add-user-contact-box .add-user-contact-box-form .form-contact-fullname textarea::-webkit-scrollbar-thumb,
        .add-user-contact-container .add-user-contact-box .add-user-contact-box-form .form-contact-alias textarea::-webkit-scrollbar-thumb,
        .add-user-contact-container .add-user-contact-box .add-user-contact-box-form .form-contact-email textarea::-webkit-scrollbar-thumb,
        .add-user-contact-container .add-user-contact-box .add-user-contact-box-form .form-contact-custom-field textarea::-webkit-scrollbar-thumb {
          border: 4px solid #00000000;
          border-radius: 8px;
          background-color: #1919194D;
          background-clip: padding-box; }
      .add-user-contact-container .add-user-contact-box .add-user-contact-box-form .form-contact-email input[type='text'].error-email:focus {
        border: 1px solid #FF3F57; }
      .add-user-contact-container .add-user-contact-box .add-user-contact-box-form .form-contact-phonenumber {
        width: 400px !important; }
        .add-user-contact-container .add-user-contact-box .add-user-contact-box-form .form-contact-phonenumber .input-phonenumber-container {
          padding: 0 16px;
          border-radius: 6px;
          border: solid 1px #1919191A;
          width: 100%;
          font-size: 16px;
          letter-spacing: -0.3px;
          display: flex;
          align-items: center;
          caret-color: #FF7E00; }
          .add-user-contact-container .add-user-contact-box .add-user-contact-box-form .form-contact-phonenumber .input-phonenumber-container input {
            width: 100%; }
          .add-user-contact-container .add-user-contact-box .add-user-contact-box-form .form-contact-phonenumber .input-phonenumber-container input:disabled {
            background: transparent; }
          .add-user-contact-container .add-user-contact-box .add-user-contact-box-form .form-contact-phonenumber .input-phonenumber-container.error-phone {
            border: 1px solid #FF3F57; }
          .add-user-contact-container .add-user-contact-box .add-user-contact-box-form .form-contact-phonenumber .input-phonenumber-container.phone-focus {
            border: solid 1px #ff7e00; }
          .add-user-contact-container .add-user-contact-box .add-user-contact-box-form .form-contact-phonenumber .input-phonenumber-container.phone-disabled {
            background-color: rgba(25, 25, 25, 0.1);
            color: rgba(25, 25, 25, 0.4); }
          .add-user-contact-container .add-user-contact-box .add-user-contact-box-form .form-contact-phonenumber .input-phonenumber-container label {
            padding: 0;
            margin: 0 4px 0 0;
            font-size: 16px;
            position: relative;
            top: 0px; }
          .add-user-contact-container .add-user-contact-box .add-user-contact-box-form .form-contact-phonenumber .input-phonenumber-container .form-phonenumber {
            padding: 0;
            height: 50px;
            border: none; }
          .add-user-contact-container .add-user-contact-box .add-user-contact-box-form .form-contact-phonenumber .input-phonenumber-container .form-phonenumber:disabled {
            color: rgba(25, 25, 25, 0.4) !important; }
        .add-user-contact-container .add-user-contact-box .add-user-contact-box-form .form-contact-phonenumber .alert-wrapper {
          display: flex;
          margin-top: 4px; }
          .add-user-contact-container .add-user-contact-box .add-user-contact-box-form .form-contact-phonenumber .alert-wrapper svg {
            margin-right: 4px;
            font-size: 20px;
            position: relative;
            top: -2px; }
          .add-user-contact-container .add-user-contact-box .add-user-contact-box-form .form-contact-phonenumber .alert-wrapper p {
            font-size: 12px; }
      .add-user-contact-container .add-user-contact-box .add-user-contact-box-form .absolute-right-0 {
        bottom: 1px; }
      .add-user-contact-container .add-user-contact-box .add-user-contact-box-form button {
        margin: 20px 0 0; }
        .add-user-contact-container .add-user-contact-box .add-user-contact-box-form button.add-contact-button {
          height: 40px;
          margin-top: 24px; }
        .add-user-contact-container .add-user-contact-box .add-user-contact-box-form button.add-contact-button:disabled {
          background-color: rgba(25, 25, 25, 0.1) !important;
          color: rgba(25, 25, 25, 0.4) !important; }
        .add-user-contact-container .add-user-contact-box .add-user-contact-box-form button.loading-button {
          width: 181px;
          height: 40px;
          display: flex;
          align-items: center;
          justify-content: center; }
        .add-user-contact-container .add-user-contact-box .add-user-contact-box-form button.loading-button:disabled {
          background-color: rgba(25, 25, 25, 0.1) !important;
          color: rgba(25, 25, 25, 0.4) !important; }
      .add-user-contact-container .add-user-contact-box .add-user-contact-box-form .contact-custom-field-separator {
        margin-top: 24px;
        width: 100%;
        height: 1px;
        background-color: #1919191A; }
      .add-user-contact-container .add-user-contact-box .add-user-contact-box-form .dropdown {
        max-width: 400px; }
        .add-user-contact-container .add-user-contact-box .add-user-contact-box-form .dropdown button {
          margin-top: 0px; }
        .add-user-contact-container .add-user-contact-box .add-user-contact-box-form .dropdown .dropdown-option-wrapper {
          max-height: none;
          border: none; }
      .add-user-contact-container .add-user-contact-box .add-user-contact-box-form .contact-date-value-wrapper {
        max-width: 400px;
        border-radius: 8px;
        height: 48px;
        width: 100%;
        position: relative;
        padding: 0 48px 0 16px;
        border: solid 1px #1919191A;
        cursor: pointer;
        line-height: 46px; }
        .add-user-contact-container .add-user-contact-box .add-user-contact-box-form .contact-date-value-wrapper:hover {
          border-color: #ff7e00; }
        .add-user-contact-container .add-user-contact-box .add-user-contact-box-form .contact-date-value-wrapper svg {
          position: absolute;
          right: 16px;
          top: 50%;
          -webkit-transform: translate(0, -50%);
                  transform: translate(0, -50%); }
      .add-user-contact-container .add-user-contact-box .add-user-contact-box-form .contact-date-value-wrapper.disabled {
        background: #1919191A;
        border: none;
        box-shadow: none;
        cursor: not-allowed; }

.detail-contact-container {
  padding: 32px 30px;
  text-align: left; }
  .detail-contact-container .comp-back-button {
    margin: 0 0 16px 0;
    font-family: PTRootBold; }
  .detail-contact-container .detail-contact-box {
    background: #ffffff;
    border-radius: 8px;
    padding: 32px;
    border: solid 1px rgba(25, 25, 25, 0.1); }
    .detail-contact-container .detail-contact-box .lds-ring {
      margin-top: 15px;
      width: 38px;
      height: 38px; }
      .detail-contact-container .detail-contact-box .lds-ring div {
        width: 38px;
        height: 38px;
        border: solid 3px #ff7e00;
        border-color: #ff7e00 transparent transparent transparent; }
    .detail-contact-container .detail-contact-box .col-channel {
      position: relative;
      padding: 8px 8px 8px 28px; }
      .detail-contact-container .detail-contact-box .col-channel img {
        position: absolute;
        left: 0;
        margin-right: 4px;
        top: 6px;
        width: 24px;
        height: 24px; }
    .detail-contact-container .detail-contact-box .status-badge {
      border-radius: 4px;
      padding: 3px 9px;
      font-size: 12px;
      letter-spacing: -0.4px;
      text-align: left;
      display: inline-block; }
      .detail-contact-container .detail-contact-box .status-badge svg {
        position: relative;
        margin-right: 4px;
        top: -1px;
        font-size: 13px;
        right: unset; }
      .detail-contact-container .detail-contact-box .status-badge.badge-open {
        color: #fff;
        background: #19c700;
        width: 63px; }
        .detail-contact-container .detail-contact-box .status-badge.badge-open svg {
          color: #fff; }
      .detail-contact-container .detail-contact-box .status-badge.badge-resolved {
        color: rgba(25, 25, 25, 0.6);
        background: rgba(25, 25, 25, 0.05);
        width: 81px; }
        .detail-contact-container .detail-contact-box .status-badge.badge-resolved svg {
          color: rgba(25, 25, 25, 0.6); }
    .detail-contact-container .detail-contact-box .button-new-message {
      padding: 0 12px 0 12px !important; }
    .detail-contact-container .detail-contact-box.detail-contact-box-bottom {
      margin-top: 12px; }
    .detail-contact-container .detail-contact-box.detail-box-contact-segment {
      position: relative; }
    .detail-contact-container .detail-contact-box .table-detail-contact thead tr {
      background: #ffffff; }
      .detail-contact-container .detail-contact-box .table-detail-contact thead tr td:nth-child(1) {
        width: 120px; }
      .detail-contact-container .detail-contact-box .table-detail-contact thead tr td:nth-child(2), .detail-contact-container .detail-contact-box .table-detail-contact thead tr td:nth-child(3) {
        width: 180px; }
      .detail-contact-container .detail-contact-box .table-detail-contact thead tr td:nth-child(4), .detail-contact-container .detail-contact-box .table-detail-contact thead tr td:nth-child(5) {
        width: 130px; }
      .detail-contact-container .detail-contact-box .table-detail-contact thead tr td:last-child {
        width: 90px; }
        .detail-contact-container .detail-contact-box .table-detail-contact thead tr td:last-child .custom-select-option-value-wrapper {
          width: 55px !important;
          height: 23px;
          padding: 2px 8px 2px 12px !important;
          border-radius: 4px !important; }
          .detail-contact-container .detail-contact-box .table-detail-contact thead tr td:last-child .custom-select-option-value-wrapper p {
            font-size: 12px;
            font-weight: bold; }
          .detail-contact-container .detail-contact-box .table-detail-contact thead tr td:last-child .custom-select-option-value-wrapper .custom-select-option-value {
            padding: 0; }
          .detail-contact-container .detail-contact-box .table-detail-contact thead tr td:last-child .custom-select-option-value-wrapper svg {
            right: 3px !important;
            height: 15px;
            top: 11px !important;
            position: absolute !important;
            margin-right: unset !important; }
    .detail-contact-container .detail-contact-box .table-detail-contact tbody td {
      position: relative; }
      .detail-contact-container .detail-contact-box .table-detail-contact tbody td.col_status P {
        font-size: 12px !important; }
      .detail-contact-container .detail-contact-box .table-detail-contact tbody td.col_responded b {
        font-size: 12px !important;
        position: relative;
        top: 2px; }
      .detail-contact-container .detail-contact-box .table-detail-contact tbody td:nth-child(1) div {
        display: flex; }
        .detail-contact-container .detail-contact-box .table-detail-contact tbody td:nth-child(1) div p {
          font-size: 12px !important;
          letter-spacing: -0.4px; }
        .detail-contact-container .detail-contact-box .table-detail-contact tbody td:nth-child(1) div img {
          margin-right: 4px;
          margin-top: 2px; }
      .detail-contact-container .detail-contact-box .table-detail-contact tbody td:nth-child(1) {
        width: 120px; }
      .detail-contact-container .detail-contact-box .table-detail-contact tbody td:nth-child(2), .detail-contact-container .detail-contact-box .table-detail-contact tbody td:nth-child(3) {
        width: 180px; }
      .detail-contact-container .detail-contact-box .table-detail-contact tbody td:nth-child(4), .detail-contact-container .detail-contact-box .table-detail-contact tbody td:nth-child(5) {
        width: 130px; }
      .detail-contact-container .detail-contact-box .table-detail-contact tbody td:last-child {
        color: #ff7e00;
        cursor: pointer;
        font-weight: 700;
        white-space: nowrap; }
        .detail-contact-container .detail-contact-box .table-detail-contact tbody td:last-child a {
          color: #ff7e00; }
        .detail-contact-container .detail-contact-box .table-detail-contact tbody td:last-child svg {
          position: relative;
          color: #ff7e00;
          font-size: 16px;
          margin-right: 4px;
          right: 0;
          top: -1px; }
      .detail-contact-container .detail-contact-box .table-detail-contact tbody td .medium-logo {
        width: 16px;
        height: 16px; }
    .detail-contact-container .detail-contact-box .box-contact-segment-list .no-contact-segment {
      padding: 24px 0 0;
      width: 100%;
      display: block;
      position: relative;
      text-align: center;
      font-size: 16px;
      letter-spacing: -0.3px;
      color: rgba(25, 25, 25, 0.4);
      margin: 0; }
    .detail-contact-container .detail-contact-box .detail-contact-box-top {
      padding-bottom: 15px;
      border-bottom: solid 1px #dcdcdc;
      position: relative;
      font-size: 20px;
      letter-spacing: -0.6px;
      width: 100%;
      display: block;
      justify-content: space-between; }
      .detail-contact-container .detail-contact-box .detail-contact-box-top .button-wrapper button {
        font-size: 16px; }
      .detail-contact-container .detail-contact-box .detail-contact-box-top .title-wrapper {
        display: inline-block;
        align-items: center;
        margin-bottom: 8px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        width: calc(100% - 368px);
        vertical-align: top; }
        .detail-contact-container .detail-contact-box .detail-contact-box-top .title-wrapper .edit-ctc-segment {
          width: 180px;
          position: absolute;
          top: 0;
          right: 0; }
        .detail-contact-container .detail-contact-box .detail-contact-box-top .title-wrapper .title-text {
          position: relative;
          top: 2px; }
        .detail-contact-container .detail-contact-box .detail-contact-box-top .title-wrapper .title-icon {
          margin-right: 4px;
          font-size: 24px; }
        .detail-contact-container .detail-contact-box .detail-contact-box-top .title-wrapper img {
          position: relative;
          margin-right: 4px;
          top: 0; }
      .detail-contact-container .detail-contact-box .detail-contact-box-top .button-wrapper {
        vertical-align: top;
        display: inline-block;
        width: 368px;
        text-align: right; }
        .detail-contact-container .detail-contact-box .detail-contact-box-top .button-wrapper button {
          margin-left: 4px;
          min-width: 180px; }
    .detail-contact-container .detail-contact-box .detail-contact-box-form {
      width: 100%; }
      .detail-contact-container .detail-contact-box .detail-contact-box-form:first-child {
        margin-top: 24px; }
      .detail-contact-container .detail-contact-box .detail-contact-box-form .contact-attribute-wrapper {
        width: 300px;
        margin-bottom: 24px; }
        .detail-contact-container .detail-contact-box .detail-contact-box-form .contact-attribute-wrapper .ass-topic-wrapper {
          margin: 4px 0 0; }
          .detail-contact-container .detail-contact-box .detail-contact-box-form .contact-attribute-wrapper .ass-topic-wrapper .topic-list-content {
            margin: 8px 4px 0 0; }
        .detail-contact-container .detail-contact-box .detail-contact-box-form .contact-attribute-wrapper:first-child {
          margin-top: 24px; }
        .detail-contact-container .detail-contact-box .detail-contact-box-form .contact-attribute-wrapper:nth-last-child(1) {
          margin-bottom: 0 !important; }
        .detail-contact-container .detail-contact-box .detail-contact-box-form .contact-attribute-wrapper .contact-title-wrapper {
          display: flex;
          align-items: center;
          margin-bottom: 4px; }
          .detail-contact-container .detail-contact-box .detail-contact-box-form .contact-attribute-wrapper .contact-title-wrapper img, .detail-contact-container .detail-contact-box .detail-contact-box-form .contact-attribute-wrapper .contact-title-wrapper svg {
            margin-right: 4px; }
        .detail-contact-container .detail-contact-box .detail-contact-box-form .contact-attribute-wrapper p {
          white-space: pre-wrap;
          word-break: break-word; }
      .detail-contact-container .detail-contact-box .detail-contact-box-form .contact-origin {
        margin: 0; }
        .detail-contact-container .detail-contact-box .detail-contact-box-form .contact-origin img {
          margin-right: 8px;
          position: relative;
          top: -2px;
          width: 20px; }
      .detail-contact-container .detail-contact-box .detail-contact-box-form label {
        padding: 24px 0 0;
        font-size: 14px;
        letter-spacing: -0.4px;
        display: block; }
        .detail-contact-container .detail-contact-box .detail-contact-box-form label b span {
          font-family: PTRootUI, 'sans-serif';
          color: rgba(25, 25, 25, 0.6); }
      .detail-contact-container .detail-contact-box .detail-contact-box-form .contact-custom-field-separator {
        margin-bottom: 24px;
        width: 100%;
        height: 1px;
        background-color: #1919191A; }
      .detail-contact-container .detail-contact-box .detail-contact-box-form .warning-error-red {
        color: #ff3f57;
        font-size: 12px;
        letter-spacing: -0.4px;
        margin: 4px 0 0; }
        .detail-contact-container .detail-contact-box .detail-contact-box-form .warning-error-red svg {
          font-size: 14px;
          position: relative;
          top: -2px;
          margin-right: 4px;
          color: #ff3f57 !important;
          cursor: unset !important; }
      .detail-contact-container .detail-contact-box .detail-contact-box-form .form-contact-fullname,
      .detail-contact-container .detail-contact-box .detail-contact-box-form .form-contact-alias,
      .detail-contact-container .detail-contact-box .detail-contact-box-form .form-contact-email {
        width: 320px; }
        .detail-contact-container .detail-contact-box .detail-contact-box-form .form-contact-fullname input[type='text'],
        .detail-contact-container .detail-contact-box .detail-contact-box-form .form-contact-alias input[type='text'],
        .detail-contact-container .detail-contact-box .detail-contact-box-form .form-contact-email input[type='text'] {
          padding: 12px 16px;
          border-radius: 6px;
          border: solid 1px #dcdcdc;
          width: 100%;
          font-size: 16px;
          letter-spacing: -0.3px;
          display: block; }
        .detail-contact-container .detail-contact-box .detail-contact-box-form .form-contact-fullname input[type='text']:focus,
        .detail-contact-container .detail-contact-box .detail-contact-box-form .form-contact-alias input[type='text']:focus,
        .detail-contact-container .detail-contact-box .detail-contact-box-form .form-contact-email input[type='text']:focus {
          border: solid 1px #ff7e00; }
        .detail-contact-container .detail-contact-box .detail-contact-box-form .form-contact-fullname input[type='text']:disabled,
        .detail-contact-container .detail-contact-box .detail-contact-box-form .form-contact-alias input[type='text']:disabled,
        .detail-contact-container .detail-contact-box .detail-contact-box-form .form-contact-email input[type='text']:disabled {
          background-color: rgba(25, 25, 25, 0.1) !important;
          color: rgba(25, 25, 25, 0.4) !important; }
      .detail-contact-container .detail-contact-box .detail-contact-box-form .form-contact-phonenumber {
        width: 320px; }
        .detail-contact-container .detail-contact-box .detail-contact-box-form .form-contact-phonenumber .input-phonenumber-container {
          padding: 0 16px;
          border-radius: 6px;
          border: solid 1px #dcdcdc;
          width: 100%;
          font-size: 16px;
          letter-spacing: -0.3px;
          display: flex;
          align-items: center; }
          .detail-contact-container .detail-contact-box .detail-contact-box-form .form-contact-phonenumber .input-phonenumber-container.phone-focus {
            border: solid 1px #ff7e00; }
          .detail-contact-container .detail-contact-box .detail-contact-box-form .form-contact-phonenumber .input-phonenumber-container.phone-disabled {
            background-color: rgba(25, 25, 25, 0.1);
            color: rgba(25, 25, 25, 0.4); }
          .detail-contact-container .detail-contact-box .detail-contact-box-form .form-contact-phonenumber .input-phonenumber-container label {
            padding: 0;
            margin: 0 4px 0 0;
            font-size: 16px; }
          .detail-contact-container .detail-contact-box .detail-contact-box-form .form-contact-phonenumber .input-phonenumber-container .form-phonenumber {
            padding: 0;
            height: 50px;
            border: none; }
          .detail-contact-container .detail-contact-box .detail-contact-box-form .form-contact-phonenumber .input-phonenumber-container .form-phonenumber:disabled {
            color: rgba(25, 25, 25, 0.4) !important; }
        .detail-contact-container .detail-contact-box .detail-contact-box-form .form-contact-phonenumber .alert-wrapper {
          display: flex;
          margin-top: 4px; }
          .detail-contact-container .detail-contact-box .detail-contact-box-form .form-contact-phonenumber .alert-wrapper svg {
            margin-right: 4px;
            font-size: 20px; }
          .detail-contact-container .detail-contact-box .detail-contact-box-form .form-contact-phonenumber .alert-wrapper p {
            font-size: 12px; }
      .detail-contact-container .detail-contact-box .detail-contact-box-form button.add-contact-button {
        width: 132px;
        height: 40px;
        margin-top: 8px; }
      .detail-contact-container .detail-contact-box .detail-contact-box-form button.add-contact-button:disabled {
        background-color: rgba(25, 25, 25, 0.1) !important;
        color: rgba(25, 25, 25, 0.4) !important; }
      .detail-contact-container .detail-contact-box .detail-contact-box-form button.loading-button {
        width: 181px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center; }
      .detail-contact-container .detail-contact-box .detail-contact-box-form button.loading-button:disabled {
        background-color: rgba(25, 25, 25, 0.1) !important;
        color: rgba(25, 25, 25, 0.4) !important; }

.modal-select-segment-contact-detail .modal-content {
  width: 840px;
  border-radius: 8px; }
  .modal-select-segment-contact-detail .modal-content .modal-body {
    padding: 32px; }
    .modal-select-segment-contact-detail .modal-content .modal-body .modal-select-segment-contact-detail-title {
      margin: 0 0 22px 0;
      font-size: 20px;
      letter-spacing: -0.6px; }
      .modal-select-segment-contact-detail .modal-content .modal-body .modal-select-segment-contact-detail-title img {
        position: relative;
        margin-right: 4px;
        top: -2px; }
    .modal-select-segment-contact-detail .modal-content .modal-body .modal-select-segment-contact-detail-button-wrap {
      width: 100%;
      display: block;
      text-align: right;
      padding: 20px 0 0; }
      .modal-select-segment-contact-detail .modal-content .modal-body .modal-select-segment-contact-detail-button-wrap .button-save {
        margin-left: 8px; }

.modal-choose-channel .modal-content {
  text-align: left;
  width: 534px;
  border-radius: 8px; }
  .modal-choose-channel .modal-content .modal-body {
    padding: 32px; }
    .modal-choose-channel .modal-content .modal-body svg,
    .modal-choose-channel .modal-content .modal-body img {
      margin-right: 5px;
      position: relative;
      top: -2px; }
    .modal-choose-channel .modal-content .modal-body b {
      font-weight: 700 !important; }
    .modal-choose-channel .modal-content .modal-body button {
      height: 40px;
      padding: 5px 20px; }
    .modal-choose-channel .modal-content .modal-body #modal-choose-channel-title {
      font-size: 20px;
      position: relative; }
      .modal-choose-channel .modal-content .modal-body #modal-choose-channel-title .cancel-new-message {
        cursor: pointer;
        top: 4px;
        margin: 0;
        right: 0;
        font-size: 22px;
        position: absolute; }
    .modal-choose-channel .modal-content .modal-body > :nth-child(2) {
      margin-top: 15px; }
    .modal-choose-channel .modal-content .modal-body .modal-choose-channel-options .modal-choose-select {
      border: solid 1px #f5f5f5;
      border-radius: 8px;
      width: -webkit-fit-content;
      width: -moz-fit-content;
      width: fit-content;
      padding: 12px 24px;
      margin: 5px 4px 0 0;
      cursor: pointer;
      text-align: center;
      display: inline-block;
      width: calc(50% - 2px);
      vertical-align: top;
      height: 80px; }
      .modal-choose-channel .modal-content .modal-body .modal-choose-channel-options .modal-choose-select:nth-child(2) {
        margin: 5px 0 0 0; }
      .modal-choose-channel .modal-content .modal-body .modal-choose-channel-options .modal-choose-select b {
        color: rgba(25, 25, 25, 0.6);
        display: block; }
      .modal-choose-channel .modal-content .modal-body .modal-choose-channel-options .modal-choose-select img {
        width: 32px;
        height: 32px;
        vertical-align: -3px; }
    .modal-choose-channel .modal-content .modal-body .modal-choose-channel-options div:hover {
      background-color: #fff2e6; }
      .modal-choose-channel .modal-content .modal-body .modal-choose-channel-options div:hover b {
        color: #ff7e00; }
    .modal-choose-channel .modal-content .modal-body .modal-choose-channel-options .modal-choose-channel-selected {
      border: solid 1px #fff2e6;
      background-color: #fff2e6; }
      .modal-choose-channel .modal-content .modal-body .modal-choose-channel-options .modal-choose-channel-selected b {
        color: #ff7e00; }
    .modal-choose-channel .modal-content .modal-body .modal-choose-channel-buttons {
      margin-top: 20px; }
      .modal-choose-channel .modal-content .modal-body .modal-choose-channel-buttons .no-fill-button {
        width: 120px;
        margin-right: 5px; }
      .modal-choose-channel .modal-content .modal-body .modal-choose-channel-buttons .orange-button {
        width: 308px; }

.detail-modal-history {
  background: red; }
  .detail-modal-history .detail-modal-content {
    width: 100%;
    position: relative;
    display: block;
    max-height: 100%;
    overflow: auto; }
    .detail-modal-history .detail-modal-content .detail-modal-content-top {
      padding: 24px 24px 24px 24px;
      border-bottom: solid 1px #dcdcdc; }
      .detail-modal-history .detail-modal-content .detail-modal-content-top #copy-string-id {
        background: 0;
        border: 0; }
    .detail-modal-history .detail-modal-content .detail-modal-content-bottom {
      padding: 10px 24px 24px 24px; }
      .detail-modal-history .detail-modal-content .detail-modal-content-bottom .case-title {
        font-size: 24px;
        letter-spacing: -0.6px;
        color: #191919;
        margin: 0 0 12px 0;
        display: block; }
      .detail-modal-history .detail-modal-content .detail-modal-content-bottom .case-action-history-card {
        padding: 0 20px 8px 30px;
        position: relative;
        margin-bottom: 12px; }
        .detail-modal-history .detail-modal-content .detail-modal-content-bottom .case-action-history-card::before {
          position: absolute;
          border-radius: 50%;
          width: 16px;
          height: 16px;
          left: 0;
          top: 10px;
          content: "";
          border: solid 2px rgba(25, 25, 25, 0.2);
          background: #ffffff;
          z-index: 2; }
        .detail-modal-history .detail-modal-content .detail-modal-content-bottom .case-action-history-card::after {
          position: absolute;
          width: 1px;
          height: 100%;
          left: 7px;
          top: 24px;
          content: "";
          border-left: dashed 2px rgba(25, 25, 25, 0.2);
          z-index: 1; }
        .detail-modal-history .detail-modal-content .detail-modal-content-bottom .case-action-history-card:nth-child(1)::before {
          border: solid 2px #ff7d00; }
        .detail-modal-history .detail-modal-content .detail-modal-content-bottom .case-action-history-card p {
          max-width: 100%; }
          .detail-modal-history .detail-modal-content .detail-modal-content-bottom .case-action-history-card p.action-time {
            font-size: 12px;
            letter-spacing: -0.2px;
            color: rgba(25, 25, 25, 0.4);
            margin: 0; }
            .detail-modal-history .detail-modal-content .detail-modal-content-bottom .case-action-history-card p.action-time svg {
              position: relative;
              margin-right: 4px;
              top: -1px; }
          .detail-modal-history .detail-modal-content .detail-modal-content-bottom .case-action-history-card p.action-text {
            margin: 0;
            font-size: 14px;
            letter-spacing: -0.4px;
            color: rgba(25, 25, 25, 0.6);
            word-break: break-word; }
        .detail-modal-history .detail-modal-content .detail-modal-content-bottom .case-action-history-card:nth-last-child(1)::after {
          display: none; }
        .detail-modal-history .detail-modal-content .detail-modal-content-bottom .case-action-history-card:nth-child(1) {
          margin-top: 12px; }
    .detail-modal-history .detail-modal-content .detail-value-wrapper {
      background: rgba(25, 25, 25, 0.08);
      border-radius: 8px;
      width: 100%;
      padding: 6px 6px 6px 32px;
      position: relative;
      font-size: 14px;
      letter-spacing: -0.2px; }
      .detail-modal-history .detail-modal-content .detail-value-wrapper p {
        margin: 0; }
      .detail-modal-history .detail-modal-content .detail-value-wrapper.detail-value-with-copy {
        padding: 7px 62px 6px 32px; }
      .detail-modal-history .detail-modal-content .detail-value-wrapper .icon-title {
        position: absolute;
        left: 10px;
        top: 10px; }
      .detail-modal-history .detail-modal-content .detail-value-wrapper .icon-copy {
        color: rgba(25, 25, 25, 0.6);
        position: absolute;
        right: 10px;
        top: 50%;
        -webkit-transform: translate(0, -50%);
                transform: translate(0, -50%);
        font-size: 12px;
        letter-spacing: -0.2px;
        cursor: pointer; }
        .detail-modal-history .detail-modal-content .detail-value-wrapper .icon-copy svg {
          margin-right: 4px;
          font-size: 14px;
          position: relative;
          top: -1px; }
        .detail-modal-history .detail-modal-content .detail-value-wrapper .icon-copy:hover {
          color: #191919; }
    .detail-modal-history .detail-modal-content label {
      font-size: 14px;
      letter-spacing: -0.4px;
      color: rgba(25, 25, 25, 0.8); }

.slide-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100%;
  background: rgba(25, 25, 25, 0.8);
  visibility: hidden;
  opacity: 0;
  right: 0;
  -webkit-transition: visibility .3s, opacity 0.5s ease-in-out;
  transition: visibility .3s, opacity 0.5s ease-in-out;
  z-index: 9999; }
  .slide-modal.active-slide-modal {
    /* right: 0; */
    opacity: 1;
    visibility: visible;
    -webkit-transition: visibility 0s, opacity 0.5s ease-in-out;
    transition: visibility 0s, opacity 0.5s ease-in-out; }
  .slide-modal .modal-body {
    position: absolute;
    top: 8px;
    right: -100%;
    left: unset;
    -webkit-transform: unset;
            transform: unset;
    height: calc(100% - 8px);
    width: 340px;
    display: inline-block;
    border-radius: 8px 0 0 0;
    background: #ffffff;
    padding: 0;
    max-height: calc(100% - 8px);
    -webkit-transition: ease-in-out .4s;
    transition: ease-in-out .4s; }
    .slide-modal .modal-body.active-slide-modal-body {
      right: 0; }
    .slide-modal .modal-body .close-slide-modal {
      border-radius: 50%;
      width: 48px;
      height: 48px;
      background: #ffffff;
      color: #757575;
      position: absolute;
      left: -65px;
      top: 0;
      text-align: center;
      cursor: pointer; }
      .slide-modal .modal-body .close-slide-modal:hover {
        background: #f9f9f9; }
      .slide-modal .modal-body .close-slide-modal svg {
        position: absolute;
        font-size: 25px;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%); }

.add-user-contact-container {
  padding: 32px 30px;
  text-align: left; }
  .add-user-contact-container .integration-list-loading-wrapper .lds-ring {
    width: 50px;
    height: 50px;
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    right: unset; }
    .add-user-contact-container .integration-list-loading-wrapper .lds-ring div {
      border: 5px solid #fff;
      border-color: #ff7d00 transparent transparent transparent;
      width: 50px;
      height: 50px;
      margin: 0; }
  .add-user-contact-container .comp-back-button {
    margin: 0 0 16px 0;
    font-family: PTRootBold; }
  .add-user-contact-container .add-user-contact-box {
    background: #ffffff;
    border-radius: 8px;
    padding: 32px;
    border: solid 1px rgba(25, 25, 25, 0.1); }
    .add-user-contact-container .add-user-contact-box .button-save {
      text-align: center;
      position: absolute;
      top: 84px;
      right: 32px;
      width: 188px;
      padding: 0 8px !important; }
    .add-user-contact-container .add-user-contact-box .add-user-contact-box-top {
      padding-bottom: 15px;
      border-bottom: solid 1px #dcdcdc;
      position: relative;
      font-size: 20px;
      letter-spacing: -0.6px; }
      .add-user-contact-container .add-user-contact-box .add-user-contact-box-top .title-wrapper {
        display: flex;
        align-items: center;
        margin-bottom: 8px; }
        .add-user-contact-container .add-user-contact-box .add-user-contact-box-top .title-wrapper svg {
          margin-right: 4px; }
      .add-user-contact-container .add-user-contact-box .add-user-contact-box-top p {
        font-size: 16px;
        margin: 0;
        color: rgba(25, 25, 25, 0.8); }
    .add-user-contact-container .add-user-contact-box .add-user-contact-box-form {
      width: 100%; }
      .add-user-contact-container .add-user-contact-box .add-user-contact-box-form label {
        padding: 24px 0 0;
        font-size: 14px;
        letter-spacing: -0.4px;
        display: block; }
        .add-user-contact-container .add-user-contact-box .add-user-contact-box-form label b span {
          font-family: PTRootUI, 'sans-serif';
          color: rgba(25, 25, 25, 0.6); }
      .add-user-contact-container .add-user-contact-box .add-user-contact-box-form .warning-error-red {
        display: flex;
        margin-top: 4px;
        color: #ff3f57; }
        .add-user-contact-container .add-user-contact-box .add-user-contact-box-form .warning-error-red svg {
          margin-right: 4px;
          font-size: 12.7px;
          position: relative;
          top: 2.5px; }
        .add-user-contact-container .add-user-contact-box .add-user-contact-box-form .warning-error-red p {
          font-size: 12px; }
      .add-user-contact-container .add-user-contact-box .add-user-contact-box-form .form-contact-fullname,
      .add-user-contact-container .add-user-contact-box .add-user-contact-box-form .form-contact-alias,
      .add-user-contact-container .add-user-contact-box .add-user-contact-box-form .form-contact-email,
      .add-user-contact-container .add-user-contact-box .add-user-contact-box-form .form-contact-custom-field {
        width: 400px !important; }
        .add-user-contact-container .add-user-contact-box .add-user-contact-box-form .form-contact-fullname input[type='text'],
        .add-user-contact-container .add-user-contact-box .add-user-contact-box-form .form-contact-alias input[type='text'],
        .add-user-contact-container .add-user-contact-box .add-user-contact-box-form .form-contact-email input[type='text'],
        .add-user-contact-container .add-user-contact-box .add-user-contact-box-form .form-contact-custom-field input[type='text'] {
          padding: 12px 16px;
          border-radius: 6px;
          border: solid 1px #dcdcdc;
          width: 100%;
          font-size: 16px;
          letter-spacing: -0.3px;
          display: block; }
        .add-user-contact-container .add-user-contact-box .add-user-contact-box-form .form-contact-fullname input[type='text']:focus,
        .add-user-contact-container .add-user-contact-box .add-user-contact-box-form .form-contact-alias input[type='text']:focus,
        .add-user-contact-container .add-user-contact-box .add-user-contact-box-form .form-contact-email input[type='text']:focus,
        .add-user-contact-container .add-user-contact-box .add-user-contact-box-form .form-contact-custom-field input[type='text']:focus {
          border: solid 1px #ff7e00; }
        .add-user-contact-container .add-user-contact-box .add-user-contact-box-form .form-contact-fullname input[type='text']:disabled,
        .add-user-contact-container .add-user-contact-box .add-user-contact-box-form .form-contact-alias input[type='text']:disabled,
        .add-user-contact-container .add-user-contact-box .add-user-contact-box-form .form-contact-email input[type='text']:disabled,
        .add-user-contact-container .add-user-contact-box .add-user-contact-box-form .form-contact-custom-field input[type='text']:disabled {
          background-color: rgba(25, 25, 25, 0.1) !important;
          color: rgba(25, 25, 25, 0.4) !important; }
        .add-user-contact-container .add-user-contact-box .add-user-contact-box-form .form-contact-fullname textarea,
        .add-user-contact-container .add-user-contact-box .add-user-contact-box-form .form-contact-alias textarea,
        .add-user-contact-container .add-user-contact-box .add-user-contact-box-form .form-contact-email textarea,
        .add-user-contact-container .add-user-contact-box .add-user-contact-box-form .form-contact-custom-field textarea {
          display: block;
          padding: 12px 16px;
          margin: 0;
          width: 100%;
          max-width: 400px;
          height: 96px;
          border-radius: 8px;
          font-size: 16px;
          letter-spacing: -0.3px;
          border: solid 1px #1919191A;
          resize: none;
          line-height: 24px;
          caret-color: #FF7E00; }
        .add-user-contact-container .add-user-contact-box .add-user-contact-box-form .form-contact-fullname textarea:focus,
        .add-user-contact-container .add-user-contact-box .add-user-contact-box-form .form-contact-alias textarea:focus,
        .add-user-contact-container .add-user-contact-box .add-user-contact-box-form .form-contact-email textarea:focus,
        .add-user-contact-container .add-user-contact-box .add-user-contact-box-form .form-contact-custom-field textarea:focus {
          border: solid 1px #ff7e00; }
        .add-user-contact-container .add-user-contact-box .add-user-contact-box-form .form-contact-fullname textarea::-webkit-scrollbar,
        .add-user-contact-container .add-user-contact-box .add-user-contact-box-form .form-contact-alias textarea::-webkit-scrollbar,
        .add-user-contact-container .add-user-contact-box .add-user-contact-box-form .form-contact-email textarea::-webkit-scrollbar,
        .add-user-contact-container .add-user-contact-box .add-user-contact-box-form .form-contact-custom-field textarea::-webkit-scrollbar {
          width: 12px; }
        .add-user-contact-container .add-user-contact-box .add-user-contact-box-form .form-contact-fullname textarea::-webkit-scrollbar-thumb,
        .add-user-contact-container .add-user-contact-box .add-user-contact-box-form .form-contact-alias textarea::-webkit-scrollbar-thumb,
        .add-user-contact-container .add-user-contact-box .add-user-contact-box-form .form-contact-email textarea::-webkit-scrollbar-thumb,
        .add-user-contact-container .add-user-contact-box .add-user-contact-box-form .form-contact-custom-field textarea::-webkit-scrollbar-thumb {
          border: 4px solid #00000000;
          border-radius: 8px;
          background-color: #1919194D;
          background-clip: padding-box; }
      .add-user-contact-container .add-user-contact-box .add-user-contact-box-form .form-contact-phonenumber {
        width: 320px; }
        .add-user-contact-container .add-user-contact-box .add-user-contact-box-form .form-contact-phonenumber .input-phonenumber-container {
          padding: 0 16px;
          border-radius: 6px;
          border: solid 1px #dcdcdc;
          width: 100%;
          font-size: 16px;
          letter-spacing: -0.3px;
          display: flex;
          align-items: center; }
          .add-user-contact-container .add-user-contact-box .add-user-contact-box-form .form-contact-phonenumber .input-phonenumber-container.phone-focus {
            border: solid 1px #ff7e00; }
          .add-user-contact-container .add-user-contact-box .add-user-contact-box-form .form-contact-phonenumber .input-phonenumber-container.phone-disabled {
            background-color: rgba(25, 25, 25, 0.1);
            color: rgba(25, 25, 25, 0.4); }
          .add-user-contact-container .add-user-contact-box .add-user-contact-box-form .form-contact-phonenumber .input-phonenumber-container label {
            padding: 0;
            margin: 0 4px 0 0;
            font-size: 16px; }
          .add-user-contact-container .add-user-contact-box .add-user-contact-box-form .form-contact-phonenumber .input-phonenumber-container .form-phonenumber {
            padding: 0;
            height: 50px;
            border: none; }
          .add-user-contact-container .add-user-contact-box .add-user-contact-box-form .form-contact-phonenumber .input-phonenumber-container .form-phonenumber:disabled {
            color: rgba(25, 25, 25, 0.4) !important; }
          .add-user-contact-container .add-user-contact-box .add-user-contact-box-form .form-contact-phonenumber .input-phonenumber-container input[type="text"] {
            border: none !important; }
        .add-user-contact-container .add-user-contact-box .add-user-contact-box-form .form-contact-phonenumber .alert-wrapper {
          display: flex;
          margin-top: 4px; }
          .add-user-contact-container .add-user-contact-box .add-user-contact-box-form .form-contact-phonenumber .alert-wrapper svg {
            margin-right: 4px;
            font-size: 20px;
            position: relative;
            top: -2px; }
          .add-user-contact-container .add-user-contact-box .add-user-contact-box-form .form-contact-phonenumber .alert-wrapper p {
            font-size: 12px; }
      .add-user-contact-container .add-user-contact-box .add-user-contact-box-form .contact-custom-field-separator {
        margin-top: 24px;
        width: 100%;
        height: 1px;
        background-color: #1919191A; }
      .add-user-contact-container .add-user-contact-box .add-user-contact-box-form .dropdown {
        max-width: 400px; }
        .add-user-contact-container .add-user-contact-box .add-user-contact-box-form .dropdown button {
          margin-top: 0px; }
        .add-user-contact-container .add-user-contact-box .add-user-contact-box-form .dropdown .dropdown-option-wrapper {
          max-height: none;
          border: none; }
      .add-user-contact-container .add-user-contact-box .add-user-contact-box-form .contact-date-value-wrapper {
        max-width: 400px;
        border-radius: 8px;
        height: 48px;
        width: 100%;
        position: relative;
        padding: 0 48px 0 16px;
        border: solid 1px #1919191A;
        cursor: pointer;
        line-height: 46px; }
        .add-user-contact-container .add-user-contact-box .add-user-contact-box-form .contact-date-value-wrapper:hover {
          border-color: #ff7e00; }
        .add-user-contact-container .add-user-contact-box .add-user-contact-box-form .contact-date-value-wrapper svg {
          position: absolute;
          right: 16px;
          top: 50%;
          -webkit-transform: translate(0, -50%);
                  transform: translate(0, -50%); }
      .add-user-contact-container .add-user-contact-box .add-user-contact-box-form .contact-date-value-wrapper.disabled {
        background: #1919191A;
        border: none;
        box-shadow: none;
        cursor: not-allowed; }

.user-segment-main-wrapper .integration-list-loading-wrapper {
  height: 80vh; }
  .user-segment-main-wrapper .integration-list-loading-wrapper .lds-ring {
    width: 50px;
    height: 50px;
    position: relative;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    right: unset; }
    .user-segment-main-wrapper .integration-list-loading-wrapper .lds-ring div {
      border: 5px solid #fff;
      border-color: #ff7d00 transparent transparent transparent;
      width: 50px;
      height: 50px;
      margin: 0; }

.user-segment-main-wrapper .user-segment-header {
  padding-bottom: 12px;
  margin-bottom: 12px;
  border-bottom: 1px solid rgba(25, 25, 25, 0.1); }
  .user-segment-main-wrapper .user-segment-header .remove-segment-wrapper button.cancel-delete-segment-btn {
    margin-right: 8px; }
  .user-segment-main-wrapper .user-segment-header .remove-segment-wrapper:focus {
    border: none;
    outline: none; }

.user-segment-main-wrapper .search-box-wrapper {
  margin-bottom: 24px; }

.table-user-segment-list thead tr .custom-checkbox-stripe [type="checkbox"]:checked + label:after {
  top: 2px;
  left: 0; }

.table-user-segment-list {
  table-layout: unset; }
  .table-user-segment-list thead tr {
    background: transparent; }
    .table-user-segment-list thead tr td {
      font-size: 14px;
      letter-spacing: -0.4px;
      font-family: "PTRootBold" !important;
      word-wrap: break-word;
      white-space: normal; }
      .table-user-segment-list thead tr td.col_segment_name {
        padding-left: 34px;
        width: calc(50% - 115px); }
        .table-user-segment-list thead tr td.col_segment_name .custom-checkbox {
          position: absolute !important;
          top: 1px !important;
          left: 12px !important; }
        .table-user-segment-list thead tr td.col_segment_name .custom-checkbox-stripe {
          position: absolute !important;
          top: 1px !important;
          left: 12px !important; }
        .table-user-segment-list thead tr td.col_segment_name .custom-checkbox-stripe [type="checkbox"]:checked + label:after {
          width: 16px;
          height: 16px; }
      .table-user-segment-list thead tr td .col_number_contact {
        width: calc(50% - 115px); }
      .table-user-segment-list thead tr td.col_select_list {
        width: 230px; }
      .table-user-segment-list thead tr td:last-child {
        width: 161px;
        padding-right: 0; }
        .table-user-segment-list thead tr td:last-child .main-custom-select-option-wrapper {
          display: flex;
          justify-content: flex-end; }
          .table-user-segment-list thead tr td:last-child .main-custom-select-option-wrapper .dropdown {
            width: 55px; }
        .table-user-segment-list thead tr td:last-child .custom-select-option-value-wrapper {
          height: 22px;
          padding: 0 4px 0 12px !important;
          border-radius: 4px !important; }
          .table-user-segment-list thead tr td:last-child .custom-select-option-value-wrapper .custom-select-option-value {
            font-weight: bold;
            font-size: 12px;
            letter-spacing: -0.4px;
            padding: 0; }
          .table-user-segment-list thead tr td:last-child .custom-select-option-value-wrapper svg {
            font-size: 12px;
            right: 8px; }
        .table-user-segment-list thead tr td:last-child .dropdown-option-wrapper button {
          font-family: "PTRootUI";
          font-weight: normal; }
  .table-user-segment-list tbody .tr-user-segment-list-body td {
    position: relative;
    word-wrap: break-word;
    max-width: unset;
    padding: 8px;
    line-height: 21px; }
    .table-user-segment-list tbody .tr-user-segment-list-body td.col_segment_name {
      padding-left: 34px;
      width: calc(50% - 115px); }
      .table-user-segment-list tbody .tr-user-segment-list-body td.col_segment_name p {
        margin-bottom: 0;
        display: inline-block;
        width: 85%;
        vertical-align: top; }
      .table-user-segment-list tbody .tr-user-segment-list-body td.col_segment_name .custom-checkbox {
        position: absolute !important;
        top: 1px !important;
        left: 12px !important; }
      .table-user-segment-list tbody .tr-user-segment-list-body td.col_segment_name .custom-checkbox-stripe {
        position: absolute !important;
        top: 1px !important;
        left: 12px !important; }
      .table-user-segment-list tbody .tr-user-segment-list-body td.col_segment_name .custom-checkbox-stripe [type="checkbox"]:checked + label:after {
        width: 16px;
        height: 16px; }
    .table-user-segment-list tbody .tr-user-segment-list-body td.col_segment_count {
      width: calc(50% - 115px); }
    .table-user-segment-list tbody .tr-user-segment-list-body td.col-action {
      padding-right: 2px;
      width: 230px;
      text-align: right;
      white-space: nowrap; }
      .table-user-segment-list tbody .tr-user-segment-list-body td.col-action button {
        border: none !important; }
        .table-user-segment-list tbody .tr-user-segment-list-body td.col-action button.detail-btn {
          margin-left: 10px;
          letter-spacing: -0.4px; }
          .table-user-segment-list tbody .tr-user-segment-list-body td.col-action button.detail-btn:hover {
            background: transparent;
            color: #e87200; }
          .table-user-segment-list tbody .tr-user-segment-list-body td.col-action button.detail-btn svg {
            position: relative;
            font-size: 16px;
            top: -2px;
            left: -4px; }
        .table-user-segment-list tbody .tr-user-segment-list-body td.col-action button.delete-btn {
          width: 100px;
          color: #FF3F57 !important; }
          .table-user-segment-list tbody .tr-user-segment-list-body td.col-action button.delete-btn:hover {
            background: transparent;
            color: #e83b51; }
          .table-user-segment-list tbody .tr-user-segment-list-body td.col-action button.delete-btn svg {
            position: relative;
            top: -3px;
            color: #FF3F57; }
  .table-user-segment-list tbody .tr-user-segment-list-empty {
    border-bottom: none; }
    .table-user-segment-list tbody .tr-user-segment-list-empty td {
      padding-top: 24px; }
      .table-user-segment-list tbody .tr-user-segment-list-empty td b {
        font-size: 16px;
        letter-spacing: -0.5px; }

.custom-table-content tbody tr:hover td {
  background: none; }

.new-segment-modal .modal-content {
  width: 520px; }
  .new-segment-modal .modal-content .modal-body {
    padding: 32px; }
    .new-segment-modal .modal-content .modal-body .new-segment-wrapper .segment-title-wrapper .new-segment-title {
      font-size: 20px;
      line-height: 24px; }
    .new-segment-modal .modal-content .modal-body .new-segment-wrapper .segment-title-wrapper svg {
      margin-right: 10px;
      font-size: 21px;
      position: relative;
      top: -3px; }
    .new-segment-modal .modal-content .modal-body .new-segment-wrapper label {
      width: 100%;
      margin-top: 20px; }
    .new-segment-modal .modal-content .modal-body .new-segment-wrapper .segment-name {
      width: 100%;
      height: 48px;
      border: 1px solid rgba(25, 25, 25, 0.1);
      border-radius: 8px;
      padding: 12px 16px;
      margin: 0; }
      .new-segment-modal .modal-content .modal-body .new-segment-wrapper .segment-name:focus {
        border: 1px solid #FF7E00; }
    .new-segment-modal .modal-content .modal-body .new-segment-wrapper .new-segment-button-container {
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin: 20px 0 0; }
      .new-segment-modal .modal-content .modal-body .new-segment-wrapper .new-segment-button-container button {
        width: 50%; }
        .new-segment-modal .modal-content .modal-body .new-segment-wrapper .new-segment-button-container button.orange-button {
          margin-left: 8px; }

.detail-segment-main-wrapper .detail-segment-header {
  padding-bottom: 22px;
  margin-bottom: 12px;
  border-bottom: 1px solid rgba(25, 25, 25, 0.1);
  width: 100%;
  position: relative;
  display: block; }
  .detail-segment-main-wrapper .detail-segment-header .btn {
    padding: 0; }
  .detail-segment-main-wrapper .detail-segment-header .title {
    position: relative;
    width: 100%;
    display: block; }
    .detail-segment-main-wrapper .detail-segment-header .title .orange-button {
      width: 192px;
      position: absolute;
      right: 0;
      top: -6px; }
    .detail-segment-main-wrapper .detail-segment-header .title .red-button {
      width: 192px;
      position: absolute;
      right: 200px;
      top: -6px; }

.detail-segment-main-wrapper .segment-name-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start; }
  .detail-segment-main-wrapper .segment-name-wrapper button {
    margin: 24px 0 0; }
  .detail-segment-main-wrapper .segment-name-wrapper b {
    margin-top: 12px;
    position: relative;
    font-size: 14px;
    width: 303px; }
  .detail-segment-main-wrapper .segment-name-wrapper span {
    font-weight: normal; }
  .detail-segment-main-wrapper .segment-name-wrapper .segment-name-input {
    width: 300px;
    border: 1px solid rgba(25, 25, 25, 0.1);
    border-radius: 8px;
    height: 48px;
    padding: 12px 16px;
    margin: 8px 0 0; }
    .detail-segment-main-wrapper .segment-name-wrapper .segment-name-input:focus {
      border: 1px solid #FF7E00; }

.contact-detail-segment-main-wrapper .contact-detail-segment-header {
  padding-bottom: 12px;
  margin-bottom: 12px;
  border-bottom: 1px solid rgba(25, 25, 25, 0.1);
  position: relative; }
  .contact-detail-segment-main-wrapper .contact-detail-segment-header .button-download-contact-segment {
    position: absolute;
    right: 286px;
    top: -5px; }
  .contact-detail-segment-main-wrapper .contact-detail-segment-header .button-add-into-segment {
    position: absolute;
    right: 0;
    top: -5px; }
  .contact-detail-segment-main-wrapper .contact-detail-segment-header .title {
    font-size: 20px;
    letter-spacing: -0.5px; }
  .contact-detail-segment-main-wrapper .contact-detail-segment-header .remove-segment-wrapper {
    position: absolute;
    right: 0;
    top: -5px; }
    .contact-detail-segment-main-wrapper .contact-detail-segment-header .remove-segment-wrapper button.cancel-delete-segment-btn {
      margin-right: 8px; }

.contact-detail-segment-main-wrapper .search-box-wrapper {
  margin-bottom: 24px; }

.table-contact-detail-segment-list .col_full_name, .table-contact-detail-segment-list .col_alias {
  width: 220px; }

.table-contact-detail-segment-list .col_phone_number {
  width: 200px; }

.table-contact-detail-segment-list .col_email {
  width: 280px; }

.table-contact-detail-segment-list .col_action, .table-contact-detail-segment-list .col_select_list {
  width: 90px; }

.table-contact-detail-segment-list thead tr {
  background: transparent; }
  .table-contact-detail-segment-list thead tr .custom-checkbox-stripe [type="checkbox"]:checked + label:after {
    top: 2px;
    left: 0; }
  .table-contact-detail-segment-list thead tr td {
    padding: 10px;
    width: 20%;
    font-size: 14px;
    word-wrap: break-word;
    white-space: normal; }
    .table-contact-detail-segment-list thead tr td .label-select-all {
      position: relative;
      top: 6px; }
    .table-contact-detail-segment-list thead tr td:nth-child(1) {
      padding-left: 34px !important; }
    .table-contact-detail-segment-list thead tr td:last-child {
      padding-right: 0; }
      .table-contact-detail-segment-list thead tr td:last-child .main-custom-select-option-wrapper {
        display: flex;
        justify-content: flex-end; }
        .table-contact-detail-segment-list thead tr td:last-child .main-custom-select-option-wrapper .dropdown {
          width: 80px; }
      .table-contact-detail-segment-list thead tr td:last-child .custom-select-option-value-wrapper {
        width: 55px !important;
        height: 23px !important;
        padding: 2px 8px 2px 12px !important;
        border-radius: 4px !important; }
        .table-contact-detail-segment-list thead tr td:last-child .custom-select-option-value-wrapper p {
          font-size: 12px;
          font-weight: bold; }
        .table-contact-detail-segment-list thead tr td:last-child .custom-select-option-value-wrapper .custom-select-option-value {
          padding: 0; }
        .table-contact-detail-segment-list thead tr td:last-child .custom-select-option-value-wrapper svg {
          right: 3px !important;
          height: 15px;
          top: 11px !important;
          position: absolute !important;
          margin-right: unset !important; }

.table-contact-detail-segment-list tbody tr:hover {
  background-color: transparent !important; }

.table-contact-detail-segment-list tbody tr td {
  background: transparent;
  position: relative;
  word-wrap: break-word; }
  .table-contact-detail-segment-list tbody tr td:nth-child(1) {
    padding-left: 34px !important; }
  .table-contact-detail-segment-list tbody tr td.col_full_name p {
    margin-bottom: 0;
    display: inline-block;
    width: 75%;
    vertical-align: top; }
  .table-contact-detail-segment-list tbody tr td.col_action {
    padding-right: 0;
    text-align: right;
    white-space: nowrap; }
    .table-contact-detail-segment-list tbody tr td.col_action button {
      border: none !important; }
      .table-contact-detail-segment-list tbody tr td.col_action button.delete-btn {
        width: 100px;
        color: #FF3F57 !important; }
        .table-contact-detail-segment-list tbody tr td.col_action button.delete-btn:hover {
          background: transparent;
          color: #e83b51; }
        .table-contact-detail-segment-list tbody tr td.col_action button.delete-btn svg {
          position: relative;
          top: -2px;
          right: 5px;
          color: #FF3F57; }

.header-add-contact-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 32px; }
  .header-add-contact-container .comp-back-button {
    margin: 0; }

.add-contact-main-container {
  width: 100%;
  display: flex; }
  .add-contact-main-container .add-contact-segment-main-wrapper {
    width: 60%;
    min-width: 600px;
    margin-right: 16px; }
    .add-contact-main-container .add-contact-segment-main-wrapper .add-contact-segment-header {
      padding-bottom: 12px;
      margin-bottom: 24px;
      border-bottom: 1px solid rgba(25, 25, 25, 0.1); }
    .add-contact-main-container .add-contact-segment-main-wrapper .add-contact-segment-search {
      width: 343px;
      border-radius: 8px;
      border: solid 1px rgba(25, 25, 25, 0.1);
      margin-bottom: 24px;
      position: relative; }
      .add-contact-main-container .add-contact-segment-main-wrapper .add-contact-segment-search .icon-remove-search {
        position: absolute;
        cursor: pointer;
        right: 50px;
        top: 50%;
        -webkit-transform: translate(0, -50%);
                transform: translate(0, -50%); }
      .add-contact-main-container .add-contact-segment-main-wrapper .add-contact-segment-search.focus-search {
        border: solid 1px #ff7e00; }
      .add-contact-main-container .add-contact-segment-main-wrapper .add-contact-segment-search input {
        border: none;
        background: transparent;
        padding: 8px 40px 8px 16px;
        width: 90%;
        height: 47px; }
      .add-contact-main-container .add-contact-segment-main-wrapper .add-contact-segment-search svg {
        position: relative;
        top: -2px;
        font-size: 20px;
        color: rgba(25, 25, 25, 0.6) !important; }
    .add-contact-main-container .add-contact-segment-main-wrapper .user-contact-segment-list-table-wrapper .custom-table {
      min-height: 200px; }
  .add-contact-main-container .selected-contact-segment-main-wrapper {
    width: 40%;
    min-width: 332px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content; }
    .add-contact-main-container .selected-contact-segment-main-wrapper .selected-contact-segment-header {
      padding-bottom: 12px;
      margin-bottom: 12px;
      border-bottom: 1px solid rgba(25, 25, 25, 0.1);
      display: flex;
      justify-content: space-between;
      align-items: center; }
      .add-contact-main-container .selected-contact-segment-main-wrapper .selected-contact-segment-header .clear-all {
        font-size: 16px;
        letter-spacing: -0.5px;
        color: #FF3F57;
        cursor: pointer;
        margin: 0; }
        .add-contact-main-container .selected-contact-segment-main-wrapper .selected-contact-segment-header .clear-all svg {
          position: relative;
          top: -1px;
          margin-right: 4px; }
        .add-contact-main-container .selected-contact-segment-main-wrapper .selected-contact-segment-header .clear-all:hover {
          color: rgba(255, 63, 87, 0.8); }
    .add-contact-main-container .selected-contact-segment-main-wrapper .contact-selected-wrapper {
      width: 100%; }
      .add-contact-main-container .selected-contact-segment-main-wrapper .contact-selected-wrapper .contact-item-selected {
        padding: 2px 12px;
        background: rgba(25, 25, 25, 0.05);
        border-radius: 4px;
        margin-right: 4px;
        color: rgba(25, 25, 25, 0.6);
        display: inline-block;
        line-height: 18px;
        letter-spacing: -0.4px;
        margin-bottom: 4px; }
        .add-contact-main-container .selected-contact-segment-main-wrapper .contact-selected-wrapper .contact-item-selected svg {
          position: relative;
          top: -1px;
          margin-right: 5px;
          cursor: pointer; }
      .add-contact-main-container .selected-contact-segment-main-wrapper .contact-selected-wrapper .no-selected-contact-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 24px; }
        .add-contact-main-container .selected-contact-segment-main-wrapper .contact-selected-wrapper .no-selected-contact-wrapper img {
          margin-bottom: 20px; }
        .add-contact-main-container .selected-contact-segment-main-wrapper .contact-selected-wrapper .no-selected-contact-wrapper p {
          margin: 0; }

.table-user-contact-segment-list {
  table-layout: fixed; }
  .table-user-contact-segment-list .custom-checkbox-stripe [type="checkbox"]:checked + label:after {
    top: 2px;
    left: 0; }
  .table-user-contact-segment-list thead tr {
    background: transparent; }
    .table-user-contact-segment-list thead tr td {
      padding: 10px;
      width: 20%;
      font-size: 14px;
      word-wrap: break-word;
      white-space: normal; }
      .table-user-contact-segment-list thead tr td .custom-checkbox {
        top: 13px !important; }
      .table-user-contact-segment-list thead tr td .custom-checkbox-stripe {
        top: 13px !important; }
      .table-user-contact-segment-list thead tr td:nth-child(1) {
        width: 200px;
        padding-left: 34px !important; }
      .table-user-contact-segment-list thead tr td:nth-child(2) {
        width: 200px; }
      .table-user-contact-segment-list thead tr td:nth-child(3) {
        width: 160px; }
      .table-user-contact-segment-list thead tr td:last-child {
        width: 90px;
        padding-right: 0; }
        .table-user-contact-segment-list thead tr td:last-child .main-custom-select-option-wrapper {
          display: flex;
          justify-content: flex-end; }
          .table-user-contact-segment-list thead tr td:last-child .main-custom-select-option-wrapper .dropdown {
            width: 80px; }
        .table-user-contact-segment-list thead tr td:last-child .custom-select-option-value-wrapper {
          width: 55px !important;
          height: 23px !important;
          padding: 2px 8px 2px 12px !important;
          border-radius: 4px !important; }
          .table-user-contact-segment-list thead tr td:last-child .custom-select-option-value-wrapper p {
            font-size: 12px;
            font-weight: bold; }
          .table-user-contact-segment-list thead tr td:last-child .custom-select-option-value-wrapper .custom-select-option-value {
            padding: 0; }
          .table-user-contact-segment-list thead tr td:last-child .custom-select-option-value-wrapper svg {
            right: 3px !important;
            height: 15px;
            top: 11px !important;
            position: absolute !important;
            margin-right: unset !important; }
  .table-user-contact-segment-list tbody tr td {
    background: transparent;
    position: relative;
    word-wrap: break-word; }
    .table-user-contact-segment-list tbody tr td:nth-child(1) {
      padding-left: 34px !important; }
    .table-user-contact-segment-list tbody tr td.col_full_name p {
      margin-bottom: 0;
      display: inline-block;
      width: 75%;
      vertical-align: top; }
    .table-user-contact-segment-list tbody tr td.no-data-column .no-contact-wrapper {
      flex-direction: column;
      margin-top: 6px;
      text-align: center; }
      .table-user-contact-segment-list tbody tr td.no-data-column .no-contact-wrapper .no-contact-found-image {
        width: 40px; }
      .table-user-contact-segment-list tbody tr td.no-data-column .no-contact-wrapper img {
        margin-bottom: 20px; }
      .table-user-contact-segment-list tbody tr td.no-data-column .no-contact-wrapper p {
        margin: 0;
        color: rgba(25, 25, 25, 0.8); }
    .table-user-contact-segment-list tbody tr td.col-action {
      padding-right: 0;
      text-align: right;
      white-space: nowrap; }
  .table-user-contact-segment-list tbody tr:hover {
    background-color: transparent !important; }

.import-contact-main-wrapper input[type="file"] {
  display: none; }

.import-contact-main-wrapper .integration-list-loading-wrapper {
  height: 80vh; }
  .import-contact-main-wrapper .integration-list-loading-wrapper .lds-ring {
    width: 50px;
    height: 50px;
    position: relative;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    right: unset; }
    .import-contact-main-wrapper .integration-list-loading-wrapper .lds-ring div {
      border: 5px solid #fff;
      border-color: #ff7d00 transparent transparent transparent;
      width: 50px;
      height: 50px;
      margin: 0; }

.import-contact-main-wrapper .import-contact-header {
  padding-bottom: 12px;
  margin-bottom: 12px;
  border-bottom: 1px solid rgba(25, 25, 25, 0.1); }
  .import-contact-main-wrapper .import-contact-header .grey-font {
    font-size: 16px;
    letter-spacing: -0.6px; }
  .import-contact-main-wrapper .import-contact-header .orange-button {
    width: 220px; }

.import-contact-main-wrapper .import-contact-search {
  width: 300px;
  border-radius: 8px;
  border: solid 1px rgba(25, 25, 25, 0.1);
  margin-bottom: 24px; }
  .import-contact-main-wrapper .import-contact-search.focus-search {
    border: solid 1px #ff7e00; }
    .import-contact-main-wrapper .import-contact-search.focus-search svg {
      color: #ff7e00; }
  .import-contact-main-wrapper .import-contact-search span {
    position: relative;
    top: -2px; }
  .import-contact-main-wrapper .import-contact-search input {
    border: none;
    background: transparent;
    padding: 8px 4px 8px 16px;
    width: 90%; }

.import-contact-list-table-wrapper .custom-table {
  min-height: 200px; }

.table-import-contact-list {
  table-layout: fixed;
  margin: 24px 0 0; }

.table-import-contact-list thead tr {
  background: transparent; }
  .table-import-contact-list thead tr td {
    padding: 8px;
    font-size: 14px;
    word-wrap: break-word;
    white-space: normal; }
    .table-import-contact-list thead tr td:nth-child(1), .table-import-contact-list thead tr td:nth-child(5), .table-import-contact-list thead tr td:nth-child(6) {
      width: 180px; }
    .table-import-contact-list thead tr td:nth-child(2) {
      width: 104px; }
    .table-import-contact-list thead tr td:nth-child(3), .table-import-contact-list thead tr td:nth-child(4) {
      width: 140px; }
    .table-import-contact-list thead tr td:last-child {
      width: 90px; }
      .table-import-contact-list thead tr td:last-child .custom-select-option-value-wrapper {
        width: 55px !important;
        height: 23px !important;
        padding: 2px 8px 2px 12px !important;
        border-radius: 4px !important; }
        .table-import-contact-list thead tr td:last-child .custom-select-option-value-wrapper p {
          font-size: 12px;
          font-weight: bold; }
        .table-import-contact-list thead tr td:last-child .custom-select-option-value-wrapper .custom-select-option-value {
          padding: 0; }
        .table-import-contact-list thead tr td:last-child .custom-select-option-value-wrapper svg {
          right: 3px !important;
          height: 15px;
          top: 11px !important;
          position: absolute !important;
          margin-right: unset !important; }

.table-import-contact-list tbody tr {
  cursor: pointer; }
  .table-import-contact-list tbody tr:hover {
    background-color: #FFF2E5; }
  .table-import-contact-list tbody tr td {
    background: transparent;
    position: relative;
    word-wrap: break-word;
    vertical-align: top; }
    .table-import-contact-list tbody tr td .unavailable {
      color: rgba(25, 25, 25, 0.4); }
    .table-import-contact-list tbody tr td .link-user {
      color: inherit;
      text-decoration: inherit; }
    .table-import-contact-list tbody tr td.col_view {
      color: #ff7d00; }
      .table-import-contact-list tbody tr td.col_view svg {
        position: relative;
        top: -2px; }
      .table-import-contact-list tbody tr td.col_view a {
        color: #ff7d00; }
    .table-import-contact-list tbody tr td.col-origin img {
      width: 20px;
      position: relative;
      margin-right: 4px;
      top: -2px; }
    .table-import-contact-list tbody tr td:nth-child(1) p {
      margin-bottom: 4px; }
    .table-import-contact-list tbody tr td:nth-child(1) .col_name_alias {
      font-size: 12px; }
    .table-import-contact-list tbody tr td:nth-child(4).col-origin p {
      margin: 0;
      display: inline-block;
      position: relative;
      padding: 0 0 0 24px; }
    .table-import-contact-list tbody tr td:nth-child(4).col-origin img {
      position: absolute;
      left: 0;
      top: 0;
      margin-right: 8px;
      width: 20px; }
    .table-import-contact-list tbody tr td:nth-child(1), .table-import-contact-list tbody tr td:nth-child(5), .table-import-contact-list tbody tr td:nth-child(6) {
      width: 180px; }
    .table-import-contact-list tbody tr td:nth-child(2) {
      width: 104px; }
    .table-import-contact-list tbody tr td:nth-child(3), .table-import-contact-list tbody tr td:nth-child(4) {
      width: 140px; }

.table-import-contact-list .col-action-button-wrapper {
  position: absolute;
  right: 7px;
  top: 50%;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%);
  /* display: none; */ }

.table-import-contact-list td {
  max-width: unset !important; }

.import-contact-modal .modal-content {
  width: 740px; }

.import-contact-modal .modal-body {
  padding: 32px; }
  .import-contact-modal .modal-body .import-contact-wrapper {
    position: relative; }
    .import-contact-modal .modal-body .import-contact-wrapper .import-contact-title {
      font-size: 20px;
      letter-spacing: -0.6px;
      color: #191919;
      line-height: 1;
      margin: 6px 0 0; }
    .import-contact-modal .modal-body .import-contact-wrapper .import-contact-description {
      font-size: 16px;
      letter-spacing: -0.3px;
      color: rgba(25, 25, 25, 0.8);
      line-height: 1;
      margin: 6px 0 0; }
    .import-contact-modal .modal-body .import-contact-wrapper svg {
      position: absolute;
      right: 0;
      top: 0;
      font-size: 24px;
      cursor: pointer; }
      .import-contact-modal .modal-body .import-contact-wrapper svg:hover {
        color: #ff7d00; }
  .import-contact-modal .modal-body .import-contact-option-container {
    width: 100%;
    margin-top: 20px;
    text-align: center;
    display: flex; }
    .import-contact-modal .modal-body .import-contact-option-container .import-contact-new-user {
      width: 50%;
      border-right: solid 1px rgba(25, 25, 25, 0.1);
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 24px 24px 32px; }
      .import-contact-modal .modal-body .import-contact-option-container .import-contact-new-user img {
        margin-bottom: 12px; }
      .import-contact-modal .modal-body .import-contact-option-container .import-contact-new-user p {
        margin-bottom: 4px; }
      .import-contact-modal .modal-body .import-contact-option-container .import-contact-new-user .add-description {
        margin-bottom: 12px;
        width: 80%;
        text-align: center;
        font-size: 14px; }
      .import-contact-modal .modal-body .import-contact-option-container .import-contact-new-user button {
        font-family: 'PTRootUI';
        font-size: 12px; }
        .import-contact-modal .modal-body .import-contact-option-container .import-contact-new-user button img {
          margin: 0;
          margin-right: 4px;
          position: relative;
          top: -1px; }
        .import-contact-modal .modal-body .import-contact-option-container .import-contact-new-user button.add-new-contact-btn {
          width: 125px;
          height: 22px;
          border-radius: 4px;
          line-height: 13px; }
    .import-contact-modal .modal-body .import-contact-option-container .import-contact-import-contact {
      width: 50%;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 24px 12px 32px; }
      .import-contact-modal .modal-body .import-contact-option-container .import-contact-import-contact img {
        margin-bottom: 12px; }
      .import-contact-modal .modal-body .import-contact-option-container .import-contact-import-contact p {
        margin-bottom: 4px; }
      .import-contact-modal .modal-body .import-contact-option-container .import-contact-import-contact .add-description {
        margin-bottom: 12px;
        text-align: center;
        font-size: 14px;
        width: 80%; }
      .import-contact-modal .modal-body .import-contact-option-container .import-contact-import-contact .button-import-container {
        display: flex; }
      .import-contact-modal .modal-body .import-contact-option-container .import-contact-import-contact button {
        font-family: 'PTRootUI';
        font-size: 12px; }
        .import-contact-modal .modal-body .import-contact-option-container .import-contact-import-contact button img {
          margin: 0;
          margin-right: 4px; }
        .import-contact-modal .modal-body .import-contact-option-container .import-contact-import-contact button.upload-csv-btn {
          width: 97px;
          height: 22px;
          border-radius: 4px;
          margin-right: 5px;
          line-height: 12px; }
          .import-contact-modal .modal-body .import-contact-option-container .import-contact-import-contact button.upload-csv-btn img {
            position: relative;
            top: -1px; }
        .import-contact-modal .modal-body .import-contact-option-container .import-contact-import-contact button.no-border {
          border: none !important;
          margin-right: 12px;
          line-height: 12px;
          position: relative;
          top: -3px; }
          .import-contact-modal .modal-body .import-contact-option-container .import-contact-import-contact button.no-border:hover {
            color: #e87200 !important;
            background: transparent; }
            .import-contact-modal .modal-body .import-contact-option-container .import-contact-import-contact button.no-border:hover svg {
              color: #e87200; }
          .import-contact-modal .modal-body .import-contact-option-container .import-contact-import-contact button.no-border svg {
            position: relative;
            font-size: 12px;
            top: -1px;
            margin-right: 4px;
            -webkit-transition: ease-in-out 0.3s;
            transition: ease-in-out 0.3s; }

.importing-contact-modal .modal-content {
  width: 450px; }

.importing-contact-modal .modal-body {
  padding: 32px; }
  .importing-contact-modal .modal-body .importing-contact-wrapper {
    position: relative; }
    .importing-contact-modal .modal-body .importing-contact-wrapper button {
      width: calc(50% - 6px);
      margin-right: 12px; }
      .importing-contact-modal .modal-body .importing-contact-wrapper button:nth-last-child(1) {
        margin: 0; }
    .importing-contact-modal .modal-body .importing-contact-wrapper .integration-list-loading-wrapper {
      position: relative;
      width: 100%;
      height: 50px; }
      .importing-contact-modal .modal-body .importing-contact-wrapper .integration-list-loading-wrapper .lds-ring {
        width: 50px;
        height: 50px;
        position: relative;
        left: 50%;
        top: 20px;
        -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%); }
        .importing-contact-modal .modal-body .importing-contact-wrapper .integration-list-loading-wrapper .lds-ring div {
          border: 5px solid #fff;
          border-color: #ff7d00 transparent transparent transparent;
          width: 50px;
          height: 50px;
          margin: 0; }
    .importing-contact-modal .modal-body .importing-contact-wrapper .importing-text {
      margin: 12px 0;
      font-size: 14px;
      text-align: center; }
    .importing-contact-modal .modal-body .importing-contact-wrapper .retry-button {
      width: 100%;
      height: 40px; }
      .importing-contact-modal .modal-body .importing-contact-wrapper .retry-button svg {
        margin-right: 4px; }
    .importing-contact-modal .modal-body .importing-contact-wrapper .warning-svg {
      display: flex;
      justify-content: center; }
      .importing-contact-modal .modal-body .importing-contact-wrapper .warning-svg svg {
        color: #FF7E00;
        font-size: 50px; }

.modal-confirmation-upload-contact button {
  width: calc(50% - 2px) !important;
  margin: 0 4px 0 0; }
  .modal-confirmation-upload-contact button:nth-last-child(1) {
    margin: 0; }

.detail-row-container {
  padding: 32px 30px;
  text-align: left; }
  .detail-row-container .import-reason {
    margin: 0; }
  .detail-row-container .import-status-top-wrapper {
    width: 100%;
    margin: 24px 0 0;
    position: relative; }
  .detail-row-container .contact-history-filter {
    width: 181px;
    vertical-align: top;
    text-align: center;
    border: solid 1px #dcdcdc;
    border-radius: 8px;
    height: 47px;
    cursor: pointer;
    padding-top: 10px;
    position: absolute;
    left: 311px;
    top: 0; }
    .detail-row-container .contact-history-filter img {
      position: relative;
      margin-right: 4px;
      top: -1px; }
  .detail-row-container .import-status-count-wrapper {
    width: 100%;
    border-radius: 8px;
    border: solid 1px rgba(25, 25, 25, 0.1);
    padding: 16px 32px;
    margin: 24px 0; }
    .detail-row-container .import-status-count-wrapper .import-status-count-wrapper-inner {
      margin-right: 24px;
      width: calc(25% - 18px);
      display: inline-block;
      position: relative;
      padding-left: 16px; }
      .detail-row-container .import-status-count-wrapper .import-status-count-wrapper-inner img {
        position: absolute;
        top: 4px;
        left: 0; }
      .detail-row-container .import-status-count-wrapper .import-status-count-wrapper-inner b {
        font-size: 14px;
        letter-spacing: -0.4px; }
      .detail-row-container .import-status-count-wrapper .import-status-count-wrapper-inner p {
        font-size: 16px;
        letter-spacing: -0.3px;
        width: 100%;
        margin: 0;
        display: block; }
      .detail-row-container .import-status-count-wrapper .import-status-count-wrapper-inner:nth-child(1) {
        margin-right: 0;
        padding-left: 18px; }
      .detail-row-container .import-status-count-wrapper .import-status-count-wrapper-inner:nth-child(3) {
        margin-right: 0;
        padding-left: 20px; }
      .detail-row-container .import-status-count-wrapper .import-status-count-wrapper-inner:nth-last-child(1) {
        margin-right: 0;
        padding-left: 14px; }
        .detail-row-container .import-status-count-wrapper .import-status-count-wrapper-inner:nth-last-child(1) img {
          top: 7px; }
  .detail-row-container .comp-back-button {
    margin: 0 0 16px 0;
    font-family: PTRootBold; }
  .detail-row-container .detail-row-box {
    background: #ffffff;
    border-radius: 8px;
    padding: 32px;
    border: solid 1px rgba(25, 25, 25, 0.1); }
    .detail-row-container .detail-row-box .lds-ring {
      margin-top: 15px;
      width: 38px;
      height: 38px; }
      .detail-row-container .detail-row-box .lds-ring div {
        width: 38px;
        height: 38px;
        border: solid 3px #ff7e00;
        border-color: #ff7e00 transparent transparent transparent; }
    .detail-row-container .detail-row-box .col-channel {
      position: relative;
      padding: 8px 8px 8px 28px; }
      .detail-row-container .detail-row-box .col-channel img {
        position: absolute;
        left: 0;
        margin-right: 4px;
        top: 6px;
        width: 24px;
        height: 24px; }
    .detail-row-container .detail-row-box .status-badge {
      border-radius: 4px;
      padding: 3px 9px;
      font-size: 12px;
      letter-spacing: -0.4px;
      text-align: left;
      display: inline-block; }
      .detail-row-container .detail-row-box .status-badge svg {
        position: relative;
        margin-right: 4px;
        top: -1px;
        font-size: 13px;
        right: unset; }
      .detail-row-container .detail-row-box .status-badge.badge-open {
        color: #fff;
        background: #19c700;
        width: 63px; }
        .detail-row-container .detail-row-box .status-badge.badge-open svg {
          color: #fff; }
      .detail-row-container .detail-row-box .status-badge.badge-resolved {
        color: rgba(25, 25, 25, 0.6);
        background: rgba(25, 25, 25, 0.05);
        width: 81px; }
        .detail-row-container .detail-row-box .status-badge.badge-resolved svg {
          color: rgba(25, 25, 25, 0.6); }
    .detail-row-container .detail-row-box .button-new-message {
      padding: 0 12px 0 12px !important; }
    .detail-row-container .detail-row-box.detail-row-box-bottom {
      margin-top: 12px; }
    .detail-row-container .detail-row-box .table-detail-row thead tr {
      background: #ffffff; }
      .detail-row-container .detail-row-box .table-detail-row thead tr .col_name {
        width: unset; }
      .detail-row-container .detail-row-box .table-detail-row thead tr td:last-child {
        width: 90px; }
        .detail-row-container .detail-row-box .table-detail-row thead tr td:last-child .main-custom-select-option-wrapper {
          position: absolute;
          top: 6px;
          right: 16px; }
        .detail-row-container .detail-row-box .table-detail-row thead tr td:last-child .custom-select-option-value-wrapper {
          width: 55px !important;
          height: 23px;
          padding: 2px 8px 2px 12px !important;
          border-radius: 4px !important; }
          .detail-row-container .detail-row-box .table-detail-row thead tr td:last-child .custom-select-option-value-wrapper p {
            font-size: 12px;
            font-weight: bold; }
          .detail-row-container .detail-row-box .table-detail-row thead tr td:last-child .custom-select-option-value-wrapper .custom-select-option-value {
            padding: 0; }
          .detail-row-container .detail-row-box .table-detail-row thead tr td:last-child .custom-select-option-value-wrapper svg {
            right: 3px !important;
            height: 15px;
            top: 11px !important;
            position: absolute !important;
            margin-right: unset !important; }
    .detail-row-container .detail-row-box .table-detail-row tbody td {
      position: relative; }
      .detail-row-container .detail-row-box .table-detail-row tbody td.col_name {
        width: unset; }
      .detail-row-container .detail-row-box .table-detail-row tbody td.col_status P {
        font-size: 12px !important; }
      .detail-row-container .detail-row-box .table-detail-row tbody td.col_responded b {
        font-size: 12px !important;
        position: relative;
        top: 2px; }
      .detail-row-container .detail-row-box .table-detail-row tbody td:nth-child(1) div {
        display: flex; }
        .detail-row-container .detail-row-box .table-detail-row tbody td:nth-child(1) div p {
          font-size: 12px !important;
          letter-spacing: -0.4px; }
        .detail-row-container .detail-row-box .table-detail-row tbody td:nth-child(1) div img {
          margin-right: 4px;
          margin-top: 2px; }
      .detail-row-container .detail-row-box .table-detail-row tbody td:last-child {
        white-space: nowrap; }
        .detail-row-container .detail-row-box .table-detail-row tbody td:last-child a {
          color: #ff7e00; }
        .detail-row-container .detail-row-box .table-detail-row tbody td:last-child svg {
          position: relative;
          color: #ff7e00;
          font-size: 16px;
          margin-right: 4px;
          right: 0;
          top: -1px; }
      .detail-row-container .detail-row-box .table-detail-row tbody td .medium-logo {
        width: 16px;
        height: 16px; }
    .detail-row-container .detail-row-box .detail-row-table-wrapper-outer {
      position: relative; }
      .detail-row-container .detail-row-box .detail-row-table-wrapper-outer .freeze-tdhead {
        border-right: solid 1px #dcdcdc;
        position: absolute;
        left: 0;
        z-index: 1;
        background: #fff;
        width: 40px; }
      .detail-row-container .detail-row-box .detail-row-table-wrapper-outer .freeze-tdbody {
        border-right: solid 1px #dcdcdc;
        position: absolute;
        left: 0;
        z-index: 1;
        width: 40px; }
      .detail-row-container .detail-row-box .detail-row-table-wrapper-outer .detail-row-table-wrapper {
        overflow-x: auto;
        min-height: 200px; }
    .detail-row-container .detail-row-box .detail-row-box-top {
      padding-bottom: 15px;
      border-bottom: solid 1px #dcdcdc;
      position: relative;
      font-size: 20px;
      letter-spacing: -0.6px;
      width: 100%;
      display: block;
      justify-content: space-between; }
      .detail-row-container .detail-row-box .detail-row-box-top .button-wrapper button {
        font-size: 16px; }
      .detail-row-container .detail-row-box .detail-row-box-top .title-wrapper {
        display: inline-block;
        align-items: center;
        margin-bottom: 8px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        width: calc(100% - 368px);
        vertical-align: top; }
        .detail-row-container .detail-row-box .detail-row-box-top .title-wrapper b {
          position: relative;
          top: 2px; }
        .detail-row-container .detail-row-box .detail-row-box-top .title-wrapper svg {
          margin-right: 4px;
          font-size: 24px; }
      .detail-row-container .detail-row-box .detail-row-box-top .button-wrapper {
        vertical-align: top;
        display: inline-block;
        width: 368px;
        text-align: right; }
        .detail-row-container .detail-row-box .detail-row-box-top .button-wrapper button {
          margin-left: 4px;
          min-width: 180px; }
    .detail-row-container .detail-row-box .detail-row-box-form {
      width: 100%; }
      .detail-row-container .detail-row-box .detail-row-box-form:first-child {
        margin-top: 24px; }
      .detail-row-container .detail-row-box .detail-row-box-form .contact-attribute-wrapper {
        width: 300px;
        margin-bottom: 24px; }
        .detail-row-container .detail-row-box .detail-row-box-form .contact-attribute-wrapper:first-child {
          margin-top: 24px; }
        .detail-row-container .detail-row-box .detail-row-box-form .contact-attribute-wrapper:nth-last-child(1) {
          margin-bottom: 0 !important; }
        .detail-row-container .detail-row-box .detail-row-box-form .contact-attribute-wrapper .contact-title-wrapper {
          display: flex;
          align-items: center;
          margin-bottom: 4px; }
          .detail-row-container .detail-row-box .detail-row-box-form .contact-attribute-wrapper .contact-title-wrapper img, .detail-row-container .detail-row-box .detail-row-box-form .contact-attribute-wrapper .contact-title-wrapper svg {
            margin-right: 4px; }
        .detail-row-container .detail-row-box .detail-row-box-form .contact-attribute-wrapper p {
          white-space: pre-wrap;
          word-break: break-word; }
      .detail-row-container .detail-row-box .detail-row-box-form .contact-origin {
        margin: 0; }
        .detail-row-container .detail-row-box .detail-row-box-form .contact-origin img {
          margin-right: 8px;
          position: relative;
          top: -2px;
          width: 20px; }
      .detail-row-container .detail-row-box .detail-row-box-form label {
        padding: 24px 0 0;
        font-size: 14px;
        letter-spacing: -0.4px;
        display: block; }
        .detail-row-container .detail-row-box .detail-row-box-form label b span {
          font-family: PTRootUI, 'sans-serif';
          color: rgba(25, 25, 25, 0.6); }
      .detail-row-container .detail-row-box .detail-row-box-form .contact-custom-field-separator {
        margin-bottom: 24px;
        width: 100%;
        height: 1px;
        background-color: #1919191A; }
      .detail-row-container .detail-row-box .detail-row-box-form .warning-error-red {
        color: #ff3f57;
        font-size: 12px;
        letter-spacing: -0.4px;
        margin: 4px 0 0; }
        .detail-row-container .detail-row-box .detail-row-box-form .warning-error-red svg {
          font-size: 14px;
          position: relative;
          top: -2px;
          margin-right: 4px;
          color: #ff3f57 !important;
          cursor: unset !important; }
      .detail-row-container .detail-row-box .detail-row-box-form .form-contact-fullname,
      .detail-row-container .detail-row-box .detail-row-box-form .form-contact-alias,
      .detail-row-container .detail-row-box .detail-row-box-form .form-contact-email {
        width: 320px; }
        .detail-row-container .detail-row-box .detail-row-box-form .form-contact-fullname input[type='text'],
        .detail-row-container .detail-row-box .detail-row-box-form .form-contact-alias input[type='text'],
        .detail-row-container .detail-row-box .detail-row-box-form .form-contact-email input[type='text'] {
          padding: 12px 16px;
          border-radius: 6px;
          border: solid 1px #dcdcdc;
          width: 100%;
          font-size: 16px;
          letter-spacing: -0.3px;
          display: block; }
        .detail-row-container .detail-row-box .detail-row-box-form .form-contact-fullname input[type='text']:focus,
        .detail-row-container .detail-row-box .detail-row-box-form .form-contact-alias input[type='text']:focus,
        .detail-row-container .detail-row-box .detail-row-box-form .form-contact-email input[type='text']:focus {
          border: solid 1px #ff7e00; }
        .detail-row-container .detail-row-box .detail-row-box-form .form-contact-fullname input[type='text']:disabled,
        .detail-row-container .detail-row-box .detail-row-box-form .form-contact-alias input[type='text']:disabled,
        .detail-row-container .detail-row-box .detail-row-box-form .form-contact-email input[type='text']:disabled {
          background-color: rgba(25, 25, 25, 0.1) !important;
          color: rgba(25, 25, 25, 0.4) !important; }
      .detail-row-container .detail-row-box .detail-row-box-form .form-contact-phonenumber {
        width: 320px; }
        .detail-row-container .detail-row-box .detail-row-box-form .form-contact-phonenumber .input-phonenumber-container {
          padding: 0 16px;
          border-radius: 6px;
          border: solid 1px #dcdcdc;
          width: 100%;
          font-size: 16px;
          letter-spacing: -0.3px;
          display: flex;
          align-items: center; }
          .detail-row-container .detail-row-box .detail-row-box-form .form-contact-phonenumber .input-phonenumber-container.phone-focus {
            border: solid 1px #ff7e00; }
          .detail-row-container .detail-row-box .detail-row-box-form .form-contact-phonenumber .input-phonenumber-container.phone-disabled {
            background-color: rgba(25, 25, 25, 0.1);
            color: rgba(25, 25, 25, 0.4); }
          .detail-row-container .detail-row-box .detail-row-box-form .form-contact-phonenumber .input-phonenumber-container label {
            padding: 0;
            margin: 0 4px 0 0;
            font-size: 16px; }
          .detail-row-container .detail-row-box .detail-row-box-form .form-contact-phonenumber .input-phonenumber-container .form-phonenumber {
            padding: 0;
            height: 50px;
            border: none; }
          .detail-row-container .detail-row-box .detail-row-box-form .form-contact-phonenumber .input-phonenumber-container .form-phonenumber:disabled {
            color: rgba(25, 25, 25, 0.4) !important; }
        .detail-row-container .detail-row-box .detail-row-box-form .form-contact-phonenumber .alert-wrapper {
          display: flex;
          margin-top: 4px; }
          .detail-row-container .detail-row-box .detail-row-box-form .form-contact-phonenumber .alert-wrapper svg {
            margin-right: 4px;
            font-size: 20px; }
          .detail-row-container .detail-row-box .detail-row-box-form .form-contact-phonenumber .alert-wrapper p {
            font-size: 12px; }
      .detail-row-container .detail-row-box .detail-row-box-form button.add-contact-button {
        width: 132px;
        height: 40px;
        margin-top: 8px; }
      .detail-row-container .detail-row-box .detail-row-box-form button.add-contact-button:disabled {
        background-color: rgba(25, 25, 25, 0.1) !important;
        color: rgba(25, 25, 25, 0.4) !important; }
      .detail-row-container .detail-row-box .detail-row-box-form button.loading-button {
        width: 181px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center; }
      .detail-row-container .detail-row-box .detail-row-box-form button.loading-button:disabled {
        background-color: rgba(25, 25, 25, 0.1) !important;
        color: rgba(25, 25, 25, 0.4) !important; }

.modal-select-status-contacts .modal-content {
  width: 554px;
  border-radius: 8px; }
  .modal-select-status-contacts .modal-content .modal-body {
    padding: 24px; }
    .modal-select-status-contacts .modal-content .modal-body .title-filter {
      font-size: 20px;
      letter-spacing: -0.6px;
      margin: 0 0 24px 0; }
      .modal-select-status-contacts .modal-content .modal-body .title-filter img {
        position: relative;
        margin-right: 4px;
        top: -1px; }
    .modal-select-status-contacts .modal-content .modal-body label {
      margin: 0 0 8px 0;
      font-size: 16px;
      letter-spacing: -0.5px; }
    .modal-select-status-contacts .modal-content .modal-body .dropdown-option-wrapper button {
      padding: 8px 16px 8px 35px !important; }
    .modal-select-status-contacts .modal-content .modal-body .filter-button-wrapper {
      text-align: right;
      width: 100%;
      position: relative; }
      .modal-select-status-contacts .modal-content .modal-body .filter-button-wrapper .clear-selection-button {
        color: var(--main-dark-orange);
        cursor: pointer;
        font-size: 14px;
        letter-spacing: -0.5px;
        position: absolute;
        left: 0;
        top: 32px; }
        .modal-select-status-contacts .modal-content .modal-body .filter-button-wrapper .clear-selection-button svg {
          position: relative;
          margin-right: 4px; }
        .modal-select-status-contacts .modal-content .modal-body .filter-button-wrapper .clear-selection-button:hover {
          color: var(--main-darker-orange); }
      .modal-select-status-contacts .modal-content .modal-body .filter-button-wrapper .orange-button, .modal-select-status-contacts .modal-content .modal-body .filter-button-wrapper .no-fill-button {
        width: 181px;
        margin: 24px 0 0 4px; }

.modal-select-status-contacts .dropdown-option-wrapper .option-badge {
  top: 12px !important; }

.main-custom-select-option-with-label-wrapper {
  position: relative; }
  .main-custom-select-option-with-label-wrapper button:disabled {
    background-color: rgba(25, 25, 25, 0.1) !important;
    color: rgba(25, 25, 25, 0.4) !important; }
  .main-custom-select-option-with-label-wrapper.error-select .dropdown button {
    border-color: #ff3f57 !important; }
  .main-custom-select-option-with-label-wrapper .custom-select-option-value-wrapper {
    border-radius: 8px !important;
    width: 100% !important;
    padding: 12px 38px 12px 16px !important;
    position: relative;
    border: solid 1px #1919191A !important;
    background-color: #ffffff !important;
    z-index: 1;
    text-align: left !important;
    outline: none !important;
    box-shadow: none !important; }
    .main-custom-select-option-with-label-wrapper .custom-select-option-value-wrapper.border-red {
      border: solid 1px #ff3f57 !important; }
    .main-custom-select-option-with-label-wrapper .custom-select-option-value-wrapper .custom-select-option-value .option-badge {
      height: 22px;
      position: relative;
      left: 0;
      top: 1px;
      font-size: 12px;
      letter-spacing: -0.4px;
      color: #fff;
      padding: 2px 8px 0;
      display: inline-block;
      border-radius: 4px;
      margin-right: 4px;
      vertical-align: top; }
  .main-custom-select-option-with-label-wrapper .main-custom-select-option-with-label-wrapper.active-dropdown
.custom-select-option-value-wrapper {
    border: solid 1px #ff7e00; }
  .main-custom-select-option-with-label-wrapper .custom-select-option-value-wrapper p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
    margin: 0 !important;
    letter-spacing: -0.2px;
    color: #191919;
    font-size: 16px;
    padding-right: 24px; }
  .main-custom-select-option-with-label-wrapper .custom-select-option-value-wrapper .icon-arrow {
    position: absolute;
    right: 13px;
    top: 50%;
    -webkit-transform: translate(0, -50%);
            transform: translate(0, -50%);
    -webkit-transition: ease-in-out 0.3s;
    transition: ease-in-out 0.3s;
    font-size: 24px;
    color: #191919; }
  .main-custom-select-option-with-label-wrapper .main-custom-select-option-with-label-wrapper.active-dropdown
.custom-select-option-value-wrapper
.icon-arrow {
    -webkit-transform: translate(0, -50%) rotate(180deg);
            transform: translate(0, -50%) rotate(180deg); }
  .main-custom-select-option-with-label-wrapper .custom-select-option-placeholder {
    color: rgba(25, 25, 25, 0.4) !important; }
  .main-custom-select-option-with-label-wrapper .dropdown-option-wrapper {
    /* position: absolute;
      top: 64px;
      left: 0; */
    border-radius: 8px !important;
    width: 100%;
    max-height: 150px;
    overflow-y: auto;
    padding: 5px 0;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.2);
    z-index: 2;
    background: #ffffff;
    display: none;
    outline: none; }
  .main-custom-select-option-with-label-wrapper .main-custom-select-option-with-label-wrapper.active-dropdown .dropdown-option-wrapper {
    display: block;
    z-index: 3; }
  .main-custom-select-option-with-label-wrapper .dropdown-option-wrapper {
    margin: 0;
    padding: 0;
    list-style: none; }
  .main-custom-select-option-with-label-wrapper .dropdown-option-wrapper .dropdown-item {
    font-size: 16px;
    white-space: nowrap;
    max-width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
    color: #191919;
    padding: 10px 16px 10px 16px !important;
    position: relative;
    cursor: pointer;
    height: 54px; }
    .main-custom-select-option-with-label-wrapper .dropdown-option-wrapper .dropdown-item.dropdown-item-active {
      padding: 10px 16px 10px 35px !important; }
    .main-custom-select-option-with-label-wrapper .dropdown-option-wrapper .dropdown-item:hover {
      background: #ffe7d0; }
    .main-custom-select-option-with-label-wrapper .dropdown-option-wrapper .dropdown-item:active {
      background: none;
      color: #191919; }
    .main-custom-select-option-with-label-wrapper .dropdown-option-wrapper .dropdown-item .option-badge {
      height: 22px;
      position: relative;
      left: 0;
      top: 0;
      font-size: 12px;
      letter-spacing: -0.4px;
      color: #fff;
      padding: 2px 8px 0;
      display: inline-block;
      border-radius: 4px;
      margin-right: 4px;
      vertical-align: top; }
    .main-custom-select-option-with-label-wrapper .dropdown-option-wrapper .dropdown-item p {
      display: inline-block;
      margin: 0;
      position: relative;
      top: -1px; }
  .main-custom-select-option-with-label-wrapper .dropdown-option-wrapper .active-value {
    color: #191919;
    padding: 8px 16px 8px 35px !important; }
  .main-custom-select-option-with-label-wrapper .dropdown-option-wrapper .active-value::before {
    position: absolute;
    left: 12px;
    top: 50%;
    -webkit-transform: translate(0, -50%);
            transform: translate(0, -50%);
    color: #ff7e00;
    width: 16px;
    height: 16px;
    background-image: url(/static/media/icon-check-orange.bc571b04.svg);
    background-size: 16px;
    content: ""; }
  .main-custom-select-option-with-label-wrapper .dropdown-option-wrapper .active-multiple::before {
    position: absolute;
    left: 8px;
    top: 50%;
    -webkit-transform: translate(0, -50%);
            transform: translate(0, -50%);
    width: 20px;
    height: 20px;
    background-image: url(/static/media/icon-checkbox-active.9e36e90b.svg);
    background-size: 20px;
    content: ""; }
  .main-custom-select-option-with-label-wrapper .dropdown-option-wrapper .inactive-multiple::before {
    position: absolute;
    left: 8px;
    top: 50%;
    -webkit-transform: translate(0, -50%);
            transform: translate(0, -50%);
    width: 20px;
    height: 20px;
    background-image: url(/static/media/icon-checkbox-inactive.483e3068.svg);
    background-size: 20px;
    content: ""; }
  .main-custom-select-option-with-label-wrapper .dropdown-option-wrapper .no-data-option {
    text-align: center;
    color: gray;
    padding: 10px 16px !important; }
  .main-custom-select-option-with-label-wrapper .loading-custom-select {
    display: inline-block;
    right: 5px;
    top: 50%;
    -webkit-transform: translate(0, -50%);
            transform: translate(0, -50%);
    position: absolute; }
  .main-custom-select-option-with-label-wrapper .dropdown-item-page .dropdown {
    width: 70px; }
  .main-custom-select-option-with-label-wrapper .dropdown-item-page .dropdown-menu {
    width: 70px;
    min-width: unset;
    -webkit-transform: unset !important;
            transform: unset !important;
    right: 0px;
    bottom: auto;
    left: 0 !important;
    top: 22px !important;
    border-radius: 4px !important;
    bottom: unset !important; }
    .main-custom-select-option-with-label-wrapper .dropdown-item-page .dropdown-menu .dropdown-item {
      padding: 0px 6px 0px 35px !important;
      font-size: 14px; }

.case-report-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #f3f3f3;
  position: relative;
  left: 50%;
  height: 100vh;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%); }
  .case-report-container .case-logo {
    margin-bottom: 42px; }
  .case-report-container .case-report-title-wrapper {
    display: flex;
    margin-bottom: 32px; }
    .case-report-container .case-report-title-wrapper .icon-wrapper {
      width: 48px;
      height: 48px;
      border-radius: 50%;
      background-color: #e7e5ff;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 8px; }
  .case-report-container .case-description {
    width: 500px;
    text-align: center;
    font-size: 16px;
    margin-bottom: 32px;
    line-height: 1.5;
    letter-spacing: -0.3px; }
  .case-report-container .case-report-input-container {
    width: 500px;
    background-color: #ffffff;
    border-radius: 8px;
    padding: 32px; }
    .case-report-container .case-report-input-container .month-select {
      margin-bottom: 32px; }
    .case-report-container .case-report-input-container .year-select {
      margin-bottom: 32px; }

.failed-modal .modal-content {
  width: 476px; }

.failed-modal .modal-body {
  padding: 32px; }
  .failed-modal .modal-body .confirm-modal-wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center; }
    .failed-modal .modal-body .confirm-modal-wrapper svg {
      width: 64px;
      height: 64px;
      color: #ffb439; }
    .failed-modal .modal-body .confirm-modal-wrapper .confirm-description {
      font-size: 14px;
      letter-spacing: -0.2px;
      color: #000000;
      line-height: 21px;
      margin: 8px 0;
      text-align: center; }

.chat-view-container {
  width: 100%;
  box-sizing: border-box;
  position: relative;
  max-height: 100vh;
  height: 100vh;
  font-size: 0;
  overflow: hidden; }
  .chat-view-container .tier-home-wrapper {
    left: 0;
    width: 100%; }

.main-chat-container {
  height: 100%;
  position: relative;
  /* top: -5px; */
  font-size: 16px; }

.modal-refresh-on-reconnect {
  width: 100vw !important;
  background: rgba(25, 25, 25, 0.8); }
  .modal-refresh-on-reconnect .modal-content {
    width: 476px;
    border: 8px;
    padding: 32px; }
  .modal-refresh-on-reconnect .modal-body {
    padding: 0; }
    .modal-refresh-on-reconnect .modal-body p:nth-child(1) {
      font-size: 20px;
      line-height: 24px;
      letter-spacing: -0.6px;
      margin: 0 0 4px; }
    .modal-refresh-on-reconnect .modal-body p:nth-child(2) {
      margin: 0;
      font-size: 14px;
      line-height: 21px;
      letter-spacing: -0.2px;
      color: rgba(25, 25, 25, 0.6); }
  .modal-refresh-on-reconnect .modal-footer {
    padding: 24px 0 0;
    margin: 12px 0 0; }
    .modal-refresh-on-reconnect .modal-footer button {
      width: 100%;
      padding: 12px 0;
      font-size: 16px;
      letter-spacing: -0.5px;
      line-height: 1; }
      .modal-refresh-on-reconnect .modal-footer button svg {
        font-size: 20px;
        margin-right: 4px;
        position: relative;
        top: -1px; }

.room-list-container {
  position: relative;
  /* width: 380px; */
  width: 382px;
  display: inline-block;
  background: #F2F2F2;
  box-shadow: 0px 0px 0px 1px rgba(25, 25, 25, 0.1);
  height: 100%;
  max-height: 100%;
  vertical-align: top;
  z-index: 2;
  overflow: hidden;
  /* border-right: solid 1px #dcdcdc; */ }

.room-list-chat-container {
  /* box-shadow: 0px 2px 20px 0px rgba(25, 25, 25, 0.05); */
  /* max-height: calc(100vh - 120px);
    overflow-y: auto; */
  /* height: 100%; */
  background: #f3f3f3;
  -webkit-transition: ease-in-out .2s;
  transition: ease-in-out .2s; }

.room-list-chat-container.solved-case-panel-active {
  max-height: calc(100vh - 104px); }

.room-list-chat-container .room-list-loading-wrapper {
  width: 100%;
  padding-top: 20px;
  text-align: center; }

.room-list-chat-container .room-list-loading-wrapper .lds-ring {
  top: 0;
  left: 0; }

.room-list-chat-container .room-list-loading-wrapper .lds-ring div {
  border-color: #ff7d00 transparent transparent transparent;
  margin: 0 !important; }

.room-list-chat-container .room-list-loading-wrapper b {
  color: #ff7d00; }

.room-list-chat-container .chat-list-wrapper {
  background: #ffffff;
  width: 100%;
  padding: 4px 0 0 12px;
  cursor: pointer;
  position: relative; }

.room-list-chat-container .chat-list-wrapper.chat-list-unassigned {
  position: relative; }

.room-list-chat-container .chat-list-wrapper.chat-list-unassigned::before {
  position: absolute;
  background: #ffc0c0;
  top: 0;
  left: 0;
  /* border-radius: 8px; */
  content: "";
  height: 100%;
  width: 100%; }

/* .room-list-chat-container .chat-list-wrapper:hover {
    background: rgb(25, 25, 25, 0.05);
  } */
.room-list-chat-container .chat-list-wrapper .chat-avatar-wrapper {
  /* width: 80px; */
  width: 32px;
  height: 32px;
  display: inline-block;
  vertical-align: top;
  text-align: center;
  position: relative; }

.room-list-chat-container .chat-list-wrapper .chat-avatar-wrapper img {
  width: 52px;
  height: 52px;
  display: inline-block;
  border-radius: 50%;
  object-fit: cover; }

.room-list-chat-container .chat-list-wrapper .chat-avatar-wrapper img.group-badge {
  /* width: 22px;
    height: 22px;
    position: absolute;
    bottom: 1px;
    right: 11px; */
  width: 32px;
  height: 32px; }

.room-list-chat-container .chat-list-wrapper .chat-avatar-wrapper .user-avatar-name {
  border-radius: 50%;
  width: 52px;
  height: 52px;
  display: inline-block;
  text-align: center;
  font-size: 20px;
  letter-spacing: -0.6px;
  color: white;
  line-height: 52px;
  background: #f9f9f9; }

.room-list-chat-container .chat-list-wrapper .dialog-message-wrapper {
  width: calc(100% - 41px);
  margin-left: 8px;
  display: inline-block;
  position: relative;
  border-bottom: solid 1px rgba(25, 25, 25, 0.1);
  padding: 0 0 5px;
  height: 100%;
  /* top: -1px; */ }

.room-list-chat-container .chat-list-wrapper .dialog-message-wrapper.no-margin-bottom {
  border: none; }

.room-list-chat-container .chat-list-wrapper:nth-last-child(1) .dialog-message-wrapper {
  /* border: none; */ }

.room-list-chat-container .chat-list-wrapper .dialog-message-wrapper .dialog-top {
  position: relative;
  width: 100%;
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
  padding-right: 77px; }

.room-list-chat-container .chat-list-wrapper .dialog-message-wrapper .dialog-top .room-list-room-name {
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
  margin-bottom: 2px;
  font-size: 16px;
  letter-spacing: -0.5px;
  line-height: 20px; }

.room-list-chat-container .chat-list-wrapper .dialog-message-wrapper .dialog-top .label-case-status {
  color: #ffffff;
  font-size: 10px;
  letter-spacing: 1.5px;
  height: 20px;
  border-radius: 8px;
  display: inline-block;
  text-align: center;
  padding: 2px 16px 0;
  vertical-align: top;
  max-width: calc(100% - 8px);
  overflow: hidden;
  text-overflow: ellipsis; }

.room-list-chat-container .chat-list-wrapper .dialog-message-wrapper .dialog-top .label-case-status.case-unassigned {
  background: #ff3f57; }

.room-list-chat-container .chat-list-wrapper .dialog-message-wrapper .dialog-top .label-case-status.case-taken {
  background: #0052ff; }

.room-list-chat-container .chat-list-wrapper .dialog-message-wrapper .dialog-top .label-case-status.case-my-case {
  background: #19c700; }

.room-list-chat-container .chat-list-wrapper .dialog-message-wrapper .dialog-top .label-case-status.case-solved {
  background: rgba(25, 25, 25, 0.2);
  color: rgba(25, 25, 25, 0.6); }

.room-list-chat-container .chat-list-wrapper .dialog-message-wrapper .dialog-top .dialog-date {
  color: rgba(25, 25, 25, 0.6);
  font-size: 12px;
  letter-spacing: -0.4px;
  /* line-height: 18px; */
  line-height: 1;
  padding-top: 6px;
  position: absolute;
  right: 12px;
  top: 0; }

.room-list-chat-container .chat-list-wrapper .dialog-message-wrapper .dialog-top b {
  /* display: block; */ }

.room-list-chat-container .chat-list-wrapper .dialog-message-wrapper .dialog-bottom {
  color: rgba(25, 25, 25, 0.6);
  font-size: 14px;
  letter-spacing: -0.2px;
  line-height: 21px;
  padding: 0 90px 0 0;
  position: relative;
  min-height: 32px; }

.room-list-chat-container .chat-list-wrapper .dialog-message-wrapper .dialog-bottom p {
  margin: 0;
  white-space: nowrap;
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
  line-height: 18px;
  color: #191919;
  line-height: 18px;
  max-width: 100%;
  white-space: nowrap;
  font-size: 14px;
  letter-spacing: -0.4px; }

.room-list-chat-container .chat-list-wrapper .dialog-message-wrapper .dialog-bottom .message-status {
  position: absolute;
  top: 4px;
  right: 12px; }

.room-list-chat-container .chat-list-wrapper .dialog-message-wrapper .dialog-bottom .message-status .unread-count-wrapper {
  background: #ff7d00;
  color: #ffffff;
  font-size: 12px;
  border-radius: 20px;
  height: 18px;
  min-width: 18px;
  text-align: center;
  line-height: 18px;
  padding: 0 5px;
  font-weight: bold;
  margin-top: 3px; }
  .room-list-chat-container .chat-list-wrapper .dialog-message-wrapper .dialog-bottom .message-status .unread-count-wrapper.white-unread-count-wrapper {
    background: #ffffff;
    color: #ff7d00; }

.room-list-chat-container .chat-list-wrapper .dialog-message-wrapper .dialog-bottom .message-status .need-reply-badge {
  font-size: 12px;
  letter-spacing: -0.4px;
  text-align: center;
  color: #ffffff;
  background: #ff3f57;
  border-radius: 12px;
  width: 74px;
  display: block;
  line-height: 1;
  padding: 4px 0; }
  .room-list-chat-container .chat-list-wrapper .dialog-message-wrapper .dialog-bottom .message-status .need-reply-badge svg {
    width: 12px;
    height: 12px;
    margin-right: 4px;
    vertical-align: -2px; }
  .room-list-chat-container .chat-list-wrapper .dialog-message-wrapper .dialog-bottom .message-status .need-reply-badge.white-need-reply-badge {
    background: #ffffff !important;
    color: #ff3f57 !important; }
  .room-list-chat-container .chat-list-wrapper .dialog-message-wrapper .dialog-bottom .message-status .need-reply-badge.white-junk-badge {
    background: #ffffff;
    color: #ff7e00; }

.chat-list-wrapper.active-typing .last-message-room-list {
  display: none; }

.chat-list-wrapper .typing-room-list {
  display: none; }

.chat-list-wrapper.active-typing .typing-room-list {
  display: block; }

.room-list-chat-container .last-message-room-list {
  color: rgba(25, 25, 25, 0.6) !important; }

.chat-list-wrapper.unassigned-room-list {
  background: #ffc0c0; }

.room-list-chat-container .chat-list-wrapper.active-need-reply::before {
  background: #ffc0c0;
  content: "";
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0; }

.room-list-chat-container .chat-list-wrapper.active-chat-list::before {
  background: #FF7E00;
  content: "";
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0; }

.room-list-chat-container .chat-list-wrapper.active-chat-list:hover::before {
  background-color: #FF7E00 !important; }

.room-list-chat-container .chat-list-wrapper.active-chat-list .dialog-message-wrapper .dialog-top .room-list-room-name {
  color: #ffffff; }

.room-list-chat-container .chat-list-wrapper.active-chat-list .dialog-message-wrapper .dialog-top .dialog-date {
  color: #ffffff; }

.room-list-chat-container .chat-list-wrapper.active-chat-list .dialog-message-wrapper .dialog-bottom .chat-room-room-name {
  color: #ffffff; }

.room-list-chat-container .chat-list-wrapper.active-chat-list .dialog-message-wrapper .dialog-bottom .last-message-room-list {
  color: #ffffff !important; }

.room-list-chat-container .chat-list-wrapper.active-chat-list .dialog-message-wrapper .case-tab-label-wrapper .more-label {
  color: #ffffff; }

.room-list-chat-container .chat-list-wrapper:hover::before {
  background: rgba(25, 25, 25, 0.1);
  content: "";
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0; }

.room-list-main-chat-container {
  border-bottom: solid 1px #dcdcdc; }

.case-room-search-filter-wrapper {
  position: relative;
  width: 100%;
  background: #ffffff;
  border-bottom: solid 1px #dcdcdc; }

.case-room-search-wrapper {
  background: #ffffff;
  width: 100%;
  position: relative;
  height: 52px;
  padding: 8px 12px; }

.submit-filter-button {
  background: #ffffff;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  border: solid 1px rgba(25, 25, 25, 0.1);
  position: absolute;
  top: 8px;
  right: 12px;
  height: 36px;
  width: 40px;
  -webkit-transition: none;
  transition: none; }

.submit-filter-button svg {
  position: relative;
  top: -2px; }

.case-room-search-wrapper input {
  border-radius: 8px;
  width: 100%;
  border: solid 1px rgba(25, 25, 25, 0.05);
  height: 36px;
  font-size: 14px;
  line-height: 1.5;
  letter-spacing: -0.2px;
  color: #191919;
  padding: 9px 45px 9px 12px;
  border: 0;
  background: rgba(25, 25, 25, 0.05);
  outline: none; }

.case-room-search-wrapper input:focus {
  border: solid 1px #ff7d00;
  background: #ffffff; }

.case-room-search-wrapper input:focus + .submit-filter-button {
  border-color: #ff7d00;
  background: #ff7d00; }

.case-room-search-wrapper input:focus + .submit-filter-button svg {
  color: #ffffff; }

.case-room-search-wrapper input::-webkit-input-placeholder {
  color: rgba(25, 25, 25, 0.4) !important;
  font-size: 12px !important; }

.case-room-search-wrapper input::-ms-input-placeholder {
  color: rgba(25, 25, 25, 0.4) !important;
  font-size: 12px !important; }

.case-room-search-wrapper input::placeholder {
  color: rgba(25, 25, 25, 0.4) !important;
  font-size: 12px !important; }

.case-room-search-wrapper .case-search-icon {
  position: absolute;
  top: 50%;
  left: 20px;
  font-size: 18px;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%);
  color: rgba(25, 25, 25, 0.4); }

.case-room-search-wrapper input:focus + .case-search-icon {
  color: #191919; }

.case-room-search-wrapper .case-search-close-icon {
  position: absolute;
  top: 50%;
  right: 20px;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%);
  font-size: 18px;
  color: #ff7d00;
  display: none; }

.case-room-search-wrapper input:focus + .case-search-close-icon {
  display: block; }

.case-room-filter-tag-wrapper {
  width: 100%;
  height: 34px;
  padding: 0 12px; }

.case-room-filter-tag-badge {
  margin-right: 4px;
  border-radius: 4px;
  white-space: nowrap;
  display: inline-block;
  height: 22px;
  /* font-weight: bold; */
  border: solid 1px rgba(25, 25, 25, 0.1);
  cursor: pointer;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: -0.4px;
  background: #ffffff;
  padding: 0 6px;
  vertical-align: top;
  white-space: nowrap;
  margin-top: 5px; }
  .case-room-filter-tag-badge:hover {
    background: #f6f6f6; }

.case-room-filter-tag-badge-active {
  background: #ff7d00;
  color: #ffffff !important;
  border: solid 1px #ff7d00; }
  .case-room-filter-tag-badge-active svg {
    color: #fff !important; }
  .case-room-filter-tag-badge-active:hover {
    background: #e87200;
    border: solid 1px #e87200; }

.case-room-filter-tag-badge svg {
  margin-right: 3px;
  position: relative;
  top: -1px; }

.case-room-filter-tag-badge.active-filter-badge {
  color: #ffffff;
  background: #ff7d00; }

.agent-badge {
  font-size: 12px;
  line-height: 1.5;
  letter-spacing: -0.4px;
  color: #ffffff;
  border-radius: 4px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: inline-block;
  padding: 1px 12px 2px;
  font-weight: bold;
  margin-top: 2px;
  max-width: calc(100% - 12px); }

.agent-badge-wrapper {
  width: 100%;
  display: block;
  height: 24px; }

.agent-badge img {
  position: relative;
  top: -1px;
  margin-right: 4px; }

.agent-badge svg {
  position: relative;
  top: -1px;
  margin-right: 4px; }

.other-agent-badge {
  background: #0052ff; }

.my-agent-badge {
  background: #19c700; }

.unassigned-agent-badge {
  background: #C3C8D0;
  color: #ffffff; }

.resolved-agent-badge {
  color: rgba(25, 25, 25, 0.6);
  background: #f3f3f3; }

.other-case-room-wrapper .chat-list-wrapper .dialog-message-wrapper, .solved-case-room-wrapper .chat-list-wrapper .dialog-message-wrapper {
  padding: 0; }

.all-case-list.need-reply-show > div > div:nth-last-child(1) {
  margin-bottom: 55px; }

.need-reply-unread-panel-wrapper {
  width: 100%;
  position: relative;
  padding: 7px 22px;
  position: absolute;
  bottom: 0; }
  .need-reply-unread-panel-wrapper .need-reply-unread-panel-content {
    border-radius: 12px;
    background: transparent;
    height: 29px;
    font-size: 14px;
    letter-spacing: -0.4px;
    color: #ffffff;
    overflow: hidden;
    display: inline-block;
    width: 100%; }
    .need-reply-unread-panel-wrapper .need-reply-unread-panel-content .need-reply-panel {
      padding: 0 19px;
      display: inline-block;
      text-align: center;
      background: #FF3F57;
      height: 29px;
      line-height: 29px;
      display: inline-block;
      cursor: pointer;
      border-radius: 12px 0 0 12px; }
    .need-reply-unread-panel-wrapper .need-reply-unread-panel-content .unread-panel {
      width: 50%;
      display: inline-block;
      text-align: center;
      background: #ff7d00;
      height: 29px;
      line-height: 29px;
      cursor: pointer;
      border-radius: 0 12px 12px 0; }
      .need-reply-unread-panel-wrapper .need-reply-unread-panel-content .unread-panel .need-reply-unread-panel-counter {
        color: #ff7d00; }
    .need-reply-unread-panel-wrapper .need-reply-unread-panel-content .need-reply-unread-panel-counter {
      border-radius: 12px;
      background: #ffffff;
      letter-spacing: -0.4px;
      font-size: 12px;
      color: #FF3F57;
      min-width: 18px;
      height: 18px;
      text-align: center;
      display: inline-block;
      vertical-align: top;
      line-height: 18px;
      margin-left: 4px;
      position: relative;
      top: 5px;
      padding: 0 5px 0 4px; }

.room-list-connection-bar-container {
  height: 26px;
  position: relative;
  display: block;
  width: 100%;
  text-align: center;
  color: #ffffff;
  letter-spacing: 2.5px;
  font-size: 12px;
  line-height: 26px;
  padding: 0 12px;
  -webkit-transition: ease-in-out 0.3s;
  transition: ease-in-out 0.3s; }

.refresh-in {
  position: absolute;
  right: 12px;
  top: 50%;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%);
  letter-spacing: 0.6px; }
  .refresh-in button {
    position: relative;
    right: 0;
    top: -1px;
    height: 20px;
    border-radius: 4px;
    line-height: 17px; }

.room-list-connection-bar-container.status-connecting {
  background: #ff7d00; }

.room-list-connection-bar-container.status-waiting {
  background: #9B9B9B; }

.room-list-connection-bar-container.status-error {
  background: #FF3F57; }

.room-list-connection-bar-container.status-connected {
  background: #19C600; }

.room-list-connection-bar-container .lds-ring {
  width: 14px;
  height: 14px;
  margin-right: 10px;
  position: relative;
  top: 3px; }

.room-list-connection-bar-container .lds-ring div {
  width: 14px;
  height: 14px;
  margin: 0;
  border-color: #ffffff transparent transparent transparent; }

.room-list-connection-bar-container svg {
  margin-right: 6px;
  position: relative;
  top: -1px;
  font-size: 14px; }

.room-list-connection-bar-container.active-connection-bar ~ .room-list-chat-container {
  max-height: calc(100vh - 90px);
  overflow-y: auto; }

#miitelPhoneIFrameButton {
  display: none !important; }

#miitelPhoneIFrameOuter {
  display: none !important; }

.side-account-panel-wrapper {
  position: relative;
  width: 62px;
  height: 100%;
  background: #ffffff;
  display: inline-block;
  box-shadow: 1px 0 #dcdcdc;
  z-index: 4;
  vertical-align: top; }
  .side-account-panel-wrapper .side-account-button-wrapper {
    position: relative;
    width: 100%;
    border-bottom: solid 1px #dcdcdc;
    padding: 12px 5px;
    font-size: 10px;
    letter-spacing: -0.3px;
    color: rgba(25, 25, 25, 0.6);
    cursor: pointer;
    text-align: center; }
    .side-account-panel-wrapper .side-account-button-wrapper p {
      margin: 0;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis; }
    .side-account-panel-wrapper .side-account-button-wrapper svg {
      color: #ff7d00;
      font-size: 24px;
      margin-bottom: 4px; }
    .side-account-panel-wrapper .side-account-button-wrapper img {
      margin-bottom: 4px;
      width: 24px; }
    .side-account-panel-wrapper .side-account-button-wrapper.button-dashboard {
      border-bottom: 0;
      border-top: solid 1px #dcdcdc;
      position: absolute;
      bottom: 0;
      left: 0; }
    .side-account-panel-wrapper .side-account-button-wrapper:hover {
      color: rgba(25, 25, 25, 0.8); }
    .side-account-panel-wrapper .side-account-button-wrapper.side-panel-account-button:hover {
      background: #ededed; }
    .side-account-panel-wrapper .side-account-button-wrapper.side-panel-account-button:active {
      background: #FBF2EB;
      color: #ff7d00; }
  .side-account-panel-wrapper .account-avatar {
    border-radius: 50%;
    width: 32px;
    height: 32px;
    background: pink;
    text-align: center;
    line-height: 32px;
    font-size: 14px;
    color: #ffffff;
    display: inline-block;
    margin-bottom: 4px;
    position: relative; }
  .side-account-panel-wrapper .account-avatar-status-dot {
    width: 12px;
    height: 12px;
    background: #ffffff;
    border-radius: 50%;
    position: absolute;
    right: 0;
    bottom: 0;
    display: inline-block; }
    .side-account-panel-wrapper .account-avatar-status-dot .account-avatar-status-dot-inside {
      content: "";
      width: 8px;
      height: 8px;
      border-radius: 50%;
      position: absolute;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%); }
  .side-account-panel-wrapper .dot-green {
    background: #19C700; }
  .side-account-panel-wrapper .dot-yellow {
    background: #FFB438; }
  .side-account-panel-wrapper .dot-red {
    background: #FF3F57; }
  .side-account-panel-wrapper .account-avatar-image {
    width: 32px;
    height: 32px;
    display: inline-block; }
  .side-account-panel-wrapper .account-avatar-name {
    font-size: 14px;
    letter-spacing: -0.4px; }
  .side-account-panel-wrapper .popup-account-panel {
    width: 300px;
    padding: 8px 12px;
    border-radius: 8px;
    background: #ffffff;
    box-shadow: 0 0 20px 2px #eaeaea;
    position: absolute;
    left: 78px;
    top: 10px;
    cursor: default;
    text-align: left;
    visibility: visible;
    opacity: 1; }
    .side-account-panel-wrapper .popup-account-panel.deactive-popup-account-panel {
      visibility: hidden;
      opacity: 0; }
    .side-account-panel-wrapper .popup-account-panel .account-panel-name-company {
      position: relative;
      width: 100%;
      padding-bottom: 15px; }
      .side-account-panel-wrapper .popup-account-panel .account-panel-name-company .account-avatar {
        width: 40px;
        height: 40px;
        line-height: 40px; }
        .side-account-panel-wrapper .popup-account-panel .account-panel-name-company .account-avatar .account-avatar-status-dot {
          width: 14px;
          height: 14px; }
          .side-account-panel-wrapper .popup-account-panel .account-panel-name-company .account-avatar .account-avatar-status-dot .account-avatar-status-dot-inside {
            width: 10px;
            height: 10px; }
      .side-account-panel-wrapper .popup-account-panel .account-panel-name-company .account-avatar-name-company-content {
        width: calc(100% - 40px);
        display: inline-block;
        vertical-align: top;
        padding-left: 16px; }
        .side-account-panel-wrapper .popup-account-panel .account-panel-name-company .account-avatar-name-company-content p {
          margin: 0;
          font-size: 12px;
          letter-spacing: -0.2px;
          color: rgba(25, 25, 25, 0.6);
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap; }
          .side-account-panel-wrapper .popup-account-panel .account-panel-name-company .account-avatar-name-company-content p b {
            color: #191919;
            font-size: 16px;
            letter-spacing: -0.5px; }
    .side-account-panel-wrapper .popup-account-panel::before {
      content: "";
      position: absolute;
      width: 16px;
      height: 16px;
      -webkit-transform: rotate(45deg);
              transform: rotate(45deg);
      top: 18px;
      left: -8px;
      box-shadow: -4px 5px 5px 0px rgba(234, 232, 232, 0.2);
      background: #ffffff; }
    .side-account-panel-wrapper .popup-account-panel .account-panel-status-bar {
      margin-bottom: 9px;
      width: 100%;
      border-radius: 8px;
      font-size: 14px;
      letter-spacing: -0.4px;
      color: rgba(25, 25, 25, 0.8);
      padding: 12px 12px 12px 30px;
      position: relative;
      text-align: left;
      line-height: 1;
      cursor: pointer; }
      .side-account-panel-wrapper .popup-account-panel .account-panel-status-bar svg {
        color: #ff7d00;
        font-size: 16px;
        position: absolute;
        right: 12px;
        top: 50%;
        -webkit-transform: translate(0, -50%);
                transform: translate(0, -50%); }
      .side-account-panel-wrapper .popup-account-panel .account-panel-status-bar:nth-last-child(1) {
        margin-bottom: 0; }
      .side-account-panel-wrapper .popup-account-panel .account-panel-status-bar .account-avatar-status-dot {
        left: 12px;
        top: 50%;
        -webkit-transform: translate(0, -50%);
                transform: translate(0, -50%);
        margin-right: 8px; }
      .side-account-panel-wrapper .popup-account-panel .account-panel-status-bar:hover {
        background: #f3f3f3;
        color: #191919; }
      .side-account-panel-wrapper .popup-account-panel .account-panel-status-bar.active-status-bar {
        background: #FFF2E5;
        color: #ff7d00; }

.modal-confirmation-clock .modal-content {
  border-radius: 8px;
  padding: 32px;
  width: 476px; }

.modal-confirmation-clock .lds-ring {
  top: 4px;
  right: 0; }
  .modal-confirmation-clock .lds-ring div {
    border-color: #ffffff transparent transparent transparent;
    margin: 0; }

.modal-confirmation-clock button[disabled] .lds-ring div {
  border-color: #959595 transparent transparent transparent; }

.modal-confirmation-clock .modal-body {
  padding: 0; }
  .modal-confirmation-clock .modal-body b {
    font-size: 20px;
    line-height: 1.2;
    letter-spacing: -0.6px;
    color: #191919; }
  .modal-confirmation-clock .modal-body p {
    font-size: 14px;
    line-height: 1.5;
    letter-spacing: -0.2px;
    color: rgba(25, 25, 25, 0.6);
    margin: 6px 0 0; }

.modal-confirmation-clock .modal-footer {
  padding: 20px 0 0;
  border: none; }
  .modal-confirmation-clock .modal-footer button {
    width: calc(50% - 4px);
    display: inline-block;
    border-radius: 8px;
    border: none;
    height: 48px;
    line-height: 45px;
    margin: 0 8px 0 0; }
    .modal-confirmation-clock .modal-footer button:nth-last-child(1) {
      margin: 0; }
    .modal-confirmation-clock .modal-footer button svg {
      position: relative;
      font-size: 24px;
      top: -1px;
      margin-right: 3px; }

.room-list-global-search-wrapper {
  width: calc(100% - 62px);
  height: 100%;
  position: absolute;
  -webkit-transition: ease-in-out .2s;
  transition: ease-in-out .2s;
  top: 0;
  left: -100%;
  z-index: 4;
  overflow: hidden; }
  .room-list-global-search-wrapper .case-room-filter-tag-badge-active {
    background: #ff7d00;
    color: #ffffff !important;
    border: solid 1px #ff7d00; }
    .room-list-global-search-wrapper .case-room-filter-tag-badge-active svg {
      color: #fff !important; }
    .room-list-global-search-wrapper .case-room-filter-tag-badge-active:hover {
      background: #e87200;
      border: solid 1px #e87200; }
  .room-list-global-search-wrapper .submit-search {
    background: #ffffff;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    border: solid 1px rgba(25, 25, 25, 0.1);
    position: absolute;
    top: 0px;
    right: 28px;
    height: 36px;
    width: 28px;
    text-align: center;
    -webkit-transition: none;
    transition: none;
    font-size: 14px;
    cursor: pointer; }
    .room-list-global-search-wrapper .submit-search svg {
      position: absolute;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%); }
  .room-list-global-search-wrapper.room-list-global-search-wrapper-active {
    left: 62px; }
  .room-list-global-search-wrapper .room-list-global-search-header {
    height: 90px;
    width: 100%;
    position: relative;
    background: #ffffff;
    padding: 8px 12px;
    border-bottom: solid 1px #dcdcdc; }
    .room-list-global-search-wrapper .room-list-global-search-header .case-room-search-wrapper {
      padding: 0;
      -webkit-transition: ease-in-out .15s;
      transition: ease-in-out .15s;
      width: 100%;
      height: unset; }
      .room-list-global-search-wrapper .room-list-global-search-header .case-room-search-wrapper .clear-search-wrapper {
        width: 16px;
        height: 16px;
        border-radius: 50%;
        position: absolute;
        top: 50%;
        right: 58px;
        -webkit-transform: translate(0, -50%);
                transform: translate(0, -50%);
        background: #a3a3a3;
        cursor: pointer; }
        .room-list-global-search-wrapper .room-list-global-search-header .case-room-search-wrapper .clear-search-wrapper:hover {
          background: #8c8c8c; }
        .room-list-global-search-wrapper .room-list-global-search-header .case-room-search-wrapper .clear-search-wrapper svg {
          top: 50%;
          left: 50%;
          -webkit-transform: translate(-50%, -50%);
                  transform: translate(-50%, -50%);
          color: #ffffff;
          font-size: 12px;
          position: absolute; }
      .room-list-global-search-wrapper .room-list-global-search-header .case-room-search-wrapper #input-search-form {
        text-align: left; }
        .room-list-global-search-wrapper .room-list-global-search-header .case-room-search-wrapper #input-search-form input[type="text"] {
          padding: 9px 11px 9px 11px;
          width: calc(100% - 49px);
          margin-bottom: 12px; }
          .room-list-global-search-wrapper .room-list-global-search-header .case-room-search-wrapper #input-search-form input[type="text"]::-webkit-input-placeholder {
            font-size: 12px;
            color: rgba(25, 25, 25, 0.4); }
          .room-list-global-search-wrapper .room-list-global-search-header .case-room-search-wrapper #input-search-form input[type="text"]::-ms-input-placeholder {
            font-size: 12px;
            color: rgba(25, 25, 25, 0.4); }
          .room-list-global-search-wrapper .room-list-global-search-header .case-room-search-wrapper #input-search-form input[type="text"]::placeholder {
            font-size: 12px;
            color: rgba(25, 25, 25, 0.4); }
          .room-list-global-search-wrapper .room-list-global-search-header .case-room-search-wrapper #input-search-form input[type="text"].active-search {
            padding: 9px 31px 9px 11px; }
          .room-list-global-search-wrapper .room-list-global-search-header .case-room-search-wrapper #input-search-form input[type="text"]:focus {
            padding: 9px 32px 9px 11px; }
            .room-list-global-search-wrapper .room-list-global-search-header .case-room-search-wrapper #input-search-form input[type="text"]:focus ~ .submit-search {
              background: #ff7d00;
              color: #ffffff; }
        .room-list-global-search-wrapper .room-list-global-search-header .case-room-search-wrapper #input-search-form .clear-search-x {
          cursor: pointer;
          border-radius: 50%;
          position: absolute;
          top: 10px;
          right: 60px;
          width: 16px;
          height: 16px;
          background: #a3a3a3;
          color: #ffffff;
          z-index: -1; }
          .room-list-global-search-wrapper .room-list-global-search-header .case-room-search-wrapper #input-search-form .clear-search-x.active-clear-search-x {
            z-index: unset; }
          .room-list-global-search-wrapper .room-list-global-search-header .case-room-search-wrapper #input-search-form .clear-search-x svg {
            font-size: 12px;
            position: absolute;
            left: 50%;
            top: 50%;
            -webkit-transform: translate(-50%, -50%);
                    transform: translate(-50%, -50%); }
          .room-list-global-search-wrapper .room-list-global-search-header .case-room-search-wrapper #input-search-form .clear-search-x:hover {
            background: #999999; }
        .room-list-global-search-wrapper .room-list-global-search-header .case-room-search-wrapper #input-search-form .cancel-search {
          font-size: 14px;
          letter-spacing: -0.4px;
          color: #ff7d00;
          cursor: pointer;
          margin-left: 8px; }
          .room-list-global-search-wrapper .room-list-global-search-header .case-room-search-wrapper #input-search-form .cancel-search:hover {
            color: var(--main-darker-orange); }
      .room-list-global-search-wrapper .room-list-global-search-header .case-room-search-wrapper .submit-filter-button {
        right: 0; }
    .room-list-global-search-wrapper .room-list-global-search-header .room-list-global-search-header-bottom .reset-button {
      background: #ff7d00;
      border-color: #ff7d00;
      color: #ffffff; }
      .room-list-global-search-wrapper .room-list-global-search-header .room-list-global-search-header-bottom .reset-button:hover {
        border-color: #e87200;
        background: #e87200; }

.case-modal-filter-option {
  width: 100%; }
  .case-modal-filter-option .modal-content {
    width: 645px;
    max-height: 90%;
    overflow: auto; }
    .case-modal-filter-option .modal-content .modal-body {
      padding: 32px 32px 0; }
      .case-modal-filter-option .modal-content .modal-body label {
        font-size: 14px;
        letter-spacing: -0.4px;
        margin-top: 24px;
        font-size: 12px;
        letter-spacing: -0.34px; }
      .case-modal-filter-option .modal-content .modal-body .checkbox-input {
        padding-bottom: 0; }
        .case-modal-filter-option .modal-content .modal-body .checkbox-input label {
          margin-top: 0; }
        .case-modal-filter-option .modal-content .modal-body .checkbox-input [type="checkbox"]:checked + label:before {
          top: 2px; }
        .case-modal-filter-option .modal-content .modal-body .checkbox-input [type="checkbox"]:checked + label:after {
          top: -4px; }
      .case-modal-filter-option .modal-content .modal-body .case-filter-option-wrapper {
        margin-top: 0px; }
        .case-modal-filter-option .modal-content .modal-body .case-filter-option-wrapper .label-value {
          font-size: 12px;
          letter-spacing: -0.4px;
          display: inline-block;
          margin: 0 4px 4px 0;
          vertical-align: top;
          border-radius: 4px;
          color: #ffffff;
          padding: 2px 8px; }
          .case-modal-filter-option .modal-content .modal-body .case-filter-option-wrapper .label-value svg {
            position: relative;
            vertical-align: top;
            margin-right: 4px;
            top: 3px;
            color: #ffffff;
            cursor: pointer; }
        .case-modal-filter-option .modal-content .modal-body .case-filter-option-wrapper .case-label-container {
          position: relative; }
          .case-modal-filter-option .modal-content .modal-body .case-filter-option-wrapper .case-label-container .input-contact-container {
            padding: 12px 40px 12px 16px;
            border: 1px solid rgba(25, 25, 25, 0.1);
            border-radius: 8px;
            height: 98px;
            overflow: auto;
            position: relative;
            display: flex;
            flex-wrap: wrap; }
            .case-modal-filter-option .modal-content .modal-body .case-filter-option-wrapper .case-label-container .input-contact-container.focus {
              border: 1px solid #FF7E00; }
            .case-modal-filter-option .modal-content .modal-body .case-filter-option-wrapper .case-label-container .input-contact-container.blur {
              border: 1px solid rgba(25, 25, 25, 0.1); }
            .case-modal-filter-option .modal-content .modal-body .case-filter-option-wrapper .case-label-container .input-contact-container::-webkit-scrollbar {
              display: none; }
            .case-modal-filter-option .modal-content .modal-body .case-filter-option-wrapper .case-label-container .input-contact-container .label-value {
              height: -webkit-fit-content;
              height: -moz-fit-content;
              height: fit-content; }
              .case-modal-filter-option .modal-content .modal-body .case-filter-option-wrapper .case-label-container .input-contact-container .label-value svg {
                position: relative;
                top: 5px;
                margin-right: 5px;
                cursor: pointer; }
              .case-modal-filter-option .modal-content .modal-body .case-filter-option-wrapper .case-label-container .input-contact-container .label-value b {
                font-size: 14px;
                line-height: 1.5;
                letter-spacing: -0.4px; }
            .case-modal-filter-option .modal-content .modal-body .case-filter-option-wrapper .case-label-container .input-contact-container textarea {
              resize: none;
              width: 90%;
              border: none;
              padding: 0;
              position: relative; }
              .case-modal-filter-option .modal-content .modal-body .case-filter-option-wrapper .case-label-container .input-contact-container textarea::-webkit-scrollbar {
                display: none; }
            .case-modal-filter-option .modal-content .modal-body .case-filter-option-wrapper .case-label-container .input-contact-container .search-icon {
              position: absolute;
              right: 12px;
              top: 12px; }
              .case-modal-filter-option .modal-content .modal-body .case-filter-option-wrapper .case-label-container .input-contact-container .search-icon svg {
                font-size: 20px; }
          .case-modal-filter-option .modal-content .modal-body .case-filter-option-wrapper .case-label-container .add-label-suggestion-wrapper {
            max-height: 190px;
            width: 100%;
            border-radius: 8px;
            background: #ffffff;
            overflow: auto;
            position: absolute;
            box-shadow: 0 2px 20px 0 rgba(25, 25, 25, 0.05);
            display: none;
            z-index: 3; }
            .case-modal-filter-option .modal-content .modal-body .case-filter-option-wrapper .case-label-container .add-label-suggestion-wrapper .loading-wrapper {
              display: flex;
              justify-content: center;
              align-items: center;
              height: 40px; }
            .case-modal-filter-option .modal-content .modal-body .case-filter-option-wrapper .case-label-container .add-label-suggestion-wrapper ul {
              list-style: none;
              margin: 0;
              padding: 0; }
              .case-modal-filter-option .modal-content .modal-body .case-filter-option-wrapper .case-label-container .add-label-suggestion-wrapper ul li {
                width: 100%;
                padding: 8px 16px;
                cursor: pointer; }
                .case-modal-filter-option .modal-content .modal-body .case-filter-option-wrapper .case-label-container .add-label-suggestion-wrapper ul li:hover {
                  background: #FFF2E5; }
                .case-modal-filter-option .modal-content .modal-body .case-filter-option-wrapper .case-label-container .add-label-suggestion-wrapper ul li.no-option {
                  color: rgba(25, 25, 25, 0.6);
                  text-align: center;
                  font-size: 16px;
                  letter-spacing: -0.3px; }
                  .case-modal-filter-option .modal-content .modal-body .case-filter-option-wrapper .case-label-container .add-label-suggestion-wrapper ul li.no-option:hover {
                    background: none; }
                .case-modal-filter-option .modal-content .modal-body .case-filter-option-wrapper .case-label-container .add-label-suggestion-wrapper ul li .label-value {
                  margin: 0 4px 0 0; }
      .case-modal-filter-option .modal-content .modal-body .select-value-wrapper {
        border-radius: 8px;
        width: 100%;
        position: relative;
        font-size: 16px;
        letter-spacing: -0.3px;
        padding: 16px;
        border: solid 1px #dcdcdc; }
        .case-modal-filter-option .modal-content .modal-body .select-value-wrapper svg {
          position: absolute;
          right: 16px;
          font-size: 24px;
          top: 50%;
          -webkit-transform: translate(0, -50%);
                  transform: translate(0, -50%); }
      .case-modal-filter-option .modal-content .modal-body .show-case-type {
        display: grid;
        grid-template-columns: 110px 110px 110px; }
        .case-modal-filter-option .modal-content .modal-body .show-case-type .input-wrapper {
          padding-bottom: 0 !important;
          margin: 0; }
        .case-modal-filter-option .modal-content .modal-body .show-case-type label {
          margin-top: unset !important;
          font-weight: 700;
          padding-left: 24px !important; }
        .case-modal-filter-option .modal-content .modal-body .show-case-type label:before, .case-modal-filter-option .modal-content .modal-body .show-case-type label:after {
          top: 0px !important; }
      .case-modal-filter-option .modal-content .modal-body .filter-by-channel {
        position: relative;
        width: 100%; }
        .case-modal-filter-option .modal-content .modal-body .filter-by-channel div {
          display: inline-block;
          margin: 0 24px 0 0; }
          .case-modal-filter-option .modal-content .modal-body .filter-by-channel div label {
            line-height: 20px;
            margin: 0 0 12px 0; }
          .case-modal-filter-option .modal-content .modal-body .filter-by-channel div [type="checkbox"]:checked + label:after {
            line-height: 16px; }
        .case-modal-filter-option .modal-content .modal-body .filter-by-channel img {
          position: relative;
          margin-right: 4px;
          width: 18px;
          top: -1px; }
    .case-modal-filter-option .modal-content .modal-footer {
      border: none;
      padding: 20px 32px 32px; }
      .case-modal-filter-option .modal-content .modal-footer button {
        width: calc(50% - 4px);
        height: 48px;
        margin: 0 8px 0 0; }
        .case-modal-filter-option .modal-content .modal-footer button:nth-last-child(1) {
          margin: 0; }

.room-list-global-search-room-list {
  background: #f3f3f3;
  height: calc(100% - 90px);
  width: 100%;
  position: relative; }
  .room-list-global-search-room-list .list-search-case {
    overflow-x: hidden !important; }
  .room-list-global-search-room-list .room-list-global-search-room-wrapper .search-not-found {
    text-align: center;
    padding: 0 16px;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    width: 100%; }
    .room-list-global-search-room-list .room-list-global-search-room-wrapper .search-not-found b {
      font-size: 18px;
      letter-spacing: -0.5px; }
    .room-list-global-search-room-list .room-list-global-search-room-wrapper .search-not-found p {
      font-size: 16px;
      letter-spacing: -0.3px;
      color: rgba(25, 25, 25, 0.6);
      line-height: 24px; }
  .room-list-global-search-room-list .room-list-global-search-room-wrapper .search-for-result {
    color: #ff7d00;
    padding: 26px 82px; }
    .room-list-global-search-room-list .room-list-global-search-room-wrapper .search-for-result .lds-ring {
      top: 3px;
      right: unset;
      margin-right: 6px; }
      .room-list-global-search-room-list .room-list-global-search-room-wrapper .search-for-result .lds-ring div {
        margin: 0; }

.new-case-list-wrapper {
  width: 100%;
  display: block;
  position: relative;
  background: #fff;
  cursor: pointer; }
  .new-case-list-wrapper .click-area-case-list {
    position: absolute;
    height: 100%;
    width: calc(100% - 30px);
    display: inline-block;
    z-index: 1; }
  .new-case-list-wrapper:hover {
    background: #dddddd !important; }
  .new-case-list-wrapper .new-case-list-top {
    width: 100%;
    position: relative; }
    .new-case-list-wrapper .new-case-list-top .omnichannel-name {
      position: relative;
      padding: 5px 123px 5px 12px;
      font-size: 12px;
      letter-spacing: -0.2px;
      color: #191919;
      margin: 0;
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis; }
      .new-case-list-wrapper .new-case-list-top .omnichannel-name .omnichannel-icon {
        width: 16px;
        height: 16px;
        position: relative;
        margin-right: 4px;
        top: -1px; }
    .new-case-list-wrapper .new-case-list-top .need-reply-or-junk {
      position: absolute;
      top: 50%;
      -webkit-transform: translate(0, -50%);
              transform: translate(0, -50%);
      right: 12px;
      padding-right: 25px; }
      .new-case-list-wrapper .new-case-list-top .need-reply-or-junk .need-reply-or-junk-badge {
        padding: 0px 8px 0 8px;
        position: relative;
        background: #ff3f57;
        color: #fff;
        font-size: 10px;
        letter-spacing: 1.5px;
        border-radius: 100px;
        height: 16px;
        line-height: 16px; }
        .new-case-list-wrapper .new-case-list-top .need-reply-or-junk .need-reply-or-junk-badge svg {
          position: relative;
          margin-right: 4px;
          top: -1px; }
      .new-case-list-wrapper .new-case-list-top .need-reply-or-junk .custom-checkbox {
        display: inline-block;
        position: absolute;
        top: 50%;
        -webkit-transform: translate(0, -50%);
                transform: translate(0, -50%);
        right: -6px; }
        .new-case-list-wrapper .new-case-list-top .need-reply-or-junk .custom-checkbox label {
          top: -16px;
          margin: 0; }
      .new-case-list-wrapper .new-case-list-top .need-reply-or-junk .custom-checkbox-white {
        display: inline-block;
        position: absolute;
        top: 50%;
        -webkit-transform: translate(0, -50%);
                transform: translate(0, -50%);
        right: -6px; }
        .new-case-list-wrapper .new-case-list-top .need-reply-or-junk .custom-checkbox-white label {
          top: -16px;
          margin: 0; }
      .new-case-list-wrapper .new-case-list-top .need-reply-or-junk.need-reply-or-junk-no-check {
        padding-right: 0; }
  .new-case-list-wrapper .new-case-list-bottom {
    padding: 0 12px 5px 12px;
    border-bottom: solid 1px rgba(25, 25, 25, 0.2); }
    .new-case-list-wrapper .new-case-list-bottom .chat-roomname {
      white-space: nowrap;
      font-size: 16px;
      letter-spacing: -0.5px;
      position: relative;
      width: 100%;
      padding-right: 68px;
      overflow: hidden;
      text-overflow: ellipsis;
      margin: 0 0 7px 0;
      line-height: 1;
      height: 20px; }
      .new-case-list-wrapper .new-case-list-bottom .chat-roomname .chat-timestamp {
        color: rgba(25, 25, 25, 0.4);
        font-size: 12px;
        position: absolute;
        right: 0;
        top: 50%;
        -webkit-transform: translate(0, -50%);
                transform: translate(0, -50%);
        letter-spacing: -0.2px; }
    .new-case-list-wrapper .new-case-list-bottom .chat-case-id {
      color: rgba(25, 25, 25, 0.8);
      font-size: 12px;
      letter-spacing: -0.2px;
      line-height: 1;
      margin: 0;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
      white-space: nowrap;
      height: 13px; }
      .new-case-list-wrapper .new-case-list-bottom .chat-case-id b {
        color: rgba(25, 25, 25, 0.6);
        letter-spacing: -0.2px;
        font-size: 12px;
        font-family: PTRootUI !important; }
    .new-case-list-wrapper .new-case-list-bottom .typing-room-list {
      font-size: 14px;
      letter-spacing: -0.2px;
      color: #ff7d00;
      display: none;
      margin: 8px 0 0 0;
      position: relative;
      line-height: 18px; }
      .new-case-list-wrapper .new-case-list-bottom .typing-room-list .typing-name {
        color: rgba(25, 25, 25, 0.6); }
      .new-case-list-wrapper .new-case-list-bottom .typing-room-list .unread-count-wrapper {
        background: #ff7d00;
        color: #ffffff;
        font-size: 12px;
        border-radius: 20px;
        height: 18px;
        min-width: 18px;
        text-align: center;
        line-height: 18px;
        padding: 0 5px;
        font-weight: bold;
        display: inline-block;
        position: absolute;
        right: 0;
        top: 1px; }
    .new-case-list-wrapper .new-case-list-bottom .chat-last-message {
      width: 100%;
      font-size: 14px;
      letter-spacing: -0.2px;
      color: #191919;
      padding: 0 32px 0 0;
      position: relative;
      margin: 8px 0 0 0;
      line-height: 18px; }
      .new-case-list-wrapper .new-case-list-bottom .chat-last-message p {
        margin: 0;
        width: 100%;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap; }
      .new-case-list-wrapper .new-case-list-bottom .chat-last-message .chat-last-message-room {
        color: rgba(25, 25, 25, 0.6); }
      .new-case-list-wrapper .new-case-list-bottom .chat-last-message .message-status {
        position: absolute;
        right: 0;
        top: 50%;
        -webkit-transform: translate(0, -50%);
                transform: translate(0, -50%); }
        .new-case-list-wrapper .new-case-list-bottom .chat-last-message .message-status .unread-count-wrapper {
          background: #ff7d00;
          color: #ffffff;
          font-size: 12px;
          border-radius: 20px;
          height: 18px;
          min-width: 18px;
          text-align: center;
          line-height: 18px;
          padding: 0 5px;
          font-weight: bold; }
        .new-case-list-wrapper .new-case-list-bottom .chat-last-message .message-status img {
          width: 20px; }
    .new-case-list-wrapper .new-case-list-bottom .label-list-wrapper {
      width: 100%;
      display: block;
      height: 21px;
      margin-top: 6px; }
    .new-case-list-wrapper .new-case-list-bottom .agent-list-wrapper {
      width: 100%;
      display: block;
      height: 21px;
      margin-top: 6px; }
    .new-case-list-wrapper .new-case-list-bottom .case-status-wrapper {
      height: 21px;
      margin-top: 6px;
      position: relative; }
      .new-case-list-wrapper .new-case-list-bottom .case-status-wrapper .agent-badge-wrapper {
        display: inline-block !important;
        max-width: calc(100% - 90px); }
        .new-case-list-wrapper .new-case-list-bottom .case-status-wrapper .agent-badge-wrapper .my-agent-badge {
          max-width: 100% !important; }
        .new-case-list-wrapper .new-case-list-bottom .case-status-wrapper .agent-badge-wrapper .other-agent-badge {
          max-width: 100% !important; }
      .new-case-list-wrapper .new-case-list-bottom .case-status-wrapper .agent-badge-new {
        font-size: 12px;
        line-height: 1.5;
        letter-spacing: -0.4px;
        color: #ffffff;
        border-radius: 4px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        display: inline-block;
        padding: 1px 12px 2px;
        font-weight: bold;
        width: 100%; }
      .new-case-list-wrapper .new-case-list-bottom .case-status-wrapper .agent-badge-new-wrapper {
        width: 86px;
        text-align: center;
        display: inline-block;
        height: 21px;
        margin-right: 4px; }
      .new-case-list-wrapper .new-case-list-bottom .case-status-wrapper .agent-badge {
        max-width: calc(100% - 90px); }
      .new-case-list-wrapper .new-case-list-bottom .case-status-wrapper .agent-badge-new img {
        position: relative;
        top: -1px;
        margin-right: 4px; }
      .new-case-list-wrapper .new-case-list-bottom .case-status-wrapper .agent-badge-new svg {
        position: relative;
        top: -1px;
        margin-right: 4px; }
      .new-case-list-wrapper .new-case-list-bottom .case-status-wrapper .other-agent-badge-new {
        background: #0052ff; }
      .new-case-list-wrapper .new-case-list-bottom .case-status-wrapper .my-agent-badge-new {
        background: #19c700; }
      .new-case-list-wrapper .new-case-list-bottom .case-status-wrapper .unassigned-agent-badge-new {
        background: #C3C8D0;
        color: #ffffff; }
      .new-case-list-wrapper .new-case-list-bottom .case-status-wrapper .resolved-agent-badge-new {
        color: rgba(25, 25, 25, 0.6);
        background: #f3f3f3; }
  .new-case-list-wrapper.new-case-list-wrapper-reply {
    background: #FFF2E5; }
  .new-case-list-wrapper.new-case-list-wrapper-active {
    background: #ff7d00 !important; }
    .new-case-list-wrapper.new-case-list-wrapper-active .need-reply-or-junk-badge {
      background: #fff !important;
      color: #ff3f57 !important; }
    .new-case-list-wrapper.new-case-list-wrapper-active .omnichannel-name {
      color: #fff; }
    .new-case-list-wrapper.new-case-list-wrapper-active .new-case-list-bottom {
      border-bottom: solid 1px #ff7d00; }
    .new-case-list-wrapper.new-case-list-wrapper-active .more-label {
      color: #fff !important; }
    .new-case-list-wrapper.new-case-list-wrapper-active .chat-roomname {
      color: #fff; }
      .new-case-list-wrapper.new-case-list-wrapper-active .chat-roomname .chat-timestamp {
        color: #fff; }
    .new-case-list-wrapper.new-case-list-wrapper-active .chat-case-id {
      color: #fff; }
      .new-case-list-wrapper.new-case-list-wrapper-active .chat-case-id b {
        color: #fff; }
    .new-case-list-wrapper.new-case-list-wrapper-active .chat-last-message {
      color: #fff; }
      .new-case-list-wrapper.new-case-list-wrapper-active .chat-last-message span {
        color: #fff !important; }
    .new-case-list-wrapper.new-case-list-wrapper-active .typing-room-list {
      color: #fff !important; }
      .new-case-list-wrapper.new-case-list-wrapper-active .typing-room-list .typing-name {
        color: #fff !important; }
      .new-case-list-wrapper.new-case-list-wrapper-active .typing-room-list .typing-dot-one {
        color: #fff !important; }
      .new-case-list-wrapper.new-case-list-wrapper-active .typing-room-list .typing-dot-two {
        color: #fff !important; }
      .new-case-list-wrapper.new-case-list-wrapper-active .typing-room-list .typing-dot-three {
        color: #fff !important; }
    .new-case-list-wrapper.new-case-list-wrapper-active .unread-count-wrapper {
      background: #ffffff !important;
      color: var(--main-dark-orange) !important; }
  .new-case-list-wrapper.active-typing .typing-room-list {
    display: block; }
  .new-case-list-wrapper.active-typing .chat-last-message {
    display: none; }

.case-list-action-wrapper {
  width: 100%;
  height: 26px;
  position: relative;
  display: block;
  border-bottom: solid 1px rgba(25, 25, 25, 0.1);
  z-index: 2 !important;
  background: #fff; }
  .case-list-action-wrapper .case-list-action-left {
    position: absolute;
    left: 12px;
    top: 50%;
    -webkit-transform: translate(0, -50%);
            transform: translate(0, -50%);
    height: 26px;
    line-height: 22px; }
    .case-list-action-wrapper .case-list-action-left .dropdown button {
      background: none !important;
      border: none !important;
      outline: none !important;
      padding: 0;
      font-size: 12px;
      letter-spacing: -0.4px;
      outline: none !important;
      box-shadow: none !important;
      color: #ff7d00 !important; }
      .case-list-action-wrapper .case-list-action-left .dropdown button .dropdown-case-action-active {
        -webkit-transform: rotate(180deg);
                transform: rotate(180deg); }
      .case-list-action-wrapper .case-list-action-left .dropdown button svg {
        margin-left: 4px;
        font-size: 16px;
        -webkit-transition: ease-in-out .2s;
        transition: ease-in-out .2s;
        position: relative;
        top: -1px; }
      .case-list-action-wrapper .case-list-action-left .dropdown button:hover {
        color: var(--main-darker-orange) !important; }
        .case-list-action-wrapper .case-list-action-left .dropdown button:hover svg {
          color: var(--main-darker-orange) !important; }
    .case-list-action-wrapper .case-list-action-left .dropdown.dropdown-disabled button {
      color: rgba(25, 25, 25, 0.6) !important; }
      .case-list-action-wrapper .case-list-action-left .dropdown.dropdown-disabled button svg {
        color: rgba(25, 25, 25, 0.6) !important; }
    .case-list-action-wrapper .case-list-action-left .dropdown-menu {
      background: #fff;
      padding: 0;
      border-radius: 8px;
      box-shadow: 0px 2px 20px rgba(25, 25, 25, 0.18);
      border: none !important;
      top: 6px; }
      .case-list-action-wrapper .case-list-action-left .dropdown-menu button {
        color: #191919 !important;
        font-size: 16px;
        letter-spacing: -0.3px;
        padding: 12px; }
        .case-list-action-wrapper .case-list-action-left .dropdown-menu button svg {
          position: relative;
          margin-right: 8px;
          margin-left: 0;
          font-size: 18px;
          color: #ff7d00 !important;
          top: -1px; }
  .case-list-action-wrapper .case-list-action-right {
    position: absolute;
    right: 12px;
    top: 50%;
    -webkit-transform: translate(0, -50%);
            transform: translate(0, -50%);
    height: 26px;
    line-height: 22px;
    padding-right: 20px; }
    .case-list-action-wrapper .case-list-action-right b {
      color: rgba(25, 25, 25, 0.6);
      letter-spacing: -0.4px;
      font-size: 12px; }
    .case-list-action-wrapper .case-list-action-right .custom-checkbox-stripe {
      display: inline-block;
      position: relative;
      top: -6px;
      right: -4px; }
      .case-list-action-wrapper .case-list-action-right .custom-checkbox-stripe [type="checkbox"]:checked + label:after {
        width: 16px;
        height: 16px;
        top: 2px;
        left: 0; }
      .case-list-action-wrapper .case-list-action-right .custom-checkbox-stripe label {
        padding: 0; }
    .case-list-action-wrapper .case-list-action-right .custom-checkbox {
      display: inline-block;
      position: relative;
      top: -6px;
      right: -4px; }
      .case-list-action-wrapper .case-list-action-right .custom-checkbox [type="checkbox"]:checked + label:after {
        width: 26px;
        height: 26px;
        top: -3px;
        left: -5px; }
      .case-list-action-wrapper .case-list-action-right .custom-checkbox label {
        padding: 0; }


.room-list-tab-wrapper {
  height: 53px;
  width: calc(100% - 62px);
  position: relative;
  padding: 0 8px;
  background: #ffffff;
  position: absolute;
  right: 0;
  bottom: 0; }
  .room-list-tab-wrapper.no-other .room-list-tab-content {
    width: calc(100% / 3) !important; }
  .room-list-tab-wrapper.no-other-and-close .room-list-tab-content {
    width: 50% !important; }

.room-list-tab-content {
  width: calc(100% / 4);
  display: inline-block;
  color: rgba(25, 25, 25, 0.4);
  text-align: center;
  font-size: 13px;
  letter-spacing: -0.4px;
  position: relative;
  height: 53px;
  vertical-align: top;
  -webkit-transition: ease-in-out .1s;
  transition: ease-in-out .1s;
  padding-top: 9px; }
  .room-list-tab-content.tab-content-with-image .black-tab {
    display: none; }
  .room-list-tab-content.tab-content-with-image:hover .black-tab {
    display: inline-block !important; }
  .room-list-tab-content.tab-content-with-image:hover .grey-tab {
    display: none; }

.room-list-tab-content.active-room-list-tab {
  color: #ff7d00 !important; }

.room-list-tab-content:before {
  -webkit-transition: ease-in-out .3s;
  transition: ease-in-out .3s;
  content: "";
  position: absolute;
  width: 100%;
  height: 4px;
  left: 0;
  top: -1px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px; }

.room-list-tab-content.active-room-list-tab:before {
  background: #ff7d00; }

.room-list-tab-content svg {
  font-size: 17px;
  position: relative;
  top: -2px; }

.room-list-tab-content img {
  position: relative;
  top: -2px; }

.room-list-tab-content:hover {
  color: #191919;
  cursor: pointer; }

.last-case-no-margin-bottom > div > div:nth-last-child(1) {
  margin-bottom: 0 !important; }

.last-case-no-margin-bottom > div > div:nth-last-child(1) .fetch-solved-case {
  margin-bottom: 0; }

.case-list-separator {
  color: rgba(25, 25, 25, 0.6);
  font-size: 12px;
  letter-spacing: -0.4px;
  background: #f3f3f3;
  width: 100%;
  line-height: 1;
  padding: 10px 12px 6px; }

.case-list-separator.with-border-top {
  border-top: solid 1px rgba(25, 25, 25, 0.1); }

.main-case-wrapper .case-tab-label-wrapper {
  position: relative;
  width: 100%;
  margin-top: 4px; }

.label-container {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  max-width: 100%;
  color: #ffffff;
  line-height: 1;
  height: 19px;
  margin-top: 8px;
  border-radius: 4px; }
  .label-container .case-tab-label-content {
    border-radius: 4px;
    font-size: 12px;
    letter-spacing: -0.4px;
    margin-right: 2px;
    display: inline !important;
    padding: 2px 8px;
    text-align: center; }

.more-label {
  font-size: 12px;
  letter-spacing: -0.4px;
  color: rgba(25, 25, 25, 0.4);
  position: absolute;
  right: 2px;
  top: 50%;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%); }

.bot-case-tab-wrapper {
  background: #f3f3f3;
  width: calc(100% - 62px);
  display: inline-block;
  overflow: hidden; }
  .bot-case-tab-wrapper .case-room-search-wrapper {
    display: none; }
  .bot-case-tab-wrapper .case-room-filter-tag-badge {
    margin-top: 6px; }

.bot-case-room-wrapper {
  height: calc(100vh - 53px);
  position: relative;
  /* max-height: calc(100vh - 105px); */ }

.bot-case-room-wrapper #input-search-form input::-webkit-input-placeholder {
  font-size: 13px;
  color: rgba(25, 25, 25, 0.4); }

.bot-case-room-wrapper #input-search-form input::-ms-input-placeholder {
  font-size: 13px;
  color: rgba(25, 25, 25, 0.4); }

.bot-case-room-wrapper #input-search-form input::placeholder {
  font-size: 13px;
  color: rgba(25, 25, 25, 0.4); }

.bot-case-room-wrapper .lds-ring {
  margin-right: 5px;
  top: 3px !important;
  right: 0;
  right: 5px; }

.bot-case-sort-by-wrapper {
  box-shadow: 0 1px 0 0 rgba(25, 25, 25, 0.1);
  width: 100%;
  position: relative;
  z-index: 3;
  padding: 4px 12px 8px 12px;
  background: #ffffff;
  white-space: nowrap;
  height: 37px;
  left: 1px; }
  .bot-case-sort-by-wrapper::-webkit-scrollbar {
    display: none; }
  .bot-case-sort-by-wrapper .bot-case-sort-by-wrapper-inner .sort-by-content-wrapper {
    top: 1px; }
  .bot-case-sort-by-wrapper .bot-case-sort-by-wrapper-inner::-webkit-scrollbar {
    display: none; }
  .bot-case-sort-by-wrapper .bot-case-sort-by-wrapper-inner .sort-by-wrapper-inner-scroll {
    position: absolute;
    top: 4.5px;
    left: 160px;
    max-width: calc(100% - 171px);
    overflow-x: auto;
    margin: 0 5px; }
    .bot-case-sort-by-wrapper .bot-case-sort-by-wrapper-inner .sort-by-wrapper-inner-scroll .case-room-filter-tag-badge:nth-child(1) {
      margin-left: 0; }
    .bot-case-sort-by-wrapper .bot-case-sort-by-wrapper-inner .sort-by-wrapper-inner-scroll::-webkit-scrollbar {
      display: none; }
  .bot-case-sort-by-wrapper .case-room-filter-tag-badge {
    margin: 3px 0 0 4px;
    color: rgba(25, 25, 25, 0.6); }
    .bot-case-sort-by-wrapper .case-room-filter-tag-badge img {
      position: relative;
      top: -1px;
      margin-right: 3px; }

.room-list-no-chat-container {
    padding: 130px 16px 30px 16px;
    text-align: center;
  }
  .room-list-no-chat-container p {
    font-size: 14px;
    letter-spacing: -0.3px;
    line-height: 24px;
    margin: 0;
  }
  .room-list-no-chat-container b {
    font-size: 18px;
    line-height: 24px;
  }
  .room-list-no-chat-container b:nth-child(3) {
    color: #ff7d00;
  }
  .room-list-no-chat-container .no-chat-button {
    color: #ff7d00;
    margin-top: 16px;
    font-size: 16px;
    letter-spacing: -0.5px;
    cursor: pointer;
  }
  .room-list-no-chat-container .no-chat-button:hover {
    color: #e87200;
  }
  .room-list-no-chat-container .no-chat-button svg {
    font-size: 18px;
    position: relative;
    top: -2px;
  }
  
.sort-by-content-wrapper {
  position: relative;
  display: inline-block; }
  .sort-by-content-wrapper .dropdown {
    display: inline-block;
    outline: none;
    -webkit-transition: ease-in-out .2s !important;
    transition: ease-in-out .2s !important; }
    .sort-by-content-wrapper .dropdown button.btn {
      border-radius: 4px;
      border: none;
      background: none !important;
      color: #191919 !important;
      border: solid 1px rgba(25, 25, 25, 0.1) !important;
      font-size: 12px;
      letter-spacing: -0.4px;
      box-shadow: none !important;
      padding: 4px 12px 2px 8px;
      line-height: 1; }
      .sort-by-content-wrapper .dropdown button.btn svg {
        font-size: 14px;
        margin-right: 4px;
        position: relative;
        top: -1px;
        -webkit-transition: ease-in-out .2s;
        transition: ease-in-out .2s; }
        .sort-by-content-wrapper .dropdown button.btn svg.active-sort-by-menu {
          -webkit-transform: rotate(180deg);
                  transform: rotate(180deg); }
      .sort-by-content-wrapper .dropdown button.btn:hover {
        color: #ff7d00 !important;
        background: #ffe7d0 !important;
        border: solid 1px #ffe7d0 !important; }
    .sort-by-content-wrapper .dropdown .dropdown-menu {
      border-radius: 8px;
      box-shadow: 0 2px 20px 0 rgba(25, 25, 25, 0.18);
      border: none;
      position: relative;
      padding: 0;
      margin-top: 12px;
      left: -8px !important;
      outline: none; }
      .sort-by-content-wrapper .dropdown .dropdown-menu::before {
        position: absolute;
        width: 16px;
        height: 16px;
        background: #ffffff;
        content: "";
        top: -8px;
        -webkit-transform: rotate(-45deg);
                transform: rotate(-45deg);
        left: 30px; }
      .sort-by-content-wrapper .dropdown .dropdown-menu .dropdown-item {
        position: relative;
        font-size: 14px;
        letter-spacing: -0.4px;
        border-bottom: solid 1px rgba(25, 25, 25, 0.1);
        background: none;
        padding: 10px 16px 8px 16px;
        line-height: 1;
        color: #191919 !important; }
        .sort-by-content-wrapper .dropdown .dropdown-menu .dropdown-item svg {
          font-size: 16px;
          margin-right: 8px;
          position: absolute;
          top: 10px;
          left: 8px; }
        .sort-by-content-wrapper .dropdown .dropdown-menu .dropdown-item:hover {
          color: #ff7d00 !important; }
        .sort-by-content-wrapper .dropdown .dropdown-menu .dropdown-item.active-sort-by-option {
          padding: 10px 16px 8px 28px !important;
          color: #ff7d00 !important; }
        .sort-by-content-wrapper .dropdown .dropdown-menu .dropdown-item:nth-last-child(1) {
          border: none; }

@charset "UTF-8";
.modal-new-conversation {
  position: fixed;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100%;
  background: rgba(25, 25, 25, 0.8);
  visibility: hidden;
  opacity: 0;
  right: 0;
  -webkit-transition: visibility .3s, opacity 0.5s ease-in-out;
  transition: visibility .3s, opacity 0.5s ease-in-out;
  z-index: 9999; }
  .modal-new-conversation .this-top-22 {
    position: relative;
    top: -22px; }
  .modal-new-conversation .maximum-upload-size {
    font-size: 12px;
    letter-spacing: -0.4px;
    color: #191919;
    margin: 0; }
    .modal-new-conversation .maximum-upload-size svg {
      margin-right: 4px;
      position: relative;
      top: -1px; }
  .modal-new-conversation.active-modal-new-conversation {
    /* right: 0; */
    opacity: 1;
    visibility: visible;
    -webkit-transition: visibility 0s, opacity 0.5s ease-in-out;
    transition: visibility 0s, opacity 0.5s ease-in-out; }
  .modal-new-conversation .modal-body {
    position: absolute;
    top: 8px;
    right: -100%;
    left: unset;
    -webkit-transform: unset;
            transform: unset;
    height: calc(100% - 8px);
    width: 600px;
    display: inline-block;
    border-radius: 8px 0 0 0;
    background: #ffffff;
    padding: 0;
    max-height: calc(100% - 8px);
    -webkit-transition: ease-in-out .4s;
    transition: ease-in-out .4s; }
    .modal-new-conversation .modal-body.action-modal-body-new-conversation {
      right: 0; }
    .modal-new-conversation .modal-body .custom-select-topic button {
      margin-top: 0; }
    .modal-new-conversation .modal-body .close-modal-start-conversation {
      border-radius: 50%;
      width: 48px;
      height: 48px;
      background: #ffffff;
      color: #757575;
      position: absolute;
      left: -65px;
      top: 0;
      text-align: center;
      cursor: pointer; }
      .modal-new-conversation .modal-body .close-modal-start-conversation:hover {
        background: #f9f9f9; }
      .modal-new-conversation .modal-body .close-modal-start-conversation svg {
        position: absolute;
        font-size: 25px;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%); }
    .modal-new-conversation .modal-body .new-conversation-wrapper {
      height: calc(100% - 72px);
      max-height: calc(100% - 72px); }
      .modal-new-conversation .modal-body .new-conversation-wrapper > div > div {
        overflow-x: hidden !important; }
      .modal-new-conversation .modal-body .new-conversation-wrapper .phone-number-form {
        overflow-x: visible !important; }
      .modal-new-conversation .modal-body .new-conversation-wrapper .comp-back-button {
        margin: 0 0 24px; }
      .modal-new-conversation .modal-body .new-conversation-wrapper label {
        display: inline-block !important; }
      .modal-new-conversation .modal-body .new-conversation-wrapper .new-conversation-container-1 {
        padding: 32px; }
        .modal-new-conversation .modal-body .new-conversation-wrapper .new-conversation-container-1 .new-conversation-title {
          color: #191919; }
          .modal-new-conversation .modal-body .new-conversation-wrapper .new-conversation-container-1 .new-conversation-title b {
            font-size: 20px;
            letter-spacing: -0.6px; }
          .modal-new-conversation .modal-body .new-conversation-wrapper .new-conversation-container-1 .new-conversation-title img {
            margin: 0 4px 0 0;
            position: relative;
            top: -3px; }
          .modal-new-conversation .modal-body .new-conversation-wrapper .new-conversation-container-1 .new-conversation-title svg {
            font-size: 24px;
            margin: 0 4px 0 0;
            position: relative;
            top: -2px; }
          .modal-new-conversation .modal-body .new-conversation-wrapper .new-conversation-container-1 .new-conversation-title p {
            margin: 2px 0 0;
            color: rgba(25, 25, 25, 0.6);
            font-size: 14px;
            letter-spacing: -0.2px; }
        .modal-new-conversation .modal-body .new-conversation-wrapper .new-conversation-container-1 .channel-type {
          margin: 0;
          line-height: 1;
          font-size: 16px;
          letter-spacing: -0.3px; }
          .modal-new-conversation .modal-body .new-conversation-wrapper .new-conversation-container-1 .channel-type img {
            margin-right: 4px;
            position: relative;
            top: -2px; }
        .modal-new-conversation .modal-body .new-conversation-wrapper .new-conversation-container-1 .form-label {
          width: 100%;
          position: relative;
          font-size: 14px;
          letter-spacing: -0.6px;
          color: #191919; }
          .modal-new-conversation .modal-body .new-conversation-wrapper .new-conversation-container-1 .form-label.form-label-phone {
            margin: 24px 0 0; }
          .modal-new-conversation .modal-body .new-conversation-wrapper .new-conversation-container-1 .form-label p {
            margin: 0 8px 0 0;
            width: 142px;
            display: inline-block; }
            .modal-new-conversation .modal-body .new-conversation-wrapper .new-conversation-container-1 .form-label p.pos-relative {
              width: calc(100% - 150px);
              margin: 0 !important; }
        .modal-new-conversation .modal-body .new-conversation-wrapper .new-conversation-container-1 label span {
          letter-spacing: -0.2px;
          font-size: 14px;
          color: rgba(25, 25, 25, 0.6); }
        .modal-new-conversation .modal-body .new-conversation-wrapper .new-conversation-container-1 label.button-upload-image {
          margin: 0 4px;
          color: #ff7d00;
          letter-spacing: -0.4px;
          font-size: 12px;
          border-radius: 4px;
          cursor: pointer;
          display: inline-block !important;
          padding: 2px 8px;
          background: rgba(255, 126, 0, 0.1);
          -webkit-transition: ease-in-out .1s;
          transition: ease-in-out .1s; }
          .modal-new-conversation .modal-body .new-conversation-wrapper .new-conversation-container-1 label.button-upload-image:hover {
            color: #ffffff;
            background: #ff7d00; }
          .modal-new-conversation .modal-body .new-conversation-wrapper .new-conversation-container-1 label.button-upload-image svg {
            position: relative;
            top: -1px;
            margin-right: 4px; }
        .modal-new-conversation .modal-body .new-conversation-wrapper .new-conversation-container-1 label.button-upload-image-dis {
          margin: 0 4px;
          color: rgba(25, 25, 25, 0.4);
          letter-spacing: -0.4px;
          font-size: 12px;
          border-radius: 4px;
          cursor: default;
          display: inline-block !important;
          padding: 2px 8px;
          background: rgba(25, 25, 25, 0.1);
          -webkit-transition: ease-in-out .1s;
          transition: ease-in-out .1s; }
          .modal-new-conversation .modal-body .new-conversation-wrapper .new-conversation-container-1 label.button-upload-image-dis svg {
            position: relative;
            top: -1px;
            margin-right: 4px; }
        .modal-new-conversation .modal-body .new-conversation-wrapper .new-conversation-container-1 textarea {
          width: 100%;
          height: 96px; }
        .modal-new-conversation .modal-body .new-conversation-wrapper .new-conversation-container-1 .image-and-caption-wrapper {
          width: 100%; }
        .modal-new-conversation .modal-body .new-conversation-wrapper .new-conversation-container-1 input[type="file"] {
          display: none; }
        .modal-new-conversation .modal-body .new-conversation-wrapper .new-conversation-container-1 .phone-number-form {
          color: #191919;
          font-size: 16px;
          letter-spacing: -0.3px;
          margin-top: 8px;
          overflow-x: unset !important; }
          .modal-new-conversation .modal-body .new-conversation-wrapper .new-conversation-container-1 .phone-number-form .dropdown-menu {
            border: 1px solid rgba(0, 0, 0, 0.15);
            -webkit-transform: translate3d(0px, 50px, 0px) !important;
                    transform: translate3d(0px, 50px, 0px) !important; }
          .modal-new-conversation .modal-body .new-conversation-wrapper .new-conversation-container-1 .phone-number-form input[type="text"] {
            width: 100%;
            padding: 12px 16px;
            border: solid 1px rgba(25, 25, 25, 0.1);
            border-radius: 8px;
            box-shadow: none;
            outline: none; }
            .modal-new-conversation .modal-body .new-conversation-wrapper .new-conversation-container-1 .phone-number-form input[type="text"]:focus {
              border-color: #ff7d00; }
          .modal-new-conversation .modal-body .new-conversation-wrapper .new-conversation-container-1 .phone-number-form .phone-wrapper {
            width: calc(100% - 150px);
            display: inline-block;
            vertical-align: top; }
            .modal-new-conversation .modal-body .new-conversation-wrapper .new-conversation-container-1 .phone-number-form .phone-wrapper input {
              height: 48px; }
            .modal-new-conversation .modal-body .new-conversation-wrapper .new-conversation-container-1 .phone-number-form .phone-wrapper p {
              color: #ff3f57;
              font-size: 12px;
              letter-spacing: -0.4px;
              margin: 4px 0 0;
              width: 100%;
              position: relative;
              padding: 0 0 0 20px; }
              .modal-new-conversation .modal-body .new-conversation-wrapper .new-conversation-container-1 .phone-number-form .phone-wrapper p svg {
                margin-right: 4px;
                position: absolute;
                left: 0;
                top: 1px;
                font-size: 16px; }
          .modal-new-conversation .modal-body .new-conversation-wrapper .new-conversation-container-1 .phone-number-form.phone-number-disabled input[type="text"] {
            background: rgba(25, 25, 25, 0.1);
            border: none;
            cursor: not-allowed; }
      .modal-new-conversation .modal-body .new-conversation-wrapper .start-conversation-contact-wrapper {
        margin: 24px 0 0; }
        .modal-new-conversation .modal-body .new-conversation-wrapper .start-conversation-contact-wrapper label {
          margin: 0 0 8px; }
        .modal-new-conversation .modal-body .new-conversation-wrapper .start-conversation-contact-wrapper .add-by-phone-number {
          display: inline-block;
          font-size: 12px;
          letter-spacing: .2px;
          cursor: pointer;
          padding: 0 10px;
          margin: 0 0 0 4px;
          border-radius: 4px; }
        .modal-new-conversation .modal-body .new-conversation-wrapper .start-conversation-contact-wrapper .conversation-data-contact-wrapper {
          border-radius: 8px;
          border: solid 1px rgba(25, 25, 25, 0.1);
          padding: 11px 16px;
          font-size: 16px;
          color: #191919; }
          .modal-new-conversation .modal-body .new-conversation-wrapper .start-conversation-contact-wrapper .conversation-data-contact-wrapper p {
            white-space: nowrap;
            overflow: hidden;
            letter-spacing: -0.3px;
            text-overflow: ellipsis;
            margin: 0;
            max-width: 100%; }
        .modal-new-conversation .modal-body .new-conversation-wrapper .start-conversation-contact-wrapper .search-contact-wrapper {
          position: relative; }
          .modal-new-conversation .modal-body .new-conversation-wrapper .start-conversation-contact-wrapper .search-contact-wrapper input {
            width: 100%;
            border: solid 1px #dcdcdc;
            padding: 12px 50px 12px 16px;
            border-radius: 8px;
            margin: 0;
            font-size: 16px;
            letter-spacing: -0.3px; }
            .modal-new-conversation .modal-body .new-conversation-wrapper .start-conversation-contact-wrapper .search-contact-wrapper input:focus {
              border: solid 1px #ff7d00; }
          .modal-new-conversation .modal-body .new-conversation-wrapper .start-conversation-contact-wrapper .search-contact-wrapper svg {
            position: absolute;
            top: 50%;
            right: 16px;
            -webkit-transform: translate(0, -50%);
                    transform: translate(0, -50%);
            font-size: 20px; }
        .modal-new-conversation .modal-body .new-conversation-wrapper .start-conversation-contact-wrapper .selected-contact {
          position: relative;
          font-size: 14px;
          letter-spacing: -.4px;
          color: rgba(25, 25, 25, 0.6);
          border-radius: 4px;
          padding: 4px 24px 3px 8px;
          background: rgba(25, 25, 25, 0.05);
          display: inline-block;
          word-break: break-word; }
          .modal-new-conversation .modal-body .new-conversation-wrapper .start-conversation-contact-wrapper .selected-contact svg {
            position: relative;
            top: -2px;
            margin-right: 4px;
            font-size: 16px; }
          .modal-new-conversation .modal-body .new-conversation-wrapper .start-conversation-contact-wrapper .selected-contact .remove-contact {
            position: absolute;
            border-radius: 50%;
            background: #707070;
            right: 7px;
            top: 50%;
            -webkit-transform: translate(0, -50%);
                    transform: translate(0, -50%);
            width: 13px;
            height: 13px;
            cursor: pointer;
            text-align: center; }
            .modal-new-conversation .modal-body .new-conversation-wrapper .start-conversation-contact-wrapper .selected-contact .remove-contact svg {
              font-size: 9px;
              position: absolute;
              top: 2px;
              left: 2px;
              color: #ffffff;
              margin: 0; }
            .modal-new-conversation .modal-body .new-conversation-wrapper .start-conversation-contact-wrapper .selected-contact .remove-contact:hover {
              background: rgba(112, 112, 112, 0.8); }
        .modal-new-conversation .modal-body .new-conversation-wrapper .start-conversation-contact-wrapper .add-phone-number-info {
          font-size: 12px;
          letter-spacing: -0.4px;
          color: #191919;
          margin: 8px 0 0; }
          .modal-new-conversation .modal-body .new-conversation-wrapper .start-conversation-contact-wrapper .add-phone-number-info svg {
            position: relative;
            margin-right: 4px;
            top: -1px;
            font-size: 16px; }
        .modal-new-conversation .modal-body .new-conversation-wrapper .start-conversation-contact-wrapper .dropdown-contact-list {
          position: absolute;
          left: 0;
          top: 52px;
          width: 100%;
          border-radius: 8px;
          max-height: 193px;
          overflow-y: auto;
          background: #ffffff;
          border: solid 1px #dcdcdc;
          z-index: 2;
          overflow-x: hidden !important; }
          .modal-new-conversation .modal-body .new-conversation-wrapper .start-conversation-contact-wrapper .dropdown-contact-list .option-contact {
            font-size: 14px;
            letter-spacing: -.2px;
            border-bottom: solid 1px #dcdcdc;
            border-radius: 0;
            padding: 8px 20px;
            cursor: pointer;
            position: relative;
            word-break: break-word; }
            .modal-new-conversation .modal-body .new-conversation-wrapper .start-conversation-contact-wrapper .dropdown-contact-list .option-contact.selected-number-active {
              padding: 8px 20px 8px 36px;
              background: #FFF2E5; }
              .modal-new-conversation .modal-body .new-conversation-wrapper .start-conversation-contact-wrapper .dropdown-contact-list .option-contact.selected-number-active::before {
                color: #ff7e00;
                content: '\2714';
                position: absolute;
                left: 15px;
                top: 50%;
                -webkit-transform: translate(0, -50%);
                        transform: translate(0, -50%); }
            .modal-new-conversation .modal-body .new-conversation-wrapper .start-conversation-contact-wrapper .dropdown-contact-list .option-contact:nth-child(1) {
              border-top-left-radius: 8px;
              border-top-right-radius: 8px; }
            .modal-new-conversation .modal-body .new-conversation-wrapper .start-conversation-contact-wrapper .dropdown-contact-list .option-contact span {
              font-size: 12px;
              display: block;
              color: rgba(25, 25, 25, 0.6); }
            .modal-new-conversation .modal-body .new-conversation-wrapper .start-conversation-contact-wrapper .dropdown-contact-list .option-contact:hover {
              background: #FFF2E5; }
            .modal-new-conversation .modal-body .new-conversation-wrapper .start-conversation-contact-wrapper .dropdown-contact-list .option-contact:nth-last-child(1) {
              border: none;
              border-bottom-left-radius: 8px;
              border-bottom-right-radius: 8px; }
            .modal-new-conversation .modal-body .new-conversation-wrapper .start-conversation-contact-wrapper .dropdown-contact-list .option-contact.no-contact-found {
              text-align: center;
              color: rgba(25, 25, 25, 0.6);
              background: none !important; }
    .modal-new-conversation .modal-body .send-message-button {
      width: 100%;
      padding: 12px 24px;
      background: #ffffff;
      font-size: 16px;
      letter-spacing: -0.5px;
      position: absolute;
      bottom: 0;
      left: 0;
      box-shadow: 0 2px 20px 0 rgba(25, 25, 25, 0.18); }
      .modal-new-conversation .modal-body .send-message-button button {
        width: 100%;
        height: 48px; }

.form-image-message-wrapper {
  position: relative; }
  .form-image-message-wrapper input[type="file"] {
    display: none; }
  .form-image-message-wrapper label {
    display: inline-block !important; }
    .form-image-message-wrapper label span {
      color: rgba(25, 25, 25, 0.6); }
    .form-image-message-wrapper label.button-upload-image {
      margin: 0 4px;
      color: #ff7d00;
      letter-spacing: -0.4px;
      font-size: 12px;
      border-radius: 4px;
      cursor: pointer;
      display: inline-block !important;
      padding: 2px 8px;
      background: rgba(255, 126, 0, 0.1);
      -webkit-transition: ease-in-out .1s;
      transition: ease-in-out .1s; }
      .form-image-message-wrapper label.button-upload-image:hover {
        color: #ffffff;
        background: #ff7d00; }
      .form-image-message-wrapper label.button-upload-image svg {
        position: relative;
        top: -1px;
        margin-right: 4px; }
  .form-image-message-wrapper .image-and-caption-wrapper {
    position: relative;
    width: 100%;
    max-width: 100%; }
    .form-image-message-wrapper .image-and-caption-wrapper .image-preview-wrapper {
      background: #ffffff;
      position: absolute;
      top: 1px;
      left: 16px;
      padding: 11px 0 8px 0;
      border-bottom: solid 1px rgba(25, 25, 25, 0.1);
      width: calc(100% - 32px); }
      .form-image-message-wrapper .image-and-caption-wrapper .image-preview-wrapper.image-preview-wrapper-grey {
        background: #e8e8e8; }
      .form-image-message-wrapper .image-and-caption-wrapper .image-preview-wrapper svg {
        font-size: 64px;
        position: relative;
        left: -5px;
        color: #9b9b9b; }
      .form-image-message-wrapper .image-and-caption-wrapper .image-preview-wrapper .image-preview-frame {
        border: solid 1px rgba(25, 25, 25, 0.1);
        width: 64px;
        height: 64px;
        display: inline-block;
        position: relative;
        border-radius: 8px;
        overflow: hidden; }
        .form-image-message-wrapper .image-and-caption-wrapper .image-preview-wrapper .image-preview-frame img {
          max-width: 100%;
          max-height: 100%;
          position: absolute;
          left: 50%;
          top: 50%;
          -webkit-transform: translate(-50%, -50%);
                  transform: translate(-50%, -50%); }
        .form-image-message-wrapper .image-and-caption-wrapper .image-preview-wrapper .image-preview-frame video {
          max-width: 100%;
          max-height: 100%;
          position: absolute;
          left: 50%;
          top: 50%;
          -webkit-transform: translate(-50%, -50%);
                  transform: translate(-50%, -50%); }
        .form-image-message-wrapper .image-and-caption-wrapper .image-preview-wrapper .image-preview-frame .progress-wrapper {
          position: absolute;
          top: 0;
          left: 0;
          background: rgba(25, 25, 25, 0.4);
          width: 100%;
          height: 100%; }
          .form-image-message-wrapper .image-and-caption-wrapper .image-preview-wrapper .image-preview-frame .progress-wrapper .CircularProgressbar {
            width: calc(100% - 12px);
            height: calc(100% - 12px);
            position: absolute;
            top: 6px;
            left: 6px; }
            .form-image-message-wrapper .image-and-caption-wrapper .image-preview-wrapper .image-preview-frame .progress-wrapper .CircularProgressbar .CircularProgressbar-trail {
              stroke: transparent;
              stroke-width: 20; }
            .form-image-message-wrapper .image-and-caption-wrapper .image-preview-wrapper .image-preview-frame .progress-wrapper .CircularProgressbar .CircularProgressbar-path {
              stroke: #ffffff; }
        .form-image-message-wrapper .image-and-caption-wrapper .image-preview-wrapper .image-preview-frame .image-button-wrapper {
          position: absolute;
          top: 0;
          left: 0;
          background: rgba(25, 25, 25, 0.4);
          width: 100%;
          height: 100%;
          display: none; }
          .form-image-message-wrapper .image-and-caption-wrapper .image-preview-wrapper .image-preview-frame .image-button-wrapper .close-button-wrapper {
            width: 16px;
            height: 16px;
            border-radius: 50%;
            background: #636462;
            text-align: center;
            font-size: 7px;
            color: #ffffff;
            cursor: pointer;
            position: absolute;
            top: 4px;
            right: 4px; }
            .form-image-message-wrapper .image-and-caption-wrapper .image-preview-wrapper .image-preview-frame .image-button-wrapper .close-button-wrapper:hover {
              background: #ff3f57; }
          .form-image-message-wrapper .image-and-caption-wrapper .image-preview-wrapper .image-preview-frame .image-button-wrapper svg.preview-button {
            font-size: 20px;
            color: #ffffff;
            cursor: pointer;
            position: absolute;
            top: 50%;
            left: 50%;
            -webkit-transform: translate(-50%, -50%);
                    transform: translate(-50%, -50%); }
          .form-image-message-wrapper .image-and-caption-wrapper .image-preview-wrapper .image-preview-frame .image-button-wrapper svg.close-button {
            color: #ffffff;
            font-size: 12px;
            position: absolute;
            left: 50%;
            top: 50%;
            -webkit-transform: translate(-50%, -50%);
                    transform: translate(-50%, -50%); }
        .form-image-message-wrapper .image-and-caption-wrapper .image-preview-wrapper .image-preview-frame:hover .image-button-wrapper {
          display: inline-block; }
    .form-image-message-wrapper .image-and-caption-wrapper textarea {
      padding: 92px 16px 12px 16px;
      height: 178px !important; }
      .form-image-message-wrapper .image-and-caption-wrapper textarea[disabled] {
        background: #e8e8e8;
        cursor: not-allowed; }

.modal-image-preview {
  width: 100% !important; }
  .modal-image-preview .modal-content {
    width: 100%;
    height: 100%;
    background: unset; }
    .modal-image-preview .modal-content .modal-body {
      padding: 56px 86px;
      position: relative; }
      .modal-image-preview .modal-content .modal-body img {
        max-width: calc(100% - 112px);
        max-height: calc(100% - 172px);
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
        background: #ffffff; }
      .modal-image-preview .modal-content .modal-body video {
        max-width: calc(100% - 112px);
        max-height: calc(100% - 172px);
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
        background: #ffffff; }
      .modal-image-preview .modal-content .modal-body svg {
        font-size: 24px;
        color: #ffffff;
        cursor: pointer;
        position: absolute;
        top: 44px;
        right: 44px; }

.form-file-message-wrapper-new-conversation {
  position: relative;
  width: 100%; }
  .form-file-message-wrapper-new-conversation input[type="file"] {
    display: none; }
  .form-file-message-wrapper-new-conversation label {
    display: inline-block !important; }
    .form-file-message-wrapper-new-conversation label span {
      color: rgba(25, 25, 25, 0.6); }
    .form-file-message-wrapper-new-conversation label.button-upload-file {
      margin: 0 8px 0 0;
      color: rgba(25, 25, 25, 0.6);
      border: solid 1px rgba(25, 25, 25, 0.1);
      letter-spacing: -0.4px;
      font-size: 12px;
      border-radius: 4px;
      cursor: pointer;
      display: inline-block !important;
      padding: 2px 8px;
      background: #ffffff;
      -webkit-transition: ease-in-out .1s;
      transition: ease-in-out .1s; }
      .form-file-message-wrapper-new-conversation label.button-upload-file:hover {
        color: #ffffff;
        border: solid 1px #ff7d00;
        background: #ff7d00; }
      .form-file-message-wrapper-new-conversation label.button-upload-file svg {
        position: relative;
        top: -1px;
        margin-right: 4px; }
    .form-file-message-wrapper-new-conversation label.button-add-var {
      margin: 0 8px 0 0;
      color: rgba(25, 25, 25, 0.6);
      border: solid 1px rgba(25, 25, 25, 0.1);
      letter-spacing: -0.4px;
      font-size: 12px;
      border-radius: 4px;
      cursor: pointer;
      display: inline-block !important;
      padding: 2px 8px;
      background: #ffffff;
      -webkit-transition: ease-in-out .1s;
      transition: ease-in-out .1s; }
      .form-file-message-wrapper-new-conversation label.button-add-var:hover {
        color: #ffffff;
        border: solid 1px #ff7d00;
        background: #ff7d00; }
      .form-file-message-wrapper-new-conversation label.button-add-var svg {
        position: relative;
        top: -1px;
        margin-right: 4px; }
  .form-file-message-wrapper-new-conversation .file-and-caption-wrapper {
    position: relative;
    width: 100%;
    max-width: 100%;
    font-size: 0;
    margin: 0 0 6px 0; }
    .form-file-message-wrapper-new-conversation .file-and-caption-wrapper textarea {
      padding: 72px 16px 12px 16px !important;
      height: 178px !important; }
    .form-file-message-wrapper-new-conversation .file-and-caption-wrapper .broadcast-message-action-wrapper {
      position: absolute;
      height: 48px;
      border: solid 1px #dcdcdc;
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
      background: #ffffff;
      bottom: 0;
      left: 0;
      font-size: 12px;
      letter-spacing: -0.4px;
      display: block;
      width: 100%;
      padding: 12px 16px; }
    .form-file-message-wrapper-new-conversation .file-and-caption-wrapper .file-preview-wrapper {
      background: #ffffff;
      position: absolute;
      top: 1px;
      left: 16px;
      padding: 11px 0 0 0;
      width: calc(100% - 32px); }
      .form-file-message-wrapper-new-conversation .file-and-caption-wrapper .file-preview-wrapper svg {
        font-size: 64px;
        position: relative;
        left: -5px;
        color: #9b9b9b; }
      .form-file-message-wrapper-new-conversation .file-and-caption-wrapper .file-preview-wrapper .file-preview-frame {
        display: inline-block;
        position: relative;
        border-radius: 8px;
        overflow: hidden; }
        .form-file-message-wrapper-new-conversation .file-and-caption-wrapper .file-preview-wrapper .file-preview-frame .file-progress-upload-wrapper {
          font-size: 14px;
          letter-spacing: -0.2px;
          color: #191919;
          margin: 0;
          max-width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          display: inline-block;
          position: relative;
          padding-right: 22px; }
          .form-file-message-wrapper-new-conversation .file-and-caption-wrapper .file-preview-wrapper .file-preview-frame .file-progress-upload-wrapper svg {
            left: unset;
            top: -2px;
            font-size: 16px;
            letter-spacing: -0.2px;
            margin-right: 4px; }
            .form-file-message-wrapper-new-conversation .file-and-caption-wrapper .file-preview-wrapper .file-preview-frame .file-progress-upload-wrapper svg.remove-file {
              top: -1px;
              color: #ff7d00;
              margin-left: 4px;
              cursor: pointer;
              position: absolute;
              right: 0;
              top: 50%;
              -webkit-transform: translate(0, -50%);
                      transform: translate(0, -50%);
              margin: 0; }
              .form-file-message-wrapper-new-conversation .file-and-caption-wrapper .file-preview-wrapper .file-preview-frame .file-progress-upload-wrapper svg.remove-file:hover {
                color: #e83b51; }
        .form-file-message-wrapper-new-conversation .file-and-caption-wrapper .file-preview-wrapper .file-preview-frame img {
          max-width: 100%;
          max-height: 100%;
          position: absolute;
          left: 50%;
          top: 50%;
          -webkit-transform: translate(-50%, -50%);
                  transform: translate(-50%, -50%); }
        .form-file-message-wrapper-new-conversation .file-and-caption-wrapper .file-preview-wrapper .file-preview-frame .file-button-wrapper {
          position: absolute;
          top: 0;
          left: 0;
          background: rgba(25, 25, 25, 0.4);
          width: 100%;
          height: 100%;
          display: none; }
          .form-file-message-wrapper-new-conversation .file-and-caption-wrapper .file-preview-wrapper .file-preview-frame .file-button-wrapper .close-button-wrapper {
            width: 16px;
            height: 16px;
            border-radius: 50%;
            background: #636462;
            text-align: center;
            font-size: 7px;
            color: #ffffff;
            cursor: pointer;
            position: absolute;
            top: 4px;
            right: 4px; }
            .form-file-message-wrapper-new-conversation .file-and-caption-wrapper .file-preview-wrapper .file-preview-frame .file-button-wrapper .close-button-wrapper:hover {
              background: #ff3f57; }
          .form-file-message-wrapper-new-conversation .file-and-caption-wrapper .file-preview-wrapper .file-preview-frame .file-button-wrapper svg.preview-button {
            font-size: 20px;
            color: #ffffff;
            cursor: pointer;
            position: absolute;
            top: 50%;
            left: 50%;
            -webkit-transform: translate(-50%, -50%);
                    transform: translate(-50%, -50%); }
          .form-file-message-wrapper-new-conversation .file-and-caption-wrapper .file-preview-wrapper .file-preview-frame .file-button-wrapper svg.close-button {
            color: #ffffff;
            font-size: 12px;
            position: absolute;
            left: 50%;
            top: 50%;
            -webkit-transform: translate(-50%, -50%);
                    transform: translate(-50%, -50%); }
        .form-file-message-wrapper-new-conversation .file-and-caption-wrapper .file-preview-wrapper .file-preview-frame:hover .file-button-wrapper {
          display: inline-block; }
    .form-file-message-wrapper-new-conversation .file-and-caption-wrapper .placeholder-file {
      font-size: 14px;
      color: rgba(25, 25, 25, 0.6);
      height: 92px;
      border: solid 1px #dcdcdc;
      border-radius: 8px;
      padding: 12px 16px; }
    .form-file-message-wrapper-new-conversation .file-and-caption-wrapper textarea {
      padding: 48px 16px 60px 16px;
      height: 168px !important; }
      .form-file-message-wrapper-new-conversation .file-and-caption-wrapper textarea[disabled] {
        background: #e8e8e8;
        cursor: not-allowed;
        padding: 12px 16px;
        height: 138px !important; }
    .form-file-message-wrapper-new-conversation .file-and-caption-wrapper .label-upload-file {
      border: dotted 2px #ff7d00;
      cursor: pointer;
      margin: 0;
      width: 300px;
      border-radius: 8px;
      padding: 8px 0;
      text-align: center;
      font-size: 16px;
      letter-spacing: -0.5px;
      -webkit-transition: ease-in-out .1s;
      transition: ease-in-out .1s;
      color: #ff7d00; }
      .form-file-message-wrapper-new-conversation .file-and-caption-wrapper .label-upload-file svg {
        position: relative;
        top: -2px;
        margin-right: 4px; }
      .form-file-message-wrapper-new-conversation .file-and-caption-wrapper .label-upload-file:hover {
        border: dotted 2px #e87200;
        color: #e87200; }
    .form-file-message-wrapper-new-conversation .file-and-caption-wrapper .file-result-wrapper-outer {
      width: 100%;
      background: #fff;
      position: absolute;
      width: calc(100% - 32px);
      left: 16px;
      top: 1px;
      padding-top: 16px; }
      .form-file-message-wrapper-new-conversation .file-and-caption-wrapper .file-result-wrapper-outer.file-result-wrapper-outer-disabled {
        background: #e8e8e8; }
    .form-file-message-wrapper-new-conversation .file-and-caption-wrapper .file-result-wrapper {
      width: 100%;
      font-size: 14px;
      letter-spacing: -0.2px;
      background: #e3e3e3;
      border-radius: 8px;
      padding: 15px 98px 15px 46px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      position: relative; }
      .form-file-message-wrapper-new-conversation .file-and-caption-wrapper .file-result-wrapper .progress-wrapper {
        position: absolute;
        top: 50%;
        -webkit-transform: translate(0, -50%);
                transform: translate(0, -50%);
        left: 10px;
        margin-right: 4px;
        display: inline-block;
        width: 32px;
        height: 32px; }
        .form-file-message-wrapper-new-conversation .file-and-caption-wrapper .file-result-wrapper .progress-wrapper .CircularProgressbar {
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0; }
          .form-file-message-wrapper-new-conversation .file-and-caption-wrapper .file-result-wrapper .progress-wrapper .CircularProgressbar .CircularProgressbar-trail {
            stroke: transparent;
            stroke-width: 20; }
          .form-file-message-wrapper-new-conversation .file-and-caption-wrapper .file-result-wrapper .progress-wrapper .CircularProgressbar .CircularProgressbar-path {
            stroke: #ff7d00; }
      .form-file-message-wrapper-new-conversation .file-and-caption-wrapper .file-result-wrapper .icon-file {
        font-size: 20px;
        position: absolute;
        left: 17px;
        top: 50%;
        -webkit-transform: translate(0, -50%);
                transform: translate(0, -50%);
        margin-right: 4px; }
      .form-file-message-wrapper-new-conversation .file-and-caption-wrapper .file-result-wrapper .remove-file {
        cursor: pointer;
        position: absolute;
        right: 17px;
        top: 50%;
        -webkit-transform: translate(0, -50%);
                transform: translate(0, -50%);
        color: #FF3F57; }
        .form-file-message-wrapper-new-conversation .file-and-caption-wrapper .file-result-wrapper .remove-file svg {
          font-size: 20px;
          position: relative;
          top: -1px; }
        .form-file-message-wrapper-new-conversation .file-and-caption-wrapper .file-result-wrapper .remove-file:hover {
          color: #e83b51; }

.modal-file-preview {
  width: 100% !important; }
  .modal-file-preview .modal-content {
    width: 100%;
    height: 100%;
    background: unset; }
    .modal-file-preview .modal-content .modal-body {
      padding: 56px 86px;
      position: relative; }
      .modal-file-preview .modal-content .modal-body img {
        max-width: calc(100% - 112px);
        max-height: calc(100% - 172px);
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
        background: #ffffff; }
      .modal-file-preview .modal-content .modal-body svg {
        font-size: 24px;
        color: #ffffff;
        cursor: pointer;
        position: absolute;
        top: 44px;
        right: 44px; }

.modal-new-conversation .select-channel-first {
  background-color: rgba(25, 25, 25, 0.1) !important;
  color: rgba(25, 25, 25, 0.4) !important;
  border-radius: 8px;
  padding: 12px 38px 12px 16px;
  position: relative;
  margin: 0 0 12px 0; }
  .modal-new-conversation .select-channel-first svg {
    position: absolute;
    right: 14px;
    top: 12px;
    font-size: 24px; }

.modal-new-conversation .select--is-disabled .select__input {
  display: none; }

.modal-new-conversation .channel-type {
  margin: 0;
  line-height: 1;
  font-size: 16px;
  letter-spacing: -0.3px; }
  .modal-new-conversation .channel-type img {
    margin-right: 4px;
    position: relative;
    top: -2px; }

.modal-new-conversation .case-id p {
  margin-bottom: 0;
  color: rgba(25, 25, 25, 0.8); }

.modal-new-conversation input:disabled {
  background-color: rgba(25, 25, 25, 0.1) !important; }

.modal-new-conversation input::-webkit-input-placeholder {
  color: rgba(25, 25, 25, 0.4); }

.modal-new-conversation input::-ms-input-placeholder {
  color: rgba(25, 25, 25, 0.4); }

.modal-new-conversation input::placeholder {
  color: rgba(25, 25, 25, 0.4); }

.modal-new-conversation .disabled label,
.modal-new-conversation .disabled b,
.modal-new-conversation .disabled input {
  color: rgba(25, 25, 25, 0.4); }

.modal-new-conversation .new-conversation-field-info {
  font-size: 12px;
  letter-spacing: -0.4px;
  color: #191919;
  margin: 8px 0 0; }
  .modal-new-conversation .new-conversation-field-info svg {
    position: relative;
    margin-right: 4px;
    top: -1px;
    font-size: 16px; }

.modal-new-conversation .search-contact-wrapper .conversation-data-contact-wrapper {
  border-radius: 8px;
  border: solid 1px rgba(25, 25, 25, 0.1);
  padding: 11px 16px;
  font-size: 16px;
  color: #191919; }
  .modal-new-conversation .search-contact-wrapper .conversation-data-contact-wrapper p {
    white-space: nowrap;
    overflow: hidden;
    letter-spacing: -0.3px;
    text-overflow: ellipsis;
    margin: 0;
    max-width: 100%; }

.modal-new-conversation .search-contact-wrapper .LoadingRoll_1234fed {
  position: absolute;
  top: 50%;
  right: 16px;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%);
  font-size: 20px; }

.modal-new-conversation .search-contact-wrapper.border-red input {
  border: solid 1px #ff3f57 !important; }

.modal-new-conversation .search-message-template-wrapper,
.modal-new-conversation .search-language-template-wrapper {
  position: relative; }
  .modal-new-conversation .search-message-template-wrapper div div,
  .modal-new-conversation .search-language-template-wrapper div div {
    margin-bottom: 0; }
  .modal-new-conversation .search-message-template-wrapper .LoadingRoll_1234fed,
  .modal-new-conversation .search-language-template-wrapper .LoadingRoll_1234fed {
    position: absolute;
    top: 50%;
    right: 16px;
    -webkit-transform: translate(0, -50%);
            transform: translate(0, -50%);
    font-size: 20px; }
  .modal-new-conversation .search-message-template-wrapper.border-red > div > div,
  .modal-new-conversation .search-language-template-wrapper.border-red > div > div {
    border-color: #ff3f57 !important; }

.modal-new-conversation .search-message-template-wrapper.disabled input,
.modal-new-conversation .search-language-template-wrapper.disabled input {
  visibility: hidden; }

.modal-new-conversation .search-message-template-wrapper.disabledd input,
.modal-new-conversation .search-language-template-wrapper.disabledd input {
  visibility: hidden; }

.modal-new-conversation .add-by-phone-number:hover svg {
  color: #ffffff; }

.new-conversation-container {
  padding: 32px 32px 72px; }
  .new-conversation-container .new-conversation-title {
    color: #191919; }
    .new-conversation-container .new-conversation-title b {
      font-size: 20px;
      letter-spacing: -0.6px; }
    .new-conversation-container .new-conversation-title img {
      margin: 0 4px 0 0;
      position: relative;
      top: -3px; }
    .new-conversation-container .new-conversation-title svg {
      font-size: 24px;
      margin: 0 4px 0 0;
      position: relative;
      top: -2px; }
    .new-conversation-container .new-conversation-title p {
      margin: 2px 0 0;
      color: rgba(25, 25, 25, 0.6);
      font-size: 14px;
      letter-spacing: -0.2px; }
  .new-conversation-container .channel-type {
    margin: 0;
    line-height: 1;
    font-size: 16px;
    letter-spacing: -0.3px; }
    .new-conversation-container .channel-type img {
      margin-right: 4px;
      position: relative;
      top: -2px; }
  .new-conversation-container .form-label {
    width: 100%;
    position: relative;
    font-size: 14px;
    letter-spacing: -0.6px;
    color: #191919; }
    .new-conversation-container .form-label.form-label-phone {
      margin: 24px 0 0; }
    .new-conversation-container .form-label p {
      margin: 0 8px 0 0;
      width: 142px;
      display: inline-block; }
      .new-conversation-container .form-label p.pos-relative {
        width: calc(100% - 150px);
        margin: 0 !important; }
  .new-conversation-container label span {
    letter-spacing: -0.2px;
    font-size: 14px;
    color: rgba(25, 25, 25, 0.6); }
  .new-conversation-container label.button-upload-image {
    margin: 0 4px;
    color: #ff7d00;
    letter-spacing: -0.4px;
    font-size: 12px;
    border-radius: 4px;
    cursor: pointer;
    display: inline-block !important;
    padding: 2px 8px;
    background: rgba(255, 126, 0, 0.1);
    -webkit-transition: ease-in-out .1s;
    transition: ease-in-out .1s; }
    .new-conversation-container label.button-upload-image:hover {
      color: #ffffff;
      background: #ff7d00; }
    .new-conversation-container label.button-upload-image svg {
      position: relative;
      top: -1px;
      margin-right: 4px; }
  .new-conversation-container label.button-upload-image-dis {
    margin: 0 4px;
    color: rgba(25, 25, 25, 0.4);
    letter-spacing: -0.4px;
    font-size: 12px;
    border-radius: 4px;
    cursor: default;
    display: inline-block !important;
    padding: 2px 8px;
    background: rgba(25, 25, 25, 0.1);
    -webkit-transition: ease-in-out .1s;
    transition: ease-in-out .1s; }
    .new-conversation-container label.button-upload-image-dis svg {
      position: relative;
      top: -1px;
      margin-right: 4px; }
  .new-conversation-container textarea {
    width: 100%;
    height: 96px; }
  .new-conversation-container .image-and-caption-wrapper {
    width: 100%; }
  .new-conversation-container input[type="file"] {
    display: none; }
  .new-conversation-container .phone-number-form {
    color: #191919;
    font-size: 16px;
    letter-spacing: -0.3px;
    margin-top: 8px;
    overflow-x: unset !important; }
    .new-conversation-container .phone-number-form .dropdown-menu {
      border: 1px solid rgba(0, 0, 0, 0.15);
      -webkit-transform: translate3d(0px, 50px, 0px) !important;
              transform: translate3d(0px, 50px, 0px) !important; }
    .new-conversation-container .phone-number-form input[type="text"] {
      width: 100%;
      padding: 12px 16px;
      border: solid 1px rgba(25, 25, 25, 0.1);
      border-radius: 8px;
      box-shadow: none;
      outline: none; }
      .new-conversation-container .phone-number-form input[type="text"]:focus {
        border-color: #ff7d00; }
    .new-conversation-container .phone-number-form .phone-wrapper {
      width: calc(100% - 150px);
      display: inline-block;
      vertical-align: top; }
      .new-conversation-container .phone-number-form .phone-wrapper input {
        height: 48px; }
      .new-conversation-container .phone-number-form .phone-wrapper p {
        color: #ff3f57;
        font-size: 12px;
        letter-spacing: -0.4px;
        margin: 4px 0 0;
        width: 100%;
        position: relative;
        padding: 0 0 0 20px; }
        .new-conversation-container .phone-number-form .phone-wrapper p svg {
          margin-right: 4px;
          position: absolute;
          left: 0;
          top: 1px;
          font-size: 16px; }
    .new-conversation-container .phone-number-form.phone-number-disabled input[type="text"] {
      background: rgba(25, 25, 25, 0.1);
      border: none;
      cursor: not-allowed; }
  .new-conversation-container .custom-select-phonenumber {
    margin-bottom: 24px; }
  .new-conversation-container .new-templated-message-title {
    color: #191919; }
    .new-conversation-container .new-templated-message-title b {
      font-size: 20px;
      letter-spacing: -0.6px; }
    .new-conversation-container .new-templated-message-title img {
      margin: 0 4px 0 0;
      position: relative;
      top: -3px; }
    .new-conversation-container .new-templated-message-title svg {
      font-size: 24px;
      margin: 0 4px 0 0;
      position: relative;
      top: -2px; }
    .new-conversation-container .new-templated-message-title p {
      margin: 2px 0 0;
      color: rgba(25, 25, 25, 0.6);
      font-size: 14px;
      letter-spacing: -0.2px; }

.modal-sending .modal-content {
  border-radius: 8px;
  width: 428px;
  display: inline-block; }

.modal-sending .modal-body {
  padding: 32px;
  text-align: center; }
  .modal-sending .modal-body .lds-ring {
    height: 53px;
    width: 53px;
    top: unset;
    right: unset;
    margin: 0 0 12px 0; }
    .modal-sending .modal-body .lds-ring div {
      height: 53px;
      width: 53px;
      margin: 0;
      border: 6px solid #fff;
      border-color: #ff7d00 transparent transparent transparent; }
  .modal-sending .modal-body p {
    color: #000000;
    font-size: 14px;
    letter-spacing: -0.2px;
    margin: 0; }

.modal-ongoing-case .modal-content {
  border-radius: 8px;
  width: 498px;
  display: inline-block; }

.modal-ongoing-case .modal-body {
  width: 100%;
  padding: 32px; }
  .modal-ongoing-case .modal-body .ongoing-title {
    color: #191919;
    letter-spacing: -0.6px;
    font-size: 20px;
    margin: 0; }
    .modal-ongoing-case .modal-body .ongoing-title svg {
      font-size: 24px;
      margin-right: 4px;
      position: relative;
      top: -2px; }
  .modal-ongoing-case .modal-body .ongoing-description {
    color: rgba(25, 25, 25, 0.6);
    font-size: 14px;
    letter-spacing: -0.2px;
    line-height: 21px;
    margin: 4px 0 20px 0; }
  .modal-ongoing-case .modal-body .ongoing-button-wrapper button {
    height: 48px;
    font-size: 16px;
    letter-spacing: -0.5px;
    margin: 0; }
    .modal-ongoing-case .modal-body .ongoing-button-wrapper button svg {
      font-size: 20px;
      position: relative;
      margin-left: 4px;
      top: -2px; }
    .modal-ongoing-case .modal-body .ongoing-button-wrapper button .lds-ring {
      top: 3px;
      right: unset; }
      .modal-ongoing-case .modal-body .ongoing-button-wrapper button .lds-ring div {
        margin: 0;
        border-color: #ffffff transparent transparent transparent; }
    .modal-ongoing-case .modal-body .ongoing-button-wrapper button.button-50 {
      width: calc(50% - 4px);
      margin: 0 8px 0 0; }
      .modal-ongoing-case .modal-body .ongoing-button-wrapper button.button-50:nth-last-child(1) {
        margin: 0; }

.modal-has-open-case .modal-content {
  width: 476px; }

.modal-has-open-case .modal-body {
  padding: 32px; }
  .modal-has-open-case .modal-body .has-open-case-title {
    font-size: 20px;
    letter-spacing: -0.6px;
    margin: 0;
    line-height: 1;
    color: #191919; }
    .modal-has-open-case .modal-body .has-open-case-title svg {
      position: relative;
      margin-right: 4px;
      top: -2px; }
  .modal-has-open-case .modal-body .has-open-case-description {
    color: rgba(25, 25, 25, 0.6);
    font-size: 14px;
    margin: 8px 0 0; }
  .modal-has-open-case .modal-body .other-agent-wrapper {
    background: rgba(25, 25, 25, 0.05);
    border-radius: 8px;
    padding: 15px 12px 15px 56px;
    position: relative;
    width: 100%;
    color: #191919;
    font-size: 14px;
    letter-spacing: -0.4px;
    margin-top: 20px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; }
    .modal-has-open-case .modal-body .other-agent-wrapper .agent-avatar {
      width: 34px;
      height: 34px;
      border-radius: 50%;
      color: #ffffff;
      text-align: center;
      line-height: 34px;
      font-size: 14px;
      letter-spacing: -0.4px;
      position: absolute;
      left: 12px;
      top: 50%;
      -webkit-transform: translate(0, -50%);
              transform: translate(0, -50%); }
  .modal-has-open-case .modal-body button.dismiss {
    height: 48px;
    font-size: 16px;
    letter-spacing: -0.5px;
    margin-top: 20px;
    width: 100%; }

.modal-preview-message {
  position: fixed;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100%;
  background: rgba(25, 25, 25, 0.8);
  visibility: hidden;
  opacity: 0;
  right: 0;
  -webkit-transition: visibility 0.3s, opacity 0.5s ease-in-out;
  transition: visibility 0.3s, opacity 0.5s ease-in-out;
  z-index: 9999; }
  .modal-preview-message .media-upload-wrapper .media-upload-wrapper-left {
    width: 200px;
    display: inline-block;
    vertical-align: top;
    position: relative;
    margin-top: 24px;
    padding-right: 12px; }
    .modal-preview-message .media-upload-wrapper .media-upload-wrapper-left label {
      margin: 0;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      padding-right: 12px; }
  .modal-preview-message .media-upload-wrapper input[type="file"] {
    display: none; }
  .modal-preview-message .media-upload-wrapper .media-upload-wrapper-right {
    width: calc(100% - 200px);
    display: inline-block;
    vertical-align: top;
    margin-top: 24px; }
    .modal-preview-message .media-upload-wrapper .media-upload-wrapper-right button {
      margin: 0;
      text-transform: capitalize;
      width: unset; }
    .modal-preview-message .media-upload-wrapper .media-upload-wrapper-right .upload-info {
      font-size: 12px;
      letter-spacing: -0.4px;
      margin-top: 10px;
      position: relative; }
      .modal-preview-message .media-upload-wrapper .media-upload-wrapper-right .upload-info .replace-media {
        font-size: 12px;
        letter-spacing: -0.4px;
        border-radius: 4px;
        padding: 2px 12px 2px 8px;
        width: unset;
        text-transform: capitalize;
        display: block;
        margin: 0 0 8px 0; }
      .modal-preview-message .media-upload-wrapper .media-upload-wrapper-right .upload-info svg {
        position: relative;
        top: -1px;
        margin-right: 4px; }
    .modal-preview-message .media-upload-wrapper .media-upload-wrapper-right .image-video-wrapper {
      width: 48px;
      height: 48px;
      position: relative;
      border-radius: 6px;
      overflow: hidden; }
      .modal-preview-message .media-upload-wrapper .media-upload-wrapper-right .image-video-wrapper .progress-wrapper {
        position: absolute;
        top: 0;
        left: 0;
        background: rgba(25, 25, 25, 0.4);
        width: 100%;
        height: 100%; }
        .modal-preview-message .media-upload-wrapper .media-upload-wrapper-right .image-video-wrapper .progress-wrapper .CircularProgressbar {
          width: calc(100% - 12px);
          height: calc(100% - 12px);
          position: absolute;
          top: 6px;
          left: 6px; }
          .modal-preview-message .media-upload-wrapper .media-upload-wrapper-right .image-video-wrapper .progress-wrapper .CircularProgressbar .CircularProgressbar-trail {
            stroke: transparent;
            stroke-width: 20; }
          .modal-preview-message .media-upload-wrapper .media-upload-wrapper-right .image-video-wrapper .progress-wrapper .CircularProgressbar .CircularProgressbar-path {
            stroke: #ffffff; }
      .modal-preview-message .media-upload-wrapper .media-upload-wrapper-right .image-video-wrapper img {
        width: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
        max-width: 100%;
        max-height: 100%;
        object-fit: contain; }
      .modal-preview-message .media-upload-wrapper .media-upload-wrapper-right .image-video-wrapper video {
        width: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
        max-width: 100%;
        max-height: 100%;
        object-fit: contain; }
    .modal-preview-message .media-upload-wrapper .media-upload-wrapper-right .document-wrapper {
      font-size: 14px;
      letter-spacing: -0.2px; }
      .modal-preview-message .media-upload-wrapper .media-upload-wrapper-right .document-wrapper svg {
        color: #ff7d00;
        font-size: 16px;
        margin-right: 4px;
        position: relative;
        top: -2px; }
  .modal-preview-message.active-modal-preview-message {
    /* right: 0; */
    opacity: 1;
    visibility: visible;
    -webkit-transition: visibility 0s, opacity 0.5s ease-in-out;
    transition: visibility 0s, opacity 0.5s ease-in-out; }
  .modal-preview-message .modal-body {
    position: absolute;
    top: 8px;
    right: -100%;
    left: unset;
    -webkit-transform: unset;
            transform: unset;
    height: calc(100% - 8px);
    width: 600px;
    display: inline-block;
    border-radius: 8px 0 0 0;
    background: #ffffff;
    padding: 0;
    max-height: calc(100% - 8px);
    -webkit-transition: ease-in-out 0.4s;
    transition: ease-in-out 0.4s; }
    .modal-preview-message .modal-body.action-modal-body-preview-message {
      right: 0; }
    .modal-preview-message .modal-body .close-modal-start-conversation {
      border-radius: 50%;
      width: 48px;
      height: 48px;
      background: #ffffff;
      color: #757575;
      position: absolute;
      left: -65px;
      top: 0;
      text-align: center;
      cursor: pointer; }
      .modal-preview-message .modal-body .close-modal-start-conversation:hover {
        background: #f9f9f9; }
      .modal-preview-message .modal-body .close-modal-start-conversation svg {
        position: relative;
        font-size: 25px;
        top: 10px; }
    .modal-preview-message .modal-body .preview-message-wrapper {
      height: 100%;
      overflow: hidden; }
      .modal-preview-message .modal-body .preview-message-wrapper .comp-back-button {
        margin: 0 0 24px; }
      .modal-preview-message .modal-body .preview-message-wrapper label {
        display: inline-block !important; }
      .modal-preview-message .modal-body .preview-message-wrapper .preview-message-container {
        padding: 32px; }
        .modal-preview-message .modal-body .preview-message-wrapper .preview-message-container .preview-message-title {
          color: #191919; }
          .modal-preview-message .modal-body .preview-message-wrapper .preview-message-container .preview-message-title b {
            font-size: 20px;
            letter-spacing: -0.6px; }
          .modal-preview-message .modal-body .preview-message-wrapper .preview-message-container .preview-message-title svg {
            font-size: 24px;
            margin: 0 4px 0 0;
            position: relative;
            top: -2px; }
          .modal-preview-message .modal-body .preview-message-wrapper .preview-message-container .preview-message-title p {
            margin: 2px 0 0;
            color: rgba(25, 25, 25, 0.6);
            font-size: 14px;
            letter-spacing: -0.2px; }
        .modal-preview-message .modal-body .preview-message-wrapper .preview-message-container .preview-message-body {
          margin-top: 24px; }
        .modal-preview-message .modal-body .preview-message-wrapper .preview-message-container .preview-message-param-wrapper {
          margin-top: 24px; }
          .modal-preview-message .modal-body .preview-message-wrapper .preview-message-container .preview-message-param-wrapper .preview-message-param-title {
            display: flex; }
          .modal-preview-message .modal-body .preview-message-wrapper .preview-message-container .preview-message-param-wrapper .line {
            content: "";
            max-width: 350px;
            margin-left: 10px;
            align-self: center;
            background: -webkit-gradient(linear, left top, right top, from(rgba(25, 25, 25, 0)), to(rgba(25, 25, 25, 0.16)));
            background: linear-gradient(90deg, rgba(25, 25, 25, 0) 0%, rgba(25, 25, 25, 0.16) 100%);
            width: 350px;
            height: 1px;
            -webkit-transform: matrix(-1, 0, 0, 1, 0, 0);
                    transform: matrix(-1, 0, 0, 1, 0, 0); }
          .modal-preview-message .modal-body .preview-message-wrapper .preview-message-container .preview-message-param-wrapper span {
            margin-left: 5px; }
          .modal-preview-message .modal-body .preview-message-wrapper .preview-message-container .preview-message-param-wrapper .preview-message-param {
            margin: 24px 0 40px;
            position: relative; }
            .modal-preview-message .modal-body .preview-message-wrapper .preview-message-container .preview-message-param-wrapper .preview-message-param label {
              vertical-align: -10px;
              width: 188px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis; }
            .modal-preview-message .modal-body .preview-message-wrapper .preview-message-container .preview-message-param-wrapper .preview-message-param .input-param-wrapper {
              position: absolute;
              right: 0;
              width: 336px;
              max-width: calc(100% - 204px);
              height: 48px;
              left: 200px;
              top: 0px;
              background: #ffffff;
              box-sizing: border-box; }
            .modal-preview-message .modal-body .preview-message-wrapper .preview-message-container .preview-message-param-wrapper .preview-message-param .input-info-text {
              margin: 0;
              width: 100%;
              position: absolute;
              left: 0;
              top: 52px;
              line-height: 1; }
              .modal-preview-message .modal-body .preview-message-wrapper .preview-message-container .preview-message-param-wrapper .preview-message-param .input-info-text svg {
                top: 0px; }
            .modal-preview-message .modal-body .preview-message-wrapper .preview-message-container .preview-message-param-wrapper .preview-message-param input {
              padding: 12px 16px;
              width: 336px;
              height: 48px;
              background: #ffffff;
              border: 1px solid rgba(25, 25, 25, 0.1);
              box-sizing: border-box;
              border-radius: 8px; }
            .modal-preview-message .modal-body .preview-message-wrapper .preview-message-container .preview-message-param-wrapper .preview-message-param .input-info-text {
              margin: 0;
              width: 100%;
              position: absolute;
              left: 0;
              top: 52px;
              line-height: 1; }
              .modal-preview-message .modal-body .preview-message-wrapper .preview-message-container .preview-message-param-wrapper .preview-message-param .input-info-text svg {
                top: 0px; }
            .modal-preview-message .modal-body .preview-message-wrapper .preview-message-container .preview-message-param-wrapper .preview-message-param input:focus {
              border: 1px solid #ff7e00; }
        .modal-preview-message .modal-body .preview-message-wrapper .preview-message-container .preview-message-button button {
          margin-top: 24px; }

.modal-send-templated-message .modal-content {
  border-radius: 8px;
  width: 476px;
  display: inline-block; }

.modal-send-templated-message .modal-body {
  padding: 32px; }
  .modal-send-templated-message .modal-body .modal-send-title {
    font-size: 20px;
    font-weight: 700; }
  .modal-send-templated-message .modal-body p {
    color: #000000;
    font-size: 14px;
    letter-spacing: -0.2px; }

.modal-send-templated-message .modal-send-templated-message-buttons {
  display: flex; }
  .modal-send-templated-message .modal-send-templated-message-buttons .no-fill-button {
    width: 120px;
    margin-right: 5px; }
  .modal-send-templated-message .modal-send-templated-message-buttons .orange-button {
    width: 308px; }

.modal-hide-other-case .modal-content {
  border-radius: 8px;
  width: 498px;
  display: inline-block; }

.modal-hide-other-case .modal-body {
  width: 100%;
  padding: 32px; }
  .modal-hide-other-case .modal-body .other-agent-wrapper {
    background: rgba(25, 25, 25, 0.05);
    border-radius: 8px;
    padding: 15px 12px 15px 56px;
    position: relative;
    width: 100%;
    color: #191919;
    font-size: 14px;
    letter-spacing: -0.4px;
    margin: 20px 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; }
    .modal-hide-other-case .modal-body .other-agent-wrapper .agent-avatar {
      width: 34px;
      height: 34px;
      border-radius: 50%;
      color: #ffffff;
      text-align: center;
      line-height: 34px;
      font-size: 14px;
      letter-spacing: -0.4px;
      position: absolute;
      left: 12px;
      top: 50%;
      -webkit-transform: translate(0, -50%);
              transform: translate(0, -50%); }
  .modal-hide-other-case .modal-body .hide-other-title {
    color: #191919;
    letter-spacing: -0.6px;
    font-size: 20px;
    margin: 0; }
    .modal-hide-other-case .modal-body .hide-other-title svg {
      font-size: 24px;
      margin-right: 4px;
      position: relative;
      top: -2px; }
  .modal-hide-other-case .modal-body .hide-other-description {
    color: rgba(25, 25, 25, 0.6);
    font-size: 14px;
    letter-spacing: -0.2px;
    line-height: 21px;
    margin: 4px 0 20px 0; }
    .modal-hide-other-case .modal-body .hide-other-description b {
      color: #7f7f7f; }
  .modal-hide-other-case .modal-body .hide-other-button-wrapper button {
    height: 48px;
    font-size: 16px;
    letter-spacing: -0.5px;
    margin: 0; }
    .modal-hide-other-case .modal-body .hide-other-button-wrapper button svg {
      font-size: 20px;
      position: relative;
      margin-left: 4px;
      top: -2px; }
    .modal-hide-other-case .modal-body .hide-other-button-wrapper button .lds-ring {
      top: 3px;
      right: unset; }
      .modal-hide-other-case .modal-body .hide-other-button-wrapper button .lds-ring div {
        margin: 0;
        border-color: #ffffff transparent transparent transparent; }
    .modal-hide-other-case .modal-body .hide-other-button-wrapper button.button-50 {
      width: calc(50% - 4px);
      margin: 0 8px 0 0; }
      .modal-hide-other-case .modal-body .hide-other-button-wrapper button.button-50:nth-last-child(1) {
        margin: 0; }

.open-case-tab-wrapper {
  height: calc(100vh - 109px);
  position: relative;
  background: #f3f3f3;
  width: calc(100% - 62px);
  display: inline-block;
  overflow: hidden; }
  .open-case-tab-wrapper .need-reply-panel-1 {
    width: 100%;
    text-align: center; }
  .open-case-tab-wrapper .border-radius-12 {
    border-radius: 12px !important; }
  .open-case-tab-wrapper .room-list-loading-wrapper {
    padding: 20px 0;
    height: calc(100% - 152px); }
    .open-case-tab-wrapper .room-list-loading-wrapper .lds-ring {
      margin-right: 5px;
      top: 3px !important;
      left: 0; }
  .open-case-tab-wrapper .room-list-no-chat-container {
    padding: 10px 16px 30px 16px;
    color: #191919; }
  .open-case-tab-wrapper .room-list-chat-container {
    -webkit-transition: ease-in-out .2s;
    transition: ease-in-out .2s;
    position: absolute;
    right: -360px;
    width: 100%; }
    .open-case-tab-wrapper .room-list-chat-container.active-open-case-list-panel {
      right: 0; }

.open-case-unassigned-panel {
  margin-bottom: 8px;
  width: 100%; }
  .open-case-unassigned-panel svg {
    color: #ff3f57; }

.open-case-panel {
  background: #ffffff;
  -webkit-transition: ease-in-out .1s;
  transition: ease-in-out .1s; }
  .open-case-panel .room-list-main-chat-container {
    box-shadow: 0px 1px 0px #dcdcdc;
    border: 0;
    -webkit-transition: ease-in-out .2s;
    transition: ease-in-out .2s;
    overflow: hidden;
    position: relative; }

.open-case-bar {
  color: #191919;
  line-height: 1.5;
  letter-spacing: -0.5px;
  font-size: 16px;
  width: 100%;
  padding: 8px 16px;
  position: relative;
  cursor: pointer;
  border-bottom: solid 1px #dcdcdc; }
  .open-case-bar span {
    color: rgba(25, 25, 25, 0.4); }
  .open-case-bar svg {
    margin-right: 2px;
    position: relative;
    top: -2px;
    left: -2px;
    font-size: 18px; }
  .open-case-bar .expand-more-bar {
    position: absolute;
    right: 14px;
    top: 50%;
    -webkit-transform: translate(0, -50%);
            transform: translate(0, -50%);
    color: #191919;
    font-size: 23px;
    -webkit-transition: ease-in-out .3s;
    transition: ease-in-out .3s;
    margin: 0;
    left: unset; }
  .open-case-bar .expand-more-bar.active-expand {
    -webkit-transform: rotate(180deg) translate(0, 50%);
            transform: rotate(180deg) translate(0, 50%); }

.open-case-my-case-panel svg {
  color: #19c700; }

.open-case-switch-wrapper {
  width: 100%;
  background: #ffffff;
  position: relative;
  text-align: center;
  padding: 9px 16px;
  box-shadow: 0 1px 0 0 rgba(25, 25, 25, 0.1);
  z-index: 1;
  padding: 0 12px 8px; }
  .open-case-switch-wrapper .case-title-and-search-button-container {
    position: relative;
    width: 100%;
    height: 52px; }
    .open-case-switch-wrapper .case-title-and-search-button-container .case-room-search-wrapper {
      padding: 8px 0;
      -webkit-transition: ease-in-out .15s;
      transition: ease-in-out .15s;
      position: absolute;
      top: 0;
      right: calc(-100% - 24px);
      width: 100%; }
      .open-case-switch-wrapper .case-title-and-search-button-container .case-room-search-wrapper.active-case-room-search-wrapper {
        right: 0; }
      .open-case-switch-wrapper .case-title-and-search-button-container .case-room-search-wrapper #input-search-form {
        text-align: left; }
        .open-case-switch-wrapper .case-title-and-search-button-container .case-room-search-wrapper #input-search-form input[type="text"] {
          padding: 9px 11px 9px 11px;
          width: calc(100% - 50px); }
          .open-case-switch-wrapper .case-title-and-search-button-container .case-room-search-wrapper #input-search-form input[type="text"]::-webkit-input-placeholder {
            font-size: 12px;
            color: rgba(25, 25, 25, 0.4); }
          .open-case-switch-wrapper .case-title-and-search-button-container .case-room-search-wrapper #input-search-form input[type="text"]::-ms-input-placeholder {
            font-size: 12px;
            color: rgba(25, 25, 25, 0.4); }
          .open-case-switch-wrapper .case-title-and-search-button-container .case-room-search-wrapper #input-search-form input[type="text"]::placeholder {
            font-size: 12px;
            color: rgba(25, 25, 25, 0.4); }
        .open-case-switch-wrapper .case-title-and-search-button-container .case-room-search-wrapper #input-search-form .cancel-search {
          font-size: 14px;
          letter-spacing: -0.4px;
          color: #ff7d00;
          cursor: pointer;
          margin-left: 8px; }
          .open-case-switch-wrapper .case-title-and-search-button-container .case-room-search-wrapper #input-search-form .cancel-search:hover {
            color: var(--main-darker-orange); }
      .open-case-switch-wrapper .case-title-and-search-button-container .case-room-search-wrapper .submit-filter-button {
        right: 0; }
    .open-case-switch-wrapper .case-title-and-search-button-container .case-title-and-search-button-wrapper {
      position: absolute;
      left: calc(-100% - 24px);
      display: inline-block;
      -webkit-transition: ease-in-out .15s;
      transition: ease-in-out .15s;
      text-align: left;
      top: 0;
      width: 100%;
      height: 52px;
      line-height: 52px; }
      .open-case-switch-wrapper .case-title-and-search-button-container .case-title-and-search-button-wrapper.active-case-title-and-search-button-wrapper {
        left: 0; }
      .open-case-switch-wrapper .case-title-and-search-button-container .case-title-and-search-button-wrapper .inbox-title {
        font-size: 24px;
        letter-spacing: -0.6px; }
      .open-case-switch-wrapper .case-title-and-search-button-container .case-title-and-search-button-wrapper .new-message-button-wrapper {
        position: absolute;
        color: #191919;
        display: inline-block;
        background: #ffffff;
        white-space: nowrap;
        height: 52px;
        right: 0;
        cursor: pointer; }
        .open-case-switch-wrapper .case-title-and-search-button-container .case-title-and-search-button-wrapper .new-message-button-wrapper img {
          font-size: 20px; }
          .open-case-switch-wrapper .case-title-and-search-button-container .case-title-and-search-button-wrapper .new-message-button-wrapper img.icon-edit {
            color: #ff7d00;
            margin: 0 4px 0 21px;
            position: relative;
            top: -2px; }
          .open-case-switch-wrapper .case-title-and-search-button-container .case-title-and-search-button-wrapper .new-message-button-wrapper img.icon-search {
            position: relative;
            top: -2px;
            color: #191919; }
        .open-case-switch-wrapper .case-title-and-search-button-container .case-title-and-search-button-wrapper .new-message-button-wrapper p {
          font-size: 14px;
          letter-spacing: -0.4px;
          color: #ff7d00;
          display: inline-block;
          margin: 0; }
          .open-case-switch-wrapper .case-title-and-search-button-container .case-title-and-search-button-wrapper .new-message-button-wrapper p svg {
            font-size: 20px; }
  .open-case-switch-wrapper .open-case-switch-content {
    width: 100%;
    border-radius: 12px;
    height: 29px;
    box-shadow: 0 0 1px #dcdcdc;
    position: relative;
    background: #f3f3f3;
    cursor: pointer;
    margin-top: 3px; }
    .open-case-switch-wrapper .open-case-switch-content .open-case-active-panel {
      width: 50%;
      content: "";
      background: #ffffff;
      border-radius: 12px;
      height: 27px;
      position: absolute;
      left: 1px;
      top: 1px;
      -webkit-transition: ease-in-out .2s;
      transition: ease-in-out .2s; }
      .open-case-switch-wrapper .open-case-switch-content .open-case-active-panel.active-mine-case-panel {
        left: calc(50% - 1px); }
    .open-case-switch-wrapper .open-case-switch-content .open-case-switch-panel {
      width: 50%;
      display: inline-block;
      text-align: center;
      font-size: 14px;
      letter-spacing: -0.4px;
      color: rgba(25, 25, 25, 0.6);
      padding-top: 4px; }
      .open-case-switch-wrapper .open-case-switch-content .open-case-switch-panel:hover {
        color: #191919; }
      .open-case-switch-wrapper .open-case-switch-content .open-case-switch-panel div {
        display: inline-block;
        position: relative; }
      .open-case-switch-wrapper .open-case-switch-content .open-case-switch-panel .counter-case {
        font-size: 11px;
        color: #ffffff;
        text-align: center;
        background: #707070;
        border-radius: 12px;
        margin-left: 4px;
        height: 18px;
        display: inline-block;
        padding: 1px 0 0;
        min-width: 18px;
        position: relative;
        top: -1px;
        padding: 1px 5px 0 4px; }
      .open-case-switch-wrapper .open-case-switch-content .open-case-switch-panel.active-case-panel {
        color: #191919; }
        .open-case-switch-wrapper .open-case-switch-content .open-case-switch-panel.active-case-panel .counter-case {
          background: #474747; }

.open-case-sort-by-wrapper {
  box-shadow: 0 1px 0 0 rgba(25, 25, 25, 0.1);
  width: 100%;
  position: relative;
  z-index: 3;
  padding: 4px 12px 8px 12px;
  background: #ffffff;
  white-space: nowrap;
  height: 37px;
  left: 1px; }
  .open-case-sort-by-wrapper::-webkit-scrollbar {
    display: none; }
  .open-case-sort-by-wrapper .open-case-sort-by-wrapper-inner::-webkit-scrollbar {
    display: none; }
  .open-case-sort-by-wrapper .open-case-sort-by-wrapper-inner .sort-by-wrapper-inner-scroll {
    position: absolute;
    top: 4.5px;
    left: 160px;
    max-width: calc(100% - 171px);
    overflow-x: auto;
    margin: 0 5px; }
    .open-case-sort-by-wrapper .open-case-sort-by-wrapper-inner .sort-by-wrapper-inner-scroll .case-room-filter-tag-badge:nth-child(1) {
      margin-left: 0; }
    .open-case-sort-by-wrapper .open-case-sort-by-wrapper-inner .sort-by-wrapper-inner-scroll::-webkit-scrollbar {
      display: none; }
  .open-case-sort-by-wrapper .case-room-filter-tag-badge {
    margin: 2px 0 0 4px;
    color: rgba(25, 25, 25, 0.6); }
    .open-case-sort-by-wrapper .case-room-filter-tag-badge img {
      position: relative;
      top: -1px;
      margin-right: 3px; }

.modal-clock-on-new-message .modal-body {
  padding: 32px 0 20px !important; }

.other-case-tab-wrapper {
  background: #f3f3f3;
  width: calc(100% - 62px);
  display: inline-block;
  overflow: hidden; }
  .other-case-tab-wrapper .case-room-search-wrapper {
    display: none; }
  .other-case-tab-wrapper .case-room-filter-tag-badge {
    margin-top: 6px; }

.other-case-room-wrapper {
  height: calc(100vh - 53px);
  position: relative;
  /* max-height: calc(100vh - 105px); */ }
  .other-case-room-wrapper .need-reply-unread-panel-wrapper {
    width: 100%;
    text-align: center; }
    .other-case-room-wrapper .need-reply-unread-panel-wrapper .need-reply-panel {
      border-radius: 12px; }

.other-case-room-wrapper #input-search-form input::-webkit-input-placeholder {
  font-size: 13px;
  color: rgba(25, 25, 25, 0.4); }

.other-case-room-wrapper #input-search-form input::-ms-input-placeholder {
  font-size: 13px;
  color: rgba(25, 25, 25, 0.4); }

.other-case-room-wrapper #input-search-form input::placeholder {
  font-size: 13px;
  color: rgba(25, 25, 25, 0.4); }

.other-case-room-wrapper .lds-ring {
  margin-right: 5px;
  top: 3px !important;
  right: 0;
  right: 5px; }

.other-case-sort-by-wrapper {
  box-shadow: 0 1px 0 0 rgba(25, 25, 25, 0.1);
  width: 100%;
  position: relative;
  z-index: 3;
  padding: 4px 12px 8px 12px;
  background: #ffffff;
  white-space: nowrap;
  height: 37px;
  left: 1px; }
  .other-case-sort-by-wrapper::-webkit-scrollbar {
    display: none; }
  .other-case-sort-by-wrapper .other-case-sort-by-wrapper-inner .sort-by-content-wrapper {
    top: 1px; }
  .other-case-sort-by-wrapper .other-case-sort-by-wrapper-inner::-webkit-scrollbar {
    display: none; }
  .other-case-sort-by-wrapper .other-case-sort-by-wrapper-inner .sort-by-wrapper-inner-scroll {
    position: absolute;
    top: 4.5px;
    left: 160px;
    max-width: calc(100% - 171px);
    overflow-x: auto;
    margin: 0 5px; }
    .other-case-sort-by-wrapper .other-case-sort-by-wrapper-inner .sort-by-wrapper-inner-scroll .case-room-filter-tag-badge:nth-child(1) {
      margin-left: 0; }
    .other-case-sort-by-wrapper .other-case-sort-by-wrapper-inner .sort-by-wrapper-inner-scroll::-webkit-scrollbar {
      display: none; }
  .other-case-sort-by-wrapper .case-room-filter-tag-badge {
    margin: 3px 0 0 4px;
    color: rgba(25, 25, 25, 0.6); }
    .other-case-sort-by-wrapper .case-room-filter-tag-badge img {
      position: relative;
      top: -1px;
      margin-right: 3px; }

.solved-case-tab-wrapper {
  position: relative;
  /* top: 1px; */
  height: calc(100vh - 109px);
  background: #f3f3f3;
  width: calc(100% - 62px);
  display: inline-block;
  overflow: hidden; }
  .solved-case-tab-wrapper .case-room-search-wrapper {
    display: none; }
  .solved-case-tab-wrapper .case-room-filter-tag-badge {
    margin-top: 6px;
    color: rgba(25, 25, 25, 0.6); }
    .solved-case-tab-wrapper .case-room-filter-tag-badge img {
      position: relative;
      top: -1px;
      margin-right: 3px; }
    .solved-case-tab-wrapper .case-room-filter-tag-badge svg {
      color: rgba(25, 25, 25, 0.6); }

.solved-case-room-wrapper {
  height: calc(100vh - 191px);
  position: relative;
  /* max-height: calc(100vh - 192px); */
  background: #f3f3f3; }

.solved-case-tab-wrapper #input-search-form input::-webkit-input-placeholder {
  font-size: 13px;
  color: rgba(25, 25, 25, 0.4); }

.solved-case-tab-wrapper #input-search-form input::-ms-input-placeholder {
  font-size: 13px;
  color: rgba(25, 25, 25, 0.4); }

.solved-case-tab-wrapper #input-search-form input::placeholder {
  font-size: 13px;
  color: rgba(25, 25, 25, 0.4); }

.solved-case-room-wrapper .lds-ring {
  margin-right: 5px;
  top: 3px !important;
  right: 0;
  right: 5px; }

.case-modal-calendar .modal-content {
  width: 690px; }

.modal-date-filter-title {
  font-size: 20px;
  letter-spacing: -0.6px;
  padding-bottom: 6px;
  margin-bottom: 0; }
  .modal-date-filter-title svg {
    position: relative;
    top: -2px;
    margin-right: 12px; }

.case-modal-calendar .modal-body {
  border: none;
  padding: 32px 32px 0; }

.case-modal-calendar .modal-footer {
  border: none;
  padding: 0 32px 32px; }

.case-modal-calendar .modal-footer button {
  height: 48px;
  padding: 0 24px;
  margin: 0 8px 0 0; }

.case-modal-calendar .modal-footer button:nth-last-child(1) {
  margin: 0; }

.fetch-solved-case {
  width: 100%;
  position: relative;
  text-align: center;
  /* background: pink; */
  height: 45px;
  margin-bottom: 57px; }

.fetch-solved-case .lds-ring {
  top: 7px !important;
  right: unset;
  margin-right: 5px;
  vertical-align: top; }

.fetch-solved-case .lds-ring div {
  margin: 0; }

.fetch-solved-case-content {
  background: transparent;
  width: calc(100% - 10px);
  border-radius: 8px;
  border: none;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  /* background: lightblue; */
  height: 35px;
  /* cursor: default !important; */
  font-size: 14px;
  color: #ff7d00;
  line-height: 32px; }

.fetch-solved-case-content.retry-fetch {
  cursor: pointer;
  border: solid 1px #ff7d00; }

.fetch-solved-case-content.retry-fetch svg {
  position: relative;
  top: -1px;
  margin-right: 5px;
  font-size: 18px; }

.DayPicker:not(.DayPicker--interactionDisabled) .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
  background-color: rgba(25, 25, 25, 0.05) !important;
  border-radius: 50% !important;
  color: #191919; }

.Selectable .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
  background-color: #FFECD9 !important;
  color: #ff7d00; }

.Selectable .DayPicker-Day {
  border-radius: 0 !important;
  outline: 0; }

.Selectable .DayPicker-Day--start {
  border-top-left-radius: 50% !important;
  border-bottom-left-radius: 50% !important; }

.Selectable .DayPicker-Day--end {
  border-top-right-radius: 50% !important;
  border-bottom-right-radius: 50% !important; }

.setup-room-list-modal {
  background: #4e4e4e; }
  .setup-room-list-modal .modal-content {
    width: unset;
    min-width: 268px;
    display: inline-block;
    border-radius: 8px;
    padding: 32px 16px;
    text-align: center;
    position: relative; }
    .setup-room-list-modal .modal-content .setup-main-content .setup-image-content {
      height: 64px;
      width: 64px;
      display: inline-block;
      position: relative;
      border-radius: 50%;
      margin-bottom: 12px; }
      .setup-room-list-modal .modal-content .setup-main-content .setup-image-content .lds-ring {
        position: absolute;
        left: 0;
        top: 0;
        height: 64px;
        width: 64px; }
        .setup-room-list-modal .modal-content .setup-main-content .setup-image-content .lds-ring div {
          height: 64px;
          width: 64px;
          border: 8px solid;
          border-color: #ff7d00 transparent transparent transparent; }
      .setup-room-list-modal .modal-content .setup-main-content .setup-image-content img {
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%); }
    .setup-room-list-modal .modal-content .setup-main-content .setup-image-content.setup-success {
      border: 12px solid #19C700; }
    .setup-room-list-modal .modal-content .setup-main-content .setup-fail {
      color: #ff3f57; }
    .setup-room-list-modal .modal-content .setup-main-content b {
      font-size: 20px;
      letter-spacing: -0.6px;
      color: #ff7d00; }
    .setup-room-list-modal .modal-content .setup-main-content p {
      font-size: 16px;
      letter-spacing: -0.3px;
      margin: 8px 0 0; }
      .setup-room-list-modal .modal-content .setup-main-content p svg {
        font-size: 20px;
        margin-right: 7px;
        font-size: 25px;
        position: relative;
        top: -1px; }
    .setup-room-list-modal .modal-content .setup-main-content p.retry-setup b {
      color: #ff7d00;
      -webkit-transform: ease-in-out 0.1s;
              transform: ease-in-out 0.1s;
      cursor: pointer;
      position: absolute;
      bottom: -12px;
      left: 50%;
      -webkit-transform: translate(-50%, 0);
              transform: translate(-50%, 0);
      font-size: 16px; }
    .setup-room-list-modal .modal-content .setup-main-content p.retry-setup:hover {
      color: var(--main-darker-orange); }
  .setup-room-list-modal .lds-ring div {
    margin: 0; }

.modal-dialog.setup-room-list-modal {
  width: 100vw !important; }

.setup-room-list-modal-tier .modal-content {
  background: transparent !important;
  border: none !important;
  box-shadow: none !important; }

.setup-room-list-modal-tier .tier-blocking .alert-component {
  width: 402px;
  padding: 16px 24px !important; }
  .setup-room-list-modal-tier .tier-blocking .alert-component svg {
    top: -2px !important;
    left: unset !important;
    margin: 0 8px 0 0 !important;
    position: relative !important; }

.setup-room-list-modal-tier .tier-blocking b {
  font-size: 16px;
  letter-spacing: -0.5px; }

.setup-room-list-modal-tier .tier-blocking .alert-component-button-wrap {
  width: 100%;
  text-align: center;
  margin: 14px 0 0; }
  .setup-room-list-modal-tier .tier-blocking .alert-component-button-wrap button {
    position: relative !important;
    margin: 0 4px !important;
    width: 126px !important;
    top: unset !important;
    right: unset !important; }
    .setup-room-list-modal-tier .tier-blocking .alert-component-button-wrap button b {
      font-size: 12px;
      letter-spacing: -0.4px; }
    .setup-room-list-modal-tier .tier-blocking .alert-component-button-wrap button svg {
      top: -1px !important; }
  .setup-room-list-modal-tier .tier-blocking .alert-component-button-wrap .no-fill-blue-button {
    color: #044b84 !important;
    border: solid 1px #044b84 !important; }
    .setup-room-list-modal-tier .tier-blocking .alert-component-button-wrap .no-fill-blue-button svg {
      color: #044b84 !important; }

.chat-room-wrapper {
    background: #F3F3F3;
    /* width: calc(100% - 635px); */
    /* width: calc(100% - 360px); */
    width: calc(100% - 382px);
    display: inline-block;
    height: 100%;
    max-height: 100vh;
    vertical-align: top;
    overflow: hidden;
    box-shadow: -1px 0 #dcdcdc;
    position: relative;
}

.group-sender-avatar-wrapper img {
    vertical-align: top;
}
.chat-room-header-container {
  height: 48px;
  background: #ffffff;
  width: 100%;
  position: relative;
  box-shadow: 0 1px 0 0 rgba(25, 25, 25, 0.1);
  z-index: 1;
  -webkit-transition: ease-in-out .1s;
  transition: ease-in-out .1s;
  border-right: solid 1px #dcdcdc; }
  .chat-room-header-container.chat-room-header-show-detail {
    width: calc(100% - 340px);
    vertical-align: top; }

.chat-room-header-container .chat-room-header-avatar-wrapper {
  height: 40px;
  padding: 6px 0 0 16px;
  display: inline-block;
  position: relative;
  top: 2px;
  vertical-align: top; }

.chat-room-header-container .chat-room-header-avatar-wrapper .chat-rooom-omnichannel-header {
  position: relative;
  right: 8px; }

.chat-room-header-container .chat-room-identifier {
  position: relative;
  top: 2px;
  display: inline-block;
  max-width: calc(100% - 354px); }
  .chat-room-header-container .chat-room-identifier P {
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 100%; }

.chat-room-header-container .chat-room-identifier .connectivity-status {
  position: relative;
  color: rgba(25, 25, 25, 0.6);
  font-size: 12px;
  letter-spacing: -0.2px;
  line-height: 16px;
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden; }
  .chat-room-header-container .chat-room-identifier .connectivity-status.is-typing {
    color: var(--main-dark-orange); }

.chat-room-header-container .chat-room-identifier .connectivity-status.status-online::before {
  content: "";
  background: #19C700;
  border-radius: 8px;
  height: 8px;
  width: 8px;
  position: absolute;
  left: 0;
  top: 50%;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%); }

.chat-room-header-container .user-avatar-name {
  width: 32px;
  height: 32px;
  display: inline-block;
  border-radius: 50%;
  line-height: 32px;
  color: #ffffff;
  position: relative;
  text-align: center;
  /* cursor: pointer; */
  background: #f9f9f9;
  font-size: 15px;
  vertical-align: top; }

.chat-room-header-container img {
  height: 32px;
  width: 32px;
  border-radius: 50%;
  /* right: 24px; */
  /* cursor: pointer; */ }

.chat-room-header-container .button-icon img {
  height: 12px !important;
  width: 12px !important;
  border-radius: unset !important; }

.open-detail-button {
  cursor: pointer;
  -webkit-transition: ease-in-out;
  transition: ease-in-out;
  position: relative;
  /* right: 16px;
  top: 50%;
  transform: translate(0, -50%); */
  color: #ff7d00;
  font-size: 14px;
  letter-spacing: -0.5px;
  -webkit-transition: ease-in-out .3s;
  transition: ease-in-out .3s;
  border-radius: 6px;
  /* padding: 7px 24px 8px 24px; */
  padding: 0 12px 0 10px !important;
  /* line-height: 25px; */
  display: inline-block;
  -webkit-transition: ease-in-out .1s;
  transition: ease-in-out .1s;
  vertical-align: top;
  /* height: 100%; */
  height: 26px;
  line-height: 26px; }

.open-detail-button:hover {
  background: #fee7ce; }

.open-detail-button svg {
  font-size: 14px;
  position: relative;
  top: -2px;
  margin-right: 4px; }

.chat-room-header-container .chatroom-header-right-button-wrapper {
  display: inline-block;
  position: absolute;
  right: 12px;
  top: 0;
  /* transform: translate(0, -50%); */
  height: 100%;
  padding: 11px 0; }

.chat-room-header-container .mark-case-button {
  position: relative;
  display: inline-block; }
  .chat-room-header-container .mark-case-button .mark-case-dropdown {
    background-color: #ffffff;
    display: none;
    position: absolute;
    width: 165px;
    right: 50%;
    -webkit-transform: translateX(50.5px);
            transform: translateX(50.5px);
    border-radius: 8px;
    top: calc(100% + 25px);
    box-shadow: 0 5px 12px 0 rgba(0, 0, 0, 0.16), 0 2px 5px 0 rgba(0, 0, 0, 0.16); }
    .chat-room-header-container .mark-case-button .mark-case-dropdown ul {
      padding: 0;
      margin: 0; }
    .chat-room-header-container .mark-case-button .mark-case-dropdown li {
      padding: 8px;
      color: rgba(25, 25, 25, 0.6);
      list-style: none;
      cursor: pointer; }
      .chat-room-header-container .mark-case-button .mark-case-dropdown li svg {
        vertical-align: -2px;
        margin-right: 8px; }
    .chat-room-header-container .mark-case-button .mark-case-dropdown li:hover {
      color: #FF7E00; }
  .chat-room-header-container .mark-case-button .mark-case-dropdown:after {
    bottom: 100%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none; }
  .chat-room-header-container .mark-case-button .mark-case-dropdown:after {
    border-color: rgba(255, 255, 255, 0);
    border-bottom-color: #ffffff;
    border-width: 12px;
    left: 80%;
    margin-left: -12px; }

.chat-room-header-container .mark-as-resolved-wrapper {
  display: inline-block;
  /* height: 100%; */
  height: 26px;
  top: -2px !important; }
  .chat-room-header-container .mark-as-resolved-wrapper .button-text-wrapper b {
    font-size: 14px;
    position: relative;
    top: -1px; }

.chat-room-header-container .mark-as-resolved-wrapper button {
  cursor: pointer;
  -webkit-transition: ease-in-out;
  transition: ease-in-out;
  position: relative;
  /* right: 178px;
  top: 50%;
  transform: translate(0, -50%); */
  font-size: 14px;
  letter-spacing: -0.5px;
  -webkit-transition: ease-in-out .3s;
  transition: ease-in-out .3s;
  border-radius: 6px;
  /* padding: 6px 0 7px; */
  /* line-height: 24px; */
  /* width: 191px; */
  height: 100%;
  margin-right: 8px;
  padding: 0 12px 0 10px !important;
  vertical-align: top;
  line-height: 24px;
  background: #ff7d00; }

.chat-room-header-container .mark-as-resolved-wrapper button svg {
  position: relative;
  /* top: -2px; */
  font-size: 14px;
  /* margin-right: 4px; */ }

.chat-room-header-container .mark-as-resolved-wrapper .lds-ring {
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  left: 50%;
  right: unset;
  position: absolute; }

.chat-room-header-container .mark-as-resolved-wrapper .lds-ring div {
  margin: 0; }

.modal-handover-case .modal-content {
  width: 500px;
  max-height: 90%;
  border-radius: 8px; }
  .modal-handover-case .modal-content .modal-body {
    padding: 32px;
    border-radius: 8px; }
    .modal-handover-case .modal-content .modal-body .modal-handover-description {
      color: rgba(25, 25, 25, 0.6);
      font-size: 14px;
      letter-spacing: -0.2px;
      margin-bottom: 0; }
    .modal-handover-case .modal-content .modal-body label {
      font-size: 14px;
      letter-spacing: -0.4px;
      margin-top: 24px;
      letter-spacing: -0.34px; }
    .modal-handover-case .modal-content .modal-body .modal-handover-case-title {
      font-size: 20px;
      letter-spacing: -0.6px;
      margin: 0; }
    .modal-handover-case .modal-content .modal-body .handover-case-button-wrapper {
      width: 100%; }
      .modal-handover-case .modal-content .modal-body .handover-case-button-wrapper button {
        margin-top: 20px;
        width: calc(50% - 4px);
        display: inline-block;
        margin-right: 8px; }
        .modal-handover-case .modal-content .modal-body .handover-case-button-wrapper button:nth-child(2) {
          margin-right: 0; }

.tooltip-dropdown-button-wrapper {
  position: relative;
  display: inline-block; }
  .tooltip-dropdown-button-wrapper .tooltip-dropdown {
    background-color: #ffffff;
    position: absolute;
    width: 165px;
    right: 50%;
    -webkit-transform: translateX(50.5px);
            transform: translateX(50.5px);
    border-radius: 8px;
    top: calc(100% + 21px);
    box-shadow: 0 5px 12px 0 rgba(0, 0, 0, 0.16), 0 2px 5px 0 rgba(0, 0, 0, 0.16); }
    .tooltip-dropdown-button-wrapper .tooltip-dropdown ul {
      padding: 0;
      margin: 0; }
    .tooltip-dropdown-button-wrapper .tooltip-dropdown li {
      padding: 8px;
      font-size: 14px;
      letter-spacing: -0.4px;
      color: rgba(25, 25, 25, 0.6);
      list-style: none;
      cursor: pointer; }
      .tooltip-dropdown-button-wrapper .tooltip-dropdown li img {
        width: 18px;
        height: 18px;
        margin-right: 6px;
        margin-bottom: 3px; }
      .tooltip-dropdown-button-wrapper .tooltip-dropdown li svg {
        vertical-align: -2px;
        margin-right: 10px;
        position: relative;
        left: 2px; }
    .tooltip-dropdown-button-wrapper .tooltip-dropdown li:hover {
      color: #ff7e00; }
  .tooltip-dropdown-button-wrapper .tooltip-dropdown:after {
    bottom: 100%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none; }
  .tooltip-dropdown-button-wrapper .tooltip-dropdown:after {
    border-color: rgba(255, 255, 255, 0);
    border-bottom-color: #ffffff;
    border-width: 9px;
    left: 80%;
    margin-left: -12px; }
  .tooltip-dropdown-button-wrapper .tooltip-dropdown-button {
    display: inline-block;
    /* height: 100%; */
    height: 26px; }
  .tooltip-dropdown-button-wrapper .tooltip-dropdown-button button {
    cursor: pointer;
    -webkit-transition: ease-in-out;
    transition: ease-in-out;
    position: relative;
    /* right: 178px;
      top: 50%;
      transform: translate(0, -50%); */
    font-size: 14px;
    letter-spacing: -0.5px;
    -webkit-transition: ease-in-out 0.3s;
    transition: ease-in-out 0.3s;
    border-radius: 6px;
    /* padding: 6px 0 7px; */
    /* line-height: 24px; */
    /* width: 191px; */
    height: 100%;
    margin-right: 8px;
    padding: 0 12px 0 10px !important;
    vertical-align: top;
    line-height: 24px;
    background: #ff7d00; }
  .tooltip-dropdown-button-wrapper .tooltip-dropdown-button button svg {
    position: relative;
    /* top: -2px; */
    font-size: 14px;
    /* margin-right: 4px; */ }
  .tooltip-dropdown-button-wrapper .tooltip-dropdown-button .lds-ring {
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    left: 50%;
    right: unset;
    position: absolute; }
  .tooltip-dropdown-button-wrapper .tooltip-dropdown-button .lds-ring div {
    margin: 0; }

.no-chat-container {
    width: 100%;
    text-align: center;
    padding-top: 250px;
    display: inline-block;
  }
  .no-chat-container .no-chat-content {
    display: inline-block;
    padding: 15px 16px;
    background: #dcebff;
    border-radius: 8px;
    color: #044b84;
    font-size: 16px;
    letter-spacing: -0.5px;
    /* line-height: 48px; */
    max-width: calc(100% - 100px);
  }
  .no-chat-container .no-chat-content svg {
    font-size: 18px;
    margin-right: 6px;
    position: relative;
    top: -2px;
  }
.chat-room-main-wrapper {
  max-height: calc(100vh - 48px);
  overflow: auto;
  position: relative;
  width: 100%;
  height: 100%;
  -webkit-transition: ease-in-out .2s;
  transition: ease-in-out .2s;
  display: inline-block; }
  .chat-room-main-wrapper .hide-message-info-status .message-info img {
    display: none; }
  .chat-room-main-wrapper .chat-room-main-wrapper-loading-blocking {
    position: absolute;
    background: #f3f3f3;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    border-top: solid 1px #dcdcdc;
    border-right: solid 1px #dcdcdc; }
    .chat-room-main-wrapper .chat-room-main-wrapper-loading-blocking .lds-ring {
      width: 32px;
      height: 32px;
      position: absolute;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%); }
      .chat-room-main-wrapper .chat-room-main-wrapper-loading-blocking .lds-ring div {
        border: solid 3px #ffffff;
        border-color: #ff7d00 transparent transparent transparent;
        width: 32px;
        height: 32px; }
  .chat-room-main-wrapper .room-is-unavailable {
    padding: 4px 16px;
    border-radius: 8px;
    box-shadow: 0px 2px 20px 0px rgba(25, 25, 25, 0.05);
    display: inline-block;
    background: #ffffff;
    color: rgba(25, 25, 25, 0.4);
    font-size: 12px;
    letter-spacing: -0.4px;
    line-height: 18px;
    text-align: center;
    position: absolute;
    top: 40%;
    left: 50%;
    -webkit-transform: translate(-50%, 0);
            transform: translate(-50%, 0); }
  .chat-room-main-wrapper .chat-room-main-content {
    padding: 0 0 16px;
    max-height: 100%;
    overflow-y: auto;
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 0; }
    .chat-room-main-wrapper .chat-room-main-content .loading-message-wrapper {
      padding-top: 16px;
      text-align: center;
      width: 100%; }
      .chat-room-main-wrapper .chat-room-main-content .loading-message-wrapper .lds-ring {
        top: unset;
        right: unset; }
        .chat-room-main-wrapper .chat-room-main-content .loading-message-wrapper .lds-ring div {
          margin: 0;
          border-color: #ff7d00 transparent transparent transparent; }
  .chat-room-main-wrapper .message-bubble-blue {
    background: #EBF7FF !important; }
    .chat-room-main-wrapper .message-bubble-blue .link-preview-container .link-preview-side-decoration-out {
      background: var(--main-dark-orange); }
    .chat-room-main-wrapper .message-bubble-blue .link-preview-container .link-preview-title-out {
      color: #191919; }
    .chat-room-main-wrapper .message-bubble-blue .link-preview-container .link-preview-description-out {
      color: #191919; }
    .chat-room-main-wrapper .message-bubble-blue .message-info img {
      display: none; }
  .chat-room-main-wrapper .message-bubble-organization {
    background: #fff !important;
    border: solid 1px var(--main-dark-orange) !important; }
    .chat-room-main-wrapper .message-bubble-organization .link-preview-container .link-preview-side-decoration-out {
      background: var(--main-dark-orange); }
    .chat-room-main-wrapper .message-bubble-organization .link-preview-container .link-preview-title-out {
      color: #191919; }
    .chat-room-main-wrapper .message-bubble-organization .link-preview-container .link-preview-description-out {
      color: #191919; }
    .chat-room-main-wrapper .message-bubble-organization .message-info img {
      display: none; }

.chat-room-main-wrapper.chat-room-respond-is-disabled {
  max-height: calc(100vh - 122px) !important; }
  .chat-room-main-wrapper.chat-room-respond-is-disabled .chat-room-main-content {
    overflow: auto;
    max-height: calc(100vh - 122px) !important; }

.chat-room-main-wrapper.force-hide-input-message {
  max-height: calc(100vh - 176px) !important; }
  .chat-room-main-wrapper.force-hide-input-message .chat-room-main-content {
    overflow: auto;
    max-height: calc(100vh - 176px) !important; }

.chat-room-main-wrapper.mark-as-solved-is-active {
  max-height: calc(100vh - 142px) !important; }
  .chat-room-main-wrapper.mark-as-solved-is-active .chat-room-main-content {
    overflow: auto; }

.chat-room-main-wrapper.chat-room-panel-info-show {
  max-height: calc(100vh - 176px) !important; }
  .chat-room-main-wrapper.chat-room-panel-info-show .chat-room-main-content {
    overflow: auto;
    max-height: 100% !important; }

.chat-room-main-wrapper.chat-room-nopanel-input-or-panel-info {
  max-height: calc(100vh - 48px); }
  .chat-room-main-wrapper.chat-room-nopanel-input-or-panel-info .chat-room-main-content {
    overflow: auto; }

.chat-room-main-wrapper.chat-room-show-input-message {
  max-height: calc(100vh - 102px); }
  .chat-room-main-wrapper.chat-room-show-input-message .chat-room-main-content {
    overflow: auto; }

.no-longer-participant, .last-message-received {
  text-align: center;
  background: #fff4b1;
  width: 100%;
  color: #ff7d00;
  padding: 12px;
  -webkit-transition: ease-in-out .2s;
  transition: ease-in-out .2s;
  display: inline-block;
  position: relative;
  z-index: 1; }
  .no-longer-participant .icon-title-send-template, .last-message-received .icon-title-send-template {
    margin-right: 4px;
    position: relative;
    top: -2px; }
  .no-longer-participant img, .last-message-received img {
    width: 16px;
    position: relative;
    top: -2px;
    margin-right: 5px; }
  .no-longer-participant svg, .last-message-received svg {
    height: 16px;
    width: 16px; }
  .no-longer-participant p, .last-message-received p {
    font-size: 16px;
    letter-spacing: -0.5px;
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; }
    .no-longer-participant p:nth-child(2), .last-message-received p:nth-child(2) {
      margin: 0 0 2px;
      font-size: 16px;
      letter-spacing: -0.2px; }
  .no-longer-participant button, .last-message-received button {
    width: 100%;
    padding: 10px 0;
    font-size: 16px;
    color: #ffffff;
    letter-spacing: -0.5px;
    margin-top: 6px; }
  .no-longer-participant .lds-ring, .last-message-received .lds-ring {
    top: 2px;
    right: unset; }
    .no-longer-participant .lds-ring div, .last-message-received .lds-ring div {
      margin: 0;
      border-color: #ffffff transparent transparent transparent; }

.wrap-all-chatroom {
  width: 100%;
  height: calc(100% - 48px);
  -webkit-transition: ease-in-out .2s;
  transition: ease-in-out .2s;
  display: inline-block;
  font-size: 0;
  position: relative; }
  .wrap-all-chatroom .drop-file-here-wrapper {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    display: none;
    text-align: center;
    font-size: 24px;
    letter-spacing: -0.5px;
    background: #eaeaea;
    z-index: 3; }
    .wrap-all-chatroom .drop-file-here-wrapper.active-drop-file-here-wrapper {
      display: block; }
    .wrap-all-chatroom .drop-file-here-wrapper svg {
      font-size: 48px;
      margin-bottom: 16px; }
    .wrap-all-chatroom .drop-file-here-wrapper p {
      margin: 0; }
    .wrap-all-chatroom .drop-file-here-wrapper .drop-file-here-content {
      width: 80%;
      height: 80%;
      padding: 40px;
      border: dashed 4px #191919;
      display: inline-block;
      position: absolute;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%);
      line-height: 80%;
      color: #191919; }
      .wrap-all-chatroom .drop-file-here-wrapper .drop-file-here-content .drop-file-here-inner-content {
        width: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
        padding: 24px; }

.wrap-all-chatroom.active-chat-user-info {
  width: calc(100% - 340px); }

@-webkit-keyframes highlight {
  0% {
    background: #FFF4B1; }
  100% {
    background: none; } }

@keyframes highlight {
  0% {
    background: #FFF4B1; }
  100% {
    background: none; } }

.highlight-chat-bubble {
  -webkit-animation: highlight 2s;
          animation: highlight 2s; }

.with-reply img, .with-reply video {
  border-radius: 0 !important; }

.hide-reply-button .reply-button {
  display: none !important; }

.forward-button {
  -webkit-transform: scaleX(-1);
          transform: scaleX(-1); }

.message-body, .caption-text {
  cursor: text; }

.message-action-button-wrapper {
  cursor: pointer; }
  .message-action-button-wrapper:hover {
    background: rgba(25, 25, 25, 0.05); }
    .message-action-button-wrapper:hover svg {
      color: rgba(25, 25, 25, 0.8) !important; }

.tap-hyperlink {
  color: unset !important;
  text-decoration: underline !important; }

.message-in-bubble .tap-hyperlink {
  color: #039be5 !important;
  text-decoration: underline !important; }

.marker-map {
  position: relative;
  width: 50px;
  height: 5px; }
  .marker-map img {
    position: absolute;
    top: -37px;
    left: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, 0);
            transform: translate(-50%, 0); }

.chat-room-info-wrapper {
    width: 100%;
    text-align: center;
    position: relative;
    padding-top: 16px;
  }
  .chat-room-info-wrapper .chat-room-info-content {
    padding: 4px 16px;
    border-radius: 8px;
    box-shadow: 0px 2px 20px 0px rgba(25, 25, 25, 0.05);
    display: inline-block;
    background: #ffffff;
    color: rgba(25, 25, 25, 0.4);
    font-size: 12px;
    letter-spacing: -0.4px;
    line-height: 18px;
    text-align: center;
    max-width: calc(100% - 40px);
    word-break: break-word;
  }
.chat-room-message-in-wrapper {
  margin-top: 16px;
  padding: 0 24px;
  width: 100%;
  position: relative; }

.chat-room-message-in-wrapper .message-in-bubble {
  border-radius: 2px 16px 16px;
  border: 1px solid rgba(25, 25, 25, 0.1);
  background: #ffffff;
  display: inline-block;
  /* max-width: calc(100% - 180px); */
  max-width: calc(100% - 110px);
  padding: 10px 45px 10px 10px;
  padding: 10px;
  color: #191919;
  font-size: 16px;
  letter-spacing: -0.3px;
  line-height: 24px;
  position: relative;
  min-height: 44px; }

.chat-room-message-in-wrapper .message-in-bubble.deleted-bubble {
  color: #9b9b9b;
  padding: 10px 10px 10px 33px; }

.chat-room-message-in-wrapper .message-in-bubble.deleted-bubble .deleted-icon {
  position: absolute;
  left: 10px;
  top: 50%;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%); }

.chat-room-message-in-wrapper .message-in-bubble.deleted-group-in {
  padding: 10px; }

.chat-room-message-in-wrapper .message-in-bubble.deleted-group-in .deleted-icon {
  position: relative;
  left: 0;
  top: 5px;
  margin-right: 3px;
  -webkit-transform: unset;
          transform: unset; }

.chat-room-message-in-wrapper .message-in-bubble .forwarded-message {
  color: #191919;
  font-size: 12px;
  letter-spacing: -0.4px;
  line-height: 18px;
  display: block;
  margin-bottom: 5px; }

.chat-room-message-in-wrapper .message-in-bubble .reply-message {
  width: calc(100% - 20px);
  border-radius: 4px;
  border-left: solid 5px #ff7d00;
  background: #f3f3f3;
  color: #191919;
  position: absolute;
  top: 10px;
  left: 10px;
  padding: 5px 7px 5px 7px;
  line-height: 1; }

.chat-room-message-in-wrapper .message-in-bubble .reply-message p {
  margin: 0;
  font-size: 12px;
  letter-spacing: -0.4px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 20px; }

.chat-room-message-in-wrapper .message-in-bubble .reply-message p:nth-child(2) {
  letter-spacing: -0.2px; }

.chat-room-message-in-wrapper .message-in-bubble .reply-message.reply-file .reply-file-thumbnail {
  display: inline-block;
  width: 40px;
  height: 40px;
  margin-right: 10px;
  position: relative; }

.chat-room-message-in-wrapper .message-in-bubble .reply-message.reply-file .reply-file-thumbnail img {
  width: 40px;
  height: 40px; }

.chat-room-message-in-wrapper .message-in-bubble .reply-message.reply-file .reply-file-thumbnail svg {
  position: absolute;
  left: 50%;
  top: 50%;
  font-size: 35px;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%); }

.chat-room-message-in-wrapper .message-in-bubble .reply-message.reply-file .reply-text-wrapper {
  width: calc(100% - 50px);
  display: inline-block;
  vertical-align: top; }

.chat-room-message-in-wrapper .message-in-bubble .message-info {
  /* position: absolute; */
  margin: 0;
  /* bottom: 11px; */
  /* right: 10px; */
  color: rgba(25, 25, 25, 0.4);
  font-size: 10px;
  letter-spacing: -0.4px;
  line-height: 18px;
  /* width: 24px; */
  width: 100%;
  text-align: right;
  /* width: 26px; */ }
  .chat-room-message-in-wrapper .message-in-bubble .message-info .centered-dot {
    width: 2px;
    height: 2px;
    display: inline-block;
    vertical-align: top;
    background: rgba(25, 25, 25, 0.4);
    border-radius: 50%;
    position: relative;
    top: 9px; }

.chat-room-message-in-wrapper .message-in-bubble .group-sender-name-wrapper {
  color: #ff7d00;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
  font-size: 14px; }

.chat-room-message-in-wrapper .message-in-bubble .group-sender-name-wrapper .agent-span {
  color: #A3A3A3; }

.chat-room-message-in-wrapper:hover .message-action-wrapper {
  display: inline-block; }

.chat-room-message-in-wrapper:hover .message-action-dropdown {
  display: inline-block; }

.chat-room-message-in-wrapper .group-sender-avatar-wrapper {
  width: 32px;
  height: 32px;
  display: inline-block;
  border-radius: 50%;
  vertical-align: top;
  margin-right: 8px;
  font-size: 16px;
  line-height: 32px;
  text-align: center;
  color: #ffffff;
  position: relative; }

.chat-room-message-in-wrapper .group-sender-avatar-wrapper .avatar-icon-badge {
  width: 20px;
  bottom: -2px;
  right: -1px;
  height: 20px;
  position: absolute; }

.chat-room-message-in-wrapper .group-sender-avatar-wrapper img {
  width: 32px;
  height: 32px;
  display: inline-block;
  border-radius: 50%; }

.chat-room-message-in-wrapper .message-action-wrapper {
  /* right: -120px !important; */
  right: -80px !important; }
  .chat-room-message-in-wrapper .message-action-wrapper.with-forward {
    right: -118px !important; }
    .chat-room-message-in-wrapper .message-action-wrapper.with-forward .forward-button {
      display: inline-block !important; }

.hide-reply-button .message-in-bubble .message-action-wrapper {
  right: -40px !important; }
  .hide-reply-button .message-in-bubble .message-action-wrapper.with-forward {
    right: -78px !important; }

.reply-message-in-bubble {
  width: 100%;
  display: block;
  position: relative;
  background: #f3f3f3;
  margin-bottom: 4px;
  border-radius: 8px;
  overflow: hidden;
  cursor: pointer; }
  .reply-message-in-bubble.with-media-or-file {
    padding: 8px 0; }
  .reply-message-in-bubble .reply-message-in-bubble-name-text-wrapper {
    padding: 0 8px; }
    .reply-message-in-bubble .reply-message-in-bubble-name-text-wrapper.with-border {
      padding: 0 8px 0 12px; }
      .reply-message-in-bubble .reply-message-in-bubble-name-text-wrapper.with-border::before {
        position: absolute;
        left: 0;
        top: 0;
        width: 4px;
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
        background: #ff7e00;
        content: "";
        height: 100%; }
    .reply-message-in-bubble .reply-message-in-bubble-name-text-wrapper .reply-file-media-wrapper {
      border-radius: 4px;
      width: 48px;
      height: 48px;
      display: inline-block;
      margin: 0 8px 0 0;
      vertical-align: top;
      position: relative;
      top: 0; }
      .reply-message-in-bubble .reply-message-in-bubble-name-text-wrapper .reply-file-media-wrapper .reply-message-image {
        width: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
        max-width: 100%;
        max-height: 100%;
        border-radius: 0 !important; }
      .reply-message-in-bubble .reply-message-in-bubble-name-text-wrapper .reply-file-media-wrapper .reply-message-video {
        width: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
        max-width: 100%;
        max-height: 100%;
        border-radius: 0 !important; }
      .reply-message-in-bubble .reply-message-in-bubble-name-text-wrapper .reply-file-media-wrapper .reply-message-file {
        border-radius: 50%;
        width: 100%;
        height: 100%;
        position: relative;
        background: #ff7e00; }
        .reply-message-in-bubble .reply-message-in-bubble-name-text-wrapper .reply-file-media-wrapper .reply-message-file svg {
          color: #ffffff;
          font-size: 22px;
          position: absolute;
          top: 50%;
          left: 50%;
          -webkit-transform: translate(-50%, -50%);
                  transform: translate(-50%, -50%); }
    .reply-message-in-bubble .reply-message-in-bubble-name-text-wrapper .reply-message-in-bubble-reply-name-wrapper {
      position: relative;
      display: inline-block;
      padding: 6px 0 8px;
      width: 100%; }
      .reply-message-in-bubble .reply-message-in-bubble-name-text-wrapper .reply-message-in-bubble-reply-name-wrapper p {
        margin: 0;
        color: #191919; }
        .reply-message-in-bubble .reply-message-in-bubble-name-text-wrapper .reply-message-in-bubble-reply-name-wrapper p.reply-message-in-bubble-reply-name {
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          max-width: 100%;
          font-size: 12px;
          line-height: 18px;
          letter-spacing: -0.4px; }
        .reply-message-in-bubble .reply-message-in-bubble-name-text-wrapper .reply-message-in-bubble-reply-name-wrapper p.reply-message-in-bubble-reply-text {
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          max-width: 100%;
          font-size: 12px;
          line-height: 16px;
          letter-spacing: -0.2px;
          color: rgba(25, 25, 25, 0.8); }
    .reply-message-in-bubble .reply-message-in-bubble-name-text-wrapper.with-media-file {
      padding: 0 8px 0 12px; }
      .reply-message-in-bubble .reply-message-in-bubble-name-text-wrapper.with-media-file .reply-message-in-bubble-reply-name-wrapper {
        width: calc(100% - 66px); }

.link-preview-container {
  position: relative;
  max-width: 100%;
  margin-top: 8px;
  margin-bottom: 12px;
  font-size: 12px; }
  .link-preview-container .link-preview-side-decoration-in {
    position: absolute;
    width: 2px;
    height: 100%;
    left: 0px;
    background-color: var(--main-dark-orange); }
  .link-preview-container .link-preview-side-decoration-out {
    position: absolute;
    width: 2px;
    height: 100%;
    left: 0px;
    background-color: white; }
  .link-preview-container .link-preview-title-in {
    width: calc(100% - 12px);
    position: relative;
    top: -2px;
    margin-left: 12px;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: -0.2px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #191919; }
  .link-preview-container .link-preview-title-out {
    width: calc(100% - 12px);
    position: relative;
    top: -2px;
    margin-left: 12px;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: -0.2px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: white;
    margin-bottom: 0; }
  .link-preview-container .link-preview-description-in {
    position: relative;
    width: calc(100% - 12px);
    max-height: 112px;
    margin-left: 12px;
    margin-bottom: 0px;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: -0.2px;
    text-overflow: ellipsis;
    word-wrap: break-word;
    overflow: hidden;
    color: #191919;
    margin-bottom: 0; }
  .link-preview-container .link-preview-description-out {
    position: relative;
    width: calc(100% - 12px);
    max-height: 112px;
    margin-left: 12px;
    margin-bottom: 0px;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: -0.2px;
    text-overflow: ellipsis;
    word-wrap: break-word;
    overflow: hidden;
    color: white; }
  .link-preview-container .link-preview-image {
    position: relative;
    max-width: calc(100% - 12px);
    max-height: 200px;
    margin-top: 12px;
    margin-left: 12px;
    border-radius: 8px;
    object-fit: contain; }

.message-action-dropdown {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  position: absolute !important;
  top: 50%;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%);
  display: none; }
  .message-action-dropdown.message-action-show {
    z-index: 2;
    display: block !important; }
  .message-action-dropdown.message-action-dropdown-left {
    right: -40px; }
  .message-action-dropdown.message-action-dropdown-right {
    left: -40px; }
  .message-action-dropdown button {
    padding: 0;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    outline: none !important;
    background: unset !important;
    border: none !important;
    box-shadow: none !important; }
    .message-action-dropdown button:hover {
      background: #dddddd !important; }
  .message-action-dropdown .dropdown-menu {
    width: 168px;
    border-radius: 8px;
    padding: 0;
    overflow: hidden;
    border: none !important;
    box-shadow: 0px 2px 20px 0px rgba(25, 25, 25, 0.18); }
    .message-action-dropdown .dropdown-menu button {
      width: 100%;
      height: unset !important;
      background: none !important;
      border-radius: 0 !important;
      padding: 12px 16px;
      font-size: 16px;
      letter-spacing: -0.3px; }
      .message-action-dropdown .dropdown-menu button:hover {
        background: #FFF2E5 !important; }
      .message-action-dropdown .dropdown-menu button img {
        margin: 0 8px 0 0;
        position: relative;
        top: -2px; }
  .message-action-dropdown .dropdown-item {
    color: #191919 !important; }

.chat-room-message-in-wrapper {
  margin-top: 16px;
  padding: 0 24px;
  width: 100%;
  position: relative; }

.chat-room-message-in-wrapper .message-in-bubble-rating {
  border-radius: 2px 16px 16px;
  border: 1px solid rgba(25, 25, 25, 0.1);
  background: #ffffff;
  display: inline-block;
  /* max-width: calc(100% - 180px); */
  max-width: calc(100% - 50px);
  padding: 10px 45px 10px 10px;
  padding: 10px;
  color: #191919;
  font-size: 16px;
  letter-spacing: -0.3px;
  line-height: 24px;
  position: relative;
  min-height: 44px; }

.chat-room-message-in-wrapper .message-in-bubble-rating.deleted-bubble {
  color: #9b9b9b;
  padding: 10px 10px 10px 33px; }

.chat-room-message-in-wrapper .message-in-bubble-rating.deleted-bubble .deleted-icon {
  position: absolute;
  left: 10px;
  top: 50%;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%); }

.chat-room-message-in-wrapper .message-in-bubble-rating.deleted-group-in {
  padding: 10px; }

.chat-room-message-in-wrapper .message-in-bubble-rating.deleted-group-in .deleted-icon {
  position: relative;
  left: 0;
  top: 5px;
  margin-right: 3px;
  -webkit-transform: unset;
          transform: unset; }

.chat-room-message-in-wrapper .message-in-bubble-rating .forwarded-message {
  color: #191919;
  font-size: 12px;
  letter-spacing: -0.4px;
  line-height: 18px;
  display: block;
  margin-bottom: 5px; }

.chat-room-message-in-wrapper .message-in-bubble-rating .reply-message {
  width: calc(100% - 20px);
  border-radius: 4px;
  border-left: solid 5px #ff7d00;
  background: #f3f3f3;
  color: #191919;
  position: absolute;
  top: 10px;
  left: 10px;
  padding: 5px 7px 5px 7px;
  line-height: 1; }

.chat-room-message-in-wrapper .message-in-bubble-rating .reply-message p {
  margin: 0;
  font-size: 12px;
  letter-spacing: -0.4px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 20px; }

.chat-room-message-in-wrapper .message-in-bubble-rating .reply-message p:nth-child(2) {
  letter-spacing: -0.2px; }

.chat-room-message-in-wrapper .message-in-bubble-rating .reply-message.reply-file .reply-file-thumbnail {
  display: inline-block;
  width: 40px;
  height: 40px;
  margin-right: 10px;
  position: relative; }

.chat-room-message-in-wrapper .message-in-bubble-rating .reply-message.reply-file .reply-file-thumbnail img {
  width: 40px;
  height: 40px; }

.chat-room-message-in-wrapper .message-in-bubble-rating .reply-message.reply-file .reply-file-thumbnail svg {
  position: absolute;
  left: 50%;
  top: 50%;
  font-size: 35px;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%); }

.chat-room-message-in-wrapper .message-in-bubble-rating .reply-message.reply-file .reply-text-wrapper {
  width: calc(100% - 50px);
  display: inline-block;
  vertical-align: top; }

.chat-room-message-in-wrapper .message-in-bubble-rating .message-info {
  /* position: absolute; */
  margin: 0;
  /* bottom: 11px; */
  /* right: 10px; */
  color: rgba(25, 25, 25, 0.4);
  font-size: 10px;
  letter-spacing: -0.4px;
  line-height: 18px;
  /* width: 24px; */
  width: 100%;
  text-align: right;
  /* width: 26px; */ }
  .chat-room-message-in-wrapper .message-in-bubble-rating .message-info .centered-dot {
    width: 2px;
    height: 2px;
    display: inline-block;
    vertical-align: top;
    background: rgba(25, 25, 25, 0.4);
    border-radius: 50%;
    position: relative;
    top: 9px; }

.chat-room-message-in-wrapper .message-in-bubble-rating .group-sender-name-wrapper {
  color: #ff7d00;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
  font-size: 14px; }

.chat-room-message-in-wrapper .message-in-bubble-rating .group-sender-name-wrapper .agent-span {
  color: #A3A3A3; }

.chat-room-message-in-wrapper:hover .message-action-wrapper {
  display: inline-block; }

.chat-room-message-in-wrapper:hover .message-action-dropdown {
  display: inline-block; }

.chat-room-message-in-wrapper .group-sender-avatar-wrapper {
  width: 32px;
  height: 32px;
  display: inline-block;
  border-radius: 50%;
  vertical-align: top;
  margin-right: 8px;
  font-size: 16px;
  line-height: 32px;
  text-align: center;
  color: #ffffff;
  position: relative; }

.chat-room-message-in-wrapper .group-sender-avatar-wrapper .avatar-icon-badge {
  width: 20px;
  bottom: -2px;
  right: -1px;
  height: 20px;
  position: absolute; }

.chat-room-message-in-wrapper .group-sender-avatar-wrapper img {
  width: 32px;
  height: 32px;
  display: inline-block;
  border-radius: 50%; }

.chat-room-message-in-wrapper .message-action-wrapper {
  /* right: -120px !important; */
  right: -80px !important; }

.hide-reply-button .message-in-bubble-rating .message-action-wrapper {
  right: -40px !important; }
  .hide-reply-button .message-in-bubble-rating .message-action-wrapper.with-forward {
    right: -78px !important; }

.chat-room-message-in-wrapper {
  margin-top: 16px;
  padding: 0 24px;
  width: 100%;
  position: relative; }

.chat-room-message-in-wrapper .message-in-bubble-broadcast {
  border-radius: 2px 16px 16px;
  border: 1px solid rgba(25, 25, 25, 0.1);
  background: #ffffff;
  display: inline-block;
  /* max-width: calc(100% - 180px); */
  max-width: calc(100% - 110px);
  padding: 10px 45px 10px 10px;
  padding: 10px;
  color: #191919;
  font-size: 16px;
  letter-spacing: -0.3px;
  line-height: 24px;
  position: relative;
  min-height: 44px; }
  .chat-room-message-in-wrapper .message-in-bubble-broadcast .title-broadcast {
    font-size: 14px;
    color: rgba(25, 25, 25, 0.4);
    letter-spacing: -0.4px;
    margin: 0; }
    .chat-room-message-in-wrapper .message-in-bubble-broadcast .title-broadcast .broadcast-image {
      margin-right: 5px !important;
      top: -2px;
      position: relative; }

.chat-room-message-in-wrapper .message-in-bubble-broadcast.deleted-bubble {
  color: #9b9b9b;
  padding: 10px 10px 10px 33px; }

.chat-room-message-in-wrapper .message-in-bubble-broadcast.deleted-bubble .deleted-icon {
  position: absolute;
  left: 10px;
  top: 50%;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%); }

.chat-room-message-in-wrapper .message-in-bubble-broadcast.deleted-group-in {
  padding: 10px; }

.chat-room-message-in-wrapper .message-in-bubble-broadcast.deleted-group-in .deleted-icon {
  position: relative;
  left: 0;
  top: 5px;
  margin-right: 3px;
  -webkit-transform: unset;
          transform: unset; }

.chat-room-message-in-wrapper .message-in-bubble-broadcast .forwarded-message {
  color: #191919;
  font-size: 12px;
  letter-spacing: -0.4px;
  line-height: 18px;
  display: block;
  margin-bottom: 5px; }

.chat-room-message-in-wrapper .message-in-bubble-broadcast .reply-message {
  width: calc(100% - 20px);
  border-radius: 4px;
  border-left: solid 5px #ff7d00;
  background: #f3f3f3;
  color: #191919;
  position: absolute;
  top: 10px;
  left: 10px;
  padding: 5px 7px 5px 7px;
  line-height: 1; }

.chat-room-message-in-wrapper .message-in-bubble-broadcast .reply-message p {
  margin: 0;
  font-size: 12px;
  letter-spacing: -0.4px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 20px; }

.chat-room-message-in-wrapper .message-in-bubble-broadcast .reply-message p:nth-child(2) {
  letter-spacing: -0.2px; }

.chat-room-message-in-wrapper .message-in-bubble-broadcast .reply-message.reply-file .reply-file-thumbnail {
  display: inline-block;
  width: 40px;
  height: 40px;
  margin-right: 10px;
  position: relative; }

.chat-room-message-in-wrapper .message-in-bubble-broadcast .reply-message.reply-file .reply-file-thumbnail img {
  width: 40px;
  height: 40px; }

.chat-room-message-in-wrapper .message-in-bubble-broadcast .reply-message.reply-file .reply-file-thumbnail svg {
  position: absolute;
  left: 50%;
  top: 50%;
  font-size: 35px;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%); }

.chat-room-message-in-wrapper .message-in-bubble-broadcast .reply-message.reply-file .reply-text-wrapper {
  width: calc(100% - 50px);
  display: inline-block;
  vertical-align: top; }

.chat-room-message-in-wrapper .message-in-bubble-broadcast .message-info {
  /* position: absolute; */
  margin: 0;
  /* bottom: 11px; */
  /* right: 10px; */
  color: rgba(25, 25, 25, 0.4);
  font-size: 10px;
  letter-spacing: -0.4px;
  line-height: 18px;
  /* width: 24px; */
  width: 100%;
  text-align: right;
  /* width: 26px; */ }
  .chat-room-message-in-wrapper .message-in-bubble-broadcast .message-info .centered-dot {
    width: 2px;
    height: 2px;
    display: inline-block;
    vertical-align: top;
    background: rgba(25, 25, 25, 0.4);
    border-radius: 50%;
    position: relative;
    top: 9px; }

.chat-room-message-in-wrapper .message-in-bubble-broadcast .group-sender-name-wrapper {
  color: #ff7d00;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
  font-size: 14px; }

.chat-room-message-in-wrapper .message-in-bubble-broadcast .group-sender-name-wrapper .agent-span {
  color: #A3A3A3; }

.chat-room-message-in-wrapper:hover .message-action-wrapper {
  display: inline-block; }

.chat-room-message-in-wrapper:hover .message-action-dropdown {
  display: inline-block; }

.chat-room-message-in-wrapper .group-sender-avatar-wrapper {
  width: 32px;
  height: 32px;
  display: inline-block;
  border-radius: 50%;
  vertical-align: top;
  margin-right: 8px;
  font-size: 16px;
  line-height: 32px;
  text-align: center;
  color: #ffffff;
  position: relative; }

.chat-room-message-in-wrapper .group-sender-avatar-wrapper .avatar-icon-badge {
  width: 20px;
  bottom: -2px;
  right: -1px;
  height: 20px;
  position: absolute; }

.chat-room-message-in-wrapper .group-sender-avatar-wrapper img {
  width: 32px;
  height: 32px;
  display: inline-block;
  border-radius: 50%; }

.chat-room-message-in-wrapper .message-action-wrapper {
  /* right: -120px !important; */
  right: -80px !important; }

.hide-reply-button .message-in-bubble-broadcast .message-action-wrapper {
  right: -40px !important; }

.chat-room-message-image-in-wrapper {
  width: 100%;
  padding: 0 24px;
  text-align: left;
  position: relative;
  margin-top: 16px;
  font-size: 0; }
  .chat-room-message-image-in-wrapper .title-broadcast {
    font-size: 14px;
    color: rgba(25, 25, 25, 0.4);
    letter-spacing: -0.4px;
    margin: 0; }
    .chat-room-message-image-in-wrapper .title-broadcast .broadcast-image {
      margin-right: 5px !important;
      top: 1px;
      position: relative;
      width: unset !important; }

.chat-room-message-image-in-wrapper .group-sender-avatar-wrapper {
  width: 34px;
  height: 34px;
  display: inline-block;
  border-radius: 50%;
  vertical-align: top;
  margin-right: 5px;
  font-size: 16px;
  line-height: 34px;
  text-align: center;
  color: #ffffff;
  position: relative; }

.chat-room-message-image-in-wrapper .group-sender-avatar-wrapper img {
  width: 34px;
  height: 34px;
  display: inline-block;
  border-radius: 50%; }

.chat-room-message-image-in-wrapper .group-sender-avatar-wrapper .avatar-icon-badge {
  width: 20px;
  bottom: -2px;
  right: -1px;
  height: 20px;
  position: absolute; }

.chat-room-message-image-in-wrapper .message-in-bubble-broadcast-image {
  border-radius: 2px 16px 16px;
  max-width: 300px;
  display: inline-block;
  position: relative;
  font-size: 0;
  background: #ffffff;
  border: 1px solid rgba(25, 25, 25, 0.1); }

.chat-room-message-image-in-wrapper .message-in-bubble-broadcast-image .bubble-image-wrapper {
  min-width: 150px;
  padding: 10px; }
  .chat-room-message-image-in-wrapper .message-in-bubble-broadcast-image .bubble-image-wrapper.bubble-wrapper-with-caption {
    padding: 10px 10px 0; }
  .chat-room-message-image-in-wrapper .message-in-bubble-broadcast-image .bubble-image-wrapper img {
    border-radius: 0px 16px 16px; }

.chat-room-message-image-in-wrapper .message-in-bubble-broadcast-image .bubble-image-wrapper img {
  vertical-align: top;
  width: 100%;
  max-width: 100%; }

.chat-room-message-image-in-wrapper .message-in-bubble-broadcast-image .bubble-image-wrapper .icon-status-wrapper {
  border-radius: 50%;
  position: absolute;
  width: 48px;
  height: 48px;
  display: inline-block;
  background: rgba(25, 25, 25, 0.4);
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  cursor: pointer; }

.chat-room-message-image-in-wrapper .message-in-bubble-broadcast-image .bubble-image-wrapper .icon-status-wrapper svg {
  color: #ffffff;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  font-size: 20px; }

.chat-room-message-image-in-wrapper .message-in-bubble-broadcast-image .bubble-image-wrapper .icon-status-wrapper .onprocess-wrapper {
  height: 40px;
  width: 40px;
  display: inline-block;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%); }

.chat-room-message-image-in-wrapper .message-in-bubble-broadcast-image .main-image-message {
  width: 100%;
  max-width: 100%; }

.chat-room-message-image-in-wrapper .message-in-bubble-broadcast-image .thumbnail-image-message {
  width: 300px;
  min-width: 300px; }

.modal-image.modal-dialog {
  width: 100vw !important; }

.modal-image .modal-content {
  width: 100%;
  height: 100%;
  background: none; }

.modal-image .modal-content .modal-body {
  width: 100%;
  height: 100%;
  background: rgba(25, 25, 25, 0.8); }

.modal-image .modal-content .modal-body img.image-preview-val {
  max-width: calc(100vw - 130px);
  max-height: calc(100vh - 130px); }

.modal-image .modal-content .modal-body .image-wrapper {
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  text-align: center; }
  .modal-image .modal-content .modal-body .image-wrapper figure {
    margin: 0; }
    .modal-image .modal-content .modal-body .image-wrapper figure.zoom {
      background-size: 180%;
      background-position: -100% -100%;
      background-repeat: no-repeat;
      position: relative;
      width: 100%;
      overflow: hidden;
      cursor: -webkit-zoom-in;
      cursor: zoom-in; }
      .modal-image .modal-content .modal-body .image-wrapper figure.zoom img {
        -webkit-transition: opacity 0.5s;
        transition: opacity 0.5s;
        display: inline-block; }
        .modal-image .modal-content .modal-body .image-wrapper figure.zoom img:hover {
          opacity: 0; }

.modal-image .modal-content .modal-body .close-modal-image {
  position: absolute;
  top: 20px;
  right: 20px;
  color: #ffffff;
  font-size: 40px;
  cursor: pointer;
  -webkit-transition: ease-in-out 0.1s;
  transition: ease-in-out 0.1s; }

.modal-image .modal-content .modal-body .close-modal-image:hover {
  color: #c9c9c9; }

.chat-room-message-image-in-wrapper .message-in-bubble-broadcast-image .caption-text {
  font-size: 16px;
  letter-spacing: -.3px;
  padding: 4px 10px 0 10px;
  margin: 0;
  word-break: break-word; }

.chat-room-message-image-in-wrapper .message-in-bubble-broadcast-image .reply-message {
  width: calc(100% - 20px);
  border-radius: 4px;
  border-left: solid 5px #ff7d00;
  background: #f3f3f3;
  color: #191919;
  position: absolute;
  top: 10px;
  left: 10px;
  padding: 5px 7px 5px 7px;
  line-height: 1;
  z-index: 1; }

.chat-room-message-image-in-wrapper .message-in-bubble-broadcast-image .reply-message p {
  margin: 0;
  font-size: 12px;
  letter-spacing: -0.4px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 20px; }

.chat-room-message-image-in-wrapper .message-in-bubble-broadcast-image .reply-message p:nth-child(2) {
  letter-spacing: -0.2px; }

.chat-room-message-image-in-wrapper .message-in-bubble-broadcast-image .reply-message.reply-file .reply-file-thumbnail {
  display: inline-block;
  width: 40px;
  height: 40px;
  margin-right: 10px;
  position: relative; }

.chat-room-message-image-in-wrapper .message-in-bubble-broadcast-image .reply-message.reply-file .reply-file-thumbnail img {
  width: 40px;
  height: 40px; }

.chat-room-message-image-in-wrapper .message-in-bubble-broadcast-image .reply-message.reply-file .reply-file-thumbnail svg {
  position: absolute;
  left: 50%;
  top: 50%;
  font-size: 35px;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%); }

.chat-room-message-image-in-wrapper .message-in-bubble-broadcast-image .reply-message.reply-file .reply-text-wrapper {
  width: calc(100% - 50px);
  display: inline-block;
  vertical-align: top; }

.chat-room-message-image-in-wrapper .message-in-bubble-broadcast-image .message-info {
  position: relative;
  margin: 0;
  white-space: nowrap;
  border-radius: 10px;
  letter-spacing: -0.4px;
  font-size: 10px;
  color: rgba(25, 25, 25, 0.4);
  width: 100%;
  text-align: right;
  padding: 2px 10px 10px; }
  .chat-room-message-image-in-wrapper .message-in-bubble-broadcast-image .message-info .centered-dot {
    width: 2px;
    height: 2px;
    display: inline-block;
    vertical-align: top;
    background: rgba(25, 25, 25, 0.4);
    border-radius: 50%;
    position: relative;
    top: 9px; }

.chat-room-message-image-in-wrapper .message-in-bubble-broadcast-image .group-sender-name-wrapper {
  color: #ff7d00;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
  font-size: 14px; }

.chat-room-message-image-in-wrapper .message-in-bubble-broadcast-image .group-sender-name-wrapper .agent-span {
  color: #A3A3A3; }

.chat-room-message-image-in-wrapper .message-in-bubble-broadcast-image .message-info-dark {
  background: rgba(25, 25, 25, 0.6);
  padding: 2px 5px;
  color: #ffffff;
  bottom: 16px;
  right: 16px;
  position: absolute;
  width: unset; }
  .chat-room-message-image-in-wrapper .message-in-bubble-broadcast-image .message-info-dark .centered-dot {
    background: #ffffff; }

.chat-room-message-image-in-wrapper .message-in-bubble-broadcast-image.not-sent-message-bubble .dark-cover {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: -5%;
  border-radius: 2px 16px 16px; }

.chat-room-message-image-in-wrapper:hover .message-action-wrapper {
  display: inline-block; }

.chat-room-message-image-in-wrapper:hover .message-action-dropdown {
  display: inline-block; }

.chat-room-message-image-in-wrapper .message-action-wrapper {
  right: -35px !important;
  left: unset !important; }

.chat-room-message-image-in-wrapper .message-action-wrapper .message-action-button-wrapper {
  cursor: pointer; }

.chat-room-message-image-in-wrapper .CircularProgressbar .CircularProgressbar-trail {
  stroke: transparent; }

.chat-room-message-image-in-wrapper .CircularProgressbar .CircularProgressbar-path {
  stroke: #ffffff; }

.chat-room-message-image-in-wrapper .image-from-url {
  /* max-width: 234px; */
  /* max-height: 300px; */ }

.chat-room-message-video-in-wrapper {
  width: 100%;
  padding: 0 24px;
  text-align: left;
  position: relative;
  margin-top: 16px;
  font-size: 0; }

.chat-room-message-video-in-wrapper .group-sender-avatar-wrapper {
  width: 34px;
  height: 34px;
  display: inline-block;
  border-radius: 50%;
  vertical-align: top;
  margin-right: 5px;
  font-size: 16px;
  line-height: 34px;
  text-align: center;
  color: #ffffff;
  position: relative; }

.chat-room-message-video-in-wrapper .group-sender-avatar-wrapper img {
  width: 34px;
  height: 34px;
  display: inline-block;
  border-radius: 50%; }

.chat-room-message-video-in-wrapper .group-sender-avatar-wrapper .avatar-icon-badge {
  width: 20px;
  bottom: -2px;
  right: -1px;
  height: 20px;
  position: absolute; }

.chat-room-message-video-in-wrapper .message-in-bubble-broadcast-video {
  border-radius: 2px 16px 16px;
  max-width: 300px;
  display: inline-block;
  position: relative;
  font-size: 0;
  background: #ffffff;
  background: #ffffff;
  border: 1px solid rgba(25, 25, 25, 0.1); }
  .chat-room-message-video-in-wrapper .message-in-bubble-broadcast-video .message-title {
    padding: 10px 10px 0 10px;
    color: rgba(25, 25, 25, 0.4); }
    .chat-room-message-video-in-wrapper .message-in-bubble-broadcast-video .message-title b {
      font-size: 14px;
      letter-spacing: -.4px; }
    .chat-room-message-video-in-wrapper .message-in-bubble-broadcast-video .message-title img {
      height: 17px !important;
      width: 16px !important;
      position: relative;
      top: -6px;
      margin-right: 5px !important; }

.chat-room-message-video-in-wrapper .message-in-bubble-broadcast-video .bubble-image-wrapper {
  min-width: 150px;
  padding: 10px; }
  .chat-room-message-video-in-wrapper .message-in-bubble-broadcast-video .bubble-image-wrapper.bubble-wrapper-with-caption {
    padding: 10px 10px 0; }
  .chat-room-message-video-in-wrapper .message-in-bubble-broadcast-video .bubble-image-wrapper .video-inner-wrapper {
    position: relative; }
  .chat-room-message-video-in-wrapper .message-in-bubble-broadcast-video .bubble-image-wrapper img {
    border-radius: 0 16px 16px; }
  .chat-room-message-video-in-wrapper .message-in-bubble-broadcast-video .bubble-image-wrapper video {
    border-radius: 0 16px 16px; }

.chat-room-message-video-in-wrapper .message-in-bubble-broadcast-video .bubble-image-wrapper .icon-status-wrapper {
  border-radius: 50%;
  position: absolute;
  width: 48px;
  height: 48px;
  display: inline-block;
  background: rgba(25, 25, 25, 0.4);
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  cursor: pointer; }

.chat-room-message-video-in-wrapper .message-in-bubble-broadcast-video .bubble-image-wrapper .icon-status-wrapper svg {
  color: #ffffff;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  font-size: 20px; }

.chat-room-message-video-in-wrapper .message-in-bubble-broadcast-video .bubble-image-wrapper .icon-status-wrapper .onprocess-wrapper {
  height: 40px;
  width: 40px;
  display: inline-block;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%); }

.chat-room-message-video-in-wrapper .message-in-bubble-broadcast-video .bubble-image-wrapper .video-thumbnail {
  width: 100%; }

.chat-room-message-video-in-wrapper .message-in-bubble-broadcast-video .main-image-message {
  width: 300px; }

.chat-room-message-video-in-wrapper .message-in-bubble-broadcast-video .caption-text {
  font-size: 16px;
  letter-spacing: -.3px;
  padding: 4px 10px 0 10px;
  margin: 0;
  word-break: break-word; }

.chat-room-message-video-in-wrapper .message-in-bubble-broadcast-video .reply-message {
  width: calc(100% - 20px);
  border-radius: 4px;
  border-left: solid 5px #ff7d00;
  background: #f3f3f3;
  color: #191919;
  position: absolute;
  top: 10px;
  left: 10px;
  padding: 5px 7px 5px 7px;
  line-height: 1;
  z-index: 1; }

.chat-room-message-video-in-wrapper .message-in-bubble-broadcast-video .reply-message p {
  margin: 0;
  font-size: 12px;
  letter-spacing: -0.4px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 20px; }

.chat-room-message-video-in-wrapper .message-in-bubble-broadcast-video .reply-message p:nth-child(2) {
  letter-spacing: -0.2px; }

.chat-room-message-video-in-wrapper .message-in-bubble-broadcast-video .reply-message.reply-file .reply-file-thumbnail {
  display: inline-block;
  width: 40px;
  height: 40px;
  margin-right: 10px;
  position: relative; }

.chat-room-message-video-in-wrapper .message-in-bubble-broadcast-video .reply-message.reply-file .reply-file-thumbnail img {
  width: 40px;
  height: 40px; }

.chat-room-message-video-in-wrapper .message-in-bubble-broadcast-video .reply-message.reply-file .reply-file-thumbnail svg {
  position: absolute;
  left: 50%;
  top: 50%;
  font-size: 35px;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%); }

.chat-room-message-video-in-wrapper .message-in-bubble-broadcast-video .reply-message.reply-file .reply-text-wrapper {
  width: calc(100% - 50px);
  display: inline-block;
  vertical-align: top; }

.chat-room-message-video-in-wrapper .message-in-bubble-broadcast-video .message-info {
  position: relative;
  margin: 0;
  white-space: nowrap;
  border-radius: 10px;
  font-size: 10px;
  letter-spacing: -0.4px;
  color: rgba(25, 25, 25, 0.4);
  width: 100%;
  text-align: right;
  padding: 2px 10px 10px; }
  .chat-room-message-video-in-wrapper .message-in-bubble-broadcast-video .message-info .centered-dot {
    width: 2px;
    height: 2px;
    display: inline-block;
    vertical-align: top;
    background: rgba(25, 25, 25, 0.4);
    border-radius: 50%;
    position: relative;
    top: 9px; }

.chat-room-message-video-in-wrapper .message-in-bubble-broadcast-video .group-sender-name-wrapper {
  color: #ff7d00;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
  font-size: 14px; }

.chat-room-message-video-in-wrapper .message-in-bubble-broadcast-video .group-sender-name-wrapper .agent-span {
  color: #A3A3A3; }

.chat-room-message-video-in-wrapper .message-in-bubble-broadcast-video .message-info-dark {
  background: rgba(25, 25, 25, 0.6);
  padding: 2px 5px;
  color: #ffffff;
  bottom: 16px;
  right: 16px;
  position: absolute;
  width: unset; }
  .chat-room-message-video-in-wrapper .message-in-bubble-broadcast-video .message-info-dark .centered-dot {
    background: #ffffff; }

.chat-room-message-video-in-wrapper .message-in-bubble-broadcast-video .message-info img {
  vertical-align: top;
  margin-left: 3px; }

.chat-room-message-video-in-wrapper .message-in-bubble-broadcast-video.not-sent-message-bubble .dark-cover {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 2px 16px 16px; }

.chat-room-message-video-in-wrapper .message-in-bubble-broadcast-video.not-sent-message-bubble .dark-cover .icon-status-wrapper {
  border-radius: 50%;
  position: absolute;
  width: 48px;
  height: 48px;
  display: inline-block;
  background: rgba(25, 25, 25, 0.4);
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  cursor: pointer; }

.chat-room-message-video-in-wrapper .message-in-bubble-broadcast-video.not-sent-message-bubble .dark-cover .icon-status-wrapper svg {
  color: #ffffff;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  font-size: 20px; }

.chat-room-message-video-in-wrapper .message-in-bubble-broadcast-video.not-sent-message-bubble .dark-cover .icon-status-wrapper .onprocess-wrapper {
  height: 40px;
  width: 40px;
  display: inline-block;
  border: solid 2px #ffffff;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%); }

.chat-room-message-video-in-wrapper .message-in-bubble-broadcast-video .timer-filesize-wrapper {
  background: rgba(25, 25, 25, 0.6);
  position: absolute;
  white-space: nowrap;
  border-radius: 10px;
  font-size: 12px;
  padding: 2px 5px;
  color: rgba(255, 255, 255, 0.6);
  top: 10px;
  left: 10px;
  z-index: 1; }

.chat-room-message-video-in-wrapper .message-in-bubble-broadcast-video .play-button-wraper {
  border-radius: 50%;
  position: absolute;
  width: 48px;
  height: 48px;
  display: inline-block;
  background: rgba(25, 25, 25, 0.4);
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  cursor: pointer; }

.chat-room-message-video-in-wrapper .message-in-bubble-broadcast-video .play-button-wraper svg {
  color: #ffffff;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  font-size: 20px; }

.chat-room-message-video-in-wrapper:hover .message-action-wrapper {
  display: inline-block; }

.chat-room-message-video-in-wrapper:hover .message-action-dropdown {
  display: inline-block; }

.chat-room-message-video-in-wrapper .message-action-wrapper {
  right: -35px !important;
  left: unset !important; }

.chat-room-message-video-in-wrapper .message-action-wrapper .message-action-button-wrapper {
  cursor: pointer; }

.chat-room-message-video-in-wrapper .CircularProgressbar .CircularProgressbar-trail {
  stroke: transparent; }

.chat-room-message-video-in-wrapper .CircularProgressbar .CircularProgressbar-path {
  stroke: #ffffff; }

.modal-video.modal-dialog {
  width: 100vw !important; }

.modal-video .modal-content {
  width: 100%;
  height: 100%;
  background: none; }

.modal-video .modal-content .modal-body {
  width: 100%;
  height: 100%;
  background: rgba(25, 25, 25, 0.8); }

.modal-video .modal-content .modal-body .video-wrapper {
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%); }

.modal-video .modal-content .modal-body .close-modal-video {
  position: absolute;
  top: 20px;
  right: 20px;
  color: #ffffff;
  font-size: 40px;
  cursor: pointer;
  -webkit-transition: ease-in-out 0.1s;
  transition: ease-in-out 0.1s; }

.modal-video .modal-content .modal-body .close-modal-video:hover {
  color: #c9c9c9; }

.modal-video .modal-content .modal-body video {
  max-height: calc(100vh - 130px);
  max-width: calc(100vw - 130px); }

.chat-room-message-file-in-wrapper {
  margin-top: 16px;
  padding: 0 24px;
  width: 100%;
  position: relative;
  text-align: left; }

.chat-room-message-file-in-wrapper .message-action-right {
  right: -40px !important; }

.chat-room-message-file-in-wrapper .group-sender-avatar-wrapper {
  width: 32px;
  height: 32px;
  display: inline-block;
  border-radius: 50%;
  vertical-align: top;
  margin-right: 8px;
  font-size: 16px;
  line-height: 32px;
  text-align: center;
  color: #ffffff;
  position: relative; }

.chat-room-message-file-in-wrapper .group-sender-avatar-wrapper img {
  width: 32px;
  height: 32px;
  display: inline-block;
  border-radius: 50%; }

.chat-room-message-file-in-wrapper .group-sender-avatar-wrapper .avatar-icon-badge {
  width: 20px;
  bottom: -2px;
  right: -1px;
  height: 20px;
  position: absolute; }

.chat-room-message-file-in-wrapper .message-in-bubble-broadcast-file {
  border-radius: 2px 16px 16px;
  background: #ffffff;
  display: inline-block;
  /* max-width: calc(100% - 224px); */
  max-width: calc(100% - 65px);
  padding: 10px;
  color: #191919;
  font-size: 16px;
  letter-spacing: -0.3px;
  line-height: 24px;
  position: relative;
  color: #ffffff;
  text-align: left;
  border: 1px solid rgba(25, 25, 25, 0.1);
  min-height: 69px; }
  .chat-room-message-file-in-wrapper .message-in-bubble-broadcast-file .click-area-file {
    cursor: pointer; }
  .chat-room-message-file-in-wrapper .message-in-bubble-broadcast-file .message-title {
    padding: 2px;
    color: rgba(25, 25, 25, 0.4); }
    .chat-room-message-file-in-wrapper .message-in-bubble-broadcast-file .message-title b {
      font-size: 14px;
      letter-spacing: -.4px; }
    .chat-room-message-file-in-wrapper .message-in-bubble-broadcast-file .message-title img {
      height: 17px !important;
      width: 16px !important;
      position: relative;
      top: -2px;
      margin-right: 5px !important; }

.chat-room-message-file-in-wrapper .message-in-bubble-broadcast-file .file-size-and-extension {
  padding: 0 3px 0 0;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 14px;
  letter-spacing: -0.2px;
  line-height: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.chat-room-message-file-in-wrapper .message-in-bubble-broadcast-file .file-size-and-extension-download-progress {
  min-width: 155px;
  max-width: 100%; }

.chat-room-message-file-in-wrapper .message-in-bubble-broadcast-file .reply-message {
  width: calc(100% - 20px);
  border-radius: 4px;
  border-left: solid 5px #ff7d00;
  background: #f3f3f3;
  color: #191919;
  position: absolute;
  top: 10px;
  left: 10px;
  padding: 5px 7px 5px 7px;
  line-height: 1; }

.chat-room-message-file-in-wrapper .message-in-bubble-broadcast-file .reply-message p {
  margin: 0;
  font-size: 12px;
  letter-spacing: -0.4px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 20px; }

.chat-room-message-file-in-wrapper .message-in-bubble-broadcast-file .reply-message p:nth-child(2) {
  letter-spacing: -0.2px; }

.chat-room-message-file-in-wrapper .message-in-bubble-broadcast-file .reply-message.reply-file .reply-file-thumbnail {
  display: inline-block;
  width: 40px;
  height: 40px;
  margin-right: 10px;
  position: relative; }

.chat-room-message-file-in-wrapper .message-in-bubble-broadcast-file .reply-message.reply-file .reply-file-thumbnail img {
  width: 40px;
  height: 40px; }

.chat-room-message-file-in-wrapper .message-in-bubble-broadcast-file .reply-message.reply-file .reply-file-thumbnail svg {
  position: absolute;
  left: 50%;
  top: 50%;
  font-size: 35px;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%); }

.chat-room-message-file-in-wrapper .message-in-bubble-broadcast-file .reply-message.reply-file .reply-text-wrapper {
  width: calc(100% - 50px);
  display: inline-block;
  vertical-align: top; }

.chat-room-message-file-in-wrapper .message-in-bubble-broadcast-file .replied-file-message {
  width: calc(100% - 20px);
  position: absolute;
  top: 10px;
  left: 10px;
  background: rgba(25, 25, 25, 0.1);
  border-radius: 16px 2px 16px 16px;
  color: rgba(25, 25, 25, 0.4);
  padding: 10px; }

.chat-room-message-file-in-wrapper .message-in-bubble-broadcast-file .file-inner-wrapper {
  position: relative;
  padding-left: 56px;
  padding-top: 3px; }

.chat-room-message-file-in-wrapper .message-in-bubble-broadcast-file .file-icon-wrapper {
  height: 32px;
  width: 32px;
  display: inline-block;
  background: #D3D3D3;
  border-radius: 50%;
  vertical-align: top;
  position: relative; }

.chat-room-message-file-in-wrapper .message-in-bubble-broadcast-file .file-icon-wrapper svg {
  font-size: 16px;
  color: rgba(25, 25, 25, 0.4);
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%); }

.chat-room-message-file-in-wrapper .message-in-bubble-broadcast-file .forwarded-message {
  color: #ffffff;
  font-size: 12px;
  letter-spacing: -0.4px;
  line-height: 18px;
  display: block;
  margin-bottom: 5px; }

.chat-room-message-file-in-wrapper .message-in-bubble-broadcast-file .message-info {
  margin: 0;
  color: rgba(25, 25, 25, 0.4);
  font-size: 10px;
  letter-spacing: -0.4px;
  line-height: 18px;
  white-space: nowrap;
  width: 100%;
  text-align: right; }
  .chat-room-message-file-in-wrapper .message-in-bubble-broadcast-file .message-info .centered-dot {
    width: 2px;
    height: 2px;
    display: inline-block;
    vertical-align: top;
    background: rgba(25, 25, 25, 0.4);
    border-radius: 50%;
    position: relative;
    top: 9px; }

.chat-room-message-file-in-wrapper .message-in-bubble-broadcast-file .group-sender-name-wrapper {
  color: #ff7d00;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
  font-size: 14px; }

.chat-room-message-file-in-wrapper .message-in-bubble-broadcast-file .group-sender-name-wrapper .agent-span {
  color: #A3A3A3; }

.chat-room-message-file-in-wrapper .message-in-bubble-broadcast-file .message-info img {
  margin-left: 4px;
  width: 16px;
  position: relative;
  top: 4px; }

.chat-room-message-file-in-wrapper .message-in-bubble-broadcast-file .message-bubble-file-wrapper {
  width: 100%;
  display: inline-block;
  color: #191919; }
  .chat-room-message-file-in-wrapper .message-in-bubble-broadcast-file .message-bubble-file-wrapper p {
    max-width: 100%;
    line-height: 16px;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0;
    display: inline-block;
    white-space: nowrap;
    font-size: 14px;
    letter-spacing: -.2px; }

.chat-room-message-file-in-wrapper .message-in-bubble-broadcast-file .file-icon-wrapper {
  height: 46px;
  width: 46px;
  display: inline-block;
  background: #ff7d00;
  border-radius: 50%;
  vertical-align: top;
  position: absolute;
  left: 0;
  top: 0; }

.chat-room-message-file-in-wrapper .message-in-bubble-broadcast-file .file-icon-wrapper svg {
  font-size: 25px;
  color: #ffffff;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%); }

.chat-room-message-file-in-wrapper .message-in-bubble-broadcast-file .file-icon-wrapper .onprocess-wrapper {
  height: 40px;
  width: 40px;
  display: inline-block;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%); }

.chat-room-message-file-in-wrapper:hover .message-action-wrapper {
  display: inline-block; }

.chat-room-message-file-in-wrapper:hover .message-action-dropdown {
  display: inline-block; }

.chat-room-message-file-in-wrapper .CircularProgressbar .CircularProgressbar-trail {
  stroke: #ff7d00; }

.chat-room-message-file-in-wrapper .CircularProgressbar .CircularProgressbar-path {
  stroke: #ffffff; }

.chat-room-message-file-in-wrapper {
  margin-top: 16px;
  padding: 0 24px;
  width: 100%;
  position: relative;
  text-align: left; }

.chat-room-message-file-in-wrapper .message-action-right {
  right: -40px !important; }

.chat-room-message-file-in-wrapper .group-sender-avatar-wrapper {
  width: 32px;
  height: 32px;
  display: inline-block;
  border-radius: 50%;
  vertical-align: top;
  margin-right: 8px;
  font-size: 16px;
  line-height: 32px;
  text-align: center;
  color: #ffffff;
  position: relative; }

.chat-room-message-file-in-wrapper .group-sender-avatar-wrapper img {
  width: 32px;
  height: 32px;
  display: inline-block;
  border-radius: 50%; }

.chat-room-message-file-in-wrapper .group-sender-avatar-wrapper .avatar-icon-badge {
  width: 20px;
  bottom: -2px;
  right: -1px;
  height: 20px;
  position: absolute; }

.chat-room-message-file-in-wrapper .message-in-bubble-file {
  border-radius: 2px 16px 16px;
  background: #ffffff;
  display: inline-block;
  /* max-width: calc(100% - 224px); */
  max-width: calc(100% - 103px);
  padding: 10px;
  color: #191919;
  font-size: 16px;
  letter-spacing: -0.3px;
  line-height: 24px;
  position: relative;
  color: #ffffff;
  text-align: left;
  border: 1px solid rgba(25, 25, 25, 0.1);
  min-height: 69px; }
  .chat-room-message-file-in-wrapper .message-in-bubble-file .click-area-file {
    cursor: pointer; }

.chat-room-message-file-in-wrapper .message-in-bubble-file .file-size-and-extension {
  padding: 0 3px 0 0;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 14px;
  letter-spacing: -0.2px;
  line-height: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.chat-room-message-file-in-wrapper .message-in-bubble-file .file-size-and-extension-download-progress {
  min-width: 155px;
  max-width: 100%; }

.chat-room-message-file-in-wrapper .message-in-bubble-file .reply-message {
  width: calc(100% - 20px);
  border-radius: 4px;
  border-left: solid 5px #ff7d00;
  background: #f3f3f3;
  color: #191919;
  position: absolute;
  top: 10px;
  left: 10px;
  padding: 5px 7px 5px 7px;
  line-height: 1; }

.chat-room-message-file-in-wrapper .message-in-bubble-file .reply-message p {
  margin: 0;
  font-size: 12px;
  letter-spacing: -0.4px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 20px; }

.chat-room-message-file-in-wrapper .message-in-bubble-file .reply-message p:nth-child(2) {
  letter-spacing: -0.2px; }

.chat-room-message-file-in-wrapper .message-in-bubble-file .reply-message.reply-file .reply-file-thumbnail {
  display: inline-block;
  width: 40px;
  height: 40px;
  margin-right: 10px;
  position: relative; }

.chat-room-message-file-in-wrapper .message-in-bubble-file .reply-message.reply-file .reply-file-thumbnail img {
  width: 40px;
  height: 40px; }

.chat-room-message-file-in-wrapper .message-in-bubble-file .reply-message.reply-file .reply-file-thumbnail svg {
  position: absolute;
  left: 50%;
  top: 50%;
  font-size: 35px;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%); }

.chat-room-message-file-in-wrapper .message-in-bubble-file .reply-message.reply-file .reply-text-wrapper {
  width: calc(100% - 50px);
  display: inline-block;
  vertical-align: top; }

.chat-room-message-file-in-wrapper .message-in-bubble-file .replied-file-message {
  width: calc(100% - 20px);
  position: absolute;
  top: 10px;
  left: 10px;
  background: rgba(25, 25, 25, 0.1);
  border-radius: 16px 2px 16px 16px;
  color: rgba(25, 25, 25, 0.4);
  padding: 10px; }

.chat-room-message-file-in-wrapper .message-in-bubble-file .file-inner-wrapper {
  position: relative;
  padding-left: 56px;
  padding-top: 3px; }

.chat-room-message-file-in-wrapper .message-in-bubble-file .caption-text {
  font-size: 16px;
  letter-spacing: -.3px;
  padding: 4px 10px 0 0;
  margin: 0;
  word-break: break-word;
  color: #191919; }

.chat-room-message-file-in-wrapper .message-in-bubble-file .file-icon-wrapper {
  height: 32px;
  width: 32px;
  display: inline-block;
  background: #D3D3D3;
  border-radius: 50%;
  vertical-align: top;
  position: relative; }

.chat-room-message-file-in-wrapper .message-in-bubble-file .file-icon-wrapper svg {
  font-size: 16px;
  color: rgba(25, 25, 25, 0.4);
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%); }

.chat-room-message-file-in-wrapper .message-in-bubble-file .forwarded-message {
  color: #ffffff;
  font-size: 12px;
  letter-spacing: -0.4px;
  line-height: 18px;
  display: block;
  margin-bottom: 5px; }

.chat-room-message-file-in-wrapper .message-in-bubble-file .message-info {
  margin: 0;
  color: rgba(25, 25, 25, 0.4);
  font-size: 10px;
  letter-spacing: -0.4px;
  line-height: 18px;
  white-space: nowrap;
  width: 100%;
  text-align: right; }
  .chat-room-message-file-in-wrapper .message-in-bubble-file .message-info .centered-dot {
    width: 2px;
    height: 2px;
    display: inline-block;
    vertical-align: top;
    background: rgba(25, 25, 25, 0.4);
    border-radius: 50%;
    position: relative;
    top: 9px; }

.chat-room-message-file-in-wrapper .message-in-bubble-file .group-sender-name-wrapper {
  color: #ff7d00;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
  font-size: 14px; }

.chat-room-message-file-in-wrapper .message-in-bubble-file .group-sender-name-wrapper .agent-span {
  color: #A3A3A3; }

.chat-room-message-file-in-wrapper .message-in-bubble-file .message-info img {
  margin-left: 4px;
  width: 16px;
  position: relative;
  top: 4px; }

.chat-room-message-file-in-wrapper .message-in-bubble-file .message-bubble-file-wrapper {
  width: 100%;
  display: inline-block;
  color: #191919; }
  .chat-room-message-file-in-wrapper .message-in-bubble-file .message-bubble-file-wrapper p {
    max-width: 100%;
    line-height: 16px;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0;
    display: inline-block;
    white-space: nowrap;
    font-size: 14px;
    letter-spacing: -.2px; }

.chat-room-message-file-in-wrapper .message-in-bubble-file .file-icon-wrapper {
  height: 46px;
  width: 46px;
  display: inline-block;
  background: #ff7d00;
  border-radius: 50%;
  vertical-align: top;
  position: absolute;
  left: 0;
  top: 0; }

.chat-room-message-file-in-wrapper .message-in-bubble-file .file-icon-wrapper svg {
  font-size: 25px;
  color: #ffffff;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%); }

.chat-room-message-file-in-wrapper .message-in-bubble-file .file-icon-wrapper .onprocess-wrapper {
  height: 40px;
  width: 40px;
  display: inline-block;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%); }

.chat-room-message-file-in-wrapper:hover .message-action-wrapper {
  display: inline-block; }

.chat-room-message-file-in-wrapper:hover .message-action-dropdown {
  display: inline-block; }

.chat-room-message-file-in-wrapper .message-action-wrapper.with-forward {
  right: -76px !important; }
  .chat-room-message-file-in-wrapper .message-action-wrapper.with-forward .forward-button {
    display: inline-block !important; }

.chat-room-message-file-in-wrapper .CircularProgressbar .CircularProgressbar-trail {
  stroke: #ff7d00; }

.chat-room-message-file-in-wrapper .CircularProgressbar .CircularProgressbar-path {
  stroke: #ffffff; }

.hide-reply-button .message-in-bubble-file .message-action-wrapper {
  right: -40px !important; }
  .hide-reply-button .message-in-bubble-file .message-action-wrapper.with-forward {
    right: -40px !important; }

.chat-room-message-image-in-wrapper {
  width: 100%;
  padding: 0 24px;
  text-align: left;
  position: relative;
  margin-top: 16px;
  font-size: 0; }

.chat-room-message-image-in-wrapper .group-sender-avatar-wrapper {
  width: 34px;
  height: 34px;
  display: inline-block;
  border-radius: 50%;
  vertical-align: top;
  margin-right: 5px;
  font-size: 16px;
  line-height: 34px;
  text-align: center;
  color: #ffffff;
  position: relative; }

.chat-room-message-image-in-wrapper .group-sender-avatar-wrapper img {
  width: 34px;
  height: 34px;
  display: inline-block;
  border-radius: 50%; }

.chat-room-message-image-in-wrapper .group-sender-avatar-wrapper .avatar-icon-badge {
  width: 20px;
  bottom: -2px;
  right: -1px;
  height: 20px;
  position: absolute; }

.chat-room-message-image-in-wrapper .message-in-bubble-image {
  border-radius: 2px 16px 16px;
  max-width: 300px;
  display: inline-block;
  position: relative;
  font-size: 0;
  background: #ffffff;
  border: 1px solid rgba(25, 25, 25, 0.1); }

.chat-room-message-image-in-wrapper .message-in-bubble-image .bubble-image-wrapper {
  min-width: 150px;
  padding: 10px; }
  .chat-room-message-image-in-wrapper .message-in-bubble-image .bubble-image-wrapper.bubble-wrapper-with-caption {
    padding: 10px 10px 0; }
  .chat-room-message-image-in-wrapper .message-in-bubble-image .bubble-image-wrapper img {
    border-radius: 0px 16px 16px; }

.chat-room-message-image-in-wrapper .message-in-bubble-image .bubble-image-wrapper img {
  vertical-align: top;
  width: 100%;
  max-width: 100%; }

.chat-room-message-image-in-wrapper .message-in-bubble-image .bubble-image-wrapper .icon-status-wrapper {
  border-radius: 50%;
  position: absolute;
  width: 48px;
  height: 48px;
  display: inline-block;
  background: rgba(25, 25, 25, 0.4);
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  cursor: pointer; }

.chat-room-message-image-in-wrapper .message-in-bubble-image .bubble-image-wrapper .icon-status-wrapper svg {
  color: #ffffff;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  font-size: 20px; }

.chat-room-message-image-in-wrapper .message-in-bubble-image .bubble-image-wrapper .icon-status-wrapper .onprocess-wrapper {
  height: 40px;
  width: 40px;
  display: inline-block;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%); }

.chat-room-message-image-in-wrapper .message-in-bubble-image .main-image-message {
  width: 100%;
  max-width: 100%; }

.chat-room-message-image-in-wrapper .message-in-bubble-image .thumbnail-image-message {
  width: 300px;
  min-width: 300px; }

.modal-image.modal-dialog {
  width: 100vw !important; }

.modal-image .modal-content {
  width: 100%;
  height: 100%;
  background: none; }

.modal-image .modal-content .modal-body {
  width: 100%;
  height: 100%;
  background: rgba(25, 25, 25, 0.8); }

.modal-image .modal-content .modal-body img.image-preview-val {
  max-width: calc(100vw - 130px);
  max-height: calc(100vh - 130px);
  background: #ffffff; }

.modal-image .modal-content .modal-body .image-wrapper {
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  text-align: center; }
  .modal-image .modal-content .modal-body .image-wrapper figure {
    margin: 0; }
    .modal-image .modal-content .modal-body .image-wrapper figure.zoom {
      background-size: 180%;
      background-position: -100% -100%;
      background-repeat: no-repeat;
      position: relative;
      width: 100%;
      overflow: hidden;
      cursor: -webkit-zoom-in;
      cursor: zoom-in; }
      .modal-image .modal-content .modal-body .image-wrapper figure.zoom img {
        -webkit-transition: opacity 0.5s;
        transition: opacity 0.5s;
        display: inline-block; }
        .modal-image .modal-content .modal-body .image-wrapper figure.zoom img:hover {
          opacity: 0; }

.modal-image .modal-content .modal-body .close-modal-image {
  position: absolute;
  top: 20px;
  right: 20px;
  color: #ffffff;
  font-size: 40px;
  cursor: pointer;
  -webkit-transition: ease-in-out 0.1s;
  transition: ease-in-out 0.1s; }

.modal-image .modal-content .modal-body .close-modal-image:hover {
  color: #c9c9c9; }

.chat-room-message-image-in-wrapper .message-in-bubble-image .caption-text {
  font-size: 16px;
  letter-spacing: -.3px;
  padding: 4px 10px 0 10px;
  margin: 0;
  word-break: break-word; }

.chat-room-message-image-in-wrapper .message-in-bubble-image .reply-message {
  width: calc(100% - 20px);
  border-radius: 4px;
  border-left: solid 5px #ff7d00;
  background: #f3f3f3;
  color: #191919;
  position: absolute;
  top: 10px;
  left: 10px;
  padding: 5px 7px 5px 7px;
  line-height: 1;
  z-index: 1; }

.chat-room-message-image-in-wrapper .message-in-bubble-image .reply-message p {
  margin: 0;
  font-size: 12px;
  letter-spacing: -0.4px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 20px; }

.chat-room-message-image-in-wrapper .message-in-bubble-image .reply-message p:nth-child(2) {
  letter-spacing: -0.2px; }

.chat-room-message-image-in-wrapper .message-in-bubble-image .reply-message.reply-file .reply-file-thumbnail {
  display: inline-block;
  width: 40px;
  height: 40px;
  margin-right: 10px;
  position: relative; }

.chat-room-message-image-in-wrapper .message-in-bubble-image .reply-message.reply-file .reply-file-thumbnail img {
  width: 40px;
  height: 40px; }

.chat-room-message-image-in-wrapper .message-in-bubble-image .reply-message.reply-file .reply-file-thumbnail svg {
  position: absolute;
  left: 50%;
  top: 50%;
  font-size: 35px;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%); }

.chat-room-message-image-in-wrapper .message-in-bubble-image .reply-message.reply-file .reply-text-wrapper {
  width: calc(100% - 50px);
  display: inline-block;
  vertical-align: top; }

.chat-room-message-image-in-wrapper .message-in-bubble-image .message-info {
  position: relative;
  margin: 0;
  white-space: nowrap;
  border-radius: 10px;
  font-size: 10px;
  letter-spacing: -0.4px;
  color: rgba(25, 25, 25, 0.4);
  width: 100%;
  text-align: right;
  padding: 2px 10px 10px; }
  .chat-room-message-image-in-wrapper .message-in-bubble-image .message-info .centered-dot {
    width: 2px;
    height: 2px;
    display: inline-block;
    vertical-align: top;
    background: rgba(25, 25, 25, 0.4);
    border-radius: 50%;
    position: relative;
    top: 9px; }

.chat-room-message-image-in-wrapper .message-in-bubble-image .group-sender-name-wrapper {
  color: #ff7d00;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
  font-size: 14px; }

.chat-room-message-image-in-wrapper .message-in-bubble-image .group-sender-name-wrapper .agent-span {
  color: #A3A3A3; }

.chat-room-message-image-in-wrapper .message-in-bubble-image .message-info-dark {
  background: rgba(25, 25, 25, 0.6);
  padding: 2px 5px;
  color: #ffffff;
  bottom: 16px;
  right: 16px;
  position: absolute;
  width: unset; }
  .chat-room-message-image-in-wrapper .message-in-bubble-image .message-info-dark .centered-dot {
    background: #ffffff; }

.chat-room-message-image-in-wrapper .message-in-bubble-image.not-sent-message-bubble .dark-cover {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: -5%;
  border-radius: 2px 16px 16px; }

.chat-room-message-image-in-wrapper:hover .message-action-wrapper {
  display: inline-block; }

.chat-room-message-image-in-wrapper:hover .message-action-dropdown {
  display: inline-block; }

.chat-room-message-image-in-wrapper .message-action-wrapper {
  right: -35px !important;
  left: unset !important; }
  .chat-room-message-image-in-wrapper .message-action-wrapper.with-forward {
    right: -73px !important; }
    .chat-room-message-image-in-wrapper .message-action-wrapper.with-forward .forward-button {
      display: inline-block !important; }

.chat-room-message-image-in-wrapper .message-action-wrapper .message-action-button-wrapper {
  cursor: pointer; }

.chat-room-message-image-in-wrapper .CircularProgressbar .CircularProgressbar-trail {
  stroke: transparent; }

.chat-room-message-image-in-wrapper .CircularProgressbar .CircularProgressbar-path {
  stroke: #ffffff; }

.chat-room-message-image-in-wrapper .image-from-url {
  /* max-width: 234px; */
  /* max-height: 300px; */ }

.hide-reply-button .message-in-bubble-image .message-action-wrapper {
  right: -40px !important; }
  .hide-reply-button .message-in-bubble-image .message-action-wrapper.with-forward {
    right: -40px !important; }

.chat-room-message-video-in-wrapper {
  width: 100%;
  padding: 0 24px;
  text-align: left;
  position: relative;
  margin-top: 16px;
  font-size: 0; }

.chat-room-message-video-in-wrapper .group-sender-avatar-wrapper {
  width: 34px;
  height: 34px;
  display: inline-block;
  border-radius: 50%;
  vertical-align: top;
  margin-right: 5px;
  font-size: 16px;
  line-height: 34px;
  text-align: center;
  color: #ffffff;
  position: relative; }

.chat-room-message-video-in-wrapper .group-sender-avatar-wrapper img {
  width: 34px;
  height: 34px;
  display: inline-block;
  border-radius: 50%; }

.chat-room-message-video-in-wrapper .group-sender-avatar-wrapper .avatar-icon-badge {
  width: 20px;
  bottom: -2px;
  right: -1px;
  height: 20px;
  position: absolute; }

.chat-room-message-video-in-wrapper .message-in-bubble-video {
  border-radius: 2px 16px 16px;
  max-width: 300px;
  display: inline-block;
  position: relative;
  font-size: 0;
  background: #ffffff;
  background: #ffffff;
  border: 1px solid rgba(25, 25, 25, 0.1); }

.chat-room-message-video-in-wrapper .message-in-bubble-video .bubble-image-wrapper {
  min-width: 150px;
  padding: 10px; }
  .chat-room-message-video-in-wrapper .message-in-bubble-video .bubble-image-wrapper.bubble-wrapper-with-caption {
    padding: 10px 10px 0; }
  .chat-room-message-video-in-wrapper .message-in-bubble-video .bubble-image-wrapper .video-inner-wrapper {
    position: relative; }
  .chat-room-message-video-in-wrapper .message-in-bubble-video .bubble-image-wrapper img {
    border-radius: 0 16px 16px; }
  .chat-room-message-video-in-wrapper .message-in-bubble-video .bubble-image-wrapper video {
    border-radius: 0 16px 16px; }

.chat-room-message-video-in-wrapper .message-in-bubble-video .bubble-image-wrapper .icon-status-wrapper {
  border-radius: 50%;
  position: absolute;
  width: 48px;
  height: 48px;
  display: inline-block;
  background: rgba(25, 25, 25, 0.4);
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  cursor: pointer; }

.chat-room-message-video-in-wrapper .message-in-bubble-video .bubble-image-wrapper .icon-status-wrapper svg {
  color: #ffffff;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  font-size: 20px; }

.chat-room-message-video-in-wrapper .message-in-bubble-video .bubble-image-wrapper .icon-status-wrapper .onprocess-wrapper {
  height: 40px;
  width: 40px;
  display: inline-block;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%); }

.chat-room-message-video-in-wrapper .message-in-bubble-video .bubble-image-wrapper .video-thumbnail {
  width: 100%; }

.chat-room-message-video-in-wrapper .message-in-bubble-video .main-image-message {
  width: 300px; }

.chat-room-message-video-in-wrapper .message-in-bubble-video .caption-text {
  font-size: 16px;
  letter-spacing: -.3px;
  padding: 4px 10px 0 10px;
  margin: 0;
  word-break: break-word; }

.chat-room-message-video-in-wrapper .message-in-bubble-video .reply-message {
  width: calc(100% - 20px);
  border-radius: 4px;
  border-left: solid 5px #ff7d00;
  background: #f3f3f3;
  color: #191919;
  position: absolute;
  top: 10px;
  left: 10px;
  padding: 5px 7px 5px 7px;
  line-height: 1;
  z-index: 1; }

.chat-room-message-video-in-wrapper .message-in-bubble-video .reply-message p {
  margin: 0;
  font-size: 12px;
  letter-spacing: -0.4px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 20px; }

.chat-room-message-video-in-wrapper .message-in-bubble-video .reply-message p:nth-child(2) {
  letter-spacing: -0.2px; }

.chat-room-message-video-in-wrapper .message-in-bubble-video .reply-message.reply-file .reply-file-thumbnail {
  display: inline-block;
  width: 40px;
  height: 40px;
  margin-right: 10px;
  position: relative; }

.chat-room-message-video-in-wrapper .message-in-bubble-video .reply-message.reply-file .reply-file-thumbnail img {
  width: 40px;
  height: 40px; }

.chat-room-message-video-in-wrapper .message-in-bubble-video .reply-message.reply-file .reply-file-thumbnail svg {
  position: absolute;
  left: 50%;
  top: 50%;
  font-size: 35px;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%); }

.chat-room-message-video-in-wrapper .message-in-bubble-video .reply-message.reply-file .reply-text-wrapper {
  width: calc(100% - 50px);
  display: inline-block;
  vertical-align: top; }

.chat-room-message-video-in-wrapper .message-in-bubble-video .message-info {
  position: relative;
  margin: 0;
  white-space: nowrap;
  border-radius: 10px;
  font-size: 10px;
  letter-spacing: -0.4px;
  color: rgba(25, 25, 25, 0.4);
  width: 100%;
  text-align: right;
  padding: 2px 10px 10px; }
  .chat-room-message-video-in-wrapper .message-in-bubble-video .message-info .centered-dot {
    width: 2px;
    height: 2px;
    display: inline-block;
    vertical-align: top;
    background: rgba(25, 25, 25, 0.4);
    border-radius: 50%;
    position: relative;
    top: 9px; }

.chat-room-message-video-in-wrapper .message-in-bubble-video .group-sender-name-wrapper {
  color: #ff7d00;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
  font-size: 14px; }

.chat-room-message-video-in-wrapper .message-in-bubble-video .group-sender-name-wrapper .agent-span {
  color: #A3A3A3; }

.chat-room-message-video-in-wrapper .message-in-bubble-video .message-info-dark {
  background: rgba(25, 25, 25, 0.6);
  padding: 2px 5px;
  color: #ffffff;
  bottom: 16px;
  right: 16px;
  position: absolute;
  width: unset; }
  .chat-room-message-video-in-wrapper .message-in-bubble-video .message-info-dark .centered-dot {
    background: #ffffff; }

.chat-room-message-video-in-wrapper .message-in-bubble-video .message-info img {
  vertical-align: top;
  margin-left: 3px; }

.chat-room-message-video-in-wrapper .message-in-bubble-video.not-sent-message-bubble .dark-cover {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 2px 16px 16px; }

.chat-room-message-video-in-wrapper .message-in-bubble-video.not-sent-message-bubble .dark-cover .icon-status-wrapper {
  border-radius: 50%;
  position: absolute;
  width: 48px;
  height: 48px;
  display: inline-block;
  background: rgba(25, 25, 25, 0.4);
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  cursor: pointer; }

.chat-room-message-video-in-wrapper .message-in-bubble-video.not-sent-message-bubble .dark-cover .icon-status-wrapper svg {
  color: #ffffff;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  font-size: 20px; }

.chat-room-message-video-in-wrapper .message-in-bubble-video.not-sent-message-bubble .dark-cover .icon-status-wrapper .onprocess-wrapper {
  height: 40px;
  width: 40px;
  display: inline-block;
  border: solid 2px #ffffff;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%); }

.chat-room-message-video-in-wrapper .message-in-bubble-video .timer-filesize-wrapper {
  background: rgba(25, 25, 25, 0.6);
  position: absolute;
  white-space: nowrap;
  border-radius: 10px;
  font-size: 12px;
  padding: 2px 5px;
  color: rgba(255, 255, 255, 0.6);
  top: 10px;
  left: 10px;
  z-index: 1; }

.chat-room-message-video-in-wrapper .message-in-bubble-video .play-button-wraper {
  border-radius: 50%;
  position: absolute;
  width: 48px;
  height: 48px;
  display: inline-block;
  background: rgba(25, 25, 25, 0.4);
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  cursor: pointer; }

.chat-room-message-video-in-wrapper .message-in-bubble-video .play-button-wraper svg {
  color: #ffffff;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  font-size: 20px; }

.chat-room-message-video-in-wrapper:hover .message-action-wrapper {
  display: inline-block; }

.chat-room-message-video-in-wrapper:hover .message-action-dropdown {
  display: inline-block; }

.chat-room-message-video-in-wrapper .message-action-wrapper {
  right: -35px !important;
  left: unset !important; }
  .chat-room-message-video-in-wrapper .message-action-wrapper.with-forward {
    right: -73px !important; }
    .chat-room-message-video-in-wrapper .message-action-wrapper.with-forward .forward-button {
      display: inline-block !important; }

.chat-room-message-video-in-wrapper .message-action-wrapper .message-action-button-wrapper {
  cursor: pointer; }

.chat-room-message-video-in-wrapper .CircularProgressbar .CircularProgressbar-trail {
  stroke: transparent; }

.chat-room-message-video-in-wrapper .CircularProgressbar .CircularProgressbar-path {
  stroke: #ffffff; }

.modal-video.modal-dialog {
  width: 100vw !important; }

.modal-video .modal-content {
  width: 100%;
  height: 100%;
  background: none; }

.modal-video .modal-content .modal-body {
  width: 100%;
  height: 100%;
  background: rgba(25, 25, 25, 0.8); }

.modal-video .modal-content .modal-body .video-wrapper {
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%); }

.modal-video .modal-content .modal-body .close-modal-video {
  position: absolute;
  top: 20px;
  right: 20px;
  color: #ffffff;
  font-size: 40px;
  cursor: pointer;
  -webkit-transition: ease-in-out 0.1s;
  transition: ease-in-out 0.1s; }

.modal-video .modal-content .modal-body .close-modal-video:hover {
  color: #c9c9c9; }

.modal-video .modal-content .modal-body video {
  max-height: calc(100vh - 130px);
  max-width: calc(100vw - 130px); }

.hide-reply-button .message-in-bubble-video .message-action-wrapper {
  right: -40px !important; }
  .hide-reply-button .message-in-bubble-video .message-action-wrapper.with-forward {
    right: -40px !important; }

.chat-room-message-in-wrapper {
  margin-top: 16px;
  padding: 0 24px;
  width: 100%;
  position: relative; }
  .chat-room-message-in-wrapper .location-wrapper {
    margin-bottom: 4px; }
    .chat-room-message-in-wrapper .location-wrapper .location-content {
      cursor: pointer;
      width: 100%;
      height: 180px;
      border-radius: 2px 16px 0 0;
      overflow: hidden; }
      .chat-room-message-in-wrapper .location-wrapper .location-content button {
        display: none; }
      .chat-room-message-in-wrapper .location-wrapper .location-content img[src="https://maps.gstatic.com/mapfiles/api-3/images/google_white5.png"] {
        display: none; }
      .chat-room-message-in-wrapper .location-wrapper .location-content span {
        display: none; }
      .chat-room-message-in-wrapper .location-wrapper .location-content a {
        display: none; }
      .chat-room-message-in-wrapper .location-wrapper .location-content .gm-style:first-of-type > div:nth-child(1) {
        cursor: pointer !important; }

.chat-room-message-in-wrapper .message-in-bubble-location {
  border-radius: 2px 16px 16px;
  border: 1px solid rgba(25, 25, 25, 0.1);
  background: #ffffff;
  display: inline-block;
  /* max-width: calc(100% - 180px); */
  max-width: calc(100% - 100px);
  padding: 10px 45px 10px 10px;
  padding: 10px;
  color: #191919;
  font-size: 16px;
  letter-spacing: -0.3px;
  line-height: 24px;
  position: relative;
  min-height: 44px; }

.chat-room-message-in-wrapper .message-in-bubble-location.deleted-bubble {
  color: #9b9b9b;
  padding: 10px 10px 10px 33px; }

.chat-room-message-in-wrapper .message-in-bubble-location.deleted-bubble .deleted-icon {
  position: absolute;
  left: 10px;
  top: 50%;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%); }

.chat-room-message-in-wrapper .message-in-bubble-location.deleted-group-in {
  padding: 10px; }

.chat-room-message-in-wrapper .message-in-bubble-location.deleted-group-in .deleted-icon {
  position: relative;
  left: 0;
  top: 5px;
  margin-right: 3px;
  -webkit-transform: unset;
          transform: unset; }

.chat-room-message-in-wrapper .message-in-bubble-location .forwarded-message {
  color: #191919;
  font-size: 12px;
  letter-spacing: -0.4px;
  line-height: 18px;
  display: block;
  margin-bottom: 5px; }

.chat-room-message-in-wrapper .message-in-bubble-location .reply-message {
  width: calc(100% - 20px);
  border-radius: 4px;
  border-left: solid 5px #ff7d00;
  background: #f3f3f3;
  color: #191919;
  position: absolute;
  top: 10px;
  left: 10px;
  padding: 5px 7px 5px 7px;
  line-height: 1; }

.chat-room-message-in-wrapper .message-in-bubble-location .reply-message p {
  margin: 0;
  font-size: 12px;
  letter-spacing: -0.4px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 20px; }

.chat-room-message-in-wrapper .message-in-bubble-location .reply-message p:nth-child(2) {
  letter-spacing: -0.2px; }

.chat-room-message-in-wrapper .message-in-bubble-location .reply-message.reply-file .reply-file-thumbnail {
  display: inline-block;
  width: 40px;
  height: 40px;
  margin-right: 10px;
  position: relative; }

.chat-room-message-in-wrapper .message-in-bubble-location .reply-message.reply-file .reply-file-thumbnail img {
  width: 40px;
  height: 40px; }

.chat-room-message-in-wrapper .message-in-bubble-location .reply-message.reply-file .reply-file-thumbnail svg {
  position: absolute;
  left: 50%;
  top: 50%;
  font-size: 35px;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%); }

.chat-room-message-in-wrapper .message-in-bubble-location .reply-message.reply-file .reply-text-wrapper {
  width: calc(100% - 50px);
  display: inline-block;
  vertical-align: top; }

.chat-room-message-in-wrapper .message-in-bubble-location .message-info {
  /* position: absolute; */
  margin: 0;
  /* bottom: 11px; */
  /* right: 10px; */
  color: rgba(25, 25, 25, 0.4);
  font-size: 10px;
  letter-spacing: -0.4px;
  line-height: 18px;
  /* width: 24px; */
  width: 100%;
  text-align: right;
  /* width: 26px; */ }
  .chat-room-message-in-wrapper .message-in-bubble-location .message-info .centered-dot {
    width: 2px;
    height: 2px;
    display: inline-block;
    vertical-align: top;
    background: rgba(25, 25, 25, 0.4);
    border-radius: 50%;
    position: relative;
    top: 9px; }

.chat-room-message-in-wrapper .message-in-bubble-location .group-sender-name-wrapper {
  color: #ff7d00;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
  font-size: 14px; }

.chat-room-message-in-wrapper .message-in-bubble-location .group-sender-name-wrapper .agent-span {
  color: #A3A3A3; }

.chat-room-message-in-wrapper:hover .message-action-wrapper {
  display: inline-block; }

.chat-room-message-in-wrapper:hover .message-action-dropdown {
  display: inline-block; }

.chat-room-message-in-wrapper .group-sender-avatar-wrapper {
  width: 32px;
  height: 32px;
  display: inline-block;
  border-radius: 50%;
  vertical-align: top;
  margin-right: 8px;
  font-size: 16px;
  line-height: 32px;
  text-align: center;
  color: #ffffff;
  position: relative; }

.chat-room-message-in-wrapper .group-sender-avatar-wrapper .avatar-icon-badge {
  width: 20px;
  bottom: -2px;
  right: -1px;
  height: 20px;
  position: absolute; }

.chat-room-message-in-wrapper .group-sender-avatar-wrapper img {
  width: 32px;
  height: 32px;
  display: inline-block;
  border-radius: 50%; }

.chat-room-message-in-wrapper .message-action-wrapper {
  /* right: -120px !important; */
  right: -80px !important; }
  .chat-room-message-in-wrapper .message-action-wrapper.with-forward {
    right: -118px !important; }
    .chat-room-message-in-wrapper .message-action-wrapper.with-forward .forward-button {
      display: inline-block !important; }

.hide-reply-button .message-in-bubble-location .message-action-wrapper {
  right: -40px !important; }
  .hide-reply-button .message-in-bubble-location .message-action-wrapper.with-forward {
    right: -78px !important; }

.chat-room-message-in-tokopedia--wrapper {
  margin-top: 16px;
  padding: 0 24px;
  width: 100%;
  position: relative; }
  .chat-room-message-in-tokopedia--wrapper .tokopedia-image-wrapper {
    background: rgba(25, 25, 25, 0.1);
    position: relative;
    width: 72px;
    height: 72px;
    border-radius: 6px;
    overflow: hidden;
    margin-right: 12px;
    display: inline-block; }
    .chat-room-message-in-tokopedia--wrapper .tokopedia-image-wrapper img {
      max-width: 100%;
      max-height: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%); }
  .chat-room-message-in-tokopedia--wrapper .tokopedia-price-wrapper {
    margin: 0; }
  .chat-room-message-in-tokopedia--wrapper .tokopedia-link {
    cursor: pointer;
    color: var(--main-dark-orange);
    border: solid 1px var(--main-dark-orange);
    font-size: 14px;
    letter-spacing: -0.5px;
    width: 100%;
    text-align: center;
    border-radius: 6px;
    height: 34px;
    line-height: 32px;
    margin: 12px 0 4px 0; }
    .chat-room-message-in-tokopedia--wrapper .tokopedia-link svg {
      font-size: 12px;
      position: relative;
      top: -1px;
      margin-right: 4px; }
    .chat-room-message-in-tokopedia--wrapper .tokopedia-link:hover {
      color: var(--main-darker-orange);
      border: solid 1px var(--main-darker-orange); }
      .chat-room-message-in-tokopedia--wrapper .tokopedia-link:hover svg {
        color: var(--main-darker-orange); }
  .chat-room-message-in-tokopedia--wrapper .tokopedia-product-description-wrapper {
    vertical-align: top;
    width: calc(100% - 84px);
    display: inline-block; }

.chat-room-message-in-tokopedia--wrapper .message-in-bubble {
  border-radius: 2px 16px 16px;
  border: 1px solid rgba(25, 25, 25, 0.1);
  background: #ffffff;
  display: inline-block;
  /* max-width: calc(100% - 180px); */
  max-width: calc(100% - 110px);
  padding: 10px 45px 10px 10px;
  padding: 10px;
  color: #191919;
  font-size: 16px;
  letter-spacing: -0.3px;
  line-height: 24px;
  position: relative;
  min-height: 44px; }

.chat-room-message-in-tokopedia--wrapper .message-in-bubble.deleted-bubble {
  color: #9b9b9b;
  padding: 10px 10px 10px 33px; }

.chat-room-message-in-tokopedia--wrapper .message-in-bubble.deleted-bubble .deleted-icon {
  position: absolute;
  left: 10px;
  top: 50%;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%); }

.chat-room-message-in-tokopedia--wrapper .message-in-bubble.deleted-group-in {
  padding: 10px; }

.chat-room-message-in-tokopedia--wrapper .message-in-bubble.deleted-group-in .deleted-icon {
  position: relative;
  left: 0;
  top: 5px;
  margin-right: 3px;
  -webkit-transform: unset;
          transform: unset; }

.chat-room-message-in-tokopedia--wrapper .message-in-bubble .forwarded-message {
  color: #191919;
  font-size: 12px;
  letter-spacing: -0.4px;
  line-height: 18px;
  display: block;
  margin-bottom: 5px; }

.chat-room-message-in-tokopedia--wrapper .message-in-bubble .reply-message {
  width: calc(100% - 20px);
  border-radius: 4px;
  border-left: solid 5px #ff7d00;
  background: #f3f3f3;
  color: #191919;
  position: absolute;
  top: 10px;
  left: 10px;
  padding: 5px 7px 5px 7px;
  line-height: 1; }

.chat-room-message-in-tokopedia--wrapper .message-in-bubble .reply-message p {
  margin: 0;
  font-size: 12px;
  letter-spacing: -0.4px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 20px; }

.chat-room-message-in-tokopedia--wrapper .message-in-bubble .reply-message p:nth-child(2) {
  letter-spacing: -0.2px; }

.chat-room-message-in-tokopedia--wrapper .message-in-bubble .reply-message.reply-file .reply-file-thumbnail {
  display: inline-block;
  width: 40px;
  height: 40px;
  margin-right: 10px;
  position: relative; }

.chat-room-message-in-tokopedia--wrapper .message-in-bubble .reply-message.reply-file .reply-file-thumbnail img {
  width: 40px;
  height: 40px; }

.chat-room-message-in-tokopedia--wrapper .message-in-bubble .reply-message.reply-file .reply-file-thumbnail svg {
  position: absolute;
  left: 50%;
  top: 50%;
  font-size: 35px;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%); }

.chat-room-message-in-tokopedia--wrapper .message-in-bubble .reply-message.reply-file .reply-text-wrapper {
  width: calc(100% - 50px);
  display: inline-block;
  vertical-align: top; }

.chat-room-message-in-tokopedia--wrapper .message-in-bubble .message-info {
  /* position: absolute; */
  margin: 0;
  /* bottom: 11px; */
  /* right: 10px; */
  color: rgba(25, 25, 25, 0.4);
  font-size: 10px;
  letter-spacing: -0.4px;
  line-height: 18px;
  /* width: 24px; */
  width: 100%;
  text-align: right;
  /* width: 26px; */ }
  .chat-room-message-in-tokopedia--wrapper .message-in-bubble .message-info .centered-dot {
    width: 2px;
    height: 2px;
    display: inline-block;
    vertical-align: top;
    background: rgba(25, 25, 25, 0.4);
    border-radius: 50%;
    position: relative;
    top: 9px; }

.chat-room-message-in-tokopedia--wrapper .message-in-bubble .group-sender-name-wrapper {
  color: #ff7d00;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
  font-size: 14px; }

.chat-room-message-in-tokopedia--wrapper .message-in-bubble .group-sender-name-wrapper .agent-span {
  color: #A3A3A3; }

.chat-room-message-in-tokopedia--wrapper:hover .message-action-wrapper {
  display: inline-block; }

.chat-room-message-in-tokopedia--wrapper:hover .message-action-dropdown {
  display: inline-block; }

.chat-room-message-in-tokopedia--wrapper .group-sender-avatar-wrapper {
  width: 32px;
  height: 32px;
  display: inline-block;
  border-radius: 50%;
  vertical-align: top;
  margin-right: 8px;
  font-size: 16px;
  line-height: 32px;
  text-align: center;
  color: #ffffff;
  position: relative; }

.chat-room-message-in-tokopedia--wrapper .group-sender-avatar-wrapper .avatar-icon-badge {
  width: 20px;
  bottom: -2px;
  right: -1px;
  height: 20px;
  position: absolute; }

.chat-room-message-in-tokopedia--wrapper .group-sender-avatar-wrapper img {
  width: 32px;
  height: 32px;
  display: inline-block;
  border-radius: 50%; }

.chat-room-message-in-tokopedia--wrapper .message-action-wrapper {
  /* right: -120px !important; */
  right: -80px !important; }
  .chat-room-message-in-tokopedia--wrapper .message-action-wrapper.with-forward {
    right: -118px !important; }
    .chat-room-message-in-tokopedia--wrapper .message-action-wrapper.with-forward .forward-button {
      display: inline-block !important; }

.hide-reply-button .message-in-bubble .message-action-wrapper {
  right: -40px !important; }
  .hide-reply-button .message-in-bubble .message-action-wrapper.with-forward {
    right: -78px !important; }

.chat-room-message-in-email-wrapper {
  margin-top: 16px;
  padding: 0 24px;
  width: 100%;
  position: relative; }

.chat-room-message-in-email-wrapper .message-in-bubble {
  border-radius: 2px 16px 16px;
  border: 1px solid rgba(25, 25, 25, 0.1);
  background: #ffffff;
  display: inline-block;
  width: 100%;
  padding: 10px;
  color: #191919;
  font-size: 16px;
  letter-spacing: -0.3px;
  line-height: 24px;
  position: relative;
  min-height: 44px; }
  .chat-room-message-in-email-wrapper .message-in-bubble .message-in-email-wrapper-top {
    position: relative;
    width: 100%;
    padding: 0 82px 10px 48px;
    border-bottom: solid 1px rgba(25, 25, 25, 0.1); }
    .chat-room-message-in-email-wrapper .message-in-bubble .message-in-email-wrapper-top .top-avatar-wrapper {
      position: relative;
      width: 40px;
      height: 40px;
      display: inline-block;
      border-radius: 50%;
      background: rgba(25, 25, 25, 0.2);
      position: absolute;
      left: 0;
      top: 0; }
      .chat-room-message-in-email-wrapper .message-in-bubble .message-in-email-wrapper-top .top-avatar-wrapper b {
        color: #fff;
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%); }
      .chat-room-message-in-email-wrapper .message-in-bubble .message-in-email-wrapper-top .top-avatar-wrapper .other-agent-icon {
        position: absolute;
        bottom: -1px;
        right: -1px;
        width: 16px;
        height: 16px;
        border-radius: 50%;
        border: solid 2px #fff;
        background: #0052ff;
        padding: 2px; }
        .chat-room-message-in-email-wrapper .message-in-bubble .message-in-email-wrapper-top .top-avatar-wrapper .other-agent-icon img {
          position: absolute;
          top: 50%;
          left: 50%;
          -webkit-transform: translate(-50%, -50%);
                  transform: translate(-50%, -50%);
          width: 8px; }
    .chat-room-message-in-email-wrapper .message-in-bubble .message-in-email-wrapper-top .top-info-wrapper {
      height: 50px; }
    .chat-room-message-in-email-wrapper .message-in-bubble .message-in-email-wrapper-top .top-name {
      font-size: 14px;
      letter-spacing: -0.4px;
      margin: 0 0 2px 0;
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      line-height: 16px;
      white-space: nowrap;
      color: rgba(25, 25, 25, 0.4); }
    .chat-room-message-in-email-wrapper .message-in-bubble .message-in-email-wrapper-top .top-subject {
      font-size: 12px;
      letter-spacing: -0.2px;
      margin: 0 0 2px 0;
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      color: rgba(25, 25, 25, 0.6);
      line-height: 16px;
      white-space: nowrap; }
    .chat-room-message-in-email-wrapper .message-in-bubble .message-in-email-wrapper-top .top-info {
      font-size: 12px;
      margin: 0;
      letter-spacing: -0.2px;
      margin: 0 0 2px 0;
      color: rgba(25, 25, 25, 0.6);
      line-height: 16px;
      position: relative;
      padding-right: 16px;
      display: inline-block;
      max-width: 100%; }
      .chat-room-message-in-email-wrapper .message-in-bubble .message-in-email-wrapper-top .top-info .top-info-name {
        white-space: nowrap;
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis; }
      .chat-room-message-in-email-wrapper .message-in-bubble .message-in-email-wrapper-top .top-info .dropdown {
        position: absolute;
        top: -1px;
        right: 0;
        margin-left: 2px;
        box-shadow: none !important; }
        .chat-room-message-in-email-wrapper .message-in-bubble .message-in-email-wrapper-top .top-info .dropdown button {
          line-height: 1;
          padding: 0;
          background: transparent !important;
          border: none !important;
          box-shadow: none !important; }
        .chat-room-message-in-email-wrapper .message-in-bubble .message-in-email-wrapper-top .top-info .dropdown .dropdown-menu {
          padding: 16px 16px 12px 16px;
          font-size: 12px;
          letter-spacing: -0.2px;
          width: 320px;
          border-radius: 8px;
          margin: 0;
          overflow-y: auto;
          max-height: 270px; }
          .chat-room-message-in-email-wrapper .message-in-bubble .message-in-email-wrapper-top .top-info .dropdown .dropdown-menu button {
            box-shadow: none !important; }
    .chat-room-message-in-email-wrapper .message-in-bubble .message-in-email-wrapper-top .top-info-arrow {
      font-size: 14px;
      color: rgba(25, 25, 25, 0.6);
      -webkit-transition: ease-in-out .1s;
      transition: ease-in-out .1s; }
      .chat-room-message-in-email-wrapper .message-in-bubble .message-in-email-wrapper-top .top-info-arrow:hover {
        color: var(--main-dark-orange); }
    .chat-room-message-in-email-wrapper .message-in-bubble .message-in-email-wrapper-top .message-info {
      position: absolute;
      top: 0;
      right: 0;
      display: inline-block; }
  .chat-room-message-in-email-wrapper .message-in-bubble .message-in-email-wrapper-bottom {
    width: 100%;
    position: relative;
    padding: 12px 0 0; }

.chat-room-message-in-email-wrapper .message-in-bubble.deleted-bubble {
  color: #9b9b9b;
  padding: 10px 10px 10px 33px; }

.chat-room-message-in-email-wrapper .message-in-bubble.deleted-bubble .deleted-icon {
  position: absolute;
  left: 10px;
  top: 50%;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%); }

.chat-room-message-in-email-wrapper .message-in-bubble.deleted-group-in {
  padding: 10px; }

.chat-room-message-in-email-wrapper .message-in-bubble.deleted-group-in .deleted-icon {
  position: relative;
  left: 0;
  top: 5px;
  margin-right: 3px;
  -webkit-transform: unset;
          transform: unset; }

.chat-room-message-in-email-wrapper .message-in-bubble .forwarded-message {
  color: #191919;
  font-size: 12px;
  letter-spacing: -0.4px;
  line-height: 18px;
  display: block;
  margin-bottom: 5px; }

.chat-room-message-in-email-wrapper .message-in-bubble .reply-message {
  width: calc(100% - 20px);
  border-radius: 4px;
  border-left: solid 5px #ff7d00;
  background: #f3f3f3;
  color: #191919;
  position: absolute;
  top: 10px;
  left: 10px;
  padding: 5px 7px 5px 7px;
  line-height: 1; }

.chat-room-message-in-email-wrapper .message-in-bubble .reply-message p {
  margin: 0;
  font-size: 12px;
  letter-spacing: -0.4px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 20px; }

.chat-room-message-in-email-wrapper .message-in-bubble .reply-message p:nth-child(2) {
  letter-spacing: -0.2px; }

.chat-room-message-in-email-wrapper .message-in-bubble .reply-message.reply-file .reply-file-thumbnail {
  display: inline-block;
  width: 40px;
  height: 40px;
  margin-right: 10px;
  position: relative; }

.chat-room-message-in-email-wrapper .message-in-bubble .reply-message.reply-file .reply-file-thumbnail img {
  width: 40px;
  height: 40px; }

.chat-room-message-in-email-wrapper .message-in-bubble .reply-message.reply-file .reply-file-thumbnail svg {
  position: absolute;
  left: 50%;
  top: 50%;
  font-size: 35px;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%); }

.chat-room-message-in-email-wrapper .message-in-bubble .reply-message.reply-file .reply-text-wrapper {
  width: calc(100% - 50px);
  display: inline-block;
  vertical-align: top; }

.chat-room-message-in-email-wrapper .message-in-bubble .message-info {
  /* position: absolute; */
  margin: 0;
  /* bottom: 11px; */
  /* right: 10px; */
  color: rgba(25, 25, 25, 0.4);
  font-size: 10px;
  letter-spacing: -0.4px;
  line-height: 18px;
  /* width: 24px; */
  width: 100%;
  text-align: right;
  /* width: 26px; */ }
  .chat-room-message-in-email-wrapper .message-in-bubble .message-info .centered-dot {
    width: 2px;
    height: 2px;
    display: inline-block;
    vertical-align: top;
    background: rgba(25, 25, 25, 0.4);
    border-radius: 50%;
    position: relative;
    top: 9px; }

.chat-room-message-in-email-wrapper .message-in-bubble .group-sender-name-wrapper {
  color: #ff7d00;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
  font-size: 14px; }

.chat-room-message-in-email-wrapper .message-in-bubble .group-sender-name-wrapper .agent-span {
  color: #A3A3A3; }

.chat-room-message-in-email-wrapper:hover .message-action-wrapper {
  display: inline-block; }

.chat-room-message-in-email-wrapper .group-sender-avatar-wrapper {
  width: 32px;
  height: 32px;
  display: inline-block;
  border-radius: 50%;
  vertical-align: top;
  margin-right: 8px;
  font-size: 16px;
  line-height: 32px;
  text-align: center;
  color: #ffffff;
  position: relative; }

.chat-room-message-in-email-wrapper .group-sender-avatar-wrapper .avatar-icon-badge {
  width: 20px;
  bottom: -2px;
  right: -1px;
  height: 20px;
  position: absolute; }

.chat-room-message-in-email-wrapper .group-sender-avatar-wrapper img {
  width: 32px;
  height: 32px;
  display: inline-block;
  border-radius: 50%; }

.chat-room-message-in-email-wrapper .message-action-wrapper {
  /* right: -120px !important; */
  right: -80px !important; }
  .chat-room-message-in-email-wrapper .message-action-wrapper.with-forward {
    right: -118px !important; }
    .chat-room-message-in-email-wrapper .message-action-wrapper.with-forward .forward-button {
      display: inline-block !important; }

.hide-reply-button .message-in-bubble .message-action-wrapper {
  right: -40px !important; }
  .hide-reply-button .message-in-bubble .message-action-wrapper.with-forward {
    right: -78px !important; }

.comp-email-wrapper-text {
  font-size: 16px;
  letter-spacing: -0.3px;
  color: #191919;
  line-height: 24px;
  word-break: break-word;
  margin: 0 0 12px 0; }

.comp-email-wrapper-docs .comp-email-wrapper-docs-inner {
  width: 100%;
  position: relative;
  display: block;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  grid-row-gap: 12px;
  row-gap: 12px;
  grid-column-gap: 7px;
  -webkit-column-gap: 7px;
          column-gap: 7px; }
  .comp-email-wrapper-docs .comp-email-wrapper-docs-inner .comp-email-wrapper-docs-box {
    border-radius: 8px;
    border: solid 1px rgba(25, 25, 25, 0.1);
    width: calc((100% / 3) - 5px); }
    .comp-email-wrapper-docs .comp-email-wrapper-docs-inner .comp-email-wrapper-docs-box .comp-email-wrapper-docs-box-top {
      background: #FFF2E5;
      width: 100%;
      height: 80px;
      position: relative;
      border-radius: 8px 8px 0 0; }
      .comp-email-wrapper-docs .comp-email-wrapper-docs-inner .comp-email-wrapper-docs-box .comp-email-wrapper-docs-box-top .comp-email-wrapper-docs-box-top-icon {
        width: 36px;
        height: 36px;
        border-radius: 50%;
        background: rgba(25, 25, 25, 0.4);
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%); }
        .comp-email-wrapper-docs .comp-email-wrapper-docs-inner .comp-email-wrapper-docs-box .comp-email-wrapper-docs-box-top .comp-email-wrapper-docs-box-top-icon svg {
          font-size: 20px;
          color: #fff;
          position: absolute;
          top: 50%;
          left: 50%;
          -webkit-transform: translate(-50%, -50%);
                  transform: translate(-50%, -50%); }
    .comp-email-wrapper-docs .comp-email-wrapper-docs-inner .comp-email-wrapper-docs-box .comp-email-wrapper-docs-box-bottom {
      padding: 8px 32px 8px 8px;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      position: relative;
      margin: 0; }
      .comp-email-wrapper-docs .comp-email-wrapper-docs-inner .comp-email-wrapper-docs-box .comp-email-wrapper-docs-box-bottom .comp-email-wrapper-docs-download {
        color: var(--main-dark-orange);
        margin-left: 6px;
        position: absolute;
        top: 50%;
        -webkit-transform: translate(0, -50%);
                transform: translate(0, -50%);
        right: 8px; }

.modal-image.modal-dialog {
  width: 100vw !important; }

.modal-image .modal-content {
  width: 100%;
  height: 100%;
  background: none; }

.modal-image .modal-content .modal-body {
  width: 100%;
  height: 100%;
  background: rgba(25, 25, 25, 0.8); }

.modal-image .modal-content .modal-body img.image-preview-val {
  max-width: calc(100vw - 130px);
  max-height: calc(100vh - 130px);
  background: #ffffff; }

.modal-image .modal-content .modal-body .image-wrapper {
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  text-align: center; }
  .modal-image .modal-content .modal-body .image-wrapper figure {
    margin: 0; }
    .modal-image .modal-content .modal-body .image-wrapper figure.zoom {
      background-size: 180%;
      background-position: -100% -100%;
      background-repeat: no-repeat;
      position: relative;
      width: 100%;
      overflow: hidden;
      cursor: -webkit-zoom-in;
      cursor: zoom-in; }
      .modal-image .modal-content .modal-body .image-wrapper figure.zoom img {
        -webkit-transition: opacity 0.5s;
        transition: opacity 0.5s;
        display: inline-block; }
        .modal-image .modal-content .modal-body .image-wrapper figure.zoom img:hover {
          opacity: 0; }

.modal-image .modal-content .modal-body .close-modal-image {
  position: absolute;
  top: 20px;
  right: 20px;
  color: #ffffff;
  font-size: 40px;
  cursor: pointer;
  -webkit-transition: ease-in-out 0.1s;
  transition: ease-in-out 0.1s; }

.modal-image .modal-content .modal-body .close-modal-image:hover {
  color: #c9c9c9; }

.modal-video.modal-dialog {
  width: 100vw !important; }

.modal-video .modal-content {
  width: 100%;
  height: 100%;
  background: none; }

.modal-video .modal-content .modal-body {
  width: 100%;
  height: 100%;
  background: rgba(25, 25, 25, 0.8); }

.modal-video .modal-content .modal-body .video-wrapper {
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%); }

.modal-video .modal-content .modal-body .close-modal-video {
  position: absolute;
  top: 20px;
  right: 20px;
  color: #ffffff;
  font-size: 40px;
  cursor: pointer;
  -webkit-transition: ease-in-out 0.1s;
  transition: ease-in-out 0.1s; }

.modal-video .modal-content .modal-body .close-modal-video:hover {
  color: #c9c9c9; }

.modal-video .modal-content .modal-body video {
  max-height: calc(100vh - 130px);
  max-width: calc(100vw - 130px); }

.comp-email-wrapper-info {
  width: 340px;
  max-width: 100%;
  background: #fff;
  font-size: 12px;
  letter-spacing: -0.4px;
  position: relative; }
  .comp-email-wrapper-info .comp-email-line-wrapper {
    width: 100%;
    display: block;
    vertical-align: top;
    box-sizing: border-box;
    line-height: 16px;
    cursor: default; }
    .comp-email-wrapper-info .comp-email-line-wrapper .comp-email-line-wrapper-left {
      width: 41px;
      display: inline-block;
      vertical-align: top;
      text-align: right;
      margin: 0 0 4px 0;
      color: rgba(25, 25, 25, 0.6); }
    .comp-email-wrapper-info .comp-email-line-wrapper .comp-email-line-wrapper-right {
      width: calc(100% - 41px);
      display: inline-block;
      vertical-align: top;
      margin: 0 0 4px 0;
      padding: 0 0 0 12px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap; }
    .comp-email-wrapper-info .comp-email-line-wrapper .comp-email-line-wrapper-right.with-overflow {
      overflow: visible !important;
      text-overflow: unset !important;
      white-space: normal !important;
      word-break: break-word; }

.chat-room-message-out-interactive-wrapper {
  margin-top: 16px;
  padding: 0 24px;
  width: 100%;
  position: relative;
  text-align: right; }
  .chat-room-message-out-interactive-wrapper .header-text {
    margin: 0;
    font-size: 16px;
    letter-spacing: -0.3px;
    word-break: break-word; }
  .chat-room-message-out-interactive-wrapper .body-text {
    margin: 0;
    font-size: 16px;
    letter-spacing: -0.3px;
    word-break: break-word; }
  .chat-room-message-out-interactive-wrapper .footer-text {
    margin: 0;
    color: #6F6A66;
    font-size: 16px;
    letter-spacing: -0.3px;
    word-break: break-word; }
  .chat-room-message-out-interactive-wrapper .button-text {
    height: 32px;
    text-align: center;
    width: 100%;
    border-radius: 8px;
    line-height: 1;
    margin-top: 8px;
    color: var(--main-dark-orange);
    cursor: pointer;
    font-size: 14px;
    letter-spacing: -0.2px;
    border: solid 1px var(--main-dark-orange);
    border-radius: 8px;
    padding: 8px 12px 0; }
    .chat-room-message-out-interactive-wrapper .button-text svg {
      position: relative;
      top: 0;
      margin-right: 4px;
      vertical-align: top; }
  .chat-room-message-out-interactive-wrapper .buttons-2 .button-text-0 {
    border-radius: 8px 8px 0 0 !important; }
  .chat-room-message-out-interactive-wrapper .buttons-2 .button-text-1 {
    border-radius: 0 0 8px 8px !important;
    margin-top: 0px !important;
    border-top: 0 !important; }
  .chat-room-message-out-interactive-wrapper .buttons-3 .button-text-0 {
    border-radius: 8px 8px 0 0 !important; }
  .chat-room-message-out-interactive-wrapper .buttons-3 .button-text-1 {
    border-radius: 0 !important;
    margin-top: 0px !important;
    border-top: 0 !important; }
  .chat-room-message-out-interactive-wrapper .buttons-3 .button-text-2 {
    border-radius: 0 0 8px 8px !important;
    margin-top: 0px !important;
    border-top: 0 !important; }
  .chat-room-message-out-interactive-wrapper .link-preview-side-decoration-out {
    background-color: var(--main-dark-orange); }
  .chat-room-message-out-interactive-wrapper .link-preview-title-out {
    color: #191919; }
  .chat-room-message-out-interactive-wrapper .link-preview-description-out {
    color: #191919; }

.chat-room-message-out-interactive-wrapper .message-out-interactive-bubble {
  border-radius: 16px 2px 16px 16px;
  border: 1px solid rgba(25, 25, 25, 0.1);
  background: #FFF2E5;
  display: inline-block;
  /* max-width: calc(100% - 180px); */
  max-width: calc(100% - 50px);
  /* padding: 10px 62px 10px 10px; */
  padding: 10px;
  color: #191919;
  font-size: 16px;
  letter-spacing: -0.3px;
  line-height: 24px;
  position: relative;
  text-align: left;
  min-height: 44px; }

.chat-room-message-out-interactive-wrapper .message-out-interactive-bubble.deleted-bubble {
  color: #ffffff;
  padding: 10px 10px 10px 33px; }

.chat-room-message-out-interactive-wrapper .message-out-interactive-bubble.deleted-bubble .deleted-icon {
  position: absolute;
  left: 10px;
  top: 50%;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%); }

.chat-room-message-out-interactive-wrapper .message-out-interactive-bubble .forwarded-message {
  color: #ffffff;
  font-size: 12px;
  letter-spacing: -0.4px;
  line-height: 18px;
  display: block;
  margin-bottom: 5px; }

.chat-room-message-out-interactive-wrapper .message-out-interactive-bubble .reply-message {
  width: calc(100% - 20px);
  border-radius: 4px;
  border-left: solid 5px #FF8F22;
  background: #E88422;
  color: #ffffff;
  position: absolute;
  top: 10px;
  left: 10px;
  padding: 5px 7px 5px 7px;
  line-height: 1; }

.chat-room-message-out-interactive-wrapper .message-out-interactive-bubble .reply-message p {
  margin: 0;
  font-size: 12px;
  letter-spacing: -0.4px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 20px; }

.chat-room-message-out-interactive-wrapper .message-out-interactive-bubble .reply-message p:nth-child(2) {
  letter-spacing: -0.2px; }

.chat-room-message-out-interactive-wrapper .message-out-interactive-bubble .reply-message.reply-file .reply-file-thumbnail {
  display: inline-block;
  width: 40px;
  height: 40px;
  margin-right: 10px;
  position: relative; }

.chat-room-message-out-interactive-wrapper .message-out-interactive-bubble .reply-message.reply-file .reply-file-thumbnail img {
  width: 40px;
  height: 40px; }

.chat-room-message-out-interactive-wrapper .message-out-interactive-bubble .reply-message.reply-file .reply-file-thumbnail svg {
  position: absolute;
  left: 50%;
  top: 50%;
  font-size: 35px;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%); }

.chat-room-message-out-interactive-wrapper .message-out-interactive-bubble .reply-message.reply-file .reply-text-wrapper {
  width: calc(100% - 50px);
  display: inline-block;
  vertical-align: top; }

.chat-room-message-out-interactive-wrapper .message-out-interactive-bubble .message-info {
  /* position: absolute; */
  /* bottom: 11px; */
  /* right: 27px; */
  margin: 0;
  color: rgba(25, 25, 25, 0.4);
  letter-spacing: -0.4px;
  font-size: 10px;
  line-height: 18px;
  /* width: 24px; */
  width: 100%;
  white-space: nowrap;
  text-align: right; }
  .chat-room-message-out-interactive-wrapper .message-out-interactive-bubble .message-info .centered-dot {
    width: 2px;
    height: 2px;
    display: inline-block;
    vertical-align: top;
    background: rgba(25, 25, 25, 0.4);
    border-radius: 50%;
    position: relative;
    top: 9px; }

.chat-room-message-out-interactive-wrapper .message-out-interactive-bubble .group-sender-name-wrapper {
  color: #ff7d00;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
  font-size: 14px; }

.chat-room-message-out-interactive-wrapper .message-out-interactive-bubble .group-sender-name-wrapper .agent-span {
  color: rgba(25, 25, 25, 0.4); }

.chat-room-message-out-interactive-wrapper .message-out-interactive-bubble .message-info img {
  margin-left: 4px;
  width: 16px;
  position: relative;
  /* top: 4px; */ }

.message-action-wrapper {
  position: relative;
  position: absolute;
  top: 50%;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%);
  /* left: -170px; */
  /* left: -90px; */
  left: -90px;
  display: none; }

.message-action-wrapper.message-action-right {
  right: -170px;
  left: unset; }

.message-action-wrapper .forward-message svg {
  -webkit-transform: scaleX(-1);
          transform: scaleX(-1); }

.message-action-wrapper .message-action-button-wrapper {
  border-radius: 50%;
  width: 30px;
  height: 30px;
  text-align: center;
  position: relative;
  display: inline-block;
  background-attachment: fixed;
  margin-left: 10px; }

.message-action-wrapper .message-action-button-wrapper svg {
  color: #9b9b9b;
  position: absolute;
  font-size: 18px;
  left: 6px;
  top: 6px; }

.chat-room-message-out-interactive-wrapper:hover .message-action-wrapper {
  display: inline-block; }

.chat-room-message-out-interactive-wrapper:hover .message-action-dropdown {
  display: inline-block; }

.message-action-popup-wrapper {
  width: 172px;
  display: inline-block;
  box-shadow: 0px 0px 9px 0px rgba(25, 25, 25, 0.1);
  line-height: 1;
  position: absolute;
  right: 63px;
  top: 0;
  border-radius: 8px;
  z-index: 1; }

.message-action-popup-wrapper .message-action-content {
  width: 100%;
  position: relative;
  padding: 12px 12px 12px 35px;
  font-size: 15px;
  letter-spacing: -0.3px;
  color: #191919;
  border-bottom: solid 1px #dcdcdc;
  background: #ffffff;
  cursor: pointer; }

.message-action-popup-wrapper .message-action-content.delete-message {
  color: #ff3f57; }

.message-action-popup-wrapper .message-action-content.forward-message svg {
  -webkit-transform: rotate(180deg) translate(0, 50%);
          transform: rotate(180deg) translate(0, 50%); }

.message-action-popup-wrapper .message-action-content:hover {
  background: #ff7d00;
  color: #ffffff; }

.message-action-popup-wrapper .message-action-content:hover svg {
  color: #ffffff; }

.message-action-popup-wrapper .message-action-content:nth-child(1) {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px; }

.message-action-popup-wrapper .message-action-content:nth-last-child(1) {
  border: none;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px; }

.message-action-popup-wrapper svg {
  left: 12px;
  font-size: 16px;
  position: absolute;
  top: 50%;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%); }

.chat-room-message-out-interactive-wrapper .message-action-wrapper {
  /* right: -120px !important; */
  left: -90px !important; }
  .chat-room-message-out-interactive-wrapper .message-action-wrapper.with-forward {
    left: -128px !important; }
    .chat-room-message-out-interactive-wrapper .message-action-wrapper.with-forward .forward-button {
      display: inline-block !important; }

.hide-reply-button .message-out-interactive-bubble .message-action-wrapper {
  left: -45px !important; }
  .hide-reply-button .message-out-interactive-bubble .message-action-wrapper.with-forward {
    left: -88px !important; }

.reply-message-out-bubble {
  width: 100%;
  display: block;
  position: relative;
  background: rgba(25, 25, 25, 0.05);
  margin-bottom: 4px;
  border-radius: 8px;
  overflow: hidden;
  cursor: pointer; }
  .reply-message-out-bubble.with-media-or-file {
    padding: 8px 0; }
  .reply-message-out-bubble .reply-message-out-bubble-name-text-wrapper {
    padding: 0 8px; }
    .reply-message-out-bubble .reply-message-out-bubble-name-text-wrapper.with-border {
      padding: 0 8px 0 12px; }
      .reply-message-out-bubble .reply-message-out-bubble-name-text-wrapper.with-border::before {
        position: absolute;
        left: 0;
        top: 0;
        width: 4px;
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
        background: var(--main-dark-orange);
        content: "";
        height: 100%; }
    .reply-message-out-bubble .reply-message-out-bubble-name-text-wrapper .reply-file-media-wrapper {
      border-radius: 4px;
      width: 48px;
      height: 48px;
      display: inline-block;
      margin: 0 8px 0 0;
      vertical-align: top;
      position: relative;
      top: 0; }
      .reply-message-out-bubble .reply-message-out-bubble-name-text-wrapper .reply-file-media-wrapper .reply-message-image {
        width: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
        max-width: 100%;
        max-height: 100%;
        border-radius: 0 !important; }
      .reply-message-out-bubble .reply-message-out-bubble-name-text-wrapper .reply-file-media-wrapper .reply-message-video {
        width: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
        max-width: 100%;
        max-height: 100%;
        border-radius: 0 !important; }
      .reply-message-out-bubble .reply-message-out-bubble-name-text-wrapper .reply-file-media-wrapper .reply-message-file {
        border-radius: 50%;
        width: 100%;
        height: 100%;
        position: relative;
        background: #ff7e00; }
        .reply-message-out-bubble .reply-message-out-bubble-name-text-wrapper .reply-file-media-wrapper .reply-message-file svg {
          color: #ffffff;
          font-size: 22px;
          position: absolute;
          top: 50%;
          left: 50%;
          -webkit-transform: translate(-50%, -50%);
                  transform: translate(-50%, -50%); }
    .reply-message-out-bubble .reply-message-out-bubble-name-text-wrapper .reply-message-out-bubble-reply-name-wrapper {
      position: relative;
      display: inline-block;
      padding: 6px 0 8px;
      width: 100%;
      color: var(--main-dark-orange); }
      .reply-message-out-bubble .reply-message-out-bubble-name-text-wrapper .reply-message-out-bubble-reply-name-wrapper p {
        margin: 0; }
        .reply-message-out-bubble .reply-message-out-bubble-name-text-wrapper .reply-message-out-bubble-reply-name-wrapper p.reply-message-out-bubble-reply-name {
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          max-width: 100%;
          font-size: 12px;
          line-height: 18px;
          letter-spacing: -0.4px; }
        .reply-message-out-bubble .reply-message-out-bubble-name-text-wrapper .reply-message-out-bubble-reply-name-wrapper p.reply-message-out-bubble-reply-text {
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          max-width: 100%;
          font-size: 12px;
          line-height: 16px;
          letter-spacing: -0.2px;
          color: rgba(25, 25, 25, 0.4); }
    .reply-message-out-bubble .reply-message-out-bubble-name-text-wrapper.with-media-file {
      padding: 0 8px 0 12px; }
      .reply-message-out-bubble .reply-message-out-bubble-name-text-wrapper.with-media-file .reply-message-out-bubble-reply-name-wrapper {
        width: calc(100% - 66px); }

.chat-room-message-out-meta-carousel-wrapper {
  margin-top: 16px;
  padding: 0 0 0 24px;
  width: 100%;
  position: relative;
  text-align: right; }
  .chat-room-message-out-meta-carousel-wrapper .link-preview-side-decoration-out {
    background-color: var(--main-dark-orange); }
  .chat-room-message-out-meta-carousel-wrapper .link-preview-title-out {
    color: #191919; }
  .chat-room-message-out-meta-carousel-wrapper .link-preview-description-out {
    color: #191919; }

.chat-room-message-out-meta-carousel-wrapper .message-out-bubble-carousel-wrapper-out {
  overflow-x: auto;
  white-space: nowrap; }
  .chat-room-message-out-meta-carousel-wrapper .message-out-bubble-carousel-wrapper-out .message-out-bubble {
    border-radius: 16px 2px 16px 16px;
    border: 1px solid rgba(25, 25, 25, 0.1);
    display: inline-block;
    /* max-width: calc(100% - 180px); */
    /* padding: 10px 62px 10px 10px; */
    padding: 10px;
    color: #191919;
    font-size: 16px;
    letter-spacing: -0.3px;
    line-height: 24px;
    position: relative;
    text-align: left;
    min-height: 44px; }
  .chat-room-message-out-meta-carousel-wrapper .message-out-bubble-carousel-wrapper-out .carousel-card {
    word-break: break-word;
    background: #ffffff;
    width: 278px;
    padding: 10px;
    border-radius: 8px 8px 8px 8px;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2);
    position: relative;
    text-align: left;
    font-family: SFPro;
    overflow: hidden;
    margin: 4px 4px 0 0;
    vertical-align: top;
    display: inline-block;
    white-space: normal;
    background: #fff2e5; }
    .chat-room-message-out-meta-carousel-wrapper .message-out-bubble-carousel-wrapper-out .carousel-card .button-carousel-wrapper {
      margin: 4px 0 0;
      border: solid 1px var(--main-dark-orange);
      border-radius: 8px; }
      .chat-room-message-out-meta-carousel-wrapper .message-out-bubble-carousel-wrapper-out .carousel-card .button-carousel-wrapper .button-carousel {
        padding: 8px;
        text-align: center;
        color: var(--main-dark-orange);
        white-space: nowrap;
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 14px;
        letter-spacing: -0.2px; }
        .chat-room-message-out-meta-carousel-wrapper .message-out-bubble-carousel-wrapper-out .carousel-card .button-carousel-wrapper .button-carousel svg {
          position: relative;
          top: -1px;
          margin-right: 4px; }
        .chat-room-message-out-meta-carousel-wrapper .message-out-bubble-carousel-wrapper-out .carousel-card .button-carousel-wrapper .button-carousel img {
          position: relative;
          top: -1px;
          margin-right: 4px; }
        .chat-room-message-out-meta-carousel-wrapper .message-out-bubble-carousel-wrapper-out .carousel-card .button-carousel-wrapper .button-carousel:nth-child(2) {
          border-top: solid 1px var(--main-dark-orange); }
        .chat-room-message-out-meta-carousel-wrapper .message-out-bubble-carousel-wrapper-out .carousel-card .button-carousel-wrapper .button-carousel:nth-child(3) {
          border-top: solid 1px var(--main-dark-orange); }
    .chat-room-message-out-meta-carousel-wrapper .message-out-bubble-carousel-wrapper-out .carousel-card .carousel-card-image-wrapper .image-wrapper {
      width: 100%;
      height: 160px;
      position: relative;
      background: #fafafa;
      border-radius: 8px;
      overflow: hidden;
      margin: 0 0 4px 0; }
      .chat-room-message-out-meta-carousel-wrapper .message-out-bubble-carousel-wrapper-out .carousel-card .carousel-card-image-wrapper .image-wrapper img {
        max-width: 100%;
        max-height: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%); }
    .chat-room-message-out-meta-carousel-wrapper .message-out-bubble-carousel-wrapper-out .carousel-card .carousel-card-image-wrapper .icon-wrapper {
      width: 44px;
      height: 44px;
      border-radius: 50%;
      background: rgba(25, 25, 25, 0.4);
      position: absolute;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%); }
      .chat-room-message-out-meta-carousel-wrapper .message-out-bubble-carousel-wrapper-out .carousel-card .carousel-card-image-wrapper .icon-wrapper svg {
        font-size: 24px;
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
        color: #fff; }
    .chat-room-message-out-meta-carousel-wrapper .message-out-bubble-carousel-wrapper-out .carousel-card p {
      margin: 0; }

.chat-room-message-out-meta-carousel-wrapper .message-out-bubble.deleted-bubble {
  color: #ffffff;
  padding: 10px 10px 10px 33px; }

.chat-room-message-out-meta-carousel-wrapper .message-out-bubble.deleted-bubble .deleted-icon {
  position: absolute;
  left: 10px;
  top: 50%;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%); }

.chat-room-message-out-meta-carousel-wrapper .message-out-bubble .forwarded-message {
  color: #ffffff;
  font-size: 12px;
  letter-spacing: -0.4px;
  line-height: 18px;
  display: block;
  margin-bottom: 5px; }

.chat-room-message-out-meta-carousel-wrapper .message-out-bubble .reply-message {
  width: calc(100% - 20px);
  border-radius: 4px;
  border-left: solid 5px #FF8F22;
  background: #E88422;
  color: #ffffff;
  position: absolute;
  top: 10px;
  left: 10px;
  padding: 5px 7px 5px 7px;
  line-height: 1; }

.chat-room-message-out-meta-carousel-wrapper .message-out-bubble .reply-message p {
  margin: 0;
  font-size: 12px;
  letter-spacing: -0.4px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 20px; }

.chat-room-message-out-meta-carousel-wrapper .message-out-bubble .reply-message p:nth-child(2) {
  letter-spacing: -0.2px; }

.chat-room-message-out-meta-carousel-wrapper .message-out-bubble .reply-message.reply-file .reply-file-thumbnail {
  display: inline-block;
  width: 40px;
  height: 40px;
  margin-right: 10px;
  position: relative; }

.chat-room-message-out-meta-carousel-wrapper .message-out-bubble .reply-message.reply-file .reply-file-thumbnail img {
  width: 40px;
  height: 40px; }

.chat-room-message-out-meta-carousel-wrapper .message-out-bubble .reply-message.reply-file .reply-file-thumbnail svg {
  position: absolute;
  left: 50%;
  top: 50%;
  font-size: 35px;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%); }

.chat-room-message-out-meta-carousel-wrapper .message-out-bubble .reply-message.reply-file .reply-text-wrapper {
  width: calc(100% - 50px);
  display: inline-block;
  vertical-align: top; }

.chat-room-message-out-meta-carousel-wrapper .message-out-bubble .message-info {
  /* position: absolute; */
  /* bottom: 11px; */
  /* right: 27px; */
  margin: 0;
  color: rgba(25, 25, 25, 0.4);
  letter-spacing: -0.4px;
  font-size: 10px;
  line-height: 18px;
  /* width: 24px; */
  width: 100%;
  white-space: nowrap;
  text-align: right; }
  .chat-room-message-out-meta-carousel-wrapper .message-out-bubble .message-info .centered-dot {
    width: 2px;
    height: 2px;
    display: inline-block;
    vertical-align: top;
    background: rgba(25, 25, 25, 0.4);
    border-radius: 50%;
    position: relative;
    top: 9px; }

.chat-room-message-out-meta-carousel-wrapper .message-out-bubble .group-sender-name-wrapper {
  color: #ff7d00;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
  font-size: 14px; }

.chat-room-message-out-meta-carousel-wrapper .message-out-bubble .group-sender-name-wrapper .agent-span {
  color: rgba(25, 25, 25, 0.4); }

.chat-room-message-out-meta-carousel-wrapper .message-out-bubble .message-info img {
  margin-left: 4px;
  width: 16px;
  position: relative;
  /* top: 4px; */ }

.message-action-wrapper {
  position: relative;
  position: absolute;
  top: 50%;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%);
  /* left: -170px; */
  /* left: -90px; */
  left: -90px;
  display: none; }

.message-action-wrapper.message-action-right {
  right: -170px;
  left: unset; }

.message-action-wrapper .forward-message svg {
  -webkit-transform: scaleX(-1);
          transform: scaleX(-1); }

.message-action-wrapper .message-action-button-wrapper {
  border-radius: 50%;
  width: 30px;
  height: 30px;
  text-align: center;
  position: relative;
  display: inline-block;
  background-attachment: fixed;
  margin-left: 10px; }

.message-action-wrapper .message-action-button-wrapper svg {
  color: #9b9b9b;
  position: absolute;
  font-size: 18px;
  left: 6px;
  top: 6px; }

.chat-room-message-out-meta-carousel-wrapper:hover .message-action-wrapper {
  display: inline-block; }

.chat-room-message-out-meta-carousel-wrapper:hover .message-action-dropdown {
  display: inline-block; }

.message-action-popup-wrapper {
  width: 172px;
  display: inline-block;
  box-shadow: 0px 0px 9px 0px rgba(25, 25, 25, 0.1);
  line-height: 1;
  position: absolute;
  right: 63px;
  top: 0;
  border-radius: 8px;
  z-index: 1; }

.message-action-popup-wrapper .message-action-content {
  width: 100%;
  position: relative;
  padding: 12px 12px 12px 35px;
  font-size: 15px;
  letter-spacing: -0.3px;
  color: #191919;
  border-bottom: solid 1px #dcdcdc;
  background: #ffffff;
  cursor: pointer; }

.message-action-popup-wrapper .message-action-content.delete-message {
  color: #ff3f57; }

.message-action-popup-wrapper .message-action-content.forward-message svg {
  -webkit-transform: rotate(180deg) translate(0, 50%);
          transform: rotate(180deg) translate(0, 50%); }

.message-action-popup-wrapper .message-action-content:hover {
  background: #ff7d00;
  color: #ffffff; }

.message-action-popup-wrapper .message-action-content:hover svg {
  color: #ffffff; }

.message-action-popup-wrapper .message-action-content:nth-child(1) {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px; }

.message-action-popup-wrapper .message-action-content:nth-last-child(1) {
  border: none;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px; }

.message-action-popup-wrapper svg {
  left: 12px;
  font-size: 16px;
  position: absolute;
  top: 50%;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%); }

.chat-room-message-out-meta-carousel-wrapper .message-action-wrapper {
  /* right: -120px !important; */
  left: -90px !important; }
  .chat-room-message-out-meta-carousel-wrapper .message-action-wrapper.with-forward {
    left: -128px !important; }
    .chat-room-message-out-meta-carousel-wrapper .message-action-wrapper.with-forward .forward-button {
      display: inline-block !important; }

.hide-reply-button .message-out-bubble .message-action-wrapper {
  left: -45px !important; }
  .hide-reply-button .message-out-bubble .message-action-wrapper.with-forward {
    left: -88px !important; }

.chat-room-message-out-wrapper {
  margin-top: 16px;
  padding: 0 24px;
  width: 100%;
  position: relative;
  text-align: right; }

.chat-room-message-out-wrapper .message-out-bubble-rating {
  border-radius: 16px 2px 16px 16px;
  background: #FFF2E5;
  border: 1px solid rgba(25, 25, 25, 0.1);
  display: inline-block;
  /* max-width: calc(100% - 180px); */
  max-width: calc(100% - 50px);
  /* padding: 10px 62px 10px 10px; */
  padding: 10px;
  color: #191919;
  font-size: 16px;
  letter-spacing: -0.3px;
  line-height: 24px;
  position: relative;
  text-align: left;
  min-height: 44px; }

.chat-room-message-out-wrapper .message-out-bubble-rating.deleted-bubble {
  color: #ffffff;
  padding: 10px 10px 10px 33px; }

.chat-room-message-out-wrapper .message-out-bubble-rating.deleted-bubble .deleted-icon {
  position: absolute;
  left: 10px;
  top: 50%;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%); }

.chat-room-message-out-wrapper .message-out-bubble-rating .forwarded-message {
  color: #ffffff;
  font-size: 12px;
  letter-spacing: -0.4px;
  line-height: 18px;
  display: block;
  margin-bottom: 5px; }

.chat-room-message-out-wrapper .message-out-bubble-rating .reply-message {
  width: calc(100% - 20px);
  border-radius: 4px;
  border-left: solid 5px #FF8F22;
  background: #E88422;
  color: #ffffff;
  position: absolute;
  top: 10px;
  left: 10px;
  padding: 5px 7px 5px 7px;
  line-height: 1; }

.chat-room-message-out-wrapper .message-out-bubble-rating .reply-message p {
  margin: 0;
  font-size: 12px;
  letter-spacing: -0.4px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 20px; }

.chat-room-message-out-wrapper .message-out-bubble-rating .reply-message p:nth-child(2) {
  letter-spacing: -0.2px; }

.chat-room-message-out-wrapper .message-out-bubble-rating .reply-message.reply-file .reply-file-thumbnail {
  display: inline-block;
  width: 40px;
  height: 40px;
  margin-right: 10px;
  position: relative; }

.chat-room-message-out-wrapper .message-out-bubble-rating .reply-message.reply-file .reply-file-thumbnail img {
  width: 40px;
  height: 40px; }

.chat-room-message-out-wrapper .message-out-bubble-rating .reply-message.reply-file .reply-file-thumbnail svg {
  position: absolute;
  left: 50%;
  top: 50%;
  font-size: 35px;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%); }

.chat-room-message-out-wrapper .message-out-bubble-rating .reply-message.reply-file .reply-text-wrapper {
  width: calc(100% - 50px);
  display: inline-block;
  vertical-align: top; }

.chat-room-message-out-wrapper .message-out-bubble-rating .message-info {
  /* position: absolute; */
  /* bottom: 11px; */
  /* right: 27px; */
  margin: 0;
  color: rgba(25, 25, 25, 0.4);
  letter-spacing: -0.4px;
  font-size: 10px;
  line-height: 18px;
  /* width: 24px; */
  width: 100%;
  white-space: nowrap;
  text-align: right; }
  .chat-room-message-out-wrapper .message-out-bubble-rating .message-info .centered-dot {
    width: 2px;
    height: 2px;
    display: inline-block;
    vertical-align: top;
    background: rgba(25, 25, 25, 0.4);
    border-radius: 50%;
    position: relative;
    top: 9px; }

.chat-room-message-out-wrapper .message-out-bubble-rating .group-sender-name-wrapper {
  color: #ff7d00;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0 0 4px 0;
  font-size: 14px; }

.chat-room-message-out-wrapper .message-out-bubble-rating .group-sender-name-wrapper .agent-span {
  color: rgba(25, 25, 25, 0.4); }

.chat-room-message-out-wrapper .message-out-bubble-rating .message-info img {
  margin-left: 4px;
  width: 16px;
  position: relative;
  /* top: 4px; */ }

.message-action-wrapper {
  position: relative;
  position: absolute;
  top: 50%;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%);
  /* left: -170px; */
  /* left: -90px; */
  left: -90px;
  display: none; }

.message-action-wrapper.message-action-right {
  right: -170px;
  left: unset; }

.message-action-wrapper .forward-message svg {
  -webkit-transform: scaleX(-1);
          transform: scaleX(-1); }

.message-action-wrapper .message-action-button-wrapper {
  border-radius: 50%;
  width: 30px;
  height: 30px;
  text-align: center;
  position: relative;
  display: inline-block;
  background-attachment: fixed;
  margin-left: 10px; }

.message-action-wrapper .message-action-button-wrapper svg {
  color: #9b9b9b;
  position: absolute;
  font-size: 18px;
  left: 6px;
  top: 6px; }

.chat-room-message-out-wrapper:hover .message-action-wrapper {
  display: inline-block; }

.chat-room-message-out-wrapper:hover .message-action-dropdown {
  display: inline-block; }

.message-action-popup-wrapper {
  width: 172px;
  display: inline-block;
  box-shadow: 0px 0px 9px 0px rgba(25, 25, 25, 0.1);
  line-height: 1;
  position: absolute;
  right: 63px;
  top: 0;
  border-radius: 8px;
  z-index: 1; }

.message-action-popup-wrapper .message-action-content {
  width: 100%;
  position: relative;
  padding: 12px 12px 12px 35px;
  font-size: 15px;
  letter-spacing: -0.3px;
  color: #191919;
  border-bottom: solid 1px #dcdcdc;
  background: #ffffff;
  cursor: pointer; }

.message-action-popup-wrapper .message-action-content.delete-message {
  color: #ff3f57; }

.message-action-popup-wrapper .message-action-content.forward-message svg {
  -webkit-transform: rotate(180deg) translate(0, 50%);
          transform: rotate(180deg) translate(0, 50%); }

.message-action-popup-wrapper .message-action-content:hover {
  background: #ff7d00;
  color: #ffffff; }

.message-action-popup-wrapper .message-action-content:hover svg {
  color: #ffffff; }

.message-action-popup-wrapper .message-action-content:nth-child(1) {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px; }

.message-action-popup-wrapper .message-action-content:nth-last-child(1) {
  border: none;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px; }

.message-action-popup-wrapper svg {
  left: 12px;
  font-size: 16px;
  position: absolute;
  top: 50%;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%); }

.chat-room-message-out-wrapper .message-action-wrapper {
  /* right: -120px !important; */
  left: -90px !important; }

.hide-reply-button .message-out-bubble-rating .message-action-wrapper {
  left: -45px !important; }
  .hide-reply-button .message-out-bubble-rating .message-action-wrapper.with-forward {
    left: -128px !important; }

.chat-room-message-file-out-wrapper {
  margin-top: 16px;
  padding: 0 24px;
  width: 100%;
  position: relative;
  text-align: right; }

.chat-room-message-file-out-wrapper .message-out-bubble-file {
  border-radius: 16px 2px 16px 16px;
  background: #FFF2E5;
  border: 1px solid rgba(25, 25, 25, 0.1);
  display: inline-block;
  max-width: calc(100% - 75px);
  padding: 10px;
  color: #191919;
  font-size: 16px;
  letter-spacing: -0.3px;
  line-height: 24px;
  position: relative;
  color: #191919;
  text-align: left;
  min-height: 69px; }
  .chat-room-message-file-out-wrapper .message-out-bubble-file .click-area-file {
    cursor: pointer; }

.chat-room-message-file-out-wrapper .message-out-bubble-file .file-size-and-extension {
  padding: 0 3px 0 0;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  letter-spacing: -0.2px;
  line-height: 1;
  white-space: nowrap; }

.chat-room-message-file-out-wrapper .message-out-bubble-file .file-size-and-extension-download-progress {
  min-width: 155px;
  max-width: 100%; }

.chat-room-message-file-out-wrapper .message-out-bubble-file .reply-message {
  width: calc(100% - 20px);
  border-radius: 4px;
  border-left: solid 5px #FF8F22;
  background: #E88422;
  color: #ffffff;
  position: absolute;
  top: 10px;
  left: 10px;
  padding: 5px 7px 5px 7px;
  line-height: 1; }

.chat-room-message-file-out-wrapper .message-out-bubble-file .reply-message p {
  margin: 0;
  font-size: 12px;
  letter-spacing: -0.4px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 20px; }

.chat-room-message-file-out-wrapper .message-out-bubble-file .reply-message p:nth-child(2) {
  letter-spacing: -0.2px; }

.chat-room-message-file-out-wrapper .message-out-bubble-file .reply-message.reply-file .reply-file-thumbnail {
  display: inline-block;
  width: 40px;
  height: 40px;
  margin-right: 10px;
  position: relative; }

.chat-room-message-file-out-wrapper .message-out-bubble-file .reply-message.reply-file .reply-file-thumbnail img {
  width: 40px;
  height: 40px; }

.chat-room-message-file-out-wrapper .message-out-bubble-file .reply-message.reply-file .reply-file-thumbnail svg {
  position: absolute;
  left: 50%;
  top: 50%;
  font-size: 35px;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%); }

.chat-room-message-file-out-wrapper .message-out-bubble-file .reply-message.reply-file .reply-text-wrapper {
  width: calc(100% - 50px);
  display: inline-block;
  vertical-align: top; }

.chat-room-message-file-out-wrapper .message-out-bubble-file .replied-file-message {
  width: calc(100% - 20px);
  position: absolute;
  top: 10px;
  left: 10px;
  background: #E87303;
  border-radius: 16px 2px 16px 16px;
  color: #ffffff;
  padding: 10px; }

.chat-room-message-file-out-wrapper .message-out-bubble-file .file-inner-wrapper {
  position: relative;
  padding-left: 56px;
  padding-top: 3px; }

.chat-room-message-file-out-wrapper .message-out-bubble-file .caption-text {
  font-size: 16px;
  letter-spacing: -.3px;
  padding: 4px 10px 0 0;
  margin: 0;
  word-break: break-word; }

.chat-room-message-file-out-wrapper .message-out-bubble-file .file-icon-wrapper {
  height: 32px;
  width: 32px;
  display: inline-block;
  background: var(--main-dark-orange);
  border-radius: 50%;
  vertical-align: top;
  position: relative; }

.chat-room-message-file-out-wrapper .message-out-bubble-file .file-icon-wrapper svg {
  font-size: 16px;
  color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%); }

.chat-room-message-file-out-wrapper .message-out-bubble-file .forwarded-message {
  color: #ffffff;
  font-size: 12px;
  letter-spacing: -0.4px;
  line-height: 18px;
  display: block;
  margin-bottom: 5px; }

.chat-room-message-file-out-wrapper .message-out-bubble-file .message-info {
  margin: 0;
  color: rgba(25, 25, 25, 0.4);
  font-size: 10px;
  letter-spacing: -0.4px;
  line-height: 18px;
  white-space: nowrap;
  width: 100%;
  text-align: right; }
  .chat-room-message-file-out-wrapper .message-out-bubble-file .message-info .centered-dot {
    width: 2px;
    height: 2px;
    display: inline-block;
    vertical-align: top;
    background: rgba(25, 25, 25, 0.4);
    border-radius: 50%;
    position: relative;
    top: 9px; }

.chat-room-message-file-out-wrapper .message-out-bubble-file .group-sender-name-wrapper {
  color: #ff7d00;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0 0 4px 0;
  font-size: 14px; }

.chat-room-message-file-out-wrapper .message-out-bubble-file .group-sender-name-wrapper .agent-span {
  color: rgba(25, 25, 25, 0.4); }

.chat-room-message-file-out-wrapper .message-out-bubble-file .message-info img {
  margin-left: 4px;
  width: 16px;
  position: relative;
  /* top: 4px; */ }

.chat-room-message-file-out-wrapper .message-out-bubble-file .message-bubble-file-wrapper {
  width: 100%;
  display: inline-block; }

.chat-room-message-file-out-wrapper .message-out-bubble-file .file-icon-wrapper {
  height: 46px;
  width: 46px;
  display: inline-block;
  background: var(--main-dark-orange);
  border-radius: 50%;
  vertical-align: top;
  position: absolute;
  left: 0;
  top: 0; }

.chat-room-message-file-out-wrapper .message-out-bubble-file .file-icon-wrapper svg {
  font-size: 25px;
  color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%); }

.chat-room-message-file-out-wrapper .message-out-bubble-file .file-icon-wrapper .onprocess-wrapper {
  height: 40px;
  width: 40px;
  display: inline-block;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%); }

.chat-room-message-file-out-wrapper .message-out-bubble-file .message-bubble-file-wrapper p {
  max-width: 100%;
  line-height: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
  display: inline-block;
  white-space: nowrap;
  font-size: 14px;
  letter-spacing: -.2px; }

.chat-room-message-file-out-wrapper .failed-sending-file-warning {
  font-size: 12px;
  letter-spacing: -0.4px;
  color: rgba(25, 25, 25, 0.4); }

.chat-room-message-file-out-wrapper:hover .message-action-wrapper {
  display: inline-block;
  left: -50px; }

.chat-room-message-file-out-wrapper:hover .message-action-dropdown {
  display: inline-block; }

.chat-room-message-file-out-wrapper .message-action-wrapper.with-forward {
  left: -88px !important; }
  .chat-room-message-file-out-wrapper .message-action-wrapper.with-forward .forward-button {
    display: inline-block !important; }

.chat-room-message-file-out-wrapper .CircularProgressbar .CircularProgressbar-trail {
  stroke: #ffffff; }

.chat-room-message-file-out-wrapper .CircularProgressbar .CircularProgressbar-path {
  stroke: #ff7d00; }

.hide-reply-button .message-out-bubble-file .message-action-wrapper {
  left: -45px !important; }
  .hide-reply-button .message-out-bubble-file .message-action-wrapper.with-forward {
    left: -48px !important; }

.chat-room-message-image-out-wrapper {
  width: 100%;
  padding: 0 24px;
  text-align: right;
  position: relative;
  margin-top: 16px;
  font-size: 0; }

.chat-room-message-image-out-wrapper .message-out-bubble-image {
  border-radius: 16px 2px 16px 16px;
  max-width: 300px;
  display: inline-block;
  position: relative;
  font-size: 0;
  border: 1px solid rgba(25, 25, 25, 0.1);
  background: #FFF2E5;
  border: 1px solid rgba(25, 25, 25, 0.1);
  text-align: left;
  color: #191919; }

.chat-room-message-image-out-wrapper .message-out-bubble-image .bubble-image-wrapper {
  min-width: 150px;
  padding: 10px; }
  .chat-room-message-image-out-wrapper .message-out-bubble-image .bubble-image-wrapper.bubble-wrapper-with-caption {
    padding: 10px 10px 0; }
  .chat-room-message-image-out-wrapper .message-out-bubble-image .bubble-image-wrapper img {
    border-radius: 16px 0 16px 16px; }

.chat-room-message-image-out-wrapper .message-out-bubble-image .bubble-image-wrapper img {
  vertical-align: top;
  width: 100%;
  max-width: 100%; }

.chat-room-message-image-out-wrapper .message-out-bubble-image .bubble-image-wrapper .icon-status-wrapper {
  border-radius: 50%;
  position: absolute;
  width: 48px;
  height: 48px;
  display: inline-block;
  background: rgba(25, 25, 25, 0.4);
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  cursor: pointer; }

.chat-room-message-image-out-wrapper .message-out-bubble-image .bubble-image-wrapper .icon-status-wrapper svg {
  color: #ffffff;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  font-size: 20px; }

.chat-room-message-image-out-wrapper .message-out-bubble-image .bubble-image-wrapper .icon-status-wrapper .onprocess-wrapper {
  height: 40px;
  width: 40px;
  display: inline-block;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%); }

.chat-room-message-image-out-wrapper .message-out-bubble-image .main-image-message {
  width: 100%;
  max-width: 100%; }

.chat-room-message-image-out-wrapper .message-out-bubble-image .thumbnail-image-message {
  width: 300px;
  min-width: 278px; }

.modal-image.modal-dialog {
  width: 100vw !important; }

.modal-image .modal-content {
  width: 100%;
  height: 100%;
  background: none; }

.modal-image .modal-content .modal-body {
  width: 100%;
  height: 100%;
  background: rgba(25, 25, 25, 0.8); }

.modal-image .modal-content .modal-body img.image-preview-val {
  max-width: calc(100vw - 130px);
  max-height: calc(100vh - 130px);
  background: #ffffff; }

.modal-image .modal-content .modal-body .image-wrapper {
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  text-align: center; }
  .modal-image .modal-content .modal-body .image-wrapper figure {
    margin: 0; }
    .modal-image .modal-content .modal-body .image-wrapper figure.zoom {
      background-size: 180%;
      background-position: -100% -100%;
      background-repeat: no-repeat;
      position: relative;
      width: 100%;
      overflow: hidden;
      cursor: -webkit-zoom-in;
      cursor: zoom-in; }
      .modal-image .modal-content .modal-body .image-wrapper figure.zoom img {
        -webkit-transition: opacity 0.5s;
        transition: opacity 0.5s;
        display: inline-block; }
        .modal-image .modal-content .modal-body .image-wrapper figure.zoom img:hover {
          opacity: 0; }

.modal-image .modal-content .modal-body .close-modal-image {
  position: absolute;
  top: 20px;
  right: 20px;
  color: #ffffff;
  font-size: 40px;
  cursor: pointer;
  -webkit-transition: ease-in-out 0.1s;
  transition: ease-in-out 0.1s; }

.modal-image .modal-content .modal-body .close-modal-image:hover {
  color: #c9c9c9; }

.chat-room-message-image-out-wrapper .message-out-bubble-image .caption-text {
  font-size: 16px;
  letter-spacing: -.3px;
  padding: 4px 10px 0 10px;
  margin: 0;
  word-break: break-word; }

.chat-room-message-image-out-wrapper .message-out-bubble-image .reply-message {
  width: calc(100% - 20px);
  border-radius: 4px;
  border-left: solid 5px #FF8F22;
  background: #E88422;
  color: #191919;
  position: absolute;
  top: 10px;
  left: 10px;
  padding: 5px 7px 5px 7px;
  line-height: 1;
  z-index: 1;
  color: #ffffff; }

.chat-room-message-image-out-wrapper .message-out-bubble-image .reply-message p {
  margin: 0;
  font-size: 12px;
  letter-spacing: -0.4px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 20px; }

.chat-room-message-image-out-wrapper .message-out-bubble-image .reply-message p:nth-child(2) {
  letter-spacing: -0.2px; }

.chat-room-message-image-out-wrapper .message-out-bubble-image .reply-message.reply-file .reply-file-thumbnail {
  display: inline-block;
  width: 40px;
  height: 40px;
  margin-right: 10px;
  position: relative; }

.chat-room-message-image-out-wrapper .message-out-bubble-image .reply-message.reply-file .reply-file-thumbnail img {
  width: 40px;
  height: 40px; }

.chat-room-message-image-out-wrapper .message-out-bubble-image .reply-message.reply-file .reply-file-thumbnail svg {
  position: absolute;
  left: 50%;
  top: 50%;
  font-size: 35px;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%); }

.chat-room-message-image-out-wrapper .message-out-bubble-image .reply-message.reply-file .reply-text-wrapper {
  width: calc(100% - 50px);
  display: inline-block;
  vertical-align: top; }

.chat-room-message-image-out-wrapper .message-out-bubble-image .message-info {
  position: relative;
  margin: 0;
  white-space: nowrap;
  border-radius: 10px;
  font-size: 10px;
  letter-spacing: -0.4px;
  color: rgba(25, 25, 25, 0.4);
  width: 100%;
  text-align: right;
  padding: 2px 10px 10px; }
  .chat-room-message-image-out-wrapper .message-out-bubble-image .message-info .centered-dot {
    width: 2px;
    height: 2px;
    display: inline-block;
    vertical-align: top;
    background: rgba(25, 25, 25, 0.4);
    border-radius: 50%;
    position: relative;
    top: 9px; }

.chat-room-message-image-out-wrapper .message-out-bubble-image .group-sender-name-wrapper {
  color: #ff7d00;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0 0 4px 0;
  font-size: 14px; }

.chat-room-message-image-out-wrapper .message-out-bubble-image .group-sender-name-wrapper .agent-span {
  color: rgba(25, 25, 25, 0.4); }

.chat-room-message-image-out-wrapper .message-out-bubble-image .message-info-dark {
  background: rgba(25, 25, 25, 0.6);
  padding: 2px 5px;
  color: #ffffff;
  bottom: 16px;
  right: 16px;
  position: absolute;
  width: unset; }

.chat-room-message-image-out-wrapper .message-out-bubble-image .message-info img {
  /* vertical-align: top; */
  margin-left: 3px; }

.chat-room-message-image-out-wrapper .failed-sending-file-warning {
  font-size: 12px;
  letter-spacing: -0.4px;
  color: rgba(25, 25, 25, 0.4); }

.chat-room-message-image-out-wrapper:hover .message-action-wrapper {
  display: inline-block; }

.chat-room-message-image-out-wrapper:hover .message-action-dropdown {
  display: inline-block; }

.chat-room-message-image-out-wrapper .message-action-wrapper {
  left: -45px; }
  .chat-room-message-image-out-wrapper .message-action-wrapper.with-forward {
    left: -88px !important; }
    .chat-room-message-image-out-wrapper .message-action-wrapper.with-forward .forward-button {
      display: inline-block !important; }

.chat-room-message-image-out-wrapper .message-action-wrapper .message-action-button-wrapper {
  cursor: pointer; }

.chat-room-message-image-out-wrapper .CircularProgressbar .CircularProgressbar-trail {
  stroke: transparent; }

.chat-room-message-image-out-wrapper .CircularProgressbar .CircularProgressbar-path {
  stroke: #ffffff; }

.chat-room-message-image-in-wrapper .image-from-url {
  /* max-width: 234px; */
  /* max-height: 300px; */ }

.hide-reply-button .message-out-bubble-image .message-action-wrapper {
  left: -45px !important; }
  .hide-reply-button .message-out-bubble-image .message-action-wrapper.with-forward {
    left: -48px !important; }

.chat-room-message-video-out-wrapper {
  width: 100%;
  padding: 0 24px;
  text-align: right;
  position: relative;
  margin-top: 16px;
  font-size: 0; }

.chat-room-message-video-out-wrapper .message-out-bubble-video {
  border-radius: 16px 2px 16px 16px;
  max-width: 300px;
  display: inline-block;
  position: relative;
  font-size: 0;
  background: #FFF2E5;
  border: 1px solid rgba(25, 25, 25, 0.1);
  text-align: left;
  color: #191919; }

.chat-room-message-video-out-wrapper .message-out-bubble-video .bubble-image-wrapper {
  min-width: 150px;
  padding: 10px; }
  .chat-room-message-video-out-wrapper .message-out-bubble-video .bubble-image-wrapper.bubble-wrapper-with-caption {
    padding: 10px 10px 0; }
  .chat-room-message-video-out-wrapper .message-out-bubble-video .bubble-image-wrapper .video-inner-wrapper {
    position: relative; }
  .chat-room-message-video-out-wrapper .message-out-bubble-video .bubble-image-wrapper video {
    border-radius: 16px 0 16px 16px; }
  .chat-room-message-video-out-wrapper .message-out-bubble-video .bubble-image-wrapper img {
    border-radius: 16px 0 16px 16px; }

.chat-room-message-video-out-wrapper .message-out-bubble-video .bubble-image-wrapper .icon-status-wrapper {
  border-radius: 50%;
  position: absolute;
  width: 48px;
  height: 48px;
  display: inline-block;
  background: rgba(25, 25, 25, 0.4);
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  cursor: pointer; }

.chat-room-message-video-out-wrapper .message-out-bubble-video .bubble-image-wrapper .icon-status-wrapper svg {
  color: #ffffff;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  font-size: 20px; }

.chat-room-message-video-out-wrapper .message-out-bubble-video .bubble-image-wrapper .icon-status-wrapper .onprocess-wrapper {
  height: 40px;
  width: 40px;
  display: inline-block;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%); }

.chat-room-message-video-out-wrapper .message-out-bubble-video .bubble-image-wrapper .video-thumbnail {
  width: 100%; }

.chat-room-message-video-out-wrapper .message-out-bubble-video .main-image-message {
  width: 100%;
  min-width: 278px; }

.chat-room-message-video-out-wrapper .message-out-bubble-video .caption-text {
  font-size: 16px;
  letter-spacing: -.3px;
  padding: 4px 10px 0 10px;
  margin: 0;
  word-break: break-word; }

.chat-room-message-video-out-wrapper .message-out-bubble-video .reply-message {
  width: calc(100% - 20px);
  border-radius: 4px;
  border-left: solid 5px #FF8F22;
  background: #E88422;
  color: #191919;
  position: absolute;
  top: 10px;
  left: 10px;
  padding: 5px 7px 5px 7px;
  line-height: 1;
  z-index: 1;
  color: #ffffff; }

.chat-room-message-video-out-wrapper .message-out-bubble-video .reply-message p {
  margin: 0;
  font-size: 12px;
  letter-spacing: -0.4px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 20px; }

.chat-room-message-video-out-wrapper .message-out-bubble-video .reply-message p:nth-child(2) {
  letter-spacing: -0.2px; }

.chat-room-message-video-out-wrapper .message-out-bubble-video .reply-message.reply-file .reply-file-thumbnail {
  display: inline-block;
  width: 40px;
  height: 40px;
  margin-right: 10px;
  position: relative; }

.chat-room-message-video-out-wrapper .message-out-bubble-video .reply-message.reply-file .reply-file-thumbnail img {
  width: 40px;
  height: 40px; }

.chat-room-message-video-out-wrapper .message-out-bubble-video .reply-message.reply-file .reply-file-thumbnail svg {
  position: absolute;
  left: 50%;
  top: 50%;
  font-size: 35px;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%); }

.chat-room-message-video-out-wrapper .message-out-bubble-video .reply-message.reply-file .reply-text-wrapper {
  width: calc(100% - 50px);
  display: inline-block;
  vertical-align: top; }

.chat-room-message-video-out-wrapper .message-out-bubble-video .message-info {
  position: relative;
  margin: 0;
  white-space: nowrap;
  border-radius: 10px;
  font-size: 10px;
  letter-spacing: -0.4px;
  color: rgba(25, 25, 25, 0.4);
  width: 100%;
  text-align: right;
  padding: 2px 10px 10px; }
  .chat-room-message-video-out-wrapper .message-out-bubble-video .message-info .centered-dot {
    width: 2px;
    height: 2px;
    display: inline-block;
    vertical-align: top;
    background: rgba(25, 25, 25, 0.4);
    border-radius: 50%;
    position: relative;
    top: 9px; }

.chat-room-message-video-out-wrapper .message-out-bubble-video .group-sender-name-wrapper {
  color: #ff7d00;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0 0 4px 0;
  font-size: 14px; }

.chat-room-message-video-out-wrapper .message-out-bubble-video .group-sender-name-wrapper .agent-span {
  color: rgba(25, 25, 25, 0.4); }

.chat-room-message-video-out-wrapper .message-out-bubble-video .message-info-dark {
  background: rgba(25, 25, 25, 0.6);
  padding: 2px 5px;
  color: #ffffff;
  bottom: 16px;
  right: 16px;
  position: absolute;
  width: unset; }

.chat-room-message-video-out-wrapper .message-out-bubble-video .message-info img {
  /* vertical-align: top; */
  margin-left: 3px; }

.chat-room-message-video-out-wrapper .message-out-bubble-video .timer-filesize-wrapper {
  background: rgba(25, 25, 25, 0.6);
  position: absolute;
  white-space: nowrap;
  border-radius: 10px;
  font-size: 12px;
  padding: 2px 5px;
  color: rgba(255, 255, 255, 0.6);
  top: 10px;
  left: 10px;
  z-index: 1; }

.chat-room-message-video-out-wrapper .message-out-bubble-video .play-button-wraper {
  border-radius: 50%;
  position: absolute;
  width: 48px;
  height: 48px;
  display: inline-block;
  background: rgba(25, 25, 25, 0.4);
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  cursor: pointer; }

.chat-room-message-video-out-wrapper .message-out-bubble-video .play-button-wraper svg {
  color: #ffffff;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  font-size: 20px; }

.chat-room-message-video-out-wrapper:hover .message-action-wrapper {
  display: inline-block; }

.chat-room-message-video-out-wrapper:hover .message-action-dropdown {
  display: inline-block; }

.chat-room-message-video-out-wrapper .message-action-wrapper {
  left: -45px; }
  .chat-room-message-video-out-wrapper .message-action-wrapper.with-forward {
    left: -88px !important; }
    .chat-room-message-video-out-wrapper .message-action-wrapper.with-forward .forward-button {
      display: inline-block !important; }

.chat-room-message-video-out-wrapper .message-action-wrapper .message-action-button-wrapper {
  cursor: pointer; }

.chat-room-message-video-out-wrapper .CircularProgressbar .CircularProgressbar-trail {
  stroke: transparent; }

.chat-room-message-video-out-wrapper .CircularProgressbar .CircularProgressbar-path {
  stroke: #ffffff; }

.modal-video.modal-dialog {
  width: 100vw !important; }

.modal-video .modal-content {
  width: 100%;
  height: 100%;
  background: none; }

.modal-video .modal-content .modal-body {
  width: 100%;
  height: 100%;
  background: rgba(25, 25, 25, 0.8); }

.modal-video .modal-content .modal-body .video-wrapper {
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%); }

.modal-video .modal-content .modal-body .close-modal-video {
  position: absolute;
  top: 20px;
  right: 20px;
  color: #ffffff;
  font-size: 40px;
  cursor: pointer;
  -webkit-transition: ease-in-out 0.1s;
  transition: ease-in-out 0.1s; }

.modal-video .modal-content .modal-body .close-modal-video:hover {
  color: #c9c9c9; }

.modal-video .modal-content .modal-body video {
  max-height: calc(100vh - 90px);
  max-width: calc(100vw - 90px); }

.hide-reply-button .message-out-bubble-video .message-action-wrapper {
  left: -45px !important; }
  .hide-reply-button .message-out-bubble-video .message-action-wrapper.with-forward {
    left: -48px !important; }

.chat-room-message-out-wrapper {
  margin-top: 16px;
  padding: 0 24px;
  width: 100%;
  position: relative;
  text-align: right; }

.chat-room-message-out-wrapper .message-out-bubble {
  border-radius: 16px 2px 16px 16px;
  border: 1px solid rgba(25, 25, 25, 0.1);
  background: #FFF2E5;
  display: inline-block;
  /* max-width: calc(100% - 180px); */
  max-width: calc(100% - 50px);
  /* padding: 10px 62px 10px 10px; */
  padding: 10px;
  color: #191919;
  font-size: 16px;
  letter-spacing: -0.3px;
  line-height: 24px;
  position: relative;
  text-align: left;
  min-height: 44px; }

.chat-room-message-out-wrapper .message-out-bubble.deleted-bubble {
  color: #ffffff;
  padding: 10px 10px 10px 33px; }

.chat-room-message-out-wrapper .message-out-bubble.deleted-bubble .deleted-icon {
  position: absolute;
  left: 10px;
  top: 50%;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%); }

.chat-room-message-out-wrapper .message-out-bubble .forwarded-message {
  color: #ffffff;
  font-size: 12px;
  letter-spacing: -0.4px;
  line-height: 18px;
  display: block;
  margin-bottom: 5px; }

.chat-room-message-out-wrapper .message-out-bubble .reply-message {
  width: calc(100% - 20px);
  border-radius: 4px;
  border-left: solid 5px #FF8F22;
  background: #E88422;
  color: #ffffff;
  position: absolute;
  top: 10px;
  left: 10px;
  padding: 5px 7px 5px 7px;
  line-height: 1; }

.chat-room-message-out-wrapper .message-out-bubble .reply-message p {
  margin: 0;
  font-size: 12px;
  letter-spacing: -0.4px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 20px; }

.chat-room-message-out-wrapper .message-out-bubble .reply-message p:nth-child(2) {
  letter-spacing: -0.2px; }

.chat-room-message-out-wrapper .message-out-bubble .reply-message.reply-file .reply-file-thumbnail {
  display: inline-block;
  width: 40px;
  height: 40px;
  margin-right: 10px;
  position: relative; }

.chat-room-message-out-wrapper .message-out-bubble .reply-message.reply-file .reply-file-thumbnail img {
  width: 40px;
  height: 40px; }

.chat-room-message-out-wrapper .message-out-bubble .reply-message.reply-file .reply-file-thumbnail svg {
  position: absolute;
  left: 50%;
  top: 50%;
  font-size: 35px;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%); }

.chat-room-message-out-wrapper .message-out-bubble .reply-message.reply-file .reply-text-wrapper {
  width: calc(100% - 50px);
  display: inline-block;
  vertical-align: top; }

.chat-room-message-out-wrapper .message-out-bubble .message-info {
  /* position: absolute; */
  /* bottom: 11px; */
  /* right: 27px; */
  margin: 0;
  color: rgba(25, 25, 25, 0.4);
  letter-spacing: -0.4px;
  font-size: 10px;
  line-height: 18px;
  /* width: 24px; */
  width: 100%;
  white-space: nowrap;
  text-align: right; }
  .chat-room-message-out-wrapper .message-out-bubble .message-info .centered-dot {
    width: 2px;
    height: 2px;
    display: inline-block;
    vertical-align: top;
    background: rgba(25, 25, 25, 0.4);
    border-radius: 50%;
    position: relative;
    top: 9px; }

.chat-room-message-out-wrapper .message-out-bubble .group-sender-name-wrapper {
  color: #ff7d00;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
  font-size: 14px; }

.chat-room-message-out-wrapper .message-out-bubble .group-sender-name-wrapper .agent-span {
  color: rgba(25, 25, 25, 0.4); }

.chat-room-message-out-wrapper .message-out-bubble .message-info img {
  margin-left: 4px;
  width: 16px;
  position: relative;
  /* top: 4px; */ }

.message-action-wrapper {
  position: relative;
  position: absolute;
  top: 50%;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%);
  /* left: -170px; */
  /* left: -90px; */
  left: -90px;
  display: none; }

.message-action-wrapper.message-action-right {
  right: -170px;
  left: unset; }

.message-action-wrapper .forward-message svg {
  -webkit-transform: scaleX(-1);
          transform: scaleX(-1); }

.message-action-wrapper .message-action-button-wrapper {
  border-radius: 50%;
  width: 30px;
  height: 30px;
  text-align: center;
  position: relative;
  display: inline-block;
  background-attachment: fixed;
  margin-left: 10px; }

.message-action-wrapper .message-action-button-wrapper svg {
  color: #9b9b9b;
  position: absolute;
  font-size: 18px;
  left: 6px;
  top: 6px; }

.chat-room-message-out-wrapper:hover .message-action-wrapper {
  display: inline-block; }

.chat-room-message-out-wrapper:hover .message-action-dropdown {
  display: inline-block; }

.message-action-popup-wrapper {
  width: 172px;
  display: inline-block;
  box-shadow: 0px 0px 9px 0px rgba(25, 25, 25, 0.1);
  line-height: 1;
  position: absolute;
  right: 63px;
  top: 0;
  border-radius: 8px;
  z-index: 1; }

.message-action-popup-wrapper .message-action-content {
  width: 100%;
  position: relative;
  padding: 12px 12px 12px 35px;
  font-size: 15px;
  letter-spacing: -0.3px;
  color: #191919;
  border-bottom: solid 1px #dcdcdc;
  background: #ffffff;
  cursor: pointer; }

.message-action-popup-wrapper .message-action-content.delete-message {
  color: #ff3f57; }

.message-action-popup-wrapper .message-action-content.forward-message svg {
  -webkit-transform: rotate(180deg) translate(0, 50%);
          transform: rotate(180deg) translate(0, 50%); }

.message-action-popup-wrapper .message-action-content:hover {
  background: #ff7d00;
  color: #ffffff; }

.message-action-popup-wrapper .message-action-content:hover svg {
  color: #ffffff; }

.message-action-popup-wrapper .message-action-content:nth-child(1) {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px; }

.message-action-popup-wrapper .message-action-content:nth-last-child(1) {
  border: none;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px; }

.message-action-popup-wrapper svg {
  left: 12px;
  font-size: 16px;
  position: absolute;
  top: 50%;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%); }

.chat-room-message-out-wrapper .message-action-wrapper {
  /* right: -120px !important; */
  left: -90px !important; }
  .chat-room-message-out-wrapper .message-action-wrapper.with-forward {
    left: -128px !important; }
    .chat-room-message-out-wrapper .message-action-wrapper.with-forward .forward-button {
      display: inline-block !important; }

.hide-reply-button .message-out-bubble .message-action-wrapper {
  left: -45px !important; }
  .hide-reply-button .message-out-bubble .message-action-wrapper.with-forward {
    left: -88px !important; }

.message-out-template-banner {
  background: rgba(25, 25, 25, 0.05);
  border-radius: 6px;
  margin: 2px 0px;
  color: var(--main-dark-orange);
  text-align: center;
  padding: 5px 16px;
  display: flex;
  justify-content: center;
  font-weight: 700; }
  .message-out-template-banner svg {
    margin-right: 5px;
    align-self: center;
    color: var(--main-dark-orange);
    font-size: 16px;
    position: relative;
    top: -1px; }
  .message-out-template-banner p {
    font-size: 14px;
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    /* margin: 0; */
    display: inline-block;
    white-space: nowrap;
    vertical-align: -7px; }

.chat-room-message-out-template-image-wrapper {
  width: 100%;
  text-align: right;
  position: relative;
  margin-top: 16px;
  padding: 0 24px;
  font-size: 0; }
  .chat-room-message-out-template-image-wrapper .message-action-wrapper {
    left: -45px; }

.chat-room-message-out-template-image-wrapper .message-out-bubble {
  border-radius: 16px 2px 16px 16px;
  max-width: 300px;
  display: inline-block;
  position: relative;
  font-size: 0;
  background: #FFF2E5;
  border: 1px solid rgba(25, 25, 25, 0.1);
  text-align: left;
  color: #191919;
  min-height: 80px; }

.chat-room-message-out-template-image-wrapper
.message-out-bubble.deleted-bubble {
  color: #ffffff;
  padding: 10px 10px 10px 33px; }

.chat-room-message-out-template-image-wrapper
.message-out-bubble.deleted-bubble
.deleted-icon {
  position: absolute;
  left: 10px;
  top: 50%;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%); }

.chat-room-message-out-template-image-wrapper
.message-out-bubble
.forwarded-message {
  color: #ffffff;
  font-size: 12px;
  letter-spacing: -0.4px;
  line-height: 18px;
  display: block;
  margin-bottom: 5px; }

.chat-room-message-out-template-image-wrapper
.message-out-bubble
.reply-message {
  width: calc(100% - 20px);
  border-radius: 4px;
  border-left: solid 5px #ff8f22;
  background: #e88422;
  color: #ffffff;
  position: absolute;
  top: 10px;
  left: 10px;
  padding: 5px 7px 5px 7px;
  line-height: 1; }

.chat-room-message-out-template-image-wrapper
.message-out-bubble
.reply-message
p {
  margin: 0;
  font-size: 12px;
  letter-spacing: -0.4px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 20px; }

.chat-room-message-out-template-image-wrapper
.message-out-bubble
.reply-message
p:nth-child(2) {
  letter-spacing: -0.2px; }

.chat-room-message-out-template-image-wrapper
.message-out-bubble
.reply-message.reply-file
.reply-file-thumbnail {
  display: inline-block;
  width: 40px;
  height: 40px;
  margin-right: 10px;
  position: relative; }

.chat-room-message-out-template-image-wrapper
.message-out-bubble
.reply-message.reply-file
.reply-file-thumbnail
img {
  width: 40px;
  height: 40px; }

.chat-room-message-out-template-image-wrapper
.message-out-bubble
.reply-message.reply-file
.reply-file-thumbnail
svg {
  position: absolute;
  left: 50%;
  top: 50%;
  font-size: 35px;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%); }

.chat-room-message-out-template-image-wrapper
.message-out-bubble
.reply-message.reply-file
.reply-text-wrapper {
  width: calc(100% - 50px);
  display: inline-block;
  vertical-align: top; }

.chat-room-message-out-template-image-wrapper
.message-out-bubble
.message-info {
  /* position: absolute; */
  /* bottom: 11px; */
  /* right: 27px; */
  margin: 0;
  color: rgba(25, 25, 25, 0.4);
  font-size: 10px;
  letter-spacing: -0.4px;
  line-height: 18px;
  /* width: 24px; */
  width: 100%;
  white-space: nowrap;
  text-align: right; }
  .chat-room-message-out-template-image-wrapper
.message-out-bubble
.message-info .centered-dot {
    width: 2px;
    height: 2px;
    display: inline-block;
    vertical-align: top;
    background: rgba(25, 25, 25, 0.4);
    border-radius: 50%;
    position: relative;
    top: 9px; }

.chat-room-message-out-template-image-wrapper .message-out-bubble .group-sender-name-wrapper {
  color: #ff7d00;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
  font-size: 14px;
  padding: 10px 10px 0 10px; }

.chat-room-message-out-template-image-wrapper .message-out-bubble .group-sender-name-wrapper .agent-span {
  color: rgba(25, 25, 25, 0.4); }

.chat-room-message-out-template-image-wrapper
.message-out-bubble
.message-info
img {
  margin-left: 4px;
  width: 16px;
  position: relative;
  /* top: 4px; */ }

.message-action-wrapper {
  position: relative;
  position: absolute;
  top: 50%;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%);
  /* left: -170px; */
  /* left: -90px; */
  left: -45px;
  display: none; }

.message-action-wrapper.message-action-right {
  right: -170px;
  left: unset; }

.message-action-wrapper .forward-message svg {
  -webkit-transform: scaleX(-1);
          transform: scaleX(-1); }

.message-action-wrapper .message-action-button-wrapper {
  border-radius: 50%;
  width: 30px;
  height: 30px;
  text-align: center;
  position: relative;
  display: inline-block;
  background-attachment: fixed;
  margin-left: 10px; }

.message-action-wrapper .message-action-button-wrapper svg {
  color: #9b9b9b;
  position: absolute;
  font-size: 18px;
  left: 6px;
  top: 6px; }

.chat-room-message-out-broadcast-wrapper:hover .message-action-wrapper {
  display: inline-block; }

.chat-room-message-out-broadcast-wrapper:hover .message-action-dropdown {
  display: inline-block; }

.message-action-popup-wrapper {
  width: 172px;
  display: inline-block;
  box-shadow: 0px 0px 9px 0px rgba(25, 25, 25, 0.1);
  line-height: 1;
  position: absolute;
  right: 63px;
  top: 0;
  border-radius: 8px;
  z-index: 1; }

.message-action-popup-wrapper .message-action-content {
  width: 100%;
  position: relative;
  padding: 12px 12px 12px 35px;
  font-size: 15px;
  letter-spacing: -0.3px;
  color: #191919;
  border-bottom: solid 1px #dcdcdc;
  background: #ffffff;
  cursor: pointer; }

.message-action-popup-wrapper .message-action-content.delete-message {
  color: #ff3f57; }

.message-action-popup-wrapper .message-action-content.forward-message svg {
  -webkit-transform: rotate(180deg) translate(0, 50%);
          transform: rotate(180deg) translate(0, 50%); }

.message-action-popup-wrapper .message-action-content:hover {
  background: #ff7d00;
  color: #ffffff; }

.message-action-popup-wrapper .message-action-content:hover svg {
  color: #ffffff; }

.message-action-popup-wrapper .message-action-content:nth-child(1) {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px; }

.message-action-popup-wrapper .message-action-content:nth-last-child(1) {
  border: none;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px; }

.message-action-popup-wrapper svg {
  left: 12px;
  font-size: 16px;
  position: absolute;
  top: 50%;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%); }

.chat-room-message-out-template-image-wrapper
.message-out-bubble
.bubble-image-wrapper {
  position: relative;
  min-width: 150px;
  padding: 10px; }
  .chat-room-message-out-template-image-wrapper
.message-out-bubble
.bubble-image-wrapper img {
    width: 100%;
    object-fit: cover;
    border-radius: 16px 2px 16px 16px; }
  .chat-room-message-out-template-image-wrapper
.message-out-bubble
.bubble-image-wrapper.bubble-wrapper-with-caption {
    padding: 10px 10px 0; }
  .chat-room-message-out-template-image-wrapper
.message-out-bubble
.bubble-image-wrapper.bubble-wrapper-clickable img {
    cursor: pointer; }

.chat-room-message-out-template-image-wrapper
.message-out-bubble
.bubble-image-wrapper
img {
  vertical-align: top;
  width: 100%;
  max-width: 100%; }

.chat-room-message-out-template-image-wrapper
.message-out-bubble
.bubble-image-wrapper
.icon-status-wrapper {
  border-radius: 50%;
  position: absolute;
  width: 48px;
  height: 48px;
  display: inline-block;
  background: rgba(25, 25, 25, 0.4);
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  cursor: pointer; }

.chat-room-message-out-template-image-wrapper
.message-out-bubble
.bubble-image-wrapper
.icon-status-wrapper
svg {
  color: #ffffff;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  font-size: 20px; }

.chat-room-message-out-template-image-wrapper
.message-out-bubble
.bubble-image-wrapper
.icon-status-wrapper
.onprocess-wrapper {
  height: 40px;
  width: 40px;
  display: inline-block;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%); }

.chat-room-message-out-template-image-wrapper .message-out-bubble .main-image-message {
  width: 100%;
  max-width: 100%; }

.chat-room-message-out-template-image-wrapper
.message-out-bubble
.thumbnail-image-message {
  width: 300px; }

.modal-image.modal-dialog {
  width: 100vw !important; }

.modal-image .modal-content {
  width: 100%;
  height: 100%;
  background: none; }

.modal-image .modal-content .modal-body {
  width: 100%;
  height: 100%;
  background: rgba(25, 25, 25, 0.8); }

.modal-image .modal-content .modal-body img.image-preview-val {
  max-width: calc(100vw - 130px);
  max-height: calc(100vh - 130px); }

.modal-image .modal-content .modal-body .image-wrapper {
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  text-align: center; }
  .modal-image .modal-content .modal-body .image-wrapper figure {
    margin: 0; }
    .modal-image .modal-content .modal-body .image-wrapper figure.zoom {
      background-size: 180%;
      background-position: -100% -100%;
      background-repeat: no-repeat;
      position: relative;
      width: 100%;
      overflow: hidden;
      cursor: -webkit-zoom-in;
      cursor: zoom-in; }
      .modal-image .modal-content .modal-body .image-wrapper figure.zoom img {
        -webkit-transition: opacity 0.5s;
        transition: opacity 0.5s;
        display: inline-block; }
        .modal-image .modal-content .modal-body .image-wrapper figure.zoom img:hover {
          opacity: 0; }

.modal-image .modal-content .modal-body .close-modal-image {
  position: absolute;
  top: 20px;
  right: 20px;
  color: #ffffff;
  font-size: 40px;
  cursor: pointer;
  -webkit-transition: ease-in-out 0.1s;
  transition: ease-in-out 0.1s; }

.modal-image .modal-content .modal-body .close-modal-image:hover {
  color: #c9c9c9; }

.chat-room-message-out-template-image-wrapper .message-out-bubble .caption-text {
  font-size: 16px;
  padding: 4px 10px 0 10px;
  margin: 0;
  word-break: break-word; }

.chat-room-message-out-template-image-wrapper
.message-out-bubble
.reply-message {
  width: calc(100% - 20px);
  border-radius: 4px;
  border-left: solid 5px #ff8f22;
  background: #e88422;
  color: #191919;
  position: absolute;
  top: 10px;
  left: 10px;
  padding: 5px 7px 5px 7px;
  line-height: 1;
  z-index: 1;
  color: #ffffff; }

.chat-room-message-out-template-image-wrapper
.message-out-bubble
.reply-message
p {
  margin: 0;
  font-size: 12px;
  letter-spacing: -0.4px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 20px; }

.chat-room-message-out-template-image-wrapper
.message-out-bubble
.reply-message
p:nth-child(2) {
  letter-spacing: -0.2px; }

.chat-room-message-out-template-image-wrapper
.message-out-bubble
.reply-message.reply-file
.reply-file-thumbnail {
  display: inline-block;
  width: 40px;
  height: 40px;
  margin-right: 10px;
  position: relative; }

.chat-room-message-out-template-image-wrapper
.message-out-bubble
.reply-message.reply-file
.reply-file-thumbnail
img {
  width: 40px;
  height: 40px; }

.chat-room-message-out-template-image-wrapper
.message-out-bubble
.reply-message.reply-file
.reply-file-thumbnail
svg {
  position: absolute;
  left: 50%;
  top: 50%;
  font-size: 35px;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%); }

.chat-room-message-out-template-image-wrapper
.message-out-bubble
.reply-message.reply-file
.reply-text-wrapper {
  width: calc(100% - 50px);
  display: inline-block;
  vertical-align: top; }

.chat-room-message-out-template-image-wrapper
.message-out-bubble
.message-info {
  position: relative;
  margin: 0;
  white-space: nowrap;
  border-radius: 10px;
  font-size: 10px;
  letter-spacing: -0.4px;
  color: rgba(25, 25, 25, 0.4);
  width: 100%;
  text-align: right;
  padding: 2px 10px 10px; }
  .chat-room-message-out-template-image-wrapper
.message-out-bubble
.message-info .centered-dot {
    width: 2px;
    height: 2px;
    display: inline-block;
    vertical-align: top;
    background: rgba(25, 25, 25, 0.4);
    border-radius: 50%;
    position: relative;
    top: 9px; }

.chat-room-message-out-template-image-wrapper
.message-out-bubble
.message-info-dark {
  background: rgba(25, 25, 25, 0.6);
  padding: 2px 5px;
  color: #ffffff;
  bottom: 60px;
  right: 16px;
  position: absolute;
  width: unset;
  border-radius: 16px; }

.chat-room-message-out-template-image-wrapper
.message-out-bubble
.message-info
img {
  /* vertical-align: top; */
  margin-left: 3px; }

.chat-room-message-out-template-image-wrapper .failed-sending-file-warning {
  font-size: 12px;
  letter-spacing: -0.4px;
  color: rgba(25, 25, 25, 0.4); }

.chat-room-message-out-template-image-wrapper:hover .message-action-wrapper {
  display: inline-block; }

.chat-room-message-out-template-image-wrapper
.message-action-wrapper
.message-action-button-wrapper {
  cursor: pointer; }

.chat-room-message-out-template-image-wrapper
.CircularProgressbar
.CircularProgressbar-trail {
  stroke: transparent; }

.chat-room-message-out-template-image-wrapper
.CircularProgressbar
.CircularProgressbar-path {
  stroke: #ffffff; }

.chat-room-message-out-template-image-wrapper .message-out-template-banner {
  background: rgba(25, 25, 25, 0.05);
  border-radius: 6px;
  margin: 0 10px 10px;
  cursor: pointer;
  color: var(--main-dark-orange);
  text-align: center;
  padding: 5px 16px;
  display: flex;
  justify-content: center;
  font-weight: 700; }
  .chat-room-message-out-template-image-wrapper .message-out-template-banner svg {
    margin-right: 5px;
    align-self: center;
    font-size: 16px; }
  .chat-room-message-out-template-image-wrapper .message-out-template-banner p {
    font-size: 14px;
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    /* margin: 0; */
    display: inline-block;
    white-space: nowrap;
    vertical-align: -7px; }

.modal-templated-message {
  border-radius: 8px;
  padding: 32px; }
  .modal-templated-message .modal-content {
    width: 476px !important; }
  .modal-templated-message svg {
    vertical-align: -1px;
    margin-right: 5px; }
  .modal-templated-message b {
    font-weight: bold;
    font-size: 20px; }
  .modal-templated-message p {
    font-size: 14px;
    margin-bottom: 20px; }
  .modal-templated-message button {
    width: 100%; }

.chat-room-message-file-out-wrapper {
  margin-top: 16px;
  padding: 0 24px;
  width: 100%;
  position: relative;
  text-align: right; }

.chat-room-message-file-out-wrapper .message-out-bubble-file {
  border-radius: 16px 2px 16px 16px;
  background: #FFF2E5;
  border: 1px solid rgba(25, 25, 25, 0.1);
  display: inline-block;
  max-width: calc(100% - 25px);
  padding: 10px;
  color: #191919;
  font-size: 16px;
  letter-spacing: -0.3px;
  line-height: 24px;
  position: relative;
  color: #191919;
  text-align: left;
  min-height: 69px; }
  .chat-room-message-file-out-wrapper .message-out-bubble-file .click-area-file {
    cursor: pointer; }

.chat-room-message-file-out-wrapper .message-out-bubble-file .file-size-and-extension {
  padding: 0 3px 0 0;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  letter-spacing: -0.2px;
  line-height: 1;
  white-space: nowrap; }

.chat-room-message-file-out-wrapper .message-out-bubble-file .file-size-and-extension-download-progress {
  min-width: 155px;
  max-width: 100%; }

.chat-room-message-file-out-wrapper .message-out-bubble-file .reply-message {
  width: calc(100% - 20px);
  border-radius: 4px;
  border-left: solid 5px #FF8F22;
  background: #E88422;
  color: #ffffff;
  position: absolute;
  top: 10px;
  left: 10px;
  padding: 5px 7px 5px 7px;
  line-height: 1; }

.chat-room-message-file-out-wrapper .message-out-bubble-file .reply-message p {
  margin: 0;
  font-size: 12px;
  letter-spacing: -0.4px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 20px; }

.chat-room-message-file-out-wrapper .message-out-bubble-file .reply-message p:nth-child(2) {
  letter-spacing: -0.2px; }

.chat-room-message-file-out-wrapper .message-out-bubble-file .reply-message.reply-file .reply-file-thumbnail {
  display: inline-block;
  width: 40px;
  height: 40px;
  margin-right: 10px;
  position: relative; }

.chat-room-message-file-out-wrapper .message-out-bubble-file .reply-message.reply-file .reply-file-thumbnail img {
  width: 40px;
  height: 40px; }

.chat-room-message-file-out-wrapper .message-out-bubble-file .reply-message.reply-file .reply-file-thumbnail svg {
  position: absolute;
  left: 50%;
  top: 50%;
  font-size: 35px;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%); }

.chat-room-message-file-out-wrapper .message-out-bubble-file .reply-message.reply-file .reply-text-wrapper {
  width: calc(100% - 50px);
  display: inline-block;
  vertical-align: top; }

.chat-room-message-file-out-wrapper .message-out-bubble-file .replied-file-message {
  width: calc(100% - 20px);
  position: absolute;
  top: 10px;
  left: 10px;
  background: #E87303;
  border-radius: 16px 2px 16px 16px;
  color: #ffffff;
  padding: 10px; }

.chat-room-message-file-out-wrapper .message-out-bubble-file .file-inner-wrapper {
  position: relative;
  padding-left: 56px;
  padding-top: 3px; }

.chat-room-message-file-out-wrapper .message-out-bubble-file .caption-text {
  font-size: 16px;
  letter-spacing: -.3px;
  padding: 4px 10px 0 0;
  margin: 0;
  word-break: break-word; }

.chat-room-message-file-out-wrapper .message-out-bubble-file .file-icon-wrapper {
  height: 32px;
  width: 32px;
  display: inline-block;
  background: var(--main-dark-orange);
  border-radius: 50%;
  vertical-align: top;
  position: relative; }

.chat-room-message-file-out-wrapper .message-out-bubble-file .file-icon-wrapper svg {
  font-size: 16px;
  color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%); }

.chat-room-message-file-out-wrapper .message-out-bubble-file .forwarded-message {
  color: #ffffff;
  font-size: 12px;
  letter-spacing: -0.4px;
  line-height: 18px;
  display: block;
  margin-bottom: 5px; }

.chat-room-message-file-out-wrapper .message-out-bubble-file .message-info {
  margin: 0;
  color: rgba(25, 25, 25, 0.4);
  font-size: 10px;
  letter-spacing: -0.4px;
  line-height: 18px;
  white-space: nowrap;
  width: 100%;
  text-align: right; }
  .chat-room-message-file-out-wrapper .message-out-bubble-file .message-info .centered-dot {
    width: 2px;
    height: 2px;
    display: inline-block;
    vertical-align: top;
    background: rgba(25, 25, 25, 0.4);
    border-radius: 50%;
    position: relative;
    top: 9px; }

.chat-room-message-file-out-wrapper .message-out-bubble-file .group-sender-name-wrapper {
  color: #ff7d00;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0 0 4px 0;
  font-size: 14px; }

.chat-room-message-file-out-wrapper .message-out-bubble-file .group-sender-name-wrapper .agent-span {
  color: rgba(25, 25, 25, 0.4); }

.chat-room-message-file-out-wrapper .message-out-bubble-file .message-info img {
  margin-left: 4px;
  width: 16px;
  position: relative;
  /* top: 4px; */ }

.chat-room-message-file-out-wrapper .message-out-bubble-file .message-bubble-file-wrapper {
  width: 100%;
  display: inline-block; }

.chat-room-message-file-out-wrapper .message-out-bubble-file .file-icon-wrapper {
  height: 46px;
  width: 46px;
  display: inline-block;
  background: var(--main-dark-orange);
  border-radius: 50%;
  vertical-align: top;
  position: absolute;
  left: 0;
  top: 0; }

.chat-room-message-file-out-wrapper .message-out-bubble-file .file-icon-wrapper svg {
  font-size: 25px;
  color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%); }

.chat-room-message-file-out-wrapper .message-out-bubble-file .file-icon-wrapper .onprocess-wrapper {
  height: 40px;
  width: 40px;
  display: inline-block;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%); }

.chat-room-message-file-out-wrapper .message-out-bubble-file .message-bubble-file-wrapper p {
  max-width: 100%;
  line-height: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
  display: inline-block;
  white-space: nowrap;
  font-size: 14px;
  letter-spacing: -.2px; }

.chat-room-message-file-out-wrapper .failed-sending-file-warning {
  font-size: 12px;
  letter-spacing: -0.4px;
  color: rgba(25, 25, 25, 0.4); }

.chat-room-message-file-out-wrapper:hover .message-action-wrapper {
  display: inline-block;
  left: -50px; }

.chat-room-message-file-out-wrapper:hover .message-action-dropdown {
  display: inline-block; }

.chat-room-message-file-out-wrapper .message-action-wrapper.with-forward {
  left: -88px !important; }
  .chat-room-message-file-out-wrapper .message-action-wrapper.with-forward .forward-button {
    display: inline-block !important; }

.chat-room-message-file-out-wrapper .CircularProgressbar .CircularProgressbar-trail {
  stroke: #ffffff; }

.chat-room-message-file-out-wrapper .CircularProgressbar .CircularProgressbar-path {
  stroke: #ff7d00; }

.hide-reply-button .message-out-bubble-file .message-action-wrapper {
  left: -45px !important; }
  .hide-reply-button .message-out-bubble-file .message-action-wrapper.with-forward {
    left: -48px !important; }

.chat-room-message-file-out-wrapper .message-out-template-banner {
  background: rgba(25, 25, 25, 0.05);
  border-radius: 6px;
  margin: 0 0 10px;
  cursor: pointer;
  color: var(--main-dark-orange);
  text-align: center;
  padding: 5px 16px;
  display: flex;
  justify-content: center;
  font-weight: 700; }
  .chat-room-message-file-out-wrapper .message-out-template-banner svg {
    margin-right: 5px;
    align-self: center;
    font-size: 16px; }
  .chat-room-message-file-out-wrapper .message-out-template-banner p {
    font-size: 14px;
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    /* margin: 0; */
    display: inline-block;
    white-space: nowrap;
    vertical-align: -7px; }

.modal-templated-message {
  border-radius: 8px;
  padding: 32px; }
  .modal-templated-message .modal-content {
    width: 476px !important; }
  .modal-templated-message svg {
    vertical-align: -1px;
    margin-right: 5px; }
  .modal-templated-message b {
    font-weight: bold;
    font-size: 20px; }
  .modal-templated-message p {
    font-size: 14px;
    margin-bottom: 20px; }
  .modal-templated-message button {
    width: 100%; }

.chat-room-message-video-template-out-wrapper {
  width: 100%;
  padding: 0 24px;
  text-align: right;
  position: relative;
  margin-top: 16px;
  font-size: 0; }

.chat-room-message-video-template-out-wrapper .message-out-bubble-video {
  border-radius: 16px 2px 16px 16px;
  max-width: 300px;
  display: inline-block;
  position: relative;
  font-size: 0;
  background: #FFF2E5;
  border: 1px solid rgba(25, 25, 25, 0.1);
  text-align: left;
  color: #191919; }

.chat-room-message-video-template-out-wrapper .message-out-bubble-video .bubble-image-wrapper {
  min-width: 150px;
  padding: 10px; }
  .chat-room-message-video-template-out-wrapper .message-out-bubble-video .bubble-image-wrapper.bubble-wrapper-with-caption {
    padding: 10px 10px 0; }
  .chat-room-message-video-template-out-wrapper .message-out-bubble-video .bubble-image-wrapper .video-inner-wrapper {
    position: relative; }
  .chat-room-message-video-template-out-wrapper .message-out-bubble-video .bubble-image-wrapper video {
    border-radius: 16px 0 16px 16px; }
  .chat-room-message-video-template-out-wrapper .message-out-bubble-video .bubble-image-wrapper img {
    border-radius: 16px 0 16px 16px; }

.chat-room-message-video-template-out-wrapper .message-out-bubble-video .bubble-image-wrapper .icon-status-wrapper {
  border-radius: 50%;
  position: absolute;
  width: 48px;
  height: 48px;
  display: inline-block;
  background: rgba(25, 25, 25, 0.4);
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  cursor: pointer; }

.chat-room-message-video-template-out-wrapper .message-out-bubble-video .bubble-image-wrapper .icon-status-wrapper svg {
  color: #ffffff;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  font-size: 20px; }

.chat-room-message-video-template-out-wrapper .message-out-bubble-video .bubble-image-wrapper .icon-status-wrapper .onprocess-wrapper {
  height: 40px;
  width: 40px;
  display: inline-block;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%); }

.chat-room-message-video-template-out-wrapper .message-out-bubble-video .bubble-image-wrapper .video-thumbnail {
  width: 100%; }

.chat-room-message-video-template-out-wrapper .message-out-bubble-video .main-image-message {
  width: 100%;
  min-width: 278px; }

.chat-room-message-video-template-out-wrapper .message-out-bubble-video .caption-text {
  font-size: 16px;
  letter-spacing: -.3px;
  padding: 4px 10px 0 10px;
  margin: 0;
  word-break: break-word; }

.chat-room-message-video-template-out-wrapper .message-out-bubble-video .reply-message {
  width: calc(100% - 20px);
  border-radius: 4px;
  border-left: solid 5px #FF8F22;
  background: #E88422;
  color: #191919;
  position: absolute;
  top: 10px;
  left: 10px;
  padding: 5px 7px 5px 7px;
  line-height: 1;
  z-index: 1;
  color: #ffffff; }

.chat-room-message-video-template-out-wrapper .message-out-bubble-video .reply-message p {
  margin: 0;
  font-size: 12px;
  letter-spacing: -0.4px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 20px; }

.chat-room-message-video-template-out-wrapper .message-out-bubble-video .reply-message p:nth-child(2) {
  letter-spacing: -0.2px; }

.chat-room-message-video-template-out-wrapper .message-out-bubble-video .reply-message.reply-file .reply-file-thumbnail {
  display: inline-block;
  width: 40px;
  height: 40px;
  margin-right: 10px;
  position: relative; }

.chat-room-message-video-template-out-wrapper .message-out-bubble-video .reply-message.reply-file .reply-file-thumbnail img {
  width: 40px;
  height: 40px; }

.chat-room-message-video-template-out-wrapper .message-out-bubble-video .reply-message.reply-file .reply-file-thumbnail svg {
  position: absolute;
  left: 50%;
  top: 50%;
  font-size: 35px;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%); }

.chat-room-message-video-template-out-wrapper .message-out-bubble-video .reply-message.reply-file .reply-text-wrapper {
  width: calc(100% - 50px);
  display: inline-block;
  vertical-align: top; }

.chat-room-message-video-template-out-wrapper .message-out-bubble-video .message-info {
  position: relative;
  margin: 0;
  white-space: nowrap;
  border-radius: 10px;
  font-size: 10px;
  letter-spacing: -0.4px;
  color: rgba(25, 25, 25, 0.4);
  width: 100%;
  text-align: right;
  padding: 2px 10px 10px; }
  .chat-room-message-video-template-out-wrapper .message-out-bubble-video .message-info .centered-dot {
    width: 2px;
    height: 2px;
    display: inline-block;
    vertical-align: top;
    background: rgba(25, 25, 25, 0.4);
    border-radius: 50%;
    position: relative;
    top: 9px; }

.chat-room-message-video-template-out-wrapper .message-out-bubble-video .group-sender-name-wrapper {
  color: #ff7d00;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0 0 4px 0;
  font-size: 14px; }

.chat-room-message-video-template-out-wrapper .message-out-bubble-video .group-sender-name-wrapper .agent-span {
  color: rgba(25, 25, 25, 0.4); }

.chat-room-message-video-template-out-wrapper .message-out-bubble-video .message-info-dark {
  background: rgba(25, 25, 25, 0.6);
  padding: 2px 5px;
  color: #ffffff;
  bottom: 60px;
  right: 16px;
  position: absolute;
  width: unset; }

.chat-room-message-video-template-out-wrapper .message-out-bubble-video .message-info img {
  /* vertical-align: top; */
  margin-left: 3px; }

.chat-room-message-video-template-out-wrapper .message-out-bubble-video .timer-filesize-wrapper {
  background: rgba(25, 25, 25, 0.6);
  position: absolute;
  white-space: nowrap;
  border-radius: 10px;
  font-size: 12px;
  padding: 2px 5px;
  color: rgba(255, 255, 255, 0.6);
  top: 10px;
  left: 10px;
  z-index: 1; }

.chat-room-message-video-template-out-wrapper .message-out-bubble-video .play-button-wraper {
  border-radius: 50%;
  position: absolute;
  width: 48px;
  height: 48px;
  display: inline-block;
  background: rgba(25, 25, 25, 0.4);
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  cursor: pointer; }

.chat-room-message-video-template-out-wrapper .message-out-bubble-video .play-button-wraper svg {
  color: #ffffff;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  font-size: 20px; }

.chat-room-message-video-template-out-wrapper:hover .message-action-wrapper {
  display: inline-block; }

.chat-room-message-video-template-out-wrapper:hover .message-action-dropdown {
  display: inline-block; }

.chat-room-message-video-template-out-wrapper .message-action-wrapper {
  left: -45px; }
  .chat-room-message-video-template-out-wrapper .message-action-wrapper.with-forward {
    left: -88px !important; }
    .chat-room-message-video-template-out-wrapper .message-action-wrapper.with-forward .forward-button {
      display: inline-block !important; }

.chat-room-message-video-template-out-wrapper .message-action-wrapper .message-action-button-wrapper {
  cursor: pointer; }

.chat-room-message-video-template-out-wrapper .CircularProgressbar .CircularProgressbar-trail {
  stroke: transparent; }

.chat-room-message-video-template-out-wrapper .CircularProgressbar .CircularProgressbar-path {
  stroke: #ffffff; }

.modal-video.modal-dialog {
  width: 100vw !important; }

.modal-video .modal-content {
  width: 100%;
  height: 100%;
  background: none; }

.modal-video .modal-content .modal-body {
  width: 100%;
  height: 100%;
  background: rgba(25, 25, 25, 0.8); }

.modal-video .modal-content .modal-body .video-wrapper {
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%); }

.modal-video .modal-content .modal-body .close-modal-video {
  position: absolute;
  top: 20px;
  right: 20px;
  color: #ffffff;
  font-size: 40px;
  cursor: pointer;
  -webkit-transition: ease-in-out 0.1s;
  transition: ease-in-out 0.1s; }

.modal-video .modal-content .modal-body .close-modal-video:hover {
  color: #c9c9c9; }

.modal-video .modal-content .modal-body video {
  max-height: calc(100vh - 90px);
  max-width: calc(100vw - 90px); }

.hide-reply-button .message-out-bubble-video .message-action-wrapper {
  left: -45px !important; }
  .hide-reply-button .message-out-bubble-video .message-action-wrapper.with-forward {
    left: -48px !important; }

.chat-room-message-video-template-out-wrapper .message-out-template-banner {
  background: rgba(25, 25, 25, 0.05);
  border-radius: 6px;
  margin: 0 10px 10px;
  cursor: pointer;
  color: var(--main-dark-orange);
  text-align: center;
  padding: 5px 16px;
  display: flex;
  justify-content: center;
  font-weight: 700; }
  .chat-room-message-video-template-out-wrapper .message-out-template-banner svg {
    margin-right: 5px;
    align-self: center;
    font-size: 16px; }
  .chat-room-message-video-template-out-wrapper .message-out-template-banner p {
    font-size: 14px;
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    /* margin: 0; */
    display: inline-block;
    white-space: nowrap;
    vertical-align: -7px; }

.modal-templated-message {
  border-radius: 8px;
  padding: 32px; }
  .modal-templated-message .modal-content {
    width: 476px !important; }
  .modal-templated-message svg {
    vertical-align: -1px;
    margin-right: 5px; }
  .modal-templated-message b {
    font-weight: bold;
    font-size: 20px; }
  .modal-templated-message p {
    font-size: 14px;
    margin-bottom: 20px; }
  .modal-templated-message button {
    width: 100%; }

.chat-room-message-out-broadcast-wrapper {
  margin-top: 16px;
  padding: 0 24px;
  width: 100%;
  position: relative;
  text-align: right; }
  .chat-room-message-out-broadcast-wrapper .message-out-bubble-broadcast .message-title {
    color: rgba(25, 25, 25, 0.4); }
    .chat-room-message-out-broadcast-wrapper .message-out-bubble-broadcast .message-title b {
      font-size: 14px;
      letter-spacing: -.4px; }
    .chat-room-message-out-broadcast-wrapper .message-out-bubble-broadcast .message-title img {
      height: 17px !important;
      width: 16px !important;
      position: relative;
      top: -1px;
      margin-right: 5px !important; }

.chat-room-message-out-broadcast-wrapper .message-out-bubble-broadcast {
  border-radius: 16px 2px 16px 16px;
  background: #FFF2E5;
  border: 1px solid rgba(25, 25, 25, 0.1);
  display: inline-block;
  /* max-width: calc(100% - 180px); */
  max-width: calc(100% - 110px);
  /* padding: 10px 62px 10px 10px; */
  padding: 10px;
  color: #191919;
  font-size: 16px;
  letter-spacing: -0.3px;
  line-height: 24px;
  position: relative;
  text-align: left;
  min-height: 44px; }

.chat-room-message-out-broadcast-wrapper .message-out-bubble-broadcast.deleted-bubble {
  color: #ffffff;
  padding: 10px 10px 10px 33px; }

.chat-room-message-out-broadcast-wrapper .message-out-bubble-broadcast.deleted-bubble .deleted-icon {
  position: absolute;
  left: 10px;
  top: 50%;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%); }

.chat-room-message-out-broadcast-wrapper .message-out-bubble-broadcast .forwarded-message {
  color: #ffffff;
  font-size: 12px;
  letter-spacing: -0.4px;
  line-height: 18px;
  display: block;
  margin-bottom: 5px; }

.chat-room-message-out-broadcast-wrapper .message-out-bubble-broadcast .reply-message {
  width: calc(100% - 20px);
  border-radius: 4px;
  border-left: solid 5px #FF8F22;
  background: #E88422;
  color: #ffffff;
  position: absolute;
  top: 10px;
  left: 10px;
  padding: 5px 7px 5px 7px;
  line-height: 1; }

.chat-room-message-out-broadcast-wrapper .message-out-bubble-broadcast .reply-message p {
  margin: 0;
  font-size: 12px;
  letter-spacing: -0.4px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 20px; }

.chat-room-message-out-broadcast-wrapper .message-out-bubble-broadcast .reply-message p:nth-child(2) {
  letter-spacing: -0.2px; }

.chat-room-message-out-broadcast-wrapper .message-out-bubble-broadcast .reply-message.reply-file .reply-file-thumbnail {
  display: inline-block;
  width: 40px;
  height: 40px;
  margin-right: 10px;
  position: relative; }

.chat-room-message-out-broadcast-wrapper .message-out-bubble-broadcast .reply-message.reply-file .reply-file-thumbnail img {
  width: 40px;
  height: 40px; }

.chat-room-message-out-broadcast-wrapper .message-out-bubble-broadcast .reply-message.reply-file .reply-file-thumbnail svg {
  position: absolute;
  left: 50%;
  top: 50%;
  font-size: 35px;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%); }

.chat-room-message-out-broadcast-wrapper .message-out-bubble-broadcast .reply-message.reply-file .reply-text-wrapper {
  width: calc(100% - 50px);
  display: inline-block;
  vertical-align: top; }

.chat-room-message-out-broadcast-wrapper .message-out-bubble-broadcast .message-info {
  /* position: absolute; */
  /* bottom: 11px; */
  /* right: 27px; */
  margin: 0;
  color: rgba(25, 25, 25, 0.4);
  font-size: 10px;
  letter-spacing: -0.4px;
  line-height: 18px;
  /* width: 24px; */
  width: 100%;
  white-space: nowrap;
  text-align: right; }
  .chat-room-message-out-broadcast-wrapper .message-out-bubble-broadcast .message-info .centered-dot {
    width: 2px;
    height: 2px;
    display: inline-block;
    vertical-align: top;
    background: rgba(25, 25, 25, 0.4);
    border-radius: 50%;
    position: relative;
    top: 9px; }

.chat-room-message-out-broadcast-wrapper .message-out-bubble-broadcast .group-sender-name-wrapper {
  color: #ff7d00;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
  font-size: 14px; }

.chat-room-message-out-broadcast-wrapper .message-out-bubble-broadcast .group-sender-name-wrapper .agent-span {
  color: rgba(25, 25, 25, 0.4); }

.chat-room-message-out-broadcast-wrapper .message-out-bubble-broadcast .message-info img {
  margin-left: 4px;
  width: 16px;
  position: relative;
  /* top: 4px; */ }

.message-action-wrapper {
  position: relative;
  position: absolute;
  top: 50%;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%);
  /* left: -170px; */
  /* left: -90px; */
  left: -45px;
  display: none; }

.message-action-wrapper.message-action-right {
  right: -170px;
  left: unset; }

.message-action-wrapper .forward-message svg {
  -webkit-transform: scaleX(-1);
          transform: scaleX(-1); }

.message-action-wrapper .message-action-button-wrapper {
  border-radius: 50%;
  width: 30px;
  height: 30px;
  text-align: center;
  position: relative;
  display: inline-block;
  background-attachment: fixed;
  margin-left: 10px; }

.message-action-wrapper .message-action-button-wrapper svg {
  color: #9b9b9b;
  position: absolute;
  font-size: 18px;
  left: 6px;
  top: 6px; }

.chat-room-message-out-broadcast-wrapper:hover .message-action-wrapper {
  display: inline-block; }

.chat-room-message-out-broadcast-wrapper:hover .message-action-dropdown {
  display: inline-block; }

.chat-room-message-out-broadcast-wrapper .message-action-wrapper {
  /* right: -120px !important; */
  left: -90px !important; }

.hide-reply-button .message-out-bubble-broadcast .message-action-wrapper {
  left: -45px !important; }
  .hide-reply-button .message-out-bubble-broadcast .message-action-wrapper.with-forward {
    left: -128px !important; }

.message-action-popup-wrapper {
  width: 172px;
  display: inline-block;
  box-shadow: 0px 0px 9px 0px rgba(25, 25, 25, 0.1);
  line-height: 1;
  position: absolute;
  right: 63px;
  top: 0;
  border-radius: 8px;
  z-index: 1; }

.message-action-popup-wrapper .message-action-content {
  width: 100%;
  position: relative;
  padding: 12px 12px 12px 35px;
  font-size: 15px;
  letter-spacing: -0.3px;
  color: #191919;
  border-bottom: solid 1px #dcdcdc;
  background: #ffffff;
  cursor: pointer; }

.message-action-popup-wrapper .message-action-content.delete-message {
  color: #ff3f57; }

.message-action-popup-wrapper .message-action-content.forward-message svg {
  -webkit-transform: rotate(180deg) translate(0, 50%);
          transform: rotate(180deg) translate(0, 50%); }

.message-action-popup-wrapper .message-action-content:hover {
  background: #ff7d00;
  color: #ffffff; }

.message-action-popup-wrapper .message-action-content:hover svg {
  color: #ffffff; }

.message-action-popup-wrapper .message-action-content:nth-child(1) {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px; }

.message-action-popup-wrapper .message-action-content:nth-last-child(1) {
  border: none;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px; }

.message-action-popup-wrapper svg {
  left: 12px;
  font-size: 16px;
  position: absolute;
  top: 50%;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%); }

.chat-room-message-out-broadcast-image-wrapper {
  width: 100%;
  text-align: right;
  position: relative;
  margin-top: 16px;
  padding: 0 24px;
  font-size: 0; }
  .chat-room-message-out-broadcast-image-wrapper .message-action-wrapper {
    left: -45px; }
  .chat-room-message-out-broadcast-image-wrapper .message-out-template-banner {
    margin: 0 10px 10px !important; }

.chat-room-message-out-broadcast-image-wrapper .message-out-bubble {
  border-radius: 16px 2px 16px 16px;
  max-width: 300px;
  display: inline-block;
  position: relative;
  font-size: 0;
  background: #FFF2E5;
  border: 1px solid rgba(25, 25, 25, 0.1);
  text-align: left;
  color: #191919;
  min-height: 80px;
  padding: 10px 0 0; }
  .chat-room-message-out-broadcast-image-wrapper .message-out-bubble .message-title {
    padding: 0 10px 0;
    color: rgba(25, 25, 25, 0.4); }
    .chat-room-message-out-broadcast-image-wrapper .message-out-bubble .message-title b {
      font-size: 14px;
      letter-spacing: -.4px; }
    .chat-room-message-out-broadcast-image-wrapper .message-out-bubble .message-title img {
      height: 17px !important;
      width: 16px !important;
      position: relative;
      top: -5px;
      margin-right: 5px !important; }

.chat-room-message-out-broadcast-image-wrapper
.message-out-bubble.deleted-bubble {
  color: #ffffff;
  padding: 10px 10px 10px 33px; }

.chat-room-message-out-broadcast-image-wrapper
.message-out-bubble.deleted-bubble
.deleted-icon {
  position: absolute;
  left: 10px;
  top: 50%;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%); }

.chat-room-message-out-broadcast-image-wrapper
.message-out-bubble
.forwarded-message {
  color: #ffffff;
  font-size: 12px;
  letter-spacing: -0.4px;
  line-height: 18px;
  display: block;
  margin-bottom: 5px; }

.chat-room-message-out-broadcast-image-wrapper
.message-out-bubble
.reply-message {
  width: calc(100% - 20px);
  border-radius: 4px;
  border-left: solid 5px #ff8f22;
  background: #e88422;
  color: #ffffff;
  position: absolute;
  top: 10px;
  left: 10px;
  padding: 5px 7px 5px 7px;
  line-height: 1; }

.chat-room-message-out-broadcast-image-wrapper
.message-out-bubble
.reply-message
p {
  margin: 0;
  font-size: 12px;
  letter-spacing: -0.4px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 20px; }

.chat-room-message-out-broadcast-image-wrapper
.message-out-bubble
.reply-message
p:nth-child(2) {
  letter-spacing: -0.2px; }

.chat-room-message-out-broadcast-image-wrapper
.message-out-bubble
.reply-message.reply-file
.reply-file-thumbnail {
  display: inline-block;
  width: 40px;
  height: 40px;
  margin-right: 10px;
  position: relative; }

.chat-room-message-out-broadcast-image-wrapper
.message-out-bubble
.reply-message.reply-file
.reply-file-thumbnail
img {
  width: 40px;
  height: 40px; }

.chat-room-message-out-broadcast-image-wrapper
.message-out-bubble
.reply-message.reply-file
.reply-file-thumbnail
svg {
  position: absolute;
  left: 50%;
  top: 50%;
  font-size: 35px;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%); }

.chat-room-message-out-broadcast-image-wrapper
.message-out-bubble
.reply-message.reply-file
.reply-text-wrapper {
  width: calc(100% - 50px);
  display: inline-block;
  vertical-align: top; }

.chat-room-message-out-broadcast-image-wrapper .message-out-bubble .message-info {
  /* position: absolute; */
  /* bottom: 11px; */
  /* right: 27px; */
  margin: 0;
  color: rgba(25, 25, 25, 0.4);
  font-size: 10px;
  letter-spacing: -0.4px;
  line-height: 18px;
  /* width: 24px; */
  width: 100%;
  white-space: nowrap;
  text-align: right;
  padding: 0 10px 10px; }
  .chat-room-message-out-broadcast-image-wrapper .message-out-bubble .message-info .centered-dot {
    width: 2px;
    height: 2px;
    display: inline-block;
    vertical-align: top;
    background: rgba(25, 25, 25, 0.4);
    border-radius: 50%;
    position: relative;
    top: 9px; }

.chat-room-message-out-broadcast-image-wrapper
.message-out-bubble
.message-info
img {
  margin-left: 4px;
  width: 16px;
  position: relative;
  /* top: 4px; */ }

.message-action-wrapper {
  position: relative;
  position: absolute;
  top: 50%;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%);
  /* left: -170px; */
  /* left: -90px; */
  left: -45px;
  display: none; }

.message-action-wrapper.message-action-right {
  right: -170px;
  left: unset; }

.message-action-wrapper .forward-message svg {
  -webkit-transform: scaleX(-1);
          transform: scaleX(-1); }

.message-action-wrapper .message-action-button-wrapper {
  border-radius: 50%;
  width: 30px;
  height: 30px;
  text-align: center;
  position: relative;
  display: inline-block;
  background-attachment: fixed;
  margin-left: 10px; }

.message-action-wrapper .message-action-button-wrapper svg {
  color: #9b9b9b;
  position: absolute;
  font-size: 18px;
  left: 6px;
  top: 6px; }

.chat-room-message-out-broadcast-wrapper:hover .message-action-wrapper {
  display: inline-block; }

.message-action-popup-wrapper {
  width: 172px;
  display: inline-block;
  box-shadow: 0px 0px 9px 0px rgba(25, 25, 25, 0.1);
  line-height: 1;
  position: absolute;
  right: 63px;
  top: 0;
  border-radius: 8px;
  z-index: 1; }

.message-action-popup-wrapper .message-action-content {
  width: 100%;
  position: relative;
  padding: 12px 12px 12px 35px;
  font-size: 15px;
  letter-spacing: -0.3px;
  color: #191919;
  border-bottom: solid 1px #dcdcdc;
  background: #ffffff;
  cursor: pointer; }

.message-action-popup-wrapper .message-action-content.delete-message {
  color: #ff3f57; }

.message-action-popup-wrapper .message-action-content.forward-message svg {
  -webkit-transform: rotate(180deg) translate(0, 50%);
          transform: rotate(180deg) translate(0, 50%); }

.message-action-popup-wrapper .message-action-content:hover {
  background: #ff7d00;
  color: #ffffff; }

.message-action-popup-wrapper .message-action-content:hover svg {
  color: #ffffff; }

.message-action-popup-wrapper .message-action-content:nth-child(1) {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px; }

.message-action-popup-wrapper .message-action-content:nth-last-child(1) {
  border: none;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px; }

.message-action-popup-wrapper svg {
  left: 12px;
  font-size: 16px;
  position: absolute;
  top: 50%;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%); }

.chat-room-message-out-broadcast-image-wrapper
.message-out-bubble .bubble-image-wrapper {
  position: relative;
  min-width: 150px;
  padding: 6px 10px 10px; }
  .chat-room-message-out-broadcast-image-wrapper
.message-out-bubble .bubble-image-wrapper.bubble-wrapper-with-caption {
    padding: 6px 10px 0; }
  .chat-room-message-out-broadcast-image-wrapper
.message-out-bubble .bubble-image-wrapper img {
    width: 100%;
    object-fit: cover;
    border-radius: 16px 2px 16px 16px; }
  .chat-room-message-out-broadcast-image-wrapper
.message-out-bubble .bubble-image-wrapper.bubble-wrapper-clickable img {
    cursor: pointer; }

.chat-room-message-out-broadcast-image-wrapper
.message-out-bubble
.bubble-image-wrapper
img {
  vertical-align: top;
  width: 100%;
  max-width: 100%; }

.chat-room-message-out-broadcast-image-wrapper
.message-out-bubble
.bubble-image-wrapper
.icon-status-wrapper {
  border-radius: 50%;
  position: absolute;
  width: 48px;
  height: 48px;
  display: inline-block;
  background: rgba(25, 25, 25, 0.4);
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  cursor: pointer; }

.chat-room-message-out-broadcast-image-wrapper
.message-out-bubble
.bubble-image-wrapper
.icon-status-wrapper
svg {
  color: #ffffff;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  font-size: 20px; }

.chat-room-message-out-broadcast-image-wrapper
.message-out-bubble
.bubble-image-wrapper
.icon-status-wrapper
.onprocess-wrapper {
  height: 40px;
  width: 40px;
  display: inline-block;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%); }

.chat-room-message-out-broadcast-image-wrapper .message-out-bubble .main-image-message {
  width: 100%;
  max-width: 100%; }

.chat-room-message-out-broadcast-image-wrapper
.message-out-bubble
.thumbnail-image-message {
  width: 300px; }

.modal-image.modal-dialog {
  width: 100vw !important; }

.modal-image .modal-content {
  width: 100%;
  height: 100%;
  background: none; }

.modal-image .modal-content .modal-body {
  width: 100%;
  height: 100%;
  background: rgba(25, 25, 25, 0.8); }

.modal-image .modal-content .modal-body img.image-preview-val {
  max-width: calc(100vw - 130px);
  max-height: calc(100vh - 130px); }

.modal-image .modal-content .modal-body .image-wrapper {
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  text-align: center; }
  .modal-image .modal-content .modal-body .image-wrapper figure {
    margin: 0; }
    .modal-image .modal-content .modal-body .image-wrapper figure.zoom {
      background-size: 180%;
      background-position: -100% -100%;
      background-repeat: no-repeat;
      position: relative;
      width: 100%;
      overflow: hidden;
      cursor: -webkit-zoom-in;
      cursor: zoom-in; }
      .modal-image .modal-content .modal-body .image-wrapper figure.zoom img {
        -webkit-transition: opacity 0.5s;
        transition: opacity 0.5s;
        display: inline-block; }
        .modal-image .modal-content .modal-body .image-wrapper figure.zoom img:hover {
          opacity: 0; }

.modal-image .modal-content .modal-body .close-modal-image {
  position: absolute;
  top: 20px;
  right: 20px;
  color: #ffffff;
  font-size: 40px;
  cursor: pointer;
  -webkit-transition: ease-in-out 0.1s;
  transition: ease-in-out 0.1s; }

.modal-image .modal-content .modal-body .close-modal-image:hover {
  color: #c9c9c9; }

.chat-room-message-out-broadcast-image-wrapper .message-out-bubble .caption-text {
  font-size: 16px;
  letter-spacing: -.3px;
  padding: 4px 10px 0 10px;
  margin: 0;
  word-break: break-word; }

.chat-room-message-out-broadcast-image-wrapper
.message-out-bubble
.reply-message {
  width: calc(100% - 20px);
  border-radius: 4px;
  border-left: solid 5px #ff8f22;
  background: #e88422;
  color: #191919;
  position: absolute;
  top: 10px;
  left: 10px;
  padding: 5px 7px 5px 7px;
  line-height: 1;
  z-index: 1;
  color: #ffffff; }

.chat-room-message-out-broadcast-image-wrapper
.message-out-bubble
.reply-message
p {
  margin: 0;
  font-size: 12px;
  letter-spacing: -0.4px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 20px; }

.chat-room-message-out-broadcast-image-wrapper
.message-out-bubble
.reply-message
p:nth-child(2) {
  letter-spacing: -0.2px; }

.chat-room-message-out-broadcast-image-wrapper
.message-out-bubble
.reply-message.reply-file
.reply-file-thumbnail {
  display: inline-block;
  width: 40px;
  height: 40px;
  margin-right: 10px;
  position: relative; }

.chat-room-message-out-broadcast-image-wrapper
.message-out-bubble
.reply-message.reply-file
.reply-file-thumbnail
img {
  width: 40px;
  height: 40px; }

.chat-room-message-out-broadcast-image-wrapper
.message-out-bubble
.reply-message.reply-file
.reply-file-thumbnail
svg {
  position: absolute;
  left: 50%;
  top: 50%;
  font-size: 35px;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%); }

.chat-room-message-out-broadcast-image-wrapper
.message-out-bubble
.reply-message.reply-file
.reply-text-wrapper {
  width: calc(100% - 50px);
  display: inline-block;
  vertical-align: top; }

.chat-room-message-out-broadcast-image-wrapper
.message-out-bubble
.message-info {
  margin: 0;
  color: rgba(25, 25, 25, 0.4);
  font-size: 10px;
  letter-spacing: -0.4px;
  line-height: 18px;
  /* width: 24px; */
  width: 100%;
  white-space: nowrap;
  text-align: right; }
  .chat-room-message-out-broadcast-image-wrapper
.message-out-bubble
.message-info .centered-dot {
    width: 2px;
    height: 2px;
    display: inline-block;
    vertical-align: top;
    background: rgba(25, 25, 25, 0.4);
    border-radius: 50%;
    position: relative;
    top: 9px; }

.chat-room-message-out-broadcast-image-wrapper .message-out-bubble .group-sender-name-wrapper {
  color: #ff7d00;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
  padding: 0 10px 0 10px;
  font-size: 14px; }

.chat-room-message-out-broadcast-image-wrapper .message-out-bubble .group-sender-name-wrapper .agent-span {
  color: rgba(25, 25, 25, 0.4); }

.chat-room-message-out-broadcast-image-wrapper
.message-out-bubble
.message-info-dark {
  background: rgba(25, 25, 25, 0.6);
  padding: 2px 5px;
  color: #ffffff;
  bottom: 16px;
  right: 16px;
  position: absolute;
  width: unset;
  border-radius: 16px; }

.chat-room-message-out-broadcast-image-wrapper
.message-out-bubble
.message-info
img {
  /* vertical-align: top; */
  margin-left: 3px; }

.chat-room-message-out-broadcast-image-wrapper .failed-sending-file-warning {
  font-size: 12px;
  letter-spacing: -0.4px;
  color: rgba(25, 25, 25, 0.4); }

.chat-room-message-out-broadcast-image-wrapper:hover .message-action-wrapper {
  display: inline-block; }

.chat-room-message-out-broadcast-image-wrapper:hover .message-action-dropdown {
  display: inline-block; }

.chat-room-message-out-broadcast-image-wrapper
.message-action-wrapper
.message-action-button-wrapper {
  cursor: pointer; }

.chat-room-message-out-broadcast-image-wrapper
.CircularProgressbar
.CircularProgressbar-trail {
  stroke: transparent; }

.chat-room-message-out-broadcast-image-wrapper
.CircularProgressbar
.CircularProgressbar-path {
  stroke: #ffffff; }

.chat-room-message-video-out-wrapper {
  width: 100%;
  padding: 0 24px;
  text-align: right;
  position: relative;
  margin-top: 16px;
  font-size: 0; }

.chat-room-message-video-out-wrapper .message-out-bubble {
  border-radius: 16px 2px 16px 16px;
  max-width: 300px;
  display: inline-block;
  position: relative;
  font-size: 0;
  background: #FFF2E5;
  border: 1px solid rgba(25, 25, 25, 0.1);
  text-align: left;
  color: #191919;
  padding: 10px 0 0; }
  .chat-room-message-video-out-wrapper .message-out-bubble .message-title {
    padding: 0 10px 0;
    color: rgba(25, 25, 25, 0.4); }
    .chat-room-message-video-out-wrapper .message-out-bubble .message-title b {
      font-size: 14px;
      letter-spacing: -.4px; }
    .chat-room-message-video-out-wrapper .message-out-bubble .message-title img {
      height: 17px !important;
      width: 16px !important;
      position: relative;
      top: -5px;
      margin-right: 5px !important; }

.chat-room-message-video-out-wrapper .message-out-bubble .bubble-image-wrapper {
  min-width: 150px;
  padding: 6px 10px 10px; }
  .chat-room-message-video-out-wrapper .message-out-bubble .bubble-image-wrapper.bubble-wrapper-with-caption {
    padding: 6px 10px 0; }
  .chat-room-message-video-out-wrapper .message-out-bubble .bubble-image-wrapper .video-inner-wrapper {
    position: relative; }
  .chat-room-message-video-out-wrapper .message-out-bubble .bubble-image-wrapper video {
    border-radius: 16px 0 16px 16px; }
  .chat-room-message-video-out-wrapper .message-out-bubble .bubble-image-wrapper img {
    border-radius: 16px 0 16px 16px; }

.chat-room-message-video-out-wrapper .message-out-bubble .bubble-image-wrapper .icon-status-wrapper {
  border-radius: 50%;
  position: absolute;
  width: 48px;
  height: 48px;
  display: inline-block;
  background: rgba(25, 25, 25, 0.4);
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  cursor: pointer; }

.chat-room-message-video-out-wrapper .message-out-bubble .bubble-image-wrapper .icon-status-wrapper svg {
  color: #ffffff;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  font-size: 20px; }

.chat-room-message-video-out-wrapper .message-out-bubble .bubble-image-wrapper .icon-status-wrapper .onprocess-wrapper {
  height: 40px;
  width: 40px;
  display: inline-block;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%); }

.chat-room-message-video-out-wrapper .message-out-bubble .bubble-image-wrapper .video-thumbnail {
  width: 100%; }

.chat-room-message-video-out-wrapper .message-out-bubble .main-image-message {
  width: 100%;
  min-width: 278px; }

.chat-room-message-video-out-wrapper .message-out-bubble .caption-text {
  font-size: 16px;
  letter-spacing: -.3px;
  padding: 4px 10px 0 10px;
  margin: 0;
  word-break: break-word; }

.chat-room-message-video-out-wrapper .message-out-bubble .reply-message {
  width: calc(100% - 20px);
  border-radius: 4px;
  border-left: solid 5px #FF8F22;
  background: #E88422;
  color: #191919;
  position: absolute;
  top: 10px;
  left: 10px;
  padding: 5px 7px 5px 7px;
  line-height: 1;
  z-index: 1;
  color: #ffffff; }

.chat-room-message-video-out-wrapper .message-out-bubble .reply-message p {
  margin: 0;
  font-size: 12px;
  letter-spacing: -0.4px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 20px; }

.chat-room-message-video-out-wrapper .message-out-bubble .reply-message p:nth-child(2) {
  letter-spacing: -0.2px; }

.chat-room-message-video-out-wrapper .message-out-bubble .reply-message.reply-file .reply-file-thumbnail {
  display: inline-block;
  width: 40px;
  height: 40px;
  margin-right: 10px;
  position: relative; }

.chat-room-message-video-out-wrapper .message-out-bubble .reply-message.reply-file .reply-file-thumbnail img {
  width: 40px;
  height: 40px; }

.chat-room-message-video-out-wrapper .message-out-bubble .reply-message.reply-file .reply-file-thumbnail svg {
  position: absolute;
  left: 50%;
  top: 50%;
  font-size: 35px;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%); }

.chat-room-message-video-out-wrapper .message-out-bubble .reply-message.reply-file .reply-text-wrapper {
  width: calc(100% - 50px);
  display: inline-block;
  vertical-align: top; }

.chat-room-message-video-out-wrapper .message-out-bubble .message-info {
  margin: 0;
  color: rgba(25, 25, 25, 0.4);
  font-size: 10px;
  letter-spacing: -0.4px;
  line-height: 18px;
  /* width: 24px; */
  width: 100%;
  white-space: nowrap;
  text-align: right;
  padding: 0 10px 10px; }
  .chat-room-message-video-out-wrapper .message-out-bubble .message-info .centered-dot {
    width: 2px;
    height: 2px;
    display: inline-block;
    vertical-align: top;
    background: rgba(25, 25, 25, 0.4);
    border-radius: 50%;
    position: relative;
    top: 9px; }

.chat-room-message-video-out-wrapper .message-out-bubble .group-sender-name-wrapper {
  color: #ff7d00;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
  padding: 0 10px 0 10px;
  font-size: 14px; }

.chat-room-message-video-out-wrapper .message-out-bubble .group-sender-name-wrapper .agent-span {
  color: rgba(25, 25, 25, 0.4); }

.chat-room-message-video-out-wrapper .message-out-bubble .message-info-dark {
  background: rgba(25, 25, 25, 0.6);
  padding: 2px 5px;
  color: #ffffff;
  bottom: 16px;
  right: 16px;
  position: absolute;
  width: unset; }

.chat-room-message-video-out-wrapper .message-out-bubble .message-info img {
  /* vertical-align: top; */
  margin-left: 3px; }

.chat-room-message-video-out-wrapper .message-out-bubble .timer-filesize-wrapper {
  background: rgba(25, 25, 25, 0.6);
  position: absolute;
  white-space: nowrap;
  border-radius: 10px;
  font-size: 12px;
  padding: 2px 5px;
  color: rgba(255, 255, 255, 0.6);
  top: 10px;
  left: 10px;
  z-index: 1; }

.chat-room-message-video-out-wrapper .message-out-bubble .play-button-wraper {
  border-radius: 50%;
  position: absolute;
  width: 48px;
  height: 48px;
  display: inline-block;
  background: rgba(25, 25, 25, 0.4);
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  cursor: pointer; }

.chat-room-message-video-out-wrapper .message-out-bubble .play-button-wraper svg {
  color: #ffffff;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  font-size: 20px; }

.chat-room-message-video-out-wrapper:hover .message-action-wrapper {
  display: inline-block; }

.chat-room-message-video-out-wrapper:hover .message-action-dropdown {
  display: inline-block; }

.chat-room-message-video-out-wrapper .message-action-wrapper {
  left: -45px; }

.chat-room-message-video-out-wrapper .message-action-wrapper .message-action-button-wrapper {
  cursor: pointer; }

.chat-room-message-video-out-wrapper .CircularProgressbar .CircularProgressbar-trail {
  stroke: transparent; }

.chat-room-message-video-out-wrapper .CircularProgressbar .CircularProgressbar-path {
  stroke: #ffffff; }

.modal-video.modal-dialog {
  width: 100vw !important; }

.modal-video .modal-content {
  width: 100%;
  height: 100%;
  background: none; }

.modal-video .modal-content .modal-body {
  width: 100%;
  height: 100%;
  background: rgba(25, 25, 25, 0.8); }

.modal-video .modal-content .modal-body .video-wrapper {
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%); }

.modal-video .modal-content .modal-body .close-modal-video {
  position: absolute;
  top: 20px;
  right: 20px;
  color: #ffffff;
  font-size: 40px;
  cursor: pointer;
  -webkit-transition: ease-in-out 0.1s;
  transition: ease-in-out 0.1s; }

.modal-video .modal-content .modal-body .close-modal-video:hover {
  color: #c9c9c9; }

.modal-video .modal-content .modal-body video {
  max-height: calc(100vh - 90px);
  max-width: calc(100vw - 90px); }

.chat-room-message-file-out-wrapper {
  margin-top: 16px;
  padding: 0 24px;
  width: 100%;
  position: relative;
  text-align: right; }
  .chat-room-message-file-out-wrapper .message-out-template-banner {
    margin: 0 !important; }

.chat-room-message-file-out-wrapper .message-out-bubble {
  border-radius: 16px 2px 16px 16px;
  background: #FFF2E5;
  border: 1px solid rgba(25, 25, 25, 0.1);
  display: inline-block;
  max-width: calc(100% - 25px);
  padding: 10px;
  color: #191919;
  font-size: 16px;
  letter-spacing: -0.3px;
  line-height: 24px;
  position: relative;
  text-align: left;
  min-height: 69px; }
  .chat-room-message-file-out-wrapper .message-out-bubble .click-area-file {
    cursor: pointer; }
  .chat-room-message-file-out-wrapper .message-out-bubble .message-title {
    padding: 0 0 10px;
    color: rgba(25, 25, 25, 0.4); }
    .chat-room-message-file-out-wrapper .message-out-bubble .message-title b {
      font-size: 14px;
      letter-spacing: -.4px; }
    .chat-room-message-file-out-wrapper .message-out-bubble .message-title img {
      height: 17px !important;
      width: 16px !important;
      position: relative;
      top: -1px;
      margin-right: 5px !important; }

.chat-room-message-file-out-wrapper .message-out-bubble .file-size-and-extension {
  padding: 0 3px 0 0;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  letter-spacing: -0.2px;
  line-height: 1;
  white-space: nowrap; }

.chat-room-message-file-out-wrapper .message-out-bubble .file-size-and-extension-download-progress {
  min-width: 155px;
  max-width: 100%; }

.chat-room-message-file-out-wrapper .message-out-bubble .reply-message {
  width: calc(100% - 20px);
  border-radius: 4px;
  border-left: solid 5px #FF8F22;
  background: #E88422;
  color: #ffffff;
  position: absolute;
  top: 10px;
  left: 10px;
  padding: 5px 7px 5px 7px;
  line-height: 1; }

.chat-room-message-file-out-wrapper .message-out-bubble .reply-message p {
  margin: 0;
  font-size: 12px;
  letter-spacing: -0.4px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 20px; }

.chat-room-message-file-out-wrapper .message-out-bubble .reply-message p:nth-child(2) {
  letter-spacing: -0.2px; }

.chat-room-message-file-out-wrapper .message-out-bubble .reply-message.reply-file .reply-file-thumbnail {
  display: inline-block;
  width: 40px;
  height: 40px;
  margin-right: 10px;
  position: relative; }

.chat-room-message-file-out-wrapper .message-out-bubble .reply-message.reply-file .reply-file-thumbnail img {
  width: 40px;
  height: 40px; }

.chat-room-message-file-out-wrapper .message-out-bubble .reply-message.reply-file .reply-file-thumbnail svg {
  position: absolute;
  left: 50%;
  top: 50%;
  font-size: 35px;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%); }

.chat-room-message-file-out-wrapper .message-out-bubble .reply-message.reply-file .reply-text-wrapper {
  width: calc(100% - 50px);
  display: inline-block;
  vertical-align: top; }

.chat-room-message-file-out-wrapper .message-out-bubble .replied-file-message {
  width: calc(100% - 20px);
  position: absolute;
  top: 10px;
  left: 10px;
  background: #E87303;
  border-radius: 16px 2px 16px 16px;
  color: #ffffff;
  padding: 10px; }

.chat-room-message-file-out-wrapper .message-out-bubble .file-inner-wrapper {
  position: relative;
  padding-left: 56px;
  padding-top: 3px; }

.chat-room-message-file-out-wrapper .message-out-bubble .file-icon-wrapper {
  height: 32px;
  width: 32px;
  display: inline-block;
  background: var(--main-dark-orange);
  border-radius: 50%;
  vertical-align: top;
  position: relative; }

.chat-room-message-file-out-wrapper .message-out-bubble .file-icon-wrapper svg {
  font-size: 16px;
  color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%); }

.chat-room-message-file-out-wrapper .message-out-bubble .forwarded-message {
  color: #ffffff;
  font-size: 12px;
  letter-spacing: -0.4px;
  line-height: 18px;
  display: block;
  margin-bottom: 5px; }

.chat-room-message-file-out-wrapper .message-out-bubble .message-info {
  margin: 0;
  color: rgba(25, 25, 25, 0.4);
  font-size: 10px;
  letter-spacing: -0.4px;
  line-height: 18px;
  /* width: 24px; */
  width: 100%;
  white-space: nowrap;
  text-align: right; }
  .chat-room-message-file-out-wrapper .message-out-bubble .message-info .centered-dot {
    width: 2px;
    height: 2px;
    display: inline-block;
    vertical-align: top;
    background: rgba(25, 25, 25, 0.4);
    border-radius: 50%;
    position: relative;
    top: 9px; }

.chat-room-message-file-out-wrapper .message-out-bubble .group-sender-name-wrapper {
  color: #ff7d00;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
  font-size: 14px; }

.chat-room-message-file-out-wrapper .message-out-bubble .group-sender-name-wrapper .agent-span {
  color: rgba(25, 25, 25, 0.4); }

.chat-room-message-file-out-wrapper .message-out-bubble .message-info img {
  margin-left: 4px;
  width: 16px;
  position: relative;
  /* top: 4px; */ }

.chat-room-message-file-out-wrapper .message-out-bubble .message-bubble-file-wrapper {
  width: 100%;
  display: inline-block; }

.chat-room-message-file-out-wrapper .message-out-bubble .file-icon-wrapper {
  height: 46px;
  width: 46px;
  display: inline-block;
  background: var(--main-dark-orange);
  border-radius: 50%;
  vertical-align: top;
  position: absolute;
  left: 0;
  top: 0; }

.chat-room-message-file-out-wrapper .message-out-bubble .file-icon-wrapper svg {
  font-size: 25px;
  color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%); }

.chat-room-message-file-out-wrapper .message-out-bubble .file-icon-wrapper .onprocess-wrapper {
  height: 40px;
  width: 40px;
  display: inline-block;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%); }

.chat-room-message-file-out-wrapper .message-out-bubble .message-bubble-file-wrapper p {
  max-width: 100%;
  line-height: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
  display: inline-block;
  white-space: nowrap;
  font-size: 14px;
  letter-spacing: -.2px; }

.chat-room-message-file-out-wrapper .failed-sending-file-warning {
  font-size: 12px;
  letter-spacing: -0.4px;
  color: rgba(25, 25, 25, 0.4); }

.chat-room-message-file-out-wrapper:hover .message-action-wrapper {
  display: inline-block;
  left: -50px; }

.chat-room-message-file-out-wrapper:hover .message-action-dropdown {
  display: inline-block; }

.chat-room-message-file-out-wrapper .CircularProgressbar .CircularProgressbar-trail {
  stroke: #ffffff; }

.chat-room-message-file-out-wrapper .CircularProgressbar .CircularProgressbar-path {
  stroke: #ff7d00; }

.chat-room-message-out-wrapper {
  margin-top: 16px;
  padding: 0 24px;
  width: 100%;
  position: relative;
  text-align: right; }
  .chat-room-message-out-wrapper .location-wrapper {
    margin-bottom: 4px; }
    .chat-room-message-out-wrapper .location-wrapper .location-content {
      cursor: pointer;
      width: 100%;
      height: 180px;
      border-radius: 16px 0 0 0;
      overflow: hidden; }
      .chat-room-message-out-wrapper .location-wrapper .location-content button {
        display: none; }
      .chat-room-message-out-wrapper .location-wrapper .location-content img[src="https://maps.gstatic.com/mapfiles/api-3/images/google_white5.png"] {
        display: none; }
      .chat-room-message-out-wrapper .location-wrapper .location-content span {
        display: none; }
      .chat-room-message-out-wrapper .location-wrapper .location-content a {
        display: none; }
      .chat-room-message-out-wrapper .location-wrapper .location-content .gm-style:first-of-type > div:nth-child(1) {
        cursor: pointer !important; }

.chat-room-message-out-wrapper .message-out-bubble-location {
  border-radius: 16px 2px 16px 16px;
  background: #FFF2E5;
  display: inline-block;
  /* max-width: calc(100% - 180px); */
  max-width: calc(100% - 100px);
  /* padding: 10px 62px 10px 10px; */
  padding: 10px;
  color: #191919;
  font-size: 16px;
  letter-spacing: -0.3px;
  line-height: 24px;
  position: relative;
  text-align: left;
  min-height: 44px; }

.chat-room-message-out-wrapper .message-out-bubble-location.deleted-bubble {
  color: #ffffff;
  padding: 10px 10px 10px 33px; }

.chat-room-message-out-wrapper .message-out-bubble-location.deleted-bubble .deleted-icon {
  position: absolute;
  left: 10px;
  top: 50%;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%); }

.chat-room-message-out-wrapper .message-out-bubble-location .forwarded-message {
  color: #ffffff;
  font-size: 12px;
  letter-spacing: -0.4px;
  line-height: 18px;
  display: block;
  margin-bottom: 5px; }

.chat-room-message-out-wrapper .message-out-bubble-location .reply-message {
  width: calc(100% - 20px);
  border-radius: 4px;
  border-left: solid 5px #FF8F22;
  background: #E88422;
  color: #ffffff;
  position: absolute;
  top: 10px;
  left: 10px;
  padding: 5px 7px 5px 7px;
  line-height: 1; }

.chat-room-message-out-wrapper .message-out-bubble-location .reply-message p {
  margin: 0;
  font-size: 12px;
  letter-spacing: -0.4px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 20px; }

.chat-room-message-out-wrapper .message-out-bubble-location .reply-message p:nth-child(2) {
  letter-spacing: -0.2px; }

.chat-room-message-out-wrapper .message-out-bubble-location .reply-message.reply-file .reply-file-thumbnail {
  display: inline-block;
  width: 40px;
  height: 40px;
  margin-right: 10px;
  position: relative; }

.chat-room-message-out-wrapper .message-out-bubble-location .reply-message.reply-file .reply-file-thumbnail img {
  width: 40px;
  height: 40px; }

.chat-room-message-out-wrapper .message-out-bubble-location .reply-message.reply-file .reply-file-thumbnail svg {
  position: absolute;
  left: 50%;
  top: 50%;
  font-size: 35px;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%); }

.chat-room-message-out-wrapper .message-out-bubble-location .reply-message.reply-file .reply-text-wrapper {
  width: calc(100% - 50px);
  display: inline-block;
  vertical-align: top; }

.chat-room-message-out-wrapper .message-out-bubble-location .message-info {
  /* position: absolute; */
  /* bottom: 11px; */
  /* right: 27px; */
  margin: 0;
  color: rgba(25, 25, 25, 0.4);
  font-size: 10px;
  letter-spacing: -0.4px;
  line-height: 18px;
  /* width: 24px; */
  width: 100%;
  white-space: nowrap;
  text-align: right; }
  .chat-room-message-out-wrapper .message-out-bubble-location .message-info .centered-dot {
    width: 2px;
    height: 2px;
    display: inline-block;
    vertical-align: top;
    background: rgba(25, 25, 25, 0.4);
    border-radius: 50%;
    position: relative;
    top: 9px; }

.chat-room-message-out-wrapper .message-out-bubble-location .group-sender-name-wrapper {
  color: #ff7d00;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
  font-size: 14px; }

.chat-room-message-out-wrapper .message-out-bubble-location .group-sender-name-wrapper .agent-span {
  color: rgba(25, 25, 25, 0.4); }

.chat-room-message-out-wrapper .message-out-bubble-location .message-info img {
  margin-left: 4px;
  width: 16px;
  position: relative;
  /* top: 4px; */ }

.message-action-wrapper {
  position: relative;
  position: absolute;
  top: 50%;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%);
  /* left: -170px; */
  /* left: -90px; */
  left: -90px;
  display: none; }

.message-action-wrapper.message-action-right {
  right: -170px;
  left: unset; }

.message-action-wrapper .forward-message svg {
  -webkit-transform: scaleX(-1);
          transform: scaleX(-1); }

.message-action-wrapper .message-action-button-wrapper {
  border-radius: 50%;
  width: 30px;
  height: 30px;
  text-align: center;
  position: relative;
  display: inline-block;
  background-attachment: fixed;
  margin-left: 10px; }

.message-action-wrapper .message-action-button-wrapper svg {
  color: #9b9b9b;
  position: absolute;
  font-size: 18px;
  left: 6px;
  top: 6px; }

.chat-room-message-out-wrapper:hover .message-action-wrapper {
  display: inline-block; }

.chat-room-message-out-wrapper:hover .message-action-dropdown {
  display: inline-block; }

.message-action-popup-wrapper {
  width: 172px;
  display: inline-block;
  box-shadow: 0px 0px 9px 0px rgba(25, 25, 25, 0.1);
  line-height: 1;
  position: absolute;
  right: 63px;
  top: 0;
  border-radius: 8px;
  z-index: 1; }

.message-action-popup-wrapper .message-action-content {
  width: 100%;
  position: relative;
  padding: 12px 12px 12px 35px;
  font-size: 15px;
  letter-spacing: -0.3px;
  color: #191919;
  border-bottom: solid 1px #dcdcdc;
  background: #ffffff;
  cursor: pointer; }

.message-action-popup-wrapper .message-action-content.delete-message {
  color: #ff3f57; }

.message-action-popup-wrapper .message-action-content.forward-message svg {
  -webkit-transform: rotate(180deg) translate(0, 50%);
          transform: rotate(180deg) translate(0, 50%); }

.message-action-popup-wrapper .message-action-content:hover {
  background: #ff7d00;
  color: #ffffff; }

.message-action-popup-wrapper .message-action-content:hover svg {
  color: #ffffff; }

.message-action-popup-wrapper .message-action-content:nth-child(1) {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px; }

.message-action-popup-wrapper .message-action-content:nth-last-child(1) {
  border: none;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px; }

.message-action-popup-wrapper svg {
  left: 12px;
  font-size: 16px;
  position: absolute;
  top: 50%;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%); }

.chat-room-message-out-wrapper .message-action-wrapper {
  /* right: -120px !important; */
  left: -90px !important; }
  .chat-room-message-out-wrapper .message-action-wrapper.with-forward {
    left: -128px !important; }
    .chat-room-message-out-wrapper .message-action-wrapper.with-forward .forward-button {
      display: inline-block !important; }

.hide-reply-button .message-out-bubble-location .message-action-wrapper {
  left: -45px !important; }
  .hide-reply-button .message-out-bubble-location .message-action-wrapper.with-forward {
    left: -128px !important; }

.chat-room-message-image-interactive-out-wrapper {
  width: 100%;
  padding: 0 24px;
  text-align: right;
  position: relative;
  margin-top: 16px;
  font-size: 0; }
  .chat-room-message-image-interactive-out-wrapper .header-text {
    margin: 0;
    font-size: 16px;
    letter-spacing: -0.3px;
    padding: 0 10px;
    word-break: break-word; }
  .chat-room-message-image-interactive-out-wrapper .body-text {
    margin: 0;
    font-size: 16px;
    letter-spacing: -0.3px;
    padding: 0 10px;
    word-break: break-word; }
  .chat-room-message-image-interactive-out-wrapper .footer-text {
    margin: 0;
    color: #6F6A66;
    font-size: 16px;
    letter-spacing: -0.3px;
    padding: 0 10px;
    word-break: break-word; }
  .chat-room-message-image-interactive-out-wrapper .button-list-wrapper {
    width: 100%;
    padding: 0 10px; }
  .chat-room-message-image-interactive-out-wrapper .button-text {
    height: 32px;
    text-align: center;
    width: 100%;
    border-radius: 8px;
    line-height: 1;
    margin-top: 8px;
    color: var(--main-dark-orange);
    cursor: pointer;
    border: solid 1px var(--main-dark-orange);
    border-radius: 8px;
    font-size: 14px;
    letter-spacing: -0.2px;
    padding: 8px 12px 0; }
    .chat-room-message-image-interactive-out-wrapper .button-text svg {
      position: relative;
      top: 0;
      margin-right: 4px;
      vertical-align: top; }
  .chat-room-message-image-interactive-out-wrapper .buttons-2 .button-text-0 {
    border-radius: 8px 8px 0 0 !important; }
  .chat-room-message-image-interactive-out-wrapper .buttons-2 .button-text-1 {
    border-radius: 0 0 8px 8px !important;
    margin-top: 0px !important;
    border-top: 0 !important; }
  .chat-room-message-image-interactive-out-wrapper .buttons-3 .button-text-0 {
    border-radius: 8px 8px 0 0 !important; }
  .chat-room-message-image-interactive-out-wrapper .buttons-3 .button-text-1 {
    border-radius: 0   !important;
    margin-top: 0px !important;
    border-top: 0 !important; }
  .chat-room-message-image-interactive-out-wrapper .buttons-3 .button-text-2 {
    border-radius: 0 0 8px 8px !important;
    margin-top: 0px !important;
    border-top: 0 !important; }

.chat-room-message-image-interactive-out-wrapper .message-out-bubble-image {
  border-radius: 16px 2px 16px 16px;
  max-width: 300px;
  display: inline-block;
  position: relative;
  font-size: 0;
  border: 1px solid rgba(25, 25, 25, 0.1);
  background: #FFF2E5;
  border: 1px solid rgba(25, 25, 25, 0.1);
  text-align: left;
  color: #191919; }

.chat-room-message-image-interactive-out-wrapper .message-out-bubble-image .bubble-image-wrapper {
  min-width: 150px;
  padding: 10px; }
  .chat-room-message-image-interactive-out-wrapper .message-out-bubble-image .bubble-image-wrapper.bubble-wrapper-with-caption {
    padding: 10px 10px 0; }
  .chat-room-message-image-interactive-out-wrapper .message-out-bubble-image .bubble-image-wrapper img {
    border-radius: 16px 0 16px 16px; }

.chat-room-message-image-interactive-out-wrapper .message-out-bubble-image .bubble-image-wrapper img {
  vertical-align: top;
  width: 100%;
  max-width: 100%; }

.chat-room-message-image-interactive-out-wrapper .message-out-bubble-image .bubble-image-wrapper .icon-status-wrapper {
  border-radius: 50%;
  position: absolute;
  width: 48px;
  height: 48px;
  display: inline-block;
  background: rgba(25, 25, 25, 0.4);
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  cursor: pointer; }

.chat-room-message-image-interactive-out-wrapper .message-out-bubble-image .bubble-image-wrapper .icon-status-wrapper svg {
  color: #ffffff;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  font-size: 20px; }

.chat-room-message-image-interactive-out-wrapper .message-out-bubble-image .bubble-image-wrapper .icon-status-wrapper .onprocess-wrapper {
  height: 40px;
  width: 40px;
  display: inline-block;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%); }

.chat-room-message-image-interactive-out-wrapper .message-out-bubble-image .main-image-message {
  width: 100%;
  max-width: 100%; }

.chat-room-message-image-interactive-out-wrapper .message-out-bubble-image .thumbnail-image-message {
  width: 300px;
  min-width: 278px; }

.modal-image.modal-dialog {
  width: 100vw !important; }

.modal-image .modal-content {
  width: 100%;
  height: 100%;
  background: none; }

.modal-image .modal-content .modal-body {
  width: 100%;
  height: 100%;
  background: rgba(25, 25, 25, 0.8); }

.modal-image .modal-content .modal-body img.image-preview-val {
  max-width: calc(100vw - 130px);
  max-height: calc(100vh - 130px);
  background: #ffffff; }

.modal-image .modal-content .modal-body .image-wrapper {
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  text-align: center; }
  .modal-image .modal-content .modal-body .image-wrapper figure {
    margin: 0; }
    .modal-image .modal-content .modal-body .image-wrapper figure.zoom {
      background-size: 180%;
      background-position: -100% -100%;
      background-repeat: no-repeat;
      position: relative;
      width: 100%;
      overflow: hidden;
      cursor: -webkit-zoom-in;
      cursor: zoom-in; }
      .modal-image .modal-content .modal-body .image-wrapper figure.zoom img {
        -webkit-transition: opacity 0.5s;
        transition: opacity 0.5s;
        display: inline-block; }
        .modal-image .modal-content .modal-body .image-wrapper figure.zoom img:hover {
          opacity: 0; }

.modal-image .modal-content .modal-body .close-modal-image {
  position: absolute;
  top: 20px;
  right: 20px;
  color: #ffffff;
  font-size: 40px;
  cursor: pointer;
  -webkit-transition: ease-in-out 0.1s;
  transition: ease-in-out 0.1s; }

.modal-image .modal-content .modal-body .close-modal-image:hover {
  color: #c9c9c9; }

.chat-room-message-image-interactive-out-wrapper .message-out-bubble-image .caption-text {
  font-size: 16px;
  letter-spacing: -.3px;
  padding: 4px 10px 0 10px;
  margin: 0;
  word-break: break-word; }

.chat-room-message-image-interactive-out-wrapper .message-out-bubble-image .reply-message {
  width: calc(100% - 20px);
  border-radius: 4px;
  border-left: solid 5px #FF8F22;
  background: #E88422;
  color: #191919;
  position: absolute;
  top: 10px;
  left: 10px;
  padding: 5px 7px 5px 7px;
  line-height: 1;
  z-index: 1;
  color: #ffffff; }

.chat-room-message-image-interactive-out-wrapper .message-out-bubble-image .reply-message p {
  margin: 0;
  font-size: 12px;
  letter-spacing: -0.4px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 20px; }

.chat-room-message-image-interactive-out-wrapper .message-out-bubble-image .reply-message p:nth-child(2) {
  letter-spacing: -0.2px; }

.chat-room-message-image-interactive-out-wrapper .message-out-bubble-image .reply-message.reply-file .reply-file-thumbnail {
  display: inline-block;
  width: 40px;
  height: 40px;
  margin-right: 10px;
  position: relative; }

.chat-room-message-image-interactive-out-wrapper .message-out-bubble-image .reply-message.reply-file .reply-file-thumbnail img {
  width: 40px;
  height: 40px; }

.chat-room-message-image-interactive-out-wrapper .message-out-bubble-image .reply-message.reply-file .reply-file-thumbnail svg {
  position: absolute;
  left: 50%;
  top: 50%;
  font-size: 35px;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%); }

.chat-room-message-image-interactive-out-wrapper .message-out-bubble-image .reply-message.reply-file .reply-text-wrapper {
  width: calc(100% - 50px);
  display: inline-block;
  vertical-align: top; }

.chat-room-message-image-interactive-out-wrapper .message-out-bubble-image .message-info {
  position: relative;
  margin: 0;
  white-space: nowrap;
  border-radius: 10px;
  font-size: 10px;
  letter-spacing: -0.4px;
  color: rgba(25, 25, 25, 0.4);
  width: 100%;
  text-align: right;
  padding: 2px 10px 10px; }
  .chat-room-message-image-interactive-out-wrapper .message-out-bubble-image .message-info .centered-dot {
    width: 2px;
    height: 2px;
    display: inline-block;
    vertical-align: top;
    background: rgba(25, 25, 25, 0.4);
    border-radius: 50%;
    position: relative;
    top: 9px; }

.chat-room-message-image-interactive-out-wrapper .message-out-bubble-image .group-sender-name-wrapper {
  color: #ff7d00;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0 0 4px 0;
  font-size: 14px; }

.chat-room-message-image-interactive-out-wrapper .message-out-bubble-image .group-sender-name-wrapper .agent-span {
  color: rgba(25, 25, 25, 0.4); }

.chat-room-message-image-interactive-out-wrapper .message-out-bubble-image .message-info-dark {
  background: rgba(25, 25, 25, 0.6);
  padding: 2px 5px;
  color: #ffffff;
  bottom: 16px;
  right: 16px;
  position: absolute;
  width: unset; }

.chat-room-message-image-interactive-out-wrapper .message-out-bubble-image .message-info img {
  /* vertical-align: top; */
  margin-left: 3px; }

.chat-room-message-image-interactive-out-wrapper .failed-sending-file-warning {
  font-size: 12px;
  letter-spacing: -0.4px;
  color: rgba(25, 25, 25, 0.4); }

.chat-room-message-image-interactive-out-wrapper:hover .message-action-wrapper {
  display: inline-block; }

.chat-room-message-image-interactive-out-wrapper:hover .message-action-dropdown {
  display: inline-block; }

.chat-room-message-image-interactive-out-wrapper .message-action-wrapper {
  left: -45px; }
  .chat-room-message-image-interactive-out-wrapper .message-action-wrapper.with-forward {
    left: -88px !important; }
    .chat-room-message-image-interactive-out-wrapper .message-action-wrapper.with-forward .forward-button {
      display: inline-block !important; }

.chat-room-message-image-interactive-out-wrapper .message-action-wrapper .message-action-button-wrapper {
  cursor: pointer; }

.chat-room-message-image-interactive-out-wrapper .CircularProgressbar .CircularProgressbar-trail {
  stroke: transparent; }

.chat-room-message-image-interactive-out-wrapper .CircularProgressbar .CircularProgressbar-path {
  stroke: #ffffff; }

.chat-room-message-image-interactive-in-wrapper .image-from-url {
  /* max-width: 234px; */
  /* max-height: 300px; */ }

.hide-reply-button .message-out-bubble-image .message-action-wrapper {
  left: -45px !important; }
  .hide-reply-button .message-out-bubble-image .message-action-wrapper.with-forward {
    left: -48px !important; }

.chat-room-message-file-out-wrapper {
  margin-top: 16px;
  padding: 0 24px;
  width: 100%;
  position: relative;
  text-align: right; }
  .chat-room-message-file-out-wrapper .header-text {
    margin: 0;
    font-size: 16px;
    letter-spacing: -0.3px;
    word-break: break-word; }
  .chat-room-message-file-out-wrapper .body-text {
    margin: 0;
    font-size: 16px;
    letter-spacing: -0.3px;
    word-break: break-word; }
  .chat-room-message-file-out-wrapper .footer-text {
    margin: 0;
    color: #6F6A66;
    font-size: 16px;
    letter-spacing: -0.3px;
    word-break: break-word; }
  .chat-room-message-file-out-wrapper .button-list-wrapper {
    width: 100%; }
  .chat-room-message-file-out-wrapper .button-text {
    height: 32px;
    text-align: center;
    width: 100%;
    border-radius: 8px;
    line-height: 1;
    margin-top: 8px;
    color: var(--main-dark-orange);
    cursor: pointer;
    border: solid 1px var(--main-dark-orange);
    border-radius: 8px;
    font-size: 14px;
    letter-spacing: -0.2px;
    padding: 8px 12px 0; }
    .chat-room-message-file-out-wrapper .button-text svg {
      position: relative;
      top: 0;
      margin-right: 4px;
      vertical-align: top; }
  .chat-room-message-file-out-wrapper .buttons-2 .button-text-0 {
    border-radius: 8px 8px 0 0 !important; }
  .chat-room-message-file-out-wrapper .buttons-2 .button-text-1 {
    border-radius: 0 0 8px 8px !important;
    margin-top: 0px !important;
    border-top: 0 !important; }
  .chat-room-message-file-out-wrapper .buttons-3 .button-text-0 {
    border-radius: 8px 8px 0 0 !important; }
  .chat-room-message-file-out-wrapper .buttons-3 .button-text-1 {
    border-radius: 0 !important;
    margin-top: 0px !important;
    border-top: 0 !important; }
  .chat-room-message-file-out-wrapper .buttons-3 .button-text-2 {
    border-radius: 0 0 8px 8px !important;
    margin-top: 0px !important;
    border-top: 0 !important; }

.chat-room-message-file-out-wrapper .message-out-bubble-file {
  border-radius: 16px 2px 16px 16px;
  background: #FFF2E5;
  border: 1px solid rgba(25, 25, 25, 0.1);
  display: inline-block;
  max-width: calc(100% - 25px);
  padding: 10px;
  color: #191919;
  font-size: 16px;
  letter-spacing: -0.3px;
  line-height: 24px;
  position: relative;
  color: #191919;
  text-align: left;
  min-height: 69px; }
  .chat-room-message-file-out-wrapper .message-out-bubble-file .click-area-file {
    cursor: pointer; }

.chat-room-message-file-out-wrapper .message-out-bubble-file .file-size-and-extension {
  padding: 0 3px 0 0;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  letter-spacing: -0.2px;
  line-height: 1;
  white-space: nowrap; }

.chat-room-message-file-out-wrapper .message-out-bubble-file .file-size-and-extension-download-progress {
  min-width: 155px;
  max-width: 100%; }

.chat-room-message-file-out-wrapper .message-out-bubble-file .reply-message {
  width: calc(100% - 20px);
  border-radius: 4px;
  border-left: solid 5px #FF8F22;
  background: #E88422;
  color: #ffffff;
  position: absolute;
  top: 10px;
  left: 10px;
  padding: 5px 7px 5px 7px;
  line-height: 1; }

.chat-room-message-file-out-wrapper .message-out-bubble-file .reply-message p {
  margin: 0;
  font-size: 12px;
  letter-spacing: -0.4px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 20px; }

.chat-room-message-file-out-wrapper .message-out-bubble-file .reply-message p:nth-child(2) {
  letter-spacing: -0.2px; }

.chat-room-message-file-out-wrapper .message-out-bubble-file .reply-message.reply-file .reply-file-thumbnail {
  display: inline-block;
  width: 40px;
  height: 40px;
  margin-right: 10px;
  position: relative; }

.chat-room-message-file-out-wrapper .message-out-bubble-file .reply-message.reply-file .reply-file-thumbnail img {
  width: 40px;
  height: 40px; }

.chat-room-message-file-out-wrapper .message-out-bubble-file .reply-message.reply-file .reply-file-thumbnail svg {
  position: absolute;
  left: 50%;
  top: 50%;
  font-size: 35px;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%); }

.chat-room-message-file-out-wrapper .message-out-bubble-file .reply-message.reply-file .reply-text-wrapper {
  width: calc(100% - 50px);
  display: inline-block;
  vertical-align: top; }

.chat-room-message-file-out-wrapper .message-out-bubble-file .replied-file-message {
  width: calc(100% - 20px);
  position: absolute;
  top: 10px;
  left: 10px;
  background: #E87303;
  border-radius: 16px 2px 16px 16px;
  color: #ffffff;
  padding: 10px; }

.chat-room-message-file-out-wrapper .message-out-bubble-file .file-inner-wrapper {
  position: relative;
  padding-left: 56px;
  padding-top: 3px; }

.chat-room-message-file-out-wrapper .message-out-bubble-file .caption-text {
  font-size: 16px;
  letter-spacing: -.3px;
  padding: 4px 10px 0 0;
  margin: 0;
  word-break: break-word; }

.chat-room-message-file-out-wrapper .message-out-bubble-file .file-icon-wrapper {
  height: 32px;
  width: 32px;
  display: inline-block;
  background: var(--main-dark-orange);
  border-radius: 50%;
  vertical-align: top;
  position: relative; }

.chat-room-message-file-out-wrapper .message-out-bubble-file .file-icon-wrapper svg {
  font-size: 16px;
  color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%); }

.chat-room-message-file-out-wrapper .message-out-bubble-file .forwarded-message {
  color: #ffffff;
  font-size: 12px;
  letter-spacing: -0.4px;
  line-height: 18px;
  display: block;
  margin-bottom: 5px; }

.chat-room-message-file-out-wrapper .message-out-bubble-file .message-info {
  margin: 0;
  color: rgba(25, 25, 25, 0.4);
  font-size: 10px;
  letter-spacing: -0.4px;
  line-height: 18px;
  white-space: nowrap;
  width: 100%;
  text-align: right; }
  .chat-room-message-file-out-wrapper .message-out-bubble-file .message-info .centered-dot {
    width: 2px;
    height: 2px;
    display: inline-block;
    vertical-align: top;
    background: rgba(25, 25, 25, 0.4);
    border-radius: 50%;
    position: relative;
    top: 9px; }

.chat-room-message-file-out-wrapper .message-out-bubble-file .group-sender-name-wrapper {
  color: #ff7d00;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0 0 4px 0;
  font-size: 14px; }

.chat-room-message-file-out-wrapper .message-out-bubble-file .group-sender-name-wrapper .agent-span {
  color: rgba(25, 25, 25, 0.4); }

.chat-room-message-file-out-wrapper .message-out-bubble-file .message-info img {
  margin-left: 4px;
  width: 16px;
  position: relative;
  /* top: 4px; */ }

.chat-room-message-file-out-wrapper .message-out-bubble-file .message-bubble-file-wrapper {
  width: 100%;
  display: inline-block; }

.chat-room-message-file-out-wrapper .message-out-bubble-file .file-icon-wrapper {
  height: 46px;
  width: 46px;
  display: inline-block;
  background: var(--main-dark-orange);
  border-radius: 50%;
  vertical-align: top;
  position: absolute;
  left: 0;
  top: 0; }

.chat-room-message-file-out-wrapper .message-out-bubble-file .file-icon-wrapper svg {
  font-size: 25px;
  color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%); }

.chat-room-message-file-out-wrapper .message-out-bubble-file .file-icon-wrapper .onprocess-wrapper {
  height: 40px;
  width: 40px;
  display: inline-block;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%); }

.chat-room-message-file-out-wrapper .message-out-bubble-file .message-bubble-file-wrapper p {
  max-width: 100%;
  line-height: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
  display: inline-block;
  white-space: nowrap;
  font-size: 14px;
  letter-spacing: -.2px; }

.chat-room-message-file-out-wrapper .failed-sending-file-warning {
  font-size: 12px;
  letter-spacing: -0.4px;
  color: rgba(25, 25, 25, 0.4); }

.chat-room-message-file-out-wrapper:hover .message-action-wrapper {
  display: inline-block;
  left: -50px; }

.chat-room-message-file-out-wrapper:hover .message-action-dropdown {
  display: inline-block; }

.chat-room-message-file-out-wrapper .message-action-wrapper.with-forward {
  left: -88px !important; }
  .chat-room-message-file-out-wrapper .message-action-wrapper.with-forward .forward-button {
    display: inline-block !important; }

.chat-room-message-file-out-wrapper .CircularProgressbar .CircularProgressbar-trail {
  stroke: #ffffff; }

.chat-room-message-file-out-wrapper .CircularProgressbar .CircularProgressbar-path {
  stroke: #ff7d00; }

.hide-reply-button .message-out-bubble-file .message-action-wrapper {
  left: -45px !important; }
  .hide-reply-button .message-out-bubble-file .message-action-wrapper.with-forward {
    left: -48px !important; }

.chat-room-message-video-out-wrapper {
  width: 100%;
  padding: 0 24px;
  text-align: right;
  position: relative;
  margin-top: 16px;
  font-size: 0; }
  .chat-room-message-video-out-wrapper .header-text {
    margin: 0;
    font-size: 16px;
    letter-spacing: -0.3px;
    padding: 0 10px;
    word-break: break-word; }
  .chat-room-message-video-out-wrapper .body-text {
    margin: 0;
    font-size: 16px;
    letter-spacing: -0.3px;
    padding: 0 10px;
    word-break: break-word; }
  .chat-room-message-video-out-wrapper .footer-text {
    margin: 0;
    color: #6F6A66;
    font-size: 16px;
    letter-spacing: -0.3px;
    padding: 0 10px;
    word-break: break-word; }
  .chat-room-message-video-out-wrapper .button-list-wrapper {
    width: 100%;
    padding: 0 10px; }
  .chat-room-message-video-out-wrapper .button-text {
    height: 32px;
    text-align: center;
    width: 100%;
    border-radius: 8px;
    line-height: 1;
    margin-top: 8px;
    color: var(--main-dark-orange);
    cursor: pointer;
    border: solid 1px var(--main-dark-orange);
    border-radius: 8px;
    font-size: 14px;
    letter-spacing: -0.2px;
    padding: 8px 12px 0; }
    .chat-room-message-video-out-wrapper .button-text svg {
      position: relative;
      top: 0;
      margin-right: 4px;
      vertical-align: top; }
  .chat-room-message-video-out-wrapper .buttons-2 .button-text-0 {
    border-radius: 8px 8px 0 0 !important; }
  .chat-room-message-video-out-wrapper .buttons-2 .button-text-1 {
    border-radius: 0 0 8px 8px !important;
    margin-top: 0px !important;
    border-top: 0 !important; }
  .chat-room-message-video-out-wrapper .buttons-3 .button-text-0 {
    border-radius: 8px 8px 0 0 !important; }
  .chat-room-message-video-out-wrapper .buttons-3 .button-text-1 {
    border-radius: 0   !important;
    margin-top: 0px !important;
    border-top: 0 !important; }
  .chat-room-message-video-out-wrapper .buttons-3 .button-text-2 {
    border-radius: 0 0 8px 8px !important;
    margin-top: 0px !important;
    border-top: 0 !important; }

.chat-room-message-video-out-wrapper .message-out-bubble-video {
  border-radius: 16px 2px 16px 16px;
  max-width: 300px;
  display: inline-block;
  position: relative;
  font-size: 0;
  background: #FFF2E5;
  border: 1px solid rgba(25, 25, 25, 0.1);
  text-align: left;
  color: #191919; }

.chat-room-message-video-out-wrapper .message-out-bubble-video .bubble-image-wrapper {
  min-width: 150px;
  padding: 10px; }
  .chat-room-message-video-out-wrapper .message-out-bubble-video .bubble-image-wrapper.bubble-wrapper-with-caption {
    padding: 10px 10px 0; }
  .chat-room-message-video-out-wrapper .message-out-bubble-video .bubble-image-wrapper .video-inner-wrapper {
    position: relative; }
  .chat-room-message-video-out-wrapper .message-out-bubble-video .bubble-image-wrapper video {
    border-radius: 16px 0 16px 16px; }
  .chat-room-message-video-out-wrapper .message-out-bubble-video .bubble-image-wrapper img {
    border-radius: 16px 0 16px 16px; }

.chat-room-message-video-out-wrapper .message-out-bubble-video .bubble-image-wrapper .icon-status-wrapper {
  border-radius: 50%;
  position: absolute;
  width: 48px;
  height: 48px;
  display: inline-block;
  background: rgba(25, 25, 25, 0.4);
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  cursor: pointer; }

.chat-room-message-video-out-wrapper .message-out-bubble-video .bubble-image-wrapper .icon-status-wrapper svg {
  color: #ffffff;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  font-size: 20px; }

.chat-room-message-video-out-wrapper .message-out-bubble-video .bubble-image-wrapper .icon-status-wrapper .onprocess-wrapper {
  height: 40px;
  width: 40px;
  display: inline-block;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%); }

.chat-room-message-video-out-wrapper .message-out-bubble-video .bubble-image-wrapper .video-thumbnail {
  width: 100%; }

.chat-room-message-video-out-wrapper .message-out-bubble-video .main-image-message {
  width: 100%;
  min-width: 278px; }

.chat-room-message-video-out-wrapper .message-out-bubble-video .caption-text {
  font-size: 16px;
  letter-spacing: -.3px;
  padding: 4px 10px 0 10px;
  margin: 0;
  word-break: break-word; }

.chat-room-message-video-out-wrapper .message-out-bubble-video .reply-message {
  width: calc(100% - 20px);
  border-radius: 4px;
  border-left: solid 5px #FF8F22;
  background: #E88422;
  color: #191919;
  position: absolute;
  top: 10px;
  left: 10px;
  padding: 5px 7px 5px 7px;
  line-height: 1;
  z-index: 1;
  color: #ffffff; }

.chat-room-message-video-out-wrapper .message-out-bubble-video .reply-message p {
  margin: 0;
  font-size: 12px;
  letter-spacing: -0.4px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 20px; }

.chat-room-message-video-out-wrapper .message-out-bubble-video .reply-message p:nth-child(2) {
  letter-spacing: -0.2px; }

.chat-room-message-video-out-wrapper .message-out-bubble-video .reply-message.reply-file .reply-file-thumbnail {
  display: inline-block;
  width: 40px;
  height: 40px;
  margin-right: 10px;
  position: relative; }

.chat-room-message-video-out-wrapper .message-out-bubble-video .reply-message.reply-file .reply-file-thumbnail img {
  width: 40px;
  height: 40px; }

.chat-room-message-video-out-wrapper .message-out-bubble-video .reply-message.reply-file .reply-file-thumbnail svg {
  position: absolute;
  left: 50%;
  top: 50%;
  font-size: 35px;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%); }

.chat-room-message-video-out-wrapper .message-out-bubble-video .reply-message.reply-file .reply-text-wrapper {
  width: calc(100% - 50px);
  display: inline-block;
  vertical-align: top; }

.chat-room-message-video-out-wrapper .message-out-bubble-video .message-info {
  position: relative;
  margin: 0;
  white-space: nowrap;
  border-radius: 10px;
  font-size: 10px;
  letter-spacing: -0.4px;
  color: rgba(25, 25, 25, 0.4);
  width: 100%;
  text-align: right;
  padding: 2px 10px 10px; }
  .chat-room-message-video-out-wrapper .message-out-bubble-video .message-info .centered-dot {
    width: 2px;
    height: 2px;
    display: inline-block;
    vertical-align: top;
    background: rgba(25, 25, 25, 0.4);
    border-radius: 50%;
    position: relative;
    top: 9px; }

.chat-room-message-video-out-wrapper .message-out-bubble-video .group-sender-name-wrapper {
  color: #ff7d00;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0 0 4px 0;
  font-size: 14px; }

.chat-room-message-video-out-wrapper .message-out-bubble-video .group-sender-name-wrapper .agent-span {
  color: rgba(25, 25, 25, 0.4); }

.chat-room-message-video-out-wrapper .message-out-bubble-video .message-info-dark {
  background: rgba(25, 25, 25, 0.6);
  padding: 2px 5px;
  color: #ffffff;
  bottom: 16px;
  right: 16px;
  position: absolute;
  width: unset; }

.chat-room-message-video-out-wrapper .message-out-bubble-video .message-info img {
  /* vertical-align: top; */
  margin-left: 3px; }

.chat-room-message-video-out-wrapper .message-out-bubble-video .timer-filesize-wrapper {
  background: rgba(25, 25, 25, 0.6);
  position: absolute;
  white-space: nowrap;
  border-radius: 10px;
  font-size: 12px;
  padding: 2px 5px;
  color: rgba(255, 255, 255, 0.6);
  top: 10px;
  left: 10px;
  z-index: 1; }

.chat-room-message-video-out-wrapper .message-out-bubble-video .play-button-wraper {
  border-radius: 50%;
  position: absolute;
  width: 48px;
  height: 48px;
  display: inline-block;
  background: rgba(25, 25, 25, 0.4);
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  cursor: pointer; }

.chat-room-message-video-out-wrapper .message-out-bubble-video .play-button-wraper svg {
  color: #ffffff;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  font-size: 20px; }

.chat-room-message-video-out-wrapper:hover .message-action-wrapper {
  display: inline-block; }

.chat-room-message-video-out-wrapper:hover .message-action-dropdown {
  display: inline-block; }

.chat-room-message-video-out-wrapper .message-action-wrapper {
  left: -45px; }
  .chat-room-message-video-out-wrapper .message-action-wrapper.with-forward {
    left: -88px !important; }
    .chat-room-message-video-out-wrapper .message-action-wrapper.with-forward .forward-button {
      display: inline-block !important; }

.chat-room-message-video-out-wrapper .message-action-wrapper .message-action-button-wrapper {
  cursor: pointer; }

.chat-room-message-video-out-wrapper .CircularProgressbar .CircularProgressbar-trail {
  stroke: transparent; }

.chat-room-message-video-out-wrapper .CircularProgressbar .CircularProgressbar-path {
  stroke: #ffffff; }

.modal-video.modal-dialog {
  width: 100vw !important; }

.modal-video .modal-content {
  width: 100%;
  height: 100%;
  background: none; }

.modal-video .modal-content .modal-body {
  width: 100%;
  height: 100%;
  background: rgba(25, 25, 25, 0.8); }

.modal-video .modal-content .modal-body .video-wrapper {
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%); }

.modal-video .modal-content .modal-body .close-modal-video {
  position: absolute;
  top: 20px;
  right: 20px;
  color: #ffffff;
  font-size: 40px;
  cursor: pointer;
  -webkit-transition: ease-in-out 0.1s;
  transition: ease-in-out 0.1s; }

.modal-video .modal-content .modal-body .close-modal-video:hover {
  color: #c9c9c9; }

.modal-video .modal-content .modal-body video {
  max-height: calc(100vh - 90px);
  max-width: calc(100vw - 90px); }

.hide-reply-button .message-out-bubble-video .message-action-wrapper {
  left: -45px !important; }
  .hide-reply-button .message-out-bubble-video .message-action-wrapper.with-forward {
    left: -48px !important; }

.chat-room-message-out-email-wrapper {
  margin-top: 16px;
  padding: 0 24px;
  width: 100%;
  position: relative;
  text-align: right; }
  .chat-room-message-out-email-wrapper .link-preview-side-decoration-out {
    background-color: var(--main-dark-orange); }
  .chat-room-message-out-email-wrapper .link-preview-title-out {
    color: #191919; }
  .chat-room-message-out-email-wrapper .link-preview-description-out {
    color: #191919; }

.chat-room-message-out-email-wrapper .message-out-bubble {
  border-radius: 16px 2px 16px 16px;
  border: 1px solid rgba(25, 25, 25, 0.1);
  background: #fff;
  display: inline-block;
  width: 100%;
  padding: 12px;
  color: #191919;
  font-size: 16px;
  letter-spacing: -0.3px;
  line-height: 24px;
  position: relative;
  text-align: left;
  min-height: 44px; }
  .chat-room-message-out-email-wrapper .message-out-bubble .message-out-email-wrapper-top {
    position: relative;
    width: 100%;
    padding: 0 82px 10px 48px;
    border-bottom: solid 1px var(--main-dark-orange); }
    .chat-room-message-out-email-wrapper .message-out-bubble .message-out-email-wrapper-top.message-out-email-wrapper-top-blue {
      border-bottom: solid 1px #2085cc; }
    .chat-room-message-out-email-wrapper .message-out-bubble .message-out-email-wrapper-top .top-avatar-wrapper {
      position: relative;
      width: 40px;
      height: 40px;
      display: inline-block;
      border-radius: 50%;
      background: rgba(25, 25, 25, 0.2);
      position: absolute;
      left: 0;
      top: 0; }
      .chat-room-message-out-email-wrapper .message-out-bubble .message-out-email-wrapper-top .top-avatar-wrapper .avatar-icon-badge {
        width: 20px;
        bottom: -2px;
        right: -1px;
        height: 20px;
        position: absolute; }
      .chat-room-message-out-email-wrapper .message-out-bubble .message-out-email-wrapper-top .top-avatar-wrapper b {
        color: #fff;
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%); }
      .chat-room-message-out-email-wrapper .message-out-bubble .message-out-email-wrapper-top .top-avatar-wrapper .other-agent-icon {
        position: absolute;
        bottom: -1px;
        right: -1px;
        width: 16px;
        height: 16px;
        border-radius: 50%;
        border: solid 2px #fff;
        background: #0052ff;
        padding: 2px; }
        .chat-room-message-out-email-wrapper .message-out-bubble .message-out-email-wrapper-top .top-avatar-wrapper .other-agent-icon img {
          position: absolute;
          top: 50%;
          left: 50%;
          -webkit-transform: translate(-50%, -50%);
                  transform: translate(-50%, -50%);
          width: 8px; }
    .chat-room-message-out-email-wrapper .message-out-bubble .message-out-email-wrapper-top .top-info-wrapper {
      height: 50px; }
    .chat-room-message-out-email-wrapper .message-out-bubble .message-out-email-wrapper-top .top-name {
      font-size: 14px;
      letter-spacing: -0.4px;
      margin: 0 0 2px 0;
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      line-height: 16px;
      white-space: nowrap;
      color: rgba(25, 25, 25, 0.4); }
    .chat-room-message-out-email-wrapper .message-out-bubble .message-out-email-wrapper-top .top-subject {
      font-size: 12px;
      letter-spacing: -0.2px;
      margin: 0 0 2px 0;
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      color: rgba(25, 25, 25, 0.6);
      line-height: 16px;
      white-space: nowrap; }
    .chat-room-message-out-email-wrapper .message-out-bubble .message-out-email-wrapper-top .top-info {
      font-size: 12px;
      margin: 0;
      letter-spacing: -0.2px;
      margin: 0 0 2px 0;
      color: rgba(25, 25, 25, 0.6);
      line-height: 16px;
      position: relative;
      padding-right: 16px;
      display: inline-block;
      max-width: 100%; }
      .chat-room-message-out-email-wrapper .message-out-bubble .message-out-email-wrapper-top .top-info .top-info-name {
        white-space: nowrap;
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis; }
      .chat-room-message-out-email-wrapper .message-out-bubble .message-out-email-wrapper-top .top-info .dropdown {
        position: absolute;
        top: -1px;
        right: 0;
        margin-left: 2px;
        box-shadow: none !important; }
        .chat-room-message-out-email-wrapper .message-out-bubble .message-out-email-wrapper-top .top-info .dropdown button {
          line-height: 1;
          padding: 0;
          background: transparent !important;
          border: none !important;
          box-shadow: none !important; }
        .chat-room-message-out-email-wrapper .message-out-bubble .message-out-email-wrapper-top .top-info .dropdown .dropdown-menu {
          padding: 16px 16px 12px 16px;
          font-size: 12px;
          letter-spacing: -0.2px;
          width: 320px;
          border-radius: 8px;
          margin: 0;
          overflow-y: auto;
          max-height: 270px; }
          .chat-room-message-out-email-wrapper .message-out-bubble .message-out-email-wrapper-top .top-info .dropdown .dropdown-menu button {
            box-shadow: none !important; }
    .chat-room-message-out-email-wrapper .message-out-bubble .message-out-email-wrapper-top .top-info-arrow {
      font-size: 14px;
      color: rgba(25, 25, 25, 0.6);
      -webkit-transition: ease-in-out .1s;
      transition: ease-in-out .1s; }
      .chat-room-message-out-email-wrapper .message-out-bubble .message-out-email-wrapper-top .top-info-arrow:hover {
        color: var(--main-dark-orange); }
    .chat-room-message-out-email-wrapper .message-out-bubble .message-out-email-wrapper-top .message-info {
      position: absolute;
      top: 0;
      right: 0;
      display: inline-block; }
  .chat-room-message-out-email-wrapper .message-out-bubble .message-out-email-wrapper-bottom {
    width: 100%;
    position: relative;
    padding: 12px 0 0; }

.chat-room-message-out-email-wrapper .message-out-bubble.deleted-bubble {
  color: #ffffff;
  padding: 10px 10px 10px 33px; }

.chat-room-message-out-email-wrapper .message-out-bubble.deleted-bubble .deleted-icon {
  position: absolute;
  left: 10px;
  top: 50%;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%); }

.chat-room-message-out-email-wrapper .message-out-bubble .forwarded-message {
  color: #ffffff;
  font-size: 12px;
  letter-spacing: -0.4px;
  line-height: 18px;
  display: block;
  margin-bottom: 5px; }

.chat-room-message-out-email-wrapper .message-out-bubble .reply-message {
  width: calc(100% - 20px);
  border-radius: 4px;
  border-left: solid 5px #FF8F22;
  background: #E88422;
  color: #ffffff;
  position: absolute;
  top: 10px;
  left: 10px;
  padding: 5px 7px 5px 7px;
  line-height: 1; }

.chat-room-message-out-email-wrapper .message-out-bubble .reply-message p {
  margin: 0;
  font-size: 12px;
  letter-spacing: -0.4px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 20px; }

.chat-room-message-out-email-wrapper .message-out-bubble .reply-message p:nth-child(2) {
  letter-spacing: -0.2px; }

.chat-room-message-out-email-wrapper .message-out-bubble .reply-message.reply-file .reply-file-thumbnail {
  display: inline-block;
  width: 40px;
  height: 40px;
  margin-right: 10px;
  position: relative; }

.chat-room-message-out-email-wrapper .message-out-bubble .reply-message.reply-file .reply-file-thumbnail img {
  width: 40px;
  height: 40px; }

.chat-room-message-out-email-wrapper .message-out-bubble .reply-message.reply-file .reply-file-thumbnail svg {
  position: absolute;
  left: 50%;
  top: 50%;
  font-size: 35px;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%); }

.chat-room-message-out-email-wrapper .message-out-bubble .reply-message.reply-file .reply-text-wrapper {
  width: calc(100% - 50px);
  display: inline-block;
  vertical-align: top; }

.chat-room-message-out-email-wrapper .message-out-bubble .message-info {
  /* position: absolute; */
  /* bottom: 11px; */
  /* right: 27px; */
  margin: 0;
  color: rgba(25, 25, 25, 0.4);
  letter-spacing: -0.4px;
  font-size: 10px;
  line-height: 18px;
  /* width: 24px; */
  width: 100%;
  white-space: nowrap;
  text-align: right; }
  .chat-room-message-out-email-wrapper .message-out-bubble .message-info .centered-dot {
    width: 2px;
    height: 2px;
    display: inline-block;
    vertical-align: top;
    background: rgba(25, 25, 25, 0.4);
    border-radius: 50%;
    position: relative;
    top: 9px; }

.chat-room-message-out-email-wrapper .message-out-bubble .group-sender-name-wrapper {
  color: #ff7d00;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
  font-size: 14px; }

.chat-room-message-out-email-wrapper .message-out-bubble .group-sender-name-wrapper .agent-span {
  color: rgba(25, 25, 25, 0.4); }

.chat-room-message-out-email-wrapper .message-out-bubble .message-info img {
  margin-left: 4px;
  width: 16px;
  position: relative;
  /* top: 4px; */ }

.message-action-wrapper {
  position: relative;
  position: absolute;
  top: 50%;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%);
  /* left: -170px; */
  /* left: -90px; */
  left: -90px;
  display: none; }

.message-action-wrapper.message-action-right {
  right: -170px;
  left: unset; }

.message-action-wrapper .forward-message svg {
  -webkit-transform: scaleX(-1);
          transform: scaleX(-1); }

.message-action-wrapper .message-action-button-wrapper {
  border-radius: 50%;
  width: 30px;
  height: 30px;
  text-align: center;
  position: relative;
  display: inline-block;
  background-attachment: fixed;
  margin-left: 10px; }

.message-action-wrapper .message-action-button-wrapper svg {
  color: #9b9b9b;
  position: absolute;
  font-size: 18px;
  left: 6px;
  top: 6px; }

.chat-room-message-out-email-wrapper:hover .message-action-wrapper {
  display: inline-block; }

.message-action-popup-wrapper {
  width: 172px;
  display: inline-block;
  box-shadow: 0px 0px 9px 0px rgba(25, 25, 25, 0.1);
  line-height: 1;
  position: absolute;
  right: 63px;
  top: 0;
  border-radius: 8px;
  z-index: 1; }

.message-action-popup-wrapper .message-action-content {
  width: 100%;
  position: relative;
  padding: 12px 12px 12px 35px;
  font-size: 15px;
  letter-spacing: -0.3px;
  color: #191919;
  border-bottom: solid 1px #dcdcdc;
  background: #ffffff;
  cursor: pointer; }

.message-action-popup-wrapper .message-action-content.delete-message {
  color: #ff3f57; }

.message-action-popup-wrapper .message-action-content.forward-message svg {
  -webkit-transform: rotate(180deg) translate(0, 50%);
          transform: rotate(180deg) translate(0, 50%); }

.message-action-popup-wrapper .message-action-content:hover {
  background: #ff7d00;
  color: #ffffff; }

.message-action-popup-wrapper .message-action-content:hover svg {
  color: #ffffff; }

.message-action-popup-wrapper .message-action-content:nth-child(1) {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px; }

.message-action-popup-wrapper .message-action-content:nth-last-child(1) {
  border: none;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px; }

.message-action-popup-wrapper svg {
  left: 12px;
  font-size: 16px;
  position: absolute;
  top: 50%;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%); }

.chat-room-message-out-email-wrapper .message-action-wrapper {
  /* right: -120px !important; */
  left: -90px !important; }
  .chat-room-message-out-email-wrapper .message-action-wrapper.with-forward {
    left: -128px !important; }
    .chat-room-message-out-email-wrapper .message-action-wrapper.with-forward .forward-button {
      display: inline-block !important; }

.hide-reply-button .message-out-bubble .message-action-wrapper {
  left: -45px !important; }
  .hide-reply-button .message-out-bubble .message-action-wrapper.with-forward {
    left: -88px !important; }

.chat-room-input-message-wrapper {
  box-shadow: 0 2px 20px 0 rgba(25, 25, 25, 0.18);
  background: #fff;
  position: absolute;
  width: 100%;
  bottom: 0;
  padding: 7px 0 4px; }
  .chat-room-input-message-wrapper .quick-reply-document-wrapper {
    height: 68px;
    width: 100%;
    background: #fff;
    padding: 10px 14px 10px 98px;
    position: absolute;
    top: -69px;
    left: 0;
    border-bottom: solid 1px rgba(25, 25, 25, 0.1); }
    .chat-room-input-message-wrapper .quick-reply-document-wrapper .clear-quick-reply-document {
      font-size: 22px;
      position: absolute;
      left: 10px;
      top: 22px;
      color: rgba(25, 25, 25, 0.2);
      cursor: pointer; }
    .chat-room-input-message-wrapper .quick-reply-document-wrapper .quick-reply-document-icon {
      background: var(--main-dark-orange);
      width: 48px;
      height: 48px;
      border-radius: 50%;
      position: absolute;
      left: 40px;
      top: 10px; }
      .chat-room-input-message-wrapper .quick-reply-document-wrapper .quick-reply-document-icon svg {
        color: #fff;
        font-size: 22px;
        position: absolute;
        left: 50%;
        top: 50%;
        -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%); }
    .chat-room-input-message-wrapper .quick-reply-document-wrapper p {
      font-size: 12px;
      letter-spacing: -0.4px;
      position: relative;
      top: 14px;
      white-space: nowrap;
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis; }
  .chat-room-input-message-wrapper .icon-salestalk {
    position: absolute;
    top: 10px;
    right: 80px;
    z-index: 1;
    width: 18px;
    cursor: pointer; }
    .chat-room-input-message-wrapper .icon-salestalk.icon-salestalk-orange {
      display: none; }
      .chat-room-input-message-wrapper .icon-salestalk.icon-salestalk-orange:hover {
        display: block !important; }
    .chat-room-input-message-wrapper .icon-salestalk:hover + .icon-salestalk-orange {
      display: block; }
    .chat-room-input-message-wrapper .icon-salestalk.icon-salestalk-46 {
      right: 46px; }
  .chat-room-input-message-wrapper .cancel-start-conversation {
    position: absolute;
    left: 25px;
    bottom: 18px;
    color: rgba(25, 25, 25, 0.7);
    font-size: 18px;
    z-index: 1;
    cursor: pointer; }
    .chat-room-input-message-wrapper .cancel-start-conversation:hover {
      color: #191919; }
  .chat-room-input-message-wrapper .start-conversation-image-video-priview-small-box {
    border-radius: 8px;
    width: 38px;
    height: 38px;
    background: #c9c9c9;
    position: absolute;
    left: 52px;
    z-index: 1;
    bottom: 8px;
    cursor: pointer; }
    .chat-room-input-message-wrapper .start-conversation-image-video-priview-small-box img {
      position: absolute;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%);
      max-width: 100%;
      max-height: 100%; }
    .chat-room-input-message-wrapper .start-conversation-image-video-priview-small-box video {
      position: absolute;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%);
      max-width: 100%;
      max-height: 100%; }
      .chat-room-input-message-wrapper .start-conversation-image-video-priview-small-box video .icon-play-mini {
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
        max-width: 100%;
        max-height: 100%; }
    .chat-room-input-message-wrapper .start-conversation-image-video-priview-small-box .icon-file-mini {
      color: #fff;
      font-size: 20px;
      position: absolute;
      left: 50%;
      top: 50%;
      -webkit-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%); }
  .chat-room-input-message-wrapper .button-send-message {
    font-size: 14px;
    letter-spacing: -0.4px;
    height: 32px;
    line-height: 29px;
    border-radius: 100px;
    position: absolute;
    z-index: 1;
    bottom: 11px;
    right: 24px; }
    .chat-room-input-message-wrapper .button-send-message.take-over-send {
      padding: 0 11px 0 16px; }
    .chat-room-input-message-wrapper .button-send-message.assign-me-send {
      padding: 0 11px 0 16px; }
    .chat-room-input-message-wrapper .button-send-message.just-send {
      padding: 0 11px 0 16px; }
    .chat-room-input-message-wrapper .button-send-message img {
      position: relative;
      margin-left: 4px;
      top: -2px; }
    .chat-room-input-message-wrapper .button-send-message .lds-ring {
      margin: 0;
      top: 4px;
      right: unset; }
      .chat-room-input-message-wrapper .button-send-message .lds-ring div {
        margin: 0;
        border-color: #ffffff transparent transparent transparent; }
    .chat-room-input-message-wrapper .button-send-message .lds-ring {
      margin-left: 4px; }
  .chat-room-input-message-wrapper .chat-room-textarea-wrapper {
    display: inline-block;
    position: relative;
    margin-right: 16px;
    width: 100%; }
    .chat-room-input-message-wrapper .chat-room-textarea-wrapper .emoji-picker-outer-wrapper {
      position: relative;
      width: 20px;
      height: 20px;
      display: inline-block;
      position: absolute;
      top: 9px;
      right: 46px; }
      .chat-room-input-message-wrapper .chat-room-textarea-wrapper .emoji-picker-outer-wrapper .emoji-picker-main-wrapper {
        position: absolute;
        padding-bottom: 22px;
        bottom: 15px;
        right: -55px;
        -webkit-transition: opacity .2s;
        transition: opacity .2s;
        opacity: 0;
        visibility: hidden; }
        .chat-room-input-message-wrapper .chat-room-textarea-wrapper .emoji-picker-outer-wrapper .emoji-picker-main-wrapper:hover {
          opacity: 1;
          visibility: visible !important; }
      .chat-room-input-message-wrapper .chat-room-textarea-wrapper .emoji-picker-outer-wrapper .emoji-toggle-button {
        top: unset;
        right: unset;
        font-size: 20px; }
        .chat-room-input-message-wrapper .chat-room-textarea-wrapper .emoji-picker-outer-wrapper .emoji-toggle-button:hover ~ .emoji-picker-main-wrapper {
          opacity: 1 !important;
          visibility: visible !important; }
      .chat-room-input-message-wrapper .chat-room-textarea-wrapper .emoji-picker-outer-wrapper.emoji-picker-outer-wrapper-16 {
        right: 16px; }
    .chat-room-input-message-wrapper .chat-room-textarea-wrapper textarea.main-textarea-input {
      z-index: 1;
      max-height: 138px;
      width: 100%;
      font-size: 16px;
      letter-spacing: -0.3px;
      resize: none;
      position: relative;
      top: -2px;
      scrollbar-width: none;
      border-radius: 20px;
      border: none;
      box-shadow: 0 0 0 1px rgba(25, 25, 25, 0.1);
      padding: 9px 105px 9px 16px;
      box-sizing: border-box;
      max-height: 138px; }
      .chat-room-input-message-wrapper .chat-room-textarea-wrapper textarea.main-textarea-input::-webkit-scrollbar {
        display: none; }
      .chat-room-input-message-wrapper .chat-room-textarea-wrapper textarea.main-textarea-input:focus {
        box-shadow: 0 0 0 1px #ff7d00; }
      .chat-room-input-message-wrapper .chat-room-textarea-wrapper textarea.main-textarea-input:-moz-read-only {
        cursor: not-allowed; }
      .chat-room-input-message-wrapper .chat-room-textarea-wrapper textarea.main-textarea-input:read-only {
        cursor: not-allowed; }
    .chat-room-input-message-wrapper .chat-room-textarea-wrapper svg {
      color: rgba(25, 25, 25, 0.6);
      position: absolute;
      right: 16px;
      top: 11px;
      font-size: 18px;
      cursor: pointer;
      z-index: 1; }
      .chat-room-input-message-wrapper .chat-room-textarea-wrapper svg:hover {
        color: #ff7d00; }
    .chat-room-input-message-wrapper .chat-room-textarea-wrapper svg.active-paperclip {
      color: #ff7d00; }
  .chat-room-input-message-wrapper .chat-send-wrapper {
    width: 32px;
    height: 32px;
    text-align: center;
    border-radius: 50%;
    background: #ff7d00;
    position: absolute;
    right: 14px;
    bottom: 10px;
    display: inline-block;
    cursor: pointer;
    -webkit-transition: ease-in-out 0.1s;
    transition: ease-in-out 0.1s;
    border: none; }
    .chat-room-input-message-wrapper .chat-send-wrapper:hover {
      background: #ef7702; }
    .chat-room-input-message-wrapper .chat-send-wrapper img {
      position: absolute;
      left: 50%;
      top: 50%;
      -webkit-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%); }
  .chat-room-input-message-wrapper form {
    width: calc(100% - 72px);
    margin-left: 12px;
    display: inline-block;
    -webkit-transition: ease-in-out 0.2s;
    transition: ease-in-out 0.2s; }
    .chat-room-input-message-wrapper form.start-conversation-active {
      width: calc(100% - 24px); }
      .chat-room-input-message-wrapper form.start-conversation-active textarea.main-textarea-input {
        padding-top: 9px;
        padding-right: 0;
        padding-bottom: 9px;
        padding-left: 36px; }
  .chat-room-input-message-wrapper.just-send textarea.main-textarea-input {
    padding-right: 106px !important; }
  .chat-room-input-message-wrapper.assign-me-send textarea.main-textarea-input {
    padding-right: 194px !important; }
  .chat-room-input-message-wrapper.take-over-send textarea.main-textarea-input {
    padding-right: 194px !important; }
  .chat-room-input-message-wrapper.start-conversation-with-image-or-video textarea.main-textarea-input {
    padding-left: 91px !important; }

.input-file-wrapper-chat {
  width: 167px;
  display: inline-block;
  box-shadow: 0px 0px 9px 0px rgba(25, 25, 25, 0.1);
  line-height: 1;
  position: absolute;
  right: 0;
  bottom: 35px;
  border-radius: 8px;
  z-index: 1; }
  .input-file-wrapper-chat input[type=file] {
    display: none; }
  .input-file-wrapper-chat .input-file-content {
    width: 100%;
    position: relative;
    padding: 12px 12px 12px 34px;
    font-size: 14px;
    letter-spacing: -0.4px;
    color: rgba(25, 25, 25, 0.6);
    background: #fff;
    cursor: pointer; }
    .input-file-wrapper-chat .input-file-content img {
      position: absolute;
      top: 11px;
      left: 12px; }
    .input-file-wrapper-chat .input-file-content:hover {
      background: #ededed; }
      .input-file-wrapper-chat .input-file-content:hover svg {
        color: #fff; }
  .input-file-wrapper-chat label {
    width: 100%;
    margin: 0; }
    .input-file-wrapper-chat label:nth-child(1) .input-file-content {
      border-top-left-radius: 8px;
      border-top-right-radius: 8px; }
    .input-file-wrapper-chat label:nth-last-child(1) .input-file-content {
      border: none;
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px; }
  .input-file-wrapper-chat svg {
    left: 12px;
    font-size: 20px; }

.modal-media.modal-dialog {
  width: 100vw !important;
  position: relative; }

.modal-media .modal-content {
  width: 100%;
  height: 100%;
  background: none; }
  .modal-media .modal-content .modal-body {
    width: 100%;
    height: 100%;
    background: #191919;
    padding: 0;
    position: relative; }
    .modal-media .modal-content .modal-body .preview-arrow-navigator {
      font-size: 48px;
      color: #a3a3a3;
      position: absolute;
      top: 40%;
      -webkit-transform: translate(0, -50%);
              transform: translate(0, -50%);
      cursor: pointer;
      -webkit-transition: ease-in-out 0.1s;
      transition: ease-in-out 0.1s; }
      .modal-media .modal-content .modal-body .preview-arrow-navigator:hover {
        color: #fff; }
    .modal-media .modal-content .modal-body .preview-arrow-navigator.left-preview-navigator {
      left: 30px; }
    .modal-media .modal-content .modal-body .preview-arrow-navigator.right-preview-navigator {
      right: 30px; }
    .modal-media .modal-content .modal-body .drop-file-here-wrapper {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      display: none;
      text-align: center;
      font-size: 24px;
      letter-spacing: -0.5px;
      background: #eaeaea;
      z-index: 3;
      opacity: 0.9; }
      .modal-media .modal-content .modal-body .drop-file-here-wrapper svg {
        font-size: 48px;
        margin-bottom: 16px; }
      .modal-media .modal-content .modal-body .drop-file-here-wrapper p {
        margin: 0; }
      .modal-media .modal-content .modal-body .drop-file-here-wrapper .drop-file-here-content {
        width: 80%;
        height: 80%;
        padding: 40px;
        border: dashed 4px #191919;
        display: inline-block;
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
        line-height: 80%;
        color: #191919; }
        .modal-media .modal-content .modal-body .drop-file-here-wrapper .drop-file-here-content .drop-file-here-inner-content {
          width: 100%;
          position: absolute;
          top: 50%;
          left: 50%;
          -webkit-transform: translate(-50%, -50%);
                  transform: translate(-50%, -50%);
          padding: 24px; }
    .modal-media .modal-content .modal-body .drop-file-here-wrapper.active-drop-file-here-wrapper {
      display: block; }
    .modal-media .modal-content .modal-body .video-image-wrapper {
      display: inline-block;
      position: absolute;
      top: 43%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%);
      max-width: 90vw;
      min-width: 450px; }
      .modal-media .modal-content .modal-body .video-image-wrapper .preview-container {
        text-align: center; }
        .modal-media .modal-content .modal-body .video-image-wrapper .preview-container .blocking-image-preview {
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          background: transparent; }
        .modal-media .modal-content .modal-body .video-image-wrapper .preview-container img {
          max-width: calc(100vw - 170px);
          max-height: calc(100vh - 320px);
          pointer-events: none; }
        .modal-media .modal-content .modal-body .video-image-wrapper .preview-container video {
          max-width: calc(100vw - 170px);
          max-height: calc(100vh - 320px); }
      .modal-media .modal-content .modal-body .video-image-wrapper .caption-container {
        position: relative; }
        .modal-media .modal-content .modal-body .video-image-wrapper .caption-container textarea {
          resize: none;
          background: rgba(25, 25, 25, 0.4);
          max-height: 71px;
          overflow: auto;
          width: 100%;
          text-overflow: ellipsis;
          border: none;
          height: 71px;
          color: #fff;
          box-shadow: none;
          padding: 8px 0 8px 0;
          border-radius: 0;
          font-size: 16px;
          letter-spacing: -0.3px;
          border-bottom: solid 1px #dcdcdc; }
          .modal-media .modal-content .modal-body .video-image-wrapper .caption-container textarea::-webkit-input-placeholder {
            color: #fff; }
          .modal-media .modal-content .modal-body .video-image-wrapper .caption-container textarea::-ms-input-placeholder {
            color: #fff; }
          .modal-media .modal-content .modal-body .video-image-wrapper .caption-container textarea::placeholder {
            color: #fff; }
          .modal-media .modal-content .modal-body .video-image-wrapper .caption-container textarea::-webkit-scrollbar {
            display: none; }
        .modal-media .modal-content .modal-body .video-image-wrapper .caption-container .airplane-icon {
          position: absolute;
          right: 0;
          top: 50%;
          -webkit-transform: translate(0, -50%);
                  transform: translate(0, -50%);
          cursor: pointer;
          -webkit-transition: ease-in-out 0.3s;
          transition: ease-in-out 0.3s; }
          .modal-media .modal-content .modal-body .video-image-wrapper .caption-container .airplane-icon:hover {
            -webkit-transform: translate(0, -50%) scale(0.95);
                    transform: translate(0, -50%) scale(0.95); }
        .modal-media .modal-content .modal-body .video-image-wrapper .caption-container .caption-limit-text {
          position: absolute;
          color: #fff;
          right: 0;
          font-size: 11px;
          bottom: -18px; }
    .modal-media .modal-content .modal-body .close-modal-video {
      position: absolute;
      top: 20px;
      left: 16px;
      color: #fff;
      font-size: 40px;
      cursor: pointer;
      -webkit-transition: ease-in-out 0.1s;
      transition: ease-in-out 0.1s; }
      .modal-media .modal-content .modal-body .close-modal-video:hover {
        color: #c9c9c9; }

.modal-media .selected-file-wrapper {
  width: 100%;
  height: 120px;
  box-shadow: 0 -1px 0 0 rgba(255, 255, 255, 0.2);
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 12px 312px 12px 106px; }
  .modal-media .selected-file-wrapper .file-counter {
    letter-spacing: -0.6px;
    font-size: 24px;
    color: #fff;
    left: 32px;
    top: 42px;
    position: absolute; }
  .modal-media .selected-file-wrapper .selected-file-container {
    position: relative;
    height: 100%;
    text-align: right;
    max-width: 100%;
    white-space: nowrap;
    overflow-x: auto; }
    .modal-media .selected-file-wrapper .selected-file-container label[for="media-input"] {
      height: 100%;
      width: 96px;
      margin: 0; }
    .modal-media .selected-file-wrapper .selected-file-container [type="file"] {
      display: none; }
    .modal-media .selected-file-wrapper .selected-file-container .add-more-file-button {
      font-size: 12px;
      letter-spacing: -0.2px;
      color: #fff;
      width: 96px;
      height: 100%;
      border-radius: 8px;
      border: solid 1px #fff;
      text-align: center;
      display: inline-block;
      vertical-align: top;
      padding-top: 24px;
      cursor: pointer;
      -webkit-transition: ease-in-out 0.1s;
      transition: ease-in-out 0.1s; }
      .modal-media .selected-file-wrapper .selected-file-container .add-more-file-button:hover {
        border: solid 1px #ff7d00; }
      .modal-media .selected-file-wrapper .selected-file-container .add-more-file-button svg {
        font-size: 30px; }
      .modal-media .selected-file-wrapper .selected-file-container .add-more-file-button img {
        margin-bottom: 8px; }
    .modal-media .selected-file-wrapper .selected-file-container .selected-file-content {
      vertical-align: top;
      width: 96px;
      height: 100%;
      border-radius: 8px;
      text-align: center;
      margin-left: 12px;
      display: inline-block;
      cursor: pointer;
      -webkit-transition: ease-in-out 0.1s;
      transition: ease-in-out 0.1s;
      position: relative;
      border: solid 1px transparent;
      overflow: hidden;
      border: solid 1px #fff; }
      .modal-media .selected-file-wrapper .selected-file-container .selected-file-content:nth-child(1) {
        margin-left: 0; }
      .modal-media .selected-file-wrapper .selected-file-container .selected-file-content .media-thumbnail {
        max-width: calc(100% - 2px);
        max-height: calc(100% - 2px);
        position: absolute;
        left: 50%;
        top: 50%;
        -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%); }
      .modal-media .selected-file-wrapper .selected-file-container .selected-file-content .remove-selected-file-button {
        width: 24px;
        height: 24px;
        border-radius: 50%;
        background: #e34d6f;
        color: #fff;
        border: solid 2px #fff;
        display: inline-block;
        position: absolute;
        left: 50%;
        top: 50%;
        -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
        text-align: center;
        display: none;
        z-index: 1; }
        .modal-media .selected-file-wrapper .selected-file-container .selected-file-content .remove-selected-file-button svg {
          position: relative;
          top: -4px; }
      .modal-media .selected-file-wrapper .selected-file-container .selected-file-content .dark-layer {
        position: absolute;
        top: 0;
        left: 0;
        border-radius: 8px;
        width: 100%;
        height: 100%;
        background: rgba(25, 25, 25, 0.4); }
      .modal-media .selected-file-wrapper .selected-file-container .selected-file-content:hover .dark-layer {
        display: none; }
    .modal-media .selected-file-wrapper .selected-file-container .selected-file-content.active-selected-file {
      border: solid 1px var(--main-dark-orange); }
      .modal-media .selected-file-wrapper .selected-file-container .selected-file-content.active-selected-file .remove-selected-file-button {
        display: block; }
      .modal-media .selected-file-wrapper .selected-file-container .selected-file-content.active-selected-file .dark-layer {
        display: none; }
  .modal-media .selected-file-wrapper .send-media-button-wrapper {
    padding: 36px 44px;
    display: inline-block;
    width: 312px;
    position: absolute;
    right: 0;
    top: 0;
    height: 100%; }
    .modal-media .selected-file-wrapper .send-media-button-wrapper button {
      font-size: 16px;
      letter-spacing: -0.5px;
      width: 100%;
      height: 100%; }
      .modal-media .selected-file-wrapper .send-media-button-wrapper button img {
        width: 20px;
        margin-right: 4px; }

.taplive-main-chat-room-send-message-hamburger {
  border-radius: 50%;
  background: #ff7d00;
  width: 32px;
  height: 32px;
  text-align: center;
  position: relative;
  top: 4px;
  display: inline-block;
  vertical-align: top;
  cursor: pointer;
  margin-left: 14px; }
  .taplive-main-chat-room-send-message-hamburger:hover {
    background: #ef7702; }
  .taplive-main-chat-room-send-message-hamburger img {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }

.onetalk-main-chat-room-solve-wrapper {
  height: 40px;
  position: relative;
  width: 100%;
  top: 4px;
  padding: 12px 33px 0 50px;
  color: #191919;
  font-size: 16px;
  letter-spacing: -0.5px;
  cursor: pointer;
  -webkit-transition: ease-in-out 0.2s;
  transition: ease-in-out 0.2s;
  line-height: 1;
  background: #efefef; }
  .onetalk-main-chat-room-solve-wrapper:hover {
    background: #ece9e9; }
  .onetalk-main-chat-room-solve-wrapper .taplive-lds-ring {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
    .onetalk-main-chat-room-solve-wrapper .taplive-lds-ring div {
      border-color: #ff7d00 transparent transparent transparent; }
  .onetalk-main-chat-room-solve-wrapper img {
    vertical-align: top;
    position: absolute;
    top: 11px;
    left: 20px;
    margin-right: 4px; }
  .onetalk-main-chat-room-solve-wrapper .lds-ring {
    position: absolute;
    left: 50%;
    top: 50%;
    right: unset;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
    .onetalk-main-chat-room-solve-wrapper .lds-ring div {
      margin: 0; }

.onetalk-main-chat-room-solve-wrapper.onetalk-main-chat-room-solve-wrapper-hide {
  height: 0;
  overflow: hidden;
  padding: 0; }

.quick-reply-outer-wrapper {
  z-index: 1;
  width: 100%;
  position: absolute;
  bottom: 54px;
  left: 0;
  box-shadow: 0px -4px 6px 0px rgba(25, 25, 25, 0.1);
  background: #fff;
  overflow: hidden; }
  .quick-reply-outer-wrapper .quick-reply-inner-wrapper {
    max-height: 257px;
    overflow: auto; }
  .quick-reply-outer-wrapper .quick-reply-title-wrapper {
    font-size: 12px;
    color: rgba(25, 25, 25, 0.8);
    letter-spacing: -0.4px;
    padding: 12px 16px 10px;
    line-height: 1; }
  .quick-reply-outer-wrapper .quick-reply-list-option-wrapper {
    font-size: 14px;
    letter-spacing: -0.2px;
    color: #100;
    padding: 8px 16px 8px 16px;
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0;
    position: relative; }
    .quick-reply-outer-wrapper .quick-reply-list-option-wrapper span {
      color: rgba(25, 25, 25, 0.6); }
    .quick-reply-outer-wrapper .quick-reply-list-option-wrapper:hover {
      background: rgba(255, 126, 0, 0.1); }
    .quick-reply-outer-wrapper .quick-reply-list-option-wrapper.active-quick-reply-list-option-wrapper {
      background: rgba(255, 126, 0, 0.1); }
    .quick-reply-outer-wrapper .quick-reply-list-option-wrapper.quick-reply-list-option-wrapper-with-media {
      padding: 8px 50px 8px 16px; }
    .quick-reply-outer-wrapper .quick-reply-list-option-wrapper .quick-reply-media-wrapper {
      border-radius: 4px;
      width: 32px;
      height: 32px;
      border: solid 1px rgba(25, 25, 25, 0.1);
      position: absolute;
      right: 8px;
      top: 3px;
      overflow: hidden; }
      .quick-reply-outer-wrapper .quick-reply-list-option-wrapper .quick-reply-media-wrapper img {
        max-width: 100%;
        max-height: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%); }
      .quick-reply-outer-wrapper .quick-reply-list-option-wrapper .quick-reply-media-wrapper video {
        max-width: 100%;
        max-height: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%); }
      .quick-reply-outer-wrapper .quick-reply-list-option-wrapper .quick-reply-media-wrapper svg {
        max-width: 100%;
        max-height: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
        color: var(--main-dark-orange); }
      .quick-reply-outer-wrapper .quick-reply-list-option-wrapper .quick-reply-media-wrapper .quick-reply-video-wrapper {
        background: rgba(25, 25, 25, 0.6);
        position: absolute;
        top: 50%;
        left: 50%;
        width: 100%;
        height: 100%;
        -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%); }
        .quick-reply-outer-wrapper .quick-reply-list-option-wrapper .quick-reply-media-wrapper .quick-reply-video-wrapper svg {
          color: #fff; }
        .quick-reply-outer-wrapper .quick-reply-list-option-wrapper .quick-reply-media-wrapper .quick-reply-video-wrapper.quick-reply-video-wrapper-orange {
          background: var(--main-dark-orange) !important;
          color: #fff !important; }

.modal-confirmation-send-as-email .modal-footer button {
  width: calc(50% - 2px) !important;
  margin-right: 4px !important; }
  .modal-confirmation-send-as-email .modal-footer button:nth-last-child(1) {
    margin-right: 0 !important; }

.modal-select-interactive-message .modal-content {
  width: 635px; }
  .modal-select-interactive-message .modal-content .modal-body {
    position: relative;
    border-radius: 8px;
    padding: 32px; }
    .modal-select-interactive-message .modal-content .modal-body .icon-close {
      position: absolute;
      top: 32px;
      right: 32px;
      color: var(--main-dark-orange);
      cursor: pointer;
      font-size: 20px; }
      .modal-select-interactive-message .modal-content .modal-body .icon-close:hover {
        color: var(--main-darker-orange); }
    .modal-select-interactive-message .modal-content .modal-body .title-modal {
      font-size: 20px;
      letter-spacing: -0.6px;
      margin: 0 0 4px 0; }
    .modal-select-interactive-message .modal-content .modal-body .modal-subtitle {
      font-size: 16px;
      letter-spacing: -0.3px;
      margin: 0 0 24px 0; }
    .modal-select-interactive-message .modal-content .modal-body .select-interactive-50 {
      width: calc(50%);
      display: inline-block;
      vertical-align: top;
      text-align: center;
      padding: 24px;
      border-right: solid 1px #dcdcdc; }
      .modal-select-interactive-message .modal-content .modal-body .select-interactive-50:nth-last-child(1) {
        border: none !important; }
      .modal-select-interactive-message .modal-content .modal-body .select-interactive-50 .select-title {
        margin: 12px 0 6px 0;
        font-size: 16px;
        letter-spacing: -0.5px; }
      .modal-select-interactive-message .modal-content .modal-body .select-interactive-50 .select-subtitle {
        margin: 0 0 12px 0;
        font-size: 14px;
        letter-spacing: -0.2px; }
      .modal-select-interactive-message .modal-content .modal-body .select-interactive-50 button {
        line-height: 22px !important;
        height: 24px;
        border-radius: 4px !important;
        font-size: 12px !important;
        letter-spacing: -0.4px !important; }

.modal-new-interactive-message .form-label label {
  width: 100%;
  position: relative; }
  .modal-new-interactive-message .form-label label b {
    font-size: 16px;
    letter-spacing: -0.3px; }
  .modal-new-interactive-message .form-label label.label-header-type b {
    font-size: 14px; }
  .modal-new-interactive-message .form-label label .grey-font {
    position: absolute;
    top: 1px;
    right: 0; }
  .modal-new-interactive-message .form-label label .text-optional {
    position: relative;
    top: unset;
    right: unset; }

.modal-new-interactive-message .form-label .form-value {
  font-size: 16px;
  letter-spacing: -0.3px;
  margin-bottom: 0;
  color: rgba(25, 25, 25, 0.8); }

.modal-new-interactive-message input:disabled {
  background-color: rgba(25, 25, 25, 0.1) !important; }

.modal-new-interactive-message input::-webkit-input-placeholder {
  color: rgba(25, 25, 25, 0.4); }

.modal-new-interactive-message input::-ms-input-placeholder {
  color: rgba(25, 25, 25, 0.4); }

.modal-new-interactive-message input::placeholder {
  color: rgba(25, 25, 25, 0.4); }

.modal-new-interactive-message .disabled label,
.modal-new-interactive-message .disabled b,
.modal-new-interactive-message .disabled input {
  color: rgba(25, 25, 25, 0.4); }

.modal-new-interactive-message {
  position: fixed;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100%;
  background: rgba(25, 25, 25, 0.8);
  visibility: hidden;
  opacity: 0;
  right: 0;
  -webkit-transition: visibility 0.3s, opacity 0.5s ease-in-out;
  transition: visibility 0.3s, opacity 0.5s ease-in-out;
  z-index: 4; }
  .modal-new-interactive-message.active-modal-new-interactive-message {
    /* right: 0; */
    opacity: 1;
    visibility: visible;
    -webkit-transition: visibility 0s, opacity 0.5s ease-in-out;
    transition: visibility 0s, opacity 0.5s ease-in-out; }
  .modal-new-interactive-message .modal-body {
    position: absolute;
    top: 8px;
    right: -100%;
    left: unset;
    -webkit-transform: unset;
            transform: unset;
    height: calc(100% - 8px);
    width: 640px;
    display: inline-block;
    border-radius: 8px 0 0 0;
    background: #ffffff;
    padding: 0;
    max-height: calc(100% - 8px);
    -webkit-transition: ease-in-out 0.4s;
    transition: ease-in-out 0.4s; }
    .modal-new-interactive-message .modal-body.action-modal-body-new-interactive-message {
      right: 0; }
    .modal-new-interactive-message .modal-body .close-modal-interactive {
      border-radius: 50%;
      width: 48px;
      height: 48px;
      background: #ffffff;
      color: #757575;
      position: absolute;
      left: -65px;
      top: 0;
      text-align: center;
      cursor: pointer; }
      .modal-new-interactive-message .modal-body .close-modal-interactive:hover {
        background: #f9f9f9; }
      .modal-new-interactive-message .modal-body .close-modal-interactive svg {
        position: relative;
        font-size: 25px;
        top: 11px; }
    .modal-new-interactive-message .modal-body .new-interactive-message-wrapper {
      height: calc(100% - 72px);
      max-height: calc(100% - 72px);
      overflow: hidden; }
      .modal-new-interactive-message .modal-body .new-interactive-message-wrapper > div > div {
        overflow-x: hidden !important; }
      .modal-new-interactive-message .modal-body .new-interactive-message-wrapper .comp-back-button {
        margin: 0 0 24px; }
      .modal-new-interactive-message .modal-body .new-interactive-message-wrapper label {
        display: inline-block !important; }
      .modal-new-interactive-message .modal-body .new-interactive-message-wrapper .new-interactive-message-container {
        padding: 32px; }
        .modal-new-interactive-message .modal-body .new-interactive-message-wrapper .new-interactive-message-container .new-interactive-message-title {
          color: #191919; }
          .modal-new-interactive-message .modal-body .new-interactive-message-wrapper .new-interactive-message-container .new-interactive-message-title img {
            width: 24px;
            position: relative;
            top: -7px;
            margin-right: 4px; }
          .modal-new-interactive-message .modal-body .new-interactive-message-wrapper .new-interactive-message-container .new-interactive-message-title b {
            font-size: 20px;
            letter-spacing: -0.6px; }
          .modal-new-interactive-message .modal-body .new-interactive-message-wrapper .new-interactive-message-container .new-interactive-message-title svg {
            font-size: 24px;
            margin: 0 4px 0 0;
            position: relative;
            top: -2px; }
          .modal-new-interactive-message .modal-body .new-interactive-message-wrapper .new-interactive-message-container .new-interactive-message-title p {
            margin: 2px 0 0;
            color: rgba(25, 25, 25, 0.6);
            font-size: 14px;
            letter-spacing: -0.2px; }
        .modal-new-interactive-message .modal-body .new-interactive-message-wrapper .new-interactive-message-container label span {
          letter-spacing: -0.2px;
          font-size: 14px;
          color: rgba(25, 25, 25, 0.6); }
        .modal-new-interactive-message .modal-body .new-interactive-message-wrapper .new-interactive-message-container textarea {
          width: 100%;
          height: 123px; }
        .modal-new-interactive-message .modal-body .new-interactive-message-wrapper .new-interactive-message-container input[type="text"] {
          width: 100%; }
        .modal-new-interactive-message .modal-body .new-interactive-message-wrapper .new-interactive-message-container input[type="file"] {
          display: none; }
      .modal-new-interactive-message .modal-body .new-interactive-message-wrapper .button-text-wrapper-form {
        border-radius: 8px;
        border: solid 1px rgba(25, 25, 25, 0.1);
        background: #f3f3f3;
        padding: 12px;
        margin: 8px 0 0; }
        .modal-new-interactive-message .modal-body .new-interactive-message-wrapper .button-text-wrapper-form.button-text-wrapper-form-1 {
          margin: 24px 0 0 !important; }
        .modal-new-interactive-message .modal-body .new-interactive-message-wrapper .button-text-wrapper-form label {
          margin-top: 0; }
        .modal-new-interactive-message .modal-body .new-interactive-message-wrapper .button-text-wrapper-form .remove-button-text {
          position: relative;
          top: -1px;
          cursor: pointer;
          margin-left: 4px;
          color: #ff3f57 !important; }
      .modal-new-interactive-message .modal-body .new-interactive-message-wrapper .add-another-button {
        margin: 16px 0 0;
        font-size: 14px;
        cursor: pointer;
        letter-spacing: -0.5px;
        color: var(--main-dark-orange); }
        .modal-new-interactive-message .modal-body .new-interactive-message-wrapper .add-another-button svg {
          position: relative;
          margin-right: 4px;
          top: -1px; }
        .modal-new-interactive-message .modal-body .new-interactive-message-wrapper .add-another-button:hover {
          color: var(--main-darker-orange); }
          .modal-new-interactive-message .modal-body .new-interactive-message-wrapper .add-another-button:hover svg {
            color: var(--main-darker-orange); }
    .modal-new-interactive-message .modal-body .send-message-button {
      width: 100%;
      padding: 12px 24px;
      background: #ffffff;
      font-size: 16px;
      letter-spacing: -0.5px;
      position: absolute;
      bottom: 0;
      left: 0;
      box-shadow: 0 2px 20px 0 rgba(25, 25, 25, 0.18); }
      .modal-new-interactive-message .modal-body .send-message-button button {
        width: 100%;
        height: 48px; }

.input-interactive-header-type {
  margin-top: 6px !important; }

.header-type-outer-wrapper button {
  margin-top: 12px !important; }

.header-type-outer-wrapper .header-type-wrapper {
  width: 100%;
  position: relative;
  display: block; }
  .header-type-outer-wrapper .header-type-wrapper .message-type-selection-selection {
    vertical-align: top;
    border-radius: 8px;
    border: solid 1px #dcdcdc;
    display: inline-block;
    position: relative;
    width: calc(25% - 6px);
    margin-right: 8px;
    font-size: 14px;
    letter-spacing: -0.2px;
    padding: 14px 14px 14px 49px;
    cursor: pointer;
    -webkit-transition: ease-in-out .1s;
    transition: ease-in-out .1s; }
    .header-type-outer-wrapper .header-type-wrapper .message-type-selection-selection:nth-last-child(1) {
      margin-right: 0; }
    .header-type-outer-wrapper .header-type-wrapper .message-type-selection-selection.active-message-type-selection-selection {
      border-color: #ff7d00; }
    .header-type-outer-wrapper .header-type-wrapper .message-type-selection-selection:hover {
      border-color: #ff7d00; }
  .header-type-outer-wrapper .header-type-wrapper .message-type-selection-icon-wrapper {
    width: 28px;
    height: 28px;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 14px;
    -webkit-transform: translate(0, -50%);
            transform: translate(0, -50%); }
    .header-type-outer-wrapper .header-type-wrapper .message-type-selection-icon-wrapper img {
      width: 25px;
      height: 25px; }
    .header-type-outer-wrapper .header-type-wrapper .message-type-selection-icon-wrapper img {
      position: absolute;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%); }

.header-type-outer-wrapper .header-content {
  font-size: 14px;
  letter-spacing: -0.4px;
  display: block;
  margin-top: 12px; }

.header-type-outer-wrapper .button-upload-document {
  width: 220px;
  margin: 12px 0 0; }

.header-type-outer-wrapper .main-button-22 {
  width: unset !important;
  padding: 0 8px 0 12px !important;
  margin: 12px 8px 0  0; }

.header-type-outer-wrapper input[type="file"] {
  display: none; }

.header-type-outer-wrapper .media-value-wrapper {
  border-radius: 8px;
  margin: 8px 0 0;
  width: 48px;
  height: 48px;
  background: #ebebeb;
  position: relative;
  overflow: hidden; }
  .header-type-outer-wrapper .media-value-wrapper img, .header-type-outer-wrapper .media-value-wrapper video {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    max-width: 100%;
    max-height: 100%; }
  .header-type-outer-wrapper .media-value-wrapper .media-value-loading-wrapper {
    position: absolute;
    border-radius: 8px;
    width: 48px;
    height: 48px;
    border-radius: 8px;
    top: 0;
    left: 0;
    background: rgba(25, 25, 25, 0.1);
    display: none;
    cursor: pointer; }
    .header-type-outer-wrapper .media-value-wrapper .media-value-loading-wrapper svg {
      font-size: 20px;
      color: #fff;
      position: absolute;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%); }
    .header-type-outer-wrapper .media-value-wrapper .media-value-loading-wrapper .lds-ring {
      width: 38px;
      height: 38px;
      position: absolute;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%); }
      .header-type-outer-wrapper .media-value-wrapper .media-value-loading-wrapper .lds-ring div {
        margin: 0;
        width: 38px;
        height: 38px;
        border: solid 3px #fff;
        border-color: #fff transparent transparent transparent; }
  .header-type-outer-wrapper .media-value-wrapper:hover .media-value-loading-wrapper {
    display: block; }

.header-type-outer-wrapper .document-value-wrapper {
  width: 100%;
  margin-top: 20px;
  font-size: 14px;
  white-space: nowrap;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis; }
  .header-type-outer-wrapper .document-value-wrapper .lds-ring {
    margin-left: 8px;
    position: relative;
    top: 4px; }
    .header-type-outer-wrapper .document-value-wrapper .lds-ring div {
      margin: 0; }
  .header-type-outer-wrapper .document-value-wrapper svg {
    position: relative;
    top: -1px;
    margin-right: 4px;
    font-size: 14px;
    color: var(--main-dark-orange); }

.modal-new-interactivePreview-message .button-whatsapp-interactive-preview {
  width: 100%;
  height: 35px;
  text-align: center;
  color: #0096DE;
  padding: 9px 8px 0;
  border-radius: 8px;
  margin: 4px 0 0;
  font-size: 16px;
  background: #fff;
  line-height: 1; }
  .modal-new-interactivePreview-message .button-whatsapp-interactive-preview svg {
    position: relative;
    margin-right: 4px;
    top: 1px;
    font-size: 15px;
    vertical-align: top; }
  .modal-new-interactivePreview-message .button-whatsapp-interactive-preview.with-icon {
    padding: 8px 8px 0; }

.modal-new-interactivePreview-message .preview-message-body {
  margin-top: 16px; }

.modal-new-interactivePreview-message .interactive-section-list {
  margin: 24px 0 0; }
  .modal-new-interactivePreview-message .interactive-section-list .table-title {
    font-size: 16px;
    letter-spacing: -0.5px;
    margin: 12px 0 8px 0; }
  .modal-new-interactivePreview-message .interactive-section-list table {
    width: 100%;
    border-collapse: collapse; }
  .modal-new-interactivePreview-message .interactive-section-list thead tr {
    border-bottom: solid 1px #dcdcdc; }
  .modal-new-interactivePreview-message .interactive-section-list thead td {
    font-size: 14px;
    letter-spacing: -0.4px;
    padding: 8px; }
    .modal-new-interactivePreview-message .interactive-section-list thead td:nth-child(1) {
      width: 50px; }
    .modal-new-interactivePreview-message .interactive-section-list thead td:nth-child(2) {
      width: calc(50%); }
    .modal-new-interactivePreview-message .interactive-section-list thead td:nth-child(3) {
      width: calc(50%); }
  .modal-new-interactivePreview-message .interactive-section-list tbody tr:nth-child(even) {
    background: #f3f3f3; }
  .modal-new-interactivePreview-message .interactive-section-list tbody td {
    font-size: 14px;
    letter-spacing: -0.2px;
    padding: 8px;
    word-break: break-word;
    vertical-align: top; }

.modal-new-interactivePreview-message .form-label label {
  width: 100%;
  position: relative; }
  .modal-new-interactivePreview-message .form-label label .grey-font {
    position: absolute;
    top: 1px;
    right: 0; }
  .modal-new-interactivePreview-message .form-label label b {
    font-size: 16px;
    letter-spacing: -0.3px; }

.modal-new-interactivePreview-message .form-label .form-value {
  font-size: 16px;
  letter-spacing: -0.3px;
  margin-bottom: 0;
  color: rgba(25, 25, 25, 0.8); }

.modal-new-interactivePreview-message input:disabled {
  background-color: rgba(25, 25, 25, 0.1) !important; }

.modal-new-interactivePreview-message input::-webkit-input-placeholder {
  color: rgba(25, 25, 25, 0.4); }

.modal-new-interactivePreview-message input::-ms-input-placeholder {
  color: rgba(25, 25, 25, 0.4); }

.modal-new-interactivePreview-message input::placeholder {
  color: rgba(25, 25, 25, 0.4); }

.modal-new-interactivePreview-message .disabled label,
.modal-new-interactivePreview-message .disabled b,
.modal-new-interactivePreview-message .disabled input {
  color: rgba(25, 25, 25, 0.4); }

.modal-new-interactivePreview-message {
  position: fixed;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100%;
  background: rgba(25, 25, 25, 0.8);
  visibility: hidden;
  opacity: 0;
  right: 0;
  -webkit-transition: visibility 0.3s, opacity 0.5s ease-in-out;
  transition: visibility 0.3s, opacity 0.5s ease-in-out;
  z-index: 1049; }
  .modal-new-interactivePreview-message.active-modal-new-interactivePreview-message {
    /* right: 0; */
    opacity: 1;
    visibility: visible;
    -webkit-transition: visibility 0s, opacity 0.5s ease-in-out;
    transition: visibility 0s, opacity 0.5s ease-in-out; }
  .modal-new-interactivePreview-message .modal-body {
    position: absolute;
    top: 8px;
    right: -100%;
    left: unset;
    -webkit-transform: unset;
            transform: unset;
    height: calc(100% - 8px);
    width: 700px;
    display: inline-block;
    border-radius: 8px 0 0 0;
    background: #ffffff;
    padding: 0;
    max-height: calc(100% - 8px);
    -webkit-transition: ease-in-out 0.4s;
    transition: ease-in-out 0.4s; }
    .modal-new-interactivePreview-message .modal-body.action-modal-body-new-interactivePreview-message {
      right: 0; }
    .modal-new-interactivePreview-message .modal-body .close-modal-interactivePreview {
      border-radius: 50%;
      width: 48px;
      height: 48px;
      background: #ffffff;
      color: #757575;
      position: absolute;
      left: -65px;
      top: 0;
      text-align: center;
      cursor: pointer; }
      .modal-new-interactivePreview-message .modal-body .close-modal-interactivePreview:hover {
        background: #f9f9f9; }
      .modal-new-interactivePreview-message .modal-body .close-modal-interactivePreview svg {
        position: relative;
        font-size: 25px;
        top: 11px; }
    .modal-new-interactivePreview-message .modal-body .new-interactivePreview-message-wrapper {
      height: calc(100% - 72px);
      max-height: calc(100% - 72px);
      overflow: auto; }
      .modal-new-interactivePreview-message .modal-body .new-interactivePreview-message-wrapper .comp-back-button {
        margin: 0 0 24px; }
      .modal-new-interactivePreview-message .modal-body .new-interactivePreview-message-wrapper label {
        display: inline-block !important; }
      .modal-new-interactivePreview-message .modal-body .new-interactivePreview-message-wrapper .new-interactivePreview-message-container {
        padding: 32px 32px 12px 32px; }
        .modal-new-interactivePreview-message .modal-body .new-interactivePreview-message-wrapper .new-interactivePreview-message-container .new-interactivePreview-message-title {
          color: #191919; }
          .modal-new-interactivePreview-message .modal-body .new-interactivePreview-message-wrapper .new-interactivePreview-message-container .new-interactivePreview-message-title img {
            width: 24px;
            position: relative;
            top: -7px;
            margin-right: 4px; }
          .modal-new-interactivePreview-message .modal-body .new-interactivePreview-message-wrapper .new-interactivePreview-message-container .new-interactivePreview-message-title b {
            font-size: 20px;
            letter-spacing: -0.6px; }
          .modal-new-interactivePreview-message .modal-body .new-interactivePreview-message-wrapper .new-interactivePreview-message-container .new-interactivePreview-message-title svg {
            font-size: 24px;
            margin: 0 4px 0 0;
            position: relative;
            top: -7px; }
          .modal-new-interactivePreview-message .modal-body .new-interactivePreview-message-wrapper .new-interactivePreview-message-container .new-interactivePreview-message-title p {
            margin: 2px 0 0;
            color: rgba(25, 25, 25, 0.6);
            font-size: 14px;
            letter-spacing: -0.2px; }
        .modal-new-interactivePreview-message .modal-body .new-interactivePreview-message-wrapper .new-interactivePreview-message-container label span {
          letter-spacing: -0.2px;
          font-size: 14px;
          color: rgba(25, 25, 25, 0.6); }
        .modal-new-interactivePreview-message .modal-body .new-interactivePreview-message-wrapper .new-interactivePreview-message-container textarea {
          width: 100%;
          height: 152px; }
        .modal-new-interactivePreview-message .modal-body .new-interactivePreview-message-wrapper .new-interactivePreview-message-container input[type="text"] {
          width: 100%; }
        .modal-new-interactivePreview-message .modal-body .new-interactivePreview-message-wrapper .new-interactivePreview-message-container input[type="file"] {
          display: none; }
    .modal-new-interactivePreview-message .modal-body .whatsapp-preview-interactive {
      width: 100%;
      position: relative;
      border-radius: 4px;
      background: #EBE6E1;
      padding: 32px 16px 24px 24px;
      text-align: center; }
      .modal-new-interactivePreview-message .modal-body .whatsapp-preview-interactive .wrapper-quick-reply div {
        margin: 0 !important; }
      .modal-new-interactivePreview-message .modal-body .whatsapp-preview-interactive .wrapper-quick-reply.wrapper-quick-reply-more div:nth-child(1) {
        width: calc(50% - 2px);
        display: inline-block;
        margin-right: 4px !important; }
      .modal-new-interactivePreview-message .modal-body .whatsapp-preview-interactive .wrapper-quick-reply.wrapper-quick-reply-more div:nth-child(2) {
        width: calc(50% - 2px);
        display: inline-block;
        margin-right: 0 !important; }
      .modal-new-interactivePreview-message .modal-body .whatsapp-preview-interactive .wrapper-call-to-action .call-to-action-button-wrap {
        text-align: center;
        border-top: solid 1px rgba(25, 25, 25, 0.1);
        color: #0096DE;
        height: 36px;
        margin: 0 !important;
        width: calc(100% + 20px);
        padding: 5px 20px 0;
        position: relative;
        left: -10px; }
        .modal-new-interactivePreview-message .modal-body .whatsapp-preview-interactive .wrapper-call-to-action .call-to-action-button-wrap svg {
          position: relative;
          margin-right: 6px;
          top: -1px; }
        .modal-new-interactivePreview-message .modal-body .whatsapp-preview-interactive .wrapper-call-to-action .call-to-action-button-wrap:nth-child(1) {
          margin-top: 8px !important; }
        .modal-new-interactivePreview-message .modal-body .whatsapp-preview-interactive .wrapper-call-to-action .call-to-action-button-wrap:nth-last-child(1) {
          height: 26px; }
      .modal-new-interactivePreview-message .modal-body .whatsapp-preview-interactive .whatsapp-link {
        color: #1391E9; }
      .modal-new-interactivePreview-message .modal-body .whatsapp-preview-interactive .whatsapp-info-wrapper {
        width: 74px;
        height: 24px;
        display: inline-block;
        background: #E4F2F9;
        border-radius: 4px;
        box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2);
        margin-bottom: 16px; }
        .modal-new-interactivePreview-message .modal-body .whatsapp-preview-interactive .whatsapp-info-wrapper .whatsapp-info-content {
          width: 50px;
          height: 14px;
          border-radius: 2px;
          display: inline-block;
          background: #D0DCE3;
          margin-top: 5px; }
      .modal-new-interactivePreview-message .modal-body .whatsapp-preview-interactive .whatsapp-warning {
        width: 100%;
        font-size: 12px;
        letter-spacing: -0.4px;
        color: rgba(25, 25, 25, 0.6); }
        .modal-new-interactivePreview-message .modal-body .whatsapp-preview-interactive .whatsapp-warning svg {
          position: relative;
          top: -1px;
          margin-right: 4px; }
        .modal-new-interactivePreview-message .modal-body .whatsapp-preview-interactive .whatsapp-warning .whatsapp-warning-content {
          padding: 2px 13px;
          border-radius: 4px;
          box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2);
          background: #ffffff;
          display: inline-block; }
      .modal-new-interactivePreview-message .modal-body .whatsapp-preview-interactive .whatsapp-chat-bubble {
        word-break: break-word;
        background: #ffffff;
        width: 100%;
        padding: 10px;
        border-radius: 0 8px 8px 8px;
        box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2);
        position: relative;
        text-align: left;
        font-family: SFPro;
        overflow: hidden; }
        .modal-new-interactivePreview-message .modal-body .whatsapp-preview-interactive .whatsapp-chat-bubble .media-wrapper {
          text-align: center;
          width: 100%;
          position: relative;
          border-radius: 8px;
          overflow: hidden;
          margin: 0 0 2px 0; }
        .modal-new-interactivePreview-message .modal-body .whatsapp-preview-interactive .whatsapp-chat-bubble .image-media-preview {
          max-width: 100%;
          max-height: 100%; }
        .modal-new-interactivePreview-message .modal-body .whatsapp-preview-interactive .whatsapp-chat-bubble .video-media-preview {
          max-width: 100%;
          max-height: 100%; }
        .modal-new-interactivePreview-message .modal-body .whatsapp-preview-interactive .whatsapp-chat-bubble .black-layer {
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%; }
        .modal-new-interactivePreview-message .modal-body .whatsapp-preview-interactive .whatsapp-chat-bubble svg {
          font-size: 24px;
          color: #fff;
          position: absolute;
          top: 50%;
          left: 50%;
          -webkit-transform: translate(-50%, -50%);
                  transform: translate(-50%, -50%); }
        .modal-new-interactivePreview-message .modal-body .whatsapp-preview-interactive .whatsapp-chat-bubble::before {
          position: absolute;
          background: transparent;
          width: 13px;
          height: 8px;
          content: "";
          border-top: solid 8px transparent;
          border-bottom: solid 8px transparent;
          border-left: solid 8px #ffffff;
          border-right: solid 8px transparent;
          -webkit-transform: rotate(-45deg);
                  transform: rotate(-45deg);
          top: -8px;
          left: -7px; }
        .modal-new-interactivePreview-message .modal-body .whatsapp-preview-interactive .whatsapp-chat-bubble.no-corner {
          text-align: center;
          border-radius: 8px !important;
          color: #0096de !important;
          margin-top: 4px !important;
          vertical-align: top; }
          .modal-new-interactivePreview-message .modal-body .whatsapp-preview-interactive .whatsapp-chat-bubble.no-corner svg {
            color: #0096de !important;
            position: relative;
            top: -2px;
            font-size: 20px; }
          .modal-new-interactivePreview-message .modal-body .whatsapp-preview-interactive .whatsapp-chat-bubble.no-corner b {
            margin-left: 4px;
            font-size: 16px; }
          .modal-new-interactivePreview-message .modal-body .whatsapp-preview-interactive .whatsapp-chat-bubble.no-corner::before {
            display: none; }
        .modal-new-interactivePreview-message .modal-body .whatsapp-preview-interactive .whatsapp-chat-bubble b {
          font-family: SFPro !important;
          font-weight: bold !important; }
        .modal-new-interactivePreview-message .modal-body .whatsapp-preview-interactive .whatsapp-chat-bubble .whatsapp-chat-bubble-title {
          font-size: 16px;
          letter-spacing: -0.3px;
          line-height: 24px;
          margin: 0; }
        .modal-new-interactivePreview-message .modal-body .whatsapp-preview-interactive .whatsapp-chat-bubble .whatsapp-chat-bubble-description {
          font-size: 16px;
          letter-spacing: -0.3px;
          line-height: 24px;
          margin: 0; }
        .modal-new-interactivePreview-message .modal-body .whatsapp-preview-interactive .whatsapp-chat-bubble .whatsapp-chat-bubble-footer {
          font-size: 16px;
          letter-spacing: -0.3px;
          line-height: 24px;
          color: rgba(25, 25, 25, 0.6);
          margin: 0; }
        .modal-new-interactivePreview-message .modal-body .whatsapp-preview-interactive .whatsapp-chat-bubble .whatsapp-chat-bubble-status {
          text-align: right;
          width: 100%;
          font-size: 12px;
          color: rgba(0, 0, 0, 0.45);
          margin: 0; }
          .modal-new-interactivePreview-message .modal-body .whatsapp-preview-interactive .whatsapp-chat-bubble .whatsapp-chat-bubble-status img {
            position: relative;
            margin-left: 3px; }
      .modal-new-interactivePreview-message .modal-body .whatsapp-preview-interactive .static-media {
        position: relative;
        font-size: 0; }
        .modal-new-interactivePreview-message .modal-body .whatsapp-preview-interactive .static-media .static-media-document {
          border-radius: 8px;
          font-size: 16px;
          letter-spacing: -0.3px;
          padding: 12px 11px 12px 36px;
          position: relative;
          width: 100%;
          margin-bottom: 6px;
          background: #f3f3f3;
          color: rgba(25, 25, 25, 0.6); }
          .modal-new-interactivePreview-message .modal-body .whatsapp-preview-interactive .static-media .static-media-document img {
            position: absolute;
            top: 50%;
            -webkit-transform: translate(0, -50%);
                    transform: translate(0, -50%);
            left: 11px; }
        .modal-new-interactivePreview-message .modal-body .whatsapp-preview-interactive .static-media img.static-media-image-asset {
          width: 100%;
          border-radius: 8px; }
        .modal-new-interactivePreview-message .modal-body .whatsapp-preview-interactive .static-media video.static-media-video-asset {
          width: 100%;
          border-radius: 8px; }
        .modal-new-interactivePreview-message .modal-body .whatsapp-preview-interactive .static-media .static-media-image {
          border-radius: 12px;
          font-size: 14px;
          letter-spacing: -0.4px;
          padding: 82px 11px;
          position: relative;
          width: 100%;
          margin-bottom: 6px;
          background: #9b9b9b;
          color: #ffffff;
          text-align: center; }
          .modal-new-interactivePreview-message .modal-body .whatsapp-preview-interactive .static-media .static-media-image svg {
            font-size: 26px;
            margin-bottom: 4px; }
        .modal-new-interactivePreview-message .modal-body .whatsapp-preview-interactive .static-media .static-media-video {
          border-radius: 12px;
          font-size: 14px;
          letter-spacing: -0.4px;
          padding: 82px 11px;
          position: relative;
          width: 100%;
          margin-bottom: 6px;
          background: #9b9b9b;
          color: #ffffff;
          text-align: center; }
          .modal-new-interactivePreview-message .modal-body .whatsapp-preview-interactive .static-media .static-media-video svg {
            font-size: 26px;
            margin-bottom: 4px; }
        .modal-new-interactivePreview-message .modal-body .whatsapp-preview-interactive .static-media .static-media-video-file-wrapper {
          background: rgba(0, 0, 0, 0.35);
          position: absolute;
          top: 50%;
          left: 50%;
          -webkit-transform: translate(-50%, -50%);
                  transform: translate(-50%, -50%);
          width: 100%;
          height: 100%;
          border-radius: 8px;
          font-size: 0; }
          .modal-new-interactivePreview-message .modal-body .whatsapp-preview-interactive .static-media .static-media-video-file-wrapper svg {
            color: #ffffff;
            position: absolute;
            top: 50%;
            left: 50%;
            -webkit-transform: translate(-50%, -50%);
                    transform: translate(-50%, -50%);
            font-size: 32px; }
      .modal-new-interactivePreview-message .modal-body .whatsapp-preview-interactive .dynamic-media .dynamic-media-document {
        border-radius: 8px;
        font-size: 16px;
        letter-spacing: -0.3px;
        padding: 12px 11px 12px 36px;
        position: relative;
        width: 100%;
        margin-bottom: 6px;
        background: #f3f3f3; }
        .modal-new-interactivePreview-message .modal-body .whatsapp-preview-interactive .dynamic-media .dynamic-media-document img {
          position: absolute;
          top: 50%;
          -webkit-transform: translate(0, -50%);
                  transform: translate(0, -50%);
          left: 11px; }
      .modal-new-interactivePreview-message .modal-body .whatsapp-preview-interactive .dynamic-media .dynamic-media-image {
        border-radius: 12px;
        font-size: 14px;
        letter-spacing: -0.4px;
        padding: 82px 11px;
        position: relative;
        width: 100%;
        margin-bottom: 6px;
        background: #9b9b9b;
        color: #ffffff;
        text-align: center; }
        .modal-new-interactivePreview-message .modal-body .whatsapp-preview-interactive .dynamic-media .dynamic-media-image svg {
          font-size: 26px;
          margin-bottom: 4px; }
      .modal-new-interactivePreview-message .modal-body .whatsapp-preview-interactive .dynamic-media .dynamic-media-video {
        border-radius: 12px;
        font-size: 14px;
        letter-spacing: -0.4px;
        padding: 82px 11px;
        position: relative;
        width: 100%;
        margin-bottom: 6px;
        background: #9b9b9b;
        color: #ffffff;
        text-align: center; }
        .modal-new-interactivePreview-message .modal-body .whatsapp-preview-interactive .dynamic-media .dynamic-media-video svg {
          font-size: 26px;
          margin-bottom: 4px; }
    .modal-new-interactivePreview-message .modal-body .send-message-button {
      width: 100%;
      padding: 12px 24px;
      background: #ffffff;
      font-size: 16px;
      letter-spacing: -0.5px;
      position: absolute;
      bottom: 0;
      left: 0; }
      .modal-new-interactivePreview-message .modal-body .send-message-button button {
        width: 100%;
        height: 48px; }
  .modal-new-interactivePreview-message .add-to-quick-reply-wrapper {
    margin: 24px 0 0; }
    .modal-new-interactivePreview-message .add-to-quick-reply-wrapper .add-to-quick-reply-save {
      padding-bottom: 24px; }
      .modal-new-interactivePreview-message .add-to-quick-reply-wrapper .add-to-quick-reply-save .add-to-quick-reply-save-left {
        width: 50%;
        display: inline-block;
        vertical-align: top;
        font-size: 16px;
        letter-spacing: -0.3px;
        position: relative;
        top: 6px; }
        .modal-new-interactivePreview-message .add-to-quick-reply-wrapper .add-to-quick-reply-save .add-to-quick-reply-save-left .custom-checkbox [type="checkbox"]:not(:checked) + label:before {
          top: 4px; }
        .modal-new-interactivePreview-message .add-to-quick-reply-wrapper .add-to-quick-reply-save .add-to-quick-reply-save-left .custom-checkbox [type="checkbox"]:checked + label:before {
          top: 4px; }
        .modal-new-interactivePreview-message .add-to-quick-reply-wrapper .add-to-quick-reply-save .add-to-quick-reply-save-left .custom-checkbox [type="checkbox"]:checked + label:after {
          top: -1px;
          left: -5px; }
      .modal-new-interactivePreview-message .add-to-quick-reply-wrapper .add-to-quick-reply-save .add-to-quick-reply-save-right {
        width: 50%;
        display: inline-block;
        vertical-align: top;
        font-size: 14px;
        letter-spacing: -0.4px;
        text-align: right; }
        .modal-new-interactivePreview-message .add-to-quick-reply-wrapper .add-to-quick-reply-save .add-to-quick-reply-save-right .dropdown {
          width: 265px;
          display: inline-block; }
        .modal-new-interactivePreview-message .add-to-quick-reply-wrapper .add-to-quick-reply-save .add-to-quick-reply-save-right .input-quick-reply-select {
          font-size: 14px;
          letter-spacing: -0.4px; }
          .modal-new-interactivePreview-message .add-to-quick-reply-wrapper .add-to-quick-reply-save .add-to-quick-reply-save-right .input-quick-reply-select .custom-select-option-value {
            font-family: PTRootBold; }
          .modal-new-interactivePreview-message .add-to-quick-reply-wrapper .add-to-quick-reply-save .add-to-quick-reply-save-right .input-quick-reply-select svg {
            color: var(--main-dark-orange) !important;
            right: 34px !important; }
          .modal-new-interactivePreview-message .add-to-quick-reply-wrapper .add-to-quick-reply-save .add-to-quick-reply-save-right .input-quick-reply-select .dropdown button {
            padding: 6px 56px 6px 36px !important; }
          .modal-new-interactivePreview-message .add-to-quick-reply-wrapper .add-to-quick-reply-save .add-to-quick-reply-save-right .input-quick-reply-select .dropdown-option-wrapper {
            padding: 0; }
            .modal-new-interactivePreview-message .add-to-quick-reply-wrapper .add-to-quick-reply-save .add-to-quick-reply-save-right .input-quick-reply-select .dropdown-option-wrapper button {
              padding: 6px 36px 6px 36px !important;
              font-family: PTRootBold;
              border-bottom: solid 1px #dcdcdc; }
              .modal-new-interactivePreview-message .add-to-quick-reply-wrapper .add-to-quick-reply-save .add-to-quick-reply-save-right .input-quick-reply-select .dropdown-option-wrapper button:nth-last-child(1) {
                border: none; }
    .modal-new-interactivePreview-message .add-to-quick-reply-wrapper .add-to-quick-reply-input {
      width: 100%;
      display: block;
      position: relative; }
      .modal-new-interactivePreview-message .add-to-quick-reply-wrapper .add-to-quick-reply-input label {
        font-size: 16px;
        letter-spacing: -0.5px;
        width: 100%;
        display: block; }
        .modal-new-interactivePreview-message .add-to-quick-reply-wrapper .add-to-quick-reply-input label span {
          position: absolute;
          font-size: 16px !important;
          letter-spacing: -0.5px !important;
          right: 0;
          top: 1px; }
      .modal-new-interactivePreview-message .add-to-quick-reply-wrapper .add-to-quick-reply-input .input-slash {
        width: 40px !important;
        margin-right: 16px;
        display: inline-block; }
      .modal-new-interactivePreview-message .add-to-quick-reply-wrapper .add-to-quick-reply-input .input-slash {
        width: 40px !important;
        margin-right: 8px;
        display: inline-block;
        vertical-align: top; }
      .modal-new-interactivePreview-message .add-to-quick-reply-wrapper .add-to-quick-reply-input .input-me {
        width: 64px !important;
        margin-left: 8px;
        display: inline-block;
        vertical-align: top; }
      .modal-new-interactivePreview-message .add-to-quick-reply-wrapper .add-to-quick-reply-input input {
        border: solid 1px #dcdcdc;
        padding: 12px 16px;
        font-size: 16px;
        letter-spacing: -0.3px;
        border-radius: 8px; }
      .modal-new-interactivePreview-message .add-to-quick-reply-wrapper .add-to-quick-reply-input.input-general .input-quick-reply {
        width: calc(100% - 48px) !important;
        display: inline-block;
        vertical-align: top; }
      .modal-new-interactivePreview-message .add-to-quick-reply-wrapper .add-to-quick-reply-input.input-personal .input-quick-reply {
        width: calc(100% - 120px) !important;
        display: inline-block;
        vertical-align: top; }
      .modal-new-interactivePreview-message .add-to-quick-reply-wrapper .add-to-quick-reply-input.input-personal .input-quick-reply-general {
        width: calc(100% - 48px) !important;
        display: inline-block;
        vertical-align: top; }
    .modal-new-interactivePreview-message .add-to-quick-reply-wrapper .add-to-quick-reply-info {
      display: block;
      width: 60%;
      margin: 8px 0 0;
      font-size: 12px;
      letter-spacing: -0.2px;
      color: rgba(25, 25, 25, 0.6); }

.modal-confirmation-send-interactive button {
  width: calc(50% - 2px) !important;
  margin-right: 4px !important; }
  .modal-confirmation-send-interactive button:nth-last-child(1) {
    margin-right: 0 !important; }

.modal-new-interactiveList-message .form-label label {
  width: 100%;
  position: relative; }
  .modal-new-interactiveList-message .form-label label b {
    font-size: 16px;
    letter-spacing: -0.3px; }
  .modal-new-interactiveList-message .form-label label.label-header-type b {
    font-size: 14px; }
  .modal-new-interactiveList-message .form-label label .grey-font {
    position: absolute;
    top: 1px;
    right: 0; }
  .modal-new-interactiveList-message .form-label label .label-sub {
    display: block;
    margin: 4px 0 0;
    width: 100%;
    position: relative;
    top: unset !important;
    right: unset !important;
    color: rgba(25, 25, 25, 0.6) !important; }
  .modal-new-interactiveList-message .form-label label .text-optional {
    position: relative;
    top: unset;
    right: unset; }

.modal-new-interactiveList-message .form-label .form-value {
  font-size: 16px;
  letter-spacing: -0.3px;
  margin-bottom: 0;
  color: rgba(25, 25, 25, 0.8); }

.modal-new-interactiveList-message input:disabled {
  background-color: rgba(25, 25, 25, 0.1) !important; }

.modal-new-interactiveList-message input::-webkit-input-placeholder {
  color: rgba(25, 25, 25, 0.4); }

.modal-new-interactiveList-message input::-ms-input-placeholder {
  color: rgba(25, 25, 25, 0.4); }

.modal-new-interactiveList-message input::placeholder {
  color: rgba(25, 25, 25, 0.4); }

.modal-new-interactiveList-message .disabled label,
.modal-new-interactiveList-message .disabled b,
.modal-new-interactiveList-message .disabled input {
  color: rgba(25, 25, 25, 0.4); }

.modal-new-interactiveList-message {
  position: fixed;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100%;
  background: rgba(25, 25, 25, 0.8);
  visibility: hidden;
  opacity: 0;
  right: 0;
  -webkit-transition: visibility 0.3s, opacity 0.5s ease-in-out;
  transition: visibility 0.3s, opacity 0.5s ease-in-out;
  z-index: 5; }
  .modal-new-interactiveList-message.active-modal-new-interactiveList-message {
    /* right: 0; */
    opacity: 1;
    visibility: visible;
    -webkit-transition: visibility 0s, opacity 0.5s ease-in-out;
    transition: visibility 0s, opacity 0.5s ease-in-out; }
  .modal-new-interactiveList-message .modal-body {
    position: absolute;
    top: 8px;
    right: -100%;
    left: unset;
    -webkit-transform: unset;
            transform: unset;
    height: calc(100% - 8px);
    width: 640px;
    display: inline-block;
    border-radius: 8px 0 0 0;
    background: #ffffff;
    padding: 0;
    max-height: calc(100% - 8px);
    -webkit-transition: ease-in-out 0.4s;
    transition: ease-in-out 0.4s; }
    .modal-new-interactiveList-message .modal-body.action-modal-body-new-interactiveList-message {
      right: 0; }
    .modal-new-interactiveList-message .modal-body .close-modal-interactiveList {
      border-radius: 50%;
      width: 48px;
      height: 48px;
      background: #ffffff;
      color: #757575;
      position: absolute;
      left: -65px;
      top: 0;
      text-align: center;
      cursor: pointer; }
      .modal-new-interactiveList-message .modal-body .close-modal-interactiveList:hover {
        background: #f9f9f9; }
      .modal-new-interactiveList-message .modal-body .close-modal-interactiveList svg {
        position: relative;
        font-size: 25px;
        top: 11px; }
    .modal-new-interactiveList-message .modal-body .new-interactiveList-message-wrapper {
      height: calc(100% - 72px);
      max-height: calc(100% - 72px);
      overflow: hidden;
      right: 0;
      -webkit-transition: ease-in-out .5s;
      transition: ease-in-out .5s;
      position: relative;
      display: block; }
      .modal-new-interactiveList-message .modal-body .new-interactiveList-message-wrapper > div > div {
        overflow-x: hidden !important; }
      .modal-new-interactiveList-message .modal-body .new-interactiveList-message-wrapper.new-interactiveList-message-wrapper-deactive {
        right: -700px;
        display: none; }
      .modal-new-interactiveList-message .modal-body .new-interactiveList-message-wrapper .comp-back-button {
        margin: 0 0 24px; }
      .modal-new-interactiveList-message .modal-body .new-interactiveList-message-wrapper label {
        display: inline-block !important; }
      .modal-new-interactiveList-message .modal-body .new-interactiveList-message-wrapper .new-interactiveList-message-container {
        padding: 32px; }
        .modal-new-interactiveList-message .modal-body .new-interactiveList-message-wrapper .new-interactiveList-message-container .new-interactiveList-message-title {
          color: #191919; }
          .modal-new-interactiveList-message .modal-body .new-interactiveList-message-wrapper .new-interactiveList-message-container .new-interactiveList-message-title img {
            width: 24px;
            position: relative;
            top: -7px;
            margin-right: 4px; }
          .modal-new-interactiveList-message .modal-body .new-interactiveList-message-wrapper .new-interactiveList-message-container .new-interactiveList-message-title b {
            font-size: 20px;
            letter-spacing: -0.6px; }
          .modal-new-interactiveList-message .modal-body .new-interactiveList-message-wrapper .new-interactiveList-message-container .new-interactiveList-message-title svg {
            font-size: 24px;
            margin: 0 4px 0 0;
            position: relative;
            top: -2px; }
          .modal-new-interactiveList-message .modal-body .new-interactiveList-message-wrapper .new-interactiveList-message-container .new-interactiveList-message-title p {
            margin: 2px 0 0;
            color: rgba(25, 25, 25, 0.6);
            font-size: 14px;
            letter-spacing: -0.2px; }
        .modal-new-interactiveList-message .modal-body .new-interactiveList-message-wrapper .new-interactiveList-message-container label span {
          letter-spacing: -0.2px;
          font-size: 14px;
          color: rgba(25, 25, 25, 0.6); }
        .modal-new-interactiveList-message .modal-body .new-interactiveList-message-wrapper .new-interactiveList-message-container textarea {
          width: 100%;
          height: 123px; }
        .modal-new-interactiveList-message .modal-body .new-interactiveList-message-wrapper .new-interactiveList-message-container input[type="text"] {
          width: 100%; }
        .modal-new-interactiveList-message .modal-body .new-interactiveList-message-wrapper .new-interactiveList-message-container input[type="file"] {
          display: none; }
      .modal-new-interactiveList-message .modal-body .new-interactiveList-message-wrapper .button-text-wrapper-form {
        border-radius: 8px;
        border: solid 1px rgba(25, 25, 25, 0.1);
        background: #f3f3f3;
        padding: 12px;
        margin: 8px 0 0; }
        .modal-new-interactiveList-message .modal-body .new-interactiveList-message-wrapper .button-text-wrapper-form .manage-rows {
          margin: 18px 0 0;
          font-size: 14px;
          letter-spacing: -0.5px;
          color: var(--main-dark-orange);
          cursor: pointer; }
          .modal-new-interactiveList-message .modal-body .new-interactiveList-message-wrapper .button-text-wrapper-form .manage-rows svg {
            position: relative;
            top: -1px;
            margin-right: 4px;
            font-size: 16px; }
          .modal-new-interactiveList-message .modal-body .new-interactiveList-message-wrapper .button-text-wrapper-form .manage-rows:hover {
            color: var(--main-darker-orange); }
        .modal-new-interactiveList-message .modal-body .new-interactiveList-message-wrapper .button-text-wrapper-form.button-text-wrapper-form-1 {
          margin: 24px 0 0 !important; }
        .modal-new-interactiveList-message .modal-body .new-interactiveList-message-wrapper .button-text-wrapper-form label {
          margin-top: 0; }
        .modal-new-interactiveList-message .modal-body .new-interactiveList-message-wrapper .button-text-wrapper-form .remove-button-text {
          position: relative;
          top: -1px;
          margin-left: 4px;
          cursor: pointer;
          color: #ff3f57 !important; }
        .modal-new-interactiveList-message .modal-body .new-interactiveList-message-wrapper .button-text-wrapper-form input[type="text"] {
          background: white;
          border-radius: 8px;
          border: 1px solid rgba(25, 25, 25, 0.1);
          height: 48px;
          color: #191919;
          font-size: 16px;
          letter-spacing: -0.3px;
          width: 316px;
          max-width: 100%;
          padding: 0 16px;
          width: 100%; }
      .modal-new-interactiveList-message .modal-body .new-interactiveList-message-wrapper .add-another-button {
        margin: 16px 0 0;
        font-size: 14px;
        cursor: pointer;
        letter-spacing: -0.5px;
        color: var(--main-dark-orange); }
        .modal-new-interactiveList-message .modal-body .new-interactiveList-message-wrapper .add-another-button svg {
          position: relative;
          margin-right: 4px;
          top: -1px; }
        .modal-new-interactiveList-message .modal-body .new-interactiveList-message-wrapper .add-another-button:hover {
          color: var(--main-darker-orange); }
          .modal-new-interactiveList-message .modal-body .new-interactiveList-message-wrapper .add-another-button:hover svg {
            color: var(--main-darker-orange); }
    .modal-new-interactiveList-message .modal-body .send-message-button {
      width: 100%;
      padding: 12px 24px;
      background: #ffffff;
      font-size: 16px;
      letter-spacing: -0.5px;
      position: absolute;
      bottom: 0;
      left: 0;
      box-shadow: 0 2px 20px 0 rgba(25, 25, 25, 0.18); }
      .modal-new-interactiveList-message .modal-body .send-message-button.send-message-button-2 button {
        width: calc(50% - 4px);
        margin-right: 8px;
        -webkit-transition: none;
        transition: none; }
        .modal-new-interactiveList-message .modal-body .send-message-button.send-message-button-2 button:nth-last-child(1) {
          margin: 0; }
      .modal-new-interactiveList-message .modal-body .send-message-button button {
        width: 100%;
        height: 48px; }

.new-interactiveList-row-message-wrapper {
  right: -700px;
  position: relative;
  -webkit-transition: ease-in-out .5s;
  transition: ease-in-out .5s;
  height: calc(100% - 72px);
  max-height: calc(100% - 72px);
  display: none;
  overflow-y: auto; }
  .new-interactiveList-row-message-wrapper .new-interactiveList-message-container {
    padding: 0 32px 32px; }
  .new-interactiveList-row-message-wrapper.new-interactiveList-row-message-wrapper-active {
    right: 0;
    display: block; }
  .new-interactiveList-row-message-wrapper .comp-back-button {
    margin: 0;
    padding: 32px 32px 0 32px; }
  .new-interactiveList-row-message-wrapper .new-interactiveList-message-title {
    margin: 32px 0 0; }
    .new-interactiveList-row-message-wrapper .new-interactiveList-message-title svg {
      position: relative;
      top: 6px;
      vertical-align: top;
      margin-right: 4px;
      font-size: 19px; }
    .new-interactiveList-row-message-wrapper .new-interactiveList-message-title b {
      font-size: 20px;
      letter-spacing: -0.2px; }
    .new-interactiveList-row-message-wrapper .new-interactiveList-message-title p {
      margin: 4px 0 12px;
      font-size: 14px;
      letter-spacing: -0.6px;
      color: rgba(25, 25, 25, 0.6); }
  .new-interactiveList-row-message-wrapper .button-text-wrapper-form {
    border-radius: 8px;
    border: solid 1px rgba(25, 25, 25, 0.1);
    background: #f3f3f3;
    padding: 12px;
    margin: 8px 0 0;
    font-size: 16px;
    letter-spacing: -0.5px;
    width: calc(100% - 6px); }
    .new-interactiveList-row-message-wrapper .button-text-wrapper-form input[type="text"] {
      background: white;
      border-radius: 8px;
      border: 1px solid rgba(25, 25, 25, 0.1);
      height: 48px;
      color: #191919;
      font-size: 16px;
      letter-spacing: -0.3px;
      width: 316px;
      max-width: 100%;
      padding: 0 16px;
      width: 100%; }
    .new-interactiveList-row-message-wrapper .button-text-wrapper-form label {
      width: 100%;
      position: relative; }
      .new-interactiveList-row-message-wrapper .button-text-wrapper-form label .grey-font {
        position: absolute;
        right: 0;
        top: 0; }
      .new-interactiveList-row-message-wrapper .button-text-wrapper-form label.row-description {
        height: 24px;
        margin: 8px 0 0; }
    .new-interactiveList-row-message-wrapper .button-text-wrapper-form .manage-rows {
      margin: 18px 0 0;
      font-size: 14px;
      letter-spacing: -0.5px;
      color: var(--main-dark-orange);
      cursor: pointer; }
      .new-interactiveList-row-message-wrapper .button-text-wrapper-form .manage-rows svg {
        position: relative;
        top: -1px;
        margin-right: 4px; }
      .new-interactiveList-row-message-wrapper .button-text-wrapper-form .manage-rows:hover {
        color: var(--main-darker-orange); }
    .new-interactiveList-row-message-wrapper .button-text-wrapper-form.button-text-wrapper-form-1 {
      margin: 24px 0 0 !important; }
    .new-interactiveList-row-message-wrapper .button-text-wrapper-form label {
      margin-top: 0; }
    .new-interactiveList-row-message-wrapper .button-text-wrapper-form .remove-button-text {
      position: relative;
      top: -1px;
      margin-left: 4px;
      cursor: pointer;
      color: #ff3f57 !important; }
  .new-interactiveList-row-message-wrapper .add-another-button {
    margin: 16px 0 0;
    font-size: 14px;
    cursor: pointer;
    letter-spacing: -0.5px;
    color: var(--main-dark-orange); }
    .new-interactiveList-row-message-wrapper .add-another-button svg {
      position: relative;
      margin-right: 4px;
      top: -1px; }
    .new-interactiveList-row-message-wrapper .add-another-button:hover {
      color: var(--main-darker-orange); }
      .new-interactiveList-row-message-wrapper .add-another-button:hover svg {
        color: var(--main-darker-orange); }

.chat-room-reply-message-wrapper {
  width: 100%;
  position: absolute;
  height: 68px;
  left: 0;
  top: 0; }
  .chat-room-reply-message-wrapper .chat-room-reply-message-wrapper-inner {
    padding: 9px 8px 9px 64px;
    width: 100%;
    position: absolute;
    height: 68px;
    top: -69px;
    left: 0;
    background: #ffffff; }
    .chat-room-reply-message-wrapper .chat-room-reply-message-wrapper-inner .cancel-reply-button {
      font-size: 20px;
      color: rgba(25, 25, 25, 0.4);
      -webkit-transition: ease-in-out .1s;
      transition: ease-in-out .1s;
      position: absolute;
      top: 50%;
      left: 25px;
      -webkit-transform: translate(0, -50%);
              transform: translate(0, -50%);
      cursor: pointer; }
      .chat-room-reply-message-wrapper .chat-room-reply-message-wrapper-inner .cancel-reply-button:hover {
        color: rgba(25, 25, 25, 0.8); }
    .chat-room-reply-message-wrapper .chat-room-reply-message-wrapper-inner .reply-content-outer-wrapper {
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
      width: 100%;
      position: relative; }
      .chat-room-reply-message-wrapper .chat-room-reply-message-wrapper-inner .reply-content-outer-wrapper.with-border {
        padding-left: 4px; }
        .chat-room-reply-message-wrapper .chat-room-reply-message-wrapper-inner .reply-content-outer-wrapper.with-border::before {
          content: "";
          width: 4px;
          height: 100%;
          display: inline-block;
          position: absolute;
          top: 0;
          left: 0;
          background: #ff7e00;
          border-top-left-radius: 8px;
          border-bottom-left-radius: 8px; }
      .chat-room-reply-message-wrapper .chat-room-reply-message-wrapper-inner .reply-content-outer-wrapper .reply-file-media-wrapper {
        border-radius: 4px;
        width: 48px;
        height: 48px;
        display: inline-block;
        margin: 0 8px 0 0;
        vertical-align: top;
        position: relative;
        top: 1px; }
        .chat-room-reply-message-wrapper .chat-room-reply-message-wrapper-inner .reply-content-outer-wrapper .reply-file-media-wrapper .reply-message-image {
          width: 100%;
          position: absolute;
          top: 50%;
          left: 50%;
          -webkit-transform: translate(-50%, -50%);
                  transform: translate(-50%, -50%);
          max-width: 100%;
          max-height: 100%;
          object-fit: contain; }
        .chat-room-reply-message-wrapper .chat-room-reply-message-wrapper-inner .reply-content-outer-wrapper .reply-file-media-wrapper .reply-message-video {
          width: 100%;
          position: absolute;
          top: 50%;
          left: 50%;
          -webkit-transform: translate(-50%, -50%);
                  transform: translate(-50%, -50%);
          max-width: 100%;
          max-height: 100%; }
        .chat-room-reply-message-wrapper .chat-room-reply-message-wrapper-inner .reply-content-outer-wrapper .reply-file-media-wrapper .reply-message-file {
          border-radius: 50%;
          width: 100%;
          height: 100%;
          position: relative;
          background: #ff7e00; }
          .chat-room-reply-message-wrapper .chat-room-reply-message-wrapper-inner .reply-content-outer-wrapper .reply-file-media-wrapper .reply-message-file svg {
            color: #ffffff;
            font-size: 22px;
            position: absolute;
            top: 50%;
            left: 50%;
            -webkit-transform: translate(-50%, -50%);
                    transform: translate(-50%, -50%); }
      .chat-room-reply-message-wrapper .chat-room-reply-message-wrapper-inner .reply-content-outer-wrapper .reply-name-text-wrapper {
        padding: 8px 0 8px 8px; }
        .chat-room-reply-message-wrapper .chat-room-reply-message-wrapper-inner .reply-content-outer-wrapper .reply-name-text-wrapper.with-image-or-video-or-file {
          width: calc(100% - 64px);
          display: inline-block; }
        .chat-room-reply-message-wrapper .chat-room-reply-message-wrapper-inner .reply-content-outer-wrapper .reply-name-text-wrapper p {
          margin: 0; }
          .chat-room-reply-message-wrapper .chat-room-reply-message-wrapper-inner .reply-content-outer-wrapper .reply-name-text-wrapper p.reply-name {
            color: #191919;
            font-size: 12px;
            letter-spacing: -0.4px;
            line-height: 18px;
            max-width: 100%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis; }
          .chat-room-reply-message-wrapper .chat-room-reply-message-wrapper-inner .reply-content-outer-wrapper .reply-name-text-wrapper p.reply-text {
            color: rgba(25, 25, 25, 0.8);
            font-size: 12px;
            letter-spacing: -0.2px;
            line-height: 16px;
            max-width: 100%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis; }

.chat-room-input-message-wrapper {
  box-shadow: 0 2px 20px 0 rgba(25, 25, 25, 0.18);
  background: #fff;
  position: absolute;
  width: 100%;
  bottom: 0;
  padding: 7px 0 4px; }
  .chat-room-input-message-wrapper .quick-reply-document-wrapper {
    height: 68px;
    width: 100%;
    background: #fff;
    padding: 10px 14px 10px 98px;
    position: absolute;
    top: -69px;
    left: 0;
    border-bottom: solid 1px rgba(25, 25, 25, 0.1); }
    .chat-room-input-message-wrapper .quick-reply-document-wrapper .clear-quick-reply-document {
      font-size: 22px;
      position: absolute;
      left: 10px;
      top: 22px;
      color: rgba(25, 25, 25, 0.2);
      cursor: pointer; }
    .chat-room-input-message-wrapper .quick-reply-document-wrapper .quick-reply-document-icon {
      background: var(--main-dark-orange);
      width: 48px;
      height: 48px;
      border-radius: 50%;
      position: absolute;
      left: 40px;
      top: 10px; }
      .chat-room-input-message-wrapper .quick-reply-document-wrapper .quick-reply-document-icon svg {
        color: #fff;
        font-size: 22px;
        position: absolute;
        left: 50%;
        top: 50%;
        -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%); }
    .chat-room-input-message-wrapper .quick-reply-document-wrapper p {
      font-size: 12px;
      letter-spacing: -0.4px;
      position: relative;
      top: 14px;
      white-space: nowrap;
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis; }
  .chat-room-input-message-wrapper .icon-salestalk {
    position: absolute;
    top: 10px;
    right: 80px;
    z-index: 1;
    width: 18px;
    cursor: pointer; }
    .chat-room-input-message-wrapper .icon-salestalk.icon-salestalk-orange {
      display: none; }
      .chat-room-input-message-wrapper .icon-salestalk.icon-salestalk-orange:hover {
        display: block !important; }
    .chat-room-input-message-wrapper .icon-salestalk:hover + .icon-salestalk-orange {
      display: block; }
    .chat-room-input-message-wrapper .icon-salestalk.icon-salestalk-46 {
      right: 46px; }
  .chat-room-input-message-wrapper .cancel-start-conversation {
    position: absolute;
    left: 25px;
    bottom: 18px;
    color: rgba(25, 25, 25, 0.7);
    font-size: 18px;
    z-index: 1;
    cursor: pointer; }
    .chat-room-input-message-wrapper .cancel-start-conversation:hover {
      color: #191919; }
  .chat-room-input-message-wrapper .start-conversation-image-video-priview-small-box {
    border-radius: 8px;
    width: 38px;
    height: 38px;
    background: #c9c9c9;
    position: absolute;
    left: 52px;
    z-index: 1;
    bottom: 8px;
    cursor: pointer; }
    .chat-room-input-message-wrapper .start-conversation-image-video-priview-small-box img {
      position: absolute;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%);
      max-width: 100%;
      max-height: 100%; }
    .chat-room-input-message-wrapper .start-conversation-image-video-priview-small-box video {
      position: absolute;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%);
      max-width: 100%;
      max-height: 100%; }
      .chat-room-input-message-wrapper .start-conversation-image-video-priview-small-box video .icon-play-mini {
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
        max-width: 100%;
        max-height: 100%; }
    .chat-room-input-message-wrapper .start-conversation-image-video-priview-small-box .icon-file-mini {
      color: #fff;
      font-size: 20px;
      position: absolute;
      left: 50%;
      top: 50%;
      -webkit-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%); }
  .chat-room-input-message-wrapper .button-send-message {
    font-size: 14px;
    letter-spacing: -0.4px;
    height: 32px;
    line-height: 29px;
    border-radius: 100px;
    position: absolute;
    z-index: 1;
    bottom: 11px;
    right: 24px; }
    .chat-room-input-message-wrapper .button-send-message.take-over-send {
      padding: 0 11px 0 16px; }
    .chat-room-input-message-wrapper .button-send-message.assign-me-send {
      padding: 0 11px 0 16px; }
    .chat-room-input-message-wrapper .button-send-message.just-send {
      padding: 0 11px 0 16px; }
    .chat-room-input-message-wrapper .button-send-message img {
      position: relative;
      margin-left: 4px;
      top: -2px; }
    .chat-room-input-message-wrapper .button-send-message .lds-ring {
      margin: 0;
      top: 4px;
      right: unset; }
      .chat-room-input-message-wrapper .button-send-message .lds-ring div {
        margin: 0;
        border-color: #ffffff transparent transparent transparent; }
    .chat-room-input-message-wrapper .button-send-message .lds-ring {
      margin-left: 4px; }
  .chat-room-input-message-wrapper .chat-room-textarea-wrapper {
    display: inline-block;
    position: relative;
    margin-right: 16px;
    width: 100%; }
    .chat-room-input-message-wrapper .chat-room-textarea-wrapper .emoji-picker-outer-wrapper {
      position: relative;
      width: 20px;
      height: 20px;
      display: inline-block;
      position: absolute;
      top: 9px;
      right: 46px; }
      .chat-room-input-message-wrapper .chat-room-textarea-wrapper .emoji-picker-outer-wrapper .emoji-picker-main-wrapper {
        position: absolute;
        padding-bottom: 22px;
        bottom: 15px;
        right: -55px;
        -webkit-transition: opacity .2s;
        transition: opacity .2s;
        opacity: 0;
        visibility: hidden; }
        .chat-room-input-message-wrapper .chat-room-textarea-wrapper .emoji-picker-outer-wrapper .emoji-picker-main-wrapper:hover {
          opacity: 1;
          visibility: visible !important; }
      .chat-room-input-message-wrapper .chat-room-textarea-wrapper .emoji-picker-outer-wrapper .emoji-toggle-button {
        top: unset;
        right: unset;
        font-size: 20px; }
        .chat-room-input-message-wrapper .chat-room-textarea-wrapper .emoji-picker-outer-wrapper .emoji-toggle-button:hover ~ .emoji-picker-main-wrapper {
          opacity: 1 !important;
          visibility: visible !important; }
      .chat-room-input-message-wrapper .chat-room-textarea-wrapper .emoji-picker-outer-wrapper.emoji-picker-outer-wrapper-16 {
        right: 16px; }
    .chat-room-input-message-wrapper .chat-room-textarea-wrapper textarea.main-textarea-input {
      z-index: 1;
      max-height: 138px;
      width: 100%;
      font-size: 16px;
      letter-spacing: -0.3px;
      resize: none;
      position: relative;
      top: -2px;
      scrollbar-width: none;
      border-radius: 20px;
      border: none;
      box-shadow: 0 0 0 1px rgba(25, 25, 25, 0.1);
      padding: 9px 105px 9px 16px;
      box-sizing: border-box;
      max-height: 138px; }
      .chat-room-input-message-wrapper .chat-room-textarea-wrapper textarea.main-textarea-input::-webkit-scrollbar {
        display: none; }
      .chat-room-input-message-wrapper .chat-room-textarea-wrapper textarea.main-textarea-input:focus {
        box-shadow: 0 0 0 1px #ff7d00; }
      .chat-room-input-message-wrapper .chat-room-textarea-wrapper textarea.main-textarea-input:-moz-read-only {
        cursor: not-allowed; }
      .chat-room-input-message-wrapper .chat-room-textarea-wrapper textarea.main-textarea-input:read-only {
        cursor: not-allowed; }
    .chat-room-input-message-wrapper .chat-room-textarea-wrapper svg {
      color: rgba(25, 25, 25, 0.6);
      position: absolute;
      right: 16px;
      top: 11px;
      font-size: 18px;
      cursor: pointer;
      z-index: 1; }
      .chat-room-input-message-wrapper .chat-room-textarea-wrapper svg:hover {
        color: #ff7d00; }
    .chat-room-input-message-wrapper .chat-room-textarea-wrapper svg.active-paperclip {
      color: #ff7d00; }
  .chat-room-input-message-wrapper .chat-send-wrapper {
    width: 32px;
    height: 32px;
    text-align: center;
    border-radius: 50%;
    background: #ff7d00;
    position: absolute;
    right: 14px;
    bottom: 10px;
    display: inline-block;
    cursor: pointer;
    -webkit-transition: ease-in-out 0.1s;
    transition: ease-in-out 0.1s;
    border: none; }
    .chat-room-input-message-wrapper .chat-send-wrapper:hover {
      background: #ef7702; }
    .chat-room-input-message-wrapper .chat-send-wrapper img {
      position: absolute;
      left: 50%;
      top: 50%;
      -webkit-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%); }
  .chat-room-input-message-wrapper form {
    width: calc(100% - 72px);
    margin-left: 12px;
    display: inline-block;
    -webkit-transition: ease-in-out 0.2s;
    transition: ease-in-out 0.2s; }
    .chat-room-input-message-wrapper form.start-conversation-active {
      width: calc(100% - 24px); }
      .chat-room-input-message-wrapper form.start-conversation-active textarea.main-textarea-input {
        padding-top: 9px;
        padding-right: 0;
        padding-bottom: 9px;
        padding-left: 36px; }
  .chat-room-input-message-wrapper.just-send textarea.main-textarea-input {
    padding-right: 106px !important; }
  .chat-room-input-message-wrapper.assign-me-send textarea.main-textarea-input {
    padding-right: 194px !important; }
  .chat-room-input-message-wrapper.take-over-send textarea.main-textarea-input {
    padding-right: 194px !important; }
  .chat-room-input-message-wrapper.start-conversation-with-image-or-video textarea.main-textarea-input {
    padding-left: 91px !important; }

.input-file-wrapper-chat {
  width: 167px;
  display: inline-block;
  box-shadow: 0px 0px 9px 0px rgba(25, 25, 25, 0.1);
  line-height: 1;
  position: absolute;
  right: 0;
  bottom: 35px;
  border-radius: 8px;
  z-index: 1; }
  .input-file-wrapper-chat input[type=file] {
    display: none; }
  .input-file-wrapper-chat .input-file-content {
    width: 100%;
    position: relative;
    padding: 12px 12px 12px 34px;
    font-size: 14px;
    letter-spacing: -0.4px;
    color: rgba(25, 25, 25, 0.6);
    background: #fff;
    cursor: pointer; }
    .input-file-wrapper-chat .input-file-content img {
      position: absolute;
      top: 11px;
      left: 12px; }
    .input-file-wrapper-chat .input-file-content:hover {
      background: #ededed; }
      .input-file-wrapper-chat .input-file-content:hover svg {
        color: #fff; }
  .input-file-wrapper-chat label {
    width: 100%;
    margin: 0; }
    .input-file-wrapper-chat label:nth-child(1) .input-file-content {
      border-top-left-radius: 8px;
      border-top-right-radius: 8px; }
    .input-file-wrapper-chat label:nth-last-child(1) .input-file-content {
      border: none;
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px; }
  .input-file-wrapper-chat svg {
    left: 12px;
    font-size: 20px; }

.modal-media.modal-dialog {
  width: 100vw !important;
  position: relative; }

.modal-media .modal-content {
  width: 100%;
  height: 100%;
  background: none; }
  .modal-media .modal-content .modal-body {
    width: 100%;
    height: 100%;
    background: #191919;
    padding: 0;
    position: relative; }
    .modal-media .modal-content .modal-body .preview-arrow-navigator {
      font-size: 48px;
      color: #a3a3a3;
      position: absolute;
      top: 40%;
      -webkit-transform: translate(0, -50%);
              transform: translate(0, -50%);
      cursor: pointer;
      -webkit-transition: ease-in-out 0.1s;
      transition: ease-in-out 0.1s; }
      .modal-media .modal-content .modal-body .preview-arrow-navigator:hover {
        color: #fff; }
    .modal-media .modal-content .modal-body .preview-arrow-navigator.left-preview-navigator {
      left: 30px; }
    .modal-media .modal-content .modal-body .preview-arrow-navigator.right-preview-navigator {
      right: 30px; }
    .modal-media .modal-content .modal-body .drop-file-here-wrapper {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      display: none;
      text-align: center;
      font-size: 24px;
      letter-spacing: -0.5px;
      background: #eaeaea;
      z-index: 3;
      opacity: 0.9; }
      .modal-media .modal-content .modal-body .drop-file-here-wrapper svg {
        font-size: 48px;
        margin-bottom: 16px; }
      .modal-media .modal-content .modal-body .drop-file-here-wrapper p {
        margin: 0; }
      .modal-media .modal-content .modal-body .drop-file-here-wrapper .drop-file-here-content {
        width: 80%;
        height: 80%;
        padding: 40px;
        border: dashed 4px #191919;
        display: inline-block;
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
        line-height: 80%;
        color: #191919; }
        .modal-media .modal-content .modal-body .drop-file-here-wrapper .drop-file-here-content .drop-file-here-inner-content {
          width: 100%;
          position: absolute;
          top: 50%;
          left: 50%;
          -webkit-transform: translate(-50%, -50%);
                  transform: translate(-50%, -50%);
          padding: 24px; }
    .modal-media .modal-content .modal-body .drop-file-here-wrapper.active-drop-file-here-wrapper {
      display: block; }
    .modal-media .modal-content .modal-body .video-image-wrapper {
      display: inline-block;
      position: absolute;
      top: 43%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%);
      max-width: 90vw;
      min-width: 450px; }
      .modal-media .modal-content .modal-body .video-image-wrapper .preview-container {
        text-align: center; }
        .modal-media .modal-content .modal-body .video-image-wrapper .preview-container .blocking-image-preview {
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          background: transparent; }
        .modal-media .modal-content .modal-body .video-image-wrapper .preview-container img {
          max-width: calc(100vw - 170px);
          max-height: calc(100vh - 320px);
          pointer-events: none; }
        .modal-media .modal-content .modal-body .video-image-wrapper .preview-container video {
          max-width: calc(100vw - 170px);
          max-height: calc(100vh - 320px); }
      .modal-media .modal-content .modal-body .video-image-wrapper .caption-container {
        position: relative; }
        .modal-media .modal-content .modal-body .video-image-wrapper .caption-container textarea {
          resize: none;
          background: rgba(25, 25, 25, 0.4);
          max-height: 71px;
          overflow: auto;
          width: 100%;
          text-overflow: ellipsis;
          border: none;
          height: 71px;
          color: #fff;
          box-shadow: none;
          padding: 8px 0 8px 0;
          border-radius: 0;
          font-size: 16px;
          letter-spacing: -0.3px;
          border-bottom: solid 1px #dcdcdc; }
          .modal-media .modal-content .modal-body .video-image-wrapper .caption-container textarea::-webkit-input-placeholder {
            color: #fff; }
          .modal-media .modal-content .modal-body .video-image-wrapper .caption-container textarea::-ms-input-placeholder {
            color: #fff; }
          .modal-media .modal-content .modal-body .video-image-wrapper .caption-container textarea::placeholder {
            color: #fff; }
          .modal-media .modal-content .modal-body .video-image-wrapper .caption-container textarea::-webkit-scrollbar {
            display: none; }
        .modal-media .modal-content .modal-body .video-image-wrapper .caption-container .airplane-icon {
          position: absolute;
          right: 0;
          top: 50%;
          -webkit-transform: translate(0, -50%);
                  transform: translate(0, -50%);
          cursor: pointer;
          -webkit-transition: ease-in-out 0.3s;
          transition: ease-in-out 0.3s; }
          .modal-media .modal-content .modal-body .video-image-wrapper .caption-container .airplane-icon:hover {
            -webkit-transform: translate(0, -50%) scale(0.95);
                    transform: translate(0, -50%) scale(0.95); }
        .modal-media .modal-content .modal-body .video-image-wrapper .caption-container .caption-limit-text {
          position: absolute;
          color: #fff;
          right: 0;
          font-size: 11px;
          bottom: -18px; }
    .modal-media .modal-content .modal-body .close-modal-video {
      position: absolute;
      top: 20px;
      left: 16px;
      color: #fff;
      font-size: 40px;
      cursor: pointer;
      -webkit-transition: ease-in-out 0.1s;
      transition: ease-in-out 0.1s; }
      .modal-media .modal-content .modal-body .close-modal-video:hover {
        color: #c9c9c9; }

.modal-media .selected-file-wrapper {
  width: 100%;
  height: 120px;
  box-shadow: 0 -1px 0 0 rgba(255, 255, 255, 0.2);
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 12px 312px 12px 106px; }
  .modal-media .selected-file-wrapper .file-counter {
    letter-spacing: -0.6px;
    font-size: 24px;
    color: #fff;
    left: 32px;
    top: 42px;
    position: absolute; }
  .modal-media .selected-file-wrapper .selected-file-container {
    position: relative;
    height: 100%;
    text-align: right;
    max-width: 100%;
    white-space: nowrap;
    overflow-x: auto; }
    .modal-media .selected-file-wrapper .selected-file-container label[for="media-input"] {
      height: 100%;
      width: 96px;
      margin: 0; }
    .modal-media .selected-file-wrapper .selected-file-container [type="file"] {
      display: none; }
    .modal-media .selected-file-wrapper .selected-file-container .add-more-file-button {
      font-size: 12px;
      letter-spacing: -0.2px;
      color: #fff;
      width: 96px;
      height: 100%;
      border-radius: 8px;
      border: solid 1px #fff;
      text-align: center;
      display: inline-block;
      vertical-align: top;
      padding-top: 24px;
      cursor: pointer;
      -webkit-transition: ease-in-out 0.1s;
      transition: ease-in-out 0.1s; }
      .modal-media .selected-file-wrapper .selected-file-container .add-more-file-button:hover {
        border: solid 1px #ff7d00; }
      .modal-media .selected-file-wrapper .selected-file-container .add-more-file-button svg {
        font-size: 30px; }
      .modal-media .selected-file-wrapper .selected-file-container .add-more-file-button img {
        margin-bottom: 8px; }
    .modal-media .selected-file-wrapper .selected-file-container .selected-file-content {
      vertical-align: top;
      width: 96px;
      height: 100%;
      border-radius: 8px;
      text-align: center;
      margin-left: 12px;
      display: inline-block;
      cursor: pointer;
      -webkit-transition: ease-in-out 0.1s;
      transition: ease-in-out 0.1s;
      position: relative;
      border: solid 1px transparent;
      overflow: hidden;
      border: solid 1px #fff; }
      .modal-media .selected-file-wrapper .selected-file-container .selected-file-content:nth-child(1) {
        margin-left: 0; }
      .modal-media .selected-file-wrapper .selected-file-container .selected-file-content .media-thumbnail {
        max-width: calc(100% - 2px);
        max-height: calc(100% - 2px);
        position: absolute;
        left: 50%;
        top: 50%;
        -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%); }
      .modal-media .selected-file-wrapper .selected-file-container .selected-file-content .remove-selected-file-button {
        width: 24px;
        height: 24px;
        border-radius: 50%;
        background: #e34d6f;
        color: #fff;
        border: solid 2px #fff;
        display: inline-block;
        position: absolute;
        left: 50%;
        top: 50%;
        -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
        text-align: center;
        display: none;
        z-index: 1; }
        .modal-media .selected-file-wrapper .selected-file-container .selected-file-content .remove-selected-file-button svg {
          position: relative;
          top: -4px; }
      .modal-media .selected-file-wrapper .selected-file-container .selected-file-content .dark-layer {
        position: absolute;
        top: 0;
        left: 0;
        border-radius: 8px;
        width: 100%;
        height: 100%;
        background: rgba(25, 25, 25, 0.4); }
      .modal-media .selected-file-wrapper .selected-file-container .selected-file-content:hover .dark-layer {
        display: none; }
    .modal-media .selected-file-wrapper .selected-file-container .selected-file-content.active-selected-file {
      border: solid 1px var(--main-dark-orange); }
      .modal-media .selected-file-wrapper .selected-file-container .selected-file-content.active-selected-file .remove-selected-file-button {
        display: block; }
      .modal-media .selected-file-wrapper .selected-file-container .selected-file-content.active-selected-file .dark-layer {
        display: none; }
  .modal-media .selected-file-wrapper .send-media-button-wrapper {
    padding: 36px 44px;
    display: inline-block;
    width: 312px;
    position: absolute;
    right: 0;
    top: 0;
    height: 100%; }
    .modal-media .selected-file-wrapper .send-media-button-wrapper button {
      font-size: 16px;
      letter-spacing: -0.5px;
      width: 100%;
      height: 100%; }
      .modal-media .selected-file-wrapper .send-media-button-wrapper button img {
        width: 20px;
        margin-right: 4px; }

.taplive-main-chat-room-send-message-hamburger {
  border-radius: 50%;
  background: #ff7d00;
  width: 32px;
  height: 32px;
  text-align: center;
  position: relative;
  top: 4px;
  display: inline-block;
  vertical-align: top;
  cursor: pointer;
  margin-left: 14px; }
  .taplive-main-chat-room-send-message-hamburger:hover {
    background: #ef7702; }
  .taplive-main-chat-room-send-message-hamburger img {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }

.onetalk-main-chat-room-solve-wrapper {
  height: 40px;
  position: relative;
  width: 100%;
  top: 4px;
  padding: 12px 33px 0 50px;
  color: #191919;
  font-size: 16px;
  letter-spacing: -0.5px;
  cursor: pointer;
  -webkit-transition: ease-in-out 0.2s;
  transition: ease-in-out 0.2s;
  line-height: 1;
  background: #efefef; }
  .onetalk-main-chat-room-solve-wrapper:hover {
    background: #ece9e9; }
  .onetalk-main-chat-room-solve-wrapper .taplive-lds-ring {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
    .onetalk-main-chat-room-solve-wrapper .taplive-lds-ring div {
      border-color: #ff7d00 transparent transparent transparent; }
  .onetalk-main-chat-room-solve-wrapper img {
    vertical-align: top;
    position: absolute;
    top: 11px;
    left: 20px;
    margin-right: 4px; }
  .onetalk-main-chat-room-solve-wrapper .lds-ring {
    position: absolute;
    left: 50%;
    top: 50%;
    right: unset;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
    .onetalk-main-chat-room-solve-wrapper .lds-ring div {
      margin: 0; }

.onetalk-main-chat-room-solve-wrapper.onetalk-main-chat-room-solve-wrapper-hide {
  height: 0;
  overflow: hidden;
  padding: 0; }

.quick-reply-outer-wrapper {
  z-index: 1;
  width: 100%;
  position: absolute;
  bottom: 54px;
  left: 0;
  box-shadow: 0px -4px 6px 0px rgba(25, 25, 25, 0.1);
  background: #fff;
  overflow: hidden; }
  .quick-reply-outer-wrapper .quick-reply-inner-wrapper {
    max-height: 257px;
    overflow: auto; }
  .quick-reply-outer-wrapper .quick-reply-title-wrapper {
    font-size: 12px;
    color: rgba(25, 25, 25, 0.8);
    letter-spacing: -0.4px;
    padding: 12px 16px 10px;
    line-height: 1; }
  .quick-reply-outer-wrapper .quick-reply-list-option-wrapper {
    font-size: 14px;
    letter-spacing: -0.2px;
    color: #100;
    padding: 8px 16px 8px 16px;
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0;
    position: relative; }
    .quick-reply-outer-wrapper .quick-reply-list-option-wrapper span {
      color: rgba(25, 25, 25, 0.6); }
    .quick-reply-outer-wrapper .quick-reply-list-option-wrapper:hover {
      background: rgba(255, 126, 0, 0.1); }
    .quick-reply-outer-wrapper .quick-reply-list-option-wrapper.active-quick-reply-list-option-wrapper {
      background: rgba(255, 126, 0, 0.1); }
    .quick-reply-outer-wrapper .quick-reply-list-option-wrapper.quick-reply-list-option-wrapper-with-media {
      padding: 8px 50px 8px 16px; }
    .quick-reply-outer-wrapper .quick-reply-list-option-wrapper .quick-reply-media-wrapper {
      border-radius: 4px;
      width: 32px;
      height: 32px;
      border: solid 1px rgba(25, 25, 25, 0.1);
      position: absolute;
      right: 8px;
      top: 3px;
      overflow: hidden; }
      .quick-reply-outer-wrapper .quick-reply-list-option-wrapper .quick-reply-media-wrapper img {
        max-width: 100%;
        max-height: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%); }
      .quick-reply-outer-wrapper .quick-reply-list-option-wrapper .quick-reply-media-wrapper video {
        max-width: 100%;
        max-height: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%); }
      .quick-reply-outer-wrapper .quick-reply-list-option-wrapper .quick-reply-media-wrapper svg {
        max-width: 100%;
        max-height: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
        color: var(--main-dark-orange); }
      .quick-reply-outer-wrapper .quick-reply-list-option-wrapper .quick-reply-media-wrapper .quick-reply-video-wrapper {
        background: rgba(25, 25, 25, 0.6);
        position: absolute;
        top: 50%;
        left: 50%;
        width: 100%;
        height: 100%;
        -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%); }
        .quick-reply-outer-wrapper .quick-reply-list-option-wrapper .quick-reply-media-wrapper .quick-reply-video-wrapper svg {
          color: #fff; }
        .quick-reply-outer-wrapper .quick-reply-list-option-wrapper .quick-reply-media-wrapper .quick-reply-video-wrapper.quick-reply-video-wrapper-orange {
          background: var(--main-dark-orange) !important;
          color: #fff !important; }

.modal-confirmation-send-as-email .modal-footer button {
  width: calc(50% - 2px) !important;
  margin-right: 4px !important; }
  .modal-confirmation-send-as-email .modal-footer button:nth-last-child(1) {
    margin-right: 0 !important; }

.chat-room-input-email-attachment {
  position: fixed;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100%;
  background: rgba(25, 25, 25, 0.8);
  visibility: hidden;
  opacity: 0;
  right: 0;
  -webkit-transition: visibility .3s, opacity 0.5s ease-in-out;
  transition: visibility .3s, opacity 0.5s ease-in-out;
  z-index: 9998; }
  .chat-room-input-email-attachment.active-chat-room-input-email-attachment {
    /* right: 0; */
    opacity: 1;
    visibility: visible;
    -webkit-transition: visibility 0s, opacity 0.5s ease-in-out;
    transition: visibility 0s, opacity 0.5s ease-in-out; }
  .chat-room-input-email-attachment .close-modal-chat-room-input-email-attachment {
    border-radius: 50%;
    width: 48px;
    height: 48px;
    background: #ffffff;
    color: #757575;
    position: absolute;
    left: -65px;
    top: 0;
    text-align: center;
    cursor: pointer; }
    .chat-room-input-email-attachment .close-modal-chat-room-input-email-attachment:hover {
      background: #f9f9f9; }
    .chat-room-input-email-attachment .close-modal-chat-room-input-email-attachment svg {
      position: absolute;
      font-size: 25px;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%); }
  .chat-room-input-email-attachment .modal-body {
    padding: 32px 32px 104px 32px;
    position: absolute;
    top: 8px;
    right: -100%;
    left: unset;
    -webkit-transform: unset;
    transform: unset;
    height: calc(100% - 8px);
    width: 600px;
    display: inline-block;
    border-radius: 8px 0 0 0;
    background: #ffffff;
    max-height: calc(100% - 8px);
    -webkit-transition: ease-in-out .4s;
    transition: ease-in-out .4s; }
    .chat-room-input-email-attachment .modal-body.active-modal-body-chat-room-input-email-attachment {
      right: 0; }
    .chat-room-input-email-attachment .modal-body .chat-room-input-email-attachment-head {
      border-radius: 8px 8px 0 0;
      font-size: 20px;
      letter-spacing: -0.6px;
      position: relative; }
      .chat-room-input-email-attachment .modal-body .chat-room-input-email-attachment-head svg {
        position: relative;
        top: -2px;
        margin-right: 4px; }
      .chat-room-input-email-attachment .modal-body .chat-room-input-email-attachment-head p {
        margin: 0;
        font-size: 14px;
        letter-spacing: -0.2px;
        color: rgba(25, 25, 25, 0.6); }
    .chat-room-input-email-attachment .modal-body .chat-room-input-email-attachment-body {
      padding: 16px 32px;
      width: 100%;
      position: absolute;
      top: 100px;
      left: 50%;
      -webkit-transform: translate(-50%, 0%);
              transform: translate(-50%, 0%);
      height: calc(100% - 173px);
      overflow-y: auto; }
      .chat-room-input-email-attachment .modal-body .chat-room-input-email-attachment-body .chat-room-input-email-attachment-body-placeholder-wrapper {
        text-align: center;
        position: absolute;
        top: 50%;
        left: 50%;
        width: 100%;
        -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
        padding: 16px;
        font-size: 14px;
        letter-spacing: -0.4px; }
        .chat-room-input-email-attachment .modal-body .chat-room-input-email-attachment-body .chat-room-input-email-attachment-body-placeholder-wrapper .icon-cloud {
          color: #d1d1d1;
          font-size: 124px;
          display: inline-block;
          margin: 0 0 18px 0; }
        .chat-room-input-email-attachment .modal-body .chat-room-input-email-attachment-body .chat-room-input-email-attachment-body-placeholder-wrapper .main-button-40 {
          margin: 24px 0 0; }
      .chat-room-input-email-attachment .modal-body .chat-room-input-email-attachment-body .chat-room-input-email-attachment-value-wrapper {
        position: relative;
        display: block;
        width: 100%; }
        .chat-room-input-email-attachment .modal-body .chat-room-input-email-attachment-body .chat-room-input-email-attachment-value-wrapper .chat-room-input-email-attachment-value-list {
          font-size: 16px;
          letter-spacing: -0.5px;
          padding: 8px 40px 8px 16px;
          border-radius: 4px;
          color: rgba(25, 25, 25, 0.8);
          background: #f3f3f3;
          margin: 0 0 8px 0;
          position: relative; }
          .chat-room-input-email-attachment .modal-body .chat-room-input-email-attachment-body .chat-room-input-email-attachment-value-wrapper .chat-room-input-email-attachment-value-list .filename {
            margin: 0;
            display: inline-block;
            white-space: nowrap;
            max-width: calc(100% - 62px);
            overflow: hidden;
            text-overflow: ellipsis;
            vertical-align: top; }
          .chat-room-input-email-attachment .modal-body .chat-room-input-email-attachment-body .chat-room-input-email-attachment-value-wrapper .chat-room-input-email-attachment-value-list .filesize {
            margin: 0;
            display: inline-block;
            white-space: nowrap;
            vertical-align: top; }
          .chat-room-input-email-attachment .modal-body .chat-room-input-email-attachment-body .chat-room-input-email-attachment-value-wrapper .chat-room-input-email-attachment-value-list svg {
            color: #ff3f57;
            position: absolute;
            top: 12px;
            right: 16px; }
          .chat-room-input-email-attachment .modal-body .chat-room-input-email-attachment-body .chat-room-input-email-attachment-value-wrapper .chat-room-input-email-attachment-value-list .lds-ring {
            position: absolute;
            top: 11px;
            right: 16px; }
            .chat-room-input-email-attachment .modal-body .chat-room-input-email-attachment-body .chat-room-input-email-attachment-value-wrapper .chat-room-input-email-attachment-value-list .lds-ring div {
              margin: 0; }
        .chat-room-input-email-attachment .modal-body .chat-room-input-email-attachment-body .chat-room-input-email-attachment-value-wrapper .button-add-attachment {
          font-size: 14px;
          letter-spacing: -0.5px;
          color: var(--main-dark-orange);
          cursor: pointer; }
          .chat-room-input-email-attachment .modal-body .chat-room-input-email-attachment-body .chat-room-input-email-attachment-value-wrapper .button-add-attachment svg {
            position: relative;
            top: -2px;
            margin-right: 4px; }
          .chat-room-input-email-attachment .modal-body .chat-room-input-email-attachment-body .chat-room-input-email-attachment-value-wrapper .button-add-attachment:hover {
            color: var(--main-darker-orange); }
    .chat-room-input-email-attachment .modal-body .chat-room-input-email-attachment-button-footer {
      width: 100%;
      padding: 12px 24px;
      position: absolute;
      bottom: 0;
      left: 0;
      box-shadow: 0px 2px 20px 0px rgba(25, 25, 25, 0.18); }
      .chat-room-input-email-attachment .modal-body .chat-room-input-email-attachment-button-footer button {
        width: 100%; }

.chat-room-reply-message-wrapper {
  width: 100%;
  position: absolute;
  height: 68px;
  left: 0;
  top: 0; }
  .chat-room-reply-message-wrapper .chat-room-reply-message-wrapper-inner {
    padding: 9px 8px 9px 64px;
    width: 100%;
    position: absolute;
    height: 68px;
    top: -69px;
    left: 0;
    background: #ffffff; }
    .chat-room-reply-message-wrapper .chat-room-reply-message-wrapper-inner .cancel-reply-button {
      font-size: 20px;
      color: rgba(25, 25, 25, 0.4);
      -webkit-transition: ease-in-out .1s;
      transition: ease-in-out .1s;
      position: absolute;
      top: 50%;
      left: 25px;
      -webkit-transform: translate(0, -50%);
              transform: translate(0, -50%);
      cursor: pointer; }
      .chat-room-reply-message-wrapper .chat-room-reply-message-wrapper-inner .cancel-reply-button:hover {
        color: rgba(25, 25, 25, 0.8); }
    .chat-room-reply-message-wrapper .chat-room-reply-message-wrapper-inner .reply-content-outer-wrapper {
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
      width: 100%;
      position: relative; }
      .chat-room-reply-message-wrapper .chat-room-reply-message-wrapper-inner .reply-content-outer-wrapper.with-border {
        padding-left: 4px; }
        .chat-room-reply-message-wrapper .chat-room-reply-message-wrapper-inner .reply-content-outer-wrapper.with-border::before {
          content: "";
          width: 4px;
          height: 100%;
          display: inline-block;
          position: absolute;
          top: 0;
          left: 0;
          background: #ff7e00;
          border-top-left-radius: 8px;
          border-bottom-left-radius: 8px; }
      .chat-room-reply-message-wrapper .chat-room-reply-message-wrapper-inner .reply-content-outer-wrapper .reply-file-media-wrapper {
        border-radius: 4px;
        width: 48px;
        height: 48px;
        display: inline-block;
        margin: 0 8px 0 0;
        vertical-align: top;
        position: relative;
        top: 1px; }
        .chat-room-reply-message-wrapper .chat-room-reply-message-wrapper-inner .reply-content-outer-wrapper .reply-file-media-wrapper .reply-message-image {
          width: 100%;
          position: absolute;
          top: 50%;
          left: 50%;
          -webkit-transform: translate(-50%, -50%);
                  transform: translate(-50%, -50%);
          max-width: 100%;
          max-height: 100%;
          object-fit: contain; }
        .chat-room-reply-message-wrapper .chat-room-reply-message-wrapper-inner .reply-content-outer-wrapper .reply-file-media-wrapper .reply-message-video {
          width: 100%;
          position: absolute;
          top: 50%;
          left: 50%;
          -webkit-transform: translate(-50%, -50%);
                  transform: translate(-50%, -50%);
          max-width: 100%;
          max-height: 100%; }
        .chat-room-reply-message-wrapper .chat-room-reply-message-wrapper-inner .reply-content-outer-wrapper .reply-file-media-wrapper .reply-message-file {
          border-radius: 50%;
          width: 100%;
          height: 100%;
          position: relative;
          background: #ff7e00; }
          .chat-room-reply-message-wrapper .chat-room-reply-message-wrapper-inner .reply-content-outer-wrapper .reply-file-media-wrapper .reply-message-file svg {
            color: #ffffff;
            font-size: 22px;
            position: absolute;
            top: 50%;
            left: 50%;
            -webkit-transform: translate(-50%, -50%);
                    transform: translate(-50%, -50%); }
      .chat-room-reply-message-wrapper .chat-room-reply-message-wrapper-inner .reply-content-outer-wrapper .reply-name-text-wrapper {
        padding: 8px 0 8px 8px; }
        .chat-room-reply-message-wrapper .chat-room-reply-message-wrapper-inner .reply-content-outer-wrapper .reply-name-text-wrapper.with-image-or-video-or-file {
          width: calc(100% - 64px);
          display: inline-block; }
        .chat-room-reply-message-wrapper .chat-room-reply-message-wrapper-inner .reply-content-outer-wrapper .reply-name-text-wrapper p {
          margin: 0; }
          .chat-room-reply-message-wrapper .chat-room-reply-message-wrapper-inner .reply-content-outer-wrapper .reply-name-text-wrapper p.reply-name {
            color: #191919;
            font-size: 12px;
            letter-spacing: -0.4px;
            line-height: 18px;
            max-width: 100%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis; }
          .chat-room-reply-message-wrapper .chat-room-reply-message-wrapper-inner .reply-content-outer-wrapper .reply-name-text-wrapper p.reply-text {
            color: rgba(25, 25, 25, 0.8);
            font-size: 12px;
            letter-spacing: -0.2px;
            line-height: 16px;
            max-width: 100%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis; }

.chat-room-reply-email-message-wrapper {
  width: 100%;
  position: absolute;
  height: 68px;
  left: 0;
  top: 0; }
  .chat-room-reply-email-message-wrapper .chat-room-reply-email-message-wrapper-inner {
    padding: 9px 58px 9px 16px;
    width: 100%;
    position: absolute;
    height: 68px;
    top: -69px;
    left: 0;
    background: #ffffff; }
    .chat-room-reply-email-message-wrapper .chat-room-reply-email-message-wrapper-inner .cancel-reply-email-button {
      font-size: 20px;
      color: rgba(25, 25, 25, 0.4);
      -webkit-transition: ease-in-out .1s;
      transition: ease-in-out .1s;
      position: absolute;
      top: 50%;
      right: 16px;
      -webkit-transform: translate(0, -50%);
              transform: translate(0, -50%);
      cursor: pointer; }
      .chat-room-reply-email-message-wrapper .chat-room-reply-email-message-wrapper-inner .cancel-reply-email-button:hover {
        color: rgba(25, 25, 25, 0.8); }
    .chat-room-reply-email-message-wrapper .chat-room-reply-email-message-wrapper-inner .reply-email-content-outer-wrapper {
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
      width: 100%;
      position: relative; }
      .chat-room-reply-email-message-wrapper .chat-room-reply-email-message-wrapper-inner .reply-email-content-outer-wrapper.with-border {
        padding-left: 4px; }
        .chat-room-reply-email-message-wrapper .chat-room-reply-email-message-wrapper-inner .reply-email-content-outer-wrapper.with-border::before {
          content: "";
          width: 4px;
          height: 100%;
          display: inline-block;
          position: absolute;
          top: 0;
          left: 0;
          background: #ff7e00;
          border-top-left-radius: 8px;
          border-bottom-left-radius: 8px; }
      .chat-room-reply-email-message-wrapper .chat-room-reply-email-message-wrapper-inner .reply-email-content-outer-wrapper .reply-email-file-media-wrapper {
        border-radius: 4px;
        width: 48px;
        height: 48px;
        display: inline-block;
        margin: 0 2px 0 0;
        vertical-align: top;
        position: relative;
        top: 1px; }
        .chat-room-reply-email-message-wrapper .chat-room-reply-email-message-wrapper-inner .reply-email-content-outer-wrapper .reply-email-file-media-wrapper .reply-email-message-image {
          width: 100%;
          position: absolute;
          top: 50%;
          left: 50%;
          -webkit-transform: translate(-50%, -50%);
                  transform: translate(-50%, -50%);
          max-width: 100%;
          max-height: 100%;
          object-fit: contain; }
        .chat-room-reply-email-message-wrapper .chat-room-reply-email-message-wrapper-inner .reply-email-content-outer-wrapper .reply-email-file-media-wrapper .reply-email-message-video {
          width: 100%;
          position: absolute;
          top: 50%;
          left: 50%;
          -webkit-transform: translate(-50%, -50%);
                  transform: translate(-50%, -50%);
          max-width: 100%;
          max-height: 100%; }
        .chat-room-reply-email-message-wrapper .chat-room-reply-email-message-wrapper-inner .reply-email-content-outer-wrapper .reply-email-file-media-wrapper .reply-email-message-file {
          border-radius: 50%;
          width: 100%;
          height: 100%;
          position: relative;
          background: var(--main-dark-orange);
          cursor: pointer; }
          .chat-room-reply-email-message-wrapper .chat-room-reply-email-message-wrapper-inner .reply-email-content-outer-wrapper .reply-email-file-media-wrapper .reply-email-message-file:hover {
            background: var(--main-darker-orange); }
          .chat-room-reply-email-message-wrapper .chat-room-reply-email-message-wrapper-inner .reply-email-content-outer-wrapper .reply-email-file-media-wrapper .reply-email-message-file svg {
            color: #ffffff;
            font-size: 22px;
            position: absolute;
            top: 50%;
            left: 50%;
            -webkit-transform: translate(-50%, -50%);
                    transform: translate(-50%, -50%); }
      .chat-room-reply-email-message-wrapper .chat-room-reply-email-message-wrapper-inner .reply-email-content-outer-wrapper .reply-email-name-text-wrapper {
        padding: 8px 0 8px 8px; }
        .chat-room-reply-email-message-wrapper .chat-room-reply-email-message-wrapper-inner .reply-email-content-outer-wrapper .reply-email-name-text-wrapper.with-image-or-video-or-file {
          width: calc(100% - 64px);
          display: inline-block; }
        .chat-room-reply-email-message-wrapper .chat-room-reply-email-message-wrapper-inner .reply-email-content-outer-wrapper .reply-email-name-text-wrapper p {
          margin: 0; }
          .chat-room-reply-email-message-wrapper .chat-room-reply-email-message-wrapper-inner .reply-email-content-outer-wrapper .reply-email-name-text-wrapper p.reply-email-name {
            color: #191919;
            font-size: 12px;
            letter-spacing: -0.4px;
            line-height: 18px;
            max-width: 100%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis; }
            .chat-room-reply-email-message-wrapper .chat-room-reply-email-message-wrapper-inner .reply-email-content-outer-wrapper .reply-email-name-text-wrapper p.reply-email-name b {
              color: var(--main-dark-orange); }
          .chat-room-reply-email-message-wrapper .chat-room-reply-email-message-wrapper-inner .reply-email-content-outer-wrapper .reply-email-name-text-wrapper p.reply-email-text {
            color: #191919;
            font-size: 12px;
            letter-spacing: -0.2px;
            line-height: 16px;
            max-width: 100%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis; }

.modal-interactiveList-message .form-label label {
  width: 100%;
  position: relative; }
  .modal-interactiveList-message .form-label label b {
    font-size: 16px;
    letter-spacing: -0.3px; }
  .modal-interactiveList-message .form-label label.label-header-type b {
    font-size: 14px; }
  .modal-interactiveList-message .form-label label .grey-font {
    position: absolute;
    top: 1px;
    right: 0; }
  .modal-interactiveList-message .form-label label .label-sub {
    display: block;
    margin: 4px 0 0;
    width: 100%;
    position: relative;
    top: unset !important;
    right: unset !important;
    color: rgba(25, 25, 25, 0.6) !important; }
  .modal-interactiveList-message .form-label label .text-optional {
    position: relative;
    top: unset;
    right: unset; }

.modal-interactiveList-message .form-label .form-value {
  font-size: 16px;
  letter-spacing: -0.3px;
  margin-bottom: 0;
  color: rgba(25, 25, 25, 0.8); }

.modal-interactiveList-message {
  position: fixed;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100%;
  background: rgba(25, 25, 25, 0.8);
  visibility: hidden;
  opacity: 0;
  right: 0;
  -webkit-transition: visibility 0.3s, opacity 0.5s ease-in-out;
  transition: visibility 0.3s, opacity 0.5s ease-in-out;
  z-index: 5; }
  .modal-interactiveList-message.active-modal-interactiveList-message {
    /* right: 0; */
    opacity: 1;
    visibility: visible;
    -webkit-transition: visibility 0s, opacity 0.5s ease-in-out;
    transition: visibility 0s, opacity 0.5s ease-in-out; }
  .modal-interactiveList-message .modal-body {
    position: absolute;
    top: 8px;
    right: -100%;
    left: unset;
    -webkit-transform: unset;
            transform: unset;
    height: calc(100%);
    width: 640px;
    display: inline-block;
    border-radius: 8px 0 0 0;
    background: #ffffff;
    padding: 0;
    max-height: calc(100%);
    -webkit-transition: ease-in-out 0.4s;
    transition: ease-in-out 0.4s; }
    .modal-interactiveList-message .modal-body.action-modal-body-interactiveList-message {
      right: 0; }
    .modal-interactiveList-message .modal-body .close-modal-interactiveList {
      border-radius: 50%;
      width: 48px;
      height: 48px;
      background: #ffffff;
      color: #757575;
      position: absolute;
      left: -65px;
      top: 0;
      text-align: center;
      cursor: pointer; }
      .modal-interactiveList-message .modal-body .close-modal-interactiveList:hover {
        background: #f9f9f9; }
      .modal-interactiveList-message .modal-body .close-modal-interactiveList svg {
        position: relative;
        font-size: 25px;
        top: 11px; }
    .modal-interactiveList-message .modal-body .interactiveList-message-wrapper {
      height: calc(100%);
      max-height: calc(100%);
      overflow: hidden;
      right: 0;
      -webkit-transition: ease-in-out .5s;
      transition: ease-in-out .5s;
      position: relative;
      display: block; }
      .modal-interactiveList-message .modal-body .interactiveList-message-wrapper .interactive-section-list {
        margin: 24px 0 0; }
        .modal-interactiveList-message .modal-body .interactiveList-message-wrapper .interactive-section-list .table-title {
          font-size: 16px;
          letter-spacing: -0.5px;
          margin: 24px 0 8px 0; }
          .modal-interactiveList-message .modal-body .interactiveList-message-wrapper .interactive-section-list .table-title:nth-child(1) {
            margin: 16px 0 8px 0; }
        .modal-interactiveList-message .modal-body .interactiveList-message-wrapper .interactive-section-list table {
          width: 100%;
          border-collapse: collapse; }
        .modal-interactiveList-message .modal-body .interactiveList-message-wrapper .interactive-section-list thead tr {
          border-bottom: solid 1px #dcdcdc; }
        .modal-interactiveList-message .modal-body .interactiveList-message-wrapper .interactive-section-list thead td {
          font-size: 14px;
          letter-spacing: -0.4px;
          padding: 8px; }
          .modal-interactiveList-message .modal-body .interactiveList-message-wrapper .interactive-section-list thead td:nth-child(1) {
            width: 50px; }
          .modal-interactiveList-message .modal-body .interactiveList-message-wrapper .interactive-section-list thead td:nth-child(2) {
            width: calc(50%); }
          .modal-interactiveList-message .modal-body .interactiveList-message-wrapper .interactive-section-list thead td:nth-child(3) {
            width: calc(50%); }
        .modal-interactiveList-message .modal-body .interactiveList-message-wrapper .interactive-section-list tbody tr:nth-child(even) {
          background: #f3f3f3; }
        .modal-interactiveList-message .modal-body .interactiveList-message-wrapper .interactive-section-list tbody td {
          font-size: 14px;
          letter-spacing: -0.2px;
          padding: 8px;
          word-break: break-word;
          vertical-align: top; }
      .modal-interactiveList-message .modal-body .interactiveList-message-wrapper > div > div {
        overflow-x: hidden !important; }
      .modal-interactiveList-message .modal-body .interactiveList-message-wrapper.interactiveList-message-wrapper-deactive {
        right: -700px;
        display: none; }
      .modal-interactiveList-message .modal-body .interactiveList-message-wrapper .comp-back-button {
        margin: 0 0 24px; }
      .modal-interactiveList-message .modal-body .interactiveList-message-wrapper label {
        display: inline-block !important; }
      .modal-interactiveList-message .modal-body .interactiveList-message-wrapper .interactiveList-message-container {
        padding: 32px; }
        .modal-interactiveList-message .modal-body .interactiveList-message-wrapper .interactiveList-message-container .interactiveList-message-title {
          color: #191919; }
          .modal-interactiveList-message .modal-body .interactiveList-message-wrapper .interactiveList-message-container .interactiveList-message-title svg {
            width: 24px;
            position: relative;
            top: -7px !important;
            margin-right: 4px !important; }
          .modal-interactiveList-message .modal-body .interactiveList-message-wrapper .interactiveList-message-container .interactiveList-message-title b {
            font-size: 20px;
            letter-spacing: -0.6px; }
          .modal-interactiveList-message .modal-body .interactiveList-message-wrapper .interactiveList-message-container .interactiveList-message-title svg {
            font-size: 24px;
            margin: 0 4px 0 0;
            position: relative;
            top: -2px; }
          .modal-interactiveList-message .modal-body .interactiveList-message-wrapper .interactiveList-message-container .interactiveList-message-title p {
            margin: 2px 0 0;
            color: rgba(25, 25, 25, 0.6);
            font-size: 14px;
            letter-spacing: -0.2px; }

.chat-room-start-chat-wrapper {
    text-align: center;
    padding: 96px 20px 0;
    height: calc(100vh - 120px);
  }
  .chat-room-start-chat-wrapper .new-chat-avatar {
    text-align: center;
    margin-bottom: 10px;
  }
  .chat-room-start-chat-wrapper .new-chat-avatar .new-chat-avatar-content {
    border-radius: 50%;
    width: 72px;
    height: 72px;
    display: inline-block;
    background: #ffffff;
    text-align: center;
    line-height: 72px;
    padding: 1px;
  }
  .chat-room-start-chat-wrapper .new-chat-avatar .new-chat-avatar-content:nth-child(1) {
    position: relative;
    right: -2px;
  }
  .chat-room-start-chat-wrapper .new-chat-avatar .new-chat-avatar-content:nth-child(2) {
    position: relative;
    left: -2px;
  }
  .chat-room-start-chat-wrapper .new-chat-avatar .new-chat-avatar-content img {
    width: 70px;
    height: 70px;
    display: inline-block;
    border-radius: 50%;
    vertical-align: top;
  }
  .chat-room-start-chat-wrapper .new-chat-avatar .new-chat-avatar-content .avatar-word-wrapper {
    width: 70px;
    height: 70px;
    display: inline-block;
    border-radius: 50%;
    background: pink;
    vertical-align: top;
    line-height: 70px;
  }
  .chat-room-start-chat-wrapper .new-chat-avatar .new-chat-avatar-content .avatar-word-wrapper b {
    color: #ffffff;
  }
  .chat-room-start-chat-wrapper b {
    font-size: 20px;
    letter-spacing: -0.6px;
    color: #191919;
  }
  .chat-room-start-chat-wrapper p {
    margin: 8px 0 0;
  }
.chat-room-user-info-wrapper {
  width: 340px;
  display: inline-block;
  -webkit-transition: ease-in-out .2s;
  transition: ease-in-out .2s;
  position: absolute;
  top: 0;
  right: -340px;
  background: #ffffff;
  vertical-align: top;
  height: 100%;
  top: -48px; }

.utm-detail-wrapper-inbox {
  border-top: solid 1px #dcdcdc;
  padding-top: 16px;
  margin-top: 16px; }
  .utm-detail-wrapper-inbox .utm-detail-wrapper-inbox-title {
    font-size: 14px;
    letter-spacing: -0.4px;
    color: rgba(25, 25, 25, 0.8);
    margin: 0 0 16px; }

.chat-room-user-info-wrapper.active-chat-room-user-info-wrapper {
  right: 0; }

.user-info-name-avatar-wrapper {
  width: 100%;
  position: relative;
  padding: 24px;
  background: #f3f3f3; }
  .user-info-name-avatar-wrapper img.user-avatar-name {
    width: 48px;
    height: 48px; }

.user-info-avatar-wrapper {
  border-radius: 50%;
  height: 48px;
  display: inline-block;
  width: 48px;
  vertical-align: top;
  background: pink;
  position: relative;
  text-align: center;
  line-height: 48px;
  color: #ffffff;
  font-size: 16px; }

.user-info-name-connectivity {
  width: calc(100% - 96px);
  display: inline-block;
  padding-left: 12px;
  line-height: 19px;
  position: absolute;
  top: 50%;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%);
  left: 72px; }
  .user-info-name-connectivity .main-user-name-outer {
    position: relative;
    display: block;
    width: 100%; }
    .user-info-name-connectivity .main-user-name-outer .main-user-name {
      display: inline-block;
      max-width: calc(100% - 104px);
      position: relative; }
      .user-info-name-connectivity .main-user-name-outer .main-user-name p {
        white-space: nowrap;
        overflow: hidden;
        margin: 0;
        text-overflow: ellipsis;
        font-size: 16px;
        letter-spacing: -0.5px;
        max-width: 100%;
        display: inline-block; }
      .user-info-name-connectivity .main-user-name-outer .main-user-name.hide-edit-alias {
        max-width: 100%; }
  .user-info-name-connectivity .edit-alias-button {
    color: #ff7d00;
    font-size: 12px;
    letter-spacing: -0.2px;
    position: absolute;
    top: 0;
    right: -110px;
    white-space: nowrap;
    cursor: pointer; }
    .user-info-name-connectivity .edit-alias-button svg {
      font-size: 16px;
      margin-right: 4px;
      position: relative;
      top: -1px; }
    .user-info-name-connectivity .edit-alias-button:hover {
      color: #e87200; }

.user-info-online {
  font-size: 10px;
  letter-spacing: 1.5px;
  color: rgba(25, 25, 25, 0.6);
  font-weight: bold; }
  .user-info-online svg {
    color: #19c700;
    position: relative;
    top: -1px;
    font-size: 13px;
    margin-right: 2px; }

.user-info-offline {
  cursor: pointer;
  border-radius: 4px;
  background: #ff7d00;
  color: #ffffff;
  font-size: 12px;
  line-height: 1.5;
  letter-spacing: -0.2px;
  padding: 1px 8px;
  display: inline-block;
  margin-top: 4px;
  -webkit-transition: ease-in-out .2s;
  transition: ease-in-out .2s; }
  .user-info-offline:hover {
    background: #e87200; }
  .user-info-offline svg {
    margin-right: 4px;
    position: relative;
    top: -1px; }

.user-info-offline-dot {
  font-size: 10px;
  letter-spacing: 1.5px;
  color: rgba(25, 25, 25, 0.6);
  font-weight: bold; }
  .user-info-offline-dot svg {
    color: #ff3f57;
    position: relative;
    top: -1px;
    font-size: 13px;
    margin-right: 2px; }

.user-info-tab-wrapper {
  width: 100%;
  position: relative;
  background: #f3f3f3; }

.user-info-tab-content {
  width: calc(100% / 3);
  display: inline-block;
  color: rgba(25, 25, 25, 0.6);
  text-align: center;
  font-size: 14px;
  letter-spacing: -0.4px;
  position: relative;
  padding: 8px 0; }
  .user-info-tab-content svg {
    font-size: 14px;
    margin-right: 4px;
    position: relative;
    top: -2px; }
  .user-info-tab-content:hover {
    color: #191919;
    cursor: pointer; }
  .user-info-tab-content:nth-child(1).active-room-list-tab {
    border-radius: 0 12px 0 0; }
  .user-info-tab-content:nth-last-child(1).active-room-list-tab {
    border-radius: 12px 0 0 0; }

.user-info-tab-content.active-room-list-tab {
  color: #ff7d00 !important;
  background: #ffffff;
  border-radius: 12px 12px 0 0; }

.main-user-info-wrapper {
  width: 100%;
  padding: 8px 12px; }

.user-info-list-wrapper {
  margin-bottom: 16px;
  width: 100%;
  text-align: left;
  position: relative; }
  .user-info-list-wrapper .tooltip-icon {
    position: relative;
    top: -2px;
    margin-left: 4px;
    cursor: pointer; }
  .user-info-list-wrapper label {
    font-size: 14px;
    letter-spacing: -0.4px;
    color: rgba(25, 25, 25, 0.8);
    display: block; }
  .user-info-list-wrapper .user-info-list-button-action {
    font-size: 12px;
    letter-spacing: -0.2px;
    color: #ff7d00;
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 9px; }
    .user-info-list-wrapper .user-info-list-button-action svg {
      font-size: 16px;
      margin-right: 4px;
      position: relative;
      top: -2px; }
    .user-info-list-wrapper .user-info-list-button-action:hover {
      color: var(--main-darker-orange); }

.user-info-box-wrapper {
  background: rgba(25, 25, 25, 0.05);
  border-radius: 8px;
  height: 40px;
  width: 100%;
  line-height: 40px;
  padding: 0 9px;
  font-size: 14px;
  color: #191919;
  width: 100%;
  position: relative;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden; }
  .user-info-box-wrapper.user-info-list-wrapper-white {
    background: #fff;
    border: solid 1px rgba(25, 25, 25, 0.05); }
  .user-info-box-wrapper input {
    border: none;
    background: none;
    height: 100%;
    width: calc(100% - 35px); }
  .user-info-box-wrapper img {
    position: relative;
    top: -2px;
    margin-right: 7px; }
  .user-info-box-wrapper.user-info-box-wrapper-topics {
    padding: 0 34px 0 9px; }
    .user-info-box-wrapper.user-info-box-wrapper-topics .button-edit-topic {
      color: #ff7d00;
      position: absolute;
      right: 9px;
      top: 50%;
      -webkit-transform: translate(0, -50%);
              transform: translate(0, -50%);
      cursor: pointer;
      margin: 0; }
  .user-info-box-wrapper.user-info-box-wrapper-multi-line {
    white-space: unset;
    text-overflow: unset;
    overflow: unset;
    word-break: break-word;
    min-height: 40px;
    height: unset;
    line-height: 24px;
    padding: 10px 9px; }

.user-info-label-wrapper {
  width: 100%;
  position: relative; }
  .user-info-label-wrapper .user-info-label-tag-content {
    font-size: 12px;
    letter-spacing: -0.4px;
    padding: 2px 8px;
    border-radius: 4px;
    margin-right: 4px;
    cursor: pointer;
    vertical-align: top;
    display: inline-block;
    height: unset;
    color: #ffffff;
    margin-bottom: 4px; }
    .user-info-label-wrapper .user-info-label-tag-content svg {
      margin-right: 4px;
      font-size: 12px;
      vertical-align: top;
      position: relative;
      top: 3px; }
    .user-info-label-wrapper .user-info-label-tag-content.user-info-label-tag-content-add {
      color: rgba(25, 25, 25, 0.6);
      -webkit-transition: ease-in-out .1s;
      transition: ease-in-out .1s; }
      .user-info-label-wrapper .user-info-label-tag-content.user-info-label-tag-content-add:hover {
        color: #ff7d00;
        background: rgba(255, 126, 0, 0.1); }

.user-info-box-wrapper-multiple-line {
  background: rgba(25, 25, 25, 0.05);
  border-radius: 8px;
  width: 100%;
  line-height: 21px;
  padding: 10px 8px 10px 36px;
  font-size: 14px;
  letter-spacing: -0.2px;
  color: #191919;
  width: 100%;
  position: relative;
  min-height: 40px;
  word-break: break-word; }
  .user-info-box-wrapper-multiple-line input {
    border: none;
    background: none;
    height: 100%; }
  .user-info-box-wrapper-multiple-line img {
    position: absolute;
    top: 12px;
    left: 8px;
    margin-right: 7px; }

.user-info-box-wrapper-multiple-line.no-icon {
  padding: 10px 8px 10px 8px;
  margin-bottom: 16px; }

.user-info-box-wrapper.with-button-copy {
  padding: 0 65px 0 8px; }

.user-detail-copy-wrapper {
  position: absolute;
  right: 12px;
  top: 50%;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%);
  color: rgba(25, 25, 25, 0.6);
  font-size: 12px;
  line-height: -0.2px;
  cursor: pointer; }
  .user-detail-copy-wrapper:hover {
    color: rgba(25, 25, 25, 0.8); }
  .user-detail-copy-wrapper svg {
    font-size: 16px;
    margin-right: 4px; }

.user-info-tab {
  max-height: calc(100% - 132px);
  height: 100%;
  overflow-y: hidden; }

.user-info-tags {
  font-size: 12px;
  letter-spacing: -0.4px;
  color: rgba(25, 25, 25, 0.6);
  line-height: 1.5;
  padding: 0 8px;
  border-radius: 4px;
  margin-right: 4px;
  display: inline-block;
  background: rgba(25, 25, 25, 0.06);
  vertical-align: top;
  margin-bottom: 5px; }

.case-detail-loading-wrapper {
  font-size: 16px;
  letter-spacing: -0.5px;
  color: #ff7d00;
  text-align: center;
  padding: 24px; }
  .case-detail-loading-wrapper .lds-ring {
    top: 3px;
    right: unset;
    position: relative;
    margin-right: 5px; }
    .case-detail-loading-wrapper .lds-ring div {
      margin: 0; }

.modal-edit-alias .modal-content {
  width: 476px;
  border-radius: 8px; }
  .modal-edit-alias .modal-content .modal-body {
    padding: 32px; }
    .modal-edit-alias .modal-content .modal-body p.title {
      font-size: 20px;
      letter-spacing: -0.6px;
      color: #191919; }
    .modal-edit-alias .modal-content .modal-body input {
      width: 100%;
      font-size: 16px;
      letter-spacing: -0.3px;
      color: #191919;
      line-height: 1;
      padding: 16px;
      border-radius: 8px;
      border: solid 1px #dcdcdc;
      margin: 0 0 24px 0; }
      .modal-edit-alias .modal-content .modal-body input:focus {
        border: solid 1px #ff7d00; }
    .modal-edit-alias .modal-content .modal-body textarea {
      width: 100%;
      font-size: 16px;
      letter-spacing: -0.3px;
      color: #191919;
      line-height: 1;
      padding: 12px 16px;
      border-radius: 8px;
      border: solid 1px #dcdcdc;
      margin: 0 0 24px 0;
      resize: none;
      line-height: 21px; }
      .modal-edit-alias .modal-content .modal-body textarea:focus {
        border: solid 1px #ff7d00; }
    .modal-edit-alias .modal-content .modal-body button {
      width: calc(50% - 4px);
      display: inline-block;
      margin: 0 8px 0 0;
      height: 48px;
      text-align: center; }
      .modal-edit-alias .modal-content .modal-body button:nth-last-child(1) {
        margin: 0; }

.channel-icon-detail {
  width: 24px; }

.chat-room-case-detail-tab-wrapper {
  font-size: 0; }
  .chat-room-case-detail-tab-wrapper button.add-label {
    background-color: transparent;
    font-size: 12px;
    color: #191919;
    border: 1px solid #FF7E00;
    border-radius: 4px;
    height: 22px;
    color: #FF7E00;
    padding: 0 6px; }
    .chat-room-case-detail-tab-wrapper button.add-label svg {
      margin-right: 4px;
      color: #FF7E00;
      position: relative;
      top: -1px; }
  .chat-room-case-detail-tab-wrapper .box-labels label {
    margin-bottom: 4px; }
  .chat-room-case-detail-tab-wrapper .box-labels .labels-wrapper {
    display: flex;
    width: 100%;
    flex-wrap: wrap; }
    .chat-room-case-detail-tab-wrapper .box-labels .labels-wrapper .label-item {
      display: inline-block;
      align-items: center;
      margin-right: 4px;
      color: #ffffff;
      height: 22px;
      border-radius: 4px;
      width: -webkit-fit-content;
      width: -moz-fit-content;
      width: fit-content;
      padding: 2px 12px 4px 9px;
      font-size: 12px;
      position: relative;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      margin-bottom: 4px; }
      .chat-room-case-detail-tab-wrapper .box-labels .labels-wrapper .label-item svg {
        color: #ffffff;
        position: relative;
        margin-right: 5px;
        cursor: pointer;
        font-size: 13px;
        vertical-align: top;
        top: 3px; }

.modal-edit-topic input {
  margin: 0 !important; }

.modal-confirmation-edit-topic .modal-footer button {
  width: calc(50% - 2px) !important; }
  .modal-confirmation-edit-topic .modal-footer button:nth-child(2) {
    margin: 0; }

.modal-add-label .modal-content {
  width: 476px;
  border-radius: 8px; }
  .modal-add-label .modal-content .modal-body {
    padding: 32px; }
    .modal-add-label .modal-content .modal-body .add-label-wrapper p.title {
      font-size: 20px;
      letter-spacing: -0.6px;
      color: #191919; }
    .modal-add-label .modal-content .modal-body .add-label-wrapper input {
      width: 100%;
      font-size: 16px;
      letter-spacing: -0.3px;
      color: #191919;
      line-height: 1;
      padding: 16px;
      border-radius: 8px;
      border: solid 1px #dcdcdc;
      margin: 0 0 24px 0; }
      .modal-add-label .modal-content .modal-body .add-label-wrapper input:focus {
        border: solid 1px #ff7d00; }
    .modal-add-label .modal-content .modal-body .add-label-wrapper .button-container {
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin-top: 24px; }
      .modal-add-label .modal-content .modal-body .add-label-wrapper .button-container button {
        width: 50%; }
        .modal-add-label .modal-content .modal-body .add-label-wrapper .button-container button.orange-button {
          margin-left: 8px; }
    .modal-add-label .modal-content .modal-body .add-label-wrapper .label-value {
      font-size: 12px;
      letter-spacing: -0.4px;
      display: inline-block;
      margin: 0 4px 4px 0;
      vertical-align: top;
      border-radius: 4px;
      color: #ffffff;
      padding: 2px 8px; }
      .modal-add-label .modal-content .modal-body .add-label-wrapper .label-value b {
        font-size: 14px;
        line-height: 1.5;
        letter-spacing: -0.4px; }
      .modal-add-label .modal-content .modal-body .add-label-wrapper .label-value svg {
        position: relative;
        vertical-align: top;
        margin-right: 4px;
        top: 5px;
        color: #ffffff;
        cursor: pointer; }
      .modal-add-label .modal-content .modal-body .add-label-wrapper .label-value.new-label {
        background-color: rgba(255, 126, 0, 0.1);
        color: #ff7d00; }
        .modal-add-label .modal-content .modal-body .add-label-wrapper .label-value.new-label svg {
          top: 3px;
          color: #ff7d00; }
    .modal-add-label .modal-content .modal-body .add-label-wrapper .label-value-wrapper {
      width: 100%;
      position: relative;
      padding: 16px 50px 12px 12px;
      border-radius: 8px;
      border: solid 1px #dcdcdc;
      height: 56px;
      overflow: auto;
      display: flex;
      flex-wrap: wrap;
      align-items: center; }
      .modal-add-label .modal-content .modal-body .add-label-wrapper .label-value-wrapper .label-value {
        display: flex; }
      .modal-add-label .modal-content .modal-body .add-label-wrapper .label-value-wrapper.focus {
        border: 1px solid #FF7E00; }
      .modal-add-label .modal-content .modal-body .add-label-wrapper .label-value-wrapper.blur {
        border: 1px solid rgba(25, 25, 25, 0.1); }
      .modal-add-label .modal-content .modal-body .add-label-wrapper .label-value-wrapper .search-add-label-icon {
        color: #191919;
        font-size: 24px;
        position: fixed;
        right: 55px;
        top: 94px; }
      .modal-add-label .modal-content .modal-body .add-label-wrapper .label-value-wrapper input {
        border: none !important;
        display: inline-block;
        width: unset;
        outline: none;
        font-size: 16px;
        letter-spacing: -0.3px;
        color: rgba(25, 25, 25, 0.6);
        margin: 0;
        padding: 0;
        flex-grow: 1;
        position: relative;
        top: -2px;
        border-radius: 0 !important; }
    .modal-add-label .modal-content .modal-body .add-label-wrapper .add-label-suggestion-wrapper {
      max-height: 190px;
      width: 412px;
      border-radius: 8px;
      background: #ffffff;
      overflow: auto;
      position: absolute;
      box-shadow: 0 2px 20px 0 rgba(25, 25, 25, 0.05);
      display: none;
      z-index: 3; }
      .modal-add-label .modal-content .modal-body .add-label-wrapper .add-label-suggestion-wrapper .loading-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 40px; }
      .modal-add-label .modal-content .modal-body .add-label-wrapper .add-label-suggestion-wrapper ul {
        list-style: none;
        margin: 0;
        padding: 0; }
        .modal-add-label .modal-content .modal-body .add-label-wrapper .add-label-suggestion-wrapper ul li {
          width: 100%;
          padding: 8px 16px;
          cursor: pointer; }
          .modal-add-label .modal-content .modal-body .add-label-wrapper .add-label-suggestion-wrapper ul li:hover {
            background: #FFF2E5; }
          .modal-add-label .modal-content .modal-body .add-label-wrapper .add-label-suggestion-wrapper ul li.no-option {
            color: rgba(25, 25, 25, 0.6);
            text-align: center;
            font-size: 16px;
            letter-spacing: -0.3px; }
            .modal-add-label .modal-content .modal-body .add-label-wrapper .add-label-suggestion-wrapper ul li.no-option:hover {
              background: none; }
          .modal-add-label .modal-content .modal-body .add-label-wrapper .add-label-suggestion-wrapper ul li .label-value {
            margin: 0 4px 0 0; }

.chat-room-chat-history-tab-wrapper {
  overflow: hidden;
  height: 100%; }

.chat-history-switch-content-outer {
  padding: 12px; }
  .chat-history-switch-content-outer .chat-history-switch-content {
    width: 100%;
    border-radius: 12px;
    height: 29px;
    box-shadow: 0 0 1px #dcdcdc;
    position: relative;
    background: #f3f3f3;
    cursor: pointer;
    margin-top: 3px; }
    .chat-history-switch-content-outer .chat-history-switch-content .chat-history-active-panel {
      width: 50%;
      content: "";
      background: #ffffff;
      border-radius: 12px;
      height: 27px;
      position: absolute;
      left: 1px;
      top: 1px;
      -webkit-transition: ease-in-out .2s;
      transition: ease-in-out .2s; }
      .chat-history-switch-content-outer .chat-history-switch-content .chat-history-active-panel.active-broadcast-panel {
        left: calc(50% - 1px); }
    .chat-history-switch-content-outer .chat-history-switch-content .chat-history-switch-panel {
      width: 50%;
      display: inline-block;
      text-align: center;
      font-size: 14px;
      letter-spacing: -0.4px;
      color: rgba(25, 25, 25, 0.6);
      padding-top: 4px; }
      .chat-history-switch-content-outer .chat-history-switch-content .chat-history-switch-panel:hover {
        color: #191919; }
      .chat-history-switch-content-outer .chat-history-switch-content .chat-history-switch-panel div {
        display: inline-block;
        position: relative; }
      .chat-history-switch-content-outer .chat-history-switch-content .chat-history-switch-panel .counter-case {
        font-size: 11px;
        color: #ffffff;
        text-align: center;
        background: #707070;
        border-radius: 12px;
        margin-left: 4px;
        height: 18px;
        display: inline-block;
        padding: 1px 0 0;
        min-width: 18px;
        position: relative;
        top: -1px;
        padding: 1px 5px 0 4px; }
      .chat-history-switch-content-outer .chat-history-switch-content .chat-history-switch-panel.active-chat-panel {
        color: #191919; }
        .chat-history-switch-content-outer .chat-history-switch-content .chat-history-switch-panel.active-chat-panel .counter-case {
          background: #474747; }
      .chat-history-switch-content-outer .chat-history-switch-content .chat-history-switch-panel .centered-dot {
        width: 2px;
        height: 2px;
        display: inline-block;
        vertical-align: top;
        background: rgba(25, 25, 25, 0.6);
        border-radius: 50%;
        position: relative;
        top: 10px; }
      .chat-history-switch-content-outer .chat-history-switch-content .chat-history-switch-panel.active-broadcast-panel-tab {
        color: #191919; }

.main-chat-history-content {
  height: calc(100% - 57px);
  position: relative; }

.hide-panel-chat-history {
  display: none; }

.chat-history-outer-wrapper {
  overflow-y: auto;
  max-height: 100%;
  min-height: 100%;
  position: relative; }
  .chat-history-outer-wrapper .lds-ring {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    width: 28px;
    height: 28px; }
    .chat-history-outer-wrapper .lds-ring div {
      margin: 0;
      width: 28px;
      border: solid 3px #fff;
      height: 28px;
      border-color: var(--main-dark-orange) transparent transparent transparent; }
  .chat-history-outer-wrapper .empty-broadcast-list {
    text-align: center;
    padding: 0 16px;
    position: absolute;
    top: 50%;
    left: 0;
    -webkit-transform: translate(0, -50%);
            transform: translate(0, -50%);
    width: 100%; }
    .chat-history-outer-wrapper .empty-broadcast-list p:nth-child(1) {
      color: #191919;
      margin: 0 0 8px;
      line-height: 1; }
    .chat-history-outer-wrapper .empty-broadcast-list p:nth-last-child(1) {
      color: rgba(25, 25, 25, 0.6);
      margin: 0; }

.chat-history-tab-list {
  padding: 8px;
  cursor: pointer;
  border-bottom: solid 1px #dcdcdc; }

.chat-history-tab-list:hover {
  background: rgba(25, 25, 25, 0.1); }

.chat-history-omnichannel-icon {
  position: relative;
  top: -2px;
  width: 24px; }

.chat-history-topic-name {
  width: calc(100% - 24px);
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 4px;
  font-size: 16px;
  letter-spacing: -0.5px;
  vertical-align: top;
  margin: 0; }

.chat-history-last-message {
  margin: 4px 0 0;
  position: relative;
  top: -1px;
  font-size: 14px;
  letter-spacing: -0.2px;
  color: rgba(25, 25, 25, 0.6);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

.chat-history-time-and-status-wrapper {
  width: 100%;
  position: relative; }

.chat-history-time-wrapper {
  font-size: 12px;
  letter-spacing: -0.4px;
  color: rgba(25, 25, 25, 0.4);
  margin-top: 7px; }

.chat-history-time-wrapper svg {
  position: relative;
  top: -1px;
  font-size: 12px;
  margin-right: 3px; }

.chat-history-case-status {
  position: absolute;
  right: 0;
  top: 50%;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%);
  font-size: 12px;
  letter-spacing: -0.4px;
  border-radius: 4px;
  padding: 2px 12px 2px 8px;
  line-height: 18px;
  border-radius: 4px;
  background: #19c700;
  color: #ffffff;
  max-width: 125px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

.chat-history-case-status svg {
  position: relative;
  top: -1px;
  margin-right: 4px; }

.chat-history-case-status.chat-history-other-agent {
  background: #0052ff; }

.chat-history-case-status.chat-history-solved {
  color: rgba(25, 25, 25, 0.6);
  background: rgba(25, 25, 25, 0.05); }

.broadcast-history-outer-wrapper {
  overflow-y: auto;
  max-height: 100%;
  height: 100%;
  width: 100%;
  position: relative; }
  .broadcast-history-outer-wrapper .empty-broadcast-list {
    text-align: center;
    padding: 0 16px;
    position: absolute;
    top: 50%;
    left: 0;
    -webkit-transform: translate(0, -50%);
            transform: translate(0, -50%);
    width: 100%; }
    .broadcast-history-outer-wrapper .empty-broadcast-list p:nth-child(1) {
      color: #191919;
      margin: 0 0 8px;
      line-height: 1; }
    .broadcast-history-outer-wrapper .empty-broadcast-list p:nth-last-child(1) {
      color: rgba(25, 25, 25, 0.6);
      margin: 0; }

.broadcast-history-tab-list {
  padding: 8px;
  cursor: pointer;
  border-bottom: solid 1px #dcdcdc; }

.broadcast-history-tab-list:hover {
  background: rgba(25, 25, 25, 0.1); }

.broadcast-history-omnichannel-icon {
  position: relative;
  top: -2px;
  width: 24px; }

.broadcast-history-topic-name {
  width: calc(100% - 24px);
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 4px;
  font-size: 16px;
  letter-spacing: -0.5px;
  vertical-align: top;
  margin: 0; }

.broadcast-history-last-message {
  margin: 4px 0 0;
  position: relative;
  top: -1px;
  font-size: 14px;
  letter-spacing: -0.2px;
  color: rgba(25, 25, 25, 0.6);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

.broadcast-history-time-and-status-wrapper {
  width: 100%;
  position: relative; }

.broadcast-history-time-wrapper {
  font-size: 12px;
  letter-spacing: -0.4px;
  color: rgba(25, 25, 25, 0.4);
  margin-top: 7px; }

.broadcast-history-time-wrapper img {
  position: relative;
  top: -1px;
  font-size: 13px;
  margin-right: 3px; }

.broadcast-history-case-status {
  position: absolute;
  right: 0;
  top: 50%;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%);
  font-size: 12px;
  letter-spacing: -0.4px;
  border-radius: 4px;
  padding: 2px 12px 2px 8px;
  line-height: 18px;
  border-radius: 4px;
  background: #19c700;
  color: #ffffff;
  max-width: 125px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

.broadcast-history-case-status svg {
  position: relative;
  top: -1px;
  margin-right: 4px; }

.broadcast-history-case-status.broadcast-history-other-agent {
  background: #0052ff; }

.broadcast-history-case-status.broadcast-history-solved {
  color: rgba(25, 25, 25, 0.6);
  background: rgba(25, 25, 25, 0.05); }

.case-nation-flag {
  width: 22px;
  position: relative;
  top: 1px !important; }

.user-info-list-wrapper.remarks-for-contact {
  border-top: solid 1px #1919191A;
  padding-top: 8px; }

.contact-segment-text {
  font-size: 12px;
  letter-spacing: -0.2px;
  margin: 0; }

.modal-select-segment .modal-content {
  width: 840px;
  max-width: 100vw;
  height: 85vh;
  max-height: 85vh;
  overflow: auto; }
  .modal-select-segment .modal-content .modal-body {
    padding: 32px; }

.option-select-segment-wrapper {
  width: 100%;
  position: relative; }
  .option-select-segment-wrapper .select-all {
    border-bottom: solid 1px #dcdcdc;
    padding: 12px 17px 12px 17px !important; }
  .option-select-segment-wrapper .main-title {
    font-size: 20px;
    letter-spacing: -0.6px;
    margin: 0 0 22px 0;
    line-height: 1; }
    .option-select-segment-wrapper .main-title img {
      position: relative;
      top: -1px;
      margin-right: 4px; }
  .option-select-segment-wrapper .option-select-segment-wrapper-50 {
    width: calc(50% - 8px);
    position: relative;
    vertical-align: top;
    display: inline-block;
    margin: 0 16px 0 0; }
    .option-select-segment-wrapper .option-select-segment-wrapper-50 label {
      position: relative;
      width: 100%;
      font-size: 14px;
      letter-spacing: -0.4px; }
      .option-select-segment-wrapper .option-select-segment-wrapper-50 label .clear-segment {
        position: absolute;
        bottom: 0;
        right: 0;
        cursor: pointer;
        color: var(--main-dark-orange); }
        .option-select-segment-wrapper .option-select-segment-wrapper-50 label .clear-segment svg {
          position: relative;
          font-size: 16px;
          top: -2px;
          margin-right: 4px; }
        .option-select-segment-wrapper .option-select-segment-wrapper-50 label .clear-segment:hover {
          color: var(--main-darker-orange); }
    .option-select-segment-wrapper .option-select-segment-wrapper-50:nth-child(5) {
      margin: 0; }
    .option-select-segment-wrapper .option-select-segment-wrapper-50 span {
      color: rgba(25, 25, 25, 0.6);
      position: relative !important; }
    .option-select-segment-wrapper .option-select-segment-wrapper-50 .selection-segment-wrapper {
      border-radius: 8px;
      font-size: 14px;
      letter-spacing: -0.2px;
      box-shadow: 0 0 0 1px #dcdcdc;
      overflow: hidden;
      position: relative; }
      .option-select-segment-wrapper .option-select-segment-wrapper-50 .selection-segment-wrapper .select-content {
        width: 100%;
        padding: 12px 17px 6px 17px;
        margin: 0;
        white-space: nowrap; }
        .option-select-segment-wrapper .option-select-segment-wrapper-50 .selection-segment-wrapper .select-content label {
          margin: 0;
          max-width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          display: inline-block !important;
          padding: 0 0 0 22px !important; }
        .option-select-segment-wrapper .option-select-segment-wrapper-50 .selection-segment-wrapper .select-content input {
          display: none; }
        .option-select-segment-wrapper .option-select-segment-wrapper-50 .selection-segment-wrapper .select-content:hover {
          background: #FFF2E5; }
        .option-select-segment-wrapper .option-select-segment-wrapper-50 .selection-segment-wrapper .select-content:nth-child(1) {
          border-top-left-radius: 8px;
          border-top-right-radius: 8px; }
      .option-select-segment-wrapper .option-select-segment-wrapper-50 .selection-segment-wrapper .search-box-wrapper {
        width: 100%; }
        .option-select-segment-wrapper .option-select-segment-wrapper-50 .selection-segment-wrapper .search-box-wrapper input[type="text"] {
          border: none !important;
          border-bottom: solid 1px #dcdcdc !important;
          border-radius: 0;
          background: transparent;
          width: 100%;
          position: relative;
          padding: 0 42px 0 16px;
          height: 40px;
          margin: 0 !important; }
        .option-select-segment-wrapper .option-select-segment-wrapper-50 .selection-segment-wrapper .search-box-wrapper #search-icon {
          width: 40px;
          right: 0 !important;
          height: 40px;
          background: transparent !important; }
      .option-select-segment-wrapper .option-select-segment-wrapper-50 .selection-segment-wrapper .overflow-select-segment {
        max-height: calc(100vh - 374px);
        min-height: calc(100vh - 374px);
        overflow-y: auto; }
        .option-select-segment-wrapper .option-select-segment-wrapper-50 .selection-segment-wrapper .overflow-select-segment .select-content img {
          margin: 0 0 20px 0; }
        .option-select-segment-wrapper .option-select-segment-wrapper-50 .selection-segment-wrapper .overflow-select-segment .select-content:nth-child(1) {
          border-top-left-radius: 0px;
          border-top-right-radius: 0px; }
        .option-select-segment-wrapper .option-select-segment-wrapper-50 .selection-segment-wrapper .overflow-select-segment .select-content.checkbox-all {
          border-bottom: solid 1px #dcdcdc; }
        .option-select-segment-wrapper .option-select-segment-wrapper-50 .selection-segment-wrapper .overflow-select-segment .select-content.not-found {
          text-align: center;
          color: rgba(25, 25, 25, 0.4);
          background: #fff !important;
          position: absolute;
          top: 50%;
          left: 50%;
          width: 100%;
          -webkit-transform: translate(-50%, -50%);
                  transform: translate(-50%, -50%); }
          .option-select-segment-wrapper .option-select-segment-wrapper-50 .selection-segment-wrapper .overflow-select-segment .select-content.not-found p {
            padding: 0 40px; }
        .option-select-segment-wrapper .option-select-segment-wrapper-50 .selection-segment-wrapper .overflow-select-segment .select-content.custom-checkbox-stripe [type="checkbox"]:checked + label:after {
          top: 2px;
          left: 0px;
          width: 16px;
          height: 16px; }
        .option-select-segment-wrapper .option-select-segment-wrapper-50 .selection-segment-wrapper .overflow-select-segment .select-content.custom-checkbox [type="checkbox"]:checked + label:after {
          top: -3px;
          left: -5px; }
    .option-select-segment-wrapper .option-select-segment-wrapper-50 .select-segment-wrapper {
      width: 100%;
      height: calc(100vh - 333px);
      position: relative; }
      .option-select-segment-wrapper .option-select-segment-wrapper-50 .select-segment-wrapper .selected-segment-container {
        width: 100%;
        min-height: 100%;
        overflow-y: auto;
        max-height: 100%;
        border: solid 1px #dcdcdc;
        border-radius: 8px;
        padding: 16px 16px 8px 16px; }
        .option-select-segment-wrapper .option-select-segment-wrapper-50 .select-segment-wrapper .selected-segment-container .no-selected-segment {
          display: flex;
          flex-direction: column;
          align-items: center;
          position: absolute;
          top: 50%;
          left: 50%;
          -webkit-transform: translate(-50%, -50%);
                  transform: translate(-50%, -50%);
          width: 100%; }
          .option-select-segment-wrapper .option-select-segment-wrapper-50 .select-segment-wrapper .selected-segment-container .no-selected-segment img {
            margin-bottom: 20px; }
          .option-select-segment-wrapper .option-select-segment-wrapper-50 .select-segment-wrapper .selected-segment-container .no-selected-segment p {
            color: rgba(25, 25, 25, 0.4);
            font-size: 14px;
            line-height: 21px;
            letter-spacing: -0.2px; }
  .option-select-segment-wrapper .contact-segment-button-wrapper {
    text-align: right;
    width: 100%;
    margin-top: 20px; }
    .option-select-segment-wrapper .contact-segment-button-wrapper button {
      margin: 0 0 0 8px;
      width: 181px; }

.modal-confirm-contact-segment .modal-content {
  width: 476px; }
  .modal-confirm-contact-segment .modal-content button {
    width: calc(50% - 2px) !important;
    margin-right: 4px !important; }
    .modal-confirm-contact-segment .modal-content button:nth-last-child(1) {
      margin: 0 !important; }

.case-action-history-wrapper {
  width: 100vw;
  height: 100vh;
  background: rgba(25, 25, 25, 0.8);
  position: fixed;
  top: 0;
  /* right: -100vw; */
  visibility: hidden;
  opacity: 0;
  right: 0;
  /* transition: visibility 0s, opacity 0.3s ease-in-out; */
  -webkit-transition: visibility .3s, opacity 0.5s ease-in-out;
  transition: visibility .3s, opacity 0.5s ease-in-out;
  z-index: 4; }
  .case-action-history-wrapper.active-case-action-history {
    opacity: 1;
    visibility: visible;
    -webkit-transition: visibility 0s, opacity 0.5s ease-in-out;
    transition: visibility 0s, opacity 0.5s ease-in-out; }
  .case-action-history-wrapper .case-action-history-main-wrapper {
    width: 340px;
    -webkit-transition: ease-in-out 0.5s;
    transition: ease-in-out 0.5s;
    background: #ffffff;
    height: calc(100vh - 8px);
    border-top-left-radius: 18px;
    bottom: 0;
    right: -405px;
    position: fixed; }
    .case-action-history-wrapper .case-action-history-main-wrapper.active-case-action-history-main-wrapper {
      right: 0; }
    .case-action-history-wrapper .case-action-history-main-wrapper .case-action-history-close {
      background: #ffffff;
      border-radius: 50%;
      cursor: pointer;
      position: absolute;
      width: 48px;
      height: 48px;
      display: inline-block;
      text-align: center;
      line-height: 45px;
      top: 0;
      left: -64px; }
      .case-action-history-wrapper .case-action-history-main-wrapper .case-action-history-close svg {
        font-size: 24px;
        color: #7D7C7D; }
    .case-action-history-wrapper .case-action-history-main-wrapper .title-case-action-history {
      font-size: 24px;
      letter-spacing: -0.6px;
      padding: 24px;
      border-bottom: solid 1px #dcdcdc;
      margin: 0; }
    .case-action-history-wrapper .case-action-history-main-wrapper .case-action-history-list-wrapper {
      width: 100%;
      height: calc(100% - 85px);
      max-height: calc(100% - 85px); }
      .case-action-history-wrapper .case-action-history-main-wrapper .case-action-history-list-wrapper .loading-case-action {
        text-align: center;
        color: #ff7d00;
        padding-top: 12px; }
        .case-action-history-wrapper .case-action-history-main-wrapper .case-action-history-list-wrapper .loading-case-action .lds-ring {
          vertical-align: top;
          top: 3px;
          right: unset;
          margin-right: 4px; }
          .case-action-history-wrapper .case-action-history-main-wrapper .case-action-history-list-wrapper .loading-case-action .lds-ring div {
            margin: 0; }
      .case-action-history-wrapper .case-action-history-main-wrapper .case-action-history-list-wrapper .on-error {
        text-align: center;
        color: #ff7d00; }
        .case-action-history-wrapper .case-action-history-main-wrapper .case-action-history-list-wrapper .on-error svg {
          position: relative;
          margin-right: 4px;
          top: -1px; }
        .case-action-history-wrapper .case-action-history-main-wrapper .case-action-history-list-wrapper .on-error p {
          cursor: pointer;
          display: inline-block; }
          .case-action-history-wrapper .case-action-history-main-wrapper .case-action-history-list-wrapper .on-error p:hover {
            color: #e87200; }
      .case-action-history-wrapper .case-action-history-main-wrapper .case-action-history-list-wrapper .case-action-history-card {
        padding: 8px 20px 8px 52px;
        position: relative;
        margin-bottom: 12px; }
        .case-action-history-wrapper .case-action-history-main-wrapper .case-action-history-list-wrapper .case-action-history-card::before {
          position: absolute;
          border-radius: 50%;
          width: 16px;
          height: 16px;
          left: 20px;
          top: 10px;
          content: "";
          border: solid 2px rgba(25, 25, 25, 0.2);
          background: #ffffff;
          z-index: 2; }
        .case-action-history-wrapper .case-action-history-main-wrapper .case-action-history-list-wrapper .case-action-history-card::after {
          position: absolute;
          width: 1px;
          height: 100%;
          left: 27px;
          top: 24px;
          content: "";
          border-left: dashed 2px rgba(25, 25, 25, 0.2);
          z-index: 1; }
        .case-action-history-wrapper .case-action-history-main-wrapper .case-action-history-list-wrapper .case-action-history-card:nth-child(1)::before {
          border: solid 2px #ff7d00; }
        .case-action-history-wrapper .case-action-history-main-wrapper .case-action-history-list-wrapper .case-action-history-card p {
          max-width: 100%; }
          .case-action-history-wrapper .case-action-history-main-wrapper .case-action-history-list-wrapper .case-action-history-card p.action-time {
            font-size: 12px;
            letter-spacing: -0.2px;
            color: rgba(25, 25, 25, 0.4);
            margin: 0; }
            .case-action-history-wrapper .case-action-history-main-wrapper .case-action-history-list-wrapper .case-action-history-card p.action-time svg {
              position: relative;
              margin-right: 4px;
              top: -1px; }
          .case-action-history-wrapper .case-action-history-main-wrapper .case-action-history-list-wrapper .case-action-history-card p.action-text {
            margin: 0;
            font-size: 14px;
            letter-spacing: -0.4px;
            color: rgba(25, 25, 25, 0.6);
            word-break: break-word; }
        .case-action-history-wrapper .case-action-history-main-wrapper .case-action-history-list-wrapper .case-action-history-card:nth-last-child(1)::after {
          display: none; }
        .case-action-history-wrapper .case-action-history-main-wrapper .case-action-history-list-wrapper .case-action-history-card:nth-child(1) {
          margin-top: 12px; }

.case-shared-media-wrapper {
  width: 100vw;
  height: 100vh;
  background: rgba(25, 25, 25, 0.8);
  position: fixed;
  top: 0;
  /* right: -100vw; */
  visibility: hidden;
  opacity: 0;
  right: 0;
  /* transition: visibility 0s, opacity 0.3s ease-in-out; */
  -webkit-transition: visibility .3s, opacity 0.5s ease-in-out;
  transition: visibility .3s, opacity 0.5s ease-in-out;
  z-index: 4; }
  .case-shared-media-wrapper.active-case-shared-media {
    opacity: 1;
    visibility: visible;
    -webkit-transition: visibility 0s, opacity 0.5s ease-in-out;
    transition: visibility 0s, opacity 0.5s ease-in-out; }
  .case-shared-media-wrapper .case-shared-media-main-wrapper {
    width: 540px;
    -webkit-transition: ease-in-out 0.5s;
    transition: ease-in-out 0.5s;
    background: #ffffff;
    height: calc(100vh - 8px);
    border-top-left-radius: 18px;
    bottom: 0;
    right: -405px;
    position: fixed; }
    .case-shared-media-wrapper .case-shared-media-main-wrapper.active-case-shared-media-main-wrapper {
      right: 0; }
    .case-shared-media-wrapper .case-shared-media-main-wrapper .case-shared-media-close {
      background: #ffffff;
      border-radius: 50%;
      cursor: pointer;
      position: absolute;
      width: 48px;
      height: 48px;
      display: inline-block;
      text-align: center;
      line-height: 45px;
      top: 0;
      left: -64px; }
      .case-shared-media-wrapper .case-shared-media-main-wrapper .case-shared-media-close svg {
        font-size: 24px;
        color: #7D7C7D; }
    .case-shared-media-wrapper .case-shared-media-main-wrapper .title-case-shared-media {
      font-size: 24px;
      letter-spacing: -0.6px;
      padding: 24px;
      margin: 0; }
    .case-shared-media-wrapper .case-shared-media-main-wrapper .case-shared-media-list-wrapper {
      width: 100%;
      height: calc(100% - 85px);
      max-height: calc(100% - 85px); }
      .case-shared-media-wrapper .case-shared-media-main-wrapper .case-shared-media-list-wrapper .loading-case-action {
        text-align: center;
        color: #ff7d00;
        padding-top: 12px; }
        .case-shared-media-wrapper .case-shared-media-main-wrapper .case-shared-media-list-wrapper .loading-case-action .lds-ring {
          vertical-align: top;
          top: 3px;
          right: unset;
          margin-right: 4px; }
          .case-shared-media-wrapper .case-shared-media-main-wrapper .case-shared-media-list-wrapper .loading-case-action .lds-ring div {
            margin: 0; }
      .case-shared-media-wrapper .case-shared-media-main-wrapper .case-shared-media-list-wrapper .on-error {
        text-align: center;
        color: #ff7d00; }
        .case-shared-media-wrapper .case-shared-media-main-wrapper .case-shared-media-list-wrapper .on-error svg {
          position: relative;
          margin-right: 4px;
          top: -1px; }
        .case-shared-media-wrapper .case-shared-media-main-wrapper .case-shared-media-list-wrapper .on-error p {
          cursor: pointer;
          display: inline-block; }
          .case-shared-media-wrapper .case-shared-media-main-wrapper .case-shared-media-list-wrapper .on-error p:hover {
            color: #e87200; }
      .case-shared-media-wrapper .case-shared-media-main-wrapper .case-shared-media-list-wrapper .shared-media-tab-wrapper {
        padding: 12px 0 0; }
        .case-shared-media-wrapper .case-shared-media-main-wrapper .case-shared-media-list-wrapper .shared-media-tab-wrapper .shared-media-tab {
          border-bottom: solid 1px #dcdcdc;
          text-align: center; }
        .case-shared-media-wrapper .case-shared-media-main-wrapper .case-shared-media-list-wrapper .shared-media-tab-wrapper .shared-media-tab-option {
          letter-spacing: 3px;
          width: calc(50% - 12px);
          display: inline-block;
          vertical-align: top;
          text-align: center;
          font-size: 16px;
          color: rgba(25, 25, 25, 0.4);
          cursor: pointer;
          padding: 0 8px 8px; }
          .case-shared-media-wrapper .case-shared-media-main-wrapper .case-shared-media-list-wrapper .shared-media-tab-wrapper .shared-media-tab-option:hover {
            color: var(--main-dark-orange); }
          .case-shared-media-wrapper .case-shared-media-main-wrapper .case-shared-media-list-wrapper .shared-media-tab-wrapper .shared-media-tab-option.active-shared-media-tab-option {
            border-bottom: solid 2px var(--main-dark-orange);
            color: var(--main-dark-orange); }

.shared-media-main-tab-wrapper-1 {
  display: block;
  width: 100%; }
  .shared-media-main-tab-wrapper-1 .no-shared-media {
    width: 100%;
    text-align: center;
    padding: 24px 110px 0; }
    .shared-media-main-tab-wrapper-1 .no-shared-media p:nth-child(2) {
      font-size: 24px;
      letter-spacing: -0.6px;
      margin: 0 0 8px 0; }
    .shared-media-main-tab-wrapper-1 .no-shared-media p:nth-child(3) {
      font-size: 16px;
      letter-spacing: -0.3px; }
  .shared-media-main-tab-wrapper-1 .share-media-list-wrapper {
    width: 100%;
    display: block; }
    .shared-media-main-tab-wrapper-1 .share-media-list-wrapper .share-media-date {
      display: block;
      width: 100%;
      background: rgba(25, 25, 25, 0.05);
      color: rgba(25, 25, 25, 0.6);
      letter-spacing: 3px;
      font-size: 14px;
      padding: 8px 16px;
      text-transform: uppercase; }
    .shared-media-main-tab-wrapper-1 .share-media-list-wrapper .share-media-list-inner {
      width: 100%;
      display: block;
      padding: 10px; }
      .shared-media-main-tab-wrapper-1 .share-media-list-wrapper .share-media-list-inner .share-media-card {
        width: 97px;
        height: 100px;
        border-radius: 8px;
        vertical-align: top;
        display: inline-block;
        margin: 2px;
        cursor: pointer;
        position: relative;
        background: rgba(25, 25, 25, 0.1); }
        .shared-media-main-tab-wrapper-1 .share-media-list-wrapper .share-media-list-inner .share-media-card .dropdown-view-in-chat {
          background: transparent !important;
          border: none !important;
          outline: none !important;
          box-shadow: none !important; }
        .shared-media-main-tab-wrapper-1 .share-media-list-wrapper .share-media-list-inner .share-media-card .dropdown-menu {
          padding: 0;
          border-radius: 8px;
          overflow: hidden; }
          .shared-media-main-tab-wrapper-1 .share-media-list-wrapper .share-media-list-inner .share-media-card .dropdown-menu button {
            border-radius: 0;
            background: #fff !important;
            font-size: 16px;
            letter-spacing: -0.3px;
            padding: 8px 20px;
            color: #191919 !important; }
            .shared-media-main-tab-wrapper-1 .share-media-list-wrapper .share-media-list-inner .share-media-card .dropdown-menu button:hover {
              background: rgba(25, 25, 25, 0.1) !important; }
            .shared-media-main-tab-wrapper-1 .share-media-list-wrapper .share-media-list-inner .share-media-card .dropdown-menu button img {
              position: relative;
              margin-right: 4px; }
            .shared-media-main-tab-wrapper-1 .share-media-list-wrapper .share-media-list-inner .share-media-card .dropdown-menu button svg {
              position: relative;
              margin-right: 4px;
              font-size: 24px;
              color: var(--main-dark-orange); }
        .shared-media-main-tab-wrapper-1 .share-media-list-wrapper .share-media-list-inner .share-media-card .triple-dot-wrap {
          width: 24px;
          height: 24px;
          border-radius: 50%;
          background: #fff;
          cursor: pointer;
          position: absolute;
          top: 4px;
          right: 4px;
          z-index: 2;
          display: none; }
          .shared-media-main-tab-wrapper-1 .share-media-list-wrapper .share-media-list-inner .share-media-card .triple-dot-wrap img {
            position: absolute;
            top: 50%;
            left: 50%;
            -webkit-transform: translate(-50%, -50%);
                    transform: translate(-50%, -50%);
            width: 12px; }
        .shared-media-main-tab-wrapper-1 .share-media-list-wrapper .share-media-list-inner .share-media-card .share-media-vid {
          max-width: 100%;
          max-height: 100%;
          position: absolute;
          top: 50%;
          left: 50%;
          -webkit-transform: translate(-50%, -50%);
                  transform: translate(-50%, -50%); }
        .shared-media-main-tab-wrapper-1 .share-media-list-wrapper .share-media-list-inner .share-media-card .share-media-img {
          max-width: 100%;
          max-height: 100%;
          position: absolute;
          top: 50%;
          left: 50%;
          -webkit-transform: translate(-50%, -50%);
                  transform: translate(-50%, -50%); }
        .shared-media-main-tab-wrapper-1 .share-media-list-wrapper .share-media-list-inner .share-media-card .media-camera-image {
          position: absolute;
          bottom: 4px;
          left: 4px;
          color: #fff;
          font-size: 14px;
          z-index: 2; }
        .shared-media-main-tab-wrapper-1 .share-media-list-wrapper .share-media-list-inner .share-media-card .media-size-text {
          position: absolute;
          font-size: 12px;
          letter-spacing: -0.4px;
          bottom: 4px;
          right: 4px;
          color: #fff;
          z-index: 2; }
        .shared-media-main-tab-wrapper-1 .share-media-list-wrapper .share-media-list-inner .share-media-card:hover .triple-dot-wrap {
          display: inline-block; }
        .shared-media-main-tab-wrapper-1 .share-media-list-wrapper .share-media-list-inner .share-media-card:hover::before {
          background: rgba(25, 25, 25, 0.05);
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          content: ""; }

.shared-media-main-tab-wrapper {
  display: block;
  width: 100%; }
  .shared-media-main-tab-wrapper .no-shared-media {
    width: 100%;
    text-align: center;
    padding: 24px 110px 0; }
    .shared-media-main-tab-wrapper .no-shared-media p:nth-child(2) {
      font-size: 24px;
      letter-spacing: -0.6px;
      margin: 0 0 8px 0; }
    .shared-media-main-tab-wrapper .no-shared-media p:nth-child(3) {
      font-size: 16px;
      letter-spacing: -0.3px; }
  .shared-media-main-tab-wrapper .share-media-list-wrapper {
    width: 100%;
    display: block; }
    .shared-media-main-tab-wrapper .share-media-list-wrapper .share-media-date {
      display: block;
      width: 100%;
      background: rgba(25, 25, 25, 0.05);
      color: rgba(25, 25, 25, 0.6);
      letter-spacing: 3px;
      font-size: 14px;
      padding: 8px 16px;
      text-transform: uppercase; }
    .shared-media-main-tab-wrapper .share-media-list-wrapper .share-media-list-inner {
      width: 100%;
      display: block;
      padding: 0 16px; }
      .shared-media-main-tab-wrapper .share-media-list-wrapper .share-media-list-inner .triple-dot-wrap {
        width: 24px;
        height: 24px;
        border-radius: 50%;
        background: rgba(25, 25, 25, 0.05);
        cursor: pointer;
        position: absolute;
        top: 4px;
        right: 4px; }
        .shared-media-main-tab-wrapper .share-media-list-wrapper .share-media-list-inner .triple-dot-wrap img {
          position: absolute;
          top: 50%;
          left: 50%;
          -webkit-transform: translate(-50%, -50%);
                  transform: translate(-50%, -50%);
          width: 12px; }
      .shared-media-main-tab-wrapper .share-media-list-wrapper .share-media-list-inner .dropdown {
        position: absolute;
        top: 1px;
        right: 0;
        display: none; }
      .shared-media-main-tab-wrapper .share-media-list-wrapper .share-media-list-inner .dropdown-view-in-chat {
        background: transparent !important;
        border: none !important;
        outline: none !important;
        box-shadow: none !important; }
      .shared-media-main-tab-wrapper .share-media-list-wrapper .share-media-list-inner .dropdown-menu {
        padding: 0;
        border-radius: 8px;
        overflow: hidden; }
        .shared-media-main-tab-wrapper .share-media-list-wrapper .share-media-list-inner .dropdown-menu button {
          border-radius: 0;
          background: #fff !important;
          font-size: 16px;
          letter-spacing: -0.3px;
          padding: 8px 20px;
          color: #191919 !important; }
          .shared-media-main-tab-wrapper .share-media-list-wrapper .share-media-list-inner .dropdown-menu button:hover {
            background: rgba(25, 25, 25, 0.1) !important; }
          .shared-media-main-tab-wrapper .share-media-list-wrapper .share-media-list-inner .dropdown-menu button img {
            position: relative;
            margin-right: 4px; }
          .shared-media-main-tab-wrapper .share-media-list-wrapper .share-media-list-inner .dropdown-menu button svg {
            position: relative;
            margin-right: 4px;
            font-size: 24px;
            color: var(--main-dark-orange); }
      .shared-media-main-tab-wrapper .share-media-list-wrapper .share-media-list-inner .share-media-card-doc {
        width: 100%;
        vertical-align: top;
        display: inline-block;
        cursor: pointer;
        position: relative;
        margin: 8px 0;
        padding: 0 0 0 56px; }
        .shared-media-main-tab-wrapper .share-media-list-wrapper .share-media-list-inner .share-media-card-doc .share-media-doc-box {
          width: 40px;
          height: 40px;
          border-radius: 8px;
          background: var(--main-dark-orange);
          position: absolute;
          left: 0;
          top: 1px; }
          .shared-media-main-tab-wrapper .share-media-list-wrapper .share-media-list-inner .share-media-card-doc .share-media-doc-box svg {
            position: absolute;
            top: 50%;
            left: 50%;
            -webkit-transform: translate(-50%, -50%);
                    transform: translate(-50%, -50%);
            font-size: 24px;
            color: #fff; }
          .shared-media-main-tab-wrapper .share-media-list-wrapper .share-media-list-inner .share-media-card-doc .share-media-doc-box .icon-download {
            display: none; }
          .shared-media-main-tab-wrapper .share-media-list-wrapper .share-media-list-inner .share-media-card-doc .share-media-doc-box:hover .icon-image-video {
            display: none; }
          .shared-media-main-tab-wrapper .share-media-list-wrapper .share-media-list-inner .share-media-card-doc .share-media-doc-box:hover .icon-download {
            display: block; }
        .shared-media-main-tab-wrapper .share-media-list-wrapper .share-media-list-inner .share-media-card-doc .share-media-doc-info .file-name {
          font-size: 16px;
          white-space: nowrap;
          max-width: calc(100% - 40px);
          text-overflow: ellipsis;
          overflow: hidden;
          letter-spacing: -0.5px;
          color: #191919;
          margin: 0 0 2px 0; }
        .shared-media-main-tab-wrapper .share-media-list-wrapper .share-media-list-inner .share-media-card-doc .share-media-doc-info .file-info {
          font-size: 10px;
          white-space: nowrap;
          max-width: 100%;
          text-overflow: ellipsis;
          overflow: hidden;
          letter-spacing: -0.2px;
          color: rgba(25, 25, 25, 0.8);
          margin: 0 0 2px 0; }
          .shared-media-main-tab-wrapper .share-media-list-wrapper .share-media-list-inner .share-media-card-doc .share-media-doc-info .file-info .centered-dot {
            width: 2px;
            height: 2px;
            margin: 0 1px;
            display: inline-block;
            vertical-align: top;
            background: rgba(25, 25, 25, 0.8);
            border-radius: 50%;
            position: relative;
            top: 6px; }
        .shared-media-main-tab-wrapper .share-media-list-wrapper .share-media-list-inner .share-media-card-doc:hover .dropdown {
          display: inline-block; }

.chat-room-sales-talk-product-modal {
  width: 340px;
  display: inline-block;
  -webkit-transition: ease-in-out .2s;
  transition: ease-in-out .2s;
  position: absolute;
  top: 0;
  right: -340px;
  background: #ffffff;
  vertical-align: top;
  height: 100%;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  z-index: 1; }
  .chat-room-sales-talk-product-modal.chat-room-sales-talk-product-modal-active {
    right: 0; }
  .chat-room-sales-talk-product-modal .chat-room-sales-talk-product-modal-title {
    font-size: 14px;
    letter-spacing: -0.4px;
    color: #191919;
    height: 48px;
    width: 100%;
    display: block;
    border-bottom: solid 1px #dcdcdc;
    position: relative;
    text-align: center;
    line-height: 48px; }
    .chat-room-sales-talk-product-modal .chat-room-sales-talk-product-modal-title svg {
      position: absolute;
      color: #191919;
      cursor: pointer;
      top: 15px;
      right: 18px; }
      .chat-room-sales-talk-product-modal .chat-room-sales-talk-product-modal-title svg:hover {
        color: #ff7d00; }
    .chat-room-sales-talk-product-modal .chat-room-sales-talk-product-modal-title.chat-room-sales-talk-product-modal-title-no-border {
      border: none !important; }
  .chat-room-sales-talk-product-modal .salestalk-error-server-wrapper {
    overflow: auto;
    height: calc(100% - 48px);
    padding: 40px 16px;
    text-align: center;
    position: relative; }
    .chat-room-sales-talk-product-modal .salestalk-error-server-wrapper .salestalk-error-server-content {
      position: absolute;
      left: 50%;
      top: 50%;
      -webkit-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%);
      width: 100%;
      padding: 24px; }
      .chat-room-sales-talk-product-modal .salestalk-error-server-wrapper .salestalk-error-server-content .desc-2 {
        margin: 14px 0 44px;
        font-size: 16px;
        letter-spacing: -0.3px;
        color: rgba(25, 25, 25, 0.6); }
  .chat-room-sales-talk-product-modal .salestalk-not-connect-wrapper {
    overflow: auto;
    max-height: calc(100% - 48px);
    padding: 40px 16px;
    text-align: center;
    border-top: solid 1px #dcdcdc; }
    .chat-room-sales-talk-product-modal .salestalk-not-connect-wrapper h3 {
      color: #003c66;
      font-size: 24px;
      letter-spacing: -0.6px;
      line-height: 30px;
      margin: 0 0 12px; }
    .chat-room-sales-talk-product-modal .salestalk-not-connect-wrapper .desc-1 {
      font-size: 16px;
      letter-spacing: -0.3px;
      color: #003c66;
      margin: 0 0 24px; }
    .chat-room-sales-talk-product-modal .salestalk-not-connect-wrapper .desc-2 {
      font-size: 16px;
      letter-spacing: -0.3px;
      color: #003c66;
      margin: 24px 0; }
    .chat-room-sales-talk-product-modal .salestalk-not-connect-wrapper img {
      width: 100%; }

.sales-talk-product-comp {
  height: calc(100% - 215px);
  position: relative;
  width: 100%;
  display: block;
  padding: 0 4px; }
  .sales-talk-product-comp.no-product-wrapper {
    height: calc(100% - 185px);
    border-top: none !important; }
  .sales-talk-product-comp .sales-talk-product-comp-no-result {
    position: absolute;
    width: 100%;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    text-align: center;
    padding: 12px 24px; }
    .sales-talk-product-comp .sales-talk-product-comp-no-result p:nth-child(1) {
      margin: 0;
      font-size: 16px;
      letter-spacing: -0.5px; }
    .sales-talk-product-comp .sales-talk-product-comp-no-result p:nth-last-child(1) {
      margin: 0;
      line-height: 24px;
      font-size: 16px;
      letter-spacing: -0.5px;
      color: rgba(25, 25, 25, 0.6); }
  .sales-talk-product-comp .sales-talk-product-comp-inner {
    padding: 8px 8px 0; }
  .sales-talk-product-comp .sales-talk-product-comp-box {
    display: inline-block;
    vertical-align: top;
    width: calc(50% - 10px);
    margin-right: 20px;
    cursor: pointer;
    padding: 12px;
    position: relative;
    border: solid 1px #fff;
    box-shadow: 0px 2px 20px rgba(25, 25, 25, 0.05);
    margin-bottom: 12px; }
    .sales-talk-product-comp .sales-talk-product-comp-box .custom-checkbox {
      width: 16px;
      height: 16px;
      position: absolute;
      top: 20px;
      right: 20px;
      border-radius: 50%;
      z-index: 2; }
      .sales-talk-product-comp .sales-talk-product-comp-box .custom-checkbox label {
        position: absolute;
        top: 0;
        left: 0;
        margin: 0;
        padding: 0; }
        .sales-talk-product-comp .sales-talk-product-comp-box .custom-checkbox label::before {
          width: 16px;
          height: 16px;
          border-radius: 50%;
          top: 0;
          left: 0; }
        .sales-talk-product-comp .sales-talk-product-comp-box .custom-checkbox label::after {
          width: 16px;
          height: 16px;
          top: 0;
          left: 0;
          border-radius: 50%; }
    .sales-talk-product-comp .sales-talk-product-comp-box:nth-child(2n) {
      margin-right: 0; }
    .sales-talk-product-comp .sales-talk-product-comp-box:hover {
      box-shadow: 0px 0px 8px 0px #FF7E003D;
      border: solid 1px #ff7d00; }
    .sales-talk-product-comp .sales-talk-product-comp-box .sales-talk-product-comp-image-wrapper {
      width: 100%;
      height: 116px;
      border-radius: 12px;
      margin: 0;
      position: relative;
      background: rgba(25, 25, 25, 0.2);
      overflow: hidden; }
      .sales-talk-product-comp .sales-talk-product-comp-box .sales-talk-product-comp-image-wrapper img {
        position: absolute;
        top: 50%;
        left: 50%;
        height: 100%;
        width: 100%;
        object-fit: cover;
        z-index: 1;
        -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%); }
    .sales-talk-product-comp .sales-talk-product-comp-box .product-title {
      font-size: 14px;
      max-width: 100%;
      display: -webkit-box;
      overflow: hidden;
      text-overflow: ellipsis;
      letter-spacing: -0.4px;
      -webkit-box-orient: vertical;
      margin: 0;
      -webkit-line-clamp: 2;
      word-break: break-word; }
      .sales-talk-product-comp .sales-talk-product-comp-box .product-title:hover {
        text-decoration: underline; }
    .sales-talk-product-comp .sales-talk-product-comp-box .product-price {
      font-size: 12px;
      color: rgba(25, 25, 25, 0.8);
      letter-spacing: -0.2px;
      margin: 4px 0; }
    .sales-talk-product-comp .sales-talk-product-comp-box .product-stock {
      font-size: 10px;
      letter-spacing: 1.5px;
      color: #ff7d00;
      margin: 0; }

.chat-room-sales-talk-product-top .sales-talk-search-product-wrapper {
  padding: 8px 14px; }
  .chat-room-sales-talk-product-top .sales-talk-search-product-wrapper .search-box-wrapper input {
    padding: 5px 60px 5px 16px;
    background: rgba(25, 25, 25, 0.05); }
  .chat-room-sales-talk-product-top .sales-talk-search-product-wrapper .search-box-wrapper .search-icon {
    width: 36px;
    height: 36px; }
    .chat-room-sales-talk-product-top .sales-talk-search-product-wrapper .search-box-wrapper .search-icon svg {
      font-size: 16px; }
  .chat-room-sales-talk-product-top .sales-talk-search-product-wrapper .search-box-wrapper .clear-search {
    right: 38px; }

.chat-room-sales-talk-product-top .sales-talk-category-wrapper-outer {
  height: 38px;
  background: #f3f3f3;
  max-width: 100%;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  position: relative;
  text-align: center;
  padding: 0 16px; }
  .chat-room-sales-talk-product-top .sales-talk-category-wrapper-outer::before {
    position: absolute;
    content: "";
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background: #f3f3f3; }
  .chat-room-sales-talk-product-top .sales-talk-category-wrapper-outer .scroll-left-wrapper {
    color: #ff7d00;
    font-size: 20px;
    width: 25px;
    text-align: center;
    display: inline-block;
    position: absolute;
    left: 0;
    top: 0;
    background: -webkit-gradient(linear, left top, right top, color-stop(55.97%, #FFFFFF), color-stop(67.46%, rgba(255, 255, 255, 0.8)), color-stop(95.95%, rgba(255, 255, 255, 0)));
    background: linear-gradient(90deg, #FFFFFF 55.97%, rgba(255, 255, 255, 0.8) 67.46%, rgba(255, 255, 255, 0) 95.95%);
    height: 100%;
    cursor: pointer;
    line-height: 32px; }
    .chat-room-sales-talk-product-top .sales-talk-category-wrapper-outer .scroll-left-wrapper:hover {
      color: #e87200; }
  .chat-room-sales-talk-product-top .sales-talk-category-wrapper-outer .scroll-right-wrapper {
    color: #ff7d00;
    font-size: 20px;
    cursor: pointer;
    text-align: center;
    width: 25px;
    display: inline-block;
    position: absolute;
    right: 0;
    top: 0;
    background: -webkit-gradient(linear, right top, left top, color-stop(55.97%, #FFFFFF), color-stop(67.46%, rgba(255, 255, 255, 0.8)), color-stop(95.95%, rgba(255, 255, 255, 0)));
    background: linear-gradient(270deg, #FFFFFF 55.97%, rgba(255, 255, 255, 0.8) 67.46%, rgba(255, 255, 255, 0) 95.95%);
    height: 100%;
    line-height: 32px;
    cursor: pointer; }
    .chat-room-sales-talk-product-top .sales-talk-category-wrapper-outer .scroll-right-wrapper:hover {
      color: #e87200; }
  .chat-room-sales-talk-product-top .sales-talk-category-wrapper-outer .sales-talk-category-wrapper {
    overflow: auto;
    border-bottom: solid 1px #f3f3f3; }
    .chat-room-sales-talk-product-top .sales-talk-category-wrapper-outer .sales-talk-category-wrapper .sales-talk-category-selection {
      text-align: center;
      line-height: 38px;
      padding: 0 8px;
      min-width: 105px;
      font-size: 14px;
      letter-spacing: -0.4px;
      display: inline-block;
      cursor: pointer; }
      .chat-room-sales-talk-product-top .sales-talk-category-wrapper-outer .sales-talk-category-wrapper .sales-talk-category-selection:hover {
        color: #ff7d00; }
      .chat-room-sales-talk-product-top .sales-talk-category-wrapper-outer .sales-talk-category-wrapper .sales-talk-category-selection.sales-talk-category-selection-disabled {
        color: rgba(25, 25, 25, 0.6) !important; }
      .chat-room-sales-talk-product-top .sales-talk-category-wrapper-outer .sales-talk-category-wrapper .sales-talk-category-selection.sales-talk-category-selection-active {
        color: #ff7d00;
        background: #ffffff;
        border-radius: 12px 12px 0 0; }
        .chat-room-sales-talk-product-top .sales-talk-category-wrapper-outer .sales-talk-category-wrapper .sales-talk-category-selection.sales-talk-category-selection-active:nth-child(1) {
          border-radius: 0 12px 0 0; }
        .chat-room-sales-talk-product-top .sales-talk-category-wrapper-outer .sales-talk-category-wrapper .sales-talk-category-selection.sales-talk-category-selection-active:nth-last-child(1) {
          border-radius: 8px 0 0 0; }

.chat-room-sales-talk-product-top .sales-talk-pagination-outer {
  padding: 0 16px; }
  .chat-room-sales-talk-product-top .sales-talk-pagination-outer .sales-talk-pagination {
    position: relative;
    height: 32px;
    width: 100%;
    display: block;
    padding: 7px 0;
    border-bottom: solid 1px #dcdcdc; }
    .chat-room-sales-talk-product-top .sales-talk-pagination-outer .sales-talk-pagination .total-products {
      color: rgba(25, 25, 25, 0.4);
      font-size: 12px;
      letter-spacing: -0.4px; }
      .chat-room-sales-talk-product-top .sales-talk-pagination-outer .sales-talk-pagination .total-products span {
        margin-left: 1px; }
    .chat-room-sales-talk-product-top .sales-talk-pagination-outer .sales-talk-pagination .paging {
      color: rgba(25, 25, 25, 0.4);
      font-size: 14px;
      letter-spacing: -0.2px;
      position: absolute;
      padding: 0 28px;
      right: 0;
      top: 50%;
      -webkit-transform: translate(0, -50%);
              transform: translate(0, -50%); }
      .chat-room-sales-talk-product-top .sales-talk-pagination-outer .sales-talk-pagination .paging svg {
        font-size: 14px;
        cursor: pointer;
        position: absolute;
        top: -1px; }
        .chat-room-sales-talk-product-top .sales-talk-pagination-outer .sales-talk-pagination .paging svg:hover {
          color: #ff7d00; }
        .chat-room-sales-talk-product-top .sales-talk-pagination-outer .sales-talk-pagination .paging svg:nth-child(1) {
          top: 4px;
          left: 0; }
        .chat-room-sales-talk-product-top .sales-talk-pagination-outer .sales-talk-pagination .paging svg:nth-last-child(1) {
          right: 0;
          top: 4px; }
      .chat-room-sales-talk-product-top .sales-talk-pagination-outer .sales-talk-pagination .paging span {
        color: #ff7d00; }

.chat-room-sales-talk-product-top .sales-talk-sort-by-wrapper {
  padding: 8px;
  position: relative; }
  .chat-room-sales-talk-product-top .sales-talk-sort-by-wrapper .view-all-items {
    color: #ff7d00;
    cursor: pointer;
    font-size: 12px;
    letter-spacing: -0.4px;
    position: absolute;
    right: 8px;
    top: 50%;
    -webkit-transform: translate(0, -50%);
            transform: translate(0, -50%); }
    .chat-room-sales-talk-product-top .sales-talk-sort-by-wrapper .view-all-items:hover {
      color: #e87200; }
    .chat-room-sales-talk-product-top .sales-talk-sort-by-wrapper .view-all-items span {
      color: rgba(25, 25, 25, 0.6); }

.sort-by-content-wrapper {
  position: relative;
  display: inline-block; }
  .sort-by-content-wrapper .dropdown {
    display: inline-block;
    outline: none;
    -webkit-transition: ease-in-out .2s !important;
    transition: ease-in-out .2s !important; }
    .sort-by-content-wrapper .dropdown button.btn {
      border-radius: 4px;
      border: none;
      background: none !important;
      color: rgba(25, 25, 25, 0.6) !important;
      border: solid 1px rgba(25, 25, 25, 0.1) !important;
      font-size: 12px;
      letter-spacing: -0.4px;
      box-shadow: none !important;
      padding: 4px 12px 2px 8px;
      line-height: 1; }
      .sort-by-content-wrapper .dropdown button.btn svg {
        font-size: 13px;
        margin-right: 4px;
        position: relative;
        top: -1px;
        -webkit-transition: ease-in-out .2s;
        transition: ease-in-out .2s; }
        .sort-by-content-wrapper .dropdown button.btn svg.active-sort-by-menu {
          -webkit-transform: rotate(180deg);
                  transform: rotate(180deg); }
      .sort-by-content-wrapper .dropdown button.btn:hover {
        color: #ff7d00 !important;
        background: #ffe7d0 !important;
        border: solid 1px #ffe7d0 !important; }
    .sort-by-content-wrapper .dropdown .dropdown-menu {
      border-radius: 8px;
      box-shadow: 0 2px 20px 0 rgba(25, 25, 25, 0.18);
      border: none;
      position: relative;
      padding: 0;
      margin-top: 12px;
      left: 4px !important;
      outline: none; }
      .sort-by-content-wrapper .dropdown .dropdown-menu::before {
        position: absolute;
        width: 16px;
        height: 16px;
        background: #ffffff;
        content: "";
        top: -8px;
        -webkit-transform: rotate(-45deg);
                transform: rotate(-45deg);
        left: 30px; }
      .sort-by-content-wrapper .dropdown .dropdown-menu .dropdown-item {
        position: relative;
        font-size: 13px;
        letter-spacing: -0.4px;
        border-bottom: solid 1px rgba(25, 25, 25, 0.1);
        background: none;
        padding: 10px 16px 8px 16px;
        line-height: 1;
        color: #191919 !important; }
        .sort-by-content-wrapper .dropdown .dropdown-menu .dropdown-item svg {
          font-size: 16px;
          margin-right: 8px;
          position: absolute;
          top: 10px;
          left: 8px; }
        .sort-by-content-wrapper .dropdown .dropdown-menu .dropdown-item:hover {
          color: #ff7d00 !important; }
        .sort-by-content-wrapper .dropdown .dropdown-menu .dropdown-item.active-sort-by-option {
          padding: 10px 16px 8px 16px;
          color: #ff7d00 !important; }
        .sort-by-content-wrapper .dropdown .dropdown-menu .dropdown-item:nth-last-child(1) {
          border: none; }

.error-product-component {
  padding: 0 24px;
  position: absolute;
  left: 50%;
  top: 60%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  text-align: center; }
  .error-product-component .error-title {
    font-size: 16px;
    letter-spacing: -0.5px;
    color: #191919;
    margin: 0 0 8px 0; }
    .error-product-component .error-title svg {
      color: #ff3f57;
      position: relative;
      margin-right: 4px;
      top: -1px; }
  .error-product-component .error-text {
    font-size: 16px;
    letter-spacing: -0.3px;
    color: rgba(25, 25, 25, 0.6); }

.shimmer-product-component {
  width: 100%;
  display: block;
  padding: 12px; }
  .shimmer-product-component .main-shimmer-wrapper {
    box-shadow: 0px 2px 20px rgba(25, 25, 25, 0.05);
    width: calc(50% - 10px);
    margin-right: 20px;
    margin-bottom: 8px;
    background: #fff;
    display: inline-block;
    padding: 12px; }
    .shimmer-product-component .main-shimmer-wrapper:nth-child(2n) {
      margin-right: 0; }
    .shimmer-product-component .main-shimmer-wrapper .shimmer-image {
      height: 116px;
      width: 100%;
      display: block;
      border-radius: 8px; }
    .shimmer-product-component .main-shimmer-wrapper .shimmer-title {
      height: 12px;
      width: 100%;
      display: block;
      border-radius: 8px;
      margin-top: 12px; }

.chat-room-contact-info- {
  width: 340px;
  display: inline-block;
  -webkit-transition: ease-in-out .2s;
  transition: ease-in-out .2s;
  position: absolute;
  top: 0;
  right: -340px;
  background: #ffffff;
  vertical-align: top;
  height: 100%;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  z-index: 1; }
  .chat-room-contact-info-.chat-room-contact-info-active {
    right: 0; }
  .chat-room-contact-info- .chat-room-contact-info-title {
    font-size: 20px;
    letter-spacing: -0.6px;
    color: #191919;
    padding: 28px 17px 12px 17px;
    width: 100%;
    display: block;
    border-bottom: solid 1px #dcdcdc;
    position: relative;
    text-align: left;
    line-height: 1; }
    .chat-room-contact-info- .chat-room-contact-info-title svg {
      position: relative;
      color: #191919;
      cursor: pointer;
      top: -2px;
      margin-right: 4px; }
    .chat-room-contact-info- .chat-room-contact-info-title.chat-room-contact-info-title-no-border {
      border: none !important; }
  .chat-room-contact-info- .chat-room-contact-info-edit-wrapper {
    padding: 0 0 24px 0;
    width: 100%;
    position: relative;
    height: calc(100% - 63px);
    overflow: auto; }
    .chat-room-contact-info- .chat-room-contact-info-edit-wrapper .chat-room-contact-info-edit-inner {
      padding: 24px 16px 0;
      max-height: calc(100% - 64px);
      overflow: auto; }
      .chat-room-contact-info- .chat-room-contact-info-edit-wrapper .chat-room-contact-info-edit-inner .grey-font-6 {
        font-size: 12px;
        line-height: 16px;
        letter-spacing: -0.2px;
        position: relative;
        padding: 0 0 0 16px;
        margin: 4px 0 0; }
        .chat-room-contact-info- .chat-room-contact-info-edit-wrapper .chat-room-contact-info-edit-inner .grey-font-6 svg {
          position: absolute;
          top: 3px;
          left: 0; }
      .chat-room-contact-info- .chat-room-contact-info-edit-wrapper .chat-room-contact-info-edit-inner .char-count {
        font-size: 12px;
        line-height: 16px;
        letter-spacing: -0.2px;
        -webkit-transform: translateY(2px);
                transform: translateY(2px); }
      .chat-room-contact-info- .chat-room-contact-info-edit-wrapper .chat-room-contact-info-edit-inner input[type="text"] {
        padding: 0 8px;
        width: 100%;
        border-radius: 8px;
        font-size: 14px;
        line-height: 21px;
        letter-spacing: -0.2px;
        color: #191919;
        height: 40px;
        z-index: 1;
        background: transparent;
        border: solid 1px rgba(25, 25, 25, 0.1);
        caret-color: #FF7E00; }
        .chat-room-contact-info- .chat-room-contact-info-edit-wrapper .chat-room-contact-info-edit-inner input[type="text"]::-webkit-input-placeholder {
          color: rgba(25, 25, 25, 0.4); }
        .chat-room-contact-info- .chat-room-contact-info-edit-wrapper .chat-room-contact-info-edit-inner input[type="text"]::-ms-input-placeholder {
          color: rgba(25, 25, 25, 0.4); }
        .chat-room-contact-info- .chat-room-contact-info-edit-wrapper .chat-room-contact-info-edit-inner input[type="text"]::placeholder {
          color: rgba(25, 25, 25, 0.4); }
        .chat-room-contact-info- .chat-room-contact-info-edit-wrapper .chat-room-contact-info-edit-inner input[type="text"]:disabled {
          background: #E8E8E8; }
        .chat-room-contact-info- .chat-room-contact-info-edit-wrapper .chat-room-contact-info-edit-inner input[type="text"]:focus {
          border: solid 1px #ff7d00; }
      .chat-room-contact-info- .chat-room-contact-info-edit-wrapper .chat-room-contact-info-edit-inner .contact-custom-field-separator {
        margin-top: 24px;
        width: 100%;
        height: 1px;
        background-color: #1919191A; }
      .chat-room-contact-info- .chat-room-contact-info-edit-wrapper .chat-room-contact-info-edit-inner textarea {
        padding: 8px;
        width: 100%;
        border-radius: 8px;
        font-size: 14px;
        line-height: 21px;
        letter-spacing: -0.2px;
        color: #191919;
        height: 40px;
        min-height: 40px;
        z-index: 1;
        background: transparent;
        border: solid 1px rgba(25, 25, 25, 0.1);
        caret-color: #FF7E00;
        resize: none; }
        .chat-room-contact-info- .chat-room-contact-info-edit-wrapper .chat-room-contact-info-edit-inner textarea::-webkit-input-placeholder {
          color: rgba(25, 25, 25, 0.4); }
        .chat-room-contact-info- .chat-room-contact-info-edit-wrapper .chat-room-contact-info-edit-inner textarea::-ms-input-placeholder {
          color: rgba(25, 25, 25, 0.4); }
        .chat-room-contact-info- .chat-room-contact-info-edit-wrapper .chat-room-contact-info-edit-inner textarea::placeholder {
          color: rgba(25, 25, 25, 0.4); }
        .chat-room-contact-info- .chat-room-contact-info-edit-wrapper .chat-room-contact-info-edit-inner textarea:disabled {
          background: #E8E8E8; }
        .chat-room-contact-info- .chat-room-contact-info-edit-wrapper .chat-room-contact-info-edit-inner textarea:focus {
          border: solid 1px #ff7d00; }
        .chat-room-contact-info- .chat-room-contact-info-edit-wrapper .chat-room-contact-info-edit-inner textarea::-webkit-scrollbar {
          width: 12px; }
        .chat-room-contact-info- .chat-room-contact-info-edit-wrapper .chat-room-contact-info-edit-inner textarea::-webkit-scrollbar-thumb {
          border: 4px solid #00000000;
          border-radius: 8px;
          background-color: #1919194D;
          background-clip: padding-box; }
      .chat-room-contact-info- .chat-room-contact-info-edit-wrapper .chat-room-contact-info-edit-inner .contact-date-value-wrapper {
        max-width: 400px;
        border-radius: 8px;
        height: 40px;
        width: 100%;
        position: relative;
        padding: 0 40px 0 8px;
        border: solid 1px #1919191A;
        line-height: 38px;
        font-size: 14px;
        letter-spacing: -0.2px;
        cursor: pointer; }
        .chat-room-contact-info- .chat-room-contact-info-edit-wrapper .chat-room-contact-info-edit-inner .contact-date-value-wrapper:hover {
          border-color: #ff7e00; }
        .chat-room-contact-info- .chat-room-contact-info-edit-wrapper .chat-room-contact-info-edit-inner .contact-date-value-wrapper svg {
          position: absolute;
          font-size: 16px;
          right: 8px;
          top: 50%;
          -webkit-transform: translate(0, -50%);
                  transform: translate(0, -50%);
          color: #FF7E00; }
      .chat-room-contact-info- .chat-room-contact-info-edit-wrapper .chat-room-contact-info-edit-inner .dropdown button {
        padding: 0 40px 0 8px !important;
        margin-top: 0px;
        height: 40px; }
        .chat-room-contact-info- .chat-room-contact-info-edit-wrapper .chat-room-contact-info-edit-inner .dropdown button p {
          line-height: 21px;
          font-size: 14px;
          letter-spacing: -0.2px; }
        .chat-room-contact-info- .chat-room-contact-info-edit-wrapper .chat-room-contact-info-edit-inner .dropdown button svg {
          font-size: 16px;
          right: 8px;
          color: #FF7E00 !important; }
      .chat-room-contact-info- .chat-room-contact-info-edit-wrapper .chat-room-contact-info-edit-inner .dropdown .dropdown-option-wrapper {
        max-height: none;
        border: none; }
        .chat-room-contact-info- .chat-room-contact-info-edit-wrapper .chat-room-contact-info-edit-inner .dropdown .dropdown-option-wrapper button {
          padding: 0 8px 0 36px !important;
          margin-top: 0px;
          height: 48px; }
    .chat-room-contact-info- .chat-room-contact-info-edit-wrapper label {
      font-size: 14px;
      letter-spacing: -0.4px;
      color: rgba(25, 25, 25, 0.8);
      margin: 24px 0 2px 0;
      width: 100%; }
      .chat-room-contact-info- .chat-room-contact-info-edit-wrapper label span {
        color: rgba(25, 25, 25, 0.4); }
    .chat-room-contact-info- .chat-room-contact-info-edit-wrapper .edit-contact-info-input-wrapper {
      position: relative;
      width: 100%;
      height: 40px;
      margin: 0; }
      .chat-room-contact-info- .chat-room-contact-info-edit-wrapper .edit-contact-info-input-wrapper.edit-contact-phone {
        margin: 0; }
      .chat-room-contact-info- .chat-room-contact-info-edit-wrapper .edit-contact-info-input-wrapper input[type="text"] {
        padding: 0 8px 0 32px;
        width: 100%;
        border-radius: 8px;
        font-size: 14px;
        letter-spacing: -0.4px;
        color: #191919;
        height: 40px;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 1;
        background: transparent;
        border: solid 1px rgba(25, 25, 25, 0.1);
        caret-color: #FF7E00; }
        .chat-room-contact-info- .chat-room-contact-info-edit-wrapper .edit-contact-info-input-wrapper input[type="text"]::-webkit-input-placeholder {
          color: rgba(25, 25, 25, 0.4); }
        .chat-room-contact-info- .chat-room-contact-info-edit-wrapper .edit-contact-info-input-wrapper input[type="text"]::-ms-input-placeholder {
          color: rgba(25, 25, 25, 0.4); }
        .chat-room-contact-info- .chat-room-contact-info-edit-wrapper .edit-contact-info-input-wrapper input[type="text"]::placeholder {
          color: rgba(25, 25, 25, 0.4); }
        .chat-room-contact-info- .chat-room-contact-info-edit-wrapper .edit-contact-info-input-wrapper input[type="text"]:disabled {
          background: #E8E8E8; }
        .chat-room-contact-info- .chat-room-contact-info-edit-wrapper .edit-contact-info-input-wrapper input[type="text"]:focus {
          border: solid 1px #ff7d00; }
      .chat-room-contact-info- .chat-room-contact-info-edit-wrapper .edit-contact-info-input-wrapper img, .chat-room-contact-info- .chat-room-contact-info-edit-wrapper .edit-contact-info-input-wrapper svg {
        position: absolute;
        left: 8px;
        top: 50%;
        -webkit-transform: translate(0, -50%);
                transform: translate(0, -50%);
        z-index: 2;
        font-size: 16px; }
    .chat-room-contact-info- .chat-room-contact-info-edit-wrapper .edit-contact-button {
      width: 100%;
      padding: 24px 16px 24px 16px;
      position: absolute;
      bottom: 0;
      left: 0; }
      .chat-room-contact-info- .chat-room-contact-info-edit-wrapper .edit-contact-button button {
        width: calc(50% - 4px);
        margin-right: 8px;
        vertical-align: top; }
        .chat-room-contact-info- .chat-room-contact-info-edit-wrapper .edit-contact-button button:nth-last-child(1) {
          margin: 0; }

.modal-confirmation-edit-contact button {
  width: calc(50% - 4px) !important;
  margin-right: 8px; }
  .modal-confirmation-edit-contact button:nth-last-child(1) {
    margin: 0; }

.chat-room-case-history-modal-wrapper {
    width: 100vw;
    height: 100vh;
    background: rgba(25, 25, 25, 0.8);
    position: fixed;
    top: 0;
    /* right: -100vw; */
    visibility: hidden;
    opacity: 0;
    right: 0;
    /* transition: visibility 0s, opacity 0.3s ease-in-out; */
    -webkit-transition: visibility .3s, opacity 0.5s ease-in-out;
    transition: visibility .3s, opacity 0.5s ease-in-out;
    z-index: 4;
}

.chat-room-case-history-modal-wrapper.active-chat-room-case-history-modal-wrapper {
    /* right: 0; */
    opacity: 1;
    visibility: visible;
    -webkit-transition: visibility 0s, opacity 0.5s ease-in-out;
    transition: visibility 0s, opacity 0.5s ease-in-out;
}

.chat-room-case-history-modal-content {
    position: absolute;
    width: calc(100vw - 360px);
    height: calc(100vh - 8px);
    right: -100vw;
    top: 8px;
    display: inline-block;
    -webkit-transition: ease-in-out .5s;
    transition: ease-in-out .5s;
}

.chat-room-case-history-modal-content.active-chat-room-case-history-modal-content {
    right: 0;
    background: #ffffff;
    border-top-left-radius: 18px;
}

.chat-room-case-history-modal-wrapper .user-info-tab-content {
    width: 50%;
}
.chat-room-case-history-modal-header-wrapper {
  position: relative;
  background: #ffffff;
  width: 100%;
  height: 48px;
  border-top-left-radius: 18px;
  -webkit-transition: ease-in-out .1s;
  transition: ease-in-out .1s; }
  .chat-room-case-history-modal-header-wrapper .chat-room-identifier {
    width: calc(100% - 205px) !important;
    overflow: hidden;
    text-overflow: ellipsis; }
    .chat-room-case-history-modal-header-wrapper .chat-room-identifier p {
      margin: 0;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 100%; }

.chat-room-case-history-modal-header-wrapper.modal-chat-room-header-show-detail {
  width: calc(100% - 340px); }

.chat-room-case-history-close {
  background: #ffffff;
  border-radius: 50%;
  cursor: pointer;
  position: absolute;
  width: 48px;
  height: 48px;
  display: inline-block;
  text-align: center;
  line-height: 45px;
  top: 0;
  left: -64px; }

.chat-room-case-history-close svg {
  font-size: 24px;
  color: #7D7C7D; }

.chat-room-case-history-close:hover svg {
  color: #5c5c5c; }

.chat-room-case-history-modal-header-wrapper .open-detail-button {
  right: 16px;
  position: absolute;
  top: 50%;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%); }

.chat-room-case-history-modal-header-wrapper .open-detail-button {
  /* height: calc(100% - 8px);
    line-height: 25px; */
  height: 26px;
  line-height: 26px; }

.chat-room-case-history-modal-main-chat-wrapper {
  background: #f3f3f3;
  width: 100%;
  height: 100%;
  -webkit-transition: ease-in-out .2s;
  transition: ease-in-out .2s;
  display: inline-block;
  /* border-right: solid 1px #dcdcdc; */ }
  .chat-room-case-history-modal-main-chat-wrapper .message-action-wrapper {
    display: none !important; }

.chat-room-case-history-modal-main-chat-wrapper.active-chat-room-case-history-modal-detail {
  width: calc(100% - 340px);
  vertical-align: top; }

.chat-room-case-history-modal-main-chat-container {
  /* height: calc(100vh - 190px);
    max-height: calc(100vh - 190px); */
  height: calc(100vh - 56px);
  max-height: calc(100vh - 56px);
  width: 100%;
  position: relative; }
  .chat-room-case-history-modal-main-chat-container .message-action-dropdown {
    display: none !important; }

.chat-room-case-history-modal-main-chat-container .chat-room-case-history-modal-main-chat-content {
  position: absolute;
  max-height: calc(100vh - 56px);
  overflow: auto;
  width: 100%;
  bottom: 0;
  left: 0;
  padding: 0 15px 16px 15px; }

.chat-room-case-history-modal-go-to-container {
  height: 134px;
  width: 100%;
  position: relative;
  background: #FFF4B1;
  color: #ff7d00;
  letter-spacing: -0.3px;
  font-size: 16px;
  text-align: center;
  padding: 10px 12px; }

.chat-room-case-history-modal-go-to-container p {
  margin: 0;
  line-height: 1.5; }

.chat-room-case-history-modal-go-to-container b {
  letter-spacing: -0.5px; }

.chat-room-case-history-modal-go-to-container button {
  width: 100%;
  letter-spacing: -0.5px;
  font-size: 16px;
  padding: 11px 0 12px;
  margin-top: 16px; }

.chat-room-case-history-modal-go-to-container svg {
  font-size: 20px;
  position: relative;
  top: -1px;
  margin-right: 4px; }

.chat-room-case-history-modal-main-chat-wrapper .loading-message-wrapper {
  text-align: center;
  padding-top: 16px; }

.chat-room-user-info-wrapper {
  /* width: 300px; */
  width: 340px;
  display: inline-block;
  -webkit-transition: ease-in-out .2s;
  transition: ease-in-out .2s;
  /* transition: left 2s, width .4s; */
  position: absolute;
  top: 0;
  /* right: -300px; */
  right: -340px;
  background: #ffffff;
  vertical-align: top;
  height: 100%;
  /* padding-top: 24px; */
  /* overflow: hidden; */
  box-shadow: -1px 0 0 0 rgba(25, 25, 25, 0.1); }

.chat-room-user-info-wrapper.active-chat-room-user-info-wrapper {
  right: 0;
  /* width: 300px; */
  /* overflow: visible; */ }

.user-info-name-avatar-wrapper {
  width: 100%;
  position: relative;
  /* padding: 0 16px 12px 16px; */
  padding: 24px;
  background: #f3f3f3; }

.user-info-avatar-wrapper {
  border-radius: 50%;
  height: 48px;
  display: inline-block;
  width: 48px;
  vertical-align: top;
  background: pink;
  position: relative;
  text-align: center;
  line-height: 48px;
  color: #ffffff;
  font-size: 16px; }

.user-info-name-connectivity-modal {
  width: calc(100% - 96px);
  display: inline-block;
  padding-left: 12px;
  line-height: 19px;
  top: 50%;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%);
  left: 72px;
  position: absolute; }

.user-info-name-connectivity-modal p {
  white-space: nowrap;
  overflow: hidden;
  margin: 0;
  text-overflow: ellipsis;
  font-size: 16px;
  letter-spacing: -0.5px; }

.user-info-online {
  font-size: 10px;
  letter-spacing: 1.5px;
  color: rgba(25, 25, 25, 0.6);
  font-weight: bold; }

.user-info-offline {
  cursor: pointer;
  border-radius: 4px;
  background: #ff7d00;
  color: #ffffff;
  font-size: 12px;
  line-height: 1.5;
  letter-spacing: -0.2px;
  padding: 1px 8px;
  display: inline-block;
  margin-top: 4px;
  -webkit-transition: ease-in-out .2s;
  transition: ease-in-out .2s; }

.user-info-offline:hover {
  background: #e87200; }

.user-info-offline svg {
  margin-right: 4px;
  position: relative;
  top: -1px; }

.user-info-online svg {
  color: #19c700;
  position: relative;
  top: -1px;
  font-size: 13px;
  margin-right: 2px; }

.user-info-offline-dot {
  font-size: 10px;
  letter-spacing: 1.5px;
  color: rgba(25, 25, 25, 0.6);
  font-weight: bold; }

.user-info-offline-dot svg {
  color: #ff3f57;
  position: relative;
  top: -1px;
  font-size: 13px;
  margin-right: 2px; }

.user-info-offline-dot svg {
  color: #ff3f57;
  position: relative;
  top: -1px;
  font-size: 13px;
  margin-right: 2px; }

.user-info-tab-wrapper {
  /* border-bottom: solid 1px #dcdcdc; */
  width: 100%;
  position: relative;
  /* padding: 12px 0 0; */
  background: #f3f3f3; }

.user-info-tab-content {
  width: calc(100% / 3);
  display: inline-block;
  color: rgba(25, 25, 25, 0.6);
  text-align: center;
  font-size: 14px;
  letter-spacing: -0.4px;
  position: relative;
  padding: 8px 0; }
  .user-info-tab-content svg {
    font-size: 14px; }
  .user-info-tab-content:nth-child(1).active-room-list-tab {
    border-radius: 0 12px 0 0; }
  .user-info-tab-content:nth-last-child(1).active-room-list-tab {
    border-radius: 12px 0 0 0; }

.user-info-tab-content.active-room-list-tab {
  color: #ff7d00;
  background: #ffffff;
  border-radius: 12px 12px 0 0; }

/* .user-info-tab-content.active-room-list-tab:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 4px;
    background: #ff7d00;
    left: 0;
    bottom: 0;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
} */
.user-info-tab-content svg {
  font-size: 14px;
  margin-right: 4px;
  position: relative;
  top: -2px; }

.user-info-tab-content:hover {
  color: #191919;
  cursor: pointer; }

.user-info-list-wrapper {
  margin-bottom: 16px;
  width: 100%;
  text-align: left; }

.user-info-list-wrapper label {
  font-size: 14px;
  letter-spacing: -0.4px;
  color: rgba(25, 25, 25, 0.8);
  display: block; }

.user-info-box-wrapper {
  background: rgba(25, 25, 25, 0.05);
  border-radius: 8px;
  height: 40px;
  width: 100%;
  line-height: 40px;
  padding: 0 9px;
  font-size: 14px;
  color: #191919;
  width: 100%;
  position: relative;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden; }

.user-info-box-wrapper.with-button-copy {
  padding: 0 65px 0 8px; }

.user-detail-copy-wrapper {
  position: absolute;
  right: 12px;
  top: 50%;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%);
  color: rgba(25, 25, 25, 0.6);
  font-size: 12px;
  line-height: -0.2px;
  cursor: pointer; }

.user-detail-copy-wrapper:hover {
  color: rgba(25, 25, 25, 0.8); }

.user-detail-copy-wrapper svg {
  font-size: 16px;
  margin-right: 4px; }

.user-info-box-wrapper img {
  position: relative;
  top: -2px;
  margin-right: 7px; }

.user-info-tab.user-info-tab-modal {
  max-height: calc(100vh - 173px);
  height: 100%;
  overflow-y: hidden; }

.user-info-tab-modal {
  max-height: calc(100vh - 173px); }

.user-info-tags {
  font-size: 12px;
  letter-spacing: -0.4px;
  color: rgba(25, 25, 25, 0.6);
  line-height: 1.5;
  padding: 0 8px;
  border-radius: 4px;
  margin-right: 4px;
  display: inline-block;
  background: rgba(25, 25, 25, 0.06);
  vertical-align: top;
  margin-bottom: 5px; }

.channel-icon-detail {
    width: 24px;
}
.case-nation-flag {
    width: 22px;
    position: relative;
    top: 1px !important;
}

.user-info-list-wrapper.remarks-for-contact {
    border-top: solid 1px #dcdcdc;
    padding-top: 8px;
}
.chat-room-history-modal-wrapper {
  width: 100vw;
  height: 100vh;
  background: rgba(25, 25, 25, 0.8);
  position: fixed;
  top: 0;
  /* right: -100vw; */
  visibility: hidden;
  opacity: 0;
  right: 0;
  /* transition: visibility 0s, opacity 0.3s ease-in-out; */
  -webkit-transition: visibility .3s, opacity 0.5s ease-in-out;
  transition: visibility .3s, opacity 0.5s ease-in-out;
  z-index: 4; }

.chat-room-history-modal-wrapper.active-chat-room-history-modal-wrapper {
  /* right: 0; */
  opacity: 1;
  visibility: visible;
  -webkit-transition: visibility 0s, opacity 0.5s ease-in-out;
  transition: visibility 0s, opacity 0.5s ease-in-out; }

.chat-room-history-modal-content {
  position: absolute;
  width: 340px;
  height: calc(100vh - 8px);
  right: -100vw;
  top: 8px;
  display: inline-block;
  -webkit-transition: ease-in-out .5s;
  transition: ease-in-out .5s; }

.chat-room-history-modal-content.active-chat-room-history-modal-content {
  right: 0;
  background: #ffffff;
  border-top-left-radius: 18px; }

.chat-room-history-modal-wrapper .user-info-tab-content {
  width: 50%; }

.chat-room-broadcast-user-info-wrapper {
  /* width: 300px; */
  width: 340px;
  display: inline-block;
  -webkit-transition: ease-in-out .2s;
  transition: ease-in-out .2s;
  /* transition: left 2s, width .4s; */
  position: relative;
  /* right: -300px; */
  right: -340px;
  background: #ffffff;
  vertical-align: top;
  height: 100%;
  /* padding-top: 24px; */
  /* overflow: hidden; */
  box-shadow: -1px 0 0 0 rgba(25, 25, 25, 0.1);
  border-top-left-radius: 12px; }
  .chat-room-broadcast-user-info-wrapper .chat-room-case-history-close {
    background: #ffffff;
    border-radius: 50%;
    cursor: pointer;
    position: absolute;
    width: 48px;
    height: 48px;
    display: inline-block;
    text-align: center;
    line-height: 45px;
    top: 0;
    left: -64px; }
    .chat-room-broadcast-user-info-wrapper .chat-room-case-history-close svg {
      font-size: 24px;
      color: #7D7C7D; }
      .chat-room-broadcast-user-info-wrapper .chat-room-case-history-close svg:hover {
        color: #5c5c5c; }
  .chat-room-broadcast-user-info-wrapper .user-info-name-avatar-wrapper {
    width: 100%;
    position: relative;
    /* padding: 0 16px 12px 16px; */
    padding: 24px;
    background: #f3f3f3;
    border-top-left-radius: 12px; }

.chat-room-broadcast-user-info-wrapper.active-chat-room-broadcast-user-info-wrapper {
  right: 0;
  /* width: 300px; */
  /* overflow: visible; */ }

.user-info-avatar-wrapper {
  border-radius: 50%;
  height: 48px;
  display: inline-block;
  width: 48px;
  vertical-align: top;
  background: pink;
  position: relative;
  text-align: center;
  line-height: 48px;
  color: #ffffff;
  font-size: 16px; }

.user-info-name-connectivity-modal {
  width: calc(100% - 96px);
  display: inline-block;
  padding-left: 12px;
  line-height: 19px;
  top: 50%;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%);
  left: 72px;
  position: absolute; }

.user-info-name-connectivity-modal p {
  white-space: nowrap;
  overflow: hidden;
  margin: 0;
  text-overflow: ellipsis;
  font-size: 16px;
  letter-spacing: -0.5px; }

.user-info-online {
  font-size: 10px;
  letter-spacing: 1.5px;
  color: rgba(25, 25, 25, 0.6);
  font-weight: bold; }

.user-info-offline {
  cursor: pointer;
  border-radius: 4px;
  background: #ff7d00;
  color: #ffffff;
  font-size: 12px;
  line-height: 1.5;
  letter-spacing: -0.2px;
  padding: 1px 8px;
  display: inline-block;
  margin-top: 4px;
  -webkit-transition: ease-in-out .2s;
  transition: ease-in-out .2s; }

.user-info-offline:hover {
  background: #e87200; }

.user-info-offline svg {
  margin-right: 4px;
  position: relative;
  top: -1px; }

.user-info-online svg {
  color: #19c700;
  position: relative;
  top: -1px;
  font-size: 13px;
  margin-right: 2px; }

.user-info-offline-dot {
  font-size: 10px;
  letter-spacing: 1.5px;
  color: rgba(25, 25, 25, 0.6);
  font-weight: bold; }

.user-info-offline-dot svg {
  color: #ff3f57;
  position: relative;
  top: -1px;
  font-size: 13px;
  margin-right: 2px; }

.user-info-offline-dot svg {
  color: #ff3f57;
  position: relative;
  top: -1px;
  font-size: 13px;
  margin-right: 2px; }

.user-info-tab-wrapper {
  /* border-bottom: solid 1px #dcdcdc; */
  width: 100%;
  position: relative;
  /* padding: 12px 0 0; */
  background: #f3f3f3; }

.user-info-tab-content {
  width: calc(100% / 3);
  display: inline-block;
  color: rgba(25, 25, 25, 0.6);
  text-align: center;
  font-size: 14px;
  letter-spacing: -0.4px;
  position: relative;
  padding: 8px 0; }
  .user-info-tab-content svg {
    font-size: 14px; }
  .user-info-tab-content:nth-child(1).active-room-broadcast-list-tab {
    border-radius: 0 12px 0 0; }
  .user-info-tab-content:nth-last-child(1).active-room-broadcast-list-tab {
    border-radius: 12px 0 0 0; }

.user-info-tab-content.active-room-broadcast-list-tab {
  color: #ff7d00;
  background: #ffffff;
  border-radius: 12px 12px 0 0; }

/* .user-info-tab-content.active-room-broadcast-list-tab:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 4px;
    background: #ff7d00;
    left: 0;
    bottom: 0;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
} */
.user-info-tab-content svg {
  font-size: 14px;
  margin-right: 4px;
  position: relative;
  top: -2px; }

.user-info-tab-content:hover {
  color: #191919;
  cursor: pointer; }

.user-info-list-wrapper {
  margin-bottom: 16px;
  width: 100%;
  text-align: left; }

.user-info-list-wrapper label {
  font-size: 14px;
  letter-spacing: -0.4px;
  color: rgba(25, 25, 25, 0.8);
  display: block; }

.user-info-box-wrapper {
  background: rgba(25, 25, 25, 0.05);
  border-radius: 8px;
  height: 40px;
  width: 100%;
  line-height: 40px;
  padding: 0 9px;
  font-size: 14px;
  color: #191919;
  width: 100%;
  position: relative;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden; }

.user-info-box-wrapper.with-button-copy {
  padding: 0 65px 0 8px; }

.user-detail-copy-wrapper {
  position: absolute;
  right: 12px;
  top: 50%;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%);
  color: rgba(25, 25, 25, 0.6);
  font-size: 12px;
  line-height: -0.2px;
  cursor: pointer; }

.user-detail-copy-wrapper:hover {
  color: rgba(25, 25, 25, 0.8); }

.user-detail-copy-wrapper svg {
  font-size: 16px;
  margin-right: 4px; }

.user-info-box-wrapper img {
  position: relative;
  top: -2px;
  margin-right: 7px; }

.user-info-box-wrapper svg {
  position: relative;
  top: -2px;
  margin-right: 7px; }

.user-info-tab.user-info-tab-modal {
  max-height: calc(100vh - 173px);
  height: 100%;
  overflow-y: hidden; }

.user-info-tab-modal {
  max-height: calc(100vh - 173px); }

.user-info-tags {
  font-size: 12px;
  letter-spacing: -0.4px;
  color: rgba(25, 25, 25, 0.6);
  line-height: 1.5;
  padding: 0 8px;
  border-radius: 4px;
  margin-right: 4px;
  display: inline-block;
  background: rgba(25, 25, 25, 0.06);
  vertical-align: top;
  margin-bottom: 5px; }

.broadcast-message-detail-wrapper {
  min-height: 100%;
  position: relative; }
  .broadcast-message-detail-wrapper .LoadingRoll_1234fed {
    height: unset;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
    .broadcast-message-detail-wrapper .LoadingRoll_1234fed .lds-ring {
      position: relative;
      height: 50px;
      width: 50px;
      -webkit-transform: unset;
              transform: unset;
      left: unset;
      top: unset; }
      .broadcast-message-detail-wrapper .LoadingRoll_1234fed .lds-ring div {
        width: 50px;
        height: 50px;
        border: solid 5px #ff7d00;
        border-color: #ff7d00 transparent transparent transparent; }
  .broadcast-message-detail-wrapper .error-component-wrapper {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }

.channel-icon-detail {
  width: 24px; }

.broadcast-dot {
  width: 3px;
  height: 3px;
  border-radius: 50%;
  position: relative;
  top: 1px;
  background: #191919;
  display: inline-block;
  top: -3px; }

.broadcast-content-box {
  min-height: 40px;
  height: unset;
  overflow: unset;
  white-space: unset;
  text-overflow: unset;
  padding-top: 8px;
  padding-bottom: 8px;
  word-break: break-word;
  line-height: 21px; }
  .broadcast-content-box.broadcast-content-box-image {
    padding-right: 15px; }
  .broadcast-content-box .icon-info {
    vertical-align: top;
    top: 6px; }
  .broadcast-content-box .broadcast-content {
    width: calc(100% - 23px);
    display: inline-block;
    font-size: 14px;
    letter-spacing: -0.2px;
    color: rgba(25, 25, 25, 0.6); }
    .broadcast-content-box .broadcast-content .broadcast-text {
      margin: 0; }
    .broadcast-content-box .broadcast-content .file-content-wrapper {
      width: 100%;
      display: block;
      position: relative;
      padding: 0; }
      .broadcast-content-box .broadcast-content .file-content-wrapper .file-content-left {
        margin: 0 8px 0 0;
        width: 44px;
        height: 44px;
        display: inline-block;
        border-radius: 50%;
        background: #fff;
        position: relative; }
        .broadcast-content-box .broadcast-content .file-content-wrapper .file-content-left svg {
          color: #ff7d00;
          top: 50%;
          left: 50%;
          -webkit-transform: translate(-50%, -50%);
                  transform: translate(-50%, -50%);
          position: absolute;
          font-size: 22px; }
      .broadcast-content-box .broadcast-content .file-content-wrapper .file-content-right {
        width: calc(100% - 44px);
        display: inline-block;
        position: absolute;
        top: 3px;
        left: 52px; }
        .broadcast-content-box .broadcast-content .file-content-wrapper .file-content-right .file-name {
          font-size: 14px;
          letter-spacing: -0.2px;
          margin: 0;
          position: relative;
          top: 12px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          color: #191919;
          line-height: 1;
          padding: 0 8px 0 0; }
        .broadcast-content-box .broadcast-content .file-content-wrapper .file-content-right .file-size {
          font-size: 14px;
          letter-spacing: -0.2px;
          margin: 0;
          color: rgba(25, 25, 25, 0.6);
          line-height: 1; }
        .broadcast-content-box .broadcast-content .file-content-wrapper .file-content-right:hover .file-name {
          color: #ff7d00; }
    .broadcast-content-box .broadcast-content .caption-broadcast {
      margin: 6px 0 0; }
    .broadcast-content-box .broadcast-content .broadcast-image {
      border-radius: 4px;
      max-width: 100%;
      margin: 0;
      cursor: pointer; }
    .broadcast-content-box .broadcast-content .broadcast-video {
      border-radius: 4px;
      max-width: 100%;
      margin: 0;
      cursor: pointer; }
    .broadcast-content-box .broadcast-content p {
      margin: 4px 0 8px;
      font-size: 14px;
      letter-spacing: -0.2px;
      color: rgba(25, 25, 25, 0.6);
      text-align: justify; }

.modal-media-broadcast.modal-dialog {
  width: 100vw !important; }

.modal-media-broadcast .modal-content {
  width: 100%;
  height: 100%;
  background: none; }

.modal-media-broadcast .modal-content .modal-body {
  width: 100%;
  height: 100%;
  background: rgba(25, 25, 25, 0.8); }

.modal-media-broadcast .modal-content .modal-body img.image-preview-val {
  max-width: calc(100vw - 130px);
  max-height: calc(100vh - 130px); }

.modal-media-broadcast .modal-content .modal-body .image-wrapper {
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  text-align: center; }
  .modal-media-broadcast .modal-content .modal-body .image-wrapper figure {
    margin: 0; }
    .modal-media-broadcast .modal-content .modal-body .image-wrapper figure.zoom {
      background-size: 180%;
      background-position: -100% -100%;
      background-repeat: no-repeat;
      position: relative;
      width: 100%;
      overflow: hidden;
      cursor: -webkit-zoom-in;
      cursor: zoom-in; }
      .modal-media-broadcast .modal-content .modal-body .image-wrapper figure.zoom img {
        -webkit-transition: opacity 0.5s;
        transition: opacity 0.5s;
        display: inline-block; }
        .modal-media-broadcast .modal-content .modal-body .image-wrapper figure.zoom img:hover {
          opacity: 0; }

.modal-media-broadcast .modal-content .modal-body .video-wrapper {
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  text-align: center; }
  .modal-media-broadcast .modal-content .modal-body .video-wrapper video {
    max-width: calc(100% - 80px);
    max-height: calc(100% - 80px); }

.modal-media-broadcast .modal-content .modal-body .close-modal-media-broadcast {
  position: absolute;
  top: 20px;
  right: 20px;
  color: #ffffff;
  font-size: 40px;
  cursor: pointer;
  -webkit-transition: ease-in-out 0.1s;
  transition: ease-in-out 0.1s; }

.modal-media-broadcast .modal-content .modal-body .close-modal-media-broadcast:hover {
  color: #c9c9c9; }

.error-component-wrapper {
  width: 100%;
  display: block;
  text-align: center;
  padding: 10px; }
  .error-component-wrapper svg {
    color: #ff3f57;
    font-size: 58px; }
  .error-component-wrapper p {
    margin: 16px 0 0;
    font-size: 16px;
    letter-spacing: -0.3px;
    color: #ff3f57; }

.broadcast-nation-flag {
    width: 22px;
    position: relative;
    top: 1px !important;
}
.chat-room-product-detail-modal-wrapper {
  width: 100vw;
  height: 100vh;
  background: rgba(25, 25, 25, 0.8);
  position: fixed;
  top: 0;
  visibility: hidden;
  opacity: 0;
  right: 0;
  -webkit-transition: visibility .3s, opacity 0.5s ease-in-out;
  transition: visibility .3s, opacity 0.5s ease-in-out;
  z-index: 4; }
  .chat-room-product-detail-modal-wrapper .user-info-tab-content {
    width: 50%; }

.chat-room-product-detail-modal-wrapper.active-chat-room-product-detail-modal-wrapper {
  opacity: 1;
  visibility: visible;
  -webkit-transition: visibility 0s, opacity 0.5s ease-in-out;
  transition: visibility 0s, opacity 0.5s ease-in-out; }

.chat-room-product-detail-modal-content {
  position: absolute;
  width: 300px;
  height: 100vh;
  right: -100vw;
  top: 0;
  display: inline-block;
  -webkit-transition: ease-in-out .5s;
  transition: ease-in-out .5s; }

.chat-room-product-detail-modal-content.active-chat-room-product-detail-modal-content {
  right: 0;
  background: #ffffff;
  border-top-left-radius: 18px; }

.close-modal-product-detail-wrapper {
  background: #ffffff;
  border-radius: 50%;
  cursor: pointer;
  position: absolute;
  width: 48px;
  height: 48px;
  display: inline-block;
  text-align: center;
  line-height: 45px;
  top: 8px;
  left: -64px; }
  .close-modal-product-detail-wrapper svg {
    font-size: 24px;
    color: #7D7C7D; }
  .close-modal-product-detail-wrapper:hover svg {
    color: #5c5c5c; }

.product-detail-modal-inner-wrapper .product-title {
  margin: 0;
  max-width: 100%;
  padding: 16px 24px;
  text-align: center; }
  .product-detail-modal-inner-wrapper .product-title p {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    line-height: 24px;
    color: #191919;
    margin: 0;
    font-size: 20px;
    letter-spacing: -0.6px;
    word-break: break-word; }

.product-detail-modal-inner-wrapper .product-detail {
  height: calc(100vh - 56px);
  box-shadow: 0px 2px 20px rgba(25, 25, 25, 0.18); }
  .product-detail-modal-inner-wrapper .product-detail .product-detail-inner {
    padding: 16px 16px 26px; }
    .product-detail-modal-inner-wrapper .product-detail .product-detail-inner .main-product-detail-wrapper {
      padding: 0 8px; }
    .product-detail-modal-inner-wrapper .product-detail .product-detail-inner .image-wrapper {
      width: 100%;
      height: 220px;
      background-color: rgba(25, 25, 25, 0.05);
      border-radius: 8px;
      position: relative;
      overflow: hidden;
      margin-bottom: 8px; }
      .product-detail-modal-inner-wrapper .product-detail .product-detail-inner .image-wrapper img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%); }
    .product-detail-modal-inner-wrapper .product-detail .product-detail-inner label {
      font-size: 14px;
      letter-spacing: -0.4px;
      color: rgba(25, 25, 25, 0.8);
      margin: 0 0 4px 0; }
    .product-detail-modal-inner-wrapper .product-detail .product-detail-inner .value-wrapper {
      padding: 12px 8px;
      margin: 0 0 4px 0;
      border-radius: 8px;
      background-color: rgba(25, 25, 25, 0.05);
      font-size: 12px;
      letter-spacing: -0.2px;
      color: #191919;
      position: relative;
      word-break: break-word; }
      .product-detail-modal-inner-wrapper .product-detail .product-detail-inner .value-wrapper.read-more {
        max-height: 100px;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        padding-bottom: 25px; }
      .product-detail-modal-inner-wrapper .product-detail .product-detail-inner .value-wrapper .read-more-wrapper {
        display: block;
        width: 100%;
        background-color: #f3f3f3;
        color: #ff7d00;
        font-size: 10px;
        letter-spacing: -0.4px;
        position: absolute;
        bottom: 0;
        right: 0;
        padding: 0 8px 6px;
        text-align: right; }
        .product-detail-modal-inner-wrapper .product-detail .product-detail-inner .value-wrapper .read-more-wrapper b {
          cursor: pointer; }
          .product-detail-modal-inner-wrapper .product-detail .product-detail-inner .value-wrapper .read-more-wrapper b:hover {
            color: #e87200; }
      .product-detail-modal-inner-wrapper .product-detail .product-detail-inner .value-wrapper.product-url {
        padding: 12px 60px 12px 8px; }
        .product-detail-modal-inner-wrapper .product-detail .product-detail-inner .value-wrapper.product-url .copy-url {
          cursor: pointer;
          color: rgba(25, 25, 25, 0.6);
          position: absolute;
          right: 12px;
          top: 12px; }
          .product-detail-modal-inner-wrapper .product-detail .product-detail-inner .value-wrapper.product-url .copy-url svg {
            margin-right: 4px; }
          .product-detail-modal-inner-wrapper .product-detail .product-detail-inner .value-wrapper.product-url .copy-url:hover {
            color: #191919; }
      .product-detail-modal-inner-wrapper .product-detail .product-detail-inner .value-wrapper #copy-product-url {
        border: 0;
        width: 100%;
        background: transparent;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden; }

.modal-confirmation-send-product .modal-content {
  width: 560px !important; }

.modal-confirmation-send-product button {
  width: calc(50% - 4px) !important;
  margin-right: 8px; }
  .modal-confirmation-send-product button:nth-last-child(1) {
    margin-right: 0; }

.modal-selected-product {
  width: 90% !important; }
  .modal-selected-product .modal-content {
    height: 90%;
    width: 90%; }
    .modal-selected-product .modal-content .modal-body {
      padding: 24px;
      position: relative; }
      .modal-selected-product .modal-content .modal-body .modal-header {
        position: relative;
        padding: 0;
        border: none;
        text-align: left; }
        .modal-selected-product .modal-content .modal-body .modal-header p {
          font-size: 24px;
          letter-spacing: -0.6px;
          color: #191919;
          margin: 0 0 20px 0;
          display: inline-block;
          line-height: 1; }
        .modal-selected-product .modal-content .modal-body .modal-header span {
          color: rgba(25, 25, 25, 0.6);
          font-size: 14px;
          letter-spacing: -0.2px;
          position: relative;
          margin-left: 4px;
          top: -2px; }
        .modal-selected-product .modal-content .modal-body .modal-header svg {
          position: absolute;
          font-size: 24px;
          color: #ff7d00;
          cursor: pointer;
          right: 0; }
          .modal-selected-product .modal-content .modal-body .modal-header svg:hover {
            color: #e87200; }
      .modal-selected-product .modal-content .modal-body .modal-foot {
        padding: 12px 0 0 0;
        text-align: right; }
        .modal-selected-product .modal-content .modal-body .modal-foot button {
          margin-left: 8px; }
      .modal-selected-product .modal-content .modal-body .sales-talk-product-selected-outer {
        height: calc(100% - 104px);
        width: 100%; }
        .modal-selected-product .modal-content .modal-body .sales-talk-product-selected-outer .sales-talk-product-selected-comp-box {
          display: inline-block;
          vertical-align: top;
          width: calc(20% - 16px);
          margin: 8px 20px 0 0;
          padding: 12px;
          position: relative;
          border: solid 1px #fff;
          box-shadow: 0px 2px 20px rgba(25, 25, 25, 0.05); }
          .modal-selected-product .modal-content .modal-body .sales-talk-product-selected-outer .sales-talk-product-selected-comp-box:nth-child(5n) {
            margin: 8px 0 0 0; }
          .modal-selected-product .modal-content .modal-body .sales-talk-product-selected-outer .sales-talk-product-selected-comp-box .remove-product-icon {
            position: absolute;
            cursor: pointer;
            color: red; }
          .modal-selected-product .modal-content .modal-body .sales-talk-product-selected-outer .sales-talk-product-selected-comp-box .custom-checkbox {
            width: 24px;
            height: 24px;
            position: absolute;
            top: 10px;
            right: 10px;
            border-radius: 50%;
            z-index: 2;
            background: #fff;
            border: solid 1px #dcdcdc; }
            .modal-selected-product .modal-content .modal-body .sales-talk-product-selected-outer .sales-talk-product-selected-comp-box .custom-checkbox svg {
              position: absolute;
              left: 50%;
              top: 50%;
              -webkit-transform: translate(-50%, -50%);
                      transform: translate(-50%, -50%); }
            .modal-selected-product .modal-content .modal-body .sales-talk-product-selected-outer .sales-talk-product-selected-comp-box .custom-checkbox label {
              position: absolute;
              top: 0;
              left: 0;
              margin: 0;
              padding: 0; }
              .modal-selected-product .modal-content .modal-body .sales-talk-product-selected-outer .sales-talk-product-selected-comp-box .custom-checkbox label::before {
                width: 16px;
                height: 16px;
                border-radius: 50%;
                top: 0;
                left: 0; }
              .modal-selected-product .modal-content .modal-body .sales-talk-product-selected-outer .sales-talk-product-selected-comp-box .custom-checkbox label::after {
                width: 16px;
                height: 16px;
                top: 0;
                left: 0;
                border-radius: 50%; }
          .modal-selected-product .modal-content .modal-body .sales-talk-product-selected-outer .sales-talk-product-selected-comp-box .sales-talk-product-selected-comp-image-wrapper {
            width: 100%;
            height: 200px;
            border-radius: 12px;
            margin: 0;
            position: relative;
            background: rgba(25, 25, 25, 0.2);
            overflow: hidden; }
            .modal-selected-product .modal-content .modal-body .sales-talk-product-selected-outer .sales-talk-product-selected-comp-box .sales-talk-product-selected-comp-image-wrapper img {
              width: 100%;
              height: 100%;
              object-fit: cover;
              position: absolute;
              top: 50%;
              left: 50%;
              -webkit-transform: translate(-50%, -50%);
                      transform: translate(-50%, -50%);
              z-index: 1; }
          .modal-selected-product .modal-content .modal-body .sales-talk-product-selected-outer .sales-talk-product-selected-comp-box .product-title {
            font-size: 14px;
            max-width: 100%;
            overflow: hidden;
            display: -webkit-box;
            overflow: hidden;
            text-overflow: ellipsis;
            letter-spacing: -0.4px;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            margin: 12px 0 0;
            cursor: pointer; }
            .modal-selected-product .modal-content .modal-body .sales-talk-product-selected-outer .sales-talk-product-selected-comp-box .product-title:hover {
              text-decoration: underline; }
          .modal-selected-product .modal-content .modal-body .sales-talk-product-selected-outer .sales-talk-product-selected-comp-box .product-price {
            font-size: 12px;
            color: rgba(25, 25, 25, 0.8);
            letter-spacing: -0.2px;
            margin: 0 0 8px 0; }
          .modal-selected-product .modal-content .modal-body .sales-talk-product-selected-outer .sales-talk-product-selected-comp-box .product-stock {
            font-size: 10px;
            letter-spacing: 1.5px;
            color: #ff7d00;
            margin: 0; }

@charset "UTF-8";
.modal-image-video-preview {
  width: 100% !important; }
  .modal-image-video-preview .modal-content {
    width: 100%;
    height: 100%;
    background: unset; }
    .modal-image-video-preview .modal-content .modal-body {
      padding: 56px 86px;
      position: relative; }
      .modal-image-video-preview .modal-content .modal-body img {
        max-width: calc(100% - 112px);
        max-height: calc(100% - 172px);
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
        background: #ffffff; }
      .modal-image-video-preview .modal-content .modal-body svg {
        font-size: 24px;
        color: #ffffff;
        cursor: pointer;
        position: absolute;
        top: 44px;
        right: 44px; }
  .modal-image-video-preview img {
    max-width: 80vw;
    max-height: 80vh;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  .modal-image-video-preview video {
    max-width: 80vw;
    max-height: 80vh;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }

.modal-forward-message {
  position: fixed;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100%;
  background: rgba(25, 25, 25, 0.8);
  visibility: hidden;
  opacity: 0;
  right: 0;
  -webkit-transition: visibility .3s, opacity 0.5s ease-in-out;
  transition: visibility .3s, opacity 0.5s ease-in-out;
  z-index: 100; }
  .modal-forward-message.active-modal-forward-message {
    /* right: 0; */
    opacity: 1;
    visibility: visible;
    -webkit-transition: visibility 0s, opacity 0.5s ease-in-out;
    transition: visibility 0s, opacity 0.5s ease-in-out; }
  .modal-forward-message .modal-body {
    position: absolute;
    top: 8px;
    right: -100%;
    left: unset;
    -webkit-transform: unset;
            transform: unset;
    height: calc(100% - 8px);
    width: 600px;
    display: inline-block;
    border-radius: 8px 0 0 0;
    background: #ffffff;
    padding: 0;
    max-height: calc(100% - 8px);
    -webkit-transition: ease-in-out .4s;
    transition: ease-in-out .4s; }
    .modal-forward-message .modal-body.action-modal-body-new-conversation {
      right: 0; }
    .modal-forward-message .modal-body .custom-select-topic button {
      margin-top: 0; }
    .modal-forward-message .modal-body .close-modal-start-conversation {
      border-radius: 50%;
      width: 48px;
      height: 48px;
      background: #ffffff;
      color: #757575;
      position: absolute;
      left: -65px;
      top: 0;
      text-align: center;
      cursor: pointer; }
      .modal-forward-message .modal-body .close-modal-start-conversation:hover {
        background: #f9f9f9; }
      .modal-forward-message .modal-body .close-modal-start-conversation svg {
        position: relative;
        font-size: 25px;
        top: 10px; }
    .modal-forward-message .modal-body .new-conversation-wrapper {
      height: calc(100% - 72px);
      max-height: calc(100% - 72px);
      overflow: hidden; }
      .modal-forward-message .modal-body .new-conversation-wrapper .comp-back-button {
        margin: 0 0 24px; }
      .modal-forward-message .modal-body .new-conversation-wrapper label {
        display: inline-block !important; }
      .modal-forward-message .modal-body .new-conversation-wrapper .new-conversation-container {
        padding: 32px; }
        .modal-forward-message .modal-body .new-conversation-wrapper .new-conversation-container .new-conversation-title {
          color: #191919; }
          .modal-forward-message .modal-body .new-conversation-wrapper .new-conversation-container .new-conversation-title b {
            font-size: 20px;
            letter-spacing: -0.6px; }
          .modal-forward-message .modal-body .new-conversation-wrapper .new-conversation-container .new-conversation-title img {
            margin: 0 4px 0 0;
            position: relative;
            top: -3px; }
          .modal-forward-message .modal-body .new-conversation-wrapper .new-conversation-container .new-conversation-title p {
            margin: 2px 0 0;
            color: rgba(25, 25, 25, 0.6);
            font-size: 14px;
            letter-spacing: -0.2px; }
        .modal-forward-message .modal-body .new-conversation-wrapper .new-conversation-container .form-label {
          width: 100%;
          position: relative;
          font-size: 14px;
          letter-spacing: -0.6px;
          color: #191919; }
          .modal-forward-message .modal-body .new-conversation-wrapper .new-conversation-container .form-label.form-label-phone {
            margin: 24px 0 0; }
          .modal-forward-message .modal-body .new-conversation-wrapper .new-conversation-container .form-label p {
            margin: 0 8px 0 0;
            width: 142px;
            display: inline-block; }
        .modal-forward-message .modal-body .new-conversation-wrapper .new-conversation-container label span {
          letter-spacing: -0.2px;
          font-size: 14px;
          color: rgba(25, 25, 25, 0.6); }
        .modal-forward-message .modal-body .new-conversation-wrapper .new-conversation-container label.button-upload-image {
          margin: 0 4px;
          color: #ff7d00;
          letter-spacing: -0.4px;
          font-size: 12px;
          border-radius: 4px;
          cursor: pointer;
          display: inline-block !important;
          padding: 2px 8px;
          background: rgba(255, 126, 0, 0.1);
          -webkit-transition: ease-in-out .1s;
          transition: ease-in-out .1s; }
          .modal-forward-message .modal-body .new-conversation-wrapper .new-conversation-container label.button-upload-image:hover {
            color: #ffffff;
            background: #ff7d00; }
          .modal-forward-message .modal-body .new-conversation-wrapper .new-conversation-container label.button-upload-image svg {
            position: relative;
            top: -1px;
            margin-right: 4px; }
        .modal-forward-message .modal-body .new-conversation-wrapper .new-conversation-container textarea {
          width: 100%;
          height: 96px; }
        .modal-forward-message .modal-body .new-conversation-wrapper .new-conversation-container .image-and-caption-wrapper {
          width: 100%; }
        .modal-forward-message .modal-body .new-conversation-wrapper .new-conversation-container input[type="file"] {
          display: none; }
        .modal-forward-message .modal-body .new-conversation-wrapper .new-conversation-container .phone-number-form {
          color: #191919;
          font-size: 16px;
          letter-spacing: -0.3px;
          margin-top: 8px; }
          .modal-forward-message .modal-body .new-conversation-wrapper .new-conversation-container .phone-number-form .dropdown-menu {
            border: 1px solid rgba(0, 0, 0, 0.15);
            -webkit-transform: translate3d(0px, 50px, 0px) !important;
                    transform: translate3d(0px, 50px, 0px) !important; }
          .modal-forward-message .modal-body .new-conversation-wrapper .new-conversation-container .phone-number-form input[type="text"] {
            width: 100%;
            padding: 12px 16px;
            border: solid 1px rgba(25, 25, 25, 0.1);
            border-radius: 8px;
            box-shadow: none;
            outline: none; }
            .modal-forward-message .modal-body .new-conversation-wrapper .new-conversation-container .phone-number-form input[type="text"]:focus {
              border-color: #ff7d00; }
          .modal-forward-message .modal-body .new-conversation-wrapper .new-conversation-container .phone-number-form .phone-wrapper {
            width: calc(100% - 150px);
            display: inline-block;
            vertical-align: top; }
            .modal-forward-message .modal-body .new-conversation-wrapper .new-conversation-container .phone-number-form .phone-wrapper input {
              height: 48px; }
            .modal-forward-message .modal-body .new-conversation-wrapper .new-conversation-container .phone-number-form .phone-wrapper p {
              color: #ff3f57;
              font-size: 12px;
              letter-spacing: -0.4px;
              margin: 4px 0 0;
              width: 100%;
              position: relative;
              padding: 0 0 0 20px; }
              .modal-forward-message .modal-body .new-conversation-wrapper .new-conversation-container .phone-number-form .phone-wrapper p svg {
                margin-right: 4px;
                position: absolute;
                left: 0;
                top: 1px;
                font-size: 16px; }
          .modal-forward-message .modal-body .new-conversation-wrapper .new-conversation-container .phone-number-form.phone-number-disabled input[type="text"] {
            background: rgba(25, 25, 25, 0.1);
            border: none;
            cursor: not-allowed; }
      .modal-forward-message .modal-body .new-conversation-wrapper .start-conversation-contact-wrapper label {
        margin: 0 0 8px; }
      .modal-forward-message .modal-body .new-conversation-wrapper .start-conversation-contact-wrapper .add-by-phone-number {
        display: inline-block;
        font-size: 12px;
        letter-spacing: .2px;
        cursor: pointer;
        padding: 0 10px;
        margin: 0 0 0 4px;
        border-radius: 4px; }
      .modal-forward-message .modal-body .new-conversation-wrapper .start-conversation-contact-wrapper .search-contact-wrapper {
        position: relative; }
        .modal-forward-message .modal-body .new-conversation-wrapper .start-conversation-contact-wrapper .search-contact-wrapper input {
          width: 100%;
          border: solid 1px #dcdcdc;
          padding: 12px 50px 12px 16px;
          border-radius: 8px;
          margin: 0;
          font-size: 16px;
          letter-spacing: -0.3px; }
          .modal-forward-message .modal-body .new-conversation-wrapper .start-conversation-contact-wrapper .search-contact-wrapper input:focus {
            border: solid 1px #ff7d00; }
        .modal-forward-message .modal-body .new-conversation-wrapper .start-conversation-contact-wrapper .search-contact-wrapper svg {
          position: absolute;
          top: 50%;
          right: 16px;
          -webkit-transform: translate(0, -50%);
                  transform: translate(0, -50%);
          font-size: 20px; }
      .modal-forward-message .modal-body .new-conversation-wrapper .start-conversation-contact-wrapper .selected-contact {
        position: relative;
        font-size: 14px;
        letter-spacing: -.4px;
        color: rgba(25, 25, 25, 0.6);
        border-radius: 4px;
        padding: 4px 24px 3px 8px;
        background: rgba(25, 25, 25, 0.05);
        display: inline-block;
        word-break: break-word; }
        .modal-forward-message .modal-body .new-conversation-wrapper .start-conversation-contact-wrapper .selected-contact svg {
          position: relative;
          top: -2px;
          margin-right: 4px;
          font-size: 16px; }
        .modal-forward-message .modal-body .new-conversation-wrapper .start-conversation-contact-wrapper .selected-contact .remove-contact {
          position: absolute;
          border-radius: 50%;
          background: #707070;
          right: 7px;
          top: 50%;
          -webkit-transform: translate(0, -50%);
                  transform: translate(0, -50%);
          width: 13px;
          height: 13px;
          cursor: pointer;
          text-align: center; }
          .modal-forward-message .modal-body .new-conversation-wrapper .start-conversation-contact-wrapper .selected-contact .remove-contact svg {
            font-size: 9px;
            position: absolute;
            top: 2px;
            left: 2px;
            color: #ffffff;
            margin: 0; }
          .modal-forward-message .modal-body .new-conversation-wrapper .start-conversation-contact-wrapper .selected-contact .remove-contact:hover {
            background: rgba(112, 112, 112, 0.8); }
      .modal-forward-message .modal-body .new-conversation-wrapper .start-conversation-contact-wrapper .add-phone-number-info {
        font-size: 12px;
        letter-spacing: -0.4px;
        color: #191919;
        margin: 8px 0 0; }
        .modal-forward-message .modal-body .new-conversation-wrapper .start-conversation-contact-wrapper .add-phone-number-info svg {
          position: relative;
          margin-right: 4px;
          top: -1px;
          font-size: 16px; }
      .modal-forward-message .modal-body .new-conversation-wrapper .start-conversation-contact-wrapper .dropdown-contact-list {
        position: absolute;
        left: 0;
        top: 52px;
        width: 100%;
        border-radius: 8px;
        max-height: 193px;
        overflow-y: auto;
        background: #ffffff;
        border: solid 1px #dcdcdc;
        z-index: 2;
        overflow-x: hidden !important; }
        .modal-forward-message .modal-body .new-conversation-wrapper .start-conversation-contact-wrapper .dropdown-contact-list .option-contact {
          font-size: 14px;
          letter-spacing: -.2px;
          border-bottom: solid 1px #dcdcdc;
          border-radius: 0;
          padding: 8px 20px;
          cursor: pointer;
          position: relative;
          word-break: break-word; }
          .modal-forward-message .modal-body .new-conversation-wrapper .start-conversation-contact-wrapper .dropdown-contact-list .option-contact.selected-number-active {
            padding: 8px 20px 8px 36px;
            background: #FFF2E5; }
            .modal-forward-message .modal-body .new-conversation-wrapper .start-conversation-contact-wrapper .dropdown-contact-list .option-contact.selected-number-active::before {
              color: #ff7e00;
              content: '\2714';
              position: absolute;
              left: 15px;
              top: 50%;
              -webkit-transform: translate(0, -50%);
                      transform: translate(0, -50%); }
          .modal-forward-message .modal-body .new-conversation-wrapper .start-conversation-contact-wrapper .dropdown-contact-list .option-contact:nth-child(1) {
            border-top-left-radius: 8px;
            border-top-right-radius: 8px; }
          .modal-forward-message .modal-body .new-conversation-wrapper .start-conversation-contact-wrapper .dropdown-contact-list .option-contact span {
            font-size: 12px;
            display: block;
            color: rgba(25, 25, 25, 0.6); }
          .modal-forward-message .modal-body .new-conversation-wrapper .start-conversation-contact-wrapper .dropdown-contact-list .option-contact:hover {
            background: #FFF2E5; }
          .modal-forward-message .modal-body .new-conversation-wrapper .start-conversation-contact-wrapper .dropdown-contact-list .option-contact:nth-last-child(1) {
            border: none;
            border-bottom-left-radius: 8px;
            border-bottom-right-radius: 8px; }
          .modal-forward-message .modal-body .new-conversation-wrapper .start-conversation-contact-wrapper .dropdown-contact-list .option-contact.no-contact-found {
            text-align: center;
            color: rgba(25, 25, 25, 0.6);
            background: none !important; }
      .modal-forward-message .modal-body .new-conversation-wrapper .channel-type-icon-wrapper {
        display: block;
        font-size: 16px;
        letter-spacing: -0.3px;
        line-height: 1; }
        .modal-forward-message .modal-body .new-conversation-wrapper .channel-type-icon-wrapper img {
          position: relative;
          margin-right: 4px;
          top: -1px; }
      .modal-forward-message .modal-body .new-conversation-wrapper .forward-message-content-wrapper {
        position: relative; }
        .modal-forward-message .modal-body .new-conversation-wrapper .forward-message-content-wrapper .forward-message-content-box {
          padding: 12px 16px;
          width: 100%;
          border-radius: 8px;
          background: rgba(25, 25, 25, 0.1);
          font-size: 16px;
          letter-spacing: -0.3px;
          color: rgba(25, 25, 25, 0.4);
          word-break: break-word; }
          .modal-forward-message .modal-body .new-conversation-wrapper .forward-message-content-wrapper .forward-message-content-box .include-info-text {
            padding: 0 0 12px 0; }
            .modal-forward-message .modal-body .new-conversation-wrapper .forward-message-content-wrapper .forward-message-content-box .include-info-text p {
              margin: 0; }
            .modal-forward-message .modal-body .new-conversation-wrapper .forward-message-content-wrapper .forward-message-content-box .include-info-text.include-info-image-video-file {
              padding: 8px 0 0 0; }
          .modal-forward-message .modal-body .new-conversation-wrapper .forward-message-content-wrapper .forward-message-content-box .caption-text {
            margin: 0;
            padding: 8px 0 0 0; }
          .modal-forward-message .modal-body .new-conversation-wrapper .forward-message-content-wrapper .forward-message-content-box .forward-image-video-outer-wrapper {
            width: 100%;
            position: relative; }
            .modal-forward-message .modal-body .new-conversation-wrapper .forward-message-content-wrapper .forward-message-content-box .forward-image-video-outer-wrapper::before {
              width: 100%;
              height: 1px;
              background: #dcdcdc;
              position: absolute;
              left: 0;
              bottom: -8px;
              content: ""; }
          .modal-forward-message .modal-body .new-conversation-wrapper .forward-message-content-wrapper .forward-message-content-box .forward-image-video-wrapper {
            width: 56px;
            height: 56px;
            position: relative;
            border-radius: 8px;
            overflow: hidden;
            border: solid 1px rgba(25, 25, 25, 0.1);
            margin-bottom: 8px; }
            .modal-forward-message .modal-body .new-conversation-wrapper .forward-message-content-wrapper .forward-message-content-box .forward-image-video-wrapper .forward-hover-wrapper {
              background: rgba(25, 25, 25, 0.4);
              top: 0;
              left: 0;
              position: absolute;
              width: 100%;
              height: 100%;
              display: none;
              cursor: pointer; }
              .modal-forward-message .modal-body .new-conversation-wrapper .forward-message-content-wrapper .forward-message-content-box .forward-image-video-wrapper .forward-hover-wrapper svg {
                color: #fff;
                position: absolute;
                left: 50%;
                top: 50%;
                -webkit-transform: translate(-50%, -50%);
                        transform: translate(-50%, -50%); }
            .modal-forward-message .modal-body .new-conversation-wrapper .forward-message-content-wrapper .forward-message-content-box .forward-image-video-wrapper:hover .forward-hover-wrapper {
              display: block; }
            .modal-forward-message .modal-body .new-conversation-wrapper .forward-message-content-wrapper .forward-message-content-box .forward-image-video-wrapper img {
              max-width: 100%;
              max-height: 100%;
              position: absolute;
              top: 50%;
              left: 50%;
              -webkit-transform: translate(-50%, -50%);
                      transform: translate(-50%, -50%); }
            .modal-forward-message .modal-body .new-conversation-wrapper .forward-message-content-wrapper .forward-message-content-box .forward-image-video-wrapper video {
              max-width: 100%;
              max-height: 100%;
              width: 100%;
              position: absolute;
              top: 50%;
              left: 50%;
              width: 100%;
              -webkit-transform: translate(-50%, -50%);
                      transform: translate(-50%, -50%); }
          .modal-forward-message .modal-body .new-conversation-wrapper .forward-message-content-wrapper .forward-message-content-box .forward-file-wrapper {
            width: 100%;
            letter-spacing: -0.2px;
            background: rgba(25, 25, 25, 0.1);
            border-radius: 8px;
            padding: 12px 12px 12px 46px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            font-size: 14px;
            position: relative; }
            .modal-forward-message .modal-body .new-conversation-wrapper .forward-message-content-wrapper .forward-message-content-box .forward-file-wrapper p {
              margin: 0;
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden; }
            .modal-forward-message .modal-body .new-conversation-wrapper .forward-message-content-wrapper .forward-message-content-box .forward-file-wrapper svg {
              font-size: 20px;
              position: absolute;
              left: 17px;
              top: 50%;
              -webkit-transform: translate(0, -50%);
              transform: translate(0, -50%);
              margin-right: 4px; }
      .modal-forward-message .modal-body .new-conversation-wrapper .include-forward-info {
        position: absolute;
        right: 0;
        top: 25px;
        font-size: 12px;
        letter-spacing: -0.4px;
        color: rgba(25, 25, 25, 0.8);
        margin: 6px;
        padding: 0 0 0 26px;
        margin: 0; }
        .modal-forward-message .modal-body .new-conversation-wrapper .include-forward-info .custom-switch-wrapper {
          width: 22px;
          margin: 0;
          height: 12px;
          position: absolute;
          left: 0;
          top: 3px; }
          .modal-forward-message .modal-body .new-conversation-wrapper .include-forward-info .custom-switch-wrapper .custom-switch {
            padding: 0; }
            .modal-forward-message .modal-body .new-conversation-wrapper .include-forward-info .custom-switch-wrapper .custom-switch::before {
              width: 7px;
              height: 7px;
              left: 4px;
              bottom: 3px; }
          .modal-forward-message .modal-body .new-conversation-wrapper .include-forward-info .custom-switch-wrapper input:checked + .custom-switch:before {
            -webkit-transform: translateX(8px);
                    transform: translateX(8px); }
    .modal-forward-message .modal-body .send-message-button {
      width: 100%;
      padding: 12px 24px;
      background: #ffffff;
      font-size: 16px;
      letter-spacing: -0.5px;
      position: absolute;
      bottom: 0;
      left: 0;
      box-shadow: 0 2px 20px 0 rgba(25, 25, 25, 0.18); }
      .modal-forward-message .modal-body .send-message-button button {
        width: 100%;
        height: 48px; }

.popup-message-info-wrapper {
  width: 100vw;
  height: 100vh;
  background: rgba(25, 25, 25, 0.8);
  position: fixed;
  top: 0;
  /* right: -100vw; */
  visibility: hidden;
  opacity: 0;
  right: 0;
  /* transition: visibility 0s, opacity 0.3s ease-in-out; */
  -webkit-transition: visibility .3s, opacity 0.5s ease-in-out;
  transition: visibility .3s, opacity 0.5s ease-in-out;
  z-index: 4; }
  .popup-message-info-wrapper.active-popup-message-info {
    opacity: 1;
    visibility: visible;
    -webkit-transition: visibility 0s, opacity 0.5s ease-in-out;
    transition: visibility 0s, opacity 0.5s ease-in-out; }
  .popup-message-info-wrapper .popup-message-info-main-wrapper {
    width: 440px;
    -webkit-transition: ease-in-out 0.5s;
    transition: ease-in-out 0.5s;
    background: #ffffff;
    height: calc(100vh - 8px);
    border-top-left-radius: 18px;
    bottom: 0;
    right: -405px;
    position: fixed; }
    .popup-message-info-wrapper .popup-message-info-main-wrapper.active-popup-message-info-main-wrapper {
      right: 0; }
    .popup-message-info-wrapper .popup-message-info-main-wrapper .popup-message-info-close {
      background: #ffffff;
      border-radius: 50%;
      cursor: pointer;
      position: absolute;
      width: 48px;
      height: 48px;
      display: inline-block;
      text-align: center;
      line-height: 45px;
      top: 0;
      left: -64px; }
      .popup-message-info-wrapper .popup-message-info-main-wrapper .popup-message-info-close svg {
        font-size: 24px;
        color: #7D7C7D; }
    .popup-message-info-wrapper .popup-message-info-main-wrapper .title-popup-message-info {
      font-size: 20px;
      letter-spacing: -0.6px;
      padding: 24px;
      border-bottom: solid 1px #dcdcdc;
      margin: 0; }
      .popup-message-info-wrapper .popup-message-info-main-wrapper .title-popup-message-info svg {
        position: relative;
        margin-right: 8px;
        top: -2px; }
  .popup-message-info-wrapper .popup-message-info-body-wrapper {
    width: 100%;
    display: block;
    position: relative;
    height: calc(100% - 80px); }
    .popup-message-info-wrapper .popup-message-info-body-wrapper .popup-message-info-body-top {
      width: 100%;
      display: block;
      position: relative;
      background: #f3f3f3;
      height: 60%; }
      .popup-message-info-wrapper .popup-message-info-body-wrapper .popup-message-info-body-top .popup-message-info-body-inner {
        position: absolute;
        max-height: 100%;
        overflow-y: auto;
        width: 100%;
        left: 0;
        top: 0;
        padding: 0 0 16px 0; }
        .popup-message-info-wrapper .popup-message-info-body-wrapper .popup-message-info-body-top .popup-message-info-body-inner .message-action-dropdown {
          display: none !important; }
        .popup-message-info-wrapper .popup-message-info-body-wrapper .popup-message-info-body-top .popup-message-info-body-inner .message-out-bubble,
        .popup-message-info-wrapper .popup-message-info-body-wrapper .popup-message-info-body-top .popup-message-info-body-inner .message-out-bubble-file,
        .popup-message-info-wrapper .popup-message-info-body-wrapper .popup-message-info-body-top .popup-message-info-body-inner .message-out-bubble-image,
        .popup-message-info-wrapper .popup-message-info-body-wrapper .popup-message-info-body-top .popup-message-info-body-inner .message-out-bubble-video,
        .popup-message-info-wrapper .popup-message-info-body-wrapper .popup-message-info-body-top .popup-message-info-body-inner .message-out-bubble-rating,
        .popup-message-info-wrapper .popup-message-info-body-wrapper .popup-message-info-body-top .popup-message-info-body-inner .message-out-bubble-location,
        .popup-message-info-wrapper .popup-message-info-body-wrapper .popup-message-info-body-top .popup-message-info-body-inner .message-out-interactive-bubble {
          max-width: 100% !important; }
    .popup-message-info-wrapper .popup-message-info-body-wrapper .popup-message-info-body-bottom {
      width: 100%;
      display: block;
      position: relative;
      height: 40%;
      padding: 32px 40px; }
      .popup-message-info-wrapper .popup-message-info-body-wrapper .popup-message-info-body-bottom .popup-message-info-name {
        padding: 0 0 8px 32px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        position: relative;
        font-size: 16px;
        letter-spacing: -0.5px;
        border-bottom: solid 1px #dcdcdc; }
        .popup-message-info-wrapper .popup-message-info-body-wrapper .popup-message-info-body-bottom .popup-message-info-name .popup-message-info-avatar {
          text-align: center;
          line-height: 24px;
          font-size: 12px;
          letter-spacing: -0.4px;
          color: #ffffff;
          width: 24px;
          height: 24px;
          background: lightblue;
          border-radius: 50%;
          position: absolute;
          left: 0;
          top: 0; }
      .popup-message-info-wrapper .popup-message-info-body-wrapper .popup-message-info-body-bottom .popup-message-status-wrapper .lds-ring {
        width: 40px;
        height: 40px;
        margin-top: 32px; }
        .popup-message-info-wrapper .popup-message-info-body-wrapper .popup-message-info-body-bottom .popup-message-status-wrapper .lds-ring div {
          width: 40px;
          height: 40px;
          border: 4px solid;
          border-color: var(--main-dark-orange) transparent transparent transparent; }
      .popup-message-info-wrapper .popup-message-info-body-wrapper .popup-message-info-body-bottom .popup-message-status-wrapper .popup-message-status-wrapper-list {
        margin: 8px 0 0;
        font-size: 12px;
        letter-spacing: -0.2px;
        position: relative;
        color: rgba(25, 25, 25, 0.6); }
        .popup-message-info-wrapper .popup-message-info-body-wrapper .popup-message-info-body-bottom .popup-message-status-wrapper .popup-message-status-wrapper-list img {
          position: relative;
          margin: 0 4px 0 0;
          top: -1px; }
        .popup-message-info-wrapper .popup-message-info-body-wrapper .popup-message-info-body-bottom .popup-message-status-wrapper .popup-message-status-wrapper-list .status-list-time {
          color: rgba(25, 25, 25, 0.6);
          position: absolute;
          right: 0;
          top: 0; }
          .popup-message-info-wrapper .popup-message-info-body-wrapper .popup-message-info-body-bottom .popup-message-status-wrapper .popup-message-status-wrapper-list .status-list-time .dot-time {
            width: 2px;
            height: 2px;
            border-radius: 50%;
            background: rgba(25, 25, 25, 0.4);
            position: relative;
            display: inline-block;
            top: -4px; }
        .popup-message-info-wrapper .popup-message-info-body-wrapper .popup-message-info-body-bottom .popup-message-status-wrapper .popup-message-status-wrapper-list .status-list-time-empty {
          position: absolute;
          height: 2px;
          right: 0;
          top: 8px;
          background: rgba(25, 25, 25, 0.4);
          width: 24px;
          border-radius: 8px; }

.clock-tutorial-container {
  width: 100%;
  max-height: 100%;
  height: 100%;
  display: inline-block;
  /* background: pink; */
  text-align: center;
  padding-top: 32px;
  overflow: auto;
  background: #f3f3f3; }
  .clock-tutorial-container .clock-tutorial-agent-company {
    max-width: 90%;
    width: 486px;
    display: inline-block;
    border-bottom: solid 1px #dcdcdc; }
    .clock-tutorial-container .clock-tutorial-agent-company p {
      margin: 0;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap; }
    .clock-tutorial-container .clock-tutorial-agent-company .clock-tutorial-agent {
      margin: 48px 0 12px;
      font-size: 20px;
      letter-spacing: -.6px; }
    .clock-tutorial-container .clock-tutorial-agent-company .clock-tutorial-company {
      font-size: 12px;
      margin: 0 0 12px;
      letter-spacing: -0.4px;
      color: rgba(25, 25, 25, 0.4); }

.clock-tutorial-container .main-logo {
  width: 120px; }

.clock-tutorial-container ul {
  padding: 0;
  margin: 0;
  list-style: none;
  display: inline-block; }

.clock-tutorial-container ul li {
  padding: 0 20px 0 36px;
  max-width: 100%;
  font-size: 14px;
  text-align: left;
  position: relative; }

.button-clock-tutorial-wrapper {
  text-align: center; }
  .button-clock-tutorial-wrapper li {
    position: relative;
    font-size: 14px;
    letter-spacing: -0.2px;
    color: rgba(25, 25, 25, 0.6);
    margin-bottom: 16px; }
  .button-clock-tutorial-wrapper .clock-status {
    width: 8px;
    height: 8px;
    display: inline-block;
    vertical-align: top;
    margin-right: 8px;
    top: 6px;
    border-radius: 50%;
    position: absolute;
    left: 20px;
    top: 6px; }
    .button-clock-tutorial-wrapper .clock-status.online-status {
      background: #19C700; }
    .button-clock-tutorial-wrapper .clock-status.away-status {
      background: #FFB438; }
  .button-clock-tutorial-wrapper .clock-separator {
    margin: 32px 0;
    width: 360px;
    display: inline-block;
    max-width: 90%;
    position: relative;
    height: 2px;
    background: rgba(25, 25, 25, 0.2); }
    .button-clock-tutorial-wrapper .clock-separator p {
      background: #f3f3f3;
      position: absolute;
      margin: 0;
      padding: 0 10px;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%);
      font-size: 14px;
      letter-spacing: -.4px;
      color: #191919; }
  .button-clock-tutorial-wrapper a {
    font-size: 16px;
    letter-spacing: -.5px;
    color: var(--main-dark-orange); }
    .button-clock-tutorial-wrapper a svg {
      font-size: 18px;
      position: relative;
      top: -2px;
      margin-right: 4px; }
    .button-clock-tutorial-wrapper a:hover {
      color: var(--main-darker-orange); }
  .button-clock-tutorial-wrapper .main-button-clock-wrapper {
    margin-bottom: 32px; }
    .button-clock-tutorial-wrapper .main-button-clock-wrapper button {
      font-size: 16px;
      letter-spacing: -.5px;
      margin: 0 6px;
      font-family: PTRootBold; }
      .button-clock-tutorial-wrapper .main-button-clock-wrapper button.button-start-away {
        background: #FFB438;
        border: solid 1px #ffb438;
        border-radius: 8px;
        color: #ffffff; }
      .button-clock-tutorial-wrapper .main-button-clock-wrapper button.button-go-online {
        background: #19C700;
        border: solid 1px #19c700;
        border-radius: 8px;
        color: #ffffff; }

.button-clock-tutorial-wrapper .tutorial-button-wrapper {
  display: inline-block;
  font-size: 10px;
  background: rgba(25, 25, 25, 0.05);
  border-radius: 4px;
  padding: 2px 7px;
  margin-right: 5px;
  color: rgba(25, 25, 25, 0.6);
  letter-spacing: 1.5px;
  margin-top: 12px; }

.button-clock-tutorial-wrapper .tutorial-start svg {
  color: #19c700; }

.button-clock-tutorial-wrapper .tutorial-start-as-away svg {
  color: #ffb438; }

.button-clock-tutorial-wrapper .tutorial-button-wrapper svg {
  position: relative;
  top: -1px;
  font-size: 9px;
  margin-right: 3px; }

.clock-tutorial-step {
  font-size: 24px;
  letter-spacing: -0.6px;
  width: 100%;
  margin-top: 24px; }
  .clock-tutorial-step .clock-tutorial-you-offline {
    margin: 0 0 16px;
    font-size: 40px;
    letter-spacing: -0.6px;
    position: relative;
    display: inline-block;
    padding-left: 22px;
    color: #191919; }
    .clock-tutorial-step .clock-tutorial-you-offline::before {
      content: "";
      position: absolute;
      left: 0;
      top: 50%;
      -webkit-transform: translate(0, -50%);
              transform: translate(0, -50%);
      width: 14px;
      height: 14px;
      border-radius: 50%;
      background: #ff3f57; }
  .clock-tutorial-step p {
    margin: 0;
    color: rgba(25, 25, 25, 0.6);
    font-size: 16px;
    letter-spacing: -0.3px; }

.clock-tutorial-step ul li {
  font-size: 16px;
  letter-spacing: -0.3px;
  margin-top: 10px; }

.clock-tutorial-step-badge {
  display: inline-block;
  border-radius: 50%;
  background: #0052ff;
  color: #ffffff;
  width: 21px;
  height: 21px;
  text-align: center;
  font-size: 13px;
  letter-spacing: -0.4px;
  line-height: 16px;
  margin-right: 12px;
  position: relative;
  top: -1px;
  box-shadow: 0 2px 20px 0 rgba(25, 25, 25, 0.18);
  border: solid 2px #ffffff; }

.modal-preview-image-or-video-reuseable {
  width: 100vw !important; }
  .modal-preview-image-or-video-reuseable .modal-content {
    width: 100%;
    height: 100%;
    background: none; }
    .modal-preview-image-or-video-reuseable .modal-content .modal-body {
      width: 100%;
      height: 100%;
      background: rgba(25, 25, 25, 0.8); }
      .modal-preview-image-or-video-reuseable .modal-content .modal-body img {
        max-width: calc(100vw - 130px);
        max-height: calc(100vh - 130px); }
      .modal-preview-image-or-video-reuseable .modal-content .modal-body .image-wrapper {
        display: inline-block;
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%); }
      .modal-preview-image-or-video-reuseable .modal-content .modal-body .video-wrapper {
        display: inline-block;
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%); }
        .modal-preview-image-or-video-reuseable .modal-content .modal-body .video-wrapper video {
          max-height: calc(100vh - 130px);
          max-width: calc(100vw - 130px); }
      .modal-preview-image-or-video-reuseable .modal-content .modal-body .close-modal-image-video {
        position: absolute;
        top: 20px;
        right: 20px;
        color: #ffffff;
        font-size: 40px;
        cursor: pointer;
        -webkit-transition: ease-in-out 0.1s;
        transition: ease-in-out 0.1s; }
        .modal-preview-image-or-video-reuseable .modal-content .modal-body .close-modal-image-video:hover {
          color: #c9c9c9; }

.modal-confirmation-send-conversation .modal-content {
  border-radius: 8px;
  padding: 32px;
  width: 476px; }
  .modal-confirmation-send-conversation .modal-content .modal-body {
    padding: 0; }
    .modal-confirmation-send-conversation .modal-content .modal-body p {
      margin: 0;
      font-size: 20px;
      line-height: 24px;
      color: #191919; }
      .modal-confirmation-send-conversation .modal-content .modal-body p svg {
        font-size: 22px;
        top: -2px;
        position: relative;
        margin-right: 4px; }
      .modal-confirmation-send-conversation .modal-content .modal-body p:nth-child(2) {
        font-size: 14px;
        line-height: 21px;
        letter-spacing: -0.2px;
        color: rgba(25, 25, 25, 0.6);
        margin: 4px 0 16px; }
    .modal-confirmation-send-conversation .modal-content .modal-body button {
      width: calc(50% - 2px);
      margin-right: 4px; }
      .modal-confirmation-send-conversation .modal-content .modal-body button:nth-last-child(1) {
        margin-right: 0; }

.modal-topup-balance .modal-content {
  border-radius: 8px;
  padding: 32px;
  width: 476px; }
  .modal-topup-balance .modal-content .modal-body {
    padding: 0; }
    .modal-topup-balance .modal-content .modal-body p {
      margin: 0;
      font-size: 20px;
      line-height: 24px;
      color: #191919; }
      .modal-topup-balance .modal-content .modal-body p svg {
        font-size: 22px;
        top: -2px;
        position: relative;
        margin-right: 4px; }
      .modal-topup-balance .modal-content .modal-body p:nth-child(2) {
        font-size: 14px;
        line-height: 21px;
        letter-spacing: -0.2px;
        color: rgba(25, 25, 25, 0.6);
        margin: 4px 0 16px; }
    .modal-topup-balance .modal-content .modal-body button {
      width: calc(50% - 2px);
      margin-right: 4px; }
      .modal-topup-balance .modal-content .modal-body button:nth-last-child(1) {
        margin-right: 0; }
      .modal-topup-balance .modal-content .modal-body button.width-100 {
        width: 100%; }

.checkout-success-main-wrapper {
  width: 100%;
  min-height: calc(100vh - 87px);
  margin-top: 87px;
  padding-top: 40px;
  background: #f3f3f3; }
  .checkout-success-main-wrapper #sectionContent {
    min-height: unset;
    background: #f3f3f3; }
  .checkout-success-main-wrapper .checkout-success-content .checkout-success-container {
    text-align: center;
    width: 100%; }
    .checkout-success-main-wrapper .checkout-success-content .checkout-success-container h1 {
      font-size: 40px;
      margin: 32px 0 12px; }
    .checkout-success-main-wrapper .checkout-success-content .checkout-success-container p {
      font-size: 16px;
      color: rgba(25, 25, 25, 0.8); }
      .checkout-success-main-wrapper .checkout-success-content .checkout-success-container p a {
        color: #ff7e00; }

.edit-profile-outer-wrapper .edit-account-profile-whatsapp {
  position: relative;
  width: 100%; }
  .edit-profile-outer-wrapper .edit-account-profile-whatsapp .edit-account-profile-header {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid rgba(25, 25, 25, 0.1);
    margin-bottom: 24px;
    padding-bottom: 12px; }
    .edit-profile-outer-wrapper .edit-account-profile-whatsapp .edit-account-profile-header .title-wrapper b {
      font-size: 20px; }
  .edit-profile-outer-wrapper .edit-account-profile-whatsapp .edit-account-profile-content .account-profile-content-wrapper {
    width: 425px;
    margin-bottom: 24px; }
    .edit-profile-outer-wrapper .edit-account-profile-whatsapp .edit-account-profile-content .account-profile-content-wrapper:last-child {
      margin-bottom: 0; }
    .edit-profile-outer-wrapper .edit-account-profile-whatsapp .edit-account-profile-content .account-profile-content-wrapper .label-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 8px; }
      .edit-profile-outer-wrapper .edit-account-profile-whatsapp .edit-account-profile-content .account-profile-content-wrapper .label-wrapper label {
        margin: 0;
        font-size: 14px; }
      .edit-profile-outer-wrapper .edit-account-profile-whatsapp .edit-account-profile-content .account-profile-content-wrapper .label-wrapper p {
        margin: 0;
        font-size: 14px;
        color: rgba(25, 25, 25, 0.6); }
    .edit-profile-outer-wrapper .edit-account-profile-whatsapp .edit-account-profile-content .account-profile-content-wrapper .edit-input-wrapper {
      margin-bottom: 8px; }
      .edit-profile-outer-wrapper .edit-account-profile-whatsapp .edit-account-profile-content .account-profile-content-wrapper .edit-input-wrapper textarea {
        width: 100%;
        border: 1px solid rgba(25, 25, 25, 0.1);
        border-radius: 8px;
        padding: 12px 16px;
        resize: none; }
    .edit-profile-outer-wrapper .edit-account-profile-whatsapp .edit-account-profile-content .account-profile-content-wrapper .info-wrapper {
      display: flex;
      align-items: center; }
      .edit-profile-outer-wrapper .edit-account-profile-whatsapp .edit-account-profile-content .account-profile-content-wrapper .info-wrapper b {
        font-size: 12px; }
      .edit-profile-outer-wrapper .edit-account-profile-whatsapp .edit-account-profile-content .account-profile-content-wrapper .info-wrapper svg {
        font-size: 16px;
        margin-right: 4px; }

.edit-business-profile-outer-wrapper .edit-business-profile-whatsapp {
  position: relative;
  width: 100%; }
  .edit-business-profile-outer-wrapper .edit-business-profile-whatsapp .edit-business-profile-header {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid rgba(25, 25, 25, 0.1);
    margin-bottom: 24px;
    padding-bottom: 12px; }
    .edit-business-profile-outer-wrapper .edit-business-profile-whatsapp .edit-business-profile-header .title-wrapper b {
      font-size: 20px; }
  .edit-business-profile-outer-wrapper .edit-business-profile-whatsapp .edit-business-profile-content .business-profile-content-wrapper {
    width: 425px;
    margin-bottom: 24px; }
    .edit-business-profile-outer-wrapper .edit-business-profile-whatsapp .edit-business-profile-content .business-profile-content-wrapper.website-wrapper {
      width: 500px; }
    .edit-business-profile-outer-wrapper .edit-business-profile-whatsapp .edit-business-profile-content .business-profile-content-wrapper .label-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 8px;
      width: 425px; }
      .edit-business-profile-outer-wrapper .edit-business-profile-whatsapp .edit-business-profile-content .business-profile-content-wrapper .label-wrapper label {
        margin: 0;
        font-size: 14px; }
      .edit-business-profile-outer-wrapper .edit-business-profile-whatsapp .edit-business-profile-content .business-profile-content-wrapper .label-wrapper p {
        margin: 0;
        font-size: 14px;
        color: rgba(25, 25, 25, 0.6); }
    .edit-business-profile-outer-wrapper .edit-business-profile-whatsapp .edit-business-profile-content .business-profile-content-wrapper .edit-input-wrapper {
      margin-bottom: 8px; }
      .edit-business-profile-outer-wrapper .edit-business-profile-whatsapp .edit-business-profile-content .business-profile-content-wrapper .edit-input-wrapper.edit-another-website {
        display: flex;
        position: relative;
        width: calc(100% + 30px); }
        .edit-business-profile-outer-wrapper .edit-business-profile-whatsapp .edit-business-profile-content .business-profile-content-wrapper .edit-input-wrapper.edit-another-website svg {
          height: 22px;
          width: 20px;
          position: absolute;
          right: 0;
          top: 12px;
          color: red;
          cursor: pointer; }
        .edit-business-profile-outer-wrapper .edit-business-profile-whatsapp .edit-business-profile-content .business-profile-content-wrapper .edit-input-wrapper.edit-another-website input {
          width: calc(100% - 30px); }
      .edit-business-profile-outer-wrapper .edit-business-profile-whatsapp .edit-business-profile-content .business-profile-content-wrapper .edit-input-wrapper textarea {
        width: 100%;
        border: 1px solid rgba(25, 25, 25, 0.1);
        border-radius: 8px;
        padding: 12px 16px;
        resize: none; }
        .edit-business-profile-outer-wrapper .edit-business-profile-whatsapp .edit-business-profile-content .business-profile-content-wrapper .edit-input-wrapper textarea:focus {
          border: 1px solid #FF7E00; }
      .edit-business-profile-outer-wrapper .edit-business-profile-whatsapp .edit-business-profile-content .business-profile-content-wrapper .edit-input-wrapper input {
        width: 100%;
        border: 1px solid rgba(25, 25, 25, 0.1);
        border-radius: 8px;
        padding: 12px 16px; }
        .edit-business-profile-outer-wrapper .edit-business-profile-whatsapp .edit-business-profile-content .business-profile-content-wrapper .edit-input-wrapper input:focus {
          border: 1px solid #FF7E00; }
      .edit-business-profile-outer-wrapper .edit-business-profile-whatsapp .edit-business-profile-content .business-profile-content-wrapper .edit-input-wrapper .custom-select-option-value-wrapper {
        border: 1px solid rgba(25, 25, 25, 0.1) !important; }
      .edit-business-profile-outer-wrapper .edit-business-profile-whatsapp .edit-business-profile-content .business-profile-content-wrapper .edit-input-wrapper.website {
        display: flex; }
        .edit-business-profile-outer-wrapper .edit-business-profile-whatsapp .edit-business-profile-content .business-profile-content-wrapper .edit-input-wrapper.website input {
          width: 425px; }
        .edit-business-profile-outer-wrapper .edit-business-profile-whatsapp .edit-business-profile-content .business-profile-content-wrapper .edit-input-wrapper.website .delete-wrapper {
          position: relative;
          left: 20px;
          top: 12px;
          cursor: pointer; }
          .edit-business-profile-outer-wrapper .edit-business-profile-whatsapp .edit-business-profile-content .business-profile-content-wrapper .edit-input-wrapper.website .delete-wrapper svg {
            font-size: 24px;
            color: #FF3F57; }
  .edit-business-profile-outer-wrapper .edit-business-profile-whatsapp .edit-business-profile-content .add-another-website-wrapper {
    display: flex;
    align-items: center;
    cursor: pointer;
    color: #FF7E00; }
    .edit-business-profile-outer-wrapper .edit-business-profile-whatsapp .edit-business-profile-content .add-another-website-wrapper svg {
      margin-right: 4px;
      position: relative;
      font-size: 20px; }
    .edit-business-profile-outer-wrapper .edit-business-profile-whatsapp .edit-business-profile-content .add-another-website-wrapper b {
      font-size: 16px; }

.case-rating-wrapper .case-rating-title {
  font-size: 20px;
  letter-spacing: -0.6px;
  margin: 0 0 16px 0;
  color: #191919;
  padding: 8px 0 12px;
  border-bottom: solid 1px #dcdcdc; }

.case-rating-wrapper .case-rating-description {
  margin: 8px 0 16px;
  font-size: 16px;
  letter-spacing: -0.3px;
  width: calc(100% - 230px);
  color: #191919;
  line-height: 24px; }

.case-rating-wrapper .add-case-rating-button {
  text-align: center;
  position: absolute;
  top: 32px;
  right: 32px; }

.case-rating-wrapper .case-rating-data-wrapper {
  width: 100%;
  color: #191919;
  overflow-x: auto; }
  .case-rating-wrapper .case-rating-data-wrapper .no-case-rating {
    width: 100%;
    text-align: center;
    font-size: 20px;
    letter-spacing: -0.6px;
    color: rgba(25, 25, 25, 0.6); }
  .case-rating-wrapper .case-rating-data-wrapper table {
    width: 100%;
    min-width: 1480px; }
    .case-rating-wrapper .case-rating-data-wrapper table thead {
      font-size: 14px;
      border-bottom: solid 1px #dcdcdc;
      color: #191919;
      letter-spacing: -0.4px; }
      .case-rating-wrapper .case-rating-data-wrapper table thead td {
        padding: 8px 20px 8px 8px !important;
        white-space: nowrap; }
    .case-rating-wrapper .case-rating-data-wrapper table tbody {
      font-size: 14px;
      letter-spacing: -0.2px;
      color: #191919; }
      .case-rating-wrapper .case-rating-data-wrapper table tbody tr {
        border-bottom: solid 1px #dcdcdc; }
      .case-rating-wrapper .case-rating-data-wrapper table tbody td {
        padding: 16px 8px; }
        .case-rating-wrapper .case-rating-data-wrapper table tbody td.col_action {
          font-size: 14px;
          letter-spacing: -0.4px; }
          .case-rating-wrapper .case-rating-data-wrapper table tbody td.col_action .edit-button {
            color: #ff7d00;
            cursor: pointer;
            margin-right: 16px; }
          .case-rating-wrapper .case-rating-data-wrapper table tbody td.col_action .remove-button {
            color: #ff3f57;
            cursor: pointer; }
          .case-rating-wrapper .case-rating-data-wrapper table tbody td.col_action svg {
            font-size: 16px;
            position: relative;
            top: -2px;
            margin-right: 4px; }

.form-filter-report {
  position: relative; }
  .form-filter-report .filter-report-title {
    border-bottom: solid 1px #dcdcdc;
    margin-bottom: 34px !important; }
  .form-filter-report .form-filter-report-button-wrapper {
    width: 100%;
    position: relative;
    display: block;
    text-align: right; }
    .form-filter-report .form-filter-report-button-wrapper button {
      margin: 24px 6px 0;
      text-align: center;
      padding: 0 75px !important; }
  .form-filter-report .date-report-select-wrapper {
    display: inline-block; }
  .form-filter-report.with-children .advanced-date-selection {
    margin-bottom: 12px; }

.message-history-filter {
  display: inline-block;
  margin-left: 24px;
  margin-bottom: 24px; }
  .message-history-filter.disabled-message-history-filter button {
    background: rgba(25, 25, 25, 0.1) !important;
    color: rgba(25, 25, 25, 0.4) !important; }
  .message-history-filter button {
    background: #ffffff !important;
    border: solid 1px #dcdcdc !important;
    outline: none !important;
    box-shadow: none !important;
    color: #191919 !important;
    padding: 12px 32px;
    border-radius: 8px;
    position: relative;
    font-size: 14px;
    letter-spacing: -0.2px;
    text-align: left; }
    .message-history-filter button .icon-filter {
      font-size: 20px;
      position: relative;
      top: -2px;
      margin-right: 4px; }
    .message-history-filter button .disabled {
      opacity: 0.4; }
    .message-history-filter button .filter-badge {
      display: inline-block;
      margin: 0 0 0 8px;
      padding: 8px;
      min-width: 24px;
      height: 24px;
      border-radius: 100px;
      background: #FF7E00;
      font-size: 12px;
      line-height: 7px;
      text-align: center;
      color: white; }
    .message-history-filter button .disabled-badge {
      background: #1919191A; }
    .message-history-filter button .hidden {
      display: none !important; }
  .message-history-filter .dropdown-menu {
    padding: 0;
    border-radius: 8px;
    box-shadow: 0px 2px 20px rgba(25, 25, 25, 0.18);
    border: none !important; }
    .message-history-filter .dropdown-menu button {
      border: none !important;
      border-bottom: solid 1px #dcdcdc !important;
      border-radius: 0;
      padding: 7px 18px 7px 36px;
      position: relative; }
      .message-history-filter .dropdown-menu button:nth-child(1) {
        border-top-left-radius: 8px;
        border-top-right-radius: 8px; }
      .message-history-filter .dropdown-menu button:nth-last-child(1) {
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
        border-bottom: 0 !important; }
      .message-history-filter .dropdown-menu button:hover {
        background: #fff2e5 !important; }
      .message-history-filter .dropdown-menu button svg {
        position: absolute;
        left: 16px;
        top: 50%;
        -webkit-transform: translate(0, -50%);
                transform: translate(0, -50%);
        font-size: 16px;
        color: #ff7d00; }

.modal-max-range-filter .modal-content {
  width: 350px; }

.modal-max-range-filter .modal-body p {
  text-align: center; }

.modal-max-range-filter .modal-body button {
  width: 100%; }

.modal-message-history-filter {
  width: 484px !important; }
  .modal-message-history-filter .absolute-right-0 {
    font-weight: normal; }
  .modal-message-history-filter .modal-content {
    position: relative !important;
    padding: 32px;
    max-height: 85vh;
    border-radius: 8px;
    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none; }
    .modal-message-history-filter .modal-content::-webkit-scrollbar {
      display: none; }
    .modal-message-history-filter .modal-content .modal-body {
      padding: 0 !important; }
      .modal-message-history-filter .modal-content .modal-body .modal-title .icon-filter {
        display: inline-block;
        vertical-align: middle;
        width: 24px;
        height: 24px;
        margin-right: 4px; }
      .modal-message-history-filter .modal-content .modal-body .modal-title h3 {
        display: inline-block;
        vertical-align: middle;
        margin: 0;
        font-size: 20px;
        line-height: 24px;
        letter-spacing: -0.6px;
        color: #191919; }
      .modal-message-history-filter .modal-content .modal-body h5 {
        display: block;
        margin: 24px 0 8px;
        font-family: PTRootBold;
        font-size: 14px;
        line-height: 21px;
        letter-spacing: -0.4px; }
      .modal-message-history-filter .modal-content .modal-body .phone-input {
        width: 100%;
        padding: 12px 16px 12px 48px;
        background: white;
        border: 1px solid #1919191A;
        border-radius: 8px;
        font-family: PTRootUI;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: -0.3px;
        color: #191919; }
        .modal-message-history-filter .modal-content .modal-body .phone-input input {
          width: 100%;
          border: none; }
        .modal-message-history-filter .modal-content .modal-body .phone-input svg {
          position: absolute;
          left: 20px;
          font-size: 16px;
          -webkit-transform: translateY(5px);
                  transform: translateY(5px); }
      .modal-message-history-filter .modal-content .modal-body .filter-item-wrapper {
        display: inline-block;
        vertical-align: middle;
        padding: 10px;
        margin-left: 12px;
        background: white;
        border: 1px solid #1919191A;
        border-radius: 8px;
        cursor: pointer; }
        .modal-message-history-filter .modal-content .modal-body .filter-item-wrapper:nth-child(1) {
          margin-left: 0 !important; }
        .modal-message-history-filter .modal-content .modal-body .filter-item-wrapper .checkbox {
          display: inline-block;
          width: 24px;
          height: 24px;
          background: none;
          border: 2px solid #DCDCDC;
          border-radius: 4px;
          object-position: -99999px 99999px; }
        .modal-message-history-filter .modal-content .modal-body .filter-item-wrapper .icon-message-type {
          display: inline-block;
          width: 28px;
          height: 28px;
          padding: 6px;
          margin: 0 0 0 6px;
          border-radius: 28px; }
        .modal-message-history-filter .modal-content .modal-body .filter-item-wrapper .text-message-type {
          display: inline-block;
          margin: 0 0 0 6px;
          font-size: 14px;
          line-height: 21px;
          letter-spacing: -0.2px;
          color: #191919; }
        .modal-message-history-filter .modal-content .modal-body .filter-item-wrapper .icon-channel-type {
          display: inline-block;
          width: 16px;
          height: 16px;
          margin: 0 0 0 6px; }
        .modal-message-history-filter .modal-content .modal-body .filter-item-wrapper .text-channel-type {
          display: inline-block;
          margin: 0 0 0 4px;
          font-family: PTRootBold;
          font-size: 16px;
          font-weight: 700;
          line-height: 20px;
          letter-spacing: -0.5px;
          color: #19191966; }
      .modal-message-history-filter .modal-content .modal-body .filter-item-wrapper-active {
        display: inline-block;
        vertical-align: middle;
        padding: 10px;
        margin-left: 12px;
        background: #FFF2E5;
        border: 1px solid #FF7E00;
        border-radius: 8px;
        cursor: pointer; }
        .modal-message-history-filter .modal-content .modal-body .filter-item-wrapper-active:nth-child(1) {
          margin-left: 0 !important; }
        .modal-message-history-filter .modal-content .modal-body .filter-item-wrapper-active .checkbox {
          display: inline-block;
          width: 24px;
          height: 24px;
          background: #FF7E00;
          border: none;
          border-radius: 4px;
          object-position: 0px 0px; }
        .modal-message-history-filter .modal-content .modal-body .filter-item-wrapper-active .icon-message-type {
          display: inline-block;
          width: 28px;
          height: 28px;
          padding: 6px;
          margin: 0 0 0 6px;
          border-radius: 28px; }
        .modal-message-history-filter .modal-content .modal-body .filter-item-wrapper-active .text-message-type {
          display: inline-block;
          margin: 0 0 0 6px;
          font-size: 14px;
          line-height: 21px;
          letter-spacing: -0.2px;
          color: #191919; }
        .modal-message-history-filter .modal-content .modal-body .filter-item-wrapper-active .icon-channel-type {
          display: inline-block;
          width: 16px;
          height: 16px;
          margin: 0 0 0 6px; }
        .modal-message-history-filter .modal-content .modal-body .filter-item-wrapper-active .text-channel-type {
          display: inline-block;
          margin: 0 0 0 4px;
          font-family: PTRootBold;
          font-size: 16px;
          font-weight: 700;
          line-height: 20px;
          letter-spacing: -0.5px;
          color: #FF7E00; }
      .modal-message-history-filter .modal-content .modal-body .message-status-dropdown {
        width: 100%;
        padding: 12px 16px;
        background: white;
        border: 1px solid #1919191A;
        border-radius: 8px;
        cursor: pointer; }
        .modal-message-history-filter .modal-content .modal-body .message-status-dropdown .message-status-label {
          padding: 2px 8px;
          border-radius: 8px;
          font-size: 12px;
          line-height: 18px;
          letter-spacing: -0.4px;
          color: white; }
    .modal-message-history-filter .modal-content .modal-footer {
      border: none;
      padding: 0 !important;
      margin: 20px 0 0; }
      .modal-message-history-filter .modal-content .modal-footer button {
        display: inline-block;
        vertical-align: middle;
        padding: 8px 24px;
        margin: 0 4px 0 0;
        min-width: 134px;
        height: 40px;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: -0.5px; }
      .modal-message-history-filter .modal-content .modal-footer .button-clear {
        padding: 4px 16px;
        height: 26px;
        color: #ff7e00;
        border: none;
        background: none;
        font-size: 14px;
        letter-spacing: -0.5px; }
        .modal-message-history-filter .modal-content .modal-footer .button-clear svg {
          font-size: 12px;
          position: relative;
          top: -1px;
          margin-right: 4px; }
        .modal-message-history-filter .modal-content .modal-footer .button-clear:hover {
          color: #e87200; }

.label-dropdown {
  width: 100%; }
  .label-dropdown.disabled-label-dropdown button {
    background: rgba(25, 25, 25, 0.1) !important;
    color: rgba(25, 25, 25, 0.4) !important; }
  .label-dropdown .button-text-wrapper {
    display: inline-block;
    text-align: left;
    width: calc(100% - 32px); }
  .label-dropdown .label {
    display: inline-block;
    margin: 0;
    padding: 2px 8px;
    font-size: 12px;
    font-family: PTRootBold;
    line-height: 18px;
    letter-spacing: -0.4px;
    border-radius: 4px;
    color: white; }
  .label-dropdown button {
    width: 100%;
    text-align: left;
    background: #ffffff !important;
    border: solid 1px #dcdcdc !important;
    outline: none !important;
    box-shadow: none !important;
    color: #191919 !important;
    padding: 12px 16px;
    border-radius: 8px;
    position: relative; }
    .label-dropdown button .placeholder {
      display: inline-block;
      margin: 0;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: -0.3px;
      color: #19191966; }
    .label-dropdown button .icon-caret {
      font-size: 24px;
      margin-left: 8px;
      -webkit-transition: -webkit-transform 0.2s;
      transition: -webkit-transform 0.2s;
      transition: transform 0.2s;
      transition: transform 0.2s, -webkit-transform 0.2s; }
    .label-dropdown button .icon-caret-open {
      -webkit-transform: rotate(180deg);
              transform: rotate(180deg); }
  .label-dropdown .dropdown-menu {
    padding: 0;
    border-radius: 8px;
    box-shadow: 0px 2px 20px rgba(25, 25, 25, 0.18);
    border: none !important; }
    .label-dropdown .dropdown-menu button {
      border: none !important;
      border-bottom: solid 1px #1919191A !important;
      border-radius: 0;
      padding: 11px 16px;
      position: relative; }
      .label-dropdown .dropdown-menu button:nth-child(1) {
        border-top-left-radius: 8px;
        border-top-right-radius: 8px; }
      .label-dropdown .dropdown-menu button:nth-last-child(1) {
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
        border-bottom: 0 !important; }
      .label-dropdown .dropdown-menu button:hover {
        background: #fff2e5 !important; }
      .label-dropdown .dropdown-menu button svg {
        position: absolute;
        left: 16px;
        top: 50%;
        -webkit-transform: translate(0, -50%);
                transform: translate(0, -50%);
        font-size: 16px;
        color: #ff7e00; }

.number-dropdown {
  margin: 0 8px; }
  .number-dropdown.disabled-number-dropdown button {
    background: rgba(25, 25, 25, 0.1) !important;
    color: rgba(25, 25, 25, 0.4) !important; }
  .number-dropdown button {
    background: #ffffff !important;
    border: solid 1px #dcdcdc !important;
    outline: none !important;
    box-shadow: none !important;
    color: #191919 !important;
    padding: 2px 8px 2px 12px;
    border-radius: 4px;
    position: relative;
    font-size: 12px;
    font-family: PTRootBold;
    line-height: 18px;
    letter-spacing: -0.4px; }
    .number-dropdown button .icon-caret {
      font-size: 12px;
      margin-left: 4px;
      -webkit-transition: -webkit-transform 0.2s;
      transition: -webkit-transform 0.2s;
      transition: transform 0.2s;
      transition: transform 0.2s, -webkit-transform 0.2s; }
    .number-dropdown button .icon-caret-open {
      -webkit-transform: rotate(180deg);
              transform: rotate(180deg); }
  .number-dropdown .dropdown-menu {
    padding: 0;
    border-radius: 8px;
    box-shadow: 0px 2px 20px rgba(25, 25, 25, 0.18);
    border: none !important; }
    .number-dropdown .dropdown-menu button {
      border: none !important;
      border-radius: 0;
      padding: 7px 18px 7px 36px;
      position: relative;
      font-family: PTRootUI !important;
      font-size: 14px !important; }
      .number-dropdown .dropdown-menu button:nth-child(1) {
        border-top-left-radius: 8px;
        border-top-right-radius: 8px; }
      .number-dropdown .dropdown-menu button:nth-last-child(1) {
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
        border-bottom: 0 !important; }
      .number-dropdown .dropdown-menu button:hover {
        background: #fff2e5 !important; }
      .number-dropdown .dropdown-menu button svg {
        position: absolute;
        left: 16px;
        top: 50%;
        -webkit-transform: translate(0, -50%);
                transform: translate(0, -50%);
        font-size: 16px;
        color: #ff7e00; }
    .number-dropdown .dropdown-menu .active {
      color: #FF7E00 !important; }

.case-rating-wrapper .case-rating-title {
  font-size: 20px;
  letter-spacing: -0.6px;
  margin: 0 0 16px 0;
  color: #191919;
  padding: 8px 0 12px;
  border-bottom: solid 1px #dcdcdc; }

.case-rating-wrapper .case-rating-description {
  margin: 8px 0 16px;
  font-size: 16px;
  letter-spacing: -0.3px;
  width: calc(100% - 230px);
  color: #191919;
  line-height: 24px; }

.case-rating-wrapper .add-case-rating-button {
  text-align: center;
  position: absolute;
  top: 32px;
  right: 32px; }

.case-rating-wrapper .case-rating-data-outer-wrapper {
  position: relative; }

.case-rating-wrapper .case-rating-data-wrapper {
  width: 100%;
  color: #191919;
  overflow-x: auto;
  min-height: 200px; }
  .case-rating-wrapper .case-rating-data-wrapper .no-case-rating {
    width: 100%;
    text-align: center;
    font-size: 20px;
    letter-spacing: -0.6px;
    color: rgba(25, 25, 25, 0.6); }
  .case-rating-wrapper .case-rating-data-wrapper table {
    width: 100%;
    min-width: 1480px; }
    .case-rating-wrapper .case-rating-data-wrapper table thead {
      font-size: 14px;
      border-bottom: solid 1px #dcdcdc;
      color: #191919;
      letter-spacing: -0.4px; }
      .case-rating-wrapper .case-rating-data-wrapper table thead td {
        padding: 0 8px 8px;
        white-space: nowrap; }
        .case-rating-wrapper .case-rating-data-wrapper table thead td:last-child {
          width: 84px; }
    .case-rating-wrapper .case-rating-data-wrapper table tbody {
      font-size: 14px;
      letter-spacing: -0.2px;
      color: #191919; }
      .case-rating-wrapper .case-rating-data-wrapper table tbody tr {
        border-bottom: solid 1px #dcdcdc; }
      .case-rating-wrapper .case-rating-data-wrapper table tbody td {
        padding: 16px 8px; }
        .case-rating-wrapper .case-rating-data-wrapper table tbody td.col_action {
          font-size: 14px;
          letter-spacing: -0.4px; }
          .case-rating-wrapper .case-rating-data-wrapper table tbody td.col_action .edit-button {
            color: #ff7d00;
            cursor: pointer;
            margin-right: 16px; }
          .case-rating-wrapper .case-rating-data-wrapper table tbody td.col_action .remove-button {
            color: #ff3f57;
            cursor: pointer; }
          .case-rating-wrapper .case-rating-data-wrapper table tbody td.col_action svg {
            font-size: 16px;
            position: relative;
            top: -2px;
            margin-right: 4px; }
        .case-rating-wrapper .case-rating-data-wrapper table tbody td.col_1 {
          min-width: 120px; }
        .case-rating-wrapper .case-rating-data-wrapper table tbody td.col_2, .case-rating-wrapper .case-rating-data-wrapper table tbody td.col_4, .case-rating-wrapper .case-rating-data-wrapper table tbody td.col_5, .case-rating-wrapper .case-rating-data-wrapper table tbody td.col_6 {
          min-width: 220px; }

.ascending-descending-table-wrapper {
  position: relative;
  display: inline-block;
  margin-left: 4px;
  height: 20px;
  width: 10px;
  vertical-align: top;
  top: 0px; }
  .ascending-descending-table-wrapper svg {
    cursor: pointer;
    color: #191919;
    font-size: 12px;
    border-radius: 2px; }
    .ascending-descending-table-wrapper svg.up-button {
      position: absolute;
      top: 0;
      left: 0; }
    .ascending-descending-table-wrapper svg.down-button {
      position: absolute;
      top: 10px;
      left: 0; }
    .ascending-descending-table-wrapper svg.active-sort {
      background: #FDF1F1;
      color: #ff7d00; }

.sort-table-header {
  cursor: pointer;
  position: relative;
  padding: 8px 20px 8px 8px !important; }
  .sort-table-header .ascending-descending-table-wrapper {
    position: relative;
    margin-left: 8px; }
  .sort-table-header .sort-table-tooltip-wrapper {
    position: relative;
    display: inline-block;
    margin-left: 4px;
    height: 30px;
    width: 10px;
    vertical-align: top;
    top: -4px;
    right: -4px; }
    .sort-table-header .sort-table-tooltip-wrapper svg {
      color: #191919;
      outline: none !important;
      font-size: 12px; }


.case-report-wrapper .case-report-title {
  font-size: 20px;
  letter-spacing: -0.6px;
  margin: 0 0 16px 0;
  border-bottom: solid 1px #dcdcdc;
  color: #191919;
  padding: 8px 0 12px; }

.case-report-wrapper .case-report-description {
  margin: 8px 0 16px;
  font-size: 16px;
  letter-spacing: -0.3px;
  width: calc(100% - 230px);
  color: #191919;
  line-height: 24px; }

.case-report-wrapper .add-case-report-button {
  text-align: center;
  position: absolute;
  top: 32px;
  right: 32px; }

.case-report-wrapper .case-report-data-outer-wrapper {
  position: relative; }

.case-report-wrapper .case-report-data-wrapper {
  width: 100%;
  color: #191919;
  overflow-x: auto;
  min-height: 200px; }
  .case-report-wrapper .case-report-data-wrapper .no-case-report {
    width: 100%;
    text-align: center;
    font-size: 20px;
    letter-spacing: -0.6px;
    color: rgba(25, 25, 25, 0.6); }
  .case-report-wrapper .case-report-data-wrapper table {
    width: 100%;
    min-width: 1480px; }
    .case-report-wrapper .case-report-data-wrapper table thead {
      font-size: 14px;
      border-bottom: solid 1px #dcdcdc;
      color: #191919;
      letter-spacing: -0.4px; }
      .case-report-wrapper .case-report-data-wrapper table thead td {
        padding: 0 8px 8px;
        white-space: nowrap; }
        .case-report-wrapper .case-report-data-wrapper table thead td:last-child {
          width: 84px; }
      .case-report-wrapper .case-report-data-wrapper table thead .col-no-sort {
        padding: 0 8px; }
    .case-report-wrapper .case-report-data-wrapper table tbody {
      font-size: 14px;
      letter-spacing: -0.2px;
      color: #191919; }
      .case-report-wrapper .case-report-data-wrapper table tbody tr {
        border-bottom: solid 1px #dcdcdc; }
      .case-report-wrapper .case-report-data-wrapper table tbody td {
        padding: 16px 8px; }
        .case-report-wrapper .case-report-data-wrapper table tbody td.col_1 {
          white-space: nowrap; }
          .case-report-wrapper .case-report-data-wrapper table tbody td.col_1 img {
            margin-right: 4px;
            width: 24px;
            position: relative;
            top: -1px; }
        .case-report-wrapper .case-report-data-wrapper table tbody td.col_2 {
          min-width: 120px; }
        .case-report-wrapper .case-report-data-wrapper table tbody td.col_4, .case-report-wrapper .case-report-data-wrapper table tbody td.col_9 {
          min-width: 220px; }
        .case-report-wrapper .case-report-data-wrapper table tbody td.col_5 {
          min-width: 190px; }
        .case-report-wrapper .case-report-data-wrapper table tbody td.col_action {
          font-size: 14px;
          letter-spacing: -0.4px; }
          .case-report-wrapper .case-report-data-wrapper table tbody td.col_action .edit-button {
            color: #ff7d00;
            cursor: pointer;
            margin-right: 16px; }
          .case-report-wrapper .case-report-data-wrapper table tbody td.col_action .remove-button {
            color: #ff3f57;
            cursor: pointer; }
          .case-report-wrapper .case-report-data-wrapper table tbody td.col_action svg {
            font-size: 16px;
            position: relative;
            top: -2px;
            margin-right: 4px; }
        .case-report-wrapper .case-report-data-wrapper table tbody td .case-report-label {
          border-radius: 4px;
          padding: 3px 12px;
          white-space: nowrap;
          color: #ffffff;
          background: #9c88f1;
          font-size: 12px;
          letter-spacing: -0.4px;
          display: inline-block;
          margin: 0 4px 4px 0;
          max-width: 200px;
          overflow: hidden;
          text-overflow: ellipsis; }
        .case-report-wrapper .case-report-data-wrapper table tbody td .case-report-status {
          border-radius: 4px;
          padding: 3px 12px;
          white-space: nowrap;
          max-width: 100px;
          overflow: hidden;
          text-overflow: ellipsis;
          color: #ffffff;
          background: #9c88f1;
          font-size: 12px;
          letter-spacing: -0.4px;
          display: inline-block; }
          .case-report-wrapper .case-report-data-wrapper table tbody td .case-report-status.ongoing-case {
            color: #ffffff;
            background: #19C700; }
          .case-report-wrapper .case-report-data-wrapper table tbody td .case-report-status.open-case {
            color: #ffffff;
            background: #C3C8D0; }
          .case-report-wrapper .case-report-data-wrapper table tbody td .case-report-status.resolved-case {
            color: rgba(25, 25, 25, 0.6);
            background: rgba(25, 25, 25, 0.05); }
          .case-report-wrapper .case-report-data-wrapper table tbody td .case-report-status svg {
            position: relative;
            margin-right: 4px;
            top: -1px; }

.agent-report-wrapper .agent-report-title {
  font-size: 20px;
  letter-spacing: -0.6px;
  margin: 0 0 16px 0;
  border-bottom: solid 1px #dcdcdc;
  color: #191919;
  padding: 8px 0 12px; }

.agent-report-wrapper .agent-report-description {
  margin: 8px 0 16px;
  font-size: 16px;
  letter-spacing: -0.3px;
  width: calc(100% - 230px);
  color: #191919;
  line-height: 24px; }

.agent-report-wrapper .add-agent-report-button {
  text-align: center;
  position: absolute;
  top: 32px;
  right: 32px; }

.agent-report-wrapper .agent-report-data-wrapper {
  width: 100%;
  color: #191919;
  overflow-x: auto; }
  .agent-report-wrapper .agent-report-data-wrapper .no-agent-report {
    width: 100%;
    text-align: center;
    font-size: 20px;
    letter-spacing: -0.6px;
    color: rgba(25, 25, 25, 0.6); }
  .agent-report-wrapper .agent-report-data-wrapper table {
    width: 100%;
    min-width: 1480px; }
    .agent-report-wrapper .agent-report-data-wrapper table thead {
      font-size: 14px;
      border-bottom: solid 1px #dcdcdc;
      color: #191919;
      letter-spacing: -0.4px; }
      .agent-report-wrapper .agent-report-data-wrapper table thead td {
        padding: 0 8px 8px;
        white-space: nowrap; }
    .agent-report-wrapper .agent-report-data-wrapper table tbody {
      font-size: 14px;
      letter-spacing: -0.2px;
      color: #191919; }
      .agent-report-wrapper .agent-report-data-wrapper table tbody tr {
        border-bottom: solid 1px #dcdcdc; }
      .agent-report-wrapper .agent-report-data-wrapper table tbody td {
        padding: 16px 8px; }
        .agent-report-wrapper .agent-report-data-wrapper table tbody td.col_action {
          font-size: 14px;
          letter-spacing: -0.4px; }
          .agent-report-wrapper .agent-report-data-wrapper table tbody td.col_action .edit-button {
            color: #ff7d00;
            cursor: pointer;
            margin-right: 16px; }
          .agent-report-wrapper .agent-report-data-wrapper table tbody td.col_action .remove-button {
            color: #ff3f57;
            cursor: pointer; }
          .agent-report-wrapper .agent-report-data-wrapper table tbody td.col_action svg {
            font-size: 16px;
            position: relative;
            top: -2px;
            margin-right: 4px; }
    .agent-report-wrapper .agent-report-data-wrapper table td.with-max-width {
      white-space: break-spaces;
      max-width: 130px; }

.agent-report-wrapper .agent-report-title {
  font-size: 20px;
  letter-spacing: -0.6px;
  margin: 0 0 16px 0;
  border-bottom: solid 1px #dcdcdc;
  color: #191919;
  padding: 8px 0 12px; }

.agent-report-wrapper .agent-report-description {
  margin: 8px 0 16px;
  font-size: 16px;
  letter-spacing: -0.3px;
  width: calc(100% - 230px);
  color: #191919;
  line-height: 24px; }

.agent-report-wrapper .add-agent-report-button {
  text-align: center;
  position: absolute;
  top: 32px;
  right: 32px; }

.agent-report-wrapper .agent-report-data-outer-wrapper {
  position: relative; }

.agent-report-wrapper .agent-report-data-wrapper {
  width: 100%;
  color: #191919;
  overflow-x: auto;
  min-height: 200px; }
  .agent-report-wrapper .agent-report-data-wrapper .no-agent-report {
    width: 100%;
    text-align: center;
    font-size: 20px;
    letter-spacing: -0.6px;
    color: rgba(25, 25, 25, 0.6); }
  .agent-report-wrapper .agent-report-data-wrapper table {
    width: 100%;
    min-width: 1480px; }
    .agent-report-wrapper .agent-report-data-wrapper table thead {
      font-size: 14px;
      border-bottom: solid 1px #dcdcdc;
      color: #191919;
      letter-spacing: -0.4px; }
      .agent-report-wrapper .agent-report-data-wrapper table thead td {
        padding: 0 8px 8px;
        white-space: nowrap;
        vertical-align: top; }
        .agent-report-wrapper .agent-report-data-wrapper table thead td .number-dropdown {
          top: 6px; }
        .agent-report-wrapper .agent-report-data-wrapper table thead td:last-child {
          width: 84px; }
    .agent-report-wrapper .agent-report-data-wrapper table tbody {
      font-size: 14px;
      letter-spacing: -0.2px;
      color: #191919; }
      .agent-report-wrapper .agent-report-data-wrapper table tbody tr {
        border-bottom: solid 1px #dcdcdc; }
      .agent-report-wrapper .agent-report-data-wrapper table tbody td {
        padding: 16px 8px; }
        .agent-report-wrapper .agent-report-data-wrapper table tbody td.col_action {
          font-size: 14px;
          letter-spacing: -0.4px; }
          .agent-report-wrapper .agent-report-data-wrapper table tbody td.col_action .edit-button {
            color: #ff7d00;
            cursor: pointer;
            margin-right: 16px; }
          .agent-report-wrapper .agent-report-data-wrapper table tbody td.col_action .remove-button {
            color: #ff3f57;
            cursor: pointer; }
          .agent-report-wrapper .agent-report-data-wrapper table tbody td.col_action svg {
            font-size: 16px;
            position: relative;
            top: -2px;
            margin-right: 4px; }
        .agent-report-wrapper .agent-report-data-wrapper table tbody td:nth-child(1), .agent-report-wrapper .agent-report-data-wrapper table tbody td:nth-child(2) {
          min-width: 220px; }
    .agent-report-wrapper .agent-report-data-wrapper table td.with-max-width {
      white-space: break-spaces;
      max-width: 130px; }

.agent-report-wrapper .agent-report-title {
  font-size: 20px;
  letter-spacing: -0.6px;
  margin: 0 0 16px 0;
  border-bottom: solid 1px #dcdcdc;
  color: #191919;
  padding: 8px 0 12px; }

.agent-report-wrapper .agent-report-description {
  margin: 8px 0 16px;
  font-size: 16px;
  letter-spacing: -0.3px;
  width: calc(100% - 230px);
  color: #191919;
  line-height: 24px; }

.agent-report-wrapper .add-agent-report-button {
  text-align: center;
  position: absolute;
  top: 32px;
  right: 32px; }

.agent-report-wrapper .agent-report-data-wrapper {
  width: 100%;
  color: #191919;
  overflow-x: auto; }
  .agent-report-wrapper .agent-report-data-wrapper .no-agent-report {
    width: 100%;
    text-align: center;
    font-size: 20px;
    letter-spacing: -0.6px;
    color: rgba(25, 25, 25, 0.6); }
  .agent-report-wrapper .agent-report-data-wrapper table {
    width: 100%;
    min-width: 1480px; }
    .agent-report-wrapper .agent-report-data-wrapper table thead {
      font-size: 14px;
      border-bottom: solid 1px #dcdcdc;
      color: #191919;
      letter-spacing: -0.4px; }
      .agent-report-wrapper .agent-report-data-wrapper table thead td {
        padding: 0 8px 8px;
        white-space: nowrap; }
    .agent-report-wrapper .agent-report-data-wrapper table tbody {
      font-size: 14px;
      letter-spacing: -0.2px;
      color: #191919; }
      .agent-report-wrapper .agent-report-data-wrapper table tbody tr {
        border-bottom: solid 1px #dcdcdc; }
      .agent-report-wrapper .agent-report-data-wrapper table tbody td {
        padding: 16px 8px; }
        .agent-report-wrapper .agent-report-data-wrapper table tbody td.col_action {
          font-size: 14px;
          letter-spacing: -0.4px; }
          .agent-report-wrapper .agent-report-data-wrapper table tbody td.col_action .edit-button {
            color: #ff7d00;
            cursor: pointer;
            margin-right: 16px; }
          .agent-report-wrapper .agent-report-data-wrapper table tbody td.col_action .remove-button {
            color: #ff3f57;
            cursor: pointer; }
          .agent-report-wrapper .agent-report-data-wrapper table tbody td.col_action svg {
            font-size: 16px;
            position: relative;
            top: -2px;
            margin-right: 4px; }
    .agent-report-wrapper .agent-report-data-wrapper table td.with-max-width {
      white-space: break-spaces;
      max-width: 130px; }

.clock-in-report-wrapper .table-clock-in-report .col_fullname {
  width: 220px; }

.clock-in-report-wrapper .table-clock-in-report .col_email {
  width: 220px; }

.clock-in-report-wrapper .table-clock-in-report .col_3, .clock-in-report-wrapper .table-clock-in-report .col_4, .clock-in-report-wrapper .table-clock-in-report .col_5, .clock-in-report-wrapper .table-clock-in-report .col_6 {
  width: 180px; }

.clock-in-report-wrapper .clock-in-report-title {
  font-size: 20px;
  letter-spacing: -0.6px;
  margin: 0 0 16px 0;
  border-bottom: solid 1px #dcdcdc;
  color: #191919;
  padding: 8px 0 12px; }

.clock-in-report-wrapper .clock-in-report-description {
  margin: 8px 0 16px;
  font-size: 16px;
  letter-spacing: -0.3px;
  width: calc(100% - 230px);
  color: #191919;
  line-height: 24px; }

.clock-in-report-wrapper .add-clock-in-report-button {
  text-align: center;
  position: absolute;
  top: 32px;
  right: 32px; }

.clock-in-report-wrapper .clock-in-report-data-wrapper {
  width: 100%;
  color: #191919;
  overflow-x: auto; }
  .clock-in-report-wrapper .clock-in-report-data-wrapper .no-clock-in-report {
    width: 100%;
    text-align: center;
    font-size: 20px;
    letter-spacing: -0.6px;
    color: rgba(25, 25, 25, 0.6); }
  .clock-in-report-wrapper .clock-in-report-data-wrapper table {
    width: 100%;
    min-width: 1480px; }
    .clock-in-report-wrapper .clock-in-report-data-wrapper table thead {
      font-size: 14px;
      border-bottom: solid 1px #dcdcdc;
      color: #191919;
      letter-spacing: -0.4px; }
      .clock-in-report-wrapper .clock-in-report-data-wrapper table thead td {
        padding: 0 8px 8px;
        white-space: nowrap; }
    .clock-in-report-wrapper .clock-in-report-data-wrapper table tbody {
      font-size: 14px;
      letter-spacing: -0.2px;
      color: #191919; }
      .clock-in-report-wrapper .clock-in-report-data-wrapper table tbody tr {
        border-bottom: solid 1px #dcdcdc; }
      .clock-in-report-wrapper .clock-in-report-data-wrapper table tbody td {
        padding: 16px 8px; }
        .clock-in-report-wrapper .clock-in-report-data-wrapper table tbody td.col_action {
          font-size: 14px;
          letter-spacing: -0.4px; }
          .clock-in-report-wrapper .clock-in-report-data-wrapper table tbody td.col_action .edit-button {
            color: #ff7d00;
            cursor: pointer;
            margin-right: 16px; }
          .clock-in-report-wrapper .clock-in-report-data-wrapper table tbody td.col_action .remove-button {
            color: #ff3f57;
            cursor: pointer; }
          .clock-in-report-wrapper .clock-in-report-data-wrapper table tbody td.col_action svg {
            font-size: 16px;
            position: relative;
            top: -2px;
            margin-right: 4px; }
    .clock-in-report-wrapper .clock-in-report-data-wrapper table td.with-max-width {
      white-space: break-spaces;
      max-width: 130px; }

.topic-report-wrapper .topic-report-title {
  font-size: 20px;
  letter-spacing: -0.6px;
  margin: 0 0 16px 0;
  color: #191919;
  padding: 8px 0 12px;
  border-bottom: solid 1px #dcdcdc; }

.topic-report-wrapper .topic-report-description {
  margin: 8px 0 16px;
  font-size: 16px;
  letter-spacing: -0.3px;
  width: calc(100% - 230px);
  color: #191919;
  line-height: 24px; }

.topic-report-wrapper .topic-report-data-outer-wrapper {
  padding: 12px 0 0; }

.topic-report-wrapper .topic-report-data-wrapper {
  width: 100%;
  color: #191919;
  overflow-x: auto; }
  .topic-report-wrapper .topic-report-data-wrapper .no-topic-report {
    width: 100%;
    text-align: center;
    font-size: 20px;
    letter-spacing: -0.6px;
    color: rgba(25, 25, 25, 0.6); }
  .topic-report-wrapper .topic-report-data-wrapper table {
    width: 100%;
    min-width: 1480px; }
    .topic-report-wrapper .topic-report-data-wrapper table thead {
      font-size: 14px;
      border-bottom: solid 1px #dcdcdc;
      color: #191919;
      letter-spacing: -0.4px; }
      .topic-report-wrapper .topic-report-data-wrapper table thead td {
        padding: 0 8px 8px;
        white-space: nowrap; }
    .topic-report-wrapper .topic-report-data-wrapper table tbody {
      font-size: 14px;
      letter-spacing: -0.2px;
      color: #191919; }
      .topic-report-wrapper .topic-report-data-wrapper table tbody tr {
        border-bottom: solid 1px #dcdcdc; }
      .topic-report-wrapper .topic-report-data-wrapper table tbody td {
        padding: 16px 8px;
        max-width: 130px; }
        .topic-report-wrapper .topic-report-data-wrapper table tbody td.col_action {
          font-size: 14px;
          letter-spacing: -0.4px; }
          .topic-report-wrapper .topic-report-data-wrapper table tbody td.col_action .edit-button {
            color: #ff7d00;
            cursor: pointer;
            margin-right: 16px; }
          .topic-report-wrapper .topic-report-data-wrapper table tbody td.col_action .remove-button {
            color: #ff3f57;
            cursor: pointer; }
          .topic-report-wrapper .topic-report-data-wrapper table tbody td.col_action svg {
            font-size: 16px;
            position: relative;
            top: -2px;
            margin-right: 4px; }
    .topic-report-wrapper .topic-report-data-wrapper table td.with-max-width {
      white-space: break-spaces;
      max-width: 130px; }

.topic-report-show-wrapper .topic-report-show-title {
  font-size: 20px;
  letter-spacing: -0.6px;
  margin: 0 0 16px 0;
  color: #191919;
  padding: 8px 0 12px;
  border-bottom: solid 1px #dcdcdc; }

.topic-report-show-wrapper .topic-report-show-description {
  margin: 8px 0 16px;
  font-size: 16px;
  letter-spacing: -0.3px;
  width: calc(100% - 230px);
  color: #191919;
  line-height: 24px; }

.topic-report-show-wrapper .add-topic-report-show-button {
  text-align: center;
  position: absolute;
  top: 32px;
  right: 32px; }

.topic-report-show-wrapper .topic-report-show-data-outer-wrapper {
  position: relative; }

.topic-report-show-wrapper .topic-report-show-data-wrapper {
  width: 100%;
  color: #191919;
  overflow-x: auto;
  min-height: 200px; }
  .topic-report-show-wrapper .topic-report-show-data-wrapper .no-fill-black-button {
    border: solid 1px rgba(25, 25, 25, 0.1) !important; }
  .topic-report-show-wrapper .topic-report-show-data-wrapper .no-topic-report {
    width: 100%;
    text-align: center;
    font-size: 20px;
    letter-spacing: -0.6px;
    color: rgba(25, 25, 25, 0.6); }
  .topic-report-show-wrapper .topic-report-show-data-wrapper table {
    margin-top: 8px;
    width: 100%; }
    .topic-report-show-wrapper .topic-report-show-data-wrapper table thead {
      font-size: 14px;
      border-bottom: solid 1px #dcdcdc;
      color: #191919;
      letter-spacing: -0.4px; }
      .topic-report-show-wrapper .topic-report-show-data-wrapper table thead td {
        padding: 8px !important;
        white-space: nowrap; }
        .topic-report-show-wrapper .topic-report-show-data-wrapper table thead td:last-child {
          width: 84px; }
    .topic-report-show-wrapper .topic-report-show-data-wrapper table tbody {
      font-size: 14px;
      letter-spacing: -0.2px;
      color: #191919; }
      .topic-report-show-wrapper .topic-report-show-data-wrapper table tbody .lds-ring div {
        margin: 0; }
      .topic-report-show-wrapper .topic-report-show-data-wrapper table tbody tr {
        border-bottom: solid 1px #dcdcdc; }
      .topic-report-show-wrapper .topic-report-show-data-wrapper table tbody td {
        padding: 16px 8px;
        max-width: 130px; }
        .topic-report-show-wrapper .topic-report-show-data-wrapper table tbody td.col_action {
          font-size: 14px;
          letter-spacing: -0.4px; }
          .topic-report-show-wrapper .topic-report-show-data-wrapper table tbody td.col_action .edit-button {
            color: #ff7d00;
            cursor: pointer;
            margin-right: 16px; }
          .topic-report-show-wrapper .topic-report-show-data-wrapper table tbody td.col_action .remove-button {
            color: #ff3f57;
            cursor: pointer; }
          .topic-report-show-wrapper .topic-report-show-data-wrapper table tbody td.col_action svg {
            font-size: 16px;
            position: relative;
            top: -2px;
            margin-right: 4px; }
    .topic-report-show-wrapper .topic-report-show-data-wrapper table td.with-max-width {
      white-space: break-spaces;
      max-width: 130px; }

.modal-custom-filter-topic-report .modal-content {
  width: 750px; }

.modal-custom-filter-topic-report .modal-body {
  padding: 24px; }
  .modal-custom-filter-topic-report .modal-body .modal-custom-filter-topic-report-title {
    font-size: 24px;
    letter-spacing: -0.6px;
    color: #191919; }
    .modal-custom-filter-topic-report .modal-body .modal-custom-filter-topic-report-title svg {
      font-size: 26px;
      position: relative;
      top: -1px;
      margin-right: 10px; }

.message-history-report-wrapper {
  min-width: 600px; }
  .message-history-report-wrapper .message-history-report-title {
    font-size: 20px;
    letter-spacing: -0.6px;
    margin: 0 0 16px 0;
    border-bottom: solid 1px #dcdcdc;
    color: #191919;
    padding: 8px 0 12px; }

.info-box-hover .tooltip-inner {
  color: #fff !important;
  background: #6B6B6B !important;
  font-size: 12px;
  padding: 8px;
  width: 153px; }

.info-box-hover .arrow::before {
  border-bottom-color: #6b6b6b !important;
  border-top-color: #6b6b6b !important; }

.message-history-report-wrapper {
  min-width: 600px; }
  .message-history-report-wrapper .message-history-report-title {
    font-size: 20px;
    letter-spacing: -0.6px;
    margin: 0 0 16px 0;
    border-bottom: solid 1px #dcdcdc;
    color: #191919;
    padding: 8px 0 12px; }
  .message-history-report-wrapper .download-report-button {
    text-align: center;
    position: absolute;
    top: 32px;
    right: 32px; }
  .message-history-report-wrapper .message-history-report-data-outer-wrapper {
    position: relative; }
    .message-history-report-wrapper .message-history-report-data-outer-wrapper .advanced-date-selection {
      display: inline-block; }
  .message-history-report-wrapper .message-history-report-data-wrapper {
    width: 100%;
    color: #191919;
    overflow-x: auto;
    min-height: 200px; }
    .message-history-report-wrapper .message-history-report-data-wrapper .no-message-history-report {
      width: 100%;
      text-align: center;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: -0.5px;
      color: rgba(25, 25, 25, 0.6); }
      .message-history-report-wrapper .message-history-report-data-wrapper .no-message-history-report p {
        margin: 0; }
    .message-history-report-wrapper .message-history-report-data-wrapper table {
      min-width: 100%;
      width: 1020px; }
      .message-history-report-wrapper .message-history-report-data-wrapper table thead {
        font-size: 14px;
        border-bottom: solid 1px #dcdcdc;
        color: #191919;
        letter-spacing: -0.4px; }
        .message-history-report-wrapper .message-history-report-data-wrapper table thead td {
          padding: 0 8px 8px;
          white-space: nowrap; }
          .message-history-report-wrapper .message-history-report-data-wrapper table thead td:last-child {
            width: 0%;
            min-width: 84px; }
      .message-history-report-wrapper .message-history-report-data-wrapper table tbody {
        font-size: 14px;
        letter-spacing: -0.2px;
        color: #191919; }
        .message-history-report-wrapper .message-history-report-data-wrapper table tbody td {
          padding: 16px 8px;
          vertical-align: top; }
      .message-history-report-wrapper .message-history-report-data-wrapper table td {
        word-wrap: break-word; }
        .message-history-report-wrapper .message-history-report-data-wrapper table td.with-max-width {
          white-space: break-spaces;
          max-width: 130px; }
      .message-history-report-wrapper .message-history-report-data-wrapper table img {
        width: 16px;
        height: 16px;
        margin-right: 4px;
        -webkit-transform: translateY(-2px);
                transform: translateY(-2px); }
      .message-history-report-wrapper .message-history-report-data-wrapper table .col_time, .message-history-report-wrapper .message-history-report-data-wrapper table .col_phone {
        width: 11%;
        min-width: 140px; }
      .message-history-report-wrapper .message-history-report-data-wrapper table .col_name, .message-history-report-wrapper .message-history-report-data-wrapper table .col_alias {
        width: 18%;
        min-width: 220px; }
      .message-history-report-wrapper .message-history-report-data-wrapper table .col_channel {
        width: 14.5%;
        min-width: 180px; }
      .message-history-report-wrapper .message-history-report-data-wrapper table .col_message {
        width: 7.5%;
        min-width: 95px;
        cursor: pointer; }
        .message-history-report-wrapper .message-history-report-data-wrapper table .col_message p {
          display: inline-block;
          margin: 0;
          font-family: PTRootBold;
          color: #FF7E00;
          letter-spacing: -0.4px;
          line-height: 21px; }
      .message-history-report-wrapper .message-history-report-data-wrapper table .col_type {
        width: 7.5%;
        min-width: 95px;
        text-transform: capitalize; }
      .message-history-report-wrapper .message-history-report-data-wrapper table .col_status {
        width: 10.5%;
        min-width: 130px; }
        .message-history-report-wrapper .message-history-report-data-wrapper table .col_status .label {
          display: inline-block;
          margin: 0;
          padding: 2px 8px;
          font-size: 12px;
          font-family: PTRootBold;
          line-height: 18px;
          letter-spacing: -0.4px;
          border-radius: 4px;
          color: white;
          position: relative; }
          .message-history-report-wrapper .message-history-report-data-wrapper table .col_status .label .info-box-hover {
            padding: 8px;
            font-family: PTRootUI !important;
            font-size: 12px;
            color: #fff;
            letter-spacing: -0.2px;
            background: #6b6b6b;
            border-radius: 4px;
            position: absolute;
            width: 142px;
            right: -8px;
            top: 30px;
            display: none; }
            .message-history-report-wrapper .message-history-report-data-wrapper table .col_status .label .info-box-hover::before {
              content: "";
              width: 10px;
              height: 10px;
              background: #6b6b6b;
              position: absolute;
              right: 16px;
              top: -5px;
              -webkit-transform: rotate(45deg);
                      transform: rotate(45deg); }
          .message-history-report-wrapper .message-history-report-data-wrapper table .col_status .label svg {
            color: #fff;
            margin-left: 4px;
            position: relative;
            top: -1px; }
            .message-history-report-wrapper .message-history-report-data-wrapper table .col_status .label svg:focus {
              outline: none !important; }
        .message-history-report-wrapper .message-history-report-data-wrapper table .col_status .pending {
          background: #FF7E00; }
        .message-history-report-wrapper .message-history-report-data-wrapper table .col_status .failed {
          background: #FF3F57; }
        .message-history-report-wrapper .message-history-report-data-wrapper table .col_status .sent {
          background: #19C700; }
        .message-history-report-wrapper .message-history-report-data-wrapper table .col_status .acknowledged {
          background: #458500; }
        .message-history-report-wrapper .message-history-report-data-wrapper table .col_status .delivered {
          background: #025F2D; }
        .message-history-report-wrapper .message-history-report-data-wrapper table .col_status .read {
          background: #3BAEFF; }

.modal-message-details {
  width: 484px !important; }
  .modal-message-details .modal-content {
    position: relative !important;
    padding: 32px;
    max-height: 85vh;
    border-radius: 8px;
    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none; }
    .modal-message-details .modal-content::-webkit-scrollbar {
      display: none; }
    .modal-message-details .modal-content .modal-body {
      padding: 0 !important; }
      .modal-message-details .modal-content .modal-body .modal-title .icon-view {
        display: inline-block;
        vertical-align: middle;
        width: 24px;
        height: 24px;
        margin-right: 4px; }
      .modal-message-details .modal-content .modal-body .modal-title h3 {
        display: inline-block;
        vertical-align: middle;
        margin: 0;
        font-size: 20px;
        line-height: 24px;
        letter-spacing: -0.6px;
        color: #191919; }
      .modal-message-details .modal-content .modal-body h5 {
        display: block;
        margin: 24px 0 8px;
        font-family: PTRootBold;
        font-size: 14px;
        line-height: 21px;
        letter-spacing: -0.4px; }
      .modal-message-details .modal-content .modal-body .text-wrapper {
        padding: 12px 16px;
        background: #1919190D;
        border: 1px solid #1919191A;
        border-radius: 8px;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: -0.3px;
        color: #19191999;
        word-wrap: break-word; }
      .modal-message-details .modal-content .modal-body .copy-button {
        padding: 12px 48px 12px 16px !important; }
        .modal-message-details .modal-content .modal-body .copy-button svg {
          position: absolute;
          right: 16px;
          font-size: 24px;
          color: #FF7E00;
          cursor: pointer; }
      .modal-message-details .modal-content .modal-body .body {
        min-height: 144px; }
      .modal-message-details .modal-content .modal-body .thumbnail {
        display: inline-block;
        width: 56px;
        height: 56px;
        margin-top: 8px;
        border-radius: 8px; }
      .modal-message-details .modal-content .modal-body .view-image {
        opacity: 0;
        display: inline-block;
        position: absolute;
        padding: 16px;
        left: 0;
        color: white;
        background: #19191966;
        cursor: pointer; }
        .modal-message-details .modal-content .modal-body .view-image:hover {
          opacity: 1;
          -webkit-transition: ease-in-out 0.2s;
          transition: ease-in-out 0.2s; }
        .modal-message-details .modal-content .modal-body .view-image svg {
          width: 24px;
          height: 24px;
          -webkit-transform: translateY(-2px);
                  transform: translateY(-2px); }
      .modal-message-details .modal-content .modal-body .document-wrapper {
        display: inline-block;
        max-width: 100%;
        padding: 12px 24px;
        border: 1px solid #19191933;
        border-radius: 8px;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: -0.3px;
        color: #191919;
        word-wrap: break-word;
        cursor: pointer; }
        .modal-message-details .modal-content .modal-body .document-wrapper:hover {
          border: 1px solid #FF7E00;
          -webkit-transition: ease-in-out 0.2s;
          transition: ease-in-out 0.2s; }
        .modal-message-details .modal-content .modal-body .document-wrapper svg {
          width: 20px;
          height: 20px;
          margin-right: 4px;
          color: #FF7E00;
          -webkit-transform: translateY(-2px);
                  transform: translateY(-2px); }
    .modal-message-details .modal-content .modal-footer {
      border: none;
      padding: 0 !important;
      margin: 20px 0 0; }
      .modal-message-details .modal-content .modal-footer button {
        display: block;
        width: 100%;
        height: 48px;
        padding: 12px 24px;
        margin: 0;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: -0.5px; }

.overview-report-wrapper {
  min-height: 500px; }
  .overview-report-wrapper .overview-report-title {
    font-size: 20px;
    letter-spacing: -0.6px;
    margin: 0 0 16px 0;
    border-bottom: solid 1px #dcdcdc;
    color: #191919;
    padding: 8px 0 24px 0;
    position: relative; }
    .overview-report-wrapper .overview-report-title .advanced-date-selection {
      position: absolute;
      right: 0;
      top: 0; }
  .overview-report-wrapper .overview-report-description {
    margin: 8px 0 16px;
    font-size: 16px;
    letter-spacing: -0.3px;
    width: calc(100% - 230px);
    color: #191919;
    line-height: 24px; }
  .overview-report-wrapper .add-overview-report-button {
    text-align: center;
    position: absolute;
    top: 32px;
    right: 32px; }
  .overview-report-wrapper .overview-report-data-outer-wrapper {
    padding: 32px 0 0; }
  .overview-report-wrapper .overview-report-data-wrapper {
    width: 100%;
    color: #191919; }
    .overview-report-wrapper .overview-report-data-wrapper .no-overview-report {
      width: 100%;
      text-align: center;
      font-size: 20px;
      letter-spacing: -0.6px;
      color: rgba(25, 25, 25, 0.6); }
    .overview-report-wrapper .overview-report-data-wrapper .overview-box-wrapper .overview-box-title {
      font-size: 20px;
      letter-spacing: -.6px;
      color: #191919;
      padding: 0 0 12px;
      border-bottom: solid 1px #dcdcdc;
      margin: 0 0 24px; }
    .overview-report-wrapper .overview-report-data-wrapper .overview-box-wrapper .overview-box-title-2 {
      margin: 40px 0 0 0;
      padding: 0 0 0 16px;
      border: none !important; }
    .overview-report-wrapper .overview-report-data-wrapper .overview-box-wrapper .case-report-box {
      border-radius: 8px;
      box-shadow: 0px 2px 20px rgba(25, 25, 25, 0.18);
      margin: 0 24px 24px 0;
      width: calc((100% / 3) - 16px);
      display: inline-block;
      padding: 18px 8px;
      vertical-align: top; }
      .overview-report-wrapper .overview-report-data-wrapper .overview-box-wrapper .case-report-box:nth-child(3n) {
        margin: 0 0 24px 0; }
      .overview-report-wrapper .overview-report-data-wrapper .overview-box-wrapper .case-report-box .case-report-value {
        font-size: 24px;
        letter-spacing: -0.6px;
        margin: 8px 0;
        line-height: 1; }
        .overview-report-wrapper .overview-report-data-wrapper .overview-box-wrapper .case-report-box .case-report-value.onetalk-shine {
          width: 120px;
          height: 30px;
          border-radius: 6px;
          display: block; }
      .overview-report-wrapper .overview-report-data-wrapper .overview-box-wrapper .case-report-box .case-report-title {
        letter-spacing: -0.4px;
        font-size: 14px;
        color: rgba(25, 25, 25, 0.6);
        margin: 0;
        line-height: 1; }
    .overview-report-wrapper .overview-report-data-wrapper .overview-box-wrapper .overview-case-rating-left {
      vertical-align: top;
      width: 60%;
      height: 351px;
      display: inline-block;
      position: relative;
      padding: 0 60px 0 60px; }
      .overview-report-wrapper .overview-report-data-wrapper .overview-box-wrapper .overview-case-rating-left .total-rating-is-text {
        position: absolute;
        left: 50%;
        top: 50%;
        -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%); }
        .overview-report-wrapper .overview-report-data-wrapper .overview-box-wrapper .overview-case-rating-left .total-rating-is-text .total-rating-counter.onetalk-shine {
          height: 30px;
          width: 64px;
          border-radius: 6px; }
      .overview-report-wrapper .overview-report-data-wrapper .overview-box-wrapper .overview-case-rating-left .total-rating-is {
        background: #ffffff;
        border-radius: 50%;
        position: absolute;
        left: 50%;
        top: 50%;
        -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
        width: 170px;
        height: 170px;
        text-align: center; }
    .overview-report-wrapper .overview-report-data-wrapper .overview-box-wrapper .overview-case-rating-right {
      vertical-align: top;
      width: 40%;
      display: inline-block; }
      .overview-report-wrapper .overview-report-data-wrapper .overview-box-wrapper .overview-case-rating-right .table-overview {
        width: 100%; }
        .overview-report-wrapper .overview-report-data-wrapper .overview-box-wrapper .overview-case-rating-right .table-overview thead tr td {
          font-size: 16px;
          letter-spacing: -0.5px;
          color: #191919;
          padding: 0 0 16px 0; }
          .overview-report-wrapper .overview-report-data-wrapper .overview-box-wrapper .overview-case-rating-right .table-overview thead tr td:nth-child(2) {
            text-align: right; }
        .overview-report-wrapper .overview-report-data-wrapper .overview-box-wrapper .overview-case-rating-right .table-overview tbody tr td {
          font-size: 14px;
          letter-spacing: -0.4px;
          border-bottom: solid 1px #dcdcdc;
          padding: 8px 0 8px 14px;
          position: relative; }
          .overview-report-wrapper .overview-report-data-wrapper .overview-box-wrapper .overview-case-rating-right .table-overview tbody tr td .onetalk-shine {
            width: 44px;
            height: 21px;
            border-radius: 6px;
            padding: 0;
            margin: 0; }
          .overview-report-wrapper .overview-report-data-wrapper .overview-box-wrapper .overview-case-rating-right .table-overview tbody tr td div {
            width: 6px;
            height: 6px;
            border-radius: 50%;
            position: absolute;
            left: 0;
            top: 50%;
            -webkit-transform: translate(0, -50%);
                    transform: translate(0, -50%); }
          .overview-report-wrapper .overview-report-data-wrapper .overview-box-wrapper .overview-case-rating-right .table-overview tbody tr td.stars-8 div {
            background: #DCDCDC; }
          .overview-report-wrapper .overview-report-data-wrapper .overview-box-wrapper .overview-case-rating-right .table-overview tbody tr td.stars-7 div {
            background: #FF3F57; }
          .overview-report-wrapper .overview-report-data-wrapper .overview-box-wrapper .overview-case-rating-right .table-overview tbody tr td.stars-6 div {
            background: #007A8A; }
          .overview-report-wrapper .overview-report-data-wrapper .overview-box-wrapper .overview-case-rating-right .table-overview tbody tr td.stars-5 div {
            background: #51E683; }
          .overview-report-wrapper .overview-report-data-wrapper .overview-box-wrapper .overview-case-rating-right .table-overview tbody tr td.stars-4 div {
            background: #3BAEff; }
          .overview-report-wrapper .overview-report-data-wrapper .overview-box-wrapper .overview-case-rating-right .table-overview tbody tr td.stars-3 div {
            background: #9C2EEF; }
          .overview-report-wrapper .overview-report-data-wrapper .overview-box-wrapper .overview-case-rating-right .table-overview tbody tr td.stars-2 div {
            background: #F4C12A; }
          .overview-report-wrapper .overview-report-data-wrapper .overview-box-wrapper .overview-case-rating-right .table-overview tbody tr td.stars-1 div {
            background: #f99080; }
          .overview-report-wrapper .overview-report-data-wrapper .overview-box-wrapper .overview-case-rating-right .table-overview tbody tr td:nth-child(2) {
            text-align: right; }
      .overview-report-wrapper .overview-report-data-wrapper .overview-box-wrapper .overview-case-rating-right .overview-case-rating-average {
        padding: 16px 8px;
        border-radius: 8px;
        width: 100%;
        position: relative;
        display: block;
        box-shadow: 0px 2px 20px rgba(25, 25, 25, 0.18);
        margin-top: 24px; }
        .overview-report-wrapper .overview-report-data-wrapper .overview-box-wrapper .overview-case-rating-right .overview-case-rating-average .rating-value {
          font-size: 24px;
          letter-spacing: -0.6px; }
          .overview-report-wrapper .overview-report-data-wrapper .overview-box-wrapper .overview-case-rating-right .overview-case-rating-average .rating-value.onetalk-shine {
            width: 64px;
            height: 30px;
            border-radius: 6px;
            position: relative;
            top: 7px; }
        .overview-report-wrapper .overview-report-data-wrapper .overview-box-wrapper .overview-case-rating-right .overview-case-rating-average svg {
          color: #F2BF2A;
          font-size: 22px;
          margin-right: 4px;
          top: -5px;
          position: relative; }
        .overview-report-wrapper .overview-report-data-wrapper .overview-box-wrapper .overview-case-rating-right .overview-case-rating-average p {
          margin: 8px 0 0;
          color: rgba(25, 25, 25, 0.6);
          font-size: 14px;
          letter-spacing: -0.4px; }
    .overview-report-wrapper .overview-report-data-wrapper .overview-box-wrapper .overview-agent-report-wrapper {
      width: 100%;
      position: relative;
      display: block; }
      .overview-report-wrapper .overview-report-data-wrapper .overview-box-wrapper .overview-agent-report-wrapper .overview-agent-report-box {
        text-align: center;
        width: 25%;
        padding: 0 16px;
        display: inline-block;
        vertical-align: top; }
      .overview-report-wrapper .overview-report-data-wrapper .overview-box-wrapper .overview-agent-report-wrapper .agent-report-icon-wrapper {
        width: 48px;
        height: 48px;
        position: relative;
        display: inline-block;
        border-radius: 50%;
        background: #F99080;
        box-shadow: 0px 2px 20px rgba(25, 25, 25, 0.18); }
        .overview-report-wrapper .overview-report-data-wrapper .overview-box-wrapper .overview-agent-report-wrapper .agent-report-icon-wrapper svg {
          color: #ffffff;
          font-size: 20px;
          position: absolute;
          top: 50%;
          left: 50%;
          -webkit-transform: translate(-50%, -50%);
                  transform: translate(-50%, -50%); }
      .overview-report-wrapper .overview-report-data-wrapper .overview-box-wrapper .overview-agent-report-wrapper p.agent-report-text-1 {
        font-size: 24px;
        letter-spacing: -0.6px;
        margin: 8px 0; }
        .overview-report-wrapper .overview-report-data-wrapper .overview-box-wrapper .overview-agent-report-wrapper p.agent-report-text-1 .rating-text svg {
          position: relative;
          top: -3px;
          font-size: 20px;
          color: #F4C12A;
          margin-right: 4px; }
        .overview-report-wrapper .overview-report-data-wrapper .overview-box-wrapper .overview-agent-report-wrapper p.agent-report-text-1 .onetalk-shine {
          width: 44px;
          height: 30px;
          border-radius: 6px; }
      .overview-report-wrapper .overview-report-data-wrapper .overview-box-wrapper .overview-agent-report-wrapper p.agent-report-text-2 {
        font-size: 12px;
        letter-spacing: -0.4px;
        margin: 0 0 2px; }
        .overview-report-wrapper .overview-report-data-wrapper .overview-box-wrapper .overview-agent-report-wrapper p.agent-report-text-2 .onetalk-shine {
          width: 44px;
          height: 21px;
          border-radius: 6px; }
      .overview-report-wrapper .overview-report-data-wrapper .overview-box-wrapper .overview-agent-report-wrapper p.agent-report-text-3 {
        font-size: 12px;
        letter-spacing: -0.2px;
        color: rgba(25, 25, 25, 0.8);
        margin: 0; }
        .overview-report-wrapper .overview-report-data-wrapper .overview-box-wrapper .overview-agent-report-wrapper p.agent-report-text-3 .onetalk-shine {
          width: 88px;
          height: 21px;
          border-radius: 6px; }
      .overview-report-wrapper .overview-report-data-wrapper .overview-box-wrapper .overview-agent-report-wrapper p .onetalk-shine-star {
        position: relative;
        top: -9px;
        font-size: 20px;
        color: #f4c12a;
        margin-right: 6px; }

.overview-topic-report-wrapper {
  margin: 30px 0 0 0; }
  .overview-topic-report-wrapper.overview-topic-report-wrapper-no-margin {
    margin: 0; }
    .overview-topic-report-wrapper.overview-topic-report-wrapper-no-margin img {
      margin: 0; }
  .overview-topic-report-wrapper .overview-topic-report-inner-wrapper {
    padding: 8px; }
    .overview-topic-report-wrapper .overview-topic-report-inner-wrapper .overview-topic-report-title {
      font-size: 20px;
      letter-spacing: -0.6px;
      margin: 0 0 16px 0; }
    .overview-topic-report-wrapper .overview-topic-report-inner-wrapper .table-overview .grey-box {
      font-size: 12px;
      letter-spacing: -0.4px;
      font-size: 12px;
      color: rgba(25, 25, 25, 0.6);
      background: rgba(25, 25, 25, 0.05);
      border-radius: 4px;
      padding: 2px 6px; }
    .overview-topic-report-wrapper .overview-topic-report-inner-wrapper .table-overview .others div {
      background: rgba(25, 25, 25, 0.18); }

.overview-topic-report-wrapper {
  margin: 30px 0 0 0; }
  .overview-topic-report-wrapper .overview-box-no-data {
    width: 100%;
    text-align: center;
    padding: 0 0 40px 0; }
    .overview-topic-report-wrapper .overview-box-no-data img {
      margin: 25px 0 0 0;
      width: 200px; }
    .overview-topic-report-wrapper .overview-box-no-data p {
      display: inline-block;
      margin: 22px 0 0 0;
      color: rgba(25, 25, 25, 0.4);
      font-size: 20px;
      letter-spacing: -0.6px; }

.overview-topic-report-wrapper {
  margin: 30px 0 0 0; }
  .overview-topic-report-wrapper.overview-topic-report-wrapper-no-margin {
    margin: 0; }
    .overview-topic-report-wrapper.overview-topic-report-wrapper-no-margin img {
      margin: 0; }
  .overview-topic-report-wrapper .overview-topic-report-inner-wrapper {
    padding: 8px; }
    .overview-topic-report-wrapper .overview-topic-report-inner-wrapper .overview-topic-report-title {
      font-size: 20px;
      letter-spacing: -0.6px;
      margin: 0 0 16px 0; }
    .overview-topic-report-wrapper .overview-topic-report-inner-wrapper .table-overview .grey-box {
      font-size: 12px;
      letter-spacing: -0.4px;
      font-size: 12px;
      color: rgba(25, 25, 25, 0.6);
      background: rgba(25, 25, 25, 0.05);
      border-radius: 4px;
      padding: 2px 6px; }
    .overview-topic-report-wrapper .overview-topic-report-inner-wrapper .table-overview .others div {
      background: rgba(25, 25, 25, 0.18); }

.overview-topic-report-wrapper {
  margin: 30px 0 0 0; }
  .overview-topic-report-wrapper .overview-topic-report-inner-wrapper {
    padding: 8px; }
    .overview-topic-report-wrapper .overview-topic-report-inner-wrapper .overview-topic-report-title {
      font-size: 20px;
      letter-spacing: -0.6px;
      margin: 0 0 16px 0; }
    .overview-topic-report-wrapper .overview-topic-report-inner-wrapper .table-overview td {
      background: #fff !important; }
      .overview-topic-report-wrapper .overview-topic-report-inner-wrapper .table-overview td .onetalk-shine-left-1 {
        width: 77px !important;
        height: 22px !important;
        border-radius: 6px;
        position: relative;
        top: 3px; }
      .overview-topic-report-wrapper .overview-topic-report-inner-wrapper .table-overview td .onetalk-shine-right {
        width: 44px;
        height: 21px;
        border-radius: 6px;
        position: relative;
        top: 4px; }
    .overview-topic-report-wrapper .overview-topic-report-inner-wrapper .table-overview .grey-box {
      font-size: 12px;
      letter-spacing: -0.4px;
      font-size: 12px;
      color: rgba(25, 25, 25, 0.6);
      background: rgba(25, 25, 25, 0.05);
      border-radius: 4px;
      padding: 2px 6px; }
    .overview-topic-report-wrapper .overview-topic-report-inner-wrapper .table-overview .others div {
      background: rgba(25, 25, 25, 0.18); }

.overview-utm-report-wrapper {
  margin: 30px 0 0 0; }
  .overview-utm-report-wrapper .overview-utm-report-title {
    font-size: 20px;
    letter-spacing: -0.6px;
    margin: 0 0 16px 0; }
  .overview-utm-report-wrapper .overview-utm-value-wrapper {
    padding: 0 12px 0;
    width: 100%;
    position: relative;
    display: block; }
    .overview-utm-report-wrapper .overview-utm-value-wrapper .overview-utm-table-title {
      font-size: 16px;
      letter-spacing: -0.6px;
      width: 100%;
      position: relative;
      margin: 0; }
      .overview-utm-report-wrapper .overview-utm-value-wrapper .overview-utm-table-title a {
        position: absolute;
        right: 0;
        color: var(--main-dark-orange); }
        .overview-utm-report-wrapper .overview-utm-value-wrapper .overview-utm-table-title a:hover {
          color: var(--main-darker-orange); }
        .overview-utm-report-wrapper .overview-utm-value-wrapper .overview-utm-table-title a svg {
          font-size: 20px;
          position: relative;
          margin-right: 4px;
          top: -2px; }
      .overview-utm-report-wrapper .overview-utm-value-wrapper .overview-utm-table-title .grey-font {
        position: absolute;
        right: 0;
        top: 0;
        color: var(--main-dark-orange); }
        .overview-utm-report-wrapper .overview-utm-value-wrapper .overview-utm-table-title .grey-font:hover {
          color: var(--main-darker-orange); }
        .overview-utm-report-wrapper .overview-utm-value-wrapper .overview-utm-table-title .grey-font svg {
          font-size: 20px;
          position: relative;
          margin-right: 4px;
          top: -2px; }
    .overview-utm-report-wrapper .overview-utm-value-wrapper .no-overview-data {
      color: rgba(25, 25, 25, 0.4);
      width: 100%;
      text-align: center;
      padding: 48px 20px 100px; }
    .overview-utm-report-wrapper .overview-utm-value-wrapper .overview-utm-table-wrapper {
      padding: 16px;
      border-radius: 8px;
      vertical-align: top;
      box-shadow: 0px 2px 20px 0px rgba(25, 25, 25, 0.05);
      background: #fff;
      width: calc(50% - 8px);
      display: inline-block;
      margin-right: 16px;
      margin-bottom: 40px; }
      .overview-utm-report-wrapper .overview-utm-value-wrapper .overview-utm-table-wrapper:nth-child(even) {
        margin-right: 0; }
      .overview-utm-report-wrapper .overview-utm-value-wrapper .overview-utm-table-wrapper:nth-child(5) {
        margin-bottom: 0; }
      .overview-utm-report-wrapper .overview-utm-value-wrapper .overview-utm-table-wrapper:nth-child(6) {
        margin-bottom: 0; }
      .overview-utm-report-wrapper .overview-utm-value-wrapper .overview-utm-table-wrapper .of-cases {
        font-size: 16px;
        letter-spacing: -0.5px;
        margin: 20px 0 16px;
        width: 100%;
        text-align: right; }
      .overview-utm-report-wrapper .overview-utm-value-wrapper .overview-utm-table-wrapper .utm-val {
        border-bottom: solid 1px #dcdcdc;
        margin: 0;
        font-size: 14px;
        letter-spacing: -0.2px;
        position: relative;
        padding: 8px 76px 8px 0;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        height: 38px; }
        .overview-utm-report-wrapper .overview-utm-value-wrapper .overview-utm-table-wrapper .utm-val.onetalk-shine-1 .onetalk-shine-left {
          width: 120px;
          height: 21px;
          border-radius: 6px; }
        .overview-utm-report-wrapper .overview-utm-value-wrapper .overview-utm-table-wrapper .utm-val.onetalk-shine-2 .onetalk-shine-left {
          width: 200px;
          height: 21px;
          border-radius: 6px; }
        .overview-utm-report-wrapper .overview-utm-value-wrapper .overview-utm-table-wrapper .utm-val.onetalk-shine-3 .onetalk-shine-left {
          width: 160px;
          height: 21px;
          border-radius: 6px; }
        .overview-utm-report-wrapper .overview-utm-value-wrapper .overview-utm-table-wrapper .utm-val.onetalk-shine-4 .onetalk-shine-left {
          width: 80px;
          height: 21px;
          border-radius: 6px; }
        .overview-utm-report-wrapper .overview-utm-value-wrapper .overview-utm-table-wrapper .utm-val.onetalk-shine-5 .onetalk-shine-left {
          width: 120px;
          height: 21px;
          border-radius: 6px; }
        .overview-utm-report-wrapper .overview-utm-value-wrapper .overview-utm-table-wrapper .utm-val.onetalk-shine-6 .onetalk-shine-left {
          width: 44px;
          height: 21px;
          border-radius: 6px; }
        .overview-utm-report-wrapper .overview-utm-value-wrapper .overview-utm-table-wrapper .utm-val .onetalk-shine-right {
          width: 44px;
          height: 21px;
          border-radius: 6px;
          position: absolute;
          right: 0; }
        .overview-utm-report-wrapper .overview-utm-value-wrapper .overview-utm-table-wrapper .utm-val .utm-total {
          position: absolute;
          text-align: left;
          width: 70px;
          right: 0; }
        .overview-utm-report-wrapper .overview-utm-value-wrapper .overview-utm-table-wrapper .utm-val:nth-last-child(1) {
          padding: 8px 76px 0 0;
          border: none; }

.overview-daily-report-wrapper {
  min-height: 500px; }
  .overview-daily-report-wrapper .total-cases-per-hour {
    margin-top: 32px; }
    .overview-daily-report-wrapper .total-cases-per-hour .overview-daily-box-title {
      padding: 0 0 12px 0;
      border-bottom: solid 1px #1919191A; }
  .overview-daily-report-wrapper .real-time-status-title {
    margin: 0 0 24px 0; }
  .overview-daily-report-wrapper .overview-daily-report-title {
    font-size: 20px;
    letter-spacing: -0.6px;
    margin: 0;
    color: #191919;
    padding: 8px 0 16px 0;
    position: relative;
    border-bottom: solid 1px #1919191A; }
    .overview-daily-report-wrapper .overview-daily-report-title .advanced-date-selection {
      position: absolute;
      right: 0;
      top: 0; }
  .overview-daily-report-wrapper .daily-overview-date-wrapper-top {
    position: relative;
    display: block;
    width: 100%;
    padding: 16px 0 0; }
    .overview-daily-report-wrapper .daily-overview-date-wrapper-top .date-wrapper {
      height: 48px;
      border: solid 1px #1919191A;
      border-radius: 8px;
      color: #191919;
      font-size: 16px;
      letter-spacing: -0.3px;
      display: inline-block;
      padding: 12px 54px 12px 16px;
      position: relative;
      cursor: pointer; }
      .overview-daily-report-wrapper .daily-overview-date-wrapper-top .date-wrapper svg {
        position: absolute;
        right: 16px;
        top: 50%;
        -webkit-transform: translate(0, -50%);
                transform: translate(0, -50%);
        font-size: 18px; }
    .overview-daily-report-wrapper .daily-overview-date-wrapper-top .toggle-auto-refresh {
      display: inline-block;
      position: absolute;
      top: 28px;
      right: 0; }
      .overview-daily-report-wrapper .daily-overview-date-wrapper-top .toggle-auto-refresh .custom-switch-wrapper {
        display: inline-block !important;
        width: 26px;
        height: 14px;
        position: relative;
        top: 5px;
        margin: 0;
        margin-right: 4px;
        font-size: 16px;
        letter-spacing: -0.5px; }
        .overview-daily-report-wrapper .daily-overview-date-wrapper-top .toggle-auto-refresh .custom-switch-wrapper .custom-switch {
          padding: 0; }
          .overview-daily-report-wrapper .daily-overview-date-wrapper-top .toggle-auto-refresh .custom-switch-wrapper .custom-switch.round::before {
            width: 8px;
            height: 8px;
            bottom: 3px;
            left: 4px; }
        .overview-daily-report-wrapper .daily-overview-date-wrapper-top .toggle-auto-refresh .custom-switch-wrapper input:checked + .custom-switch:before {
          -webkit-transform: translateX(10px);
                  transform: translateX(10px); }
      .overview-daily-report-wrapper .daily-overview-date-wrapper-top .toggle-auto-refresh svg {
        color: rgba(25, 25, 25, 0.8);
        outline: none !important;
        font-size: 12px;
        position: relative;
        top: -1px;
        margin-left: 2px; }
  .overview-daily-report-wrapper .daily-overview-top-right {
    position: absolute;
    top: 3px;
    right: 0; }
    .overview-daily-report-wrapper .daily-overview-top-right span {
      font-size: 14px;
      letter-spacing: -0.2px;
      color: rgba(25, 25, 25, 0.4);
      margin-right: 8px;
      position: relative;
      top: 4px; }
    .overview-daily-report-wrapper .daily-overview-top-right button {
      width: 181px; }
  .overview-daily-report-wrapper .real-time-status-wrapper {
    width: 100%;
    position: relative;
    display: block; }
    .overview-daily-report-wrapper .real-time-status-wrapper.real-time-status-wrapper-bottom {
      border: none !important;
      padding: 0;
      margin: 0 0 28px 0; }
    .overview-daily-report-wrapper .real-time-status-wrapper.real-time-status-wrapper-not-today {
      border: none !important;
      margin: 16px 0 0 0;
      padding: 0; }
    .overview-daily-report-wrapper .real-time-status-wrapper .status-value-wrapper {
      padding: 0 0 0 24px; }
      .overview-daily-report-wrapper .real-time-status-wrapper .status-value-wrapper p {
        position: relative;
        width: 100%;
        border-bottom: solid 1px #1919191A;
        padding: 0 0 8px 24px;
        color: #191919;
        font-size: 14px;
        letter-spacing: -0.4px;
        font-family: "PTRootBold"; }
        .overview-daily-report-wrapper .real-time-status-wrapper .status-value-wrapper p .activity-circle {
          width: 12px;
          top: 4px;
          height: 12px;
          position: absolute;
          border-radius: 50%;
          left: 0; }
          .overview-daily-report-wrapper .real-time-status-wrapper .status-value-wrapper p .activity-circle.offline {
            background: #FF3F57; }
          .overview-daily-report-wrapper .real-time-status-wrapper .status-value-wrapper p .activity-circle.online {
            background: #19C700; }
          .overview-daily-report-wrapper .real-time-status-wrapper .status-value-wrapper p .activity-circle.online-not {
            background: #118300; }
          .overview-daily-report-wrapper .real-time-status-wrapper .status-value-wrapper p .activity-circle.away {
            background: #F4C12A; }
        .overview-daily-report-wrapper .real-time-status-wrapper .status-value-wrapper p .value-icon {
          top: 1px;
          position: absolute;
          left: 0; }
        .overview-daily-report-wrapper .real-time-status-wrapper .status-value-wrapper p span {
          position: absolute;
          right: 0; }
  .overview-daily-report-wrapper .real-time-status-left-wrapper {
    width: calc(50% - 16px);
    position: relative;
    display: inline-block;
    vertical-align: top;
    margin: 0 16px 0 0; }
  .overview-daily-report-wrapper .real-time-status-right-wrapper {
    width: calc(50% - 16px);
    position: relative;
    display: inline-block;
    vertical-align: top;
    margin: 0 0 0 16px; }
    .overview-daily-report-wrapper .real-time-status-right-wrapper.real-time-status-wrapper-60 {
      width: 65%; }
  .overview-daily-report-wrapper .value-title-icon {
    margin-right: 8px;
    position: relative;
    top: -2px; }
  .overview-daily-report-wrapper .overview-daily-report-description {
    margin: 8px 0 16px;
    font-size: 16px;
    letter-spacing: -0.3px;
    width: calc(100% - 230px);
    color: #191919;
    line-height: 24px; }
  .overview-daily-report-wrapper .add-overview-daily-report-button {
    text-align: center;
    position: absolute;
    top: 32px;
    right: 32px; }
  .overview-daily-report-wrapper .overview-daily-report-data-wrapper {
    width: 100%;
    color: #191919; }
    .overview-daily-report-wrapper .overview-daily-report-data-wrapper .no-overview-daily-report {
      width: 100%;
      text-align: center;
      font-size: 20px;
      letter-spacing: -0.6px;
      color: rgba(25, 25, 25, 0.6); }
    .overview-daily-report-wrapper .overview-daily-report-data-wrapper .overview-daily-box-wrapper {
      position: relative;
      margin-top: 32px; }
      .overview-daily-report-wrapper .overview-daily-report-data-wrapper .overview-daily-box-wrapper .overview-daily-box-title {
        font-size: 20px;
        letter-spacing: -.6px;
        color: #191919;
        padding: 0 0 12px;
        border-bottom: solid 1px #1919191A;
        margin: 0 0 24px; }
      .overview-daily-report-wrapper .overview-daily-report-data-wrapper .overview-daily-box-wrapper .overview-daily-box-title-2 {
        margin: 40px 0 0 0;
        padding: 0 0 0 16px;
        border: none !important; }
      .overview-daily-report-wrapper .overview-daily-report-data-wrapper .overview-daily-box-wrapper .case-report-box {
        border-radius: 8px;
        box-shadow: 0px 2px 20px rgba(25, 25, 25, 0.18);
        margin: 0 24px 24px 0;
        width: calc((100% / 3) - 16px);
        display: inline-block;
        padding: 18px 8px;
        vertical-align: top; }
        .overview-daily-report-wrapper .overview-daily-report-data-wrapper .overview-daily-box-wrapper .case-report-box:nth-child(3n) {
          margin: 0 0 24px 0; }
        .overview-daily-report-wrapper .overview-daily-report-data-wrapper .overview-daily-box-wrapper .case-report-box .case-report-value {
          font-size: 24px;
          letter-spacing: -0.6px;
          margin: 8px 0;
          line-height: 1; }
        .overview-daily-report-wrapper .overview-daily-report-data-wrapper .overview-daily-box-wrapper .case-report-box .case-report-title {
          letter-spacing: -0.4px;
          font-size: 14px;
          color: rgba(25, 25, 25, 0.6);
          margin: 0;
          line-height: 1;
          position: relative; }
          .overview-daily-report-wrapper .overview-daily-report-data-wrapper .overview-daily-box-wrapper .case-report-box .case-report-title svg {
            position: relative;
            margin-left: 4px;
            top: -2px;
            font-size: 12px;
            outline: none !important;
            cursor: pointer; }
      .overview-daily-report-wrapper .overview-daily-report-data-wrapper .overview-daily-box-wrapper .overview-daily-case-rating-left {
        vertical-align: top;
        width: 60%;
        height: 351px;
        display: inline-block;
        position: relative;
        padding: 0 60px 0 60px; }
        .overview-daily-report-wrapper .overview-daily-report-data-wrapper .overview-daily-box-wrapper .overview-daily-case-rating-left .total-rating-is-text {
          position: absolute;
          left: 50%;
          top: 50%;
          -webkit-transform: translate(-50%, -50%);
                  transform: translate(-50%, -50%); }
        .overview-daily-report-wrapper .overview-daily-report-data-wrapper .overview-daily-box-wrapper .overview-daily-case-rating-left .total-rating-is {
          background: #ffffff;
          border-radius: 50%;
          position: absolute;
          left: 50%;
          top: 50%;
          -webkit-transform: translate(-50%, -50%);
                  transform: translate(-50%, -50%);
          width: 170px;
          height: 170px;
          text-align: center; }
      .overview-daily-report-wrapper .overview-daily-report-data-wrapper .overview-daily-box-wrapper .overview-daily-case-rating-right {
        vertical-align: top;
        width: 40%;
        display: inline-block; }
        .overview-daily-report-wrapper .overview-daily-report-data-wrapper .overview-daily-box-wrapper .overview-daily-case-rating-right .table-overview {
          width: 100%; }
          .overview-daily-report-wrapper .overview-daily-report-data-wrapper .overview-daily-box-wrapper .overview-daily-case-rating-right .table-overview thead tr td {
            font-size: 16px;
            letter-spacing: -0.5px;
            color: #191919;
            padding: 0 0 16px 0; }
            .overview-daily-report-wrapper .overview-daily-report-data-wrapper .overview-daily-box-wrapper .overview-daily-case-rating-right .table-overview thead tr td:nth-child(2) {
              text-align: right; }
          .overview-daily-report-wrapper .overview-daily-report-data-wrapper .overview-daily-box-wrapper .overview-daily-case-rating-right .table-overview tbody tr td {
            font-size: 14px;
            letter-spacing: -0.4px;
            border-bottom: solid 1px #1919191A;
            padding: 8px 0 8px 14px;
            position: relative; }
            .overview-daily-report-wrapper .overview-daily-report-data-wrapper .overview-daily-box-wrapper .overview-daily-case-rating-right .table-overview tbody tr td div {
              width: 6px;
              height: 6px;
              border-radius: 50%;
              position: absolute;
              left: 0;
              top: 50%;
              -webkit-transform: translate(0, -50%);
                      transform: translate(0, -50%); }
            .overview-daily-report-wrapper .overview-daily-report-data-wrapper .overview-daily-box-wrapper .overview-daily-case-rating-right .table-overview tbody tr td.stars-8 div {
              background: #1919191A; }
            .overview-daily-report-wrapper .overview-daily-report-data-wrapper .overview-daily-box-wrapper .overview-daily-case-rating-right .table-overview tbody tr td.stars-7 div {
              background: #FF3F57; }
            .overview-daily-report-wrapper .overview-daily-report-data-wrapper .overview-daily-box-wrapper .overview-daily-case-rating-right .table-overview tbody tr td.stars-6 div {
              background: #007A8A; }
            .overview-daily-report-wrapper .overview-daily-report-data-wrapper .overview-daily-box-wrapper .overview-daily-case-rating-right .table-overview tbody tr td.stars-5 div {
              background: #51E683; }
            .overview-daily-report-wrapper .overview-daily-report-data-wrapper .overview-daily-box-wrapper .overview-daily-case-rating-right .table-overview tbody tr td.stars-4 div {
              background: #3BAEff; }
            .overview-daily-report-wrapper .overview-daily-report-data-wrapper .overview-daily-box-wrapper .overview-daily-case-rating-right .table-overview tbody tr td.stars-3 div {
              background: #9C2EEF; }
            .overview-daily-report-wrapper .overview-daily-report-data-wrapper .overview-daily-box-wrapper .overview-daily-case-rating-right .table-overview tbody tr td.stars-2 div {
              background: #F4C12A; }
            .overview-daily-report-wrapper .overview-daily-report-data-wrapper .overview-daily-box-wrapper .overview-daily-case-rating-right .table-overview tbody tr td.stars-1 div {
              background: #f99080; }
            .overview-daily-report-wrapper .overview-daily-report-data-wrapper .overview-daily-box-wrapper .overview-daily-case-rating-right .table-overview tbody tr td:nth-child(2) {
              text-align: right; }
        .overview-daily-report-wrapper .overview-daily-report-data-wrapper .overview-daily-box-wrapper .overview-daily-case-rating-right .overview-daily-case-rating-average {
          padding: 16px 8px;
          border-radius: 8px;
          width: 100%;
          position: relative;
          display: block;
          box-shadow: 0px 2px 20px rgba(25, 25, 25, 0.18);
          margin-top: 24px; }
          .overview-daily-report-wrapper .overview-daily-report-data-wrapper .overview-daily-box-wrapper .overview-daily-case-rating-right .overview-daily-case-rating-average .rating-value {
            font-size: 24px;
            letter-spacing: -0.6px; }
          .overview-daily-report-wrapper .overview-daily-report-data-wrapper .overview-daily-box-wrapper .overview-daily-case-rating-right .overview-daily-case-rating-average svg {
            color: #F2BF2A;
            font-size: 22px;
            margin-right: 4px;
            top: -5px;
            position: relative; }
          .overview-daily-report-wrapper .overview-daily-report-data-wrapper .overview-daily-box-wrapper .overview-daily-case-rating-right .overview-daily-case-rating-average p {
            margin: 8px 0 0;
            color: rgba(25, 25, 25, 0.6);
            font-size: 14px;
            letter-spacing: -0.4px; }
      .overview-daily-report-wrapper .overview-daily-report-data-wrapper .overview-daily-box-wrapper .overview-daily-agent-report-wrapper {
        width: 100%;
        position: relative;
        display: block; }
        .overview-daily-report-wrapper .overview-daily-report-data-wrapper .overview-daily-box-wrapper .overview-daily-agent-report-wrapper .overview-daily-agent-report-box {
          text-align: center;
          width: 25%;
          padding: 0 16px;
          display: inline-block;
          vertical-align: top; }
        .overview-daily-report-wrapper .overview-daily-report-data-wrapper .overview-daily-box-wrapper .overview-daily-agent-report-wrapper .agent-report-icon-wrapper {
          width: 48px;
          height: 48px;
          position: relative;
          display: inline-block;
          border-radius: 50%;
          background: #F99080;
          box-shadow: 0px 2px 20px rgba(25, 25, 25, 0.18); }
          .overview-daily-report-wrapper .overview-daily-report-data-wrapper .overview-daily-box-wrapper .overview-daily-agent-report-wrapper .agent-report-icon-wrapper svg {
            color: #ffffff;
            font-size: 20px;
            position: absolute;
            top: 50%;
            left: 50%;
            -webkit-transform: translate(-50%, -50%);
                    transform: translate(-50%, -50%); }
        .overview-daily-report-wrapper .overview-daily-report-data-wrapper .overview-daily-box-wrapper .overview-daily-agent-report-wrapper p.agent-report-text-1 {
          font-size: 24px;
          letter-spacing: -0.6px;
          margin: 8px 0; }
          .overview-daily-report-wrapper .overview-daily-report-data-wrapper .overview-daily-box-wrapper .overview-daily-agent-report-wrapper p.agent-report-text-1 .rating-text svg {
            position: relative;
            top: -3px;
            font-size: 20px;
            color: #F4C12A;
            margin-right: 4px; }
        .overview-daily-report-wrapper .overview-daily-report-data-wrapper .overview-daily-box-wrapper .overview-daily-agent-report-wrapper p.agent-report-text-2 {
          font-size: 12px;
          letter-spacing: -0.4px;
          margin: 0 0 2px; }
        .overview-daily-report-wrapper .overview-daily-report-data-wrapper .overview-daily-box-wrapper .overview-daily-agent-report-wrapper p.agent-report-text-3 {
          font-size: 12px;
          letter-spacing: -0.2px;
          color: rgba(25, 25, 25, 0.8);
          margin: 0; }
      .overview-daily-report-wrapper .overview-daily-report-data-wrapper .overview-daily-box-wrapper .chart-legend-wrapper {
        position: absolute;
        top: 4px;
        right: 0px; }
        .overview-daily-report-wrapper .overview-daily-report-data-wrapper .overview-daily-box-wrapper .chart-legend-wrapper .chart-legend-list {
          display: inline-block; }
          .overview-daily-report-wrapper .overview-daily-report-data-wrapper .overview-daily-box-wrapper .chart-legend-wrapper .chart-legend-list .chart-legend {
            display: inline-block;
            vertical-align: middle;
            margin: 0 8px 0 16px;
            width: 8px;
            height: 8px;
            border-radius: 4px; }
          .overview-daily-report-wrapper .overview-daily-report-data-wrapper .overview-daily-box-wrapper .chart-legend-wrapper .chart-legend-list .chart-legend-tooltip-icon {
            display: inline-block;
            vertical-align: middle;
            margin-left: 2px;
            width: 12px;
            height: 12px;
            color: #19191966;
            cursor: pointer; }
          .overview-daily-report-wrapper .overview-daily-report-data-wrapper .overview-daily-box-wrapper .chart-legend-wrapper .chart-legend-list .created {
            background: #3BAEFF; }
          .overview-daily-report-wrapper .overview-daily-report-data-wrapper .overview-daily-box-wrapper .chart-legend-wrapper .chart-legend-list .unassigned {
            background: #F99080; }
          .overview-daily-report-wrapper .overview-daily-report-data-wrapper .overview-daily-box-wrapper .chart-legend-wrapper .chart-legend-list .resolved {
            background: #191919; }
          .overview-daily-report-wrapper .overview-daily-report-data-wrapper .overview-daily-box-wrapper .chart-legend-wrapper .chart-legend-list .channel-unassigned {
            background: #F99080; }
          .overview-daily-report-wrapper .overview-daily-report-data-wrapper .overview-daily-box-wrapper .chart-legend-wrapper .chart-legend-list .assigned-to-agent {
            background: #51E683; }
          .overview-daily-report-wrapper .overview-daily-report-data-wrapper .overview-daily-box-wrapper .chart-legend-wrapper .chart-legend-list .assigned-to-bot {
            background: #9C88F1; }
          .overview-daily-report-wrapper .overview-daily-report-data-wrapper .overview-daily-box-wrapper .chart-legend-wrapper .chart-legend-list p {
            display: inline-block;
            vertical-align: middle;
            margin: 0px;
            font-size: 12px;
            line-height: 16px;
            letter-spacing: -0.2px;
            color: #191919; }
      .overview-daily-report-wrapper .overview-daily-report-data-wrapper .overview-daily-box-wrapper .overview-topic-report-wrapper .overview-topic-report-inner-wrapper {
        padding-left: 28px; }
        .overview-daily-report-wrapper .overview-daily-report-data-wrapper .overview-daily-box-wrapper .overview-topic-report-wrapper .overview-topic-report-inner-wrapper .channel-traffic-chart-wrapper {
          width: 100%;
          overflow-x: auto; }
        .overview-daily-report-wrapper .overview-daily-report-data-wrapper .overview-daily-box-wrapper .overview-topic-report-wrapper .overview-topic-report-inner-wrapper .chart-y-label {
          position: absolute;
          left: -16px;
          top: 50%;
          -webkit-transform: translate(0, -50%);
                  transform: translate(0, -50%);
          -webkit-transform: rotate(-90deg);
                  transform: rotate(-90deg);
          font-size: 12px;
          line-height: 16px;
          letter-spacing: -0.2px;
          color: #19191966; }
        .overview-daily-report-wrapper .overview-daily-report-data-wrapper .overview-daily-box-wrapper .overview-topic-report-wrapper .overview-topic-report-inner-wrapper .chart-x-label {
          width: 100%;
          text-align: center;
          font-size: 12px;
          line-height: 16px;
          letter-spacing: -0.2px;
          color: #19191966; }
    .overview-daily-report-wrapper .overview-daily-report-data-wrapper .section-separator {
      border-bottom: solid 1px #1919191A;
      margin: 32px 0; }

#channel-traffic-chart .apexcharts-tooltip {
  padding: 8px;
  font-family: "PTRootUI";
  font-size: 12px;
  letter-spacing: -0.4px;
  color: white;
  background: #6B6B6B !important; }
  #channel-traffic-chart .apexcharts-tooltip img {
    margin: 0 4px 4px 0;
    width: 16px;
    height: 16px;
    border-radius: 8px; }
  #channel-traffic-chart .apexcharts-tooltip b {
    margin-bottom: 4px;
    -webkit-transform: translateY(-1px);
            transform: translateY(-1px); }
  #channel-traffic-chart .apexcharts-tooltip p {
    margin: 0; }

.tooltip-daily-report .tooltip-inner {
  font-family: "PTRootUI" !important;
  font-weight: 400 !important;
  font-size: 12px !important;
  line-height: 16px !important;
  letter-spacing: -0.2px !important;
  color: #FFFFFF !important; }

.tooltip-chart-legend .tooltip-inner {
  text-align: center !important;
  font-family: "PTRootUI" !important;
  font-weight: 400 !important;
  font-size: 12px !important;
  line-height: 16px !important;
  letter-spacing: -0.2px !important;
  color: #FFFFFF !important; }

.utm-main-wrapper .btn {
  border: 0; }

.utm-main-wrapper tr:hover td {
  background: #ffffff !important; }

.utm-list-title {
  color: #191919;
  font-size: 20px;
  letter-spacing: -0.6px;
  line-height: 24px;
  padding-bottom: 16px;
  border-bottom: solid 1px rgba(25, 25, 25, 0.1); }

.utm-list-tool-wrapper {
  width: 100%;
  position: relative;
  margin-top: 24px;
  margin-bottom: 32px; }

.utm-list-info {
  font-size: 16px;
  letter-spacing: -0.3px;
  color: rgba(25, 25, 25, 0.8);
  line-height: 1.5;
  padding: 0 208px 18px 0; }

.utm-list-tool-wrapper button.button-invite-member {
  position: absolute;
  right: 0;
  top: 50%;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%);
  padding: 11px 0;
  width: 200px; }

.utm-list-tool-wrapper button.button-invite-member svg {
  position: relative;
  top: -1px;
  margin-right: 5px; }

.utm-list-table-wrapper {
  width: 100%;
  position: relative; }

.table-utm-list thead tr {
  background: #ffffff; }

.table-utm-list tbody td {
  background: #ffffff;
  position: relative; }

.table-utm-list button svg {
  position: relative !important;
  right: unset !important;
  top: -2px !important;
  margin-right: 5px; }

.table-utm-list .col-action-button-wrapper {
  position: absolute;
  right: 7px;
  top: 50%;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%);
  /* display: none; */ }

.table-utm-list .single-remove-button {
  margin-left: 16px;
  color: #ff3f57; }

.table-utm-list .single-remove-button:hover {
  color: #e83b51; }

.table-utm-list .shortcut-badge {
  font-size: 12px;
  color: rgba(25, 25, 25, 0.6);
  letter-spacing: -0.4px;
  background: #f3f3f3;
  line-height: 1;
  border-radius: 4px;
  padding: 1px 8px 3px; }

.table-utm-list td {
  max-width: unset !important; }

.table-utm-list .col-action-button-wrapper {
  display: block !important;
  position: relative;
  top: 0;
  right: unset;
  -webkit-transform: unset;
          transform: unset; }

.table-utm-list .col-action-button-wrapper .view-agent {
  color: #ff7d00;
  letter-spacing: -0.5px; }

.table-utm-list .col-action-button-wrapper .view-agent:hover {
  color: var(--main-darker-orange); }

.table-utm-list .col-action-button-wrapper img.view-agent {
  margin-right: 3px;
  position: relative;
  top: -1px; }

.member-tool-wrapper {
  width: 460px; }

.table-utm-list .col_action {
  width: 200px;
  text-align: right; }

.utm-page-wrapper {
  margin: 24px 0 0; }
  .utm-page-wrapper .nav-item {
    cursor: pointer;
    color: rgba(25, 25, 25, 0.4);
    font-size: 16px;
    letter-spacing: -0.5px;
    padding: 0 0 12px 0;
    margin-right: 32px; }
    .utm-page-wrapper .nav-item a {
      padding: 0;
      border: none; }
    .utm-page-wrapper .nav-item :hover {
      color: #ff7d00; }
    .utm-page-wrapper .nav-item.active-setup-tab {
      color: #ff7d00;
      border-bottom: solid 4px #ff7d00; }
  .utm-page-wrapper .tab-content {
    margin: 24px 0 0; }

.utm-wrapper .utm-title {
  font-size: 20px;
  letter-spacing: -0.6px;
  margin: 0 0 16px 0;
  color: #191919;
  padding: 8px 0 12px;
  border-bottom: solid 1px #dcdcdc; }

.utm-wrapper .utm-description {
  margin: 8px 0 16px;
  font-size: 16px;
  letter-spacing: -0.3px;
  width: calc(100% - 230px);
  color: #191919;
  line-height: 24px; }

.utm-wrapper .add-utm-button {
  text-align: center;
  position: absolute;
  top: 32px;
  right: 32px; }

.utm-wrapper .utm-data-outer-wrapper {
  position: relative; }

.utm-wrapper .utm-data-wrapper {
  width: 100%;
  color: #191919;
  overflow-x: auto; }
  .utm-wrapper .utm-data-wrapper .no-utm {
    width: 100%;
    text-align: center;
    font-size: 20px;
    letter-spacing: -0.6px;
    color: rgba(25, 25, 25, 0.6); }
  .utm-wrapper .utm-data-wrapper table {
    width: 100%;
    min-width: 1480px; }
    .utm-wrapper .utm-data-wrapper table thead {
      font-size: 14px;
      border-bottom: solid 1px #dcdcdc;
      color: #191919;
      letter-spacing: -0.4px; }
      .utm-wrapper .utm-data-wrapper table thead td {
        padding: 8px 20px 8px 8px !important;
        white-space: nowrap; }
    .utm-wrapper .utm-data-wrapper table tbody {
      font-size: 14px;
      letter-spacing: -0.2px;
      color: #191919; }
      .utm-wrapper .utm-data-wrapper table tbody tr {
        border-bottom: solid 1px #dcdcdc; }
      .utm-wrapper .utm-data-wrapper table tbody td {
        padding: 16px 8px; }
        .utm-wrapper .utm-data-wrapper table tbody td.col_action {
          font-size: 14px;
          letter-spacing: -0.4px; }
          .utm-wrapper .utm-data-wrapper table tbody td.col_action .edit-button {
            color: #ff7d00;
            cursor: pointer;
            margin-right: 16px; }
          .utm-wrapper .utm-data-wrapper table tbody td.col_action .remove-button {
            color: #ff3f57;
            cursor: pointer; }
          .utm-wrapper .utm-data-wrapper table tbody td.col_action svg {
            font-size: 16px;
            position: relative;
            top: -2px;
            margin-right: 4px; }

.utm-wrapper .utm-title {
  font-size: 20px;
  letter-spacing: -0.6px;
  margin: 0 0 16px 0;
  color: #191919;
  padding: 8px 0 12px;
  border-bottom: solid 1px #dcdcdc; }

.utm-wrapper .utm-description {
  margin: 8px 0 16px;
  font-size: 16px;
  letter-spacing: -0.3px;
  width: calc(100% - 230px);
  color: #191919;
  line-height: 24px; }

.utm-wrapper .add-utm-button {
  text-align: center;
  position: absolute;
  top: 32px;
  right: 32px; }

.utm-wrapper .utm-data-outer-wrapper {
  position: relative; }

.utm-wrapper .utm-data-wrapper {
  width: 100%;
  color: #191919;
  overflow-x: auto;
  min-height: 200px; }
  .utm-wrapper .utm-data-wrapper .no-utm {
    width: 100%;
    text-align: center;
    font-size: 20px;
    letter-spacing: -0.6px;
    color: rgba(25, 25, 25, 0.6); }
  .utm-wrapper .utm-data-wrapper table {
    width: 100%;
    min-width: 1480px; }
    .utm-wrapper .utm-data-wrapper table thead {
      font-size: 14px;
      border-bottom: solid 1px #dcdcdc;
      color: #191919;
      letter-spacing: -0.4px; }
      .utm-wrapper .utm-data-wrapper table thead td {
        padding: 0 8px 8px;
        white-space: nowrap; }
        .utm-wrapper .utm-data-wrapper table thead td:last-child {
          width: 84px; }
    .utm-wrapper .utm-data-wrapper table tbody {
      font-size: 14px;
      letter-spacing: -0.2px;
      color: #191919; }
      .utm-wrapper .utm-data-wrapper table tbody tr {
        border-bottom: solid 1px #dcdcdc; }
      .utm-wrapper .utm-data-wrapper table tbody td {
        padding: 16px 8px;
        vertical-align: top; }
        .utm-wrapper .utm-data-wrapper table tbody td.col_action {
          font-size: 14px;
          letter-spacing: -0.4px; }
          .utm-wrapper .utm-data-wrapper table tbody td.col_action .edit-button {
            color: #ff7d00;
            cursor: pointer;
            margin-right: 16px; }
          .utm-wrapper .utm-data-wrapper table tbody td.col_action .remove-button {
            color: #ff3f57;
            cursor: pointer; }
          .utm-wrapper .utm-data-wrapper table tbody td.col_action svg {
            font-size: 16px;
            position: relative;
            top: -2px;
            margin-right: 4px; }

.utm-wrapper .group-by-wrapper b {
  font-size: 14px;
  letter-spacing: -0.4px; }

.utm-wrapper .group-by-wrapper .checkbox-wrapper {
  width: 344px;
  border-radius: 8px;
  border: solid 1px #dcdcdc;
  font-size: 14px;
  margin: 8px 0 12px;
  letter-spacing: -0.2px; }
  .utm-wrapper .group-by-wrapper .checkbox-wrapper label {
    margin: 0;
    line-height: 21px; }
  .utm-wrapper .group-by-wrapper .checkbox-wrapper .custom-checkbox {
    width: 100%;
    padding: 8px 12px;
    height: 40px; }
  .utm-wrapper .group-by-wrapper .checkbox-wrapper .custom-checkbox-stripe {
    width: 100%;
    padding: 9px 12px;
    height: 40px; }
  .utm-wrapper .group-by-wrapper .checkbox-wrapper .custom-checkbox-stripe [type="checkbox"]:checked + label:after {
    width: 16px;
    height: 16px;
    top: 2px;
    left: 0; }
  .utm-wrapper .group-by-wrapper .checkbox-wrapper .select-all-group {
    border-bottom: solid 1px #dcdcdc; }

.utm-wrapper .utm-title {
  font-size: 20px;
  letter-spacing: -0.6px;
  margin: 0 0 16px 0;
  color: #191919;
  padding: 8px 0 12px;
  border-bottom: solid 1px #dcdcdc; }

.utm-wrapper .utm-description {
  margin: 8px 0 16px;
  font-size: 16px;
  letter-spacing: -0.3px;
  width: calc(100% - 230px);
  color: #191919;
  line-height: 24px; }

.utm-wrapper .add-utm-button {
  text-align: center;
  position: absolute;
  top: 32px;
  right: 32px; }

.utm-wrapper .utm-data-outer-wrapper {
  position: relative; }

.utm-wrapper .utm-data-wrapper {
  width: 100%;
  color: #191919;
  overflow-x: auto; }
  .utm-wrapper .utm-data-wrapper .no-utm {
    width: 100%;
    text-align: center;
    font-size: 20px;
    letter-spacing: -0.6px;
    color: rgba(25, 25, 25, 0.6); }
  .utm-wrapper .utm-data-wrapper table {
    width: 100%;
    min-width: 1480px; }
    .utm-wrapper .utm-data-wrapper table thead {
      font-size: 14px;
      border-bottom: solid 1px #dcdcdc;
      color: #191919;
      letter-spacing: -0.4px; }
      .utm-wrapper .utm-data-wrapper table thead td {
        padding: 8px 20px 8px 8px !important;
        white-space: nowrap; }
    .utm-wrapper .utm-data-wrapper table tbody {
      font-size: 14px;
      letter-spacing: -0.2px;
      color: #191919; }
      .utm-wrapper .utm-data-wrapper table tbody tr {
        border-bottom: solid 1px #dcdcdc; }
      .utm-wrapper .utm-data-wrapper table tbody td {
        padding: 16px 8px; }
        .utm-wrapper .utm-data-wrapper table tbody td.col_action {
          font-size: 14px;
          letter-spacing: -0.4px; }
          .utm-wrapper .utm-data-wrapper table tbody td.col_action .edit-button {
            color: #ff7d00;
            cursor: pointer;
            margin-right: 16px; }
          .utm-wrapper .utm-data-wrapper table tbody td.col_action .remove-button {
            color: #ff3f57;
            cursor: pointer; }
          .utm-wrapper .utm-data-wrapper table tbody td.col_action svg {
            font-size: 16px;
            position: relative;
            top: -2px;
            margin-right: 4px; }

.utm-wrapper .utm-title {
  font-size: 20px;
  letter-spacing: -0.6px;
  margin: 0 0 16px 0;
  color: #191919;
  padding: 8px 0 12px;
  border-bottom: solid 1px #dcdcdc; }

.utm-wrapper .utm-description {
  margin: 8px 0 16px;
  font-size: 16px;
  letter-spacing: -0.3px;
  width: calc(100% - 230px);
  color: #191919;
  line-height: 24px; }

.utm-wrapper .add-utm-button {
  text-align: center;
  position: absolute;
  top: 32px;
  right: 32px; }

.utm-wrapper .utm-data-outer-wrapper {
  position: relative; }
  .utm-wrapper .utm-data-outer-wrapper .custom-filter {
    font-size: 16px;
    letter-spacing: -0.3px;
    border-radius: 8px;
    padding: 12px 16px;
    width: 171px;
    border: solid 1px #dcdcdc;
    cursor: pointer;
    margin: 0 0 12px; }
    .utm-wrapper .utm-data-outer-wrapper .custom-filter svg {
      margin-right: 6px;
      font-size: 18px;
      position: relative;
      top: -1px; }

.utm-wrapper .utm-data-wrapper {
  width: 100%;
  color: #191919;
  overflow-x: auto;
  min-height: 200px; }
  .utm-wrapper .utm-data-wrapper .no-utm {
    width: 100%;
    text-align: center;
    font-size: 20px;
    letter-spacing: -0.6px;
    color: rgba(25, 25, 25, 0.6); }
  .utm-wrapper .utm-data-wrapper table {
    width: 100%; }
    .utm-wrapper .utm-data-wrapper table thead {
      font-size: 14px;
      border-bottom: solid 1px #dcdcdc;
      color: #191919;
      letter-spacing: -0.4px; }
      .utm-wrapper .utm-data-wrapper table thead td {
        padding: 0 8px 8px;
        white-space: nowrap; }
        .utm-wrapper .utm-data-wrapper table thead td:last-child {
          width: 84px; }
    .utm-wrapper .utm-data-wrapper table tbody {
      font-size: 14px;
      letter-spacing: -0.2px;
      color: #191919; }
      .utm-wrapper .utm-data-wrapper table tbody tr {
        border-bottom: solid 1px #dcdcdc; }
      .utm-wrapper .utm-data-wrapper table tbody td {
        padding: 16px 10px;
        vertical-align: top;
        min-width: 180px; }
        .utm-wrapper .utm-data-wrapper table tbody td.col_action {
          font-size: 14px;
          letter-spacing: -0.4px; }
          .utm-wrapper .utm-data-wrapper table tbody td.col_action .edit-button {
            color: #ff7d00;
            cursor: pointer;
            margin-right: 16px; }
          .utm-wrapper .utm-data-wrapper table tbody td.col_action .remove-button {
            color: #ff3f57;
            cursor: pointer; }
          .utm-wrapper .utm-data-wrapper table tbody td.col_action svg {
            font-size: 16px;
            position: relative;
            top: -2px;
            margin-right: 4px; }

.modal-custom-filter .modal-content {
  width: 410px;
  border-radius: 8px; }
  .modal-custom-filter .modal-content .modal-body {
    padding: 24px; }
    .modal-custom-filter .modal-content .modal-body .custom-filter-wrapper {
      margin: 12px 0 24px; }
    .modal-custom-filter .modal-content .modal-body .advanced-date-selection button {
      width: 100%; }
    .modal-custom-filter .modal-content .modal-body .custom-filter-title-icon {
      font-size: 26px;
      position: relative;
      top: -4px;
      margin-right: 4px; }
    .modal-custom-filter .modal-content .modal-body .custom-filter-title {
      font-size: 24px;
      letter-spacing: -0.6px; }
    .modal-custom-filter .modal-content .modal-body .group-by-wrapper b {
      font-size: 14px;
      letter-spacing: -0.4px; }
    .modal-custom-filter .modal-content .modal-body .group-by-wrapper .checkbox-wrapper {
      width: 100%;
      border-radius: 8px;
      border: solid 1px #dcdcdc;
      font-size: 14px;
      margin: 8px 0 12px;
      letter-spacing: -0.2px; }
      .modal-custom-filter .modal-content .modal-body .group-by-wrapper .checkbox-wrapper label {
        margin: 0;
        line-height: 21px; }
      .modal-custom-filter .modal-content .modal-body .group-by-wrapper .checkbox-wrapper .custom-checkbox {
        width: 100%;
        padding: 8px 12px;
        height: 40px; }
      .modal-custom-filter .modal-content .modal-body .group-by-wrapper .checkbox-wrapper .custom-checkbox-stripe {
        width: 100%;
        padding: 9px 12px;
        height: 40px; }
      .modal-custom-filter .modal-content .modal-body .group-by-wrapper .checkbox-wrapper .custom-checkbox-stripe [type="checkbox"]:checked + label:after {
        width: 16px;
        height: 16px;
        top: 2px;
        left: 0; }
      .modal-custom-filter .modal-content .modal-body .group-by-wrapper .checkbox-wrapper .select-all-group {
        border-bottom: solid 1px #dcdcdc; }
    .modal-custom-filter .modal-content .modal-body .main-button-40 {
      width: 100%; }

.overview-report-wrapper {
  min-height: 500px; }
  .overview-report-wrapper .overview-report-title {
    font-size: 20px;
    letter-spacing: -0.6px;
    margin: 0 0 16px 0;
    border-bottom: solid 1px #dcdcdc;
    color: #191919;
    padding: 8px 0 24px 0;
    position: relative; }
    .overview-report-wrapper .overview-report-title .advanced-date-selection {
      position: absolute;
      right: 0;
      top: 0; }
  .overview-report-wrapper .overview-report-description {
    margin: 8px 0 16px;
    font-size: 16px;
    letter-spacing: -0.3px;
    width: calc(100% - 230px);
    color: #191919;
    line-height: 24px; }
  .overview-report-wrapper .add-overview-report-button {
    text-align: center;
    position: absolute;
    top: 32px;
    right: 32px; }
  .overview-report-wrapper .overview-report-data-outer-wrapper {
    padding: 32px 0 0; }
  .overview-report-wrapper .overview-report-data-wrapper {
    width: 100%;
    color: #191919; }
    .overview-report-wrapper .overview-report-data-wrapper .no-overview-report {
      width: 100%;
      text-align: center;
      font-size: 20px;
      letter-spacing: -0.6px;
      color: rgba(25, 25, 25, 0.6); }
    .overview-report-wrapper .overview-report-data-wrapper .summary-section-wrapper {
      position: relative; }
      .overview-report-wrapper .overview-report-data-wrapper .summary-section-wrapper .summary-title {
        font-size: 16px;
        line-height: 24px;
        letter-spacing: -0.5px;
        color: #191919; }
      .overview-report-wrapper .overview-report-data-wrapper .summary-section-wrapper .summary-subtitle {
        margin: 0;
        font-size: 14px;
        line-height: 21px;
        letter-spacing: -0.2px;
        color: #19191999; }
      .overview-report-wrapper .overview-report-data-wrapper .summary-section-wrapper .heatmap-legend-wrapper {
        position: absolute;
        top: 24px;
        right: 22px; }
        .overview-report-wrapper .overview-report-data-wrapper .summary-section-wrapper .heatmap-legend-wrapper .heatmap-legend {
          display: inline-block;
          margin-left: 1px; }
          .overview-report-wrapper .overview-report-data-wrapper .summary-section-wrapper .heatmap-legend-wrapper .heatmap-legend .heatmap-legend-box {
            min-width: 40px;
            height: 8px; }
          .overview-report-wrapper .overview-report-data-wrapper .summary-section-wrapper .heatmap-legend-wrapper .heatmap-legend .color-1 {
            background-color: #FFF3F4; }
          .overview-report-wrapper .overview-report-data-wrapper .summary-section-wrapper .heatmap-legend-wrapper .heatmap-legend .color-2 {
            background-color: #FFE7EA; }
          .overview-report-wrapper .overview-report-data-wrapper .summary-section-wrapper .heatmap-legend-wrapper .heatmap-legend .color-3 {
            background-color: #FFC9D0; }
          .overview-report-wrapper .overview-report-data-wrapper .summary-section-wrapper .heatmap-legend-wrapper .heatmap-legend .color-4 {
            background-color: #FFACB6; }
          .overview-report-wrapper .overview-report-data-wrapper .summary-section-wrapper .heatmap-legend-wrapper .heatmap-legend .color-5 {
            background-color: #FF8191; }
          .overview-report-wrapper .overview-report-data-wrapper .summary-section-wrapper .heatmap-legend-wrapper .heatmap-legend p {
            display: block;
            margin: 4px 0 0 0;
            padding-right: 4px;
            font-size: 12px;
            line-height: 16px;
            letter-spacing: -0.2px;
            color: #191919; }
      .overview-report-wrapper .overview-report-data-wrapper .summary-section-wrapper .chart-legend-wrapper {
        position: absolute;
        top: 4px;
        right: 0px; }
        .overview-report-wrapper .overview-report-data-wrapper .summary-section-wrapper .chart-legend-wrapper .chart-legend-list {
          display: inline-block; }
          .overview-report-wrapper .overview-report-data-wrapper .summary-section-wrapper .chart-legend-wrapper .chart-legend-list .chart-legend {
            display: inline-block;
            vertical-align: middle;
            margin: 0 8px 0 16px;
            width: 8px;
            height: 8px;
            border-radius: 4px; }
          .overview-report-wrapper .overview-report-data-wrapper .summary-section-wrapper .chart-legend-wrapper .chart-legend-list .chart-legend-tooltip-icon {
            display: inline-block;
            vertical-align: middle;
            margin-left: 2px;
            width: 12px;
            height: 12px;
            color: #19191966;
            cursor: pointer; }
          .overview-report-wrapper .overview-report-data-wrapper .summary-section-wrapper .chart-legend-wrapper .chart-legend-list .orange-dot {
            background: #FF7E00; }
          .overview-report-wrapper .overview-report-data-wrapper .summary-section-wrapper .chart-legend-wrapper .chart-legend-list .green-dot {
            background: #19C700; }
          .overview-report-wrapper .overview-report-data-wrapper .summary-section-wrapper .chart-legend-wrapper .chart-legend-list .blue-dot {
            background: #3BAEFF; }
          .overview-report-wrapper .overview-report-data-wrapper .summary-section-wrapper .chart-legend-wrapper .chart-legend-list .purple-dot {
            background: #9C88F1; }
          .overview-report-wrapper .overview-report-data-wrapper .summary-section-wrapper .chart-legend-wrapper .chart-legend-list p {
            display: inline-block;
            vertical-align: middle;
            margin: 0px;
            font-size: 12px;
            line-height: 16px;
            letter-spacing: -0.2px;
            color: #191919; }
      .overview-report-wrapper .overview-report-data-wrapper .summary-section-wrapper .chart-y-label {
        position: absolute;
        padding: 16px;
        width: 300px;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: -0.2px;
        color: #19191966;
        background: white;
        text-align: center;
        z-index: 1; }
      .overview-report-wrapper .overview-report-data-wrapper .summary-section-wrapper .y-label-left {
        left: -128px;
        top: 44%;
        -webkit-transform: translate(0, -50%);
                transform: translate(0, -50%);
        -webkit-transform: rotate(-90deg);
                transform: rotate(-90deg); }
      .overview-report-wrapper .overview-report-data-wrapper .summary-section-wrapper .y-label-right {
        right: -128px;
        top: 44%;
        -webkit-transform: translate(0, -50%);
                transform: translate(0, -50%);
        -webkit-transform: rotate(90deg);
                transform: rotate(90deg); }
      .overview-report-wrapper .overview-report-data-wrapper .summary-section-wrapper .overview-box-title {
        font-size: 20px;
        letter-spacing: -.6px;
        color: #191919;
        padding: 0 0 12px;
        border-bottom: solid 1px #dcdcdc;
        margin: 0 0 24px; }
      .overview-report-wrapper .overview-report-data-wrapper .summary-section-wrapper .overview-box-title-2 {
        margin: 40px 0 0 0;
        padding: 0 0 0 16px;
        border: none !important; }
      .overview-report-wrapper .overview-report-data-wrapper .summary-section-wrapper .case-report-box {
        border-radius: 8px;
        box-shadow: 0px 2px 20px rgba(25, 25, 25, 0.18);
        margin: 0 24px 24px 0;
        width: calc((100% / 3) - 16px);
        display: inline-block;
        padding: 18px 8px;
        vertical-align: top; }
        .overview-report-wrapper .overview-report-data-wrapper .summary-section-wrapper .case-report-box:nth-child(3n) {
          margin: 0 0 24px 0; }
        .overview-report-wrapper .overview-report-data-wrapper .summary-section-wrapper .case-report-box .case-report-value {
          font-size: 24px;
          letter-spacing: -0.6px;
          margin: 8px 0;
          line-height: 1; }
          .overview-report-wrapper .overview-report-data-wrapper .summary-section-wrapper .case-report-box .case-report-value.onetalk-shine {
            width: 120px;
            height: 30px;
            border-radius: 6px;
            display: block; }
        .overview-report-wrapper .overview-report-data-wrapper .summary-section-wrapper .case-report-box .case-report-title {
          letter-spacing: -0.4px;
          font-size: 14px;
          color: rgba(25, 25, 25, 0.6);
          margin: 0;
          line-height: 1; }
      .overview-report-wrapper .overview-report-data-wrapper .summary-section-wrapper .overview-case-rating-left {
        vertical-align: top;
        width: 60%;
        height: 351px;
        display: inline-block;
        position: relative;
        padding: 0 60px 0 60px; }
        .overview-report-wrapper .overview-report-data-wrapper .summary-section-wrapper .overview-case-rating-left .total-rating-is-text {
          position: absolute;
          left: 50%;
          top: 50%;
          -webkit-transform: translate(-50%, -50%);
                  transform: translate(-50%, -50%); }
          .overview-report-wrapper .overview-report-data-wrapper .summary-section-wrapper .overview-case-rating-left .total-rating-is-text .total-rating-counter.onetalk-shine {
            height: 30px;
            width: 64px;
            border-radius: 6px; }
        .overview-report-wrapper .overview-report-data-wrapper .summary-section-wrapper .overview-case-rating-left .total-rating-is {
          background: #ffffff;
          border-radius: 50%;
          position: absolute;
          left: 50%;
          top: 50%;
          -webkit-transform: translate(-50%, -50%);
                  transform: translate(-50%, -50%);
          width: 170px;
          height: 170px;
          text-align: center; }
      .overview-report-wrapper .overview-report-data-wrapper .summary-section-wrapper .overview-case-rating-right {
        vertical-align: top;
        width: 40%;
        display: inline-block; }
        .overview-report-wrapper .overview-report-data-wrapper .summary-section-wrapper .overview-case-rating-right .table-overview {
          width: 100%; }
          .overview-report-wrapper .overview-report-data-wrapper .summary-section-wrapper .overview-case-rating-right .table-overview thead tr td {
            font-size: 16px;
            letter-spacing: -0.5px;
            color: #191919;
            padding: 0 0 16px 0; }
            .overview-report-wrapper .overview-report-data-wrapper .summary-section-wrapper .overview-case-rating-right .table-overview thead tr td:nth-child(2) {
              text-align: right; }
          .overview-report-wrapper .overview-report-data-wrapper .summary-section-wrapper .overview-case-rating-right .table-overview tbody tr td {
            font-size: 14px;
            letter-spacing: -0.4px;
            border-bottom: solid 1px #dcdcdc;
            padding: 8px 0 8px 14px;
            position: relative; }
            .overview-report-wrapper .overview-report-data-wrapper .summary-section-wrapper .overview-case-rating-right .table-overview tbody tr td .onetalk-shine {
              width: 44px;
              height: 21px;
              border-radius: 6px;
              padding: 0;
              margin: 0; }
            .overview-report-wrapper .overview-report-data-wrapper .summary-section-wrapper .overview-case-rating-right .table-overview tbody tr td div {
              width: 6px;
              height: 6px;
              border-radius: 50%;
              position: absolute;
              left: 0;
              top: 50%;
              -webkit-transform: translate(0, -50%);
                      transform: translate(0, -50%); }
            .overview-report-wrapper .overview-report-data-wrapper .summary-section-wrapper .overview-case-rating-right .table-overview tbody tr td.stars-8 div {
              background: #DCDCDC; }
            .overview-report-wrapper .overview-report-data-wrapper .summary-section-wrapper .overview-case-rating-right .table-overview tbody tr td.stars-7 div {
              background: #FF3F57; }
            .overview-report-wrapper .overview-report-data-wrapper .summary-section-wrapper .overview-case-rating-right .table-overview tbody tr td.stars-6 div {
              background: #007A8A; }
            .overview-report-wrapper .overview-report-data-wrapper .summary-section-wrapper .overview-case-rating-right .table-overview tbody tr td.stars-5 div {
              background: #51E683; }
            .overview-report-wrapper .overview-report-data-wrapper .summary-section-wrapper .overview-case-rating-right .table-overview tbody tr td.stars-4 div {
              background: #3BAEff; }
            .overview-report-wrapper .overview-report-data-wrapper .summary-section-wrapper .overview-case-rating-right .table-overview tbody tr td.stars-3 div {
              background: #9C2EEF; }
            .overview-report-wrapper .overview-report-data-wrapper .summary-section-wrapper .overview-case-rating-right .table-overview tbody tr td.stars-2 div {
              background: #F4C12A; }
            .overview-report-wrapper .overview-report-data-wrapper .summary-section-wrapper .overview-case-rating-right .table-overview tbody tr td.stars-1 div {
              background: #f99080; }
            .overview-report-wrapper .overview-report-data-wrapper .summary-section-wrapper .overview-case-rating-right .table-overview tbody tr td:nth-child(2) {
              text-align: right; }
        .overview-report-wrapper .overview-report-data-wrapper .summary-section-wrapper .overview-case-rating-right .overview-case-rating-average {
          padding: 16px 8px;
          border-radius: 8px;
          width: 100%;
          position: relative;
          display: block;
          box-shadow: 0px 2px 20px rgba(25, 25, 25, 0.18);
          margin-top: 24px; }
          .overview-report-wrapper .overview-report-data-wrapper .summary-section-wrapper .overview-case-rating-right .overview-case-rating-average .rating-value {
            font-size: 24px;
            letter-spacing: -0.6px; }
            .overview-report-wrapper .overview-report-data-wrapper .summary-section-wrapper .overview-case-rating-right .overview-case-rating-average .rating-value.onetalk-shine {
              width: 64px;
              height: 30px;
              border-radius: 6px;
              position: relative;
              top: 7px; }
          .overview-report-wrapper .overview-report-data-wrapper .summary-section-wrapper .overview-case-rating-right .overview-case-rating-average svg {
            color: #F2BF2A;
            font-size: 22px;
            margin-right: 4px;
            top: -5px;
            position: relative; }
          .overview-report-wrapper .overview-report-data-wrapper .summary-section-wrapper .overview-case-rating-right .overview-case-rating-average p {
            margin: 8px 0 0;
            color: rgba(25, 25, 25, 0.6);
            font-size: 14px;
            letter-spacing: -0.4px; }
      .overview-report-wrapper .overview-report-data-wrapper .summary-section-wrapper .overview-agent-report-wrapper {
        width: 100%;
        position: relative;
        display: block; }
        .overview-report-wrapper .overview-report-data-wrapper .summary-section-wrapper .overview-agent-report-wrapper .overview-agent-report-box {
          text-align: center;
          width: 25%;
          padding: 0 16px;
          display: inline-block;
          vertical-align: top; }
        .overview-report-wrapper .overview-report-data-wrapper .summary-section-wrapper .overview-agent-report-wrapper .agent-report-icon-wrapper {
          width: 48px;
          height: 48px;
          position: relative;
          display: inline-block;
          border-radius: 50%;
          background: #F99080;
          box-shadow: 0px 2px 20px rgba(25, 25, 25, 0.18); }
          .overview-report-wrapper .overview-report-data-wrapper .summary-section-wrapper .overview-agent-report-wrapper .agent-report-icon-wrapper svg {
            color: #ffffff;
            font-size: 20px;
            position: absolute;
            top: 50%;
            left: 50%;
            -webkit-transform: translate(-50%, -50%);
                    transform: translate(-50%, -50%); }
        .overview-report-wrapper .overview-report-data-wrapper .summary-section-wrapper .overview-agent-report-wrapper p.agent-report-text-1 {
          font-size: 24px;
          letter-spacing: -0.6px;
          margin: 8px 0; }
          .overview-report-wrapper .overview-report-data-wrapper .summary-section-wrapper .overview-agent-report-wrapper p.agent-report-text-1 .rating-text svg {
            position: relative;
            top: -3px;
            font-size: 20px;
            color: #F4C12A;
            margin-right: 4px; }
          .overview-report-wrapper .overview-report-data-wrapper .summary-section-wrapper .overview-agent-report-wrapper p.agent-report-text-1 .onetalk-shine {
            width: 44px;
            height: 30px;
            border-radius: 6px; }
        .overview-report-wrapper .overview-report-data-wrapper .summary-section-wrapper .overview-agent-report-wrapper p.agent-report-text-2 {
          font-size: 12px;
          letter-spacing: -0.4px;
          margin: 0 0 2px; }
          .overview-report-wrapper .overview-report-data-wrapper .summary-section-wrapper .overview-agent-report-wrapper p.agent-report-text-2 .onetalk-shine {
            width: 44px;
            height: 21px;
            border-radius: 6px; }
        .overview-report-wrapper .overview-report-data-wrapper .summary-section-wrapper .overview-agent-report-wrapper p.agent-report-text-3 {
          font-size: 12px;
          letter-spacing: -0.2px;
          color: rgba(25, 25, 25, 0.8);
          margin: 0; }
          .overview-report-wrapper .overview-report-data-wrapper .summary-section-wrapper .overview-agent-report-wrapper p.agent-report-text-3 .onetalk-shine {
            width: 88px;
            height: 21px;
            border-radius: 6px; }
        .overview-report-wrapper .overview-report-data-wrapper .summary-section-wrapper .overview-agent-report-wrapper p .onetalk-shine-star {
          position: relative;
          top: -9px;
          font-size: 20px;
          color: #f4c12a;
          margin-right: 6px; }

.section-separator {
  border-bottom: solid 1px #1919191A;
  margin: 16px 0 32px 0; }

.section-separator-no-legend {
  border-bottom: solid 1px #1919191A;
  margin: -16px 0 32px 0; }

#user-messages-heatmap .apexcharts-tooltip {
  padding: 8px;
  font-family: "PTRootBold";
  font-size: 12px;
  letter-spacing: -0.4px;
  color: white; }

.tooltip-chart-legend .tooltip-inner {
  text-align: center !important;
  font-family: "PTRootUI" !important;
  font-weight: 400 !important;
  font-size: 12px !important;
  line-height: 16px !important;
  letter-spacing: -0.2px !important;
  color: #FFFFFF !important; }

#user-messages-heatmap .apexcharts-tooltip {
  padding: 8px;
  font-family: "PTRootUI";
  font-size: 12px;
  letter-spacing: -0.4px;
  color: white;
  background: #6B6B6B !important; }
  #user-messages-heatmap .apexcharts-tooltip p {
    margin: 0; }

#verification-wrapper {
    position: fixed;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(calc(-50% - 60px), calc(-50% - 60px));
            transform: translate(calc(-50% - 60px), calc(-50% - 60px));
    padding: 48px;
    margin: 60px;
    width: calc(100% - 120px);
    max-width: 540px;
    background: #ffffff;
    border-radius: 32px;
    box-shadow: 0px 3px 22px 0px #1919194D;
    text-align: center;
}

#verification-wrapper img {
    width: 154px;
    display: inline-block;
}

#verification-wrapper .verification-status-failed {
    padding: 9.5px;
    margin: auto;
    width: 88px;
    height: 88px;
    background: #FF3F5733;
    border-radius: 44px;
}

#verification-wrapper .verification-status-failed svg {
    padding: 12.5px;
    width: 69px;
    height: 69px;
    background: #FF3F57;
    color: white;
    border-radius: 35px;
}

#verification-wrapper .verification-status-success {
    padding: 9.5px;
    margin: auto;
    width: 88px;
    height: 88px;
    background: #11830033;
    border-radius: 44px;
}

#verification-wrapper .verification-status-success svg {
    padding: 12.5px;
    width: 69px;
    height: 69px;
    background: #118300;
    color: #cfe6cc;
    border-radius: 35px;
}

#verification-wrapper .verification-title {
    margin: 10px 0 0;
    font-size: 24px;
    line-height: 30px;
    letter-spacing: -0.6px;
    text-align: center;
    color: #191919;
}

#verification-wrapper .verification-description {
    margin: 10px 0 0;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.3px;
    text-align: center;
    color: #191919;
}

/* @media screen and (max-width: 600px) {
    #verification-wrapper {
        width: 100%;
        border-radius: 0;
        padding: 40px 28px;
    }

    #verification-wrapper .verification-status {
        font-size: 20px !important;
    }

    #verification-wrapper .verification-status svg {
        top: -2px !important;
        width: 21px;
    }

    #verification-wrapper .login-button {
        padding: 11px 0;
        font-size: 15px;
    }
} */

#verification-wrapper .resend-email-wrapper {
    margin: 32px 0;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.5px;
    text-align: center;
    color: #19191999;
    margin-top: 15px;
}

#verification-wrapper .resend-email-wrapper .resend-button {
    margin: 0 0 0 4px;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.5px;
    color: var(--main-dark-orange);
    font-weight: bold;
    -webkit-transition: ease-in-out 0.1s;
    transition: ease-in-out 0.1s;
    cursor: pointer;
}

#verification-wrapper .resend-email-wrapper .resend-button:hover {
    -webkit-transform: scale(0.975);
            transform: scale(0.975);
}

#verification-wrapper .resend-email-wrapper .timer-resend-button {
    margin: 0 0 0 4px;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.5px;
    color: #19191999;
    font-weight: bold;
}

#verification-wrapper .resend-email-wrapper .lds-ring div {
    width: 12px;
    height: 12px;
    margin: 10px 0 0 4px;
}

.assist-ai {
  padding: 0; }
  .assist-ai iframe {
    border: 0;
    box-shadow: none;
    outline: none;
    width: 100%;
    height: 100%; }

.sectionContent .assist-ai {
  height: calc(100vh - 48px); }

.sectionContent.section-wrap-active-billing-warning .assist-ai {
  height: calc(100vh - 80px); }

