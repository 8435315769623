.chat-room-broadcast-user-info-wrapper {
    /* width: 300px; */
    width: 340px;
    display: inline-block;
    transition: ease-in-out .2s;
    /* transition: left 2s, width .4s; */
    position: relative;
    /* right: -300px; */
    right: -340px;
    background: #ffffff;
    vertical-align: top;
    height: 100%;
    /* padding-top: 24px; */
    /* overflow: hidden; */
    box-shadow: -1px 0 0 0 rgba(25, 25, 25, 0.1);
    border-top-left-radius: 12px;

    .chat-room-case-history-close {
        background: #ffffff;
        border-radius: 50%;
        cursor: pointer;
        position: absolute;
        width: 48px;
        height: 48px;
        display: inline-block;
        text-align: center;
        line-height: 45px;
        top: 0;
        left: -64px;

        svg {
            font-size: 24px;
            color: #7D7C7D;

            &:hover {
                color: #5c5c5c;
            }
        }
    }

    .user-info-name-avatar-wrapper {
        width: 100%;
        position: relative;
        /* padding: 0 16px 12px 16px; */
        padding: 24px;
        background: #f3f3f3;
        border-top-left-radius: 12px;
    }
}

.chat-room-broadcast-user-info-wrapper.active-chat-room-broadcast-user-info-wrapper {
    right: 0;
    /* width: 300px; */
    /* overflow: visible; */
}

.user-info-avatar-wrapper {
    border-radius: 50%;
    height: 48px;
    display: inline-block;
    width: 48px;
    vertical-align: top;
    background: pink;
    position: relative;
    text-align: center;
    line-height: 48px;
    color: #ffffff;
    font-size: 16px;
}

.user-info-name-connectivity-modal {
    width: calc(100% - 96px);
    display: inline-block;
    padding-left: 12px;
    line-height: 19px;
    top: 50%;
	transform: translate(0, -50%);
	left: 72px;
    position: absolute;
}

.user-info-name-connectivity-modal p {
    white-space: nowrap;
    overflow: hidden;
    margin: 0;
    text-overflow: ellipsis;
    font-size: 16px;
    letter-spacing: -0.5px;
}

.user-info-online {
    font-size: 10px;
    letter-spacing: 1.5px;
    color: rgba(25, 25, 25, 0.6);
    font-weight: bold;
}

.user-info-offline {
    cursor: pointer;
    border-radius: 4px;
    background: #ff7d00;
    color: #ffffff;
    font-size: 12px;
    line-height: 1.5;
    letter-spacing: -0.2px;
    padding: 1px 8px;
    display: inline-block;
    margin-top: 4px;
    transition: ease-in-out .2s;
}

.user-info-offline:hover {
    background: #e87200;
}

.user-info-offline svg {
    margin-right: 4px;
    position: relative;
    top: -1px;
}

.user-info-online svg {
    color: #19c700;
    position: relative;
    top: -1px;
    font-size: 13px;
    margin-right: 2px;
}

.user-info-offline-dot {
    font-size: 10px;
    letter-spacing: 1.5px;
    color: rgba(25, 25, 25, 0.6);
    font-weight: bold;    
}

.user-info-offline-dot svg {
    color: #ff3f57;
    position: relative;
    top: -1px;
    font-size: 13px;
    margin-right: 2px;
}

.user-info-offline-dot svg {
    color: #ff3f57;
    position: relative;
    top: -1px;
    font-size: 13px;
    margin-right: 2px;
}

.user-info-tab-wrapper {
    /* border-bottom: solid 1px #dcdcdc; */
    width: 100%;
    position: relative;
    /* padding: 12px 0 0; */
    background: #f3f3f3;
}

.user-info-tab-content {
    width: calc(100% / 3);
    display: inline-block;
    color: rgba(25, 25, 25, 0.6);
    text-align: center;
    font-size: 14px;
    letter-spacing: -0.4px;
    position: relative;
    padding: 8px 0;

    svg {
        font-size: 14px;
    }

    &:nth-child(1) {
		&.active-room-broadcast-list-tab {
			border-radius: 0 12px 0 0;
		}
	}

	&:nth-last-child(1) {
		&.active-room-broadcast-list-tab {
			border-radius: 12px 0 0 0;
		}
	}
}

.user-info-tab-content.active-room-broadcast-list-tab {
    color: #ff7d00;
    background: #ffffff;
    border-radius: 12px 12px 0 0;
}

/* .user-info-tab-content.active-room-broadcast-list-tab:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 4px;
    background: #ff7d00;
    left: 0;
    bottom: 0;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
} */

.user-info-tab-content svg {
    font-size: 14px;
    margin-right: 4px;
    position: relative;
    top: -2px;
}

.user-info-tab-content:hover {
    color: #191919;
    cursor: pointer;
}

.user-info-list-wrapper {
    margin-bottom: 16px;
    width: 100%;
    text-align: left;
}

.user-info-list-wrapper label {
    font-size: 14px;
    letter-spacing: -0.4px;
    color: rgba(25, 25, 25, 0.8);
    display: block;
}

.user-info-box-wrapper {
    background: rgba(25, 25, 25, 0.05);
    border-radius: 8px;
    height: 40px;
    width: 100%;
    line-height: 40px;
    padding: 0 9px;
    font-size: 14px;
    color: #191919;
    width: 100%;
    position: relative;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.user-info-box-wrapper.with-button-copy {
    padding: 0 65px 0 8px;
}

.user-detail-copy-wrapper {
    position: absolute;
    right: 12px;
    top: 50%;
    transform: translate(0, -50%);
    color: rgba(25, 25, 25, 0.6);
    font-size: 12px;
    line-height: -0.2px;
    cursor: pointer;
}

.user-detail-copy-wrapper:hover {
    color: rgba(25, 25, 25, 0.8);   
}

.user-detail-copy-wrapper svg {
    font-size: 16px;
    margin-right: 4px;
}

.user-info-box-wrapper img {
    position: relative;
    top: -2px;
    margin-right: 7px;
}

.user-info-box-wrapper svg {
    position: relative;
    top: -2px;
    margin-right: 7px;
}

.user-info-tab.user-info-tab-modal {
    max-height: calc(100vh - 173px);
    height: 100%;
    overflow-y: hidden;
}

.user-info-tab-modal {
    max-height: calc(100vh - 173px);
}

.user-info-tags {
    font-size: 12px;
    letter-spacing: -0.4px;
    color: rgba(25, 25, 25, 0.6);
    line-height: 1.5;
    padding: 0 8px;
    border-radius: 4px;
    margin-right: 4px;
    display: inline-block;
    background: rgba(25, 25, 25, 0.06);
    vertical-align: top;
    margin-bottom: 5px;
}